import {
	type RadioButtonsPublicGetValue,
	type RadioButtonsPublicSetValue,
	radioButtonsScopes,
} from '@atlassian/jira-ui-modifications-common/src/common/utils/common/fields/radio-buttons.tsx';
import { commonIssueViewGranularScopes } from './fields-scopes.tsx';

type RadioButtonsInternalFullShapeValue = null | { id: string };

type RadioButtonsInternalIdShapeValue = null | {
	id: string;
	value: string;
	self?: string;
};

export const radioButtonsSetValueTransformer = (
	value: RadioButtonsPublicSetValue,
): RadioButtonsInternalFullShapeValue => {
	if (value === null) {
		return null;
	}

	return { id: value };
};

export const radioButtonsGetValueTransformer = (
	value: RadioButtonsInternalIdShapeValue,
): RadioButtonsPublicGetValue => {
	if (!value) {
		return null;
	}

	return { id: value.id, value: value.value };
};

export const radioButtonsIssueViewScopes = {
	...radioButtonsScopes,
	granular: [...radioButtonsScopes.granular, ...commonIssueViewGranularScopes],
};
