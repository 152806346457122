import { useCallback } from 'react';
import { di } from 'react-magnetic-di';
import { useIssueId } from '@atlassian/jira-issue-context-service/src/main.tsx';

const useConvertToIssue = (
	itemKey: string,
	onClick?: (itemKey: string, event?: Event) => Promise<undefined> | undefined,
) => {
	di(window);
	const issueId = useIssueId();
	const href = `/secure/ConvertSubTask.jspa?id=${issueId || ''}`;

	return useCallback(() => {
		onClick?.(itemKey);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.location.assign(href);
	}, [onClick, itemKey, href]);
};

export default useConvertToIssue;
