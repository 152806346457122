import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const AiIssueBreakdownEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(/* webpackChunkName: "async-ai-work-breakdown" */ './src/view').then(
			(module) => module.AiIssueBreakdownEntryPointComponent,
		),
	),
	getPreloadProps: () => ({}),
});
