import React, { useCallback, useMemo, useState } from 'react';
import { useFragment, graphql, useMutation } from 'react-relay';
import { useInlineEditFieldInjections } from '@atlassian/jira-issue-field-injections/src/controllers/inline-edit-injections-context/index.tsx';
import { useFieldInlineEditActions } from '@atlassian/jira-issue-field-inline-edit-actions/src/controllers/index.tsx';
import type { OnSubmitCallbacks } from '@atlassian/jira-issue-field-inline-edit-actions/src/controllers/types.tsx';
import type { ValidationFieldProps } from '@atlassian/jira-issue-field-inline-edit-lite/src/ui/field-inline-edit-lite/types.tsx';
import { FieldInlineEditLiteWithEntryPoint } from '@atlassian/jira-issue-field-inline-edit-lite/src/ui/index.tsx';
import PeopleEditViewEntryPoint from '@atlassian/jira-issue-field-people-editview-full/src/entrypoint.tsx';
import type { PeopleEditViewProps } from '@atlassian/jira-issue-field-people-editview-full/src/ui/people/types.tsx';
import {
	transformAggToOption,
	transformToSuggestions,
} from '@atlassian/jira-issue-field-people-form-field/src/ui/people-field/index.tsx';
import { PeopleReadView } from '@atlassian/jira-issue-field-people-readview-full/src/ui/people/index.tsx';
import type { AggUser } from '@atlassian/jira-issue-user-picker-edit-view/src/common/types.tsx';
import type { people_issueFieldPeople_PeopleField_Mutation as PeopleMutation } from '@atlassian/jira-relay/src/__generated__/people_issueFieldPeople_PeopleField_Mutation.graphql';
import type { people_issueFieldPeopleInlineEditFull_PeopleInlineEditView_fragmentRef$key as PeopleFragment } from '@atlassian/jira-relay/src/__generated__/people_issueFieldPeopleInlineEditFull_PeopleInlineEditView_fragmentRef.graphql';
import type { people_issueFieldPeopleInlineEditFull_PeopleInlineEditViewWithIsEditable_fragmentRef$key as PeopleWithIsEditableFragment } from '@atlassian/jira-relay/src/__generated__/people_issueFieldPeopleInlineEditFull_PeopleInlineEditViewWithIsEditable_fragmentRef.graphql';
import type {
	PeopleInlineEditViewProps,
	PeopleInlineEditViewWithIsEditableProps,
} from './types.tsx';

/** Determine if the updated edit view value is equal to our original value before submitting a mutation. */
const isEqualOption = (a: AggUser[] | null, b: AggUser[] | null) =>
	!!(a?.length === b?.length && a?.every((option, index) => option?.id === b?.[index]?.id));

/**
 * Inline edit will handle the switching behaviour between the 'readView' and 'editView' components. This variant allows
 * consumers to define their own value to determine whether the field is editable.
 *
 * In most cases consumers should use `PeopleInlineEditView` which will enforce that the user has permission to
 * edit the field within the issue view. However, this component can be used for experiences that have differing
 * permissions or want finer control for how this data is retrieved, e.g. lazy loading editability.
 *
 * @param props [PeopleInlineEditViewWithIsEditableProps](./types.tsx)
 */
export const PeopleInlineEditViewWithIsEditable = ({
	editViewPopup,
	editViewPopupAlignBlock,
	fragmentRef,
	isEditable,
	isEditing: startWithEditViewOpen = false,
	menuPosition,
	onSubmit,
	onSubmitFailed,
	onSubmitSucceeded,
	readViewFitContainerHeight,
}: PeopleInlineEditViewWithIsEditableProps) => {
	// #region Relay
	const data = useFragment<PeopleWithIsEditableFragment>(
		graphql`
			fragment people_issueFieldPeopleInlineEditFull_PeopleInlineEditViewWithIsEditable_fragmentRef on JiraPeopleField {
				id
				name
				type
				...people_issueFieldPeopleReadviewFull_PeopleReadView
				selectedUsersConnection {
					# eslint-disable-next-line @atlassian/relay/unused-fields
					edges {
						node {
							accountId
							id
							accountStatus
							name
							picture
							... on AtlassianAccountUser {
								email
							}
						}
					}
				}
			}
		`,
		fragmentRef,
	);

	const [commit] = useMutation<PeopleMutation>(graphql`
		mutation people_issueFieldPeople_PeopleField_Mutation($input: JiraUpdatePeopleFieldInput!)
		@raw_response_type {
			jira @optIn(to: ["JiraIssueFieldMutations"]) {
				updatePeopleField(input: $input) {
					success
					errors {
						message
					}
					field {
						...people_issueFieldPeopleInlineEditFull_PeopleInlineEditViewWithIsEditable_fragmentRef
					}
				}
			}
		}
	`);
	// #endregion

	// #region Common state
	const { overriding } = useInlineEditFieldInjections();
	const { id: uniqueFieldId, name, type: fieldType, selectedUsersConnection } = data;

	const isFieldEditable = overriding.overrideIsEditable(isEditable);
	const fieldName = overriding.overrideLabel(name);

	const initialValue = transformToSuggestions(selectedUsersConnection);

	const [updatedValue, setUpdatedValue] = useState<AggUser[] | null>(initialValue);

	const handleSubmit = useCallback(
		(value: AggUser[] | null, { onSuccess, onFail }: OnSubmitCallbacks) => {
			onSubmit?.(value);

			commit({
				variables: {
					input: {
						id: uniqueFieldId,
						operations: [
							{
								ids: value?.map((user) => user.id) ?? [],
								operation: 'SET',
							},
						],
					},
				},
				onCompleted(mutationData) {
					if (mutationData.jira?.updatePeopleField?.success) {
						onSuccess();
					} else {
						onFail();
					}
				},
				onError(error: Error) {
					onFail(error);
				},
				optimisticResponse: {
					jira: {
						updatePeopleField: {
							success: true,
							errors: null,
							field: {
								id: uniqueFieldId,
								name: fieldName,
								type: fieldType,
								selectedUsersConnection: {
									edges: value?.map((edge) => ({
										node: {
											accountId: edge.accountId,
											id: edge.id,
											accountStatus: edge.accountStatus,
											name: edge.name,
											picture: edge.picture,
											email: edge.email,
											__typename: 'AtlassianAccountUser',
										},
									})),
								},
							},
						},
					},
				},
			});
		},
		[commit, fieldName, onSubmit, uniqueFieldId, fieldType],
	);

	const {
		invalidMessage,
		isEditing,
		hasServerValidationError,
		handleCancel,
		handleEdit,
		handleConfirm,
		handleChange,
	} = useFieldInlineEditActions({
		fieldId: uniqueFieldId,
		fieldName,
		fieldType,
		initialValue,
		isValueEqual: isEqualOption,
		onSubmit: handleSubmit,
		onSubmitFailed,
		onSubmitSucceeded,
		onUpdateValue: setUpdatedValue,
		startWithEditViewOpen,
		updatedValue,
	});

	// #region Read view
	const renderReadView = () => <PeopleReadView fragmentRef={data} />;
	// #endregion

	// #region Edit view
	const getEditViewProps = (fieldProps: ValidationFieldProps): PeopleEditViewProps => ({
		...fieldProps,
		autoFocus: true,
		fieldId: uniqueFieldId,
		value: transformAggToOption(updatedValue),
		menuPosition,
		onChange: handleChange,
	});
	const editViewEntryPointParams = useMemo(() => ({}), []);
	// #endregion

	return (
		<FieldInlineEditLiteWithEntryPoint
			editViewPopup={editViewPopup}
			editViewPopupAlignBlock={editViewPopupAlignBlock}
			editViewEntryPoint={PeopleEditViewEntryPoint}
			editViewEntryPointParams={editViewEntryPointParams}
			getEditViewProps={getEditViewProps}
			fieldName={fieldName}
			hasUnsubmittedChanges={hasServerValidationError}
			invalidMessage={invalidMessage}
			isEditing={isEditing}
			isEditable={isFieldEditable}
			onCancel={handleCancel}
			onConfirm={handleConfirm}
			onEdit={handleEdit}
			readViewFitContainerHeight={readViewFitContainerHeight}
			readView={renderReadView}
			hideActionButtons
		/>
	);
};

/**
 * Inline edit will handle the switching behaviour between the 'readView' and 'editView' components.
 *
 * @param props [ProjectInlineEditViewProps](./types.tsx)
 */
export const PeopleInlineEditView = ({ fragmentRef, ...props }: PeopleInlineEditViewProps) => {
	// #region Relay
	const data = useFragment<PeopleFragment>(
		graphql`
			fragment people_issueFieldPeopleInlineEditFull_PeopleInlineEditView_fragmentRef on JiraPeopleField {
				...people_issueFieldPeopleInlineEditFull_PeopleInlineEditViewWithIsEditable_fragmentRef
				fieldConfig {
					isEditable
				}
			}
		`,
		fragmentRef,
	);
	// #endregion

	return (
		<PeopleInlineEditViewWithIsEditable
			{...props}
			fragmentRef={data}
			isEditable={data.fieldConfig?.isEditable ?? false}
		/>
	);
};
