import omitBy from 'lodash/omitBy';
import type { TimeTracking } from '@atlassian/jira-issue-shared-types/src/common/types/jira-settings-type.tsx';
import type { TimeTrackingState } from '@atlassian/jira-issue-shared-types/src/common/types/time-tracking-type.tsx';

const isUndefined = (val: undefined | number) => val === undefined;

export const transformFromStateValue = (stateValue: TimeTrackingState | null): TimeTracking => {
	if (!stateValue) {
		return Object.seal({});
	}

	const { remainingEstimateSeconds, timeSpentSeconds } = stateValue;
	return omitBy(
		{
			loggedTime: timeSpentSeconds,
			remainingTime: remainingEstimateSeconds,
		},
		isUndefined,
	);
};

export const transformToStateValue = ({
	loggedTime,
	remainingTime,
}: TimeTracking): TimeTrackingState =>
	omitBy(
		{
			timeSpentSeconds: loggedTime,
			remainingEstimateSeconds: remainingTime,
		},
		isUndefined,
	);
