import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const issueAdjustmentsEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() =>
			import(
				/* webpackChunkName: "async-ui-modifications-entrypoint" */ './enabled-issue-adjustments/index'
			),
	),
	getPreloadProps: () => ({}),
});
