import type { DocNode } from '@atlaskit/adf-schema';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import {
	type Field,
	type FieldInternalMetadata,
	type ViewType,
	isValidADF,
} from '@atlassian/jira-issue-adjustments/src/common.tsx';

import { convertTextToAdf } from '@atlassian/jira-issue-field-textarea/src/ui/edit/utils.tsx';
import {
	PLAIN_TEXT,
	RICH_CONTENT,
} from '@atlassian/jira-issue-shared-types/src/common/types/field-schema-type.tsx';
import type { DescriptionAndParagraphPublicValue } from '@atlassian/jira-ui-modifications-common/src/common/utils/common/fields/paragraph.tsx';

export type ParagraphInternalValue = string | DocNode | undefined | null;

export const validateParagraphSetValue = (
	value: unknown,
	{ fieldRenderer }: FieldInternalMetadata,
	_createAnalyticsEvent: CreateUIAnalyticsEvent,
	extra: { currentField: Field; viewType: ViewType },
): Promise<boolean> => {
	switch (fieldRenderer) {
		case RICH_CONTENT:
			return isValidADF({
				newValue: value,
				currentValue: extra.currentField?.value,
			});
		case PLAIN_TEXT:
			return Promise.resolve(typeof value === 'string');
		default:
			return Promise.resolve(false);
	}
};

// We only want to pass expected properties through to setFieldValue
export const paragraphSetValueTransformer = (
	value: DescriptionAndParagraphPublicValue,
): ParagraphInternalValue => {
	if (typeof value === 'string') {
		return value;
	}
	return { ...value };
};

export const paragraphGetValueTransformer = (
	value: ParagraphInternalValue,
	fieldRenderer?: string,
): DescriptionAndParagraphPublicValue => {
	if (value === undefined || value === null) {
		// The `convertTextToAdf` call will give us an "empty ADF object":
		// {
		//   "type": "doc",
		//   "version": 1,
		//   "content": [
		//     {
		//       "type": "paragraph",
		//       "content": []
		//     }
		//   ]
		// }
		return fieldRenderer === RICH_CONTENT ? convertTextToAdf('') : '';
	}
	if (typeof value === 'string') {
		return value;
	}
	return value;
};
