import React, {
	Component,
	type PropsWithChildren,
	type ReactNode,
	type ComponentType,
} from 'react';
import { styled } from '@compiled/react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OffScreenWrapper = styled.div({
	position: 'absolute',
	left: '-999999px',
});

export type MountAnimationProps = PropsWithChildren<{
	height: number;
	width: number;
	onAnimationEnd: () => void;
}>;

type Props = {
	children: ReactNode;
	mountAnimation: ComponentType<MountAnimationProps>;
};

type State = {
	shouldAnimateMount: boolean;
	childSize: {
		height: number;
		width: number;
	} | null;
};

// eslint-disable-next-line jira/react/no-class-components
export default class MountAnimator extends Component<Props, State> {
	static defaultProps = {
		mountAnimation: undefined,
	};

	state: State = {
		shouldAnimateMount: true,
		childSize: null,
	};

	onMountAnimationEnd = () => {
		this.setState({ shouldAnimateMount: false });
	};

	calculateSize = (domNode: HTMLDivElement | null) => {
		if (!domNode) {
			return;
		}

		requestAnimationFrame(() => {
			const { width, height } = domNode.getBoundingClientRect();
			this.setState({ childSize: { width, height } });
		});
	};

	render() {
		const { mountAnimation: MountAnimation, children } = this.props;

		// Render only the child element if the animation has completed.
		if (!this.state.shouldAnimateMount) {
			return children;
		}

		// Render the children off-screen to calculate their size before animating.
		if (!this.state.childSize) {
			return <OffScreenWrapper ref={this.calculateSize}>{children}</OffScreenWrapper>;
		}

		// Actually animate the new elements.
		return (
			<MountAnimation
				onAnimationEnd={this.onMountAnimationEnd}
				height={this.state.childSize.height}
				width={this.state.childSize.width}
			>
				{children}
			</MountAnimation>
		);
	}
}
