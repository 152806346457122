import React from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { messages } from './messages.tsx';

export const IssueLeftIcon = ({
	iconUrl,
	issueTypeName,
}: {
	iconUrl?: string;
	issueTypeName?: string;
}) => {
	const { formatMessage } = useIntl();
	return (
		<img
			src={iconUrl}
			alt={formatMessage(
				fg('jira-issue-terminology-refresh-m3')
					? messages.issueIconAltTextIssueTermRefresh
					: messages.issueIconAltText,
				{
					issueTypeName,
				},
			)}
			height="16"
		/>
	);
};
