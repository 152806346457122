import React, { useCallback, type ReactNode, type KeyboardEvent } from 'react';
import Checkbox from '@atlaskit/checkbox';
import { components } from '@atlaskit/select';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';
import { CheckboxWrapper } from './styled.tsx';
import type { Props } from './types.tsx';

const Menu = (props: Props) => {
	const { formatMessage } = useIntl();
	const { children, ...restProps } = props;

	const isChecked = props.selectProps?.componentsProps?.isChecked || false;
	const projectKey = props.selectProps?.componentsProps?.projectKey || null;
	const label = formatMessage(messages.checkboxLabel, { projectKey });

	const onKeyDown = useCallback((event: KeyboardEvent) => {
		// stopping propagation when focus moves to checkbox because select already binded with space and enter for selection
		if (event.key === ' ') {
			event.stopPropagation();
		}
	}, []);

	return (
		// @ts-expect-error - TS2746 - This JSX tag's 'children' prop expects a single child of type 'ReactElement<any, string | JSXElementConstructor<any>>', but multiple children were provided.
		<components.Menu {...restProps}>
			<>
				<CheckboxWrapper onClick={props.selectProps?.componentsProps?.onChange}>
					<Checkbox
						onKeyDown={onKeyDown}
						isChecked={isChecked}
						label={label}
						testId="issue-field-sprint.ui.edit.menu.checkbox"
					/>
				</CheckboxWrapper>
				{children}
			</>
		</components.Menu>
	);
};

// @ts-expect-error - TS2352 - Conversion of type '(props: Props) => JSX.Element' to type 'ReactNode' may be a mistake because neither type sufficiently overlaps with the other. If this was intentional, convert the expression to 'unknown' first.
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default Menu as ReactNode;
