import React, { useMemo } from 'react';
import type { FeatureFlagName } from '@atlassian/jira-development-common/src/model/releases-data.tsx';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';
import ContentTooltip from './tooltip/index.tsx';

type Props = {
	enabledFeatureFlags: FeatureFlagName[];
	disabledFeatureFlags: FeatureFlagName[];
};

const FlagsDescription = (props: Props) => {
	const { formatMessage } = useIntl();
	const { enabledFeatureFlags, disabledFeatureFlags } = props;

	const hasFlags = () => enabledFeatureFlags.length + disabledFeatureFlags.length > 0;

	const Description = useMemo(() => {
		let labelText;
		const flagsCnt = enabledFeatureFlags.length + disabledFeatureFlags.length;
		if (flagsCnt === 1) {
			labelText =
				enabledFeatureFlags.length === 1 ? enabledFeatureFlags[0] : disabledFeatureFlags[0];
		} else {
			labelText = formatMessage(messages.many, { flagsCnt });
		}
		return <span>{labelText}</span>;
	}, [disabledFeatureFlags, enabledFeatureFlags, formatMessage]);

	if (!hasFlags()) {
		return null;
	}
	return (
		<ContentTooltip
			enabledFeatureFlags={enabledFeatureFlags}
			disabledFeatureFlags={disabledFeatureFlags}
		>
			{Description}
		</ContentTooltip>
	);
};

export default FlagsDescription;
