import React from 'react';
import { AnalyticsContext } from '@atlaskit/analytics-next';
import { AIOpsUnifiedEventsContainer } from '@atlassian/eoc-aiops-instrumentation';
import { GlobalContextContainer } from '@atlassian/jira-eoc-global-context/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { AIContextContainer } from '@atlassian/jira-servicedesk-ai-context-common/src/ui/ai-context-container/index.tsx';
import type { IncidentSuggestionResource } from '../common/types.tsx';
import { OpsPanelContent } from './content/index.tsx';
import messages from './messages.tsx';
import { SendUnifiedEvents } from './send-analytics/index.tsx';

type Props = {
	resource: IncidentSuggestionResource;
};

export const AIContextOpsPanel = ({ resource }: Props) => {
	const { formatMessage } = useIntl();
	const { loading, error, data } = resource;

	return (
		<AnalyticsContext
			data={{
				source: 'AIOpsContextPanel',
			}}
		>
			<GlobalContextContainer
				packageName="jiraServicedeskAIContextOpsPanel"
				teamName="itsm-aiops-grogu"
			>
				<AIOpsUnifiedEventsContainer aiFeatureName="incidentContextPanel">
					<AIContextContainer
						isLoading={loading}
						error={error}
						attribution={formatMessage(messages.attribution)}
						groupId="ai-context-ops"
						title={formatMessage(messages.contextTitle)}
					>
						{data && <OpsPanelContent resource={resource} />}
					</AIContextContainer>
					<SendUnifiedEvents error={error} />
				</AIOpsUnifiedEventsContainer>
			</GlobalContextContainer>
		</AnalyticsContext>
	);
};
