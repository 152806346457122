import type {
	SuggestedCommentReplyAgentCommentType,
	SuggestedCommentReplyCommentTypes,
} from '@atlassian/jira-ai-assistance-service-client/src/services/invoke-agent/suggested-comment-reply-agent/types.tsx';
import {
	type Action,
	createStore,
	createHook,
	createContainer,
} from '@atlassian/react-sweet-state';
import type { SuggestedComments } from '../../services/use-ai-comments/types.tsx';
import { getAvailableCommentTypeOptions, type CommentTypeOption } from './utils.tsx';

export type AICommentState = {
	availableCommentTypeOptions: CommentTypeOption[];
	availableCommentTypes: SuggestedCommentReplyCommentTypes;
	suggestedComments: SuggestedComments | null;
	currentCommentType: SuggestedCommentReplyAgentCommentType | null;
	acceptedCommentTypes: SuggestedCommentReplyCommentTypes;
};

export const initialState: AICommentState = {
	availableCommentTypeOptions: [],
	availableCommentTypes: [],
	suggestedComments: null,
	acceptedCommentTypes: [],
	currentCommentType: null,
};

export type ContainerProps = {
	totalOfComments: number;
	hasMissingAssignee: boolean;
	hasMissingDescription: boolean;
};

export const setSuggestedComments =
	(suggestedComments: SuggestedComments | null): Action<AICommentState, ContainerProps> =>
	({ setState }) => {
		setState({ suggestedComments });
	};

export const setCurrentCommentType =
	(
		currentCommentType: SuggestedCommentReplyAgentCommentType | null,
	): Action<AICommentState, ContainerProps> =>
	({ setState }) => {
		setState({ currentCommentType });
	};

export const acceptSuggestedComment =
	(commentType: SuggestedCommentReplyAgentCommentType): Action<AICommentState, ContainerProps> =>
	({ setState, getState }, props) => {
		const acceptedCommentTypes = getState().acceptedCommentTypes.concat(commentType);
		const { commentTypes, commentTypeOptions } = getAvailableCommentTypeOptions({
			...props,
			acceptedCommentTypes,
		});

		setState({
			availableCommentTypes: commentTypes,
			availableCommentTypeOptions: commentTypeOptions,
			currentCommentType: null,
			suggestedComments: null,
			acceptedCommentTypes,
		});
	};

export const actions = {
	setSuggestedComments,
	setCurrentCommentType,
	acceptSuggestedComment,
};
export type Actions = typeof actions;

export const JiraIssueAICommentsContainer = createContainer<ContainerProps>({
	displayName: 'JiraIssueAICommentsContainer',
});

export const Store = createStore<AICommentState, Actions, ContainerProps>({
	initialState,
	actions,
	name: 'jira-issue-ai-comments',
	containedBy: JiraIssueAICommentsContainer,
	handlers: {
		onInit:
			() =>
			({ setState }, props) => {
				const { commentTypes, commentTypeOptions } = getAvailableCommentTypeOptions(props);
				setState({
					availableCommentTypeOptions: commentTypeOptions,
					availableCommentTypes: commentTypes,
				});
			},
		onContainerUpdate:
			(nextProps) =>
			({ setState, getState }) => {
				const { commentTypes, commentTypeOptions } = getAvailableCommentTypeOptions({
					...nextProps,
					acceptedCommentTypes: getState().acceptedCommentTypes,
				});
				// clean the suggestedComments when the container props are updated
				setState({
					availableCommentTypes: commentTypes,
					availableCommentTypeOptions: commentTypeOptions,
					suggestedComments: null,
					currentCommentType: null,
				});
			},
	},
});

export const useAICommentsStates = createHook(Store);

const getCurrentComment = (
	state: AICommentState,
): {
	currentCommentType: SuggestedCommentReplyAgentCommentType | null;
	currentComment?: string | null;
} => {
	const currentComment =
		state.currentCommentType &&
		state.suggestedComments &&
		state.suggestedComments[state.currentCommentType];
	return { currentCommentType: state.currentCommentType, currentComment };
};

export const useCurrentComment = createHook(Store, {
	selector: getCurrentComment,
});
