import type { ComponentType } from 'react';
import memoizeOne from 'memoize-one';
import type {
	FieldType,
	CollectionType,
	CustomField,
	CustomFieldType,
} from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import type { CustomFieldProps } from '../types.tsx';
import DateTime from './date-time/view.tsx';
import Date from './date/view.tsx';
import Groups from './group/multi/view.tsx';
import Group from './group/single/view.tsx';
import Labels from './labels/view.tsx';
import SingleLineTextInline from './single-line-text-inline/view.tsx';
import Users from './user/multi/view.tsx';
import User from './user/single/view.tsx';

export type FieldItemMap = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[C in CollectionType]?: Record<FieldType, ComponentType<CustomFieldProps<any>> | undefined>;
};

// TODO ARKEN-707: undefined components should receive an implementation
const getFieldMap = (): FieldItemMap => ({
	none: {
		string: SingleLineTextInline,
		number: SingleLineTextInline,
		object: SingleLineTextInline,
		user: User,
		group: Group,
		datetime: DateTime,
		date: Date,
	},
	list: {
		string: Labels,
		number: undefined,
		object: undefined,
		group: Groups,
		user: Users,
		datetime: undefined,
		date: undefined,
	},
});

const getComponent = (
	extension: CustomField | CustomFieldType | null, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): ComponentType<CustomFieldProps<any>> | null => {
	if (!extension) {
		return null;
	}

	const { type, collection = 'none' } = extension.properties;
	const fieldCollection = getFieldMap()[collection];
	return (fieldCollection && fieldCollection[type]) ?? null;
};

const getForgeCustomFields = (extension: CustomField | CustomFieldType | null) =>
	getComponent(extension);

export default memoizeOne(getForgeCustomFields);
