import { initialState } from '../../../common/constants.tsx';
import type { Actions } from '../../../common/types.tsx';

const closeIssueTransitionModal: Actions['closeIssueTransitionModal'] =
	() =>
	({ setState }) => {
		setState({
			...initialState,
			isModalOpen: false,
			isSubsequentLoad: true,
		});
	};

export default closeIssueTransitionModal;
