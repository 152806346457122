import get from 'lodash/get';
import { ApolloError } from 'apollo-client';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type { AnalyticsAttributes } from '@atlassian/jira-product-analytics-bridge';

export const ERROR_ANALYTICS_CONTEXT_KEY = 'giraErrorAnalytics';

type ErrorAnalyticsContext = {
	id: string;
	packageName?: string;
	teamName?: string;
	attributes?: AnalyticsAttributes;
	sendToPrivacyUnsafeSplunk?: boolean;
};
type OperationContext = {
	[key: string]: unknown;
};

/**
 * Append error analytics data to the provided GraphQL operation context. This is how gira consumers can opt-in to FAMA
 * error reporting for their GraphQL queries, e.g.
 * ```
 * useQuery(MY_QUERY, {
 *  context: withErrorAnalyticsContext({
 *      id: 'my-query',
 *      packageName: 'my-package',
 *      teamName: 'my-team',
 *      sendToPrivacyUnsafeSplunk: true,
 *  })
 * }
 * ```
 *
 * @param errorAnalytics Analytics data that will be set as the analytics payload for any GraphQL/network errors.
 * @param context GraphQL operation context to append to. Can be undefined if there is no additional context data
 * required.
 * @returns New GraphQL operation context
 */
export const withErrorAnalyticsContext = (
	errorAnalytics: ErrorAnalyticsContext,
	context: OperationContext = {},
): OperationContext => ({
	...context,
	[ERROR_ANALYTICS_CONTEXT_KEY]: errorAnalytics,
});

/**
 * Extract error analytics data from the provided operation context in a type-safe manner. If error analytics data is
 * not defined or does not match the expected shape then `undefined` is returned.
 *
 * @param context GraphQL operation context to read from.
 */
export const getErrorAnalyticsContext = (
	context: OperationContext,
): ErrorAnalyticsContext | undefined => {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const errorAnalytics = get(
		context,
		[ERROR_ANALYTICS_CONTEXT_KEY],
		undefined,
	) as ErrorAnalyticsContext;

	if (
		typeof errorAnalytics === 'object' &&
		typeof errorAnalytics.id === 'string' &&
		(errorAnalytics.packageName === undefined || typeof errorAnalytics.packageName === 'string') &&
		(errorAnalytics.teamName === undefined || typeof errorAnalytics.teamName === 'string') &&
		(errorAnalytics.attributes === undefined || typeof errorAnalytics.attributes === 'object') &&
		(errorAnalytics.sendToPrivacyUnsafeSplunk === undefined ||
			typeof errorAnalytics.sendToPrivacyUnsafeSplunk === 'boolean')
	) {
		return {
			id: errorAnalytics.id,
			packageName: errorAnalytics.packageName,
			teamName: errorAnalytics.teamName,
			attributes: errorAnalytics.attributes,
			sendToPrivacyUnsafeSplunk: errorAnalytics.sendToPrivacyUnsafeSplunk,
		};
	}

	return undefined;
};

type ReportErrorsPayload = {
	graphQLErrors?: unknown;
	networkError?: Error;
	// Partial representation of apollo-link operation
	operation: {
		operationName: string;
		getContext: () => OperationContext;
	};
};

/**
 * Report errors using the JFE analytics pipeline if analytics data has been provided in the GraphQL operation context.
 */
export const reportErrors = ({ graphQLErrors, networkError, operation }: ReportErrorsPayload) => {
	const errorAnalytics = getErrorAnalyticsContext(operation.getContext());

	// Refine mixed type to Array | void
	if (graphQLErrors !== undefined && !Array.isArray(graphQLErrors)) {
		return;
	}

	const hasGraphQLError = graphQLErrors !== undefined && graphQLErrors.length > 0;
	const hasNetworkError = networkError !== undefined;

	// Only report errors using the JFE analytics pipeline if analytics context data is defined for the query.
	if (errorAnalytics === undefined || (!hasGraphQLError && !hasNetworkError)) {
		return;
	}

	const { id, packageName, teamName, attributes, sendToPrivacyUnsafeSplunk } = errorAnalytics;

	const error = new ApolloError({
		// Above Array.isArray check will refine the type to a read-only array. We need this spread to keep flow happy.
		graphQLErrors: graphQLErrors ? [...graphQLErrors] : undefined,
		networkError,
	});

	const attributesWithOperationName = {
		...attributes,
		operationName: operation.operationName,
		hasGraphQLError,
		hasNetworkError,
	};

	fireErrorAnalytics({
		error,
		meta: {
			id,
			packageName,
			teamName,
		},
		attributes: attributesWithOperationName,
		sendToPrivacyUnsafeSplunk,
	});
};
