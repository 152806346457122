import { useCallback, type MouseEvent } from 'react';
import { useRouterActions, type RouterActionsType } from '@atlassian/react-resource-router';

export const useRouterPush = () => {
	const { push: routerPush } = useRouterActions();

	const push = useCallback(
		(event: MouseEvent<HTMLElement>, ...pushArgs: Parameters<RouterActionsType['push']>) => {
			if (event.metaKey || event.ctrlKey || event.altKey || event.shiftKey) return;
			event.preventDefault();
			routerPush(...pushArgs);
		},
		[routerPush],
	);

	return { push };
};
