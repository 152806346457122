/**
 * @generated SignedSource<<09b84e91c9c1c3ce724260ae3192786f>>
 * @relayHash 416caaeb397c61b987a7d4f8e6ca4b62
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID fec885faee82275c7ba952cf6acb6ce2ba83c0b011e36ded00be7853203522c4

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type JiraMultiValueFieldOperations = "ADD" | "REMOVE" | "SET" | "%future added value";
export type JiraUpdateMultipleSelectUserPickerFieldInput = {
  id: string;
  operations: ReadonlyArray<JiraMultipleSelectUserPickerFieldOperationInput>;
};
export type JiraMultipleSelectUserPickerFieldOperationInput = {
  ids: ReadonlyArray<string>;
  operation: JiraMultiValueFieldOperations;
};
export type ui_issueViewLayoutMultiUserField_IssueViewMultiUserField_Mutation$variables = {
  input: JiraUpdateMultipleSelectUserPickerFieldInput;
};
export type ui_issueViewLayoutMultiUserField_IssueViewMultiUserField_Mutation$data = {
  readonly jira: {
    readonly updateMultipleSelectUserPickerField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly id: string;
        readonly selectedUsersConnection: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly accountId: string;
              readonly email?: string | null | undefined;
              readonly id: string;
              readonly name: string;
              readonly picture: AGG$URL;
            } | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type ui_issueViewLayoutMultiUserField_IssueViewMultiUserField_Mutation$rawResponse = {
  readonly jira: {
    readonly updateMultipleSelectUserPickerField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly id: string;
        readonly selectedUsersConnection: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly __typename: "AtlassianAccountUser";
              readonly accountId: string;
              readonly email: string | null | undefined;
              readonly id: string;
              readonly name: string;
              readonly picture: AGG$URL;
            } | {
              readonly __typename: string;
              readonly accountId: string;
              readonly id: string;
              readonly name: string;
              readonly picture: AGG$URL;
            } | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type ui_issueViewLayoutMultiUserField_IssueViewMultiUserField_Mutation = {
  rawResponse: ui_issueViewLayoutMultiUserField_IssueViewMultiUserField_Mutation$rawResponse;
  response: ui_issueViewLayoutMultiUserField_IssueViewMultiUserField_Mutation$data;
  variables: ui_issueViewLayoutMultiUserField_IssueViewMultiUserField_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "success"
},
v3 = {
  "concreteType": "MutationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "message"
    }
  ]
},
v4 = {
  "kind": "ScalarField",
  "name": "id"
},
v5 = {
  "kind": "ScalarField",
  "name": "accountId"
},
v6 = {
  "kind": "ScalarField",
  "name": "name"
},
v7 = {
  "kind": "ScalarField",
  "name": "picture"
},
v8 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "email"
    }
  ],
  "type": "AtlassianAccountUser"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "ui_issueViewLayoutMultiUserField_IssueViewMultiUserField_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraMultipleSelectUserPickerFieldPayload",
            "kind": "LinkedField",
            "name": "updateMultipleSelectUserPickerField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraMultipleSelectUserPickerField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "concreteType": "JiraUserConnection",
                    "kind": "LinkedField",
                    "name": "selectedUsersConnection",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraUserEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v4/*: any*/),
                              (v6/*: any*/),
                              (v7/*: any*/),
                              (v8/*: any*/)
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ui_issueViewLayoutMultiUserField_IssueViewMultiUserField_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraMultipleSelectUserPickerFieldPayload",
            "kind": "LinkedField",
            "name": "updateMultipleSelectUserPickerField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraMultipleSelectUserPickerField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "concreteType": "JiraUserConnection",
                    "kind": "LinkedField",
                    "name": "selectedUsersConnection",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraUserEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "__typename"
                              },
                              (v5/*: any*/),
                              (v4/*: any*/),
                              (v6/*: any*/),
                              (v7/*: any*/),
                              (v8/*: any*/)
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "fec885faee82275c7ba952cf6acb6ce2ba83c0b011e36ded00be7853203522c4",
    "metadata": {},
    "name": "ui_issueViewLayoutMultiUserField_IssueViewMultiUserField_Mutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "c7be035792198f6aa7c35a648c7675d9";

export default node;
