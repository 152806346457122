import type { Stakeholder, Services } from './types.tsx';

export const transformStkeholdersDetailResponseData = (
	stakeholders: Stakeholder[],
): Stakeholder[] | undefined =>
	// @ts-expect-error: After old version of stakeholders removed/deprecated, that util will be removed. `isNew` is the condition
	stakeholders.map((stakeholder) => ({
		// @ts-expect-error - TS2339 - Property 'name' does not exist on type 'Stakeholder | Stakeholder'. | TS2339 - Property 'name' does not exist on type 'Stakeholder | Stakeholder'. | TS2339 - Property 'fullName' does not exist on type 'Stakeholder | Stakeholder'.
		name: stakeholder.name !== undefined ? stakeholder.name : stakeholder.fullName,
		avatarUrl: stakeholder.avatarUrl,
		// @ts-expect-error: After old version of stakeholders removed/deprecated, that util will be removed. `isNew` is the condition
		ari: stakeholder.ari,
		// @ts-expect-error: After old version of stakeholders removed/deprecated, that util will be removed. `isNew` is the condition
		id: stakeholder.ari,
		// @ts-expect-error: After old version of stakeholders removed/deprecated, that util will be removed. `isNew` is the condition
		isManuallyAdded: stakeholder?.isManuallyAdded,
		services: stakeholder?.services,
	}));

export const toServiceListString = (services: Services): string => {
	if (!services || !services.length) {
		return '';
	}

	return services.join(', ');
};
