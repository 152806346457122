// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	back: {
		id: 'issue.views.issue-base.foundation.back-button.back',
		defaultMessage: 'Back',
		description: 'A button that takes user back to the previous page',
	},
});
