import React from 'react';
import { styled } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import CheckCircleIcon from '@atlaskit/icon/glyph/check-circle';
import CrossCircleIcon from '@atlaskit/icon/glyph/cross-circle';
import QuestionCircleIcon from '@atlaskit/icon/glyph/question-circle';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { buildData_softwareReleasesVersionDetailIssueList_devOpsSummarisedBuilds$key } from '@atlassian/jira-relay/src/__generated__/buildData_softwareReleasesVersionDetailIssueList_devOpsSummarisedBuilds.graphql';
import type { buildData_softwareReleasesVersionDetailIssueList_jiraIssueDevSummary$key } from '@atlassian/jira-relay/src/__generated__/buildData_softwareReleasesVersionDetailIssueList_jiraIssueDevSummary.graphql';
import messages from './messages.tsx';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const IconWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
});

type Props = {
	jiraIssueDevSummary: buildData_softwareReleasesVersionDetailIssueList_jiraIssueDevSummary$key;
	devOpsSummarisedBuilds?: buildData_softwareReleasesVersionDetailIssueList_devOpsSummarisedBuilds$key;
	onBuildClicked: () => void;
};

export const BuildData = ({
	jiraIssueDevSummary,
	devOpsSummarisedBuilds,
	onBuildClicked,
}: Props) => {
	const { formatMessage } = useIntl();
	const appLinkBuildData = useFragment(
		graphql`
			fragment buildData_softwareReleasesVersionDetailIssueList_jiraIssueDevSummary on JiraIssueDevSummary {
				build @required(action: THROW) {
					overall @required(action: THROW) {
						failedBuildCount @required(action: THROW)
						successfulBuildCount @required(action: THROW)
						unknownBuildCount @required(action: THROW)
					}
				}
			}
		`,
		jiraIssueDevSummary,
	);

	const nonAppLinkBuildData = useFragment(
		graphql`
			fragment buildData_softwareReleasesVersionDetailIssueList_devOpsSummarisedBuilds on DevOpsSummarisedBuilds {
				buildStates {
					state
					count
				}
			}
		`,
		devOpsSummarisedBuilds ?? null,
	);

	const {
		failedBuildCount: failedBuildCountAppLink,
		successfulBuildCount: successfulBuildCountAppLink,
		unknownBuildCount: unknownBuildCountAppLink,
	} = appLinkBuildData.build.overall;
	const buildStates = nonAppLinkBuildData?.buildStates ?? [];
	const failedBuildCountNonAppLink =
		buildStates.find((buildState) => buildState?.state === 'FAILED')?.count ?? 0;
	const successfulBuildCountNonAppLink =
		buildStates.find((buildState) => buildState?.state === 'SUCCESSFUL')?.count ?? 0;
	const unknownBuildCountNonAppLink =
		buildStates.find((buildState) => buildState?.state === 'UNKNOWN')?.count ?? 0;
	const failedBuildCount = failedBuildCountAppLink + failedBuildCountNonAppLink;
	const successfulBuildCount = successfulBuildCountAppLink + successfulBuildCountNonAppLink;
	const unknownBuildCount = unknownBuildCountAppLink + unknownBuildCountNonAppLink;

	const hasBuilds = failedBuildCount + successfulBuildCount + unknownBuildCount > 0;

	const renderIcon = () => {
		if (failedBuildCount > 0) {
			const failedBuildMessage = formatMessage(messages.failedBuild, {
				buildCount: failedBuildCount,
			});

			return (
				<Tooltip content={failedBuildMessage}>
					<CrossCircleIcon
						size="medium"
						label={failedBuildMessage}
						primaryColor={token('color.icon.danger', colors.R300)}
					/>
				</Tooltip>
			);
		}

		if (successfulBuildCount > 0) {
			const successfulBuildMessage = formatMessage(messages.successfulBuild, {
				buildCount: successfulBuildCount,
			});

			return (
				<Tooltip content={successfulBuildMessage}>
					<CheckCircleIcon
						size="medium"
						label={successfulBuildMessage}
						primaryColor={token('color.icon.success', colors.G300)}
					/>
				</Tooltip>
			);
		}

		if (unknownBuildCount > 0) {
			const unknownBuildMessage = formatMessage(messages.unknownBuild, {
				buildCount: unknownBuildCount,
			});

			return (
				<Tooltip content={unknownBuildMessage}>
					<QuestionCircleIcon
						size="medium"
						label={unknownBuildMessage}
						primaryColor={token('color.icon.warning', colors.Y300)}
					/>
				</Tooltip>
			);
		}

		return null;
	};

	return hasBuilds ? (
		<IconWrapper>
			<Button
				spacing="none"
				appearance="subtle-link"
				onClick={(_, analyticsEvent: UIAnalyticsEvent) => {
					onBuildClicked();
					fireUIAnalytics(analyticsEvent, 'versionsBuildsLozenge');
				}}
			>
				{renderIcon()}
			</Button>
		</IconWrapper>
	) : null;
};
