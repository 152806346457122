import { useState, useEffect } from 'react';
import { logSafeErrorWithoutCustomerData } from '@atlassian/jira-common-directory-v2/src/utils/log-safe-error/index.tsx';

type Props = [string | undefined, (value?: string) => void];

/*
 * TODO BENTO-9507: This should be keyed differently as a project can have issue types with different tabs
 *
 * Using ?. removes the need for us to mock Local Storage in tests where window.localStorage does not exist
 */
export const useTabsLocalStorage = (projectKey: string): Props => {
	const keyName = `tabs.state.${projectKey}`;
	const [storedValue, setStoredValue] = useState(() => {
		try {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			return window.localStorage?.getItem(keyName);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (_: any) {
			return undefined;
		}
	});

	// Required for multiple gets in the case of transitions between already-visited issues in the SPA
	useEffect(() => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		setStoredValue(window.localStorage?.getItem(keyName));
	}, [keyName]);

	const setValue = (value?: string) => {
		try {
			setStoredValue(value);
			// @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.localStorage?.setItem(keyName, value);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			logSafeErrorWithoutCustomerData(
				error,
				'issue.issue-view-layout-templates.components.tab-view',
				'issue.templates.tab-view',
			);
		}
	};

	// @ts-expect-error - TS2322 - Type 'string | null | undefined' is not assignable to type 'string | undefined'.
	return [storedValue, setValue];
};
