import React, { useCallback, useMemo } from 'react';
import { useThemeObserver } from '@atlaskit/tokens';
import { EMBEDDED_CONFLUENCE_MODE, parseUrl } from '@atlassian/embedded-confluence';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type { ConfluencePage } from '@atlassian/jira-issue-shared-types/src/common/types/confluence-content-type.tsx';
import type { FailedRemoteLink } from '@atlassian/jira-issue-shared-types/src/common/types/remote-link-error-type.tsx';
import { embeddedConfluencePageModalEntrypoint } from '../../../entrypoint.tsx';
import { useEmbeddedPageTracking } from '../use-embedded-page-tracking/index.tsx';
import { useParentProduct } from '../use-parent-product/index.tsx';

const entryPointParams = {};
export const useEmbeddedPageModalEntryPoint = (
	page?: ConfluencePage | FailedRemoteLink,
	embeddedConfluenceSource?: string,
) => {
	const parentProduct = useParentProduct();
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const isPageError = !page || (page as FailedRemoteLink).error;

	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		embeddedConfluencePageModalEntrypoint,
		entryPointParams,
	);

	const fireAnalyticsEvent = useEmbeddedPageTracking();
	const themeState = useThemeObserver();
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const confluencePage = page as ConfluencePage;
	const pageHref = confluencePage?.href;
	const hostname = confluencePage?.hostname ?? '';

	const onPageAction = useCallback(
		(action: string, eventName: string) => {
			fireAnalyticsEvent({
				action,
				eventName,
				embeddedConfluenceSource,
			});
		},
		[fireAnalyticsEvent, embeddedConfluenceSource],
	);

	const onClose = useCallback(() => {
		fireAnalyticsEvent({
			action: 'clicked',
			eventName: 'closeLinkedConfluencePage',
			embeddedConfluenceSource,
		});
		entryPointActions.unload();
	}, [fireAnalyticsEvent, entryPointActions, embeddedConfluenceSource]);

	const { locale } = useIntl();

	const runtimeProps = useMemo(
		() => ({
			locale,
			hostname,
			isOpen: true,
			modalTitle: confluencePage?.title,
			navigationPolicy: undefined,
			onClose,
			onPageAction,
			parentProduct,
			spaceKey: parseUrl(pageHref)?.spaceKey,
			themeState,
			url: pageHref,
			mode: EMBEDDED_CONFLUENCE_MODE.VIEW_MODE,
			embeddedConfluenceSource,
			contentId: parseUrl(pageHref)?.contentId,
			allowedFeatures: {
				view: [
					'byline-contributors',
					'byline-extensions',
					'page-comments',
					'page-reactions',
					'inline-comments',
					'non-licensed-share',
				],
			},
		}),
		[
			locale,
			hostname,
			confluencePage?.title,
			onClose,
			onPageAction,
			parentProduct,
			pageHref,
			themeState,
			embeddedConfluenceSource,
		],
	);

	return !isPageError
		? {
				entryPointActions,
				entryPoint: (
					<JiraEntryPointContainer
						entryPointReferenceSubject={entryPointReferenceSubject}
						id="epModalEntrypointContainer"
						packageName="@atlassian/jira-issue-create-confluence-content"
						teamName="confluence-better-together"
						runtimeProps={runtimeProps}
					/>
				),
			}
		: { ref: undefined, entryPoint: null };
};
