import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/do';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { isHttpClientErrorResponse } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { sendExperienceAnalytics } from '@atlassian/jira-issue-view-analytics/src/controllers/send-experience-analytics/index.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { trackOrLogClientError } from '@atlassian/jira-issue-view-common-utils/src/errors/index.tsx';
import {
	deleteLinkedIssueSuccess,
	deleteLinkedIssueFailure,
	DELETE_LINKED_ISSUE_REQUEST,
} from '@atlassian/jira-issue-view-store/src/actions/issue-links-actions.tsx';
import { baseUrlSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import {
	issueLinkSelector,
	linkedIssueSelector,
} from '@atlassian/jira-issue-view-store/src/selectors/issue-links-selector.tsx';
import type { BaseUrl, IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import {
	EXPERIENCE_DELETE_ISSUE_LINK_SUCCEEDED,
	EXPERIENCE_DELETE_ISSUE_LINK_FAILED,
	SOURCE_JIRA_LINKED_ISSUES,
} from './constants.tsx';

const deleteOptions = { method: 'DELETE' } as const;
export const getDeletionUrl = (baseUrl: BaseUrl, linkId: IssueId) =>
	`${baseUrl}/rest/api/2/issueLink/${linkId}`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<any>, store: MiddlewareAPI<State>) =>
	action$.ofType(DELETE_LINKED_ISSUE_REQUEST).mergeMap((action) => {
		const state = store.getState();
		const baseUrl = baseUrlSelector(state);
		const { application, edition } = action.payload;

		/**
		 * BENTO-3469 wording here is confusing, because linkedIssueId
		 * is actually link id
		 */
		const linkId = action.payload.linkedIssueId;
		const deleteUrl = getDeletionUrl(baseUrl, linkId);
		const link = issueLinkSelector(linkId)(state);
		const linkedIssue = linkedIssueSelector(link.linkedIssueKey)(state);

		return fetchJson$(deleteUrl, deleteOptions)
			.map(() =>
				deleteLinkedIssueSuccess(linkId, {
					issueId: linkedIssue.id,
					direction: link.direction,
				}),
			)
			.do(() => {
				sendExperienceAnalytics({
					experience: EXPERIENCE_DELETE_ISSUE_LINK_SUCCEEDED,
					wasExperienceSuccesful: true,
					analyticsSource: SOURCE_JIRA_LINKED_ISSUES,
					application: application ?? null,
					edition: edition ?? null,
				});
			})
			.catch((error: Error | FetchError) => {
				if (!(error instanceof FetchError && isHttpClientErrorResponse(error))) {
					fireErrorAnalytics({
						error,
						meta: {
							id: 'issue.issue-links.delete-issue-link-error',
							teamName: 'bento',
						},
						attributes: {
							experience: EXPERIENCE_DELETE_ISSUE_LINK_FAILED,
							analyticsSource: SOURCE_JIRA_LINKED_ISSUES,
							application: application ?? null,
							edition: edition ?? null,
						},
					});
				}
				trackOrLogClientError(
					'issue.issue-links.delete-issue-link-error',
					'Error when removing issue link',
					error,
				);
				return Observable.of(deleteLinkedIssueFailure(linkId));
			});
	});
