// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	childLinkButton: {
		id: 'issue.views.common.child-issues-panel.add-existing-issue.child-link-button',
		defaultMessage: 'Add',
		description: 'Button label to link child issue',
	},
	childCancelButton: {
		id: 'issue.views.common.child-issues-panel.add-existing-issue.child-cancel-button',
		defaultMessage: 'Cancel',
		description: 'Button label to cancel linking of child issue',
	},
	ariaLabelForAddExistingIssue: {
		id: 'issue.views.common.child-issues-panel.add-existing-issue.aria-label',
		defaultMessage: 'add existing issue',
		description: 'Aria label to add existing issue',
	},
	ariaLabelForAddExistingIssueIssueTermRefresh: {
		id: 'issue.views.common.child-issues-panel.add-existing-issue.aria-label-issue-term-refresh',
		defaultMessage: 'add existing issue',
		description: 'Aria label to add existing issue',
	},
});
