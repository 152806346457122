import { defineMessages, useIntl } from '@atlassian/jira-intl';
import automationIconLegacy from './assets/automation-icon-legacy.svg';

/**
 * An admittedly, hacky way, to leverage the same UI that connect context panel uses.
 */
const CONTEXT_PANEL_GROUP_HARDCODED_PROPS = {
	icon: { url: automationIconLegacy },
	status: null,
	groupId: 'automation-issue-audit-log-panel',
	extension: undefined,
} as const;

const messages = defineMessages({
	panelTitle: {
		id: 'automation-issue-audit-log-panel.panel-title',
		defaultMessage: 'Automation',
		description:
			"The title of the panel. It's always shown and indicates that the panel is about Automation. This panel can be expanded/collapsed. The experience within is about showing automation rules which have been ran and have affected this issue.",
	},
	panelSubtitle: {
		id: 'automation-issue-audit-log-panel.panel-subtitle',
		defaultMessage: 'Rule executions',
		description:
			'This is text shown next to the title when the panel is minimised. It gives a brief description of what the panel is about. Then panel is about showing automation rule executions which have acted on this issue.',
	},
});

/**
 * Returns the props for the Automation Issue Audit Log panel specifically for the {@link ContextPanelGroup} component.
 * Text has been internationalized.
 */
const useI18nContextPanelAutomationProps = () => {
	const { formatMessage } = useIntl();
	return {
		...CONTEXT_PANEL_GROUP_HARDCODED_PROPS,
		title: formatMessage(messages.panelTitle),
		subtitle: formatMessage(messages.panelSubtitle),
	};
};

export default useI18nContextPanelAutomationProps;
