import type { OriginalEstimateValue } from '@atlassian/jira-issue-field-original-estimate-inline-edit/src/services/index.tsx';

export type EstimateValue = number | null;
const SECONDS_PER_MINUTE = 60;

export const validateOriginalEstimate = (value: unknown): value is EstimateValue =>
	value === null || (typeof value === 'number' && value > 0 && Number.isInteger(value));

export const transformSetValueOriginalEstimate = (
	value: OriginalEstimateValue,
): OriginalEstimateValue => {
	if (!value) {
		return null;
	}
	return value * SECONDS_PER_MINUTE;
};

export const transformGetValueOriginalEstimate = (
	value: OriginalEstimateValue,
): OriginalEstimateValue => {
	if (!value) {
		return null;
	}

	return value / SECONDS_PER_MINUTE;
};
