import { fg } from '@atlassian/jira-feature-gating';
import type {
	SmartWebLink,
	PersistedWebLink,
	WebLinks,
} from '@atlassian/jira-issue-view-common-types/src/web-links-type.tsx';
import {
	FETCH_ISSUE_SUCCESS,
	REFRESH_ISSUE_SUCCESS,
	type FetchIssueSuccessAction,
	type RefreshIssueSuccessAction,
} from '@atlassian/jira-issue-view-store/src/common/actions/issue-fetch-actions.tsx';
import {
	type FetchIssueRemoteDataSuccess,
	FETCH_ISSUE_REMOTE_DATA_SUCCESS,
} from '../../actions/issue-remote-data-actions.tsx';
import {
	type ShowCreateWebLinkFormAction,
	type HideCreateWebLinkFormAction,
	type DeleteWebLinkSuccess,
	type DeleteWebLinkRequest,
	type DeleteWebLinkFailed,
	type DeleteWebLinkCancel,
	type DeleteOptimisticWebLink,
	type CreateWebLinkRequest,
	type CreateWebLinkSuccess,
	type CreateWebLinkFailed,
	type CreateWebLinkRetry,
	SHOW_CREATE_WEB_LINK_FORM,
	HIDE_CREATE_WEB_LINK_FORM,
	DELETE_WEB_LINK_SUCCESS,
	DELETE_WEB_LINK_REQUEST,
	DELETE_WEB_LINK_FAILED,
	CANCEL_FAILED_WEB_LINK_DELETION,
	DELETE_OPTIMISTIC_WEB_LINK,
	CREATE_WEB_LINK_REQUEST,
	CREATE_WEB_LINK_SUCCESS,
	CREATE_WEB_LINK_FAILED,
	CREATE_WEB_LINK_LIMIT_EXCEEDED,
	CREATE_WEB_LINK_RETRY,
} from '../../actions/web-links-actions.tsx';

type State = WebLinks;

type Action =
	| FetchIssueRemoteDataSuccess
	| ShowCreateWebLinkFormAction
	| HideCreateWebLinkFormAction
	| DeleteWebLinkSuccess
	| DeleteWebLinkRequest
	| DeleteWebLinkFailed
	| DeleteWebLinkCancel
	| DeleteOptimisticWebLink
	| CreateWebLinkRequest
	| CreateWebLinkSuccess
	| CreateWebLinkFailed
	| FetchIssueSuccessAction
	| RefreshIssueSuccessAction
	| CreateWebLinkRetry;

export const initialState: State = {
	isLinkingEnabled: true, // Default to true https://jdog.jira-dev.com/browse/BENTO-3775
	linkCount: 0,
	links: [],
	createClickCount: 0,
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: State = initialState, action: Action): State => {
	switch (action.type) {
		case REFRESH_ISSUE_SUCCESS:
		case FETCH_ISSUE_SUCCESS: {
			if (fg('jiv-19125-fetch-remote-links-in-interactive-query')) {
				const { remoteLinks = {} } = action.payload;
				const { webLinks } = remoteLinks;
				const { linkCount = 0, links = [] } = webLinks || {};

				return {
					...state,
					isLinkingEnabled: !!remoteLinks.isLinkingEnabled,
					linkCount,
					links,
				};
			}

			return state;
		}

		case FETCH_ISSUE_REMOTE_DATA_SUCCESS: {
			const { remoteLinks = {} } = action.payload;
			const { webLinks } = remoteLinks;
			const { linkCount = 0, links = [] } = webLinks || {};

			return {
				...state,
				isLinkingEnabled: !!remoteLinks.isLinkingEnabled,
				linkCount,
				links,
			};
		}

		case SHOW_CREATE_WEB_LINK_FORM: {
			return {
				...state,
				createClickCount: state.createClickCount + 1,
			};
		}

		case HIDE_CREATE_WEB_LINK_FORM: {
			return {
				...state,
				createClickCount: initialState.createClickCount,
			};
		}

		case DELETE_WEB_LINK_REQUEST: {
			const { id } = action.payload;
			const links = state.links.map((link) =>
				link.id === id ? { ...link, isDeleting: true, deleteFailed: false } : link,
			);
			return {
				...state,
				links,
			};
		}

		case DELETE_OPTIMISTIC_WEB_LINK:
		case DELETE_WEB_LINK_SUCCESS: {
			const { id } = action.payload;

			if (id && state.links) {
				const links = state.links.filter((link) => link.id !== id);
				const linkCount = links.length;
				return {
					...state,
					linkCount,
					links,
				};
			}
			return state;
		}

		case DELETE_WEB_LINK_FAILED: {
			const { id } = action.payload;
			const links = state.links.map((link) =>
				link.id === id ? { ...link, isDeleting: false, deleteFailed: true } : link,
			);

			return {
				...state,
				links,
			};
		}

		case CANCEL_FAILED_WEB_LINK_DELETION: {
			const { id } = action.payload;
			const links = state.links.map((link) => {
				if (link.id !== id) {
					return link;
				}
				const updatedLink: PersistedWebLink = {
					id: link.id,
					href: link.href,
					linkText: link.linkText,
					iconUrl: link.iconUrl,
					resolved: null,
					relationship: null,
					applicationName: null,
					applicationType: null,
				};
				return updatedLink;
			});

			return {
				...state,
				links,
			};
		}

		case CREATE_WEB_LINK_REQUEST: {
			const { optimisticId, href, linkText, iconUrl, application } = action.payload;
			// Note: Add the optimistic link to the TOP of the list, in the same order we fetch the web links in
			return {
				...state,
				linkCount: state.linkCount + 1,
				links: [
					{
						id: optimisticId,
						smartLinkId: optimisticId,
						href,
						linkText,
						iconUrl,
						isCreating: false,
						isCreateFailed: false,
						resolved: null,
						relationship: null,
						applicationName: application?.name || null,
						applicationType: application?.type || null,
					},
					...state.links,
				],
			};
		}

		case CREATE_WEB_LINK_SUCCESS: {
			const { optimisticId, webLink } = action.payload;
			const links = state.links.map((link) => {
				if (link.id === optimisticId) {
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					(webLink as SmartWebLink).smartLinkId = (link as SmartWebLink).smartLinkId;
					return webLink;
				}
				return link;
			});

			return {
				...state,
				links,
			};
		}
		case CREATE_WEB_LINK_LIMIT_EXCEEDED: {
			const { optimisticId } = action.payload;
			const links = state.links.map((link) =>
				link.id === optimisticId
					? {
							...link,
							isCreateFailedLimitExceeded: true,
						}
					: link,
			);

			return {
				...state,
				links,
			};
		}

		case CREATE_WEB_LINK_FAILED: {
			const { optimisticId } = action.payload;
			const links = state.links.map((link) =>
				link.id === optimisticId
					? {
							...link,
							isCreating: false,
							isCreateFailed: true,
						}
					: link,
			);

			return {
				...state,
				links,
			};
		}

		case CREATE_WEB_LINK_RETRY: {
			const { optimisticId } = action.payload;
			const links = state.links.map((link) =>
				link.id === optimisticId
					? {
							...link,
							isCreating: true,
							isCreateFailed: false,
						}
					: link,
			);

			return {
				...state,
				links,
			};
		}
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
