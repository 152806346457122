import React, { type ComponentType } from 'react';
import { EcosystemModuleAsync } from '@atlassian/jira-connect-utils-async/src/async.tsx';
import type { EcosystemModuleProps } from '@atlassian/jira-connect-utils/src/common/types.tsx';
import { useOnDemandIssueRefreshTime } from '@atlassian/jira-issue-refresh-service/src/services/main.tsx';
import { safeComponent } from '@atlassian/jira-issue-view-common-utils/src/safe-component/index.tsx';

type PropsWithRefreshedOn = EcosystemModuleProps & {
	refreshedOn: number; // This prop is used to force a re-render of the connect iframe.
};

const EcosystemModuleBase = (props: PropsWithRefreshedOn) => {
	const { appKey, moduleKey, options, appName, refreshedOn, location } = props;

	const key = refreshedOn
		? `connect-iFrame-${appKey}-${moduleKey}-${refreshedOn}`
		: `connect-iFrame-${appKey}-${moduleKey}`;

	return (
		<EcosystemModuleAsync
			key={key}
			appKey={appKey}
			moduleKey={moduleKey}
			options={options}
			appName={appName}
			location={location}
		/>
	);
};

EcosystemModuleBase.defaultProps = { refreshedOn: 0 };

const EcosystemModuleBaseWithoutRefresh = (props: EcosystemModuleProps) => (
	<EcosystemModuleBase {...props} />
);
export const EcosystemModuleWithoutRefresh: ComponentType<EcosystemModuleProps> = safeComponent()(
	EcosystemModuleBaseWithoutRefresh,
);

const EcosystemModuleWithRefresh = (props: EcosystemModuleProps) => {
	const refreshedOn = useOnDemandIssueRefreshTime();

	return <EcosystemModuleBase {...props} refreshedOn={refreshedOn} />;
};

export default EcosystemModuleWithRefresh;
