import { createSelector } from 'reselect';
import uniq from 'lodash/uniq';
import type { ProjectFieldValue } from '@atlassian/jira-polaris-domain-field/src/field/project/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import { getProperties } from '../index.tsx';

export const createGetProject = (localIssueId: LocalIssueId | undefined) =>
	createSelector(getProperties, (properties): ProjectFieldValue | undefined => {
		if (localIssueId === undefined) {
			return;
		}
		return properties.projects?.project?.[localIssueId];
	});

export const getAllIssuesProjectIds = createSelector(getProperties, (properties): ProjectId[] =>
	uniq(Object.values(properties.projects?.project || {}).map(({ id }) => id)),
);
