import type { IssueViewRealtimeEventType } from '@atlassian/jira-issue-shared-types/src/common/types/realtime-support.tsx';
import {
	ISSUE_UPDATED_EVENT,
	ISSUE_COMMENTED_EVENT,
} from '@atlassian/jira-realtime/src/common/constants/events.tsx';

export const EVENTS_TO_LISTEN: IssueViewRealtimeEventType[] = [
	ISSUE_UPDATED_EVENT,
	ISSUE_COMMENTED_EVENT,
];

export const REALTIME_EVENTS_ANALYTICS_SAMPLE_RATE = 100;
