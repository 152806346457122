import { useEffect, useMemo } from 'react';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { useUserSubscriber } from '@atlassian/jira-user-services/src/main.tsx';

export const useCurrentUser = () => {
	const [{ data, error, loading }, { fetch }] = useUserSubscriber();
	const { atlassianAccountId } = useTenantContext();

	useEffect(() => {
		if (atlassianAccountId) {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			fetch({ params: { accountId: atlassianAccountId as string }, header: {} }, false);
		}
	}, [atlassianAccountId, fetch]);

	return useMemo(() => ({ data, error, loading }), [data, error, loading]);
};

export const useCurrentUserTimezone = (): string => {
	const { data, error, loading } = useCurrentUser();

	if (loading || error) {
		return '';
	}

	return data?.timeZone || '';
};

export const useCurrentUserAccountId = (): AccountId | null => {
	const { atlassianAccountId } = useTenantContext();

	return atlassianAccountId;
};

export const useCurrentUserAvatar = () => {
	const { data, error, loading } = useCurrentUser();

	if (loading || error) {
		return undefined;
	}

	return data?.avatarUrls['32x32'];
};

export const useCurrentUserDisplayName = () => {
	const { data, error, loading } = useCurrentUser();

	if (loading || error) {
		return undefined;
	}

	return data?.displayName;
};
