import React, { type FormEvent, type KeyboardEvent, useCallback, useRef } from 'react';
import { styled } from '@compiled/react';
import { FormControls } from './form-controls/index.tsx';
import type { Props } from './types.tsx';

/**
 * `<form/>` to be rendered within the edit view of an inline edit component to allow the user to submit or cancel
 * changes and transition to the read view.
 */
export const Form = ({
	cancelButtonLabel,
	children,
	confirmButtonLabel,
	context,
	hideActionButtons,
	keepEditViewOpenOnBlur,
}: Props) => {
	const { onCancel, onConfirm } = context;
	const wasFocusReceivedSinceLastBlurRef = useRef(false);
	const formRef = useRef<HTMLFormElement | null>(null);

	/** If keepEditViewOpenOnBlur prop is set to false, will call confirmIfUnfocused() which
	 *  confirms the value, if the focus is not transferred to the action buttons
	 *
	 *  When you're in `editing` state, the focus will be on the input field. And if you use keyboard
	 *  to navigate to `submit` button, this function will be invoked. Then function `onEditViewWrapperFocus`
	 *  will be called, the timeout used here is making sure `onEditViewWrapperFocus` is always called before
	 *  `autoSubmitWhenBlur`.
	 *
	 *  There are two paths here the function can be triggered:
	 *
	 *  - focus on input first, and then use keyboard to `submit`
	 *  - focus on input first, and then click anywhere else on the page (outside of edit view wrapper) to `submit` (auto save)
	 */
	const onBlur = useCallback(() => {
		if (!keepEditViewOpenOnBlur) {
			wasFocusReceivedSinceLastBlurRef.current = false;
			setTimeout(() => {
				// Check the form hasn't unmounted before attempting to auto-submit
				!wasFocusReceivedSinceLastBlurRef.current && formRef.current && onConfirm(true);
			}, 0);
		}
	}, [keepEditViewOpenOnBlur, onConfirm]);

	/** Gets called when focus is transferred to the editView, or action buttons
	 *
	 * There are three paths here the function can be called:
	 *
	 * - when a user click the `editView`
	 * - when a user use keyboard to tab into `editView`
	 * - when a user use keyboard to tab into `submit` when they were on input field
	 */
	const onFocus = useCallback(() => {
		wasFocusReceivedSinceLastBlurRef.current = true;
	}, []);

	const onSubmit = useCallback(
		(event: FormEvent<HTMLElement> | KeyboardEvent<HTMLElement>) => {
			event.preventDefault();
			onConfirm(false);
		},
		[onConfirm],
	);

	const onKeyDown = useCallback(
		(event: KeyboardEvent<HTMLElement>) => {
			if (event.key === 'Enter' && (event.ctrlKey || event.metaKey)) {
				onSubmit(event);
			}
			if (event.key === 'Esc' || event.key === 'Escape') {
				onCancel(false);
			}
		},
		[onCancel, onSubmit],
	);

	return (
		<StyledForm
			ref={formRef}
			role="presentation"
			onBlur={onBlur}
			onFocus={onFocus}
			onKeyDown={onKeyDown}
			onSubmit={onSubmit}
		>
			{children}
			<FormControls
				cancelButtonLabel={cancelButtonLabel}
				confirmButtonLabel={confirmButtonLabel}
				context={context}
				hideActionButtons={hideActionButtons}
			/>
		</StyledForm>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledForm = styled.form({
	maxWidth: '100%',
	position: 'relative',
});
