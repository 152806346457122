export const EXPRESSION_TEMPLATE = 'expr';

/**
 * Formula to wrap a subformula that was built by parsing an expression langauge.
 */
export interface GenericExpressionFormula<TRecursiveFormula> {
	template: typeof EXPRESSION_TEMPLATE;
	parameters: {
		expression: string;
		formula: TRecursiveFormula;
	};
}
