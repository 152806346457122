/**
 * @generated SignedSource<<975170e91daa818ce2bf2ced6fa58557>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type DeploymentState = "CANCELLED" | "FAILED" | "IN_PROGRESS" | "PENDING" | "ROLLED_BACK" | "SUCCESSFUL" | "UNKNOWN" | "%future added value";
export type DevOpsEnvironmentCategory = "DEVELOPMENT" | "PRODUCTION" | "STAGING" | "TESTING" | "UNMAPPED" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type statusIcon_softwareReleasesVersionDetailIssueList$data = {
  readonly deploymentEnvironment: {
    readonly category: DevOpsEnvironmentCategory | null | undefined;
  } | null | undefined;
  readonly deploymentState: DeploymentState | null | undefined;
  readonly " $fragmentType": "statusIcon_softwareReleasesVersionDetailIssueList";
};
export type statusIcon_softwareReleasesVersionDetailIssueList$key = {
  readonly " $data"?: statusIcon_softwareReleasesVersionDetailIssueList$data;
  readonly " $fragmentSpreads": FragmentRefs<"statusIcon_softwareReleasesVersionDetailIssueList">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "statusIcon_softwareReleasesVersionDetailIssueList",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "deploymentState"
    },
    {
      "concreteType": "DevOpsEnvironment",
      "kind": "LinkedField",
      "name": "deploymentEnvironment",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "category"
        }
      ]
    }
  ],
  "type": "DevOpsSummarisedDeployments"
};

(node as any).hash = "f151e3f42403e9b74c8517a51ce0be46";

export default node;
