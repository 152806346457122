import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	acceptAll: {
		id: 'ai-related-resources.related-resources-draft-list.accept-all',
		defaultMessage: 'Accept all',
		description: 'Text for button which accepts all the ai provided suggestions',
	},
	close: {
		id: 'ai-related-resources.related-resources-draft-list.close',
		defaultMessage: 'Close',
		description: 'Text for button which closes the ai provided suggestions',
	},
	giveFeedback: {
		id: 'ai-related-resources.related-resources-draft-list.give-feedback',
		defaultMessage: 'Give feedback',
		description: 'Text for button used to provide feedback',
	},
	accept: {
		id: 'ai-related-resources.related-resources-draft-list.accept',
		defaultMessage: 'Accept',
		description: 'Text for button used to provide feedback',
	},
	remove: {
		id: 'ai-related-resources.related-resources-draft-list.remove',
		defaultMessage: 'Remove',
		description: 'Text for button used to provide feedback',
	},
});
