/**
 * @generated SignedSource<<ddc2cadf7150eb840bac96328f39a8a6>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type AccountStatus = "active" | "closed" | "inactive" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type singleUserPicker_issueFieldSingleUserPickerReadviewFull_SingleUserPickerReadView_fragmentRef$data = {
  readonly user: {
    readonly accountStatus: AccountStatus;
    readonly name: string;
    readonly picture: AGG$URL;
  } | null | undefined;
  readonly " $fragmentType": "singleUserPicker_issueFieldSingleUserPickerReadviewFull_SingleUserPickerReadView_fragmentRef";
};
export type singleUserPicker_issueFieldSingleUserPickerReadviewFull_SingleUserPickerReadView_fragmentRef$key = {
  readonly " $data"?: singleUserPicker_issueFieldSingleUserPickerReadviewFull_SingleUserPickerReadView_fragmentRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"singleUserPicker_issueFieldSingleUserPickerReadviewFull_SingleUserPickerReadView_fragmentRef">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "singleUserPicker_issueFieldSingleUserPickerReadviewFull_SingleUserPickerReadView_fragmentRef",
  "selections": [
    {
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "name"
        },
        {
          "kind": "ScalarField",
          "name": "picture"
        },
        {
          "kind": "ScalarField",
          "name": "accountStatus"
        }
      ]
    }
  ],
  "type": "JiraSingleSelectUserPickerField"
};

(node as any).hash = "83f7121fa6b0005e4cbdae4ef31fe6bb";

export default node;
