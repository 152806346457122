import React, { Component, type MouseEvent } from 'react';
import { styled } from '@compiled/react';
import { Text } from '@atlaskit/primitives';
import type { ActionMeta } from '@atlassian/jira-common-components-picker/src/model.tsx';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import { useRapidViewId } from '@atlassian/jira-issue-context-service/src/main.tsx';
import Menu from '@atlassian/jira-issue-field-sprint/src/ui/edit/menu/index.tsx';
import {
	getCheckboxValueFromLocalStorage,
	setCheckBoxValueInLocalStorage,
	EDIT_SPRINT_CHECKBOX_CACHE_KEY,
} from '@atlassian/jira-issue-field-sprint/src/ui/utils.tsx';
import { RichOptionView } from '@atlassian/jira-issue-internal-field-select/src/common/custom-format/format-option-label/index.tsx';
import type { FormatOptionLabelMeta } from '@atlassian/jira-issue-internal-field-select/src/common/select-inline-edit/select-field/index.tsx';
import type {
	ServerSuggestions,
	Option,
	SelectValueShape,
} from '@atlassian/jira-issue-internal-field-select/src/common/select-inline-edit/select-field/types.tsx';
import SingleSelectInlineEdit, {
	type Props as SingleSelectInlineEditProps,
} from '@atlassian/jira-issue-internal-field-select/src/single-select-inline-edit/index.tsx';
import { getRapidViewUrl } from '@atlassian/jira-issue-view-common/src/legacy/urls.tsx';
import { isBacklogSidebar } from '@atlassian/jira-issue-view-services/src/issue/issue-view-mode.tsx';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils/src/index.tsx';
import type { ClosedSprint } from '@atlassian/jira-shared-types/src/rest/jira/closed-sprint.tsx';
import type { Route } from '@atlassian/react-resource-router';
import ClosedSprintsView from './closed-sprints/view.tsx';

type Props = SingleSelectInlineEditProps & {
	getDataFromCache?: (intl: IntlShape) => Promise<ServerSuggestions>;
	fetchSuggestions: (
		query: string,
		restrictSprintSuggestion?: boolean,
		sessionId?: string,
	) => Promise<ServerSuggestions>;
	baseUrl: string;
	closedSprints: ClosedSprint[];
	closedSprintsLabel: string | null;
	projectKey: string;
	rapidViewId?: number;
	route: Route;
	onSprintClicked: (arg1: number | string) => void;
	intl: IntlShape;
};
type State = {
	restrictSprintSuggestion: boolean;
	query: string;
	data: ServerSuggestions;
	loading: boolean;
	error: boolean;
};

// eslint-disable-next-line jira/react/no-class-components
export class SprintInlineEditView extends Component<Props, State> {
	static displayName = 'SprintInlineEditView';

	static defaultProps = {
		closedSprints: [],
		closedSprintsLabel: null,
	};

	state = {
		restrictSprintSuggestion: getCheckboxValueFromLocalStorage(EDIT_SPRINT_CHECKBOX_CACHE_KEY),
		query: '',
		data: [],
		loading: false,
		error: false,
	};

	componentDidMount = () => {
		this.getDataFromCache();
	};

	sessionId = 'default-session-id';

	getDataFromCache = () => {
		this.props.getDataFromCache?.(this.props.intl).then((data: ServerSuggestions) => {
			this.setState({ data, loading: false });
		});
	};

	onSprintClick = (event: MouseEvent<HTMLAnchorElement>) => {
		const { rapidViewId, value, onSprintClicked } = this.props;

		if (rapidViewId !== undefined && value) {
			onSprintClicked(value.value);
		}
		event.stopPropagation();
	};

	onEditRequest = () => {
		this.props.onEditRequest();
	};

	onChange = (selectedValue?: SelectValueShape | null, _actionMeta?: ActionMeta<Option>): void => {
		this.props.onChange(selectedValue);
	};

	onConfirm = () => {
		this.props.onConfirm();
	};

	onCancel = () => {
		this.props.onCancel();
	};

	// go/jfe-eslint

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onDataRequest = ({ isInitial: _ }: { isInitial: boolean }): void => {};

	// go/jfe-eslint
	// @ts-expect-error - TS2741 - Property 'isInitial' is missing in type '{}' but required in type '{ isInitial: boolean; }'.
	onDataLoaded = ({
		isInitial: _,
	}: {
		isInitial: boolean;
		// eslint-disable-next-line @typescript-eslint/no-empty-function
	} = {}): void => {};

	renderSprintContent = () => {
		const { baseUrl, rapidViewId, value, route } = this.props;

		const isIssueViewInBacklogSideBar =
			isBacklogSidebar(route) && typeof rapidViewId === 'number' && rapidViewId > 0;
		if (isIssueViewInBacklogSideBar) {
			// Don't show sprint link while in backlog side bar.
			return value && value.content;
		}

		// Show sprint as link while in dialog or full issue view
		return (
			// this needs to be anchor, so bitbucket entry index will add target="_blank" to element.
			// eslint-disable-next-line jsx-a11y/anchor-is-valid
			<a
				href={
					rapidViewId == null && value
						? getRapidViewUrl(baseUrl, value.value) // issue in full page view
						: undefined
				}
				onClick={this.onSprintClick}
			>
				{value && value.content}
			</a>
		);
	};

	renderReadView = () => {
		const { value, noValueText, closedSprints, closedSprintsLabel, isMobile } = this.props;

		const sprintView = value ? (
			this.renderSprintContent()
		) : (
			<Text color="color.text.subtlest">{noValueText}</Text>
		);

		return (
			<ReadWrapper>
				{sprintView}

				{closedSprints.length > 0 && (
					<ClosedSprintsView
						closedSprints={closedSprints}
						closedSprintsLabel={closedSprintsLabel}
						shouldHideLinks={isMobile}
					/>
				)}
			</ReadWrapper>
		);
	};

	// go/jfe-eslint

	formatOptionLabel = (option: Option, { context }: FormatOptionLabelMeta) => {
		if (context === 'menu') {
			return <RichOptionView option={option} />;
		}

		return option.label;
	};

	fetchSuggestions = (query: string, sessionId?: string) => {
		if (sessionId !== null && sessionId !== undefined && sessionId !== '') {
			this.sessionId = sessionId;
		}
		this.setState({ query });
		return this.props
			.fetchSuggestions(query, this.state.restrictSprintSuggestion, this.sessionId)
			.then((data) => {
				this.setState({ data });
				return data;
			});
	};

	onCheckboxClick = async () => {
		const { query, restrictSprintSuggestion } = this.state;
		this.setState(
			{
				restrictSprintSuggestion: !restrictSprintSuggestion,
				loading: true,
			},
			() => {
				this.fetchSuggestions(query)
					.then((data) => {
						this.setState({ data, loading: false, error: false });
					})
					.catch(() => {
						this.setState({ data: [], loading: false, error: true });
					});
			},
		);
		setCheckBoxValueInLocalStorage(EDIT_SPRINT_CHECKBOX_CACHE_KEY, !restrictSprintSuggestion);
	};

	render() {
		const {
			baseUrl,
			closedSprints,
			closedSprintsLabel,
			rapidViewId,
			onSprintClicked,
			intl,
			getDataFromCache,
			route,
			projectKey,
			...selectProps
		} = this.props;
		const { data, loading, error } = this.state;
		const initialData = { data, loading, error };
		const componentsProps = {
			isChecked: this.state.restrictSprintSuggestion,
			onChange: this.onCheckboxClick.bind(this),
			projectKey,
		};

		return (
			<SingleSelectInlineEdit
				{...selectProps}
				fetchSuggestions={this.fetchSuggestions}
				debounceFetchSuggestionsTime={300}
				initialData={initialData}
				onEditRequest={this.onEditRequest}
				onChange={this.onChange}
				onConfirm={this.onConfirm}
				onCancel={this.onCancel}
				// @ts-expect-error - TS2322 - Type '{ isChecked: boolean; onChange: () => Promise<void>; projectKey: string; } | undefined' is not assignable to type '({ isChecked: boolean; onChange: () => Promise<undefined> | undefined; } & { isChecked: boolean; onChange: () => Promise<undefined> | undefined; } & { isChecked: boolean; onChange: () => Promise<...> | undefined; }) | undefined'.
				componentsProps={projectKey ? componentsProps : undefined}
				components={projectKey ? { Menu } : undefined}
				onDataRequest={this.onDataRequest}
				onDataLoaded={this.onDataLoaded}
				renderReadView={this.renderReadView}
				// @ts-expect-error - TS2322 - Type '(option: Option, { context }: FormatOptionLabelMeta) => string | JSX.Element' is not assignable to type 'FormatOptionLabel'.
				formatOptionLabel={this.formatOptionLabel}
				hasAutocomplete
				optionValuesSafeForAnalytics
			/>
		);
	}
}

const SprintInlineEditViewWithHooks = ({ rapidViewId: rapidViewIdOld, ...props }: Props) => {
	const rapidViewId = useRapidViewId();
	const route = useCurrentRoute();

	return <SprintInlineEditView {...props} route={route} rapidViewId={rapidViewId} />;
};

SprintInlineEditViewWithHooks.defaultProps = {
	closedSprints: [],
	closedSprintsLabel: null,
};

export default SprintInlineEditViewWithHooks;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReadWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	width: '100%',
});
