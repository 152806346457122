import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { getNumberFieldMappings } from '../../selectors/fields.tsx';
import type { State, Props } from '../../types.tsx';

export const updateCheckboxOrRatingNumberFieldMapping =
	(field: Field) =>
	({ getState, setState }: StoreActionApi<State>, props: Props) => {
		if (field.type !== FIELD_TYPES.CHECKBOX && field.type !== FIELD_TYPES.RATING) {
			return;
		}

		const state = getState();
		const { ids, properties } = state;

		const fieldMappings = getNumberFieldMappings(state, props);
		const fieldMapping = fieldMappings[field.key];

		if (!fieldMapping || ids.length === 0) {
			return;
		}

		const updatedProperties = {
			...properties,
		};

		for (const id of ids) {
			fieldMapping.setMutable(updatedProperties, id, fieldMapping.valueAccessor(state, props, id));
		}

		setState({
			properties: updatedProperties,
		});
	};
