import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	all: {
		id: 'linked-alerts.services.actions.fetch-saved-searches.all',
		defaultMessage: 'All',
		description: 'Default dropdown option for showing all alerts.',
	},
	open: {
		id: 'linked-alerts.services.actions.fetch-saved-searches.open',
		defaultMessage: 'Open',
		description: 'Dropdown option for showing all open alerts.',
	},
	closed: {
		id: 'linked-alerts.services.actions.fetch-saved-searches.closed',
		defaultMessage: 'Closed',
		description: 'Dropdown option for showing all closed alerts.',
	},
	unAcked: {
		id: 'linked-alerts.services.actions.fetch-saved-searches.un-acked',
		defaultMessage: "Un'Acked",
		description: 'Dropdown option for showing unacknowledged alerts.',
	},
	notSeen: {
		id: 'linked-alerts.services.actions.fetch-saved-searches.not-seen',
		defaultMessage: 'Not seen',
		description: 'Dropdown option for showing alerts that have not yet been seen.',
	},
	assigned: {
		id: 'linked-alerts.services.actions.fetch-saved-searches.assigned',
		defaultMessage: 'Assigned to me',
		description: 'Dropdown option for showing alerts assigned to the user.',
	},
	errorTitle: {
		id: 'linked-alerts.services.actions.fetch-saved-searches.error-title',
		defaultMessage: 'We could not load your saved searches',
		description: 'The title text in the status flag if linked alerts can not be fetched.',
	},
});
