// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	deleteAll: {
		id: 'attachments.heading.delete-all',
		defaultMessage: 'Delete all',
		description: 'The dropdown item to delete all attachments of an issue',
	},
});
