/** @jsx jsx */
import React, { type ReactNode } from 'react';
import { styled, css, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';

export const readViewContainerSelectorName =
	'jira-issue-view-radio-inline-edit-view-read-view-container';
export const READ_VIEW_CONTAINER_COMPONENT_SELECTOR = `[data-component-selector="${readViewContainerSelectorName}"]`;

const inlineEditContainerStyles = css({
	width: '100%',
	marginLeft: token('space.negative.050', '-4px'),
	marginTop: token('space.negative.100', '-8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& div[data-read-view-fit-container-width]': {
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		minHeight: '32px',
		paddingTop: 0,
		paddingBottom: 0,
		paddingLeft: token('space.075', '6px'),
		paddingRight: 0,
	},
});

const InlineEditContainerNew = ({ children, ...props }: { children: ReactNode }) => (
	<div css={inlineEditContainerStyles} {...props}>
		{children}
	</div>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ReadViewContainer = styled.div({
	display: 'flex',
	flex: '1 1 auto',
	wordBreak: 'break-word',
	position: 'relative',
	font: token('font.body'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const InlineEditContainerOld = styled.div({
	width: '100%',
	marginLeft: token('space.negative.100', '-8px'),
	marginTop: token('space.negative.100', '-8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& div[data-read-view-fit-container-width]': {
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		minHeight: '32px',
		paddingTop: 0,
		paddingBottom: 0,
		paddingLeft: token('space.075', '6px'),
		paddingRight: 0,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const InlineEditContainer = componentWithFG(
	'issue_view_field_config_edit',
	InlineEditContainerNew,
	InlineEditContainerOld,
);
