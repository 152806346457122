import {
  Query,
  OrderBy,
  AstNode,
  OrderByField,
  JastVisitor,
  JastBuilder,
  Jast,
  FieldValueOperator,
  ORDER_BY_DIRECTION_DESC,
  COMPOUND_CLAUSE_AND,
  COMPOUND_CLAUSE_OR,
  CompoundClause,
  FieldValueClause,
  FieldValueOperand,
  OPERAND_TYPE_FIELD_VALUE,
  FIELD_OPERATOR_EQUALS,
  FIELD_OPERATOR_IN,
  OPERAND_TYPE_LIST,
  ORDER_BY_DIRECTION_ASC
} from '@atlassiansox/jql-ast';

export interface Field {
  key: string;
  name: string;
  defaultSortOrder: Direction;
}

type Direction = 'ASC' | 'DESC';

interface Sort {
  field: Field;
  direction: Direction;
}

interface Filter {
  field: Field;
  disjuncts: FieldValueOperand[];
}

type FieldProvider = (name: string) => Field | undefined;

interface ConjunctionIndex {
  [key: string]: Filter;
}

class BasicJQLVisitor implements JastVisitor<void> {
  constructor(private onBehalfOf: BasicJQL, private fields: FieldProvider) {}

  simple: boolean = true;
  why?: string;

  setAdvanced = (why: string) => {
    this.simple = false;
    if (!this.why) {
      this.why = why;
    }
  };

  visitOrderBy = (orderBy: OrderBy) => {
    orderBy.fields.forEach(f => f.accept(this));
  };

  visitFieldValueOperator = (op: FieldValueOperator) => {
    return;
  };

  inConjunction: boolean = false;
  inDisjunction: boolean = false;
  disjunctionOver: Field | undefined = undefined;
  conjuncts: ConjunctionIndex = {};

  visitFieldValueClause = (fv: FieldValueClause) => {
    const f = this.fields(fv.field.name);
    if (!f) {
      this.setAdvanced(`field ${fv.field.name} is not known`);
      return;
    }

    if (this.inDisjunction) {
      if (this.disjunctionOver) {
        if (this.disjunctionOver.key !== f.key) {
          this.setAdvanced(`unsupported OR across different fields`);
          return;
        }
      } else {
        this.disjunctionOver = f;
      }
    }

    if (!fv.operator) {
      this.setAdvanced(`unsupported lack of operator`);
      return;
    }

    let multiValue: boolean = false;
    if (fv.operator.value === FIELD_OPERATOR_IN) {
      multiValue = true;
    } else if (fv.operator.value !== FIELD_OPERATOR_EQUALS) {
      this.setAdvanced(`unsupported operator "${fv.operator.value}"`);
      return;
    }

    const con: Filter = this.conjuncts[f.key] || { field: f, disjuncts: [] };
    this.conjuncts[f.key] = con; // there's probably JS shorthand for this getorset

    if (!fv.operand) {
      this.setAdvanced(`unsupported form of field value clause`);
      return;
    }

    if (!multiValue) {
      if (fv.operand.operandType !== OPERAND_TYPE_FIELD_VALUE) {
        // console.log(`no likey ${JSON.stringify(fv.operand)}`);
        this.setAdvanced(
          `unsupported form of field value operand for "=" operator`
        );
        return;
      }
      con.disjuncts.push(fv.operand);
    } else {
      if (fv.operand.operandType !== OPERAND_TYPE_LIST) {
        // console.log(`no likey ${JSON.stringify(fv.operand)}`);
        this.setAdvanced(
          `unsupported form of field value operand for IN operator`
        );
        return;
      }
      fv.operand.values.forEach(unitaryOperand => {
        if (unitaryOperand.operandType !== OPERAND_TYPE_FIELD_VALUE) {
          this.setAdvanced(`unsupported operand type in list`);
          return;
        }
        con.disjuncts.push(unitaryOperand);
      });
    }
  };

  visitCompoundClause = (clause: CompoundClause) => {
    if (clause.operator.value === COMPOUND_CLAUSE_AND) {
      if (this.inConjunction || this.inDisjunction) {
        this.setAdvanced('unsupported nesting of AND and OR clauses');
        return;
      }
      this.inConjunction = true;
    } else if (clause.operator.value === COMPOUND_CLAUSE_OR) {
      if (this.inDisjunction) {
        this.setAdvanced('unsupported nesting of OR within OR');
        return;
      }
      this.inDisjunction = true;
      this.disjunctionOver = undefined;
    }
    clause.clauses.forEach(c => c.accept(this));
  };

  visitOrderByField = (orderByField: OrderByField) => {
    // console.log("visit ORDER BY field", orderByField);

    const f = this.fields(orderByField.field.name);
    if (!f) {
      this.setAdvanced(`field "${orderByField.field.name}" is not known`);
      return;
    }

    let dir: Direction = f.defaultSortOrder;
    if (orderByField.direction) {
      if (orderByField.direction.value === ORDER_BY_DIRECTION_DESC) {
        dir = 'DESC';
      } else if (orderByField.direction.value === ORDER_BY_DIRECTION_ASC) {
        dir = 'ASC';
      }
    }

    this.onBehalfOf.sorts.push({
      field: f,
      direction: dir
    });
  };

  visitQuery = (node: Query) => {
    if (node.orderBy) {
      node.orderBy.accept(this);
    }
    if (node.where) {
      node.where.accept(this);
    }
  };

  visitNode = (node: AstNode) => {
    return;
    // console.log(`fallback ${JSON.stringify(node)}`);
  };
}

function jqlNameForField(f: Field): string {
  // some might be weird, like cf[10003] instead of customfield_10003,
  // so the key might not match the name
  return f.key;
}

function jqlQuotedValue(value: FieldValueOperand): string {
  return value.value;
}

function jqlForFilter(f: Filter): string[] {
  if (f.disjuncts.length > 1) {
    const parts: string[] = [jqlNameForField(f.field), ' IN ('];
    parts.push(jqlQuotedValue(f.disjuncts[0]));
    f.disjuncts.slice(1).forEach(v => {
      parts.push(', ');
      parts.push(jqlQuotedValue(v));
    });
    parts.push(')');
    return parts;
  } else {
    return [jqlNameForField(f.field), ' = ', jqlQuotedValue(f.disjuncts[0])];
  }
}

export class BasicJQL {
  filters: ConjunctionIndex = {};
  // sorts: Sort[] = [];
  valid: boolean = false;

  constructor(public sorts: Sort[] = []) {}

  setFromJQL(jql: string, fields: FieldProvider) {
    const ast: Jast = new JastBuilder().build(jql);

    const visitor = new BasicJQLVisitor(this, fields);
    const q = ast.query;
    if (q) {
      q.accept(visitor);
      if (visitor.simple) {
        this.valid = true;
        this.filters = visitor.conjuncts;
      } else {
        throw new Error(`JQL is not basic, and this is why: ${visitor.why}`);
      }
    } else {
      throw new Error(`JQL is not basic, we can't even parse it`);
    }
  }

  JQL(): string {
    return this.toString();
  }

  toString(): string {
    let fragments: string[] = [];
    let anyfilter = false;

    Object.keys(this.filters).forEach(key => {
      const f = this.filters[key];
      if (anyfilter) {
        fragments.push(' AND ');
      }
      fragments = [...fragments, ...jqlForFilter(f)];
      anyfilter = true;
    });

    if (this.sorts.length > 0) {
      if (anyfilter) {
        fragments.push(' ');
      }
      fragments.push('ORDER BY ');
      let anysort = false;
      this.sorts.forEach(s => {
        if (anysort) {
          fragments.push(', ');
        }
        fragments.push(jqlNameForField(s.field));
        if (s.direction === 'DESC') {
          fragments.push(' DESC');
        } else {
          fragments.push(' ASC');
        }
        anysort = true;
      });
    }
    return fragments.join('');
  }
}

export const parse = (
  jql: string,
  fields: FieldProvider
): BasicJQL | undefined => {
  const b = new BasicJQL();
  try {
    b.setFromJQL(jql, fields);
    return b;
  } catch {
    return undefined;
  }
};
