import React, { Component, type ReactNode } from 'react';
import debounce from 'lodash/debounce';
import sendExperienceAnalytics from '@atlassian/jira-common-experience-tracking-analytics/src/index.tsx';
import type { Attributes } from '@atlassian/jira-product-analytics-bridge';
import type { Application } from '@atlassian/jira-shared-types/src/application.tsx';
import type { ApplicationEdition } from '@atlassian/jira-shared-types/src/edition.tsx';
import Context from '../common/context/index.tsx';

type Props = {
	// The name of the experience (e.g. 'editIssue').
	experience: string;
	// The analytics source (e.g. 'full-issue', 'backlog-issue', 'bitbucket-issue').
	analyticsSource: string;
	// The application/product in which the experience is being tracked.
	application: Application | null;
	// The edition of the application/product in which the experience is being tracked.
	edition: ApplicationEdition | null;
	// The experience content.
	children: ReactNode;
};

const DEBOUNCE_DELAY = 1000;

// eslint-disable-next-line jira/react/no-class-components
export default class ExperienceTrackingProvider extends Component<Props> {
	onFailure = debounce(this.trackFailure.bind(this), DEBOUNCE_DELAY);

	trackFailure(field: string, errorMessage: string, additionalAttributes?: Attributes) {
		const { analyticsSource, experience, application, edition } = this.props;

		sendExperienceAnalytics({
			experience,
			wasExperienceSuccesful: false,
			field,
			application,
			edition,
			analyticsSource,
			additionalAttributes: {
				...additionalAttributes,
				errorMessage,
			},
		});
	}

	render() {
		return (
			// @ts-expect-error - TS2322 - Type 'DebouncedFunc<(field: string, errorMessage: string, additionalAttributes?: Attributes | undefined) => void>' is not assignable to type '(field: string) => void'.
			<Context.Provider value={{ onFailure: this.onFailure }}>
				{this.props.children}
			</Context.Provider>
		);
	}
}
