import React, { useEffect } from 'react';
import noop from 'lodash/noop';
import UserAvatarCircleIcon from '@atlaskit/icon/core/migration/person-avatar--user-avatar-circle';
import { getCommandPaletteIssueActionsPriority } from '@atlassian/jira-command-palette-common/src/common/utils/get-command-palette-issue-actions-priority/index.tsx';
import { getCommandPaletteIssueActionsHeader } from '@atlassian/jira-command-palette-common/src/common/utils/index.tsx';
import { useCommandKeywords } from '@atlassian/jira-command-palette-common/src/controllers/use-command-keywords/index.tsx';
import { CommandFieldHeader } from '@atlassian/jira-command-palette-issue-actions-common/src/common/ui/command-field-header/index.tsx';
import { COMMAND_PALETTE_REGISTRY_IDS } from '@atlassian/jira-command-palette-registry/src/common/constants/registry/index.tsx';
import { useSubscribeCommands } from '@atlassian/jira-command-palette-registry/src/controllers/use-subscribe-commands/index.tsx';
import {
	type Command,
	CommandActionType,
} from '@atlassian/jira-command-palette/src/common/types/commands/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { FIELD_TYPE_MAP } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/constants.tsx';
import { getUpdateAnalyticsFlowHelper } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/index.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { ASSIGNEE_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useProjectKey } from '@atlassian/jira-project-context-service/src/main.tsx';
import { useProjectPermissions } from '@atlassian/jira-project-permissions-service/src/main.tsx';
import { AssigneeListBody } from './assignee-list-body/index.tsx';
import { messages } from './messages.tsx';
import type { CommandPaletteAssigneeListProps } from './types.tsx';

export const CommandPaletteAssigneeList = (props: CommandPaletteAssigneeListProps) => {
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const [{ canAssignIssues }] = useProjectPermissions(projectKey);
	const subscribeCP = useSubscribeCommands();
	const { formatMessage } = useIntl();
	const { getKeywords } = useCommandKeywords();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		if (!canAssignIssues) return () => {};
		const sectionId = getCommandPaletteIssueActionsHeader(issueKey);
		const commands: Command[] = [
			{
				id: 'issue.issue-view.issue-base.assignee',
				priority: getCommandPaletteIssueActionsPriority('OLD_ISSUE_FIELDS'),
				name: formatMessage(
					fg('jira-issue-terminology-refresh-m3')
						? messages.assignIssueIssueTermRefresh
						: messages.assignIssue,
				),
				keywords: getKeywords('assignIssueSynonyms'),
				placeholder: formatMessage(messages.searchPeople),
				shortcut: 'a',
				section: sectionId,
				components: {
					LeftIcon: () => (
						<UserAvatarCircleIcon
							color="currentColor"
							label={formatMessage(
								fg('jira-issue-terminology-refresh-m3')
									? messages.assignIssueIssueTermRefresh
									: messages.assignIssue,
							)}
						/>
					),
				},
				primaryAction: {
					type: CommandActionType.COMMAND_LIST,
					components: {
						Body: (base) => <AssigneeListBody {...base} {...props} />,
						Header: (base) => <CommandFieldHeader {...base} issueKey={issueKey} />,
					},
					fallback: {
						onNoResults: {
							label: formatMessage(messages.fallbackNoResults),
						},
						onError: {
							label: formatMessage(messages.fallbackOnError),
						},
					},
					onClick: fg('one_event_rules_them_all_fg')
						? () => {
								getUpdateAnalyticsFlowHelper().fireAnalyticsStart(ASSIGNEE_TYPE, {
									analytics: createAnalyticsEvent({}),
									attributes: {
										fieldType: FIELD_TYPE_MAP[ASSIGNEE_TYPE],
										isCommandPaletteEditing: true,
									},
								});
							}
						: noop,
				},
				analytics: {
					action: 'updateAssigneeMenu',
				},
			},
		];
		const unsubscribe = subscribeCP(COMMAND_PALETTE_REGISTRY_IDS.ISSUE_FIELD_ASSIGN, commands);

		return () => {
			unsubscribe();
		};
	}, [
		subscribeCP,
		formatMessage,
		issueKey,
		canAssignIssues,
		getKeywords,
		props,
		createAnalyticsEvent,
	]);

	return null;
};
