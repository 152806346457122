import React, { useRef, useState, type SyntheticEvent, type ChangeEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { Field } from '@atlaskit/form';
import CopyIcon from '@atlaskit/icon/glyph/copy';
import Textfield from '@atlaskit/textfield';
import Tooltip from '@atlaskit/tooltip';
import { StyledButton } from '@atlassian/jira-common-components-field-copy-text-stateless/src/view.tsx';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';
import { copyTextFieldWrapperStyles, fieldWrapperStyles } from './styled.tsx';

export type CopyTextFieldProps = {
	value: string;
	delay?: number;
	id?: string;
	['aria-labelledby']?: string;
	onCopyClick:
		| ((e: SyntheticEvent<HTMLButtonElement>, analyticsEvent: UIAnalyticsEvent) => void)
		| undefined;
};

const handleTextFieldClick = ({ target }: ChangeEvent<HTMLInputElement>) => {
	// Selects the full contents of the input for single clicks. This check
	// preserves default click & drag functionality when selecting a portion of text.
	const { selectionStart, selectionEnd, value } = target;
	if (selectionStart === selectionEnd || (selectionStart === 0 && selectionEnd === value.length)) {
		target.select();
	}
};

// Exporting for test purposes
export const CopyTextField = ({
	value,
	delay = 100,
	onCopyClick,
	id,
	'aria-labelledby': ariaLabelledBy,
	'aria-label': ariaLabel, // used for testing purposes
}: CopyTextFieldProps & { ['aria-label']?: string }) => {
	let tooltipLabel;
	const { formatMessage } = useIntl();
	const inputRef = useRef<unknown>();
	const timeoutIdRef = useRef<unknown>();
	const [showCopiedTooltip, setShowCopiedTooltip] = useState(false);
	const [copyFailed, setCopyFailed] = useState(false);

	const handleCopyClick = (
		event: SyntheticEvent<HTMLButtonElement>,
		analyticsEvent: UIAnalyticsEvent,
	) => {
		onCopyClick && onCopyClick(event, analyticsEvent);
		setCopyFailed(false);

		// @ts-expect-error - TS2571 - Object is of type 'unknown'.
		inputRef && inputRef.current && inputRef.current.select();

		let success = false;

		try {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			success = document.execCommand('copy');
			setShowCopiedTooltip(true);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			setCopyFailed(true);
			success = false;
		}

		if (!success) {
			setShowCopiedTooltip(false);
		}

		timeoutIdRef.current = setTimeout(() => {
			setShowCopiedTooltip(false);
			// @ts-expect-error - TS2769 - No overload matches this call.
			clearTimeout(timeoutIdRef.current);
		}, 3000);

		event.currentTarget.focus();
	};

	const handleMouseLeave = () => {
		if (timeoutIdRef.current) {
			setShowCopiedTooltip(false);
			// @ts-expect-error - TS2769 - No overload matches this call.
			clearTimeout(timeoutIdRef.current);
		}
	};

	if (showCopiedTooltip === true) {
		tooltipLabel = formatMessage(messages.copiedTooltipLabel);
	} else if (copyFailed) {
		tooltipLabel = formatMessage(messages.copyFailedTooltipLabel);
	}

	return (
		// @ts-expect-error Property 'css' does not exist on type 'DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>'.ts(2322)
		// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		<div css={copyTextFieldWrapperStyles}>
			<div
				// @ts-expect-error Property 'css' does not exist on type 'DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>'.ts(2322)
				// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				css={fieldWrapperStyles}
			>
				<Textfield
					id={id}
					testId={`platform-copy-text-field.textfield--${id || ''}`}
					aria-labelledby={ariaLabelledBy}
					aria-label={ariaLabel}
					ref={inputRef}
					value={value}
					// @ts-expect-error - TS2322 - Type '({ target }: ChangeEvent<HTMLInputElement>) => void' is not assignable to type 'MouseEventHandler<HTMLInputElement>'.
					onClick={handleTextFieldClick}
					onMouseDown={(e) => {
						// This prevents the cursor from jumping to the end of the input onMouseDown
						// caused by Atlaskit's Textfield focusing the underlying input onMouseDown
						// @ts-expect-error - TS2339 - Property 'blur' does not exist on type 'EventTarget'.
						e.target.blur();
					}}
					onMouseUp={(e) => {
						// Prevents flaky behaviour where text is de-selected onMouseUp
						e.preventDefault();
					}}
					isReadOnly
				/>
			</div>
			<Tooltip tag="span" delay={delay} content={tooltipLabel || ''} position="top">
				<StyledButton
					onClick={handleCopyClick}
					onMouseLeave={handleMouseLeave}
					testId={`platform-copy-text-field.styled-button-${id || ''}`}
				>
					<CopyIcon label={formatMessage(messages.copyIconLabel)} />
				</StyledButton>
			</Tooltip>
		</div>
	);
};

export const CopyTextFieldWithLabel = ({
	label,
	name,
	...props
}: {
	label: string;
	name: string;
} & CopyTextFieldProps) => (
	<Field name={name} label={label}>
		{({ fieldProps: { id, 'aria-labelledby': ariaLabelledBy } }) => (
			<CopyTextField id={id} aria-labelledby={ariaLabelledBy} {...props} />
		)}
	</Field>
);

export default CopyTextField;
