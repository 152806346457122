import { useRef, useCallback, useEffect } from 'react';
import type { Event, Config, HandlerFunc } from './types.tsx';
import { useRealtimeQueueContext } from './utils/context/index.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { RealtimeQueueContextProvider } from './utils/context';

type Fn = (
	handler: HandlerFunc,
	config?: Config,
	shouldSkipEvent?: (event: Event) => boolean,
) => [HandlerFunc, () => void];

export const useAreRealtimeQueueQuotasExceeded = () => {
	const { areQueueQuotasExceeded, eventsInQueue } = useRealtimeQueueContext();

	return { areQueueQuotasExceeded, eventsInQueue };
};

export const useWrappedRealtimeHandlerWithQueue: Fn = (handler, config, shouldSkipEvent) => {
	const { queue } = useRealtimeQueueContext();
	const ref = useRef<HandlerFunc | null>(null);

	useEffect(() => {
		ref.current = handler;
		// set queue config
		config && queue.setConfig(config);
	}, [ref, handler, config, queue]);

	const onReceive = useCallback(
		(event: Event) => {
			if (shouldSkipEvent && shouldSkipEvent(event)) {
				return;
			}

			ref.current && queue.addEvent(ref, event);
		},
		[queue, ref, shouldSkipEvent],
	);

	const clearEvents = useCallback(() => {
		ref.current && queue.clearEvents(ref);
	}, [queue]);

	return [onReceive, clearEvents];
};

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { Config } from './types';
