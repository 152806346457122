// INVESTIGATE_INCIDENT_CLICKED
export const INVESTIGATE_INCIDENT_CLICKED = 'INVESTIGATE_INCIDENT_CLICKED' as const;

export const investigateIncidentClicked = () => ({
	type: INVESTIGATE_INCIDENT_CLICKED,
});

// INVESTIGATE_INCIDENT_MODAL_CLOSED
export const INVESTIGATE_INCIDENT_MODAL_CLOSED = 'INVESTIGATE_INCIDENT_MODAL_CLOSED' as const;

export const investigateIncidentModalClosed = () => ({
	type: INVESTIGATE_INCIDENT_MODAL_CLOSED,
});

export type InvestigateIncidentClicked = ReturnType<typeof investigateIncidentClicked>;
export type InvestigateIncidentModalClosed = ReturnType<typeof investigateIncidentModalClosed>;
