import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { BaseUrl } from '@atlassian/jira-shared-types/src/general.tsx';

export type CreateIssuePayload = {
	fields: {
		project: {
			id: string;
		};
		issuetype: {
			id: string;
		};
		summary: string;
		parent?: {
			id: string;
		};
	};
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (baseUrl: BaseUrl, createPayload: CreateIssuePayload) =>
	performPostRequest(`${baseUrl}/rest/api/2/issue`, {
		body: JSON.stringify(createPayload),
	});
