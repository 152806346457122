import { useCallback } from 'react';
import { useRelayEnvironment } from 'react-relay';
import { usePersistentlyCachedRequest } from '@atlassian/jira-platform-persistent-cache/src/index.tsx';
import { useCurrentUser } from '@atlassian/jira-platform-services-user-current/src/main.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useLicensedProducts } from '@atlassian/jira-tenant-context-controller/src/components/licensed-products/index.tsx';
import { fetchDefinitionsPermissions } from '../../services/fetch-definitions-permissions/index.tsx';

export const useUserHasConfluenceAccess = () => {
	const { data: currentUser } = useCurrentUser();
	const storageKey = `jira-definition-user-has-confluence-access-agg-${currentUser.user.accountId}`;

	const cloudId = useCloudId();
	const environment = useRelayEnvironment();

	const fetchData = useCallback(
		() => fetchDefinitionsPermissions(cloudId, environment),
		[cloudId, environment],
	);

	const licensedProducts = useLicensedProducts();
	const hasConfluence = Boolean(licensedProducts.confluence);

	const [hasAccess] = usePersistentlyCachedRequest(storageKey, false, hasConfluence && fetchData);

	return { hasAccess };
};
