import { parse, isValid, parseISO, format } from 'date-fns';

type DatePickerPublicValue = string | null;
type DatePickerInternalGetValue = string | undefined;
type DatePickerInternalSetValue = string;

// Date picker accepts only date string in format yyyy-MM-dd, so we need to validate it
// The date format is consistent with API contract
const dateHasExpectedFormat = (date: unknown) => {
	const dateFormat = 'yyyy-MM-dd';

	// `parse` doesn't respect strict format, see known issue https://github.com/date-fns/date-fns/issues/942
	// where format yyyy-MM-dd date 2022-10-2 is parsed successfully but shouldn't
	if (typeof date !== 'string' || dateFormat.length !== date.length) {
		return false;
	}

	const parsedDate = parse(date, dateFormat, new Date());

	return isValid(parsedDate);
};

export const datePickerSetValueValidator = (value: unknown): value is DatePickerPublicValue =>
	value === null || dateHasExpectedFormat(value);

export const datePickerSetValueTransformer = (
	publicValue: DatePickerPublicValue,
): DatePickerInternalSetValue => (publicValue === null ? '' : publicValue);

export const datePickerGetValueTransformer = (
	value: DatePickerInternalGetValue,
): DatePickerPublicValue => {
	if (!value) {
		return null;
	}

	const parsedDate = parseISO(value);

	if (isValid(parsedDate)) {
		return format(parsedDate, 'yyyy-MM-dd');
	}

	return null;
};
