import { createSelector } from 'reselect';
import type { Locale } from '@atlassian/jira-platform-utils-date-fns/src/types.tsx';
import type { State } from '../../types.tsx';
import type { ContextState } from '../types.tsx';

const contextSelector = (state: State): ContextState => state.context;

export const localeSelector = createSelector(
	contextSelector,
	(context: ContextState): Locale => context.locale,
);

export const issueIdSelector = createSelector(
	contextSelector,
	(context: ContextState): string => context.issueId,
);

export const issueAriSelector = createSelector(
	contextSelector,
	(context: ContextState): string => context.issueAri,
);

export const issueKeySelector = createSelector(
	contextSelector,
	(context: ContextState): string => context.issueKey,
);

export const issueStatusCategorySelector = createSelector(
	contextSelector,
	(context: ContextState): string => context.issueStatusCategory,
);

export const projectIdSelector = createSelector(
	contextSelector,
	(context: ContextState): string => context.projectId,
);

export const projectTypeSelector = createSelector(
	contextSelector,
	(context: ContextState): string => context.projectType,
);

export const isSimplifiedProjectSelector = createSelector(
	contextSelector,
	(context: ContextState): boolean => context.nextGenProject,
);

export const analyticsSourceSelector = createSelector(
	contextSelector,
	(context: ContextState): string | null | undefined => context.analyticsSource,
);

export const isSiteAdminSelector = createSelector(
	contextSelector,
	(context: ContextState): boolean => context.isSiteAdmin,
);

export const projectAriSelector = createSelector(
	contextSelector,
	(context: ContextState): string => context.projectAri,
);

export const isJiraAdminSelector = createSelector(
	contextSelector,
	(context: ContextState): boolean => context.isJiraAdmin,
);
