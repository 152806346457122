import { useCallback, useState } from 'react';
import { useRelayEnvironment, fetchQuery, graphql } from 'react-relay';
import versionsCache from '@atlassian/jira-cache/src/services/versions/index.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { versionStatus } from '@atlassian/jira-issue-field-constants/src/index.tsx';
import type { SelectableValueOption } from '@atlassian/jira-issue-selectable-field-edit-view/src/ui/types.tsx';
import type { releaseVersionsCacheServicesValidateQuery } from '@atlassian/jira-relay/src/__generated__/releaseVersionsCacheServicesValidateQuery.graphql';

export const MAX_CACHED_OPTIONS_SHOWN = 5;

export const useReleaseVersionsCache = (projectId: string, fieldId?: string) => {
	const [cachedReleaseVersions, setCachedReleaseVersions] = useState<SelectableValueOption[]>([]);
	const environment = useRelayEnvironment();

	/**
	 * Get displayable cached versions. This function will return the cached versions
	 * while only show the last MAX_CACHED_OPTIONS_SHOWN versions and reverse the order
	 * to show the latest cached version at the top
	 */
	const getDisplayableCachedVersions = useCallback(async () => {
		const cachedVersions = await versionsCache.getAll();

		const releaseVersions = cachedVersions.filter(
			(version) => Boolean(version.ari) && version.projectId === projectId,
		);

		return releaseVersions.slice(-1 * MAX_CACHED_OPTIONS_SHOWN).reverse();
	}, [projectId]);

	/**
	 * Refresh cached versions in against the server versions. This function will
	 * make a request to the server to get the latest versions based on the cached
	 * version ARIs in local state and update the cache accordingly.
	 */
	const refreshCachedVersions = useCallback(async () => {
		if (!fieldId) {
			return;
		}

		try {
			const displayableCachedVersions = await getDisplayableCachedVersions();

			if (!displayableCachedVersions.length) {
				return;
			}

			const serverData = await fetchQuery<releaseVersionsCacheServicesValidateQuery>(
				environment,
				graphql`
					query releaseVersionsCacheServicesValidateQuery(
						$id: ID!
						$filterById: JiraFieldOptionIdsFilterInput
					) {
						node(id: $id) {
							id
							__typename
							... on JiraHasSelectableValueOptions {
								selectableValueOptions(filterById: $filterById) {
									edges {
										node {
											id
											selectableLabel
											selectableGroupKey
										}
									}
								}
							}
						}
					}
				`,
				{
					id: fieldId,
					filterById: {
						optionIds: displayableCachedVersions.map((version) => version.ari).filter(Boolean),
						operation: 'ALLOW',
					},
				},
			).toPromise();

			const serverVersions = serverData?.node?.selectableValueOptions?.edges || [];

			const refreshPromises = displayableCachedVersions.map(async (version) => {
				const found = serverVersions.find(
					(serverVersion) => serverVersion?.node?.id === version.ari,
				);

				if (!found) {
					await versionsCache.remove(String(version.id));
					return;
				}

				const serverVersionNode = found.node;

				if (!serverVersionNode || !serverVersionNode.id) {
					return;
				}

				const updatedCachedVersion = {
					name: serverVersionNode.selectableLabel || '',
					ari: serverVersionNode.id,
					id: version.id || '',
					released: serverVersionNode.selectableGroupKey === versionStatus.RELEASED,
					projectId,
				};

				await versionsCache.update(String(version.id), updatedCachedVersion);

				return {
					...serverVersionNode,
					selectableIconUrl: null,
					versionId: version.id,
					isDisabled: false,
				};
			});

			const refreshedCachedVersions = await Promise.all(refreshPromises);

			setCachedReleaseVersions(refreshedCachedVersions.filter(Boolean));
		} catch (error) {
			const errorMessage =
				error instanceof Error ? error.message : 'Unknown error during validating cached versions';

			log.safeErrorWithoutCustomerData(
				'issue-view.release-versions-cache-service.validateCachedVersions',
				errorMessage,
			);
		}
	}, [projectId, environment, fieldId, getDisplayableCachedVersions]);

	const getAllCachedVersions = useCallback(async () => {
		try {
			const displayableCachedVersions = await getDisplayableCachedVersions();

			const transformedReleaseVersions = displayableCachedVersions.map((version) => ({
				id: version.ari || '',
				selectableLabel: version.name,
				selectableGroupKey: version.released ? versionStatus.RELEASED : versionStatus.UNRELEASED,
				selectableIconUrl: null,
				versionId: version.id,
				isDisabled: false,
			}));

			setCachedReleaseVersions(transformedReleaseVersions);
		} catch (error) {
			const errorMessage =
				error instanceof Error ? error.message : 'Unknown error during fetching cached versions';

			log.safeErrorWithoutCustomerData(
				'issue-view.release-versions-cache-service.getAll',
				errorMessage,
			);
		}
	}, [setCachedReleaseVersions, getDisplayableCachedVersions]);

	const addToCache = useCallback(
		async (versions: SelectableValueOption[]) => {
			await Promise.all(
				versions.map(async (version) => {
					try {
						if (!version.id || !version.versionId) {
							return;
						}

						await versionsCache.set(String(version.versionId), {
							name: version.selectableLabel || '',
							id: version.versionId,
							ari: version.id,
							released: version.selectableGroupKey === versionStatus.RELEASED,
							projectId,
						});
					} catch (error) {
						const errorMessage =
							error instanceof Error
								? error.message
								: 'Unknown error during adding versions to cache';

						log.safeErrorWithoutCustomerData(
							'issue-view.release-versions-cache-service.addToCache',
							errorMessage,
						);
					}
				}),
			);

			await getAllCachedVersions();
		},
		[getAllCachedVersions, projectId],
	);

	return [
		cachedReleaseVersions,
		{ addToCache, getAllCachedVersions, refreshCachedVersions },
	] as const;
};
