import { getTime, isValid } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';

/** For formatting display date/times. For field date/times use the `useDateTimeFormatter` hook from `@atlassian/jira-issue-format-date` instead */
export const intlFormatDateTime = (intl: IntlShape, dateTime: string | null, timeZone?: string) => {
	if (!dateTime) {
		return null;
	}

	const dateFormat = {
		day: '2-digit',
		month: 'short',
		year: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
		hour12: true,
	};
	const options = timeZone ? { ...dateFormat, timeZone } : { ...dateFormat };

	const unixTime = getTime(new Date(dateTime));
	return intl.formatDate(unixTime, options);
};

export const parseDateTimeValue = (
	dateTimeValue: string | null,
	fallbackDate: string | null,
	fallbackTime: string | null,
	timeZone?: string | null,
) => {
	const parsed =
		dateTimeValue !== null && dateTimeValue !== '' && timeZone && isValid(new Date(dateTimeValue))
			? utcToZonedTime(dateTimeValue, timeZone)
			: null;

	return parsed !== null && timeZone && isValid(parsed)
		? {
				dateValue: format(parsed, 'yyyy-MM-dd', { timeZone }),
				timeValue: format(parsed, 'HH:mm', { timeZone }),
				zoneValue: format(parsed, 'XX', { timeZone }),
			}
		: {
				dateValue: fallbackDate,
				timeValue: fallbackTime,
				zoneValue: timeZone ? format(new Date(), 'XX', { timeZone }) : null,
			};
};

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { ParseValue } from './types';
