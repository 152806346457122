import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/ignoreElements';
import type { ActionsObservable } from 'redux-observable';
import { getAnalyticsWebClient } from '@atlassian/jira-product-analytics-web-client';
import type { Action } from '../../state/actions/app/index.tsx';
import type { State } from '../../state/index.tsx';
import actionAnalyticsMap from './analytics-actions-map.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getAnalyticsForAction = (state: State, action: any) =>
	actionAnalyticsMap[action.type] && actionAnalyticsMap[action.type](state, action);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const triggerAnalyticsForAction = (event: any) => {
	getAnalyticsWebClient().sendEvent(event);
};

export const epic = (action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
	action$
		.map((action) => getAnalyticsForAction(store.getState(), action))
		.filter((event) => !!event)
		.do(triggerAnalyticsForAction)
		.ignoreElements();
