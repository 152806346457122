/**
 * @generated SignedSource<<d62678fb4ec7e68c53320183ae270d4e>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type AccountStatus = "active" | "closed" | "inactive" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type assignToMe_issueFieldAssigneeAssignToMe_AssignToMeWithControlledAssignee_fragmentRefRoot$data = {
  readonly me: {
    readonly user: {
      readonly accountId: string;
      readonly accountStatus: AccountStatus;
      readonly id: string;
      readonly name: string;
      readonly picture: AGG$URL;
    } | null | undefined;
  };
  readonly " $fragmentType": "assignToMe_issueFieldAssigneeAssignToMe_AssignToMeWithControlledAssignee_fragmentRefRoot";
};
export type assignToMe_issueFieldAssigneeAssignToMe_AssignToMeWithControlledAssignee_fragmentRefRoot$key = {
  readonly " $data"?: assignToMe_issueFieldAssigneeAssignToMe_AssignToMeWithControlledAssignee_fragmentRefRoot$data;
  readonly " $fragmentSpreads": FragmentRefs<"assignToMe_issueFieldAssigneeAssignToMe_AssignToMeWithControlledAssignee_fragmentRefRoot">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "assignToMe_issueFieldAssigneeAssignToMe_AssignToMeWithControlledAssignee_fragmentRefRoot",
  "selections": [
    {
      "concreteType": "AuthenticationContext",
      "kind": "LinkedField",
      "name": "me",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "id"
            },
            {
              "kind": "ScalarField",
              "name": "accountStatus"
            },
            {
              "kind": "ScalarField",
              "name": "name"
            },
            {
              "kind": "ScalarField",
              "name": "picture"
            },
            {
              "kind": "ScalarField",
              "name": "accountId"
            }
          ]
        }
      ]
    }
  ],
  "type": "Query"
};

(node as any).hash = "83877b7658f8821d508e1a7540700be5";

export default node;
