import React, { Component } from 'react';
import type { Dispatch } from 'redux';
import noop from 'lodash/noop';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Badge from '@atlaskit/badge';
import ArrowRightIcon from '@atlaskit/icon/core/migration/arrow-right';
import LinkExternalIcon from '@atlaskit/icon/core/migration/link-external--shortcut';
import Lozenge from '@atlaskit/lozenge';
import { Text } from '@atlaskit/primitives';
// eslint-disable-next-line jira/wrm/no-load-bridge
import JiraBridge from '@atlassian/jira-common-bridge/src/jira-bridge';
import AsyncIcon from '@atlassian/jira-common-components-async-icon/src/view.tsx';
import EnterEscapeHandler from '@atlassian/jira-common-components-enter-escape-handler/src/index.tsx';
import broadcastConnectEvent from '@atlassian/jira-connect-utils/src/common/utils/broadcast-connect-event.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import {
	TASK_FAIL,
	TASK_SUCCESS,
} from '@atlassian/jira-experience-tracker/src/common/constants.tsx';
import { FormattedMessage } from '@atlassian/jira-intl';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { FieldHeading } from '@atlassian/jira-issue-field-heading/src/index.tsx';
import {
	FieldHeadingTitle,
	FieldWrapper,
	SideBySideField,
} from '@atlassian/jira-issue-field-heading/src/styled.tsx';
import type {
	GlanceIcon,
	GlanceStatus,
} from '@atlassian/jira-issue-gira-transformer-types/src/common/types/ecosystem.tsx';
import { sendExperienceAnalytics } from '@atlassian/jira-issue-view-analytics/src/controllers/send-experience-analytics/index.tsx';
import {
	type GlanceUpdate,
	type GlanceWorkMode,
	CLICK_THROUGH,
	OPEN_PANEL,
} from '@atlassian/jira-issue-view-common-types/src/glance-type.tsx';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import { glanceAnalyticsData } from '@atlassian/jira-issue-view-common/src/ecosystem/ecosystem-analytics.tsx';
import { useIssueLayoutActions } from '@atlassian/jira-issue-view-layout/src/services/main.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { convertIconUrlToAbsolute } from '@atlassian/jira-issue-view-services/src/issue/ecosystem-extensions-transformer.tsx';
import { ecosystemShowGlance } from '@atlassian/jira-issue-view-store/src/actions/ecosystem-glance-actions.tsx';
import {
	ISSUE_GLANCE_CONTENT_CLICKED,
	ISSUE_GLANCE_OPENED,
} from '@atlassian/jira-issue-view-store/src/ecosystem/ecosystem-event.tsx';
import { glancePanelSelector } from '@atlassian/jira-issue-view-store/src/ecosystem/ecosystem-extensions-selector.tsx';
import { openedGlanceSelector } from '@atlassian/jira-issue-view-store/src/selectors/ecosystem-glance-ui-selector.tsx';
import MountAnimator from '@atlassian/jira-packages-components-animations/src/mount-animator.tsx';
import { MountEvent, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import {
	useProjectKey,
	useApplication,
	useEdition,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import type { Application } from '@atlassian/jira-shared-types/src/application.tsx';
import type { ApplicationEdition } from '@atlassian/jira-shared-types/src/edition.tsx';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import {
	ContentContainer,
	Icon,
	Content,
	StatusContainer,
	RoundButtonContainer,
	RoundButton,
	SlideInAnimation,
} from '../common/styled-glance-view.tsx';
import { sanitizeGlanceUpdate } from './sanitize-glance-update.tsx';

export type Label = {
	type: string;
	value: string;
};

type OwnProps = {
	name: string;
	appKey: string;
	moduleKey: string;
	icon: GlanceIcon | null;
	label: Label;
	status: GlanceStatus | null;
	workMode: GlanceWorkMode;
	options?: {
		addon_key?: string;
		key?: string;
		moduleLocation?: string;
		moduleType?: string;
		pearApp?: boolean;
		url?: string;
	};
	onClickThroughGlancePanel: (appKey: string, moduleKey: string) => void;
};

type Props = OwnProps & {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	glance: any | undefined;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	openedGlance: any | null;
	application: Application | undefined;
	edition: ApplicationEdition | undefined;
	onOpenGlancePanel: (appKey: string, moduleKey: string) => void;
	onClickThroughGlancePanel: (appKey: string, moduleKey: string) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onGlanceShowAnalytics: any;
};

type State = {
	isHovering: boolean;
	update?: GlanceUpdate;
	analyticsEvent?: UIAnalyticsEvent;
};

type UpdateIssueGlanceData = {
	addonKey: string;
	moduleKey: string;
	data: GlanceUpdate;
};

type OnGlanceUpdateNativeEvent = {
	detail: [IssueId, UpdateIssueGlanceData];
};

// eslint-disable-next-line jira/react/no-class-components
export class ConnectGlance extends Component<Props, State> {
	static displayName = 'ConnectGlance';

	static defaultProps = {
		icon: null,
		openedGlance: null,
		status: null,
		workMode: OPEN_PANEL,
		onGlanceShowAnalytics: noop,
	};

	state: State = {
		isHovering: false,
		update: undefined,
		analyticsEvent: undefined,
	};

	componentDidMount() {
		// eslint-disable-next-line jira/wrm/no-load-bridge
		JiraBridge.addUpdateIssueGlanceListener(this.onGlanceUpdate);
	}

	componentWillUnmount() {
		// eslint-disable-next-line jira/wrm/no-load-bridge
		JiraBridge.removeUpdateIssueGlanceListener(this.onGlanceUpdate);
	}

	onGlanceUpdate = (event: OnGlanceUpdateNativeEvent): void => {
		const payload = event.detail[1];
		if (payload.addonKey === this.props.appKey && payload.moduleKey === this.props.moduleKey) {
			this.setState({ update: sanitizeGlanceUpdate(payload.data) });
		}
	};

	getGlanceIcon = (): GlanceIcon | null =>
		this.state.update && this.state.update.icon ? this.state.update.icon : this.props.icon;

	getGlanceWorkMode = (): GlanceWorkMode =>
		(this.state.update && this.state.update.workMode) || this.props.workMode;

	eventHandlers = {
		onMouseEnter: () => this.setState({ isHovering: true }),
		onMouseLeave: () => this.setState({ isHovering: false }),
		onFocus: () => this.setState({ isHovering: true }),
		onBlur: () => this.setState({ isHovering: false }),
	};

	handleOpenGlancePanel = () => {
		const {
			appKey,
			moduleKey,
			options = {},
			status,
			onGlanceShowAnalytics,
			onOpenGlancePanel,
			onClickThroughGlancePanel,
		} = this.props;
		const currentWorkMode = this.getGlanceWorkMode();

		if (this.state.analyticsEvent) {
			// We must mutate the event since fireUIAnalytics ignores the action in the second param
			const thisAnalyticsEvent = this.state.analyticsEvent;
			if (thisAnalyticsEvent.payload) thisAnalyticsEvent.payload.action = 'clicked';
			fireUIAnalytics(thisAnalyticsEvent, 'connectGlancePanel clicked', {
				moduleType: options.moduleType,
				iframeIsCacheable: String(options.url).includes('xdm_e='),
				moduleKey: options.key,
				moduleLocation: options.moduleLocation,
				pearApp: Boolean(options.pearApp),
				issueField: options.addon_key,
			});
		}

		if (currentWorkMode === OPEN_PANEL) {
			onGlanceShowAnalytics(glanceAnalyticsData(appKey, moduleKey, status));
			onOpenGlancePanel(appKey, moduleKey);
		} else if (currentWorkMode === CLICK_THROUGH) {
			onClickThroughGlancePanel(appKey, moduleKey);
		}
	};

	container: Element | null | undefined;

	renderStatus = () => {
		const status =
			this.state.update && this.state.update.status ? this.state.update.status : this.props.status;
		if (!status) {
			return null;
		}

		// TODO: remove any type and add value check for undefined.
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const { value, type }: any = status;
		if (value.label) {
			switch (type) {
				case 'lozenge':
					return <Lozenge appearance={value.type}>{value.label}</Lozenge>;
				case 'icon':
					return this.props.glance ? (
						<AsyncIcon
							url={convertIconUrlToAbsolute(this.props.glance.options.origin, value.label)}
							alt={value.type}
							width={24}
							height={24}
						/>
					) : null;
				case 'badge': {
					const num = parseInt(value.label, 10);
					if (num >= 0) {
						return <Badge appearance="default">{num}</Badge>;
					}
					return null;
				}
				default:
					return null;
			}
		}
		return null;
	};

	renderContent = () => {
		if (this.state.update && this.state.update.content) {
			return <Content>{this.state.update.content}</Content>;
		}
		const { label, name } = this.props;
		return label.value === name ? (
			<Content>
				<FormattedMessage
					id="issue.ecosystem.context.rich-glance-label"
					defaultMessage="Open {label}"
					values={{ label: <Text weight="bold">{label.value}</Text> }}
				/>
			</Content>
		) : (
			<Content>{label.value}</Content>
		);
	};

	render() {
		const { name, glance } = this.props;
		const icon = this.getGlanceIcon();

		return (
			<ErrorBoundary
				id="issue-view.context.ecosystem-glance-field"
				packageName="jiraIssueViewBase"
				onError={(location, error) => {
					sendExperienceAnalytics({
						experience: 'issueViewConnectGlanceFieldRender',
						analyticsSource: 'issueViewConnectGlanceFieldView',
						action: TASK_FAIL,
						wasExperienceSuccesful: false,
						application: this.props.application ?? null,
						edition: this.props.edition ?? null,
						additionalAttributes: {
							location,
							errorMessage: error.message,
						},
					});
				}}
			>
				<FieldWrapper
					/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
					data-testid="issue.views.issue-base.context.ecosystem.connect.field-wrapper"
				>
					<FieldHeading>
						<FieldHeadingTitle>{name}</FieldHeadingTitle>
					</FieldHeading>
					<SideBySideField
						/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
						data-testid="issue.views.issue-base.context.ecosystem.connect.field"
					>
						<EnterEscapeHandler onEnter={this.handleOpenGlancePanel}>
							<MountEvent onMount={(analyticsEvent) => this.setState({ analyticsEvent })} />

							<ContentContainer
								onClick={this.handleOpenGlancePanel}
								tabIndex={0}
								data-testid="issue-view-base.context.ecosystem.connect.content-container"
								{...this.eventHandlers}
							>
								{icon && glance && (
									<Icon>
										<AsyncIcon
											url={convertIconUrlToAbsolute(glance.options.origin, icon.url)}
											alt={icon.alt}
											width={24}
											height={24}
										/>
									</Icon>
								)}
								{this.renderContent()}
								<StatusContainer>{this.renderStatus()}</StatusContainer>
								{this.state.isHovering && (
									<MountAnimator mountAnimation={SlideInAnimation}>
										<RoundButtonContainer>
											<RoundButton>
												{this.getGlanceWorkMode() === CLICK_THROUGH ? (
													<LinkExternalIcon
														color="currentColor"
														spacing="spacious"
														LEGACY_size="medium"
														label=""
													/>
												) : (
													<ArrowRightIcon
														color="currentColor"
														spacing="spacious"
														LEGACY_size="medium"
														label=""
													/>
												)}
											</RoundButton>
										</RoundButtonContainer>
									</MountAnimator>
								)}
							</ContentContainer>
						</EnterEscapeHandler>
					</SideBySideField>
				</FieldWrapper>
				{/* The MountEvent is at the bottom so that it mounts last */}
				<MountEvent
					onMount={() =>
						sendExperienceAnalytics({
							experience: 'issueViewConnectGlanceFieldRender',
							analyticsSource: 'issueViewConnectGlanceFieldView',
							application: this.props.application ?? null,
							edition: this.props.edition ?? null,
							wasExperienceSuccesful: true,
							action: TASK_SUCCESS,
						})
					}
				/>
			</ErrorBoundary>
		);
	}
}

const onClickThroughGlancePanel = (appKey: string, moduleKey: string) => {
	broadcastConnectEvent(ISSUE_GLANCE_CONTENT_CLICKED, {
		addon_key: appKey,
		key: moduleKey,
	});
};

const ConnectGlanceWithIssueLayout = (props: Props) => {
	const issueKey = useIssueKey();
	const [, { setIssueViewLayoutGlance }] = useIssueLayoutActions();
	const projectKey = useProjectKey(issueKey);
	const application = useApplication(projectKey, true);
	const edition = useEdition(projectKey, true);

	return (
		<ConnectGlance
			{...props}
			application={application}
			edition={edition}
			onOpenGlancePanel={(appKey, moduleKey) => {
				setIssueViewLayoutGlance(issueKey, { appKey, moduleKey });
				broadcastConnectEvent(ISSUE_GLANCE_OPENED, {
					addon_key: appKey,
					key: moduleKey,
				});
			}}
		/>
	);
};

export default flowWithSafeComponent(
	connect(
		(state, ownProps: { appKey: string; moduleKey: string }) => ({
			openedGlance: openedGlanceSelector(state),
			glance: glancePanelSelector(ownProps.appKey, ownProps.moduleKey)(state),
			onClickThroughGlancePanel,
		}),
		(dispatch: Dispatch) => ({
			onOpenGlancePanel: (appKey: string, moduleKey: string) => {
				dispatch(ecosystemShowGlance(appKey, moduleKey));
				broadcastConnectEvent(ISSUE_GLANCE_OPENED, {
					addon_key: appKey,
					key: moduleKey,
				});
			},
		}),
	),
)(ConnectGlanceWithIssueLayout);
