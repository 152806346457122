import { fg } from '@atlassian/jira-feature-gating';
import type { Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import { useCurrentUser } from '@atlassian/jira-polaris-common/src/common/utils/tenant-context/index.tsx';
import { useCanEditCollection } from '@atlassian/jira-polaris-component-collections/src/controllers/combined-selectors.tsx';
import { useEntityLimitsByType } from '@atlassian/jira-polaris-component-entity-limits-store/src/controllers/entity-limits/selectors/entity-limits-hooks.tsx';
import { useEnvironmentContainer } from '@atlassian/jira-polaris-component-environment-container/src/controllers/store/index.tsx';
import {
	useCanManageViewBasedOnViewPermissions,
	useViewAccessLevelBasedOnViewPermissions,
} from '@atlassian/jira-polaris-component-view-access/src/controllers/hooks/index.tsx';
import { useHasSeenView } from '@atlassian/jira-polaris-component-view-visitors/src/controllers/index.tsx';
import type { FieldType } from '@atlassian/jira-polaris-domain-field/src/field-types/types.tsx';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { SortField } from '@atlassian/jira-polaris-domain-field/src/sort/types.tsx';
import type { GroupValue } from '@atlassian/jira-polaris-domain-field/src/value/types.tsx';
import { ENTITY_LIMIT_TYPE } from '@atlassian/jira-polaris-domain-project/src/constants.tsx';
import type { Filter } from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import type { ArrangementInformation } from '@atlassian/jira-polaris-domain-view/src/timeline/types.tsx';
import type { ViewAccessLevel } from '@atlassian/jira-polaris-domain-view/src/view-access/types.tsx';
import type { ViewSetType } from '@atlassian/jira-polaris-domain-view/src/view-set/types.tsx';
import type { LocalViewId, ViewKind } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { useEntityLimitMessage } from '@atlassian/jira-polaris-lib-limits/src/controllers/index.tsx';
import { useIsCollectionView, useIsSharedView } from '../../environment/index.tsx';
import { useViewActions } from '../main.tsx';
import { createHigherLevelViewHook, createViewHook } from './utils.tsx';
import {
	hasVerticalGroupByUnsavedChanges,
	hasGroupByUnsavedChanges,
	hasFiltersUnsavedChanges,
	hasSortUnsavedChanges,
	isCurrentViewAutosaveEnabled,
	hasFieldRollupUnsavedChanges,
	hasFieldsUnsavedChanges,
	hasMatrixXAxisUnsavedChanges,
	hasMatrixYAxisUnsavedChanges,
	hasMatrixZAxisUnsavedChanges,
	hasTimelineConfigUnsavedChanges,
	hasViewUnsavedChanges,
	hasLayoutTypeUnsavedChanges,
} from './view/autosave/index.tsx';
import { hasSharedViewConfigError, getCurrentView } from './view/current/index.tsx';
import {
	getCurrentViewFields,
	getCurrentViewFieldsUnsupportedForSharing,
	getCurrentViewHiddenFields,
	getCurrentViewId,
	getCurrentViewKind,
	getCurrentViewSlug,
	getCurrentViewSortBy,
	getCurrentViewUUID,
	isLastView,
	getCurrentViewGroupBy,
	getCurrentViewContainsArchived,
	getCurrentViewVerticalGroupBy,
	getCurrentViewGroupValues,
	getCurrentViewVerticalGroupValues,
	getCurrentViewSelectedIssueIds,
	getCurrentViewTitle,
	getCurrentViewEmoji,
	getCurrentViewDescription,
	getCurrentViewIsEditingTitle,
	getCurrentViewFieldCount,
	getCurrentViewFieldKeys,
	getCurrentViewHiddenFieldKeys,
	getCurrentViewCollapsedSwimlanes,
	getCurrentViewVisibleFields,
	getCurrentViewAri,
	getHasCurrentView,
	createGetViewAri,
	createGetViewEmoji,
	createGetViewFieldKeys,
	createGetViewFieldTypes,
	createGetViewFilterLength,
	createGetViewFilterTypes,
	createGetViewGroupBy,
	createGetViewGroupValues,
	createGetViewKind,
	createGetViewSortFieldTypes,
	createGetViewSlug,
	getArchiveViewId,
	getCurrentViewSelectedIssueIdsAsList,
	getViewTypeCount,
	createGetViewTitle,
	createGetViewSetName,
	createGetViewContainsArchived,
	getCurrentViewConfigured,
	getCurrentViewSortByFieldCount,
	getCurrentViewSortByField,
	isCurrentViewGroupedList,
	getIsExporting,
	getIsReadyToExport,
	getViewsCountFromViewSetTypes,
	getCurrentViewBulkEditHoveredFieldKey,
	getCurrentHideEmptyGroups,
	getHasUnsavedChanges,
	getCurrentViewLayoutType,
	getCurrentHideEmptyBoardColumns,
	getViewAnalyticsData,
	getAllViewsNameAndIds,
	createGetValidIssueSubmissionFormFieldKeys,
	createGetInvalidIssueSubmissionFormFieldLabels,
	createGetViewFilter,
	getCurrentViewRankFieldKey,
	getCurrentViewProjectId,
	getCurrentViewVisibleFieldKeys,
	getCurrentViewVisibleIssueActionFields,
	getPinnedViewActionFieldKeys,
	getStatusCategoriesForPublishedView,
	createGetViewFieldKeysByAri,
	getAllViewFields,
	createGetViewUUID,
	getIsCurrentViewDescriptionEmpty,
	areCurrentViewAllSwimlanesCollapsed,
	getCurrentViewAccessLevel,
	getCurrentViewPermanentSortBy,
	getCurrentViewTemporarySortBy,
	getHasTemporaryData,
	isFieldVisibleInCurrentView,
	getCurrentViewRank,
} from './view/index.tsx';
import {
	getCurrentViewTableColumnSizes,
	getCurrentViewTableColumnSize,
	getCurrentViewTableColumnSizesByFieldKey,
} from './view/list/index.tsx';
import {
	getCurrentViewXAxisField,
	getCurrentViewYAxisField,
	getCurrentViewZAxisField,
	getCurrentViewXAxis,
	getCurrentViewYAxis,
	getCurrentViewZAxis,
	getMatrixFieldOptions,
	getMatrixOptionsHasReverseOrder,
} from './view/matrix/index.tsx';
import { getCanManageCurrentView } from './view/permissions/index.tsx';
import {
	getCurrentViewTimelineModeOrDefault,
	getCurrentViewTimelineStartDateField,
	getCurrentViewTimelineEndDateField,
	getCurrentViewTimelineIntervalStartDate,
	getCurrentViewTimelineIntervalEndDate,
	getCurrentViewArrangementInformation,
	getCurrentViewSummaryCardFieldKey,
	getCurrentViewTimelineMarkers,
	getCurrentViewSummaryCardField,
} from './view/timeline/index.tsx';
import {
	getCurrentViewSortMode,
	getCurrentViewIssueRanking,
	isManualIdeaRankingPossibleInCurrentView,
	isCurrentViewSortModeViewRank,
} from './view/view-rank/index.tsx';
import {
	getIsCurrentViewInCaptureSet,
	getAllIdeasViewId,
	getViewSlugByRank,
	createGetViewIdsInSet,
	getPinnedViewId,
	getPinnedViewFields,
	getSystemViewsContainingArchived,
	getSystemViewsNotContainingArchived,
	getViewIdsConfiguredByField,
	getHasViewSetsError,
	getPrioritizeViews,
	getPinnedViewFieldsForSmartCard,
} from './view/view-sets/index.tsx';

export const useGetCurrentView = createViewHook(getCurrentView);

export const useHasViewSetsError = createViewHook(getHasViewSetsError);
export const useHasCurrentView = createViewHook(getHasCurrentView);
export const useCurrentViewKind = createViewHook(getCurrentViewKind);
export const useAllViewFields = createViewHook(getAllViewFields);
export const useCurrentViewFields = createViewHook(getCurrentViewFields);
export const useCurrentViewHiddenFields = createViewHook(getCurrentViewHiddenFields);
export const useCurrentViewFieldKeys = createViewHook(getCurrentViewFieldKeys);
export const useCurrentViewHiddenFieldKeys = createViewHook(getCurrentViewHiddenFieldKeys);
export const useCurrentViewVisibleFields = createViewHook(getCurrentViewVisibleFields);
export const useCurrentViewId = createViewHook(getCurrentViewId);
export const useCurrentViewSlug = createViewHook(getCurrentViewSlug);
export const useCurrentViewAri = createViewHook(getCurrentViewAri);
export const useCurrentViewFieldsUnsupportedForSharing = createViewHook(
	getCurrentViewFieldsUnsupportedForSharing,
);
export const useCurrentViewProjectId = createViewHook(getCurrentViewProjectId);
/**
 * This provides the fields for sorting the current view. NOTE that if the sort mode
 * is not set to fields, this should not have an effect.
 */
export const useCurrentViewSortBy = createViewHook(getCurrentViewSortBy);
export const useCurrentViewPermanentSortBy = createViewHook(getCurrentViewPermanentSortBy);
export const useCurrentViewTemporarySortBy = createViewHook(getCurrentViewTemporarySortBy);
export const useCurrentViewSortByField = createHigherLevelViewHook<FieldKey, SortField | undefined>(
	getCurrentViewSortByField,
);
export const useCurrentSortByFieldCount = createViewHook(getCurrentViewSortByFieldCount);
/**
 * This provides the issue ranking for the current view. If the sort mode is set
 * appropriately this should determine the ranking in the view.
 */
export const useCurrentViewIssueRanking = createViewHook(getCurrentViewIssueRanking);
export const useCurrentViewSortMode = createViewHook(getCurrentViewSortMode);
export const useCurrentViewInViewSortMode = createViewHook(isCurrentViewSortModeViewRank);
export const useIsManualIdeaRankingPossibleInCurrentView = createViewHook(
	isManualIdeaRankingPossibleInCurrentView,
);
export const useHasSharedViewConfigError = createViewHook(hasSharedViewConfigError);
export const useIsLastView = createViewHook(isLastView);
export const useCurrentViewGroupBy = createViewHook(getCurrentViewGroupBy);
export const useCurrentViewHideEmptyGroups = createViewHook(getCurrentHideEmptyGroups);
export const useCurrentViewHideEmptyBoardColumns = createViewHook(getCurrentHideEmptyBoardColumns);
export const useCurrentViewVerticalGroupBy = createViewHook(getCurrentViewVerticalGroupBy);
export const useCurrentViewContainsArchived = createViewHook(getCurrentViewContainsArchived);
export const useCurrentViewGroupValues = createViewHook(getCurrentViewGroupValues);
export const useCurrentViewVerticalGroupValues = createViewHook(getCurrentViewVerticalGroupValues);
export const useSelectedIssueIds = createViewHook(getCurrentViewSelectedIssueIds);
export const useBulkEditHoveredFieldKey = createViewHook(getCurrentViewBulkEditHoveredFieldKey);
export const useCurrentViewSelectedIssueIdsAsList = createViewHook(
	getCurrentViewSelectedIssueIdsAsList,
);
export const useCurrentViewTitle = createViewHook(getCurrentViewTitle);
export const useCurrentViewUUID = createViewHook(getCurrentViewUUID);
export const useCurrentViewRank = createViewHook(getCurrentViewRank);

export const useCurrentViewEmoji = createViewHook(getCurrentViewEmoji);
export const useCurrentViewDescription = createViewHook(getCurrentViewDescription);

export const useIsCurrentViewDescriptionEmpty = createViewHook(getIsCurrentViewDescriptionEmpty);

export const useAutoOpenSidebarCondition = () => {
	const viewUUID = useCurrentViewUUID();
	const isEmptyDescription = useIsCurrentViewDescriptionEmpty();
	const accountId = useCurrentUser();
	const isSharedView = useIsSharedView();
	const isCollectionView = useIsCollectionView();
	const hasVisited = useHasSeenView(viewUUID, accountId, !(isSharedView || isCollectionView));

	if (isSharedView && accountId === undefined) {
		// always auto open sidebar in anonymous published views
		return !isEmptyDescription;
	}

	if (hasVisited === undefined) {
		return undefined;
	}

	return !isEmptyDescription && !hasVisited;
};

export const useCurrentViewIsEditingTitle = createViewHook(getCurrentViewIsEditingTitle);
export const useCurrentViewFieldCount = createViewHook(getCurrentViewFieldCount);
export const useCurrentViewCollapsedSwimlanes = createViewHook(getCurrentViewCollapsedSwimlanes);
export const useAreCurrentViewAllSwimlanesCollapsed = createViewHook(
	areCurrentViewAllSwimlanesCollapsed,
);

export const useCurrentViewTableColumnSizes = createViewHook(getCurrentViewTableColumnSizes);
export const useCurrentViewTableColumnSizesByFieldKey = createViewHook(
	getCurrentViewTableColumnSizesByFieldKey,
);
export const useCurrentViewTableColumnSize = createHigherLevelViewHook<
	FieldKey | undefined,
	number | undefined
>(getCurrentViewTableColumnSize);

export const useCanManageCurrentView = createViewHook(getCanManageCurrentView);

export const useCurrentViewAccessLevel = createViewHook(getCurrentViewAccessLevel);

export const useIsCurrentViewGroupedList = createViewHook(isCurrentViewGroupedList);

export const useViewSetName = createHigherLevelViewHook<Ari, string | undefined>(
	createGetViewSetName,
);
export const useViewIdsInViewSet = createHigherLevelViewHook<ViewSetType, LocalViewId[]>(
	createGetViewIdsInSet,
);

export const useAllIdeasViewId = createViewHook(getAllIdeasViewId);
export const usePinnedViewId = createViewHook(getPinnedViewId);
export const usePinnedViewFields = createViewHook(getPinnedViewFields);
export const usePinnedViewFieldsForSmartCard = createViewHook(getPinnedViewFieldsForSmartCard);
export const useArchiveViewId = createViewHook(getArchiveViewId);

export const useViewSlugByRank = createHigherLevelViewHook<number, string | undefined>(
	getViewSlugByRank,
);

export const useIsCurrentViewInCaptureSet = createViewHook(getIsCurrentViewInCaptureSet);

export const useViewUUID = createHigherLevelViewHook<LocalViewId | undefined, string | undefined>(
	createGetViewUUID,
);
export const useViewAri = createHigherLevelViewHook<LocalViewId | undefined, Ari | undefined>(
	createGetViewAri,
);
export const useViewSlug = createHigherLevelViewHook<LocalViewId | undefined, string | undefined>(
	createGetViewSlug,
);
export const useViewKind = createHigherLevelViewHook<LocalViewId | undefined, ViewKind | undefined>(
	createGetViewKind,
);
export const useViewTitle = createHigherLevelViewHook<LocalViewId | undefined, string | undefined>(
	createGetViewTitle,
);
export const useViewEmoji = createHigherLevelViewHook<LocalViewId | undefined, string | undefined>(
	createGetViewEmoji,
);
export const useViewContainsArchived = createHigherLevelViewHook<LocalViewId | undefined, boolean>(
	createGetViewContainsArchived,
);
export const useViewFilter = createHigherLevelViewHook<string | undefined, Filter[] | undefined>(
	createGetViewFilter,
);
export const useViewFilterLength = createHigherLevelViewHook<
	LocalViewId | undefined,
	number | undefined
>(createGetViewFilterLength);
export const useViewFilterTypes = createHigherLevelViewHook<
	LocalViewId | undefined,
	string[] | undefined
>(createGetViewFilterTypes);
export const useViewFieldTypes = createHigherLevelViewHook<LocalViewId | undefined, FieldType[]>(
	createGetViewFieldTypes,
);
export const useViewFieldKeys = createHigherLevelViewHook<LocalViewId | undefined, FieldKey[]>(
	createGetViewFieldKeys,
);
export const useViewFieldKeysByAri = createHigherLevelViewHook<
	string | undefined,
	FieldKey[] | undefined
>(createGetViewFieldKeysByAri);
export const useValidIssueSubmissionFormFieldKeys = createHigherLevelViewHook<
	string | undefined,
	FieldKey[]
>(createGetValidIssueSubmissionFormFieldKeys);
export const useInvalidIssueSubmissionFormFieldLabels = createHigherLevelViewHook<
	string | undefined,
	FieldKey[]
>(createGetInvalidIssueSubmissionFormFieldLabels);
export const useViewSortFieldTypes = createHigherLevelViewHook<
	LocalViewId | undefined,
	string[] | undefined
>(createGetViewSortFieldTypes);
export const useViewGroupBy = createHigherLevelViewHook<LocalViewId | undefined, Field | undefined>(
	createGetViewGroupBy,
);
export const useViewGroupValues = createHigherLevelViewHook<
	LocalViewId | undefined,
	GroupValue[] | undefined
>(createGetViewGroupValues);

export const useViewTypeCount = createHigherLevelViewHook<ViewKind, number>(getViewTypeCount);

export const useViewsCountFromViewSetTypes = createHigherLevelViewHook<ViewSetType[], number>(
	getViewsCountFromViewSetTypes,
);

export const useSystemViewsContainingArchived = createViewHook(getSystemViewsContainingArchived);
export const useSystemViewsNotContainingArchived = createViewHook(
	getSystemViewsNotContainingArchived,
);

export const useCurrentViewXAxisField = createViewHook(getCurrentViewXAxisField);
export const useCurrentViewYAxisField = createViewHook(getCurrentViewYAxisField);
export const useCurrentViewZAxisField = createViewHook(getCurrentViewZAxisField);
export const useCurrentViewXAxis = createViewHook(getCurrentViewXAxis);
export const useCurrentViewYAxis = createViewHook(getCurrentViewYAxis);
export const useCurrentViewZAxis = createViewHook(getCurrentViewZAxis);
export const useCurrentViewConfigured = createViewHook(getCurrentViewConfigured);
export const useCurrentMatrixOptionsHasReverseOrder = createHigherLevelViewHook<string, boolean>(
	getMatrixOptionsHasReverseOrder,
);
export const useCurrentMatrixFieldOptions = createHigherLevelViewHook<string, GroupValue[]>(
	getMatrixFieldOptions,
);

export const useCurrentViewTimelineMarkers = createViewHook(getCurrentViewTimelineMarkers);
export const useCurrentViewTimelineMode = createViewHook(getCurrentViewTimelineModeOrDefault);
export const useCurrentViewStartDateField = createViewHook(getCurrentViewTimelineStartDateField);
export const useCurrentViewEndDateField = createViewHook(getCurrentViewTimelineEndDateField);
export const useCurrentViewTimelineIntervalStartDate = createViewHook(
	getCurrentViewTimelineIntervalStartDate,
);
export const useCurrentViewTimelineIntervalEndDate = createViewHook(
	getCurrentViewTimelineIntervalEndDate,
);
export const useCurrentViewSummaryCardField = createViewHook(getCurrentViewSummaryCardField);

export const useIsExporting = createViewHook(getIsExporting);
export const useIsReadyToExport = createViewHook(getIsReadyToExport);
export const useHasUnsavedChanges = createViewHook(getHasUnsavedChanges);

export const useCurrentViewArrangementInformation: () => ArrangementInformation | undefined =
	createViewHook(getCurrentViewArrangementInformation);

export const useIsCurrentViewAutosaveEnabled = createViewHook(isCurrentViewAutosaveEnabled);

export const useHasVerticalGroupByUnsavedChanges = createViewHook(hasVerticalGroupByUnsavedChanges);
export const useHasGroupByUnsavedChanges = createViewHook(hasGroupByUnsavedChanges);
export const useHasFiltersUnsavedChanges = createViewHook(hasFiltersUnsavedChanges);
export const useHasSortUnsavedChanges = createViewHook(hasSortUnsavedChanges);
export const useHasFieldsUnsavedChanges = createViewHook(hasFieldsUnsavedChanges);
export const useHasMatrixXAxisUnsavedChanges = createViewHook(hasMatrixXAxisUnsavedChanges);
export const useHasMatrixYAxisUnsavedChanges = createViewHook(hasMatrixYAxisUnsavedChanges);
export const useHasMatrixZAxisUnsavedChanges = createViewHook(hasMatrixZAxisUnsavedChanges);
export const useHasTimelineConfigUnsavedChanges = createViewHook(hasTimelineConfigUnsavedChanges);
export const useHasFieldRollupUnsavedChanges = createViewHook(hasFieldRollupUnsavedChanges);
export const useHasLayoutTypeUnsavedChanges = createViewHook(hasLayoutTypeUnsavedChanges);

export const useHasViewUnsavedChanges = createViewHook(hasViewUnsavedChanges);

export const useHasViewTemporaryData = createViewHook(getHasTemporaryData);

export const useCurrentViewLayoutType = createViewHook(getCurrentViewLayoutType);
export const useViewIdsConfiguredByField = createHigherLevelViewHook(getViewIdsConfiguredByField);
export const usePrioritizeViews = createViewHook(getPrioritizeViews);

export const useViewAnalyticsData = createViewHook(getViewAnalyticsData);

export const useAllViewsNameAndIds: () => { id: string; title: string }[] =
	createViewHook(getAllViewsNameAndIds);

export const useCurrentViewRankFieldKey = createViewHook(getCurrentViewRankFieldKey);

export const useCurrentViewVisibleFieldKeys = createViewHook(getCurrentViewVisibleFieldKeys);

export const useIsFieldVisibleInCurrentView = createHigherLevelViewHook(
	isFieldVisibleInCurrentView,
);

export const useCurrentViewVisibleIssueActionFields = createViewHook(
	getCurrentViewVisibleIssueActionFields,
);

export const usePinnedViewActionFieldKeys = createViewHook(getPinnedViewActionFieldKeys);

export const useBoardViewVisibleFields = () => {
	const visibleFields = useCurrentViewVisibleFields();
	const fieldGroupedBy = useCurrentViewGroupBy();

	if (!fieldGroupedBy) {
		return visibleFields;
	}
	return [fieldGroupedBy, ...visibleFields.filter((field) => field.key !== fieldGroupedBy.key)];
};

export const useMatrixViewVisibleFields = () => {
	const visibleFields = useCurrentViewVisibleFields();
	const xAxisField = useCurrentViewXAxisField();
	const yAxisField = useCurrentViewYAxisField();
	const zAxisField = useCurrentViewZAxisField();

	if (!xAxisField || !yAxisField) {
		return visibleFields;
	}
	if (zAxisField) {
		return [
			xAxisField,
			yAxisField,
			zAxisField,
			...visibleFields.filter(
				(field) =>
					field.key !== xAxisField.key &&
					field.key !== yAxisField.key &&
					field.key !== zAxisField.key,
			),
		];
	}

	return [
		xAxisField,
		yAxisField,
		...visibleFields.filter(
			(field) => field.key !== xAxisField.key && field.key !== yAxisField.key && field.key,
		),
	];
};

export const useTimelineViewVisibleFields = () => {
	const visibleFields = useCurrentViewVisibleFields();
	const fieldStartDate = useCurrentViewStartDateField();
	const fieldEndDate = useCurrentViewEndDateField();

	if (!fieldStartDate || !fieldEndDate) {
		return visibleFields;
	}
	return [
		fieldStartDate,
		fieldEndDate,
		...visibleFields.filter(
			(field) => field.key !== fieldStartDate.key && field.key !== fieldEndDate.key,
		),
	];
};

export const useTimelineViewNeededFields = () => {
	const timelineStartDate = useCurrentViewTimelineIntervalStartDate();
	const timelineEndDate = useCurrentViewTimelineIntervalEndDate();
	if (!timelineStartDate || !timelineEndDate) {
		return [];
	}
	return [timelineStartDate, timelineEndDate];
};

export const useStatusCategoriesForPublishedView = createViewHook(
	getStatusCategoriesForPublishedView,
);

export const useCurrentViewSummaryCardFieldKey: () => FieldKey | undefined = createViewHook(
	getCurrentViewSummaryCardFieldKey,
);

export const useHasReachedViewFieldsLimit = () => {
	const entityLimitMessage = useEntityLimitMessage();

	const fieldCount = useCurrentViewFieldCount();
	const fieldsLimit = useEntityLimitsByType(ENTITY_LIMIT_TYPE.FIELDS_PER_VIEW);

	if (fieldsLimit && fieldCount >= fieldsLimit) {
		return entityLimitMessage(ENTITY_LIMIT_TYPE.FIELDS_PER_VIEW, fieldsLimit);
	}

	return null;
};

export const useCanManageView = (
	viewId: string | undefined,
	shouldLoadViewPermissions?: boolean,
) => {
	const viewUUID = useViewUUID(viewId);
	const isCollectionView = useIsCollectionView();
	const collectionId = useEnvironmentContainer()?.id ?? '';
	const canManageViewBasedOnViewUUID = useCanManageViewBasedOnViewPermissions(
		viewUUID,
		viewId,
		shouldLoadViewPermissions ?? !isCollectionView,
	);

	const canEditCollection = useCanEditCollection({
		collectionUUID: collectionId,
		forceLoading: isCollectionView,
	});

	if (isCollectionView) {
		return canEditCollection;
	}

	return canManageViewBasedOnViewUUID.canEdit;
};

export const useRemoveViewIfNoAccess = (viewId: string | undefined) => {
	const { deleteView } = useViewActions();
	const viewUUID = useViewUUID(viewId);
	const isCollectionView = useIsCollectionView();
	const canManageViewBasedOnViewUUID = useCanManageViewBasedOnViewPermissions(
		viewUUID,
		viewId,
		false,
	);

	if (!isCollectionView && viewId && viewUUID && !canManageViewBasedOnViewUUID.canView) {
		deleteView(viewId, true);
	}
};

export const useViewAccessLevel = (
	viewId: string | undefined,
	shouldLoadViewPermissions?: boolean,
): ViewAccessLevel | undefined => {
	const viewUUID = useViewUUID(viewId);
	const viewAccessLevel = useViewAccessLevelBasedOnViewPermissions(
		viewUUID,
		viewId,
		!!shouldLoadViewPermissions,
	);

	return viewAccessLevel;
};

export const useCanManagePermanentFilters = (): boolean => {
	const canManageCurrentView = useCanManageCurrentView();

	return fg('polaris_just-for-you') ? canManageCurrentView : true;
};

export const useCanManagePermanentSort = (): boolean => {
	const canManageCurrentView = useCanManageCurrentView();

	return fg('polaris_just-for-you') ? canManageCurrentView : true;
};

export const useCanManageAdvancedSortConfig = (): boolean => {
	const canManageCurrentView = useCanManageCurrentView();

	return canManageCurrentView;
};
