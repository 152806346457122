export const LOOM_BANNER_AD_CONTROL_DISMISS_KEY = 'loom-banner-ad-control-dismissed';
export const LOOM_QUICK_ADD_AD_CONTROL_DISMISS_KEY = 'loom-quick-add-ad-control-dismissed';

export const BANNER_LOCATIONS = {
	DESCRIPTION: 'Description',
	COMMENT: 'Comment',
} as const;

export type BannerLocation = typeof BANNER_LOCATIONS.DESCRIPTION | typeof BANNER_LOCATIONS.COMMENT;

export const DATA_PRIVACY_LINK = 'https://www.atlassian.com/legal/privacy-policy';
