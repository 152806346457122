import React, { useCallback, useEffect, useMemo, useRef } from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled, { keyframes } from 'styled-components';
import { AnalyticsContext } from '@atlaskit/analytics-next';
import { useSmartLinkLifecycleAnalytics } from '@atlaskit/link-analytics';
import { SmartCardProvider } from '@atlaskit/link-provider';
import {
	type ActionItem,
	ActionName,
	Card,
	CardAction,
	TitleBlock,
	ElementName,
	SmartLinkPosition,
	SmartLinkSize,
} from '@atlaskit/smart-card';
import { colors, noFocusRing } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { EMBEDDED_CONFLUENCE_MODE } from '@atlassian/embedded-confluence';
import {
	EmbeddedConfluenceSidepanelContainer,
	useEmbeddedConfluenceSidePanel,
} from '@atlassian/jira-confluence-integration-controls/src/controllers/use-embedded-confluence-side-panel/index.tsx';
import { ConfluenceContentTypes } from '@atlassian/jira-confluence-integration-controls/src/controllers/use-embedded-confluence-side-panel/types.tsx';
import { useUserHasConfluenceAccess } from '@atlassian/jira-confluence-integration-controls/src/controllers/use-user-has-confluence-access/index.tsx';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { type FlagId, type FlagMessage, toFlagId, useFlagsService } from '@atlassian/jira-flags';
import type { ShowFlag } from '@atlassian/jira-flags/src/services/types';
import {
	useIsEmbedPageIssueView,
	useIsFullPageIssueView,
	useIsModal,
} from '@atlassian/jira-issue-context-service/src/main.tsx';
import { JIRA_ISSUE_LINKED_MENTIONED_PAGES } from '@atlassian/jira-issue-create-confluence-content/src/common/constants/embedded-confluence-source.tsx';
import { useEmbeddedPageModalEntryPoint } from '@atlassian/jira-issue-create-confluence-content/src/controllers/use-embedded-page-modal-entry-point/index.tsx';
import { useEmbeddedWhiteboardModalEntryPoint } from '@atlassian/jira-issue-create-confluence-content/src/controllers/use-embedded-whiteboard-modal-entry-point/index.tsx';
import { useParentProduct } from '@atlassian/jira-issue-create-confluence-content/src/controllers/use-parent-product/index.tsx';
import type { ConfluencePage } from '@atlassian/jira-issue-shared-types/src/common/types/confluence-content-type.tsx';
import type { FailedRemoteLink } from '@atlassian/jira-issue-shared-types/src/common/types/remote-link-error-type.tsx';
import {
	useSmartCardProductType,
	useSmartCardIsAIEnabled,
} from '@atlassian/jira-linking-platform-utils/src/index.tsx';
import { generateNanoId } from '@atlassian/jira-uuid/src/index.tsx';
import messages from '../web-link-line-card-group/line-card/error-message/messages.tsx';
import { smartLinksAttributes } from './smart-link-analytics.tsx';
import { styleOverrides } from './style-overrides.tsx';
import type { DispatchProps, Props, StateProps } from './types.tsx';
import uiOptions from './ui-options.tsx';

const getErrorFlagConfiguration = (
	id: FlagId,
	title: FlagMessage,
	onRetry: () => void,
	onCancel: () => void,
): ShowFlag => ({
	id,
	title,
	description: messages.errorBody,
	type: 'warning',
	isAutoDismiss: true,
	actions: [
		{
			content: messages.retryAction,
			onClick: onRetry,
		},
		{
			content: messages.cancelAction,
			onClick: onCancel,
		},
	],
});

const SmartLinkContent = (props: Props & DispatchProps & StateProps) => {
	const {
		analyticsLocation,
		canDelete,
		deleteFailed,
		linkId,
		smartLinkId,
		text,
		url,
		onClick,
		onDeleteClick,
		onDeleteWebLinkRetry,
		onDeleteWebLinkCancel,
		loadConfluenceInNewTab,
		page,
		whiteboard,
		hasActivatedConfluenceWithinFourteenDays,
		entryPointWrapper,
	} = props;

	const { entryPointActions: pageEntryPointActions, entryPoint: pageEntrypoint } =
		useEmbeddedPageModalEntryPoint(page, JIRA_ISSUE_LINKED_MENTIONED_PAGES);
	const { entryPointActions: whiteboardEntryPointActions, entryPoint: whiteboardEntryPoint } =
		useEmbeddedWhiteboardModalEntryPoint(whiteboard, JIRA_ISSUE_LINKED_MENTIONED_PAGES);

	const entryPoint = pageEntrypoint || whiteboardEntryPoint;
	const entryPointActions = pageEntryPointActions || whiteboardEntryPointActions;
	const { showFlag, dismissFlag } = useFlagsService();
	const linkAnalytics = useSmartLinkLifecycleAnalytics();
	const deleteFailedOld = useRef(false);

	const isFullIssueView = useIsFullPageIssueView();

	const isModalView = useIsModal();

	const isEmbedView = useIsEmbedPageIssueView();

	const parentProduct = useParentProduct();

	const { hasAccess: hasConfluenceAccess } = useUserHasConfluenceAccess(
		JIRA_ISSUE_LINKED_MENTIONED_PAGES,
	);

	const shouldShowHoverPreview = isFullIssueView || isModalView || isEmbedView;

	const analyticsData = {
		attributes: {
			location: analyticsLocation,
		},
	};

	const showErrorFlag = useCallback(
		(title: FlagMessage, onRetry: () => void, onCancel: () => void) => {
			const flagId = toFlagId(generateNanoId());
			const dismissAndRetry = () => {
				dismissFlag(flagId);
				onRetry();
			};
			const dismissAndCancel = () => {
				dismissFlag(flagId);
				onCancel();
			};

			showFlag(getErrorFlagConfiguration(flagId, title, dismissAndRetry, dismissAndCancel));
		},
		[showFlag, dismissFlag],
	);

	const showDeleteErrorFlag = useCallback(
		() => showErrorFlag(messages.deleteErrorHeading, onDeleteWebLinkRetry, onDeleteWebLinkCancel),
		[onDeleteWebLinkRetry, onDeleteWebLinkCancel, showErrorFlag],
	);

	useEffect(() => {
		if (!deleteFailedOld.current && deleteFailed) {
			showDeleteErrorFlag();
			deleteFailedOld.current = true;
		}
	}, [deleteFailed, showDeleteErrorFlag]);

	/* Initializing Experiment for SidePanel EP */
	const isEligibleForSidePanelEPExp = useMemo(
		() =>
			hasConfluenceAccess &&
			isFullIssueView &&
			!hasActivatedConfluenceWithinFourteenDays &&
			parentProduct !== 'JSM',
		[hasConfluenceAccess, isFullIssueView, hasActivatedConfluenceWithinFourteenDays, parentProduct],
	);

	const [, sidePanelEPExperimentfireExposureEventAA] = UNSAFE_noExposureExp(
		'jira_issue_view_side_by_side_modeless_ep_aa_exp',
	);

	const [sideBySideEPExperimentConfig, sidePanelEPExperimentfireExposureEvent] =
		UNSAFE_noExposureExp('jira_issue_view_side_by_side_modeless_ep_exp');

	const [, embeddedConfluenceSidePanelActions] = useEmbeddedConfluenceSidePanel(
		JIRA_ISSUE_LINKED_MENTIONED_PAGES,
	);

	const sendDeletedEvent = useCallback(() => {
		linkAnalytics?.linkDeleted(
			{
				url,
				smartLinkId: linkId,
			},
			null,
			{
				...smartLinksAttributes,
				location: analyticsLocation,
			},
		);
	}, [url, linkId, analyticsLocation, linkAnalytics]);

	const cardOnclick = useCallback(
		(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			const isFailedRemoteLink = !page || !!(page as FailedRemoteLink).error;
			const openInModal = () => {
				entryPointActions?.load();
				onClick?.(event);
			};

			if (!loadConfluenceInNewTab && !isFailedRemoteLink && (page || whiteboard)) {
				event.preventDefault();
				if (page && isEligibleForSidePanelEPExp) {
					sidePanelEPExperimentfireExposureEventAA();
					sidePanelEPExperimentfireExposureEvent();
					if (sideBySideEPExperimentConfig.get('cohort', 'not-enrolled') === 'experiment') {
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						const confluencePage = page as ConfluencePage;
						embeddedConfluenceSidePanelActions.openSidePanel({
							contentType: ConfluenceContentTypes.PAGE,
							mode: EMBEDDED_CONFLUENCE_MODE.VIEW_MODE,
							hostname: confluencePage?.hostname,
							url: confluencePage?.href,
							title: confluencePage?.title,
							openInModal,
						});
						return;
					}
				}
				openInModal();
			} else {
				onClick?.(event);
			}
		},
		[
			entryPointActions,
			loadConfluenceInNewTab,
			onClick,
			page,
			whiteboard,
			isEligibleForSidePanelEPExp,
			sidePanelEPExperimentfireExposureEvent,
			sidePanelEPExperimentfireExposureEventAA,
			sideBySideEPExperimentConfig,
			embeddedConfluenceSidePanelActions,
		],
	);

	const onDeleteCallback = useCallback(() => {
		onDeleteClick && onDeleteClick(linkId);
		sendDeletedEvent();
	}, [linkId, onDeleteClick, sendDeletedEvent]);

	const actions: ActionItem[] = useMemo(
		() =>
			canDelete
				? [
						{
							name: ActionName.DeleteAction,
							onClick: onDeleteCallback,
							hideContent: true,
							entryPointWrapper,
						},
					]
				: // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					([] as ActionItem[]),
		[canDelete, onDeleteCallback, entryPointWrapper],
	);

	const smartLink = useMemo(
		() => (
			<Card
				id={linkId}
				key={smartLinkId}
				appearance="block"
				url={url}
				ui={uiOptions}
				onClick={cardOnclick}
				placeholder=" "
				showHoverPreview={shouldShowHoverPreview}
				showAuthTooltip={shouldShowHoverPreview}
				actionOptions={{
					hide: false,
					exclude: [CardAction.ChangeStatusAction],
				}}
			>
				<TitleBlock
					// https://product-fabric.atlassian.net/browse/EDM-3980 fix this override type
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
					overrideCss={styleOverrides as any}
					position={SmartLinkPosition.Center}
					metadata={[
						{
							name: ElementName.ModifiedOn,
						},
						{
							name: ElementName.State,
						},
						{
							name: ElementName.AuthorGroup,
							size: SmartLinkSize.Large,
						},
					]}
					text={text}
					actions={actions}
					showActionOnHover
					maxLines={1}
					anchorTarget="_blank"
					hideTitleTooltip={shouldShowHoverPreview}
				/>
			</Card>
		),
		[linkId, smartLinkId, url, cardOnclick, shouldShowHoverPreview, text, actions],
	);

	return (
		<AnalyticsContext data={analyticsData}>
			<CardWrapper>{smartLink}</CardWrapper>
			{entryPoint}
		</AnalyticsContext>
	);
};

const ConfluenceContentWrapper = ({ children }: { children: React.ReactNode }) =>
	fg('jira_issue_view_modeless_ep_container') ? (
		<EmbeddedConfluenceSidepanelContainer>{children}</EmbeddedConfluenceSidepanelContainer>
	) : (
		<>{children}</>
	);

const SmartLinkContentWithSmartCardProvider = (props: Props & DispatchProps & StateProps) => {
	const smartCardProviderProduct = useSmartCardProductType();
	const isAIFeatureEnabled = useSmartCardIsAIEnabled();

	return (
		<SmartCardProvider product={smartCardProviderProduct} isAdminHubAIEnabled={isAIFeatureEnabled}>
			<ConfluenceContentWrapper>
				<SmartLinkContent {...props} />
			</ConfluenceContentWrapper>
		</SmartCardProvider>
	);
};

export default SmartLinkContentWithSmartCardProvider;

const animationNameStyles = keyframes({
	'0%': {
		backgroundPosition: '-300px 0',
	},
	'100%': {
		backgroundPosition: '1000px 0',
	},
});

const loadingShimmer = `
  animation-duration: 1.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${animationNameStyles};
  animation-timing-function: linear;
  background-color: ${token('color.background.neutral', colors.N30)};
  background-image: linear-gradient(
      to right,
      ${token('color.background.neutral', colors.N30)} 10%,
      ${token('color.background.neutral.subtle', colors.N20)} 30%,
      ${token('color.background.neutral', colors.N30)} 50%
  );
  background-repeat: no-repeat;
  border-radius: 4px;
`;

// TODO: migrate to object syntax. Autofix is available for many cases. Remove the eslint-disable for @atlaskit/design-system/no-styled-tagged-template-expression to check.
// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardWrapper = styled.li`
	list-style: none;

	&:last-child {
		${noFocusRing};
		border-bottom-color: transparent;
	}

	&:hover {
		background: ${token('color.background.neutral.subtle.hovered', colors.N30)};
		text-decoration: none;
	}

	border-bottom: 1px solid ${token('color.border', colors.N30)};
	box-shadow: none;
	background: ${token('color.background.neutral.subtle', colors.N0)};
	padding: 0 ${token('space.075', '6px')};
	margin: 0 ${token('space.025', '2px')};
	border-radius: 3px;

	.smart-link-loading-placeholder {
		margin-left: -0.5rem;
		height: 20px;
		border-bottom: solid ${token('color.background.neutral.subtle', colors.N0)} 10px;
		border-top: solid ${token('color.background.neutral.subtle', colors.N0)} 10px;
		border-left: solid ${token('color.background.neutral.subtle', colors.N0)} 14px;
		border-right: solid ${token('color.background.neutral.subtle', colors.N0)} 14px;
		width: calc(100% + -1rem);
		display: block;
		${loadingShimmer}
	}
`;
