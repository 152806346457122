// import type { TeamValue } from '@atlassian/jira-issue-field-team/src/common/types.tsx'; POL-12642
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { IssuesRemote } from '@atlassian/jira-polaris-remote-issue/src/controllers/types.tsx';
import { defaultJiraUpdateService } from '../common/index.tsx';
import { TEAM_SELECT } from '../constants.tsx';
import type { JiraFieldMapping } from '../types.tsx';

// TODO: POL-12642 - update this file to use TeamValue instead of string

export const jiraTeamMapping = (
	issuesRemote: IssuesRemote,
	field: Field,
): JiraFieldMapping<string | undefined> => ({
	field,
	getValueFromJiraIssue: (issue) => {
		// TODO: POL-12642 - Remove the value/id workaround
		// the state initially only stores ids but we get the full object when we fetch the new issue
		if (typeof issue.fields[field.key] === 'string') {
			return issue.fields[field.key];
		}
		return issue.fields[field.key]?.id;
	},
	getFieldValueForJiraUpdate: (value) => {
		if (!value) {
			return null;
		}
		return value;
	},
	getFieldValueForJiraBulkUpdate: (value) => {
		return {
			[TEAM_SELECT]: {
				fieldId: field.key,
				team: { teamId: value ?? '' },
			},
		};
	},
	isMultiValueField: false,
	isSupportedByIssueUpdateApi: true,
	...defaultJiraUpdateService(issuesRemote, field.key),
});
