import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { BaseUrl, IssueId } from '@atlassian/jira-shared-types/src/general.tsx';

const getUpdateRequestLanguageUrl = (baseUrl: string, issueId: IssueId): string =>
	`${baseUrl}/rest/servicedesk/lingo-integration/1/request-language/${issueId}`;

export const updateRequestLanguage = async (
	baseUrl: BaseUrl,
	issueId: IssueId | null | undefined,
	languageCode: string,
) => {
	if (!issueId) {
		throw new Error('Issue ID not available');
	}
	return performPutRequest(getUpdateRequestLanguageUrl(baseUrl, issueId), {
		body: JSON.stringify({ languageCode }),
	});
};
