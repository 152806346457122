/**
 * @generated SignedSource<<1a0405500622336932d5ead6480a3058>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutSentimentField_IssueViewSentimentField$data = {
  readonly fieldConfig: {
    readonly isEditable: boolean | null | undefined;
  } | null | undefined;
  readonly fieldId: string;
  readonly " $fragmentSpreads": FragmentRefs<"sentiment_issueFieldSentimentInlineEditFull_SentimentInlineEditView" | "ui_issueViewLayoutFieldHeading_IssueViewFieldHeading">;
  readonly " $fragmentType": "ui_issueViewLayoutSentimentField_IssueViewSentimentField";
};
export type ui_issueViewLayoutSentimentField_IssueViewSentimentField$key = {
  readonly " $data"?: ui_issueViewLayoutSentimentField_IssueViewSentimentField$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutSentimentField_IssueViewSentimentField">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutSentimentField_IssueViewSentimentField",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "ui_issueViewLayoutFieldHeading_IssueViewFieldHeading"
    },
    {
      "kind": "FragmentSpread",
      "name": "sentiment_issueFieldSentimentInlineEditFull_SentimentInlineEditView"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "concreteType": "JiraFieldConfig",
      "kind": "LinkedField",
      "name": "fieldConfig",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isEditable"
        }
      ]
    }
  ],
  "type": "JiraServiceManagementSentimentField"
};

(node as any).hash = "03500e68a2278c0d35e435061403c8ad";

export default node;
