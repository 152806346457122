/**
 * @generated SignedSource<<1f329b3dd8924e74c742345f95e2d6dd>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutPriorityField_IssueViewPriorityField$data = {
  readonly __typename: "JiraPriorityField";
  readonly fieldId: string;
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"priority_issueFieldPriorityInlineEditFull_PriorityInlineEditView_fragmentRef" | "ui_issueViewLayoutFieldHeading_IssueViewFieldHeading">;
  readonly " $fragmentType": "ui_issueViewLayoutPriorityField_IssueViewPriorityField";
};
export type ui_issueViewLayoutPriorityField_IssueViewPriorityField$key = {
  readonly " $data"?: ui_issueViewLayoutPriorityField_IssueViewPriorityField$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutPriorityField_IssueViewPriorityField">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutPriorityField_IssueViewPriorityField",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "ui_issueViewLayoutFieldHeading_IssueViewFieldHeading"
    },
    {
      "kind": "FragmentSpread",
      "name": "priority_issueFieldPriorityInlineEditFull_PriorityInlineEditView_fragmentRef"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "ScalarField",
      "name": "__typename"
    }
  ],
  "type": "JiraPriorityField"
};

(node as any).hash = "691099efb66b23d05ae1b58d3b61b490";

export default node;
