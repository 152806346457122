import {
	SuggestedCommentReplyAgentCommentType,
	type SuggestedCommentReplyCommentTypes,
} from '@atlassian/jira-ai-assistance-service-client/src/services/invoke-agent/suggested-comment-reply-agent/types.tsx';
import type { MessageDescriptor } from '@atlassian/jira-intl';
import messages from './messages.tsx';

const maxNumberOfCommentTypes = 3;

const getAvailableCommentTypes: (props: {
	totalOfComments: number;
	hasMissingAssignee: boolean;
	hasMissingDescription: boolean;
	acceptedCommentTypes?: SuggestedCommentReplyCommentTypes;
}) => SuggestedCommentReplyCommentTypes = ({
	totalOfComments,
	hasMissingAssignee,
	hasMissingDescription,
	acceptedCommentTypes,
}) => {
	let result: SuggestedCommentReplyCommentTypes = [];
	if (totalOfComments > 0) {
		result.push(SuggestedCommentReplyAgentCommentType.JIRA_COMMENT_REPLY);
	}
	if (hasMissingAssignee) {
		result.push(SuggestedCommentReplyAgentCommentType.JIRA_ASSIGNEE_MISSING);
	}
	if (hasMissingDescription) {
		result.push(SuggestedCommentReplyAgentCommentType.JIRA_DESCRIPTION_MISSING);
	}

	result.push(SuggestedCommentReplyAgentCommentType.JIRA_STATUS_UPDATE);
	result.push(SuggestedCommentReplyAgentCommentType.JIRA_MOTIVATION);

	if (acceptedCommentTypes) {
		result = result.filter((commentType) => !acceptedCommentTypes.includes(commentType));
	}
	// always returns the three general comment types
	result.push(SuggestedCommentReplyAgentCommentType.QUICK_REPLY);
	result.push(SuggestedCommentReplyAgentCommentType.QUESTION);
	result.push(SuggestedCommentReplyAgentCommentType.SUGGESTION);

	return result;
};

const getDisplayMessageForCommentType = (commentType: SuggestedCommentReplyAgentCommentType) => {
	switch (commentType) {
		case SuggestedCommentReplyAgentCommentType.JIRA_COMMENT_REPLY:
			return messages.commentReply;
		case SuggestedCommentReplyAgentCommentType.JIRA_ASSIGNEE_MISSING:
			return messages.assigneeMissing;
		case SuggestedCommentReplyAgentCommentType.JIRA_DESCRIPTION_MISSING:
			return messages.descriptionMissing;
		case SuggestedCommentReplyAgentCommentType.JIRA_STATUS_UPDATE:
			return messages.statusUpdate;
		case SuggestedCommentReplyAgentCommentType.JIRA_MOTIVATION:
			return messages.motivation;
		case SuggestedCommentReplyAgentCommentType.QUICK_REPLY:
			return messages.quickReply;
		case SuggestedCommentReplyAgentCommentType.QUESTION:
			return messages.question;
		case SuggestedCommentReplyAgentCommentType.SUGGESTION:
			return messages.suggestion;
		default:
			return null;
	}
};

export type CommentTypeOption = {
	value: SuggestedCommentReplyAgentCommentType;
	message: MessageDescriptor;
};

export const getAvailableCommentTypeOptions = (props: {
	totalOfComments: number;
	hasMissingAssignee: boolean;
	hasMissingDescription: boolean;
	acceptedCommentTypes?: SuggestedCommentReplyCommentTypes;
}) => {
	const availableCommentTypes = getAvailableCommentTypes(props);
	const commentTypes: SuggestedCommentReplyCommentTypes = [];
	const commentTypeOptions: CommentTypeOption[] = [];
	availableCommentTypes.forEach((commentType) => {
		const displayMessage = getDisplayMessageForCommentType(commentType);
		if (!displayMessage) {
			return;
		}
		commentTypes.push(commentType);
		commentTypeOptions.push({
			value: commentType,
			message: displayMessage,
		});
	});
	return {
		commentTypes: commentTypes.slice(0, maxNumberOfCommentTypes),
		commentTypeOptions: commentTypeOptions.slice(0, maxNumberOfCommentTypes),
	};
};
