// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	commentWorklogAttachmentFetchFailedTitle: {
		id: 'issue.comment-worklog-attachment-fetch-failed-title',
		defaultMessage: "We couldn't find the {field}",
		description: 'Displays error flag for when comment/worklog attachment fetch fails',
	},
	commentWorklogAttachmentFetchFailedDescription: {
		id: 'issue.comment-worklog-attachment-fetch-failed-description',
		defaultMessage: 'Reload the issue, then give it another try.',
		description: 'Displays error flag for when comment/worklog attachment fetch fails',
	},
});
