import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	externalLink: {
		id: 'development-summary-common.summary-item.external-link',
		defaultMessage: 'Link to external website in new tab',
		description:
			'Descriptive label placed on an icon next to an anchor link describing that the anchor will navigate to an external site in a new tab',
	},
});
