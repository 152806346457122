import type { PropertyMaps, DynamicPropertyMaps, State } from './types.tsx';

export const IDEA_SECTION_LAST_VIEWED = 'POLARIS_IDEA_TABS_LAST_VIEWED';
export const ISSUES_PAGE_LIMIT = 5000;

export const EMPTY_PROPERTIES: PropertyMaps = {
	number: {},
	string: {},
	stringList: {},
	status: {},
	issueType: {},
	user: {},
	people: {},
	singleSelect: {},
	multiSelect: {},
	created: {},
	insights: {},
	insightsMetadata: {},
	linkedDeliveryIssues: {},
	plays: {},
	externalReference: {},
	lexoRank: {},
	document: {},
	commentsMetadata: {},
	externalReferenceEntities: {},
	reactions: {},
	aggregatedDeliveryProgress: {},
	aggregatedDeliveryDates: {},
	issueMetadata: {},
	projects: {},
	connection: {},
	team: {},
};

export const EMPTY_DYNAMIC_PROPERTIES: DynamicPropertyMaps<State> = {
	numberValue: {},
	numberValues: {},
	dateValue: {},
	dateValues: {},
};

export const EMPTY_STATE: State = {
	meta: {
		initialized: false,
		initializedArchived: false,
		loading: false,
		loadingArchivedIssues: false,
		loadingLinkedIssues: false,
		error: undefined,
		archivedError: undefined,
		loadingProps: undefined,
		isSingleIssueLoaded: false,
		singleIssueLoadedTimestamp: undefined,
		fullDeliveryDataInitialized: false,
	},
	selectedIssue: undefined,
	lastUpdatedIssueIds: [],
	ids: [],
	externalIssueRanking: undefined,
	properties: EMPTY_PROPERTIES,
	dynamicProperties: EMPTY_DYNAMIC_PROPERTIES,
	externalIssueData: {},
	containerProps: undefined,
	openUpdateCounter: {},
	prefechedIssues: undefined,
	archivedIssues: undefined,
};

export const JPD_CONNECTION_ISSUE_LINK_TYPE = 'Discovery - Connected';
