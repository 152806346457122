// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	noValueText: {
		id: 'issue.multiline-field.empty-placeholder',
		defaultMessage: 'None',
		description: 'Placeholder text for when there is no content in the multiline field.',
	},
});
