// These were copied across from src/packages/issue/issue-view/src/common/constants/index.tsx to avoid circular deps
export const MODAL_DROPDOWN_PORTAL_CLASS_NAME = 'issue-app-modal-ak-dropdown-portal';
export const EDITOR_DROPDOWN_PORTAL_CLASS_NAME = 'issue-app-modal-editor-dropdown-portal';

export const getIssueModalAkDropdownPortal = () =>
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	document.querySelector(`.${MODAL_DROPDOWN_PORTAL_CLASS_NAME}`);

export const getIssueModalEditorDropdownPortal = () =>
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	document.querySelector(`.${EDITOR_DROPDOWN_PORTAL_CLASS_NAME}`);
