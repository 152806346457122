import { useEffect, useState } from 'react';

export type useAIMockStreamingReturn = {
	delayedContent: string;
	isComplete: boolean;
};

export const useAIMockStreaming = (
	content?: string,
	totalDuration: number = 1000,
	intervalDuration: number = 50,
): useAIMockStreamingReturn => {
	const [delayedContent, setDelayedContent] = useState<string>('');
	const [isComplete, setIsComplete] = useState<boolean>(false);

	useEffect(() => {
		const contentLength = content?.length || 0;
		const numberOfUpdates = totalDuration / intervalDuration;
		const increment = Math.ceil(contentLength / numberOfUpdates);

		let currentIndex = 0;
		let interval: NodeJS.Timeout;

		if (content?.length) {
			interval = setInterval(() => {
				currentIndex += increment;
				if (currentIndex >= contentLength) {
					currentIndex = contentLength;
					clearInterval(interval);
					setIsComplete(true);
				}

				setDelayedContent(content.slice(0, currentIndex));
			}, intervalDuration);
		}

		return () => clearInterval(interval);
	}, [content, intervalDuration, totalDuration]);

	return { delayedContent, isComplete };
};
