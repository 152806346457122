import type { ReactNode } from 'react';

type Window = {
	document: ReactNode & {
		dispatchEvent: (arg1: Event) => void;
		body: HTMLBodyElement;
		links: HTMLAnchorElement[];
	};
	location: {
		assign: (url: string) => void;
		replace: (url: string) => void;
		href: string;
		origin: string;
		protocol: string;
		hostname: string;
		port: number;
	};
	name: string;
	parent: Window;
	origin: string;
	postMessage: (message: unknown, targetOrigin: string) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	addEventListener: (arg1: string, arg2: (arg1: any) => unknown) => unknown;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	removeEventListener: (arg1: string, arg2: (arg1: any) => unknown) => void;
	HTMLElement: HTMLElement;
	Element: Element;
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (win: Window) => {
	const satisfiesSameOrigin = (w: Window): boolean => {
		try {
			// Accessing location.href from a window on another origin will throw an exception.
			if (w.location.href == null) {
				return false;
			}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			return false;
		}
		return true;
	};

	const isTopMostWindow = (w: Window): boolean => w === w.parent;

	let topWindow = win;

	while (!isTopMostWindow(topWindow) && satisfiesSameOrigin(topWindow.parent)) {
		topWindow = topWindow.parent;
	}
	return topWindow;
};
