import keyBy from 'lodash/keyBy';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { OptionFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/option/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { MULTISELECT_COUNT_TEMPLATE } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/multi-select-count/types.tsx';
import type { DynamicFieldFormula } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/types.tsx';
import { getAllFieldsByKey } from '../../../../selectors/fields.tsx';
import { optionMapping } from '../../../field-mapping/option/index.tsx';
import { optionsMapping } from '../../../field-mapping/options/index.tsx';
import type { DynamicFieldResolver } from '../../types.tsx';

export const multiselectCountResolver: DynamicFieldResolver<number> = (
	formula: DynamicFieldFormula,
) => {
	if (formula === undefined || formula.template !== MULTISELECT_COUNT_TEMPLATE) {
		return undefined;
	}

	return (localIssueId: LocalIssueId) => (state, props) => {
		const baseField = getAllFieldsByKey(state, props)[formula.parameters.id];

		if (baseField === undefined) {
			return undefined;
		}

		if (
			baseField.type !== FIELD_TYPES.SINGLE_SELECT &&
			baseField.type !== FIELD_TYPES.MULTI_SELECT &&
			baseField.type !== FIELD_TYPES.JSW_MULTI_SELECT
		) {
			return undefined;
		}

		const mapping =
			baseField.type === FIELD_TYPES.SINGLE_SELECT
				? // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain, @typescript-eslint/no-non-null-assertion
					optionMapping(props?.issuesRemote!, baseField)
				: // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain, @typescript-eslint/no-non-null-assertion
					optionsMapping(props?.issuesRemote!, baseField);

		const value = mapping.valueAccessor(state, props, localIssueId);

		if (value === undefined) {
			return undefined;
		}

		const arrValue: OptionFieldValue[] = Array.isArray(value) ? value : [value];

		const optionsById = keyBy(baseField.options, (option) => option.jiraOptionId);
		const sumOfOptionWeights = arrValue.reduce((acc, cur) => {
			if (!cur) {
				return acc;
			}
			const weight = optionsById[cur.id] ? optionsById[cur.id].weight : 1;
			return acc + weight;
		}, 0);
		return sumOfOptionWeights;
	};
};
