import React, {
	useEffect,
	useCallback,
	type ReactNode,
	// eslint-disable-next-line jira/restricted/react-component-props
	type ComponentProps,
	type CSSProperties,
} from 'react';
import { Text } from '@atlaskit/primitives';
import Select, { components } from '@atlaskit/select';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { defaultSelectStyles } from '@atlassian/jira-issue-internal-field-select/src/common/select-inline-edit/select-field/styled.tsx';
import { Ellipsis } from '@atlassian/jira-issue-view-common-styles/src/issue-value.tsx';
import useConfluenceSpaceService from '../../services/spaces/index.tsx';
import type { Option } from '../../services/spaces/types.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const customSelectStyle = (isCompact: boolean) => ({
	...defaultSelectStyles,
	// @ts-expect-error - TS7006 - Parameter 'base' implicitly has an 'any' type. | TS7006 - Parameter 'state' implicitly has an 'any' type.
	menu: (base, state) => {
		const defaultMenu = defaultSelectStyles.menu ? defaultSelectStyles.menu(base, state) : {};
		return {
			...defaultMenu,
			minWidth: isCompact ? 'auto' : '140%',
		};
	},
});
export const SingleValue = ({ children, ...props }: { children: ReactNode; data: Option }) => {
	const { formatMessage } = useIntl();
	const { data } = props;

	return (
		// @ts-expect-error - TS2740 - Type '{ children: Element; data: Option; }' is missing the following properties from type 'CommonProps<Option, false, GroupTypeBase<Option>>': clearValue, cx, getStyles, getValue, and 8 more.
		<components.SingleValue {...props}>
			<Tooltip content={data.label} position="top">
				{data ? (
					<div>{data.label}</div>
				) : (
					<Text color="color.text.subtlest">{formatMessage(messages.filterBySpace)}</Text>
				)}
			</Tooltip>
		</components.SingleValue>
	);
};

// export const CustomOption = (
//     props: JSX.LibraryManagedAttributes<
//         typeof components.Option,
//         ComponentProps<typeof components.Option>
//     >,
// ) => {
//     const { innerProps, innerRef, getStyles, data } = props;
//     const style = getStyles('option', props);
//     return (
//         <OptionContainer
//             /* eslint-disable-next-line jira/react/no-style-attribute */
//             style={style}
//         >
//             <div {...innerProps} ref={innerRef}>
//                 <Ellipsis>{data.label}</Ellipsis>
//             </div>
//         </OptionContainer>
//     );
// };

export const CustomOptionNew = (
	props: JSX.LibraryManagedAttributes<
		typeof components.Option,
		ComponentProps<typeof components.Option>
	>,
) => {
	const { innerProps, innerRef, getStyles, data } = props;
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const style = getStyles('option', props) as CSSProperties;
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const { label } = data as Option;

	return (
		<div
			/* eslint-disable-next-line jira/react/no-style-attribute, @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766 */
			style={style}
		>
			<div {...innerProps} ref={innerRef}>
				<Ellipsis>{label}</Ellipsis>
			</div>
		</div>
	);
};

const filterOption = () => true;

const ConfluenceSpaceSelect = (props: Props) => {
	const { formatMessage } = useIntl();
	const { appId, onSpaceKeyChange, spaceKey, isCompact } = props;

	const [{ loading, spaces, isFilteredResult }, { fetchSpaces, filterSpaces }] =
		useConfluenceSpaceService({
			appId,
		});
	const { filtered } = spaces;
	useEffect(() => {
		fetchSpaces();
	}, [fetchSpaces]);

	const onChange = useCallback(
		(newValue: Option | null) => {
			if (newValue && newValue.space) {
				onSpaceKeyChange(newValue.value);
			} else {
				onSpaceKeyChange(''); // reset
			}
		},
		[onSpaceKeyChange],
	);

	const onInputChange = useCallback(
		(
			// @ts-expect-error - TS7006 - Parameter 'input' implicitly has an 'any' type.
			input,
			{
				action,
			}: {
				action: string;
			},
		) => {
			if (action === 'input-change') {
				filterSpaces(input);
			}
		},
		[filterSpaces],
	);

	const onMenuClose = useCallback(() => {
		filterSpaces(''); // reset search
	}, [filterSpaces]);

	const label = formatMessage(!isFilteredResult ? messages.allSpaces : messages.matchingSpaces);

	return (
		<Select
			options={[{ label, options: filtered }]}
			onInputChange={onInputChange}
			// @ts-expect-error - TS2322 - Type '(newValue: Option | null) => void' is not assignable to type '(value: Option | OptionsType<Option> | null, action: ActionMeta<Option>) => void'.
			onChange={onChange}
			onMenuClose={onMenuClose}
			filterOption={filterOption}
			value={filtered.find((elem) => elem.value === spaceKey)}
			isClearable
			placeholder={formatMessage(messages.filterBySpace)}
			isLoading={loading}
			maxMenuHeight={256}
			minMenuHeight={256}
			components={{
				SingleValue,
				// @ts-expect-error - TS2322 - Type '(props: JSX.LibraryManagedAttributes<typeof components.Option, ComponentProps<typeof components.Option>>) => JSX.Element' is not assignable to type 'ComponentType<OptionProps<Option, boolean, GroupTypeBase<Option>>> | undefined'.
				Option: CustomOptionNew,
			}}
			styles={customSelectStyle(isCompact)}
		/>
	);
};
export default ConfluenceSpaceSelect;
