const MINUTES_PER_HOUR = 60;
const SECONDS_PER_MINUTE = 60;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (daysPerWeek: number, hoursPerDay: number) => {
	const units = [
		{ suffix: 'w', seconds: daysPerWeek * hoursPerDay * MINUTES_PER_HOUR * SECONDS_PER_MINUTE },
		{ suffix: 'd', seconds: hoursPerDay * MINUTES_PER_HOUR * SECONDS_PER_MINUTE },
		{ suffix: 'h', seconds: MINUTES_PER_HOUR * SECONDS_PER_MINUTE },
		{ suffix: 'm', seconds: SECONDS_PER_MINUTE },
	];

	return (seconds: number): string => {
		const initial = {
			secondsRemaining: seconds,
			timeString: '',
		};

		const result = units.reduce((acc, unit) => {
			// Skip this unit if it doesn't have a valid number of seconds
			if (unit.seconds <= 0) {
				return acc;
			}

			const numUnits = Math.floor(acc.secondsRemaining / unit.seconds);

			const updatedSecondsRemaining =
				numUnits >= 1 ? acc.secondsRemaining % unit.seconds : acc.secondsRemaining;

			const updatedTimeString =
				numUnits >= 1 ? `${acc.timeString} ${numUnits}${unit.suffix}` : acc.timeString;

			return {
				secondsRemaining: updatedSecondsRemaining,
				timeString: updatedTimeString,
			};
		}, initial);

		return result.timeString.trim() || '0m';
	};
};
