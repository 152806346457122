import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/empty';

import { toIssueKey, toCloudId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { DataProvider, DataProviderMetrics } from '../model/data-provider.tsx';
import type { IssueServiceResult } from '../model/errors.tsx';
import type { Issue, IssueRemoteData } from '../model/issue.tsx';
import { fetchIssueRemoteDataWithWhiteboards$ } from '../source/issue-express/index.tsx';

// exported just for tests
export const createSupressedDataProvider = (): DataProvider => {
	// Replace with lodash/noop
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	const clearCache = (): void => {};
	// Replace with lodash/noop
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	const insertSerializedIssue = () => {};

	// Replace with lodash/noop
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	const loadIssues = () => {};

	// Replace with lodash/noop
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	const loadIssuesById = () => {};

	// @ts-expect-error - TS2355 - A function whose declared type is neither 'void' nor 'any' must return a value.
	// Replace with lodash/noop
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	const getIssue = (): Issue | undefined => {};

	const loadIssue$ = (): Observable<IssueServiceResult> => Observable.empty<never>();

	const getIssue$ = (): Observable<IssueServiceResult> => Observable.empty<never>();

	const refreshIssue$ = (): Observable<IssueServiceResult> => loadIssue$();

	const getIssueRemoteData$ = (issueKey: string, cloudId: string): Observable<IssueRemoteData> =>
		fetchIssueRemoteDataWithWhiteboards$(toIssueKey(issueKey), toCloudId(cloudId));

	// Replace with lodash/noop
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	const updateIssue = (): void => {};

	const getMetrics = (): DataProviderMetrics => ({
		cacheSize: 0,
		cacheStaleCount: 0,
		cacheAvailableCapacity: 0,
		cacheHit: false,
		staleCacheHit: false,
	});

	return {
		clearCache,
		loadIssues,
		loadIssuesById,
		getIssue,
		getIssue$,
		getIssueRemoteData$,
		refreshIssue$,
		updateIssue,
		getMetrics,
		insertSerializedIssue,
	};
};

// store it as a singleton
let dataProvider: DataProvider;
export const getDataProvider = () => {
	if (dataProvider == null) {
		dataProvider = createSupressedDataProvider();
	}
	return dataProvider;
};
