import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	authenticationMessage: {
		id: 'development-summary-pull-request.create-pull-request-summary-item.create-pull-request-dropdown.create-pull-request-authentication-row.authentication-message',
		defaultMessage: "We couldn't load all development information",
		description: "Message informing users that all of their data wasn'tt retrieved.",
	},
	openDialogPrompt: {
		id: 'development-summary-pull-request.create-pull-request-summary-item.create-pull-request-dropdown.create-pull-request-authentication-row.open-dialog-prompt',
		defaultMessage: 'Find out more',
		description: 'Prompt used as a call to action to address issue with accessing data',
	},
});
