export const FORMATTER = {
	singleLineTextFields: {
		value: {
			fieldId: 'ID',
			text: null,
		},
		type: 'array',
	},
	epicLinkField: {
		value: {
			fieldId: 'ID',
			epicLink: {
				issueKey: null,
			},
		},
		type: 'object',
	},
	sprintsField: {
		value: {
			fieldId: 'ID',
			sprints: [
				{
					sprintId: null,
				},
			],
		},
		type: 'object',
	},
	priority: {
		value: {
			priorityId: 'ID',
		},
		type: 'object',
	},
	datePickerFields: {
		value: {
			fieldId: 'ID',
			date: {
				formattedDate: null,
			},
		},
		type: 'array',
	},
	dateTimePickerFields: {
		value: {
			fieldId: 'ID',
			dateTime: {
				formattedDateTime: null,
			},
		},
		type: 'array',
	},
	labelsFields: {
		value: {
			fieldId: 'ID',
			labels: [
				{
					name: null,
				},
			],
		},
		type: 'array',
	},
	singleSelectUserPickerFields: {
		value: {
			fieldId: 'ID',
			user: {
				accountId: null,
			},
		},
		type: 'array',
	},
	multipleVersionPickerFields: {
		value: {
			fieldId: 'ID',
			versions: [
				{
					versionId: null,
				},
			],
		},
		type: 'array',
	},
	components: {
		value: {
			componentId: null,
		},
		type: 'array',
	},
	parentField: {
		value: {
			issueId: null,
		},
		type: 'object',
	},
	//  ... Add all other mutations as needed
} as const;

export const FIELD_MAPPER_MUTATION_GROUP = {
	summary: 'singleLineTextFields',
	epic: 'epicLinkField',
	epicName: 'singleLineTextFields',
	duedate: 'datePickerFields',
	startdate: 'datePickerFields',
	plannedStartDate: 'dateTimePickerFields',
	plannedEndDate: 'dateTimePickerFields',
	labels: 'labelsFields',
	sprint: 'sprintsField',
	assignee: 'singleSelectUserPickerFields',
	reporter: 'singleSelectUserPickerFields',
	fixVersions: 'multipleVersionPickerFields',
	components: 'components',
	parent: 'parentField',
} as const;
