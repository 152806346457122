/**
 * @generated SignedSource<<e0b19c688784554a1b0ae24dca989610>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraIssueDeploymentEnvironmentState = "DEPLOYED" | "NOT_DEPLOYED" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type bambooDeploymentData_softwareReleasesVersionDetailIssueList$data = {
  readonly deploymentEnvironments: {
    readonly overall: {
      readonly topEnvironments: ReadonlyArray<{
        readonly status: JiraIssueDeploymentEnvironmentState;
        readonly title: string | null | undefined;
      }>;
    };
  };
  readonly " $fragmentType": "bambooDeploymentData_softwareReleasesVersionDetailIssueList";
};
export type bambooDeploymentData_softwareReleasesVersionDetailIssueList$key = {
  readonly " $data"?: bambooDeploymentData_softwareReleasesVersionDetailIssueList$data;
  readonly " $fragmentSpreads": FragmentRefs<"bambooDeploymentData_softwareReleasesVersionDetailIssueList">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "bambooDeploymentData_softwareReleasesVersionDetailIssueList",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "concreteType": "JiraIssueDeploymentEnvironmentDevSummaryContainer",
        "kind": "LinkedField",
        "name": "deploymentEnvironments",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "concreteType": "JiraIssueDeploymentEnvironmentDevSummary",
              "kind": "LinkedField",
              "name": "overall",
              "plural": false,
              "selections": [
                {
                  "kind": "RequiredField",
                  "field": {
                    "concreteType": "JiraIssueDeploymentEnvironment",
                    "kind": "LinkedField",
                    "name": "topEnvironments",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "RequiredField",
                        "field": {
                          "kind": "ScalarField",
                          "name": "status"
                        },
                        "action": "THROW",
                        "path": "deploymentEnvironments.overall.topEnvironments.status"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "title"
                      }
                    ]
                  },
                  "action": "THROW",
                  "path": "deploymentEnvironments.overall.topEnvironments"
                }
              ]
            },
            "action": "THROW",
            "path": "deploymentEnvironments.overall"
          }
        ]
      },
      "action": "THROW",
      "path": "deploymentEnvironments"
    }
  ],
  "type": "JiraIssueDevSummary"
};

(node as any).hash = "f1dd87aba73205d2eb34f56156e152c9";

export default node;
