// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	feedbackTitle: {
		id: 'platform.ui.feedback.bento-feedback-dialog.common.feedback-title-new',
		defaultMessage: 'Give us feedback',
		description: 'Title of a modal dialog',
	},
	feedbackPreambleMessage: {
		id: 'platform.ui.feedback.bento-feedback-dialog.common.feedback-preamble-message-new',
		defaultMessage: 'Your feedback helps us continually improve Jira.',
		description: 'Message asking users to provide useful feedback about the new issueview',
	},
	reportABugMessage: {
		id: 'platform.ui.feedback.bento-feedback-dialog.common.report-a-bug-message-new',
		defaultMessage:
			'If you have technical problems, or want to report a bug, <link>contact our support team</link>.',
		description: 'Link and message directing users to report a bug if they have a problem',
	},
	supportLinkContent: {
		id: 'platform.ui.feedback.bento-feedback-dialog.common.support-link-content',
		defaultMessage: 'contact our support team',
		description:
			'Text to be displayed as the support link which the user should click to report a bug.',
	},
	feedbackSubmitCaption: {
		id: 'platform.ui.feedback.bento-feedback-dialog.common.feedback-submit-caption',
		defaultMessage: 'Send feedback',
		description: 'Option in a select dropdown',
	},
	cancelButtonCaption: {
		id: 'platform.ui.feedback.bento-feedback-dialog.common.opt-out-cancel-button-caption-new',
		defaultMessage: 'Close',
		description: 'Cancel button caption',
	},
	selectFeedbackCaption: {
		id: 'platform.ui.feedback.bento-feedback-dialog.common.select-feedback-caption',
		defaultMessage: 'Select feedback type',
		description: 'The feedback dropdown list label',
	},
	canContactPrompt: {
		id: 'business-common.feedback-collector.can-contact-prompt-new',
		defaultMessage: 'Allow Atlassian to contact you about this feedback.',
		description: 'Prompt asking user if they give consent to be contacted',
	},
	canContactPromptLink: {
		id: 'business-common.feedback-collector.can-contact-prompt-link',
		defaultMessage: 'Privacy policy',
		description: 'Privacy policy for user to visit and read',
	},
	enrollInResearchPrompt: {
		id: 'business-common.feedback-collector.enroll-in-research-prompt-new',
		defaultMessage:
			'Join the <link>Atlassian Research Group</link> to help improve Atlassian products',
		description: 'A prompt that allows the customer to enrol in product research.',
	},
	enrollInResearchLink: {
		id: 'business-common.feedback-collector.enroll-in-research-link',
		defaultMessage: 'Atlassian Research Group',
		description:
			'A link in the prompt that allows the customer to see information about the research group',
	},
});
