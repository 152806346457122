import { useCallback } from 'react';
import { useMutation, graphql, useRelayEnvironment } from 'react-relay';
import { ValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { useIssueId } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { SaveField } from '@atlassian/jira-issue-field-base/src/common/types.tsx';
import type { useSingleSelectMutation as useSingleSelectMutationQuery } from '@atlassian/jira-relay/src/__generated__/useSingleSelectMutation.graphql';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import type {
	SingleSelectShapeResponse,
	SingleSelectFieldValue,
} from '../use-select-field/types.tsx';
import { fetchMissingIssueAndFieldOptionData } from './utils.tsx';

export function useSingleSelectMutation<
	T extends SingleSelectFieldValue,
	R extends SingleSelectShapeResponse,
>(): SaveField<T | null, R | null> {
	const cloudId = useCloudId();
	const issueContextIssueId = useIssueId();
	const environment = useRelayEnvironment();

	const [commit] = useMutation<useSingleSelectMutationQuery>(graphql`
		mutation useSingleSelectMutation($input: JiraUpdateSingleSelectFieldInput!) @raw_response_type {
			jira @optIn(to: ["JiraIssueFieldMutations"]) {
				updateSingleSelectField(input: $input) {
					success
					errors {
						message
					}
					field {
						fieldOption {
							id
							optionId
							value
						}
					}
				}
			}
		}
	`);

	return useCallback(
		async (issueKey, fieldId, option) => {
			const { issueId, fieldOptionAri } = await fetchMissingIssueAndFieldOptionData<T>(
				issueContextIssueId,
				option,
				environment,
				{
					cloudId,
					issueKey,
					fieldId,
				},
			);

			return new Promise((resolve, reject) => {
				commit({
					variables: {
						input: {
							// @todo: we shouldn't be manually constructing ARIs
							// https://jplat.jira.atlassian.cloud/browse/JIV-16566
							id: `ari:cloud:jira:${cloudId}:issuefieldvalue/${issueId}/${fieldId}`,
							operation: {
								operation: 'SET',
								id: fieldOptionAri,
							},
						},
					},
					onCompleted(data) {
						if (!data.jira?.updateSingleSelectField) {
							reject(new Error('unknown error occurred'));
							return;
						}

						const { success, errors, field } = data.jira.updateSingleSelectField;
						if (!success || errors?.length) {
							reject(
								errors?.[0].message
									? new ValidationError(errors[0].message)
									: new Error('unknown error occurred'),
							);
							return;
						}

						resolve(
							field?.fieldOption
								? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
									({
										ari: field.fieldOption.id,
										value: field.fieldOption.value,
										id: field.fieldOption.optionId,
									} as R)
								: null,
						);
					},
					onError: reject,
				});
			});
		},
		[cloudId, commit, environment, issueContextIssueId],
	);
}
