import React, { useEffect } from 'react';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { loomVideosEntrypoint } from './ui/entrypoint.tsx';

export const LoomPanel = () => {
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(loomVideosEntrypoint, {});

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		entryPointActions.load();
	}, [entryPointActions]);

	return (
		<JiraEntryPointContainer
			entryPointReferenceSubject={entryPointReferenceSubject}
			id="loomVideosPanel"
			packageName="issueViewBase"
			errorFallback="unmount"
			teamName="team-multipliers"
			runtimeProps={{}}
		/>
	);
};
