import React, { useCallback, useMemo, useRef } from 'react';
import { useFragment, graphql, useMutation } from 'react-relay';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import {
	RICH_CONTENT,
	PLAIN_TEXT,
} from '@atlassian/jira-issue-shared-types/src/common/types/field-schema-type.tsx';
import type { Area } from '@atlassian/jira-issue-view-common-types/src/connect-field-type.tsx';
import {
	TEXT_AREA_CF_TYPE,
	ENVIRONMENT_TYPE,
	DESCRIPTION_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { ui_issueViewLayoutRichTextField_IssueViewRichTextField_fragmentKey$key } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutRichTextField_IssueViewRichTextField_fragmentKey.graphql';
import type { ui_issueViewLayoutRichTextField_IssueViewRichTextField_Mutation } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutRichTextField_IssueViewRichTextField_Mutation.graphql';
import type { ui_issueViewLayoutRichTextField_IssueViewRichTextField_richText$key } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutRichTextField_IssueViewRichTextField_richText.graphql';
import { convertAdfToText } from '@atlassian/jira-rich-content/src/common/convert-adf-to-text/index.tsx';
import type { AggJiraRichText, OnSave } from '../common/types.tsx';
import { PlainTextField } from './plain-text-field/index.tsx';
import { RichTextField } from './rich-text-field/index.tsx';

export type IssueViewRichTextFieldProps = {
	area?: Area;
	fragmentKey: ui_issueViewLayoutRichTextField_IssueViewRichTextField_fragmentKey$key;
};

export function IssueViewRichTextField({ area, fragmentKey }: IssueViewRichTextFieldProps) {
	const data = useFragment<ui_issueViewLayoutRichTextField_IssueViewRichTextField_fragmentKey$key>(
		graphql`
			fragment ui_issueViewLayoutRichTextField_IssueViewRichTextField_fragmentKey on JiraRichTextField {
				id
				type
				renderer
				__typename
				...richTextField_issueViewLayoutRichTextField
				richText {
					...ui_issueViewLayoutRichTextField_IssueViewRichTextField_richText
				}
				...plainTextField_issueViewLayoutRichTextField
			}
		`,
		fragmentKey,
	);

	const [commit] = useMutation<ui_issueViewLayoutRichTextField_IssueViewRichTextField_Mutation>(
		graphql`
			mutation ui_issueViewLayoutRichTextField_IssueViewRichTextField_Mutation(
				$input: JiraUpdateRichTextFieldInput!
			) @raw_response_type {
				jira {
					updateRichTextField(input: $input) @optIn(to: "JiraIssueFieldMutations") {
						success
						errors {
							message
						}
						field {
							id
							richText {
								...ui_issueViewLayoutRichTextField_IssueViewRichTextField_richText
								adfValue {
									json
									convertedPlainText {
										plainText
									}
								}
							}
						}
					}
				}
			}
		`,
	);
	const isRichTextField = useMemo(() => {
		const isDescription = data.type === DESCRIPTION_TYPE;

		const isMultilineTextField =
			data.type === TEXT_AREA_CF_TYPE ||
			data.type === ENVIRONMENT_TYPE ||
			data.type === DESCRIPTION_TYPE;

		const isMultilineRichTextField = isMultilineTextField && data.renderer === RICH_CONTENT;
		return isDescription || isMultilineRichTextField;
	}, [data.renderer, data.type]);

	const onSave: OnSave = useCallback(
		({ value: componentValue, onSubmit, onSubmitFailed, onSubmitSucceeded }) => {
			// Note, we aren't calling useIssueViewFieldUpdateEvents here since we are relying on the connectField/redux notification service of the child components to handle this
			// except for plain text field, which is why these are passed in
			const newAggValue: AggJiraRichText = {
				adfValue: {
					json: componentValue,
					convertedPlainText: {
						plainText: convertAdfToText(componentValue) || '',
					},
				},
			};
			onSubmit?.(newAggValue);
			commit({
				variables: {
					input: {
						id: data.id,
						operation: {
							operation: 'SET',
							document: {
								version: typeof componentValue === 'string' ? 0 : componentValue?.version,
								jsonValue: componentValue,
							},
						},
					},
				},
				onCompleted: (mutationData) => {
					if (mutationData.jira?.updateRichTextField?.success) {
						onSubmitSucceeded?.(newAggValue);
					} else {
						onSubmitFailed?.();
					}
				},
				onError() {
					onSubmitFailed?.();
				},
				optimisticUpdater: (store) => {
					if (!data.richText) {
						return;
					}
					const { updatableData } =
						store.readUpdatableFragment<ui_issueViewLayoutRichTextField_IssueViewRichTextField_richText$key>(
							// eslint-disable-next-line @atlassian/relay/must-use-inline
							graphql`
								fragment ui_issueViewLayoutRichTextField_IssueViewRichTextField_richText on JiraRichText
								@updatable {
									adfValue {
										json
										convertedPlainText {
											plainText
										}
									}
								}
							`,
							data.richText,
						);
					if (updatableData.adfValue) {
						updatableData.adfValue.json = componentValue;
						if (updatableData.adfValue?.convertedPlainText?.plainText) {
							updatableData.adfValue.convertedPlainText.plainText =
								newAggValue?.adfValue?.convertedPlainText?.plainText;
						}
					}
				},
			});
		},
		[commit, data],
	);

	const hasLoggedUnknownType = useRef(false);
	const logUnknownType = useCallback(() => {
		if (!hasLoggedUnknownType.current) {
			hasLoggedUnknownType.current = true;
			log.safeWarnWithoutCustomerData(
				'issue.fields.issue-view-layout.rich-text-field',
				`unknown fieldType ${data.type} and renderer ${data.renderer}`,
			);
		}
	}, [data.type, data.renderer]);

	const areaWithDefault: Area = area ?? ('content' as const);
	if (data.type === DESCRIPTION_TYPE || isRichTextField) {
		return <RichTextField area={areaWithDefault} onSave={onSave} richTextField={data} />;
	}
	if (data.renderer === PLAIN_TEXT) {
		return <PlainTextField area={areaWithDefault} onSave={onSave} richTextField={data} />;
	}
	logUnknownType();
	return null;
}
