import flow from 'lodash/flow';
import memoizeOne from 'memoize-one';
import { withAnalyticsEvents } from '@atlaskit/analytics-next';
import componentsCache from '@atlassian/jira-cache/src/services/components/index.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { getGlobalComponentsProperty } from '@atlassian/jira-compass-common/src/services/use-global-components-property/utils.tsx';
import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import { genericMessages } from '@atlassian/jira-issue-view-common-constants/src/context-items-messages.tsx';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button/index.tsx';
import connectField from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-field.tsx';
import {
	baseUrlSelector,
	isMobileSelector,
	issueIdSelector,
	projectKeySelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import { fieldAllowedValuesSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector.tsx';
import { projectIdSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';
import {
	canAdministerJiraPermissionsSelector,
	canAdministerProjectPermissionsSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/permissions-selector.tsx';
import {
	fireOperationalAnalytics,
	fireTrackAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import type { ProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';
import componentsRanker from './components-ranker.tsx';
import messages from './messages.tsx';
import {
	type StateValue,
	transformFromStateValue,
	transformSuggestionsFromServerAndCache,
	transformToStateValue,
} from './transformer.tsx';
import { View } from './view.tsx';

interface ComponentCounts {
	addedCompass: number;
	removedCompass: number;
	addedJira: number;
	removedJira: number;
}

const rankComponents = memoizeOne(
	(suggestion, issueId, projectId, sessionId, createAnalyticsEvent) =>
		componentsRanker(suggestion, issueId, projectId, sessionId, createAnalyticsEvent),
);

const fetchSuggestionsFactory = memoizeOne(
	(intl: IntlShape, suggestions, issueId, projectId, createAnalyticsEvent, projectKey) =>
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		async (query: any, sessionId: any) => {
			const pGlobalComponentsProperty = getGlobalComponentsProperty(projectKey);

			const pRankedComponents = rankComponents(
				suggestions,
				issueId,
				projectId,
				sessionId,
				createAnalyticsEvent,
			);

			const [globalComponentsProperty, rankedComponents, cachedComponents] = await Promise.all([
				pGlobalComponentsProperty,
				pRankedComponents,
				componentsCache.getAll(),
			]);

			const globalComponentsEnabled = globalComponentsProperty.value === true;

			return transformSuggestionsFromServerAndCache(
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
				rankedComponents as any,
				globalComponentsEnabled ? [] : cachedComponents,
				intl,
				projectKey,
			);
		},
);

const countAddedAndRemovedComponents = (stateComponents: {
	value: StateValue[];
	pendingValue: StateValue[];
}) => {
	const { value: existingComponents, pendingValue: pendingComponents } = stateComponents;
	const componentCounts: ComponentCounts = {
		addedCompass: 0,
		removedCompass: 0,
		addedJira: 0,
		removedJira: 0,
	};

	const idMap = (components: StateValue[]): Map<string, StateValue> =>
		new Map(components.map((comp) => [comp.id, comp]));
	const existingIdMap: Map<string, StateValue> = idMap(existingComponents);
	const pendingIdMap: Map<string, StateValue> = idMap(pendingComponents);
	const updateCounts = (component: StateValue, added: boolean): void => {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const key: keyof ComponentCounts = `${added ? 'added' : 'removed'}${
			component.ari === undefined || component.ari === null ? 'Jira' : 'Compass'
		}` as keyof ComponentCounts;
		componentCounts[key]++;
	};
	existingComponents.forEach((comp: StateValue) => {
		if (!pendingIdMap.has(comp.id)) updateCounts(comp, false);
	});
	pendingComponents.forEach((comp: StateValue) => {
		if (!existingIdMap.has(comp.id)) updateCounts(comp, true);
	});

	return componentCounts;
};

const saveField = (
	// @ts-expect-error - TS7031 - Binding element 'baseUrl' implicitly has an 'any' type. | TS7031 - Binding element 'issueKey' implicitly has an 'any' type. | TS7031 - Binding element 'fieldMetaKey' implicitly has an 'any' type. | TS7031 - Binding element 'value' implicitly has an 'any' type. | TS7031 - Binding element 'fieldEditSessionId' implicitly has an 'any' type.
	{ baseUrl, issueKey, fieldMetaKey, value, fieldEditSessionId },
	// @ts-expect-error - TS2304 - Cannot find name 'State'.
	state: State,
	// @ts-expect-error - TS7006 - Parameter 'ownPropsOnMount' implicitly has an 'any' type.
	ownPropsOnMount,
) => {
	const url =
		fieldEditSessionId !== undefined
			? `${baseUrl}/rest/api/2/issue/${issueKey}?fieldEditSessionId=${fieldEditSessionId}`
			: `${baseUrl}/rest/api/2/issue/${issueKey}`;

	const projectKey = projectKeySelector(state);

	// @ts-expect-error - TS7006 - Parameter 'current' implicitly has an 'any' type.
	value.forEach((current) => {
		try {
			if (ownPropsOnMount.createAnalyticsEvent) {
				fireOperationalAnalytics(
					ownPropsOnMount.createAnalyticsEvent({}),
					'componentPickerCache set',
					{
						isFromCache: current.fromCache,
					},
				);
			}

			current.id && componentsCache.set(`${current.id}`, { ...current, projectKey });
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			log.safeErrorWithoutCustomerData('issue-view.components.cache.set', error.message);
		}
	});

	return performPutRequest(url, {
		body: JSON.stringify({
			fields: {
				[fieldMetaKey]: value,
			},
		}),
	})
		.then(() => {
			if (ownPropsOnMount.createAnalyticsEvent) {
				fireTrackAnalytics(ownPropsOnMount.createAnalyticsEvent({}), 'componentField updated', {
					componentCounts: countAddedAndRemovedComponents(state.fields.components),
				});
			}
		})
		.catch((error) => {
			// @ts-expect-error - TS7006 - Parameter 'current' implicitly has an 'any' type.
			value.forEach((current) => {
				try {
					if (ownPropsOnMount.createAnalyticsEvent) {
						fireOperationalAnalytics(
							ownPropsOnMount.createAnalyticsEvent({}),
							'componentPickerCache removed',
						);
					}

					current.id && componentsCache.remove(`${current.id}`);
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
				} catch (e: any) {
					log.safeErrorWithoutCustomerData('issue-view.components.cache.remove', e.message);
				}
			});

			throw error;
		});
};

// @ts-expect-error - TS7006 - Parameter 'ownPropsOnMount' implicitly has an 'any' type.
const getConnectedComponentProps = (ownPropsOnMount) => {
	// use memoized factory in the absence of redux-thunk
	const getDataFromCacheFactory = memoizeOne(
		(projectKey: ProjectKey) => () =>
			componentsCache
				.getAll()
				.then((components) =>
					transformSuggestionsFromServerAndCache([], components, ownPropsOnMount.intl, projectKey),
				),
	);

	return {
		fieldId: ownPropsOnMount.fieldId,
		transformFromStateValue,
		transformToStateValue,
		// @ts-expect-error - TS7006 - Parameter 'state' implicitly has an 'any' type. | TS7006 - Parameter 'intl' implicitly has an 'any' type.
		additionalProps: (state, intl) => ({
			baseUrl: baseUrlSelector(state),
			projectKey: projectKeySelector(state),
			isMobile: isMobileSelector(state),
			fetchSuggestions: fetchSuggestionsFactory(
				intl,
				fieldAllowedValuesSelector(ownPropsOnMount.fieldId)(state),
				issueIdSelector(state),
				projectIdSelector(state),
				ownPropsOnMount.createAnalyticsEvent,
				projectKeySelector(state),
			),
			placeholder: intl.formatMessage(messages.placeholder),
			noValueText: intl.formatMessage(genericMessages.noValue),
			shouldShowFooter:
				(canAdministerProjectPermissionsSelector(state) ||
					canAdministerJiraPermissionsSelector(state)) &&
				!isMobileSelector(state),
			showPinButton: getShowPinButton(ownPropsOnMount.area),
			getDataFromCache: getDataFromCacheFactory(projectKeySelector(state)),
		}),
		// @ts-expect-error - TS2345 - Argument of type 'SaveFieldArguments<unknown>' is not assignable to parameter of type '{ baseUrl: any; issueKey: any; fieldMetaKey: any; value: any; fieldEditSessionId: any; }'.
		saveField: (...args) => saveField(...args, ownPropsOnMount),
	};
};

export default flow(
	injectIntl,
	connectField((stateOnMount, ownPropsOnMount) => getConnectedComponentProps(ownPropsOnMount)),
	withAnalyticsEvents(),
)(View);
