import { useEffect } from 'react';
import { usePrevious } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';

type Parameters = {
	issueId: string;
	refreshResource: () => void;
};

export const useRefreshOnIssueIdChange = ({ issueId, refreshResource }: Parameters) => {
	const previousIssueId = usePrevious(issueId);

	// This is for cases where the user opens the issues in sidebar in "Queues" screen
	// Opening a new issue while another is open in the sidebar doesn't unmount the component, so we need to refresh the resource.
	useEffect(() => {
		if (!!previousIssueId && issueId !== previousIssueId) {
			refreshResource();
		}
	}, [issueId, previousIssueId, refreshResource]);
};
