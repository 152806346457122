import React, { useCallback } from 'react';
import {
	ContextualAnalyticsData,
	DRAWER,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import AddFeatureFlagSummaryItem from './empty/index.tsx';
import FeatureFlagSummaryItem from './summary/index.tsx';
import type { Props } from './types.tsx';

const FeatureFlags = (props: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const {
		hasFeatureFlagsProviders,
		hasFeatureFlags,
		onClick,
		detailsLink,
		disabledFeatureFlags,
		disabledFeatureFlagsCnt,
		enabledFeatureFlags,
		enabledFeatureFlagsCnt,
		issueContext,
		rolloutSummary,
		connectFeatureFlagTarget,
		createFeatureFlagTarget,
		totalFeatureFlags,
	} = props;

	const onLinkClicked = useCallback(
		(
			attributes: {
				[key: string]: string | number | boolean | null;
			},
			onClickAction: () => void,
		) => {
			fireUIAnalytics(
				createAnalyticsEvent({ action: 'clicked', actionSubject: 'link' }),
				'clickFeatureFlagsSummaryLink',
				attributes,
			);
			if (onClickAction) {
				onClickAction();
			}
		},
		[createAnalyticsEvent],
	);

	const { issueStatusCategory, projectType, projectId, isSimplifiedProject } = issueContext;

	const analyticsAttributes = {
		issueStatusCategory,
		projectType,
		projectId,
		isSimplifiedProject,
		totalFeatureFlags,
	};

	if (hasFeatureFlagsProviders) {
		if (hasFeatureFlags) {
			return (
				<ContextualAnalyticsData sourceType={DRAWER} sourceName="viewFeatureFlagSummary">
					<FeatureFlagSummaryItem
						onClick={() => onLinkClicked(analyticsAttributes, onClick)}
						connectFeatureFlagTarget={connectFeatureFlagTarget}
						createFeatureFlagTarget={createFeatureFlagTarget}
						detailsLink={detailsLink}
						disabledFeatureFlags={disabledFeatureFlags}
						disabledFeatureFlagsCnt={disabledFeatureFlagsCnt}
						enabledFeatureFlags={enabledFeatureFlags}
						enabledFeatureFlagsCnt={enabledFeatureFlagsCnt}
						rolloutSummary={rolloutSummary}
						totalFeatureFlags={totalFeatureFlags}
					/>
				</ContextualAnalyticsData>
			);
		}
		return (
			<AddFeatureFlagSummaryItem
				connectFeatureFlagTarget={connectFeatureFlagTarget}
				createFeatureFlagTarget={createFeatureFlagTarget}
			/>
		);
	}
	return null;
};

FeatureFlags.defaultProps = {
	hasFeatureFlagsProviders: false,
	hasFeatureFlags: false,
};

export default FeatureFlags;
