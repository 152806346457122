import React, { type ReactNode, useMemo } from 'react';
import Tooltip from '@atlaskit/tooltip';
import type { FeatureFlagName } from '@atlassian/jira-development-common/src/model/releases-data.tsx';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

type Props = {
	enabledFeatureFlags: FeatureFlagName[];
	disabledFeatureFlags: FeatureFlagName[];
	children: ReactNode;
};

class ContentLineKeyGenerator {
	cnt = 0;

	next() {
		this.cnt += 1;
		return `rp-cnt-tt-${this.cnt}`;
	}
}

const ContentTooltip = (props: Props) => {
	const { formatMessage } = useIntl();

	const TooltipContentHeader = useMemo(() => {
		const { enabledFeatureFlags, disabledFeatureFlags } = props;
		const keyGenerator = new ContentLineKeyGenerator();

		const contentLines = enabledFeatureFlags
			.concat(disabledFeatureFlags)
			.sort()
			.slice(0, 3)
			.map((featureFlagName) => featureFlagName);

		return contentLines.map((contentLine) => <div key={keyGenerator.next()}>{contentLine}</div>);
	}, [props]);

	const TooltipFooter = useMemo(() => {
		const { enabledFeatureFlags, disabledFeatureFlags } = props;
		const remainingFeatureFlags = enabledFeatureFlags.length + disabledFeatureFlags.length - 3;
		if (remainingFeatureFlags > 1) {
			return (
				<>
					{formatMessage(messages.moreFeatureFlags, {
						featureFlagsCnt: remainingFeatureFlags,
					})}
				</>
			);
		}
		if (remainingFeatureFlags === 1) {
			return <>{formatMessage(messages.oneMoreFeatureFlag)}</>;
		}
		return null;
	}, [formatMessage, props]);

	const TooltipContent = useMemo(
		() => (
			<>
				{TooltipContentHeader}
				{TooltipFooter}
			</>
		),
		[TooltipContentHeader, TooltipFooter],
	);

	return (
		<Tooltip content={TooltipContent} tag="span">
			{props.children}
		</Tooltip>
	);
};

export default ContentTooltip;
