import type Token from 'markdown-it/lib/token';

export interface Nodes {
	[key: string]: string | string[];
}

export interface Marks {
	[key: string]: string | string[];
}

export interface SchemaMapping {
	nodes: Nodes;
	marks: Marks;
}

export const pmToMd: SchemaMapping = {
	nodes: {
		blockquote: 'blockquote',
		paragraph: 'paragraph',
		rule: 'hr',
		// lheading (---, ===)
		heading: ['heading', 'lheading'],
		codeBlock: ['code', 'fence'],
		listItem: 'list',
		image: 'image',
	},
	marks: {
		em: 'emphasis',
		strong: 'text',
		link: ['link', 'autolink', 'reference', 'linkify'],
		strike: 'strikethrough',
		code: 'backticks',
	},
};

export const mdToPmSchema = {
	blockquote: { block: 'blockquote' },
	paragraph: { block: 'paragraph' },
	em: { mark: 'em' },
	strong: { mark: 'strong' },
	link: {
		mark: 'link',
		attrs: (tok: Token) => ({
			href: tok.attrGet('href'),
			title: tok.attrGet('title') || null,
		}),
	},
	table: { block: 'table' },
	tr: { block: 'tableRow' },
	th: { block: 'tableHeader' },
	td: { block: 'tableCell' },
	s: { mark: 'strike' },
	task_list: { block: 'taskList' },
	task_item: {
		block: 'taskItem',
		attrs: (tok: Token) => ({
			state: tok.meta,
		}),
	},
	hr: { node: 'rule' },
	heading: {
		block: 'heading',
		attrs: (tok: Token) => ({ level: +tok.tag.slice(1) }),
	},
	softbreak: { node: 'hardBreak' },
	hardbreak: { node: 'hardBreak' },
	code_block: { block: 'codeBlock' },
	list_item: { block: 'listItem' },
	bullet_list: { block: 'bulletList' },
	code_inline: { mark: 'code' },
	ordered_list: {
		block: 'orderedList',
		attrs: (tok: Token) => {
			const order = +(tok.attrGet('order') || 1);
			return { order };
		},
	},
	fence: {
		block: 'codeBlock',
		// we trim any whitespaces around language definition
		attrs: (tok: Token) => ({
			language: tok.info?.trim() || null,
		}),
	},
	image: {
		node: 'image',
		attrs: (_tok: Token) => {
			return {};
		},
	},
	media_single: {
		block: 'mediaSingle',
		attrs: () => ({}),
	},
	media: {
		node: 'media',
		attrs: (tok: Token) => {
			return {
				url: tok.attrGet('url'),
				type: 'external',
			};
		},
	},
	// Enable ADF SmartLinks, produced by our SmartLink MD plugin
	inlineCard: {
		node: 'inlineCard',
		attrs: (tok: Token) => {
			return {
				url: tok.attrGet('url'),
			};
		},
	},

	blockCard: {
		node: 'blockCard',
		attrs: (tok: Token) => {
			return {
				url: tok.attrGet('url'),
			};
		},
	},

	embedCard: {
		node: 'embedCard',
		attrs: (tok: Token) => {
			return {
				url: tok.attrGet('url'),
			};
		},
	},

	// Enable ADF Mentions, produced by our Mentions MD plugin which converts /people links
	mention: {
		node: 'mention',
		attrs: (tok: Token) => {
			return {
				id: tok.attrGet('id'),
				text: tok.attrGet('text'),
				type: 'mention',
			};
		},
	},

	status: {
		node: 'status',
		attrs: (token: Token) => ({
			text: token.attrGet('text'),
			color: token.attrGet('color'),
			localId: token.attrGet('localId'),
		}),
	},
};
