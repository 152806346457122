import React from 'react';
import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

type Props = {
	emptyText?: string;
};

export const EmptyFieldText = ({ emptyText }: Props) => {
	const { formatMessage } = useIntl();
	return (
		<EmptyValueStyleWrapper>
			{emptyText ?? formatMessage(messages.emptyFieldText)}
		</EmptyValueStyleWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const EmptyValueStyleWrapper = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N300),
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	maxWidth: '100%',
});
