import React from 'react';
import { useInlineEditReadViewContainer } from '@atlassian/jira-inline-edit-lite/src/ui/use-inline-edit-read-view-container/index.tsx';
import InlineEditReadViewContainer from '@atlassian/jira-issue-field-inline-edit-read-view-container/src/ui/index.tsx';
import type { Props } from './types.tsx';

/**
 * Responsible for rendering the common read view UI elements for an issue field, including the container and associated
 * edit button.
 */
export const ReadView = ({
	children,
	context,
	editButtonLabel,
	editButtonRef,
	hideEditButton,
	isEditInteractionEnabled,
	isInvalid,
	isLoading,
	readViewContainerRef,
	readViewFitContainerHeight,
}: Props) => {
	const { isEditButtonFocused, onClick, onMouseDown, onEditButtonBlur, onEditButtonFocus } =
		useInlineEditReadViewContainer(context, isEditInteractionEnabled);

	return (
		<InlineEditReadViewContainer
			context={context}
			editButtonLabel={editButtonLabel}
			editButtonRef={editButtonRef}
			hideEditButton={hideEditButton}
			isEditInteractionEnabled={isEditInteractionEnabled}
			isEditButtonFocused={isEditButtonFocused}
			// Highlight the field as invalid to notify the user if there was an error attempting to submit changes.
			isInvalid={isInvalid}
			isLoading={isLoading}
			onClick={onClick}
			onMouseDown={onMouseDown}
			onEditButtonBlur={onEditButtonBlur}
			onEditButtonFocus={onEditButtonFocus}
			readViewFitContainerHeight={readViewFitContainerHeight}
			ref={readViewContainerRef}
		>
			{children}
		</InlineEditReadViewContainer>
	);
};
