import React, { Component } from 'react';
import { createMetrics, createMetricsContext } from './index.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default () => (WrappedComponent: any) =>
	// eslint-disable-next-line jira/react/no-class-components, @typescript-eslint/no-explicit-any
	class WithMetricsProvider extends Component<any> {
		static displayName = `WithMetricsProvider(${
			WrappedComponent.displayName || WrappedComponent.name
		})`;

		MetricsContext = createMetricsContext(this.props.metrics);

		render() {
			const { MetricsContext } = this;
			return (
				<MetricsContext.Provider value={createMetrics(this.props.metrics)}>
					<WrappedComponent {...this.props} />
				</MetricsContext.Provider>
			);
		}
	};
