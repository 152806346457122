/* eslint-disable @typescript-eslint/no-non-null-assertion */ // Remove on relay-migration-issue-header-and-parent cleanup

import React from 'react';
import { graphql, useFragment } from 'react-relay';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import {
	isLoadingSelector,
	errorSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';
import { isWatchesPresentSelector } from '@atlassian/jira-issue-view-store/src/issue-field/state/selectors/watch-toggle-selector.tsx';
import type { header_issueViewWatchers_WatchersItem$key } from '@atlassian/jira-relay/src/__generated__/header_issueViewWatchers_WatchersItem.graphql';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import { IssueWatchersLoading, IssueWatchersBoundary } from '../async.tsx';
import IssueWatchersSync from '../index.tsx';

type Props = {
	isWatchesPresent: boolean;
	isLoading: boolean;
	error: string | null;
	watches?: header_issueViewWatchers_WatchersItem$key; // Remove optional on relay-migration-issue-header-and-parent cleanup
};

// TODO reevaluate phase - forPaint is simply for initial parity
export const AsyncIssueWatchers = lazyForPaint(
	() => import(/* webpackChunkName: "async-issue-watchers" */ '../index'),
);

export const IssueWatchers = componentWithFG(
	'change_issue_watcher_to_sync',
	IssueWatchersSync,
	AsyncIssueWatchers,
);

const WatchersItem = ({ isWatchesPresent, isLoading, error, watches }: Props) => {
	const loggedInAccountId = useAccountId();
	const shouldRenderWatchers = !error && !!loggedInAccountId;
	const isWatchersLoading = !isWatchesPresent || isLoading;

	const data = fg('relay-migration-issue-header-and-parent')
		? // eslint-disable-next-line @atlassian/relay/query-restriction, react-hooks/rules-of-hooks
			useFragment<header_issueViewWatchers_WatchersItem$key>(
				graphql`
					fragment header_issueViewWatchers_WatchersItem on JiraWatchesField {
						...src_issueViewWatchers_WatchersAppWithRelay
					}
				`,

				watches!,
			)
		: undefined;

	if (fg('relay-migration-issue-header-and-parent')) {
		if (!data) {
			return null;
		}
	}

	if (!shouldRenderWatchers) {
		return null;
	}

	if (isWatchersLoading) {
		return <IssueWatchersLoading />;
	}

	return (
		<IssueWatchersBoundary packageName="issue" fallback={<IssueWatchersLoading />}>
			{fg('relay-migration-issue-header-and-parent') ? (
				<IssueWatchersSync watches={data!} />
			) : (
				<IssueWatchers watches={data!} />
			)}
		</IssueWatchersBoundary>
	);
};

export const Watchers = connect(
	(state) => ({
		isWatchesPresent: isWatchesPresentSelector(state),
		isLoading: isLoadingSelector(state),
		error: errorSelector(state),
	}),
	{},
)(WatchersItem);
