import type { UiConfigState } from '@atlassian/jira-issue-view-common-types/src/ui-config-type.tsx';
import {
	type FetchIssueSuccessAction,
	FETCH_ISSUE_SUCCESS,
} from '../common/actions/issue-fetch-actions.tsx';
import {
	type FieldPreviewAction,
	PREVIEW_DATA_FETCHED,
} from '../issue-field/state/actions/field-preview-actions.tsx';

const initialState = {
	newIssueViewLockedIn: false,
} as const;

type Action = FetchIssueSuccessAction | FieldPreviewAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: UiConfigState = initialState, action: Action) => {
	switch (action.type) {
		case PREVIEW_DATA_FETCHED: {
			const newIssueViewLockedIn = action.payload.meta && action.payload.meta.newIssueViewLockedIn;
			if (newIssueViewLockedIn !== undefined) {
				return {
					...state,
					newIssueViewLockedIn,
				};
			}
			return state;
		}
		case FETCH_ISSUE_SUCCESS: {
			const { newIssueViewLockedIn } = action.payload;
			if (newIssueViewLockedIn !== undefined) {
				return {
					...state,
					newIssueViewLockedIn,
				};
			}
			return state;
		}

		default:
			return state;
	}
};
