import { useCallback, useEffect } from 'react';
import {
	useHighlightActions,
	useTriggerPosition,
} from '../controllers/use-highlight-actions/index.tsx';

export const useOnHighlight = <T extends HTMLElement>(ref: React.RefObject<T>) => {
	const { setTriggerPosition, resetTriggerPosition } = useHighlightActions();
	const triggerPosition = useTriggerPosition();

	const onPointerUp = useCallback(
		(e: PointerEvent) => {
			setTriggerPosition(e.clientX);
		},
		[setTriggerPosition],
	);
	const onClick = useCallback(
		(e: MouseEvent | TouchEvent) => {
			if (triggerPosition) {
				e.stopPropagation();
			}
			/* setImmediate callback is needed here because when texts are deselected by clicking on the same
            selection again. The isCollapsed value is only reflected correctly after event loop concludes */
			// Do not use setImmediate. More info: https://developer.mozilla.org/en/docs/Web/API/Window/setImmediate
			setTimeout(() => {
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				if (window.getSelection()?.isCollapsed) {
					resetTriggerPosition();
				}
			}, 0);
		},
		[resetTriggerPosition, triggerPosition],
	);

	useEffect(() => {
		const currentRef = ref.current;
		if (currentRef) {
			currentRef.addEventListener('click', onClick);
			currentRef.addEventListener('touchend', onClick);
		}
		return () => {
			if (currentRef) {
				currentRef.removeEventListener('click', onClick);
				currentRef.removeEventListener('touchend', onClick);
			}
		};
	}, [onClick, ref]);

	const register = useCallback(() => {
		if (ref.current) {
			ref.current.addEventListener('pointerup', onPointerUp);
		}
	}, [onPointerUp, ref]);

	const unregister = useCallback(() => {
		if (ref.current) {
			ref.current.removeEventListener('pointerup', onPointerUp);
		}
	}, [onPointerUp, ref]);
	return { register, unregister };
};
