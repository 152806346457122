import React, { useCallback, type ComponentPropsWithoutRef } from 'react';
import { graphql, useMutation, useFragment, fetchQuery, useRelayEnvironment } from 'react-relay';
import { toQuotedString, createIssuesUrl } from '@atlassian/jira-common-jql/src/helpers.tsx';
import { useGlobalComponentsProperty } from '@atlassian/jira-compass-common/src/services/use-global-components-property/main.tsx';
import type { SelectValueShape } from '@atlassian/jira-issue-internal-field-select/src/common/select-inline-edit/select-field/types.tsx';
import messages from '@atlassian/jira-issue-view-base/src/context/components/messages.tsx';
import type { ServerSuggestions } from '@atlassian/jira-issue-view-base/src/context/components/types.tsx';
import { View } from '@atlassian/jira-issue-view-base/src/context/components/view.tsx';
import { genericMessages } from '@atlassian/jira-issue-view-common-constants/src/context-items-messages.tsx';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button/index.tsx';
import { getDisplayName } from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-field.tsx';
import {
	useConnectRelayField,
	type ConnectedLayoutProps,
	type PropsCallback,
} from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-relay-field.tsx';
import { ConnectedRelayFieldWrapper } from '@atlassian/jira-issue-view-common-views/src/connect-field/relay-field/field-wrapper.tsx';
import { useIsMobile } from '@atlassian/jira-issue-view-layout-mobile/src/is-mobile/index.tsx';
import { useAnalyticsEvents, fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import {
	useCanAdministerJira,
	useCanAdministerProject,
} from '@atlassian/jira-project-permissions-service/src/main.tsx';
import { mapNodes } from '@atlassian/jira-relay-utils/src/utils/map-nodes/index.tsx';
import type { ui_issueViewComponentsField_Mutation } from '@atlassian/jira-relay/src/__generated__/ui_issueViewComponentsField_Mutation.graphql';
import type { ui_issueViewComponentsField_Query } from '@atlassian/jira-relay/src/__generated__/ui_issueViewComponentsField_Query.graphql';
import type {
	ui_issueViewLayoutComponentsField_IssueViewComponentsField$key as ComponentsInlineEditFragment,
	ui_issueViewLayoutComponentsField_IssueViewComponentsField$data as ComponentsInlineEditFragmentData,
} from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutComponentsField_IssueViewComponentsField.graphql';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';

export type FetchSuggestions = (
	query: string,
	sessionId: string | undefined,
) => Promise<ServerSuggestions>;

type ComponentValueShape = SelectValueShape[];
type AggValueShape = Pick<ComponentsInlineEditFragmentData, 'selectedComponentsConnection'>;

export type AggItemValue = {
	readonly ari: string | null | undefined;

	readonly id: string;
	readonly metadata:
		| {
				readonly typeId: string;
		  }
		| null
		| undefined;
	readonly name: string | null | undefined;

	// those fields required for legacy transformations
	readonly componentId: string;
	readonly description: string | null | undefined;
};

const toComponentValueShape = (projectKey: string, fieldData: AggItemValue): SelectValueShape => ({
	content: fieldData.name ?? '',
	value: fieldData.id ?? '',
	href: createIssuesUrl(
		`project = ${toQuotedString(projectKey)} AND component = ${toQuotedString(
			fieldData.name ?? '',
		)}`,
	),
	ari: fieldData.ari ?? '',
	metadata: fieldData.metadata?.typeId ? { typeId: fieldData.metadata.typeId } : undefined,
});

const transformComponentValueToAggShape = (componentValue: ComponentValueShape): AggValueShape => ({
	selectedComponentsConnection: {
		edges:
			componentValue
				?.map((option) => ({
					node: {
						id: option.value.toString(),
						name: option.content,
						ari: option.ari,
						metadata: option.metadata,
						componentId: option.value.toString(),
						description: '',
					},
				}))
				.sort((a, b) => a.node.name.localeCompare(b.node.name)) ?? [],
	},
});

export type IssueViewComponentsFieldProps = ConnectedLayoutProps<ComponentsInlineEditFragment>;

type AdditionalProps = Pick<
	ComponentPropsWithoutRef<typeof View>,
	| 'projectKey'
	| 'fetchSuggestions'
	| 'placeholder'
	| 'noValueText'
	| 'shouldShowFooter'
	| 'showPinButton'
	| 'isGlobalComponentsEnabled'
	| 'globalComponentsPropertyLoading'
	| 'validate'
	| 'baseUrl'
>;

interface ComponentCounts {
	addedCompass: number;
	removedCompass: number;
	addedJira: number;
	removedJira: number;
}

const countAddedAndRemovedComponents = (stateComponents: {
	value: AggItemValue[];
	pendingValue: AggItemValue[];
}) => {
	const { value: existingComponents, pendingValue: pendingComponents } = stateComponents;
	const componentCounts = {
		addedCompass: 0,
		removedCompass: 0,
		addedJira: 0,
		removedJira: 0,
	};

	const idMap = (components: AggItemValue[]): Map<string, AggItemValue> =>
		new Map(components.map((comp) => [comp.id, comp]));
	const existingIdMap: Map<string, AggItemValue> = idMap(existingComponents);
	const pendingIdMap: Map<string, AggItemValue> = idMap(pendingComponents);
	const updateCounts = (component: AggItemValue, added: boolean): void => {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const key: keyof ComponentCounts = `${added ? 'added' : 'removed'}${
			component.ari === undefined || component.ari === null ? 'Jira' : 'Compass'
		}` as keyof ComponentCounts;
		componentCounts[key]++;
	};
	existingComponents.forEach((comp: AggItemValue) => {
		if (!pendingIdMap.has(comp.id)) updateCounts(comp, false);
	});
	pendingComponents.forEach((comp: AggItemValue) => {
		if (!existingIdMap.has(comp.id)) updateCounts(comp, true);
	});

	return componentCounts;
};

export const IssueViewComponentsField = (props: IssueViewComponentsFieldProps) => {
	const data = useFragment<ComponentsInlineEditFragment>(
		graphql`
			fragment ui_issueViewLayoutComponentsField_IssueViewComponentsField on JiraComponentsField {
				id
				# eslint-disable-next-line @atlassian/relay/unused-fields
				fieldId
				# eslint-disable-next-line @atlassian/relay/unused-fields
				type
				name
				# eslint-disable-next-line @atlassian/relay/unused-fields
				description
				__typename
				# eslint-disable-next-line @atlassian/relay/unused-fields
				fieldConfig {
					isEditable
				}
				issue {
					projectField {
						project {
							key
						}
					}
				}
				selectedComponentsConnection {
					# eslint-disable-next-line @atlassian/relay/unused-fields
					edges {
						node {
							id
							name
							ari
							metadata
							componentId
							description
						}
					}
				}
			}
		`,
		props.fragmentKey,
	);

	const projectKey = data.issue?.projectField?.project?.key ?? '';

	// #region not "relayble" dependencies
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const environment = useRelayEnvironment();
	const isJiraAdmin = useCanAdministerJira(projectKey);
	// project perms are available in AGG but not isJiraAdmin
	const isProjectAdmin = useCanAdministerProject(projectKey);
	const isMobile = useIsMobile();
	const { enabled: isGlobalComponentsEnabled, loading: globalComponentsPropertyLoading } =
		useGlobalComponentsProperty(projectKey);

	// #endregion

	const [commit] = useMutation<ui_issueViewComponentsField_Mutation>(graphql`
		mutation ui_issueViewComponentsField_Mutation($input: JiraUpdateComponentsFieldInput!)
		@raw_response_type {
			jira @optIn(to: ["JiraIssueFieldMutations"]) {
				updateComponentsField(input: $input) {
					success
					errors {
						message
					}
					field {
						selectedComponentsConnection {
							edges {
								node {
									id
									name
									ari
									metadata
									componentId
									description
								}
							}
						}
					}
				}
			}
		}
	`);

	const getComponentProps = useCallback<
		PropsCallback<
			ComponentsInlineEditFragment,
			ComponentsInlineEditFragmentData,
			ComponentValueShape,
			AggValueShape,
			AdditionalProps
		>
	>(
		({ intl, softRefreshCallbacks }) => {
			const fetchSuggestions = async (query: string) => {
				const rankedComponentsQueryData = await fetchQuery<ui_issueViewComponentsField_Query>(
					environment,
					graphql`
						query ui_issueViewComponentsField_Query($ids: [ID!]!, $first: Int, $searchBy: String!) {
							jira {
								issueFieldsByIds(ids: $ids) @optIn(to: "JiraIssueFieldsByIds") {
									# eslint-disable-next-line @atlassian/relay/unused-fields
									edges {
										node {
											... on JiraComponentsField {
												components(first: $first, searchBy: $searchBy) {
													# eslint-disable-next-line @atlassian/relay/unused-fields
													edges {
														node {
															id
															name
															ari
															metadata
															componentId
															description
														}
													}
												}
											}
										}
									}
								}
							}
						}
					`,
					{
						ids: [data.id],
						searchBy: query,
						first: 50,
					},
				).toPromise();

				const options = mapNodes(
					mapNodes(rankedComponentsQueryData?.jira?.issueFieldsByIds)?.[0].components,
				).map((option) => toComponentValueShape(projectKey, option));

				return [{ heading: intl.formatMessage(messages.all), items: options }];
			};

			return {
				jiraIssueField: data,

				value: mapNodes(data.selectedComponentsConnection).map((option) =>
					toComponentValueShape(projectKey, option),
				),

				onValueConfirm(componentValue) {
					const newAggValue = transformComponentValueToAggShape(componentValue);
					softRefreshCallbacks.onSubmit(newAggValue);

					commit({
						variables: {
							input: {
								id: data.id,
								operations: [
									{
										operation: 'SET',
										ids: componentValue?.map(({ value }) => value.toString()) ?? [],
									},
								],
							},
						},
						onCompleted: (mutationData) => {
							if (mutationData.jira?.updateComponentsField?.success) {
								softRefreshCallbacks.onSubmitSucceeded(newAggValue);
								fireTrackAnalytics(createAnalyticsEvent({}), 'componentField updated', {
									componentCounts: countAddedAndRemovedComponents({
										pendingValue: mapNodes(newAggValue.selectedComponentsConnection),
										value: mapNodes(data.selectedComponentsConnection),
									}),
								});
							} else {
								softRefreshCallbacks.onSubmitFailed();
							}
						},
						onError() {
							softRefreshCallbacks.onSubmitFailed();
						},
						optimisticResponse: {
							jira: {
								updateComponentsField: {
									success: true,
									errors: [],
									field: {
										id: data.id,
										...newAggValue,
									},
								},
							},
						},
					});
				},

				additionalProps: {
					baseUrl: '',
					projectKey,
					isMobile,
					validate: () => null,
					fetchSuggestions,
					placeholder: intl.formatMessage(messages.placeholder),
					noValueText: intl.formatMessage(genericMessages.noValue),
					shouldShowFooter: (isProjectAdmin || isJiraAdmin) && isMobile,
					showPinButton: getShowPinButton(props.area),
					isGlobalComponentsEnabled: isGlobalComponentsEnabled === true,
					globalComponentsPropertyLoading,
				},
			};
		},
		[
			commit,
			createAnalyticsEvent,
			data,
			environment,
			globalComponentsPropertyLoading,
			isGlobalComponentsEnabled,
			isJiraAdmin,
			isMobile,
			isProjectAdmin,
			projectKey,
			props.area,
		],
	);

	const connectField = useConnectRelayField(props, data, getComponentProps);
	const componentName = getDisplayName(View);

	return (
		<ConnectedRelayFieldWrapper componentName={componentName}>
			<UFOSegment name="issue-field-components">
				<View {...connectField.componentProps} />
			</UFOSegment>
		</ConnectedRelayFieldWrapper>
	);
};
