import React, { type PropsWithChildren } from 'react';
import { SpotlightManager, SpotlightPulse, SpotlightTarget } from '@atlaskit/onboarding';

type Props = {
	showPulse: boolean;
};

export const SpotlightWrapper = ({ showPulse, children }: PropsWithChildren<Props>) => {
	return (
		<SpotlightManager>
			<SpotlightTarget name="new">
				<SpotlightPulse radius={3} pulse={showPulse}>
					{children}
				</SpotlightPulse>
			</SpotlightTarget>
		</SpotlightManager>
	);
};
