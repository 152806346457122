import type { IssueContentPanels } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import {
	type AddContentPanelSuccess,
	type RemoveContentPanelSuccess,
	type ContentPanelsCustomisedSuccess,
	ADD_CONTENT_PANEL_SUCCESS,
	REMOVE_CONTENT_PANEL_SUCCESS,
	CONTENT_PANELS_CUSTOMISED_SUCCESS,
} from '../../actions/ecosystem-content-panel-actions.tsx';
import {
	type FetchIssueSuccessAction,
	type RefreshIssueSuccessAction,
	FETCH_ISSUE_SUCCESS,
	REFRESH_ISSUE_SUCCESS,
} from '../../common/actions/issue-fetch-actions.tsx';
import { ISSUE_CONTENT_PANEL_CUSTOMISED } from '../../ecosystem/ecosystem-property-keys.tsx';

type State = IssueContentPanels;
type Action =
	| FetchIssueSuccessAction
	| RefreshIssueSuccessAction
	| AddContentPanelSuccess
	| RemoveContentPanelSuccess
	| ContentPanelsCustomisedSuccess;

export const initialState: Array<string> = [];

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: State = initialState, action: Action): State => {
	switch (action.type) {
		case FETCH_ISSUE_SUCCESS:
		case REFRESH_ISSUE_SUCCESS:
			return action.payload.issueContentPanels || state;

		case ADD_CONTENT_PANEL_SUCCESS:
			return [...state, action.payload];

		case REMOVE_CONTENT_PANEL_SUCCESS: {
			const { payload } = action;
			return state.filter((id) => id !== payload);
		}

		case CONTENT_PANELS_CUSTOMISED_SUCCESS:
			return state.includes(ISSUE_CONTENT_PANEL_CUSTOMISED)
				? state
				: [...state, ISSUE_CONTENT_PANEL_CUSTOMISED];

		default:
			return state;
	}
};
