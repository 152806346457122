import React from 'react';
import type { CustomGlyphProps } from '@atlaskit/icon';

export const dateGlyph = (props: CustomGlyphProps) => (
	<svg
		{...props}
		width="16"
		height="16"
		viewBox="4 4 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M5.77333 5.77778H18.2267C19.2062 5.77778 20 6.57333 20 7.55022V18.2276C20 18.4604 19.9541 18.6909 19.865 18.906C19.7759 19.1211 19.6452 19.3166 19.4805 19.4812C19.3159 19.6458 19.1204 19.7763 18.9052 19.8653C18.6901 19.9544 18.4595 20.0001 18.2267 20H5.77333C5.30317 20 4.85225 19.8133 4.51971 19.4809C4.18717 19.1485 4.00024 18.6977 4 18.2276V7.55022C4 6.57156 4.79289 5.77778 5.77333 5.77778ZM5.77778 9.33333V17.3333C5.77778 17.5691 5.87143 17.7952 6.03813 17.9619C6.20483 18.1286 6.43092 18.2222 6.66667 18.2222H17.3333C17.5691 18.2222 17.7952 18.1286 17.9619 17.9619C18.1286 17.7952 18.2222 17.5691 18.2222 17.3333V9.33333H5.77778ZM6.66667 4.88889C6.66667 4.65314 6.76032 4.42705 6.92702 4.26035C7.09372 4.09365 7.31981 4 7.55556 4C7.7913 4 8.0174 4.09365 8.18409 4.26035C8.35079 4.42705 8.44444 4.65314 8.44444 4.88889V5.77778H6.66667V4.88889ZM15.5556 4.88889C15.5556 4.65314 15.6492 4.42705 15.8159 4.26035C15.9826 4.09365 16.2087 4 16.4444 4C16.6802 4 16.9063 4.09365 17.073 4.26035C17.2397 4.42705 17.3333 4.65314 17.3333 4.88889V5.77778H15.5556V4.88889ZM7.55556 12.8889V11.1102H9.33333V12.8889H7.55556ZM14.6667 12.8889V11.1102H16.4444V12.8889H14.6667ZM11.1111 12.8889V11.1102H12.8898V12.8889H11.1111ZM7.55556 16.4444V14.6667H9.33333V16.4444H7.55556ZM11.1111 16.4444V14.6667H12.8898V16.4444H11.1111ZM14.6667 16.4444V14.6667H16.4444V16.4444H14.6667Z"
			fill="currentColor"
		/>
	</svg>
);
