import { useState } from 'react';

/**
 * Used to collect the `HTMLElement` rendered by each sortable item.
 *
 * This is used for triggering the flash animation after moving an item.
 */
function createItemRegistry() {
	const itemRegistry: Map<string, HTMLElement> = new Map();

	const registerItem = ({
		draggableId,
		element,
	}: {
		draggableId: string;
		element: HTMLElement;
	}) => {
		itemRegistry.set(draggableId, element);
		return () => {
			itemRegistry.delete(draggableId);
		};
	};

	return { itemRegistry, registerItem };
}

export function useItemRegistry() {
	const [instance] = useState(createItemRegistry);
	return instance;
}
