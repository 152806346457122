import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	greyLightest: {
		id: '@atlassian/jira-option-color-picker.color-picker.grey-lightest',
		defaultMessage: 'Grey lightest',
		description: 'Tooltip text for grey lightest color',
	},
	greyLighter: {
		id: '@atlassian/jira-option-color-picker.color-picker.grey-lighter',
		defaultMessage: 'Grey lighter',
		description: 'Tooltip text for grey lightest color',
	},
	grey: {
		id: '@atlassian/jira-option-color-picker.color-picker.grey',
		defaultMessage: 'Grey',
		description: 'Tooltip text for grey color',
	},
	greyDarker: {
		id: '@atlassian/jira-option-color-picker.color-picker.grey-darker',
		defaultMessage: 'Grey darker',
		description: 'Tooltip text for grey darker color',
	},
	greyDarkest: {
		id: '@atlassian/jira-option-color-picker.color-picker.grey-darkest',
		defaultMessage: 'Grey darkest',
		description: 'Tooltip text for grey darkest color',
	},
	purpleLightest: {
		id: '@atlassian/jira-option-color-picker.color-picker.purple-lightest',
		defaultMessage: 'Purple lightest',
		description: 'Tooltip text for purple lightest color',
	},
	purpleLighter: {
		id: '@atlassian/jira-option-color-picker.color-picker.purple-lighter',
		defaultMessage: 'Purple lighter',
		description: 'Tooltip text for purple lightest color',
	},
	purple: {
		id: '@atlassian/jira-option-color-picker.color-picker.purple',
		defaultMessage: 'Purple',
		description: 'Tooltip text for purple color',
	},
	purpleDarker: {
		id: '@atlassian/jira-option-color-picker.color-picker.purple-darker',
		defaultMessage: 'Purple darker',
		description: 'Tooltip text for purple darker color',
	},
	purpleDarkest: {
		id: '@atlassian/jira-option-color-picker.color-picker.purple-darkest',
		defaultMessage: 'Purple darkest',
		description: 'Tooltip text for purple darkest color',
	},
	blueLightest: {
		id: '@atlassian/jira-option-color-picker.color-picker.blue-lightest',
		defaultMessage: 'Blue lightest',
		description: 'Tooltip text for blue lightest color',
	},
	blueLighter: {
		id: '@atlassian/jira-option-color-picker.color-picker.blue-lighter',
		defaultMessage: 'Blue lighter',
		description: 'Tooltip text for blue lightest color',
	},
	blue: {
		id: '@atlassian/jira-option-color-picker.color-picker.blue',
		defaultMessage: 'Blue',
		description: 'Tooltip text for blue color',
	},
	blueDarker: {
		id: '@atlassian/jira-option-color-picker.color-picker.blue-darker',
		defaultMessage: 'Blue darker',
		description: 'Tooltip text for blue darker color',
	},
	blueDarkest: {
		id: '@atlassian/jira-option-color-picker.color-picker.blue-darkest',
		defaultMessage: 'Blue darkest',
		description: 'Tooltip text for blue darkest color',
	},
	tealLightest: {
		id: '@atlassian/jira-option-color-picker.color-picker.teal-lightest',
		defaultMessage: 'Teal lightest',
		description: 'Tooltip text for teal lightest color',
	},
	tealLighter: {
		id: '@atlassian/jira-option-color-picker.color-picker.teal-lighter',
		defaultMessage: 'Teal lighter',
		description: 'Tooltip text for teal lightest color',
	},
	teal: {
		id: '@atlassian/jira-option-color-picker.color-picker.teal',
		defaultMessage: 'Teal',
		description: 'Tooltip text for teal color',
	},
	tealDarker: {
		id: '@atlassian/jira-option-color-picker.color-picker.teal-darker',
		defaultMessage: 'Teal darker',
		description: 'Tooltip text for teal darker color',
	},
	tealDarkest: {
		id: '@atlassian/jira-option-color-picker.color-picker.teal-darkest',
		defaultMessage: 'Teal darkest',
		description: 'Tooltip text for teal darkest color',
	},
	greenLightest: {
		id: '@atlassian/jira-option-color-picker.color-picker.green-lightest',
		defaultMessage: 'Green lightest',
		description: 'Tooltip text for green lightest color',
	},
	greenLighter: {
		id: '@atlassian/jira-option-color-picker.color-picker.green-lighter',
		defaultMessage: 'Green lighter',
		description: 'Tooltip text for green lightest color',
	},
	green: {
		id: '@atlassian/jira-option-color-picker.color-picker.green',
		defaultMessage: 'Green',
		description: 'Tooltip text for green color',
	},
	greenDarker: {
		id: '@atlassian/jira-option-color-picker.color-picker.green-darker',
		defaultMessage: 'Green darker',
		description: 'Tooltip text for green darker color',
	},
	greenDarkest: {
		id: '@atlassian/jira-option-color-picker.color-picker.green-darkest',
		defaultMessage: 'Green darkest',
		description: 'Tooltip text for green darkest color',
	},
	limeLightest: {
		id: '@atlassian/jira-option-color-picker.color-picker.lime-lightest',
		defaultMessage: 'Lime lightest',
		description: 'Tooltip text for lime lightest color',
	},
	limeLighter: {
		id: '@atlassian/jira-option-color-picker.color-picker.lime-lighter',
		defaultMessage: 'Lime lighter',
		description: 'Tooltip text for lime lightest color',
	},
	lime: {
		id: '@atlassian/jira-option-color-picker.color-picker.lime',
		defaultMessage: 'Lime',
		description: 'Tooltip text for lime color',
	},
	limeDarker: {
		id: '@atlassian/jira-option-color-picker.color-picker.lime-darker',
		defaultMessage: 'Lime darker',
		description: 'Tooltip text for lime darker color',
	},
	limeDarkest: {
		id: '@atlassian/jira-option-color-picker.color-picker.lime-darkest',
		defaultMessage: 'Lime darkest',
		description: 'Tooltip text for lime darkest color',
	},
	yellowLightest: {
		id: '@atlassian/jira-option-color-picker.color-picker.yellow-lightest',
		defaultMessage: 'Yellow lightest',
		description: 'Tooltip text for yellow lightest color',
	},
	yellowLighter: {
		id: '@atlassian/jira-option-color-picker.color-picker.yellow-lighter',
		defaultMessage: 'Yellow lighter',
		description: 'Tooltip text for yellow lightest color',
	},
	yellow: {
		id: '@atlassian/jira-option-color-picker.color-picker.yellow',
		defaultMessage: 'Yellow',
		description: 'Tooltip text for yellow color',
	},
	yellowDarker: {
		id: '@atlassian/jira-option-color-picker.color-picker.yellow-darker',
		defaultMessage: 'Yellow darker',
		description: 'Tooltip text for yellow darker color',
	},
	yellowDarkest: {
		id: '@atlassian/jira-option-color-picker.color-picker.yellow-darkest',
		defaultMessage: 'Yellow darkest',
		description: 'Tooltip text for yellow darkest color',
	},
	orangeLightest: {
		id: '@atlassian/jira-option-color-picker.color-picker.orange-lightest',
		defaultMessage: 'Orange lightest',
		description: 'Tooltip text for orange lightest color',
	},
	orangeLighter: {
		id: '@atlassian/jira-option-color-picker.color-picker.orange-lighter',
		defaultMessage: 'Orange lighter',
		description: 'Tooltip text for orange lightest color',
	},
	orange: {
		id: '@atlassian/jira-option-color-picker.color-picker.orange',
		defaultMessage: 'Orange',
		description: 'Tooltip text for orange color',
	},
	orangeDarker: {
		id: '@atlassian/jira-option-color-picker.color-picker.orange-darker',
		defaultMessage: 'Orange darker',
		description: 'Tooltip text for orange darker color',
	},
	orangeDarkest: {
		id: '@atlassian/jira-option-color-picker.color-picker.orange-darkest',
		defaultMessage: 'Orange darkest',
		description: 'Tooltip text for orange darkest color',
	},
	redLightest: {
		id: '@atlassian/jira-option-color-picker.color-picker.red-lightest',
		defaultMessage: 'Red lightest',
		description: 'Tooltip text for red lightest color',
	},
	redLighter: {
		id: '@atlassian/jira-option-color-picker.color-picker.red-lighter',
		defaultMessage: 'Red lighter',
		description: 'Tooltip text for red lightest color',
	},
	red: {
		id: '@atlassian/jira-option-color-picker.color-picker.red',
		defaultMessage: 'Red',
		description: 'Tooltip text for red color',
	},
	redDarker: {
		id: '@atlassian/jira-option-color-picker.color-picker.red-darker',
		defaultMessage: 'Red darker',
		description: 'Tooltip text for red darker color',
	},
	redDarkest: {
		id: '@atlassian/jira-option-color-picker.color-picker.red-darkest',
		defaultMessage: 'Red darkest',
		description: 'Tooltip text for red darkest color',
	},
	magentaLightest: {
		id: '@atlassian/jira-option-color-picker.color-picker.magenta-lightest',
		defaultMessage: 'Magenta lightest',
		description: 'Tooltip text for magenta lightest color',
	},
	magentaLighter: {
		id: '@atlassian/jira-option-color-picker.color-picker.magenta-lighter',
		defaultMessage: 'Magenta lighter',
		description: 'Tooltip text for magenta lightest color',
	},
	magenta: {
		id: '@atlassian/jira-option-color-picker.color-picker.magenta',
		defaultMessage: 'Magenta',
		description: 'Tooltip text for magenta color',
	},
	magentaDarker: {
		id: '@atlassian/jira-option-color-picker.color-picker.magenta-darker',
		defaultMessage: 'Magenta darker',
		description: 'Tooltip text for magenta darker color',
	},
	magentaDarkest: {
		id: '@atlassian/jira-option-color-picker.color-picker.magenta-darkest',
		defaultMessage: 'Magenta darkest',
		description: 'Tooltip text for magenta darkest color',
	},
});
