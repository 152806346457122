/* eslint-disable max-classes-per-file */
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';

export class SharedViewFieldsUnavailableError extends FetchError {}

export class SharedViewAuthenticationMissingError extends Error {}

export class RemoteFieldsNotInitializedError extends Error {
	// when the project is not onboarded yet, trying to get fields will return empty list
	// we throw this to let the caller know that they need to try again
}

export class AccessForbiddenError extends Error {
	// when the user is not authorized to fetch the project and the fields
}
