import { isJsmTimelineEnabled } from '../feature-flags.tsx';
import messages from './messages.tsx';
import {
	COMMENTS,
	HISTORY,
	WORK_LOG,
	RESPONDER_ALERT_ENTRY_GROUP,
	INCIDENT_ENTRY_GROUP,
	type FilterDropdownOption,
	type FilterDropdownOptions,
	type IncidentActivityFeedEntryId,
	STAKEHOLDER_UPDATES_ENTRY_GROUP,
	APPROVAL,
	HIDDEN_ENTRY_GROUP,
} from './types.tsx';

export const jiraOnlyOptions: FilterDropdownOptions = {
	comments: {
		id: COMMENTS,
		i18nMessage: messages.filterDropdownComments,
	},
	history: {
		id: HISTORY,
		i18nMessage: messages.filterDropdownHistory,
	},
	workLog: {
		id: WORK_LOG,
		i18nMessage: messages.filterDropdownWorkLog,
	},
} as const;

export const jiraWithApprovalsOptions: FilterDropdownOptions = {
	...jiraOnlyOptions,
	approval: {
		id: APPROVAL,
		i18nMessage: messages.filterDropdownApprovals,
	},
} as const;

export const jsmApprovalOptions: FilterDropdownOptions = {
	approval: {
		id: APPROVAL,
		i18nMessage: messages.filterDropdownApprovals,
	},
} as const;

export const jsmIncidentOnlyOptions: FilterDropdownOptions = {
	responderAlerts: {
		id: RESPONDER_ALERT_ENTRY_GROUP,
		i18nMessage: messages.filterDropdownCheckboxAlerts,
	},
	incidentChanges: {
		id: INCIDENT_ENTRY_GROUP,
		i18nMessage: messages.filterDropdownCheckboxIncidentChanges,
	},
	stakeHolderChanges: {
		id: STAKEHOLDER_UPDATES_ENTRY_GROUP,
		i18nMessage: messages.filterDropdownCheckboxStakeholderUpdates,
	},
	...(isJsmTimelineEnabled()
		? {
				hiddenChange: {
					id: HIDDEN_ENTRY_GROUP,
					i18nMessage: messages.filterHiddenMessages,
				},
			}
		: {}),
};

export const jsmBaseOptions = {
	...jiraOnlyOptions,
	...jsmApprovalOptions,
};

export const jsmIncidentOptions = {
	...jsmBaseOptions,
	...jsmIncidentOnlyOptions,
} as const;

export const jsmBaseFilters = Object.values(jsmBaseOptions);

export const jiraOnlyFilters: FilterDropdownOption[] = Object.keys(jiraOnlyOptions).map(
	(k) => jiraOnlyOptions[k],
);

export const jsmIncidentFilters: FilterDropdownOption[] = Object.keys(jsmIncidentOptions).map(
	(k) => jsmIncidentOptions[k],
);

export const selectedFiltersDefault: readonly IncidentActivityFeedEntryId[] = [
	jsmIncidentOptions.comments.id,
	jsmIncidentOptions.history.id,
	jsmIncidentOptions.workLog.id,
	jsmIncidentOptions.approval.id,
	jsmIncidentOptions.responderAlerts.id,
	jsmIncidentOptions.incidentChanges.id,
	jsmIncidentOptions.stakeHolderChanges.id,
];
