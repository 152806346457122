// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	label: {
		id: 'issue.web-links-add-label',
		defaultMessage: 'Link to a web page',
		description: 'Link to a web page',
	},
});
