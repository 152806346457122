import { combineEpics, type Epic } from 'redux-observable';
import type { AssociatedIssuesContextActions } from '@atlassian/jira-associated-issues-context-service/src/actions.tsx';
import type { FieldConfigServiceActions } from '@atlassian/jira-issue-field-base/src/services/field-config-service/types.tsx';
import type { FieldValueServiceActions } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import type { State } from '../state/types.tsx';
import changeNotification from './change-notification/index.tsx';
import childCreatedInFullDialog from './child-created-in-full-dialog/index.tsx';
import createChildFailure from './create-child-failure/index.tsx';
import createChildRequest from './create-child-request/index.tsx';
import createChildRetry from './create-child-retry/index.tsx';
import createChildSuccess from './create-child-success/index.tsx';
import fetchDetailsForIssues from './fetch-details-for-issues/index.tsx';
import linkChildRequest from './link-child-request/index.tsx';
import linkChildSuccess from './link-child-success/index.tsx';
import reorderChildrenRequest from './reorder-children-request/index.tsx';
import requiresFullCreateCheck from './requires-full-create-check-request/index.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type StateEpic = Epic<any, State>;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	associatedIssuesContextActions?: AssociatedIssuesContextActions,
	fieldsValuesActions?: FieldValueServiceActions,
	fieldConfigActions?: FieldConfigServiceActions,
) =>
	combineEpics(
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		createChildRequest as StateEpic,
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		createChildSuccess(
			associatedIssuesContextActions,
			fieldsValuesActions,
			fieldConfigActions,
		) as StateEpic,
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		createChildFailure as StateEpic,
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		createChildRetry as StateEpic,
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		fetchDetailsForIssues as StateEpic,
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		childCreatedInFullDialog as StateEpic,
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		requiresFullCreateCheck as StateEpic,
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		reorderChildrenRequest as StateEpic,
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		changeNotification as StateEpic,
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		linkChildRequest(fieldsValuesActions, associatedIssuesContextActions) as StateEpic,
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		linkChildSuccess(
			associatedIssuesContextActions,
			fieldsValuesActions,
			fieldConfigActions,
		) as StateEpic,
	);
