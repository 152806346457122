/** @jsx jsx */
import React, { type FC, type PropsWithChildren, useCallback, useEffect } from 'react';
import { css, jsx } from '@compiled/react';
import {
	useClipboardContextActions,
	ClipboardStoreContainer,
	useClipboardContextBoundary,
} from '../store/index.tsx';

const invisibleDivStyles = css({
	// to remove this element from the "flow"
	display: 'contents',
	// to bypass important variables. Required for issue-view
	height: 'inherit',
	width: 'inherit',
});

const ClipboardContextBoundaryProvider: FC<PropsWithChildren> = ({ children }) => {
	const { setClipBoardContext } = useClipboardContextActions();
	const setRef = useCallback(
		(node: HTMLDivElement | null) => {
			setClipBoardContext(node);
		},
		[setClipBoardContext],
	);

	return (
		<div
			css={invisibleDivStyles}
			ref={setRef}
			data-testid="clipboard.controllers.boundary.clipboard-boundary"
		>
			{children}
		</div>
	);
};

export const ClipboardContextBoundaryContainer: FC<PropsWithChildren> = ({ children }) => (
	<ClipboardStoreContainer>
		<ClipboardContextBoundaryProvider>{children}</ClipboardContextBoundaryProvider>
	</ClipboardStoreContainer>
);

type ContainerRefProps = {
	container: HTMLElement | undefined | null;
};
const ClipboardContainerSetEffector: FC<ContainerRefProps> = ({ container }) => {
	const { setClipBoardContext } = useClipboardContextActions();

	useEffect(() => setClipBoardContext(container), [setClipBoardContext, container]);

	return null;
};
/**
 * overrides the clipboard boundary to the provided container
 */
export const OverrideClipboardBoundary: FC<
	PropsWithChildren<ContainerRefProps & { intent: 'support' | 'override' }>
> = ({ children, container, intent }) => {
	const currentContainer = useClipboardContextBoundary();

	if (!container || (currentContainer && intent === 'support')) {
		return <>{children}</>;
	}
	return (
		<ClipboardStoreContainer>
			<ClipboardContainerSetEffector container={container} />
			{children}
		</ClipboardStoreContainer>
	);
};
