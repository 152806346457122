/**
 * @generated SignedSource<<604ceeba5bf0655a0eb8c7ded299ffdb>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type assignToMe_issueFieldAssigneeAssignToMe_fragmentRefRoot$data = {
  readonly " $fragmentSpreads": FragmentRefs<"assignToMe_issueFieldAssigneeAssignToMe_AssignToMeWithControlledAssignee_fragmentRefRoot">;
  readonly " $fragmentType": "assignToMe_issueFieldAssigneeAssignToMe_fragmentRefRoot";
};
export type assignToMe_issueFieldAssigneeAssignToMe_fragmentRefRoot$key = {
  readonly " $data"?: assignToMe_issueFieldAssigneeAssignToMe_fragmentRefRoot$data;
  readonly " $fragmentSpreads": FragmentRefs<"assignToMe_issueFieldAssigneeAssignToMe_fragmentRefRoot">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "assignToMe_issueFieldAssigneeAssignToMe_fragmentRefRoot",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "assignToMe_issueFieldAssigneeAssignToMe_AssignToMeWithControlledAssignee_fragmentRefRoot"
    }
  ],
  "type": "Query"
};

(node as any).hash = "a10ea8519b5aaedecd7e28bde88be4a3";

export default node;
