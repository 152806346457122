/**
 * @generated SignedSource<<a16f2c9a9068f83de1c7ec4ed0b8b240>>
 * @relayHash 47519ccd70ecaf08d5e6402a6373186c
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID e961ce02df52a01507a3bf7e81efeeab8d8b0f4571b8af19759fb2ff78ab0892

import type { ConcreteRequest, Mutation } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type AccountStatus = "active" | "closed" | "inactive" | "%future added value";
export type JiraMultiValueFieldOperations = "ADD" | "REMOVE" | "SET" | "%future added value";
export type JiraUpdatePeopleFieldInput = {
  id: string;
  operations: ReadonlyArray<JiraPeopleFieldOperationInput>;
};
export type JiraPeopleFieldOperationInput = {
  ids: ReadonlyArray<string>;
  operation: JiraMultiValueFieldOperations;
};
export type people_issueFieldPeople_PeopleField_Mutation$variables = {
  input: JiraUpdatePeopleFieldInput;
};
export type people_issueFieldPeople_PeopleField_Mutation$data = {
  readonly jira: {
    readonly updatePeopleField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly " $fragmentSpreads": FragmentRefs<"people_issueFieldPeopleInlineEditFull_PeopleInlineEditViewWithIsEditable_fragmentRef">;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type people_issueFieldPeople_PeopleField_Mutation$rawResponse = {
  readonly jira: {
    readonly updatePeopleField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly id: string;
        readonly name: string;
        readonly selectedUsersConnection: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly __typename: "AtlassianAccountUser";
              readonly accountId: string;
              readonly accountStatus: AccountStatus;
              readonly email: string | null | undefined;
              readonly id: string;
              readonly name: string;
              readonly picture: AGG$URL;
            } | {
              readonly __typename: string;
              readonly accountId: string;
              readonly accountStatus: AccountStatus;
              readonly id: string;
              readonly name: string;
              readonly picture: AGG$URL;
            } | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined;
        readonly type: string;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type people_issueFieldPeople_PeopleField_Mutation = {
  rawResponse: people_issueFieldPeople_PeopleField_Mutation$rawResponse;
  response: people_issueFieldPeople_PeopleField_Mutation$data;
  variables: people_issueFieldPeople_PeopleField_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "success"
},
v3 = {
  "concreteType": "MutationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "message"
    }
  ]
},
v4 = {
  "kind": "ScalarField",
  "name": "id"
},
v5 = {
  "kind": "ScalarField",
  "name": "name"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "people_issueFieldPeople_PeopleField_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraPeopleFieldPayload",
            "kind": "LinkedField",
            "name": "updatePeopleField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraPeopleField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  {
                    "kind": "FragmentSpread",
                    "name": "people_issueFieldPeopleInlineEditFull_PeopleInlineEditViewWithIsEditable_fragmentRef"
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "people_issueFieldPeople_PeopleField_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraPeopleFieldPayload",
            "kind": "LinkedField",
            "name": "updatePeopleField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraPeopleField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "type"
                  },
                  {
                    "concreteType": "JiraUserConnection",
                    "kind": "LinkedField",
                    "name": "selectedUsersConnection",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraUserEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "__typename"
                              },
                              {
                                "kind": "ScalarField",
                                "name": "accountId"
                              },
                              (v4/*: any*/),
                              {
                                "kind": "ScalarField",
                                "name": "accountStatus"
                              },
                              (v5/*: any*/),
                              {
                                "kind": "ScalarField",
                                "name": "picture"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "name": "email"
                                  }
                                ],
                                "type": "AtlassianAccountUser"
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "e961ce02df52a01507a3bf7e81efeeab8d8b0f4571b8af19759fb2ff78ab0892",
    "metadata": {},
    "name": "people_issueFieldPeople_PeopleField_Mutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "f4502332a5a0b2b6713a1f35352bc33b";

export default node;
