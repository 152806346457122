import { fetchQuery, graphql } from 'react-relay';
import { createOperationDescriptor } from 'relay-runtime';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import {
	KNOWN_APPS,
	isBitbucketApp,
} from '@atlassian/jira-development-app-configuration-prompt/src/common/constants.tsx';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import getRelayEnvironment from '@atlassian/jira-relay-environment/src/index.tsx';
import ServicesDeploymentDataDetectedQuery, {
	type servicesDeploymentDataDetectedQuery$data,
} from '@atlassian/jira-relay/src/__generated__/servicesDeploymentDataDetectedQuery.graphql';
import ServicesGetShowAppConfigPromptQuery, {
	type servicesGetShowAppConfigPromptQuery$data,
} from '@atlassian/jira-relay/src/__generated__/servicesGetShowAppConfigPromptQuery.graphql';
import type { ConsolidatedDataResponse } from './types.tsx';

export const operationName = 'jswddFetchOperation';

const remoteLinkInformationQuery = `remoteLinkInformation {
      providers {
        id
        name
        homeUrl
        logoUrl
        documentationUrl
        actions {
          id
          label {
            value
          }
          templateUrl
        }
      }
      remoteLinks {
        id
        providerId
        displayName
        url
        type
        description
        status {
          appearance
          label
        }
        actionIds
        attributeMap {
          key
          value
        }
      }
    }`;

const createGraphqlQuery = (issueId: number) => `query ${operationName} {
  dataDepotEntities (issueId:${issueId}) {
    deploymentInformation {
      providers {
        id
        name
        homeUrl
        logoUrl
        documentationUrl
        listDeploymentsTemplateUrl
      }
      deployments {
        providerId
        deploymentSequenceNumber
        updateSequenceNumber
        displayName
        url
        description
        lastUpdated
        state
        pipeline {
          id
          displayName
          url
        }
        environment {
          id
          type
          displayName
        }
      }
    }
    featureFlagInformation {
      providers {
        id,
        createFlagTemplateUrl,
        linkFlagTemplateUrl
      },
      featureFlags {
        id,
        key,
        displayName,
        providerId,
        summary {
          url,
          lastUpdated,
          status{
            enabled,
            defaultValue,
            rollout {
              text,
              percentage,
              rules
            }
          }
        }
      }
    }
    ${remoteLinkInformationQuery}
  }
}`;

export const fetchReleasesData = (issueId: number): Promise<ConsolidatedDataResponse> =>
	fetchJson('/jsw2/graphql', {
		method: 'POST',
		body: JSON.stringify({
			operationName,
			query: createGraphqlQuery(issueId),
		}),
	});

export const getDeploymentDataDetected = (
	projectAri: Ari,
	updatedFrom: string,
): Promise<boolean> => {
	const query = graphql`
		query servicesDeploymentDataDetectedQuery($projectAri: ID!, $updatedFrom: DateTime) {
			devOps @required(action: THROW) {
				ariGraph {
					deploymentRelationships: relationships(
						filter: {
							from: $projectAri
							type: "project-associated-deployment"
							updatedFrom: $updatedFrom
						}
						first: 1
					) {
						nodes {
							__typename
						}
					}
				}
			}
		}
	`;
	const variables = {
		projectAri,
		updatedFrom,
	};
	const request = fetchQuery(getRelayEnvironment(), query, variables, {
		fetchPolicy: 'store-or-network',
	});
	/* fetchQuery will NOT retain the data for the query, meaning that it is not guaranteed that the
       data will remain saved in the Relay store at any point after the request completes. We need to
       explicitly retain the operation to ensure it doesn't get deleted. See https://relay.dev/docs/api-reference/fetch-query/#behavior */
	getRelayEnvironment().retain(
		createOperationDescriptor(ServicesDeploymentDataDetectedQuery, variables),
	);
	return request.toPromise().then((result) => {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const data = result as servicesDeploymentDataDetectedQuery$data;
		return (data?.devOps?.ariGraph?.deploymentRelationships?.nodes ?? []).length > 0;
	});
};

export const getShowAppConfigPrompt = (
	cloudAri: string,
	isJiraAdmin: boolean,
	isSiteAdmin: boolean,
): Promise<boolean> => {
	if (!isJiraAdmin && !isSiteAdmin) {
		return Promise.resolve(false);
	}

	const query = graphql`
		query servicesGetShowAppConfigPromptQuery($cloudAri: ID!) {
			jira @required(action: THROW) {
				devOps @required(action: THROW) {
					configStates(cloudId: $cloudAri, providerTypeFilter: [DEPLOYMENTS])
						@optIn(to: "Jira-config-states-by-provider")
						@required(action: THROW) {
						edges @required(action: THROW) {
							node @required(action: THROW) {
								id @required(action: THROW)
								config @required(action: THROW) {
									edges @required(action: THROW) {
										node @required(action: THROW) {
											status @required(action: THROW)
										}
									}
								}
							}
						}
					}
					isInContextConfigPromptDismissed(cloudId: $cloudAri, location: RELEASES_PANEL)
						@required(action: THROW)
				}
			}
		}
	`;
	const variables = {
		cloudAri,
	};
	const request = fetchQuery(getRelayEnvironment(), query, variables, {
		fetchPolicy: 'store-or-network',
	});
	/* fetchQuery will NOT retain the data for the query, meaning that it is not guaranteed that the
     data will remain saved in the Relay store at any point after the request completes. We need to
     explicitly retain the operation to ensure it doesn't get deleted. See https://relay.dev/docs/api-reference/fetch-query/#behavior */
	getRelayEnvironment().retain(
		createOperationDescriptor(ServicesGetShowAppConfigPromptQuery, variables),
	);

	return request
		.toPromise()
		.then((result) => {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			const data = result as servicesGetShowAppConfigPromptQuery$data;
			const isPromptDismissed = data.jira.devOps.isInContextConfigPromptDismissed || false;

			if (isPromptDismissed) {
				return false;
			}

			const appEdges = data.jira.devOps.configStates.edges || [];

			const unconfiguredDisplayableEdges = appEdges
				// Known apps filter
				.filter((edge) => KNOWN_APPS.includes(edge.node.id))
				// UNCONFIGURED filter
				.filter(
					(edge) =>
						edge.node.config.edges
							.map((configEdge) => configEdge.node)
							// finding apps that have only workspaces whose states are either NOT_SET or NOT_CONFIGURED
							.find((node) => node.status === 'CONFIGURED') === undefined,
				)
				// BBC Permission filter
				.filter((edge) => !isBitbucketApp(edge.node.id) || isJiraAdmin)
				// BBC no workspace filter.
				.filter((edge) => !isBitbucketApp(edge.node.id) || edge.node.config.edges.length !== 0)
				// Site admin filter for non-BBC apps
				.filter((edge) => isBitbucketApp(edge.node.id) || isSiteAdmin)
				.filter(Boolean);

			return unconfiguredDisplayableEdges.length > 0;
		})
		.catch((error) => {
			log.safeErrorWithoutCustomerData(
				'packages.development.summary.releases-field.src.services.servicesGetShowAppConfigPromptQuery',
				error,
			);
			return false;
		});
};
