import { useCallback, useState } from 'react';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { INVOKE_VALUE_FUNCTION_FAILED } from '@atlassian/jira-forge-ui-analytics/src/common/utils/get-error-type/index.tsx';
import { fireOperationalFailedEvent } from '@atlassian/jira-forge-ui-analytics/src/services/custom-field/index.tsx';
import type { ForgeCustomFieldValue } from '@atlassian/jira-forge-ui-types/src/common/types/contexts/custom-field.tsx';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import type { IssueId, IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { useAnalyticsAttributesContext } from '../analytics/atrributes-context/index.tsx';
import { executeValueFunction } from './rest.tsx';

export type ValueFunction = {
	value: unknown;
	renderedValue: string | null;
};

export const useValueFunction = (
	issueKey: IssueKey,
	issueId: IssueId | undefined,
	fieldId: string,
	extensionType: string | undefined,
	defaultLoadingState: boolean,
	onSave: ((value: ForgeCustomFieldValue) => void) | undefined,
) => {
	const [, { setFieldValue }] = useFieldValue({
		issueKey,
		fieldKey: fieldId,
	});

	const analyticsAttributes = useAnalyticsAttributesContext();
	const [value, setValue] = useState<ValueFunction | null>(null);
	const [error, setError] = useState<Error | null>(null);
	const [isLoading, setIsLoading] = useState(defaultLoadingState);

	const requestExecuteValueFunction = useCallback(async () => {
		if (!issueId) {
			return;
		}

		setError(null);
		setIsLoading(true);
		try {
			const response = await executeValueFunction(fieldId, [issueId]);
			if (response) {
				const valueFunction = response?.values[0];
				const newValue: ForgeCustomFieldValue = valueFunction.value;
				setValue(valueFunction);
				onSave && onSave(newValue);
				setFieldValue(issueKey, fieldId, newValue);
			}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (err: any) {
			const attributes = {
				...analyticsAttributes,
				error: new FetchError(err?.statusCode, `${INVOKE_VALUE_FUNCTION_FAILED} ${err.message}`),
			};
			fireOperationalFailedEvent(analyticsAttributes.source, attributes);

			setError(err);
		} finally {
			setIsLoading(false);
		}
		// we don't want onSave function to constantly recall this function
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [extensionType, setFieldValue, fieldId, issueId, issueKey, analyticsAttributes]);

	return { error, value, isLoading, requestExecuteValueFunction };
};
