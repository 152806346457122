/**
 * This will be used to set the default value for the number of options
 * to be fetched in the first request for the field options.
 */
export const FRAGMENT_FIELD_OPTIONS_FIRST = 100;

/**
 * This will be used to set the default value for the number of options
 * to be fetched in the first request for field options of the selectable
 * field using SelectableFieldEditView
 */
export const FRAGMENT_SELECTABLE_FIELD_OPTIONS_FIRST = 50;

/**
 * Default value for the number of options fetched on each iteration of
 * the infinite scrolling for field options of the selectable field
 * using SelectableFieldEditView
 */
export const SELECTABLE_FIELD_PAGE_OPTIONS = 50;

/**
 * This will be used to set the default value for the number of options
 * to be fetched in the first request for the multi version options.
 */
export const MULTI_VERSIONS_FIELD_OPTIONS_FETCH_FIRST = 1000;

/**
 * Debounce timeout for input search requests.
 */
export const SEARCH_DEBOUNCE_TIMEOUT = 300;

/**
 * The status indicating that the version is released.
 */
export const RELEASED = 'RELEASED' as const;
/**
 * The status indicating that the version is unreleased.
 */
export const UNRELEASED = 'UNRELEASED' as const;
/**
 * The status indicating that the version is archived.
 */
export const ARCHIVED = 'ARCHIVED' as const;
export const versionStatus = {
	RELEASED,
	UNRELEASED,
	ARCHIVED,
};
