import React from 'react';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import useConvertToIssue from './hook.tsx';
import type { Props } from './types.tsx';

const ConvertToIssue = ({ onClick, label, itemKey }: Props) => {
	const handleClick = useConvertToIssue(itemKey, onClick);

	return (
		<DropdownItem key="convert-to-issue" onClick={handleClick}>
			{label}
		</DropdownItem>
	);
};

export default ConvertToIssue;
