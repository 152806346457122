import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	delete: {
		id: 'issue-view-base.content.attachment.filmstrip-view-relay.delete',
		defaultMessage: 'Delete',
		description: '',
	},
	goto: {
		id: 'issue-view-base.content.attachment.filmstrip-view-relay.goto',
		defaultMessage: 'GoTo',
		description: '',
	},
	show: {
		id: 'issue-view-base.content.attachment.filmstrip-view-relay.show',
		defaultMessage: 'Show on card',
		description: '',
	},
	hide: {
		id: 'issue-view-base.content.attachment.filmstrip-view-relay.hide',
		defaultMessage: 'Hide on card',
		description: '',
	},
	confirmationHeader: {
		id: 'issue-view-base.content.attachment.filmstrip-view-relay.confirmation-header',
		defaultMessage: 'Delete this attachment?',
		description: '',
	},
	confirmationContent: {
		id: 'issue-view-base.content.attachment.filmstrip-view-relay.confirmation-content',
		defaultMessage: "Once you delete, it's gone for good.",
		description: '',
	},
});
