import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	label: {
		id: 'development-summary-development-field.summary.onboarding-message.label',
		defaultMessage: 'Development',
		description: 'Field title in the issue view.',
	},
	sectionFirstAction: {
		id: 'development-summary-development-field.summary.onboarding-message.section-first-action',
		defaultMessage: 'Learn more',
		description: 'Link to external url educating the user how to use dev tools',
	},
	sectionSecondAction: {
		id: 'development-summary-development-field.summary.onboarding-message.section-second-action',
		defaultMessage: 'Dismiss',
		description: 'cta to dismiss the section',
	},
	sectionMessageCanCreate: {
		id: 'development-summary-development-field.summary.onboarding-message.section-message-can-create',
		defaultMessage:
			'Link this issue to your code by including issue keys when creating a branch, commit, or pull request below.',
		description:
			'Body of section message describing how to use development tools. Prompts to creat a branch using a cta underneath',
	},
	sectionMessageCantCreate: {
		id: 'development-summary-development-field.summary.onboarding-message.section-message-cant-create',
		defaultMessage:
			'Link this issue to your code by including issue keys when creating a branch, commit, or pull request.',
		description: 'Body of section message describing how to use development tools',
	},
	sectionTitle: {
		id: 'development-summary-development-field.summary.onboarding-message.section-title',
		defaultMessage: 'Quick start development',
		description: 'Title of section message',
	},
	sectionMessageCanCreateIssueTermRefresh: {
		id: 'development-summary-development-field.summary.onboarding-message.section-message-can-create-issue-term-refresh',
		defaultMessage:
			'Link this issue to your code by including issue keys when creating a branch, commit, or pull request below.',
		description:
			'Body of section message describing how to use development tools. Prompts to creat a branch using a cta underneath',
	},
	sectionMessageCantCreateIssueTermRefresh: {
		id: 'development-summary-development-field.summary.onboarding-message.section-message-cant-create-issue-term-refresh',
		defaultMessage:
			'Link this issue to your code by including issue keys when creating a branch, commit, or pull request.',
		description: 'Body of section message describing how to use development tools',
	},
});
