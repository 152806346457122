import React, { useMemo } from 'react';
import {
	endOfQuarter,
	startOfQuarter,
	formatISO,
	format as formatReal,
	parse as parseReal,
	differenceInDays,
	parseISO,
	isValid,
} from 'date-fns';
import { useIntl } from '@atlassian/jira-intl';
import { messages } from './messages.tsx';
import type { IntervalValue } from './types.tsx';
import { getADGTimestampDisplaySetting, RELATIVE, getRelativeValueAndUnit } from './utils.tsx';

// TODO: these constant strings were floating around different places so i pulled them all here
// however, a fixed date format for all locales seems very wrong to me. potentially an i18n bug
export const DATETIME_FORMAT = 'LLL d, yyyy hh:mm a';
export const DATE_FORMAT = 'LLL d, yyyy';

export const DATE_FORMAT_DFNS = 'MMM D, YYYY';

export const DIFF_YEAR_FORMAT = 'MMM d, yyyy';
export const MONTH_ONLY_FORMAT_SHORT = 'MMM';
export const MONTH_ONLY_FORMAT_DIFF_YEAR = 'MMM, yyyy';
export const MONTH_WITH_DIFF_YEAR_FORMAT = 'MMM yyyy';

const DEFAULT_DATE_FNS_OPTIONS: {
	weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
} = {
	weekStartsOn: 1, // Start on monday. Week is monday -> sunday.
};

// Wrap a date-fns method with default options param
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mixinDefaultOptions = <p extends (...args: any[]) => any>(fn: p) =>
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
	function (this: any, ...theArgs: any[]) {
		const lastIndex = fn.length - 1;
		if (fn.length === theArgs.length) {
			Object.assign(theArgs, {
				lastIndex: {
					...DEFAULT_DATE_FNS_OPTIONS,
					...theArgs[lastIndex],
				},
			});
		} else {
			Object.assign(theArgs, { lastIndex: DEFAULT_DATE_FNS_OPTIONS });
		}
		return fn.apply(this, theArgs);
	} as p;

export const format = mixinDefaultOptions(formatReal);
export const parse = mixinDefaultOptions(parseReal);

const formatWithLocale = (date: Date, dateFormat: string, locale?: string) =>
	format(date, dateFormat, locale ? { locale: { code: locale } } : undefined);

export const formatNiceDate = (date: Date, locale?: string) =>
	formatWithLocale(date, DIFF_YEAR_FORMAT, locale);

export const formatNiceMonthDate = (date: Date, locale?: string) =>
	formatWithLocale(date, MONTH_ONLY_FORMAT_DIFF_YEAR, locale);

export const formatMonthAndYearDate = (date: Date, locale?: string) =>
	formatWithLocale(date, MONTH_WITH_DIFF_YEAR_FORMAT, locale);

export const formatQuarterAndYearDate = (date: Date, locale?: string) =>
	`${formatWithLocale(startOfQuarter(date), MONTH_ONLY_FORMAT_SHORT)}-${formatWithLocale(
		endOfQuarter(date),
		MONTH_WITH_DIFF_YEAR_FORMAT,
		locale,
	)}`;

export const formatNiceQuarterDate = (date: Date, locale?: string) =>
	`${formatWithLocale(startOfQuarter(date), MONTH_ONLY_FORMAT_SHORT)}-${formatWithLocale(
		endOfQuarter(date),
		MONTH_ONLY_FORMAT_DIFF_YEAR,
		locale,
	)}`;

export const formatIsoLocalDate = (date: Date) => formatISO(date, { representation: 'date' });

// TODO: go/restrict-enums
// eslint-disable-next-line no-restricted-syntax
export enum FuzzyScale {
	DAY = 'day',
	MONTH = 'month',
	QUARTER = 'quarter',
}

const MONTH_THRESHOLD = 31;
const DAY_THRESHOLD = 1;

export const getFuzzyScale = (dateObj?: IntervalValue) => {
	if (dateObj) {
		const dateDiff = differenceInDays(new Date(dateObj.end), new Date(dateObj.start));
		if (dateDiff < DAY_THRESHOLD) {
			return FuzzyScale.DAY;
		}
		if (dateDiff <= MONTH_THRESHOLD) {
			return FuzzyScale.MONTH;
		}
		return FuzzyScale.QUARTER;
	}
	// If date is somehow undefined, we'll show quarters picker by default
	return FuzzyScale.QUARTER;
};

export const renderDateString = (dateString?: IntervalValue, placeholder?: string) => {
	if (!dateString) {
		return placeholder;
	}
	const endDate = parseISO(dateString.end);
	if (!isValid(endDate)) {
		return placeholder;
	}
	switch (getFuzzyScale(dateString)) {
		case FuzzyScale.DAY:
			return formatNiceDate(endDate);
		case FuzzyScale.QUARTER:
			return formatNiceQuarterDate(endDate);
		default:
		case FuzzyScale.MONTH:
			return formatNiceMonthDate(endDate);
	}
};

export const useAdgCompliantRelativeDate = (date?: string | number | null): string | undefined => {
	const { formatDate, formatRelativeTime, formatMessage } = useIntl();
	return useMemo(() => {
		if (date === null || date === undefined) {
			return undefined;
		}
		if (getADGTimestampDisplaySetting(date) === RELATIVE) {
			const [value, unit] = getRelativeValueAndUnit(date);
			if (unit === 'second' && value < 60 && value > -60) {
				return formatMessage(messages.justNow);
			}
			return formatRelativeTime(value, unit);
		}
		return formatDate(date, {
			year: 'numeric',
			month: 'long',
			day: 'numeric',
		});
	}, [date, formatDate, formatRelativeTime, formatMessage]);
};

type FormattedDateProps = {
	date: string | number;
};

export const FormattedDate = ({ date }: FormattedDateProps) => {
	const formatted = useAdgCompliantRelativeDate(date);
	return <>{formatted}</>;
};
