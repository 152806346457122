import React from 'react';
import { MountEvent } from '@atlassian/analytics-bridge';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import { sendExperienceAnalytics } from '@atlassian/jira-issue-view-analytics/src/controllers/send-experience-analytics/index.tsx';
import type { Application } from '@atlassian/jira-shared-types/src/application.tsx';
import type { ApplicationEdition } from '@atlassian/jira-shared-types/src/edition.tsx';

type EcosystemIssueViewProps = {
	children: React.ReactNode;
	application: Application | null;
	edition: ApplicationEdition | null;
	experience: string;
};

export const EcosystemIssueViewErrorBoundary = ({
	children,
	application,
	edition,
	experience,
}: EcosystemIssueViewProps) => (
	<ErrorBoundary
		id="issue.issue-view.issue-base.content.ecosystem-issue-view-error-boundary"
		onError={() => {
			sendExperienceAnalytics({
				experience,
				wasExperienceSuccesful: false,
				analyticsSource: 'issueViewEcosystemErrorBoundary',
				application,
				edition,
			});
		}}
	>
		{children}
		<MountEvent
			onMount={() => {
				sendExperienceAnalytics({
					experience,
					wasExperienceSuccesful: true,
					analyticsSource: 'issueViewEcosystemErrorBoundary',
					application,
					edition,
				});
			}}
		/>
	</ErrorBoundary>
);
