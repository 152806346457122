/**
 * @generated SignedSource<<ee5a9d6e284f348287e2fb3f43ac6b5a>>
 * @relayHash f4e6dff108665f56a065165cdb2881b0
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 5e0401767c05eeb61d927c1a0a29b23c159ff178e7311a8b9d713df6176dd8e4

import type { ConcreteRequest, Query } from 'relay-runtime';
export type AccountStatus = "active" | "closed" | "inactive" | "%future added value";
export type assignToMeMutationDataQuery$variables = {
  cloudId: string;
  issueKey: string;
  oneEventEnabled: boolean;
};
export type assignToMeMutationDataQuery$data = {
  readonly jira: {
    readonly issueByKey: {
      readonly assigneeField: {
        readonly fieldConfig: {
          readonly isEditable: boolean | null | undefined;
        } | null | undefined;
        readonly fieldId: string;
        readonly id: string;
        readonly name: string;
        readonly type: string;
        readonly user: {
          readonly accountId?: string;
          readonly id: string;
        } | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly me: {
    readonly user: {
      readonly accountId: string;
      readonly accountStatus: AccountStatus;
      readonly id: string;
      readonly name: string;
      readonly picture: AGG$URL;
    } | null | undefined;
  };
};
export type assignToMeMutationDataQuery = {
  response: assignToMeMutationDataQuery$data;
  variables: assignToMeMutationDataQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "issueKey"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "oneEventEnabled"
  }
],
v1 = {
  "kind": "ScalarField",
  "name": "accountId"
},
v2 = {
  "kind": "ScalarField",
  "name": "name"
},
v3 = {
  "kind": "ScalarField",
  "name": "picture"
},
v4 = {
  "kind": "ScalarField",
  "name": "id"
},
v5 = {
  "kind": "ScalarField",
  "name": "accountStatus"
},
v6 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "kind": "Variable",
    "name": "key",
    "variableName": "issueKey"
  }
],
v7 = {
  "kind": "ScalarField",
  "name": "fieldId"
},
v8 = {
  "kind": "ScalarField",
  "name": "type"
},
v9 = {
  "condition": "oneEventEnabled",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    (v1/*: any*/)
  ]
},
v10 = {
  "concreteType": "JiraFieldConfig",
  "kind": "LinkedField",
  "name": "fieldConfig",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "isEditable"
    }
  ]
},
v11 = {
  "kind": "ScalarField",
  "name": "__typename"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "assignToMeMutationDataQuery",
    "selections": [
      {
        "concreteType": "AuthenticationContext",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ]
          }
        ]
      },
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v6/*: any*/),
            "concreteType": "JiraIssue",
            "kind": "LinkedField",
            "name": "issueByKey",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraSingleSelectUserPickerField",
                "kind": "LinkedField",
                "name": "assigneeField",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v2/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v9/*: any*/)
                    ]
                  },
                  (v10/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "assignToMeMutationDataQuery",
    "selections": [
      {
        "concreteType": "AuthenticationContext",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v11/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ]
          }
        ]
      },
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v6/*: any*/),
            "concreteType": "JiraIssue",
            "kind": "LinkedField",
            "name": "issueByKey",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraSingleSelectUserPickerField",
                "kind": "LinkedField",
                "name": "assigneeField",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v2/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v11/*: any*/),
                      (v4/*: any*/),
                      (v9/*: any*/)
                    ]
                  },
                  (v10/*: any*/)
                ]
              },
              (v4/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "5e0401767c05eeb61d927c1a0a29b23c159ff178e7311a8b9d713df6176dd8e4",
    "metadata": {},
    "name": "assignToMeMutationDataQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "a01d8c8d4e4c6f4e792b20dc3da5a3e6";

export default node;
