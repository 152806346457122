import {
	performDeleteRequest,
	performPostRequest,
} from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type {
	ApplicationInput,
	PersistedWebLink,
} from '@atlassian/jira-issue-view-common-types/src/web-links-type.tsx';
import {
	type BaseUrl,
	type Href,
	type IssueKey,
	type IssueId,
	type WebLinkId,
	toWebLinkId,
} from '@atlassian/jira-shared-types/src/general.tsx';

type ServerResponse = {
	id: WebLinkId;
	self: string;
};

const createWebLinkUrl = (baseUrl: BaseUrl, issueId: IssueId) =>
	`${baseUrl}/rest/api/3/issue/${issueId}/remotelink`;

const createWebLinkPostBody = (
	linkText: string,
	href: Href,
	iconUrl: Href,
	application: ApplicationInput | undefined,
) => ({
	object: {
		icon: {
			url16x16: iconUrl,
		},
		title: linkText,
		url: href,
	},
	application: application || undefined,
});

const transformCreatedWebLink = (
	response: ServerResponse,
	href: Href,
	linkText: string,
	iconUrl: Href,
	application: ApplicationInput | undefined,
) =>
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	({
		id: toWebLinkId(response.id),
		href,
		linkText,
		iconUrl,
		resolved: null,
		relationship: null,
		applicationName: application?.name || null,
		applicationType: application?.type || null,
	}) as PersistedWebLink;

export const getDeleteRequest = (baseUrl: BaseUrl, issueKey: IssueKey, id: WebLinkId) =>
	performDeleteRequest(`${baseUrl}/rest/api/3/issue/${issueKey}/remotelink/${id}`);

export const createWebLinkRequest = ({
	baseUrl,
	issueId,
	href,
	linkText,
	iconUrl,
	application,
}: {
	baseUrl: BaseUrl;
	issueId: IssueId;
	href: Href;
	linkText: string;
	iconUrl: Href;
	application?: ApplicationInput;
}) =>
	performPostRequest(`${createWebLinkUrl(baseUrl, issueId)}`, {
		body: JSON.stringify(createWebLinkPostBody(linkText, href, iconUrl, application)),
	}).then((response) => transformCreatedWebLink(response, href, linkText, iconUrl, application));
