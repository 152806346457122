import { useCallback, useEffect, useRef } from 'react';
// eslint-disable-next-line jira/restricted/lottie
import type { AnimationItem, LottiePlayer } from 'lottie-web';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type { IntlFormatV2 } from '@atlassian/jira-intl/src/v2/types.tsx';

export type DataCallbackFn = () => Promise<{
	data: (formatMessage: IntlFormatV2['formatMessage']) => Record<string, unknown>;
}>;

export const useLazyLottie = <T extends HTMLElement>(dataCallback: DataCallbackFn) => {
	const lottieRef = useRef<T | null>(null);
	const instance = useRef<AnimationItem | null>(null);
	const { formatMessage } = useIntl();

	const loadAnimation = useCallback(
		async (container: HTMLElement) => {
			try {
				const Lottie = await import(/* webpackChunkName: "compal-spotlight-lottie" */ 'lottie-web');
				const { data } = await dataCallback();
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				instance.current = (Lottie as unknown as LottiePlayer).loadAnimation({
					container,
					renderer: 'svg',
					loop: true,
					autoplay: true,
					animationData: data(formatMessage),
				});
			} catch (err) {
				const error = err instanceof Error ? err : new Error('Unknown error');
				fireErrorAnalytics({
					error,
					meta: {
						id: 'compalSpotlightLottieError',
						packageName: 'jiraCommandPaletteDiscoverability',
						teamName: 'deliveroo',
					},
				});
			}
		},
		[dataCallback, formatMessage],
	);

	useEffect(() => {
		if (lottieRef.current !== null) {
			const container = lottieRef.current;
			loadAnimation(container);
		}
		return () => {
			if (instance.current !== null) {
				instance.current.destroy();
				instance.current = null;
			}
		};
	}, [loadAnimation]);

	return lottieRef;
};
