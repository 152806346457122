export const PERSONALIZATION_TAP_TRAIT_FETCHED = 'PERSONALIZATION_TAP_TRAIT_FETCHED' as const;

export type PersonalizationTapTraitFetchAction = {
	type: typeof PERSONALIZATION_TAP_TRAIT_FETCHED;
	payload: {
		hasShownIntentToXflowToConfluenceTrait: boolean;
	};
};

export const personalizationTapTraitFetched = (
	hasShownIntentToXflowToConfluenceTrait: boolean,
): PersonalizationTapTraitFetchAction => ({
	type: PERSONALIZATION_TAP_TRAIT_FETCHED,
	payload: {
		hasShownIntentToXflowToConfluenceTrait,
	},
});
