/**
 * @generated SignedSource<<29db414d6afefaaa1b7b6c8068c018e5>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutMetaContainer_IssueViewMetaContainer$data = {
  readonly createdField: {
    readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutMetaCreatedDateField_IssueViewCreatedDateField">;
  } | null | undefined;
  readonly resolutionDateField: {
    readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutMetaResolvedDateField_IssueViewResolvedDateField">;
  } | null | undefined;
  readonly updatedField: {
    readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutMetaUpdatedDateField_IssueViewUpdatedDateField">;
  } | null | undefined;
  readonly " $fragmentType": "ui_issueViewLayoutMetaContainer_IssueViewMetaContainer";
};
export type ui_issueViewLayoutMetaContainer_IssueViewMetaContainer$key = {
  readonly " $data"?: ui_issueViewLayoutMetaContainer_IssueViewMetaContainer$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutMetaContainer_IssueViewMetaContainer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutMetaContainer_IssueViewMetaContainer",
  "selections": [
    {
      "concreteType": "JiraDateTimePickerField",
      "kind": "LinkedField",
      "name": "createdField",
      "plural": false,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "ui_issueViewLayoutMetaCreatedDateField_IssueViewCreatedDateField"
        }
      ]
    },
    {
      "concreteType": "JiraDateTimePickerField",
      "kind": "LinkedField",
      "name": "updatedField",
      "plural": false,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "ui_issueViewLayoutMetaUpdatedDateField_IssueViewUpdatedDateField"
        }
      ]
    },
    {
      "concreteType": "JiraDateTimePickerField",
      "kind": "LinkedField",
      "name": "resolutionDateField",
      "plural": false,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "ui_issueViewLayoutMetaResolvedDateField_IssueViewResolvedDateField"
        }
      ]
    }
  ],
  "type": "JiraIssue"
};

(node as any).hash = "a71ce3a68365ca196ff43c7f039c2c97";

export default node;
