import type { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import {
	getMediaUploadPermissionUrl,
	type MediaUploadPermissionResponse,
} from '@atlassian/jira-issue-fetch-services-common/src/services/issue-media-upload-permissions/index.tsx';
import { IssueViewFetchError } from '@atlassian/jira-issue-view-errors/src/common/utils/issue-view-fetch-error/index.tsx';

export const fetchMediaUploadPermission$ = (
	baseUrl: string,
	issueKey: string,
): Observable<MediaUploadPermissionResponse> =>
	fetchJson$<MediaUploadPermissionResponse>(getMediaUploadPermissionUrl(baseUrl, issueKey)).catch(
		(error) => {
			throw new IssueViewFetchError(error, getMediaUploadPermissionUrl('', '{issueKey}'));
		},
	);

export const fetchMediaUploadPermission = (
	baseUrl: string,
	issueKey: string,
): Promise<MediaUploadPermissionResponse> =>
	fetchJson(getMediaUploadPermissionUrl(baseUrl, issueKey));
