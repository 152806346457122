import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	none: {
		id: 'issue-history.history-items.history-item.responders-history-item.responders-list.none',
		defaultMessage: 'None',
		description: 'Text indicating either from / to list has no responders',
	},
	teamIconLabel: {
		id: 'issue-history.history-items.history-item.responders-history-item.responders-list.team-icon-label',
		defaultMessage: 'Responder team icon',
		description: "Label passed to icon component that'll be used as arial label",
	},
	noTeamNameMessage: {
		id: 'issue-history.history-items.history-item.responders-history-item.responders-list.no-team-name-message',
		defaultMessage: 'Team',
		description: "Text shown in place of team name when the user doesn't have access to opsgenie",
	},
	noOpgenieAccessTeamNameTooltipMessage: {
		id: 'issue-history.history-items.history-item.responders-history-item.responders-list.no-opgenie-access-team-name-tooltip-message',
		defaultMessage: 'You need Opsgenie access to view this team',
		description: `Text shown in tooltip on the message when the user doesn't have access to opsgenie. 
            Message is the content in the key issue-history.history-items.history-item.responders-history-item.responders-list.team`,
	},
});
