import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { isPreviewSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';
import AttachmentContent from './main.tsx';
import type { StateProps } from './types.tsx';

export default connect(
	(state): StateProps => ({
		isPreview: isPreviewSelector(state),
	}),
	{},
)(AttachmentContent);
