/**
 * @generated SignedSource<<c6eb3c3fd2ec404cd165a031d2253654>>
 * @relayHash d50033a35b08f8be170b35fac9a17cfe
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 6cff0bff184dc8f1a073b553a9ba32cbb75ba7299e1ab00005f3c40a5bffd4db

import type { ConcreteRequest, Query } from 'relay-runtime';
export type JiraProjectType = "BUSINESS" | "CUSTOMER_SERVICE" | "PRODUCT_DISCOVERY" | "SERVICE_DESK" | "SOFTWARE" | "%future added value";
export type aiOptinDataQuery$variables = {
  cloudId: string;
  projectType: JiraProjectType;
};
export type aiOptinDataQuery$data = {
  readonly jira: {
    readonly isIssueViewEditorAiEnabled: boolean | null | undefined;
  } | null | undefined;
};
export type aiOptinDataQuery = {
  response: aiOptinDataQuery$data;
  variables: aiOptinDataQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectType"
},
v2 = [
  {
    "concreteType": "JiraQuery",
    "kind": "LinkedField",
    "name": "jira",
    "plural": false,
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "cloudId",
            "variableName": "cloudId"
          },
          {
            "kind": "Variable",
            "name": "jiraProjectType",
            "variableName": "projectType"
          }
        ],
        "kind": "ScalarField",
        "name": "isIssueViewEditorAiEnabled"
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "name": "aiOptinDataQuery",
    "selections": (v2/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "aiOptinDataQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": "6cff0bff184dc8f1a073b553a9ba32cbb75ba7299e1ab00005f3c40a5bffd4db",
    "metadata": {},
    "name": "aiOptinDataQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "4c56f4213bd1abff6264c36450debe12";

export default node;
