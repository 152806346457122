import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { cacheSelectorCreator } from '@atlassian/jira-polaris-lib-selector-creator-cache/src/index.tsx';
import {
	createIssueHook as createIssueHookMain,
	createHigherLevelIssueHook as createHigherLevelIssueHookMain,
} from '../main.tsx';
import type { Props, State } from '../types.tsx';
import { getSelectedIssueLocalIssueId } from './properties/index.tsx';

export type Selector<TValue> = (arg1: State, arg2: Props | undefined) => TValue;

export const createIssueHook = createIssueHookMain;

export const createHigherLevelIssueHook = <TArg1, TReturnValue>(
	selectorCreator: (arg1: TArg1) => (arg1: State, arg2: Props | undefined) => TReturnValue,
): ((arg1: TArg1) => TReturnValue) =>
	createHigherLevelIssueHookMain<TReturnValue, [TArg1]>(selectorCreator);

export const createHigherLevelIssueHook2 = <TArg1, TArg2, TReturnValue>(
	selectorCreator: (
		arg1: TArg1,
		arg2: TArg2,
	) => (arg1: State, arg2: Props | undefined) => TReturnValue,
): ((arg1: TArg1, arg2: TArg2) => TReturnValue) =>
	createHigherLevelIssueHookMain<TReturnValue, [TArg1, TArg2]>(selectorCreator);

export const createHigherLevelIssueHook3 = <TArg1, TArg2, TArg3, TReturnValue>(
	selectorCreator: (
		arg1: TArg1,
		arg2: TArg2,
		arg3: TArg3,
	) => (arg1: State, arg2: Props | undefined) => TReturnValue,
): ((arg1: TArg1, arg2: TArg2, arg3: TArg3) => TReturnValue) =>
	createHigherLevelIssueHookMain<TReturnValue, [TArg1, TArg2, TArg3]>(selectorCreator);

export const createHigherLevelIssueHook4 = <TArg1, TArg2, TArg3, TArg4, TReturnValue>(
	selectorCreator: (
		arg1: TArg1,
		arg2: TArg2,
		arg3: TArg3,
		arg4: TArg4,
	) => (arg1: State, arg2: Props | undefined) => TReturnValue,
): ((arg1: TArg1, arg2: TArg2, arg3: TArg3, arg4: TArg4) => TReturnValue) =>
	createHigherLevelIssueHookMain<TReturnValue, [TArg1, TArg2, TArg3, TArg4]>(selectorCreator);

/**
 * helper to create selected issue hooks with a single argument (fieldkey)
 */
export const createSelectedIssueHook = <TReturnValue,>(
	selectorCreator: (arg1: FieldKey, arg2: LocalIssueId) => Selector<TReturnValue | undefined>,
): ((arg1: FieldKey) => TReturnValue | undefined) => {
	const memoizedSelectorCreator = cacheSelectorCreator(selectorCreator);

	return createHigherLevelIssueHookMain((fieldKey) => (state: State, props: undefined | Props) => {
		const selectedIssueId = getSelectedIssueLocalIssueId(state);
		if (selectedIssueId === undefined) {
			return undefined;
		}
		const selector = memoizedSelectorCreator(fieldKey, selectedIssueId);
		return selector(state, props);
	});
};

/**
 * helper to create hooks for selector creators requiring the selected local issue id
 */
export const createSelectedIssueHook2 = <TReturnValue,>(
	selectorCreator: (arg1: LocalIssueId) => Selector<TReturnValue | undefined>,
): (() => TReturnValue | undefined) => {
	const memoizedSelectorCreator = cacheSelectorCreator(selectorCreator);

	return createIssueHookMain((state, props) => {
		const selectedIssueId = getSelectedIssueLocalIssueId(state);
		if (selectedIssueId === undefined) {
			return undefined;
		}
		const selector = memoizedSelectorCreator(selectedIssueId);
		return selector(state, props);
	});
};
