import { ExperiencePerformanceTypes, ExperienceTypes, UFOExperience } from '@atlassian/ufo';

export const customerServiceIssueViewPanelCustomerExperience = new UFOExperience(
	'jsm-cs-issue-view-panel-customer',
	{
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	},
);

export const customerServiceIssueViewPanelOrganizationExperience = new UFOExperience(
	'jsm-cs-issue-view-panel-organization',
	{
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	},
);

export const customerServiceIssueViewPanelEntitlementExperience = new UFOExperience(
	'jsm-cs-issue-view-panel-entitlement',
	{
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	},
);

export const experienceMap = {
	CUSTOMER: customerServiceIssueViewPanelCustomerExperience,
	ORGANIZATION: customerServiceIssueViewPanelOrganizationExperience,
	ENTITLEMENT: customerServiceIssueViewPanelEntitlementExperience,
} as const;
