/**
 * Finds multiple entities from a provided list of entities
 *
 * @param value - the list of values search being made by
 * @param lookupValues - list of entities where search is being made
 * @param lookupKey - property name of the entity from the lookupValues list with which value will be compared
 */

export const multiValueLookup = <T extends {}>(
	value: string[],
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	lookupValues: any[] = [],
	lookupKey: string,
): T[] => lookupValues.filter((option) => value.includes(option[lookupKey]));
