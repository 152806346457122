// This has TS defs, so when we move to TS let's enable them again
// @ts-expect-error - TS7016 - Could not find a declaration file for module 'jwt-decode'. '/buildeng/bamboo-agent-home/xml-data/build-dir/JF-TSMIG123-APPLY/node_modules/jwt-decode/lib/index.js' implicitly has an 'any' type.
import jwtDecode from 'jwt-decode';
import type { ViewResponse, ViewContext } from '../../common/types.tsx';

const TOKEN_NETWORK_BUFFER = 10000;

/**
 * Get the approx difference in seconds between server time & local time
 * @param {number} startTimestamp unix epoch in seconds
 * @returns
 */
export const getLocalTimeDeltaMilliseconds = (startTimestamp?: number) =>
	startTimestamp ? startTimestamp - Date.now() + 0 : undefined;

/**
 * Decode the token and create an object of relevant props
 * @param {string} token JWT from the server
 * @returns
 */
export const getTokenDeltaProps = (
	token: string,
): {
	tokenDecodeError?: string;
	localTimeDelta?: number;
	tokenExpiryTime?: number;
} => {
	let decodedToken;
	try {
		decodedToken = jwtDecode(token);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		// why would this be the case
		return {
			tokenDecodeError: typeof error.message === 'string' ? error.message : undefined,
			localTimeDelta: undefined,
			tokenExpiryTime: undefined,
		};
	}

	// In my testing, sometimes issued at was missing
	const startTimestamp = decodedToken.iat || decodedToken.nbf;

	return {
		tokenDecodeError: undefined,
		localTimeDelta: getLocalTimeDeltaMilliseconds(startTimestamp * 1000),
		tokenExpiryTime: decodedToken.exp * 1000,
	};
};

/**
 * Is this viewContext/token valid?
 * @returns true when present & valid, false while still loading or after expired
 */
export function isTokenValid({ token, tokenExpiryTime, localTimeDelta }: ViewContext): boolean {
	if (typeof token !== 'string' || token.length === 0) {
		return false;
	}
	if (typeof tokenExpiryTime !== 'number' || typeof localTimeDelta !== 'number') {
		return false;
	}
	const actualExpiryTime = tokenExpiryTime + localTimeDelta - TOKEN_NETWORK_BUFFER;
	const timeUntilExpiry = actualExpiryTime - Date.now();
	const isValid = timeUntilExpiry >= 0;

	return isValid;
}

export const viewContextTransformer = (viewResponse: ViewResponse): ViewContext => {
	const token = viewResponse.tokensWithFiles[0]?.token || null;

	const tokenProps = typeof token === 'string' ? getTokenDeltaProps(token) : {};

	return {
		clientId: viewResponse.clientId,
		serviceHost: viewResponse.endpointUrl,
		token,
		areTokenPermissionsUpToDate: true,
		tokenLifespanInMs: viewResponse.tokenLifespanInSeconds * 1000,
		collection:
			viewResponse.collection !== undefined && viewResponse.collection !== ''
				? viewResponse.collection
				: null,
		tokenIssueTimestamp: Date.now() - TOKEN_NETWORK_BUFFER, // added 10s buffer to cover network calls,
		tokenDecodeError: tokenProps.tokenDecodeError,
		localTimeDelta: tokenProps.localTimeDelta,
		tokenExpiryTime: tokenProps.tokenExpiryTime,
	};
};
