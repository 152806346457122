import React, { Component } from 'react';
import type { PreloadedQuery } from 'react-relay';
import type { ServerSuggestions } from '@atlassian/jira-issue-internal-field-select/src/common/select-inline-edit/select-field/types.tsx';
import { selectFooterStyles } from '@atlassian/jira-issue-internal-field-select/src/common/styled.tsx';
import MultiSelectInlineEditView, {
	type Props as MultiSelectProps,
} from '@atlassian/jira-issue-internal-field-select/src/multi-select-inline-edit/index.tsx';
import type { createReleaseModalQuery as createReleaseModalQueryType } from '@atlassian/jira-relay/src/__generated__/createReleaseModalQuery.graphql';
import type { BaseUrl } from '@atlassian/jira-shared-types/src/general.tsx';
import type { Version } from '@atlassian/jira-software-releases-release-modals-relay/src/common/ui/types.tsx';
import { CreateReleaseModal } from '@atlassian/jira-software-releases-release-modals-relay/src/ui/create-release-modal/async.tsx';
import { CreateVersionButton } from './create-version-button/index.tsx';

export type Props = {
	canAdministerProject: boolean;
	canAdministerJira: boolean;
	getDataFromCache?: () => Promise<ServerSuggestions>;
} & MultiSelectProps & {
		baseUrl: BaseUrl;
		projectId: number;
		createNewItemMessage: string;
		createNewItemIconLabel?: string;
		versionFieldIds: string[];
		onVersionCreated: (version: Version, versionFieldIds: string[]) => void;
	};

type State = {
	isDialogOpen: boolean;
	data: ServerSuggestions;
	loading: boolean;
	error: boolean;
	inputQueryStr: string;
	createReleaseModalQueryReference:
		| PreloadedQuery<createReleaseModalQueryType, Record<string, unknown>>
		| null
		| undefined;
};

// This is for the case where customers have many number of versions that will render so many dropdown items
// that makes browser frozen. https://getsupport.atlassian.com/browse/PCS-121596 https://getsupport.atlassian.com/browse/PCS-48395
export const MAX_OPTIONS_PER_CATEGORY = 20;
export const prefilterAndLimitItems = (
	data: ServerSuggestions,
	inputQueryStr: string,
): ServerSuggestions =>
	data.map((categoryData) => ({
		...categoryData,
		items: categoryData.items
			.filter((x) => x.content.toLowerCase().includes(inputQueryStr.toLowerCase()))
			// apply sort to ensure that exact matches are not sliced off
			.sort(
				(a, b) =>
					Number(b.content.toLowerCase() === inputQueryStr.toLowerCase()) -
					Number(a.content.toLowerCase() === inputQueryStr.toLowerCase()),
			)
			.slice(0, MAX_OPTIONS_PER_CATEGORY),
	}));
// eslint-disable-next-line jira/react/no-class-components
export default class FixVersionsView extends Component<Props, State> {
	state = {
		isDialogOpen: false,
		data: [],
		loading: false,
		error: false,
		inputQueryStr: '',
		createReleaseModalQueryReference: null,
	};

	componentDidMount = () => {
		this.getDataFromCache();
	};

	getDataFromCache = () => {
		this.props.getDataFromCache?.().then((data: ServerSuggestions) => {
			this.setState({ data, loading: false });
		});
	};

	fetchSuggestions = (query: string, sessionId?: string) =>
		this.props.fetchSuggestions(query, sessionId).then((data) => {
			const { inputQueryStr } = this.state;

			const filteredAndSlicedData = prefilterAndLimitItems(data, inputQueryStr);

			this.setState({
				data: filteredAndSlicedData,
			});
			return filteredAndSlicedData;
		});

	openDialog = () => {
		this.setState({ isDialogOpen: true });
	};

	closeDialog = () => {
		this.setState({ isDialogOpen: false });
	};

	setQueryRef = (
		queryRef:
			| PreloadedQuery<createReleaseModalQueryType, Record<string, unknown>>
			| null
			| undefined,
	) => {
		this.setState({ createReleaseModalQueryReference: queryRef });
	};

	onInputChange = (inputQueryStr: string) => {
		this.setState({ inputQueryStr });
	};

	onSuccess = (version: Version) => {
		const { onChange, value, onVersionCreated, versionFieldIds } = this.props;

		const versionARI = { ari: version.ari };

		// dispatch a change event which will add the new version to the list and focus the field again
		onChange([...value, { content: version.name, value: version.id, ...versionARI }]);

		this.closeDialog();

		onVersionCreated(version, versionFieldIds);
	};

	renderSelect(selectProps: MultiSelectProps) {
		const { canAdministerProject, canAdministerJira, createNewItemMessage } = this.props;

		const shouldShowFooter = canAdministerProject || canAdministerJira;
		const { data, loading, error } = this.state;
		const initialData = { data, loading, error };

		const createNewItemIconLabel = { createNewItemIconLabel: this.props.createNewItemIconLabel };

		return (
			<MultiSelectInlineEditView
				{...selectProps}
				classNamePrefix="issue-view-fix-versions"
				styles={selectFooterStyles}
				onInputChange={this.onInputChange}
				shouldShowFooter={shouldShowFooter}
				footer={
					<CreateVersionButton
						createNewItemLabel={createNewItemMessage}
						onCreate={this.setQueryRef}
						{...createNewItemIconLabel}
					/>
				}
				onFooterSelect={this.openDialog}
				allowEmptyValue
				optionValuesSafeForAnalytics
				fetchSuggestions={this.fetchSuggestions}
				debounceFetchSuggestionsTime={300}
				initialData={initialData}
				customEditView={this.props.customEditView}
			/>
		);
	}

	render() {
		const {
			baseUrl,
			projectId,
			createNewItemMessage,
			canAdministerProject,
			canAdministerJira,
			onVersionCreated,
			versionFieldIds,
			...selectProps
		} = this.props;
		const { isDialogOpen, createReleaseModalQueryReference } = this.state;

		return (
			<>
				{isDialogOpen && createReleaseModalQueryReference != null && (
					<CreateReleaseModal
						queryReference={createReleaseModalQueryReference}
						onClose={this.closeDialog}
						projectId={String(projectId)}
						onReleaseCreation={this.onSuccess}
					/>
				)}
				{this.renderSelect(selectProps)}
			</>
		);
	}
}
