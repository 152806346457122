import type { AkSelectStyles } from '@atlassian/jira-common-components-picker/src/model.tsx';

export const mergeReactSelectStyles = (
	styles?: AkSelectStyles,
	...stylesToMergeArray: (AkSelectStyles | undefined)[]
): AkSelectStyles => {
	const originalStyles = styles || {};
	if (stylesToMergeArray.length === 0) return originalStyles;

	const result = {
		...originalStyles,
	};

	stylesToMergeArray.forEach((stylesToMerge?: AkSelectStyles) => {
		if (!stylesToMerge) return;

		Object.keys(stylesToMerge).forEach((key) => {
			const currentStyleFunction = result[key];
			const styleFunctionToMerge = stylesToMerge[key];

			if (currentStyleFunction) {
				// stylesToMerge should override originalStyles css props if they overlap (because stylesToMerge are passed from props), that's why we call it last
				// @ts-expect-error - TS2322 - Type '(base: AkSelectStyles, state: SelectElementState) => ElementStyles' is not assignable to type '(providedStyles: ElementStyles, state: SelectElementState) => ElementStyles'.
				result[key] = (base: AkSelectStyles, ...otherArgs) =>
					// @ts-expect-error - TS2345 - Argument of type 'AkSelectStyles' is not assignable to parameter of type 'ElementStyles'.
					styleFunctionToMerge(currentStyleFunction(base, ...otherArgs), ...otherArgs);
			} else {
				result[key] = styleFunctionToMerge;
			}
		});
	});

	return result;
};
