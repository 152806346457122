import type {
	Project,
	TransformedProject,
} from '@atlassian/jira-issue-gira-transformer-types/src/common/types/project.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { getQuery } from './graphql';

export const transformData = (project?: Project): TransformedProject => ({
	issueTypes: project ? project.issueTypes : [],
	newIssueViewLockedIn: Boolean(project && project.newIssueViewLockedIn),
});
