import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	completionPercentageText: {
		id: 'issue.views.common.child-issues-panel.progress-summary.completion-percentage-text',
		defaultMessage: '{completionPercentage}% Done',
		description:
			'Text to be displayed next to the child issues progress bar which indicates percentage of child issues completed. Please ensure translation for the word Done aligns with the status category translation.',
	},
});
