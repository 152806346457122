import { useMemo } from 'react';
import type { ForgeUiIssueData } from '@atlassian/jira-forge-ui-types/src/common/types/extension-data.tsx';
import { useIssueKey, useIssueId } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import { ISSUE_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import {
	useProjectId,
	useProjectKey,
	useProjectType,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import { toProjectId } from '@atlassian/jira-shared-types/src/general.tsx';

/*
 * `useForgeUiIssueData()` builds a base `extensionData` for rendering a Forge extension on issue view.
 * Please  __do not__ use it on other view than issue view
 */
export function useForgeUiIssueData(): Required<ForgeUiIssueData> | null {
	const issueKey = useIssueKey();
	const issueId = useIssueId();
	const projectKey = useProjectKey(issueKey);
	const projectId = useProjectId(projectKey);
	const projectType = useProjectType(projectKey);
	const [issueType] = useFieldValue({
		issueKey,
		fieldKey: ISSUE_TYPE,
	});

	return useMemo(
		() =>
			!issueId || !projectId || !projectType || !issueType?.name || !issueType?.id
				? null
				: {
						issue: {
							key: issueKey,
							id: issueId,
							type: issueType.name,
							typeId: issueType.id,
						},
						project: {
							id: toProjectId(projectId.toString()),
							key: projectKey,
							type: projectType,
						},
					},
		[issueKey, issueId, projectKey, projectId, projectType, issueType],
	);
}
