import React from 'react';
import Icon from '@atlaskit/icon';

const FFAgentGlyph = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M11.1458 2.20513C11.6747 1.90117 12.3253 1.90117 12.8542 2.20513L20.1399 6.39218C20.672 6.69796 21 7.26483 21 7.8785V16.2389C21 16.8526 20.672 17.4195 20.1399 17.7253L12.8542 21.9123C12.3253 22.2163 11.6747 22.2163 11.1458 21.9123L3.86011 17.7253C3.32804 17.4195 3 16.8526 3 16.2389V7.8785C3 7.26483 3.32803 6.69796 3.86011 6.39218L11.1458 2.20513Z"
			fill="#357DE8"
		/>
		<g clip-path="url(#clip0_1555_146874)">
			<path
				d="M11.2165 7.80291C11.185 7.17841 11.0584 6.34523 11.0382 6.045C11.2007 6.40574 11.137 5.47015 11.1018 5.31655C11.0869 5.47403 11.0195 5.35224 11.0337 5.24518C11.2749 5.39568 11.1108 2.84338 10.8996 4.15599C10.8449 4.77506 10.7415 6.13112 10.7266 7.11713C10.8524 7.59889 11.0876 9.62288 11.2165 7.80369V7.80291Z"
				fill="#669DF1"
			/>
			<path
				d="M8.85297 8.86955C8.70166 8.57321 8.53911 8.31642 8.22376 8.00223C8.24997 8.26212 8.11589 8.0837 8.10391 7.96112C8.32863 8.01 8.02675 7.67253 7.92038 7.62598C7.9511 7.67331 7.89641 7.71597 7.84548 7.64383C8.03499 7.62831 7.35934 6.80134 7.29492 7.17293C7.38181 7.30326 7.28743 7.36222 7.44323 7.30559C7.2732 7.45609 7.54661 7.45376 7.55784 7.47936C7.45297 7.64538 7.77432 7.67098 7.63949 7.71442C7.69117 8.03094 8.88143 9.5631 8.85297 8.87033V8.86955Z"
				fill="#669DF1"
			/>
			<path
				d="M7.72127 11.6493C7.37445 11.3622 6.99093 11.1706 6.43888 11.0698C6.66884 11.2901 6.40142 11.2001 6.28457 11.1093C6.80142 10.913 3.97522 10.7703 5.04787 11.2179C5.09581 10.9712 5.06285 11.2916 5.20742 11.2009C5.31828 11.1202 5.4239 11.1466 5.42165 11.2102C5.4209 11.2808 5.71828 11.0938 5.63738 11.2272C5.88532 11.266 8.29205 12.2443 7.72127 11.6493Z"
				fill="#669DF1"
			/>
			<path
				d="M7.36814 14.1768C6.96814 14.3117 6.59886 14.5251 6.24156 14.9362C6.53744 14.8548 6.31946 15.0425 6.18987 15.0844C6.3202 14.515 4.70299 16.8237 5.62583 16.1713C5.44231 15.9959 5.70223 16.1969 5.69699 16.0301C5.77115 15.674 6.12096 15.698 6.40261 15.2838C6.56216 15.0115 8.00783 14.2598 7.36814 14.1775V14.1768Z"
				fill="#669DF1"
			/>
			<path
				d="M19.0696 10.9168C18.6486 10.8788 18.2778 10.8819 17.7393 10.9323C18.0494 11.082 17.6411 11.0998 17.6044 10.9711C17.8554 10.9012 17.2029 10.8725 17.137 10.94C17.2209 10.9493 17.1842 11.0207 17.0913 11.0106C17.161 10.8291 15.5108 10.8353 16.385 11.1984C16.4119 11.1937 16.3932 11.0525 16.4344 11.0952C16.4224 11.3271 16.6614 11.1169 16.6936 11.1254C16.719 11.3054 17.0644 11.0944 16.9835 11.2185C17.1572 11.4319 19.8733 11.2961 19.0688 10.9168H19.0696Z"
				fill="#669DF1"
			/>
			<path
				d="M15.1802 6.6044C15.1495 6.19246 15.554 5.90543 15.6296 5.49194C15.4693 4.82555 14.1967 7.38871 14.6139 6.90773C14.739 6.7549 13.2678 9.03801 13.7937 9.03956C13.8124 9.08145 13.9944 8.82235 13.96 8.80373C13.9263 8.7696 13.8753 8.76183 13.9105 8.73003C14.1135 8.82622 14.0356 8.45075 14.0603 8.41274C14.4476 8.15052 14.6985 7.08306 15.1809 6.60286L15.1802 6.6044Z"
				fill="#669DF1"
			/>
			<path
				d="M10.8572 14.2146L10.1953 8.77356L16.9966 7.94618L15.2872 10.9149L17.6586 13.3872L10.8572 14.2146Z"
				fill="#101214"
			/>
			<path
				d="M9.17472 8.89763L7.81445 9.06311L8.84868 17.5648L10.2089 17.3993L9.17472 8.89763Z"
				fill="white"
			/>
			<path
				d="M13.9375 17.2501C13.5736 17.1578 13.4656 17.5799 13.4089 16.8651C13.3555 16.5365 13.1151 16.6276 13.0327 16.7368C12.9827 16.8021 13.1385 17.1128 13.0773 17.142C13.0773 17.142 13.0839 17.3987 13.1006 17.5382C13.0828 17.3897 12.5242 17.4707 12.266 17.581C12.266 17.581 12.0824 17.8073 12.3428 17.9457C12.3584 17.9412 12.8591 17.8771 13.0016 17.9322C13.2353 18.0988 13.055 18.1416 13.0973 18.3217C13.1396 18.5007 13.1351 19.292 13.6381 18.8305C13.6571 18.4703 13.6537 18.4005 13.5892 17.9052C13.6693 17.769 13.7917 17.6711 13.9765 17.7026C14.0744 17.6733 14.0933 17.1702 13.9386 17.2501H13.9375Z"
				fill="#669DF1"
			/>
			<path
				d="M15.4276 16.0962C15.7358 16.2426 16.1231 15.9927 16.3067 16.3011C16.0719 16.2043 16.1031 17.4492 16.4904 17.1543C16.5371 16.9607 16.6495 16.9843 16.4514 16.9179C16.7842 16.9157 16.4748 16.7018 16.4859 16.6714C16.6673 16.6669 16.4303 16.5634 16.6028 16.5994C16.7029 16.5791 16.4102 16.3225 16.6083 16.3911C16.6718 16.112 16.9811 16.1142 16.9121 15.9443C16.9155 16.0602 17.9438 16.0872 17.6589 15.6437C17.4786 15.6527 17.5019 15.4479 17.4752 15.7147C17.4207 15.3837 17.2994 15.7146 17.2705 15.7068C17.2438 15.521 17.1903 15.7777 17.1981 15.5965C17.1714 15.4985 16.9978 15.8114 17.0323 15.6066C16.4325 15.4479 16.9244 15.2768 16.5783 14.9323C16.5438 14.7725 16.6283 14.2536 16.2578 14.302C15.7703 14.4438 16.339 15.3297 16.0497 15.4839C15.9206 15.601 15.737 15.7484 15.4932 15.6482C15.373 15.646 15.2184 16.1424 15.4265 16.0974L15.4276 16.0962Z"
				fill="#669DF1"
			/>
		</g>
		<defs>
			<clipPath id="clip0_1555_146874">
				<rect
					width="16.3636"
					height="16.3636"
					fill="white"
					transform="translate(4.02344 4.16895)"
				/>
			</clipPath>
		</defs>
	</svg>
);

const FFAgentIcon = () => <Icon glyph={FFAgentGlyph} label="" />;

export default FFAgentIcon;
