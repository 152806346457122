/** @jsx jsx */
import React, {
	type ReactNode,
	type ReactElement,
	type MouseEvent,
	useRef,
	useEffect,
	useCallback,
	useState,
} from 'react';
import ReactDOM from 'react-dom';
import { css, jsx } from '@compiled/react';
import { DropIndicator } from '@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/box';
import { colors } from '@atlaskit/theme';

import { token } from '@atlaskit/tokens';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { useSortableItem } from '@atlassian/jira-issue-sortable/src/controllers/use-sortable-item/index.tsx';
import { ReorderMenu } from './reorder-menu/index.tsx';
import type { BaseItem } from './types.tsx';

export type ItemRendererArgs<Item extends BaseItem> = {
	isHovering: boolean;
	isActive: boolean;
	isFocused: boolean;
	isDragging: boolean;
	item: Item;
	isReorderMenuOpen: boolean;
	reorderMenu: ReactNode;
};

export type ItemRenderer<Item extends BaseItem> = (args: ItemRendererArgs<Item>) => ReactElement;

type ItemLineCardProps<Item extends BaseItem> = {
	item: Item;
	index: number;
	isReorderEnabled?: boolean;
	isInteractive?: boolean;
	isVariableHeight?: boolean;
	children: ItemRenderer<Item>;
	onClick: (item: Item, event: MouseEvent<HTMLElement>) => void;

	testId?: string;
	numItems: number;

	onReorderItem?: (args: { startIndex: number; finishIndex: number }) => void;
	setItemInRegistry: (args: { draggableId: string; element: HTMLElement }) => void;

	instanceId: string;
};

export const ItemLineCard = <Item extends BaseItem>({
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onReorderItem = () => {},
	isReorderEnabled = false,
	isInteractive = true,
	isVariableHeight = false,
	item,
	onClick: onClickProp,
	index,
	children,
	testId,
	setItemInRegistry,
	numItems,
	instanceId,
}: ItemLineCardProps<Item>) => {
	const {
		isFocused,
		isPressed,
		isHovering,
		onClick,
		onDragEnd,
		onFocusIn,
		onFocusOut,
		onMouseDown,
		onMouseEnter,
		onMouseLeave,
		onMouseUp,
	} = useCardInteractionState({ item, onClickProp });

	const ref = useRef<HTMLDivElement | null>(null);

	const draggableId = item.id;

	const { dragState } = useSortableItem({
		draggableId,
		index,
		ref,
		isReorderEnabled,
		onDragEnd,
		instanceId,
	});

	const isDragging = dragState.type === 'dragging';
	// Dragging should also count as an active state for consumers
	const isActive = isPressed || isDragging;

	useEffect(() => {
		const element = ref.current;
		if (!element) {
			return;
		}

		return setItemInRegistry({ draggableId, element });
	}, [draggableId, setItemInRegistry]);

	const [isReorderMenuOpen, setIsReorderMenuOpen] = useState(false);

	return (
		// Maintaining legacy behavior
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
		<div
			ref={ref}
			/**
			 * React's `onFocus` and `onBlur` behave like the `focusin` and `focusout` events.
			 * They bubble up.
			 */
			onFocus={onFocusIn}
			onBlur={onFocusOut}
			onMouseDown={onMouseDown}
			onMouseUp={onMouseUp}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			onClick={onClick}
			css={[
				cardStyles,
				isInteractive && interactiveStyles,
				!isVariableHeight && fixedHeightStyles,
				!isReorderEnabled && notDraggableStyles,
				isDragging && draggingStyles,
			]}
			data-testid={testId}
			role="listitem"
		>
			{children({
				isHovering,
				isActive,
				isFocused,
				isDragging,
				item,
				isReorderMenuOpen,
				reorderMenu: (
					<ReorderMenu
						itemIndex={index}
						onReorderItem={onReorderItem}
						numItems={numItems}
						itemName={item.issueSummary ?? ''}
						itemType={item.issueTypeName ?? ''}
						setIsOpen={setIsReorderMenuOpen}
					/>
				),
			})}
			{dragState.type === 'dragging-over' && dragState.shouldRenderDropIndicator && (
				<DropIndicator edge={dragState.edge} gap="1px" />
			)}
			{dragState.type === 'preview' &&
				ReactDOM.createPortal(item?.dragPreview ?? item.id, dragState.container)}
		</div>
	);
};

const isMiddleClick = (event: MouseEvent<HTMLElement> | MouseEvent<HTMLElement, MouseEvent>) =>
	event.button === 1;

function useCardInteractionState<Item extends BaseItem>({
	item,
	onClickProp,
}: {
	item: Item;
	onClickProp: (item: Item, event: MouseEvent<HTMLElement>) => void;
}) {
	/**
	 * Not using a union for this state because it is passed down to the
	 * consumer, so there are no guarantees around how this state is being
	 * used.
	 *
	 * It could be refactored to use a union but would require checking all
	 * usage.
	 */
	const [isFocused, setIsFocused] = useState(false);
	const [isHovering, setIsHovering] = useState(false);
	const [isPressed, setIsPressed] = useState(false);

	const onFocusIn = () => {
		setIsFocused(true);
	};

	const onFocusOut = () => {
		setIsFocused(false);
	};

	const propagateClick = (event: MouseEvent<HTMLElement>) => {
		onClickProp(item, event);
	};

	const onDragEnd = useCallback(() => {
		setIsPressed(false);
	}, []);

	const onMouseEnter = () => {
		setIsHovering(true);
	};

	const onMouseLeave = () => {
		setIsHovering(false);
		setIsPressed(false);
	};

	const onMouseDown = (event: MouseEvent<HTMLElement>) => {
		if (isMiddleClick(event)) {
			propagateClick(event);
		}

		setIsPressed(true);
	};

	const onMouseUp = () => {
		setIsPressed(false);
	};

	const onClick = (event: MouseEvent<HTMLElement>) => {
		if (event.defaultPrevented === true) {
			/**
			 * Originally used for dealing with `react-beautiful-dnd` but also
			 * required so that clicking the status lozenge doesn't count as
			 * a click on the card.
			 *
			 * Ideally interactive content would not be a DOM child of the card,
			 * which is also interactive.
			 */
			return;
		}

		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		if (!event.currentTarget.contains(event.target as Node)) {
			/**
			 * This is to ignore clicks on portalled elements (such as dropdown
			 * menu items)
			 */
			return;
		}

		if (!isMiddleClick(event)) {
			propagateClick(event);
		}
	};

	return {
		isFocused,
		isPressed,
		isHovering,
		onDragEnd,
		onFocusIn,
		onFocusOut,
		onMouseEnter,
		onMouseLeave,
		onMouseDown,
		onMouseUp,
		onClick,
	};
}

const cardStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.background.neutral.subtle', colors.N0),
	display: 'flex',
	/**
	 * Using `position: 'relative'` so that
	 * the drop indicator is positioned relative to this element.
	 */
	position: 'relative',
	padding: `0 ${token('space.100', '8px')}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderBottom: `1px solid ${token('color.border', colors.N30)}`,
	cursor: 'auto',
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('color.background.neutral.subtle.hovered', colors.N30),
		textDecoration: 'none',
	},
	'&:active': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('color.background.neutral.subtle.pressed', colors.B50),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':first-of-type': {
		borderTopLeftRadius: token('border.radius', '3px'),
		borderTopRightRadius: token('border.radius', '3px'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':last-of-type': {
		borderBottomLeftRadius: token('border.radius', '3px'),
		borderBottomRightRadius: token('border.radius', '3px'),
		borderBottom: 'none',
	},
});

const interactiveStyles = css({
	cursor: 'pointer',
});

const fixedHeightStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	height: token('space.500', `${gridSize * 5}px`),
});

const notDraggableStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:last-child': {
		borderBottomColor: 'transparent',
	},
});

const draggingStyles = css({
	opacity: 0.4,
});
