import { useMemo } from 'react';
import noop from 'lodash/noop';
import type AnalyticsClient from '@atlassiansox/analytics-web-client';
import fireErrorAnalytics, {
	type AnalyticsMeta as SentryAnalyticsMeta,
} from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { getAnalyticsWebClientPromise } from '@atlassian/jira-product-analytics-web-client-async';

type AnalyticsMeta = {
	id: string;
	packageName: string;
	teamName: string;
	type?: string;
	[key: string]: string | undefined;
};

const SCREEN_EVENT_NAME = 'codeNotDeadDetector';

const getEventKey = (meta: AnalyticsMeta) => {
	if (meta.type) {
		return `${meta.packageName}-${meta.id}-${meta.type}`;
	}
	return `${meta.packageName}-${meta.id}`;
};

const RaisedEventsRegister: { [key: string]: boolean } = {};

const hasEventBeenRaised = (meta: AnalyticsMeta) => {
	return RaisedEventsRegister[getEventKey(meta)] === true;
};

const setEventHasBeenRaised = (meta: AnalyticsMeta) => {
	RaisedEventsRegister[getEventKey(meta)] = true;
};

const sendScreenEvent = async (
	clientPromise: Promise<AnalyticsClient> | AnalyticsClient,
	meta: AnalyticsMeta,
) => {
	(await clientPromise).sendScreenEvent({
		name: SCREEN_EVENT_NAME,
		attributes: {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			hostName: window?.location?.hostname || '',
			...meta,
		},
	});
};

const sendSentryError = (meta: AnalyticsMeta) => {
	if (!fg('jira_dead_code_detector_send_to_sentry')) {
		return;
	}

	const { id, packageName, teamName, ...otherAttributes } = meta;
	const sentryMeta: SentryAnalyticsMeta = { id, packageName, teamName };

	return fireErrorAnalytics({
		error: new Error(
			`WARNING: Code-not-dead warning (package: ${meta.packageName}, id: ${meta.id})`,
		),
		meta: sentryMeta,
		attributes: {
			componentId: meta.id,
			...otherAttributes,
		},
		sendToPrivacyUnsafeSplunk: false,
		skipSentry: false,
	});
};

export const useDeadCodeDetectorAnalytics = () => {
	if (!fg('jira_dead_code_detector')) {
		return { fireCodeNotDeadEvent: noop };
	}

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const clientPromise = useMemo(async () => {
		return (await getAnalyticsWebClientPromise()).getInstance();
	}, []);

	const fireCodeNotDeadEvent = async (meta: AnalyticsMeta) => {
		if (hasEventBeenRaised(meta)) {
			return;
		}

		setEventHasBeenRaised(meta);
		sendScreenEvent(clientPromise, meta);
		sendSentryError(meta);
	};

	return { fireCodeNotDeadEvent };
};

export const fireCodeNotDeadAnalyticEvent = async (meta: AnalyticsMeta) => {
	if (!fg('jira_dead_code_detector')) {
		return;
	}

	if (hasEventBeenRaised(meta)) {
		return;
	}

	const clientPromise = (await getAnalyticsWebClientPromise()).getInstance();

	setEventHasBeenRaised(meta);
	sendScreenEvent(clientPromise, meta);
	sendSentryError(meta);
};
