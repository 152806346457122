const NORM_MIN = 0;
const NORM_MAX = 100;

export const normalize = (x: number, min: number, max: number): number | undefined => {
	if (!Number.isFinite(x)) return undefined;
	if (x < min || x > max) return undefined;

	if (min === max) {
		if (max === 0) {
			return NORM_MIN;
		}
		return NORM_MAX;
	}
	return Math.floor(((x - min) * (NORM_MAX - NORM_MIN)) / (max - min) + NORM_MIN);
};
