import transformToISOString from '@atlassian/jira-common-date/src/utils/transform-to-iso-string/index.tsx';
import type {
	servicesMainJiraDevSummaryDevInfoSummaryQuery$data,
	JiraDevOpsIssuePanelState,
	JiraDevOpsIssuePanelBannerType,
	JiraReviewState,
	JiraPullRequestState,
} from '@atlassian/jira-relay/src/__generated__/servicesMainJiraDevSummaryDevInfoSummaryQuery.graphql';
import {
	SUMMARY_ITEM_BRANCH,
	SUMMARY_ITEM_BUILD,
	SUMMARY_ITEM_COMMIT,
	SUMMARY_ITEM_DEPLOYMENT,
	SUMMARY_ITEM_PULLREQUEST,
	SUMMARY_ITEM_REVIEW,
} from '../common/constants.tsx';
import type {
	PanelState,
	ReviewState,
	DevPanelOnboardingBanner,
	LegacyDevSummaryResult,
	PullRequestState,
	ByInstanceType,
	PullRequest,
	Review,
	Build,
	Commit,
	Branch,
	Deployment,
	Error as ErrorType,
	Summary,
	DevSummaryResult,
	DevSummary,
	SummaryBranchSummaryByProvider,
	SummaryCommitSummaryByProvider,
	SummaryPullRequestSummaryByProvider,
	SummaryBuildSummaryByProvider,
	SummaryReviewSummaryByProvider,
	SummaryErrors,
} from './types.tsx';

// @ts-expect-error - TS7006 - Parameter 'date' implicitly has an 'any' type.
const transformDate = (date) => {
	if (date !== undefined && date !== null) {
		return transformToISOString(date);
	}

	return null;
};

const transformError = ({ instance, message }: NonNullable<SummaryErrors>[0]): ErrorType => ({
	instanceName: instance?.name ?? '',
	instanceUrl: instance?.baseUrl ?? '',
	error: message ?? '',
});

const transformBySummaryProvider = (
	summaryByProvider?:
		| SummaryBranchSummaryByProvider
		| SummaryCommitSummaryByProvider
		| SummaryPullRequestSummaryByProvider
		| SummaryBuildSummaryByProvider
		| SummaryReviewSummaryByProvider,
): ByInstanceType =>
	// @ts-expect-error - TS2322 - Type '{ readonly count: number | null; readonly name: string | null; readonly providerId: string | null; } | {}' is not assignable to type 'ByInstanceType'.
	summaryByProvider
		? // @ts-expect-error - TS2345 - Argument of type '(instanceTypes: ByInstanceType, providerInstance: { readonly count: number | null; readonly name: string | null; readonly providerId: string | null; }) => { ...; }' is not assignable to parameter of type '(previousValue: ByInstanceType, currentValue: { readonly count: number | null; readonly name: string | null; readonly providerId: string | null; }, currentIndex: number, array: readonly { ...; }[]) => ByInstanceType'.
			summaryByProvider.reduce<ByInstanceType>((instanceTypes, providerInstance) => {
				if (providerInstance.providerId !== null && providerInstance.providerId !== undefined) {
					return {
						[providerInstance.providerId]: providerInstance.count,
						// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
						...instanceTypes,
					};
				}
				return instanceTypes;
			}, {})
		: {};

function getPullRequestState(state?: JiraPullRequestState | null): PullRequestState | null {
	switch (state) {
		case 'DECLINED':
			return 'DECLINED';
		case 'MERGED':
			return 'MERGED';
		case 'OPEN':
			return 'OPEN';
		case 'DRAFT':
			return 'DRAFT';
		default:
			return null;
	}
}

const transformPullRequest = (summary: DevSummary): PullRequest => {
	const { pullrequest } = summary;
	return {
		url: null,
		count: pullrequest?.overall?.count ?? 0,
		lastUpdated: transformDate(pullrequest?.overall?.lastUpdated ?? null),
		stateCount: pullrequest?.overall?.stateCount ?? 0,
		state: getPullRequestState(pullrequest?.overall?.state),
		byInstanceType: transformBySummaryProvider(pullrequest?.summaryByProvider),
	};
};

const transformBuild = (summary: DevSummary): Build => {
	const { build } = summary;
	return {
		url: null,
		count: build?.overall?.count ?? 0,
		lastUpdated: transformDate(build.overall.lastUpdated),
		failedBuildCount: build?.overall?.failedBuildCount ?? 0,
		successfulBuildCount: build?.overall?.successfulBuildCount ?? 0,
		unknownBuildCount: build?.overall?.unknownBuildCount ?? 0,
		byInstanceType: transformBySummaryProvider(build?.summaryByProvider),
	};
};

function getReviewState(state?: JiraReviewState | null): ReviewState | null {
	switch (state) {
		case 'APPROVAL':
			return 'APPROVAL';
		case 'CLOSED':
			return 'CLOSED';
		case 'DEAD':
			return 'DEAD';
		case 'DRAFT':
			return 'DRAFT';
		case 'REJECTED':
			return 'REJECTED';
		case 'REVIEW':
			return 'REVIEW';
		case 'SUMMARIZE':
			return 'SUMMARIZE';
		case 'UNKNOWN':
			return 'UNKNOWN';
		default:
			return null;
	}
}

const transformReview = (summary: DevSummary): Review => {
	const { review } = summary;
	return {
		count: review?.overall?.count ?? 0,
		lastUpdated: transformDate(review?.overall?.lastUpdated),
		stateCount: review?.overall?.stateCount ?? 0,
		state: getReviewState(review?.overall?.state),
		byInstanceType: transformBySummaryProvider(review?.summaryByProvider),
	};
};

const transformCommit = (summary: DevSummary): Commit => {
	const { commit } = summary;
	return {
		url: null,
		count: commit?.overall?.count ?? 0,
		lastUpdated: transformDate(commit?.overall?.lastUpdated),
		byInstanceType: transformBySummaryProvider(commit?.summaryByProvider),
	};
};

const transformBranch = (summary: DevSummary): Branch => {
	const { branch } = summary;
	return {
		url: null,
		count: branch?.overall?.count ?? 0,
		lastUpdated: transformDate(branch?.overall?.lastUpdated),
		byInstanceType: transformBySummaryProvider(branch?.summaryByProvider),
	};
};

const transformDeployment = (summary: DevSummary): Deployment => {
	const { deploymentEnvironments } = summary;
	return {
		count: deploymentEnvironments?.overall?.count ?? 0,
		environments: (deploymentEnvironments?.overall?.topEnvironments ?? []).map(
			(environment) => environment?.title ?? '',
		),
		byInstanceType: transformBySummaryProvider(deploymentEnvironments?.summaryByProvider),
	};
};

// @ts-expect-error - TS7006 - Parameter 'byProvider' implicitly has an 'any' type.
const mapToLegacyByInstanceType = (byProvider) =>
	byProvider.reduce(
		// @ts-expect-error - TS7006 - Parameter 'instanceAcc' implicitly has an 'any' type. | TS7031 - Binding element 'providerId' implicitly has an 'any' type. | TS7031 - Binding element 'name' implicitly has an 'any' type. | TS7031 - Binding element 'count' implicitly has an 'any' type.
		(instanceAcc, { providerId, name, count }) =>
			providerId !== null && providerId !== undefined
				? {
						// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
						...instanceAcc,
						[providerId]: {
							name,
							count,
						},
					}
				: instanceAcc,
		{},
	);

const mapToLegacyDataStructure = (
	result: NonNullable<DevSummaryResult>,
): LegacyDevSummaryResult => ({
	configErrors: (result.configErrors ?? []).map((error) => ({
		instance: error.instance,
		error: error.message,
	})),
	errors: (result.errors ?? []).map((error) => ({
		instance: error.instance,
		error: error.message,
	})),
	summary: {
		branch: {
			overall: {
				count: result.devSummary.branch.overall.count || 0,
				lastUpdated: result.devSummary.branch.overall.lastUpdated || '',
			},
			byInstanceType: mapToLegacyByInstanceType(result.devSummary.branch.summaryByProvider),
		},
		repository: {
			overall: {
				count: result.devSummary.commit.overall.count || 0,
				lastUpdated: result.devSummary.commit.overall.lastUpdated || '',
			},
			byInstanceType: mapToLegacyByInstanceType(result.devSummary.commit.summaryByProvider),
		},
		pullrequest: {
			overall: {
				count: result.devSummary.pullrequest.overall.count || 0,
				lastUpdated: result.devSummary.pullrequest.overall.lastUpdated || '',
				state: getPullRequestState(result.devSummary.pullrequest.overall.state),
				stateCount: result.devSummary.pullrequest.overall.stateCount || 0,
				open: result.devSummary.pullrequest.overall.open || false,
			},
			byInstanceType: mapToLegacyByInstanceType(result.devSummary.pullrequest.summaryByProvider),
		},
		build: {
			overall: {
				count: result.devSummary.build.overall.count || 0,
				lastUpdated: result.devSummary.build.overall.lastUpdated || '',
				failedBuildCount: result.devSummary.build.overall.failedBuildCount || 0,
				successfulBuildCount: result.devSummary.build.overall.successfulBuildCount || 0,
				unknownBuildCount: result.devSummary.build.overall.unknownBuildCount || 0,
			},
			byInstanceType: mapToLegacyByInstanceType(result.devSummary.build.summaryByProvider),
		},
		review: {
			overall: {
				count: result.devSummary.review.overall.count || 0,
				lastUpdated: result.devSummary.review.overall.lastUpdated || '',
				state: getReviewState(result.devSummary.review.overall.state),
				stateCount: result.devSummary.review.overall.stateCount || 0,
			},
			byInstanceType: mapToLegacyByInstanceType(result.devSummary.review.summaryByProvider),
		},
		'deployment-environment': {
			overall: {
				count: result.devSummary.deploymentEnvironments.overall.count || 0,
				lastUpdated: '',
				topEnvironments: [],
				showProjects: false,
				successfulCount: 0,
			},
			byInstanceType: mapToLegacyByInstanceType(
				result.devSummary.deploymentEnvironments.summaryByProvider,
			),
		},
	},
});

export const transformDevSummary = (result: DevSummaryResult): Summary => {
	if (!result) {
		return {
			legacyResponseData: undefined,
			[SUMMARY_ITEM_PULLREQUEST]: undefined,
			[SUMMARY_ITEM_BUILD]: undefined,
			[SUMMARY_ITEM_REVIEW]: undefined,
			[SUMMARY_ITEM_COMMIT]: undefined,
			[SUMMARY_ITEM_BRANCH]: undefined,
			[SUMMARY_ITEM_DEPLOYMENT]: undefined,
			errors: [],
			configErrors: [],
		};
	}

	const { errors, configErrors, devSummary: summary } = result;

	return {
		// TODO: legacyResponseData is only used for legacy dialogue, needs to be cleaned up when legacy dialogue is removed.
		legacyResponseData: mapToLegacyDataStructure(result),

		[SUMMARY_ITEM_BRANCH]: transformBranch(summary),
		[SUMMARY_ITEM_COMMIT]: transformCommit(summary),
		[SUMMARY_ITEM_PULLREQUEST]: transformPullRequest(summary),
		[SUMMARY_ITEM_BUILD]: transformBuild(summary),
		[SUMMARY_ITEM_REVIEW]: transformReview(summary),
		[SUMMARY_ITEM_DEPLOYMENT]: transformDeployment(summary),

		errors: errors ? errors.map<ErrorType>(transformError) : [],
		configErrors: configErrors ? configErrors.map<ErrorType>(transformError) : [],
	};
};
export type TransformedSummary = {
	summary: Summary;
	panelState: PanelState;
	bannerType: DevPanelOnboardingBanner | null | undefined;
	hasBranchCapabilities: boolean;
};

function getPanelState(state?: JiraDevOpsIssuePanelState | null): PanelState {
	switch (state) {
		case 'DEV_SUMMARY':
			return 'DEV_SUMMARY';
		case 'NOT_CONNECTED':
			return 'NOT_CONNECTED';
		case 'HIDDEN':
		default:
			return 'HIDDEN';
	}
}

function getDevOpsIssuePanelBanner(
	item?: JiraDevOpsIssuePanelBannerType | null,
): DevPanelOnboardingBanner | null {
	switch (item) {
		case 'ISSUE_KEY_ONBOARDING':
			return 'ISSUE_KEY_ONBOARDING';
		default:
			return null;
	}
}

export const transformSummary = (
	relaySummary: servicesMainJiraDevSummaryDevInfoSummaryQuery$data,
): TransformedSummary => {
	const issuePanelSummary = relaySummary.jira.devOps.devOpsIssuePanel;

	return {
		summary: transformDevSummary(issuePanelSummary.devSummaryResult),
		panelState: getPanelState(issuePanelSummary.panelState),
		bannerType: getDevOpsIssuePanelBanner(issuePanelSummary.devOpsIssuePanelBanner),
		hasBranchCapabilities: issuePanelSummary.hasBranchCapabilities || false,
	};
};
