import { APP_ID_PREFIX } from '@atlassian/jira-polaris-lib-realtime/src/common/types.tsx';

export const PREFIXED_APP_ID = `${APP_ID_PREFIX}ideas`;

export const VIEW_COMMENT_CREATED_EVENT = 'avi:polaris:created:comment';
export const VIEW_COMMENT_DELETED_EVENT = 'avi:polaris:deleted:comment';
export const VIEW_COMMENT_UPDATED_EVENT = 'avi:polaris:updated:comment';

export const VIEW_CREATED_EVENT = 'avi:polaris:created:view';
export const VIEW_DELETED_EVENT = 'avi:polaris:deleted:view';
export const VIEW_UPDATED_EVENT = 'avi:polaris:updated:view';

export const VIEWSET_CREATED_EVENT = 'avi:polaris:created:viewset';
export const VIEWSET_DELETED_EVENT = 'avi:polaris:deleted:viewset';
export const VIEWSET_UPDATED_EVENT = 'avi:polaris:updated:viewset';

export const VIEW_EVENTS = [
	VIEW_COMMENT_CREATED_EVENT,
	VIEW_COMMENT_DELETED_EVENT,
	VIEW_COMMENT_UPDATED_EVENT,
	VIEW_CREATED_EVENT,
	VIEW_DELETED_EVENT,
	VIEW_UPDATED_EVENT,
	VIEWSET_CREATED_EVENT,
	VIEWSET_DELETED_EVENT,
	VIEWSET_UPDATED_EVENT,
];
