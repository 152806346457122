import type { ConnectionFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/connection/types.tsx';
import type { IssueMetaIssueLink } from '@atlassian/jira-polaris-remote-issue/src/controllers/crud/types.tsx';
import { JPD_CONNECTION_ISSUE_LINK_TYPE } from '../../constants.tsx';
import { createGetLinkedIssueDataByIssueId } from '../../selectors/properties/linked-issues/index.tsx';
import type { State } from '../../types.tsx';

export const findConnectionIssueLink = (
	id: string,
	{ typeName, inwardIssue, outwardIssue }: IssueMetaIssueLink,
) =>
	typeName === JPD_CONNECTION_ISSUE_LINK_TYPE &&
	(outwardIssue.id.toString() === id || inwardIssue.id.toString() === id);

export const getLinkedIssueData = (issues: ConnectionFieldValue[], state: State) =>
	issues
		.map(({ id }) => {
			const issueData = createGetLinkedIssueDataByIssueId(parseInt(id, 10))(state);

			if (!issueData) {
				return null;
			}

			return {
				id: String(issueData?.issueId),
				issueKey: issueData.issueKey,
				summary: issueData.summary,
				issueType: issueData.issueType,
			};
		})
		.filter(Boolean);
