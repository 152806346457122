import type { MediaUploadPermissionResponse } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-media-upload-permissions/index.tsx';
import type { UploadContext } from '@atlassian/jira-issue-gira-transformer-types/src/common/types/media-context.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (uploadResponse: MediaUploadPermissionResponse): UploadContext => ({
	clientId: uploadResponse.clientId,
	serviceHost: uploadResponse.endpointUrl,
	token: uploadResponse.token,
	tokenLifespanInMs: uploadResponse.tokenDurationInMins * 60 * 1000,
	collection: uploadResponse.targetCollection,
	tokenIssueTimestamp: Date.now() - 10000, // added 10s buffer to cover network calls
});
