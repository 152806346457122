import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	production: {
		id: 'development-summary-deployment-releases-field.env-type.production',
		defaultMessage: 'Production',
		description: '',
	},
	staging: {
		id: 'development-summary-deployment-releases-field.env-type.staging',
		defaultMessage: 'Staging',
		description: '',
	},
	testing: {
		id: 'development-summary-deployment-releases-field.env-type.testing',
		defaultMessage: 'Testing',
		description: '',
	},
	development: {
		id: 'development-summary-deployment-releases-field.env-type.development',
		defaultMessage: 'Development',
		description: '',
	},
	unmapped: {
		id: 'development-summary-deployment-releases-field.env-type.unmapped',
		defaultMessage: 'Undefined environment',
		description: '',
	},
});
