import React, { type FC, type PropsWithChildren } from 'react';
import { styled } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import { token } from '@atlaskit/tokens';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { IssueClassificationBadge } from '@atlassian/jira-issue-classification-badge/src/ui/index.tsx';
import type { IssueViewDataClassificationFragment } from '@atlassian/jira-issue-classification-badge/src/ui/types.tsx';
import type { ui_issueFieldDataClassification_IssueViewDataClassification$key } from '@atlassian/jira-relay/src/__generated__/ui_issueFieldDataClassification_IssueViewDataClassification.graphql';

/**
 * Issue-View specific version of the IssueClassificationBadge
 */
const IssueViewDataClassificationWrapper: FC<PropsWithChildren> = ({ children }) => (
	<IssueViewDataClassificationWrapperStyle>{children}</IssueViewDataClassificationWrapperStyle>
);

/**
 * Aware of the `JiraIssue.fields` API and how to extract data from it using Relay fragment.
 */
export const IssueViewDataClassification = ({
	issueViewRelayFragment,
}: {
	issueViewRelayFragment: ui_issueFieldDataClassification_IssueViewDataClassification$key | null;
}) => {
	const dataClassification =
		useFragment<ui_issueFieldDataClassification_IssueViewDataClassification$key>(
			graphql`
				fragment ui_issueFieldDataClassification_IssueViewDataClassification on JiraIssue {
					fields {
						edges {
							node {
								... on JiraDataClassificationField {
									__typename
									...ui_issueClassificationBadge_IssueClassificationBadge
								}
							}
						}
					}
				}
			`,
			issueViewRelayFragment,
		);

	const classificationField =
		dataClassification?.fields?.edges
			?.map((edge) => edge?.node)
			// nodes constants also "%other" fields. Look for the one we need
			.find(
				(node): node is IssueViewDataClassificationFragment =>
					node?.__typename === 'JiraDataClassificationField',
			) ?? null;
	return (
		classificationField && (
			<IssueViewDataClassificationWrapper>
				<IssueClassificationBadge
					dataClassificationFragment={classificationField}
					popupZIndex={layers.modal}
				/>
			</IssueViewDataClassificationWrapper>
		)
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IssueViewDataClassificationWrapperStyle = styled.div({
	marginTop: `${token('space.050', '4px')}`,
});
