import TextAreaInlineEditView from '@atlassian/jira-issue-internal-fields/src/text-area/text-area-inline-edit-view.tsx';
import connectField from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-field.tsx';
import messages from './messages.tsx';

export default connectField((stateOnMount, ownPropsOnMount) => ({
	fieldId: ownPropsOnMount.fieldId,
	// @ts-expect-error - TS2322 - Type '(state: Readonly<{ agile: Agile; context: ContextState; entities: Readonly<{ applicationRoles?: ApplicationRole[] | undefined; cardCover: CardCover; childrenIssues: ChildrenIssuesState; ... 29 more ...; myPreferences?: Partial<...> | undefined; }>; ... 5 more ...; validators: Validators; }>, intl: IntlShapeV2) => { ...' is not assignable to type 'AdditionalProps<unknown>'.
	additionalProps: (state, intl) => ({
		noValueText: intl.formatMessage(messages.noValue),
		area: ownPropsOnMount.area,
	}),
}))(TextAreaInlineEditView);
