import ApplicationKeys, {
	type ApplicationKey,
} from '@atlassian/jira-common-constants/src/application-keys.tsx';

type FeedbackConfigType = Record<
	ApplicationKey,
	{
		feedbackGroupId: string;
		collectorId: string;
	}
>;

// @ts-expect-error(PARTIAL_RECORD) TS2739 | TS2739 - Type '{ "software-issue": { feedbackGroupId: string; collectorId: string; }; "core-issue": { feedbackGroupId: string; collectorId: string; }; "service-desk": { feedbackGroupId: string; collectorId: string; }; "bitbucket-issue": { ...; }; }' is missing the following properties from type 'FeedbackConfigType': "", "connect-issue", "mobile-issue", "simple-software-board", "standard-roadmap-issue" | Type '{ "software-issue": { feedbackGroupId: string; collectorId: string; }; "core-issue": { feedbackGroupId: string; collectorId: string; }; "service-desk": { feedbackGroupId: string; collectorId: string; }; "bitbucket-issue": { ...; }; }' is missing the following properties from type 'FeedbackConfigType': "", "connect-issue", "mobile-issue", "simple-software-board", "standard-roadmap-issue"
export const feedbackComponentsProps: FeedbackConfigType = {
	[ApplicationKeys.SOFTWARE_ISSUE]: {
		feedbackGroupId: 'jira-software-full-issueview',
		collectorId: '12f21050',
	},
	[ApplicationKeys.CORE_ISSUE]: {
		feedbackGroupId: 'jira-core-full-issueview',
		collectorId: 'f7ac9eb0',
	},
	[ApplicationKeys.SERVICE_DESK]: {
		feedbackGroupId: 'jira-service-desk-full-issueview',
		collectorId: '3311e016',
	},
	[ApplicationKeys.BITBUCKET_ISSUE]: {
		feedbackGroupId: 'bitbucket-jira-issueview',
		collectorId: 'e7fdb917',
	},
};
