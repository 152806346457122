import has from 'lodash/has';
import keyBy from 'lodash/keyBy';
import type { OptionFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/option/types.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { IssuesRemote } from '@atlassian/jira-polaris-remote-issue/src/controllers/types.tsx';
import { defaultJiraUpdateService } from '../common/index.tsx';
import { SINGLE_SELECT } from '../constants.tsx';
import type { JiraFieldMapping } from '../types.tsx';

export const jiraOptionMapping = (
	issuesRemote: IssuesRemote,
	field: Field,
): JiraFieldMapping<OptionFieldValue | undefined> => {
	const optionsById = keyBy(field.options, ({ jiraOptionId }) => jiraOptionId);

	return {
		field,
		getValueFromJiraIssue: (issue) =>
			issue.fields[field.key] && has(optionsById, issue.fields[field.key].id)
				? {
						id: issue.fields[field.key].id,
					}
				: undefined,
		getFieldValueForJiraUpdate: (value) => {
			if (value === undefined) {
				return null;
			}

			return {
				...value,
				value: optionsById[value.id]?.value,
			};
		},
		getFieldValueForJiraBulkUpdate: (value) => ({
			[SINGLE_SELECT]: {
				fieldId: field.key,
				option: {
					optionId: value ? optionsById[value.id].jiraOptionId : -1,
				},
			},
		}),
		isMultiValueField: false,
		isSupportedByIssueUpdateApi: true,
		...defaultJiraUpdateService(issuesRemote, field.key),
	};
};
