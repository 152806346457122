import React from 'react';
import { styled } from '@compiled/react';
import InlineDialog from '@atlaskit/inline-dialog';
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';

import { token } from '@atlaskit/tokens';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import LokIllustration from '@atlassian/jira-illustrations/src/ui/adg4/brand/spots/objects/components/lock-red/index.tsx';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import messages from '../../../common/messages.tsx';
import lockImage from './assets/Lock.svg';
import type { Props, CreateDialog, CreateTextContent } from './types.tsx';

const createTextContent: CreateTextContent = (securityLevel) =>
	securityLevel.description ? (
		<>
			<Name>{securityLevel.name}</Name>
			<Box xcss={descriptionStyles}>{securityLevel.description}</Box>
		</>
	) : (
		<NameOnly>{securityLevel.name}</NameOnly>
	);

const imgRole = 'img';
const createDialog: CreateDialog = (securityLevel, { formatMessage }) => (
	<DialogContainer
		data-testid="issue-field.security-level.ui.security-level-view.details-popup.test"
		tabIndex={0}
	>
		<Title>{formatMessage(messages.securityLevel)}</Title>
		<Content>
			{fg('move_issue_view_assets_into_central_location') && (
				<Box xcss={imageWrapperStyles}>
					<LokIllustration alt={formatMessage(messages.securityLevel)} width={60} height={80} />
				</Box>
			)}
			{!fg('move_issue_view_assets_into_central_location') && (
				<Image role={imgRole} aria-label={formatMessage(messages.securityLevel)} />
			)}
			{createTextContent(securityLevel)}
		</Content>
	</DialogContainer>
);

const DetailsPopup = ({ trigger, isOpen, onChangeOpen, securityLevel }: Props) => {
	const intl = useIntl();
	const handleClose = () => onChangeOpen(false);

	return (
		<Container>
			<InlineDialog
				onClose={handleClose}
				content={createDialog(securityLevel, intl)}
				isOpen={isOpen}
				placement="bottom-end"
			>
				{trigger}
			</InlineDialog>
		</Container>
	);
};

export default DetailsPopup;

// There is currently no nice way of making the contents of an atlaskit/inline-dialog
// scrollable. So I had to resort to this ultra-specific selector.
// I've raised a ticket in Atlaskit requesting a change that would allow consumers
// of inline-dialog to specify if they want the content to be scrollable.
// More details in this ticket: AK-6465

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'> div:nth-of-type(2)': {
		overflow: 'auto',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DialogContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle', colors.N200),
	textAlign: 'center',
	font: token('font.body.UNSAFE_small'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${240 - gridSize * 6}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxHeight: `${300 - gridSize * 4}px`,
	marginBottom: token('space.negative.200', '-16px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Title = styled.p({
	font: token('font.body.small'),
	textTransform: 'uppercase',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Image = styled.div({
	width: '80px',
	height: '80px',
	margin: `${token('space.150', '12px')} 0`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	background: `center no-repeat url(${lockImage})`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Name = styled.div({
	fontWeight: token('font.weight.medium'),
	marginBottom: token('space.150', '12px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NameOnly = styled(Name)({
	marginBottom: token('space.200', '16px'),
});

const descriptionStyles = xcss({
	marginBottom: 'space.200',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Content = styled.div({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text', colors.N800),
});

const imageWrapperStyles = xcss({
	marginTop: 'space.150',
	marginBottom: 'space.150',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const visibleForTesting = {
	Content,
};
