export const DISCRETIZATION_TEMPLATE = 'discretization';

/**
 * Formula for discretizing a value given by a subformula. The discretization limits are given by a sorted array
 * of n upper bounds, e.g. [1, 10, 100, 100]. These for the n+1 "buckets" the numeric value is assigned to. The index
 * of the bucket is used as the index to yield the corresponding value.
 */
export interface GenericDiscretizationFormula<TRecursiveFormula> {
	template: typeof DISCRETIZATION_TEMPLATE;
	parameters: {
		formula: TRecursiveFormula;
		upperBounds: number[];
		values: number[];
	};
}
