import { createSelector } from 'reselect';
import pickBy from 'lodash/pickBy';
import { fg } from '@atlassian/jira-feature-gating';
import type { FieldsByKey } from '@atlassian/jira-polaris-domain-field/src/collections/types.tsx';
import {
	FIELD_TYPES_CATEGORIES,
	FIELD_TYPES,
} from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { FieldType } from '@atlassian/jira-polaris-domain-field/src/field-types/types.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { Props, State } from '../types.tsx';

const EMPTY_FIELDS: FieldsByKey = Object.freeze({});

export const getFields = createSelector(
	(_: State, props?: Props) => props?.fields,
	(fields) => {
		if (!fields) {
			return EMPTY_FIELDS;
		}
		return pickBy(fields, (field) => {
			const isHidden = field.configuration?.hidden;
			const isRestricted = field.hasRestrictedContext;
			return !isHidden && !isRestricted;
		});
	},
);

const getAllFields = createSelector(
	(_: State, props?: Props) => props?.fields,
	(fields) => fields || EMPTY_FIELDS,
);

const UNSUPPORTED_FIELD_TYPES_OLD: FieldType[] = [
	...FIELD_TYPES_CATEGORIES.ARCHIVED,
	FIELD_TYPES.DESCRIPTION,
	FIELD_TYPES.ISSUE_TYPE,
	FIELD_TYPES.ISSUE_ID,
];

const UNSUPPORTED_FIELD_TYPES: FieldType[] = [
	...FIELD_TYPES_CATEGORIES.ARCHIVED,
	FIELD_TYPES.DESCRIPTION,
	FIELD_TYPES.ISSUE_ID,
];

export const getFieldsForViewControls = createSelector(getFields, (fields) =>
	pickBy(
		fields,
		({ type }) =>
			!(
				fg('jpd_issues_relationships') ? UNSUPPORTED_FIELD_TYPES : UNSUPPORTED_FIELD_TYPES_OLD
			).some((t) => t === type),
	),
);

export const getAllFieldsForViewControls = createSelector(getAllFields, (fields) =>
	pickBy(
		fields,
		({ type }) =>
			!(
				fg('jpd_issues_relationships') ? UNSUPPORTED_FIELD_TYPES : UNSUPPORTED_FIELD_TYPES_OLD
			).some((t) => t === type),
	),
);

export const createGetFieldForFieldKey = (fieldKey?: FieldKey) =>
	createSelector(getFields, (fields) => (fieldKey === undefined ? undefined : fields[fieldKey]));
