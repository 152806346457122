import React from 'react';
import { RadioSelect } from '@atlaskit/select';
import { useIntl } from '@atlassian/jira-intl';
import { defaultSelectStyles } from '@atlassian/jira-issue-field-select-base/src/ui/react-select-styles/styled.tsx';
import SelectWrapper from '@atlassian/jira-issue-view-common-views/src/select-validation-wrapper/select-validation-wrapper.tsx';
import messages from './messages.tsx';

export type RadioValueItemShape = {
	label: string;
	value?: string;
	optionId?: string;
};

type Props = {
	value: RadioValueItemShape | null;
	options: RadioValueItemShape[];
	placeholder: string | null;
	invalidMessage: string | null;
	portalElement?: HTMLElement | null;
	onChange: (arg1: RadioValueItemShape) => void;
};

export default function RadioFieldView(props: Props) {
	const { invalidMessage, options, placeholder, onChange, value, portalElement } = props;

	const { formatMessage } = useIntl();

	const ariaLabel =
		value && value.label ? value.label : formatMessage(messages.ariaLabelForRadioSelectField);

	return (
		<SelectWrapper
			validationState={invalidMessage != null ? 'error' : null}
			validationMessage={invalidMessage}
		>
			<RadioSelect
				menuPortalTarget={portalElement}
				// @ts-expect-error - TS2322 - Type 'RadioValueItemShape[]' is not assignable to type 'readonly (OptionType | GroupTypeBase<OptionType>)[]'.
				options={options}
				placeholder={placeholder}
				// @ts-expect-error - TS2322 - Type '(arg1: RadioValueItemShape) => void' is not assignable to type '(value: OptionType | null, action: ActionMeta<OptionType>) => void'.
				onChange={onChange}
				// @ts-expect-error - TS2322 - Type 'RadioValueItemShape | null' is not assignable to type 'OptionType | readonly OptionType[] | null | undefined'.
				value={value}
				tabSelectsValue={false}
				autoFocus
				styles={defaultSelectStyles}
				spacing="compact"
				label={ariaLabel}
			/>
		</SelectWrapper>
	);
}

RadioFieldView.defaultProps = {
	value: null,
	options: [],
	placeholder: null,
	invalidMessage: null,
};

RadioFieldView.displayName = 'RadioFieldView';
