import React, { useMemo } from 'react';
import Link from '@atlaskit/link';
import { Box, Text, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import type { RouteResourceError } from '@atlassian/react-resource-router/src/common/types.tsx';
import messages from './messages.tsx';

type ErrorMessageProps = {
	error: RouteResourceError;
};

const AUP_ERROR_CODE = 451;
const AUP_ERROR_URL = 'https://www.atlassian.com/legal/acceptable-use-policy#disruption';

/**
 * Returns a boolean if the statuscode is one that indicates a retry might be helpful
 */
const isStatusCodeTransientError = (statusCode: number) => statusCode === 429 || statusCode >= 500;

export const ErrorMessage = ({ error }: ErrorMessageProps) => {
	const { formatMessage } = useIntl();

	const errorMessage = useMemo(() => {
		const errorCode = 'statusCode' in error && error.statusCode;

		if (errorCode === AUP_ERROR_CODE) {
			return (
				<Text>
					{formatMessage(messages.aupError, {
						policyLink: (
							<Link
								href={AUP_ERROR_URL}
								testId="servicedesk-ai-context-common.ui.ai-context-container.error.aup-link"
								target="_blank"
								rel="noopener noreferrer"
							>
								{formatMessage(messages.aup)}
							</Link>
						),
					})}
				</Text>
			);
		}

		if (errorCode && isStatusCodeTransientError(errorCode)) {
			return <Text>{formatMessage(messages.errorWithRefresh)}</Text>;
		}

		return <Text>{formatMessage(messages.error)}</Text>;
	}, [error, formatMessage]);

	return <Box xcss={errorContainerCss}>{errorMessage}</Box>;
};

const errorContainerCss = xcss({
	paddingInline: 'space.100',
	paddingBlockStart: 'space.100',
	paddingBlockEnd: 'space.200',
});
