/**
 * @generated SignedSource<<c94bdf1c79189333e24a348a4ea936cb>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraProjectType = "BUSINESS" | "CUSTOMER_SERVICE" | "PRODUCT_DISCOVERY" | "SERVICE_DESK" | "SOFTWARE" | "%future added value";
export type JiraStatusCategoryColor = "BLUE_GRAY" | "BROWN" | "GREEN" | "MEDIUM_GRAY" | "WARM_RED" | "YELLOW" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutStatusField_IssueViewStatusField$data = {
  readonly projectField: {
    readonly project: {
      readonly projectType: JiraProjectType | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly statusField: {
    readonly __typename: "JiraStatusField";
    readonly description: string | null | undefined;
    readonly fieldId: string;
    readonly id: string;
    readonly issue: {
      readonly issueId: string;
      readonly key: string;
    } | null | undefined;
    readonly name: string;
    readonly status: {
      readonly description: string | null | undefined;
      readonly id: string;
      readonly name: string | null | undefined;
      readonly statusCategory: {
        readonly colorName: JiraStatusCategoryColor | null | undefined;
        readonly id: string;
        readonly key: string | null | undefined;
        readonly name: string | null | undefined;
        readonly statusCategoryId: string;
      } | null | undefined;
      readonly statusId: string;
    };
    readonly type: string;
  } | null | undefined;
  readonly " $fragmentType": "ui_issueViewLayoutStatusField_IssueViewStatusField";
};
export type ui_issueViewLayoutStatusField_IssueViewStatusField$key = {
  readonly " $data"?: ui_issueViewLayoutStatusField_IssueViewStatusField$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutStatusField_IssueViewStatusField">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "id"
},
v1 = {
  "kind": "ScalarField",
  "name": "name"
},
v2 = {
  "kind": "ScalarField",
  "name": "description"
},
v3 = {
  "kind": "ScalarField",
  "name": "key"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutStatusField_IssueViewStatusField",
  "selections": [
    {
      "concreteType": "JiraProjectField",
      "kind": "LinkedField",
      "name": "projectField",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraProject",
          "kind": "LinkedField",
          "name": "project",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "projectType"
            }
          ]
        }
      ]
    },
    {
      "concreteType": "JiraStatusField",
      "kind": "LinkedField",
      "name": "statusField",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "name": "fieldId"
        },
        {
          "kind": "ScalarField",
          "name": "type"
        },
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "kind": "ScalarField",
          "name": "__typename"
        },
        {
          "concreteType": "JiraIssue",
          "kind": "LinkedField",
          "name": "issue",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "issueId"
            },
            (v3/*: any*/)
          ]
        },
        {
          "concreteType": "JiraStatus",
          "kind": "LinkedField",
          "name": "status",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "ScalarField",
              "name": "statusId"
            },
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "concreteType": "JiraStatusCategory",
              "kind": "LinkedField",
              "name": "statusCategory",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "name": "statusCategoryId"
                },
                (v3/*: any*/),
                (v1/*: any*/),
                {
                  "kind": "ScalarField",
                  "name": "colorName"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraIssue"
};
})();

(node as any).hash = "4c79671e9f26603e90731a809658362a";

export default node;
