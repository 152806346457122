import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import useAssigneeField from '@atlassian/jira-issue-field-assignee/src/services/use-assignee-field/index.tsx';
import { useCurrentUser } from '@atlassian/jira-platform-services-user-current/src/main.tsx';
import { useProjectKey } from '@atlassian/jira-project-context-service/src/main.tsx';
import { useProjectPermissions } from '@atlassian/jira-project-permissions-service/src/main.tsx';

/**
 * Used to identify if the user can perform specific issue actions
 */
export const useIssueActionsPermissions = () => {
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const [{ canBeAssignedToIssues, canAssignIssues, canAddComments }] =
		useProjectPermissions(projectKey);

	const {
		data: { user },
	} = useCurrentUser();

	const [{ value: assigneeField }] = useAssigneeField({ issueKey });

	const isCurrentUserAssigned = user.accountId === assigneeField?.accountId;

	return {
		canAssign: canBeAssignedToIssues && canAssignIssues,
		canAddComments,
		isCurrentUserAssigned,
	};
};
