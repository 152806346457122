import { useEffect } from 'react';

import { type UIAnalyticsEvent, useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useAIUnifiedAnalytics } from '../controllers/ai-unified-analytics';
import { type CreateAIOpsEvent } from '../controllers/ai-unified-analytics/types';

type Props = {
	onMount: (event: UIAnalyticsEvent, createAIOpsEventPayload: CreateAIOpsEvent) => void;
};

export const AIOpsSendUnifiedAIEvent = ({ onMount }: Props) => {
	const { createAIOpsEventPayload } = useAIUnifiedAnalytics();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(
		() => {
			onMount(createAnalyticsEvent({}), createAIOpsEventPayload);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	return null;
};
