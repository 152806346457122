// eslint-disable-next-line jira/restricted/react
import React, { PureComponent, type MouseEvent, type SyntheticEvent } from 'react';
import { defaultSchema } from '@atlaskit/adf-schema/schema-default';
import { ProviderFactory } from '@atlaskit/editor-common/provider-factory';
import { Text } from '@atlaskit/primitives';
import { ReactRenderer as AkRenderer } from '@atlaskit/renderer';
import { setupEditorExperiments } from '@atlaskit/tmp-editor-statsig/setup';
import type { ExtensionHandlers } from '@atlaskit/editor-common/extensions';
import { getEmojiProviderWithCustomEmoji } from '@atlassian/jira-common-atlaskit-services/src/emoji.tsx';
import checkForHref from '@atlassian/jira-common-components-html-view/src/check-for-href.tsx';
import iframeRedirect from '@atlassian/jira-common-navigation/src/iframe-redirect/index.tsx';
import { getUnsupportedContentLevelsTracking } from '@atlassian/jira-common-util-unsupported-content/src/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import {
	SEVERITY_DEGRADED_THRESHOLD,
	SEVERITY_NORMAL_THRESHOLD,
} from '@atlassian/jira-issue-view-common-constants/src/index.tsx';
import {
	isCmdOrCtrlClick,
	isMiddleClick,
} from '@atlassian/jira-issue-view-common-utils/src/events/index.tsx';
import {
	isExternalLink,
	smartLinksDefault,
} from '@atlassian/jira-linking-platform-utils/src/index.tsx';
import {
	removeCollectionFromAdf,
	isOnlyWhitespaceAdf,
} from '@atlassian/jira-rich-content/src/common/adf-parsing-utils.tsx';
import type { ADF } from '@atlassian/jira-rich-content/src/model/adf.tsx';
import { getRedactionExtensionHandler } from '@atlassian/redaction-extension';
import { mediaFeatureFlags } from './media-feature-flags.tsx';
import type { EventHandlers, InlineCardClickHandler, Props } from './types.tsx';

// eslint-disable-next-line jira/react/no-class-components
export default class Renderer extends PureComponent<Props> {
	static displayName = 'Renderer';

	static defaultProps = {
		adf: undefined,
		placeholder: undefined,
	};

	constructor(props: Props) {
		super(props);
		const {
			contextIdentifier,
			mediaProvider,
			mentionProvider,
			profileCardProvider,
			taskDecisionProvider,
		} = this.props;

		this.node = null;

		this.dataProviders = new ProviderFactory();
		this.dataProviders.setProvider('mediaProvider', mediaProvider);
		this.dataProviders.setProvider('mentionProvider', Promise.resolve(mentionProvider));

		if (!__SERVER__) {
			this.dataProviders.setProvider(
				'emojiProvider',
				getEmojiProviderWithCustomEmoji(
					this.props.cloudId,
					this.props.loggedInUserAccountId,
					!fg('perf-push-big-components-delay-emoji'),
				),
			);
		}

		if (contextIdentifier) {
			this.dataProviders.setProvider(
				'contextIdentifierProvider',
				Promise.resolve(contextIdentifier),
			);
		}

		this.dataProviders.setProvider('profilecardProvider', profileCardProvider);

		this.dataProviders.setProvider('taskDecisionProvider', Promise.resolve(taskDecisionProvider));

		this.eventHandlers = {
			smartCard: {
				onClick: this.onInlineCardClicked,
			},
			link: {
				onClick: this.onInlineCardClicked,
			},
		};
		this.extensionHandlers = {
			...(fg('enable_redaction_support_in_jira_issue_view') && getRedactionExtensionHandler()),
		};
		if (expVal('platform_editor_support_table_in_comment_jira', 'isEnabled', false)) {
			setupEditorExperiments('jira');
		}
	}

	onInlineCardClicked: InlineCardClickHandler = (e, url) => {
		const { linkActions } = this.props;

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const event = e || window.event;
		event.preventDefault();
		if (isExternalLink(url)) {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.open(url, '_blank', 'noopener,noreferrer');
			return;
		}
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
		const mouseEvent: MouseEvent<any> = event as any;
		const shouldOpenInNewTab = isCmdOrCtrlClick(mouseEvent) || isMiddleClick(mouseEvent);
		iframeRedirect(url, shouldOpenInNewTab, linkActions.push);
	};

	onContentSelected = (e: SyntheticEvent<HTMLElement>) => {
		if (
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			!(e.target instanceof window.Element) ||
			checkForHref(e.target, e.currentTarget) ||
			this.checkForMedia(e.target, e.currentTarget) ||
			this.checkForTaskItem(e.target, e.currentTarget)
		) {
			// This is to avoid entering edit mode when clicking on a link, task item, or video.
			e.stopPropagation();
		}
	};

	checkForMedia = (currentElement: Element | null, topElement: Element): boolean => {
		// If we reach the original div, we didn't find a video.
		if (!currentElement || currentElement === topElement) {
			return false;
		}
		// TODO: These checks are unsafe. Use the method outlined in
		// https://jdog.jira-dev.com/browse/BENTO-6367
		if (
			currentElement.className.includes?.('rich-media-item') ||
			currentElement.className.includes?.('media-file-card-view') ||
			currentElement.className.includes?.('media-viewer-popup')
		) {
			return true;
		}
		// Continue walking up the DOM tree looking for a video.
		return this.checkForMedia(currentElement.parentElement, topElement);
	};

	// Can be refactored as an event handler
	// See platform/packages/editor/renderer/src/ui/Renderer/index.tsx
	// and `EventHandlers` from platform/packages/editor/editor-common/src/ui/EventHandlers/index.tsz
	checkForTaskItem = (
		currentElement: Element | null,
		topElement: Element,
		checkboxElement?: Element,
	): boolean => {
		if (!expVal('issue_view_action_items', 'isActionItemsEnabled', false)) {
			return false;
		}

		let foundCheckboxElement = checkboxElement;

		// Only consider the checkbox click as the valid event
		// i.e., clicking task item content should still open the edit-view
		if (currentElement?.getAttribute('type') === 'checkbox') {
			foundCheckboxElement = currentElement;
		}

		// If we reach the original element, we didn't find a task item.
		if (!currentElement || currentElement === topElement) {
			return false;
		}

		// Ensure it's an Atlaskit task item by checking `data-task-local-id`
		// See `platform/packages/elements/task-decision/src/components/TaskList.tsx`
		if (currentElement.getAttribute('data-task-local-id') != null && foundCheckboxElement != null) {
			return true;
		}

		// Continue walking up the DOM tree looking for a task item.
		return this.checkForTaskItem(currentElement.parentElement, topElement, foundCheckboxElement);
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	dataProviders: any;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	node: any;

	eventHandlers: EventHandlers;

	extensionHandlers: ExtensionHandlers;

	isEmpty = (): boolean => this.isAdfValueEmpty();

	isAdfValueEmpty = (): boolean => {
		const { adf } = this.props;
		// We should change this to an empty ADF object after this ticket is done: https://product-fabric.atlassian.net/browse/CS-235
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return !adf || isOnlyWhitespaceAdf(adf as ADF);
	};

	setNodeRef = (element: HTMLElement | null) => {
		this.node = element;
	};

	render() {
		const { adf, disableActions, placeholder } = this.props;

		if (this.isEmpty()) {
			return <Text color="color.text.subtlest">{placeholder}</Text>;
		}

		return (
			<div
				role="presentation"
				onClick={this.onContentSelected}
				onKeyPress={this.onContentSelected}
				ref={this.setNodeRef}
			>
				<AkRenderer
					appearance="comment"
					schema={defaultSchema}
					document={removeCollectionFromAdf(adf)}
					dataProviders={this.dataProviders}
					eventHandlers={this.eventHandlers}
					extensionHandlers={this.extensionHandlers}
					allowAltTextOnImages
					allowWrapCodeBlock
					allowCopyToClipboard
					media={{
						allowLinking: true,
						featureFlags: mediaFeatureFlags(),
					}}
					portal={this.node}
					disableHeadingIDs
					disableActions={disableActions}
					analyticsEventSeverityTracking={{
						enabled: true,
						severityDegradedThreshold: SEVERITY_DEGRADED_THRESHOLD,
						severityNormalThreshold: SEVERITY_NORMAL_THRESHOLD,
					}}
					unsupportedContentLevelsTracking={getUnsupportedContentLevelsTracking()}
					useSpecBasedValidator
					featureFlags={{
						codeBidiWarnings: true,
						'code-bidi-warnings': true,
						// enables sending analytics event with renderer specific tti measurement
						// please do not clean up, this feature flag is meant to be rolled out permanently just for a fraction of users
						'renderer-tti-tracking': fg('renderer_tti_tracking_jira'),
						'allow-windowed-code-block': true,
						// eslint-disable-next-line jira/ff/inline-usage
						isActionItemsEnabled: expVal('issue_view_action_items', 'isActionItemsEnabled', false),
					}}
					smartLinks={{
						showAuthTooltip: true,
						...smartLinksDefault,
					}}
					UNSTABLE_allowTableResizing={expVal(
						'platform_editor_support_table_in_comment_jira',
						'isEnabled',
						false,
					)}
					UNSTABLE_allowTableAlignment={expVal(
						'platform_editor_support_table_in_comment_jira',
						'isEnabled',
						false,
					)}
				/>
			</div>
		);
	}
}
