import { issueGiraDataResource } from '@atlassian/jira-router-resources-issue-view/src/services/issue-gira-data/index.tsx';
import { useResource } from '@atlassian/react-resource-router';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { ResourceManager } from './prefetched-resource-manager';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { useLazyLoadingResourceManager } from './lazy-load-resource-manager';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { setCacheEntry, hasCacheEntry, clearCache } from './lazy-load-resource-manager/utils';

export const useWaitForPrefetchData = () => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const resources: any[] = [useResource(issueGiraDataResource)];
	const loading = resources.some(({ loading: v }) => v);
	const error = resources.map(({ error: v }) => v).find(Boolean) || null;
	const promise = Promise.all(resources.map(({ promise: v }) => v)).then(() => undefined);
	return { loading, error, promise };
};
