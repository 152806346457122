import type { DocNode as ADF } from '@atlaskit/adf-schema';
import { defaultSchema } from '@atlaskit/adf-schema/schema-default';
import { renderDocument } from '@atlaskit/renderer/render-document';
import { TextSerializer } from '@atlaskit/renderer/text-serializer';
import {
	fireOperationalAnalytics,
	type UIAnalyticsEvent,
} from '@atlassian/jira-product-analytics-bridge';

const serializer = TextSerializer.fromSchema(defaultSchema);

// String serializer, reference to https://bitbucket.org/atlassian/pf-adf-service/src/master/src/lib/converters/adf-to-text.ts
// TODO: for productionisation, potentially move this to jira/src/packages/rich-content/src/common/adf-parsing-utils.tsx
const adfToText = (doc: ADF) => {
	if (doc.content.length < 1) {
		// renderer has a bug where empty adf returns null instead of empty string
		return '';
	}
	const outputText = renderDocument<string>(doc, serializer, defaultSchema).result;
	if (outputText === null) {
		throw new Error('Unable to convert adf to text');
	}

	return outputText;
};

export const isCannedCommentInContent = (
	doc: ADF | undefined,
	cannedComments: string[],
	analyticsEvent: UIAnalyticsEvent,
): { isExactMatch: boolean | undefined; hasCannedComment: boolean | undefined } => {
	try {
		if (!doc || cannedComments.length === 0) {
			return {
				isExactMatch: false,
				hasCannedComment: false,
			};
		}

		const contentText = adfToText(doc);
		const isExactMatch = cannedComments.some((canned) => canned.trim() === contentText.trim());
		if (isExactMatch) {
			return {
				isExactMatch,
				hasCannedComment: true,
			};
		}

		return {
			isExactMatch: false,
			hasCannedComment: cannedComments.some((canned) => contentText.trim().includes(canned.trim())),
		};
	} catch (e) {
		// In case the function to check throw an error, we should not block the user from using the feature
		fireOperationalAnalytics(analyticsEvent, 'isCannedCommentInContent failed');
		return {
			isExactMatch: undefined,
			hasCannedComment: undefined,
		};
	}
};
