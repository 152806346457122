import React from 'react';
import type {
	LazyCustomFieldInlineEditRendererProps,
	LazyCustomFieldInlineEditFormProps,
	LazyCustomFieldInlineEditIssueViewProps,
} from '@atlassian/jira-forge-ui-types/src/common/types/custom-field.tsx';
import { LazyCustomFieldInlineEditForm } from './experiences/form/index.tsx';
import { LazyCustomFieldInlineEditIssueView } from './experiences/issue-view/index.tsx';

const isIssueViewProps = (
	props: LazyCustomFieldInlineEditRendererProps,
): props is LazyCustomFieldInlineEditIssueViewProps =>
	props.extensionData.renderContext === 'issue-view';
const isIssueCreateOrTransitionProps = (
	props: LazyCustomFieldInlineEditRendererProps,
): props is LazyCustomFieldInlineEditFormProps =>
	props.extensionData.renderContext === 'issue-create' ||
	props.extensionData.renderContext === 'issue-transition';

export const LazyCustomFieldInlineEdit = (props: LazyCustomFieldInlineEditRendererProps) => {
	if (isIssueViewProps(props)) {
		return <LazyCustomFieldInlineEditIssueView {...props} />;
	}

	if (isIssueCreateOrTransitionProps(props)) {
		return <LazyCustomFieldInlineEditForm {...props} />;
	}
	return null;
};

export const AsyncCustomFieldInlineEdit = LazyCustomFieldInlineEdit;
