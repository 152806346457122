/**
 * @generated SignedSource<<629f585957bcdb3ded48783782d85a25>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraConfigStateConfigurationStatus = "CONFIGURED" | "NOT_CONFIGURED" | "NOT_INSTALLED" | "NOT_SET" | "PARTIALLY_CONFIGURED" | "PROVIDER_ACTION_CONFIGURED" | "PROVIDER_ACTION_NOT_CONFIGURED" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type heading_issueDesignSection_DesignsHeading$data = {
  readonly designProviders: ReadonlyArray<{
    readonly configState: {
      readonly config: {
        readonly nodes: ReadonlyArray<{
          readonly status: JiraConfigStateConfigurationStatus | null | undefined;
          readonly workspaceId: string;
        }> | null | undefined;
      } | null | undefined;
    } | null | undefined;
    readonly id: string;
    readonly supportedActions: {
      readonly checkAuth: boolean | null | undefined;
      readonly getEntityByUrl: boolean | null | undefined;
    } | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "heading_issueDesignSection_DesignsHeading";
};
export type heading_issueDesignSection_DesignsHeading$key = {
  readonly " $data"?: heading_issueDesignSection_DesignsHeading$data;
  readonly " $fragmentSpreads": FragmentRefs<"heading_issueDesignSection_DesignsHeading">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "cloudId"
    }
  ],
  "kind": "Fragment",
  "name": "heading_issueDesignSection_DesignsHeading",
  "selections": [
    {
      "concreteType": "DevOpsDesignProvider",
      "kind": "LinkedField",
      "name": "designProviders",
      "plural": true,
      "selections": [
        {
          "kind": "RequiredField",
          "field": {
            "kind": "ScalarField",
            "name": "id"
          },
          "action": "THROW",
          "path": "designProviders.id"
        },
        {
          "args": [
            {
              "kind": "Variable",
              "name": "cloudId",
              "variableName": "cloudId"
            }
          ],
          "concreteType": "JiraAppConfigState",
          "kind": "LinkedField",
          "name": "configState",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraConfigStateConnection",
              "kind": "LinkedField",
              "name": "config",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraConfigState",
                  "kind": "LinkedField",
                  "name": "nodes",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "status"
                    },
                    {
                      "kind": "ScalarField",
                      "name": "workspaceId"
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          "concreteType": "DevOpsSupportedActions",
          "kind": "LinkedField",
          "name": "supportedActions",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "getEntityByUrl"
            },
            {
              "kind": "ScalarField",
              "name": "checkAuth"
            }
          ]
        }
      ]
    }
  ],
  "type": "DevOpsProviders"
};

(node as any).hash = "89d52708d1c0fa89689fdc467795bff8";

export default node;
