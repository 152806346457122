import { type Environment, fetchQuery, graphql } from 'react-relay';
import type { utilsUseSingleSelectMutationMissingIssueAndFieldOptionDataQuery } from '@atlassian/jira-relay/src/__generated__/utilsUseSingleSelectMutationMissingIssueAndFieldOptionDataQuery.graphql';
import type { SingleSelectFieldValue } from '../use-select-field/types.tsx';

interface FetchOptionAriVars {
	cloudId: string;
	issueKey: string;
	fieldId: string;
}

interface FetchMissingIssueAndFieldOptionDataReturn {
	issueId: string;
	fieldOptionAri: string | null;
}

export async function fetchMissingIssueAndFieldOptionData<T extends SingleSelectFieldValue>(
	issueId: string | undefined,
	option: T | null,
	environment: Environment,
	vars: FetchOptionAriVars,
): Promise<FetchMissingIssueAndFieldOptionDataReturn> {
	const skipFieldOptionAri = option === null || Boolean(option.ari);
	// we have all the data required to make the mutation. if the field option
	// value is null that means we are unsetting so an ari is not required
	if (issueId && skipFieldOptionAri) {
		return {
			issueId,
			fieldOptionAri: option?.ari ?? null,
		};
	}

	const result = await fetchQuery<utilsUseSingleSelectMutationMissingIssueAndFieldOptionDataQuery>(
		environment,
		graphql`
			query utilsUseSingleSelectMutationMissingIssueAndFieldOptionDataQuery(
				$cloudId: ID!
				$issueKey: String!
				$fieldId: ID!
				$searchBy: String
				$skipFieldOptionAri: Boolean!
			) {
				jira {
					issueByKey(cloudId: $cloudId, key: $issueKey) {
						issueId
						fieldsById(ids: [$fieldId]) @skip(if: $skipFieldOptionAri) {
							edges {
								node {
									... on JiraSingleSelectField {
										fieldOptions(searchBy: $searchBy) {
											edges {
												node {
													id
													optionId
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		`,
		{
			...vars,
			// @todo: how do we scale when the value is empty for fields with large
			//        amount of options
			// @see https://jplat.jira.atlassian.cloud/browse/JIV-16573
			searchBy: option?.value,
			skipFieldOptionAri,
		},
		{
			fetchPolicy: 'store-or-network',
		},
	).toPromise();

	const jiraIssue = result?.jira?.issueByKey;

	if (!jiraIssue?.issueId) {
		throw new Error('failed fetch issue id');
	}

	let ari: string | null | undefined = option != null ? option?.ari : null;
	if (!skipFieldOptionAri) {
		ari = option
			? jiraIssue?.fieldsById?.edges?.[0]?.node?.fieldOptions?.edges?.find(
					(opt) => opt?.node?.optionId === option.id,
				)?.node?.id
			: null;
	}

	// ari being null is still valid request as the user will be unsetting it
	// so here we check for undefined only meaning that the query above returned
	// an empty options connection, or we could not match the `optionId` from the
	// nodes in the connection.
	if (ari === undefined) {
		throw new Error('failed to fetch field option ari');
	}

	return {
		issueId: jiraIssue.issueId,
		fieldOptionAri: ari,
	};
}
