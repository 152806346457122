// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	validUrl: {
		id: 'issue.views.field.single-line-text.validUrl',
		defaultMessage: 'Please enter a valid URL.',
		description: 'Invalid URL when user enters javascript:// forbidden urls.',
	},
});
