import { useCallback } from 'react';
import { graphql, useMutation, type UseMutationConfig } from 'react-relay';
import type { useWhiteboardIssueLinkingIssueToWhiteboardCreateMutation } from '@atlassian/jira-relay/src/__generated__/useWhiteboardIssueLinkingIssueToWhiteboardCreateMutation.graphql';
import type { useWhiteboardIssueLinkingIssueToWhiteboardDeleteMutation } from '@atlassian/jira-relay/src/__generated__/useWhiteboardIssueLinkingIssueToWhiteboardDeleteMutation.graphql';

export type UseWhiteboardIssueLinkingOptions = {
	readonly issueAri: string;
};

export type UseWhiteboardIssueLinkingResult = {
	readonly linkIssueToWhiteboard: (whiteboardId: string) => Promise<void>;
	readonly unlinkIssueFromWhiteboard: (
		whiteboardId: string,
		updater: UseMutationConfig<useWhiteboardIssueLinkingIssueToWhiteboardDeleteMutation>['updater'],
	) => Promise<void>;
};

export const useWhiteboardIssueLinking = ({
	issueAri,
}: UseWhiteboardIssueLinkingOptions): UseWhiteboardIssueLinkingResult => {
	const [commitLinkIssueToWhiteboard] =
		useMutation<useWhiteboardIssueLinkingIssueToWhiteboardCreateMutation>(graphql`
			mutation useWhiteboardIssueLinkingIssueToWhiteboardCreateMutation(
				$issueAri: ID!
				$whiteboardAri: ID!
			) {
				graphStore @optIn(to: "GraphStoreMutation") {
					createIssueToWhiteboard(
						input: { relationships: [{ from: $issueAri, to: $whiteboardAri }] }
					) @optIn(to: "GraphStoreIssueToWhiteboard") {
						success
						errors {
							message
						}
					}
				}
			}
		`);

	const [commitUnlinkIssueFromWhiteboard] =
		useMutation<useWhiteboardIssueLinkingIssueToWhiteboardDeleteMutation>(graphql`
			mutation useWhiteboardIssueLinkingIssueToWhiteboardDeleteMutation(
				$input: GraphStoreDeleteIssueToWhiteboardInput
			) {
				graphStore @optIn(to: "GraphStoreMutation") {
					deleteIssueToWhiteboard(input: $input) @optIn(to: "GraphStoreIssueToWhiteboard") {
						success
						errors {
							message
						}
					}
				}
			}
		`);

	const linkIssueToWhiteboard = useCallback<
		UseWhiteboardIssueLinkingResult['linkIssueToWhiteboard']
	>(
		(whiteboardAri) =>
			new Promise((resolve, reject) => {
				commitLinkIssueToWhiteboard({
					variables: { issueAri, whiteboardAri },
					onCompleted: (response) => {
						if (response.graphStore?.createIssueToWhiteboard?.success) {
							resolve();
						} else {
							const errors = response.graphStore?.createIssueToWhiteboard?.errors;
							const errorMessage = errors?.[0]?.message ?? 'Unknown AGS linking error';
							reject(new Error(errorMessage));
						}
					},
					onError: (error) => {
						reject(error);
					},
				});
			}),
		[commitLinkIssueToWhiteboard, issueAri],
	);

	const unlinkIssueFromWhiteboard = useCallback<
		UseWhiteboardIssueLinkingResult['unlinkIssueFromWhiteboard']
	>(
		(whiteboardAri, updater) =>
			new Promise((resolve, reject) => {
				commitUnlinkIssueFromWhiteboard({
					variables: {
						input: {
							relationships: [
								{
									from: issueAri,
									to: whiteboardAri,
								},
							],
						},
					},
					updater,
					onCompleted: (response) => {
						if (response?.graphStore?.deleteIssueToWhiteboard?.success) {
							resolve();
						} else {
							const errors = response?.graphStore?.deleteIssueToWhiteboard?.errors ?? [];

							const errorMessages = errors.map((error) => error.message);
							if (errorMessages.length > 0) {
								reject(new Error(errorMessages.join(', ')));
							} else {
								reject(new Error('Unknown AGS unlinking error'));
							}
						}
					},
					onError: (error) => {
						reject(error);
					},
				});
			}),
		[commitUnlinkIssueFromWhiteboard, issueAri],
	);

	return {
		linkIssueToWhiteboard,
		unlinkIssueFromWhiteboard,
	};
};
