import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	errorTitle: {
		id: 'issue-field-parent.error-title',
		defaultMessage: "We couldn't set the parent value",
		description: 'Error message informing the user that updating the parent field failed',
	},
	errorMessage: {
		id: 'issue-field-parent.error-message',
		defaultMessage: 'Check your connection, then give it another try.',
		description: 'Error message when save fails',
	},
	noValueText: {
		id: 'issue-field-parent.no-value-text',
		defaultMessage: 'None',
		description: 'Message when a field has no value',
	},
	editButtonLabel: {
		id: 'issue-field-parent.edit-button-label',
		defaultMessage: 'Edit {fieldName}',
		description:
			'Text to be used as aria-label of inline-edit button. Aria-label provides more context for users who use assistive technologies.',
	},
});
