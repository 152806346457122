import React from 'react';
import type { CustomGlyphProps } from '@atlaskit/icon';

export const insightsGlyph = (props: CustomGlyphProps) => (
	<svg
		{...props}
		width="16"
		height="11"
		viewBox="0 0 16 11"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M16 2.58776C16 3.58763 15.1894 4.39818 14.1896 4.39818C14.1246 4.39818 14.0605 4.39476 13.9973 4.38809L11.4607 6.92467L11.461 6.95571C11.461 7.95558 10.6504 8.76613 9.65057 8.76613C8.6507 8.76613 7.84015 7.95558 7.84015 6.95571C7.84015 6.91418 7.84155 6.87297 7.8443 6.83213L7.05226 6.04009C6.89521 6.08454 6.72949 6.10832 6.55822 6.10832C6.40649 6.10832 6.25913 6.08966 6.11829 6.0545L3.6077 8.56509C3.61637 8.63694 3.62083 8.71008 3.62083 8.78426C3.62083 9.78413 2.81028 10.5947 1.81042 10.5947C0.810551 10.5947 0 9.78413 0 8.78426C0 7.7844 0.810551 6.97385 1.81042 6.97385C1.9525 6.97385 2.09076 6.99021 2.22342 7.02117L4.75756 4.48703C4.75111 4.42487 4.7478 4.36177 4.7478 4.2979C4.7478 3.29804 5.55835 2.48749 6.55822 2.48749C7.55808 2.48749 8.36864 3.29804 8.36864 4.2979C8.36864 4.34027 8.36718 4.3823 8.36432 4.42395L9.15447 5.21411C9.31213 5.16929 9.47854 5.14529 9.65057 5.14529C9.84965 5.14529 10.0412 5.17743 10.2204 5.23679L12.4323 3.0249C12.3976 2.88491 12.3792 2.73849 12.3792 2.58776C12.3792 1.58789 13.1897 0.777344 14.1896 0.777344C15.1894 0.777344 16 1.58789 16 2.58776Z"
			fill="currentColor"
		/>
	</svg>
);
