/**
 * @generated SignedSource<<a915f86f984bf0d357677c111ce3d858>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type priority_issueFieldPriorityInlineEditFull_PriorityInlineEditViewIsEditable_fragmentRef$data = {
  readonly fieldId: string;
  readonly id: string;
  readonly name: string;
  readonly priority: {
    readonly iconUrl: AGG$URL | null | undefined;
    readonly id: string;
    readonly name: string | null | undefined;
  } | null | undefined;
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"priority_issueFieldPriorityReadviewFull_PriorityReadView">;
  readonly " $fragmentType": "priority_issueFieldPriorityInlineEditFull_PriorityInlineEditViewIsEditable_fragmentRef";
};
export type priority_issueFieldPriorityInlineEditFull_PriorityInlineEditViewIsEditable_fragmentRef$key = {
  readonly " $data"?: priority_issueFieldPriorityInlineEditFull_PriorityInlineEditViewIsEditable_fragmentRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"priority_issueFieldPriorityInlineEditFull_PriorityInlineEditViewIsEditable_fragmentRef">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "id"
},
v1 = {
  "kind": "ScalarField",
  "name": "name"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "priority_issueFieldPriorityInlineEditFull_PriorityInlineEditViewIsEditable_fragmentRef",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "priority_issueFieldPriorityReadviewFull_PriorityReadView"
    },
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    (v1/*: any*/),
    {
      "concreteType": "JiraPriority",
      "kind": "LinkedField",
      "name": "priority",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "kind": "ScalarField",
          "name": "iconUrl"
        }
      ]
    }
  ],
  "type": "JiraPriorityField"
};
})();

(node as any).hash = "d2dd68625b79ae7cbb16126a5aa64c95";

export default node;
