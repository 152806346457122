/**
 * @generated SignedSource<<c29b101cc868f9fea8432c1a43a162ce>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type DevOpsBuildState = "CANCELLED" | "FAILED" | "IN_PROGRESS" | "PENDING" | "SUCCESSFUL" | "UNKNOWN" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type buildData_softwareReleasesVersionDetailIssueList_devOpsSummarisedBuilds$data = {
  readonly buildStates: ReadonlyArray<{
    readonly count: number | null | undefined;
    readonly state: DevOpsBuildState | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "buildData_softwareReleasesVersionDetailIssueList_devOpsSummarisedBuilds";
};
export type buildData_softwareReleasesVersionDetailIssueList_devOpsSummarisedBuilds$key = {
  readonly " $data"?: buildData_softwareReleasesVersionDetailIssueList_devOpsSummarisedBuilds$data;
  readonly " $fragmentSpreads": FragmentRefs<"buildData_softwareReleasesVersionDetailIssueList_devOpsSummarisedBuilds">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "buildData_softwareReleasesVersionDetailIssueList_devOpsSummarisedBuilds",
  "selections": [
    {
      "concreteType": "DevOpsSummarisedBuildState",
      "kind": "LinkedField",
      "name": "buildStates",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "state"
        },
        {
          "kind": "ScalarField",
          "name": "count"
        }
      ]
    }
  ],
  "type": "DevOpsSummarisedBuilds"
};

(node as any).hash = "dd56ce5fd7d57ba00bf945a310710b23";

export default node;
