import React from 'react';
import type { CustomGlyphProps } from '@atlaskit/icon';

export const chevronRightGlyph = (props: CustomGlyphProps) => (
	<svg
		{...props}
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M10.4714 7.52861C10.7131 7.77036 10.7304 8.15159 10.5232 8.41327L10.4714 8.47142L7.13807 11.8047C6.87772 12.0651 6.45561 12.0651 6.19526 11.8047C5.95351 11.563 5.93624 11.1818 6.14346 10.9201L6.19526 10.8619L9.05717 7.99954L6.19526 5.13809C5.95351 4.89634 5.93624 4.5151 6.14346 4.25342L6.19526 4.19529C6.43702 3.95353 6.81824 3.93625 7.07992 4.14347L7.13807 4.19529L10.4714 7.52861Z"
			fill="currentColor"
		/>
	</svg>
);
