import React, { memo } from 'react';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import type { HandlerProps } from '../../common/types.tsx';
import Resizer from './main.tsx';
import { ResizeHandle } from './styled.tsx';

export default memo<HandlerProps>((props: HandlerProps) => (
	<ErrorBoundary
		id="async.issue.view.resizer"
		packageName="async.issue.view.resizer"
		render={() => <ResizeHandle />}
	>
		<Resizer {...props} />
	</ErrorBoundary>
));
