const ENCODE_MAP = {
	'\t': '\\t',
	'\n': '\\n',
	'\r': '\\r',
	'"': '\\"',
	"'": "\\'",
	'\\': '\\\\',
} as const;

export const encodeCharacter = (character: string, ignoredCharacter?: string) => {
	if (character === ignoredCharacter) {
		return character;
	}

	// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ readonly '\t': "\\t"; readonly '\n': "\\n"; readonly '\r': "\\r"; readonly '"': "\\\""; readonly "'": "\\'"; readonly '\\': "\\\\"; }'.
	return ENCODE_MAP[character] || character;
};

/**
 * Used to create a quoted string for a JQL query.
 * Must be used with a user-created parameters as they may contain bad characters
 */
export const toQuotedString = (parameter: string) => {
	let escaped = '';
	for (let position = 0; position < parameter.length; position += 1) {
		escaped += encodeCharacter(parameter[position], "'");
	}

	return `"${escaped}"`;
};

export const createIssuesUrl = (jql: string) => `/issues/?jql=${encodeURIComponent(jql)}`;
