import { JiraPriorityAri } from '@atlassian/ari/jira/priority';
import type {
	FieldOptionsFilter,
	OptionsVisibility,
	PublicToInternalPropertyTransformerContext,
} from '@atlassian/jira-issue-adjustments/src/common.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	priorityScopes,
	type PriorityPublicSetValue,
} from '@atlassian/jira-ui-modifications-common/src/common/utils/common/fields/priority.tsx';
import { commonIssueViewGranularScopes } from './fields-scopes.tsx';

type PriorityPublicGetValue = {
	id: string;
	name: string;
	iconUrl?: string;
} | null;

type PriorityInternalIdShapeValue = { id: string };

type PriorityInternalFullShapeValue = {
	id: string;
	name: string;
	iconUrl?: string;
} | null;

export const transformPrioritySetValue = (
	value: PriorityPublicSetValue,
): PriorityInternalIdShapeValue => ({ id: value });

export const transformPriorityGetValue = (
	priority: PriorityInternalFullShapeValue,
): PriorityPublicGetValue => {
	if (!priority) {
		return null;
	}

	const { id, name, iconUrl } = priority;
	return {
		id,
		name,
		iconUrl,
	};
};

export const priorityIssueViewScopes = {
	...priorityScopes,
	granular: [...priorityScopes.granular, ...commonIssueViewGranularScopes],
};

export const transformSetOptionsVisibilityAriPriority = (
	{ isVisible, options }: OptionsVisibility,
	_lookupValues: undefined,
	{ tenantContext }: PublicToInternalPropertyTransformerContext,
): FieldOptionsFilter => ({
	operation: isVisible ? 'ALLOW' : 'EXCLUDE',
	optionIds: fg('uim-use-new-options-visibility-transformers')
		? options.reduce<string[]>((acc, optionId) => {
				try {
					const optionAri = JiraPriorityAri.create({
						priorityId: optionId,
						siteId: tenantContext.cloudId,
						activationId: tenantContext.activationId,
					}).toString();

					acc.push(optionAri);
				} catch (e) {
					// do nothing
				}

				return acc;
			}, [])
		: options.map((optionId) =>
				JiraPriorityAri.create({
					priorityId: optionId,
					siteId: tenantContext.cloudId,
					activationId: tenantContext.activationId,
				}).toString(),
			),
});
