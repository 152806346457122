// This eslint error is suppressed because the fields are passed to transformer which uses them all
/* eslint-disable @atlassian/relay/unused-fields */
import 'rxjs/add/operator/map';
import { graphql, fetchQuery } from 'react-relay';
import { Observable } from 'rxjs/Observable';
import {
	NUM_INITIAL_ITEMS_TO_LOAD,
	NUM_PAGED_ITEMS_TO_LOAD,
} from '@atlassian/jira-issue-view-common-constants/src/activity-feed.tsx';
import getRelayEnvironment from '@atlassian/jira-relay-environment/src/index.tsx';
import type { CommentsPageInfo } from '@atlassian/jira-issue-shared-types/src/common/types/comment-transformer-types.tsx';
import type {
	commentFetchAggServerQuery,
	commentFetchAggServerQuery$variables,
} from '@atlassian/jira-relay/src/__generated__/commentFetchAggServerQuery.graphql';
import { transformAggData } from './gira/comments/index.tsx';

const getComments = (variables: commentFetchAggServerQuery$variables) => {
	return fetchQuery<commentFetchAggServerQuery>(
		getRelayEnvironment(),
		graphql`
			query commentFetchAggServerQuery(
				$issueAri: ID!
				$first: Int
				$after: String
				$last: Int
				$before: String
				$targetId: String
				$beforeTarget: Int
				$afterTarget: Int
			) {
				node(id: $issueAri) {
					... on JiraIssue {
						comments(
							first: $first
							after: $after
							last: $last
							before: $before
							targetId: $targetId
							beforeTarget: $beforeTarget
							afterTarget: $afterTarget
							sortBy: { field: CREATED, order: DESC }
						) {
							edges {
								cursor
								node {
									__typename
									commentId
									created
									updated
									author {
										name
										accountId
										picture
									}
									updateAuthor {
										name
										accountId
										picture
									}
									richText {
										adfValue {
											json
										}
									}
									permissionLevel {
										group {
											id
											name
											groupId
										}
										role {
											id
											name
										}
									}
									... on JiraServiceManagementComment {
										authorCanSeeRequest
										eventOccurredAt
										visibility
										jsdIncidentActivityViewHidden
									}
								}
							}
							pageInfo {
								hasNextPage
								hasPreviousPage
								startCursor
								endCursor
							}
						}
					}
				}
			}
		`,
		{
			...variables,
		},
	).toPromise();
};

export const fetchMoreCommentsFromAgg = (
	issueAri: string,
	isOlderButton: boolean,
	pageInfo?: CommentsPageInfo,
) => {
	const buildFetchParams = () => {
		if (pageInfo) {
			if (isOlderButton) {
				return {
					issueAri,
					first: NUM_PAGED_ITEMS_TO_LOAD,
					after: pageInfo.endCursor,
				};
			}

			return {
				issueAri,
				last: NUM_PAGED_ITEMS_TO_LOAD,
				before: pageInfo.startCursor,
			};
		}

		return { issueAri };
	};

	return Observable.fromPromise(
		getComments(buildFetchParams()).then((data) => transformAggData(data, pageInfo, isOlderButton)),
	);
};

export const fetchFocusedCommentFromAgg = (issueAri: string, targetId: string) => {
	const buildFetchParams = () => {
		return {
			issueAri,
			targetId,
			afterTarget: NUM_INITIAL_ITEMS_TO_LOAD,
			beforeTarget: NUM_INITIAL_ITEMS_TO_LOAD,
		};
	};

	return Observable.fromPromise(
		getComments(buildFetchParams()).then((data) => transformAggData(data)),
	);
};

export const fetchSortedCommentsFromAgg = (issueAri: string) => {
	const buildFetchParams = () => {
		return {
			issueAri,
			first: NUM_INITIAL_ITEMS_TO_LOAD,
		};
	};

	return Observable.fromPromise(
		getComments(buildFetchParams()).then((data) => transformAggData(data)),
	);
};
