import { performPutRequest } from '@atlassian/jira-fetch/src/index.tsx';
import type { IssueTypeId, ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { RestIssueType } from './types.tsx';

type IssueTypeUpdatePayload = {
	name: string;
	description: string;
	avatarId: number;
};

type IssueTypeUpdateResponse = Pick<
	RestIssueType,
	'id' | 'name' | 'description' | 'avatarId' | 'hierarchyLevel'
>;

export const performUpdateIssueType = (
	issueTypeId: IssueTypeId,
	projectId: ProjectId,
	update: IssueTypeUpdatePayload,
) => {
	return performPutRequest<IssueTypeUpdateResponse>(
		`/rest/polaris/v2/project/${projectId}/settings/issuetype/${issueTypeId}`,
		{
			body: JSON.stringify(update),
		},
	);
};
