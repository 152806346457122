import head from 'lodash/head';
import isEqual from 'lodash/isEqual';
import { ValueRuleOperator } from '@atlassian/jira-polaris-domain-field/src/decoration/constants.tsx';
import type {
	LocalDecorationId,
	ValueRule,
} from '@atlassian/jira-polaris-domain-field/src/decoration/types.tsx';
import { isDecorationWithLogic } from '@atlassian/jira-polaris-domain-field/src/decoration/utils.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../../types.tsx';
import type { UpdateValueDecorationProps } from './types.tsx';
import { getUpdatePayloadWithUpdatedRules, updateInternal } from './utils.tsx';

type UpdateDecorationRulesProps = {
	fieldKey: FieldKey;
	localDecorationId: LocalDecorationId;
	rules: ValueRule[];
};

export const updateRules =
	(updateProps: UpdateDecorationRulesProps) =>
	(
		{ getState, setState }: StoreActionApi<State>,
		{ fieldRemote, onDecorationUpdateFailed, createAnalyticsEvent, issueTypeIds }: Props,
	) => {
		const { fieldKey, rules, localDecorationId } = updateProps;

		const state = getState();
		const { fieldValueDecorations } = state;
		const existingValueDecorations = fieldValueDecorations[fieldKey] || [];
		const oldDecoration = existingValueDecorations.find(
			(decoration) => decoration.localDecorationId === localDecorationId,
		);
		const oldDecorationRules = isDecorationWithLogic(oldDecoration)
			? [] // TODO: Handle Decoration Logic
			: oldDecoration?.rules ?? [];

		if (!oldDecoration || isEqual(oldDecorationRules, rules)) {
			// noop - rules didn't change or decoration doesn't exist
			return;
		}

		const issueTypeId = head(issueTypeIds);

		const ltValue = rules.find(
			(rule) => rule.operator === ValueRuleOperator.LT || rule.operator === ValueRuleOperator.LTE,
		)?.value;
		const gteValue = rules.find(
			(rule) => rule.operator === ValueRuleOperator.GTE || rule.operator === ValueRuleOperator.GT,
		)?.value;

		updateInternal(
			getUpdatePayloadWithUpdatedRules(fieldKey, oldDecoration, gteValue, ltValue),
			fieldRemote,
			getState,
			setState,
			onDecorationUpdateFailed,
			issueTypeId,
		);

		fireCompoundAnalyticsEvent.FieldConfig.valueDecorationChanged(createAnalyticsEvent({}), {
			issueFieldKey: updateProps.fieldKey,
			issueFieldType: state.fields[fieldKey]?.type,
		});
	};

export const updateValueDecoration =
	(updateProps: UpdateValueDecorationProps) =>
	(
		{ getState, setState }: StoreActionApi<State>,
		{ fieldRemote, onDecorationUpdateFailed, createAnalyticsEvent, issueTypeIds }: Props,
	) => {
		const issueTypeId = head(issueTypeIds);
		updateInternal(
			updateProps,
			fieldRemote,
			getState,
			setState,
			onDecorationUpdateFailed,
			issueTypeId,
		);

		fireCompoundAnalyticsEvent.FieldConfig.valueDecorationChanged(createAnalyticsEvent({}), {
			issueFieldKey: updateProps.fieldKey,
			issueFieldType: getState().fields[updateProps.fieldKey]?.type,
		});
	};
