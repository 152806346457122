// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	includeChildIssueLabel: {
		id: 'issue.log-time-modal.include-child-issues-label',
		defaultMessage: 'Include {childIssuesPanelType}',
		description:
			'Label for control where the user toggles whether they want time tracking data to include information from child issues/subtasks or not.',
	},
	timetrackingRollupDisabledTooltipLabel: {
		id: 'issue.log-time-modal.time-tracking-disabled-tooltip.label',
		defaultMessage:
			'Including {childIssuesPanelType} is not available with more than 100 child issues',
		description:
			'An accessibility label for the disabled tooltip on roll up time tracking checkbox when over the child issue limit',
	},
	timetrackingRollupDisabledTooltipLabelWithLimit: {
		id: 'issue.log-time-modal.time-tracking-disabled-tooltip.label-with-limit',
		defaultMessage:
			'Including {childIssuesPanelType} is not available with more than {limit} child issues',
		description:
			'An accessibility label for the disabled tooltip on roll up time tracking checkbox when over the child issue limit',
	},
	timetrackingRollupDisabledTooltipLabelIssueTermRefresh: {
		id: 'issue.log-time-modal.time-tracking-disabled-tooltip.label-issue-term-refresh',
		defaultMessage:
			'Including {childIssuesPanelType} is not available with more than 100 child issues',
		description:
			'An accessibility label for the disabled tooltip on roll up time tracking checkbox when over the child issue limit',
	},
	timetrackingRollupDisabledTooltipLabelWithLimitIssueTermRefresh: {
		id: 'issue.log-time-modal.time-tracking-disabled-tooltip.label-with-limit-issue-term-refresh',
		defaultMessage:
			'Including {childIssuesPanelType} is not available with more than {limit} child issues',
		description:
			'An accessibility label for the disabled tooltip on roll up time tracking checkbox when over the child issue limit',
	},
});
