import { useCallback } from 'react';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import { useIssueId, useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useFieldsValuesActions } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import type { FieldOptions } from '@atlassian/jira-issue-view-common-types/src/connect-field-type.tsx';
import { useIssueViewFieldUpdateEvents } from '@atlassian/jira-issue-view-field-update-events/src/services/issue-view-field-update-events/index.tsx';
import { fieldSaveRequest } from '@atlassian/jira-issue-view-store/src/issue-field/state/actions/field-save-actions.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useDispatch } from '@atlassian/jira-react-redux/src/index.tsx';
import { useTasksUpdater } from '../use-tasks-updater/index.tsx';

export const useRichTextFieldTasksUpdater = (fieldKey: string, fieldOptions: FieldOptions<ADF>) => {
	const dispatch = useDispatch();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const issueId = useIssueId();
	const issueKey = useIssueKey();

	const [, { getFieldValue, setFieldValue }] = useFieldsValuesActions();
	const [, { fieldChanged }] = useIssueViewFieldUpdateEvents();

	const getAdfValue = useCallback(
		() => getFieldValue(issueKey, fieldKey),
		[fieldKey, issueKey, getFieldValue],
	);

	const changeAdfValue = useCallback(
		(updatedAdf: ADF) => {
			issueId && fieldChanged(issueId, fieldKey, updatedAdf);
			setFieldValue(issueKey, fieldKey, updatedAdf);
		},
		[issueId, issueKey, fieldChanged, fieldKey, setFieldValue],
	);

	const saveAdfValue = useCallback(() => {
		const adfValue = getAdfValue();
		dispatch(
			fieldSaveRequest(fieldKey, adfValue, fieldOptions, null, createAnalyticsEvent({}), null),
		);
	}, [createAnalyticsEvent, dispatch, fieldKey, fieldOptions, getAdfValue]);

	const toggleTask = useTasksUpdater({
		getAdfValue,
		onChange: changeAdfValue,
		onSave: saveAdfValue,
	});

	return toggleTask;
};
