import React, { useCallback } from 'react';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import Tooltip from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useIsGICPositioningEnabled } from '@atlassian/jira-issue-create/src/controllers/gic-positioning-enabled/main.tsx';
import type { ViewModeOptions } from '@atlassian/jira-issue-view-model/src/view-mode-options.tsx';
import { useTriggerIssueCreateModal } from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/main.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';

type Props = {
	// eslint-disable-next-line jira/react/handler-naming
	emitEventToLegacyApp: (event: string) => void;
	viewModeOptions: ViewModeOptions;
};

const SwitchToModal = ({ viewModeOptions, emitEventToLegacyApp }: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isGICPositioningEnabled = useIsGICPositioningEnabled();
	// @ts-expect-error - TS2525 - Initializer provides no value for this binding element and the binding element has no default value.
	const [{ isModalOpen }] = isGICPositioningEnabled()
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useTriggerIssueCreateModal()
		: [{}];

	const onClick = useCallback(() => {
		viewModeOptions.viewModeSwitchEnabled === true && viewModeOptions.onSwitchToModal
			? viewModeOptions.onSwitchToModal()
			: emitEventToLegacyApp('issue.details.view.switch-to-modal');

		const analyticsEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'dropdownItem',
		});
		fireUIAnalytics(analyticsEvent, 'switchToModal');
	}, [createAnalyticsEvent, emitEventToLegacyApp, viewModeOptions]);

	return (
		<>
			{isGICPositioningEnabled() ? (
				<Tooltip
					content={
						isModalOpen
							? formatMessage(
									fg('jira-issue-terminology-refresh-m3')
										? messages.disabledTooltipIssueTermRefresh
										: messages.disabledTooltip,
								)
							: false
					}
				>
					<DropdownItem key="switch-to-modal" onClick={onClick} isDisabled={isModalOpen}>
						{formatMessage(
							fg('jira-issue-terminology-refresh-m3')
								? messages.switchToModalIssueTermRefresh
								: messages.switchToModal,
						)}
					</DropdownItem>
				</Tooltip>
			) : (
				<DropdownItem key="switch-to-modal" onClick={onClick}>
					{formatMessage(
						fg('jira-issue-terminology-refresh-m3')
							? messages.switchToModalIssueTermRefresh
							: messages.switchToModal,
					)}
				</DropdownItem>
			)}
		</>
	);
};

SwitchToModal.defaultProps = {
	viewModeOptions: {
		viewModeSwitchEnabled: false,
	},
};

export default SwitchToModal;
