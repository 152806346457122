import gqlTagPolaris from 'graphql-tag';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type {
	SetSelectedDeliveryProjectMutation,
	SetSelectedDeliveryProjectVariables,
} from './types.tsx';

const SET_POLARIS_SELECTED_DELIVERY_PROJECT_MUTATION = gqlTagPolaris`
mutation jira_polaris_SetPolarisSelectedDeliveryProject($input: SetPolarisSelectedDeliveryProjectInput!) {
    setPolarisSelectedDeliveryProject(input: $input) {
        success
        errors {
            message
        }
    }
}
`;

// @ts-expect-error - TS7006 - Parameter 'result' implicitly has an 'any' type.
const handleResult = (result): boolean => {
	if (result.errors !== undefined) {
		throw new Error(
			`polaris-ideas.set-delivery-project-error:${result.errors
				// @ts-expect-error - TS7006 - Parameter 'e' implicitly has an 'any' type.
				.map((e) => e.message)
				.join(', ')}`,
		);
	}

	return true;
};

export const setDeliveryProject = (
	client: PolarisApolloClient,
	projectAri: string,
	deliveryProjectAri: string,
): Promise<boolean> => {
	const variables: SetSelectedDeliveryProjectVariables = {
		input: {
			projectId: projectAri,
			selectedDeliveryProjectId: deliveryProjectAri,
		},
	};
	return client
		.mutate<SetSelectedDeliveryProjectMutation>({
			mutation: SET_POLARIS_SELECTED_DELIVERY_PROJECT_MUTATION,
			variables,
		})
		.then(handleResult);
};
