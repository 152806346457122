import { useEffect, useState } from 'react';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import {
	DescriptionKey,
	SummaryKey,
} from '@atlassian/jira-providers-issue/src/model/issue-system-fields.tsx';

const MAX_ISSUE_TEXT_LENGTH = 10000;

export const useQueryText = (): string | undefined => {
	const issueKey = useIssueKey();
	const [queryText, setQueryText] = useState<string>();
	const [description] = useFieldValue({ issueKey, fieldKey: DescriptionKey });
	const [summaryValue] = useFieldValue({ issueKey, fieldKey: SummaryKey });

	useEffect(() => {
		const parseContent = async () => {
			setQueryText(`${summaryValue}`.substring(0, MAX_ISSUE_TEXT_LENGTH));
		};
		parseContent();
	}, [summaryValue, description]);

	return queryText;
};
