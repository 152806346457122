import { useCallback } from 'react';
import { PARENT_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import {
	fireOperationalAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { useProjectKey } from '@atlassian/jira-project-context-service/src/main.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import {
	useFieldsSuggestionsActions,
	useFieldPrefetched,
	useFieldRecentlySelected,
} from './main.tsx';
import type { CachedIssueParent, CachedIssueParentWithNumericalId } from './types.tsx';

const MAX_RECENTLY_SELECTED_PARENTS = 5;

export const useParentFieldSuggestionsCache = ({
	issueKey,
}: {
	issueKey: IssueKey;
}): {
	recentlySelectedParents: CachedIssueParent[];
	prefetchedParents: undefined | CachedIssueParent[];
	setPrefetchedParents: (prefetched: CachedIssueParent[]) => void;
	setRecentlySelectedParent: (
		selected: CachedIssueParent | CachedIssueParentWithNumericalId,
	) => void;
} => {
	const projectKey = useProjectKey(issueKey);

	const { setRecentlySelectedItem, setPrefetched } = useFieldsSuggestionsActions();
	const prefetchedParents = useFieldPrefetched({
		type: PARENT_TYPE,
		projectKey,
	});
	const recentlySelectedParents = useFieldRecentlySelected({
		type: PARENT_TYPE,
		projectKey,
	});
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const setPrefetchedParents = useCallback(
		(prefetched: CachedIssueParent[]) =>
			setPrefetched({
				fieldKey: PARENT_TYPE,
				projectKey,
				prefetched,
			}),
		[projectKey, setPrefetched],
	);

	const setRecentlySelectedParent = useCallback(
		(selected: CachedIssueParent | CachedIssueParentWithNumericalId) => {
			// The validation is necessary because there are a few scenarios where the id is actually a number, but the existing typing from external sources (incorrectly) specifies it as a string.
			// An example includes, submitting a parent from the picker API, in the view all modals.
			const selectedWithValidation =
				typeof selected.id === 'string' ? selected : { ...selected, id: `${selected.id}` };

			const selectedInPrefetchedParents = (prefetchedParents || []).find(
				(prefetchedParent) => prefetchedParent.id === selectedWithValidation.id,
			);

			if (!selectedInPrefetchedParents) {
				// We use this event for our monitoring how often it happens that an epic, which is out of the prefetched, is selected from modal.
				fireOperationalAnalytics(
					createAnalyticsEvent({}),
					'parentIssue couldntBeSetAsRecentlySelected',
				);
				return;
			}
			setRecentlySelectedItem({
				fieldKey: PARENT_TYPE,
				projectKey,
				maxLength: MAX_RECENTLY_SELECTED_PARENTS,
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				selected: selectedWithValidation as CachedIssueParent,
			});
		},
		[projectKey, setRecentlySelectedItem, createAnalyticsEvent, prefetchedParents],
	);

	return {
		recentlySelectedParents: recentlySelectedParents || [],
		prefetchedParents,
		setPrefetchedParents,
		setRecentlySelectedParent,
	};
};
