import React from 'react';
import sortBy from 'lodash/sortBy';
import takeWhile from 'lodash/takeWhile';
import Lozenge, { type ThemeAppearance } from '@atlaskit/lozenge';
import { useIntl } from '@atlassian/jira-intl';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';
import messages from './messages.tsx';

export type DevOpsSecurityVulnerabilityStatus =
	| 'CLOSED'
	| 'IGNORED'
	| 'OPEN'
	| 'UNKNOWN'
	// eslint-disable-next-line @atlassian/relay/no-future-added-value
	| '%future added value';

export type Props = {
	status: DevOpsSecurityVulnerabilityStatus | null;
	count?: number;
};

type GetLozengeConfigFn = (
	status: Props['status'],
	count: Props['count'],
) => {
	message: MessageDescriptor;
	appearance?: ThemeAppearance;
};

const getLozengeConfig: GetLozengeConfigFn = (status, count) => {
	switch (status) {
		case 'OPEN':
			return {
				message: count ? messages.openCount : messages.open,
				appearance: 'inprogress',
			};
		case 'IGNORED':
			return {
				message: count ? messages.ignoredCount : messages.ignored,
			};
		case 'CLOSED':
			return {
				message: count ? messages.closedCount : messages.closed,
				appearance: 'success',
			};
		default:
			return {
				message: count ? messages.unknownCount : messages.unknown,
			};
	}
};

export const BASE_STATUS_LOZENGE_TEST_ID =
	'software-security-common.common.ui.status-lozenge.lozenge';

export const StatusLozenge = ({ status, count }: Props) => {
	const { formatMessage } = useIntl();
	const { message, ...lozengeProps } = getLozengeConfig(status, count);
	return (
		<Lozenge {...lozengeProps} testId={`${BASE_STATUS_LOZENGE_TEST_ID}-${status}`}>
			{formatMessage(message, { count })}
		</Lozenge>
	);
};

const getStatusScore = (status: DevOpsSecurityVulnerabilityStatus | null) => {
	switch (status) {
		case 'OPEN':
			return 1;
		case 'IGNORED':
			return 2;
		case 'UNKNOWN':
			return 3;
		case 'CLOSED':
			return 4;
		default:
			return 5;
	}
};

export type PriorityStatusLozengeProps = {
	statuses: (DevOpsSecurityVulnerabilityStatus | null)[];
};

export const PriorityStatusLozenge = ({ statuses }: PriorityStatusLozengeProps) => {
	const sortedStatuses = sortBy(statuses, getStatusScore);
	const priorityStatuses = takeWhile(sortedStatuses, (status) => status === sortedStatuses[0]);
	const priorityStatus = priorityStatuses[0] ?? null;
	// Hide the count when every status is of the same priority as it becomes noise.
	const count = statuses.length === priorityStatuses.length ? undefined : priorityStatuses.length;
	return <StatusLozenge status={priorityStatus} count={count} />;
};
