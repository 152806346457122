import React from 'react';
import { styled } from '@compiled/react';
import truncate from 'lodash/truncate';
import { graphql, useMutation } from 'react-relay';
import Button from '@atlaskit/button';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import { errorFlags, useFlagsService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import {
	fireOperationalAnalytics,
	fireUIAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import type { absoluteCloseButtonJiraDevSummaryUserOptOutNotConnectedStateMutation } from '@atlassian/jira-relay/src/__generated__/absoluteCloseButtonJiraDevSummaryUserOptOutNotConnectedStateMutation.graphql';
import { isFailureStatusCode } from '@atlassian/jira-software-sla-tracker/src/services/response-to-sla/index.tsx';
import { getStatusCodeFromGraphQLErrors } from '@atlassian/jira-software-sla-tracker/src/services/status-code/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { onToggleDismissButton } from '../../../controllers/dev-summary/actions/index.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

export const AbsoluteCloseButton = ({ setPanelShownState }: Props) => {
	const { formatMessage } = useIntl();
	const cloudId = useCloudId();
	const { showFlag } = useFlagsService();

	const dismissLabel = formatMessage(messages.dismissState);

	const fireCompleteAnalytics = (
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		analyticsEvent: any,
		// @ts-expect-error - TS2304 - Cannot find name 'PayloadError'.
		errors: Array<PayloadError> | null | undefined,
	) => {
		const statusCode = errors ? getStatusCodeFromGraphQLErrors(errors) : 200;
		const isFailure = isFailureStatusCode(statusCode);

		// response contains graphql errors
		if (errors && errors.length > 0 && isFailure) {
			fireOperationalAnalytics(analyticsEvent, 'optOutOfDevEmptyState failed', {
				statusCode,
				errorMessage: truncate(errors[0].message, {
					length: 140,
				}),
			});
			showFlag(errorFlags.genericError());

			return;
		}
		fireOperationalAnalytics(analyticsEvent, 'optOutOfDevEmptyState success', {
			statusCode,
		});
	};

	const [commitMutation] =
		useMutation<absoluteCloseButtonJiraDevSummaryUserOptOutNotConnectedStateMutation>(graphql`
			mutation absoluteCloseButtonJiraDevSummaryUserOptOutNotConnectedStateMutation($cloudId: ID!) {
				jira {
					devOps {
						optoutOfDevOpsIssuePanelNotConnectedState(input: { cloudId: $cloudId }) {
							success
							errors {
								message
								extensions {
									__typename
									... on GenericMutationErrorExtension {
										statusCode
									}
								}
							}
						}
					}
				}
			}
		`);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onDismiss = (_: any, analyticsEvent: any) => {
		// optimistic update.
		fireUIAnalytics(analyticsEvent, 'optOutOfDevEmptyState');
		setPanelShownState(false);
		commitMutation({
			variables: { cloudId },
			onCompleted: (data, errors) => {
				setPanelShownState(
					data // @ts-expect-error(Suppressed for relay bump) - 'data.jira' is possibly 'null' or 'undefined'. | 'data.jira.devOps' is possibly 'null' or 'undefined'. | 'data.jira.devOps.optoutOfDevOpsIssuePanelNotConnectedState' is possibly 'null' or 'undefined'.
						? !data.jira.devOps.optoutOfDevOpsIssuePanelNotConnectedState.success
						: true,
				);
				fireCompleteAnalytics(analyticsEvent, errors);
			},
			onError: () => {
				setPanelShownState(true);
				showFlag(errorFlags.genericError());

				fireOperationalAnalytics(analyticsEvent, 'optOutOfDevEmptyState failed', {
					statusCode: 'unknown',
					errorMessage: 'networkError',
				});
			},
		});
	};

	return (
		<AbsoluteContainer>
			<Button
				iconBefore={<CrossIcon label={dismissLabel} size="small" />}
				appearance="subtle"
				onClick={onDismiss}
			/>
		</AbsoluteContainer>
	);
};

export default connect(() => ({}), {
	setPanelShownState: onToggleDismissButton,
})(AbsoluteCloseButton);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AbsoluteContainer = styled.div({
	position: 'absolute',
	top: '0px',
	right: '0px',
});
