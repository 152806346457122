import MarkdownIt from 'markdown-it';
import { markdownItTable } from 'markdown-it-table';

import { MarkdownTransformer } from '@atlaskit/editor-markdown-transformer';
import { MarkdownParser } from '@atlaskit/editor-prosemirror/markdown';
import { Node, type Schema } from '@atlaskit/editor-prosemirror/model';

import { type RegexStorage } from '../../linking-platform/utils';
import mdPluginCitation from '../md-plugin-citation';
import {
	createLinkifyPlugin,
	type FindFilepathsFn,
	type IsLinkInMatchesFn,
} from '../md-plugin-linkify';
import mdPluginMedia from '../md-plugin-media';
import mdPluginMentions from '../md-plugin-mention';
import { createSmartLinksPlugin } from '../md-plugin-smartlink';

import { mdToPmSchema } from './mappings';
import { enableSchemaPlugins, filterSchemaMapping } from './utils';

type PluginConfig = {
	linkify: {
		findFilepaths: FindFilepathsFn;
		isLinkInMatches: IsLinkInMatchesFn;
	};
	smartLinks: {
		regexStorage: RegexStorage;
	};
};

export class AiMateMarkdownTransformer extends MarkdownTransformer {
	public parser;

	constructor(schema: Schema, pluginConfig: PluginConfig, streaming?: boolean) {
		super();

		const md = MarkdownIt('zero', { html: false });

		md.enable([
			// Process html entity - &#123;, &#xAF;, &quot;, ...
			'entity',
			// Process escaped chars and hardbreaks
			'escape',
			'newline',
		]);

		enableSchemaPlugins(md, schema);

		// enable modified version of linkify plugin
		// @see https://product-fabric.atlassian.net/browse/ED-3097
		md.use(createLinkifyPlugin(pluginConfig.linkify));
		md.use(mdPluginCitation);

		// Convert links with Linking Platform supported URLs to Smart Links
		if (
			!streaming &&
			(schema.nodes.inlineCard || schema.nodes.blockCard || schema.nodes.embedCard)
		) {
			md.use(createSmartLinksPlugin(pluginConfig.smartLinks));
		}

		// Convert links matching people directory URLs to mentions
		if (schema.nodes.mention) {
			md.use(mdPluginMentions);
		}

		// Table support
		if (schema.nodes.table) {
			md.use(markdownItTable);
		}

		// Image support
		if (schema.nodes.media && schema.nodes.mediaSingle) {
			md.use(mdPluginMedia);
		}

		const filter = filterSchemaMapping(schema, mdToPmSchema);

		this.parser = new MarkdownParser(schema, md, filter);
	}

	parse(content: string): Node {
		const node = this.parser.parse(content);

		if (!node) {
			return new Node();
		}

		return node;
	}
}
