import flatten from 'lodash/flatten';
import isNumber from 'lodash/isNumber';
import { toForgeKeyFromExtensionId } from '@atlassian/jira-forge-ui-utils/src/utils/connect/index.tsx';
import { getEcosystemQuery } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-gira-data/gira-fragments/ecosystem/index.tsx';
import type {
	ViewIssueData as IssueServiceViewIssueData,
	Jira,
} from '@atlassian/jira-issue-fetch-services/src/types.tsx';
import type { EcosystemTransformResult } from '@atlassian/jira-issue-gira-transformer-types/src/common/types/ecosystem.tsx';

import type {
	ContentPanelConnection,
	ViewIssueData,
} from '@atlassian/jira-issue-gira-transformer-types/src/common/types/server-data.tsx';

import { removeTimestamp } from '@atlassian/jira-issue-view-common-utils/src/ecosystem/module-key-helper.tsx';
import { transformEcosystemExtensions } from '../../ecosystem-extensions-transformer.tsx';
import { legacyRightWebPanelQuery } from './graphql.tsx';

export const getLegacyRightWebPanelsQuery = () => legacyRightWebPanelQuery;

export const getQuery = () => getEcosystemQuery();

export const ISSUE_CONTENT_PANEL_CUSTOMISED = 'issue.content.panel.customised.flag';

type Ecosystem = NonNullable<(ViewIssueData | IssueServiceViewIssueData)['ecosystem']>;

const transformForgePanels = (
	ecosystem: Ecosystem,
): {
	forgeCollapsedPanels: string[];
	forgePanelsKeys: string[];
} => {
	const forgePanels = ecosystem.forge
		? flatten(
				(ecosystem.forge.panels || []).map((props) => {
					const { id, instances } = props;
					return (Array.isArray(instances) ? instances : [])
						.filter(({ added }) => isNumber(added))
						.map(({ added, id: panelInstanceId, collapsed }) => ({
							key: toForgeKeyFromExtensionId(id, panelInstanceId, added),
							collapsed: collapsed || false,
						}));
				}),
			)
		: [];

	return {
		forgeCollapsedPanels: forgePanels.filter(({ collapsed }) => collapsed).map(({ key }) => key),
		forgePanelsKeys: forgePanels.map(({ key }) => key),
	};
};

export const defaultEmptyResult = {
	ecosystem: {},
	issueContentPanels: undefined,
	forgeIssueProperties: {
		collapsedPanels: undefined,
	},
} as const;

export const transformData = (
	jira?: Jira | null,
	issue?: ViewIssueData | IssueServiceViewIssueData,
): EcosystemTransformResult => {
	if (!issue?.ecosystem) {
		return defaultEmptyResult;
	}

	const { ecosystem, legacyRightWebPanels } = issue;

	const contextPanels = ecosystem.contextPanels
		? ecosystem.contextPanels.map((contextPanel) => ({
				...contextPanel,
				status: contextPanel.status ? JSON.parse(contextPanel.status) : null,
			}))
		: [];

	const { forgeCollapsedPanels, forgePanelsKeys } = transformForgePanels(ecosystem);

	const contentPanelsCustomisedFlag = issue.hasCustomisedContentPanels
		? [ISSUE_CONTENT_PANEL_CUSTOMISED]
		: [];

	const safeContentPanels = {
		contentPanels: issue.contentPanels ?? { edges: [] },
		legacyContentPanels: issue.legacyContentPanels ?? { edges: [] },
	};

	// Convert the nullable ecosystem type received from '/gira' into a non-nullable type expected by the transformer
	const safeEcosystem = {
		...safeContentPanels,
		activityPanels: ecosystem.activityPanels ?? [],
		// ecoSystemOnDate can only ever be returned as `null` when the server errors out. In that scenario, we assign
		// the value of this field to 0. As a result, contentPanels won't be displayed by default for fields that
		// were created pre-Bento. It's not the best user experience, but at least they won't get an error.
		ecoSystemOnDate: jira?.ecosystemFirstSeenOnIssueView
			? new Date(jira.ecosystemFirstSeenOnIssueView).getTime()
			: 0,
		contentPanelsCustomised: issue.hasCustomisedContentPanels ?? false,
	};

	const manuallyAddedToIssuePanelKeys: string[] = [];
	const addManuallyAddedPanelKeyToList = (
		edge: NonNullable<ContentPanelConnection['edges']>[number],
		_index: number,
		_array: ContentPanelConnection['edges'],
	) => {
		if (edge?.node?.wasManuallyAddedToIssue) {
			manuallyAddedToIssuePanelKeys.push(
				`${edge.node.addonKey}_${removeTimestamp(edge.node.moduleKey || '')}`,
			);
		}
	};
	safeEcosystem.contentPanels.edges?.forEach(addManuallyAddedPanelKeyToList);
	safeEcosystem.legacyContentPanels.edges?.forEach(addManuallyAddedPanelKeyToList);

	return {
		ecosystem: transformEcosystemExtensions({
			...safeEcosystem,
			legacyRightWebPanels,
			contextPanels,
			canInstallAddons: false,
		}),
		issueContentPanels: [
			...forgePanelsKeys,
			...manuallyAddedToIssuePanelKeys,
			...contentPanelsCustomisedFlag,
		],
		forgeIssueProperties: {
			collapsedPanels: forgeCollapsedPanels,
		},
	};
};
