import {
	createActionsHook,
	createStateHook,
	createHook,
	createStore,
	type HookStateFunction,
	type StoreActionApi,
	createContainer,
} from '@atlassian/react-sweet-state';
import type { UIMConsentType, UIMConsentMap, AdjustmentsContextState } from './types.tsx';

const initialState: AdjustmentsContextState = {
	shouldUiModificationsLoad: 'unknown',
	hasFinishedLoading: false,
	adjustmentsAreEnabled: false,
	uiModificationsConsentStatus: {},
};

export const actions = {
	resetAdjustmentContext:
		() =>
		({ setState }: StoreActionApi<AdjustmentsContextState>) => {
			setState(initialState);
		},
	setShouldUiModificationsLoad:
		(status: AdjustmentsContextState['shouldUiModificationsLoad']) =>
		({ setState }: StoreActionApi<AdjustmentsContextState>) => {
			setState({
				shouldUiModificationsLoad: status,
			});
		},
	setHasFinishedLoading:
		(hasFinishedLoading: boolean) =>
		({ setState }: StoreActionApi<AdjustmentsContextState>) => {
			setState({
				hasFinishedLoading,
			});
		},
	setAdjustmentsAreEnabled:
		(enabled: boolean) =>
		({ setState }: StoreActionApi<AdjustmentsContextState>) => {
			setState({
				adjustmentsAreEnabled: enabled,
			});
		},
	setUIModificationsConsentStatus:
		({ appId, status }: { appId: string; status: UIMConsentType }) =>
		({ setState, getState }: StoreActionApi<AdjustmentsContextState>) => {
			const currentConsentState: UIMConsentMap = getState().uiModificationsConsentStatus;

			setState({
				uiModificationsConsentStatus: {
					...currentConsentState,
					[appId]: status,
				},
			});
		},
} as const;

export type Actions = typeof actions;

export const store = createStore<AdjustmentsContextState, Actions>({
	name: 'issue-adjustments-context',
	initialState,
	actions,
});

export const useAdjustmentsContext = createHook(store);

export const useAdjustmentsEnabled: HookStateFunction<boolean> = createStateHook(store, {
	selector: (state: AdjustmentsContextState) => state.adjustmentsAreEnabled,
});

export const useAdjustmentsContextActions = createActionsHook<AdjustmentsContextState, Actions>(
	store,
);

export const UiModificationContextContainer = createContainer(store, {
	displayName: 'UiModificationContextContainer',
});
