import { v4 as uuid } from 'uuid';

import { createContainer, createHook, createStore } from '@atlassian/eoc-rss-helpers';

import { type ContainerProps, type StoreState } from './types';

export const initialState: StoreState = {
	singleInstrumentationID: null,
	aiFeatureName: null,
};

export const actions = {};
const Store = createStore<StoreState, typeof actions>({
	initialState,
	actions,
});

export const useAIOpsUnfiedEventStore = createHook(Store);

export const AIOpsUnifiedEventsContainer = createContainer<
	StoreState,
	typeof actions,
	ContainerProps
>(Store, {
	onInit:
		() =>
		({ setState, getState }, { aiFeatureName, source }) => {
			const { aiFeatureName: aiFeatureNameInStore } = getState();

			if (!aiFeatureNameInStore) {
				// If the store has not been initialized only then
				// initialize it with a new AI Interaction ID
				// (for eg. Don't re-init same scoped containers)
				setState({
					singleInstrumentationID: uuid(),
					aiFeatureName,
					source,
				});

				return;
			}

			if (aiFeatureNameInStore !== aiFeatureName) {
				throw new Error('The same container instance should not change its aiFeatureName');
			}
		},
});
