import isUrl from '@atlassian/jira-common-util-is-url/src/index.tsx';

/**
 * Absolute URL regex
 */
const AbsoluteURLRegex = new RegExp(/^[a-z][a-z\d+.-]*:/);

/**
 * Prefix url if it is url, return what we got if not
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default (string?: any, prefix?: string): string | any => {
	// The same line is in isUrl, but flow requires it here too
	if (typeof string !== 'string' || !prefix) {
		return string;
	}
	// Exit early
	if (!isUrl(string)) {
		return string;
	}
	// Double slash urls are absolute too, relative to the schema used in current window
	if (string.substring(0, 2) === '//') {
		return string;
	}
	// If it's an absolute URL, return it, no need to prefix
	if (AbsoluteURLRegex.test(string)) {
		return string;
	}
	// If it's a relative URL, prefix it with prefix
	return prefix + string;
};
