import head from 'lodash/head';
import type { JiraFieldOptionWeight } from '@atlassian/jira-polaris-domain-field/src/field-option/types.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types.tsx';
import { sanitizeOptionWeights } from './utils.tsx';

export const updateFieldOptionWeight =
	(fieldKey: FieldKey, jiraOptionId: string, newWeight: number) =>
	async (
		{ getState, setState }: StoreActionApi<State>,
		{ onFieldUpdateFailed, projectId, createAnalyticsEvent, fieldRemote, issueTypeIds }: Props,
	) => {
		if (projectId === undefined) {
			return;
		}

		const state = getState();
		const field = state.fields[fieldKey];
		const issueTypeId = head(issueTypeIds) ?? '';
		const previousOptions = field.options;
		const optionIds = previousOptions?.map((option) => option.id) || [];

		const newOptions = previousOptions?.map((option) => {
			if (option.jiraOptionId === jiraOptionId) {
				return {
					...option,
					weight: newWeight,
				};
			}
			return option;
		});

		const jiraOptionIdNumber = parseInt(jiraOptionId, 10);
		// We need to get the real api configuration to be able to correctly apply the patch to the server
		const oldConfigurationOptions = field.configuration?.optionWeights ?? [];
		// {weight: 3, optionId: 10067}
		const hasOldConfigWeight = oldConfigurationOptions.find(
			(option: JiraFieldOptionWeight) => option.optionId === jiraOptionIdNumber,
		);
		let newConfigurationOptions: { optionId: number; weight: number }[];
		if (hasOldConfigWeight) {
			newConfigurationOptions = oldConfigurationOptions?.map((option) => {
				if (option.optionId === jiraOptionIdNumber) {
					return {
						...option,
						weight: newWeight,
					};
				}
				return option;
			});
		} else {
			newConfigurationOptions = [
				...oldConfigurationOptions,
				{
					weight: newWeight,
					optionId: parseInt(jiraOptionId, 10),
				},
			];
		}

		newConfigurationOptions = sanitizeOptionWeights(newConfigurationOptions, optionIds);

		const fields = {
			...state.fields,
			[fieldKey]: {
				...field,
				options: newOptions,
				configuration: {
					...state.fields[fieldKey].configuration,
					optionWeights: newConfigurationOptions,
				},
			},
		};

		setState({ fields });

		return fieldRemote
			.updateFieldConfiguration({
				fieldKey,
				issueTypeId,
				oldConfiguration:
					oldConfigurationOptions?.length > 0 ? { optionWeights: oldConfigurationOptions } : {},
				configuration:
					newConfigurationOptions.length > 0 ? { optionWeights: newConfigurationOptions } : {},
				type: 'updateFieldOptionWeight',
			})
			.then(() => {
				fireCompoundAnalyticsEvent.FieldConfig.optionWeightChanged(createAnalyticsEvent({}), {
					issueFieldKey: fieldKey,
					issueFieldType: fields[fieldKey].type,
					fieldValueId: jiraOptionId,
				});
			})
			.catch((err) => {
				const currentFields = { ...getState().fields };

				currentFields[fieldKey] = {
					...currentFields[fieldKey],
					options: previousOptions,
					configuration: {
						...currentFields[fieldKey].configuration,
						optionWeights:
							oldConfigurationOptions?.length > 0 ? oldConfigurationOptions : undefined,
					},
				};
				setState({ fields: currentFields });

				onFieldUpdateFailed(err);
			});
	};
