import React, { memo, useMemo, useState } from 'react';
import { styled } from '@compiled/react';
import AkBranchIcon from '@atlaskit/icon/core/branch';
import BitbucketBranchesIcon from '@atlaskit/icon/glyph/bitbucket/branches';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import AkChevronDown from '@atlaskit/icon/utility/chevron-down';
import { token } from '@atlaskit/tokens';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { ISSUE } from '@atlassian/jira-development-board-common/src/common/constants.tsx';
import { DevInfoTypes } from '@atlassian/jira-development-board-common/src/types.tsx';
import { AsyncDevInfoIcon } from '@atlassian/jira-development-board-dev-info-icon/src/async.tsx';
import DevInfoIcon from '@atlassian/jira-development-board-dev-info-icon/src/main.tsx';
import type { DropdownTriggerParams } from '@atlassian/jira-development-summary-common/src/common/types.tsx';
import { SummaryItem } from '@atlassian/jira-development-summary-common/src/ui/summary-item/index.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { FormattedMessage, FormattedNumber } from '@atlassian/jira-intl';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { CreateBranchCommandButton } from './create-branch-command-button/index.tsx';
import { CreateBranchDropdown } from './create-branch-dropdown/index.tsx';
import { CreateBranchIconButton } from './create-branch-icon-button/index.tsx';
import messages from './messages.tsx';
import type { CreateBranchTarget, Branch as BranchType } from './types.tsx';

// eslint-disable-next-line jira/styled/styled-component-order, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconWrapper = styled.span({
	verticalAlign: 'middle',
});

const BranchIcon = (
	<IconWrapper aria-hidden>
		<AkBranchIcon color={token('color.link')} label="" />
	</IconWrapper>
);

const BranchIconOld = (
	<IconWrapper aria-hidden>
		<BitbucketBranchesIcon size="medium" label="" />
	</IconWrapper>
);

type TitleProps = {
	count: number;
};
export const Title = memo<TitleProps>(({ count }: TitleProps) => (
	<FormattedMessage
		{...messages.title}
		values={{
			count,
			styledCount: (
				<BoldFont>
					<FormattedNumber value={count} />
				</BoldFont>
			),
		}}
	/>
));

type CreateBranchSummaryItemProps = {
	hasBranchCapabilities: boolean;
	shouldForceVisibleSecondary: boolean;
	branchTargets: CreateBranchTarget[];
	setShouldForceVisibleSecondary: (arg1: ((arg1: boolean) => boolean) | boolean) => void;
};

const CreateBranchSummaryItem = ({
	branchTargets,
	shouldForceVisibleSecondary,
	setShouldForceVisibleSecondary,
	hasBranchCapabilities,
}: CreateBranchSummaryItemProps) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const issueKey = useIssueKey();

	const downChevron = useMemo(
		() =>
			isVisualRefreshEnabled() ? (
				<AkChevronDown
					color={token('color.link')}
					label={formatMessage(messages.createBranchDropdownIconLabelLowercase)}
				/>
			) : (
				<ChevronDownIcon
					size="medium"
					label={formatMessage(messages.createBranchDropdownIconLabelLowercase)}
				/>
			),
		[formatMessage],
	);

	const urlArray = useMemo(() => branchTargets.map((target) => target.url), [branchTargets]);

	const hasSingleTarget = urlArray.length === 1;
	const hasMultipleTargets = urlArray.length > 1;

	if (!hasSingleTarget && !hasMultipleTargets && !hasBranchCapabilities) {
		return null;
	}
	const commonSummaryItemProps = {
		title: formatMessage(messages.createBranchTitleLowercase),
		issueKey,
		linkFormatting: true,
		icon: isVisualRefreshEnabled() ? BranchIcon : BranchIconOld,
	};

	return hasSingleTarget ? (
		<SummaryItem
			{...commonSummaryItemProps}
			href={urlArray[0]}
			onClick={() => {
				fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'createBranchButton', {
					singleScmProvider: true,
					redirectToScmProviderPage: true,
				});
			}}
			secondaryHover={
				hasSingleTarget && (
					<CreateBranchDropdown
						getDropdownTrigger={(props) => <CreateBranchCommandButton {...props} />}
						createBranchTargets={branchTargets}
						hideCreateBranchLinks
						onOpenDropdown={({ isOpen }) => {
							setShouldForceVisibleSecondary(isOpen);
						}}
					/>
				)
			}
			forceVisibleSecondary={shouldForceVisibleSecondary}
			data-testid="development-summary-branch.ui.summary-item"
		/>
	) : (
		<CreateBranchDropdown
			createBranchTargets={branchTargets}
			onOpenDropdown={({ isOpen }) => {
				setShouldForceVisibleSecondary(isOpen);
			}}
			getDropdownTrigger={({ ref, ...props }: DropdownTriggerParams) => (
				<SummaryItem
					secondaryData={downChevron}
					forceVisibleSecondary={shouldForceVisibleSecondary}
					reference={ref}
					{...commonSummaryItemProps}
					{...props}
					onClick={() => {
						props.onClick();
						fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'createBranchButton', {
							singleScmProvider: false,
							redirectToScmProviderPage: false,
						});
					}}
					data-testid="development-summary-branch.ui.summary-item"
				/>
			)}
		/>
	);
};

export const BranchEmptyState = () => {
	const { formatMessage } = useIntl();

	const icon = isVisualRefreshEnabled() ? BranchIcon : BranchIconOld;
	return (
		<SummaryItem
			icon={icon}
			disabled
			title={formatMessage(messages.emptyStateUpdate)}
			data-testid="development-summary-branch.ui.summary-item"
		/>
	);
};

type BranchProps = {
	hasBranchCapabilities: boolean;
	branch: BranchType | null | undefined;
	branchTargets: CreateBranchTarget[];
	onClick?: () => void;
	issueId: string;
	issueKey: string;
	shouldForceVisibleSecondary?: boolean;
};

const BranchOld = ({
	branch,
	onClick,
	hasBranchCapabilities,
	branchTargets,
	issueId,
	issueKey,
	shouldForceVisibleSecondary: initialShouldForceVisibleSecondary = false,
}: BranchProps) => {
	const { formatMessage } = useIntl();
	const [shouldForceVisibleSecondary, setShouldForceVisibleSecondary] = useState(
		initialShouldForceVisibleSecondary,
	);
	const branchTargetsWithUrls = useMemo(
		() => branchTargets.filter((target) => target.url),
		[branchTargets],
	);
	const { count = 0 } = branch ?? {};
	const title = useMemo(() => <Title count={count} />, [count]);
	const icon = isVisualRefreshEnabled() ? BranchIcon : BranchIconOld;

	const summaryItemComponent = (branchInput: BranchType) => (
		<SummaryItem
			title={title}
			href={null}
			onClick={onClick}
			itemType="branch"
			itemCount={branchInput.count}
			oneClickUrl={branchInput.url}
			oneClickToolTipContent={formatMessage(messages.tooltip)}
			secondaryHover={
				<CreateBranchDropdown
					getDropdownTrigger={(props) => <CreateBranchIconButton {...props} />}
					createBranchTargets={branchTargetsWithUrls}
					onOpenDropdown={({ isOpen }) => {
						setShouldForceVisibleSecondary(isOpen);
					}}
				/>
			}
			icon={icon}
			forceVisibleSecondary={shouldForceVisibleSecondary}
			data-testid="development-summary-branch.ui.summary-item"
		/>
	);

	if (branch && branch.count > 0) {
		if (ff('devops-popups-on-issue-view_zikxe')) {
			if (fg('convert_devinfoicon_to_be_synchronous')) {
				<DevInfoIcon
					devInfoType={DevInfoTypes.BRANCH}
					issueId={Number(issueId)}
					customTrigger={summaryItemComponent(branch)}
					scopeId={`devInfoPanelApp${issueKey}`}
					placement="bottom-end"
					offset={[-8, 8]}
					zIndex={layers.modal}
					location={ISSUE}
				/>;
			}
			return (
				<Placeholder fallback={summaryItemComponent(branch)} name="async-branch-dev-info-icon">
					<AsyncDevInfoIcon
						devInfoType={DevInfoTypes.BRANCH}
						issueId={Number(issueId)}
						customTrigger={summaryItemComponent(branch)}
						scopeId={`devInfoPanelApp${issueKey}`}
						placement="bottom-end"
						offset={[-8, 8]}
						zIndex={layers.modal}
						location={ISSUE}
					/>
				</Placeholder>
			);
		}
		return summaryItemComponent(branch);
	}

	return (
		<CreateBranchSummaryItem
			branchTargets={branchTargetsWithUrls}
			hasBranchCapabilities={hasBranchCapabilities}
			shouldForceVisibleSecondary={shouldForceVisibleSecondary}
			setShouldForceVisibleSecondary={setShouldForceVisibleSecondary}
		/>
	);
};

const BranchNew = (props: BranchProps) => (
	<UFOSegment name="development-summary-branch">
		<BranchOld {...props} />
	</UFOSegment>
);

const Branch = componentWithFG(
	'jira-add-ufo-instrument-for-asyncdevinfoicon',
	BranchNew,
	BranchOld,
);

export default Branch;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BoldFont = styled.span({
	fontWeight: token('font.weight.medium'),
});
