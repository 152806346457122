import { useCallback, useMemo } from 'react';
import { fetchQuery, graphql } from 'relay-runtime';
import { toProjectType } from '@atlassian/jira-common-constants/src/project-types.tsx';
import getRelayEnvironment from '@atlassian/jira-relay-environment/src/index.tsx';
import type { useWorkflowRelayQuery } from '@atlassian/jira-relay/src/__generated__/useWorkflowRelayQuery.graphql';
import {
	type IssueKey,
	toIssueTypeId,
	toProjectId,
} from '@atlassian/jira-shared-types/src/general.tsx';

import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import type { ViewWorkflowData } from '../use-view-workflow-data/types.tsx';

type Props = {
	issueKey: IssueKey;
};

export const useFetchWorkflowServiceData = ({ issueKey }: Props) => {
	const cloudId = useCloudId();

	const fetchWorkflowServiceData = useCallback(async (): Promise<ViewWorkflowData> => {
		const data = await fetchQuery<useWorkflowRelayQuery>(
			getRelayEnvironment(),
			graphql`
				query useWorkflowRelayQuery($cloudId: ID!, $issueKey: String!) {
					jira {
						issueByKey(key: $issueKey, cloudId: $cloudId) {
							canReadWorkflow: hasProjectPermission(permission: VIEW_READONLY_WORKFLOW)
								@optIn(to: "JiraHasProjectPermissionQuery")
							canEditWorkflow: hasProjectPermission(permission: EDIT_WORKFLOW)
								@optIn(to: "JiraHasProjectPermissionQuery")
							projectField {
								project {
									projectId
									projectType
									projectStyle
								}
							}
							issueTypeField {
								issueType {
									issueTypeId
								}
							}
						}
					}
				}
			`,
			{
				issueKey,
				cloudId,
			},
		).toPromise();
		return {
			hasPermission:
				(data?.jira?.issueByKey?.projectField?.project?.projectStyle === 'TEAM_MANAGED_PROJECT'
					? data?.jira?.issueByKey?.canReadWorkflow || data?.jira?.issueByKey?.canEditWorkflow
					: data?.jira?.issueByKey?.canReadWorkflow) ?? false,
			projectId: toProjectId(data?.jira?.issueByKey?.projectField?.project?.projectId ?? ''),
			projectType: toProjectType(data?.jira?.issueByKey?.projectField?.project?.projectType ?? ''),
			isSimplified:
				data?.jira?.issueByKey?.projectField?.project?.projectStyle === 'TEAM_MANAGED_PROJECT',
			issueTypeId: toIssueTypeId(
				data?.jira?.issueByKey?.issueTypeField?.issueType?.issueTypeId ?? '',
			),
		};
	}, [cloudId, issueKey]);
	return useMemo(() => ({ fetchWorkflowServiceData }), [fetchWorkflowServiceData]);
};
