import withFireUiAnalytics from '@atlassian/jira-analytics-web-react/src/components/with-fire-ui-analytics.tsx';
import ComponentWithAnalytics from '@atlassian/jira-analytics-web-react/src/utils/component-with-analytics.tsx';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { getParentKey, getProjectId } from '../../state/context/selectors.tsx';
import { addExistingChildIssueRequest } from '../../state/entities/actions.tsx';
import { getNextGenChildrenIssueTypesJql } from '../../state/entities/selectors.tsx';
import type { State } from '../../state/types.tsx';
import {
	setChildPanelView,
	hideAddChildIssue,
	CHILD_PANEL_VIEWS,
	setChildCreateSummary,
} from '../../state/ui/actions.tsx';
import { getChildCreateSummary, getAddClickCount } from '../../state/ui/selectors.tsx';
import AddExistingIssue from './view.tsx';

export default flowWithSafeComponent(
	ComponentWithAnalytics('button', {
		onAdd: 'clicked',
		onCancel: 'clicked',
		onCreate: 'clicked',
	}),
	withFireUiAnalytics({
		onAdd: 'addExistingChildIssueRequest',
		onCancel: 'hideAddExistingChildIssue',
		onCreate: 'switchToCreateChildIssue',
	}),
	// @ts-expect-error - Argument of type 'InferableComponentEnhancerWithProps<{ addClickCount: number; initialValue: string; issueKey: string | null; jql: string; projectId: string | null; } & { onAdd: (issue: any, analyticsEvent: any) => { type: "state.entities.ADD_EXISTING_CHILD_ISSUE_REQUEST"; payload: { ...; }; meta: { ...; }; }; onCancel: () => { ...; ...' is not assignable to parameter of type 'FlowStep<WithUIAnalyticsEvent<PropsWithoutRef<Omit<{ [K in keyof Props]: K extends "onAdd" | "onCancel" | "onCreate" ? (...args: [...Parameters<Props[K]>, UIAnalyticsEvent]) => ReturnType<...> : Props[K]; }, keyof WithAnalyticsEventsProps>>, { ...; }>, ConnectedComponent<...>>'.
	connect(
		(state: State) => ({
			addClickCount: getAddClickCount(state),
			initialValue: getChildCreateSummary(state),
			issueKey: getParentKey(state),
			jql: getNextGenChildrenIssueTypesJql(state),
			projectId: getProjectId(state),
		}),
		(dispatch) => ({
			// @ts-expect-error - TS7006 - Parameter 'issue' implicitly has an 'any' type. | TS7006 - Parameter 'analyticsEvent' implicitly has an 'any' type.
			onAdd: (issue, analyticsEvent) =>
				dispatch(addExistingChildIssueRequest(issue, analyticsEvent)),
			onCancel: () => dispatch(hideAddChildIssue()),
			onCreate: (inputValue: string) => {
				dispatch(setChildCreateSummary(inputValue));
				dispatch(setChildPanelView(CHILD_PANEL_VIEWS.InlineCreate));
			},
		}),
	),
)(AddExistingIssue);
