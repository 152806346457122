import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useIssueFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import {
	type LayoutContainerTemplateItem,
	CUSTOM_ITEM_TYPE,
	ECOSYSTEM_CONTEXT_TYPE,
	ECOSYSTEM_GLANCE_TYPE,
	FORGE_CONTEXT_TYPE,
	FORGE_GLANCE_TYPE,
	layoutContainerItemTypes,
} from '@atlassian/jira-issue-layout-common-constants/src/index.tsx';
import {
	fieldTypeHandler,
	isUnsupportedItem,
} from '@atlassian/jira-issue-view-layout-templates-utils/src/index.tsx';
import { getLayoutItemId } from '@atlassian/jira-issue-view-layout/src/services/utils.tsx';
import {
	GROUP_APP,
	GROUP_CUSTOM,
	GROUP_PANEL,
	GROUP_SYSTEM,
	type IssueViewLayoutItemTypeWithAnalytics,
} from './types.tsx';

export const EMPTY_PAYLOAD = Object.freeze({});

/**
 * This hook maps all Layout Items with their correspondent analytics values, as well as setting their groups,
 * and also will transform the types of some fields into more specific data (eg: for customfield_xxxx to something more readable)
 *
 * @param items ReadOnlyArray<IssueViewLayoutItemType>
 *
 * @returns IssueViewLayoutItemTypeWithAnalytics[]
 */
export const useLayoutItem = (
	items: LayoutContainerTemplateItem[],
): IssueViewLayoutItemTypeWithAnalytics[] => {
	const issueKey = useIssueKey();
	const [{ value: issueFieldsConfig }] = useIssueFieldConfig(issueKey);

	if (issueFieldsConfig === null || issueFieldsConfig === undefined) return [];

	const mappedItems: IssueViewLayoutItemTypeWithAnalytics[] = [];

	items.forEach((sectionItem) => {
		let payload = EMPTY_PAYLOAD;
		let analytics = {
			group: '',
			value: '',
		};

		if (sectionItem.type === CUSTOM_ITEM_TYPE) {
			mappedItems.push({
				...sectionItem,
				analytics: {
					group: GROUP_CUSTOM,
					value: sectionItem.id,
				},
				payload,
			});
		}

		const fieldConfig = issueFieldsConfig[getLayoutItemId(sectionItem)];
		const { type, id } = fieldTypeHandler(fieldConfig, sectionItem);

		if (isUnsupportedItem(type)) {
			return;
		}

		// default custom or system
		analytics = {
			group: fieldConfig?.schema?.custom !== null ? GROUP_CUSTOM : GROUP_SYSTEM,
			value: type,
		};

		// panelItemTypes
		if (sectionItem.type === layoutContainerItemTypes.panel) {
			analytics = { group: GROUP_PANEL, value: type };
		}

		if (
			sectionItem.type === ECOSYSTEM_GLANCE_TYPE ||
			sectionItem.type === FORGE_GLANCE_TYPE ||
			sectionItem.type === ECOSYSTEM_CONTEXT_TYPE ||
			sectionItem.type === FORGE_CONTEXT_TYPE
		) {
			payload = sectionItem.payload;
			// @ts-expect-error - TS2339 - Property 'appKey' does not exist on type 'Readonly<{}>'.
			analytics = { group: GROUP_APP, value: payload.appKey };
		}

		// @ts-expect-error - TS2322 - Type 'string' is not assignable to type '"ecosystemGlance" | "forgeGlance" | "JiraIssueItemFieldItem" | "JiraIssueItemPanelItem" | "JiraIssueItemTabContainer" | "TabLayout" | "FieldSectionItem" | "EcosystemSectionItem" | "ForgeSectionItem" | "PanelSectionItem" | "CustomItem"'. | TS2322 - Type '{ group: string; value: string; }' is not assignable to type '{ group: "" | "custom" | "app" | "system" | "panel"; value: string; }'.
		mappedItems.push({ ...sectionItem, id, type, payload, analytics });
	});
	return mappedItems;
};
