import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fireOperationalAnalyticsDeferred } from '@atlassian/jira-product-analytics-bridge';

export const fireErrorAnalytics = (
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	fetchStartTimestamp: number,
) => {
	fireOperationalAnalyticsDeferred(createAnalyticsEvent({}), 'loadDeliveryData failure', {
		durationMs: fetchStartTimestamp - Date.now(),
	});
};
