// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	downloadBlockDisclaimer: {
		id: 'attachments.heading.meatballs.download-block-disclaimer',
		defaultMessage: 'Download is blocked by your organization’s security policy.',
		description: 'The reason for blocking attachment download',
	},
});
