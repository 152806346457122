import type { OptionToFilter } from '@atlassian/jira-common-components-picker/src/model.tsx';

// @ts-expect-error - TS1016 - A required parameter cannot follow an optional parameter.
const matchesProperty = (val?: string, inputValueIgnoredCase: string) =>
	// label can be Object for new option in CreatableSelect
	typeof val === 'string' &&
	val
		// no need to .trim() since input is already trimmed and we search for .includes() so it does not matter if field has leading or trailing spaces
		.toLowerCase()
		.includes(inputValueIgnoredCase);
/**
 * By default react-select matches by both option.label and option.value: https://github.com/JedWatson/react-select/blob/b7868d23ccd0b9e623b277f971e89020d426fbdb/packages/react-select/src/filters.js#L14.
 * This leads to undesired UX, when filter result includes options without displayed text including the query (which is very confusing for the user): BENTO-5735, BENTO-5655.
 * That's why we need this function.
 */
export const filterOptionByLabelAndFilterValues = (
	option: OptionToFilter,
	inputValue: string,
): boolean => {
	if (option.data && option.data.__isNew__ === true) return true;

	// we do trim() and lowerCase() to match ignoreCase: true, and trim: true default behavior in react-select: https://github.com/JedWatson/react-select/blob/b7868d23ccd0b9e623b277f971e89020d426fbdb/packages/react-select/src/filters.js
	const inputValueIgnoredCase = inputValue.trim().toLowerCase();

	if (matchesProperty(option.label, inputValueIgnoredCase)) return true;

	return option.data && option.data.filterValues
		? option.data.filterValues.some((filterValue) =>
				matchesProperty(filterValue, inputValueIgnoredCase),
			)
		: false;
};
