import React, {
	useEffect,
	useRef,
	useMemo,
	useCallback,
	type ReactElement,
	type SyntheticEvent,
} from 'react';

type ChildRenderProps = {
	onMouseDownCapture: (e: SyntheticEvent) => void;
	onTouchEndCapture: (e: SyntheticEvent) => void;
};
type Props = {
	children: (arg1: ChildRenderProps) => ReactElement;
	onClickOutside: (arg1: Event) => void;
};

export const OutsideClickAlerter = ({ onClickOutside, children }: Props) => {
	const innerClickRef = useRef<boolean>(false);
	const clickStartRef = useRef<boolean>(false);

	const handleClick = useCallback(
		(e: MouseEvent | TouchEvent) => {
			if (clickStartRef.current && !innerClickRef.current && e.target) {
				onClickOutside && onClickOutside(e);
			}
			innerClickRef.current = false;
			clickStartRef.current = false;
		},
		[onClickOutside],
	);

	const innerClick = useCallback(() => {
		innerClickRef.current = true;
	}, []);

	const clickStart = useCallback(() => {
		clickStartRef.current = true;
	}, []);

	useEffect(() => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.addEventListener('mousedown', clickStart, true);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.addEventListener('touchstart', clickStart, true);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.addEventListener('click', handleClick, true);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.addEventListener('touchend', handleClick, true);
		// eslint-disable-next-line @atlaskit/design-system/no-direct-use-of-web-platform-drag-and-drop, jira/jira-ssr/no-unchecked-globals-usage
		document.addEventListener('drop', handleClick, true);
		// eslint-disable-next-line @atlaskit/design-system/no-direct-use-of-web-platform-drag-and-drop, jira/jira-ssr/no-unchecked-globals-usage
		document.addEventListener('dragend', handleClick, true);
		return () => {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.removeEventListener('mousedown', clickStart, true);

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.removeEventListener('touchstart', clickStart, true);

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.removeEventListener('click', handleClick, true);

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.removeEventListener('touchend', handleClick, true);

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.removeEventListener('drop', handleClick, true);

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.removeEventListener('dragend', handleClick, true);
		};
	}, [handleClick, clickStart]);

	const childRenderProps = useMemo(
		() => ({
			onMouseDownCapture: innerClick,
			onTouchEndCapture: innerClick,
		}),
		[innerClick],
	);

	return children(childRenderProps);
};

export const isFocusOutsideJFE = (target?: EventTarget | null) => {
	if (target instanceof HTMLElement) {
		return (
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			!document.querySelector('#jira-frontend')?.contains(target) && // eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
			(window as any).IS_STORYBOOK !== true
		);
	}
	return false;
};

export const withOutsideClickAlerterHandling = (storyFn: () => ReactElement) => (
	<div id="jira-frontend">{storyFn()}</div>
);
