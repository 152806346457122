import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { ChildIssueType } from '@atlassian/jira-issue-view-common-types/src/child-issue-type.tsx';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { getIssueTypes } from '../../../state/entities/selectors.tsx';
import type { State } from '../../../state/types.tsx';
import { selectedIssueTypeChanged } from '../../../state/ui/actions.tsx';
import { getSelectedIssueType } from '../../../state/ui/selectors.tsx';
import ChildTypesSelect from './view.tsx';

type StateProps = {
	issueTypes: ChildIssueType[];
	selectedIssueType: ChildIssueType;
};

export default flowWithSafeComponent(
	connect(
		(state: State): StateProps => ({
			selectedIssueType: getSelectedIssueType(state),
			issueTypes: getIssueTypes(state),
		}),
		{
			onChange: selectedIssueTypeChanged,
		},
	),
)(injectIntl(ChildTypesSelect));
