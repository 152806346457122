/* eslint-disable @repo/internal/import/no-unresolved */
import type { SDKResult, SDKUnsupportedReasons } from '@loomhq/record-sdk';

import type { ExperienceTrackerAPI } from '@atlassian/experience-tracker/ExperienceTracker';
import type { EnvironmentType } from '@atlassian/iap-types';

import {
	INITIALIZE_LOOM_ENTRY_POINT,
	INITIALIZE_LOOM_RECORDER_SDK_INTERACTION_METRIC,
	type ProductType,
} from '../constants';

import { getLoomInstanceDataByEnvironment } from './getLoomInstanceDataByEnvironment';

type InitSDKParams = {
	cloudId: string;
	environment: EnvironmentType;
	productName: ProductType;
	experienceTracker: ExperienceTrackerAPI;
	entryPointLocation: string;
};

export const LoomInitErrorException = {
	UNSUPPORTED_BROWSER: 'unsupported-browser',
	UNSUPPORTED_MEDIA_STREAMING: 'unsupported-media-streaming',
	UNSUPPORTED_3RD_PARTY_COOKIE: 'unsupported-third-party-cookie',
	UNSUPPORTED_UNKNOWN: 'unsupported-unknown',
	TOKEN_UNSUCCESSFUL: 'token-unsuccessful',
	UNMERGED_USER: 'token-unsuccessful-unmerged-user',
	TOKEN_EMPTY: 'token-empty',
	NETWORK: 'network',
	LOOM_INTERNAL_ERROR: 'loom-internal-error',
	UNKNOWN: 'unknown',
} as const;

// A nicer way to define an enum - will accept both LoomInitErrorException.UNSUPPORTED_BROWSER and "unsupported_browser"
export type LoomInitErrorExceptionType =
	(typeof LoomInitErrorException)[keyof typeof LoomInitErrorException];

class LoomInitError extends Error {
	public type;

	constructor(type: LoomInitErrorExceptionType, message?: string) {
		super(message);
		this.type = type;
	}
}

function mapLoomUnsupportedTypeToSubType(unsupportedReason: SDKUnsupportedReasons | undefined) {
	switch (unsupportedReason) {
		case 'incompatible-browser':
			return LoomInitErrorException.UNSUPPORTED_BROWSER;
		case 'third-party-cookies-disabled':
			return LoomInitErrorException.UNSUPPORTED_3RD_PARTY_COOKIE;
		case 'no-media-streams-support':
			return LoomInitErrorException.UNSUPPORTED_MEDIA_STREAMING;
		default:
			return LoomInitErrorException.UNSUPPORTED_UNKNOWN;
	}
}

async function fetchJsonFromUrl<T>(
	url: string,
	method: 'GET' | 'POST' = 'GET',
	expectedStatuses: number[] = [],
): Promise<T> {
	try {
		const response = await fetch(url, {
			method,
			headers: {
				Accept: 'application/json',
			},
		});
		if (!response.ok && !expectedStatuses.includes(response.status)) {
			throw new LoomInitError(LoomInitErrorException.NETWORK, response.statusText);
		}
		const data = await response.json();
		return data;
	} catch (error) {
		throw new LoomInitError(
			LoomInitErrorException.NETWORK,
			error instanceof Error ? error.message : '',
		);
	}
}

type GetAuthTokenResponseType = {
	token: string | undefined;
	message: string | undefined;
};

async function getAuthToken() {
	const { token, message } = await fetchJsonFromUrl<GetAuthTokenResponseType>(
		'/gateway/api/loom-auth/api/internal/auth/sdk_token',
		'POST',
		[404],
	);
	if (message === 'Loom user needs merge') {
		throw new LoomInitError(LoomInitErrorException.UNMERGED_USER);
	}
	if (message !== 'Success') {
		let userStatus;
		try {
			// we don't want a failure to make the identity check to mask the original issue
			const { status } = await fetchJsonFromUrl<{ status: string }>(
				'/gateway/api/loom-auth/api/internal/identity/user-status',
				'GET',
			);
			userStatus = status;
		} catch (error) {
			userStatus = 'unknown user status';
		}
		throw new LoomInitError(
			LoomInitErrorException.TOKEN_UNSUCCESSFUL,
			`${message} , ${userStatus}`,
		);
	}
	if (!token) {
		throw new LoomInitError(LoomInitErrorException.TOKEN_EMPTY);
	}
	return token;
}

async function _init({
	cloudId,
	environment,
	productName,
	experienceTracker,
	entryPointLocation,
}: InitSDKParams): Promise<LoomSDKInitResultType> {
	let loomSDKInstance: SDKResult;
	try {
		INITIALIZE_LOOM_RECORDER_SDK_INTERACTION_METRIC.start();
		experienceTracker.start({
			id: INITIALIZE_LOOM_ENTRY_POINT,
			name: INITIALIZE_LOOM_ENTRY_POINT,
			attributes: { entryPointLocation },
		});
		const { isSupported } = await import(
			/* webpackChunkName: "@atlaskit-internal_loadable-loomhq-record-sdk-is-supported" */ '@loomhq/record-sdk/is-supported'
		);
		const { supported, error } = isSupported();
		if (!supported) {
			throw new LoomInitError(mapLoomUnsupportedTypeToSubType(error));
		}
		const recorderToken = await getAuthToken();
		const { createInstance } = await import(
			/* webpackChunkName: "@atlaskit-internal_loadable-loomhq-record-sdk" */ '@loomhq/record-sdk'
		);
		loomSDKInstance = await createInstance(
			getLoomInstanceDataByEnvironment({
				environment,
				jws: recorderToken,
				siteId: cloudId,
				productName,
			}),
		);
		if (!loomSDKInstance.status().success) {
			throw new LoomInitError(
				LoomInitErrorException.LOOM_INTERNAL_ERROR,
				'Loom SDK timeout or another internal issue',
			);
		}
		experienceTracker.succeed({
			name: INITIALIZE_LOOM_ENTRY_POINT,
			attributes: { entryPointLocation },
		});
		INITIALIZE_LOOM_RECORDER_SDK_INTERACTION_METRIC.stop();
	} catch (error: unknown) {
		INITIALIZE_LOOM_RECORDER_SDK_INTERACTION_METRIC.cancel();
		if (error instanceof LoomInitError) {
			if (
				error.type === LoomInitErrorException.UNSUPPORTED_BROWSER ||
				error.type === LoomInitErrorException.UNSUPPORTED_MEDIA_STREAMING ||
				error.type === LoomInitErrorException.UNSUPPORTED_3RD_PARTY_COOKIE ||
				error.type === LoomInitErrorException.UNMERGED_USER
			) {
				experienceTracker.succeed({
					name: INITIALIZE_LOOM_ENTRY_POINT,
					attributes: { entryPointLocation },
				});
			} else {
				experienceTracker.fail({
					name: INITIALIZE_LOOM_ENTRY_POINT,
					error: new Error(error.type),
					attributes: { entryPointLocation },
				});
			}
			return { kind: 'failure', initError: { type: error.type, message: error.message } };
		}

		experienceTracker.fail({
			name: INITIALIZE_LOOM_ENTRY_POINT,
			error: new Error(LoomInitErrorException.UNKNOWN),
			attributes: { entryPointLocation },
		});

		if (error instanceof Error) {
			return {
				kind: 'failure',
				initError: { type: LoomInitErrorException.UNKNOWN, message: error.message },
			};
		}

		return { kind: 'failure', initError: { type: LoomInitErrorException.UNKNOWN } };
	}
	return { kind: 'success', loomSDKInstance };
}

type LoomSDKInitResultSuccessType = {
	kind: 'success';
	loomSDKInstance: SDKResult;
	initError?: undefined;
};
type LoomSDKInitResultFailureType = {
	kind: 'failure';
	initError: LoomSDKInitErrorType;
	loomSDKInstance?: undefined;
};
type LoomSDKInitResultType = LoomSDKInitResultSuccessType | LoomSDKInitResultFailureType;

export type LoomSDKInitErrorType = {
	type: LoomInitErrorExceptionType;
	message?: string;
};

// this will hold our singleton result instance
let initSDKResultPromise: Promise<LoomSDKInitResultType> | undefined;

export function initLoomSDK(params: InitSDKParams): Promise<LoomSDKInitResultType> {
	// Singleton
	if (initSDKResultPromise === undefined) {
		initSDKResultPromise = _init(params);
	}
	return initSDKResultPromise;
}

export function resetInstance() {
	initSDKResultPromise = undefined;
}
