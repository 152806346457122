/**
 * @generated SignedSource<<86276685ccdd7e1a67357e5a38fb11ca>>
 * @relayHash 177e8918da15eb8363d2f0a56d3be3a4
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 7761aa25ec0acd8d7149e1b2c7674484bb6d14bf4052136f6c0fbc11547be6c1

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { ui_AddDesignFormQuery } from './ui_AddDesignFormQuery.graphql';

const node: PreloadableConcreteRequest<ui_AddDesignFormQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "7761aa25ec0acd8d7149e1b2c7674484bb6d14bf4052136f6c0fbc11547be6c1",
    "metadata": {},
    "name": "ui_AddDesignFormQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
