import { combineEpics } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { switchMap } from 'rxjs/operators/switchMap';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/startWith';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/delay';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/ignoreElements';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/retryWhen';
import 'rxjs/add/operator/catch';
import { FORBIDDEN } from '@atlassian/jira-common-constants/src/http-status-codes.tsx';
import type { SubProduct } from '@atlassian/jira-common-constants/src/sub-product-types.tsx';
import { isPageVisible } from '@atlassian/jira-common-page-visibility/src/index.tsx';
import type { MediaUploadPermissionResponse } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-media-upload-permissions/index.tsx';
import { fetchMediaUploadPermission$ } from '@atlassian/jira-issue-fetch-services/src/services/issue-media-upload-permissions/index.tsx';
import type { MediaContextServiceActions } from '@atlassian/jira-issue-media-context-service/src/types.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { trackOrLogClientError } from '@atlassian/jira-issue-view-common-utils/src/errors/index.tsx';
import { ofTypeOrBatchType } from '@atlassian/jira-issue-view-common-utils/src/rx/of-type-or-batch-type.tsx';
import { genericRetryStrategy } from '@atlassian/jira-issue-view-common-utils/src/rx/retry.tsx';
import { fallbackOnMissingOrError$ } from '@atlassian/jira-issue-view-common-utils/src/utils/prefetched-resources/utils/fallback-on-error.tsx';
import {
	CHECK_UPLOAD_CONTEXT,
	checkUploadContext,
	FETCH_UPLOAD_CONTEXT_FAILURE,
	FETCH_UPLOAD_CONTEXT_REQUEST,
	FETCH_UPLOAD_CONTEXT_SUCCESS,
	fetchUploadContextFailure,
	fetchUploadContextRequest,
	fetchUploadContextSuccess,
} from '@atlassian/jira-issue-view-store/src/common/media/upload-context/upload-context-actions.tsx';
import { uploadContextSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/media-context-selector.tsx';
import { toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { attachmentsUploadCredentialsExperience } from '../experiences.tsx';
import { isServiceDesk as isServiceDeskDI } from '../utils.tsx';
import fetchUploadContextTransformer from './upload-context-transformer.tsx';

const MINIMUM_FOCUS_TIME_MS = 1000;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const createFailureStream = (error: any) => Observable.of(fetchUploadContextFailure(error));

export const fetchUploadContextWithErrorHandlingDeprecated = (
	baseUrl: string,
	issueKey: string,
	prefetchedMediaUploadPermissions?: Promise<MediaUploadPermissionResponse> | null,
) =>
	fallbackOnMissingOrError$(prefetchedMediaUploadPermissions, () =>
		fetchMediaUploadPermission$(baseUrl, issueKey),
	)
		.map(fetchUploadContextTransformer)
		.map(fetchUploadContextSuccess)
		.catch((error) => {
			if (error.statusCode === FORBIDDEN) {
				return createFailureStream(error);
			}
			throw error;
		})
		.retryWhen(genericRetryStrategy)
		.catch(createFailureStream);

export const fetchUploadContextWithErrorHandling = (
	baseUrl: string,
	issueKey: string,
	isServiceDesk: boolean,
	prefetchedMediaUploadPermissions?: Promise<MediaUploadPermissionResponse> | null,
) =>
	fallbackOnMissingOrError$(prefetchedMediaUploadPermissions, () => {
		if (isServiceDesk) {
			attachmentsUploadCredentialsExperience.start();
		}
		return fetchMediaUploadPermission$(baseUrl, issueKey);
	})
		.map(fetchUploadContextTransformer)
		.map(fetchUploadContextSuccess)
		.catch((error) => {
			if (error.statusCode === FORBIDDEN) {
				return createFailureStream(error);
			}
			throw error;
		})
		.retryWhen(genericRetryStrategy)
		.do(() => {
			if (isServiceDesk) {
				attachmentsUploadCredentialsExperience.success();
			}
		})
		.catch((error) => {
			if (isServiceDesk) {
				attachmentsUploadCredentialsExperience.failure();
			}
			return createFailureStream(error);
		});

export const uploadContextEpicDeprecated = (
	getBaseUrl: (state: State) => string,
	getIssueKey: (state: State) => string,
	mediaContextActions: MediaContextServiceActions,
) => {
	const fetchUploadContextEpic = (
		// @ts-expect-error - TS2304 - Cannot find name 'ActionsObservable'.
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		action$: ActionsObservable<any>,
		// @ts-expect-error - TS2304 - Cannot find name 'MiddlewareAPI'.
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		store: MiddlewareAPI<any>,
	) =>
		action$.ofType(FETCH_UPLOAD_CONTEXT_REQUEST).switchMap(() => {
			const state = store.getState();
			const baseUrl = getBaseUrl(state);
			const issueKey = getIssueKey(state);
			return fetchUploadContextWithErrorHandlingDeprecated(baseUrl, issueKey);
		});

	// @ts-expect-error - TS2304 - Cannot find name 'ActionsObservable'.
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const failureLoggingEpic = (action$: ActionsObservable<any>) =>
		action$
			.ofType(FETCH_UPLOAD_CONTEXT_FAILURE)
			// @ts-expect-error - TS7031 - Binding element 'payload' implicitly has an 'any' type.
			.filter(({ payload }) => payload.error.statusCode !== FORBIDDEN)
			// @ts-expect-error - TS7031 - Binding element 'payload' implicitly has an 'any' type.
			.do(({ payload }) => {
				const { error } = payload;
				trackOrLogClientError(
					'issue.media.fetch-upload-context-error',
					'Error when fetching media upload context',
					error,
				);
			})
			.ignoreElements();

	// Whenever the upload context is fetched, we need to schedule a subsequent fetch
	// to occur just before the current token is due to expire. This is implemented as
	// an infinite loop - "fetch success/failure" actions result in a new (delayed)
	// "fetch request" being scheduled.
	const refreshBeforeTokenExpiryEpic = (
		// @ts-expect-error - TS2304 - Cannot find name 'ActionsObservable'.
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		action$: ActionsObservable<any>,
		// @ts-expect-error - TS2304 - Cannot find name 'MiddlewareAPI'.
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		store: MiddlewareAPI<any>,
	) =>
		action$.pipe(
			ofTypeOrBatchType(FETCH_UPLOAD_CONTEXT_SUCCESS, CHECK_UPLOAD_CONTEXT),
			switchMap(() => {
				const state = store.getState();
				const issueKey = getIssueKey(state);
				const uploadContext = uploadContextSelector(state);

				if (uploadContext == null || !isPageVisible()) {
					return Observable.empty<never>();
				}
				mediaContextActions.setUploadContext(toIssueKey(issueKey), uploadContext);
				const { tokenIssueTimestamp, tokenLifespanInMs } = uploadContext;
				const wantsRefreshAt = tokenIssueTimestamp + tokenLifespanInMs;
				const timeUntilRefresh = Math.max(1, wantsRefreshAt - Date.now());

				return Observable.of(fetchUploadContextRequest()).delay(timeUntilRefresh);
			}),
		);

	// When a user returns to a tab (either from moving around in their browser, or a wake from
	// device sleep) we want to check their media upload token and refresh it if necessary. If a
	// refresh is needed, the switchMap in refreshBeforeTokenExpiryEpic will ensure we don't end
	// up with multiple timed refreshes, only the latest one.
	//
	// The combination of debounce and isPageVisible check means we only fire the event if the user
	// has stayed on the page for at least the debounce time, so as not to spam events.
	const focusChangeEpic = () =>
		Observable.fromEvent(window, 'focus')
			.startWith(null)
			.debounceTime(MINIMUM_FOCUS_TIME_MS)
			.switchMap(() =>
				isPageVisible() ? Observable.of(checkUploadContext()) : Observable.empty<never>(),
			);
	return combineEpics(
		fetchUploadContextEpic,
		refreshBeforeTokenExpiryEpic,
		failureLoggingEpic,
		focusChangeEpic,
	);
};

export const uploadContextEpic = (
	getBaseUrl: (state: State) => string,
	getIssueKey: (state: State) => string,
	subProduct: SubProduct | null,
	mediaContextActions: MediaContextServiceActions,
) => {
	const fetchUploadContextEpic = (
		// @ts-expect-error - TS2304 - Cannot find name 'ActionsObservable'.
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		action$: ActionsObservable<any>,
		// @ts-expect-error - TS2304 - Cannot find name 'MiddlewareAPI'.
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		store: MiddlewareAPI<any>,
	) =>
		action$.ofType(FETCH_UPLOAD_CONTEXT_REQUEST).switchMap(() => {
			const state = store.getState();
			const baseUrl = getBaseUrl(state);
			const issueKey = getIssueKey(state);
			const isServiceDesk = isServiceDeskDI(subProduct);

			return fetchUploadContextWithErrorHandling(baseUrl, issueKey, isServiceDesk);
		});

	// @ts-expect-error - TS2304 - Cannot find name 'ActionsObservable'.
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const failureLoggingEpic = (action$: ActionsObservable<any>) =>
		action$
			.ofType(FETCH_UPLOAD_CONTEXT_FAILURE)
			// @ts-expect-error - TS7031 - Binding element 'payload' implicitly has an 'any' type.
			.filter(({ payload }) => payload.error.statusCode !== FORBIDDEN)
			// @ts-expect-error - TS7031 - Binding element 'payload' implicitly has an 'any' type.
			.do(({ payload }) => {
				const { error } = payload;
				trackOrLogClientError(
					'issue.media.fetch-upload-context-error',
					'Error when fetching media upload context',
					error,
				);
			})
			.ignoreElements();

	// Whenever the upload context is fetched, we need to schedule a subsequent fetch
	// to occur just before the current token is due to expire. This is implemented as
	// an infinite loop - "fetch success/failure" actions result in a new (delayed)
	// "fetch request" being scheduled.
	const refreshBeforeTokenExpiryEpic = (
		// @ts-expect-error - TS2304 - Cannot find name 'ActionsObservable'.
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		action$: ActionsObservable<any>,
		// @ts-expect-error - TS2304 - Cannot find name 'MiddlewareAPI'.
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		store: MiddlewareAPI<any>,
	) =>
		action$.pipe(
			ofTypeOrBatchType(FETCH_UPLOAD_CONTEXT_SUCCESS, CHECK_UPLOAD_CONTEXT),
			switchMap(() => {
				const state = store.getState();
				const issueKey = getIssueKey(state);
				const uploadContext = uploadContextSelector(state);

				if (uploadContext == null || !isPageVisible()) {
					return Observable.empty<never>();
				}
				mediaContextActions.setUploadContext(toIssueKey(issueKey), uploadContext);
				const { tokenIssueTimestamp, tokenLifespanInMs } = uploadContext;
				const wantsRefreshAt = tokenIssueTimestamp + tokenLifespanInMs;
				const timeUntilRefresh = Math.max(1, wantsRefreshAt - Date.now());

				return Observable.of(fetchUploadContextRequest()).delay(timeUntilRefresh);
			}),
		);

	// When a user returns to a tab (either from moving around in their browser, or a wake from
	// device sleep) we want to check their media upload token and refresh it if necessary. If a
	// refresh is needed, the switchMap in refreshBeforeTokenExpiryEpic will ensure we don't end
	// up with multiple timed refreshes, only the latest one.
	//
	// The combination of debounce and isPageVisible check means we only fire the event if the user
	// has stayed on the page for at least the debounce time, so as not to spam events.
	const focusChangeEpic = () =>
		Observable.fromEvent(window, 'focus')
			.startWith(null)
			.debounceTime(MINIMUM_FOCUS_TIME_MS)
			.switchMap(() =>
				isPageVisible() ? Observable.of(checkUploadContext()) : Observable.empty<never>(),
			);
	return combineEpics(
		fetchUploadContextEpic,
		refreshBeforeTokenExpiryEpic,
		failureLoggingEpic,
		focusChangeEpic,
	);
};
