import React from 'react';
import { Form } from '@atlassian/jira-inline-edit-lite/src/ui/form/index.tsx';
import { EditViewPopup } from './edit-view-popup/index.tsx';
import type { Props } from './types.tsx';

/**
 * Responsible for rendering the common edit view UI elements for an issue field, including the form and associated
 * action buttons.
 */
export const EditView = ({
	cancelButtonLabel,
	children,
	confirmButtonLabel,
	context,
	editViewPopup,
	editViewPopupAlignBlock,
	editViewPopupMinWidth,
	hideActionButtons,
	keepEditViewOpenOnBlur,
}: Props) => {
	const form = (
		<Form
			cancelButtonLabel={cancelButtonLabel}
			confirmButtonLabel={confirmButtonLabel}
			context={context}
			hideActionButtons={hideActionButtons}
			keepEditViewOpenOnBlur={keepEditViewOpenOnBlur}
		>
			{children}
		</Form>
	);
	if (editViewPopup) {
		return (
			<EditViewPopup alignBlock={editViewPopupAlignBlock} minWidth={editViewPopupMinWidth}>
				{form}
			</EditViewPopup>
		);
	}

	return form;
};
