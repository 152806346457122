import type { ViewSet } from '@atlassian/jira-polaris-domain-view/src/view-set/types.tsx';

// Updates view sets
export const updateViewSetState = (
	currentViewSets: ViewSet[],
	predicate: (arg1: ViewSet) => boolean,
	mutation: (arg: ViewSet) => ViewSet,
): {
	changedViewSet?: ViewSet;
	viewSets?: ViewSet[];
} => {
	let changedViewSet;

	const newViewSets = currentViewSets.map((element) => {
		const viewSets = (element.viewSets || []).map((vSet) => {
			if (predicate(vSet)) {
				changedViewSet = mutation(vSet);
				return changedViewSet;
			}
			return vSet;
		});

		return {
			...element,
			viewSets,
		};
	});

	return { changedViewSet, viewSets: newViewSets };
};
