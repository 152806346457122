import { REQUIRED } from '@atlassian/jira-issue-form-field-common/src/constants.tsx';
import type { AggUser } from '@atlassian/jira-issue-user-picker-edit-view/src/common/types.tsx';

export const validatePeopleField = (value: AggUser[], isRequired: boolean): string | undefined => {
	if (isRequired && !value?.length) {
		return REQUIRED;
	}

	return undefined;
};
