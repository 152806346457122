import { useMemo } from 'react';
import { JiraProjectAri } from '@atlassian/ari/jira/project';
import type { DocumentFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/document/types.tsx';
import type { IssueTypeFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/issue-type/types.tsx';
import type { OptionFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/option/types.tsx';
import type { LinkedIssuesProgress } from '@atlassian/jira-polaris-domain-field/src/field-types/progress/types.tsx';
import type { StatusFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/status/types.tsx';
import type { UserFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/user/types.tsx';
import type { IntervalFieldSource } from '@atlassian/jira-polaris-domain-field/src/field/interval/index.tsx';
import type { ProjectFieldValue } from '@atlassian/jira-polaris-domain-field/src/field/project/types.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type {
	LocalIssueId,
	OptionProperty,
	IssueAnalyticsData,
} from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { IntervalValue } from '@atlassian/jira-polaris-lib-date-time/src/types.tsx';
import type { IssueKey, IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import type { User } from '../../../../common/types/user/index.tsx';
import { useIsDynamicFieldIsInitialized } from '../../../dynamic-field/index.tsx';
import type {
	IssueFieldDate,
	ExternalIssueData,
	IssueCreateStatusType,
	PropertyMap,
	State,
} from '../../types.tsx';
import type { Value } from '../../utils/field-mapping/external-reference-property/index.tsx';
import { useField } from '../fields-hooks.tsx';
import { useIsInitialized } from '../meta-hooks.tsx';
import { useIsSingleIssueLoaded } from '../single-issue-hooks.tsx';
import {
	createHigherLevelIssueHook,
	createHigherLevelIssueHook2,
	createIssueHook,
	createSelectedIssueHook,
	createSelectedIssueHook2,
	createHigherLevelIssueHook3,
} from '../utils.tsx';
import {
	createGetKeySelector,
	createSafeGetKeySelector,
	createGetSummary,
	createGetStringValueSelector,
	createGetNumberValueSelector,
	createGetStringListValueSelector,
	createGetUserSelector,
	createGetPeopleSelector,
	createGetSingleOptionSelector,
	createGetMultiOptionSelector,
	createGetIssueCreationStateProperties,
	createSafeGetJiraIdSelector,
	getSelectedIssueLocalIssueId,
	createGetIsArchivedSelector,
	createGetDescription,
	createGetSingularStatusSelectorForSelectedIssueHook,
	createGetIdSelector,
	getSelectedIssue,
	hasSelectedLocalIssueId,
	createGetIssueType,
	createGetStatus,
	getKeyProperties,
	createGetUserByAccountIdSelector,
	getIdeasInCreation,
	createGetPeopleTransformedSelector,
	createGetExternalReferencesValueSelector,
	createGetExternalReferencePropertyValueSelector,
	getAllExternalReferencesPropertiesByProvider,
	createGetIntervalDateValuesSelector,
	createGetIssueAnalyticsAttributes,
	getProperties,
	createGetPeopleByAccountIdSelector,
	getIdeasInCreationGrouped,
	createGetProjectSelector,
	getSelectedIssueJiraId,
	createGetTeamSelector,
} from './index.tsx';
import {
	createGetLinkedIssueData,
	createGetLinkedProgress,
	createGetLinkedDeliveryIssuesExist,
	createGetAggregatedDeliveryDate,
	createGetLinkedDeliveryTicketsCount,
} from './linked-issues/index.tsx';
import { createGetUsersByAccountIdSelector } from './users/index.tsx';

export const useIssueKey = createHigherLevelIssueHook<LocalIssueId, IssueKey>(createGetKeySelector);

export const useSafeIssueKey = createHigherLevelIssueHook<
	LocalIssueId | undefined,
	IssueKey | undefined
>(createSafeGetKeySelector);

export const useSafeJiraIssueId = createHigherLevelIssueHook<
	LocalIssueId | undefined,
	IssueId | undefined
>(createSafeGetJiraIdSelector);

export const useSummary = createHigherLevelIssueHook<LocalIssueId, string>(createGetSummary);

export const useDescription = createHigherLevelIssueHook<
	LocalIssueId | undefined,
	DocumentFieldValue
>(createGetDescription);

export const useIssueAnalitycsAttributes = createHigherLevelIssueHook<
	LocalIssueId | undefined,
	IssueAnalyticsData | undefined
>(createGetIssueAnalyticsAttributes);

export const useIssueType = createHigherLevelIssueHook<
	LocalIssueId | undefined,
	IssueTypeFieldValue | undefined
>(createGetIssueType);

export const useStatus = createHigherLevelIssueHook<
	LocalIssueId | undefined,
	StatusFieldValue | undefined
>(createGetStatus);

export const useIssueCreatedProperty = createHigherLevelIssueHook<
	LocalIssueId,
	{
		status: IssueCreateStatusType;
		anchorBefore?: LocalIssueId;
		anchorAfter?: LocalIssueId;
	}
>(createGetIssueCreationStateProperties);

export const useIdeasInCreation = createIssueHook(getIdeasInCreation);

export const useIdeasInCreationGrouped = createIssueHook(getIdeasInCreationGrouped);

export const useStringValue = createHigherLevelIssueHook2<
	FieldKey,
	LocalIssueId | undefined,
	string | undefined
>(createGetStringValueSelector);

export const useTeamValue = createHigherLevelIssueHook2<FieldKey, LocalIssueId, string | undefined>(
	createGetTeamSelector,
);

export const useIsSelectedIssueAvailable = createIssueHook(hasSelectedLocalIssueId);

export const useSelectedIssue: () => LocalIssueId | undefined = createIssueHook(
	getSelectedIssueLocalIssueId,
);

export const useSelectedIssueStringValue: (arg1: FieldKey) => string | undefined =
	createSelectedIssueHook(createGetStringValueSelector);

export const useSelectedIssuesIntervalDateValues = createHigherLevelIssueHook2<
	FieldKey | undefined,
	LocalIssueId[],
	Record<
		LocalIssueId,
		{
			field: { dataSource: IntervalFieldSource; fieldKey: FieldKey };
			isExternal: boolean;
			value: IntervalValue;
		}
	>
>(createGetIntervalDateValuesSelector);

export const useSelectedIssueStatusValue: (arg1: FieldKey) => StatusFieldValue | undefined =
	createSelectedIssueHook(createGetSingularStatusSelectorForSelectedIssueHook);

export const useSelectedIssueUserValue: (arg1: FieldKey) => UserFieldValue | undefined =
	createSelectedIssueHook(createGetUserSelector);

export const useSelectedIssueTeamValue: (key: FieldKey) => string | undefined =
	createSelectedIssueHook(createGetTeamSelector);

export const useSelectedIssueSummary: () => string | undefined =
	createSelectedIssueHook2(createGetSummary);

export const useSelectedIssueKey: () => IssueKey | undefined = createIssueHook(getSelectedIssue);

export const useSelectedIssueJiraId: () => number | undefined =
	createIssueHook(getSelectedIssueJiraId);

export const useSelectedIssueId: () => number | undefined =
	createSelectedIssueHook2(createGetIdSelector);

export const useSelectedIssueIssueType: () => IssueTypeFieldValue | undefined =
	createSelectedIssueHook2(createGetIssueType);

export const useSelectedIssueProject: () => ProjectFieldValue | undefined =
	createSelectedIssueHook2(createGetProjectSelector);

export const useSelectedIssueProjectAri = (): string | undefined => {
	const { cloudId } = useTenantContext();
	const projectId = useSelectedIssueProject()?.id;

	if (!projectId) return;

	return JiraProjectAri.create({
		siteId: cloudId,
		projectId,
	}).toString();
};

const useNumberValueInternal = createHigherLevelIssueHook3<
	FieldKey,
	LocalIssueId,
	boolean,
	number | undefined
>(createGetNumberValueSelector);

export const useNumberValue = (fieldKey: FieldKey, localIssueId: LocalIssueId) => {
	const field = useField(fieldKey);
	const isDynamicFieldIsInitialized = useIsDynamicFieldIsInitialized(fieldKey);
	const isSingleIssueLoaded = useIsSingleIssueLoaded();
	const isInitialized = useIsInitialized();
	return {
		value: useNumberValueInternal(fieldKey, localIssueId, isDynamicFieldIsInitialized),
		initialized:
			isDynamicFieldIsInitialized || !field?.formula || (isSingleIssueLoaded && !isInitialized),
	};
};

export const useExternalReferenceValue = createHigherLevelIssueHook2<
	FieldKey | undefined,
	LocalIssueId,
	string | string[] | undefined
>(createGetExternalReferencesValueSelector);

export const useExternalReferencePropertyValue = createHigherLevelIssueHook2<
	FieldKey,
	LocalIssueId,
	Value | undefined
>(createGetExternalReferencePropertyValueSelector);

export const useAllExternalReferencesPropertiesByProvider = createIssueHook(
	getAllExternalReferencesPropertiesByProvider,
);

export const useAllExternalReferencesPropertiesForProvider = (provider?: string) => {
	const allExternalReferencesPropertiesByProvider = useAllExternalReferencesPropertiesByProvider();
	return useMemo(
		() => (provider ? allExternalReferencesPropertiesByProvider[provider] || [] : []),
		[allExternalReferencesPropertiesByProvider, provider],
	);
};

const useIsSelectedIssueArchivedSelector: () => boolean | undefined = createSelectedIssueHook2(
	createGetIsArchivedSelector,
);

export const useIsSelectedIssueArchived: () => boolean = () => {
	const isArchived = useIsSelectedIssueArchivedSelector();
	return isArchived === true;
};

export const useIsIssueArchived = createHigherLevelIssueHook<LocalIssueId, boolean>(
	createGetIsArchivedSelector,
);

export const useStringListValue = createHigherLevelIssueHook2<
	FieldKey,
	LocalIssueId,
	string[] | undefined
>(createGetStringListValueSelector);

export const useUser = createHigherLevelIssueHook2<
	FieldKey,
	LocalIssueId,
	UserFieldValue | undefined
>(createGetUserSelector);

export const useUserByAccountId = createHigherLevelIssueHook2<
	FieldKey,
	string,
	UserFieldValue | undefined
>(createGetUserByAccountIdSelector);

export const useUsersByAccountId = createHigherLevelIssueHook<
	FieldKey,
	| {
			[key: string]: UserFieldValue | undefined;
	  }
	| undefined
>(createGetUsersByAccountIdSelector);

export const usePeople = createHigherLevelIssueHook2<
	FieldKey,
	LocalIssueId,
	UserFieldValue[] | undefined
>(createGetPeopleSelector);

export const usePeopleByAccountId = createHigherLevelIssueHook2<FieldKey, string, User | undefined>(
	createGetPeopleByAccountIdSelector,
);

export const usePeopleTransformed = createHigherLevelIssueHook2<FieldKey, LocalIssueId, User[]>(
	createGetPeopleTransformedSelector,
);

export const useSingleSelect = createHigherLevelIssueHook2<
	FieldKey,
	LocalIssueId,
	OptionProperty[]
>(createGetSingleOptionSelector);

export const useMultiSelect = createHigherLevelIssueHook2<FieldKey, LocalIssueId, OptionProperty[]>(
	createGetMultiOptionSelector,
);

const getMultiSelects = (state: State): PropertyMap<OptionFieldValue[]> =>
	state.properties.multiSelect;
export const useMultiSelects = createIssueHook(getMultiSelects);

const getSummaries = (state: State): Record<LocalIssueId, string> =>
	state.properties.string.summary;
export const useSummaries = createIssueHook(getSummaries);

export const getKeys = (state: State): Record<LocalIssueId, string> => state.properties.string.key;
export const useKeys = createIssueHook(getKeys);

const getIssueTypes = (state: State): Record<LocalIssueId, IssueTypeFieldValue> =>
	state.properties.issueType.issuetype;
export const useIssueTypes = createIssueHook(getIssueTypes);

export const useLinkedIssueData = createHigherLevelIssueHook<LocalIssueId, ExternalIssueData[]>(
	createGetLinkedIssueData,
);

export const useSelectedIssueLinkedIssueData: () => ExternalIssueData[] | undefined =
	createSelectedIssueHook2(createGetLinkedIssueData);

export const useLinkedProgress = createHigherLevelIssueHook<
	LocalIssueId | undefined,
	LinkedIssuesProgress
>(createGetLinkedProgress);

export const useLinkedDeliveryIssuesCount = createHigherLevelIssueHook<LocalIssueId, number>(
	createGetLinkedDeliveryTicketsCount,
);

export const useLinkedDeliveryIssuesExist = createHigherLevelIssueHook<LocalIssueId, boolean>(
	createGetLinkedDeliveryIssuesExist,
);

export const useSelectedIssueLinkedProgress: () => LinkedIssuesProgress | undefined =
	createSelectedIssueHook2(createGetLinkedProgress);

export const useSingleLinkedStatus = (issueId: LocalIssueId) => {
	const externalIssueData = useLinkedIssueData(issueId);
	const issueDeliveryProgress = useLinkedProgress(issueId);

	return useMemo(() => {
		const { singleStatus, total } = issueDeliveryProgress;

		if (total === 1) {
			if (singleStatus) {
				return singleStatus;
			}

			if (externalIssueData.length === 1) {
				return {
					name: externalIssueData[0].status.name,
					category: externalIssueData[0].status.statusCategory.key,
				};
			}
		}

		return undefined;
	}, [externalIssueData, issueDeliveryProgress]);
};

export const useSingleIssueDeliveryDate = createHigherLevelIssueHook3<
	LocalIssueId | undefined,
	FieldKey | undefined,
	string | undefined,
	IssueFieldDate | undefined
>(createGetAggregatedDeliveryDate);

export const useKeyProperties = createIssueHook(getKeyProperties);

export const useAllProperties = createIssueHook(getProperties);
