import {
	type ModalState,
	NO_MODAL,
} from '@atlassian/jira-issue-view-common-types/src/worklog-type.tsx';
import {
	OPEN_MODAL,
	type OpenModalAction,
	CLOSE_MODAL,
	type CloseModalAction,
	ADD_WORKLOG_REQUEST,
	type AddWorklogRequestAction,
	ADD_WORKLOG_SUCCESS,
	type AddWorklogSuccessAction,
	ADD_WORKLOG_FAILURE,
	type AddWorklogFailureAction,
	UPDATE_TIME_REMAINING_REQUEST,
	type UpdateTimeRemainingRequestAction,
	UPDATE_TIME_REMAINING_SUCCESS,
	type UpdateTimeRemainingSuccessAction,
	UPDATE_TIME_REMAINING_FAILURE,
	type UpdateTimeRemainingFailureAction,
	EDIT_WORKLOG_REQUEST,
	type EditWorklogRequestAction,
	EDIT_WORKLOG_SUCCESS,
	type EditWorklogSuccessAction,
	EDIT_WORKLOG_FAILURE,
	type EditWorklogFailureAction,
	DELETE_WORKLOG_REQUEST,
	type DeleteWorklogRequestAction,
	DELETE_WORKLOG_SUCCESS,
	type DeleteWorklogSuccessAction,
	DELETE_WORKLOG_FAILURE,
	type DeleteWorklogFailureAction,
} from '../../../common/actions/worklog-actions.tsx';

export const initialModalState = {
	openModal: NO_MODAL,
	isSaving: false,
	activeWorklogId: null,
} as const;

type Action =
	| OpenModalAction
	| CloseModalAction
	| AddWorklogRequestAction
	| AddWorklogSuccessAction
	| AddWorklogFailureAction
	| UpdateTimeRemainingRequestAction
	| UpdateTimeRemainingSuccessAction
	| UpdateTimeRemainingFailureAction
	| EditWorklogRequestAction
	| EditWorklogSuccessAction
	| EditWorklogFailureAction
	| DeleteWorklogRequestAction
	| DeleteWorklogSuccessAction
	| DeleteWorklogFailureAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: ModalState = initialModalState, action: Action): ModalState => {
	switch (action.type) {
		case OPEN_MODAL:
			return {
				openModal: action.payload.modalType,
				isSaving: false,
				activeWorklogId: action.payload.worklogId,
			};

		case UPDATE_TIME_REMAINING_REQUEST:
		case ADD_WORKLOG_REQUEST:
		case EDIT_WORKLOG_REQUEST:
		case DELETE_WORKLOG_REQUEST:
			return { ...state, isSaving: true };

		case UPDATE_TIME_REMAINING_FAILURE:
		case ADD_WORKLOG_FAILURE:
		case EDIT_WORKLOG_FAILURE:
		case DELETE_WORKLOG_FAILURE:
			return { ...state, isSaving: false };

		case CLOSE_MODAL:
		case ADD_WORKLOG_SUCCESS:
		case UPDATE_TIME_REMAINING_SUCCESS:
		case EDIT_WORKLOG_SUCCESS:
		case DELETE_WORKLOG_SUCCESS:
			return {
				openModal: NO_MODAL,
				isSaving: false,
				activeWorklogId: null,
			};
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
