import {
  Jast,
  Query,
  Clause,
  CLAUSE_TYPE_COMPOUND,
  CLAUSE_TYPE_FIELD_CHANGED,
  CLAUSE_TYPE_FIELD_VALUE,
  CLAUSE_TYPE_FIELD_WAS,
  CompoundClause,
  Field,
  FieldChangedClause,
  FieldValueClause,
  FieldWasClause,
  CompoundOperator,
  FieldChangedOperator,
  FieldChangedTimePredicate,
  FieldChangedTimePredicateOperator,
  FieldValueOperator,
  FieldWasOperator,
  FieldOperand,
  FieldValueOperand,
  FunctionArg,
  FunctionOperand,
  FunctionString,
  KeywordOperand,
  ListOperand,
  OPERAND_TYPE_FIELD_VALUE,
  OPERAND_TYPE_FUNCTION,
  OPERAND_TYPE_KEYWORD,
  OPERAND_TYPE_LIST,
  OrderBy,
  OrderByDirection,
  OrderByField,
  OrderByOperator
} from './types';
import { JastListener } from './JastListener';

/**
 * Default method to walk an AST an invoke the corresponding listener methods for each node.
 *
 * @param listener Listener to invoke enter and exit methods
 * @param jast JQL AST to walk
 */
export const walkAST = (listener: JastListener, jast: Jast): void => {
  jast.query && enterQuery(listener, jast.query);
};

const enterQuery = (listener: JastListener, query: Query): void => {
  listener.enterQuery && listener.enterQuery(query);

  query.where && enterClause(listener, query.where);
  query.orderBy && enterOrderBy(listener, query.orderBy);

  listener.exitQuery && listener.exitQuery(query);
};
const enterClause = (listener: JastListener, clause: Clause): void => {
  listener.enterClause && listener.enterClause(clause);

  switch (clause.clauseType) {
    case CLAUSE_TYPE_COMPOUND:
      enterCompoundClause(listener, clause);
      break;
    case CLAUSE_TYPE_FIELD_VALUE:
      enterFieldValueClause(listener, clause);
      break;
    case CLAUSE_TYPE_FIELD_CHANGED:
      enterFieldChangedClause(listener, clause);
      break;
    case CLAUSE_TYPE_FIELD_WAS:
      enterFieldWasClause(listener, clause);
      break;
  }

  listener.exitClause && listener.exitClause(clause);
};
const enterCompoundClause = (
  listener: JastListener,
  compoundClause: CompoundClause
): void => {
  listener.enterCompoundClause && listener.enterCompoundClause(compoundClause);

  enterCompoundOperator(listener, compoundClause.operator);
  compoundClause.clauses.forEach(clause => enterClause(listener, clause));

  listener.exitCompoundClause && listener.exitCompoundClause(compoundClause);
};
const enterFieldValueClause = (
  listener: JastListener,
  fieldValueClause: FieldValueClause
): void => {
  listener.enterFieldValueClause &&
    listener.enterFieldValueClause(fieldValueClause);

  enterField(listener, fieldValueClause.field);
  fieldValueClause.operator &&
    enterFieldValueOperator(listener, fieldValueClause.operator);
  fieldValueClause.operand &&
    enterFieldOperand(listener, fieldValueClause.operand);

  listener.exitFieldValueClause &&
    listener.exitFieldValueClause(fieldValueClause);
};
const enterFieldChangedClause = (
  listener: JastListener,
  fieldChangedClause: FieldChangedClause
): void => {
  listener.enterFieldChangedClause &&
    listener.enterFieldChangedClause(fieldChangedClause);

  enterField(listener, fieldChangedClause.field);
  fieldChangedClause.operator &&
    enterFieldChangedOperator(listener, fieldChangedClause.operator);
  fieldChangedClause.predicates.forEach(predicate =>
    enterFieldChangedTimePredicate(listener, predicate)
  );

  listener.exitFieldChangedClause &&
    listener.exitFieldChangedClause(fieldChangedClause);
};
const enterFieldWasClause = (
  listener: JastListener,
  fieldWasClause: FieldWasClause
): void => {
  listener.enterFieldWasClause && listener.enterFieldWasClause(fieldWasClause);

  enterField(listener, fieldWasClause.field);
  fieldWasClause.operator &&
    enterFieldWasOperator(listener, fieldWasClause.operator);
  fieldWasClause.operand && enterFieldOperand(listener, fieldWasClause.operand);
  fieldWasClause.predicates.forEach(predicate =>
    enterFieldChangedTimePredicate(listener, predicate)
  );

  listener.exitFieldWasClause && listener.exitFieldWasClause(fieldWasClause);
};
const enterCompoundOperator = (
  listener: JastListener,
  compoundOperator: CompoundOperator
): void => {
  listener.enterCompoundOperator &&
    listener.enterCompoundOperator(compoundOperator);
  listener.exitCompoundOperator &&
    listener.exitCompoundOperator(compoundOperator);
};
const enterField = (listener: JastListener, field: Field): void => {
  listener.enterField && listener.enterField(field);
  listener.exitField && listener.exitField(field);
};
const enterFieldValueOperator = (
  listener: JastListener,
  fieldValueOperator: FieldValueOperator
): void => {
  listener.enterFieldValueOperator &&
    listener.enterFieldValueOperator(fieldValueOperator);
  listener.exitFieldValueOperator &&
    listener.exitFieldValueOperator(fieldValueOperator);
};
const enterFieldWasOperator = (
  listener: JastListener,
  fieldWasOperator: FieldWasOperator
): void => {
  listener.enterFieldWasOperator &&
    listener.enterFieldWasOperator(fieldWasOperator);
  listener.exitFieldWasOperator &&
    listener.exitFieldWasOperator(fieldWasOperator);
};
const enterFieldChangedOperator = (
  listener: JastListener,
  fieldChangedOperator: FieldChangedOperator
): void => {
  listener.enterFieldChangedOperator &&
    listener.enterFieldChangedOperator(fieldChangedOperator);
  listener.exitFieldChangedOperator &&
    listener.exitFieldChangedOperator(fieldChangedOperator);
};
const enterFieldOperand = (
  listener: JastListener,
  fieldOperand: FieldOperand
): void => {
  listener.enterFieldOperand && listener.enterFieldOperand(fieldOperand);

  switch (fieldOperand.operandType) {
    case OPERAND_TYPE_LIST:
      enterListOperand(listener, fieldOperand);
      break;
    case OPERAND_TYPE_FIELD_VALUE:
      enterFieldValueOperand(listener, fieldOperand);
      break;
    case OPERAND_TYPE_KEYWORD:
      enterKeywordOperand(listener, fieldOperand);
      break;
    case OPERAND_TYPE_FUNCTION:
      enterFunctionOperand(listener, fieldOperand);
      break;
  }

  listener.exitFieldOperand && listener.exitFieldOperand(fieldOperand);
};
const enterListOperand = (
  listener: JastListener,
  listOperand: ListOperand
): void => {
  listener.enterListOperand && listener.enterListOperand(listOperand);

  listOperand.values.forEach(operand => {
    switch (operand.operandType) {
      case OPERAND_TYPE_FIELD_VALUE:
        enterFieldValueOperand(listener, operand);
        break;
      case OPERAND_TYPE_KEYWORD:
        enterKeywordOperand(listener, operand);
        break;
      case OPERAND_TYPE_FUNCTION:
        enterFunctionOperand(listener, operand);
        break;
    }
  });

  listener.exitListOperand && listener.exitListOperand(listOperand);
};
const enterFieldValueOperand = (
  listener: JastListener,
  fieldValueOperand: FieldValueOperand
): void => {
  listener.enterFieldValueOperand &&
    listener.enterFieldValueOperand(fieldValueOperand);
  listener.exitFieldValueOperand &&
    listener.exitFieldValueOperand(fieldValueOperand);
};
const enterKeywordOperand = (
  listener: JastListener,
  keywordOperand: KeywordOperand
): void => {
  listener.enterKeywordOperand && listener.enterKeywordOperand(keywordOperand);
  listener.exitKeywordOperand && listener.exitKeywordOperand(keywordOperand);
};
const enterFunctionOperand = (
  listener: JastListener,
  functionOperand: FunctionOperand
): void => {
  listener.enterFunctionOperand &&
    listener.enterFunctionOperand(functionOperand);

  enterFunction(listener, functionOperand.function);
  functionOperand.arguments.forEach(arg => enterArgument(listener, arg));

  listener.exitFunctionOperand && listener.exitFunctionOperand(functionOperand);
};
const enterFunction = (
  listener: JastListener,
  functionString: FunctionString
): void => {
  listener.enterFunction && listener.enterFunction(functionString);
  listener.exitFunction && listener.exitFunction(functionString);
};
const enterArgument = (listener: JastListener, argument: FunctionArg): void => {
  listener.enterArgument && listener.enterArgument(argument);
  listener.exitArgument && listener.exitArgument(argument);
};
const enterFieldChangedTimePredicate = (
  listener: JastListener,
  fieldChangedTimePredicate: FieldChangedTimePredicate
): void => {
  listener.enterFieldChangedTimePredicate &&
    listener.enterFieldChangedTimePredicate(fieldChangedTimePredicate);

  enterFieldChangedTimePredicateOperator(
    listener,
    fieldChangedTimePredicate.operator
  );
  fieldChangedTimePredicate.operand &&
    enterFieldOperand(listener, fieldChangedTimePredicate.operand);

  listener.exitFieldChangedTimePredicate &&
    listener.exitFieldChangedTimePredicate(fieldChangedTimePredicate);
};
const enterFieldChangedTimePredicateOperator = (
  listener: JastListener,
  fieldChangedTimePredicateOperator: FieldChangedTimePredicateOperator
): void => {
  listener.enterFieldChangedTimePredicateOperator &&
    listener.enterFieldChangedTimePredicateOperator(
      fieldChangedTimePredicateOperator
    );
  listener.exitFieldChangedTimePredicateOperator &&
    listener.exitFieldChangedTimePredicateOperator(
      fieldChangedTimePredicateOperator
    );
};
const enterOrderBy = (listener: JastListener, orderBy: OrderBy): void => {
  listener.enterOrderBy && listener.enterOrderBy(orderBy);

  enterOrderByOperator(listener, orderBy.operator);
  orderBy.fields.forEach(field => enterOrderByField(listener, field));

  listener.exitOrderBy && listener.exitOrderBy(orderBy);
};
const enterOrderByOperator = (
  listener: JastListener,
  orderByOperator: OrderByOperator
): void => {
  listener.enterOrderByOperator &&
    listener.enterOrderByOperator(orderByOperator);
  listener.exitOrderByOperator && listener.exitOrderByOperator(orderByOperator);
};
const enterOrderByField = (
  listener: JastListener,
  orderByField: OrderByField
): void => {
  listener.enterOrderByField && listener.enterOrderByField(orderByField);

  enterField(listener, orderByField.field);
  orderByField.direction &&
    enterOrderByDirection(listener, orderByField.direction);

  listener.exitOrderByField && listener.exitOrderByField(orderByField);
};
const enterOrderByDirection = (
  listener: JastListener,
  orderByDirection: OrderByDirection
): void => {
  listener.enterOrderByDirection &&
    listener.enterOrderByDirection(orderByDirection);
  listener.exitOrderByDirection &&
    listener.exitOrderByDirection(orderByDirection);
};
