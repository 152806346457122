import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	suggestedActions: {
		id: 'servicedesk-ai-context.ops-panel.suggested-actions',
		defaultMessage: 'Actions',
		description: 'Heading for the suggested actions section',
	},
	noSuggestion: {
		id: 'servicedesk-ai-context.ops-panel.no-suggestion',
		defaultMessage: 'There are no suggested actions for this work item.',
		description: 'Empty state text when there are no suggestions',
	},
});
