import { createContext, type Context } from 'react';
import {
	markIssuePreviewRendered,
	markIssueInteractive,
	markIssueOnPageLoadEvent,
	setIsInitialRender,
	type PreviewMetricArgs,
} from '../index.tsx';

// Externally used, metrics object to define general callback
// functions for calculations (such as APDEX).
export type MetricsCallbacks = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onReadyForUser?: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onInteractive?: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onMarkIssueOnPageLoadEvent?: any;
};

// Internal use, will call relevant MetricsCallbacks functions at various
// Bento metrics stages
export type Metrics = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onPreviewRendered: any;
	onInteractive: (args: { lastTransitionStartTime: number | undefined }) => void;
	onMarkIssueOnPageLoadEvent: () => void;
};

const createOnPreviewRendered =
	(
		metrics?: MetricsCallbacks,
	): any => // eslint-disable-line @typescript-eslint/no-explicit-any
	(args: PreviewMetricArgs) => {
		if (metrics && metrics.onReadyForUser) {
			metrics.onReadyForUser();
		}

		const { isInitialRender, lastTransitionStartTime } = args;
		if (isInitialRender) {
			setIsInitialRender(true);
		}
		markIssuePreviewRendered({ isInitialRender, lastTransitionStartTime });
	};

const createOnInteractive =
	(metrics?: MetricsCallbacks) => (args: { lastTransitionStartTime: number | undefined }) => {
		if (metrics && metrics.onInteractive) {
			metrics.onInteractive();
		}
		markIssueInteractive(args);
	};

const createOnPageLoadEvent = (metrics?: MetricsCallbacks) => () => {
	if (metrics && metrics.onMarkIssueOnPageLoadEvent) {
		metrics.onMarkIssueOnPageLoadEvent();
	}

	markIssueOnPageLoadEvent();
};

export const createMetrics = (metrics?: MetricsCallbacks): Metrics => ({
	onPreviewRendered: createOnPreviewRendered(metrics),
	onInteractive: createOnInteractive(metrics),
	onMarkIssueOnPageLoadEvent: createOnPageLoadEvent(metrics),
});

/**
 * The Atlaskit modal dialog component (as of version 3.x) renders into a
 * custom portal implementation and does not pass the React context to the
 * header/footer/children.
 *
 * Until the component moves to using React native portals, we are waiting
 * to create the metrics context until we are given the MetricsCallbacks
 * object, and storing it as the contexts default value.
 *
 * In future we should be able to replace the create/get context methods
 * below with {export const MetricsContext = createContext();}.
 *
 * See: https://jdog.jira-dev.com/browse/BENTO-1069
 */
let MetricsContext: Context<Metrics>;

export const createMetricsContext = (metrics?: MetricsCallbacks) => {
	MetricsContext = createContext<Metrics>(createMetrics(metrics));
	return MetricsContext;
};

export const getMetricsContext = () => MetricsContext || createContext<Metrics>(createMetrics());
