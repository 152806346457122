import { useEnvironmentContainer } from './controllers/store/index.tsx';
import { PolarisEnvironmentContainerTypes } from './controllers/types.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	useEnvironmentContainer,
	useHasEnvironmentContainerError,
	useIsEnvironmentContainerAvailable,
} from './controllers/store/index.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	PolarisJiraSpaProjectEnvironmentProvider,
	PolarisCustomProjectEnvironmentContainerProvider,
} from './controllers/project/index.tsx';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { PolarisCustomEnvironmentContainerProvider } from './controllers/custom/index.tsx';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	PolarisEnvironmentContainerTypes,
	type PolarisEnvironmentContainer,
	type ContainerId,
} from './controllers/types';

/**
 * @deprecated useEnvironmentContainer instead, as JPD can be rendered within different data container contexts.\
 * A project is no longer guaranteed to be available
 */
export const useProjectKey = () => {
	const container = useEnvironmentContainer();

	if (container?.type === PolarisEnvironmentContainerTypes.PROJECT) {
		return container.projectKey;
	}

	return undefined;
};

/**
 * @deprecated useEnvironmentContainer instead, as JPD can be rendered within different data container contexts.\
 * A project is no longer guaranteed to be available
 */
export const useProjectKeyUnsafe = (): string => {
	const projectKey = useProjectKey();
	return projectKey || '';
};

/**
 * @deprecated useEnvironmentContainer instead, as JPD can be rendered within different data container contexts.\
 * A project is no longer guaranteed to be available
 */
export const useProjectId = () => {
	const container = useEnvironmentContainer();

	if (container?.type === PolarisEnvironmentContainerTypes.PROJECT) {
		return container.projectId;
	}

	return undefined;
};

/**
 * @deprecated useEnvironmentContainer instead, as JPD can be rendered within different data container contexts.\
 * A project is no longer guaranteed to be available
 */
export const useProjectIdUnsafe = (): string => {
	const projectId = useProjectId();
	return projectId || '';
};

/**
 * @deprecated useEnvironmentContainer instead, as JPD can be rendered within different data container contexts.\
 * A project is no longer guaranteed to be available
 */
export const useProjectAri = () => {
	const container = useEnvironmentContainer();

	if (container?.type === PolarisEnvironmentContainerTypes.PROJECT) {
		return container.projectAri.toString();
	}

	return undefined;
};

/**
 * @deprecated useEnvironmentContainer instead, as JPD can be rendered within different data container contexts.\
 * A project is no longer guaranteed to be available
 */
export const useProjectAriUnsafe = (fallbackAri = ''): string => {
	const projectAri = useProjectAri();
	return projectAri || fallbackAri;
};

export const useIsInCollectionContainer = () => {
	const container = useEnvironmentContainer();
	return container?.type === PolarisEnvironmentContainerTypes.COLLECTION;
};

export const useIsInSharingContainer = () => {
	const container = useEnvironmentContainer();
	return container?.type === PolarisEnvironmentContainerTypes.VIEW;
};
