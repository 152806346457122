import { findADate, checkIfValidId } from './field-supporters/index.tsx';

export const ECOSYSTEM_API_FIELD_FORMATTER = {
	security: (id: unknown, input: unknown) => {
		if (checkIfValidId(input)) {
			return {
				value: {
					securityLevelId: Number(input),
				},
				type: 'object',
			};
		}
		if (Array.isArray(input) && input.length > 0 && checkIfValidId(input[0])) {
			return {
				value: {
					securityLevelId: Number(input[0]),
				},
				type: 'object',
			};
		}
	},
	singleLineTextFields: (id: unknown, input: unknown) => {
		if (typeof input === 'string' || typeof input === 'number') {
			return {
				value: {
					fieldId: id,
					text: input.toString(),
				},
				type: 'array',
			};
		}
		if (Array.isArray(input) && input.length > 0) {
			return {
				value: {
					fieldId: id,
					text: input[0].toString(),
				},
				type: 'array',
			};
		}
	},
	timeTrackingField: (id: unknown, input: unknown) => {
		if (typeof input === 'string') {
			return {
				value: {
					originalEstimate: input,
				},
				type: 'object',
			};
		}
		if (Array.isArray(input) && input.length > 0 && typeof input[0] === 'string') {
			return {
				value: {
					originalEstimate: input[0],
				},
				type: 'object',
			};
		}
	},
	priority: (id: unknown, input: unknown) => {
		if (checkIfValidId(input)) {
			return {
				value: {
					priorityId: Number(input),
				},
				type: 'object',
			};
		}
		if (Array.isArray(input) && input.length > 0 && checkIfValidId(input[0])) {
			return {
				value: {
					priorityId: Number(input[0]),
				},
				type: 'object',
			};
		}
	},
	resolution: (id: unknown, input: unknown) => {
		if (checkIfValidId(input)) {
			return {
				value: {
					resolutionId: Number(input),
				},
				type: 'object',
			};
		}
		if (Array.isArray(input) && input.length > 0 && checkIfValidId(input[0])) {
			return {
				value: {
					resolutionId: Number(input[0]),
				},
				type: 'object',
			};
		}
		return {
			value: {
				resolutionId: 10000,
			},
			type: 'object',
		};
	},
	datePickerFields: (id: unknown, input: unknown) => {
		if (typeof input === 'string') {
			const dateInISO = findADate(input);
			if (dateInISO) {
				return {
					value: {
						fieldId: id,
						date: {
							formattedDate: dateInISO,
						},
					},
					type: 'array',
				};
			}
		}
		if (Array.isArray(input) && input.length > 0 && typeof input[0] === 'string') {
			const dateInISO = findADate(input[0]);
			if (dateInISO) {
				return {
					value: {
						fieldId: id,
						date: {
							formattedDate: dateInISO,
						},
					},
					type: 'array',
				};
			}
		}
	},
	dateTimePickerFields: (id: unknown, input: unknown) => {
		if (typeof input === 'string') {
			const dateInISO = findADate(input);
			if (dateInISO) {
				return {
					value: {
						fieldId: id,
						dateTime: {
							formattedDateTime: dateInISO,
						},
					},
					type: 'array',
				};
			}
		}
		if (Array.isArray(input) && input.length > 0 && typeof input[0] === 'string') {
			const dateInISO = findADate(input[0]);
			if (dateInISO) {
				return {
					value: {
						fieldId: id,
						dateTime: {
							formattedDateTime: dateInISO,
						},
					},
					type: 'array',
				};
			}
		}
	},
	labelsFields: (id: unknown, input: unknown) => {
		let transformedValue: { name: string | number }[] = [];
		if (Array.isArray(input) && input.length > 0) {
			for (const label of input)
				if (typeof label === 'string' || typeof label === 'number')
					transformedValue.push({ name: label.toString() });
		} else if (typeof input === 'string' || typeof input === 'number') {
			transformedValue = [{ name: input.toString() }];
		}
		return {
			value: {
				fieldId: id,
				labels: transformedValue,
			},
			type: 'array',
		};
	},
	multipleVersionPickerFields: (id: unknown, input: unknown) => {
		let transformedValue: { versionId: Number }[] = [];
		if (Array.isArray(input) && input.length > 0)
			for (const version of input) {
				if (checkIfValidId(version)) transformedValue.push({ versionId: Number(version) });
			}
		else if (checkIfValidId(input)) {
			transformedValue = [{ versionId: Number(input) }];
		}
		return {
			value: {
				fieldId: id,
				versions: transformedValue,
			},
			type: 'array',
		};
	},
	components: (id: unknown, input: unknown) => {
		let transformedValue: { componentId: Number }[] = [];
		if (Array.isArray(input) && input.length > 0)
			for (const component of input) {
				if (checkIfValidId(component)) transformedValue.push({ componentId: Number(component) });
			}
		else if (checkIfValidId(input)) {
			transformedValue = [{ componentId: Number(input) }];
		}
		return {
			value: transformedValue,
			type: 'object',
		};
	},
	parentField: (id: unknown, input: unknown) => {
		if (checkIfValidId(input)) {
			return {
				value: {
					issueId: Number(input),
				},
				type: 'object',
			};
		}
		if (Array.isArray(input) && input.length > 0 && checkIfValidId(input[0])) {
			return {
				value: {
					issueId: Number(input[0]),
				},
				type: 'object',
			};
		}
	},
} as const;

export const ECOSYSTEM_API_FIELD_MAPPER_MUTATION_GROUP = {
	security: 'security',
	resolution: 'resolution',
	summary: 'singleLineTextFields',
	epicName: 'singleLineTextFields',
	duedate: 'datePickerFields',
	startdate: 'datePickerFields',
	labels: 'labelsFields',
	fixVersions: 'multipleVersionPickerFields',
	versions: 'multipleVersionPickerFields',
	components: 'components',
	parent: 'parentField',
	priority: 'priority',
	timetracking_originalestimate: 'timeTrackingField',
} as const;
