/**
 * @generated SignedSource<<b070299cd48a9ca7e58ed5b6a8305caf>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type view_issueViewWatchers_Dropdown$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"actionButton_issueViewWatchers_ActionButtonWithRelay">;
  readonly " $fragmentType": "view_issueViewWatchers_Dropdown";
};
export type view_issueViewWatchers_Dropdown$key = {
  readonly " $data"?: view_issueViewWatchers_Dropdown$data;
  readonly " $fragmentSpreads": FragmentRefs<"view_issueViewWatchers_Dropdown">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "view_issueViewWatchers_Dropdown",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "kind": "FragmentSpread",
      "name": "actionButton_issueViewWatchers_ActionButtonWithRelay"
    }
  ],
  "type": "JiraWatchesField"
};

(node as any).hash = "a297614800e343ef4de43d5426e8a232";

export default node;
