import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	automation: {
		id: 'issue-field-status.status-view.actions.actions-menu.automation',
		defaultMessage: 'Automation',
		description: 'The label for the tooltip that appears when hovering over the automation button',
	},
	actions: {
		id: 'issue-field-status.status-view.actions.actions-menu.actions',
		defaultMessage: 'Actions',
		description:
			'Label for a dropdown menu that holds "actions" a user can execute based on the issue and workflow currently in context',
	},
	manualRules: {
		id: 'issue-field-status.status-view.actions.actions-menu.manual-rules',
		defaultMessage: 'From Automation',
		description:
			'Label for a section in a dropdown menu that holds "manual rules" a user can execute based on the issue currently in context',
	},
	workflowTransitions: {
		id: 'issue-field-status.status-view.actions.actions-menu.workflow-transitions',
		defaultMessage: 'From Workflow',
		description:
			'Label for a section in a dropdown menu that holds "Jira Workflow Transitions" a user can execute based on the issue currently in context',
	},
});
