import head from 'lodash/head';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types.tsx';

const getUpdatedState = (state: State, fieldKey: string, emoji: string | undefined) => ({
	...state,
	fields: {
		...state.fields,
		[fieldKey]: {
			...state.fields[fieldKey],
			emoji,
		},
	},
});

export const updateEmoji =
	(fieldKey: string, emoji: string | undefined, createAnalyticsEvent: CreateUIAnalyticsEvent) =>
	async (
		{ getState, setState }: StoreActionApi<State>,
		{ onFieldUpdateFailed, issueTypeIds, fieldRemote }: Props,
	) => {
		const state = getState();
		const field = state.fields[fieldKey];
		const oldEmoji = field.emoji;

		if (!field || field.emoji === emoji) {
			return;
		}

		const issueTypeId = head(issueTypeIds) ?? '';

		setState(getUpdatedState(state, fieldKey, emoji));

		try {
			await fieldRemote.updateFieldConfiguration({
				fieldKey,
				issueTypeId,
				oldConfiguration: oldEmoji ? { emoji: oldEmoji } : {},
				configuration: emoji ? { emoji } : {},
				type: 'updateEmoji',
			});

			fireCompoundAnalyticsEvent.FieldConfig.emojiChangedSuccess(createAnalyticsEvent({}), {
				issueFieldKey: fieldKey,
				issueFieldType: field.type,
			});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (err: any) {
			setState(getUpdatedState(getState(), fieldKey, oldEmoji));
			fireCompoundAnalyticsEvent.FieldConfig.emojiChangedFailure(createAnalyticsEvent({}));
			onFieldUpdateFailed(err);
		}
	};
