import React, { useState } from 'react';
import { graphql, useFragment } from 'react-relay';
import InlineEdit from '@atlaskit/inline-edit';
import { Box, xcss } from '@atlaskit/primitives';
import { FieldHeading } from '@atlassian/jira-issue-field-heading/src/index.tsx';
import {
	FieldWrapper,
	FieldHeadingTitle,
	SideBySideField,
} from '@atlassian/jira-issue-field-heading/src/styled.tsx';
import { FieldPin } from '@atlassian/jira-issue-field-pin/src/index.tsx';
import type { Area } from '@atlassian/jira-issue-view-common-types/src/connect-field-type.tsx';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button/index.tsx';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	SCREEN,
} from '@atlassian/jira-product-analytics-bridge';
import type { issueViewOrganizationField_servicedeskCustomerServiceCustomFields_OrganizationField$key } from '@atlassian/jira-relay/src/__generated__/issueViewOrganizationField_servicedeskCustomerServiceCustomFields_OrganizationField.graphql.ts';
import { ReadView } from '../../common/ui/organization-read-view/index.tsx';

export type Props = {
	area?: Area;
	fragmentKey: issueViewOrganizationField_servicedeskCustomerServiceCustomFields_OrganizationField$key;
};

export const OrganizationField = ({ area, fragmentKey }: Props) => {
	const [isEditing, setEditing] = useState<boolean>(false);

	const data =
		useFragment<issueViewOrganizationField_servicedeskCustomerServiceCustomFields_OrganizationField$key>(
			graphql`
				fragment issueViewOrganizationField_servicedeskCustomerServiceCustomFields_OrganizationField on JiraCustomerServiceOrganizationField {
					id
					name
					# eslint-disable-next-line @atlassian/relay/unused-fields # used by agg transformer
					type
					fieldId
					selectedOrganization {
						id
						organizationName
					}
				}
			`,
			fragmentKey,
		);

	const selectedOrganizationOption = data.selectedOrganization
		? {
				label: data.selectedOrganization.organizationName,
				value: data.selectedOrganization.id,
			}
		: undefined;

	// const { onChange, isInvalid } = useUpdateIssueViewEntitlementField({
	// 	setEditing,
	// 	id: data.id,
	// 	fieldId: data.fieldId,
	// 	fieldName: data.name,
	// });

	const showPinButton = getShowPinButton(area);

	return (
		<ContextualAnalyticsData sourceName="organizationFieldIssueView" sourceType={SCREEN}>
			<FieldWrapper>
				<FieldHeading>
					<Box xcss={titleStyles}>
						<FieldHeadingTitle>{data.name}</FieldHeadingTitle>
						{showPinButton === true && <FieldPin fieldId={data.fieldId} label={data.name} />}
					</Box>
				</FieldHeading>
				<SideBySideField>
					<Box xcss={contentStyles}>
						<FireScreenAnalytics
							attributes={{
								organizationFieldViewed: 'IssueView',
							}}
						/>
						<InlineEdit
							isEditing={isEditing}
							defaultValue={selectedOrganizationOption}
							// TODO implement edit view
							editView={() => <>Edit view placeholder</>}
							readView={() => <ReadView name={selectedOrganizationOption?.label || undefined} />}
							onCancel={() => setEditing(false)}
							onConfirm={() => setEditing(false)}
							onEdit={() => setEditing(true)}
							readViewFitContainerWidth
							hideActionButtons
						/>
					</Box>
				</SideBySideField>
			</FieldWrapper>
		</ContextualAnalyticsData>
	);
};

const contentStyles = xcss({
	paddingRight: 'space.100',
	marginLeft: 'space.negative.100',
	marginTop: 'space.negative.050',
});

const titleStyles = xcss({
	display: 'flex',
});
