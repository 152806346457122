import React from 'react';
import type { CustomGlyphProps } from '@atlaskit/icon';

export const chevronUpGlyph = (props: CustomGlyphProps) => (
	<svg
		{...props}
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10.8314 8.48301L6.43781 12.9157C6.15759 13.199 6.00043 13.5814 6.00043 13.9799C6.00043 14.3784 6.15759 14.7609 6.43781 15.0442C6.57543 15.184 6.73947 15.2949 6.92038 15.3707C7.1013 15.4464 7.29547 15.4854 7.49159 15.4854C7.68772 15.4854 7.88189 15.4464 8.0628 15.3707C8.24371 15.2949 8.40775 15.184 8.54537 15.0442L11.9925 11.5686L15.4531 15.0592C15.5911 15.1989 15.7554 15.3097 15.9365 15.3854C16.1177 15.4611 16.3121 15.5 16.5084 15.5C16.7047 15.5 16.899 15.4611 17.0802 15.3854C17.2613 15.3097 17.4257 15.1989 17.5637 15.0592C17.8435 14.7757 18.0004 14.3933 18.0004 13.9949C18.0004 13.5965 17.8435 13.2142 17.5637 12.9307L13.1535 8.48301C12.8434 8.17444 12.424 8.00084 11.9865 8C11.568 8 11.151 8.1605 10.8314 8.48301Z"
			fill="currentColor"
		/>
	</svg>
);
