import { di } from 'react-magnetic-di';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';

declare let window: {
	location: {
		protocol: string;
		host: string;
	};
};

/**
 * @returns the absolute URL for the current issue.
 * @example https://jdog.jira-dev.com/browse/MAGMA-215
 */
export const useIssueAbsoluteUrl = (): string => {
	di(window);
	const issueKey = useIssueKey();

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	return `${window.location.protocol}//${window.location.host}/browse/${issueKey}`;
};
