import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noEligibleBranchesFound: {
		id: 'development-summary-pull-request.create-pull-request-summary-item.create-pull-request-dropdown.no-eligible-branches.no-eligible-branches-found',
		defaultMessage:
			"We can't find any links to create a pull request. You'll need to create a pull request directly in your development tool.",
		description:
			'Error message when the developent summary has branches but no url was supplied to create a pull request for them.',
	},
});
