import React, { useState, memo, useEffect, type FC } from 'react';
import ArrowLeftIcon from '@atlaskit/icon/core/migration/arrow-left';
import { ConnectIframe } from '@atlassian/connect-module-core';
import { useDefaultConnectIframeProvider } from '@atlassian/jira-common-ecosystem/src/index.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { EcosystemTimeoutViewAsync } from '@atlassian/jira-connect-utils-async/src/async.tsx';
import { withConnectHost } from '@atlassian/jira-connect-utils/src/common/utils/ecosystem-connect-host.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import { TASK_SUCCESS } from '@atlassian/jira-experience-tracker/src/common/constants.tsx';
import { useIssueId, useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useOnDemandIssueRefreshTime } from '@atlassian/jira-issue-refresh-service/src/services/main.tsx';
import { sendExperienceAnalytics } from '@atlassian/jira-issue-view-analytics/src/controllers/send-experience-analytics/index.tsx';
import {
	useEcosystemGlancePanel,
	useIsEcosystemDataLoadedFor,
} from '@atlassian/jira-issue-view-ecosystem-service/src/services/main.tsx';
import { useIssueLayoutActions } from '@atlassian/jira-issue-view-layout/src/services/main.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { statusCategorySelector } from '@atlassian/jira-issue-view-store/src/selectors/status-selector.tsx';
import { MountEvent } from '@atlassian/jira-product-analytics-bridge';
import {
	useProjectKey,
	useApplication,
	useEdition,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import type { Application } from '@atlassian/jira-shared-types/src/application.tsx';
import type { ApplicationEdition } from '@atlassian/jira-shared-types/src/edition.tsx';
import {
	HeaderContentContainer,
	HeaderContainer,
	RoundButton,
	PanelContentContainerInner,
} from '../../context-panel/view/styled.tsx';
import { HeaderBreadcrumb } from '../../side-panel/styled.tsx';
import { PanelContents } from '../common/styled-glance-panel.tsx';

type OwnProps = {
	appKey: string;
	moduleKey: string;
	styles?: {
		headerWidth?: number | undefined;
		contentWidth?: number | undefined;
	};
};

type Props = OwnProps & {
	statusCategory: string | null;
};

export const glancePanelPropsAreEqual = (prevProps: Props, nextProps: Props) => {
	const hasAppKeyChanged = prevProps.appKey !== nextProps.appKey;
	const hasModuleKeyChanged = prevProps.moduleKey !== nextProps.moduleKey;
	const hasStatusChanged = prevProps.statusCategory !== nextProps.statusCategory;
	const hasStylesRefreshed = prevProps.styles !== nextProps.styles;

	return !(hasAppKeyChanged || hasModuleKeyChanged || hasStatusChanged || hasStylesRefreshed);
};

export const NakedGlancePanel: FC<Props> = ({ appKey, moduleKey, styles = {}, statusCategory }) => {
	const refreshedOn = useOnDemandIssueRefreshTime();

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const [connectHost, setConnectHost] = useState(window.connectHost);

	const issueKey = useIssueKey();
	const [, { setIssueViewLayoutGlance }] = useIssueLayoutActions();

	const glance = useEcosystemGlancePanel(appKey, moduleKey);

	const projectKey = useProjectKey(issueKey);
	const application: Application | undefined = useApplication(projectKey, true);
	const edition: ApplicationEdition | undefined = useEdition(projectKey, true);

	useEffect(() => {
		if (!connectHost) {
			log.safeInfoWithoutCustomerData(
				'issue.ecosystem.glance.panel',
				'Delayed initialization of ecosystem glance panel',
			);
			withConnectHost((host) => setConnectHost(host));
		}
	}, [connectHost]);

	const handleHideGlancePanel = () => {
		setIssueViewLayoutGlance(issueKey, undefined);
	};

	const connectIframeProvider = useDefaultConnectIframeProvider({
		isAutoresizeBugfixEnabled: true,
	});

	const issueId = useIssueId();
	const hasCorrectIssueInformation = useIsEcosystemDataLoadedFor(useIssueKey());

	if (!glance) {
		return null;
	}

	const {
		options: { origin },
	} = glance;

	// there is a situation when during SPA transition issueId is yet undefined
	const renderUnblocked = connectHost && Boolean(issueId) && hasCorrectIssueInformation;

	return (
		<ErrorBoundary
			id="issue-view.context.ecosystem-glance-panel"
			packageName="jiraIssueViewBase"
			onError={(location, error) => {
				sendExperienceAnalytics({
					experience: 'issueViewConnectGlancePanelRender',
					wasExperienceSuccesful: false,
					analyticsSource: 'issueViewConnectGlancePanelView',
					application: application ?? null,
					edition: edition ?? null,
					additionalAttributes: {
						location,
						errorMessage: error.message,
					},
				});
			}}
		>
			<HeaderContainer>
				<HeaderContentContainer
					maxWidth={styles.headerWidth}
					onClick={handleHideGlancePanel}
					tabIndex={0}
				>
					<RoundButton>
						<ArrowLeftIcon
							color="currentColor"
							spacing="spacious"
							LEGACY_size="medium"
							label="Return"
						/>
					</RoundButton>
					<HeaderBreadcrumb>{glance.name}</HeaderBreadcrumb>
				</HeaderContentContainer>
			</HeaderContainer>
			{renderUnblocked && (
				<PanelContents panelWidth={styles.contentWidth}>
					<PanelContentContainerInner maxWidth={styles.contentWidth}>
						<ConnectIframe
							key={`connect-iFrame-${origin}-${issueId}-${statusCategory}-${refreshedOn}`}
							// setting data testid same as key here to assert in unit tests as key is not accessible
							data-testid={`connect-iFrame-${origin}-${issueId}-${statusCategory}-${refreshedOn}`}
							connectHost={connectHost}
							appKey={appKey}
							moduleKey={moduleKey}
							url={glance.options.url}
							options={connectIframeProvider.convertConnectOptions(glance.options)}
							height="100%"
							width="100%"
							connectIframeProvider={connectIframeProvider}
							// @ts-expect-error - TS2322 - Type '() => JSX.Element' is not assignable to type 'new () => PureComponent<{ failedCallback: Function; }, {}, any>'.
							timeoutIndicator={() => (
								<EcosystemTimeoutViewAsync appName={glance.name} appKey={appKey} />
							)}
						/>
					</PanelContentContainerInner>
				</PanelContents>
			)}
			<MountEvent
				onMount={() =>
					sendExperienceAnalytics({
						experience: 'issueViewConnectGlancePanelRender',
						analyticsSource: 'issueViewConnectGlancePanelView',
						application: application ?? null,
						edition: edition ?? null,
						wasExperienceSuccesful: true,
						action: TASK_SUCCESS,
					})
				}
			/>
		</ErrorBoundary>
	);
};

export const GlancePanel = memo(NakedGlancePanel, glancePanelPropsAreEqual);

export const ConnectGlancePanel = connect((state) => ({
	statusCategory: statusCategorySelector(state),
}))(GlancePanel);
