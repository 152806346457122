import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/switchMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { trackOrLogClientError } from '@atlassian/jira-issue-view-common-utils/src/errors/index.tsx';
import {
	APPLINK_AUTHENTICATION_SUCCESS,
	type ApplinkAuthenticationSuccess,
} from '@atlassian/jira-issue-view-store/src/actions/applink-authentication-actions.tsx';
import {
	FETCH_JIRA_APP_LINKS_REQUEST,
	fetchJiraAppLinksFailed,
	type FetchJiraAppLinksRequest,
	fetchJiraAppLinksSuccess,
} from '@atlassian/jira-issue-view-store/src/actions/fetch-jira-app-links-actions.tsx';
import {
	baseUrlSelector,
	issueKeySelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import fetchJiraAppLinks from './fetch-jira-app-links-server.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	action$: ActionsObservable<FetchJiraAppLinksRequest | ApplinkAuthenticationSuccess>,
	store: MiddlewareAPI<State>,
) =>
	action$.ofType(FETCH_JIRA_APP_LINKS_REQUEST, APPLINK_AUTHENTICATION_SUCCESS).switchMap(() => {
		const state = store.getState();
		const baseUrl = baseUrlSelector(state);
		const issueKey = issueKeySelector(state);

		// @ts-expect-error - TS2684 - The 'this' context of type 'Observable<unknown>' is not assignable to method's 'this' of type 'Observable<JiraAppLinksResponse>'.
		return fetchJiraAppLinks(baseUrl, issueKey)
			.map(fetchJiraAppLinksSuccess)
			.catch((error) => {
				trackOrLogClientError(
					'issue.fetch.jira-app-links-epic',
					'FETCH_JIRA_APP_LINKS_REQUEST: Failed to fetch jira app links data',
					error,
				);
				return Observable.of(fetchJiraAppLinksFailed());
			});
	});
