import React, { type ReactNode } from 'react';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import Context from '../common/context/index.tsx';
import Consumer from './view.tsx';

type Props = {
	isEditing: boolean;
	field: string;
	children: ReactNode;
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (props: Props) => (
	// We need `onFailure` to be available in the experience tracker's
	// lifecycle methods. The version of React we're on doesn't support `Class.contextType`,
	// (v16.6.0 needed) so we're using a wrapper component to achieve this instead.
	<Context.Consumer>
		{(context) => {
			if (context === null) {
				log.safeErrorWithoutCustomerData(
					'edit-experience-tracker.missing-provider',
					'Edit experience tracker rendered without a provider',
				);
				return props.children;
			}

			return (
				<Consumer field={props.field} isEditing={props.isEditing} onFailure={context.onFailure}>
					{props.children}
				</Consumer>
			);
		}}
	</Context.Consumer>
);
