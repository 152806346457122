import React, { useState } from 'react';
import { IconButton } from '@atlaskit/button/new';
import AddIcon from '@atlaskit/icon/glyph/add';
import OpenIcon from '@atlaskit/icon/glyph/open';
import SettingsIcon from '@atlaskit/icon/glyph/settings';
import { ButtonItem, MenuGroup, Section } from '@atlaskit/menu';
import { Popup } from '@atlaskit/popup';
import { useIntl } from '@atlassian/jira-intl';
import { useCustomFieldsConfigurationExperiment } from '@atlassian/jira-issue-field-edit-icon/src/controllers/useCustomFieldsConfigurationExperiment.tsx';
import useConfigurationMode from '@atlassian/jira-issue-view-foundation/src/utils/config-link-mode.tsx';
import useConfigurationHref from '@atlassian/jira-issue-view-foundation/src/utils/get-configuration-href.tsx';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	DROPDOWN,
	useAnalyticsEvents,
	fireScreenAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { useRouterActions } from '@atlassian/react-resource-router';
import messages from './messages.tsx';

const CustomSettingsIcon = () => <SettingsIcon size="small" label="Icon" />;

export const IssueDetailsSettingsPopover = ({
	onCreateFieldClick,
}: {
	onCreateFieldClick: () => void;
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const { formatMessage } = useIntl();
	const isCustomFieldConfigurationEnabled = useCustomFieldsConfigurationExperiment();
	const configurationHref = useConfigurationHref(formatMessage);
	const { push } = useRouterActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const mode = useConfigurationMode();

	if (!isCustomFieldConfigurationEnabled || mode !== 'show') {
		return null;
	}

	return (
		<Popup
			isOpen={isOpen}
			placement="bottom-start"
			shouldRenderToParent
			trigger={(props) => (
				<IconButton
					{...props}
					onClick={(event) => {
						event.preventDefault();
						event.stopPropagation();
						setIsOpen(!isOpen);
					}}
					icon={CustomSettingsIcon}
					spacing="compact"
					label={formatMessage(messages.configureFieldsButtonLabel)}
					isSelected={isOpen}
				/>
			)}
			content={() => (
				<ContextualAnalyticsData sourceName="issueDetailsSettingsPopover" sourceType={DROPDOWN}>
					<MenuGroup>
						<Section title="Actions">
							<ButtonItem
								onClick={(event) => {
									event.preventDefault();
									event.stopPropagation();
									onCreateFieldClick();
									const screenEvent = createAnalyticsEvent({
										screenName: 'issueDetailsSettingsPopover',
									});
									fireScreenAnalytics(screenEvent, {
										buttonName: 'createField',
									});
								}}
								iconBefore={<AddIcon label="add" size="small" />}
							>
								{formatMessage(messages.createField)}
							</ButtonItem>
						</Section>
						<Section>
							<ButtonItem
								onClick={(event) => {
									event.preventDefault();
									event.stopPropagation();
									push(configurationHref);
									const screenEvent = createAnalyticsEvent({
										screenName: 'issueDetailsSettingsPopover',
									});
									fireScreenAnalytics(screenEvent, {
										buttonName: 'manageFields',
									});
								}}
								iconBefore={<OpenIcon label="open" size="small" />}
							>
								{formatMessage(messages.manageFields)}
							</ButtonItem>
						</Section>
						<FireScreenAnalytics />
					</MenuGroup>
				</ContextualAnalyticsData>
			)}
		/>
	);
};
