import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';

export const getStatusTransitions = async (issueKey: IssueKey) => {
	try {
		return await fetchJson(`/rest/api/2/issue/${issueKey}/transitions?sortByOpsBarAndStatus=true`);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		log.safeErrorWithoutCustomerData('issue.status-field.fetch-transitions', error.toString());
		return null;
	}
};
