import React from 'react';
import type { CustomGlyphProps } from '@atlaskit/icon';

export const ratingGlyph = (props: CustomGlyphProps) => (
	<svg
		{...props}
		width="16"
		height="16"
		viewBox="4 4 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M17.25 6C18.2165 6 19 6.7835 19 7.75V16.5C19 17.4665 18.2165 18.25 17.25 18.25C16.2835 18.25 15.5 17.4665 15.5 16.5V7.75C15.5 6.7835 16.2835 6 17.25 6ZM13.75 11.25C13.75 10.2835 12.9665 9.5 12 9.5C11.0335 9.5 10.25 10.2835 10.25 11.25V16.5C10.25 17.4665 11.0335 18.25 12 18.25C12.9665 18.25 13.75 17.4665 13.75 16.5V11.25ZM8.5 14.75C8.5 13.7835 7.7165 13 6.75 13C5.7835 13 5 13.7835 5 14.75V16.5C5 17.4665 5.7835 18.25 6.75 18.25C7.7165 18.25 8.5 17.4665 8.5 16.5V14.75Z"
			fill="currentColor"
		/>
	</svg>
);
