import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	remoteIssueUneditableStatusTooltip: {
		id: 'issue-line-card.status.remote-issue-uneditable-status-tooltip',
		defaultMessage:
			"Remote issue can't be transitioned here. Select to view the issue, then change the status.",
		description:
			'A tooltip text to notify user that the status in remote issue link is not editable.',
	},
});
