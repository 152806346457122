import {
	type Validators,
	LOADING,
	FAILED,
	LOADED,
} from '@atlassian/jira-issue-view-common-types/src/project-validator-type.tsx';
import { type SetContext, SET_CONTEXT } from '../actions/context-actions.tsx';
import {
	FETCH_PROJECT_HAS_SUBTASK_VALIDATORS_SUCCESS,
	FETCH_PROJECT_HAS_SUBTASK_VALIDATORS_FAILURE,
	FETCH_PROJECT_HAS_SUBTASK_VALIDATORS,
	type FetchProjectHasSubtaskValidatorsAction,
	type FetchProjectHasSubtaskValidatorsSuccessAction,
	type FetchProjectHasSubtaskValidatorsFailureAction,
} from '../actions/project-validator-actions.tsx';

type Action =
	| SetContext
	| FetchProjectHasSubtaskValidatorsAction
	| FetchProjectHasSubtaskValidatorsSuccessAction
	| FetchProjectHasSubtaskValidatorsFailureAction;

const initialState: Validators = {
	projectHasSubtaskValidators: {
		status: LOADING,
	},
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: Validators = initialState, action: Action) => {
	switch (action.type) {
		case SET_CONTEXT:
			return state;
		case FETCH_PROJECT_HAS_SUBTASK_VALIDATORS:
			return {
				...state,
				projectHasSubtaskValidators: { status: LOADING },
			};

		case FETCH_PROJECT_HAS_SUBTASK_VALIDATORS_SUCCESS:
			return {
				...state,
				projectHasSubtaskValidators: {
					status: LOADED,
					value: action.payload,
				},
			};
		case FETCH_PROJECT_HAS_SUBTASK_VALIDATORS_FAILURE:
			return {
				...state,
				projectHasSubtaskValidators: { status: FAILED },
			};
		default:
			return state;
	}
};
