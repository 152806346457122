import React from 'react';
import { useFragment, graphql } from 'react-relay';
import { EmptyFieldText } from '@atlassian/jira-issue-empty-field-text/src/ui/index.tsx';
import type {
	cascadingSelect_issueFieldCascadingSelectReadviewFull_CascadingSelectReadView$key as CascadingSelectFragment,
	cascadingSelect_issueFieldCascadingSelectReadviewFull_CascadingSelectReadView$data as AggCascadingSelectTypes,
} from '@atlassian/jira-relay/src/__generated__/cascadingSelect_issueFieldCascadingSelectReadviewFull_CascadingSelectReadView.graphql';
import type { CascadingSelectReadViewProps } from './types.tsx';

const getFieldValueAsText = (cascadingOption: AggCascadingSelectTypes['cascadingOption']) => {
	if (!cascadingOption || !cascadingOption?.parentOptionValue) {
		return null;
	}

	if (!cascadingOption?.childOptionValue) {
		return cascadingOption.parentOptionValue.value;
	}

	return `${cascadingOption.parentOptionValue.value} - ${cascadingOption.childOptionValue.value}`;
};

/**
 * The CascadingSelectReadView will show a read only view of CascadingSelect field.
 * @param props [CascadingSelectReadViewProps](./types.tsx)
 */
export const CascadingSelectReadView = ({ fragmentRef }: CascadingSelectReadViewProps) => {
	const { cascadingOption } = useFragment<CascadingSelectFragment>(
		graphql`
			fragment cascadingSelect_issueFieldCascadingSelectReadviewFull_CascadingSelectReadView on JiraCascadingSelectField {
				cascadingOption {
					parentOptionValue {
						value
					}
					childOptionValue {
						value
					}
				}
			}
		`,
		fragmentRef,
	);

	const fieldValueAsText = getFieldValueAsText(cascadingOption);

	if (!fieldValueAsText) {
		return <EmptyFieldText />;
	}

	return <>{fieldValueAsText}</>;
};
