import { fetchJson } from '@atlassian/jira-fetch/src/index.tsx';
import type { IncidentSuggestion } from '../../common/types.tsx';
import type { IncidentSuggestionRequestParams } from './types.tsx';
import { transformResponse } from './utils.tsx';

export const getIncidentSuggestionUrl = ({
	cloudId,
	issueId,
	projectId,
}: IncidentSuggestionRequestParams): string =>
	`/gateway/api/jsm/aiops/cloudId/${cloudId}/api/v1/incident-suggestion/${issueId}?projectId=${projectId}
`;

export const getIncidentSuggestionEndpoint = (
	reqParams: IncidentSuggestionRequestParams,
): Promise<IncidentSuggestion> => {
	const url = getIncidentSuggestionUrl(reqParams);
	return fetchJson(url, {
		method: 'GET',
	}).then(transformResponse);
};
