import React from 'react';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyAfterPaint } from '@atlassian/react-loosely-lazy';
import type { TeamProfileCardNextProps } from './types.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const AsyncTeamProfileCardNext = lazyAfterPaint(
	() => import(/* webpackChunkName: "async-team-profilecard-next" */ './main'),
	{ ssr: false },
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (props: TeamProfileCardNextProps) => (
	<ErrorBoundary
		id="async.profilecard-next.team-profilecard"
		packageName="async.profilecard-next.team-profilecard"
	>
		<Placeholder name="async-team-profilecard-next" fallback={<>{props.children}</>}>
			<AsyncTeamProfileCardNext {...props} />
		</Placeholder>
	</ErrorBoundary>
);
