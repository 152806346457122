import React, { memo } from 'react';
import Link from '@atlaskit/link';
import { FormattedMessage } from '@atlassian/jira-intl';
import messages from '../messages.tsx';

export const ResearchLabel = memo(() => (
	<p>
		<FormattedMessage
			{...messages.enrollInResearchPrompt}
			values={{
				link: (chunks) => (
					<Link
						href="https://www.atlassian.com/research-group"
						target="_blank"
						rel="noopener noreferrer"
					>
						{chunks}
					</Link>
				),
			}}
		/>
	</p>
));
