import kebabCase from 'lodash/kebabCase';
import truncate from 'lodash/truncate';
import {
	FORBIDDEN_GIT_BRANCH_EXPRESSIONS,
	GIT_CHECKOUT_NEW_BRANCH,
} from '../../../../common/constants.tsx';
import {
	type UserBranchSettings,
	PlaceholderEnum,
	BranchTemplateType,
} from '../../../../common/types.tsx';
import { getBranchSettings } from '../../../../controllers/branch-command-builder-store/actions/index.tsx';

const getTruncatedIssueTitle = (issueTitle: string) => {
	const maxLengthToTruncate = 30;
	if (issueTitle.length <= maxLengthToTruncate) return issueTitle;
	// truncate without cutting a word
	return truncate(issueTitle, {
		length: maxLengthToTruncate,
		omission: '',
		separator: /\s/,
	});
};

const sanitizeBranchName = (branchName: string) =>
	branchName.trim().replace(FORBIDDEN_GIT_BRANCH_EXPRESSIONS, '-').replace(/-{2,}/g, '-');
export const getGitCommand = (
	userBranchSettings: UserBranchSettings,
	projectKey: string,
	issueKey: string,
	issueTitle: string,
	issueType: string,
): { gitCommand: string; gitCommandLength: number } => {
	const templateReplacements = {
		[PlaceholderEnum.ISSUE_KEY]: issueKey,
		[PlaceholderEnum.ISSUE_SUMMARY]: kebabCase(issueTitle),
		[PlaceholderEnum.ISSUE_SUMMARY_SHORT]: kebabCase(getTruncatedIssueTitle(issueTitle)),
		[PlaceholderEnum.ISSUE_TYPE]: issueType?.toLowerCase(),
		[PlaceholderEnum.PROJECT_KEY]: projectKey,
	};

	const { branchNameTemplate } = getBranchSettings(userBranchSettings);

	let branchName = '';

	branchNameTemplate.forEach((template) => {
		if (template.type === BranchTemplateType.PLACEHOLDER) {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			const index = template.value as PlaceholderEnum;
			const translatedValue = templateReplacements[index];
			branchName += translatedValue;
		} else {
			branchName += template.value;
		}
	});

	// Replace spaces from any other template such as issue type
	const sanitizedBranchName = sanitizeBranchName(branchName);
	const gitCommandLength = sanitizedBranchName.length;
	const gitCommand = `${GIT_CHECKOUT_NEW_BRANCH} ${sanitizedBranchName}`;

	return { gitCommand, gitCommandLength };
};
