/**
 * @generated SignedSource<<91093e58067a9355a8682f5fbe6db9ff>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ufoDataFragment_issueSelectableFieldEditView_UFOCustomDataFragment$data = {
  readonly __typename: string;
  readonly type: string;
  readonly " $fragmentType": "ufoDataFragment_issueSelectableFieldEditView_UFOCustomDataFragment";
};
export type ufoDataFragment_issueSelectableFieldEditView_UFOCustomDataFragment$key = {
  readonly " $data"?: ufoDataFragment_issueSelectableFieldEditView_UFOCustomDataFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ufoDataFragment_issueSelectableFieldEditView_UFOCustomDataFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ufoDataFragment_issueSelectableFieldEditView_UFOCustomDataFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__typename"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    }
  ],
  "type": "JiraIssueField",
  "abstractKey": "__isJiraIssueField"
};

(node as any).hash = "7190d14e7ff553efafa184ffb0b610f0";

export default node;
