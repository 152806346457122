import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';

interface LicenseInformationResponse {
	products: {
		[productKey: string]: {
			state?: 'ACTIVE';
		};
	};
}

export const fetchLicenseInformation = (cloudId: string) =>
	fetchJson<LicenseInformationResponse>(`/gateway/api/xflow/${cloudId}/license-information`);
