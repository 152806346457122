import { useEffect, useState } from 'react';

type Props = {
	ref: React.RefObject<HTMLElement>;
	delay: number;
};

/**
 * Hook to track dwell time on an element. Dwell time is the time the user spends or hovers on an element.
 * @param ref - The ref of the element to track.
 * @param delay - The delay in milliseconds before the dwell is considered active.
 */
export const useDwell = ({ ref, delay }: Props) => {
	const [dwell, setDwell] = useState(false);

	useEffect(() => {
		let timer: ReturnType<typeof setTimeout>;
		const handleMouseEnter = () => {
			timer = setTimeout(() => {
				setDwell(true);
			}, delay);
		};

		const handleMouseLeave = () => {
			clearTimeout(timer);
			setDwell(false);
		};

		const element = ref.current;
		if (element) {
			element.addEventListener('mouseenter', handleMouseEnter);
			element.addEventListener('mouseleave', handleMouseLeave);
		}

		return () => {
			if (element) {
				element.removeEventListener('mouseenter', handleMouseEnter);
				element.removeEventListener('mouseleave', handleMouseLeave);
			}
		};
	}, [ref, delay]);

	return dwell;
};
