import queryString from 'query-string';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';

export const getRapidViewIdFromUrl = (): number =>
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	queryString.parse(window.location.search).rapidView;

export const getLegacyIssueViewUrl = () => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const params = queryString.parse(window.location.search);
	params.oldIssueView = 'true';

	/* eslint-disable jira/jira-ssr/no-unchecked-globals-usage */
	return `${window.location.protocol}//${window.location.host}${
		window.location.pathname
	}?${queryString.stringify(params)}${window.location.hash}`;
};

export const addViewportMetaTag = () => {
	try {
		const existingMetaTag = document.querySelector('meta[name="viewport"]');

		if (existingMetaTag || typeof document === 'undefined') {
			return;
		}

		const meta = document.createElement('meta');
		meta.setAttribute('name', 'viewport');
		meta.setAttribute('content', 'width=device-width, initial-scale=1.0');

		document.head?.appendChild(meta);
	} catch (error) {
		fireErrorAnalytics({
			error: new Error('Failed to add viewport meta tag', {
				cause: error,
			}),
			meta: {
				id: 'addViewportMetaTag',
				packageName: 'spa-apps.issue',
				teamName: 'jlove-makkuro',
			},
			sendToPrivacyUnsafeSplunk: true,
		});
	}
};
