import React, { memo, useMemo } from 'react';
import { useContextStateIssueAri, useReactionsStateIsActive } from '../../../state/context.tsx';
import { CommentReactions } from './main.tsx';

const ConnectedCommentReactions = memo(() => {
	const [{ issueAri, commentAri }] = useContextStateIssueAri();
	const [{ isActive }] = useReactionsStateIsActive();

	const props = useMemo(
		() => ({
			visible: isActive,
			issueAri,
			commentAri,
		}),
		[isActive, issueAri, commentAri],
	);

	const MemoizedCommentReactions = memo(CommentReactions);

	return <MemoizedCommentReactions {...props} />;
});

export default ConnectedCommentReactions;
