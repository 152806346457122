import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'autodev-draft-pr.title',
		defaultMessage: 'FF Cleanup Agent',
		description:
			'This is the title of the dropdown on the right hand side of the issue view to ask AI to try to create a draft PR for this issue',
	},
	inProgress: {
		id: 'autodev-draft-pr.in-progress',
		defaultMessage: 'In Progress',
		description:
			'This is the status of the Create Draft PR action shown next to the dropdown title showing the status of the Draft PR as being In Progress',
	},
	failed: {
		id: 'autodev-draft-pr.failed',
		defaultMessage: 'Failed',
		description:
			'This is the status of the Create Draft PR action shown next to the dropdown title showing the status of the Draft PR as being unsuccessful',
	},
	successNonFinal: {
		id: 'autodev-draft-pr.success-non-final',
		defaultMessage: 'Success',
		description:
			'This is the status of the Create Draft PR action shown next to the dropdown title showing the status of the Draft PR as being successful',
	},
	permissionFailure: {
		id: 'autodev-draft-pr.permission-failure',
		defaultMessage: 'Repository Access Required',
		description:
			'This is the status of the Create Draft PR action shown next to the dropdown title showing the status of the Draft PR as being unsuccessful',
	},
	ineligible: {
		id: 'autodev-draft-pr.ineligible',
		defaultMessage: 'Ineligible',
		description:
			'This is the status of the Create Draft PR action shown next to the dropdown title showing the status of the Draft PR as being ineligible for the FF Cleanup Agent',
	},
});
