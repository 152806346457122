import type { CustomFieldTypes, SystemFieldTypes } from './field-type-type.tsx';

type FieldSchemaItems =
	| 'string'
	| 'group'
	| 'component'
	| 'issuelinks'
	| 'option'
	| 'sd-asset'
	| 'version'
	| 'sd-customerorganization'
	| 'sd-customerrequesttype'
	| 'service-entity-field'
	| 'user'
	| 'json'
	| 'team'
	| 'option2'
	| 'cmdb-object-field';

// Possible values of renderer in FieldSchema
export const RICH_CONTENT = 'atlassian-wiki-renderer' as const;
export const PLAIN_TEXT = 'jira-text-renderer' as const;
export const FROTHER = 'frother-control-renderer' as const;

type FieldSchemaConfiguration = {
	[key: string]: string;
}[];

// TODO: Clean up after properly defining issue field types
type NonSpecificFieldType = string | null;

// TODO: Use `SupportedFieldTypes` instead of `any` after properly defining issue field types
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface FieldSchema<T = any> {
	// TODO: Use `null` for the falsey case since we can only have one or the other
	custom: T extends CustomFieldTypes ? T : NonSpecificFieldType;
	// TODO: Use `null` for the falsey case since we can only have one or the other
	system: T extends SystemFieldTypes ? T : NonSpecificFieldType;
	type: string;
	configuration?: FieldSchemaConfiguration | null;
	customId?: number | null;
	items?: FieldSchemaItems | null;
	renderer?: string | null;
}
