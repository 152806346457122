// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	downloadAll: {
		id: 'attachments.heading.download-all',
		defaultMessage: 'Download all',
		description: 'The dropdown item to download all attachments as a zip file',
	},
	downloading: {
		id: 'attachments.heading.downloading',
		defaultMessage: 'Downloading attachments',
		description: 'The dropdown item that shows user that they are downloading attachments',
	},
});
