import {
	componentsRequiredScopes,
	type ComponentsPublicSetValue,
	type ComponentsPublicGetValues,
} from '@atlassian/jira-ui-modifications-common/src/common/utils/common/fields/components.tsx';
import { commonIssueViewGranularScopes } from './fields-scopes.tsx';

export const componentsIssueViewScopes = {
	...componentsRequiredScopes,
	granular: [...componentsRequiredScopes.granular, ...commonIssueViewGranularScopes],
};

type ComponentsInternalIdShapeValue = {
	id: string;
}[];

export const transformComponentsGetValue = (
	internalValue: ComponentsPublicGetValues,
): ComponentsPublicGetValues => {
	if (!internalValue) {
		return [];
	}

	return internalValue.map(({ id, name }) => ({
		id,
		name,
	}));
};

export const transformComponentsSetValue = (
	publicValues: ComponentsPublicSetValue,
): ComponentsInternalIdShapeValue => {
	if (!publicValues) {
		return [];
	}

	return publicValues.map((id) => ({ id }));
};
