import React, { useCallback } from 'react';
import { ButtonItem } from '@atlaskit/menu';
import { useOptionallyControlledEditingState } from '@atlassian/jira-issue-field-optional-editing-state-manager/src/index.tsx';
import { ISSUE_RESTRICTION_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { Props } from './types.tsx';

export const RestrictIssueButtonItem = ({
	onClick,
	label,
	setDropdownItemRef,
	itemKey,
	isSelected = false,
}: Props) => {
	const [, setIsIssueRestrictOpen] = useOptionallyControlledEditingState(
		false,
		ISSUE_RESTRICTION_TYPE,
	);
	const handleClick = useCallback(() => {
		setIsIssueRestrictOpen(true);
		onClick?.(itemKey);
	}, [setIsIssueRestrictOpen, onClick, itemKey]);

	return (
		<ButtonItem
			key="restrictIssue"
			isSelected={isSelected}
			ref={setDropdownItemRef}
			role="menuitem"
			onClick={handleClick}
		>
			{label}
		</ButtonItem>
	);
};
