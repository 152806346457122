// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	invitePeopleOption: {
		id: 'issue.assignee.invite-people-option',
		defaultMessage: 'Add teammate to Jira',
		description:
			'Text used in the dropdown item that, when select, open invite poeple drawer and allow user to invite new people. It will leave the issue unassigned.',
	},
	invitePeopleOptionAdmin: {
		id: 'issue.assignee.invite-people-option-admin',
		defaultMessage: 'Invite teammate to Jira',
		description:
			'Text used in the dropdown item that, when select, open invite poeple drawer and allow admin user to add new people. It will leave the issue unassigned.',
	},
	deactivated: {
		id: 'issue.assignee.deactivated-status',
		defaultMessage: 'Deactivated',
		description:
			'Text displayed to the right of the user name of a deactivated user in user picker field',
	},
});
