// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	TOGGLE_WATCHING_ERROR,
	COMMENT_ADD_ERROR_GENERIC,
	COMMENT_ADD_ERROR_INVALID,
	COMMENT_CONTENT_EXCEEDED_ERROR,
	COMMENT_DELETE_ERROR,
	EDITOR_ERROR,
	ATTACHMENT_ADD_ERROR,
	ATTACHMENT_ADD_EXCEED_SIZE_LIMIT_ERROR,
	ATTACHMENT_ADD_SUCCESS,
	DELETE_LINKED_ISSUE_ERROR,
	ATTACHMENT_DELETE_ERROR,
	WATCH_TOGGLE_UPDATE_ERROR,
	FETCH_WATCHERS_ERROR,
	STOP_WATCHING_SUCCESS,
	START_WATCHING_SUCCESS,
	REMOVE_WATCHER_ERROR,
	ADD_WATCHER_ERROR,
	SUBTASKS_REORDER_ERROR_GENERIC,
	LOG_WORK_CONFIRM_ERROR,
	ASSIGN_TO_ME_SUCCESS,
	REMOVE_FROM_ME_SUCCESS,
	CREATE_CHILD_ISSUE_ERROR,
	LINK_CHILD_ISSUE_ERROR,
	CHANGE_ISSUE_TYPE_SUCCESS,
	CHANGE_ISSUE_TYPE_ERROR,
	DELETE_ISSUE_ERROR,
	ADD_WORKLOG_SUCCESS,
	ADD_WORKLOG_FAILURE,
	WORKLOGS_PER_ISSUE_LIMIT_EXCEEDED,
	UPDATE_TIME_REMAINING_SUCCESS,
	UPDATE_TIME_REMAINING_FAILURE,
	EDIT_WORKLOG_SUCCESS,
	EDIT_WORKLOG_FAILURE,
	DELETE_WORKLOG_SUCCESS,
	DELETE_WORKLOG_FAILURE,
	RICH_CONTENT_FIELD_CONFIRM_ERROR,
	RICH_CONTENT_FIELD_MAXIMUM_LENGTH_EXCEEDED_ERROR,
	RICH_CONTENT_FIELD_REQUIRED_ERROR,
	DELETE_CONFLUENCE_PAGE_LINK_FAILED,
	DELETE_WEB_LINK_FAILED,
	CLICKTHROUGH_BANNER_DISMISS_SUCCESS,
	SAVE_APPROVAL_ANSWER_ERROR,
	SAVE_APPROVAL_ANSWER_SUCCESS,
	EDIT_EPIC_COLOUR_ERROR,
	EDIT_ISSUE_COLOUR_ERROR,
	FETCH_JIRA_APP_LINKS_ERROR,
	REMOTE_LINK_VALIDATION_ERROR,
	COMMENT_FUTURE_TIME_ERROR,
	SAVE_COMMENT_SUCCESS_FLAG_MESSAGE,
	COMMENTS_PER_ISSUE_LIMIT_EXCEEDED,
	ISSUE_LINKS_PER_ISSUE_LIMIT_EXCEEDED,
	ISSUE_REMOTE_LINKS_PER_ISSUE_LIMIT_EXCEEDED,
	CONFLUENCE_LINKS_PER_ISSUE_LIMIT_EXCEEDED,
	COMMENTS_INTERNAL_SERVER_ERROR,
	ISSUE_ATTACHMENTS_PER_ISSUE_LIMIT_EXCEEDED,
	COMMENTS_LIMIT_EXCEEDED_FIELDS_LIST,
	type WatcherFlagType,
} from './flags';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	getChildIssuesLimit,
	EXCEEDED_LIMIT_AFTER_LOAD,
	EXCEEDED_LIMIT_INITIAL_LOAD,
	WITHIN_LIMIT,
	HREF,
	IN_PRODUCT_HELP,
	WHAT_ARE_ISSUE_TYPES_URL,
	IN_PRODUCT_HELP_ARTICLE_IDS,
	DISMISSED,
	DEFAULT_ASSIGNEE_PERMISSION_ERROR_MESSAGE,
	DEFAULT_ASSIGNEE_PERMISSION_ERROR_MESSAGE_FE,
	GIC_CALLBACK_PAYLOAD_INLINE_CREATE_ID,
	GIC_CALLBACK_PAYLOAD_AI_BREAKDOWN_CREATE_ID,
	type ChildIssuesLimitStatus,
} from './child-issues';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { REALTIME_EVENTS_ANALYTICS_SAMPLE_RATE, EVENTS_TO_LISTEN } from './realtime-support';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	ALL_ACTIVITY,
	APPROVALS,
	COMMENTS,
	HISTORY,
	WORKLOG,
	FORGE,
	CONNECT,
} from './activity-items';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { activityFeedTopMargin, LOAD_NEWER_BUTTON, LOAD_OLDER_BUTTON } from './activity-feed';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { FORGE_ENTITY_TYPE, CONNECT_ENTITY_TYPE, ECOSYSTEM_TYPES } from './ecosystem-constants';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	APPLINK_REQ_AUTH,
	APPLINK_MISSING,
	REMOTE_LINK_MISSING,
	REMOTE_SERVER_ERROR,
} from './remote-link-error-type';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { TOGGLE_WATCHING_SHORTCUT, THROTTLE_MS } from './watchers';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	DEFAULT_PARENT_ISSUE_TYPE_NAME,
	DEFAULT_BASE_ISSUE_TYPE_NAME,
	DEFAULT_CHILD_ISSUE_TYPE_NAME,
} from './issue-types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { FlagFailureConstant, FlagSuccessConstant, FlagConstant } from './flags';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { noWorklogPermissions, noPermissions } from './permissions';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { Permissions } from './permissions';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	fetchCommentsPerf,
	fetchFieldDataPerf,
	fetchIssueLinkPerf,
	fetchIssuePropertiesPerf,
	fetchSubtaskTypesPerf,
	fetchUploadPerf,
	fetchViewContextPerf,
	fetchUserAuthPerf,
} from './browser-metrics-constants';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { STATUS_AND_APPROVAL_SPOTLIGHT, ISSUE_FIELD_SPOTLIGHT_PREFIX } from './layout';
export const issueAppModalWrapperClassName = '_issue-app-modal-dialog-wrapper';
// To avoid circular dependencies these 2 have been copied across into @atlassian/jira-issue-view-common-utils
// if changing them you must also update that package.
export const MODAL_DROPDOWN_PORTAL_CLASS_NAME = 'issue-app-modal-ak-dropdown-portal';
export const EDITOR_DROPDOWN_PORTAL_CLASS_NAME = 'issue-app-modal-editor-dropdown-portal';
export const EDITOR_END_CLASS_NAME = 'issue-editor-end';
export const EDITOR_START_CLASS_NAME = 'issue-editor-start';
export const ISSUE_DESCRIPTION_EDITOR_ID = 'issue-description-editor';
export const ISSUE_ERROR_ANALYTICS = 'issue.error';
export const ELID_ISSUE_HEADER = 'jira-issue-header' as const;
export const ELID_ISSUE_HEADER_ACTIONS = 'jira-issue-header-actions' as const;

export const AGG_ISSUE_ERROR_ANALYTICS = 'issue.error.agg';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	ATTACHMENTS_PER_ISSUE_LIMIT_EXCEEDED_ERROR_MESSAGE,
	COMMENTS_PER_ISSUE_LIMIT_EXCEEDED_ERROR_MESSAGE,
	ISSUE_LIMIT_FIELDS_ERROR_MESSAGE_LIST,
	REMOTE_ISSUE_LINKS_PER_ISSUE_LIMIT_EXCEEDED_ERROR_MESSAGE,
	WORKLOGS_PER_ISSUE_LIMIT_EXCEEDED_ERROR_MESSAGE,
} from './errors';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { SEVERITY_DEGRADED_THRESHOLD, SEVERITY_NORMAL_THRESHOLD } from './severity-thresholds';
