import React from 'react';
import type { CustomGlyphProps } from '@atlaskit/icon';

export const peopleGlyph = (props: CustomGlyphProps) => (
	<svg
		{...props}
		width="16"
		height="16"
		viewBox="4 4 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12.062 13.93C11.158 13.93 10.611 13.196 10.611 11.985C10.611 10.759 11.149 10.033 12.077 10.033C13.005 10.033 13.499 10.797 13.499 12C13.499 13.195 12.997 13.93 12.061 13.93H12.062ZM12 5C8.075 5 5 8.075 5 12C5 16.596 8.522 19 12 19C12.874 19 13.614 18.91 14.26 18.721C14.4417 18.6576 14.5922 18.5269 14.6804 18.3558C14.7686 18.1848 14.7877 17.9863 14.7338 17.8016C14.6799 17.6168 14.5571 17.4598 14.3908 17.3629C14.2245 17.266 14.0273 17.2367 13.84 17.281C13.332 17.428 12.73 17.501 12 17.501C9.352 17.501 6.5 15.779 6.5 12.001C6.5 8.916 8.917 6.501 12 6.501C15.24 6.501 17.5 8.453 17.5 11.251C17.5 13.296 16.457 14.251 15.752 14.251C15.744 14.251 15 14.141 15 13.501V9.501C15 9.30209 14.921 9.11132 14.7803 8.97067C14.6397 8.83002 14.4489 8.751 14.25 8.751C14.0511 8.751 13.8603 8.83002 13.7197 8.97067C13.579 9.11132 13.5 9.30209 13.5 9.501V9.688C13.154 9.103 12.484 8.736 11.705 8.736C10.102 8.736 9 10.04 9 11.938C9 13.922 10.103 15.25 11.753 15.25C12.618 15.25 13.263 14.863 13.618 14.174C13.952 15.19 14.988 15.75 15.75 15.75C17.348 15.75 19 14.067 19 11.25C19 7.628 16.058 5 12 5Z"
			fill="currentColor"
		/>
	</svg>
);
