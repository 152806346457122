import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	securityLevel: {
		id: 'issue-field-security-level.common.security-level',
		defaultMessage: 'Security level',
		description:
			'Represents the "security level" entity. This text is used in a few different places',
	},
});
