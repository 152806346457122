import React from 'react';
import { Box } from '@atlaskit/primitives';
import Tooltip, { type PositionType } from '@atlaskit/tooltip';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import { defaultLocale } from '@atlassian/jira-platform-utils-date-fns/src/constants.tsx';
import {
	formatDistanceWithLocale,
	formatDistanceStrictWithLocale,
} from '@atlassian/jira-platform-utils-date-fns/src/main.tsx';
import type { Locale } from '@atlassian/jira-platform-utils-date-fns/src/types.tsx';
import messages from './messages.tsx';

export const DATE_FORMAT = {
	year: 'numeric',
	month: 'long',
	day: 'numeric',
	hour: 'numeric',
	minute: 'numeric',
} as const;
type Props = {
	date: Date;
	locale?: Locale;
	timeZone?: string | undefined;
	intl: IntlShape;
	strict?: boolean;
	// optionally pass in delay so we can set it to 0 to make the tooltip appear immediately
	// for the purpose of vr-testing
	delay?: number;
	position?: PositionType;
};

function RelativeDate(props: Props) {
	const {
		locale = defaultLocale,
		date,
		timeZone,
		intl: { formatDate },
		strict = false,
		delay,
		position = 'top',
	} = props;

	const { formatMessage } = useIntl();

	const options =
		timeZone !== undefined && timeZone.trim() !== '' && timeZone !== null
			? { ...DATE_FORMAT, timeZone }
			: { ...DATE_FORMAT };

	const timeElapsed = strict
		? formatDistanceStrictWithLocale(date, new Date(), locale)
		: formatDistanceWithLocale(date, new Date(), locale);
	const lastUpdateDateTime = formatDate(date.valueOf(), options);

	return (
		<Tooltip content={lastUpdateDateTime} position={position} delay={delay}>
			<Box as="span" testId="development-common.ui.relative-date">
				{formatMessage(messages.relativeDate, {
					relativeDate: timeElapsed,
				})}
			</Box>
		</Tooltip>
	);
}

export default injectIntl(RelativeDate);
