/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//Finnish
export default {
  'fabric.elements.share.copied.to.clipboard.message': 'Linkki kopioitu leikepöydälle',
  'fabric.elements.share.copylink.button.text': 'Kopioi linkki',
  'fabric.elements.share.copypubliclink.button.text': 'Kopioi julkinen linkki',
  'fabric.elements.share.failure.icon.label': 'Virhekuvake',
  'fabric.elements.share.failure.message': 'Ei voitu jakaa',
  'fabric.elements.share.form.comment.label': 'Viesti (valinnainen)',
  'fabric.elements.share.form.comment.placeholder': 'Mitä muiden tulisi tietää?',
  'fabric.elements.share.form.info.message.no.invite.confluence': 'Vastaanottajat näkevät sivun nimen ja viestisi',
  'fabric.elements.share.form.info.message.no.invite.jira': 'Vastaanottajat näkevät asian nimen ja viestisi',
  'fabric.elements.share.form.no-permissions': 'Sinulla ei ole jakamisoikeutta.',
  'fabric.elements.share.form.public.send': 'Lähetä julkinen linkki',
  'fabric.elements.share.form.public.share': 'Jaa julkinen linkki',
  'fabric.elements.share.form.required-field.summary': 'Pakolliset kentät on merkitty tähdellä',
  'fabric.elements.share.form.retry': 'Yritä uudelleen',
  'fabric.elements.share.form.send': 'Lähetä',
  'fabric.elements.share.form.share': 'Jaa',
  'fabric.elements.share.form.title': 'Jaa',
  'fabric.elements.share.form.user-picker.add-more': 'Syötä lisää',
  'fabric.elements.share.form.user-picker.label.confluence': 'Nimet, tiimit, ryhmät tai sähköpostiosoitteet',
  'fabric.elements.share.form.user-picker.label.email-disabled-confluence': 'Nimet, tiimit tai ryhmät',
  'fabric.elements.share.form.user-picker.label.email-disabled-jira': 'Nimet tai tiimit',
  'fabric.elements.share.form.user-picker.label.email-only': 'Sähköpostiosoitteet',
  'fabric.elements.share.form.user-picker.label.jira': 'Nimet, tiimit tai sähköpostiosoitteet',
  'fabric.elements.share.form.user-picker.no-options.existingUserOnly': 'Tuloksia ei löytynyt haulle "{inputValue}".',
  'fabric.elements.share.form.user-picker.no-options.generic':
    'Tuloksia ei löytynyt haulle "{inputValue}". Kutsu henkilöitä sähköpostiosoitteen avulla.',
  'fabric.elements.share.form.user-picker.placeholder.confluence': 'esim. Maria, Oranssi tiimi, ykkösryhmä',
  'fabric.elements.share.form.user-picker.placeholder.email-disabled-jira': 'esim. Maria, Oranssi tiimi',
  'fabric.elements.share.form.user-picker.placeholder.email-only': 'esim. maria@yritys.com',
  'fabric.elements.share.form.user-picker.placeholder.jira': 'esim. Maria, Oranssi tiimi, maria@yritys.com',
  'fabric.elements.share.form.user-picker.validation.required':
    'Valitse vähintään yksi käyttäjä, ryhmä, tiimi tai sähköpostiosoite.',
  'fabric.elements.share.form.user-picker.validation.required-message.confluence':
    'Valitse vähintään yksi henkilö, tiimi, ryhmä tai sähköpostiosoite',
  'fabric.elements.share.form.user-picker.validation.required-message.email-disabled-confluence':
    'Valitse vähintään yksi henkilö, tiimi tai ryhmä',
  'fabric.elements.share.form.user-picker.validation.required-message.email-disabled-jira':
    'Valitse vähintään yksi henkilö tai tiimi',
  'fabric.elements.share.form.user-picker.validation.required-message.email-only':
    'Valitse vähintään yksi sähköpostiosoite',
  'fabric.elements.share.form.user-picker.validation.required-message.jira':
    'Valitse vähintään yksi henkilö, tiimi tai sähköpostiosoite',
  'fabric.elements.share.in.integration.button': 'Jaa sovelluksessa {integrationName}',
  'fabric.elements.share.invite.to.confluence': 'Kutsu Confluenceen',
  'fabric.elements.share.invite.to.jira': 'Kutsu Jiraan',
  'fabric.elements.share.main.tab.text.confluence': 'Jaa sivu',
  'fabric.elements.share.main.tab.text.jira': 'Jaa asia',
  'fabric.elements.share.menu.email.label': 'Sähköposti',
  'fabric.elements.share.menu.slack.label': 'Slack',
  'fabric.elements.share.popup.label': 'Jaa',
  'fabric.elements.share.success.message':
    '{object, select,blogpost {Blogijulkaisu jaettu}board {Taulu jaettu}calendar {Kalenteri jaettu}draft {Luonnos jaettu}filter {Suodatin jaettu}issue {Asia jaettu}summary {Yhteenveto jaettu}list {Luettelo jaettu}timeline {Aikajana jaettu}form {Lomake jaettu}media {Media jaettu}page {Sivu jaettu}project {Projekti jaettu}pullrequest {Pull-pyyntö jaettu}question {Kysymys jaettu}report {Raportti jaettu}repository {Säilö jaettu}request {Pyyntö jaettu}roadmap {Aikajana jaettu}site {Sivusto jaettu}space {Työtila jaettu}other {Linkki jaettu}}',
  'fabric.elements.share.to.integration.button': 'Jaa kohteeseen {integrationName}',
  'fabric.elements.share.trigger.button.icon.label': 'Jaa kuvake',
  'fabric.elements.share.trigger.button.invite.text': 'Kutsu',
  'fabric.elements.share.trigger.button.text': 'Jaa',
  'fabric.elements.share.trigger.button.tooltip.text': 'Jaa',
};
