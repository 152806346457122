import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { IssuesRemote } from '@atlassian/jira-polaris-remote-issue/src/controllers/types.tsx';
import { noopJiraUpdateService } from '../common/index.tsx';
import { jiraStringMapping } from '../string/index.tsx';
import type { JiraFieldMapping } from '../types.tsx';

export const jiraIssueKeyMapping = (
	issuesRemote: IssuesRemote,
	field: Field,
): JiraFieldMapping<string> => {
	const baseMapping = jiraStringMapping(issuesRemote, field);
	return {
		field,
		getValueFromJiraIssue: (issue) => issue.key,
		getFieldValueForJiraUpdate: baseMapping.getFieldValueForJiraUpdate,
		isMultiValueField: false,
		isSupportedByIssueUpdateApi: false,
		...noopJiraUpdateService,
	};
};
