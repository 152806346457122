/**
 * @generated SignedSource<<9fd49b1cd554098c69ccc61178d1d3e8>>
 * @relayHash 62b4ad3160148267a24d838803deb09b
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID a3276eae9fb701fbfe2cc62de094f849c1b12769bf0763107abf751acdbe56ed

import type { ConcreteRequest, Mutation } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraSingleValueFieldOperations = "SET" | "%future added value";
export type JiraUpdateProjectFieldInput = {
  id: string;
  operation: JiraProjectFieldOperationInput;
};
export type JiraProjectFieldOperationInput = {
  id?: string | null | undefined;
  operation: JiraSingleValueFieldOperations;
};
export type project_issueFieldProject_ProjectField_Mutation$variables = {
  input: JiraUpdateProjectFieldInput;
};
export type project_issueFieldProject_ProjectField_Mutation$data = {
  readonly jira: {
    readonly updateProjectField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly " $fragmentSpreads": FragmentRefs<"project_issueFieldProjectInlineEditFull_ProjectInlineEditViewWithIsEditable_fragmentRef">;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type project_issueFieldProject_ProjectField_Mutation$rawResponse = {
  readonly jira: {
    readonly updateProjectField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly id: string;
        readonly name: string;
        readonly project: {
          readonly avatar: {
            readonly large: string | null | undefined;
            readonly small: string | null | undefined;
          } | null | undefined;
          readonly id: string;
          readonly key: string;
          readonly name: string;
          readonly projectId: string | null | undefined;
        } | null | undefined;
        readonly type: string;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type project_issueFieldProject_ProjectField_Mutation = {
  rawResponse: project_issueFieldProject_ProjectField_Mutation$rawResponse;
  response: project_issueFieldProject_ProjectField_Mutation$data;
  variables: project_issueFieldProject_ProjectField_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "success"
},
v3 = {
  "concreteType": "MutationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "message"
    }
  ]
},
v4 = {
  "kind": "ScalarField",
  "name": "id"
},
v5 = {
  "kind": "ScalarField",
  "name": "name"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "project_issueFieldProject_ProjectField_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraProjectFieldPayload",
            "kind": "LinkedField",
            "name": "updateProjectField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraProjectField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  {
                    "kind": "FragmentSpread",
                    "name": "project_issueFieldProjectInlineEditFull_ProjectInlineEditViewWithIsEditable_fragmentRef"
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "project_issueFieldProject_ProjectField_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraProjectFieldPayload",
            "kind": "LinkedField",
            "name": "updateProjectField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraProjectField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "type"
                  },
                  {
                    "concreteType": "JiraProject",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "key"
                      },
                      (v5/*: any*/),
                      {
                        "concreteType": "JiraAvatar",
                        "kind": "LinkedField",
                        "name": "avatar",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "small"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "large"
                          }
                        ]
                      },
                      (v4/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "projectId"
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "a3276eae9fb701fbfe2cc62de094f849c1b12769bf0763107abf751acdbe56ed",
    "metadata": {},
    "name": "project_issueFieldProject_ProjectField_Mutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "b5f3a5dcfab2c26aea1d6b264c6f2714";

export default node;
