type ConnectionFieldIssueTypeIdFilter = {
	type: 'id';
	ids: string[];
};

type ConnectionFieldIssueTypeNameFilter = {
	type: 'name';
	names: string[];
};

export type ConnectionFieldIssueTypeFilter =
	| ConnectionFieldIssueTypeIdFilter
	| ConnectionFieldIssueTypeNameFilter;

export const isIssueTypeNameFilter = (
	filter: ConnectionFieldIssueTypeFilter,
): filter is ConnectionFieldIssueTypeNameFilter => filter.type === 'name';
export const isIssueTypeIdFilter = (
	filter: ConnectionFieldIssueTypeFilter,
): filter is ConnectionFieldIssueTypeIdFilter => filter.type === 'id';

export type ConnectionFieldIssueViewLayoutSort = {
	fieldKey: string;
	order: 'ASC' | 'DESC';
};
