import React from 'react';

import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import AutomationIssueAuditLogBody from '@atlassian/jira-automation-issue-audit-log/src/ui/index.tsx';
import GenericAutomationIssueAuditLogError from '@atlassian/jira-automation-issue-audit-log/src/ui/generic-automation-issue-audit-log-error/index.tsx';
import useI18nContextPanelAutomationProps from '@atlassian/jira-automation-issue-audit-log/src/controllers/use-i18n-context-panel-automation-props/index.tsx';
import useShouldShowAutomationAuditLogPanel from '@atlassian/jira-automation-issue-audit-log/src/controllers/use-should-show-automation-audit-log-panel/index.tsx';
import { ContextPanelGroup } from '../context-group/main.tsx';

const AutomationIssueAuditLogPanel: React.FC = () => {
	const automationPanelContextPanelProps = useI18nContextPanelAutomationProps();
	const shouldShow = useShouldShowAutomationAuditLogPanel();

	if (!shouldShow) {
		return null;
	}

	return (
		<UFOSegment name="issue-automation-audit-log-panel">
			<ErrorBoundary id="automation-issue-audit-log-panel">
				<ContextPanelGroup {...automationPanelContextPanelProps}>
					<JSErrorBoundary
						id="automation-issue-audit-log-panel-body"
						fallback={() => <GenericAutomationIssueAuditLogError />}
					>
						<AutomationIssueAuditLogBody />
					</JSErrorBoundary>
				</ContextPanelGroup>
			</ErrorBoundary>
		</UFOSegment>
	);
};

export default AutomationIssueAuditLogPanel;
