import { createSelector } from 'reselect';
import { getChildIssuesLimit } from '@atlassian/jira-issue-view-common-constants/src/child-issues.tsx';
import {
	CHILDREN_ISSUES,
	EPIC_ISSUES,
	PORTFOLIO_CHILD_ISSUES,
	SUBTASKS,
} from '@atlassian/jira-issue-view-configurations/src/index.tsx';
import {
	childIssuesLimitUrlSelector,
	fieldsSelector,
} from '../common/state/selectors/field-selector.tsx';
import { persistedChildrenIssuesSelector } from './children-issues-selector.tsx';
import { subtaskEntitiesSelector } from './subtasks-selector.tsx';

export const totalChildIssueCountSelector = createSelector(
	subtaskEntitiesSelector,
	persistedChildrenIssuesSelector,
	(subtasks, childrenIssues) => subtasks.length + childrenIssues.length,
);

export const hasExceededIssuesLimitAfterLoadSelector = createSelector(
	subtaskEntitiesSelector,
	persistedChildrenIssuesSelector,
	childIssuesLimitUrlSelector,
	(subtasks, childrenIssues, getChildIssuesLimitUrl) => {
		const getHasExceededChildIssuesLimitAfterLoad = (fieldId: string) => {
			const hasExceededChildIssuesLimitOnLoad = Boolean(getChildIssuesLimitUrl(fieldId));

			return (
				!hasExceededChildIssuesLimitOnLoad &&
				subtasks.length + childrenIssues.length > getChildIssuesLimit()
			);
		};
		return getHasExceededChildIssuesLimitAfterLoad;
	},
);

export const hasExceededChildIssuesLimitAnyPanelOnLoad = createSelector(
	fieldsSelector,
	(fields) =>
		Boolean(fields?.[SUBTASKS]?.issueLimitUrl) ||
		Boolean(fields?.[CHILDREN_ISSUES]?.issueLimitUrl) ||
		Boolean(fields?.[EPIC_ISSUES]?.issueLimitUrl) ||
		Boolean(fields?.[PORTFOLIO_CHILD_ISSUES]?.issueLimitUrl),
);

export const hasExceededChildIssuesLimitAnyPanelAfterLoad = createSelector(
	hasExceededIssuesLimitAfterLoadSelector,
	(getHasExceededChildIssuesLimitAfterLoad) =>
		getHasExceededChildIssuesLimitAfterLoad(SUBTASKS) ||
		getHasExceededChildIssuesLimitAfterLoad(CHILDREN_ISSUES) ||
		getHasExceededChildIssuesLimitAfterLoad(PORTFOLIO_CHILD_ISSUES) ||
		getHasExceededChildIssuesLimitAfterLoad(EPIC_ISSUES),
);
