import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';

export const getExtensionMeasuredKey = (issueKey: IssueKey, extensionId: string) =>
	`${issueKey}-${extensionId}`;

export function defaultShouldMeasurePageSegmentLoad(
	source: string | undefined,
	renderContext: string,
): boolean {
	return source === 'full-issue' && renderContext === 'issue-view';
}

export function defaultShouldMeasureInteraction(
	source: string | undefined,
	renderContext: string,
): boolean {
	return (
		renderContext === 'issue-view' &&
		!!source &&
		[
			'roadmap-issue',
			'software-issue',
			'issue-navigator-next',
			'global-issue-navigator-issue',
			'full-issue',
		].includes(source)
	);
}
