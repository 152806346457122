import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { LabelsSuggestionList } from '@atlassian/jira-shared-types/src/rest/jira/label.tsx';
import {
	createStore,
	createHook,
	createSubscriber,
	type Store,
	type SubscriberComponent,
	type StoreActionApi,
	type HookReturnValue,
} from '@atlassian/react-sweet-state';

export type FieldShape = LabelsSuggestionList;

type FieldState<TPayload> = {
	sessionId: string | null; // session id for getting field value to store it,
	fieldValuePromise: Promise<TPayload> | null; // null when prefetched = false, otherwise will hold field values,
	prefetched: boolean; // true when field is prefetched, false when field is not prefetched,
	prefetchSessionId: string; // frs prefetch session id to check which fields to prefetch
};

type State = {
	assignee: null;
	labels: FieldState<LabelsSuggestionList> | null;
};

const initialState: State = {
	assignee: null,
	labels: null,
};

type StoreApi = StoreActionApi<State>;

const setField =
	(
		fieldId: string,
		prefetchSessionId: string,
		prefetched: boolean,
		sessionId: string | null,
		fieldValuePromise: Promise<FieldShape> | null,
	) =>
	({ setState }: StoreApi) => {
		setState({
			[fieldId]: {
				prefetchSessionId,
				prefetched,
				fieldValuePromise,
				sessionId,
			},
		});
	};

const actions = {
	setField,
} as const;

export type SmartPrefetchActions = typeof actions;

const createIssueStore = (issueKey: IssueKey): Store<State, SmartPrefetchActions> =>
	createStore<State, SmartPrefetchActions>({
		name: `smart-issue-prefetch-${issueKey}`,
		initialState,
		actions,
	});

const issueStores: {
	[key: string]: Store<State, SmartPrefetchActions>;
} = {};

const getStore = (issueKey: IssueKey): Store<State, SmartPrefetchActions> => {
	if (!issueStores[issueKey]) {
		issueStores[issueKey] = createIssueStore(issueKey);
	}
	return issueStores[issueKey];
};

type SelectorProps = {
	fieldId: string;
};

export type CachedFieldShape<TPayload> = {
	sessionId: string | null;
	fieldValuePromise: Promise<TPayload> | null; // null when not prefetched,
	prefetched: boolean;
	prefetchSessionId: string;
};

type FieldSubscriber<TPayload> = SubscriberComponent<
	CachedFieldShape<TPayload>,
	SmartPrefetchActions,
	SelectorProps
> | null;

const subscribers: Record<
	IssueKey,
	{
		labels: FieldSubscriber<LabelsSuggestionList>;
		assignee: null;
	}
> = {};

const getFieldValue = <TPayload,>(
	state: State,
	// @ts-expect-error - TS2525 - Initializer provides no value for this binding element and the binding element has no default value. | TS7031 - Binding element 'fieldId' implicitly has an 'any' type.
	{ fieldId } = {},
	// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type 'State'.
): CachedFieldShape<TPayload> => state[fieldId];

const hooks: Record<IssueKey, () => HookReturnValue<State, SmartPrefetchActions>> = {};

export const useLoadedFieldValue = <_TPayload,>(issueKey: IssueKey) => {
	if (!hooks[issueKey]) {
		// @ts-expect-error - TS2322 - Type 'HookFunction<State, BoundActions<State, { readonly setField: (fieldId: string, prefetchSessionId: string, prefetched: boolean, sessionId: string | null, fieldValuePromise: Promise<FieldShape> | null) => ({ setState }: StoreApi) => void; }>, void>' is not assignable to type '() => HookReturnValue<State, { readonly setField: (fieldId: string, prefetchSessionId: string, prefetched: boolean, sessionId: string | null, fieldValuePromise: Promise<FieldShape> | null) => ({ setState }: StoreApi) => void; }>'.
		hooks[issueKey] = createHook(getStore(issueKey));
	}
	return hooks[issueKey];
};

export const usePrefetchedField = (issueKey: IssueKey, fieldId: string) => {
	const [fields] = useLoadedFieldValue(issueKey)();
	// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'State'.
	return fields[fieldId];
};

export const getFieldLoadedValueSubscriber = <TPayload,>(issueKey: IssueKey, fieldId: string) => {
	if (subscribers[issueKey] !== undefined && subscribers[issueKey] !== null) {
		if (
			// @ts-expect-error - TS2532 - Object is possibly 'undefined'. | TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ labels: FieldSubscriber<LabelsResponsePayload>; assignee: FieldSubscriber<AssigneePayload[]>; }'.
			subscribers[issueKey][fieldId] !== undefined &&
			// @ts-expect-error - TS2532 - Object is possibly 'undefined'. | TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ labels: FieldSubscriber<LabelsResponsePayload>; assignee: FieldSubscriber<AssigneePayload[]>; }'.
			subscribers[issueKey][fieldId] !== null
		) {
			// @ts-expect-error - TS2532 - Object is possibly 'undefined'. | TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ labels: FieldSubscriber<LabelsResponsePayload>; assignee: FieldSubscriber<AssigneePayload[]>; }'.
			return subscribers[issueKey][fieldId];
		}
	} else {
		subscribers[issueKey] = {
			// @ts-expect-error(PARTIAL_RECORD) TS2783 | TS2783 - 'labels' is specified more than once, so this usage will be overwritten. | 'labels' is specified more than once, so this usage will be overwritten.
			labels: null,
			// @ts-expect-error(PARTIAL_RECORD) TS2783 | TS2783 - 'assignee' is specified more than once, so this usage will be overwritten. | 'assignee' is specified more than once, so this usage will be overwritten.
			assignee: null,
			...subscribers[issueKey],
		};
	}
	// @ts-expect-error - TS2532 - Object is possibly 'undefined'. | TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ labels: FieldSubscriber<LabelsResponsePayload>; assignee: FieldSubscriber<AssigneePayload[]>; }'.
	subscribers[issueKey][fieldId] = createSubscriber<
		State,
		SmartPrefetchActions,
		CachedFieldShape<TPayload>,
		SelectorProps
	>(getStore(issueKey), {
		selector: getFieldValue,
	});
	// @ts-expect-error - TS2532 - Object is possibly 'undefined'. | TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ labels: FieldSubscriber<LabelsResponsePayload>; assignee: FieldSubscriber<AssigneePayload[]>; }'.
	return subscribers[issueKey][fieldId];
};
