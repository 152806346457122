import React, { useState, type ReactElement, useRef, useEffect } from 'react';
import { graphql, useFragment } from 'react-relay';
import { PopupMenuGroup } from '@atlaskit/menu';
import Popup, { type TriggerProps } from '@atlaskit/popup';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { vulnerabilityDropdown_developmentSummaryVulnerability$key } from '@atlassian/jira-relay/src/__generated__/vulnerabilityDropdown_developmentSummaryVulnerability.graphql';
import { VulnerabilityDropdownItem } from './vulnerability-dropdown-item/index.tsx';

export type DropdownTriggerProps = TriggerProps & {
	onClick: () => void;
	isSelected: boolean;
};

export type Props = {
	renderDropdownTrigger: (props: DropdownTriggerProps) => ReactElement;
	vulnerabilityDropdownData: vulnerabilityDropdown_developmentSummaryVulnerability$key;
};

export const VulnerabilityDropdown = ({
	renderDropdownTrigger,
	vulnerabilityDropdownData,
}: Props) => {
	const data = useFragment(
		graphql`
			fragment vulnerabilityDropdown_developmentSummaryVulnerability on DevOpsSecurityVulnerabilityDetails
			@relay(plural: true) {
				id
				...vulnerabilityDropdownItem_developmentSummaryVulnerability
			}
		`,
		vulnerabilityDropdownData,
	);

	const [isOpen, setIsOpen] = useState(false);
	const previousOpen = useRef(false);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	useEffect(() => {
		if (previousOpen.current !== isOpen) {
			previousOpen.current = isOpen;
			isOpen &&
				fireUIAnalytics(
					createAnalyticsEvent({
						actionSubject: 'dropdown',
						action: 'opened',
					}),
					'devPanelVulnerabilityDropdown',
				);
			!isOpen &&
				fireUIAnalytics(
					createAnalyticsEvent({
						actionSubject: 'dropdown',
						action: 'closed',
					}),
					'devPanelVulnerabilityDropdown',
				);
		}
	}, [createAnalyticsEvent, isOpen]);
	return (
		<Popup
			isOpen={isOpen}
			onClose={() => setIsOpen(false)}
			content={() => (
				<PopupMenuGroup>
					{data.map((vulnerabilityDetail) => (
						<VulnerabilityDropdownItem
							key={vulnerabilityDetail.id}
							vulnerabilityDropdownItemData={vulnerabilityDetail}
							onActionClick={() => setIsOpen(false)}
						/>
					))}
				</PopupMenuGroup>
			)}
			placement="bottom-end"
			shouldFlip
			zIndex={layers.modal}
			trigger={(triggerProps) =>
				renderDropdownTrigger({
					...triggerProps,
					isSelected: isOpen,
					onClick: () => setIsOpen(!isOpen),
				})
			}
		/>
	);
};
