import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	newLozenge: {
		id: 'issue-internal-field-select.common.select-inline-edit.new-lozenge',
		defaultMessage: 'NEW',
		description: 'The new lozenge displayed next to the field label',
	},
	extendedEditButtonLabel: {
		id: 'issue-internal-field-select.common.select-inline-edit.extended-edit-button-label',
		defaultMessage: 'Edit {fieldName}, {inputFieldValue} selected',
		description:
			'An accessibility label for an edit button in an input field. The fieldName parameter refers to an optional heading for what the field is controlling. The inputFieldValue refers to the vale in the field.',
	},
});
