import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/observable/zip';
import type { ActionsObservable } from 'redux-observable';
import startOfDay from 'date-fns/startOfDay';
import sub from 'date-fns/sub';
import { Observable } from 'rxjs/Observable';
import { fetchDevSummaryData } from '@atlassian/jira-development-summary-common/src/services/index.tsx';
import { createAri, getAriConfig } from '@atlassian/jira-platform-ari/src/index.tsx';
import { getDeploymentDataDetected, getShowAppConfigPrompt } from '../../../services/index.tsx';
import fetchData from '../../../services/utils.tsx';
import type { SetContextAction } from '../../context/actions/index.tsx';
import { contextSelector, issueContextSelector } from '../../context/selectors/index.tsx';
import type { State } from '../../types.tsx';
import {
	type ReleasesDataFetchAction,
	releasesDataFetchResponse,
	type ReleasesDataFetchResponseAction,
	RELEASES_DATA_FETCH_REQUEST,
} from '../actions/index.tsx';

export const getUpdatedFromDate = () =>
	startOfDay(
		sub(new Date(), {
			weeks: 6,
		}),
	).toISOString();

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	action$: ActionsObservable<
		ReleasesDataFetchAction | ReleasesDataFetchResponseAction | SetContextAction
	>,
	store: MiddlewareAPI<State>,
) =>
	action$.ofType(RELEASES_DATA_FETCH_REQUEST).mergeMap((action: ReleasesDataFetchAction) => {
		const state = store.getState();
		const context = contextSelector(state);
		const { payload } = action;

		const { projectAri } = issueContextSelector(state);
		const updatedFrom = getUpdatedFromDate();
		return Observable.zip(
			fetchData({
				issueId: context.issueContext.issueId,
				analyticsEvent: payload.meta.analyticsEvent,
			}),
			getDeploymentDataDetected(projectAri, updatedFrom),
			getShowAppConfigPrompt(context.cloudAri, context.isSiteAdmin, context.isJiraAdmin),
			fetchDevSummaryData(
				createAri({
					resourceOwner: 'jira',
					cloudId: getAriConfig(projectAri).cloudId ?? '',
					resourceType: 'issue',
					resourceId: String(context.issueContext.issueId),
				}),
			),
			(
				releasesData,
				hasDeploymentsInProject,
				shouldShowAppConfigContextPrompt,
				{ panelState, summary },
			) =>
				releasesDataFetchResponse({
					...releasesData,
					shouldShowEmptyState:
						panelState === 'DEV_SUMMARY' &&
						(summary.pullrequest?.count ?? 0) !== 0 &&
						!hasDeploymentsInProject,
					hasDeploymentsInProject,
					shouldShowAppConfigContextPrompt,
				}),
		).catch((ex) => Observable.of(releasesDataFetchResponse(ex)));
	});
