import React, { useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import { getEmptyADF } from '@atlaskit/adf-utils/empty-adf';
import { useInlineEditFieldInjections } from '@atlassian/jira-issue-field-injections/src/controllers/inline-edit-injections-context/index.tsx';
import DescriptionField from '@atlassian/jira-issue-view-base/src/common/description/index.tsx';
import MultilineField from '@atlassian/jira-issue-view-base/src/common/switching-multiline-field/view.tsx';
import { DESCRIPTION_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { richTextField_issueViewLayoutRichTextField$key } from '@atlassian/jira-relay/src/__generated__/richTextField_issueViewLayoutRichTextField.graphql';
import { removeCollectionFromAdf } from '@atlassian/jira-rich-content/src/common/adf-parsing-utils.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import type { CommonRichTextFieldProps } from '../../common/types.tsx';

const emptyAdfObject = getEmptyADF();

type Props = CommonRichTextFieldProps & {
	richTextField: richTextField_issueViewLayoutRichTextField$key;
};

export const RichTextField = ({ area, onSave, richTextField }: Props) => {
	const data = useFragment<richTextField_issueViewLayoutRichTextField$key>(
		graphql`
			fragment richTextField_issueViewLayoutRichTextField on JiraRichTextField
			@argumentDefinitions(
				isIssueViewFieldConfigEditEnabled: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/relay-migration-issue-fields-custom-field-config.relayprovider"
				}
			) {
				fieldId
				description
				name
				isEditableInIssueView
				type
				richText {
					adfValue {
						json
					}
				}
				fieldOperations @include(if: $isIssueViewFieldConfigEditEnabled) {
					canEdit
				}
			}
		`,
		richTextField,
	);

	const adfValue: ADF = useMemo(() => {
		const adfValueJson = data.richText?.adfValue?.json ?? null;
		return adfValueJson ? removeCollectionFromAdf(adfValueJson) : emptyAdfObject;
	}, [data.richText?.adfValue?.json]);

	const { overriding } = useInlineEditFieldInjections();

	const fieldProps = useMemo(() => {
		const label = overriding.overrideLabel(data.name);
		const description = overriding.overrideDescription(data.description) || '';
		const isEditable = overriding.overrideIsEditable(data.isEditableInIssueView || false);
		return {
			label,
			description,
			isEditable,
			area,
			fieldId: data.fieldId,
			adfValue,
			showEditIcon: data.fieldOperations?.canEdit,
		};
	}, [
		adfValue,
		area,
		data.description,
		data.fieldId,
		data.isEditableInIssueView,
		data.name,
		overriding,
		data.fieldOperations,
	]);

	if (data.type === DESCRIPTION_TYPE) {
		return <DescriptionField onSave={onSave} {...fieldProps} />;
	}
	return (
		<UFOSegment name="issue-field-rich-text">
			<MultilineField saveFieldOverride={onSave} {...fieldProps} />
		</UFOSegment>
	);
};
