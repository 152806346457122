import React, { useMemo } from 'react';
import { expVal } from '@atlassian/jira-feature-experiments';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import { Emphasise } from '@atlassian/jira-issue-view-activity-common/src/styles/index.tsx';
import GroupedChange from '../../../../common/ui/grouped-change/index.tsx';
import HistoryItem from '../../../../common/ui/history-item/index.tsx';
import type { ArchivedChangedHistoryItem } from '../../../../types.tsx';
import messages from './messages.tsx';

type Props = {
	isGrouped?: Boolean;
	historyItem: ArchivedChangedHistoryItem;
};

const ArchivedHistoryItem = ({ historyItem, isGrouped = false }: Props) => {
	const { formatMessage } = useIntl();
	const { actor, timestamp, fromValue } = historyItem;
	const isArchived = useMemo(() => fromValue === 'false', [fromValue]);

	const messageObj = useMemo(() => {
		if (isArchived) {
			return messages.archived;
		}

		return messages.unarchived;
	}, [isArchived]);

	return (
		<FormattedMessage
			{...messageObj}
			values={{
				issue: (
					<Emphasise key="issue">
						<FormattedMessage
							{...(expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
								? messages.issueIssueTermRefresh
								: messages.issue)}
						/>
					</Emphasise>
				),
			}}
		>
			{(...action) =>
				isGrouped && expVal('issue_view_activity_timeline', 'isActivityTimelineEnabled', false) ? (
					<GroupedChange field={formatMessage(messageObj)} from={null} to={null} />
				) : (
					<HistoryItem
						testId="issue-history.ui.history-items.history-item.archived-history-item.history-item"
						actor={actor}
						action={action}
						timestamp={timestamp}
						change={null}
					/>
				)
			}
		</FormattedMessage>
	);
};

export default ArchivedHistoryItem;
