/**
 * @generated SignedSource<<282de2ab139fec91f0aa05104f82f81e>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutLabelsSystemField_IssueViewLabelsSystemField$data = {
  readonly fieldId: string;
  readonly " $fragmentSpreads": FragmentRefs<"labels_issueFieldLabelsInlineEditViewFull_LabelsInlineEditView" | "ui_issueViewLayoutFieldHeading_IssueViewFieldHeading">;
  readonly " $fragmentType": "ui_issueViewLayoutLabelsSystemField_IssueViewLabelsSystemField";
};
export type ui_issueViewLayoutLabelsSystemField_IssueViewLabelsSystemField$key = {
  readonly " $data"?: ui_issueViewLayoutLabelsSystemField_IssueViewLabelsSystemField$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutLabelsSystemField_IssueViewLabelsSystemField">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutLabelsSystemField_IssueViewLabelsSystemField",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "ui_issueViewLayoutFieldHeading_IssueViewFieldHeading"
    },
    {
      "kind": "FragmentSpread",
      "name": "labels_issueFieldLabelsInlineEditViewFull_LabelsInlineEditView"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    }
  ],
  "type": "JiraLabelsField"
};

(node as any).hash = "e1f7b6cc001e180e790ee53a98317eeb";

export default node;
