import React from 'react';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import type { Area } from '@atlassian/jira-issue-view-common-types/src/connect-field-type.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import Placeholder from '@atlassian/react-ufo/placeholder';
import type { issueViewEntitlementField_servicedeskCustomerServiceCustomFields_EntitlementFieldProductSelector_fragmentKey$key } from '@atlassian/jira-relay/src/__generated__/issueViewEntitlementField_servicedeskCustomerServiceCustomFields_EntitlementFieldProductSelector_fragmentKey.graphql';
import type { EntitlementFieldProductSelector } from './ui/issue-view-entitlement-field/index.tsx';

const LazyEntitlementField = lazyForPaint<typeof EntitlementFieldProductSelector>(() =>
	import(
		/* webpackChunkName: "async-customer-service-custom-fields-entitlement-field" */ './ui/issue-view-entitlement-field/index.tsx'
	).then((module) => module.EntitlementFieldProductSelector),
);

type EntitlementFieldProps = {
	area?: Area;
	fragmentKey: issueViewEntitlementField_servicedeskCustomerServiceCustomFields_EntitlementFieldProductSelector_fragmentKey$key;
};

export const AsyncEntitlementField = (props: EntitlementFieldProps) => {
	return (
		<ErrorBoundary id="asyncEntitlementField" packageName="servicedeskCustomerServiceCustomFields">
			<Placeholder name="async-customer-service-custom-fields-entitlement-field" fallback={null}>
				<LazyEntitlementField {...props} />
			</Placeholder>
		</ErrorBoundary>
	);
};
