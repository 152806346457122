import React, { type ComponentType } from 'react';
import { AnalyticsSubject } from '@atlassian/jira-analytics-web-react/src/components/decorators.tsx';

type Props = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	ActionIcon: ComponentType<Record<any, any>>;
};

function Watchers(props: Props) {
	const { ActionIcon } = props;
	return <ActionIcon />;
}

export default AnalyticsSubject('watchers')(Watchers);
