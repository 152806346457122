import React from 'react';
import InlineMessage from '@atlaskit/inline-message';
import { Box, xcss } from '@atlaskit/primitives';
import { DevPanelIconContainer } from '@atlassian/jira-issue-field-heading/src/styled.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import {
	configErrorsSelector,
	errorsSelector,
} from '../../../controllers/dev-summary/selectors/index.tsx';
import ConfigErrorMessage from './config-errors/index.tsx';
import InstanceErrorMessage from './instance-errors/index.tsx';
import type { StateProps, Props } from './types.tsx';

export const Errors = (props: Props) => {
	const { errors, configErrors } = props;

	if (!errors.length && !configErrors.length) {
		return null;
	}

	return (
		<DevPanelIconContainer>
			<InlineMessage appearance="warning">
				<Box xcss={containerStyles}>
					{errors && errors.length ? <InstanceErrorMessage errors={errors} /> : null}
					{configErrors && configErrors.length ? (
						<ConfigErrorMessage configErrors={configErrors} />
					) : null}
				</Box>
			</InlineMessage>
		</DevPanelIconContainer>
	);
};

export default connect(
	(state): StateProps => ({
		// @ts-expect-error - TS2345 - Argument of type '{}' is not assignable to parameter of type 'State'.
		errors: errorsSelector(state) ?? [],
		// @ts-expect-error - TS2345 - Argument of type '{}' is not assignable to parameter of type 'State'.
		configErrors: configErrorsSelector(state) ?? [],
	}),
	{},
)(Errors);

const containerStyles = xcss({
	marginTop: 'space.negative.300',
});
