import React, { type FC } from 'react';
import { xcss, Flex, Box } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { GeneratingAnimation } from './generating-animation/index.tsx';
import messages from './messages.tsx';

type Props = {
	message?: string;
};
export const StreamingStatusBar: FC<Props> = ({ message }) => {
	const { formatMessage } = useIntl();

	return (
		<Flex xcss={containerStyle}>
			<GeneratingAnimation
				alt={formatMessage(
					fg('jira-issue-terminology-refresh-m3')
						? messages.generatingIssueTermRefresh
						: messages.generating,
				)}
			/>
			<Box as="p" xcss={textStyle}>
				{message}
			</Box>
		</Flex>
	);
};

const containerStyle = xcss({
	alignItems: 'center',
	paddingBlockStart: 'space.150',
	paddingBlockEnd: 'space.150',
	paddingInline: 'space.150',
	gap: 'space.100',
});

const textStyle = xcss({
	color: 'color.text.subtlest',
	margin: 'space.0',
	padding: 'space.0',
	display: 'flex',
	alignItems: 'center',
	gap: 'space.050',
});
