import React from 'react';
import { styled } from '@compiled/react';
import { useFragment, graphql } from 'react-relay';
import { token } from '@atlaskit/tokens';
import AsyncIcon from '@atlassian/jira-common-components-async-icon/src/view.tsx';
import { EmptyFieldText } from '@atlassian/jira-issue-empty-field-text/src/ui/index.tsx';
import type { priority_issueFieldPriorityReadviewFull_PriorityReadView$key as PriorityFragment } from '@atlassian/jira-relay/src/__generated__/priority_issueFieldPriorityReadviewFull_PriorityReadView.graphql';
import type { PriorityReadViewProps } from './types.tsx';

/**
 * The PriorityReadView will show a read only view of Priority field.
 * @param props [PriorityReadViewProps](./types.tsx)
 */
export const PriorityReadView = ({ fragmentRef, isCompact }: PriorityReadViewProps) => {
	const data = useFragment<PriorityFragment>(
		graphql`
			fragment priority_issueFieldPriorityReadviewFull_PriorityReadView on JiraPriorityField {
				priority {
					name
					iconUrl
				}
			}
		`,
		fragmentRef,
	);
	const value = data.priority;

	if (isCompact && (!value?.name || !value?.iconUrl)) {
		return null;
	}

	if (!value?.name || !value?.iconUrl) {
		return <EmptyFieldText />;
	}

	return (
		<Wrapper data-testid="issue-field-priority-readview-full.ui.priority.wrapper">
			<AsyncIcon url={value.iconUrl} alt={isCompact ? value.name : undefined} />
			{!isCompact && <Text>{value.name}</Text>}
		</Wrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	height: '20px',
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Text = styled.span({
	marginLeft: token('space.100', '8px'),
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});
