import { metrics } from '@atlassian/browser-metrics';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import {
	getFieldDataOptions,
	getGraphQlUrl,
} from '@atlassian/jira-issue-fetch-services-common/src/services/issue-graphql-data/index.tsx';
import { IssueViewFetchOperations } from '@atlassian/jira-issue-fetch-services-common/src/types.tsx';
import { isInSample } from '@atlassian/jira-issue-sample-utils/src/common/utils/index.tsx';
import type { IssueKey, BaseUrl, ProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { GraphQLIssueResponse } from './types.tsx';

const ISSUE_GRAPHQL_DATA = 'issue-graphql-data';
const timeToFetchIssueGraphQlDataMetric = metrics.custom({
	key: ISSUE_GRAPHQL_DATA,
});

export const fetchIssueGraphQlData = (
	baseUrl: BaseUrl,
	issueKey: IssueKey,
	projectKey: ProjectKey,
): Promise<{
	data: GraphQLIssueResponse;
}> => {
	if (isInSample()) {
		timeToFetchIssueGraphQlDataMetric.start();
	}

	const issueGraphqlDataPromise = fetchJson<{ data: GraphQLIssueResponse }>(
		getGraphQlUrl(baseUrl, IssueViewFetchOperations.INITIAL_GRAPHQL),
		getFieldDataOptions(issueKey, projectKey),
	)
		.then((response) => {
			// Best-effort attempt to provide `issueId` and is not guaranteed
			const issueId = response.data?.issue?.id;

			timeToFetchIssueGraphQlDataMetric.stop({
				customData: issueId ? { issueId } : null,
			});
			return response;
		})
		.catch((error) => {
			// Cancel the metric and re-throw the error
			timeToFetchIssueGraphQlDataMetric.cancel();
			throw error;
		});

	return issueGraphqlDataPromise;
};
