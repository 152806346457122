/**
 * @generated SignedSource<<3544f4fde4e58c3db63548472c851999>>
 * @relayHash 3f73c046ded86edefc83912b3cd5e2d8
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID f4e94696fd29cf731c3234b50dbf74d91aa220d3c9064bbb1beb198ce6b790f4

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type JiraSingleValueFieldOperations = "SET" | "%future added value";
export type JiraUpdateDateFieldInput = {
  id: string;
  operation: JiraDateFieldOperationInput;
};
export type JiraDateFieldOperationInput = {
  date?: AGG$Date | null | undefined;
  operation: JiraSingleValueFieldOperations;
};
export type date_issueFieldDate_DateField_Mutation$variables = {
  input: JiraUpdateDateFieldInput;
};
export type date_issueFieldDate_DateField_Mutation$data = {
  readonly jira: {
    readonly updateDateField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly date: AGG$Date | null | undefined;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type date_issueFieldDate_DateField_Mutation$rawResponse = {
  readonly jira: {
    readonly updateDateField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly date: AGG$Date | null | undefined;
        readonly id: string;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type date_issueFieldDate_DateField_Mutation = {
  rawResponse: date_issueFieldDate_DateField_Mutation$rawResponse;
  response: date_issueFieldDate_DateField_Mutation$data;
  variables: date_issueFieldDate_DateField_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "date"
},
v3 = {
  "kind": "ScalarField",
  "name": "success"
},
v4 = {
  "concreteType": "MutationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "message"
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "date_issueFieldDate_DateField_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraDateFieldPayload",
            "kind": "LinkedField",
            "name": "updateDateField",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraDatePickerField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ]
              },
              (v3/*: any*/),
              (v4/*: any*/)
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "date_issueFieldDate_DateField_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraDateFieldPayload",
            "kind": "LinkedField",
            "name": "updateDateField",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraDatePickerField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "id"
                  }
                ]
              },
              (v3/*: any*/),
              (v4/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "f4e94696fd29cf731c3234b50dbf74d91aa220d3c9064bbb1beb198ce6b790f4",
    "metadata": {},
    "name": "date_issueFieldDate_DateField_Mutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "3265ff9f0b11e3ed4bac45dff405280d";

export default node;
