export const COMPOSITION_TEMPLATE = 'composition';
export const COMPOSITION_SUM = 'sum'; // sum, aka addition
export const COMPOSITION_DIFFERENCE = 'diff'; // difference, aka subtraction
export const COMPOSITION_PRODUCT = 'prod'; // product, aka multiplication
export const COMPOSITION_QUOTIENT = 'quot'; // quotient, aka division

export type CompositionAggregation =
	| typeof COMPOSITION_SUM
	| typeof COMPOSITION_DIFFERENCE
	| typeof COMPOSITION_PRODUCT
	| typeof COMPOSITION_QUOTIENT;

type CompositionParameters<TRecursiveFormula> = {
	agg: CompositionAggregation;
	formulas: TRecursiveFormula[];
};

/**
 * Formula to compose a number of subformulas. This can be used to create basic arithmetic
 * compositions.
 *
 * The missing-value semantics for multiplicative operations are that all values must
 * be present.
 *
 * The missing-value semantics for additive operations are that a (any) value must be present.
 *
 * The semantics for inverse operations (difference and quotient) are:
 *    The first operand is positive (numerator)
 *    Remaining operands are negative (denominator)
 *    Left associativity
 *
 * Thus:
 *    difference(a,b,c) = a - b - c = (a - b) - c
 *    quotient(a,b,c) = a / b / c = (a / b) / c
 *
 */
export interface GenericCompositionFormula<TRecursiveFormula> {
	template: typeof COMPOSITION_TEMPLATE;
	parameters: CompositionParameters<TRecursiveFormula>;
}
