import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	show: {
		id: 'smart-related-issues-panel.similar-incidents.header.expander.show',
		defaultMessage: 'Show',
		description: 'Show label',
	},
	hide: {
		id: 'smart-related-issues-panel.similar-incidents.header.expander.hide',
		defaultMessage: 'Hide',
		description: 'Hide label',
	},
});
