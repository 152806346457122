import type { Vote as VoteValue } from '@atlassian/jira-issue-shared-types/src/common/types/issue-type.tsx';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { type VoteValue };
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type {
	ServerRestUser as User,
	User as Voter,
} from '@atlassian/jira-issue-shared-types/src/common/types/user-type.tsx';

export const FIELD_KEY = 'votes' as const;

export type DisableVotingProps = {
	isDisabled: boolean;
	tooltips?: {
		addVote: string;
		removeVote: string;
	};
};

export type UseVoteField = ({
	onFieldUpdated,
}: {
	onFieldUpdated?: ((value: VoteValue) => void) | undefined;
}) => readonly [
	{
		readonly value: VoteValue;
	},
	{
		readonly toggleValue: (isDropdownItem: boolean) => void;
	},
];

export type VoteServiceProps = {
	onFieldUpdated?: (value: VoteValue) => void;
	useVoteField?: UseVoteField;
};

export type AggJiraVote = {
	count: number;
	hasVoted: boolean;
};

export type SubmitCallbacks = {
	onSubmit?: (value: AggJiraVote) => void;
	onSubmitFailed?: () => void;
	onSubmitSucceeded?: (value: AggJiraVote) => void;
};
