import {
	type LayoutContainerNode,
	type CustomItemType,
	layoutContainerItemTypes,
	type LayoutContainerTabItem,
} from '@atlassian/jira-issue-layout-common-constants/src/index.tsx';
import { ISSUE_FIELD_SPOTLIGHT_PREFIX } from '@atlassian/jira-issue-view-common-constants/src/layout.tsx';
import { fieldTypeHandler } from '@atlassian/jira-issue-view-layout-templates-utils/src/index.tsx';
import { getLayoutItemId } from '@atlassian/jira-issue-view-layout/src/services/utils.tsx';
import {
	DESCRIPTION_TYPE,
	SELECT_CF_TYPE,
	SERVICE_ENTITY_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';

// We can't directly identify `Affected services`, `Change risk` or `Impact`, but we can
// minimise the number of extra targets by wrapping only 'select' type fields since the above
// all fit that criteria. `Description` fortunately has a custom type to identify it
const fieldsWithGlobalTargets = [SELECT_CF_TYPE, DESCRIPTION_TYPE, SERVICE_ENTITY_CF_TYPE];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addRefsToArrayOfItems = (items: any, issueFieldsConfig: any) =>
	// @ts-expect-error - TS7006 - Parameter 'item' implicitly has an 'any' type.
	items.map((item) => {
		const { type, id } = fieldTypeHandler(issueFieldsConfig[getLayoutItemId(item)], item);
		// @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type '"description" | "com.atlassian.jira.plugin.system.customfieldtypes:select" | "com.atlassian.jira.plugins.service-entity:service-entity-field-cftype"'.
		if (fieldsWithGlobalTargets.includes(type)) {
			return {
				...item,
				globalRef: `${ISSUE_FIELD_SPOTLIGHT_PREFIX}${id}`,
			};
		}
		return item;
	});

export const getContentItemsWithLooseItemsInFirstTab = (
	contentItems: LayoutContainerNode[],
	riskImprovementPanel: CustomItemType[],
	primaryIncidentPanel: CustomItemType[],
): LayoutContainerNode[] => {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const firstTab = contentItems.find(
		(item) => item.type === layoutContainerItemTypes.tab,
	) as LayoutContainerTabItem;

	if (!firstTab) {
		return contentItems;
	}

	return [
		{
			...firstTab,
			items: {
				...firstTab.items,
				nodes: [...riskImprovementPanel, ...firstTab.items.nodes, ...primaryIncidentPanel],
			},
		},
		...contentItems.filter((item) => item !== firstTab), // remove first tab since we are creating it above
	];
};
