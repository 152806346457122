import { createStore } from '@atlassian/react-sweet-state';
import { actions } from './actions/main.tsx';
import type { Actions, State } from './actions/types.tsx';

export const initialState: State = {
	csatComment: undefined,
};

export const store = createStore<State, Actions>({
	name: 'request-details-panel',
	initialState,
	actions,
});
