/**
 * @generated SignedSource<<b620af3a22a8015cc3bfe5076d4f83d1>>
 * @relayHash cb22e3609fb64e11c6b119b3c0a9069e
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 0a0bc6b8679a054594aa92883fb8423946f7de79c5058835340cb60c5f7f3167

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type GraphStoreDeleteIssueToWhiteboardInput = {
  relationships: ReadonlyArray<GraphStoreDeleteIssueToWhiteboardRelationshipInput>;
  synchronousWrite?: boolean | null | undefined;
};
export type GraphStoreDeleteIssueToWhiteboardRelationshipInput = {
  from: string;
  to: string;
};
export type useWhiteboardIssueLinkingIssueToWhiteboardDeleteMutation$variables = {
  input?: GraphStoreDeleteIssueToWhiteboardInput | null | undefined;
};
export type useWhiteboardIssueLinkingIssueToWhiteboardDeleteMutation$data = {
  readonly graphStore: {
    readonly deleteIssueToWhiteboard: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type useWhiteboardIssueLinkingIssueToWhiteboardDeleteMutation = {
  response: useWhiteboardIssueLinkingIssueToWhiteboardDeleteMutation$data;
  variables: useWhiteboardIssueLinkingIssueToWhiteboardDeleteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "concreteType": "GraphStoreMutation",
    "kind": "LinkedField",
    "name": "graphStore",
    "plural": false,
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "GraphStoreDeleteIssueToWhiteboardPayload",
        "kind": "LinkedField",
        "name": "deleteIssueToWhiteboard",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "name": "success"
          },
          {
            "concreteType": "MutationError",
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "message"
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "useWhiteboardIssueLinkingIssueToWhiteboardDeleteMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useWhiteboardIssueLinkingIssueToWhiteboardDeleteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "0a0bc6b8679a054594aa92883fb8423946f7de79c5058835340cb60c5f7f3167",
    "metadata": {},
    "name": "useWhiteboardIssueLinkingIssueToWhiteboardDeleteMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "d549f0d76dd33434ca7b5bae79167251";

export default node;
