/**
 * @generated SignedSource<<ca329ec06912ae2f0f17db58b630d34c>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type throwOnQueryError_servicedeskCustomerServiceCommon$data = {
  readonly extensions: ReadonlyArray<{
    readonly errorType: string | null | undefined;
    readonly statusCode: number | null | undefined;
  }> | null | undefined;
  readonly identifier: string | null | undefined;
  readonly message: string | null | undefined;
  readonly " $fragmentType": "throwOnQueryError_servicedeskCustomerServiceCommon";
};
export type throwOnQueryError_servicedeskCustomerServiceCommon$key = {
  readonly " $data"?: throwOnQueryError_servicedeskCustomerServiceCommon$data;
  readonly " $fragmentSpreads": FragmentRefs<"throwOnQueryError_servicedeskCustomerServiceCommon">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "throwOnQueryError_servicedeskCustomerServiceCommon",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "identifier"
    },
    {
      "kind": "ScalarField",
      "name": "message"
    },
    {
      "kind": "LinkedField",
      "name": "extensions",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "statusCode"
        },
        {
          "kind": "ScalarField",
          "name": "errorType"
        }
      ]
    }
  ],
  "type": "QueryError"
};

(node as any).hash = "fa4d32afd4039279d9b1db3545b08588";

export default node;
