import {
	CASCADING_SELECT_CF_TYPE,
	LABELS_CF_TYPE,
	LABELS_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { src_issueViewLayoutTemplatesLayoutItem_LayoutItemInternal$data as LayoutItemInternalData } from '@atlassian/jira-relay/src/__generated__/src_issueViewLayoutTemplatesLayoutItem_LayoutItemInternal.graphql';

export const isRelayDataReady = (
	relayData: LayoutItemInternalData | null,
): relayData is LayoutItemInternalData => relayData !== null;

export const isFieldTypeFullyMigratedToRelay = (type: string) => {
	switch (type) {
		case CASCADING_SELECT_CF_TYPE:
		case LABELS_TYPE:
		case LABELS_CF_TYPE:
			return true;
		default:
			return false;
	}
};
