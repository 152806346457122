import some from 'lodash/some';
// eslint-disable-next-line jira/import-whitelist
import { addSpanToAll } from '@atlaskit/react-ufo/interaction-metrics';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';

import type { MyPermissionsResponse } from './types.tsx';

const ADMINISTER_PROJECTS_PERMISSION = 'ADMINISTER_PROJECTS';
const getMyPermissionsGraphQLQuery = (projectKeyOrId: string) => `query myPermissionsDataQuery {
        mypermissions(${Number.isNaN(+projectKeyOrId) ? `projectKey: "${projectKeyOrId}"` : `projectId: "${projectKeyOrId}"`}
        permissions: "${ADMINISTER_PROJECTS_PERMISSION}") {
            key
            havePermission
        }
    }`;
const fetchMyPermissions = (projectKeyOrId: string): Promise<MyPermissionsResponse> =>
	performPostRequest('/rest/graphql/1/?', {
		method: 'POST',
		body: JSON.stringify({
			query: getMyPermissionsGraphQLQuery(projectKeyOrId),
		}),
	});

/**
 * Checks if the current user is an administrator of a given project. It evaluates user permissions,
 * handling any encountered errors gracefully. If permissions cannot be determined due to client errors,
 * a conservative approach is taken by assuming non-admin status to maintain security.
 */
export const isCurrentUserProjectAdmin = async (projectArg: string): Promise<boolean> => {
	const fetchStartTime = performance.now();

	const { data, errors } = await fetchMyPermissions(projectArg);

	addSpanToAll(
		'fetch',
		'fetchMyPermissions',
		[{ name: 'network' }],
		fetchStartTime,
		performance.now(),
	);

	const hasError = errors && errors.length > 0;

	if (hasError || !data?.mypermissions) {
		const error = new Error(
			`Project admin permission check failed: ${hasError ? errors[0].message : 'no data'}`,
		);
		// @ts-expect-error - TS2339 - Property 'statusCode' does not exist on type 'Error'.
		error.statusCode = hasError ? errors[0].statusCode : 500;
		// @ts-expect-error - TS2339 - Property 'skipSentry' does not exist on type 'Error'.
		error.skipSentry = hasError ? errors[0].statusCode === 404 : false;

		// We want to provide a degraded user experience if we get 4xx error and can't determine if the user is a project admin.
		if (hasError && errors[0].statusCode >= 400 && errors[0].statusCode <= 499) {
			return false;
		}

		throw error;
	}

	return some(data.mypermissions, {
		key: ADMINISTER_PROJECTS_PERMISSION,
		havePermission: true,
	});
};
