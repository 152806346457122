import type { SyntheticEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { IntlShape, MessageDescriptor } from '@atlassian/jira-intl/src/index.tsx';
import {
	APPROVAL_OK as OK,
	type Approval,
	type ApprovalState,
} from '@atlassian/jira-issue-shared-types/src/common/types/approval-type.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { GROUP_FIELD_TYPE, ALL_CONDITION_TYPE, NUMBER_CONDITION_TYPE } from './constants.tsx';

export type MessageDescriptorMap = {
	[key: string]: MessageDescriptor;
};

export const zeroApprovalRequired = (approval: Approval) =>
	approval.approvalState === OK && approval.pendingApprovalCount === 0;

/**
 * TODO: START-766: Remove all logic from the FE and just map BE error keys to messages
 * Format approval condition description for ALL_CONDITION_TYPE.
 * Helper function for getApprovalConditionDescription
 * @param approvalState             The state of the approval.
 * @param approversLength           Length of approvers
 * @param approverPrincipalsLength  Length of approver principals
 * @param fieldType                 Field type i.e. group field, multi user picker, etc.
 * @param fieldName                 Field name of the approver field
 * @param m                  A reference to the intl messages object.
 * @param formatMessage             The formatMessage() from intl
 * @returns                         A translated string.
 */
export const formatAllConditionDescription = (
	approvalState: ApprovalState | undefined,
	approversLength: number,
	approverPrincipalsLength: number,
	fieldType: string,
	fieldName: string,
	m: MessageDescriptorMap,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	formatMessage: (MessageDescriptor: MessageDescriptor, values?: any) => string,
) => {
	if (fieldType === GROUP_FIELD_TYPE) {
		switch (true) {
			case approvalState === OK:
				return formatMessage(m.allConditionTypeGroupApprovers, { fieldName });
			// Below are the cases where the approvalState !== OK and it is implied something is wrong
			case approverPrincipalsLength === 0:
				return formatMessage(m.allConditionTypeGroupApproversNoGroups, { fieldName });
			// it may seem overkill to split the below into 2 messages, but cannot put multiple
			case approverPrincipalsLength === 1:
				return formatMessage(m.allConditionTypeGroupApproversInsufficientMembersOneGroup, {
					fieldName,
				});
			case approverPrincipalsLength > 1: // more that 1 group
				return formatMessage(m.allConditionTypeGroupApproversInsufficientMembersMultipleGroups, {
					fieldName,
				});
			default: // just return the approvalState === OK message
				return formatMessage(m.allConditionTypeGroupApprovers, { fieldName });
		}
	} else {
		// fieldType === USER_FIELD_TYPE
		switch (true) {
			case approvalState === OK:
				return formatMessage(m.allConditionType, { fieldName });
			// Below are the cases where the approvalState !== OK and it is implied something is wrong
			case approversLength === 0:
				return formatMessage(m.allConditionTypeNoApprovers, { fieldName });
			default: // just return the approvalState === OK message
				return formatMessage(m.allConditionType, { fieldName });
		}
	}
};

/**
 * TODO: START-766: Remove all logic from the FE and just map BE error keys to messages
 * Format approval condition description for NUMBER_CONDITION_TYPE.
 * Helper function for getApprovalConditionDescription
 * @param approvalState             The state of the approval.
 * @param approversLength           Length of approvers
 * @param approverPrincipalsLength  Length of approver principals
 * @param fieldType                 Field type i.e. group field, multi user picker, etc.
 * @param args                      Object containing field name and value from configuration
 * @param m                         A reference to the intl messages object.
 * @param formatMessage             The formatMessage() from intl
 * @returns                         A translated string.
 */
export const formatNumberConditionDescription = (
	approvalState: ApprovalState | undefined,
	approversLength: number,
	approverPrincipalsLength: number,
	fieldType: string,
	args: { value: string; fieldName: string },
	m: MessageDescriptorMap,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	formatMessage: (MessageDescriptor: MessageDescriptor, values?: any) => string,
) => {
	if (fieldType === GROUP_FIELD_TYPE) {
		switch (true) {
			case approvalState === OK:
				return formatMessage(m.numberConditionTypeGroupApprovers, args);
			// Below are the cases where the approvalState !== OK and it is implied something is wrong
			// it may seem overkill to split the below into 2 messages, but cannot put multiple
			case approverPrincipalsLength === 0:
				return formatMessage(m.numberConditionTypeGroupApproversNoGroups, args);
			case approverPrincipalsLength > 0 && approversLength < Number(args.value):
				return formatMessage(m.numberConditionTypeGroupApproversInsufficientMembers, args);
			default: // just return the approvalState === OK message
				return formatMessage(m.numberConditionTypeGroupApprovers, args);
		}
	} else {
		// fieldType === USER_FIELD_TYPE
		switch (true) {
			case approvalState === OK:
				return formatMessage(m.numberConditionType, args);
			// Below are the cases where the approvalState !== OK and it is implied something is wrong
			case approversLength === 0:
				return formatMessage(m.numberConditionTypeNoApprovers, args);
			case approversLength < Number(args.value):
				return formatMessage(m.numberConditionTypeInsufficientApprovers, args);
			default: // just return the approvalState === OK message
				return formatMessage(m.numberConditionType, args);
		}
	}
};

/**
 * TODO: START-766: Remove all logic from the FE and just map BE error keys to messages
 * Format approval condition description for N_PER_BAG_CONDITION_TYPE.
 * Helper function for getApprovalConditionDescription
 * @param approvalState             The state of the approval.
 * @param approverPrincipalsLength  Length of approver principals
 * @param fieldName                 Field name of the approver field
 * @param args                      Object containing field name and value from configuration
 * @param m                         A reference to the intl messages object.
 * @param formatMessage             The formatMessage() from intl
 * @returns                         A translated string.
 */
export const formatNPerBagConditionDescription = (
	approvalState: ApprovalState | undefined,
	approverPrincipalsLength: number,
	fieldName: string,
	args: { value: string; fieldName: string },
	m: MessageDescriptorMap,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	formatMessage: (MessageDescriptorV2: MessageDescriptor, values?: any) => string,
) => {
	switch (true) {
		case approvalState === OK:
			return formatMessage(m.nPerBagConditionType, args);
		// Below are the cases where the approvalState !== OK and it is implied something is wrong
		case approverPrincipalsLength === 0:
			return formatMessage(m.nPerBagConditionTypeNoGroups, args);
		// again, it may seem overkill to split the below into 2 messages, but cannot put multiple
		// plurals in one message due to the difficulty it creates localising
		case args.value === '1':
			return formatMessage(m.nPerBagConditionTypeNoMembers, { fieldName });
		default: // rule is more than one person per bag
			return formatMessage(m.nPerBagConditionTypeInsufficientMembers, args);
	}
};

/**
 * Get approval condition description. This displays below the summary in the glance panel, either as
 * the body or as the body of the warning depending on approvalState.
 * @param approval         The approval object.
 * @param messages         A reference to the intl messages object.
 * @param intl             A reference to the intl object containing formatMessage()
 * @returns                A translated string.
 */
export const getApprovalConditionDescription = (
	{ approvalState, configuration, approvers, approverPrincipals }: Approval,
	m: MessageDescriptorMap,
	{ formatMessage }: IntlShape,
) => {
	const fieldType = configuration.approvers.type;
	const fieldName = configuration.approvers.customFieldName;
	const conditionType = configuration.condition.type;
	const { value } = configuration.condition;
	const args = { value, fieldName };

	switch (conditionType) {
		case ALL_CONDITION_TYPE:
			return formatAllConditionDescription(
				approvalState,
				approvers.length,
				approverPrincipals?.length ?? 0,
				fieldType,
				fieldName,
				m,
				formatMessage,
			);
		case NUMBER_CONDITION_TYPE:
			return formatNumberConditionDescription(
				approvalState,
				approvers.length,
				approverPrincipals?.length ?? 0,
				fieldType,
				args,
				m,
				formatMessage,
			);
		default: // nPerBagConditionType
			return formatNPerBagConditionDescription(
				approvalState,
				approverPrincipals?.length ?? 0,
				fieldName,
				args,
				m,
				formatMessage,
			);
	}
};

/**
 * Get approval warning title. This displays as the title of the warning section when approvalState != OK
 * @param approval         The approval object.
 * @param messages         A reference to the intl messages object.
 * @param intl             A reference to the intl object containing formatMessage()
 * @returns                A translated string.
 */
export const getApprovalWarningTitle = (
	{ approvalState, configuration }: Approval,
	m: MessageDescriptorMap,
	{ formatMessage }: IntlShape,
) => {
	if (approvalState === OK) return ''; // Assuming this should not happen

	const fieldType = configuration.approvers.type;
	const conditionType = configuration.condition.type;
	const { value } = configuration.condition;

	switch (conditionType) {
		case ALL_CONDITION_TYPE:
			switch (true) {
				case fieldType !== GROUP_FIELD_TYPE: // approvers.length === 0 implied from condition not being OK
					return formatMessage(m.addApproversWarning, { value: 1 });
				default: // Group field type
					return formatMessage(m.updateApproverGroupsWarning);
			}
		case NUMBER_CONDITION_TYPE:
			switch (true) {
				case fieldType !== GROUP_FIELD_TYPE:
					return formatMessage(m.addApproversWarning, { value });
				default: // from group source
					return formatMessage(m.updateApproverGroupsWarning);
			}
		default: // nPerBagConditionType
			// Group source warnings were simplified to 'Update', nPerBag can only be from group source.
			return formatMessage(m.updateApproverGroupsWarning);
	}
};

/**
 * Get approval summary.
 * @param approval         The approval object.
 * @param canEditApprovers Describes whether the current user can edit who can approve.
 * @param messages         A reference to the intl messages object.
 * @param intl             A reference to the intl object containing formatMessage()
 * @returns                A translated string.
 */
export const getApprovalSummary = (
	{ pendingApprovalCount, approvalState, configuration /* approvers */ }: Approval,
	canEditApprovers: boolean,
	messages: MessageDescriptorMap,
	{ formatMessage }: IntlShape,
) => {
	switch (true) {
		case approvalState === OK || configuration.condition.type === NUMBER_CONDITION_TYPE:
			return formatMessage(messages.waitingForApprovals, { pendingApprovalCount });
		default:
			return formatMessage(messages.waitingForApproval);
	}
};

// Generic manipulation functions
// ------------------------------------------------------------------------------------------------

export const get =
	<O extends { [k: string]: unknown }, K extends keyof O>(key: K) =>
	(x: O) =>
		x[key];

export const keys = <O extends { [k: string]: unknown }>(obj: O): Array<keyof O> =>
	Object.keys(obj);

// This is a legacy from Flow. At the time, it was the only version of assign that Flow understood.
// Flow wouldn't read past the first parameter to Object.assign(), and cast anything coming out of
// lodash/assign to be a generic object.
export const assign =
	<A extends { [k: string]: unknown }, B extends { [k: string]: unknown }>(
		a: A,
	): ((b: B) => A & B) =>
	(b: B): A & B => ({ ...a, ...b });

const isArray = <A, Arr extends readonly A[]>(a: A | Arr): a is Arr => Array.isArray(a);

// Check the value of a variable. If it's null or undefined, return an empty array. If it’s
// already an array, then return the array untouched. Otherwise return the value in an array.
export const toArray = <A,>(x?: A | ReadonlyArray<A>): ReadonlyArray<A> => {
	if (x === null || x === undefined) return [];
	// @ts-expect-error - Type 'A & readonly (readonly A[] | (A & {}))[]' is not assignable to type 'readonly A[]'.
	if (isArray(x)) /*              */ return x;
	// @ts-expect-error - Type 'readonly A[] | (A & {})' is not assignable to type 'A'.
	/* otherwise                    */ return [x];
};

export const compose2 =
	<A, B, C>(f: (b: B) => C, g: (a: A) => B): ((a: A) => C) =>
	(x: A): C =>
		f(g(x));

export const toError = (e: Error | string): Error => (e instanceof Error ? e : new Error(e));

export const withUIAnalyticsEvent =
	<A, B>(
		id: string,
		handler: (arg1: SyntheticEvent<A>, arg2: UIAnalyticsEvent) => B,
		fire?: (arg1: UIAnalyticsEvent, arg2: string) => void,
	): ((arg1: SyntheticEvent<A>, arg2: UIAnalyticsEvent) => B) =>
	(uiEvent: SyntheticEvent<A>, analyticsEvent: UIAnalyticsEvent): B => {
		(fire || fireUIAnalytics)(analyticsEvent, id);
		return handler(uiEvent, analyticsEvent);
	};
