import React, { type FC } from 'react';
import Button from '@atlaskit/button/new';
import { Box, xcss } from '@atlaskit/primitives';
import { AiIcon } from '@atlassian/jira-atlassian-intelligence/src/common/ui/ai-icon/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	useRelatedResourcesActions,
	useRelatedResourcesState,
} from '../../controllers/related-resources-context.tsx';
import messages from '../messages.tsx';

type Props = {
	onShowAISuggestionsClicked: () => void;
};

export const SuggestRelatedResourcesButton: FC<Props> = ({ onShowAISuggestionsClicked }) => {
	const { formatMessage } = useIntl();
	const { fetchSuggestions } = useRelatedResourcesActions();
	const { isLoadingSuggestions } = useRelatedResourcesState();

	return (
		<Box xcss={buttonStyles}>
			<Button
				spacing="compact"
				isDisabled={isLoadingSuggestions}
				onClick={() => {
					onShowAISuggestionsClicked();
					fetchSuggestions('suggestConfluencePagesButton');
				}}
				iconBefore={(iconProps) => (
					<AiIcon
						{...iconProps}
						label={formatMessage(messages.suggestConfluencePages)}
						size="small"
					/>
				)}
			>
				{formatMessage(messages.suggestConfluencePages)}
			</Button>
		</Box>
	);
};

const buttonStyles = xcss({
	marginInlineStart: 'space.075',
});
