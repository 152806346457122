export const NO_CATEGORY = 'undefined';
export const TODO = 'new';
export const IN_PROGRESS = 'indeterminate';
export const DONE = 'done';

export type StatusCategoryKey = 'new' | 'indeterminate' | 'done' | 'undefined';

export const toKnownStatusCategoryKey = (rawCategoryKey: string | undefined): StatusCategoryKey => {
	switch (rawCategoryKey) {
		case TODO:
			return TODO;
		case IN_PROGRESS:
			return IN_PROGRESS;
		case DONE:
			return DONE;
		default:
			return NO_CATEGORY;
	}
};

export const toKnownStatusCategoryMap = (
	categories: StatusCategory[],
): Record<StatusCategoryKey, StatusCategory> | undefined => {
	const unsafeMap: Partial<Record<StatusCategoryKey, StatusCategory>> = {};
	categories.forEach((category) => {
		unsafeMap[toKnownStatusCategoryKey(category.key)] = category;
	});

	if (!unsafeMap[TODO] || !unsafeMap[IN_PROGRESS] || !unsafeMap[DONE] || !unsafeMap[NO_CATEGORY]) {
		return undefined;
	}

	return {
		[TODO]: unsafeMap[TODO],
		[IN_PROGRESS]: unsafeMap[IN_PROGRESS],
		[DONE]: unsafeMap[DONE],
		[NO_CATEGORY]: unsafeMap[NO_CATEGORY],
	};
};

export type StatusCategory = {
	id: number;
	colorName: string;
	name: string;
	key: StatusCategoryKey;
};

export type StatusFieldValue = {
	id: string;
	name: string;
	statusCategory: {
		id: number;
		name: string;
		key: StatusCategoryKey;
	};
};
