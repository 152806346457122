/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//Estonian
export default {
	'fabric.elements.share.copied.to.clipboard.message': 'Link kopeeritud lõikelauale',
	'fabric.elements.share.copylink.button.text': 'Kopeeri link',
	'fabric.elements.share.failure.icon.label': 'Vea ikoon',
	'fabric.elements.share.failure.message': 'Jagamine nurjus',
	'fabric.elements.share.form.comment.placeholder': 'Sõnumi lisamine',
	'fabric.elements.share.form.retry': 'Proovi uuesti',
	'fabric.elements.share.form.send': 'Saada',
	'fabric.elements.share.form.title': 'Jaga',
	'fabric.elements.share.form.user-picker.add-more': 'Sisesta veel',
	'fabric.elements.share.form.user-picker.no-options.existingUserOnly':
		'Me ei suutnud leida ühtegi tulemust seoses otsitavaga „{inputValue}".',
	'fabric.elements.share.form.user-picker.no-options.generic':
		'Me ei suutnud leida ühtegi tulemust seoses otsitavaga „{inputValue}". Kutsu inimesi e-posti aadressi alusel.',
	'fabric.elements.share.form.user-picker.placeholder.existingUserOnly':
		'Sisesta nimi või meeskond',
	'fabric.elements.share.form.user-picker.placeholder.generic':
		'Sisestage nimi, rühm, meeskond või meil',
	'fabric.elements.share.form.user-picker.placeholder.jira': 'Sisestage nimi, tiim või meil',
	'fabric.elements.share.form.user-picker.validation.required':
		'Valige vähemalt üks kasutaja, rühm, meeskond või meil.',
	'fabric.elements.share.form.user-picker.validation.required.jira':
		'Valige vähemalt üks kasutaja, tiim või meil.',
	'fabric.elements.share.success.message':
		'{object, select, blogpost {Blogipostitus jagatud} board {Töölaud jagatud} calendar {Kalender jagatud} draft {Mustand jagatud} filter {Filter jagatud} issue {Probleem jagatud} media {Meediafail jagatud} page {Leht jagatud} project {Projekt jagatud} pullrequest {Tõmbekutse jagatud} question {Küsimus jagatud} report {Raport jagatud} repository {Koodihoidla jagatud} request {Taotlus jagatud} roadmap {Tööplaan jagatud} site {Sait jagatud} space {Ruum jagatud} other {Link jagatud}}',
	'fabric.elements.share.trigger.button.icon.label': 'Jagamisikoon',
	'fabric.elements.share.trigger.button.invite.text': 'Kutsu',
	'fabric.elements.share.trigger.button.text': 'Jaga',
	'fabric.elements.share.trigger.button.tooltip.text': 'Jaga',
};
