/**
 * Feature flags convention: go/frontend-feature-flags
 */
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { ff } from '@atlassian/jira-feature-flagging';

// <ssaraswat>
export const isQuickActionExperimentFF = () => ff('issue_view_quick_actions_revamp_l8mbx');
// </ssaraswat>

// <ugouhar>
export const isDataClassificationEnabled = () =>
	ff('jira-issue-history-data-classification_mhyjw', false);
// </lli7>
// Rach slot
// start

// 2
// 3
// Rach slot

// @pierrelingat slot start

// <crobertson>

// on cleanup, please remove from src/packages/issue/test-util/src/main.js also
export const isSwitcherooRealtimeExposuresTestFlagEnabled = () =>
	ff('switcheroo-realtime-exposures-test-flag_648u4', false);

// </crobertson>

// <fnio>
// </fnio>

// <jsmTimeline>
export const isJsmTimelineEnabled = (): boolean => ff('jsm-timelines-phase-2_70gja', false);

// <jsmTimeline>

// <asharma9>

export const isHideEmptyFieldsEnabled = () =>
	ff('ken-545-display-hide-when-empty-button-to-user-_fjpw9');

// </asharma9>

// </ssharma9>
export const isArchivedIssuesPageLinkEnabled = () => ff('enable-archived-issues-page-link', false);
// </ssharma9>

// <mbiniek>
// </mbiniek>

// <itsm-agni>
export const isConferenceCallFieldHidden = () => ff('hide-icc-for-all-users_avyh5', false);
// <itsm-agni>

// <kmaharjan4>
export const isDevOpsAppRecommenderEnabled = () =>
	ff('devops_app_recommendation_on_link_paste', false) &&
	expValEquals('devops_app_recommendation_on_link_paste', 'cohort', 'variation');
// </kmaharjan4>

// <aullal>
export const isTokensAndStyledComponentsMigrationEnabled = () =>
	ff('im-tokens-and-styled-components-migration_qpsld', false);
// <aullal>

// <rkumar8>
export const isIssueArchivalFeatureEnabled = () => ff('issue-unarchival_wdpb9', false);
// </rkumar8>

// <dxu>
export const aggUseIsEditableInViewFlag = () =>
	ff('jiv-17158-agg-use-iseditableinview_8nogh', false);
// </dxu>

// vyakimenko
export const isCascadingSelectSupportForUIMEnabled = () =>
	ff('jsw.xflow.issue-view-nudge-experiment', false);
// vyakimenko

// <sms2>
export const is3pIssueWatchNudgeEnabled = () =>
	ff('third_party_app_nudge_on_issues_watch', false) &&
	expValEquals('third_party_app_nudge_on_issues_watch', 'cohort', 'variation');
// </sms2>

// <ksingh4>
// </ksingh4>

// <jgrose>
export const isRelayMigrationIssueFieldsSprintEnabled = () =>
	ff('relay-migration-issue-fields-sprint_jozpu');
// </jgrose>

// <aoshannessy>
export const isTeamAttributeAdded = () => ff('add-team-id-analytics-data');
// </aoshannessy>

export const isRelayMigrationIssueFieldSummaryEnabled = () =>
	ff('relay-migration-issue-fields-summary_eog1v', false);
// <relay-code-gen-Summary>

// <relay-code-gen-TimeTracking>
export const isRelayMigrationIssueFieldTimeTrackingEnabled = () =>
	ff('relay-migration-issue-fields-time-tracking_eefh0', false);
// <relay-code-gen-TimeTracking>

// <relay-code-gen-UserPicker>
export const isRelayMigrationIssueFieldParticipantsEnabled = () =>
	ff('relay-migration-issue-fields-participants', false);
// <relay-code-gen-UserPicker>

// <relay-code-gen-UserPicker>
export const isRelayMigrationIssueFieldRequestParticipantsEnabled = () =>
	ff('relay-migration-issue-fields-request-participants_itocm', false);
// <relay-code-gen-UserPicker>
