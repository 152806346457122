import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	criticalAlertPriority: {
		id: 'servicedesk-incident-management-common.critical-alert-priority',
		defaultMessage: 'Critical',
		description: 'Label for Opsgeneie alert Priority P1 - Critical',
	},
	highAlertPriority: {
		id: 'servicedesk-incident-management-common.high-alert-priority',
		defaultMessage: 'High',
		description: 'Label for Opsgeneie alert Priority P2 - High',
	},
	moderateAlertPriority: {
		id: 'servicedesk-incident-management-common.moderate-alert-priority',
		defaultMessage: 'Moderate',
		description: 'Label for Opsgeneie alert Priority P3 - Moderate',
	},
	lowAlertPriority: {
		id: 'servicedesk-incident-management-common.low-alert-priority',
		defaultMessage: 'Low',
		description: 'Label for Opsgeneie alert Priority P4 - Low',
	},
	informationalAlertPriority: {
		id: 'servicedesk-incident-management-common.informational-alert-priority',
		defaultMessage: 'Informational',
		description: 'Label for Opsgeneie alert Priority P5 - Informational',
	},
});
