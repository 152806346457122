import React from 'react';
import type { CustomGlyphProps } from '@atlaskit/icon';

export const cardLayoutGlyph = (props: CustomGlyphProps) => (
	<svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
		<path fill="currentColor" fillOpacity=".01" d="M0 0h24v24H0z" />
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M5 5h14a1 1 0 1 1 0 2H5a1 1 0 0 1 0-2Zm0 12h6a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Zm0-8h6a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1Z"
			clipRule="evenodd"
		/>
		<path
			fill="currentColor"
			d="m17.11 10.776-.657.944a.604.604 0 1 1-.854-.855l1.581-1.581A.617.617 0 0 1 17.715 9c.232 0 .433.115.534.284l1.581 1.581a.605.605 0 0 1-.854.855l-.657-.944v5.48c0 .294-.27.533-.604.533-.334 0-.605-.239-.605-.533v-5.48Zm1.139 7.845a.617.617 0 0 1-.534.284.617.617 0 0 1-.535-.284L15.6 17.039a.604.604 0 0 1 .854-.855l.657.945v-2.225c0-.294.27-.533.605-.533.333 0 .604.239.604.533v2.225l.657-.945a.605.605 0 0 1 .854.855l-1.581 1.582Z"
		/>
	</svg>
);
