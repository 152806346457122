import type React from 'react';
import { useEffect, useCallback } from 'react';
import { useFlagsService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { useIntl } from '@atlassian/jira-intl';
import {
	fireUIAnalytics,
	fireTrackAnalytics,
	useAnalyticsEvents,
	type UIAnalyticsEvent,
} from '@atlassian/jira-product-analytics-bridge';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useEnvironment } from '@atlassian/jira-tenant-context-controller/src/components/environment/index.tsx';
import { useLoomRecorderEntryPoint } from '@atlassian/loom-utils';
import { useJiraLoomSDKProductName } from '../../common/utils.tsx';
import { useLoomRecorderLoadingBlanket } from '../use-loom-recorder-loading-blanket/index.tsx';
import { useLoomTouchpointVariant } from '../use-loom-touchpoint-variant/index.tsx';
import { LoomTouchpointVariant } from '../use-loom-touchpoint-variant/types.tsx';
import messages from './messages.tsx';
import type { UseJiraLoomRecorderEntryPointProps } from './types.tsx';

function reloadPage() {
	if (typeof window !== 'undefined') {
		window.location.reload();
	}
}

export const useJiraLoomRecorderEntryPoint = ({
	entryPointLocation,
	onInsert,
	insertButtonText,
	renderOnInsertFallbackWhen,
	skipInitialisation,
}: UseJiraLoomRecorderEntryPointProps) => {
	const { formatMessage } = useIntl();
	const { showFlag } = useFlagsService();
	const cloudId = useCloudId();
	const environment = useEnvironment();
	const { loomTouchpointVariant } = useLoomTouchpointVariant();
	const productName = useJiraLoomSDKProductName();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [, { hideBlanket }] = useLoomRecorderLoadingBlanket();

	const { loomInitError, openLoomRecorder, recorderState } = useLoomRecorderEntryPoint({
		environment,
		cloudId,
		productName: productName ?? 'UNKNOWN',
		skipInitialisation: skipInitialisation || loomTouchpointVariant !== LoomTouchpointVariant.COUSE,
		entryPointLocation,
		onInsert: (loomVideoUrl, video) => {
			onInsert(loomVideoUrl, video);
			fireTrackAnalytics(createAnalyticsEvent({}), 'loomVideoLink inserted');
		},
		insertButtonText,
		renderOnInsertFallbackWhen,
		eventHandler: {
			// DONT USE OUR PATTERN, if you want, reach to us(Growth: Crossflow)
			// Loom has its own analytics event records, so due to the project schedule, we adopted the simplest method
			// and made a temporary version first. We will do the refactor after Loom's analytics are ready
			// (or if we find out that there is no intention on their end to do the integration any time soon)
			onStart: () =>
				fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'loomRecordingStart'),
			onRecordingStarted: () =>
				fireTrackAnalytics(createAnalyticsEvent({}), 'loomRecording started'),
			onCancel: () =>
				fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'loomRecordingCancel'),
			onComplete: () =>
				fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'loomRecordingComplete'),
			onRecordingComplete: () =>
				fireTrackAnalytics(createAnalyticsEvent({}), 'loomRecording completed'),
			onUploadComplete: () =>
				fireTrackAnalytics(createAnalyticsEvent({}), 'loomRecording uploaded'),
			onInsertClicked: (isInsertFallbackRendered: boolean) =>
				fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'insertVideoLink', {
					isInsertFallbackRendered,
				}),
		},
	});

	useEffect(() => {
		if (loomInitError) {
			hideBlanket();
		}
	}, [loomInitError, hideBlanket]);

	useEffect(() => {
		// when cross-flowing, we need to hide the loading blanket at this point.
		// No harm hiding it when it's already hidden in other cases, though.
		if (recorderState === 'pre-recording') {
			hideBlanket();
		}
	}, [hideBlanket, recorderState]);

	const fireMergeAccountsClickAnalytic = useCallback(
		(e?: React.MouseEvent<HTMLElement>, analyticsEvent?: UIAnalyticsEvent | undefined) => {
			fireUIAnalytics(
				analyticsEvent ?? createAnalyticsEvent({}),
				'button clicked',
				'loomRecorderErrorFlagMergeAccountsAction',
			);
		},
		[createAnalyticsEvent],
	);

	const wrappedOpenLoomRecorder = useCallback(() => {
		if (loomInitError) {
			switch (loomInitError.type) {
				case 'unsupported-browser':
					showFlag({
						id: 'loomInitErrorUnsupportedBrowser',
						type: 'error',
						title: messages.errorTitleInitFailed,
						description: formatMessage(messages.errorInitFailedBrowserUnsupported),
						actions: [
							{
								content: messages.errorActionBrowserUnsupported,
								target: '_blank',
								href: 'https://support.loom.com/hc/en-us/articles/360002228418-Can-I-use-Loom-with-Firefox-Safari-etc',
							},
						],
					});
					break;
				case 'unsupported-third-party-cookie':
					showFlag({
						id: 'loomInitErrorCookie',
						type: 'error',
						title: messages.errorTitleInitFailed,
						description: formatMessage(messages.errorInitFailedThirdPartyCookiesDisabled),
					});
					break;
				case 'unsupported-media-streaming':
					showFlag({
						id: 'loomInitErrorUnsupportedMediaStreaming',
						type: 'error',
						title: messages.errorTitleInitFailed,
						description: formatMessage(messages.errorInitFailedStreamingUnsupported),
					});
					break;
				case 'token-unsuccessful-unmerged-user':
					showFlag({
						id: 'loomInitErrorUnmergedUser',
						type: 'error',
						title: messages.errorTitleUnmergedAccount,
						description: formatMessage(messages.errorUnmergedAccount),
						actions: [
							{
								content: messages.errorActionMergeAccounts,
								target: '_blank',
								href:
									environment === 'staging'
										? 'https://stage.loom.com/merge-atlassian-profile'
										: 'https://www.loom.com/merge-atlassian-profile',
								onClick: fireMergeAccountsClickAnalytic,
							},
						],
					});
					break;
				case 'loom-internal-error':
				default:
					showFlag({
						id: 'loomInitErrorInternal',
						type: 'error',
						title: messages.errorTitleInitFailed,
						description: formatMessage(messages.errorInitFailedInternal),
						actions: [
							{
								content: messages.errorActionRefresh,
								onClick: reloadPage,
							},
						],
					});
			}
			fireTrackAnalytics(createAnalyticsEvent({}), 'loomRecording refused', {
				reason: loomInitError.type,
			});
			return;
		}

		const { isSuccess, failureReason } = openLoomRecorder();
		if (!isSuccess) {
			hideBlanket();
			if (failureReason === 'already-recording') {
				showFlag({
					id: 'loomAlreadyRecording',
					type: 'error',
					title: messages.errorTitleRecordingAlreadyInProgress,
					description: formatMessage(messages.errorRecordingAlreadyInProgress),
				});
				// this will only happen if loom reports it is initialised before that's actually the case
			} else if (failureReason === 'still-initialising') {
				showFlag({
					id: 'loomStillInitialising',
					type: 'error',
					title: messages.errorTitleSdkNotInitialized,
					description: formatMessage(messages.errorSdkNotInitialized),
					actions: [
						{
							content: messages.errorActionRefresh,
							onClick: reloadPage,
						},
					],
				});
			} else {
				// This should never happen
				showFlag({
					id: 'loomUnknownError',
					type: 'error',
					title: messages.errorTitleUnknown,
					description: formatMessage(messages.errorUnknown),
					actions: [
						{
							content: messages.errorActionRefresh,
							onClick: reloadPage,
						},
					],
				});
			}
		}
	}, [
		openLoomRecorder,
		showFlag,
		formatMessage,
		hideBlanket,
		loomInitError,
		createAnalyticsEvent,
		fireMergeAccountsClickAnalytic,
		environment,
	]);

	return {
		openLoomRecorder: wrappedOpenLoomRecorder,
		recorderState,
	};
};
