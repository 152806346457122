import type { InvestigateIncidentUi } from '@atlassian/jira-issue-view-common-types/src/investigate-incident-type.tsx';
import {
	type InvestigateIncidentClicked,
	type InvestigateIncidentModalClosed,
	INVESTIGATE_INCIDENT_CLICKED,
	INVESTIGATE_INCIDENT_MODAL_CLOSED,
} from '../actions/investigate-incident-actions.tsx';

type State = InvestigateIncidentUi;

type Action = InvestigateIncidentClicked | InvestigateIncidentModalClosed;

export const initialState: State = {
	showInvestigateIncidentModal: false,
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: State = initialState, action: Action): State => {
	switch (action.type) {
		case INVESTIGATE_INCIDENT_CLICKED:
			return {
				...state,
				showInvestigateIncidentModal: true,
			};
		case INVESTIGATE_INCIDENT_MODAL_CLOSED:
			return {
				...state,
				showInvestigateIncidentModal: false,
			};
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
