import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	emptyState: {
		id: 'servicedesk-customer-service-issue-view-panel.customer.empty-state',
		defaultMessage: 'Add a reporter to view customer details',
		description:
			'Empty state text shown when the reporter field has not been filled out, so we dont show the user any details about the customer.',
	},
	customer: {
		id: 'servicedesk-customer-service-issue-view-panel.customer.customer',
		defaultMessage: 'Customer details',
		description:
			'Section header in the issue view panel indicating which customer reported the issue',
	},
});
