import React from 'react';
import { AnalyticsSubject } from '@atlassian/jira-analytics-web-react/src/components/decorators.tsx';
import withFireUiAnalytics from '@atlassian/jira-analytics-web-react/src/components/with-fire-ui-analytics.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import ReportErrors from '@atlassian/jira-errors-handling/src/utils/reporting-error-boundary.tsx';
import LogTimeModal, { type Props } from './view.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { LogTimeValidator } from './view';

const WrappedModal = withFireUiAnalytics({
	onSubmit: 'clicked',
	onCancel: 'clicked',
	onToggleRollingUpData: 'toggleRollingUpData',
})(AnalyticsSubject('worklogModal')(LogTimeModal));

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (props: Props) => (
	<ErrorBoundary id="issue.issue-view.common.worklog-dialog">
		<ReportErrors
			id="common.worklog-dialog"
			packageName="jiraIssueViewCommon"
			sendToPrivacyUnsafeSplunk
		>
			<WrappedModal {...props} />
		</ReportErrors>
	</ErrorBoundary>
);
