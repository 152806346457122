import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';

/**
 * Abstracted Helper with GasV2 and GasV3 errors
 */
export const trackClientFetchError = (
	location: string,
	message: string,
	error: TypeError,
): void => {
	fireErrorAnalytics({
		error,
		attributes: { message },
		meta: { id: location, packageName: 'jiraIssueViewCommonUtils' },
	});
};

export const trackOrLogClientError = (
	location: string,
	message: string,
	error: Error | TypeError,
): void => {
	if (error instanceof TypeError && isClientFetchError(error)) {
		trackClientFetchError(location, message, error);
	} else {
		log.safeErrorWithoutCustomerData(location, message, error);
	}
};
