import {
	type Action,
	createContainer,
	createStore,
	createHook,
} from '@atlassian/react-sweet-state';

type State = {
	shouldShowAddDesignForm: boolean;
	wasAddDesignButtonJustClicked: boolean;
};

export const setShouldShowAddDesignForm =
	(shouldShow: boolean): Action<State> =>
	({ setState }) => {
		setState({
			shouldShowAddDesignForm: shouldShow,
			wasAddDesignButtonJustClicked: shouldShow,
		});
	};

export const resetShouldShowAddDesignForm =
	(): Action<State> =>
	({ setState }) => {
		setState({ shouldShowAddDesignForm: false });
	};

const resetWasAddDesignButtonJustClicked =
	(): Action<State> =>
	({ setState }) => {
		setState({ wasAddDesignButtonJustClicked: false });
	};

export const actions = {
	setShouldShowAddDesignForm,
	resetShouldShowAddDesignForm,
	resetWasAddDesignButtonJustClicked,
} as const;

export type Actions = typeof actions;

export const initialState: State = {
	shouldShowAddDesignForm: false,
	wasAddDesignButtonJustClicked: false,
};

export const Store = createStore<State, Actions>({
	name: 'designPanelStore',
	initialState,
	actions,
});

export const useDesignPanelStore = createHook(Store);

export const DesignPanelContainer = createContainer(Store);
