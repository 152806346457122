import { MentionNameStatus } from '@atlaskit/mention';

const bulkUserPath = '/rest/api/3/user/bulk';
const MAX_MENTIONS = 50;

interface MentionUser {
	accountId: string;
	displayName: string;
	status: MentionNameStatus;
}

// this is a MentionName client copied from how Confluence currently does it suggested by Editor
export default class MentionNameClient {
	getLookupLimit() {
		// HOT-108964 - sending too many mentions in the request header at once returns a 400
		// The API itself apparently has a hard limit of 90 (HOT-95106), but if we're not careful we can send too many
		// mentions and make the request header too large and the endpoint falls over
		return MAX_MENTIONS;
	}

	async lookupMentionNames(ids: string[]) {
		const params: string = ids.reduce(
			(idParams: string, id: string, index: number) =>
				`${idParams}${index > 0 ? '&' : ''}accountId=${encodeURIComponent(id)}`,
			'',
		);

		return fetch(`${bulkUserPath}?${params}&maxResults=${MAX_MENTIONS}`, {
			credentials: 'same-origin',
		})
			.then((response) => response.json())
			.then((data) => {
				if (!data || !data.values || !data.values.length) {
					return [];
				}
				return data.values.filter(Boolean).map((user: MentionUser) => ({
					id: user.accountId,
					name: user.displayName,
					status: MentionNameStatus.OK,
				}));
			});
	}
}
