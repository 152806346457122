import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import {
	cancelFailedWebLinkDeletion,
	createWebLinkRetry,
	deleteOptimisticWebLink,
	deleteWebLinkRequest,
} from '@atlassian/jira-issue-view-store/src/actions/web-links-actions.tsx';
import { hasActivatedConfluenceWithinFourteenDaysSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import SmartLinkComponent from './main.tsx';
import type { Props, DispatchProps, StateProps } from './types.tsx';

const mapStateToProps =
	() =>
	(state: State): StateProps => ({
		hasActivatedConfluenceWithinFourteenDays:
			hasActivatedConfluenceWithinFourteenDaysSelector(state),
	});

export default connect<StateProps, DispatchProps, Props, State>(
	mapStateToProps,
	(dispatch, { linkId }) => ({
		onCreateWebLinkRetry: () => {
			dispatch(createWebLinkRetry(linkId));
		},
		onCreateWebLinkCancel: () => {
			dispatch(deleteOptimisticWebLink(linkId));
		},
		onDeleteWebLinkRetry: () => {
			dispatch(deleteWebLinkRequest(linkId));
		},
		onDeleteWebLinkCancel: () => {
			dispatch(cancelFailedWebLinkDeletion(linkId));
		},
	}),
)(SmartLinkComponent);
