import React from 'react';
import type { CustomGlyphProps } from '@atlaskit/icon';

export const timelineGlyph = (props: CustomGlyphProps) => (
	<svg {...props} width="25" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fill="#fff" fillOpacity=".01" d="M.5 0h24v24H.5z" />
		<rect x="4.5" y="5" width="12" height="2" rx="1" fill="currentColor" />
		<rect x="7.5" y="9" width="12" height="2" rx="1" fill="currentColor" />
		<rect x="5.5" y="13" width="12" height="2" rx="1" fill="currentColor" />
		<rect x="8.5" y="17" width="12" height="2" rx="1" fill="currentColor" />
	</svg>
);
