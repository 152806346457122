export const ISSUE_DESIGNS_ANALYTICS_SOURCE = 'issue-view-designs' as const;
export const LOAD_DESIGN_PANEL_QUERY_EXPERIENCE = 'loadDesignPanelQuery' as const;
export const VIEW_DESIGN_SECTION_EXPERIENCE = 'viewDesignSection' as const;
export const VIEW_ADD_DESIGN_BUTTON_EXPERIENCE = 'viewAddDesignButton' as const;
export const LINK_DESIGN_EXPERIENCE = 'linkDesign' as const;
export const UNLINK_DESIGN_EXPERIENCE = 'unlinkDesign' as const;
export const VIEW_EXPANDED_DESIGN_EXPERIENCE = 'viewExpandedDesign' as const;

export type IssueDesignsExperiences =
	| typeof LOAD_DESIGN_PANEL_QUERY_EXPERIENCE
	| typeof VIEW_DESIGN_SECTION_EXPERIENCE
	| typeof VIEW_ADD_DESIGN_BUTTON_EXPERIENCE
	| typeof LINK_DESIGN_EXPERIENCE
	| typeof UNLINK_DESIGN_EXPERIENCE
	| typeof VIEW_EXPANDED_DESIGN_EXPERIENCE;
