/**
 * @generated SignedSource<<14d002df574b1a4c9436c0ddd6de0acd>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type view_issueViewFoundation_IssueBreadcrumbsNew$data = {
  readonly " $fragmentSpreads": FragmentRefs<"addParent_issueViewFoundation_BreadcrumbAddParent" | "currentIssue_issueViewFoundation_BreadcrumbCurrentIssue_issue" | "parentIssue_issueViewFoundation_BreadcrumbParentIssueNew">;
  readonly " $fragmentType": "view_issueViewFoundation_IssueBreadcrumbsNew";
};
export type view_issueViewFoundation_IssueBreadcrumbsNew$key = {
  readonly " $data"?: view_issueViewFoundation_IssueBreadcrumbsNew$data;
  readonly " $fragmentSpreads": FragmentRefs<"view_issueViewFoundation_IssueBreadcrumbsNew">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "view_issueViewFoundation_IssueBreadcrumbsNew",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "addParent_issueViewFoundation_BreadcrumbAddParent"
    },
    {
      "kind": "FragmentSpread",
      "name": "parentIssue_issueViewFoundation_BreadcrumbParentIssueNew"
    },
    {
      "kind": "FragmentSpread",
      "name": "currentIssue_issueViewFoundation_BreadcrumbCurrentIssue_issue"
    }
  ],
  "type": "JiraIssue"
};

(node as any).hash = "15af7ffe969bd1475dba179343dd25dc";

export default node;
