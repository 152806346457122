import React, { useCallback } from 'react';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const ExportIssueXML = ({ baseUrl, issueKey }: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const onClick = useCallback(() => {
		const analyticsEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'dropdownItem',
		});
		fireUIAnalytics(analyticsEvent, 'exportXml');
	}, [createAnalyticsEvent]);
	const href = `${baseUrl}/si/jira.issueviews:issue-xml/${issueKey}/${issueKey}.xml`;

	return (
		<DropdownItem key="ExportXmlIssueActionView" onClick={onClick} href={href} target="_blank">
			{formatMessage(messages.exportXML)}
		</DropdownItem>
	);
};
export default ExportIssueXML;
