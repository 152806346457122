import { type DocNode } from '@atlaskit/adf-schema';
import { findFilepaths, isLinkInMatches } from '@atlaskit/editor-common/utils';
import { ADFEncoder } from '@atlaskit/renderer/utils';
import { AiMateMarkdownTransformer } from '@atlassian/conversation-assistant-content-transformer';

export type Content = DocNode | string;

export const isAdfContent = (content: Content): content is DocNode => {
	/*
	This try-catch is a necessary guard rail against broken content to prevent higher level
	error boundaries from displaying the error and breaking the experience.
	*/
	try {
		return typeof content !== 'string' && 'type' in content && content.type === 'doc';
	} catch {
		return false;
	}
};

export const appendContent = (
	content: string | DocNode,
	suggestionContent: string,
): string | DocNode => {
	if (isAdfContent(content)) {
		// Ensure the content is an array of nodes
		const updatedContent = Array.isArray(content.content) ? content.content : [content.content];

		// Append the new paragraph node
		updatedContent.push({
			type: 'paragraph',
			content: [
				{
					type: 'text',
					text: suggestionContent,
				},
			],
		});

		// Return the updated DocNode
		return {
			...content,
			content: updatedContent,
		};
	}
	return `${content}\n\n${suggestionContent}`;
};

const pluginConfig = {
	linkify: {
		findFilepaths,
		isLinkInMatches,
	},
	smartLinks: {
		regexStorage: global.localStorage,
	},
};

// Default schema is https://bitbucket.org/atlassian/adf-schema/src/main/packages/adf-schema/src/schema/default-schema.ts
export const adfEncoder = new ADFEncoder(
	(schema) => new AiMateMarkdownTransformer(schema, pluginConfig, false),
);

const streamingAdfEncoder = new ADFEncoder(
	(schema) => new AiMateMarkdownTransformer(schema, pluginConfig, true),
);

export const encodeAdf = (content: Content, streaming: boolean = false): DocNode => {
	if (isAdfContent(content)) {
		return content;
	}

	return streaming ? streamingAdfEncoder.encode(content) : adfEncoder.encode(content);
};
