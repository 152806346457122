import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	likeButtonLabel: {
		id: 'servicedesk-ai-context-common.feedback.like-button-label',
		defaultMessage: 'Like',
		description: 'Label for like button',
	},
	dislikeButtonLabel: {
		id: 'servicedesk-ai-context-common.feedback.dislike-button-label',
		defaultMessage: 'Dislike',
		description: 'Label for dislike button',
	},
	feedbackSent: {
		id: 'servicedesk-ai-context-common.feedback.feedback-sent',
		defaultMessage: 'Feedback sent',
		description: 'Label indicating feedback has been sent',
	},
});
