import React from 'react';
import { useFragment, graphql } from 'react-relay';
import { Box, xcss } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { useIssueId, useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useFieldsValuesActions } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import { LabelsInlineEditView } from '@atlassian/jira-issue-field-labels-inline-edit-view-full/src/ui/labels/index.tsx';
import { useIssueViewFieldUpdateEvents } from '@atlassian/jira-issue-view-field-update-events/src/services/issue-view-field-update-events/index.tsx';
import { IssueViewFieldHeading } from '@atlassian/jira-issue-view-layout-field-heading/src/ui/index.tsx';
import type { ui_issueViewLayoutLabelsSystemField_IssueViewLabelsSystemField$key } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutLabelsSystemField_IssueViewLabelsSystemField.graphql';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { ui_issueViewLayoutLabelsSystemField_IssueViewLabelsSystemField$key as IssueViewLabelsSystemFieldFragment };

/**
 * Issue View wrapper for all labels field types, including the system field.
 * i.e all labels system field & custom labels field share this component.
 */
export const IssueViewLabelsSystemField = ({
	area,
	fragmentKey,
}: {
	area?: string;
	fragmentKey: ui_issueViewLayoutLabelsSystemField_IssueViewLabelsSystemField$key;
}) => {
	const issueId = useIssueId();
	const issueKey = useIssueKey();
	const [, { fieldChanged, fieldChangeFailed, fieldChangeRequested }] =
		useIssueViewFieldUpdateEvents();
	const [, { setFieldValue }] = useFieldsValuesActions();

	const data = useFragment<ui_issueViewLayoutLabelsSystemField_IssueViewLabelsSystemField$key>(
		graphql`
			fragment ui_issueViewLayoutLabelsSystemField_IssueViewLabelsSystemField on JiraLabelsField {
				...ui_issueViewLayoutFieldHeading_IssueViewFieldHeading
				...labels_issueFieldLabelsInlineEditViewFull_LabelsInlineEditView
				fieldId
			}
		`,
		fragmentKey,
	);

	const shouldShowProjectScopedCheckbox =
		fg('labels_restricted_to_project_experiment') && data?.fieldId === 'labels';

	return (
		<UFOSegment name="issue-field-labels">
			<IssueViewFieldHeading
				// eslint-disable-next-line jira/integration/test-id-by-folder-structure
				testId="issue.views.issue-base.context.labels"
				area={area}
				fragmentKey={data}
			>
				<Box xcss={fg('issue_view_field_config_edit') ? containerStylesNew : containerStylesOld}>
					<LabelsInlineEditView
						fragmentRef={data}
						onSubmit={(newValue) =>
							issueId && fieldChangeRequested(issueId, data?.fieldId, newValue)
						}
						onSubmitSucceeded={(newValue) => {
							issueId && fieldChanged(issueId, data?.fieldId, newValue);
							setFieldValue(issueKey, data.fieldId, newValue);
						}}
						onSubmitFailed={() => issueId && fieldChangeFailed(issueId, data?.fieldId)}
						shouldShowProjectScopedCheckbox={shouldShowProjectScopedCheckbox}
						shouldPrefetchSuggestions
					/>
				</Box>
			</IssueViewFieldHeading>
		</UFOSegment>
	);
};

// Apply negative left margin to offset the internal padding of the inline edit component, which will left align the
// read view content with the parent.
const containerStylesOld = xcss({
	marginLeft: 'space.negative.100',
	paddingRight: 'space.100',
});

const containerStylesNew = xcss({
	marginLeft: 'space.negative.050',
	paddingRight: 'space.100',
});
