// eslint-disable-next-line jira/restricted/react-component-props
import React, { type ComponentType, type ComponentProps } from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { ForgeUiIssueData } from '@atlassian/jira-forge-ui-types/src/common/types/extension-data.tsx';
import type AllActivityType from '@atlassian/jira-issue-all-activity/src/index.tsx';
import GroupedActivityEntry from '@atlassian/jira-issue-all-activity/src/ui/grouped-activity-entry/main.tsx';
import type HistoryType from '@atlassian/jira-issue-history/src/index.tsx';
import type { ActivitySortOrderType } from '@atlassian/jira-issue-shared-types/src/common/types/activity-sort-order-type.tsx';
import FeedSkeleton from '@atlassian/jira-issue-view-activity-common/src/component/feed-skeleton/view.tsx';
import { FeedContainer } from '@atlassian/jira-issue-view-activity-common/src/styles/index.tsx';
import IssueViewComments from '@atlassian/jira-issue-view-activity/src/async.tsx';
import Comments from '@atlassian/jira-issue-view-activity/src/comments-view.tsx';
import type IssueViewCommentProps from '@atlassian/jira-issue-view-activity/src/comments-view.tsx';
import type WorklogType from '@atlassian/jira-issue-view-activity/src/worklog/index.tsx';
import {
	ALL_ACTIVITY,
	APPROVALS,
	COMMENTS,
	CONNECT,
	HISTORY,
	WORKLOG,
} from '@atlassian/jira-issue-view-common-constants/src/activity-items.tsx';
import { FORGE_ENTITY_TYPE as FORGE } from '@atlassian/jira-issue-view-common-constants/src/ecosystem-constants.tsx';
import type { ActivityItem } from '@atlassian/jira-issue-view-common-types/src/activity-item-type.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import type { MediaContext } from '@atlassian/jira-issue-view-common-types/src/media-context-type.tsx';
import ConnectModule from '@atlassian/jira-issue-view-ecosystem/src/ecosystem-module-view/index.tsx';
import ForgeIssueActivityRenderer from '@atlassian/jira-issue-view-forge-issue-activity/src/renderer-view.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import {
	analyticsSourceSelector,
	baseUrlSelector,
	issueKeySelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';

import { mediaContextSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/media-context-selector.tsx';
import { forgeIssueDataSelector } from '@atlassian/jira-issue-view-store/src/ecosystem/forge/forge-extensions-selector.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import type { mainIssueAggQuery$data } from '@atlassian/jira-relay/src/__generated__/mainIssueAggQuery.graphql';
import { toBaseUrl, toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

type OwnProps = {
	selectedItem: ActivityItem | null;
	selectedSortOrder: ActivitySortOrderType;
	rootRelayFragment?: mainIssueAggQuery$data | null;
};

type StateProps = {
	issueKey: string;
	source: string | undefined;
	baseUrl: string;
	timeZone?: string;
	mediaContext: MediaContext;
	extensionData: ForgeUiIssueData;
};

type Props = OwnProps & StateProps;

const withActivityFeedItemBoundary =
	(id: string) =>
	<C, T extends ComponentType<C> = ComponentType<C>>(WrappedComponent: T): ComponentType<C> =>
	(props: C) => (
		<ErrorBoundary id={id} packageName="issue">
			<Placeholder
				name="activity-feed-item-boundary"
				fallback={
					<FeedContainer>
						<FeedSkeleton />
					</FeedContainer>
				}
			>
				{/* @ts-expect-error - TS2322 - Type 'C' is not assignable to type 'IntrinsicAttributes & LibraryManagedAttributes>'. */}
				<WrappedComponent {...props} />
			</Placeholder>
		</ErrorBoundary>
	);

const withActivityFeedItemBoundaryNew =
	(id: string) =>
	<C, T extends ComponentType<C> = ComponentType<C>>(WrappedComponent: T): ComponentType<C> =>
	(props: C) => (
		<JSErrorBoundary id={id} packageName="issue" teamName="bento" fallback="unmount">
			<Placeholder
				name="activity-feed-item-boundary"
				fallback={
					<FeedContainer>
						<FeedSkeleton />
					</FeedContainer>
				}
			>
				{/* @ts-expect-error - TS2322 - Type 'C' is not assignable to type 'IntrinsicAttributes & LibraryManagedAttributes>'. */}
				<WrappedComponent {...props} />
			</Placeholder>
		</JSErrorBoundary>
	);

const AllActivity = withActivityFeedItemBoundary('activity-feed-all-activity')<
	JSX.LibraryManagedAttributes<typeof AllActivityType, ComponentProps<typeof AllActivityType>>
>(
	lazyForPaint<typeof AllActivityType>(
		() =>
			import(
				/* webpackChunkName: "async-activity-feed-item-all-activity" */ '@atlassian/jira-issue-all-activity'
			),
	),
);
AllActivity.displayName = 'AllActivity';

const HistoryWithActivityFeedItemBoundary = withActivityFeedItemBoundaryNew(
	'activity-feed-item-history',
)<JSX.LibraryManagedAttributes<typeof HistoryType, ComponentProps<typeof HistoryType>>>(
	lazyForPaint<typeof HistoryType>(
		() =>
			import(
				/* webpackChunkName: "async-activity-feed-item-history" */ '@atlassian/jira-issue-history'
			),
	),
);
HistoryWithActivityFeedItemBoundary.displayName = 'History';

const History = (
	props: JSX.LibraryManagedAttributes<typeof HistoryType, ComponentProps<typeof HistoryType>>,
) => (
	<UFOSegment name="issue-history">
		<HistoryWithActivityFeedItemBoundary {...props} />
	</UFOSegment>
);

const WorklogWithFeedItemBoundary = withActivityFeedItemBoundaryNew('activity-feed-item-worklog')<
	JSX.LibraryManagedAttributes<typeof WorklogType, ComponentProps<typeof WorklogType>>
>(
	// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
	lazyForPaint<typeof WorklogType>(
		() =>
			import(
				/* webpackChunkName: "async-activity-feed-item-worklog" */ '@atlassian/jira-issue-view-activity/src/worklog'
			),
		{ ssr: false },
	),
);
WorklogWithFeedItemBoundary.displayName = 'Worklog';

const Worklog = (
	props: JSX.LibraryManagedAttributes<typeof WorklogType, ComponentProps<typeof WorklogType>>,
) => (
	<UFOSegment name="issue-activity-worklog">
		<WorklogWithFeedItemBoundary {...props} />
	</UFOSegment>
);

type ApprovalsType = (props: {
	issueKey: string;
	sortOrder: ActivitySortOrderType;
}) => JSX.Element | null;

const Approvals = withActivityFeedItemBoundary('activity-feed-item-approvals')<
	JSX.LibraryManagedAttributes<ApprovalsType, ComponentProps<ApprovalsType>>
>(
	lazyForPaint<ApprovalsType>(
		() =>
			import(
				/* webpackChunkName: "async-activity-feed-item-approvals" */ '@atlassian/jira-issue-approval-activity'
			),
	),
);
Approvals.displayName = 'Approvals';

const AsyncComments = withActivityFeedItemBoundary('activity-feed-item-comments')<
	JSX.LibraryManagedAttributes<
		typeof IssueViewCommentProps,
		ComponentProps<typeof IssueViewCommentProps>
	>
>(
	// async import exists in a different file because if it points to the same file path, webpack will optimise it away
	IssueViewComments,
);
AsyncComments.displayName = 'AsyncComments';

export const ActivityFeedItem = ({
	selectedItem,
	baseUrl,
	issueKey,
	timeZone,
	selectedSortOrder,
	extensionData,
	source,
	mediaContext,
	rootRelayFragment,
}: Props) => {
	if (!selectedItem) return null;
	switch (selectedItem.type) {
		case ALL_ACTIVITY:
			return <AllActivity sortOrder={selectedSortOrder} mediaContext={mediaContext} />;
		case COMMENTS:
			return fg('perf-push-big-components-async-comment') ? (
				<AsyncComments {...(fg('smart-replies-system-setting') ? { rootRelayFragment } : {})} />
			) : (
				<Comments {...(fg('smart-replies-system-setting') ? { rootRelayFragment } : {})} />
			);
		case CONNECT:
			return (
				<UFOSegment name="issue-activity-connect-module">
					<ConnectModule
						appKey={selectedItem.appKey}
						moduleKey={selectedItem.moduleKey}
						options={selectedItem.options}
						appName={selectedItem.name}
						location="issueActionsActivityFeed"
					/>
				</UFOSegment>
			);
		case FORGE:
			return (
				<UFOSegment name="issue-activity-forge-module">
					<ForgeIssueActivityRenderer
						key={selectedItem.extension.id}
						source={source}
						extensionData={extensionData}
						extension={selectedItem.extension}
					/>
				</UFOSegment>
			);
		case HISTORY:
			return (
				<History
					baseUrl={toBaseUrl(baseUrl)}
					issueKey={toIssueKey(issueKey)}
					timeZone={timeZone}
					sortOrder={selectedSortOrder}
					GroupedActivityEntryComponent={GroupedActivityEntry}
				/>
			);
		case WORKLOG:
			return <Worklog timeZone={timeZone} />;
		case APPROVALS:
			return <Approvals issueKey={issueKey} sortOrder={selectedSortOrder} />;
		default:
			return null;
	}
};

const ConnectedComponent = connect(
	(state: State): StateProps => ({
		baseUrl: baseUrlSelector(state),
		issueKey: issueKeySelector(state),
		timeZone: undefined,
		extensionData: forgeIssueDataSelector(state),
		source: analyticsSourceSelector(state),
		mediaContext: mediaContextSelector(state),
	}),
	{},
)(ActivityFeedItem);

export default ConnectedComponent;
