import React, { Component } from 'react';
import noop from 'lodash/noop';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { AnalyticsSource } from '@atlassian/jira-analytics-web-react/src/components/decorators.tsx';
import ViewTracker from '@atlassian/jira-analytics-web-react/src/components/view-tracker.tsx';
import { PRETTY } from '@atlassian/jira-common-constants/src/jira-settings.tsx';
import type { IntlShape } from '@atlassian/jira-intl';
import type { Worklog } from '@atlassian/jira-issue-gira-transformer-types/src/common/types/worklogs.tsx';
import type { TimeTrackingConfig } from '@atlassian/jira-issue-shared-types/src/common/types/jira-settings-type.tsx';
import type { TimeTrackingState } from '@atlassian/jira-issue-shared-types/src/common/types/time-tracking-type.tsx';
import type { FormValues } from '@atlassian/jira-issue-view-common-types/src/log-time-modal-type.tsx';
import type { MediaContext } from '@atlassian/jira-issue-view-common-types/src/media-context-type.tsx';
import isTimeStringValidOrBlank from '@atlassian/jira-issue-view-common-utils/src/time-string/is-time-string-valid-or-blank/index.tsx';
import isTimeStringValid from '@atlassian/jira-issue-view-common-utils/src/time-string/is-time-string-valid/index.tsx';
import timeStringToSeconds from '@atlassian/jira-issue-view-common-utils/src/time-string/time-string-to-seconds/index.tsx';
import LogTimeModal from '@atlassian/jira-issue-view-common/src/component/log-time-modal/index.tsx';
import type { LogTimeValidator } from '@atlassian/jira-issue-view-common/src/component/log-time-modal/view.tsx';
import { MODAL } from '@atlassian/jira-product-analytics-bridge';
import { emptyAdfObject } from '@atlassian/jira-rich-content/src/common/adf-parsing-utils.tsx';
import { timeTrackingFormatter } from '@atlassian/jira-time-tracking-formatter/src/main.tsx';
import messages from './messages.tsx';

export type OwnProps = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	activityProvider: any;
	externalId: string;
	intl: IntlShape;
};

export type StateProps = {
	isDone: boolean;
	isSaving: boolean;
	isRollingUpData: boolean;
	isClassicProject: boolean;
	shouldDisplayRollUpDataControl: boolean;
	mediaContext: MediaContext;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mentionProvider: any | null;
	// eslint-disable-next-line jira/react/handler-naming
	mentionEncoder?: (arg1: string) => string | undefined;
	timeTrackingConfiguration: TimeTrackingConfig;
	timeTracking: TimeTrackingState;
	rolledUpTimeTracking: TimeTrackingState;
	timeZone: string | undefined;
	worklog: Worklog | null;
};

export type DispatchProps = {
	onSubmit: (worklogId: string, values: FormValues, analyticsEvent: UIAnalyticsEvent) => void;
	onCancel: () => void;
	onToggleRollingUpData: (arg1: boolean) => void;
};

export type Props = OwnProps & StateProps & DispatchProps;

/**
 * In the Edit worklog modal, the form is valid if:
 * - "Time spent" is a valid non-empty, (you can spend zero time on a worklog)
 * - "Time remaining" is empty or valid
 * - "Date started" is a valid non-empty value
 */
export const getValidator =
	(): LogTimeValidator =>
	({ timeLogged, timeRemaining, dateStarted }) => {
		const isTimeLoggedValid = isTimeStringValid(timeLogged) || timeLogged === '0';
		return isTimeLoggedValid && isTimeStringValidOrBlank(timeRemaining) && !!dateStarted;
	};

// eslint-disable-next-line jira/react/no-class-components
class EditModal extends Component<Props> {
	onSubmit = (values: FormValues, analyticsEvent: UIAnalyticsEvent) => {
		const { worklog } = this.props;
		if (worklog) {
			this.props.onSubmit(worklog.id, values, analyticsEvent);
		}
	};

	render() {
		const {
			worklog,
			timeTracking: timeTrackingState,
			rolledUpTimeTracking,
			timeTrackingConfiguration,
			intl,
		} = this.props;

		// should never be the case as this is the "edit" view
		// but the conditional is needed so Flow understands it
		if (!worklog) {
			return null;
		}

		const { timeSpentSeconds, remainingEstimateSeconds, originalEstimateSeconds } =
			timeTrackingState;
		const { daysPerWeek, hoursPerDay } = timeTrackingConfiguration;

		let timeRemaining = '';
		if (typeof remainingEstimateSeconds === 'number') {
			// At this time we need to force the value into an english formatted
			// time string eg: `1w 1d 1h 1m` as that is the only format supported
			// on entry, future changes will support localised values.
			const intlForTimeTrackingInput = {
				...intl,
				formatMessage: ({ defaultMessage }: { defaultMessage?: string }) => defaultMessage || '',
			};
			timeRemaining = timeTrackingFormatter(
				remainingEstimateSeconds,
				{
					workingHoursPerDay: timeTrackingConfiguration.hoursPerDay,
					workingDaysPerWeek: timeTrackingConfiguration.daysPerWeek,
					timeFormat: timeTrackingConfiguration.format || PRETTY,
					defaultUnit: timeTrackingConfiguration.defaultUnit,
				},
				intlForTimeTrackingInput,
			);
		}

		const initialValue = {
			timeLogged: worklog.timeSpent,
			timeLoggedSeconds: timeStringToSeconds(daysPerWeek, hoursPerDay)(worklog.timeSpent) || 0,
			timeRemaining,
			timeRemainingSeconds: remainingEstimateSeconds,
			dateStarted: new Date(worklog.started).toISOString(),
			workDescription: worklog.comment || emptyAdfObject,
		};
		const timeTracking = {
			loggedTime: timeSpentSeconds,
			remainingTime: remainingEstimateSeconds,
		};
		return (
			<>
				<LogTimeModal
					initialValue={initialValue}
					timeTracking={timeTracking}
					rolledUpTimeTracking={{
						loggedTime: rolledUpTimeTracking.timeSpentSeconds,
						remainingTime: rolledUpTimeTracking.remainingEstimateSeconds,
					}}
					estimatedTime={originalEstimateSeconds}
					rolledUpEstimatedTime={rolledUpTimeTracking.originalEstimateSeconds}
					config={timeTrackingConfiguration}
					header={intl.formatMessage(messages.title)}
					timeZone={this.props.timeZone}
					isCollapsible={false}
					isDone={this.props.isDone}
					isWaiting={this.props.isSaving}
					shouldUpdateDate={false}
					validator={getValidator()}
					onSubmit={this.onSubmit}
					onCancel={this.props.onCancel}
					onWorkDescriptionChangeFailure={noop}
					mediaContext={this.props.mediaContext}
					mentionProvider={this.props.mentionProvider || {}}
					mentionEncoder={this.props.mentionEncoder}
					activityProvider={this.props.activityProvider}
					externalId={this.props.externalId}
					shouldDisplayRollUpDataControl={this.props.shouldDisplayRollUpDataControl}
					isRollingUpData={this.props.isRollingUpData}
					onToggleRollingUpData={this.props.onToggleRollingUpData}
					isClassicProject={this.props.isClassicProject}
				/>
				<ViewTracker />
			</>
		);
	}
}

export default AnalyticsSource('editWorklog', MODAL)(EditModal);
