import { useCallback, useState } from 'react';
import { metrics } from '@atlassian/browser-metrics';
import { invokeAgent } from '@atlassian/jira-ai-assistance-service-client/src/services/invoke-agent/index.tsx';
import {
	SuggestedCommentReplyAgentCommentType,
	SUGGESTED_COMMENT_AGENT_EXPERIENCE_ID,
	SUGGESTED_COMMENT_AGENT_NAME_ID,
} from '@atlassian/jira-ai-assistance-service-client/src/services/invoke-agent/suggested-comment-reply-agent/types.tsx';
import { useExperienceFail } from '@atlassian/jira-experience-tracker/src/ui/experience-fail/index.tsx';
import { useExperienceStart } from '@atlassian/jira-experience-tracker/src/ui/experience-start/index.tsx';
import { useExperienceSuccess } from '@atlassian/jira-experience-tracker/src/ui/experience-success/index.tsx';
import { useFlagsService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { useNativeLocale } from '@atlassian/jira-tenant-context-controller/src/components/locale/index.tsx';
import { getProductReferrerKey } from '../../common/utils.tsx';
import {
	AI_COMMENTS_EXPERIENCE,
	AI_COMMENTS_HISTOGRAM,
	AI_COMMENTS_METRIC_KEY,
	FETCH_SUGGESTED_COMMENTS_FAILED_FLAG_ID,
	FETCH_SUGGESTED_COMMENTS_FAILED_FLAG,
} from './constants.tsx';
import type { FetchSuggestedComments, SuggestedComments, UseAIComments } from './types.tsx';

export const transformComments: (
	comments?: { commentReplyType: string; text: string }[],
) => SuggestedComments | null = (comments) => {
	if (!comments) {
		return null;
	}
	const results: SuggestedComments = {};
	comments.forEach((comment) => {
		if (
			comment.commentReplyType === SuggestedCommentReplyAgentCommentType.QUICK_REPLY ||
			comment.commentReplyType === SuggestedCommentReplyAgentCommentType.QUESTION ||
			comment.commentReplyType === SuggestedCommentReplyAgentCommentType.SUGGESTION ||
			comment.commentReplyType === SuggestedCommentReplyAgentCommentType.JIRA_STATUS_UPDATE ||
			comment.commentReplyType === SuggestedCommentReplyAgentCommentType.JIRA_COMMENT_REPLY ||
			comment.commentReplyType === SuggestedCommentReplyAgentCommentType.JIRA_ASSIGNEE_MISSING ||
			comment.commentReplyType === SuggestedCommentReplyAgentCommentType.JIRA_DESCRIPTION_MISSING ||
			comment.commentReplyType === SuggestedCommentReplyAgentCommentType.JIRA_MOTIVATION
		) {
			results[comment.commentReplyType] = comment.text;
		}
	});
	return results;
};

export const hasSuggestedComment = (
	selectedType: SuggestedCommentReplyAgentCommentType,
	suggestedComments: SuggestedComments | null,
) => {
	if (!suggestedComments) {
		return false;
	}
	const comment = suggestedComments[selectedType];
	return !!comment;
};

export const useAIComments: () => UseAIComments = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [hasErrorOccurred, setHasErrorOccurred] = useState(false);
	const locale = useNativeLocale();
	const startExperience = useExperienceStart(AI_COMMENTS_EXPERIENCE);
	const markExperienceSuccess = useExperienceSuccess(AI_COMMENTS_EXPERIENCE);
	const markExperienceFailed = useExperienceFail(AI_COMMENTS_EXPERIENCE);

	const aiCommentsMetric = metrics.interaction({
		key: AI_COMMENTS_METRIC_KEY,
		histogram: AI_COMMENTS_HISTOGRAM,
	});
	const { showFlag, dismissFlag, hasFlag } = useFlagsService();

	const fetchSuggestedComments: FetchSuggestedComments = useCallback(
		async ({ issueKey, projectType, selectedType, commentTypes, onFail }) => {
			startExperience();
			setIsLoading(true);
			aiCommentsMetric.start();
			if (hasFlag?.(FETCH_SUGGESTED_COMMENTS_FAILED_FLAG_ID)) {
				dismissFlag(FETCH_SUGGESTED_COMMENTS_FAILED_FLAG_ID);
			}
			try {
				const comments = await invokeAgent(SUGGESTED_COMMENT_AGENT_NAME_ID, {
					experienceId: SUGGESTED_COMMENT_AGENT_EXPERIENCE_ID,
					agent_input_context: {
						object_id: issueKey,
						locale,
						product: getProductReferrerKey(projectType),
						comment_types: commentTypes,
					},
				});
				setIsLoading(false);
				aiCommentsMetric.stop();

				const result = transformComments(comments?.content?.commentSuggestions);
				if (hasSuggestedComment(selectedType, result)) {
					markExperienceSuccess();
					return result;
				}
				throw new Error('Selected comment type not returned');
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				setIsLoading(false);
				setHasErrorOccurred(true);
				aiCommentsMetric.stop();
				markExperienceFailed('use-ai-comment', error, {
					commentTypes: commentTypes?.toString() || '',
				});
				showFlag(FETCH_SUGGESTED_COMMENTS_FAILED_FLAG);
				onFail?.(error);
				return null;
			}
		},
		[
			aiCommentsMetric,
			dismissFlag,
			hasFlag,
			locale,
			markExperienceFailed,
			markExperienceSuccess,
			showFlag,
			startExperience,
		],
	);

	return { isLoading, hasErrorOccurred, fetchSuggestedComments };
};
