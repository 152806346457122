import React from 'react';
import { xcss, Box } from '@atlaskit/primitives';
import { expVal } from '@atlassian/jira-feature-experiments';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import { Emphasise } from '@atlassian/jira-issue-view-activity-common/src/styles/index.tsx';
import Change from '../../../../common/ui/change/index.tsx';
import GroupedChange from '../../../../common/ui/grouped-change/index.tsx';
import HistoryItem from '../../../../common/ui/history-item/index.tsx';
import type { ParentIssueChangedHistoryItem } from '../../../../types.tsx';
import messages from './messages.tsx';

export type Props = {
	isGrouped?: Boolean;
	historyItem: ParentIssueChangedHistoryItem;
};

const ParentIssueHistoryItem = ({ historyItem, isGrouped = false }: Props) => {
	const { formatMessage } = useIntl();
	const { actor, timestamp, fromValue, toValue } = historyItem;

	return (
		<FormattedMessage
			{...messages.changed}
			values={{
				parent: (
					<Emphasise key="parent">
						{formatMessage(
							expVal('issue_view_activity_timeline', 'isActivityTimelineEnabled', false)
								? messages.parentLowercase
								: messages.parent,
						)}
					</Emphasise>
				),
			}}
		>
			{(...action) =>
				isGrouped && expVal('issue_view_activity_timeline', 'isActivityTimelineEnabled', false) ? (
					<GroupedChange
						field={formatMessage(messages.parent)}
						from={
							fromValue != null && fromValue !== '' ? (
								<Box xcss={textStyles}>{fromValue}</Box>
							) : null
						}
						to={toValue != null && toValue !== '' ? <Box xcss={textStyles}>{toValue}</Box> : null}
					/>
				) : (
					<HistoryItem
						// eslint-disable-next-line jira/integration/test-id-by-folder-structure
						testId="issue-history.ui.history-items.parent-issue-history-item.history-item"
						actor={actor}
						action={action}
						timestamp={timestamp}
						change={
							<Change
								from={
									fromValue != null && fromValue !== '' ? (
										<Box xcss={textStyles}>{fromValue}</Box>
									) : null
								}
								to={
									toValue != null && toValue !== '' ? <Box xcss={textStyles}>{toValue}</Box> : null
								}
							/>
						}
					/>
				)
			}
		</FormattedMessage>
	);
};

export default ParentIssueHistoryItem;

const textStyles = xcss({
	overflowX: 'auto',
});
