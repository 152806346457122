import type { Registry } from '../../types.tsx';
import getCache, { type Config } from '../storage/index.tsx';

const pNothing = () => Promise.resolve();

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default <C,>(config: Config): Registry<C> => {
	if (!__SERVER__) {
		const Cache = getCache<string, C>(config);

		const getAll = () =>
			Cache.then((cache) =>
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				cache.getEntries().then((entries) => entries.map(([, value]: [any, any]) => value)),
			);

		const get = (key: string) => Cache.then((cache) => cache.get(key));

		const set = (key: string, value: C) => Cache.then((cache) => cache.set(key, value));

		const remove = (key: string) => Cache.then((cache) => cache.delete(key));

		const update = (key: string, value: C) => Cache.then((cache) => cache.update(key, value));

		const refresh = () => Cache.then((cache) => cache.refresh()).then(() => getAll());

		const clearAll = () =>
			Cache.then((cache) =>
				cache
					.getEntries()
					.then((entries) =>
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						Promise.all(entries.map(([key]: [any, any]) => cache.delete(key))),
					) // Replace with lodash/noop
					// eslint-disable-next-line @typescript-eslint/no-empty-function
					.then(() => {}),
			);

		return { get, getAll, set, remove, update, refresh, clearAll };
	}

	return {
		// @ts-expect-error - TS2322 - Type '() => Promise<void>' is not assignable to type '(key: string) => Promise<C | undefined>'.
		get: pNothing,
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		getAll: () => Promise.resolve([]) as Promise<C[]>,
		set: () => Promise.resolve(),
		remove: pNothing,
		update: pNothing,
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		refresh: () => Promise.resolve([]) as Promise<C[]>,
		clearAll: pNothing,
	};
};
