/**
 * @generated SignedSource<<f79f1e22c80f6a3ec64ba44954d8a3d8>>
 * @relayHash 4422631dd314dcd65a51f33775382967
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID e8f3d3afc22bfb66e555b6f194da54f669ac621e84377b867a268855c6d6acdf

import type { ConcreteRequest, Mutation } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraSingleValueFieldOperations = "SET" | "%future added value";
export type JiraUpdateUrlFieldInput = {
  id: string;
  operation: JiraUrlFieldOperationInput;
};
export type JiraUrlFieldOperationInput = {
  operation: JiraSingleValueFieldOperations;
  uri?: string | null | undefined;
};
export type url_issueFieldUrl_UrlField_Mutation$variables = {
  input: JiraUpdateUrlFieldInput;
};
export type url_issueFieldUrl_UrlField_Mutation$data = {
  readonly jira: {
    readonly updateUrlField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly " $fragmentSpreads": FragmentRefs<"url_issueFieldUrlInlineEditFull_UrlInlineEditView">;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type url_issueFieldUrl_UrlField_Mutation$rawResponse = {
  readonly jira: {
    readonly updateUrlField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly fieldConfig: {
          readonly isEditable: boolean | null | undefined;
        } | null | undefined;
        readonly fieldId: string;
        readonly id: string;
        readonly name: string;
        readonly type: string;
        readonly uri: string | null | undefined;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type url_issueFieldUrl_UrlField_Mutation = {
  rawResponse: url_issueFieldUrl_UrlField_Mutation$rawResponse;
  response: url_issueFieldUrl_UrlField_Mutation$data;
  variables: url_issueFieldUrl_UrlField_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "success"
},
v3 = {
  "concreteType": "MutationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "message"
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "url_issueFieldUrl_UrlField_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraUrlFieldPayload",
            "kind": "LinkedField",
            "name": "updateUrlField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraUrlField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  {
                    "kind": "FragmentSpread",
                    "name": "url_issueFieldUrlInlineEditFull_UrlInlineEditView"
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "url_issueFieldUrl_UrlField_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraUrlFieldPayload",
            "kind": "LinkedField",
            "name": "updateUrlField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraUrlField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "id"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "name"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "fieldId"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "type"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "uri"
                  },
                  {
                    "concreteType": "JiraFieldConfig",
                    "kind": "LinkedField",
                    "name": "fieldConfig",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "isEditable"
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "e8f3d3afc22bfb66e555b6f194da54f669ac621e84377b867a268855c6d6acdf",
    "metadata": {},
    "name": "url_issueFieldUrl_UrlField_Mutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "e4564db8bf082a46de2f2ecb18a2ab6e";

export default node;
