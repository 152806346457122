import React, { type PropsWithChildren } from 'react';

import { IntlProvider, useIntl } from 'react-intl-next';
import {
	createActionsHook,
	createContainer,
	createHook,
	createStore,
	createSubscriber,
	type StoreActionApi,
} from 'react-sweet-state';

import {
	type ContainerProps,
	type FlagService,
	type GlobalMessageHandler,
	type InnerContainerProps,
	type Locale,
	type State,
} from '../common/utils/types';

import * as actions from './actions';

export const Store = createStore<State, typeof actions>({
	name: 'global-context',
	initialState: {
		reporter: undefined,
	},
	actions,
});

export const GlobalContextContainer = <T,>({
	flagService,
	reporter,
	children,
	messages,
	config,
}: PropsWithChildren<ContainerProps<T>>) => {
	const { locale } = useIntl();

	return (
		<OriginalGlobalContextContainer
			config={config}
			reporter={reporter}
			isGlobal
			flagService={flagService}
		>
			<IntlProvider locale={locale} messages={messages && messages[locale as Locale]}>
				{children}
			</IntlProvider>
		</OriginalGlobalContextContainer>
	);
};

const OriginalGlobalContextContainer = createContainer<State, typeof actions, InnerContainerProps>(
	Store,
	{
		onInit:
			() =>
			async (
				{ getState, setState }: StoreActionApi<State>,
				{ flagService, config }: InnerContainerProps,
			) => {
				!getState().flagService && setState({ flagService });
				if (config?.context) {
					setState({ config: { ...getState().config, context: config.context } });
				}
				if (config?.messageHandler) {
					setState({ config: { ...getState().config, messageHandler: config.messageHandler } });
				}
			},
		onUpdate:
			() =>
			async ({ getState, setState }: StoreActionApi<State>, { config }: InnerContainerProps) => {
				if (config?.context) {
					setState({ config: { ...getState().config, context: config.context } });
				}
				if (config?.messageHandler) {
					setState({ config: { ...getState().config, messageHandler: config.messageHandler } });
				}
			},
	},
);

export const GlobalContextSubscriber = createSubscriber<State, {}>(Store);

export const useExternalFlagService = createHook<State, {}, FlagService | undefined>(Store, {
	selector: (state) => state.flagService,
});

/**
 * @deprecated Please avoid using useSendMessage as we intend to remove it completely in a future.
 * See https://hello.atlassian.net/wiki/spaces/ITSOL/pages/4318000301/LDR+Eoc+packages+will+not+use+messaging+mechanism+from+global-context+to+write+product-agnostic+code for more detail.
 */

export const useSendMessage = <T extends GlobalMessageHandler<unknown>>(
	handlerClass: new () => T,
): T['handler'] => {
	const [{ handler, context }] = useSendMessageInternal(handlerClass);
	return (params) => {
		return handler(params, context);
	};
};

const useSendMessageInternal = createHook<
	State,
	{},
	{ handler: GlobalMessageHandler<unknown>['handler']; context?: unknown },
	any
>(Store, {
	selector: (state, arg) => {
		if (state.config && state.config.messageHandler && state.config.messageHandler instanceof arg) {
			return {
				handler: state.config.messageHandler.handler.bind(state.config.messageHandler),
				context: state.config.context,
			};
		}
		throw new Error(
			`The messageHandler provided from nearest GlobalContextContainer doesn't implement the one from the inner tree. Please make sure you have a compatible GlobalContextContainer`,
		);
	},
});

export const useGlobalContextActions = createActionsHook<State, typeof actions>(Store);
