import { useCallback } from 'react';
import { graphql, useMutation } from 'react-relay';
import { ValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import type { LabelsValue } from '@atlassian/jira-issue-shared-types/src/common/types/labels-type.tsx';
import type { useSaveLabelsField_LabelsMutation as LabelsMutation } from '@atlassian/jira-relay/src/__generated__/useSaveLabelsField_LabelsMutation.graphql';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import type { SaveField } from './types.tsx';

/**
 * @deprecated (do not copy/use) TODO: find a way to get ari rather than building it
 * https://jplat.atlassian.net/browse/JIV-13978
 */
const buildId = (cloudId: string, issueId: string, fieldKey: string) =>
	`ari:cloud:jira:${cloudId}:issuefieldvalue/${issueId}/${fieldKey}`;

export const useSaveLabelsField = (issueId: string): SaveField => {
	const cloudId = useCloudId();

	const [commit] = useMutation<LabelsMutation>(graphql`
		mutation useSaveLabelsField_LabelsMutation($input: JiraUpdateLabelsFieldInput!)
		@raw_response_type {
			jira {
				updateLabelsField(input: $input) {
					success
					errors {
						message
					}
					field {
						...labels_issueFieldLabelsInlineEditViewFull_LabelsInlineEditView
					}
				}
			}
		}
	`);

	return useCallback(
		async (_issueKey: IssueKey, fieldKey: string, value: LabelsValue) =>
			new Promise((resolve, reject) => {
				commit({
					variables: {
						input: {
							id: buildId(cloudId, issueId, fieldKey),
							operations: [
								{
									operation: 'SET',
									labels: value || [],
								},
							],
						},
					},
					onCompleted(mutationData) {
						if (mutationData.jira?.updateLabelsField) {
							const { success, errors: responseErrors } = mutationData.jira?.updateLabelsField;
							if (success) {
								resolve();
								return;
							}
							if (responseErrors?.length) {
								reject(
									responseErrors[0].message
										? new ValidationError(responseErrors[0].message)
										: new Error('Unknown error when mutating labels'),
								);
							}
						} else {
							reject(new Error('Unknown error when mutating labels'));
						}
					},
					onError(error: Error) {
						reject(error);
					},
				});
			}),
		[cloudId, commit, issueId],
	);
};
