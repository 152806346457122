// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	searchAriaLabel: {
		id: 'issue.issue-links-search-aria-label',
		defaultMessage: 'Current issue {linkType}..., search issues',
		description:
			'Aria-label for the linked issues selector. linkType will be replaced with the selected relation, i.e. "is blocked by" or "relates to"',
	},
	searchPlaceholder: {
		id: 'issue.issue-links-search-placeholder',
		defaultMessage: 'Search for issues',
		description: '',
	},
	openIssues: {
		id: 'issue.issue-links-search-open-issues',
		defaultMessage: 'Open Issues',
		description: '',
	},
	exactKey: {
		id: 'issue.issue-links-exact-key',
		defaultMessage: 'Exact key',
		description: '',
	},
	noHistoryResults: {
		id: 'issue.issue-link-no-history-results',
		defaultMessage: 'Type to search for an issue summary or key',
		description:
			'Shown to a user when there are no automatically suggested issues available to display',
	},
	searchAriaLabelIssueTermRefresh: {
		id: 'issue.issue-links-search-aria-label-issue-term-refresh',
		defaultMessage: 'Current work item {linkType}..., search work items',
		description:
			'Aria-label for the linked issues selector. linkType will be replaced with the selected relation, i.e. "is blocked by" or "relates to"',
	},
	searchPlaceholderIssueTermRefresh: {
		id: 'issue.issue-links-search-placeholder-issue-term-refresh',
		defaultMessage: 'Search for work items',
		description: '',
	},
	openIssuesIssueTermRefresh: {
		id: 'issue.issue-links-search-open-issues-issue-term-refresh',
		defaultMessage: 'Open Work items',
		description: '',
	},
	noHistoryResultsIssueTermRefresh: {
		id: 'issue.issue-link-no-history-results-issue-term-refresh',
		defaultMessage: 'Type to search for an issue summary or key',
		description:
			'Shown to a user when there are no automatically suggested issues available to display',
	},
});
