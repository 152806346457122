/* eslint-disable no-restricted-properties */
/**
 * This is an eval user timing for the issue-app. We are using the performance directly so we don't
 * accidentally bring in any code that may have side effects or runtime code during the script eval.
 */

const mark = (name: string) => {
	if (__SERVER__) {
		return;
	}

	try {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.performance.mark(name);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (_: any) {
		// void
	}
};

export const ISSUE_APP_EVAL_START = 'jira.issue-view.issue-app.eval-start';
export const ISSUE_APP_EVAL_END = 'jira.issue-view.issue-app.eval-end';

mark(ISSUE_APP_EVAL_START);

export const markEvalEnd = () => mark(ISSUE_APP_EVAL_END);

export const measureEval = () => {
	if (__SERVER__) {
		return;
	}

	try {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.performance.measure(
			'jira.issue-view.issue-app.eval',
			ISSUE_APP_EVAL_START,
			ISSUE_APP_EVAL_END,
		);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (_: any) {
		// / void
	}
};
