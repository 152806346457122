import React, { createContext, useContext } from 'react';
import type { TenantContextValue, TenantContextProviderProps } from './types.tsx';

const TenantContext = createContext<TenantContextValue | null>(null);

export const useTenantContext = (): TenantContextValue => {
	const tenantContext = useContext(TenantContext);
	if (tenantContext === null) {
		throw new Error('tenantContext value is null');
	}
	return tenantContext;
};

export const TenantContextProvider = ({ children, value }: TenantContextProviderProps) => {
	if (!value) {
		throw new Error('TenantContextProvider requires a value');
	}
	return <TenantContext.Provider value={value}>{children}</TenantContext.Provider>;
};
