import React from 'react';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';
import type { HeaderProps, TabProps } from '../model/types.tsx';
import DetailsHeaderPlaceholder from './details-header-placeholder/view.tsx';
import TabContentPlaceholder from './tab-content-placeholder/view.tsx';

const loadAsyncComponent = async () => {
	const result = await import(
		/* webpackChunkName: "dev-panel.dev-details-modal-view-lazy" */ './async-components'
	);
	return result;
};

// DetailsHeaders
// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyDetailsHeaderComponent = lazy(() =>
	loadAsyncComponent().then((exportedModule) => ({ default: exportedModule.Header })),
);

export const DetailsHeader = (props: HeaderProps) => (
	<Placeholder name="details-header-component" fallback={<DetailsHeaderPlaceholder />}>
		<LazyDetailsHeaderComponent {...props} />
	</Placeholder>
);

// Branches
// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyBranchesComponent = lazy(() =>
	loadAsyncComponent().then((exportedModule) => ({ default: exportedModule.Branches })),
);

export const Branches = (props: TabProps) => (
	<Placeholder name="branches-component" fallback={<TabContentPlaceholder />}>
		{/* @ts-expect-error - Type '{ issueId: string; issueKey: string; baseUrl: string; locale: Locale; }' has no properties in common with type 'IntrinsicAttributes'. */}
		<LazyBranchesComponent {...props} />
	</Placeholder>
);

// Build
// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyBuildsComponent = lazy(() =>
	loadAsyncComponent().then((exportedModule) => ({ default: exportedModule.Builds })),
);
export const Builds = (props: TabProps) => (
	<Placeholder name="builds-component" fallback={<TabContentPlaceholder />}>
		{/* @ts-expect-error - Type '{ issueId: string; issueKey: string; baseUrl: string; locale: Locale; }' has no properties in common with type 'IntrinsicAttributes'. */}
		<LazyBuildsComponent {...props} />
	</Placeholder>
);

// Commits
// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyCommitsComponent = lazy(() =>
	loadAsyncComponent().then((exportedModule) => ({ default: exportedModule.Commits })),
);
export const Commits = (props: TabProps) => (
	<Placeholder name="commits-component" fallback={<TabContentPlaceholder />}>
		{/* @ts-expect-error - Type '{ issueId: string; issueKey: string; baseUrl: string; locale: Locale; }' has no properties in common with type 'IntrinsicAttributes'. */}
		<LazyCommitsComponent {...props} />
	</Placeholder>
);

// Deployment
// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyDeploymentsComponent = lazy(() =>
	loadAsyncComponent().then((exportedModule) => ({
		default: exportedModule.Deployments,
	})),
);
export const Deployments = (props: TabProps) => (
	<Placeholder name="deployments-component" fallback={<TabContentPlaceholder />}>
		{/* @ts-expect-error - Type '{ issueId: string; issueKey: string; baseUrl: string; locale: Locale; }' has no properties in common with type 'IntrinsicAttributes'. */}
		<LazyDeploymentsComponent {...props} />
	</Placeholder>
);

// PullRequests
// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyPullRequestsComponent = lazy(() =>
	loadAsyncComponent().then((exportedModule) => ({
		default: exportedModule.PullRequests,
	})),
);
export const PullRequests = (props: TabProps) => (
	<Placeholder name="pull-requests-component" fallback={<TabContentPlaceholder />}>
		{/* @ts-expect-error - Type '{ issueId: string; issueKey: string; baseUrl: string; locale: Locale; }' has no properties in common with type 'IntrinsicAttributes'. */}
		<LazyPullRequestsComponent {...props} />
	</Placeholder>
);

// FeatureFlags
// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyFeatureFlagsComponent = lazy(() =>
	loadAsyncComponent().then((exportedModule) => ({
		default: exportedModule.FeatureFlags,
	})),
);
export const FeatureFlags = (props: TabProps) => (
	<Placeholder name="feature-flags-component" fallback={<TabContentPlaceholder />}>
		{/* @ts-expect-error - Type '{ issueId: string; issueKey: string; baseUrl: string; locale: Locale; }' has no properties in common with type 'IntrinsicAttributes'. */}
		<LazyFeatureFlagsComponent {...props} />
	</Placeholder>
);

// RemoteLinks
// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyRemoteLinksComponent = lazy(() =>
	loadAsyncComponent().then((exportedModule) => ({
		default: exportedModule.RemoteLinks,
	})),
);
export const RemoteLinks = (props: TabProps) => (
	<Placeholder name="remote-links-component" fallback={<TabContentPlaceholder />}>
		{/* @ts-expect-error - Type '{ issueId: string; issueKey: string; baseUrl: string; locale: Locale; }' has no properties in common with type 'IntrinsicAttributes'. */}
		<LazyRemoteLinksComponent {...props} />
	</Placeholder>
);
