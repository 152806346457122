import { freeze, set, setIn } from 'icepick';
import { devPanelTimeToLoad } from '../../../common/utils.tsx';
import { enrichSummary } from '../../../services/details/utils.tsx';
import {
	FETCH_DEV_PANEL_FAILURE,
	FETCH_DEV_PANEL_SUCCESS,
	FETCH_DEV_PANEL_URL_DETAILS_SUCCESS,
	TOGGLE_EMPTY_STATE_PANEL,
	TOGGLE_SUMMARY_SECTION_MESSAGE,
	SHOW_EMPTY_STATE_DIALOG,
	CLOSE_EMPTY_STATE_DIALOG,
	type DevPanelActions,
} from '../actions/index.tsx';
import type { SummaryState } from '../types.tsx';

export const initialState = freeze({
	devPanel: {
		summary: {},
		panelState: 'HIDDEN' as const,
		showSummarySectionMessage: false,
		hasBranchCapabilities: false,
		hasDevelopmentDataInProject: false,
	},
	createBranchTargets: [],
	// To show the empty state diag.
	isEmptyStateDialogShown: false,
});

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: SummaryState = initialState, action: DevPanelActions): SummaryState => {
	switch (action.type) {
		case FETCH_DEV_PANEL_SUCCESS: {
			devPanelTimeToLoad.stop();
			const updatedState = set(state, 'createBranchTargets', action.payload.createBranchTargets);
			return set(updatedState, 'devPanel', {
				summary: action.payload.summary,
				panelState: action.payload.panelState,
				hasBranchCapabilities: action.payload.hasBranchCapabilities,
				showSummarySectionMessage: action.payload.bannerType === 'ISSUE_KEY_ONBOARDING',
				hasDevelopmentDataInProject: action.payload.hasDevelopmentDataInProject,
			});
		}
		case FETCH_DEV_PANEL_URL_DETAILS_SUCCESS: {
			const currentSummary = state.devPanel.summary;
			if (currentSummary) {
				const enrichedSummary = enrichSummary(currentSummary, action.payload);
				return setIn(state, ['devPanel', 'summary'], enrichedSummary);
			}
			return state;
		}
		case FETCH_DEV_PANEL_FAILURE:
			devPanelTimeToLoad.cancel();
			return state;

		case SHOW_EMPTY_STATE_DIALOG:
			return set(state, 'isEmptyStateDialogShown', true);

		case CLOSE_EMPTY_STATE_DIALOG:
			return set(state, 'isEmptyStateDialogShown', false);

		case TOGGLE_EMPTY_STATE_PANEL:
			return set(state, 'devPanel', {
				...state.devPanel,
				panelState: action.payload.panelState,
			});

		case TOGGLE_SUMMARY_SECTION_MESSAGE:
			return set(state, 'devPanel', {
				...state.devPanel,
				showSummarySectionMessage: action.payload.showSummarySectionMessage,
			});

		default:
			return state;
	}
};
