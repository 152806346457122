/* eslint-disable @atlassian/relay/unused-fields */
/* eslint-disable @atlassian/relay/query-restriction */
import React, { useCallback, useMemo } from 'react';
import type { Dispatch } from 'redux';
import { graphql, useFragment } from 'react-relay';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fireUiAnalytics } from '@atlassian/jira-analytics-web-react/src/utils/fire-ui-event.tsx';
import { log } from '@atlassian/jira-common-util-logging/src/index.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import type { User } from '@atlassian/jira-issue-shared-types/src/common/types/user-type.tsx';
import { REMOVE_WATCHER_ERROR } from '@atlassian/jira-issue-view-common-constants/src/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { transformEdgesToTransformedNodes } from '@atlassian/jira-relay-utils/src/utils/transform-edges-to-transformed-nodes/index.tsx';
import type {
	watchersList_issueViewWatchers_WatchersListRelay$data,
	watchersList_issueViewWatchers_WatchersListRelay$key,
} from '@atlassian/jira-relay/src/__generated__/watchersList_issueViewWatchers_WatchersListRelay.graphql';
import { useOnShowFlag } from '../../../../controllers/watchers-context/index.tsx';
import { useWatchesMutation } from '../../../../services/use-watches-mutation/index.tsx';
import type { State } from '../../../model/types.tsx';
import {
	fetchWatchersRequest,
	removeWatcherRequest,
	type Action,
} from '../../../state/actions.tsx';
import {
	getIsWatchersListLoading,
	getWatchersListUsers,
	getCanManageWatchers,
} from '../../../state/selectors.tsx';
import { WatchersList } from './view.tsx';

type StateProps = {
	isLoading: boolean;
	canManageWatchers: boolean;
	users: User[];
};

type DispatchProps = {
	onFetchUsers: () => void;
	onDeleteUser: (arg1: User, arg2: () => void, arg3: () => void, arg4: UIAnalyticsEvent) => void;
};

export const WatchersListOld = connect(
	(state: State): StateProps => ({
		isLoading: getIsWatchersListLoading(state),
		users: getWatchersListUsers(state),
		canManageWatchers: getCanManageWatchers(state),
	}),
	(dispatch: Dispatch<Action>): DispatchProps => ({
		onFetchUsers: () => {
			dispatch(fetchWatchersRequest());
		},
		onDeleteUser: (user, onSuccess, onError, analyticsEvent) => {
			dispatch(removeWatcherRequest({ user, onSuccess, onError }));
			fireUiAnalytics(analyticsEvent, { name: 'deleteWatcher' });
		},
	}),
)(WatchersList);

type ExternalProps = {
	watches: watchersList_issueViewWatchers_WatchersListRelay$key;
};

const transformNodeToUser = (
	node: NonNullable<
		NonNullable<
			NonNullable<
				NonNullable<
					watchersList_issueViewWatchers_WatchersListRelay$data['selectedUsersConnection']
				>['edges']
			>[number]
		>['node']
	>,
): User => ({
	id: node.accountId,
	displayName: node.name,
	avatarUrl: node.picture,
	ari: node.id,
});

const WatchersListRelay = ({ watches, ...rest }: Omit<StateProps, 'users'> & ExternalProps) => {
	const data = useFragment<watchersList_issueViewWatchers_WatchersListRelay$key>(
		graphql`
			fragment watchersList_issueViewWatchers_WatchersListRelay on JiraWatchesField {
				id
				type
				fieldId
				watch {
					count
					isWatching
					...useWatchesMutation_Mutation_Updatable
				}
				selectedUsersConnection {
					edges {
						node {
							... on User {
								id
								accountId
								name
								picture
							}
						}
					}
				}
			}
		`,
		watches,
	);

	const users = useMemo(
		() => transformEdgesToTransformedNodes(data.selectedUsersConnection, transformNodeToUser),
		[data.selectedUsersConnection],
	);

	const { onUpdate } = useWatchesMutation();
	const { onShowFlag } = useOnShowFlag();

	const onDeleteUser = useCallback(
		async (
			user: User,
			onSuccess: () => void,
			onError: () => void,
			analyticsEvent: UIAnalyticsEvent,
		) => {
			const handleError = (error: Error) => {
				onShowFlag?.(REMOVE_WATCHER_ERROR);
				log.safeErrorWithoutCustomerData('issue.watchers.delete.watcher', error?.message, error);
				onError?.();
			};
			if (!user?.ari) {
				handleError(new Error('Could not find user to delete'));
				return;
			}
			onUpdate({
				id: data.id,
				userAri: user?.ari,
				isWatching: false,
				onError: handleError,
				onSuccess,
				watch: data?.watch,
				field: {
					type: data?.type,
					fieldId: data?.fieldId,
					watch: {
						count: data?.watch?.count,
						isWatching: data?.watch?.isWatching,
					},
				},
			});
			fireUiAnalytics(analyticsEvent, { name: 'deleteWatcher' });
		},
		[data, onShowFlag, onUpdate],
	);

	return <WatchersList {...rest} users={users} onDeleteUser={onDeleteUser} />;
};

export const WatchersListNew = connect((state: State) => ({
	isLoading: getIsWatchersListLoading(state),
	canManageWatchers: getCanManageWatchers(state),
}))(WatchersListRelay);

export default componentWithCondition(
	() => fg('relay-migration-issue-header-and-parent'),
	WatchersListNew,
	WatchersListOld,
);
