/**
 * @generated SignedSource<<901a84048d79c1879372de976d6c09d8>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type DevOpsEnvironmentCategory = "DEVELOPMENT" | "PRODUCTION" | "STAGING" | "TESTING" | "UNMAPPED" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type deploymentData_softwareReleasesVersionDetailIssueList$data = {
  readonly summarisedDeployments: {
    readonly deploymentEnvironment: {
      readonly category: DevOpsEnvironmentCategory | null | undefined;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"statusIcon_softwareReleasesVersionDetailIssueList">;
  } | null | undefined;
  readonly " $fragmentType": "deploymentData_softwareReleasesVersionDetailIssueList";
};
export type deploymentData_softwareReleasesVersionDetailIssueList$key = {
  readonly " $data"?: deploymentData_softwareReleasesVersionDetailIssueList$data;
  readonly " $fragmentSpreads": FragmentRefs<"deploymentData_softwareReleasesVersionDetailIssueList">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "deploymentData_softwareReleasesVersionDetailIssueList",
  "selections": [
    {
      "concreteType": "DevOpsSummarisedDeployments",
      "kind": "LinkedField",
      "name": "summarisedDeployments",
      "plural": false,
      "selections": [
        {
          "concreteType": "DevOpsEnvironment",
          "kind": "LinkedField",
          "name": "deploymentEnvironment",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "category"
            }
          ]
        },
        {
          "kind": "FragmentSpread",
          "name": "statusIcon_softwareReleasesVersionDetailIssueList"
        }
      ]
    }
  ],
  "type": "DevOpsSummarisedEntities"
};

(node as any).hash = "0a7623df234c698148c854c1e3a6ce8d";

export default node;
