import head from 'lodash/head';
import type {
	FieldOption,
	JiraFieldOptionWeight,
} from '@atlassian/jira-polaris-domain-field/src/field-option/types.tsx';
import type {
	FieldKey,
	FieldConfiguration,
} from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { createExperienceWithMetaType } from '@atlassian/jira-polaris-lib-analytics/src/common/utils/experience/main.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { getAppliedDecoration } from '../../decoration/index.tsx';
import type { Props, State } from '../../types.tsx';
import { remapValueDecorations } from '../decorations/utils.tsx';

const fieldOptionUpdateExperience = createExperienceWithMetaType(
	experience.fieldsSidebar.fieldOptionUpdate,
	'deleteOption',
	(err) => `FieldOptionUpdate: ${err?.message ?? 'generic error'}`,
);

export const deleteOption =
	(fieldKey: FieldKey, jiraOptionId: string) =>
	async (
		{ getState, setState }: StoreActionApi<State>,
		{ onFieldUpdateFailed, projectId, issueTypeIds, createAnalyticsEvent, fieldRemote }: Props,
	) => {
		const state = getState();
		const field = state.fields[fieldKey];

		const previousOptions = field.options;
		const { valueDecorations, ...previousConfig } = field?.configuration ?? {};
		const previousOptionWeights = previousConfig.optionWeights ?? [];

		const newOptions = previousOptions?.filter(
			({ jiraOptionId: optionId }: FieldOption) => optionId !== jiraOptionId,
		);

		if (newOptions === undefined || newOptions.length === 0) {
			// do nothing, the last option cannot be deleted
			return;
		}

		const previousValueDecorations = state.fieldValueDecorations[fieldKey] || [];
		const appliedDecoration = getAppliedDecoration(previousValueDecorations, jiraOptionId);

		const newValueDecorations = [
			...previousValueDecorations.filter(
				(decoration) => decoration.localDecorationId !== appliedDecoration?.localDecorationId,
			),
		];

		const jiraOptionIdNumber = parseInt(jiraOptionId, 10);
		const newOptionWeights = previousOptionWeights.filter(
			({ optionId }: JiraFieldOptionWeight) => optionId !== jiraOptionIdNumber,
		);
		const newConfig: FieldConfiguration = {
			...previousConfig,
			optionWeights: newOptionWeights,
		};

		const issueTypeId = head(issueTypeIds);

		setState({
			fields: {
				...state.fields,
				[fieldKey]: {
					...field,
					options: newOptions,
					configuration: newConfig,
				},
			},
			fieldValueDecorations: {
				...state.fieldValueDecorations,
				[fieldKey]: newValueDecorations,
			},
		});

		if (projectId !== undefined && issueTypeId !== undefined) {
			try {
				fieldOptionUpdateExperience.start();

				await fieldRemote.deleteFieldOption({
					fieldKey,
					optionId: jiraOptionIdNumber,
					issueTypeId,
				});

				fieldOptionUpdateExperience.success();

				fieldRemote
					.updateFieldConfiguration({
						fieldKey,
						issueTypeId,
						oldConfiguration: {
							...(previousOptionWeights.length > 0 ? { optionWeights: previousOptionWeights } : {}),
							...remapValueDecorations(previousValueDecorations, field.type),
						},
						configuration: {
							...(newOptionWeights.length > 0 ? { optionWeights: newOptionWeights } : {}),
							...remapValueDecorations(newValueDecorations, field.type),
						},
						type: 'deleteOptionRelatedFieldConfiguration',
					})
					.then(() => {
						fireCompoundAnalyticsEvent.FieldConfig.optionWeightChanged(createAnalyticsEvent({}), {
							issueFieldKey: fieldKey,
							issueFieldType: field.type,
							fieldValueId: jiraOptionId,
						});

						fireCompoundAnalyticsEvent.FieldConfig.valueDecorationChanged(
							createAnalyticsEvent({}),
							{
								issueFieldKey: fieldKey,
								issueFieldType: field.type,
							},
						);
					})
					.catch(onFieldUpdateFailed);

				fireCompoundAnalyticsEvent.FieldConfig.optionDeletedSuccess(createAnalyticsEvent({}), {
					issueFieldKey: fieldKey,
					issueFieldType: field.type,
					fieldValueId: jiraOptionId,
				});
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (err: any) {
				fireCompoundAnalyticsEvent.FieldConfig.optionDeletedFailure(createAnalyticsEvent({}));

				fieldOptionUpdateExperience.failure(err);

				onFieldUpdateFailed(err);
			}
		}
	};
