import {
	type CommentVisibility,
	type CommentVisibilityType,
	COMMENT_VISIBILITY_TYPE_PUBLIC,
	COMMENT_VISIBILITY_TYPE_ROLE,
	COMMENT_VISIBILITY_TYPE_GROUP,
} from '@atlassian/jira-issue-gira-transformer-types/src/common/types/comments.tsx';
import { COMMENT_VISIBILITY_PUBLIC } from '@atlassian/jira-issue-view-common-types/src/comment-type.tsx';
import type { FormatMessage } from '@atlassian/jira-shared-types/src/general.tsx';
import messages from '../messages.tsx';
import type { Option, Options } from '../types.tsx';
import { areCommentVisibilitiesEqual } from '../utils.tsx';

export const transformOption = ({ type, value, groupId }: Option): CommentVisibility => ({
	type,
	value,
	groupId,
});

const transformCommentVisibilities = (
	options: CommentVisibility[],
	value: CommentVisibility,
	type: CommentVisibilityType,
): Option[] =>
	options
		.filter((item) => item.type === type)
		.map((item) => ({
			...item,
			selected: item ? areCommentVisibilitiesEqual(item, value) : false,
		}));

const publicOption = (selectedOption: CommentVisibility): Options => ({
	label: '',
	options: [
		{
			...COMMENT_VISIBILITY_PUBLIC,
			selected: selectedOption.type === COMMENT_VISIBILITY_TYPE_PUBLIC,
		},
	],
});

export const getDefaultOptionGroups = (
	options: CommentVisibility[],
	formatMessage: FormatMessage,
	selectedOption: CommentVisibility,
): Options[] => [
	publicOption(selectedOption),
	{
		label: formatMessage(messages.projectRolesLabel),
		options: transformCommentVisibilities(options, selectedOption, COMMENT_VISIBILITY_TYPE_ROLE),
	},
	{
		label: formatMessage(messages.groupsLabel),
		options: transformCommentVisibilities(options, selectedOption, COMMENT_VISIBILITY_TYPE_GROUP),
	},
];

export const getEmptyOptionsWithInitialValue = (
	initialValue: CommentVisibility,
	selectedOption: CommentVisibility,
	formatMessage: FormatMessage,
): Options[] => {
	const emptyOptionsGroup: Options[] = [publicOption(selectedOption)];
	if (initialValue) {
		const isInitialValueSelected =
			initialValue.type === selectedOption.type && initialValue.value === selectedOption.value;
		emptyOptionsGroup.push({
			label:
				initialValue.type === COMMENT_VISIBILITY_TYPE_GROUP
					? formatMessage(messages.groupsLabel)
					: formatMessage(messages.projectRolesLabel),
			options: [
				{
					...initialValue,
					selected: isInitialValueSelected,
				},
			],
		});
	}
	return emptyOptionsGroup;
};
