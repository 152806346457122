import { createHook, createStore, createContainer } from '@atlassian/react-sweet-state';
import * as actions from './actions.tsx';
import type { State } from './types.tsx';

export type Actions = typeof actions;

export const store = createStore<State, Actions>({
	name: 'issue-voters',
	initialState: {
		voters: [],
		loggedInUserAction: undefined,
	},
	actions,
});

const getVoters = (state: State) => state.voters;
const getLoggedInUserAction = (state: State) => state.loggedInUserAction;

export const useVotersListStoreVoters = createHook(store, {
	selector: getVoters,
});

export const useVotersListStoreLoggedInUserAction = createHook(store, {
	selector: getLoggedInUserAction,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const VotersListContainer = createContainer<State, Actions, Record<any, any>>(store);
