import {
	VIEW_KIND_BOARD,
	VIEW_KIND_MATRIX,
	VIEW_KIND_TABLE,
	VIEW_KIND_TIMELINE,
} from '@atlassian/jira-polaris-domain-view/src/view/constants.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';

export const startCurrentViewLoadExperience = (viewKind?: string) => {
	switch (viewKind) {
		case VIEW_KIND_MATRIX:
			experience.matrixView.pageSegmentLoad.start();
			break;
		case VIEW_KIND_TIMELINE:
			experience.timelineView.pageSegmentLoad.start();
			break;
		case VIEW_KIND_BOARD:
			experience.boardView.pageSegmentLoad.start();
			break;
		case VIEW_KIND_TABLE:
			experience.listView.pageSegmentLoad.start();
			break;
		default:
	}
};
