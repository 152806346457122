import React, { useCallback, useState } from 'react';
import Button, { IconButton } from '@atlaskit/button/new';
import EditorCloseIcon from '@atlaskit/icon/glyph/editor/close';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
	ModalTransition,
	type KeyboardOrMouseEvent,
} from '@atlaskit/modal-dialog';
import { Box, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { messages } from './messages.tsx';

export const IdeaUnlinkCrossButton = ({
	issueKey,
	issueLinkId,
	isParentHovered,
	onUnlinkModalConfirmed,
}: {
	issueKey: IssueKey;
	issueLinkId: string;
	isParentHovered: boolean;
	onUnlinkModalConfirmed: (issueLinkId: string) => void;
}) => {
	const { formatMessage } = useIntl();
	const [isOpen, setIsOpen] = useState(false);

	const openModal = useCallback((e: React.MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();
		setIsOpen(true);
	}, []);

	const closeModal = useCallback((e: KeyboardOrMouseEvent) => {
		e.preventDefault();
		e.stopPropagation();
		setIsOpen(false);
	}, []);

	const confirmUnlink = useCallback(
		(e: React.MouseEvent) => {
			e.preventDefault();
			e.stopPropagation();
			setIsOpen(false);
			onUnlinkModalConfirmed(issueLinkId);
		},
		[issueLinkId, onUnlinkModalConfirmed],
	);

	return (
		<Box xcss={isParentHovered ? undefined : invisibleStyles}>
			<IconButton
				icon={EditorCloseIcon}
				label={formatMessage(messages.unlinkButtonTooltip)}
				spacing="compact"
				appearance="subtle"
				onClick={openModal}
				testId="issue-view-jpd-ideas.ui.idea-card.idea-unlink-cross-button.icon-button"
			/>
			<ModalTransition>
				{isOpen && (
					<ConfirmationModal issueKey={issueKey} onClose={closeModal} onConfirm={confirmUnlink} />
				)}
			</ModalTransition>
		</Box>
	);
};

export const ConfirmationModal = ({
	onClose,
	onConfirm,
	issueKey,
}: {
	onClose: (e: KeyboardOrMouseEvent) => void;
	onConfirm: (e: React.MouseEvent) => void;
	issueKey: IssueKey;
}) => {
	const { formatMessage } = useIntl();
	return (
		<Modal onClose={onClose}>
			<ModalHeader>
				<ModalTitle appearance="warning">
					{formatMessage(messages.unlinkModalTitle, { issueKey })}
				</ModalTitle>
			</ModalHeader>
			<ModalBody>{formatMessage(messages.unlinkModalBody)}</ModalBody>
			<ModalFooter>
				<Button appearance="subtle" onClick={onClose}>
					{formatMessage(messages.unlinkModalCancelButton)}
				</Button>
				<Button appearance="warning" onClick={onConfirm}>
					{formatMessage(messages.unlinkModalConfirmButton)}
				</Button>
			</ModalFooter>
		</Modal>
	);
};

const invisibleStyles = xcss({
	visibility: 'hidden',
});
