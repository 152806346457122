import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	publicTooltip: {
		id: 'issue-comment-base.comment.comment-visibility.button.public-tooltip',
		defaultMessage: 'Visible to all users',
		description: 'Comment visibility tooltip for dropdown public option.',
	},
	optionsTooltip: {
		id: 'issue-comment-base.comment.comment-visibility.button.options-tooltip',
		defaultMessage: 'Only visible to {value}',
		description: 'Comment visibility tooltip for dropdown options.',
	},
});
