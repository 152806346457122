import { combineReducers } from 'redux';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { setContext } from '../actions/context-actions.tsx';
import { LOAD_NEW_ISSUE, LOAD_RECENT_ISSUE } from '../actions/issue-navigation-actions.tsx';
import { fetchLoggedInUserSuccess } from '../actions/user-fetch-actions.tsx';
import analyticsReducer from '../analytics/analytics-reducer.tsx';
import { refreshIssueRequest } from '../common/actions/issue-fetch-actions.tsx';
import uploadContextReducer from '../common/media/upload-context/upload-context-reducer.tsx';
import userAuthReducer from '../common/media/user-auth/user-auth-reducer.tsx';
import viewContextReducer from '../common/media/view-context/view-context-reducer.tsx';
import { contextSelector } from '../common/state/selectors/context-selector.tsx';
import { loggedInUserDetailsSelector } from '../common/state/selectors/issue-selector.tsx';
import fieldsReducer from '../issue-field/state/reducers/field-reducer.tsx';
import activityFeedReducer from './activity-feed-reducer.tsx';
import agileReducer from './agile-reducer.tsx';
import assignIssueParentModalReducer from './assign-issue-parent-modal-reducer.tsx';
import attachmentPickerReducer from './attachment-picker-reducer.tsx';
import changeboardingReducer from './changeboarding-reducer.tsx';
import childPanelUiReducer from './child-panel-ui-reducer.tsx';
import cloneModalReducer from './clone-modal-reducer.tsx';
import commentsUiReducer from './comment-ui-reducer.tsx';
import contextReducer from './context-reducer.tsx';
import draftsUiReducer from './drafts/reducer.tsx';
import glanceUiReducer from './ecosystem-glance-reducer.tsx';
import entitiesReducer from './entities/reducer.tsx';
import errorMessageReducer from './error-message-reducer.tsx';
import errorReducer from './error-reducer.tsx';
import fetchIssueTypesStatusReducer from './fetch-issue-types-status-reducer.tsx';
import flagsReducer from './flag-reducer.tsx';
import InvestigateIncidentReducer from './investigate-incident-ui-reducer.tsx';
import isDeletingIssueReducer from './issue-delete-reducer.tsx';
import issueLoadingStageReducer, {
	issueLoadingStagePromiseReducer,
} from './issue-loading-stage-reducer.tsx';
import issueTypeReducer from './issue-type-reducer.tsx';
import proformaReducer from './proforma-reducer.tsx';
import projectValidatorReducer from './project-validator-reducer.tsx';
import recentlyVisitedConfluencePagesReducer from './recently-visited-confluence-pages-reducer.tsx';
import shareAsCommentReducer from './share-as-comment-reducer.tsx';
import uiConfigReducer from './ui-config-reducer.tsx';
import viewMountStatusReducer from './view-mount-status-reducer.tsx';
import worklogUiReducer from './worklog-ui-reducer/index.tsx';

const initialState = {
	agile: undefined,
	context: undefined,
	entities: undefined,
	fields: undefined,
	mediaContext: { viewContext: null, uploadContext: null, userAuth: null },
	shareAsComment: undefined,
	timing: undefined,
	ui: undefined,
	validators: undefined,
} as const;

const featureFlaggedUiReducers = () => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const flaggedReducers: Record<string, any> = {};

	flaggedReducers.issueType = issueTypeReducer;
	// This is safe to be switched on even without checking `useIsRelayEnabled` because:
	// We have no access to other data for performing this check,
	// so we must check for `useIsRelayEnabled` when requesting this piece of redux state
	flaggedReducers.doNotUseIsLoadingPromise = issueLoadingStagePromiseReducer;
	flaggedReducers.proforma = proformaReducer;
	return flaggedReducers;
};

// @ts-expect-error - TS7006 - Parameter 'action' implicitly has an 'any' type.
const appReducer = (state: State, action) =>
	combineReducers<State>({
		agile: agileReducer,
		context: contextReducer,
		entities: entitiesReducer,
		fields: fieldsReducer,
		mediaContext: combineReducers({
			uploadContext: uploadContextReducer,
			userAuth: userAuthReducer,
			viewContext: viewContextReducer,
		}),
		shareAsComment: shareAsCommentReducer,
		timing: analyticsReducer,
		// @ts-expect-error - No overload matches this call.
		ui: combineReducers({
			attachments: combineReducers({
				picker: attachmentPickerReducer,
			}),
			activityFeed: activityFeedReducer,
			assignIssueParentModal: assignIssueParentModalReducer,
			comments: commentsUiReducer,
			changeboarding: changeboardingReducer,
			childPanel: childPanelUiReducer,
			cloneModal: cloneModalReducer,
			drafts: draftsUiReducer,
			ecosystem: combineReducers({
				glance: glanceUiReducer,
			}),
			error: errorReducer,
			fetchIssueTypesStatus: fetchIssueTypesStatusReducer,
			flags: flagsReducer,
			issueDeletion: isDeletingIssueReducer,
			loadingStage: issueLoadingStageReducer,
			recentlyVisitedConfluencePages: recentlyVisitedConfluencePagesReducer,
			uiConfig: uiConfigReducer,
			viewMountStatus: viewMountStatusReducer,
			worklog: worklogUiReducer,
			investigateIncident: InvestigateIncidentReducer,
			errorMessage: errorMessageReducer,
			...featureFlaggedUiReducers(),
		}),
		validators: projectValidatorReducer,
	})(state, action);

/*
 TODO: Ideally we shouldn't use "InitialState" here in root reducer because it will be huge and its default values will override the default values in child reducers.
  Once that's done we should use "State" type instead of "any".
*/
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const rootReducer = (state: any = initialState, action: any) => {
	if (action.type === LOAD_NEW_ISSUE) {
		// reset the state here to only keep context and some specific preferences, so the epic
		// will use the new issue key to fetch the new issue. this would not be needed if our state
		// was linked to issue-key rather than 'global'
		const withContext = appReducer(
			{
				// @ts-expect-error - TS2740 - Type '{ changeboarding: any; uiConfig: any; }' is missing the following properties from type 'Readonly<{ activityFeed: ActivityFeedUiState; assignIssueParentModal: AssignIssueParentModalUiState; attachments: AttachmentsUiState; ... 19 more ...; shortcutDialog: ShortcutDialogUiState; }>': activityFeed, assignIssueParentModal, attachments, childPanel, and 17 more.
				ui: {
					changeboarding: state.ui.changeboarding,
					uiConfig: state.ui.uiConfig,
				},
			},
			setContext(contextSelector(state)),
		);
		const updatedContext = appReducer(withContext, action);
		const currentLoggedInUser = loggedInUserDetailsSelector(state);

		if (currentLoggedInUser) {
			return appReducer(updatedContext, fetchLoggedInUserSuccess(currentLoggedInUser));
		}
		return updatedContext;
	}

	if (action.type === LOAD_RECENT_ISSUE) {
		const currentContext = contextSelector(state);
		const { issueState } = action.payload;

		return appReducer(
			{
				...issueState,
				// The context for same issue might have changed - e.g. analyticsSource
				context: {
					...issueState.context,
					...currentContext,

					// Overwrite properties below - newContext might contain stale data for these properties =============================
					issueId: issueState.context.issueId,
				},
			},
			refreshIssueRequest(),
		);
	}

	return appReducer(state, action);
};

export default rootReducer;
