import React from 'react';
import { Redirect } from '@atlassian/react-resource-router';
import type { Props } from './types.tsx';

const SanitiseUrl = ({ issueKey, location, children }: Props) => {
	const upperCaseKey = issueKey.toUpperCase();
	if (issueKey && issueKey !== upperCaseKey) {
		const newLocation = {
			pathname: location.pathname.replace(issueKey, upperCaseKey),
			search: '',
			hash: '',
		};

		return <Redirect to={newLocation} />;
	}

	return <>{children}</>;
};

export default SanitiseUrl;
