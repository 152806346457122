import type { ReactNode } from 'react';
import type { ApolloError } from 'apollo-client';
import type {
	GetRequestTypesForChangeCalendarEventCreateDialog as Response,
	GetRequestTypesForChangeCalendarEventCreateDialog_jiraIssueCreateFields_JiraIssueCreateFields_fields_nodes_JsmRequestTypeField_requestTypes_nodes as ResponseRequestType,
} from './__generated_apollo__/GetRequestTypesForChangeCalendarEventCreateDialog';
import type { Filter, RequestType } from './types.tsx';

const toRequestType = (responseRequestType: ResponseRequestType): RequestType => ({
	id: responseRequestType.requestTypeId,
	name: responseRequestType.name,
	key: responseRequestType.key,
	issueTypeId: responseRequestType.issueType?.issueTypeId ?? undefined,
	practices: responseRequestType.practices.map((practice) => practice.key),
});

const filterResponse = (responseRequestType: ResponseRequestType, filter?: Filter): boolean => {
	if (filter == null) {
		return true;
	}

	const { practices: practicesFilter } = filter;

	let result = true;
	if (practicesFilter != null) {
		result =
			result &&
			responseRequestType.practices.some(
				(practice) => practicesFilter && practicesFilter.includes(practice.key),
			);
	}

	return result;
};

export const transformResponse = (
	loading: boolean,
	error: ApolloError | null | undefined,
	data?: Response | null,
	filter?: Filter,
): RequestType[] => {
	if (loading || error != null) {
		return [];
	}

	const result: RequestType[] = [];
	if (data?.jiraIssueCreateFields?.__typename === 'JiraIssueCreateFields') {
		const nodes = data.jiraIssueCreateFields.fields?.nodes ?? [];
		// @ts-expect-error - TS2345 - Argument of type '(node?: ReactNode | null) => void' is not assignable to parameter of type '(value: GetRequestTypesForChangeCalendarEventCreateDialog_jiraIssueCreateFields_JiraIssueCreateFields_fields_nodes, index: number, array: readonly GetRequestTypesForChangeCalendarEventCreateDialog_jiraIssueCreateFields_JiraIssueCreateFields_fields_nodes[]) => void'.
		nodes.forEach((node?: ReactNode | null) => {
			// @ts-expect-error - TS2339 - Property '__typename' does not exist on type 'true | ReactChild | ReactFragment | ReactPortal'.
			if (node && node.__typename === 'JsmRequestTypeField') {
				// @ts-expect-error - TS2339 - Property 'requestTypes' does not exist on type 'true | ReactChild | ReactFragment | ReactPortal'.
				const { requestTypes } = node;
				if (requestTypes != null) {
					const filteredResult: RequestType[] = requestTypes.nodes
						.filter((responseRequestType: ResponseRequestType) =>
							filterResponse(responseRequestType, filter),
						)
						.map((responseRequestType: ResponseRequestType) => toRequestType(responseRequestType));

					result.push(...filteredResult);
				}
			}
		});
	}
	return result;
};
