import { defaultLocale } from '@atlassian/jira-common-constants/src/supported-locales.tsx';
import type { User } from '@atlassian/jira-issue-shared-types/src/common/types/user-type.tsx';
import { toIssueKey, toBaseUrl } from '@atlassian/jira-shared-types/src/general.tsx';
import type { State } from '../model/types.tsx';
import {
	INITIALIZE_APP_PROPS,
	REFRESH_APP_PROPS,
	ADD_WATCHER_REQUEST,
	ADD_WATCHER_FAILURE,
	REMOVE_WATCHER_REQUEST,
	REMOVE_WATCHER_FAILURE,
	TOGGLE_WATCHING_REQUEST,
	START_WATCHING_FAILURE,
	START_WATCHING_SUCCESS,
	STOP_WATCHING_FAILURE,
	STOP_WATCHING_SUCCESS,
	FETCH_WATCHERS_REQUEST,
	FETCH_WATCHERS_SUCCESS,
	FETCH_WATCHERS_FAILURE,
	type Action,
} from './actions.tsx';
import { getLoggedInUserDetails, getIsWatching, getWatchersListUsers } from './selectors.tsx';

const defaultState: State = {
	isProjectSimplified: false,
	tenantContext: {
		baseUrl: toBaseUrl(''),
		locale: defaultLocale,
	},
	loggedInUserDetails: null,
	issueKey: toIssueKey(''),
	watchersCount: 0,
	isWatching: false,
	isKeyboardShortcutEnabled: false,
	watchersList: {
		isLoading: false,
		users: [],
	},
	permissions: {
		canViewWatchers: false,
		canManageWatchers: false,
	},
};

const addUserToWatcherList = (state: State, payload: { user: User }) => {
	const { user } = payload;
	const loggedInUserDetails = getLoggedInUserDetails(state);
	const isWatching = getIsWatching(state);

	if (!loggedInUserDetails) {
		return state;
	}

	return {
		...state,
		isWatching: user.id === loggedInUserDetails.id || isWatching,
		watchersCount: state.watchersCount + 1,
		watchersList: {
			...state.watchersList,
			users: [user, ...state.watchersList.users],
		},
	};
};

const addLoggedInUserToWatcherList = (state: State): State => {
	const loggedInUserDetails = getLoggedInUserDetails(state);

	if (!loggedInUserDetails) {
		return state;
	}

	return addUserToWatcherList(state, { user: loggedInUserDetails });
};

const removeUserFromWatcherList = (state: State, payload: { user: User }): State => {
	const { user } = payload;
	const loggedInUserDetails = getLoggedInUserDetails(state);
	const isWatching = getIsWatching(state);

	if (!loggedInUserDetails) {
		return state;
	}

	const isWatchingByAccountId = user.id === loggedInUserDetails.id;

	return {
		...state,
		isWatching: isWatchingByAccountId ? false : isWatching,
		watchersCount: state.watchersCount - 1,
		watchersList: {
			...state.watchersList,
			users: state.watchersList.users.filter((u) => u.id !== user.id),
		},
	};
};

/*
 * Checks if start/stop watch request is fired before fetch watchers returns.
 * If this happens, it results in a race condition resulting in local
 * optimistic state being incorrect, requiring a resync to what the user
 * expects to see.
 */
const isOptimisticLoggedInUserWatchStateInSync = (
	state: State,
	shouldBeWatching: boolean,
): boolean => {
	const loggedInUserDetails = getLoggedInUserDetails(state);
	const loggedInUserIsWatching = !!getWatchersListUsers(state).find(
		(u) => u.id === (loggedInUserDetails && loggedInUserDetails.id),
	);
	return loggedInUserIsWatching === shouldBeWatching;
};

const removeLoggedInUserFromWatcherList = (state: State): State => {
	const loggedInUserDetails = getLoggedInUserDetails(state);

	if (!loggedInUserDetails) {
		return state;
	}

	return removeUserFromWatcherList(state, { user: loggedInUserDetails });
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: State = defaultState, action: Action): State => {
	switch (action.type) {
		case INITIALIZE_APP_PROPS:
			return {
				...state,
				...action.payload,
			};
		case REFRESH_APP_PROPS:
			return {
				...state,
				...action.payload,
			};
		case ADD_WATCHER_REQUEST:
			return addUserToWatcherList(state, action.payload);

		case ADD_WATCHER_FAILURE:
			return removeUserFromWatcherList(state, action.payload);

		case REMOVE_WATCHER_REQUEST:
			return removeUserFromWatcherList(state, action.payload);

		case REMOVE_WATCHER_FAILURE:
			return addUserToWatcherList(state, action.payload);

		case TOGGLE_WATCHING_REQUEST:
			return state.isWatching
				? removeLoggedInUserFromWatcherList(state)
				: addLoggedInUserToWatcherList(state);

		case START_WATCHING_FAILURE:
			return removeLoggedInUserFromWatcherList(state);

		case STOP_WATCHING_FAILURE:
			return addLoggedInUserToWatcherList(state);

		case FETCH_WATCHERS_REQUEST: {
			return {
				...state,
				watchersList: {
					...state.watchersList,
					isLoading: true,
				},
			};
		}

		case FETCH_WATCHERS_SUCCESS: {
			const { users } = action.payload;

			const loggedInUser = getLoggedInUserDetails(state);
			const loggedInUserAccountId = loggedInUser && loggedInUser.id;
			const isWatching = users.filter((u) => u.id === loggedInUserAccountId).length === 1;

			return {
				...state,
				watchersCount: users.length,
				watchersList: {
					isLoading: false,
					users,
				},
				isWatching,
			};
		}

		case START_WATCHING_SUCCESS:
			return isOptimisticLoggedInUserWatchStateInSync(state, true)
				? state
				: addLoggedInUserToWatcherList(state);

		case STOP_WATCHING_SUCCESS:
			return isOptimisticLoggedInUserWatchStateInSync(state, false)
				? state
				: removeLoggedInUserFromWatcherList(state);

		case FETCH_WATCHERS_FAILURE:
			return {
				...state,
				watchersList: {
					...state.watchersList,
					users: [],
				},
			};
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
