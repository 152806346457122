import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	defaultNoValueText: {
		id: 'issue-field-date.default-no-value-text',
		defaultMessage: 'None',
		description: 'Default text shown when no date has been set',
	},
	dueToday: {
		id: 'issue-field-date.due-today',
		defaultMessage: 'Due today',
		description: 'Tooltip text shown when a date is due today',
	},
	dueIn: {
		id: 'issue-field-date.due-in',
		defaultMessage: '{daysBetween} {daysBetween, plural, one {day} other {days}} overdue',
		description: 'Tooltip shown when a date is past X days. Eg: Due in 2 days',
	},
});
