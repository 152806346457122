import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	parentTitle: {
		id: 'issue-field-parent.parent-title',
		defaultMessage: 'Click to visit {label}',
		description: 'Parent url title - Click to visit [parent label]',
	},
	icon: {
		id: 'issue-field-parent.icon',
		defaultMessage: '{issueType} icon',
		description: 'Alt text explainer, i.e. Epic icon',
	},
});
