import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { RESULTS } from '../constants.tsx';

export const Field = {
	createdSuccess: (event: UIAnalyticsEvent, attributes: object) => {
		// https://data-portal.internal.atlassian.com/analytics/registry/51308
		fireTrackAnalytics(event, 'issueField created', attributes);
	},
	createdFailure: (event: UIAnalyticsEvent, fieldType: string) => {
		// https://data-portal.internal.atlassian.com/analytics/registry/47737
		fireTrackAnalytics(event, 'ideaField created', {
			result: RESULTS.FAILURE,
			fieldType,
		});
	},
};
