import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	codeToolsPromptLabel: {
		id: 'development-summary-app-config-prompt-field.code-tools-prompt-label',
		defaultMessage: 'Set up code tools',
		description: 'The title of the code (SCM) apps configuration prompt dropdown.',
	},
	deploymentToolsPromptLabel: {
		id: 'development-summary-app-config-prompt-field.deployment-tools-prompt-label',
		defaultMessage: 'Set up deployment tools',
		description: 'The title of the deployment (CICD) apps configuration prompt dropdown.',
	},
	securityToolsPromptLabel: {
		id: 'development-summary-app-config-prompt-field.security-tools-prompt-label',
		defaultMessage: 'Set up security tools',
		description: 'The title of the security apps configuration prompt dropdown.',
	},
	pendingLozenge: {
		id: 'development-summary-app-config-prompt-field.pending-lozenge',
		defaultMessage: 'PENDING',
		description:
			'The lozenge text explaining there is pending configuration to finish. The reason why it is all capital is it is rendered inside lozenge that will be auto-capitalized in English. Not sure in other languages, if this is needed.',
	},
});
