import { type Ari, createAri } from '@atlassian/jira-platform-ari/src/index.tsx';
import { useProjectContext } from '@atlassian/jira-providers-project-context/src/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';

export const useProjectAri = (): Ari => {
	const { data: projectContext } = useProjectContext();
	const cloudId = useCloudId();

	const projectAri = projectContext?.projectId.toString() ?? '';

	return createAri({
		resourceOwner: 'jira',
		cloudId,
		resourceType: 'project',
		// Since we are in project context, empty string should not happen. If it happens, we will be able to monitor errors via the nearest error boundary, since the hasRelationship query will emit an error.
		resourceId: projectAri,
	});
};
