/**
 * @generated SignedSource<<5c8551d21bf96a87ce8aa13f4237812b>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type project_issueFieldProjectInlineEditFull_ProjectInlineEditView_fragmentRef$data = {
  readonly fieldConfig: {
    readonly isEditable: boolean | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"project_issueFieldProjectInlineEditFull_ProjectInlineEditViewWithIsEditable_fragmentRef">;
  readonly " $fragmentType": "project_issueFieldProjectInlineEditFull_ProjectInlineEditView_fragmentRef";
};
export type project_issueFieldProjectInlineEditFull_ProjectInlineEditView_fragmentRef$key = {
  readonly " $data"?: project_issueFieldProjectInlineEditFull_ProjectInlineEditView_fragmentRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"project_issueFieldProjectInlineEditFull_ProjectInlineEditView_fragmentRef">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "project_issueFieldProjectInlineEditFull_ProjectInlineEditView_fragmentRef",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "project_issueFieldProjectInlineEditFull_ProjectInlineEditViewWithIsEditable_fragmentRef"
    },
    {
      "concreteType": "JiraFieldConfig",
      "kind": "LinkedField",
      "name": "fieldConfig",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isEditable"
        }
      ]
    }
  ],
  "type": "JiraProjectField"
};

(node as any).hash = "a22c082aad5aeb9d34240f4a5a1f9176";

export default node;
