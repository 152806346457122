import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	successTitle: {
		id: 'linked-alerts.services.actions.unlink-alert.success-title',
		defaultMessage: 'Success',
		description:
			'The title text in the status flag when unlinking an alert from current issue is successful.',
	},
	successDescription: {
		id: 'linked-alerts.services.actions.unlink-alert.success-description',
		defaultMessage: 'The alert was unlinked',
		description:
			'The description text in the status flag when unlinking an alert from current issue is successful.',
	},
	errorTitle: {
		id: 'linked-alerts.services.actions.unlink-alert.error-title',
		defaultMessage: 'We couldn’t unlink the alert',
		description:
			'The title text in the status flag when unlinking an alert from current issue fails.',
	},
	errorDescription: {
		id: 'linked-alerts.services.actions.unlink-alert.error-description',
		defaultMessage: 'Select Unlink or refresh the page to try again.',
		description:
			'The description text in the status flag when unlinking an alert from current issue fails.',
	},
});
