import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const asyncInlineEditStatusModal = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(
			/* webpackChunkName: "async-inline-edit-status-modal" */ './src/AsyncInlineEditStatusModal'
		).then((module) => module.AsyncInlineEditStatusModal),
	),
	getPreloadProps: () => ({}),
});
