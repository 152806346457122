import React, { useCallback, useMemo } from 'react';
import { xcss, Box } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';
import client from '@atlassian/jira-apollo-gira/src/index.tsx';
import { ApolloClientProvider } from '@atlassian/jira-apollo-multiple-clients/src/main.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import type { EntryPointReferenceSubject } from '@atlassian/jira-entry-point/src/controllers/utils/use-entry-point-load-manager/index.tsx';
import type { EditFieldConfigState } from '@atlassian/jira-issue-view-services/src/edit-field-config-context/context.tsx';
import type { inlineFieldConfigEntryPoint } from '@atlassian/jira-issue-view-services/src/edit-field-config-context/inlineFieldConfigEntryPoint.tsx';
import type { ProjectId, ProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';

type WrappedInlineFieldConfigContentEntryPointProps = {
	editFieldConfigState: EditFieldConfigState;
	setEditFieldConfigState: (args: EditFieldConfigState) => void;
	inlineFieldConfigEntryPointReferenceSubject: EntryPointReferenceSubject<
		typeof inlineFieldConfigEntryPoint
	>;
	onRefreshIssue: () => void;
	projectId: ProjectId;
	projectKey: ProjectKey;
	isSidebarView?: boolean;
};

export const WrappedInlineFieldConfigContentEntryPoint = ({
	editFieldConfigState,
	setEditFieldConfigState,
	inlineFieldConfigEntryPointReferenceSubject,
	onRefreshIssue,
	projectId,
	projectKey,
	isSidebarView,
}: WrappedInlineFieldConfigContentEntryPointProps) => {
	const onEditFieldClosed = useCallback(() => {
		setEditFieldConfigState({
			mode: 'closed',
			fieldId: null,
		});
		setTimeout(() => {
			onRefreshIssue();
		}, 1000);
	}, [onRefreshIssue, setEditFieldConfigState]);

	const onFieldCreated = useCallback(
		async (fieldId: string) => {
			setEditFieldConfigState({
				mode: 'edit',
				fieldId,
			});
		},
		[setEditFieldConfigState],
	);

	const editFieldConfigStateMode: 'create' | 'edit' =
		editFieldConfigState.mode === 'adding' ? 'create' : 'edit';

	const entryPointRuntimeProps = useMemo(
		() => ({
			mode: editFieldConfigStateMode,
			fieldId: editFieldConfigState.fieldId ?? undefined,
			projectId,
			projectKey,
			onClose: onEditFieldClosed,
			onFieldCreated,
			isSidebarView,
		}),
		[
			editFieldConfigState.fieldId,
			editFieldConfigStateMode,
			isSidebarView,
			onEditFieldClosed,
			onFieldCreated,
			projectId,
			projectKey,
		],
	);

	return (
		<Box xcss={fieldConfigLayoutStyles}>
			<ApolloClientProvider client={client}>
				<JiraEntryPointContainer
					id="issue-view.inline-field-config"
					packageName="jira-issue-view-common"
					entryPointReferenceSubject={inlineFieldConfigEntryPointReferenceSubject}
					fallback={
						<Box xcss={fieldConfigLayoutLoadingStyles}>
							<Spinner size="small" />
						</Box>
					}
					runtimeProps={entryPointRuntimeProps}
				/>
			</ApolloClientProvider>
		</Box>
	);
};

const fieldConfigLayoutLoadingStyles = xcss({
	height: '600px',
	display: 'flex',
	justifyContent: 'center',
});

const fieldConfigLayoutStyles = xcss({
	height: 'fit-content',
	minHeight: '600px',
	borderRadius: 'border.radius.200',
	borderColor: 'color.border',
	borderWidth: 'border.width',
	borderStyle: 'solid',
	paddingTop: 'space.200',
	paddingBottom: 'space.200',
});
