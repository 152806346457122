export const RESOLVED = 'RESOLVED' as const;
export const OPEN = 'OPEN' as const;

// Feedback collector constants
export const FEEDBACK_COLLECTOR_LOCATION = 'jsm.issue-view';
export const FEEDBACK_COLLECTOR_ID = 'bento-similar-requests-panel';
export const FEEDBACK_GROUP_ID = 'jira-servicedesk-related-issues';

export const BENTO_FEEDBACK_TYPE_FIELD_ID = 'customfield_10065';
export const BENTO_FEEDBACK_TYPE_BUG = { id: '10161' };
export const BENTO_FEEDBACK_TYPE_OTHER = { id: '10160' };
export const SESSION_ID = 'sessionId';
export const TENANT_CLOUD_ID = 'tenantCloudId';

// Similar Incidents Feedback collector constants
export const SIMILAR_INCIDENTS_FEEDBACK_COLLECTOR_ID = 'bento-similar-incidents-panel';

// Default Similar Incidents View Max Cards Number
export const DEFAULT_SIMILAR_INCIDENTS_CARD = 3;
export const MAX_SIMILAR_INCIDENTS_CARD = 5;

export const LIKE = 'like' as const;
export const DISLIKE = 'dislike' as const;
