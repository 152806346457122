import React, { useMemo } from 'react';

import type AnalyticsWebClient from '@atlassiansox/analytics-web-client';

import {
	AnalyticsListener as AnalyticsListenerAnalyticsNext,
	type UIAnalyticsEvent,
	type UIAnalyticsEventHandler,
} from '@atlaskit/analytics-next';
import {
	extractAWCDataFromEvent,
	OPERATIONAL_EVENT_TYPE,
	TRACK_EVENT_TYPE,
} from '@atlassian/analytics-bridge';

import { UI_MODIFICATIONS_ANALYTICS_CHANNEL } from '../../constants';
import {
	type AnalyticsEventPayload,
	type AnalyticsEventWithType,
	type AnalyticsListenerProps,
} from '../../types';

export const sendEvent = (
	event: AnalyticsEventWithType,
	client: AnalyticsWebClient | Promise<AnalyticsWebClient>,
): void | Promise<void> => {
	switch (event.type) {
		case OPERATIONAL_EVENT_TYPE:
			Promise.resolve(client).then((c) => c.sendOperationalEvent(event));
			break;

		case TRACK_EVENT_TYPE:
			Promise.resolve(client).then((c) => c.sendTrackEvent(event));
			break;
	}
};

export const getEventHandler: (
	analyticsWebClient: AnalyticsWebClient | Promise<AnalyticsWebClient>,
) => UIAnalyticsEventHandler =
	(analyticsWebClient: AnalyticsWebClient | Promise<AnalyticsWebClient>) =>
	(event: UIAnalyticsEvent) => {
		const eventData = extractAWCDataFromEvent(event);

		sendEvent(
			{
				type: eventData.type,
				...(eventData.payload as AnalyticsEventPayload),
			},
			analyticsWebClient,
		);
	};

export const AnalyticsListener = ({ children, analyticsWebClient }: AnalyticsListenerProps) => {
	const onEvent = useMemo(() => getEventHandler(analyticsWebClient), [analyticsWebClient]);

	return (
		<AnalyticsListenerAnalyticsNext onEvent={onEvent} channel={UI_MODIFICATIONS_ANALYTICS_CHANNEL}>
			{children}
		</AnalyticsListenerAnalyticsNext>
	);
};
