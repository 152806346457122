import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import {
	FETCH_DEV_PANEL_SUCCESS,
	FETCH_DEV_PANEL_FAILURE,
} from '../../dev-summary/actions/index.tsx';
import type { State } from '../../types.tsx';
import { getAnalyticsIssueData } from '../selectors/index.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	[FETCH_DEV_PANEL_SUCCESS]: (
		state: State,
		action: {
			analyticsEvent: UIAnalyticsEvent;
		},
	) => ({
		eventNameSuffix: 'fetch.succeeded',
		eventData: getAnalyticsIssueData(state),
		analyticsEvent: action.analyticsEvent,
		action: 'panel rendered',
		actionSubjectId: 'devpanelsummary',
	}),

	[FETCH_DEV_PANEL_FAILURE]: (
		state: State,
		action: {
			analyticsEvent: UIAnalyticsEvent;
		},
	) => ({
		eventNameSuffix: 'fetch.failed',
		eventData: getAnalyticsIssueData(state),
		analyticsEvent: action.analyticsEvent,
		action: 'panel failed',
		actionSubjectId: 'devpanelsummary',
	}),
};
