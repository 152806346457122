import { Clause } from './clause';
import { OrderBy } from './order-by';
import { AstNode, BaseError } from './common';

export const NODE_TYPE_QUERY: 'query' = 'query';

/**
 * A parsed JQL query.
 */
export interface Query extends AstNode {
  type: typeof NODE_TYPE_QUERY;
  /**
   * The <em>where</em> part of the JQL query.
   */
  where: Clause | void;
  /**
   * The <em>order by</em> part of the JQL query.
   */
  orderBy: OrderBy | void;
  /**
   * The original query string used to construct the AST.
   */
  represents: string;
}

/**
 * A complete abstract syntax tree for a JQL expression. AST shape is derived from https://stash.atlassian.com/projects/JIRACLOUD/repos/jira/browse/jira-components/jira-rest/jira-rest-api/src/main/java/com/atlassian/jira/rest/api/jql/ast/JqlQueryBean.java
 */
export interface Jast {
  /**
   * A parsed JQL query. This will be undefined if the JQL could not be parsed.
   */
  query: Query | void;
  /**
   * Collection of errors that occurred while parsing the JQL expression.
   */
  errors: BaseError[];
}
