import React, { useEffect, useMemo } from 'react';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { loomQuickAddItemEntryPoint } from './ui/entrypoint.tsx';
import type { LoomQuickAddItemViewProps } from './ui/types.tsx';

export const LoomQuickAddItemView = ({
	loomTouchpointVariant,
	onDismissAd,
}: LoomQuickAddItemViewProps) => {
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		loomQuickAddItemEntryPoint,
		{},
	);

	const runtimeProps = useMemo(
		() => ({
			loomTouchpointVariant,
			onDismissAd,
		}),
		[loomTouchpointVariant, onDismissAd],
	);

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		entryPointActions.load();
	}, [entryPointActions]);

	return (
		<JiraEntryPointContainer
			entryPointReferenceSubject={entryPointReferenceSubject}
			id="loomQuickAddItemView"
			packageName="jiraIssueViewFoundation"
			errorFallback="unmount"
			teamName="team-multipliers"
			runtimeProps={runtimeProps}
		/>
	);
};
