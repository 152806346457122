// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	defaultRecentPages: {
		id: 'issue.views.confluence-search-view.default-recent-view',
		defaultMessage: 'Recently visited',
		description: 'Group heading for the recently viewed results',
	},
	defaultMatchingPages: {
		id: 'issue.views.confluence-search-view.default-matching-pages',
		defaultMessage: 'Matching pages',
		description: 'Group heading for the search results',
	},
});
