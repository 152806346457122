/**
 * @generated SignedSource<<92d68c94f3945ef62ecc6fb60db9d2cf>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type issueViewReporterField_servicedeskCustomerServiceCustomFields_JCSReporterField$data = {
  readonly user: {
    readonly accountId: string;
    readonly name: string;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"reporterField_servicedeskCustomerServiceCustomFields">;
  readonly " $fragmentType": "issueViewReporterField_servicedeskCustomerServiceCustomFields_JCSReporterField";
};
export type issueViewReporterField_servicedeskCustomerServiceCustomFields_JCSReporterField$key = {
  readonly " $data"?: issueViewReporterField_servicedeskCustomerServiceCustomFields_JCSReporterField$data;
  readonly " $fragmentSpreads": FragmentRefs<"issueViewReporterField_servicedeskCustomerServiceCustomFields_JCSReporterField">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "issueViewReporterField_servicedeskCustomerServiceCustomFields_JCSReporterField",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "reporterField_servicedeskCustomerServiceCustomFields"
    },
    {
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "accountId"
        },
        {
          "kind": "ScalarField",
          "name": "name"
        }
      ]
    }
  ],
  "type": "JiraSingleSelectUserPickerField"
};

(node as any).hash = "3d9d2ba744c9daa5441219585baa594b";

export default node;
