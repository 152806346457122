import React from 'react';
import {
	ModalBoundary,
	type ModalBoundaryProps,
} from '@atlassian/jira-modal/src/ui/modal-boundary/index.tsx';

export type AssignIssueParentModalBoundaryProps = Flow.Diff<
	ModalBoundaryProps,
	{
		id: string;
	}
>;

export const AssignIssueParentModalBoundary = ({
	children,
	...props
}: AssignIssueParentModalBoundaryProps) => (
	<ModalBoundary id="assign-issue-parent-modal" {...props}>
		{children}
	</ModalBoundary>
);
