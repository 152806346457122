import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { createErrorAnalytics } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import type { ProjectAccessLevelType } from '../../../../common/types/access/index.tsx';
import { fetchProjectAccessLevel } from '../../../../services/jira/project/access/index.tsx';
import type { State, Props } from '../../types.tsx';

export const loadProjectAccessLevel =
	(): Action<State, Props> =>
	async ({ setState }, { isSharedView, projectId }) => {
		if (isSharedView || !projectId) {
			return;
		}

		try {
			const level = await fetchProjectAccessLevel(projectId);

			setState({
				projectAccessLevel: level?.type,
			});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			fireErrorAnalytics(
				createErrorAnalytics('polaris.view-sharing.load-project-access-level', error),
			);
		}
	};

export const setProjectAccessLevel =
	(projectAccessLevel: ProjectAccessLevelType): Action<State, Props> =>
	({ setState }) => {
		setState({
			projectAccessLevel,
		});
	};
