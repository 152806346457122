import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	createBranchMessage: {
		id: 'development-summary-branch.create-branch-dropdown.create-branch-message',
		defaultMessage: 'Create branch in {remoteAppName}',
		description:
			"The cta users click to create a branch in an external SCM tool. 'Branch' is a technical term for the tool Git, and is most often not translated.",
	},
	branchTargetsGroupTitle: {
		id: 'development-summary-branch.create-branch-dropdown.branch-targets-group-title',
		defaultMessage: 'Source code integration',
		description: 'The title grouping all of the links to create a branch in an external tool',
	},
	gitCommandGroupTitle: {
		id: 'development-summary-branch.create-branch-dropdown.git-command-group-title',
		defaultMessage: 'Git create & checkout a new branch',
		description:
			"The title of a dropdown section where a git command is. 'Git' is a technical term, and is most often not translated.",
	},
	configurableBranchCreateButtonLabel: {
		id: 'development-summary-branch.create-branch-dropdown.configurable-branch-create-button-label',
		defaultMessage: 'Update branch name format',
		description:
			"The label of the configurable branch create button. 'branch' is a technical term for the tool Git, and is most often not translated.",
	},
});
