import { SmartLinkSize, SmartLinkTheme } from '@atlaskit/smart-card';

const uiOptions = {
	size: SmartLinkSize.Medium,
	hidePadding: true,
	hideElevation: true,
	theme: SmartLinkTheme.Black,
	clickableContainer: true,
	hideBackground: true,
} as const;

export default uiOptions;
