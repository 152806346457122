/**
 * @generated SignedSource<<1ff25944f8c73ffcf750eb7d80e69726>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutNumberField_IssueViewNumberField$data = {
  readonly isStoryPointField: boolean | null | undefined;
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutNumberFieldDefault_IssueViewNumberFieldDefault" | "ui_issueViewLayoutStoryPointEstimateField_IssueViewStoryPointEstimateField">;
  readonly " $fragmentType": "ui_issueViewLayoutNumberField_IssueViewNumberField";
};
export type ui_issueViewLayoutNumberField_IssueViewNumberField$key = {
  readonly " $data"?: ui_issueViewLayoutNumberField_IssueViewNumberField$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutNumberField_IssueViewNumberField">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutNumberField_IssueViewNumberField",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "ui_issueViewLayoutNumberFieldDefault_IssueViewNumberFieldDefault"
    },
    {
      "kind": "FragmentSpread",
      "name": "ui_issueViewLayoutStoryPointEstimateField_IssueViewStoryPointEstimateField"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "ScalarField",
      "name": "isStoryPointField"
    }
  ],
  "type": "JiraNumberField"
};

(node as any).hash = "39114bb636bda9d9d72afbf31857affc";

export default node;
