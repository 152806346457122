import { Component, type ReactElement } from 'react';
import { type UIAnalyticsEvent, withAnalyticsEvents } from '@atlaskit/analytics-next';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { fireOperationalAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { BaseUrl, IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { ViewWorkflowData } from './types.tsx';
import { getViewWorkflowData } from './utils.tsx';

type State = {
	data: ViewWorkflowData | undefined;
	error: Error | undefined;
	loading: boolean;
};

type ServiceAPI = State & {
	retry: () => Promise<void>;
};

export type Props = {
	issueKey: IssueKey;
	shouldLoadOnMount?: boolean;
	baseUrl: BaseUrl;
	children: (arg1: ServiceAPI) => ReactElement;
};

type InternalProps = Props & {
	createAnalyticsEvent: (arg1: { action: string }) => UIAnalyticsEvent;
};

// eslint-disable-next-line jira/react/no-class-components
class ClassicViewWorkflowService extends Component<InternalProps, State> {
	static defaultProps = {
		shouldLoadOnMount: true,
	};

	state = {
		data: undefined,
		error: undefined,
		loading: false,
	};

	componentDidMount() {
		if (this.props.shouldLoadOnMount === true) {
			this.load();
		}
	}

	onLoading() {
		this.setState({
			data: undefined,
			error: undefined,
			loading: true,
		});
	}

	onLoadSucceeded(data: ViewWorkflowData) {
		this.setState({
			data,
			error: undefined,
			loading: false,
		});
	}

	onLoadFailed(error: Error) {
		this.setState({
			data: undefined,
			error,
			loading: false,
		});
	}

	fireAnalytics() {
		const analyticsEvent = this.props.createAnalyticsEvent({ action: 'requested' });
		fireOperationalAnalytics(analyticsEvent, 'classicViewWorkflowData requested');
	}

	load = async () => {
		this.onLoading();

		const { baseUrl, issueKey } = this.props;
		try {
			this.fireAnalytics();
			const data = await getViewWorkflowData(baseUrl, issueKey);
			this.onLoadSucceeded(data);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			log.safeErrorWithoutCustomerData(
				'issue-field.status.classic-view-workflow-service.fetch',
				'Failed to fetch classic view workflow data.',
				{
					errorReason: error.message && error.message === 'TransformError' ? 'transform' : 'fetch',
				},
			);
			this.onLoadFailed(error);
		}
	};

	render() {
		return this.props.children({
			...this.state,
			retry: this.load,
		});
	}
}

export const ClassicViewWorkflowServiceWithAnalytics = withAnalyticsEvents()(
	// @ts-expect-error - TS2345 - Argument of type 'typeof ClassicViewWorkflowService' is not assignable to parameter of type '(((props: WithAnalyticsEventsProps) => ReactElement<any, string | ((props: any) => ReactElement<any, any> | null) | (new (props: any) => Component<any, any, any>)> | null) & typeof ClassicViewWorkflowService) | ((new (props: WithAnalyticsEventsProps) => Component<...>) & typeof ClassicViewWorkflowService)'.
	ClassicViewWorkflowService,
);
