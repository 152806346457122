/**
 * @generated SignedSource<<627fe55cd027a11ebd9af0ed29ba0e3c>>
 * @relayHash b0eb9873044d9ccba19314fe68e9bb1a
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 8342e2ea0ff3cd037923d335fe38968b82cafad942e8e2eafb1a491ed32effc8

import type { ConcreteRequest, Query } from 'relay-runtime';
export type servicesDeploymentDataDetectedQuery$variables = {
  projectAri: string;
  updatedFrom?: AGG$DateTime | null | undefined;
};
export type servicesDeploymentDataDetectedQuery$data = {
  readonly devOps: {
    readonly ariGraph: {
      readonly deploymentRelationships: {
        readonly nodes: ReadonlyArray<{
          readonly __typename: "AriGraphRelationship";
        } | null | undefined> | null | undefined;
      } | null | undefined;
    } | null | undefined;
  };
};
export type servicesDeploymentDataDetectedQuery = {
  response: servicesDeploymentDataDetectedQuery$data;
  variables: servicesDeploymentDataDetectedQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectAri"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "updatedFrom"
  }
],
v1 = {
  "concreteType": "DevOps",
  "kind": "LinkedField",
  "name": "devOps",
  "plural": false,
  "selections": [
    {
      "concreteType": "AriGraph",
      "kind": "LinkedField",
      "name": "ariGraph",
      "plural": false,
      "selections": [
        {
          "alias": "deploymentRelationships",
          "args": [
            {
              "fields": [
                {
                  "kind": "Variable",
                  "name": "from",
                  "variableName": "projectAri"
                },
                {
                  "kind": "Literal",
                  "name": "type",
                  "value": "project-associated-deployment"
                },
                {
                  "kind": "Variable",
                  "name": "updatedFrom",
                  "variableName": "updatedFrom"
                }
              ],
              "kind": "ObjectValue",
              "name": "filter"
            },
            {
              "kind": "Literal",
              "name": "first",
              "value": 1
            }
          ],
          "concreteType": "AriGraphRelationshipConnection",
          "kind": "LinkedField",
          "name": "relationships",
          "plural": false,
          "selections": [
            {
              "concreteType": "AriGraphRelationship",
              "kind": "LinkedField",
              "name": "nodes",
              "plural": true,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "__typename"
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "servicesDeploymentDataDetectedQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": (v1/*: any*/),
        "action": "THROW",
        "path": "devOps"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "servicesDeploymentDataDetectedQuery",
    "selections": [
      (v1/*: any*/)
    ]
  },
  "params": {
    "id": "8342e2ea0ff3cd037923d335fe38968b82cafad942e8e2eafb1a491ed32effc8",
    "metadata": {},
    "name": "servicesDeploymentDataDetectedQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "4abf81733e9857e06456b12e09b78a9a";

export default node;
