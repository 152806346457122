import React from 'react';
import FeedbackButton from '@atlassian/jira-issue-view-feedback-button/src/index.tsx';
import FindOutMore from './find-out-more/index.tsx';
import StartTour from './start-tour/index.tsx';

const AddToIssueDropdownGroup = (
	shouldShowStartTour: boolean,
	shouldShowFindOutMore: boolean,
	shouldShowFeedbackButton: boolean,
	feedbackButtonProps?: {
		feedbackGroupId: string;
		collectorId: string;
	},
) => {
	const renderDropdownItems = () => [
		...(shouldShowStartTour ? [<StartTour key="startTour" />] : []),
		...(shouldShowFindOutMore ? [<FindOutMore key="findOutMore" />] : []),
		...(shouldShowFeedbackButton && feedbackButtonProps
			? [
					<FeedbackButton
						key="feedback"
						isCompact={false}
						{...feedbackButtonProps}
						isMeatballButton
					/>,
				]
			: []),
	];

	return {
		name: '',
		key: 'IssueActionsDropdownGroup',
		items: renderDropdownItems(),
	};
};

export default AddToIssueDropdownGroup;
