import React from 'react';
import { ButtonItem } from '@atlaskit/menu';
import useConvertToSubtask from './hook.tsx';
import type { Props } from './types.tsx';

const ConvertToSubtaskButtonItem = ({
	onClick,
	label,
	setDropdownItemRef,
	itemKey,
	isSelected = false,
}: Props) => {
	const handleClick = useConvertToSubtask(itemKey, onClick);

	return (
		<ButtonItem
			isSelected={isSelected}
			ref={setDropdownItemRef}
			role="menuitem"
			key="convert-to-subtask"
			onClick={handleClick}
		>
			{label}
		</ButtonItem>
	);
};

export default ConvertToSubtaskButtonItem;
