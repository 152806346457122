import type { IncidentManagementField } from './types.tsx';

export const shouldLinkAlertsItemRender = (
	incidentManagementField?: IncidentManagementField,
): boolean => {
	if (!incidentManagementField) {
		return false;
	}
	return incidentManagementField.alertLinking.enabled;
};
