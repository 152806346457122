import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { Href } from '@atlassian/jira-shared-types/src/general.tsx';

// APPLINK_AUTHENTICATION_REQUEST
export const APPLINK_AUTHENTICATION_REQUEST = 'APPLINK_AUTHENTICATION_REQUEST' as const;

export const applinkAuthenticationRequest = (href: Href, analyticsEvent?: UIAnalyticsEvent) => ({
	type: APPLINK_AUTHENTICATION_REQUEST,
	payload: href,
	meta: { analyticsEvent },
});

export type ApplinkAuthenticationRequest = ReturnType<typeof applinkAuthenticationRequest>;

// APPLINK_AUTHENTICATION_SUCCESS
export const APPLINK_AUTHENTICATION_SUCCESS = 'APPLINK_AUTHENTICATION_SUCCESS' as const;

export const applinkAuthenticationSuccess = () => ({
	type: APPLINK_AUTHENTICATION_SUCCESS,
});

export type ApplinkAuthenticationSuccess = ReturnType<typeof applinkAuthenticationSuccess>;

export type ApplinkAuthenticationAction =
	| ApplinkAuthenticationRequest
	| ApplinkAuthenticationSuccess;
