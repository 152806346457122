import { createSessionStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/session-storage/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';

export interface SessionStorageData {
	accessStatus: string;
}

const createTTLTimestamp = (ttl: number) => Date.now() + ttl;

const storage = createSessionStorageProvider('create-confluence-content');

export const getSessionStorageData = (key: string) => storage.get(key);
export const setSessionStorageData = ({
	key,
	value,
	ttl,
}: {
	ttl: number;
	key: string;
	value: SessionStorageData;
}) => {
	const expiresAt = createTTLTimestamp(ttl);
	storage.set(key, { ...value, expiresAt });
};

export const isStorageKeyExpired = (key: string) => {
	const expiresAt = getSessionStorageData(key)?.expiresAt;
	// if no storage key exists, that means the one was never set and thus we can consider it expired
	return expiresAt ? new Date().getTime() >= expiresAt : true;
};

export const getLocalStorageData = (cachedRequestId: string) =>
	JSON.parse(localStorage?.getItem(cachedRequestId) ?? '""');

export const setLocalStorageData = (cachedRequestId: string, responseData: Object) => {
	const currentTime = new Date().getTime();
	try {
		localStorage.setItem(
			cachedRequestId,
			JSON.stringify({ ...responseData, storedAt: currentTime }),
		);
	} catch (error) {
		// localStorage.setItem might fail if the storage is full
		fireErrorAnalytics({
			meta: {
				id: 'setLocalStorageData',
				packageName: 'jiraIssueCreateConfluenceContent',
			},
			error: error instanceof Error ? error : undefined,
		});
	}
};

export const isCacheExpired = (cachedRequestId: string, invalidAt: number) => {
	const { storedAt } = getLocalStorageData(cachedRequestId);
	if (!storedAt) {
		return true;
	}
	const currentTime = new Date().getTime();
	const timeDifference = currentTime - storedAt;
	return storedAt && timeDifference > invalidAt;
};
