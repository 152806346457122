import React from 'react';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type StakeholderUpdatesModalContainer from './index.tsx';
import type { Props } from './types.tsx';

const SendStakeholderUpdatesModalLazy = lazyForPaint<typeof StakeholderUpdatesModalContainer>(
	() => import(/* webpackChunkName: "async-send-stakeholder-updates-modal" */ './index'),
);

export const AsyncSendStakeholderUpdatesModal = ({ onCloseUpdateStakeholderModal }: Props) => (
	<Placeholder name="send-stakeholder-updates-modal" fallback={null}>
		<SendStakeholderUpdatesModalLazy
			onCloseUpdateStakeholderModal={onCloseUpdateStakeholderModal}
		/>
	</Placeholder>
);
