import { createSelector } from '@atlassian/react-sweet-state';
import type { ViewType } from '../../../../common/types/context.tsx';
import type { FormDataPublic } from '../../../../common/types/fields/form-field-data.tsx';
import type { ScreenTabsDataPublic } from '../../../../common/types/screen-tabs.tsx';
import { isScreenTabsSupportEnabled } from '../../../view-configuration/index.tsx';
import type { IssueAdjustmentsState } from '../../types.tsx';
import type { ReadyPublicDataForOnInit } from './types.tsx';

export const selectReadyFieldsOld = createSelector<
	IssueAdjustmentsState,
	undefined,
	FormDataPublic | null,
	Readonly<FormDataPublic | null>,
	number,
	boolean
>(
	(state) => state.formData,
	(state) => state.numberOfSupportedFields,
	(state) => state.experienceDataIsLoading,
	(formData, numberOfSupportedFields, experienceDataIsLoading) => {
		if (
			formData !== null &&
			numberOfSupportedFields !== 0 &&
			experienceDataIsLoading === false &&
			Object.keys(formData).length === numberOfSupportedFields
		) {
			return formData;
		}

		return null;
	},
);

export const selectReadyPublicDataForOnInit = createSelector<
	IssueAdjustmentsState,
	{ viewType: ViewType },
	ReadyPublicDataForOnInit | null,
	ViewType,
	Readonly<FormDataPublic | null>,
	number,
	ScreenTabsDataPublic | null,
	boolean
>(
	(_, props) => props.viewType,
	(state) => state.formData,
	(state) => state.numberOfSupportedFields,
	(state) => state.screenTabs,
	(state) => state.experienceDataIsLoading,
	(viewType, formData, numberOfSupportedFields, screenTabsData, experienceDataIsLoading) => {
		if (
			formData !== null &&
			!isScreenTabsSupportEnabled(viewType) &&
			numberOfSupportedFields !== 0 &&
			experienceDataIsLoading === false &&
			Object.keys(formData).length === numberOfSupportedFields
		) {
			return {
				formData,
				screenTabsData: [],
			};
		}

		if (
			formData !== null &&
			screenTabsData !== null &&
			isScreenTabsSupportEnabled(viewType) &&
			numberOfSupportedFields !== 0 &&
			experienceDataIsLoading === false &&
			Object.keys(formData).length === numberOfSupportedFields
		) {
			return {
				formData,
				screenTabsData,
			};
		}

		return null;
	},
);
