import { useMemo } from 'react';
import { useCommentActivityStoreActions } from './context.tsx';
import type { CommentsActivityActions } from './types.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { useContextState, useEditorState, useReactionsState } from './context';

export const useCommentActivityActions = () => {
	const [, actions] = useCommentActivityStoreActions();

	const workflowActions: CommentsActivityActions = useMemo(() => {
		// @ts-expect-error - TS2304 - Cannot find name 'ContextState'.
		const setContextState = (contextState: ContextState) => {
			actions.setContextState(contextState);
		};
		// @ts-expect-error - TS2304 - Cannot find name 'EditorState'.
		const setEditorState = (editorState: EditorState) => {
			actions.setEditorState(editorState);
		};
		// @ts-expect-error - TS2304 - Cannot find name 'ReactionsState'.
		const setReactionsState = (reactionsState: ReactionsState) => {
			actions.setReactionsState(reactionsState);
		};

		return {
			setContextState,
			setEditorState,
			setReactionsState,
		};
	}, [actions]);

	return [null, workflowActions] as const;
};
