/**
 * @generated SignedSource<<a1c9f0ca0f32a1a7d832e77365f43e75>>
 * @relayHash 0df4748cd7c393765978be5843c7a913
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID a6f93e860194a5c2419c4911409b28d0ab9e3c6da5d722ecc79fae56d217265b

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { srcAsyncInlineCreateStatusQuery } from './srcAsyncInlineCreateStatusQuery.graphql';

const node: PreloadableConcreteRequest<srcAsyncInlineCreateStatusQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "a6f93e860194a5c2419c4911409b28d0ab9e3c6da5d722ecc79fae56d217265b",
    "metadata": {},
    "name": "srcAsyncInlineCreateStatusQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
