/**
 * @generated SignedSource<<cbebd2804d87fedf20d02b7582cea9b0>>
 * @relayHash dc45be69854514b597b316335bda1a0e
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 2d5a890b5bd66056f09c1d2415b7cd96288a1643a91883b5588d47725ebb32b2

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type issueParentSwitcher_issueViewFoundation_IssueParentSwitcherWithRelay_Query$variables = {
  parentFieldId: string;
};
export type issueParentSwitcher_issueViewFoundation_IssueParentSwitcherWithRelay_Query$data = {
  readonly node: {
    readonly parentCandidatesForExistingIssue?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly " $fragmentSpreads": FragmentRefs<"issueParentLegacyAndAgg_inlineData">;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type issueParentSwitcher_issueViewFoundation_IssueParentSwitcherWithRelay_Query = {
  response: issueParentSwitcher_issueViewFoundation_IssueParentSwitcherWithRelay_Query$data;
  variables: issueParentSwitcher_issueViewFoundation_IssueParentSwitcherWithRelay_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "parentFieldId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "parentFieldId"
  }
],
v2 = [
  {
    "kind": "Literal",
    "name": "excludeDone",
    "value": true
  },
  {
    "kind": "Literal",
    "name": "searchBy",
    "value": ""
  }
],
v3 = {
  "kind": "ScalarField",
  "name": "id"
},
v4 = {
  "kind": "ScalarField",
  "name": "issueId"
},
v5 = {
  "kind": "ScalarField",
  "name": "key"
},
v6 = {
  "kind": "ScalarField",
  "name": "webUrl"
},
v7 = {
  "concreteType": "JiraSingleLineTextField",
  "kind": "LinkedField",
  "name": "summaryField",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "kind": "ScalarField",
      "name": "text"
    }
  ]
},
v8 = {
  "kind": "ScalarField",
  "name": "statusCategoryId"
},
v9 = {
  "concreteType": "JiraColorField",
  "kind": "LinkedField",
  "name": "issueColorField",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "concreteType": "JiraColor",
      "kind": "LinkedField",
      "name": "color",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        {
          "kind": "ScalarField",
          "name": "colorKey"
        }
      ]
    }
  ]
},
v10 = {
  "concreteType": "JiraIssueTypeField",
  "kind": "LinkedField",
  "name": "issueTypeField",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "concreteType": "JiraIssueType",
      "kind": "LinkedField",
      "name": "issueType",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        {
          "kind": "ScalarField",
          "name": "description"
        },
        {
          "kind": "ScalarField",
          "name": "issueTypeId"
        },
        {
          "kind": "ScalarField",
          "name": "name"
        },
        {
          "concreteType": "JiraIssueTypeHierarchyLevel",
          "kind": "LinkedField",
          "name": "hierarchy",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "level"
            }
          ]
        },
        {
          "concreteType": "JiraAvatar",
          "kind": "LinkedField",
          "name": "avatar",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "medium"
            },
            {
              "kind": "ScalarField",
              "name": "xsmall"
            }
          ]
        }
      ]
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "issueParentSwitcher_issueViewFoundation_IssueParentSwitcherWithRelay_Query",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": (v2/*: any*/),
                "concreteType": "JiraIssueConnection",
                "kind": "LinkedField",
                "name": "parentCandidatesForExistingIssue",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraIssueEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "JiraIssue",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "InlineDataFragmentSpread",
                            "name": "issueParentLegacyAndAgg_inlineData",
                            "selections": [
                              (v3/*: any*/),
                              (v4/*: any*/),
                              (v5/*: any*/),
                              (v6/*: any*/),
                              (v7/*: any*/),
                              {
                                "concreteType": "JiraStatusField",
                                "kind": "LinkedField",
                                "name": "statusField",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraStatus",
                                    "kind": "LinkedField",
                                    "name": "status",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "concreteType": "JiraStatusCategory",
                                        "kind": "LinkedField",
                                        "name": "statusCategory",
                                        "plural": false,
                                        "selections": [
                                          (v8/*: any*/)
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              },
                              (v9/*: any*/),
                              (v10/*: any*/)
                            ],
                            "argumentDefinitions": []
                          }
                        ]
                      }
                    ]
                  }
                ],
                "storageKey": "parentCandidatesForExistingIssue(excludeDone:true,searchBy:\"\")"
              }
            ],
            "type": "JiraParentIssueField"
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "issueParentSwitcher_issueViewFoundation_IssueParentSwitcherWithRelay_Query",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "name": "__typename"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": (v2/*: any*/),
                "concreteType": "JiraIssueConnection",
                "kind": "LinkedField",
                "name": "parentCandidatesForExistingIssue",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraIssueEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "JiraIssue",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          {
                            "concreteType": "JiraStatusField",
                            "kind": "LinkedField",
                            "name": "statusField",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraStatus",
                                "kind": "LinkedField",
                                "name": "status",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraStatusCategory",
                                    "kind": "LinkedField",
                                    "name": "statusCategory",
                                    "plural": false,
                                    "selections": [
                                      (v8/*: any*/),
                                      (v3/*: any*/)
                                    ]
                                  },
                                  (v3/*: any*/)
                                ]
                              },
                              (v3/*: any*/)
                            ]
                          },
                          (v9/*: any*/),
                          (v10/*: any*/)
                        ]
                      }
                    ]
                  }
                ],
                "storageKey": "parentCandidatesForExistingIssue(excludeDone:true,searchBy:\"\")"
              }
            ],
            "type": "JiraParentIssueField"
          },
          (v3/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "id": "2d5a890b5bd66056f09c1d2415b7cd96288a1643a91883b5588d47725ebb32b2",
    "metadata": {},
    "name": "issueParentSwitcher_issueViewFoundation_IssueParentSwitcherWithRelay_Query",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "4f831a342a2b46e0841fcae9db04b727";

export default node;
