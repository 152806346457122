import head from 'lodash/head';
import type { FieldKey, FieldMap } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types.tsx';

export const updateFieldOptionAutoFormattingDisabled =
	(fieldKey: FieldKey, newValue: boolean) =>
	async (
		{ getState, setState }: StoreActionApi<State>,
		{ onFieldUpdateFailed, projectId, fieldRemote, issueTypeIds }: Props,
	) => {
		const state = getState();
		const field = state.fields[fieldKey];
		const issueTypeId = head(issueTypeIds);
		const oldValue = field.configuration?.optionAutoFormatDisabled;

		if (projectId === undefined || issueTypeId === undefined) {
			return;
		}

		const fields: FieldMap = {
			...state.fields,
			[fieldKey]: {
				...field,
				configuration: {
					...field.configuration,
					optionAutoFormatDisabled: newValue,
				},
			},
		};

		setState({ fields });

		return fieldRemote
			.updateFieldConfiguration({
				fieldKey,
				issueTypeId,
				oldConfiguration: oldValue !== undefined ? { optionAutoFormatDisabled: oldValue } : {},
				configuration: newValue !== undefined ? { optionAutoFormatDisabled: newValue } : {},
				type: 'updateFieldOptionAutoFormattingDisabled',
			})
			.catch((err) => {
				const currentState = getState();
				const currentFieldsData: FieldMap = {
					...currentState.fields,
					[fieldKey]: {
						...currentState.fields[fieldKey],
						configuration: {
							...currentState.fields[fieldKey].configuration,
							optionAutoFormatDisabled: oldValue,
						},
					},
				};

				setState({ fields: currentFieldsData });

				onFieldUpdateFailed(err);
			});
	};
