import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const asyncTargetEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() =>
			import(/* webpackChunkName: "async-highlight-actions-popup-target-entrypoint" */ './async'),
	),
	getPreloadProps: () => ({}),
});
