// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	easyMentionsPlaceholder: {
		id: 'issue.easy-mentions-placeholder-for-empty-comment-editor',
		defaultMessage: 'Type @ to mention and notify someone.',
		description: 'Placeholder when there is no draft for a new comment',
	},
	cannedResponsesPlaceholder: {
		id: 'issue.canned-responses-placeholder-for-empty-comment-editor',
		defaultMessage: 'Type /canned to create or insert predefined responses.',
		description:
			'Placeholder when canned responses are enabled and there is no draft for a new comment',
	},
	aiUpsellPlaceholder: {
		id: 'issue.ai-upsell-placeholder-for-empty-comment-editor',
		defaultMessage:
			'Type /ai for Atlassian Intelligence, / to add elements, or @ to mention someone.',
		description:
			'The placeholder to tell the user that AI is available as an upgrade, part of the AI in Editor experiment',
	},
	placeholder: {
		id: 'issue.placeholder-for-empty-comment-editor',
		defaultMessage: 'Add a comment…',
		description: 'Placeholder when there is no draft for a new comment',
	},
	draftPlaceholder: {
		id: 'issue.draft-placeholder-for-empty-comment-editor',
		defaultMessage: 'You have an unsaved comment',
		description: 'Placeholder when there is a draft for a new comment',
	},
	commentShortcutHintHeading: {
		id: 'issue.comment.shortcut-hint-pro-tip-text',
		defaultMessage: 'Pro tip:',
		description: 'Shortcut heading in bold for comment hint',
	},
	commentShortcutHintMessage: {
		id: 'issue.comment.shortcut-hint-message-with-pro-tip-text',
		defaultMessage: '{proTipText} press {shortcutKey} to comment',
		description:
			'Shortcut hint for comment on issue view, {shortcutKey} is the key to press for the shortcut passed as a react component',
	},
	userAvatarAriaLabel: {
		id: 'issue.comment.user-avatar-aria-label',
		defaultMessage: 'Profile image of {displayName}',
		description: 'Text to be used as user avatar image aria-label',
	},
	comment: {
		id: 'issue-view.comment.comment-add.comment',
		defaultMessage: 'Comment',
		description: 'Editor assisitive label for comment section',
	},
});
