import type { Dispatch } from 'redux';
import memoizeOne from 'memoize-one';
import { genericMessages } from '@atlassian/jira-issue-view-common-constants/src/context-items-messages.tsx';
import { PREVIEW } from '@atlassian/jira-issue-view-common-types/src/loading-stage-type.tsx';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button/index.tsx';
import connectField from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-field.tsx';
import {
	issueKeySelector,
	issueIdSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import { isFieldEditableSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector.tsx';
import { loadingStageSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';
import { sweetStateFieldUpdated } from '@atlassian/jira-issue-view-store/src/issue-field/state/actions/field-save-actions.tsx';
import getXsrfToken from '@atlassian/jira-platform-xsrf-token/src/index.tsx';
import { View } from './view.tsx';

const onUpdateMemoized = memoizeOne(
	(dispatch, fieldId) =>
		// @ts-expect-error - TS7006 - Parameter 'value' implicitly has an 'any' type.
		(value) =>
			dispatch(sweetStateFieldUpdated(fieldId, value)),
);

export const StoryPointEstimate = connectField((stateOnMount, ownPropsOnMount) => ({
	fieldId: ownPropsOnMount.fieldId,
	additionalProps: (state, intl) => ({
		issueKey: issueKeySelector(state),
		issueId: issueIdSelector(state),
		noValueText: intl.formatMessage(genericMessages.noValue),
		isEditable:
			isFieldEditableSelector(ownPropsOnMount.fieldId)(state) &&
			loadingStageSelector(state) !== PREVIEW &&
			!!getXsrfToken(),
		showPinButton: getShowPinButton(ownPropsOnMount.area),
	}),
	additionalCallbacks: (dispatch: Dispatch) => ({
		onUpdate: onUpdateMemoized(dispatch, ownPropsOnMount.fieldId),
	}),
}))(View);

export default StoryPointEstimate;
