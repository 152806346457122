import React, { memo, useMemo, useState, type ReactNode } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import BitbucketPullRequestsIcon from '@atlaskit/icon/glyph/bitbucket/pullrequests';
import Lozenge, { type ThemeAppearance } from '@atlaskit/lozenge';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { ISSUE } from '@atlassian/jira-development-board-common/src/common/constants.tsx';
import { DevInfoTypes } from '@atlassian/jira-development-board-common/src/types.tsx';
import { AsyncDevInfoIcon } from '@atlassian/jira-development-board-dev-info-icon/src/async.tsx';
import DevInfoIcon from '@atlassian/jira-development-board-dev-info-icon/src/main.tsx';
import { SummaryItem } from '@atlassian/jira-development-summary-common/src/ui/summary-item/index.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { FormattedI18nMessage } from '@atlassian/jira-formatted-i18n-message/src/ui/index.tsx';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { CreatePRIconButton } from './create-pull-request-summary-item/create-pr-icon-button/index.tsx';
import { CreatePullRequestDropdown } from './create-pull-request-summary-item/create-pull-request-dropdown/index.tsx';
import { CreatePullRequestSummaryItem } from './create-pull-request-summary-item/index.tsx';
import messages from './messages.tsx';
import type { PullRequest as PullRequestType, PullRequestState } from './types.tsx';

const statusCategoryToAppearenceMap: Record<PullRequestState, ThemeAppearance> = {
	OPEN: 'inprogress',
	DRAFT: 'inprogress',
	MERGED: 'success',
	DECLINED: 'removed',
};

type Props = {
	hasErrors: boolean;
	branchesCount: number;
	pullRequest: PullRequestType | null | undefined;
	onClick?: () => void;
	issueId: string;
	issueKey: string;
};

export const areEqual = (previousProps: Props, nextProps: Props): boolean =>
	nextProps.pullRequest?.count === previousProps.pullRequest?.count &&
	nextProps.pullRequest?.lastUpdated === previousProps.pullRequest?.lastUpdated &&
	nextProps.pullRequest?.stateCount === previousProps.pullRequest?.stateCount &&
	nextProps.pullRequest?.state === previousProps.pullRequest?.state &&
	nextProps.pullRequest?.url === previousProps.pullRequest?.url &&
	nextProps.pullRequest?.createPullRequestTargets?.length ===
		previousProps.pullRequest?.createPullRequestTargets?.length;

const PullRequestOld = memo<Props>(
	({ onClick, branchesCount, pullRequest, hasErrors, issueId, issueKey }: Props) => {
		const { formatNumber, formatMessage } = useIntl();
		const { count: pullRequestsCount = 0, stateCount = 0, state } = pullRequest ?? {};
		const [shouldForceVisibleSecondary, setShouldForceVisibleSecondary] = useState(false);

		const title = useMemo(
			() => (
				<FormattedI18nMessage
					componentsMapping={{
						bold: BoldFont,
					}}
					message={formatMessage(messages.title, {
						count: formatNumber(stateCount),
						boldStart: '{boldStart}',
						boldEnd: '{boldEnd}',
					})}
				/>
			),
			[formatMessage, formatNumber, stateCount],
		);

		const secondaryData = useMemo(
			() =>
				state ? (
					<Lozenge appearance={statusCategoryToAppearenceMap[state]}>{state}</Lozenge>
				) : undefined,
			[state],
		);

		const summaryItemComponent = (
			pullRequestInput: PullRequestType,
			targetsLength: number,
			href: string | undefined,
		) => (
			<SummaryItem
				title={title}
				secondaryData={secondaryData}
				onClick={onClick}
				itemType="pullrequest"
				itemCount={pullRequestInput.stateCount}
				oneClickUrl={pullRequestInput.url}
				oneClickToolTipContent={formatMessage(messages.tooltip)}
				data-testid="development-summary-pull-request.ui.summary-item"
				secondaryHover={
					targetsLength > 0 || hasErrors ? (
						<CreatePullRequestDropdown
							hasErrors={hasErrors}
							onAuthErrorClick={onClick}
							createPullRequestTargets={pullRequestInput.createPullRequestTargets}
							renderDropdownTrigger={(props) => <CreatePRIconButton {...props} href={href} />}
							onOpenDropdown={({ isOpen }) => {
								setShouldForceVisibleSecondary(isOpen);
							}}
						/>
					) : null
				}
				forceVisibleSecondary={shouldForceVisibleSecondary}
			/>
		);

		if (pullRequest && pullRequestsCount) {
			const targetsLength = pullRequest.createPullRequestTargets?.length || 0;
			const isSingleTarget = pullRequest.createPullRequestTargets?.length === 1;
			const href =
				isSingleTarget && !hasErrors // if there are error messages in response we want to show the dropdown to show the error message.
					? pullRequest?.createPullRequestTargets?.[0]?.createPullRequestUrl
					: undefined;

			if (ff('devops-popups-on-issue-view_zikxe')) {
				if (fg('convert_devinfoicon_to_be_synchronous')) {
					return (
						<DevInfoIcon
							devInfoType={DevInfoTypes.PULLREQUEST}
							issueId={Number(issueId)}
							customTrigger={summaryItemComponent(pullRequest, targetsLength, href)}
							scopeId={`devInfoPanelApp${issueKey}`}
							placement="bottom-end"
							offset={[-8, 8]}
							zIndex={layers.modal}
							location={ISSUE}
						/>
					);
				}
				return (
					<Placeholder
						fallback={summaryItemComponent(pullRequest, targetsLength, href)}
						name="async-pr-dev-info-icon"
					>
						<AsyncDevInfoIcon
							devInfoType={DevInfoTypes.PULLREQUEST}
							issueId={Number(issueId)}
							customTrigger={summaryItemComponent(pullRequest, targetsLength, href)}
							scopeId={`devInfoPanelApp${issueKey}`}
							placement="bottom-end"
							offset={[-8, 8]}
							zIndex={layers.modal}
							location={ISSUE}
						/>
					</Placeholder>
				);
			}

			return summaryItemComponent(pullRequest, targetsLength, href);
		}

		if (pullRequest && branchesCount > 0) {
			return (
				<CreatePullRequestSummaryItem
					createPullRequestTargets={pullRequest.createPullRequestTargets}
					hasErrors={hasErrors}
					onAuthErrorClick={onClick}
					branchesCount={branchesCount}
				/>
			);
		}

		return null;
	},
	areEqual,
);

// eslint-disable-next-line jira/styled/styled-component-order, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconWrapper = styled.span({
	verticalAlign: 'middle',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const PullRequestIcon = (
	<IconWrapper aria-hidden>
		<BitbucketPullRequestsIcon size="medium" label="" />
	</IconWrapper>
);

export const PullRequestEmptyState = () => {
	const { formatMessage } = useIntl();

	const icon = PullRequestIcon;
	return (
		<SummaryItem
			icon={icon}
			disabled
			title={formatMessage(messages.emptyStateUpdate)}
			data-testid="development-summary-pull-request.ui.summary-item"
		/>
	);
};

const PullRequestNew = memo<Props>((props: Props) => (
	<UFOSegment name="development-summary-pullrequest">
		<PullRequestOld {...props} />
	</UFOSegment>
));

const PullRequest = componentWithFG(
	'jira-add-ufo-instrument-for-asyncdevinfoicon',
	PullRequestNew,
	PullRequestOld,
);

export default PullRequest;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BoldFont = styled.span<{ children: ReactNode }>({
	fontWeight: token('font.weight.medium'),
});
