import React, { useCallback, useEffect, useRef } from 'react';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import { type ThemeTokens, CustomThemeButton, type ThemeProps } from '@atlaskit/button';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';

export type Props = {
	onClick: (value: ADF) => void;
	buttonText: string;
	commentText: ADF;
	commentKey: string;
	onFocus: React.FocusEventHandler<HTMLButtonElement>;
};

const CannedComment = ({ commentKey, buttonText, commentText, onClick, onFocus }: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const timer = useRef<NodeJS.Timeout | null>(null);

	useEffect(
		() => () => {
			if (timer.current) {
				clearTimeout(timer.current);
			}
		},
		[],
	);

	const onClickWithAnalytics = useCallback(() => {
		onClick(commentText);
		// Storing the index instead of the message to avoid sending UGC data in the analytics event
		fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'cannedComment', {
			commentKey,
		});
	}, [onClick, commentText, createAnalyticsEvent, commentKey]);

	const onMouseEnterWithAnalytics = useCallback(() => {
		// Delay the analytics event to avoid sending too many events
		timer.current = setTimeout(() => {
			fireUIAnalytics(createAnalyticsEvent({}), 'button hovered', 'cannedComment', {
				commentKey,
			});
		}, 500);
	}, [commentKey, createAnalyticsEvent]);

	const onMouseLeaveWithAnalytics = useCallback(() => {
		if (timer.current) {
			clearTimeout(timer.current);
		}
	}, []);

	// We are aware this is deprecated already, we will put it in the cut corner
	// once the replacement is available we will use xcss with new button instead
	return (
		<CustomThemeButton
			theme={buttonStyle}
			appearance="default"
			spacing="compact"
			onClick={onClickWithAnalytics}
			onFocus={onFocus}
			onMouseEnter={onMouseEnterWithAnalytics}
			onMouseLeave={onMouseLeaveWithAnalytics}
		>
			{buttonText}
		</CustomThemeButton>
	);
};

export const buttonStyle = (
	current: (themeProps: ThemeProps) => ThemeTokens,
	themeProps: ThemeProps,
) => {
	const theme = current(themeProps);
	return {
		...theme,
		buttonStyles: {
			...theme.buttonStyles,
			borderRadius: '100px',
		},
	};
};

export default CannedComment;
