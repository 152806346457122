import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	title: {
		id: 'invite-prompt-on-link-insert.invite-success-flag.title',
		defaultMessage:
			'{inviteeCount, plural, one {Invite successfully sent} other {Invites successfully sent}}',
		description: 'Title of success flag shown when invites are sent successfully',
	},
	dismiss: {
		id: 'invite-prompt-on-link-insert.invite-success-flag.dismiss',
		defaultMessage: 'Ok, got it',
		description: 'Button to dismiss the flag',
	},
	descriptionGeneric: {
		id: 'invite-prompt-on-link-insert.invite-success-flag.description-generic',
		defaultMessage:
			'{inviteeCount, plural, one {We emailed <strong>{email}</strong> inviting them to {productDisplayName}.} other {We emailed <strong># people</strong> inviting them to {productDisplayName}.}}',
		description: 'Description informing the user how many invites were successfully sent',
	},
	additionalInfoGeneric: {
		id: 'invite-prompt-on-link-insert.invite-success-flag.additional-info-generic',
		defaultMessage:
			// eslint-disable-next-line @atlassian/tangerine/i18n/messages-are-spelled-correctly
			"Some people might need your admin's approval before they can join {productDisplayName}.",
		description: 'Description explaining that some invitees will still require admin approval',
	},
	descriptionGenericV5: {
		id: 'invite-prompt-on-link-insert.invite-success-flag.description-generic-v-5',
		defaultMessage:
			'{inviteeCount, plural, one {<strong># project assignee</strong> will get an email inviting them to <strong>{productDisplayName}</strong>.} other {<strong># project assignees</strong> will get an email inviting them to <strong>{productDisplayName}</strong>.}}',
		description: 'Description informing the user how many invites were successfully sent',
	},
	additionalInfoGenericV5: {
		id: 'invite-prompt-on-link-insert.invite-success-flag.additional-info-generic-v-5',
		defaultMessage:
			'Admin may need to approve some assignees before they can join <strong>{productDisplayName}</strong>.',
		description: 'Description explaining that some invitees will still require admin approval',
	},
});
