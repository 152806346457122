import { useCallback } from 'react';

import { type GasCorePayload, OPERATIONAL_EVENT_TYPE } from '@atlaskit/analytics-gas-types';
import { createAndFireEvent, useAnalyticsEvents } from '@atlaskit/analytics-next';

const ACTION_SUBJECT = 'recaptcha';

export const useAnalytics = (channel: string, source: string) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const triggerEvent = useCallback(
		<Args extends unknown[]>(eventFactory: (...args: Args) => GasCorePayload, ...args: Args) => {
			const analyticsEvent = { source, ...eventFactory(...args) };
			createAndFireEvent(channel)(analyticsEvent)(createAnalyticsEvent);
		},
		[source, channel, createAnalyticsEvent],
	);
	return triggerEvent;
};

export const recaptchaLoaded = (): GasCorePayload => ({
	eventType: OPERATIONAL_EVENT_TYPE,
	actionSubject: ACTION_SUBJECT,
	action: 'loaded',
});

export const recaptchaLoadFailed = (errorMessage: string): GasCorePayload => ({
	eventType: OPERATIONAL_EVENT_TYPE,
	actionSubject: ACTION_SUBJECT,
	actionSubjectId: 'load',
	action: 'failed',
	attributes: {
		error: errorMessage,
	},
});

export const recaptchaExecuteFailed = (errorMessage: string): GasCorePayload => ({
	eventType: OPERATIONAL_EVENT_TYPE,
	actionSubject: ACTION_SUBJECT,
	actionSubjectId: 'execute',
	action: 'failed',
	attributes: {
		error: errorMessage,
	},
});
