import React from 'react';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type MsTeamsConversation from './ui/index.tsx';
import type { Props } from './ui/msteams-conversation-field/index.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyMsTeamsConversation = lazyForPaint<typeof MsTeamsConversation>(
	() =>
		import(
			/* webpackChunkName: "async-servicedesk-incident-management-msteams-conversation" */ './ui'
		),
	{ ssr: false },
);

export const AsyncMsTeamsConversationField = (props: Props) => (
	<Placeholder name="servicedesk-incident-management-msteams-conversation" fallback={null}>
		<LazyMsTeamsConversation {...props} />
	</Placeholder>
);
