/**
 * @generated SignedSource<<116a398c8d7d1cf082e761ff7dba9f49>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutSingleLineTextMessageField_IssueViewSingleLineTextMessageField$data = {
  readonly __typename: "JiraSingleLineTextField";
  readonly description: string | null | undefined;
  readonly fieldId: string;
  readonly name: string;
  readonly text: string | null | undefined;
  readonly " $fragmentType": "ui_issueViewLayoutSingleLineTextMessageField_IssueViewSingleLineTextMessageField";
};
export type ui_issueViewLayoutSingleLineTextMessageField_IssueViewSingleLineTextMessageField$key = {
  readonly " $data"?: ui_issueViewLayoutSingleLineTextMessageField_IssueViewSingleLineTextMessageField$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutSingleLineTextMessageField_IssueViewSingleLineTextMessageField">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutSingleLineTextMessageField_IssueViewSingleLineTextMessageField",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__typename"
    },
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "description"
    },
    {
      "kind": "ScalarField",
      "name": "text"
    }
  ],
  "type": "JiraSingleLineTextField"
};

(node as any).hash = "bca0723e101c01b94b3177763bbe08e5";

export default node;
