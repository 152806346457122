import React from 'react';
import { FormattedMessage } from '@atlassian/jira-intl';
import type { MultiSelectValueItemShape } from '@atlassian/jira-issue-internal-field-select/src/multi-select-inline-edit/index.tsx';

/*
 * This number is a maximum allowed label length in Jira.
 * It is also hardcoded on backend:
 * - https://stash.atlassian.com/projects/JIRACLOUD/repos/jira/browse/jira-components/jira-api/src/main/java/com/atlassian/jira/issue/label/LabelParser.java
 */
export const MAX_LABEL_SIZE = 255;

/*
 * This number is the maximum number of labels that can be added to a field in Jira.
 * This limit is frontend-only; it is not enforced on backend at all.
 * https://donut-world-dogfood.atlassian.net/browse/MCD-483
 *
 * This same number is also hardcoded in src/packages/project-settings/issue-type-page/src/state/ui/fields/selectors.js
 */
export const MAX_ALLOWED_LABELS = 55;

export const validate = (values: MultiSelectValueItemShape[]) => {
	if (values.length > MAX_ALLOWED_LABELS) {
		return (
			<FormattedMessage
				id="issue.labels-view.input-too-many-labels"
				defaultMessage="Can't have more than {max} labels."
				values={{ max: MAX_ALLOWED_LABELS }}
			/>
		);
	}

	const isInvalid = values
		.map((value) => value.content.trim())
		.some((content) => content.includes(' ') || content.length > MAX_LABEL_SIZE);

	return isInvalid ? (
		<FormattedMessage
			id="issue.labels-view.input-label-is-invalid"
			defaultMessage="Labels can't have spaces or be more than {max} characters."
			values={{ max: MAX_LABEL_SIZE }}
		/>
	) : undefined;
};
