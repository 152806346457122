import {
	CORE_PROJECT,
	PRODUCT_DISCOVERY_PROJECT,
	SERVICE_DESK_PROJECT,
	CUSTOMER_SERVICE_PROJECT,
	SOFTWARE_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';

const CHILD_ISSUES_LIMIT = 500;

export const getChildIssuesLimit = () => CHILD_ISSUES_LIMIT;

export const EXCEEDED_LIMIT_INITIAL_LOAD = 'EXCEEDED_LIMIT_INITIAL_LOAD' as const;
export const EXCEEDED_LIMIT_AFTER_LOAD = 'EXCEEDED_LIMIT_AFTER_LOAD' as const;
export const WITHIN_LIMIT = 'WITHIN_LIMIT' as const;

export type ChildIssuesLimitStatus =
	| typeof EXCEEDED_LIMIT_AFTER_LOAD
	| typeof EXCEEDED_LIMIT_INITIAL_LOAD
	| typeof WITHIN_LIMIT;

export const HREF = 'HREF' as const;
export const IN_PRODUCT_HELP = 'IN_PRODUCT_HELP' as const;

export const WHAT_ARE_ISSUE_TYPES_URL =
	'https://support.atlassian.com/jira-cloud-administration/docs/what-are-issue-types/' as const;

export const IN_PRODUCT_HELP_ARTICLE_IDS = {
	[CORE_PROJECT]: '2uhc4ieBPinkLJlWbdhXkC',
	[SOFTWARE_PROJECT]: 'z6BNb3cPucF3fi5UA85Ma',
	[SERVICE_DESK_PROJECT]: '6feRxZzkYUHez8TwHTjSut',
	[CUSTOMER_SERVICE_PROJECT]: null,
	[PRODUCT_DISCOVERY_PROJECT]: null,
} as const;

export const DISMISSED = 'dismissed' as const;

export const DEFAULT_ASSIGNEE_PERMISSION_ERROR_MESSAGE =
	'The default assignee does NOT have ASSIGNABLE permission OR Unassigned issues are turned off.' as const;

export const DEFAULT_ASSIGNEE_PERMISSION_ERROR_MESSAGE_FE =
	'The default assignee doesn’t have the Assignable User permission, or unassigned issues are turned off.';

export const GIC_CALLBACK_PAYLOAD_INLINE_CREATE_ID = 'issue-view-child-issues-panel-inline-create';

export const GIC_CALLBACK_PAYLOAD_AI_BREAKDOWN_CREATE_ID =
	'issue-view-child-issues-panel-ai-breakdown-create';
