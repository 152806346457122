import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	dropdownItemActionTooltip: {
		id: 'development-summary-vulnerability.vulnerability-dropdown.vulnerability-dropdown-item.dropdown-item-action-tooltip',
		defaultMessage: 'Open vulnerability',
		description:
			'The tooltip describing the dropdown item click action for a given vulnerability associated with an issue.',
	},
});
