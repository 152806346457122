import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { graphql, useFragment, useMutation } from 'react-relay';
import uuid from 'uuid';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import type { SessionId } from '@atlassian/jira-issue-analytics/src/common/types.tsx';
import { useInlineEditFieldInjections } from '@atlassian/jira-issue-field-injections/src/controllers/inline-edit-injections-context/index.tsx';
import { useFieldInlineEditActions } from '@atlassian/jira-issue-field-inline-edit-actions/src/controllers/index.tsx';
import type { OnSubmitCallbacks } from '@atlassian/jira-issue-field-inline-edit-actions/src/controllers/types.tsx';
import type { ValidationFieldProps } from '@atlassian/jira-issue-field-inline-edit-lite/src/ui/field-inline-edit-lite/types.tsx';
import { FieldInlineEditLiteWithEntryPoint } from '@atlassian/jira-issue-field-inline-edit-lite/src/ui/index.tsx';
// importing this from the old labels field to avoid large bundle size increases, will need to move this over when deleting old labels field.
import LabelsEditViewEntryPoint from '@atlassian/jira-issue-field-labels-editview-full/src/entrypoint.tsx';
import type {
	Option,
	LabelsEditViewProps,
} from '@atlassian/jira-issue-field-labels-editview-full/src/ui/labels/types.tsx';
import { LabelsReadView } from '@atlassian/jira-issue-field-labels-readview-full/src/ui/labels/index.tsx';
import { useLabelsCache } from '@atlassian/jira-issue-field-labels/src/services/labels-cache-service/index.tsx';
import {
	type Operation,
	useMultiSelectFieldDirtyValueOperations,
} from '@atlassian/jira-issue-hooks/src/services/use-multi-select-field-dirty-value/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { labels_issueFieldLabelsInlineEditViewFull_LabelsInlineEditView$key as LabelsInlineEditViewFragment } from '@atlassian/jira-relay/src/__generated__/labels_issueFieldLabelsInlineEditViewFull_LabelsInlineEditView.graphql';
import type {
	JiraLabelsFieldOperationInput,
	labels_issueFieldLabelsInlineEditViewFull_LabelsInlineEditViewMutation as LabelsInlineEditViewMutation,
} from '@atlassian/jira-relay/src/__generated__/labels_issueFieldLabelsInlineEditViewFull_LabelsInlineEditViewMutation.graphql';
import type {
	labels_issueFieldLabelsInlineEditViewFull_LabelsInlineEditViewWithIsEditable_fragmentRef$key as LabelsInlineEditViewWithIsEditableFragment,
	labels_issueFieldLabelsInlineEditViewFull_LabelsInlineEditViewWithIsEditable_fragmentRef$data as LabelsInlineEditViewData,
} from '@atlassian/jira-relay/src/__generated__/labels_issueFieldLabelsInlineEditViewFull_LabelsInlineEditViewWithIsEditable_fragmentRef.graphql';
import type {
	LabelsInlineEditViewProps,
	LabelsInlineEditViewWithIsEditableProps,
} from './types.tsx';
import {
	getProjectScopeLabelCheckboxValueFromLocalStorage,
	setProjectScopeLabelCheckboxValueFromLocalStorage,
	useLabelsValidator,
} from './utils.tsx';

const FIELD_KEY = 'labels';
const FIELD_TYPE = 'labels';
export const FIELD_UPDATED_ACTION = 'field updated';
export const CODE_LOCATION = 'issue-field.save-value';
export const ERROR_MESSAGE = `Failed to update field ${FIELD_KEY} of type ${FIELD_TYPE}`;

const transformAggToAtlaskitOption = (
	// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
	connection: LabelsInlineEditViewData['selectedLabelsConnection'] | null | void,
): Option[] => {
	const labelValues =
		connection?.edges
			?.map((edge) => edge?.node ?? null)
			.filter(Boolean)
			.filter((label) => label && label.name != null) || [];
	const labelOptions: Option[] = labelValues.map((label) => ({
		label: label.name || '',
		value: label.name || '',
	}));
	return labelOptions;
};

const transformAtlaskitOptionToAgg = (
	value: Option[],
): LabelsInlineEditViewData['selectedLabelsConnection'] => ({
	totalCount: value.length,
	edges: value.map((label) => ({
		node: {
			name: label.value,
		},
	})),
});

const transformLabelsOptionsToLabelNames = (value: Option[]): string[] =>
	value?.map((labelOption) => labelOption.value) ?? [];

const transformOptionsOperationsIntoAggOperationInput = (
	operations: Operation<Option>[],
): JiraLabelsFieldOperationInput[] =>
	operations.map(({ operation, value }) => ({
		operation,
		labels: transformLabelsOptionsToLabelNames(value),
	}));

const isEqualLabels = (a: Option[], b: Option[]): boolean =>
	a.length === b.length &&
	a.every((option, index) => option.label === b[index].label && option.value === b[index].value);

export const LabelsInlineEditViewWithIsEditable = ({
	attributes,
	spacing = 'compact',
	baseSearchUrl,
	editViewPopup,
	editViewPopupAlignBlock,
	isTruncated,
	isEditable,
	menuPosition,
	menuPortalTarget,
	menuShouldScrollIntoView,
	onCancel,
	onEdit,
	onSubmit,
	onSubmitSucceeded: onSubmitSucceededProp,
	onSubmitFailed: onSubmitFailedProp,
	fragmentRef,
	readViewFitContainerHeight,
	shouldShowProjectScopedCheckbox,
	shouldPrefetchSuggestions = false,
}: LabelsInlineEditViewWithIsEditableProps) => {
	const [{ dirtyValue, operations }, { onChange, initializeDirtyValue }] =
		useMultiSelectFieldDirtyValueOperations<Option>();
	const sessionId = useRef<SessionId>(uuid.v4());
	const { overriding } = useInlineEditFieldInjections();
	const [currentProjectOnly, setCurrentProjectOnly] = useState(
		getProjectScopeLabelCheckboxValueFromLocalStorage,
	);

	useEffect(() => {
		fg('labels_restricted_to_project_experiment') &&
			setProjectScopeLabelCheckboxValueFromLocalStorage(currentProjectOnly);
	}, [currentProjectOnly]);

	const data = useFragment<LabelsInlineEditViewWithIsEditableFragment>(
		graphql`
			fragment labels_issueFieldLabelsInlineEditViewFull_LabelsInlineEditViewWithIsEditable_fragmentRef on JiraLabelsField {
				...labels_issueFieldLabelsReadviewFull_LabelsReadView
				# eslint-disable-next-line @atlassian/relay/must-colocate-fragment-spreads
				...labels_issueFieldLabelsEditviewFull_LabelsEditView_nodeIdFragment
				id
				name
				fieldId
				type
				selectedLabelsConnection(first: 1000) @required(action: THROW) {
					# eslint-disable-next-line @atlassian/relay/unused-fields
					totalCount
					edges {
						node {
							name
						}
					}
				}
			}
		`,
		fragmentRef,
	);

	const { id: uniqueFieldId, name: fieldNameNoOverrides, fieldId, type } = data;
	const isFieldEditable = overriding.overrideIsEditable(isEditable);
	const fieldName = overriding.overrideLabel(fieldNameNoOverrides);
	const initialValue = useMemo(
		() => transformAggToAtlaskitOption(data?.selectedLabelsConnection),
		[data?.selectedLabelsConnection],
	);

	const [cachedLabels, { addToCache }] = useLabelsCache(fieldId);

	const [commit] = useMutation<LabelsInlineEditViewMutation>(graphql`
		mutation labels_issueFieldLabelsInlineEditViewFull_LabelsInlineEditViewMutation(
			$input: JiraUpdateLabelsFieldInput!
		) @raw_response_type {
			jira {
				updateLabelsField(input: $input) {
					success
					errors {
						message
					}
					field {
						...labels_issueFieldLabelsInlineEditViewFull_LabelsInlineEditViewWithIsEditable_fragmentRef
					}
				}
			}
		}
	`);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const validator = useLabelsValidator();

	const isValueEqual = useCallback(
		(a: Option[], b: Option[]): boolean => operations.length === 0 || isEqualLabels(a, b),
		[operations.length],
	);

	const onUpdateValue = useCallback(
		(value: Option[], isInitRequest: boolean) => {
			isInitRequest ? initializeDirtyValue(value) : onChange(value);
		},
		[initializeDirtyValue, onChange],
	);

	const handleSubmit = useCallback(
		(value: Option[], { onSuccess, onFail }: OnSubmitCallbacks) => {
			const updatedValue = transformLabelsOptionsToLabelNames(value);
			addToCache(updatedValue);
			onSubmit?.(updatedValue);
			commit({
				variables: {
					input: {
						id: uniqueFieldId,
						operations: transformOptionsOperationsIntoAggOperationInput(operations),
					},
				},
				optimisticResponse: {
					jira: {
						updateLabelsField: {
							success: true,
							errors: null,
							field: {
								id: uniqueFieldId,
								name: fieldName,
								type,
								fieldId,
								selectedLabelsConnection: transformAtlaskitOptionToAgg(dirtyValue),
							},
						},
					},
				},
				onCompleted(mutationData) {
					if (mutationData?.jira?.updateLabelsField?.success) {
						onSuccess();
					} else {
						const errorMessage =
							mutationData?.jira?.updateLabelsField?.errors?.[0]?.message ?? undefined;
						onFail(undefined, errorMessage);
						log.safeErrorWithoutCustomerData(
							CODE_LOCATION,
							`Failed to update field ${fieldId} of type ${FIELD_TYPE}`,
						);
					}
				},
				onError(error: Error) {
					onFail(error, error.message);
					log.safeErrorWithoutCustomerData(
						CODE_LOCATION,
						`Failed to update field ${fieldId} of type ${FIELD_TYPE}`,
					);
				},
			});
		},
		// PLEASE CONFIRM THESE DEPENDENCIES ARE CORRECT
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			addToCache,
			onSubmit,
			commit,
			uniqueFieldId,
			operations,
			fieldName,
			type,
			fieldId,
			dirtyValue,
			createAnalyticsEvent,
		],
	);

	const handleSubmitSucceeded = (value: Option[]) => {
		// onSubmitSucceededProp expects a list of strings so we must map our updated value accordingly.
		onSubmitSucceededProp?.(transformLabelsOptionsToLabelNames(value));
	};

	const [config] = UNSAFE_noExposureExp('labels_restricted_to_project');

	const {
		handleCancel,
		handleEdit,
		handleConfirm,
		handleChange,
		hasServerValidationError,
		invalidMessage,
		isEditing,
	} = useFieldInlineEditActions({
		attributes: {
			...attributes,
			...(fg('labels_restricted_to_project_experiment')
				? {
						hasProjectScopedLabels: config.get<boolean>('showProjectFilter', false),
						currentProjectOnly,
					}
				: {}),
		},
		fieldId,
		fieldName,
		fieldType: type,
		initialValue,
		isValueEqual,
		onCancel,
		onEdit,
		onSubmit: handleSubmit,
		onSubmitSucceeded: handleSubmitSucceeded,
		onSubmitFailed: onSubmitFailedProp,
		onUpdateValue,
		updatedValue: dirtyValue,
		validator,
	});

	const renderReadView = useCallback(
		() => (
			<LabelsReadView baseSearchUrl={baseSearchUrl} fragmentRef={data} isTruncated={isTruncated} />
		),
		[baseSearchUrl, data, isTruncated],
	);

	const getEditViewProps = (fieldProps: ValidationFieldProps): LabelsEditViewProps => ({
		...fieldProps,
		autoFocus: true,
		ariaLabel: fieldName,
		spacing,
		menuPosition,
		menuPortalTarget,
		menuShouldScrollIntoView,
		onChange: handleChange,
		value: dirtyValue,
		cachedOptions: cachedLabels,
		nodeIdFragment: data,
		isInvalid: !!invalidMessage,
		sessionId: sessionId.current,
		testId: 'issue-field-labels-inline-edit-view-full.ui.labels.edit-view',
		shouldShowProjectScopedCheckbox,
		...(fg('labels_restricted_to_project_experiment')
			? { currentProjectOnly, setCurrentProjectOnly }
			: {}),
	});

	return (
		<FieldInlineEditLiteWithEntryPoint
			editViewPopup={editViewPopup}
			editViewPopupAlignBlock={editViewPopupAlignBlock}
			editViewEntryPoint={LabelsEditViewEntryPoint}
			editViewEntryPointParams={shouldPrefetchSuggestions ? { id: uniqueFieldId } : {}}
			getEditViewProps={getEditViewProps}
			fieldName={fieldName}
			invalidMessage={invalidMessage}
			isEditable={isFieldEditable}
			isEditing={isEditing}
			readViewFitContainerHeight={readViewFitContainerHeight}
			readView={renderReadView}
			onCancel={handleCancel}
			onConfirm={handleConfirm}
			onEdit={handleEdit}
			hasUnsubmittedChanges={hasServerValidationError}
		/>
	);
};

export const LabelsInlineEditView = ({ fragmentRef, ...props }: LabelsInlineEditViewProps) => {
	const data = useFragment<LabelsInlineEditViewFragment>(
		/* eslint-disable @atlassian/relay/graphql-naming */
		graphql`
			fragment labels_issueFieldLabelsInlineEditViewFull_LabelsInlineEditView on JiraLabelsField {
				...labels_issueFieldLabelsInlineEditViewFull_LabelsInlineEditViewWithIsEditable_fragmentRef
				fieldConfig {
					isEditable
				}
			}
		`,
		fragmentRef,
		/* eslint-enable @atlassian/relay/graphql-naming */
	);

	return (
		<LabelsInlineEditViewWithIsEditable
			{...props}
			fragmentRef={data}
			isEditable={data.fieldConfig?.isEditable ?? false}
		/>
	);
};
