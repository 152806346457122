import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	readOnlyIssueTitle: {
		id: 'read-only-issue-info.read-only-issue-title',
		defaultMessage: "This issue can't be edited",
		description:
			'Informs that the issue cannot be edited because the issue is read only due to the project being archived.',
	},
	readOnlyIssueLink: {
		id: 'read-only-issue-info.read-only-issue-link',
		defaultMessage: 'Go to archived projects',
		description:
			'Text used in the link to redirect the user to archived projects. This can only be showed when the user has access to archived projects page.',
	},
	readOnlyIssueMessage: {
		id: 'read-only-issue-info.read-only-issue-message',
		defaultMessage:
			'Because it belongs to an archived project. Jira admins can restore projects from the archive.',
		description:
			'Tells the reason why the issue cannot be edited and explains how to restore the project so the issue can be edited again.',
	},
	jsmReadOnlyIssueMessage: {
		id: 'read-only-issue-info.jsm-read-only-issue-message',
		defaultMessage:
			'Because it belongs to an archived service project. Jira admins can restore service projects from the archive.',
		description:
			'Tells the reason why the issue cannot be edited and explains how to restore the service project so the issue can be edited again.',
	},
	readOnlyArchivedIssueTitle: {
		id: 'read-only-issue-info.read-only-archived-issue-title',
		defaultMessage: 'This issue is archived',
		description: 'Informs that the issue has been archived.',
	},
	readOnlyArchivedIssueLink: {
		id: 'read-only-issue-info.read-only-archived-issue-link',
		defaultMessage: 'View all archived issues',
		description:
			'Text used in the link to redirect the user to archived issues. This can only be showed when the user has access to archived issues page.',
	},
	archiveIssueSupportMessage: {
		id: 'read-only-issue-info.archive-issue-support-message',
		defaultMessage: 'More about archiving issues',
		description: 'Message shown to render the support document link of archived issues',
	},
	readOnlyArchivedIssueLinkDefault: {
		id: 'read-only-issue-info.read-only-archived-issue-link-default',
		defaultMessage: 'View all archived issues',
		description:
			'Text used in the link to redirect the user to archived issues. This can only be showed when the user has access to archived issues page.',
	},
	readOnlyArchivedIssueRestoreLink: {
		id: 'read-only-issue-info.read-only-archived-issue-restore-link',
		defaultMessage: 'Restore',
		description: 'Text used in the link to restore the archived issues.',
	},
	readOnlyArchivedSubtaskRestoreLink: {
		id: 'read-only-issue-info.read-only-archived-Subtask-restore-link',
		defaultMessage: 'Restore parent issue',
		description: 'Text used in the link to restore the archived parent of a subtask.',
	},
	readOnlyArchivedIssueMessageAdminUser: {
		id: 'read-only-issue-info.read-only-archived-issue-message-admin-user',
		defaultMessage:
			'This is an archived issue. You will need to restore it before you can edit it.',
		description: 'Gives user information on how to unarchive the issue',
	},
	readOnlyArchivedSubtaskMessageAdminUser: {
		id: 'read-only-issue-info.read-only-archived-subtsak-message-admin-user',
		defaultMessage:
			'This is an archived subtask. To edit it, you will need to restore its parent issue first. This will automatically restore all its subtasks.',
		description: 'Gives user information on how to unarchive the issue',
	},
	readOnlyArchivedIssueMessageNonAdminUser: {
		id: 'read-only-issue-info.read-only-archived-issue-message-non-admin-user',
		defaultMessage: 'This is an archived issue and can’t be edited.',
		description: 'Gives user information on how to unarchive the issue',
	},
	readOnlyArchivedIssueMessageDefault: {
		id: 'read-only-issue-info.read-only-archived-issue-message-default',
		defaultMessage: 'This is an archived issue and can’t be edited.',
		description: 'Gives user information on how to unarchive the issue',
	},
});
