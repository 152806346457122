import React, { type ReactNode, type ChangeEvent } from 'react';
import { styled } from '@compiled/react';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import SingleLineTextField from '@atlassian/jira-issue-internal-field-single-line-text/src/single-line-text-field-view.tsx';
import { originalEstimateEditTestId } from '../../common/constants.tsx';

type Props = {
	isInvalidInput: boolean;
	isCompact?: boolean;
	invalidTimeFormatMessage: undefined | ReactNode;
	placeholder: string;
	testId?: string;
	value: string;
	onChange: (arg1: ChangeEvent<HTMLInputElement>) => void;
};

export const OriginalEstimateEdit = ({
	testId = originalEstimateEditTestId,
	isInvalidInput,
	invalidTimeFormatMessage,
	placeholder,
	value,
	onChange,
	isCompact = false,
}: Props) => (
	<Container data-testid={testId} isCompact={isCompact}>
		<SingleLineTextField
			compact
			type="text"
			value={value}
			placeholder={placeholder}
			onChange={onChange}
			invalidMessage={isInvalidInput ? invalidTimeFormatMessage : undefined}
		/>
	</Container>
);

export default OriginalEstimateEdit;

export const compactWidthPixels = gridSize * 14.5;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-dynamic-styles
const Container = styled.div<{ isCompact: boolean }>(({ isCompact }) => ({
	cursor: 'auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: isCompact ? `${compactWidthPixels}px` : '100%',
}));
