import parameters from '@atlassian/jira-relay/src/__generated__/ui_MoreDesignActionsQuery$parameters';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const moreActionsDesignsModalEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-design-more-actions" */ './ui/index.tsx'),
	),
	getPreloadProps: ({
		siteAri,
		issueKey,
		cloudId,
	}: {
		siteAri: string;
		issueKey: string;
		cloudId: string;
	}) => ({
		queries: {
			moreActions: {
				options: {
					fetchPolicy: 'store-or-network' as const,
				},
				parameters,
				variables: {
					siteAri,
					issueKey,
					cloudId,
				},
			},
		},
	}),
});
