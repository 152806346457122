import FetchError, { ValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';

export const SERVER_ERROR = 'SERVER_ERROR' as const;
export const VALIDATION_ERROR = 'VALIDATION_ERROR' as const;
export const CONNECTIVITY_ERROR = 'CONNECTIVITY_ERROR' as const;

export type FieldErrorTypes =
	| typeof VALIDATION_ERROR
	| typeof SERVER_ERROR
	| typeof CONNECTIVITY_ERROR;

// ValidationError: status code = 400
// FetchError: status code > 400
// Error = no status code due to lack of network connectivity
export type FieldError = ValidationError | FetchError | Error;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (error: FieldError): FieldErrorTypes => {
	if (error instanceof ValidationError) {
		return VALIDATION_ERROR;
	}
	if (!(error instanceof FetchError)) {
		return CONNECTIVITY_ERROR;
	}
	if (error.statusCode >= 400 && error.statusCode < 500) {
		return VALIDATION_ERROR;
	}
	return SERVER_ERROR;
};
