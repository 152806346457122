import React, { Component } from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import Spinner from '@atlaskit/spinner';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { CannedCommentsPlaceHolderSkeleton } from '@atlassian/jira-canned-comments/src/ui/canned-comments-placeholder/index.tsx';
import { borderRadius } from '@atlassian/jira-common-styles/src/main.tsx';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const ServiceDeskSpinner = styled.div({
	textAlign: 'center',
});
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const SpinnerSpan = styled.span({
	paddingTop: token('space.050', '4px'),
});
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const Flex = styled.div({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	height: '14px',
	paddingTop: token('space.025', '2px'),
});
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const Button = styled.button(
	{
		textAlign: 'left',
		color: token('color.text.subtlest', 'grey'),
		font: 'inherit',
		backgroundColor: token('elevation.surface', 'white'),
		width: '100%',
		height: '40px',
		paddingLeft: token('space.250', '20px'),
		paddingRight: token('space.250', '20px'),
		paddingTop: token('space.150', '12px'),
		paddingBottom: token('space.150', '12px'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		borderRadius: `${borderRadius}px`,
		boxSizing: 'border-box',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	() => ({
		borderWidth: '1px',
		borderStyle: 'solid',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		borderColor: token('color.border.input', colors.N100),
	}),
);

export type OwnProps = {
	placeholder: string;
	onExpanded: () => void;
	isSmartRepliesEnabled?: boolean;
};

export type StateProps = {
	isServiceDesk: boolean;
};

export type Props = OwnProps & StateProps;

type State = {
	changed: boolean;
};

// eslint-disable-next-line jira/react/no-class-components
export class FallbackAddCommentEditor extends Component<Props, State> {
	// go/jfe-eslint
	// eslint-disable-next-line react/sort-comp
	static defaultProps = {
		placeholder: '',
		onExpanded: noop,
		isSmartRepliesEnabled: false,
	};

	static getServiceDeskFallbackEditor() {
		return (
			<ServiceDeskSpinner>
				<Spinner size="medium" />
			</ServiceDeskSpinner>
		);
	}

	state = {
		changed: false,
	};

	onClick = () => {
		this.props.onExpanded();
		this.setState({ changed: true });
	};

	getGeneralFallbackEditor() {
		const [expConfig] = UNSAFE_noExposureExp('smart_replies_m1');

		return this.props.isSmartRepliesEnabled && expConfig.get('isEnabled', false) ? (
			<CannedCommentsPlaceHolderSkeleton
				onClick={this.onClick}
				shouldShowLoadingStateSpinner={this.state.changed}
			/>
		) : (
			<Button onClick={this.onClick}>
				<Flex>
					{this.props.placeholder}
					{this.state.changed && (
						<SpinnerSpan>
							<Spinner size="small" />
						</SpinnerSpan>
					)}
				</Flex>
			</Button>
		);
	}

	render() {
		const shouldUseServiceDeskCommentEditor = this.props.isServiceDesk;
		return shouldUseServiceDeskCommentEditor
			? FallbackAddCommentEditor.getServiceDeskFallbackEditor()
			: this.getGeneralFallbackEditor();
	}
}
