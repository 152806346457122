import compact from 'lodash/compact';
import find from 'lodash/find';
import { toAri } from '@atlassian/jira-platform-ari/src/index.tsx';
import type {
	ViewSet,
	ViewSetType,
} from '@atlassian/jira-polaris-domain-view/src/view-set/types.tsx';
import type { View } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import type { Action, StoreActionApi } from '@atlassian/react-sweet-state';
import { getCurrentViewAri } from '../../selectors/view/index.tsx';
import type { State, Props } from '../../types.tsx';
import { fireViewUpdatedEvent } from '../utils/analytics.tsx';
import { logViewError } from '../utils/errors.tsx';
import { findViewSet, findView } from '../utils/views/index.tsx';

type RankPayload = {
	id: string;
	parentId: string;
	index: number;
	srcParentId?: string;
};

export const rankViews =
	(rankPayload: RankPayload, onSuccess?: () => void, onError?: (err?: Error) => void) =>
	async (
		{ getState, setState }: StoreActionApi<State>,
		{ viewRemote, onViewUpdateFailed, createAnalyticsEvent }: Props,
	) => {
		const { id, index, parentId } = rankPayload;
		const idAri = toAri(id);
		const parentIdAri = toAri(parentId);

		const { viewSets } = getState();
		const prioritizeViewSet = findViewSet(viewSets, (v) => v.type === 'PRIORITIZE');

		if (prioritizeViewSet) {
			// Save all original views
			const flatViews = [...prioritizeViewSet.views];
			prioritizeViewSet.viewSets?.forEach(({ views }) => {
				flatViews.push(...views);
			});

			try {
				if (idAri && parentIdAri) {
					// Populate a new views
					const node = await viewRemote.updateViewRank({
						viewId: idAri,
						rank: index + 1,
						containerId: parentIdAri,
					});

					if (node.views.length > 0) {
						const rankedViews = [...node.views].sort(
							({ rank: rankA }, { rank: rankB }) => rankA - rankB,
						);
						const rankedViewSets = [...node.viewsets];

						// Item was moved into section
						if (parentId !== prioritizeViewSet.id) {
							// First find moved item and remove it from it's original position
							const updatedViewSet = viewSets.map((v) => {
								if (v.id === prioritizeViewSet.id) {
									return {
										...v,
										views: v.views.filter((view) => view.viewId !== id),
										viewSets: v.viewSets?.map((vSet: ViewSet) => {
											if (vSet.id === parentId) {
												return {
													...vSet,
													views: rankedViews.map(({ id: viewId, rank }) => {
														// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
														const oldView = find(flatViews, {
															viewId,
														}) as View;
														const newView = {
															...oldView,
															viewSetId: parentId,
														};
														return { ...newView, ...{ rank } };
													}),
												};
											}
											return {
												...vSet,
												views: vSet.views.filter((view) => view.viewId !== id),
											};
										}),
									};
								}
								return v;
							});
							setState({
								viewSets: updatedViewSet,
							});
						} else {
							// Update views with new ranks.
							const newViews = rankedViews
								.map(({ id: viewId, rank }) => {
									const oldView = find(flatViews, { viewId });
									if (!oldView) {
										return undefined;
									}
									return { ...oldView, ...{ rank } };
								})
								.filter(Boolean);

							const newViewSetsUnsafe: (ViewSet | undefined)[] = rankedViewSets.map((vSet) => {
								const { collapsed } =
									prioritizeViewSet.viewSets?.find(({ id: origId }) => origId === vSet.id) ?? {};

								const setInState: ViewSet | undefined = findViewSet(
									viewSets,
									(v) => v.id === vSet.id,
								);

								if (setInState === undefined) {
									return undefined;
								}

								const updatedSet: ViewSet = {
									...setInState,
									rank: vSet.rank,
									collapsed: collapsed || false,
									views: vSet.views
										.map(({ id: viewId, rank }) => {
											const oldView = find(flatViews, { viewId });
											if (!oldView) {
												return undefined;
											}
											return { ...oldView, ...{ rank } };
										})
										.filter(Boolean)
										.sort(({ rank: rankA }, { rank: rankB }) => rankA - rankB),
								};

								return updatedSet;
							});

							const newViewSets: ViewSet[] = compact(newViewSetsUnsafe);

							// Update state with latest ranked views.
							const updatedViewSet: ViewSet[] = viewSets.map((v) => {
								if (v.id === prioritizeViewSet.id) {
									return {
										...prioritizeViewSet,
										views: newViews,
										viewSets: newViewSets,
									};
								}
								return v;
							});
							setState({
								viewSets: updatedViewSet,
							});
						}
					}
					onSuccess && onSuccess();

					const movedView = flatViews.find((v) => v.viewId === idAri);
					if (movedView) {
						fireViewUpdatedEvent(createAnalyticsEvent, movedView, {
							movedFrom: movedView.viewSetId,
							movedTo: parentIdAri,
						});
					}
				}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				logViewError('actions.rank', Object.assign(error));
				onViewUpdateFailed(error);
				onError && onError(error);
			}
		}
	};

export const refreshViewsRanks =
	(): Action<State, Props> =>
	async ({ getState, setState }, props) => {
		const state = getState();
		const { viewRemote, onViewUpdateFailed } = props;
		const { viewSets } = state;
		const currentViewAri = getCurrentViewAri(state, props);

		try {
			const result = await viewRemote.fetchViewRanks();
			const prioritizeViewSet = result.viewsets?.find(({ type }) => type === 'PRIORITIZE');
			if (!prioritizeViewSet) {
				return;
			}

			const updatedViewSet: ViewSet[] = viewSets.map((rootViewSet) => {
				if (rootViewSet.type !== 'PRIORITIZE') {
					return rootViewSet;
				}

				return {
					...rootViewSet,
					views: prioritizeViewSet.views
						.map(({ id, rank }) => {
							const foundView = findView(viewSets, ({ viewId }) => id === viewId);
							if (!foundView) {
								return undefined;
							}
							return {
								...foundView,
								rank,
							};
						})
						.filter(Boolean)
						.sort(({ rank: rankA }, { rank: rankB }) => rankA - rankB),

					viewSets: prioritizeViewSet.viewsets
						.map(({ id, views, rank, name, type }) => {
							const foundViewSet = findViewSet(viewSets, (vSet) => id === vSet.id);

							return {
								id,
								name,
								// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
								type: (type as ViewSetType) || 'SECTION',
								collapsed:
									!!views.find((view) => view.id === currentViewAri) || !!foundViewSet?.collapsed,
								rank,
								views: views
									.map((view) => {
										const foundView = findView(viewSets, ({ viewId }) => view.id === viewId);
										if (!foundView) {
											return undefined;
										}
										return {
											...foundView,
											rank: view.rank,
										};
									})
									.filter(Boolean)
									.sort(({ rank: rankA }, { rank: rankB }) => rankA - rankB),
							};
						})
						.filter(Boolean)
						.sort(({ rank: rankA }, { rank: rankB }) => rankA - rankB),
				};
			});

			setState({
				viewSets: updatedViewSet,
			});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			onViewUpdateFailed(error);
		}
	};
