import { createContext } from 'react';
import type { FieldInjectionsContextValue } from './types.tsx';

// Replace empty function with noop
// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = () => {};
export const identity = <T,>(x: T) => x;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const initialValue: FieldInjectionsContextValue<any> = {
	capturing: {
		captureAllowedValues: noop,
		captureDescription: noop,
		captureFieldId: noop,
		captureFieldType: noop,
		captureIsDisabled: noop,
		captureIsRequired: noop,
		captureLabel: noop,
		captureRenderer: noop,
	},
	overriding: {
		overrideLabel: identity,
		overrideElementAfterLabel: identity,
		overrideIsRequired: identity,
		overrideIsDisabled: identity,
		overrideAllowedValues: identity,
		overrideFieldOptionsFilter: identity,
		overrideOnChange: identity,
		overrideOnBlur: identity,
		overrideDescription: identity,
	},
};
export const FieldInjectionsContext = createContext(initialValue);
