import React, { useCallback, useMemo, type ComponentPropsWithoutRef, type FC } from 'react';
import { Clipboard as RealClipboard } from '@atlaskit/media-picker';
import { useClipboardContextBoundary } from '../controllers/store/index.tsx';

/**
 * @deprecated - this component is intended for testing purposes only
 */
export const ClipboardWithoutBoundaryMarker = () => null;

/**
 * A secure version of {@link import('@atlaskit/media-picker').Clipboard} with automatic boundary tracking
 * @borrows @RealClipboard
 */
export const Clipboard: FC<
	ComponentPropsWithoutRef<typeof RealClipboard> & { isolated?: false }
> = ({ config, isolated, ...props }) => {
	const container = useClipboardContextBoundary();

	const onPaste = useCallback((event: Event) => {
		// do not propagate event above container boundary
		event.stopPropagation();

		// preventing default will disable any copy-paste
		// event.preventDefault();

		// false means "allow"
		return false;
	}, []);

	const derivedConfig = useMemo(
		() =>
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			(container
				? {
						...config,
						container,
						onPaste,
					}
				: config) as typeof config,
		[config, container, onPaste],
	);

	if (!container && isolated !== false) {
		// hard block clipboard if boundary is not set
		// render "marker" to ease testing
		return <ClipboardWithoutBoundaryMarker />;
	}

	// Here there is a good opportunity to report own existence to the boundary to prevent two Clipboards from being active at the same node
	return <RealClipboard {...props} config={derivedConfig} />;
};
