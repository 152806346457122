import React, { type ComponentType, type ReactElement, type ReactNode, useMemo } from 'react';
import { EditAnalyticsWrapper } from '@atlassian/jira-forge-ui-analytics/src/ui/custom-field/index.tsx';
import type { ForgeCustomFieldValue } from '@atlassian/jira-forge-ui-types/src/common/types/contexts/custom-field.tsx';
import type { ForgeUiIssueData } from '@atlassian/jira-forge-ui-types/src/common/types/extension-data.tsx';
import type {
	CustomField,
	CustomFieldType,
} from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import { getForgeUIType } from '@atlassian/jira-forge-ui-utils-internal/src/utils/extension/index.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { useAnalyticsAttributesContext } from '../analytics/atrributes-context/index.tsx';
import type { CustomFieldProps } from '../types.tsx';
import EditReadView from './edit-read-view/index.tsx';
import { ForgeCustomFieldEditView as ForgeCustomFieldEditViewOld } from './view-old.tsx';
import { ForgeCustomFieldEditView } from './view.tsx';

type Props = {
	issueKey: IssueKey;
	shouldRenderCustomReadView: boolean;
	value: ForgeCustomFieldValue;
	fieldId: string;
	fieldName: string;
	renderCustomReadView: (newValue: ForgeCustomFieldValue) => ReactElement;
	extension: CustomField | CustomFieldType;
	extensionData: ForgeUiIssueData | null;
	viewProps: CustomFieldProps;
	ForgeField: ComponentType<CustomFieldProps>;
	onCancel: () => void;
	onSave?: (value: ForgeCustomFieldValue | null) => void;
};

export const ForgeCustomFieldEditInner = ({
	renderCustomReadView,
	shouldRenderCustomReadView,
	ForgeField,
	viewProps,
	...restProps
}: Props) => {
	const viewComponent = (
		switchToEditMode: () => void,
		newValue: ForgeCustomFieldValue,
		isLoading: boolean,
	) => {
		const readView = (fieldValue: ForgeCustomFieldValue) => (
			<EditReadView isLoading={isLoading}>{renderCustomReadView(fieldValue)}</EditReadView>
		);

		const fieldContentWrapper = ({
			topMargin,
			children,
		}: {
			topMargin?: number | undefined;
			children: ReactNode;
		}) => (
			<EditReadView
				isCustomReadView={shouldRenderCustomReadView}
				topMargin={topMargin}
				isLoading={isLoading}
			>
				{children}
			</EditReadView>
		);

		const editViewProps = {
			...viewProps,
			onEditRequest: switchToEditMode,
			value: newValue,
			...(shouldRenderCustomReadView ? { customReadView: readView } : { fieldContentWrapper }),
		};

		return <ForgeField {...editViewProps} />;
	};

	return <ForgeCustomFieldEditViewOld {...restProps} viewComponent={viewComponent} />;
};

export const ForgeCustomFieldEdit = (props: Props) => {
	const analyticsAttributes = useAnalyticsAttributesContext();
	const isInlineEditEnabled = useMemo(() => {
		const forgeUIType = getForgeUIType(props.extension, 'edit');
		return (
			forgeUIType !== 'ui-kit' &&
			props.extension.properties?.edit &&
			'resource' in props.extension.properties.edit &&
			props.extension.properties?.edit?.isInline
		);
	}, [props.extension]);

	return (
		<EditAnalyticsWrapper attributes={analyticsAttributes}>
			{isInlineEditEnabled ? (
				<ForgeCustomFieldEditView {...props} />
			) : (
				<ForgeCustomFieldEditInner {...props} />
			)}
		</EditAnalyticsWrapper>
	);
};
