import { styled } from '@compiled/react';
import type { ThemeProps, ThemeTokens } from '@atlaskit/button';
import { colors } from '@atlaskit/theme';

import { token } from '@atlaskit/tokens';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { SectionHeading } from '@atlassian/jira-issue-view-common/src/component/section-heading/section-heading-view.tsx';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ActivitySortingWrapper = styled.div({
	alignSelf: 'flex-end',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ActivityHeadingEndWrapper = styled.div({
	alignSelf: 'flex-end',
	display: 'flex',
	flexDirection: 'row',
	gap: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ActivityItemHeading = styled(SectionHeading)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	marginTop: `${gridSize / 2}px`,
});

ActivityItemHeading.displayName = 'ActivityItemHeading';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ActivityItems = styled.div({
	display: 'flex',
});

type ButtonThemeType = (
	isSelected: boolean,
) => (currentTheme: (arg1: ThemeProps) => ThemeTokens, themeProps: ThemeProps) => ThemeTokens;

export const buttonTheme: ButtonThemeType =
	(isSelected: boolean) =>
	(currentTheme: (arg1: ThemeProps) => ThemeTokens, themeProps: ThemeProps) => {
		const { buttonStyles, ...rest } = currentTheme(themeProps);
		return {
			...rest,
			buttonStyles: {
				...buttonStyles,
				padding: `0 ${token('space.050', '4px')} 0 ${token('space.050', '4px')}`,
				margin: `0 ${token('space.050', '4px')} 0 ${token('space.050', '4px')}`,
				backgroundColor: isSelected
					? token('color.background.selected', colors.N400)
					: token('color.background.neutral', colors.N20),
				'&:hover': {
					backgroundColor: isSelected
						? token('color.background.selected.hovered', colors.N400)
						: token('color.background.neutral.hovered', colors.N30),
				},
				'&:active': {
					backgroundColor: isSelected
						? token('color.background.selected.pressed', colors.N400)
						: token('color.background.neutral.pressed', colors.B50),
				},
			},
		};
	};
