import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	assignToMe: {
		id: 'issue-field-assignee-assign-to-me.assign-to-me.assign-to-me',
		defaultMessage: 'Assign to me',
		description:
			'Text used in the dropdown item that, when selected, will assign the issue to the current user.',
	},
});
