import memoize from 'lodash/memoize';
import { getLabelsCache } from '@atlassian/jira-cache/src/services/labels/index.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import { genericMessages } from '@atlassian/jira-issue-view-common-constants/src/context-items-messages.tsx';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button/index.tsx';
import connectField from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-field.tsx';
import { issueKeySelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import {
	fieldAutoCompleteUrlSelector,
	fieldEditSessionIdSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector.tsx';
import { fireOperationalAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { getMemoizedSuggestionsFactory } from './fetch-suggestions.tsx';
import messages from './messages.tsx';
import {
	transformFromStateValue,
	transformToStateValue,
	transformSuggestionsFromServerAndCache,
	transformToCacheItem,
} from './transformer.tsx';
import { validate } from './validation.tsx';
import LabelsView from './view.tsx';

const labelsCache = getLabelsCache();

const saveField = (
	// @ts-expect-error - TS7031 - Binding element 'baseUrl' implicitly has an 'any' type. | TS7031 - Binding element 'issueKey' implicitly has an 'any' type. | TS7031 - Binding element 'fieldMetaKey' implicitly has an 'any' type. | TS7031 - Binding element 'value' implicitly has an 'any' type. | TS7031 - Binding element 'fieldEditSessionId' implicitly has an 'any' type.
	{ baseUrl, issueKey, fieldMetaKey, value, fieldEditSessionId },
	// @ts-expect-error - TS2304 - Cannot find name 'State'.
	_: State,
	// @ts-expect-error - TS7006 - Parameter 'ownPropsOnMount' implicitly has an 'any' type.
	ownPropsOnMount,
) => {
	const url =
		fieldEditSessionId !== undefined
			? `${baseUrl}/rest/api/2/issue/${issueKey}?fieldEditSessionId=${fieldEditSessionId}`
			: `${baseUrl}/rest/api/2/issue/${issueKey}`;

	// @ts-expect-error - TS7006 - Parameter 'current' implicitly has an 'any' type.
	value.forEach((current) => {
		labelsCache
			.get(current)
			.then((cachedItem) => {
				if (ownPropsOnMount.createAnalyticsEvent) {
					fireOperationalAnalytics(
						ownPropsOnMount.createAnalyticsEvent({}),
						'labelPickerCache set',
						{
							isFromCache: Boolean(cachedItem?.label),
						},
					);
				}
				current && labelsCache.set(`${current}`, transformToCacheItem(current));
			})
			.catch((error) => {
				log.safeErrorWithoutCustomerData('issue-view.labels.cache.set', error.message);
			});
	});

	return performPutRequest(url, {
		body: JSON.stringify({
			fields: {
				[fieldMetaKey]: value,
			},
		}),
	}).catch((error) => {
		// @ts-expect-error - TS7006 - Parameter 'current' implicitly has an 'any' type.
		value.forEach((current) => {
			// Logging only cache removals
			labelsCache
				.get(current)
				.then((cachedItem) => {
					if (cachedItem?.label && ownPropsOnMount.createAnalyticsEvent) {
						fireOperationalAnalytics(
							ownPropsOnMount.createAnalyticsEvent({}),
							'labelPickerCache removed',
						);
						cachedItem && labelsCache.remove(`${cachedItem.label}`);
					}
				})
				.catch((e) => {
					log.safeErrorWithoutCustomerData('issue-view.labels.cache.remove', e.message);
				});
		});

		throw error;
	});
};

// NOTE: temporarily exported for unit testing purposes only
export const formatCreateLabelFactory = memoize(
	(intl) => (inputValue: string) => `${inputValue} (${intl.formatMessage(messages.createNewItem)})`,
);

const ConnectedLabelsField = injectIntl(
	connectField((stateOnMount, ownPropsOnMount) => {
		// Added two flow fix  here after bending over backwards
		// NOTE -> The labels extracted version is coming soon, which will render the
		// connected components obsolete :) This will all be DELETED very very soon :)
		const formatCreateLabel = formatCreateLabelFactory(ownPropsOnMount.intl);
		const getDataFromCache = () =>
			labelsCache
				.getAll()
				.then((labels) =>
					transformSuggestionsFromServerAndCache({}, labels, ownPropsOnMount.intl.formatMessage),
				);
		const fieldAutoCompleteUrlSelectorGetter = fieldAutoCompleteUrlSelector(
			ownPropsOnMount.fieldId,
		);
		const fieldEditSessionIdSelectorGetter = fieldEditSessionIdSelector(ownPropsOnMount.fieldId);

		return {
			fieldId: ownPropsOnMount.fieldId,
			transformFromStateValue,
			transformToStateValue,
			additionalProps: (state, intl) => ({
				fetchSuggestions: getMemoizedSuggestionsFactory(
					fieldAutoCompleteUrlSelectorGetter(state),
					ownPropsOnMount.fieldId,
					fieldEditSessionIdSelectorGetter(state),
					ownPropsOnMount.createAnalyticsEvent,
					intl.formatMessage,
				),
				formatMessage: intl.formatMessage,
				placeholder: intl.formatMessage(messages.placeholder),
				noValueText: intl.formatMessage(genericMessages.noValue),
				formatCreateLabel,
				canCreateNewItem: true,
				allowEmptyValue: true,
				validate,
				issueKey: issueKeySelector(state),
				showPinButton: getShowPinButton(ownPropsOnMount.area),
				getDataFromCache,
			}),
			// @ts-expect-error - TS2345 - Argument of type 'SaveFieldArguments<unknown>' is not assignable to parameter of type '{ baseUrl: any; issueKey: any; fieldMetaKey: any; value: any; fieldEditSessionId: any; }'.
			saveField: (...args) => saveField(...args, ownPropsOnMount),
		};
	})(LabelsView),
);

export default ConnectedLabelsField;
