export const EXTERNAL_REFERENCE_PROPERTY_TEMPLATE = 'external-reference-property';

export type SUPPORTED_EXTERNAL_REFERENCE_PROPERTY = 'dueDate' | 'state';

/**
 * Formula for a external reference property value.
 */
export type ExternalReferencePropertyFormula = {
	template: typeof EXTERNAL_REFERENCE_PROPERTY_TEMPLATE;
	parameters: {
		externalReferenceFieldKey: string;
		property: SUPPORTED_EXTERNAL_REFERENCE_PROPERTY;
	};
};
