/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { type KeyboardEventHandler, useCallback } from 'react';

import { jsx } from '@emotion/react'; // eslint-disable-line @atlaskit/ui-styling-standard/use-compiled
import { useIntl } from 'react-intl-next';

import { IconButton } from '@atlaskit/button/new';
import {
	ACTION,
	ACTION_SUBJECT,
	ACTION_SUBJECT_ID,
	EVENT_TYPE,
	INPUT_METHOD,
} from '@atlaskit/editor-common/analytics';
import { useSharedPluginState } from '@atlaskit/editor-common/hooks';
import type { PublicPluginAPI } from '@atlaskit/editor-common/types';
import type { HyperlinkPlugin } from '@atlaskit/editor-plugins/hyperlink';
import LinkIcon from '@atlaskit/icon/core/link';

import { useFireAnalyticsEvent } from '../../hooks/useAnalyticsEvent';

import messages from './messages';

type Props = {
	promptType: 'user-input' | 'interrogate';
	editorApi?: PublicPluginAPI<[HyperlinkPlugin]>;
};

export const LinkPickerButton = ({ editorApi, promptType }: Props) => {
	const { formatMessage } = useIntl();

	const { hyperlinkState } = useSharedPluginState(editorApi, ['hyperlink']);

	const fireAnalyticsEvent = useFireAnalyticsEvent();

	const handleClick = useCallback(() => {
		if (editorApi) {
			const showLinkToolbarAction = editorApi.hyperlink?.commands.showLinkToolbar(
				INPUT_METHOD.QUICK_INSERT,
			);
			if (showLinkToolbarAction) {
				editorApi.core.actions.execute(showLinkToolbarAction);
			}
		}

		fireAnalyticsEvent({
			payload: {
				action: ACTION.CLICKED,
				actionSubject: ACTION_SUBJECT.BUTTON,
				actionSubjectId: ACTION_SUBJECT_ID.AI_PROMPT_LINK_PICKER_BUTTON,
				attributes: {
					promptType,
				},
				eventType: EVENT_TYPE.UI,
			},
		});
	}, [editorApi, fireAnalyticsEvent, promptType]);

	// The editor can be wrapped in a component that process keyboard events,
	// but we don't need to propagate `Enter`, `Space`, and `Tab` key event to the editor.
	const stopPropagationForActionKeys: KeyboardEventHandler = useCallback((event) => {
		if (['Enter', ' ', 'Tab'].includes(event.key)) {
			event.stopPropagation();
		}
	}, []);

	return (
		<IconButton
			isTooltipDisabled={false}
			label={formatMessage(messages.label)}
			testId="link-picker-button"
			icon={LinkIcon}
			isSelected={!!hyperlinkState?.activeLinkMark}
			spacing="compact"
			appearance="subtle"
			onClick={handleClick}
			onKeyDown={stopPropagationForActionKeys}
			onKeyUp={stopPropagationForActionKeys}
		/>
	);
};
