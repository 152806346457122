import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	viewDeleted: {
		id: 'polaris-common.controllers.views.view-deleted',
		defaultMessage:
			'The view you had opened was deleted by {userName, select, undefined {another user} other {{userName}}}',
		description: '',
	},
	viewUpdated: {
		id: 'polaris-common.controllers.views.view-updated',
		defaultMessage:
			"The view's configuration was saved by {userName, select, undefined {someone else} other {{userName}}}, so we've reset your changes",
		description: '',
	},
});
