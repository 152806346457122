import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noOptionsSelected: {
		id: 'issue-field-cascading-select.cascading-select-view.no-options-selected',
		defaultMessage: 'None',
		description: 'Text to indicate that no option has been selected',
	},
	errorMessage: {
		id: 'issue-field-cascading-select.cascading-select-view.error-message',
		defaultMessage: 'The value could not be saved',
		description: 'Error message when save fails',
	},
});
