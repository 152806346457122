import { isAriResourceIdValid } from '../utils/is-ari-resource-id-valid/index.tsx';
import { isNotEmptyString } from '../utils/is-not-empty-string/index.tsx';

export type CascadingSelectPublicSetValue = null | {
	parentId: string;
	childId: string | null;
};

export type CascadingSelectPublicGetValue = {
	parent: CascadingSelectOptionValue;
	child: CascadingSelectOptionValue | null;
} | null;

export type CascadingSelectOptionValue = {
	id: string;
	value: string;
};

export const validateCascadingSelectSetValue = (
	value: unknown,
): value is CascadingSelectPublicSetValue =>
	value === null ||
	(typeof value === 'object' &&
		'parentId' in value &&
		'childId' in value &&
		isNotEmptyString(value.parentId) &&
		(isNotEmptyString(value.childId) || value.childId === null));

export const validateCascadingSelectWithAriSetValue = (
	value: unknown,
): value is CascadingSelectPublicSetValue =>
	value === null ||
	(typeof value === 'object' &&
		'parentId' in value &&
		'childId' in value &&
		isNotEmptyString(value.parentId) &&
		isAriResourceIdValid(value.parentId) &&
		(value.childId === null ||
			(isNotEmptyString(value.childId) && isAriResourceIdValid(value.childId))));
