import React from 'react';
import { styled } from '@compiled/react';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import Warning from '@atlaskit/icon/glyph/warning';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { type WARNING, ERROR } from '../../../../../common/constants.tsx';

export type AlertType = typeof ERROR | typeof WARNING;

export type Props = {
	type: AlertType;
	alertMessage: string;
	testId: string;
};

const ErrorAlert = ({ type, alertMessage, testId }: Props) => (
	<Wrapper data-testid={testId}>
		{type === ERROR ? (
			<>
				<ErrorIcon label="error" primaryColor={token('color.icon.danger', colors.R500)} />
				<ErrorMessage>{alertMessage}</ErrorMessage>
			</>
		) : (
			<>
				<Warning label="warning" primaryColor={token('color.icon.subtle', colors.N200)} />
				<WarningMessage>{alertMessage}</WarningMessage>
			</>
		)}
	</Wrapper>
);

export default ErrorAlert;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	display: 'flex',
	alignItems: 'start',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const WarningMessage = styled.p({
	margin: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N200),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ErrorMessage = styled.p({
	margin: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.danger', colors.R500),
});
