import React from 'react';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';
import type { ConfluenceContentPlaceholderCrossJoinProps } from '../../confluence-placeholder-template-experiment/confluence-dummy-link/experiments/grw-1117-placeholder-crossjoin/index.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const ConfluenceContentPlaceholderCrossJoinModule = lazy(() =>
	import(
		/* webpackChunkName: "async-confluence-content-placeholder-experiment" */ '../../confluence-placeholder-template-experiment/confluence-dummy-link/experiments/grw-1117-placeholder-crossjoin'
	).then((module) => module.ConfluenceContentPlaceholderCrossJoin),
);

export function ConfluenceContentPlaceholderCrossJoin(
	props: ConfluenceContentPlaceholderCrossJoinProps,
) {
	return (
		<ErrorBoundary
			id="confluence-content-placeholder.crossjoin-experiment"
			packageName="ConfluenceContentPlaceholderCrossJoin"
		>
			<ConfluenceContentPlaceholderCrossJoinModule {...props} />
		</ErrorBoundary>
	);
}
