import React, { useCallback } from 'react';
import LinkIcon from '@atlaskit/icon/glyph/link';
import type { IssueContext } from '@atlassian/jira-development-summary-common/src/common/types.tsx';
import { SummaryItem } from '@atlassian/jira-development-summary-common/src/ui/summary-item/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	ContextualAnalyticsData,
	DRAWER,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';

type Props = {
	issueContext: IssueContext;
	remoteLinksCount: number;
	onClick: () => void;
};

const Description = ({ description }: { description: string }) => <>{description}</>;

export const RemoteLinks = ({ remoteLinksCount, issueContext, onClick }: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();
	const onLinkClicked = useCallback(
		(
			attributes: {
				[key: string]: string | number | boolean | null;
			},
			onClickAction: () => void,
		) => {
			fireUIAnalytics(
				createAnalyticsEvent({ action: 'clicked', actionSubject: 'link' }),
				'clickRemoteLinksSummaryLink',
				attributes,
			);
			if (onClickAction) {
				onClickAction();
			}
		},
		[createAnalyticsEvent],
	);

	const summaryItemLabel = formatMessage(messages.summaryItemLabel, {
		remoteLinksCount,
	});

	const { issueStatusCategory, projectType, projectId, isSimplifiedProject } = issueContext;

	const analyticsAttributes = {
		issueStatusCategory,
		projectType,
		projectId,
		isSimplifiedProject,
		remoteLinksCount,
	};

	return (
		<ContextualAnalyticsData sourceType={DRAWER} sourceName="viewRemoteLinksSummary">
			<SummaryItem
				title={<Description description={summaryItemLabel} />}
				icon={<LinkIcon label={formatMessage(messages.linkIconLabel)} size="medium" />}
				onClick={() => {
					onLinkClicked(analyticsAttributes, onClick);
				}}
				showIconInPlainLink
				itemType="remote-links"
				itemCount={remoteLinksCount}
				// eslint-disable-next-line jira/integration/test-id-by-folder-structure
				data-testid="development-summary-remote-links.ui.summary-item"
			/>
		</ContextualAnalyticsData>
	);
};

export default RemoteLinks;
