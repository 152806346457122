import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	invalidPhoneNumber: {
		id: 'servicedesk-customer-service-custom-details.common.field.phone-number-field.invalid-phone-number',
		defaultMessage: 'Enter a valid phone number (e.g. +61 111 111).',
		description:
			'Validation error message in red text shown when entry is not a valid phone number.',
	},
});
