import React from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import AkLozenge from '@atlaskit/lozenge';
import { BranchEmptyState } from '@atlassian/jira-development-summary-branch/src/ui/index.tsx';
import { CommitEmptyState } from '@atlassian/jira-development-summary-commit/src/ui/index.tsx';
import { SummaryItemContainer } from '@atlassian/jira-development-summary-common/src/ui/summary-item/index.tsx';
import { PullRequestEmptyState } from '@atlassian/jira-development-summary-pull-request/src/ui/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { FireUiAnalytics, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import DevelopmentFieldWrapper from '../../common/ui/development-field-wrapper/index.tsx';
import SlideDownOnMount from '../../common/ui/slide-down-on-mount/index.tsx';
import { showEmptyStateDialog } from '../../controllers/dev-summary/actions/index.tsx';
import AbsoluteCloseButton from './absolute-close-button/index.tsx';
import EmptyStateDialogView from './empty-state-dialog/index.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

export const EmptySummary = ({ onOpenEmptyStateDialog }: Props) => {
	const { formatMessage } = useIntl();
	const notIntegratedLabel = formatMessage(messages.notIntegrated);
	const buttonCTA = formatMessage(messages.buttonCta);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onButtonClick = (_: any, analyticsEvent: any) => {
		fireUIAnalytics(analyticsEvent, 'openDevPanelEmptyStateModal');
		onOpenEmptyStateDialog();
	};

	return (
		<>
			<FireUiAnalytics eventName="viewed devPanelEmptyState" />
			<RelativeFieldWrapper>
				<DevelopmentFieldWrapper>
					<SlideDownOnMount>
						<SummaryItemContainer>
							<AkLozenge appearance="new">{notIntegratedLabel}</AkLozenge>
						</SummaryItemContainer>
						<BranchEmptyState />
						<CommitEmptyState />
						<PullRequestEmptyState />
						<SummaryItemContainer>
							<Button spacing="none" appearance="link" onClick={onButtonClick}>
								{buttonCTA}
							</Button>
						</SummaryItemContainer>
						<AbsoluteCloseButton />
					</SlideDownOnMount>
				</DevelopmentFieldWrapper>
			</RelativeFieldWrapper>
			<EmptyStateDialogView />
		</>
	);
};

export default connect(() => ({}), {
	onOpenEmptyStateDialog: showEmptyStateDialog,
})(EmptySummary);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RelativeFieldWrapper = styled.div({
	position: 'relative',
});
