import type { AggParentCandidate } from '@atlassian/jira-issue-field-parent-editview-full/src/common/types.tsx';
import type { NullableParentOption } from '@atlassian/jira-issue-field-parent-editview-full/src/ui/parent/types.tsx';
import type { AggJiraParent } from './types.tsx';

export const transformOptionToAgg = (option: NullableParentOption): AggJiraParent | null => {
	if (!option) {
		return null;
	}
	return {
		id: String(option?.id) || '',
		issueId: option?.issueId || '',
		webUrl: option?.webUrl || '',
		key: option?.key,
		issueColorField: {
			color: {
				colorKey: option?.color || '',
			},
		},
		issueTypeField: {
			issueType: {
				avatar: {
					xsmall: option?.fields?.issuetype?.iconUrl || '',
				},
				name: option?.fields?.issuetype?.name || '',
			},
		},
		summaryField: {
			text: option?.fields.summary || '',
		},
	};
};

type ParentIssue = AggParentCandidate & {
	summaryField: {
		id: string;
		text?: string;
	};
	issueTypeField: {
		id: string;
		issueType: {
			id: string;
			avatar: {
				xsmall: string;
				medium?: string;
			};
			name: string;
		};
	};
};

export type ParentFieldOptimisticResponse = {
	id: string;
	fieldId: string;
	type: string;
	name: string;
	parentIssue: ParentIssue | null;
};

export const transformOptionToOptimisticFieldResponse = (
	field: {
		id: string;
		fieldId: string;
		type: string;
		name: string;
	},
	aggValue: AggParentCandidate,
): ParentFieldOptimisticResponse => {
	const summaryField = aggValue?.fieldsById?.edges?.find(
		(edge) => edge?.node?.__typename === 'JiraSingleLineTextField',
	);
	const issueTypeField = aggValue?.fieldsById?.edges?.find(
		(edge) => edge?.node?.__typename === 'JiraIssueTypeField',
	);
	return {
		id: field?.id,
		fieldId: field?.fieldId,
		type: field?.type,
		name: field?.name,
		parentIssue: aggValue
			? {
					id: aggValue?.id,
					issueId: aggValue?.issueId,
					webUrl: aggValue?.webUrl,
					key: aggValue?.key,
					issueColorField: aggValue?.issueColorField,
					fieldsById: aggValue?.fieldsById,

					summaryField: {
						id: summaryField?.node?.id || '',
						text: summaryField?.node?.text || '',
					},
					issueTypeField: {
						id: issueTypeField?.node?.id || '',
						issueType: {
							id: issueTypeField?.node?.issueType?.id || '',
							avatar: {
								xsmall: issueTypeField?.node?.issueType?.avatar?.xsmall || '',
							},
							name: issueTypeField?.node?.issueType?.name || '',
						},
					},
				}
			: null,
	};
};
