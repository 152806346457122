import { fetchQuery, graphql } from 'react-relay';
import type { IEnvironment } from 'relay-runtime';
import type { fetchDefinitionsPermissionsQuery as fetchDefinitionsPermissionsQueryType } from '@atlassian/jira-relay/src/__generated__/fetchDefinitionsPermissionsQuery.graphql';
import type { CloudId } from '@atlassian/jira-shared-types/src/general.tsx';

export const fetchDefinitionsPermissions = async (cloudId: CloudId, environment: IEnvironment) => {
	try {
		const data = await fetchQuery<fetchDefinitionsPermissionsQueryType>(
			environment,
			graphql`
				query fetchDefinitionsPermissionsQuery($cloudId: ID!) {
					jira {
						isJiraDefinitionsPermissionsEnabled(cloudId: $cloudId)
					}
				}
			`,
			{
				cloudId,
			},
		)
			.toPromise()
			.catch((err: unknown) => {
				throw err;
			});

		const result = data?.jira?.isJiraDefinitionsPermissionsEnabled || false;

		return result;
	} catch (err) {
		return false;
	}
};
