/**
 * @generated SignedSource<<e14f8a1df320b9d79139210fcc8743c3>>
 * @relayHash 4c29abddd7eb65a76f51ee057781b14a
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 7214d971ca27d88233d82f95bff063776ad54f0b6b9fe256a8b3762c4b2f126b

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type JiraSingleValueFieldOperations = "SET" | "%future added value";
export type JiraUpdateSingleGroupPickerFieldInput = {
  id: string;
  operation: JiraSingleGroupPickerFieldOperationInput;
};
export type JiraSingleGroupPickerFieldOperationInput = {
  groupId?: string | null | undefined;
  id?: string | null | undefined;
  operation: JiraSingleValueFieldOperations;
};
export type ui_issueViewLayoutGroupPickerField_IssueViewGroupPickerField_Mutation$variables = {
  input: JiraUpdateSingleGroupPickerFieldInput;
};
export type ui_issueViewLayoutGroupPickerField_IssueViewGroupPickerField_Mutation$data = {
  readonly jira: {
    readonly updateSingleGroupPickerField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly id: string;
        readonly selectedGroup: {
          readonly groupId: string;
          readonly id: string;
          readonly name: string;
        } | null | undefined;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type ui_issueViewLayoutGroupPickerField_IssueViewGroupPickerField_Mutation$rawResponse = {
  readonly jira: {
    readonly updateSingleGroupPickerField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly id: string;
        readonly selectedGroup: {
          readonly groupId: string;
          readonly id: string;
          readonly name: string;
        } | null | undefined;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type ui_issueViewLayoutGroupPickerField_IssueViewGroupPickerField_Mutation = {
  rawResponse: ui_issueViewLayoutGroupPickerField_IssueViewGroupPickerField_Mutation$rawResponse;
  response: ui_issueViewLayoutGroupPickerField_IssueViewGroupPickerField_Mutation$data;
  variables: ui_issueViewLayoutGroupPickerField_IssueViewGroupPickerField_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "kind": "ScalarField",
  "name": "id"
},
v2 = [
  {
    "concreteType": "JiraMutation",
    "kind": "LinkedField",
    "name": "jira",
    "plural": false,
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "JiraSingleGroupPickerFieldPayload",
        "kind": "LinkedField",
        "name": "updateSingleGroupPickerField",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "name": "success"
          },
          {
            "concreteType": "MutationError",
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "message"
              }
            ]
          },
          {
            "concreteType": "JiraSingleGroupPickerField",
            "kind": "LinkedField",
            "name": "field",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "concreteType": "JiraGroup",
                "kind": "LinkedField",
                "name": "selectedGroup",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "groupId"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "name"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "ui_issueViewLayoutGroupPickerField_IssueViewGroupPickerField_Mutation",
    "selections": (v2/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ui_issueViewLayoutGroupPickerField_IssueViewGroupPickerField_Mutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": "7214d971ca27d88233d82f95bff063776ad54f0b6b9fe256a8b3762c4b2f126b",
    "metadata": {},
    "name": "ui_issueViewLayoutGroupPickerField_IssueViewGroupPickerField_Mutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "86e37d05b84f2b5c936aaf6583d5f5f6";

export default node;
