import React, { useEffect } from 'react';
import { useExperienceFail } from '@atlassian/jira-experience-tracker/src/ui/experience-fail/index.tsx';
import { ExperienceStart } from '@atlassian/jira-experience-tracker/src/ui/experience-start/index.tsx';
import { ExperienceSuccess } from '@atlassian/jira-experience-tracker/src/ui/experience-success/index.tsx';

export const EXPERIENCE_NAME = 'issueDevOpsSummaryIcons';

type DevOpsSummaryExperienceStartProps = {
	location: string;
};

export const DevOpsSummaryExperienceStart = ({ location }: DevOpsSummaryExperienceStartProps) => (
	<ExperienceStart
		experience={EXPERIENCE_NAME}
		experienceId={location}
		analyticsSource={location}
	/>
);

export const DevOpsSummaryExperienceSuccess = () => (
	<ExperienceSuccess experience={EXPERIENCE_NAME} />
);

export const useDevOpsSummaryExperienceFail = () =>
	useExperienceFail({
		experience: EXPERIENCE_NAME,
	});

type DevOpsSummaryExperienceFailProps = {
	location: string;
	error: Error;
};

export const DevOpsSummaryExperienceFail = ({
	location,
	error,
}: DevOpsSummaryExperienceFailProps) => {
	const onFail = useDevOpsSummaryExperienceFail();
	useEffect(() => {
		onFail(location, error);
	}, [location, error, onFail]);
	return null;
};
