import isNil from 'lodash/isNil';
import type { User } from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import type { UserOptionValue } from '@atlassian/jira-issue-user-picker/src/types.tsx';

// this transformer adjusts user option value response
export const toCustomFieldValue = (value: UserOptionValue | null): User | null => {
	if (isNil(value)) {
		return null;
	}
	return {
		accountId: !isNil(value.accountId) ? value.accountId : value.id,
	};
};
