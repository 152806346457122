export const NORMALIZATION_TEMPLATE = 'norm';

/**
 * Formula to compose a number of subformulas. This can be used to create sums and products.
 */
export interface GenericNormalizationFormula<TRecursiveFormula> {
	template: typeof NORMALIZATION_TEMPLATE;
	parameters: {
		formula: TRecursiveFormula;
	};
}
