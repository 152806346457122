import React from 'react';
import Badge from '@atlaskit/badge';
import { expVal } from '@atlassian/jira-feature-experiments';
import { FormattedMessage } from '@atlassian/jira-intl';
import { Emphasise } from '@atlassian/jira-issue-view-activity-common/src/styles/index.tsx';
import messages from '../../../../common/messages.tsx';
import Change from '../../../../common/ui/change/index.tsx';
import GroupedChange from '../../../../common/ui/grouped-change/index.tsx';
import HistoryItem from '../../../../common/ui/history-item/index.tsx';
import type { NumericFieldChangedHistoryItem } from '../../../../types.tsx';

type Props = {
	isGrouped?: Boolean;
	historyItem: NumericFieldChangedHistoryItem;
};

const NumericValueHistoryItem = ({ historyItem, isGrouped = false }: Props) => {
	const { field, fieldType, actor, timestamp, fromValue, toValue } = historyItem;
	const ChangeComponent = (
		<Change
			from={fromValue !== null ? <Badge max={Infinity}>{fromValue}</Badge> : null}
			to={toValue !== null ? <Badge max={Infinity}>{toValue}</Badge> : null}
		/>
	);

	const normalizedField = fieldType === 'jira' ? field?.toLocaleLowerCase() : field;

	return (
		<FormattedMessage
			{...messages.updatedField}
			values={{
				field: <Emphasise key="field">{normalizedField}</Emphasise>,
			}}
		>
			{(...action) =>
				isGrouped && expVal('issue_view_activity_timeline', 'isActivityTimelineEnabled', false) ? (
					<GroupedChange
						field={normalizedField}
						from={fromValue !== null ? <Badge max={Infinity}>{fromValue}</Badge> : null}
						to={toValue !== null ? <Badge max={Infinity}>{toValue}</Badge> : null}
					/>
				) : (
					<HistoryItem
						// eslint-disable-next-line jira/integration/test-id-by-folder-structure
						testId="issue-history.ui.history-items.numeric-value-history-item.history-item"
						actor={actor}
						action={action}
						timestamp={timestamp}
						change={ChangeComponent}
					/>
				)
			}
		</FormattedMessage>
	);
};

export default NumericValueHistoryItem;
