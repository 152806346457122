import React from 'react';
import { styled } from '@compiled/react';
import EditorAddIcon from '@atlaskit/icon/glyph/editor/add';
import EmojiFlagsIcon from '@atlaskit/icon/glyph/emoji/flags';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconWrapper = styled.span({
	verticalAlign: 'middle',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FeatureFlagIcon = (
	<IconWrapper aria-hidden>
		<EmojiFlagsIcon size="medium" label="" />
	</IconWrapper>
);

export const AddFeatureFlagIcon = (
	<IconWrapper aria-hidden>
		<EditorAddIcon size="medium" label="" />
	</IconWrapper>
);
