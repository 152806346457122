import head from 'lodash/head';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types.tsx';

const getUpdatedState = (state: State, fieldKey: FieldKey, description: string | undefined) => ({
	...state,
	fields: {
		...state.fields,
		[fieldKey]: {
			...state.fields[fieldKey],
			description,
		},
	},
});

export const updateDescription =
	(fieldKey: string, description: string, createAnalyticsEvent: CreateUIAnalyticsEvent) =>
	async (
		{ getState, setState }: StoreActionApi<State>,
		{ onFieldUpdateFailed, issueTypeIds, fieldRemote }: Props,
	) => {
		const state = getState();
		const oldDescription = state.fields[fieldKey].description;

		if (!state.fields[fieldKey] || state.fields[fieldKey].description === description) {
			return;
		}

		const issueTypeId = head(issueTypeIds);
		if (issueTypeId === undefined) {
			return;
		}

		setState(getUpdatedState(state, fieldKey, description));

		try {
			const newFieldState = await fieldRemote.updateField({
				fieldKey,
				issueTypeId: parseInt(issueTypeId, 10),
				description,
			});
			setState(getUpdatedState(getState(), fieldKey, newFieldState.description));
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (err: any) {
			setState(getUpdatedState(getState(), fieldKey, oldDescription));

			fireCompoundAnalyticsEvent.FieldConfig.descriptionChangedFailure(createAnalyticsEvent({}));
			onFieldUpdateFailed(err);
			return;
		}

		fireCompoundAnalyticsEvent.FieldConfig.descriptionChangedSuccess(createAnalyticsEvent({}), {
			issueFieldKey: fieldKey,
			issueFieldType: state.fields[fieldKey].type,
		});
	};
