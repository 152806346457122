import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	fetchVotersListFailedTitle: {
		id: 'issue-field-voters.fetch-voters-list-failed-title',
		defaultMessage: "We couldn't load the voters list",
		description: 'Title informing the user that fetching the voters list failed',
	},
	fetchVotersListFailedDescription: {
		id: 'issue-field-voters.fetch-voters-list-failed-description',
		defaultMessage: 'Check your connection, then give it another try.',
		description: 'Description informing the user that fetching the voters list failed',
	},
	updateVoteFailedTitle: {
		id: 'issue-field-voters.update-vote-failed-title',
		defaultMessage: "We couldn't update your vote status",
		description: 'Title informing the user that updating their vote failed',
	},
	updateVoteFailedDescription: {
		id: 'issue-field-voters.update-vote-failed-description',
		defaultMessage: 'Check your connection, then try clicking vote again.',
		description: 'Description informing the user that updating their vote failed',
	},
	addVoteLabel: {
		id: 'issue-field-voters.add-vote-label',
		defaultMessage: 'Vote for this issue',
		description:
			'Clicking the button associated with this tooltip will result in the user starting to get notifications for the issue',
	},
	removeVoteLabel: {
		id: 'issue-field-voters.remove-vote-label',
		defaultMessage: 'Remove Vote',
		description:
			'Clicking the button associated with this tooltip will result in the user no longer getting notifications for the issue',
	},
	voteOptionsTooltip: {
		id: 'issue-field-voters.vote-options-tooltip',
		defaultMessage: 'Vote options',
		description:
			'Clicking the button associated with this tooltip will display a dropdown with management actions',
	},
	emptyVotersMessage: {
		id: 'issue-field-voters.empty-voters-message',
		defaultMessage: 'No one has voted for this issue',
		description: 'Message displayed when the voters list is empty',
	},
	votersListLabel: {
		id: 'issue-field-voters.voters-list-label',
		defaultMessage: 'Voted for this issue',
		description: 'Title for the dropdown item containing the voters list',
	},
	voteCountWithSelfLabel: {
		id: 'issue-field-voters.vote-count-with-self-label',
		defaultMessage:
			"Vote options: You've voted for this issue. {votes, plural, one {# person has} other {# people have}} voted.",
		description: 'Label for no of people voted with self vote',
	},
	voteCountWithoutSelfLabel: {
		id: 'issue-field-voters.vote-count-without-self-label',
		defaultMessage:
			'Vote options: {votes, plural, one {# person has} other {# people have}} voted for this issue.',
		description: 'Label for no of people voted for the issue',
	},
	noOneHasVotedLabel: {
		id: 'issue-field-voters.no-one-has-voted-label',
		defaultMessage: 'Vote options: No one has voted for this issue yet.',
		description: 'Label for no one has voted for the issue yet',
	},
	voteCountWithoutSelfLabelForToggle: {
		id: 'issue-field-voters.vote-count-without-self-label-for-toggle',
		defaultMessage:
			'Vote for this issue: {votes, plural, one {# person has} other {# people have}} voted for this issue.',
		description: 'Label for no of people voted for the issue',
	},
	noOneHasVotedLabelForToggle: {
		id: 'issue-field-voters.no-one-has-voted-label-for-toggle',
		defaultMessage: 'Vote for this issue: No one has voted for this issue yet.',
		description: 'Label for no one has voted for the issue yet',
	},
	voteCountWithSelfLabelForToggle: {
		id: 'issue-field-voters.vote-count-with-self-label-for-toggle',
		defaultMessage:
			"Remove Vote: You've voted for this issue. {votes, plural, one {# person has} other {# people have}} voted.",
		description: 'Label for no of people voted with self vote',
	},
	addVoteLabelIssueTermRefresh: {
		id: 'issue-field-voters.add-vote-label-issue-term-refresh',
		defaultMessage: 'Vote for this issue',
		description:
			'Clicking the button associated with this tooltip will result in the user starting to get notifications for the issue',
	},
	emptyVotersMessageIssueTermRefresh: {
		id: 'issue-field-voters.empty-voters-message-issue-term-refresh',
		defaultMessage: 'No one has voted for this issue',
		description: 'Message displayed when the voters list is empty',
	},
	votersListLabelIssueTermRefresh: {
		id: 'issue-field-voters.voters-list-label-issue-term-refresh',
		defaultMessage: 'Voted for this issue',
		description: 'Title for the dropdown item containing the voters list',
	},
	voteCountWithSelfLabelIssueTermRefresh: {
		id: 'issue-field-voters.vote-count-with-self-label-issue-term-refresh',
		defaultMessage:
			"Vote options: You've voted for this issue. {votes, plural, one {# person has} other {# people have}} voted.",
		description: 'Label for no of people voted with self vote',
	},
	voteCountWithoutSelfLabelIssueTermRefresh: {
		id: 'issue-field-voters.vote-count-without-self-label-issue-term-refresh',
		defaultMessage:
			'Vote options: {votes, plural, one {# person has} other {# people have}} voted for this issue.',
		description: 'Label for no of people voted for the issue',
	},
	noOneHasVotedLabelIssueTermRefresh: {
		id: 'issue-field-voters.no-one-has-voted-label-issue-term-refresh',
		defaultMessage: 'Vote options: No one has voted for this issue yet.',
		description: 'Label for no one has voted for the issue yet',
	},
	voteCountWithoutSelfLabelForToggleIssueTermRefresh: {
		id: 'issue-field-voters.vote-count-without-self-label-for-toggle-issue-term-refresh',
		defaultMessage:
			'Vote for this issue: {votes, plural, one {# person has} other {# people have}} voted for this issue.',
		description: 'Label for no of people voted for the issue',
	},
	noOneHasVotedLabelForToggleIssueTermRefresh: {
		id: 'issue-field-voters.no-one-has-voted-label-for-toggle-issue-term-refresh',
		defaultMessage: 'Vote for this issue: No one has voted for this issue yet.',
		description: 'Label for no one has voted for the issue yet',
	},
	voteCountWithSelfLabelForToggleIssueTermRefresh: {
		id: 'issue-field-voters.vote-count-with-self-label-for-toggle-issue-term-refresh',
		defaultMessage:
			"Remove Vote: You've voted for this issue. {votes, plural, one {# person has} other {# people have}} voted.",
		description: 'Label for no of people voted with self vote',
	},
});
