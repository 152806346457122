import { MAP_FROM_SERVER_COLOR } from '@atlassian/jira-issue-epic-color/src/common/constants.tsx';
import type { ServerColor, Color } from '@atlassian/jira-issue-epic-color/src/common/types.tsx';
import { PLAIN_TEXT } from '@atlassian/jira-issue-shared-types/src/common/types/field-schema-type.tsx';
import type {
	Field,
	FieldsState,
} from '@atlassian/jira-issue-shared-types/src/common/types/field-type.tsx';
import type { IssueType } from '@atlassian/jira-issue-shared-types/src/common/types/issue-hierarchy-type.tsx';
import type {
	ParentIssue,
	ServerParentIssue,
	ServerParentLink,
} from '@atlassian/jira-issue-shared-types/src/common/types/parent-issue-type.tsx';
import type { ProjectContext } from '@atlassian/jira-issue-shared-types/src/common/types/project-type.tsx';
import {
	ISSUE_FIELD_ANY_CF_TYPE,
	ISSUE_FIELD_MULTI_SELECT_CF_TYPE,
	ISSUE_LINKS_TYPE,
	PARENT_TYPE,
	PARENT_CF_TYPE,
	ISSUE_TYPE_GQL_FIELD,
	PARENT_GQL_FIELD,
	TEXT_GQL_FIELD,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import { toIssueId, toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { AggParentIssue } from '../types/issue-type.tsx';
import type {
	AggFieldsTransformerFunction,
	AggTransformerFunction,
} from '../types/transformer-type.tsx';
import { transformAggNodeToLegacyField } from './default-node-transformer.tsx';
import { transformAggFieldsToLegacyFields } from './fields-transformer.tsx';
import { transformAggProjectToProjectContext } from './project-field-transformer.tsx';

const getIssueTypeFieldValue = (parentIssue: AggParentIssue, fields: FieldsState): IssueType => {
	const issueType = fields.issuetype?.value;

	if (issueType == null) {
		throw new Error(
			`Failed to transform parent issue: ${ISSUE_TYPE_GQL_FIELD} field not found [${JSON.stringify(
				parentIssue,
			)}]`,
		);
	}

	return issueType;
};

const getSummaryFieldValue = (parentIssue: AggParentIssue, fields: FieldsState): string => {
	const summary = fields.summary?.value;

	if (summary == null) {
		throw new Error(
			`Failed to transform parent issue: ${TEXT_GQL_FIELD} field not found [${JSON.stringify(
				parentIssue,
			)}]`,
		);
	}

	return summary;
};

const transformAggParentIssueToServerParentIssue = (
	parentIssue: AggParentIssue | null,
	fields: FieldsState,
): ServerParentIssue | null => {
	if (parentIssue == null) return null;

	const colorMapped: Color | undefined =
		!parentIssue.issueColorField?.color?.colorKey ||
		parentIssue.issueColorField?.color?.colorKey === ''
			? undefined
			: // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				MAP_FROM_SERVER_COLOR[parentIssue.issueColorField?.color?.colorKey as ServerColor];

	return {
		id: parentIssue.issueId,
		key: toIssueKey(parentIssue.key),
		self: parentIssue.webUrl ?? '',
		fields: {
			issuetype: getIssueTypeFieldValue(parentIssue, fields),
			summary: getSummaryFieldValue(parentIssue, fields),
		},
		color: colorMapped,
	};
};

const transformAggParentIssueToServerParentLink = (
	parentIssue: AggParentIssue | null,
	fields: FieldsState,
	project: ProjectContext | null,
	parentVisibility:
		| {
				readonly canUseParentLinkField: boolean | null | undefined;
				readonly hasEpicLinkFieldDependency: boolean | null | undefined;
		  }
		| null
		| undefined,
): ServerParentLink => {
	const parentLinkVisibilityValue = {
		hasEpicLinkFieldDependency: parentVisibility?.hasEpicLinkFieldDependency ?? false,
		showField: parentVisibility?.canUseParentLinkField ?? false,
	};

	if (parentIssue == null || project == null) {
		return parentLinkVisibilityValue;
	}

	const issueType = getIssueTypeFieldValue(parentIssue, fields);

	return {
		...parentLinkVisibilityValue,
		data: {
			id: Number(parentIssue.issueId),
			key: parentIssue.key,
			projectId: Number(project.projectId),
			summary: getSummaryFieldValue(parentIssue, fields),
			issueType: {
				id: Number(issueType.id),
				iconUrl: issueType.iconUrl,
			},
		},
	};
};

const transformAggParentIssueToParentLinkField = (
	field: Field,
	parentIssue: AggParentIssue | null,
	parentIssueFields: FieldsState,
	project: ProjectContext | null,
	parentVisibility:
		| {
				readonly canUseParentLinkField: boolean | null | undefined;
				readonly hasEpicLinkFieldDependency: boolean | null | undefined;
		  }
		| null
		| undefined,
): FieldsState => ({
	[field.key]: {
		...field,
		value: transformAggParentIssueToServerParentLink(
			parentIssue,
			parentIssueFields,
			project,
			parentVisibility,
		),
		schema: {
			configuration: null,
			items: null,
			renderer: PLAIN_TEXT,
			type: ISSUE_FIELD_ANY_CF_TYPE,
			custom: PARENT_CF_TYPE,
			system: null,
		},
	},
});

const transformAggParentIssueToParentField = (
	field: Field,
	parentIssue: AggParentIssue | null,
	parentIssueFields: FieldsState,
): FieldsState => ({
	[PARENT_TYPE]: {
		...field,
		key: PARENT_TYPE, // `field.key` might be from parent link
		title: field.title,
		value: transformAggParentIssueToServerParentIssue(parentIssue, parentIssueFields),
		schema: {
			configuration: null,
			items: ISSUE_LINKS_TYPE,
			renderer: null,
			type: ISSUE_FIELD_MULTI_SELECT_CF_TYPE,
			custom: null,
			system: PARENT_TYPE,
		},
	},
});

export const transformAggParentIssueToLegacyFields: AggTransformerFunction<
	typeof PARENT_GQL_FIELD
> = (node) => {
	const parentIssue = node.parentIssue ?? null;
	const parentIssueFields = parentIssue?.fieldsById?.edges ?? null;

	const fieldsState = transformAggFieldsToLegacyFields(parentIssueFields);

	const projectContext = parentIssueFields
		? transformAggProjectToProjectContext(parentIssueFields)
		: null;

	const field = transformAggNodeToLegacyField(node);

	// This field is present in all parent issue types, and will be solely used after the hierarchy unification.
	const unifiedParentField = transformAggParentIssueToParentField(field, parentIssue, fieldsState);

	switch (node.type) {
		// Parent link for portfolio child issues (epics)
		case PARENT_CF_TYPE:
			return {
				...unifiedParentField,
				...transformAggParentIssueToParentLinkField(
					field,
					parentIssue,
					fieldsState,
					projectContext,
					node.parentVisibility,
				),
			};

		case PARENT_TYPE:
		default:
			return unifiedParentField;
	}
};

export const transformAggFieldsToLegacyParentFields: AggFieldsTransformerFunction = (fields) => {
	const parentFields: FieldsState = {};

	if (fields != null) {
		// There can be multiple parent link fields, but they all use the same parent value
		fields.forEach((field) => {
			const node = field?.node;

			if (node != null && node.__typename === PARENT_GQL_FIELD) {
				Object.assign(parentFields, transformAggParentIssueToLegacyFields(node));
			}
		});
	}

	return parentFields;
};

export const transformServerParentFieldsToParentIssue = (
	fields: FieldsState,
): ParentIssue | null => {
	const parentIssue = fields[PARENT_TYPE]?.value;

	if (parentIssue == null) return null;

	return {
		id: toIssueId(parentIssue.id),
		key: toIssueKey(parentIssue.key),
		summary: parentIssue.fields.summary,
		issueTypeName: parentIssue.fields.issuetype?.name ?? '',
		issueTypeIconUrl: parentIssue.fields.issuetype?.iconUrl ?? '',
	};
};
