import { LOCAL } from '../../common/constants.tsx';

const MAX_RESULTS = 10;
const CACHE_SIZE = MAX_RESULTS * 2;
const VERSIONS_CACHE_KEY = 'versions';

export const props = {
	size: CACHE_SIZE,
	cacheKey: VERSIONS_CACHE_KEY,
	maxAge: 1000 * 60 * 60 * 24 * 7,
	storageType: LOCAL,
} as const;
