import type { JsonLd } from 'json-ld-types';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import { traverse } from '@atlaskit/adf-utils/traverse';
import type { CardContext } from '@atlaskit/link-provider';
import { useProjectSidebarNavigation } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/common/project-sidebar-navigation-resource/index.tsx';
import { createAri } from '@atlassian/jira-platform-ari/src/index.tsx';
import {
	CONFLUENCE,
	CORE,
	PRODUCT_DISCOVERY,
	SERVICE_DESK,
	SOFTWARE,
} from '@atlassian/jira-shared-types/src/application-key.tsx';

export const SUPPORTED_PRODUCTS = {
	[CONFLUENCE]: 'Confluence',
	[PRODUCT_DISCOVERY]: 'Jira Product Discovery',
};

export type Product = keyof typeof SUPPORTED_PRODUCTS;

const getProductFromSubproduct = (subproduct?: string) => {
	switch (subproduct) {
		case 'software':
		case 'jira_software':
			return SOFTWARE;
		case 'servicedesk':
		case 'jira_service_management':
			return SERVICE_DESK;
		case 'core':
		case 'jira_work_management':
			return CORE;
		case 'polaris':
		case 'jira_product_discovery':
			return PRODUCT_DISCOVERY;
		default:
			return null;
	}
};

export const isConfluenceLink = (url?: string, cloudName?: string): url is string => {
	if (!url || !cloudName) return false;
	const regex = new RegExp(`^https?://${cloudName}.atlassian.net/wiki`);
	return regex.test(url);
};

export type LinkData = {
	name?: string;
	tenantId: string;
	product: Product;
};

export const getAllLinks = (doc: ADF) => {
	const links = new Set<string>();

	const handleLink = (link?: string) => {
		link && links.add(link);
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleSmartLink = (node: any) => handleLink(node.attrs?.url);

	traverse(doc, {
		text: (node) => {
			if ((node.marks || []).some((mark) => mark.type === 'link')) {
				handleLink(node.text);
			}
		},
		inlineCard: handleSmartLink,
		blockCard: handleSmartLink,
		embedCard: handleSmartLink,
	});

	return links;
};

const getSmartLinkData = (cloudId: string, details?: JsonLd.Response) => {
	if (!details) return;

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const { name } = details.data as JsonLd.Data.BaseData;
	const { tenantId, subproduct } = details.meta;
	let { product } = details.meta;

	if (tenantId !== cloudId) return;

	product = product === 'jira' ? getProductFromSubproduct(subproduct) : product;
	if (!Object.keys(SUPPORTED_PRODUCTS).includes(product)) return;

	return { name, tenantId, product };
};

export const getAllProductLinks = (
	doc: ADF,
	store: CardContext['store'],
	cloudId: string,
	cloudName?: string,
) => {
	const links: Record<string, LinkData> = {};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleSmartLink = (node: any) => {
		// Check URL is on the ADF node
		const { url } = node?.attrs;
		if (!url) return;

		// Check URL is in the Smart Links Store
		const details = store.getState()[url]?.details;
		const data = getSmartLinkData(cloudId, details);

		if (data) links[url] = data;
	};

	const handleRawConfluenceLink = (url?: string) => {
		if (isConfluenceLink(url, cloudName)) {
			links[url] = {
				tenantId: cloudId,
				product: CONFLUENCE,
			};
		}
	};

	traverse(doc, {
		text: (node) => {
			if ((node.marks || []).some((mark) => mark.type === 'link')) {
				handleRawConfluenceLink(node.text);
			}
		},
		inlineCard: handleSmartLink,
		blockCard: handleSmartLink,
		embedCard: handleSmartLink,
	});

	return links;
};

let id = 0;
export const generateId = () => {
	id += 1;
	return String(id);
};

export const clampString = (string: string, maxLength: number): string => {
	if (string.length > maxLength) {
		return string.slice(0, maxLength).concat('...');
	}
	return string;
};

export const getProductDisplayName = (product: Product) => SUPPORTED_PRODUCTS[product];

export const getSiteAri = (tenantId: string, product: Product) =>
	createAri({
		resourceOwner: product,
		resourceType: 'site',
		resourceId: tenantId,
	}).toString();

export const useCurrentProduct = () => {
	const { data } = useProjectSidebarNavigation();
	return getProductFromSubproduct(data?.projectType) || SOFTWARE;
};
