/**
 * @generated SignedSource<<b973f7193843497a07c1470c89d06f40>>
 * @relayHash 61fe91a04aa18abb03fb45259643fc02
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 7ba7f55b9606c6306f9ffee483513fccb037383f6a29e7aa236eb5cbac386518

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type createReleaseModalQuery$variables = {
  accountId: string;
};
export type createReleaseModalQuery$data = {
  readonly user: {
    readonly " $fragmentSpreads": FragmentRefs<"createReleaseForm_softwareReleasesReleaseModalsRelay">;
  };
};
export type createReleaseModalQuery = {
  response: createReleaseModalQuery$data;
  variables: createReleaseModalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "accountId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "accountId",
    "variableName": "accountId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "createReleaseModalQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "args": (v1/*: any*/),
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            {
              "kind": "FragmentSpread",
              "name": "createReleaseForm_softwareReleasesReleaseModalsRelay"
            }
          ]
        },
        "action": "THROW",
        "path": "user"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createReleaseModalQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "name": "__typename"
          },
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isUser"
          },
          {
            "kind": "ScalarField",
            "name": "accountId"
          },
          {
            "kind": "ScalarField",
            "name": "name"
          },
          {
            "kind": "ScalarField",
            "name": "picture"
          },
          {
            "kind": "ScalarField",
            "name": "id"
          }
        ]
      }
    ]
  },
  "params": {
    "id": "7ba7f55b9606c6306f9ffee483513fccb037383f6a29e7aa236eb5cbac386518",
    "metadata": {},
    "name": "createReleaseModalQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "9f85087ab53fe21f6df07185269b323d";

export default node;
