import type { OptionalNullable } from '../types/types.tsx';

export type Connection<TNode> = {
	readonly edges?: OptionalNullable<
		ReadonlyArray<
			OptionalNullable<{
				readonly node?: OptionalNullable<TNode>;
			}>
		>
	>;
} | null;

export function transformEdgesToTransformedNodes<TNode, TTransformedNode>(
	conn: OptionalNullable<Connection<TNode>>,
	transformNode: (node: TNode) => TTransformedNode,
): TTransformedNode[] {
	const nodes: TTransformedNode[] = [];

	return (
		conn?.edges?.reduce((acc, edge) => {
			if (edge?.node) {
				acc.push(transformNode(edge.node));
			}

			return acc;
		}, nodes) ?? []
	);
}
