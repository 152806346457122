import has from 'lodash/has';
import type { Insight } from '@atlassian/jira-polaris-domain-insight/src/insight/types.tsx';
import type { InsightsRemote } from '@atlassian/jira-polaris-remote-insight/src/types.tsx';
import { getLocalIssueIdsByJiraIssueId } from '../../../selectors/issue-ids.tsx';
import type { Props, State } from '../../../types.tsx';

export const getInsightTransformed = (
	insightsRemote: InsightsRemote,
	insightIdAsNumber: number,
	jiraIssueId: number,
	state: State,
	props: Props,
): Promise<Insight | undefined> => {
	const localIdMap = getLocalIssueIdsByJiraIssueId(state, props);

	if (!has(localIdMap, jiraIssueId) || !insightsRemote.fetchInsight) {
		return Promise.resolve(undefined);
	}

	return insightsRemote.fetchInsight({ id: String(insightIdAsNumber) });
};
