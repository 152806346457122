import type { Action } from '@atlassian/react-sweet-state';
import type { State, Props, ViewSharingStakeholders, ViewSharingSettings } from '../types.tsx';

export const setSharingSettingsForView =
	(viewUUID: string, sharing: Partial<ViewSharingSettings> = {}): Action<State, Props> =>
	({ getState, setState }) => {
		const state = getState();
		const viewSharingToUpdate = state.sharingSettings[viewUUID];

		setState({
			sharingSettings: {
				...state.sharingSettings,
				[viewUUID]: {
					isLoading: sharing.isLoading || false,
					settings: sharing.settings || viewSharingToUpdate?.settings,
					error: sharing.error,
				},
			},
		});
	};

export const setSharingStakeholdersForView =
	(viewUUID: string, sharing: Partial<ViewSharingStakeholders> = {}): Action<State, Props> =>
	({ getState, setState }) => {
		const state = getState();
		const viewSharingToUpdate = state.sharingStakeholders[viewUUID];

		setState({
			sharingStakeholders: {
				...state.sharingStakeholders,
				[viewUUID]: {
					isLoading: sharing.isLoading || false,
					stakeholders: sharing.stakeholders || viewSharingToUpdate?.stakeholders,
					error: sharing.error,
				},
			},
		});
	};
