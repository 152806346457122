import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	sectionTitle: {
		id: 'autodev-draft-pr.create-pr-dropdown.create-pr-dropdown-content.section-title',
		defaultMessage: 'FF Cleanup Agent',
		description:
			'Title of the FF (Feature Flag) action on the right hand side of the issue view, as well as the title of the modal that pops up when the action is clicked',
	},
	alpha: {
		id: 'autodev-draft-pr.create-pr-dropdown.create-pr-dropdown-content.alpha',
		defaultMessage: 'Alpha',
		description:
			'Status shown next to the title of the FF Cleanup Agent modal, indicating that the feature is in Alpha state',
	},
	errorMessageTitle: {
		id: 'autodev-draft-pr.create-pr-dropdown.create-pr-dropdown-content.error-message-title',
		defaultMessage: 'Job failed',
		description:
			'Section title for failure message which is shown when the Create Pull Request action has failed',
	},
	successMessageTitleNonFinal: {
		id: 'autodev-draft-pr.create-pr-dropdown.create-pr-dropdown-content.success-message-title-non-final',
		defaultMessage: 'Job succeeded',
		description:
			'Section title for failure message which is shown when the Create Pull Request action has failed',
	},
	errorMessage: {
		id: 'autodev-draft-pr.create-pr-dropdown.create-pr-dropdown-content.error-message',
		// eslint-disable-next-line @atlassian/tangerine/i18n/messages-are-spelled-correctly
		defaultMessage: 'Try again or contact #help-autodev on Slack',
		description: 'Error message which is shown when the Create Pull Request action has failed',
	},
	repoAccessErrorMessageNonFinal: {
		id: 'autodev-draft-pr.create-pr-dropdown.create-pr-dropdown-content.repo-access-error-message-non-final',
		defaultMessage:
			'Feature Flag Cleanup Agent did not have access to the following Bitbucket Cloud repository: {repo}.',
		description: 'Error message which is shown when the Create Pull Request action has failed',
	},
	repoAccessErrorMessageInstructionsNonFinal: {
		id: 'autodev-draft-pr.create-pr-dropdown.create-pr-dropdown-content.repo-access-error-message-instructions-non-final',
		defaultMessage:
			'If you already requested access, please try again. Otherwise, see the {faq} to request access.',
		description: 'Error message which is shown when the Create Pull Request action has failed',
	},
	faqNonFinal: {
		id: 'autodev-draft-pr.create-pr-dropdown.create-pr-dropdown-content.faq-non-final',
		defaultMessage: 'faq',
		description: 'Error message which is shown when the Create Pull Request action has failed',
	},
	instructionalText: {
		id: 'autodev-draft-pr.create-pr-dropdown.create-pr-dropdown-content.instructional-text',
		defaultMessage:
			'The FF Cleanup Agent will create a pull request for you that removes stale feature flags, as specified in the issue description.',
		description:
			'This is the instruction explaining what the Create Pull Request action needs to do and is displayed in the FF Cleanup Agent popup',
	},
	learnMoreText: {
		id: 'autodev-draft-pr.create-pr-dropdown.create-pr-dropdown-content.learn-more-text',
		defaultMessage: 'Learn more about the project',
		description:
			'Link on the FF Cleanup Agent popup that will link out to a confluence page explaining how the FF Cleanup Agent works/any limitations',
	},
	createPrButtonText: {
		id: 'autodev-draft-pr.create-pr-dropdown.create-pr-dropdown-content.create-pr-button-text',
		defaultMessage: 'Create Pull Request',
		description:
			'This is the cta for the Create Pull Request button that is displayed in the FF Cleanup Agent popup',
	},
	retryButtonText: {
		id: 'autodev-draft-pr.create-pr-dropdown.create-pr-dropdown-content.retry-button-text',
		defaultMessage: 'Retry',
		description:
			'This is the text for the Retry button that is displayed after the Create Pull Request action fails',
	},
	regenerateButtonTextNonFinal: {
		id: 'autodev-draft-pr.create-pr-dropdown.create-pr-dropdown-content.regenerate-button-text-non-final',
		defaultMessage: 'Regenerate PR',
		description:
			'This is the text for the Retry button that is displayed after the Create Pull Request action fails',
	},
	nonEligibleIssue: {
		id: 'autodev-draft-pr.create-pr-dropdown.create-pr-dropdown-content.non-eligible-issue',
		defaultMessage:
			'Unfortunately this issue is not currently eligible for the Feature Flag Cleanup Agent',
		description:
			'This is an informational text displayed when the FF Cleanup Agent is not suitable/eligible to be run on the issue',
	},
	instructionalTextIssueTermRefresh: {
		id: 'autodev-draft-pr.create-pr-dropdown.create-pr-dropdown-content.instructional-text-issue-term-refresh',
		defaultMessage:
			'The FF Cleanup Agent will create a pull request for you that removes stale feature flags, as specified in the issue description.',
		description:
			'This is the instruction explaining what the Create Pull Request action needs to do and is displayed in the FF Cleanup Agent popup',
	},
	nonEligibleIssueIssueTermRefresh: {
		id: 'autodev-draft-pr.create-pr-dropdown.create-pr-dropdown-content.non-eligible-issue-issue-term-refresh',
		defaultMessage:
			'Unfortunately this issue is not currently eligible for the Feature Flag Cleanup Agent',
		description:
			'This is an informational text displayed when the FF Cleanup Agent is not suitable/eligible to be run on the issue',
	},
});
