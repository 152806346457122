import { defaultLocale } from '@atlassian/jira-common-constants/src/supported-locales.tsx';
import type { ContextState } from '@atlassian/jira-issue-view-common-types/src/context-type.tsx';
import {
	getIssueConfiguration,
	type ProjectConfig,
} from '@atlassian/jira-issue-view-configurations/src/index.tsx';
import { populateApplicationEditions } from '@atlassian/jira-shared-types/src/edition.tsx';
import {
	toBaseUrl,
	toIssueKey,
	toIssueId,
	type IssueId,
} from '@atlassian/jira-shared-types/src/general.tsx';
import { populateApplicationLicenseStates } from '@atlassian/jira-shared-types/src/license-state.tsx';
import { populateApplicationPermissions } from '@atlassian/jira-shared-types/src/tenant-context.tsx';
import { type SetContext, SET_CONTEXT } from '../actions/context-actions.tsx';
import { type LoadNewIssueAction, LOAD_NEW_ISSUE } from '../actions/issue-navigation-actions.tsx';
import { type LoginSuccessfulAction, LOGIN_SUCCESSFUL } from '../actions/login-actions.tsx';
import {
	type PersonalizationTapTraitFetchAction,
	PERSONALIZATION_TAP_TRAIT_FETCHED,
} from '../actions/personalization-fetch-tap-trait-action.tsx';
import {
	type PersonalizationTapTraitConfluenceActivationFetchAction,
	PERSONALIZATION_TAP_TRAIT_CONFLUENCE_ACTIVATION_FETCHED,
} from '../actions/personalization-fetch-tap-trait-confluence-activation-action.tsx';
import {
	FETCH_ISSUE_SUCCESS,
	REFRESH_ISSUE_SUCCESS,
} from '../common/actions/issue-fetch-actions.tsx';
import {
	PREVIEW_DATA_FETCHED,
	type PreviewDataFetchedAction,
} from '../issue-field/state/actions/field-preview-actions.tsx';

type Action =
	| SetContext
	| LoadNewIssueAction
	| LoginSuccessfulAction
	| PreviewDataFetchedAction
	| PersonalizationTapTraitFetchAction
	| PersonalizationTapTraitConfluenceActivationFetchAction;

const getInitialState = (): ContextState => ({
	applicationKey: '',
	issueActions: [],
	atlassianAccountId: null,
	baseUrl: toBaseUrl(''),
	isAnonymous: false,
	issueKey: toIssueKey(''),
	isModal: false,
	cloudId: null,
	activationId: null,
	locale: defaultLocale,
	maxTokenLength: 0,
	ecosystemEnabled: null,
	isLoadedWithPage: null,
	rapidViewMap: null,
	estimationStatistic: null,
	licensedProducts: {},
	appPermissions: populateApplicationPermissions(),
	appEditions: populateApplicationEditions(),
	licenseStates: populateApplicationLicenseStates(),
	siteAdminStatus: 'unknown',
	productCrossSellConfig: 'unknown',
	isLoading: false,
	viewMode: null,
	firstActivationDateMs: null,
	viewScreenId: undefined,
	hasShownIntentToXflowToConfluence: false,
	confluenceActivationDate: undefined,
});

const getConfiguration = ({
	state,
	projectConfig,
	issueId,
}: {
	state: ContextState;
	projectConfig: ProjectConfig;
	issueId: IssueId;
}) => {
	const config = getIssueConfiguration(projectConfig);

	const issueConfiguration = {
		...state,
		issueId,
		applicationKey: config.applicationKey,
		issueActions: config.issueActions,
		ecosystemEnabled: config.ecosystemEnabled,
	};

	return issueConfiguration;
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: ContextState = getInitialState(), action: Action) => {
	// noinspection JSUnreachableSwitchBranches
	switch (action.type) {
		case SET_CONTEXT: {
			const { payload: context } = action;
			return {
				...state,
				...context,
			};
		}
		case LOAD_NEW_ISSUE:
			return {
				...state,
				issueKey: action.payload.issueKey,
			};
		case LOGIN_SUCCESSFUL:
			return {
				...state,
				// @ts-expect-error - TS2339 - Property 'accountId' does not exist on type '{ issueKey: string; } | { username?: string | undefined; accountId: string; }'.
				atlassianAccountId: action.payload.accountId,
			};
		case PREVIEW_DATA_FETCHED: {
			const { payload: issue } = action;
			// @ts-expect-error - TS2339 - Property 'id' does not exist on type 'Issue | { issueKey: string; }'. | TS2339 - Property 'fields' does not exist on type 'Issue | { issueKey: string; }'.
			if (issue && issue.id && issue.fields.has('project')) {
				// @ts-expect-error - TS2339 - Property 'id' does not exist on type 'Issue | { issueKey: string; }'.
				const { id: issueId } = issue;

				// @ts-expect-error - TS2339 - Property 'fields' does not exist on type 'Issue | { issueKey: string; }'.
				const projectConfig = issue.fields.get('project').value;
				return getConfiguration({
					state,
					projectConfig,
					issueId: toIssueId(issueId),
				});
			}
			return state;
		}
		case REFRESH_ISSUE_SUCCESS:
		case FETCH_ISSUE_SUCCESS: {
			// @ts-expect-error - TS2339 - Property 'project' does not exist on type '{ issueKey: string; }'.
			const projectConfig = action.payload.project;
			// @ts-expect-error - TS2339 - Property 'issue' does not exist on type '{ issueKey: string; }'.
			const issueId = action.payload.issue.id;

			return getConfiguration({
				state,
				projectConfig,
				issueId,
			});
		}
		case PERSONALIZATION_TAP_TRAIT_FETCHED: {
			if ('hasShownIntentToXflowToConfluenceTrait' in action.payload) {
				return {
					...state,
					hasShownIntentToXflowToConfluence: action.payload.hasShownIntentToXflowToConfluenceTrait,
				};
			}
			return state;
		}
		case PERSONALIZATION_TAP_TRAIT_CONFLUENCE_ACTIVATION_FETCHED: {
			if ('confluenceActivationDate' in action.payload) {
				return {
					...state,
					confluenceActivationDate: action.payload.confluenceActivationDate,
				};
			}
			return state;
		}
		default:
			return state;
	}
};
