import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	statusNameButtonLabel: {
		id: 'issue-field-status.status-view.transition.configure-status.status-name-button-label',
		defaultMessage: 'Edit {statusName} status',
		description:
			'Label (used by screen readers) of the status name button that can be clicked to edit the status name',
	},
	statusEditNameTooltip: {
		id: 'issue-field-status.status-view.transition.configure-status.status-edit-name-tooltip',
		defaultMessage: 'Edit name',
		description: 'Tooltip of the status name button that can be clicked to edit the status name',
	},
	editStatusTooltip: {
		id: 'issue-field-status.status-view.transition.configure-status.edit-status-tooltip',
		defaultMessage: 'Edit status',
		description: 'Tooltip of the button that can be clicked to edit the status',
	},
	createStatus: {
		id: 'issue-field-status.status-view.configure-status.create-status',
		defaultMessage: 'Create status',
		description: 'Create status button visible text',
	},
});
