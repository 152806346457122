import { createStore, createHook, type Action } from '@atlassian/react-sweet-state';

export type State = {
	isOpen: boolean;
};

export const initialState: State = {
	isOpen: false,
};

export const actions = {
	openExportConfluenceDialog:
		(): Action<State> =>
		({ setState }) => {
			setState({
				isOpen: true,
			});
		},
	closeExportConfluenceDialog:
		(): Action<State> =>
		({ setState }) => {
			setState({
				isOpen: false,
			});
		},
} as const;

export type Actions = typeof actions;

const store = createStore<State, Actions>({
	name: 'issue-view.issue-actions.export-confluence-modal',
	initialState,
	actions,
});

export const useExportConfluenceDialog = createHook(store);
