import React, { forwardRef, useContext, useEffect, useMemo } from 'react';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { JsmSmartRequestSummaryEntryPointContext } from '@atlassian/jira-issue-view-smart-request-summary-entrypoint/src/index.tsx';
import useMergeRefs from '@atlassian/jira-merge-refs/src/index.tsx';
import { jsmSmartRequestSummaryTriggerEntryPoint } from '@atlassian/jira-servicedesk-smart-request-summary-trigger/entrypoint.tsx';

const entryPointParams = {};
export const JsmSmartRequestSummaryTrigger = forwardRef<
	HTMLButtonElement,
	{ isCompact: boolean; totalComments: number }
>(({ isCompact, totalComments }, ref) => {
	// The button is the trigger for loading the JsmSmartRequestSummary panel through this entrypoint context
	const jsmSmartRequestSummaryEntryPoint = useContext(JsmSmartRequestSummaryEntryPointContext);
	const jsmSmartRequestSummaryEntryPointActions =
		jsmSmartRequestSummaryEntryPoint.entryPointActions;

	const entryPointTriggerRef = useEntryPointButtonTrigger(
		jsmSmartRequestSummaryEntryPoint.entryPointActions,
	);

	const triggerRef = useMergeRefs(ref, entryPointTriggerRef);

	// Button entrypoint - we load this in asynchronously
	const { entryPointReferenceSubject, entryPointActions } = useEntryPoint(
		jsmSmartRequestSummaryTriggerEntryPoint,
		entryPointParams,
	);

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		entryPointActions.load();
	}, [entryPointActions]);

	const runtimeProps = useMemo(
		() => ({ totalComments, triggerRef, isCompact, jsmSmartRequestSummaryEntryPointActions }),
		[totalComments, isCompact, triggerRef, jsmSmartRequestSummaryEntryPointActions],
	);

	return (
		<JiraEntryPointContainer
			entryPointReferenceSubject={entryPointReferenceSubject}
			id="jsmSmartRequestSummaryTrigger"
			packageName="issueActivityFeed"
			teamName="itsm-grimlock"
			fallback={null}
			errorFallback="flag"
			runtimeProps={runtimeProps}
		/>
	);
});
