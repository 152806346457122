import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	show: {
		id: 'smart-related-issues-panel.header.expander.show',
		defaultMessage: 'Show',
		description: 'Show label for the related issues panel in JSM Issue view.',
	},
	newShow: {
		id: 'smart-related-issues-panel.header.expander.new-show',
		defaultMessage: 'Similar requests expandable section',
		description: 'Show label for the related issues panel in JSM Issue view.',
	},
	hide: {
		id: 'smart-related-issues-panel.header.expander.hide',
		defaultMessage: 'Hide',
		description: 'Hide label for the related issues panel in JSM Issue view.',
	},
});
