import React from 'react';
import { AsyncDevPanelEmptyStateModal } from '@atlassian/jira-development-empty-state-modal/src/async.tsx';
import { useIsModal, useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { useQueryParam } from '@atlassian/jira-software-router-utils/src/services/query-param/index.tsx';
import { isSimplifiedProjectSelector } from '../../../controllers/context/selectors/index.tsx';
import {
	closeEmptyStateDialog,
	fetchDevPanelRequest,
	showEmptyStateDialog,
} from '../../../controllers/dev-summary/actions/index.tsx';
import { isEmptyStateDialogShownSelector } from '../../../controllers/dev-summary/selectors/index.tsx';
import type { Props, StateProps, DispatchProps } from './types.tsx';

export const hideLegacyIssueView = () => {
	// be prepared for the worst code.

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const container = document.getElementById('ghx-issue-fragment');

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	if (window.AJS) {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.AJS.$(container).trigger('minimise-before-crossflow');
	}
};

export const showLegacyIssueView = () => {
	// be prepared for the worst code.

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const container = document.getElementById('ghx-issue-fragment');

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	if (window.AJS) {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.AJS.$(container).trigger('expand-after-crossflow');
	}
};

export const EmptyStateDialogView = ({
	onCloseEmptyStateDialog,
	onShowEmptyStateDialog,
	isClassicProject,
	isEmptyStateDialogShown,
	onCompleteConnection,
}: Props) => {
	const issueKey = useIssueKey();

	const isModal = useIsModal();
	const [, updateSelectedIssueQueryParam] = useQueryParam('selectedIssue');

	const closeEmptyState = () => {
		onCloseEmptyStateDialog();
	};
	const showEmptyState = () => {
		onShowEmptyStateDialog();
	};
	const closeModalIssue = () => {
		if (isModal) {
			if (isClassicProject) {
				hideLegacyIssueView();
			}
			updateSelectedIssueQueryParam();
		}
	};
	const showModalIssue = () => {
		if (isModal) {
			if (isClassicProject) {
				showLegacyIssueView();
			}
			updateSelectedIssueQueryParam(issueKey);
		}
	};
	const onCrossFlowStart = () => {
		closeEmptyState();
		closeModalIssue();
	};
	const onCrossFlowEnd = (success: boolean) => {
		showModalIssue();
		if (success) {
			showEmptyState();
		}
	};

	return isEmptyStateDialogShown ? (
		<AsyncDevPanelEmptyStateModal
			onCloseModal={closeEmptyState}
			onComplete={onCompleteConnection}
			onCrossFlowStart={onCrossFlowStart}
			onCrossFlowEnd={onCrossFlowEnd}
		/>
	) : null;
};

export default connect(
	(state): StateProps => ({
		// @ts-expect-error - TS2345 - Argument of type '{}' is not assignable to parameter of type 'State'.
		isEmptyStateDialogShown: isEmptyStateDialogShownSelector(state),
		// @ts-expect-error - TS2345 - Argument of type '{}' is not assignable to parameter of type 'State'.
		isClassicProject: !isSimplifiedProjectSelector(state),
	}),
	(dispatch): DispatchProps => ({
		onCloseEmptyStateDialog: () => dispatch(closeEmptyStateDialog()),
		onShowEmptyStateDialog: () => dispatch(showEmptyStateDialog()),
		onCompleteConnection: () => {
			dispatch(fetchDevPanelRequest());
		},
	}),
)(EmptyStateDialogView);
