import React from 'react';
import DevDetailsDialogLoader from '@atlassian/jira-development-details-loader/src/ui/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { DEV_DETAILS_DIALOG_SCOPE_ID } from '../../common/constants.tsx';
import { ErrorBoundary } from '../../common/ui/error-boundary/index.tsx';
import { DevOpsSummaryContainer as BaseContainer } from '../../services/context.tsx';
import {
	DevOpsSummaryExperienceStart,
	useDevOpsSummaryExperienceFail,
} from '../../services/experience-tracking/index.tsx';
import type { Props } from './types.tsx';

export const DevOpsSummaryContainer = ({ children, issueIds, location }: Props) => {
	const cloudId = useCloudId();
	const onFail = useDevOpsSummaryExperienceFail();

	return (
		<UFOSegment name="DevOpsSummaryContainer">
			<DevOpsSummaryExperienceStart location={location} />
			<ErrorBoundary fallback={() => <>{children}</>} onError={onFail}>
				<DevDetailsDialogLoader scopeId={DEV_DETAILS_DIALOG_SCOPE_ID} />
				<BaseContainer cloudId={cloudId} issueIds={issueIds} location={location}>
					{children}
				</BaseContainer>
			</ErrorBoundary>
		</UFOSegment>
	);
};
