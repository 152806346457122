import React from 'react';
import { styled } from '@compiled/react';
import { useCloseOnEscapePress } from '@atlaskit/layering';
import { SlideIn } from '@atlaskit/motion';
import Portal from '@atlaskit/portal';
import { Box, xcss } from '@atlaskit/primitives';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { OutsideClickAlerter } from '@atlassian/jira-polaris-lib-outside-click-alerter/src/index.tsx';

export const RightSidebar = ({
	children,
	onClose,
}: {
	children: React.ReactNode;
	onClose: () => void;
}) => {
	useCloseOnEscapePress({
		onClose,
	});

	return (
		<OutsideClickAlerter onClickOutside={onClose}>
			{(outsideClickAlerterProps) => (
				<Portal zIndex={layers.rightSidebar}>
					<SlideIn enterFrom="right" fade="none">
						{({ ref, className }) => (
							// this is the way motion library is intended to be used
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop
							<AnimationContainer ref={ref} className={className}>
								<Box
									xcss={sidebarStyles}
									onMouseDownCapture={outsideClickAlerterProps.onMouseDownCapture}
									onTouchEndCapture={outsideClickAlerterProps.onTouchEndCapture}
								>
									{children}
								</Box>
							</AnimationContainer>
						)}
					</SlideIn>
				</Portal>
			)}
		</OutsideClickAlerter>
	);
};

// - the animations only work with styled components
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const AnimationContainer = styled.div({
	width: '500px',
	position: 'fixed',
	right: 0,
});

const sidebarStyles = xcss({
	width: '500px',
	height: 'calc(100vh - (var(--topNavigationHeight, 0px) + 1px + var(--bannerHeight, 0px)))',
	position: 'fixed',
	bottom: 'space.0',
	backgroundColor: 'elevation.surface.overlay',
	boxShadow: 'elevation.shadow.overlay',
	clipPath: 'inset(0px 0px 0px -15px)', // Remove shadow everywhere except on the left side of the sidebar
});
