import React from 'react';
import type { CustomGlyphProps } from '@atlaskit/icon';

export const matrixGlyph = (props: CustomGlyphProps) => (
	<svg
		{...props}
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.995 18H19C19.2652 18 19.5196 18.1054 19.7071 18.2929C19.8946 18.4804 20 18.7348 20 19C20 19.2652 19.8946 19.5196 19.7071 19.7071C19.5196 19.8946 19.2652 20 19 20H6.995C6.19899 19.9976 5.43646 19.6794 4.8749 19.1152C4.31335 18.551 3.99868 17.787 4 16.991V5C4 4.73478 4.10536 4.48043 4.2929 4.29289C4.48043 4.10536 4.73479 4 5 4C5.26522 4 5.51957 4.10536 5.70711 4.29289C5.89465 4.48043 6 4.73478 6 5V16.991C6 17.548 6.448 18 6.995 18ZM8 8C8 6.89543 8.89543 6 10 6C11.1046 6 12 6.89543 12 8C12 9.10457 11.1046 10 10 10C8.89543 10 8 9.10457 8 8ZM17 10C16.4477 10 16 10.4477 16 11C16 11.5523 16.4477 12 17 12C17.5523 12 18 11.5523 18 11C18 10.4477 17.5523 10 17 10ZM12.5 13C11.6716 13 11 13.6716 11 14.5C11 15.3284 11.6716 16 12.5 16C13.3284 16 14 15.3284 14 14.5C14 13.6716 13.3284 13 12.5 13Z"
			fill="currentColor"
		/>
	</svg>
);
