import React from 'react';
import { styled } from '@compiled/react';
import Badge from '@atlaskit/badge';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import type { ClassicStoryPointValue } from '../../common/types.tsx';

type Props = {
	isEditable: boolean;
	noValueText?: string;
	value: ClassicStoryPointValue;
};

const TEST_ID_CONTAINER = 'issue-field-classic-story-point.ui.inline-view-field-container';
const TEST_ID = 'issue-field-classic-story-point.ui.inline-view-field';

export const ClassicStoryPointView = ({ isEditable, noValueText, value }: Props) => {
	const { formatNumber } = useIntl();

	const badgeProps = {
		min: Number.MAX_SAFE_INTEGER * -1,
		max: Number.MAX_SAFE_INTEGER,
	};

	if (value === null) {
		return <EmptyValue data-testid={TEST_ID}>{noValueText}</EmptyValue>;
	}

	const BadgeComp = (
		<Badge testId={TEST_ID} {...badgeProps}>
			{formatNumber(value)}
		</Badge>
	);

	if (isEditable) {
		return <HoverContainer data-testid={TEST_ID_CONTAINER}>{BadgeComp}</HoverContainer>;
	}

	return BadgeComp;
};

export default ClassicStoryPointView;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const EmptyValue = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N300),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const HoverContainer = styled.div(
	{
		cursor: 'pointer',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	`> span:hover {
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */ ''}
        color: ${token('color.text', colors.N800)} !important;
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */ ''}
        background-color: ${token('color.background.neutral.hovered', colors.N90)} !important;
    }`,
);
