import React from 'react';
import type { CustomGlyphProps } from '@atlaskit/icon';

export const deliveryTicketsGlyph = (props: CustomGlyphProps) => (
	<svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M14.625 9.401h3.064c.997 0 1.811.803 1.811 1.8-.1.446-.113.504-.148.542l-.017.016-.085.13c-.054.077-.128.18-.223.306l-.4.527-.334.433-.629.807c-.524.67-1.052 1.34-1.581 2.008l-.049.061c-.99 1.25-1.98 2.498-2.973 3.744l-.28.35-.073.083c-.344.344-.679.566-.985.685-.19.074-.37.108-.535.108a1.807 1.807 0 0 1-1.813-1.8v-4.626H6.438l-.168-.014c-.95-.16-1.77-.75-1.77-1.76 0-.526.188-.77 3.375-4.769l.093-.117a667.96 667.96 0 0 1 2.976-3.699l.324-.397.197-.21a1.81 1.81 0 0 1 3.08.665c.053.17.08.348.08.527v4.6Zm-2-4.083v6.083h4.495a400.352 400.352 0 0 1-2.606 3.33l-.048.06-1.602 2.019-1.367 1.717-.122.154v-6.106H6.843c.379-.508 1.023-1.323 2.596-3.296l.046-.059.047-.058c.74-.928 1.484-1.854 2.23-2.778l.863-1.066Z"
			clipRule="evenodd"
		/>
	</svg>
);
