export const SUMMARY_ITEM_BRANCH = 'branch' as const;
export const SUMMARY_ITEM_BUILD = 'build' as const;
export const SUMMARY_ITEM_COMMIT = 'commit' as const;
export const SUMMARY_ITEM_DEPLOYMENT = 'deployment' as const;
export const SUMMARY_ITEM_REMOTE_LINKS = 'remote-links' as const;
export const SUMMARY_ITEM_PULLREQUEST = 'pullrequest' as const;
export const SUMMARY_ITEM_REVIEW = 'review' as const;
export const SUMMARY_ITEM_FEATURE_FLAGS = 'feature-flags' as const;

export const OAUTH_ANALYTICS_TYPE = 'oAuth';

export type SUMMARY_ITEM =
	| typeof SUMMARY_ITEM_BRANCH
	| typeof SUMMARY_ITEM_BUILD
	| typeof SUMMARY_ITEM_COMMIT
	| typeof SUMMARY_ITEM_DEPLOYMENT
	| typeof SUMMARY_ITEM_PULLREQUEST
	| typeof SUMMARY_ITEM_REVIEW;

export const SUMMARY_ITEMS = [
	SUMMARY_ITEM_BRANCH,
	SUMMARY_ITEM_BUILD,
	SUMMARY_ITEM_COMMIT,
	SUMMARY_ITEM_DEPLOYMENT,
	SUMMARY_ITEM_PULLREQUEST,
	SUMMARY_ITEM_REVIEW,
] as const;
