import React, { useContext } from 'react';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { JsmSmartRequestSummaryEntryPointContext } from '@atlassian/jira-issue-view-smart-request-summary-entrypoint/src/index.tsx';

const runtimeProps = {};
export const JsmSmartRequestSummaryEntryPointContainer = () => {
	const { entryPointReferenceSubject } = useContext(JsmSmartRequestSummaryEntryPointContext);

	return (
		<JiraEntryPointContainer
			entryPointReferenceSubject={entryPointReferenceSubject}
			id="jsmSmartRequestSummary"
			packageName="issue"
			teamName="itsm-grimlock"
			runtimeProps={runtimeProps}
			fallback={null}
			errorFallback="flag"
		/>
	);
};
