import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const QuickApprovalsPanelEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(/* webpackChunkName: "async-jira-issue-view-quick-approvals-panel" */ './ui/index').then(
			({ QuickApprovalsPanel }) => QuickApprovalsPanel,
		),
	),
	getPreloadProps: () => ({}),
});
