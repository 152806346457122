const ENVIRONMENT_TYPE_PRODUCTION = 'production';
const ENVIRONMENT_TYPE_STAGING = 'staging';
const ENVIRONMENT_TYPE_TESTING = 'testing';
const ENVIRONMENT_TYPE_DEVELOPMENT = 'development';
const ENVIRONMENT_TYPE_OTHER = 'other';

export const featureFlagEnvironmentTypes = [
	ENVIRONMENT_TYPE_PRODUCTION,
	ENVIRONMENT_TYPE_STAGING,
	ENVIRONMENT_TYPE_TESTING,
	ENVIRONMENT_TYPE_DEVELOPMENT,
	ENVIRONMENT_TYPE_OTHER,
] as const;
