import type { JSONDocNode } from '@atlaskit/editor-json-transformer';

// the imports here were kept async so as not to blow the bundle sizes for everyone
export const convertWikiToAdfAsync = async (wiki?: string | null): Promise<JSONDocNode | null> => {
	if (wiki === null || wiki === undefined || wiki === '') {
		return Promise.resolve(null);
	}

	const wikiPromise = await import(
		/* webpackChunkName: "async-wikimarkup-transformer" */ '@atlaskit/editor-wikimarkup-transformer'
	);
	const schemaPromise = await import(
		/* webpackChunkName: "async-adf-defaultschema" */ '@atlaskit/adf-schema/schema-default'
	);
	const jsonPromise = await import(
		/* webpackChunkName: "async-json-transformer" */ '@atlaskit/editor-json-transformer'
	);

	const [{ defaultSchema }, { WikiMarkupTransformer }, { JSONTransformer }] = await Promise.all([
		schemaPromise,
		wikiPromise,
		jsonPromise,
	]);

	const wikiTransformer = new WikiMarkupTransformer(defaultSchema);
	const adfTransformer = new JSONTransformer();

	const pmNode = wikiTransformer.parse(wiki);
	return adfTransformer.encode(pmNode);
};
