import { createSelector } from 'reselect';
import last from 'lodash/last';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { DynamicFieldFormula } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/types.tsx';
import type { DynamicFieldResolver } from '../../types.tsx';
import { getNumberSelectorForFormula } from '../index.tsx';

const FORMULA_TEMPLATE = 'discretization';

export const discreteResolver: DynamicFieldResolver<number> = (formula: DynamicFieldFormula) => {
	if (formula === undefined || formula.template !== FORMULA_TEMPLATE) {
		return undefined;
	}
	const { upperBounds, values } = formula.parameters;
	const termResolver = getNumberSelectorForFormula(formula.parameters.formula);
	if (!termResolver) {
		return undefined;
	}

	return (localIssueId: LocalIssueId) =>
		createSelector(termResolver(localIssueId), (value) => {
			if (value === undefined) {
				return undefined;
			}
			const index = upperBounds.findIndex((upperBound: number) => value < upperBound);
			if (index === -1) {
				return last(values);
			}
			return values[index];
		});
};
