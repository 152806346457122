/* eslint-disable @atlassian/relay/unused-fields */
/** @jsx jsx */
import React, { useCallback, useMemo, type ReactNode } from 'react';
import { css, styled, jsx } from '@compiled/react';
import { useFragment, graphql } from 'react-relay';
import { Box, Flex, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import type { Goal } from '@atlassian/jira-business-goal-picker/src/common/types.tsx';
import { GoalsCellContent } from '@atlassian/jira-business-goal-picker/src/ui/goals-cell-content/index.tsx';
import GoalsPicker from '@atlassian/jira-business-goal-picker/src/ui/index.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { useIssueId, useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useGoalsField } from '@atlassian/jira-issue-field-goals/src/services/use-goals-field/index.tsx';
import { FieldInlineEditStateLess } from '@atlassian/jira-issue-field-inline-edit/src/ui/index.tsx';
import { useOptionallyControlledEditingState } from '@atlassian/jira-issue-field-optional-editing-state-manager/src/index.tsx';
import { useIssueAri } from '@atlassian/jira-issue-hooks/src/services/use-issue-ari/index.tsx';
import { useIsJsmIssue } from '@atlassian/jira-issue-util-hooks/src/index.tsx';
import { IssueViewFieldHeading } from '@atlassian/jira-issue-view-layout-field-heading/src/ui/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { ui_businessGoalsIssueViewField_GoalsInlineEditViewField$key as GoalsFragment } from '@atlassian/jira-relay/src/__generated__/ui_businessGoalsIssueViewField_GoalsInlineEditViewField.graphql';
import type { GoalsInlineEditViewProps } from './types.tsx';

export const GoalsInlineEditViewField = ({
	fragmentKey,
	isEditing: startWithEditViewOpen = false,
	readViewFitContainerWidth = true,
	area,
}: GoalsInlineEditViewProps) => {
	const issueAri = useIssueAri();
	const issueKey = useIssueKey();
	const issueId = useIssueId();
	const isJsm = useIsJsmIssue();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const data = useFragment<GoalsFragment>(
		graphql`
			fragment ui_businessGoalsIssueViewField_GoalsInlineEditViewField on JiraGoalsField {
				...ui_issueViewLayoutFieldHeading_IssueViewFieldHeading
				__typename
				id
				type
				fieldId
				name
				fieldConfig {
					isEditable
				}
				selectedGoals(first: 1) @connection(key: "issue_view_goals_field_selectedGoals") {
					totalCount
					edges {
						node {
							id
							name
							status
						}
					}
				}
			}
		`,
		fragmentKey,
	);

	const { id: uniqueFieldId, name: fieldName, selectedGoals, fieldConfig, fieldId } = data;

	const goals: Goal[] = useMemo(
		() =>
			selectedGoals?.edges
				?.filter((edge) => !!edge?.node)
				?.map((edge) => ({
					id: edge?.node?.id || '',
					name: edge?.node?.name || '',
					status: edge?.node?.status,
				})) || [],
		[selectedGoals],
	);

	const [{ value }, { saveValue }] = useGoalsField({
		issueId,
		issueKey,
		fieldKey: fieldId,
		initialValue: { goals, totalCount: selectedGoals?.totalCount || 0 },
	});
	const [editMode, setEditMode] = useOptionallyControlledEditingState(
		startWithEditViewOpen,
		uniqueFieldId,
	);

	const isFieldEditable = fieldConfig?.isEditable || false;

	const onCancelRequest = useCallback(() => {
		setEditMode(false);
	}, [setEditMode]);

	const onEditRequested = useCallback(() => {
		setEditMode(!editMode);
	}, [editMode, setEditMode]);

	const onEscape = () => {
		setEditMode(false);
	};

	const onClose = useCallback(() => {
		setEditMode(false);
	}, [setEditMode]);

	const onGoalPicked = useCallback(
		(goal: Goal) => {
			saveValue(
				{
					goals: value.goals.length ? value.goals : [goal],
					totalCount: value.totalCount + 1,
				},
				null,
				createAnalyticsEvent({}),
			);
		},
		[createAnalyticsEvent, saveValue, value.goals, value.totalCount],
	);

	const onGoalUnlinked = useCallback(
		(_: string, remainingGoals: Goal[]) => {
			saveValue(
				{
					goals: remainingGoals,
					totalCount: value.totalCount - 1,
				},
				null,
				createAnalyticsEvent({}),
			);
		},
		[createAnalyticsEvent, saveValue, value.totalCount],
	);

	const renderReadView = () => (
		<Flex testId="business-goals-issue-view-field.ui.read-view" xcss={readViewContainerStyles}>
			<Box xcss={readModeStyles}>
				<GoalsCellContent
					goals={value.goals}
					goalCount={value.totalCount}
					isEditable={isFieldEditable}
					showPlaceholder
				/>
			</Box>
		</Flex>
	);

	const renderEditView = () => (
		<Box testId="business-goals-issue-view-field.ui.edit-view" xcss={editViewContainerStyles}>
			<Flex alignItems="center" xcss={editModeStyles}>
				<GoalsPicker
					goals={value.goals}
					goalCount={value.totalCount}
					isEditable={isFieldEditable}
					editMode
					shouldRenderToParent
					onGoalPicked={onGoalPicked}
					issueAri={issueAri}
					onClose={onClose}
					onGoalUnlinked={onGoalUnlinked}
					showPlaceholder
				/>
			</Flex>
		</Box>
	);

	if (isJsm) {
		return null;
	}

	return (
		<IssueViewFieldHeading
			area={area}
			fragmentKey={data}
			testId={`issue.issue-view-layout.issue-view-single-select-field.${data.fieldId}`}
		>
			<InlineEditContainer isEditable={isFieldEditable}>
				<FieldInlineEditStateLess
					editView={renderEditView}
					hideActionButtons
					isEditable={isFieldEditable}
					isEditing={editMode}
					isLabelHidden
					label={fieldName}
					readView={renderReadView}
					readViewFitContainerWidth={readViewFitContainerWidth}
					testId="business-goals-issue-view-field.ui.field-inline-edit-state-less"
					onCancel={onCancelRequest}
					onEdit={onEditRequested}
					onEscape={onEscape}
				/>
			</InlineEditContainer>
		</IssueViewFieldHeading>
	);
};

const nonEditableStyles = css({
	display: 'flex',
	flex: '1 1 auto',
	wordBreak: 'break-word',
	position: 'relative',
	lineHeight: 1.4,
	paddingLeft: token('space.075', '6px'),
});

const inlineEditContainerStyles = css({
	width: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& div[data-read-view-fit-container-width]': {
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		minHeight: '32px',
	},
	marginTop: `${token('space.negative.100', '-8px')}`,
	marginLeft: `${token('space.negative.050', '-4px')}`,
});

const InlineEditContainerNew = ({
	isEditable,
	children,
	...props
}: {
	isEditable: boolean;
	children: ReactNode;
}) => (
	<div css={[inlineEditContainerStyles, !isEditable && nonEditableStyles]} {...props}>
		{children}
	</div>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const InlineEditContainerOld = styled.div<{ isEditable: boolean }>(
	{
		width: '100%',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& div[data-read-view-fit-container-width]': {
			display: 'flex',
			alignItems: 'center',
			width: '100%',
			minHeight: '32px',
		},
		marginTop: `${token('space.negative.100', '-8px')}`,
		marginLeft: `${token('space.negative.100', '-8px')}`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isEditable }) => !isEditable && nonEditableStyles,
);

const InlineEditContainer = componentWithFG(
	'issue_view_field_config_edit',
	InlineEditContainerNew,
	InlineEditContainerOld,
);

export default GoalsInlineEditViewField;

const editModeStyles = xcss({
	width: '100%',
	minHeight: '32px',
	paddingTop: 'space.0',
	paddingBottom: 'space.0',
	paddingLeft: 'space.100',
	paddingRight: 'space.075',
});

const readModeStyles = xcss({
	paddingLeft: 'space.075',
	width: '100%',
});

const editViewContainerStyles = xcss({
	zIndex: 'dialog',
	position: 'relative',
	whiteSpace: 'nowrap',
});

const readViewContainerStyles = xcss({
	flex: '1 1 auto',
	wordBreak: 'break-word',
	position: 'relative',
	lineHeight: 1.4,
	width: '100%',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
});
