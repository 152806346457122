import React, { useEffect } from 'react';
import type { UFOExperience } from '@atlassian/ufo';
import type { CustomData } from '@atlassian/ufo-experimental';

export const ExperienceSuccess = ({
	experience,
	config,
}: {
	experience: UFOExperience;
	config?: {
		metadata: CustomData;
	};
}) => {
	useEffect(() => {
		experience.success(config);
	}, [experience, config]);

	return <></>;
};
