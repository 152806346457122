/**
 * @generated SignedSource<<5c236a18bf6aa55c91bc5553c487f3a7>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { UpdatableFragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutRichTextField_IssueViewRichTextField_richText$data = {
  get adfValue(): {
    json: AGG$JSON | null | undefined;
    get convertedPlainText(): {
      plainText: string | null | undefined;
    } | null | undefined;
    set convertedPlainText(value: null | undefined);
  } | null | undefined;
  set adfValue(value: null | undefined);
  readonly " $fragmentType": "ui_issueViewLayoutRichTextField_IssueViewRichTextField_richText";
};
export type ui_issueViewLayoutRichTextField_IssueViewRichTextField_richText$key = {
  readonly " $data"?: ui_issueViewLayoutRichTextField_IssueViewRichTextField_richText$data;
  readonly $updatableFragmentSpreads: FragmentRefs<"ui_issueViewLayoutRichTextField_IssueViewRichTextField_richText">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutRichTextField_IssueViewRichTextField_richText",
  "selections": [
    {
      "concreteType": "JiraADF",
      "kind": "LinkedField",
      "name": "adfValue",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "json"
        },
        {
          "concreteType": "JiraAdfToConvertedPlainText",
          "kind": "LinkedField",
          "name": "convertedPlainText",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "plainText"
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraRichText"
};

(node as any).hash = "e021ecc4f4189a5b227613c32dcca997";

export default node;
