import type { CloudId, ProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';

export const getEscalatorBaseUrl = (cloudId: CloudId) =>
	`/gateway/api/jsd/escalator/${cloudId}/api`;

export const getChatOpsBaseUrl = (cloudId: CloudId) =>
	`/gateway/api/chatplatform/opsgenie/${cloudId}/api/internal-api/v3/chat`;

export const getServiceDeskAgentContextUrl = (projectKey: ProjectKey) =>
	`/rest/servicedesk/1/pages/agent/servicedesk/${projectKey}`;
