import React, { type ComponentPropsWithoutRef } from 'react';
import { css, keyframes, styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import type { Position } from '../../common/types.tsx';

const resizeHandleSelectorName = 'jira-issue-view-common-component-resize-handle';
export const RESIZE_HANDLE_COMPONENT_SELECTOR = `[data-component-selector="${resizeHandleSelectorName}"]`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ResizeHandleComponent = styled.div<{
	resizeHandleAsOverlay?: boolean;
	resizeHandleZIndex?: number;
	position?: Position;
}>(
	{
		flexGrow: 0,
		flexShrink: 0,
		width: `${token('space.300', '24px')}`,
		cursor: 'ew-resize',
		alignSelf: 'stretch',
		display: 'flex',
		justifyContent: 'center',
		'&:focus': {
			outline: 'none',
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ resizeHandleZIndex }) => resizeHandleZIndex && `z-index: ${resizeHandleZIndex}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ position }) => (position === 'left' ? 'left: 0px' : 'right: 0px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ resizeHandleAsOverlay, position }) =>
		resizeHandleAsOverlay &&
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		css({
			top: '0px',
			position: 'absolute',
			height: '100%',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			transform: position === 'left' ? 'translate3d(-50%, 0, 0)' : 'translate3d(50%, 0, 0)',
		}),
);

export const ResizeHandle = (props: ComponentPropsWithoutRef<typeof ResizeHandleComponent>) => (
	<ResizeHandleComponent data-component-selector={resizeHandleSelectorName} {...props} />
);

const appearAnimationBorder = keyframes({
	'0%': {
		opacity: 0,
	},
	'25%': {
		opacity: 1,
	},
	'75%': {
		opacity: 1,
	},
	'100%': {
		opacity: 0,
	},
});

const appearAnimationIcon = keyframes({
	'0%': {
		opacity: 0,
	},
	'1%': {
		opacity: 1,
	},
	'99%': {
		opacity: 1,
	},
	'100%': {
		opacity: 0,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const ResizeIconOld = styled.div<{ triggerAnimation?: boolean }>({
	position: 'absolute',
	top: token('space.400', '32px'),
	height: '24px',
	width: '24px',
	left: token('space.negative.150', '-12px'),
	opacity: 0,
	boxShadow: `${token(
		'elevation.shadow.overlay',
		'0 0 0 1px rgb(9 30 66 / 8%), 0 2px 4px 1px rgb(9 30 66 / 8%)',
	)}`,
	borderRadius: '50%',
	animationName: appearAnimationIcon,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	animationDuration: ({ triggerAnimation }) => (triggerAnimation ? '1s' : '0s'),
	animationTimingFunction: 'ease-in-out',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('elevation.surface', colors.N0),
});
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const ResizeIconRefresh = styled.div<{ triggerAnimation?: boolean }>({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	position: 'absolute',
	top: token('space.400', '32px'),
	height: '24px',
	width: '24px',
	left: token('space.negative.150', '-12px'),
	opacity: 0,
	boxShadow: `${token(
		'elevation.shadow.overlay',
		'0 0 0 1px rgb(9 30 66 / 8%), 0 2px 4px 1px rgb(9 30 66 / 8%)',
	)}`,
	borderRadius: '50%',
	animationName: appearAnimationIcon,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	animationDuration: ({ triggerAnimation }) => (triggerAnimation ? '1s' : '0s'),
	animationTimingFunction: 'ease-in-out',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('elevation.surface', colors.N0),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ResizeIcon = componentWithCondition(
	() => isVisualRefreshEnabled() && fg('jira_nav4_eap_drop_2'),
	ResizeIconRefresh,
	ResizeIconOld,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ResizeBorder = styled.div<{
	triggerAnimation?: boolean;
}>({
	width: '2px',
	alignSelf: 'stretch',
	animationName: appearAnimationBorder,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	animationDuration: ({ triggerAnimation }) => (triggerAnimation ? '1s' : '0s'),
	animationTimingFunction: 'ease-in-out',
	transition: 'background 0.5s',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.border.focused', colors.B200),
	opacity: 0,
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	[`
                    ${RESIZE_HANDLE_COMPONENT_SELECTOR}:hover &,
                    ${RESIZE_HANDLE_COMPONENT_SELECTOR}:active &,
                    ${RESIZE_HANDLE_COMPONENT_SELECTOR}:focus &
                  `]: {
		opacity: 1,
	},
});
