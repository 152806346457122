// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'issue.subtasks-heading',
		defaultMessage: 'Subtasks',
		description: 'Heading text for subtasks issues panel',
	},
	createSubtaskErrorHeader: {
		id: 'issue.subtask-error-header',
		defaultMessage: "We couldn't create the subtask",
		description: '',
	},
	createSubtaskErrorContent: {
		id: 'issue.subtask-error-content',
		defaultMessage: 'Wait a few moments, then give it another try.',
		description: '',
	},
});
