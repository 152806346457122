/**
 * @generated SignedSource<<bc068f793c3291753cc0246dd0198639>>
 * @relayHash 0f76743340e227d0604c00bab235ce10
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 1be5b97734864741668de20d6ccead252ec1d0c9fd6f813e4476da05a4208d5c

import type { ConcreteRequest, Query } from 'relay-runtime';
export type utilsUseStatusMutationMissingDataQuery$variables = {
  cloudId: string;
  fieldId: string;
  issueKey: string;
};
export type utilsUseStatusMutationMissingDataQuery$data = {
  readonly jira: {
    readonly issueByKey: {
      readonly fieldsById: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly status?: {
              readonly name: string | null | undefined;
              readonly statusCategory: {
                readonly name: string | null | undefined;
                readonly statusCategoryId: string;
              } | null | undefined;
              readonly statusId: string;
            };
            readonly transitions?: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly hasScreen: boolean | null | undefined;
                  readonly name: string | null | undefined;
                  readonly to: {
                    readonly name: string | null | undefined;
                    readonly statusCategory: {
                      readonly name: string | null | undefined;
                      readonly statusCategoryId: string;
                    } | null | undefined;
                    readonly statusId: string;
                  } | null | undefined;
                  readonly transitionId: number | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
          } | null | undefined;
        } | null | undefined> | null | undefined;
      } | null | undefined;
      readonly issueId: string;
    } | null | undefined;
  } | null | undefined;
};
export type utilsUseStatusMutationMissingDataQuery = {
  response: utilsUseStatusMutationMissingDataQuery$data;
  variables: utilsUseStatusMutationMissingDataQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fieldId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueKey"
},
v3 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "kind": "Variable",
    "name": "key",
    "variableName": "issueKey"
  }
],
v4 = {
  "kind": "ScalarField",
  "name": "issueId"
},
v5 = [
  {
    "items": [
      {
        "kind": "Variable",
        "name": "ids.0",
        "variableName": "fieldId"
      }
    ],
    "kind": "ListValue",
    "name": "ids"
  }
],
v6 = {
  "kind": "ScalarField",
  "name": "hasScreen"
},
v7 = {
  "kind": "ScalarField",
  "name": "transitionId"
},
v8 = {
  "kind": "ScalarField",
  "name": "name"
},
v9 = {
  "kind": "ScalarField",
  "name": "statusId"
},
v10 = {
  "kind": "ScalarField",
  "name": "statusCategoryId"
},
v11 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "name": "utilsUseStatusMutationMissingDataQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v3/*: any*/),
            "concreteType": "JiraIssue",
            "kind": "LinkedField",
            "name": "issueByKey",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "args": (v5/*: any*/),
                "concreteType": "JiraIssueFieldConnection",
                "kind": "LinkedField",
                "name": "fieldsById",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraIssueFieldEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "concreteType": "JiraTransitionConnection",
                                "kind": "LinkedField",
                                "name": "transitions",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraTransitionEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "concreteType": "JiraTransition",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v6/*: any*/),
                                          (v7/*: any*/),
                                          (v8/*: any*/),
                                          {
                                            "concreteType": "JiraStatus",
                                            "kind": "LinkedField",
                                            "name": "to",
                                            "plural": false,
                                            "selections": [
                                              (v9/*: any*/),
                                              (v8/*: any*/),
                                              {
                                                "concreteType": "JiraStatusCategory",
                                                "kind": "LinkedField",
                                                "name": "statusCategory",
                                                "plural": false,
                                                "selections": [
                                                  (v10/*: any*/),
                                                  (v8/*: any*/)
                                                ]
                                              }
                                            ]
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              },
                              {
                                "concreteType": "JiraStatus",
                                "kind": "LinkedField",
                                "name": "status",
                                "plural": false,
                                "selections": [
                                  (v9/*: any*/),
                                  (v8/*: any*/),
                                  {
                                    "concreteType": "JiraStatusCategory",
                                    "kind": "LinkedField",
                                    "name": "statusCategory",
                                    "plural": false,
                                    "selections": [
                                      (v8/*: any*/),
                                      (v10/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ],
                            "type": "JiraStatusField"
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "utilsUseStatusMutationMissingDataQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v3/*: any*/),
            "concreteType": "JiraIssue",
            "kind": "LinkedField",
            "name": "issueByKey",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "args": (v5/*: any*/),
                "concreteType": "JiraIssueFieldConnection",
                "kind": "LinkedField",
                "name": "fieldsById",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraIssueFieldEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "__typename"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "concreteType": "JiraTransitionConnection",
                                "kind": "LinkedField",
                                "name": "transitions",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "JiraTransitionEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "concreteType": "JiraTransition",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                          (v6/*: any*/),
                                          (v7/*: any*/),
                                          (v8/*: any*/),
                                          {
                                            "concreteType": "JiraStatus",
                                            "kind": "LinkedField",
                                            "name": "to",
                                            "plural": false,
                                            "selections": [
                                              (v9/*: any*/),
                                              (v8/*: any*/),
                                              {
                                                "concreteType": "JiraStatusCategory",
                                                "kind": "LinkedField",
                                                "name": "statusCategory",
                                                "plural": false,
                                                "selections": [
                                                  (v10/*: any*/),
                                                  (v8/*: any*/),
                                                  (v11/*: any*/)
                                                ]
                                              },
                                              (v11/*: any*/)
                                            ]
                                          },
                                          (v11/*: any*/)
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              },
                              {
                                "concreteType": "JiraStatus",
                                "kind": "LinkedField",
                                "name": "status",
                                "plural": false,
                                "selections": [
                                  (v9/*: any*/),
                                  (v8/*: any*/),
                                  {
                                    "concreteType": "JiraStatusCategory",
                                    "kind": "LinkedField",
                                    "name": "statusCategory",
                                    "plural": false,
                                    "selections": [
                                      (v8/*: any*/),
                                      (v10/*: any*/),
                                      (v11/*: any*/)
                                    ]
                                  },
                                  (v11/*: any*/)
                                ]
                              }
                            ],
                            "type": "JiraStatusField"
                          },
                          (v11/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              },
              (v11/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "1be5b97734864741668de20d6ccead252ec1d0c9fd6f813e4476da05a4208d5c",
    "metadata": {},
    "name": "utilsUseStatusMutationMissingDataQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "0ea6fd905e529b5d6d91e9e0f434117c";

export default node;
