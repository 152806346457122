import React from 'react';
import Spinner from '@atlassian/jira-development-common/src/ui/spinner/index.tsx';
import Container from '../../../common/components/container/index.tsx';

export function TabContentPlaceholder() {
	return (
		<Container>
			<Spinner />
		</Container>
	);
}

export default TabContentPlaceholder;
