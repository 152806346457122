import { createSelector } from 'reselect';
import { ActivityResource } from '@atlassian/activity-provider';
import { cloudIdSelector } from './context-selector.tsx';

const ACTIVITY_URL = '/gateway/api/graphql';

export const activityProviderSelector = createSelector(
	cloudIdSelector,
	(cloudId) => new ActivityResource(ACTIVITY_URL, cloudId),
);
