/**
 * @generated SignedSource<<4a69aaacfa49f2177bf884363be532dd>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type parentIssue_issueViewFoundation_BreadcrumbParentIssueNew$data = {
  readonly " $fragmentSpreads": FragmentRefs<"issueParentSwitcher_issueViewFoundation_IssueParentSwitcherWithRelay">;
  readonly " $fragmentType": "parentIssue_issueViewFoundation_BreadcrumbParentIssueNew";
};
export type parentIssue_issueViewFoundation_BreadcrumbParentIssueNew$key = {
  readonly " $data"?: parentIssue_issueViewFoundation_BreadcrumbParentIssueNew$data;
  readonly " $fragmentSpreads": FragmentRefs<"parentIssue_issueViewFoundation_BreadcrumbParentIssueNew">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "parentIssue_issueViewFoundation_BreadcrumbParentIssueNew",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "issueParentSwitcher_issueViewFoundation_IssueParentSwitcherWithRelay"
    }
  ],
  "type": "JiraIssue"
};

(node as any).hash = "44681bb9d2280ea8c74f05cb94c43971";

export default node;
