import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	changed: {
		id: 'issue-history.history-items.history-item.data-classification-history-item.changed',
		defaultMessage: 'changed the {status}',
		description: `Describes that a user has changed the Data Classification level of the issue.
            Part of the heading in History feed, appears after the display name of the user who made the change.
            {status} is the 'Classification level' field label.`,
	},
	dataClassificationLevel: {
		id: 'issue-history.history-items.history-item.data-classification-history-item.data-classification-level',
		defaultMessage: 'Classification level',
		description: "(noun) Value to display as label for the 'Classification level' field.",
	},
	dataClassificationLevelLowercase: {
		id: 'issue-history.history-items.history-item.data-classification-history-item.data-classification-level-lowercase',
		defaultMessage: 'classification level',
		description: "(noun) Value to display as label for the 'classification level' field.",
	},
	noClassification: {
		id: 'issue-history.history-items.history-item.data-classification-history-item.no-classification',
		defaultMessage: 'No classification',
		description: 'Value to display when the "Classification level" field is empty.',
	},
	noClassificationLowercase: {
		id: 'issue-history.history-items.history-item.data-classification-history-item.no-classification-lowercase',
		defaultMessage: 'no classification',
		description: 'Value to display when the "classification level" field is empty.',
	},
});
