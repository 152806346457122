import React, { useEffect, useMemo, useState, type ReactNode } from 'react';
import { useCoordination } from '@atlassiansox/engagekit-ts';
import { SpotlightPulse } from '@atlaskit/onboarding';
import { Bleed, Box } from '@atlaskit/primitives';
import { CoordinationClient } from '@atlassian/jira-engagement/src/ui/coordination-client/index.tsx';
import { useOrgId } from '@atlassian/jira-router-resources-navigation-org-id/src/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';

export const useShowMessage = () => useState(false);

type Props = {
	children: ReactNode;
};

const EP_MESSAGE_ID = 'command-palette-meatball-button-pulse';

export const PulseWrapper = ({ children }: Props) => {
	const { cloudId } = useTenantContext();
	const { data: orgId } = useOrgId();
	const client = useMemo(
		() => new CoordinationClient(cloudId, '/gateway/api', false, orgId),
		[cloudId, orgId],
	);
	const [msgStarted, stopMsg] = useCoordination(client, EP_MESSAGE_ID);
	const [show, setShow] = useShowMessage();

	useEffect(() => {
		if (msgStarted) {
			setShow(true);
			return () => {
				stopMsg();
			};
		}
	}, [msgStarted, setShow, stopMsg]);

	return show ? (
		<Box padding="space.050">
			<SpotlightPulse radius={3} pulse>
				<Bleed all="space.050">{children}</Bleed>
			</SpotlightPulse>
		</Box>
	) : (
		children
	);
};
