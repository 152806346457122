/**
 * @generated SignedSource<<ac257beb0cfe2cf1aea5cd1782c7ce81>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type url_issueFieldUrlReadviewFull_UrlReadView$data = {
  readonly uri: string | null | undefined;
  readonly " $fragmentType": "url_issueFieldUrlReadviewFull_UrlReadView";
};
export type url_issueFieldUrlReadviewFull_UrlReadView$key = {
  readonly " $data"?: url_issueFieldUrlReadviewFull_UrlReadView$data;
  readonly " $fragmentSpreads": FragmentRefs<"url_issueFieldUrlReadviewFull_UrlReadView">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "url_issueFieldUrlReadviewFull_UrlReadView",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "uri"
    }
  ],
  "type": "JiraUrlField"
};

(node as any).hash = "636e1f468ff8f541721bc22786d7eed9";

export default node;
