// SHOW_CLONE_MODAL
export const SHOW_CLONE_MODAL = 'SHOW_CLONE_MODAL';
export const showCloneModal = () => ({
	type: SHOW_CLONE_MODAL,
});

// HIDE_CLONE_MODAL
export const HIDE_CLONE_MODAL = 'HIDE_CLONE_MODAL';
export const hideCloneModal = () => ({
	type: HIDE_CLONE_MODAL,
});

export type CloneModalAction =
	| ReturnType<typeof showCloneModal>
	| ReturnType<typeof hideCloneModal>;
