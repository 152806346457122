import React, { useState, createContext, type ReactNode, useCallback, useMemo } from 'react';
import { styled } from '@compiled/react';
import throttle from 'lodash/throttle';
import { WidthObserver } from '@atlaskit/width-detector';

export const FieldsCompactModeContext = createContext<boolean>(false);
const { Provider } = FieldsCompactModeContext;

// The number is based on field label min-width (120px) / width (40%) + the padding and border (25px)
const FIELD_COMPACT_MAX_WIDTH = 325;

type Props = {
	defaultIsCompact?: boolean;
	children: ReactNode;
};

export const FieldsCompactModeProvider = ({ children, defaultIsCompact = false }: Props) => {
	const [isCompact, setIsCompact] = useState<boolean>(defaultIsCompact);
	const throttledSetIsCompact = useMemo(() => throttle(setIsCompact, 50), []);
	const setWidth = useCallback(
		(newWidth: number) => {
			throttledSetIsCompact(newWidth != null ? newWidth <= FIELD_COMPACT_MAX_WIDTH : false);
		},
		[throttledSetIsCompact],
	);

	return (
		<>
			<RelativeWrapper>
				<WidthObserver setWidth={setWidth} />
			</RelativeWrapper>
			<Provider value={isCompact}>{children}</Provider>
		</>
	);
};

// This component is used as the parent wrapper for WidthObserver, which is an absolute element
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RelativeWrapper = styled.div({
	position: 'relative',
});
