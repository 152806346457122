import { defineMessages } from '@atlassian/jira-intl';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';

type Messages = {
	startTyping: MessageDescriptor;
	empty: MessageDescriptor;
};

const messages: Messages = defineMessages({
	startTyping: {
		id: 'project-settings-apps.people-field.start-typing',
		defaultMessage: 'Start typing',
		description:
			'This message will be shown when dropdown menu is open but user has not typed anything yet',
	},
	empty: {
		id: 'project-settings-apps.people-field.empty',
		defaultMessage: 'No options',
		description: 'Displayed to users when there are no users matching',
	},
});

export default messages;
