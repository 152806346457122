import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	empty: {
		id: 'issue-field-cascading-select-editview-full.cascading-select.empty',
		defaultMessage: 'No matches found',
		description: 'Message when no options are found in a select',
	},
	failedFetch: {
		id: 'issue-field-cascading-select-editview-full.cascading-select.failed-fetch',
		defaultMessage: 'Search failed.',
		description: 'Message when fetching suggestions fails',
	},
	loading: {
		id: 'issue-field-cascading-select-editview-full.cascading-select.loading',
		defaultMessage: 'Searching...',
		description: 'Message when options are being loaded from the server.',
	},
	noOptionsSelected: {
		id: 'issue-field-cascading-select-editview-full.cascading-select.no-options-selected',
		defaultMessage: 'None',
		description: 'Text to indicate that no option has been selected',
	},
	queryError: {
		id: 'issue-field-cascading-select-editview-full.cascading-select.query-error',
		defaultMessage: 'Search failed.',
		description: 'Message when fetching suggestions fails',
	},
});
