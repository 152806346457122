import React from 'react';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import { useUFOComponentExperience } from '@atlassian/ufo';
import type { StakeholdersGlancePanelProps } from '../../common/types.tsx';
import { jsmStakeholderUpdatesExperience } from '../../common/ui/experience/index.tsx';
import type { StakeholdersGlancePanel as SyncStakeholdersGlancePanel } from './stakeholders-glance-panel/index.tsx';
import type { StakeholdersGlance as SyncStakeholdersGlance } from './stakeholders-glance/index.tsx';
import type { Props } from './stakeholders-glance/types.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const StakeholdersGlanceComponent = lazyForPaint<typeof SyncStakeholdersGlance>(
	() => import(/* webpackChunkName: "async-stakeholders-glance"   */ './stakeholders-glance'),
	{ ssr: false },
);

export const StakeholdersGlance = (props: Props) => {
	useUFOComponentExperience(jsmStakeholderUpdatesExperience);
	return (
		<ErrorBoundary
			id="async.stakeholders.glance"
			packageName="atlassian/jira-jsm-stakeholder-updates"
		>
			<Placeholder name="stakeholders-glance" fallback={null}>
				<StakeholdersGlanceComponent {...props} />
			</Placeholder>
		</ErrorBoundary>
	);
};

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const StakeholdersGlancePanelComponent = lazyForPaint<typeof SyncStakeholdersGlancePanel>(
	() =>
		import(/* webpackChunkName: "async-stakeholders-glance-panel" */ './stakeholders-glance-panel'),
	{ ssr: false },
);

export const StakeholdersGlancePanel = (props: StakeholdersGlancePanelProps) => (
	<ErrorBoundary
		id="async.stakeholders.glance.panel"
		packageName="atlassian/jira-jsm-stakeholder-updates"
	>
		<Placeholder name="stakeholders-glance-panel" fallback={null}>
			<StakeholdersGlancePanelComponent {...props} />
		</Placeholder>
	</ErrorBoundary>
);
