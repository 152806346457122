import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noResultsFound: {
		id: 'development-summary-pull-request.create-pull-request-summary-item.create-pull-request-dropdown.no-results-found.no-results-found',
		defaultMessage: 'No matches found',
		description:
			'Error message when the developent summary has branches but no url was supplied to create a pull request for them.',
	},
});
