import type { ReactNode } from 'react';
import type { StoreContainerProps } from '../../types.tsx';
import { useSuggestedResourcesStoreProps } from './utils.tsx';

export const LoadIfNeeded = ({
	children,
}: {
	children: (containerProps: StoreContainerProps) => ReactNode;
}) => {
	const containerProps = useSuggestedResourcesStoreProps();

	if (!containerProps) {
		return null;
	}

	return children(containerProps);
};
