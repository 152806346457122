import type { ServerTimingState } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import {
	SAVE_SERVER_TIME,
	type SaveServerTimeAction,
} from '../common/metrics/analytics-actions.tsx';

export type ServerState = {
	server: ServerTimingState;
};

const initialState: ServerState = {
	server: {},
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: ServerState = initialState, action: SaveServerTimeAction): ServerState => {
	const { type, payload } = action;

	switch (type) {
		case SAVE_SERVER_TIME:
			if (payload.serverTime) {
				return {
					...state,
					server: {
						...state.server,
						[payload.key]: payload.serverTime,
					},
				};
			}
			return state;
		default:
			return state;
	}
};
