import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	updatedField: {
		id: 'issue-history.common.updated-field',
		defaultMessage: 'updated the {field}',
		description: `Describes that a user has updated a field on the issue.
            Part of the heading in History feed, appears after the display name of the user who made the change.
            {field} is the label for the field.`,
	},
});
