import { JastBuildingQueryVisitor } from './JastBuildingParseTreeVisitor';
import { JQLLexer, JQLParser } from '@atlassiansox/jql-grammar';
import { Jast } from './types';
import {
  ParserErrorListener,
  CharStreams,
  CommonTokenStream,
  ANTLRErrorStrategy
} from 'antlr4ts';
import JastBuilderErrorListener from './errors/JastBuilderErrorListener';
import memoize from 'memoize-one';

export class JastBuilder {
  private errorListeners: ParserErrorListener[] = [];
  private errorHandler: ANTLRErrorStrategy | void = undefined;

  setErrorHandler(errorHandler: ANTLRErrorStrategy): JastBuilder {
    this.errorHandler = errorHandler;
    return this;
  }

  setErrorListeners(errorListeners: ParserErrorListener[]): JastBuilder {
    this.errorListeners = this.errorListeners.concat(errorListeners);
    return this;
  }

  private getTokens = memoize(
    (value: string): CommonTokenStream => {
      const chars = CharStreams.fromString(value);
      const lexer = new JQLLexer(chars);
      return new CommonTokenStream(lexer);
    }
  );

  private getParser = memoize(
    (tokens: CommonTokenStream): JQLParser => new JQLParser(tokens)
  );

  build(value: string): Jast {
    const tokens = this.getTokens(value);
    const parser = this.getParser(tokens);

    const astErrorListener = new JastBuilderErrorListener();

    parser.removeErrorListeners();
    parser.addErrorListener(astErrorListener);
    this.errorListeners.forEach(errorListener =>
      parser.addErrorListener(errorListener)
    );

    if (this.errorHandler) {
      parser.errorHandler = this.errorHandler;
    }

    const visitor = new JastBuildingQueryVisitor(tokens);
    try {
      const parseTree = parser.query();
      const query = parseTree.accept(visitor);

      return {
        query,
        errors: astErrorListener.errors
      };
    } catch (e) {
      // TODO instrument this so we can monitor any parse failures
      console.log('parse tree error ', e);
    }

    return {
      query: undefined,
      errors: []
    };
  }
}
