/**
 * @generated SignedSource<<198f7ad0edafac08c3c8fabf076a2453>>
 * @relayHash b434dd6be61b7b891db6c2ea298d2b15
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID e3ebeda71340562f2a5bff04e9f995d09880d0e99b110609fb8ca01281f0f112

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type DeploymentState = "CANCELLED" | "FAILED" | "IN_PROGRESS" | "PENDING" | "ROLLED_BACK" | "SUCCESSFUL" | "UNKNOWN" | "%future added value";
export type DevOpsEnvironmentCategory = "DEVELOPMENT" | "PRODUCTION" | "STAGING" | "TESTING" | "UNMAPPED" | "%future added value";
export type fetchDevOpsSummariesQuery$variables = {
  ids: ReadonlyArray<string>;
};
export type fetchDevOpsSummariesQuery$data = {
  readonly devOps: {
    readonly summarisedEntities: ReadonlyArray<{
      readonly summarisedBuilds: {
        readonly count: number | null | undefined;
        readonly " $fragmentSpreads": FragmentRefs<"buildData_softwareReleasesVersionDetailIssueList_devOpsSummarisedBuilds">;
      } | null | undefined;
      readonly summarisedDeployments: {
        readonly count: number | null | undefined;
        readonly deploymentEnvironment: {
          readonly category: DevOpsEnvironmentCategory | null | undefined;
        } | null | undefined;
        readonly deploymentState: DeploymentState | null | undefined;
      } | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"deploymentData_softwareReleasesVersionDetailIssueList">;
    } | null | undefined> | null | undefined;
  } | null | undefined;
};
export type fetchDevOpsSummariesQuery = {
  response: fetchDevOpsSummariesQuery$data;
  variables: fetchDevOpsSummariesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "ids"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "count"
},
v3 = {
  "concreteType": "DevOpsEnvironment",
  "kind": "LinkedField",
  "name": "deploymentEnvironment",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "category"
    }
  ]
},
v4 = {
  "kind": "ScalarField",
  "name": "deploymentState"
},
v5 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "fetchDevOpsSummariesQuery",
    "selections": [
      {
        "concreteType": "DevOps",
        "kind": "LinkedField",
        "name": "devOps",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "DevOpsSummarisedEntities",
            "kind": "LinkedField",
            "name": "summarisedEntities",
            "plural": true,
            "selections": [
              {
                "concreteType": "DevOpsSummarisedDeployments",
                "kind": "LinkedField",
                "name": "summarisedDeployments",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/)
                ]
              },
              {
                "concreteType": "DevOpsSummarisedBuilds",
                "kind": "LinkedField",
                "name": "summarisedBuilds",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "FragmentSpread",
                    "name": "buildData_softwareReleasesVersionDetailIssueList_devOpsSummarisedBuilds"
                  }
                ]
              },
              {
                "kind": "FragmentSpread",
                "name": "deploymentData_softwareReleasesVersionDetailIssueList"
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "fetchDevOpsSummariesQuery",
    "selections": [
      {
        "concreteType": "DevOps",
        "kind": "LinkedField",
        "name": "devOps",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "DevOpsSummarisedEntities",
            "kind": "LinkedField",
            "name": "summarisedEntities",
            "plural": true,
            "selections": [
              {
                "concreteType": "DevOpsSummarisedDeployments",
                "kind": "LinkedField",
                "name": "summarisedDeployments",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/)
                ]
              },
              {
                "concreteType": "DevOpsSummarisedBuilds",
                "kind": "LinkedField",
                "name": "summarisedBuilds",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "concreteType": "DevOpsSummarisedBuildState",
                    "kind": "LinkedField",
                    "name": "buildStates",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "state"
                      },
                      (v2/*: any*/)
                    ]
                  },
                  (v5/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "e3ebeda71340562f2a5bff04e9f995d09880d0e99b110609fb8ca01281f0f112",
    "metadata": {},
    "name": "fetchDevOpsSummariesQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "1da537a97ea815b50260e9a461c5d0b3";

export default node;
