import { useEffect } from 'react';
import { createHook, createSelector, createStore } from '@atlassian/react-sweet-state';
import { actions } from './actions.tsx';
import type { State } from './types.tsx';

export const initialState: State = {
	isFetching: false,
	wasFetchedOnce: false,
	fetchError: null,
};

type ActionType = typeof actions;

const Store = createStore<State, ActionType>({
	initialState,
	actions,
	name: 'atlassian-account',
});

const loggedInEmailDomainSelector = createSelector(
	(state: State) => state.data,
	(accountInfo): string | null => {
		if (!accountInfo) {
			return null;
		}

		const [, loggedInEmailDomain] = accountInfo.email?.split('@') ?? [];
		return loggedInEmailDomain;
	},
);

export const useLoggedInEmailDomain = createHook(Store, {
	selector: loggedInEmailDomainSelector,
});

export const useUserEmailDomain = (isEligibleToCall = true) => {
	const [loggedInEmailDomain, { fetchAtlassianAccountData }] = useLoggedInEmailDomain();

	useEffect(() => {
		if (isEligibleToCall && !loggedInEmailDomain) {
			fetchAtlassianAccountData();
		}
	}, [fetchAtlassianAccountData, isEligibleToCall, loggedInEmailDomain]);

	return { data: loggedInEmailDomain };
};

export const useAtlassianAccount = createHook(Store);
