type ByInstanceType = {
	[key: string]: number;
};

export type Branch = {
	url: string | null | undefined;
	count: number;
	lastUpdated: string | null | undefined;
	byInstanceType?: ByInstanceType;
};

export type CreateBranchTarget = {
	readonly name: string;
	readonly url: string | null | undefined;
	readonly baseUrl: string | null;
	readonly iconUrl: string | null;
	readonly iconClass: string | null;
	readonly applicationType: string | null | undefined;
	readonly applicationLinkId: string | null | undefined;
};

export const PlaceholderEnum = {
	ISSUE_KEY: 'issueKey',
	ISSUE_SUMMARY: 'issueSummary',
	ISSUE_SUMMARY_SHORT: 'issueSummaryShort',
	ISSUE_TYPE: 'issueType',
	PROJECT_KEY: 'projectKey',
} as const;

export type PlaceholderEnum = (typeof PlaceholderEnum)[keyof typeof PlaceholderEnum];

export const BranchTemplateType = {
	TEXT: 'TEXT',
	PLACEHOLDER: 'PLACEHOLDER',
} as const;

export type BranchTemplateType = (typeof BranchTemplateType)[keyof typeof BranchTemplateType];

export type UserConfigTemplates = Array<{
	type: BranchTemplateType;
	value: string | PlaceholderEnum;
}>;

export type UserBranchSettingsObject = {
	version?: string;
	isGitCommandIncluded?: boolean;
	branchNameTemplate: UserConfigTemplates;
};

export type UserBranchSettings = UserConfigTemplates | UserBranchSettingsObject;

export type CaretPosition = {
	templateIndex: number;
	characterIndex: number;
};
