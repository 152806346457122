import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { isIssueDropzoneAndClipboardUploadEnabled } from '@atlassian/jira-issue-view-store/src/issue-field/state/selectors/attachment-quick-upload-selector.tsx';
import { CommonDropzoneMediaPicker } from './view.tsx';

export default connect(
	(state: State) => ({
		isEnabled: isIssueDropzoneAndClipboardUploadEnabled(state),
	}),
	{},
)(CommonDropzoneMediaPicker);
