import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { CARD_COVER_ACTIONS } from '@atlassian/jira-issue-view-configurations/src/index.tsx';
import { issueActionsSelector } from '../common/context-selectors/index.tsx';
import { entitiesSelector } from '../common/issue-selectors/index.tsx';

export const cardCoverSelector = createSelector(entitiesSelector, (entities) => entities.cardCover);

export const isCardCoverEnabledSelector = createSelector(
	cardCoverSelector,
	(cardCover) => cardCover && cardCover.isEnabled,
);

export const hasCardCoverSelector = createSelector(
	cardCoverSelector,
	(cardCover) =>
		cardCover && (!isNil(cardCover.attachmentId) || !isEmpty(cardCover.availableAttachmentIds)),
);

export const isCardCoverActionsEnabledSelector = createSelector(
	issueActionsSelector,
	(issueActions) => !!issueActions && issueActions.includes(CARD_COVER_ACTIONS),
);
