import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	createPullRequestTitle: {
		id: 'development-summary-pull-request.create-pull-request-summary-item.create-pull-request-title',
		defaultMessage: 'Create pull request',
		description:
			"The label indicating that clicking the link will result in an action to create a pull request. 'Pull request' is a technical term from the tool Git that is most often not translated. It is acceptable to transliterate this term to another alphabet/script.",
	},
	createPullRequestDropdownIconLabel: {
		id: 'development-summary-pull-request.create-pull-request-summary-item.create-pull-request-dropdown-icon-label',
		defaultMessage: 'Create pull request',
		description:
			"The alt text describing the icon on a drop down. 'Pull request' is a technical term from the tool Git that is most often not translated. It is acceptable to transliterate this term to another alphabet/script.",
	},
});
