import {
	ACTIVE_SPRINT_STATE,
	CLOSED_SPRINT_STATE,
	FUTURE_SPRINT_STATE,
	SPRINT_STATES,
	type SprintState,
} from '@atlassian/jira-shared-types/src/rest/jira/sprint.tsx';

export const getRapidViewUrl = (sprintId: number) =>
	`/secure/GHGoToBoard.jspa?sprintId=${sprintId}`;

/** Base interface to represent a sprint object with a non-strict state property. */
interface WithState {
	state?: string | null;
}

export const hasState =
	(state: SprintState) =>
	<T extends WithState>(sprint: T): boolean =>
		sprint.state != null && sprint.state.toUpperCase() === state.toUpperCase();

export const findSprintWithState = <T extends WithState>(
	sprints: T[],
	state: SprintState,
): T | undefined => sprints.find(hasState(state));

export const findActiveOrFutureSprint = <T extends WithState>(value: T[]): T | null => {
	const activeSprint = findSprintWithState(value, ACTIVE_SPRINT_STATE);
	if (activeSprint) return activeSprint;

	const futureSprint = findSprintWithState(value, FUTURE_SPRINT_STATE);
	if (futureSprint) return futureSprint;

	return null;
};

export const isClosedSprint = hasState(CLOSED_SPRINT_STATE);

export const filterClosedSprints = <T extends WithState>(value: T[]): T[] =>
	value.filter(isClosedSprint);

/** Type predicate to narrow the `state` property in a sprint object */
export const isObjectWithSprintState = <T extends WithState>(
	val: T,
): val is T & {
	state: SprintState;
} => (val.state ? val.state in SPRINT_STATES : false);
