import { initialAttachmentState } from '@atlassian/jira-issue-attachments-store/src/common/utils.tsx';
import { issueAttachmentsStore } from '@atlassian/jira-issue-attachments-store/src/index.tsx';
import type { State } from '@atlassian/jira-issue-attachments-store/src/types.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { createHook } from '@atlassian/react-sweet-state';

// @ts-expect-error - TS7006 - Parameter 'state' implicitly has an 'any' type.
const getIssueAttachment = (state, issueKey: IssueKey) =>
	state.issueAttachments[issueKey] || initialAttachmentState;

const getIsDownloading = (state: State, issueKey: IssueKey) =>
	getIssueAttachment(state, issueKey).isDownloading;

export const useDownloadStatus = createHook(issueAttachmentsStore, {
	selector: getIsDownloading,
});
