import type { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';
import type { Attachment } from '@atlassian/jira-issue-view-common-types/src/attachment-type.tsx';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';

export const getCreateAttachmentUrl = (baseUrl: string, issueKey: string): string =>
	`${baseUrl}/rest/api/2/attachment/upload/issue/${issueKey}`;

const getCreateAttachmentOptions = (attachment: Attachment) => ({
	method: 'POST',
	body: JSON.stringify({
		fileId: attachment.id,
	}),
});

const transformAttachment = (
	createResponse: unknown,
	pendingAttachment: Attachment,
	loggedInAccountId: null | AccountId,
): Attachment => ({
	// @ts-expect-error - TS2571 - Object is of type 'unknown'.
	id: `${createResponse.attachmentId}`,
	mediaApiFileId: pendingAttachment.id,
	createdDate: pendingAttachment.createdDate,
	author: loggedInAccountId,
	mimetype: pendingAttachment.mimetype,
	filename: pendingAttachment.filename,
});

export const createAttachmentInJira = (
	pendingAttachment: Attachment,
	baseUrl: string,
	issueKey: string,
	loggedInAccountId: AccountId | null,
): Observable<Attachment> => {
	const createAttachmentUrl = getCreateAttachmentUrl(baseUrl, issueKey);
	const createAttachmentOptions = getCreateAttachmentOptions(pendingAttachment);

	return fetchJson$(createAttachmentUrl, createAttachmentOptions).map((createResponse) =>
		transformAttachment(createResponse, pendingAttachment, loggedInAccountId),
	);
};
