const EQ = 'EQ' as const;
const GT = 'GT' as const;
const LT = 'LT' as const;
const GTE = 'GTE' as const;
const LTE = 'LTE' as const;

export const ValueRuleOperator = {
	EQ,
	GT,
	LT,
	GTE,
	LTE,
} as const;

const LOGICEQ = '==' as const;
const LOGICGT = '>' as const;
const LOGICLT = '<' as const;
const LOGICGTE = '>=' as const;
const LOGICLTE = '<=' as const;

export const JsonLogicOperator = {
	EQ: LOGICEQ,
	GT: LOGICGT,
	LT: LOGICLT,
	GTE: LOGICGTE,
	LTE: LOGICLTE,
} as const;
