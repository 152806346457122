const DUPLICATE_LABEL = 'duplicateLabel';
const SPECIAL_CHARACTERS = 'specialCharacters';
const MORE_THAN_ONE_NONSPACE_CHARACTER = 'moreThanOneNonSpaceCharacter';
const RESERVED_LABEL = 'reservedLabel';
const GENERIC_ERROR = 'genericError';
const COLOR_NOT_SAVED_ERROR = 'colorNotSavedError';
const PERMISSIONS_NOT_SAVED_ERROR = 'permissionsNotSavedError';

export const ModalLabelFieldError = {
	DUPLICATE_LABEL,
	SPECIAL_CHARACTERS,
	MORE_THAN_ONE_NONSPACE_CHARACTER,
	RESERVED_LABEL,
	GENERIC_ERROR,
	COLOR_NOT_SAVED_ERROR,
	PERMISSIONS_NOT_SAVED_ERROR,
};

export type ModalLabelFieldErrorType =
	(typeof ModalLabelFieldError)[keyof typeof ModalLabelFieldError];

export const IMPORT_STATUSES = {
	PREPARE: 'PREPARE',
	UPLOAD: 'UPLOAD',
	IN_PROGRESS: 'IN_PROGRESS',
	COMPLETE: 'COMPLETE',
} as const;

export const IMPORT_MODES = {
	DETAILS_ONLY: 'DETAILS_ONLY',
	ADD_TO_PROJECT: 'ADD_TO_PROJECT',
} as const;

export const IMPORT_CONTEXTS = {
	ORGANIZATIONS: 'ORGANIZATIONS',
	CUSTOMERS: 'CUSTOMERS',
};

export const EDIT_DETAILS_HASH = '#editDetails';
