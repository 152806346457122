import indexOf from 'lodash/indexOf';
import { toRankCustomFieldId } from '@atlassian/jira-polaris-domain-field/src/field-types/rank/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { fireAnalyticsEventForIssueUpdate } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { getLocalIssueIdToJiraId } from '../../selectors/issue-ids.tsx';
import {
	createGetIssueAnalyticsAttributes,
	createGetKeySelector,
} from '../../selectors/properties/index.tsx';
import type { State, Props } from '../../types.tsx';
import { clearCreatedProperty } from '../create-issue/index.tsx';
import { incrementOpenUpdateCounter } from '../real-time/index.tsx';
import { updateIssuesRanks } from '../utils/index.tsx';

export const moveIssue =
	(id: LocalIssueId, destinationBeforeId?: LocalIssueId, destinationAfterId?: LocalIssueId) =>
	({ getState, setState, dispatch }: StoreActionApi<State>, props: Props) => {
		const { rankField, isRankingEnabled } = props;

		const localIdMap = getLocalIssueIdToJiraId(getState(), props);
		const issueId = localIdMap[id];

		if (!isRankingEnabled) {
			return;
		}

		// move is only possible when sorted by rank - therefore it is safe to clear the temporary rank-information of newly-created issues.
		dispatch(clearCreatedProperty());
		// mark current issue as updated locally to prevent RT updates on the same issue
		dispatch(incrementOpenUpdateCounter([id]));

		// spread state into new object as the store action api gives us a read only state,
		// which leads to flow errors on the reselect selector creators
		const state = getState();

		const ids = [...state.ids];

		// remove ranked issue id from the ids array
		const itemIndex = indexOf(ids, id);
		ids.splice(itemIndex, 1);

		// calculate new position in ids
		const toIndex =
			destinationBeforeId !== undefined
				? indexOf(ids, destinationBeforeId)
				: indexOf(ids, destinationAfterId) + 1;

		const rankKey = createGetKeySelector(id)(state);
		const rankBeforeKey =
			destinationBeforeId !== undefined
				? createGetKeySelector(destinationBeforeId)(state)
				: undefined;
		const rankAfterKey =
			rankBeforeKey === undefined && destinationAfterId !== undefined
				? createGetKeySelector(destinationAfterId)(state)
				: undefined;

		// optimistic ranking: do not wait for backend response
		props.issuesRemote
			.rankIssues({
				issueKeys: [rankKey],
				rankBeforeIssueKey: rankBeforeKey,
				rankAfterIssueKey: rankAfterKey,
				rankCustomFieldId: rankField !== undefined ? toRankCustomFieldId(rankField) : undefined,
			})
			.then(() => {
				dispatch(updateIssuesRanks([id]));
			})
			.catch((error) => props.onIssueUpdateFailed(error));

		// add issue id to ids again at the new position
		ids.splice(toIndex, 0, id);
		setState({
			ids,
		});

		fireAnalyticsEventForIssueUpdate(props.createAnalyticsEvent({}), issueId, {
			updatedItems: [{ name: 'projectRank' }],
			...createGetIssueAnalyticsAttributes(id)(getState()),
		});
	};

export const rankBefore = (id: LocalIssueId, destinationBeforeId: LocalIssueId) =>
	moveIssue(id, destinationBeforeId);

export const rankAfter = (id: LocalIssueId, destinationAfterId: LocalIssueId) =>
	moveIssue(id, undefined, destinationAfterId);
