import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	tooltip: {
		id: 'issue-field-edit-icon.tooltip',
		defaultMessage: 'Edit field',
		description: 'Tooltip when hovering over the edit field icon',
	},
	label: {
		id: 'issue-field-edit-icon.label',
		defaultMessage: 'Edit the name or description of this field',
		description: 'Edit icon label',
	},
	contactAdminToManageFields: {
		id: 'issue-field-edit-icon.contact-admin-to-manage-fields',
		defaultMessage: '<link>Contact your project admin</link> to manage fields',
		description:
			'Message shown in edit icon tooltip when user does not have permission to manage fields',
	},
});
