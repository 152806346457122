import { type RefObject, useCallback } from 'react';
import { useModalDialogActions } from '@atlassian/jira-software-modal-dialog/src/controllers/index.tsx';

// Menu item keys which are opening Modal Dialog (to get focus back to meatball menu)
export const MENU_ITEM_KEYS = [
	'logWork',
	'cloneIssue',
	'findField',
	'dotDialog',
	'linkToParent',
	'deleteIssue',
];

/*
 * Ref - issue view meatball menu trigger
 * This hook used only for dialog which is triggered from menu items in issue view
 */
const useMeatballMenuFocusReturn = (ref: RefObject<HTMLElement>) => {
	const { setReturnFocusTo: setRef } = useModalDialogActions();

	return useCallback(
		(id: string) => {
			if (MENU_ITEM_KEYS.includes(id)) {
				setRef(ref);
			}
		},
		[ref, setRef],
	);
};
export default useMeatballMenuFocusReturn;
