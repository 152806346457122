import type { UnsupportedContentLevelsTracking } from '@atlaskit/editor-common/utils';
import { fg } from '@atlassian/jira-feature-gating';

export const getUnsupportedContentLevelsTracking = (
	defaultConfig?: UnsupportedContentLevelsTracking,
): UnsupportedContentLevelsTracking | undefined => {
	if (fg('details_renderer_unsupported_content_tracking')) {
		return {
			enabled: true,
			thresholds: {
				blocking: 1.5,
				degraded: 1,
			},
		};
	}

	return defaultConfig;
};
