import chunk from 'lodash/chunk';
import head from 'lodash/head';
import tail from 'lodash/tail';

type PromiseProvider<TResult> = () => Promise<TResult>;

const executeBatchesRecursively = <TResult,>(
	remainingChunks: PromiseProvider<TResult>[][],
): Promise<TResult[]> => {
	const currentChunk = head(remainingChunks) || [];
	const nextChunks = tail(remainingChunks);

	return Promise.all(currentChunk.map((promiseProvider) => promiseProvider())).then(
		(results: TResult[]) => {
			if (nextChunks.length > 0) {
				return executeBatchesRecursively(nextChunks).then((recursiveResults) => [
					...results,
					...recursiveResults,
				]);
			}
			return results;
		},
	);
};

/**
 * Utility to run promises in sequential batches of certain sizes
 */
export const runInBatch = <TResult,>(
	promises: PromiseProvider<TResult>[],
	batchSize: number,
): Promise<TResult[]> => {
	if (promises.length === 0) {
		return Promise.resolve([]);
	}
	const batches = chunk(promises, batchSize);
	return executeBatchesRecursively(batches);
};
