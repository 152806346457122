// THIS FILE IS GENERATED via packages/editor/editor-plugins/scripts/update-editor-plugins.ts. DO NOT MODIFY IT MANUALLY.
// Disable no-re-export rule for entry point files
/* eslint-disable @atlaskit/editor/no-re-export */
export { selectionPlugin } from '@atlaskit/editor-plugin-selection';
export type {
	SelectionPlugin,
	EditorSelectionAPI,
	SetSelectionRelativeToNode,
	SelectionPluginOptions,
} from '@atlaskit/editor-plugin-selection';
