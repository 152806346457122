/**
 * @generated SignedSource<<b37a15a2646cf2b254e93c51233c770a>>
 * @relayHash 52ec9f41292374b8b42a659792cc636f
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 04a89f473a7a99d1e53af734bfdc49e7266fc4803ed427ccf54421609fb93a8a

import type { ConcreteRequest, Query } from 'relay-runtime';
export type JiraProjectStyle = "COMPANY_MANAGED_PROJECT" | "TEAM_MANAGED_PROJECT" | "%future added value";
export type JiraProjectType = "BUSINESS" | "CUSTOMER_SERVICE" | "PRODUCT_DISCOVERY" | "SERVICE_DESK" | "SOFTWARE" | "%future added value";
export type useWorkflowRelayQuery$variables = {
  cloudId: string;
  issueKey: string;
};
export type useWorkflowRelayQuery$data = {
  readonly jira: {
    readonly issueByKey: {
      readonly canEditWorkflow: boolean | null | undefined;
      readonly canReadWorkflow: boolean | null | undefined;
      readonly issueTypeField: {
        readonly issueType: {
          readonly issueTypeId: string | null | undefined;
        } | null | undefined;
      } | null | undefined;
      readonly projectField: {
        readonly project: {
          readonly projectId: string | null | undefined;
          readonly projectStyle: JiraProjectStyle | null | undefined;
          readonly projectType: JiraProjectType | null | undefined;
        } | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type useWorkflowRelayQuery = {
  response: useWorkflowRelayQuery$data;
  variables: useWorkflowRelayQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "issueKey"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "kind": "Variable",
    "name": "key",
    "variableName": "issueKey"
  }
],
v2 = {
  "alias": "canReadWorkflow",
  "args": [
    {
      "kind": "Literal",
      "name": "permission",
      "value": "VIEW_READONLY_WORKFLOW"
    }
  ],
  "kind": "ScalarField",
  "name": "hasProjectPermission",
  "storageKey": "hasProjectPermission(permission:\"VIEW_READONLY_WORKFLOW\")"
},
v3 = {
  "alias": "canEditWorkflow",
  "args": [
    {
      "kind": "Literal",
      "name": "permission",
      "value": "EDIT_WORKFLOW"
    }
  ],
  "kind": "ScalarField",
  "name": "hasProjectPermission",
  "storageKey": "hasProjectPermission(permission:\"EDIT_WORKFLOW\")"
},
v4 = {
  "kind": "ScalarField",
  "name": "projectId"
},
v5 = {
  "kind": "ScalarField",
  "name": "projectType"
},
v6 = {
  "kind": "ScalarField",
  "name": "projectStyle"
},
v7 = {
  "kind": "ScalarField",
  "name": "issueTypeId"
},
v8 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "useWorkflowRelayQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraIssue",
            "kind": "LinkedField",
            "name": "issueByKey",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraProjectField",
                "kind": "LinkedField",
                "name": "projectField",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraProject",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ]
                  }
                ]
              },
              {
                "concreteType": "JiraIssueTypeField",
                "kind": "LinkedField",
                "name": "issueTypeField",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraIssueType",
                    "kind": "LinkedField",
                    "name": "issueType",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useWorkflowRelayQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraIssue",
            "kind": "LinkedField",
            "name": "issueByKey",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraProjectField",
                "kind": "LinkedField",
                "name": "projectField",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraProject",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v8/*: any*/)
                    ]
                  },
                  (v8/*: any*/)
                ]
              },
              {
                "concreteType": "JiraIssueTypeField",
                "kind": "LinkedField",
                "name": "issueTypeField",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraIssueType",
                    "kind": "LinkedField",
                    "name": "issueType",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/)
                    ]
                  },
                  (v8/*: any*/)
                ]
              },
              (v8/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "04a89f473a7a99d1e53af734bfdc49e7266fc4803ed427ccf54421609fb93a8a",
    "metadata": {},
    "name": "useWorkflowRelayQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "ebdee6e1f1db415100c81dcb27198ff2";

export default node;
