import type { FieldsByKey } from '@atlassian/jira-polaris-domain-field/src/collections/types.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { View } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';

/**
 * adds fieldKey to hidden view fields
 * if the field is not used in any other axis and if the view fields includes that field
 */
export const addFieldToMatrixHiddenViewFields = (view: View, fieldKey?: FieldKey): View => {
	const viewOrDraft = view.isAutosaveEnabled ? view : view.draft;
	let { hidden = [] } = viewOrDraft;
	const { fields = [], matrixConfig: { axes = [] } = {} } = viewOrDraft;

	if (
		fieldKey !== undefined &&
		fields.includes(fieldKey) &&
		!hidden.includes(fieldKey) &&
		!axes.some((axis) => axis.field?.key === fieldKey)
	) {
		hidden = [...hidden, fieldKey];

		return {
			...view,
			hidden: view.isAutosaveEnabled ? hidden : view.hidden,
			modified: view.isAutosaveEnabled,
			draft: {
				...view.draft,
				hidden,
			},
		};
	}

	return view;
};

/**
 * adds field to view fields
 * if it is hidden it removes it from hidden fields
 */
export const addFieldToMatrixViewFieldsOrUnhide = (view: View, fieldKey?: FieldKey): View => {
	if (fieldKey === undefined) return view;

	const viewOrDraft = view.isAutosaveEnabled ? view : view.draft;
	let { fields = [], hidden = [] } = viewOrDraft;
	let modified = view.modified || false;

	if (!fields.includes(fieldKey)) {
		fields = [...fields, fieldKey];
		modified = true;
	}
	if (hidden.includes(fieldKey)) {
		hidden = hidden.filter((field) => field !== fieldKey);
		modified = true;
	}

	const changedProperties = {
		fields,
		hidden,
	};

	return {
		...view,
		...(view.isAutosaveEnabled ? changedProperties : {}),
		modified: view.isAutosaveEnabled ? modified : false,
		draft: {
			...view.draft,
			...changedProperties,
		},
	};
};

/**
 * sets or unsets (when undefined) a particular dimension of the matrix
 */
export const changeFieldForMatrixAxis = (
	view: View,
	dimension: string,
	fieldKey: FieldKey | undefined,
	allFields: FieldsByKey,
): {
	view: View;
	previousField: FieldKey | undefined;
} => {
	const viewOrDraft = view.isAutosaveEnabled ? view : view.draft;
	let { matrixConfig: { axes = [] } = {} } = viewOrDraft;
	axes = [...axes];
	let previousField: FieldKey | undefined;

	for (let i = axes.length - 1; i >= 0; i -= 1) {
		if (axes[i].dimension === dimension) {
			previousField = axes[i].field?.key;
			axes.splice(i, 1);
		}
	}

	if (fieldKey === previousField) {
		return { view, previousField };
	}

	if (fieldKey !== undefined) {
		axes = [
			...axes,
			{
				dimension,
				field: allFields[fieldKey],
			},
		];
	}

	const newMatrixConfig = {
		...viewOrDraft.matrixConfig,
		axes,
	};

	return {
		view: {
			...view,
			matrixConfig: view.isAutosaveEnabled ? newMatrixConfig : view.matrixConfig,
			modified: view.isAutosaveEnabled,
			draft: {
				...view.draft,
				matrixConfig: newMatrixConfig,
			},
		},
		previousField,
	};
};
