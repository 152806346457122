import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	maxLengthErrorMessage: {
		id: 'issue-field-single-line-text-inline-edit-full.single-line-text.max-length-error-message',
		defaultMessage: '{maxLength} character limit',
		description:
			'Validation message shown to the user when the updated value exceeds the inputs max length.',
	},
	requiredErrorMessage: {
		id: 'issue-field-single-line-text-inline-edit-full.single-line-text.required-error-message',
		defaultMessage: '{fieldName} is required',
		description:
			'Validation message shown to the user when they attempt to clear a required field.',
	},
});
