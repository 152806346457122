// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	flagged: {
		id: 'issue.status-field.flagged',
		defaultMessage: 'Flagged',
		description: '',
	},
});
