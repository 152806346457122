import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	updateStakeholderError: {
		id: 'jsm-stakeholder-updates.services.actions.update-stakeholder.update-stakeholder-error',
		defaultMessage: 'Something went wrong. Please try again.',
		description: 'Update Stakeholder message failed',
	},
	updateStakeholderSuccess: {
		id: 'jsm-stakeholder-updates.services.actions.update-stakeholder.update-stakeholder-success',
		defaultMessage: 'Message sent to stakeholders.',
		description: 'Update Stakeholder message successfull',
	},
	updateError: {
		id: 'jsm-stakeholder-updates.services.actions.update-stakeholder.update-error',
		defaultMessage: 'Couldn’t send message. Try again.',
		description: 'Update Stakeholder message failed',
	},
	updateSuccess: {
		id: 'jsm-stakeholder-updates.services.actions.update-stakeholder.update-success',
		defaultMessage: 'Message sent!',
		description: 'Update Stakeholder message successfull',
	},
});
