/**
 * @generated SignedSource<<2a5d785db9d94536cfc54c642b914993>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type labels_issueFieldLabelsInlineEditViewFull_LabelsInlineEditView$data = {
  readonly fieldConfig: {
    readonly isEditable: boolean | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"labels_issueFieldLabelsInlineEditViewFull_LabelsInlineEditViewWithIsEditable_fragmentRef">;
  readonly " $fragmentType": "labels_issueFieldLabelsInlineEditViewFull_LabelsInlineEditView";
};
export type labels_issueFieldLabelsInlineEditViewFull_LabelsInlineEditView$key = {
  readonly " $data"?: labels_issueFieldLabelsInlineEditViewFull_LabelsInlineEditView$data;
  readonly " $fragmentSpreads": FragmentRefs<"labels_issueFieldLabelsInlineEditViewFull_LabelsInlineEditView">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "labels_issueFieldLabelsInlineEditViewFull_LabelsInlineEditView",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "labels_issueFieldLabelsInlineEditViewFull_LabelsInlineEditViewWithIsEditable_fragmentRef"
    },
    {
      "concreteType": "JiraFieldConfig",
      "kind": "LinkedField",
      "name": "fieldConfig",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isEditable"
        }
      ]
    }
  ],
  "type": "JiraLabelsField"
};

(node as any).hash = "83c0a65ee08db15e69b7b1d990bb3c2d";

export default node;
