/**
 * @generated SignedSource<<8f70045a4aea3653f82e33c269330a50>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type view_issueViewWatchers_ActionIcon$data = {
  readonly " $fragmentSpreads": FragmentRefs<"actionButton_issueViewWatchers_ActionButtonWithRelay" | "view_issueViewWatchers_Dropdown">;
  readonly " $fragmentType": "view_issueViewWatchers_ActionIcon";
};
export type view_issueViewWatchers_ActionIcon$key = {
  readonly " $data"?: view_issueViewWatchers_ActionIcon$data;
  readonly " $fragmentSpreads": FragmentRefs<"view_issueViewWatchers_ActionIcon">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "view_issueViewWatchers_ActionIcon",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "actionButton_issueViewWatchers_ActionButtonWithRelay"
    },
    {
      "kind": "FragmentSpread",
      "name": "view_issueViewWatchers_Dropdown"
    }
  ],
  "type": "JiraWatchesField"
};

(node as any).hash = "8baa44f0b10c5cfa331f9b1ca175702e";

export default node;
