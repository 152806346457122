/**
 * @generated SignedSource<<0ee21c1a385c649c86345ac0a88a0470>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraSprintState = "ACTIVE" | "CLOSED" | "FUTURE" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type sprint_issueFieldSprintReadviewFull_SprintReadView$data = {
  readonly selectedSprintsConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly endDate: AGG$DateTime | null | undefined;
        readonly id: string;
        readonly name: string;
        readonly sprintId: string;
        readonly state: JiraSprintState;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "sprint_issueFieldSprintReadviewFull_SprintReadView";
};
export type sprint_issueFieldSprintReadviewFull_SprintReadView$key = {
  readonly " $data"?: sprint_issueFieldSprintReadviewFull_SprintReadView$data;
  readonly " $fragmentSpreads": FragmentRefs<"sprint_issueFieldSprintReadviewFull_SprintReadView">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "sprint_issueFieldSprintReadviewFull_SprintReadView",
  "selections": [
    {
      "concreteType": "JiraSprintConnection",
      "kind": "LinkedField",
      "name": "selectedSprintsConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraSprintEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraSprint",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "id"
                },
                {
                  "kind": "ScalarField",
                  "name": "sprintId"
                },
                {
                  "kind": "RequiredField",
                  "field": {
                    "kind": "ScalarField",
                    "name": "name"
                  },
                  "action": "THROW",
                  "path": "selectedSprintsConnection.edges.node.name"
                },
                {
                  "kind": "RequiredField",
                  "field": {
                    "kind": "ScalarField",
                    "name": "state"
                  },
                  "action": "THROW",
                  "path": "selectedSprintsConnection.edges.node.state"
                },
                {
                  "kind": "ScalarField",
                  "name": "endDate"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraSprintField"
};

(node as any).hash = "94ae9fbf007cadd0e44b60b0777f74f2";

export default node;
