import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	issueKeyWithResolution: {
		id: 'issue-line-card.issue-key.issue-key-with-resolution',
		defaultMessage: `{issueKey} is {isResolved, select, 
            true {done}
            false {not done}
            other {}
        }`,
		description: 'Text description of issue link that composite with issueKey and resolution',
	},
});
