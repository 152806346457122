import {
	createAri,
	getAriConfig,
	toAri,
	type Ari,
} from '@atlassian/jira-platform-ari/src/index.tsx';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';

export const createProjectAri = (cloudId: string, projectId: string): Ari =>
	createAri({
		resourceOwner: 'jira',
		cloudId,
		resourceType: 'project',
		resourceId: projectId,
	});

export const createIssueAri = (cloudId: string, issueId: IssueId): Ari =>
	createAri({
		resourceOwner: 'jira',
		cloudId,
		resourceType: 'issue',
		resourceId: issueId,
	});

export const createIssueFieldValueAriWithFieldKey = (
	cloudId: string,
	issueId: string,
	fieldKey: string,
) => {
	const ariWithoutFieldKey = createAri({
		resourceOwner: 'jira',
		cloudId,
		resourceType: 'issuefieldvalue',
		resourceId: issueId,
	});

	return `${ariWithoutFieldKey}/${fieldKey}`;
};

export const createViewAri = (cloudId: string, viewId: string): Ari =>
	createAri({
		resourceOwner: 'jira',
		cloudId,
		resourceType: 'view',
		resourceId: viewId,
	});

export const createOptionAri = (cloudId: string, jiraOptionId: string) =>
	createAri({
		resourceOwner: 'jira',
		cloudId,
		resourceType: 'option',
		resourceId: jiraOptionId,
	});

export const createViewSetAri = (cloudId: string, viewSetId: string): Ari =>
	createAri({
		resourceOwner: 'jira',
		cloudId,
		resourceType: 'viewset',
		resourceId: viewSetId,
	});

// our jira:cloud ARIs with workspaceIds and UUIDs are a shape
// that product-fabric (pf-collab and pf-reactions) needs but
// which jira-platform-ari doesn't support... so we generate them
// explicitly here.
//
// Note that if we switch our JPD aris to include a cloudid then this
// function can probably go away :awesome:
export const cloudifyViewAri = (cloudId: string, viewAri: string) => {
	const ari = toAri(viewAri);
	if (ari === undefined) {
		return viewAri; // return unchanged in this case, we can't even parse it
	}

	const viewAriConfig = getAriConfig(ari);

	const viewAriWithCloud =
		viewAriConfig.workspaceId != null
			? `ari:cloud:jira:${cloudId}:view/${viewAriConfig.workspaceId}/${viewAriConfig.resourceId}`
			: `ari:cloud:jira:${cloudId}:view/${viewAriConfig.resourceId}`;

	return viewAriWithCloud;
};
