import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noPermission: {
		id: 'issue-field-status.common.view-workflow.no-permission',
		defaultMessage: "You don't have permission to view this workflow.",
		description: 'Tooltip message indicating why the user cannot view the workflow',
	},
	loading: {
		id: 'issue-field-status.common.view-workflow.loading',
		defaultMessage: 'Looks like your connection is slow. We’re still finding this workflow.',
		description:
			'Initial load tooltip message indicating why "View Workflow" button is in a disabled state',
	},
});
