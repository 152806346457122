import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	oneInstanceError: {
		id: 'development-summary-development-field.summary.error-tag.instance-errors.one-instance-error',
		defaultMessage:
			'Jira is having difficulty contacting {instanceOne}. If this condition persists, please contact your Jira administrators.',
		description:
			'Message displayed to the user when a single error occurred occurred communicating to their service.',
	},
	threeInstanceError: {
		id: 'development-summary-development-field.summary.error-tag.instance-errors.three-instance-error',
		defaultMessage:
			'Jira is having difficulty contacting {instanceOne}, {instanceTwo} and {instanceThree}. If this condition persists, please contact your Jira administrators.',

		description:
			'Message displayed to the user when three errors occur when communicating to their service.',
	},
	twoInstanceError: {
		id: 'development-summary-development-field.summary.error-tag.instance-errors.two-instance-error',
		defaultMessage:
			'Jira is having difficulty contacting {instanceOne} and {instanceTwo}. If this condition persists, please contact your Jira administrators.',

		description:
			'Message displayed to the user when two errors occur when communicating to their service.',
	},
	moreInstanceError: {
		id: 'development-summary-development-field.summary.error-tag.instance-errors.more-instance-error',
		defaultMessage:
			'Jira is having difficulty contacting {instanceOne}, {instanceTwo}, {instanceThree} and {count} other {count, plural, one {application} other {applications}}. If this condition persists, please contact your Jira administrators.',

		description:
			'Message displayed to the user when more than three errors occurred when communicating to their service.',
	},

	connectionTitle: {
		id: 'development-summary-development-field.summary.error-tag.instance-errors.connection-title',
		defaultMessage: 'Connection problem',
		description:
			'Header of a dialog that lets the user know a connection problem has occurred with their service',
	},
});
