import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import { trackOrLogClientError } from '@atlassian/jira-issue-view-common-utils/src/errors/index.tsx';
import { deleteDraftRequest } from '@atlassian/jira-issue-view-store/src/drafts/draft-actions.tsx';
import { fieldDeleteDraftError } from '@atlassian/jira-issue-view-store/src/issue-field/state/actions/field-draft-actions.tsx';
import {
	type FieldSaveSuccessAction,
	FIELD_SAVE_SUCCESS,
} from '@atlassian/jira-issue-view-store/src/issue-field/state/actions/field-save-actions.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const sendDeleteDraftRequest = (action: any) => {
	const {
		payload: { fieldId },
	} = action;
	return Observable.of(deleteDraftRequest({ fieldId }));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const errorHandler = (error: any) => {
	trackOrLogClientError(
		'issue.delete-draft-request',
		'Could not send request to delete field draft from local storage',
		error,
	);
	return Observable.of(fieldDeleteDraftError(error));
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<FieldSaveSuccessAction>) =>
	action$
		.ofType(FIELD_SAVE_SUCCESS)
		.mergeMap((action) => sendDeleteDraftRequest(action))
		.catch((error) => errorHandler(error));
