/**
 * @generated SignedSource<<596728515b9dc6e10069aac6fb2580b2>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraGoalStatus = "ARCHIVED" | "AT_RISK" | "CANCELLED" | "COMPLETED" | "DONE" | "OFF_TRACK" | "ON_TRACK" | "PAUSED" | "PENDING" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type ui_businessGoalsIssueViewField_GoalsInlineEditViewField$data = {
  readonly __typename: "JiraGoalsField";
  readonly fieldConfig: {
    readonly isEditable: boolean | null | undefined;
  } | null | undefined;
  readonly fieldId: string;
  readonly id: string;
  readonly name: string;
  readonly selectedGoals: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string | null | undefined;
        readonly status: JiraGoalStatus | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
    readonly totalCount: number | null | undefined;
  } | null | undefined;
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutFieldHeading_IssueViewFieldHeading">;
  readonly " $fragmentType": "ui_businessGoalsIssueViewField_GoalsInlineEditViewField";
};
export type ui_businessGoalsIssueViewField_GoalsInlineEditViewField$key = {
  readonly " $data"?: ui_businessGoalsIssueViewField_GoalsInlineEditViewField$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_businessGoalsIssueViewField_GoalsInlineEditViewField">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v1 = {
  "kind": "ScalarField",
  "name": "id"
},
v2 = {
  "kind": "ScalarField",
  "name": "name"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "selectedGoals"
        ]
      }
    ]
  },
  "name": "ui_businessGoalsIssueViewField_GoalsInlineEditViewField",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "ui_issueViewLayoutFieldHeading_IssueViewFieldHeading"
    },
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    (v2/*: any*/),
    {
      "concreteType": "JiraFieldConfig",
      "kind": "LinkedField",
      "name": "fieldConfig",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isEditable"
        }
      ]
    },
    {
      "alias": "selectedGoals",
      "concreteType": "JiraGoalConnection",
      "kind": "LinkedField",
      "name": "__issue_view_goals_field_selectedGoals_connection",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "totalCount"
        },
        {
          "concreteType": "JiraGoalEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraGoal",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v2/*: any*/),
                {
                  "kind": "ScalarField",
                  "name": "status"
                },
                (v0/*: any*/)
              ]
            },
            {
              "kind": "ScalarField",
              "name": "cursor"
            }
          ]
        },
        {
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "endCursor"
            },
            {
              "kind": "ScalarField",
              "name": "hasNextPage"
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraGoalsField"
};
})();

(node as any).hash = "c59fd40fed7e15b318f5a145ae9e8e5c";

export default node;
