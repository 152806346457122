import React, { useCallback } from 'react';
import { styled } from '@compiled/react';
import ColorPicker from '@atlaskit/color-picker';

import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { getUpdateAnalyticsFlowHelper } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/index.tsx';
import type { Color, PaletteColor } from '@atlassian/jira-issue-epic-color/src/common/types.tsx';
import { getDefaultColorPalette } from '@atlassian/jira-issue-epic-color/src/common/utils.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';

type ReadOnlyProps = {
	value: string;
	palette: PaletteColor[];
};
export type Props = {
	isEditable: boolean;
	value: string;
	fieldId?: string; // fieldId is passed in as a prop already, remove optional when cleanup one_event_rules_them_all_fg
	fieldType?: string; // fieldType is passed in as a prop already, remove optional when cleanup one_event_rules_them_all_fg
	onChange: (arg1: string) => void;
	onConfirm: () => void;
};

export const ReadOnlyColorPicker = ({ value, palette }: ReadOnlyProps) => {
	const colorLabel = palette.filter((color) => color.value === value)[0].label;
	const { formatMessage } = useIntl();
	const label = formatMessage(messages.readOnlyLabel, { color: colorLabel });
	return (
		<ReadOnlyColorWrapper
			aria-label={label}
			data-testid="issue-view-foundation.color.read-only-color-wrapper"
		>
			<ReadOnlyColourPickerStyle selectedColor={value} />
		</ReadOnlyColorWrapper>
	);
};

export const UnsafeColorPicker = (props: Props) => {
	// static displayName = 'ColorPicker';

	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleOnMenuOpen = useCallback(() => {
		if (!fg('one_event_rules_them_all_fg')) return;

		// remove checking props.fieldId when cleanup one_event_rules_them_all_fg
		props.fieldId &&
			getUpdateAnalyticsFlowHelper().fireAnalyticsStart(props.fieldId, {
				analytics: createAnalyticsEvent({}),
				attributes: {
					fieldType: props.fieldType || '', // remove assign an empty string to fieldId when cleanup one_event_rules_them_all_fg
				},
			});
	}, [createAnalyticsEvent, props.fieldId, props.fieldType]);

	const handleOnChange = (newColor: Color) => {
		props.onChange(newColor);
		props.onConfirm();
	};

	const colorPalette = getDefaultColorPalette(formatMessage);

	return (
		<ColorPickerWrapper
			// eslint-disable-next-line jira/integration/test-id-by-folder-structure
			data-testid="issue.views.issue-base.foundation.color.color-picker-wrapper"
		>
			{props.isEditable ? (
				<ColorPicker
					label={formatMessage(messages.label)}
					popperProps={{
						positionFixed: true,
						modifiers: {
							// @ts-expect-error - TS2322 - Type '{ offset: { offset: string; }; }' is not assignable to type 'readonly Partial<Modifier<string, object>>[]'.
							offset: {
								offset: '-10 5',
							},
						},
					}}
					cols={colorPalette.length / 2}
					selectedColor={props.value}
					palette={colorPalette}
					// @ts-expect-error - TS2322 - Type '(newColor: Color) => void' is not assignable to type '(value: string, analyticsEvent?: object | undefined) => void'.
					onChange={handleOnChange}
					{...(fg('one_event_rules_them_all_fg') && {
						onMenuOpen: handleOnMenuOpen,
					})}
				/>
			) : (
				<ReadOnlyColorPicker value={props.value} palette={colorPalette} />
			)}
		</ColorPickerWrapper>
	);
};

// move 2px to the left to align with other components

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ColorPickerWrapper = styled.div({
	marginLeft: token('space.negative.025', '-2px'),
	paddingTop: token('space.100', '8px'),
	paddingRight: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 4}px`,
	/* color picker focused style clashes with some global css style on the page */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'button:focus': {
		boxShadow: 'none',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReadOnlyColourPickerStyle = styled.div<{ selectedColor: string }>({
	position: 'absolute',
	top: '1px',
	left: '1px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * 3}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 3}px`,
	borderRadius: '3px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	background: (props) => props.selectedColor,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const ReadOnlyColorWrapper = styled.button({
	display: 'inline-block',
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * 3.75}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 3.75}px`,
	borderWidth: '2px',
	borderStyle: 'solid',
	boxSizing: 'border-box',
	borderRadius: '6px',
	borderColor: 'transparent',
	backgroundColor: 'transparent',
	padding: 0,
	outline: 'none',
	transition: 'box-shadow 0.2s cubic-bezier(0.47, 0.03, 0.49, 1.38)',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&:focus.focus-visible': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		borderColor: token('color.border.focused', colors.B200),
	},
});
