import React from 'react';
import { AsyncOptInBackOnboarding } from '@atlassian/jira-issue-onboarding-modal/src/async.tsx';
import { useChangeboardingTourProps } from '../services/hooks/index.tsx';

export const ChangeBoardingTour = () => {
	const [hasEditField, hasShowMoreLink] = useChangeboardingTourProps();

	return <AsyncOptInBackOnboarding hasShowMoreLink={hasShowMoreLink} hasEditField={hasEditField} />;
};

export default ChangeBoardingTour;
