import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	parentTitle: {
		id: 'issue-field-parent-readview-full.parent.summary.parent-title',
		defaultMessage: 'Click to visit {label}',
		description:
			'Aria Label for the link that takes the user to the parent issue - Click to visit [parent label]',
	},
});
