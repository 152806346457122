import React, { memo, useMemo } from 'react';
import isEqual from 'lodash/isEqual';
import { Box, xcss } from '@atlaskit/primitives';
import ToolTip from '@atlaskit/tooltip';
import { SummaryItem } from '@atlassian/jira-development-summary-common/src/ui/summary-item/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';
import type { Deployment as DeploymentType } from './types.tsx';

type DeploymentProps = {
	issueKey: string;
	deployment: DeploymentType | undefined | null;
	onClick?: () => void;
};
const getHref = (issueKey: string) =>
	`/browse/${issueKey}?devStatusDetailDialog=deployment-environment`;

export const areEqual = (previousProps: DeploymentProps, nextProps: DeploymentProps): boolean =>
	isEqual(nextProps.deployment?.environments, previousProps.deployment?.environments) &&
	nextProps.deployment?.count === previousProps.deployment?.count;

export const Deployment = memo<DeploymentProps>(
	({ deployment, issueKey, onClick }: DeploymentProps) => {
		const { formatMessage } = useIntl();
		const { count = 0, environments = [] } = deployment ?? {};

		const title = useMemo(() => {
			const displayedEnvironments = environments.join(', ');

			return (
				<Box>
					<Box xcss={greyFontStyles}>{formatMessage(messages.title)}</Box>
					<ToolTip content={displayedEnvironments}>
						<Box xcss={ellipsisStyles}>{displayedEnvironments}</Box>
					</ToolTip>
				</Box>
			);
		}, [environments, formatMessage]);

		if (deployment && count > 0 && environments.length) {
			return (
				<SummaryItem
					title={title}
					href={getHref(issueKey)}
					onClick={onClick}
					data-testid="development-summary-deployment-development-field.ui.summary-item"
				/>
			);
		}
		return <></>;
	},
	areEqual,
);

export default Deployment;

const ellipsisStyles = xcss({
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

const greyFontStyles = xcss({
	fontSize: 'font.body.UNSAFE_small',
	color: 'color.text.subtlest',
});
