/**
 * @generated SignedSource<<5f68c112c4e06b3f7e1cea409cbd5cac>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type labels_issueFieldLabelsReadviewFull_LabelsReadView$data = {
  readonly fieldId: string;
  readonly selectedLabelsConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly name: string | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
    readonly totalCount: number | null | undefined;
  };
  readonly " $fragmentType": "labels_issueFieldLabelsReadviewFull_LabelsReadView";
};
export type labels_issueFieldLabelsReadviewFull_LabelsReadView$key = {
  readonly " $data"?: labels_issueFieldLabelsReadviewFull_LabelsReadView$data;
  readonly " $fragmentSpreads": FragmentRefs<"labels_issueFieldLabelsReadviewFull_LabelsReadView">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "labels_issueFieldLabelsReadviewFull_LabelsReadView",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "RequiredField",
      "field": {
        "args": [
          {
            "kind": "Literal",
            "name": "first",
            "value": 1000
          }
        ],
        "concreteType": "JiraLabelConnection",
        "kind": "LinkedField",
        "name": "selectedLabelsConnection",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "name": "totalCount"
          },
          {
            "concreteType": "JiraLabelEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "concreteType": "JiraLabel",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "name"
                  }
                ]
              }
            ]
          }
        ],
        "storageKey": "selectedLabelsConnection(first:1000)"
      },
      "action": "THROW",
      "path": "selectedLabelsConnection"
    }
  ],
  "type": "JiraLabelsField"
};

(node as any).hash = "7dbf871989626785f282568d738052ba";

export default node;
