import { useCallback } from 'react';
import { useAIUnifiedAnalytics, fireAIOpsEvent } from '@atlassian/eoc-aiops-instrumentation';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import {
	useAnalyticsEvents,
	type AnalyticsAttributes,
} from '@atlassian/jira-product-analytics-bridge';

export const useAIAnalytics = (type: string) => {
	const { createAIOpsEventPayload } = useAIUnifiedAnalytics();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onClickFireAnalytics = useCallback(
		(payload?: Record<string, unknown>) => {
			fireAIOpsEvent(
				createAnalyticsEvent({}),
				createAIOpsEventPayload({
					type: 'actioned',
					aiAction: 'accept',
					isProactive: true,
					isAIOpsUsed: true,
					aiExperienceName: type,
					...(payload ?? {}),
				}),
			);
		},
		[createAIOpsEventPayload, createAnalyticsEvent, type],
	);

	const onFailureFireAnalytics = useCallback(
		(error: Error, attributes?: AnalyticsAttributes) => {
			fireErrorAnalytics({
				meta: {
					id: 'SuggestedActionFailure',
					packageName: 'jiraServicedeskAIContextOpsPanel',
					teamName: 'itsm-aiops-grogu',
				},
				attributes: {
					action: type,
					...(attributes ?? {}),
				},
				error,
				sendToPrivacyUnsafeSplunk: true,
			});
		},
		[type],
	);
	return [{ onClickFireAnalytics, onFailureFireAnalytics }];
};
