import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';

// LOGIN
export const LOGIN = 'LOGIN' as const;

export const login = () => ({
	type: LOGIN,
});

export type LoginAction = ReturnType<typeof login>;

// LOGIN_SUCCESSFUL
export const LOGIN_SUCCESSFUL = 'LOGIN_SUCCESSFUL' as const;

export const loginSuccessful = (payload: { username?: string; accountId: AccountId }) => ({
	type: LOGIN_SUCCESSFUL,
	payload,
});

export type LoginSuccessfulAction = ReturnType<typeof loginSuccessful>;

export type Action = LoginAction | LoginSuccessfulAction;
