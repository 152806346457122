import { createSelector } from 'reselect';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';

export const mediaContextSelector = (state: State) => state.mediaContext;

export const userAuthSelector = createSelector(
	mediaContextSelector,
	(mediaContext) => (mediaContext && mediaContext.userAuth) || null,
);

export const viewContextSelector = createSelector(
	mediaContextSelector,
	(mediaContext) => (mediaContext && mediaContext.viewContext) || null,
);

export const uploadContextSelector = createSelector(
	mediaContextSelector,
	(mediaContext) => (mediaContext && mediaContext.uploadContext) || null,
);
