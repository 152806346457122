import {
	type AnalyticsEventPayload,
	createAndFireEvent,
	type CreateUIAnalyticsEvent,
} from '@atlaskit/analytics-next';
import { OPERATIONAL_EVENT_TYPE } from '@atlassian/analytics-bridge';

import { UI_MODIFICATIONS_ANALYTICS_CHANNEL } from '../constants';

export const fireOperationalEvent = (
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	eventPayload: AnalyticsEventPayload,
) => {
	createAndFireEvent(UI_MODIFICATIONS_ANALYTICS_CHANNEL)({
		...eventPayload,
		analyticsType: OPERATIONAL_EVENT_TYPE,
	})(createAnalyticsEvent);
};
