import { createSelector } from 'reselect';
import find from 'lodash/find';
import fromPairs from 'lodash/fromPairs';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import {
	BRANCH_PANEL_DATA_TYPE,
	COMMIT_PANEL_DATA_TYPE,
	PULLREQUEST_PANEL_DATA_TYPE,
} from '../../../../common/model/constants.tsx';
import type { InstanceTypeDetail } from '../../../model/index.tsx';
import type { ApplicationSummary } from '../../../model/summary.tsx';
import type { State } from '../../reducers/types.tsx';
import { getAllInstanceTypeDetails } from '../details/index.tsx';

const panelTypeToDetailsField = {
	[BRANCH_PANEL_DATA_TYPE]: 'branches',
	[COMMIT_PANEL_DATA_TYPE]: 'commits',
	[PULLREQUEST_PANEL_DATA_TYPE]: 'pullRequests',
} as const;

const mapInstancesToApplicationSummary = (instances: InstanceTypeDetail[]): ApplicationSummary[] =>
	instances.map(({ type, name }) => ({
		type,
		name,
	}));

const filterByPanelTypePredicate = (instance: InstanceTypeDetail, panelType: string): boolean =>
	// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type 'Repository'. | TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ readonly branch: "branches"; readonly repository: "commits"; readonly pullrequest: "pullRequests"; }'.
	!!find(instance.repositories, (repo) => !isEmpty(repo[panelTypeToDetailsField[panelType]]));

const filterByDanglingPRsPredicate = (instance: InstanceTypeDetail, panelType: string): boolean =>
	!isEmpty(instance.danglingPullRequests) || filterByPanelTypePredicate(instance, panelType);

const getPanelData = (
	panelType: string,
	instances: InstanceTypeDetail[],
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	filterFunc: (...rest: any) => boolean,
) => [
	panelType,
	uniqBy(
		mapInstancesToApplicationSummary(
			instances.filter((instance) => filterFunc(instance, panelType)),
		),
		'type',
	),
];

const getApplicationsFromDetails = createSelector(
	[getAllInstanceTypeDetails],
	(instances: InstanceTypeDetail[]) =>
		fromPairs([
			getPanelData(COMMIT_PANEL_DATA_TYPE, instances, filterByPanelTypePredicate),
			getPanelData(BRANCH_PANEL_DATA_TYPE, instances, filterByPanelTypePredicate),
			getPanelData(PULLREQUEST_PANEL_DATA_TYPE, instances, filterByDanglingPRsPredicate),
		]),
);

export const getApplications =
	(panelType: string) =>
	(state: State): ApplicationSummary[] =>
		getApplicationsFromDetails(state)[panelType] || [];

export const getApplicationTypes =
	(panelType: string) =>
	(state: State): string[] =>
		getApplications(panelType)(state).map((application) => application.type);
