import flatMap from 'lodash/flatMap';
import { expVal } from '@atlassian/jira-feature-experiments';
import type {
	Group,
	Option,
	Options,
} from '@atlassian/jira-issue-internal-field-select/src/common/select-inline-edit/select-field/types.tsx';
import type { SelectableValueOption } from './types.tsx';

export const transformSelectableValueToSelectOption = (option: SelectableValueOption): Option => {
	const transformSelectableValueToSelectOptionBaseObject = {
		ari: option.id,
		id: option.versionId || option.optionId,
		label: option.selectableLabel || '',
		value: option.selectableLabel || '',
		isDisabled: option.isDisabled || false,
		content: '',
		iconUrl: option.selectableIconUrl,
		groupKey: option.selectableGroupKey,
	};

	if (
		expVal(
			'thor_colourful_single_select_milestone1_experiment',
			'isColorfulSingleSelectEnabled',
			false,
		)
	) {
		// Add customColor if colorful single select experiment is enabled
		return {
			...transformSelectableValueToSelectOptionBaseObject,
			customColor: option.color ? option.color.colorKey : null,
		};
	}

	return transformSelectableValueToSelectOptionBaseObject;
};

export const transformSelectableValuesToSelectOptions = (
	options: SelectableValueOption | SelectableValueOption[],
): Option[] => {
	const optionsArray = Array.isArray(options) ? options : [options];
	return optionsArray.map(transformSelectableValueToSelectOption);
};

// Returns:
// 'Recent' group for the cached options (if exist or expected (cachedOptions === [])) and custom groups if there is groupKey prop for the options
// 'Recent' group for the cached options (if exist or expected (cachedOptions === [])) and 'All' group if there is no groupKey for the options
// No 'Recent' group if cached options are not expected (cachedOptions == undefined) and no `All` group if there is no groupKey for the options
export const getGroupedOptions = (
	options: SelectableValueOption[],
	cachedOptions: SelectableValueOption[] | [] | undefined,
	allLabel: string,
	recentLabel: string,
	otherLabel: string,
): Options => {
	const groupedOptions: Group[] = [];
	const hasCache = !!cachedOptions?.length;
	const hasOrExpectsCacheAndHasOtionsWithoutGroupKey =
		cachedOptions &&
		cachedOptions?.length >= 0 &&
		options.length &&
		// assuming that if the first option has groupKey, all of the options have groupKey
		!options[0].selectableGroupKey;

	// if there are cached options, group them under the 'Recent' group label
	if (hasCache) {
		groupedOptions.push({
			label: recentLabel,
			options: transformSelectableValuesToSelectOptions(cachedOptions),
		});
	}

	// if there are cached options (or they are expected) and options without groupKey, group options under the 'All' group label
	if (hasOrExpectsCacheAndHasOtionsWithoutGroupKey) {
		groupedOptions.push({
			label: allLabel,
			options: transformSelectableValuesToSelectOptions(options),
		});
		return groupedOptions;
	}

	if (options?.length) {
		// normalize options into groups
		options.forEach((option) => {
			if (option.selectableGroupKey) {
				const group = groupedOptions.find(
					(groupedOption) => option.selectableGroupKey === groupedOption.label,
				);

				if (group) {
					group.options.push(transformSelectableValueToSelectOption(option));
				} else {
					groupedOptions.push({
						label: option.selectableGroupKey,
						options: transformSelectableValuesToSelectOptions([option]),
					});
				}
			}
		});

		// handle possible scenario where some of the options have selectableGroupKey and some don't. Group the options with no selectableGroupKey under the Other group
		const allOptionsCount = cachedOptions ? cachedOptions?.length + options.length : options.length;
		const allGroupedOptionsCount = groupedOptions.map((x) => x.options).flat().length;
		const hasUngroupedOptions =
			allGroupedOptionsCount && allGroupedOptionsCount !== allOptionsCount;
		if (hasUngroupedOptions) {
			const unGroupedOptions = options.filter((x) => x.selectableGroupKey == null);

			groupedOptions.push({
				label: otherLabel,
				options: transformSelectableValuesToSelectOptions([...unGroupedOptions]),
			});
		}

		if (!groupedOptions.length) {
			return transformSelectableValuesToSelectOptions(options);
		}
	}

	return groupedOptions;
};

export const flattenOptions = (optionsOrOptionGroups: Options): Option[] =>
	flatMap(
		optionsOrOptionGroups.map((optionOrGroup) =>
			// @ts-expect-error - TS2339 - Property 'options' does not exist on type 'Option | Group'. | TS2339 - Property 'options' does not exist on type 'Option | Group'.
			optionOrGroup.options ? optionOrGroup.options : optionOrGroup,
		),
	);

export const flattenSelectedOptions = (
	selectedOptions?: ReadonlyArray<Option | Group> | Readonly<Option | Group> | null,
): ReadonlyArray<Readonly<Option>> => {
	if (selectedOptions == null) return [];

	return Array.isArray(selectedOptions) ? selectedOptions : [selectedOptions];
};
