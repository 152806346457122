import { JiraIssueAri } from '@atlassian/ari/jira/issue';
import {
	baseScopesRequiredPerField,
	type OptionsVisibility,
} from '@atlassian/jira-issue-adjustments/src/common.tsx';
import { isAriResourceIdValid } from '../utils/is-ari-resource-id-valid/index.tsx';
import { isNotEmptyString } from '../utils/is-not-empty-string/index.tsx';
import { validateSetOptionsVisibility } from '../utils/options-visibility/index.tsx';

const CLOUD_ID_MOCK = 'cloud-id';

export const isAriIssueIdValid = (optionId: string) => {
	try {
		JiraIssueAri.create({
			siteId: CLOUD_ID_MOCK,
			issueId: optionId,
		});

		return true;
	} catch (e) {
		return false;
	}
};

export type ParentSetPublicValue = string | null;
export type ParentGetPublicValue = { id: string; key: string } | null;

export const parentSetValueValidator = (value: unknown): value is ParentSetPublicValue =>
	value === null || isNotEmptyString(value);

export const validateParentWithAriSetValueOld = (value: unknown): value is ParentSetPublicValue =>
	value === null || (isNotEmptyString(value) && isAriResourceIdValid(value));

export const validateParentWithAriSetValue = (value: unknown): value is ParentSetPublicValue =>
	value === null || (isNotEmptyString(value) && isAriIssueIdValid(value));

export const validateParentSetOptionsVisibilityAri = (
	optionsVisibility: OptionsVisibility,
): boolean =>
	validateSetOptionsVisibility(optionsVisibility) &&
	optionsVisibility.options.every((optionId) => isAriIssueIdValid(optionId));

export const parentScopes = baseScopesRequiredPerField;
