import React, { type ComponentType } from 'react';
// eslint-disable-next-line jira/restricted/react-redux
import type { MapStateToProps, MapDispatchToPropsFunction } from 'react-redux';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';

export const ConnectRelayHOC =
	<TRelayData, TRelayOwnProps, TStateProps, TDispatchProps, TState, TOwnProps>(
		mapRelayToProps: (
			relayData: TRelayData,
			relayProps: TRelayOwnProps,
		) => Partial<TStateProps & TDispatchProps>,
		mapStateToProps: MapStateToProps<TStateProps, TOwnProps, TState>,
		mapDispatchToProps: MapDispatchToPropsFunction<TDispatchProps, TOwnProps>,
	) =>
	(Component: ComponentType<Omit<TStateProps & TDispatchProps & TOwnProps, 'relayData'>>) => {
		return connect<TStateProps, TDispatchProps, TOwnProps, TState>(
			mapStateToProps,
			mapDispatchToProps,
			// this proved difficult to type in the first iteration of this utility but we should spend some time figuring it out after first merge
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		)((props: any) => {
			const { relayData, relayProps = {}, ...restProps } = props;
			const relayEnabledProps = mapRelayToProps(relayData, relayProps);
			const combinedProps = { ...restProps, ...relayEnabledProps };

			return <Component {...combinedProps} />;
		});
	};
