import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	open: {
		id: 'software-security-common.status-lozenge.open',
		defaultMessage: 'OPEN',
		description:
			'Indicates the status of open associated vulnerabilities. This string is displayed for security vulnerabilities inside a table cell and the jira issue view.',
	},
	ignored: {
		id: 'software-security-common.status-lozenge.ignored',
		defaultMessage: 'IGNORED',
		description:
			'Indicates the status of ignored associated vulnerabilities. This string is displayed for security vulnerabilities inside a table cell and the jira issue view.',
	},
	closed: {
		id: 'software-security-common.status-lozenge.closed',
		defaultMessage: 'CLOSED',
		description:
			'Indicates the status of closed associated vulnerabilities. This string is displayed for security vulnerabilities inside a table cell and the jira issue view.',
	},
	unknown: {
		id: 'software-security-common.status-lozenge.unknown',
		defaultMessage: 'UNKNOWN',
		description:
			'Indicates the status of unknown associated vulnerabilities. This string is displayed for security vulnerabilities inside a table cell and the jira issue view.',
	},
	openCount: {
		id: 'software-security-common.status-lozenge.open-count',
		defaultMessage: '{count, plural, one {# OPEN} other {# OPEN}}',
		description:
			'Indicates the status of open associated vulnerabilities. This string is displayed for security vulnerabilities inside a table cell and the jira issue view.',
	},
	ignoredCount: {
		id: 'software-security-common.status-lozenge.ignored-count',
		defaultMessage: '{count, plural, one {# IGNORED} other {# IGNORED}}',
		description:
			'Indicates the status of ignored associated vulnerabilities. This string is displayed for security vulnerabilities inside a table cell and the jira issue view.',
	},
	closedCount: {
		id: 'software-security-common.status-lozenge.closed-count',
		defaultMessage: '{count, plural, one {# CLOSED} other {# CLOSED}}',
		description:
			'Indicates the status of closed associated vulnerabilities. This string is displayed for security vulnerabilities inside a table cell and the jira issue view.',
	},
	unknownCount: {
		id: 'software-security-common.status-lozenge.unknown-count',
		defaultMessage: '{count, plural, one {# UNKNOWN} other {# UNKNOWN}}',
		description:
			'Indicates the status of unknown associated vulnerabilities. This string is displayed for security vulnerabilities inside a table cell and the jira issue view.',
	},
});
