import toLower from 'lodash/toLower';

type SortDirection = 'ASC' | 'DESC';

type UnsafeComparator<TValue> = (arg1: TValue, arg2: TValue, arg3: SortDirection) => number;
export type SafeComparator<TValue> = (
	arg1: TValue | undefined,
	arg2: TValue | undefined,
	arg3: SortDirection,
) => number;

// arrow down: DESC (-1), arrow up: ASC (1)
export const factor = (direction: SortDirection) => (direction === 'ASC' ? 1 : -1);

export const createCombinedComparator =
	<TValue,>(comparators: SafeComparator<TValue>[]): SafeComparator<TValue> =>
	(a: TValue | undefined, b: TValue | undefined, direction: SortDirection): number => {
		for (const comparator of comparators) {
			const val = comparator(a, b, direction);
			if (val !== 0) {
				return val;
			}
		}
		return 0;
	};

export const nullSafeComparator =
	<TValue,>(comparator: UnsafeComparator<TValue>): SafeComparator<TValue> =>
	(a, b, direction) => {
		if (a === undefined && b === undefined) {
			return 0;
		}
		if (a === undefined) {
			return 1;
		}
		if (b === undefined) {
			return -1;
		}
		return comparator(a, b, direction);
	};

export const stringComparator = nullSafeComparator<string>(
	(a: string, b: string, direction: SortDirection): number => {
		const comparableA = toLower(a);
		const comparableB = toLower(b);
		if (comparableA < comparableB) return -1 * factor(direction);
		if (comparableB < comparableA) return factor(direction);
		return 0;
	},
);

const compareLength = <TValue,>(a: TValue[], b: TValue[], direction: SortDirection) => {
	if (a.length === 0 && b.length === 0) {
		return 0;
	}
	if (a.length === 0) {
		return 1;
	}
	if (b.length === 0) {
		return -1;
	}
	return (a.length - b.length) * factor(direction);
};

export const stringListComparator = nullSafeComparator<(string | undefined)[]>(
	(a: (string | undefined)[], b: (string | undefined)[], direction: SortDirection): number => {
		for (let i = 0; i < Math.min(a.length, b.length); i += 1) {
			const elementVal = stringComparator(a[i], b[i], direction);
			if (elementVal !== 0) {
				return elementVal;
			}
		}
		return compareLength(a, b, direction);
	},
);

export const safeStringListComparator = nullSafeComparator<string[]>(
	(a: string[], b: string[], direction: SortDirection): number => {
		for (let i = 0; i < Math.min(a.length, b.length); i += 1) {
			const elementVal = stringComparator(a[i], b[i], direction);
			if (elementVal !== 0) {
				return elementVal;
			}
		}
		return compareLength(a, b, direction);
	},
);

export const numberComparator = nullSafeComparator<number>(
	(a: number, b: number, direction: SortDirection): number => (a - b) * factor(direction),
);

export const numberListComparator = nullSafeComparator<(number | undefined)[]>(
	(a: (number | undefined)[], b: (number | undefined)[], direction: SortDirection): number => {
		for (let i = 0; i < Math.min(a.length, b.length); i += 1) {
			const elementVal = numberComparator(a[i], b[i], direction);
			if (elementVal !== 0) {
				return elementVal;
			}
		}
		return compareLength(a, b, direction);
	},
);

export const weightedListComparator = nullSafeComparator<(number | undefined)[]>(
	(a: (number | undefined)[], b: (number | undefined)[], direction: SortDirection): number => {
		const reducer = (acc: number, curVal = 0) => acc + curVal;
		const aWeight = a.reduce(reducer, 0);
		const bWeight = b.reduce(reducer, 0);

		return numberComparator(aWeight, bWeight, direction);
	},
);

export const statusCategoryAggregateComparator = nullSafeComparator<number[]>(
	(status1, status2, direction) => {
		const diff = status1.reduce((a, b) => a + b, 0) - status2.reduce((a, b) => a + b, 0);
		if (diff !== 0) {
			return diff * factor(direction);
		}
		for (let i = 0; i < 3; i += 1) {
			if (status1[i] - status2[i] !== 0) {
				return (status1[i] - status2[i]) * factor(direction);
			}
		}
		return 0;
	},
);
