import { performGetRequest, performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';

export type UserPropertiesApiResponse<T> = {
	key: string;
	value: T;
};

const getUserUrl = (accountId: string, propertyKey: string) =>
	`/rest/api/3/user/properties/${propertyKey}?accountId=${accountId}`;

export const setUserProperty = async <T,>({
	accountId,
	propertyKey,
	propertyValue,
}: {
	accountId: string;
	propertyKey: string;
	propertyValue: T;
}): Promise<void> => {
	const url = getUserUrl(accountId, propertyKey);

	try {
		await performPutRequest(url, {
			body: JSON.stringify(propertyValue),
		});
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		throw new Error(`Saving ${propertyKey} user property failed. Error: ${error}`);
	}
};

export const getUserProperty = async <T,>({
	accountId,
	propertyKey,
	defaultValue,
}: {
	accountId: string;
	propertyKey: string;
	defaultValue: T;
}): Promise<UserPropertiesApiResponse<T>> => {
	const url = getUserUrl(accountId, propertyKey);
	try {
		const response: UserPropertiesApiResponse<T> = await performGetRequest(url);
		return response;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		return { key: propertyKey, value: defaultValue };
	}
};
