/**
 * @generated SignedSource<<37eac46038373715a6842e6484fbce2f>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type assignToMe_issueFieldAssigneeAssignToMe_fragmentRef$data = {
  readonly fieldConfig: {
    readonly isEditable: boolean | null | undefined;
  } | null | undefined;
  readonly user: {
    readonly id: string;
  } | null | undefined;
  readonly " $fragmentType": "assignToMe_issueFieldAssigneeAssignToMe_fragmentRef";
};
export type assignToMe_issueFieldAssigneeAssignToMe_fragmentRef$key = {
  readonly " $data"?: assignToMe_issueFieldAssigneeAssignToMe_fragmentRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"assignToMe_issueFieldAssigneeAssignToMe_fragmentRef">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "assignToMe_issueFieldAssigneeAssignToMe_fragmentRef",
  "selections": [
    {
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "id"
        }
      ]
    },
    {
      "concreteType": "JiraFieldConfig",
      "kind": "LinkedField",
      "name": "fieldConfig",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isEditable"
        }
      ]
    }
  ],
  "type": "JiraSingleSelectUserPickerField"
};

(node as any).hash = "1efb163bcd31cbd76e54f4ecddc29e12";

export default node;
