import { useCallback, useEffect, useState } from 'react';

import {
	recaptchaExecuteFailed,
	recaptchaLoaded,
	recaptchaLoadFailed,
	useAnalytics,
} from '../../common/utils/analytics';

import type { RecaptchaOptions, RecaptchaResult } from './types';
import { hideRecaptchaBadge, loadRecaptcha, RecaptchaError, unloadRecaptcha } from './utils';

export const useInvisibleRecaptcha = ({
	siteKey,
	displayBadge = true,
	analyticsChannel,
	analyticsSource,
}: RecaptchaOptions): RecaptchaResult => {
	const triggerEvent = useAnalytics(analyticsChannel, analyticsSource);

	const [isRecaptchaLoaded, setIsRecaptchaLoaded] = useState(false);
	const [recaptchaLoadErrorReason, setRecaptchaLoadErrorReason] = useState<string>();

	useEffect(() => {
		let isMounted = true;

		const initRecaptcha = async () => {
			try {
				await loadRecaptcha(siteKey);
				if (!displayBadge) {
					hideRecaptchaBadge(siteKey);
				}
				triggerEvent(recaptchaLoaded);
				if (isMounted) {
					setIsRecaptchaLoaded(true);
					setRecaptchaLoadErrorReason(undefined);
				}
			} catch (err) {
				const errorMessage = String(err);
				triggerEvent(recaptchaLoadFailed, errorMessage);
				if (isMounted) {
					setRecaptchaLoadErrorReason(errorMessage);
				}
			}
		};

		initRecaptcha();

		return () => {
			unloadRecaptcha(siteKey);
			setRecaptchaLoadErrorReason(undefined);
			isMounted = false;
		};
	}, [displayBadge, siteKey, triggerEvent]);

	const getRecaptchaToken = useCallback(
		async (action: string) => {
			try {
				if (!isRecaptchaLoaded || !window?.grecaptcha?.enterprise?.execute) {
					throw new RecaptchaError('Execute function not ready');
				}
				return await window.grecaptcha.enterprise.execute(siteKey, { action });
			} catch (err) {
				triggerEvent(recaptchaExecuteFailed, String(err));
				throw err;
			}
		},
		[isRecaptchaLoaded, siteKey, triggerEvent],
	);

	return {
		isRecaptchaLoaded,
		getRecaptchaToken,
		recaptchaLoadErrorReason,
	};
};
