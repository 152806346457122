export const DESCRIPTION_BEGIN_MARK_KEY = 'DESCRIPTION_BEGIN_MARK';
export const DESCRIPTION_END_MARK_KEY = 'DESCRIPTION_END_MARK';

export const ATTACHMENTS_BEGIN_MARK_KEY = 'ATTACHMENTS_BEGIN_MARK';
export const ATTACHMENTS_END_MARK_KEY = 'ATTACHMENTS_END_MARK';

export const EPICS_BEGIN_MARK_KEY = 'EPICS_BEGIN_MARK';
export const EPICS_END_MARK_KEY = 'EPICS_END_MARK';

export const CHILDREN_ISSUES_BEGIN_MARK_KEY = 'CHILDREN_ISSUES_BEGIN_MARK';
export const CHILDREN_ISSUES_END_MARK_KEY = 'CHILDREN_ISSUES_END_MARK';

export const DESIGNS_BEGIN_MARK_KEY = 'DESIGNS_BEGIN_MARK';
export const DESIGNS_END_MARK_KEY = 'DESIGNS_END_MARK';

export const PORTFOLIO_CHILD_ISSUES_BEGIN_MARK_KEY = 'PORTFOLIO_CHILD_ISSUES_BEGIN_MARK';
export const PORTFOLIO_CHILD_ISSUES_END_MARK_KEY = 'PORTFOLIO_CHILD_ISSUES_END_MARK';

export const SUBTASKS_BEGIN_MARK_KEY = 'SUBTASKS_BEGIN_MARK';
export const SUBTASKS_END_MARK_KEY = 'SUBTASKS_END_MARK';

export const ISSUE_LINKS_BEGIN_MARK_KEY = 'ISSUE_LINKS_BEGIN_MARK';
export const ISSUE_LINKS_END_MARK_KEY = 'ISSUE_LINKS_END_MARK';

export const INCIDENT_INVESTIGATIONS_BEGIN_MARK_KEY = 'INCIDENT_INVESTIGATIONS_BEGIN_MARK';
export const INCIDENT_INVESTIGATIONS_END_MARK_KEY = 'INCIDENT_INVESTIGATIONS_END_MARK';

export const IT_OPS_DISCOVERABILITY_BEGIN_MARK_KEY = 'IT_OPS_DISCOVERABILITY_BEGIN_MARK_KEY';
export const IT_OPS_DISCOVERABILITY_END_MARK_KEY = 'IT_OPS_DISCOVERABILITY_END_MARK_KEY';

export const OBJECT_RELATED_REQUESTS_BEGIN_MARK_KEY = 'OBJECT_RELATED_REQUESTS_BEGIN_MARK';
export const OBJECT_RELATED_REQUESTS_END_MARK_KEY = 'OBJECT_RELATED_REQUESTS_END_MARK';

export const SMART_RELATED_ISSUES_BEGIN_MARK_KEY = 'SMART_RELATED_ISSUES_BEGIN_MARK';
export const SMART_RELATED_ISSUES_END_MARK_KEY = 'SMART_RELATED_ISSUES_END_MARK';

export const SUGGESTED_RESOURCES_BEGIN_MARK_KEY = 'SUGGESTED_RESOURCES_BEGIN_MARK_KEY';
export const SUGGESTED_RESOURCES_END_MARK_KEY = 'SUGGESTED_RESOURCES_END_MARK_KEY';

export const CONFLUENCE_PAGES_BEGIN_MARK_KEY = 'CONFLUENCE_PAGES_BEGIN_MARK';
export const CONFLUENCE_PAGES_END_MARK_KEY = 'CONFLUENCE_PAGES_END_MARK';

export const WEB_LINKS_BEGIN_MARK_KEY = 'WEB_LINKS_BEGIN_MARK';
export const WEB_LINKS_END_MARK_KEY = 'WEB_LINKS_END_MARK';

export const ADDITIONAL_WEB_LINKS_BEGIN_MARK_KEY = 'ADDITIONAL_WEB_LINKS_BEGIN_MARK';
export const ADDITIONAL_WEB_LINKS_END_MARK_KEY = 'ADDITIONAL_WEB_LINKS_END_MARK';

export const ECOSYSTEM_CONTENT_BEGIN_MARK_KEY = 'ECOSYSTEM_CONTENT_BEGIN_MARK';
export const ECOSYSTEM_CONTENT_END_MARK_KEY = 'ECOSYSTEM_CONTENT_END_MARK';

export const LINKED_ALERTS_BEGIN_MARK_KEY = 'LINKED_ALERTS_BEGIN_MARK';
export const LINKED_ALERTS_END_MARK_KEY = 'LINKED_ALERTS_END_MARK';

export const PROFORMA_FORMS_BEGIN_MARK_KEY = 'PROFORMA_FORMS_BEGIN_MARK';
export const PROFORMA_FORMS_END_MARK_KEY = 'PROFORMA_FORMS_END_MARK';

export const DEV_OPS_APP_RECOMMENDATIONS_BEGIN_MARK_KEY =
	'DEV_OPS_APP_RECOMMENDATIONS_BEGIN_MARK_KEY';
export const DEV_OPS_APP_RECOMMENDATIONS_END_MARK_KEY = 'DEV_OPS_APP_RECOMMENDATIONS_END_MARK_KEY';

export const JPD_IDEAS_BEGIN_MARK_KEY = 'JPD_IDEAS_BEGIN_MARK_KEY';
export const JPD_IDEAS_END_MARK_KEY = 'JPD_IDEAS_END_MARK_KEY';

export const LOOM_PANEL_BEGIN_MARK_KEY = 'LOOM_PANEL_BEGIN_MARK';
export const LOOM_PANEL_END_MARK_KEY = 'LOOM_PANEL_END_MARK';

export const PLAYBOOKS_PANEL_BEGIN_MARK_KEY = 'PLAYBOOKS_PANEL_BEGIN_MARK';
export const PLAYBOOKS_PANEL_END_MARK_KEY = 'PLAYBOOKS_PANEL_END_MARK';

export const AUTOMATION_DISCOVERY_PANEL_BEGIN_MARK_KEY = 'AUTOMATION_DISCOVERY_PANEL_BEGIN_MARK';
export const AUTOMATION_DISCOVERY_PANEL_END_MARK_KEY = 'AUTOMATION_DISCOVERY_PANEL_END_MARK';
