import { fg } from '@atlassian/jira-feature-gating';
import type { MessageDescriptorV2 } from '@atlassian/jira-intl/src/v2/types.tsx';
import messages from '../messages.tsx';

export const getVoteLabelForDropdown = (hasVoted: boolean, votes: number): MessageDescriptorV2 => {
	if (hasVoted) {
		return fg('jira-issue-terminology-refresh-m3')
			? messages.voteCountWithSelfLabelIssueTermRefresh
			: messages.voteCountWithSelfLabel;
	}
	const voteCountWithoutSelfLabelMessage = fg('jira-issue-terminology-refresh-m3')
		? messages.voteCountWithoutSelfLabelIssueTermRefresh
		: messages.voteCountWithoutSelfLabel;
	const noOneHasVotedLabelMessage = fg('jira-issue-terminology-refresh-m3')
		? messages.noOneHasVotedLabelIssueTermRefresh
		: messages.noOneHasVotedLabel;
	return votes ? voteCountWithoutSelfLabelMessage : noOneHasVotedLabelMessage;
};

export const getLabelForToggleWithSelfVote = (): MessageDescriptorV2 =>
	fg('jira-issue-terminology-refresh-m3')
		? messages.voteCountWithSelfLabelForToggleIssueTermRefresh
		: messages.voteCountWithSelfLabelForToggle;

export const getLabelForToggleWithoutSelfVote = (votes: number): MessageDescriptorV2 => {
	const voteCountWithoutSelfLabelForToggleMessage = fg('jira-issue-terminology-refresh-m3')
		? messages.voteCountWithoutSelfLabelForToggleIssueTermRefresh
		: messages.voteCountWithoutSelfLabelForToggle;

	const noOneHasVotedLabelForToggleMessage = fg('jira-issue-terminology-refresh-m3')
		? messages.noOneHasVotedLabelForToggleIssueTermRefresh
		: messages.noOneHasVotedLabelForToggle;

	return votes ? voteCountWithoutSelfLabelForToggleMessage : noOneHasVotedLabelForToggleMessage;
};
