import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fireTrackAnalytics, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { sendPendoTrackEvent } from '../../pendo/index.tsx';
import { RESULTS } from '../constants.tsx';

export const View = {
	createdSuccess: (event: UIAnalyticsEvent, viewKind: string) => {
		// https://data-portal.internal.atlassian.com/analytics/registry/47738
		fireTrackAnalytics(event, 'view created', {
			result: RESULTS.SUCCESS,
			targetViewKind: viewKind,
		});
	},
	createdFailure: (event: UIAnalyticsEvent, viewKind: string) => {
		// https://data-portal.internal.atlassian.com/analytics/registry/47738
		fireTrackAnalytics(event, 'view created', {
			result: RESULTS.FAILURE,
			targetViewKind: viewKind,
		});
	},
	nameChanged: (event: UIAnalyticsEvent) => {
		// https://data-portal.internal.atlassian.com/analytics/registry/47648
		fireTrackAnalytics(event, 'view updated', {
			updatedItems: [{ name: 'name' }],
		});
	},
	emojiChanged: (event: UIAnalyticsEvent) => {
		// https://data-portal.internal.atlassian.com/analytics/registry/47648
		fireTrackAnalytics(event, 'view updated', 'emojiChanged');
	},
	copyViewCommentLinkButtonClicked: (event: UIAnalyticsEvent) => {
		// https://data-portal.internal.atlassian.com/analytics/registry/48689
		fireUIAnalytics(event, 'button clicked', 'copyViewCommentLinkButton');
	},
	viewSettingsOpened: () => {
		sendPendoTrackEvent('viewSettings opened');
	},
	autosaveChanged: (event: UIAnalyticsEvent, isEnabled: boolean) => {
		sendPendoTrackEvent(isEnabled ? 'autosave enabled' : 'autosave disabled');
		fireUIAnalytics(event, 'autosave changed', { isEnabled });
	},
	unsavedChangesReset: (event: UIAnalyticsEvent) => {
		sendPendoTrackEvent('unsavedChanges reset');
		fireUIAnalytics(event, 'unsavedChanges reset');
	},
	unsavedChangesSave: (event: UIAnalyticsEvent) => {
		sendPendoTrackEvent('unsavedChanges saved');
		fireUIAnalytics(event, 'unsavedChanges saved');
	},
	unsavedChangesSaveAsNew: (event: UIAnalyticsEvent) => {
		sendPendoTrackEvent('unsavedChanges savedAsNew');
		fireUIAnalytics(event, 'unsavedChanges savedAsNew');
	},
	viewLayoutSettingOpened: () => {
		sendPendoTrackEvent('viewLayoutSetting opened');
	},
	viewLayoutChanged: (event: UIAnalyticsEvent, layoutType: string) => {
		sendPendoTrackEvent(`view layout changed - ${layoutType.toLowerCase()}`);
		fireUIAnalytics(event, 'viewLayout changed', { layoutType });
	},
};
