/**
 * @generated SignedSource<<cd90d3f90f7aeaeaff9701d58c665bde>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type AccountStatus = "active" | "closed" | "inactive" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type people_issueFieldPeopleReadviewFull_PeopleReadView$data = {
  readonly selectedUsersConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly accountId: string;
        readonly accountStatus: AccountStatus;
        readonly email?: string | null | undefined;
        readonly id: string;
        readonly name: string;
        readonly picture: AGG$URL;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "people_issueFieldPeopleReadviewFull_PeopleReadView";
};
export type people_issueFieldPeopleReadviewFull_PeopleReadView$key = {
  readonly " $data"?: people_issueFieldPeopleReadviewFull_PeopleReadView$data;
  readonly " $fragmentSpreads": FragmentRefs<"people_issueFieldPeopleReadviewFull_PeopleReadView">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "people_issueFieldPeopleReadviewFull_PeopleReadView",
  "selections": [
    {
      "concreteType": "JiraUserConnection",
      "kind": "LinkedField",
      "name": "selectedUsersConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraUserEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "accountId"
                },
                {
                  "kind": "ScalarField",
                  "name": "id"
                },
                {
                  "kind": "ScalarField",
                  "name": "accountStatus"
                },
                {
                  "kind": "ScalarField",
                  "name": "name"
                },
                {
                  "kind": "ScalarField",
                  "name": "picture"
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "email"
                    }
                  ],
                  "type": "AtlassianAccountUser"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraPeopleField"
};

(node as any).hash = "31e16e5ea33b4f5a3248bfd084b99ea1";

export default node;
