import type { Timing } from '@atlassian/jira-forge-ui-analytics/src/common/utils/performance-analytics/types.tsx';
import { PERFORMANCE_KEYS } from '@atlassian/jira-forge-ui-analytics/src/constants.tsx';
import type {
	CustomField,
	CustomFieldType,
} from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import * as issueMarks from '@atlassian/jira-issue-view-common-constants/src/mark-types.tsx';
import * as marks from '@atlassian/jira-issue-view-layout-templates-constants/src/performance.tsx';

export function getPerformanceCustomData(
	source?: string,
	// @ts-expect-error - TS1016 - A required parameter cannot follow an optional parameter.
	renderContext: string,
	extension: CustomField | CustomFieldType | null,
):
	| {
			[key: string]: string | boolean;
	  }
	| undefined {
	if (extension?.properties) {
		let viewRendering;
		let editRendering;

		if (typeof extension.properties.function === 'string') {
			viewRendering = 'ui-kit';
		} else if (typeof extension.properties.formatter?.expression === 'string') {
			viewRendering = 'formatter';
		} else {
			viewRendering = 'default';
		}

		// @ts-expect-error - TS2339 - Property 'function' does not exist on type '{ function: string; } | { resource: string; }'.
		if (typeof extension.properties.edit?.function === 'string') {
			editRendering = 'ui-kit';
			// @ts-expect-error - TS2339 - Property 'resource' does not exist on type '{ function: string; } | { resource: string; }'.
		} else if (typeof extension.properties.edit?.resource === 'string') {
			editRendering = 'custom-ui';
		} else {
			editRendering = 'default';
		}

		return typeof source === 'string'
			? {
					source,
					renderContext,
					fieldType: extension.properties.type,
					hasValueFunction: typeof extension.properties.value?.function === 'string',
					viewRendering,
					editRendering,
				}
			: {
					renderContext,
					fieldType: extension.properties.type,
					hasValueFunction: typeof extension.properties.value?.function === 'string',
					viewRendering,
					editRendering,
				};
	}

	return undefined;
}

export function getAdditionalPageSegmentLoadTimings(): Timing[] {
	const additionalPageSegmentLoadTimings: Timing[] = [
		// TODO ARKEN-363: might not be accurate
		{
			key: 'issueView',
			startMark: issueMarks.ISSUE_START_RENDERING_MARK,
			endMark: issueMarks.ISSUE_RENDERED,
		},
		{
			key: 'pinnedItems',
			startMark: marks.PINNED_FIELDS_BEGIN_MARK_KEY,
			endMark: marks.PINNED_FIELDS_END_MARK_KEY,
		},
		{
			key: 'detailsFields',
			startMark: marks.DETAILS_FIELDS_BEGIN_MARK_KEY,
			endMark: marks.DETAILS_FIELDS_END_MARK_KEY,
		},
		{
			key: 'moreFields',
			startMark: marks.MORE_FIELDS_BEGIN_MARK_KEY,
			endMark: marks.MORE_FIELDS_END_MARK_KEY,
		},
		{
			key: 'customFieldAsyncBundle',
			startMark: PERFORMANCE_KEYS.MARKS.ASYNC_FORGE_UI_CUSTOM_FIELD_BUNDLE_START,
			endMark: PERFORMANCE_KEYS.MARKS.ASYNC_FORGE_UI_CUSTOM_FIELD_BUNDLE_END,
			cleanStart: true,
			cleanEnd: true,
		},
		{
			key: 'customFieldValueFunction',
			startMark: PERFORMANCE_KEYS.MARKS.ASYNC_FORGE_UI_CUSTOM_FIELD_VALUE_FUNCTION_START,
			endMark: PERFORMANCE_KEYS.MARKS.ASYNC_FORGE_UI_CUSTOM_FIELD_VALUE_FUNCTION_END,
			cleanStart: true,
			cleanEnd: true,
		},
	];

	return additionalPageSegmentLoadTimings;
}
