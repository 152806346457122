import { defineMessages } from 'react-intl-next';

const messages = defineMessages({
	termsAndPolicy: {
		id: 'growth.google-recaptcha.terms-and-policy.text',
		description: 'Text substitute for reCAPTCHA branding when badge is visibly hidden',
		defaultMessage:
			'This site is protected by reCAPTCHA and the Google {privacyPolicy} and {termsOfService} apply.',
	},
	privacyPolicy: {
		id: 'growth.google-recaptcha.terms-and-policy.privacy-policy',
		description: 'Privacy Policy link text',
		defaultMessage: 'Privacy Policy',
	},
	termsOfService: {
		id: 'growth.google-recaptcha.terms-and-policy.terms-of-service',
		description: 'Terms of Service link text',
		defaultMessage: 'Terms of Service',
	},
});

export default messages;
