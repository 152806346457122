import {
	categoryIdForStatusCategory,
	DONE,
} from '@atlassian/jira-common-constants/src/status-categories.tsx';
import type { IssueFieldConfigurationType } from '@atlassian/jira-issue-field-base/src/services/field-config-service/types.tsx';
import type { LayoutItems } from '@atlassian/jira-issue-view-layout-templates-services/src/services/context/visible-hidden/visible-hidden-items/index.tsx';
import { addGlobalRefToLayoutContainerTemplateItems } from '@atlassian/jira-issue-view-layout-templates-utils/src/index.tsx';
import { getLayoutItemId } from '@atlassian/jira-issue-view-layout/src/services/utils.tsx';
import {
	REQUEST_FEEDBACK_CF_TYPE,
	SENTIMENT_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';

export const transformCSAT = (
	isNextGen: boolean,
	items: LayoutItems | undefined,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	status: any,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	issueFieldsConfig: any,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	issueFieldsValues: any,
) => {
	if (!items) {
		return undefined;
	}

	if (!items.visibleItems || !issueFieldsConfig || !issueFieldsValues || !status) {
		return items;
	}

	if (!isNextGen) {
		return items;
	}

	const CsatField = items.visibleItems.find((item) => {
		const fieldConfig = issueFieldsConfig[getLayoutItemId(item)];
		return fieldConfig?.schema?.custom === REQUEST_FEEDBACK_CF_TYPE;
	});

	if (CsatField === undefined) {
		return items;
	}

	const itemsWithoutCsatField = items.visibleItems.filter((item) => {
		const fieldConfig = issueFieldsConfig[getLayoutItemId(item)];
		return fieldConfig?.schema?.custom !== REQUEST_FEEDBACK_CF_TYPE;
	});
	const hasValue =
		issueFieldsValues[getLayoutItemId(CsatField)] !== undefined &&
		issueFieldsValues[getLayoutItemId(CsatField)] !== null;

	if (status.statusCategory.id !== categoryIdForStatusCategory(DONE) || !hasValue) {
		return {
			...items,
			visibleItems: itemsWithoutCsatField,
		};
	}

	return {
		...items,
		visibleItems: [CsatField, ...itemsWithoutCsatField],
	};
};

/**
 * Layout items need a global reference for spotlight. This adds it.
 *
 * @param items All the relevant layoutitems to add a reference to
 * @param issueFieldsConfig The field configuration for the issue
 * @returns The passed layout item object, optionally enriched with global references for spotlight
 */
export const addGlobalRefs = (
	items?: LayoutItems,
	issueFieldsConfig?: IssueFieldConfigurationType['value'],
) => ({
	hiddenItems: addGlobalRefToLayoutContainerTemplateItems(items?.hiddenItems, issueFieldsConfig),
	pinnedFields: addGlobalRefToLayoutContainerTemplateItems(items?.pinnedFields, issueFieldsConfig),
	visibleItems: addGlobalRefToLayoutContainerTemplateItems(items?.visibleItems, issueFieldsConfig),
	apps: {
		contextPanels: addGlobalRefToLayoutContainerTemplateItems(
			items?.apps?.contextPanels ?? [],
			issueFieldsConfig,
		),
	},
});

export const isSentimentCustomFieldShown = (
	items: LayoutItems | undefined,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	issueFieldsConfig: any,
): boolean => {
	const isVisible =
		items?.visibleItems.some((item) => {
			const fieldConfig = issueFieldsConfig && issueFieldsConfig[getLayoutItemId(item)];
			return fieldConfig?.schema?.custom === SENTIMENT_CF_TYPE;
		}) || false;
	const isHidden =
		items?.hiddenItems.some((item) => {
			const fieldConfig = issueFieldsConfig && issueFieldsConfig[getLayoutItemId(item)];
			return fieldConfig?.schema?.custom === SENTIMENT_CF_TYPE;
		}) || false;
	const isPinned =
		items?.pinnedFields.some((item) => {
			const fieldConfig = issueFieldsConfig && issueFieldsConfig[getLayoutItemId(item)];
			return fieldConfig?.schema?.custom === SENTIMENT_CF_TYPE;
		}) || false;

	return isVisible || isHidden || isPinned;
};
