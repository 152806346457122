/**
 * @generated SignedSource<<ac973913f9060bb519739c5dc997ac7d>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type priority_issueFieldPriorityReadviewFull_PriorityReadView$data = {
  readonly priority: {
    readonly iconUrl: AGG$URL | null | undefined;
    readonly name: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "priority_issueFieldPriorityReadviewFull_PriorityReadView";
};
export type priority_issueFieldPriorityReadviewFull_PriorityReadView$key = {
  readonly " $data"?: priority_issueFieldPriorityReadviewFull_PriorityReadView$data;
  readonly " $fragmentSpreads": FragmentRefs<"priority_issueFieldPriorityReadviewFull_PriorityReadView">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "priority_issueFieldPriorityReadviewFull_PriorityReadView",
  "selections": [
    {
      "concreteType": "JiraPriority",
      "kind": "LinkedField",
      "name": "priority",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "name"
        },
        {
          "kind": "ScalarField",
          "name": "iconUrl"
        }
      ]
    }
  ],
  "type": "JiraPriorityField"
};

(node as any).hash = "332bda8ea012bca1bc3e1ad5426cb13a";

export default node;
