import React from 'react';
import { useFragment, graphql } from 'react-relay';
import { SentimentInlineEditView } from '@atlassian/jira-issue-field-sentiment-inline-edit-full/src/ui/sentiment/index.tsx';
import type { Area } from '@atlassian/jira-issue-view-common-types/src/connect-field-type.tsx';
import { IssueViewFieldHeading } from '@atlassian/jira-issue-view-layout-field-heading/src/ui/index.tsx';
import type { ui_issueViewLayoutSentimentField_IssueViewSentimentField$key } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutSentimentField_IssueViewSentimentField.graphql';

type Props = {
	area?: Area;
	fragmentKey: ui_issueViewLayoutSentimentField_IssueViewSentimentField$key;
};

const attributes = {
	// If the update was made inline (and not via the issue view)
	isInlineEditing: true,
};

export const IssueViewSentimentField = ({ area, fragmentKey }: Props) => {
	const data = useFragment<ui_issueViewLayoutSentimentField_IssueViewSentimentField$key>(
		graphql`
			fragment ui_issueViewLayoutSentimentField_IssueViewSentimentField on JiraServiceManagementSentimentField {
				...ui_issueViewLayoutFieldHeading_IssueViewFieldHeading
				...sentiment_issueFieldSentimentInlineEditFull_SentimentInlineEditView
				fieldId
				fieldConfig {
					isEditable
				}
			}
		`,
		fragmentKey,
	);

	return (
		<IssueViewFieldHeading
			area={area}
			fragmentKey={data}
			testId={`issue.issue-view-layout.issue-view-sentiment-field.${data.fieldId}`}
		>
			<SentimentInlineEditView
				attributes={attributes}
				fragmentKey={data}
				isEditable={data.fieldConfig?.isEditable ?? false}
				editViewPopupAlignBlock="center"
				menuPosition="fixed"
				spacing="default"
				readViewFitContainerHeight
			/>
		</IssueViewFieldHeading>
	);
};
