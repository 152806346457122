import React, { useEffect, useState, type ComponentPropsWithoutRef } from 'react';
import { styled } from '@compiled/react';
import type { JSONDocNode } from '@atlaskit/editor-json-transformer';
import { N70, N500 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
// TODO move InfoIcon to local asset when we no longer need the old description package https://jplat.jira.atlassian.cloud/browse/JIV-12738
import InfoIcon from '@atlassian/jira-issue-field-description/src/ui/assets/info-icon.tsx';
// TODO move Popup to local asset when we no longer need the old description package https://jplat.jira.atlassian.cloud/browse/JIV-12738
import { FieldIconPopup } from '@atlassian/jira-issue-field-icon-popup/src/FieldIconPopup.tsx';
import { convertWikiToAdfAsync } from '@atlassian/jira-platform-convert-wiki-to-adf/src/async.tsx';
import { fieldDescriptionWrapperSelectorName } from '../constants.tsx';
import type { Props } from './types.tsx';

// TODO: replace description with relay field fragment https://jplat.jira.atlassian.cloud/browse/JIV-12737
export const FieldDescriptionIcon = ({ description }: Props) => {
	const [adfDescription, setAdfDescription] = useState<JSONDocNode | null>(null);

	useEffect(() => {
		convertWikiToAdfAsync(description).then((convertedAdfValue) => {
			setAdfDescription(convertedAdfValue);
		});
	}, [description]);

	if (!adfDescription) {
		return null;
	}

	return (
		<FieldDescriptionWrapper>
			<FieldIconPopup adfDescription={adfDescription} label="">
				<InfoIcon />
			</FieldIconPopup>
		</FieldDescriptionWrapper>
	);
};

export const FieldDescriptionWrapper = (
	props: ComponentPropsWithoutRef<typeof FieldDescriptionWrapperComponent>,
) => (
	<FieldDescriptionWrapperComponent
		data-component-selector={fieldDescriptionWrapperSelectorName}
		{...props}
	/>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldDescriptionWrapperComponent = styled.div({
	color: token('color.background.neutral.bold', N70),
	cursor: 'pointer',
	marginLeft: token('space.050', '4px'),
	marginTop: token('space.025', '2px'),
	flexShrink: 0,
	'&:hover': {
		color: token('color.background.neutral.bold.hovered', N500),
	},
});
