import { useEditField } from '@atlassian/jira-issue-field-base/src/services/edit-field-service/main.tsx';
import { useFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import { getFieldType } from '@atlassian/jira-issue-view-common-utils/src/fields/index.tsx';
import { useSingleSelectMutation } from '../use-single-select-mutation/index.tsx';
import type {
	SingleSelectFieldValue,
	MultiSelectFieldValue,
	SelectFieldProps,
	MultiSelectSaveId,
	MultiSelectShapeResponse,
	CommonSelectFieldProps,
	SingleSelectSaveId,
	SingleSelectShapeResponse,
} from './types.tsx';

const useSelectField = <T, R, I>({
	issueKey,
	fieldKey,
	fieldDefaultSchemaType,
	onSuccess,
	onFailure,
	saveField,
	fieldType,
	initialValue,
}: CommonSelectFieldProps<T, R, null, I>) => {
	const [fieldConfig] = useFieldConfig(issueKey, fieldKey);
	const resolvedFieldType = fieldType || getFieldType(fieldConfig.value) || fieldDefaultSchemaType;
	const [{ value, loading, error }, { saveValue, saveById, resetError }] = useEditField<
		T,
		null,
		R,
		I
	>({
		fieldKey,
		issueKey,
		fieldType: resolvedFieldType,
		// @ts-expect-error Fix in https://jplat.jira.atlassian.cloud/browse/JIV-17559
		initialValue,
		onSuccess,
		onFailure,
		saveField,
	});

	return [
		{ value, loading, error, fieldConfig },
		{ saveValue, saveById, resetError },
	] as const;
};

export const useSingleSelectField = <
	T extends SingleSelectFieldValue = SingleSelectFieldValue,
	R extends SingleSelectShapeResponse = SingleSelectShapeResponse,
	I extends SingleSelectSaveId = SingleSelectSaveId,
>(
	props: SelectFieldProps<T, R, null, I>,
) => {
	const saveSingleSelectField = useSingleSelectMutation<T, R>();

	return useSelectField<T, R | null, I>({
		...props,
		saveField: saveSingleSelectField,
		fieldDefaultSchemaType: 'option',
	});
};

export const useMultiSelectField = <
	T extends MultiSelectFieldValue = MultiSelectFieldValue,
	R extends MultiSelectShapeResponse = MultiSelectShapeResponse,
	I extends MultiSelectSaveId = MultiSelectSaveId,
>(
	props: SelectFieldProps<T, R, null, I>,
) =>
	useSelectField<T, R, I>({
		...props,
		fieldDefaultSchemaType: 'array',
	});
