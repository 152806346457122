import React, { useMemo } from 'react';
import noop from 'lodash/noop';
import TrashIcon from '@atlaskit/icon/core/migration/delete--trash';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import GoToButton from '@atlassian/jira-issue-attachments-base/src/ui/goto-button/index.tsx';
import { filmStripView } from '@atlassian/jira-issue-attachments-base/src/ui/types.tsx';
import { issueAttachmentsDeleteModalEntrypoint } from '@atlassian/jira-issue-attachments-base/src/ui/with-issue-attachments-delete/entrypoint.tsx';
import { ATTACHMENT_PARENT_ISSUE } from '@atlassian/jira-issue-attachments-store/src/common/constants.tsx';
import type {
	AttachmentId,
	AttachmentParent,
} from '@atlassian/jira-issue-attachments-store/src/common/types.tsx';
import type { CardCover } from '@atlassian/jira-issue-view-common-types/src/card-cover-type.tsx';
import { ModalEntryPointPressableTrigger } from '@atlassian/jira-modal-entry-point-pressable-trigger/src/ModalEntryPointPressableTrigger.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import i18n from './messages.tsx';

const getDeleteOrGotoActionForAttachment = (
	attachmentId: AttachmentId,
	attachmentParentName: AttachmentParent,
	attachmentParentId: string,
	canDeleteAttachment: boolean,
	intl: IntlShape,
	issueKey: IssueKey,
	onScrollToComments: () => void,
	onScrollToWorklogs: () => void,
) => {
	const deleteMessage = intl.formatMessage(i18n.delete);

	if (attachmentParentName === ATTACHMENT_PARENT_ISSUE) {
		// no permission to delete attachment
		if (!canDeleteAttachment) {
			return [];
		}

		return [
			{
				label: deleteMessage,
				handler: noop,
				icon: <DeleteTrashIcon attachmentId={attachmentId} deleteMessage={deleteMessage} />,
			},
		];
	}

	// show the goto button as attachment in either commment/workog, customfield, description or envrionment
	return [
		{
			label: intl.formatMessage(i18n.goto),
			handler: noop,
			icon: (
				<GoToButton
					attachmentParent={attachmentParentName}
					attachmentParentId={attachmentParentId}
					attachmentView={filmStripView}
					onScrollToComments={onScrollToComments}
					onScrollToWorklogs={onScrollToWorklogs}
					issueKey={issueKey}
					// This is a div to match the CardActionButton in the media-card component
					component="div"
					attachmentId={attachmentId}
				/>
			),
		},
	] as const;
};

const DeleteTrashIcon = ({
	attachmentId,
	deleteMessage,
}: {
	attachmentId: AttachmentId;
	deleteMessage: string;
}) => {
	const entryPointProps = useMemo(
		() => ({
			attachmentView: filmStripView,
			deletingAttachmentId: attachmentId,
		}),
		[attachmentId],
	);
	const modalProps = useMemo(() => ({ width: 'small' }), []);
	return (
		<ModalEntryPointPressableTrigger
			entryPoint={issueAttachmentsDeleteModalEntrypoint}
			entryPointProps={entryPointProps}
			modalProps={modalProps}
			useInternalModal
			interactionName="issue-attachment-table-delete-attachment"
		>
			{({ ref }) => (
				<span ref={ref}>
					<TrashIcon
						color="currentColor"
						label={deleteMessage}
						testId={`issue.views.issue-base.content.attachment.filmstrip-view.trash-icon.${attachmentId}`}
					/>
				</span>
			)}
		</ModalEntryPointPressableTrigger>
	);
};

const getCardCoverActionForAttachment = (
	attachmentId: AttachmentId,
	cardCover: CardCover | null,
	intl: IntlShape,
	onCardCoverShow: (arg1: number | null, arg2: number) => void,
	onCardCoverHide: (arg1: number) => void,
) => {
	const idAsNumber = Number(attachmentId);
	const hideMsg = intl.formatMessage(i18n.hide);
	const showMsg = intl.formatMessage(i18n.show);

	if (
		!cardCover ||
		!cardCover.isEnabled ||
		!cardCover.availableAttachmentIds.includes(idAsNumber)
	) {
		return [];
	}

	if (idAsNumber === cardCover.attachmentId) {
		return [
			{
				label: hideMsg,
				handler: () => {
					onCardCoverHide(idAsNumber);
				},
			},
		];
	}
	return [
		{
			label: showMsg,
			handler: () => {
				onCardCoverShow(cardCover.attachmentId, idAsNumber);
			},
		},
	] as const;
};

export const getActionsForAttachment = (
	attachmentId: AttachmentId,
	attachmentParentName: AttachmentParent,
	attachmentParentId: string,
	cardCover: CardCover | null,
	canDeleteAttachment: boolean,
	intl: IntlShape,
	issueKey: IssueKey,
	onCardCoverShow: (arg1: number | null, arg2: number) => void,
	onCardCoverHide: (arg1: number) => void,
	onScrollToComments: () => void,
	onScrollToWorklogs: () => void,
) => [
	...getDeleteOrGotoActionForAttachment(
		attachmentId,
		attachmentParentName,
		attachmentParentId,
		canDeleteAttachment,
		intl,
		issueKey,
		onScrollToComments,
		onScrollToWorklogs,
	),
	...getCardCoverActionForAttachment(
		attachmentId,
		cardCover,
		intl,
		onCardCoverShow,
		onCardCoverHide,
	),
];
