import React from 'react';
import { useFragment, graphql } from 'react-relay';
import type { ui_issueViewLayoutMetaUpdatedDateField_IssueViewUpdatedDateField$key } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutMetaUpdatedDateField_IssueViewUpdatedDateField.graphql';
import { UpdatedDate as UpdatedDateFieldReadView } from '@atlassian/jira-updated-date/src/ui/read/main.tsx';

export interface IssueViewUpdatedDateFieldProps {
	fragmentKey: ui_issueViewLayoutMetaUpdatedDateField_IssueViewUpdatedDateField$key;
}

export const IssueViewUpdatedDateField = ({ fragmentKey }: IssueViewUpdatedDateFieldProps) => {
	const data = useFragment<ui_issueViewLayoutMetaUpdatedDateField_IssueViewUpdatedDateField$key>(
		graphql`
			fragment ui_issueViewLayoutMetaUpdatedDateField_IssueViewUpdatedDateField on JiraDateTimePickerField {
				dateTime
			}
		`,
		fragmentKey,
	);

	if (!data.dateTime) {
		return null;
	}

	return <UpdatedDateFieldReadView date={data.dateTime} />;
};
