import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	crossflowIdeaSummary: {
		id: 'issue-view-jpd-ideas.crossflow-ideas-summary',
		defaultMessage: 'Supporting idea with customer insights',
		description:
			'An invitation to link an issue to a JPD idea, displayed in an issue view smart link',
	},
	crossflowIdeaTooltip: {
		id: 'issue-view-jpd-ideas.crossflow-ideas-tooltip',
		defaultMessage: 'Create and link an idea to this issue',
		description: 'An invitation to link an issue to a JPD idea, displayed in a tooltip',
	},
	tryLozenge: {
		id: 'issue-view-jpd-ideas.crossflow-ideas-try-lozenge',
		defaultMessage: 'Try',
		description: 'An lozenge indicating that the user should try JPD',
	},
	fallbackIdeaName: {
		id: 'issue-view-jpd-ideas.crossflow-ideas-fallback-idea-name',
		defaultMessage: 'My supporting idea',
		description:
			'A generic fallback issue name that will be used to create JPD idea as a last resort.',
	},
});
