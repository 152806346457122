/* The relay/unused-fields rule is disabled as this file is only responsible for fetching from AGG.
 * When we decompose this query and move to Relay fragments this will be removed.
 */

import { fetchQuery } from 'react-relay';
import type { IEnvironment } from 'relay-runtime';
import { metrics } from '@atlassian/browser-metrics';
import { fg } from '@atlassian/jira-feature-gating';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { extractProjectKey } from '@atlassian/jira-issue-fetch-services-common/src/common/utils/extract-project-key.tsx';
import { ISSUE_AGG_OPERATION_NAME } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/main.tsx';
import { getMainIssueViewAggQueryVariables } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/utils.tsx';
import { isInSample } from '@atlassian/jira-issue-sample-utils/src/common/utils/index.tsx';
import {
	sendExperienceAnalytics,
	type ExperienceDescription,
} from '@atlassian/jira-issue-view-analytics/src/controllers/send-experience-analytics/index.tsx';
import {
	startCaptureGraphQlErrors,
	stopCaptureGraphQlErrors,
	type AGGError,
} from '@atlassian/jira-relay-errors/src/index.tsx';
import mainIssueAggQuery, {
	type mainIssueAggQuery as mainIssueAggQueryType,
	type mainIssueAggQuery$data as mainIssueAggQueryResponse,
} from '@atlassian/jira-relay/src/__generated__/mainIssueAggQuery.graphql';
import type { IssueKey, CloudId } from '@atlassian/jira-shared-types/src/general.tsx';
import {
	startCapturingTraceIds,
	stopCapturingTraceIds,
	getTraceIds,
} from '@atlassian/relay-traceid';

export type IssueAggData = {
	data?: mainIssueAggQueryResponse;
	errors?: AGGError[];
	traceId?: string;
};

export type mainIssueAGGQueryExperienceDescription = {
	wasExperienceSuccessful: boolean;
	errorMessage?: string;
	traceId?: string;
};

export type mainIssueAGGExperienceTrackingEvent = {
	wasExperienceSuccessful: boolean;
	error?: FetchError | Error;
};

const ISSUE_AGG_DATA = 'issue-agg-data';
// visible for testing
export const timeToFetchIssueAggDataMetric = metrics.custom({
	key: ISSUE_AGG_DATA,
});

export const captureIssueViewAggErrors = (key: string) => {
	const errors = stopCaptureGraphQlErrors(key)
		.filter((errorWrapper) => errorWrapper.meta.operationName === ISSUE_AGG_OPERATION_NAME)
		.flatMap((errorWrapper) => errorWrapper.errors);
	return errors;
};

const mainIssueAGGQueryExperienceDescription = ({
	wasExperienceSuccessful,
	errorMessage,
	traceId,
}: mainIssueAGGQueryExperienceDescription): ExperienceDescription => {
	const errorMessageItem: { errorMessage: string } | {} =
		errorMessage != null ? { errorMessage } : {};
	const traceIdItem: { traceId: string } | {} = traceId != null ? { traceId } : {};

	return {
		experience: ISSUE_AGG_OPERATION_NAME,
		wasExperienceSuccesful: wasExperienceSuccessful,
		analyticsSource: 'Issue View Main Issue AGG Query',
		additionalAttributes: { method: 'GET', ...errorMessageItem, ...traceIdItem },
		application: null,
		edition: null,
	};
};

const mainIssueAGGQueryExperienceTracking = ({
	wasExperienceSuccessful,
	error,
}: mainIssueAGGExperienceTrackingEvent) => {
	sendExperienceAnalytics(
		mainIssueAGGQueryExperienceDescription({
			wasExperienceSuccessful,
			...(error?.message != null ? { errorMessage: error.message } : {}),
			...(error instanceof FetchError && error?.traceId != null ? { traceId: error.traceId } : {}),
		}),
	);
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default async (
	issueKey: IssueKey,
	cloudId: CloudId,
	siteAri: string,
	environment: IEnvironment,
): Promise<IssueAggData> => {
	if (isInSample()) {
		timeToFetchIssueAggDataMetric.start();
	}

	// ConcreteRequest has params, but ReaderFragment doesn't
	const operationName = mainIssueAggQuery.params.name;
	startCapturingTraceIds(operationName);

	const id = startCaptureGraphQlErrors();

	const projectKey = extractProjectKey(issueKey);

	const data = await fetchQuery<mainIssueAggQueryType>(environment, mainIssueAggQuery, {
		cloudId,
		issueKey,
		projectKey,
		siteAri,
		...getMainIssueViewAggQueryVariables(),
	})
		.toPromise()
		.catch((error) => {
			// Cancel the metric and re-throw the error
			timeToFetchIssueAggDataMetric.cancel();
			if (fg('add_metrics_to_main_issue_agg_query')) {
				mainIssueAGGQueryExperienceTracking({
					wasExperienceSuccessful: false,
					error: error instanceof FetchError ? error : undefined,
				});
			}

			throw error;
		});
	const errors = captureIssueViewAggErrors(id);

	if (fg('add_metrics_to_main_issue_agg_query')) {
		mainIssueAGGQueryExperienceTracking({
			wasExperienceSuccessful: errors.length === 0,
			...(errors.length > 0 && { error: new Error('Unexpected Main Issue AGG response') }),
		});
	}

	const traceId = getTraceIds(operationName)[0] ?? '';
	stopCapturingTraceIds(operationName);

	// Best-effort attempt to provide `issueId` and is not guaranteed
	const issueId = data?.jira.issueByKey?.issueId;

	timeToFetchIssueAggDataMetric.stop({
		customData: issueId
			? {
					issueId,
				}
			: null,
	});

	return { data, errors, traceId };
};
