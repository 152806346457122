/**
 * @generated SignedSource<<0bfcda9587143c2c044c79db00210b83>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutNumberFieldDefault_IssueViewNumberFieldDefault$data = {
  readonly __typename: "JiraNumberField";
  readonly fieldId: string;
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"number_issueFieldNumberInlineEditFull_NumberInlineEditView" | "ui_issueViewLayoutFieldHeading_IssueViewFieldHeading">;
  readonly " $fragmentType": "ui_issueViewLayoutNumberFieldDefault_IssueViewNumberFieldDefault";
};
export type ui_issueViewLayoutNumberFieldDefault_IssueViewNumberFieldDefault$key = {
  readonly " $data"?: ui_issueViewLayoutNumberFieldDefault_IssueViewNumberFieldDefault$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutNumberFieldDefault_IssueViewNumberFieldDefault">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutNumberFieldDefault_IssueViewNumberFieldDefault",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "ui_issueViewLayoutFieldHeading_IssueViewFieldHeading"
    },
    {
      "kind": "FragmentSpread",
      "name": "number_issueFieldNumberInlineEditFull_NumberInlineEditView"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "ScalarField",
      "name": "__typename"
    }
  ],
  "type": "JiraNumberField"
};

(node as any).hash = "d74f557a7d376734ce280c921d10cbf8";

export default node;
