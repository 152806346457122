import { lazy } from '@atlassian/react-loosely-lazy';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const DummyConfluenceLinkViewLazy = lazy(() =>
	import(
		/* webpackChunkName: "async-dummy-confluence-link-view" */ '@atlassian/jira-issue-view-base/src/content/confluence-placeholder-template-experiment/confluence-dummy-link/dummy-confluence-link-view'
	).then((module) => module.DummyConfluenceLinkView),
);

export default DummyConfluenceLinkViewLazy;
