// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	createChildLabel: {
		id: 'issue.views.common.child-issues-panel.heading.add-child.create-child-label',
		defaultMessage: 'Create child',
		description: 'Aria label for a "+" button to create a child issue',
	},
	createSubtaskLabel: {
		id: 'issue.views.common.child-issues-panel.heading.add-child.create-subtask-label',
		defaultMessage: 'Create subtask',
		description: 'Aria label for a "+" button to create a child issue',
	},
});
