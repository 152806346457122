import React from 'react';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

const LazyComponent = lazyForPaint(
	() => import(/* webpackChunkName: "jira-autodev" */ './ui/index.tsx'),
);
type Props = {
	issueId: string;
};

export const Component = ({ issueId }: Props) => (
	<Placeholder name="jira-autodev" fallback={null}>
		<LazyComponent issueId={issueId} />
	</Placeholder>
);

export default Component;
