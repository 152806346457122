// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	replyToCustomer: {
		id: 'issue.views.issue-base.activity.comment.comment-editor-view.servicedesk-comment-editor-view.reply-to-customer-button',
		defaultMessage: 'Reply to customer',
		description:
			'A link button which opens an editor to add an external comment in a Jira Service Management issue.',
	},
	addInternalNote: {
		id: 'issue.views.issue-base.activity.comment.comment-editor-view.servicedesk-comment-editor-view.add-internal-note-button',
		defaultMessage: 'Add internal note',
		description:
			'A link button which opens an editor to add an internal comment in a Jira Service Management issue.',
	},
	addNote: {
		id: 'issue.views.issue-base.activity.comment.comment-editor-view.servicedesk-comment-editor-view.add-note-button',
		defaultMessage: 'Add note',
		description:
			'A link button which opens an editor to add an internal comment in a Jira Service Management issue.',
	},
	cancel: {
		id: 'issue.views.issue-base.activity.comment.comment-editor-view.servicedesk-comment-editor-view.cancel-button',
		defaultMessage: 'Cancel',
		description: 'A button to cancel the comment.',
	},
	loadingAttachments: {
		id: 'issue.views.issue-base.activity.comment.comment-editor-view.servicedesk-comment-editor-view.loading-attachments',
		defaultMessage: 'Loading attachments…',
		description: 'The loading status to indicate that attachments are being uploaded.',
	},
	informStakeholders: {
		id: 'issue.views.issue-base.activity.comment.comment-editor-view.servicedesk-comment-editor-view.inform-stakeholders',
		defaultMessage: 'Inform stakeholders',
		description: 'A link button which will open inform stakeholder modal',
	},
	informStakeholderLimitExceed: {
		id: 'issue.views.issue-base.activity.comment.comment-editor-view.servicedesk-comment-editor-view.inform-stakeholder-limit-exceed',
		defaultMessage:
			'You cannot send any updates as your trial period limit of 50 stakeholder emails per day is over.',
		description: 'Tooltip message when user exceed limits',
	},
});
