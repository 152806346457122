import React from 'react';
import type { CustomGlyphProps } from '@atlaskit/icon';

export const formulaGlyph = (props: CustomGlyphProps) => (
	<svg
		{...props}
		width="16"
		height="16"
		viewBox="4 4 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10.2579 6.55387C9.45642 6.55387 9.0148 6.93008 8.85123 7.79697L8.77041 8.24723C8.75286 8.34502 8.82803 8.43488 8.92738 8.43488H10.1646C10.4612 8.43488 10.6864 8.70197 10.6362 8.99428L10.5224 9.65672C10.483 9.88635 10.2839 10.0542 10.0509 10.0542H8.59248C8.51509 10.0542 8.44887 10.1097 8.43543 10.186L6.7946 19.4891C6.75427 19.7178 6.55561 19.8845 6.32344 19.8845H5.47856C5.1814 19.8845 4.95613 19.6164 5.00727 19.3237L6.59408 10.2411C6.61113 10.1435 6.53603 10.0542 6.43698 10.0542H6.01665C5.72006 10.0542 5.49493 9.78709 5.54512 9.49478L5.65887 8.83234C5.6983 8.60271 5.89741 8.43488 6.1304 8.43488H6.78653C6.86428 8.43488 6.9307 8.37881 6.94374 8.30216L7.05201 7.66612C7.33007 6.07953 8.19697 5 10.1925 5C10.485 5 10.7247 5.01922 10.9272 5.04724C11.1781 5.08196 11.3363 5.32569 11.2929 5.57523L11.1937 6.1456C11.1471 6.41311 10.8868 6.58865 10.6161 6.56779C10.4999 6.55883 10.3789 6.55387 10.2579 6.55387ZM17.7688 15.9934C17.9369 16.312 17.7058 16.6949 17.3456 16.6949H16.7477C16.3184 16.6949 15.927 16.4487 15.7413 16.0615L14.5998 13.6824C14.5493 13.5771 14.4065 13.56 14.3326 13.6504L12.1765 16.2855C11.9645 16.5447 11.6474 16.6949 11.3125 16.6949H10.743C10.331 16.6949 10.1117 16.2088 10.3844 15.8999L13.3048 12.5918C13.3493 12.5413 13.3574 12.4683 13.325 12.4094L11.5283 9.14394C11.3529 8.82509 11.5836 8.43488 11.9475 8.43488H12.5256C12.9484 8.43488 13.3349 8.67378 13.524 9.05197L14.6932 11.3902C14.7446 11.493 14.8845 11.5093 14.9582 11.4211L17.1172 8.83569C17.3293 8.58169 17.6431 8.43488 17.974 8.43488H18.5206C18.9348 8.43488 19.1533 8.92538 18.8763 9.23328L16.0051 12.425C15.9604 12.4747 15.9514 12.5469 15.9826 12.606L17.7688 15.9934Z"
			fill="currentColor"
		/>
	</svg>
);
