import React, { type ElementType, memo, useCallback, useMemo } from 'react';
import isEqual from 'lodash/isEqual';
import { graphql, useFragment } from 'react-relay';
import { useDeadCodeDetectorAnalytics } from '@atlassian/jira-common-analytics-dead-code-detector/src/index.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { TASK_FAIL } from '@atlassian/jira-experience-tracker/src/common/constants.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { sendExperienceAnalytics } from '@atlassian/jira-issue-view-analytics/src/controllers/send-experience-analytics/index.tsx';
import { getLayoutItemMap as legacyGetLayoutItemMap } from '@atlassian/jira-issue-view-base/src/get-layout-item-map.tsx';
import { ItemBusyWrapperByIssueKey } from '@atlassian/jira-issue-view-common/src/component/item-busy-wrapper/index.tsx';
import { useIssueLayoutActions } from '@atlassian/jira-issue-view-layout/src/services/main.tsx';
import { UiModificationsRelayFieldDecorator } from '@atlassian/jira-issue-view-ui-modifications-relay-field-decorator/src/ui/index.tsx';
import {
	MESSAGE_EDIT_CF_TYPE,
	MESSAGE_VIEW_CF_TYPE,
	REPORTER_TYPE,
	USER_CF_TYPE,
	ASSIGNEE_TYPE,
	PEOPLE_CF_TYPE,
	APPROVERS_LIST_CF_TYPE,
	MULTI_USER_CF_TYPE,
	PARTICIPANTS_CF_TYPE,
	REQUEST_PARTICIPANTS_CF_TYPE,
	MULTI_GROUP_CF_TYPE,
	ISSUE_FIELD_GROUPS_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import {
	useProjectKey,
	useApplication,
	useEdition,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import type { src_issueViewLayoutTemplatesLayoutItem_LayoutItemInternal$key as LayoutItemInternalFragment } from '@atlassian/jira-relay/src/__generated__/src_issueViewLayoutTemplatesLayoutItem_LayoutItemInternal.graphql';
import {
	RelayLayoutItemFragmentContainer,
	getLayoutItemMap,
} from './ui/relay-layout-items/index.tsx';
import { LegacyLayoutItemErrorBoundary } from './ui/relay-layout-items/legacy-layout-item-error-boundary/index.tsx';
import { RelayLayoutItemErrorBoundary } from './ui/relay-layout-items/relay-layout-item-error-boundary/index.tsx';
import type {
	LayoutItemElementMapNonLiteral,
	LayoutItemProps,
	LayoutItemPropsWithPanel,
} from './ui/relay-layout-items/types.tsx';
import { isRelayDataReady, isFieldTypeFullyMigratedToRelay } from './utils/index.tsx';

/**
 * Sometimes a field uses 1 graphql type, but has multiple jira field types
 * eg, JiraSingleSelectUserPickerField is used for assignee, reporter, and custom single user field
 * We can then migrate assignee without needing to also migrate reporter and custom single user field
 *
 * See:
 * jira/src/packages/issue/fields/issue-view-layout/single-select-user-picker/single-select-user-picker-field/src/ui/index.tsx
 * jira/src/packages/issue/fields/issue-view-layout/number/number-field/src/ui/index.tsx
 */

export const getRelayLayoutExclusions = (): string[] => {
	const exclusions = [];
	// user fields
	if (
		fg('relay-migration-issue-fields-assignee-fg') ||
		fg('relay-migration-issue-fields-reporter') ||
		fg('relay-migration-issue-fields-user-fg')
	) {
		if (!fg('relay-migration-issue-fields-assignee-fg')) {
			exclusions.push(ASSIGNEE_TYPE);
		}
		if (!fg('relay-migration-issue-fields-reporter')) {
			exclusions.push(REPORTER_TYPE);
		}
		if (!fg('relay-migration-issue-fields-user-fg')) {
			exclusions.push(USER_CF_TYPE);
		}
	}
	if (
		fg('relay-migration-issue-fields-single-line-text-fg') ||
		fg('relay-migration-issue-fields-single-line-text-msg')
	) {
		if (!fg('relay-migration-issue-fields-single-line-text-msg')) {
			exclusions.push(MESSAGE_VIEW_CF_TYPE, MESSAGE_EDIT_CF_TYPE);
		}
	}
	if (
		fg('relay-migration-issue-fields-people-fg') ||
		fg('relay-migration-issue-fields-approvers-fg')
	) {
		if (!fg('relay-migration-issue-fields-people-fg')) {
			exclusions.push(PEOPLE_CF_TYPE);
		}

		if (!fg('relay-migration-issue-fields-approvers-fg')) {
			exclusions.push(APPROVERS_LIST_CF_TYPE);
		}
	}

	if (
		fg('relay-migration-issue-fields-multi-user-fg') ||
		ff('relay-migration-issue-fields-participants') ||
		ff('relay-migration-issue-fields-request-participants_itocm')
	) {
		if (!fg('relay-migration-issue-fields-multi-user-fg')) {
			exclusions.push(MULTI_USER_CF_TYPE);
		}
		if (!ff('relay-migration-issue-fields-participants')) {
			exclusions.push(PARTICIPANTS_CF_TYPE);
		}
		if (!ff('relay-migration-issue-fields-request-participants_itocm')) {
			exclusions.push(REQUEST_PARTICIPANTS_CF_TYPE);
		}
	}

	if (fg('relay-migration-issue-fields-multi-group-picker-fg')) {
		if (!fg('relay-migration-issue-fields-multi-group-picker-fg')) {
			exclusions.push(MULTI_GROUP_CF_TYPE);
		}

		exclusions.push(ISSUE_FIELD_GROUPS_CF_TYPE);
	}
	return exclusions;
};
/**
 * Hook to return  the relevant relay-powered component given graphql type and optionally a jira field type
 *
 * @param graphqlTypename Search for a component in {@link LayoutItemMap} given the graphql {@code __typename}
 * @param jiraFieldType if the relevant {@code graphqlTypename} is for a {@code JiraIssueField}, then this should be the accompanying type according to jira (eg: used to distinguish assignee vs reporter vs custom user field for JiraSingleUserSelectField)
 * @returns A component from getLayoutItemMap Returns null if no relevant component is found.
 *          Will return the same answer even if the result should change between renders
 */
export const useRelayLayoutItemComponent = (
	graphqlTypename: string | null | undefined,
	jiraFieldType: string | null | undefined,
): ElementType<LayoutItemProps> | ElementType<LayoutItemPropsWithPanel> | null => {
	const isRelaySingleSelectUserPicker =
		fg('relay-migration-issue-fields-assignee-fg') ||
		fg('relay-migration-issue-fields-reporter') ||
		fg('relay-migration-issue-fields-user-fg');
	const isRelaySingleLineText =
		fg('relay-migration-issue-fields-single-line-text-fg') ||
		fg('relay-migration-issue-fields-single-line-text-msg');
	const isRelayPeople =
		fg('relay-migration-issue-fields-people-fg') || fg('relay-migration-issue-fields-approvers-fg');
	const isRelayMultiUser =
		fg('relay-migration-issue-fields-multi-user-fg') ||
		ff('relay-migration-issue-fields-participants');
	if (
		(fg('relay-migration-issue-fields-number') ||
			isRelaySingleSelectUserPicker ||
			isRelaySingleLineText ||
			isRelayPeople ||
			isRelayMultiUser) &&
		jiraFieldType &&
		getRelayLayoutExclusions().includes(jiraFieldType)
	) {
		return null;
	}
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const layoutItemMap = getLayoutItemMap() as LayoutItemElementMapNonLiteral;
	const relayLayoutItemEntryOrWrapper = layoutItemMap[graphqlTypename || ''];
	const relayLayoutItemEntry =
		!!relayLayoutItemEntryOrWrapper && 'splitByJiraFieldType' in relayLayoutItemEntryOrWrapper
			? relayLayoutItemEntryOrWrapper.splitByJiraFieldType?.[jiraFieldType || '']
			: relayLayoutItemEntryOrWrapper;

	const RelayLayoutItemComponent = relayLayoutItemEntry?.item ?? null;
	return RelayLayoutItemComponent;
};

export type Props = {
	layoutItemFragment: LayoutItemInternalFragment | null;
	/** @deprecated should get all data from relay */
	type: string;
	/** @deprecated should get all data from relay */
	id: string;
	area: string;
	isLast?: boolean;
	/** @deprecated should get all data from relay */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	payload?: Record<any, any>;
};

/**
 * Component responsible for picking the correct component to mount in the issue-view layout-controlled areas.
 * Priority order of component selection:
 * 1. Relay powered component if provided by {@link useRelayLayoutItemComponent} based on the type of the field in its Relay data
 * 2. Falling back to finding matching legacy components via {@link getLayoutItemMap} which forms a circular dep back to {@code @atlassian/jira-issue-view}
 */
const LayoutItemInternal = ({
	id,
	type,
	area,
	isLast,
	payload = {},
	layoutItemFragment,
}: Props) => {
	const issueKey = useIssueKey();
	const data = useFragment<LayoutItemInternalFragment>(
		graphql`
			fragment src_issueViewLayoutTemplatesLayoutItem_LayoutItemInternal on JiraIssueField
			@argumentDefinitions(
				jsmSentimentCustomFieldFlag: { type: "Boolean!", defaultValue: false }
				issueViewRelayPriorityFieldFlag: { type: "Boolean!", defaultValue: false }
				issueViewRelayAssigneeFieldFlag: { type: "Boolean!", defaultValue: false }
				issueViewRelayNumberFieldFlag: { type: "Boolean!", defaultValue: false }
				issueViewRelaySprintFieldFlag: { type: "Boolean!", defaultValue: false }
				issueViewRelaySingleLineTextFieldFlag: { type: "Boolean!", defaultValue: false }
				issueViewRelayDateFieldFlag: { type: "Boolean!", defaultValue: false }
				issueViewRelayProjectFieldFlag: { type: "Boolean!", defaultValue: false }
				issueViewRelayDateTimeFieldFlag: { type: "Boolean!", defaultValue: false }
				issueViewRelayUrlFieldFlag: { type: "Boolean!", defaultValue: false }
				issueViewRelayPeopleAndApproversFieldFlag: { type: "Boolean!", defaultValue: false }
				jcsIssueLayoutEapFlag: { type: "Boolean!", defaultValue: false }
				isIssueViewFieldConfigEditEnabled: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/relay-migration-issue-fields-custom-field-config.relayprovider"
				}
			) {
				__typename
				type
				fieldId
				fieldOperations @include(if: $isIssueViewFieldConfigEditEnabled) {
					canEdit
				}
				...relayLayoutItems_issueViewLayoutTemplatesLayoutItem_RelayLayoutItemFragmentContainer
					@arguments(
						jsmSentimentCustomFieldFlag: $jsmSentimentCustomFieldFlag
						issueViewRelayPriorityFieldFlag: $issueViewRelayPriorityFieldFlag
						issueViewRelayAssigneeFieldFlag: $issueViewRelayAssigneeFieldFlag
						issueViewRelayNumberFieldFlag: $issueViewRelayNumberFieldFlag
						issueViewRelaySprintFieldFlag: $issueViewRelaySprintFieldFlag
						issueViewRelaySingleLineTextFieldFlag: $issueViewRelaySingleLineTextFieldFlag
						issueViewRelayDateFieldFlag: $issueViewRelayDateFieldFlag
						issueViewRelayUrlFieldFlag: $issueViewRelayUrlFieldFlag
						issueViewRelayProjectFieldFlag: $issueViewRelayProjectFieldFlag
						issueViewRelayDateTimeFieldFlag: $issueViewRelayDateTimeFieldFlag
						issueViewRelayPeopleAndApproversFieldFlag: $issueViewRelayPeopleAndApproversFieldFlag
						jcsIssueLayoutEapFlag: $jcsIssueLayoutEapFlag
					)
			}
		`,
		layoutItemFragment,
	);

	const projectKey = useProjectKey(issueKey);
	const application = useApplication(projectKey, true);
	const edition = useEdition(projectKey, true);
	const { fireCodeNotDeadEvent } = useDeadCodeDetectorAnalytics();

	const [, { setIssueViewLayoutContextPanel }] = useIssueLayoutActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const handleOpenPanel = useCallback(
		// @ts-expect-error - TS7006 - Parameter 'eventPayload' implicitly has an 'any' type.
		(eventPayload) => {
			const contextPanelData = {
				contextPanelType: data?.type ?? data?.__typename,
			};
			fireUIAnalytics(
				createAnalyticsEvent({}),
				'contextPanel viewed',
				'issueContextPanel',
				contextPanelData,
			);
			setIssueViewLayoutContextPanel(issueKey, {
				payload: eventPayload,
				title: eventPayload?.title,
				type,
				key: id,
			});
		},
		[createAnalyticsEvent, data, setIssueViewLayoutContextPanel, issueKey, type, id],
	);

	const showEditIcon = useMemo(() => {
		if (!fg('issue_view_field_config_edit')) {
			return false;
		}
		return data?.fieldOperations?.canEdit;
	}, [data?.fieldOperations?.canEdit]);

	const RelayLayoutItemComponent = useRelayLayoutItemComponent(data?.__typename, data?.type);

	if (
		data == null &&
		isFieldTypeFullyMigratedToRelay(type) &&
		fg('jira_issue_no_legacy_labels_and_cascading_select')
	) {
		sendExperienceAnalytics({
			experience: 'issueViewLegacyFieldRender',
			analyticsSource: 'issueViewRelayFieldView',
			action: TASK_FAIL,
			wasExperienceSuccesful: false,
			application: application ?? null,
			edition: edition ?? null,
			additionalAttributes: {
				location: 'issue-view-layout-item',
				errorMessage: 'Relay data are null',
				fieldType: type,
				fieldId: id,
			},
		});

		return null;
	}

	if (RelayLayoutItemComponent && isRelayDataReady(data)) {
		return (
			<RelayLayoutItemErrorBoundary fieldType={type} edition={edition} application={application}>
				<UiModificationsRelayFieldDecorator fieldId={data.fieldId} issueKey={issueKey}>
					<RelayLayoutItemFragmentContainer
						fragmentKey={data}
						area={area}
						onOpenPanel={handleOpenPanel}
						Component={RelayLayoutItemComponent}
						isLast={isLast}
					/>
				</UiModificationsRelayFieldDecorator>
			</RelayLayoutItemErrorBoundary>
		);
	}

	const legacyLayoutItemMap = legacyGetLayoutItemMap();
	if (legacyLayoutItemMap[type]) {
		const LegacyLayoutItemComponent = legacyLayoutItemMap[type].item;

		if (fg('jira_dead_code_detector_legacy_layout_item')) {
			fireCodeNotDeadEvent({
				id: 'legacy-layout-type-internal-render',
				packageName: 'issue-view-layout-templates-layout-item',
				teamName: 'Issue View Nike',
				type,
				fieldType: type,
				fieldId: id,
				isRelayDataReady: isRelayDataReady(data),
				graphqlTypename: data?.__typename,
				jiraFieldType: data?.type,
				issueKey,
				projectKey,
			});
		}

		return (
			<LegacyLayoutItemErrorBoundary fieldType={type} edition={edition} application={application}>
				<ItemBusyWrapperByIssueKey issueKey={issueKey} fieldId={id}>
					<LegacyLayoutItemComponent
						{...payload}
						key={id}
						fieldId={id}
						area={area}
						onOpenPanel={handleOpenPanel}
						isLast={isLast}
						showEditIcon={showEditIcon}
					/>
				</ItemBusyWrapperByIssueKey>
			</LegacyLayoutItemErrorBoundary>
		);
	}

	log.safeErrorWithoutCustomerData(
		'issue.views.issue-base.context',
		`Could not find a matching component for "${id}" of type "${type}"`,
	);

	return null;
};

export const LayoutItem = memo<Props>(LayoutItemInternal, isEqual);
LayoutItem.displayName = 'LayoutItem';

export default LayoutItem;
