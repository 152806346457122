import React, { type FC, type PropsWithChildren, useRef } from 'react';
import { styled } from '@compiled/react';
import { useSetHighlightContainerRef } from '../../controllers/use-highlight-actions/index.tsx';

export type Props = {
	scope: string;
};

export const TargetContainer: FC<PropsWithChildren<Props>> = ({ scope, children }) => {
	const ref = useRef<HTMLDivElement>(null);
	useSetHighlightContainerRef(ref);

	return (
		<Container ref={ref} data-testid={`highlight-actions.ui.target.container.${scope}`}>
			{children}
		</Container>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	position: 'relative',
});
