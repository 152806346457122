import type { ParametricSelector } from 'reselect';

let cache = new WeakMap();
let jsonEqualityMap = new WeakMap();

type Options = {
	useJsonEquality?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getValueFromJsonEqualityCache = <T extends Function>(f: T, value: any, options?: Options) => {
	if (options?.useJsonEquality !== true) {
		return value;
	}
	if (!jsonEqualityMap.has(f)) {
		jsonEqualityMap.set(f, new Map());
	}
	const storage = jsonEqualityMap.get(f);
	try {
		const key = JSON.stringify(value);
		if (storage.has(key)) {
			return storage.get(key);
		}
		storage.set(key, value);
	} catch {
		// do nothing
	}
	return value;
};

export function cacheSelectorCreator<
	T extends Function & { displayName?: string; __isCachedSelectorCreator?: boolean },
>(f: T, options?: Options): T & { __isCachedSelectorCreator?: boolean } {
	if (f.__isCachedSelectorCreator === true) {
		return f;
	}
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const cacheFunction: any = (...args: any[]) => {
		if (cache.has(f)) {
			let lastNode = cache.get(f);
			for (let i = 0; i < args.length; i += 1) {
				const value = getValueFromJsonEqualityCache(f, args[i], options);
				if (!lastNode.args.has(value)) {
					lastNode = null;
					break;
				}
				lastNode = lastNode.args.get(value);
			}
			if (lastNode?.selector) {
				return lastNode.selector;
			}
		}

		const maybeSelector = f(...args);

		if (typeof maybeSelector?.resultFunc === 'function' || typeof maybeSelector === 'function') {
			if (!cache.has(f)) {
				cache.set(f, {
					args: new Map(),
					selector: null,
				});
			}
			let lastNode = cache.get(f);
			for (let i = 0; i < args.length; i += 1) {
				const value = getValueFromJsonEqualityCache(f, args[i], options);
				if (!lastNode.args.has(value)) {
					lastNode.args.set(value, {
						args: new Map(),
						selector: null,
					});
				}
				lastNode = lastNode.args.get(value);
			}
			lastNode.selector = maybeSelector;
		}

		return maybeSelector;
	};
	cacheFunction.__isCachedSelectorCreator = true;
	cacheFunction.__origin = f;
	cacheFunction.displayName = f.name || f.displayName;
	return cacheFunction;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Node = { args: Map<any, Node>; selector: ParametricSelector<any, any, any> | null };

export const getCache = () => cache;
export const getCacheValue = (key: Function): Node | undefined => cache.get(key);

export const resetCache = () => {
	cache = new WeakMap();
	jsonEqualityMap = new WeakMap();
};
