import React, {
	type MouseEvent,
	type SyntheticEvent,
	useCallback,
	useMemo,
	type ReactNode,
} from 'react';
import { SmartCardProvider as Provider } from '@atlaskit/link-provider';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useEmbeddedPageTracking } from '@atlassian/jira-issue-create-confluence-content/src/controllers/use-embedded-page-tracking/index.tsx';
import type { ConfluencePage } from '@atlassian/jira-issue-shared-types/src/common/types/confluence-content-type.tsx';
import type { FailedRemoteLink } from '@atlassian/jira-issue-shared-types/src/common/types/remote-link-error-type.tsx';
import {
	APPLINK_REQ_AUTH,
	REMOTE_LINK_MISSING,
} from '@atlassian/jira-issue-view-common-constants/src/remote-link-error-type.tsx';
import {
	useSmartCardProductType,
	useSmartCardIsAIEnabled,
} from '@atlassian/jira-linking-platform-utils/src/index.tsx';
import { ModalEntryPointPressableTrigger } from '@atlassian/jira-modal-entry-point-pressable-trigger/src/ModalEntryPointPressableTrigger.tsx';
import { useProjectContext } from '@atlassian/jira-providers-project-context/src/index.tsx';
import type {
	Href,
	RefObject,
	RemoteLinkGlobalId,
} from '@atlassian/jira-shared-types/src/general.tsx';
import SmartLinkCard from '../../../smart-link-content/index.tsx';
import { JiraSmartLinkLocations } from '../../../smart-link-content/smart-link-analytics.tsx';
import { getContentWithHostname, shouldLoadConfluenceInNewTab } from '../../utils.tsx';
import { confirmationModalEntryPoint } from '../confirmation-modal/entrypoint.tsx';
import messages from './messages.tsx';

type Props = {
	groupId: string;
	pages: (ConfluencePage | FailedRemoteLink)[];
	onClick?: (
		event: MouseEvent<HTMLElement, globalThis.MouseEvent> | SyntheticEvent<Element, Event>,
	) => void;
	onAuthenticateApplink: (href: Href) => void;
	onDeleteConfluencePageLink: (id: RemoteLinkGlobalId) => void;
	systemConfluenceAppLinkUrl: string | undefined;
};

const isPageError = (page: ConfluencePage | FailedRemoteLink): page is FailedRemoteLink =>
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	(page as FailedRemoteLink).error !== undefined;

export const EntryPointWrapper = ({
	children,
	linkId,
	onDeleteConfluencePageLink,
}: {
	children: ReactNode;
	linkId: RemoteLinkGlobalId | RemoteLinkGlobalId;
	onDeleteConfluencePageLink: (id: RemoteLinkGlobalId) => void;
}) => {
	const entryPointProps = useMemo(
		() => ({
			onDelete: (linkToDeleteId: RemoteLinkGlobalId | RemoteLinkGlobalId) => {
				onDeleteConfluencePageLink(linkToDeleteId);
			},
			linkId,
		}),
		[onDeleteConfluencePageLink, linkId],
	);

	return (
		<ModalEntryPointPressableTrigger
			entryPoint={confirmationModalEntryPoint}
			entryPointProps={entryPointProps}
			interactionName="delete-confluence-page-line-card-modal"
			useInternalModal={false}
		>
			{({ ref }) => (
				<div
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					ref={ref as RefObject<HTMLDivElement>}
				>
					{children}
				</div>
			)}
		</ModalEntryPointPressableTrigger>
	);
};

export const ConfluencePageLineCardGroup = ({
	pages,
	onClick,
	onAuthenticateApplink,
	onDeleteConfluencePageLink,
	systemConfluenceAppLinkUrl,
}: Props) => {
	const { formatMessage } = useIntl();
	const trackEmbeddedConfluence = useEmbeddedPageTracking();
	const projectContext = useProjectContext();

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const currentHostname = window?.location?.hostname || '';
	const isAppLinkAuthError = useCallback(
		(page: ConfluencePage | FailedRemoteLink): page is FailedRemoteLink =>
			isPageError(page) &&
			page.error === APPLINK_REQ_AUTH &&
			page.repairLink?.href !== undefined &&
			onAuthenticateApplink !== undefined,
		[onAuthenticateApplink],
	);
	const isRestrictedPageError = useCallback(
		(page: ConfluencePage | FailedRemoteLink): page is FailedRemoteLink =>
			isPageError(page) && page.error === REMOTE_LINK_MISSING && page.link?.href !== undefined,
		[],
	);

	const smartCardProviderProduct = useSmartCardProductType();
	const isAIFeatureEnabled = useSmartCardIsAIEnabled();
	/**
	 * Returns either the url contained in the link or the authentication url for a failed applink
	 * @param page is the confluence page to be connected
	 */
	const getHrefForSmartCard = useCallback(
		(page: ConfluencePage | FailedRemoteLink) => {
			if (
				isAppLinkAuthError(page) ||
				(isRestrictedPageError(page) && fg('linked_mentioned_pages_handle_restricted_page'))
			) {
				return page.repairLink ? page.repairLink.href : page.link.href;
			}

			return isPageError(page) ? page.link.href : page.href;
		},
		[isAppLinkAuthError, isRestrictedPageError],
	);

	const getPageWithHostname = useCallback(
		(page: ConfluencePage | FailedRemoteLink) => {
			if (isPageError(page)) {
				return page;
			}
			return getContentWithHostname({
				content: page,
				systemConfluenceAppLinkUrl,
				currentHostname,
			});
		},
		[currentHostname, systemConfluenceAppLinkUrl],
	);

	const loadConfluenceInNewTab = useCallback(
		(page: ConfluencePage | FailedRemoteLink) => {
			if (isPageError(page)) {
				return false;
			}
			return shouldLoadConfluenceInNewTab({
				content: page,
				systemConfluenceAppLinkUrl,
				currentHostname,
			});
		},
		[currentHostname, systemConfluenceAppLinkUrl],
	);

	/**
	 * Returns an authenticate function for failed applinks or a passed onClick function for all other cases
	 * @param page is the confluence page to be connected
	 */
	const onClickSmartCard = useCallback(
		(page: ConfluencePage | FailedRemoteLink) => {
			if (isAppLinkAuthError(page)) {
				return onAuthenticateApplink(getHrefForSmartCard(page));
			}
			if (isRestrictedPageError(page) && fg('linked_mentioned_pages_handle_restricted_page')) {
				// open the page in a new tab as restricted pages don't open in embedded confluence

				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				window.open(getHrefForSmartCard(page), '_blank', 'noopener, noreferrer');
			}
			return onClick;
		},
		[
			isAppLinkAuthError,
			onAuthenticateApplink,
			getHrefForSmartCard,
			onClick,
			isRestrictedPageError,
		],
	);

	const onClickConfluencePage = useCallback(
		(page: ConfluencePage | FailedRemoteLink): typeof onClick =>
			(event) => {
				trackEmbeddedConfluence({
					action: 'clicked',
					eventName: 'openLinkedConfluencePage',
					projectType: projectContext.data?.projectType,
				});
				return onClickSmartCard(page)?.(event);
			},
		[trackEmbeddedConfluence, projectContext.data?.projectType, onClickSmartCard],
	);

	/**
	 * Returns a title for the passed confluence page. If the page is a failed remote app link,
	 * an error message will be shown to the user
	 * @param page is the confluence page to be connected
	 */
	const getTitleForSmartLink = useCallback(
		(page: ConfluencePage | FailedRemoteLink) => {
			if (isAppLinkAuthError(page)) {
				return formatMessage(messages.connectToConfluenceToAuthenticate);
			}

			return !isPageError(page) && page.href !== page.title ? page.title : undefined;
		},
		[isAppLinkAuthError, formatMessage],
	);

	return (
		<>
			<Provider product={smartCardProviderProduct} isAdminHubAIEnabled={isAIFeatureEnabled}>
				{pages.map((page) => (
					<SmartLinkCard
						key={page.id}
						analyticsLocation={JiraSmartLinkLocations.CONFLUENCE_PAGES}
						url={getHrefForSmartCard(page)}
						onClick={onClickConfluencePage(page)}
						canDelete
						text={getTitleForSmartLink(page)}
						linkId={isPageError(page) ? page.link.globalId : page.globalId}
						smartLinkId={page.id}
						page={getPageWithHostname(page)}
						loadConfluenceInNewTab={loadConfluenceInNewTab(page)}
						entryPointWrapper={(p) => (
							<EntryPointWrapper
								{...p}
								onDeleteConfluencePageLink={onDeleteConfluencePageLink}
								linkId={isPageError(page) ? page.link.globalId : page.globalId}
							/>
						)}
					/>
				))}
			</Provider>
		</>
	);
};
