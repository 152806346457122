import type { StoreApi } from '../../types.tsx';

export const markRollingUpData =
	() =>
	({ setState }: StoreApi) => {
		setState({
			isRollingUp: true,
		});
	};

export const unmarkRollingUpData =
	() =>
	({ setState }: StoreApi) => {
		setState({
			isRollingUp: false,
		});
	};
