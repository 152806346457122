import { useEffect } from 'react';
import { createStore, createHook, type Action } from '@atlassian/react-sweet-state';
import { useJiraLoomRecorderEntryPoint } from '../use-jira-loom-recorder-entrypoint/index.tsx';
import type { UseJiraLoomRecorderEntryPointProps } from '../use-jira-loom-recorder-entrypoint/types.tsx';
import type { LoomRecorderTriggerEventType } from './types.tsx';

interface State {
	triggers: Set<LoomRecorderTriggerEventType>;
}

const initialState: State = {
	triggers: new Set(),
};

const actions = {
	triggerLoomRecorder:
		(event: LoomRecorderTriggerEventType): Action<State> =>
		({ getState, setState }) => {
			const { triggers } = getState();
			triggers.add(event);
			setState({ triggers });
		},
	markTriggered:
		(event: LoomRecorderTriggerEventType): Action<State> =>
		({ getState, setState }) => {
			const { triggers } = getState();
			triggers.delete(event);
			setState({ triggers });
		},
} as const;

type Actions = typeof actions;

const store = createStore<State, Actions>({
	name: 'loom-utils.loom-recorder-trigger',
	initialState,
	actions,
});

const useLoomRecorderTriggerStore = createHook(store);

/**
 * Use this hook at the component you want to programmatically open the SDK from. The trigger can be invoked even after
 * the component rendering the hook has been unmounted (eg. in a callback).
 *
 * @returns a function to open the SDK at the listener point, with a given event name
 */
export const useLoomRecorderTrigger = () => {
	const [, { triggerLoomRecorder }] = useLoomRecorderTriggerStore();
	return triggerLoomRecorder;
};

/**
 * Use this listener at the component you want to trigger the SDK at (ie. where you want to define onInsert, etc)
 *
 * @param event the event name to listen for to trigger the record SDK
 * @param entrypointProps the useJiraLoomRecorderEntrypoint props to pass through
 */
export const useLoomRecorderTriggerListener = (
	event: LoomRecorderTriggerEventType,
	entrypointProps: UseJiraLoomRecorderEntryPointProps,
) => {
	const [{ triggers }, { markTriggered }] = useLoomRecorderTriggerStore();

	const { openLoomRecorder, recorderState } = useJiraLoomRecorderEntryPoint({
		...entrypointProps,
		skipInitialisation: entrypointProps.skipInitialisation || !triggers.has(event),
	});
	useEffect(() => {
		if (
			// we "open" the recorder even if the initialisation has failed, so that the user is notified of it via error flags
			(recorderState === 'ready' || recorderState === 'initialisation-failed') &&
			openLoomRecorder !== null &&
			triggers.has(event)
		) {
			openLoomRecorder();
			markTriggered(event);
		}
	}, [event, markTriggered, openLoomRecorder, recorderState, triggers]);
};
