import type { BaseUrl } from '@atlassian/jira-shared-types/src/general.tsx';
import type { OptimisticUiChildIssue } from '../../model/types.tsx';
import createIssue, { type CreateIssuePayload } from '../../rest/create-issue.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	baseUrl: BaseUrl,
	createPayload: CreateIssuePayload,
	optimisticUiChildIssue: OptimisticUiChildIssue,
) =>
	createIssue(baseUrl, createPayload).then((createdIssue) => ({
		id: createdIssue.id,
		issueKey: createdIssue.key,
		issueLink: `${baseUrl}/browse/${createdIssue.key}`,
		issueSummary: optimisticUiChildIssue.issueSummary,
		issueTypeIconUrl: optimisticUiChildIssue.issueTypeIconUrl,
		issueTypeName: optimisticUiChildIssue.issueTypeName,
		assigneeUrl: optimisticUiChildIssue.assigneeUrl,
		assigneeDisplayName: optimisticUiChildIssue.assigneeDisplayName,
		isLoading: false,
		errorType: undefined,
	}));
