import React, {
	Component,
	type Fragment,
	type ReactElement,
	// eslint-disable-next-line jira/restricted/react-component-props
	type ComponentProps,
	type ReactNode,
	type ComponentPropsWithoutRef,
	forwardRef,
} from 'react';
import { styled as styled2 } from '@compiled/react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled, jira/restricted/styled-components-migration -- Ignored via go/DSP-18766
import styled from 'styled-components';
import isEqual from 'lodash/isEqual';
import over from 'lodash/over';
import uuid from 'uuid/v4';
import { GridColumn } from '@atlaskit/page';
import { token } from '@atlaskit/tokens';
import PerformanceMark from '@atlassian/jira-common-performance/src/set-performance-mark.tsx';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import Resizer from '@atlassian/jira-flex-resizer/src/ui/main.tsx';
import type { UiModificationsExtension } from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import ActivityFeed from '@atlassian/jira-issue-activity-feed/src/index.tsx';
import { useIssueContextStoreViewMode } from '@atlassian/jira-issue-context-service/src/context.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type {
	IssueViewRelayFragment,
	MainIssueAggQueryRelayFragment,
} from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/main.tsx';
import type { Glance } from '@atlassian/jira-issue-gira-transformer-types/src/common/types/ecosystem.tsx';
import { StickyHeaderTrackerContainer } from '@atlassian/jira-issue-header-tracker-service/src/services/sticky-header/index.tsx';
import { ContextPanel } from '@atlassian/jira-issue-view-base/src/context/context-panel/view/view.tsx';
import GlanceAnimatedPanel from '@atlassian/jira-issue-view-base/src/context/ecosystem/common/glance-animated-panel.tsx';
import type { PassedDownRefs } from '@atlassian/jira-issue-view-base/src/context/side-panel/types.tsx';
import {
	ELID_ISSUE_HEADER,
	ELID_ISSUE_HEADER_ACTIONS,
} from '@atlassian/jira-issue-view-common-constants/src/index.tsx';
import type { ViewModeType } from '@atlassian/jira-issue-view-common-types/src/context-type.tsx';
import type { State as ReduxState } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { isBrokenSafari } from '@atlassian/jira-issue-view-common-utils/src/utils/is-broken-safari.tsx';
import IssuePageGrid from '@atlassian/jira-issue-view-common-views/src/issue-page-grid/index.tsx';
import {
	fullPageIssueWrapperMarginTop,
	issueViewBottomPadding,
} from '@atlassian/jira-issue-view-common-views/src/issue-page-grid/styled.tsx';
import { ConditionalInlineFieldConfigContent } from '@atlassian/jira-issue-view-common/src/component/inline-field-config-content/ConditionalInlineFieldConfigContent.tsx';
import { isSwitcherooRealtimeExposuresTestFlagEnabled } from '@atlassian/jira-issue-view-feature-flags/src/index.tsx';
import { ConfigurationButton } from '@atlassian/jira-issue-view-foundation/src/configuration-button/configuration-button.tsx';
import { HeaderActions } from '@atlassian/jira-issue-view-foundation/src/header/header-actions/index.tsx';
import { HeaderBreadcrumbs } from '@atlassian/jira-issue-view-foundation/src/header/header-breadcrumbs/index.tsx';
import Header from '@atlassian/jira-issue-view-foundation/src/header/index.tsx';
import MetaContainerView from '@atlassian/jira-issue-view-foundation/src/meta-container/meta-container-view.tsx';
import QuickAddItems from '@atlassian/jira-issue-view-foundation/src/quick-add/quick-add-items/index.tsx';
import {
	useIssueScrollKeyboardShortcutsStore,
	useFocusedPanelKeyboardShortcutsStore,
} from '@atlassian/jira-issue-view-keyboard-shortcuts/src/services/store.tsx';
import type { Panel } from '@atlassian/jira-issue-view-keyboard-shortcuts/src/types.tsx';
import { IssueViewMetaContainer } from '@atlassian/jira-issue-view-layout-meta-container/src/ui/index.tsx';
import {
	ContextTemplateRenderer,
	FoundationContentTemplateRenderer,
	FoundationContextTemplateRenderer,
} from '@atlassian/jira-issue-view-layout-templates/src/main.tsx';
import {
	useIssueLayoutContextPanel,
	useIssueLayoutGlance,
} from '@atlassian/jira-issue-view-layout/src/services/main.tsx';
import type { Glance as GlanceReference } from '@atlassian/jira-issue-view-layout/src/services/types.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { IssueLayoutSubscriber } from '@atlassian/jira-issue-view-services/src/issue-layout-service/context.tsx';
import StickyHeaderContainer from '@atlassian/jira-issue-view-sticky-header-container/src/index.tsx';
import {
	isMobileSelector,
	shouldRenderHeaderSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import { ecosystemGlancesSelector } from '@atlassian/jira-issue-view-store/src/ecosystem/ecosystem-extensions-selector.tsx';
import type { RefObject } from '@atlassian/jira-shared-types/src/general.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { UiModificationsEntryPoint } from '@atlassian/jira-ui-modifications-view-issue-view/src/ui/entry-point/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { ContentItemsView } from '../../issue-base/content/content-items-view.tsx';
import { issueViewSidePadding } from '../issue-app.styled.tsx';
import type { IssueViewRenderProps } from '../issue-view.types.tsx';
import { RenderCompactHeader } from './compact-header.tsx';
import * as PerfMarks from './constants.tsx';
import { defaultRatioRightColumn } from './constants.tsx';
import { useSidebarResize, type LayoutStyle, HighlightActionsWrapper } from './utils.tsx';

// These keys are needed for React's reconciliation algorithm
// to know the components are the same when changing layout.
// This stops component level state being reset on layout change.
const FOUNDATION_CONTENT = 'FOUNDATION_CONTENT';
const FOUNDATION_CONTEXT = 'FOUNDATION_CONTEXT';
const QUICK_ADD_ITEMS = 'QUICK_ADD_ITEMS';
const CONTEXT_ITEMS = 'CONTEXT_ITEMS';
const CONTEXT_ITEMS_EXT_ID = 'issue.context-items';
const META_CONTAINER = 'META_CONTAINER';
const CONTENT_ITEMS = 'CONTENT_ITEMS';
const FOOTNOTE_WRAPPER_KEY = 'FOOTNOTE_WRAPPER_KEY';
const ACTIVITY_FEED = 'ACTIVITY_FEED';
const ACTIVITY_FEED_EXT_ID = 'issue.activity-feed';
const CONFIGURE_FIELDS = 'CONFIGURE_FIELDS';
const SPLIT_HEADER = 'SPLIT_HEADER' as const;

export type Props = {
	isCompact: boolean;
	isMobile: boolean;
	issueScrollTriggerCount?: number;
	// TODO Decomp BENTO-12514 - add useFragment to this component and replace this prop with more specific fragment key
	issueViewRelayFragment: IssueViewRelayFragment | null;
	rootRelayFragment: MainIssueAggQueryRelayFragment | null;
	shouldRenderHeader: boolean;
	shouldHandleBrokenSafari?: boolean;
	uiModificationsModules: UiModificationsExtension[];
} & IssueViewRenderProps & {
		openedGlance: GlanceReference | null;
		glances: Glance[];
		arePanelsClosed: boolean;
		sidebarRatio: number;
		focusedPanel?: Panel;
		viewMode?: ViewModeType;
		triggerFocusedPanel?: (panel: Panel) => void;
		onSaveSidebarRatio: (ratio: number) => void;
		onResetSidebarRatio: (ratio: number) => void;
	};

type State = {
	contextWidth: number;
	refs: PassedDownRefs;
	breadcrumbsScrollContainer?: HTMLElement;
	compactHeaderScrollContainer?: HTMLElement;
	headerActionsScrollContainer?: HTMLElement;
};

// TODO Decomp BENTO-12515 - convert to functional component
// eslint-disable-next-line jira/react/no-class-components
export class IssueLayout extends Component<Props, State> {
	static displayName = 'IssueLayout';

	static defaultProps = {
		isCompact: false,
		shouldRenderHeader: false,
		shouldSetInitialFocus: false,
		openedGlance: null,
		glances: [],
	};

	constructor(props: Props) {
		super(props);
		this.state = {
			contextWidth: 0,
			refs: {
				refVisibilityContainer: null,
				refSidePanelContainerElement: null,
				refPanelColumnElement: null,
				refLayoutContainer: null,
			},
			breadcrumbsScrollContainer: undefined,
			compactHeaderScrollContainer: undefined,
			headerActionsScrollContainer: undefined,
		};
		this.loomInsertTargetId = uuid();
	}

	shouldComponentUpdate(nextProps: Props, nextState: State): boolean {
		return this.didPropsChange(nextProps) || this.didStateChange(nextState);
	}

	componentDidUpdate(prevProps: Props) {
		/**
		 * When the issue modal (or side view) is open, the focus needs to change to within the issue
		 * otherwise the user will still be able to scroll background elements when issue is open.
		 *
		 * This block should happen at mount level (componentDidMount), however, the DOM references
		 * are set through `setState` (vide setBreadcrumbsScrollContainer and setCompactHeaderScrollContainer functions).
		 * For this reason the focus is triggered at the componentDidUpdate level and only happens once
		 */
		const isInitialFocusSet = !!this.isInitialFocusSet.current;

		if (!isInitialFocusSet && this.props.shouldSetInitialFocus) {
			const loaded =
				this.state.breadcrumbsScrollContainer || this.state.compactHeaderScrollContainer;

			if (loaded) {
				if (this.state.breadcrumbsScrollContainer) this.state.breadcrumbsScrollContainer.focus();
				else if (this.state.compactHeaderScrollContainer)
					this.state.compactHeaderScrollContainer.focus({ preventScroll: true });
				this.isInitialFocusSet.current = true;
			}
		}

		const isScrollingHotkeysDisabled = !!this.props.isScrollingHotkeysDisabled;
		if (isScrollingHotkeysDisabled) {
			return;
		}

		const hasScrollHotkeyTriggered =
			this.props.issueScrollTriggerCount !== prevProps.issueScrollTriggerCount;

		// ref container will be focused if user presses any of the arrow keys
		if (hasScrollHotkeyTriggered) {
			// Right panel is selected and panel exists
			if (this.state.headerActionsScrollContainer && this.props.focusedPanel === 'RIGHT') {
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				if (!this.state.headerActionsScrollContainer.contains(document.activeElement))
					this.state.headerActionsScrollContainer.focus();
			}
			// Left panel is selected and panel exists
			else if (this.state.breadcrumbsScrollContainer && this.props.focusedPanel === 'LEFT') {
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				if (!this.state.breadcrumbsScrollContainer.contains(document.activeElement))
					this.state.breadcrumbsScrollContainer.focus();
			}
			// Bento is using single column view
			else if (this.state.compactHeaderScrollContainer) {
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				if (!this.state.compactHeaderScrollContainer.contains(document.activeElement))
					this.state.compactHeaderScrollContainer.focus();
			}
		}
	}

	private loomInsertTargetId: string;

	avoidFocusDOM: RefObject<boolean> = { current: false };

	isInitialFocusSet: RefObject<boolean> = { current: false };

	setBreadcrumbsScrollContainer = (element: HTMLDivElement) => {
		this.setState({ breadcrumbsScrollContainer: element });
	};

	setCompactHeaderScrollContainer = (element: HTMLElement) => {
		this.setState({ compactHeaderScrollContainer: element });
	};

	setHeaderActionsScrollContainer = (element: HTMLElement) => {
		this.setState({ headerActionsScrollContainer: element });
	};

	setSidePanelContainer = (element?: HTMLElement) => {
		if (!element) return;
		this.setState((prevState: State) => ({
			refs: {
				...prevState.refs,
				refSidePanelContainerElement:
					// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					this.props.isMobile && this.props.isCompact ? document.body : element,
			},
		}));
	};

	setLayoutContainer = (element: HTMLElement | null) => {
		if (!element) return;
		this.setState((prevState: State) => ({
			refs: {
				...prevState.refs,
				refLayoutContainer: element,
			},
		}));
	};

	setPanelColumn = (element: HTMLElement | null) => {
		if (!element) return;
		this.setState((prevState: State) => ({
			refs: {
				...prevState.refs,
				refPanelColumnElement: element,
			},
		}));
	};

	setVisibilityContainer = (element: HTMLElement | null) => {
		if (!element) return;
		this.setState((prevState: State) => ({
			refs: {
				...prevState.refs,
				refVisibilityContainer: element,
			},
			contextWidth: element.offsetWidth,
		}));
	};

	// When a column is focused, this function is triggered to change the focusedPanel
	setFocusedPanel(panel: Panel) {
		return () => {
			if (!this.props.triggerFocusedPanel || this.props.focusedPanel === panel) return;
			this.props.triggerFocusedPanel(panel);
		};
	}

	setSidePanelAndHeaderActionsContainers = (node: HTMLElement) => {
		this.setSidePanelContainer(node);
		this.setHeaderActionsScrollContainer(node);
	};

	getPassedDownRefs = (): PassedDownRefs => this.state.refs;

	shouldRenderHeaderFF = (): boolean => this.props.shouldRenderHeader;

	didStateChange(nextState: State) {
		const { refs, ...nonPanelPositioningState } = this.state;
		const { refs: nextStateRefs, ...nonPanelPositioningNextState } = nextState;
		// if only panel positioning has changed but panels are closed - no need to rerender
		if (
			this.props.arePanelsClosed &&
			isEqual(nonPanelPositioningState, nonPanelPositioningNextState)
		) {
			return false;
		}

		return !isEqual(this.state, nextState);
	}

	didPropsChange(nextProps: Props) {
		return !isEqual(this.props, nextProps);
	}

	renderGlancePanels(): ReactElement<ComponentProps<typeof GlanceAnimatedPanel>>[] {
		return this.props.glances.map((glance) => (
			<GlanceAnimatedPanel
				key={`connectGlanceAnimatedPanel-${glance.appKey}__${glance.moduleKey}`}
				glance={glance}
				openedGlance={this.props.openedGlance}
				refs={this.getPassedDownRefs()}
				glanceType={glance.glanceType}
			/>
		));
	}

	renderContextPanel(): ReactElement<ComponentProps<typeof ContextPanel>> {
		return <ContextPanel key="context-panel" refs={this.getPassedDownRefs()} />;
	}

	// If adding any new items to layout please ensure they are added to the templates and not here.
	renderFullSizeLayoutSideContent = () => (
		<VisibilityContainer
			ref={this.setVisibilityContainer}
			show={this.props.arePanelsClosed}
			key="sideContentWrapper"
			data-testid="issue.views.issue-details.issue-layout.visibility-container"
		>
			<PerformanceMark metricKey={PerfMarks.ISSUE_STATUS_BEGIN_MARK_KEY} />

			<FoundationContextTemplateRenderer
				issueViewRelayFragment={this.props.issueViewRelayFragment}
			/>

			<PerformanceMark metricKey={PerfMarks.ISSUE_STATUS_END_MARK_KEY} />

			<UFOSegment name="context-panel">
				<ContextTemplateRenderer
					key={CONTEXT_ITEMS}
					issueViewRelayFragment={this.props.issueViewRelayFragment}
					// @ts-expect-error - TS2322 - Type '{ externalId: string; }' is not assignable to type 'IntrinsicAttributes'.
					externalId={CONTEXT_ITEMS_EXT_ID}
				/>
			</UFOSegment>

			<PerformanceMark metricKey={PerfMarks.FOOTNOTE_BEGIN_MARK_KEY} />
			<FootnoteWrapper data-testid="issue.views.issue-details.issue-layout.footnote">
				<UFOSegment name="issue-timestamps">
					{fg('relay-migration-issue-fields-metadata') ? (
						<IssueViewMetaContainer
							key={META_CONTAINER}
							fragmentKey={this.props.issueViewRelayFragment ?? null}
						/>
					) : (
						<MetaContainerView key={META_CONTAINER} />
					)}
				</UFOSegment>
				<UFOSegment name="issue-configuration-button">
					<ConfigurationButton key={CONFIGURE_FIELDS} />
				</UFOSegment>
			</FootnoteWrapper>

			<PerformanceMark metricKey={PerfMarks.FOOTNOTE_END_MARK_KEY} />
		</VisibilityContainer>
	);

	compactHeader = () =>
		fg('a11y-jira-team_bento_header_popup_reading_order') ? (
			<RenderCompactHeader
				issueViewRelayFragment={
					fg('relay-migration-issue-header-and-parent')
						? this.props.issueViewRelayFragment
						: undefined
				}
				rootRelayFragment={this.props.rootRelayFragment}
				scrollContainer={this.state.compactHeaderScrollContainer || undefined}
				shouldRenderHeader={this.shouldRenderHeaderFF()}
				shouldShowCloseButton={Boolean(this.props.shouldShowCloseButton)}
				shouldShowProjectLevelBreadcrumb={Boolean(this.props.shouldShowProjectLevelBreadcrumb)}
				shouldShowRootProjectsBreadcrumb={Boolean(this.props.shouldShowRootProjectsBreadcrumb)}
				issueDeleteCallbacks={this.props.issueDeleteCallbacks}
				renderFeedback={this.props.renderFeedback}
				viewModeOptions={this.props.viewModeOptions}
				onClose={this.props.onClose}
			/>
		) : (
			<StickyHeaderContainer
				key={SPLIT_HEADER}
				elementId={ELID_ISSUE_HEADER}
				scrollContainer={this.state.compactHeaderScrollContainer || undefined}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
				style={
					isVisualRefreshEnabled() && fg('jira_nav4_eap_drop_2')
						? {
								top: 0,
								/* padding 0 so that not to cut out button border etc. because of 0 extraTopOffset */
								paddingTop: 0,
							}
						: undefined
				}
			>
				<Header
					issueViewRelayFragment={
						fg('relay-migration-issue-header-and-parent')
							? this.props.issueViewRelayFragment
							: undefined
					}
					rootRelayFragment={this.props.rootRelayFragment}
					shouldShowCloseButton={Boolean(this.props.shouldShowCloseButton)}
					shouldShowProjectLevelBreadcrumb={Boolean(this.props.shouldShowProjectLevelBreadcrumb)}
					shouldShowRootProjectsBreadcrumb={Boolean(this.props.shouldShowRootProjectsBreadcrumb)}
					issueDeleteCallbacks={this.props.issueDeleteCallbacks}
					renderFeedback={this.props.renderFeedback}
					viewModeOptions={this.props.viewModeOptions}
					onClose={this.props.onClose}
				/>
			</StickyHeaderContainer>
		);

	renderHeaderBreadcrumbs = (): ReactElement<
		ComponentProps<typeof StickyHeaderContainer>
	> | null =>
		this.shouldRenderHeaderFF() ? (
			<UFOSegment name="issue-view-header-breadcrumbs">
				<StickyHeaderContainer
					elementId={ELID_ISSUE_HEADER}
					scrollContainer={this.state.breadcrumbsScrollContainer}
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
					style={
						isVisualRefreshEnabled() && fg('jira_nav4_eap_drop_2')
							? {
									top: 0,
									/* padding 0 so that not to cut out button border etc. because of 0 extraTopOffset */
									paddingTop: 0,
								}
							: undefined
					}
				>
					<PerformanceMark metricKey={PerfMarks.HEADER_BREADCRUMBS_BEGIN_MARK_KEY} />

					<HeaderBreadcrumbs
						shouldShowProjectLevelBreadcrumb={this.props.shouldShowProjectLevelBreadcrumb}
						shouldShowRootProjectsBreadcrumb={this.props.shouldShowRootProjectsBreadcrumb}
						isCompact={this.props.isCompact}
						issue={
							fg('relay-migration-issue-header-and-parent')
								? this.props.issueViewRelayFragment
								: undefined
						}
					/>

					<PerformanceMark metricKey={PerfMarks.HEADER_BREADCRUMBS_END_MARK_KEY} />
				</StickyHeaderContainer>
			</UFOSegment>
		) : null;

	renderHeaderActions = (): ReactElement<ComponentProps<typeof Fragment>> | ReactNode | null =>
		this.shouldRenderHeaderFF() ? (
			<UFOSegment name="issue-view-header-actions">
				<PerformanceMark metricKey={PerfMarks.HEADER_ACTIONS_BEGIN_MARK_KEY} />

				<StickyHeaderContainer
					elementId={ELID_ISSUE_HEADER_ACTIONS}
					scrollContainer={this.state.headerActionsScrollContainer || undefined}
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
					style={
						isVisualRefreshEnabled() && fg('jira_nav4_eap_drop_2')
							? {
									top: 0,
									/* padding 0 so that not to cut out button border etc. because of 0 extraTopOffset */

									paddingTop: 0,
								}
							: undefined
					}
				>
					<HeaderActions
						headerActions={
							fg('relay-migration-issue-header-and-parent')
								? this.props.issueViewRelayFragment
								: undefined
						}
						renderFeedback={this.props.renderFeedback}
						issueDeleteCallbacks={this.props.issueDeleteCallbacks}
						viewModeOptions={this.props.viewModeOptions}
						onClose={this.props.onClose}
						shouldShowCloseButton={this.props.shouldShowCloseButton}
					/>
				</StickyHeaderContainer>

				<PerformanceMark metricKey={PerfMarks.HEADER_ACTIONS_END_MARK_KEY} />
			</UFOSegment>
		) : null;

	renderResizableFullSizeLayout = () => {
		const Container = ResizableFullSizeModeColumnScrollContainer;
		const ColumnInner = FullSizeModeColumnInner;

		// This intentionally does nothing. This is to test Switcheroo exposure event processing functionality.
		// See http://go/mep-rte for more information.
		isSwitcherooRealtimeExposuresTestFlagEnabled();

		const shouldHandleBrokenSafari = !!this.props.shouldHandleBrokenSafari;
		const isModalView = this.props.viewMode === 'MODAL';

		const showChevron = !(shouldHandleBrokenSafari && isModalView);

		// Safari has no independent scrolling in issue modal view, so the chevron has to be ignored
		const chevronDirection = showChevron ? this.props.focusedPanel : undefined;
		// If adding any new items to layout please ensure they are added to the templates and not here.
		return (
			<IssuePageGrid hasMultipleScrollingColumns>
				<Container
					data-testid="issue.views.issue-details.issue-layout.container-left"
					issueMaxWidth={this.props.issueMaxWidth}
					sidebarMinWidth={0}
					isLeftmost
					ref={this.setBreadcrumbsScrollContainer}
					tabIndex={0}
					onFocus={this.setFocusedPanel('LEFT')}
				>
					<ColumnInner
						issueMaxWidth={this.props.issueMaxWidth}
						data-testid="issue.views.issue-details.issue-layout.left-most-column"
					>
						<HighlightActionsWrapper scope="issue-layout">
							<StickyHeaderTrackerContainer scope="issue-layout">
								{this.renderHeaderBreadcrumbs()}

								<PerformanceMark metricKey={PerfMarks.ISSUE_NAME_BEGIN_MARK_KEY} />
								<UFOSegment name="issue-view-foundation-content-template-renderer">
									<FoundationContentTemplateRenderer
										issueViewRelayFragment={this.props.issueViewRelayFragment}
									/>
								</UFOSegment>

								<PerformanceMark metricKey={PerfMarks.ISSUE_NAME_END_MARK_KEY} />

								<PerformanceMark metricKey={PerfMarks.QUICK_ADD_ITEMS_BEGIN_MARK_KEY} />

								<QuickAddItems key={QUICK_ADD_ITEMS} externalId="issue.quick-add" />

								<PerformanceMark metricKey={PerfMarks.QUICK_ADD_ITEMS_END_MARK_KEY} />

								<PerformanceMark metricKey={PerfMarks.CONTENT_ITEMS_BEGIN_MARK_KEY} />

								<ContentItemsView
									key={CONTENT_ITEMS}
									isCompactMode={false}
									issueViewRelayFragment={this.props.issueViewRelayFragment}
									rootRelayFragment={this.props.rootRelayFragment}
								/>

								<PerformanceMark metricKey={PerfMarks.CONTENT_ITEMS_END_MARK_KEY} />

								<PerformanceMark metricKey={PerfMarks.ACTIVITY_FEED_BEGIN_MARK_KEY} />

								<ActivityFeed
									key={ACTIVITY_FEED}
									// @ts-expect-error - TS2322 - Type '{ externalId: string; }' is not assignable to type 'IntrinsicAttributes'.
									externalId={ACTIVITY_FEED_EXT_ID}
									{...(fg('smart-replies-system-setting')
										? { rootRelayFragment: this.props.rootRelayFragment }
										: {})}
								/>

								<PerformanceMark metricKey={PerfMarks.ACTIVITY_FEED_END_MARK_KEY} />
							</StickyHeaderTrackerContainer>
						</HighlightActionsWrapper>
					</ColumnInner>
				</Container>
				{/* @ts-expect-error - TS2745 - This JSX tag's 'children' prop expects type 'never' which requires multiple children, but only a single child was provided. */}
				<IssueLayoutSubscriber>
					{/* @ts-expect-error - TS7031 - Binding element 'sidebarMinWidth' implicitly has an 'any' type. */}
					{({ sidebarMinWidth }) => (
						<Resizer
							initialRatio={this.props.sidebarRatio}
							defaultRatio={defaultRatioRightColumn}
							onRatioChange={this.props.onSaveSidebarRatio}
							onRatioReset={this.props.onResetSidebarRatio}
							extraWidth={extraSizeRightColumn}
							chevronDirection={chevronDirection}
						>
							{({ ref: resizerRef, ...resizerProps }) => (
								<Container
									{...resizerProps}
									data-testid="issue.views.issue-details.issue-layout.container-right"
									issueMaxWidth={this.props.issueMaxWidth}
									isRightmost
									sidebarMinWidth={sidebarMinWidth}
									scrollHidden={!this.props.arePanelsClosed}
									isMobile={this.props.isMobile}
									ref={over(
										resizerRef,
										this.setSidePanelContainer,
										this.setHeaderActionsScrollContainer,
									)}
									tabIndex={0}
									onFocus={this.setFocusedPanel('RIGHT')}
								>
									<ColumnInner
										isRightmost
										ref={this.setPanelColumn}
										issueMaxWidth={this.props.issueMaxWidth}
										data-testid="issue.views.issue-details.issue-layout.right-most-column"
									>
										<HighlightActionsWrapper scope="issue-layout-right">
											<StickyHeaderTrackerContainer scope="issue-layout-right">
												{this.renderHeaderActions()}
												{this.renderFullSizeLayoutSideContent()}
											</StickyHeaderTrackerContainer>
										</HighlightActionsWrapper>
									</ColumnInner>
								</Container>
							)}
						</Resizer>
					)}
				</IssueLayoutSubscriber>
			</IssuePageGrid>
		);
	};

	renderCompactLayoutItems = () => [
		<PerformanceMark
			key={PerfMarks.ISSUE_NAME_BEGIN_MARK_KEY}
			metricKey={PerfMarks.ISSUE_NAME_BEGIN_MARK_KEY}
		/>,
		<FoundationContentTemplateRenderer
			key={FOUNDATION_CONTENT}
			issueViewRelayFragment={this.props.issueViewRelayFragment}
		/>,

		<PerformanceMark
			key={PerfMarks.ISSUE_NAME_END_MARK_KEY}
			metricKey={PerfMarks.ISSUE_NAME_END_MARK_KEY}
		/>,
		<PerformanceMark
			key={PerfMarks.QUICK_ADD_ITEMS_BEGIN_MARK_KEY}
			metricKey={PerfMarks.QUICK_ADD_ITEMS_BEGIN_MARK_KEY}
		/>,
		<QuickAddItems key={QUICK_ADD_ITEMS} externalId="issue.quick-add" />,
		<PerformanceMark
			key={PerfMarks.QUICK_ADD_ITEMS_END_MARK_KEY}
			metricKey={PerfMarks.QUICK_ADD_ITEMS_END_MARK_KEY}
		/>,
		<PerformanceMark
			key={PerfMarks.ISSUE_STATUS_BEGIN_MARK_KEY}
			metricKey={PerfMarks.ISSUE_STATUS_BEGIN_MARK_KEY}
		/>,
		<FoundationContextTemplateRenderer
			key={FOUNDATION_CONTEXT}
			issueViewRelayFragment={this.props.issueViewRelayFragment}
		/>,

		<PerformanceMark
			key={PerfMarks.ISSUE_STATUS_END_MARK_KEY}
			metricKey={PerfMarks.ISSUE_STATUS_END_MARK_KEY}
		/>,
		<PerformanceMark
			key={PerfMarks.CONTENT_ITEMS_BEGIN_MARK_KEY}
			metricKey={PerfMarks.CONTENT_ITEMS_BEGIN_MARK_KEY}
		/>,
		<ContentItemsView
			key={CONTENT_ITEMS}
			isCompactMode
			issueViewRelayFragment={this.props.issueViewRelayFragment}
			rootRelayFragment={this.props.rootRelayFragment}
		/>,

		<PerformanceMark
			key={PerfMarks.CONTENT_ITEMS_END_MARK_KEY}
			metricKey={PerfMarks.CONTENT_ITEMS_END_MARK_KEY}
		/>,
		<CompactContextItemsContainer key={CONTEXT_ITEMS}>
			<ContextTemplateRenderer
				// @ts-expect-error - TS2322 - Type '{ externalId: string; }' is not assignable to type 'IntrinsicAttributes'.
				externalId={CONTEXT_ITEMS_EXT_ID}
				issueViewRelayFragment={this.props.issueViewRelayFragment}
			/>
		</CompactContextItemsContainer>,

		<PerformanceMark
			key={PerfMarks.FOOTNOTE_BEGIN_MARK_KEY}
			metricKey={PerfMarks.FOOTNOTE_BEGIN_MARK_KEY}
		/>,
		<FootnoteWrapper
			data-testid="issue.views.issue-details.issue-layout.footnote"
			key={FOOTNOTE_WRAPPER_KEY}
		>
			{fg('relay-migration-issue-fields-metadata') ? (
				<IssueViewMetaContainer
					key={META_CONTAINER}
					fragmentKey={this.props.issueViewRelayFragment ?? null}
				/>
			) : (
				<MetaContainerView key={META_CONTAINER} />
			)}
			<ConfigurationButton key={CONFIGURE_FIELDS} />
		</FootnoteWrapper>,
		<PerformanceMark
			key={PerfMarks.FOOTNOTE_END_MARK_KEY}
			metricKey={PerfMarks.FOOTNOTE_END_MARK_KEY}
		/>,
		<PerformanceMark
			key={PerfMarks.ACTIVITY_FEED_BEGIN_MARK_KEY}
			metricKey={PerfMarks.ACTIVITY_FEED_BEGIN_MARK_KEY}
		/>,
		<ActivityFeed
			key={ACTIVITY_FEED}
			// @ts-expect-error - TS2322 - Type '{ externalId: string; }' is not assignable to type 'IntrinsicAttributes'.
			externalId={ACTIVITY_FEED_EXT_ID}
			{...(fg('smart-replies-system-setting')
				? { rootRelayFragment: this.props.rootRelayFragment }
				: {})}
		/>,

		<PerformanceMark
			key={PerfMarks.ACTIVITY_FEED_END_MARK_KEY}
			metricKey={PerfMarks.ACTIVITY_FEED_END_MARK_KEY}
		/>,
	];

	renderCompactLayout = () => {
		return (
			<IssueCompactScrollContainer
				scrollHidden={!this.props.arePanelsClosed}
				ref={over(this.setSidePanelContainer, this.setCompactHeaderScrollContainer)}
				isMobile={this.props.isMobile}
				tabIndex={0}
				data-testid="issue.views.issue-details.issue-layout.compact-layout"
				onFocus={this.setFocusedPanel('LEFT')}
			>
				<IssueCompactColumnInner issueMaxWidth={this.props.issueMaxWidth} ref={this.setPanelColumn}>
					<HighlightActionsWrapper scope="issue-layout-compact">
						<StickyHeaderTrackerContainer scope="issue-layout-compact">
							{this.shouldRenderHeaderFF() ? this.compactHeader() : null}
							<VisibilityContainer
								ref={this.setVisibilityContainer}
								show={this.props.arePanelsClosed}
								key="sideContentWrapper"
								data-testid="issue.views.issue-details.issue-layout.visibility-container"
							>
								<IssuePageGrid isCompact>
									<GridColumn>
										<ConditionalInlineFieldConfigContent
											issueViewRelayFragment={this.props.issueViewRelayFragment ?? undefined}
										>
											{this.renderCompactLayoutItems()}
										</ConditionalInlineFieldConfigContent>
									</GridColumn>
								</IssuePageGrid>
							</VisibilityContainer>
						</StickyHeaderTrackerContainer>
					</HighlightActionsWrapper>
				</IssueCompactColumnInner>
			</IssueCompactScrollContainer>
		);
	};

	// If adding any new items to layout please ensure they are added to the templates and not here.
	render() {
		const issueLayout = this.props.isCompact
			? this.renderCompactLayout()
			: this.renderResizableFullSizeLayout();

		return (
			<LayoutContainer
				ref={this.setLayoutContainer}
				data-testid="issue.views.issue-details.issue-layout.issue-layout"
				data-loom-insert-target-marker={
					fg('loom_crossflow_enablement_in_jira') ? this.loomInsertTargetId : undefined
				}
			>
				<ResizableFullSizeModeColumnScrollContainer
					isLeftmost
					isRightmost
					issueMaxWidth={this.props.issueMaxWidth}
					sidebarMinWidth={0}
				>
					<UiModificationsStyleContainer
						isCompact={this.props.isCompact}
						issueMaxWidth={this.props.issueMaxWidth}
					>
						<UiModificationsEntryPoint modules={this.props.uiModificationsModules} />
					</UiModificationsStyleContainer>
				</ResizableFullSizeModeColumnScrollContainer>
				{issueLayout}
				{this.renderGlancePanels()}
				{this.renderContextPanel()}
			</LayoutContainer>
		);
	}
}

export const IssueLayoutWithIssueLayoutState = (
	props: Props & {
		layoutStyle: LayoutStyle;
	},
) => {
	const { layoutStyle, isScrollingHotkeysDisabled = false, ...otherProps } = props;
	const issueKey = useIssueKey();
	const [contextPanel] = useIssueLayoutContextPanel(issueKey);
	const [glancePanel] = useIssueLayoutGlance(issueKey);
	const [issueScrollTriggerCount] = useIssueScrollKeyboardShortcutsStore();
	const [focusedPanel, { triggerFocusedPanel }] = useFocusedPanelKeyboardShortcutsStore();

	const shouldHandleBrokenSafari = isBrokenSafari();
	const [viewMode] = useIssueContextStoreViewMode();

	const [sidebarRatio, onSaveSidebarRatio, onResetSidebarRatio] = useSidebarResize(layoutStyle);

	return (
		<IssueLayout
			{...otherProps}
			arePanelsClosed={contextPanel === undefined && glancePanel === undefined}
			openedGlance={glancePanel}
			sidebarRatio={sidebarRatio}
			onSaveSidebarRatio={onSaveSidebarRatio}
			onResetSidebarRatio={onResetSidebarRatio}
			issueScrollTriggerCount={!isScrollingHotkeysDisabled ? issueScrollTriggerCount : undefined}
			focusedPanel={!isScrollingHotkeysDisabled ? focusedPanel : undefined}
			shouldHandleBrokenSafari={shouldHandleBrokenSafari}
			viewMode={viewMode}
			triggerFocusedPanel={!isScrollingHotkeysDisabled ? triggerFocusedPanel : undefined}
			isScrollingHotkeysDisabled={isScrollingHotkeysDisabled}
		/>
	);
};

export default connect(
	(state: ReduxState) => ({
		glances: ecosystemGlancesSelector(state),
		shouldRenderHeader: shouldRenderHeaderSelector(state),
		isMobile: isMobileSelector(state),
	}),
	{},
)(
	// @ts-expect-error - Argument of type '(props: Props & {    layoutStyle: LayoutStyle;}) => JSX.Element' is not assignable to parameter of type 'ComponentType<Matching<{ glances: ({ title: string; label: string; status: IssueGlanceStatus | undefined; icon: string | undefined; extension: IssueGlance; glanceType: "FORGE_ENTITY_TYPE"; moduleKey: string; appKey: string; } | { ...; })[]; shouldRenderHeader: boolean; isMobile: boolean; } & {}, { ...; } & ... 2 mor...'.
	IssueLayoutWithIssueLayoutState,
);

const extraSizeRightColumn = 1.5 * gridSize;
const minSizeLeftColumn = 380; // pixels
const scrollbarSize = 15;

const fullSizeModeColumnInnerSelectorName = 'jira.issue-view.issue-details.full-size-mode-column';
const FULL_SIZE_MODE_COLUMN_INNER_COMPONENT_SELECTOR = `[data-component-selector="${fullSizeModeColumnInnerSelectorName}"]`;

/**
 * @deprecated `FullSizeModeColumnInnerControl` is consumed by other styled-components (e.g. src/entry/issue/rapid-board/backlog/styles)
 * Please use `FullSizeModeColumnInner` (CompiledCSS component) when migrating the consumer to CompiledCSS
 * TODO: Remove `FullSizeModeColumnInnerControl` once its consumers are migrated to CompiledCSS
 */
// TODO: migrate to object syntax. Autofix is available for many cases. Remove the eslint-disable for @atlaskit/design-system/no-styled-tagged-template-expression to check.
// eslint-disable-next-line  @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FullSizeModeColumnInnerControl = styled.div<{
	issueMaxWidth: number | undefined;
	isLeftmost?: boolean;
	isRightmost?: boolean;
}>`
	padding: 0 ${token('space.400', '32px')} ${issueViewBottomPadding}px;
	outline: none;

	/* Padding needs to be added to top in the case that issueMaxWidth is present to space from header in SPA mode. */
	${({ issueMaxWidth }) =>
		issueMaxWidth !== undefined ? `padding-top: ${fullPageIssueWrapperMarginTop}px;` : ''}

	/* side padding is needed to provide min padding for those browsers which do not support css max() function: https://caniuse.com/#feat=css-math-functions */
    ${({ isLeftmost, isRightmost }) => {
		if (isLeftmost)
			return `
                padding-left: ${issueViewSidePadding}px;
                padding-right: ${3 * gridSize}px;
                `;
		if (isRightmost)
			return `
                padding-left: ${gridSize}px;
                padding-right: ${issueViewSidePadding - 1.5 * gridSize}px;
                `;
		return '';
	}}
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FullSizeModeColumnInnerComponent = styled2.div<{
	issueMaxWidth: number | undefined;
	isRightmost?: boolean;
}>(
	{
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		paddingTop: ({ issueMaxWidth }) =>
			issueMaxWidth !== undefined ? token('space.250', '20px') : token('space.0', '0px'),
		paddingRight: token('space.150', '12px'),
		paddingBottom: token('space.400', '32px'),
		paddingLeft: token('space.300', '24px'),
		outline: 'none',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isRightmost }) =>
		isRightmost && {
			paddingLeft: token('space.0', '0px'),
			paddingRight: token('space.300', '24px'),
		},
);

const FullSizeModeColumnInner = forwardRef<
	HTMLDivElement,
	ComponentPropsWithoutRef<typeof FullSizeModeColumnInnerComponent>
>((props, ref) => (
	<FullSizeModeColumnInnerComponent
		data-component-selector={fullSizeModeColumnInnerSelectorName}
		ref={ref}
		{...props}
	/>
));

// This component should not be rendered in Compact mode (with 1 column in issue view)
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ResizableFullSizeModeColumnScrollContainer = styled2.div<{
	scrollHidden?: boolean;
	isMobile?: boolean;
	isLeftmost?: boolean;
	isRightmost?: boolean;
	issueMaxWidth: number | undefined;
	sidebarMinWidth: number;
}>(
	{
		outline: 'none',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ scrollHidden, isMobile }) =>
		scrollHidden
			? {
					overflow: 'hidden',
					[FULL_SIZE_MODE_COLUMN_INNER_COMPONENT_SELECTOR]: {
						borderRight: `${scrollbarSize}px solid transparent`,
					},
				}
			: {
					overflowX: isMobile
						? 'visible'
						: 'hidden' /* 2 value shorthand syntax is not supported in IE11 */,
					overflowY: isMobile ? 'visible' : 'auto',
				},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isLeftmost }) =>
		isLeftmost && {
			flexGrow: 1,
			flexShrink: 0,
			flexBasis: 0,
			minWidth: `${minSizeLeftColumn}px`,
		},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isRightmost, issueMaxWidth, sidebarMinWidth }) =>
		isRightmost &&
		issueMaxWidth === undefined && {
			minWidth: `${sidebarMinWidth}px`,
		},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isLeftmost, issueMaxWidth }) =>
		isLeftmost &&
		issueMaxWidth !== undefined && {
			paddingLeft: `max(calc((100% - ${issueMaxWidth}px) / 2), 0px)`,
		},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isRightmost, issueMaxWidth, sidebarMinWidth }) =>
		isRightmost &&
		issueMaxWidth !== undefined && {
			paddingRight: `max(calc((100% - ${issueMaxWidth}px) / 2), 0px)`,
			minWidth: `max(calc((100% - ${issueMaxWidth}px) / 2 + ${sidebarMinWidth}px), ${sidebarMinWidth}px)`,
			transition: 'min-width .15s',
		},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CompactContextItemsContainer = styled2.div({
	marginTop: token('space.300', '24px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LayoutContainer = styled2.div({
	position: 'relative',
	height: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FootnoteWrapper = styled2.div({
	display: 'flex',
	flexWrap: 'wrap',
	flexDirection: 'row',
	alignItems: 'flex-start',
	justifyContent: 'space-between',
	alignContent: 'stretch',
	marginLeft: token('space.negative.100', '-8px'),
	marginBottom: token('space.300', '24px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	paddingLeft: `${gridSize * 1.25}px`,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const VisibilityContainer = styled2.div<{ show?: any }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	visibility: (props) => (props.show ? 'visible' : 'hidden'),
});

/*
 * Apply overflow auto, unless it's mobile.
 * The overflow needs to be visible on mobile when the FF is on, otherwise the header won't be sticky.
 * On desktop, if scrollHidden is true, the overflow is overwritten to hidden.
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IssueCompactScrollContainer = styled2.div<{
	isMobile: boolean;
	scrollHidden: boolean;
}>(
	{
		height: '100%',
		outline: 'none',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isMobile }) => !isMobile && { overflow: 'auto' },
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ scrollHidden }) =>
		scrollHidden && {
			overflow: 'hidden',
			borderRight: `${scrollbarSize}px solid transparent`,
		},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IssueCompactColumnInner = styled2.div<{ issueMaxWidth: string | number | undefined }>({
	padding: `${token('space.250', '20px')}
	${token('space.300', '24px')}
	${token('space.400', '32px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const UiModificationsStyleContainer = styled2.div<{
	isCompact: boolean;
	issueMaxWidth?: number | undefined;
}>(
	{
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		paddingLeft: `${issueViewSidePadding}px`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		paddingRight: ({ isCompact }) =>
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			isCompact ? `${issueViewSidePadding}px` : `${issueViewSidePadding - 1.5 * gridSize}px`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ issueMaxWidth, isCompact }) =>
		issueMaxWidth === undefined &&
		isCompact && {
			paddingLeft: `${token('space.250', '20px')}`,
			paddingRight: `${token('space.250', '20px')}`,
		},
);
