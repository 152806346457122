// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction
/* eslint-disable jira/i18n/id-named-by-package */

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	exportDOC: {
		id: 'issue.export-doc',
		defaultMessage: 'Export Word',
		description: 'Label for the issue actions item to export the issue to Microsoft Word',
	},
});
