import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import {
	CHILDREN_ISSUES,
	EPIC_ISSUES,
	PORTFOLIO_CHILD_ISSUES,
	SUBTASKS,
} from '@atlassian/jira-issue-view-configurations/src/index.tsx';

export const useTotalChildIssuesCount = () => {
	const issueKey = useIssueKey();
	const [subtasksFieldValue] = useFieldValue({ issueKey, fieldKey: SUBTASKS });
	const [childrenIssuesFieldValue] = useFieldValue({ issueKey, fieldKey: CHILDREN_ISSUES });
	const [epicIssuesFieldValue] = useFieldValue({ issueKey, fieldKey: EPIC_ISSUES });
	const [portfolioChildIssuesFieldValue] = useFieldValue({
		issueKey,
		fieldKey: PORTFOLIO_CHILD_ISSUES,
	});

	const subtasksCount = Array.isArray(subtasksFieldValue) ? subtasksFieldValue.length : 0;
	const childrenIssuesCount = Array.isArray(childrenIssuesFieldValue)
		? childrenIssuesFieldValue.length
		: 0;
	const epicIssuesCount = Array.isArray(epicIssuesFieldValue) ? epicIssuesFieldValue.length : 0;
	const portfolioChildIssuesCount = Array.isArray(portfolioChildIssuesFieldValue)
		? portfolioChildIssuesFieldValue.length
		: 0;

	return subtasksCount + childrenIssuesCount + epicIssuesCount + portfolioChildIssuesCount;
};
