import React, { useMemo } from 'react';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import EmojiFlagsIcon from '@atlaskit/icon/glyph/emoji/flags';
import { SummaryItem } from '@atlassian/jira-development-summary-common/src/ui/summary-item/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	FireUiAnalytics,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { FEATURE_FLAG_SUMMARY_ITEM_TEST_ID } from '../../common/types.tsx';
import { FeatureFlagActionDropdownSimple } from '../../common/ui/feature-flag-action-dropdown-simple/index.tsx';
import { AddFeatureFlagIcon } from '../../common/ui/feature-flag-icon/index.tsx';
import messages from './messages.tsx';

type FeatureFlagSummaryItemProps = {
	connectFeatureFlagTarget?: string;
	createFeatureFlagTarget?: string;
};

export const FeatureFlagEmptyState = () => {
	const { formatMessage } = useIntl();

	const icon = <EmojiFlagsIcon label="Flag Icon" />;
	return (
		<SummaryItem
			icon={icon}
			disabled
			title={formatMessage(messages.emptyState)}
			data-testid={FEATURE_FLAG_SUMMARY_ITEM_TEST_ID}
		/>
	);
};

const AddFeatureFlagSummaryItem = ({
	connectFeatureFlagTarget,
	createFeatureFlagTarget,
}: FeatureFlagSummaryItemProps) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const downChevron = useMemo(
		() => (
			<ChevronDownIcon
				testId="development-summary-feature-flag.ui.empty.chevron-down-icon"
				size="medium"
				label={formatMessage(messages.featureFlagActionDropdownIconLabel)}
			/>
		),
		[formatMessage],
	);

	const commonSummaryItemProps = {
		title: formatMessage(messages.addFeatureFlagTitle),
		linkFormatting: true,
		icon: AddFeatureFlagIcon,
	};

	return (connectFeatureFlagTarget != null && connectFeatureFlagTarget !== undefined) ||
		(createFeatureFlagTarget != null && createFeatureFlagTarget !== undefined) ? (
		<>
			<FireUiAnalytics
				key="viewed button createDevAction"
				action="viewed"
				actionSubject="button"
				actionSubjectId="createDevAction"
				attributes={{ inline: false, summaryItemType: 'commit' }}
			/>
			<FeatureFlagActionDropdownSimple
				getDropdownTrigger={({ ref, onClick, ...props }) => (
					<SummaryItem
						secondaryData={downChevron}
						reference={ref}
						{...commonSummaryItemProps}
						{...props}
						onClick={() => {
							onClick();
							fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'createDevAction', {
								inline: false,
								summaryItemType: 'commit',
							});
						}}
						data-testid={FEATURE_FLAG_SUMMARY_ITEM_TEST_ID}
					/>
				)}
				connectFeatureFlagTarget={connectFeatureFlagTarget}
				createFeatureFlagTarget={createFeatureFlagTarget}
			/>
		</>
	) : null;
};

export default AddFeatureFlagSummaryItem;
