import React from 'react';

import { LinkButton } from '@atlaskit/button/new';
import Link from '@atlaskit/link';

import { emptyStateIcon } from './icons/main';
import messages from './messages';
import {
	ActionLink,
	EmptyStateIconContainer,
	Links,
	ManualRuleEmptyState,
	Message,
} from './styled';

/**
 * the interface for the properties of @ManualRulesEmptyState
 *
 * @baseAutomationUrl {string} the host url you'd like the create/manage automations links to route to
 * @createAutomationComponentFilter {string} optional category string to apply as a filter to the triggers when routing
 * @canManageAutomations {boolean} indicates whether the user is an administrator and has access to creating automations
 * @onEmptyStateCreateAutomationButtonClicked {() => void} is called when create automation button is clicked
 * @onEmptyStateSeeTemplatesLinkClicked {() => void} is called when see templates link is clicked
 */
interface ManualRulesEmptyStateProps {
	baseAutomationUrl: string;
	createAutomationComponentFilter?: string;
	canManageAutomations: boolean;
	onEmptyStateCreateAutomationButtonClicked?: () => void;
	onEmptyStateSeeTemplatesLinkClicked?: () => void;
}

const handleOptionalCallback = (optionalEvent?: () => void) => {
	if (optionalEvent) {
		optionalEvent();
	}
};
/**
 * The new design for the empty state, when there is no rule to show. Based on the parameters passed to the component, it determines
 * if the user has administration access to the Automation and if so, it will show the buttons to manage the rules
 */
export const ManualRulesEmptyState = (props: ManualRulesEmptyStateProps) => {
	const {
		baseAutomationUrl,
		canManageAutomations,
		createAutomationComponentFilter,
		onEmptyStateCreateAutomationButtonClicked,
		onEmptyStateSeeTemplatesLinkClicked,
	} = props;

	const componentFilter = createAutomationComponentFilter
		? `?componentFilterCriteria=${createAutomationComponentFilter}`
		: '';

	const createNewRuleUrl = `${baseAutomationUrl}#/rule/new/__NEW__TRIGGER${componentFilter}`;

	const automationTemplateLibraryUrl = canManageAutomations
		? `${baseAutomationUrl}#/tab/rule-library`
		: 'https://www.atlassian.com/software/jira/automation-template-library#/rule-list?systemLabelId=all&page=1&pageSize=20&sortKey=name&sortOrder=ASC';

	return (
		<ManualRuleEmptyState>
			<EmptyStateIconContainer>{emptyStateIcon}</EmptyStateIconContainer>
			<Message>{messages.emptyStateMessage.defaultMessage}</Message>
			<Links>
				<ActionLink>
					<LinkButton
						appearance="default"
						onClick={() => {
							handleOptionalCallback(onEmptyStateCreateAutomationButtonClicked);
						}}
						href={createNewRuleUrl}
						isDisabled={!canManageAutomations}
						testId="empty-state-create-automation-button"
						title={
							canManageAutomations
								? undefined
								: messages.createAutomationsButtonTitle.defaultMessage
						}
					>
						{messages.addManuallyTriggeredAutomation.defaultMessage}
					</LinkButton>
				</ActionLink>
				<ActionLink>
					<Link
						onClick={() => {
							handleOptionalCallback(onEmptyStateSeeTemplatesLinkClicked);
						}}
						href={automationTemplateLibraryUrl}
						testId="empty-state-see-templates-link"
					>
						{messages.seeTemplates.defaultMessage}
					</Link>
				</ActionLink>
			</Links>
		</ManualRuleEmptyState>
	);
};
