// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	exportConfluence: {
		id: 'issue.export-confluence',
		defaultMessage: 'Export Confluence',
		description: 'Label for the issue actions item to export the issue to Confluence',
	},
	errorTitle: {
		id: 'issue.export-confluence.error-title',
		defaultMessage: "We couldn't fetch confluence settings",
		description:
			'The title text in the status flag if the confluence configuration can not be fetched.',
	},
	errorDescription: {
		id: 'issue.export-confluence.error-description',
		defaultMessage: 'Wait a moment then refresh the page to try again.',
		description:
			'The description text in the status flag if the responder alerts configuration status can not be fetched.',
	},
});
