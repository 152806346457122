import { createSelector } from 'reselect';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { Props, State } from '../types.tsx';
import { isMatchingConnectionFieldFilter } from '../utils/connection-field-filters.tsx';
import { fieldMapping } from '../utils/field-mapping/index.tsx';
import { createGetField, getAllFieldsByKey, getFields } from './fields.tsx';
import { getIssueIdsConsideringArchived } from './filters.tsx';
import { getJiraIdToLocalIssueId } from './issue-ids.tsx';
import {
	createGetIssueType,
	getConnectionProperties,
	getSelectedIssueLocalIssueId,
} from './properties/index.tsx';
import {
	createCombinedComparator,
	createIdeaComparatorForFieldComparator,
	type IdeaComparator,
} from './sort.tsx';

export const createGetAllIssueIdsMatchingConnectionFieldFilters = (
	localIssueId: LocalIssueId,
	fieldKey: FieldKey,
) =>
	createSelector(
		getIssueIdsConsideringArchived,
		createGetField(fieldKey),
		(state, props) => ({ state, props }),
		(issueIds, connectionField, { state, props }) =>
			issueIds.filter(
				(issueId) =>
					issueId !== localIssueId &&
					isMatchingConnectionFieldFilter({
						filters: connectionField?.configuration?.issueTypeFilters || [],
						issueType: createGetIssueType(issueId)(state, props),
						issueTypeNameFilter: props?.getIssueTypeNameFilter,
					}),
			),
	);

export const createIsIssueMatchingConnectionFieldFilter = (
	localIssueId: LocalIssueId = '',
	fieldKey: FieldKey,
) =>
	createSelector(
		createGetField(fieldKey),
		(state: State, props?: Props) => ({ state, props }),
		(connectionField, { state, props }) =>
			isMatchingConnectionFieldFilter({
				filters: connectionField?.configuration?.issueTypeFilters || [],
				issueType: createGetIssueType(localIssueId)(state, props),
				issueTypeNameFilter: props?.getIssueTypeNameFilter,
			}),
	);

export const createGetConnectionFieldIssueIds = (localIssueId: LocalIssueId, fieldKey: FieldKey) =>
	createSelector(
		getConnectionProperties,
		(connectionProperties) => connectionProperties[fieldKey]?.[localIssueId] || [],
	);

const createGetSelectedIssueConnectionFieldIssueIds = (connectionFieldKey: FieldKey) =>
	createSelector(
		getSelectedIssueLocalIssueId,
		getConnectionProperties,
		(localIssueId, connectionProperties) => {
			if (!localIssueId) {
				return [];
			}

			return connectionProperties[connectionFieldKey]?.[localIssueId] || [];
		},
	);

export const createGetSelectedIssueConnectionFieldSortedIssueIds = (connectionFieldKey: FieldKey) =>
	createSelector(
		createGetSelectedIssueConnectionFieldIssueIds(connectionFieldKey),
		createGetField(connectionFieldKey),
		getAllFieldsByKey,
		getFields,
		getJiraIdToLocalIssueId,
		({ containerProps }) => containerProps?.issuesRemote,
		(state, props) => ({ state, props }),
		(
			ids,
			connectionField,
			fieldsByKey,
			fields,
			jiraIdToLocalIssueId,
			issuesRemote,
			{ state, props },
		) => {
			if (!connectionField || !issuesRemote) {
				return [];
			}

			const sortBy = connectionField.configuration?.issueViewLayout?.sort || [];

			const configuredComparators = sortBy.reduce<Array<IdeaComparator>>((comparators, sort) => {
				const mapping = fieldMapping(issuesRemote, fields, fieldsByKey[sort.fieldKey]);

				const comparator = createIdeaComparatorForFieldComparator(
					state,
					props,
					mapping.valueAccessor,
					mapping.comparator,
					mapping.comparatorWithMapping,
					sort.order === 'ASC',
				);

				comparators.push(comparator);

				return comparators;
			}, []);

			const combinedComparator = createCombinedComparator(configuredComparators);

			const idsCopy = [...ids];

			idsCopy.sort((a, b) =>
				combinedComparator(
					jiraIdToLocalIssueId[parseInt(a.id, 10)],
					jiraIdToLocalIssueId[parseInt(b.id, 10)],
				),
			);

			return idsCopy;
		},
	);

export const createGetSelectedIssuesConnectionFieldIssuesCount = (fieldKey: FieldKey) =>
	createSelector(createGetSelectedIssueConnectionFieldIssueIds(fieldKey), (ids) => ids.length);
