import React from 'react';
import type { CustomGlyphProps } from '@atlaskit/icon';

export const visibleGlyph = (props: CustomGlyphProps) => (
	<svg
		{...props}
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect width="16" height="16" fill="white" fillOpacity="0.01" />
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M7.99992 12.0001C4.97592 12.0001 2.66725 9.16008 2.66725 8.00008C2.66725 6.66608 4.97325 4.00008 8.00059 4.00008C10.9179 4.00008 13.3326 6.64875 13.3326 8.00008C13.3326 9.16008 11.0246 12.0001 8.00059 12.0001H7.99992ZM8.00059 2.66675C4.31992 2.66675 1.33325 5.89408 1.33325 8.00008C1.33325 10.0574 4.38392 13.3334 7.99992 13.3334C11.6153 13.3334 14.6666 10.0574 14.6666 8.00008C14.6666 5.89408 11.6799 2.66675 7.99992 2.66675"
			fill="currentColor"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M7.98459 9.32275C7.24925 9.32275 6.65125 8.72475 6.65125 7.98941C6.65125 7.25408 7.24925 6.65608 7.98459 6.65608C8.72059 6.65608 9.31792 7.25408 9.31792 7.98941C9.31792 8.72475 8.72059 9.32275 7.98459 9.32275ZM7.98459 5.32275C6.51392 5.32275 5.31792 6.51875 5.31792 7.98941C5.31792 9.46008 6.51392 10.6561 7.98459 10.6561C9.45592 10.6561 10.6513 9.46008 10.6513 7.98941C10.6513 6.51875 9.45592 5.32275 7.98459 5.32275Z"
			fill="currentColor"
		/>
	</svg>
);
