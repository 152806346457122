/**
 * @generated SignedSource<<e56ab2656e0a974c408d441d834cfc87>>
 * @relayHash 29fd4cb1579123e358050f8433d533d6
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID e9dead6d817ca59da95260ff1b1afc6588c9e42e8a9d4f5089bf92a2a5740fca

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type JiraSingleValueFieldOperations = "SET" | "%future added value";
export type JiraUpdateCascadingSelectFieldInput = {
  id: string;
  operation: JiraCascadingSelectFieldOperationInput;
};
export type JiraCascadingSelectFieldOperationInput = {
  childOption?: string | null | undefined;
  operation: JiraSingleValueFieldOperations;
  parentOption?: string | null | undefined;
};
export type useSaveCascadeSelectField_Mutation$variables = {
  input: JiraUpdateCascadingSelectFieldInput;
};
export type useSaveCascadeSelectField_Mutation$data = {
  readonly jira: {
    readonly updateCascadingSelectField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly cascadingOption: {
          readonly childOptionValue: {
            readonly id: string;
            readonly optionId: string;
            readonly value: string | null | undefined;
          } | null | undefined;
          readonly parentOptionValue: {
            readonly id: string;
            readonly optionId: string;
            readonly value: string | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type useSaveCascadeSelectField_Mutation$rawResponse = {
  readonly jira: {
    readonly updateCascadingSelectField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly cascadingOption: {
          readonly childOptionValue: {
            readonly id: string;
            readonly optionId: string;
            readonly value: string | null | undefined;
          } | null | undefined;
          readonly parentOptionValue: {
            readonly id: string;
            readonly optionId: string;
            readonly value: string | null | undefined;
          } | null | undefined;
        } | null | undefined;
        readonly id: string;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type useSaveCascadeSelectField_Mutation = {
  rawResponse: useSaveCascadeSelectField_Mutation$rawResponse;
  response: useSaveCascadeSelectField_Mutation$data;
  variables: useSaveCascadeSelectField_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "success"
},
v3 = {
  "concreteType": "MutationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "message"
    }
  ]
},
v4 = {
  "kind": "ScalarField",
  "name": "id"
},
v5 = [
  (v4/*: any*/),
  {
    "kind": "ScalarField",
    "name": "value"
  },
  {
    "kind": "ScalarField",
    "name": "optionId"
  }
],
v6 = {
  "concreteType": "JiraCascadingOption",
  "kind": "LinkedField",
  "name": "cascadingOption",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraOption",
      "kind": "LinkedField",
      "name": "parentOptionValue",
      "plural": false,
      "selections": (v5/*: any*/)
    },
    {
      "concreteType": "JiraOption",
      "kind": "LinkedField",
      "name": "childOptionValue",
      "plural": false,
      "selections": (v5/*: any*/)
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "useSaveCascadeSelectField_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraCascadingSelectFieldPayload",
            "kind": "LinkedField",
            "name": "updateCascadingSelectField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraCascadingSelectField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  (v6/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useSaveCascadeSelectField_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraCascadingSelectFieldPayload",
            "kind": "LinkedField",
            "name": "updateCascadingSelectField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraCascadingSelectField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v4/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "e9dead6d817ca59da95260ff1b1afc6588c9e42e8a9d4f5089bf92a2a5740fca",
    "metadata": {},
    "name": "useSaveCascadeSelectField_Mutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "31eb55881d99579d2be9c6794238a263";

export default node;
