import type { DocNode as ADF } from '@atlaskit/adf-schema';
import { defaultSchema } from '@atlaskit/adf-schema/schema-default';
import { renderDocument } from '@atlaskit/renderer/render-document';
import { TextSerializer } from '@atlaskit/renderer/text-serializer';

const serializer = TextSerializer.fromSchema(defaultSchema);

export const convertAdfToText = (doc?: ADF) => {
	if (!doc) {
		return '';
	}
	if (doc?.content?.length < 1) {
		// renderer has a bug where empty adf returns null instead of empty string
		return '';
	}
	const outputText = renderDocument<string>(doc, serializer, defaultSchema).result;

	return outputText;
};
