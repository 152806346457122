import { baseScopesRequiredPerField } from '@atlassian/jira-issue-adjustments/src/common.tsx';
import { isAriResourceIdValid } from '../utils/is-ari-resource-id-valid/index.tsx';
import { isNotEmptyString } from '../utils/is-not-empty-string/index.tsx';

export type SelectPublicGetValue = null | {
	id: string;
	value: string;
};

export type SelectPublicSetValue = null | string;

export const selectSetValueValidator = (value: unknown): value is SelectPublicSetValue =>
	value === null || isNotEmptyString(value);

export const selectWithAriSetValueValidator = (value: unknown): value is SelectPublicSetValue =>
	value === null || (isNotEmptyString(value) && isAriResourceIdValid(value));

export const selectScopes = baseScopesRequiredPerField;
