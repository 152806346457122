import type { UserPreferences } from '@atlassian/jira-issue-shared-types/src/common/types/user-preferences-type.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { State, Value, Preferences } from './types.tsx';

type StoreApi = StoreActionApi<State>;

const setUserPreferenceValue =
	(key: UserPreferences, value: Value) =>
	({ setState, getState }: StoreApi) => {
		setState({
			preferences: {
				...getState().preferences,
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				[key as string]: value,
			},
		});
	};

const deleteUserPreferenceValue =
	(key: UserPreferences) =>
	({ setState, getState }: StoreApi) => {
		const { [key]: _, ...preferences } = getState().preferences;
		setState({
			preferences,
		});
	};

const setUserPreferences =
	(state: Preferences) =>
	({ setState }: StoreApi) => {
		setState({
			preferences: state,
		});
	};

export const getUserPreferenceValue =
	(key: UserPreferences) =>
	({ getState }: StoreApi) => {
		const { preferences } = getState();
		return preferences[key];
	};

export const actions = {
	setUserPreferenceValue,
	deleteUserPreferenceValue,
	setUserPreferences,
	getUserPreferenceValue,
} as const;
export type Actions = typeof actions;
