import React, { useCallback } from 'react';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import type { ShortcutComponentProps } from '@atlassian/jira-shortcuts-dialog/src/common/types.tsx';
import { useFindFieldDialog } from '../../find-field/controller/index.tsx';

const FindField = ({ label, itemKey, onClick }: ShortcutComponentProps) => {
	const [, { openFindFieldDialog }] = useFindFieldDialog();

	const handleClick = useCallback(() => {
		openFindFieldDialog();
		onClick?.(itemKey);
	}, [itemKey, onClick, openFindFieldDialog]);

	return (
		<DropdownItem key="findFieldDropdownItem" onClick={handleClick}>
			{label}
		</DropdownItem>
	);
};

export default FindField;
