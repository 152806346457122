/**
 * @generated SignedSource<<715f2761cb45dc3b82b50c07e34f84ff>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutApproversField_IssueViewApproversField$data = {
  readonly __typename: "JiraPeopleField";
  readonly fieldId: string;
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"people_issueFieldPeopleInlineEditFull_PeopleInlineEditView_fragmentRef" | "ui_issueViewLayoutFieldHeading_IssueViewFieldHeading">;
  readonly " $fragmentType": "ui_issueViewLayoutApproversField_IssueViewApproversField";
};
export type ui_issueViewLayoutApproversField_IssueViewApproversField$key = {
  readonly " $data"?: ui_issueViewLayoutApproversField_IssueViewApproversField$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutApproversField_IssueViewApproversField">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutApproversField_IssueViewApproversField",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "ui_issueViewLayoutFieldHeading_IssueViewFieldHeading"
    },
    {
      "kind": "FragmentSpread",
      "name": "people_issueFieldPeopleInlineEditFull_PeopleInlineEditView_fragmentRef"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "ScalarField",
      "name": "__typename"
    }
  ],
  "type": "JiraPeopleField"
};

(node as any).hash = "e9639f0efb02d0cb20db3d3c7112c786";

export default node;
