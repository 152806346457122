import React, { useCallback } from 'react';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import type { Props } from './types.tsx';

const DeleteIssue = ({ onDeleteIssueClick, onClick, label, itemKey }: Props) => {
	const handleClick = useCallback(() => {
		onDeleteIssueClick();
		onClick?.(itemKey);
	}, [onDeleteIssueClick, onClick, itemKey]);

	return (
		<DropdownItem key="delete" onClick={handleClick}>
			{label}
		</DropdownItem>
	);
};

export default DeleteIssue;
