import type { ThemeAppearance } from '@atlaskit/lozenge';
import type {
	DeploymentState,
	EnvironmentType,
} from '@atlassian/jira-development-common/src/model/releases-data.tsx';

export const deploymentStates: {
	[key: string]: number;
} = {
	PENDING: 5,
	IN_PROGRESS: 4,
	SUCCESSFUL: 3,
	FAILED: 6,
	ROLLED_BACK: 2,
	CANCELLED: 1,
	UNKNOWN: 0,
};

export const deploymentEnvironmentTypes: {
	[key: string]: number;
} = {
	PRODUCTION: 4,
	STAGING: 3,
	TESTING: 2,
	DEVELOPMENT: 1,
	UNMAPPED: 0,
};

type Provider = {
	id: string;
	createFlagTemplateUrl?: string;
	linkFlagTemplateUrl?: string;
};

type FeatureFlagRollout = {
	percentage?: number;
	rules?: number;
	text?: string;
};

type FeatureFlagStatus = {
	enabled: boolean;
	defaultValue?: string;
	rollout?: FeatureFlagRollout;
};

type FeatureFlagSummary = {
	url?: string;
	status: FeatureFlagStatus;
	lastUpdated?: string;
};

export type FeatureFlag = {
	providerId?: string;
	id: string;
	key: string;
	displayName?: string;
	summary: FeatureFlagSummary;
};

type FeatureFlagInformation = {
	providers: Provider[];
	featureFlags: FeatureFlag[];
};

type RemoteLinkStatus = {
	readonly label: string;
	readonly appearance: ThemeAppearance;
};

type RemoteLinkAction = {
	readonly id: string;
	readonly label: string;
	readonly url: string;
};

type RemoteLink = {
	readonly id: string;
	readonly providerId: string;
	readonly displayName: string;
	readonly url: string;
	readonly type: string;
	readonly description?: string;
	readonly status?: RemoteLinkStatus;
	readonly actions?: RemoteLinkAction[];
};

type RemoteLinkProvider = {
	readonly id: string;
	readonly name: string;
	readonly homeUrl: string;
	readonly logoUrl?: string;
	readonly documentationUrl?: string;
};

type RemoteLinkInformation = {
	readonly providers: RemoteLinkProvider[];
	readonly remoteLinks: RemoteLink[];
};

// deployments types
type DeploymentProvider = {
	id: string;
	name: string;
	homeUrl: string;
	logoUrl?: string;
	documentationUrl?: string;
	listDeploymentsTemplateUrl?: string;
};

type DeploymentPipeline = {
	id: string;
	displayName: string;
	url: string;
};

type DeploymentEnvironment = {
	id: string;
	type: EnvironmentType;
	displayName: string;
};

export type Deployment = {
	providerId: string;
	deploymentSequenceNumber: number;
	updateSequenceNumber: number;
	displayName: string;
	url: string;
	description: string;
	lastUpdated: string;
	state: DeploymentState;
	pipeline: DeploymentPipeline;
	environment: DeploymentEnvironment;
};

type DeploymentInformation = {
	providers: DeploymentProvider[];
	deployments: Deployment[];
};

export type Data = {
	featureFlagInformation: FeatureFlagInformation;
	deploymentInformation?: DeploymentInformation;
	remoteLinkInformation?: RemoteLinkInformation;
};

type ErrorLocation = {
	line?: number;
	column?: number;
};

type ExtensionGraphqlError = {
	status: number[];
	statusCode: number | null;
	errorType?: string;
	operationName?: string;
	tenantId?: string;
	userMessage?: string;
	classification?: string;
};

export type ReleasesDataError = {
	message?: string;
	locations?: ErrorLocation[];
	path?: string[];
	extensions?: ExtensionGraphqlError;
};

export type ConsolidatedDataResponse = {
	data: {
		dataDepotEntities: Data;
	};
	errors?: ReleasesDataError[];
};

export type DataResponse = {
	data: Data;
	errors?: ReleasesDataError[];
};
