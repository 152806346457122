import { graphql, fetchQuery } from 'react-relay';
import { createSessionStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/session-storage/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { setApplicationProperties } from '@atlassian/jira-platform-application-properties/src/services/rest-v3/index.tsx';
import getRelayEnvironment from '@atlassian/jira-relay-environment/src/index.tsx';
import type { applicationPropertiesGetConfluencePropertiesQuery } from '@atlassian/jira-relay/src/__generated__/applicationPropertiesGetConfluencePropertiesQuery.graphql';
import {
	CONFLUENCE_CONTROLS_STORAGE_PROVIDER_KEY,
	CONFLUENCE_CONTROLS_STORAGE_KEY,
	ConfluenceFeatureKeys,
} from '../../constants.tsx';
import { clearSessionStorageData } from '../session-storage/index.tsx';
import type {
	ApplicationPropertiesMap,
	ApplicationPropertiesResponse,
	ConfluenceApplicationProperty,
} from './types.tsx';

const storage = createSessionStorageProvider(CONFLUENCE_CONTROLS_STORAGE_PROVIDER_KEY);

export const getConfluenceApplicationProperties = async (cloudId: string) => {
	try {
		const data = await fetchQuery<applicationPropertiesGetConfluencePropertiesQuery>(
			getRelayEnvironment(),
			graphql`
				query applicationPropertiesGetConfluencePropertiesQuery($cloudId: ID!, $keys: [String!]!) {
					jira {
						applicationPropertiesByKey(cloudId: $cloudId, keys: $keys) {
							key
							value
						}
					}
				}
			`,
			{
				cloudId,
				keys: Object.values(ConfluenceFeatureKeys),
			},
			{
				fetchPolicy: 'network-only',
			},
		).toPromise();

		const response = data?.jira?.applicationPropertiesByKey;

		if (!response) {
			throw new Error('GraphQL error fetching application properties');
		}

		return response.reduce((accumulator: ApplicationPropertiesMap, responseObject) => {
			const { key, value } = responseObject;
			accumulator[key] = { key, value: value === 'true' };
			return accumulator;
		}, {});
	} catch (e) {
		const error = e instanceof Error ? e : new Error('Failed to fetch application properties');
		fireErrorAnalytics({
			meta: {
				id: 'getConfluenceApplicationProperties',
				packageName: 'jiraConfluenceIntegrationControls',
			},
			error,
		});
		throw error;
	}
};

export const setConfluenceApplicationProperty = async (
	featureKey: string,
	value: string,
): Promise<ConfluenceApplicationProperty> => {
	const data = {
		id: featureKey,
		value,
	};

	try {
		const { id, value: updatedValue } =
			await setApplicationProperties<ApplicationPropertiesResponse>(
				'',
				featureKey,
				JSON.stringify(data),
			);
		// For admins who had previously cached values used in features
		clearSessionStorageData(CONFLUENCE_CONTROLS_STORAGE_KEY, storage);
		return {
			key: id,
			value: updatedValue === 'true',
		};
	} catch (e) {
		const error = e instanceof Error ? e : new Error('Failed to set application properties');
		fireErrorAnalytics({
			meta: {
				id: 'setConfluenceApplicationProperty',
				packageName: 'jiraConfluenceIntegrationControls',
			},
			error,
		});

		throw error;
	}
};
