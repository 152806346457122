import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	tooltipContent: {
		id: 'development-summary-pull-request.create-pull-request-summary-item.create-pr-icon-button.tooltip-content',
		defaultMessage: 'Create new pull request',
		description:
			"The create new pull request button tooltip content. 'Pull request' is a technical term for the tool Git, and is most often not translated.",
	},
});
