import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	title: {
		id: 'platform-react-hooks-use-ai-opt-in.ai-opt-in-premium-upsell-modal.title',
		defaultMessage: 'Write better, work faster with Atlassian Intelligence',
		description: 'Text shown as the title text in Jira Premium upsell modal',
	},
	body: {
		id: 'platform-react-hooks-use-ai-opt-in.ai-opt-in-premium-upsell-modal.body',
		defaultMessage:
			'Use AI to brainstorm, summarize, change tone, edit and more, with Jira Premium.',
		description: 'Text shown as body content in Jira Premium upsell modal',
	},
	closeModal: {
		id: 'platform-react-hooks-use-ai-opt-in.ai-opt-in-premium-upsell-modal.close-modal',
		defaultMessage: 'Close Modal',
		description: 'Text shown as label text for close button in Jira Premium upsell modal',
	},
	tryItFreeButton: {
		id: 'platform-react-hooks-use-ai-opt-in.ai-opt-in-premium-upsell-modal.try-it-free-button',
		defaultMessage: 'Try it free',
		description: 'Jira Premium',
	},
});
