import { createSelector } from 'reselect';
import isEqual from 'lodash/isEqual';
import noop from 'lodash/noop';
import type { ReactionsFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/reactions/types.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { Filter } from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import { isShallowEqual } from '@atlassian/jira-polaris-lib-equals/src/index.tsx';
import type { IssuesRemote } from '@atlassian/jira-polaris-remote-issue/src/controllers/types.tsx';
import type { PropertyMaps, State } from '../../../types.tsx';
import { nullSafeComparator, numberComparator } from '../../comparators/index.tsx';
import { CSV_SEPARATOR } from '../common/constanst.tsx';
import { createStringValueIntersectionFilter } from '../common/filter-utils.tsx';
import { removePropertyValue } from '../common/remove-utils.tsx';
import type { FieldMapping } from '../types.tsx';

export const reactionsMapping = (
	issuesRemote: IssuesRemote,
	field: Field,
): FieldMapping<ReactionsFieldValue[]> => {
	const valueAccessor: FieldMapping<ReactionsFieldValue[]>['valueAccessor'] = (
		state,
		props,
		issueId,
	) =>
		state.properties.reactions[field.key] !== undefined
			? state.properties.reactions[field.key][issueId]
			: undefined;

	const valueAccessorToExport: FieldMapping<string>['valueAccessorToExport'] = (
		state,
		props,
		issueId,
	) => {
		const reactions = valueAccessor(state, props, issueId)?.map(({ id }) => id) || [];
		return reactions.join(CSV_SEPARATOR);
	};

	return {
		field,
		setMutable: noop,
		// @ts-expect-error(PARTIAL_RECORD) TS2322 - Type '(maps: PropertyMaps, issueId: LocalIssueId, value?: IssueOptionProperty[]) => { multiSelect: { [x: string]: Record<string, IssueOptionProperty[]> | { [x: string]: IssueOptionProperty[] | undefined; }; }; ... 17 more ...; commentsMetadata: CommentsMetadataMap; }' is not assignable to type '(arg1: PropertyMaps, arg2: string, arg3: IssueOptionProperty[] | undefined) => PropertyMaps'.
		setImmutable: (maps: PropertyMaps, issueId: LocalIssueId, value?: ReactionsFieldValue[]) => {
			if (maps.reactions[field.key] && isEqual(maps.reactions[field.key][issueId], value)) {
				return maps;
			}
			return {
				...maps,
				reactions: {
					...maps.reactions,
					[field.key]: {
						...maps.reactions[field.key],
						[issueId]: value,
					},
				},
			};
		},
		remove: (maps: PropertyMaps, issueId: LocalIssueId) =>
			removePropertyValue(field.key, maps, issueId, 'reactions'),
		modifyImmutableIfMultiValueField: (
			maps: PropertyMaps,
			issueId: LocalIssueId,
			addValues?: ReactionsFieldValue[],
			removeValues: ReactionsFieldValue[] = [],
		) => {
			const oldValues = (maps.reactions[field.key] || {})[issueId] || [];
			const newValues = oldValues
				.filter(
					(oldValue) => removeValues.findIndex((removeValue) => oldValue.id === removeValue.id) < 0,
				)
				.concat(
					(addValues || []).filter(
						(newValue) => oldValues.findIndex((oldValue) => oldValue.id === newValue.id) < 0,
					),
				);
			if (isShallowEqual(newValues, oldValues)) {
				return maps;
			}
			return {
				...maps,
				reactions: {
					...maps.reactions,
					[field.key]: {
						...maps.reactions[field.key],
						[issueId]: newValues,
					},
				},
			};
		},
		comparator: nullSafeComparator<ReactionsFieldValue[]>((a, b, direction) =>
			numberComparator(
				a.reduce((sum, reaction) => sum + reaction.count, 0),
				b.reduce((sum, reaction) => sum + reaction.count, 0),
				direction,
			),
		),
		valueAccessor,
		valueAccessorToExport,
		getAllValues: (state) =>
			state.properties.reactions[field.key] ? state.properties.reactions[field.key] : {},
		getGroupIdentitiesSelector: (fieldKey, issueIdsSelector) =>
			createSelector(
				issueIdsSelector,
				(state: State) => state.properties.reactions[fieldKey],
				(ids, reactions) =>
					ids.reduce(
						(result, issueId) =>
							Object.assign(result, {
								[issueId]:
									reactions !== undefined && reactions[issueId] !== undefined
										? reactions[issueId].map((value) => ({
												groupIdentity: value.id,
												value: [value],
											}))
										: [],
							}),
						{},
					),
			),
		getGroupIdentities: (state, props, issueId) =>
			state.properties.reactions[field.key] !== undefined &&
			state.properties.reactions[field.key][issueId] !== undefined
				? state.properties.reactions[field.key][issueId].map((value) => ({
						groupIdentity: value.id,
						value: [value],
					}))
				: [],
		allowEmptyGroup: true,
		getValueFromJiraIssue: () => undefined,
		getLabel: () => undefined,
		getFilter: (filter: Filter) => {
			if (filter.type === 'FIELD' && filter.field === field.key) {
				const stringValueIntersectionFilter = createStringValueIntersectionFilter(filter);
				if (stringValueIntersectionFilter === undefined) {
					return undefined;
				}
				return (value: undefined | Array<ReactionsFieldValue>, state, props, localIssueId) =>
					stringValueIntersectionFilter(
						value?.map(({ id }) => id),
						state,
						props,
						localIssueId,
					);
			}
			return undefined;
		},
	};
};
