import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';

// ISSUE_RELATIONSHIP_UPDATE_SUCCESS
export const ISSUE_RELATIONSHIP_UPDATE_SUCCESS = 'ISSUE_RELATIONSHIP_UPDATE_SUCCESS' as const;

export const issueRelationshipUpdateSuccess = (
	oldParentIdsByIssueIds: {
		[key: string]: IssueId | null;
	},
	parentIssueId: IssueId | null = null,
) => ({
	type: ISSUE_RELATIONSHIP_UPDATE_SUCCESS,
	payload: {
		oldParentIdsByIssueIds,
		parentIssueId,
	},
});

export type IssueRelationshipUpdateSuccess = ReturnType<typeof issueRelationshipUpdateSuccess>;

export type IssueRelationshipAction = IssueRelationshipUpdateSuccess;
