import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	errorTitle: {
		id: 'issue-field-original-estimate.error-title',
		defaultMessage: "We couldn't set the original estimate value",
		description:
			'Error message informing the user that updating the original estimate field failed',
	},
	errorMessage: {
		id: 'issue-field-original-estimate.error-message',
		defaultMessage: 'You can try again in a few minutes, or refresh the page.',
		description: 'Error message when save fails, potentially due to network issues',
	},
});
