import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import type { DocNode } from '@atlaskit/adf-schema';
import { Inline, Stack, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { AIContextRenderer } from '../../ai-context-renderer/index.tsx';

export const ActionItem = ({
	summary,
	description,
	action,
	id,
	teamName = 'itsm-aiops-grogu',
}: ActionItemProps) => {
	return (
		<JSErrorBoundary
			id={`async-servicedesk-ai-context-action-item-${id || 'ops'}`}
			fallback="unmount"
			teamName={teamName}
		>
			<Inline spread="space-between" xcss={actionItemCss} role="listitem">
				<Stack xcss={actionDetailsCss}>
					<AIContextRenderer document={summary} />
					<ActionDescription>
						<AIContextRenderer document={description} />
					</ActionDescription>
				</Stack>
				{action}
			</Inline>
		</JSErrorBoundary>
	);
};

const actionItemCss = xcss({
	marginBlockEnd: 'space.100',
});

const actionDetailsCss = xcss({
	marginRight: 'space.200',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ActionDescription = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'.ak-renderer-document': {
		color: token('color.text.subtle'),
		font: token('font.body.small'),
	},
});

export type ActionItemProps = {
	summary: DocNode;
	description: DocNode;
	action: ReactNode;
	id?: string;
	teamName?: string;
};
