import { createSelector } from 'reselect';
import find from 'lodash/find';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { Props, State } from '../types.tsx';

const NO_FIELDS: Array<Field> = [];

const getFields = createSelector(
	(state: State, props: Props | undefined) => props?.fields,
	(fields?: Field[]) => fields || NO_FIELDS,
);

export const createGetFieldSelector = (condition: (field: Field) => boolean) =>
	createSelector(getFields, (fields) => find(fields, condition));

export const getSummaryField = createGetFieldSelector(({ type }) => type === FIELD_TYPES.SUMMARY);

export const getIssueIdField = createGetFieldSelector(({ type }) => type === FIELD_TYPES.ISSUE_ID);

export const getDescriptionField = createGetFieldSelector(
	({ type }) => type === FIELD_TYPES.DESCRIPTION,
);

export const getCreatorField = createGetFieldSelector(({ type }) => type === FIELD_TYPES.CREATOR);

export const getCreatedField = createGetFieldSelector(({ type }) => type === FIELD_TYPES.CREATED);
