const FAIL_LOAD_CAPTCHA_ERROR_MESSAGE = 'Failed to load grecaptcha enterprise';
export const RECAPTCHA_SCRIPT_URL = 'https://www.recaptcha.net/recaptcha/enterprise.js';
const RECAPTCHA_BADGE_SELECTOR = '.grecaptcha-badge';

export const loadRecaptcha = (siteKey: string) =>
	new Promise<void>((resolve, reject) => {
		if (isRecaptchaLoaded(siteKey)) {
			resolve();
			return;
		}

		const scriptElement = document.createElement('script');
		scriptElement.src = `${RECAPTCHA_SCRIPT_URL}?render=${siteKey}`;
		scriptElement.async = true;
		scriptElement.defer = true;

		scriptElement.id = siteKey;
		scriptElement.onload = () => {
			if (!window?.grecaptcha?.enterprise) {
				reject(FAIL_LOAD_CAPTCHA_ERROR_MESSAGE);
			} else {
				window.grecaptcha.enterprise.ready(resolve);
			}
		};

		scriptElement.onerror = (error) => {
			reject(
				typeof error === 'string'
					? error
					: (error as ErrorEvent).message ?? FAIL_LOAD_CAPTCHA_ERROR_MESSAGE,
			);
		};

		document.body.appendChild(scriptElement);
	});

const isRecaptchaLoaded = (siteKey: string) => Boolean(document.getElementById(siteKey));

export const unloadRecaptcha = (siteKey: string) => {
	removeRecaptchaScriptElement(siteKey);
	removeRecaptchaBadgeStyleElement(siteKey);
	removeRecaptchaBadge();
};

export const hideRecaptchaBadge = (siteKey: string) => {
	const id = `${siteKey}-styles`;
	if (document.getElementById(id)) {
		return;
	}

	const styleElement = document.createElement('style');
	styleElement.id = `${siteKey}-styles`;
	styleElement.innerText = `${RECAPTCHA_BADGE_SELECTOR} { visibility: hidden; }`;
	document.head.appendChild(styleElement);
};

const removeRecaptchaScriptElement = (siteKey: string) => {
	const scriptElement = document.getElementById(siteKey);
	if (scriptElement) {
		scriptElement.remove();
	}
};

const removeRecaptchaBadgeStyleElement = (siteKey: string) => {
	const styleElement = document.getElementById(`${siteKey}-styles`);
	if (styleElement) {
		styleElement.remove();
	}
};

const removeRecaptchaBadge = () => {
	const badgeElements = document.querySelectorAll(RECAPTCHA_BADGE_SELECTOR);
	// @ts-ignore "TS2488: Type 'NodeListOf<Element>' must have a '[Symbol.iterator]()' method that returns an iterator." occurs within Jira due to missing DOM.Iterable. We've dropped IE support so we shouldn't need it.
	for (const el of badgeElements) {
		el.remove();
	}
};

export class RecaptchaError extends Error {
	constructor(message: string) {
		super(message);

		// fixing the `instanceof` operators, see https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
		Object.setPrototypeOf(this, RecaptchaError.prototype);
	}
}
