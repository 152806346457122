import head from 'lodash/head';
import type { LocalDecorationId } from '@atlassian/jira-polaris-domain-field/src/decoration/types.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types.tsx';
import { performJiraConfigurationUpdate } from './utils.tsx';

type deleteAllValueDecorationsProps = {
	fieldKey: FieldKey;
};

export const deleteValueDecoration =
	(fieldKey: FieldKey, localDecorationId: LocalDecorationId) =>
	async (
		{ getState, setState }: StoreActionApi<State>,
		{ onFieldUpdateFailed, fieldRemote, createAnalyticsEvent, issueTypeIds }: Props,
	): Promise<void> => {
		try {
			const state = getState();
			const { fieldValueDecorations, fields } = state;
			const field = fields[fieldKey];
			const existingValueDecorations = fieldValueDecorations[fieldKey] || [];
			const newValueDecorations = [...existingValueDecorations];
			const issueTypeId = head(issueTypeIds);

			const decorationIdx = newValueDecorations.findIndex(
				({ localDecorationId: id }) => localDecorationId === id,
			);
			newValueDecorations.splice(decorationIdx, 1);

			setState({
				fieldValueDecorations: {
					...fieldValueDecorations,
					[fieldKey]: newValueDecorations,
				},
			});

			await performJiraConfigurationUpdate({
				fieldKey,
				fieldType: field.type,
				issueTypeId,
				fieldRemote,
				existingDecorations: existingValueDecorations,
				newDecorations: newValueDecorations,
			});

			fireCompoundAnalyticsEvent.FieldConfig.valueDecorationChanged(createAnalyticsEvent({}), {
				issueFieldKey: fieldKey,
				issueFieldType: field.type,
			});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			onFieldUpdateFailed(e);
		}
		return undefined;
	};

export const deleteAllValueDecorations =
	({ fieldKey }: deleteAllValueDecorationsProps) =>
	async (
		{ getState, setState }: StoreActionApi<State>,
		{ onFieldUpdateFailed, fieldRemote, createAnalyticsEvent, issueTypeIds }: Props,
	): Promise<void> => {
		try {
			const state = getState();
			const { fieldValueDecorations, fields } = state;
			const field = fields[fieldKey];
			const existingValueDecorations = fieldValueDecorations[fieldKey] || [];
			const issueTypeId = head(issueTypeIds);

			setState({
				fieldValueDecorations: {
					...fieldValueDecorations,
					[fieldKey]: [],
				},
			});

			await performJiraConfigurationUpdate({
				fieldKey,
				fieldType: field.type,
				issueTypeId,
				fieldRemote,
				existingDecorations: existingValueDecorations,
				newDecorations: [],
			});

			fireCompoundAnalyticsEvent.FieldConfig.valueDecorationChanged(createAnalyticsEvent({}), {
				issueFieldKey: fieldKey,
				issueFieldType: field.type,
			});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			onFieldUpdateFailed(e);
		}
		return undefined;
	};
