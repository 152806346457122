import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/retry';
import 'rxjs/add/observable/fromPromise';
import { type ActionsObservable, combineEpics } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import type { AnalyticsEvent } from '@atlassian/jira-common-analytics-v2-wrapped-components/src/types.tsx';
import logger from '@atlassian/jira-common-util-logging/src/log.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import {
	type SaveLinkedIssueSuccess,
	SAVE_LINKED_ISSUE_SUCCESS,
} from '@atlassian/jira-issue-view-store/src/actions/issue-links-actions.tsx';
import {
	linkedIssueProjectAnalyticsSuccess,
	linkedIssueProjectAnalyticsFailure,
} from '@atlassian/jira-issue-view-store/src/actions/issue-links-analytics-actions.tsx';
import { baseUrlSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';

export const ERROR_LOCATION = 'issue.issue-view.get-create-linked-issue-permission.epic';

// FIXME: memory leak in a global variable. Consider to _weakmap_ it
const projectKeyToTypeMap: { [key: string]: Promise<string> } = {};

const getProject = (
	state: State,
	projectKey: string,
	requestAnalyticsEvent: AnalyticsEvent | null,
) => {
	const baseUrl = baseUrlSelector(state);

	if (requestAnalyticsEvent == null) {
		logger.safeErrorWithoutCustomerData(ERROR_LOCATION, 'No analytics event provided');
		return Observable.of(linkedIssueProjectAnalyticsFailure('No analytics event provided'));
	}
	if (projectKeyToTypeMap[projectKey] === undefined) {
		projectKeyToTypeMap[projectKey] = performGetRequest(
			`${baseUrl}/rest/api/2/project/${projectKey}`,
			{
				method: 'GET',
			},
		) // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			.then((body): string => body.projectTypeKey as string);
	}
	return Observable.fromPromise(
		projectKeyToTypeMap[projectKey]
			.then((projectType) => {
				if (projectType) {
					requestAnalyticsEvent.update({ linkedProjectType: projectType });
					return linkedIssueProjectAnalyticsSuccess(requestAnalyticsEvent);
				}
				logger.safeErrorWithoutCustomerData(
					ERROR_LOCATION,
					'Issue linked did not contain any project type key',
				);
				return linkedIssueProjectAnalyticsFailure(
					'Issue linked did not contain any project type key',
				);
			})
			.catch((error: Error) => {
				logger.safeErrorWithoutCustomerData(ERROR_LOCATION, error.message);
				return linkedIssueProjectAnalyticsFailure(error.message);
			}),
	);
};

const asyncIssueLinkAnalyticsEpic = (
	action$: ActionsObservable<SaveLinkedIssueSuccess>,
	store: MiddlewareAPI<State>,
) =>
	action$.ofType(SAVE_LINKED_ISSUE_SUCCESS).mergeMap((action) => {
		if (
			ff('corex-operandi-issue-view-additional-logging_l6hj8') ||
			fg('operandi_issue_view_additional_logging')
		) {
			const state = store.getState();
			const { issueKey, postAnalyticsEvent } = action.payload;
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			const projectKey = issueKey!.split('-')[0];
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			return getProject(state, projectKey, postAnalyticsEvent!);
		}
		return Observable.of({});
	});

export default combineEpics(asyncIssueLinkAnalyticsEpic);
