import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	/**
	 * @deprecated use `attached` instead
	 * This entry will be removed when cleaning up this gate:
	 * issue_view_activity_timeline
	 */
	uploaded: {
		id: 'issue-history.history-items.history-item.attachment-history-item.uploaded',
		defaultMessage: 'added an {attachment}',
		description: `Describes that a user has added a new attachment to the issue.
            Part of the heading in History feed, appears after the display name of the user who made the change.
            {attachment} is the 'Attachment' field label.`,
	},
	attached: {
		id: 'issue-history.history-items.history-item.attachment-history-item.attached',
		defaultMessage: 'attached {filename}',
		description: `Describes that a user has attached an attachment to the issue.
			Part of the heading in History feed, appears after the display name of the user who made the change.
			{filename} is the name of the attachment file.`,
	},
	deleted: {
		id: 'issue-history.history-items.history-item.attachment-history-item.deleted',
		defaultMessage: 'deleted {filename}',
		description: `Describes that a user has removed an attachment from the issue.
			Part of the heading in History feed, appears after the display name of the user who made the change.
			{filename} is the name of the attachment file.`,
	},
	/**
	 * @deprecated use `deleted` instead
	 * This entry will be removed when cleaning up this gate:
	 * issue_view_activity_timeline
	 */
	deletedDeprecated: {
		id: 'issue-history.history-items.history-item.attachment-history-item.deleted-deprecated',
		defaultMessage: 'deleted an {attachment}',
		description: `Describes that a user has removed an attachment from the issue.
            Part of the heading in History feed, appears after the display name of the user who made the change.
            {attachment} is the 'Attachment' field label.`,
	},
	/**
	 * This entry will be removed when cleaning up this gate:
	 * issue_view_activity_timeline
	 */
	attachment: {
		id: 'issue-history.history-items.history-item.attachment-history-item.attachment',
		defaultMessage: 'Attachment',
		description: "(noun) Value to display as label for the 'Attachment' field",
	},
	/**
	 * This entry will be removed when cleaning up this gate:
	 * issue_view_activity_timeline
	 */
	attachmentLowercase: {
		id: 'issue-history.history-items.history-item.attachment-history-item.attachment-lowercase',
		defaultMessage: 'attachment',
		description: "(noun) Value to display as label for the 'attachment' field",
	},
});
