import { createSelector } from 'reselect';
import { fg } from '@atlassian/jira-feature-gating';
import type { Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import { FIELD_SORT, VIEW_RANK } from '@atlassian/jira-polaris-domain-view/src/sort/constants.tsx';
import { findView } from '../../../actions/utils/views/index.tsx';
import type { Props, State } from '../../../types.tsx';
import { getSortMode } from '../../../utils/index.tsx';
import { getFields } from '../../fields.tsx';
import { getCurrentViewDraft, isCurrentViewAutosaveEnabled } from '../autosave/index.tsx';
import { getCurrentView } from '../current/index.tsx';

export const getCurrentViewSortMode = createSelector(
	getFields,
	getCurrentView,
	getCurrentViewDraft,
	isCurrentViewAutosaveEnabled,
	(fields, view, draft, isAutosaveEnabled) => {
		if (fg('polaris_just-for-you')) {
			const sortMode = view !== undefined ? getSortMode(view, fields) : undefined;

			return !view?.temporaryData?.sortBy?.length ? sortMode : FIELD_SORT;
		}

		const config = isAutosaveEnabled ? view : draft;

		return config !== undefined ? getSortMode(config, fields) : undefined;
	},
);

const getViewByViewId = (state: State, viewAri: Ari) =>
	findView(state.viewSets, ({ viewId }) => viewId === viewAri);

export const isViewInViewRankSortMode = (state: State, viewAri: Ari): boolean => {
	const view = getViewByViewId(state, viewAri);

	if (fg('polaris_just-for-you')) {
		return view?.sortMode === VIEW_RANK && !view?.temporaryData?.sortBy?.length;
	}

	return (view?.isAutosaveEnabled === false ? view.draft : view)?.sortMode === VIEW_RANK;
};

export const isCurrentViewSortModeViewRank = (state: State, props?: Props): boolean =>
	getCurrentViewSortMode(state, props) === VIEW_RANK;

export const getCurrentViewIssueRanking = createSelector(
	getCurrentView,
	(view) => view?.issueRanking,
);

export const isManualIdeaRankingPossibleInCurrentView = createSelector(
	getCurrentView,
	getCurrentViewDraft,
	isCurrentViewAutosaveEnabled,
	(view, draft, isAutosaveEnabled) => {
		if (fg('polaris_just-for-you')) {
			view?.sortMode !== FIELD_SORT && !view?.temporaryData?.sortBy?.length;
		}

		return (isAutosaveEnabled ? view : draft)?.sortMode !== FIELD_SORT;
	},
);
