import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';

type CommentAfterContentProps = {
	isHighlighted: boolean;
};

const CommentAfterContent = ({ isHighlighted }: CommentAfterContentProps) => {
	// eslint-disable-next-line jira/react/no-style-attribute, @atlaskit/ui-styling-standard/enforce-style-prop
	return <Box style={{ marginTop: '-38px' }}>{isHighlighted && <Box xcss={styles} />}</Box>;
};

export default CommentAfterContent;

const styles = xcss({
	position: 'absolute',
	backgroundColor: 'color.background.neutral',
	pointerEvents: 'none',
	bottom: 'space.negative.100',
	right: 'space.negative.100',
	left: 'space.negative.100',
	height: 'size.300',
});
