import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	feedbackSentLozenge: {
		id: 'atlassian-intelligence.common.feedback-form.feedback-sent-lozenge',
		defaultMessage: 'Feedback Sent',
		description: 'Text in feedback sent lozenge for feedback form.',
	},
	feedbackIconLabel: {
		id: 'atlassian-intelligence.common.feedback-form.feedback-icon-label',
		defaultMessage: 'Feedback Icon',
		description: 'Feedback icon label in the feedback form.',
	},
	tooltipLike: {
		id: 'atlassian-intelligence.common.feedback-form.tooltip-like',
		defaultMessage: 'Good response',
		description: 'Tooltip content for the like button in the feedback form.',
	},
	tooltipDislike: {
		id: 'atlassian-intelligence.common.feedback-form.tooltip-dislike',
		defaultMessage: 'Bad response',
		description: 'Tooltip content for the dislike button in the feedback form.',
	},
});
