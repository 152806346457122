import React, { useMemo } from 'react';
import { styled } from '@compiled/react';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { useForgeUiIssueData } from '@atlassian/jira-forge-controllers-extension-context/src/index.tsx';
import { withMetrics } from '@atlassian/jira-forge-ui-analytics/src/common/ui/index.tsx';
import {
	CONTEXT_TOKEN_TYPE_JIRA,
	ISSUE_CONTEXT_MODULE,
} from '@atlassian/jira-forge-ui-constants/src/constants.tsx';
import type { IssueContextExtensionData } from '@atlassian/jira-forge-ui-types/src/common/types/extension-data.tsx';
import {
	isCustomUIExtension,
	isNativeUiExtension,
} from '@atlassian/jira-forge-ui-utils-internal/src/utils/extension/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import { LazyIssueViewForgeExtension } from '@atlassian/jira-forge-issue-view-base/src/ui/async/index.tsx';
import type { LazyComponentProps } from '../../types.tsx';

// eslint-disable-next-line jira/styled/styled-component-order,  @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledLoading = styled.div({
	width: '100%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	marginBlock: token('space.300', '24px'),
});

const loadingComponent = (
	<StyledLoading>
		<Spinner size={5 * gridSize} />
	</StyledLoading>
);

export const LazyIssueContextRenderer = withMetrics(
	// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
	lazyForPaint(
		() =>
			import(
				/* webpackChunkName: "async-forge-ui-context-panel" */ '@atlassian/jira-forge-ui/src/ui/components/issue-context'
			),
		{
			ssr: false,
		},
	),
);

export const AsyncIssueContext = (props: LazyComponentProps<typeof LazyIssueContextRenderer>) => {
	const { extension } = props;

	const forgeUiIssueData = useForgeUiIssueData();
	const extensionData: IssueContextExtensionData | null = useMemo(
		() => (forgeUiIssueData && { ...forgeUiIssueData, type: ISSUE_CONTEXT_MODULE }) || null,
		[forgeUiIssueData],
	);
	const LoadingComponent = useMemo(() => <>{props.loadingComponent}</>, [props.loadingComponent]);
	const extraProps = useMemo(
		() =>
			(extensionData && {
				loadingComponent: LoadingComponent,
				module: ISSUE_CONTEXT_MODULE,
				extensionData,
				contextToken: CONTEXT_TOKEN_TYPE_JIRA,
			}) ||
			null,
		[LoadingComponent, extensionData],
	);

	if (!extensionData || !extraProps) {
		return LoadingComponent;
	}

	if (isNativeUiExtension(extension) || isCustomUIExtension(extension)) {
		return (
			<Placeholder name="issue-context" fallback={loadingComponent}>
				<LazyIssueViewForgeExtension {...props} {...extraProps} />
			</Placeholder>
		);
	}

	return (
		<Placeholder name="issue-context" fallback={loadingComponent}>
			<LazyIssueContextRenderer {...props} {...extraProps} />
		</Placeholder>
	);
};
