import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	shareTriggerButtonText: {
		id: 'share-button.pre-share-view.share-trigger-button-text',
		defaultMessage: 'Share',
		description: 'Default text for the disabled share dialog trigger button',
	},
	errorMessage: {
		id: 'share-button.pre-share-view.error-message',
		defaultMessage: 'Unable to fetch sharing details',
		description: 'Error message if API returns an error',
	},
});
