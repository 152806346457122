import { useCallback, useEffect, useMemo } from 'react';
import debounce from 'lodash/debounce';
import once from 'lodash/once';
import {
	useQueryLoader,
	type PreloadableConcreteRequest,
	type PreloadedQuery,
	type UseQueryLoaderLoadQueryOptions,
} from 'react-relay';
import type { GraphQLTaggedNode, OperationType } from 'relay-runtime';

type UseQueryLoaderOnIntentReturnType<T extends OperationType> = [
	PreloadedQuery<T> | null | undefined,
	(variables: T['variables'], options?: UseQueryLoaderLoadQueryOptions) => void,
	() => void,
];

const defaultTimeout = 250;

export function useQueryLoaderOnIntent<T extends OperationType>(
	request: GraphQLTaggedNode | PreloadableConcreteRequest<T>,
	timeout = defaultTimeout,
): UseQueryLoaderOnIntentReturnType<T> {
	const [ref, fetch] = useQueryLoader(request);
	const trigger = useMemo(() => {
		const onceFetch = once(fetch);
		return debounce(onceFetch, timeout, {
			trailing: true,
		});
	}, [fetch, timeout]);
	const abort = useCallback(() => trigger.cancel(), [trigger]);

	// cancel the fetch if the component is unmounted before debounce is triggered
	useEffect(() => () => trigger.cancel(), [trigger]);

	return [ref, trigger, abort];
}
