import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	errorTitle: {
		id: 'issue-field-date-time-inline-edit-full.date-time.error-title',
		defaultMessage: "We couldn't set the date",
		description: 'Error message informing the user that updating the field failed',
	},
	errorMessage: {
		id: 'issue-field-date-time-inline-edit-full.date-time.error-message',
		defaultMessage: 'Check your connection, then give it another try.',
		description: 'Error message when save fails',
	},
});
