import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';

export const localStorage = createLocalStorageProvider('ai-upsell-in-editor-free');

export function getUpsellButtonWrapperClicked(): boolean {
	return Boolean(localStorage.get('upsellButtonWrapperClicked') ?? false);
}

export function setUpsellButtonWrapperClicked(value: boolean) {
	localStorage.set('upsellButtonWrapperClicked', value);
}
