import React, { useCallback } from 'react';
import { token } from '@atlaskit/tokens';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import { Box, Inline, xcss } from '@atlaskit/primitives';
import { useActionsCommandPalette } from '@atlassian/jira-command-palette/src/controllers/command-palette/index.tsx';
import { MODAL_DIALOG_TEST_ID } from '@atlassian/jira-command-palette/src/ui/modal/constants.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { messages } from './messages.tsx';
import { PulseWrapper } from './pulse-wrapper/index.tsx';

type Props = {
	onClick: () => void;
};

// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
export const isMacOs = () => /Mac/i.test(window?.navigator?.platform);

const KeyWrapper = ({ children }: { children: React.ReactNode }) => (
	<Box
		backgroundColor="color.background.neutral"
		paddingInline="space.050"
		paddingBlock="space.025"
		xcss={wrapperStyles}
	>
		{children}
	</Box>
);

export const TriggerButton = ({ onClick }: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { open } = useActionsCommandPalette();

	const onClickFn = useCallback(() => {
		onClick();
		open();
		const analyticsEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'dropdownItem',
		});
		fireUIAnalytics(analyticsEvent, 'compalDialog');
	}, [createAnalyticsEvent, onClick, open]);

	return (
		<PulseWrapper>
			<DropdownItem
				key="compalDialog"
				onClick={onClickFn}
				elemAfter={
					<Inline space="space.025">
						<KeyWrapper>{isMacOs() ? '⌘' : 'Ctrl'}</KeyWrapper>
						<KeyWrapper>K</KeyWrapper>
					</Inline>
				}
				aria-haspopup="dialog"
				aria-controls={MODAL_DIALOG_TEST_ID}
			>
				{formatMessage(messages.buttonText)}
			</DropdownItem>
		</PulseWrapper>
	);
};

const wrapperStyles = xcss({
	borderRadius: '3px',
	fontWeight: token('font.weight.bold'),
});
