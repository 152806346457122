import { useState, useEffect } from 'react';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import {
	useAnalyticsEvents,
	fireOperationalAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { getEscalatorBaseUrl } from '@atlassian/jira-servicedesk-incident-management-common/src/utils.tsx';
import type { CloudId } from '@atlassian/jira-shared-types/src/general.tsx';

type PermissionResponse = {
	customerHasConfluenceLicense: boolean;
	userHasConfluenceLicense: boolean;
	userIsSiteAdmin: boolean;
};

export const useConfluencePermission = (
	cloudId: CloudId,
): {
	result: PermissionResponse;
	error?: Error;
	loading: boolean;
} => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const analyticsEvent = createAnalyticsEvent({
		action: 'fetch',
		actionSubject: 'useConfluencePermission',
	});

	const [permission, setPermission] = useState<PermissionResponse>({
		customerHasConfluenceLicense: false,
		userHasConfluenceLicense: false,
		userIsSiteAdmin: false,
	});
	const [error, setError] = useState<Error | undefined>(undefined);
	const [loading, setLoading] = useState<boolean>(true);

	const permissionUrl = `${getEscalatorBaseUrl(cloudId)}/confluence/permission`;

	useEffect(() => {
		setLoading(true);
		setError(undefined);

		fetchJson(permissionUrl)
			.then((permissionDetail: PermissionResponse) => {
				setPermission(permissionDetail);
				fireOperationalAnalytics(analyticsEvent, 'useConfluencePermission succeeded');
			})
			.catch((err: Error) => {
				setError(err);
				fireErrorAnalytics({
					meta: {
						id: 'useConfluencePermission',

						packageName: 'jiraServicedeskPostIncidentReview',
						teamName: 'itsm-agni',
					},
					sendToPrivacyUnsafeSplunk: true,
					error: err,
				});
			})
			.finally(() => setLoading(false));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [permissionUrl]);

	return { result: permission, error, loading };
};
