import React from 'react';
import { styled } from '@compiled/react';
import LikeIcon from '@atlaskit/icon/glyph/like';
import { colors } from '@atlaskit/theme';
import { B400 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import TransitiveNumber from '@atlassian/react-transitive-number';
import type { Props } from './types.tsx';

const VoteIcon = ({ isSelected = false, hasVoted, votes }: Props) => {
	return (
		<IconWrapper hasVoted={hasVoted} isSelected={isSelected}>
			<ButtonIcon>
				{hasVoted ? (
					<LikeIcon label="" size="medium" primaryColor={token('color.icon.selected', B400)} />
				) : (
					<LikeIcon label="" size="medium" />
				)}
			</ButtonIcon>
			<visibleForTesting.Counter
				data-testid="issue-field-voters.ui.icon.counter"
				hasVoters={votes !== 0}
			>
				<TransitiveNumber>{votes !== 0 ? votes : ''}</TransitiveNumber>
			</visibleForTesting.Counter>
		</IconWrapper>
	);
};

export default VoteIcon;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const IconWrapper = styled.span<{ hasVoted: boolean; isSelected: boolean }>(
	{
		display: 'flex',
		alignItems: 'center',
	},
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props: any) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		props.hasVoted && !props.isSelected ? `color: ${token('color.icon.brand', colors.B500)};` : '',
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonIcon = styled.span({
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-dynamic-styles
const Counter = styled.span<{ hasVoters?: boolean }>((props) => ({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	minWidth: props.hasVoters ? '15px' : '0',
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const visibleForTesting = {
	Counter,
};
