/* eslint-disable @atlassian/relay/must-colocate-fragment-spreads */
import React from 'react';
import { graphql, useFragment } from 'react-relay';
import Placeholder from '@atlaskit/react-ufo/placeholder';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import type { LoggedInUser } from '@atlassian/jira-issue-view-common-types/src/user-type.tsx';
import { useIsTempoInstalled } from '@atlassian/jira-issue-view-ecosystem-service/src/services/main.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { showWatchersFlag } from '@atlassian/jira-issue-view-store/src/actions/watchers-actions.tsx';
import { issueKeySelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import {
	isSimplifiedProjectSelector,
	loggedInUserDetailsSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';
import { watchersPermissionsSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/permissions-selector.tsx';
import {
	isUserWatchingSelector,
	watchersCountSelector,
} from '@atlassian/jira-issue-view-store/src/issue-field/state/selectors/watch-field-selector.tsx';
import type { src_issueViewWatchers_WatchersAppWithRelay$key } from '@atlassian/jira-relay/src/__generated__/src_issueViewWatchers_WatchersAppWithRelay.graphql';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { useLocale } from '@atlassian/jira-tenant-context-controller/src/components/locale/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import WatchersApp from './watchers/index.tsx';
import type { OnShowFlag } from './watchers/model/types.tsx';

type ExternalProps = {
	watches: src_issueViewWatchers_WatchersAppWithRelay$key;
};

type StateProps = {
	issueKey: IssueKey;
	isWatching: boolean;
	isProjectSimplified: boolean;
	loggedInUserDetails: LoggedInUser;
	watchersCount: number;
	permissions: {
		canViewWatchers: boolean;
		canManageWatchers: boolean;
	};
};

type DispatchProps = {
	onShowFlag: OnShowFlag;
};

type Props = StateProps & DispatchProps;

// New
const WatchersAppWithRelay = ({ watches, onShowFlag, ...props }: ExternalProps & Props) => {
	const data = useFragment<src_issueViewWatchers_WatchersAppWithRelay$key>(
		graphql`
			fragment src_issueViewWatchers_WatchersAppWithRelay on JiraWatchesField {
				watch {
					isWatching
					count
				}
				...view_issueViewWatchers_ActionIcon
				...keyboardShortcuts_issueViewWatchers_KeyboardShortcutsRelay
			}
		`,
		watches,
	);

	const isTempoInstalled = useIsTempoInstalled();
	const locale = useLocale();
	const accountId = useAccountId();

	return (
		<UFOSegment name="issue-view-watcher">
			<JSErrorBoundary
				id="issue.issue-view.watcher"
				packageName="jiraIssueViewWatchers"
				teamName="bento"
				fallback="unmount"
			>
				<Placeholder name="issue.issue-view.watcher">
					<WatchersApp
						{...props}
						locale={locale}
						accountId={accountId}
						onShowFlag={onShowFlag}
						isKeyboardShortcutEnabled={!isTempoInstalled}
						isWatching={data?.watch?.isWatching || false}
						watchersCount={data?.watch?.count || 0}
						watches={data}
					/>
				</Placeholder>
			</JSErrorBoundary>
		</UFOSegment>
	);
};

const WatchersAppWithHook = ({ onShowFlag, ...props }: Props) => {
	const isTempoInstalled = useIsTempoInstalled();
	const locale = useLocale();
	const accountId = useAccountId();

	return (
		<UFOSegment name="issue-view-watcher">
			<JSErrorBoundary
				id="issue.issue-view.watcher"
				packageName="jiraIssueViewWatchers"
				teamName="bento"
				fallback="unmount"
			>
				<WatchersApp
					{...props}
					locale={locale}
					accountId={accountId}
					onShowFlag={onShowFlag}
					isKeyboardShortcutEnabled={!isTempoInstalled}
				/>
			</JSErrorBoundary>
		</UFOSegment>
	);
};

const WatchersAppInner = componentWithCondition(
	() => fg('relay-migration-issue-header-and-parent'),
	WatchersAppWithRelay,
	WatchersAppWithHook,
);

/**
 * Connects the Watchers app.
 *
 * Watchers related data (isWatching, watchersCount, ...) is present in the Issue store, so it's being passed here
 * to initialize the app.
 *
 * If that data changes (as a result of a issue refresh, for example), or if the issueKey itself changes, the app
 * will be re-rendered with new initial data.
 *
 */
const Watchers: (props: ExternalProps) => React.ReactNode = connect(
	(state: State): StateProps => ({
		loggedInUserDetails: loggedInUserDetailsSelector(state),
		issueKey: issueKeySelector(state),
		isWatching: isUserWatchingSelector(state),
		watchersCount: watchersCountSelector(state),
		permissions: watchersPermissionsSelector(state),
		isProjectSimplified: isSimplifiedProjectSelector(state),
	}),
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	{
		onShowFlag: showWatchersFlag,
	} as DispatchProps,
)(WatchersAppInner);

export default Watchers;
