import { createSelector } from 'reselect';
import { fieldByCustomTypeSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector.tsx';
import { REQUEST_FEEDBACK_DATE_CF_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';

export const feedbackDateFieldSelector = fieldByCustomTypeSelector(REQUEST_FEEDBACK_DATE_CF_TYPE);

export const feedbackDateSelector = createSelector(
	feedbackDateFieldSelector,
	(field) => field && field.value,
);
