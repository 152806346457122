import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	portal: {
		id: 'servicedesk-request-details-panel-services.services.fetch-panel-metadata.portal',
		defaultMessage: 'Portal',
		description: 'Portal Channel name to be displayed in description section',
	},
	email: {
		id: 'servicedesk-request-details-panel-services.services.fetch-panel-metadata.email',
		defaultMessage: 'Email',
		description: 'Email Channel name to be displayed in description section',
	},
	api: {
		id: 'servicedesk-request-details-panel-services.services.fetch-panel-metadata.api',
		defaultMessage: 'API',
		description: 'API Channel name to be displayed in description section',
	},
	jira: {
		id: 'servicedesk-request-details-panel-services.services.fetch-panel-metadata.jira',
		defaultMessage: 'Jira',
		description: 'Jira Channel name to be displayed in description section',
	},
	deployment: {
		id: 'servicedesk-request-details-panel-services.services.fetch-panel-metadata.deployment',
		defaultMessage: 'Deployment',
		description: 'Jira Channel name to be displayed in description section',
	},
	anonymousportal: {
		id: 'servicedesk-request-details-panel-services.services.fetch-panel-metadata.anonymousportal',
		defaultMessage: 'Anonymous Portal',
		description: 'Anonymous Portal Channel name to be displayed in description section',
	},
	unknown: {
		id: 'servicedesk-request-details-panel-services.services.fetch-panel-metadata.unknown',
		defaultMessage: 'Unknown',
		description: 'Unknown will be displayed when no channel source is available',
	},
});
