import { combineReducers } from 'redux';
import type { WorklogUiState } from '@atlassian/jira-issue-view-common-types/src/worklog-type.tsx';
import highlightedWorklogReducer, {
	initialHighlightedWorklogId,
} from './highlighted-worklog/index.tsx';
import loadingStageReducer, { initialLoadingStage } from './loading-stage/index.tsx';
import modalReducer, { initialModalState } from './modal/index.tsx';
import permalinkStatusReducer, { initialPermalinkStatusState } from './permalink-status/index.tsx';

export const initialUiState: WorklogUiState = {
	loadingStage: initialLoadingStage,
	modal: initialModalState,
	highlightedWorklogId: initialHighlightedWorklogId,
	permalinkStatus: initialPermalinkStatusState,
};

export default combineReducers({
	loadingStage: loadingStageReducer,
	modal: modalReducer,
	highlightedWorklogId: highlightedWorklogReducer,
	permalinkStatus: permalinkStatusReducer,
});
