import React, { useCallback } from 'react';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import { useIntl } from '@atlassian/jira-intl';
import { useVoteField } from '@atlassian/jira-issue-field-voters/src/services/vote-field-service/index.tsx';
import messages from './messages.tsx';

export const VoteButton = () => {
	const { formatMessage } = useIntl();

	const [{ value }, { toggleValue }] = useVoteField({});

	const handleClick = useCallback(() => {
		toggleValue();
	}, [toggleValue]);

	return (
		<DropdownItem key="vote" onClick={handleClick}>
			{value.hasVoted ? formatMessage(messages.removeVote) : formatMessage(messages.addVote)}
		</DropdownItem>
	);
};
