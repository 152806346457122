import React from 'react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';

const unknownColor = token('color.icon.success', colors.G300);
const backgroundColor = token('color.icon.inverse', colors.N0);

const UnknownOld = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="100%"
		height="100%"
		fill="none"
		viewBox="0 0 16 16"
	>
		<circle r="7" cx="8" cy="8" fill={backgroundColor} />

		<mask id="path-1-inside-1" fill={backgroundColor}>
			<path
				fillRule="evenodd"
				d="M15.367 4.898a8.039 8.039 0 00-1.703-2.546A8.118 8.118 0 0011.11.641 7.777 7.777 0 008 0a7.784 7.784 0 00-3.101.633 8.032 8.032 0 00-2.547 1.703A8.11 8.11 0 00.641 4.891 7.748 7.748 0 000 8c0 1.083.211 2.117.633 3.102a8.022 8.022 0 001.703 2.546 8.1 8.1 0 002.555 1.711A7.762 7.762 0 008 16a7.796 7.796 0 003.102-.633 8.055 8.055 0 002.547-1.703 8.15 8.15 0 001.711-2.555C15.786 10.12 16 9.083 16 8a7.796 7.796 0 00-.633-3.102z"
				clipRule="evenodd"
			/>
		</mask>
		<path
			fill={unknownColor}
			d="M11.11.641l.793-1.836H11.9L11.11.64zM4.899.633l.788 1.838L4.9.633zM.641 4.891l1.836.794v-.002L.641 4.891zm-.008 6.211l-1.838.788 1.838-.788zm1.703 2.546l1.415-1.413-.002-.002-1.413 1.415zm2.555 1.711l.793-1.836-.793 1.836zm6.211.008l.788 1.838-.788-1.838zm2.547-1.703l1.413 1.415.002-.002-1.415-1.413zm1.711-2.555l1.836.794V11.9l-1.836-.791zm1.845-7A10.037 10.037 0 0015.078.939L12.25 3.766a6.041 6.041 0 011.279 1.92l3.676-1.576zM15.078.939a10.116 10.116 0 00-3.175-2.133l-1.586 3.672a6.12 6.12 0 011.933 1.29l2.828-2.83zm-3.177-2.134A9.777 9.777 0 008-2v4c.811 0 1.578.159 2.319.478L11.9-1.196zM8-2a9.784 9.784 0 00-3.89.795L5.688 2.47A5.784 5.784 0 018 2v-4zm-3.889.795A10.031 10.031 0 00.938.922L3.766 3.75a6.034 6.034 0 011.92-1.279L4.112-1.205zM.938.922a10.11 10.11 0 00-2.133 3.177l3.672 1.584a6.113 6.113 0 011.29-1.933L.936.922zm-2.132 3.175A9.748 9.748 0 00-2 8h4c0-.813.158-1.58.476-2.315l-3.67-1.588zM-2 8c0 1.35.265 2.653.795 3.89l3.676-1.576A5.796 5.796 0 012 8h-4zm.795 3.89a10.021 10.021 0 002.128 3.173l2.826-2.83a6.024 6.024 0 01-1.278-1.92l-3.676 1.577zM.92 15.061a10.1 10.1 0 003.178 2.134l1.584-3.672a6.1 6.1 0 01-1.932-1.288L.921 15.06zm3.177 2.134A9.763 9.763 0 008 18v-4a5.76 5.76 0 01-2.316-.477l-1.586 3.672zM8 18c1.35 0 2.653-.265 3.89-.795l-1.576-3.676C9.581 13.843 8.815 14 8 14v4zm3.89-.795a10.054 10.054 0 003.172-2.126l-2.826-2.83a6.056 6.056 0 01-1.922 1.28l1.576 3.676zm3.174-2.128c.902-.903 1.61-1.969 2.132-3.174l-3.672-1.588a6.152 6.152 0 01-1.29 1.936l2.83 2.826zm2.133-3.177A9.78 9.78 0 0018 8h-4c0 .812-.159 1.58-.477 2.318l3.674 1.582zM18 8c0-1.35-.265-2.653-.795-3.89L13.53 5.686C13.843 6.419 14 7.185 14 8h4z"
			mask="url(#path-1-inside-1)"
		/>
		<path
			fill={unknownColor}
			d="M5.419 6.425h1.456c.032-.607.44-1 1.058-1 .612 0 1.02.371 1.02.882 0 .51-.21.773-.902 1.187-.741.435-1.037.918-.967 1.761l.01.312h1.424v-.285c0-.526.199-.79.913-1.203.758-.446 1.15-1.01 1.15-1.82 0-1.225-1.016-2.08-2.552-2.08-1.649 0-2.583.924-2.61 2.246zm2.444 5.73c.601 0 .972-.343.972-.896 0-.559-.37-.903-.972-.903s-.978.344-.978.903c0 .553.376.897.978.897z"
		/>
	</svg>
);

const UnknownNew = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="100%"
		height="100%"
		fill="none"
		viewBox="0 0 16 16"
	>
		<circle cx="8" cy="8" r="7" fill={backgroundColor} />
		<mask id="a" fill={backgroundColor}>
			<path
				d="M15.4 4.9a8 8 0 0 0-1.7-2.5A8.1 8.1 0 0 0 11 .6 7.8 7.8 0 0 0 8 0a7.8 7.8 0 0 0-3.1.6 8 8 0 0 0-2.5 1.7A8.1 8.1 0 0 0 .6 5 7.7 7.7 0 0 0 0 8c0 1 .2 2.1.6 3.1a8 8 0 0 0 1.7 2.5A8.1 8.1 0 0 0 5 15.4 7.8 7.8 0 0 0 8 16a7.8 7.8 0 0 0 3.1-.6 8 8 0 0 0 2.5-1.7 8.2 8.2 0 0 0 1.8-2.6c.4-1 .6-2 .6-3.1a7.8 7.8 0 0 0-.6-3.1z"
				clipRule="evenodd"
				fillRule="evenodd"
			/>
		</mask>
		<path
			fill={unknownColor}
			d="m11.1.6.8-1.8-.8 1.8zM5 .6l.8 1.9L4.9.6zM.6 5l1.9.8-1.9-.8zm0 6.2-1.8.8L.6 11zm1.7 2.5 1.5-1.4-1.5 1.4zM5 15.4l.8-1.9-.8 1.9zm6.2 0 .8 1.8-.8-1.8zm2.5-1.7 1.5 1.4-1.5-1.4zm1.8-2.6 1.8.8-1.8-.8zm1.8-7A10 10 0 0 0 15.1 1l-2.8 2.9a6 6 0 0 1 1.2 1.9L17.2 4zM15.1 1a10.1 10.1 0 0 0-3.2-2l-1.6 3.6a6.1 6.1 0 0 1 2 1.3L15 .9zm-3.2-2A9.8 9.8 0 0 0 8-2v4c.8 0 1.6.2 2.3.5L12-1.2zM8-2a9.8 9.8 0 0 0-3.9.8l1.6 3.7A5.8 5.8 0 0 1 8 2v-4zm-3.9.8A10 10 0 0 0 1 .9l2.9 2.9a6 6 0 0 1 1.9-1.3L4-1.2zM1 .9a10.1 10.1 0 0 0-2.1 3.2l3.7 1.6a6.1 6.1 0 0 1 1.3-2L.9 1zm-2 3.2A9.7 9.7 0 0 0-2 8h4c0-.8.2-1.6.5-2.3L-1.2 4zM-2 8c0 1.4.3 2.7.8 3.9l3.7-1.6A5.8 5.8 0 0 1 2 8h-4zm.8 3.9A10 10 0 0 0 .9 15l2.8-2.9a6 6 0 0 1-1.2-1.9L-1.2 12zM.9 15a10.1 10.1 0 0 0 3.2 2.1l1.6-3.7a6.1 6.1 0 0 1-2-1.3L1 15.1zm3.2 2.1A9.8 9.8 0 0 0 8 18v-4a5.8 5.8 0 0 1-2.3-.5L4 17.2zM8 18c1.4 0 2.7-.3 3.9-.8l-1.6-3.7c-.7.3-1.5.5-2.3.5v4zm3.9-.8a10 10 0 0 0 3.2-2.1l-2.9-2.9a6 6 0 0 1-1.9 1.3l1.6 3.7zm3.2-2.1c.9-1 1.6-2 2.1-3.2l-3.7-1.6a6.2 6.2 0 0 1-1.3 2l2.9 2.8zm2.1-3.2A9.8 9.8 0 0 0 18 8h-4c0 .8-.2 1.6-.5 2.3l3.7 1.6zM18 8c0-1.4-.3-2.7-.8-3.9l-3.7 1.6c.3.7.5 1.5.5 2.3h4z"
			mask="url(#a)"
		/>
		<path
			fill={unknownColor}
			d="M5.4 6.4H7c0-.6.4-1 1-1s1 .4 1 1c0 .4-.2.7-.8 1-.8.5-1 1-1 1.9v.3h1.4v-.3c0-.5.2-.8 1-1.2.7-.5 1-1 1-1.8 0-1.3-1-2.1-2.5-2.1-1.6 0-2.6.9-2.6 2.2zM8 12.2c.6 0 1-.4 1-1 0-.5-.4-.8-1-.8s-1 .3-1 .9c0 .5.4.9 1 .9z"
		/>
	</svg>
);

const Unknown = componentWithFG(
	'optimise_development_and_deployment_icons',
	UnknownNew,
	UnknownOld,
);

Unknown.displayName = 'Unknown';
export default Unknown;
