/**
 * @generated SignedSource<<b8e2abf23f858f96328f7fbf9405c873>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutMultiGroupPicker_IssueViewMultiGroupPicker$data = {
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutMultiGroupPickerField_IssueViewMultiGroupPickerField">;
  readonly " $fragmentType": "ui_issueViewLayoutMultiGroupPicker_IssueViewMultiGroupPicker";
};
export type ui_issueViewLayoutMultiGroupPicker_IssueViewMultiGroupPicker$key = {
  readonly " $data"?: ui_issueViewLayoutMultiGroupPicker_IssueViewMultiGroupPicker$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutMultiGroupPicker_IssueViewMultiGroupPicker">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsmultigrouppickerrelayprovider"
    }
  ],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutMultiGroupPicker_IssueViewMultiGroupPicker",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsmultigrouppickerrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "ui_issueViewLayoutMultiGroupPickerField_IssueViewMultiGroupPickerField"
        }
      ]
    }
  ],
  "type": "JiraMultipleGroupPickerField"
};

(node as any).hash = "717cca5dc99bbb263cb2e5860ef5ffdc";

export default node;
