import isEmpty from 'lodash/isEmpty';
import { ISSUE_KEY_REGEXP } from '../strip-url-to-issue-key/index.tsx';

const BROWSE_PATH = /\/browse\/((?:\w+)-(?:\d+))/;
export type RemoteIssueURL = {
	host: string;
	issueKey: string;
};

const extractIssueKeyFromPath = (path: string): string => {
	const match = path.match(BROWSE_PATH);
	return (match && match[1].match(ISSUE_KEY_REGEXP) && match[1]) || '';
};

export const parseIssueUrl = (url: string): RemoteIssueURL | null => {
	if (isEmpty(url)) return null;

	try {
		const parsedURL = new URL(url);
		const host = parsedURL.origin;
		const issueKey = extractIssueKeyFromPath(parsedURL.pathname);

		return !isEmpty(issueKey) ? { host, issueKey } : null;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		return null;
	}
};
