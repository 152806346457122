/**
 * @generated SignedSource<<d38c11cbcc28d051d2caaff8b2100e27>>
 * @relayHash fe046116013b2c3336c51bcbbb0fadff
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID e41bae81fc0a3022641c80d39ea76c8cb98023eb98fecc3699abf8ef4138c4e7

import type { ConcreteRequest, Mutation } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraSingleValueFieldOperations = "SET" | "%future added value";
export type JiraSprintState = "ACTIVE" | "CLOSED" | "FUTURE" | "%future added value";
export type JiraUpdateSprintFieldInput = {
  id: string;
  operation: JiraSprintFieldOperationInput;
};
export type JiraSprintFieldOperationInput = {
  id?: string | null | undefined;
  operation: JiraSingleValueFieldOperations;
};
export type sprint_issueFieldSprint_SprintField_Mutation$variables = {
  input: JiraUpdateSprintFieldInput;
};
export type sprint_issueFieldSprint_SprintField_Mutation$data = {
  readonly jira: {
    readonly updateSprintField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"sprint_issueFieldSprintReadviewFull_SprintReadView">;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type sprint_issueFieldSprint_SprintField_Mutation$rawResponse = {
  readonly jira: {
    readonly updateSprintField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly id: string;
        readonly selectedSprintsConnection: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly endDate: AGG$DateTime | null | undefined;
              readonly id: string;
              readonly name: string | null | undefined;
              readonly sprintId: string;
              readonly state: JiraSprintState | null | undefined;
            } | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type sprint_issueFieldSprint_SprintField_Mutation = {
  rawResponse: sprint_issueFieldSprint_SprintField_Mutation$rawResponse;
  response: sprint_issueFieldSprint_SprintField_Mutation$data;
  variables: sprint_issueFieldSprint_SprintField_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "success"
},
v3 = {
  "concreteType": "MutationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "message"
    }
  ]
},
v4 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "sprint_issueFieldSprint_SprintField_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraSprintFieldPayload",
            "kind": "LinkedField",
            "name": "updateSprintField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraSprintField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "kind": "FragmentSpread",
                    "name": "sprint_issueFieldSprintReadviewFull_SprintReadView"
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "sprint_issueFieldSprint_SprintField_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraSprintFieldPayload",
            "kind": "LinkedField",
            "name": "updateSprintField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraSprintField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "concreteType": "JiraSprintConnection",
                    "kind": "LinkedField",
                    "name": "selectedSprintsConnection",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraSprintEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "concreteType": "JiraSprint",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              {
                                "kind": "ScalarField",
                                "name": "sprintId"
                              },
                              {
                                "kind": "ScalarField",
                                "name": "name"
                              },
                              {
                                "kind": "ScalarField",
                                "name": "state"
                              },
                              {
                                "kind": "ScalarField",
                                "name": "endDate"
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "e41bae81fc0a3022641c80d39ea76c8cb98023eb98fecc3699abf8ef4138c4e7",
    "metadata": {},
    "name": "sprint_issueFieldSprint_SprintField_Mutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "10015ae363c37285aaa230e388ba466f";

export default node;
