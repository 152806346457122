import { getAutomationStack } from '@atlassian/jira-automation-platform/src/common/utils.tsx';
import { performDeleteRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { Environment } from '@atlassian/jira-shared-types/src/tenant-context.tsx';

export type DeleteTemplateParams = {
	environment: Environment | null;
	cloudId: string;
	projectId: string;
	ruleId: number | string;
};

export type DeleteTemplateResult = {};

export const deleteTemplate = async ({
	environment,
	cloudId,
	projectId,
	ruleId,
}: DeleteTemplateParams): Promise<DeleteTemplateResult> => {
	const automationStack = await getAutomationStack(environment, cloudId);
	const DELETE_TEMPLATE_URL = `/gateway/api/automation/internal-api/jira/${cloudId}/${automationStack}/rest/${projectId}/rule/${ruleId}`;

	return performDeleteRequest(DELETE_TEMPLATE_URL);
};

type RemoveRuleConfigParams = {
	issueKey: string;
};

export type RemoveRuleConfigResult = {};

export const removeRuleConfig = async ({
	issueKey,
}: RemoveRuleConfigParams): Promise<RemoveRuleConfigResult> => {
	const ruleConfigEndpoint = `/rest/api/2/issue/${issueKey}/properties/recurrence-config`;
	return performDeleteRequest(ruleConfigEndpoint);
};
