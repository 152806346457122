import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { attachmentPickerPopupOpenSuccess } from '@atlassian/jira-issue-view-store/src/actions/attachment-popup-actions.tsx';
import { isCommentAttachmentInProgressSelector } from '@atlassian/jira-issue-view-store/src/selectors/comment-selector.tsx';
import AttachmentPicker, { type StateProps, type DispatchProps } from './view.tsx';

export default flowWithSafeComponent(
	connect(
		(state: State): StateProps => ({
			isCommentAttachmentInProgress: isCommentAttachmentInProgressSelector(state),
		}),
		(dispatch): DispatchProps => ({
			onClick: () => {
				dispatch(attachmentPickerPopupOpenSuccess());
			},
		}),
	),
)(AttachmentPicker);
