import { performGetRequest } from '@atlassian/jira-fetch/src/index.tsx';
import type { ViewUUID } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { getJpdViewsServicePathPrefix } from '@atlassian/jira-polaris-lib-jpd-views-service/src/index.tsx';
import type { ViewLastSeenResponse } from './types.tsx';

const getViewLastSeenURL = (viewUUID: ViewUUID, limit: number, nextPageKey?: string) =>
	`${getJpdViewsServicePathPrefix()}/v1/views/${viewUUID}/last-viewed?limit=${limit}${nextPageKey ? `&nextPageKey=${nextPageKey}` : ''}`;

export const getViewLastSeen = async (
	viewUUID: ViewUUID,
	limit: number,
	nextPageKey?: string,
): Promise<ViewLastSeenResponse> =>
	performGetRequest(getViewLastSeenURL(viewUUID, limit, nextPageKey));
