import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	shareMainTabTextJwmSummary: {
		id: 'share-button.share-main-tab-text-jwm-summary',
		defaultMessage: 'Share summary',
		description: 'Text for the main share tab for JWM Summary',
	},
	shareMainTabTextJwmBoard: {
		id: 'share-button.share-main-tab-text-jwm-board',
		defaultMessage: 'Share board',
		description: 'Text for the main share tab for JWM Board',
	},
	shareMainTabTextJwmList: {
		id: 'share-button.share-main-tab-text-jwm-list',
		defaultMessage: 'Share list',
		description: 'Text for the main share tab for JWM List',
	},
	shareMainTabTextJwmCalendar: {
		id: 'share-button.share-main-tab-text-jwm-calendar',
		defaultMessage: 'Share calendar',
		description: 'Text for the main share tab for JWM Calendar',
	},
	shareMainTabTextJwmTimeline: {
		id: 'share-button.share-main-tab-text-jwm-timeline',
		defaultMessage: 'Share timeline',
		description: 'Text for the main share tab for JWM Timeline',
	},
	shareMainTabTextJwmForm: {
		id: 'share-button.share-main-tab-text-jwm-form',
		defaultMessage: 'Share form',
		description: 'Text for the main share tab for JWM Form',
	},
	shareMainTabTextJwmAttachments: {
		id: 'share-button.share-main-tab-text-jwm-attachments',
		defaultMessage: 'Share attachments',
		description: 'Text for the main share tab for JWM Attachments',
	},
});
