import type {
	GlanceItem,
	ContextPanelItem,
	LayoutContainerTemplateItem,
} from '@atlassian/jira-issue-layout-common-constants/src/index.tsx';

export const GROUP_APP = 'app' as const;
export const GROUP_CUSTOM = 'custom' as const;
export const GROUP_PANEL = 'panel' as const;
export const GROUP_SYSTEM = 'system' as const;

export type IssueViewLayoutItemTypeWithAnalytics = LayoutContainerTemplateItem & {
	analytics: {
		group: typeof GROUP_APP | typeof GROUP_CUSTOM | typeof GROUP_PANEL | typeof GROUP_SYSTEM | '';
		value: string;
	};
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	payload: GlanceItem['payload'] | ContextPanelItem['payload'] | Record<any, any>;
};
