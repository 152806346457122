/**
 * @generated SignedSource<<6f04d875585f050e9766fd0fd3c33b71>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueFormFieldBase_JiraIssueFieldFormComponent$data = {
  readonly description: string | null | undefined;
  readonly fieldConfig?: {
    readonly isEditable: boolean | null | undefined;
    readonly isRequired: boolean | null | undefined;
  } | null | undefined;
  readonly fieldId: string;
  readonly name: string;
  readonly type: string;
  readonly " $fragmentType": "ui_issueFormFieldBase_JiraIssueFieldFormComponent";
};
export type ui_issueFormFieldBase_JiraIssueFieldFormComponent$key = {
  readonly " $data"?: ui_issueFormFieldBase_JiraIssueFieldFormComponent$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueFormFieldBase_JiraIssueFieldFormComponent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueFormFieldBase_JiraIssueFieldFormComponent",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "ScalarField",
      "name": "description"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "concreteType": "JiraFieldConfig",
          "kind": "LinkedField",
          "name": "fieldConfig",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "isRequired"
            },
            {
              "kind": "ScalarField",
              "name": "isEditable"
            }
          ]
        }
      ],
      "type": "JiraIssueFieldConfiguration",
      "abstractKey": "__isJiraIssueFieldConfiguration"
    }
  ],
  "type": "JiraIssueField",
  "abstractKey": "__isJiraIssueField"
};

(node as any).hash = "ba6801aa134e9f257c5f0d7e3a132ec2";

export default node;
