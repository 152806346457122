/**
 * @generated SignedSource<<32c84035751da17ff672a63d69ca44d0>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type project_issueFieldProjectReadviewFull_ProjectReadView$data = {
  readonly project: {
    readonly avatar: {
      readonly small: string | null | undefined;
    } | null | undefined;
    readonly key: string;
    readonly name: string;
  } | null | undefined;
  readonly " $fragmentType": "project_issueFieldProjectReadviewFull_ProjectReadView";
};
export type project_issueFieldProjectReadviewFull_ProjectReadView$key = {
  readonly " $data"?: project_issueFieldProjectReadviewFull_ProjectReadView$data;
  readonly " $fragmentSpreads": FragmentRefs<"project_issueFieldProjectReadviewFull_ProjectReadView">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "project_issueFieldProjectReadviewFull_ProjectReadView",
  "selections": [
    {
      "concreteType": "JiraProject",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "key"
        },
        {
          "kind": "ScalarField",
          "name": "name"
        },
        {
          "concreteType": "JiraAvatar",
          "kind": "LinkedField",
          "name": "avatar",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "small"
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraProjectField"
};

(node as any).hash = "f418eab58373472aa76206a83e02bd64";

export default node;
