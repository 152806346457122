import { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import {
	CORE_PROJECT,
	SOFTWARE_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { HIDE_DONE_SUBTASKS_FILTER } from '@atlassian/jira-issue-shared-types/src/common/types/user-preferences-type.tsx';
import { useUserPreferencesValue } from '@atlassian/jira-issue-user-preference-services/src/main.tsx';
import type { ChildIssue } from '@atlassian/jira-issue-view-common-types/src/children-issues-type.tsx';
import {
	useProjectKey,
	useProjectType,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import { PREMIUM_EDITION } from '@atlassian/jira-shared-types/src/edition.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import {
	CLASSIC_PORTFOLIO_CHILDREN,
	CLASSIC_SUBTASKS,
	CHILDREN_ISSUES_PANEL,
	type ChildIssuesPanelType,
} from '../model/types.tsx';

export const useHideDoneChildIssues = (
	allIssues: ChildIssue[],
	incompleteIssues: ChildIssue[],
): {
	shouldShowHideDoneInfoIcon: boolean;
	completedIssuesCount: number;
} => {
	const [hideDonePreference] = useUserPreferencesValue(HIDE_DONE_SUBTASKS_FILTER);

	const completedIssuesCount = useMemo(
		() => allIssues.length - incompleteIssues.length,
		[allIssues.length, incompleteIssues.length],
	);

	const shouldShowHideDoneInfoIcon = useMemo(() => {
		if (expVal('jira-nike-chin-m1a-peek-a-boo-child-issue-table', 'isChildIssueEnabled', false)) {
			return hideDonePreference === 'true';
		}
		const hasIncompleteIssues = !isEmpty(incompleteIssues);
		return completedIssuesCount > 0 && hasIncompleteIssues && hideDonePreference === 'true';
	}, [hideDonePreference, completedIssuesCount, incompleteIssues]);

	return { shouldShowHideDoneInfoIcon, completedIssuesCount };
};

const getIsNextgenSubtask = (
	childIssuesPanelType: ChildIssuesPanelType | undefined,
	issueHierarchyLevel: number | undefined,
) =>
	childIssuesPanelType === CHILDREN_ISSUES_PANEL
		? !isNil(issueHierarchyLevel) && issueHierarchyLevel === 0
		: false;

export const getIsSubtaskCreateEnabled = (
	childIssuesPanelType: ChildIssuesPanelType | undefined,
	issueHierarchyLevel: number | undefined,
) =>
	getIsNextgenSubtask(childIssuesPanelType, issueHierarchyLevel) ||
	childIssuesPanelType === CLASSIC_SUBTASKS ||
	childIssuesPanelType === CLASSIC_PORTFOLIO_CHILDREN;

export const useIsAiEnabled = () => {
	const appEditions = useAppEditions();
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const projectType = useProjectType(projectKey);

	if (!appEditions) {
		return false;
	}

	// currently admin hub AI BE endpoint for JWM already defaults to JSW value
	// so we reflect same logic for JWM here
	switch (projectType) {
		case CORE_PROJECT:
		case SOFTWARE_PROJECT:
			return appEditions.software === PREMIUM_EDITION;
		default:
			return false;
	}
};
