// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	save: {
		id: 'issue.log-time.modal.log-time-action.save',
		defaultMessage: 'Save',
		description: 'Text to display for the Save button',
	},
	cancel: {
		id: 'issue.log-time.modal.log-time-action.cancel',
		defaultMessage: 'Cancel',
		description: 'Text to display for the Cancel button',
	},
});
