import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

const ParentEditViewEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-parent-edit-view" */ './ui/parent'),
	),
	getPreloadProps: () => ({}),
});

export default ParentEditViewEntryPoint;
