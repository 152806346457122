import { LOCAL } from '../../common/constants.tsx';

const MAX_RESULTS = 5;
const CACHE_SIZE = MAX_RESULTS * 2;
const SPRINTS_CACHE_KEY = 'sprints';

export const props = {
	size: CACHE_SIZE,
	cacheKey: SPRINTS_CACHE_KEY,
	maxAge: 1000 * 60 * 60 * 24 * 7,
	storageType: LOCAL,
} as const;
