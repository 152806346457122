import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { SortField } from '@atlassian/jira-polaris-domain-field/src/sort/types.tsx';
import * as jqlTransform from '@atlassian/jql-transform-basic';

const { parse, BasicJQL } = jqlTransform;

export const fieldJqlName = (key: string): string => {
	// JQL does not let you sort using customfield_nnn, it has to be in cf[nnn] form
	if (key.startsWith('customfield_')) {
		return `cf[${key.substr(12)}]`;
	}
	return key;
};

export const jqlToFieldKey = (key: string): string => {
	if (key.startsWith('cf[')) {
		return `customfield_${key.slice(3, -1)}`;
	}
	return key;
};

export const fieldProvider = (fieldName: string): jqlTransform.Field => ({
	key: fieldJqlName(fieldName),
	name: fieldName,
	defaultSortOrder: fieldName === 'status' ? 'DESC' : 'ASC',
});

export const generateJQL = (jql: string, sortBy?: { asc: boolean; field: Field }[]) => {
	const prev = parse(jql, fieldProvider);
	const succ = new BasicJQL();
	// no change to filters; copy that over unchanged if we were able to parse
	// the input JQL
	if (prev) {
		succ.filters = prev.filters;
	}

	if (sortBy !== undefined) {
		succ.sorts = sortBy
			.map((sf) => ({
				field: fieldProvider(sf.field.key),
				direction: sf.asc ? ('ASC' as const) : ('DESC' as const),
			}))
			.filter(Boolean);
	} else {
		succ.sorts = [{ field: fieldProvider('rank'), direction: 'ASC' }];
	}

	return succ.toString();
};

export const jqlToSortBy = (
	fields: {
		[key: FieldKey]: Field;
	},
	jql: string,
): SortField[] | undefined => {
	const prev = parse(jql, fieldProvider);

	if (!prev) {
		return undefined;
	}

	// Rank is represented by undefined
	if (prev.sorts.length === 1 && prev.sorts[0].field.key.toLowerCase() === 'rank') {
		return undefined;
	}

	const sortBy = prev.sorts
		.map((s) => {
			const fieldKey = jqlToFieldKey(s.field.key.toLowerCase());
			const field = fields[fieldKey];
			if (!field) {
				return undefined;
			}

			return {
				fieldKey: field.key,
				asc: s.direction === 'ASC',
			};
		})
		.filter(Boolean);

	// If empty, sort by rank
	if (sortBy.length === 0) {
		return undefined;
	}

	return sortBy;
};
