import React from 'react';
import { SERVICE_DESK_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { PROJECT_ARCHIVED } from '@atlassian/jira-issue-shared-types/src/common/types/project-type.tsx';
import {
	AsyncLinkedAlertsViewModal,
	AsyncLinkedAlertsLinkModal,
} from '@atlassian/jira-linked-alerts/src/async.tsx';
import {
	useProjectContext,
	useProjectType,
	useProjectKey,
} from '@atlassian/jira-project-context-service/src/main.tsx';

export const LinkedAlertsModals = () => {
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const projectType = useProjectType(projectKey);
	const isServiceDesk = projectType === SERVICE_DESK_PROJECT;
	const projectContext = useProjectContext(projectKey);

	const isProjectArchived = projectContext?.projectStatus === PROJECT_ARCHIVED;

	return isServiceDesk && !isProjectArchived ? (
		<>
			<AsyncLinkedAlertsViewModal />
			<AsyncLinkedAlertsLinkModal />
		</>
	) : null;
};

export default LinkedAlertsModals;
