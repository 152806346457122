// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { keyframes } from 'styled-components';
import type { ThemeProps, ThemeTokens } from '@atlaskit/button';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

const pulseAnimation = keyframes({
	to: {
		boxShadow: '0 0 0 10px transparent',
	},
});

export const pulseButtonTheme = (
	current: (props: ThemeProps) => ThemeTokens,
	props: ThemeProps,
) => {
	const { buttonStyles, ...rest } = current(props);

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return {
		...rest,
		buttonStyles: {
			...buttonStyles,
			borderRadius: '4px',
			animation: `${pulseAnimation} 1.45s 3 cubic-bezier(0.66, 0, 0, 1)`,
			animationDelay: '0.5s',
			boxShadow: `0 0 0 0 ${token('color.border.discovery', colors.P300)}`,
		},
	} as ThemeTokens;
};
