import React, { type ChangeEvent } from 'react';
import { styled } from '@compiled/react';
import TextArea from '@atlaskit/textarea';
import { useIntl } from '@atlassian/jira-intl';
import {
	CONTENT,
	CONTEXT,
	type Area,
} from '@atlassian/jira-issue-view-common-types/src/connect-field-type.tsx';
import messages from './messages.tsx';

const MIN_ROWS = 2;
export const MAX_ROWS_CONTEXT = 5;
export const MAX_ROWS_CONTENT = 20;

const countRows = (value: string) => {
	if (!value) {
		return 0;
	}

	return value.split(/\r\n|\r|\n/).length;
};

type Props = {
	value: string;
	invalidMessage: string | null;
	scrollRows: number | null;
	area: Area;
	onChange: (arg1: string) => void;
};

const TextAreaField = (props: Props) => {
	const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
		props.onChange(event.target.value);
	};

	const { formatMessage } = useIntl();

	const numRows = countRows(props.value);

	const rows = props.scrollRows || numRows;
	const maxRows = props.area === CONTENT ? MAX_ROWS_CONTENT : MAX_ROWS_CONTEXT;
	const minimumRows = Math.min(maxRows, Math.max(MIN_ROWS, rows));

	const ariaLabel = formatMessage(messages.ariaLabelForTextArea);

	return (
		<TextAreaTransparentScrollTrack>
			<TextArea
				value={props.value}
				onChange={onChange}
				isInvalid={!!props.invalidMessage}
				// @ts-expect-error - TS2322 - Type '{ value: string; onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void; isInvalid: boolean; invalidMessage: string | null; shouldFitContainer: true; autoFocus: true; isLabelHidden: true; label: string; minimumRows: number; }' is not assignable to type 'IntrinsicAttributes & Pick<Combine<Pick<TextareaHTMLAttributes<HTMLTextAreaElement>, "dir" | "form" | "slot" | ... 262 more ... | "dirName">, OwnProps>, "dir" | ... 277 more ... | "isMonospaced"> & RefAttributes<...>'.
				invalidMessage={props.invalidMessage}
				shouldFitContainer
				autoFocus
				isLabelHidden
				label=""
				minimumRows={minimumRows}
				aria-label={ariaLabel}
			/>
		</TextAreaTransparentScrollTrack>
	);
};

TextAreaField.defaultProps = {
	value: '',
	invalidMessage: null,
	scrollRows: null,
	area: CONTEXT,
};

TextAreaField.displayName = 'TextAreaField';

export default TextAreaField;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TextAreaTransparentScrollTrack = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'textarea::-webkit-scrollbar': {
		width: '8px',
		background: 'transparent',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'textarea::-webkit-scrollbar-thumb': {
		// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
		background: '#c1c1c1',
		borderRadius: '10px',
	},
});
