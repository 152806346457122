import React from 'react';
import type { CustomGlyphProps } from '@atlaskit/icon';

export const mergeIdeasGlyph = (props: CustomGlyphProps) => (
	<svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
		<path
			fill="currentColor"
			d="M16.59 13.943a.957.957 0 1 0 1.346 1.357l2.832-2.808a1.053 1.053 0 0 0 .01-1.486l-2.843-2.82a.958.958 0 0 0-1.35 1.361l2.22 2.2-2.215 2.196Z"
		/>
		<path
			fill="currentColor"
			d="M4.706 5.295a.998.998 0 0 0-1.415-.001c-.39.39-.387 1.028.004 1.419l5.233 5.232c.393.394 1.158.713 1.71.713H20.26v-2H10.07L4.706 5.295Z"
		/>
		<path
			fill="currentColor"
			d="M4.706 5.295a.998.998 0 0 0-1.415-.001c-.39.39-.387 1.028.004 1.419l5.233 5.232c.393.394 1.158.713 1.71.713H20.26v-2H10.07L4.706 5.295Z"
		/>
		<path
			fill="currentColor"
			d="M4.706 5.295a.998.998 0 0 0-1.415-.001c-.39.39-.387 1.028.004 1.419l5.233 5.232c.393.394 1.158.713 1.71.713H20.26v-2H10.07L4.706 5.295Z"
		/>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="m7.083 13-3.788 3.787a1.006 1.006 0 0 0 .32 1.637.997.997 0 0 0 1.091-.22l4.1-4.1c-.47-.106-.98-.36-1.278-.659L7.083 13Zm12.178-.158v-.684H9.07l-.572-.572.031-.031c.393-.394 1.158-.713 1.71-.713H20.26v2h-1Z"
			clipRule="evenodd"
		/>
	</svg>
);
