/*
eslint-disable
prefer-arrow-callback,
vars-on-top,
no-var,
eqeqeq,
prefer-rest-params,
no-console,
no-else-return,
camelcase,
prefer-spread,
prefer-template,
object-shorthand,
no-prototype-builtins,
@typescript-eslint/no-this-alias
*/

export type RulesLogic =
	| boolean
	| string
	| number

	// AccessingData
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	| { var: RulesLogic | [RulesLogic] | [RulesLogic, any] | [RulesLogic, any] } // eslint-disable-next-line @typescript-eslint/no-explicit-any
	| { missing: RulesLogic | any[] } // eslint-disable-next-line @typescript-eslint/no-explicit-any
	| { missing_some: [RulesLogic, RulesLogic | any[]] }

	// LogicBooleanOperations
	| { if: [any, any, any, ...any[]] } // eslint-disable-line @typescript-eslint/no-explicit-any
	| { '==': [any, any] } // eslint-disable-line @typescript-eslint/no-explicit-any
	| { '===': [any, any] } // eslint-disable-line @typescript-eslint/no-explicit-any
	| { '!=': [any, any] } // eslint-disable-line @typescript-eslint/no-explicit-any
	| { '!==': [any, any] } // eslint-disable-line @typescript-eslint/no-explicit-any
	| { '!': any } // eslint-disable-line @typescript-eslint/no-explicit-any
	| { '!!': any } // eslint-disable-line @typescript-eslint/no-explicit-any
	| { or: RulesLogic[] }
	| { and: RulesLogic[] }

	// NumericOperations
	| { '>': [RulesLogic, RulesLogic] }
	| { '>=': [RulesLogic, RulesLogic] }
	| { '<': [RulesLogic, RulesLogic] | [RulesLogic, RulesLogic, RulesLogic] }
	| { '<=': [RulesLogic, RulesLogic] | [RulesLogic, RulesLogic, RulesLogic] }
	| { max: RulesLogic[] }
	| { min: RulesLogic[] }
	| { '+': RulesLogic[] | RulesLogic }
	| { '-': RulesLogic[] | RulesLogic }
	| { '*': RulesLogic[] | RulesLogic }
	| { '/': RulesLogic[] | RulesLogic }
	| { '%': [RulesLogic, RulesLogic] }

	// ArrayOperations
	| { map: [RulesLogic, RulesLogic] }
	| { filter: [RulesLogic, RulesLogic] }
	| { reduce: [RulesLogic, RulesLogic, RulesLogic] }
	| { all: [RulesLogic[], RulesLogic] | [RulesLogic, RulesLogic] }
	| { none: [RulesLogic[], RulesLogic] | [RulesLogic, RulesLogic] }
	| { some: [RulesLogic[], RulesLogic] | [RulesLogic, RulesLogic] }
	| { merge: Array<RulesLogic[] | RulesLogic> }
	| { in: [RulesLogic, RulesLogic[]] }

	// StringOperations
	| { in: [RulesLogic, RulesLogic] }
	| { cat: RulesLogic[] }
	| { substr: [RulesLogic, RulesLogic] | [RulesLogic, RulesLogic, RulesLogic] }

	// MiscOperations
	| { log: RulesLogic };

export default (() => {
	/**
	 * Return an array that contains no duplicates (original not modified)
	 * @param  {array} array   Original reference array
	 * @returns {array}         New array with no duplicates
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	function arrayUnique(array: string | any[]) {
		var a = [];
		for (var i = 0, l = array.length; i < l; i++) {
			if (a.indexOf(array[i]) === -1) {
				a.push(array[i]);
			}
		}
		return a;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	var operations: { [key: string]: any } = {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		'==': function (a: any, b: any) {
			return a == b;
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		'===': function (a: any, b: any) {
			return a === b;
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		'!=': function (a: any, b: any) {
			return a != b;
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		'!==': function (a: any, b: any) {
			return a !== b;
		},
		'>': function (a: number, b: number) {
			return a > b;
		},
		'>=': function (a: number, b: number) {
			return a >= b;
		},
		'<': function (a: number, b: number, c: number | undefined) {
			return c === undefined ? a < b : a < b && b < c;
		},
		'<=': function (a: number, b: number, c: number | undefined) {
			return c === undefined ? a <= b : a <= b && b <= c;
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		'!!': function (a: any) {
			return truthy(a);
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		'!': function (a: any) {
			return !truthy(a);
		},
		'%': function (a: number, b: number) {
			return a % b;
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		log: function (a: any) {
			console.log(a);
			return a;
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		in: function (a: any, b: any[]) {
			if (!b || typeof b.indexOf === 'undefined') return false;
			return b.indexOf(a) !== -1;
		},
		cat: function () {
			return Array.prototype.join.call(arguments, '');
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		substr: function (source: any, start: number, end: number) {
			if (end < 0) {
				// JavaScript doesn't support negative end, this emulates PHP behavior
				var temp = String(source).substr(start);
				return temp.substr(0, temp.length + end);
			}
			return String(source).substr(start, end);
		},
		'+': function () {
			return Array.prototype.reduce.call(
				arguments,
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				function (a: any, b: any) {
					return parseFloat(a) + parseFloat(b);
				},
				0,
			);
		},
		'*': function () {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			return Array.prototype.reduce.call(arguments, function (a: string, b: string) {
				return parseFloat(a) * parseFloat(b);
			});
		},
		'-': function (a: number, b: number | undefined) {
			if (b === undefined) {
				return -a;
			} else {
				return a - b;
			}
		},
		'/': function (a: number, b: number) {
			return a / b;
		},
		min: function () {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			return Math.min.apply(this, arguments);
		},
		max: function () {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			return Math.max.apply(this, arguments);
		},
		merge: function () {
			return Array.prototype.reduce.call(
				arguments,
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				function (a: any, b) {
					return a.concat(b);
				},
				[],
			);
		},
		var: function (a: string | null, b: undefined) {
			var not_found = b === undefined ? null : b;
			var data = this;
			if (typeof a === 'undefined' || a === '' || a === null) {
				return data;
			}
			var sub_props = String(a).split('.');
			for (var i = 0; i < sub_props.length; i++) {
				if (data === null || data === undefined) {
					return not_found;
				}
				// Descending into data
				data = data[sub_props[i]];
				if (data === undefined) {
					return not_found;
				}
			}
			return data;
		},
		missing: function () {
			/*
              Missing can receive many keys as many arguments, like {"missing:[1,2]}
              Missing can also receive *one* argument that is an array of keys,
              which typically happens if it's actually acting on the output of another command
              (like 'if' or 'merge')
              */
			var missing = [];
			var keys = Array.isArray(arguments[0]) ? arguments[0] : arguments;

			for (var i = 0; i < keys.length; i++) {
				var key = keys[i];
				var value = apply({ var: key }, this);
				if (value === null || value === '') {
					missing.push(key);
				}
			}

			return missing;
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		missing_some: function (need_count: number, options: string | any[]) {
			// missing_some takes two arguments, how many (minimum) items must be present, and an array of keys (just like 'missing') to check for presence.
			var are_missing = apply({ missing: options }, this);

			if (options.length - are_missing.length >= need_count) {
				return [];
			} else {
				return are_missing;
			}
		},
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const is_logic = function (logic: any | null) {
		return (
			typeof logic === 'object' && // An object
			logic !== null && // but not null
			!Array.isArray(logic) && // and not an array
			Object.keys(logic).length === 1 // with exactly one key
		);
	};

	/*
    This helper will defer to the JsonLogic spec as a tie-breaker when different language interpreters define different behavior for the truthiness of primitives.  E.g., PHP considers empty arrays to be falsy, but Javascript considers them to be truthy. JsonLogic, as an ecosystem, needs one consistent answer.

    Spec and rationale here: http://jsonlogic.com/truthy
    */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const truthy = function (value: any | any[]) {
		if (Array.isArray(value) && value.length === 0) {
			return false;
		}
		return !!value;
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const get_operator = function (logic: any) {
		return Object.keys(logic)[0];
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const get_values = function (logic: any) {
		return logic[get_operator(logic)];
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const apply = function (logic: RulesLogic, data?: unknown): any {
		// Does this array contain logic? Only one way to find out.
		if (Array.isArray(logic)) {
			return logic.map(function (l) {
				return apply(l, data);
			});
		}
		// You've recursed to a primitive, stop!
		if (!is_logic(logic)) {
			return logic;
		}

		var op = get_operator(logic);
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		var values = logic[op];
		var i;
		var current;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		var scopedLogic: any;
		var scopedData;
		var initial;

		// easy syntax for unary operators, like {"var" : "x"} instead of strict {"var" : ["x"]}
		if (!Array.isArray(values)) {
			values = [values];
		}

		// 'if', 'and', and 'or' violate the normal rule of depth-first calculating consequents, let each manage recursion as needed.
		if (op === 'if' || op == '?:') {
			/* 'if' should be called with a odd number of parameters, 3 or greater
              This works on the pattern:
              if( 0 ){ 1 }else{ 2 };
              if( 0 ){ 1 }else if( 2 ){ 3 }else{ 4 };
              if( 0 ){ 1 }else if( 2 ){ 3 }else if( 4 ){ 5 }else{ 6 };

              The implementation is:
              For pairs of values (0,1 then 2,3 then 4,5 etc)
              If the first evaluates truthy, evaluate and return the second
              If the first evaluates falsy, jump to the next pair (e.g, 0,1 to 2,3)
              given one parameter, evaluate and return it. (it's an Else and all the If/ElseIf were false)
              given 0 parameters, return NULL (not great practice, but there was no Else)
              */
			for (i = 0; i < values.length - 1; i += 2) {
				if (truthy(apply(values[i], data))) {
					return apply(values[i + 1], data);
				}
			}
			if (values.length === i + 1) {
				return apply(values[i], data);
			}
			return null;
		} else if (op === 'and') {
			// Return first falsy, or last
			for (i = 0; i < values.length; i += 1) {
				current = apply(values[i], data);
				if (!truthy(current)) {
					return current;
				}
			}
			return current; // Last
		} else if (op === 'or') {
			// Return first truthy, or last
			for (i = 0; i < values.length; i += 1) {
				current = apply(values[i], data);
				if (truthy(current)) {
					return current;
				}
			}
			return current; // Last
		} else if (op === 'filter') {
			scopedData = apply(values[0], data);
			scopedLogic = values[1];

			if (!Array.isArray(scopedData)) {
				return [];
			}
			// Return only the elements from the array in the first argument,
			// that return truthy when passed to the logic in the second argument.
			// For parity with JavaScript, reindex the returned array
			return scopedData.filter(function (datum) {
				return truthy(apply(scopedLogic, datum));
			});
		} else if (op === 'map') {
			scopedData = apply(values[0], data);
			scopedLogic = values[1];

			if (!Array.isArray(scopedData)) {
				return [];
			}

			return scopedData.map(function (datum) {
				return apply(scopedLogic, datum);
			});
		} else if (op === 'reduce') {
			scopedData = apply(values[0], data);
			scopedLogic = values[1];
			initial = typeof values[2] !== 'undefined' ? values[2] : null;

			if (!Array.isArray(scopedData)) {
				return initial;
			}

			// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-shadow
			return scopedData.reduce(function (accumulator: any, current) {
				return apply(scopedLogic, { current: current, accumulator: accumulator });
			}, initial);
		} else if (op === 'all') {
			scopedData = apply(values[0], data);
			scopedLogic = values[1];
			// All of an empty set is false. Note, some and none have correct fallback after the for loop
			if (!Array.isArray(scopedData) || !scopedData.length) {
				return false;
			}
			for (i = 0; i < scopedData.length; i += 1) {
				if (!truthy(apply(scopedLogic, scopedData[i]))) {
					return false; // First falsy, short circuit
				}
			}
			return true; // All were truthy
		} else if (op === 'none') {
			scopedData = apply(values[0], data);
			scopedLogic = values[1];

			if (!Array.isArray(scopedData) || !scopedData.length) {
				return true;
			}
			for (i = 0; i < scopedData.length; i += 1) {
				if (truthy(apply(scopedLogic, scopedData[i]))) {
					return false; // First truthy, short circuit
				}
			}
			return true; // None were truthy
		} else if (op === 'some') {
			scopedData = apply(values[0], data);
			scopedLogic = values[1];

			if (!Array.isArray(scopedData) || !scopedData.length) {
				return false;
			}
			for (i = 0; i < scopedData.length; i += 1) {
				if (truthy(apply(scopedLogic, scopedData[i]))) {
					return true; // First truthy, short circuit
				}
			}
			return false; // None were truthy
		}

		// Everyone else gets immediate depth-first recursion
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		values = values.map(function (val: any) {
			return apply(val, data);
		});

		// The operation is called with "data" bound to its "this" and "values" passed as arguments.
		// Structured commands like % or > can name formal arguments while flexible commands (like missing or merge) can operate on the pseudo-array arguments
		// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Functions/arguments
		if (operations.hasOwnProperty(op) && typeof operations[op] === 'function') {
			return operations[op].apply(data, values);
		} else if (op.indexOf('.') > 0) {
			// Contains a dot, and not in the 0th position
			var sub_ops = String(op).split('.');
			var operation = operations;
			for (i = 0; i < sub_ops.length; i++) {
				if (!operation.hasOwnProperty(sub_ops[i])) {
					throw new Error(
						'Unrecognized operation ' +
							op +
							' (failed at ' +
							sub_ops.slice(0, i + 1).join('.') +
							')',
					);
				}
				// Descending into operations
				operation = operation[sub_ops[i]];
			}

			return operation.apply(data, values);
		}

		throw new Error('Unrecognized operation ' + op);
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const uses_data = function (logic: { [x: string]: any }) {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		var collection: any[] = [];

		if (is_logic(logic)) {
			var op = get_operator(logic);
			var values = logic[op];

			if (!Array.isArray(values)) {
				values = [values];
			}

			if (op === 'var') {
				// This doesn't cover the case where the arg to var is itself a rule.
				collection.push(values[0]);
			} else {
				// Recursion!
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				values.forEach(function (val: { [x: string]: any }) {
					collection.push.apply(collection, uses_data(val));
				});
			}
		}

		return arrayUnique(collection);
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const add_operation = function (name: string | number, code: any) {
		operations[name] = code;
	};

	const rm_operation = function (name: string | number) {
		delete operations[name];
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const rule_like = function (rule: string | any[], pattern: string | any[]): boolean {
		// console.log("Is ". JSON.stringify(rule) . " like " . JSON.stringify(pattern) . "?");
		if (pattern === rule) {
			return true;
		} // TODO : Deep object equivalency?
		if (pattern === '@') {
			return true;
		} // Wildcard!
		if (pattern === 'number') {
			return typeof rule === 'number';
		}
		if (pattern === 'string') {
			return typeof rule === 'string';
		}
		if (pattern === 'array') {
			// !logic test might be superfluous in JavaScript
			return Array.isArray(rule) && !is_logic(rule);
		}

		if (is_logic(pattern)) {
			if (is_logic(rule)) {
				var pattern_op = get_operator(pattern);
				var rule_op = get_operator(rule);

				if (pattern_op === '@' || pattern_op === rule_op) {
					// echo "\nOperators match, go deeper\n";
					return rule_like(get_values(rule), get_values(pattern));
				}
			}
			return false; // pattern is logic, rule isn't, can't be eq
		}

		if (Array.isArray(pattern)) {
			if (Array.isArray(rule)) {
				if (pattern.length !== rule.length) {
					return false;
				}
				/*
                  Note, array order MATTERS, because we're using this array test logic to consider arguments, where order can matter. (e.g., + is commutative, but '-' or 'if' or 'var' are NOT)
                */
				for (var i = 0; i < pattern.length; i += 1) {
					// If any fail, we fail
					if (!rule_like(rule[i], pattern[i])) {
						return false;
					}
				}
				return true; // If they *all* passed, we pass
			} else {
				return false; // Pattern is array, rule isn't
			}
		}

		// Not logic, not array, not a === match for rule.
		return false;
	};

	return {
		is_logic,
		truthy,
		get_operator,
		get_values,
		apply,
		uses_data,
		add_operation,
		rm_operation,
		rule_like,
	};
})();
