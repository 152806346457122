/**
 * @generated SignedSource<<065aafb22dc6953d1ff22f2ced471324>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type changeWatchState_issueViewWatchers_ChangeWatchWithRelay$data = {
  readonly fieldId: string;
  readonly id: string;
  readonly type: string;
  readonly watch: {
    readonly $updatableFragmentSpreads: FragmentRefs<"useWatchesMutation_Mutation_Updatable">;
    readonly count: AGG$Long | null | undefined;
    readonly isWatching: boolean | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "changeWatchState_issueViewWatchers_ChangeWatchWithRelay";
};
export type changeWatchState_issueViewWatchers_ChangeWatchWithRelay$key = {
  readonly " $data"?: changeWatchState_issueViewWatchers_ChangeWatchWithRelay$data;
  readonly " $fragmentSpreads": FragmentRefs<"changeWatchState_issueViewWatchers_ChangeWatchWithRelay">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "changeWatchState_issueViewWatchers_ChangeWatchWithRelay",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "concreteType": "JiraWatch",
      "kind": "LinkedField",
      "name": "watch",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isWatching"
        },
        {
          "kind": "ScalarField",
          "name": "count"
        },
        {
          "kind": "FragmentSpread",
          "name": "useWatchesMutation_Mutation_Updatable"
        }
      ]
    }
  ],
  "type": "JiraWatchesField"
};

(node as any).hash = "9092ff78a66fb65888770de091f98631";

export default node;
