import { useCallback } from 'react';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import {
	fireOperationalAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';

export type WithAnalytics = <T>(
	requestCallback: () => Promise<T>,
	requestName: string,
) => Promise<T>;

const getErrorAttributes = (error: unknown) => {
	if (error instanceof Error) {
		return {
			errorMessage: error.message,
			...(error instanceof FetchError && { statusCode: error.statusCode }),
		};
	}

	return {};
};

export const useWithAnalytics = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	return useCallback(
		async <T,>(requestPromise: Promise<T>, requestName: string): Promise<T> => {
			const analyticsEvent = createAnalyticsEvent({});

			try {
				const response = await requestPromise;
				fireOperationalAnalytics(analyticsEvent, `${requestName} succeeded`);
				return response;
			} catch (err) {
				fireOperationalAnalytics(analyticsEvent, `${requestName} failed`, getErrorAttributes(err));
				throw err;
			}
		},
		[createAnalyticsEvent],
	);
};
