const monthMap: Record<string, string> = {
	Jan: '01',
	January: '01',
	Feb: '02',
	February: '02',
	Mar: '03',
	March: '03',
	Apr: '04',
	April: '04',
	May: '05',
	Jun: '06',
	June: '06',
	Jul: '07',
	July: '07',
	Aug: '08',
	August: '08',
	Sep: '09',
	September: '09',
	Oct: '10',
	October: '10',
	Nov: '11',
	November: '11',
	Dec: '12',
	December: '12',
};

const formatDay = (day: string): string => {
	if (day.length === 1) return `0${day}`;
	return day;
};

const formatMonth = (month: string): string => {
	if (monthMap[month]) return monthMap[month];
	if (month.length === 1) return `0${month}`;
	return month;
};

const formatYear = (year: string): number => {
	const yearAsNumber = Number(year);
	if (yearAsNumber < 100) return yearAsNumber + 2000;
	return yearAsNumber;
};

const constructISOTime = (time: string) => {
	const timeStr = time.trim();

	const time24HourPattern = /^T(\d{1,2}):(\d{2}):?(\d{2})?$/;
	const time12HourPattern = /^@?\s*(\d{1,2}):(\d{2})\s*(am|pm)?$/i;

	const match24Hour = timeStr.match(time24HourPattern);
	if (match24Hour) {
		// eslint-disable-next-line prefer-const
		let [, hours, minutes, seconds] = match24Hour;
		seconds = seconds || '00';
		return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}:${seconds.padStart(2, '0')}`;
	}
	const match12Hour = timeStr.match(time12HourPattern);
	if (match12Hour) {
		let hours: number | string;
		let minutes: number | string;
		let ampm: string;
		[, hours, minutes, ampm] = match12Hour;
		hours = parseInt(hours, 10);
		minutes = parseInt(minutes, 10);
		if (ampm) {
			ampm = ampm.toLowerCase();
			if (ampm === 'pm' && hours !== 12) {
				hours += 12;
			} else if (ampm === 'am' && hours === 12) {
				hours = 0;
			}
		}
		return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:00`;
	}
	return '00:00:00';
};

const constructISODateTime = (date: string) => {
	const regexForDay = /^(\d{1,2})[/\s-]([A-Za-z]+|\d{1,2})[/\s-](\d{2,4})(.*)/;
	const regexForDateWithYYYYFirst = /^(\d{2,4})[/\s-]([A-Za-z]+|\d{1,2})[/\s-](\d{1,2})(.*)/;
	const matchForDay = date.match(regexForDay);
	const matchForDayWithYYYYFirst = date.match(regexForDateWithYYYYFirst);

	if (matchForDay) {
		const [, day, month, year, time] = matchForDay;
		let formattedDay = formatDay(day);
		let formattedMonth = formatMonth(month);
		const formattedYear = formatYear(year);
		let skipMatchingDay = false;
		if (Number(day) < 13 && Number(month)) {
			[formattedDay, formattedMonth] = [formattedMonth, formattedDay];
		} else if (Number(formattedMonth) > 12) {
			if (Number(formattedDay) < 13)
				[formattedDay, formattedMonth] = [formattedMonth, formattedDay];
			else skipMatchingDay = true;
		}
		if (!skipMatchingDay) {
			const isoTime = constructISOTime(time);
			return `${formattedYear}-${formattedMonth}-${formattedDay}T${isoTime}`;
		}
	}
	if (matchForDayWithYYYYFirst) {
		const [, year, month, day, time] = matchForDayWithYYYYFirst;
		let formattedDay = formatDay(day);
		let formattedMonth = formatMonth(month);
		const formattedYear = formatYear(year);
		if (Number(formattedMonth) > 12) {
			if (Number(formattedDay) < 13)
				[formattedDay, formattedMonth] = [formattedMonth, formattedDay];
			else return null;
		}
		const isoTime = constructISOTime(time);
		return `${formattedYear}-${formattedMonth}-${formattedDay}T${isoTime}`;
	}
	return null;
};

function convertUnixToISO(unixTimestamp: number) {
	const date = new Date(unixTimestamp * 1000);
	if (!date.getTime()) return null;
	return date.toISOString().slice(0, 19);
}

export const findADate = (defaultValue: unknown) => {
	if (typeof defaultValue === 'number') {
		return convertUnixToISO(defaultValue);
	}
	if (typeof defaultValue === 'string') {
		if (Number(defaultValue)) return convertUnixToISO(Number(defaultValue));
		return constructISODateTime(defaultValue);
	}

	return null;
};

export const checkIfValidId = (input: unknown) => {
	if ((typeof input === 'string' || typeof input === 'number') && Number(input)) return true;

	return false;
};
