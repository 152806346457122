// Todo clean moment after soaking this change for a while @AAG
import { startOfDay, addDays, subDays } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import type { Locale } from '@atlassian/jira-common-constants/src/supported-locales.tsx';
import type { IntlShape } from '@atlassian/jira-intl/src/index.tsx';
import { NEW_SLA_FORMAT } from '../../common/constants.tsx';
import {
	COMPLETED,
	ONGOING,
	PAUSED,
	type GoalView,
	type Sla,
	type TransformedGoalView,
	type TransformedSla,
	type CompletedCycle,
} from '../../common/types.tsx';
import messages from '../../messages.tsx';

export const EMPTY_TIMEZONE = '';

export const DEFAULT_TIMEZONE = 'America/Toronto';
export const DEFAULT_LOCALE: Locale = 'en_US';

export const utilGlobals: {
	timezone: string;
	locale: Locale;
	intl: IntlShape | undefined;
} = {
	timezone: DEFAULT_TIMEZONE,
	locale: DEFAULT_LOCALE,
	intl: undefined,
};

// convert time in hh:mm format to number of mins
const getMinutes = (timeInHourColonMin: string) => {
	const [hours, minutes] = timeInHourColonMin.split(':');
	return +hours * 60 + +minutes;
};
const getTimeRemaining = (timeInHourColonMin: string) => {
	const isTimeInMinus = timeInHourColonMin.indexOf('-') === 0;
	if (isTimeInMinus) {
		const timeInHourColonMinIgnoringMinus = timeInHourColonMin.substr(1);
		return -1 * getMinutes(timeInHourColonMinIgnoringMinus);
	}
	return getMinutes(timeInHourColonMin);
};

const getTimeRemainingInMinutes = (timeInHourSpaceMinutes: string): number => {
	const isTimeInMinus = timeInHourSpaceMinutes.indexOf('-') === 0;
	const absoluteTime = isTimeInMinus ? timeInHourSpaceMinutes.substring(1) : timeInHourSpaceMinutes;
	const [hoursStr = '0', minutesStr = '0'] = absoluteTime.split(':');
	const hours = parseInt(hoursStr, 10);
	const minutes = parseInt(minutesStr, 10);
	const totalMinutes = hours * 60 + minutes;
	return isTimeInMinus ? -1 * totalMinutes : totalMinutes;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getPercentageOfTimeTaken = (goalTime: any, remainingTime: string) => {
	const goalTimeInMinutes = getTimeRemainingInMinutes(goalTime);
	const remainingTimeInMinutes = getTimeRemainingInMinutes(remainingTime);
	let percentage = (goalTimeInMinutes - remainingTimeInMinutes) / goalTimeInMinutes;
	// @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'number'.
	percentage = (percentage * 100).toFixed(2);
	// @ts-expect-error - TS2345 - Argument of type 'number' is not assignable to parameter of type 'string'.
	return Math.round(parseInt(percentage, 10)).toString();
};

export const getHoverContent = (
	closed: boolean,
	remainingTime: string,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	breachTime: any,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	goalTime: any,
	goalTimeLong: string,
	remainingTimeLong: string,
): string => {
	const isTimeInMinus = remainingTimeLong.indexOf('-') === 0;
	let remainingTimeString;
	let timeStatus;
	if (isTimeInMinus) {
		remainingTimeString = remainingTimeLong.substring(1);
		timeStatus = utilGlobals.intl?.formatMessage(messages.past);
	} else {
		remainingTimeString = remainingTimeLong;
		timeStatus = utilGlobals.intl?.formatMessage(messages.left);
	}

	const getBreachTime = () =>
		breachTime ||
		(utilGlobals.intl ? utilGlobals.intl.formatMessage(messages.notApplicable) : 'undefined');

	if (closed === true) {
		timeStatus = isTimeInMinus
			? utilGlobals.intl?.formatMessage(messages.breached)
			: utilGlobals.intl?.formatMessage(messages.met);
		const percentage = getPercentageOfTimeTaken(goalTime, remainingTime);
		const hoverContentCompleted = `${
			isTimeInMinus ? `-${remainingTimeString}` : remainingTimeString
		} ${timeStatus ?? ''} ${String.fromCodePoint(
			0x2022,
		)} ${percentage}% of ${goalTimeLong} ${String.fromCodePoint(0x2022)} ${getBreachTime()}`;
		return hoverContentCompleted;
	}

	const hoverContent = `${remainingTimeString} ${timeStatus ?? ''} ${String.fromCodePoint(
		0x2022,
	)} ${utilGlobals.intl?.formatMessage(messages.due) ?? ''}: ${breachTime}`;

	return hoverContent;
};

const getBreachTimeInLocalString = (breachTimeInEpochMillis: undefined | number | string) => {
	if (breachTimeInEpochMillis) {
		const BREACH_DATE = utcToZonedTime(breachTimeInEpochMillis, utilGlobals.timezone);
		const formattedDate = `${format(BREACH_DATE, 'yyyy-MM-dd hh:mma ', {
			timeZone: utilGlobals.timezone,
		})}GMT${format(BREACH_DATE, 'xx', { timeZone: utilGlobals.timezone })}`;
		return formattedDate;
	}
	return '';
};

const getBreachTimeInLocalStringForLocale = (
	breachTimeInEpochMillis: undefined | number | string,
) => {
	if (breachTimeInEpochMillis) {
		const BREACH_DATE = utcToZonedTime(breachTimeInEpochMillis, utilGlobals.timezone);
		const formattedDate = `${utilGlobals.intl?.formatDate(breachTimeInEpochMillis, {
			hour12: !isBrowserLocale24h(),
			timeZone: utilGlobals.timezone,
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
			hour: '2-digit',
			minute: '2-digit',
		})} GMT${format(BREACH_DATE, 'xxx', { timeZone: utilGlobals.timezone })}`;
		return formattedDate;
	}
	return '';
};

export const isBrowserLocale24h = () => {
	let { locale } = utilGlobals;
	if (locale === undefined) {
		locale = 'en_US';
	}
	const localeString = locale.replace('_', '-');
	const dateTimeFormat = new Intl.DateTimeFormat(localeString, { hour: 'numeric' });
	return !dateTimeFormat.format(0).match(/[A/P]M/);
};

const getDays = (timezone: string) => {
	const date = utcToZonedTime(new Date(), timezone);
	return [startOfDay(date), startOfDay(addDays(date, 1)), startOfDay(subDays(date, 1))] as const;
};
// daysArray is writter to facilitate unit test for mocking getDays response
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getMatchingDay = (receivedDate: any, timezone: any, daysArray?: any) => {
	const [today, tomorrow, yesterday] = daysArray || getDays(timezone);

	// @ts-expect-error - TS7006 - Parameter 'dateOne' implicitly has an 'any' type.
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const isSame = (dateOne, dateTwo: any) =>
		dateOne.getDate() === dateTwo.getDate() &&
		dateOne.getMonth() === dateTwo.getMonth() &&
		dateOne.getFullYear() === dateTwo.getFullYear();

	const isToday = isSame(today, receivedDate);
	const isTomorrow = isSame(tomorrow, receivedDate);
	const isYesterday = isSame(yesterday, receivedDate);

	let defaultDayFormat = format(receivedDate, 'd MMM');

	if (utilGlobals.intl) {
		isToday && (defaultDayFormat = utilGlobals.intl.formatMessage(messages.today));
		isTomorrow && (defaultDayFormat = utilGlobals.intl.formatMessage(messages.tomorrow));
		isYesterday && (defaultDayFormat = utilGlobals.intl.formatMessage(messages.yesterday));
	}

	return defaultDayFormat;
};

export const getMatchingDayForLocale = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	receivedDate: any,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	timezone: any,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	timeInEpochMillis: any,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	daysArray?: any,
) => {
	const [today, tomorrow, yesterday] = daysArray || getDays(timezone);

	// @ts-expect-error - TS7006 - Parameter 'dateOne' implicitly has an 'any' type.
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const isSame = (dateOne, dateTwo: any) =>
		dateOne.getDate() === dateTwo.getDate() &&
		dateOne.getMonth() === dateTwo.getMonth() &&
		dateOne.getFullYear() === dateTwo.getFullYear();

	const isToday = isSame(today, receivedDate);
	const isTomorrow = isSame(tomorrow, receivedDate);
	const isYesterday = isSame(yesterday, receivedDate);

	let defaultDayFormat = utilGlobals.intl?.formatDate(timeInEpochMillis, {
		timeZone: utilGlobals.timezone,
		day: '2-digit',
		month: 'short',
	});

	if (utilGlobals.intl) {
		isToday && (defaultDayFormat = utilGlobals.intl.formatMessage(messages.today));
		isTomorrow && (defaultDayFormat = utilGlobals.intl.formatMessage(messages.tomorrow));
		isYesterday && (defaultDayFormat = utilGlobals.intl.formatMessage(messages.yesterday));
	}

	return defaultDayFormat;
};

const formatTimeAndDateForLocale = (timeInEpochMillis: number) => {
	const { timezone } = utilGlobals;
	const timeTz = utcToZonedTime(timeInEpochMillis, timezone);
	return `${getMatchingDayForLocale(
		timeTz,
		timezone,
		timeInEpochMillis,
	)} ${utilGlobals.intl?.formatDate(timeInEpochMillis, {
		hour12: !isBrowserLocale24h(),
		timeZone: timezone,
		hour: '2-digit',
		minute: '2-digit',
	})}`;
};

// Returns localised time
export const getTimeInSlaFriendlierFormatForLocale = (
	closed: boolean,
	breachTimeInEpochMillis?: number,
	stopTimeInEpochMillis?: number,
) => {
	if (closed && stopTimeInEpochMillis) {
		return formatTimeAndDateForLocale(stopTimeInEpochMillis);
	}
	if (breachTimeInEpochMillis) {
		return formatTimeAndDateForLocale(breachTimeInEpochMillis);
	}
	return '';
};

const transformCompleteGoals = ({
	failed,
	breachTimeInEpochMillis,
	remainingTimeLong,
	startTime,
	stopTime,
	goalTimeLong,
	remainingTime,
	goalTime,
}: CompletedCycle): CompletedCycle => ({
	failed,
	remainingTimeLong,
	goalTimeLong,
	startTime,
	stopTime,
	remainingTime,
	goalTime,
	breachTimeInEpochMillis: getBreachTimeInLocalString(breachTimeInEpochMillis),
});
const transformGoalView = ({
	active,
	paused,
	closed,
	failed,
	remainingTime,
	remainingTimeLong,
	goalTimeLong,
	goalTime,
	metricName,
	calendarName,
	stopTime,
	completeGoals,
	breachTimeInEpochMillis,
	stopTimeInEpochMillis,
	slaDisplayFormat = NEW_SLA_FORMAT,
}: GoalView): TransformedGoalView => {
	const shouldDisplaySlaInNewFormat: boolean = slaDisplayFormat === NEW_SLA_FORMAT;
	let status = ONGOING;

	// When an Sla is paused due to being outside of working hours, the paused value is false
	if (paused || !active) {
		// @ts-expect-error - TS2322 - Type '"PAUSED"' is not assignable to type '"ONGOING"'.
		status = PAUSED;
	}
	// closed
	if (closed) {
		// @ts-expect-error - TS2322 - Type '"COMPLETED"' is not assignable to type '"ONGOING"'.
		status = COMPLETED;
	}
	const timeRemainingInMinutes = getTimeRemaining(remainingTime);
	const isBreached = failed || timeRemainingInMinutes < 0;
	const timeInSlaFriendlierFormat = (
		shouldDisplaySlaInNewFormat &&
		getTimeInSlaFriendlierFormatForLocale(closed, breachTimeInEpochMillis, stopTimeInEpochMillis)
	).toString();

	const timeRemainingFriendly = shouldDisplaySlaInNewFormat
		? timeInSlaFriendlierFormat
		: remainingTimeLong;

	const breachTimeString = () => {
		if (breachTimeInEpochMillis) {
			return getBreachTimeInLocalStringForLocale(breachTimeInEpochMillis);
		}
		return utilGlobals.intl?.formatMessage(messages.notApplicable);
	};

	const hoverContent = shouldDisplaySlaInNewFormat
		? getHoverContent(
				closed,
				remainingTime,
				breachTimeString(),
				goalTime,
				goalTimeLong,
				remainingTimeLong,
			)
		: undefined;

	return {
		isBreached,
		timeRemainingInMinutes,
		status,
		timeRemainingFriendly,
		metricName,
		goalTime: goalTimeLong,
		calendarName,
		stopTime,
		previousCycles: shouldDisplaySlaInNewFormat
			? completeGoals.map(transformCompleteGoals)
			: completeGoals,
		hoverContent,
		slaDisplayFormat,
	};
};

export const transformSla = (
	{ goalViews, hasPreviousCycles }: Sla,
	timezone: string,
	intl?: IntlShape,
	locale?: Locale,
): TransformedSla => {
	if (timezone !== EMPTY_TIMEZONE) {
		utilGlobals.timezone = timezone;
	}
	utilGlobals.intl = intl;
	if (locale !== undefined) {
		utilGlobals.locale = locale;
	}
	const transformedGoalViews = goalViews.map(transformGoalView);
	return {
		hasPreviousCycles,
		goalViews: transformedGoalViews,
	};
};
