/**
 * @generated SignedSource<<eee6ea3a8b3b25ed9fa4c9e85d091d71>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type parent_issueFieldParentReadviewFull_ParentReadView$data = {
  readonly parentIssue: {
    readonly issueColorField: {
      readonly color: {
        readonly colorKey: string | null | undefined;
      } | null | undefined;
    } | null | undefined;
    readonly issueTypeField: {
      readonly issueType: {
        readonly avatar: {
          readonly xsmall: string | null | undefined;
        } | null | undefined;
        readonly name: string;
      } | null | undefined;
    } | null | undefined;
    readonly key: string;
    readonly summaryField: {
      readonly text: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "parent_issueFieldParentReadviewFull_ParentReadView";
};
export type parent_issueFieldParentReadviewFull_ParentReadView$key = {
  readonly " $data"?: parent_issueFieldParentReadviewFull_ParentReadView$data;
  readonly " $fragmentSpreads": FragmentRefs<"parent_issueFieldParentReadviewFull_ParentReadView">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "parent_issueFieldParentReadviewFull_ParentReadView",
  "selections": [
    {
      "concreteType": "JiraIssue",
      "kind": "LinkedField",
      "name": "parentIssue",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "key"
        },
        {
          "concreteType": "JiraColorField",
          "kind": "LinkedField",
          "name": "issueColorField",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraColor",
              "kind": "LinkedField",
              "name": "color",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "colorKey"
                }
              ]
            }
          ]
        },
        {
          "concreteType": "JiraIssueTypeField",
          "kind": "LinkedField",
          "name": "issueTypeField",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraIssueType",
              "kind": "LinkedField",
              "name": "issueType",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraAvatar",
                  "kind": "LinkedField",
                  "name": "avatar",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "xsmall"
                    }
                  ]
                },
                {
                  "kind": "ScalarField",
                  "name": "name"
                }
              ]
            }
          ]
        },
        {
          "concreteType": "JiraSingleLineTextField",
          "kind": "LinkedField",
          "name": "summaryField",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "text"
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraParentIssueField"
};

(node as any).hash = "f9cdc2acd2c426037f13d9ef6280fd67";

export default node;
