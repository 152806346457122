export const CreateLinkError = {
	DUPLICATE: 'addconfluencelink.error.duplicate',
	FORBIDDEN: 'addconfluencelink.error.page.forbidden',
	ISSUE_ID_INVALID: 'addconfluencelink.error.issueId.invalid',
	NO_MATCHING_APP_LINK: 'addconfluencelink.error.no.matching.app.link',
	PAGE_ID_NOT_FOUND: 'addconfluencelink.error.pageid.notfound',
	UNAUTHORIZED: 'addconfluencelink.error.page.unauthorized',
	UNAUTHORIZED_PROMPT: 'addconfluencelink.error.page.unauthorized-prompt',
	URL_INVALID: 'addconfluencelink.error.url.invalid',
	URL_REQUIRED: 'addconfluencelink.error.url.required',
} as const;
