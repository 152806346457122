import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	errorEmpty: {
		id: 'issue-field-single-line-text-inline-edit-heading.single-line-text-heading.error-empty',
		defaultMessage: 'You must specify a summary of the issue.',
		description: 'Error message when field is empty before saving.',
	},
	errorTitle: {
		id: 'issue-field-single-line-text-inline-edit-heading.single-line-text-heading.error-title',
		defaultMessage: "We couldn't save the issue summary.",
		description: 'Error message informing the user that updating the field failed',
	},
	errorMessage: {
		id: 'issue-field-single-line-text-inline-edit-heading.single-line-text-heading.error-message',
		defaultMessage: 'Check your connection, then give it another try.',
		description: 'Error message when save fails',
	},
	maxLengthErrorMessage: {
		id: 'issue-field-single-line-text-inline-edit-heading.single-line-text-heading.max-length-error-message',
		defaultMessage: '{maxLength, plural, one {# character} other {# characters}} limit',
		description:
			'Validation message shown to the user when the updated value exceeds the inputs max length.',
	},
	confirmButtonLabel: {
		id: 'issue-field-single-line-text-inline-edit-heading.single-line-text-heading.confirm-button-label',
		defaultMessage: 'Confirm {fieldName}',
		description:
			'An accessibility label for a confirm button in an input field. The fieldName parameter refers to an optional heading for what the field is controlling. Pressing the button saves the contents of the input field.',
	},
	cancelButtonLabel: {
		id: 'issue-field-single-line-text-inline-edit-heading.single-line-text-heading.cancel-button-label',
		defaultMessage: 'Cancel {fieldName}',
		description:
			'An accessibility label for a cancel button in an input field. The fieldName parameter refers to an optional heading for what the field is controlling. Pressing the button reverts the contents of the input field to their previous value.',
	},
	editButtonLabel: {
		id: 'issue-field-single-line-text-inline-edit-heading.single-line-text-heading.edit-button-label',
		defaultMessage: 'Edit {fieldName}',
		description:
			'An accessibility label for an edit button in an input field. Pressing the button allows the user to edit the contents of the input field.',
	},
	extendedEditButtonLabel: {
		id: 'issue-field-single-line-text-inline-edit-heading.single-line-text-heading.extended-edit-button-label',
		defaultMessage: '{inputFieldValue}- edit {fieldName}',
		description:
			'An accessibility label for an edit button in an input field. Pressing the button allows the user to edit the contents of the input field.',
	},
});
