import { freeze } from 'icepick';
import type { ReleasesData } from '@atlassian/jira-development-common/src/model/releases-data.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type { SetContextAction } from '../../context/actions/index.tsx';
import {
	type ReleasesDataFetchAction,
	RELEASES_DATA_FETCH_RESPONSE,
	type ReleasesDataFetchResponseAction,
} from '../actions/index.tsx';

type ReleaseGlanceActions =
	| ReleasesDataFetchAction
	| ReleasesDataFetchResponseAction
	| SetContextAction;

export const releasesDataReducer = (
	state: ReleasesData | null = null,
	action: ReleaseGlanceActions,
): ReleasesData | null => {
	const { type, payload } = action;
	switch (type) {
		case RELEASES_DATA_FETCH_RESPONSE:
			if (payload instanceof Error) {
				// TODO: show a error flag
				fireErrorAnalytics({
					meta: {
						id: 'releasesField',
						packageName: 'jiraDevelopmentSummaryReleasesField',
					},
					attributes: {
						errorType: 'serverError',
					},
				});
				return null;
			}
			return freeze(payload);
		default:
			return state;
	}
};
