export const ANALYTICS_SOURCE_NAME = 'itsmPIRIncidentTour';
export const INCIDENT_REVIEW_BUTTON = 'incident-review-button';

export const PRIMARY_INCIDENT_SECTION = 'primary-incident-section';
export const INCIDENT_SUMMARY_SECTION = 'incident-summary-section';

export const LEARN_MORE_DOCUMENT_LINK =
	'https://support.atlassian.com/jira-service-management-cloud/docs/what-are-post-incident-reviews/';

export const SUMMARIZE_INCIDENT_BUTTON_SPOTLIGHT = 'summarize-incident-button';
