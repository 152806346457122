import { functionWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import messages from './messages.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { default } from './main';

export const convertToIssueLabel = functionWithCondition(
	() => fg('jira-issue-terminology-refresh-m3'),
	() => messages.convertToIssueIssueTermRefresh,
	() => messages.convertToIssue,
)();

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { default as ConvertToIssueButtonItem } from './button-item';
