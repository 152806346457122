import React, { type ComponentType } from 'react';
import type { MediaClientConfig } from '@atlaskit/media-core';
import { createAuthProvider } from '../create-auth-provider/index.tsx';
import {
	useTokenProvider,
	type WithMediaContextProps,
	type WithTokenProviderProps,
} from '../token-provider/index.tsx';

export type InjectedProps = {
	viewMediaClientConfig: MediaClientConfig;
};

type RequiredProps = WithMediaContextProps;

const withViewMediaClientConfig = <Config extends {}>(
	WrappedComponent: ComponentType<Config & RequiredProps & InjectedProps>,
) => {
	const WrappedComponentWithViewMediaClientConfig = (
		props: Config & RequiredProps & WithTokenProviderProps,
	) => {
		const { onViewRefresh, onUploadRefresh, ...otherProps } = props;
		const { mediaContext } = otherProps;

		const tokenProvider = useTokenProvider(mediaContext, onViewRefresh, onUploadRefresh);

		// Return null while waiting for mediaContext server response.
		if (!mediaContext.viewContext) {
			return null;
		}

		const { clientId, serviceHost } = mediaContext.viewContext;
		const viewMediaClientConfig: MediaClientConfig = {
			authProvider: createAuthProvider(tokenProvider, clientId, serviceHost),
			useSha256ForUploads: true,
		};

		return (
			<WrappedComponent
				viewMediaClientConfig={viewMediaClientConfig}
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				{...(otherProps as Config & RequiredProps)}
			/>
		);
	};

	return WrappedComponentWithViewMediaClientConfig;
};

export default withViewMediaClientConfig;
