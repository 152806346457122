import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	spotlightHeading: {
		id: 'issue-view-foundation.quick-add.quick-actions-changeboarding.changeboarding-message.spotlight-heading',
		defaultMessage: 'One button, many options',
		description: 'Heading for Quick actions Changeboarding message',
	},
	spotlightDescription: {
		id: 'issue-view-foundation.quick-add.quick-actions-changeboarding.changeboarding-message.spotlight-description',
		defaultMessage: "We've made it less cluttered, you'll now find all your options under 'Add'.",
		description: 'Description for Quick actions Changeboarding message',
	},
	closeButtonLabel: {
		id: 'issue-view-foundation.quick-add.quick-actions-changeboarding.changeboarding-message.close-button-label',
		defaultMessage: 'Close',
		description: 'Close button label for Changeboarding message',
	},
});
