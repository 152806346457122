import type {
	Worklog,
	WorklogUser,
} from '@atlassian/jira-issue-gira-transformer-types/src/common/types/server-data.tsx';
import type {
	WorklogMap,
	User,
} from '@atlassian/jira-issue-gira-transformer-types/src/common/types/worklogs.tsx';
import { convertAdfStringToObject } from '@atlassian/jira-rich-content/src/common/adf-parsing-utils.tsx';
import { normalizeItemArray } from './gira/util.tsx';

// Worklog has its own User type meaning we can't reuse user-transformer here
// https://jdog.jira-dev.com/browse/BENTO-6233 aims to have a single user object
const transformUser = (user: WorklogUser): User => {
	const { accountId, displayName = null, avatarUrl = null } = user;
	return { accountId, displayName, avatarUrl };
};

export const transformGraphQlWorklog = (worklog: Worklog) => {
	const { id, author, updateAuthor, created, updated, started, comment, timeSpent } = worklog;
	const wasUpdated = updated !== created;
	return {
		id,
		author: author ? transformUser(author) : null,
		updateAuthor: wasUpdated && updateAuthor ? transformUser(updateAuthor) : null,
		updated: wasUpdated ? updated : null,
		started,
		timeSpent,
		comment: convertAdfStringToObject(comment),
	};
};

export type NormalizedLoadedWorklogs = {
	worklogs: WorklogMap;
};

export const normalizeWorklogs = (worklogs: Worklog[]): NormalizedLoadedWorklogs => {
	const transformedWorklogs = worklogs.map((worklog) => transformGraphQlWorklog(worklog));
	return {
		worklogs: normalizeItemArray(transformedWorklogs, 'id'),
	};
};
