import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	successTitle: {
		id: 'linked-alerts.services.actions.link-selected-alerts.success-title',
		defaultMessage: 'Success!',
		description:
			'The title text in the status flag if alerts are successfully linked to the current issue.',
	},
	successDescription: {
		id: 'linked-alerts.services.actions.link-selected-alerts.success-description',
		defaultMessage: 'View linked alerts',
		description:
			'The link text used to open the view linked alerts modal after successfully linking new alerts',
	},
	errorTitle: {
		id: 'linked-alerts.services.actions.link-selected-alerts.error-title',
		defaultMessage: 'We could not link the selected alerts',
		description:
			'The title text in the status flag if alerts can not be linked to the current issue.',
	},
});
