import React, { type ReactNode } from 'react';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import PreviewCommentContent from './preview-content/index.tsx';

export const IssueCommentEditorReadonly = ({ bodyHtml }: { bodyHtml?: string }) => (
	<PreviewCommentContent bodyHtml={bodyHtml} />
);

export type IssueCommentEditorBoundaryProps = {
	children: ReactNode;
	fallback: ReactNode;
	packageName: string;
};

export const IssueCommentEditorBoundary = ({
	children,
	fallback,
	packageName,
}: IssueCommentEditorBoundaryProps) => (
	<ErrorBoundary id="issue-comment-editor" packageName={packageName} render={() => fallback}>
		<Placeholder name="issue-comment-editor-boundary" fallback={fallback}>
			{children}
		</Placeholder>
	</ErrorBoundary>
);
