import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import type { PolarisRoute } from '../types.tsx';

const storage = createLocalStorageProvider('polaris');

type StorageRouteSlug = PolarisRoute & {
	storageKey?: string;
};

export const setStorageRouteSlug = (route: StorageRouteSlug) => {
	storage.set('routeslug', route);
};

export const getStorageRouteSlugWithLegacySupport = (): StorageRouteSlug | undefined => {
	const localRouteSlug = storage.get('routeslug');

	if (localRouteSlug !== null && localRouteSlug !== undefined) {
		return localRouteSlug;
	}

	const legacyLocalRouteSlug = storage.get('viewslug');

	if (legacyLocalRouteSlug !== null && legacyLocalRouteSlug !== undefined) {
		return {
			section: legacyLocalRouteSlug.section,
			resource: legacyLocalRouteSlug.id,
		};
	}

	return undefined;
};
