import React, {
	type ReactNode,
	useState,
	useRef,
	useEffect,
	useMemo,
	useCallback,
	useContext,
} from 'react';
import { styled } from '@compiled/react';
import throttle from 'lodash/throttle';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useStickyHeaderRegistration } from '@atlassian/jira-issue-header-tracker-service/src/services/sticky-header/index.tsx';
import { getScrollParent } from '@atlassian/jira-issue-view-common-utils/src/dom-util/get-scroll-parent/index.tsx';
import { useIsEmbedMode } from '@atlassian/jira-issue-view-embed-mode/src/index.tsx';
import { issueViewLayers } from '@atlassian/jira-issue-view-layers/src/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { HeaderPopupsStateContext } from './popup-context/index.tsx';

export type Props = {
	/**
	 * Whether it should use the deprecated `getScrollContainer` instead of explicitly
	 * providing a scroll container. We can't rely on the absence of the `scrollContainer`
	 * property itself because it can be provided after the component renders, and that tends
	 * to be the case.
	 * This is kept to ensure compatibility while there are references to this component that
	 * are indirectly referenced in many places, such as the issue app header for mobile mode.
	 */
	shouldUseDeprecatedScrollParent?: boolean;
	children: ReactNode;
	elementId: string;
	/**
	 * The scroll container for the sticky header.
	 */
	scrollContainer?: HTMLElement;
	style?: React.CSSProperties;
};

const STICKY_NAME = 'sticky-header';

const StickyHeader = ({
	shouldUseDeprecatedScrollParent = false,
	scrollContainer,
	elementId,
	children,
	style,
}: Props) => {
	const isEmbedMode = useIsEmbedMode();
	const [shouldShowKeyline, setShouldShowKeyline] = useState(false);
	const [deprecatedScrollParent, setDeprecatedScrollParent] = useState<Element | null>(null);
	const innerElRef = useRef<HTMLElement | null>(null);
	const { registerSticky, deregisterSticky } = useStickyHeaderRegistration();
	const { isOpened } = useContext(HeaderPopupsStateContext);

	const innerEl = useCallback(
		(node: HTMLElement | null) => {
			innerElRef.current = node;
			if (shouldUseDeprecatedScrollParent) {
				setDeprecatedScrollParent(getScrollParent(node));
			}
		},
		[shouldUseDeprecatedScrollParent],
	);

	const container: Element | HTMLElement | null = useMemo(
		() => (shouldUseDeprecatedScrollParent ? deprecatedScrollParent : scrollContainer) ?? null,
		[shouldUseDeprecatedScrollParent, deprecatedScrollParent, scrollContainer],
	);

	useEffect(() => {
		// this element is constantly sticky. Conditional register is not an option
		registerSticky(STICKY_NAME, innerElRef, { marginBottom: extraTopOffset });
		return () => deregisterSticky(STICKY_NAME);
	}, [registerSticky, deregisterSticky]);

	const calculateShouldShowKeyline: () => void = useMemo(
		() =>
			throttle(() => {
				if (container && innerElRef.current) {
					const innerRect = innerElRef.current.getBoundingClientRect();
					const containerRect = container.getBoundingClientRect();
					const containerTop = Math.max(containerRect.top, 0);
					const newShouldShowKeyline = container.scrollTop > 0 && innerRect.top <= containerTop;
					setShouldShowKeyline(newShouldShowKeyline);
				}
			}, 100),
		[container],
	);

	useEffect(() => {
		calculateShouldShowKeyline();
		// FIXME: on MobileView this container will point to a non-correct scrollable parent
		// instead it shall point on body
		container && container.addEventListener('scroll', calculateShouldShowKeyline);

		return () => {
			container && container.removeEventListener('scroll', calculateShouldShowKeyline);
		};
	}, [container, calculateShouldShowKeyline]);

	return (
		<StickyWrapper
			topOffset={0}
			id={elementId}
			showKeyline={shouldShowKeyline}
			ref={innerEl}
			/* eslint-disable-next-line jira/react/no-style-attribute, @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766 */
			style={style}
			isEmbedMode={isEmbedMode}
			data-testid="issue-view-sticky-header-container.sticky-header"
			{...(fg('a11y-jira-team_bento_header_popup_reading_order') && {
				isPopupOpened: isOpened,
			})}
			applyVisualRefreshChanges={isVisualRefreshEnabled() && fg('visual-refresh_drop_2')}
		>
			{children}
		</StickyWrapper>
	);
};

export default StickyHeader;

const ISSUE_HEADER_SHADOW_HEIGHT = 2;
const stickyLineExtraLengthLeft = gridSize;
const extraTopOffset = -1; // without '-1px' - part of underlying page/text is shown sometimes on top of header on scroll

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StickyWrapper = styled.div<{
	topOffset: number;
	showKeyline?: boolean;
	isEmbedMode?: boolean;
	isPopupOpened?: boolean;
	applyVisualRefreshChanges?: boolean;
}>({
	'@supports (position: sticky) or (position: -webkit-sticky)': {
		position: 'sticky',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		background: (props) =>
			props.isEmbedMode
				? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					token('elevation.surface.overlay', colors.N0)
				: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					token('elevation.surface', colors.N0),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		zIndex: ({ isPopupOpened }) =>
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			isPopupOpened ? issueViewLayers.baseLayer : issueViewLayers.stickyHeaderBreadcrumbs,
		paddingLeft: token('space.100', '8px'),
		marginLeft: token('space.negative.100', '-8px'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		top: (props) => `${props.topOffset + extraTopOffset}px`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		boxShadow: (props) =>
			props.showKeyline
				? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					`0 ${token('space.025', '2px')} ${token('color.border', colors.N30)}`
				: undefined,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		paddingBottom: ({ showKeyline, applyVisualRefreshChanges }) =>
			showKeyline && applyVisualRefreshChanges ? token('space.100', '8px') : undefined,

		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		paddingTop: ({ applyVisualRefreshChanges }) =>
			applyVisualRefreshChanges ? token('space.100', '8px') : `${-extraTopOffset}px`,
	},
});
StickyWrapper.displayName = 'StickyWrapper';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { stickyLineExtraLengthLeft, ISSUE_HEADER_SHADOW_HEIGHT };
