/**
 * @generated SignedSource<<144c21b7be8b75eb59e721b328ac35f2>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type voteFieldServiceRelay_issueFieldVoters_UseVoteFieldRelayGenerator$data = {
  readonly fieldId: string;
  readonly id: string;
  readonly type: string;
  readonly vote: {
    readonly count: AGG$Long | null | undefined;
    readonly hasVoted: boolean | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "voteFieldServiceRelay_issueFieldVoters_UseVoteFieldRelayGenerator";
};
export type voteFieldServiceRelay_issueFieldVoters_UseVoteFieldRelayGenerator$key = {
  readonly " $data"?: voteFieldServiceRelay_issueFieldVoters_UseVoteFieldRelayGenerator$data;
  readonly " $fragmentSpreads": FragmentRefs<"voteFieldServiceRelay_issueFieldVoters_UseVoteFieldRelayGenerator">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "voteFieldServiceRelay_issueFieldVoters_UseVoteFieldRelayGenerator",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "concreteType": "JiraVote",
      "kind": "LinkedField",
      "name": "vote",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "count"
        },
        {
          "kind": "ScalarField",
          "name": "hasVoted"
        }
      ]
    }
  ],
  "type": "JiraVotesField"
};

(node as any).hash = "4fbe28968977a0e3b3d7b078aef97cd6";

export default node;
