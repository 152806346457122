import React from 'react';
import Avatar from '@atlaskit/avatar';
import type { MultiSelectValueItemShape } from '@atlassian/jira-issue-internal-field-select/src/multi-select-inline-edit/index.tsx';
import { type AccountId, toAccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';

const avatarSize = '24x24';

type RequestParticipant = {
	displayName: string;
	name?: string;
	accountId: AccountId;
	id: AccountId;
	avatarUrls: {
		['24x24']?: string;
	};
};

type User = {
	displayName: string;
	username?: string;
	accountId: AccountId;
	avatarUrl: string;
	email?: string;
};

type ServerResponse = {
	users: User[];
};

export const transformFromStateValue = (
	stateValues: RequestParticipant[] | null,
): MultiSelectValueItemShape[] => {
	if (!stateValues) {
		return [];
	}
	return stateValues.map((item) => ({
		content: item.displayName,
		value: item.accountId,
		avatarUrl: item.avatarUrls[avatarSize],
		elemBefore: (
			<Avatar
				size="xsmall"
				{...(isVisualRefreshEnabled() ? { borderColor: 'transparent' } : {})}
				src={item.avatarUrls[avatarSize]}
			/>
		),
	}));
};

export const transformToStateValue = (value: MultiSelectValueItemShape[]): RequestParticipant[] =>
	value &&
	value.map((item) => ({
		displayName: item.content,
		id: toAccountId(item.value),
		accountId: toAccountId(item.value),
		avatarUrls: {
			[avatarSize]: item.avatarUrl,
		},
	}));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const transformSuggestions = (response: ServerResponse | null): any[] => {
	const items = (response && response.users) || [];

	return [
		{
			items: items.map((user) => ({
				content: user.displayName,
				value: user.accountId,
				avatarUrl: user.avatarUrl,
				email: user.email,
			})),
		},
	];
};
