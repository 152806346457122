export const SCROLL_TO_ATTACHMENT_COMMENT = 'SCROLL_TO_ATTACHMENT_COMMENT' as const;

export type ScrollToAttachmentCommentAction = {
	type: typeof SCROLL_TO_ATTACHMENT_COMMENT;
};

export const scrollToAttachmentComment = (): ScrollToAttachmentCommentAction => ({
	type: SCROLL_TO_ATTACHMENT_COMMENT,
});

export const SCROLL_TO_ATTACHMENT_WORKLOG = 'SCROLL_TO_ATTACHMENT_WORKLOG' as const;

export type ScrollToAttachmentWorklogAction = {
	type: typeof SCROLL_TO_ATTACHMENT_WORKLOG;
};

export const scrollToAttachmentWorklog = (): ScrollToAttachmentWorklogAction => ({
	type: SCROLL_TO_ATTACHMENT_WORKLOG,
});

export type AttachmentScrollAction =
	| ScrollToAttachmentCommentAction
	| ScrollToAttachmentWorklogAction;
