import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	icon: {
		id: 'issue-field-parent.edit.option.icon',
		defaultMessage: '{issueType} icon',
		description: 'Alt text explainer, i.e. Epic icon',
	},
	doneParentLabel: {
		id: 'issue-field-parent.edit.option.done-parent-label',
		defaultMessage: 'Done',
		description: 'Label visible in parent issues list when parent issue is marked as done.',
	},
});
