// eslint-disable-next-line jira/restricted/react-component-props
import React, { useEffect, useState, useCallback, type ComponentProps } from 'react';
// eslint-disable-next-line jira/restricted/styled-components-migration, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled, { css } from 'styled-components';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { ErrorFlag } from '@atlassian/jira-issue-error-flag/src/index.tsx';
import {
	ReadViewContainer,
	InlineEditContainer,
} from '@atlassian/jira-issue-field-inline-edit/src/styled.tsx';
import { FieldInlineEditStateLess } from '@atlassian/jira-issue-field-inline-edit/src/ui/index.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { DATE_TIME_TEST_ID, DATE_TIME_READ_VIEW_TEST_ID } from '../common/constants.tsx';
import type { JiraDateTimePickerValue } from '../common/types.tsx';
import { useDateTimeField } from '../services/use-date-time-field/index.tsx';
import { DateTimeFieldEditView } from './edit/index.tsx';
import messages from './messages.tsx';
import type { DateTimeFieldProps } from './types.tsx';
import { DateTimeFieldReadView } from './view/index.tsx';

export const ACTION_SUBJECT = 'dateTimeFieldInlineEdit';

// eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression
const nonEditableDateTimeInlineEditContainerStyles = css`
	${'' /* NonEditableMargin overrides */}
	& > div {
		margin: 17px 0 9px ${token('space.025', '2px')};
	}

	${ReadViewContainer} {
		line-height: 1;
	}
`;

type InlineEditDateTimeContainerProps = {
	isEditable?: boolean;
} & ComponentProps<typeof InlineEditContainer>;

// TODO: migrate to object syntax. Autofix is available for many cases. Remove the eslint-disable for @atlaskit/design-system/no-styled-tagged-template-expression to check.
// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InlineEditDateTimeContainer = styled<InlineEditDateTimeContainerProps>(InlineEditContainer)`
	${({ isEditable }) => !isEditable && nonEditableDateTimeInlineEditContainerStyles}
`;

export const DateTimeField = ({
	fieldKey,
	issueKey,
	timeZone,
	isEditing: isForceEditing,
	onFormatDateTime,
	onParseValue,
	onCancel,
	onEdit,
	onEscape,
	onConfirm,
	onEnter,
	onUpdate,
	readView,
	editView,
	noValueText,
	renderAppEditView,
	...rest
}: DateTimeFieldProps) => {
	const [isEditing, setIsEditing] = useState<boolean>(false);

	const onSuccess = useCallback(
		// @ts-expect-error - TS7006 - Parameter 'newValue' implicitly has an 'any' type.
		(newValue) => {
			onUpdate?.(newValue);
			setIsEditing(false);
		},
		[onUpdate],
	);

	const [{ value, error, fieldConfig }, { saveValue, resetError }] = useDateTimeField({
		issueKey: toIssueKey(issueKey),
		fieldKey,
		onSuccess,
	});
	const [updatedValue, setUpdatedValue] = useState<JiraDateTimePickerValue>(value);
	const isFieldEditable = fieldConfig?.isEditable ?? false;

	useEffect(() => {
		if (error) {
			setIsEditing(true);
		}
		if (!isEditing) {
			setUpdatedValue(value);
		}
	}, [error, isEditing, value]);

	const save = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			if (updatedValue !== value) {
				saveValue(updatedValue, null, analyticsEvent);
			} else {
				setIsEditing(false);
			}
			fireUIAnalytics(analyticsEvent);
		},
		[saveValue, updatedValue, value],
	);

	const cancel = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			resetError();
			setIsEditing(false);
			setUpdatedValue(value);
			fireUIAnalytics(analyticsEvent);
		},
		[resetError, value],
	);

	const onCancelRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			cancel(analyticsEvent);
			onCancel?.(analyticsEvent);
		},
		[onCancel, cancel],
	);

	const onConfirmRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			save(analyticsEvent);
			onConfirm?.(analyticsEvent);
		},
		[onConfirm, save],
	);

	const onEditRequested = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			setIsEditing(true);
			fireUIAnalytics(analyticsEvent);
			onEdit?.(analyticsEvent);
		},
		[onEdit],
	);

	const onEscapeRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			cancel(analyticsEvent);
			onEscape?.(analyticsEvent);
		},
		[onEscape, cancel],
	);

	const onChange = useCallback(
		// @ts-expect-error - TS7006 - Parameter 'newValue' implicitly has an 'any' type.
		(newValue) => {
			resetError();
			setUpdatedValue(newValue);
		},
		[resetError, setUpdatedValue],
	);

	const renderReadView = (
		<ReadViewContainer data-testid={DATE_TIME_READ_VIEW_TEST_ID}>
			{readView !== undefined ? (
				readView
			) : (
				<DateTimeFieldReadView
					timeZone={timeZone}
					value={value}
					noValueText={noValueText}
					onFormatDateTime={onFormatDateTime}
				/>
			)}
		</ReadViewContainer>
	);

	const renderEditView = () =>
		renderAppEditView ||
		(editView !== undefined ? (
			editView
		) : (
			<DateTimeFieldEditView
				value={updatedValue}
				onChange={onChange}
				isInvalid={!!error}
				onParseValue={onParseValue}
				timeZone={timeZone}
			/>
		));

	return (
		<>
			{error != null && (
				<ErrorFlag error={error} title={messages.errorTitle} description={messages.errorMessage} />
			)}
			<InlineEditDateTimeContainer isEditable={isFieldEditable}>
				<FieldInlineEditStateLess
					testId={DATE_TIME_TEST_ID}
					fieldId={fg('one_event_rules_them_all_fg') ? fieldKey : undefined}
					areActionButtonsHidden
					actionSubject={ACTION_SUBJECT}
					isEditable={isFieldEditable}
					isEditing={isForceEditing ?? isEditing}
					readView={renderReadView}
					editView={isFieldEditable ? renderEditView() : null}
					onCancel={onCancelRequest}
					onConfirm={onConfirmRequest}
					onEdit={onEditRequested}
					onEscape={onEscapeRequest}
					{...rest}
				/>
			</InlineEditDateTimeContainer>
		</>
	);
};
