import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	expand: {
		id: 'servicedesk-customer-service-issue-view-panel.common.expand-panel.expand',
		defaultMessage: 'Expand',
		description: 'Aria label text for a chevron button that when clicked opens a details panel.',
	},
	collapse: {
		id: 'servicedesk-customer-service-issue-view-panel.common.expand-panel.collapse',
		defaultMessage: 'Collapse',
		description: 'Aria label text for a chevron button that when clicked closes a details panel.',
	},
});
