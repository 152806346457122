import type { PanelDataTypes } from '../../../model/types.tsx';
import type { State } from '../../reducers/types.tsx';
import type { UiState } from '../../reducers/ui/types.tsx';

const getUi = (state: State): UiState => state.ui;

export const getActiveTab = (state: State): PanelDataTypes => getUi(state).activeTab;

export const getSelectedApplicationType = (state: State): string | undefined =>
	getUi(state).selectedApplicationType;

export const isCreatingPullRequestFromDiscovery = (state: State): boolean =>
	getUi(state).isCreatingPullRequestFromDiscovery;
