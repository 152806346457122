import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	majorIncidentOnSuccess: {
		id: 'jira-servicedesk-ai-context-ops-panel.major-incident.major-incident-on-success',
		defaultMessage: 'Incident marked as major',
		description: 'Success toast message when user turned on the major incident flag.',
	},
	majorIncidentOffSuccess: {
		id: 'jira-servicedesk-ai-context-ops-panel.major-incident.major-incident-off-success',
		defaultMessage: 'Major incident toggle turned off',
		description: 'Success toast message when user turned off the major incident flag.',
	},
});
