import React, { useRef, type ComponentType } from 'react';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import fireErrorAnalytics, {
	type AnalyticsPayload,
} from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { lazyAfterPaint, LazySuspense } from '@atlassian/react-loosely-lazy';
import { useHardLimitsContactAdminModal } from './controllers/index.tsx';

const AsyncLimitExceed: ComponentType = lazyAfterPaint(
	() => import(/* webpackChunkName: "jira-limit-exceed-modal" */ './ui/index'),
);

const AsyncLimitExceedContactAdminModal = () => {
	const wasEverOpen = useRef(false);
	const [{ isModalOpen }] = useHardLimitsContactAdminModal();
	wasEverOpen.current = wasEverOpen.current || isModalOpen;

	const onError = (location: string, error: Error) => {
		const errorPayload: AnalyticsPayload = {
			error,
			meta: {
				id: 'limitExceedAdminModalError',
				packageName: 'jiraIssueView',
				teamName: 'bento',
			},
			sendToPrivacyUnsafeSplunk: true,
		};
		return fireErrorAnalytics(errorPayload);
	};

	// Make sure we lazy load the modal only if it was ever open
	if (!wasEverOpen.current) {
		return null;
	}

	return (
		<ErrorBoundary
			id="hard-limit-exceed-modal"
			packageName="jiraHardLimitExceedModal"
			onError={onError}
		>
			<LazySuspense fallback={null}>
				<AsyncLimitExceed />
			</LazySuspense>
		</ErrorBoundary>
	);
};

export { AsyncLimitExceedContactAdminModal };
