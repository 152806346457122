import isEmpty from 'lodash/isEmpty';
// 'react-intl-next' is required for ATLAS-68984
// eslint-disable-next-line jira/restricted/react-intl
import type { IntlShape } from 'react-intl-next';
import type { IEnvironment } from 'relay-runtime';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fg } from '@atlassian/jira-feature-gating';
import { fireOperationalAnalyticsWithoutContext } from '@atlassian/jira-ui-modifications-analytics/src/common/utils/operational-analytics/index.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import { fireOperationalEvent } from '@atlassian/ui-modifications-analytics';
import {
	LOOKUP_SOURCE_ALLOWED_VALUES_PROPERTY,
	LOOKUP_SOURCE_USERS_ASYNC_DATA,
} from '../../../../common/constants.tsx';
import type { TenantContext, ViewContext } from '../../../../common/types/context.tsx';
import type {
	FieldMapFromIframe,
	FieldId,
	Field,
	AppliedFieldsChanges,
} from '../../../../common/types/field.tsx';
import type { FieldChangesMapPublic } from '../../../../common/types/fields/field-changes.tsx';
import type { FormDataPublic } from '../../../../common/types/fields/form-field-data.tsx';
import type { LookupData } from '../../../../common/types/lookup.tsx';
import type { AppId, ChangeId, IssueAdjustmentsState, StoreContainerProps } from '../../types.tsx';
import type { FieldValidationError } from '../../utils/errors/types.tsx';
import { UiModificationsFieldsValidationError } from '../../utils/errors/ui-modifications-field-validation-error.tsx';
import { fieldWasRegistered } from '../../utils/field-was-registered/index.tsx';
import { mapFieldPropertyNamesToInternal as mapFieldPropsFromPublicToInternalShape } from '../../utils/map-field-props-from-public-to-internal-shape/index.tsx';
import { prepareErrorsArray } from '../../utils/prepare-errors-array/index.tsx';
import { transformFieldToIframe } from '../../utils/supported-field-properties/index.tsx';
import { addAppsErrors } from '../app-errors/index.tsx';
import { collectFieldsAppliedChangesHistory } from '../applied-changes-history/collect-history/index.tsx';
import { transformFieldChangesToInternalShape } from './transform-field-changes-to-internal-shape/index.tsx';

type UpdateFieldsPayload = {
	changeId: ChangeId;
	fieldsChanges: FieldMapFromIframe;
	lookupDataFromApi: Exclude<LookupData, typeof LOOKUP_SOURCE_ALLOWED_VALUES_PROPERTY>;
	tenantContext: TenantContext;
	viewContext: ViewContext;
	environment: IEnvironment;
	appId: AppId;
	intl: IntlShape;
	createAnalyticsEvent: CreateUIAnalyticsEvent;
};

/**
 * Updates fields with the provided changes.
 */
export const updateFields =
	({
		changeId,
		fieldsChanges,
		lookupDataFromApi,
		tenantContext,
		viewContext,
		environment,
		appId,
		intl,
		createAnalyticsEvent,
	}: UpdateFieldsPayload): Action<IssueAdjustmentsState, StoreContainerProps, Promise<void>> =>
	async ({ setState, getState, dispatch }, { viewType }) => {
		const appliedChanges = getState().appliedChanges ?? {};
		const formData = getState().formData ?? {};
		const { internalFormMetadata, registeredFields } = getState();

		const { changesToApply, incomingFormData, errorsByField, newAppliedChangesPublic } =
			await Object.keys(fieldsChanges).reduce(
				async (
					acc: Promise<{
						changesToApply: AppliedFieldsChanges;
						newAppliedChangesPublic: FieldChangesMapPublic;
						incomingFormData: FormDataPublic;
						errorsByField: { [key: string]: FieldValidationError[] };
					}>,
					fieldId: FieldId,
				) => {
					// Do not update fields that are not inside initialized data.
					// Validates if the field that comes from Iframe is supported
					// - because we add to formData only the supported ones.
					const fieldData: Field = formData[fieldId];
					const internalMetadata = internalFormMetadata[fieldId];
					const fieldIsRegistered = fieldWasRegistered(registeredFields, changeId, fieldId, appId);
					// Only registered field Ids can be updated.
					if (!fieldIsRegistered || !fieldData) {
						const { fieldType } = fieldData ?? {};
						fg('uim-1972-unregisterd-field-error-handler')
							? dispatch(
									addAppsErrors({
										errors: {
											[appId]: [
												{
													fieldType,
													fieldId,
													type: 'APPLY_CHANGES_FOR_UNREGISTERED_FIELD',
													message: 'Application tried to apply changes to unregistered field',
												},
											],
										},
									}),
								)
							: // eslint-disable-next-line no-console
								console.warn(`UIM tried to apply changes to the unregistered field ${fieldId}`);

						if (fg('analytics-replace-jira-packages')) {
							fireOperationalEvent(createAnalyticsEvent, {
								action: 'unregisteredField',
								actionSubject: 'jiraUiModifications.applyChanges',
								attributes: {
									message: 'Attempt to apply changes to a field that was not registered',
									fieldId,
									viewType,
								},
							});
						} else {
							fireOperationalAnalyticsWithoutContext(
								'applyChanges',
								'unregisteredField',
								viewType,
								{
									message: 'Attempt to apply changes to a field that was not registered',
									fieldId,
								},
							);
						}

						return acc;
					}

					const lookupData: LookupData = {
						[LOOKUP_SOURCE_ALLOWED_VALUES_PROPERTY]: internalMetadata.allowedValues,
						[LOOKUP_SOURCE_USERS_ASYNC_DATA]: lookupDataFromApi[LOOKUP_SOURCE_USERS_ASYNC_DATA],
					};

					// It updates properties in each field to avoid removing unchanged props for
					// selected field by another app or another onChange update.
					// Only supported properties are allowed.
					const supportedFieldChanges = await transformFieldChangesToInternalShape({
						fieldType: fieldData.fieldType,
						fieldId,
						fieldFromIframe: fieldsChanges?.[fieldId],
						lookupData,
						viewType,
						tenantContext,
						viewContext,
						environment,
						intl,
					});

					if (supportedFieldChanges.isValid === false) {
						(await acc).errorsByField[fieldId] = supportedFieldChanges.errors;
						return acc;
					}

					// It converts changes to 'public' format to update the formData
					const publicShapeChanges = transformFieldToIframe(
						supportedFieldChanges.data,
						viewType,
						fieldData.fieldType,
						lookupData,
						fieldsChanges?.[fieldId],
						internalMetadata,
					);

					const transformedFieldChanges = mapFieldPropsFromPublicToInternalShape(
						supportedFieldChanges.data,
						viewType,
						fieldData.fieldType,
					);

					const combinedChangesToApply = {
						...appliedChanges[fieldId],
						...transformedFieldChanges,
					};

					if (!isEmpty(combinedChangesToApply)) {
						(await acc).newAppliedChangesPublic[fieldId] = publicShapeChanges;
						(await acc).changesToApply[fieldId] = combinedChangesToApply;
						(await acc).incomingFormData[fieldId] = {
							...formData[fieldId],
							...publicShapeChanges,
						};
					}

					return acc;
				},
				Promise.resolve({
					changesToApply: { ...appliedChanges },
					incomingFormData: { ...formData },
					newAppliedChangesPublic: {},
					errorsByField: {},
				}),
			);

		if (!isEmpty(errorsByField)) {
			throw new UiModificationsFieldsValidationError('Transformation failed', {
				errors: prepareErrorsArray(errorsByField),
			});
		}

		setState({
			appliedChanges: changesToApply,
			formData: incomingFormData,
		});

		dispatch(
			collectFieldsAppliedChangesHistory({
				appId,
				changeId,
				fieldsChanges: newAppliedChangesPublic,
			}),
		);
	};
