import React, { useState, useRef, useMemo } from 'react';
import uuid from 'uuid';
import type { ValidationPopupProps } from './types.tsx';
import ValidationPopper from './validation-popper/index.tsx';

/**
 * Popup component to display a validation message for inline editable fields. The message will be displayed when the
 * user focuses the trigger attached to this component (which should be an interactive form field), and hidden when the
 * trigger is blurred.
 *
 * Why do we have a custom popup instead of using an existing `@atlaskit` component? There are a couple of caveats from
 * the existing components which makes them unsuitable for our use case:
 * 1. `@atlaskit/popup` - Creates a focus lock within the popup preventing the user from tab navigating to and from the
 * popup content.
 * 2. `@atlaskit/inline-dialog` - Deprecated in favour of `@atlaskit/popup`.
 * 3. `@atlaskit/tooltip` - Does not allow visibility to be controlled via a prop and attaches to mouseover events,
 * which is undesired.
 */
const ValidationPopup = ({
	message,
	placement,
	fallbackPlacements,
	trigger,
}: ValidationPopupProps) => {
	const [isVisuallyHidden, setIsVisuallyHidden] = useState(true);
	const wasFocusReceivedSinceLastBlurRef = useRef(false);
	const triggerRef = useRef<HTMLElement>();
	const onFocus = () => {
		wasFocusReceivedSinceLastBlurRef.current = true;
		setIsVisuallyHidden(false);
	};

	const onBlur = () => {
		wasFocusReceivedSinceLastBlurRef.current = false;
		setTimeout(() => {
			if (!wasFocusReceivedSinceLastBlurRef.current) {
				setIsVisuallyHidden(true);
			}
		}, 0);
	};

	const popupContentId = useMemo(() => `${uuid()}-validation-popup`, []);

	const fieldProps = {
		ref: triggerRef,
		onFocus,
		onBlur,
		'aria-describedby': isVisuallyHidden ? undefined : popupContentId,
	};

	return (
		<>
			{trigger(fieldProps)}
			<ValidationPopper
				id={popupContentId}
				isVisuallyHidden={isVisuallyHidden}
				message={message}
				onFocus={onFocus}
				onBlur={onBlur}
				placement={placement}
				fallbackPlacements={fallbackPlacements}
				referenceElement={triggerRef?.current}
			/>
		</>
	);
};

export default ValidationPopup;
