import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	areTheseRelevant: {
		id: 'smart-related-issues-panel.similar-incidents.feedback.are-these-relevant',
		defaultMessage: 'Are these results relevant?',
		description: 'Feedback footer first line message',
	},
	tellUsMore: {
		id: 'smart-related-issues-panel.similar-incidents.feedback.tell-us-more',
		defaultMessage: 'Tell us more',
		description: 'Label of button which can be clicked to open feedback collector',
	},
	toImproveFutureResultsWithPlaceholder: {
		id: 'smart-related-issues-panel.similar-incidents.feedback.to-improve-future-results-with-placeholder',
		defaultMessage: '{tellUsMore} to improve future results.',
		description: 'Sentence with a placeholder for a button labeled "Tell us more".',
	},
	thanksForFeedback: {
		id: 'smart-related-issues-panel.similar-incidents.feedback.thanks-for-feedback',
		defaultMessage: 'Thanks for your feedback! ',
		description: 'Feedback footer second line message',
	},
	yes: {
		id: 'smart-related-issues-panel.similar-incidents.feedback.yes',
		defaultMessage: 'Yes',
		description: 'Label for yes button on the feedback footer',
	},
	no: {
		id: 'smart-related-issues-panel.similar-incidents.feedback.no',
		defaultMessage: 'No',
		description: 'Label for no button on the feedback footer',
	},
});
