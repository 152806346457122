import React, { useCallback } from 'react';
import memoizeOne from 'memoize-one';
import withFireUiAnalytics from '@atlassian/jira-analytics-web-react/src/components/with-fire-ui-analytics.tsx';
import ComponentWithAnalytics from '@atlassian/jira-analytics-web-react/src/utils/component-with-analytics.tsx';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import type { ChildIssue } from '@atlassian/jira-issue-shared-types/src/common/types/children-issues-type.tsx';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { toIssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { useSortableIssuesActions } from '../../hooks/context.tsx';
import { useChildIssuesSortBy } from '../../hooks/main.tsx';
import {
	getParentKey,
	getIsClassicSubtaskPanel,
	getIsChildrenIssuesPanel,
	getOnNavigateToNewIssue,
	getChildIssuesPanelProjectType,
	getIsSimplifiedProject,
	getOnEditAssociatedIssue,
} from '../../state/context/selectors.tsx';
import {
	cancelOptimisticChild,
	createChildRetry,
	reorderChildrenRequest,
	updateIssue,
	linkingIssueRetry,
} from '../../state/entities/actions.tsx';
import {
	getIncompleteIssues,
	getIssueHierarchyLevel,
	getAllIssues,
} from '../../state/entities/selectors.tsx';
import type { State } from '../../state/types.tsx';
import { getHideDone } from '../../state/ui/selectors.tsx';
import { IssuesList, type WrapperProps } from './view.tsx';

export const ReorderingObserverComponent = (props: WrapperProps) => {
	const { allIssues, onReorderChildren } = props;
	const [, { setSortableIssueIds }] = useSortableIssuesActions();

	const enhancedOnReorderChildren = useCallback(
		// @ts-expect-error - TS7019 - Rest parameter 'args' implicitly has an 'any[]' type.
		(...args) => {
			setSortableIssueIds(allIssues.map((issue) => toIssueId(issue.id)));
			// @ts-expect-error - TS2556 - A spread argument must either have a tuple type or be passed to a rest parameter.
			onReorderChildren(...args);
		},
		[allIssues, onReorderChildren, setSortableIssueIds],
	);
	return <IssuesList {...props} onReorderChildren={enhancedOnReorderChildren} />;
};

type Props = Flow.Diff<
	WrapperProps,
	{
		intl: IntlShape;
	}
>;

const ConnectedIssuesList = (props: Props) => {
	const intl = useIntl();
	const [sortByValue] = useChildIssuesSortBy();
	return <ReorderingObserverComponent {...props} sortBy={sortByValue} intl={intl} />;
};

const mapStateToProps = (state: State) => ({
	allIssues: getAllIssues(state),
	incompleteIssues: getIncompleteIssues(state),
	hideDone: getHideDone(state),
	parentKey: getParentKey(state),
	projectType: getChildIssuesPanelProjectType(state),
	isSimplifiedProject: getIsSimplifiedProject(state),
	isClassicSubtaskPanel: getIsClassicSubtaskPanel(state),
	isChildrenIssuesPanel: getIsChildrenIssuesPanel(state),
	onNavigateToNewIssue: getOnNavigateToNewIssue(state),
	shouldDisplayWorklogGraph:
		getIsClassicSubtaskPanel(state) ||
		(getIsChildrenIssuesPanel(state) && getIssueHierarchyLevel(state) === 0),
	onEditAssociatedIssue: getOnEditAssociatedIssue(state),
});

const mapDispatchToProps = {
	onInlineErrorCancel: cancelOptimisticChild,
	onInlineErrorCreateRetry: createChildRetry,
	onInlineErrorLinkingRetry: linkingIssueRetry,
	onReorderChildren: reorderChildrenRequest,
	onEditIssue: updateIssue,
};

const onEditIssueFactory = memoizeOne(
	(onEditIssue: typeof updateIssue, onEditAssociatedIssue?: (issueKey?: string) => void) =>
		(payload: ChildIssue) => {
			onEditIssue(payload);
			onEditAssociatedIssue?.(payload.issueKey);
		},
);

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps, ownProps: Props) => ({
	...ownProps,
	...stateProps,
	...dispatchProps,
	onEditIssue: onEditIssueFactory(dispatchProps.onEditIssue, stateProps.onEditAssociatedIssue),
});
const ReduxConnectedView = flowWithSafeComponent(
	ComponentWithAnalytics('button', {
		onInlineErrorCancel: 'clicked',
		onInlineErrorCreateRetry: 'clicked',
	}),
	ComponentWithAnalytics('inlineIssue', {
		onNavigateToNewIssue: 'clicked',
		onReorderChildren: 'dragged',
	}),
	withFireUiAnalytics({
		onInlineErrorCancel: 'cancelInlineErrorCreate',
		onInlineErrorCreateRetry: 'retryInlineErrorCreate',
		onNavigateToNewIssue: 'navigateToNewIssue',
		onReorderChildren: 'reorderChildren',
	}),
	// @ts-expect-error - Argument of type 'InferableComponentEnhancerWithProps<{ onEditIssue: (payload: ChildIssue) => void; onInlineErrorCancel: (optimisticId: string) => { type: "state.entities.CANCEL_OPTIMISTIC_CHILD"; payload: { optimisticId: string; }; }; ... 16 more ...; sortBy: SortingOption | null; }, Props>' is not assignable to parameter of type 'FlowStep<WithUIAnalyticsEvent<PropsWithoutRef<Omit<{ [K in keyof PropsWithRef<Omit<{ [K in keyof Props]: K extends "onInlineErrorCreateRetry" | "onInlineErrorCancel" ? (...args: [...Parameters<Props[K]>, UIAnalyticsEvent]) => ReturnType<...> : Props[K]; }, keyof WithAnalyticsEventsProps>>]: K extends "onNavigateToNe...'.
	connect(mapStateToProps, mapDispatchToProps, mergeProps),
)(ConnectedIssuesList);

export default ReduxConnectedView;
