import cloneDeep from 'lodash/cloneDeep';
import type {
	FetchIssuesRequest,
	FetchResponse,
} from '@atlassian/jira-polaris-remote-issue/src/controllers/crud/types.tsx';
import type { IssuesRemote } from '@atlassian/jira-polaris-remote-issue/src/controllers/types.tsx';
import { fireOperationalAnalyticsDeferred } from '@atlassian/jira-product-analytics-bridge';
import type { Action } from '@atlassian/react-sweet-state';
import {
	jpdProjectPageLoad,
	PAGE_LOAD_MARK_LOAD_ISSUES_START,
	PAGE_LOAD_MARK_LOAD_ISSUES_END,
} from '../../../../common/utils/metrics/project.tsx';
import { EMPTY_PROPERTIES, ISSUES_PAGE_LIMIT } from '../../constants.tsx';
import type { State, Props } from '../../types.tsx';

export const loadIssues =
	(): Action<State, Props, Promise<void>> =>
	({ getState, setState }, props) => {
		const { createAnalyticsEvent, issuesRemote, projectId, isSharedView } = props;

		const state = getState();
		const { isSingleIssueLoaded, singleIssueLoadedTimestamp } = state.meta;
		if (state.meta.initialized || state.meta.loading) {
			return Promise.resolve();
		}

		if (state.prefechedIssues) {
			return Promise.resolve();
		}

		setState({
			meta: {
				...state.meta,
				loading: true,
				error: undefined,
			},
		});

		jpdProjectPageLoad.mark(PAGE_LOAD_MARK_LOAD_ISSUES_START);

		const fetchStart = new Date();

		const fetchIssuesPromise = !isSharedView
			? fetchIssuesWithPagination(issuesRemote, {
					archivedFilter: 'ACTIVE_ONLY',
				})
			: issuesRemote.fetch({});

		return fetchIssuesPromise
			.then((allIdeasResponse) => {
				const { issues, total } = allIdeasResponse;

				jpdProjectPageLoad.mark(PAGE_LOAD_MARK_LOAD_ISSUES_END);

				const commentsCount = issues.reduce((acc, issue) => {
					if ('meta' in issue) {
						const count = issue.meta?.comments?.count || 0;
						return acc + count;
					}
					return acc;
				}, 0);

				const issueLinksCount = issues.reduce((acc, issue) => {
					if ('meta' in issue) {
						const count = issue.meta?.issueLinks?.count || 0;
						return acc + count;
					}
					return acc;
				}, 0);

				fireOperationalAnalyticsDeferred(createAnalyticsEvent({}), 'loadIssues success', {
					durationMs: new Date().getTime() - fetchStart.getTime(),
					idsTotal: issues.length,
					projectId,
					commentsCount,
					issueLinksCount,
					responseTotal: total,
					archived: false,
				});

				if (isSingleIssueLoaded) {
					fireOperationalAnalyticsDeferred(
						createAnalyticsEvent({}),
						'loadIssuesWithSingleIssueLoaded success',
						{
							timeElapsedSinceSingleIssueLoaded: Math.round(
								// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
								(new Date().getTime() - singleIssueLoadedTimestamp!) / 1000,
							),
						},
					);
				} else {
					fireOperationalAnalyticsDeferred(
						createAnalyticsEvent({}),
						'loadIssuesWithoutSingleIssueLoaded success',
					);
				}

				setState({
					meta: {
						...getState().meta,
						loading: false,
						error: undefined,
					},
					prefechedIssues: issues,
				});
			})
			.catch((error) => {
				jpdProjectPageLoad.mark(PAGE_LOAD_MARK_LOAD_ISSUES_END);

				fireOperationalAnalyticsDeferred(createAnalyticsEvent({}), 'loadIssues failure', {
					projectId,
					durationMs: new Date().getTime() - fetchStart.getTime(),
				});

				setState({
					meta: {
						...getState().meta,
						loading: true,
						error,
					},
					ids: [],
					properties: cloneDeep(EMPTY_PROPERTIES),
				});
			});
	};

export const loadArchivedIssues =
	(): Action<State, Props, Promise<void>> =>
	({ getState, setState }, props) => {
		const { createAnalyticsEvent, issuesRemote, projectId, onLoadArchivedIssues } = props;

		const state = getState();
		if (
			state.meta.loadingArchivedIssues ||
			state.meta.initializedArchived ||
			state.archivedIssues
		) {
			return Promise.resolve();
		}

		setState({
			meta: {
				...state.meta,
				loadingArchivedIssues: true,
				archivedError: undefined,
			},
		});

		onLoadArchivedIssues();

		jpdProjectPageLoad.mark(PAGE_LOAD_MARK_LOAD_ISSUES_START);

		const fetchStart = new Date();

		return fetchIssuesWithPagination(issuesRemote, {
			archivedFilter: 'ARCHIVED_ONLY',
		})
			.then((allIdeasResponse: FetchResponse) => {
				const { issues, total } = allIdeasResponse;
				fireOperationalAnalyticsDeferred(createAnalyticsEvent({}), 'loadIssues success', {
					durationMs: new Date().getTime() - fetchStart.getTime(),
					idsTotal: issues.length,
					projectId,
					responseTotal: total,
					archived: true,
				});

				setState({
					meta: {
						...getState().meta,
						loadingArchivedIssues: false,
						archivedError: undefined,
					},
					archivedIssues: issues,
				});
			})
			.catch((error: Error) => {
				fireOperationalAnalyticsDeferred(createAnalyticsEvent({}), 'loadIssues failure', {
					projectId,
					durationMs: new Date().getTime() - fetchStart.getTime(),
					archived: true,
				});

				setState({
					meta: {
						...getState().meta,
						loadingArchivedIssues: false,
						archivedError: error,
					},
				});
			});
	};

const fetchIssuesWithPagination = (
	issuesRemote: IssuesRemote,
	params: Partial<FetchIssuesRequest>,
	page = 0,
	lastPage = 0,
): Promise<FetchResponse> =>
	issuesRemote
		.fetch({
			...params,
			startAt: page * ISSUES_PAGE_LIMIT,
			maxResults: ISSUES_PAGE_LIMIT,
		})
		.then((response: FetchResponse) => {
			if (response.total >= ISSUES_PAGE_LIMIT && lastPage > page) {
				return fetchIssuesWithPagination(issuesRemote, params, page + 1).then(
					(nextResponse: FetchResponse) => ({
						total: response.total + nextResponse.total,
						issues: response.issues.concat(nextResponse.issues),
					}),
				);
			}
			return response;
		});
