import React from 'react';
import { useFragment, graphql } from 'react-relay';
import { expVal } from '@atlassian/jira-feature-experiments';
import { EmptyFieldText } from '@atlassian/jira-issue-empty-field-text/src/ui/index.tsx';
import { OptionTag } from '@atlassian/jira-option-color-picker/src/option-tag/index.tsx';
import type { singleSelect_issueFieldSingleSelectReadviewFull_SingleSelectReadView$key as SingleSelectReadViewFragment } from '@atlassian/jira-relay/src/__generated__/singleSelect_issueFieldSingleSelectReadviewFull_SingleSelectReadView.graphql';
import type { SingleSelectReadViewProps } from './types.tsx';

/**
 * The SingleSelectReadView will show a read only view of SingleSelect field.
 * @param props {@link SingleSelectReadViewProps}
 */
export const SingleSelectReadView = (props: SingleSelectReadViewProps) => {
	const { fragmentRef, fieldType } = props;
	const data = useFragment<SingleSelectReadViewFragment>(
		graphql`
			fragment singleSelect_issueFieldSingleSelectReadviewFull_SingleSelectReadView on JiraSingleSelectField
			@argumentDefinitions(
				includeSingleSelectColorField: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/thor-colorful-single-select-milestone1-experiment.relayprovider"
				}
			) {
				fieldOption {
					value
					color
						@include(if: $includeSingleSelectColorField)
						@optIn(to: "JiraColorfulSingleSelect") {
						colorKey
					}
				}
			}
		`,
		fragmentRef,
	);

	if (!data.fieldOption?.value) {
		return <EmptyFieldText />;
	}
	if (
		fieldType === 'com.atlassian.jira.plugin.system.customfieldtypes:select' &&
		expVal(
			'thor_colourful_single_select_milestone1_experiment',
			'isColorfulSingleSelectEnabled',
			false,
		)
	) {
		return (
			<OptionTag name={data.fieldOption.value} color={data?.fieldOption?.color?.colorKey ?? null} />
		);
	}

	return <>{data.fieldOption.value}</>;
};
