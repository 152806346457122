import {
	type ShowLinkIdeaInputAction,
	type HideLinkIdeaInputAction,
	SHOW_LINK_IDEA_INPUT,
	HIDE_LINK_IDEA_INPUT,
} from '../../actions/link-idea-actions.tsx';

type State = {
	showLinkIdeaInput: boolean;
};

export type Action = ShowLinkIdeaInputAction | HideLinkIdeaInputAction;

export const initialState: State = {
	showLinkIdeaInput: false,
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: State = initialState, action: Action): State => {
	switch (action.type) {
		case SHOW_LINK_IDEA_INPUT: {
			return {
				...state,
				showLinkIdeaInput: true,
			};
		}

		case HIDE_LINK_IDEA_INPUT: {
			return {
				...state,
				showLinkIdeaInput: false,
			};
		}

		default: {
			return state;
		}
	}
};
