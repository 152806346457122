import React from 'react';
import truncate from 'lodash/truncate';
import { useMutation, graphql } from 'react-relay';
import type { PayloadError } from 'relay-runtime';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';
import { fg } from '@atlassian/jira-feature-gating';
import { errorFlags, useFlagsService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import {
	fireOperationalAnalytics,
	fireUIAnalytics,
	FireUiAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import type { onboardingMessageJiraDevstatusUserOptOutOfNoDataStateMutation } from '@atlassian/jira-relay/src/__generated__/onboardingMessageJiraDevstatusUserOptOutOfNoDataStateMutation.graphql';
import { isFailureStatusCode } from '@atlassian/jira-software-sla-tracker/src/services/response-to-sla/index.tsx';
import { getStatusCodeFromGraphQLErrors } from '@atlassian/jira-software-sla-tracker/src/services/status-code/index.tsx';
import type { GraphqlError } from '@atlassian/jira-software-swag/src/common/types.tsx';
import { issueAriSelector } from '../../../controllers/context/selectors/index.tsx';
import { onClickDismissLink } from '../../../controllers/dev-summary/actions/index.tsx';
import { hasCreateBranchTargetsSelector } from '../../../controllers/dev-summary/selectors/index.tsx';
import messages from './messages.tsx';
import type { Props, StateProps } from './types.tsx';

export const OnboardingMessage = ({
	issueAri,
	setShowSummarySectionMessageState,
	hasCreateBranchTargets,
}: Props) => {
	const { formatMessage } = useIntl();
	const { showFlag } = useFlagsService();

	const fireCompleteAnalytics = (
		analyticsEvent: UIAnalyticsEvent,
		errors?: ReadonlyArray<Readonly<GraphqlError | PayloadError>> | null,
	) => {
		const statusCode = errors ? getStatusCodeFromGraphQLErrors(errors) : 200;
		const isFailure = isFailureStatusCode(statusCode);

		// response contains graphql errors
		if (errors && errors.length > 0 && isFailure) {
			fireOperationalAnalytics(analyticsEvent, 'optOutOfDevOpsIssuePanelBanner failed', {
				statusCode,
				errorMessage: truncate(errors[0].message, {
					length: 140,
				}),
			});
			showFlag(errorFlags.genericError());

			return;
		}
		fireOperationalAnalytics(analyticsEvent, 'optOutOfDevOpsIssuePanelBanner success', {
			statusCode,
		});
	};

	const [commitMutation] =
		useMutation<onboardingMessageJiraDevstatusUserOptOutOfNoDataStateMutation>(graphql`
			mutation onboardingMessageJiraDevstatusUserOptOutOfNoDataStateMutation(
				$issueId: ID!
				$bannerType: JiraDevOpsIssuePanelBannerType!
			) {
				jira {
					devOps {
						dismissDevOpsIssuePanelBanner(input: { issueId: $issueId, bannerType: $bannerType }) {
							success
							errors {
								message
								extensions {
									__typename
									... on GenericMutationErrorExtension {
										statusCode
									}
								}
							}
						}
					}
				}
			}
		`);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onDismiss = (_: any, analyticsEvent: any) => {
		const bannerType = 'ISSUE_KEY_ONBOARDING';
		// optimistic update.
		setShowSummarySectionMessageState(false);
		fireUIAnalytics(analyticsEvent, 'dismissDevOpsIssuePanelBanner');
		commitMutation({
			variables: { issueId: issueAri, bannerType },
			onCompleted: (data, errors) => {
				setShowSummarySectionMessageState(
					// @ts-expect-error(Suppressed for relay bump) - 'data.jira' is possibly 'null' or 'undefined'. | 'data.jira.devOps' is possibly 'null' or 'undefined'. | 'data.jira.devOps.dismissDevOpsIssuePanelBanner' is possibly 'null' or 'undefined'.
					data ? !data.jira.devOps.dismissDevOpsIssuePanelBanner.success : true,
				);
				fireCompleteAnalytics(analyticsEvent, errors);
			},
			onError: () => {
				setShowSummarySectionMessageState(true);
				showFlag(errorFlags.genericError());

				fireOperationalAnalytics(analyticsEvent, 'dismissDevOpsIssuePanelBanner failed', {
					statusCode: 'unknown',
					errorMessage: 'networkError',
				});
			},
		});
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onLearnMoreLinkClick = (_: any, analyticsEvent: any) => {
		fireUIAnalytics(analyticsEvent, 'devPannelOnboardingBannerLink');
	};

	const sectionTitle = formatMessage(messages.sectionTitle);
	const learnMoreUrl =
		'https://support.atlassian.com/jira-software-cloud/docs/reference-issues-in-your-development-work/';
	const sectionFirstAction = formatMessage(messages.sectionFirstAction);
	const sectionSecondAction = formatMessage(messages.sectionSecondAction);
	const sectionMessageCanCreate = formatMessage(
		fg('jira-issue-terminology-refresh-m3')
			? messages.sectionMessageCanCreateIssueTermRefresh
			: messages.sectionMessageCanCreate,
	);
	const sectionMessageCantCreate = formatMessage(
		fg('jira-issue-terminology-refresh-m3')
			? messages.sectionMessageCantCreateIssueTermRefresh
			: messages.sectionMessageCantCreate,
	);
	return (
		<>
			<FireUiAnalytics eventName="viewed devPanelOnboardingBanner" />
			<SectionMessage
				testId="development-summary-development-field.ui.summary.onboarding-message.onboarding-section-message"
				title={sectionTitle}
				appearance="discovery"
				actions={[
					<SectionMessageAction key="dismiss" onClick={onDismiss}>
						{sectionSecondAction}
					</SectionMessageAction>,
				]}
			>
				{hasCreateBranchTargets ? sectionMessageCanCreate : sectionMessageCantCreate}
				&nbsp;
				<Button
					key="learnMore"
					appearance="link"
					spacing="none"
					href={learnMoreUrl}
					target="_blank"
					onClick={onLearnMoreLinkClick}
				>
					{sectionFirstAction}
				</Button>
			</SectionMessage>
		</>
	);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapStateToProps = (state: any): StateProps => ({
	hasCreateBranchTargets: hasCreateBranchTargetsSelector(state),
	issueAri: issueAriSelector(state),
});

const mapDispatchToProps = {
	setShowSummarySectionMessageState: onClickDismissLink,
};

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingMessage);
