import type { ProjectType } from '@atlassian/jira-common-constants/src/index.tsx';

export type GetProjectSettingsUrl = {
	projectKey: string;
	projectType?: ProjectType | string;
};

export const SUMMARY_VIEW_SOURCE = 'summaryView' as const;
export const LIST_VIEW_SOURCE = 'listView' as const;
export const LIST_VIEW_NO_RESULTS_SOURCE = 'listViewNoResults' as const;
export const ISSUE_VIEW_SOURCE = 'issueView' as const;

export type ConfigureStatusSource =
	| typeof SUMMARY_VIEW_SOURCE
	| typeof LIST_VIEW_SOURCE
	| typeof LIST_VIEW_NO_RESULTS_SOURCE
	| typeof ISSUE_VIEW_SOURCE;

export type IssueType = {
	id: string;
	iconUrl: string;
	name: string;
	description: string;
};

export type Project = {
	label: string;
	value: string;
	iconUrl: string;
	projectKey: string;
	projectType: ProjectType;
	isSimplified: boolean;
	classificationTags?: string[];
};
