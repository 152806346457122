import type { User as Voter } from '@atlassian/jira-issue-shared-types/src/common/types/user-type.tsx';
import type { StoreApi, LoggedInUserAction } from './types.tsx';

export const setVoters =
	(voters: Voter[]) =>
	({ setState }: StoreApi) => {
		setState({
			voters,
		});
	};

export const setLoggedInUserAction =
	(loggedInUserAction: LoggedInUserAction) =>
	({ setState }: StoreApi) => {
		setState({
			loggedInUserAction,
		});
	};
