import {
	CORE_PROJECT,
	SOFTWARE_PROJECT,
	SERVICE_DESK_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import {
	useProjectKey,
	useProjectType,
} from '@atlassian/jira-project-context-service/src/main.tsx';

/**
 * Hook to get the project type of the current issue.
 * Use this hook only in a context where an issue & project are already available Eg: issue view.
 *
 * @returns {string | undefined} The project type of the current issue.
 */
export const useIssueProjectType = () => {
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	return useProjectType(projectKey);
};

/**
 * Hook to check if the current issue is a JSM (Jira Service Management) issue.
 * Use this hook only in a context where an issue & project are already available Eg: issue view.
 *
 * @returns {boolean} True if the current issue is a JSM issue, false otherwise.
 */
export const useIsJsmIssue = () => {
	const projectType = useIssueProjectType();
	return projectType === SERVICE_DESK_PROJECT;
};

/**
 * Hook to check if the current issue is a software issue.
 * Use this hook only in a context where an issue & project are already available Eg: issue view.
 *
 * @returns {boolean} True if the current issue is a software issue, false otherwise.
 */
export const useIsSoftwareIssue = () => {
	const projectType = useIssueProjectType();
	return projectType === SOFTWARE_PROJECT;
};

/**
 * Hook to check if the current issue is a business issue.
 * Use this hook only in a context where an issue & project are already available Eg: issue view.
 *
 * @returns {boolean} True if the current issue is a business issue, false otherwise.
 */
export const useIsBusinessIssue = () => {
	const projectType = useIssueProjectType();
	return projectType === CORE_PROJECT;
};
