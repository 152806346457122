import {
	createStore,
	createHook,
	createContainer,
	type Action,
} from '@atlassian/react-sweet-state';

type State = {
	issueId: string;
	issueKey: string;
	projectId: string;
	projectKey: string;
};

type ContainerProps = State;

const actions = {
	updateIssueContext:
		(): Action<State, ContainerProps> =>
		({ setState }, { issueId, issueKey, projectId, projectKey }: ContainerProps) => {
			setState({
				issueId,
				issueKey,
				projectId,
				projectKey,
			});
		},
};

const handlers = {
	onInit: actions.updateIssueContext,
	onContainerUpdate: actions.updateIssueContext,
};

export const AutodevIssueContextStoreContainer = createContainer<ContainerProps>();

const initialState: State = {
	issueId: '',
	issueKey: '',
	projectId: '',
	projectKey: '',
};

const AutodevIssueContextStore = createStore({
	initialState,
	actions,
	handlers,
	name: 'AutodevIssueContextStore',
	containedBy: AutodevIssueContextStoreContainer,
});

export const useAutodevIssueContext = createHook(AutodevIssueContextStore);
