import { Component } from 'react';
import type { BM3Metric } from '@atlassian/jira-providers-spa-apdex-analytics/src/submit-apdex-mark/types.tsx';
import { addBM3SSRDoneAsFMPToInteraction } from '@atlassian/jira-providers-spa-apdex-analytics/src/submit-apdex-mark/utils/add-bm3-config-to-interaction.tsx';
import { trackBM3FeatureFlagsAccessed } from '@atlassian/jira-providers-spa-apdex-analytics/src/submit-apdex-mark/utils/track-bm3-feature-flags-accessed.tsx';
import { embedPageIssueViewLoad, fullPageIssueViewLoad } from './perf.config.tsx';

type StartMetricProps = {
	issueKey: String;
	isEmbedMode: boolean;
};

const startPageLoad = (isEmbedMode: boolean) => {
	let metric = fullPageIssueViewLoad;
	if (isEmbedMode) {
		metric = embedPageIssueViewLoad;
	}
	metric.startPageLoad();
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	addBM3SSRDoneAsFMPToInteraction(metric as unknown as BM3Metric);
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	trackBM3FeatureFlagsAccessed(metric as unknown as BM3Metric);
};

// eslint-disable-next-line jira/react/no-class-components
export class StartIssueMetric extends Component<StartMetricProps> {
	constructor(props: StartMetricProps) {
		super(props);

		/**
		 * This needs to be in a constructor (or equivalent) in order to run
		 * synchronously during the inital render of the component. Running
		 * "correctly" according to react in eg. a useEffect block will make
		 * the start time for metrics incorrect.
		 */
		startPageLoad(props.isEmbedMode);
	}

	componentDidUpdate(prevProps: StartMetricProps) {
		if (this.props.issueKey !== prevProps.issueKey) {
			startPageLoad(this.props.isEmbedMode);
		}
	}

	render() {
		return null;
	}
}
