import { UFOExperience, ExperienceTypes, ExperiencePerformanceTypes } from '@atlassian/ufo';

export const entitlementFieldGicMountExperience = new UFOExperience(
	'jsm-cs-entitlement-custom-field-gic-mount',
	{
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	},
);

export const entitlementFieldDataFetchExperience = new UFOExperience(
	'jsm-cs-entitlement-custom-field-data-fetch',
	{
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	},
);

export const entitlementFieldIssueViewMountExperience = new UFOExperience(
	'jsm-cs-entitlement-custom-field-issue-view-mount',
	{
		type: ExperienceTypes.Load,
		performanceType: ExperiencePerformanceTypes.PageSegmentLoad,
	},
);

export const editEntitlementFieldIssueViewExperience = new UFOExperience(
	'jsm-cs-entitlement-custom-field-issue-view-edit',
	{
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	},
);
