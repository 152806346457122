import {
	SUMMARY_ITEMS,
	type SUMMARY_ITEM,
} from '@atlassian/jira-development-summary-common/src/common/constants.tsx';
import {
	issueIdSelector,
	issueStatusCategorySelector,
	projectIdSelector,
	projectTypeSelector,
	isSimplifiedProjectSelector,
	analyticsSourceSelector,
	isSiteAdminSelector,
} from '../../context/selectors/index.tsx';
import { summaryItemSelector } from '../../dev-summary/selectors/index.tsx';
import type { State } from '../../types.tsx';
import type { AnalyticsCountBySummaryItem } from './types.tsx';

const getSummaryItemCounts = (state: State): AnalyticsCountBySummaryItem =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	SUMMARY_ITEMS.reduce<Record<string, any>>(
		(items: AnalyticsCountBySummaryItem, itemKey: SUMMARY_ITEM) => {
			const summaryItem = summaryItemSelector(state, itemKey);
			return summaryItem && typeof summaryItem.count === 'number'
				? {
						// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
						...items,
						[`${itemKey}Count`]: summaryItem.count,
					}
				: items;
		},
		{},
	);

const updateInstanceTypeCounts = (
	instanceTypeCounts: AnalyticsCountBySummaryItem,
	// @ts-expect-error - TS2304 - Cannot find name 'ByInstanceType'.
	byInstanceType: ByInstanceType,
) =>
	Object.keys(byInstanceType).reduce((counts, typeKey) => {
		const typeCountKey = `${typeKey}Count`;
		const updatedCount =
			typeof counts[typeCountKey] === 'number'
				? counts[typeCountKey] + byInstanceType[typeKey]
				: byInstanceType[typeKey];
		return {
			// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
			...counts,
			[typeCountKey]: updatedCount,
		};
	}, instanceTypeCounts);

const getInstanceTypeCounts = (state: State): AnalyticsCountBySummaryItem =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	SUMMARY_ITEMS.reduce<Record<string, any>>(
		(instanceTypeCounts: AnalyticsCountBySummaryItem, itemKey: SUMMARY_ITEM) => {
			const summaryItem = summaryItemSelector(state, itemKey);
			return summaryItem &&
				summaryItem.byInstanceType &&
				typeof summaryItem.byInstanceType === 'object'
				? updateInstanceTypeCounts(instanceTypeCounts, summaryItem.byInstanceType)
				: instanceTypeCounts;
		},
		{},
	);

export const getAnalyticsIssueData = (state: State) => ({
	...getSummaryItemCounts(state),
	...getInstanceTypeCounts(state),
	issueId: issueIdSelector(state),
	issueStatusCategory: issueStatusCategorySelector(state),
	projectId: projectIdSelector(state),
	projectType: projectTypeSelector(state),
	nextGenProject: isSimplifiedProjectSelector(state),
	analyticsSource: analyticsSourceSelector(state),
	isSiteAdmin: isSiteAdminSelector(state),
	isEmptyStateEnabled: true,
});
