import React from 'react';
import type { StringValue } from '@atlassian/jira-issue-field-summary-common/src/services/types.tsx';
import { SummaryHeading } from '../../common/ui/styled.tsx';

export type Props = {
	value: StringValue;
};
export const SummaryView = ({ value }: Props) => {
	const displayValue = value === null ? '' : value.toString();
	const testId = 'issue.views.issue-base.foundation.summary.heading';
	return <SummaryHeading data-testid={testId}>{displayValue}</SummaryHeading>;
};
