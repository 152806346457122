import get from 'lodash/get';
import { useEditField } from '@atlassian/jira-issue-field-base/src/services/edit-field-service/main.tsx';
import { useFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';

type Props = {
	issueKey: IssueKey;
	fieldKey: string;
};

export const useMessageField = ({ issueKey, fieldKey }: Props) => {
	// useFieldConfig now returns an object { value, loading } instead of just the fieldConfig value
	// if possible when touching this, try and refactor to pass the new object instead of just the value
	const [{ value: fieldConfig }] = useFieldConfig(issueKey, fieldKey);

	const fieldType = get(fieldConfig, 'schema.type', 'string');

	const [{ value, error }] = useEditField<string | null, null>({
		fieldKey,
		issueKey,
		fieldType,
		initialValue: null,
	});

	return [{ value, error, fieldConfig }] as const;
};
