// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	label: {
		id: 'issue.log-time.modal.work-description.label',
		defaultMessage: 'Work description',
		description: 'Label for the work description editor',
	},
});
