import React from 'react';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { TextFieldReadView } from '../../text-field/read-view/index.tsx';
import type { ReadViewProps } from '../../types.tsx';

export const EmailFieldReadView = ({
	render,
	value,
	getValueContainer,
	inInlineEdit,
}: ReadViewProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	return (
		<TextFieldReadView
			getValueContainer={getValueContainer}
			render={
				render ||
				(() => {
					if (value) {
						return (
							<a
								href={`mailto:${value}`}
								onClick={() => {
									fireUIAnalytics(createAnalyticsEvent({}), 'customDetailFieldLink clicked', {
										fieldType: 'EMAIL',
									});
								}}
							>
								{value}
							</a>
						);
					}

					return null;
				})
			}
			inInlineEdit={inInlineEdit}
		/>
	);
};
