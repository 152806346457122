export const createRelativeIssueUrl = (fullIssueUrl?: string | null) => {
	if (!fullIssueUrl) {
		return '#';
	}
	try {
		const url = new URL(fullIssueUrl);
		return url.pathname;
	} catch {
		return '#';
	}
};
