/**
 * @generated SignedSource<<d4b2b6def2f38c94552b7e1209f6993c>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraClassificationLevelSource = "ISSUE" | "PROJECT" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type editViewPopup_issueClassificationBadge_ClassificationEditViewPopup$data = {
  readonly classificationLevel: {
    readonly color: {
      readonly colorKey: string | null | undefined;
    } | null | undefined;
    readonly definition: string | null | undefined;
    readonly id: string;
    readonly name: string | null | undefined;
  } | null | undefined;
  readonly classificationLevelSource: JiraClassificationLevelSource | null | undefined;
  readonly classificationLevels: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly color: {
          readonly colorKey: string | null | undefined;
        } | null | undefined;
        readonly definition: string | null | undefined;
        readonly id: string;
        readonly name: string | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly defaultClassificationLevel: {
    readonly color: {
      readonly colorKey: string | null | undefined;
    } | null | undefined;
    readonly definition: string | null | undefined;
    readonly id: string;
    readonly name: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "editViewPopup_issueClassificationBadge_ClassificationEditViewPopup";
};
export type editViewPopup_issueClassificationBadge_ClassificationEditViewPopup$key = {
  readonly " $data"?: editViewPopup_issueClassificationBadge_ClassificationEditViewPopup$data;
  readonly " $fragmentSpreads": FragmentRefs<"editViewPopup_issueClassificationBadge_ClassificationEditViewPopup">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "ScalarField",
    "name": "id"
  },
  {
    "kind": "ScalarField",
    "name": "name"
  },
  {
    "concreteType": "JiraColor",
    "kind": "LinkedField",
    "name": "color",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "name": "colorKey"
      }
    ]
  },
  {
    "kind": "ScalarField",
    "name": "definition"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "editViewPopup_issueClassificationBadge_ClassificationEditViewPopup",
  "selections": [
    {
      "concreteType": "JiraClassificationLevel",
      "kind": "LinkedField",
      "name": "classificationLevel",
      "plural": false,
      "selections": (v0/*: any*/)
    },
    {
      "args": [
        {
          "kind": "Literal",
          "name": "filterByCriteria",
          "value": {
            "filterByStatus": [
              "PUBLISHED"
            ],
            "filterByType": [
              "USER"
            ]
          }
        },
        {
          "kind": "Literal",
          "name": "first",
          "value": 10
        }
      ],
      "concreteType": "JiraClassificationLevelConnection",
      "kind": "LinkedField",
      "name": "classificationLevels",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraClassificationLevelEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraClassificationLevel",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v0/*: any*/)
            }
          ]
        }
      ],
      "storageKey": "classificationLevels(filterByCriteria:{\"filterByStatus\":[\"PUBLISHED\"],\"filterByType\":[\"USER\"]},first:10)"
    },
    {
      "kind": "ScalarField",
      "name": "classificationLevelSource"
    },
    {
      "concreteType": "JiraClassificationLevel",
      "kind": "LinkedField",
      "name": "defaultClassificationLevel",
      "plural": false,
      "selections": (v0/*: any*/)
    }
  ],
  "type": "JiraDataClassificationField"
};
})();

(node as any).hash = "c6693c3c7f5578be27613e98e4890b27";

export default node;
