import type { DefaultUnit } from '@atlassian/jira-issue-shared-types/src/common/types/jira-settings-type.tsx';

// This is required to map the default time-tracking unit coming from the server (gira endpoint BENTO: 3334) to client
const DEFAULT_TIME_TRACKING_UNIT_CLIENT = {
	minute: 'm',
	hour: 'h',
	day: 'd',
	week: 'w',
} as const;

export const getDefaultTimeTrackingUnit = (serverDefaultUnit: DefaultUnit): string =>
	DEFAULT_TIME_TRACKING_UNIT_CLIENT[serverDefaultUnit] || DEFAULT_TIME_TRACKING_UNIT_CLIENT.minute;
