import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	errorStateTitle: {
		id: 'issue-attachments-table.error.error-state-title',
		defaultMessage: "We couldn't load your attachments",
		description:
			'The title to use when displaying the attachments dialog and there is an error fetching the attachments',
	},
	errorStateDescription: {
		id: 'issue-attachments-table.error.error-state-description',
		defaultMessage: 'Check your network connection, then try reloading your attachments.',
		description:
			'The description to use when displaying the attachments dialog and there is an error fetching the attachments',
	},
	primaryButton: {
		id: 'issue-attachments-table.error.primary-button',
		defaultMessage: 'Reload Attachments',
		description: 'The text on the button used to reload attachments in case of error',
	},
});
