import React, { type ReactNode, type ComponentType } from 'react';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import { ModalBoundary } from '@atlassian/jira-modal/src/ui/modal-boundary/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';
import type { Props as CloneIssueDialogProps } from './index.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const AsyncLazyCloneIssueDialog = lazy<ComponentType<CloneIssueDialogProps>>(
	() => import(/* webpackChunkName: "async-jira-clone-issue-dialog" */ './index'),
);
export const AsyncCloneIssueDialog = (props: CloneIssueDialogProps) => (
	<ErrorBoundary id="clone-issue-dialog" packageName="jira-clone-issue-dialog">
		<Placeholder name="jira-clone-issue-dialog" fallback={null}>
			<AsyncLazyCloneIssueDialog {...props} />
		</Placeholder>
	</ErrorBoundary>
);

export type CloneIssueDialogBoundaryProps = {
	children: ReactNode;
	packageName: string;
};

export const CloneIssueDialogBoundary = ({
	children,
	packageName,
}: CloneIssueDialogBoundaryProps) => (
	<ModalBoundary id="clone-issue-dialog" packageName={packageName}>
		{children}
	</ModalBoundary>
);
