import { createSelector } from 'reselect';
import every from 'lodash/every';
import set from 'lodash/set';
import memoizeOne from 'memoize-one';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import {
	AVG_ROLLUP,
	MEDIAN_ROLLUP,
	SUM_ROLLUP,
} from '@atlassian/jira-polaris-domain-field/src/rollup/constants.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { IssuesRemote } from '@atlassian/jira-polaris-remote-issue/src/controllers/types.tsx';
import { jiraNumberMapping } from '../../../../field/mapping/number/index.tsx';
import type { PropertyMaps, State } from '../../../types.tsx';
import { numberComparator } from '../../comparators/index.tsx';
import { removePropertyValue } from '../common/remove-utils.tsx';
import type { FieldMapping } from '../types.tsx';
import { isFieldInsights, roundOneDecimal } from './utils.tsx';

const isPresentationalNumberField = (field: Field) =>
	field.type === FIELD_TYPES.RATING || field.type === FIELD_TYPES.CHECKBOX;

const numberMappingInternal = (issuesRemote: IssuesRemote, field: Field): FieldMapping<number> => {
	const valueAccessor: FieldMapping<number>['valueAccessor'] = (state, props, issueId) => {
		if (field.formula === undefined) {
			const fieldValue = state.properties.number[field.key]?.[issueId];

			return isPresentationalNumberField(field) && fieldValue === undefined ? 0 : fieldValue;
		}

		let val;
		const numberDynamicProperties = state.dynamicProperties.numberValue;
		if (numberDynamicProperties[field.key] !== undefined) {
			val = numberDynamicProperties[field.key](issueId)?.(state, props);
		}

		if (field.type === FIELD_TYPES.CHECKBOX) {
			return val !== undefined && val >= 1 ? 1 : 0;
		}

		// Not displaying Infinity. It's the result of a division by 0, so not possible.
		if (val === Infinity) return undefined;

		return val;
	};

	const valueAccessorToExport: FieldMapping<string>['valueAccessorToExport'] = (
		state,
		props,
		issueId,
	) => {
		const stringNumber = isFieldInsights(field)
			? state.properties.insights?.[issueId]?.length
			: valueAccessor(state, props, issueId);
		return `${roundOneDecimal(stringNumber || 0)}`;
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const EMPTY: Record<string, any> = {};

	return {
		...jiraNumberMapping(issuesRemote, field),
		getAllValues: (state, props) => {
			if (field.formula === undefined) {
				return state.properties.number[field.key] ? state.properties.number[field.key] : {};
			}
			if (state.dynamicProperties.numberValues[field.key] === undefined) {
				return EMPTY;
			}
			return state.dynamicProperties.numberValues[field.key](state, props);
		},
		setMutable: (maps, issueId, value) => set(maps, ['number', field.key, issueId], value),
		// @ts-expect-error(PARTIAL_RECORD) TS2322 - Type '(maps: PropertyMaps, issueId: LocalIssueId, value?: number) => { number: { [x: string]: Record<string, number> | { [x: string]: number | undefined; }; }; string: PropertyMap<string>; stringList: PropertyMap<...>; ... 15 more ...; commentsMetadata: CommentsMetadataMap; }' is not assignable to type '(arg1: PropertyMaps, arg2: string, arg3: number | undefined) => PropertyMaps'.
		setImmutable: (maps: PropertyMaps, issueId: LocalIssueId, value?: number) => {
			if (maps.number[field.key] && maps.number[field.key][issueId] === value) {
				return maps;
			}
			return {
				...maps,
				number: {
					...maps.number,
					[field.key]: {
						...maps.number[field.key],
						[issueId]: value,
					},
				},
			};
		},
		remove: (maps: PropertyMaps, issueId: LocalIssueId) =>
			removePropertyValue(field.key, maps, issueId, 'number'),
		modifyImmutableIfMultiValueField: (maps: PropertyMaps) => maps,
		comparator: numberComparator,
		valueAccessor,
		valueAccessorToExport,
		getGroupIdentitiesSelector: (fieldKey, issueIdsSelector) =>
			createSelector(
				issueIdsSelector,
				(state: State) => state,
				(ids, state) =>
					ids.reduce((result, issueId) => {
						const value = valueAccessor(state, state.containerProps, issueId);
						return Object.assign(result, {
							[issueId]: value !== undefined ? [{ groupIdentity: `${value}`, value }] : [],
						});
					}, {}),
			),
		getGroupIdentities: (state, props, issueId) => {
			const value = valueAccessor(state, props, issueId);
			if (value !== undefined) {
				return [{ groupIdentity: `${value}`, value }];
			}
			return [];
		},
		getRollupOperations: () => [AVG_ROLLUP, MEDIAN_ROLLUP, SUM_ROLLUP],
		allowEmptyGroup: field.type !== FIELD_TYPES.RATING && field.type !== FIELD_TYPES.CHECKBOX,
		getLabel: (groupIdentity, value) => String(value),
		getFilter: (filter) => {
			if (filter.type === 'NUMBER' && filter.field === field.key) {
				return (value: undefined | number) =>
					every(filter.values, ({ operator, numericValue }) => {
						switch (operator) {
							case 'EQ':
								return (
									numericValue === value ||
									(isPresentationalNumberField(field) && value === 0 && numericValue === undefined)
								);
							case 'NEQ':
								return numericValue !== value;
							case 'LT':
								return numericValue !== undefined && value !== undefined && value < numericValue;
							case 'GT':
								return numericValue !== undefined && value !== undefined && value > numericValue;
							case 'LTE':
								return numericValue !== undefined && value !== undefined && value <= numericValue;
							case 'GTE':
								return numericValue !== undefined && value !== undefined && value >= numericValue;
							default:
								return true;
						}
					});
			}
			return undefined;
		},
	};
};

export const numberMapping = memoizeOne(numberMappingInternal);
