import { CONTENT_TOO_LARGE } from '@atlassian/jira-common-constants/src/http-status-codes.tsx';
import type FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import type {
	ApplicationInput,
	PersistedWebLink,
} from '@atlassian/jira-issue-view-common-types/src/web-links-type.tsx';
import {
	type Href,
	type WebLinkId,
	toWebLinkId,
} from '@atlassian/jira-shared-types/src/general.tsx';

// SHOW_CREATE_WEB_LINK_FORM
export const SHOW_CREATE_WEB_LINK_FORM = 'SHOW_CREATE_WEB_LINK_FORM' as const;

export const showCreateWebLinkForm = () => ({
	type: SHOW_CREATE_WEB_LINK_FORM,
});

// HIDE_CREATE_WEB_LINK_FORM
export const HIDE_CREATE_WEB_LINK_FORM = 'HIDE_CREATE_WEB_LINK_FORM' as const;

export const hideCreateWebLinkForm = () => ({
	type: HIDE_CREATE_WEB_LINK_FORM,
});

// CREATE_WEB_LINK_REQUEST
export const CREATE_WEB_LINK_REQUEST = 'CREATE_WEB_LINK_REQUEST' as const;

export const createWebLinkRequest = (
	href: Href,
	linkText: string,
	iconUrl: Href,
	application: ApplicationInput | undefined,
) => ({
	type: CREATE_WEB_LINK_REQUEST,
	payload: {
		optimisticId: toWebLinkId(`optimistic-web-link-${Date.now()}`),
		href,
		linkText,
		iconUrl,
		application,
	},
});

// CREATE_WEB_LINK_SUCCESS
export const CREATE_WEB_LINK_SUCCESS = 'CREATE_WEB_LINK_SUCCESS' as const;

export const createWebLinkSuccess = (optimisticId: WebLinkId, webLink: PersistedWebLink) => ({
	type: CREATE_WEB_LINK_SUCCESS,
	payload: { optimisticId, webLink },
});

// CREATE_WEB_LINK_FAILED
export const CREATE_WEB_LINK_FAILED = 'CREATE_WEB_LINK_FAILED' as const;

export const CREATE_WEB_LINK_LIMIT_EXCEEDED = 'CREATE_WEB_LINK_LIMIT_EXCEEDED' as const;

export const createWebLinkFailed = (optimisticId: WebLinkId, error: FetchError) => {
	if (error.statusCode === CONTENT_TOO_LARGE) {
		return {
			type: CREATE_WEB_LINK_LIMIT_EXCEEDED,
			payload: { optimisticId },
		};
	}

	return {
		type: CREATE_WEB_LINK_FAILED,
		payload: { optimisticId },
	};
};

// CREATE_WEB_LINK_RETRY
export const CREATE_WEB_LINK_RETRY = 'CREATE_WEB_LINK_RETRY' as const;

export const createWebLinkRetry = (optimisticId: WebLinkId) => ({
	type: CREATE_WEB_LINK_RETRY,
	payload: { optimisticId },
});

// DELETE_WEB_LINK_REQUEST
export const DELETE_WEB_LINK_REQUEST = 'DELETE_WEB_LINK_REQUEST' as const;

export const deleteWebLinkRequest = (id: WebLinkId) => ({
	type: DELETE_WEB_LINK_REQUEST,
	payload: { id },
});

// DELETE_WEB_LINK_SUCCESS
export const DELETE_WEB_LINK_SUCCESS = 'DELETE_WEB_LINK_SUCCESS' as const;

export const deleteWebLinkSuccess = (id: WebLinkId) => ({
	type: DELETE_WEB_LINK_SUCCESS,
	payload: { id },
});

// DELETE_WEB_LINK_FAILED
export const DELETE_WEB_LINK_FAILED = 'DELETE_WEB_LINK_FAILED' as const;

export const deleteWebLinkFailed = (id: WebLinkId) => ({
	type: DELETE_WEB_LINK_FAILED,
	payload: { id },
});

// CANCEL_FAILED_WEB_LINK_DELETION
export const CANCEL_FAILED_WEB_LINK_DELETION = 'CANCEL_FAILED_WEB_LINK_DELETION' as const;

export const cancelFailedWebLinkDeletion = (id: WebLinkId) => ({
	type: CANCEL_FAILED_WEB_LINK_DELETION,
	payload: { id },
});

// DELETE_OPTIMISTIC_WEB_LINK
export const DELETE_OPTIMISTIC_WEB_LINK = 'DELETE_OPTIMISTIC_WEB_LINK' as const;

export const deleteOptimisticWebLink = (id: WebLinkId) => ({
	type: DELETE_OPTIMISTIC_WEB_LINK,
	payload: { id },
});

export type ShowCreateWebLinkFormAction = ReturnType<typeof showCreateWebLinkForm>;
export type HideCreateWebLinkFormAction = ReturnType<typeof hideCreateWebLinkForm>;

export type CreateWebLinkFailed = ReturnType<typeof createWebLinkFailed>;
export type CreateWebLinkRequest = ReturnType<typeof createWebLinkRequest>;
export type CreateWebLinkSuccess = ReturnType<typeof createWebLinkSuccess>;
export type CreateWebLinkRetry = ReturnType<typeof createWebLinkRetry>;

export type DeleteWebLinkRequest = ReturnType<typeof deleteWebLinkRequest>;
export type DeleteWebLinkSuccess = ReturnType<typeof deleteWebLinkSuccess>;
export type DeleteWebLinkFailed = ReturnType<typeof deleteWebLinkFailed>;
export type DeleteWebLinkCancel = ReturnType<typeof cancelFailedWebLinkDeletion>;
export type DeleteOptimisticWebLink = ReturnType<typeof deleteOptimisticWebLink>;

export type WebLinkAction =
	| ShowCreateWebLinkFormAction
	| HideCreateWebLinkFormAction
	| CreateWebLinkRequest
	| CreateWebLinkSuccess
	| CreateWebLinkFailed
	| CreateWebLinkRetry
	| DeleteWebLinkRequest
	| DeleteWebLinkSuccess
	| DeleteWebLinkFailed
	| DeleteOptimisticWebLink;
