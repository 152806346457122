import { createHook, createStore, createContainer } from '@atlassian/react-sweet-state';
import * as actions from './actions.tsx';
import type { State } from './types.tsx';

export type Actions = typeof actions;

export const initialState: State = {
	sortableIssueIds: [],
	sortBy: null,
};

export const store = createStore<State, Actions>({
	name: 'child-issues',
	initialState,
	actions,
});

const getSortableIssueIds = (state: State) => state.sortableIssueIds;
const getSortBy = (state: State) => state.sortBy;

export const useSortableIssueIdsStore = createHook(store, {
	selector: getSortableIssueIds,
});

export const useChildIssuesStoreSortBy = createHook(store, {
	selector: getSortBy,
});

export const useSortableIssuesActions = createHook(store, {
	selector: null,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ChildIssuesContainer = createContainer<State, Actions, Record<any, any>>(store);
