import React, { type ReactNode, type ComponentType, useCallback } from 'react';
import { styled } from '@compiled/react';
import { useGlobalRefStoreActions } from '@atlassian/jira-refs-store/src/index.tsx';

export type TargetProps = {
	ref: (arg1: HTMLElement | undefined | null) => void;
	['data-testid']?: string; // http://go/data-testid
};

export type Props = {
	id: string;
	children: ReactNode;
	TargetComponent?: ComponentType<TargetProps>;
};

/**
 * @deprecated This component is deprecated as part of JIG-1315 due to the top level
 * SpotlightManager in Jira Frontend. Atlaskit SpotlightTarget is now usable in
 * Jira Frontend in place of GlobalSpotlightTarget.
 * Please use import { SpotlightTarget } from '@atlaskit/onboarding' instead.
 * NOTE: GlobalSpotlightTarget may also cause OOM errors while using redux-devtools
 * due to the component's usage of global refs to attach spotlights to targets
 */
const GlobalSpotlightTargetDeprecated = (props: Props) => {
	const { id, children, TargetComponent } = props;

	const [, { register }] = useGlobalRefStoreActions();
	const onRef = useCallback(
		(ref: HTMLElement | null | undefined) => {
			register(id, ref);
		},
		[register, id],
	);

	const targetProps = {
		ref: onRef,
		...{ 'data-testid': `ref-spotlight-target-${props.id}` },
	};

	return TargetComponent ? (
		<TargetComponent {...targetProps}>{children}</TargetComponent>
	) : (
		<InheritDisplay {...targetProps}>{children}</InheritDisplay>
	);
};

export default GlobalSpotlightTargetDeprecated;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InheritDisplay = styled.div({
	display: 'inherit',
	flexDirection: 'inherit',
	alignItems: 'inherit',
	justifyContent: 'inherit',
});
