import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import type { Storage } from '@atlassian/jira-browser-storage-providers/src/types.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { trackOrLogClientError } from '@atlassian/jira-issue-view-common-utils/src/errors/index.tsx';
import {
	issueKeySelector,
	accountIdloggedInUserSelector,
} from '../common/state/selectors/context-selector.tsx';
import {
	deleteDraftSuccess,
	deleteDraftError,
	type DeleteDraftAction,
	DELETE_DRAFT_REQUEST,
} from './draft-actions.tsx';
import { getDraftKey, getFieldNameFromKey, createHashKey } from './draft-utils.tsx';

let localStorage: Storage;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const deleteDraftAndHashUsingFieldId = (state: State, fieldId: any) => {
	const issueKey = issueKeySelector(state);

	const loggedInAccountId = accountIdloggedInUserSelector(state);
	const draftKey = getDraftKey(loggedInAccountId, issueKey, fieldId);

	const hashKey = createHashKey(draftKey);
	localStorage.remove(draftKey);
	localStorage.remove(hashKey);
	return Observable.of(deleteDraftSuccess(fieldId));
};

const deleteDraftAndHashUsingDraftKey = (draftKey: string) => {
	const hashKey = createHashKey(draftKey);
	localStorage.remove(draftKey);
	localStorage.remove(hashKey);
	return Observable.of(deleteDraftSuccess(draftKey));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const deleteDraft = (action: any, state: State) => {
	const {
		payload: { fieldId, draftKey },
	} = action;
	if (fieldId) {
		return deleteDraftAndHashUsingFieldId(state, fieldId);
	}

	const fieldName = getFieldNameFromKey(draftKey);
	if (draftKey) {
		return deleteDraftAndHashUsingDraftKey(fieldName).catch((error) => {
			trackOrLogClientError('issue.draft.delete', 'Failed to delete a draft.', error);
			return Observable.of(deleteDraftError(fieldName, error));
		});
	}

	return Observable.empty<never>();
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const errorHandler = (error: any) => Observable.of(deleteDraftError(null, error));

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<DeleteDraftAction>, store: MiddlewareAPI<State>) => {
	localStorage = createLocalStorageProvider('issue-drafts');
	return action$
		.ofType(DELETE_DRAFT_REQUEST)
		.mergeMap((action) => deleteDraft(action, store.getState()))
		.catch((error) => errorHandler(error));
};
