import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';

// CHANGEBOARDING_TOUR_BEGIN
export const CHANGEBOARDING_TOUR_BEGIN = 'CHANGEBOARDING_TOUR_BEGIN' as const;

export const changeboardingTourBegin = (timestamp: number) => ({
	type: CHANGEBOARDING_TOUR_BEGIN,
	payload: timestamp,
});

export type ChangeboardingTourBegin = ReturnType<typeof changeboardingTourBegin>;

// CHANGEBOARDING_TOUR_END
export const CHANGEBOARDING_TOUR_END = 'CHANGEBOARDING_TOUR_END' as const;

export const changeboardingTourEnd = () => ({
	type: CHANGEBOARDING_TOUR_END,
});

// CHANGEBOARDING_BANNER_DISMISS
export const CHANGEBOARDING_BANNER_DISMISS = 'CHANGEBOARDING_BANNER_DISMISS' as const;

export const changeboardingBannerDismiss = (timestamp: number) => ({
	type: CHANGEBOARDING_BANNER_DISMISS,
	payload: timestamp,
});

// CHANGEBOARDING_BANNER_SEEN
export const CHANGEBOARDING_BANNER_SEEN = 'CHANGEBOARDING_BANNER_SEEN' as const;

export const changeboardingBannerSeen = (
	currentSeenTimes: number,
	firstSeenTimestamp?: number,
) => ({
	type: CHANGEBOARDING_BANNER_SEEN,
	payload: {
		currentSeenTimes,
		firstSeenTimestamp,
	},
});

// OPT_OUT_REQUEST
export const OPT_OUT_REQUEST = 'OPT_OUT_REQUEST' as const;

export const changeboardingOptOutRequest = (payload: { analyticsEvent: UIAnalyticsEvent }) => ({
	type: OPT_OUT_REQUEST,
	payload,
});

export type ChangeboardingAction =
	| ChangeboardingTourBegin
	| ReturnType<typeof changeboardingTourEnd>
	| ReturnType<typeof changeboardingBannerDismiss>
	| ReturnType<typeof changeboardingOptOutRequest>;
