import {
	UFOExperience,
	ConcurrentExperience,
	ExperienceTypes,
	ExperiencePerformanceTypes,
} from '@atlassian/ufo';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { UFOExperience } from '@atlassian/ufo';

export const attachmentsUploadCredentialsExperience = new UFOExperience(
	'jsm-issue-view-attachments-upload-credentials',
	{
		type: ExperienceTypes.Operation,
		performanceType: ExperiencePerformanceTypes.Custom,
	},
);

export const attachmentsReadCredentialsExperience = new UFOExperience(
	'jsm-issue-view-attachments-read-credentials',
	{
		type: ExperienceTypes.Operation,
		performanceType: ExperiencePerformanceTypes.Custom,
	},
);

export const concurrentAttachmentUploadExperience = new ConcurrentExperience(
	'jsm-issue-view-attachments-upload',
	{
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	},
);

export const activityItemsExperience = new UFOExperience('jsm-activity-items', {
	type: ExperienceTypes.Load,
	performanceType: ExperiencePerformanceTypes.PageSegmentLoad,
});
