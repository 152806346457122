import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { useEditField } from '@atlassian/jira-issue-field-base/src/services/edit-field-service/main.tsx';
import { useFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import type { FieldConfiguration } from '@atlassian/jira-issue-field-base/src/services/field-config-service/types.tsx';
import {
	getFieldType,
	isRichTextSupported,
} from '@atlassian/jira-issue-view-common-utils/src/fields/index.tsx';
import { DESCRIPTION_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { emptyAdfObject } from '@atlassian/jira-rich-content/src/common/adf-parsing-utils.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { RichTextProps, RichTextValue } from './types.tsx';

export const savePlaintext = async <T,>(
	issueKey: IssueKey,
	fieldKey: string,
	newValue: T,
	_baseUrl: string,
) =>
	performPutRequest(`/rest/api/2/issue/${issueKey}/`, {
		body: JSON.stringify({
			fields: {
				[fieldKey]: newValue,
			},
		}),
	});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getEmptyValueForField = (fieldConfig?: FieldConfiguration<unknown> | null): any =>
	isRichTextSupported(fieldConfig) ? emptyAdfObject : '';

const useRichTextField = <T extends string | typeof DESCRIPTION_TYPE>({
	issueKey,
	fieldKey,
	onSuccess,
	onFailure,
}: RichTextProps<T>) => {
	const [fieldConfig] = useFieldConfig(issueKey, fieldKey);
	const fieldType = getFieldType(fieldConfig.value) || 'string';

	const [{ value, error }, { saveValue, resetError }] = useEditField<RichTextValue<T>, null>({
		fieldKey,
		issueKey,
		fieldType,
		initialValue: getEmptyValueForField(fieldConfig?.value),
		onSuccess,
		onFailure,
		...(!isRichTextSupported(fieldConfig?.value) ? { saveField: savePlaintext } : {}),
	});

	return [
		{
			value,
			error,
			fieldConfig,
		},
		{ saveValue, resetError },
	] as const;
};

export type ParagraphProps = RichTextProps;

export const useParagraphField = (props: RichTextProps) => useRichTextField(props);

export type DescriptionProps = Omit<RichTextProps<typeof DESCRIPTION_TYPE>, 'fieldKey'>;

export const useDescriptionField = (props: DescriptionProps) =>
	useRichTextField({ ...props, fieldKey: DESCRIPTION_TYPE });
