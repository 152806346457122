import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';

export const fetchLoomUserPermissions = async (cloudId: string) => {
	const response = await fetchJson<{ permitted: boolean }>('/gateway/api/permissions/permitted', {
		method: 'POST',
		body: JSON.stringify({
			resourceId: `ari:cloud:loom::site/${cloudId}`,
			permissionId: 'write', // TODO: Check permission structure of loom and product id in perms endpoint.
		}),
		headers: {
			Accept: 'application/json',
		},
	});
	return response;
};
