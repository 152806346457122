import { colors as themeColors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import messages from './messages.tsx';
import type { OptionColorMeta } from './types.tsx';

// Grey
const N100 = '#F0F1F4';
const N200 = 'rgba(9, 30, 66, 0.14)';
const N300 = themeColors.N60A;
const N400 = '#44546F';
const N500 = themeColors.N800;

// Purple
const P100 = '#F3F0FF';
const P200 = '#DFD8FD';
const P300 = '#9F8FEF';
const P400 = '#5E4DB2';
const P500 = '#352C63';

// Blue
const B100 = '#E9F2FF';
const B200 = '#CCE0FF';
const B300 = '#579DFF';
const B400 = '#0055CC';
const B500 = '#09326C';

// Teal
const T100 = '#E3FAFC';
const T200 = '#C1F0F5';
const T300 = '#60C6D2';
const T400 = '#206B74';
const T500 = '#1D474C';

// Green
const G100 = '#DFFCF0';
const G200 = '#BAF3DB';
const G300 = '#4BCE97';
const G400 = '#216E4E';
const G500 = '#164B35';

// Lime
const L100 = '#EEFBDA';
const L200 = '#D3F1A7';
const L300 = '#94C748';
const L400 = '#4C6B1F';
const L500 = '#37471F';

// Yellow
const Y100 = '#FFF7D6';
const Y200 = themeColors.Y75;
const Y300 = '#E2B203';
const Y400 = '#7F5F01';
const Y500 = '#533F04';

// Orange
const O100 = '#FFF4E5';
const O200 = '#FFE2BD';
const O300 = '#FAA53D';
const O400 = '#974F0C';
const O500 = '#5F3811';

// Red
const R100 = '#FFEDEB';
const R200 = '#FFD2CC';
const R300 = '#F87462';
const R400 = '#AE2A19';
const R500 = '#601E16';

// Magenta
const M100 = '#FFECF8';
const M200 = '#FDD0EC';
const M300 = '#F797D2';
const M400 = '#943D73';
const M500 = '#50253F';

export const GREY_LIGHTEST_META: OptionColorMeta = {
	name: 'GREY_LIGHTEST',
	backgroundColor: token('color.background.neutral', N100),
	textColor: token('color.text.accent.gray.bolder', themeColors.N900),
	messageLabel: messages.greyLightest,
};

export const GREY_LIGHTER_META: OptionColorMeta = {
	name: 'GREY_LIGHTER',
	backgroundColor: token('color.background.neutral.hovered', N200),
	textColor: token('color.text.accent.gray.bolder', themeColors.N900),
	messageLabel: messages.greyLighter,
};

export const GREY_META: OptionColorMeta = {
	name: 'GREY',
	backgroundColor: token('color.background.neutral.pressed', N300),
	textColor: token('color.text.accent.gray.bolder', themeColors.N900),
	messageLabel: messages.grey,
};

export const GREY_DARKER_META: OptionColorMeta = {
	name: 'GREY_DARKER',
	backgroundColor: token('color.background.neutral.bold', N400),
	textColor: token('color.text.inverse', themeColors.N0),
	messageLabel: messages.greyDarker,
};

export const GREY_DARKEST_META: OptionColorMeta = {
	name: 'GREY_DARKEST',
	backgroundColor: token('color.background.neutral.bold.pressed', N500),
	textColor: token('color.text.inverse', themeColors.N0),
	messageLabel: messages.greyDarkest,
};

export const PURPLE_LIGHTEST_META: OptionColorMeta = {
	name: 'PURPLE_LIGHTEST',
	backgroundColor: token('color.background.accent.purple.subtlest', P100),
	textColor: token('color.text.accent.purple.bolder', P500),
	messageLabel: messages.purpleLightest,
};

export const PURPLE_LIGHTER_META: OptionColorMeta = {
	name: 'PURPLE_LIGHTER',
	backgroundColor: token('color.background.accent.purple.subtler', P200),
	textColor: token('color.text.accent.purple.bolder', P500),
	messageLabel: messages.purpleLighter,
};

export const PURPLE_META: OptionColorMeta = {
	name: 'PURPLE',
	backgroundColor: token('color.background.accent.purple.subtle', P300),
	textColor: token('color.text.accent.purple.bolder', P500),
	messageLabel: messages.purple,
};

export const PURPLE_DARKER_META: OptionColorMeta = {
	name: 'PURPLE_DARKER',
	backgroundColor: token('color.text.accent.purple', P400),
	textColor: token('color.text.inverse', themeColors.N0),
	messageLabel: messages.purpleDarker,
};

export const PURPLE_DARKEST_META: OptionColorMeta = {
	name: 'PURPLE_DARKEST',
	backgroundColor: token('color.text.accent.purple.bolder', P500),
	textColor: token('color.text.inverse', themeColors.N0),
	messageLabel: messages.purpleDarkest,
};

export const BLUE_LIGHTEST_META: OptionColorMeta = {
	name: 'BLUE_LIGHTEST',
	backgroundColor: token('color.background.accent.blue.subtlest', B100),
	textColor: token('color.text.accent.blue.bolder', B500),
	messageLabel: messages.blueLightest,
};

export const BLUE_LIGHTER_META: OptionColorMeta = {
	name: 'BLUE_LIGHTER',
	backgroundColor: token('color.background.accent.blue.subtler', B200),
	textColor: token('color.text.accent.blue.bolder', B500),
	messageLabel: messages.blueLighter,
};

export const BLUE_META: OptionColorMeta = {
	name: 'BLUE',
	backgroundColor: token('color.background.accent.blue.subtle', B300),
	textColor: token('color.text.accent.blue.bolder', B500),
	messageLabel: messages.blue,
};

export const BLUE_DARKER_META: OptionColorMeta = {
	name: 'BLUE_DARKER',
	backgroundColor: token('color.text.accent.blue', B400),
	textColor: token('color.text.inverse', themeColors.N0),
	messageLabel: messages.blueDarker,
};

export const BLUE_DARKEST_META: OptionColorMeta = {
	name: 'BLUE_DARKEST',
	backgroundColor: token('color.text.accent.blue.bolder', B500),
	textColor: token('color.text.inverse', themeColors.N0),
	messageLabel: messages.blueDarkest,
};

export const TEAL_LIGHTEST_META: OptionColorMeta = {
	name: 'TEAL_LIGHTEST',
	backgroundColor: token('color.background.accent.teal.subtlest', T100),
	textColor: token('color.text.accent.teal.bolder', T500),
	messageLabel: messages.tealLightest,
};

export const TEAL_LIGHTER_META: OptionColorMeta = {
	name: 'TEAL_LIGHTER',
	backgroundColor: token('color.background.accent.teal.subtler', T200),
	textColor: token('color.text.accent.teal.bolder', T500),
	messageLabel: messages.tealLighter,
};

export const TEAL_META: OptionColorMeta = {
	name: 'TEAL',
	backgroundColor: token('color.background.accent.teal.subtle', T300),
	textColor: token('color.text.accent.teal.bolder', T500),
	messageLabel: messages.teal,
};

export const TEAL_DARKER_META: OptionColorMeta = {
	name: 'TEAL_DARKER',
	backgroundColor: token('color.text.accent.teal', T400),
	textColor: token('color.text.inverse', themeColors.N0),
	messageLabel: messages.tealDarker,
};

export const TEAL_DARKEST_META: OptionColorMeta = {
	name: 'TEAL_DARKEST',
	backgroundColor: token('color.text.accent.teal.bolder', T500),
	textColor: token('color.text.inverse', themeColors.N0),
	messageLabel: messages.tealDarkest,
};

export const GREEN_LIGHTEST_META: OptionColorMeta = {
	name: 'GREEN_LIGHTEST',
	backgroundColor: token('color.background.accent.green.subtlest', G100),
	textColor: token('color.text.accent.green.bolder', G500),
	messageLabel: messages.greenLightest,
};

export const GREEN_LIGHTER_META: OptionColorMeta = {
	name: 'GREEN_LIGHTER',
	backgroundColor: token('color.background.accent.green.subtler', G200),
	textColor: token('color.text.accent.green.bolder', G500),
	messageLabel: messages.greenLighter,
};

export const GREEN_META: OptionColorMeta = {
	name: 'GREEN',
	backgroundColor: token('color.background.accent.green.subtle', G300),
	textColor: token('color.text.accent.green.bolder', G500),
	messageLabel: messages.green,
};

export const GREEN_DARKER_META: OptionColorMeta = {
	name: 'GREEN_DARKER',
	backgroundColor: token('color.background.accent.green.bolder', G400),
	textColor: token('color.text.inverse', themeColors.N0),
	messageLabel: messages.greenDarker,
};

export const GREEN_DARKEST_META: OptionColorMeta = {
	name: 'GREEN_DARKEST',
	backgroundColor: token('color.text.accent.green.bolder', G500),
	textColor: token('color.text.inverse', themeColors.N0),
	messageLabel: messages.greenDarkest,
};

export const LIME_LIGHTEST_META: OptionColorMeta = {
	name: 'LIME_LIGHTEST',
	backgroundColor: token('color.background.accent.lime.subtlest', L100),
	textColor: token('color.text.accent.lime.bolder', L500),
	messageLabel: messages.limeLightest,
};

export const LIME_LIGHTER_META: OptionColorMeta = {
	name: 'LIME_LIGHTER',
	backgroundColor: token('color.background.accent.lime.subtler', L200),
	textColor: token('color.text.accent.lime.bolder', L500),
	messageLabel: messages.limeLighter,
};

export const LIME_META: OptionColorMeta = {
	name: 'LIME',
	backgroundColor: token('color.background.accent.lime.subtle', L300),
	textColor: token('color.text.accent.lime.bolder', L500),
	messageLabel: messages.lime,
};

export const LIME_DARKER_META: OptionColorMeta = {
	name: 'LIME_DARKER',
	backgroundColor: token('color.text.accent.lime', L400),
	textColor: token('color.text.inverse', themeColors.N0),
	messageLabel: messages.limeDarker,
};
export const LIME_DARKEST_META: OptionColorMeta = {
	name: 'LIME_DARKEST',
	backgroundColor: token('color.text.accent.lime.bolder', L500),
	textColor: token('color.text.inverse', themeColors.N0),
	messageLabel: messages.limeDarkest,
};

export const YELLOW_LIGHTEST_META: OptionColorMeta = {
	name: 'YELLOW_LIGHTEST',
	backgroundColor: token('color.background.accent.yellow.subtlest', Y100),
	textColor: token('color.text.accent.yellow.bolder', Y500),
	messageLabel: messages.yellowLightest,
};
export const YELLOW_LIGHTER_META: OptionColorMeta = {
	name: 'YELLOW_LIGHTER',
	backgroundColor: token('color.background.accent.yellow.subtler', Y200),
	textColor: token('color.text.accent.yellow.bolder', Y500),
	messageLabel: messages.yellowLighter,
};

export const YELLOW_META: OptionColorMeta = {
	name: 'YELLOW',
	backgroundColor: token('color.background.accent.yellow.subtle', Y300),
	textColor: token('color.text.accent.yellow.bolder', Y500),
	messageLabel: messages.yellow,
};

export const YELLOW_DARKER_META: OptionColorMeta = {
	name: 'YELLOW_DARKER',
	backgroundColor: token('color.text.accent.yellow', Y400),
	textColor: token('color.text.inverse', themeColors.N0),
	messageLabel: messages.yellowDarker,
};

export const YELLOW_DARKEST_META: OptionColorMeta = {
	name: 'YELLOW_DARKEST',
	backgroundColor: token('color.text.accent.yellow.bolder', Y500),
	textColor: token('color.text.inverse', themeColors.N0),
	messageLabel: messages.yellowDarkest,
};

export const ORANGE_LIGHTEST_META: OptionColorMeta = {
	name: 'ORANGE_LIGHTEST',
	backgroundColor: token('color.background.accent.orange.subtlest', O100),
	textColor: token('color.text.accent.orange.bolder', O500),
	messageLabel: messages.orangeLightest,
};

export const ORANGE_LIGHTER_META: OptionColorMeta = {
	name: 'ORANGE_LIGHTER',
	backgroundColor: token('color.background.accent.orange.subtler', O200),
	textColor: token('color.text.accent.orange.bolder', O500),
	messageLabel: messages.orangeLighter,
};

export const ORANGE_META: OptionColorMeta = {
	name: 'ORANGE',
	backgroundColor: token('color.background.accent.orange.subtle', O300),
	textColor: token('color.text.accent.orange.bolder', O500),
	messageLabel: messages.orange,
};

export const ORANGE_DARKER_META: OptionColorMeta = {
	name: 'ORANGE_DARKER',
	backgroundColor: token('color.background.accent.orange.bolder', O400),
	textColor: token('color.text.inverse', themeColors.N0),
	messageLabel: messages.orangeDarker,
};

export const ORANGE_DARKEST_META: OptionColorMeta = {
	name: 'ORANGE_DARKEST',
	backgroundColor: token('color.text.accent.orange.bolder', O500),
	textColor: token('color.text.inverse', themeColors.N0),
	messageLabel: messages.orangeDarkest,
};

export const RED_LIGHTEST_META: OptionColorMeta = {
	name: 'RED_LIGHTEST',
	backgroundColor: token('color.background.accent.red.subtlest', R100),
	textColor: token('color.text.accent.red.bolder', R500),
	messageLabel: messages.redLightest,
};

export const RED_LIGHTER_META: OptionColorMeta = {
	name: 'RED_LIGHTER',
	backgroundColor: token('color.background.accent.red.subtler', R200),
	textColor: token('color.text.accent.red.bolder', R500),
	messageLabel: messages.redLighter,
};

export const RED_META: OptionColorMeta = {
	name: 'RED',
	backgroundColor: token('color.background.accent.red.subtle', R300),
	textColor: token('color.text.accent.red.bolder', R500),
	messageLabel: messages.red,
};

export const RED_DARKER_META: OptionColorMeta = {
	name: 'RED_DARKER',
	backgroundColor: token('color.background.accent.red.bolder', R400),
	textColor: token('color.text.inverse', themeColors.N0),
	messageLabel: messages.redDarker,
};

export const RED_DARKEST_META: OptionColorMeta = {
	name: 'RED_DARKEST',
	backgroundColor: token('color.text.accent.red.bolder', R500),
	textColor: token('color.text.inverse', themeColors.N0),
	messageLabel: messages.redDarkest,
};

export const MAGENTA_LIGHTEST_META: OptionColorMeta = {
	name: 'MAGENTA_LIGHTEST',
	backgroundColor: token('color.background.accent.magenta.subtlest', M100),
	textColor: token('color.text.accent.magenta.bolder', R500),
	messageLabel: messages.magentaLightest,
};

export const MAGENTA_LIGHTER_META: OptionColorMeta = {
	name: 'MAGENTA_LIGHTER',
	backgroundColor: token('color.background.accent.magenta.subtler', M200),
	textColor: token('color.text.accent.magenta.bolder', R500),
	messageLabel: messages.magentaLighter,
};

export const MAGENTA_META: OptionColorMeta = {
	name: 'MAGENTA',
	backgroundColor: token('color.background.accent.magenta.subtle.hovered', M300),
	textColor: token('color.text.accent.magenta.bolder', R500),
	messageLabel: messages.magenta,
};

export const MAGENTA_DARKER_META: OptionColorMeta = {
	name: 'MAGENTA_DARKER',
	backgroundColor: token('color.text.accent.magenta', M400),
	textColor: token('color.text.inverse', themeColors.N0),
	messageLabel: messages.magentaDarker,
};

export const MAGENTA_DARKEST_META: OptionColorMeta = {
	name: 'MAGENTA_DARKEST',
	backgroundColor: token('color.text.accent.magenta.bolder', M500),
	textColor: token('color.text.inverse', themeColors.N0),
	messageLabel: messages.magentaDarkest,
};

export const CHECK_MARK_COLOR_DARK = token('color.text.inverse', themeColors.N0);
export const CHECK_MARK_COLOR_LIGHT = token('color.text', themeColors.N500);

export const OPTION_COLOR_THEMES: OptionColorMeta[] = [
	GREY_LIGHTEST_META,
	PURPLE_LIGHTEST_META,
	BLUE_LIGHTEST_META,
	TEAL_LIGHTEST_META,
	GREEN_LIGHTEST_META,
	LIME_LIGHTEST_META,
	YELLOW_LIGHTEST_META,
	ORANGE_LIGHTEST_META,
	RED_LIGHTEST_META,
	MAGENTA_LIGHTEST_META,
	GREY_LIGHTER_META,
	PURPLE_LIGHTER_META,
	BLUE_LIGHTER_META,
	TEAL_LIGHTER_META,
	GREEN_LIGHTER_META,
	LIME_LIGHTER_META,
	YELLOW_LIGHTER_META,
	ORANGE_LIGHTER_META,
	RED_LIGHTER_META,
	MAGENTA_LIGHTER_META,
	GREY_META,
	PURPLE_META,
	BLUE_META,
	TEAL_META,
	GREEN_META,
	LIME_META,
	YELLOW_META,
	ORANGE_META,
	RED_META,
	MAGENTA_META,
	GREY_DARKER_META,
	PURPLE_DARKER_META,
	BLUE_DARKER_META,
	TEAL_DARKER_META,
	GREEN_DARKER_META,
	LIME_DARKER_META,
	YELLOW_DARKER_META,
	ORANGE_DARKER_META,
	RED_DARKER_META,
	MAGENTA_DARKER_META,
	GREY_DARKEST_META,
	PURPLE_DARKEST_META,
	BLUE_DARKEST_META,
	TEAL_DARKEST_META,
	GREEN_DARKEST_META,
	LIME_DARKEST_META,
	YELLOW_DARKEST_META,
	ORANGE_DARKEST_META,
	RED_DARKEST_META,
	MAGENTA_DARKEST_META,
];
export const OPTION_COLOR_THEMES_COLORFUL_SINGLE_SELECT: OptionColorMeta[] = [
	PURPLE_META,
	YELLOW_META,
	GREEN_META,
	TEAL_META,
	RED_META,
	ORANGE_META,
	MAGENTA_META,
	GREY_DARKER_META,
	GREY_META,
];
export const OPTION_COLOR_THEMES_COLORFUL_SINGLE_SELECT_MAP: Record<string, OptionColorMeta> = {};

OPTION_COLOR_THEMES_COLORFUL_SINGLE_SELECT.forEach((option) => {
	OPTION_COLOR_THEMES_COLORFUL_SINGLE_SELECT_MAP[option.name] = option;
});
export const OPTION_COLOR_THEMES_MAP: Record<string, OptionColorMeta> = {};
OPTION_COLOR_THEMES.forEach((option) => {
	OPTION_COLOR_THEMES_MAP[option.name] = option;
});
