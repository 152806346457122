/**
 * @generated SignedSource<<84beb611f2ebc4a2b8aa6a1395f71177>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type richTextField_issueViewLayoutRichTextField$data = {
  readonly description: string | null | undefined;
  readonly fieldId: string;
  readonly fieldOperations?: {
    readonly canEdit: boolean | null | undefined;
  } | null | undefined;
  readonly isEditableInIssueView: boolean | null | undefined;
  readonly name: string;
  readonly richText: {
    readonly adfValue: {
      readonly json: AGG$JSON | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly type: string;
  readonly " $fragmentType": "richTextField_issueViewLayoutRichTextField";
};
export type richTextField_issueViewLayoutRichTextField$key = {
  readonly " $data"?: richTextField_issueViewLayoutRichTextField$data;
  readonly " $fragmentSpreads": FragmentRefs<"richTextField_issueViewLayoutRichTextField">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldscustomfieldconfigrelayprovider"
    }
  ],
  "kind": "Fragment",
  "name": "richTextField_issueViewLayoutRichTextField",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "description"
    },
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "kind": "ScalarField",
      "name": "isEditableInIssueView"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "concreteType": "JiraRichText",
      "kind": "LinkedField",
      "name": "richText",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraADF",
          "kind": "LinkedField",
          "name": "adfValue",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "json"
            }
          ]
        }
      ]
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldscustomfieldconfigrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "concreteType": "JiraFieldOperation",
          "kind": "LinkedField",
          "name": "fieldOperations",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "canEdit"
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraRichTextField"
};

(node as any).hash = "efc2651d2a853a5bdba9860e4ef8e237";

export default node;
