import { statusCategoryForId } from '@atlassian/jira-common-constants/src/status-categories.tsx';
import type {
	AssociatedIssue,
	ServerAssociatedIssue,
} from '@atlassian/jira-issue-shared-types/src/common/types/associated-issue-type.tsx';

export const toTopLevelAssociatedIssue = (
	subtask: ServerAssociatedIssue,
	isResolved: boolean,
): AssociatedIssue => ({
	id: subtask.id,
	issueLink: subtask.self,
	issueTypeIconUrl: subtask.fields.issuetype?.iconUrl,
	issueTypeName: subtask.fields.issuetype?.name,
	issuePriorityUrl: subtask.fields.priority?.iconUrl ?? undefined,
	issuePrioritySequence: subtask.fields.priority?.sequence ?? undefined,
	issuePriorityName: subtask.fields.priority?.name ?? undefined,
	issueKey: subtask.key,
	issueSummary: subtask.fields.summary,
	statusCategory: statusCategoryForId(subtask.fields.status?.statusCategory?.id),
	statusCategoryId: subtask.fields.status?.statusCategory?.id,
	statusId: subtask.fields.status?.id,
	statusName: subtask.fields.status?.name,
	isLoading: false,
	hasError: false,
	assigneeUrl: subtask.fields.assignee?.avatarUrls['48x48'] ?? null,
	assigneeDisplayName: subtask.fields.assignee?.displayName ?? null,
	issueTimeTracking: subtask.fields.timetracking,
	isResolved,
});
