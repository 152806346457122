import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	description: {
		id: 'issue-get-design-updates-content.description',
		defaultMessage:
			'To get status updates for your designs, ask your Jira administrator to connect your Figma teams to Jira.',
		description: 'Content for the popup to get design updates',
	},
	descriptionForAdmin: {
		id: 'issue-get-design-updates-content.description-for-admin',
		defaultMessage:
			'Highlight designs that have changed since you last saw them to stay aligned on the latest in Jira.',
		description: 'Content for get design updates',
	},
	button: {
		id: 'issue-get-design-updates-content.button',
		defaultMessage: 'Notify admin',
		description: 'Button to direct user to info regarding contacting there Jira admin',
	},
	buttonForAdmin: {
		id: 'issue-get-design-updates-content.button-for-admin',
		defaultMessage: 'Configure Figma teams',
		description: 'Button to connect Figma account to Jira',
	},
});
