import React from 'react';
import { SERVICE_DESK_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useContextState } from '../../../state/context.tsx';
import { CommentContent, type OwnProps } from './view.tsx';

const ConnectedCommentContent = (props: OwnProps) => {
	const [{ projectType }] = useContextState();
	const intl = useIntl();
	if (!useContextState) {
		return null;
	}
	return (
		<CommentContent {...props} isServiceDesk={projectType === SERVICE_DESK_PROJECT} intl={intl} />
	);
};
export default ConnectedCommentContent;
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { EditorContainer } from './view';
