import {
	add,
	endOfMonth,
	isBefore,
	isSameDay,
	startOfDay,
	endOfDay,
	parseISO,
	startOfMonth,
	sub,
	startOfWeek,
	isSameWeek,
	isSameMonth,
	startOfYear,
	isSameYear,
	endOfWeek,
	endOfYear,
} from 'date-fns';
import { fg } from '@atlassian/jira-feature-gating';
import {
	type IntervalFieldFilterValue,
	type INTERVAL_PERIOD,
	END_AFTER_NOW,
	END_BEFORE_NOW,
	START_AFTER_NOW,
	START_BEFORE_NOW,
	DAY,
	MONTH,
	type IntervalFilterDateType,
	type IntervalFilterPeriod,
	type IntervalFilterTime,
	INTERVAL_FILTER_DATE_TYPE_START,
	INTERVAL_FILTER_PERIOD_DAY,
	INTERVAL_FILTER_PERIOD_WEEK,
	INTERVAL_FILTER_PERIOD_MONTH,
	INTERVAL_FILTER_PERIOD_YEAR,
	INTERVAL_FILTER_TIME_PAST,
	INTERVAL_FILTER_TIME_NEXT,
	INTERVAL_FILTER_TIME_CURRENT,
} from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import {
	isIntervalFieldFilterAbsoluteDatesValue,
	isIntervalFieldFilterEmptyValue,
	isIntervalFieldFilterLegacyValue,
	isIntervalFieldFilterNotEmptyValue,
	isIntervalFieldFilterRollingDatesCurrentValue,
	isIntervalFieldFilterRollingDatesPastNextValue,
} from '@atlassian/jira-polaris-domain-view/src/filter/utils.tsx';
import type { MappingFilterFunction } from '../../types.tsx';

type IntervalFilter = (startDate: Date, endDate: Date, now?: Date) => boolean;

type IntervalStringFilter = (value?: string) => boolean;

const matchPastTimeHorizon = (dateToCheck: Date, horizon: Date, now: Date) => {
	return (
		isSameDay(dateToCheck, now) ||
		isSameDay(dateToCheck, horizon) ||
		(isBefore(horizon, dateToCheck) && isBefore(dateToCheck, now))
	);
};

const matchFutureTimeHorizon = (dateToCheck: Date, horizon: Date, now: Date) => {
	return (
		isSameDay(dateToCheck, now) ||
		isSameDay(dateToCheck, horizon) ||
		(isBefore(now, dateToCheck) && isBefore(dateToCheck, horizon))
	);
};

export const createIntervalStartBeforeNowFilterFunction =
	(interval: number, period: INTERVAL_PERIOD): IntervalFilter =>
	(startDate: Date, endDate: Date, now: Date = new Date()) => {
		let horizon;
		switch (period) {
			case MONTH:
				horizon = startOfMonth(sub(now, { months: interval }));
				break;
			case DAY:
				horizon = startOfDay(sub(now, { days: interval }));
				break;
			default:
				throw new Error('Unknown period for interval filter function');
		}
		return matchPastTimeHorizon(startDate, horizon, now);
	};

export const createIntervalStartAfterNowFilterFunction =
	(interval: number, period: INTERVAL_PERIOD): IntervalFilter =>
	(startDate: Date, endDate: Date, now: Date = new Date()) => {
		let horizon;
		switch (period) {
			case MONTH:
				horizon = endOfMonth(add(now, { months: interval }));
				break;
			case DAY:
				horizon = endOfDay(add(now, { days: interval }));
				break;
			default:
				throw new Error('Unknown period for interval filter function');
		}
		return matchFutureTimeHorizon(startDate, horizon, now);
	};

export const createIntervalEndBeforeNowFilterFunction =
	(interval: number, period: INTERVAL_PERIOD): IntervalFilter =>
	(startDate: Date, endDate: Date, now: Date = new Date()) => {
		let horizon;
		switch (period) {
			case MONTH:
				horizon = startOfMonth(sub(now, { months: interval }));
				break;
			case DAY:
				horizon = startOfDay(sub(now, { days: interval }));
				break;
			default:
				throw new Error('Unknown period for interval filter function');
		}
		return matchPastTimeHorizon(endDate, horizon, now);
	};

export const createIntervalEndAfterNowFilterFunction =
	(interval: number, period: INTERVAL_PERIOD): IntervalFilter =>
	(startDate: Date, endDate: Date, now: Date = new Date()) => {
		let horizon;
		switch (period) {
			case MONTH:
				horizon = endOfMonth(add(now, { months: interval }));
				break;
			case DAY:
				horizon = endOfDay(add(now, { days: interval }));
				break;
			default:
				throw new Error('Unknown period for interval filter function');
		}
		return matchFutureTimeHorizon(endDate, horizon, now);
	};

export const createIntervalAbsoluteDatesFilterFunction =
	(expectedStartDateIsoString?: string, expectedEndDateIsoString?: string): IntervalFilter =>
	(startDate: Date, endDate: Date) => {
		if (expectedStartDateIsoString && expectedEndDateIsoString) {
			const expectedStartDate = parseISO(expectedStartDateIsoString);
			const expectedEndDate = parseISO(expectedEndDateIsoString);
			return (
				(isSameDay(expectedStartDate, startDate) || isBefore(expectedStartDate, startDate)) &&
				(isSameDay(expectedEndDate, endDate) || isBefore(endDate, expectedEndDate))
			);
		}

		if (expectedStartDateIsoString) {
			const expectedStartDate = parseISO(expectedStartDateIsoString);
			return isSameDay(expectedStartDate, startDate) || isBefore(expectedStartDate, startDate);
		}

		if (expectedEndDateIsoString) {
			const expectedEndDate = parseISO(expectedEndDateIsoString);
			return isSameDay(expectedEndDate, endDate) || isBefore(endDate, expectedEndDate);
		}

		return true;
	};

export const createIntervalRollingDatesFilterFunction =
	(
		intervalDateType: IntervalFilterDateType,
		period: IntervalFilterPeriod,
		time: IntervalFilterTime,
		n = 0,
	): IntervalFilter =>
	(startDate: Date, endDate: Date, now: Date = new Date()) => {
		const dateToCheck = intervalDateType === INTERVAL_FILTER_DATE_TYPE_START ? startDate : endDate;

		switch (period) {
			case INTERVAL_FILTER_PERIOD_DAY: {
				switch (time) {
					case INTERVAL_FILTER_TIME_PAST: {
						const horizon = startOfDay(sub(now, { days: n }));
						return matchPastTimeHorizon(dateToCheck, horizon, now);
					}
					case INTERVAL_FILTER_TIME_CURRENT:
						return isSameDay(dateToCheck, now);
					case INTERVAL_FILTER_TIME_NEXT: {
						const horizon = endOfDay(add(now, { days: n }));
						return matchFutureTimeHorizon(dateToCheck, horizon, now);
					}
					default:
						throw new Error(
							'Unknown time for period: day in interval rolling dates filter function',
						);
				}
			}
			case INTERVAL_FILTER_PERIOD_WEEK:
				switch (time) {
					case INTERVAL_FILTER_TIME_PAST: {
						const horizon = startOfWeek(sub(now, { weeks: n }));
						return matchPastTimeHorizon(dateToCheck, horizon, now);
					}
					case INTERVAL_FILTER_TIME_CURRENT:
						return isSameWeek(dateToCheck, now);
					case INTERVAL_FILTER_TIME_NEXT: {
						const horizon = endOfWeek(add(now, { weeks: n }));
						return matchFutureTimeHorizon(dateToCheck, horizon, now);
					}
					default:
						throw new Error(
							'Unknown time for period: week in interval rolling dates filter function',
						);
				}
			case INTERVAL_FILTER_PERIOD_MONTH:
				switch (time) {
					case INTERVAL_FILTER_TIME_PAST: {
						const horizon = startOfMonth(sub(now, { months: n }));
						return matchPastTimeHorizon(dateToCheck, horizon, now);
					}
					case INTERVAL_FILTER_TIME_CURRENT:
						return isSameMonth(dateToCheck, now);
					case INTERVAL_FILTER_TIME_NEXT: {
						const horizon = endOfMonth(add(now, { months: n }));
						return matchFutureTimeHorizon(dateToCheck, horizon, now);
					}
					default:
						throw new Error(
							'Unknown time for period: month in interval rolling dates filter function',
						);
				}
			case INTERVAL_FILTER_PERIOD_YEAR:
				switch (time) {
					case INTERVAL_FILTER_TIME_PAST: {
						const horizon = startOfYear(sub(now, { years: n }));
						return matchPastTimeHorizon(dateToCheck, horizon, now);
					}
					case INTERVAL_FILTER_TIME_CURRENT:
						return isSameYear(dateToCheck, now);
					case INTERVAL_FILTER_TIME_NEXT: {
						const horizon = endOfYear(add(now, { years: n }));
						return matchFutureTimeHorizon(dateToCheck, horizon, now);
					}
					default:
						throw new Error(
							'Unknown time for period: year in interval rolling dates filter function',
						);
				}
			default:
				throw new Error('Unknown period for interval rolling dates filter function');
		}
	};

const createStringParsingIntervalFilter =
	(
		wrappedIntervalFilter: IntervalFilter,
		filterValue: IntervalFieldFilterValue,
	): IntervalStringFilter =>
	(value) => {
		if (fg('polaris_better_date_filters')) {
			if (isIntervalFieldFilterEmptyValue(filterValue)) {
				return value === undefined;
			}
		}

		if (value === undefined) {
			return false;
		}

		try {
			const interval = JSON.parse(value);

			if (fg('polaris_better_date_filters')) {
				if (isIntervalFieldFilterNotEmptyValue(filterValue)) {
					return true;
				}
			}

			return wrappedIntervalFilter(parseISO(interval.start), parseISO(interval.end));
		} catch (err) {
			return false;
		}
	};

const createStringParsingDateFilter =
	(
		wrappedIntervalFilter: IntervalFilter,
		filterValue: IntervalFieldFilterValue,
	): IntervalStringFilter =>
	(value) => {
		if (fg('polaris_better_date_filters')) {
			if (isIntervalFieldFilterEmptyValue(filterValue)) {
				return value === undefined;
			}

			if (isIntervalFieldFilterNotEmptyValue(filterValue)) {
				return value !== undefined;
			}
		}

		if (value === undefined) {
			return false;
		}

		try {
			return wrappedIntervalFilter(parseISO(value), parseISO(value));
		} catch (err) {
			return false;
		}
	};

const getWrappedFilterFunctionLegacy = (
	filterValue: IntervalFieldFilterValue,
	period: INTERVAL_PERIOD,
) => {
	const pass = () => true;

	if (!isIntervalFieldFilterLegacyValue(filterValue)) {
		return pass;
	}

	switch (filterValue.operator) {
		case START_BEFORE_NOW:
			return createIntervalStartBeforeNowFilterFunction(filterValue.numericValue, period);
		case START_AFTER_NOW:
			return createIntervalStartAfterNowFilterFunction(filterValue.numericValue, period);
		case END_BEFORE_NOW:
			return createIntervalEndBeforeNowFilterFunction(filterValue.numericValue, period);
		case END_AFTER_NOW:
			return createIntervalEndAfterNowFilterFunction(filterValue.numericValue, period);
		default:
			return pass;
	}
};

const getWrappedFilterFunctionNext = (
	filterValue: IntervalFieldFilterValue,
	period: INTERVAL_PERIOD,
) => {
	const pass = () => true;

	if (isIntervalFieldFilterLegacyValue(filterValue)) {
		switch (filterValue.operator) {
			case START_BEFORE_NOW:
				return createIntervalStartBeforeNowFilterFunction(filterValue.numericValue, period);
			case START_AFTER_NOW:
				return createIntervalStartAfterNowFilterFunction(filterValue.numericValue, period);
			case END_BEFORE_NOW:
				return createIntervalEndBeforeNowFilterFunction(filterValue.numericValue, period);
			case END_AFTER_NOW:
				return createIntervalEndAfterNowFilterFunction(filterValue.numericValue, period);
			default:
			// do nothing
		}
	}

	if (isIntervalFieldFilterAbsoluteDatesValue(filterValue)) {
		return createIntervalAbsoluteDatesFilterFunction(filterValue.start, filterValue.end);
	}

	if (isIntervalFieldFilterRollingDatesCurrentValue(filterValue)) {
		return createIntervalRollingDatesFilterFunction(
			filterValue.intervalDateType,
			filterValue.period,
			filterValue.time,
		);
	}

	if (isIntervalFieldFilterRollingDatesPastNextValue(filterValue)) {
		return createIntervalRollingDatesFilterFunction(
			filterValue.intervalDateType,
			filterValue.period,
			filterValue.time,
			filterValue.numericValue,
		);
	}

	return pass;
};

export const createFilterFunction =
	(period: INTERVAL_PERIOD) =>
	(filterValueOperator: IntervalFieldFilterValue): MappingFilterFunction<string> => {
		const getWrappedFilterFunction = fg('polaris_better_date_filters')
			? getWrappedFilterFunctionNext
			: getWrappedFilterFunctionLegacy;

		return createStringParsingIntervalFilter(
			getWrappedFilterFunction(filterValueOperator, period),
			filterValueOperator,
		);
	};

export const createDateFilterFunction =
	(pediod: INTERVAL_PERIOD) =>
	(filterValueOperator: IntervalFieldFilterValue): MappingFilterFunction<string> => {
		const getWrappedFilterFunction = fg('polaris_better_date_filters')
			? getWrappedFilterFunctionNext
			: getWrappedFilterFunctionLegacy;

		return createStringParsingDateFilter(
			getWrappedFilterFunction(filterValueOperator, pediod),
			filterValueOperator,
		);
	};
