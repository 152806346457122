import type { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';
import { getGiraUrl } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-gira-data/index.tsx';
import { COMMENTS } from '@atlassian/jira-platform-issue-permalinks/src/constants.tsx';
import { getPermalinkStatus } from '@atlassian/jira-platform-issue-permalinks/src/index.tsx';
import type {
	BaseUrl,
	IssueKey,
	CloudId,
	IssueId,
} from '@atlassian/jira-shared-types/src/general.tsx';
import { fetchIssueExpressPerf } from '../../metrics/constants.tsx';
import {
	getIssueRemoteDataQuery,
	getRemoteDataQueryPrefix,
	getIssueRemoteConfluenceWhiteboardQuery,
} from './graphql-query.tsx';
import { getGraphQlData as extractData } from './graphql-util.tsx';
import type {
	ViewIssueRemoteData,
	ConfluenceWhiteboardsGraphqlResponse,
	GraphqlResponse,
} from './types.tsx';

type Variables = {
	issueKey?: IssueKey;
	id?: string;
	jql?: string;
	limit?: number;
};

const NUM_INITIAL_COMMENTS_TO_LOAD = 5;
const CREATED_ORDER_DESCENDING = '-created';

const commentParameter = () => {
	const { hasPermalink, permalinkId } = getPermalinkStatus(COMMENTS);

	return hasPermalink ? `, commentId: ${permalinkId}` : '';
};

const LOG_LOCATION = 'issue.data-provider.source.issue-express.graphql';
const VIEW_ISSUE_ALIAS = 'bentoViewIssue';

const addQueryForRemoteLinks = () =>
	`remoteLinks {
       isLinkingEnabled
       confluencePages {
         linkedPages {
            pageCount
         }
         mentionedPages {
            pageCount
         }
       }
     }`;

const getCommentsQuery = () => {
	const commentAmountParameter = `, commentAmount: ${Math.floor(NUM_INITIAL_COMMENTS_TO_LOAD / 2)}`;
	const orderByParameter = `, orderBy: "${CREATED_ORDER_DESCENDING}"`;

	const query = `comments (first: 0, maxResults: ${NUM_INITIAL_COMMENTS_TO_LOAD}${orderByParameter}, isPreview: true ${commentParameter()}${commentAmountParameter}) {
            nodes{
              id
              author {
                  accountId
                  displayName
                  avatarUrl
              }
              updateAuthor {
                  accountId
                  displayName
                  avatarUrl
              }
              visibility {
                  type
                  value
              }
              created
              updated
              jsdPublic
              bodyAdf
              jsdAuthorCanSeeRequest
            }
            totalCount
            startIndex
          }
        `;

	return query;
};

export const getQueryFragment = () => `
        issueId
        fields {
            __typename

            ... on ProjectField {
                fieldKey
                type
                simplified
                name
            }

            ... on TextField {
                fieldKey
                title
                isEditable
                textValue
                adf
                renderer
            }

            ... on IssueTypeField {
              fieldKey
              id
              iconUrl
              name
            }

            ... on StatusField {
              fieldKey
              name
              statusCategoryId
            }

            ... on IssueKeyField {
              fieldKey
              stringValue
            }

            ... on PriorityField {
              fieldKey
              title
              isEditable
              iconUrl
              name
              id
            }

            ... on UserField {
              fieldKey
              title
              isEditable
              userValue {
                displayName
                avatarUrl
                accountId
              }
            }

            ... on VersionsField {
              fieldKey
              title
              isEditable
              versions {
                id
                name
              }
            }

            ... on LabelsField {
              fieldKey
              title
              isEditable
              labels
            }

            ... on ProjectComponentsField {
              fieldKey
              title
              isEditable
              componentValues {
                id
                name
              }
            }

            ... on TimeTrackingField {
              fieldKey
              title
              isEditable
              originalEstimate {
                estimateSeconds
              }
              remainingEstimate {
                estimateSeconds
              }
              timeSpent {
                estimateSeconds
              }
            }

            ... on StoryPointsField {
              fieldKey
              title
              isEditable
              floatValue
            }

            ... on EpicLinkField {
              fieldKey
              title
              isEditable
              epic {
                id
                key
                name
                summary
                colorKey
                done
              }
            }

            ... on EpicNameField {
              fieldKey
              stringValue
              title
              isEditable
            }

            ... on EpicStatusField {
              fieldKey
              title
              isEditable
              selectedValue {
                  optionId
                  value
              }
            }

            ... on SprintCustomField {
              fieldKey
              title
              isEditable
              values {
                id
                name
                goal
                state
                boardId
                startDate
                endDate
                completeDate
              }
            }

            ... on SelectCustomField {
              fieldKey
              title
              isEditable
              selectedValue {
                  optionId
                  value
              }
            }

            ... on CascadingSelectCustomField {
              fieldKey
              title
              isEditable
              selectedValue {
                  optionId
                  value
                  child {
                    optionId
                    value
                  }
              }
            }

            ... on UserPickerCustomField {
              fieldKey
              title
              isEditable
              selectedUser {
                displayName
                avatarUrl
              }
            }

            ... on TextAreaCustomField {
              fieldKey
              title
              isEditable
              wiki
              adf
              renderer
              textValue
            }

            ... on FloatCustomField {
              fieldKey
              title
              isEditable
              floatValue
            }

            ... on DatePickerCustomField {
              fieldKey
              title
              isEditable
              stringValue
            }

            ... on DateTimeCustomField {
              fieldKey
              title
              isEditable
              dateTime
            }

            ... on TextFieldCustomField {
              fieldKey
              title
              isEditable
              wiki
            }

            ... on MultiSelectCustomField {
              fieldKey
              title
              isEditable
              selectedValues {
                  optionId
                  value
              }
            }

            ... on LabelsCustomField {
              fieldKey
              title
              isEditable
              labels
            }

            ... on MultiUserPickerCustomField {
              fieldKey
              title
              isEditable
              selectedUsers {
                accountId
                displayName
                avatarUrl
              }
            }

            ... on UrlCustomField {
              fieldKey
              title
              isEditable
              url
            }

            ... on VersionCustomField {
              fieldKey
              title
              isEditable
              version {
                  id
                  name
              }
            }

            ... on GroupPickerCustomField {
              fieldKey
              title
              isEditable
              group
            }

            ... on MultiVersionCustomField {
              fieldKey
              title
              isEditable
              versions {
                  id
                  name
              }
            }

            ... on MultiGroupPickerCustomField {
              fieldKey
              title
              isEditable
              groups
            }

            ... on DateField {
              fieldKey
              stringValue
              isEditable
              title
            }

            ... on CheckBoxesCustomField {
                fieldKey
                isEditable
                title
                selectedValues {
                    optionId
                    value
                }
            }

            ... on RadioButtonsCustomField {
                fieldKey
                isEditable
                title
                selectedValue {
                    optionId
                    value
                }
            }

            ... on ColorField {
                fieldKey
                isEditable
                title
                stringValue
            }

            ... on EpicColorField {
                fieldKey
                isEditable
                title
                stringValue
            }

            ... on ResolutionField {
                id
                fieldKey
                name
            }

            ... on FlaggedCustomField {
                fieldKey
                title
                isFlagged
                isEditable
                selectedValues {
                    optionId
                    value
                }
            }

            ... on ProjectCustomField {
              fieldKey
              key
              name
              title
              avatarUrl
              isEditable
            }
        }
        ${addQueryForRemoteLinks()}
        ${getCommentsQuery()}
    `;

const getQueryPrefix = () => '_all';

const getFieldPinningPreference = (projectKey?: string) =>
	projectKey != null ? `"jira.user.issue.details.pinned-fields.${projectKey}",` : '';

const getQueryForMyPreferences = (projectKey?: string) => `
    myPreferences(keys: [
        "jira.user.issue.changeboarding.lastinteracted",
        "jira.user.issue.clickthrough-banner.lastinteracted",
        "jira.user.issue.pinned-fields-banner.lastinteracted",
        ${getFieldPinningPreference(projectKey)}
    ])`;

const getProjectQueryFragment = (projectKey: string) => `
    project(projectKey: "${projectKey}") {
        newIssueViewLockedIn
    }
`;

export const getQuery = (projectKey?: string) => `query issueViewQuery($issueKey: String!) {
    ${getQueryForMyPreferences(projectKey)}
    ${projectKey !== undefined ? getProjectQueryFragment(projectKey) : ''}
    ${VIEW_ISSUE_ALIAS}${getQueryPrefix()}: viewIssue(issueKey: $issueKey) {
        ${getQueryFragment()}
    }
    activitySortOrder
}
`;

const getFieldDataOptions = (query: string, variables: Variables) => ({
	method: 'POST',
	body: JSON.stringify({
		query,
		variables,
	}),
	perf: fetchIssueExpressPerf,
});

export const fetchIssueRemoteData = (
	baseUrl: BaseUrl,
	issueKey: IssueKey,
): Observable<ViewIssueRemoteData> =>
	fetchJson$(
		getGiraUrl(baseUrl, 'issueViewRemoteDataQuery'),
		getFieldDataOptions(getIssueRemoteDataQuery(), { issueKey }),
	)
		// @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'GraphqlResponse<ViewIssueGraphqlResponse | ViewMultipleIssueGraphqlResponse>'.
		.map((response) => extractData(LOG_LOCATION, response))
		// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type '`bentoViewIssue${string}`' can't be used to index type 'ViewIssueGraphqlResponse | ViewMultipleIssueGraphqlResponse'.
		.map((value) => value[`${VIEW_ISSUE_ALIAS}${getRemoteDataQueryPrefix()}`]);

export const fetchIssueConfluenceWhiteboardData = (issueId: IssueId, cloudId: CloudId) =>
	fetchJson$<GraphqlResponse<ConfluenceWhiteboardsGraphqlResponse>>(
		'/gateway/api/graphql?operation=issueViewRemoteConfluenceWhiteboardQuery',
		getFieldDataOptions(getIssueRemoteConfluenceWhiteboardQuery(), {
			id: `ari:cloud:jira:${cloudId}:issue/${issueId}`,
		}),
	).map((response) => extractData(LOG_LOCATION, response));
