import type { AggUser } from '../../common/types.tsx';

export type UserConnection = {
	edges: { node: AggUser }[];
};
export const transformAggToUserConnection = (users: AggUser[] | null): UserConnection => {
	return {
		edges:
			users?.map((item) => ({
				node: item,
			})) ?? [],
	};
};
