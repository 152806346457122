import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	copyGitCommitLabel: {
		id: 'development-summary-commit.create-commit-dropdown.copy-commit-command.copy-git-commit-label',
		defaultMessage: 'Copy sample Git commit',
		description:
			"The cta users click to create a commit in an external SCM tool. 'Commit' is a technical term for the tool Git, and is most often not translated.",
	},
});
