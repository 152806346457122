import { useIssueId } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { createAri, type Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';

/**
 * @returns the ARI for the current issue
 * @example ari:cloud:jira:49d8b9d6-ee7d-4931-a0ca-7fcae7d1c3b5:issue/681276
 */
export const useIssueAri = (): Ari => {
	const cloudId = useCloudId();
	const issueId = useIssueId();
	return createAri({
		resourceOwner: 'jira',
		cloudId,
		resourceType: 'issue',
		resourceId: issueId ?? '',
	});
};
