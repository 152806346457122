import { useCallback, useEffect, useMemo } from 'react';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import {
	useIsAiEnabledForIssue,
	useIssueKey,
} from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useIsPremium } from '@atlassian/jira-platform-react-hooks-use-ai-opt-in/src/index.tsx';
import { useUserProperty } from '@atlassian/jira-platform-use-user-properties/src/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useProjectKey, useEdition } from '@atlassian/jira-project-context-service/src/main.tsx';
import { useAcronymsQuery } from '../../services/index.tsx';
import { useHighlightActions, useIsHighlightEnabled } from '../use-highlight-actions/index.tsx';
import { markUpContentGracefully, resetMarkedUpContentGracefully, parseContent } from './utils.tsx';

// Replace with lodash/noop
// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

const AUTO_HIGHLIGHTING_ENABLED_PROPERTY_KEY = 'autoHighlightingEnabled';

export const useAutoHighlightingEnabled: () => {
	autoHighlightingEnabled: boolean;
	setAutoHighlightingEnabled: (newValue: boolean) => void;
} = () => {
	const [autoHighlightingEnabled, setAutoHighlightingEnabledInternal] = useUserProperty(
		AUTO_HIGHLIGHTING_ENABLED_PROPERTY_KEY,
	);

	const autoHighlightingEnabledBoolean = useMemo(
		() => autoHighlightingEnabled === 'true' || autoHighlightingEnabled === undefined,
		[autoHighlightingEnabled],
	);

	const setAutoHighlightingEnabled = useCallback(
		(newValue: boolean) => {
			setAutoHighlightingEnabledInternal(newValue ? 'true' : 'false');
		},
		[setAutoHighlightingEnabledInternal],
	);

	return { autoHighlightingEnabled: autoHighlightingEnabledBoolean, setAutoHighlightingEnabled };
};

export const useAcronymHighlighter = <T extends HTMLElement>(
	descriptionRef: React.RefObject<T>,
) => {
	const { highlightEnabled } = useIsHighlightEnabled();
	const { data, loadAcronyms, resetData } = useAcronymsQuery();
	const { toggleAcronymDialog } = useHighlightActions();

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isAiOptInEnabled = useIsAiEnabledForIssue();
	const isPremium = useIsPremium();
	const isFeatureEnabled = isPremium && isAiOptInEnabled;
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const edition = useEdition(projectKey, true);

	const { autoHighlightingEnabled } = useAutoHighlightingEnabled();

	const onClick = useCallback(
		(e: MouseEvent) => {
			e.stopPropagation();
			toggleAcronymDialog(
				e.clientX,
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				e.target as HTMLElement,
			);
			const analyticsEvent = createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'jiraReadingAidsAcronymHighlight',
			});
			fireUIAnalytics(analyticsEvent, {
				isReadingAidsEnabled: isFeatureEnabled,
				edition,
			});
		},
		[toggleAcronymDialog, createAnalyticsEvent, edition, isFeatureEnabled],
	);

	const loadData = useCallback(
		(adfValue: ADF | undefined) => {
			const content = parseContent(adfValue);
			if (content) {
				loadAcronyms(content);
			}
		},
		[loadAcronyms],
	);
	const highlightAcronyms = useCallback(
		(adfValue: ADF | undefined) => {
			if (adfValue) {
				loadData(adfValue);
			}
		},
		[loadData],
	);

	useEffect(() => {
		if (data && descriptionRef.current) {
			const { acronyms } = data;
			if (autoHighlightingEnabled) {
				markUpContentGracefully(descriptionRef.current, acronyms, onClick);
			} else if (!autoHighlightingEnabled) {
				resetMarkedUpContentGracefully();
			}
		}
	}, [data, descriptionRef, autoHighlightingEnabled, onClick]);

	const resetAcronymsData = useCallback(() => {
		resetData();
	}, [resetData]);

	return !highlightEnabled
		? {
				highlightAcronyms: noop,
				resetAcronymsData: noop,
			}
		: {
				highlightAcronyms,
				resetAcronymsData,
			};
};
