import { createSelector } from 'reselect';
import { getAllBranches } from '../branches/index.tsx';
import { getBuildProviders, getBuildsCount } from '../builds/index.tsx';
import { getAllCommitsCount, getAllRepositories } from '../commits/index.tsx';
import { getDeploymentsCount } from '../deployments/index.tsx';
import { getAllInstanceTypeDetails } from '../index.tsx';
import { getAllPullRequests } from '../pullrequests/index.tsx';
import { getRemoteLinksTotalCount } from '../remote-links/index.tsx';

export const getDetailsReceivedEventAttributes = createSelector(
	[
		getAllInstanceTypeDetails,
		getAllRepositories,
		getAllCommitsCount,
		getAllBranches,
		getAllPullRequests,
		getBuildProviders,
		getBuildsCount,
		getDeploymentsCount,
		getRemoteLinksTotalCount,
	],
	(
		instanceTypes,
		repositories,
		commitsCount,
		branches,
		pullrequests,
		buildProviders,
		buildsCount,
		deploymentsCount,
		remoteLinksCount,
	) => ({
		instanceTypeCount: instanceTypes.length,
		buildProvidersCount: buildProviders.length,
		reposCount: repositories.length,
		commitsCount,
		branchesCount: branches.length,
		pullrequestsCount: pullrequests.length,
		buildsCount,
		deploymentsCount,
		remoteLinksCount,
	}),
);
