import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	requiredErrorMessage: {
		id: 'issue-form-field-base.common.field-message.required-error-message',
		defaultMessage: '{fieldName} is required',
		description: 'Error message for required fields',
	},
	stringInvalidErrorMessage: {
		id: 'issue-form-field-base.common.field-message.string-invalid-error-message',
		defaultMessage:
			"{fieldName} can't exceed {MAX_STRING_SIZE, plural, one {# character} other {# characters}}.",
		description: 'Validation error displayed if text input is invalid',
	},
	urlInvalidErrorMessage: {
		id: 'issue-form-field-base.common.field-message.url-invalid-error-message',
		defaultMessage: '{fieldName} is not a valid URL',
		description: 'Validation error displayed if url input is invalid',
	},
	fieldHelpTextLabel: {
		id: 'issue-form-field-base.common.field-message.field-help-text-label',
		defaultMessage: 'Learn more',
		description: 'Help text for fields',
	},
	fieldHelpIssueTypes: {
		id: 'issue-form-field-base.common.field-message.field-help-issue-types',
		defaultMessage: 'Learn about issue types',
		description: 'Helper text for issue types',
	},
	fieldHelpPriorityLevels: {
		id: 'issue-form-field-base.common.field-message.field-help-priority-levels',
		defaultMessage: 'Learn about priority levels',
		description: 'Helper text for priority levels',
	},
	fieldHelpLogWork: {
		id: 'issue-form-field-base.common.field-message.field-help-log-work',
		defaultMessage: 'Learn about logging work',
		description: 'Helper text for Log Work',
	},
	fieldHelpTimeTracking: {
		id: 'issue-form-field-base.common.field-message.field-help-time-tracking',
		defaultMessage: 'Learn about time tracking',
		description: 'Helper text for time tracking',
	},
	fieldHelpResolutionDefinitions: {
		id: 'issue-form-field-base.common.field-message.field-help-resolution-definitions',
		defaultMessage: 'Resolutions definitions',
		description: 'Helper text for resolutions definitions',
	},
	fieldHelpSecurityLevels: {
		id: 'issue-form-field-base.common.field-message.field-help-security-levels',
		defaultMessage: 'More on security levels',
		description: 'Help text for security levels',
	},
	fieldContentWithHelpText: {
		id: 'issue-form-field-base.common.field-message.field-content-with-help-text',
		defaultMessage: '{fieldDescription} {fieldHelpText}',
		description: 'Overall field message, fieldHelpText is the help link',
	},
});
