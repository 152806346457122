import type { KeyboardEvent, MouseEvent } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isKeyEscapeEvent = (e: any): boolean =>
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	e && e instanceof window.KeyboardEvent && e.key === 'Escape'; /* Esc */
export const isKeyEnterEvent = (e: KeyboardEvent): boolean => e.keyCode === 13; /* Enter */
export const isLeftClick = (event: MouseEvent): boolean => event.button === 0;
export const isLeftClickOnly = (event: MouseEvent): boolean =>
	isLeftClick(event) && !(event.metaKey || event.ctrlKey);
export const isMiddleClick = (event: MouseEvent): boolean => event.button === 1;
export const isCmdOrCtrlClick = (event: MouseEvent): boolean =>
	isLeftClick(event) && (event.metaKey || event.ctrlKey);
