import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { FieldsByKey } from '@atlassian/jira-polaris-domain-field/src/collections/types.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { View } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { sendPendoTrackEvent as sendTrackEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';

export const trackAtlasAnalytics = (view: View, fields: FieldsByKey) => {
	if (view.groupBy && fields[view.groupBy]?.configuration?.provider) {
		sendTrackEvent(`${fields[view.groupBy]?.configuration?.provider}_grouped_by_view mounted`);
	}
	if (view.verticalGroupBy && fields[view.verticalGroupBy]?.configuration?.provider) {
		sendTrackEvent(
			`${fields[view.verticalGroupBy]?.configuration?.provider}_vertically_grouped_by_view mounted`,
		);
	}
	view.fields?.forEach((fieldkey: string) => {
		if (fields[fieldkey]?.configuration?.provider) {
			sendTrackEvent(`${fields[fieldkey]?.configuration?.provider}_field_in_view mounted`);
		}
	});
};

export const trackAtlasSiteAnalytics = (
	view: View,
	fields: FieldsByKey,
	cloudId: string,
	createAnalyticsEvent: CreateUIAnalyticsEvent,
) => {
	const atlasConfiguredFields = view.fields?.filter(
		(fieldKey) =>
			(fields[fieldKey]?.type === FIELD_TYPES.ATLAS_GOAL ||
				fields[fieldKey]?.type === FIELD_TYPES.ATLAS_PROJECT) &&
			fields[fieldKey]?.configuration?.cloudId !== undefined,
	);

	if (!atlasConfiguredFields || !atlasConfiguredFields.length) {
		return;
	}

	const isSameAtlasSite = atlasConfiguredFields.some(
		(fieldKey) => cloudId === fields[fieldKey]?.configuration?.cloudId,
	);

	if (isSameAtlasSite) {
		fireTrackAnalytics(createAnalyticsEvent({}), 'atlasFields loaded', 'sameAtlasSiteConfig');
	} else {
		fireTrackAnalytics(createAnalyticsEvent({}), 'atlasFields loaded', 'differentAtlasSiteConfig');
	}
};
