import { useLayoutEffect } from 'react';
import { useIssueAdjustmentsActions } from '@atlassian/jira-issue-adjustments/src/controllers.tsx';
import { useBackgroundRefreshLoading } from '@atlassian/jira-issue-field-base/src/services/issue-refresh-loading-service/index.tsx';

/**
 * Responsible for blocking the onInit when there is a background refresh in progress.
 * Background refresh happens in Issue View in the following cases (list non-exhaustive):
 * -> issue type change
 * -> SPA transition into recently-viewed issue
 * -> Forge Bridge Custom UI method call: view.refresh()
 */
export const useBackgroundRefresh = () => {
	const backgroundRefreshLoadingStatus = useBackgroundRefreshLoading();
	const { setExperienceDataIsLoading } = useIssueAdjustmentsActions();

	useLayoutEffect(() => {
		setExperienceDataIsLoading(backgroundRefreshLoadingStatus === 'LOADING');
	}, [backgroundRefreshLoadingStatus, setExperienceDataIsLoading]);
};
