/**
 * @generated SignedSource<<c362131bc04a91704edff5f27a5abea6>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type DevOpsSecurityVulnerabilityStatus = "CLOSED" | "IGNORED" | "OPEN" | "UNKNOWN" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type main_developmentSummaryVulnerability_Vulnerability$data = ReadonlyArray<{
  readonly status: DevOpsSecurityVulnerabilityStatus | null | undefined;
  readonly url: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"vulnerabilityDropdown_developmentSummaryVulnerability">;
  readonly " $fragmentType": "main_developmentSummaryVulnerability_Vulnerability";
}>;
export type main_developmentSummaryVulnerability_Vulnerability$key = ReadonlyArray<{
  readonly " $data"?: main_developmentSummaryVulnerability_Vulnerability$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_developmentSummaryVulnerability_Vulnerability">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "main_developmentSummaryVulnerability_Vulnerability",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "vulnerabilityDropdown_developmentSummaryVulnerability"
    },
    {
      "kind": "ScalarField",
      "name": "status"
    },
    {
      "kind": "ScalarField",
      "name": "url"
    }
  ],
  "type": "DevOpsSecurityVulnerabilityDetails"
};

(node as any).hash = "73243e66a7f6c31bf2ed0394784ca6fc";

export default node;
