import { USER_JSM_PROJECT_ROLE } from '@atlassian/jira-servicedesk-spa-commons/src/common/utils/servicedesk-context/constants.tsx';
import { useServiceDeskContext } from '@atlassian/jira-servicedesk-spa-commons/src/common/utils/servicedesk-context/index.tsx';

export const useIsAgentOrProjectAdmin = () => {
	const [{ data, error }] = useServiceDeskContext();
	if (error) return false;
	return (
		data?.userRole === USER_JSM_PROJECT_ROLE.AGENT_ROLE ||
		data?.userRole === USER_JSM_PROJECT_ROLE.AGENT_PROJECT_ADMIN_ROLE
	);
};
