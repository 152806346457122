import { defaultTimeTrackingConfiguration } from '@atlassian/jira-common-constants/src/jira-settings.tsx';
import type {
	DefaultUnit,
	Format,
	TimeTrackingConfig,
} from '@atlassian/jira-issue-shared-types/src/common/types/jira-settings-type.tsx';
import type { AggResponseData } from '../types/issue-type.tsx';

export const transformAggResponseToTimeTrackingConfig = (
	data: AggResponseData,
): TimeTrackingConfig => {
	const timeTrackingAGG = data.jira.globalTimeTrackingSettings;

	if (!timeTrackingAGG) {
		return defaultTimeTrackingConfiguration;
	}

	const {
		isJiraConfiguredTimeTrackingEnabled,
		workingHoursPerDay,
		workingDaysPerWeek,
		defaultFormat,
		defaultUnit,
	} = timeTrackingAGG;

	return {
		isTimeTrackingEnabled:
			isJiraConfiguredTimeTrackingEnabled || defaultTimeTrackingConfiguration.isTimeTrackingEnabled,
		hoursPerDay: workingHoursPerDay || defaultTimeTrackingConfiguration.hoursPerDay,
		daysPerWeek: workingDaysPerWeek || defaultTimeTrackingConfiguration.daysPerWeek,
		format: defaultFormat
			? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				(defaultFormat.toLowerCase() as Format)
			: defaultTimeTrackingConfiguration.format, // NOTE: The original gira transformer returns undefined here
		defaultUnit: defaultUnit
			? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				(defaultUnit.toLowerCase() as DefaultUnit)
			: defaultTimeTrackingConfiguration.defaultUnit,
	};
};
