import React, { type ComponentPropsWithoutRef, type ReactNode } from 'react';
import EditIcon from '@atlaskit/icon/core/edit';
import Link from '@atlaskit/link';
import { Box, xcss } from '@atlaskit/primitives';
import Pressable from '@atlaskit/primitives/pressable';
import Tooltip from '@atlaskit/tooltip';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import { useCustomFieldsInlineEditExperiment } from '@atlassian/jira-issue-field-edit-icon/src/controllers/useCustomFieldsInlineEditExperiment.tsx';
import { FieldIconPopup } from '@atlassian/jira-issue-field-icon-popup/src/FieldIconPopup.tsx';
import { fieldEditButtonSelectorName } from './constants.tsx';
import messages from './messages.tsx';

export const FieldEditIcon = ({
	onClick,
	hasEditPermission,
}: {
	onClick: React.ComponentProps<typeof Pressable>['onClick'];
	hasEditPermission?: boolean;
}) => {
	const { formatMessage } = useIntl();
	const isCustomFieldConfigurationEnabled = useCustomFieldsInlineEditExperiment();

	if (!isCustomFieldConfigurationEnabled) {
		return null;
	}

	const popupContent = (
		<FormattedMessage
			{...messages.contactAdminToManageFields}
			values={{
				link: (chunks) => (
					<Link href="/secure/ContactAdministrators!default.jspa" target="_blank">
						{chunks}
					</Link>
				),
			}}
		/>
	);

	if (!hasEditPermission) {
		return (
			<FieldIconPopup customContent={popupContent} label={formatMessage(messages.label)}>
				<FieldEditButton>
					<Box xcss={pressableStyles} aria-label={formatMessage(messages.tooltip)}>
						<EditIcon
							color="currentColor"
							label={formatMessage(messages.label)}
							LEGACY_size="small"
						/>
					</Box>
				</FieldEditButton>
			</FieldIconPopup>
		);
	}

	return (
		<FieldEditButton>
			<Tooltip content={formatMessage(messages.tooltip)}>
				<Pressable
					onClick={onClick}
					xcss={pressableStyles}
					aria-label={formatMessage(messages.tooltip)}
				>
					<EditIcon
						color="currentColor"
						label={formatMessage(messages.label)}
						LEGACY_size="small"
					/>
				</Pressable>
			</Tooltip>
		</FieldEditButton>
	);
};

const FieldEditButton = (props: ComponentPropsWithoutRef<typeof FieldEditButtonComponent>) => (
	<FieldEditButtonComponent data-component-selector={fieldEditButtonSelectorName} {...props} />
);

const FieldEditButtonComponent = ({ children, ...props }: { children: ReactNode }) => (
	// eslint-disable-next-line react/jsx-props-no-spreading
	<Box xcss={fieldEditButtonComponentStyles} {...props}>
		{children}
	</Box>
);

const pressableStyles = xcss({
	width: '16px',
	height: '16px',
	borderRadius: '4px',
	display: 'inline-flex',
	alignItems: 'center',
	justifyContent: 'center',
	backgroundColor: 'color.background.neutral.subtle',
	color: 'color.text.subtle',
});

const fieldEditButtonComponentStyles = xcss({
	paddingTop: 'space.025',
	paddingRight: 'space.025',
});
