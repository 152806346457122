import { Field } from './clause';
import { AstNode, Position } from './common';

export const ORDER_BY_DIRECTION_ASC: 'ASC' = 'ASC';
export const ORDER_BY_DIRECTION_DESC: 'DESC' = 'DESC';

export type OrderByDirectionValue =
  | typeof ORDER_BY_DIRECTION_ASC
  | typeof ORDER_BY_DIRECTION_DESC;

export const ORDER_BY_OPERATOR_ORDER_BY = 'ORDER BY';

/**
 * The ORDER BY keyword used in an order-by clause.
 */
export interface OrderByOperator extends AstNode {
  /**
   * Literal value for the ORDER BY keyword.
   */
  value: typeof ORDER_BY_OPERATOR_ORDER_BY;
  /**
   * Tuple representing the character position of an order by operator.
   */
  position: Position;
}

/**
 * The direction to use when ordering results by a field.
 */
export interface OrderByDirection extends AstNode {
  /**
   * Literal value for a direction.
   */
  value: OrderByDirectionValue;
  /**
   * Tuple representing the character position of an order by direction.
   */
  position: Position;
}

/**
 * An element of the order-by JQL clause.
 */
export interface OrderByField extends AstNode {
  /**
   * The field to order by.
   */
  field: Field;
  /**
   * The direction in which to order the results.
   */
  direction: OrderByDirection | void;
  /**
   * Tuple representing the character position of an order by field.
   */
  position: Position;
}

export const NODE_TYPE_ORDER_BY: 'order_by' = 'order_by';

/**
 * Details of the order-by JQL clause.
 */
export interface OrderBy extends AstNode {
  type: typeof NODE_TYPE_ORDER_BY;
  /**
   * The ORDER BY keyword used in an order-by clause.
   */
  operator: OrderByOperator;
  /**
   * The list of order-by clause fields and their ordering directives.
   */
  fields: OrderByField[];
  /**
   * Tuple representing the character position of an order by clause.
   */
  position: Position;
}
