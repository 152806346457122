/**
 * @generated SignedSource<<443f0c89d07eb07b952eb73351600e91>>
 * @relayHash 34496f84e497133947582f2bb1b5a3ce
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID f4134bc8b9bbb4490882d6eb2262b96ca53b3bbe831931e223a5a2c77947295a

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type view_issueViewWatchers_DropdownContentQuery$variables = {
  id: string;
};
export type view_issueViewWatchers_DropdownContentQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"addWatchers_issueViewWatchers_AddWatchersRelay" | "changeWatchState_issueViewWatchers_ChangeWatchWithRelay" | "watchersList_issueViewWatchers_WatchersListRelay">;
  } | null | undefined;
};
export type view_issueViewWatchers_DropdownContentQuery = {
  response: view_issueViewWatchers_DropdownContentQuery$data;
  variables: view_issueViewWatchers_DropdownContentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v3 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "view_issueViewWatchers_DropdownContentQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "changeWatchState_issueViewWatchers_ChangeWatchWithRelay"
              },
              {
                "kind": "FragmentSpread",
                "name": "watchersList_issueViewWatchers_WatchersListRelay"
              },
              {
                "kind": "FragmentSpread",
                "name": "addWatchers_issueViewWatchers_AddWatchersRelay"
              }
            ],
            "type": "JiraWatchesField"
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "view_issueViewWatchers_DropdownContentQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "kind": "ScalarField",
                "name": "type"
              },
              {
                "kind": "ScalarField",
                "name": "fieldId"
              },
              {
                "concreteType": "JiraWatch",
                "kind": "LinkedField",
                "name": "watch",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "isWatching"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "count"
                  },
                  (v2/*: any*/)
                ]
              },
              {
                "concreteType": "JiraUserConnection",
                "kind": "LinkedField",
                "name": "selectedUsersConnection",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraUserEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "kind": "TypeDiscriminator",
                            "abstractKey": "__isUser"
                          },
                          (v3/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "accountId"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "name"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "picture"
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ],
            "type": "JiraWatchesField"
          }
        ]
      }
    ]
  },
  "params": {
    "id": "f4134bc8b9bbb4490882d6eb2262b96ca53b3bbe831931e223a5a2c77947295a",
    "metadata": {},
    "name": "view_issueViewWatchers_DropdownContentQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "241914e51efef5d14e82b0977b08685f";

export default node;
