import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import type { LocalViewId } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { sendPendoTrackEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';
import { isPermissionError } from '@atlassian/jira-polaris-lib-errors/src/controllers/utils.tsx';
import { ViewNotFoundError } from '@atlassian/jira-polaris-remote-view/src/common/errors/view-not-found-error.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { Action, StoreActionApi } from '@atlassian/react-sweet-state';
import { createViewAri } from '../../../../common/utils/ari/index.tsx';
import { getCurrentView } from '../../selectors/view/current/index.tsx';
import {
	createGetViewByAri,
	extractViewAnalyticsData,
	getCurrentViewId,
} from '../../selectors/view/index.tsx';
import type { Props, State } from '../../types.tsx';
import { setCurrentView } from '../current/index.tsx';
import { updateState as updateSectionState } from '../sections/index.tsx';
import { logViewError } from '../utils/errors.tsx';
import { findView, findViewSet, getFirstAvailableViewIdInViewSet } from '../utils/views/index.tsx';

export const deleteView =
	(id: LocalViewId, updateStateOnly = false): Action<State, Props> =>
	async ({ getState, setState, dispatch }: StoreActionApi<State>, props) => {
		const { viewSets } = getState();
		const viewToDelete = findView(viewSets, (v) => v.id === id);
		const viewSet = findViewSet(getState().viewSets, (v) => v.id === viewToDelete?.viewSetId);
		const currentView = getCurrentView(getState(), props);

		if (viewToDelete && viewSet) {
			const newViewSets = viewSets.map((v) => ({
				...v,
				views: v.id === viewSet.id ? v.views.filter((view) => view.id !== id) : v.views,
				viewSets: v.viewSets?.map((vSet) => ({
					...vSet,
					views: vSet.id === viewSet.id ? vSet.views.filter((view) => view.id !== id) : vSet.views,
				})),
			}));
			setState({
				viewSets: newViewSets,
			});

			// if we are deleting the current view, set the new current as first of the current view set
			// or fallback to All Ideas view
			if (viewToDelete.id === currentView?.id) {
				const currentViewSet = findViewSet(newViewSets, (v) => v.id === viewToDelete.viewSetId);

				let newCurrentViewId: string | undefined = currentViewSet?.views?.[0]?.id;
				if (newCurrentViewId === undefined) {
					const getFirstAvailableViewIdInViewSetWithType =
						getFirstAvailableViewIdInViewSet(newViewSets);

					const newCurrentViewData =
						getFirstAvailableViewIdInViewSetWithType('CAPTURE') ??
						getFirstAvailableViewIdInViewSetWithType('PRIORITIZE');

					newCurrentViewId = newCurrentViewData?.view?.id;

					if (newCurrentViewData?.sectionId) {
						dispatch(
							updateSectionState({
								id: newCurrentViewData?.sectionId,
								collapsed: true, // due to the logical bug we need to set `collapsed: true` to expand the section
							}),
						);
					}
				}

				// newCurrentViewId would never be null since there will always be a capture or a prioritize view
				// as we disable deleting the last one
				dispatch(setCurrentView(newCurrentViewId ?? ''));
			}

			viewToDelete.viewLegacyId &&
				props.onSyncViewDeletion(String(viewToDelete.viewLegacyId), viewToDelete.kind);

			if (!updateStateOnly && viewToDelete.viewId) {
				try {
					experience.view.deleteView.start();

					viewToDelete.uuid && (await props.viewRemote.deleteView(viewToDelete.uuid));

					const analyticsData = extractViewAnalyticsData(viewToDelete);
					fireTrackAnalytics(
						props.createAnalyticsEvent({
							containers: analyticsData?.containers,
						}),
						'view deleted',
						analyticsData?.attributes || {},
					);
					sendPendoTrackEvent({
						actionSubjectAndAction: 'view deleted',
						attributes: { viewId: viewToDelete.slug || '' },
					});

					experience.view.deleteView.success();
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
				} catch (error: any) {
					logViewError('actions.delete', Object.assign(error, { viewId: viewToDelete.viewId }));

					if (error instanceof ViewNotFoundError) {
						experience.view.deleteView.abort(error);

						return; // we don't need to revert it / show an error as the view doesn't exist anyway
					}

					if (isPermissionError(error) || isClientFetchError(error)) {
						experience.view.deleteView.abort(error);
					} else {
						experience.view.deleteView.failure(error);
					}

					props.onViewUpdateFailed(error);

					setState({
						viewSets: getState().viewSets.map((v) => ({
							...v,
							views: v.id === viewSet.id ? [...v.views, viewToDelete] : v.views,
							viewSets: v.viewSets?.map((vSet) => ({
								...vSet,
								views: vSet.id === viewSet.id ? [...vSet.views, viewToDelete] : vSet.views,
							})),
						})),
					});
				}
			}
		}
	};

export const deleteViewFromState =
	(id: number): Action<State, Props, { isCurrentView: boolean }> =>
	({ getState, dispatch }, props) => {
		const state = getState();
		const viewAri = createViewAri(props.cloudId, id.toString());
		const viewToDelete = createGetViewByAri(viewAri)(state);
		const currentViewId = getCurrentViewId(state, props);

		if (!viewToDelete?.id) {
			return {
				isCurrentView: false,
			};
		}

		dispatch(deleteView(viewToDelete.id, true));

		return {
			isCurrentView: currentViewId === viewToDelete.id,
		};
	};
