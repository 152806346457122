import React from 'react';
import type { CustomGlyphProps } from '@atlaskit/icon';

export const dropdownGlyph = (props: CustomGlyphProps) => (
	<svg
		{...props}
		width="16"
		height="16"
		viewBox="4 4 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16.5255 16.5255C15.3253 17.7257 13.6974 18.4 12 18.4C10.3026 18.4 8.67475 17.7257 7.47452 16.5255C6.27428 15.3253 5.6 13.6974 5.6 12C5.6 10.3026 6.27428 8.67475 7.47452 7.47452C8.67475 6.27428 10.3026 5.6 12 5.6C13.6974 5.6 15.3253 6.27428 16.5255 7.47452C17.7257 8.67475 18.4 10.3026 18.4 12C18.4 13.6974 17.7257 15.3253 16.5255 16.5255ZM4 12C4 16.4184 7.5816 20 12 20C16.4184 20 20 16.4184 20 12C20 7.5816 16.4184 4 12 4C7.5816 4 4 7.5816 4 12ZM9.0336 10.6344C8.88415 10.7855 8.80033 10.9895 8.80033 11.202C8.80033 11.4145 8.88415 11.6185 9.0336 11.7696L11.3848 14.1416C11.5592 14.3136 11.7848 14.3992 12.008 14.3992C12.2312 14.3992 12.4528 14.3136 12.6232 14.1416L14.9672 11.7776C15.1165 11.6264 15.2001 11.4225 15.2001 11.21C15.2001 10.9975 15.1165 10.7936 14.9672 10.6424C14.8937 10.5679 14.8062 10.5087 14.7096 10.4683C14.6131 10.4278 14.5095 10.407 14.4048 10.407C14.3001 10.407 14.1965 10.4278 14.1 10.4683C14.0034 10.5087 13.9159 10.5679 13.8424 10.6424L12.004 12.496L10.1584 10.6344C10.0848 10.5602 9.99716 10.5012 9.90064 10.461C9.80411 10.4208 9.70057 10.4001 9.596 10.4001C9.49143 10.4001 9.38789 10.4208 9.29136 10.461C9.19483 10.5012 9.10723 10.5602 9.0336 10.6344Z"
			fill="currentColor"
		/>
	</svg>
);
