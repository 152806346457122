import React, { type ComponentType, useMemo } from 'react';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useFieldOptionsOverrides } from '@atlassian/jira-issue-field-base/src/services/field-config-service/context.tsx';
import type { AllowedValuesOverrides } from '@atlassian/jira-issue-field-base/src/services/field-config-service/types.tsx';

export const withExtraOwnProps =
	<T extends { extraOwnProps: { allowedValuesOverrides: AllowedValuesOverrides } }>(
		WrappedComponent: ComponentType<T>,
	) =>
	(props: Omit<T, 'extraOwnProps'> & { fieldId: string }) => {
		const { fieldId } = props;
		const issueKey = useIssueKey();
		const [allowedValuesOverrides] = useFieldOptionsOverrides({
			issueKey,
			fieldKey: fieldId,
		});

		const extraOwnProps = useMemo(() => ({ allowedValuesOverrides }), [allowedValuesOverrides]);

		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return <WrappedComponent {...(props as unknown as T)} extraOwnProps={extraOwnProps} />;
	};
