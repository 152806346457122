import React, { memo } from 'react';
import Tooltip from '@atlaskit/tooltip';
import AsyncIcon from '@atlassian/jira-common-components-async-icon/src/view.tsx';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import { FlexContainer } from '../../common/ui/styled.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const priorityIconSize = 20;

export const Priority = memo<Props>(
	({ issuePriorityUrl, issuePriorityName, hideTooltipOnMouseDown }: Props) => {
		const intl = useIntl();
		const title = intl.formatMessage(messages.issuePriorityTitle, { issuePriorityName });

		const icon = (
			<AsyncIcon
				alt={title}
				url={issuePriorityUrl}
				width={priorityIconSize}
				height={priorityIconSize}
			/>
		);

		return (
			<Tooltip
				content={issuePriorityName || ''}
				hideTooltipOnMouseDown={hideTooltipOnMouseDown}
				position="bottom"
			>
				<FlexContainer data-testid="issue-line-card.ui.priority.priority">{icon}</FlexContainer>
			</Tooltip>
		);
	},
);
