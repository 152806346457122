import type { ReactElement } from 'react';
import { epicModalOpenInteraction } from '@atlassian/jira-issue-view-common-utils/src/utils/performance-analytics/index.tsx';
// eslint-disable-next-line camelcase
import { withStoreForIssueViewShortcutActions_DO_NOT_USE } from '@atlassian/jira-issue-view-common-views/src/store-provider/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { showAssignIssueParentModal } from '@atlassian/jira-issue-view-store/src/actions/assign-issue-parent-modal-actions.tsx';
import LinkToParentButtonItemView from './button-item.tsx';
import LinkToParentView from './main.tsx';
import messages from './messages.tsx';
import type { OwnProps, Props, DispatchProps } from './types.tsx';

const connectToProps = (Component: (arg1: Props) => ReactElement) =>
	connect(
		null,
		(dispatch): DispatchProps => ({
			onLinkToParentClick: () => {
				epicModalOpenInteraction.start();
				dispatch(showAssignIssueParentModal());
			},
		}),
	)(Component);

export default withStoreForIssueViewShortcutActions_DO_NOT_USE<OwnProps>(
	// @ts-expect-error - TS2345 - Argument of type 'ComponentClass<Omit<ShortcutComponentProps & StateProps & DispatchProps, "onLogTimeClick">> & { ...; }' is not assignable to parameter of type 'ComponentType<ShortcutComponentProps>'.
	connectToProps(LinkToParentView),
);

export const addToParentLevelLabel = messages.addToParentLevel;
export const changeParentLevelLabel = messages.changeParentLevel;

export const LinkToParentButtonItem = withStoreForIssueViewShortcutActions_DO_NOT_USE<OwnProps>(
	// @ts-expect-error - TS2345 - Argument of type 'ComponentClass<Omit<ShortcutComponentProps & StateProps & DispatchProps, "onLogTimeClick">> & { ...; }' is not assignable to parameter of type 'ComponentType<ShortcutComponentProps>'.
	connectToProps(LinkToParentButtonItemView),
);
