import { useMemo } from 'react';
import { useIssueFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';

export const useIssueViewExtensionId = (
	issueKey: IssueKey,
	fieldId: string,
): { loading: boolean; data?: string | null } => {
	const [{ loading, value }] = useIssueFieldConfig(issueKey);

	return useMemo(() => {
		if (loading) {
			return { loading };
		}

		return { loading, data: value && value[fieldId]?.schema.custom };
	}, [fieldId, loading, value]);
};
