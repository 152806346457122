import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { Field, FieldMap } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../types.tsx';

export const loadSelectedFields =
	(fieldKeys: string[], loadOnlyIfFieldsKnown?: boolean): Action<State, Props, Promise<Field[]>> =>
	async ({ getState, setState }, { fieldRemote }) => {
		const shouldRefetch =
			!loadOnlyIfFieldsKnown || fieldKeys.every((fieldKey) => fieldKey in getState().fields);

		if (!shouldRefetch) {
			return [];
		}

		const fieldData = await fieldRemote.fetchFields(fieldKeys);

		const updatedFields = preprocessFields(getState().fields, fieldData.fields);

		setState({
			fields: {
				...getState().fields,
				...updatedFields,
			},
			fieldValueDecorations: {
				...getState().fieldValueDecorations,
				...fieldData.fieldValueDecorations,
			},
		});

		return Object.values(updatedFields);
	};

const preprocessFields = (currentFields: FieldMap, newFields: FieldMap): FieldMap => {
	const updatedFields: FieldMap = {};

	for (const field of Object.values(newFields)) {
		let updatedField = field;

		const outdatedField = currentFields[field.key];

		const isGlobalLabelsField =
			updatedField.type === FIELD_TYPES.CUSTOM_LABELS && updatedField.custom && updatedField.global;

		if (isGlobalLabelsField && outdatedField?.options?.length) {
			// keep loaded global labels
			updatedField = {
				...updatedField,
				options: outdatedField.options,
			};
		}

		updatedFields[field.key] = updatedField;
	}

	return updatedFields;
};
