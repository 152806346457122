import React, { useCallback } from 'react';
import { ButtonItem } from '@atlaskit/menu';
import { useOptionallyControlledEditingState } from '@atlassian/jira-issue-field-optional-editing-state-manager/src/index.tsx';
import { DATA_CLASSIFICATION_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { Props } from './types.tsx';

export const ClassifyIssueButtonItem = ({
	onClick,
	label,
	setDropdownItemRef,
	itemKey,
	isSelected = false,
}: Props) => {
	/**
	 * Todo: Replace the constant `DATA_CLASSIFICATION_EDITING_STATE_KEY` with the destructured value from relay response
	 */
	const [, setIsIssueClassified] = useOptionallyControlledEditingState(
		false,
		DATA_CLASSIFICATION_TYPE,
	);
	const handleClick = useCallback(() => {
		setIsIssueClassified(true);
		onClick?.(itemKey);
	}, [setIsIssueClassified, onClick, itemKey]);

	return (
		<ButtonItem
			key="classifyIssue"
			isSelected={isSelected}
			ref={setDropdownItemRef}
			role="menuitem"
			onClick={handleClick}
		>
			{label}
		</ButtonItem>
	);
};
