import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	oneMoreFeatureFlag: {
		id: 'development-summary-feature-flag.summary.flags-description.tooltip.one-more-feature-flag',
		defaultMessage: '(+1 more flag)',
		description:
			"The tool tip message indicating there is an additional feature flag associated with this issue. 'Feature Flag' is a technical term that is most often not translated. It is acceptable to transliterate this term to another alphabet/script.",
	},
	moreFeatureFlags: {
		id: 'development-summary-feature-flag.summary.flags-description.tooltip.more-feature-flags',
		defaultMessage: '(+{featureFlagsCnt} more flag)',
		description:
			"The tool tip message indicating there are an additional number of feature flags associated with this issue. 'Feature Flag' is a technical term that is most often not translated. It is acceptable to transliterate this term to another alphabet/script.",
	},
});
