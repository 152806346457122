import {
	createStore,
	createContainer,
	createActionsHook,
	createHook,
} from '@atlassian/react-sweet-state';

type MayBeElement = HTMLElement | null | undefined;

type State = {
	boundary: MayBeElement;
};

const ClipboardStore = createStore({
	name: 'clipboard-store',
	// using containedBy will break all "unscoped" locations. We dont want it for now
	// containedBy: ClipboardStoreContainer,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	initialState: {
		boundary: null,
	} as State,
	actions: {
		setClipBoardContext:
			(boundary: MayBeElement) =>
			({ setState }) => {
				setState({ boundary });
			},
	},
});

export const ClipboardStoreContainer = createContainer(ClipboardStore);

export const useClipboardContextActions = createActionsHook(ClipboardStore);
const useClipboardContextBoundarySelector = createHook(ClipboardStore, {
	selector: (state) => state.boundary,
});

export const useClipboardContextBoundary = (): MayBeElement =>
	useClipboardContextBoundarySelector()[0];
