// ECOSYSTEM_SHOW_GLANCE
export const ECOSYSTEM_SHOW_GLANCE = 'ECOSYSTEM_SHOW_GLANCE' as const;

export const ecosystemShowGlance = (appKey: string, moduleKey: string) => ({
	type: ECOSYSTEM_SHOW_GLANCE,
	payload: {
		appKey,
		moduleKey,
	},
});

export type EcosystemShowGlanceAction = ReturnType<typeof ecosystemShowGlance>;

export type EcosystemGlanceAction = EcosystemShowGlanceAction;
