// ATTACHMENT_PANEL_DRAG_ENTER
export const ATTACHMENT_PANEL_DRAG_ENTER = 'ATTACHMENT_PANEL_DRAG_ENTER' as const;

export const attachmentPanelDragEnter = (numAttachments: number) => ({
	type: ATTACHMENT_PANEL_DRAG_ENTER,
	payload: numAttachments,
});

// ATTACHMENT_PANEL_DRAG_LEAVE
export const ATTACHMENT_PANEL_DRAG_LEAVE = 'ATTACHMENT_PANEL_DRAG_LEAVE' as const;

export const attachmentPanelDragLeave = () => ({
	type: ATTACHMENT_PANEL_DRAG_LEAVE,
});

// ATTACHMENT_PANEL_DROP
export const ATTACHMENT_PANEL_DROP = 'ATTACHMENT_PANEL_DROP' as const;

export const attachmentPanelDrop = () => ({
	type: ATTACHMENT_PANEL_DROP,
});

export type AttachmentPanelAction =
	| ReturnType<typeof attachmentPanelDragEnter>
	| ReturnType<typeof attachmentPanelDragLeave>
	| ReturnType<typeof attachmentPanelDrop>;
