import React, { type ReactNode } from 'react';
import { ModalBoundary } from '@atlassian/jira-modal/src/ui/modal-boundary/index.tsx';
import { LOAD_START_MARK_KEY } from './common/constants.tsx';

export type WorkflowReadonlyModalBoundaryProps = {
	children: ReactNode;
	packageName: string;
};

export const WorkflowReadonlyModalBoundary = ({
	children,
	packageName,
}: WorkflowReadonlyModalBoundaryProps) => (
	<ModalBoundary
		id="workflow-readonly-modal"
		metricKey={LOAD_START_MARK_KEY}
		packageName={packageName}
	>
		{children}
	</ModalBoundary>
);
