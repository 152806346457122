import { getHostnameFromUrl } from '@atlassian/jira-issue-create-confluence-content/src/controllers/utils/getHostnameFromUrl.tsx';
import { isCustomDomain } from '@atlassian/jira-issue-create-confluence-content/src/controllers/utils/isCustomDomain.tsx';
import type {
	ConfluencePage,
	ConfluenceWhiteboard,
} from '@atlassian/jira-issue-shared-types/src/common/types/confluence-content-type.tsx';

export const shouldLoadConfluenceInNewTab = ({
	content,
	systemConfluenceAppLinkUrl = '',
	currentHostname,
}: {
	content: ConfluencePage | ConfluenceWhiteboard;
	systemConfluenceAppLinkUrl: string | undefined;
	currentHostname: string;
}) => {
	const contentHref = content?.href;
	const hostname = getHostnameFromUrl(contentHref);
	const systemHostName = getHostnameFromUrl(systemConfluenceAppLinkUrl);
	// When Confluence is not on the same tenant and one of the hosts is a custom domain, render new tab
	return (
		systemHostName !== hostname && (isCustomDomain(currentHostname) || isCustomDomain(hostname))
	);
};

export const getContentWithHostname = <TContentType extends ConfluencePage | ConfluenceWhiteboard>({
	content,
	systemConfluenceAppLinkUrl = '',
	currentHostname,
}: {
	content: TContentType;
	systemConfluenceAppLinkUrl: string | undefined;
	currentHostname: string;
}): TContentType => {
	const contentHref = content?.href;
	const hostname = getHostnameFromUrl(contentHref);
	const systemHostName = getHostnameFromUrl(systemConfluenceAppLinkUrl);
	// If content is not on the same tenant, and custom domain is not enabled, we need to pass the hostname
	if (
		systemHostName !== hostname &&
		!(isCustomDomain(currentHostname) || isCustomDomain(hostname))
	) {
		return { ...content, hostname };
	}

	return content;
};
