/** @jsx jsx */
import React from 'react';
import { styled as styled2, css as css2, jsx } from '@compiled/react';
// eslint-disable-next-line jira/restricted/styled-components-migration, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled, { css } from 'styled-components';
import Heading from '@atlaskit/heading';
import { N200, R400 } from '@atlaskit/theme/colors';
import { h200 } from '@atlaskit/theme/typography';
import { token } from '@atlaskit/tokens';
import { fontFamily } from '@atlassian/jira-common-styles/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';

type Props = {
	label: string;
	htmlFor?: string;
	isRequired?: boolean;
};

export const FieldLabel = ({ label, htmlFor, isRequired = false }: Props) => (
	<Label htmlFor={htmlFor}>
		{fg('migrate_compiled_component_18058') ? (
			<Heading size="xxsmall" as="h2">
				<div css={labelStyle}>
					<span>{label}</span>
					{isRequired ? (
						<span css={requiredIndicatorStyle} role="presentation">
							*
						</span>
					) : null}
				</div>
			</Heading>
		) : (
			<LabelText>
				<span>{label}</span>
				{isRequired ? <RequiredIndicator role="presentation">*</RequiredIndicator> : null}
			</LabelText>
		)}
	</Label>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Label = styled2.label({
	display: 'block',
});

// FIXME: h200() mixin as first arg causes type error in object syntax
// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LabelText = styled.div`
	${h200()}
	display: block;
	font-family: ${fontFamily};
	margin: 0; /* override h200 margins. Padding used for backwards compatibility */
	${css({
		padding: `${token('space.250', '20px')} 0px ${token('space.050', '4px')} 0px`,
	})}
`;

// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage
const labelStyle = css2({
	color: token('color.text.subtlest', N200),
	display: 'block',
	margin: 0,
	padding: `${token('space.250', '20px')} 0px ${token('space.050', '4px')} 0px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	fontFamily,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RequiredIndicator = styled.span({
	color: token('color.text.danger', R400),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	fontFamily,
	paddingLeft: token('space.025', '2px'),
});

// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage
const requiredIndicatorStyle = css2({
	color: token('color.text.danger', R400),
	paddingLeft: token('space.025', '2px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	fontFamily,
});

RequiredIndicator.displayName = 'RequiredIndicator';
