import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	defaultPlaceholder: {
		id: 'issue-picker.default-placeholder',
		defaultMessage: 'Search by issue key or summary',
		description: 'Placeholder for the user input in the issue picker',
	},
	defaultRecentIssues: {
		id: 'issue-picker.default-recent-issues',
		defaultMessage: 'Recent Issues',
		description: 'Group heading for the recent search results',
	},
	defaultMatchingIssues: {
		id: 'issue-picker.default-matching-issues',
		defaultMessage: 'Matching Issues',
		description: 'Group heading for the recent search results',
	},
	defaultFailedLoadingIssues: {
		id: 'issue-picker.default-failed-loading-issues',
		defaultMessage: 'Failed to load issues',
		description: 'Message when there is a connectivity failure',
	},
	createIssue: {
		id: 'issue-picker.create-issue',
		defaultMessage: 'Create an issue for {value}',
		description: 'label for creating a new issue with specified value',
	},
});
