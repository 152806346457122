import { useCallback, useMemo } from 'react';
import {
	setUserProperties,
	getUserProperty,
} from '@atlassian/jira-common-rest/src/api/latest/user-properties/index.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { usePersistentlyCachedRequest } from '@atlassian/jira-platform-persistent-cache/src/index.tsx';
import { useCurrentUser } from '@atlassian/jira-platform-services-user-current/src/main.tsx';
/**
 * Hook to get and set "user property"
 * "Sets" are synchronized across all consumers (limited to the current tab)
 * @see https://developer.atlassian.com/cloud/jira/platform/rest/v3/api-group-user-properties/#api-group-user-properties
 * All input should be "string", but the output MAY BE JSON
 * @param {String} property
 * @param [userId] defaults to the current user
 */
export const useUserProperty = (
	property: string,
	userId?: string | undefined,
): [string | undefined, (newValue: string) => void] => {
	const { data: currentUser } = useCurrentUser();
	const user = userId || currentUser.user.accountId;

	const storageKey = `user-property-${user}-${property}`;

	const [value, setValue] = usePersistentlyCachedRequest<string | undefined>(
		storageKey,
		undefined,
		useCallback(
			() =>
				user
					? getUserProperty<string>(user, property).catch((error) => {
							// user property is not found
							if (error instanceof FetchError && error.statusCode === 404) {
								return undefined;
							}
							throw error;
						})
					: undefined,
			[user, property],
		),
	);

	const setProperty = useCallback(
		(newValue: string) => {
			if (!user) {
				return Promise.reject(new Error('user is unknown'));
			}
			setValue(newValue);
			const operation = setUserProperties(user, property, newValue);
			operation.catch(() => {
				// do not report error, that's consumer's responsibility
				// restore old value
				setValue(value);
			});
			return operation;
		},
		[setValue, user, property, value],
	);

	return useMemo(() => [value, setProperty] as const, [value, setProperty]);
};
