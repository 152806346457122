/**
 * @generated SignedSource<<197ba14762fe9d19ead4c8f8cc3206ca>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type src_issueViewLayoutTemplatesLayoutItem_LayoutItemInternal$data = {
  readonly __typename: string;
  readonly fieldId: string;
  readonly fieldOperations?: {
    readonly canEdit: boolean | null | undefined;
  } | null | undefined;
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"relayLayoutItems_issueViewLayoutTemplatesLayoutItem_RelayLayoutItemFragmentContainer">;
  readonly " $fragmentType": "src_issueViewLayoutTemplatesLayoutItem_LayoutItemInternal";
};
export type src_issueViewLayoutTemplatesLayoutItem_LayoutItemInternal$key = {
  readonly " $data"?: src_issueViewLayoutTemplatesLayoutItem_LayoutItemInternal$data;
  readonly " $fragmentSpreads": FragmentRefs<"src_issueViewLayoutTemplatesLayoutItem_LayoutItemInternal">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldscustomfieldconfigrelayprovider"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "issueViewRelayAssigneeFieldFlag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "issueViewRelayDateFieldFlag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "issueViewRelayDateTimeFieldFlag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "issueViewRelayNumberFieldFlag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "issueViewRelayPeopleAndApproversFieldFlag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "issueViewRelayPriorityFieldFlag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "issueViewRelayProjectFieldFlag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "issueViewRelaySingleLineTextFieldFlag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "issueViewRelaySprintFieldFlag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "issueViewRelayUrlFieldFlag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "jcsIssueLayoutEapFlag"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "jsmSentimentCustomFieldFlag"
    }
  ],
  "kind": "Fragment",
  "name": "src_issueViewLayoutTemplatesLayoutItem_LayoutItemInternal",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__typename"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldscustomfieldconfigrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "concreteType": "JiraFieldOperation",
          "kind": "LinkedField",
          "name": "fieldOperations",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "canEdit"
            }
          ]
        }
      ]
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "issueViewRelayAssigneeFieldFlag",
          "variableName": "issueViewRelayAssigneeFieldFlag"
        },
        {
          "kind": "Variable",
          "name": "issueViewRelayDateFieldFlag",
          "variableName": "issueViewRelayDateFieldFlag"
        },
        {
          "kind": "Variable",
          "name": "issueViewRelayDateTimeFieldFlag",
          "variableName": "issueViewRelayDateTimeFieldFlag"
        },
        {
          "kind": "Variable",
          "name": "issueViewRelayNumberFieldFlag",
          "variableName": "issueViewRelayNumberFieldFlag"
        },
        {
          "kind": "Variable",
          "name": "issueViewRelayPeopleAndApproversFieldFlag",
          "variableName": "issueViewRelayPeopleAndApproversFieldFlag"
        },
        {
          "kind": "Variable",
          "name": "issueViewRelayPriorityFieldFlag",
          "variableName": "issueViewRelayPriorityFieldFlag"
        },
        {
          "kind": "Variable",
          "name": "issueViewRelayProjectFieldFlag",
          "variableName": "issueViewRelayProjectFieldFlag"
        },
        {
          "kind": "Variable",
          "name": "issueViewRelaySingleLineTextFieldFlag",
          "variableName": "issueViewRelaySingleLineTextFieldFlag"
        },
        {
          "kind": "Variable",
          "name": "issueViewRelaySprintFieldFlag",
          "variableName": "issueViewRelaySprintFieldFlag"
        },
        {
          "kind": "Variable",
          "name": "issueViewRelayUrlFieldFlag",
          "variableName": "issueViewRelayUrlFieldFlag"
        },
        {
          "kind": "Variable",
          "name": "jcsIssueLayoutEapFlag",
          "variableName": "jcsIssueLayoutEapFlag"
        },
        {
          "kind": "Variable",
          "name": "jsmSentimentCustomFieldFlag",
          "variableName": "jsmSentimentCustomFieldFlag"
        }
      ],
      "kind": "FragmentSpread",
      "name": "relayLayoutItems_issueViewLayoutTemplatesLayoutItem_RelayLayoutItemFragmentContainer"
    }
  ],
  "type": "JiraIssueField",
  "abstractKey": "__isJiraIssueField"
};

(node as any).hash = "15a15bad668b5ad387fddd763773eea8";

export default node;
