import type { StoreApi } from './types.tsx';

export const setTourIsActive =
	() =>
	({ setState }: StoreApi) => {
		setState({ isTourActive: true });
	};

export const setTourIsInactive =
	() =>
	({ setState }: StoreApi) => {
		setState({ isTourActive: false });
	};
