/**
 * @generated SignedSource<<839d3116dacc9e67a9f578ce89a0c2b1>>
 * @relayHash ee63005d42eda2fc398ef9863c615e97
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 7da7c5a46c358c6d4b98d3268e8c89997b11585a246809b155fd52dad39eacd9

import type { ConcreteRequest, Query } from 'relay-runtime';
export type JiraServiceManagementCommentVisibility = "INTERNAL" | "VISIBLE_TO_HELPSEEKER" | "%future added value";
export type commentFetchAggServerQuery$variables = {
  after?: string | null | undefined;
  afterTarget?: number | null | undefined;
  before?: string | null | undefined;
  beforeTarget?: number | null | undefined;
  first?: number | null | undefined;
  issueAri: string;
  last?: number | null | undefined;
  targetId?: string | null | undefined;
};
export type commentFetchAggServerQuery$data = {
  readonly node: {
    readonly comments?: {
      readonly edges: ReadonlyArray<{
        readonly cursor: string;
        readonly node: {
          readonly __typename: string;
          readonly author: {
            readonly accountId: string;
            readonly name: string;
            readonly picture: AGG$URL;
          } | null | undefined;
          readonly authorCanSeeRequest?: boolean | null | undefined;
          readonly commentId: string;
          readonly created: AGG$DateTime;
          readonly eventOccurredAt?: AGG$DateTime | null | undefined;
          readonly jsdIncidentActivityViewHidden?: boolean | null | undefined;
          readonly permissionLevel: {
            readonly group: {
              readonly groupId: string;
              readonly id: string;
              readonly name: string;
            } | null | undefined;
            readonly role: {
              readonly id: string;
              readonly name: string | null | undefined;
            } | null | undefined;
          } | null | undefined;
          readonly richText: {
            readonly adfValue: {
              readonly json: AGG$JSON | null | undefined;
            } | null | undefined;
          } | null | undefined;
          readonly updateAuthor: {
            readonly accountId: string;
            readonly name: string;
            readonly picture: AGG$URL;
          } | null | undefined;
          readonly updated: AGG$DateTime | null | undefined;
          readonly visibility?: JiraServiceManagementCommentVisibility | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
      readonly pageInfo: {
        readonly endCursor: string | null | undefined;
        readonly hasNextPage: boolean;
        readonly hasPreviousPage: boolean;
        readonly startCursor: string | null | undefined;
      };
    } | null | undefined;
  } | null | undefined;
};
export type commentFetchAggServerQuery = {
  response: commentFetchAggServerQuery$data;
  variables: commentFetchAggServerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "afterTarget"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "before"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "beforeTarget"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueAri"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "last"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "targetId"
},
v8 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "issueAri"
  }
],
v9 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "afterTarget",
    "variableName": "afterTarget"
  },
  {
    "kind": "Variable",
    "name": "before",
    "variableName": "before"
  },
  {
    "kind": "Variable",
    "name": "beforeTarget",
    "variableName": "beforeTarget"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Literal",
    "name": "sortBy",
    "value": {
      "field": "CREATED",
      "order": "DESC"
    }
  },
  {
    "kind": "Variable",
    "name": "targetId",
    "variableName": "targetId"
  }
],
v10 = {
  "kind": "ScalarField",
  "name": "cursor"
},
v11 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v12 = {
  "kind": "ScalarField",
  "name": "commentId"
},
v13 = {
  "kind": "ScalarField",
  "name": "created"
},
v14 = {
  "kind": "ScalarField",
  "name": "updated"
},
v15 = {
  "kind": "ScalarField",
  "name": "name"
},
v16 = {
  "kind": "ScalarField",
  "name": "accountId"
},
v17 = {
  "kind": "ScalarField",
  "name": "picture"
},
v18 = [
  (v15/*: any*/),
  (v16/*: any*/),
  (v17/*: any*/)
],
v19 = {
  "concreteType": "JiraRichText",
  "kind": "LinkedField",
  "name": "richText",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraADF",
      "kind": "LinkedField",
      "name": "adfValue",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "json"
        }
      ]
    }
  ]
},
v20 = {
  "kind": "ScalarField",
  "name": "id"
},
v21 = {
  "concreteType": "JiraPermissionLevel",
  "kind": "LinkedField",
  "name": "permissionLevel",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraGroup",
      "kind": "LinkedField",
      "name": "group",
      "plural": false,
      "selections": [
        (v20/*: any*/),
        (v15/*: any*/),
        {
          "kind": "ScalarField",
          "name": "groupId"
        }
      ]
    },
    {
      "concreteType": "JiraRole",
      "kind": "LinkedField",
      "name": "role",
      "plural": false,
      "selections": [
        (v20/*: any*/),
        (v15/*: any*/)
      ]
    }
  ]
},
v22 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "authorCanSeeRequest"
    },
    {
      "kind": "ScalarField",
      "name": "eventOccurredAt"
    },
    {
      "kind": "ScalarField",
      "name": "visibility"
    },
    {
      "kind": "ScalarField",
      "name": "jsdIncidentActivityViewHidden"
    }
  ],
  "type": "JiraServiceManagementComment"
},
v23 = {
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "hasNextPage"
    },
    {
      "kind": "ScalarField",
      "name": "hasPreviousPage"
    },
    {
      "kind": "ScalarField",
      "name": "startCursor"
    },
    {
      "kind": "ScalarField",
      "name": "endCursor"
    }
  ]
},
v24 = [
  (v11/*: any*/),
  (v15/*: any*/),
  (v16/*: any*/),
  (v17/*: any*/),
  (v20/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "name": "commentFetchAggServerQuery",
    "selections": [
      {
        "args": (v8/*: any*/),
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": (v9/*: any*/),
                "concreteType": "JiraCommentConnection",
                "kind": "LinkedField",
                "name": "comments",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraCommentEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      (v10/*: any*/),
                      {
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v11/*: any*/),
                          (v12/*: any*/),
                          (v13/*: any*/),
                          (v14/*: any*/),
                          {
                            "kind": "LinkedField",
                            "name": "author",
                            "plural": false,
                            "selections": (v18/*: any*/)
                          },
                          {
                            "kind": "LinkedField",
                            "name": "updateAuthor",
                            "plural": false,
                            "selections": (v18/*: any*/)
                          },
                          (v19/*: any*/),
                          (v21/*: any*/),
                          (v22/*: any*/)
                        ]
                      }
                    ]
                  },
                  (v23/*: any*/)
                ]
              }
            ],
            "type": "JiraIssue"
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v6/*: any*/),
      (v2/*: any*/),
      (v7/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "commentFetchAggServerQuery",
    "selections": [
      {
        "args": (v8/*: any*/),
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v11/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": (v9/*: any*/),
                "concreteType": "JiraCommentConnection",
                "kind": "LinkedField",
                "name": "comments",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraCommentEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      (v10/*: any*/),
                      {
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v11/*: any*/),
                          (v12/*: any*/),
                          (v13/*: any*/),
                          (v14/*: any*/),
                          {
                            "kind": "LinkedField",
                            "name": "author",
                            "plural": false,
                            "selections": (v24/*: any*/)
                          },
                          {
                            "kind": "LinkedField",
                            "name": "updateAuthor",
                            "plural": false,
                            "selections": (v24/*: any*/)
                          },
                          (v19/*: any*/),
                          (v21/*: any*/),
                          (v22/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v20/*: any*/)
                            ],
                            "type": "Node",
                            "abstractKey": "__isNode"
                          }
                        ]
                      }
                    ]
                  },
                  (v23/*: any*/)
                ]
              }
            ],
            "type": "JiraIssue"
          },
          (v20/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "id": "7da7c5a46c358c6d4b98d3268e8c89997b11585a246809b155fd52dad39eacd9",
    "metadata": {},
    "name": "commentFetchAggServerQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "457026fd0ba6e5537e1106c69ef2c9d1";

export default node;
