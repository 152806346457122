import React from 'react';
import { useFragment, graphql } from 'react-relay';
import { Box, xcss } from '@atlaskit/primitives';
import { DateWithUrgencyIndicator } from '@atlassian/jira-date-with-urgency-indicator/src/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { EmptyFieldText } from '@atlassian/jira-issue-empty-field-text/src/ui/index.tsx';
import { useDateTimeFormatter } from '@atlassian/jira-issue-format-date/src/main.tsx';
import { DUE_DATE } from '@atlassian/jira-issue-view-configurations/src/index.tsx';
import type { date_issueFieldDateReadviewFull_DateReadView$key as DateFragment } from '@atlassian/jira-relay/src/__generated__/date_issueFieldDateReadviewFull_DateReadView.graphql';
import type { Props } from './types.tsx';

export const DateReadView = ({ fragmentRef, fieldId, includeUrgencyTreatment }: Props) => {
	const { formatDateOnly } = useDateTimeFormatter();
	const data = useFragment<DateFragment>(
		graphql`
			fragment date_issueFieldDateReadviewFull_DateReadView on JiraDatePickerField {
				date
			}
		`,
		fragmentRef,
	);
	const { date } = data || {};
	if (!date) {
		return <EmptyFieldText />;
	}

	const renderedValue =
		includeUrgencyTreatment &&
		fieldId === DUE_DATE &&
		expVal('due_date_colured_field', 'showUrgencyIndicator', false) ? (
			<DateWithUrgencyIndicator value={date} />
		) : (
			formatDateOnly(date)
		);

	return (
		<Box as="span" xcss={dateReadViewStyles}>
			{renderedValue}
		</Box>
	);
};

const dateReadViewStyles = xcss({
	display: '-webkit-box',
	WebkitBoxOrient: 'vertical',
	WebkitLineClamp: 2,
	maxWidth: '100%',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});
