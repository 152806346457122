import React, { useMemo } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { useProjectKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { Attributes } from '@atlassian/jira-product-analytics-bridge';
import { useIsSimplifiedProject } from '@atlassian/jira-project-context-service/src/main.tsx';
import type { ShortcutOption } from '@atlassian/jira-shortcuts-dialog/src/common/types.tsx';
import { useIsAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-admin/index.tsx';
import FindField from './find-field/index.tsx';
import messages from './messages.tsx';

export const adminActionsDropdownGroup = (
	actions: ShortcutOption[],
	onClick: (
		itemKey: string,
		event?: Event,
		actionAttributes?: Attributes,
	) => Promise<undefined> | undefined,
) => ({
	name: '',
	key: 'AdminActionsDropdownGroup',
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	items: actions.reduce<Array<any>>((itemsArray, { Component, label, key }) => {
		if (Component) {
			itemsArray.push(<Component label={label} key={key} itemKey={key} onClick={onClick} />);
		}
		return itemsArray;
	}, []),
});

export const useIssueAdminActions = (): ShortcutOption[] => {
	const projectKey = useProjectKey();
	const isSimplifiedProject = useIsSimplifiedProject(projectKey);
	const { formatMessage } = useIntl();
	const isAdmin = useIsAdmin();
	const items = useMemo(
		() => [
			...(!isSimplifiedProject
				? [
						{
							Component: FindField,
							label: formatMessage(messages.findField),
							key: 'findField',
						},
					]
				: []),
		],
		[formatMessage, isSimplifiedProject],
	);

	return isAdmin ? items : [];
};

export default adminActionsDropdownGroup;
