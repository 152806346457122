/**
 * @generated SignedSource<<e9008ee9dcd2b3f4bcc2b993cbfe95bd>>
 * @relayHash 5f89c2da551b1ec945cbd46c007551e3
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 3f34ce5c5360fc44350e2999d71acb2350f0cd83d29d920197dd3808fc1d504e

import type { ConcreteRequest, Query } from 'relay-runtime';
export type AccountStatus = "active" | "closed" | "inactive" | "%future added value";
export type useFetchWhiteboardFetchConfluenceWhiteboardQuery$variables = {
  id: string;
};
export type useFetchWhiteboardFetchConfluenceWhiteboardQuery$data = {
  readonly confluence: {
    readonly whiteboard: {
      readonly author: {
        readonly user: {
          readonly accountId: string;
          readonly accountStatus: AccountStatus;
          readonly name: string;
          readonly picture: AGG$URL;
        } | null | undefined;
      } | null | undefined;
      readonly id: string;
      readonly links: {
        readonly base: string | null | undefined;
        readonly webUi: string | null | undefined;
      } | null | undefined;
      readonly title: string | null | undefined;
      readonly whiteboardId: string;
    } | null | undefined;
  } | null | undefined;
};
export type useFetchWhiteboardFetchConfluenceWhiteboardQuery = {
  response: useFetchWhiteboardFetchConfluenceWhiteboardQuery$data;
  variables: useFetchWhiteboardFetchConfluenceWhiteboardQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "whiteboardId"
},
v3 = {
  "concreteType": "ConfluenceWhiteboardLinks",
  "kind": "LinkedField",
  "name": "links",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "base"
    },
    {
      "kind": "ScalarField",
      "name": "webUi"
    }
  ]
},
v4 = {
  "kind": "ScalarField",
  "name": "accountId"
},
v5 = {
  "kind": "ScalarField",
  "name": "accountStatus"
},
v6 = {
  "kind": "ScalarField",
  "name": "name"
},
v7 = {
  "kind": "ScalarField",
  "name": "picture"
},
v8 = {
  "kind": "ScalarField",
  "name": "title"
},
v9 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "useFetchWhiteboardFetchConfluenceWhiteboardQuery",
    "selections": [
      {
        "concreteType": "ConfluenceQueryApi",
        "kind": "LinkedField",
        "name": "confluence",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "ConfluenceWhiteboard",
            "kind": "LinkedField",
            "name": "whiteboard",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "ConfluenceUserInfo",
                "kind": "LinkedField",
                "name": "author",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/)
                    ]
                  }
                ]
              },
              (v8/*: any*/),
              (v9/*: any*/)
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useFetchWhiteboardFetchConfluenceWhiteboardQuery",
    "selections": [
      {
        "concreteType": "ConfluenceQueryApi",
        "kind": "LinkedField",
        "name": "confluence",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "ConfluenceWhiteboard",
            "kind": "LinkedField",
            "name": "whiteboard",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "ConfluenceUserInfo",
                "kind": "LinkedField",
                "name": "author",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "__typename"
                      },
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v9/*: any*/)
                    ]
                  }
                ]
              },
              (v8/*: any*/),
              (v9/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "3f34ce5c5360fc44350e2999d71acb2350f0cd83d29d920197dd3808fc1d504e",
    "metadata": {},
    "name": "useFetchWhiteboardFetchConfluenceWhiteboardQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "34ddc6c67546adc86798747e2fe0da96";

export default node;
