import type { DocNode } from '@atlaskit/adf-schema';
import { doc, p, link } from '@atlaskit/adf-utils/builders';

export const AI_MATE_URL = '/gateway/api/assist/chat/v1/invoke_agent';

export const GENERAL_ERROR_ADF: DocNode = doc(
	p(
		"Atlassian Intelligence can't provide a response right now. Read more ",
		link({
			href: 'https://support.atlassian.com/organization-administration/docs/troubleshooting-for-atlassian-intelligence/',
		})('in our support documentation.'),
	),
);

export const RATE_LIMITED_ADF: DocNode = doc(
	p(
		'Excessive use has been detected with Atlassian Intelligence. Try again later. Read more ',
		link({
			href: 'https://support.atlassian.com/organization-administration/docs/excessive-use-in-atlassian-intelligence/',
		})('in our support documentation.'),
	),
);

export const ACCEPTABLE_USAGE_ADF: DocNode = doc(
	p('Text does not comply with Atlassian\'s "Acceptable use policy".'),
);
