import React, { type MouseEvent, type SyntheticEvent, useMemo } from 'react';
import { di } from 'react-magnetic-di';
import { SmartCardProvider } from '@atlaskit/link-provider';
import type { ProductType } from '@atlaskit/linking-common';
import { CardAction } from '@atlaskit/smart-card';
import {
	type ProjectType,
	CORE_PROJECT,
	SOFTWARE_PROJECT,
	SERVICE_DESK_PROJECT,
	PRODUCT_DISCOVERY_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import {
	useIssueKey,
	useIsAiEnabledForIssue,
} from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useIsPremium } from '@atlassian/jira-platform-react-hooks-use-ai-opt-in/src/index.tsx';
import {
	useProjectKey,
	useProjectType,
} from '@atlassian/jira-project-context-service/src/main.tsx';

const isLeftClick = (event: MouseEvent): boolean => event.button === 0;
const isMiddleClick = (event: MouseEvent): boolean => event.button === 1;
const isCmdOrCtrlClick = (event: MouseEvent): boolean =>
	isLeftClick(event) && (event.metaKey || event.ctrlKey);

export const isExternalLink = (url: string) => {
	di(window);
	try {
		const { hostname, pathname } = new URL(url);
		return (
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			hostname !== window.location.hostname ||
			!['/jira', '/browse'].some((includedPath) => pathname.startsWith(includedPath))
		);
	} catch (err: unknown) {
		// Defaults to external link for invalid URLs to avoid further processing
		return true;
	}
};

type LinkClickHandler = (event: SyntheticEvent<HTMLAnchorElement>, url?: string) => void;

export const onLinkClick: LinkClickHandler = (event, url) => {
	di(window);
	if (!url) return;
	if (isExternalLink(url)) {
		event.preventDefault();

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.open(url, '_blank', 'noopener,noreferrer');
		return;
	}
	if (event.defaultPrevented) {
		// The default has already been prevented meaning that we need to open links in one
		// way or another to ensure we do eventually open the link
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
		const mouseEvent: MouseEvent<any> = event as any;
		isCmdOrCtrlClick(mouseEvent) || isMiddleClick(mouseEvent)
			? // eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				window.open(url, '_blank', 'noopener,noreferrer')
			: // eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				window.location.assign(url);
	}
};

/**
 * Default smartLinks prop for `ReactRenderer` to disable `CardAction.ChangeStatusAction`.
 */
export const smartLinksDefault = {
	actionOptions: {
		hide: false,
		exclude: [CardAction.ChangeStatusAction],
	},
};

/**
 * Convert Jira `ProjectType` into `ProductType` for smart links for AI Mate
 */
export const getProductForSmartLinksProvider = (
	projectType: ProjectType | undefined,
): ProductType | undefined => {
	switch (projectType) {
		case CORE_PROJECT:
			return 'JWM';
		case SOFTWARE_PROJECT:
			return 'JSW';
		case SERVICE_DESK_PROJECT:
			return 'JSM';
		case PRODUCT_DISCOVERY_PROJECT:
			return 'JPD';
		default:
			return undefined;
	}
};

/**
 * Hook to get and parse the product prop for SmartCardProvider.
 */
export const useSmartCardProductType = (): ProductType => {
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const projectType = useProjectType(projectKey);
	const product = useMemo(
		() =>
			// Arbitrarily default to Jira Software if not specified
			getProductForSmartLinksProvider(projectType) ?? 'JSW',
		[projectType],
	);
	return product;
};

/**
 * Hook to determine if AI is enabled for SmartCardProvider
 */
export const useSmartCardIsAIEnabled = (): boolean => {
	const isAiOptInEnabled = useIsAiEnabledForIssue();
	const isPremium = useIsPremium();
	const isAIFeatureEnabled = useMemo(
		() => isAiOptInEnabled && isPremium,
		[isAiOptInEnabled, isPremium],
	);
	return isAIFeatureEnabled;
};

export const SmartCardProviderWithAIHooks = (
	props: React.ComponentProps<typeof SmartCardProvider>,
): JSX.Element => {
	const product = useSmartCardProductType();
	const isAIEnabled = useSmartCardIsAIEnabled();
	return (
		<SmartCardProvider product={product} isAdminHubAIEnabled={isAIEnabled} {...props}>
			{props.children}
		</SmartCardProvider>
	);
};
