/**
 * @generated SignedSource<<4eb2a1d28b9896f08d873445b73c80bc>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type url_issueFieldUrlInlineEditFull_UrlInlineEditView$data = {
  readonly fieldConfig: {
    readonly isEditable: boolean | null | undefined;
  } | null | undefined;
  readonly fieldId: string;
  readonly id: string;
  readonly name: string;
  readonly type: string;
  readonly uri: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"url_issueFieldUrlReadviewFull_UrlReadView">;
  readonly " $fragmentType": "url_issueFieldUrlInlineEditFull_UrlInlineEditView";
};
export type url_issueFieldUrlInlineEditFull_UrlInlineEditView$key = {
  readonly " $data"?: url_issueFieldUrlInlineEditFull_UrlInlineEditView$data;
  readonly " $fragmentSpreads": FragmentRefs<"url_issueFieldUrlInlineEditFull_UrlInlineEditView">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "url_issueFieldUrlInlineEditFull_UrlInlineEditView",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "ScalarField",
      "name": "uri"
    },
    {
      "kind": "FragmentSpread",
      "name": "url_issueFieldUrlReadviewFull_UrlReadView"
    },
    {
      "concreteType": "JiraFieldConfig",
      "kind": "LinkedField",
      "name": "fieldConfig",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isEditable"
        }
      ]
    }
  ],
  "type": "JiraUrlField"
};

(node as any).hash = "8d245a34a30c104ae9c291e6620772a6";

export default node;
