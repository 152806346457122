import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import ErrorIcon from '@atlaskit/icon/core/migration/error';
import WarningIcon from '@atlaskit/icon/core/migration/warning';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

export type ValidationState = 'default' | 'error' | 'success' | 'warning' | null;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NoticeContainer = styled.div({
	alignItems: 'center',
	display: 'flex',
	marginTop: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NoticeLabel = styled.div<{ colour: string | null }>({
	marginLeft: token('space.050', '4px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	color: (props) => (props.colour ? props.colour : undefined),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconContainer = styled.div<{ colour: string }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	color: (props) => props.colour,
});

/**
 * Map the 'validationState' variable to the desired colour/icon values
 */
const getTheme = (theme: null | string) => {
	switch (theme) {
		case 'error':
			return {
				iconColor: token('color.text.danger', colors.R400),
				textColor: token('color.text.danger', colors.R400),
				Icon: ErrorIcon,
			};
		case 'warning':
			return {
				iconColor: token('color.text.warning', colors.Y400),
				textColor: null,

				Icon: WarningIcon,
			};
		case 'success':
		case 'default':
		default:
			return {
				iconColor: null,
				textColor: null,
				Icon: null,
			};
	}
};

type Props = {
	children: ReactNode;
	validationMessage: ReactNode | null;
	validationState: ValidationState;
};

/**
 * Wrap a Select (or similar) component with an optional error message
 */
const SelectValidationWrapper = ({
	children = null,
	validationState = 'default',
	validationMessage = null,
}: Props) => {
	const { textColor, iconColor, Icon } = getTheme(validationState);

	return (
		<>
			{children}
			{validationState && validationMessage ? (
				// eslint-disable-next-line jira/integration/test-id-by-folder-structure
				<NoticeContainer data-testid="select-validation-wrapper__notice_container">
					{Icon && (
						// the IconContainer is not needed once icon migration completes
						<IconContainer colour={iconColor}>
							<Icon spacing="spacious" color={iconColor} label={`${validationState} icon`} />
						</IconContainer>
					)}
					<NoticeLabel colour={textColor}>{validationMessage}</NoticeLabel>
				</NoticeContainer>
			) : null}
		</>
	);
};
export default SelectValidationWrapper;
