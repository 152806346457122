import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import type { CreatedJiraAttachment } from './types.tsx';

const createAttachmentsApiUrl = (issueKeyOrId: string): string =>
	`/rest/api/3/issue/${issueKeyOrId}/attachments`;

export const createAttachments = (
	issueKeyOrId: string,
	formData: FormData,
): Promise<CreatedJiraAttachment[]> =>
	fetch(createAttachmentsApiUrl(issueKeyOrId), {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'X-Atlassian-Token': 'no-check',
		},
		body: formData,
	}).then((response) => {
		if (!response.ok) {
			throw new FetchError(response.status, 'Failed to copy attachments');
		}
		return response.json();
	});
