import { createSelector } from 'reselect';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { State, Props } from '../types.tsx';

const EMPTY_COLLECTION: Array<LocalIssueId> = [];

export const getCurrentViewSelectedIssueIds = (state: State): LocalIssueId[] =>
	state.containerProps?.currentViewSelectedIssueId || EMPTY_COLLECTION;

export const getIsSelected = (localIssueId: LocalIssueId) =>
	createSelector(getCurrentViewSelectedIssueIds, (selected) => selected.includes(localIssueId));

export const getIsSharedView = (state: State, props: Props | undefined) => props?.isSharedView;
