import merge from 'lodash/merge';
import type { FieldKeyToAriMap } from '@atlassian/jira-polaris-component-field-ari/src/controllers/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { RemoteIssue } from '@atlassian/jira-polaris-remote-issue/src/controllers/crud/types.tsx';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import { getLocalIssueIdToJiraId } from '../../selectors/issue-ids.tsx';
import type { State, Props } from '../../types.tsx';
import { scheduleDynamicFieldCalculation } from '../create-dynamic-fields/index.tsx';
import { initDeliveryProgress, loadDeliveryProgress } from '../load-delivery-data/index.tsx';
import { loadTrackingMetadata } from '../load-tracking-metadata/index.tsx';
import { loadReactions } from '../reactions/index.tsx';
import {
	transformIssueResponse,
	preserveExistingInsights,
	preserveExistingPlays,
} from './utils.tsx';

// This action is meant to be called when we have both issues and fields loaded
// Because the 2 requests are done in parallel, we need a third action to transform those data
// once they are available
export const initialize =
	(fieldKeyToAriMapPromise: Promise<FieldKeyToAriMap>): Action<State, Props> =>
	({ getState, setState, dispatch }, props) => {
		const { fields, insights, plays, rankField, issuesRemote, isSharedView, isCollectionView } =
			props;

		const state = getState();

		if (!issuesRemote.fetch || state.meta.loading) {
			return;
		}

		// No fields yet (too soon)
		if (fields === undefined || fields.length === 0) {
			return;
		}

		// No issues yet (too soon)
		if (!state.prefechedIssues) {
			return;
		}

		// no rank field id (too soon) - cannot order issues properly if this is missing
		if (rankField === undefined && !isSharedView && !isCollectionView) {
			return;
		}

		const { isSingleIssueLoaded } = state.meta;
		const existingMapping: Record<LocalIssueId, IssueId> = {};

		// preserve existing mapping if any (e.g. when opening an idea in a new tab)
		const jiraIdMap = getLocalIssueIdToJiraId(state, props);
		for (const localIssueId of state.ids) {
			const jiraIssueId = jiraIdMap[localIssueId];
			existingMapping[jiraIssueId] = localIssueId;
		}

		const { ids, properties, externalIssueData } = transformIssueResponse({
			remoteIssues: state.prefechedIssues,
			insights: isSingleIssueLoaded
				? preserveExistingInsights(insights || {}, state.properties, existingMapping)
				: insights,
			plays: isSingleIssueLoaded
				? preserveExistingPlays(plays || {}, state.properties, existingMapping)
				: plays,
			existingState: state,
			existingMapping,
			props,
		});

		// we merge the old properties and the updated one because the old one may contain
		// some data already (mainly in the case where a single idea is opened in a new tab, atlas fields will
		// already be loaded, as well as the idea description)
		merge(properties, state.properties);

		setState({
			meta: {
				...state.meta,
				initialized: true,
			},
			ids,
			properties,
			externalIssueData,
			prefechedIssues: undefined,
		});

		dispatch(scheduleDynamicFieldCalculation());
		dispatch(initDeliveryProgress());
		dispatch(loadTrackingMetadata());

		// load reactions for all ideas
		const externalIds = state.prefechedIssues.map((issue) => issue.id);
		dispatch(loadReactions(externalIds, fieldKeyToAriMapPromise));
	};

export const initializeArchived =
	(fieldKeyToAriMapPromise: Promise<FieldKeyToAriMap>): Action<State, Props> =>
	({ getState, setState, dispatch }, props) => {
		const { fields, insights, plays, issuesRemote } = props;

		const state = getState();
		const { archivedIssues, meta } = state;

		if (!meta.initialized || meta.initializedArchived) {
			return;
		}

		if (!issuesRemote.fetch || meta.loadingArchivedIssues) {
			return;
		}

		// No fields yet (too soon)
		if (fields === undefined || fields.length === 0) {
			return;
		}

		// No issues yet (too soon)
		if (!archivedIssues) {
			return;
		}

		const existingMapping: Record<LocalIssueId, IssueId> = {};

		// preserve existing mapping if any (e.g. when opening an idea in a new tab)
		const jiraIdMap = getLocalIssueIdToJiraId(state, props);
		for (const localIssueId of state.ids) {
			const jiraIssueId = jiraIdMap[localIssueId];
			existingMapping[jiraIssueId] = localIssueId;
		}

		const { ids, properties, externalIssueData } = transformIssueResponse({
			remoteIssues: archivedIssues,
			insights,
			plays,
			existingState: state,
			existingMapping,
			props,
		});

		merge(properties, state.properties);
		merge(externalIssueData, state.externalIssueData);

		setState({
			meta: {
				...state.meta,
				initializedArchived: true,
			},
			ids: Array.from(new Set([...state.ids, ...ids])),
			properties,
			externalIssueData,
			archivedIssues: undefined,
		});

		// load reactions for archived ideas
		const externalIds = archivedIssues.map((issue) => issue.id);
		dispatch(loadReactions(externalIds, fieldKeyToAriMapPromise));
		dispatch(loadDeliveryProgress(undefined, undefined, undefined, true));
	};

export const addPropertiesForLoadedIssue =
	(remoteIssue: RemoteIssue): Action<State, Props> =>
	({ getState, setState }, props) => {
		const { fields, insights, plays } = props;
		// Bail early if we don't yet have all the information needed to load issues
		if (fields === undefined || fields.length === 0) {
			return;
		}

		// copy state to get around readonly flow errors for selectors
		const state = getState();

		// don't add response to issues state if all the issues have been loaded already
		if (state.meta.initialized) {
			return;
		}

		const { ids, properties, externalIssueData } = transformIssueResponse({
			remoteIssues: [remoteIssue],
			insights,
			plays,
			existingState: state,
			props,
		});

		setState({
			ids,
			properties,
			externalIssueData,
		});
	};
