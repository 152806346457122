/**
 * @generated SignedSource<<8e62461a988e03387f49af3b21d84234>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutPeopleField_IssueViewPeopleField$data = {
  readonly __typename: "JiraPeopleField";
  readonly fieldId: string;
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"people_issueFieldPeopleInlineEditFull_PeopleInlineEditView_fragmentRef" | "ui_issueViewLayoutFieldHeading_IssueViewFieldHeading">;
  readonly " $fragmentType": "ui_issueViewLayoutPeopleField_IssueViewPeopleField";
};
export type ui_issueViewLayoutPeopleField_IssueViewPeopleField$key = {
  readonly " $data"?: ui_issueViewLayoutPeopleField_IssueViewPeopleField$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutPeopleField_IssueViewPeopleField">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutPeopleField_IssueViewPeopleField",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "ui_issueViewLayoutFieldHeading_IssueViewFieldHeading"
    },
    {
      "kind": "FragmentSpread",
      "name": "people_issueFieldPeopleInlineEditFull_PeopleInlineEditView_fragmentRef"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "ScalarField",
      "name": "__typename"
    }
  ],
  "type": "JiraPeopleField"
};

(node as any).hash = "48942f9072413fd2a1a0b973f48c13ee";

export default node;
