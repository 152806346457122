import {
	DONE,
	IN_PROGRESS,
	TODO,
	UNDEFINED,
} from '@atlassian/jira-common-constants/src/status-categories.tsx';

export const START_NODE_ID = 'START';

export type TransitionEnum = 'INITIAL' | 'GLOBAL' | 'DIRECTED';

export const transitionType: {
	[key: string]: TransitionEnum;
} = {
	INITIAL: 'INITIAL',
	GLOBAL: 'GLOBAL',
	DIRECTED: 'DIRECTED',
};

export const transitionIcon: {
	[key: string]: string;
} = {
	BOLT: 'bolt',
	ERROR: 'error',
	CHECK: 'check',
	CHECK_INACTIVE: 'check_inactive',
	CROSS: 'cross',
	CROSS_INACTIVE: 'cross_inactive',
};

export const SCOPE_GLOBAL = 'GLOBAL' as const;
export const SCOPE_PROJECT = 'PROJECT' as const;

export const ALL_OPERATION = 'ALL' as const;

export const STATUS_CATEGORY_WEIGHTS = {
	[TODO]: 1,
	[IN_PROGRESS]: 2,
	[DONE]: 3,
	[UNDEFINED]: 4,
} as const;
export const ANY_OPERATION = 'ANY' as const;

export const errorSeverity = {
	WARNING: 'WARNING',
	ERROR: 'ERROR',
} as const;
