import React from 'react';
import { Checkbox } from '@atlaskit/checkbox';
import { fg } from '@atlassian/jira-feature-gating';
import type { ReadViewProps } from '../../types.tsx';

export const BooleanFieldReadView = ({
	id,
	value,
	render,
	getValueContainer,
	inInlineEdit,
}: { id: string } & ReadViewProps) => {
	const ValueContainer = getValueContainer();
	return (
		<ValueContainer
			render={
				render ||
				(() => (
					<Checkbox
						id={fg('jsm-cs-ally-fixes') ? id : undefined}
						isChecked={value === 'true'}
						isDisabled={fg('jcs_detail_permissions') ? true : undefined}
					/>
				))
			}
			noBorder={inInlineEdit}
		/>
	);
};
