import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	loomInsertExistingVideoButtonLabel: {
		id: 'issue-view-foundation.quick-add.loom-insert-link-item-button.loom-insert-existing-video-button-label',
		defaultMessage: 'Insert existing Loom',
		description: 'The label to be used on the button for inserting an existing Loom video',
	},
	loomExistingVideoButtonLabel: {
		id: 'issue-view-foundation.quick-add.loom-insert-link-item-button.loom-existing-video-button-label',
		defaultMessage: 'Existing Loom video',
		description:
			'The label to be used on the button for inserting an existing Loom video, for quick actions menu GA experiment only',
	},
});

export default messages;
