import React, { type KeyboardEventHandler, useCallback, useEffect } from 'react';

import { toJSON } from '@atlaskit/editor-common/utils';
// eslint-disable-next-line @atlaskit/editor/warn-no-restricted-imports
import type { EditorActions } from '@atlaskit/editor-core';
import { ComposableEditor } from '@atlaskit/editor-core/composable-editor';
import { EditorContext } from '@atlaskit/editor-core/editor-context';
import { usePreset } from '@atlaskit/editor-core/use-preset';
import { type JSONDocNode } from '@atlaskit/editor-json-transformer';
import { AllSelection } from '@atlaskit/editor-prosemirror/state';
import type { EditorView } from '@atlaskit/editor-prosemirror/view';
import { Flex } from '@atlaskit/primitives';
import { TextSerializer } from '@atlaskit/renderer/text-serializer';
import { editorExperiment } from '@atlaskit/tmp-editor-statsig/experiments';

import { LinkPickerButton } from '../components/LinkPickerButton/LinkPickerButton';
import { MentionButton } from '../components/MentionButton/MentionButton';
import type { AIPromptPresetOptions } from '../presets/prompt';
import { createAIPromptPreset } from '../presets/prompt';
import { maybeBuild1pAutoFormattingProvider } from '../utils/smart-link-unfurl';

export type CreatePromptEditorOptions = Omit<AIPromptPresetOptions, 'placeholder' | 'onSave'>;

// PromptEditorProps is mirrored at `packages/editor/generative-ai-modal/src/ui/components/PromptEditorWrapper/PromptEditorWrapper.tsx`
// since editor-ai-injected-editors cannot be a dependency of generative-ai-modal or editor-plugin-ai.
type PromptEditorProps = {
	type?: 'user-input' | 'interrogate';
	autoFocus?: boolean;
	defaultValue?: JSONDocNode;
	placeholder?: string;
	onInputChange?: (input: string) => void;
	onADFChange?: (input: JSONDocNode) => void;
	setFocusHandlers?: (focusFn?: () => boolean, blurFn?: () => boolean) => void;
	setReplaceDocumentHandler?: (replaceFn?: (adf: any) => boolean) => void;
	/**
	 * Using separate setter function for clear as focus and blur
	 *  is set from editorApi while clear from editorActions.
	 * They both can be available at different time.
	 * So it's safest to have separate setter function.
	 */
	setClearHandler?: (clearFn?: () => boolean) => void;
	/**
	 * Having links in Editor is giving false impression to customers that
	 *  we have started supporting links, but we haven't yet as backend will
	 *  still point to xp-gen-ai and leading to hallucinations.
	 * So we have tied enabling links to ConvoAI FF through this prop.
	 */
	enableLinks?: boolean;
};

type EditorWrapperProps = {
	editorProps: PromptEditorProps;
	options: CreatePromptEditorOptions;
};

const EditorWrapper = (props: EditorWrapperProps) => {
	const appearance = 'chromeless';
	const { editorProps, options } = props;
	const {
		type = 'user-input',
		onInputChange,
		onADFChange,
		defaultValue,
		enableLinks,
		setFocusHandlers,
		setClearHandler,
		setReplaceDocumentHandler,
	} = editorProps;

	const onChange = useCallback(
		(editorView: EditorView) => {
			if (onADFChange) {
				onADFChange(toJSON(editorView.state.doc));
			}
			if (onInputChange) {
				const textSerializer = TextSerializer.fromSchema(editorView.state.schema);
				const text = textSerializer.serializeFragment(editorView.state.doc.content);
				onInputChange(text);
			}
		},
		[onInputChange, onADFChange],
	);

	const onSave = useCallback(
		(editorView: EditorView) => {
			onChange(editorView);
		},
		[onChange],
	);

	// We could have created preset outside PromptEditor component,
	//  but currently placeholder prop ComposableEditor is not working.
	// We have to pass placeholder to placeholder plugin.
	// That's why using usePreset, who memoize preset.
	const { preset, editorApi } = usePreset(
		() =>
			createAIPromptPreset({
				...options,
				placeholder: {
					placeholder: editorProps.placeholder,
				},
				onSave,
				enableLinks,
			}),
		[options, editorProps.placeholder],
	);

	const onEditorReady = useCallback(
		(editorActions: EditorActions) => {
			if (setClearHandler) {
				setClearHandler(() => editorActions.clear());
			}
			if (setReplaceDocumentHandler && editorExperiment('platform_editor_ai_draft_with_ai', true)) {
				setReplaceDocumentHandler((adf) => editorActions.replaceDocument(adf));
			}
			const editorView = editorActions._privateGetEditorView();
			if (editorView) {
				if (!editorActions.isDocumentEmpty()) {
					editorView.dispatch(
						editorView.state.tr.setSelection(new AllSelection(editorView.state.doc)),
					);
				}
			}
		},
		[setClearHandler, setReplaceDocumentHandler],
	);

	useEffect(() => {
		if (setFocusHandlers) {
			/**
			 * We need to expose clear function through ref because
			 *  changing defaultValue does not clear editor.
			 */
			setFocusHandlers(editorApi?.core?.actions.blur, editorApi?.core?.actions.focus);
		}
	}, [editorApi, setFocusHandlers]);

	// Skip any toolbars that displays in the editor, like Link Picker toolbar
	// Yes, it's ugly, but it's easier way, because we can't have access to the PromptEditor
	// floating toolbar.
	const stopPropagationForFloatingToolbar: KeyboardEventHandler = useCallback((event) => {
		const eventFloatingToolbar =
			event.target instanceof HTMLElement
				? event.target.closest('[data-testid="editor-floating-toolbar"]')
				: null;

		// Check that floating toolbar is a child of the current target,
		// i.e. it's a child of current the editor.
		if (eventFloatingToolbar && event.currentTarget.contains(eventFloatingToolbar)) {
			event.stopPropagation();
		}
	}, []);

	return (
		// eslint-disable-next-line jsx-a11y/no-static-element-interactions
		<div onKeyDown={stopPropagationForFloatingToolbar} onKeyUp={stopPropagationForFloatingToolbar}>
			<Flex gap="space.050">
				<ComposableEditor
					mentionProvider={options.mentionProvider}
					autoformattingProvider={maybeBuild1pAutoFormattingProvider(options)}
					appearance={appearance}
					minHeight={20}
					maxHeight={200}
					preset={preset}
					shouldFocus={true}
					defaultValue={defaultValue}
					onChange={onChange}
					// We will remove use of onEditorReady when editorApi provides a matching "clear()" action.
					onEditorReady={onEditorReady}
				/>
				{options.enableLinks &&
					options.linkPicker &&
					editorExperiment('platform_editor_ai_prompt_link_picker', true, { exposure: true }) && (
						<LinkPickerButton editorApi={editorApi} promptType={type} />
					)}
				{options.mentionProvider &&
					editorExperiment('platform_editor_ai_mentions_support', true, { exposure: true }) && (
						<MentionButton editorApi={editorApi} />
					)}
			</Flex>
		</div>
	);
};

export function createPromptEditor(options: CreatePromptEditorOptions) {
	return (props: PromptEditorProps): JSX.Element => {
		const editorProps = {
			editorProps: props,
			options,
		};

		return (
			<EditorContext>
				<EditorWrapper {...editorProps} />
			</EditorContext>
		);
	};
}
