import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type {
	DevPanelOnboardingBanner,
	PanelState,
	Summary,
	CreateBranchTarget,
} from '../../../common/types.tsx';
import type { ResponseData } from '../../../services/details/types.tsx';

export const FETCH_DEV_PANEL_REQUEST = 'FETCH_DEV_PANEL_REQUEST' as const;
export const FETCH_DEV_PANEL_SUCCESS = 'FETCH_DEV_PANEL_SUCCESS' as const;
export const FETCH_DEV_PANEL_URL_DETAILS_SUCCESS = 'FETCH_DEV_PANEL_URL_DETAILS_SUCCESS' as const;
export const FETCH_DEV_PANEL_FAILURE = 'FETCH_DEV_PANEL_FAILURE' as const;
export const DEV_PANEL_UNMOUNTED = 'DEV_PANEL_UNMOUNTED' as const;
export const TOGGLE_SUMMARY_SECTION_MESSAGE = 'TOGGLE_SUMMARY_SECTION_MESSAGE' as const;

export const fetchDevPanelRequest = (analyticsEvent?: UIAnalyticsEvent) => ({
	type: FETCH_DEV_PANEL_REQUEST,
	analyticsEvent,
});

export const fetchDevPanelFailure = (analyticsEvent?: UIAnalyticsEvent) => ({
	type: FETCH_DEV_PANEL_FAILURE,
	analyticsEvent,
});

export type DevPanelSuccess = {
	summary?: Summary;
	panelState?: PanelState;
	bannerType?: DevPanelOnboardingBanner | null;
	hasBranchCapabilities?: boolean;
	createBranchTargets?: CreateBranchTarget[];
	hasDevelopmentDataInProject?: boolean;
};

export const fetchDevPanelSuccess = (
	devPanel: DevPanelSuccess,
	analyticsEvent?: UIAnalyticsEvent,
) => ({
	type: FETCH_DEV_PANEL_SUCCESS,
	payload: devPanel,
	analyticsEvent,
});

export const fetchDevPanelUrlDetailsSuccess = (swagDetailsResponse: ResponseData) => ({
	type: FETCH_DEV_PANEL_URL_DETAILS_SUCCESS,
	payload: swagDetailsResponse,
});

export const devPanelWillUnmount = () => ({
	type: DEV_PANEL_UNMOUNTED,
});

export type OnClickDismissLinkAction = {
	type: typeof TOGGLE_SUMMARY_SECTION_MESSAGE;
	payload: {
		showSummarySectionMessage: boolean;
	};
};

export const onClickDismissLink = (showSection: boolean): OnClickDismissLinkAction => ({
	type: TOGGLE_SUMMARY_SECTION_MESSAGE,
	payload: { showSummarySectionMessage: showSection },
});

export const SHOW_EMPTY_STATE_DIALOG = 'SHOW_EMPTY_STATE_DIALOG';
export const CLOSE_EMPTY_STATE_DIALOG = 'CLOSE_EMPTY_STATE_DIALOG';
export const TOGGLE_EMPTY_STATE_PANEL = 'TOGGLE_EMPTY_STATE_PANEL';

export const showEmptyStateDialog = () =>
	({
		type: SHOW_EMPTY_STATE_DIALOG,
	}) as const;

export const closeEmptyStateDialog = () =>
	({
		type: CLOSE_EMPTY_STATE_DIALOG,
	}) as const;

export const onToggleDismissButton = (showPanel: boolean) =>
	({
		type: TOGGLE_EMPTY_STATE_PANEL,
		payload: { panelState: showPanel ? 'NOT_CONNECTED' : 'HIDDEN' },
	}) as const;

export type DevPanelActions =
	| ReturnType<typeof fetchDevPanelFailure>
	| ReturnType<typeof fetchDevPanelRequest>
	| ReturnType<typeof fetchDevPanelSuccess>
	| ReturnType<typeof fetchDevPanelUrlDetailsSuccess>
	| ReturnType<typeof devPanelWillUnmount>
	| ReturnType<typeof onClickDismissLink>
	| ReturnType<typeof showEmptyStateDialog>
	| ReturnType<typeof closeEmptyStateDialog>
	| ReturnType<typeof onToggleDismissButton>;
