export type MediaReadPermissionResponse = {
	clientId: string;
	endpointUrl: string;
	tokenLifespanInSeconds: number;
	tokensWithFiles: {
		token: string;
	}[];
	collection?: string;
};

export const getMediaReadPermissionUrl = (issueKey: string, maxTokenLength: string | number) =>
	`/rest/api/2/attachment/read/issue/${issueKey}/credentials?maxTokenLength=${maxTokenLength}`;
