import React, { type MouseEvent, type KeyboardEvent } from 'react';
import type { CommandRowProps, ListedCommand } from '../../../../types/commands/index.tsx';
import { getAnalyticsAttributesFromResult } from '../../../../utils.tsx';
import { CommandRow as CommandRowNew } from '../../command-row/index.tsx';

type Props = {
	index: number;
	result: ListedCommand;
	isSearchFieldFocused?: boolean;
	onHoverCommand: (event: MouseEvent) => void;
	onClickCommand: (event: MouseEvent | KeyboardEvent) => void;
	onMouseDownCommand: (event: MouseEvent) => void;
};

export const CommandRow = ({
	index,
	result,
	isSearchFieldFocused,
	onHoverCommand,
	onClickCommand,
	onMouseDownCommand,
}: Props) => {
	const { command } = result;
	const isActive = !!(result.active && isSearchFieldFocused);
	const isFirstCommand = result.index === 0;

	const rowProps: CommandRowProps = {
		index,
		isActive,
		command,
		isFirstCommand,
		onMouseMove: onHoverCommand,
		onClick: onClickCommand,
		onMouseDown: onMouseDownCommand,
		analyticsAttributes: getAnalyticsAttributesFromResult(result),
	};

	if (command.components?.CommandRow) {
		return <command.components.CommandRow {...rowProps} />;
	}

	return <CommandRowNew {...rowProps} />;
};
