import { useMemo } from 'react';
import { buildChannelString } from '@atlassian/jira-realtime/src/common/utils/pubsub.utils.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import type { RealtimeContainerType } from './types.tsx';

export const useRealtimeChannels = (
	containerId: string | number | undefined,
	containerType: RealtimeContainerType,
): string[] => {
	const cloudId = useCloudId();

	return useMemo(() => {
		if (containerId === undefined) {
			return [];
		}

		if (containerType === 'PROJECT') {
			return [buildChannelString(cloudId, String(containerId))];
		}
		if (containerType === 'ISSUE') {
			return [`ari:cloud:jira:${cloudId}:issue/${containerId}`];
		}

		return [];
	}, [cloudId, containerId, containerType]);
};

export const useRealtimeProjectChannel = (projectId: string): string[] =>
	useRealtimeChannels(projectId, 'PROJECT');
