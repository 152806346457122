/**
 * @generated SignedSource<<f139c9e4c74bf141b30d416189961e0a>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraTimeFormat = "DAYS" | "HOURS" | "PRETTY" | "%future added value";
export type JiraTimeUnit = "DAY" | "HOUR" | "MINUTE" | "WEEK" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type originalEstimate_issueFieldOriginalEstimateReadviewFull_OriginalEstimateReadView$data = {
  readonly originalEstimate: {
    readonly timeInSeconds: AGG$Long | null | undefined;
  } | null | undefined;
  readonly timeTrackingSettings: {
    readonly defaultFormat: JiraTimeFormat | null | undefined;
    readonly defaultUnit: JiraTimeUnit | null | undefined;
    readonly workingDaysPerWeek: number | null | undefined;
    readonly workingHoursPerDay: number | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "originalEstimate_issueFieldOriginalEstimateReadviewFull_OriginalEstimateReadView";
};
export type originalEstimate_issueFieldOriginalEstimateReadviewFull_OriginalEstimateReadView$key = {
  readonly " $data"?: originalEstimate_issueFieldOriginalEstimateReadviewFull_OriginalEstimateReadView$data;
  readonly " $fragmentSpreads": FragmentRefs<"originalEstimate_issueFieldOriginalEstimateReadviewFull_OriginalEstimateReadView">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "originalEstimate_issueFieldOriginalEstimateReadviewFull_OriginalEstimateReadView",
  "selections": [
    {
      "concreteType": "JiraEstimate",
      "kind": "LinkedField",
      "name": "originalEstimate",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "timeInSeconds"
        }
      ]
    },
    {
      "concreteType": "JiraTimeTrackingSettings",
      "kind": "LinkedField",
      "name": "timeTrackingSettings",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "workingHoursPerDay"
        },
        {
          "kind": "ScalarField",
          "name": "workingDaysPerWeek"
        },
        {
          "kind": "ScalarField",
          "name": "defaultFormat"
        },
        {
          "kind": "ScalarField",
          "name": "defaultUnit"
        }
      ]
    }
  ],
  "type": "JiraTimeTrackingField"
};

(node as any).hash = "416ee9c2ca1a0709882a616f7a6e726b";

export default node;
