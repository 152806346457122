/**
 * @generated SignedSource<<ba077e23c10879ff98a9c438fdda2517>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type heading_issueViewLayoutParentField_ParentFieldHeading$data = {
  readonly fieldId: string;
  readonly name: string;
  readonly " $fragmentType": "heading_issueViewLayoutParentField_ParentFieldHeading";
};
export type heading_issueViewLayoutParentField_ParentFieldHeading$key = {
  readonly " $data"?: heading_issueViewLayoutParentField_ParentFieldHeading$data;
  readonly " $fragmentSpreads": FragmentRefs<"heading_issueViewLayoutParentField_ParentFieldHeading">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "heading_issueViewLayoutParentField_ParentFieldHeading",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    }
  ],
  "type": "JiraIssueField",
  "abstractKey": "__isJiraIssueField"
};

(node as any).hash = "03a209f40de3d3586c7350121fd3474f";

export default node;
