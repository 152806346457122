import React from 'react';
import { useFragment, graphql } from 'react-relay';
import Avatar from '@atlaskit/avatar';
import { Box, Inline, xcss } from '@atlaskit/primitives';
import { EmptyFieldText } from '@atlassian/jira-issue-empty-field-text/src/ui/index.tsx';
import type {
	project_issueFieldProjectReadviewFull_ProjectReadView$key as ProjectSelectFragment,
	project_issueFieldProjectReadviewFull_ProjectReadView$data as AggProjectSelectTypes,
} from '@atlassian/jira-relay/src/__generated__/project_issueFieldProjectReadviewFull_ProjectReadView.graphql';
import { Link } from '@atlassian/react-resource-router';
import type { ProjectReadViewProps } from './types.tsx';

const getFieldValueAsText = (project: AggProjectSelectTypes['project']) => {
	if (!project) return null;
	return (
		<Box testId="issue-field-project-readview-full.ui.project.container" xcss={boxStyles}>
			{project.avatar?.small && (
				<Box xcss={imgStyles}>
					<Avatar
						testId={`issue-field-project-editview-full.ui.project.issue-view-project-editview-avatar-${project.key}`}
						appearance="square"
						size="xsmall"
						src={project.avatar.small}
						name={project.name}
						borderColor="transparent"
					/>
				</Box>
			)}
			<Inline xcss={inlineStyles}>
				<Link
					data-testid="issue-field-project-readview-full.ui.project.label-link"
					href={`/projects/${project.key}`}
				>
					{project.name}
				</Link>
			</Inline>
		</Box>
	);
};

/**
 * The ProjectReadView will show a read only view of Project field.
 * @param props [ProjectReadViewProps](./types.tsx)
 */
export const ProjectReadView = ({ fragmentRef }: ProjectReadViewProps) => {
	const { project } = useFragment<ProjectSelectFragment>(
		graphql`
			fragment project_issueFieldProjectReadviewFull_ProjectReadView on JiraProjectField {
				project {
					key
					name
					avatar {
						small
					}
				}
			}
		`,
		fragmentRef,
	);

	const fieldValueAsText = getFieldValueAsText(project);

	if (!fieldValueAsText) {
		return <EmptyFieldText />;
	}

	return <>{fieldValueAsText}</>;
};

const boxStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	width: '100%',
});

const imgStyles = xcss({
	display: 'flex',
	paddingRight: 'space.100',
});

const inlineStyles = xcss({
	display: 'inline',
	color: 'color.link',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});
