import type { Observable as ObservableType } from 'rxjs/Observable';
import fetchText$ from '@atlassian/jira-fetch/src/utils/as-text-stream.tsx';

export const getRankBeforeUrl = (baseUrl: string): string =>
	`${baseUrl}/rest/greenhopper/1.0/api/rank/before`;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	baseUrl: string,
	customFieldId: number,
	issueIds: number[],
	rankBeforeId: number,
): ObservableType<string | null> =>
	fetchText$(getRankBeforeUrl(baseUrl), {
		method: 'PUT',
		body: JSON.stringify({
			customFieldId,
			issueIds,
			rankBeforeId,
		}),
	});
