import React, { type FC, type ReactNode } from 'react';
import { FormattedMessage } from '@atlassian/jira-intl';
import { FeedbackButton } from './feedback-button/button.tsx';
import type { Props } from './types.tsx';

const Feedback: FC<Props> = (props) => (
	<FormattedMessage
		id="software-backlog-issue.labs-feature-button-tooltip"
		defaultMessage="Give feedback"
	>
		{(tooltip: ReactNode) => <FeedbackButton {...props} tooltip={tooltip} />}
	</FormattedMessage>
);

export default Feedback;
