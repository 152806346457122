import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import {
	fireOperationalAnalytics,
	type Attributes,
} from '@atlassian/jira-product-analytics-bridge';

const triggerCacheEvent = (
	createAnalyticsEvent?: CreateUIAnalyticsEvent | null,
	// @ts-expect-error - TS1016 - A required parameter cannot follow an optional parameter.
	cacheHit: boolean,
	prefetchSessionId: string,
	fieldSelectionSessionId: string,
	fieldId: string,
	fieldPrefetchSessionId?: string,
) => {
	if (createAnalyticsEvent === undefined || createAnalyticsEvent === null) {
		return;
	}

	const action = 'access';
	const actionSubject = 'fieldPrefetchCache';

	const event = createAnalyticsEvent({
		action,
		actionSubject,
		actionSubjectId: fieldSelectionSessionId,
	});

	const attrs: Attributes = {
		fieldSelectionSessionId,
		fieldFetchSessionId: fieldPrefetchSessionId,
		prefetchSessionId,
		cacheHit,
		fieldId,
	};

	fireOperationalAnalytics(event, `${actionSubject} ${action}`, fieldSelectionSessionId, attrs);
};

export const triggerCacheHitEvent = (
	createAnalyticsEvent?: CreateUIAnalyticsEvent | null,
	// @ts-expect-error - TS1016 - A required parameter cannot follow an optional parameter.
	prefetchSessionId: string,
	fieldSelectionSessionId: string,
	fieldPrefetchSessionId: string,
	fieldId: string,
) =>
	triggerCacheEvent(
		createAnalyticsEvent,
		true,
		prefetchSessionId,
		fieldSelectionSessionId,
		fieldId,
		fieldPrefetchSessionId,
	);

export const triggerCacheMissEvent = (
	createAnalyticsEvent?: CreateUIAnalyticsEvent | null,
	// @ts-expect-error - TS1016 - A required parameter cannot follow an optional parameter.
	prefetchSessionId: string,
	fieldSelectionSessionId: string,
	fieldId: string,
) =>
	triggerCacheEvent(
		createAnalyticsEvent,
		false,
		prefetchSessionId,
		fieldSelectionSessionId,
		fieldId,
	);
