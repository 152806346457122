import difference from 'lodash/difference';
import type { FragmentRefs } from 'relay-runtime';
import type { Location } from '@atlassian/jira-development-board-common/src/common/types.tsx';
import type {
	DeploymentEnvironment,
	DeploymentState,
} from '@atlassian/jira-development-board-dev-info-icon/src/types.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type { CloudId, IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import {
	createHook,
	createStore,
	createContainer,
	type StoreActionApi,
} from '@atlassian/react-sweet-state';
import { getAnalyticsMeta } from '../common/utils.tsx';
import { fetchDevOpsSummaries } from './fetch/index.tsx';

export type DevOpsBuildState =
	| 'CANCELLED'
	| 'FAILED'
	| 'IN_PROGRESS'
	| 'PENDING'
	| 'SUCCESSFUL'
	| 'UNKNOWN';

type DevOpsSummary = {
	summarisedDeployments: {
		count: number | null;
		deploymentEnvironment: {
			category: DeploymentEnvironment | null;
		} | null;
		deploymentState: DeploymentState | null;
	} | null;
	summarisedBuilds: {
		count: number | null;
		' $fragmentSpreads': FragmentRefs<'buildData_softwareReleasesVersionDetailIssueList_devOpsSummarisedBuilds'>;
	} | null;
	' $fragmentSpreads': FragmentRefs<'deploymentData_softwareReleasesVersionDetailIssueList'>;
};

/**
 * Uses issue ids as keys
 */
type DevOpsSummaries = Record<string, DevOpsSummary | null>;

type State = {
	devOpsSummaries: DevOpsSummaries;
	fetchingIssueIds: IssueId[];
	error: Error | null;
	loading: boolean;
	// Will be non-null after the initial render
	location: Location | null;
};

type StoreApi = StoreActionApi<State>;

type Actions = typeof actions;

const toMapUsingKeys = <K, T>(list: Readonly<T[]> = [], keys: K[]) =>
	Object.fromEntries(list.map((item, idx) => [keys[idx], item]));

const actions = {
	fetchDevOpsSummaries:
		(cloudId: CloudId, issueIds: IssueId[], location: Location) =>
		async ({ getState, setState }: StoreApi) => {
			setState({ location });

			const state = getState();
			const fetchedIds = Object.keys(state.devOpsSummaries);
			// Do not fetch any already fetched/fetching issues
			const idsToFetch = difference(issueIds, fetchedIds, state.fetchingIssueIds);
			if (idsToFetch.length === 0) {
				return;
			}

			let summaries;

			setState({ fetchingIssueIds: idsToFetch, loading: true });
			try {
				summaries = await fetchDevOpsSummaries(cloudId, idsToFetch);
			} catch (error) {
				fireErrorAnalytics({
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					error: error as Error,
					meta: getAnalyticsMeta('fetch-dev-ops-summaries.failed'),
					sendToPrivacyUnsafeSplunk: true,
				});
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				setState({ error: error as Error });
				return;
			} finally {
				setState({ fetchingIssueIds: [], loading: false });
			}

			const newDevOpsSummaries = toMapUsingKeys(summaries, idsToFetch);
			setState({
				devOpsSummaries: {
					...state.devOpsSummaries,
					...newDevOpsSummaries,
				},
				error: null,
			});
		},
};

const initialState: State = {
	devOpsSummaries: {},
	fetchingIssueIds: [],
	error: null,
	loading: true,
	location: null,
};

const store = createStore<State, Actions>({
	name: 'dev-ops-summary',
	initialState,
	actions,
});

export const useIssueDevOpsSummary = createHook(store, {
	selector: (state: State, issueId: IssueId) => state.devOpsSummaries[issueId],
});

export const useIssueDevOpsSummaryError = createHook(store, {
	selector: (state: State) => state.error,
});

export const useIssueDevOpsSummaryLoading = createHook(store, {
	selector: (state: State) => state.loading,
});

export const useAnalyticsLocation = createHook(store, {
	selector: (state: State) => state.location,
});

type ContainerProps = {
	cloudId: CloudId;
	issueIds: IssueId[];
	location: Location;
};

const refreshContainer = (
	{ dispatch }: StoreApi,
	{ cloudId, issueIds, location }: ContainerProps,
) => {
	dispatch(actions.fetchDevOpsSummaries(cloudId, issueIds, location));
};

export const DevOpsSummaryContainer = createContainer<State, Actions, ContainerProps>(store, {
	onInit: () => refreshContainer,
	onUpdate: () => refreshContainer,
});
