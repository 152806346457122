/**
 * @generated SignedSource<<1fa60d0b1a3a8ed0caa871a0c71dbfdc>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutMetaUpdatedDateField_IssueViewUpdatedDateField$data = {
  readonly dateTime: AGG$DateTime | null | undefined;
  readonly " $fragmentType": "ui_issueViewLayoutMetaUpdatedDateField_IssueViewUpdatedDateField";
};
export type ui_issueViewLayoutMetaUpdatedDateField_IssueViewUpdatedDateField$key = {
  readonly " $data"?: ui_issueViewLayoutMetaUpdatedDateField_IssueViewUpdatedDateField$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutMetaUpdatedDateField_IssueViewUpdatedDateField">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutMetaUpdatedDateField_IssueViewUpdatedDateField",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "dateTime"
    }
  ],
  "type": "JiraDateTimePickerField"
};

(node as any).hash = "34f406f9cda9f12c9ce457379082edbd";

export default node;
