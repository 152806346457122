import React, {
	type ReactNode,
	useCallback,
	useEffect,
	type MouseEvent,
	useState,
	useRef,
} from 'react';
import noop from 'lodash/noop';
import Button from '@atlaskit/button';
import { IconButton } from '@atlaskit/button/new';
import DropdownMenu, {
	DropdownItemGroup,
	type CustomTriggerProps,
	type OnOpenChangeArgs,
} from '@atlaskit/dropdown-menu';
import AddIcon from '@atlaskit/icon/core/migration/add';
import AppIcon from '@atlaskit/icon/core/migration/app--addon';
import ShowMoreHorizontalIcon from '@atlaskit/icon/core/migration/show-more-horizontal--more';
import { SpotlightPulse } from '@atlaskit/onboarding';
import { Inline } from '@atlaskit/primitives';
import AkToolTip from '@atlaskit/tooltip';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import type { EcosystemContentPanel } from '@atlassian/jira-issue-gira-transformer-types/src/common/types/ecosystem.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import QuickActionsChangeboarding, {
	type SpotlightTargetCoordinates,
} from '../quick-actions-changeboarding/changeboarding-message/index.tsx';
import { useShowSpotlight } from '../quick-actions-changeboarding/use-show-spotlight/index.tsx';
import { messages } from './messages.tsx';

type Props = {
	actions: ReactNode[];
	apps: ReactNode;
	availableAddons?: EcosystemContentPanel[];
	setMenuOpen: (menuName: string, isOpen: boolean) => void;
	openMenu: string | null;
	canInstallAddons?: boolean;
};

export const QuickAddItemsCompact = (props: Props) => {
	const { actions, apps, availableAddons, setMenuOpen, openMenu, canInstallAddons } = props;
	const { formatMessage } = useIntl();
	const add = formatMessage(messages.add);
	const appsMessage = formatMessage(messages.apps);
	const dropdownRef = useRef<HTMLElement>(null);
	const spotlightRef = useRef<HTMLElement>(null);
	const [isSpotlightActive, setIsSpotlightActive] = useState(false);
	const [spotlightTargetCoordinates, setSpotlightTargetCoordinates] =
		useState<SpotlightTargetCoordinates | null>(null);

	const { shouldShowSpotlight, updateShouldShowSpotlight } = fg('quick_actions_menu_ga')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useShowSpotlight()
		: { shouldShowSpotlight: false, updateShouldShowSpotlight: noop };

	const filteredAddons =
		availableAddons &&
		availableAddons.filter(
			(addon) =>
				!(
					addon.moduleKey.includes('linked-goals-content-panel') &&
					expVal('native_goals_on_issue_view', 'isGoalsFieldEnabled', false)
				),
		);

	const availableApps = filteredAddons && filteredAddons.length >= 1;

	useEffect(() => {
		// eslint-disable-next-line jira/ff/no-preconditioning
		if (fg('quick_actions_menu_ga') && openMenu === 'addButton' && dropdownRef.current) {
			// used timeout here as dropdown takes some time to take it's final position, maybe due to animation
			setTimeout(() => {
				const dropdownRect = dropdownRef.current?.getBoundingClientRect();
				if (dropdownRect) {
					const { top, left, right, bottom } = dropdownRect;
					setSpotlightTargetCoordinates({
						top,
						left,
						right,
						bottom,
					});
				}
			});
		}
	});

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onClickWithAnalytics = useCallback(
		(originalOnClick?: (event: MouseEvent<HTMLElement>) => void, buttonState?: string) =>
			(event: MouseEvent<HTMLElement>) => {
				if (originalOnClick) {
					originalOnClick(event);
				}

				if (buttonState) {
					fireUIAnalytics(
						createAnalyticsEvent({}),
						'button clicked',
						`quickActionButtonRevamp${buttonState}`,
					);
				}
			},
		[createAnalyticsEvent],
	);

	useEffect(() => {
		// eslint-disable-next-line jira/ff/no-preconditioning
		if (fg('quick_actions_menu_ga') && dropdownRef?.current && !isSpotlightActive) {
			const firstMenuItem = dropdownRef?.current?.querySelector('[role="menuitem"]');
			if (firstMenuItem) {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				(firstMenuItem as HTMLElement).focus();
			}
		}
	}, [isSpotlightActive, dropdownRef]);

	const dismissSpotlight = useCallback(() => {
		setIsSpotlightActive(false);
		updateShouldShowSpotlight();
	}, [setIsSpotlightActive, updateShouldShowSpotlight]);

	const toggleMenuOpen: (menuName: string) => (params: OnOpenChangeArgs) => void = useCallback(
		(menuName: string) =>
			({ isOpen, event }: OnOpenChangeArgs) => {
				// eslint-disable-next-line jira/ff/no-preconditioning
				if (fg('quick_actions_menu_ga') && menuName === 'addButton' && shouldShowSpotlight) {
					if (
						spotlightRef.current &&
						event.target &&
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						spotlightRef.current.contains(event.target as Node)
					) {
						return;
					}

					if (fg('qa_ga_remove_changeboarding')) {
						if (!isOpen) {
							updateShouldShowSpotlight();
						}
					} else if (!isOpen) {
						dismissSpotlight();
					} else {
						setIsSpotlightActive(isOpen);
					}
				}

				setMenuOpen(menuName, isOpen);
			},
		[shouldShowSpotlight, setMenuOpen, dismissSpotlight, updateShouldShowSpotlight],
	);

	const renderDropdownTrigger = ({ triggerRef, onClick, ...rest }: CustomTriggerProps) => {
		if (fg('quick_actions_menu_ga')) {
			if (fg('qa_ga_remove_changeboarding')) {
				return (
					<AkToolTip content={formatMessage(messages.buttonAddContent)}>
						<SpotlightPulse radius={3} pulse={shouldShowSpotlight}>
							<Button
								{...rest}
								label={formatMessage(messages.buttonAddContent)}
								ref={triggerRef}
								onClick={onClickWithAnalytics(onClick, 'Add')}
								iconBefore={
									<AddIcon color="currentColor" spacing="none" label="" LEGACY_size="small" />
								}
							>
								{add}
							</Button>
						</SpotlightPulse>
					</AkToolTip>
				);
			}
			return (
				<SpotlightPulse radius={3} pulse={shouldShowSpotlight}>
					<Button
						{...rest}
						label={formatMessage(messages.buttonAddContent)}
						ref={triggerRef}
						onClick={onClickWithAnalytics(onClick, 'Add')}
						iconBefore={
							<AddIcon color="currentColor" spacing="none" label="" LEGACY_size="small" />
						}
					>
						{add}
					</Button>
				</SpotlightPulse>
			);
		}

		return (
			<Button
				{...rest}
				label={formatMessage(messages.buttonAddContent)}
				ref={triggerRef}
				onClick={onClickWithAnalytics(onClick, 'Add')}
				iconBefore={<AddIcon color="currentColor" spacing="none" label="" LEGACY_size="small" />}
			>
				{add}
			</Button>
		);
	};

	return (
		<>
			<Inline space="space.100">
				<UFOSegment name="issue-quick-add-button">
					<DropdownMenu
						trigger={renderDropdownTrigger}
						testId="issue-view-foundation.quick-add.quick-add-items-compact.add-button-dropdown"
						shouldRenderToParent
						onOpenChange={toggleMenuOpen('addButton')}
						isOpen={openMenu === 'addButton'}
					>
						<DropdownItemGroup ref={fg('quick_actions_menu_ga') ? dropdownRef : undefined}>
							{actions}
						</DropdownItemGroup>
					</DropdownMenu>
				</UFOSegment>
				{(availableApps || canInstallAddons) && (
					<UFOSegment name="issue-quick-apps-button">
						<DropdownMenu
							trigger={({ triggerRef, onClick, ...rest }) => (
								<AkToolTip
									content={
										availableApps
											? formatMessage(messages.buttonAddApps)
											: formatMessage(messages.buttonAddAppsNoApps)
									}
								>
									{availableApps || fg('quick_actions_menu_ga') ? (
										<Button
											{...rest}
											label={formatMessage(messages.buttonAddApps)}
											ref={triggerRef}
											onClick={onClickWithAnalytics(onClick, 'Apps')}
											iconBefore={
												<AppIcon color="currentColor" label="" spacing="none" LEGACY_size="small" />
											}
										>
											{appsMessage}
										</Button>
									) : (
										<IconButton
											{...rest}
											ref={triggerRef}
											label={formatMessage(messages.buttonAddAppsNoApps)}
											onClick={onClickWithAnalytics(onClick, '...')}
											icon={() => (
												<ShowMoreHorizontalIcon
													color="currentColor"
													spacing="spacious"
													label=""
													LEGACY_size="small"
												/>
											)}
										/>
									)}
								</AkToolTip>
							)}
							testId="issue-view-foundation.quick-add.quick-add-items-compact.apps-button-dropdown"
							onOpenChange={toggleMenuOpen('appsButton')}
							isOpen={openMenu === 'appsButton'}
						>
							{apps}
						</DropdownMenu>
					</UFOSegment>
				)}
			</Inline>
			{
				// eslint-disable-next-line jira/ff/no-preconditioning
				!fg('qa_ga_remove_changeboarding') && fg('quick_actions_menu_ga') && isSpotlightActive && (
					<QuickActionsChangeboarding
						targetCoordinates={spotlightTargetCoordinates}
						ref={spotlightRef}
						onCloseSpotlight={dismissSpotlight}
					/>
				)
			}
		</>
	);
};

export default QuickAddItemsCompact;
