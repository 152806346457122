import has from 'lodash/has';
import isArray from 'lodash/isArray';
import keys from 'lodash/keys';
import uniq from 'lodash/uniq';

export const isShallowEqual = <T, U>(a: T, b: U, debug = false): boolean => {
	if (isArray(a) && isArray(b)) {
		if (a.length !== b.length) {
			if (debug) {
				// eslint-disable-next-line no-console
				console.log('arrays not of equal length', a, b);
			}
			return false;
		}

		return a.every((value, index) => {
			if (value !== b[index]) {
				if (debug) {
					// eslint-disable-next-line no-console
					console.log(`[${index}]`, value, '!=', b[index]);
				}
				return false;
			}
			return true;
		});
	}

	return uniq([...keys(a), ...keys(b)]).every((key) => {
		if (!has(b, key)) {
			if (debug) {
				// eslint-disable-next-line no-console
				console.log('property missing in [arg2]', key);
			}
			return false;
		}
		if (!has(a, key)) {
			if (debug) {
				// eslint-disable-next-line no-console
				console.log('property missing in [arg1]', key);
			}
			return false;
		}
		// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'unknown'. | TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'unknown'.
		if (!Object.is(a[key], b[key])) {
			if (debug) {
				/* eslint-disable-next-line no-console */ // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'unknown'. | TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'unknown'.
				console.log(`[${key}]`, a[key], '!=', b[key]);
			}
			return false;
		}
		return true;
	});
};
