import React, { type ComponentType, useMemo, useCallback, useEffect, useRef } from 'react';
import { compose } from 'redux';
import memoizeOne from 'memoize-one';
import { graphql } from 'react-relay';
import uuid from 'uuid';
import { ErrorMessage } from '@atlaskit/form';
import { Stack } from '@atlaskit/primitives';
import type { ActionMeta } from '@atlassian/jira-common-components-picker/src/model.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useFlagsService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import type { SessionId } from '@atlassian/jira-issue-analytics/src/common/types.tsx';
import { useIssueId, useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { FRAGMENT_SELECTABLE_FIELD_OPTIONS_FIRST } from '@atlassian/jira-issue-field-constants/src/index.tsx';
import { ReleaseVersionsEditView } from '@atlassian/jira-issue-field-release-versions-editview-full/src/ui/release-versions/index.tsx';
import { useReleaseVersionsCache } from '@atlassian/jira-issue-field-release-versions-inline-edit-view-full/src/services/release-versions-cache-services/index.tsx';
import { useFieldRanker } from '@atlassian/jira-issue-fields-ranker/src/services/index.tsx';
import { useQueryLoaderOnIntent } from '@atlassian/jira-issue-hooks/src/services/use-query-loader-on-intent/index.tsx';
import type { Option as SelectOption } from '@atlassian/jira-issue-internal-field-select/src/common/select-inline-edit/select-field/types.tsx';
import FixVersionsView, {
	type Props as FixVersionsProps,
} from '@atlassian/jira-issue-internal-fields/src/fix-version/view.tsx';
import type {
	SelectableFieldEditViewWithoutFragmentProps,
	SelectableValueOption,
} from '@atlassian/jira-issue-selectable-field-edit-view/src/ui/types.tsx';
import { genericMessages } from '@atlassian/jira-issue-view-common-constants/src/context-items-messages.tsx';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button/index.tsx';
import connectField from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-field.tsx';
import { withExtraOwnProps } from '@atlassian/jira-issue-view-common-views/src/with-extra-own-props/index.tsx';
import { fieldARISelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector.tsx';
import { projectIdSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';
import {
	canAdministerProjectPermissionsSelector,
	canAdministerJiraPermissionsSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/permissions-selector.tsx';
import {
	FIX_VERSIONS_TYPE,
	AFFECTS_VERSIONS_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import { useProjectId, useProjectKey } from '@atlassian/jira-project-context-service/src/main.tsx';
import type { versionsWithRelayEditViewFieldOptionsPrefetchIntentQuery } from '@atlassian/jira-relay/src/__generated__/versionsWithRelayEditViewFieldOptionsPrefetchIntentQuery.graphql';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import messages from './messages.tsx';
import {
	transformFromStateValue,
	transformToStateValue,
	transformToStateValueSingleSelect,
	transformRelayToReduxValue,
	transformReduxToRelayValue,
	transformFromStateValueSingleSelect,
} from './transformer.tsx';
import { limitOptionPerCategory, sortOptions } from './utils.tsx';

type HandleOnChange = SelectableFieldEditViewWithoutFragmentProps['onChange'];
type GetCustomInlineEditViewProps = {
	onChange: FixVersionsProps['onChange'];
	value: FixVersionsProps['value'];
	noOptionsMessage?: FixVersionsProps['noOptionsMessage'];
	loadingMessage?: FixVersionsProps['loadingMessage'];
	isInvalid?: FixVersionsProps['isInvalid'];
	fieldId: FixVersionsProps['fieldId'];
	footer?: FixVersionsProps['footer'];
	onFooterSelect?: ((value: string) => void) | undefined;
	shouldShowFooter?: boolean;
	invalidMessage?: string;
};

export const getCustomInlineEditView = memoizeOne(
	(ari?: string, isMulti = true) =>
		({
			onChange,
			value,
			noOptionsMessage,
			loadingMessage,
			isInvalid,
			fieldId,
			footer,
			onFooterSelect,
			shouldShowFooter,
			invalidMessage,
		}: GetCustomInlineEditViewProps) => {
			const { formatMessage } = useIntl();

			const issueId = useIssueId();
			const issueKey = useIssueKey();
			const projectKey = useProjectKey(issueKey);
			const projectId = useProjectId(projectKey);
			const sessionId = useRef<SessionId>(uuid.v4());

			const isReleaseVersionsField =
				fieldId === FIX_VERSIONS_TYPE || fieldId === AFFECTS_VERSIONS_TYPE;
			const canCreateVersion = shouldShowFooter && isReleaseVersionsField;

			const [cachedOptions, { addToCache, getAllCachedVersions, refreshCachedVersions }] =
				useReleaseVersionsCache(String(projectId), ari);

			useEffect(() => {
				(async function getCachedVersions() {
					await getAllCachedVersions();
					refreshCachedVersions();
				})();
			}, [getAllCachedVersions, refreshCachedVersions]);

			const { rankFieldOptions } = useFieldRanker({
				fieldId,
				issueId,
				projectId,
				experience: 'JiraFields',
				sessionId: sessionId.current,
			});

			const handleChange: HandleOnChange = (values, actionMeta) => {
				const legacyValues = values?.map(transformRelayToReduxValue) ?? [];
				const legacyActionMeta: ActionMeta<SelectOption> = {
					action: actionMeta.action,
					// value was selected
					...(actionMeta.option
						? {
								option: transformRelayToReduxValue(actionMeta.option),
							}
						: null),
					// value was removed
					...(actionMeta.removedValue
						? {
								removedValue: transformRelayToReduxValue(actionMeta.removedValue),
							}
						: null),
				};

				onChange?.(legacyValues, legacyActionMeta);

				if (values) {
					addToCache(values);
				}
			};

			const selectedOptions = useMemo(
				() => (value ? value.map(transformReduxToRelayValue) : []),
				[value],
			);

			const loadOptions = useCallback(
				async (options: SelectableValueOption[]) => {
					const rankedOptions = await rankFieldOptions(options, (option) => ({
						key: option.versionId ?? '',
						value: option.selectableLabel ?? '',
					}));
					return limitOptionPerCategory(rankedOptions);
				},
				[rankFieldOptions],
			);

			const getClassNamePrefix = (field: string): string => {
				switch (field) {
					case FIX_VERSIONS_TYPE:
					case AFFECTS_VERSIONS_TYPE:
						return 'issue-view-fix-versions';
					default:
						return '';
				}
			};

			return (
				<Stack space="space.075">
					{Boolean(invalidMessage) && <ErrorMessage>{invalidMessage}</ErrorMessage>}
					<ReleaseVersionsEditView
						autoFocus
						fieldId={ari || ''}
						value={selectedOptions}
						loadOptions={isReleaseVersionsField ? loadOptions : undefined}
						sortOptions={sortOptions}
						cachedOptions={cachedOptions}
						onChange={handleChange}
						noOptionsMessage={noOptionsMessage}
						loadingMessage={loadingMessage}
						isInvalid={isInvalid}
						spacing="compact"
						classNamePrefix={getClassNamePrefix(fieldId)}
						footer={footer}
						onFooterSelect={onFooterSelect}
						shouldShowFooter={canCreateVersion}
						recentOptionsLabel={formatMessage(messages.recentVersions)}
						{...(fg('relay-migration-issue-fields-issue-version')
							? {
									isMulti,
									isClearable: true,
								}
							: { isMulti: true })}
					/>
				</Stack>
			);
		},
);

export type VersionsWithPrefetchFieldOptionsProps = {
	ari: string;
} & FixVersionsProps &
	SelectableFieldEditViewWithoutFragmentProps;

export const VersionsWithPrefetchFieldOptions = (props: VersionsWithPrefetchFieldOptionsProps) => {
	const { showFlag } = useFlagsService();
	const { formatMessage } = useIntl();

	const [, { addToCache }] = useReleaseVersionsCache(String(props.projectId), props.ari);

	const handleOnVersionCreated: FixVersionsProps['onVersionCreated'] = useCallback(
		(version) => {
			addToCache([
				{
					id: version.ari || '',
					selectableLabel: version.name,
					selectableGroupKey: version.status,
					selectableIconUrl: null,
					versionId: version.id,
					isDisabled: false,
				},
			]);

			showFlag({
				type: 'success',
				title: formatMessage(messages.successFlagCreation, { name: version.name }),
			});
		},
		[addToCache, formatMessage, showFlag],
	);

	const [, prefetchOptions, abortPrefetchOptions] =
		// eslint-disable-next-line @atlassian/relay/must-use-inline
		useQueryLoaderOnIntent<versionsWithRelayEditViewFieldOptionsPrefetchIntentQuery>(graphql`
			query versionsWithRelayEditViewFieldOptionsPrefetchIntentQuery($id: ID!, $first: Int) {
				# eslint-disable-next-line @atlassian/relay/must-colocate-fragment-spreads
				...ui_issueSelectableFieldEditView_SelectableFieldEditViewWithFieldOptionsFragment
					@arguments(id: $id, first: $first)
			}
		`);

	return (
		<UFOSegment name="issue-field-versions">
			<FixVersionsView
				{...props}
				onVersionCreated={handleOnVersionCreated}
				onInlineEditContainerPointerEnter={() =>
					prefetchOptions({
						id: props.ari,
						first: FRAGMENT_SELECTABLE_FIELD_OPTIONS_FIRST,
					})
				}
				onInlineEditContainerPointerLeave={(e) =>
					e.pointerType === 'mouse' && abortPrefetchOptions()
				}
			/>
		</UFOSegment>
	);
};

export const VersionsFieldWithRelayEditView = compose<
	ComponentType<{ fieldId: string; area: string }>
>(
	withExtraOwnProps,
	connectField((_, ownPropsOnMount) => {
		const fieldARISelectorGetter = fieldARISelector(ownPropsOnMount.fieldId);
		return {
			fieldId: ownPropsOnMount.fieldId,
			transformFromStateValue,
			transformToStateValue,
			additionalProps: (state, intl) => ({
				noValueText: intl.formatMessage(genericMessages.noValue),
				showPinButton: getShowPinButton(ownPropsOnMount.area),
				customEditView: getCustomInlineEditView(fieldARISelectorGetter(state)),
				projectId: projectIdSelector(state),
				ari: fieldARISelectorGetter(state),
				createNewItemMessage: intl.formatMessage(messages.createNewItem),
				createNewItemIconLabel: intl.formatMessage(messages.createNewIcon),
				canAdministerProject: canAdministerProjectPermissionsSelector(state),
				canAdministerJira: canAdministerJiraPermissionsSelector(state),
			}),
		};
	}),
)(VersionsWithPrefetchFieldOptions);

export const SingleSelectVersionCustomFieldWithRelayEditView = compose<
	ComponentType<{ fieldId: string; area: string }>
>(
	withExtraOwnProps,
	connectField((_, ownPropsOnMount) => {
		const fieldARISelectorGetter = fieldARISelector(ownPropsOnMount.fieldId);
		return {
			fieldId: ownPropsOnMount.fieldId,
			transformFromStateValue: transformFromStateValueSingleSelect,
			transformToStateValue: transformToStateValueSingleSelect,
			additionalProps: (state, intl) => ({
				noValueText: intl.formatMessage(genericMessages.noValue),
				showPinButton: getShowPinButton(ownPropsOnMount.area),
				customEditView: getCustomInlineEditView(fieldARISelectorGetter(state), false),
				projectId: projectIdSelector(state),
				ari: fieldARISelectorGetter(state),
				createNewItemMessage: intl.formatMessage(messages.createNewItem),
				createNewItemIconLabel: intl.formatMessage(messages.createNewIcon),
				canAdministerProject: canAdministerProjectPermissionsSelector(state),
				canAdministerJira: canAdministerJiraPermissionsSelector(state),
			}),
		};
	}),
)(VersionsWithPrefetchFieldOptions);
