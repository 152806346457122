import type { Epic } from 'redux-observable';
import initiateStore from '@atlassian/jira-common-tangerine/src/state/initiate.tsx';
import type { State } from '../model/types.tsx';
import type { Action } from './actions.tsx';
import rootReducer from './reducer.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (rootEpic?: Epic<Action, State>) =>
	initiateStore<State, Action, undefined>({
		appName: 'issue/watchers',
		rootReducer,
		rootEpic,
	});
