import type { DocNode } from '@atlaskit/adf-schema';
import type { Message } from '@atlassian/conversation-assistant';
import {
	TOO_MANY_REQUESTS,
	INTERNAL_SERVER_ERROR,
} from '@atlassian/jira-common-constants/src/http-status-codes.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { AiMateXProductType } from '@atlassian/jira-issue-hooks/src/services/use-ai-mate-product-type/index.tsx';
import { sendExperienceAnalytics } from '@atlassian/jira-issue-view-analytics/src/controllers/send-experience-analytics/index.tsx';
import type { Application } from '@atlassian/jira-shared-types/src/application.tsx';
import type { ApplicationEdition } from '@atlassian/jira-shared-types/src/edition.tsx';
import {
	AI_MATE_URL,
	GENERAL_ERROR_ADF,
	RATE_LIMITED_ADF,
	ACCEPTABLE_USAGE_ADF,
} from './constants.tsx';
import type { AgentResponse, AgentError } from './types.tsx';

export const mapErrorToCode = (node: DocNode | Message | null): string | undefined => {
	switch (node) {
		case ACCEPTABLE_USAGE_ADF:
			return 'ACCEPTABLE_USAGE';
		case RATE_LIMITED_ADF:
			return 'RATE_LIMITED';
		case GENERAL_ERROR_ADF:
			return 'GENERAL_ERROR';
		default:
			return undefined;
	}
};

// The status code belongs to this list won't be sent as taskFail
const STATUS_CODE_RELIABILITY_WHITE_LIST = [400, 401, 403, 429];

const mapErrorStatusToAdfError = (statusCode: number) => {
	switch (statusCode) {
		case TOO_MANY_REQUESTS:
			return RATE_LIMITED_ADF;
		default:
			return GENERAL_ERROR_ADF;
	}
};

const mapMessageTemplateErrorToAdfError = (messageTemplate: AgentError['message_template']) => {
	switch (messageTemplate) {
		case 'ACCEPTABLE_USE_VIOLATIONS':
			return ACCEPTABLE_USAGE_ADF;
		default:
			return GENERAL_ERROR_ADF;
	}
};

export const getSummaryFromAIMate = async ({
	currentUrl,
	productType,
	application,
	edition,
}: {
	currentUrl: string;
	productType: AiMateXProductType;
	application?: Application;
	edition?: ApplicationEdition;
}): Promise<Message | DocNode> => {
	const sendSloMetrics = (
		wasExperienceSuccesful: boolean,
		errorMessage?: string,
		statusCode?: number,
	) => {
		const experience = 'jiraSmartSummarizeComments';
		const analyticsSource = 'requestSummaryQuery';
		const additionalAttributes = {
			...(errorMessage && { error: errorMessage }),
			...(statusCode && { statusCode }),
			isStreamingExperience: false,
		};
		sendExperienceAnalytics({
			experience,
			wasExperienceSuccesful,
			analyticsSource,
			application: application ?? null,
			edition: edition ?? null,
			additionalAttributes,
		});
	};

	try {
		const response: AgentResponse | AgentError = await performPostRequest(AI_MATE_URL, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json;charset=UTF-8',
				'X-Experience-Id': 'jira-smart-summary',
				'x-product': fg('jira_ai_fix_spork_bug') ? 'jira' : productType,
			},
			body: JSON.stringify({
				recipient_agent_named_id: 'summary_agent',
				agent_input_prompt:
					'Summarise the comments in this ticket, focus on the essential context points, key decisions made, and specified action items',
				agent_input_context: {
					content_url: currentUrl,
					summary_style: 'medium',
					summary_output_mimetype: 'text/markdown',
					prompt_id: 'jira-smart-summary',
				},
			}),
		});

		// means there is an error scenario that is outside of 429/internal error
		// stuff like ethical filtering.
		if ('message_template' in response) {
			log.safeInfoWithoutCustomerData(
				'issue.smart-request-summary.request-summary-state.src.services.ai-mate.getSummaryFromAIMate',
				response.message_template,
			);
			return mapMessageTemplateErrorToAdfError(response.message_template);
		}

		sendSloMetrics(true);
		return response.message;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		if (error instanceof FetchError && error.originalResponse) {
			const errorObj = await error.originalResponse.json();
			fireErrorAnalytics({
				error,
				meta: {
					id: 'getSummaryAiMate',
					packageName: 'jiraIssueSmartRequestSummaryState',
					teamName: 'crocs',
				},
				attributes: { statusCode: error.statusCode, errorType: errorObj.message_template },
				skipSentry: true,
			});

			if (
				!isClientFetchError(error) &&
				!STATUS_CODE_RELIABILITY_WHITE_LIST.includes(error.statusCode)
			) {
				sendSloMetrics(false, errorObj.content || error.message, error.statusCode);
			}

			return mapErrorStatusToAdfError(error.statusCode);
		}

		fireErrorAnalytics({
			error,
			meta: {
				id: 'getSummaryAiMate',
				packageName: 'jiraIssueSmartRequestSummaryState',
				teamName: 'crocs',
			},
			attributes: { statusCode: error.statusCode, errorType: error.message },
			skipSentry: true,
		});

		// Client errors should not be sent as taskFail event
		// This is a quick fix for the `validation failed` error to secure our SLO
		// Check out https://jplat.atlassian.net/browse/CRCS-714 for more details
		if (
			!isClientFetchError(error) &&
			!STATUS_CODE_RELIABILITY_WHITE_LIST.includes(error.statusCode)
		) {
			sendSloMetrics(false, error.message, error.statusCode);
		}
		return mapErrorStatusToAdfError(INTERNAL_SERVER_ERROR);
	}
};
