/**
 * @generated SignedSource<<f529f2bcd1650e1706a4915343ed86dd>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraIssueLinkDirection = "INWARD" | "OUTWARD" | "%future added value";
export type JiraProjectType = "BUSINESS" | "CUSTOMER_SERVICE" | "PRODUCT_DISCOVERY" | "SERVICE_DESK" | "SOFTWARE" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewJpdIdeas_JPDIdeasPanel$data = {
  readonly __id: string;
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly direction: JiraIssueLinkDirection | null | undefined;
      readonly id: string | null | undefined;
      readonly issue: {
        readonly fieldsById: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly __typename: "JiraIssueTypeField";
              readonly fieldId: string;
              readonly issueType: {
                readonly avatar: {
                  readonly medium: string | null | undefined;
                } | null | undefined;
                readonly name: string;
              } | null | undefined;
              readonly name: string;
            } | {
              readonly __typename: "JiraProjectField";
              readonly fieldId: string;
              readonly project: {
                readonly projectType: JiraProjectType | null | undefined;
              } | null | undefined;
            } | {
              readonly __typename: "JiraSingleLineTextField";
              readonly fieldId: string;
              readonly name: string;
              readonly text: string | null | undefined;
            } | {
              // This will never be '%other', but we need some
              // value in case none of the concrete values match.
              readonly __typename: "%other";
            } | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined;
        readonly id: string;
        readonly issueId: string;
        readonly key: string;
      } | null | undefined;
      readonly issueLinkId: string | null | undefined;
      readonly type: {
        readonly linkTypeId: string | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "ui_issueViewJpdIdeas_JPDIdeasPanel";
};
export type ui_issueViewJpdIdeas_JPDIdeasPanel$key = {
  readonly " $data"?: ui_issueViewJpdIdeas_JPDIdeasPanel$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewJpdIdeas_JPDIdeasPanel">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "id"
},
v1 = {
  "kind": "ScalarField",
  "name": "fieldId"
},
v2 = {
  "kind": "ScalarField",
  "name": "name"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueViewJpdIdeas_JPDIdeasPanel",
  "selections": [
    {
      "concreteType": "JiraIssueLinkEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraIssueLink",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "ScalarField",
              "name": "issueLinkId"
            },
            {
              "kind": "ScalarField",
              "name": "direction"
            },
            {
              "concreteType": "JiraIssueLinkType",
              "kind": "LinkedField",
              "name": "type",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "linkTypeId"
                }
              ]
            },
            {
              "concreteType": "JiraIssue",
              "kind": "LinkedField",
              "name": "issue",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "name": "issueId"
                },
                {
                  "kind": "ScalarField",
                  "name": "key"
                },
                {
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "ids",
                      "value": [
                        "assignee",
                        "issuetype",
                        "priority",
                        "status",
                        "summary",
                        "project"
                      ]
                    }
                  ],
                  "concreteType": "JiraIssueFieldConnection",
                  "kind": "LinkedField",
                  "name": "fieldsById",
                  "plural": false,
                  "selections": [
                    {
                      "concreteType": "JiraIssueFieldEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "ScalarField",
                              "name": "__typename"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v1/*: any*/),
                                (v2/*: any*/),
                                {
                                  "kind": "ScalarField",
                                  "name": "text"
                                }
                              ],
                              "type": "JiraSingleLineTextField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v1/*: any*/),
                                {
                                  "concreteType": "JiraProject",
                                  "kind": "LinkedField",
                                  "name": "project",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "kind": "ScalarField",
                                      "name": "projectType"
                                    }
                                  ]
                                }
                              ],
                              "type": "JiraProjectField"
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v1/*: any*/),
                                (v2/*: any*/),
                                {
                                  "concreteType": "JiraIssueType",
                                  "kind": "LinkedField",
                                  "name": "issueType",
                                  "plural": false,
                                  "selections": [
                                    (v2/*: any*/),
                                    {
                                      "concreteType": "JiraAvatar",
                                      "kind": "LinkedField",
                                      "name": "avatar",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "kind": "ScalarField",
                                          "name": "medium"
                                        }
                                      ]
                                    }
                                  ]
                                }
                              ],
                              "type": "JiraIssueTypeField"
                            }
                          ]
                        }
                      ]
                    }
                  ],
                  "storageKey": "fieldsById(ids:[\"assignee\",\"issuetype\",\"priority\",\"status\",\"summary\",\"project\"])"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "kind": "ScalarField",
          "name": "__id"
        }
      ]
    }
  ],
  "type": "JiraIssueLinkConnection"
};
})();

(node as any).hash = "fa0b2866f22db8eb2dfa658167e1b3cb";

export default node;
