export const goalAppearances = ['DEFAULT', 'ON_TRACK', 'AT_RISK', 'OFF_TRACK', 'MENU'] as const;

export type GoalAppearance = (typeof goalAppearances)[number];

export const isGoalAppearance = (value: string): value is GoalAppearance =>
	(goalAppearances as ReadonlyArray<string>).includes(value);

/**
 * This is solely here to support Jira only having these values available
 *
 * @private
 * @deprecated please use `GoalAppearance` instead
 */
export const goalStatusValues = [
	'ON_TRACK',
	'AT_RISK',
	'OFF_TRACK',
	'PENDING',
	'DONE', // this is the code value for what we show as 'Completed'
	'CANCELLED',
	'PAUSED',
	'ARCHIVED',
] as const;

export const goalTypeKeys = ['GOAL', 'OBJECTIVE', 'KEY_RESULT'] as const;

export type GoalTypeKeys = (typeof goalTypeKeys)[number];

/**
 * This is solely here to support Jira only having these values available
 *
 * @private
 * @deprecated please use `GoalAppearance` instead
 */
export type GoalStatusValue = (typeof goalStatusValues)[number];

export interface GoalStatus {
	value: string;
	appearance: GoalAppearance;
	localizedLabel: {
		messageId: string;
	};
}

export interface TownsquareGoal {
	id: string;
	key: string;
	name: string;
	url: string;
	status: GoalStatus;
}
