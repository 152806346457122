import cloneDeep from 'lodash/cloneDeep';
import forEach from 'lodash/forEach';
import merge from 'lodash/merge';
import { fg } from '@atlassian/jira-feature-gating';
import type { ConnectionFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/connection/types.tsx';
import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import {
	KEY_FIELDKEY,
	ISSUEID_FIELDKEY,
} from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { RemoteIssue } from '@atlassian/jira-polaris-remote-issue/src/controllers/crud/types.tsx';
import { getMetaFromJiraSearchIssue } from '@atlassian/jira-polaris-remote-issue/src/controllers/util/index.tsx';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import type {
	PolarisInsightsByIssueId,
	PolarisPlayContributionsByIssueId,
} from '../../../project/types.tsx';
import { EMPTY_PROPERTIES } from '../../constants.tsx';
import { getFieldMappings, type FieldMappings } from '../../selectors/fields.tsx';
import type { State, PropertyMaps, Props } from '../../types.tsx';
import { generateLocalIssueId } from '../../utils/local-id.tsx';
import { getConnectionFieldValuesFromLinkedIssues } from '../common/connection/index.tsx';
import { transformIssueLinkData } from '../common/issue-links/index.tsx';
import { sortIssuesByRank } from '../common/issue-rank/index.tsx';

export const preserveExistingPlays = (
	plays: PolarisPlayContributionsByIssueId,
	existingProperties: PropertyMaps,
	issueMap: Record<LocalIssueId, IssueId>,
) => {
	const finalPlays = {
		...plays,
	};

	for (const [playId, values] of Object.entries(existingProperties.plays)) {
		for (const localIssueId of Object.keys(values)) {
			const jiraIssueId = Object.keys(issueMap).find((key) => issueMap[key] === localIssueId);

			if (jiraIssueId) {
				finalPlays[playId][jiraIssueId] = existingProperties.plays[playId][localIssueId];
			}
		}
	}

	return finalPlays;
};

export const preserveExistingInsights = (
	insights: PolarisInsightsByIssueId,
	existingProperties: PropertyMaps,
	issueMap: Record<LocalIssueId, IssueId>,
) => {
	const finalInsights = {
		...insights,
	};

	for (const localIssueId of Object.keys(issueMap)) {
		const jiraIssueId = issueMap[localIssueId];
		finalInsights[localIssueId] = existingProperties.insights[jiraIssueId];
	}

	return finalInsights;
};

export const isIssueAlreadyLoaded = (
	issueId: IssueId,
	mapping: Record<LocalIssueId, IssueId>,
	fieldMappings: FieldMappings<unknown>,
	existingState: State,
) => {
	if (mapping[issueId] === undefined) {
		return false;
	}

	return (
		fieldMappings[KEY_FIELDKEY].getAllValues(existingState, undefined)[mapping[issueId]] !==
		undefined
	);
};

type TransformIssueResponse = {
	remoteIssues: RemoteIssue[];
	insights: PolarisInsightsByIssueId | undefined;
	plays: PolarisPlayContributionsByIssueId | undefined;
	existingState: State;
	existingMapping?: Record<IssueId, LocalIssueId>;
	props: Props;
};

export const transformIssueResponse = (args: TransformIssueResponse) => {
	const { remoteIssues, insights, plays, existingState, existingMapping = {}, props } = args;
	const { polarisIssueLinkType, hiddenIssueLinkTypes, rankField } = props;
	const fieldMappings = getFieldMappings(existingState, props);

	let ids: Array<LocalIssueId> = [];
	const properties = cloneDeep(EMPTY_PROPERTIES);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	let externalIssueData: Record<string, any> = {};

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const issues = sortIssuesByRank({ issues: remoteIssues, rankField }) as RemoteIssue[];

	const connectionFieldsMappings: FieldMappings<ConnectionFieldValue[]> = {};

	issues.forEach((issue) => {
		const isIssueLoaded = isIssueAlreadyLoaded(
			issue.id,
			existingMapping,
			fieldMappings,
			existingState,
		);
		// reuse the existing local issue id if it already exists
		// this can be the case when loading a single idea in a new tab
		const id = existingMapping[issue.id] || generateLocalIssueId();

		if (existingMapping[issue.id] === undefined) {
			existingMapping[issue.id] = id;
		}

		ids = [...ids, id];

		if (fg('jpd_issues_relationships')) {
			forEach(fieldMappings, (mapping) => {
				if (mapping.field?.type === FIELD_TYPES.CONNECTION) {
					connectionFieldsMappings[mapping.field.key] = mapping;
					return;
				}

				// We do not want to override the field values of the already loaded issue (if any)
				// because some fields which are already loaded (e.g. description) would have an
				// undefined value as they are not present
				// The exception is the issue id field, which should always be updated since we need mapping of jiraIssueIds
				// to local issue ids in order to update connection fields
				if (isIssueLoaded && mapping.field?.type !== FIELD_TYPES.ISSUE_ID) {
					return;
				}

				// do not attempt to map dynamic fields from jira values
				if (mapping.field?.formula !== undefined) {
					return;
				}

				const fieldValue = mapping.getValueFromJiraIssue(issue);

				mapping.setMutable(properties, id, fieldValue !== null ? fieldValue : undefined);
			});
		} else {
			// we do not want to override the field values of the already loaded issue (if any)
			// because some fields which are already loaded (e.g. description) would have an
			// undefined value as they are not present
			// eslint-disable-next-line no-lonely-if
			if (!isIssueLoaded) {
				forEach(fieldMappings, (mapping) => {
					// do not attempt to map dynamic fields from jira values
					if (mapping.field?.formula !== undefined) {
						return;
					}

					const fieldValue = mapping.getValueFromJiraIssue(issue);

					mapping.setMutable(properties, id, fieldValue !== null ? fieldValue : undefined);
				});
			}
		}

		// handle lexorank
		if (rankField !== undefined) {
			properties.lexoRank[id] = issue.fields[rankField];
		}

		if (insights !== undefined && Array.isArray(insights[issue.id])) {
			properties.insights[id] = insights[issue.id];
		}

		properties.issueMetadata[id] = getMetaFromJiraSearchIssue(
			issue,
			polarisIssueLinkType,
			hiddenIssueLinkTypes,
		);

		const issueLinkData = transformIssueLinkData(polarisIssueLinkType, hiddenIssueLinkTypes, issue);
		properties.linkedDeliveryIssues[id] = issueLinkData.deliveryIssueIds;
		externalIssueData = merge(externalIssueData, issueLinkData.externalIssueData);
	});

	if (fg('jpd_issues_relationships')) {
		// Connection fields for all issues are handled separately after all other fields because
		// - the values of connection fields depend on the issueMetadata properties of other issues
		// - configurations include issueFilters which depend on the values of other fields
		issues.forEach((issue) => {
			const localIssueId = existingMapping[issue.id];

			if (localIssueId) {
				forEach(connectionFieldsMappings, (mapping, fieldKey) => {
					const fieldValue = getConnectionFieldValuesFromLinkedIssues({
						localIssueId,
						fieldKey,
						state: {
							...existingState,
							ids,
							properties,
							externalIssueData,
						},
						props,
					});

					mapping.setMutable(properties, localIssueId, fieldValue);
				});
			}
		});
	}

	if (plays) {
		for (const [, play] of Object.entries(plays)) {
			for (const issueId of Object.keys(play)) {
				const localIssueId = existingMapping[issueId];

				if (localIssueId) {
					if (properties.number[ISSUEID_FIELDKEY] === undefined) {
						properties.number[ISSUEID_FIELDKEY] = {};
					}

					fieldMappings[ISSUEID_FIELDKEY].setMutable(
						properties,
						localIssueId,
						Number.parseInt(issueId, 10),
					);
				}
			}
		}

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const issuePlaysWithContributions = Object.keys(plays).reduce<Record<string, any>>(
			(acc, key) => {
				const play = plays[key];

				for (const issueId of Object.keys(play)) {
					const localIssueId = existingMapping[issueId];

					if (Array.isArray(play[issueId])) {
						if (acc[key] === undefined) {
							acc[key] = {};
						}
						acc[key][localIssueId] = play[issueId];
					}
				}

				return acc;
			},
			{},
		);

		properties.plays = merge(properties.plays, issuePlaysWithContributions);
	}

	return {
		ids,
		properties,
		externalIssueData,
	};
};
