import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { TextFieldReadView } from '../../text-field/read-view/index.tsx';
import type { ReadViewProps } from '../../types.tsx';

export const DateFieldReadView = ({
	value,
	render,
	getValueContainer,
	inInlineEdit,
}: ReadViewProps) => {
	const { formatDate } = useIntl();
	return (
		<TextFieldReadView
			getValueContainer={getValueContainer}
			render={render}
			value={value ? formatDate(value) : null}
			inInlineEdit={inInlineEdit}
		/>
	);
};
