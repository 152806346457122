import {
	useChildIssuesIssueLimitUrl,
	useChildIssuesLimit,
} from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { ChildIssueFieldKeys } from '@atlassian/jira-issue-shared-types/src/common/types/children-issues-type.tsx';
import {
	CHILDREN_ISSUES,
	EPIC_ISSUES,
	PORTFOLIO_CHILD_ISSUES,
	SUBTASKS,
} from '@atlassian/jira-issue-view-configurations/src/index.tsx';
import { useTotalChildIssuesCount } from '../use-total-child-issues-count/index.tsx';

export const useHasExceededChildIssuesLimitOnLoad = (fieldKey: ChildIssueFieldKeys) => {
	const issueLimitUrl = useChildIssuesIssueLimitUrl(fieldKey);
	return Boolean(issueLimitUrl);
};

export const useHasExceededChildIssuesLimitAnyPanelOnLoad = () => {
	const hasExceededChildIssuesLimitSubtasks = useHasExceededChildIssuesLimitOnLoad(SUBTASKS);
	const hasExceededChildIssuesLimitChildrenIssues =
		useHasExceededChildIssuesLimitOnLoad(CHILDREN_ISSUES);
	const hasExceededChildIssuesLimitEpicIssues = useHasExceededChildIssuesLimitOnLoad(EPIC_ISSUES);
	const hasExceededChildIssuesLimitPortfolioChildIssues =
		useHasExceededChildIssuesLimitOnLoad(PORTFOLIO_CHILD_ISSUES);
	return (
		hasExceededChildIssuesLimitSubtasks ||
		hasExceededChildIssuesLimitChildrenIssues ||
		hasExceededChildIssuesLimitEpicIssues ||
		hasExceededChildIssuesLimitPortfolioChildIssues
	);
};

/**
 * This is used to determine if the user loaded this issue from the server and it
 * was not yet exceeding the child issues limit, however they have added enough
 * child issues after loading to cause the issue to exceed the limit.
 *
 * @param fieldKey string representing the child issue type
 * @returns boolean
 */
export const useHasExceededChildIssuesLimitAfterLoad = (fieldKey: ChildIssueFieldKeys) => {
	const hasExceededChildIssuesLimitOnLoad = useHasExceededChildIssuesLimitOnLoad(fieldKey);

	const totalChildIssuesCount = useTotalChildIssuesCount();

	const childIssuesLimit = useChildIssuesLimit();

	if (hasExceededChildIssuesLimitOnLoad) {
		return false;
	}

	return totalChildIssuesCount > childIssuesLimit;
};

export const useHasExceededChildIssuesLimitAnyPanelAfterLoad = () => {
	const hasExceededChildIssuesLimitOnLoad = useHasExceededChildIssuesLimitAnyPanelOnLoad();

	const totalChildIssuesCount = useTotalChildIssuesCount();

	const childIssuesLimit = useChildIssuesLimit();

	if (hasExceededChildIssuesLimitOnLoad) {
		return false;
	}

	return totalChildIssuesCount > childIssuesLimit;
};

export const useHasExceededChildIssuesLimitAnyPanelOnOrAfterLoad = () => {
	const hasExceededOnLoad = useHasExceededChildIssuesLimitAnyPanelOnLoad();
	const hasExceededAfterLoad = useHasExceededChildIssuesLimitAnyPanelAfterLoad();
	return hasExceededOnLoad || hasExceededAfterLoad;
};
