export type Status = 'pending' | 'resolved' | 'rejected';
/**
 * Generic class to let us resolve a promise based on some code outside the Promise() function body.
 * Used by the tokenProvider to create a singleton promise that resolves when a specific React
 * prop (the token) becomes available.
 */
export class PromiseProvider<T> {
	/**
	 * Defining properties with exclamation mark via constructor
	 * invoke reset()
	 */
	constructor() {
		this.reset();
	}

	status!: Status;

	promise!: Promise<T>;

	resolvePromise!: (arg1: T) => void;

	rejectPromise!: () => void;

	get(): Promise<T> {
		return this.promise;
	}

	resolve(value: T) {
		this.resetIfSettled();
		this.resolvePromise(value);
		this.status = 'resolved';
	}

	reject() {
		this.resetIfSettled();
		this.rejectPromise();
		this.status = 'rejected';
	}

	resetIfSettled() {
		const isSettled = this.status !== 'pending';
		if (isSettled) {
			this.reset();
		}
	}

	reset() {
		this.promise = new Promise((resolve, reject) => {
			this.resolvePromise = resolve;
			this.rejectPromise = reject;
		});
		this.status = 'pending';
	}
}
