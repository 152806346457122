import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const PanelContents = styled.div<{ panelWidth: string | number | undefined }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	minWidth: ({ panelWidth }) => (panelWidth ? `${panelWidth}px;` : 0),
	width: '100%',
	height: '100%',
	overflow: 'auto',
	position: 'relative',
	padding: token('space.100', '8px'),
});
