import { useCallback } from 'react';
import { useService } from '@atlassian/jira-common-services/src/use-service/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useEnvironment } from '@atlassian/jira-tenant-context-controller/src/components/environment/index.tsx';
import {
	deleteTemplate,
	removeRuleConfig,
	type DeleteTemplateParams,
	type DeleteTemplateResult,
	type RemoveRuleConfigResult,
} from '../../services/set-non-recurring.tsx';
import { useWithAnalytics } from './use-with-analytics.tsx';

type SetAsNonRecurringIssueParams = Pick<DeleteTemplateParams, 'ruleId'>;
type SetAsNonRecurringIssueResult = DeleteTemplateResult & RemoveRuleConfigResult;

export const useSetNonRecurringIssue = (issueKey: string, projectId: string) => {
	const cloudId = useCloudId();
	const environment = useEnvironment();
	const withAnalytics = useWithAnalytics();

	const setAsNonRecurringIssue = useCallback(
		async ({ ruleId }: SetAsNonRecurringIssueParams): Promise<SetAsNonRecurringIssueResult> => {
			const deleteTemplatePromise = withAnalytics(
				deleteTemplate({
					environment,
					cloudId,
					projectId,
					ruleId,
				}),
				'deleteTemplate',
			);
			const removeIssueConfigPromise = withAnalytics(
				removeRuleConfig({
					issueKey,
				}),
				'removeRuleConfig',
			);
			return Promise.all([deleteTemplatePromise, removeIssueConfigPromise]);
		},
		[cloudId, environment, issueKey, projectId, withAnalytics],
	);

	return useService<SetAsNonRecurringIssueResult, SetAsNonRecurringIssueParams>(
		setAsNonRecurringIssue,
	);
};
