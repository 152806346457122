import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

const SingleLineTextEditViewEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() =>
			import(/* webpackChunkName: "async-single-line-text-edit-view" */ './ui/single-line-text'),
	),
	getPreloadProps: () => ({}),
});

export default SingleLineTextEditViewEntryPoint;
