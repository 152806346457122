import React from 'react';
import { FormattedMessage } from '@atlassian/jira-intl';
import type { MultiSelectValueItemShape } from '@atlassian/jira-issue-internal-field-select/src/multi-select-inline-edit/index.tsx';
import { MAX_ALLOWED_LABELS, MAX_LABEL_SIZE } from '../../../../../../labels/validation.tsx';

export const validate = (values: MultiSelectValueItemShape[]) => {
	if (values.length > MAX_ALLOWED_LABELS) {
		return (
			<FormattedMessage
				id="issue.labels-view.input-too-many-labels"
				defaultMessage="Can't have more than {max} labels."
				values={{ max: MAX_ALLOWED_LABELS }}
			/>
		);
	}

	const isInvalid = values
		.map((value) => value.content.trim())
		.some((content) => content.length > MAX_LABEL_SIZE);

	return isInvalid ? (
		<FormattedMessage
			id="issue.labels-view.input-label-is-invalid"
			defaultMessage="Labels can't have more than {max} characters."
			values={{ max: MAX_LABEL_SIZE }}
		/>
	) : undefined;
};
