import React from 'react';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import { IssueSecurityLevelFieldBoundary } from '@atlassian/jira-issue-field-security-level/src/ui/async.tsx';
import type IssueSecurityLevelFieldType from '@atlassian/jira-issue-field-security-level/src/ui/index.tsx';
import { Loading as IssueSecurityLevelFieldReadonly } from '@atlassian/jira-issue-field-security-level/src/ui/loading/index.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import { SECURITY } from '@atlassian/jira-issue-view-configurations/src/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { issueKeySelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import { errorSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';
import { sweetStateFieldUpdated } from '@atlassian/jira-issue-view-store/src/issue-field/state/actions/field-save-actions.tsx';
import {
	allowedValuesSelector,
	isEnabledForCurrentIssueSelector,
	isEditableSelector,
	type Value as SecurityLevelValue,
} from '@atlassian/jira-issue-view-store/src/issue-field/state/selectors/security-level-selector.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

// TODO reevaluate phase - forPaint is simply for initial parity
export const IssueSecurityLevelField = lazyForPaint<typeof IssueSecurityLevelFieldType>(
	() =>
		import(
			/* webpackChunkName: "async-issue-field-security-level" */ '@atlassian/jira-issue-field-security-level'
		),
);

type Props = {
	isSecurityLevelEnabledForIssue: boolean;
	isSecurityLevelEditable: boolean;
	issueKey: IssueKey;
	securityLevelItems: SecurityLevelValue[];
	error: string | null;
	onSuccess: (value: SecurityLevelValue | null) => void;
};

const SecurityLevelIconItem = ({
	securityLevelItems,
	isSecurityLevelEnabledForIssue,
	isSecurityLevelEditable,
	error,
	issueKey,
	onSuccess,
}: Props) => {
	const [fieldValue] = useFieldValue({ issueKey, fieldKey: SECURITY });

	if (!error && isSecurityLevelEnabledForIssue) {
		const fallback = <IssueSecurityLevelFieldReadonly initialValue={fieldValue} />;

		return (
			<IssueSecurityLevelFieldBoundary packageName="issue" fallback={fallback}>
				{/* @ts-expect-error - TS2739: Type '{ issueKey: string; initialValue: any; allowedValues: Value[]; isEditable: boolean; onSuccess: (value: Value | null) => void; }' is missing the following properties from type 'FieldOptions<SecurityLevel, null, undefined>': fieldKey, fieldType */}
				<IssueSecurityLevelField
					issueKey={issueKey}
					initialValue={fieldValue || null}
					allowedValues={securityLevelItems}
					isEditable={isSecurityLevelEditable}
					onSuccess={onSuccess}
				/>
			</IssueSecurityLevelFieldBoundary>
		);
	}

	return null;
};

export const SecurityLevelIcon = flowWithSafeComponent(
	connect(
		(state: State) => ({
			securityLevelItems: allowedValuesSelector(state),
			isSecurityLevelEnabledForIssue: Boolean(isEnabledForCurrentIssueSelector(state)),
			isSecurityLevelEditable: isEditableSelector(state),
			error: errorSelector(state),
			issueKey: issueKeySelector(state),
		}),
		(dispatch) => ({
			onSuccess: (value: SecurityLevelValue | null) =>
				dispatch(sweetStateFieldUpdated(SECURITY, value)),
		}),
	),
)(SecurityLevelIconItem);
