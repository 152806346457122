import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { getOptionBaseStyles } from '@atlassian/jira-common-components-picker/src/base/index.tsx';
import type {
	AkSelectStyles,
	ElementStyles,
} from '@atlassian/jira-common-components-picker/src/model.tsx';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';

export const defaultSelectStyles: AkSelectStyles = {
	multiValue: (base) => ({
		...base,
		backgroundColor: token('color.background.neutral', colors.N20),
	}),
	option: (base, state) => ({
		...base,
		...getOptionBaseStyles(state),
		cursor: 'pointer',

		...(state.isFocused && !state.isSelected
			? {
					backgroundColor: token('color.background.neutral', colors.N20), // so that option does not change color once cursor is moved outside of select
				}
			: {}),

		'&:hover': {
			...(state.isSelected
				? {}
				: // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					({
						backgroundColor: token('color.background.neutral', colors.N20),
					} as ElementStyles)),
			...(state.isDisabled
				? {
						backgroundColor: 'transparent',
						cursor: 'not-allowed',
					}
				: // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					({} as ElementStyles)),
		},
	}),
	menu: (base) => ({
		...base,
		// without properly set zIndex value dropdown with options is cut inside modal dialogs
		zIndex: layers.modal,
	}),
	menuPortal: (base) => ({
		...base,
		// Safari fix - otherwise dropdown menu will be shown below elements which have transform: translateZ set: https://github.com/JedWatson/react-select/issues/1010, BENTO-5741
		transform: 'translateZ(0)',
		// without properly set zIndex value dropdown with options is behind containers with a higher zIndex
		zIndex: layers.modal,
	}),
};

export const issueFieldSelectStyles: AkSelectStyles = {
	indicatorsContainer: (base) => ({
		...base,
		paddingRight: token('space.050', '4px'), // this is fixed in ak/select v10.2.0 and will no longer be needed
	}),
	// need to apply same padding to both loadingIndicator and clearIndicator - not to have input query jumping between lines e.g. in multi-select
	loadingIndicator: (base) => ({
		...base,
		paddingLeft: token('space.025', '2px'),
		paddingRight: token('space.050', '4px'),
	}),
	clearIndicator: (base) => ({
		...base,
		paddingLeft: token('space.025', '2px'),
		paddingRight: token('space.050', '4px'),
	}),
	dropdownIndicator: (base) => ({
		...base,
		display: 'none', // there seems to be no way to keep dropdown open on option selected (which we do in multi-select field), but closed on dropdownIndicator icon click. Because both call ony onMenuClose() without any params to distinguish the source of the call
	}),
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const OptionWrapper = styled.span({
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FailedFetchOptionWrapper = styled(OptionWrapper)({
	justifyContent: 'center',
	whiteSpace: 'normal',
	textAlign: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ImgSpan = styled.span({
	display: 'flex',
	paddingRight: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FlexSpan = styled.span({
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ErrorMessageWrapper = styled.div<{ errorMessagePosition?: string }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginBottom: (props) =>
		props.errorMessagePosition === 'top' ? token('space.075', '6px') : undefined,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&& > div': {
		minHeight: 'auto',
	},
});
