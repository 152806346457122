import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { State, UiState } from './types.tsx';

export type StoreApi = StoreActionApi<State>;

const mergeUiState =
	(ui: UiState) =>
	({ setState, getState }: StoreApi) => {
		const previousUiState = getState().ui;
		const newState: State = {
			...getState(),
			ui: {
				...previousUiState,
				...ui,
			},
		};
		setState(newState);
	};

export const actions = { mergeUiState } as const;
export type Actions = typeof actions;
