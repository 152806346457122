import React, { useEffect } from 'react';
import { graphql, useFragment } from 'react-relay';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import type { Area } from '@atlassian/jira-issue-view-common-types/src/connect-field-type.tsx';
import { IssueViewNumberFieldDefault } from '@atlassian/jira-issue-view-layout-number-field-default/src/ui/index.tsx';
import { IssueViewStoryPointEstimateField } from '@atlassian/jira-issue-view-layout-story-point-estimate-field/src/ui/index.tsx';
import {
	STORY_POINTS_TYPE,
	STORY_POINT_ESTIMATE_CF_TYPE,
	NUMBER_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { ui_issueViewLayoutNumberField_IssueViewNumberField$key } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutNumberField_IssueViewNumberField.graphql';

export interface IssueViewNumberFieldProps {
	area?: Area;
	fragmentKey: ui_issueViewLayoutNumberField_IssueViewNumberField$key;
}

const knownTypes: string[] = [
	STORY_POINTS_TYPE,
	STORY_POINT_ESTIMATE_CF_TYPE,
	NUMBER_CF_TYPE,
] as const;

/**
 * If you implement STORY_POINTS_TYPE or STORY_POINT_ESTIMATE_CF_TYPE remember to update
 * getRelayLayoutExclusions in jira/src/packages/issue/issue-view-layout-templates/components/layout-item/src/index.tsx
 */
export const IssueViewNumberField = ({ area, fragmentKey }: IssueViewNumberFieldProps) => {
	const data = useFragment<ui_issueViewLayoutNumberField_IssueViewNumberField$key>(
		graphql`
			fragment ui_issueViewLayoutNumberField_IssueViewNumberField on JiraNumberField {
				...ui_issueViewLayoutNumberFieldDefault_IssueViewNumberFieldDefault
				...ui_issueViewLayoutStoryPointEstimateField_IssueViewStoryPointEstimateField
				type
				isStoryPointField
			}
		`,
		fragmentKey,
	);

	useEffect(() => {
		if (!knownTypes.includes(data.type)) {
			log.safeInfoWithoutCustomerData(
				'issue.fields.issue-view-layout.number-field',
				`unknown fieldType ${data.type}`,
			);
		}
	}, [data.type]);

	if (
		data.isStoryPointField ||
		data.type === STORY_POINTS_TYPE ||
		data.type === STORY_POINT_ESTIMATE_CF_TYPE
	) {
		return <IssueViewStoryPointEstimateField area={area} fragmentKey={data} />;
	}
	return <IssueViewNumberFieldDefault area={area} fragmentKey={data} />;
};
