import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	unassignedOption: {
		id: 'issue-field-assignee-inline-edit-full.assignee.unassigned-option',
		defaultMessage: 'Unassigned',
		description:
			'Text used in the dropdown item that, when select, will leave the issue unassigned.',
	},
	assignToMe: {
		id: 'issue-field-assignee-inline-edit-full.assignee.assign-to-me',
		defaultMessage: 'Assign to me',
		description:
			'Text used in the dropdown item that, when selected, will assign the issue to the current user.',
	},
});
