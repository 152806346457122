import mapValues from 'lodash/mapValues';
import { createStore, type StoreActionApi } from '@atlassian/react-sweet-state';

// @ts-expect-error - Force TS to see this as RSS createStore
export const createPolarisStore: typeof createStore = ({ initialState, actions, name }) => {
	// @ts-expect-error - TS7019 - Rest parameter 'args' implicitly has an 'any[]' type.
	const wrappedActions = mapValues(actions, (actionCreator) => (...args) => {
		try {
			const actionFct = actionCreator(...args);
			return (
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				storeAction: StoreActionApi<any>,
				props: {
					onActionFailed: (arg1: Error) => void;
				},
			) => {
				try {
					return actionFct(storeAction, props);
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
				} catch (error: any) {
					props.onActionFailed(error);
					throw error;
				}
			};
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			return (
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				storeAction: StoreActionApi<any>,
				props: {
					onActionFailed: (arg1: Error) => void;
				},
			) => {
				props.onActionFailed(error);
				throw error;
			};
		}
	});
	return createStore({
		initialState,
		actions: wrappedActions,
		name,
	});
};
