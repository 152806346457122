import React, { type ReactNode, forwardRef, type Ref, type ChangeEvent } from 'react';
import { styled } from '@compiled/react';
import { ErrorMessage } from '@atlaskit/form';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import useUFOTypingPerformanceTracing from '@atlaskit/react-ufo/typing-performance-tracing';
import Textfield from '@atlaskit/textfield';
import { Y300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import useMergeRefs from '@atlassian/jira-merge-refs/src/index.tsx';

type Props = {
	readonly type?: 'number' | 'url' | 'text' | 'object';
	readonly value?: string;
	readonly placeholder?: string;
	readonly invalidMessage?: ReactNode;
	readonly compact?: boolean;
	readonly onChange: (arg1: ChangeEvent<HTMLInputElement>) => void;
	readonly label?: string;
};

export type RefShape = HTMLInputElement;

const Warning = () => (
	<WarningWrapper data-testid="issue-internal-field-single-line-text.warning-wrapper">
		<WarningIcon size="medium" primaryColor={token('color.icon.warning', Y300)} label="" />
	</WarningWrapper>
);

const SingleLineTextField = (props: Props, ref: Ref<HTMLInputElement>) => {
	const {
		type = 'text',
		value = '',
		placeholder = '',
		invalidMessage,
		onChange,
		compact = false,
		label,
	} = props;

	const typingPerformanceTracingRef = useUFOTypingPerformanceTracing(
		'single-line-text-field-type-tracing',
	);
	const fieldRef = useMergeRefs(ref, typingPerformanceTracingRef);

	const afterElement = compact ? (
		<Tooltip content={invalidMessage}>
			<Warning />
		</Tooltip>
	) : (
		<Warning />
	);

	return (
		<>
			<Textfield
				aria-label={label}
				type={type}
				value={value}
				placeholder={placeholder}
				onChange={onChange}
				isInvalid={!!invalidMessage}
				autoFocus
				ref={fieldRef}
				isCompact={compact}
				elemAfterInput={!!invalidMessage === true ? afterElement : null}
			/>
			{!compact && invalidMessage != null ? <ErrorMessage>{invalidMessage}</ErrorMessage> : null}
		</>
	);
};

SingleLineTextField.displayName = 'SingleLineTextField';

export default forwardRef<HTMLInputElement, Props>(SingleLineTextField);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const WarningWrapper = styled.div({
	marginLeft: token('space.negative.050', '-4px'),
	marginRight: token('space.050', '4px'),
});
