import React, { forwardRef, type HTMLProps } from 'react';
import { COMMAND_CONTAINER_ROW_TEST_ID, COMPAL_MENU_ITEM_ID } from '../constants.tsx';

export type ListItemProps = {
	id?: string;
	active?: boolean;
};

export const CommandPaletteSearchResultListItem = forwardRef<
	HTMLDivElement,
	HTMLProps<HTMLDivElement> & ListItemProps
>(({ id, active, ...rest }, ref) => (
	<div
		ref={ref}
		data-testid={COMMAND_CONTAINER_ROW_TEST_ID}
		aria-current={active}
		role="menuitem"
		id={`${COMPAL_MENU_ITEM_ID}-${id}`}
		{...rest}
	/>
));
