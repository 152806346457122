import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	errorTitle: {
		id: 'issue-field-remaining-estimate.error-title',
		defaultMessage: "We couldn't set the remaining estimate value",
		description:
			'Error message informing the user that updating the remaining time estimation field failed',
	},
	errorMessage: {
		id: 'issue-field-remaining-estimate.error-message',
		defaultMessage: 'Check your connection, then give it another try.',
		description: 'Error message when save fails',
	},
});
