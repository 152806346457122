import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import type {
	ConnectFlagLinkTemplate,
	CreateFlagLinkTemplate,
	DeploymentSummary,
	FeatureFlagDetailsLink,
	FeatureFlagName,
	FeatureFlagRolloutSummary,
	FeatureFlagsPerProvider,
	Provider,
	ReleasesData,
	RemoteLink,
	RemoteLinkProvider,
} from '@atlassian/jira-development-common/src/model/releases-data.tsx';
import { contextSelector } from '../../context/selectors/index.tsx';
import type { State } from '../../types.tsx';
import { isVersionRelatedLinksProvider } from './utils.tsx';

export const panelHasFeatureFlagsProvidersSelector = (state: State) =>
	!!state.releasesData && Object.keys(state.releasesData.featureFlags.flagsPerProvider).length > 0;

export const enabledFeatureFlagsSelector = (state: State): FeatureFlagName[] =>
	state.releasesData ? state.releasesData.featureFlags.enabledFlagNames : [];

export const enabledFeatureFlagsCntSelector = (state: State): number =>
	enabledFeatureFlagsSelector(state).length;

export const disabledFeatureFlagsSelector = (state: State): FeatureFlagName[] =>
	state.releasesData ? state.releasesData.featureFlags.disabledFlagNames : [];

export const detailsLinkSelector = (state: State): FeatureFlagDetailsLink | undefined =>
	state.releasesData ? state.releasesData.featureFlags.detailsLink : undefined;

export const flagsPerProviderSelector = (state: State): FeatureFlagsPerProvider =>
	state.releasesData ? state.releasesData.featureFlags.flagsPerProvider : {};

const injectContextParams = (
	state: State,
	urlTemplate?: CreateFlagLinkTemplate | ConnectFlagLinkTemplate,
): string | undefined => {
	if (!urlTemplate) {
		return undefined;
	}

	const contextValues = {
		'issue.key': contextSelector(state).issueContext.issueKey || '',
		'issue.flags': (flagsPerProviderSelector(state)[urlTemplate.providerId] || []).join(','),
		'issue.summary': (contextSelector(state).summary || '').substr(0, 255),
	};

	return Object.keys(contextValues).reduce(
		(previousValue, key) =>
			// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ 'issue.key': string; 'issue.flags': string; 'issue.summary': string; }'.
			previousValue.split(`{${key}}`).join(encodeURIComponent(contextValues[key])),
		urlTemplate.linkTemplate,
	);
};

export const createFlagLinkSelector = (state: State): string | undefined =>
	state.releasesData
		? injectContextParams(state, state.releasesData.featureFlags.createFlagLinkTemplate)
		: undefined;

export const connectFlagLinkSelector = (state: State): string | undefined =>
	state.releasesData
		? injectContextParams(state, state.releasesData.featureFlags.connectFlagLinkTemplate)
		: undefined;

export const panelHasValidFeatureFlagsProvidersSelector = createSelector(
	panelHasFeatureFlagsProvidersSelector,
	createFlagLinkSelector,
	connectFlagLinkSelector,
	(hasFeatureFlagsProviders, createFlagLink, connectFlagLink) =>
		hasFeatureFlagsProviders && (!isEmpty(createFlagLink) || !isEmpty(connectFlagLink)),
);

export const disabledFeatureFlagsCntSelector = (state: State): number =>
	disabledFeatureFlagsSelector(state).length;

export const totalFeatureFlagSelector = (state: State): number =>
	enabledFeatureFlagsCntSelector(state) + disabledFeatureFlagsCntSelector(state);

export const panelHasFeatureFlagsSelector = (state: State): boolean =>
	enabledFeatureFlagsCntSelector(state) + disabledFeatureFlagsCntSelector(state) > 0;

export const rolloutSummarySelector = (state: State): FeatureFlagRolloutSummary | undefined =>
	state.releasesData ? state.releasesData.featureFlags.rolloutSummary : undefined;

export const deploymentSummarySelector = (state: State): DeploymentSummary | undefined =>
	(state.releasesData &&
		state.releasesData.deployments &&
		state.releasesData.deployments.summary) ||
	undefined;

export const shouldShowEmptyStateSelector = (state: State): boolean =>
	state.releasesData?.shouldShowEmptyState ?? false;

export const shouldShowAppConfigContextPromptSelector = (state: State): boolean =>
	(!deploymentSummarySelector(state) &&
		!hasDeploymentsInProjectSelector(state) &&
		state.releasesData?.shouldShowAppConfigContextPrompt) ||
	false;

export const hasDeploymentsInProjectSelector = (state: State): boolean =>
	state.releasesData?.hasDeploymentsInProject ?? true;

export const releasesDataSelector = (state: State): ReleasesData | null => state.releasesData;

export const remoteLinksDataSelector = createSelector(
	releasesDataSelector,
	(releasesData) => releasesData && releasesData.remoteLinks,
);

export const remoteLinkProviderSelector = createSelector(
	remoteLinksDataSelector,
	(remoteLinksData) =>
		remoteLinksData && remoteLinksData.providers
			? remoteLinksData.providers.filter(
					(provider: RemoteLinkProvider) => !isVersionRelatedLinksProvider(provider.id),
				)
			: [],
);

export const remoteLinksSelector = createSelector(remoteLinksDataSelector, (remoteLinksData) =>
	remoteLinksData && remoteLinksData.remoteLinks
		? remoteLinksData.remoteLinks.filter(
				(link: RemoteLink) => !isVersionRelatedLinksProvider(link.providerId),
			)
		: [],
);

export const remoteLinksProvidersCountSelector = (state: State): number =>
	remoteLinkProviderSelector(state).length;

export const remoteLinksCountSelector = (state: State): number => remoteLinksSelector(state).length;

export const panelHasProvidersSelector = createSelector(
	panelHasFeatureFlagsProvidersSelector,
	deploymentSummarySelector,
	remoteLinksProvidersCountSelector,
	remoteLinksCountSelector,
	(hasFeatureFlagsProviders, deploymentSummary, remoteLinksProvidersCount, remoteLinksCount) =>
		hasFeatureFlagsProviders ||
		!!deploymentSummary ||
		(remoteLinksProvidersCount > 0 && remoteLinksCount > 0),
);

export const panelHasValidProvidersOrDataSelector = createSelector(
	panelHasValidFeatureFlagsProvidersSelector,
	panelHasFeatureFlagsSelector,
	deploymentSummarySelector,
	remoteLinksProvidersCountSelector,
	remoteLinksCountSelector,
	hasDeploymentsInProjectSelector,
	(
		panelHasValidFeatureFlagsProviders,
		panelHasFeatureFlags,
		deploymentSummary,
		remoteLinksProvidersCount,
		remoteLinksCount,
		hasDeploymentsInProject,
	) =>
		panelHasValidFeatureFlagsProviders ||
		panelHasFeatureFlags ||
		!!deploymentSummary ||
		!hasDeploymentsInProject ||
		(remoteLinksProvidersCount > 0 && remoteLinksCount > 0),
);

export const providersSelector = (state: State): Provider[] | undefined =>
	(state.releasesData &&
		state.releasesData.deployments &&
		state.releasesData.deployments.providers) ||
	[];
