import set from 'lodash/set';
import type { DocumentFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/document/types.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { IssuesRemote } from '@atlassian/jira-polaris-remote-issue/src/controllers/types.tsx';
import { jiraDocumentMapping } from '../../../../field/mapping/document/index.tsx';
import type { PropertyMaps } from '../../../types.tsx';
import { removePropertyValue } from '../common/remove-utils.tsx';
import type { FieldMapping } from '../types.tsx';

export const documentMapping = (
	issuesRemote: IssuesRemote,
	field: Field,
): FieldMapping<DocumentFieldValue> => ({
	...jiraDocumentMapping(issuesRemote, field),
	setMutable: (maps, issueId, value) => set(maps, ['document', field.key, issueId], value),
	setImmutable: (maps, issueId, value) => {
		if (maps.document[field.key] && maps.document[field.key][issueId] === value) {
			return maps;
		}
		return {
			...maps,
			document: {
				...maps.document,
				[field.key]: {
					...maps.document[field.key],
					[issueId]: value,
				},
			},
		};
	},
	remove: (maps: PropertyMaps, issueId: LocalIssueId) =>
		removePropertyValue(field.key, maps, issueId, 'document'),
	modifyImmutableIfMultiValueField: (maps: PropertyMaps) => maps,
	comparator: () => 0,
	valueAccessor: (state, props, issueId) =>
		state.properties.document[field.key] !== undefined
			? state.properties.document[field.key][issueId]
			: undefined,
	valueAccessorToExport: (state, props, issueId) =>
		state.properties.document[field.key] !== undefined
			? state.properties.document[field.key][issueId]?.type
			: undefined,
	getAllValues: (state) =>
		state.properties.document[field.key] ? state.properties.document[field.key] : {},
	getGroupIdentitiesSelector: (_fieldKey, _issueIdsSelector) => () => ({}),
	getGroupIdentities: () => [],
	allowEmptyGroup: false,
	getLabel: () => undefined,
	getFilter: () => undefined,
});
