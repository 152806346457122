import type { QuickAddItemToRender } from '../../types.tsx';
import getQuickActionItems, { type ItemListProps } from './quick-action-items.tsx';

export type ItemListGroups = {
	issueActions: QuickAddItemToRender[];
	linkingActions: QuickAddItemToRender[];
	ideaActions: QuickAddItemToRender[];
};

const getItemList = (props: ItemListProps): ItemListGroups => {
	const {
		attachment,
		subtask,
		issueInEpic,
		createChild,
		investigateIncident,
		addIssueLinks,
		linkConfluence,
		linkIdea,
		linkAlerts,
		addWebLink,
		linkConfluenceListView,
		linkIdeaListView,
	} = getQuickActionItems(props);

	const quickAddActionData: QuickAddItemToRender[] = [
		attachment,
		subtask,
		issueInEpic,
		createChild,
		investigateIncident,
	];

	const linkingActionsData: QuickAddItemToRender[] = [
		addIssueLinks,
		linkConfluence,
		linkIdea,
		linkAlerts,
		addWebLink,
		linkConfluenceListView,
	];

	const ideaActionsData: QuickAddItemToRender[] = [linkIdeaListView];

	return {
		issueActions: quickAddActionData.filter((item) => item.shouldRender),
		linkingActions: linkingActionsData.filter((item) => item.shouldRender),
		ideaActions: ideaActionsData.filter((item) => item.shouldRender),
	};
};

export default getItemList;
