import React from 'react';
import { styled } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import ThreeAvatars from '@atlassian/jira-illustrations/src/ui/adg4/jira/spots/other/components/three-avatars/index.tsx';
import PeopleGroup from './assets/people-group.svg';

type Props = {
	text: string;
};

export const List = (props: Props) => {
	const { text } = props;

	return (
		<EmptyListContainer>
			{fg('move_issue_view_assets_into_central_location') && (
				<Box xcss={imageWrapperStyles}>
					<ThreeAvatars alt="" width={128} height={55} />
				</Box>
			)}
			{!fg('move_issue_view_assets_into_central_location') && <EmptyListImage />}
			{text}
		</EmptyListContainer>
	);
};

export default List;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmptyListContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	margin: `${token('space.250', '20px')} 0`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmptyListImage = styled.div({
	height: '55px',
	width: '128px',
	marginBottom: token('space.150', '12px'),
	backgroundPosition: 'center',
	backgroundSize: 'contain',
	backgroundRepeat: 'no-repeat',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	backgroundImage: `url(${PeopleGroup})`,
});

const imageWrapperStyles = xcss({
	marginBottom: 'space.150',
});
