import React, { useCallback } from 'react';
import { useFragment, graphql } from 'react-relay';
import { useIssueId } from '@atlassian/jira-issue-context-service/src/index.tsx';
import type { VoteValue } from '@atlassian/jira-issue-field-voters/src/common/types.tsx';
import { IssueVotersRelay } from '@atlassian/jira-issue-field-voters/src/main.tsx';
import { useIssueViewFieldUpdateEvents } from '@atlassian/jira-issue-view-field-update-events/src/index.tsx';
import type { ui_issueViewLayoutVotesField_IssueViewVotesField$key } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutVotesField_IssueViewVotesField.graphql';
import type { AggJiraVote } from '../common/types.tsx';

export interface IssueViewVotesFieldProps {
	votesField: ui_issueViewLayoutVotesField_IssueViewVotesField$key;
	onFieldUpdated?: (value: VoteValue) => void; // For redux backwards compatibility
}

export const IssueViewVotesField = ({ votesField, onFieldUpdated }: IssueViewVotesFieldProps) => {
	const issueId = useIssueId();
	const [, { fieldChanged, fieldChangeFailed, fieldChangeRequested }] =
		useIssueViewFieldUpdateEvents();

	const data = useFragment<ui_issueViewLayoutVotesField_IssueViewVotesField$key>(
		graphql`
			fragment ui_issueViewLayoutVotesField_IssueViewVotesField on JiraVotesField {
				fieldId
				type
				__typename
				...main_issueFieldVoters_IssueVotersRelay
			}
		`,
		votesField,
	);

	const onSubmit = useCallback(
		(value: AggJiraVote) => {
			issueId &&
				fieldChangeRequested(issueId, data.fieldId, value, undefined, {
					type: data.type,
					__typename: data.__typename,
				});
		},
		[data.__typename, data.fieldId, data.type, fieldChangeRequested, issueId],
	);

	const onSubmitSucceeded = useCallback(
		(value: AggJiraVote) => {
			issueId &&
				fieldChanged(issueId, data.fieldId, value, {
					type: data.type,
					__typename: data.__typename,
				});
		},
		[data.__typename, data.fieldId, data.type, fieldChanged, issueId],
	);

	const onSubmitFailed = useCallback(
		() => issueId && fieldChangeFailed(issueId, data.fieldId),
		[data.fieldId, fieldChangeFailed, issueId],
	);
	return (
		<IssueVotersRelay
			onSubmit={onSubmit}
			onSubmitSucceeded={onSubmitSucceeded}
			onSubmitFailed={onSubmitFailed}
			onFieldUpdated={onFieldUpdated}
			votesField={data}
		/>
	);
};
