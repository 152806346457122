/**
 * @generated SignedSource<<56655fd947034248b7a833e1a6f1bf8b>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutRichTextField_IssueViewRichTextField_fragmentKey$data = {
  readonly __typename: "JiraRichTextField";
  readonly id: string;
  readonly renderer: string | null | undefined;
  readonly richText: {
    readonly $updatableFragmentSpreads: FragmentRefs<"ui_issueViewLayoutRichTextField_IssueViewRichTextField_richText">;
  } | null | undefined;
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"plainTextField_issueViewLayoutRichTextField" | "richTextField_issueViewLayoutRichTextField">;
  readonly " $fragmentType": "ui_issueViewLayoutRichTextField_IssueViewRichTextField_fragmentKey";
};
export type ui_issueViewLayoutRichTextField_IssueViewRichTextField_fragmentKey$key = {
  readonly " $data"?: ui_issueViewLayoutRichTextField_IssueViewRichTextField_fragmentKey$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutRichTextField_IssueViewRichTextField_fragmentKey">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutRichTextField_IssueViewRichTextField_fragmentKey",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "ScalarField",
      "name": "renderer"
    },
    {
      "kind": "ScalarField",
      "name": "__typename"
    },
    {
      "kind": "FragmentSpread",
      "name": "richTextField_issueViewLayoutRichTextField"
    },
    {
      "concreteType": "JiraRichText",
      "kind": "LinkedField",
      "name": "richText",
      "plural": false,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "ui_issueViewLayoutRichTextField_IssueViewRichTextField_richText"
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "plainTextField_issueViewLayoutRichTextField"
    }
  ],
  "type": "JiraRichTextField"
};

(node as any).hash = "2ce7c451403835781ee5a741db89ce31";

export default node;
