/**
 * @module is-broken-safari
 * XXX: This module exists solely as a preventive measure for
 * HOT-94246, where Safari >= 14.0.3 presents reasonable performance
 * regressions. Once Safari fixes this issue, we should completely
 * get rid of this verification.
 */

const VERSION_REGEX = /Version\/(\d+\.\d+(\.\d+)?)/;
const BROKEN_VERSION = [14, 0, 3];

/**
 * Compare semver versions and return whether the given version
 * is newer or the same as the broken Safari version.
 *
 * @param {string} version version to compare
 * @returns whether the given version is >= 14.0.3
 */
const isNewerOrEqualVersion = (version: string) => {
	const parts = version.split('.');
	for (let i = 0; i < parts.length; i++) {
		const component = parseInt(parts[i], 10);
		if (component === BROKEN_VERSION[i]) {
			// eslint-disable-next-line no-continue
			continue;
		}

		return component > BROKEN_VERSION[i];
	}

	return true;
};

/**
 * Check if the browser is Safari and it's newer or the same as version
 * 14.0.3 as it has shown multiple performance issues.
 * Note that this function has an additional check for Chrome because
 * Chrome reports Safari as part of its version on macOS.
 *
 * @returns whether it's a broken version of Safari
 * @see BENTO-8778 for more information about why this check exists
 * @see HOT-94246 for details of why Safari 14.0.3 is a naugthy browser
 */
export const isBrokenSafari = () => {
	if (typeof navigator === 'undefined') {
		return false;
	}

	const { appVersion } = navigator;
	if (appVersion.includes('Chrome') || !appVersion.includes('Safari')) {
		return false;
	}

	const match = appVersion.match(VERSION_REGEX) ?? [];
	const version = match[1] ?? '0.0.0';
	return isNewerOrEqualVersion(version);
};
