import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	postMoveAnnouncement: {
		id: 'issue-item-line-card.post-move-announcement',
		defaultMessage:
			'You\'ve moved "{itemName}" from position {startPosition} to position {endPosition} of {numberOfItems}',
		description: 'Message announced to screen readers after an item is reordered',
	},
});
