import { useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';
import giraClient from '@atlassian/jira-apollo-gira/src/index.tsx';
import { getClient } from '@atlassian/jira-apollo-multiple-clients/src/main.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { isBackendAPIError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import {
	fireOperationalAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import type {
	GetRequestTypesForChangeCalendarEventCreateDialog as Response,
	GetRequestTypesForChangeCalendarEventCreateDialogVariables as Variables,
} from './__generated_apollo__/GetRequestTypesForChangeCalendarEventCreateDialog';
import { GET_REQUEST_TYPES } from './gql.tsx';
import type { Options, RequestTypesData } from './types.tsx';
import { transformResponse } from './utils.tsx';

export const useGetRequestTypesForIssueCreate = (options: Options): [RequestTypesData] => {
	const { projectId, filter } = options;
	const tenantContext = useTenantContext();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const client = getClient(tenantContext.baseUrl, giraClient, tenantContext.xsrfToken ?? '');

	const variables: Variables = {
		project: {
			projectId: projectId?.toString() ?? '',
		},
	};

	const queryResult = useQuery<Response, Variables>(GET_REQUEST_TYPES, {
		client,
		variables,
		skip: projectId == null,
		onCompleted: () => {
			// https://data-portal.internal.atlassian.com/analytics/registry/40167
			fireOperationalAnalytics(
				createAnalyticsEvent({}),
				'jiraServicedeskChangeCalendar.fetchRequestTypesForIssueCreate succeeded',
			);
		},
		onError: (apolloError) => {
			// https://data-portal.internal.atlassian.com/analytics/registry/40168
			fireErrorAnalytics({
				error: apolloError,
				meta: {
					id: 'fetchRequestTypesForIssueCreate',
					packageName: 'jiraServicedeskGetRequestTypesForIssueCreate',
					teamName: 'jenga',
				},
				sendToPrivacyUnsafeSplunk: true,
				skipSentry: isBackendAPIError(apolloError.networkError),
			});
		},
	});

	const data = useMemo(
		() => transformResponse(queryResult.loading, queryResult.error, queryResult.data, filter),
		[queryResult, filter],
	);

	return [
		{
			data,
			loading: queryResult.loading,
			error: queryResult.error,
		},
	];
};
