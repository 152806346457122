import { useMemo } from 'react';
import {
	useAnalyticsEvents,
	fireTrackAnalytics,
	type ActionSubjectAndAction,
} from '@atlassian/jira-product-analytics-bridge';
import type { AnalyticsAttributesUntyped } from '@atlassian/jira-product-analytics-bridge/src/types';

export const analyticsAttrs = {
	aiFeatureName: 'jiraSmartSummarizeComments',
	proactiveAIGenerated: 0,
	userGeneratedAI: 1,
	isAIFeature: 1,
} as const;

export const useSmartSummarizeCommentsAnalytics = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const actions = useMemo(() => {
		const event = createAnalyticsEvent({});
		return {
			fireTrack: (name: ActionSubjectAndAction, id: string, attrs?: AnalyticsAttributesUntyped) =>
				fireTrackAnalytics(event, name, id, { ...analyticsAttrs, ...attrs }),
		};
	}, [createAnalyticsEvent]);
	return actions;
};
