import React, { useRef, useCallback, useState } from 'react';
import { AsyncSelect } from '@atlaskit/select';
import { metrics } from '@atlassian/browser-metrics';
import { useIntl } from '@atlassian/jira-intl';
import { formatOptionLabel } from '@atlassian/jira-issue-field-select-base/src/ui/format-option-label/index.tsx';
import { defaultSelectStyles } from '@atlassian/jira-issue-field-select-base/src/ui/react-select-styles/styled.tsx';
import { isInSample } from '@atlassian/jira-issue-sample-utils/src/common/utils/index.tsx';
import debouncePromise from '@atlassian/jira-platform-debounce-promise/src/ui/debounced-promise/index.tsx';
import {
	fireOperationalAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import type { Options } from '../../../common/types.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';
import { fetchProjects } from './utils.tsx';

const LOAD_OPTIONS_DEBOUNCE_TIMER = 300;

const PROJECT_OPTIONS_LOADED = 'issue-field-project-options-loaded';
const projectOptionsLoadedMetric = metrics.custom({
	key: PROJECT_OPTIONS_LOADED,
});

export const ProjectCustomFieldEdit = (props: Props) => {
	const {
		fieldId,
		autoCompleteUrl,
		autoFocus,
		blurInputOnSelect,
		isDropdownMenuFixedAndLayered,
		hideSelectedOptions,
		onChange,
		onCloseMenuOnScroll,
		openMenuOnFocus,
		spacing,
		fetchSuggestionsOnFocus = false,
		isInvalid = false,
		value = null,
		onBlur,
		'aria-labelledby': ariaLabelledBy,
	} = props;

	const { formatMessage } = useIntl();

	const [lastFetchFailed, setLastFetchFailed] = useState<boolean>(false);
	const [defaultOptions, setDefaultOptions] = useState<Options>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const shouldFetchDefaultOptions = useRef<boolean>(true);

	const isInSampleAnalytics = useRef<boolean>(isInSample());
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const fireOptionsLoadedAnalyticsEvent = (duration: number | null) => {
		const action = 'loaded';
		const actionSubject = 'projectFieldOptions';
		const event = createAnalyticsEvent({
			action,
			actionSubject,
		});
		const attrs = { duration };

		fireOperationalAnalytics(event, `${actionSubject} ${action}`, attrs);
	};

	const searchProjects = async (query = ''): Promise<Options> => {
		setLastFetchFailed(false);
		isInSampleAnalytics.current && projectOptionsLoadedMetric.start();
		return fetchProjects(query, autoCompleteUrl)
			.then((projectsData) => {
				if (isInSampleAnalytics.current) {
					projectOptionsLoadedMetric.stop();
					const { start, stop } = projectOptionsLoadedMetric.getData();
					const duration = stop && start ? stop - start : null;
					fireOptionsLoadedAnalyticsEvent(duration);
				}
				return projectsData;
			})
			.catch(() => {
				isInSampleAnalytics.current && projectOptionsLoadedMetric.cancel();
				setLastFetchFailed(true);
				return [];
			});
	};

	const onFocus = async () => {
		if (
			shouldFetchDefaultOptions &&
			defaultOptions &&
			shouldFetchDefaultOptions.current &&
			!defaultOptions.length
		) {
			shouldFetchDefaultOptions.current = false;
			setIsLoading && setIsLoading(true);
			const options = await searchProjects();
			setDefaultOptions && setDefaultOptions(options);
			setIsLoading && setIsLoading(false);
		}
	};

	// go/jfe-eslint
	const loadOptionsDebounced = debouncePromise(searchProjects, LOAD_OPTIONS_DEBOUNCE_TIMER);

	const getNoOptionsMessage = useCallback(
		() => formatMessage(lastFetchFailed ? messages.failedFetch : messages.noMatches),
		[formatMessage, lastFetchFailed],
	);

	const defaultOptionsProps = () => {
		if (fetchSuggestionsOnFocus) {
			return { onFocus, isLoading, defaultOptions };
		}
		return {};
	};
	// NOTE: Only used for drilling down `react-select` props!
	// See: https://react-select.com/props
	const additionalSelectProps = {
		blurInputOnSelect,
		hideSelectedOptions,
		openMenuOnFocus,
		spacing,
	};

	return (
		<AsyncSelect
			fieldId={fieldId}
			autoFocus={autoFocus}
			{...defaultOptionsProps()}
			{...additionalSelectProps}
			isClearable
			// @ts-expect-error - TS2322 - Type '((e: ChangeEvent<HTMLElement>) => boolean) | undefined' is not assignable to type 'boolean | EventListener | undefined'.
			closeMenuOnScroll={onCloseMenuOnScroll}
			formatOptionLabel={formatOptionLabel}
			loadOptions={loadOptionsDebounced}
			menuPosition={isDropdownMenuFixedAndLayered === true ? 'fixed' : undefined}
			noOptionsMessage={getNoOptionsMessage}
			placeholder={formatMessage(messages.placeholder)}
			styles={defaultSelectStyles}
			// @ts-expect-error - TS2322 - Type '"error" | null' is not assignable to type 'ValidationState | undefined'.
			validationState={isInvalid === true ? 'error' : null}
			value={value}
			// @ts-expect-error - TS2322 - Type '(value: Option) => void' is not assignable to type '(value: Option | null, action: ActionMeta<Option>) => void'.
			onChange={onChange}
			onBlur={onBlur}
			aria-labelledby={ariaLabelledBy}
			inputId={fieldId}
		/>
	);
};
