import pick from 'lodash/pick';
import {
	PolarisTimelineMode,
	type PolarisTimelineConfig,
} from '@atlassian/jira-polaris-domain-view/src/timeline/types.tsx';
import type {
	TimelineConfigDraft,
	View,
	ViewDraft,
} from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';

const AUTOSAVE_AFFECTED_VIEW_CONFIGURATIONS: (keyof ViewDraft)[] = [
	'verticalGroupBy',
	'verticalGroupValues',
	'groupBy',
	'groupValues',
	'filter',
	'sortBy',
	'sortMode',
	'fields',
	'hidden',
	'matrixConfig',
	'timelineConfig',
	'hideEmptyGroups',
	'hideEmptyColumns',
	'fieldRollups',
	'layoutType',
];

const AUTOSAVE_AFFECTED_TIMELINE_CONFIGURATIONS: (keyof TimelineConfigDraft)[] = [
	'startDateField',
	'dueDateField',
	'startTimestamp',
	'endTimestamp',
	'mode',
	'summaryCardField',
];

export const getTimelineConfigDraft = (
	timelineConfig: PolarisTimelineConfig = {
		startDateField: undefined,
		dueDateField: undefined,
		startTimestamp: undefined,
		endTimestamp: undefined,
		summaryCardField: undefined,
		mode: PolarisTimelineMode.QUARTERS,
	},
): TimelineConfigDraft => pick(timelineConfig, AUTOSAVE_AFFECTED_TIMELINE_CONFIGURATIONS);

export const getAutosaveAffectedViewConfigurations = (view: View): ViewDraft =>
	AUTOSAVE_AFFECTED_VIEW_CONFIGURATIONS.reduce(
		(acc, key) => ({
			...acc, // eslint-disable-line jira/js/no-reduce-accumulator-spread
			[key]: key === 'timelineConfig' ? getTimelineConfigDraft(view.timelineConfig) : view[key],
		}),
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		{} as ViewDraft,
	);

export const applyDraftToView = (view: View): View => {
	const newView: View = {
		...view,
		...view.draft,
	};

	if (view.timelineConfig) {
		newView.timelineConfig = {
			...view.timelineConfig,
			...(view.draft?.timelineConfig || {}),
		};
	}

	return newView;
};
