import type { User } from '@atlassian/jira-issue-shared-types/src/common/types/user-type.tsx';

// FETCH_LOGGED_IN_USER_REQUEST
export const FETCH_LOGGED_IN_USER_REQUEST = 'FETCH_LOGGED_IN_USER_REQUEST' as const;

export const fetchLoggedInUserRequest = () => ({
	type: FETCH_LOGGED_IN_USER_REQUEST,
});

export type FetchLoggedInUserRequest = ReturnType<typeof fetchLoggedInUserRequest>;

// FETCH_LOGGED_IN_USER_SUCCESS
export const FETCH_LOGGED_IN_USER_SUCCESS = 'FETCH_LOGGED_IN_USER_SUCCESS' as const;

export type UserFetchSuccessAction = {
	type: typeof FETCH_LOGGED_IN_USER_SUCCESS;
	payload: {
		user: User;
	};
};

export const fetchLoggedInUserSuccess = (user: User): UserFetchSuccessAction => ({
	type: FETCH_LOGGED_IN_USER_SUCCESS,
	payload: { user },
});

export type FetchLoggedInUserSuccess = ReturnType<typeof fetchLoggedInUserSuccess>;

// FETCH_LOGGED_IN_USER_FAILURE
export const FETCH_LOGGED_IN_USER_FAILURE = 'FETCH_LOGGED_IN_USER_FAILURE' as const;

export const fetchLoggedInUserFailure = () => ({
	type: FETCH_LOGGED_IN_USER_FAILURE,
});

export type FetchLoggedInUserFailure = ReturnType<typeof fetchLoggedInUserFailure>;

export type UserFetchAction = {
	type: typeof FETCH_LOGGED_IN_USER_REQUEST | typeof FETCH_LOGGED_IN_USER_FAILURE;
};

export type Action = FetchLoggedInUserRequest | FetchLoggedInUserSuccess | FetchLoggedInUserFailure;
