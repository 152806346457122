import React, { useCallback } from 'react';
import { graphql, useFragment } from 'react-relay';
import type { IssueViewRelayFragment } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/main.tsx';
import { useLazyLoadQueryAfterPageLoad } from '@atlassian/jira-issue-hooks/src/services/use-lazy-load-query-after-page-load/index.tsx';
import { LABELS_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { useCurrentUser } from '@atlassian/jira-platform-services-user-current/src/main.tsx';
import type {
	prefetchFieldSuggestions_issueView_MaybePrefetchFieldSuggestions$key,
	prefetchFieldSuggestions_issueView_MaybePrefetchFieldSuggestions$data,
} from '@atlassian/jira-relay/src/__generated__/prefetchFieldSuggestions_issueView_MaybePrefetchFieldSuggestions.graphql';
import { shouldPrefetchLabelsSuggestionsUtil } from './utils.tsx';

// A relay powered replacement of SmartFieldPrefetchApp
export const PrefetchFieldSuggestions = ({
	issueViewRelayFragment,
}: {
	issueViewRelayFragment: IssueViewRelayFragment;
}) => {
	const getAriForFieldId = useCallback(
		(fieldId: string): string | undefined => {
			const field = issueViewRelayFragment?.fields?.edges?.find(
				(edge) => edge?.node && 'fieldId' in edge.node && edge.node.fieldId === fieldId,
			)?.node;

			return field && 'id' in field ? field.id : undefined;
		},
		[issueViewRelayFragment],
	);

	useLazyLoadQueryAfterPageLoad(
		graphql`
			query prefetchFieldSuggestionsQuery($labelsFieldAri: ID!, $first: Int) {
				# eslint-disable-next-line @atlassian/relay/must-colocate-fragment-spreads
				...labels_issueFieldLabelsEditviewFull_LabelsEditViewWithSuggestionsFragment_suggestionsFragment
					@arguments(id: $labelsFieldAri, first: $first)
				# add any other fragments from edit components of fields which have data prefetched using SmartFieldPrefetchApp
			}
		`,
		{ labelsFieldAri: getAriForFieldId(LABELS_TYPE), first: 50 },
	);

	return null;
};

export const MaybePrefetchFieldSuggestions = ({
	issueViewRelayFragment,
}: {
	issueViewRelayFragment: IssueViewRelayFragment;
}) => {
	const currentUserData = useCurrentUser();
	const currentUserAccountId = currentUserData?.data?.user?.accountId;

	const fieldsData: prefetchFieldSuggestions_issueView_MaybePrefetchFieldSuggestions$data | null =
		useFragment<prefetchFieldSuggestions_issueView_MaybePrefetchFieldSuggestions$key>(
			graphql`
				fragment prefetchFieldSuggestions_issueView_MaybePrefetchFieldSuggestions on JiraIssue {
					status: fieldsById(ids: ["status"]) {
						edges {
							node {
								... on JiraStatusField {
									status {
										statusCategory {
											statusCategoryId
										}
									}
								}
							}
						}
					}
					reporter: fieldsById(ids: ["reporter"]) {
						edges {
							node {
								... on JiraSingleSelectUserPickerField {
									user {
										accountId
									}
								}
							}
						}
					}
					assignee: fieldsById(ids: ["assignee"]) {
						edges {
							node {
								... on JiraSingleSelectUserPickerField {
									user {
										accountId
									}
								}
							}
						}
					}
				}
			`,
			// @ts-expect-error - No overload matches this call.
			issueViewRelayFragment,
		);

	if (fieldsData) {
		const labelsData = issueViewRelayFragment?.fields?.edges?.find(
			(edge) => edge?.node && 'fieldId' in edge.node && edge.node.fieldId === 'labels',
		)?.node;
		const labelsFieldIsNotEmpty = !!(
			labelsData &&
			'selectedLabelsConnection' in labelsData &&
			labelsData.selectedLabelsConnection?.edges?.length
		);

		const statusData = fieldsData.status?.edges;
		const statusCategoryId =
			statusData && statusData[0]
				? statusData[0].node?.status?.statusCategory?.statusCategoryId
				: undefined;
		const reporterData = fieldsData.reporter?.edges;
		const reporterAccountId =
			reporterData && reporterData[0] ? reporterData[0].node?.user?.accountId : undefined;
		const assigneeData = fieldsData.assignee?.edges;
		const assigneeAccountId =
			assigneeData && assigneeData[0] ? assigneeData[0].node?.user?.accountId : undefined;

		const shouldPrefetchLabelsSuggestions = shouldPrefetchLabelsSuggestionsUtil(
			labelsFieldIsNotEmpty,
			statusCategoryId,
			reporterAccountId,
			assigneeAccountId,
			currentUserAccountId,
		);

		return shouldPrefetchLabelsSuggestions ? (
			<PrefetchFieldSuggestions issueViewRelayFragment={issueViewRelayFragment} />
		) : null;
	}

	return null;
};
