import React, { Component, type MouseEvent, type Ref } from 'react';
import noop from 'lodash/noop';
import { graphql, useFragment } from 'react-relay';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { BreadcrumbsItem } from '@atlaskit/breadcrumbs';
import AkTooltip from '@atlaskit/tooltip';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import type { ParentIssue } from '@atlassian/jira-issue-view-store/src/selectors/breadcrumbs-selector.tsx';
import type { issueParentSwitcher_issueViewFoundation_IssueParentSwitcherWithRelay$key } from '@atlassian/jira-relay/src/__generated__/issueParentSwitcher_issueViewFoundation_IssueParentSwitcherWithRelay.graphql';
import type { parentIssue_issueViewFoundation_BreadcrumbParentIssueNew$key } from '@atlassian/jira-relay/src/__generated__/parentIssue_issueViewFoundation_BreadcrumbParentIssueNew.graphql';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import IssueParentSwitcher from '../../issue-parent-switcher/index.tsx';
import { truncateBreadcrumbs } from '../common/utils.tsx';
import { IssueContainer, SmallBreadcrumbsItem } from '../styled.tsx';

export type Props = {
	isSimplifiedProject: boolean;
	isDisabled: boolean;
	issueHierarchyLevel: number | null;
	parentIssue: ParentIssue;
	currentIssueKey: string;
	onClick: (event: MouseEvent, analyticsEvent?: UIAnalyticsEvent) => void;
	triggerRef?: Ref<HTMLElement>;
	onAddOrRemoveParent?: () => void;
	issue: parentIssue_issueViewFoundation_BreadcrumbParentIssueNew$key;
};

type State = {
	isHovered: boolean;
};

const BreadcrumbParentIssueNew = ({ issue, ...rest }: Props) => {
	// eslint-disable-next-line @atlassian/relay/query-restriction
	const data = useFragment<parentIssue_issueViewFoundation_BreadcrumbParentIssueNew$key>(
		graphql`
			fragment parentIssue_issueViewFoundation_BreadcrumbParentIssueNew on JiraIssue {
				...issueParentSwitcher_issueViewFoundation_IssueParentSwitcherWithRelay
			}
		`,
		issue,
	);
	return <BreadcrumbParentIssueInner issue={data} {...rest} />;
};

// eslint-disable-next-line jira/react/no-class-components
class BreadcrumbParentIssueInner extends Component<
	Omit<Props, 'issue'> & {
		issue: issueParentSwitcher_issueViewFoundation_IssueParentSwitcherWithRelay$key;
	},
	State
> {
	static defaultProps = {
		isSimplifiedProject: false,
		isDisabled: true,
		issueHierarchyLevel: 0,
		onClick: noop,
		triggerRef: { current: null },
		onAddOrRemoveParent: noop,
	};

	state: State = { isHovered: false };

	onHoveredChange = (isHovered: boolean) => () => {
		this.setState({ isHovered });
	};

	renderBreadcrumbsItem() {
		const { isSimplifiedProject, parentIssue, onClick } = this.props;
		const { key, summary, url } = parentIssue;

		return (
			<AkTooltip
				content={`${key}: ${summary}`}
				position="top"
				delay={0}
				/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
				testId="issue.views.issue-base.foundation.breadcrumbs.parent-issue.tooltip"
			>
				<BreadcrumbsItem
					/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
					testId="issue.views.issue-base.foundation.breadcrumbs.parent-issue.item"
					href={url}
					onClick={onClick}
					text={truncateBreadcrumbs(key)}
					truncationWidth={isSimplifiedProject ? undefined : 300}
					component={SmallBreadcrumbsItem}
				/>
			</AkTooltip>
		);
	}

	render() {
		const {
			isDisabled,
			parentIssue,
			issueHierarchyLevel,
			currentIssueKey,
			triggerRef,
			onAddOrRemoveParent,
			issue,
		} = this.props;
		const { key, issueTypeName, issueTypeIconUrl } = parentIssue;
		const breadcrumbsItemWithTooltip = this.renderBreadcrumbsItem();
		let resolvedIssueTypeIconUrl = '';
		if (isVisualRefreshEnabled() && fg('jira_nav4_eap_drop_2')) {
			resolvedIssueTypeIconUrl = issueTypeIconUrl;
		} else if (!this.state.isHovered) {
			resolvedIssueTypeIconUrl = issueTypeIconUrl;
		}

		return (
			<IssueContainer
				// eslint-disable-next-line jira/integration/test-id-by-folder-structure
				data-testid="issue.views.issue-base.foundation.breadcrumbs.breadcrumb-parent-issue-container"
				onMouseEnter={!isDisabled ? this.onHoveredChange(true) : undefined}
				onMouseLeave={!isDisabled ? this.onHoveredChange(false) : undefined}
				fixExtraDivider
				shouldTruncate
				role="listitem"
			>
				<IssueParentSwitcher
					isDisabled={isDisabled}
					currentIssueKey={currentIssueKey}
					issueKey={key}
					issueTypeName={issueTypeName}
					issueTypeIconUrl={resolvedIssueTypeIconUrl}
					issueHierarchyLevel={issueHierarchyLevel}
					onSelect={this.onHoveredChange(false)}
					onAddOrRemoveParent={onAddOrRemoveParent}
					triggerRef={triggerRef}
					component={
						fg('relay-migration-issue-header-and-parent') ? undefined : SmallBreadcrumbsItem
					}
					issue={issue}
				/>
				{breadcrumbsItemWithTooltip}
			</IssueContainer>
		);
	}
}

const BreadcrumbParentIssue = componentWithCondition(
	() => fg('relay-migration-issue-header-and-parent'),
	BreadcrumbParentIssueNew,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	BreadcrumbParentIssueInner as unknown as (props: Props) => React.ReactNode,
);

export default BreadcrumbParentIssue;
