/**
 * @generated SignedSource<<2b3e8e8308bc279b607b0a43731892d1>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutReporterField_IssueViewReporterField$data = {
  readonly __typename: "JiraSingleSelectUserPickerField";
  readonly fieldId: string;
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"reporter_issueFieldReporterInlineEditFull_ReporterInlineEditView_fragmentRef" | "ui_issueViewLayoutFieldHeading_IssueViewFieldHeading">;
  readonly " $fragmentType": "ui_issueViewLayoutReporterField_IssueViewReporterField";
};
export type ui_issueViewLayoutReporterField_IssueViewReporterField$key = {
  readonly " $data"?: ui_issueViewLayoutReporterField_IssueViewReporterField$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutReporterField_IssueViewReporterField">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutReporterField_IssueViewReporterField",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "ui_issueViewLayoutFieldHeading_IssueViewFieldHeading"
    },
    {
      "kind": "FragmentSpread",
      "name": "reporter_issueFieldReporterInlineEditFull_ReporterInlineEditView_fragmentRef"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "ScalarField",
      "name": "__typename"
    }
  ],
  "type": "JiraSingleSelectUserPickerField"
};

(node as any).hash = "a0e1b6ef771a09196f4e7a0722372b3f";

export default node;
