/**
 * @generated SignedSource<<7a87763ce970c1c8fa45e49fe652f3eb>>
 * @relayHash 3e828c47183504248650054be7346951
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 274f360c028a35a367c74bd0df1133793ef29a52b5241ee76c6ba42b4324914a

import type { ConcreteRequest, Query } from 'relay-runtime';
export type issueUserTeamMentionProviderQuery$variables = {
  cloudId: string;
  issueKey: string;
  maxResults: number;
  organizationId: string;
  query: string;
  sessionId: string;
  siteId: string;
};
export type issueUserTeamMentionProviderQuery$data = {
  readonly jira: {
    readonly searchUserTeamMention: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly team: {
            readonly fullTeam: {
              readonly displayName: string | null | undefined;
              readonly id: string;
              readonly members: {
                readonly nodes: ReadonlyArray<{
                  readonly member: {
                    readonly accountId: string;
                    readonly id: string;
                    readonly name: string;
                    readonly picture: AGG$URL;
                  } | null | undefined;
                } | null | undefined> | null | undefined;
              } | null | undefined;
              readonly smallAvatarImageUrl: string | null | undefined;
            } | null | undefined;
            readonly jiraIncludesYou: boolean | null | undefined;
            readonly jiraMemberCount: number | null | undefined;
          } | null | undefined;
          readonly user: {
            readonly accountId: string;
            readonly name: string;
            readonly picture: AGG$URL;
          } | null | undefined;
        };
      } | null | undefined>;
    } | null | undefined;
  } | null | undefined;
};
export type issueUserTeamMentionProviderQuery = {
  response: issueUserTeamMentionProviderQuery$data;
  variables: issueUserTeamMentionProviderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueKey"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "maxResults"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "query"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sessionId"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "siteId"
},
v7 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "kind": "Variable",
    "name": "issueKey",
    "variableName": "issueKey"
  },
  {
    "kind": "Variable",
    "name": "maxResults",
    "variableName": "maxResults"
  },
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  },
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "query"
  },
  {
    "kind": "Variable",
    "name": "sessionId",
    "variableName": "sessionId"
  }
],
v8 = [
  {
    "kind": "Variable",
    "name": "siteId",
    "variableName": "siteId"
  }
],
v9 = {
  "kind": "ScalarField",
  "name": "id"
},
v10 = {
  "kind": "ScalarField",
  "name": "displayName"
},
v11 = {
  "kind": "ScalarField",
  "name": "smallAvatarImageUrl"
},
v12 = {
  "kind": "ScalarField",
  "name": "accountId"
},
v13 = {
  "kind": "ScalarField",
  "name": "name"
},
v14 = {
  "kind": "ScalarField",
  "name": "picture"
},
v15 = {
  "kind": "ScalarField",
  "name": "jiraIncludesYou"
},
v16 = {
  "kind": "ScalarField",
  "name": "jiraMemberCount"
},
v17 = {
  "kind": "ScalarField",
  "name": "__typename"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "name": "issueUserTeamMentionProviderQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v7/*: any*/),
            "concreteType": "JiraMentionableConnection",
            "kind": "LinkedField",
            "name": "searchUserTeamMention",
            "plural": false,
            "selections": [
              {
                "kind": "RequiredField",
                "field": {
                  "concreteType": "JiraMentionableEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "RequiredField",
                      "field": {
                        "concreteType": "JiraMentionable",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "concreteType": "JiraTeamView",
                            "kind": "LinkedField",
                            "name": "team",
                            "plural": false,
                            "selections": [
                              {
                                "args": (v8/*: any*/),
                                "concreteType": "TeamV2",
                                "kind": "LinkedField",
                                "name": "fullTeam",
                                "plural": false,
                                "selections": [
                                  (v9/*: any*/),
                                  (v10/*: any*/),
                                  (v11/*: any*/),
                                  {
                                    "concreteType": "TeamMemberConnectionV2",
                                    "kind": "LinkedField",
                                    "name": "members",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "concreteType": "TeamMemberV2",
                                        "kind": "LinkedField",
                                        "name": "nodes",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "kind": "LinkedField",
                                            "name": "member",
                                            "plural": false,
                                            "selections": [
                                              (v9/*: any*/),
                                              (v12/*: any*/),
                                              (v13/*: any*/),
                                              (v14/*: any*/)
                                            ]
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              },
                              (v15/*: any*/),
                              (v16/*: any*/)
                            ]
                          },
                          {
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v12/*: any*/),
                              (v13/*: any*/),
                              (v14/*: any*/)
                            ]
                          }
                        ]
                      },
                      "action": "THROW",
                      "path": "jira.searchUserTeamMention.edges.node"
                    }
                  ]
                },
                "action": "THROW",
                "path": "jira.searchUserTeamMention.edges"
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v6/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v5/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "issueUserTeamMentionProviderQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v7/*: any*/),
            "concreteType": "JiraMentionableConnection",
            "kind": "LinkedField",
            "name": "searchUserTeamMention",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraMentionableEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "JiraMentionable",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraTeamView",
                        "kind": "LinkedField",
                        "name": "team",
                        "plural": false,
                        "selections": [
                          {
                            "args": (v8/*: any*/),
                            "concreteType": "TeamV2",
                            "kind": "LinkedField",
                            "name": "fullTeam",
                            "plural": false,
                            "selections": [
                              (v9/*: any*/),
                              (v10/*: any*/),
                              (v11/*: any*/),
                              {
                                "concreteType": "TeamMemberConnectionV2",
                                "kind": "LinkedField",
                                "name": "members",
                                "plural": false,
                                "selections": [
                                  {
                                    "concreteType": "TeamMemberV2",
                                    "kind": "LinkedField",
                                    "name": "nodes",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "kind": "LinkedField",
                                        "name": "member",
                                        "plural": false,
                                        "selections": [
                                          (v17/*: any*/),
                                          (v9/*: any*/),
                                          (v12/*: any*/),
                                          (v13/*: any*/),
                                          (v14/*: any*/)
                                        ]
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          },
                          (v15/*: any*/),
                          (v16/*: any*/)
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          (v17/*: any*/),
                          (v12/*: any*/),
                          (v13/*: any*/),
                          (v14/*: any*/),
                          (v9/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "274f360c028a35a367c74bd0df1133793ef29a52b5241ee76c6ba42b4324914a",
    "metadata": {},
    "name": "issueUserTeamMentionProviderQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "9cdf46c2f750f3cd60f1f9855263c534";

export default node;
