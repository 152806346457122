import React from 'react';
import range from 'lodash/range';
import { useIsModal } from '@atlassian/jira-issue-context-service/src/main.tsx';
import ContainerMask from '@atlassian/jira-issue-view-common/src/skeleton/container-mask-view.tsx';
import SkeletonBackground from '@atlassian/jira-issue-view-common/src/skeleton/skeleton-background-view.tsx';
import SkeletonContainer from '@atlassian/jira-issue-view-common/src/skeleton/skeleton-container-view.tsx';
import TextLineMask from '@atlassian/jira-issue-view-common/src/skeleton/text-line-mask-view.tsx';

type Props = {
	itemCount: number;
	paddingTopInPixels?: number;
	paddingBottomInPixels?: number;
};

const ItemLineCardGroup = ({
	itemCount,
	paddingTopInPixels = 0,
	paddingBottomInPixels = 0,
}: Props) => {
	const isModal = useIsModal();

	const getItems = range(itemCount).map((index) => (
		<TextLineMask isModal={isModal} key={index} fontSize={20} lineHeight={41} intrusion={0} />
	));

	return (
		<SkeletonContainer>
			<SkeletonBackground />
			<ContainerMask
				isModal={isModal}
				paddingTop={paddingTopInPixels}
				paddingBottom={paddingBottomInPixels}
			>
				{getItems}
			</ContainerMask>
		</SkeletonContainer>
	);
};

export default ItemLineCardGroup;
