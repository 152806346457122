import React, { useEffect } from 'react';
import { createSelector } from 'reselect';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import {
	FireScreenAnalytics,
	ContextualAnalyticsData,
	SCREEN,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import {
	isAnonymousSelector,
	issueContextSelector,
	userSelector,
} from '../../controllers/context/selectors/index.tsx';
import { releasesDataFetchRequest } from '../../controllers/releases-data/actions/index.tsx';
import {
	deploymentSummarySelector,
	detailsLinkSelector,
	disabledFeatureFlagsSelector,
	disabledFeatureFlagsCntSelector,
	createFlagLinkSelector,
	connectFlagLinkSelector,
	enabledFeatureFlagsSelector,
	enabledFeatureFlagsCntSelector,
	panelHasFeatureFlagsSelector,
	panelHasFeatureFlagsProvidersSelector,
	panelHasValidProvidersOrDataSelector,
	providersSelector,
	remoteLinksDataSelector,
	remoteLinksCountSelector,
	rolloutSummarySelector,
	totalFeatureFlagSelector,
	shouldShowEmptyStateSelector,
	flagsPerProviderSelector,
	shouldShowAppConfigContextPromptSelector,
} from '../../controllers/releases-data/selectors/index.tsx';
import type { State } from '../../controllers/types.tsx';
import { ReleasesFieldSummary } from './main.tsx';
import { useOnMountedAnalytics } from './on-mounted-analytics/index.tsx';
import type {
	ReleasesFieldProps,
	ReleasesFieldDispatchProps,
	ReleasesFieldStateProps,
} from './types.tsx';

export const ReleasesField = (props: ReleasesFieldProps) => {
	const {
		isAnonymous,
		onIssueChange,
		hasProviders,
		hasSoftwareAccess,
		flagsPerProvider,
		providers,
		remoteLinksData,
	} = props;

	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		if (!isAnonymous && props.issueContext.issueId) {
			onIssueChange(
				createAnalyticsEvent({
					action: 'mounted',
				}),
			);
		}
	}, [props.issueContext.issueId, createAnalyticsEvent, onIssueChange, isAnonymous]);

	const fireOnMountAnalytics = useOnMountedAnalytics({
		actionSubject: 'panel',
		actionSubjectId: 'releasepanelsummary',
		flagsPerProvider,
		providers,
		remoteLinksData,
	});

	useEffect(() => {
		if (!isAnonymous && hasProviders) fireOnMountAnalytics();
	}, [fireOnMountAnalytics, hasProviders, isAnonymous]);

	return (
		<ContextualAnalyticsData sourceName="releasesField" sourceType={SCREEN}>
			<JSErrorBoundary id="releasesField" packageName="jiraDevelopmentSummaryReleasesField">
				<FireScreenAnalytics attributes={{ isAnonymous }} />
				{hasSoftwareAccess && !isAnonymous && <ReleasesFieldSummary {...props} />}
			</JSErrorBoundary>
		</ContextualAnalyticsData>
	);
};

type mapStateToPropsFactoryFn = (state: State) => ReleasesFieldStateProps;
const disabledFeatureFlagsSelectorMemoized = () =>
	createSelector(disabledFeatureFlagsSelector, (res) => res);
const enabledFeatureFlagsSelectorMemoized = () =>
	createSelector(enabledFeatureFlagsSelector, (res) => res);
const providersSelectorMemoized = () => createSelector(providersSelector, (res) => res);
const flagsPerProviderSelectorMemoized = () =>
	createSelector(flagsPerProviderSelector, (res) => res);

const mapStateToPropsFactory = (): mapStateToPropsFactoryFn => {
	const disabledFeatureFlagsSelectorInstance = disabledFeatureFlagsSelectorMemoized();
	const enabledFeatureFlagsSelectorInstance = enabledFeatureFlagsSelectorMemoized();
	const providersSelectorInstance = providersSelectorMemoized();
	const flagsPerProviderSelectorInstance = flagsPerProviderSelectorMemoized();

	return (state: State) => ({
		hasProviders: panelHasValidProvidersOrDataSelector(state),
		hasFeatureFlagsProviders: panelHasFeatureFlagsProvidersSelector(state),
		hasFeatureFlags: panelHasFeatureFlagsSelector(state),
		hasDeployments: Boolean(deploymentSummarySelector(state)),
		isAnonymous: isAnonymousSelector(state),
		user: userSelector(state),
		deploymentSummary: deploymentSummarySelector(state),
		shouldShowEmptyState: shouldShowEmptyStateSelector(state),
		shouldShowAppConfigContextPrompt: shouldShowAppConfigContextPromptSelector(state),
		providers: providersSelectorInstance(state),
		remoteLinksCount: remoteLinksCountSelector(state),
		issueContext: issueContextSelector(state),
		totalFeatureFlags: totalFeatureFlagSelector(state),
		createFlagLink: createFlagLinkSelector(state),
		connectFlagLink: connectFlagLinkSelector(state),
		enabledFeatureFlags: enabledFeatureFlagsSelectorInstance(state),
		disabledFeatureFlags: disabledFeatureFlagsSelectorInstance(state),
		detailsLink: detailsLinkSelector(state),
		enabledFeatureFlagsCnt: enabledFeatureFlagsCntSelector(state),
		disabledFeatureFlagsCnt: disabledFeatureFlagsCntSelector(state),
		rolloutSummary: rolloutSummarySelector(state),
		flagsPerProvider: flagsPerProviderSelectorInstance(state),
		remoteLinksData: remoteLinksDataSelector(state),
	});
};

export default connect(
	mapStateToPropsFactory,
	(dispatch): ReleasesFieldDispatchProps => ({
		onIssueChange: (analyticsEvent: UIAnalyticsEvent) => {
			dispatch(releasesDataFetchRequest(analyticsEvent));
		},
	}),
)(ReleasesField);
