import { createSelector } from 'reselect';
import isEqual from 'lodash/isEqual';
import set from 'lodash/set';
import type { Field, FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { Filter } from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import { isShallowEqual } from '@atlassian/jira-polaris-lib-equals/src/index.tsx';
import type { IssuesRemote } from '@atlassian/jira-polaris-remote-issue/src/controllers/types.tsx';
import {
	jiraExternalReferenceMapping,
	type Value,
} from '../../../../field/mapping/external-reference/index.tsx';
import type { PropertyMaps, State, ExternalReferenceIdProperty } from '../../../types.tsx';
import { safeStringListComparator } from '../../comparators/index.tsx';
import { CSV_SEPARATOR } from '../common/constanst.tsx';
import { createStringValueIntersectionFilter } from '../common/filter-utils.tsx';
import { removePropertyValue } from '../common/remove-utils.tsx';
import type { FieldMapping, GroupIdMap } from '../types.tsx';

const mapValueToEntityValue = (state: State, value: string): string | undefined =>
	state.properties.externalReferenceEntities[value]?.name;

const getGroupIdentitiesForIssue = (
	externalReference: ExternalReferenceIdProperty,
	issueId: LocalIssueId,
) => {
	const values = externalReference?.[issueId];
	if (!values) {
		return [];
	}
	if (Array.isArray(values)) {
		return values.map((value: string) => ({
			groupIdentity: value,
			value: [value],
		}));
	}
	return [
		{
			groupIdentity: values,
			value: values,
		},
	];
};

export const getGroupIdentitiesSelector = (
	fieldKey: FieldKey,
	issueIdsSelector: (state: State) => LocalIssueId[],
) =>
	createSelector(
		issueIdsSelector,
		(state: State) => state.properties.externalReference[fieldKey],
		(ids, externalReference) =>
			ids.reduce(
				(result, issueId) =>
					Object.assign(result, {
						[issueId]: getGroupIdentitiesForIssue(externalReference, issueId),
					}),
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				{} as GroupIdMap<Value>,
			),
	);

export const externalReferenceMapping = (
	issuesRemote: IssuesRemote,
	field: Field,
): FieldMapping<Value> => {
	const valueAccessor: FieldMapping<string | string[]>['valueAccessor'] = (
		state,
		props,
		issueId,
	) =>
		state.properties.externalReference[field.key] !== undefined
			? state.properties.externalReference[field.key][issueId]
			: undefined;

	const valueAccessorToExport: FieldMapping<string>['valueAccessorToExport'] = (
		state,
		props,
		issueId,
	) => {
		const value = valueAccessor(state, props, issueId) || '';
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return Array.isArray(value) ? (value as string[]).join(CSV_SEPARATOR) : (value as string);
	};

	return {
		...jiraExternalReferenceMapping(issuesRemote, field),
		setMutable: (maps: PropertyMaps, issueId: LocalIssueId, value?: Value) =>
			set(maps.externalReference, [field.key, issueId], value),
		setImmutable: (maps: PropertyMaps, issueId: LocalIssueId, value?: Value) => {
			if (
				maps.externalReference[field.key] &&
				isEqual(maps.externalReference[field.key][issueId], value)
			) {
				return maps;
			}
			return {
				...maps,
				externalReference: {
					...maps.externalReference,
					[field.key]: {
						...maps.externalReference[field.key],
						[issueId]: value,
					},
				},
			};
		},
		remove: (maps: PropertyMaps, issueId: LocalIssueId) =>
			removePropertyValue(field.key, maps, issueId, 'externalReference'),
		modifyImmutableIfMultiValueField: (
			maps: PropertyMaps,
			issueId: LocalIssueId,
			addValues?: string | string[],
			removeValues: string | string[] = [],
		) => {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			const oldValues = (maps.externalReference[field.key]?.[issueId] || []) as string[];
			const definedAddValues = addValues || [];
			const newValues = oldValues
				.filter(
					(oldValue) =>
						(Array.isArray(removeValues) ? removeValues : [removeValues]).findIndex(
							(removeValue) => oldValue === removeValue,
						) < 0,
				)
				.concat(
					(Array.isArray(definedAddValues) ? definedAddValues : [definedAddValues]).filter(
						(newValue) => oldValues.findIndex((oldValue) => oldValue === newValue) < 0,
					),
				);
			if (isShallowEqual(newValues, oldValues)) {
				return maps;
			}
			return {
				...maps,
				externalReference: {
					...maps.externalReference,
					[field.key]: {
						...maps.externalReference[field.key],
						[issueId]: newValues,
					},
				},
			};
		},
		comparator: () => 0,
		comparatorWithMapping(state, props, a, b, direction) {
			let aValues: string[] = [];
			if (a !== undefined) {
				if (field.configuration?.isMulti) {
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					aValues = a as string[];
				} else {
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					aValues = [a as string];
				}
			}
			let bValues: string[] = [];
			if (b !== undefined) {
				if (field.configuration?.isMulti) {
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					bValues = b as string[];
				} else {
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					bValues = [b as string];
				}
			}
			const mappedA = aValues
				.map((v) => mapValueToEntityValue(state, v))
				.filter((v): v is string => Boolean(v));
			const mappedB = bValues
				.map((v) => mapValueToEntityValue(state, v))
				.filter((v): v is string => Boolean(v));
			const rank = safeStringListComparator(mappedA, mappedB, direction);
			return rank;
		},
		valueAccessor,
		valueAccessorToExport,
		getAllValues: (state) =>
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			(state.properties.externalReference[field.key]
				? state.properties.externalReference[field.key]
				: {}) as Record<string, string | string[]>,
		getDependencyValues: (state) => state.properties.externalReferenceEntities,
		getGroupIdentitiesSelector,
		getGroupIdentities: (state, props, issueId) =>
			getGroupIdentitiesForIssue(state.properties.externalReference[field.key], issueId),
		allowEmptyGroup: true,
		getLabel: (groupIdentity, value) => {
			if (Array.isArray(value)) {
				return value[0] || groupIdentity;
			}
			return value || groupIdentity;
		},
		getFilter: (filter: Filter) => {
			if (filter.type === 'FIELD' && filter.field === field.key) {
				const stringValueIntersectionFilter = createStringValueIntersectionFilter(filter);
				if (stringValueIntersectionFilter === undefined) {
					return undefined;
				}
				return (value: undefined | Value, props, state, localIssueId) => {
					if (value === undefined) {
						return stringValueIntersectionFilter([], props, state, localIssueId);
					}
					const valueAsArray = Array.isArray(value) ? value : [value];
					return stringValueIntersectionFilter(valueAsArray, props, state, localIssueId);
				};
			}
			return undefined;
		},
	};
};
