import React from 'react';
import { FormattedMessage, defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	logWork: {
		id: 'issue-view-common.messages.time.log-work',
		defaultMessage: 'Log work',
		description: 'Action or label for logging work',
	},
	timeInputPlaceholder: {
		id: 'issue-view-common.messages.time.time-input-placeholder',
		defaultMessage: 'e.g. 2w 4d 6h 45m',
		description: 'Placeholder for a time input field',
	},
	exampleFormat: {
		id: 'issue-view-common.messages.time.example-format',
		defaultMessage: 'Use the format: {formatExample}',
		description: 'Tooltip with required input format',
	},
	weeks: {
		id: 'issue-view-common.messages.time.weeks',
		defaultMessage: '{w} = weeks',
		description:
			'Explaining the meaning of weeks in the locale. The final string will look like "w = weeks"',
	},
	days: {
		id: 'issue-view-common.messages.time.days',
		defaultMessage: '{d} = days',
		description:
			'Explaining the meaning of days in the locale. The final string will look like "d = days"',
	},
	hours: {
		id: 'issue-view-common.messages.time.hours',
		defaultMessage: '{h} = hours',
		description:
			'Explaining the meaning of hours in the locale. The final string will look like "h = hours"',
	},
	minutes: {
		id: 'issue-view-common.messages.time.minutes',
		defaultMessage: '{m} = minutes',
		description:
			'Explaining the meaning of minutes in the locale. The final string will look like "m = minutes"',
	},
});

export const timeInvalidMessage = (
	<FormattedMessage
		id="issue.common.messages.time.time-invalid-message"
		defaultMessage="You need to log time in the format {timeString}."
		values={{ timeString: <b>2w 4d 6h 45m</b> }}
		description="The text shown when a user enters time that is in an invalid format"
	/>
);
