/* eslint-disable no-param-reassign */

import { useState, useRef } from 'react';
import noop from 'lodash/noop';
import type { ConnectIframeProvider } from '@atlassian/connect-module-core';
import logger from '@atlassian/jira-common-util-logging/src/log.tsx';
import type { ACJSHost } from '@atlassian/jira-connect/src/index.tsx';

// @ts-expect-error - TS7006 - Parameter 'event' implicitly has an 'any' type.
const log = (message: string, event) =>
	logger.safeInfoWithoutCustomerData('Ecosystem.DefaultConnectIFrameProvider', message, event);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type IframeContext = any;
type ConstructorParams = {
	handleHideInlineDialog?: () => void;
	handleOnLoadingComplete?: () => void;
	handleOnTimeout?: () => void;
	connectHost?: ACJSHost;
	isAutoresizeBugfixEnabled?: boolean;
};
/**
 * Manages IFrames within Atlassian Connect apps, facilitating their interaction with the host environment. It addresses common challenges such as IFrame resizing issues and JWT expiration, ensuring a seamless integration. The class provides mechanisms for IFrame visibility control, loading state management, and secure context resolution, aiming to enhance the reliability and user experience of embedded app content.
 */
export default class DefaultConnectIFrameProvider implements ConnectIframeProvider {
	handleHideInlineDialog: () => void;

	handleOnLoadingComplete: () => void;

	handleOnTimeout: () => void;

	iframeContext: IframeContext | undefined;

	connectHost: ACJSHost | undefined;

	isAutoresizeBugfixEnabled: boolean;

	constructor({
		handleHideInlineDialog,
		handleOnLoadingComplete,
		handleOnTimeout,
		isAutoresizeBugfixEnabled,
	}: ConstructorParams = {}) {
		this.handleHideInlineDialog = handleHideInlineDialog || noop;
		this.handleOnLoadingComplete = handleOnLoadingComplete || noop;
		this.handleOnTimeout = handleOnTimeout || noop;
		this.isAutoresizeBugfixEnabled = isAutoresizeBugfixEnabled || false;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/jira-ssr/no-unchecked-globals-usage
	convertConnectOptions = (data: any) => window._AP._convertConnectOptions(data).options;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onStoreNestedIframeJSON = (connectAppProps: any) => {
		log('onStoreNestedIframeJSON', connectAppProps);
	};

	onHideInlineDialog() {
		this.handleHideInlineDialog();
		// @ts-expect-error - TS2554 - Expected 2 arguments, but got 1.
		log('DefaultConnectAppProvider.onHideInlineDialog');
	}

	getLoadingTimeoutMilliseconds = (appKey: string) => {
		log('getLoadingTimeoutMilliseconds', {
			appKey,
		});
		// In the ACJS we use an aggressive timeout value so we can test more quickly.
		return 30000;
	};

	handleIframeLoadingStarted = (appKey: string) => {
		log('handleIframeLoadingStarted', {
			appKey,
		});
	};

	handleIframeLoadingComplete = (appKey: string) => {
		this.handleOnLoadingComplete();
		log('handleIframeLoadingComplete', {
			appKey,
		});
	};

	handleIframeUnload = (appKey: string) => {
		log('handleIframeUnload', {
			appKey,
		});
	};

	handleIframeLoadTimeout = (appKey: string) => {
		this.handleOnTimeout();
		log('handleIframeLoadTimeout', {
			appKey,
		});
	};

	isJwtExpired = (url: string, connectHost: ACJSHost) => {
		if (connectHost.hasJwt && connectHost.isJwtExpired) {
			return url && connectHost.hasJwt(url) && connectHost.isJwtExpired(url);
		}
		// @ts-expect-error - TS2554 - Expected 2 arguments, but got 1.
		log('connectHost missing JWT utilities');
		return false;
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	sanitiseContext = (ctx: string | Record<string, any>): Record<string, any> =>
		typeof ctx === 'string' ? JSON.parse(ctx) : ctx;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	buildIframeStyles = (loadingState: any, LoadingState: any) => {
		if (loadingState === LoadingState.TIMEOUT) {
			return {
				opacity: 0.0,
				height: '0px',
			};
		}
		if (loadingState === LoadingState.LOADED) {
			// This is a hack to try to prevent the autoresize behaviour in the atlassian-connect/all.js for connect addons from
			// self-sizing to zero height (we see this flake very occasionally in pollinator)
			// We should see logs of this occurring outside pollinator when switched on in prod if it is a bug affecting customers.
			if (this.isAutoresizeBugfixEnabled && this.iframeContext && this.connectHost) {
				const iframes = Object.keys(this.connectHost?.getExtensions())
					.filter(
						(extensionKey) =>
							this.iframeContext && extensionKey.startsWith(this.iframeContext.options?.uniqueKey),
					)
					// prettier-ignore
					// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					.map((extensionKey) => window.document.getElementById(extensionKey))
					.filter((node) => node != null);
				iframes.forEach((iframe) => {
					if (iframe?.style?.height === '0px') {
						logger.safeWarnWithoutCustomerData(
							'ecosystem.iframe.provider',
							`Connect addon iframe with 0 height in loaded state. Set to 100%. appKey: ${this.iframeContext?.appKey ?? 'null'}, modulekey: ${this.iframeContext?.moduleKey ?? 'null'}`,
						);
						iframe.style.height = '100%';
					}
				});
			}
			return {
				opacity: 1.0,
				maxWidth: '100%',
			};
		}
		return null;
	};

	resolveIframeContext = (
		iframeContext: IframeContext,
		connectHost: ACJSHost,
	): Promise<IframeContext> => {
		this.iframeContext = iframeContext;
		this.connectHost = connectHost;
		return new Promise((resolve) => {
			if (iframeContext.url && !this.isJwtExpired(iframeContext.url, connectHost)) {
				resolve(iframeContext);
				return;
			}
			if (!connectHost.getContentResolver || !connectHost.getContentResolver()) {
				// @ts-expect-error - TS2554 - Expected 2 arguments, but got 1.
				log('No content resolver supplied');
				resolve(iframeContext);
				return;
			}
			let options;
			try {
				options = {
					...iframeContext.options,
					productContext: this.sanitiseContext(
						iframeContext.options.productContext || iframeContext.options.productCtx || {},
					),
					structuredContext: this.sanitiseContext(
						iframeContext.options.structuredContext || iframeContext.options.structuredCtx || {},
					),
				};
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (e: any) {
				// @ts-expect-error - TS2554 - Expected 2 arguments, but got 1.
				log('Unable to parse supplied productCtx');
				resolve(iframeContext);
				return;
			}
			connectHost
				.getContentResolver()({
					addon_key: iframeContext.appKey,
					key: iframeContext.moduleKey,
					options,
					classifier: 'json',
				})
				.then((promiseData) => {
					/* eslint-disable no-prototype-builtins */
					if (typeof promiseData !== 'object' || !promiseData.hasOwnProperty('url')) {
						// @ts-expect-error - TS2554 - Expected 2 arguments, but got 1.
						log('Invalid response');
						resolve(iframeContext);
					} else {
						resolve({
							url: promiseData.url,
							appKey: promiseData.addon_key || iframeContext.appKey,
							moduleKey: promiseData.key || iframeContext.moduleKey,
							options: {
								...iframeContext.options,
								...promiseData.options,
							},
						});
					}
				});
		});
	};
}
export const useDefaultConnectIframeProviderOld = (
	props?: ConstructorParams,
): DefaultConnectIFrameProvider => {
	const providerRef = useRef<DefaultConnectIFrameProvider>();
	if (providerRef.current == null) {
		providerRef.current = new DefaultConnectIFrameProvider(props);
	}
	return providerRef.current;
};

// A hook that enables functional components that mount ConnectIFrame components to keep the same IFrameProvider reference
// between renders.
export const useDefaultConnectIframeProviderNew = (
	props?: ConstructorParams,
): DefaultConnectIFrameProvider => useState(() => new DefaultConnectIFrameProvider(props))[0];
export const useDefaultConnectIframeProvider = (
	props?: ConstructorParams,
): DefaultConnectIFrameProvider => useDefaultConnectIframeProviderNew(props);
