import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	deleted: {
		id: 'issue-history.history-items.history-item.comment-history-item.deleted',
		defaultMessage: 'deleted a {comment}',
		description: `Describes that a user has deleted a comment from the issue.
            Part of the heading in History feed, appears after the display name of the user who made the change.
            {comment} is the 'Comment' field label.`,
	},
	comment: {
		id: 'issue-history.history-items.history-item.comment-history-item.comment',
		defaultMessage: 'Comment',
		description: "(noun) Value to display as label for the 'Comment' field",
	},
	commentLowercase: {
		id: 'issue-history.history-items.history-item.comment-history-item.comment-lowercase',
		defaultMessage: 'comment',
		description: "(noun) Value to display as label for the 'comment' field",
	},
});
