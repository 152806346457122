/**
 * NOTE:
 *
 * This file is automatically generated by i18n-tools.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
// Romanian
export default {
	'fabric.elements.share.form.title': 'Partajați',
	'fabric.elements.share.form.send': 'Trimiteți',
	'fabric.elements.share.form.retry': 'Reîncercați',
	'fabric.elements.share.form.comment.placeholder': 'Adăugați un mesaj',
	'fabric.elements.share.form.user-picker.placeholder.generic':
		'Introduceți nume, echipă sau e-mail',
	'fabric.elements.share.form.user-picker.placeholder.existingUserOnly':
		'Introduceți nume sau echipă',
	'fabric.elements.share.form.user-picker.add-more': 'Introduceți mai multe',
	'fabric.elements.share.form.user-picker.validation.required':
		'Selectați cel puțin un utilizator, echipă sau e-mail.',
	'fabric.elements.share.form.user-picker.no-options.domainBasedUserOnly':
		'Puteți invita persoane nu mai cu adrese de e-mail {domains}. Contactați administratorul site-ului pentru a invita alte persoane.',
	'fabric.elements.share.form.user-picker.no-options.existingUserOnly':
		'Nu am găsit niciun rezultat pentru „{inputValue}”.',
	'fabric.elements.share.form.user-picker.no-options.generic':
		'Nu am găsit niciun rezultat pentru "{inputValue}". Invită persoane utilizând o adresă de e-mail.',
	'fabric.elements.share.trigger.button.icon.label': 'Pictogramă partajare',
	'fabric.elements.share.trigger.button.text': 'Partajați',
	'fabric.elements.share.trigger.button.tooltip.text': 'Partajați',
	'fabric.elements.share.trigger.button.invite.text': 'Invitați',
	'fabric.elements.share.copylink.button.icon.label': 'Pictogramă copiere link',
	'fabric.elements.share.copylink.button.text': 'Copiați link',
	'fabric.elements.share.copied.to.clipboard.icon.label': 'Pictogramă copiere linkuri cu succes',
	'fabric.elements.share.copied.to.clipboard.message': 'Link copiat în clipboard',
	'fabric.elements.share.form.capabilities.info.message':
		'Invitația dvs. va fi transmisă unui administrator pentru aprobare',
	'fabric.elements.share.failure.icon.label': 'Pictogramă eroare',
	'fabric.elements.share.failure.message': 'Nu se poate partaja',
	'fabric.elements.share.success.message':
		'{object, select, blogpost {Blog post shared} panou {Board shared} calendar {Calendar shared} schiță{Draft shared} filtru {Filter shared} problemă {Issue shared} media {Media shared} pagină {Page shared} proiect {Project shared} pullrequest {Pull Request shared} întrebare {Question shared} raport {Report shared} depozit {Repository shared} solicitare {Request shared} planificare {Roadmap shared} site {Site shared} spațiu {Space shared} altele {Link Shared}}',
	'fabric.elements.share.admin.notified.message': 'Administratorul dvs. a fost notificat',
};
