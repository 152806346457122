import type { Observable as ObservableType } from 'rxjs/Observable';
import { gqlTagRest } from '@atlassian/jira-common-graphql-tags/src/index.tsx';
import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { ChildrenResponse } from './types.tsx';

export const getGraphQlUrl = (baseUrl: string): string => `${baseUrl}/rest/graphql/1/`;

const getChildrenGraphqlQuery = (parentId: IssueId): string => gqlTagRest`query childrenQuery {
      issue(issueIdOrKey: "${parentId}", latestVersion: true, screen:"view", fields:"subtasks") {
        fields {
          key
          value
        }
      }
    }`;

export const fetchChildren = (
	baseUrl: string,
	parentId: IssueId,
): ObservableType<ChildrenResponse> =>
	fetchJson$(getGraphQlUrl(baseUrl), {
		method: 'POST',
		body: JSON.stringify({
			query: getChildrenGraphqlQuery(parentId),
		}),
	});
