import { useEffect, useMemo } from 'react';
import { useService } from '@atlassian/jira-common-services/src/use-service/index.tsx';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';

type CustomFieldsName = string[];

type CustomFieldsData = {
	[key: string]: string;
};

type Field = {
	name: string;
	fieldId: string;
};
type ResourceResponse = {
	fields: Field[];
};

type Context = {
	projectId: number;
	issueTypeId: string;
};

const getCreateMetaUrl = (projectId: number, issueTypeId: string) => {
	return `/rest/api/3/issue/createmeta/${projectId}/issuetypes/${issueTypeId}?maxResults=200`;
};

const getCustomField = async ({
	projectId,
	issueTypeId,
}: {
	projectId: number;
	issueTypeId: string;
}): Promise<ResourceResponse> => {
	return performGetRequest<ResourceResponse>(getCreateMetaUrl(projectId, issueTypeId));
};

const transformData = (
	data: ResourceResponse | undefined,
	customFieldsName: CustomFieldsName,
): CustomFieldsData | null => {
	if (data && data.fields && Array.isArray(data.fields)) {
		const matchedFields = data.fields.filter(
			(field) => customFieldsName.indexOf(field.name) !== -1,
		);
		const result: CustomFieldsData = {};
		Object.entries(matchedFields).forEach(([_, value]) => {
			result[value.name] = value.fieldId;
		});
		return result;
	}
	return null;
};

export const useGetCustomFields = (
	context: Partial<Context>,
	customFieldsName: CustomFieldsName,
) => {
	const { projectId, issueTypeId } = context;
	const { loading, data, error, fetch } = useService<ResourceResponse, Context>(getCustomField);

	useEffect(() => {
		if (!loading && data == null && !error && projectId && issueTypeId) {
			fetch({ projectId, issueTypeId });
		}
	}, [loading, data, error, projectId, issueTypeId, fetch]);

	const modifiedData = useMemo(
		() => transformData(data, customFieldsName),
		[customFieldsName, data],
	);
	return [{ loading, data: modifiedData, error }];
};
