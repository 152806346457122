import type {
	Handler,
	ObjectKey,
	RecentUpdateContext,
	RecentUpdatesId,
	TaskDecisionProvider,
	TaskState,
} from '@atlaskit/task-decision';
import type { ToggleTaskCallback } from '../common/types.tsx';

export class IssueTaskDecisionProvider implements TaskDecisionProvider {
	_toggleTaskCallback: ToggleTaskCallback | undefined;

	constructor(public toggleTaskCallback?: ToggleTaskCallback) {
		this._toggleTaskCallback = toggleTaskCallback;
	}

	toggleTask(objectKey: ObjectKey, state: TaskState): Promise<TaskState> {
		if (this._toggleTaskCallback) {
			this._toggleTaskCallback(objectKey, state);
		}
		return Promise.resolve(state);
	}

	notifyRecentUpdates(_updateContext: RecentUpdateContext): void {
		// do nothing
	}

	subscribe(_objectKey: ObjectKey, _handler: Handler): void {
		// do nothing
	}

	unsubscribe(_objectKey: ObjectKey, _handler: Handler): void {
		// do nothing
	}

	unsubscribeRecentUpdates(_id: RecentUpdatesId) {
		// do nothing
	}
}
