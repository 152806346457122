/**
 * @generated SignedSource<<c71a6f62dcfd136b82b5a837a5a59d4d>>
 * @relayHash ac5544b9096e2bcf66ac7f9cd975386a
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 2343754de6a651625bc76ed351f09c6a656f7d654fbf167e3c170840995cd9ab

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type JiraIssueLinkDirection = "INWARD" | "OUTWARD" | "%future added value";
export type JiraProjectType = "BUSINESS" | "CUSTOMER_SERVICE" | "PRODUCT_DISCOVERY" | "SERVICE_DESK" | "SOFTWARE" | "%future added value";
export type JiraBulkCreateIssueLinksInput = {
  issueLinkTypeId: string;
  sourceIssueId: string;
  targetIssueIds: ReadonlyArray<string>;
};
export type uiLinkIdeaMutation$variables = {
  cloudId: string;
  input: JiraBulkCreateIssueLinksInput;
};
export type uiLinkIdeaMutation$data = {
  readonly jira: {
    readonly createIssueLinks: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly issueLinkEdges: ReadonlyArray<{
        readonly node: {
          readonly direction: JiraIssueLinkDirection | null | undefined;
          readonly id: string | null | undefined;
          readonly issue: {
            readonly fieldsById: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly __typename: "JiraIssueTypeField";
                  readonly fieldId: string;
                  readonly issueType: {
                    readonly avatar: {
                      readonly medium: string | null | undefined;
                    } | null | undefined;
                    readonly name: string;
                  } | null | undefined;
                  readonly name: string;
                } | {
                  readonly __typename: "JiraProjectField";
                  readonly fieldId: string;
                  readonly project: {
                    readonly projectType: JiraProjectType | null | undefined;
                  } | null | undefined;
                } | {
                  readonly __typename: "JiraSingleLineTextField";
                  readonly fieldId: string;
                  readonly name: string;
                  readonly text: string | null | undefined;
                } | {
                  // This will never be '%other', but we need some
                  // value in case none of the concrete values match.
                  readonly __typename: "%other";
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
            readonly id: string;
            readonly key: string;
          } | null | undefined;
          readonly issueLinkId: string | null | undefined;
          readonly type: {
            readonly linkTypeId: string | null | undefined;
          } | null | undefined;
        } | null | undefined;
      }> | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type uiLinkIdeaMutation = {
  response: uiLinkIdeaMutation$data;
  variables: uiLinkIdeaMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "success"
},
v3 = {
  "kind": "ScalarField",
  "name": "id"
},
v4 = {
  "kind": "ScalarField",
  "name": "issueLinkId"
},
v5 = {
  "kind": "ScalarField",
  "name": "direction"
},
v6 = {
  "kind": "ScalarField",
  "name": "linkTypeId"
},
v7 = {
  "kind": "ScalarField",
  "name": "key"
},
v8 = [
  {
    "kind": "Literal",
    "name": "ids",
    "value": [
      "assignee",
      "issuetype",
      "priority",
      "status",
      "summary",
      "project"
    ]
  }
],
v9 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v10 = {
  "kind": "ScalarField",
  "name": "fieldId"
},
v11 = {
  "kind": "ScalarField",
  "name": "name"
},
v12 = {
  "kind": "InlineFragment",
  "selections": [
    (v10/*: any*/),
    (v11/*: any*/),
    {
      "kind": "ScalarField",
      "name": "text"
    }
  ],
  "type": "JiraSingleLineTextField"
},
v13 = {
  "kind": "ScalarField",
  "name": "projectType"
},
v14 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "medium"
    }
  ]
},
v15 = {
  "concreteType": "MutationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "message"
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "uiLinkIdeaMutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraBulkCreateIssueLinksPayload",
            "kind": "LinkedField",
            "name": "createIssueLinks",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "concreteType": "JiraIssueLinkEdge",
                "kind": "LinkedField",
                "name": "issueLinkEdges",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "JiraIssueLink",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "concreteType": "JiraIssueLinkType",
                        "kind": "LinkedField",
                        "name": "type",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/)
                        ]
                      },
                      {
                        "concreteType": "JiraIssue",
                        "kind": "LinkedField",
                        "name": "issue",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v7/*: any*/),
                          {
                            "args": (v8/*: any*/),
                            "concreteType": "JiraIssueFieldConnection",
                            "kind": "LinkedField",
                            "name": "fieldsById",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraIssueFieldEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v9/*: any*/),
                                      (v12/*: any*/),
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          (v10/*: any*/),
                                          {
                                            "concreteType": "JiraProject",
                                            "kind": "LinkedField",
                                            "name": "project",
                                            "plural": false,
                                            "selections": [
                                              (v13/*: any*/)
                                            ]
                                          }
                                        ],
                                        "type": "JiraProjectField"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          (v10/*: any*/),
                                          (v11/*: any*/),
                                          {
                                            "concreteType": "JiraIssueType",
                                            "kind": "LinkedField",
                                            "name": "issueType",
                                            "plural": false,
                                            "selections": [
                                              (v11/*: any*/),
                                              (v14/*: any*/)
                                            ]
                                          }
                                        ],
                                        "type": "JiraIssueTypeField"
                                      }
                                    ]
                                  }
                                ]
                              }
                            ],
                            "storageKey": "fieldsById(ids:[\"assignee\",\"issuetype\",\"priority\",\"status\",\"summary\",\"project\"])"
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              (v15/*: any*/)
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "uiLinkIdeaMutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraBulkCreateIssueLinksPayload",
            "kind": "LinkedField",
            "name": "createIssueLinks",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "concreteType": "JiraIssueLinkEdge",
                "kind": "LinkedField",
                "name": "issueLinkEdges",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "JiraIssueLink",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "concreteType": "JiraIssueLinkType",
                        "kind": "LinkedField",
                        "name": "type",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v3/*: any*/)
                        ]
                      },
                      {
                        "concreteType": "JiraIssue",
                        "kind": "LinkedField",
                        "name": "issue",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v7/*: any*/),
                          {
                            "args": (v8/*: any*/),
                            "concreteType": "JiraIssueFieldConnection",
                            "kind": "LinkedField",
                            "name": "fieldsById",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraIssueFieldEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v9/*: any*/),
                                      (v12/*: any*/),
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          (v10/*: any*/),
                                          {
                                            "concreteType": "JiraProject",
                                            "kind": "LinkedField",
                                            "name": "project",
                                            "plural": false,
                                            "selections": [
                                              (v13/*: any*/),
                                              (v3/*: any*/)
                                            ]
                                          }
                                        ],
                                        "type": "JiraProjectField"
                                      },
                                      {
                                        "kind": "InlineFragment",
                                        "selections": [
                                          (v10/*: any*/),
                                          (v11/*: any*/),
                                          {
                                            "concreteType": "JiraIssueType",
                                            "kind": "LinkedField",
                                            "name": "issueType",
                                            "plural": false,
                                            "selections": [
                                              (v11/*: any*/),
                                              (v14/*: any*/),
                                              (v3/*: any*/)
                                            ]
                                          }
                                        ],
                                        "type": "JiraIssueTypeField"
                                      },
                                      (v3/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ],
                            "storageKey": "fieldsById(ids:[\"assignee\",\"issuetype\",\"priority\",\"status\",\"summary\",\"project\"])"
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              (v15/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "2343754de6a651625bc76ed351f09c6a656f7d654fbf167e3c170840995cd9ab",
    "metadata": {},
    "name": "uiLinkIdeaMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "9c5ad731894048c8adb494c065d4951d";

export default node;
