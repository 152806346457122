import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	empty: {
		id: 'issue-field-single-select-inline-edit-full.single-select.empty',
		defaultMessage: 'No matches found',
		description: 'Message when no options are found in a select',
	},
	failedFetch: {
		id: 'issue-field-single-select-inline-edit-full.single-select.failed-fetch',
		defaultMessage: 'Search failed.',
		description: 'Message when fetching suggestions fails',
	},
	failedSave: {
		id: 'issue-field-single-select-inline-edit-full.single-select.failed-save',
		defaultMessage: 'The value could not be saved',
		description: 'Message when saving the field value fails',
	},
	reloadIssue: {
		id: 'issue-field-single-select-inline-edit-full.single-select.reload-issue',
		defaultMessage: 'Try reloading the issue.',
		description: 'Message to prompt the user to reload the issue',
	},
	confirmButtonLabel: {
		id: 'issue-field-single-select-inline-edit-full.single-select.confirm-button-label',
		defaultMessage: 'Confirm {fieldName}',
		description:
			'An accessibility label for a confirm button in an input field. The fieldName parameter refers to an optional heading for what the field is controlling. Pressing the button saves the contents of the input field.',
	},
	cancelButtonLabel: {
		id: 'issue-field-single-select-inline-edit-full.single-select.cancel-button-label',
		defaultMessage: 'Cancel {fieldName}',
		description:
			'An accessibility label for a cancel button in an input field. The fieldName parameter refers to an optional heading for what the field is controlling. Pressing the button reverts the contents of the input field to their previous value.',
	},
	editButtonLabel: {
		id: 'issue-field-single-select-inline-edit-full.single-select.edit-button-label',
		defaultMessage: 'Edit {fieldName}',
		description:
			'An accessibility label for an edit button in an input field. The fieldName parameter refers to an optional heading for what the field is controlling. Pressing the button allows the user to edit the contents of the input field.',
	},
});
