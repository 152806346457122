/**
 * @generated SignedSource<<e50e78d01171c85a3aafa1ec2b742370>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutParentField_IssueViewParentField$data = {
  readonly __typename: "JiraParentIssueField";
  readonly fieldId: string;
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"heading_issueViewLayoutParentField_ParentFieldHeading" | "parent_issueFieldParentInlineEditFull_ParentInlineEditView_fragmentRef">;
  readonly " $fragmentType": "ui_issueViewLayoutParentField_IssueViewParentField";
};
export type ui_issueViewLayoutParentField_IssueViewParentField$key = {
  readonly " $data"?: ui_issueViewLayoutParentField_IssueViewParentField$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutParentField_IssueViewParentField">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutParentField_IssueViewParentField",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "heading_issueViewLayoutParentField_ParentFieldHeading"
    },
    {
      "kind": "FragmentSpread",
      "name": "parent_issueFieldParentInlineEditFull_ParentInlineEditView_fragmentRef"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "ScalarField",
      "name": "__typename"
    }
  ],
  "type": "JiraParentIssueField"
};

(node as any).hash = "6eaa4bc9c2ac632d32b78eb5932015ad";

export default node;
