import keyBy from 'lodash/keyBy';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { wrapWithExperience } from '@atlassian/jira-polaris-lib-analytics/src/common/utils/experience/main.tsx';
import { createErrorAnalytics } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import { fetchUsers } from '../../../services/users/index.tsx';
import type { State } from '../types.tsx';

export const loadUsers =
	(accountIds: string[]): Action<State, void, Promise<void>> =>
	async ({ setState, getState }) => {
		if (accountIds.length === 0) {
			return;
		}

		const state = getState();
		if (accountIds.every((accountId) => state.users[accountId])) {
			return;
		}

		try {
			await wrapWithExperience(experience.collections.loadUsers, async () => {
				setState({
					isLoading: true,
				});

				const users = await fetchUsers(accountIds);

				setState({
					users: keyBy(users, 'accountId'),
					isLoading: false,
				});
			});
		} catch (error) {
			if (error instanceof Error) {
				fireErrorAnalytics(
					createErrorAnalytics('polaris.error.collectionUsersLoadingFailed', error),
				);
				setState({
					isLoading: false,
					error,
				});
			}
			throw error;
		}
	};
