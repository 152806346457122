import memoizeOne from 'memoize-one';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import type { SelectValueShape } from '@atlassian/jira-issue-internal-field-select/src/common/select-inline-edit/select-field/types.tsx';
import type { TransformerContext } from '@atlassian/jira-issue-view-common-types/src/connect-field-type.tsx';
import messages from './messages.tsx';

export type StateValue = {
	displayName: string;
	languageCode: string;
};

const toItem = (stateValue: StateValue): SelectValueShape => ({
	content: stateValue.displayName,
	value: stateValue.languageCode,
});

const getUnknownLanguageValue = memoizeOne((intl) => ({
	displayName: intl.formatMessage(messages.languageUnknown),
	languageCode: '',
}));

export const transformFromStateValue = (
	stateValue: StateValue | null,
	transformerContext: TransformerContext,
	intl: IntlShape,
): SelectValueShape => toItem(stateValue || getUnknownLanguageValue(intl));

export const transformToStateValue = (value: SelectValueShape): StateValue => ({
	displayName: value.content,
	languageCode: value.value.toString(),
});

export const transformSuggestions = (suggestionsFromServer: StateValue[], intl: IntlShape) => {
	const suggestionsWithUnknownLanguage: StateValue[] = [
		getUnknownLanguageValue(intl),
		...suggestionsFromServer,
	];
	const singleSelectItems: SelectValueShape[] = suggestionsWithUnknownLanguage.map(toItem);
	return [
		{
			items: singleSelectItems,
		},
	] as const;
};
