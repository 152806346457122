/**
 * @generated SignedSource<<b7735160732824337ac8d363f0c91a06>>
 * @relayHash 396cef6d317a9982d56bae0401d0192f
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID e79778d8ae574a33e0439f2d59888b0f1b3db2a38ec6c0682cf054fcc665db61

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type JiraSingleValueFieldOperations = "SET" | "%future added value";
export type JiraUpdateSingleSelectFieldInput = {
  id: string;
  operation: JiraSingleSelectOperationInput;
};
export type JiraSingleSelectOperationInput = {
  fieldOption?: string | null | undefined;
  id?: string | null | undefined;
  operation: JiraSingleValueFieldOperations;
};
export type useSingleSelectMutation$variables = {
  input: JiraUpdateSingleSelectFieldInput;
};
export type useSingleSelectMutation$data = {
  readonly jira: {
    readonly updateSingleSelectField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly fieldOption: {
          readonly id: string;
          readonly optionId: string;
          readonly value: string | null | undefined;
        } | null | undefined;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type useSingleSelectMutation$rawResponse = {
  readonly jira: {
    readonly updateSingleSelectField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly fieldOption: {
          readonly id: string;
          readonly optionId: string;
          readonly value: string | null | undefined;
        } | null | undefined;
        readonly id: string;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type useSingleSelectMutation = {
  rawResponse: useSingleSelectMutation$rawResponse;
  response: useSingleSelectMutation$data;
  variables: useSingleSelectMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "success"
},
v3 = {
  "concreteType": "MutationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "message"
    }
  ]
},
v4 = {
  "kind": "ScalarField",
  "name": "id"
},
v5 = {
  "concreteType": "JiraOption",
  "kind": "LinkedField",
  "name": "fieldOption",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    {
      "kind": "ScalarField",
      "name": "optionId"
    },
    {
      "kind": "ScalarField",
      "name": "value"
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "useSingleSelectMutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraSingleSelectFieldPayload",
            "kind": "LinkedField",
            "name": "updateSingleSelectField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraSingleSelectField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useSingleSelectMutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraSingleSelectFieldPayload",
            "kind": "LinkedField",
            "name": "updateSingleSelectField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraSingleSelectField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v4/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "e79778d8ae574a33e0439f2d59888b0f1b3db2a38ec6c0682cf054fcc665db61",
    "metadata": {},
    "name": "useSingleSelectMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "b8d6036932768e6445d7615f80fc6acb";

export default node;
