/**
 * @generated SignedSource<<462b911cfb86aab06bbd59e47653f6e7>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type AccountStatus = "active" | "closed" | "inactive" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type peopleField_issueFieldPeopleFormField_PeopleFormField$data = {
  readonly id: string;
  readonly selectedUsersConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly accountId: string;
        readonly accountStatus: AccountStatus;
        readonly email?: string | null | undefined;
        readonly id: string;
        readonly name: string;
        readonly picture: AGG$URL;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueFormFieldBase_JiraIssueFieldFormComponent">;
  readonly " $fragmentType": "peopleField_issueFieldPeopleFormField_PeopleFormField";
};
export type peopleField_issueFieldPeopleFormField_PeopleFormField$key = {
  readonly " $data"?: peopleField_issueFieldPeopleFormField_PeopleFormField$data;
  readonly " $fragmentSpreads": FragmentRefs<"peopleField_issueFieldPeopleFormField_PeopleFormField">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "peopleField_issueFieldPeopleFormField_PeopleFormField",
  "selections": [
    {
      "concreteType": "JiraUserConnection",
      "kind": "LinkedField",
      "name": "selectedUsersConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraUserEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "accountId"
                },
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "name": "accountStatus"
                },
                {
                  "kind": "ScalarField",
                  "name": "name"
                },
                {
                  "kind": "ScalarField",
                  "name": "picture"
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "email"
                    }
                  ],
                  "type": "AtlassianAccountUser"
                }
              ]
            }
          ]
        }
      ]
    },
    (v0/*: any*/),
    {
      "kind": "FragmentSpread",
      "name": "ui_issueFormFieldBase_JiraIssueFieldFormComponent"
    }
  ],
  "type": "JiraPeopleField"
};
})();

(node as any).hash = "120d5aa2f575044c927aa8026551cd81";

export default node;
