import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { CommentTextAreaPlaceholder } from './comment-text-area-placeholder.tsx';

type Props = {
	placeholder?: string;
	onFocus?: () => void;
	onClick?: () => void;
};

export const CommentsPlaceholder = ({ placeholder, onFocus, onClick }: Props) => (
	<Box xcss={[containerStyles, hoveredStyle]}>
		<CommentTextAreaPlaceholder placeholder={placeholder} onFocus={onFocus} onClick={onClick} />
	</Box>
);

const containerStyles = xcss({
	position: 'relative',
});

/**
 * Add css-var to adjust styling between parents and children.
 * hovering the div should make the background color of the textarea and button right and left the same
 * NOTE: if css-var name gets changed, please search for the usage of it within the children.
 *
 * Arguably, another solution is to use react state and event listener (mouseenter and mouseleave)
 * but we will need to wait extra render cycle for css to change.
 */
const hoveredStyle = xcss({
	'--jira-issue-view-add-comment-placeholder-background-color': token('color.background.input'),
	':hover': {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-expect-error
		'--jira-issue-view-add-comment-placeholder-background-color': token(
			'color.background.input.hovered',
		),
	},
});
