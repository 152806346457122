import React, { useCallback, useMemo } from 'react';
import { Text } from '@atlaskit/primitives';
import { expVal } from '@atlassian/jira-feature-experiments';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import { Emphasise } from '@atlassian/jira-issue-view-activity-common/src/styles/index.tsx';
import Change from '../../../../common/ui/change/index.tsx';
import GroupedChange from '../../../../common/ui/grouped-change/index.tsx';
import HistoryItem from '../../../../common/ui/history-item/index.tsx';
import {
	type AttachmentChangedHistoryItem,
	ATTACHMENT_UPLOADED,
	ATTACHMENT_DELETED,
} from '../../../../types.tsx';
import messages from './messages.tsx';

type Props = {
	isGrouped?: Boolean;
	historyItem: AttachmentChangedHistoryItem;
};

const AttachmentHistoryItem = ({ historyItem, isGrouped = false }: Props) => {
	const { formatMessage } = useIntl();
	const { actor, timestamp, filename, action } = historyItem;
	const attachedMessage = expVal('issue_view_activity_timeline', 'isActivityTimelineEnabled', false)
		? messages.attached
		: messages.uploaded;
	const deletedMessage = expVal('issue_view_activity_timeline', 'isActivityTimelineEnabled', false)
		? messages.deleted
		: messages.deletedDeprecated;

	const attachmentMessage = useMemo(
		() => (action === ATTACHMENT_UPLOADED ? attachedMessage : deletedMessage),
		[action, attachedMessage, deletedMessage],
	);

	const ChangeComponent = useCallback(() => {
		if (expVal('issue_view_activity_timeline', 'isActivityTimelineEnabled', false)) {
			return undefined;
		}

		return (
			<Change
				from={action === ATTACHMENT_DELETED ? filename : null}
				to={action === ATTACHMENT_UPLOADED ? filename : null}
			/>
		);
	}, [filename, action]);

	return (
		<FormattedMessage
			{...attachmentMessage}
			values={{
				/**
				 * remove the attachment message from the translation key when
				 * cleaning up this gate: issue_view_activity_timeline
				 * */
				attachment: (
					<Emphasise key="attachment">
						{formatMessage(
							expVal('issue_view_activity_timeline', 'isActivityTimelineEnabled', false)
								? messages.attachmentLowercase
								: messages.attachment,
						)}
					</Emphasise>
				),
				filename: (
					<Text weight="medium" key="filename">
						{filename}
					</Text>
				),
			}}
		>
			{(...actionText) =>
				isGrouped && expVal('issue_view_activity_timeline', 'isActivityTimelineEnabled', false) ? (
					<GroupedChange to={actionText} />
				) : (
					<HistoryItem
						testId="issue-history.ui.history-items.history-item.attachment-history-item.history-item"
						actor={actor}
						action={actionText}
						timestamp={timestamp}
						change={<ChangeComponent />}
					/>
				)
			}
		</FormattedMessage>
	);
};

export default AttachmentHistoryItem;
