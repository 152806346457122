import React, { useMemo, type MouseEventHandler } from 'react';
import { styled } from '@compiled/react';
import { Anchor, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { borderRadius } from '@atlassian/jira-common-styles/src/main.tsx';
import { singleLineClamp } from '@atlassian/jira-common-styles/src/mixins.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import {
	asColor,
	getLozengeThemeByColor,
} from '@atlassian/jira-issue-epic-color/src/common/utils.tsx';
import messages from './messages.tsx';

type Props = {
	issueKey: string;
	label: string;
	colorKey?: string | null;
	disableClickAction?: boolean;
	onClick?: MouseEventHandler;
	/**
	 * When `true` the link text will cut off and show ellipsis if it exceeds the available space.
	 * When `false` the link text will break onto the next line.
	 */
	isTruncated: boolean;
};

export const Summary = ({
	disableClickAction,
	onClick,
	label,
	colorKey,
	issueKey,
	isTruncated,
}: Props) => {
	const { formatMessage } = useIntl();

	const lozengeTheme = useMemo(
		() => (colorKey ? getLozengeThemeByColor(asColor(colorKey)) : undefined),
		[colorKey],
	);

	if (disableClickAction) {
		return (
			<WrapperNoClick
				// eslint-disable-next-line jira/react/no-style-attribute, @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				style={lozengeTheme}
			>
				{label}
			</WrapperNoClick>
		);
	}

	if (onClick != null) {
		return (
			<ButtonText
				onClick={onClick}
				// eslint-disable-next-line jira/react/no-style-attribute, @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				style={lozengeTheme}
			>
				{label}
			</ButtonText>
		);
	}

	return (
		<Anchor
			testId={
				fg('relay-migration-issue-header-and-parent')
					? 'issue-field-parent.ui.view-link'
					: 'issue-field-parent-readview-full.ui.parent.summary.view-link'
			}
			href={`/browse/${issueKey}`}
			aria-label={formatMessage(messages.parentTitle, { label })}
			// eslint-disable-next-line jira/react/no-style-attribute, @atlaskit/ui-styling-standard/enforce-style-prop
			style={lozengeTheme}
			xcss={[
				linkTextStylesBase,
				isTruncated ? linkTextStylesTruncated : linkTextStylesNonTruncated,
			]}
		>
			{label}
		</Anchor>
	);
};

const linkTextStylesBase = xcss({
	borderRadius: token('border.radius'),
	lineHeight: '16px',
	paddingInline: 'space.050',
	paddingBlock: 'space.025',
	textDecoration: 'none',
	':hover': {
		textDecoration: 'underline',
	},
});

const linkTextStylesNonTruncated = xcss({
	overflowWrap: 'anywhere',
});

const linkTextStylesTruncated = xcss({
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const ButtonText = styled.button(singleLineClamp, {
	// Disable buttons styles
	border: 'none',
	background: 'none',
	color: 'inherit',
	font: token('font.body'),
	lineHeight: '16px',
	outline: 'inherit',
	// Add custom styles
	cursor: 'pointer',
	height: '20px',
	maxWidth: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${borderRadius}px`,
	padding: `${token('space.025', '2px')} ${token('space.050', '4px')}`,
	'&:hover': {
		textDecoration: 'underline',
	},
	'&:focus': {
		outline: 'none',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const WrapperNoClick = styled.span(singleLineClamp, {
	display: 'block',
	font: token('font.body'),
	lineHeight: '16px',
	height: '16px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${borderRadius}px`,
	padding: `${token('space.025', '2px')} ${token('space.050', '4px')}`,
	maxWidth: '100%',
});
