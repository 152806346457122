import {
  JiraIssueFieldOptionAriResourceOwner,
  JiraIssueFieldOptionAriResourceType
} from "./chunk-TKZ6M5JE.mjs";
import {
  RegisteredAri
} from "./chunk-YG2E6ZVD.mjs";
import {
  AriParser
} from "./chunk-Q7FABYST.mjs";

// src/jira/issue-field-option/manifest.ts
var jiraIssueFieldOptionAriStaticOpts = {
  qualifier: "ari",
  platformQualifier: "cloud",
  cloudId: new RegExp("^[a-zA-Z0-9_\\-.]{1,255}$"),
  // eslint-disable-line no-useless-escape
  resourceOwner: JiraIssueFieldOptionAriResourceOwner,
  resourceType: JiraIssueFieldOptionAriResourceType,
  resourceIdSlug: "activation/{activationId}/{fieldId}/{optionId}",
  resourceIdSegmentFormats: {
    activationId: /[a-zA-Z0-9\-]+/,
    // eslint-disable-line no-useless-escape
    fieldId: /[!a-zA-Z0-9\-_.~@:{}=]+(\/[!a-zA-Z0-9\-_.~@:{}=]+)*/,
    // eslint-disable-line no-useless-escape
    optionId: /[!a-zA-Z0-9\-_.~@:{}=]+(\/[!a-zA-Z0-9\-_.~@:{}=]+)*/
    // eslint-disable-line no-useless-escape
  }
};

// src/jira/issue-field-option/index.ts
var JiraIssueFieldOptionAri = class _JiraIssueFieldOptionAri extends RegisteredAri {
  constructor(opts) {
    super(opts);
    this._siteId = opts.cloudId || "", this._activationId = opts.resourceIdSegmentValues.activationId, this._fieldId = opts.resourceIdSegmentValues.fieldId, this._optionId = opts.resourceIdSegmentValues.optionId;
  }
  get siteId() {
    return this._siteId;
  }
  get activationId() {
    return this._activationId;
  }
  get fieldId() {
    return this._fieldId;
  }
  get optionId() {
    return this._optionId;
  }
  static create(opts) {
    let derivedOpts = {
      qualifier: jiraIssueFieldOptionAriStaticOpts.qualifier,
      platformQualifier: jiraIssueFieldOptionAriStaticOpts.platformQualifier,
      cloudId: opts.siteId,
      resourceOwner: jiraIssueFieldOptionAriStaticOpts.resourceOwner,
      resourceType: jiraIssueFieldOptionAriStaticOpts.resourceType,
      resourceId: `activation/${opts.activationId}/${opts.fieldId}/${opts.optionId}`,
      resourceIdSegmentValues: {
        activationId: opts.activationId,
        fieldId: opts.fieldId,
        optionId: opts.optionId
      }
    }, ariOpts = AriParser.fromOpts(derivedOpts, jiraIssueFieldOptionAriStaticOpts);
    return new _JiraIssueFieldOptionAri(ariOpts);
  }
  static parse(maybeAri) {
    let opts = AriParser.fromString(maybeAri, jiraIssueFieldOptionAriStaticOpts);
    return new _JiraIssueFieldOptionAri(opts);
  }
  getVariables() {
    return {
      siteId: this.siteId,
      activationId: this.activationId,
      fieldId: this.fieldId,
      optionId: this.optionId
    };
  }
};

export {
  JiraIssueFieldOptionAri
};
