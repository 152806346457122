import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { SortingOption } from '../model/types.tsx';
import type { StoreApi } from './types.tsx';

export const setSortableIssueIds =
	(issueIds: IssueId[]) =>
	({ setState }: StoreApi) => {
		setState({ sortableIssueIds: issueIds });
	};

export const setSortBy =
	(sortBy: SortingOption) =>
	({ setState }: StoreApi) => {
		setState({ sortBy });
	};
