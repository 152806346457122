import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	errorMessage: {
		id: 'smart-related-issues-panel.issue-line-card-group.error-feedback.error-message',
		defaultMessage: "We couldn't load any similar requests.",
		description:
			'Error message shown when the related issues panel failed to fetch similar issues from the backend',
	},
	tryAgainLink: {
		id: 'smart-related-issues-panel.issue-line-card-group.error-feedback.try-again-link',
		defaultMessage: 'Try again?',
		description: 'Link to attempt to fetch similar issues from the backend again',
	},
});
