import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	anonymousUser: {
		id: 'issue-history.common.history-item.anonymous-user',
		defaultMessage: 'Anonymous user',
		description:
			'Text to indicate that an action was performed by an Anonymous User. Displayed before the action performed e.g. "Anonymous User {didSomething}".',
	},
	lozenge: {
		id: 'issue-history.common.history-item.lozenge',
		defaultMessage: 'History',
		description:
			'Text inside a lozenge to indicate to the user that the item they are viewing is a history item from the history feed.',
	},
});
