import type { Area } from '@atlassian/jira-issue-view-common-types/src/connect-field-type.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { issueKeySelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import {
	fieldPersistedValueSelector,
	fieldNameSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector.tsx';
import { isRequestFeedbackVisibleSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-visibility-selector.tsx';
import RequestFeedback from './view.tsx';

export const getFeedbackRating = (state: State, fieldId: string) => {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const feedbackFieldValue = fieldPersistedValueSelector(fieldId as string)(state);
	return feedbackFieldValue && feedbackFieldValue.rating;
};

export default flowWithSafeComponent(
	connect(
		(state: State, props: { fieldId: string; area?: Area }) => ({
			fieldId: props.fieldId,
			rating: getFeedbackRating(state, props.fieldId),
			fieldName: fieldNameSelector(props.fieldId)(state),
			isVisible: isRequestFeedbackVisibleSelector(props.fieldId)(state),
			showPinButton: getShowPinButton(props.area),
			issueKey: issueKeySelector(state),
		}),
		{},
	),
)(RequestFeedback);
