/**
 * @generated SignedSource<<35d10ffcb09b5b7f845a34d3dc8cc1d5>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { UpdatableFragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type useWatchesMutation_Mutation_Updatable$data = {
  count: AGG$Long | null | undefined;
  isWatching: boolean | null | undefined;
  readonly " $fragmentType": "useWatchesMutation_Mutation_Updatable";
};
export type useWatchesMutation_Mutation_Updatable$key = {
  readonly " $data"?: useWatchesMutation_Mutation_Updatable$data;
  readonly $updatableFragmentSpreads: FragmentRefs<"useWatchesMutation_Mutation_Updatable">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "useWatchesMutation_Mutation_Updatable",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "isWatching"
    },
    {
      "kind": "ScalarField",
      "name": "count"
    }
  ],
  "type": "JiraWatch"
};

(node as any).hash = "480974cbf7971b42048328167a3bc73c";

export default node;
