import React, { useCallback, type ReactNode } from 'react';
import { styled } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import UFOLoadHold from '@atlaskit/react-ufo/load-hold';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import AIContainer from '@atlassian/jira-atlassian-intelligence/src/common/ui/ai-container/index.tsx';
import { AiFooter } from '@atlassian/jira-atlassian-intelligence/src/common/ui/ai-footer/index.tsx';
import { borderRadius } from '@atlassian/jira-common-styles/src/main.tsx';
import { ContextGroup } from '@atlassian/jira-issue-view-layout-templates-views-context-group/src/index.tsx';
import type { RouteResourceError } from '@atlassian/react-resource-router/src/common/types.tsx';
import { ErrorMessage } from './error/index.tsx';
import { GenerationSkeleton } from './generation-skeleton/index.tsx';

export const aiContextContainerTestId =
	'servicedesk-ai-context-common.ui.ai-context-container.ai-container';

export const AIContextContainer = ({
	title,
	isLoading,
	error,
	attribution,
	children,
	groupId,
	initialOpened = true,
	onPanelToggle,
}: AIContextContainerProps) => {
	const onChange = useCallback(
		(isOpen: boolean) => {
			onPanelToggle?.(isOpen);
		},
		[onPanelToggle],
	);

	return (
		<AIContainer
			isLoading={isLoading}
			shouldFitContainer
			spacing="none"
			testId={aiContextContainerTestId}
			hasNewBorderExperience
			components={{
				ContainerComponent: AIOuterContainer,
				ContentComponent: AIContent,
			}}
		>
			<ContextGroupContainer>
				<ContextGroup
					title={title}
					groupId={groupId}
					initialOpened={initialOpened}
					onChange={onChange}
				>
					{isLoading ? (
						<UFOLoadHold name="ai-context-panel-loading">
							<GenerationSkeleton />
						</UFOLoadHold>
					) : (
						<>
							{error ? <ErrorMessage error={error} /> : children}
							<Box xcss={aiFooterCss}>
								<AiFooter
									attribution={attribution}
									testId="servicedesk-ai-context-common.ui.ai-context-container.footer"
									isBeta={false}
									showAttributionLink
								/>
							</Box>
						</>
					)}
				</ContextGroup>
			</ContextGroupContainer>
		</AIContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContextGroupContainer = styled.div({
	marginBlockEnd: token('space.negative.100'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	details: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors
		'div:first-of-type': {
			border: 'none',
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors
	'details:not([open]) > summary': {
		borderBottomColor: token('color.background.neutral.subtle'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'details > summary': {
		borderTop: 'none',
		borderRight: 'none',
		borderLeft: 'none',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AIOuterContainer = styled.div<{ shouldFitContainer: boolean }>({
	display: 'flex',
	position: 'relative',
	padding: token('border.width', '1px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: (props) => (props.shouldFitContainer ? 'auto' : 'fit-content'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'& > svg': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		borderRadius: `${borderRadius + 2}px`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'g > path': {
			fill: token('color.background.accent.gray.subtler'),
		},
	},
	marginBlockEnd: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AIContent = styled.div<{ spacing: 'default' | 'none' }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('elevation.surface', colors.N0),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${borderRadius + 1}px`,
	flexGrow: 1,
	width: '100%',
	position: 'relative',
});

const aiFooterCss = xcss({
	marginInline: 'space.negative.150',
	marginBlockEnd: 'space.negative.100',
	borderTop: `1px solid ${token('color.border')}`,
});

export type AIContextContainerProps = {
	title: string;
	children: ReactNode;
	attribution: string;
	isLoading: boolean;
	error: RouteResourceError | null;
	groupId: string;
	initialOpened?: boolean;
	onPanelToggle?: (isOpen: boolean) => void;
};
