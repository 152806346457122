import { useMemo } from 'react';
import type { FlagConfiguration } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { useIntl } from '@atlassian/jira-intl';
import type { ErrorCode } from '@atlassian/jira-issue-adjustments/src/controllers.tsx';
import { getErrorFlagProperties } from './utils.tsx';

type Props = {
	errorCode: ErrorCode;
	fieldName?: string;
} | null;

export const useErrorFlagConfig = (errorProperties: Props): FlagConfiguration | null => {
	const { formatMessage } = useIntl();
	const errorFlagConfig = useMemo(() => {
		if (errorProperties === null) {
			return null;
		}
		const { errorCode, fieldName } = errorProperties;
		const errorFlagProperties = getErrorFlagProperties(errorCode);
		return errorFlagProperties
			? ({
					type: 'error',
					title: formatMessage(errorFlagProperties.title),
					description: formatMessage(errorFlagProperties.message, {
						fieldName: fieldName ?? 'Unknown field',
					}),
					isAutoDismiss: true,
				} as const)
			: null;
	}, [errorProperties, formatMessage]);
	return errorFlagConfig;
};
