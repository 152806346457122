import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import {
	COUNT_ROLLUP,
	EMPTY_ROLLUP,
	FILLED_ROLLUP,
} from '@atlassian/jira-polaris-domain-field/src/rollup/constants.tsx';
import type { IssuesRemote } from '@atlassian/jira-polaris-remote-issue/src/controllers/types.tsx';
import { createGetCommentsCount } from '../../../selectors/properties/comments/index.tsx';
import { numberComparator } from '../../comparators/index.tsx';
import { defaultMapping } from '../default/index.tsx';
import type { FieldMapping } from '../types.tsx';

export const commentsCountMapping = (
	issuesRemote: IssuesRemote,
	field: Field,
): FieldMapping<number> => {
	const valueAccessor: FieldMapping<number>['valueAccessor'] = (state, props, issueId) =>
		createGetCommentsCount(issueId)(state);

	const valueAccessorToExport: FieldMapping<string>['valueAccessorToExport'] = (
		state,
		props,
		issueId,
	) => `${state.properties.issueMetadata[issueId]?.comments.count || 0}`;

	return {
		...defaultMapping,
		field,
		comparator: numberComparator,
		valueAccessor,
		valueAccessorToExport,
		getRollupOperations: () => [COUNT_ROLLUP, EMPTY_ROLLUP, FILLED_ROLLUP],
	};
};
