import { getClient } from '@atlassian/jira-issue-fetch-services/src/common/utils/client.tsx';
import type { CommentVisibility } from '@atlassian/jira-issue-gira-transformer-types/src/common/types/comments.tsx';
import type {
	CommentVisibilitiesGraphQlResponse,
	JiraCommentVisibility,
} from '@atlassian/jira-issue-view-common-types/src/gira.tsx';
import { transformJiraCommentVisibility } from '@atlassian/jira-issue-view-services/src/issue/comment-transformer.tsx';
import { commentVisibilitiesQuery } from '@atlassian/jira-issue-view-services/src/issue/gira/comments/graphql.tsx';
import type { BaseUrl, IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { graphqlResponseError, graphqlNullDataError } from './errors.tsx';

const transformData = (
	jiraCommentVisibilities: { node: JiraCommentVisibility }[],
): CommentVisibility[] =>
	jiraCommentVisibilities.map((edge) => transformJiraCommentVisibility(edge.node));

export const fetchCommentVisibilities = async (
	baseUrl: BaseUrl,
	issueKey: IssueKey,
): Promise<CommentVisibility[]> => {
	const { errors, data }: CommentVisibilitiesGraphQlResponse = await getClient(baseUrl).query({
		query: commentVisibilitiesQuery,
		variables: { issueKey },
	});

	if (errors) {
		throw new Error(graphqlResponseError);
	}

	if (data?.viewIssue?.jiraCommentVisibilities) {
		const transformedData = transformData(data.viewIssue.jiraCommentVisibilities.edges);
		return transformedData;
	}

	throw new Error(graphqlNullDataError);
};
