import React from 'react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

const pendingColor = token('color.icon.information', colors.B300);
const backgroundColor = token('color.icon.inverse', colors.N0);

const PendingIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="100%"
		height="100%"
		fill="none"
		viewBox="0 0 16 16"
	>
		<circle r="7" cx="8" cy="8" fill={backgroundColor} />

		<path
			fill={pendingColor}
			d="M13.657 2.343A8 8 0 102.343 13.657 8 8 0 0013.657 2.343zm-2.308 9.006a1 1 0 01-1.389.024L7.294 8.707a.988.988 0 01-.217-.325l.002.007-.005-.013.003.006A.993.993 0 017 8V4a1 1 0 012 0v3.587l2.373 2.373a1 1 0 01-.024 1.389z"
		/>
	</svg>
);

PendingIcon.displayName = 'PendingIcon';
export default PendingIcon;
