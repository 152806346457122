import type { FieldOptions } from '@atlassian/jira-issue-view-common-types/src/connect-field-type.tsx';

// FIELD_INIT_OPTIONS
export const FIELD_INIT_OPTIONS = 'FIELD_INIT_OPTIONS' as const;

// PUBLIC - These are action creators expected to be wired up by a consuming field
// (eg priority-view).
export const fieldInitOptions = <T,>(
	fieldId: string,
	fieldOptions: FieldOptions<T>,
): {
	payload: {
		fieldId: string;
		fieldOptions: FieldOptions<T>;
	};
	type: 'FIELD_INIT_OPTIONS';
} => ({
	type: FIELD_INIT_OPTIONS,
	payload: {
		fieldId,
		fieldOptions,
	},
});

export type FieldInitOptionsAction = ReturnType<typeof fieldInitOptions>;

// FIELD_EDIT_BEGIN
export const FIELD_EDIT_BEGIN = 'FIELD_EDIT_BEGIN' as const;

export const fieldEditBegin = <T,>(
	fieldId: string,
	fieldOptions: FieldOptions<T>,
	fieldEditSessionId: string | null | undefined,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	analyticsEvent: any,
): {
	payload: {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		analyticsEvent: any;
		fieldEditSessionId: undefined | string;
		fieldId: string;
		fieldOptions: FieldOptions<T>;
	};
	type: 'FIELD_EDIT_BEGIN';
} => ({
	type: FIELD_EDIT_BEGIN,
	payload: {
		analyticsEvent,
		fieldId,
		fieldOptions,
		// @ts-expect-error - TS2322 - Type 'string | null | undefined' is not assignable to type 'string | undefined'.
		fieldEditSessionId,
	},
});

export type FieldEditBeginAction = ReturnType<typeof fieldEditBegin>;

// FIELD_EDIT_UPDATE
export const FIELD_EDIT_UPDATE = 'FIELD_EDIT_UPDATE' as const;

export const fieldEditUpdate = <T,>(
	fieldId: string,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	value: any,
	fieldOptions: FieldOptions<T>,
): {
	payload: {
		fieldId: string;
		fieldOptions: FieldOptions<T>;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		value: any;
	};
	type: 'FIELD_EDIT_UPDATE';
} => ({
	type: FIELD_EDIT_UPDATE,
	payload: {
		fieldId,
		value,
		fieldOptions,
	},
});

export type FieldEditUpdateAction = ReturnType<typeof fieldEditUpdate>;

// FIELD_EDIT_CONFIRM
export const FIELD_EDIT_CONFIRM = 'FIELD_EDIT_CONFIRM' as const;

export const fieldEditConfirm = <T,>(
	fieldId: string,
	fieldOptions: FieldOptions<T>,
	saveFieldData: T | null | undefined,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	analyticsEvent: any,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	analyticsAttributeIdRetriever?: (obj?: any) => string,
): {
	payload: {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		analyticsAttributeIdRetriever: undefined | ((obj?: any) => string);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		analyticsEvent: any;
		fieldId: string;
		fieldOptions: FieldOptions<T>;
		saveFieldData: undefined | T;
	};
	type: 'FIELD_EDIT_CONFIRM';
} => ({
	type: FIELD_EDIT_CONFIRM,
	payload: {
		analyticsEvent,
		fieldId,
		fieldOptions,
		// @ts-expect-error - TS2322 - Type 'T | null | undefined' is not assignable to type 'T | undefined'.
		saveFieldData,
		analyticsAttributeIdRetriever,
	},
});

export type FieldEditConfirmAction = ReturnType<typeof fieldEditConfirm>;

// FIELD_EDIT_CANCEL
export const FIELD_EDIT_CANCEL = 'FIELD_EDIT_CANCEL' as const;

export const fieldEditCancel = <T,>(
	fieldId: string,
	fieldOptions: FieldOptions<T>,
): {
	payload: {
		fieldId: string;
		fieldOptions: FieldOptions<T>;
	};
	type: 'FIELD_EDIT_CANCEL';
} => ({
	type: FIELD_EDIT_CANCEL,
	payload: {
		fieldId,
		fieldOptions,
	},
});

export type FieldEditCancelAction = ReturnType<typeof fieldEditCancel>;

// FIELD_PASTE
export const FIELD_PASTE = 'FIELD_PASTE' as const;

export const fieldPaste = <T,>(
	fieldId: string,
	fieldOptions: FieldOptions<T>,
	pastedContent: string,
): {
	payload: {
		fieldId: string;
		fieldOptions: FieldOptions<T>;
		pastedContent: string;
	};
	type: 'FIELD_PASTE';
} => ({
	type: FIELD_PASTE,
	payload: {
		fieldId,
		fieldOptions,
		pastedContent,
	},
});

export type FieldPasteAction = ReturnType<typeof fieldPaste>;

// FIELD_ADD_ALLOWED_VALUES
export const FIELD_ADD_ALLOWED_VALUES = 'FIELD_ADD_ALLOWED_VALUES' as const;

export const fieldAddAllowedValues = <T,>(
	fieldId: string,
	allowedValues: T[],
): {
	payload: {
		allowedValues: T[];
		fieldId: string;
	};
	type: 'FIELD_ADD_ALLOWED_VALUES';
} => ({
	type: FIELD_ADD_ALLOWED_VALUES,
	payload: {
		fieldId,
		allowedValues,
	},
});

export type FieldAddAllowedValuesAction = ReturnType<typeof fieldAddAllowedValues>;

export type FieldAction =
	| FieldInitOptionsAction
	| FieldEditBeginAction
	| FieldEditUpdateAction
	| FieldEditConfirmAction
	| FieldEditCancelAction
	| FieldPasteAction
	| FieldAddAllowedValuesAction;
