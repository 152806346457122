/**
 * @generated SignedSource<<5b5fcd39ff155b4b7f81d42a73651366>>
 * @relayHash 92b74832e1427424c007d113f6dffd74
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 397fdbc6c0cc480189efdc0912beed08f87eb4ea62f247a0b2b871b55ab34b30

import type { ConcreteRequest, Query } from 'relay-runtime';
export type detailsHasDevelopmentDataInProjectQuery$variables = {
  projectAri: string;
  updatedFrom?: AGG$DateTime | null | undefined;
};
export type detailsHasDevelopmentDataInProjectQuery$data = {
  readonly devOps: {
    readonly ariGraph: {
      readonly branchRelationships: {
        readonly nodes: ReadonlyArray<{
          readonly __typename: "AriGraphRelationship";
        } | null | undefined> | null | undefined;
      } | null | undefined;
      readonly buildRelationships: {
        readonly nodes: ReadonlyArray<{
          readonly __typename: "AriGraphRelationship";
        } | null | undefined> | null | undefined;
      } | null | undefined;
      readonly prRelationships: {
        readonly nodes: ReadonlyArray<{
          readonly __typename: "AriGraphRelationship";
        } | null | undefined> | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type detailsHasDevelopmentDataInProjectQuery = {
  response: detailsHasDevelopmentDataInProjectQuery$data;
  variables: detailsHasDevelopmentDataInProjectQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectAri"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "updatedFrom"
  }
],
v1 = {
  "kind": "Variable",
  "name": "from",
  "variableName": "projectAri"
},
v2 = {
  "kind": "Variable",
  "name": "updatedFrom",
  "variableName": "updatedFrom"
},
v3 = {
  "kind": "Literal",
  "name": "first",
  "value": 1
},
v4 = [
  {
    "concreteType": "AriGraphRelationship",
    "kind": "LinkedField",
    "name": "nodes",
    "plural": true,
    "selections": [
      {
        "kind": "ScalarField",
        "name": "__typename"
      }
    ]
  }
],
v5 = [
  {
    "concreteType": "DevOps",
    "kind": "LinkedField",
    "name": "devOps",
    "plural": false,
    "selections": [
      {
        "concreteType": "AriGraph",
        "kind": "LinkedField",
        "name": "ariGraph",
        "plural": false,
        "selections": [
          {
            "alias": "branchRelationships",
            "args": [
              {
                "fields": [
                  (v1/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "type",
                    "value": "project-associated-branch"
                  },
                  (v2/*: any*/)
                ],
                "kind": "ObjectValue",
                "name": "filter"
              },
              (v3/*: any*/)
            ],
            "concreteType": "AriGraphRelationshipConnection",
            "kind": "LinkedField",
            "name": "relationships",
            "plural": false,
            "selections": (v4/*: any*/)
          },
          {
            "alias": "buildRelationships",
            "args": [
              {
                "fields": [
                  (v1/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "type",
                    "value": "project-associated-build"
                  },
                  (v2/*: any*/)
                ],
                "kind": "ObjectValue",
                "name": "filter"
              },
              (v3/*: any*/)
            ],
            "concreteType": "AriGraphRelationshipConnection",
            "kind": "LinkedField",
            "name": "relationships",
            "plural": false,
            "selections": (v4/*: any*/)
          },
          {
            "alias": "prRelationships",
            "args": [
              {
                "fields": [
                  (v1/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "type",
                    "value": "project-associated-pr"
                  },
                  (v2/*: any*/)
                ],
                "kind": "ObjectValue",
                "name": "filter"
              },
              (v3/*: any*/)
            ],
            "concreteType": "AriGraphRelationshipConnection",
            "kind": "LinkedField",
            "name": "relationships",
            "plural": false,
            "selections": (v4/*: any*/)
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "detailsHasDevelopmentDataInProjectQuery",
    "selections": (v5/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "detailsHasDevelopmentDataInProjectQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "id": "397fdbc6c0cc480189efdc0912beed08f87eb4ea62f247a0b2b871b55ab34b30",
    "metadata": {},
    "name": "detailsHasDevelopmentDataInProjectQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "23fe9927a99afda98b0233664e8a0667";

export default node;
