import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { trackFetchErrors } from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { useFlagsService } from '@atlassian/jira-flags';
import {
	fireOperationalAnalytics,
	fireTrackAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { getEscalatorBaseUrl } from '@atlassian/jira-servicedesk-incident-management-common/src/utils.tsx';
import type { CloudId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import commonMessages from '../../../common/messages.tsx';
import type { State } from '../../types.tsx';
import messages from './messages.tsx';

export const linkSelectedAlerts =
	(cloudId: CloudId, issueId: string, analyticsEvent?: UIAnalyticsEvent) =>
	async ({ getState, setState }: StoreActionApi<State>) => {
		const { showFlag, dismissFlag } = useFlagsService();
		const { selectedAlertsToLinkIds } = getState();

		if (selectedAlertsToLinkIds.length === 0) {
			return;
		}

		try {
			await performPostRequest(
				`${getEscalatorBaseUrl(cloudId)}/issue/${issueId}/incident/associated-alerts`,
				{
					body: JSON.stringify(selectedAlertsToLinkIds),
				},
			);

			analyticsEvent && fireOperationalAnalytics(analyticsEvent, 'linkAlerts success');
			analyticsEvent && fireTrackAnalytics(analyticsEvent, 'linkAlerts added');
			setState({
				isLinkModalOpen: false,
			});

			const flagId = showFlag({
				type: 'success',
				isAutoDismiss: true,
				title: messages.successTitle,
				actions: [
					{
						content: messages.successDescription,
						onClick: () => {
							if (flagId) {
								dismissFlag(flagId);
							}

							setState({
								isViewModalOpen: true,
							});
						},
					},
				],
				testId: 'linked-alerts.services.actions.link-selected-alerts.success-flag',
			});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			showFlag({
				type: 'error',
				isAutoDismiss: true,
				title: messages.errorTitle,
				description: commonMessages.errorDescription,
			});

			trackFetchErrors({
				event: analyticsEvent,
				error: e,
				id: 'linkAlerts',
				sendToPrivacyUnsafeSplunk: true,
			});

			throw e;
		}
	};
