import { getIn } from 'icepick';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import maxBy from 'lodash/maxBy';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { toSanitizedUrl } from '@atlassian/jira-development-common/src/model/common-types.tsx';
import {
	type ReleasesData,
	type FeatureFlagName,
	type FeatureFlagRolloutSummary,
	type FeatureFlagDetailsLink,
	type FeatureFlagsPerProvider,
	type CreateFlagLinkTemplate,
	type ConnectFlagLinkTemplate,
	type DeploymentsData,
	type DeploymentSummary,
	type RemoteLinksData,
	toFeatureFlagName,
	toFeatureFlagDetailsLink,
	toFeatureFlagId,
	toDeploymentDisplayName,
	toEnvironmentDisplayName,
	toPipelineDisplayName,
	hasFeatureFlags,
	hasDeployments,
	hasRemoteLinks,
} from '@atlassian/jira-development-common/src/model/releases-data.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { UNKNOWN_PROVIDER_ID } from './constants.tsx';
import { fetchReleasesData } from './index.tsx';
import {
	type ReleasesDataError,
	type Deployment,
	type DataResponse as RestResponse,
	type FeatureFlag as RestFeatureFlag,
	deploymentStates,
	deploymentEnvironmentTypes,
	type ConsolidatedDataResponse,
} from './types.tsx';

const ENABLED = true;
const DISABLED = false;

const ENTITY_PERMISSION_ERROR_STATUS_CODE = 403; // User does not have view_issue permission or dev_tool_permission
const ENTITY_NOT_AVAILABLE_STATUS_CODE = 404; // jswdd entity is not available, e.g tenant is not active.
const EXPECTED_ERROR_STATUS_CODES = [
	ENTITY_PERMISSION_ERROR_STATUS_CODE,
	ENTITY_NOT_AVAILABLE_STATUS_CODE,
];
const JSWDD_ENTITIES_FETCH_ERROR = 'jswdd.entities.fetch-error';

const byEnabledFilter = (enabledStatus: boolean) => (flag: RestFeatureFlag) =>
	(getIn(flag, ['summary', 'status', 'enabled']) || DISABLED) === enabledStatus;

const byUrlExistsFilter = (flag: RestFeatureFlag): boolean => !!getIn(flag, ['summary', 'url']);

const extractFlagName = (flag: RestFeatureFlag): FeatureFlagName =>
	toFeatureFlagName(flag.displayName || flag.key || flag.id || '');

const extractFeatureFlagNames = (
	response: RestResponse,
	enabledStatus: boolean,
): FeatureFlagName[] =>
	(getIn(response, ['data', 'featureFlagInformation', 'featureFlags']) || [])
		.filter(byEnabledFilter(enabledStatus))
		.map(extractFlagName);

const extractRolloutSummary = (response: RestResponse): FeatureFlagRolloutSummary =>
	getIn(response.data.featureFlagInformation.featureFlags[0], ['summary', 'status', 'rollout']) || {
		text: undefined,
		percentage: undefined,
		rules: undefined,
	};

const extractDetailsLink = (
	response: RestResponse,
	enabledStatus: boolean,
): FeatureFlagDetailsLink | undefined => {
	const suitableFlagWithUrl = (
		getIn(response, ['data', 'featureFlagInformation', 'featureFlags']) || []
	)
		.filter(byEnabledFilter(enabledStatus))
		.filter(byUrlExistsFilter)
		// @ts-expect-error - TS7006 - Parameter 'flag1' implicitly has an 'any' type. | TS7006 - Parameter 'flag2' implicitly has an 'any' type.
		.sort((flag1, flag2) => (extractFlagName(flag1) < extractFlagName(flag2) ? -1 : 1))[0];

	if (suitableFlagWithUrl) {
		// getIn return type is generic. Not specifying the type as string causes flow to infer getIn returns SanitizedUrl
		const summaryUrl: string = getIn(suitableFlagWithUrl, ['summary', 'url']) || '';
		return toFeatureFlagDetailsLink(toSanitizedUrl(summaryUrl));
	}
	return undefined;
};

const extractCreateFeatureFlagLinkTemplate = (
	response: RestResponse,
): CreateFlagLinkTemplate | undefined =>
	(getIn(response, ['data', 'featureFlagInformation', 'providers']) || [])
		// @ts-expect-error - TS7006 - Parameter 'provider' implicitly has an 'any' type.
		.filter((provider) => !!provider.createFlagTemplateUrl)
		// @ts-expect-error - TS7006 - Parameter 'provider' implicitly has an 'any' type.
		.map((provider) => ({
			linkTemplate: toSanitizedUrl(provider.createFlagTemplateUrl || ''),
			providerId: provider.id || UNKNOWN_PROVIDER_ID,
		}))[0];

const extractConnectFeatureFlagLinkTemplate = (
	response: RestResponse,
): ConnectFlagLinkTemplate | undefined =>
	(getIn(response, ['data', 'featureFlagInformation', 'providers']) || [])
		// @ts-expect-error - TS7006 - Parameter 'provider' implicitly has an 'any' type.
		.filter((provider) => !!provider.linkFlagTemplateUrl)
		// @ts-expect-error - TS7006 - Parameter 'provider' implicitly has an 'any' type.
		.map((provider) => ({
			linkTemplate: toSanitizedUrl(provider.linkFlagTemplateUrl || ''),
			providerId: provider.id || UNKNOWN_PROVIDER_ID,
		}))[0];

const extractFeatureFlagsPerProvider = (response: RestResponse): FeatureFlagsPerProvider => {
	const featureFlagsPerProvider: FeatureFlagsPerProvider = {};
	// @ts-expect-error - TS7006 - Parameter 'provider' implicitly has an 'any' type.
	(getIn(response, ['data', 'featureFlagInformation', 'providers']) || []).forEach((provider) => {
		featureFlagsPerProvider[provider.id || UNKNOWN_PROVIDER_ID] = [];
	});

	// @ts-expect-error - TS7006 - Parameter 'flag' implicitly has an 'any' type.
	(getIn(response, ['data', 'featureFlagInformation', 'featureFlags']) || []).forEach((flag) => {
		const providerId = flag.providerId || UNKNOWN_PROVIDER_ID;
		featureFlagsPerProvider[providerId] = featureFlagsPerProvider[providerId] || [];
		featureFlagsPerProvider[providerId].push(toFeatureFlagId(flag.id || ''));
	});

	Object.keys(featureFlagsPerProvider).forEach((key) => {
		featureFlagsPerProvider[key].sort();
	});
	return featureFlagsPerProvider;
};

const extractEnvironmentTypeWeight = (deployment: Deployment): number | undefined =>
	deploymentEnvironmentTypes[deployment.environment.type];

const extractStateWeight = (deployment: Deployment): number | undefined =>
	deploymentStates[deployment.state];

export const getLatestDeploymentInEachEnvironment = (deployments: Deployment[]): Deployment[] => {
	const deploymentsByEnvironment = groupBy(deployments, (deployment) => {
		const { environment } = deployment;
		return `${environment.type}_${environment.displayName}`;
	});

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return map(deploymentsByEnvironment, (deploymentsInEnv: Deployment[]) =>
		maxBy(deploymentsInEnv, (deployment) => new Date(deployment.lastUpdated)),
	) as Deployment[];
};

export const calculateDeploymentSummary = (deployments: Deployment[]): DeploymentSummary | null => {
	let environmentTypeWeight = -1;
	let stateWeight = -1;

	if (!deployments) {
		return null;
	}

	// @ts-expect-error - TS2741 - Property 'environmentType' is missing in type 'Deployment' but required in type 'DeploymentSummary'. | TS2769 - No overload matches this call.
	return deployments.reduce((accumulator, deployment): DeploymentSummary | null => {
		const newEnvironmentTypeWeight: number | undefined = extractEnvironmentTypeWeight(deployment);
		const newStateWeight: number | undefined = extractStateWeight(deployment);

		if (
			typeof newEnvironmentTypeWeight !== 'undefined' &&
			(environmentTypeWeight < newEnvironmentTypeWeight ||
				(environmentTypeWeight === newEnvironmentTypeWeight &&
					typeof newStateWeight !== 'undefined' &&
					stateWeight < newStateWeight))
		) {
			environmentTypeWeight = newEnvironmentTypeWeight;
			stateWeight = typeof newStateWeight !== 'undefined' ? newStateWeight : -1; // Can't move undefined check to constant or flow doesn't pick it up

			return {
				environmentType: deployment.environment.type,
				state: deployment.state,
				providerId: deployment.providerId,
			};
		}

		return accumulator;
	}, null);
};

export const getSummaryFromDeployments = (deployments: Deployment[]): DeploymentSummary | null => {
	const latestDeploymentInEachEnvironment = getLatestDeploymentInEachEnvironment(deployments);
	return calculateDeploymentSummary(latestDeploymentInEachEnvironment);
};

const extractDeploymentsDataForMultipleProviders = (
	response: RestResponse,
): DeploymentsData | undefined => {
	const providerInformation = response?.data?.deploymentInformation?.providers || [];
	const deploymentInformation = response?.data?.deploymentInformation?.deployments || [];

	// Organise by provider for future referencing
	const deploymentsByProvider = deploymentInformation.reduce<Record<string, Deployment[]>>(
		(accumulator, deployment) => {
			const { providerId } = deployment;
			// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
			const newAccumulator = { ...accumulator };

			if (!Object.hasOwnProperty.call(newAccumulator, providerId)) {
				newAccumulator[providerId] = [];
			}

			newAccumulator[providerId].push(deployment);

			return newAccumulator;
		},
		{},
	);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const mapToDeploymentsByEnvironmentType = (deployments: any) =>
		// @ts-expect-error - TS7006 - Parameter 'accumulator' implicitly has an 'any' type. | TS7006 - Parameter 'deployment' implicitly has an 'any' type.
		deployments.reduce((accumulator, deployment) => {
			// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
			const newAccumulator = { ...accumulator };
			const {
				environment: { type: environmentType },
			} = deployment;

			if (!Object.hasOwnProperty.call(newAccumulator, environmentType)) {
				newAccumulator[environmentType] = [];
			}

			newAccumulator[environmentType].push({
				displayName: toDeploymentDisplayName(deployment.displayName),
				lastUpdated: Date.parse(deployment.lastUpdated),
				environment: {
					displayName: toEnvironmentDisplayName(deployment.environment.displayName),
				},
				pipeline: {
					displayName: toPipelineDisplayName(deployment.pipeline.displayName),
					url: toSanitizedUrl(deployment.pipeline.url),
				},
				state: deployment.state,
				url: toSanitizedUrl(deployment.url),
			});

			return newAccumulator;
		}, {});

	const providers = providerInformation.reduce<DeploymentsData['providers']>(
		(accumulator, provider) => {
			const summary = getSummaryFromDeployments(deploymentsByProvider[provider.id]);

			if (summary) {
				// @ts-expect-error - TS2769 - No overload matches this call.
				return accumulator.concat({
					id: provider.id || UNKNOWN_PROVIDER_ID,
					name: provider.name || '',
					state: summary.state,
					listDeploymentsLinkTemplateUrl: toSanitizedUrl(provider.listDeploymentsTemplateUrl || ''),
					environments: mapToDeploymentsByEnvironmentType(deploymentsByProvider[provider.id]),
				});
			}

			return accumulator;
		},
		[],
	);
	const summary = getSummaryFromDeployments(deploymentInformation);
	return summary ? { summary, providers } : { summary: null, providers: [] };
};

const extractRemoteLinksForMultipleProviders = (
	response: RestResponse,
): RemoteLinksData | undefined => {
	let providers = response?.data?.remoteLinkInformation?.providers || [];
	let remoteLinks = response?.data?.remoteLinkInformation?.remoteLinks || [];

	// check FF
	// @ts-expect-error - TS2339 - Property 'actions' does not exist on type 'RemoteLinkProvider'.
	providers = providers.map(({ logoUrl, homeUrl, documentationUrl, actions, ...rest }) => ({
		...rest,
		// @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
		logoUrl: toSanitizedUrl(logoUrl),
		homeUrl: toSanitizedUrl(homeUrl),
		// @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
		documentationUrl: toSanitizedUrl(documentationUrl),
		actions: {
			...actions,
			...(actions && { templateUrl: toSanitizedUrl(actions.templateUrl) }),
		},
	}));

	remoteLinks = remoteLinks.map(({ url, ...rest }) => ({
		...rest,
		url: toSanitizedUrl(url),
	}));

	return providers.length ? { providers, remoteLinks } : { providers: [], remoteLinks: [] };
};

const logErrors = (response: RestResponse): void => {
	if (response.errors && response.errors.length > 0) {
		log.safeErrorWithoutCustomerData(
			'development.releases-glance.data-provider.errors',
			`Releases information from SWAG contains errors: ${JSON.stringify(response.errors)}`,
		);
	}
};

const transformResponse = (response: RestResponse): ReleasesData => {
	logErrors(response);

	const enabledFlagNames = extractFeatureFlagNames(response, ENABLED).sort();
	const disabledFlagNames = extractFeatureFlagNames(response, DISABLED).sort();
	return {
		featureFlags: {
			enabledFlagNames,
			disabledFlagNames,

			// This is a temp solution: the details will be replaced by a sliding panel after MVP
			detailsLink: extractDetailsLink(response, ENABLED) || extractDetailsLink(response, DISABLED),

			rolloutSummary:
				enabledFlagNames.length === 1 && disabledFlagNames.length === 0
					? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						extractRolloutSummary(response as RestResponse)
					: undefined,

			createFlagLinkTemplate: extractCreateFeatureFlagLinkTemplate(response),
			connectFlagLinkTemplate: extractConnectFeatureFlagLinkTemplate(response),

			flagsPerProvider: extractFeatureFlagsPerProvider(response),
		},
		deployments: extractDeploymentsDataForMultipleProviders(response),
		remoteLinks: extractRemoteLinksForMultipleProviders(response),
		shouldShowEmptyState: undefined,
		hasDeploymentsInProject: undefined,
		shouldShowAppConfigContextPrompt: undefined,
	};
};

const eventName = 'releasesData fetched';

export const isExpectedGraphQLError = (error: ReleasesDataError): boolean => {
	if (error.extensions) {
		const { statusCode, errorType } = error.extensions;
		log.safeErrorWithoutCustomerData(
			'development.releases-glance.data-provider.errors',
			`Releases information SWAG errors statusCode: ${statusCode}, errorType: ${errorType}`,
		);
		if (
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			EXPECTED_ERROR_STATUS_CODES.includes(statusCode as number) &&
			errorType === JSWDD_ENTITIES_FETCH_ERROR
		) {
			return true;
		}
	}
	return false;
};

const fireFetchedEvent = (
	issueId: number,
	analyticsEvent: UIAnalyticsEvent,
	restResponse: RestResponse,
	releasesData: ReleasesData,
	startTime: number,
) => {
	const hasErrors = !!(restResponse && restResponse.errors && restResponse.errors.length > 0);

	const isExpectedBackendError: boolean =
		hasErrors && restResponse.errors && restResponse.errors.length > 0
			? isExpectedGraphQLError(restResponse.errors[0])
			: false;

	fireTrackAnalytics(analyticsEvent, eventName, {
		issueId,
		hasFeatureFlags: hasFeatureFlags(releasesData),
		hasDeployments: hasDeployments(releasesData),
		hasRemoteLinks: hasRemoteLinks(releasesData),
		hasErrors,
		elapsed: Date.now() - startTime,
		isExpectedBackendError,
	});
};

const fireFetchedErrorEvent = (
	issueId: number,
	analyticsEvent: UIAnalyticsEvent,
	error: Error,
	startTime: number,
) => {
	fireTrackAnalytics(analyticsEvent, eventName, {
		issueId,
		globalError: true,
		error,
		elapsed: Date.now() - startTime,
	});
};

const flattenEntities = (response: ConsolidatedDataResponse): RestResponse => ({
	data: response.data.dataDepotEntities,
	errors: response.errors,
});

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({
	issueId,
	analyticsEvent,
}: {
	issueId: number;
	analyticsEvent: UIAnalyticsEvent;
}): Promise<ReleasesData> => {
	const startTime = Date.now();

	return fetchReleasesData(issueId)
		.then(flattenEntities)
		.then((restResponse) => {
			const releasesData = transformResponse(restResponse);
			fireFetchedEvent(issueId, analyticsEvent, restResponse, releasesData, startTime);
			return releasesData;
		})
		.catch((error) => {
			log.safeErrorWithoutCustomerData(
				'development.releases-glance.data-provider.exception',
				'Failed to fetch releases information via SWAG',
				error,
			);

			fireFetchedErrorEvent(issueId, analyticsEvent, error, startTime);

			throw error;
		});
};
