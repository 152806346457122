import { transformUser } from '@atlassian/jira-issue-view-services/src/issue/user-transformer.tsx';
import type { BaseUrl, IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import fetchDetailsForIssues from '../../rest/fetch-details-for-issues.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (baseUrl: BaseUrl, issueKeys: IssueKey[]) =>
	fetchDetailsForIssues(baseUrl, issueKeys).then((issues) =>
		issues.map(({ id, fields }) => ({
			id,
			assigneeUrl: fields && fields.assignee && transformUser(fields.assignee).avatarUrl,
			assigneeDisplayName: fields && fields.assignee && transformUser(fields.assignee).displayName,
		})),
	);
