import type { SelectValueShape as MultiSelectValueItemShape } from '@atlassian/jira-issue-internal-field-select/src/common/select-inline-edit/select-field/types.tsx';

export type StateValue = {
	id: string | number;
	value: string;
};

type MultiSelectSuggestions = {
	items: MultiSelectValueItemShape[];
};

type MultiSelectSuggestionsArray = MultiSelectSuggestions[];

const toItem = (fieldData: StateValue): MultiSelectValueItemShape => ({
	value: fieldData.id,
	content: fieldData.value,
});

export const transformFromStateValue = (value: StateValue[] | null): MultiSelectValueItemShape[] =>
	value ? value.map(toItem) : [];

export const transformToStateValue = (
	value: MultiSelectValueItemShape[] | null,
): StateValue[] | null =>
	value &&
	value.map((item) => ({
		id: item.value,
		value: item.content,
	}));

export const transformSuggestions = (suggestions: StateValue[]): MultiSelectSuggestionsArray =>
	(suggestions && [{ items: suggestions.map(toItem) }]) || [];
