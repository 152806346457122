/**
 * @generated SignedSource<<e78c8beec595a9fd094f7baaddbef264>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type singleLineTextHeading_issueFieldSingleLineTextReadviewHeading_SingleLineTextHeadingReadView$data = {
  readonly text: string | null | undefined;
  readonly " $fragmentType": "singleLineTextHeading_issueFieldSingleLineTextReadviewHeading_SingleLineTextHeadingReadView";
};
export type singleLineTextHeading_issueFieldSingleLineTextReadviewHeading_SingleLineTextHeadingReadView$key = {
  readonly " $data"?: singleLineTextHeading_issueFieldSingleLineTextReadviewHeading_SingleLineTextHeadingReadView$data;
  readonly " $fragmentSpreads": FragmentRefs<"singleLineTextHeading_issueFieldSingleLineTextReadviewHeading_SingleLineTextHeadingReadView">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "singleLineTextHeading_issueFieldSingleLineTextReadviewHeading_SingleLineTextHeadingReadView",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "text"
    }
  ],
  "type": "JiraSingleLineTextField"
};

(node as any).hash = "5385571d01021dbe0e88e49227c552ed";

export default node;
