// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	exceedWarningLabel: {
		id: 'attachments.panel.exceed-warning',
		defaultMessage: 'This issue has too many attachments. Thumbnails and previews won’t load.',
		description: 'The warning label on attachment panel when user is exceeding the limit',
	},
	exceedWarningLinkLabel: {
		id: 'attachments.panel.exceed-warning.link',
		defaultMessage: 'Learn about attachments',
		description:
			'The Learn about attachment link label on attachment panel when user is exceeding the limit',
	},
	exceedWarningLabelIssueTermRefresh: {
		id: 'attachments.panel.exceed-warning-issue-term-refresh',
		defaultMessage: 'This issue has too many attachments. Thumbnails and previews won’t load.',
		description: 'The warning label on attachment panel when user is exceeding the limit',
	},
});
