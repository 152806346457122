import React, { type ReactNode } from 'react';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';

export type IssueCommentReactionsBoundaryProps = {
	children: ReactNode;
	fallback: ReactNode;
	packageName: string;
};

export const IssueCommentReactionsBoundary = ({
	children,
	fallback,
	packageName,
}: IssueCommentReactionsBoundaryProps) => (
	<ErrorBoundary id="issue-comment-reactions" packageName={packageName} render={() => fallback}>
		<Placeholder name="issue-comment-reactions-boundary" fallback={fallback}>
			{children}
		</Placeholder>
	</ErrorBoundary>
);
