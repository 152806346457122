import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import type { DocumentFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/document/types.tsx';
import { STATUS_FIELDKEY } from '@atlassian/jira-polaris-domain-field/src/field/constants.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { runInBatch } from '@atlassian/jira-polaris-lib-run-in-batch/src/index.tsx';
import type { RemoteIssueLink } from '@atlassian/jira-polaris-remote-issue/src/controllers/issue-link/types.tsx';
import type { IssuesRemote } from '@atlassian/jira-polaris-remote-issue/src/controllers/types.tsx';
import { type IssueKey, toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { MAX_FIELD_LENGTH } from '../../../../common/constants.tsx';
import { createGetTransitionForStatus } from '../../../workflow/selectors/transitions.tsx';
import type { Status } from '../../../workflow/types.tsx';
import { createGetIssuesForMerge } from '../../selectors/issue-ids.tsx';
import { createGetIssueType, createGetStatus } from '../../selectors/properties/index.tsx';
import type { State, Props } from '../../types.tsx';
import { copyMediaAndAttachments } from '../attachments/utils.tsx';
import { createAndUpdate } from '../create-issue/index.tsx';
import { refreshIssues } from '../refresh-issues/index.tsx';
import { updateStatus } from '../update-status/index.tsx';

const copyTheInsights =
	(sourceIssueId: number, targetIssueId: number) =>
	async (_: StoreActionApi<State>, { projectId, insightsRemote }: Props) => {
		if (projectId === undefined) return;

		await insightsRemote.copyInsights?.({
			source: {
				projectId,
				issueId: sourceIssueId ? String(sourceIssueId) : undefined,
			},
			destination: {
				projectId,
				issueId: targetIssueId ? String(targetIssueId) : undefined,
			},
		});
	};

const copyAttachments =
	(sourceIssueId: number, sourceIssueKey: IssueKey, targetIssueKey: IssueKey) =>
	async (_: StoreActionApi<State>, props: Props) => {
		const { projectId, issuesRemote, insightsRemote } = props;

		if (projectId === undefined) return;

		await copyMediaAndAttachments(
			insightsRemote,
			issuesRemote,
			sourceIssueId,
			sourceIssueKey,
			targetIssueKey,
		);
	};

const copyLinkedIssues = async (
	issuesRemote: IssuesRemote,
	issueLinks: RemoteIssueLink[],
	targetIssueKey: IssueKey,
) => {
	await runInBatch(
		issueLinks.map(
			(link: RemoteIssueLink) => () =>
				link?.inwardIssue // unfortunately using a filter doesn't resolve possible type problems (undefined values)
					? issuesRemote.createIssueLink({
							issueLinkTypeId: link.type.id,
							inwardIssueKey: link.inwardIssue.key,
							outwardIssueKey: targetIssueKey,
						})
					: Promise.resolve(),
		),
		5,
	);
};

const copyStatus =
	(issueId: LocalIssueId, value: Status) =>
	({ getState, dispatch }: StoreActionApi<State>, props: Props) => {
		const issueTypeSelector = createGetIssueType(issueId);
		const issueType = issueTypeSelector(getState(), props);

		const getStatus = createGetStatus(issueId);
		const status = getStatus(getState(), props);

		if (issueType === undefined || status === undefined) {
			return Promise.reject();
		}

		const getTransitionForStatus = createGetTransitionForStatus(issueType.id, status, value);

		const transition = getTransitionForStatus({ transitions: props.workflowTransitions });

		if (transition !== undefined) {
			return new Promise((resolve, reject) =>
				dispatch(updateStatus(STATUS_FIELDKEY, issueId, transition, resolve, reject)),
			);
		}

		return Promise.resolve();
	};

const copyFields =
	(
		sourceLocalIssueId: LocalIssueId,
		targetLocalIssueId: LocalIssueId,
		targetIssueKey: IssueKey,
		description: DocumentFieldValue,
		fieldsForUpdate: {
			[fieldKey: string]: unknown;
		},
	) =>
	async ({ getState, dispatch }: StoreActionApi<State>, props: Props) => {
		const getStatus = createGetStatus(sourceLocalIssueId);
		const status = getStatus(getState(), props);

		if (status !== undefined) {
			await dispatch(copyStatus(targetLocalIssueId, status));
		}

		await props.issuesRemote.updateIssueFields({
			issueKey: targetIssueKey,
			update: fieldsForUpdate,
		});
		if (description != null) {
			await props.issuesRemote.updateIssueDescription({
				issueKey: targetIssueKey,
				description,
			});
		}
	};

const copyEverything =
	(
		sourceLocalIssueId: LocalIssueId,
		targetLocalIssueId: LocalIssueId,
		targetIssueKey: IssueKey,
		targetIssueId: number,
		issueLinks: RemoteIssueLink[],
	) =>
	async ({ getState, dispatch }: StoreActionApi<State>, props: Props) => {
		const { id, key, fieldsForUpdate, description } = createGetIssuesForMerge([sourceLocalIssueId])(
			getState(),
			props,
		)[0];
		await dispatch(
			copyFields(
				sourceLocalIssueId,
				targetLocalIssueId,
				targetIssueKey,
				description,
				fieldsForUpdate,
			),
		);
		await copyLinkedIssues(props.issuesRemote, issueLinks, targetIssueKey);
		await dispatch(copyTheInsights(id, targetIssueId));
		await dispatch(copyAttachments(id, toIssueKey(key), targetIssueKey));
	};

export type ClonedIssue = {
	id: LocalIssueId;
	key: IssueKey;
	summary: string;
};

export const clonePolarisIssue =
	(
		localIssueId: LocalIssueId,
		summary: string,
		issueLinks: RemoteIssueLink[],
		onSuccess: (target: ClonedIssue) => void,
		onFailure: (error: Error) => void,
	) =>
	async ({ dispatch }: StoreActionApi<State>, { issueTypeIds }: Props) => {
		if (issueTypeIds === undefined || issueTypeIds.length === 0) return;

		const newSummary = summary.slice(0, MAX_FIELD_LENGTH);

		dispatch(
			createAndUpdate(
				issueTypeIds[0],
				newSummary,
				{ summary: newSummary },
				// eslint-disable-next-line @typescript-eslint/no-empty-function
				() => {},
				(newLocalIssueId, _, issue) => {
					if (issue === null || typeof issue !== 'object') {
						return;
					}

					const issueKey = toIssueKey(String(issue.key));

					dispatch(
						copyEverything(localIssueId, newLocalIssueId, issueKey, Number(issue.id), issueLinks),
					)
						.then(() =>
							dispatch(
								refreshIssues({
									jiraIssueIds: [issue.id],
								}),
							),
						)
						.then(() =>
							onSuccess({
								id: newLocalIssueId,
								key: issueKey,
								summary: newSummary,
							}),
						)
						.catch((err) => {
							const error = err instanceof Error ? err : new Error('Unknown error');
							log.safeErrorWithoutCustomerData(
								'polaris.clone-ideas-error',
								'Failed to clone the idea',
								error,
							);

							onFailure(error);
						});
				},
			),
		);
	};
