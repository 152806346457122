import React, { useCallback } from 'react';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import type { ViewModeOptions } from '@atlassian/jira-issue-view-model/src/view-mode-options.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';

type Props = {
	// eslint-disable-next-line jira/react/handler-naming
	emitEventToLegacyApp: (event: string) => void;
	viewModeOptions: ViewModeOptions;
};

const SwitchToSidebar = ({ viewModeOptions, emitEventToLegacyApp }: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const onClick = useCallback(() => {
		viewModeOptions.viewModeSwitchEnabled === true && viewModeOptions.onSwitchToSidebar
			? viewModeOptions.onSwitchToSidebar()
			: emitEventToLegacyApp('issue.details.view.switch-to-sidebar');

		const analyticsEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'dropdownItem',
		});
		fireUIAnalytics(analyticsEvent, 'switchToSidebar');
	}, [createAnalyticsEvent, emitEventToLegacyApp, viewModeOptions]);

	return (
		<DropdownItem key="switch-to-sidebar" onClick={onClick}>
			{formatMessage(
				fg('jira-issue-terminology-refresh-m3')
					? messages.switchToSidebarIssueTermRefresh
					: messages.switchToSidebar,
			)}
		</DropdownItem>
	);
};

SwitchToSidebar.defaultProps = {
	viewModeOptions: {
		viewModeSwitchEnabled: false,
	},
};

export default SwitchToSidebar;
