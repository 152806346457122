/**
 * @generated SignedSource<<1b7bf366b3c8c1a95f2d49435467771d>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type issueParentLegacyAndAgg_inlineData$data = {
  readonly id: string;
  readonly issueColorField: {
    readonly color: {
      readonly colorKey: string | null | undefined;
      readonly id: string | null | undefined;
    } | null | undefined;
    readonly id: string;
  } | null | undefined;
  readonly issueId: string;
  readonly issueTypeField: {
    readonly id: string;
    readonly issueType: {
      readonly avatar: {
        readonly medium: string | null | undefined;
        readonly xsmall: string | null | undefined;
      } | null | undefined;
      readonly description: string | null | undefined;
      readonly hierarchy: {
        readonly level: number | null | undefined;
      } | null | undefined;
      readonly id: string;
      readonly issueTypeId: string | null | undefined;
      readonly name: string;
    } | null | undefined;
  } | null | undefined;
  readonly key: string;
  readonly statusField: {
    readonly status: {
      readonly statusCategory: {
        readonly statusCategoryId: string;
      } | null | undefined;
    };
  } | null | undefined;
  readonly summaryField: {
    readonly id: string;
    readonly text: string | null | undefined;
  } | null | undefined;
  readonly webUrl: AGG$URL | null | undefined;
  readonly " $fragmentType": "issueParentLegacyAndAgg_inlineData";
};
export type issueParentLegacyAndAgg_inlineData$key = {
  readonly " $data"?: issueParentLegacyAndAgg_inlineData$data;
  readonly " $fragmentSpreads": FragmentRefs<"issueParentLegacyAndAgg_inlineData">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "issueParentLegacyAndAgg_inlineData"
};

(node as any).hash = "81825eb6997072f30c518404a577cad2";

export default node;
