import React from 'react';
import Tabs, { Tab, TabList } from '@atlaskit/tabs';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import { ConditionallyVisibleDiv } from '@atlassian/jira-issue-adjustments/src/ui.tsx';

type TabData = {
	id?: string;
	label: string;
	content: JSX.Element;
	// make non-optional when cleaning FF 'orge-ui-modifications-screentabs_qoa33'
	isVisible?: boolean;
};

// remove type when cleaning FF 'orge-ui-modifications-screentabs_qoa33'
type TabArray = TabData | null;

type StaticPropsOld = {
	tabsContent: TabArray[];
};

export const StaticTabsSpotlightOld = ({ tabsContent }: StaticPropsOld) => (
	<Tabs
		id="issue-view-layout-templates-tabs-spotlight"
		shouldUnmountTabPanelOnChange
		testId="issue-view-layout-templates-tab-view.ui.static-tabs-spotlight.issue-view-layout-templates-tabs-spotlight"
	>
		<TabList>
			{tabsContent.map((tab, index) => (
				<Tab key={index}>{tab?.label}</Tab>
			))}
		</TabList>
	</Tabs>
);

type StaticPropsNew = {
	tabs: TabData[];
};

export const StaticTabsSpotlightNew = ({ tabs }: StaticPropsNew) => (
	<Tabs
		id="issue-view-layout-templates-tabs-spotlight"
		shouldUnmountTabPanelOnChange
		testId="issue-view-layout-templates-tab-view.ui.static-tabs-spotlight.issue-view-layout-templates-tabs-spotlight"
	>
		<TabList>
			{tabs.map((tab, index) => (
				<ConditionallyVisibleDiv key={tab.id} isVisible={tab.isVisible}>
					<Tab key={index}>{tab?.label}</Tab>
				</ConditionallyVisibleDiv>
			))}
		</TabList>
	</Tabs>
);

export const StaticTabsSpotlight = componentWithCondition(
	() => fg('forge_ui_modifications_for_screen_tabs'),
	StaticTabsSpotlightNew,
	StaticTabsSpotlightOld,
);
