import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	projectDefaultClassficationName: {
		id: 'issue-classification-badge.edit-view-popup.project-default-classfication-name',
		defaultMessage: 'Project default',
		description: 'This is the project default option for data classification field.',
	},

	projectDefaultClassficationGuideline: {
		id: 'issue-classification-badge.edit-view-popup.project-default-classfication-guideline',
		defaultMessage: 'The default level is {name}',
		description: 'Guideline for project default option for data classification field.',
	},

	noClassificationGuideline: {
		id: 'issue-classification-badge.edit-view-popup.no-classification-guideline',
		defaultMessage: 'Project default - No classification',
		description: 'This is no classification option for data classification field.',
	},
});
