import {
  ParserErrorListener,
  RecognitionException,
  Recognizer,
  Token
} from 'antlr4ts';
import { BaseError } from '../types';

class JQLError implements BaseError {
  start: number;
  stop: number;
  message: string;

  constructor(start: number, stop: number, message: string) {
    this.start = start;
    this.stop = stop;
    this.message = message;
  }
}

export default class JastBuilderErrorListener implements ParserErrorListener {
  errors: BaseError[] = [];
  syntaxError = <T extends Token>(
    recognizer: Recognizer<T, any>,
    offendingSymbol: T | undefined,
    line: number,
    charPositionInLine: number,
    msg: string,
    e: RecognitionException | undefined
  ) => {
    if (offendingSymbol === undefined) {
      // TODO what error do we show
    } else {
      this.errors.push(
        new JQLError(
          offendingSymbol.startIndex,
          offendingSymbol.stopIndex + 1,
          msg
        )
      );
    }
  };
}
