// eslint-disable-next-line jira/restricted/styled-components-migration, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css } from 'styled-components';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression
export const getIconDimensionStyles = (value: string) => css`
	height: ${value};
	min-height: ${value};
	max-height: ${value};
	width: ${value};
	min-width: ${value};
	max-width: ${value};
`;

const avatarSizeStyles = getIconDimensionStyles('24px');

const highlightRemovalStyles = css({
	boxShadow: 'none',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles
	outline: 'none !important',
	outlineColor: 'inherit',
	color: 'inherit',
	WebkitTapHighlightColor: 'transparent',
	WebkitTouchCallout: 'none',
	WebkitUserSelect: 'none',
	MozUserSelect: 'none',
	MsUserSelect: 'none',
	userSelect: 'none',
});

// eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const styleOverrides = css`
	height: 40px;
	gap: ${token('space.100', '8px')};
	cursor: pointer;

	[data-smart-element-avatar-group] {
		${avatarSizeStyles}
		div,
        span,
        img,
        svg {
			${avatarSizeStyles}
		}

		span {
			margin: 0;
		}
	}

	[data-smart-element-link] {
		${highlightRemovalStyles}
	}
`;
