import type { DynamicFieldFormula } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/types.tsx';
import { createGetLinkedIssuesCount } from '../../../../selectors/properties/linked-issues/index.tsx';
import type { DynamicFieldResolver } from '../../types.tsx';

const FORMULA_TEMPLATE = 'num_linked_issues';

export const linkedIssuesCountDynamicFieldResolver: DynamicFieldResolver<number> = (
	formula: DynamicFieldFormula,
) => {
	// @ts-expect-error - TS2367 - This condition will always return 'true' since the types '"num_data_points" | "composition" | "norm" | "const" | "field" | "property_agg" | "linked_issues" | "expr" | "discretization" | "multi-select-count"' and '"num_linked_issues"' have no overlap.
	if (formula === undefined || formula.template !== FORMULA_TEMPLATE) {
		return undefined;
	}

	return createGetLinkedIssuesCount;
};
