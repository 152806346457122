import React, { memo, type Ref, useCallback, useState, useEffect, type ChangeEvent } from 'react';
import { styled } from '@compiled/react';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import InlineDialog from '@atlaskit/inline-dialog';
import Textfield from '@atlaskit/textfield';
import { colors } from '@atlaskit/theme';
import { Y300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { FIELD_TYPE_MAP } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/constants.tsx';
import { getUpdateAnalyticsFlowHelper } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/index.tsx';
import { useIsCompactMode } from '@atlassian/jira-issue-view-compact-mode/src/index.tsx';
import { SUMMARY_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import messages from '../messages.tsx';

export type Props = {
	isInvalid: boolean;
	passedRef?: Ref<'input'>;
	defaultValue: string | null;
	invalidMessage: string | undefined;
	onChange: (arg1: string) => void;
};

export const InlineSummaryEdit = memo<Props>(
	({ defaultValue, passedRef, onChange, isInvalid, invalidMessage }: Props) => {
		const { formatMessage } = useIntl();
		const { createAnalyticsEvent } = useAnalyticsEvents();

		useEffect(() => {
			// We do this on mount since currently it requires two clicks of the edit button, to open the edit mode
			fg('one_event_rules_them_all_fg') &&
				getUpdateAnalyticsFlowHelper().fireAnalyticsStart(SUMMARY_TYPE, {
					analytics: createAnalyticsEvent({}),
					attributes: {
						fieldType: FIELD_TYPE_MAP[SUMMARY_TYPE],
						isInlineEditing: true,
					},
				});
		}, [createAnalyticsEvent]);

		const onChangeHandler = useCallback(
			(event: ChangeEvent<HTMLInputElement>) => {
				onChange(event.target.value);
			},
			[onChange],
		);

		const [isFocused, setIsFocused] = useState(false);

		const onFocus = useCallback(() => {
			setIsFocused(true);
		}, []);

		const onBlur = useCallback(() => {
			setIsFocused(false);
		}, []);

		const isCompact = useIsCompactMode();

		const warningIcon = isInvalid ? (
			<WarningIcon size="medium" primaryColor={token('color.icon.warning', Y300)} label="" />
		) : null;

		return (
			<>
				<InlineDialog
					content={invalidMessage}
					isOpen={isFocused && !!invalidMessage}
					placement={isCompact === true ? 'auto-start' : 'right'}
				>
					<InlineEditViewWrapper isInvalid={isInvalid}>
						<Textfield
							type="text"
							aria-label={formatMessage(messages.summaryTextareaLabel)}
							value={defaultValue === null ? '' : defaultValue}
							onChange={onChangeHandler}
							label=""
							ref={passedRef}
							appearance={isInvalid ? 'none' : 'subtle'}
							onBlur={onBlur}
							onFocus={onFocus}
							isCompact
							autoFocus
						/>
						{warningIcon}
					</InlineEditViewWrapper>
				</InlineDialog>
			</>
		);
	},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InlineEditViewWrapper = styled.div<{ isInvalid?: boolean }>({
	display: 'flex',
	flex: '1 0 auto',
	justifyContent: 'space-between',
	overflow: 'hidden',
	alignItems: 'center',
	marginLeft: token('space.150', '12px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderColor: (props) => (props.isInvalid ? token('color.border.danger', colors.R400) : undefined),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	borderStyle: (props) => (props.isInvalid ? 'solid' : 'none'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	borderRadius: (props) => (props.isInvalid ? '3px' : undefined),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	borderWidth: (props) => (props.isInvalid ? '2px' : undefined),
});
