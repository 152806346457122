import { useCallback, useEffect, useState, type RefObject } from 'react';
import { useResizeObserver } from '@atlassian/jira-react-use-resize-observer/src/index.tsx';
import { MIN_CARD_WIDTH_PX } from './constants.tsx';

/**
 * Hook to determine if the design panel is in compact view mode, based on the size of the container and the minimum card width.
 * Uses the `useResizeObserver` hook to listen for resize events on the container and updates the view mode accordingly.
 * @param designPanelContainerRef - ref to the design panel container
 * @returns
 */
export const useIsDesignPanelCompactView = (designPanelContainerRef: RefObject<HTMLDivElement>) => {
	const [isCompactView, setIsCompactView] = useState(false);

	const updateViewMode = useCallback(() => {
		const containerWidth = designPanelContainerRef.current?.getBoundingClientRect().width;
		if (!containerWidth) return;
		const isCompact = containerWidth < MIN_CARD_WIDTH_PX * 2;
		if (isCompact !== isCompactView) {
			setIsCompactView(isCompact);
		}
	}, [designPanelContainerRef, isCompactView]);

	// Effect to set the initial view mode as early as possible
	useEffect(() => {
		updateViewMode();
	}, [updateViewMode]);

	useResizeObserver({
		ref: designPanelContainerRef,
		onResize: updateViewMode,
	});

	return isCompactView;
};
