import { throttleTime } from 'rxjs/operators';
import { async } from 'rxjs/scheduler/async';

export const THROTTLE_ISSUE_REFRESH_TIME = 5000;

export const throttleIssueRefresh = <T,>() =>
	throttleTime<T>(THROTTLE_ISSUE_REFRESH_TIME, async, {
		leading: true,
		trailing: true,
	});

export const EXPERIENCE_DELETE_ISSUE_LINK_SUCCEEDED = 'deleteIssueLinkSucceeded';
export const EXPERIENCE_DELETE_ISSUE_LINK_FAILED = 'deleteIssueLinkFailed';
export const SOURCE_JIRA_LINKED_ISSUES = 'jiraLinkedIssues';
