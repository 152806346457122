import { useCallback } from 'react';
import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import type { Option } from '@atlassian/jira-issue-field-labels-editview-full/src/ui/labels/types.tsx';
import type {
	ClientEventValidator,
	ClientValidator,
} from '@atlassian/jira-issue-field-validation-handler/src/controllers/client-validation-handler/types.tsx';
import { MAXIMUM_LABEL_LENGTH, MAXIMUM_NUMBER_OF_LABELS } from './constants.tsx';
import messages from './messages.tsx';

export const useLabelsValidator = (): ClientEventValidator<Option[]> => {
	const { formatMessage } = useIntl();
	const change: ClientValidator<Option[]> = useCallback(
		(newValues: Option[]) => {
			for (let i = newValues.length - 1; i >= 0; i -= 1) {
				const { __isNew__, value } = newValues[i];
				if (__isNew__ === true) {
					if (value.includes(' ')) {
						return {
							type: 'error',
							message: formatMessage(messages.newLabelContainsSpacesError),
						};
					}
					if (value.length > MAXIMUM_LABEL_LENGTH) {
						return {
							type: 'error',
							message: formatMessage(messages.newLabelTooLongError, {
								maxLength: MAXIMUM_LABEL_LENGTH,
							}),
						};
					}
				}
			}

			if (newValues.length > MAXIMUM_NUMBER_OF_LABELS) {
				return {
					type: 'warning',
					message: formatMessage(messages.maximumNumberOfLabelsExceededError, {
						maxNumberOfLabels: MAXIMUM_NUMBER_OF_LABELS,
					}),
				};
			}

			return null;
		},
		[formatMessage],
	);

	return { change };
};

export const RESTRICT_PROJECTS_CACHE_KEY = 'restrict_labels_to_projects_checkbox_value';
export const labelsLocalCache = createLocalStorageProvider('project-scoped-labels');

export const getProjectScopeLabelCheckboxValueFromLocalStorage = (): boolean => {
	if (!fg('labels_restricted_to_project_experiment')) return false;
	return labelsLocalCache.get(RESTRICT_PROJECTS_CACHE_KEY) || false;
};

export const setProjectScopeLabelCheckboxValueFromLocalStorage = (value: boolean) => {
	return labelsLocalCache.set(RESTRICT_PROJECTS_CACHE_KEY, value);
};
