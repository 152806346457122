/**
 * @generated SignedSource<<ebc67ee0e5b1d19577ad84dec6dd3022>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type singleLineText_issueFieldSingleLineTextInlineEditFull_SingleLineTextInlineEditViewWithIsEditable_fragmentRef$data = {
  readonly fieldId: string;
  readonly id: string;
  readonly name: string;
  readonly text: string | null | undefined;
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"singleLineText_issueFieldSingleLineTextReadviewFull_SingleLineTextReadView">;
  readonly " $fragmentType": "singleLineText_issueFieldSingleLineTextInlineEditFull_SingleLineTextInlineEditViewWithIsEditable_fragmentRef";
};
export type singleLineText_issueFieldSingleLineTextInlineEditFull_SingleLineTextInlineEditViewWithIsEditable_fragmentRef$key = {
  readonly " $data"?: singleLineText_issueFieldSingleLineTextInlineEditFull_SingleLineTextInlineEditViewWithIsEditable_fragmentRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"singleLineText_issueFieldSingleLineTextInlineEditFull_SingleLineTextInlineEditViewWithIsEditable_fragmentRef">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "singleLineText_issueFieldSingleLineTextInlineEditFull_SingleLineTextInlineEditViewWithIsEditable_fragmentRef",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "singleLineText_issueFieldSingleLineTextReadviewFull_SingleLineTextReadView"
    },
    {
      "kind": "ScalarField",
      "name": "text"
    },
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "ScalarField",
      "name": "name"
    }
  ],
  "type": "JiraSingleLineTextField"
};

(node as any).hash = "fa64e21466cd3e14f917674796ea3359";

export default node;
