import { createFilter } from '@atlaskit/select';
import type { Space, FilteredSpaces, Option } from './types.tsx';

export const getSpaceUrl = (appId: string): string =>
	`/rest/confluenceIssueLink/1/confluence/space?appId=${appId}&_=${Date.now()}`;

export const transformSpaces = (options: Space[]): Option[] =>
	options.map((option) => ({
		label: option.name,
		value: option.key,
		data: option,
		space: option,
	}));

export const filteredResponse = (
	options: Option[],
	renderOptionsLimit: number,
	query = '',
): FilteredSpaces => {
	const filterRecentResults = createFilter();
	// @ts-expect-error - TS2345 - Argument of type 'import("/buildeng/bamboo-agent-home/xml-data/build-dir/JF-TSMIG123-APPLY/src/packages/issue/issue-view/src/views/issue-base/content/confluence-content/create-link/confluence-search-view/services/spaces/types").Option' is not assignable to parameter of type 'import("/buildeng/bamboo-agent-home/xml-data/build-dir/JF-TSMIG123-APPLY/node_modules/@types/react-select/src/filters").Option'.
	const filtered = options.filter((option) => filterRecentResults(option, query));
	return {
		filtered: filtered.slice(0, renderOptionsLimit),
		overall: options,
	};
};
