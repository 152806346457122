import type CancelledIcon from './cancelled/index.tsx';
import type FailedIcon from './failed/index.tsx';
import type InProgressIcon from './in-progress/index.tsx';
import type PendingIcon from './pending/index.tsx';
import type RolledBackIcon from './rolled-back/index.tsx';
import type SuccessNonProductionIcon from './success-non-production/index.tsx';
import type SuccessProductionIcon from './success-production/index.tsx';
import type UnknownIcon from './unknown/index.tsx';

const PENDING = 'PENDING' as const;
const IN_PROGRESS = 'IN_PROGRESS' as const;
const SUCCESSFUL = 'SUCCESSFUL' as const;
const SUCCESSFULNONPRODUCTION = 'SUCCESSFULNONPRODUCTION' as const;
const CANCELLED = 'CANCELLED' as const;
const FAILED = 'FAILED' as const;
const ROLLED_BACK = 'ROLLED_BACK' as const;
const UNKNOWN = 'UNKNOWN' as const;

export const DeploymentStateOptions = {
	PENDING,
	IN_PROGRESS,
	SUCCESSFUL,
	SUCCESSFULNONPRODUCTION,
	CANCELLED,
	FAILED,
	ROLLED_BACK,
	UNKNOWN,
} as const;

export type DeploymentState = (typeof DeploymentStateOptions)[keyof typeof DeploymentStateOptions];

export type IconType =
	| typeof FailedIcon
	| typeof InProgressIcon
	| typeof PendingIcon
	| typeof RolledBackIcon
	| typeof SuccessNonProductionIcon
	| typeof SuccessProductionIcon
	| typeof UnknownIcon
	| typeof CancelledIcon;

export type IconSize = 'small' | 'medium';
