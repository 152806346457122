import { fetchQuery, graphql } from 'react-relay';
import { createOperationDescriptor } from 'relay-runtime';
import type { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import getRelayEnvironment from '@atlassian/jira-relay-environment/src/index.tsx';
import DetailsHasDevelopmentDataInProjectQuery, {
	type detailsHasDevelopmentDataInProjectQuery$data,
} from '@atlassian/jira-relay/src/__generated__/detailsHasDevelopmentDataInProjectQuery.graphql';
import { query$ } from '@atlassian/jira-software-swag/src/services/query/index.tsx';
import type { ResponseData } from './types.tsx';

const OPERATION_NAME = 'DevSummaryPanelOneClickUrls';

const innerQuery = () => `
    details {
        instanceTypes {
            id
            type
            devStatusErrorMessages
            repository {
                avatarUrl
                name
                branches {
                    createPullRequestUrl
                    name
                    url
                }
                commits{
                    url
                }
                pullRequests {
                    url
                    status
                }
            }
            danglingPullRequests {
                url
                status
            }
            buildProviders {
                id
                builds {
                    url
                    state
                }
            }
         }
    }
`;

const swagGraphQLQuery = () => `
    query ${OPERATION_NAME} ($issueId: ID!) {
        developmentInformation(issueId: $issueId){
            ${innerQuery()}
        }
    }`;

export const query = (issueId: string): Observable<ResponseData> =>
	// @ts-expect-error - TS2322 - Type 'Observable<unknown>' is not assignable to type 'Observable<ResponseData>'.
	query$<ResponseData>('', OPERATION_NAME, swagGraphQLQuery(), { issueId })
		.map((result) => ({
			data: result,
		}))
		.map((result) => {
			// @ts-expect-error - TS2339 Property 'errors' does not exist on type '{ data: ResponseData; }'.
			if (result.errors && result.errors.length) {
				// @ts-expect-error - TS2339 Property 'errors' does not exist on type '{ data: ResponseData; }'.
				const errorMessages = result.errors
					// @ts-expect-error - TS7006 - Parameter 'error' implicitly has an 'any' type.
					.map((error) => error.message)
					// @ts-expect-error - TS7006 - Parameter 'acc' implicitly has an 'any' type. | TS7006 - Parameter 'current' implicitly has an 'any' type.
					.reduce((acc, current) => `${acc}\n ${current}`, '');
				throw Error(`Error retrieving url details from service: ${errorMessages}`);
			}
			return result;
		});

export const getHasDevelopmentDataInProject = (
	projectAri: string,
	updatedFrom: string,
	isJiraAdmin: boolean,
	isSiteAdmin: boolean,
): Promise<boolean> => {
	if (isJiraAdmin || isSiteAdmin) {
		const developmentDataQuery = graphql`
			query detailsHasDevelopmentDataInProjectQuery($projectAri: ID!, $updatedFrom: DateTime) {
				devOps {
					ariGraph {
						branchRelationships: relationships(
							filter: {
								from: $projectAri
								type: "project-associated-branch"
								updatedFrom: $updatedFrom
							}
							first: 1
						) {
							nodes {
								__typename
							}
						}
						buildRelationships: relationships(
							filter: {
								from: $projectAri
								type: "project-associated-build"
								updatedFrom: $updatedFrom
							}
							first: 1
						) {
							nodes {
								__typename
							}
						}
						prRelationships: relationships(
							filter: {
								from: $projectAri
								type: "project-associated-pr"
								updatedFrom: $updatedFrom
							}
							first: 1
						) {
							nodes {
								__typename
							}
						}
					}
				}
			}
		`;

		const variables = {
			projectAri,
			updatedFrom,
		};

		const request = fetchQuery(getRelayEnvironment(), developmentDataQuery, variables, {
			fetchPolicy: 'store-or-network',
		});

		/* fetchQuery will NOT retain the data for the query, meaning that it is not guaranteed that the
       data will remain saved in the Relay store at any point after the request completes. We need to
       explicitly retain the operation to ensure it doesn't get deleted. See https://relay.dev/docs/api-reference/fetch-query/#behavior */
		getRelayEnvironment().retain(
			createOperationDescriptor(DetailsHasDevelopmentDataInProjectQuery, variables),
		);

		return request.toPromise().then((result) => {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			const data = result as detailsHasDevelopmentDataInProjectQuery$data;
			return (
				(data?.devOps?.ariGraph?.branchRelationships?.nodes ?? []).length > 0 ||
				(data?.devOps?.ariGraph?.buildRelationships?.nodes ?? []).length > 0 ||
				(data?.devOps?.ariGraph?.prRelationships?.nodes ?? []).length > 0
			);
		});
	}
	return Promise.resolve(false);
};
