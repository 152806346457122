import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	triggerLabel: {
		id: 'issue-item-line-card.item-line-card.reorder-menu.trigger-label',
		defaultMessage: 'Reorder "{itemName}" {itemType}',
		description: 'Accessible label for the reorder dropdown menu trigger',
	},
	moveUp: {
		id: 'issue-item-line-card.item-line-card.reorder-menu.move-up',
		defaultMessage: 'Move up',
		description: 'Dropdown menu item. Move the list item up the list by one',
	},
	moveDown: {
		id: 'issue-item-line-card.item-line-card.reorder-menu.move-down',
		defaultMessage: 'Move down',
		description: 'Dropdown menu item. Move the list item down the list by one',
	},
});
