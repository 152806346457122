/**
 * @generated SignedSource<<4e41b4119f87d681177f2463cd9f0f94>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutRadioSelectField_IssueViewRadioSelectField$data = {
  readonly __typename: "JiraRadioSelectField";
  readonly description: string | null | undefined;
  readonly fieldConfig: {
    readonly isEditable: boolean | null | undefined;
    readonly isRequired: boolean | null | undefined;
  } | null | undefined;
  readonly fieldId: string;
  readonly fieldOptions: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly isDisabled: boolean | null | undefined;
        readonly optionId: string;
        readonly value: string;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly id: string;
  readonly issue: {
    readonly key: string;
  } | null | undefined;
  readonly name: string;
  readonly selectedOption: {
    readonly id: string;
    readonly optionId: string;
    readonly value: string | null | undefined;
  } | null | undefined;
  readonly type: string;
  readonly " $fragmentType": "ui_issueViewLayoutRadioSelectField_IssueViewRadioSelectField";
};
export type ui_issueViewLayoutRadioSelectField_IssueViewRadioSelectField$key = {
  readonly " $data"?: ui_issueViewLayoutRadioSelectField_IssueViewRadioSelectField$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutRadioSelectField_IssueViewRadioSelectField">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "id"
},
v1 = {
  "kind": "ScalarField",
  "name": "value"
},
v2 = {
  "kind": "ScalarField",
  "name": "optionId"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutRadioSelectField_IssueViewRadioSelectField",
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "kind": "ScalarField",
      "name": "description"
    },
    {
      "kind": "ScalarField",
      "name": "__typename"
    },
    {
      "concreteType": "JiraFieldConfig",
      "kind": "LinkedField",
      "name": "fieldConfig",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isEditable"
        },
        {
          "kind": "ScalarField",
          "name": "isRequired"
        }
      ]
    },
    {
      "concreteType": "JiraIssue",
      "kind": "LinkedField",
      "name": "issue",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "key"
        }
      ]
    },
    {
      "concreteType": "JiraOption",
      "kind": "LinkedField",
      "name": "selectedOption",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/)
      ]
    },
    {
      "concreteType": "JiraOptionConnection",
      "kind": "LinkedField",
      "name": "fieldOptions",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraOptionEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraOption",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "RequiredField",
                  "field": (v0/*: any*/),
                  "action": "NONE",
                  "path": "fieldOptions.edges.node.id"
                },
                {
                  "kind": "RequiredField",
                  "field": (v1/*: any*/),
                  "action": "NONE",
                  "path": "fieldOptions.edges.node.value"
                },
                {
                  "kind": "ScalarField",
                  "name": "isDisabled"
                },
                (v2/*: any*/)
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraRadioSelectField"
};
})();

(node as any).hash = "6f0baaf24f87719999beab805248015a";

export default node;
