/**
 * @generated SignedSource<<552969473bf5ea7ea7e3e9b5568d046b>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutProjectField_IssueViewProjectField$data = {
  readonly __typename: "JiraProjectField";
  readonly fieldId: string;
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"project_issueFieldProjectInlineEditFull_ProjectInlineEditView_fragmentRef" | "ui_issueViewLayoutFieldHeading_IssueViewFieldHeading">;
  readonly " $fragmentType": "ui_issueViewLayoutProjectField_IssueViewProjectField";
};
export type ui_issueViewLayoutProjectField_IssueViewProjectField$key = {
  readonly " $data"?: ui_issueViewLayoutProjectField_IssueViewProjectField$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutProjectField_IssueViewProjectField">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutProjectField_IssueViewProjectField",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "ui_issueViewLayoutFieldHeading_IssueViewFieldHeading"
    },
    {
      "kind": "FragmentSpread",
      "name": "project_issueFieldProjectInlineEditFull_ProjectInlineEditView_fragmentRef"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "ScalarField",
      "name": "__typename"
    }
  ],
  "type": "JiraProjectField"
};

(node as any).hash = "05a4745b5d708184df379d38a65e0445";

export default node;
