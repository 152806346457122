/**
 * @generated SignedSource<<66efe47e6c13c2e4e9ed6ba37fbaf256>>
 * @relayHash 0d544872218143a14ee7168de06f6442
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID d39d0d1cefd6375e0d72faf04965fb0bbaaddbd420bfe52380f499140d83f0f7

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraAttachmentSortField = "CREATED" | "%future added value";
export type SortDirection = "ASC" | "DESC" | "%future added value";
export type JiraAttachmentSortInput = {
  field: JiraAttachmentSortField;
  order?: SortDirection;
};
export type ui_issueViewBase_AttachmentFilmstripRelayQuery$variables = {
  cloudId: string;
  first?: number | null | undefined;
  issueKey: string;
  sortBy?: JiraAttachmentSortInput | null | undefined;
};
export type ui_issueViewBase_AttachmentFilmstripRelayQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewBase_AttachmentFilmstripRelayFragment">;
};
export type ui_issueViewBase_AttachmentFilmstripRelayQuery = {
  response: ui_issueViewBase_AttachmentFilmstripRelayQuery$data;
  variables: ui_issueViewBase_AttachmentFilmstripRelayQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueKey"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sortBy"
},
v4 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v5 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v6 = {
  "kind": "Variable",
  "name": "issueKey",
  "variableName": "issueKey"
},
v7 = {
  "kind": "Variable",
  "name": "sortBy",
  "variableName": "sortBy"
},
v8 = {
  "kind": "ScalarField",
  "name": "clientId"
},
v9 = {
  "kind": "ScalarField",
  "name": "endpointUrl"
},
v10 = {
  "kind": "ScalarField",
  "name": "token"
},
v11 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v12 = [
  (v5/*: any*/),
  (v7/*: any*/)
],
v13 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "name": "ui_issueViewBase_AttachmentFilmstripRelayQuery",
    "selections": [
      {
        "args": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "ui_issueViewBase_AttachmentFilmstripRelayFragment"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "ui_issueViewBase_AttachmentFilmstripRelayQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": [
              (v4/*: any*/),
              {
                "kind": "Variable",
                "name": "key",
                "variableName": "issueKey"
              }
            ],
            "concreteType": "JiraIssue",
            "kind": "LinkedField",
            "name": "issueByKey",
            "plural": false,
            "selections": [
              {
                "args": [
                  {
                    "kind": "Literal",
                    "name": "maxTokenLength",
                    "value": 65536
                  }
                ],
                "concreteType": "JiraMediaReadToken",
                "kind": "LinkedField",
                "name": "mediaReadToken",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "tokenLifespanInSeconds"
                  },
                  {
                    "concreteType": "JiraMediaTokenWithFilesConnection",
                    "kind": "LinkedField",
                    "name": "tokensWithFiles",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraMediaTokenWithFilesEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "concreteType": "JiraMediaTokenWithFiles",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v10/*: any*/)
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ],
                "storageKey": "mediaReadToken(maxTokenLength:65536)"
              },
              {
                "kind": "LinkedField",
                "name": "mediaUploadToken",
                "plural": false,
                "selections": [
                  (v11/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v9/*: any*/),
                      (v8/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "targetCollection"
                      },
                      (v10/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "tokenDurationInMin"
                      }
                    ],
                    "type": "JiraMediaUploadToken"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": "errorId",
                        "kind": "ScalarField",
                        "name": "identifier"
                      },
                      {
                        "alias": "errorMessage",
                        "kind": "ScalarField",
                        "name": "message"
                      }
                    ],
                    "type": "QueryError"
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "name": "key"
              },
              {
                "alias": "canDeleteAllAttachments",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "permission",
                    "value": "DELETE_ALL_ATTACHMENTS"
                  }
                ],
                "kind": "ScalarField",
                "name": "hasProjectPermission",
                "storageKey": "hasProjectPermission(permission:\"DELETE_ALL_ATTACHMENTS\")"
              },
              {
                "alias": "canDeleteOwnAttachments",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "permission",
                    "value": "DELETE_OWN_ATTACHMENTS"
                  }
                ],
                "kind": "ScalarField",
                "name": "hasProjectPermission",
                "storageKey": "hasProjectPermission(permission:\"DELETE_OWN_ATTACHMENTS\")"
              },
              {
                "args": (v12/*: any*/),
                "concreteType": "JiraAttachmentConnection",
                "kind": "LinkedField",
                "name": "attachments",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraAttachmentEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v11/*: any*/),
                          {
                            "kind": "TypeDiscriminator",
                            "abstractKey": "__isJiraAttachment"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "attachmentId"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "mediaApiFileId"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "created"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "parentId"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "parentName"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "hasRestrictedParent"
                          },
                          {
                            "kind": "LinkedField",
                            "name": "author",
                            "plural": false,
                            "selections": [
                              (v11/*: any*/),
                              {
                                "kind": "ScalarField",
                                "name": "accountId"
                              },
                              (v13/*: any*/)
                            ]
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v13/*: any*/)
                            ],
                            "type": "Node",
                            "abstractKey": "__isNode"
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "name": "cursor"
                      }
                    ]
                  },
                  {
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "endCursor"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "hasNextPage"
                      }
                    ]
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "name": "totalCountResolver",
                        "args": [
                          (v6/*: any*/)
                        ],
                        "kind": "RelayResolver",
                        "isOutputType": true
                      }
                    ]
                  }
                ]
              },
              {
                "args": (v12/*: any*/),
                "filters": [
                  "sortBy"
                ],
                "handle": "connection",
                "key": "issue_attachments",
                "kind": "LinkedHandle",
                "name": "attachments"
              },
              (v13/*: any*/),
              {
                "kind": "ClientExtension",
                "selections": [
                  {
                    "name": "cardCoverResolver",
                    "kind": "RelayResolver",
                    "isOutputType": true
                  },
                  {
                    "name": "isMobileResolver",
                    "kind": "RelayResolver",
                    "isOutputType": true
                  },
                  {
                    "name": "isPreviewResolver",
                    "kind": "RelayResolver",
                    "isOutputType": true
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "d39d0d1cefd6375e0d72faf04965fb0bbaaddbd420bfe52380f499140d83f0f7",
    "metadata": {},
    "name": "ui_issueViewBase_AttachmentFilmstripRelayQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "524374d8c252dc47b0bd0eee47f12d60";

export default node;
