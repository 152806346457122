import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	label: {
		id: 'issue-view-foundation.quick-add.add-form-button.add-form-button-view.label',
		defaultMessage: 'Add form',
		description: 'The label to be used on the quick add button for adding a form to an issue',
	},
	dropdownLabel: {
		id: 'issue-view-foundation.quick-add.add-form-button.add-form-button-view.dropdown-label',
		defaultMessage: 'Form',
		description:
			'The label to be used on the quick add button within quick actions dropdown for adding a form to an issue',
	},
	tooltip: {
		id: 'issue-view-foundation.quick-add.add-form-button.add-form-button-view.tooltip',
		defaultMessage: 'Add Form',
		description: 'The tooltip to be used on the quick add button for adding a form to an issue',
	},
});
