import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	editStatus: {
		id: 'issue-field-status.status-view.command-palette.edit-status',
		defaultMessage: 'Edit status',
		description: 'Command name in the command palette to edit the status of an issue',
	},
	searchTransition: {
		id: 'issue-field-status.status-view.command-palette.search-transition',
		defaultMessage: 'Search a status or transition',
		description: 'Command name in the command palette to change the status of an issue',
	},
	current: {
		id: 'issue-field-status.status-view.command-palette.current',
		defaultMessage: 'Current',
		description: 'Text for Lozenge that signals that this is the current status of an issue',
	},
	fallbackNoResults: {
		id: 'issue-field-status.status-view.command-palette.fallback-no-results',
		defaultMessage: 'No results match your search.',
		description: 'Message displayed in the command palette when the search returns no results',
	},
	fallbackError: {
		id: 'issue-field-status.status-view.command-palette.fallback-error',
		defaultMessage: "We're having trouble loading this data. Wait a few moments, then try again.",
		description:
			'When there is an error loading the commands in the command palette, this message will be displayed',
	},
});
