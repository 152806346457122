import parseISO from 'date-fns/parseISO';
import toDate from 'date-fns/toDate';
// IE 11 and Safari can't recognize date format '2013-04-19T18:26:46.396+0000' provided by JIRA
// so we convert it to format '2013-05-03T08:36:14.787Z'
// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (dateString: Date | string | number) => {
	if (dateString === null) return dateString;

	const parsedDate = typeof dateString === 'string' ? parseISO(dateString) : toDate(dateString);

	return parsedDate.toISOString();
};
