/** @jsx jsx */
import React, { type PropsWithChildren } from 'react';
import { css, jsx } from '@compiled/react';
// eslint-disable-next-line jira/restricted/styled-components-migration, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';

const StyleWrapper = ({ children }: PropsWithChildren) => {
	return fg('jfp-1020-use-compiled') ? (
		<div css={styles}>{children}</div>
	) : (
		<OldStyleWrapper>{children}</OldStyleWrapper>
	);
};

const styles = css({
	lineHeight: token('space.300'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	pre: {
		overflow: 'auto',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	a: {
		color: token('color.link'),
	},
	marginTop: '0',
	marginBottom: '0',
	overflow: 'hidden',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const OldStyleWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	lineHeight: `${gridSize * 3}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	pre: {
		overflow: 'auto',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	a: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		color: token('color.link', colors.B400),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'a:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		color: token('color.link', colors.B300),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':first-child': {
		marginTop: 0,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':last-child': {
		marginBottom: 0,
	},
	overflow: 'hidden',
});

export default StyleWrapper;
