export const COMMENT = 'comment';
export const LOG_WORK = 'logWork';
export const ATTACH_FILES = 'attachFiles';
export const CREATE_SUBTASK = 'createSubtask';
export const CONVERT_SUBTASK = 'convertSubtask';
export const MOVE = 'move';
export const CLONE = 'clone';

// TODO: add after watcher package is refactored into issue package: BENTO-6977
// export const TOGGLE_WATCH = 'toggleWatching';
// export const WATCHERS = 'watcher';

// Old issue view action only
export const AGILE_BOARD = 'agileBoard';
export const RANK_TOP = 'rankToTop';
export const RANK_BOTTOM = 'rankToBottom';
export const ADD_FIELD = 'addField';
export const PERMISSION_HELPER = 'permissionHelper';
export const NOTIFICATION_HELPER = 'notificationHelper';

export const ASSIGN_TO_ME_ID = 'assign-to-me';
export const ADD_COMMENT_ID = 'add-comment';
export const ISSUE_VIEW_TRANSITION_SHORTCUT = 'issue-view-transition-register-shortcuts';
