import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const QuickApprovalsButtonEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(/* webpackChunkName: "async-jira-issue-view-quick-approvals-button" */ './index').then(
			({ QuickApprovalButton }) => QuickApprovalButton,
		),
	),
	getPreloadProps: () => ({}),
});
