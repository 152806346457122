// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	createChildErrorTitle: {
		id: 'issue.views.common.child-issues-panel.issues.create-child-error-title',
		defaultMessage: "We couldn't create the child issue",
		description: 'Heading of inline error message',
	},
	createSubtaskErrorTitle: {
		id: 'issue.views.common.child-issues-panel.issues.create-subtask-error-title',
		defaultMessage: "We couldn't create the subtask",
		description: 'Heading of inline error message',
	},
	createErrorBody: {
		id: 'issue.views.common.child-issues-panel.issues.create-error-body',
		defaultMessage: 'Check your connection, then give it another try',
		description: 'Body of inline error message',
	},
	linkIssueErrorTitle: {
		id: 'issue.views.common.child-issues-panel.issues.link-issue-error-title',
		defaultMessage: "We couldn't link the child issue",
		description: 'Heading of inline error message',
	},
	linkIssueErrorBody: {
		id: 'issue.views.common.child-issues-panel.issues.link-issue-error-body',
		defaultMessage: 'Check your connection, then give it another try',
		description: 'Body of inline error message',
	},
	restrictedReorderingMessage: {
		id: 'issue.views.common.child-issues-panel.issues.restricted-reordering-message',
		defaultMessage: "To order these issues manually, choose 'Order by rank'",
		description:
			'Message displayed to the user explaining they have to change the sorting order before manually sorting issues',
	},
	restrictedReorderingExceededLimitMessage: {
		id: 'issue.views.common.child-issues-panel.issues.restricted-reordering-exceeded-limit-message',
		defaultMessage: 'To order more than 100 child issues, select ... -> View in search',
		description:
			'Message displayed to the user that reordering child issues is disabled when over the child issues limit',
	},
	createChildErrorTitleIssueTermRefresh: {
		id: 'issue.views.common.child-issues-panel.issues.create-child-error-title-issue-term-refresh',
		defaultMessage: "We couldn't create the child issue",
		description: 'Heading of inline error message',
	},
	linkIssueErrorTitleIssueTermRefresh: {
		id: 'issue.views.common.child-issues-panel.issues.link-issue-error-title-issue-term-refresh',
		defaultMessage: "We couldn't link the child issue",
		description: 'Heading of inline error message',
	},
	restrictedReorderingMessageIssueTermRefresh: {
		id: 'issue.views.common.child-issues-panel.issues.restricted-reordering-message-issue-term-refresh',
		defaultMessage: "To order these issues manually, choose 'Order by rank'",
		description:
			'Message displayed to the user explaining they have to change the sorting order before manually sorting issues',
	},
	restrictedReorderingExceededLimitMessageIssueTermRefresh: {
		id: 'issue.views.common.child-issues-panel.issues.restricted-reordering-exceeded-limit-message-issue-term-refresh',
		defaultMessage: 'To order more than 100 child issues, select ... -> View in search',
		description:
			'Message displayed to the user that reordering child issues is disabled when over the child issues limit',
	},
});
