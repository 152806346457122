import React from 'react';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import {
	applicationKeySelector,
	baseUrlSelector,
	localeSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import { toBaseUrl } from '@atlassian/jira-shared-types/src/general.tsx';
import type { StateProps } from './types.tsx';
import FeedbackView from './view.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { canRenderFeedbackForApplication } from './view';

const Feedback = connect(
	(state: State): StateProps => ({
		baseUrl: toBaseUrl(baseUrlSelector(state)),
		locale: localeSelector(state),
		applicationKey: applicationKeySelector(state),
	}),
	{},
)(FeedbackView);
export default Feedback;

export const renderFeedback = (applicationKeys: string | null | undefined, isCompact = true) => (
	<Feedback isCompact={isCompact} />
);
