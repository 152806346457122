import React, { type MouseEvent } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { InlineSummaryRead } from '@atlassian/jira-issue-field-summary/src/ui/view/inline-read/index.tsx';

type Props = {
	isInlineEditEnabled: boolean;
	issueLink?: string;
	value?: string;
	onClick?: (event: MouseEvent) => void;
	showHoverPreviewEnabled?: boolean;
};

export const SummaryReadOnly = ({
	value,
	isInlineEditEnabled,
	issueLink,
	onClick,
	showHoverPreviewEnabled,
}: Props) => (
	<SummaryWrapper
		// eslint-disable-next-line jira/integration/test-id-by-folder-structure
		data-testid="issue.issue-view.views.common.issue-line-card.issue-line-card-view.summary"
	>
		<InlineSummaryRead
			onClick={onClick}
			isEditable={false}
			value={value ?? null}
			isInlineEditEnabled={isInlineEditEnabled}
			url={issueLink}
			showHoverPreviewEnabled={showHoverPreviewEnabled}
		/>
	</SummaryWrapper>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, , @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SummaryWrapper = styled.div({
	marginLeft: token('space.100', '8px'),
	flex: 1,
	overflow: 'hidden',
	marginRight: token('space.200', '16px'),
});
