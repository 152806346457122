import React, { type ReactNode, type FC, useState, useEffect } from 'react';
import { messages as feedbackMessages } from '@atlaskit/feedback-collector/messages';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	EntryKeys,
	ENTRYPOINT_ID_JIRA_ISSUE_VIEW,
} from '@atlassian/jira-feedback-collector/src/constants.tsx';
import {
	type actions,
	FeedbackActionsSubscriber,
} from '@atlassian/jira-feedback-collector/src/controllers/index.tsx';

import type { FeedbackCollectorProps } from '@atlassian/jira-feedback-collector/src/types.tsx';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import { useIsConcealActionsInMeatballExpEnabled } from '@atlassian/jira-issue-view-common/src/utils/use-is-conceal-actions-experiment-enabled/index.tsx';
import normaliseUrl from '@atlassian/jira-platform-url-normalisation/src/index.tsx';
import type { RefObject } from '@atlassian/jira-shared-types/src/general.tsx';
import type { LicensedProducts } from '@atlassian/jira-shared-types/src/tenant-context.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import BentoFeedbackTriggerButton from '../button-unified/index.tsx';
import messages from '../messages.tsx';
import type { Props } from '../types.tsx';
import { ContactLabel } from './contact-label.tsx';
import { ResearchLabel } from './research-label.tsx';
import { FeedbackTitle } from './title.tsx';

type Actions = typeof actions;

type FeedbackButtonProps = { tooltip: ReactNode } & Props;

type AccountDetails = { emailAddress: string; displayName: string };
type LicensedProductsType = (arg1: LicensedProducts) => string;

const licensedProductsToString: LicensedProductsType = (licensedProducts) =>
	Object.keys(licensedProducts)
		.map((productKey) => (licensedProducts[productKey] ? productKey : ''))
		.join(', ');

export const FeedbackButton: FC<FeedbackButtonProps> = (props: FeedbackButtonProps) => {
	const { formatMessage } = useIntl();
	const [accountDetails, setAccountDetails] = useState<AccountDetails | undefined>();
	const { atlassianAccountId, isAdmin, licensedProducts } = useTenantContext();

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const issueRemovedFromUrl = normaliseUrl(document?.location.href);

	const isConcealActionsExpEnabled = fg('_conceal_items_into_meatball_menu')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useIsConcealActionsInMeatballExpEnabled()
		: false;

	useEffect(() => {
		if (atlassianAccountId) {
			fetchJson(`/rest/api/3/user?accountId=${encodeURIComponent(atlassianAccountId)}`)
				.then((response) =>
					setAccountDetails({
						emailAddress: response.emailAddress,
						displayName: response.displayName,
					}),
				)
				.catch((error) =>
					log.safeErrorWithoutCustomerData('nav-v3.opt-out.feedback.collector.user-request', error),
				);
		}
	}, [atlassianAccountId]);

	const feedbackPayload: FeedbackCollectorProps = {
		feedbackCollectorId: props.collectorId,
		feedbackGroupId: props.feedbackGroupId,
		entrypointId: ENTRYPOINT_ID_JIRA_ISSUE_VIEW,
		cancelButtonLabel: formatMessage(messages.cancelButtonCaption),
		feedbackTitle: formatMessage(messages.feedbackTitle),
		feedbackTitleDetails: <FeedbackTitle />,
		email: accountDetails?.emailAddress,
		canBeContactedLabel: <ContactLabel />,
		enrolInResearchLabel: <ResearchLabel />,
		feedbackContext: [
			// We will include the older values for migration purposes, Some of these should be in fields array by themselves when fully migrated.
			{ id: 'Location', value: issueRemovedFromUrl },
			{
				id: 'User-Agent',
				value: fg('jfp-magma-undefined-navigator')
					? globalThis.navigator?.userAgent
					: window.navigator.userAgent, // eslint-disable-line jira/jira-ssr/no-unchecked-globals-usage
			},
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			{ id: 'Screen Resolution', value: `${window.screen.width} x ${window.screen.height}` },
			{ id: EntryKeys.IS_ADMIN, value: isAdmin },
			{ id: EntryKeys.FEEDBACK_COLLECTOR_LOCATION, value: props.feedbackGroupId },
			{
				id: EntryKeys.SUPPORTED_PROJECT_TYPES,
				value: licensedProductsToString(licensedProducts),
			},
			{ id: EntryKeys.FEEDBACK_COLLECTOR_ID, value: props.collectorId },
		],
		selectLabel: formatMessage(messages.selectFeedbackCaption),

		customFeedbackOptions: [
			{
				label: formatMessage(feedbackMessages.selectionOptionCommentLabel),
				value: 'comment',
			},
			{
				label: formatMessage(feedbackMessages.selectionOptionSuggestionLabel),
				value: 'suggestion',
			},
		],
	};

	return (
		<>
			{/* @ts-expect-error - TS2745 - This JSX tag's 'children' prop expects type 'never' which requires multiple children, but only a single child was provided. */}
			<FeedbackActionsSubscriber>
				{/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
				{(_: any, { openFeedbackCollector }: Actions) => (
					<FormattedMessage
						id="software-issue.labs-feature-button-text"
						defaultMessage="Give feedback"
					>
						{(buttonText: ReactNode[]) => (
							<BentoFeedbackTriggerButton
								onClick={(ref: RefObject<HTMLButtonElement>) => {
									openFeedbackCollector({
										...feedbackPayload,
										triggerRef: ref,
									});
								}}
								{...(props.isCompact
									? { iconLabel: String(buttonText), tooltip: props.tooltip }
									: { children: buttonText })}
								isCompact={props.isCompact}
								isMeatballButton={isConcealActionsExpEnabled && props.isMeatballButton}
							/>
						)}
					</FormattedMessage>
				)}
			</FeedbackActionsSubscriber>
		</>
	);
};
