import type { Context } from '../../types.tsx';

export const SET_CONTEXT = 'SET_RELEASES_CONTEXT' as const;

export const setContext = (context: Context) => ({
	type: SET_CONTEXT,
	payload: context,
});

export type SetContextAction = ReturnType<typeof setContext>;
