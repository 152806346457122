/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//Slovak
export default {
	'fabric.elements.share.copied.to.clipboard.message': 'Prepojenie sa skopírovalo do schránky',
	'fabric.elements.share.copylink.button.text': 'Kopírovať prepojenie',
	'fabric.elements.share.failure.icon.label': 'Ikona chyby',
	'fabric.elements.share.failure.message': 'Zdieľanie nie je možné',
	'fabric.elements.share.form.comment.placeholder': 'Pridať správu',
	'fabric.elements.share.form.retry': 'Opakovať',
	'fabric.elements.share.form.send': 'Odoslať',
	'fabric.elements.share.form.title': 'Zdieľanie',
	'fabric.elements.share.form.user-picker.add-more': 'Zadajte ďalšie položky',
	'fabric.elements.share.form.user-picker.no-options.existingUserOnly':
		'Nepodarilo sa nájsť žiadne výsledky pre „{inputValue}“.',
	'fabric.elements.share.form.user-picker.no-options.generic':
		'Nepodarilo sa nájsť žiadne výsledky pre „{inputValue}“. Pozvite ľudí pomocou e-mailovej adresy.',
	'fabric.elements.share.form.user-picker.placeholder.existingUserOnly': 'Zadajte meno alebo tím',
	'fabric.elements.share.form.user-picker.placeholder.generic':
		'Zadajte meno, skupinu, tím alebo e-mail',
	'fabric.elements.share.form.user-picker.placeholder.jira': 'Zadajte meno, tím alebo e-mail',
	'fabric.elements.share.form.user-picker.validation.required':
		'Vyberte aspoň jedného používateľa, skupinu, tím alebo e-mail.',
	'fabric.elements.share.form.user-picker.validation.required.jira':
		'Vyberte aspoň jedného používateľa, tím alebo e-mail.',
	'fabric.elements.share.success.message':
		'{object, select, blogpost {Blogový príspevok sa zdieľal} board {Nástenka sa zdieľala} calendar {Kalendár sa zdieľal} draft {Koncept sa zdieľal} filter {Filter sa zdieľal} issue {Problém sa zdieľal} media {Mediálny súbor sa zdieľal} page {Stránka sa zdieľala} project {Projekt sa zdieľal} pullrequest {Požiadavka na stiahnutie a posúdenie sa zdieľala} question {Otázka sa zdieľala} report {Zostava sa zdieľala} repository {Repozitár sa zdieľal} request {Žiadosť sa zdieľala} roadmap {Plán sa zdieľal} site {Web sa zdieľal} space {Priestor sa zdieľal} other {Prepojenie sa zdieľalo}}',
	'fabric.elements.share.trigger.button.icon.label': 'Ikona zdieľania',
	'fabric.elements.share.trigger.button.invite.text': 'Pozvať',
	'fabric.elements.share.trigger.button.text': 'Zdieľať',
	'fabric.elements.share.trigger.button.tooltip.text': 'Zdieľanie',
};
