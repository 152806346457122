// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	startTour: {
		id: 'issue.start-changeboarding-tour',
		defaultMessage: 'Take a tour',
		description: 'Label for the issue actions item which starts the changeboarding tour',
	},
});
