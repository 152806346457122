import { logPolarisError } from '../../../../common/utils/errors/index.tsx';

export const VIEW_ERROR_PREFIX = 'controllers.view';

/**
 * Logs polaris errors to the backend that identify as originating in this controller.
 * NOTE: use without customer data
 */
export const logViewError = (postfix: string, error: Error) => {
	logPolarisError(`${VIEW_ERROR_PREFIX}.${postfix}`, error);
};
