import { projectTypeToCanonicalId } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import {
	useProjectKey,
	useProjectType,
} from '@atlassian/jira-project-context-service/src/main.tsx';

export type SubProductId =
	| 'bitbucket'
	| 'confluence'
	| 'jira-core'
	| 'jira-servicedesk'
	| 'jira-customer-service'
	| 'jira-software'
	| 'jira-product-discovery'
	| 'jira'
	| 'trello';

/**
 * @returns the subproduct id, for example, jira-software or jira-servicedesk
 */
export const useSubProductId = (): SubProductId => {
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const projectType = useProjectType(projectKey);

	return projectTypeToCanonicalId(projectType ?? null);
};
