import React, { useCallback, type SyntheticEvent } from 'react';
import flow from 'lodash/flow';
import { graphql, useFragment } from 'react-relay';
import { useAnalyticsEvents, type UIAnalyticsEvent } from '@atlaskit/analytics-next';
import ComponentWithAnalytics from '@atlassian/jira-analytics-web-react/src/utils/component-with-analytics.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-analytics-web-react/src/utils/fire-track-event.tsx';
import { log } from '@atlassian/jira-common-util-logging/src/index.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import { TOGGLE_WATCHING_ERROR } from '@atlassian/jira-issue-view-common-constants/src/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import type { changeWatchState_issueViewWatchers_ChangeWatchWithRelay$key } from '@atlassian/jira-relay/src/__generated__/changeWatchState_issueViewWatchers_ChangeWatchWithRelay.graphql';
import type FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { useOnShowFlag } from '../../../../controllers/watchers-context/index.tsx';
import { useWatchesMutation } from '../../../../services/use-watches-mutation/index.tsx';
import type { State } from '../../../model/types.tsx';
import { toggleWatchingRequest } from '../../../state/actions.tsx';
import { getIsKeyboardShortcutEnabled, getIsWatching } from '../../../state/selectors.tsx';
import { DROPDOWN_SOURCE } from '../../../state/types.tsx';
import ChangeWatchState, { type Props } from './view.tsx';

const ChangeWatchWithRelay = ({
	watches,
	...props
}: Props & { watches: changeWatchState_issueViewWatchers_ChangeWatchWithRelay$key }) => {
	// eslint-disable-next-line @atlassian/relay/query-restriction
	const data = useFragment<changeWatchState_issueViewWatchers_ChangeWatchWithRelay$key>(
		graphql`
			fragment changeWatchState_issueViewWatchers_ChangeWatchWithRelay on JiraWatchesField {
				id
				type
				fieldId
				watch {
					isWatching
					count
					...useWatchesMutation_Mutation_Updatable
				}
			}
		`,
		watches,
	);

	const { onUpdate } = useWatchesMutation();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { onShowFlag } = useOnShowFlag();
	const onToggleWatching = useCallback(
		(
			_clickEvent: SyntheticEvent,
			onSuccess: (source: string, experience: string) => void,
			onError: (source: string, experience: string, error?: Error | FetchError) => void,
		) => {
			const isWatching = !data?.watch?.isWatching;
			const source = DROPDOWN_SOURCE;

			fireTrackAnalytics(
				createAnalyticsEvent({
					actionSubject: 'watchers',
				}),
				{
					actionSubjectId: 'watchersToggleWatching',
					action: isWatching ? 'startWatching' : 'stopWatching',
					attributes: {
						source,
					},
				},
			);

			const handleError = (error?: Error) => {
				onShowFlag?.(TOGGLE_WATCHING_ERROR);
				const err = error instanceof Error ? error : new Error('Error updating watchers');

				log.safeErrorWithoutCustomerData(
					isWatching ? 'issue.watchers.watch' : 'issue.watchers.unwatch',
					err.message,
					err,
				);
				onError?.(source, isWatching ? 'startWatching' : 'stopWatching', error);
			};

			const handleSuccess = () => {
				onSuccess?.(source, 'success');
			};
			onUpdate({
				id: data?.id,
				isWatching,
				onSuccess: handleSuccess,
				onError: handleError,
				watch: data.watch,
				field: {
					type: data.type,
					fieldId: data.fieldId,
					watch: {
						count: data.watch?.count,
						isWatching: data.watch?.isWatching,
					},
				},
			});
		},
		[createAnalyticsEvent, data, onShowFlag, onUpdate],
	);

	return (
		<ChangeWatchState
			{...props}
			isWatching={data?.watch?.isWatching || false}
			onToggleWatching={onToggleWatching}
		/>
	);
};

const View = componentWithCondition(
	() => fg('relay-migration-issue-header-and-parent'),
	ChangeWatchWithRelay,
	ChangeWatchState,
);

export default flow(
	ComponentWithAnalytics('watchers', {
		onToggleWatching: 'onToggleWatching',
	}),
	connect(
		(state: State, props) => ({
			isWatching: getIsWatching(state),
			isKeyboardShortcutEnabled: getIsKeyboardShortcutEnabled(state),
			...props,
		}),
		{
			onToggleWatching: (
				_clickEvent: unknown,
				onSuccess: (source: string, experience: string) => void,
				onError: (source: string, experience: string, error?: Error | FetchError) => void,
				analyticsEvent: UIAnalyticsEvent,
			) =>
				toggleWatchingRequest({
					analyticsEvent,
					source: DROPDOWN_SOURCE,
					onSuccess,
					onError,
				}),
		},
	),
)(View);
