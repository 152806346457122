import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

const SingleUserPickerEditViewEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() =>
			import(
				/* webpackChunkName: "async-single-user-picker-edit-view" */ './ui/single-user-picker'
			),
	),
	getPreloadProps: () => ({}),
});

export default SingleUserPickerEditViewEntryPoint;
