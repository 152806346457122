import { createSelector } from 'reselect';
import {
	SUMMARY_ITEMS,
	SUMMARY_ITEM_BUILD,
	SUMMARY_ITEM_REVIEW,
	SUMMARY_ITEM_DEPLOYMENT,
	type SUMMARY_ITEM,
	SUMMARY_ITEM_PULLREQUEST,
	SUMMARY_ITEM_COMMIT,
} from '@atlassian/jira-development-summary-common/src/common/constants.tsx';
import type {
	DevPanel,
	PanelState,
	Error,
	LegacyDevSummary,
	Summary,
	PullRequest,
	Review,
	Build,
	Commit,
	Deployment,
} from '../../../common/types.tsx';
import type { State } from '../../types.tsx';
import type { SummaryState } from '../types.tsx';

const entitiesSelector = (state: State): SummaryState => state.entities;

export const entitiesDevPanelSelector = createSelector(
	[entitiesSelector],
	(entities: SummaryState): DevPanel => entities.devPanel,
);

export const panelStateSelector = createSelector(
	entitiesDevPanelSelector,
	(devPanel: DevPanel): PanelState => devPanel.panelState,
);

export const summarySectionMessageSelector = createSelector(
	entitiesDevPanelSelector,
	(devPanel: DevPanel): boolean => devPanel.showSummarySectionMessage,
);

/* Summary selectors */

const hasSummaryItemWithPositiveCount = (summary: Summary): boolean =>
	SUMMARY_ITEMS.some(
		// @ts-expect-error - TS2532 - Object is possibly 'undefined'. | TS2532 - Object is possibly 'undefined'.
		(item) => summary[item] && summary[item].count != null && summary[item].count > 0,
	);

const hasItems = (items?: Error[] | null): boolean => !!(items && items.length);

const entitiesSummarySelector = createSelector(
	entitiesDevPanelSelector,
	(devPanel: DevPanel): Summary => devPanel.summary,
);

export const hasBranchCapabilitiesSelector = createSelector(
	entitiesDevPanelSelector,
	(devPanel: DevPanel): boolean => devPanel.hasBranchCapabilities,
);

export const branchSelector = createSelector(entitiesSummarySelector, (summary: Summary) => {
	if (summary.branch) {
		return summary.branch;
	}
	return null;
});
export const summaryItemSelector = createSelector(
	// @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'State'.
	(state) => entitiesSummarySelector(state),
	// @ts-expect-error - TS7006 - Parameter 'state' implicitly has an 'any' type.
	(state, itemType: SUMMARY_ITEM) => itemType,
	(summary: Summary, itemType: SUMMARY_ITEM) => {
		if (summary[itemType]) {
			return summary[itemType];
		}
		return null;
	},
);

export const reviewSummaryItemSelector = createSelector(
	entitiesSummarySelector,
	(summary: Summary): Review | null | undefined => summary[SUMMARY_ITEM_REVIEW],
);
export const buildSummaryItemSelector = createSelector(
	entitiesSummarySelector,
	(summary: Summary): Build | null | undefined => summary[SUMMARY_ITEM_BUILD],
);
export const deploymentSummaryItemSelector = createSelector(
	entitiesSummarySelector,
	(summary: Summary): Deployment | null | undefined => summary[SUMMARY_ITEM_DEPLOYMENT],
);
export const pullRequestSummaryItemSelector = createSelector(
	entitiesSummarySelector,
	(summary: Summary): PullRequest | null | undefined => summary[SUMMARY_ITEM_PULLREQUEST],
);

export const commitSummaryItemSelector = createSelector(
	entitiesSummarySelector,
	(summary: Summary): Commit | null | undefined => summary[SUMMARY_ITEM_COMMIT],
);
export const errorsSelector = createSelector(
	entitiesSummarySelector,
	(summary: Summary): Error[] | null | undefined => summary.errors,
);

export const configErrorsSelector = createSelector(
	entitiesSummarySelector,
	(summary: Summary): Error[] | null | undefined => summary.configErrors,
);

export const hasSummaryItemsSelector = createSelector(
	entitiesSummarySelector,
	(summary: Summary): boolean => !!hasSummaryItemWithPositiveCount(summary),
);

const hasSummaryErrorsSelector = createSelector(
	entitiesSummarySelector,
	(summary: Summary): boolean =>
		!!(summary && (hasItems(summary.errors) || hasItems(summary.configErrors))),
);

export const hasSummaryInformationSelector = createSelector(
	hasSummaryItemsSelector,
	hasSummaryErrorsSelector,
	(hasSummaryItems: boolean, hasSummaryErrors: boolean): boolean =>
		hasSummaryItems || hasSummaryErrors,
);

export const legacyDataInformationSelector = createSelector(
	entitiesSummarySelector,
	(summary: Summary): LegacyDevSummary | undefined =>
		summary && summary?.legacyResponseData?.summary,
);

export const hasBranchInformationSelector = createSelector(
	entitiesSummarySelector,
	(summary: Summary): boolean => !!summary.branch?.count,
);

export const hasPullRequestInformationSelector = createSelector(
	entitiesSummarySelector,
	(summary: Summary): boolean => !!summary.pullrequest?.count,
);

export const detailsHasInstanceErrorsSelector = createSelector(
	entitiesSummarySelector,
	(summary: Summary): boolean => !!summary.detailsHasInstanceErrors,
);

/* Empty state dialog selectors */
export const isEmptyStateDialogShownSelector = createSelector(
	entitiesSelector,
	(entities) => entities.isEmptyStateDialogShown,
);

/* Create branch selectors */
export const createBranchTargetsSelector = createSelector(
	entitiesSelector,
	(entities) => entities.createBranchTargets,
);

export const hasCreateBranchTargetsSelector = createSelector(
	createBranchTargetsSelector,
	(targets) => !!(targets && targets.length > 0),
);

export const hasDevelopmentDataInProjectSelector = createSelector(
	entitiesDevPanelSelector,
	(devPanel: DevPanel): boolean => devPanel.hasDevelopmentDataInProject,
);
