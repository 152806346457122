/**
 * @generated SignedSource<<618c32e9247eac0102ddf65ba736cd5b>>
 * @relayHash 86804d352bb1aea530a99372b60147a4
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID bf577d38a78dfef5a0e63d8814509ac3ed2cefa21159e2eaa282a3d327654200

import type { ConcreteRequest, Query } from 'relay-runtime';
export type uiMultiUserFieldSearchRefetchQuery$variables = {
  after?: string | null | undefined;
  first: number;
  id: string;
  searchBy: string;
};
export type uiMultiUserFieldSearchRefetchQuery$data = {
  readonly node: {
    readonly users?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly accountId: string;
          readonly email?: string | null | undefined;
          readonly id: string;
          readonly name: string;
          readonly picture: AGG$URL;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type uiMultiUserFieldSearchRefetchQuery = {
  response: uiMultiUserFieldSearchRefetchQuery$data;
  variables: uiMultiUserFieldSearchRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchBy"
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v5 = {
  "kind": "Variable",
  "name": "searchBy",
  "variableName": "searchBy"
},
v6 = {
  "kind": "ScalarField",
  "name": "id"
},
v7 = {
  "kind": "ScalarField",
  "name": "accountId"
},
v8 = {
  "kind": "ScalarField",
  "name": "name"
},
v9 = {
  "kind": "ScalarField",
  "name": "picture"
},
v10 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "email"
    }
  ],
  "type": "AtlassianAccountUser"
},
v11 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v12 = {
  "kind": "ScalarField",
  "name": "cursor"
},
v13 = {
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "endCursor"
    },
    {
      "kind": "ScalarField",
      "name": "hasNextPage"
    }
  ]
},
v14 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  (v5/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "name": "uiMultiUserFieldSearchRefetchQuery",
    "selections": [
      {
        "args": (v4/*: any*/),
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": "users",
                "args": [
                  (v5/*: any*/)
                ],
                "concreteType": "JiraUserConnection",
                "kind": "LinkedField",
                "name": "__multiUser_issueFieldMultiUser_users_connection",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraUserEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/),
                          (v11/*: any*/)
                        ]
                      },
                      (v12/*: any*/)
                    ]
                  },
                  (v13/*: any*/)
                ]
              }
            ],
            "type": "JiraMultipleSelectUserPickerField"
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "uiMultiUserFieldSearchRefetchQuery",
    "selections": [
      {
        "args": (v4/*: any*/),
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v11/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": (v14/*: any*/),
                "concreteType": "JiraUserConnection",
                "kind": "LinkedField",
                "name": "users",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraUserEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v11/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/)
                        ]
                      },
                      (v12/*: any*/)
                    ]
                  },
                  (v13/*: any*/)
                ]
              },
              {
                "args": (v14/*: any*/),
                "filters": [
                  "searchBy"
                ],
                "handle": "connection",
                "key": "multiUser_issueFieldMultiUser_users",
                "kind": "LinkedHandle",
                "name": "users"
              }
            ],
            "type": "JiraMultipleSelectUserPickerField"
          },
          (v6/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "id": "bf577d38a78dfef5a0e63d8814509ac3ed2cefa21159e2eaa282a3d327654200",
    "metadata": {
      "connection": [
        {
          "count": "first",
          "cursor": "after",
          "direction": "forward",
          "path": [
            "node",
            "users"
          ]
        }
      ]
    },
    "name": "uiMultiUserFieldSearchRefetchQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "ac77a1a658fa387e286d3f0ee012feac";

export default node;
