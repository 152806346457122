import React, { useMemo } from 'react';
import { ForgeScreenEvent } from '@atlassian/jira-forge-ui-analytics/src/common/ui/index.tsx';
import type { FieldType as ForgeFieldType } from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import SingleLineTextInlineEditView, {
	type FieldType,
	type NumberOrString,
} from '@atlassian/jira-issue-internal-field-single-line-text/src/single-line-text-inline-edit-view.tsx';
import { useAnalyticsAttributesContext } from '../../analytics/atrributes-context/index.tsx';
import type { CustomFieldProps } from '../../types.tsx';

const getFieldType = (forgeType?: ForgeFieldType): FieldType => {
	switch (forgeType) {
		case 'string':
			return 'text';
		case 'number':
			return forgeType;
		case 'object':
			return forgeType;
		default:
			return 'text';
	}
};

export const View = ({
	shouldFireScreenEvent,
	extension,
	...restProps
}: CustomFieldProps<NumberOrString>) => {
	const { type } = extension.properties;

	const analyticsAttributes = useAnalyticsAttributesContext();
	const attributes = useMemo(
		() => ({ ...analyticsAttributes, renderMode: 'default' }),
		[analyticsAttributes],
	);

	return (
		<>
			{shouldFireScreenEvent && <ForgeScreenEvent attributes={attributes} />}
			<SingleLineTextInlineEditView {...restProps} noValueText={null} type={getFieldType(type)} />
		</>
	);
};

export default View;
