export const LIMIT_ERROR_NAME = 'LimitError';

export class LimitError {
	name: string;

	message: string;

	errors: Error[];

	constructor(message: string, errors: Error[] = []) {
		this.name = LIMIT_ERROR_NAME;
		this.message = message;
		this.errors = errors;
	}
}
