import React from 'react';
import { expVal } from '@atlassian/jira-feature-experiments';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import { Emphasise } from '@atlassian/jira-issue-view-activity-common/src/styles/index.tsx';
import {
	fireOperationalAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import Change from '../../../../common/ui/change/index.tsx';
import GroupedChange from '../../../../common/ui/grouped-change/index.tsx';
import HistoryItem from '../../../../common/ui/history-item/index.tsx';
import type { RespondersChangedHistoryItem } from '../../../../types.tsx';
import messages from './messages.tsx';
import { RespondersList } from './responders-list/index.tsx';

type Props = {
	isGrouped?: Boolean;
	historyItem: RespondersChangedHistoryItem;
};

const RespondersHistoryItem = ({ historyItem, isGrouped = false }: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { actor, timestamp, fromValue, toValue } = historyItem;

	if (fromValue?.length === toValue?.length) {
		fireOperationalAnalytics(createAnalyticsEvent({}), 'respondersChangelogSameSize failed');
		return null;
	}

	const actionMessage =
		(fromValue || []).length > (toValue || []).length ? messages.removed : messages.added;

	const action = (
		<FormattedMessage
			{...actionMessage}
			values={{
				responders: (
					<Emphasise>
						{formatMessage(
							expVal('issue_view_activity_timeline', 'isActivityTimelineEnabled', false)
								? messages.respondersLowercase
								: messages.responders,
						)}
					</Emphasise>
				),
			}}
		/>
	);

	return isGrouped && expVal('issue_view_activity_timeline', 'isActivityTimelineEnabled', false) ? (
		<GroupedChange
			field={formatMessage(messages.responders)}
			from={fromValue != null ? <RespondersList responders={fromValue} /> : null}
			to={toValue != null ? <RespondersList responders={toValue} /> : null}
		/>
	) : (
		<HistoryItem
			testId="issue-history.ui.history-items.history-item.responders-history-item.history-item"
			actor={actor}
			action={action}
			timestamp={timestamp}
			change={
				<Change
					from={fromValue != null ? <RespondersList responders={fromValue} /> : null}
					to={toValue != null ? <RespondersList responders={toValue} /> : null}
				/>
			}
		/>
	);
};

export default RespondersHistoryItem;
