import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	confirmButtonLabel: {
		id: 'issue-field-inline-edit-lite.field-inline-edit-lite.confirm-button-label',
		defaultMessage: 'Confirm {fieldName}',
		description:
			'An accessibility label for a confirm button in an input field. The fieldName parameter refers to an optional heading for what the field is controlling. Pressing the button saves the contents of the input field.',
	},
	cancelButtonLabel: {
		id: 'issue-field-inline-edit-lite.field-inline-edit-lite.cancel-button-label',
		defaultMessage: 'Cancel {fieldName}',
		description:
			'An accessibility label for a cancel button in an input field. The fieldName parameter refers to an optional heading for what the field is controlling. Pressing the button reverts the contents of the input field to their previous value.',
	},
	editButtonLabel: {
		id: 'issue-field-inline-edit-lite.field-inline-edit-lite.edit-button-label',
		defaultMessage: 'Edit {fieldName}',
		description:
			'An accessibility label for an edit button in an input field. The fieldName parameter refers to an optional heading for what the field is controlling. Pressing the button allows the user to edit the contents of the input field.',
	},
});
