import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import { SpotlightTarget } from '@atlaskit/onboarding';
import { token } from '@atlaskit/tokens';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { AdditionalEventAttributes } from '@atlassian/jira-issue-view-layout-group/src/common/types.tsx';
import { CollapsibleGroup } from '@atlassian/jira-issue-view-layout-group/src/ui/index.tsx';
import { useProjectKey } from '@atlassian/jira-project-context-service/src/main.tsx';
import { ChangeBoarding } from './context/change-boarding/index.tsx';

export type ContextGroupProps = {
	subTitle?: ReactNode;
	children: ReactNode;
	groupId: string;
	title: string;
	initialOpened?: boolean;
	additionalEventAttributes?: AdditionalEventAttributes;
	onChange?: (value: boolean) => void;
	actionButton?: React.ComponentProps<typeof CollapsibleGroup>['actionButton'] | null;
};

export const ContextGroup = ({
	onChange,
	children,
	subTitle,
	groupId,
	title,
	initialOpened,
	additionalEventAttributes,
	actionButton,
}: ContextGroupProps) => {
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);

	/**
	 * When groupId is details-group Targeted with DETAILS_PANEL for the spotlight tour
	 * /src/packages/issue/onboarding-modal/src/common/constants.tsx
	 */
	const testId = `issue-view-layout-templates-views.ui.context.visible-hidden.ui.context-group.container.${groupId}`;

	const contextGroupContainerChildren = (
		<ContextGroupContainer data-testid={testId}>
			<CollapsibleGroup
				id={groupId}
				projectKey={projectKey}
				title={title}
				onChange={onChange}
				subTitle={subTitle}
				initialOpened={initialOpened}
				additionalEventAttributes={additionalEventAttributes}
				actionButton={actionButton}
			>
				<div
					data-testid={`issue-view-layout-templates-views.ui.context.visible-hidden.ui.context-group.${groupId}`}
				>
					{children}
				</div>
			</CollapsibleGroup>
		</ContextGroupContainer>
	);

	return <SpotlightTarget name={testId}>{contextGroupContainerChildren}</SpotlightTarget>;
};

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export default ChangeBoarding;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const ContextGroupContainer = styled.div({
	marginBottom: token('space.100', '8px'),
});
