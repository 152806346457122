import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button/index.tsx';
import connectField from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-field.tsx';
import { AsyncEntitlementsField } from '@atlassian/jira-servicedesk-customer-service-entitlements-field/src/async.tsx';

export default connectField((stateOnMount, ownPropsOnMount) => ({
	fieldId: ownPropsOnMount.fieldId,
	additionalProps: () => ({
		fieldId: ownPropsOnMount.fieldId,
		showPinButton: getShowPinButton(ownPropsOnMount.area),
	}),
}))(AsyncEntitlementsField);
