import { type Agent as RealAgent } from '@atlassian/conversation-assistant-service';

/**
 * This is similar to Agent from @atlassian/conversation-assistant-service,
 * but creating our own type as it seems to be out of sync with real data.
 * https://atlassian.slack.com/archives/C075C6PPJ01/p1717722104905379
 */
export type Agent = Omit<RealAgent, 'creator' | 'external_files' | 'available_plugins'> & {
	available_plugins: Record<string, unknown>;
	creator?: string | null;
	external_files?: RealAgent['external_files'] | null;
};

// The sample agent data was copied straight from poking around Rovo chat `AgentSelector` component props
export const ChatAgent: Agent = {
	actor_type: 'AGENT',
	available_plugins: {
		'Content-Read-Plugin': {},
		'Ethical-Filtering-Plugin': {},
		'Jira-JQL-Plugin': {},
		'Jira-NL-JQL-Plugin': {},
		'Jira-Software-Plugin': {},
		'Page-Search-Plugin': {},
		'People-Plugin': {},
		'Search-QA-Plugin': {},
	},
	creator: 'mock',
	creator_cloud_id: null,
	creator_type: 'SYSTEM',
	description:
		'Mocking: Get answers, generate new ideas, and accelerate everyday work. Write a prompt to get started.',
	external_files: null,
	follow_up_prompt_template:
		'\nYour task is to generate follow-up questions for the given conversation from the user\'s perspective.\nThe conversation history is provided in triple backticks.\n\nConversation History:\n```\n{message_history}\n```\n\nWhen you generate follow-up questions, follow these steps closely:\n1. Read the conversation history carefully to understand the user\'s intent. Keep it open and refer back to it as necessary.\n2. Pretend that you are the user and think about what you would ask next based on the conversation history and the original intent.\n3. Generate 3 follow-up questions from the perspective of the user that meet the following criteria.\n    - Relevant to the conversation.\n    - Unique and different from previously asked questions.\n    - Requests new information.\n    - Insightful and enhances the engagement of the conversation.\n    - Succinct and straight to the point.\n    - Specific and avoids being too general.\n    - Less than 9 words.\n    - When the subject of the question is the user asking questions, use the first-person pronoun "I".\n4. Read your follow-up questions again and remove the ones that do not meet ALL of the listed criteria.\n5. If there are no follow-up questions left, return an empty list.\n6. If there are follow-up questions left, paraphrase them to make them more concise.\n7. Return your paraphrased follow-up questions.\n',
	id: 'f3a534d9-1498-e0d4-8b50-e058a8fb8156',
	is_default: true,
	// model: null,
	name: 'Mock Chat',
	named_id: 'ai_mate_agent',
	plugin_routing_type: 'DEFAULT',
	// preferred_plugin: null,
	system_prompt_template:
		'\nYou are Rovo Chat, an AI assistant built by Atlassian, you can do everything in the list of functions available. \nTreat the human in a friendly and personalised way, if what they ask is completely nonsensical, ask for clarification.  \n\nFollowing are the details of the human user:\n\nName: {{ user_name }}\n{{ location_info }}\n\nCurrent Time: {{ current_time }}\n\n{{ browsing_context }}\n\nFollow these rules:\n- Respond with names in the following format [name](/people/id) when appropriate.\n- Always use a function to find information, even if you know the answer.\n- Prioritise the Search-QA-Plugin when the function to use is not obvious.\n- Never answer the user directly without results from a function, unless they asked to reformat an existing answer.\n- Do not use your pretrained knowledge to answer.\n- Do not return function details in your answer.\n- If the answer is not available from the current function, always call another function.\n',
	visibility: 'PUBLIC',
	user_defined_conversation_starters: [],
	favourite: false,
	deactivated: false,
};

export const CommsCrafterAgent: Agent = {
	actor_type: 'AGENT',
	available_plugins: {
		'Confluence-Suggestion-Plugin': {
			is_terminal: true,
			raise_no_answer_exception: true,
		},
		'Page-Search-Plugin': {},
	},
	creator: null,
	creator_cloud_id: null,
	creator_type: 'SYSTEM',
	description:
		"Craft and refine all things blogs, external comms, and announcements. Align with your brand's voice.",
	external_files: null,
	follow_up_prompt_template: null,
	id: '3506f1fa-13ab-0c9c-d645-f26c759ef903',
	is_default: false,
	// model: null,
	name: 'Comms crafter',
	named_id: 'confluence_reviewer_agent',
	plugin_routing_type: 'DEFAULT',
	// preferred_plugin: null,
	system_prompt_template:
		'You are the Comms crafter, a helpful assistant for reviewing Confluence documents. You are tasked with providing suggestions to improve a Confluence document.\n\nA user may highlight an important part of the document for you to focus on using a document selection.\n\nFollow these rules:\n- Always use a function to provide a suggested replacement for the current document selection when there is a suggested replacement.\n- If the user asks for similar documents, you rely on the document selection.\n- If there is a suggested replacement to a document selection, the final answer should be only a 1 sentence summary of the changes suggested, not the update itself.\n- Do not return function details in your answer.\n- Respond with names in the following format [name](/people/id) if at all possible.\n- Do not use your pretrained knowledge to answer.\n\nFollowing are the details of the users session:\n\nName: {{ user_name }}\nLocation: {{ location_info }}\nCurrent Time: {{ current_time }}\nDocument Selection: {{ additional_context.confluence.selection }}\nDocument: {{ additional_context.confluence.document }}\n',
	visibility: 'PRIVATE',
	user_defined_conversation_starters: [
		"Can you help me with this blog post I'm working on?",
		"Help me with this announcement I'm drafting.",
	],
	favourite: false,
	deactivated: false,
};

export const JobWriterAgent: Agent = {
	actor_type: 'AGENT',
	available_plugins: {
		'Confluence-Suggestion-Plugin': {},
		'Content-Read-Plugin': {},
		'Page-Search-Plugin': {},
	},
	creator: null,
	creator_cloud_id: null,
	creator_type: 'SYSTEM',
	description:
		'Crafts job descriptions that align with corporate guidelines and ensures they promote inclusivity and diversity.',
	external_files: null,
	follow_up_prompt_template: null,
	id: '5692d6dd-3045-499f-b08b-77ed2d253941',
	is_default: false,
	// model: 'gpt-3-5-turbo-atlassian-0125',
	name: 'Job writer',
	named_id: 'job_writer_agent',
	plugin_routing_type: 'DEFAULT',
	// preferred_plugin: null,
	system_prompt_template:
		'You are the Job writer agent, a helpful assistant for hiring managers and recruitment teams to standardize on job descriptions which follow diversity and inclusion guidelines. \nYou are tasked with providing suggestions to improve a Confluence document for job descriptions.\n\nA user may highlight an important part of the document for you to focus on using a document selection.\n\nFollow these rules:\n- You must always provide a suggested replacement for insertion when asked to create, insert or draft job descriptions, summaries or any unique content.\n- You must always use a function to provide a suggested replacement for the current document selection when there is a suggested replacement.\n- If the user asks for similar documents, you rely on the document selection.\n- If there is a suggested replacement to a document selection, the final answer should be only a 1 sentence summary of the changes suggested, not the update itself.\n- Do not return function details in your answer.\n- Respond with names in the following format [name](/people/id) if at all possible.\n- Do not use your pretrained knowledge to answer.\n\nFollowing are the details of the users session:\n\nName: {{ user_name }}\nLocation: {{ location_info }}\nCurrent Time: {{ current_time }}\nDocument Selection: {{ additional_context.confluence.selection }}\nDocument: {{ additional_context.confluence.document }}\nBrowsing Context: {{ browsing_context }}',
	visibility: 'PRIVATE',
	user_defined_conversation_starters: [],
	favourite: false,
	deactivated: false,
};

export const A11yMateAgent: Agent = {
	actor_type: 'AGENT',
	available_plugins: {
		'Search-QA-Plugin': {
			space_filter: ['A11YKB'],
		},
	},
	creator: 'ari:cloud:identity::user/557057:db25fe7e-23f8-4e0f-aa1d-74a8c29bbc10',
	creator_cloud_id: 'a436116f-02ce-4520-8fbb-7301462a1674',
	creator_type: 'CUSTOMER',
	description: 'Accessibility help by Central Accessibility Team',
	external_files: [],
	follow_up_prompt_template: null,
	id: 'a7f547de-aa39-4d25-9234-65ac13c19acd',
	is_default: false,
	// model: null,
	name: 'A11Y Mate Long Name for Selection Toolbar',
	named_id: 'A11Y Mate',
	plugin_routing_type: null,
	// preferred_plugin: null,
	system_prompt_template: '',
	visibility: 'PUBLIC',
	user_defined_conversation_starters: [
		'What can you help me with?',
		'What can you help me with?',
		'What can you help me with?',
	],
	favourite: false,
	deactivated: false,
};
