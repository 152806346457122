import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';

// This request only applies to classic issues
export const classicEpicLinkIssue = (baseUrl: string, parentId: string, issueId: IssueId) =>
	performPutRequest(`${baseUrl}/rest/greenhopper/1.0/epics/${parentId}/add`, {
		body: JSON.stringify({
			ignoreEpics: true,
			issueKeys: [issueId],
		}),
	});

export const nextGenLinkIssue = (baseUrl: string, parentId: string, issueId: IssueId) =>
	performPutRequest(`${baseUrl}/rest/api/3/issue/${issueId}`, {
		body: JSON.stringify({
			fields: {
				parent: { id: parentId },
			},
		}),
	});
