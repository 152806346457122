import get from 'lodash/get';
import { useEditField } from '@atlassian/jira-issue-field-base/src/services/edit-field-service/main.tsx';
import { useFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import { PROJECT_PICKER_CF_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { ProjectOption } from '../common/types.tsx';

type Props = {
	issueKey: IssueKey;
	fieldKey: string;
	onSuccess?: () => void;
	onFailure?: (arg1: Error) => void;
	initialValue?: ProjectOption | null;
};

export const useProjectField = ({
	issueKey,
	fieldKey,
	onSuccess,
	onFailure,
	initialValue = null,
}: Props) => {
	// useFieldConfig now returns an object { value, loading } instead of just the fieldConfig value
	// if possible when touching this, try and refactor to pass the new object instead of just the value
	const [{ value: fieldConfig }] = useFieldConfig(issueKey, fieldKey);
	const fieldType = get(fieldConfig, 'schema.type', PROJECT_PICKER_CF_TYPE);

	const [{ value, error }, { saveValue, resetError }] = useEditField<ProjectOption | null, null>({
		fieldKey,
		issueKey,
		fieldType,
		initialValue,
		onSuccess,
		onFailure,
	});

	return [
		{ value, error, fieldConfig },
		{ saveValue, resetError },
	] as const;
};
