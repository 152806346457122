// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	label: {
		id: 'issue.issue-links-add-label',
		defaultMessage: 'Link an issue',
		description: '',
	},
	labelIssueTermRefresh: {
		id: 'issue.issue-links-add-label-issue-term-refresh',
		defaultMessage: 'Link an issue',
		description: '',
	},
});
