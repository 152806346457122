import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	pending: {
		id: 'development-summary-deployment-releases-field.status.pending',
		defaultMessage: 'Pending',
		description: '',
	},
	inProgress: {
		id: 'development-summary-deployment-releases-field.status.in-progress',
		defaultMessage: 'In progress',
		description: '',
	},
	successful: {
		id: 'development-summary-deployment-releases-field.status.successful',
		defaultMessage: 'Successful',
		description: '',
	},
	failed: {
		id: 'development-summary-deployment-releases-field.status.failed',
		defaultMessage: 'Failed',
		description: '',
	},
	rolledBack: {
		id: 'development-summary-deployment-releases-field.status.rolled-back',
		defaultMessage: 'Rolled back',
		description: '',
	},
	cancelled: {
		id: 'development-summary-deployment-releases-field.status.cancelled',
		defaultMessage: 'Canceled',
		description: '',
	},
	unknown: {
		id: 'development-summary-deployment-releases-field.status.unknown',
		defaultMessage: 'Unknown',
		description: '',
	},
});
