import React from 'react';
import { Text, Box } from '@atlaskit/primitives';
import { AIOpsSendUnifiedAIEvent, fireAIOpsEvent } from '@atlassian/eoc-aiops-instrumentation';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { ENTRY_POINT_ID_AIOPS } from '@atlassian/jira-feedback-collector/src/constants.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { Feedback } from '@atlassian/jira-servicedesk-ai-context-common/src/ui/ai-context-container/feedback/index.tsx';
import { AIContextItem } from '@atlassian/jira-servicedesk-ai-context-common/src/ui/ai-context-item/index.tsx';
import { AIContextRenderer } from '@atlassian/jira-servicedesk-ai-context-common/src/ui/ai-context-renderer/index.tsx';
import type { IncidentSuggestionResource } from '../../common/types.tsx';
import { useOpsPanel } from '../../controllers/ops-panel/index.tsx';
import { AffectedServicesItem } from './action-item/affected-services/index.tsx';
import { MajorIncidentItem } from './action-item/major-incident/index.tsx';
import { PriorityItem } from './action-item/priority/index.tsx';
import { ProblemTicketItem } from './action-item/problem-ticket/index.tsx';
import { AutomationAction } from './action-item/rca-automation/index.tsx';
import { RespondersItem } from './action-item/responders/index.tsx';
import { SeverityItem } from './action-item/severity/index.tsx';

import messages from './messages.tsx';

type Props = {
	resource: IncidentSuggestionResource;
};

export const FEEDBACK_GROUP_ID = 'jira-servicedesk-ai-context-panel-ops';

export const OpsPanelContent = ({ resource }: Props) => {
	const { formatMessage } = useIntl();

	const [
		{ incidentSuggestion, topVisibleActions },
		{ onFeedbackClicked, onFeedbackSent, createOnRemoveAction },
	] = useOpsPanel(resource);

	return (
		<>
			<AIContextItem title={incidentSuggestion.incidentSummary.title}>
				<AIContextRenderer document={incidentSuggestion.incidentSummary.description} />
			</AIContextItem>
			{incidentSuggestion.probableRCA ? (
				<AIContextItem title={incidentSuggestion.probableRCA.title}>
					<AIContextRenderer document={incidentSuggestion.probableRCA.description} />
				</AIContextItem>
			) : null}
			<AIContextItem title={formatMessage(messages.suggestedActions)}>
				{topVisibleActions.length === 0 ? (
					<Text>{formatMessage(messages.noSuggestion)}</Text>
				) : (
					<Box role="list">
						{topVisibleActions.map((action) => {
							const actionType = action.type;
							const key = `${actionType}-${action.context.fieldId}`;

							switch (actionType) {
								case 'MAJOR_INCIDENT':
									return (
										<MajorIncidentItem
											{...(fg('concurrent-rendering-fix-unique-list-key') ? { key } : {})}
											action={action}
											onRemoveItem={createOnRemoveAction(actionType)}
										/>
									);
								case 'RESPONDERS':
									return (
										<RespondersItem
											{...(fg('concurrent-rendering-fix-unique-list-key') ? { key } : {})}
											action={action}
											onRemoveItem={createOnRemoveAction(actionType)}
										/>
									);
								case 'SEVERITY':
									return (
										<SeverityItem
											{...(fg('concurrent-rendering-fix-unique-list-key') ? { key } : {})}
											action={action}
											onRemoveItem={createOnRemoveAction(actionType)}
										/>
									);
								case 'PRIORITY':
									return (
										<PriorityItem
											{...(fg('concurrent-rendering-fix-unique-list-key') ? { key } : {})}
											action={action}
											onRemoveItem={createOnRemoveAction(actionType)}
										/>
									);
								case 'AFFECTED_SERVICES':
									return (
										<AffectedServicesItem
											{...(fg('concurrent-rendering-fix-unique-list-key') ? { key } : {})}
											action={action}
											onRemoveItem={createOnRemoveAction(actionType)}
										/>
									);
								case 'PROBLEM_TICKET':
									return (
										<ProblemTicketItem
											{...(fg('concurrent-rendering-fix-unique-list-key') ? { key } : {})}
											action={action}
											onRemoveItem={createOnRemoveAction(actionType)}
										/>
									);
								case 'RCA_WITH_AUTOMATION':
									return (
										<AutomationAction
											{...(fg('concurrent-rendering-fix-unique-list-key') ? { key } : {})}
											action={action}
											onRemoveItem={createOnRemoveAction(actionType)}
										/>
									);
								default: {
									const exhaustiveCheck: never = actionType;
									fireErrorAnalytics({
										meta: {
											id: 'OpsPanelContent',
											packageName: 'jiraServicedeskAIContextOpsPanel',
											teamName: 'itsm-aiops-grogu',
										},
										error: new Error(
											`This condition is not handled in the OpsPanelContent handler function: ${exhaustiveCheck}`,
										),
									});
								}
							}

							return null;
						})}
					</Box>
				)}
			</AIContextItem>
			<AIOpsSendUnifiedAIEvent
				onMount={(event, createAIPayload) => {
					const visibleActions = Object.fromEntries(
						topVisibleActions.map((action) => [action.type, true]),
					);

					fireAIOpsEvent(
						event,
						createAIPayload({
							type: 'viewed',
							isProactive: true,
							suggestedActions: visibleActions,
							suggestions: {
								RCA: Boolean(incidentSuggestion.probableRCA),
							},
						}),
					);
				}}
			/>
			<Feedback
				entrypointId={ENTRY_POINT_ID_AIOPS}
				feedbackGroupId={FEEDBACK_GROUP_ID}
				onFeedbackClicked={onFeedbackClicked}
				onFeedbackSent={onFeedbackSent}
			/>
		</>
	);
};
