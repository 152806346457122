import isNil from 'lodash/isNil';
import type { User } from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import type { StateUser } from '../../../../../../multi-user/transformer.tsx';

// this transformer adjusts user option value response
export const toCustomFieldValue = (value: StateUser[] | null): User[] | null => {
	if (isNil(value)) {
		return null;
	}
	// @ts-expect-error - TS2322 - Type '{ accountId: string | null; }[]' is not assignable to type 'User[]'.
	return value.map((option) => ({
		accountId: !isNil(option.accountId) ? option.accountId : option.id,
	}));
};
