import type { MiddlewareAPI } from 'redux';
import type { ActionsObservable } from 'redux-observable';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/ignoreElements';
import type { AssociatedIssuesContextActions } from '@atlassian/jira-associated-issues-context-service/src/actions.tsx';
import fetchAssociatedIssueData, {
	type AssociatedIssueData,
} from '@atlassian/jira-issue-fetch-services/src/services/associated-issue-data/index.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { trackOrLogClientError } from '@atlassian/jira-issue-view-common-utils/src/errors/index.tsx';
import {
	UPDATE_ASSOCIATED_ISSUE_REQUEST,
	type UpdateAssociatedIssueRequest,
} from '@atlassian/jira-issue-view-store/src/actions/associated-issue-actions.tsx';
import { cloudIdSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import getRelayEnvironment from '@atlassian/jira-relay-environment/src/index.tsx';

export const LOG_LOCATION = 'issue.associated-issues.fetch-associated-issue-epic';
export const ERROR_MESSAGE = 'Error while fetching associated issue';
export const MISSING_ISSUE_KEY_ERROR_MESSAGE = 'Issue key is not defined';
export const MISSING_IS_RESOLVED_ERROR_MESSAGE = 'Failed to get isResolved value for issue';

const transformAssociatedIssueData = (associatedIssueData: AssociatedIssueData) => {
	const isResolved = associatedIssueData.data?.jira?.issueByKey?.isResolved;

	if (typeof isResolved !== 'boolean') {
		throw new Error(MISSING_IS_RESOLVED_ERROR_MESSAGE);
	}

	return { isResolved };
};

/**
 * Fetch associated issue data from AGG for a linked and child issue
 */

const fetchAssociatedIssueRequestEpic =
	(associatedIssuesContextActions?: AssociatedIssuesContextActions) =>
	(action$: ActionsObservable<UpdateAssociatedIssueRequest>, store: MiddlewareAPI<State>) =>
		action$
			.ofType(UPDATE_ASSOCIATED_ISSUE_REQUEST)
			.do(async (action: UpdateAssociatedIssueRequest) => {
				try {
					const { issueKey } = action.payload;

					if (!issueKey) {
						throw new Error(MISSING_ISSUE_KEY_ERROR_MESSAGE);
					}

					const state = store.getState();
					const cloudId = cloudIdSelector(state);

					const associatedIssueData = await fetchAssociatedIssueData(
						issueKey,
						cloudId,
						getRelayEnvironment(),
					);

					if (associatedIssuesContextActions) {
						associatedIssuesContextActions.mergeSingleLocalAssociatedIssueContext(
							issueKey,
							transformAssociatedIssueData(associatedIssueData),
						);
					}
				} catch (error) {
					if (error instanceof Error || error instanceof TypeError) {
						trackOrLogClientError(LOG_LOCATION, ERROR_MESSAGE, error);
					}
				}
			})
			.ignoreElements();

export default fetchAssociatedIssueRequestEpic;
