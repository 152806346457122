import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	empty: {
		id: 'issue-field-single-select-editview-full.single-select.empty',
		defaultMessage: 'No matches found',
		description: 'Message when no options are found in a select',
	},
	failedFetch: {
		id: 'issue-field-single-select-editview-full.single-select.failed-fetch',
		defaultMessage: 'Search failed.',
		description: 'Message when fetching suggestions fails',
	},
	loading: {
		id: 'issue-field-single-select-editview-full.single-select.loading',
		defaultMessage: 'Searching...',
		description: 'Message when options are being loaded from the server.',
	},
	placeholder: {
		id: 'issue-field-single-select-editview-full.single-select.placeholder',
		defaultMessage: 'Select {fieldName}',
		description: 'Placeholder message for when no value selected',
	},
	queryError: {
		id: 'issue-field-single-select-editview-full.single-select.query-error',
		defaultMessage: 'Search failed.',
		description: 'Message when fetching suggestions fails',
	},
});
