import React, { useCallback, useState, useEffect } from 'react';
import ArrowLeftIcon from '@atlaskit/icon/core/migration/arrow-left';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import {
	TASK_FAIL,
	TASK_SUCCESS,
} from '@atlassian/jira-experience-tracker/src/common/constants.tsx';
import { getAnalyticsAttributesFromExtension } from '@atlassian/jira-forge-ui-analytics/src/common/utils/get-analytics-attributes-from-extension/index.tsx';
import { ViewAnalyticsWrapper } from '@atlassian/jira-forge-ui-analytics/src/ui/issue-glance/index.tsx';
import { AsyncIssueGlance } from '@atlassian/jira-forge-ui-async/src/ui/issue-glance/async.tsx';
import type { ForgeUiIssueData } from '@atlassian/jira-forge-ui-types/src/common/types/extension-data.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useOnDemandIssueRefreshTime } from '@atlassian/jira-issue-refresh-service/src/services/main.tsx';
import { sendExperienceAnalytics } from '@atlassian/jira-issue-view-analytics/src/controllers/send-experience-analytics/index.tsx';
import type { Glance } from '@atlassian/jira-issue-view-common-types/src/forge-types.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import { useIssueLayoutActions } from '@atlassian/jira-issue-view-layout/src/services/main.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { analyticsSourceSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import { forgeIssueDataSelector } from '@atlassian/jira-issue-view-store/src/ecosystem/forge/forge-extensions-selector.tsx';
import { MountEvent } from '@atlassian/jira-product-analytics-bridge';
import {
	useProjectKey,
	useApplication,
	useEdition,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import {
	HeaderContentContainer,
	PanelContentContainerInner,
	HeaderContainer,
	RoundButton,
} from '../../../context-panel/view/styled.tsx';
import { HeaderBreadcrumb } from '../../../side-panel/styled.tsx';
import { PanelContents } from '../../common/styled-glance-panel.tsx';

type Props = {
	isOpen: boolean;
	extensionData: ForgeUiIssueData;
	glance: Glance;
	source: string | undefined;
	styles: {
		headerWidth: number | undefined;
		contentWidth: number | undefined;
	};
};

export const ForgeGlancePanel = ({ glance, extensionData, styles, source, isOpen }: Props) => {
	const [shouldRenderGlance, setShouldRenderGlance] = useState(false);

	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const application = useApplication(projectKey, true);
	const edition = useEdition(projectKey, true);

	const [, { setIssueViewLayoutGlance }] = useIssueLayoutActions();

	const hideForgeGlance = useCallback(() => {
		setIssueViewLayoutGlance(issueKey, undefined);
	}, [issueKey, setIssueViewLayoutGlance]);

	useEffect(() => {
		if (isOpen && !shouldRenderGlance) {
			setShouldRenderGlance(true);
		}
	}, [isOpen, shouldRenderGlance]);

	const commonAnalyticAttributes = {
		experience: 'issueViewForgeGlancePanelRender',
		analyticsSource: 'issueViewForgeGlancePanelView',
		application: application ?? null,
		edition: edition ?? null,
	};
	return (
		<UFOSegment name="issue-forge-glance-panel">
			<ErrorBoundary
				id="issue-view.context.ecosystem-glance-panel-forge"
				onError={(location, error) =>
					sendExperienceAnalytics({
						...commonAnalyticAttributes,
						wasExperienceSuccesful: false,
						action: TASK_FAIL,
						additionalAttributes: {
							location,
							errorMessage: error.message,
						},
					})
				}
			>
				<HeaderContainer onClick={undefined} tabIndex={undefined}>
					<HeaderContentContainer
						data-testid="issue-view-base.context.ecosystem.forge.issue-glance.header-content-container"
						maxWidth={styles.headerWidth}
						onClick={hideForgeGlance}
						tabIndex={0}
					>
						<RoundButton>
							<ArrowLeftIcon
								color="currentColor"
								spacing="spacious"
								LEGACY_size="medium"
								label="Return"
							/>
						</RoundButton>
						<HeaderBreadcrumb>{glance.title}</HeaderBreadcrumb>
					</HeaderContentContainer>
				</HeaderContainer>
				<PanelContents
					panelWidth={styles.contentWidth}
					data-testid="issue-view-base.context.ecosystem.forge.issue-glance.panel-contents"
				>
					<PanelContentContainerInner maxWidth={styles.contentWidth}>
						{shouldRenderGlance && (
							<AsyncIssueGlance
								extensionData={extensionData}
								extension={glance.extension}
								analyticsAttributes={{ source }}
							/>
						)}
					</PanelContentContainerInner>
				</PanelContents>
				<MountEvent
					onMount={() =>
						sendExperienceAnalytics({
							...commonAnalyticAttributes,
							wasExperienceSuccesful: true,
							action: TASK_SUCCESS,
						})
					}
				/>
			</ErrorBoundary>
		</UFOSegment>
	);
};

ForgeGlancePanel.displayName = 'ForgeGlancePanel';

export const ForgeGlancePanelWithAnalytics = ({ ...props }: Props) => {
	const refreshedOn = useOnDemandIssueRefreshTime();

	return (
		<ViewAnalyticsWrapper
			key={refreshedOn}
			attributes={{
				source: props.source,
				...getAnalyticsAttributesFromExtension(props.glance.extension),
			}}
		>
			<ForgeGlancePanel {...props} />
		</ViewAnalyticsWrapper>
	);
};

export default flowWithSafeComponent(
	connect(
		(state: State) => ({
			extensionData: forgeIssueDataSelector(state),
			source: analyticsSourceSelector(state),
		}),
		{},
	),
)(ForgeGlancePanelWithAnalytics);
