import log from '@atlassian/jira-common-util-logging/src/log.tsx';

export const getExternalReferencePropertyLabel = (property: {
	__typename: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key: string]: any;
}) => {
	switch (property.__typename) {
		case 'TownsquareGoalState':
		case 'TownsquareProjectState':
			return property.label;
		default:
			log.safeWarnWithoutCustomerData(
				'external-reference-property-label.failure',
				'Failed to match external reference property type to defined label',
			);
			return undefined;
	}
};

export const getExternalReferencePropertyIdentificator = (property: {
	__typename: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key: string]: any;
}) => {
	switch (property.__typename) {
		case 'TownsquareGoalState':
		case 'TownsquareProjectState':
			return property.value;
		default:
			log.safeWarnWithoutCustomerData(
				'external-reference-property-identificator.failure',
				'Failed to match external reference property type to defined identificator',
			);
			return undefined;
	}
};
