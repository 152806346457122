import { performPostRequest } from '@atlassian/jira-fetch/src/index.tsx';
import type { ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';

type IssueTypeCreatePayload = {
	name: string;
	description: string;
	avatarId: number;
};

export type IssueTypeCreateResponse = {
	name: string;
	description: string;
	id: number;
	avatarId: number;
	entityId: string; // UUID
	hierarchyLevel: number;
};

export const performCreateIssueType = (
	projectId: ProjectId,
	data: IssueTypeCreatePayload,
): Promise<IssueTypeCreateResponse> => {
	return performPostRequest(`/rest/polaris/v2/project/${projectId}/settings/issuetype`, {
		body: JSON.stringify(data),
	});
};
