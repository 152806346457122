import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	extendedEditButtonLabel: {
		id: 'issue-view-base.context.number-estimate.extended-edit-button-label',
		defaultMessage: 'Edit {fieldName}, currently {inputFieldValue}',
		description:
			'An accessibility label for an edit button in an input field. The fieldName parameter refers to an optional heading for what the field is controlling. The inputFieldValue refers to the vale in the field.',
	},
});
