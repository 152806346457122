// SHOW_LINK_IDEA_INPUT
export const SHOW_LINK_IDEA_INPUT = 'SHOW_LINK_IDEA_INPUT' as const;

export const showLinkIdeaInput = () => ({
	type: SHOW_LINK_IDEA_INPUT,
});

// HIDE_LINK_IDEA_INPUT
export const HIDE_LINK_IDEA_INPUT = 'HIDE_LINK_IDEA_INPUT' as const;

export const hideLinkIdeaInput = () => ({
	type: HIDE_LINK_IDEA_INPUT,
});

export type ShowLinkIdeaInputAction = ReturnType<typeof showLinkIdeaInput>;
export type HideLinkIdeaInputAction = ReturnType<typeof hideLinkIdeaInput>;

export type LinkIdeaAction = ShowLinkIdeaInputAction | HideLinkIdeaInputAction;
