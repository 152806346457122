import {
	COMPONENTS_TYPE,
	LABELS_TYPE,
	DESCRIPTION_TYPE,
	ASSIGNEE_TYPE,
	DUE_DATE_TYPE,
	PRIORITY_TYPE,
	REPORTER_TYPE,
	ENVIRONMENT_TYPE,
	SPRINT_TYPE,
	STORY_POINTS_TYPE,
	EPIC_TYPE,
	FIX_VERSIONS_TYPE,
	AFFECTS_VERSIONS_TYPE,
	DEV_SUMMARY_TYPE,
	APPROVALS_CF_TYPE,
	KNOWLEDGE_BASE_TYPE,
	SLA_PANEL_TYPE,
	SENTIMENT_TYPE,
	RELEASES_TYPE,
	TIME_ESTIMATE_TYPE,
	TIME_TRACKING_TYPE,
	PARENT_CF_TYPE,
	BASELINE_START_CF_TYPE,
	BASELINE_END_CF_TYPE,
	LABELS_CF_TYPE,
	TEXT_CF_TYPE,
	MULTI_USER_CF_TYPE,
	URL_CF_TYPE,
	NUMBER_CF_TYPE,
	STORY_POINT_ESTIMATE_CF_TYPE,
	SELECT_CF_TYPE,
	MULTI_SELECT_CF_TYPE,
	TEXT_AREA_CF_TYPE,
	MULTI_VERSION_CF_TYPE,
	VERSION_CF_TYPE,
	DATE_CF_TYPE,
	GROUP_CF_TYPE,
	MULTI_GROUP_CF_TYPE,
	USER_CF_TYPE,
	DATETIME_CF_TYPE,
	MULTI_CHECKBOXES_CF_TYPE,
	RADIO_BUTTONS_CF_TYPE,
	FORGE_GLANCE_TYPE,
	ECOSYSTEM_GLANCE_TYPE,
	TEAM_CF_TYPE,
	ISSUE_FIELD_NUMBER_CF_TYPE,
	ISSUE_FIELD_DATETIME_CF_TYPE,
	ISSUE_FIELD_STRING_CF_TYPE,
	ISSUE_FIELD_SINGLE_SELECT_CF_TYPE,
	ISSUE_FIELD_MULTI_SELECT_CF_TYPE,
	REQUEST_TYPE_CF_TYPE,
	ASSETS_CF_TYPE,
	SERVICE_ENTITY_CF_TYPE,
	REQUEST_PARTICIPANTS_CF_TYPE,
	ORGANIZATIONS_CF_TYPE,
	REQUEST_FEEDBACK_CF_TYPE,
	COLOR_CF_TYPE,
	PEOPLE_CF_TYPE,
	REQUEST_LANGUAGE_CF_TYPE,
	ASSET_ONBOARDING_TYPE,
	CASCADING_SELECT_CF_TYPE,
	PROJECT_PICKER_CF_TYPE,
	MESSAGE_VIEW_CF_TYPE,
	MESSAGE_EDIT_CF_TYPE,
	ENTITLEMENT_CF_TYPE,
	SENTIMENT_CF_TYPE,
	ORGANIZATION_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';

export const ALWAYS_TRACKING_FIELD_VIEW_EXPERIENCE = 'ALWAYS_TRACKING' as const;
export const NEVER_TRACKING_FIELD_VIEW_EXPERIENCE = 'NEVER_TRACKING' as const;

export type FieldViewExperienceTrackingWhitelist =
	| string[]
	| typeof ALWAYS_TRACKING_FIELD_VIEW_EXPERIENCE
	| typeof NEVER_TRACKING_FIELD_VIEW_EXPERIENCE;

// Specific experience-provider keys to be whitelisted
export const REQUEST_DETAILS_PANEL_SUB_EXPERIENCE = 'requestDetailsPanel';
// ...

// Meaning of entries in this map:
// * Missing field type - do not track the field's experience at all
// * Field type present with ALWAYS_TRACKING - Indicates that the field type should get a ViewExperienceTracker that always reports to the nearest experience provider
// * Field type present with a list of strings - All fields of this type will only report successes/failures when mounted directly inside an experience provider by this name (or sub experience provider with this location)
const viewTrackerProviderWhitelistByFieldType: {
	[fieldType: string]: FieldViewExperienceTrackingWhitelist;
} = {
	// Always tracking
	[DESCRIPTION_TYPE]: ALWAYS_TRACKING_FIELD_VIEW_EXPERIENCE,

	// Never tracking (if a field type is missing from this whole map, this category applies, items listed below are for clarity)
	[ECOSYSTEM_GLANCE_TYPE]: NEVER_TRACKING_FIELD_VIEW_EXPERIENCE,
	[FORGE_GLANCE_TYPE]: NEVER_TRACKING_FIELD_VIEW_EXPERIENCE,
	[ASSIGNEE_TYPE]: NEVER_TRACKING_FIELD_VIEW_EXPERIENCE,
	[REPORTER_TYPE]: NEVER_TRACKING_FIELD_VIEW_EXPERIENCE,
	[SPRINT_TYPE]: NEVER_TRACKING_FIELD_VIEW_EXPERIENCE,
	[STORY_POINTS_TYPE]: NEVER_TRACKING_FIELD_VIEW_EXPERIENCE,
	[EPIC_TYPE]: NEVER_TRACKING_FIELD_VIEW_EXPERIENCE,
	[FIX_VERSIONS_TYPE]: NEVER_TRACKING_FIELD_VIEW_EXPERIENCE,
	[AFFECTS_VERSIONS_TYPE]: NEVER_TRACKING_FIELD_VIEW_EXPERIENCE,
	[DEV_SUMMARY_TYPE]: NEVER_TRACKING_FIELD_VIEW_EXPERIENCE,
	[RELEASES_TYPE]: NEVER_TRACKING_FIELD_VIEW_EXPERIENCE,
	[ASSETS_CF_TYPE]: NEVER_TRACKING_FIELD_VIEW_EXPERIENCE,
	[ASSET_ONBOARDING_TYPE]: NEVER_TRACKING_FIELD_VIEW_EXPERIENCE,
	[SERVICE_ENTITY_CF_TYPE]: NEVER_TRACKING_FIELD_VIEW_EXPERIENCE,

	// Tracking only when mounted directly inside specific experience providers
	[LABELS_TYPE]: [REQUEST_DETAILS_PANEL_SUB_EXPERIENCE],
	[COMPONENTS_TYPE]: [REQUEST_DETAILS_PANEL_SUB_EXPERIENCE],
	[DUE_DATE_TYPE]: [REQUEST_DETAILS_PANEL_SUB_EXPERIENCE],
	[PRIORITY_TYPE]: [REQUEST_DETAILS_PANEL_SUB_EXPERIENCE],
	[ENVIRONMENT_TYPE]: [REQUEST_DETAILS_PANEL_SUB_EXPERIENCE],
	// eslint-disable-next-line @atlassian/eng-health/code-evolution/ts-migration-4.9-5.4
	// @ts-expect-error([Part of upgrade 4.9-5.4]) - An object literal cannot have multiple properties with the same name.
	[LABELS_TYPE]: [REQUEST_DETAILS_PANEL_SUB_EXPERIENCE],
	// eslint-disable-next-line @atlassian/eng-health/code-evolution/ts-migration-4.9-5.4
	// @ts-expect-error([Part of upgrade 4.9-5.4]) - An object literal cannot have multiple properties with the same name.
	[COMPONENTS_TYPE]: [REQUEST_DETAILS_PANEL_SUB_EXPERIENCE],
	[APPROVALS_CF_TYPE]: [REQUEST_DETAILS_PANEL_SUB_EXPERIENCE],
	[KNOWLEDGE_BASE_TYPE]: [REQUEST_DETAILS_PANEL_SUB_EXPERIENCE],
	[SLA_PANEL_TYPE]: [REQUEST_DETAILS_PANEL_SUB_EXPERIENCE],
	[SENTIMENT_TYPE]: [REQUEST_DETAILS_PANEL_SUB_EXPERIENCE],
	[TIME_ESTIMATE_TYPE]: [REQUEST_DETAILS_PANEL_SUB_EXPERIENCE],
	[TIME_TRACKING_TYPE]: [REQUEST_DETAILS_PANEL_SUB_EXPERIENCE],
	[PARENT_CF_TYPE]: [REQUEST_DETAILS_PANEL_SUB_EXPERIENCE],
	[BASELINE_START_CF_TYPE]: [REQUEST_DETAILS_PANEL_SUB_EXPERIENCE],
	[BASELINE_END_CF_TYPE]: [REQUEST_DETAILS_PANEL_SUB_EXPERIENCE],
	[LABELS_CF_TYPE]: [REQUEST_DETAILS_PANEL_SUB_EXPERIENCE],
	[TEXT_CF_TYPE]: [REQUEST_DETAILS_PANEL_SUB_EXPERIENCE],
	[MULTI_USER_CF_TYPE]: [REQUEST_DETAILS_PANEL_SUB_EXPERIENCE],
	[URL_CF_TYPE]: [REQUEST_DETAILS_PANEL_SUB_EXPERIENCE],
	[NUMBER_CF_TYPE]: [REQUEST_DETAILS_PANEL_SUB_EXPERIENCE],
	[STORY_POINT_ESTIMATE_CF_TYPE]: [REQUEST_DETAILS_PANEL_SUB_EXPERIENCE],
	[SELECT_CF_TYPE]: [REQUEST_DETAILS_PANEL_SUB_EXPERIENCE],
	[MULTI_SELECT_CF_TYPE]: [REQUEST_DETAILS_PANEL_SUB_EXPERIENCE],
	[TEXT_AREA_CF_TYPE]: [REQUEST_DETAILS_PANEL_SUB_EXPERIENCE],
	[MULTI_VERSION_CF_TYPE]: [REQUEST_DETAILS_PANEL_SUB_EXPERIENCE],
	[VERSION_CF_TYPE]: [REQUEST_DETAILS_PANEL_SUB_EXPERIENCE],
	[DATE_CF_TYPE]: [REQUEST_DETAILS_PANEL_SUB_EXPERIENCE],
	[GROUP_CF_TYPE]: [REQUEST_DETAILS_PANEL_SUB_EXPERIENCE],
	[MULTI_GROUP_CF_TYPE]: [REQUEST_DETAILS_PANEL_SUB_EXPERIENCE],
	[USER_CF_TYPE]: [REQUEST_DETAILS_PANEL_SUB_EXPERIENCE],
	[DATETIME_CF_TYPE]: [REQUEST_DETAILS_PANEL_SUB_EXPERIENCE],
	[MULTI_CHECKBOXES_CF_TYPE]: [REQUEST_DETAILS_PANEL_SUB_EXPERIENCE],
	[RADIO_BUTTONS_CF_TYPE]: [REQUEST_DETAILS_PANEL_SUB_EXPERIENCE],
	[TEAM_CF_TYPE]: [REQUEST_DETAILS_PANEL_SUB_EXPERIENCE],
	[ISSUE_FIELD_NUMBER_CF_TYPE]: [REQUEST_DETAILS_PANEL_SUB_EXPERIENCE],
	[ISSUE_FIELD_DATETIME_CF_TYPE]: [REQUEST_DETAILS_PANEL_SUB_EXPERIENCE],
	[ISSUE_FIELD_STRING_CF_TYPE]: [REQUEST_DETAILS_PANEL_SUB_EXPERIENCE],
	[ISSUE_FIELD_SINGLE_SELECT_CF_TYPE]: [REQUEST_DETAILS_PANEL_SUB_EXPERIENCE],
	[ISSUE_FIELD_MULTI_SELECT_CF_TYPE]: [REQUEST_DETAILS_PANEL_SUB_EXPERIENCE],
	[REQUEST_TYPE_CF_TYPE]: [REQUEST_DETAILS_PANEL_SUB_EXPERIENCE],
	[REQUEST_PARTICIPANTS_CF_TYPE]: [REQUEST_DETAILS_PANEL_SUB_EXPERIENCE],
	[ORGANIZATIONS_CF_TYPE]: [REQUEST_DETAILS_PANEL_SUB_EXPERIENCE],
	[REQUEST_FEEDBACK_CF_TYPE]: [REQUEST_DETAILS_PANEL_SUB_EXPERIENCE],
	[COLOR_CF_TYPE]: [REQUEST_DETAILS_PANEL_SUB_EXPERIENCE],
	[PEOPLE_CF_TYPE]: [REQUEST_DETAILS_PANEL_SUB_EXPERIENCE],
	[REQUEST_LANGUAGE_CF_TYPE]: [REQUEST_DETAILS_PANEL_SUB_EXPERIENCE],
	[CASCADING_SELECT_CF_TYPE]: [REQUEST_DETAILS_PANEL_SUB_EXPERIENCE],
	[PROJECT_PICKER_CF_TYPE]: [REQUEST_DETAILS_PANEL_SUB_EXPERIENCE],
	[MESSAGE_VIEW_CF_TYPE]: [REQUEST_DETAILS_PANEL_SUB_EXPERIENCE],
	[MESSAGE_EDIT_CF_TYPE]: [REQUEST_DETAILS_PANEL_SUB_EXPERIENCE],
	[ENTITLEMENT_CF_TYPE]: [REQUEST_DETAILS_PANEL_SUB_EXPERIENCE],
	[SENTIMENT_CF_TYPE]: [REQUEST_DETAILS_PANEL_SUB_EXPERIENCE],
	[ORGANIZATION_CF_TYPE]: [REQUEST_DETAILS_PANEL_SUB_EXPERIENCE],
};

export const getFieldViewTrackerProviderWhitelist = (
	fieldType: string,
): FieldViewExperienceTrackingWhitelist => {
	const whitelist = viewTrackerProviderWhitelistByFieldType[fieldType];
	if (whitelist === undefined) {
		return NEVER_TRACKING_FIELD_VIEW_EXPERIENCE;
	}
	return whitelist;
};
