import React from 'react';
import Icon from '@atlaskit/icon';
import { Flex } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

const InfoIconSvg = () => {
	const fillColor = token('color.text.subtlest', colors.N200);
	return (
		<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9 15C12.3137 15 15 12.3137 15 9C15 5.68629 12.3137 3 9 3C5.68629 3 3 5.68629 3 9C3 12.3137 5.68629 15 9 15ZM9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
				fill={fillColor}
			/>
			<path
				d="M8.25 8.25C8.25 7.83579 8.58579 7.5 9 7.5C9.41421 7.5 9.75 7.83579 9.75 8.25V12C9.75 12.4142 9.41421 12.75 9 12.75C8.58579 12.75 8.25 12.4142 8.25 12V8.25Z"
				fill={fillColor}
			/>
			<path
				d="M9.75 6C9.75 6.41421 9.41421 6.75 9 6.75C8.58579 6.75 8.25 6.41421 8.25 6C8.25 5.58579 8.58579 5.25 9 5.25C9.41421 5.25 9.75 5.58579 9.75 6Z"
				fill={fillColor}
			/>
		</svg>
	);
};

export const AiInformationIcon = () => (
	<Flex>
		<Icon
			label="atlassian-intelligence-info-icon"
			size="small"
			glyph={InfoIconSvg}
			primaryColor={token('color.icon.subtle', colors.N500)}
			testId="atlassian-intelligence.common.ui.ai-information-icon.atlassian-intelligence-info-icon"
		/>
	</Flex>
);
