import log from '@atlassian/jira-common-util-logging/src/log.tsx';

const LOG_LOCATION = 'issue.middleware.error-logger';

type Action = {
	type: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	payload: any;
};

export const errorLoggingMiddleware =
	() =>
	(next: (arg1: Action) => Action) =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(action: Action): any => {
		try {
			next(action);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			log.safeErrorWithoutCustomerData(LOG_LOCATION, `Unhandled error in ${action.type}`);
			throw error;
		}
	};
