import React, { type SyntheticEvent } from 'react';
import Button from '@atlaskit/button';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import ChevronUpIcon from '@atlaskit/icon/glyph/chevron-up';
import { fireUIAnalytics, type UIAnalyticsEvent } from '@atlassian/jira-product-analytics-bridge';

type Props = {
	fieldName: string;
	isOpen: boolean;
	onToggle: () => void;
	expandAriaLabel: string;
	collapseAriaLabel: string;
};

export const ExpandButton = ({
	fieldName,
	isOpen,
	onToggle,
	expandAriaLabel,
	collapseAriaLabel,
}: Props) => {
	const controlContentId = `${fieldName}-custom-field-expand-panel`;
	const openedOrClosed = isOpen ? 'closed' : 'opened';

	return (
		<Button
			testId={controlContentId}
			onClick={(e: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
				fireUIAnalytics(analyticsEvent, 'issueViewCustomerContextFieldExpand', {
					context: fieldName,
					openedOrClosed,
				});
				onToggle();
			}}
			appearance="subtle"
			aria-expanded={isOpen}
			aria-controls={controlContentId}
			aria-label={isOpen ? collapseAriaLabel : expandAriaLabel}
			iconBefore={isOpen ? <ChevronUpIcon label="" /> : <ChevronDownIcon label="" />}
		/>
	);
};
