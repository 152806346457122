import { createSelector } from 'reselect';
import flatten from 'lodash/flatten';
import filter from 'lodash/fp/filter';
import map from 'lodash/fp/map';
import pipe from 'lodash/fp/pipe';
import keyBy from 'lodash/keyBy';
import type { ApplinkData, ErrorLink } from '../../../../common/model/types.tsx';
import type {
	InstanceTypeDetail,
	Repository,
	Branch,
	EmbeddedMarketplaceDetails,
} from '../../../model/index.tsx';
import type { State } from '../../index.tsx';
import type { DetailsState } from '../../reducers/details/types.tsx';
import { getSelectedApplicationType } from '../ui/index.tsx';

export const getDetailsState = (state: State): DetailsState => state.details;

export const getSelectedInstanceTypeDetails = createSelector(
	[getDetailsState, getSelectedApplicationType],
	(detailsState: DetailsState, selectedType): InstanceTypeDetail[] =>
		detailsState.details.instanceTypeDetails.filter((item) => item.type === selectedType),
);

export const getSelectedInstanceBaseUrl = createSelector(
	[getDetailsState, getSelectedApplicationType],
	(detailsState: DetailsState, selectedType): string =>
		detailsState.details.instanceTypeDetails.filter((item) => item.type === selectedType)[0]
			?.baseUrl,
);

export const getAllInstanceTypeDetails = createSelector(
	[getDetailsState],
	(detailsState): InstanceTypeDetail[] => detailsState.details.instanceTypeDetails,
);

export const getInstanceTypeDetailsByType = createSelector(
	[getAllInstanceTypeDetails],
	(details: InstanceTypeDetail[]) => keyBy(details, (detail: InstanceTypeDetail) => detail.id),
);

export const getEmbeddedMarketplaceDetails = createSelector(
	[getDetailsState],
	(detailsState): EmbeddedMarketplaceDetails | undefined =>
		detailsState.details.embeddedMarketplaceDetails,
);

export const getRepositories = (state: State): Repository[] => {
	const instanceTypeDetails = getSelectedInstanceTypeDetails(state);
	return instanceTypeDetails
		? flatten(instanceTypeDetails.map((instanceType) => instanceType.repositories))
		: [];
};

export const getMultiInstanceRepositories = (state: State): Repository[] => {
	const instanceTypeDetails = getAllInstanceTypeDetails(state);
	return instanceTypeDetails
		? flatten(instanceTypeDetails.map((instanceType) => instanceType.repositories))
		: [];
};

const filterBranchesWithoutPRLinks: (arg1: Branch[]) => Branch[] = filter(
	(branch) => !!branch.createPullRequestUrl && branch.pullRequests.length === 0,
);

const filterRepositoriesWithoutBranchesWithPRLinks: (arg1: Repository[]) => Repository[] = pipe(
	map((repository: Repository) => ({
		...repository,
		branches: filterBranchesWithoutPRLinks(repository.branches),
	})),
	filter((repository) => repository.branches.length > 0),
);

const filterInstancesWithoutRepositories: (arg1: InstanceTypeDetail[]) => InstanceTypeDetail[] =
	pipe(
		map((instance: InstanceTypeDetail) => ({
			...instance,
			repositories: filterRepositoriesWithoutBranchesWithPRLinks(instance.repositories),
		})),
		filter((instance: InstanceTypeDetail) => instance.repositories.length > 0),
	);

export const getInstancesForPRDiscovery = createSelector(
	getAllInstanceTypeDetails,
	filterInstancesWithoutRepositories,
);

const getErrorLinkForTypes = (instanceTypeDetails: InstanceTypeDetail[]): ErrorLink[] => {
	const results: Array<ErrorLink> = [];
	if (instanceTypeDetails.length > 0) {
		instanceTypeDetails.forEach((instanceType) => {
			if (instanceType.errorLink) {
				results.push(instanceType.errorLink);
			}
		});
	}
	return results;
};

const getUnauthoroizedAppLinks = (instanceTypeDetails: InstanceTypeDetail[]): ApplinkData[] => {
	const results: Array<ApplinkData> = [];
	if (instanceTypeDetails.length > 0) {
		instanceTypeDetails.forEach((instanceType) => {
			if (instanceType.unauthorizedLink) {
				results.push(instanceType.unauthorizedLink);
			}
		});
	}
	return results;
};

export const getAllErrorLinks = createSelector([getAllInstanceTypeDetails], (instanceTypes) =>
	getErrorLinkForTypes(instanceTypes),
);

export const getErrorLink = createSelector([getSelectedInstanceTypeDetails], (instanceTypes) =>
	getErrorLinkForTypes(instanceTypes),
);

export const getUnauthorizedApplink = createSelector(
	getSelectedInstanceTypeDetails,
	(instanceTypes) => getUnauthoroizedAppLinks(instanceTypes),
);

export const getAllUnauthorizedApplinks = createSelector(
	getAllInstanceTypeDetails,
	(instanceTypes) => getUnauthoroizedAppLinks(instanceTypes),
);

export const isApplicationError = createSelector(
	[getErrorLink, getUnauthorizedApplink],
	(errors, appErrors) => (errors && errors.length > 0) || (appErrors && appErrors.length > 0),
);

export const isLoading = (state: State): boolean => getDetailsState(state).isLoading;
export const isError = (state: State): boolean => getDetailsState(state).isError;
export const isDiffstatAnalyticsFired = (state: State): boolean =>
	getDetailsState(state).isDiffstatAnalyticsFired || false;

export const isOptOutOfDeploymentDiscovery = (state: State): boolean =>
	getDetailsState(state).isOptOutOfDeploymentDiscovery;
