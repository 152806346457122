import type { Observable as ObservableType } from 'rxjs/Observable';
import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';

type Payload = {
	issueId: IssueId;
};

/*
 * This API can delete the issue parent of either a single Company Managed Project (CMP) issue or a single Team Managed Project (TMP) issue
 */

export const getIssueSetParentUrlCMP = (issueId: IssueId) => `/rest/api/3/issue/${issueId}`;

export const deleteIssueParentCMP = (payload: Payload): ObservableType<unknown> =>
	fetchJson$(getIssueSetParentUrlCMP(payload.issueId), {
		method: 'PUT',
		body: JSON.stringify({ fields: { parent: { id: null } } }),
	});
