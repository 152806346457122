import { createSelector } from 'reselect';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { Snippet } from '@atlassian/jira-polaris-domain-insight/src/snippet/types.tsx';
import type { DynamicFieldFormula } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/types.tsx';
import { createGetInsights } from '../../../../selectors/properties/insights/index.tsx';
import type { DynamicFieldResolver } from '../../types.tsx';

const FORMULA_TEMPLATE = 'num_data_points';

const any = (lst: ReadonlyArray<Snippet>, predicate: (s: Snippet) => boolean) =>
	lst.find(predicate) !== undefined;

export const dataPointsCountDynamicFieldResolver: DynamicFieldResolver<number> = (
	formula: DynamicFieldFormula,
) => {
	if (formula === undefined || formula.template !== FORMULA_TEMPLATE) {
		return undefined;
	}

	return (localIssueId: LocalIssueId) => {
		const ideaInsights = createGetInsights(localIssueId, formula.parameters?.filter);

		return createSelector(ideaInsights, (insights) =>
			formula.parameters === undefined || formula.parameters.oauthClientId === undefined
				? insights.length
				: insights.reduce(
						(acc, insight) =>
							acc +
							(any(
								insight.snippets,
								(s) => s.appInfo?.oauthClientId === formula.parameters?.oauthClientId,
							)
								? 1
								: 0),
						0,
					),
		);
	};
};

export const dataPointsCreatedDynamicFieldResolver: DynamicFieldResolver<string[]> = (
	formula: DynamicFieldFormula,
) => {
	if (formula === undefined || formula.template !== FORMULA_TEMPLATE) {
		return undefined;
	}

	return (localIssueId: LocalIssueId) => {
		const ideaInsights = createGetInsights(localIssueId, formula.parameters?.filter);

		return createSelector(ideaInsights, (insights) => {
			const list =
				formula.parameters === undefined || formula.parameters.oauthClientId === undefined
					? insights
					: insights.filter((insight) =>
							any(
								insight.snippets,
								(s) => s.appInfo?.oauthClientId === formula.parameters?.oauthClientId,
							),
						);

			return list.map((item) => item.created);
		});
	};
};
