import React from 'react';

import CrossCircleIcon from '@atlaskit/icon/core/migration/cross-circle';
import SuccessCheckCircleIcon from '@atlaskit/icon/core/migration/success--check-circle';
import WarningIcon from '@atlaskit/icon/core/warning'; // TODO: find the correct icon GLIDE-152

import { Box, xcss, Stack, Grid } from '@atlaskit/primitives';
import Anchor from '@atlaskit/primitives/anchor';
import Spinner from '@atlaskit/spinner';

import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { defaultLocale } from '@atlassian/jira-common-constants/src/supported-locales.tsx';
import { useCurrentUser } from '@atlassian/jira-platform-services-user-current/src/main.tsx';

import { formatDistanceStrictWithLocale } from '@atlassian/jira-platform-utils-date-fns/src/main.tsx';

import type { AuditlogSummary, ProjectAdminDetails } from '../../../common/types.tsx';
import NoActionsCircle from './no-actions-circle/index.tsx';

type RuleDisplayProps = { recentItems: AuditlogSummary[] } & {
	projectAdminDetails: ProjectAdminDetails;
};

const AuditItemIconMapping: {
	[key: string]: React.ElementType;
} = {
	SUCCESS: () => (
		<SuccessCheckCircleIcon
			label=""
			LEGACY_size="small"
			color={token('color.text.success', colors.G300)}
		/>
	),
	THROTTLED: () => (
		<WarningIcon label="" LEGACY_size="small" color={token('color.icon.warning', colors.Y500)} />
	),
	LOOP: () => (
		<WarningIcon label="" LEGACY_size="small" color={token('color.icon.warning', colors.Y500)} />
	),
	SOME_ERRORS: () => (
		<WarningIcon label="" LEGACY_size="small" color={token('color.icon.warning', colors.Y500)} />
	),
	ABORTED: () => (
		<CrossCircleIcon label="" LEGACY_size="small" color={token('color.icon.danger', colors.R300)} />
	),
	FAILURE: () => (
		<CrossCircleIcon label="" LEGACY_size="small" color={token('color.icon.danger', colors.R300)} />
	),
	NO_ACTIONS_PERFORMED: () => (
		<NoActionsCircle label="" size="small" primaryColor={token('color.icon', colors.N300A)} />
	),
	QUEUED: () => <Spinner size="small" />,
};

const RenderIcon: React.FC<{ category: string }> = ({ category }) => {
	const icon = AuditItemIconMapping[category];
	if (!icon) {
		return null;
	}

	const Icon = icon;

	return (
		<Box xcss={iconBackgroundStyle}>
			<Icon />
		</Box>
	);
};

const RuleName: React.FC<
	{
		ruleName: AuditlogSummary['ruleName'];
		ruleId: AuditlogSummary['ruleId'];
		ruleIdUuid?: AuditlogSummary['ruleIdUuid'];
	} & { projectAdminDetails: ProjectAdminDetails }
> = (props) => {
	const { ruleName, ruleId, ruleIdUuid, projectAdminDetails } = props;
	if (projectAdminDetails.isProjectAdmin) {
		const ruleIdInLink = ruleIdUuid ?? ruleId;
		const manageSpecificRuleUrl = `${projectAdminDetails.manageRuleUrl}/${ruleIdInLink}`;
		return (
			<Anchor href={manageSpecificRuleUrl} target="_blank" rel="noopener noreferrer">
				{ruleName}
			</Anchor>
		);
	}
	return <>{ruleName}</>;
};

const RuleDisplay: React.FC<RuleDisplayProps> = (props) => {
	const { recentItems, projectAdminDetails } = props;
	const { data } = useCurrentUser();
	const locale = data?.user?.locale || defaultLocale;
	const currentDate = new Date();

	return (
		<>
			{recentItems.map((item) => {
				const execTime = item.endTime ?? item.startTime;
				const formattedDate = formatDistanceStrictWithLocale(execTime, currentDate, locale, true);
				return (
					<Stack key={`recent-item-${item.id}-${item.ruleName}`} xcss={ruleContainerStyle}>
						<Grid
							gap="space.050"
							templateAreas={['icon name', 'empty time']}
							templateColumns="16px 1fr"
						>
							<RenderIcon category={item.category} />

							<Box title={item.ruleName} xcss={textContainerStyle}>
								<RuleName
									ruleName={item.ruleName}
									ruleId={item.ruleId}
									ruleIdUuid={item.ruleIdUuid}
									projectAdminDetails={projectAdminDetails}
								/>
							</Box>

							<Box xcss={ruleTimeStyle}>{formattedDate}</Box>
						</Grid>
					</Stack>
				);
			})}
		</>
	);
};

const ruleContainerStyle = xcss({
	marginTop: 'space.050',
	width: '100%',
});

const textContainerStyle = xcss({
	color: 'color.text',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	gridArea: 'name',
});

const ruleTimeStyle = xcss({
	fontSize: 'font.body', // should be 12px
	color: 'color.text',
	gridArea: 'time',
});

const iconBackgroundStyle = xcss({
	height: '16px',
	width: '16px',
	borderRadius: '3px',
	verticalAlign: 'middle',
	gridArea: 'icon',
	alignSelf: 'center',
});

export default RuleDisplay;
