import mapValues from 'lodash/mapValues';
import type {
	Field,
	FieldsState,
} from '@atlassian/jira-issue-shared-types/src/common/types/field-type.tsx';
import type { TimeTrackingState } from '@atlassian/jira-issue-shared-types/src/common/types/time-tracking-type.tsx';
import { TIME_TRACKING } from '@atlassian/jira-issue-view-configurations/src/index.tsx';
import {
	type UpdateApprovalLinkedUserPickerFieldAction,
	UPDATE_APPROVAL_LINKED_USER_PICKER_FIELD,
} from '../../../actions/approval-action/index.tsx';
import {
	type FetchIssueSuccessAction,
	type RefreshIssueSuccessAction,
	FETCH_ISSUE_SUCCESS,
	REFRESH_ISSUE_SUCCESS,
} from '../../../common/actions/issue-fetch-actions.tsx';
import {
	MULTILINE_FIELD_EDIT_BEGIN,
	type MultilineFieldEditBeginAction,
} from '../../../common/actions/multiline-field-edit-actions.tsx';
import {
	ADD_WORKLOG_SUCCESS,
	EDIT_WORKLOG_SUCCESS,
	DELETE_WORKLOG_SUCCESS,
	UPDATE_TIME_REMAINING_SUCCESS,
	type AddWorklogSuccessAction,
	type EditWorklogSuccessAction,
	type DeleteWorklogSuccessAction,
	type UpdateTimeRemainingSuccessAction,
} from '../../../common/actions/worklog-actions.tsx';
import getEditingValue, {
	getEditingAdfValue,
} from '../../../common/state/selectors/get-editing-value.tsx';
import {
	type FieldEditBeginAction,
	type FieldEditUpdateAction,
	type FieldEditCancelAction,
	type FieldAddAllowedValuesAction,
	FIELD_EDIT_BEGIN,
	FIELD_EDIT_UPDATE,
	FIELD_EDIT_CANCEL,
	FIELD_ADD_ALLOWED_VALUES,
} from '../actions/field-actions.tsx';
import {
	type FieldSaveRequestAction,
	type FieldSaveSuccessAction,
	type FieldSaveFailureAction,
	type SweetStateFieldSaveFailureAction,
	type FieldUpdateAction,
	FIELD_SAVE_REQUEST,
	FIELD_SAVE_SUCCESS,
	FIELD_SAVE_FAILURE,
	FIELD_UPDATE,
	SWEET_STATE_FIELD_SAVE_FAILURE,
} from '../actions/field-save-actions.tsx';

type State = FieldsState;

type Action =
	| FetchIssueSuccessAction
	| RefreshIssueSuccessAction
	| FieldSaveRequestAction
	| FieldSaveSuccessAction
	| FieldSaveFailureAction
	| SweetStateFieldSaveFailureAction
	| FieldUpdateAction
	| FieldEditBeginAction
	| MultilineFieldEditBeginAction
	| FieldEditUpdateAction
	| FieldEditCancelAction
	| FieldAddAllowedValuesAction
	| AddWorklogSuccessAction
	| EditWorklogSuccessAction
	| DeleteWorklogSuccessAction
	| UpdateTimeRemainingSuccessAction
	| UpdateApprovalLinkedUserPickerFieldAction;

const initialState: State = {};

const changeField = (
	state: State,
	fieldId: string,
	propertiesToSet: Partial<Field>,
	propertiesToDelete: (keyof Field)[] = [],
): State => {
	const newState = {
		...state,
		[fieldId]: {
			...state[fieldId],
			...propertiesToSet,
		},
	};

	propertiesToDelete.forEach((propertyKey) => {
		delete newState[fieldId][propertyKey];
	});

	return newState;
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: State = initialState, action: Action): State => {
	switch (action.type) {
		case REFRESH_ISSUE_SUCCESS:
		case FETCH_ISSUE_SUCCESS: {
			const { fields } = action.payload.issue;
			return mapValues(fields, (field) => ({
				...field,
				editingValue: state[field.key] && state[field.key].editingValue,
				pendingValue: state[field.key] && state[field.key].pendingValue,
			}));
		}

		case FIELD_EDIT_BEGIN: {
			const {
				fieldId,
				fieldOptions: { isRichTextField },
				fieldEditSessionId,
			} = action.payload;

			if (!state[fieldId]) {
				return state;
			}

			const valueToCopy = isRichTextField
				? getEditingAdfValue(state[fieldId])
				: getEditingValue(state[fieldId]);

			return changeField(
				state,
				fieldId,
				{
					editingValue: valueToCopy,
					...(fieldEditSessionId ? { fieldEditSessionId } : null),
				},
				['invalidMessage', 'isInvalid'],
			);
		}

		case MULTILINE_FIELD_EDIT_BEGIN: {
			const { multilineFieldIdsToClose } = action.payload;
			if (multilineFieldIdsToClose != null) {
				const newMultilineFieldsToBeClosed = multilineFieldIdsToClose.reduce<
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					Record<string, any>
				>(
					(accu, fieldId) => ({
						// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
						...accu,
						[fieldId]: {
							...state[fieldId],
							editingValue: undefined,
						},
					}),
					{},
				);
				return {
					...state,
					...newMultilineFieldsToBeClosed,
				};
			}
			return state;
		}

		case FIELD_EDIT_UPDATE: {
			const { fieldId, value } = action.payload;
			return changeField(
				state,
				fieldId,
				{
					editingValue: value,
				},
				['invalidMessage', 'isInvalid'],
			);
		}

		case FIELD_SAVE_REQUEST: {
			const { fieldId, value } = action.payload;
			return changeField(
				state,
				fieldId,
				{
					pendingValue: value,
				},
				['editingValue'],
			);
		}

		case FIELD_EDIT_CANCEL: {
			const { fieldId } = action.payload;
			return changeField(state, fieldId, {}, [
				'editingValue',
				'invalidMessage',
				'isInvalid',
				'fieldEditSessionId',
			]);
		}

		case FIELD_SAVE_SUCCESS: {
			const { fieldId, fieldOptions, saveFieldResult } = action.payload;

			if (!(fieldId in state)) {
				return state;
			}

			// clear the pendingValue
			const { pendingValue } = state[fieldId];
			const isPendingValueInState = Object.prototype.hasOwnProperty.call(
				state[fieldId],
				'pendingValue',
			);

			let newState = changeField(state, fieldId, {}, ['pendingValue', 'fieldEditSessionId']);

			// Do we have a usable new value for this field from the response when saving the value?
			const hasUpdatedFieldValueFromSaveResult =
				// @ts-expect-error - TS2339 - Property 'updatedFieldValue' does not exist on type 'unknown'.
				saveFieldResult?.updatedFieldValue !== undefined;
			// if not, can we use the `pendingValue` in state (only usable if this was an optimistically saving field)
			const shouldUseOptimisticPendingValueAsFallback =
				fieldOptions.isOptimistic && isPendingValueInState;
			// Combine these conditions, if neither pass we probably shouldn't update the field's value at all
			const shouldUpdateValue =
				hasUpdatedFieldValueFromSaveResult || shouldUseOptimisticPendingValueAsFallback;
			if (shouldUpdateValue) {
				const value = hasUpdatedFieldValueFromSaveResult
					? // @ts-expect-error - TS2339 - Property 'updatedFieldValue' does not exist on type 'unknown'.
						saveFieldResult?.updatedFieldValue
					: pendingValue;

				const key = fieldOptions.isRichTextField ? 'adfValue' : 'value';
				newState = changeField(newState, fieldId, {
					[key]: value,
				});
			}

			return newState;
		}

		case FIELD_UPDATE: {
			const { fieldId, fieldValue } = action.payload;

			if (!Object.prototype.hasOwnProperty.call(state, fieldId)) {
				return state;
			}

			return changeField(
				state,
				fieldId,
				{
					value: fieldValue,
				},
				['pendingValue', 'editingValue', 'fieldEditSessionId'],
			);
		}

		case SWEET_STATE_FIELD_SAVE_FAILURE:
		case FIELD_SAVE_FAILURE: {
			const { fieldId, invalidMessage, fieldOptions } = action.payload;

			const fieldValue = state[fieldId];
			if (!fieldValue) {
				return state;
			}
			const { pendingValue } = fieldValue;

			const additionalPropertiesToDelete = fieldOptions.shouldDiscardChangeOnFailure
				? ['editingValue']
				: [];

			return changeField(
				state,
				fieldId,
				{
					editingValue: pendingValue,
					isInvalid: true,
					invalidMessage,
				},
				// @ts-expect-error - TS2322 - Type 'string' is not assignable to type '"name" | "value" | "isInvalid" | "renderedValue" | "invalidMessage" | keyof BaseField | "adfValue" | "editingValue" | "pendingValue" | "fieldEditSessionId"'.
				['pendingValue', 'fieldEditSessionId', ...additionalPropertiesToDelete],
			);
		}

		case FIELD_ADD_ALLOWED_VALUES: {
			const { allowedValues, fieldId } = action.payload;
			const oldAllowedValues = (state[fieldId] && state[fieldId].allowedValues) || [];

			return changeField(state, fieldId, {
				allowedValues: [...oldAllowedValues, ...allowedValues],
			});
		}

		case UPDATE_TIME_REMAINING_SUCCESS: {
			const { timeRemaining: remainingEstimateSeconds } = action.payload;
			return {
				...state,
				[TIME_TRACKING]: {
					...state[TIME_TRACKING],
					value: {
						...state[TIME_TRACKING].value,
						remainingEstimateSeconds,
					},
				},
			};
		}

		case ADD_WORKLOG_SUCCESS: {
			const { timeTracking: value } = action.payload;
			return changeField(state, TIME_TRACKING, { value });
		}

		case EDIT_WORKLOG_SUCCESS:
		case DELETE_WORKLOG_SUCCESS: {
			const { timeSpentSecondsDelta, newRemainingEstimateSeconds } =
				action.payload.timeTrackingDeltas;
			const timeTrackingValue: TimeTrackingState = state[TIME_TRACKING].value;

			const newTimeTrackingValue = {
				...timeTrackingValue,
				timeSpentSeconds: (timeTrackingValue.timeSpentSeconds || 0) + timeSpentSecondsDelta,
			};
			if (newRemainingEstimateSeconds !== null) {
				newTimeTrackingValue.remainingEstimateSeconds = newRemainingEstimateSeconds;
			}

			return changeField(state, TIME_TRACKING, { value: newTimeTrackingValue });
		}

		case UPDATE_APPROVAL_LINKED_USER_PICKER_FIELD: {
			const { linkedUserPickerFieldId, newApproverList } = action.payload;
			if (linkedUserPickerFieldId && state[linkedUserPickerFieldId]) {
				return changeField(state, linkedUserPickerFieldId, {
					value: newApproverList,
				});
			}
			return state;
		}

		default:
			return state;
	}
};
