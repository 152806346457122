import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

const SprintEditViewEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-sprint-edit-view" */ './ui/sprint'),
	),
	getPreloadProps: () => ({}),
});

export default SprintEditViewEntryPoint;
