import { combineEpics } from 'redux-observable';
import 'rxjs/add/operator/mapTo';
import {
	ATTACHMENT_MEDIA_SERVICES_UPLOAD_END,
	ATTACHMENT_JIRA_CREATE_FAILURE,
	ATTACHMENT_JIRA_CREATE_SUCCESS,
} from '@atlassian/jira-issue-view-store/src/actions/attachment-picker-actions.tsx';
import {
	markTokenAsOutdated,
	markTokenAsUpToDate,
	fetchViewContextRequest,
} from '@atlassian/jira-issue-view-store/src/common/media/view-context/view-context-actions.tsx';

// @ts-expect-error - TS2304 - Cannot find name 'ActionsObservable'.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const markTokenAsOutdatedOnAttachmentUploadEnd = (action$: ActionsObservable<any>) =>
	action$.ofType(ATTACHMENT_MEDIA_SERVICES_UPLOAD_END).mapTo(markTokenAsOutdated());

// @ts-expect-error - TS2304 - Cannot find name 'ActionsObservable'.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const markTokenAsUpToDateOnAttachmentCreateFailure = (action$: ActionsObservable<any>) =>
	action$.ofType(ATTACHMENT_JIRA_CREATE_FAILURE).mapTo(markTokenAsUpToDate());

// @ts-expect-error - TS2304 - Cannot find name 'ActionsObservable'.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fetchViewContextOnAttachmentCreateSuccess = (action$: ActionsObservable<any>) =>
	action$.ofType(ATTACHMENT_JIRA_CREATE_SUCCESS).mapTo(fetchViewContextRequest());

export default combineEpics(
	markTokenAsOutdatedOnAttachmentUploadEnd,
	markTokenAsUpToDateOnAttachmentCreateFailure,
	fetchViewContextOnAttachmentCreateSuccess,
);
