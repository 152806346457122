import type { Option } from '@atlassian/jira-issue-field-project-editview-full/src/ui/project/types.tsx';
import type { AggJiraProject } from './types.tsx';

/** Determine if the updated edit view value is equal to our original value before submitting a mutation. */
export const isEqualOption = (a: AggJiraProject, b: AggJiraProject) => a?.id === b?.id;

export const tranformOptionToValue = (value: Option | null): AggJiraProject =>
	value
		? {
				name: value.label,
				id: value.value ?? '',
				avatar: value.iconUrl
					? {
							small: value.iconUrl?.small,
							large: value.iconUrl?.large,
						}
					: undefined,
				projectId: value.projectId,
				key: value.key ?? '',
			}
		: null;
