import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'development-summary-pull-request.title',
		defaultMessage:
			'{boldStart}{count}{boldEnd} {count, plural, one {pull request} other {pull requests}}',
		description:
			"The number of pull requests associated with a Jira issue. 'Pull request' is a technical term from the tool Git that is most often not translated. It is acceptable to transliterate this term to another alphabet/script.",
	},
	emptyState: {
		id: 'development-summary-pull-request.empty-state',
		defaultMessage: '{Icon} Pull request',
		description:
			"Placeholder for the number of pull requests associated with a Jira issue. 'Pull request' is a technical term from the tool Git that is most often not translated. It is acceptable to transliterate this term to another alphabet/script.",
	},
	emptyStateUpdate: {
		id: 'development-summary-pull-request.empty-state-update',
		defaultMessage: 'Pull request',
		description:
			"Placeholder for the number of pull requests associated with a Jira issue. 'Pull request' is a technical term from the tool Git that is most often not translated. It is acceptable to transliterate this term to another alphabet/script.",
	},
	tooltip: {
		id: 'development-summary-pull-request.tooltip',
		defaultMessage: 'Open pull request',
		description:
			"The tool tip message indicating that clicking the link will lead to external link of a pull request. 'Pull request' is a technical term from the tool Git that is most often not translated. It is acceptable to transliterate this term to another alphabet/script.",
	},
});
