import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	createFeatureFlagMessage: {
		id: 'development-summary-feature-flag.common.feature-flag-action-dropdown-simple.create-feature-flag-message',
		defaultMessage: 'Create feature flag',
		description:
			"The cta users click to create a feature flag in an external tool. 'Feature Flag' is a technical term, and is most often not translated.",
	},
	connectFeatureFlagMessage: {
		id: 'development-summary-feature-flag.common.feature-flag-action-dropdown-simple.connect-feature-flag-message',
		defaultMessage: 'Connect feature flag',
		description:
			"The cta users click to connect an existing feature flag in an external SCM tool. 'Feature Flag' is a technical term, and is most often not translated.",
	},
	sectionTitle: {
		id: 'development-summary-feature-flag.common.feature-flag-action-dropdown-simple.section-title',
		defaultMessage: 'Actions',
		description: 'The heading title for the section that wraps the Call to Actions for users',
	},
});
