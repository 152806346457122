import type { AttachmentsResponse } from '@atlassian/jira-issue-attachments-base/src/services/attachments-service/types.tsx';
import type { ActivitySortOrderType } from '@atlassian/jira-issue-shared-types/src/common/types/activity-sort-order-type.tsx';
import type { ServerRemoteLinks } from '@atlassian/jira-issue-shared-types/src/common/types/remote-link-type.tsx';
import type { MyPreferences } from '@atlassian/jira-issue-shared-types/src/common/types/user-preferences-type.tsx';
import type { Comment, IssueWorkflow } from '../../model/issue.tsx';

export const IssueKey = 'IssueKeyField';
type Option = {
	optionId: number;
	value: string;
};

type BaseField = {
	__typename: string;
	fieldKey: string;
	title: string;
	isEditable: boolean;
};

type Epic = {
	id: number;
	key: string;
	name?: string;
	summary: string;
	colorKey?: string;
	done: boolean;
};

type EpicLink = {
	epic: Epic | null;
} & BaseField;

export type IssueKeyField = {
	__typename: 'IssueKeyField';
	fieldKey: 'issuekey';
	title: string;
	stringValue: string;
};

type IssueType = {
	id: string;
	iconUrl: string;
	name: string;
} & BaseField;

type Labels = {
	labels: string[];
} & BaseField;

type Priority = {
	id: string;
	name: string;
	iconUrl: string;
} & BaseField;

type ProjectComponents = {
	componentValues: {
		id: number;
		name: string;
	}[];
} & BaseField;

type Status = {
	name: string;
	// make this required when `issue.details.bento-8045.transitions-fetch-ssr` is cleaned up
	statusId?: string;
	statusCategoryId: number;
} & BaseField;

type TimeTracking = {
	originalEstimate?: {
		estimateSeconds: number;
	};
	remainingEstimate?: {
		estimateSeconds: number;
	};
	timeSpent?: {
		estimateSeconds: number;
	};
} & BaseField;
type StoryPoints = {
	floatValue: number | null;
} & BaseField;

type Sprint = {
	id: number;
	name: string;
	goal?: string;
	state?: string;
	boardId: number;
	startDate?: string;
	endDate?: string;
	completeDate?: string;
};

type SprintCustomField = {
	values: Sprint[];
} & BaseField;

type FlaggedField = {
	isFlagged: boolean;
	selectedValues?: Option[];
} & BaseField;

type TextField = {
	textValue: string;
	adf: string;
	renderer?: string | null;
} & BaseField;

type UserValue = {
	name: string;
	displayName: string;
	avatarUrl: string;
	accountId?: string;
};

type User = {
	userValue: UserValue | null;
} & BaseField;

type Version = {
	id: string;
	description: string;
	name: string;
};

type Versions = {
	versions: Version[];
} & BaseField;

type DueDate = {
	stringValue: string;
} & BaseField;

// Custom Fields

type CheckBoxesCustomField = {
	selectedValues: Option[];
} & BaseField;

type DatePickerCustomField = {
	stringValue: string | null;
} & BaseField;

type DateTimeCustomField = {
	dateTime: string | null;
} & BaseField;

type FloatCustomField = {
	floatValue: number | null;
} & BaseField;

type GroupPickerCustomField = {
	group: string | null;
} & BaseField;

type LabelsCustomField = {
	labels: string[];
} & BaseField;

type MultiGroupPickerCustomField = {
	groups: string[];
} & BaseField;

type MultiSelectCustomField = {
	selectedValues: Option[];
} & BaseField;

type MultiUserPickerCustomField = {
	selectedUsers: UserValue[];
} & BaseField;

type MultiVersionCustomField = {
	versions: Version[];
} & BaseField;

type RadioButtonsCustomField = {
	selectedValue: Option | null;
} & BaseField;

type SelectCustomField = {
	selectedValue: Option | null;
} & BaseField;

type CascadingSelectCustomField = {
	selectedValue:
		| (Option & {
				child: Option | null;
		  })
		| null;
} & BaseField;

type TextAreaCustomField = {
	wiki: string | null;
	adf: string | null;
	renderer?: string | null;
	textValue?: string | null;
} & BaseField;

type TextFieldCustomField = {
	adf: string | null;
	wiki: string | null;
} & BaseField;

type UrlCustomField = {
	url: string | null;
} & BaseField;

type UserPickerCustomField = {
	selectedUser: UserValue | null;
} & BaseField;

type VersionCustomField = {
	version: Version | null;
} & BaseField;

type ColorCustomField = {
	isPresent: boolean;
	stringValue: string | null;
} & BaseField;

type EpicColorCustomField = {
	isPresent: boolean;
	stringValue: string | null;
} & BaseField;

type ProjectCustomField = {
	name: string;
	avatarUrl: string;
	key: string;
} & BaseField;

type EpicStatusField = {
	selectedValue: Option | null;
} & BaseField;

// All

type FieldType =
	| Labels
	| IssueType
	| IssueKeyField
	| Priority
	| ProjectComponents
	| Status
	| TextField
	| User
	| Versions
	| TimeTracking // Managed Fields
	| DueDate
	| EpicLink
	| SprintCustomField
	| StoryPoints // Custom Fields
	| FlaggedField
	| CheckBoxesCustomField
	| DatePickerCustomField
	| DateTimeCustomField
	| FloatCustomField
	| GroupPickerCustomField
	| LabelsCustomField
	| MultiGroupPickerCustomField
	| MultiSelectCustomField
	| MultiUserPickerCustomField
	| MultiVersionCustomField
	| RadioButtonsCustomField
	| SelectCustomField
	| CascadingSelectCustomField
	| TextAreaCustomField
	| TextFieldCustomField
	| UrlCustomField
	| UserPickerCustomField
	| VersionCustomField
	| ColorCustomField
	| EpicColorCustomField
	| ProjectCustomField
	| EpicStatusField;

type Comments = {
	totalCount: number;
	startIndex: number;
	nodes: Comment[];
};

export type ViewIssue = {
	issueId: string;
	fields: FieldType[];
	meta: {
		newIssueViewLockedIn: boolean;
	};
	remoteLinks: ServerRemoteLinks;
	myPreferences: MyPreferences;
	comments: Comments | null;
	attachments: AttachmentsResponse;
	// make this required when `issue.details.bento-8045.transitions-fetch-ssr` is cleaned up
	workflow?: IssueWorkflow;
	activitySortOrder: ActivitySortOrderType;
};

export type ViewIssueRemoteData = {
	issueId: string;
	meta: {
		newIssueViewLockedIn: boolean;
	};
	remoteLinks: ServerRemoteLinks;
};

export type ViewIssueGraphqlResponse = {
	viewIssue: ViewIssue | null | undefined;
};

export type ViewMultipleIssueGraphqlResponse = {
	nodes: ViewIssue[];
};

type IssueToWhiteboardEdge = {
	node: {
		whiteboardId: string;
		author: {
			user: {
				accountId: string;
				accountStatus: string;
				name: string;
				picture: string;
			};
		};
		links: {
			webUi: string;
			base: string;
		};
		id: string;
		title: string;
	};
};

export type ConfluenceWhiteboardsGraphqlResponse = {
	graphStore: {
		issueToWhiteboard: {
			edges: IssueToWhiteboardEdge[];
			totalCount: number;
		};
	};
};

export type GraphqlResponse<
	T extends
		| ViewIssueGraphqlResponse
		| ViewMultipleIssueGraphqlResponse
		| ConfluenceWhiteboardsGraphqlResponse,
> = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	errors: any;
	data: T;
};
