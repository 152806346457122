import { expValEquals, UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';

export const isInviteOnLinkInsertV2Enrolled = () => {
	const [expConfig] = UNSAFE_noExposureExp('groot-aioli-v5');
	const cohort = expConfig.get('cohort', 'not-enrolled');
	return cohort !== 'not-enrolled';
};

export const isThirdPartyNudgeEnrolled = () => {
	if (!fg('statsig-guard-third-party-nudge')) return false;

	const [expConfig] = UNSAFE_noExposureExp('third_party_app_nudge_on_after_jira_comment');
	const cohort = expConfig.get('cohort', 'not-enrolled');

	return ['cohort', 'variation'].includes(cohort);
};

export const isDevOpsAppRecommenderEnabled = () =>
	ff('devops_app_recommendation_on_link_paste', false) &&
	expValEquals('devops_app_recommendation_on_link_paste', 'cohort', 'variation');
