import type { NullableOption } from '@atlassian/jira-issue-field-sprint-editview-full/src/ui/sprint/types.tsx';
import { isClosedSprint } from '@atlassian/jira-issue-field-sprint-readview-full/src/common/utils.tsx';
import type { sprint_issueFieldSprint_SprintField_Mutation$rawResponse as SprintOptimisticResponse } from '@atlassian/jira-relay/src/__generated__/sprint_issueFieldSprint_SprintField_Mutation.graphql';
import type { sprint_issueFieldSprintInlineEditFull_SprintInlineEditViewIsEditable_fragmentRef$data as SprintData } from '@atlassian/jira-relay/src/__generated__/sprint_issueFieldSprintInlineEditFull_SprintInlineEditViewIsEditable_fragmentRef.graphql';
import type { AggJiraSprint } from './types.tsx';

export type SelectedSprintEdges = NonNullable<
	NonNullable<SprintData['selectedSprintsConnection']>['edges']
>;

/** Map the updated sprint option to an optimistic response when mutating the field. */
export const toOptimisticResponse = (
	fieldId: string,
	option: NullableOption,
	previousEdges: SelectedSprintEdges,
): SprintOptimisticResponse => {
	// Previously closed sprints should still be displayed for the updated field
	const selectedSprints = previousEdges.filter((edge) => edge?.node && isClosedSprint(edge.node));

	if (option) {
		selectedSprints.unshift({
			node: {
				id: option.value,
				name: option.label,
				state: option.state,
				sprintId: option.sprintId,
				endDate: null,
			},
		});
	}

	return {
		jira: {
			updateSprintField: {
				success: true,
				errors: null,
				field: {
					id: fieldId,
					selectedSprintsConnection: {
						edges: selectedSprints,
					},
				},
			},
		},
	};
};

export const transformOptionToAgg = (
	currentEdges: SelectedSprintEdges,
	option: NullableOption,
): AggJiraSprint => {
	// Previously closed sprints should still be displayed for the updated field
	const selectedSprints = currentEdges.filter((edge) => edge?.node && isClosedSprint(edge.node));

	if (option) {
		selectedSprints.unshift({
			node: {
				id: option.value,
				name: option.label,
				state: option.state,
				sprintId: option.sprintId,
				endDate: null,
			},
		});
	}

	return {
		selectedSprintsConnection: {
			edges: selectedSprints,
		},
	};
};
