import React from 'react';
import {
	JSErrorBoundary,
	type JSErrorBoundaryProps,
} from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';

type Props = Omit<JSErrorBoundaryProps, 'packageName' | 'teamName'> &
	Required<Pick<JSErrorBoundaryProps, 'fallback'>>;

export const AutodevErrorBoundary = (props: Props) => (
	<JSErrorBoundary packageName="jiraAutodev" teamName="fusion-isotopes" {...props} />
);
