import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { FIELD_TEMPLATE } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/field/types.tsx';
import type { DynamicFieldFormula } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/types.tsx';
import { getVisibleFieldMappings } from '../../../../selectors/fields.tsx';
import { isInitialized } from '../../../../selectors/meta.tsx';
import type { Props, State } from '../../../../types.tsx';
import type { DynamicFieldResolver } from '../../types.tsx';
import {
	FORMULA_TEMPLATE as NORMALIZATION_TEMPLATE,
	normalizationResolver,
} from '../norm/index.tsx';

export const fieldResolver: DynamicFieldResolver<number> = (formula: DynamicFieldFormula) => {
	if (formula === undefined || formula.template !== FIELD_TEMPLATE) {
		return undefined;
	}
	if (formula.parameters.normalize === true) {
		return normalizationResolver({
			template: NORMALIZATION_TEMPLATE,
			parameters: {
				formula: {
					template: FIELD_TEMPLATE,
					parameters: {
						id: formula.parameters.id,
					},
				},
			},
		});
	}
	return (localIssueId: LocalIssueId) => (state: State, props: Props | undefined) => {
		if (!isInitialized(state)) {
			return undefined;
		}

		const fieldKey = formula.parameters.id;
		const fieldsMappings = getVisibleFieldMappings(state, props);
		const mapping = fieldsMappings[fieldKey];
		if (mapping === undefined) {
			return undefined;
		}
		const value = mapping.valueAccessor(state, props, localIssueId);
		return value ?? 0;
	};
};
