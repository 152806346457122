import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type {
	AccessLevel,
	ProjectAccessLevelType,
} from '../../../../common/types/access/index.tsx';

type JiraAccessLevel = {
	value: string;
	displayName: string;
	description: string;
	iconName: string;
};

type JiraResponse = {
	accessLevel: JiraAccessLevel;
};

const transformType = (value: string): ProjectAccessLevelType =>
	value === 'OPEN' || value === 'LIMITED' || value === 'PRIVATE' || value === 'FREE'
		? value
		: 'UNKNOWN';

const transform = (response: JiraResponse): AccessLevel | undefined => {
	if (response.accessLevel === undefined) {
		return undefined;
	}
	return {
		type: transformType(response.accessLevel.value),
		label: response.accessLevel.displayName,
		description: response.accessLevel.description,
		iconName: response.accessLevel.iconName,
	};
};

export const fetchProjectAccessLevel = (projectId: string): Promise<AccessLevel | undefined> =>
	fetchJson(`/rest/internal/simplified/1.0/projects/${projectId}/summary`, {
		method: 'GET',
	}).then(transform);
