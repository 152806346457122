import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

const DateEditViewEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-date-edit-view" */ './ui/date'),
	),
	getPreloadProps: () => ({}),
});

export default DateEditViewEntryPoint;
