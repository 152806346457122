import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	add: {
		id: 'issue-view-foundation.quick-add.quick-add-items-compact.add',
		defaultMessage: 'Add',
		description:
			'Command name in the command palette to add an attachment to an issue with quick actions list enabled',
	},
	apps: {
		id: 'issue-view-foundation.quick-add.quick-add-items-compact.apps',
		defaultMessage: 'Apps',
		description:
			'Command name in the command palette to add an attachment to an issue with quick actions list enabled',
	},
	buttonAddContent: {
		id: 'issue-view-foundation.quick-add.quick-add-items-compact.button-add-content',
		defaultMessage: 'Add content',
		description: 'Button label for adding content in the quick add list',
	},
	buttonAddApps: {
		id: 'issue-view-foundation.quick-add.quick-add-items-compact.button-add-apps',
		defaultMessage: 'Open or add apps',
		description: 'Button label for adding apps in the quick add list',
	},
	buttonAddAppsNoApps: {
		id: 'issue-view-foundation.quick-add.quick-add-items-compact.button-add-apps-no-apps',
		defaultMessage: 'Add apps',
		description: 'Button label for adding apps in the quick add list',
	},
});
