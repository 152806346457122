import React from 'react';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import type { Fallback } from '@atlassian/jira-issue-view-common-utils/src/safe-component/index.tsx';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import { getDisplayNamePrefix, getFallbackComponent } from '../connect-field.tsx';

const analyticsData = {
	componentName: 'issueFields',
	actionSubject: 'issueFields',
};

type ConnectedRelayFieldWrapperProps = {
	componentName: string;
	FallbackField?: Fallback;
	children: React.ReactNode;
};

export function ConnectedRelayFieldWrapper(props: ConnectedRelayFieldWrapperProps) {
	const { componentName, FallbackField, children } = props;

	const FallbackComponent = getFallbackComponent(FallbackField);

	return (
		<ErrorBoundary
			prefixOverride="issue"
			id={`${getDisplayNamePrefix(FallbackField)}(${componentName})`}
			render={() => <FallbackComponent />}
		>
			<ContextualAnalyticsData {...analyticsData}>{children}</ContextualAnalyticsData>
		</ErrorBoundary>
	);
}
