// FETCH_CONFLUENCE_APP_LINKS_REQUEST
export const FETCH_CONFLUENCE_APP_LINKS_REQUEST = 'FETCH_CONFLUENCE_APP_LINKS_REQUEST';

export const fetchConfluenceAppLinks = () => ({
	type: FETCH_CONFLUENCE_APP_LINKS_REQUEST,
});

// FETCH_CONFLUENCE_APP_LINKS_SUCCESS
export const FETCH_CONFLUENCE_APP_LINKS_SUCCESS = 'FETCH_CONFLUENCE_APP_LINKS_SUCCESS';

export type AppLink = {
	id: string;
	name: string;
};

export type ConfluenceAppLinksResponse = {
	applicationLinks: AppLink[];
};

export const fetchConfluenceAppLinksSuccess = (response: ConfluenceAppLinksResponse) => ({
	type: FETCH_CONFLUENCE_APP_LINKS_SUCCESS,
	payload: response.applicationLinks,
});

// FETCH_CONFLUENCE_APP_LINKS_FAILED
export const FETCH_CONFLUENCE_APP_LINKS_FAILED = 'FETCH_CONFLUENCE_APP_LINKS_FAILED';

export const fetchConfluenceAppLinksFailed = () => ({
	type: FETCH_CONFLUENCE_APP_LINKS_FAILED,
});

export type FetchConfluenceAppLinksSuccess = ReturnType<typeof fetchConfluenceAppLinksSuccess>;
