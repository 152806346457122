import React, { useCallback, useRef } from 'react';
import { graphql, useFragment } from 'react-relay';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import type { Area } from '@atlassian/jira-issue-view-common-types/src/connect-field-type.tsx';
import { IssueViewSingleLineTextField } from '@atlassian/jira-issue-view-layout-single-line-text-field/src/ui/index.tsx';
import { IssueViewSingleLineTextMessageField } from '@atlassian/jira-issue-view-layout-single-line-text-message-field/src/ui/index.tsx';
import {
	MESSAGE_VIEW_CF_TYPE,
	MESSAGE_EDIT_CF_TYPE,
	SUMMARY_TYPE,
	RANK_CF_TYPE,
	READ_ONLY_CF_TYPE,
	TEXT_CF_TYPE,
	EPIC_NAME_TYPE,
	TIME_IN_STATUS_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { ui_issueViewLayoutSingleLineText_IssueViewSingleLineText$key } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutSingleLineText_IssueViewSingleLineText.graphql';

export interface IssueViewSingleLineTextProps {
	area?: Area;
	fragmentKey: ui_issueViewLayoutSingleLineText_IssueViewSingleLineText$key;
}

export const IssueViewSingleLineText = ({ area, fragmentKey }: IssueViewSingleLineTextProps) => {
	const data = useFragment<ui_issueViewLayoutSingleLineText_IssueViewSingleLineText$key>(
		graphql`
			fragment ui_issueViewLayoutSingleLineText_IssueViewSingleLineText on JiraSingleLineTextField
			@argumentDefinitions(
				issueViewRelaySingleLineTextMessageFieldFlag: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/relay-migration-issue-fields-single-line-text-message.relayprovider"
				}
			) {
				...ui_issueViewLayoutSingleLineTextMessageField_IssueViewSingleLineTextMessageField
					@include(if: $issueViewRelaySingleLineTextMessageFieldFlag)
				...ui_issueViewLayoutSingleLineTextField_IssueViewSingleLineTextField
				type
			}
		`,
		fragmentKey,
	);

	const hasLoggedUnknownType = useRef(false);

	const logUnknownType = useCallback(() => {
		if (!hasLoggedUnknownType.current) {
			hasLoggedUnknownType.current = true;
			log.safeWarnWithoutCustomerData(
				'issue.fields.issue-view-layout.single-line-text-field',
				`unknown fieldType ${data.type}`,
			);
		}
	}, [data.type]);

	/**
	 * See https://hello.atlassian.net/wiki/spaces/JDP/pages/4342790003/ADR-008+How+to+handle+JiraSingleTextField+weird+types
	 * Note - we may not reach here for some of these types, but better to be explicit if things upstream change
	 * See the above page for more details about why
	 */

	switch (data.type) {
		// Summary field is added directly to foundation section
		// The others are not supported in issue view
		case SUMMARY_TYPE:
		case RANK_CF_TYPE:
		case TIME_IN_STATUS_CF_TYPE:
		case 'com.atlassian.jconnect.jconnect-plugin:uuid':
			return null;
		case MESSAGE_VIEW_CF_TYPE:
		case MESSAGE_EDIT_CF_TYPE:
			return <IssueViewSingleLineTextMessageField area={area} fragmentKey={data} />;
		case TEXT_CF_TYPE:
		case READ_ONLY_CF_TYPE:
		case EPIC_NAME_TYPE:
		case 'com.atlassian.jira.toolkit:userproperty': // Note - existing behavior of this field means the read works, but mutation errors
			return <IssueViewSingleLineTextField area={area} fragmentKey={data} />;
		default:
			logUnknownType();
			return null;
	}
};
