/**
 * @generated SignedSource<<6d7168912f79bee85bed1ccee310f328>>
 * @relayHash 8f24b0a55ab6fe89f5c8bc1fa327d7c1
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID d9c5b7aee18c39f13d4f88772b857edb6c4c96ea985683347abb9a3f432ef5b1

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type prefetchFieldSuggestionsQuery$variables = {
  first?: number | null | undefined;
  labelsFieldAri: string;
};
export type prefetchFieldSuggestionsQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"labels_issueFieldLabelsEditviewFull_LabelsEditViewWithSuggestionsFragment_suggestionsFragment">;
};
export type prefetchFieldSuggestionsQuery = {
  response: prefetchFieldSuggestionsQuery$data;
  variables: prefetchFieldSuggestionsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "labelsFieldAri"
},
v2 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v3 = {
  "kind": "Variable",
  "name": "id",
  "variableName": "labelsFieldAri"
},
v4 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v5 = [
  (v2/*: any*/),
  {
    "kind": "Literal",
    "name": "searchBy",
    "value": ""
  },
  {
    "kind": "Literal",
    "name": "suggested",
    "value": true
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "name": "prefetchFieldSuggestionsQuery",
    "selections": [
      {
        "args": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "labels_issueFieldLabelsEditviewFull_LabelsEditViewWithSuggestionsFragment_suggestionsFragment"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "prefetchFieldSuggestionsQuery",
    "selections": [
      {
        "args": [
          (v3/*: any*/)
        ],
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": (v5/*: any*/),
                "concreteType": "JiraLabelConnection",
                "kind": "LinkedField",
                "name": "labels",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraLabelEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "JiraLabel",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "name"
                          },
                          (v4/*: any*/)
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "name": "cursor"
                      }
                    ]
                  },
                  {
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "endCursor"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "hasNextPage"
                      }
                    ]
                  }
                ]
              },
              {
                "args": (v5/*: any*/),
                "filters": [
                  "suggested",
                  "searchBy",
                  "currentProjectOnly"
                ],
                "handle": "connection",
                "key": "labels_issueFieldLabelsEditviewFull_LabelsEditViewWithSuggestionsFragmentSearch__labels",
                "kind": "LinkedHandle",
                "name": "labels"
              }
            ],
            "type": "JiraLabelsField"
          },
          {
            "kind": "ScalarField",
            "name": "id"
          }
        ]
      }
    ]
  },
  "params": {
    "id": "d9c5b7aee18c39f13d4f88772b857edb6c4c96ea985683347abb9a3f432ef5b1",
    "metadata": {},
    "name": "prefetchFieldSuggestionsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "75911a5d3598b5c20c07849689228da3";

export default node;
