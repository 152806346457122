import React, { type FC } from 'react';
import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

type Props = {
	color?: string;
	proportion?: number;
};

const ProgressBarSegment: FC<Props> = ({
	color = token('color.border.brand', colors.B400),
	proportion = 0,
}) => {
	const width = proportion > 0 ? `${proportion * 100}%` : '0';
	return (
		<Segment
			data-testid="issue.component.progress-tracker.progress-bar.progress-bar-segment"
			color={color}
			width={width}
		/>
	);
};

export default ProgressBarSegment;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Segment = styled.div<{
	color: string;
	width: string | number;
}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: (props) => props.color,
	flexShrink: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: (props) => props.width,
	transition: 'width 0.3s ease',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:not(:last-child)': {
		borderRadius: 0,
		marginRight: token('space.025', '2px'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:last-child': {
		marginRight: 0,
	},
});
