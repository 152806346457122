import React from 'react';
import ExportIssueConfluence from './export-issue-confluence/index.tsx';
import ExportIssueDoc from './export-issue-doc/index.tsx';
import ExportIssuePDF from './export-issue-pdf/index.tsx';
import ExportIssueXML from './export-issue-xml/index.tsx';
import PrintIssue from './print-issue/index.tsx';

const issueExportDropdownGroup = (shouldShowExportActions: boolean) =>
	({
		name: '',
		key: 'issueExportDropdownGroup',
		items: [
			...(shouldShowExportActions
				? [
						<PrintIssue key="printIssue" />,
						<ExportIssueXML key="exportIssueXML" />,
						<ExportIssueDoc key="exportIssueDoc" />,
						<ExportIssueConfluence key="exportIssueConfluence" />,
						<ExportIssuePDF key="exportIssuePDF" />,
					]
				: [<PrintIssue key="printIssue" />]),
		],
	}) as const;

export default issueExportDropdownGroup;
