import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { styled } from '@compiled/react';
import { type PreloadedQuery, graphql, useQueryLoader, usePreloadedQuery } from 'react-relay';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import Lozenge from '@atlaskit/lozenge';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import { SummaryItem } from '@atlassian/jira-development-summary-common/src/ui/summary-item/index.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { useIntl } from '@atlassian/jira-intl';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	DROPDOWN,
} from '@atlassian/jira-product-analytics-bridge';
import type { uiGetAutofixJobsQuery as GetAutofixJobQueryType } from '@atlassian/jira-relay/src/__generated__/uiGetAutofixJobsQuery.graphql';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { STATUS } from '../constants.tsx';
import { isBitbucketPermError, type JobStatus } from '../types.tsx';
import FFAgentIcon from './assets/ff-agent-icon.tsx';
import { CreatePrDropdown } from './create-pr-dropdown/index.tsx';
import messages from './messages.tsx';
import { useIsEligible } from './use-is-eligible/index.tsx';

type DraftPRRenderProps = {
	issueAri: string;
	autofixQueryRef: PreloadedQuery<GetAutofixJobQueryType>;
	isEligible: boolean;
	eligibilityFailureMessages: string[];
};

const AutofixQuery = graphql`
	query uiGetAutofixJobsQuery($issueAri: ID!) {
		jira {
			devOps {
				autofixJobs(issueAri: $issueAri) @required(action: THROW) @optIn(to: "Jira-autofix-jobs") {
					edges {
						node {
							status
							errorMessage
							errorType
						}
					}
				}
			}
		}
	}
`;

const DraftPRRender = ({
	issueAri,
	autofixQueryRef,
	isEligible,
	eligibilityFailureMessages,
}: DraftPRRenderProps) => {
	const [status, setStatus] = useState<JobStatus | undefined>();

	const { formatMessage } = useIntl();

	const preloadedQuery = usePreloadedQuery<GetAutofixJobQueryType>(AutofixQuery, autofixQueryRef);

	const autofixJobs = preloadedQuery?.jira?.devOps?.autofixJobs?.edges;

	const lastJob = useMemo(
		() => (autofixJobs && autofixJobs.length > 0 ? autofixJobs[autofixJobs.length - 1] : undefined),
		[autofixJobs],
	);

	const errorType = lastJob?.node?.errorType;
	const errorMessage = lastJob?.node?.errorMessage;

	const ffcleanupError = useMemo(() => {
		if (errorType === 'GitCloneException' && errorMessage) {
			const repoRegex = /https:\/\/bitbucket\.org\/(.+)\.git/;
			const match = errorMessage.match(repoRegex);

			if (
				match &&
				match.length > 1 &&
				errorMessage.includes('The requested URL returned error: 403')
			) {
				return {
					errorType,
					errorMessage,
					repoUrl: match[0],
					repoName: match[1],
				};
			}
		}

		return {
			errorType,
			errorMessage,
		};
	}, [errorType, errorMessage]);

	useEffect(() => {
		setStatus(lastJob?.node?.status);
	}, [lastJob?.node?.status]);

	const downChevron = useMemo(
		() => <ChevronDownIcon size="medium" label={formatMessage(messages.title)} />,
		[formatMessage],
	);

	const downChevronFailed = useMemo(
		() => (
			<>
				<LozengeWrapper>
					<Lozenge appearance="removed">
						{ffcleanupError && isBitbucketPermError(ffcleanupError)
							? formatMessage(messages.permissionFailure)
							: formatMessage(messages.failed)}
					</Lozenge>
				</LozengeWrapper>
				{downChevron}
			</>
		),
		[formatMessage, downChevron, ffcleanupError],
	);

	const downChevronSuccess = useMemo(
		() => (
			<>
				<LozengeWrapper>
					<Lozenge appearance="success">{formatMessage(messages.successNonFinal)}</Lozenge>
				</LozengeWrapper>
				{downChevron}
			</>
		),
		[formatMessage, downChevron],
	);

	const downChevronIneligible = useMemo(
		() => (
			<>
				<LozengeWrapper>
					<Lozenge appearance="moved">{formatMessage(messages.ineligible)}</Lozenge>
				</LozengeWrapper>
				{downChevron}
			</>
		),
		[formatMessage, downChevron],
	);

	const commonSummaryItemProps = {
		title: formatMessage(messages.title),
		linkFormatting: true,
		icon: <FFAgentIcon />,
	};

	const getFailedDropdown = () => (
		<CreatePrDropdown
			issueAri={issueAri}
			isEligible={isEligible}
			status={STATUS.FAILED}
			ffcleanupError={ffcleanupError}
			setStatus={setStatus}
			getDropdownTrigger={({ ref, onClick, ...props }) => (
				<SummaryItem
					disableAnimationOnUpdates
					secondaryData={downChevronFailed}
					reference={ref}
					{...commonSummaryItemProps}
					{...props}
					onClick={onClick}
					data-testid="autodev-draft-pr.ui.autodev-summary-item-create-draft-pr"
				/>
			)}
		/>
	);

	const getNoStatusDropdown = () => (
		<CreatePrDropdown
			issueAri={issueAri}
			isEligible={isEligible}
			eligibilityFailureMessages={eligibilityFailureMessages}
			status={status}
			setStatus={setStatus}
			getDropdownTrigger={({ ref, onClick, ...props }) => (
				<SummaryItem
					disableAnimationOnUpdates
					secondaryData={isEligible ? downChevron : downChevronIneligible}
					reference={ref}
					{...commonSummaryItemProps}
					{...props}
					onClick={onClick}
					data-testid="autodev-draft-pr.ui.autodev-summary-item-create-draft-pr"
				/>
			)}
		/>
	);

	const getInProgressStatus = () => (
		<SummaryItem
			secondaryData={<Spinner interactionName="load" label={formatMessage(messages.inProgress)} />}
			showIconInPlainLink
			icon={<FFAgentIcon />}
			title={formatMessage(messages.title)}
			linkFormatting={false}
			data-testid="autodev-draft-pr.ui.autodev-summary-item-create-draft-pr"
		/>
	);

	const getSuccessStatus = () => (
		<CreatePrDropdown
			issueAri={issueAri}
			isEligible={isEligible}
			status={STATUS.COMPLETED}
			ffcleanupError={ffcleanupError}
			setStatus={setStatus}
			getDropdownTrigger={({ ref, onClick, ...props }) => (
				<SummaryItem
					disableAnimationOnUpdates
					secondaryData={downChevronSuccess}
					reference={ref}
					{...commonSummaryItemProps}
					{...props}
					onClick={onClick}
					data-testid="autodev-draft-pr.ui.autodev-summary-item-create-draft-pr"
				/>
			)}
		/>
	);

	const analyticsStatus = useMemo(() => {
		if (!status) {
			if (isEligible) {
				return 'no-job-found';
			}
			return 'issue-ineligible';
		}

		return status;
	}, [isEligible, status]);

	return (
		<ContextualAnalyticsData sourceName="autodevDraftPR" sourceType={DROPDOWN}>
			{!status && getNoStatusDropdown()}
			{status === STATUS.FAILED && getFailedDropdown()}
			{(status === STATUS.PENDING || status === STATUS.IN_PROGRESS) && getInProgressStatus()}
			{status === STATUS.COMPLETED && getSuccessStatus()}
			<FireScreenAnalytics
				attributes={{
					autodevStatus: analyticsStatus,
				}}
			/>
		</ContextualAnalyticsData>
	);
};

type DraftPRProps = {
	issueId: string;
	issueKey: string;
};

type DraftPRQueryProps = {
	issueId: string;
	isEligible: boolean;
	eligibilityFailureMessages: string[];
};

const DraftPRQuery = ({ issueId, isEligible, eligibilityFailureMessages }: DraftPRQueryProps) => {
	const [queryReference, loadQuery] = useQueryLoader<GetAutofixJobQueryType>(AutofixQuery);
	const cloudId = useCloudId();

	const issueAri = useMemo(() => `ari:cloud:jira:${cloudId}:issue/${issueId}`, [cloudId, issueId]);

	const fetch = useCallback(() => {
		loadQuery({ issueAri }, { fetchPolicy: 'store-and-network' });
	}, [loadQuery, issueAri]);

	useEffect(() => {
		fetch();
	}, [fetch]);

	if (queryReference) {
		return (
			<JSErrorBoundary
				id="AutodevDraftPR"
				packageName="jiraAutodevDraftPr"
				teamName="autodev"
				fallback="unmount"
			>
				<Placeholder name="autodev-draft-pr-entrypoint">
					<DraftPRRender
						issueAri={issueAri}
						autofixQueryRef={queryReference}
						isEligible={isEligible}
						eligibilityFailureMessages={eligibilityFailureMessages}
					/>
				</Placeholder>
			</JSErrorBoundary>
		);
	}
	return <></>;
};

export const DraftPR = ({ issueId, issueKey }: DraftPRProps) => {
	const { isEligible, isProjectWhitelisted, isLoading, eligibilityFailureMessages } = useIsEligible(
		{
			issueId,
			issueKey,
		},
	);
	if (!isLoading && isProjectWhitelisted) {
		return (
			<DraftPRQuery
				issueId={issueId}
				isEligible={isEligible}
				eligibilityFailureMessages={eligibilityFailureMessages}
			/>
		);
	}

	return <></>;
};

export default DraftPR;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LozengeWrapper = styled.div({
	top: `${token('space.050', '4px')}`,
	position: 'relative',
});
