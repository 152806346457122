import { useCallback, useEffect, useState, type RefObject } from 'react';

const MAX_HEIGHT = 376;
const STARTING_HEIGHT = 56;
const ADDITIONAL_HEIGHT = 16;

export const useSmoothExpand = (content: RefObject<HTMLDivElement | null>) => {
	const [height, setHeight] = useState<number>(STARTING_HEIGHT);

	const callback = useCallback(() => {
		const contentClientHeight = content.current?.clientHeight;
		if (!contentClientHeight) return;
		if (contentClientHeight > MAX_HEIGHT) {
			setHeight(MAX_HEIGHT);
		} else {
			setHeight(contentClientHeight + ADDITIONAL_HEIGHT);
		}
	}, [content]);

	useEffect(() => {
		let resizeObserver: ResizeObserver;
		if (content.current && typeof ResizeObserver !== 'undefined') {
			resizeObserver = new ResizeObserver(callback);
			resizeObserver.observe(content.current);
		}

		return () => {
			if (resizeObserver) {
				resizeObserver.disconnect();
			}
		};
	}, [callback, content]);

	return height;
};
