import type { ReactElement } from 'react';
// eslint-disable-next-line camelcase
import { withStoreForIssueViewShortcutActions_DO_NOT_USE } from '@atlassian/jira-issue-view-common-views/src/store-provider/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { showCloneModal } from '@atlassian/jira-issue-view-store/src/actions/clone-modal-actions.tsx';
import CloneIssueButtonItemView from './button-item.tsx';
import CloneIssueView from './main.tsx';
import messages from './messages.tsx';
import type { OwnProps, Props, DispatchProps } from './types.tsx';

const connectToProps = (Component: (arg1: Props) => ReactElement) =>
	connect(
		null,
		(dispatch): DispatchProps => ({
			onCloneItemClick: () => {
				dispatch(showCloneModal());
			},
		}),
	)(Component);

export default withStoreForIssueViewShortcutActions_DO_NOT_USE<OwnProps>(
	// @ts-expect-error - TS2345 - Argument of type 'ComponentClass<Omit<ShortcutComponentProps & StateProps & DispatchProps, "onCloneItemClick">, any> & { ...; }' is not assignable to parameter of type 'ComponentType<ShortcutComponentProps>'.
	connectToProps(CloneIssueView),
);

export const cloneIssueLabel = messages.clone;

export const CloneIssueButtonItem = withStoreForIssueViewShortcutActions_DO_NOT_USE<OwnProps>(
	// @ts-expect-error - TS2345 - Argument of type 'ComponentClass<Omit<ShortcutComponentProps & StateProps & DispatchProps, "onCloneItemClick">, any> & { ...; }' is not assignable to parameter of type 'ComponentType<ShortcutComponentProps>'.
	connectToProps(CloneIssueButtonItemView),
);
