import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';
import initiateStore from '@atlassian/jira-common-tangerine/src/state/initiate.tsx';
import type { SetContextAction } from './context/actions/index.tsx';
import { contextReducer } from './context/reducers/index.tsx';
import type {
	ReleasesDataFetchAction,
	ReleasesDataFetchResponseAction,
} from './releases-data/actions/index.tsx';
import fetchDataOnReadyEpic from './releases-data/epics/index.tsx';
import { releasesDataReducer } from './releases-data/reducers/index.tsx';
import type { State } from './types.tsx';

export type ReleaseGlanceActions =
	| ReleasesDataFetchAction
	| ReleasesDataFetchResponseAction
	| SetContextAction;

export const rootReducer = combineReducers<State, ReleaseGlanceActions>({
	releasesContext: contextReducer,
	releasesData: releasesDataReducer,
});

export const rootEpic = combineEpics<ReleaseGlanceActions, State>(fetchDataOnReadyEpic);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () =>
	initiateStore<State, ReleaseGlanceActions, undefined>({
		appName: 'releases-glance',
		// @ts-expect-error - Type 'Reducer<CombinedState<{ releasesContext: Context; releasesData: ReleasesData | null; }>, { type: "RELEASES_DATA_FETCH_RESPONSE"; payload: Error | ReleasesData; error: boolean; } | SetContextAction>' is not assignable to type 'Reducer<State, AnyAction>'.
		rootReducer,
		rootEpic,
	});
