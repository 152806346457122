import { createContext } from 'react';

// `EditExperienceTracker` will call this function to signal to the provider
// that an edit operation failed. The provier will apply debouncing before sending
// the analytics events, to make sure we don't send duplicates.
export type ProvidedContext = null | {
	onFailure: (field: string) => void;
};

export default createContext<ProvidedContext>(null);
