import React, { useMemo } from 'react';
import type { ActiveKeyMap } from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-registry.tsx';
import { FormattedMessage } from '@atlassian/jira-intl';
import { KEYBOARD_SHORTCUT_PRIORITY, MENU_SHORTCUT_DIALOG_OPEN } from '../constant.tsx';
import { messages } from '../messages.tsx';
import type { TriggerDotDialogShortcutAction } from '../types.tsx';

const useMenuShortcuts = (triggerShortcutAction: TriggerDotDialogShortcutAction): ActiveKeyMap =>
	useMemo(
		() => ({
			[MENU_SHORTCUT_DIALOG_OPEN]: {
				callback: () => {
					triggerShortcutAction('triggerShortcutDialogOpen', MENU_SHORTCUT_DIALOG_OPEN);
				},
				label: <FormattedMessage {...messages.openCompalDialog} />,
				priority: KEYBOARD_SHORTCUT_PRIORITY,
			},
		}),
		[triggerShortcutAction],
	);

export default useMenuShortcuts;
