import { fg } from '@atlassian/jira-feature-gating';
import type { IssueTypeId, ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import {
	createStore,
	createStateHook,
	createActionsHook,
	createSelector,
} from '@atlassian/react-sweet-state';
import { actions } from './actions/index.tsx';
import type { State } from './types.tsx';

const EMPTY: IssueTypeId[] = [];

const initialState: State = {
	issueTypesById: {},
	issueTypesByProjectId: {},
};

const Store = createStore({
	initialState,
	actions,
	name: 'PolarisIssueTypesStore',
});

export const useActions = createActionsHook(Store);

export const useFetchIssueTypesForProject = () => {
	const { fetchIssueTypesForProject } = useActions();
	return fetchIssueTypesForProject;
};

const getAllIssueTypes = (state: State) => state.issueTypesById;

export const useAllIssueTypes = createStateHook(Store, {
	selector: getAllIssueTypes,
});

const getIssueTypeIdsForProject = (
	state: State,
	{ projectId }: { projectId: ProjectId | undefined },
): IssueTypeId[] => {
	if (!fg('jpd_extracted_issue_type_store')) {
		return EMPTY;
	}
	if (projectId === undefined) {
		return EMPTY;
	}
	return state.issueTypesByProjectId[projectId] || EMPTY;
};

export const useIssueTypeIdsForProject = createStateHook(Store, {
	selector: getIssueTypeIdsForProject,
});

const getIssueTypesForProject = createSelector(
	getAllIssueTypes,
	getIssueTypeIdsForProject,
	(issueTypesById, issueTypeIds) =>
		issueTypeIds.map((issueTypeId) => issueTypesById[issueTypeId]).filter(Boolean),
);

export const useIssueTypesForProject = createStateHook(Store, {
	selector: getIssueTypesForProject,
});

/**
 * @deprecated Use useIssueTypeIdsForProject instead, we can no longer
 * guarantee a single issue type per project
 */
export const useIssueTypeIdForProject = ({
	projectId,
}: {
	projectId: ProjectId | undefined;
}): IssueTypeId | undefined => {
	const issueTypeIds = useIssueTypeIdsForProject({ projectId });
	return issueTypeIds.length > 0 ? issueTypeIds[0] : undefined;
};

export const useIssueTypeName = createStateHook(Store, {
	selector: (state, { issueTypeId }: { issueTypeId: IssueTypeId }) =>
		state.issueTypesById[issueTypeId]?.name,
});

export const useIssueTypeDescription = createStateHook(Store, {
	selector: (state, { issueTypeId }: { issueTypeId: IssueTypeId }) =>
		state.issueTypesById[issueTypeId]?.description,
});

export const useIssueTypeAvatarId = createStateHook(Store, {
	selector: (state, { issueTypeId }: { issueTypeId: IssueTypeId }) =>
		state.issueTypesById[issueTypeId]?.avatarId,
});

export const useIsIssueTypeDeletable = createStateHook(Store, {
	selector: (state, { issueTypeId }: { issueTypeId: IssueTypeId }) =>
		state.issueTypesById[issueTypeId]?.operation.deletable === true,
});

export const useIsIssueTypeEditable = createStateHook(Store, {
	selector: (state, { issueTypeId }: { issueTypeId: IssueTypeId }) =>
		state.issueTypesById[issueTypeId]?.operation.editable === true,
});
