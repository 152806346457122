import React, { useState, useCallback } from 'react';
import { graphql, useFragment, useMutation } from 'react-relay';
import { fg } from '@atlassian/jira-feature-gating';
import DateEditViewEntryPoint from '@atlassian/jira-issue-field-date-editview-full/src/entrypoint.tsx';
import type { DateEditViewProps } from '@atlassian/jira-issue-field-date-editview-full/src/ui/date/types.tsx';
import { DateReadView } from '@atlassian/jira-issue-field-date-readview-full/src/ui/date/index.tsx';
import { useInlineEditFieldInjections } from '@atlassian/jira-issue-field-injections/src/controllers/inline-edit-injections-context/index.tsx';
import { useFieldInlineEditActions } from '@atlassian/jira-issue-field-inline-edit-actions/src/controllers/index.tsx';
import type { OnSubmitCallbacks } from '@atlassian/jira-issue-field-inline-edit-actions/src/controllers/types.tsx';
import type { ValidationFieldProps } from '@atlassian/jira-issue-field-inline-edit-lite/src/ui/field-inline-edit-lite/types.tsx';
import { FieldInlineEditLiteWithEntryPoint } from '@atlassian/jira-issue-field-inline-edit-lite/src/ui/index.tsx';
import type { date_issueFieldDate_DateField_Mutation as DateMutation } from '@atlassian/jira-relay/src/__generated__/date_issueFieldDate_DateField_Mutation.graphql';
import type { date_issueFieldDateInlineEditFull_DateInlineEditView_fragmentRef$key as DateFragment } from '@atlassian/jira-relay/src/__generated__/date_issueFieldDateInlineEditFull_DateInlineEditView_fragmentRef.graphql';
import type { date_issueFieldDateInlineEditFull_DateInlineEditViewWithIsEditable_fragmentRef$key as DateWithIsEditableFragment } from '@atlassian/jira-relay/src/__generated__/date_issueFieldDateInlineEditFull_DateInlineEditViewWithIsEditable_fragmentRef.graphql';
import type { Props, PropsWithIsEditable, JiraDatePickerValue } from './types.tsx';
import { useDateValidator } from './utils.tsx';

export const DateInlineEditViewWithIsEditable = (props: PropsWithIsEditable) => {
	const {
		attributes,
		spacing = 'compact',
		editViewPopup,
		editViewPopupAlignBlock,
		isEditing: isEditingProp = false,
		isEditable,
		onSubmit,
		onSubmitFailed,
		onSubmitSucceeded,
		fragmentRef,
		readViewFitContainerHeight,
		includeUrgencyTreatment,
	} = props;

	const data = useFragment<DateWithIsEditableFragment>(
		graphql`
			fragment date_issueFieldDateInlineEditFull_DateInlineEditViewWithIsEditable_fragmentRef on JiraDatePickerField {
				...date_issueFieldDateReadviewFull_DateReadView
				date
				id
				fieldId
				type
				name
				fieldConfig {
					isRequired
				}
			}
		`,
		fragmentRef,
	);

	const [commit] = useMutation<DateMutation>(graphql`
		mutation date_issueFieldDate_DateField_Mutation($input: JiraUpdateDateFieldInput!)
		@raw_response_type {
			jira {
				updateDateField(input: $input) {
					field {
						date
					}
					success
					errors {
						message
					}
				}
			}
		}
	`);

	const { id: uniqueFieldId, fieldId, type, name, fieldConfig } = data;

	const { overriding } = useInlineEditFieldInjections();

	const fieldName = fg('relay-migration-issue-fields-date-sg')
		? overriding.overrideLabel(name)
		: name;

	const isFieldEditable = fg('relay-migration-issue-fields-date-sg')
		? overriding.overrideIsEditable(isEditable)
		: isEditable;

	const isFieldRequired = fieldConfig?.isRequired ?? false;
	const validator = useDateValidator(fieldName, isFieldRequired);

	const initialValue = data.date ?? null;

	const [updatedValue, setUpdatedValue] = useState<JiraDatePickerValue>(initialValue);

	const handleSubmit = useCallback(
		(value: JiraDatePickerValue, { onSuccess, onFail }: OnSubmitCallbacks) => {
			onSubmit?.(value);
			commit({
				variables: {
					input: {
						id: uniqueFieldId,
						operation: {
							date: value,
							operation: 'SET',
						},
					},
				},
				onCompleted(mutationData) {
					if (mutationData.jira?.updateDateField?.success) {
						onSuccess();
					} else {
						onFail();
					}
				},
				onError(error: Error) {
					onFail(error);
				},
				optimisticResponse: {
					jira: {
						updateDateField: {
							success: true,
							errors: null,
							field: {
								date: value,
								id: uniqueFieldId,
							},
						},
					},
				},
			});
		},
		[commit, onSubmit, uniqueFieldId],
	);

	const handleSubmitSucceeded = fg('relay-migration-issue-fields-date-sg')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useCallback(
				(value: JiraDatePickerValue) => {
					onSubmitSucceeded?.(value);
				},
				[onSubmitSucceeded],
			)
		: undefined;

	const {
		handleCancel,
		handleEdit,
		handleConfirm,
		handleChange,
		hasServerValidationError,
		invalidMessage,
		isEditing,
	} = useFieldInlineEditActions({
		attributes,
		fieldId,
		fieldName,
		fieldType: type,
		initialValue,
		onSubmit: handleSubmit,
		onSubmitFailed,
		onSubmitSucceeded: handleSubmitSucceeded,
		onUpdateValue: setUpdatedValue,
		startWithEditViewOpen: isEditingProp,
		updatedValue,
		validator,
	});

	const renderReadView = useCallback(
		() => (
			<DateReadView
				fragmentRef={data}
				includeUrgencyTreatment={includeUrgencyTreatment}
				{...(fg('issue_view_due_date_colored_field_targeting_gate') ? { fieldId } : {})}
			/>
		),
		[data, fieldId, includeUrgencyTreatment],
	);

	const getEditViewProps = (fieldProps: ValidationFieldProps): DateEditViewProps => ({
		...fieldProps,
		spacing,
		value: updatedValue,
		onChange: handleChange,
		isInvalid: !!invalidMessage,
		autoFocus: true,
		label: fieldName,
	});

	return (
		<FieldInlineEditLiteWithEntryPoint
			editViewPopup={editViewPopup}
			editViewPopupAlignBlock={editViewPopupAlignBlock}
			editViewPopupMinWidth="small"
			editViewEntryPoint={DateEditViewEntryPoint}
			editViewEntryPointParams={{}}
			getEditViewProps={getEditViewProps}
			fieldName={fieldName}
			hasUnsubmittedChanges={hasServerValidationError}
			isEditing={isEditing}
			isEditable={isFieldEditable}
			onCancel={handleCancel}
			onConfirm={handleConfirm}
			onEdit={handleEdit}
			readViewFitContainerHeight={readViewFitContainerHeight}
			readView={renderReadView}
			invalidMessage={invalidMessage}
			hideActionButtons
		/>
	);
};

export const DateInlineEditView = ({ fragmentRef, ...props }: Props) => {
	const data = useFragment<DateFragment>(
		graphql`
			fragment date_issueFieldDateInlineEditFull_DateInlineEditView_fragmentRef on JiraDatePickerField {
				...date_issueFieldDateInlineEditFull_DateInlineEditViewWithIsEditable_fragmentRef
				fieldConfig {
					isEditable
				}
			}
		`,
		fragmentRef,
	);

	return (
		<DateInlineEditViewWithIsEditable
			{...props}
			fragmentRef={data}
			isEditable={data.fieldConfig?.isEditable ?? false}
		/>
	);
};
