import React, { forwardRef, type MouseEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import AddIcon from '@atlaskit/icon/glyph/add';
import Tooltip from '@atlaskit/tooltip';
import type { DropdownTriggerParams } from '@atlassian/jira-development-summary-common/src/common/types.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, FireUiAnalytics } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';

export type Props = Partial<Omit<DropdownTriggerParams, 'ref'>>;

export const CreateCommitIconButton = forwardRef<HTMLElement, Props>(
	({ onClick: onClickProp, ...props }, ref) => {
		const { formatMessage } = useIntl();

		const tooltipContent = formatMessage(messages.tooltipContent);
		const onClick = (e: MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
			onClickProp && onClickProp();
			fireUIAnalytics(analyticsEvent, 'createDevAction', {
				inline: true,
				summaryItemType: 'commit',
			});
		};

		return (
			<Tooltip content={tooltipContent} hideTooltipOnClick>
				<FireUiAnalytics
					action="viewed"
					actionSubject="button"
					actionSubjectId="createDevAction"
					attributes={{ inline: true, summaryItemType: 'commit' }}
				/>
				<Button
					ref={ref}
					{...props}
					appearance="subtle"
					testId="development-summary-commit.ui.create-commit-icon-button.button"
					iconBefore={<AddIcon size="small" label={tooltipContent} />}
					onClick={onClick}
				/>
			</Tooltip>
		);
	},
);
