import type { ThemeAppearance } from '@atlaskit/lozenge';
import type { SanitizedUrl } from './common-types.tsx';

// Not an opaque cause is used as a key and flow has a very weak support for computed property keys
// https://github.com/facebook/flow/issues/252
export type ProviderId = string;

export type FeatureFlagId = string;
export type FeatureFlagName = string;
export type FeatureFlagDetailsLink = SanitizedUrl;

export type ProviderName = string;
export type PipelineDisplayName = string;
export type EnvironmentDisplayName = string;
export type DeploymentDisplayName = string;

export type RemoteLinkDisplayName = string;
export type RemoteLinkUrl = string;
export type RemoteLinkId = string;
export type RemoteLinkType = string;
export type RemoteLinkDescription = string;
export type RemoteLinkActionId = string;

export type RemoteLinkAttributeKey = string;
export type RemoteLinkAttributeValue = string;

export type RemoteLinkLabelValue = string;

export type RemoteLinkProviderActionTemplateUrl = string;
export type RemoteLinkProviderActionId = string;

export type RemoteLinkProviderId = string;
export type RemoteLinkProviderName = string;
export type RemoteLinkProviderHomeUrl = SanitizedUrl;
export type RemoteLinkProviderLogoUrl = SanitizedUrl;
export type RemoteLinkProviderDocumentationUrl = SanitizedUrl;

export type RemoteLinkStatusLabel = string;

export const toFeatureFlagId = (str: string): FeatureFlagId => str;
export const toFeatureFlagName = (str: string): FeatureFlagName => str;
export const toFeatureFlagDetailsLink = (str: SanitizedUrl): FeatureFlagDetailsLink => str;

export const toProviderName = (str: string): ProviderName => str;
export const toPipelineDisplayName = (str: string): PipelineDisplayName => str;
export const toEnvironmentDisplayName = (str: string): EnvironmentDisplayName => str;
export const toDeploymentDisplayName = (str: string): DeploymentDisplayName => str;
export const fromRemoteLinkProviderId = (remoteLinkProviderId: RemoteLinkProviderId): string =>
	remoteLinkProviderId;
export const fromRemoteLinkType = (remoteLinkType: RemoteLinkType): string => remoteLinkType;

export const toRemoteLinkId = (str: string): RemoteLinkId => str;
export const toRemoteLinkProviderId = (str: string): RemoteLinkProviderId => str;
export const toRemoteLinkDisplayName = (str: string): RemoteLinkDisplayName => str;
export const toRemoteLinkUrl = (str: SanitizedUrl): RemoteLinkUrl => str;
export const toRemoteLinkType = (str: string): RemoteLinkType => str;

export const toRemoteLinkProviderName = (str: string): RemoteLinkProviderName => str;
export const toRemoteLinkProviderHomeUrl = (str: SanitizedUrl): RemoteLinkProviderHomeUrl => str;

export type CreateFlagLinkTemplate = {
	linkTemplate: string;
	providerId: ProviderId;
};

export type ConnectFlagLinkTemplate = {
	linkTemplate: string;
	providerId: ProviderId;
};

export type FeatureFlagRolloutSummary = {
	// Only one of these should be set
	percentage?: number;
	rules?: number;
	text?: string;
};

export type FeatureFlagsPerProvider = Record<ProviderId, FeatureFlagId[]>;

export type FeatureFlagsData = {
	enabledFlagNames: FeatureFlagName[];
	disabledFlagNames: FeatureFlagName[];
	detailsLink?: FeatureFlagDetailsLink;
	// Is populated if and only if there's only one feature flag and it is enabled
	rolloutSummary?: FeatureFlagRolloutSummary;
	createFlagLinkTemplate?: CreateFlagLinkTemplate;
	connectFlagLinkTemplate?: ConnectFlagLinkTemplate;
	flagsPerProvider: FeatureFlagsPerProvider;
};

export const deploymentStates = {
	PENDING: 'PENDING',
	IN_PROGRESS: 'IN_PROGRESS',
	SUCCESSFUL: 'SUCCESSFUL',
	FAILED: 'FAILED',
	ROLLED_BACK: 'ROLLED_BACK',
	CANCELLED: 'CANCELLED',
	UNKNOWN: 'UNKNOWN',
} as const;

export type DeploymentState = keyof typeof deploymentStates;

export const deploymentEnvironmentTypes = {
	PRODUCTION: 'PRODUCTION',
	STAGING: 'STAGING',
	TESTING: 'TESTING',
	DEVELOPMENT: 'DEVELOPMENT',
	UNMAPPED: 'UNMAPPED',
} as const;

export type EnvironmentType = keyof typeof deploymentEnvironmentTypes;

export const deploymentEnvironmentTypesOrder = [
	'PRODUCTION',
	'STAGING',
	'TESTING',
	'DEVELOPMENT',
	'UNMAPPED',
] as const;

export type PipelineDetails = {
	displayName: PipelineDisplayName;
	url: SanitizedUrl;
};

export type EnvironmentDetails = {
	displayName: EnvironmentDisplayName;
};

export type DeploymentData = {
	displayName: DeploymentDisplayName;
	lastUpdated: Date;
	environment: EnvironmentDetails;
	pipeline: PipelineDetails;
	state: DeploymentState;
	url: SanitizedUrl;
};

export type DeploymentDetailsPerEnvironment = {
	environmentType: EnvironmentType;
	deployments: DeploymentData[];
};

export type DeploymentDetailsPerProvider = {
	providerId: ProviderId;
	providerName: ProviderName;
	listDeploymentsLinkTemplateUrl?: string;
	deploymentDetails: DeploymentDetailsPerEnvironment[];
};

export type DeploymentSummary = {
	environmentType: EnvironmentType;
	state: DeploymentState;
	providerId: ProviderId;
};

export type Provider = {
	id: string;
	name: string;
	state: DeploymentState;
	environments: Partial<Record<EnvironmentType, DeploymentData[]>>;
};

export type DeploymentsData = {
	summary: DeploymentSummary | null;
	providers: Provider[];
};

export type ProviderOption = {
	label: string;
	value: string;
	state: DeploymentState;
};

export type RemoteLinkStatus = {
	label: RemoteLinkStatusLabel;
	appearance: ThemeAppearance;
};

export type RemoteLinkAttributeTuple = {
	key: RemoteLinkAttributeKey;
	value: RemoteLinkAttributeValue;
};

export type RemoteLink = {
	id: RemoteLinkId;
	providerId: RemoteLinkProviderId;
	displayName: RemoteLinkDisplayName;
	url: RemoteLinkUrl;
	type: RemoteLinkType;
	description?: RemoteLinkDescription;
	status?: RemoteLinkStatus;
	actionIds?: RemoteLinkActionId[];
	attributeMap?: RemoteLinkAttributeTuple[];
};

export type RemoteLinkLabel = {
	value: RemoteLinkLabelValue;
};

export type RemoteLinkProviderAction = {
	id: RemoteLinkProviderActionId;
	label: RemoteLinkLabel;
	templateUrl: RemoteLinkProviderActionTemplateUrl;
};

export type RemoteLinkProvider = {
	id: RemoteLinkProviderId;
	name: RemoteLinkProviderName;
	homeUrl: RemoteLinkProviderHomeUrl;
	logoUrl?: RemoteLinkProviderLogoUrl;
	documentationUrl?: RemoteLinkProviderDocumentationUrl;
	actions?: RemoteLinkProviderAction[];
};

export type RemoteLinksData = {
	providers: RemoteLinkProvider[];
	remoteLinks: RemoteLink[];
};

export type ReleasesData = {
	featureFlags: FeatureFlagsData;
	deployments: DeploymentsData | undefined;
	remoteLinks: RemoteLinksData | undefined;
	shouldShowEmptyState: boolean | undefined;
	hasDeploymentsInProject: boolean | undefined;
	shouldShowAppConfigContextPrompt: boolean | undefined;
};

export const hasFeatureFlags = (data: ReleasesData): boolean =>
	!!(
		data &&
		data.featureFlags &&
		((data.featureFlags.enabledFlagNames && data.featureFlags.enabledFlagNames.length > 0) ||
			(data.featureFlags.disabledFlagNames && data.featureFlags.disabledFlagNames.length > 0))
	);

export const hasDeployments = (data: ReleasesData): boolean =>
	!!(data && data.deployments && data.deployments.summary);

export const hasRemoteLinks = (data: ReleasesData): boolean =>
	!!(data && data.remoteLinks && data.remoteLinks.remoteLinks);
