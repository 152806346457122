import flatMap from 'lodash/flatMap';
import groupBy from 'lodash/groupBy';
import { featureFlagEnvironmentTypes } from '../../model/feature-flags.tsx';
import type { FeatureFlags } from '../../model/index.tsx';
import type {
	FeatureFlagProvider as FeatureFlagProviderRest,
	FeatureFlagDetails as FeatureFlagDetailsRest,
	FeatureFlag as FeatureFlagRest,
	FeatureFlagEnvironment,
} from '../../rest/details/types.tsx';

type FlatFlag = {
	readonly id: string;
	readonly key?: string;
	readonly displayName: string;
	readonly providerId: string;
} & FeatureFlagDetailsRest;

const ENVIRONMENT_TYPE_OTHER = 'other';

const flattenFeatureFlag = (featureFlag: FeatureFlagRest): FlatFlag[] =>
	flatMap(featureFlag.details, (ffDetails) => ({
		id: featureFlag.id,
		key: featureFlag.key,
		displayName: featureFlag.displayName,
		providerId: featureFlag.providerId,
		...ffDetails,
	}));

const flattenFeatureFlags = (provider: FeatureFlagProviderRest): FlatFlag[] =>
	provider.featureFlags ? flatMap(provider.featureFlags, (ff) => flattenFeatureFlag(ff)) : [];

const getEnvironmentType = ({ type }: FeatureFlagEnvironment) =>
	// @ts-expect-error - TS2345 - Argument of type 'string | null | undefined' is not assignable to parameter of type '"other" | "production" | "staging" | "development" | "testing"'.
	featureFlagEnvironmentTypes.includes(type) ? type : ENVIRONMENT_TYPE_OTHER;

const transformEnvironment = (environment: FeatureFlagEnvironment) => ({
	...environment,
	type: getEnvironmentType(environment),
});

const transformFlag = (flag: FlatFlag) => ({
	// @ts-expect-error - TS2783 - 'id' is specified more than once, so this usage will be overwritten.
	id: flag.id,
	// @ts-expect-error - TS2783 - 'providerId' is specified more than once, so this usage will be overwritten.
	providerId: flag.providerId,
	key: flag.key,
	// @ts-expect-error - TS2783 - 'displayName' is specified more than once, so this usage will be overwritten.
	displayName: flag.displayName,
	...flag,
	environment: transformEnvironment(flag.environment),
});

// @ts-expect-error - TS2304 - Cannot find name 'FeatureFlagProvider'.
const transformProviders = (providers: Array<FeatureFlagProvider>) => {
	const flatFlags = flatMap(providers, (provider) =>
		flatMap(flattenFeatureFlags(provider), (flatFlag) => ({
			// @ts-expect-error - TS2783 - 'providerId' is specified more than once, so this usage will be overwritten.
			providerId: provider.id,
			...transformFlag(flatFlag),
		})),
	);

	return groupBy(flatFlags, ({ environment }) => getEnvironmentType(environment));
};

export const transformFeatureFlags = (
	featureFlagProviders: FeatureFlagProviderRest[],
	// @ts-expect-error - TS2322 - Type 'Dictionary<{ environment: { type: string | null | undefined; name: string; }; id: string; key: string | undefined; displayName: string; providerId: string; url: string; lastUpdated: string; status: FeatureFlagStatus; }[]>' is not assignable to type 'FeatureFlags'.
): FeatureFlags => transformProviders(featureFlagProviders);
