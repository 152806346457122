import React, {
	type LegacyRef,
	useState,
	useCallback,
	type ComponentType,
	type FC,
	useLayoutEffect,
	useRef,
} from 'react';
import { styled } from '@compiled/react';
import throttle from 'lodash/throttle';
import { WidthObserver } from '@atlaskit/width-detector';

/**
 * Currently WidthObserver is async. This hook prevents jank by providing an initial
 *  measurement synchronously.
 */
export const useMeasureWidthSynchronously = (
	setWidth: (value: number) => void,
): LegacyRef<HTMLDivElement> | undefined => {
	const ref = useRef<HTMLDivElement>(null);
	useLayoutEffect(() => {
		if (!ref.current) {
			return;
		}

		const { width } = ref.current.getBoundingClientRect();
		setWidth(width);
	}, [setWidth]);
	return ref;
};

export default function withContainerWidth<P extends { containerWidth?: number | undefined }>(
	WrappedComponent: ComponentType<P>,
): FC<Omit<P, 'containerWidth'>> {
	if (!__SERVER__) {
		return (props) => {
			const [width, setWidth] = useState<number | undefined>(undefined);
			const throttledSetWidth = throttle(setWidth, 50);
			const ref = useMeasureWidthSynchronously(setWidth);

			return (
				<>
					<RelativeWrapper ref={ref}>
						{/* RelativeWrapper use - The width observer generates a that is then measured with position absolute and with 100% */}
						<WidthObserver setWidth={throttledSetWidth} />
					</RelativeWrapper>
					{/* eslint-disable-next-line @typescript-eslint/consistent-type-assertions */}
					<WrappedComponent {...(props as P)} containerWidth={width} />
				</>
			);
		};
	}
	return (props) => {
		const [width, setWidth] = useState<number | undefined>(undefined);
		const throttledSetWidth = useCallback(() => throttle(setWidth, 50), [setWidth]);

		return (
			<>
				<RelativeWrapper>
					{/* RelativeWrapper use - The width observer generates a that is then measured with position aboslute and with 100% */}
					<WidthObserver setWidth={throttledSetWidth} />
				</RelativeWrapper>
				{/* eslint-disable-next-line @typescript-eslint/consistent-type-assertions */}
				<WrappedComponent {...(props as P)} containerWidth={width} />
			</>
		);
	};
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RelativeWrapper = styled.div({
	position: 'relative',
});
