// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	label: {
		id: 'issue.views.field.team.label',
		defaultMessage: 'Team',
		description: 'Field label. Represents link to an issue team.',
	},
});
