import type { ActionsObservable } from 'redux-observable';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/ignoreElements';
import type { OnErrorCallback } from '@atlassian/jira-issue-shared-types/src/common/types/error-type.tsx';
import {
	type FetchIssueFailureAction,
	FETCH_ISSUE_FAILURE,
} from '@atlassian/jira-issue-view-store/src/common/actions/issue-fetch-actions.tsx';

// Notifies consumers of fatal errors. This is required so that consumers can
// react when we are unable to display the requested issue for some reason.
//
// For example, in the Project Issue Navigator (PIN), when the user does not
// have permission to view the requested issue they will instead show a
// different issue which the user does have access to.
// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default (onError?: OnErrorCallback) => (action$: ActionsObservable<any>) =>
	action$
		.ofType(FETCH_ISSUE_FAILURE)
		.do((action: FetchIssueFailureAction) => onError && onError(action.payload.error))
		.ignoreElements();
