export const ACTIVITY_ITEM_COMMENT_CATEGORY = 'COMMENT' as const;
export const ACTIVITY_ITEM_HISTORY_CATEGORY = 'HISTORY' as const;
export const ACTIVITY_ITEM_WORKLOG_CATEGORY = 'WORKLOG' as const;
export const ACTIVITY_ITEM_INCIDENT_CATEGORY = 'INCIDENT' as const;
export const ACTIVITY_ITEM_APPROVAL_CATEGORY = 'APPROVAL' as const;
export const ACTIVITY_ITEM_GROUP_CATEGORY = 'GROUP' as const;

export const ACTIVITY_URL = '/gateway/api/graphql';

export const HISTORY_ITEM_TYPE_GRAPHQL = 'HistoryItem' as const;
export const COMMENT_ITEM_TYPE_GRAPHQL = 'CommentItem' as const;
export const WORKLOG_ITEM_TYPE_GRAPHQL = 'WorklogItem' as const;
export const INCIDENT_ITEM_TYPE_GRAPHQL = 'IncidentItem' as const;
export const APPROVAL_ITEM_TYPE_GRAPHQL = 'ApprovalItem' as const;
export const HIDDEN_COMMENT_ITEM = 'HiddenCommentItem' as const;

export const COMMENTS = 'ANY_COMMENT' as const;
export const HISTORY = 'HISTORY' as const;
export const WORK_LOG = 'WORK_LOG' as const;
export const APPROVAL = 'APPROVAL' as const;

export const HISTORY_GROUPED_ITEMS_CATEGORY = 'HISTORY' as const;
export const WORK_LOG_GROUPED_ITEMS_CATEGORY = 'WORKLOG' as const;

export const ACTOR_TYPE_SYSTEM = 'SYSTEM';
export const ACTOR_TYPE_API = 'API';
