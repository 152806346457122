import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	contextTitle: {
		id: 'servicedesk-ai-context.ops-panel.context-title',
		defaultMessage: 'Suggestions',
		description: 'Heading for the context panel',
	},
	attribution: {
		id: 'servicedesk-ai-context.ops-panel.attribution',
		defaultMessage: 'Powered by AI',
		description: 'Attribution text in the footer',
	},
});
