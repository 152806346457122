import { SERVICE_DESK_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import {
	useIssueKey,
	useProjectKey,
	useViewScreenId,
} from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useIssueFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import { ISSUE_TYPE } from '@atlassian/jira-issue-view-configurations/src/index.tsx';
import {
	CONFIG_LINK_MODE_DISABLED_NO_REQUEST_TYPE,
	CONFIG_LINK_MODE_DISABLED_NO_SCREEN_ID,
	CONFIG_LINK_MODE_DISABLED_PERMISSION,
	CONFIG_LINK_MODE_SHOW,
	type ConfigLinkMode,
} from '@atlassian/jira-issue-view-store/src/selectors/issue-actions-selector.tsx';
import { REQUEST_TYPE_CF_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import {
	useIsSimplifiedProject,
	useProjectType,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import {
	useCanConfigureIssue,
	useCanUseServiceDeskAgentFeatures,
} from '@atlassian/jira-project-permissions-service/src/main.tsx';
import { getFieldKeyByCustomType } from './get-field-key-by-custom-type.tsx';

// Determine how to show the configure button in the bottom right for classic and simplified (next gen) issues
// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (): ConfigLinkMode => {
	const projectKey = useProjectKey();
	const canUseServiceDeskAgentFeatures = useCanUseServiceDeskAgentFeatures(projectKey);
	const canConfigureIssues = useCanConfigureIssue(projectKey);
	const projectType = useProjectType(projectKey);

	const isClassicProject = !useIsSimplifiedProject(projectKey);
	const issueKey = useIssueKey();
	const viewScreenId = useViewScreenId();

	const [{ value: issueFieldsConfig }] = useIssueFieldConfig(issueKey);

	let requestKeyCustomFieldKey = ISSUE_TYPE;

	// Classic service desk is the only project to have a request type
	if (isClassicProject && projectType === SERVICE_DESK_PROJECT && issueFieldsConfig) {
		// @ts-expect-error - TS2322 - Type 'string | undefined' is not assignable to type 'string'.
		requestKeyCustomFieldKey = getFieldKeyByCustomType(REQUEST_TYPE_CF_TYPE, issueFieldsConfig);
	}

	const key = requestKeyCustomFieldKey !== undefined ? requestKeyCustomFieldKey : ISSUE_TYPE;

	// Classic project request type id is custom, next gen is the issue type
	const [requestType] = useFieldValue({
		issueKey,
		fieldKey: key,
	});

	const issueTypeIdFieldKey = ISSUE_TYPE;
	const [issueTypeId] = useFieldValue({ issueKey, fieldKey: issueTypeIdFieldKey });

	if (!canConfigureIssues) {
		return CONFIG_LINK_MODE_DISABLED_PERMISSION;
	}

	switch (projectType) {
		case SERVICE_DESK_PROJECT: {
			if (!canUseServiceDeskAgentFeatures) {
				return CONFIG_LINK_MODE_DISABLED_PERMISSION;
			}

			if (
				(isClassicProject &&
					!(
						requestKeyCustomFieldKey !== null &&
						requestType != null &&
						requestType.requestType !== undefined &&
						requestType.requestType.id !== undefined
					)) ||
				(!isClassicProject && !(issueTypeId && issueTypeId.id))
			) {
				// classic project doesn't have request type id
				// next gen project doesn't have issue type
				return CONFIG_LINK_MODE_DISABLED_NO_REQUEST_TYPE;
			}

			break;
		}
		default:
	}
	if ((viewScreenId == null || viewScreenId === 0) && isClassicProject) {
		// Classic project requires screen ID for configuration
		return CONFIG_LINK_MODE_DISABLED_NO_SCREEN_ID;
	}
	return CONFIG_LINK_MODE_SHOW;
};
