import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	uimIssueViewConsentMessage: {
		id: 'ui-modifications-fields-configuration.common.utils.views.issue-view.uim-issue-view-consent-message',
		defaultMessage:
			'Allow “{appTitle}” to access your Atlassian products to get the full issue experience.',
		description:
			'When a user opens a context the first time, we require that they give consent for their data otherwise they cannot get the UIM`s applied',
	},
	uimIssueViewConsentMessageIssueTermRefresh: {
		id: 'ui-modifications-fields-configuration.common.utils.views.issue-view.uim-issue-view-consent-message-issue-term-refresh',
		defaultMessage:
			'Allow “{appTitle}” to access your Atlassian products to get the full work item experience.',
		description:
			'When a user opens a context the first time, we require that they give consent for their data otherwise they cannot get the UIM`s applied',
	},
});
