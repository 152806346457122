import { stringify } from 'query-string';
import {
	performPutRequest,
	performPostRequest,
} from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { SaveFieldArguments } from '@atlassian/jira-issue-view-common-types/src/connect-field-type.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { idSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';
import getXsrfToken from '@atlassian/jira-platform-xsrf-token/src/index.tsx';

export const saveField = (
	saveFieldArguments: SaveFieldArguments<string>,
	state: State,
): Promise<void> =>
	performPostRequest(`${saveFieldArguments.baseUrl}/secure/AjaxIssueAction.jspa?decorator=none`, {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		},
		body: stringify({
			[saveFieldArguments.fieldId]: saveFieldArguments.value,
			issueId: idSelector(state),
			skipScreenCheck: true,
			atl_token: getXsrfToken(),
			singleFieldEdit: true,
		}),
	});

/**
 * @deprecated - use saveField instead. This endpoint doesn't work for JWM users with no JSW access.
 * The same edit Issue Color endpoint that is used by the Epic Panel in the backlog.
 * Enables editing the field while bypassing screen validation for the Issue Color field.
 */
export const saveFieldUsingXboard = (
	saveFieldArguments: SaveFieldArguments<string>,
): Promise<void> =>
	performPutRequest(
		`${saveFieldArguments.baseUrl}/rest/greenhopper/1.0/xboard/issue/update-field.json`,
		{
			body: JSON.stringify({
				fieldId: saveFieldArguments.fieldId,
				issueIdOrKey: saveFieldArguments.issueKey,
				newValue: saveFieldArguments.value,
			}),
		},
	);
