import { useCallback } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import type {
	ClientEventValidator,
	ClientValidator,
} from '@atlassian/jira-issue-field-validation-handler/src/controllers/client-validation-handler/types.tsx';
import messages from './messages.tsx';

export const useSingleLineTextValidator = (
	fieldName: string,
	isRequired: boolean,
	maxLength?: number,
): ClientEventValidator<string> => {
	const { formatMessage } = useIntl();
	const change: ClientValidator<string> = useCallback(
		(value: string) => {
			if (maxLength && maxLength > 0 && value.length > maxLength) {
				return {
					type: 'error',
					message: formatMessage(messages.maxLengthErrorMessage, {
						maxLength,
					}),
				};
			}

			return null;
		},
		[formatMessage, maxLength],
	);

	const submit: ClientValidator<string> = useCallback(
		(value: string) => {
			if (isRequired && value.trim().length === 0) {
				return {
					type: 'error',
					message: formatMessage(messages.requiredErrorMessage, { fieldName }),
				};
			}

			return null;
		},
		[fieldName, formatMessage, isRequired],
	);

	return { change, submit };
};
