import React from 'react';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import { useUFOComponentExperience } from '@atlassian/ufo';
import { jsmConferenceCallExperience } from './common/ui/experience/index.tsx';
import type ConferenceCallField from './ui/conference-call-field/index.tsx';
import type { Props } from './ui/conference-call-field/index.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyConferenceCallField = lazyForPaint<typeof ConferenceCallField>(
	() =>
		import(
			/* webpackChunkName: "async-servicedesk-incident-management-conference-call-field" */ './ui/conference-call-field'
		),
	{ ssr: false },
);

export const AsyncConferenceCallField = (props: Props) => {
	useUFOComponentExperience(jsmConferenceCallExperience);
	return (
		<Placeholder name="servicedesk-incident-management-conference-call-field" fallback={null}>
			<LazyConferenceCallField {...props} />
		</Placeholder>
	);
};
