import {
	createActionsHook,
	createContainer,
	createHook,
	createStore,
} from '@atlassian/react-sweet-state';
import {
	loadSummaryStream,
	loadSummary,
	stopLoading,
	closeSummaryPanel,
	setIsGenerating,
} from './actions/index.tsx';
import type { ContainerProps, State } from './types.tsx';

export const StateContainer = createContainer<ContainerProps>();

export const initialState: State = {
	isPanelShown: false,
	isGenerating: false,
	content: null,
	error: undefined,
};

const actions = {
	loadSummaryStream,
	loadSummary,
	stopLoading,
	closeSummaryPanel,
	setIsGenerating,
};

const store = createStore({
	name: 'issue-smart-request-summary',
	initialState,
	actions,
	containedBy: StateContainer,
});

export const useActions = createActionsHook(store);

export const useIsPanelShown = createHook(store, {
	selector: (state) => state.isPanelShown,
});

export const useIsGenerating = createHook(store, {
	selector: (state) => state.isGenerating,
});

export const useContent = createHook(store, {
	selector: (state) => state.content,
});

export const useError = createHook(store, {
	selector: (state) => state.error,
});
