import { stringify } from 'query-string';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { MentionContextIdentifier, MentionDescription } from '@atlaskit/mention';
import type { ProjectType } from '@atlassian/jira-common-constants/src/index.tsx';
import type { AccountId, BaseUrl, IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { AbstractMentionProvider } from '../abstract-mention-provider/index.tsx';

export class UserMentionProvider extends AbstractMentionProvider {
	constructor(
		baseUrl: BaseUrl,
		issueKey: IssueKey,
		loggedInAccountId: AccountId | null,
		projectType?: ProjectType | null,
		createAnalyticsEvent?: CreateUIAnalyticsEvent | null,
	) {
		super(baseUrl, projectType, createAnalyticsEvent);
		this.issueKey = issueKey;
		this.loggedInAccountId = loggedInAccountId;
	}

	getQueryString = (query?: string, contextIdentifier?: MentionContextIdentifier) =>
		stringify({
			query,
			issueKey: this.issueKey,
			maxResults: 10,
			...contextIdentifier,
		});

	getUrl = (queryString: string) =>
		`${this.baseUrl}/rest/internal/2/user/mention/search?${queryString}`;

	issueKey: string;

	loggedInAccountId: string | null;

	shouldHighlightMention = (mention: MentionDescription) => mention.id === this.loggedInAccountId;
}
