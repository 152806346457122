import {
  IdentityTeamAriResourceOwner,
  IdentityTeamAriResourceType
} from "./chunk-T76PG5FI.mjs";
import {
  RegisteredAri
} from "./chunk-YG2E6ZVD.mjs";
import {
  AriParser
} from "./chunk-Q7FABYST.mjs";

// src/identity/team/manifest.ts
var identityTeamAriStaticOpts = {
  qualifier: "ari",
  platformQualifier: "cloud",
  cloudId: new RegExp("^$"),
  // eslint-disable-line no-useless-escape
  resourceOwner: IdentityTeamAriResourceOwner,
  resourceType: IdentityTeamAriResourceType,
  resourceIdSlug: "{teamId}",
  resourceIdSegmentFormats: {
    teamId: /[a-zA-Z0-9.\-_~]+/
    // eslint-disable-line no-useless-escape
  }
};

// src/identity/team/index.ts
var IdentityTeamAri = class _IdentityTeamAri extends RegisteredAri {
  constructor(opts) {
    super(opts);
    this._teamId = opts.resourceIdSegmentValues.teamId;
  }
  get teamId() {
    return this._teamId;
  }
  static create(opts) {
    let derivedOpts = {
      qualifier: identityTeamAriStaticOpts.qualifier,
      platformQualifier: identityTeamAriStaticOpts.platformQualifier,
      cloudId: void 0,
      resourceOwner: identityTeamAriStaticOpts.resourceOwner,
      resourceType: identityTeamAriStaticOpts.resourceType,
      resourceId: `${opts.teamId}`,
      resourceIdSegmentValues: {
        teamId: opts.teamId
      }
    }, ariOpts = AriParser.fromOpts(derivedOpts, identityTeamAriStaticOpts);
    return new _IdentityTeamAri(ariOpts);
  }
  static parse(maybeAri) {
    let opts = AriParser.fromString(maybeAri, identityTeamAriStaticOpts);
    return new _IdentityTeamAri(opts);
  }
  getVariables() {
    return {
      teamId: this.teamId
    };
  }
};

export {
  IdentityTeamAri
};
