import { createSelector } from 'reselect';
import { SERVICE_ENTITY_CF_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { fieldByCustomTypeSelector } from '../common/state/selectors/field-selector.tsx';

export const serviceEntityFieldSelector = fieldByCustomTypeSelector(SERVICE_ENTITY_CF_TYPE);

export const serviceEntitySelector = createSelector(
	serviceEntityFieldSelector,
	(field) => field && field.value,
);
