import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	invalidInput: {
		id: 'issue-field-original-estimate-editview-full.original-estimate.invalid-input',
		defaultMessage: 'Your estimate must be in the format 2w 4d 6h 45m',
		description: 'Error message notifying the user that the value entered is in incorrect format',
	},
	label: {
		id: 'issue-field-original-estimate-editview-full.original-estimate.label',
		defaultMessage: 'Original estimate',
		description: 'Aria label for accessibility',
	},
});
