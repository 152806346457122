import { defaultTimeTrackingConfiguration } from '@atlassian/jira-common-constants/src/jira-settings.tsx';
import type { JiraSettingsGira } from '@atlassian/jira-issue-shared-types/src/common/types/jira-settings-type.tsx';
import type { JiraSettings } from '@atlassian/jira-issue-view-common-types/src/gira.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { getQuery, getTimeTrackingQuery } from './graphql';

export const transformTimeTrackingData = (jiraSettings: JiraSettings) => ({
	timeTrackingConfiguration: jiraSettings.timeTracking || defaultTimeTrackingConfiguration,
});

export const transformSubtaskData = (jiraSettings: JiraSettings) => ({
	subtasksConfiguration: jiraSettings.subtasks,
});

export const transformNewIssueViewTransition = (jiraSettings: JiraSettings) => ({
	newIssueViewTransitionSettings: jiraSettings.newIssueViewTransitionSettings,
});

export const transformData = (jiraSettings: JiraSettings): { jiraSettings: JiraSettingsGira } => {
	const newIssueViewTransitionSettings = transformNewIssueViewTransition(jiraSettings);

	return {
		jiraSettings: {
			...newIssueViewTransitionSettings,
		},
	};
};
