import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import {
	getParentIssueTypeId,
	getProjectId,
	getFilterSubtasks,
} from '../../state/context/selectors.tsx';
import {
	getCompletedIssues,
	getAllIssues,
	getIncompleteIssues,
} from '../../state/entities/selectors.tsx';
import type { State } from '../../state/types.tsx';
import Title from '../heading/title/index.tsx';
import { CollapsibleChildIssues } from './view.tsx';

export default flowWithSafeComponent(
	connect((state: State) => {
		if (!state) {
			// Monitoring has shown that state can be `undefined` but we have
			// not been able to replicate it.
			return {
				Title,
				totalIssueCount: 0,
				projectId: null,
				parentIssueTypeId: null,
				filterSubtasks: null,
				completedIssueCount: 0,
				allIssues: [],
				incompleteIssues: [],
			};
		}

		const totalIssues = getAllIssues(state);
		const completedIssues = getCompletedIssues(state);

		return {
			Title,
			totalIssueCount: totalIssues.length,
			projectId: getProjectId(state),
			parentIssueTypeId: getParentIssueTypeId(state),
			filterSubtasks: getFilterSubtasks(state),
			completedIssueCount: completedIssues.length,
			allIssues: totalIssues,
			incompleteIssues: getIncompleteIssues(state),
		};
	}, {}),
)(CollapsibleChildIssues);
