import React, { Component, createRef, type ReactNode } from 'react';
import Button, { IconButton } from '@atlaskit/button/new';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import FeedbackIcon from '@atlaskit/icon/glyph/feedback';
import AkTooltip from '@atlaskit/tooltip';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';

type Props = {
	children: ReactNode;
	tooltip: ReactNode;
	iconLabel: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onClick: any;
	isCompact: boolean;
	isMeatballButton?: boolean;
};

// eslint-disable-next-line jira/react/no-class-components
export default class BentoFeedbackTriggerButton extends Component<Props> {
	static defaultProps = {
		children: null,
		tooltip: null,
		iconLabel: '',
		onClick: null,
		isCompact: false,
	};

	buttonRef = createRef<HTMLButtonElement>();

	onClick = () => {
		this.props.onClick(this.buttonRef);
	};

	renderButton() {
		if (this.props.isMeatballButton) {
			return (
				<UFOSegment name="issue-feedback-button">
					<DropdownItem
						onClick={this.onClick}
						key="feedback-button"
						data-testid="issue-view-feedback-button.button-unified.feedback-button"
					>
						{this.props.children}
					</DropdownItem>
				</UFOSegment>
			);
		}
		return (
			<UFOSegment name="issue-feedback-button">
				{this.props.isCompact ? (
					<IconButton
						// eslint-disable-next-line jira/integration/test-id-by-folder-structure
						testId="issue.feedback-button.button-unified.button"
						onClick={this.onClick}
						ref={this.buttonRef}
						appearance="default"
						icon={FeedbackIcon}
						label={this.props.iconLabel}
						aria-haspopup="dialog"
					/>
				) : (
					<Button
						// eslint-disable-next-line jira/integration/test-id-by-folder-structure
						testId="issue.feedback-button.button-unified.button"
						onClick={this.onClick}
						ref={this.buttonRef}
						appearance="default"
						iconBefore={FeedbackIcon}
						aria-haspopup="dialog"
					>
						{this.props.children}
					</Button>
				)}
			</UFOSegment>
		);
	}

	render() {
		return (
			<div>
				{this.props.tooltip ? (
					<AkTooltip content={this.props.tooltip} position="top">
						{this.renderButton()}
					</AkTooltip>
				) : (
					this.renderButton()
				)}
			</div>
		);
	}
}
