import React from 'react';
import { ActionItem } from '@atlassian/jira-servicedesk-ai-context-common/src/ui/ai-context-item/action-item/index.tsx';
import type { ResponderSuggestedAction } from '@atlassian/jira-servicedesk-ai-context-ops-panel-resources/src/common/types.tsx';
import { AddRespondersButton } from '../../../../common/ui/add-responders/index.tsx';
import { useAIAnalytics } from '../../../../common/utils/index.tsx';

type props = {
	action: ResponderSuggestedAction;
	onRemoveItem: () => void;
};

export const RespondersItem = ({ action, onRemoveItem }: props) => {
	const [{ onClickFireAnalytics, onFailureFireAnalytics }] = useAIAnalytics(action.type);

	return (
		<ActionItem
			summary={action.content.title}
			description={action.content.description}
			action={
				<AddRespondersButton
					context={action.context}
					onClick={onClickFireAnalytics}
					onSuccess={onRemoveItem}
					onFailure={onFailureFireAnalytics}
				/>
			}
		/>
	);
};
