export const APP_ID = 'development.details';

// Pull Request Status Types
export const DECLINED = 'DECLINED';
export const OPEN = 'OPEN';
export const MERGED = 'MERGED';
export const PR_UNKNOWN = 'UNKNOWN';
export const DRAFT = 'DRAFT';

// Commit Change Types
export const ADDED = 'ADDED';
export const COPIED = 'COPIED';
export const DELETED = 'DELETED';
export const MODIFIED = 'MODIFIED';
export const MOVED = 'MOVED';

// Deployment Types
export const DEPLOYED = 'DEPLOYED' as const;
export const FAILED = 'FAILED' as const;
export const NOT_DEPLOYED = 'NOT_DEPLOYED' as const;

// Deployment Environment Types

export const PRODUCTION = 'PRODUCTION';
export const STAGING = 'STAGING';
export const TESTING = 'TESTING';
export const DEVELOPMENT = 'DEVELOPMENT';
export const UNMAPPED = 'UNMAPPED';

// Deployment states

export const UNKNOWN = 'UNKNOWN';
export const PENDING = 'PENDING';
export const IN_PROGRESS = 'IN_PROGRESS';
export const CANCELLED = 'CANCELLED';
export const DEPLOYMENT_FAILED = 'FAILED';
export const ROLLED_BACK = 'ROLLED_BACK';
export const SUCCESSFUL = 'SUCCESSFUL';

export const VERSION_RELATED_LINKS_PROVIDER_ID_STG = 'vyhOd8hgbSa4rzgnTvyaZNSUqUViKIOc';
export const VERSION_RELATED_LINKS_PROVIDER_ID_PROD = 'oAPCjkrphiFxmqbTLkv9r2jBFTvfOI8x';
