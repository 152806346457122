/** @jsx jsx */
import React, {
	useEffect,
	useState,
	useCallback,
	useRef,
	memo,
	createElement,
	type ReactNode,
} from 'react';
import { css, styled, jsx } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { token } from '@atlaskit/tokens';
import EnterEscapeHandler from '@atlassian/jira-common-components-enter-escape-handler/src/index.tsx';
import messagesDefault from '@atlassian/jira-common-components-inline-edit/src/messages.tsx';
import { MOBILE_ISSUE } from '@atlassian/jira-common-constants/src/analytics-sources.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { ValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import {
	isClientFetchError,
	isHttpClientErrorResponse,
} from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { ErrorFlag } from '@atlassian/jira-issue-error-flag/src/index.tsx';
import { FieldInlineEditStateLess } from '@atlassian/jira-issue-field-inline-edit/src/ui/index.tsx';
import { useSummaryField } from '@atlassian/jira-issue-field-summary-common/src/services/index.tsx';
import type { StringValue } from '@atlassian/jira-issue-field-summary-common/src/services/types.tsx';
import { SUMMARY_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { SummaryKey } from '@atlassian/jira-providers-issue/src/model/issue-system-fields.tsx';
import { toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { INLINE, HEADER } from '../common/constants.tsx';
import { confirmedPayload, cancelledPayload } from '../common/utils.tsx';
import { InlineSummaryEdit } from './edit/inline-edit/index.tsx';
import { SummaryEdit } from './edit/main.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';
import { InlineSummaryRead } from './view/inline-read/index.tsx';
import { SummaryView } from './view/main.tsx';

export const actionSubject = 'summaryInlineEdit';

export default memo<Props>((props: Props) => {
	// Dependency Injection
	// More at: https://hello.atlassian.net/wiki/spaces/~agasparin/pages/774678789/Dependency+injection+and+replacement+moving+past+props+and+mocking+imports

	const {
		onCancel,
		onEdit,
		onEscape,
		onConfirm,
		onEnter,
		onUpdate,
		onClick,
		url,
		readView,
		editView,
		issueId,
		issueKey,
		source,
		onFailure,
		onRenderSuccess,
		onEditFailure,
		appearance = INLINE,
		showHoverPreviewEnabled,
	} = props;

	const isMobile = source === MOBILE_ISSUE;

	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [isInvalid, setIsInvalid] = useState<boolean>(false);
	const { formatMessage } = useIntl();
	const invalidMessage = isInvalid
		? formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.errorEmptyIssueTermRefresh
					: messages.errorEmpty,
			)
		: undefined;
	const editViewRef = useRef<unknown>();

	// Store previous value of the field, for error scenario, to notify the parent that we failed
	// and the previous value is being restored.
	const previousValue = useRef<unknown>();

	const onSuccess = (newValue: StringValue) => {
		onUpdate && onUpdate(newValue);
	};

	const onFieldFailure = (error: Error) => {
		// If we get a ValidationError, TypeError, or client fetch error, we don't fire a failure event.
		if (
			error instanceof ValidationError || // For invalid data
			error instanceof TypeError ||
			isClientFetchError(error) || // For network issues
			isHttpClientErrorResponse(error)
		) {
			return;
		}
		onFailure && onFailure(error, previousValue.current);
		// Replicating `withEditExperienceTracker`
		isEditing && onEditFailure && onEditFailure(SUMMARY_TYPE, error.message);
	};

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const [
		{
			value,
			error,
			fieldConfig: { value: fieldConfigValue, loading: fieldConfigLoading },
		},
		{ saveValue, resetError },
	] = useSummaryField({
		issueId,
		issueKey: toIssueKey(issueKey),
		onSuccess,
		onFailure: onFieldFailure,
	});

	const label = fieldConfigValue?.title ?? '';
	const [updatedValue, setUpdatedValue] = useState<StringValue>(value);
	const isFieldEditable = fieldConfigValue ? fieldConfigValue.isEditable : false;

	useEffect(() => {
		// Replicating `withViewExperienceTracker`
		onRenderSuccess && onRenderSuccess();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (isEditing) {
			// only blur active element on issueKey change
			// if the field is currently being edited

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			if (document?.activeElement) {
				// @ts-expect-error - TS2339 - Property 'blur' does not exist on type 'Element'.

				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				document.activeElement.blur();
			}
			setIsEditing(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [issueKey]);

	useEffect(() => {
		previousValue.current = value;
		if (error) {
			setIsEditing(true);
		}
		if (!isEditing) {
			setUpdatedValue(value);
		}
	}, [error, isEditing, value]);

	const save = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			if (updatedValue !== value) {
				saveValue(updatedValue, null, analyticsEvent);
				setIsEditing(false);
			} else {
				setIsEditing(false);
			}
			fireUIAnalytics(analyticsEvent);
		},
		[saveValue, updatedValue, value],
	);

	const cancel = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			resetError();
			setIsEditing(false);
			setUpdatedValue(value);
			fireUIAnalytics(analyticsEvent);
		},
		[resetError, value],
	);

	const onCancelRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			if (document && document.activeElement) {
				// @ts-expect-error - TS2339 - Property 'blur' does not exist on type 'Element'.

				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				document.activeElement.blur();
			}
			setIsInvalid(false);
			cancel(analyticsEvent);
			onCancel && onCancel(analyticsEvent);
		},
		[onCancel, cancel],
	);

	const onConfirmRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			if (updatedValue === null || updatedValue === '') {
				if (!isInvalid) setIsInvalid(true);
				return;
			}

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			if (document && document.activeElement) {
				// @ts-expect-error - TS2339 - Property 'blur' does not exist on type 'Element'.

				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				document.activeElement.blur();
			}
			save(analyticsEvent);
			onConfirm && onConfirm(analyticsEvent, updatedValue);
		},
		[isInvalid, onConfirm, save, updatedValue],
	);

	const onEditRequested = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			setIsEditing(true);
			fireUIAnalytics(analyticsEvent);
			onEdit && onEdit(analyticsEvent);
		},
		[onEdit],
	);

	const onChange = useCallback(
		(inputValue: string) => {
			resetError();
			const typedValue = inputValue === '' ? null : inputValue.toString();
			if (typedValue !== null) setIsInvalid(false);
			setUpdatedValue(typedValue);
		},
		[resetError, setUpdatedValue],
	);

	const onEscapeRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			cancel(analyticsEvent);
			onEscape && onEscape(analyticsEvent);
		},
		[onEscape, cancel],
	);

	const onEnterRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			save(analyticsEvent);
			onEnter && onEnter(analyticsEvent);
		},
		[onEnter, save],
	);

	const onEnterKeyHandler = useCallback(() => {
		const enterEvent = createAnalyticsEvent(confirmedPayload);
		onConfirmRequest(enterEvent);
	}, [createAnalyticsEvent, onConfirmRequest]);

	const onEscapeKeyHandler = useCallback(
		(event: KeyboardEvent) => {
			event.preventDefault();
			event.stopPropagation();
			const escapeEvent = createAnalyticsEvent(cancelledPayload);
			onCancelRequest(escapeEvent);
		},
		[createAnalyticsEvent, onCancelRequest],
	);

	const renderEditView = useCallback(() => {
		if (!isFieldEditable) return null;

		const view =
			appearance === INLINE ? (
				<InlineSummaryEdit
					// @ts-expect-error - TS2322 - Type 'MutableRefObject<unknown>' is not assignable to type 'Ref<"input"> | undefined'.
					passedRef={editViewRef}
					defaultValue={updatedValue}
					onChange={onChange}
					isInvalid={isInvalid}
					invalidMessage={invalidMessage}
				/>
			) : (
				<SummaryEdit
					// @ts-expect-error - TS2322 - Type 'MutableRefObject<unknown>' is not assignable to type 'Ref<"h1"> | undefined'.
					passedRef={editViewRef}
					defaultValue={updatedValue}
					onChange={onChange}
					isInvalid={isInvalid}
					invalidMessage={invalidMessage}
				/>
			);
		return editView !== undefined && typeof editView === 'function' ? (
			createElement(editView, {
				// @ts-expect-error - TS2769 - No overload matches this call.
				passedRef: editViewRef,
				defaultValue: updatedValue,
				onChange,
			})
		) : (
			// @ts-expect-error - TS2741 - Property 'onBlur' is missing in type '{ children: Element; onEnter: () => void; onEscape: (event: KeyboardEvent) => void; }' but required in type 'Props'.
			<EnterEscapeHandlerFullWidth onEnter={onEnterKeyHandler} onEscape={onEscapeKeyHandler}>
				{view}
			</EnterEscapeHandlerFullWidth>
		);
	}, [
		isFieldEditable,
		appearance,
		updatedValue,
		onChange,
		isInvalid,
		invalidMessage,
		onEnterKeyHandler,
		onEscapeKeyHandler,
		editView,
	]);

	const renderReadViewOld = () => {
		const view = appearance === INLINE ? null : <SummaryView value={value} />;

		return readView !== undefined && typeof readView === 'function'
			? createElement(readView, {
					value,
				})
			: view;
	};

	const renderReadView = () => <ReadViewContainer>{renderReadViewOld()}</ReadViewContainer>;

	if (editViewRef.current && isEditing && isInvalid) {
		const foundCurrent = editViewRef.current;
		const foundTextArea =
			foundCurrent instanceof HTMLElement ? foundCurrent.querySelector('textarea') : undefined;
		const foundArea = appearance === INLINE ? foundCurrent : foundTextArea;

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const foundActiveElement = document ? document.activeElement : null;
		if (foundArea && foundArea !== foundActiveElement) {
			// @ts-expect-error - TS2571 - Object is of type 'unknown'.
			foundArea.focus();
		}
	}

	const getEditButtonLabel = () =>
		value !== null
			? formatMessage(messages.extendedEditButtonLabel, {
					inputFieldValue: value,
				})
			: formatMessage(messages.editButtonLabel);

	const summaryInlineEdit = (
		<>
			{error && (
				<ErrorFlag error={error} title={messages.errorTitle} description={messages.errorMessage} />
			)}
			<InlineEditContainer>
				<FieldInlineEditStateLess
					testId="issue-field-summary.ui.issue-field-summary-inline-edit"
					defaultValue={updatedValue}
					fieldId={fg('one_event_rules_them_all_fg') ? SummaryKey : undefined}
					readView={renderReadView}
					editView={renderEditView}
					onCancel={onCancelRequest}
					onConfirm={onConfirmRequest}
					onEnter={onEnterRequest}
					onEscape={onEscapeRequest}
					onEdit={onEditRequested}
					readViewFitContainerWidth={!isMobile}
					isEditing={isEditing}
					isEditable={isFieldEditable}
					editButtonLabel={getEditButtonLabel()}
					confirmButtonLabel={formatMessage(messagesDefault.confirmButtonLabel, {
						fieldName: label,
					})}
					cancelButtonLabel={formatMessage(messagesDefault.cancelButtonLabel, {
						fieldName: label,
					})}
				/>
			</InlineEditContainer>
		</>
	);

	if (appearance === HEADER) {
		return summaryInlineEdit;
	}

	return isEditing ? (
		summaryInlineEdit
	) : (
		<InlineSummaryRead
			url={url}
			value={updatedValue}
			setIsEditing={setIsEditing}
			isEditable={isFieldEditable}
			isConfigLoading={fieldConfigLoading}
			onClick={onClick}
			showHoverPreviewEnabled={showHoverPreviewEnabled}
		/>
	);
});

const inlineEditContainerStyles = css({
	width: '100%',
	marginLeft: token('space.negative.050', '-4px'),
	marginTop: token('space.negative.100', '-8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> div': {
		width: '100%',
	},
});

const InlineEditContainerNew = ({ children, ...props }: { children: ReactNode }) => (
	<div css={inlineEditContainerStyles} {...props}>
		{children}
	</div>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReadViewContainer = styled.div({
	display: 'flex',
	lineHeight: 1,
	maxWidth: '100%',
	wordBreak: 'break-word',
	paddingTop: token('space.075', '6px'),
	paddingBottom: token('space.075', '6px'),
	position: 'relative',
	left: token('space.075', '6px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InlineEditContainerOld = styled.div({
	width: '100%',
	marginLeft: token('space.negative.100', '-8px'),
	marginTop: token('space.negative.100', '-8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> div': {
		width: '100%',
	},
});

const InlineEditContainer = componentWithFG(
	'issue_view_field_config_edit',
	InlineEditContainerNew,
	InlineEditContainerOld,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const EnterEscapeHandlerFullWidth = styled(EnterEscapeHandler)({
	width: '100%',
});
