/* eslint-disable @atlassian/relay/query-restriction */

import { useCallback, useMemo } from 'react';
import { graphql, useFragment, useMutation } from 'react-relay';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { isClientFetchError } from '@atlassian/jira-fetch/src/index.tsx';
import { useIssueId } from '@atlassian/jira-issue-context-service/src/index.tsx';
import {
	fireOperationalAnalytics,
	fireTrackAnalytics,
	fireUIAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import type { voteFieldServiceRelay_issue_field_Mutation } from '@atlassian/jira-relay/src/__generated__/voteFieldServiceRelay_issue_field_Mutation.graphql';
import type { voteFieldServiceRelay_issueFieldVoters_UseVoteFieldRelayGenerator$key } from '@atlassian/jira-relay/src/__generated__/voteFieldServiceRelay_issueFieldVoters_UseVoteFieldRelayGenerator.graphql';
import type { SubmitCallbacks, UseVoteField, VoteValue } from '../../common/types.tsx';
import { calculateVote } from './utils.tsx';

type useVoteFieldRelayGeneratorProps = {
	votesField: voteFieldServiceRelay_issueFieldVoters_UseVoteFieldRelayGenerator$key;
} & SubmitCallbacks;

/**
 * @deprecated This is used just as a migration tool for vote field to relay. Use pure relay instead of re-using this.
 */
export const UseVoteFieldRelayGenerator = ({
	votesField,
	onSubmit,
	onSubmitFailed,
	onSubmitSucceeded,
}: useVoteFieldRelayGeneratorProps): UseVoteField => {
	const data = useFragment<voteFieldServiceRelay_issueFieldVoters_UseVoteFieldRelayGenerator$key>(
		graphql`
			fragment voteFieldServiceRelay_issueFieldVoters_UseVoteFieldRelayGenerator on JiraVotesField {
				fieldId
				type
				id
				vote {
					count
					hasVoted
				}
			}
		`,
		votesField,
	);
	const value = useMemo(
		() => ({
			votes: data?.vote?.count || 0,
			hasVoted: data?.vote?.hasVoted || false,
		}),
		[data?.vote?.count, data?.vote?.hasVoted],
	);

	const [commit] = useMutation<voteFieldServiceRelay_issue_field_Mutation>(graphql`
		mutation voteFieldServiceRelay_issue_field_Mutation($input: JiraUpdateVotesFieldInput!)
		@raw_response_type {
			jira @optIn(to: ["JiraIssueFieldMutations"]) {
				updateVotesField(input: $input) {
					success
					errors {
						message
					}
					field {
						...voteFieldServiceRelay_issueFieldVoters_UseVoteFieldRelayGenerator
					}
				}
			}
		}
	`);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const issueId = useIssueId();

	const toggleValueInner = useCallback(
		(isDropdownItem?: boolean, onFieldUpdated?: (value: VoteValue) => void) => {
			const didToggleOn = !(data?.vote?.hasVoted || false);
			const analyticsEvent = createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'button',
			});
			fireUIAnalytics(analyticsEvent, didToggleOn ? 'voteAdded' : 'voteRemoved', {
				viewMode: isDropdownItem ? 'showVotersList' : 'hideVotersList',
			});
			const newVote = calculateVote(didToggleOn, data?.vote);

			const analyticsData = {
				editOperationType: 'set',
				issueId,
				fieldKey: data?.fieldId,
				fieldType: data?.type,
			};

			onSubmit?.(newVote);

			const onFail = (error?: Error) => {
				fireOperationalAnalytics(analyticsEvent, 'field updateFailed', {
					fieldKey: data?.fieldId,
					fieldType: data?.type,
					isValidationError: false,
					isClientFetchError: error ? isClientFetchError(error) : false,
				});
				onSubmitFailed?.();
			};

			commit({
				variables: {
					input: {
						id: data.id,
						operation: {
							operation: didToggleOn ? 'ADD' : 'REMOVE',
						},
					},
				},
				optimisticResponse: {
					jira: {
						updateVotesField: {
							success: true,
							errors: null,
							field: {
								fieldId: data.fieldId,
								id: data.id,
								type: data.type,
								vote: newVote,
							},
						},
					},
				},
				onCompleted: (response) => {
					if (response.jira?.updateVotesField?.success) {
						fireTrackAnalytics(analyticsEvent, 'field updated', analyticsData);
						fireOperationalAnalytics(analyticsEvent, 'field updateSucceeded', analyticsData);
						onFieldUpdated?.({
							votes: newVote.count,
							hasVoted: newVote.hasVoted,
						});
						onSubmitSucceeded?.(newVote);
					} else {
						onFail();
					}
				},
				onError: (error: Error) => {
					onFail(error);
				},
			});
		},
		[
			commit,
			createAnalyticsEvent,
			data.fieldId,
			data.id,
			data.type,
			data?.vote,
			issueId,
			onSubmit,
			onSubmitFailed,
			onSubmitSucceeded,
		],
	);

	const useVoteField = useCallback(
		({ onFieldUpdated }: { onFieldUpdated?: (value: VoteValue) => void }) => {
			const toggleValue = async (isDropdownItem?: boolean) => {
				return toggleValueInner(isDropdownItem, onFieldUpdated);
			};
			return [{ value }, { toggleValue }] as const;
		},
		[toggleValueInner, value],
	);
	return useVoteField;
};
