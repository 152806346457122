const issueType = () => `
    ... on JiraIssueTypeField {
        fieldId
        issueType {
            issueTypeId
            name
            avatar {
                xsmall
            }
        }
    }
`;

const summary = () => `
    ... on JiraSingleLineTextField {
        fieldId
        text
    }
`;

const color = () => `
    issueColorField {
      color {
        colorKey
      }
    }
`;

const status = () => `
    ... on JiraStatusField {
      fieldId
      status {
        statusCategory{
          statusCategoryId
        }
      }
    }
`;

const fieldsById = () => `fieldsById(ids:["summary", "issuetype", "status"]){
  edges{
    node{
      ${issueType()}
      ${summary()}
      ${status()}
    }
  }
}`;

export const parentCandidatesForNewIssueQuery = () => `
    jira {
        parentCandidatesWithInfoForNewIssue(projectId: $projectId, issueTypeId: $issueTypeId, cloudId: $cloudId, searchTerm: $searchTerm, excludeDone: $excludeDone, first: $first) {
          parentCandidates {
            edges {
              node {
                issueId
                key
                ${color()}
                ${fieldsById()}
              }
            }
          }
          message
        }
      }
`;
