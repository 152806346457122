import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const jsmSmartRequestSummaryEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(/* webpackChunkName: "async-smart-request-summary" */ './src').then(
			(module) => module.SmartRequestSummary,
		),
	),
	getPreloadProps: () => ({}),
});
