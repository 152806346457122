import React from 'react';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type PrimaryIncident from './index.tsx';

const PrimaryIncidentAsync = lazyForPaint<typeof PrimaryIncident>(
	() => import(/* webpackChunkName: "async-pir-primary-incident" */ './index'),
);

export const AsyncPrimaryIncident = () => (
	<Placeholder name="pir-primary-incident" fallback={null}>
		<PrimaryIncidentAsync />
	</Placeholder>
);
