/* eslint-disable @atlassian/relay/unused-fields */
import React, { useCallback, useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';
import TextAreaInlineEditView from '@atlassian/jira-issue-internal-fields/src/text-area/text-area-inline-edit-view.tsx';
import messages from '@atlassian/jira-issue-view-base/src/common/multiline-plaintext/messages.tsx';

import MultilinePlainTextView from '@atlassian/jira-issue-view-base/src/common/multiline-plaintext/view.tsx';
import type { Area } from '@atlassian/jira-issue-view-common-types/src/connect-field-type.tsx';
import { getDisplayName } from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-field.tsx';
import {
	useConnectRelayField,
	type PropsCallback,
} from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-relay-field.tsx';
import { ConnectedRelayFieldWrapper } from '@atlassian/jira-issue-view-common-views/src/connect-field/relay-field/field-wrapper.tsx';
import { useIssueViewFieldUpdateEvents } from '@atlassian/jira-issue-view-field-update-events/src/services/issue-view-field-update-events/index.tsx';
import type {
	plainTextField_issueViewLayoutRichTextField$key,
	plainTextField_issueViewLayoutRichTextField$data,
} from '@atlassian/jira-relay/src/__generated__/plainTextField_issueViewLayoutRichTextField.graphql';
import { convertTextToAdf } from '@atlassian/jira-rich-content/src/common/convert-text-to-adf/index.tsx';
import type { AggJiraRichText, CommonRichTextFieldProps } from '../../common/types.tsx';

type Props = CommonRichTextFieldProps & {
	richTextField: plainTextField_issueViewLayoutRichTextField$key;
};

/**
 * To create this field, in CMP create a custom field of type "Paragraph (supports rich text)"
 * Then go to the Global Field Configuration page: https://${your-instance}/secure/admin/ViewFieldLayouts.jspa
 * click "Configure" on one a scheme your field is in.
 * From there you can search for the rich text field and click on "Renderers"
 * Choose "Default Text Renderer" and click "Update"
 */

export const PlainTextField = ({ area, onSave, richTextField }: Props) => {
	const data = useFragment<plainTextField_issueViewLayoutRichTextField$key>(
		graphql`
			fragment plainTextField_issueViewLayoutRichTextField on JiraRichTextField {
				__typename
				type
				fieldId
				name
				description
				issue {
					issueId
				}
				renderer
				fieldConfig {
					isEditable
				}
				richText {
					adfValue {
						convertedPlainText {
							plainText
						}
					}
				}
			}
		`,
		richTextField,
	);
	const issueId = useMemo(() => data.issue?.issueId, [data.issue?.issueId]);

	const [, { fieldChanged, fieldChangeFailed, fieldChangeRequested }] =
		useIssueViewFieldUpdateEvents();
	const onSubmit = useCallback(
		(value: AggJiraRichText) => {
			issueId &&
				fieldChangeRequested(issueId, data.fieldId, value, undefined, {
					type: data.type,
					__typename: data.__typename,
					renderer: data.renderer,
				});
		},
		[data.__typename, data.fieldId, data.renderer, data.type, fieldChangeRequested, issueId],
	);

	const onSubmitSucceeded = useCallback(
		(value: AggJiraRichText) => {
			issueId &&
				fieldChanged(issueId, data.fieldId, value, {
					type: data.type,
					__typename: data.__typename,
					renderer: data.renderer,
				});
		},
		[data.__typename, data.fieldId, data.renderer, data.type, fieldChanged, issueId],
	);

	const onSubmitFailed = useCallback(
		() => issueId && fieldChangeFailed(issueId, data.fieldId),
		[data.fieldId, fieldChangeFailed, issueId],
	);

	const onSavePlainText = useCallback(
		({ value }: { value: string }) => {
			return onSave({
				value: convertTextToAdf(value),
				onSubmit,
				onSubmitFailed,
				onSubmitSucceeded,
			});
		},
		[onSave, onSubmit, onSubmitFailed, onSubmitSucceeded],
	);

	const getComponentProps = useCallback<
		PropsCallback<
			plainTextField_issueViewLayoutRichTextField$key,
			plainTextField_issueViewLayoutRichTextField$data,
			string,
			AggJiraRichText,
			{
				noValueText: string;
				area: Area;
			}
		>
	>(
		({ intl }) => {
			return {
				jiraIssueField: data,
				value: data?.richText?.adfValue?.convertedPlainText?.plainText || '',
				onValueConfirm(componentValue) {
					return onSavePlainText({ value: componentValue });
				},
				additionalProps: {
					noValueText: intl.formatMessage(messages.noValue),
					area,
				},
			};
		},
		[data, area, onSavePlainText],
	);

	const connectField = useConnectRelayField(
		{ area, fragmentKey: richTextField },
		data,
		getComponentProps,
	);
	const componentName = getDisplayName(MultilinePlainTextView);

	return (
		<ConnectedRelayFieldWrapper componentName={componentName}>
			<TextAreaInlineEditView {...connectField.componentProps} />
		</ConnectedRelayFieldWrapper>
	);
};
