import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useSearchCustomFieldKeys } from '@atlassian/jira-issue-field-base/src/services/custom-field-key-service/index.tsx';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import { ENTITLEMENT_CF_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { EntitlementFieldValue } from '../../types.tsx';

/**
 * We choose what value the useFieldValue hook returns via a GraphQL query in the EntitlementsField code
 */
export const useEntitlementFieldValue = (): EntitlementFieldValue | null => {
	const issueKey = useIssueKey();
	const [fieldKey] = useSearchCustomFieldKeys(issueKey, ENTITLEMENT_CF_TYPE);
	const [entitlementFieldValue] = useFieldValue({
		issueKey,
		fieldKey,
	});

	// useFieldValue returns any,
	// and I don't see a clean way to type it
	// so better evaluate its shape before returning it
	if (
		entitlementFieldValue?.id &&
		entitlementFieldValue?.product?.id &&
		entitlementFieldValue?.product?.name &&
		entitlementFieldValue?.entity?.__typename &&
		entitlementFieldValue?.entity?.id
	) {
		return entitlementFieldValue;
	}

	return null;
};
