import {
	type AnalyticsAttributes,
	type AnalyticsEvent,
	fireTrackAnalytics as fireTrackAnalyticsBase,
} from '@atlassian/jira-product-analytics-bridge';
import type { AnalyticsAttributesUntyped } from '@atlassian/jira-product-analytics-bridge/src/types';
import { getAnalyticsWebClient } from '@atlassian/jira-product-analytics-web-client';

// Remove on 'analytics-replace-jira-packages' cleanup
export const fireTrackAnalytics = (
	event: AnalyticsEvent,
	id: string,
	viewType: string,
	attributes: AnalyticsAttributes = {},
) => {
	fireTrackAnalyticsBase(event, `jiraUiModifications.${id}`, { ...attributes, viewType });
};

/**
 * Triggers a track analytics event without the Jira analytics context.
 * Action subjects are prefixed with `jiraUiModifications.`
 *
 * jira-frontend/browse/src/packages/platform/observability/product-analytics-bridge/README.md#Tracking outside of UI
 */
export const fireTrackAnalyticsWithoutContext = (
	actionSubject: string,
	action: string,
	viewType: string,
	attributes: AnalyticsAttributesUntyped = {},
) =>
	getAnalyticsWebClient()
		.getInstance()
		.sendTrackEvent({
			action,
			actionSubject: `jiraUiModifications.${actionSubject}`,
			source: viewType,
			attributes: {
				...attributes,
				viewType,
			},
		});
