import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	filterDropdownComments: {
		id: 'jsm-issue-activity-filter.common.filter-dropdown-comments',
		defaultMessage: 'Comments',
		description:
			'Checkbox label to filter activity feed comments. Shown inside the issue view activity feed filter dropdown.',
	},
	filterDropdownHistory: {
		id: 'jsm-issue-activity-filter.common.filter-dropdown-history',
		defaultMessage: 'History',
		description:
			'Checkbox label to filter activity feed history. Shown inside the issue view activity feed filter dropdown.',
	},
	filterDropdownWorkLog: {
		id: 'jsm-issue-activity-filter.common.filter-dropdown-work-log',
		defaultMessage: 'Work log',
		description:
			'Checkbox label to filter activity feed work log. Shown inside the issue view activity feed filter dropdown.',
	},
	filterDropdownCheckboxStakeholderUpdates: {
		id: 'jsm-issue-activity-filter.common.filter-dropdown-checkbox-stakeholder-updates',
		defaultMessage: 'Stakeholder updates',
		description:
			'Checkbox label to filter timeline entries created by stakeholders. Shown inside the issue view activity feed filter dropdown.',
	},
	filterDropdownCheckboxAlerts: {
		id: 'jsm-issue-activity-filter.common.filter-dropdown-checkbox-alerts',
		defaultMessage: 'Alerts',
		description:
			'Checkbox label to filter alerts timeline entries. Shown inside the issue view activity feed filter dropdown.',
	},
	filterDropdownCheckboxIncidentChanges: {
		id: 'jsm-issue-activity-filter.common.filter-dropdown-checkbox-incident-changes',
		defaultMessage: 'Incident changes',
		description:
			'Checkbox label to filter Incident changes timeline entries. Shown inside the issue view activity feed filter dropdown.',
	},
	filterDropdownCheckboxIccSessionActivity: {
		id: 'jsm-issue-activity-filter.common.filter-dropdown-checkbox-icc-session-activity',
		defaultMessage: 'ICC session activity',
		description:
			'Checkbox label to filter ICC (Incident Command Center) session activity timeline entries. Shown inside the issue view activity feed filter dropdown.',
	},
	filterDropdownCheckboxIccParticipantActivity: {
		id: 'jsm-issue-activity-filter.common.filter-dropdown-checkbox-icc-participant-activity',
		defaultMessage: 'ICC participant activity',
		description:
			'Checkbox label to filter ICC (Incident Command Center) participant activity timeline entries. Shown inside the issue view activity feed filter dropdown.',
	},
	filterDropdownCheckboxIntegrationActions: {
		id: 'jsm-issue-activity-filter.common.filter-dropdown-checkbox-integration-actions',
		defaultMessage: 'Integration actions',
		description:
			'Checkbox label to filter integration actions timeline entries. Shown inside the issue view activity feed filter dropdown.',
	},
	filterDropdownCheckboxSlackMessages: {
		id: 'jsm-issue-activity-filter.common.filter-dropdown-checkbox-slack-messages',
		defaultMessage: 'Slack messages',
		description:
			'Checkbox label to filter Slack messages timeline entries. Shown inside the issue view activity feed filter dropdown.',
	},
	filterDropdownApprovals: {
		id: 'jsm-issue-activity-filter.common.filter-dropdown-approvals',
		defaultMessage: 'Approvals',
		description:
			'Checkbox label to filter Jira Service Management approvals activity. Shown inside the issue view activity feed filter dropdown.',
	},
	filterHiddenMessages: {
		id: 'jsm-issue-activity-filter.common.filter-hidden-messages',
		defaultMessage: 'Hidden internal notes',
		description:
			'Checkbox label to filter Jira Service Management hidden activity. Shown inside the issue view activity feed filter dropdown.',
	},
});
