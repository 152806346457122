import { isValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { fireOperationalEditFailedEvent } from '@atlassian/jira-forge-ui-analytics/src/services/custom-field-edit/index.tsx';
import type { ForgeCustomFieldValue } from '@atlassian/jira-forge-ui-types/src/common/types/contexts/custom-field.tsx';
import type { ServiceResult } from '@atlassian/jira-forge-ui-types/src/common/types/custom-field.tsx';
import type { SaveField } from '@atlassian/jira-issue-field-base/src/common/types.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';

export const createSaveCustomField =
	(source?: string): SaveField<ForgeCustomFieldValue, ServiceResult> =>
	async (issueKey: IssueKey, fieldId: string, value: ForgeCustomFieldValue) => {
		try {
			await performPutRequest(`/rest/api/2/issue/${issueKey}`, {
				body: JSON.stringify({
					fields: {
						[fieldId]: value,
					},
				}),
			});

			return {
				data: value,
			};
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			if (!isValidationError(error)) {
				fireOperationalEditFailedEvent(source, { error });
			}

			throw error;
		}
	};
