type Callback<T = unknown> = (payload: T) => void;

/**
 * simple event bus
 */
export const createEventBus = () => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const listeners = new Map<Callback<any>, string>();

	const subscribe = <T = unknown,>(event: string, listener: Callback<T>) => {
		listeners.set(listener, event);
		return () => {
			listeners.delete(listener);
		};
	};
	const emit = (event: string, payload: unknown) => {
		listeners.forEach((eventFilter, listener) => {
			if (eventFilter === event) {
				listener(payload);
			}
		});
	};

	return {
		subscribe,
		emit,
		get length() {
			return listeners.size;
		},
	};
};
