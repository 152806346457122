/**
 * @generated SignedSource<<35b4236b89489e3cbba60195be4908fc>>
 * @relayHash 329753622b810715fc36aea23e1cf107
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID e37e32d992ba027a9a2a35ba9f868f90a44b578830a8f801f507ad1f56970701

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraAttachmentSortField = "CREATED" | "%future added value";
export type SortDirection = "ASC" | "DESC" | "%future added value";
export type JiraAttachmentSortInput = {
  field: JiraAttachmentSortField;
  order?: SortDirection;
};
export type ui_issueViewBase_AttachmentFilmstripRelayFragmentRefetchQuery$variables = {
  after?: string | null | undefined;
  cloudId: string;
  first?: number | null | undefined;
  issueKey: string;
  maxTokenLength?: number | null | undefined;
  sortBy?: JiraAttachmentSortInput | null | undefined;
};
export type ui_issueViewBase_AttachmentFilmstripRelayFragmentRefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewBase_AttachmentFilmstripRelayFragment">;
};
export type ui_issueViewBase_AttachmentFilmstripRelayFragmentRefetchQuery = {
  response: ui_issueViewBase_AttachmentFilmstripRelayFragmentRefetchQuery$data;
  variables: ui_issueViewBase_AttachmentFilmstripRelayFragmentRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "issueKey"
  },
  {
    "defaultValue": 65536,
    "kind": "LocalArgument",
    "name": "maxTokenLength"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sortBy"
  }
],
v1 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v2 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v3 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v4 = {
  "kind": "Variable",
  "name": "issueKey",
  "variableName": "issueKey"
},
v5 = {
  "kind": "Variable",
  "name": "maxTokenLength",
  "variableName": "maxTokenLength"
},
v6 = {
  "kind": "Variable",
  "name": "sortBy",
  "variableName": "sortBy"
},
v7 = {
  "kind": "ScalarField",
  "name": "clientId"
},
v8 = {
  "kind": "ScalarField",
  "name": "endpointUrl"
},
v9 = {
  "kind": "ScalarField",
  "name": "token"
},
v10 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v11 = [
  (v1/*: any*/),
  (v3/*: any*/),
  (v6/*: any*/)
],
v12 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "ui_issueViewBase_AttachmentFilmstripRelayFragmentRefetchQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "ui_issueViewBase_AttachmentFilmstripRelayFragment"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ui_issueViewBase_AttachmentFilmstripRelayFragmentRefetchQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": [
              (v2/*: any*/),
              {
                "kind": "Variable",
                "name": "key",
                "variableName": "issueKey"
              }
            ],
            "concreteType": "JiraIssue",
            "kind": "LinkedField",
            "name": "issueByKey",
            "plural": false,
            "selections": [
              {
                "args": [
                  (v5/*: any*/)
                ],
                "concreteType": "JiraMediaReadToken",
                "kind": "LinkedField",
                "name": "mediaReadToken",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "tokenLifespanInSeconds"
                  },
                  {
                    "concreteType": "JiraMediaTokenWithFilesConnection",
                    "kind": "LinkedField",
                    "name": "tokensWithFiles",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraMediaTokenWithFilesEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "concreteType": "JiraMediaTokenWithFiles",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v9/*: any*/)
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "name": "mediaUploadToken",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v8/*: any*/),
                      (v7/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "targetCollection"
                      },
                      (v9/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "tokenDurationInMin"
                      }
                    ],
                    "type": "JiraMediaUploadToken"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": "errorId",
                        "kind": "ScalarField",
                        "name": "identifier"
                      },
                      {
                        "alias": "errorMessage",
                        "kind": "ScalarField",
                        "name": "message"
                      }
                    ],
                    "type": "QueryError"
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "name": "key"
              },
              {
                "alias": "canDeleteAllAttachments",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "permission",
                    "value": "DELETE_ALL_ATTACHMENTS"
                  }
                ],
                "kind": "ScalarField",
                "name": "hasProjectPermission",
                "storageKey": "hasProjectPermission(permission:\"DELETE_ALL_ATTACHMENTS\")"
              },
              {
                "alias": "canDeleteOwnAttachments",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "permission",
                    "value": "DELETE_OWN_ATTACHMENTS"
                  }
                ],
                "kind": "ScalarField",
                "name": "hasProjectPermission",
                "storageKey": "hasProjectPermission(permission:\"DELETE_OWN_ATTACHMENTS\")"
              },
              {
                "args": (v11/*: any*/),
                "concreteType": "JiraAttachmentConnection",
                "kind": "LinkedField",
                "name": "attachments",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraAttachmentEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/),
                          {
                            "kind": "TypeDiscriminator",
                            "abstractKey": "__isJiraAttachment"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "attachmentId"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "mediaApiFileId"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "created"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "parentId"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "parentName"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "hasRestrictedParent"
                          },
                          {
                            "kind": "LinkedField",
                            "name": "author",
                            "plural": false,
                            "selections": [
                              (v10/*: any*/),
                              {
                                "kind": "ScalarField",
                                "name": "accountId"
                              },
                              (v12/*: any*/)
                            ]
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v12/*: any*/)
                            ],
                            "type": "Node",
                            "abstractKey": "__isNode"
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "name": "cursor"
                      }
                    ]
                  },
                  {
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "endCursor"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "hasNextPage"
                      }
                    ]
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "name": "totalCountResolver",
                        "args": [
                          (v4/*: any*/)
                        ],
                        "kind": "RelayResolver",
                        "isOutputType": true
                      }
                    ]
                  }
                ]
              },
              {
                "args": (v11/*: any*/),
                "filters": [
                  "sortBy"
                ],
                "handle": "connection",
                "key": "issue_attachments",
                "kind": "LinkedHandle",
                "name": "attachments"
              },
              (v12/*: any*/),
              {
                "kind": "ClientExtension",
                "selections": [
                  {
                    "name": "cardCoverResolver",
                    "kind": "RelayResolver",
                    "isOutputType": true
                  },
                  {
                    "name": "isMobileResolver",
                    "kind": "RelayResolver",
                    "isOutputType": true
                  },
                  {
                    "name": "isPreviewResolver",
                    "kind": "RelayResolver",
                    "isOutputType": true
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "e37e32d992ba027a9a2a35ba9f868f90a44b578830a8f801f507ad1f56970701",
    "metadata": {},
    "name": "ui_issueViewBase_AttachmentFilmstripRelayFragmentRefetchQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "70268ebefd07f4dccf36a158b30f1df6";

export default node;
