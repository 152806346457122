import React from 'react';
import { useFragment, graphql } from 'react-relay';
import { useIntl } from '@atlassian/jira-intl';
import { EmptyFieldText } from '@atlassian/jira-issue-empty-field-text/src/ui/index.tsx';
import type { number_issueFieldNumberReadviewFull_NumberReadView$key as NumberFragment } from '@atlassian/jira-relay/src/__generated__/number_issueFieldNumberReadviewFull_NumberReadView.graphql';
import type { Props } from './types.tsx';

export const NumberReadView = ({ fragmentRef }: Props) => {
	const { formatNumber } = useIntl();

	const data = useFragment<NumberFragment>(
		graphql`
			fragment number_issueFieldNumberReadviewFull_NumberReadView on JiraNumberField {
				number
			}
		`,
		fragmentRef,
	);

	const { number } = data;

	return (
		<span data-testid="issue-field-number-readview-full.ui.number.span">
			{number != null ? formatNumber(number) : <EmptyFieldText />}
		</span>
	);
};
