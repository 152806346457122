import React, { useEffect } from 'react';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { QuickApprovalsPanelEntryPoint } from './entrypoint.tsx';

interface Props {
	projectId: number;
	isIssueDone: boolean;
	issueKey: string;
	summary: string;
}

const entryPointParams = {};

export const AsyncQuickApprovalsPanel = (props: Props) => {
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		QuickApprovalsPanelEntryPoint,
		entryPointParams,
	);

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		entryPointActions.load();
	}, [entryPointActions]);

	return (
		<JiraEntryPointContainer
			id="jswApprovalsPanel"
			packageName="jira-software-quick-approvals-panel"
			teamName="a4t-pandora"
			fallback={null}
			entryPointReferenceSubject={entryPointReferenceSubject}
			runtimeProps={props}
		/>
	);
};
