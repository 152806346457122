import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import withFireUiAnalytics from '@atlassian/jira-analytics-web-react/src/components/with-fire-ui-analytics.tsx';
import ComponentWithAnalytics from '@atlassian/jira-analytics-web-react/src/utils/component-with-analytics.tsx';
import type { ChildIssue } from '@atlassian/jira-issue-view-common-types/src/children-issues-type.tsx';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import type { OptimisticUiChildIssue } from '../../model/types.tsx';
import {
	getBaseUrl,
	getProjectId,
	getParentId,
	getChildIssuesPanelType,
	getEpicLinkFieldKey,
	getRequiresFullCreateByIssueType,
	getParentKey,
} from '../../state/context/selectors.tsx';
import { createChildRequest, childCreatedInFullDialog } from '../../state/entities/actions.tsx';
import {
	getAllIssues,
	getIssueTypes,
	getIssueHierarchyLevel,
} from '../../state/entities/selectors.tsx';
import type { State } from '../../state/types.tsx';
import {
	hideAddChildIssue,
	setChildPanelView,
	CHILD_PANEL_VIEWS,
	setChildCreateSummary,
} from '../../state/ui/actions.tsx';
import {
	getSelectedIssueType,
	getAddClickCount,
	getChildCreateSummary,
} from '../../state/ui/selectors.tsx';
import ChildTypesSelect from './child-types-select/index.tsx';
import InlineCreate from './view.tsx';

export default flowWithSafeComponent(
	ComponentWithAnalytics('button', {
		onSubmitClickAnalytics: 'clicked',
		onCancelClickAnalytics: 'clicked',
		onAddExistingClickAnalytics: 'clicked',
	}),
	ComponentWithAnalytics('keyboardShortcut', {
		onEnterKeyPressedAnalytics: 'pressed',
		onEscapeKeyPressedAnalytics: 'pressed',
	}),
	ComponentWithAnalytics('childIssuePanelInlineCreate', {
		onSubmit: 'onSubmit',
		onFullDialogCreate: 'onFullDialogCreate',
	}),
	withFireUiAnalytics({
		onSubmitClickAnalytics: 'createChildIssue',
		onCancelClickAnalytics: 'hideChildIssueInlineCreate',
		onEnterKeyPressedAnalytics: 'confirmChildIssueCreate',
		onEscapeKeyPressedAnalytics: 'cancelChildIssueCreate',
		onAddExistingClickAnalytics: 'addExistingChildClick',
	}),
	// @ts-expect-error - Argument of type 'InferableComponentEnhancerWithProps<{ ChildTypesSelect: ConnectedComponent<ComponentType<WithIntlProps<Pick<{ issueTypes: ChildIssueType[]; isDisabled?: boolean | undefined; } & Intl & { selectedIssueType: ChildIssueType; onChange: (arg1: ChildIssueType) => void; defaultMenuIsOpen?: boolean | undefined; }, "intl" | ...' is not assignable to parameter of type 'FlowStep<WithUIAnalyticsEvent<PropsWithoutRef<Omit<{ [K in keyof PropsWithRef<Omit<{ [K in keyof PropsWithRef<Omit<{ [K in keyof Props]: K extends "onSubmitClickAnalytics" | "onCancelClickAnalytics" | "onAddExistingClickAnalytics" ? (...args: [...Parameters<Props[K]>, UIAnalyticsEvent]) => ReturnType<...> : Props[K]...'.
	connect(
		(state: State) => ({
			ChildTypesSelect,
			baseUrl: getBaseUrl(state),
			issues: getAllIssues(state),
			addClickCount: getAddClickCount(state),
			issueTypes: getIssueTypes(state),
			selectedIssueType: getSelectedIssueType(state),
			projectId: getProjectId(state),
			parentIssueId: getParentId(state),
			parentIssueKey: getParentKey(state),
			epicLinkFieldKey: getEpicLinkFieldKey(state),
			childIssuesPanelType: getChildIssuesPanelType(state),
			requiresFullCreate: getRequiresFullCreateByIssueType(state),
			summary: getChildCreateSummary(state),
			issueHierarchyLevel: getIssueHierarchyLevel(state),
		}),
		{
			onCancel: hideAddChildIssue,
			onAddExistingIssue: () => setChildPanelView(CHILD_PANEL_VIEWS.AddExistingIssue),
			onChangeSummary: (summary: string) => setChildCreateSummary(summary),
			onSubmit: (
				optimisticUiChildIssue: OptimisticUiChildIssue,
				analyticsEvent: UIAnalyticsEvent,
			) => createChildRequest(optimisticUiChildIssue, analyticsEvent),
			onFullDialogCreate: (
				optimisticId: string,
				childIssue: ChildIssue,
				analyticsEvent: UIAnalyticsEvent,
			) => childCreatedInFullDialog(optimisticId, childIssue, analyticsEvent),
		},
	),
)(InlineCreate);
