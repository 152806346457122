/**
 * @generated SignedSource<<79a5679963e8b35cbf8adec5d79a4206>>
 * @relayHash e4c8b3ea74b70fe5ffcfca1b26c57fca
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 24e4ba638e85322b846946353b7c8f6df91fbe245572ecf0fe21e02737d6b695

import type { ConcreteRequest, Mutation } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraWatchesOperations = "ADD" | "REMOVE" | "%future added value";
export type JiraUpdateWatchesFieldInput = {
  id: string;
  operation: JiraWatchesFieldOperationInput;
};
export type JiraWatchesFieldOperationInput = {
  accountId?: string | null | undefined;
  id?: string | null | undefined;
  operation: JiraWatchesOperations;
};
export type useWatchesMutation_Mutation$variables = {
  input: JiraUpdateWatchesFieldInput;
};
export type useWatchesMutation_Mutation$data = {
  readonly jira: {
    readonly updateWatchesField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly " $fragmentSpreads": FragmentRefs<"actionButton_issueViewWatchers_ActionButtonWithRelay" | "addWatchers_issueViewWatchers_AddWatchersRelay" | "changeWatchState_issueViewWatchers_ChangeWatchWithRelay" | "watchersList_issueViewWatchers_WatchersListRelay">;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type useWatchesMutation_Mutation$rawResponse = {
  readonly jira: {
    readonly updateWatchesField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly fieldId: string;
        readonly id: string;
        readonly selectedUsersConnection: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly __typename: string;
              readonly __isUser: string;
              readonly accountId: string;
              readonly id: string;
              readonly name: string;
              readonly picture: AGG$URL;
            } | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined;
        readonly type: string;
        readonly watch: {
          readonly __typename: "JiraWatch";
          readonly count: AGG$Long | null | undefined;
          readonly isWatching: boolean | null | undefined;
        } | null | undefined;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type useWatchesMutation_Mutation = {
  rawResponse: useWatchesMutation_Mutation$rawResponse;
  response: useWatchesMutation_Mutation$data;
  variables: useWatchesMutation_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "success"
},
v3 = {
  "concreteType": "MutationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "message"
    }
  ]
},
v4 = {
  "kind": "ScalarField",
  "name": "id"
},
v5 = {
  "kind": "ScalarField",
  "name": "__typename"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "useWatchesMutation_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraWatchesFieldPayload",
            "kind": "LinkedField",
            "name": "updateWatchesField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraWatchesField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  {
                    "kind": "FragmentSpread",
                    "name": "addWatchers_issueViewWatchers_AddWatchersRelay"
                  },
                  {
                    "kind": "FragmentSpread",
                    "name": "changeWatchState_issueViewWatchers_ChangeWatchWithRelay"
                  },
                  {
                    "kind": "FragmentSpread",
                    "name": "watchersList_issueViewWatchers_WatchersListRelay"
                  },
                  {
                    "kind": "FragmentSpread",
                    "name": "actionButton_issueViewWatchers_ActionButtonWithRelay"
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useWatchesMutation_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraWatchesFieldPayload",
            "kind": "LinkedField",
            "name": "updateWatchesField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraWatchesField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "concreteType": "JiraWatch",
                    "kind": "LinkedField",
                    "name": "watch",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "count"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "isWatching"
                      },
                      (v5/*: any*/)
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "name": "type"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "fieldId"
                  },
                  {
                    "concreteType": "JiraUserConnection",
                    "kind": "LinkedField",
                    "name": "selectedUsersConnection",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraUserEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              {
                                "kind": "TypeDiscriminator",
                                "abstractKey": "__isUser"
                              },
                              (v4/*: any*/),
                              {
                                "kind": "ScalarField",
                                "name": "accountId"
                              },
                              {
                                "kind": "ScalarField",
                                "name": "name"
                              },
                              {
                                "kind": "ScalarField",
                                "name": "picture"
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "24e4ba638e85322b846946353b7c8f6df91fbe245572ecf0fe21e02737d6b695",
    "metadata": {},
    "name": "useWatchesMutation_Mutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "0220335ea10f4b525f4a3e9cfb25292e";

export default node;
