import uuid from 'uuid';
import {
	createStore,
	createContainer,
	createHook,
	createActionsHook,
	type HookReturnValue,
	type BoundActions,
} from '@atlassian/react-sweet-state';
import type { AIResponse, FeedbackContext } from '../../common/types.tsx';
import { actions } from './actions.tsx';
import type { State, Actions, Props, PromptId } from './types.tsx';

const initialState: State = {
	idPrefix: '',
	isLoading: false,
	isSubmitted: false,
	prompt: '',
	response: undefined,
	submittedPrompt: '',
	cancellable: undefined,
	promptId: '',
};

// Exported for testing
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Store = createStore<State<any>, Actions>({
	initialState,
	actions,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PromptContainer = createContainer<State<any>, Actions, Props<any>>(Store, {
	onInit:
		() =>
		({ setState }) => {
			setState({
				idPrefix: uuid(),
			});
		},
});

export const usePromptActions = createActionsHook<State, Actions>(Store);

export const useResponseFeedbackContext = createHook<State, Actions, FeedbackContext[] | undefined>(
	Store,
	{
		selector: (state) => state.response?.additionalFeedbackContext,
	},
);

const usePromptResponseGeneric = createHook<State, Actions, unknown>(Store, {
	selector: (state) => state.response,
});
export function usePromptResponse<Payload>(): HookReturnValue<
	AIResponse<Payload>,
	BoundActions<State, Actions>
> {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return usePromptResponseGeneric() as HookReturnValue<
		AIResponse<Payload>,
		BoundActions<State, Actions>
	>;
}

export const usePrompt = createHook<State, Actions, string>(Store, {
	selector: (state) => state.prompt,
});

export const useSubmittedPrompt = createHook<State, Actions, string>(Store, {
	selector: (state) => state.submittedPrompt,
});

export const usePromptIsLoading = createHook<State, Actions, boolean>(Store, {
	selector: (state) => state.isLoading,
});
export const usePromptIsSubmitted = createHook<State, Actions, boolean>(Store, {
	selector: (state) => state.isSubmitted,
});

export const usePromptId = createHook<State, Actions, PromptId>(Store, {
	selector: (state) => state.promptId,
});

const getScopedId = (state: State, idSuffix: string): string => `${state.idPrefix}_${idSuffix}`;

export const useScopedId = createHook<State, Actions, string, string>(Store, {
	selector: getScopedId,
});
