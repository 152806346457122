import React, { useState, useEffect, useCallback, useMemo, memo, type ChangeEvent } from 'react';
import { styled } from '@compiled/react';
import isEqual from 'lodash/isEqual';
import { graphql, useMutation } from 'react-relay';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { token } from '@atlaskit/tokens';
import { PRETTY } from '@atlassian/jira-common-constants/src/jira-settings.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useProjectKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { ErrorFlag } from '@atlassian/jira-issue-error-flag/src/index.tsx';
import { FieldInlineEditStateLess } from '@atlassian/jira-issue-field-inline-edit/src/ui/index.tsx';
import {
	parseTimeString,
	isValidTimeString,
} from '@atlassian/jira-issue-format-time/src/index.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useProjectPermissions } from '@atlassian/jira-project-permissions-service/src/main.tsx';
import type {
	ui_issueFieldRemainingEstimate_RemainingEstimateField_Mutation as RemainingEstimateMutation,
	ui_issueFieldRemainingEstimate_RemainingEstimateField_Mutation$rawResponse as RemainingEstimateMutationResponse,
} from '@atlassian/jira-relay/src/__generated__/ui_issueFieldRemainingEstimate_RemainingEstimateField_Mutation.graphql';
import { toIssueKey, type IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { timeTrackingFormatter } from '@atlassian/jira-time-tracking-formatter/src/main.tsx';
import messages from '../messages.tsx';
import { useRemainingEstimateField as defaultUseRemainingEstimateField } from '../services/index.tsx';
import { RemainingEstimateEdit } from './edit/index.tsx';
import type { Props } from './types.tsx';
import { RemainingEstimateView } from './view/index.tsx';

export const actionSubject = 'remainingEstimateInlineEdit';

const RemainingEstimateInlineEdit = memo<Props>((props: Props) => {
	const {
		config,
		issueKey,
		issueId,
		onCancel,
		onEdit,
		onEscape,
		onConfirm,
		onEnter,
		onUpdate,
		readView,
		editView,
		placeholderMessage,
		invalidTimeFormatMessage,
		useRemainingEstimateField = defaultUseRemainingEstimateField,
		...rest
	} = props;

	const projectKey = useProjectKey();
	const [permissions] = useProjectPermissions(projectKey);
	const isEditable = permissions.canLogWork;

	const cloudId = useCloudId();

	const [commit] = useMutation<RemainingEstimateMutation>(graphql`
		mutation ui_issueFieldRemainingEstimate_RemainingEstimateField_Mutation(
			$input: JiraRemainingTimeEstimateFieldInput!
		) @raw_response_type {
			jira @optIn(to: ["JiraIssueFieldMutations"]) {
				updateRemainingTimeEstimateField(input: $input) {
					success
					errors {
						message
					}
					field {
						id
						remainingEstimate {
							timeInSeconds
						}
					}
				}
			}
		}
	`);

	const callCommit = useCallback(
		(value: number | undefined, id: IssueId): Promise<void> =>
			new Promise((resolve, reject) => {
				commit({
					variables: {
						input: {
							id: `ari:cloud:jira:${cloudId}:issuefieldvalue/${id}/timeestimate`,
							remainingEstimate: {
								timeInSeconds: value || 0,
							},
						},
					},
					onCompleted(res) {
						if (res.jira?.updateRemainingTimeEstimateField) {
							const { success, errors: responseErrors } =
								res.jira?.updateRemainingTimeEstimateField;
							if (success) {
								resolve();
							}
							if (responseErrors?.length) {
								reject(responseErrors[0].message);
							}
						} else {
							reject(new Error('Unknown error when mutating remaining estimate time'));
						}
					},
					onError(error) {
						reject(error);
					},
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					optimisticResponse: {
						jira: {
							updateRemainingTimeEstimateField: {
								success: true,
								errors: null,
								field: {
									id,
									remainingEstimate: {
										timeInSeconds: value,
									},
								},
							},
						},
					} as RemainingEstimateMutationResponse,
				});
			}),
		[commit, cloudId],
	);

	const [{ value: timeTrackingValue, error }, { saveValue, resetError }] =
		useRemainingEstimateField({
			issueId,
			issueKey: toIssueKey(issueKey),
			// @ts-expect-error - TS2322 - Type '(<T>(arg1: T) => void) | undefined' is not assignable to type '(() => void) | undefined'.
			onSuccess: onUpdate,
			save: callCommit,
		});

	const remainingEstimateSecondsValue = timeTrackingValue.remainingEstimateSeconds || 0;
	const [isInvalidInput, setIsInvalidInput] = useState<boolean>(false);
	const [isEditing, setIsEditing] = useState<boolean>(false);

	const intl = useIntl();

	const remainingTimeString = useMemo(() => {
		// At this time we need to force the value into an english formatted
		// time string eg: `1w 1d 1h 1m` as that is the only format supported
		// on entry, future changes will support localised values.
		const intlForTimeTrackingInput = {
			...intl,
			// @ts-expect-error - TS7031 - Binding element 'defaultMessage' implicitly has an 'any' type.
			formatMessage: ({ defaultMessage }) => defaultMessage || '',
		};
		return timeTrackingFormatter(
			remainingEstimateSecondsValue || 0,
			{
				workingHoursPerDay: config.hoursPerDay,
				workingDaysPerWeek: config.daysPerWeek,
				timeFormat: config.format || PRETTY,
				defaultUnit: config.defaultUnit,
			},
			intlForTimeTrackingInput,
		);
	}, [config, intl, remainingEstimateSecondsValue]);

	const readTimeString = useMemo(
		() =>
			timeTrackingFormatter(
				remainingEstimateSecondsValue || 0,
				{
					workingHoursPerDay: config.hoursPerDay,
					workingDaysPerWeek: config.daysPerWeek,
					timeFormat: config.format || PRETTY,
					defaultUnit: config.defaultUnit,
				},
				intl,
			),
		[config, intl, remainingEstimateSecondsValue],
	);

	const [inputTime, setInputTime] = useState<string>(remainingTimeString);

	useEffect(() => {
		setInputTime(remainingTimeString);
	}, [remainingTimeString]);

	useEffect(() => {
		if (error) {
			setIsEditing(true);
		}
	}, [error]);

	const save = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			if (isEqual(inputTime, remainingTimeString)) {
				setIsEditing(false);
				return;
			}

			if (isValidTimeString(config)(inputTime)) {
				const updatedTimeTrackingValue = {
					...timeTrackingValue,
					remainingEstimateSeconds: parseTimeString(config)(inputTime) || 0,
				};
				saveValue(updatedTimeTrackingValue, null, analyticsEvent);
				fireUIAnalytics(analyticsEvent);
				setIsInvalidInput(false);
				setIsEditing(false);
			} else {
				setIsInvalidInput(true);
				setIsEditing(true);
			}
		},
		[config, remainingTimeString, saveValue, timeTrackingValue, inputTime],
	);

	const cancel = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			resetError();
			setIsInvalidInput(false);
			setIsEditing(false);
			setInputTime(remainingTimeString);
			fireUIAnalytics(analyticsEvent);
		},
		[remainingTimeString, resetError],
	);

	const onCancelRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			cancel(analyticsEvent);
			onCancel && onCancel(analyticsEvent);
		},
		[cancel, onCancel],
	);

	const onConfirmRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			save(analyticsEvent);
			onConfirm && onConfirm(analyticsEvent);
		},
		[onConfirm, save],
	);

	const onEditRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			setIsEditing(true);
			fireUIAnalytics(analyticsEvent);
			onEdit && onEdit(analyticsEvent);
		},
		[onEdit],
	);

	const onEscapeRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			cancel(analyticsEvent);
			onEscape && onEscape(analyticsEvent);
		},
		[cancel, onEscape],
	);

	const onEnterRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			save(analyticsEvent);
			onEnter && onEnter(analyticsEvent);
		},
		[onEnter, save],
	);

	const onChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			setIsInvalidInput(false);
			resetError();
			setInputTime(e.target.value);
		},
		[resetError, setInputTime],
	);

	const renderEditView = () =>
		editView !== undefined ? (
			editView
		) : (
			<RemainingEstimateEdit
				defaultValue={inputTime}
				placeholderMessage={placeholderMessage}
				onChange={onChange}
				invalidTimeFormatMessage={invalidTimeFormatMessage}
				isInvalidInput={isInvalidInput}
			/>
		);

	const renderReadView = () =>
		readView !== undefined ? (
			readView
		) : (
			<RemainingEstimateView defaultValue={readTimeString} isHoverable={isEditable} />
		);

	const renderErrorFlag = () => {
		if (!error) return null;

		return (
			<ErrorFlag error={error} title={messages.errorTitle} description={messages.errorMessage} />
		);
	};

	return (
		<>
			{error && renderErrorFlag()}
			<InlineEditContainer isEditing={isEditing}>
				<FieldInlineEditStateLess
					testId="issue-field-remaining-estimate.ui.ui"
					actionSubject={actionSubject}
					isEditable={isEditable}
					isEditing={isEditing}
					readView={renderReadView()}
					editView={isEditable ? renderEditView() : null}
					onCancel={onCancelRequest}
					onConfirm={onConfirmRequest}
					onEdit={onEditRequest}
					onEscape={onEscapeRequest}
					onEnter={onEnterRequest}
					{...rest}
				/>
			</InlineEditContainer>
		</>
	);
});

const RemainingEstimateInlineEditOld = memo<Props>((props: Props) => {
	const {
		config,
		issueKey,
		issueId,
		onCancel,
		onEdit,
		onEscape,
		onConfirm,
		onEnter,
		onUpdate,
		readView,
		editView,
		placeholderMessage,
		invalidTimeFormatMessage,
		useRemainingEstimateField = defaultUseRemainingEstimateField,
		...rest
	} = props;

	const projectKey = useProjectKey();
	const [permissions] = useProjectPermissions(projectKey);
	const isEditable = permissions.canLogWork;

	const [{ value: timeTrackingValue, error }, { saveValue, resetError }] =
		useRemainingEstimateField({
			issueId,
			issueKey: toIssueKey(issueKey),
			// @ts-expect-error - TS2322 - Type '(<T>(arg1: T) => void) | undefined' is not assignable to type '(() => void) | undefined'.
			onSuccess: onUpdate,
			save: null,
		});

	const remainingEstimateSecondsValue = timeTrackingValue.remainingEstimateSeconds || 0;
	const [isInvalidInput, setIsInvalidInput] = useState<boolean>(false);
	const [isEditing, setIsEditing] = useState<boolean>(false);

	const intl = useIntl();

	const remainingTimeString = useMemo(() => {
		// At this time we need to force the value into an english formatted
		// time string eg: `1w 1d 1h 1m` as that is the only format supported
		// on entry, future changes will support localised values.
		const intlForTimeTrackingInput = {
			...intl,
			// @ts-expect-error - TS7031 - Binding element 'defaultMessage' implicitly has an 'any' type.
			formatMessage: ({ defaultMessage }) => defaultMessage || '',
		};
		return timeTrackingFormatter(
			remainingEstimateSecondsValue || 0,
			{
				workingHoursPerDay: config.hoursPerDay,
				workingDaysPerWeek: config.daysPerWeek,
				timeFormat: config.format || PRETTY,
				defaultUnit: config.defaultUnit,
			},
			intlForTimeTrackingInput,
		);
	}, [config, intl, remainingEstimateSecondsValue]);

	const readTimeString = useMemo(
		() =>
			timeTrackingFormatter(
				remainingEstimateSecondsValue || 0,
				{
					workingHoursPerDay: config.hoursPerDay,
					workingDaysPerWeek: config.daysPerWeek,
					timeFormat: config.format || PRETTY,
					defaultUnit: config.defaultUnit,
				},
				intl,
			),
		[config, intl, remainingEstimateSecondsValue],
	);

	const [inputTime, setInputTime] = useState<string>(remainingTimeString);

	useEffect(() => {
		setInputTime(remainingTimeString);
	}, [remainingTimeString]);

	useEffect(() => {
		if (error) {
			setIsEditing(true);
		}
	}, [error]);

	const save = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			if (isEqual(inputTime, remainingTimeString)) {
				setIsEditing(false);
				return;
			}

			if (isValidTimeString(config)(inputTime)) {
				const updatedTimeTrackingValue = {
					...timeTrackingValue,
					remainingEstimateSeconds: parseTimeString(config)(inputTime) || 0,
				};
				saveValue(updatedTimeTrackingValue, null, analyticsEvent);
				fireUIAnalytics(analyticsEvent);
				setIsInvalidInput(false);
				setIsEditing(false);
			} else {
				setIsInvalidInput(true);
				setIsEditing(true);
			}
		},
		[config, remainingTimeString, saveValue, timeTrackingValue, inputTime],
	);

	const cancel = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			resetError();
			setIsInvalidInput(false);
			setIsEditing(false);
			setInputTime(remainingTimeString);
			fireUIAnalytics(analyticsEvent);
		},
		[remainingTimeString, resetError],
	);

	const onCancelRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			cancel(analyticsEvent);
			onCancel && onCancel(analyticsEvent);
		},
		[cancel, onCancel],
	);

	const onConfirmRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			save(analyticsEvent);
			onConfirm && onConfirm(analyticsEvent);
		},
		[onConfirm, save],
	);

	const onEditRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			setIsEditing(true);
			fireUIAnalytics(analyticsEvent);
			onEdit && onEdit(analyticsEvent);
		},
		[onEdit],
	);

	const onEscapeRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			cancel(analyticsEvent);
			onEscape && onEscape(analyticsEvent);
		},
		[cancel, onEscape],
	);

	const onEnterRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			save(analyticsEvent);
			onEnter && onEnter(analyticsEvent);
		},
		[onEnter, save],
	);

	const onChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			setIsInvalidInput(false);
			resetError();
			setInputTime(e.target.value);
		},
		[resetError, setInputTime],
	);

	const renderEditView = () =>
		editView !== undefined ? (
			editView
		) : (
			<RemainingEstimateEdit
				defaultValue={inputTime}
				placeholderMessage={placeholderMessage}
				onChange={onChange}
				invalidTimeFormatMessage={invalidTimeFormatMessage}
				isInvalidInput={isInvalidInput}
			/>
		);

	const renderReadView = () =>
		readView !== undefined ? (
			readView
		) : (
			<RemainingEstimateView defaultValue={readTimeString} isHoverable={isEditable} />
		);

	const renderErrorFlag = () => {
		if (!error) return null;

		return (
			<ErrorFlag error={error} title={messages.errorTitle} description={messages.errorMessage} />
		);
	};

	return (
		<>
			{error && renderErrorFlag()}
			<InlineEditContainer isEditing={isEditing}>
				<FieldInlineEditStateLess
					testId="issue-field-remaining-estimate.ui.ui"
					actionSubject={actionSubject}
					isEditable={isEditable}
					isEditing={isEditing}
					readView={renderReadView()}
					editView={isEditable ? renderEditView() : null}
					onCancel={onCancelRequest}
					onConfirm={onConfirmRequest}
					onEdit={onEditRequest}
					onEscape={onEscapeRequest}
					onEnter={onEnterRequest}
					{...rest}
				/>
			</InlineEditContainer>
		</>
	);
});

export default componentWithCondition(
	() => fg('jiv-18175-usemutation-for-remaining-estimate-field'),
	RemainingEstimateInlineEdit,
	RemainingEstimateInlineEditOld,
);

// TODO: migrate to object syntax. Autofix is available for many cases. Remove the eslint-disable for @atlaskit/design-system/no-styled-tagged-template-expression to check.
// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const InlineEditContainer = styled.div<{ isEditing: boolean }>`
	${({ isEditing }) =>
		isEditing ? `margin-left: ${token('space.negative.075', '-6px')};` : 'margin-left: -10px;'}
	margin-top: ${token('space.negative.100', '-8px')};
	padding-right: ${token('space.150', '12px')};
	padding-left: ${token('space.050', '4px')};

	& div[data-read-view-fit-container-width] {
		width: 100%;
		min-height: 32px;
		padding: ${token('space.075', '6px')} 0;
	}
`;
