import { type StoreActionApi } from 'react-sweet-state';

import {
	type ExtraErrorAttributes,
	type InnerContainerProps,
	type State,
} from '../common/utils/types';

export const reporterAction =
	({ id, error, attributes }: { id: string; error: unknown; attributes?: ExtraErrorAttributes }) =>
	({}: StoreActionApi<State>, { reporter }: InnerContainerProps) => {
		if (reporter) {
			reporter({ id, error, attributes });
			return;
		}
	};

export const getHorizontalNav =
	() =>
	({}: StoreActionApi<State>, { config }: InnerContainerProps) => {
		return config?.isNewNavigation || false;
	};
