export const ATTACHMENT_PARENT_ISSUE = 'issue';

export const ATTACHMENT_PARENT = {
	comment: 'comment',
	worklog: 'worklog',
	description: 'description',
	environment: 'environment',
	customfield: 'customfield',
	issue: ATTACHMENT_PARENT_ISSUE,
	form: 'form',
} as const;

export const ORDER_DIRECTION = {
	ASC: 'ASC',
	DESC: 'DESC',
} as const;

export const DEFAULT_ORDER_DIRECTION = ORDER_DIRECTION.DESC;

export const ORDER_FIELD = {
	FILENAME: 'FILENAME',
	CREATED: 'CREATED',
	FILESIZE: 'FILESIZE',
	MIMETYPE: 'MIMETYPE',
} as const;

export const DEFAULT_ORDER_FIELD = ORDER_FIELD.CREATED;

export const DEFAULT_PAGE_SIZE = 12;
