import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	heading: {
		id: 'issue-view-layout-templates-views.context.visible-hidden.pin-field-configurability.pin-field-info-banner.heading',
		defaultMessage: 'Fields pinned',
		description:
			'This message is displayed as a heading when the user has pinned fields in the issue view layout. It indicates that fields have been successfully pinned.',
	},
	info: {
		id: 'issue-view-layout-templates-views.context.visible-hidden.pin-field-configurability.pin-field-info-banner.info',
		defaultMessage: 'To unpin a field, hover over the field name and select the pin icon. ',
		description:
			'This message provides instructions on how to unpin a field. It is displayed in the information banner in the issue view layout and instructs the user to hover over the field name and select the pin icon to unpin a field.',
	},
	crossButtonMessage: {
		id: 'issue-view-layout-templates-views.context.visible-hidden.pin-field-configurability.pin-field-info-banner.cross-button-message',
		defaultMessage: 'Close Pin Field Information Banner',
		description:
			'This message is displayed as the aria-label for the close button in the information banner in the issue view layout. It instructs the user to close the information banner.',
	},
});
