import head from 'lodash/head';
import type { IntervalFieldSource } from '@atlassian/jira-polaris-domain-field/src/field/interval/index.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import {
	fireTrackAnalytics,
	type UIAnalyticsEvent,
} from '@atlassian/jira-product-analytics-bridge';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { createGetFieldByKey } from '../../selectors/field.tsx';
import type { Props, State } from '../../types.tsx';

export const updateIntervalFieldSource =
	(
		fieldKey: FieldKey,
		source: IntervalFieldSource | undefined,
		contextAnalyticsEvent: UIAnalyticsEvent,
	) =>
	async (
		{ getState, setState }: StoreActionApi<State>,
		{ fieldRemote, issueTypeIds }: Props,
		// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
	): Promise<Error | void> => {
		const state = getState();
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		const currentField = createGetFieldByKey(fieldKey)(state)!;
		const issueTypeId = head(issueTypeIds);

		if (issueTypeId === undefined) {
			throw new Error('cannot update interval field source for unknown issue type');
		}

		const oldConfiguration = currentField.configuration ? currentField.configuration : {};
		const newConfiguration = { ...currentField.configuration };

		if (source) {
			newConfiguration.source = source;
		} else {
			delete newConfiguration.source;
			delete newConfiguration.aggregationType;
			delete newConfiguration.customfieldKey;
		}

		const fields = { ...getState().fields };
		fields[fieldKey] = {
			...fields[fieldKey],
			configuration: newConfiguration,
		};
		setState({ fields });

		await fieldRemote.updateFieldConfiguration({
			fieldKey,
			issueTypeId,
			oldConfiguration,
			configuration: newConfiguration,
			type: 'updateIntervalFieldSource',
		});

		fireTrackAnalytics(contextAnalyticsEvent, 'issueFields updated', {
			issueFieldKey: fieldKey,
			issueFieldType: currentField.type,
			updatedItems: source
				? [{ name: 'source' }]
				: [{ name: 'source' }, { name: 'aggregationType' }, { name: 'customfieldKey' }],
		});
	};
