import React, { useRef } from 'react';
import Tooltip from '@atlaskit/tooltip';
import {
	TooltipComponent,
	TooltipContent,
	TooltipTrigger,
	TruncateWithTooltipContainer,
} from '../components/index.tsx';
import { TOOLTIP_DELAY } from '../constants.tsx';
import { useTruncatedTooltip } from '../use-truncated-tooltip/index.tsx';
import type { TruncateWithTooltipOnHoverProps } from './types.tsx';

export const useContainerRef = () => useRef<HTMLDivElement | null>(null);

export const TruncateWithTooltipOnHover = ({ children }: TruncateWithTooltipOnHoverProps) => {
	const containerRef = useContainerRef();
	const { shouldShowTruncatedTooltip } = useTruncatedTooltip({
		containerRef,
		// Tooltip delay is set on the tooltip component
		tooltipDelay: 0,
	});

	return (
		<Tooltip
			content={
				shouldShowTruncatedTooltip
					? () => (
							<TooltipContent data-testid="command-palette.common.ui.truncate-with-tooltip.truncate-with-tooltip-on-hover.tooltip-content">
								{children}
							</TooltipContent>
						)
					: ''
			}
			component={TooltipComponent}
			position="bottom-end"
			delay={TOOLTIP_DELAY}
		>
			{(tooltipProps) => (
				<TooltipTrigger
					data-testid="command-palette.common.ui.truncate-with-tooltip.truncate-with-tooltip-on-hover.tooltip-trigger"
					{...tooltipProps}
				>
					<TruncateWithTooltipContainer ref={containerRef}>{children}</TruncateWithTooltipContainer>
				</TooltipTrigger>
			)}
		</Tooltip>
	);
};
