type ElementToCheck = {
	// HTMLElement and EventTarget are inexact therefore cannot use exact type
	href?: string;
	parentElement?: Element | null;
};
/**
 * Recursively searches for an 'href' attribute by traversing up the DOM tree from a given element until a specified top element is reached.
 * This function is useful for determining if an element is part of a larger navigable link structure.
 */
// @ts-expect-error - TS7023 - 'checkForHref' implicitly has return type 'any' because it does not have a return type annotation and is referenced directly or indirectly in one of its return expressions.
const checkForHref = (
	currentElement: ElementToCheck | null | null | undefined,
	topElement: ElementToCheck,
) => {
	// if we reach the original div, we didn't find href
	if (!currentElement || currentElement === topElement) {
		return false;
	}
	if (currentElement.href != null) {
		return true;
	}

	// continue walking up the DOM tree looking for hrefs
	return checkForHref(currentElement.parentElement, topElement);
};
export default checkForHref;
