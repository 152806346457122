import React, { useCallback } from 'react';
import get from 'lodash/get';
import isNaN from 'lodash/isNaN';
import { DateTimePicker } from '@atlaskit/datetime-picker';
import availableTimes from '@atlassian/jira-common-constants/src/datetimepicker-available-times.tsx';
import { convertISODateFormat } from '@atlassian/jira-compatibility/src/index.tsx';
import { standardizeLocale } from '@atlassian/jira-issue-format-date/src/common/utils.tsx';
import { useCurrentUser } from '@atlassian/jira-platform-services-user-current/src/main.tsx';
import { getLocale } from '@atlassian/jira-platform-utils-date-fns/src/main.tsx';
import type { TimeZone } from '@atlassian/jira-shared-types/src/general.tsx';
import { useLocale } from '@atlassian/jira-tenant-context-controller/src/components/locale/index.tsx';
import { DEFAULT_WEEK_START_DAY } from '../../common/constants.tsx';
import type {
	JiraDateTimePickerValue,
	ParseValueFn,
	DatePickerProps,
	TimePickerProps,
} from '../../common/types.tsx';
import { parseDateTimeValue } from '../../common/utils.tsx';

export type DateTimeFieldEditViewProps = {
	isDisabled?: boolean;
	isInvalid?: boolean;
	autoFocus?: boolean;
	timeZone?: TimeZone | null;
	value: JiraDateTimePickerValue;
	datePickerProps?: DatePickerProps;
	timePickerProps?: TimePickerProps;
	onParseValue?: ParseValueFn;
	onChange?: (value: JiraDateTimePickerValue) => void;
	label?: string;
	id?: string;
};

export const DateTimeFieldEditView = ({
	autoFocus,
	isDisabled,
	isInvalid,
	timeZone,
	onParseValue = parseDateTimeValue,
	value,
	onChange,
	datePickerProps = {},
	timePickerProps = {},
	label,
	...fieldProps
}: DateTimeFieldEditViewProps) => {
	const locale = useLocale();
	const {
		data: {
			// @ts-expect-error - TS2339 - Property 'timeZone' does not exist on type 'DataBasic | (DataBasic & ResponseSuccess)'.
			user: { timeZone: currentUserTimeZone = null },
		},
	} = useCurrentUser();

	// Prefer timeZone prop (even if it's null!), and default to current user's timezone if prop is not set
	const timeZoneToUse: TimeZone | null = timeZone !== undefined ? timeZone : currentUserTimeZone;

	const handleOnChange = useCallback(
		(isoDateTimeValue?: string | null) => {
			const convertedIso = convertISODateFormat(isoDateTimeValue ?? '');
			if (isNaN(Date.parse(convertedIso))) {
				onChange?.(null);
			} else {
				onChange?.(convertedIso);
			}
		},
		[onChange],
	);

	const parseValue: ParseValueFn = useCallback(
		(dateTimeValue, fallbackDate, fallbackTime, fallbackTimeZone) =>
			onParseValue(dateTimeValue, fallbackDate, fallbackTime, timeZoneToUse ?? fallbackTimeZone),
		[onParseValue, timeZoneToUse],
	);

	const standardizedLocale = standardizeLocale(locale);

	return (
		<DateTimePicker
			testId="issue-field-date-time.ui.edit.date-time-picker"
			locale={standardizedLocale}
			autoFocus={autoFocus}
			// @ts-expect-error - TS2322 - Type '{ datePickerProps?: { weekStartDay: any; } | undefined; testId: string; locale: string; autoFocus: boolean | undefined; defaultIsOpen: boolean; isDisabled: boolean | undefined; isInvalid: boolean | undefined; ... 6 more ...; datePickerSelectProps: DatePickerSelectProps; }' is not assignable to type 'IntrinsicAttributes & Pick<Pick<Pick<DateTimePickerProps, "name" | "value" | "locale" | "id" | "autoFocus" | "defaultValue" | "onFocus" | ... 17 more ... | "parseValue">, "value" | ... 4 more ... | "parseValue"> & Partial<...> & Partial<...> & RefAttributes<...> & WithContextProps, "name" | ... 25 more ... | "parseV...'.
			defaultIsOpen={false}
			isDisabled={isDisabled}
			isInvalid={isInvalid}
			value={value || ''}
			onChange={handleOnChange}
			parseValue={timeZoneToUse != null ? parseValue : undefined}
			// Use of non-standardized locale is intentional, getLocale map accounts for this.
			datePickerProps={{
				weekStartDay: get(getLocale(locale), 'options.weekStartsOn', DEFAULT_WEEK_START_DAY),
				selectProps: {
					'aria-labelledby': `${fieldProps?.id}-label ${fieldProps?.id}-message`,
					onKeyDown: datePickerProps.selectProps?.onKeyDown,
				},
				placeholder: datePickerProps.placeholder,
				id: fieldProps?.id,
				...datePickerProps,
			}}
			spacing="compact"
			timePickerProps={{
				times: availableTimes,
				timeIsEditable: true,
				selectProps: {
					formatCreateLabel: (val: string) => val,

					// Want to show the dropdown option if the user input
					// matches the value (22:30) or the label (10:30pm)
					filterOption: (
						option: {
							label: string;
							value: unknown;
						},
						val: string,
					) => !val || option.value === val || option.label === val,
				},
				...timePickerProps,
			}}
		/>
	);
};

export const DateTimeFieldEditViewEntryPointContent = ({
	props,
}: {
	props: DateTimeFieldEditViewProps;
}) => <DateTimeFieldEditView {...props} />;
