import { functionWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import messages from './messages.tsx';

export const classifyIssueLabel = functionWithCondition(
	() => fg('jira-issue-terminology-refresh-m3'),
	() => messages.classifyIssueIssueTermRefresh,
	() => messages.classifyIssue,
)();
export const classifyRequestLabel = messages.classifyRequest;
