// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	placeholder: {
		id: 'issue.multi-user.text-for-edit-view-when-no-value-is-provided',
		defaultMessage: 'Select User',
		description: '',
	},
});
