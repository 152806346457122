import React, { useCallback } from 'react';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import type { Props } from './types.tsx';

const LinkToParent = ({ onLinkToParentClick, onClick, label, itemKey }: Props) => {
	const handleClick = useCallback(() => {
		onLinkToParentClick();
		onClick?.(itemKey);
	}, [onLinkToParentClick, onClick, itemKey]);

	return (
		<DropdownItem key="link-to-parent" onClick={handleClick}>
			{label}
		</DropdownItem>
	);
};

export default LinkToParent;
