// Original onAdd issue link lives here: jira/src/packages/issue/issue-view-base/src/content/issue-links/add/view.tsx

import { useEffect } from 'react';
import { commandPaletteSubscribeEvent } from '@atlassian/jira-command-palette-subscriptions-integration/src/index.tsx';
import type { AnalyticsEvent } from '@atlassian/jira-common-analytics-v2-wrapped-components/src/types.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { IssueLinkDirection } from '@atlassian/jira-issue-shared-types/src/common/types/linked-issue-type.tsx';
import { transformIssueLink } from '@atlassian/jira-issue-view-content-issue-links/src/add/transformer.tsx';
import type { NewIssueLinks } from '@atlassian/jira-issue-view-store/src/actions/issue-links-actions.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import {
	useProjectKey,
	useApplication,
	useEdition,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import type { Application } from '@atlassian/jira-shared-types/src/application.tsx';
import type { ApplicationEdition } from '@atlassian/jira-shared-types/src/edition.tsx';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';

export type UseLinkUnlinkIssuesProps = {
	linkIssuesRequest: (issueLinks: NewIssueLinks) => void;
	linkIssuesSuccess: (
		optimisticId: string,
		analyticsEvent: AnalyticsEvent,
		issueKey?: string,
		postAnalyticsEvent?: AnalyticsEvent,
	) => void;
	linkIssuesFailure: (optimisticId: string, error: string, analyticsEvent?: AnalyticsEvent) => void;
	unlinkIssueRequest: (
		linkedIssueId: string,
		application: Application | undefined,
		edition: ApplicationEdition | undefined,
	) => void;
	unlinkIssueSuccess: (
		linkedIssueId: string,
		meta: { issueId: IssueId; direction: IssueLinkDirection },
	) => void;
	unlinkIssueFailure: (linkedIssueId: string) => void;
};

export const useLinkUnlinkIssues = ({
	linkIssuesRequest,
	linkIssuesSuccess,
	linkIssuesFailure,
	unlinkIssueRequest,
	unlinkIssueSuccess,
	unlinkIssueFailure,
}: UseLinkUnlinkIssuesProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const issueKey: string = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const application = useApplication(projectKey, true);
	const edition = useEdition(projectKey, true);

	useEffect(() => {
		const unregister = commandPaletteSubscribeEvent('link-issues-request', (data) => {
			if (!(data.id === 'link-issues-request')) return;
			const { optimisticId, newIssueLink, issueLinkType } = data.data;
			linkIssuesRequest({
				optimisticId,
				linkedIssueKey: newIssueLink.key,
				issueLinkType,
				issueLinks: transformIssueLink(optimisticId, issueLinkType, newIssueLink, ''),
				isOpenedFromJsmSimilarIssues: false,
			});
		});
		return () => {
			unregister();
		};
	}, [linkIssuesRequest]);

	useEffect(() => {
		const unregister = commandPaletteSubscribeEvent('link-issues-success', (data) => {
			if (!(data.id === 'link-issues-success')) return;
			const { optimisticId, linkedIssueKey } = data.data;
			linkIssuesSuccess(
				optimisticId,
				createAnalyticsEvent({
					action: 'succeeded',
					isOpenedFromJsmSimilarIssues: false,
				}),
				linkedIssueKey,
				createAnalyticsEvent({
					action: 'linkedToProject',
				}),
			);
		});
		return () => {
			unregister();
		};
	}, [createAnalyticsEvent, linkIssuesSuccess]);

	useEffect(() => {
		const unregister = commandPaletteSubscribeEvent('link-issues-failure', (data) => {
			if (!(data.id === 'link-issues-failure')) return;
			const { optimisticId, error } = data.data;
			linkIssuesFailure(optimisticId, error);
		});
		return () => {
			unregister();
		};
	}, [linkIssuesFailure]);

	useEffect(() => {
		const unregister = commandPaletteSubscribeEvent('unlink-issue-request', (data) => {
			if (!(data.id === 'unlink-issue-request')) return;
			const { linkedIssueId } = data.data;
			unlinkIssueRequest(linkedIssueId, application, edition);
		});
		return () => {
			unregister();
		};
	}, [unlinkIssueRequest, application, edition]);

	useEffect(() => {
		const unregister = commandPaletteSubscribeEvent('unlink-issue-success', (data) => {
			if (!(data.id === 'unlink-issue-success')) return;
			const { linkedIssueId, issueId, direction } = data.data;
			unlinkIssueSuccess(linkedIssueId, { issueId, direction });
		});
		return () => {
			unregister();
		};
	}, [unlinkIssueSuccess]);

	useEffect(() => {
		const unregister = commandPaletteSubscribeEvent('unlink-issue-failure', (data) => {
			if (!(data.id === 'unlink-issue-failure')) return;
			const { linkedIssueId } = data.data;
			unlinkIssueFailure(linkedIssueId);
		});
		return () => {
			unregister();
		};
	}, [unlinkIssueFailure]);

	return null;
};
