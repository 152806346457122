import { isFieldKey } from '@atlassian/jira-polaris-domain-field/src/field/utils.tsx';
import {
	type Filter,
	type FieldValueFilter,
	type NumericFieldFilter,
	type IntervalFieldFilter,
	type IntervalFieldFilterValue,
	type IntervalFieldFilterLegacyValue,
	type IntervalFieldFilterAbsoluteDatesValue,
	type IntervalFieldFilterRollingDatesCurrentValue,
	type IntervalFieldFilterRollingDatesPastNextValue,
	type IntervalFieldFilterLegacy,
	type IntervalFieldFilterEmptyValue,
	type IntervalFieldFilterNotEmptyValue,
	type IntervalFieldFilterEmpty,
	type IntervalFieldFilterNotEmpty,
	INTERVAL_FILTER_OPERATOR_CODES,
	INTERVAL_FILTER_TIME_CURRENT,
} from './types.tsx';

export const filterHasFieldProperty = (
	filter: Filter,
): filter is FieldValueFilter | NumericFieldFilter | IntervalFieldFilter =>
	'field' in filter && isFieldKey(filter.field);

function createIsIntervalFieldFilter<T extends IntervalFieldFilter>(
	fn: (value: IntervalFieldFilterValue) => boolean,
) {
	return function isIntervalFieldFilter(filter: IntervalFieldFilter): filter is T {
		return filter.values.length === 0 || filter.values.some((value) => fn(value));
	};
}

export const isIntervalFieldFilterLegacy = createIsIntervalFieldFilter<IntervalFieldFilterLegacy>(
	isIntervalFieldFilterLegacyValue,
);

export const isIntervalFieldFilterEmpty = createIsIntervalFieldFilter<IntervalFieldFilterEmpty>(
	isIntervalFieldFilterEmptyValue,
);

export const isIntervalFieldFilterNotEmpty =
	createIsIntervalFieldFilter<IntervalFieldFilterNotEmpty>(isIntervalFieldFilterNotEmptyValue);

function isObject(obj: unknown): obj is object {
	return obj !== null && typeof obj === 'object';
}

export function isIntervalFieldFilterLegacyValue(
	value: unknown,
): value is IntervalFieldFilterLegacyValue {
	return INTERVAL_FILTER_OPERATOR_CODES.some(
		(operator) => isObject(value) && 'operator' in value && value.operator === operator,
	);
}

export function isIntervalFieldFilterAbsoluteDatesValue(
	value: unknown,
): value is IntervalFieldFilterAbsoluteDatesValue {
	return isObject(value) && 'type' in value && value.type === 'ABSOLUTE_DATES';
}

export function isIntervalFieldFilterRollingDatesCurrentValue(
	value: unknown,
): value is IntervalFieldFilterRollingDatesCurrentValue {
	return (
		isObject(value) &&
		'type' in value &&
		value.type === 'ROLLING_DATES' &&
		'time' in value &&
		value.time === INTERVAL_FILTER_TIME_CURRENT
	);
}

export function isIntervalFieldFilterRollingDatesPastNextValue(
	value: unknown,
): value is IntervalFieldFilterRollingDatesPastNextValue {
	return (
		isObject(value) &&
		'type' in value &&
		value.type === 'ROLLING_DATES' &&
		'time' in value &&
		value.time !== INTERVAL_FILTER_TIME_CURRENT
	);
}

export function isIntervalFieldFilterEmptyValue(
	value: IntervalFieldFilterValue,
): value is IntervalFieldFilterEmptyValue {
	return 'operator' in value && value.operator === 'EQ';
}

export function isIntervalFieldFilterNotEmptyValue(
	value: IntervalFieldFilterValue,
): value is IntervalFieldFilterNotEmptyValue {
	return 'operator' in value && value.operator === 'NEQ';
}

export function isArrayIntervalFieldFilterLegacyValue(
	arr: IntervalFieldFilterValue[],
): arr is IntervalFieldFilterLegacyValue[] {
	if (arr.length > 0) {
		return isIntervalFieldFilterLegacyValue(arr[0]);
	}

	return true;
}
