import { useMemo } from 'react';
import { useOnboardingTourStore } from './context.tsx';

export const useOnboardingTour = () => {
	const [{ isTourActive }, { setTourIsActive, setTourIsInactive }] = useOnboardingTourStore();

	const actions = useMemo(
		() => ({ setTourIsActive, setTourIsInactive }),
		[setTourIsActive, setTourIsInactive],
	);

	return [{ isTourActive }, actions] as const;
};
