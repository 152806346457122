import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';

// This function is used to determine if the Automation Audit Log panel should be shown.
const useShouldShowAutomationAuditLogPanel = () => {
	const accountId: string | null = useAccountId();
	const isSignedIn = accountId !== null;
	return isSignedIn;
};

export default useShouldShowAutomationAuditLogPanel;
