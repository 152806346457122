import type { BatchAction } from 'redux-batched-actions';
import { type Epic, combineEpics } from 'redux-observable';
import type { SubProduct } from '@atlassian/jira-common-constants/src/sub-product-types.tsx';
import type { AttachmentServiceActions } from '@atlassian/jira-issue-attachments-base/src/services/attachments-service/types.tsx';
import type { IssueContextServiceActions } from '@atlassian/jira-issue-context-service/src/types.tsx';
import type { MediaContextServiceActions } from '@atlassian/jira-issue-media-context-service/src/types.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import type { UploadContextAction } from '@atlassian/jira-issue-view-store/src/common/media/upload-context/upload-context-actions.tsx';
import type { UserAuthAction } from '@atlassian/jira-issue-view-store/src/common/media/user-auth/user-auth-actions.tsx';
import type { ViewContextAction } from '@atlassian/jira-issue-view-store/src/common/media/view-context/view-context-actions.tsx';
import {
	uploadContextEpic,
	uploadContextEpicDeprecated,
} from './upload-context/upload-context-epic.tsx';
import { userAuthEpic } from './user-auth/user-auth-epic.tsx';
import { viewContextEpic, viewContextEpicDeprecated } from './view-context/view-context-epic.tsx';

export const mediaContextEpicDeprecated = (
	getBaseUrl: (arg1: State) => string,
	getIssueKey: (arg1: State) => string,
	attachmentActions: AttachmentServiceActions,
	issueContextActions: IssueContextServiceActions,
	mediaContextActions: MediaContextServiceActions,
): Epic<UserAuthAction | ViewContextAction | UploadContextAction | BatchAction, State> =>
	combineEpics<UserAuthAction | ViewContextAction | UploadContextAction | BatchAction, State>(
		uploadContextEpicDeprecated(getBaseUrl, getIssueKey, mediaContextActions),
		// @ts-expect-error - TS2345: Type 'UserAuthAction | ViewContextAction | UploadContextAction | BatchAction' is not assignable to type 'ViewContextAction | BatchAction'.
		viewContextEpicDeprecated(
			getBaseUrl,
			getIssueKey,
			attachmentActions,
			issueContextActions,
			mediaContextActions,
		),
		userAuthEpic(getBaseUrl, getIssueKey, mediaContextActions),
	);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	getBaseUrl: (arg1: State) => string,
	getIssueKey: (arg1: State) => string,
	subProduct: SubProduct | null,
	attachmentActions: AttachmentServiceActions,
	issueContextActions: IssueContextServiceActions,
	mediaContextActions: MediaContextServiceActions,
): Epic<UserAuthAction | ViewContextAction | UploadContextAction | BatchAction, State> =>
	combineEpics<UserAuthAction | ViewContextAction | UploadContextAction | BatchAction, State>(
		uploadContextEpic(getBaseUrl, getIssueKey, subProduct, mediaContextActions),
		// @ts-expect-error - TS2345: Type 'UserAuthAction | ViewContextAction | UploadContextAction | BatchAction' is not assignable to type 'ViewContextAction | BatchAction'.
		viewContextEpic(
			getBaseUrl,
			getIssueKey,
			subProduct,
			attachmentActions,
			issueContextActions,
			mediaContextActions,
		),
		userAuthEpic(getBaseUrl, getIssueKey, mediaContextActions),
	);
