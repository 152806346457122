import { useMemo } from 'react';
import { useService } from '@atlassian/jira-common-services/src/use-service/index.tsx';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { EmailSettingsResponse, EmailSettingsHook } from './types.tsx';

const getEmailSettings = async (): Promise<EmailSettingsResponse | undefined> =>
	performGetRequest('/rest/jira-mail-plugin/1.0/outgoingMail/config');

export const useEmailSettingsService = (): EmailSettingsHook => {
	const {
		loading,
		error,
		data,
		fetch: fetchEmailSettings,
	} = useService<EmailSettingsResponse>(getEmailSettings);
	const transformedData = useMemo(
		() =>
			data
				? {
						isEmailEnabled: data.enabled,
						canUserEnableEmail: data.canEditConfig,
					}
				: undefined,
		[data],
	);

	return [{ loading, error, data: transformedData }, fetchEmailSettings];
};
export default useEmailSettingsService;
