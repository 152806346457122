import React, { useEffect, useRef } from 'react';
import { useDwell } from '../../controllers/use-dwell.tsx';

type Props = {
	children: React.ReactNode;
	delay?: number;
	onDwell?: (dwell: boolean) => void;
};

/**
 * Use this component to wrap content that you want to track dwell time on.
 * (hovering over the content for a specified amount of time)
 * @param {Props} props
 * @param {React.ReactNode} props.children - The content to wrap
 * @param {number} [props.delay=2000] - The delay in milliseconds before the onDwell callback is called
 * @param {(dwell: boolean) => void} [props.onDwell] - The callback to call when the user has dwelled on the content for the specified delay
 */
export const DwellWrapper = ({ children, delay = 2000, onDwell }: Props) => {
	const containerRef = useRef(null);
	const eventFiredRef = useRef(false);
	const dwell = useDwell({ ref: containerRef, delay });

	useEffect(() => {
		// for successive re-renders we need to use the ref to check if the event has already been fired
		// ex: if dwell is true (user is inside panel) and onDwell changes because of a re-render, we don't want to fire the event again
		// if dwell becomes false (user leaves panel), we want to reset the eventFiredRef so that the event can be fired again
		if (dwell && onDwell && !eventFiredRef.current) {
			onDwell(dwell);
			eventFiredRef.current = true;
		}
		if (!dwell) {
			eventFiredRef.current = false;
		}
	}, [dwell, onDwell]);

	return <div ref={containerRef}>{children}</div>;
};
