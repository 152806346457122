/**
 * @generated SignedSource<<43756a5459fcd34f98fba8fd39253be8>>
 * @relayHash 5b4bf8a90d936b0ec625d595f7bdf11a
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 3f544ceec779657803bd6edddfff556f195b12f9cbeabe8b1a2819093f480e23

import type { ConcreteRequest, Query } from 'relay-runtime';
export type main_votersListService_issueFieldVoters_IssueVotersRelayQuery$variables = {
  cloudId: string;
  issueKey: string;
};
export type main_votersListService_issueFieldVoters_IssueVotersRelayQuery$data = {
  readonly jira: {
    readonly issueByKey: {
      readonly votesField: {
        readonly selectedUsersConnection?: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly accountId: string;
              readonly id: string;
              readonly name: string;
              readonly picture: AGG$URL;
            } | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type main_votersListService_issueFieldVoters_IssueVotersRelayQuery = {
  response: main_votersListService_issueFieldVoters_IssueVotersRelayQuery$data;
  variables: main_votersListService_issueFieldVoters_IssueVotersRelayQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueKey"
},
v2 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "kind": "Variable",
    "name": "key",
    "variableName": "issueKey"
  }
],
v3 = [
  {
    "kind": "Literal",
    "name": "idOrAlias",
    "value": "votes"
  }
],
v4 = {
  "kind": "ScalarField",
  "name": "id"
},
v5 = {
  "kind": "ScalarField",
  "name": "accountId"
},
v6 = {
  "kind": "ScalarField",
  "name": "name"
},
v7 = {
  "kind": "ScalarField",
  "name": "picture"
},
v8 = {
  "kind": "ScalarField",
  "name": "__typename"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "name": "main_votersListService_issueFieldVoters_IssueVotersRelayQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v2/*: any*/),
            "concreteType": "JiraIssue",
            "kind": "LinkedField",
            "name": "issueByKey",
            "plural": false,
            "selections": [
              {
                "alias": "votesField",
                "args": (v3/*: any*/),
                "kind": "LinkedField",
                "name": "fieldByIdOrAlias",
                "plural": false,
                "selections": [
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "concreteType": "JiraUserConnection",
                        "kind": "LinkedField",
                        "name": "selectedUsersConnection",
                        "plural": false,
                        "selections": [
                          {
                            "concreteType": "JiraUserEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v4/*: any*/),
                                  (v5/*: any*/),
                                  (v6/*: any*/),
                                  (v7/*: any*/)
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ],
                    "type": "JiraVotesField"
                  }
                ],
                "storageKey": "fieldByIdOrAlias(idOrAlias:\"votes\")"
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "main_votersListService_issueFieldVoters_IssueVotersRelayQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v2/*: any*/),
            "concreteType": "JiraIssue",
            "kind": "LinkedField",
            "name": "issueByKey",
            "plural": false,
            "selections": [
              {
                "alias": "votesField",
                "args": (v3/*: any*/),
                "kind": "LinkedField",
                "name": "fieldByIdOrAlias",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "concreteType": "JiraUserConnection",
                        "kind": "LinkedField",
                        "name": "selectedUsersConnection",
                        "plural": false,
                        "selections": [
                          {
                            "concreteType": "JiraUserEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v8/*: any*/),
                                  (v4/*: any*/),
                                  (v5/*: any*/),
                                  (v6/*: any*/),
                                  (v7/*: any*/)
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ],
                    "type": "JiraVotesField"
                  },
                  (v4/*: any*/)
                ],
                "storageKey": "fieldByIdOrAlias(idOrAlias:\"votes\")"
              },
              (v4/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "3f544ceec779657803bd6edddfff556f195b12f9cbeabe8b1a2819093f480e23",
    "metadata": {},
    "name": "main_votersListService_issueFieldVoters_IssueVotersRelayQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "bd3e8bfc4ab4c2108bb8f61d18ff36ce";

export default node;
