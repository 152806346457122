import { useCallback } from 'react';
import { commitLocalUpdate, useRelayEnvironment } from 'react-relay';
import { ConnectionHandler } from 'relay-runtime';
import { JiraIssuefieldvalueAri } from '@atlassian/ari/jira';
import { useEditField } from '@atlassian/jira-issue-field-base/src/services/edit-field-service/main.tsx';
import { GOALS_CF_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import RelayDataID from '@atlassian/relay-data-id';
import type { Props, GoalsValue, UseGoalsField } from './types.tsx';

export const useGoalsField: UseGoalsField = ({
	fieldKey,
	issueId,
	issueKey,
	onSuccess,
	onFailure,
	initialValue,
}: Props) => {
	const environment = useRelayEnvironment();
	const cloudId = useCloudId();

	const getDataId = useCallback(
		// issueId can be undefined so passing this in after the saveField check to avoid TS error and ensure we actually have a valid value
		(typeName: string, issue: string) =>
			RelayDataID(
				{
					id: JiraIssuefieldvalueAri.create({
						siteId: cloudId,
						issueId: issue.toString(),
						fieldId: fieldKey,
					}).toString(),
				},
				typeName,
			),
		[cloudId, fieldKey],
	);

	const saveField = useCallback(
		(_iKey: string, _fKey: string, valuesForOperation: GoalsValue) => {
			commitLocalUpdate(environment, (store) => {
				if (!issueId) {
					return;
				}
				const dataID = getDataId('JiraGoalsField', issueId);

				if (!dataID) {
					return;
				}

				const goalsField = store.get(dataID);

				if (!goalsField) {
					return;
				}

				const connection = ConnectionHandler.getConnection(
					goalsField,
					'issue_view_goals_field_selectedGoals',
				);

				if (connection == null) {
					return;
				}

				let edges = connection.getLinkedRecords('edges');

				if (!edges || edges.length === 0) {
					const newGoalDataId = getDataId('JiraGoalsField:selectedGoals(first:1):edges:0', issueId);
					if (!newGoalDataId) {
						return;
					}

					const newEdge = ConnectionHandler.createEdge(
						store,
						connection,
						store.create(newGoalDataId, 'JiraGoal'),
						'JiraGoalEdge',
					);

					ConnectionHandler.insertEdgeBefore(connection, newEdge);

					edges = connection.getLinkedRecords('edges');
				}

				if (!edges) {
					return;
				}

				// We should only ever have one edge
				const node = edges[0].getLinkedRecord('node');

				if (valuesForOperation.totalCount === 0) {
					const nodeId = node?.getDataID();
					const edgeId = edges[0].getDataID();

					if (!nodeId || !edgeId) {
						return;
					}

					// delete both the node and the edge to avoid invariant violations
					store.delete(nodeId);
					store.delete(edgeId);

					// Clear the connection to avoid invariant violations
					connection.setLinkedRecords([], 'edges');
				} else {
					node?.setValue(valuesForOperation.goals[0].id, 'id');
					node?.setValue(valuesForOperation.goals[0].name, 'name');
					node?.setValue(valuesForOperation.goals[0].status, 'status');
				}

				// We _should_ alway require the count to update
				connection?.setValue(valuesForOperation.totalCount, 'totalCount');
			});
			return Promise.resolve(undefined);
		},
		[environment, getDataId, issueId],
	);

	const [{ error, value }, { resetError, saveValue }] = useEditField<GoalsValue, null>({
		fieldType: GOALS_CF_TYPE,
		issueId,
		issueKey,
		onSuccess,
		onFailure,
		initialValue,
		fieldKey,
		saveField,
		shouldPreventIssueViewSoftRefresh: true,
	});

	return [
		{ error, value },
		{ resetError, saveValue },
	];
};
