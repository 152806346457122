import type { Action } from '@atlassian/react-sweet-state';
import type { Actions, State } from './types.tsx';

export const actions: Actions = {
	setCsatComment:
		(csatComment: string): Action<State> =>
		({ setState }) => {
			setState({
				csatComment,
			});
		},
};
