import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	editResolutionFailedTitle: {
		id: 'issue-field-resolution.edit-resolution-failed-title',
		defaultMessage: "We couldn't update {issueKey}'s resolution",
		description: 'Title informing the user that updating the issue resolution failed',
	},
	editResolutionFailedDescription: {
		id: 'issue-field-resolution.edit-resolution-failed-description',
		defaultMessage: 'Check your connection, then try again.',
		description: 'Description informing the user that updating the issue resolution  failed',
	},
	editResolutionFailedTitleIssueTermRefresh: {
		id: 'issue-field-resolution.edit-resolution-failed-title-issue-term-refresh',
		defaultMessage: "We couldn't update {issueKey}'s resolution",
		description: 'Title informing the user that updating the issue resolution failed',
	},
});
