import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';

export class AccessNotPermittedError extends Error {
	skipSentry: boolean;

	constructor(message?: string, cause?: Error) {
		super(message);
		this.name = 'AccessNotPermittedError';
		this.cause = cause;
		this.skipSentry = true;
	}
}

const isObject = (value: unknown): value is Record<string, unknown> =>
	typeof value === 'object' && value !== null;

const isForbiddenFetchError = (error: unknown): boolean =>
	error instanceof FetchError && error.statusCode === 403;

const isGraphqlForbiddenError = (error: unknown): boolean =>
	isObject(error) &&
	Array.isArray(error.graphQLErrors) &&
	error.graphQLErrors.length === 1 &&
	error.graphQLErrors[0].extensions?.statusCode === 403;

export const isAccessNotPermittedError = (error: unknown): boolean => {
	if (isForbiddenFetchError(error) || isGraphqlForbiddenError(error)) {
		return true;
	}

	return false;
};
