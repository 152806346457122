import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { fg } from '@atlassian/jira-feature-gating';
import { ValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { getErrorType } from '@atlassian/jira-forge-ui-analytics/src/common/utils/get-error-type/index.tsx';
import { useIssueAdjustmentsActions } from '@atlassian/jira-issue-adjustments/src/controllers.tsx';
import type { Actions } from '@atlassian/jira-issue-field-base/src/services/edit-field-service/types.tsx';
import type {
	FieldConfiguration,
	FieldConfigurationType,
} from '@atlassian/jira-issue-field-base/src/services/field-config-service/types.tsx';
import type { IssueKey, IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { fireIAErrorAnalytics } from '@atlassian/jira-ui-modifications-analytics/src/common/utils/error-analytics/index.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-ui-modifications-analytics/src/common/utils/track-analytics/index.tsx';
import { ISSUE_VIEW_VIEW_TYPE } from '../../../common/constants.tsx';
import type { Field } from '../../../common/types.tsx';
import type { UseFieldHook, SaveValueAction } from './field-processor/types.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AllFieldConfigs = FieldConfigurationType<any> | (FieldConfiguration<any> | null);

export type Hook<ValueType, Meta, Result, IdShape> = (props: {
	issueKey: IssueKey;
	issueId: IssueId;
	fieldKey: string;
	onFailure: (error: Error) => void;
	onSuccess: (value: ValueType) => void;
}) => Readonly<
	[
		{
			value: ValueType;
			fieldConfig: AllFieldConfigs;
		},
		{
			// Some hooks do not provide saveValue, like useStatusField
			saveValue?: SaveValueAction<ValueType, Meta, Result, IdShape>;
			// Some hooks do not provide saveById, like useSummary
			saveById?: Actions<ValueType, Meta, Result, IdShape>['saveById'];
		},
	]
>;

const isFieldConfigurationType = (
	fieldConfig: AllFieldConfigs, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): fieldConfig is FieldConfigurationType<any> =>
	!!fieldConfig && Object.hasOwnProperty.call(fieldConfig, 'loading');

type HookAdapterArgs<ValueType, Meta, Result, IdShape> = {
	hook: Hook<ValueType, Meta, Result, IdShape>;
	saveFnName: 'saveValue' | 'saveById';
};

const VALIDATION_ERROR = 'VALIDATION_ERROR';

/**
 * Adapts Issue View per field hooks to return the same, consistent type @see return of UseFieldHook
 */
export const adaptIssueViewPerFieldHook =
	<ValueType, Meta, Result, IdShape>({
		hook,
		saveFnName = 'saveValue',
	}: HookAdapterArgs<ValueType, Meta, Result, IdShape>): UseFieldHook<
		ValueType,
		Meta,
		Result,
		IdShape
	> =>
	({ issueKey, fieldId, fieldType, issueId }) => {
		const { setError } = useIssueAdjustmentsActions();
		const { createAnalyticsEvent } = useAnalyticsEvents();
		const onFailure = (error: Error) => {
			const errorType = error instanceof ValidationError ? VALIDATION_ERROR : getErrorType(error);

			setError({
				errorCode: 'PER_FIELD_HOOK_ERROR_CODE',
				fieldName: field?.fieldName,
			});
			fireIAErrorAnalytics({
				error,
				id: 'adaptIssueViewPerFieldHookSaveValue',
				viewType: ISSUE_VIEW_VIEW_TYPE,
				attributes: {
					fieldId,
					fieldType,
					errorType,
				},
				event: createAnalyticsEvent({}),
			});
		};

		const onSuccess = () => {
			if (fg('analytics-replace-jira-packages')) {
				return;
			}

			// Archive track event on 'analytics-replace-jira-packages' FG cleanup
			fireTrackAnalytics(
				createAnalyticsEvent({}),
				'adaptIssueViewPerFieldHookSaveValue success',
				ISSUE_VIEW_VIEW_TYPE,
				{
					fieldId,
					fieldType,
					saveFnName,
				},
			);
		};

		const [{ value, fieldConfig }, actions] = hook({
			issueKey,
			issueId,
			fieldKey: fieldId,
			onFailure,
			onSuccess,
		});

		let loading;
		let config;

		if (isFieldConfigurationType(fieldConfig)) {
			loading = !fieldConfig.value;
			config = fieldConfig.value;
		} else {
			loading = !fieldConfig;
			config = fieldConfig;
		}

		const field: Field | null = config
			? {
					fieldId,
					fieldType,
					fieldName: config.title,
					description: config.description,
					isReadOnly: !config.isEditable,
					isRequired: config.isRequired,
					value,
					isVisible: true,
					metadata: {
						// Keep in mind that renderer string is different in IssueView than in GIC for the same fieldType
						// e.g. for description `atlassian-wiki-renderer` (IV) vs `JiraRichTextField` (GIC)
						renderer: config.schema.renderer ?? undefined,
					},
				}
			: null;

		const saveValue = actions[saveFnName];

		if (!saveValue) {
			throw new Error(
				`Undefined save function was used for "${fieldType}" hook in adaptIssueViewPerFieldHook: ${saveFnName}`,
			);
		}

		return { field, actions: { saveValue }, loading } as const;
	};
