import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	internalNoteBanner: {
		id: 'issue.views.issue-base.activity.comment.comment-editor-view.servicedesk-comment-editor-view.customer-service.custom-banner.internal-note-banner--non-final',
		defaultMessage: 'Only team members will see your note.',
		description: 'A banner to indicate that a comment is internal.',
	},
	replyToCustomerBanner: {
		id: 'issue.views.issue-base.activity.comment.comment-editor-view.servicedesk-comment-editor-view.customer-service.custom-banner.reply-to-customer-banner--non-final',
		defaultMessage: 'The customer will see your reply.',
		description: 'A banner to indicate that a comment is visible to customers.',
	},
});
