import { type RefObject, useEffect, useState } from 'react';
import {
	attachClosestEdge,
	extractClosestEdge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import type { Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/types';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import {
	draggable,
	dropTargetForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { pointerOutsideOfPreview } from '@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview';
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview';
import { token } from '@atlaskit/tokens';

type SortableItemState =
	| { type: 'idle' }
	| { type: 'preview'; container: HTMLElement }
	| { type: 'dragging' }
	| { type: 'dragging-over'; edge: Edge; shouldRenderDropIndicator: boolean };

const idleState: SortableItemState = { type: 'idle' };
const draggingState: SortableItemState = { type: 'dragging' };

/**
 * We want to hide the drop indicator if it would display next to the dragging
 * item, because that would be a no-op drop.
 */
function getShouldRenderDropIndicator({
	edge,
	sourceIndex,
	index,
}: {
	edge: Edge;
	sourceIndex: number;
	index: number;
}): boolean {
	/**
	 * Hide drop indicator when dragging over the source item.
	 */
	if (sourceIndex === index) {
		return false;
	}

	/**
	 * Hide drop indicator when over the bottom of the item before.
	 */
	if (edge === 'bottom' && index === sourceIndex - 1) {
		return false;
	}

	/**
	 * Hide drop indicator when over the top of the item after.
	 */
	if (edge === 'top' && index === sourceIndex + 1) {
		return false;
	}

	return true;
}

function isShallowEqual(a: Record<string, unknown>, b: Record<string, unknown>) {
	if (Object.keys(a).length !== Object.keys(b).length) {
		return false;
	}

	return Object.entries(a).every(([key, value]) => value === b[key]);
}

const sortableItemSymbol = Symbol('sortable item');

type SortableItemData = {
	draggableId: string;
	index: number;
	symbol: typeof sortableItemSymbol;
	instanceId: string;
};

export function isSortableItemData(data: Record<string, unknown>): data is SortableItemData {
	return data.symbol === sortableItemSymbol;
}

export function useSortableItem<ElementType extends HTMLElement>({
	draggableId,
	index,
	ref,
	isReorderEnabled,
	onDragEnd,
	instanceId,
}: {
	draggableId: string;
	index: number;
	ref: RefObject<ElementType>;
	isReorderEnabled: boolean;
	onDragEnd?: () => void;
	instanceId: string;
}) {
	const [dragState, setDragState] = useState<SortableItemState>(idleState);

	useEffect(() => {
		if (!isReorderEnabled) {
			return;
		}

		const element = ref.current;
		if (!element) {
			return;
		}

		const data = { draggableId, index, symbol: sortableItemSymbol, instanceId };

		return combine(
			draggable({
				element,
				getInitialData() {
					return data;
				},
				onGenerateDragPreview({ nativeSetDragImage }) {
					setCustomNativeDragPreview({
						nativeSetDragImage,
						getOffset: pointerOutsideOfPreview({
							x: token('space.200', '16px'),
							y: token('space.100', '8px'),
						}),
						render({ container }) {
							setDragState({ type: 'preview', container });
						},
					});
				},
				onDragStart() {
					setDragState(draggingState);
				},
				onDrop() {
					setDragState(idleState);
					onDragEnd?.();
				},
			}),
			dropTargetForElements({
				element,
				getIsSticky() {
					return true;
				},
				canDrop({ source }) {
					return (
						isSortableItemData(source.data) &&
						source.data.instanceId === data.instanceId &&
						source.element !== element
					);
				},
				getData({ input }) {
					return attachClosestEdge(data, {
						element,
						input,
						allowedEdges: ['top', 'bottom'],
					});
				},
				onDrag({ self, source }) {
					const edge = extractClosestEdge(self.data);

					if (edge === null) {
						// This branch should never actually execute
						setDragState(idleState);
						return;
					}

					setDragState((current) => {
						if (!isSortableItemData(source.data)) {
							return current;
						}

						const proposed: SortableItemState = {
							type: 'dragging-over',
							edge,
							shouldRenderDropIndicator: getShouldRenderDropIndicator({
								edge,
								sourceIndex: source.data.index,
								index,
							}),
						};

						/**
						 * The current state data structure only needs a shallow
						 * comparison to check equality.
						 *
						 * This also lets us use a more lightweight
						 * comparison than `lodash.isequal`
						 */
						if (isShallowEqual(current, proposed)) {
							return current;
						}

						return proposed;
					});
				},
				onDragLeave() {
					setDragState(idleState);
				},
				onDrop() {
					setDragState(idleState);
				},
			}),
		);
	}, [draggableId, index, isReorderEnabled, onDragEnd, ref, instanceId]);

	return { dragState };
}
