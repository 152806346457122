import gqlTagGira from 'graphql-tag';

export const GET_REQUEST_TYPES = gqlTagGira`
  query GetRequestTypesForChangeCalendarEventCreateDialog($project: JiraProjectInput) {
    jiraIssueCreateFields(fieldsFilter: ADMIN_CONFIGURED_FIELDS, project: $project) {
      ... on JiraIssueCreateFields {
        fields {
          nodes {
            ... on JsmRequestTypeField {
              requestTypes {
                nodes {
                  requestTypeId
                  practices {
                    key
                  }
                  issueType {
                    issueTypeId
                  }
                  name
                  key
                }
              }
            }
          }
        }
      }
    }
  }
`;
