import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	contextTitle: {
		id: 'servicedesk-ai-context.context-title',
		defaultMessage: 'Suggestions',
		description: 'Heading for the context panel',
	},
	summary: {
		id: 'servicedesk-ai-context.summary',
		defaultMessage: 'Summary',
		description: 'Heading for the summary section',
	},
	reporterDetails: {
		id: 'servicedesk-ai-context.reporter-details',
		defaultMessage: 'Reporter details',
		description: 'Heading for the reporter details section',
	},
	nextSteps: {
		id: 'servicedesk-ai-context.next-steps',
		defaultMessage: 'To solve this issue:',
		description: 'Heading for the next steps section',
	},
	suggestedActions: {
		id: 'servicedesk-ai-context.suggested-actions',
		defaultMessage: 'Update issue',
		description: 'Heading for the suggested actions section',
	},
	noSuggestedActions: {
		id: 'servicedesk-ai-context.no-suggested-actions',
		defaultMessage: 'No actions available at the moment',
		description: 'Placeholder text when there are no suggested actions',
	},
	attribution: {
		id: 'servicedesk-ai-context.attribution',
		defaultMessage: 'Powered by AI',
		description: 'Attribution text in the footer',
	},
});
