import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import withFireUiAnalytics from '@atlassian/jira-analytics-web-react/src/components/with-fire-ui-analytics.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import {
	closeDeleteIssueModal,
	deleteIssueRequest,
} from '@atlassian/jira-issue-view-store/src/actions/issue-delete-actions.tsx';
import { issueKeySelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import {
	didIssueDeleteFailSelector,
	idSelector,
	isDeleteIssueModalOpenSelector,
	isDeletingIssueSelector,
	isSimplifiedProjectSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';
import { hasChildrenIssuesSelector } from '@atlassian/jira-issue-view-store/src/issue-field/state/selectors/children-issue-selector.tsx';
import { isIssueBaseLevel } from '@atlassian/jira-issue-view-store/src/issue-field/state/selectors/hierarchy-level-selector.tsx';
import { hasSubtasksSelector } from '@atlassian/jira-issue-view-store/src/selectors/subtasks-selector.tsx';
import type { Application } from '@atlassian/jira-shared-types/src/application.tsx';
import type { ApplicationEdition } from '@atlassian/jira-shared-types/src/edition.tsx';
import DeleteIssueView from './delete-issue-view.tsx';

export default flowWithSafeComponent(
	connect(
		(state: State) => {
			const shouldShowChildSpecificCopy: boolean = isSimplifiedProjectSelector(state)
				? isIssueBaseLevel(state) && hasChildrenIssuesSelector(state)
				: hasSubtasksSelector(state);
			return {
				issueId: idSelector(state),
				issueKey: issueKeySelector(state),
				isDeletingIssue: isDeletingIssueSelector(state),
				isDeleteIssueModalOpen: isDeleteIssueModalOpenSelector(state),
				didIssueDeleteFail: didIssueDeleteFailSelector(state),
				isSimplifiedProject: isSimplifiedProjectSelector(state),
				shouldShowChildSpecificCopy,
			};
		},
		(dispatch) => ({
			triggerCloseDeleteIssueModal: () => {
				dispatch(closeDeleteIssueModal());
			},
			triggerDeleteRequest: (
				issueKey: string,
				analyticsEvent: UIAnalyticsEvent,
				application: Application | undefined,
				edition: ApplicationEdition | undefined,
			) => {
				dispatch(deleteIssueRequest(issueKey, analyticsEvent, application, edition));
			},
		}),
	),
	withFireUiAnalytics({
		deleteCancelAnalytics: 'deleteIssueCancel',
		deleteConfirmAnalytics: 'deleteIssueDelete',
	}),
)(
	// @ts-expect-error - Argument of type 'FC<DeleteIssueViewProps>' is not assignable to parameter of type 'ComponentType<Matching<{ issueId: string | null; issueKey: string; isDeletingIssue: boolean; isDeleteIssueModalOpen: boolean; didIssueDeleteFail: boolean; isSimplifiedProject: boolean; shouldShowChildSpecificCopy: boolean; } & { ...; }, DeleteIssueViewProps>>'.
	DeleteIssueView,
);
