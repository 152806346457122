import ExtensibleError from '@atlassian/jira-extensible-error/src/index.tsx';

export class IssueErrorConstructor extends ExtensibleError {
	name = 'IssueError';

	errors: { error: string }[];

	statusCode: number | null;

	constructor(message: string, errors: { error: string }[] = [], statusCode: number | null = null) {
		super(message, { cause: errors[0] });
		this.errors = errors;
		this.statusCode = statusCode;
	}
}
