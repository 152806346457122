import { format, utcToZonedTime } from 'date-fns-tz';
import isValid from 'date-fns/isValid';
import type { JiraDateTimePickerValue, ParseValueFn } from './types.tsx';

export const parseDateTimeValue: ParseValueFn = (
	dateTimeValue: JiraDateTimePickerValue,
	fallbackDate: string,
	fallbackTime: string,
	timeZone: string,
) => {
	const parsed =
		dateTimeValue !== null && isValid(new Date(dateTimeValue))
			? utcToZonedTime(new Date(dateTimeValue), timeZone)
			: null;

	return parsed !== null && isValid(parsed)
		? {
				dateValue: format(parsed, 'yyyy-MM-dd', { timeZone }),
				timeValue: format(parsed, 'HH:mm', { timeZone }),
				zoneValue: format(parsed, 'XX', { timeZone }),
			}
		: {
				dateValue: fallbackDate,
				timeValue: fallbackTime,
				zoneValue: format(new Date(), 'XX', { timeZone }),
			};
};
