import React, { useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { useDesignPanelStore } from '@atlassian/jira-issue-design-section-store/src/index.tsx';
import { VIEW_DESIGN_SECTION_EXPERIENCE } from '@atlassian/jira-issue-designs-observability/src/services/constants.tsx';
import {
	IssueDesignsExperienceStart,
	useIssueDesignsExperienceFail,
} from '@atlassian/jira-issue-designs-observability/src/services/index.tsx';
import type { ui_issueDesignSection_DesignPanelWrapper$key } from '@atlassian/jira-relay/src/__generated__/ui_issueDesignSection_DesignPanelWrapper.graphql.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import DesignPanel from './panel/index.tsx';

export type DesignPanelProps = {
	isExpanded: boolean;
	rootRelayFragment: ui_issueDesignSection_DesignPanelWrapper$key;
	issueAri: string;
};

const DesignPanelWrapper = ({ isExpanded, rootRelayFragment, issueAri }: DesignPanelProps) => {
	const devOpsData = useFragment<ui_issueDesignSection_DesignPanelWrapper$key>(
		graphql`
			fragment ui_issueDesignSection_DesignPanelWrapper on Query {
				...panel_issueDesignSection_DesignPanel
				devOps @optIn(to: "DevOpsSecurityInJira") {
					providers(id: $siteAri, providerTypes: [DESIGN]) {
						designProviders {
							supportedActions {
								getEntityByUrl
								checkAuth
							}
						}
					}
				}
			}
		`,
		rootRelayFragment,
	);

	const viewDesignSectionExperienceFail = useIssueDesignsExperienceFail({
		experience: VIEW_DESIGN_SECTION_EXPERIENCE,
	});

	const [{ shouldShowAddDesignForm }] = useDesignPanelStore();

	const shouldRenderDesignsPanel = useMemo(() => {
		const designProviders = devOpsData.devOps?.providers?.designProviders ?? [];

		const associateEntitySupportedProviders = designProviders.filter((provider) =>
			provider != null && provider.supportedActions != null
				? provider.supportedActions.getEntityByUrl && provider.supportedActions.checkAuth
				: false,
		);
		return associateEntitySupportedProviders.length > 0;
	}, [devOpsData.devOps?.providers?.designProviders]);

	const shouldShowDesignPanel = shouldShowAddDesignForm || shouldRenderDesignsPanel;

	return shouldShowDesignPanel ? (
		<UFOSegment name="issue-designs-panel">
			<IssueDesignsExperienceStart
				experience={VIEW_DESIGN_SECTION_EXPERIENCE}
				experienceId={VIEW_DESIGN_SECTION_EXPERIENCE}
			/>
			<JSErrorBoundary
				id="designPanelWrapper"
				packageName="jiraIssueDesignSection"
				teamName="helios-headcoach"
				onError={(location, error, errorAttributes) => {
					viewDesignSectionExperienceFail(location, error, {
						errorMessage: error.message,
						errorHash: errorAttributes.hash,
						teamName: errorAttributes.teamName,
					});
				}}
				fallback="unmount"
			>
				<DesignPanel rootRelayFragment={devOpsData} isExpanded={isExpanded} issueAri={issueAri} />
			</JSErrorBoundary>
		</UFOSegment>
	) : null;
};

export default DesignPanelWrapper;
