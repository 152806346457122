import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

export const TODO_SEGMENT_COLOR_HIGHER_CONTRAST_OLD = token('color.border', colors.N30);

export const TODO_SEGMENT_COLOR_HIGHER_CONTRAST_NEW = token(
	'color.chart.gray.boldest',
	colors.DN70,
);

export const IN_PROGRESS_SEGMENT_COLOR_HIGHER_CONTRAST = token(
	'color.border.information',
	colors.B200,
);
export const DONE_SEGMENT_COLOR = token('color.border.success', colors.G300);
