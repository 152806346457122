import React, { type ReactNode, useEffect, useState } from 'react';
import { styled } from '@compiled/react';
import { useResizingHeight } from '@atlaskit/motion';
import { token } from '@atlaskit/tokens';

const SlideDownOnMount = ({ children }: { children: ReactNode }) => {
	const [show, setShow] = useState(false);

	useEffect(() => {
		setShow(true);
	}, [setShow]);
	const { ref } = useResizingHeight();
	return <HiddenOverflowContainer ref={ref}>{show && children}</HiddenOverflowContainer>;
};
export default SlideDownOnMount;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HiddenOverflowContainer = styled.div({
	overflow: 'hidden',
	/* SummaryItems have a negative margin so we need to not cut off things outside. */
	margin: token('space.negative.200', '-16px'),
	padding: token('space.200', '16px'),
});
