import type { ComponentType } from 'react';
import { compose } from 'redux';
import memoizeOne from 'memoize-one';
import MultiSelectInlineEditView from '@atlassian/jira-issue-internal-field-select/src/multi-select-inline-edit/index.tsx';
import { genericMessages } from '@atlassian/jira-issue-view-common-constants/src/context-items-messages.tsx';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button/index.tsx';
import connectField from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-field.tsx';
import { withExtraOwnProps } from '@atlassian/jira-issue-view-common-views/src/with-extra-own-props/index.tsx';
import {
	fieldAllowedValuesSelector,
	fieldNameSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector.tsx';
import { filterAllowedValues } from '../../common/utils/filter-select-allowed-values.tsx';
import messages from './messages.tsx';
import {
	transformFromStateValue,
	transformToStateValue,
	transformSuggestions,
	type StateValue,
} from './transformer.tsx';

const fetchSuggestionsFactoryV2 = memoizeOne(
	(suggestions, allowedValuesOverrides) => () =>
		Promise.resolve(filterAllowedValues<StateValue>(suggestions, allowedValuesOverrides)).then(
			transformSuggestions,
		),
);

const MultiSelect = compose(
	withExtraOwnProps,
	connectField((stateOnMount, ownPropsOnMount) => {
		const fieldAllowedValuesSelectorGetter = fieldAllowedValuesSelector(ownPropsOnMount.fieldId);

		return {
			fieldId: ownPropsOnMount.fieldId,
			transformFromStateValue,
			transformToStateValue,
			additionalProps: (state, intl, fieldOptions, extraOwnProps) => ({
				fetchSuggestions: fetchSuggestionsFactoryV2(
					fieldAllowedValuesSelectorGetter(state),
					extraOwnProps?.allowedValuesOverrides,
				),
				placeholder: intl.formatMessage(messages.placeholder, {
					fieldName: fieldNameSelector(ownPropsOnMount.fieldId)(state),
				}),
				noValueText: intl.formatMessage(genericMessages.noValue),
				showPinButton: getShowPinButton(ownPropsOnMount.area),
			}),
		};
	}),
)(MultiSelectInlineEditView);

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
export default MultiSelect as ComponentType<any>;
