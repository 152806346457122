import React from 'react';
import type { CommentVisibility } from '@atlassian/jira-issue-gira-transformer-types/src/common/types/comments.tsx';
import { safeComponent } from '@atlassian/jira-issue-view-common-utils/src/safe-component/index.tsx';
import CommentVisibilitiesSubscriber from './state/components/subscriber.tsx';
import CommentVisibilityView from './view/index.tsx';

type Props = {
	initialValue: CommentVisibility;
	onChange: (arg1: CommentVisibility) => void;
};

const CommentVisibilitySelect = ({ initialValue, onChange }: Props) => (
	<CommentVisibilitiesSubscriber>
		{({ loading, error, data }, { fetch }) => (
			<CommentVisibilityView
				initialValue={initialValue}
				onChange={onChange}
				isLoading={loading}
				error={error}
				options={data ? data.commentVisibilities : null}
				getCommentVisibilityData={fetch}
			/>
		)}
	</CommentVisibilitiesSubscriber>
);

export default safeComponent()(CommentVisibilitySelect);
