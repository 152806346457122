/**
 * Finds a single entity from a provided list of entities
 *
 * @param value - the value search being made by
 * @param lookupValues - list of entities where search is being made
 * @param lookupKey - property name of the entity from the lookupValues list with which value will be compared
 */
export const singleValueLookup = <T extends {}>(
	value: string,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	lookupValues: any[] = [],
	lookupKey: string,
): T | undefined => lookupValues.find((option) => option[lookupKey] === value);
