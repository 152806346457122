import React, { type ReactNode, type ChangeEvent } from 'react';
import { styled } from '@compiled/react';
import SingleLineTextField from '@atlassian/jira-issue-internal-field-single-line-text/src/single-line-text-field-view.tsx';

export type Props = {
	isInvalidInput: boolean;
	defaultValue: string | undefined;
	placeholderMessage: string;
	invalidTimeFormatMessage: undefined | ReactNode;
	onChange: (arg1: ChangeEvent<HTMLInputElement>) => void;
};

export const RemainingEstimateEdit = (props: Props) => {
	const { defaultValue, placeholderMessage, onChange, isInvalidInput, invalidTimeFormatMessage } =
		props;
	return (
		<Container>
			<SingleLineTextField
				compact
				type="text"
				value={defaultValue}
				placeholder={placeholderMessage}
				onChange={onChange}
				invalidMessage={isInvalidInput ? invalidTimeFormatMessage : undefined}
			/>
		</Container>
	);
};

export default RemainingEstimateEdit;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	cursor: 'auto',
	width: '100%',
});
