import React, { type ComponentType, useCallback } from 'react';
import type { Dispatch } from 'redux';
import memoizeOne from 'memoize-one';
import uuid from 'uuid';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import type { ProjectType } from '@atlassian/jira-common-constants/src/index.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { defineMessages } from '@atlassian/jira-intl';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import { useFieldOverridesStoreWithLabel } from '@atlassian/jira-issue-field-base/src/services/field-config-service/context.tsx';
import type { Action } from '@atlassian/jira-issue-view-actions/src/index.tsx';
import type {
	MapStateToConfig,
	PartialConnectFieldConfig,
	ConnectFieldConfig,
	FieldOptions,
	ReduxConnectParams,
	ObjectWithIntl,
	OwnProps,
	SaveFieldArguments,
} from '@atlassian/jira-issue-view-common-types/src/connect-field-type.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { PREVIEW } from '@atlassian/jira-issue-view-common-types/src/loading-stage-type.tsx';
import type { Fallback } from '@atlassian/jira-issue-view-common-utils/src/safe-component/index.tsx';
import { WithViewExperienceTrackerWrapper } from '@atlassian/jira-issue-view-experience-tracking/src/view-experience/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import {
	baseUrlSelector,
	issueKeySelector,
	isMobileSelector,
	projectKeySelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import {
	fieldSchemaConfigurationSelector,
	fieldEditingValueSelector,
	fieldInvalidMessageSelector,
	isFieldEditableSelector,
	isFieldEditingSelector,
	isFieldWaitingSelector,
	fieldNameSelector,
	isRichTextFieldSelector,
	fieldEditSessionIdSelector,
	fieldTypeSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector.tsx';
import {
	loadingStageSelector,
	projectTypeSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';
import { deleteDraftRequest } from '@atlassian/jira-issue-view-store/src/drafts/draft-actions.tsx';
import {
	fieldInitOptions,
	fieldEditBegin,
	fieldEditUpdate,
	fieldEditConfirm,
	fieldEditCancel,
	fieldPaste,
} from '@atlassian/jira-issue-view-store/src/issue-field/state/actions/field-actions.tsx';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';

export const messages = defineMessages({
	defaultFallbackInvalidMessage: {
		id: 'issue-view-common-views.connect-field.connect-field.default-fallback-invalid-message',
		defaultMessage: 'The value could not be saved',
		description: '',
	},
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const defaultConnectFieldConfig: ConnectFieldConfig<any> = {
	// Transform from our state (jira's) representation of the value, to the component's value prop.
	// Also provides i18n if any translated strings are required as part of this transformation.
	transformFromStateValue: (stateValue, _transformerContext, _intl) => stateValue,

	// Transform to our state (jira's) representation of the value, from the component's value prop.
	transformToStateValue: (componentValue) => componentValue,

	// The message to show when a validation error cannot be extracted from a save failure.
	fallbackInvalidMessage: (intl) => intl.formatMessage(messages.defaultFallbackInvalidMessage),

	// Map state and intl to any additional props required by the component.
	additionalProps: (_state, _intl, _fieldOptions) => ({}),

	// Map dispatch to any additional callback props required by the component.
	additionalCallbacks: (_dispatch) => ({}),

	// Whether the field's value/renderedValue will update optimistically after a save,
	// or wait for a refresh of the issue data.
	isOptimistic: true,

	// Should be set to true if editing the field can result in new Media Services
	// files being attached to the issue. Causes a new media view-token to be fetched,
	// which will include the necessary permissions for the new files to be displayed.
	canContainMediaContent: false,

	// Should be set to true if the particular field should have a draft of itself saved
	// in local storage. This is likely not apply to many fields which have the default
	// behaviour of actually saving when being clicked out of anyway - as this property
	// is targeted at fields like description that would otherwise lose changes if the field
	// loses focus or Bento is closed, the tab is closed, the browser crashes/etc.
	shouldSaveDraft: false,

	// How to save the field's value. By default, save by PUT-ing the new value
	// to the issue endpoint.
	saveField: ({
		baseUrl,
		issueKey,
		fieldMetaKey,
		value,
		fieldEditSessionId, // eslint-disable-next-line @typescript-eslint/no-explicit-any
	}: SaveFieldArguments<any>) => {
		const url = fieldEditSessionId
			? `${baseUrl}/rest/api/2/issue/${issueKey}?fieldEditSessionId=${fieldEditSessionId}`
			: `${baseUrl}/rest/api/2/issue/${issueKey}`;
		return performPutRequest(url, {
			body: JSON.stringify({
				fields: {
					[fieldMetaKey]: value,
				},
			}),
		});
	},

	// Controls whether a save failure will set the saved value back to the editingValue.
	// Usually this is desirable for an inline-editable component, but for a component
	// like a toggle button (watches), we just want to discard the value that failed to
	// save.
	shouldDiscardChangeOnFailure: false,

	// Set which flag message will display if the field fails to save with an error.
	// The flag message types are found in 'flag-constants'
	onSaveFailureFlagType: null,

	// Set which flag message will display if the field is saved successfully.
	// The flag message types are found in 'flag-constants'
	onSaveSuccessFlagType: null,

	// Needed by flow so we can have the defaults match the ConnectFieldConfig type.
	fieldId: '',
};

const createTransformerContext = (
	baseUrl: string,
	projectKey: string,
	projectType: ProjectType | null,
	issueKey: string,
	fieldId: string,
) => ({
	baseUrl,
	projectKey,
	...(projectType === null ? {} : { projectType }),
	issueKey,
	fieldId,
});

const callbacksFactory = memoizeOne((dispatchProps, stateProps) =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Object.keys(dispatchProps).reduce<Record<string, any>>((acc, key) => {
		acc[key] = dispatchProps[key](stateProps);
		return acc;
	}, {}),
);

export type ConnectFieldOptions = {
	shouldPassProps?: boolean;
};

const getReduxConnectParams = <T,>(
	mapStateToConfig: MapStateToConfig<T>,
	connectFieldOptions?: ConnectFieldOptions,
): ReduxConnectParams => ({
	mapStateToProps: (stateOnMount: State, ownPropsOnMount: OwnProps) => {
		// Get the partial config, by calling the provided function
		// with our state and passed in props
		const providedConnectFieldConfig: PartialConnectFieldConfig<T> = mapStateToConfig(
			stateOnMount,
			ownPropsOnMount,
		);

		const connectFieldConfig: ConnectFieldConfig<T> = {
			...defaultConnectFieldConfig,
			...providedConnectFieldConfig,
		};

		const {
			fieldId,
			transformFromStateValue,
			transformToStateValue,
			fallbackInvalidMessage,
			additionalProps,
			additionalCallbacks,
			isOptimistic,
			canContainMediaContent,
			saveField,
			shouldDiscardChangeOnFailure,
			shouldSaveDraft,
			onSaveFailureFlagType,
			onSaveSuccessFlagType,
		} = connectFieldConfig;

		// This data is passed into every action creator so that it can be used by reducers/epics to
		// 'configure' the editing and saving flow.
		const fieldOptions: FieldOptions<T> = {
			isRichTextField: isRichTextFieldSelector(fieldId)(stateOnMount),
			isOptimistic,
			canContainMediaContent,
			saveField,
			shouldDiscardChangeOnFailure,
			shouldSaveDraft,
			onSaveFailureFlagType,
			onSaveSuccessFlagType,
		};

		const memoizedTransformerContext = memoizeOne(createTransformerContext);
		const memoizedTransformToStateValue = memoizeOne(transformToStateValue);
		const memoizedTransformFromStateValue = memoizeOne(transformFromStateValue);

		const fieldSchemaConfigurationStateSelector = fieldSchemaConfigurationSelector(fieldId);

		return (state: State, ownProps: ObjectWithIntl) => ({
			// Needed for mergeStateAndDispatchProps.
			fieldId,
			fieldOptions,
			memoizedTransformToStateValue,
			additionalCallbacks,
			fieldType: fieldTypeSelector(fieldId)(state),
			// Passed through.
			intl: ownProps.intl,
			label: fieldNameSelector(fieldId)(state),
			issueKey: issueKeySelector(state),
			isEditable:
				isFieldEditableSelector(fieldId)(state) && loadingStageSelector(state) !== PREVIEW,
			isEditing: isFieldEditingSelector(fieldId)(state),
			fieldEditSessionId: fieldEditSessionIdSelector(fieldId)(state),
			invalidMessage: fieldInvalidMessageSelector(
				fieldId,
				fallbackInvalidMessage(ownProps.intl),
			)(state),
			customFieldConfig: fieldSchemaConfigurationStateSelector(state),
			value: memoizedTransformFromStateValue(
				fieldEditingValueSelector(fieldId)(state),
				memoizedTransformerContext(
					baseUrlSelector(state),
					projectKeySelector(state),
					projectTypeSelector(state),
					issueKeySelector(state),
					fieldId,
				),
				ownProps.intl,
			),
			isWaiting: isOptimistic ? false : isFieldWaitingSelector(fieldId)(state),
			isMobile: isMobileSelector(state),
			...additionalProps(state, ownProps.intl, fieldOptions, ownProps.extraOwnProps),
			...(connectFieldOptions?.shouldPassProps &&
			fg('relay-migration-issue-fields-multi-line-text-fg')
				? ownProps
				: {}),
		});
	},

	mapDispatchToProps: (dispatch: Dispatch<Action>) => ({
		initFieldOptions:
			// @ts-expect-error - TS7031 - Binding element 'fieldId' implicitly has an 'any' type. | TS7031 - Binding element 'fieldOptions' implicitly has an 'any' type.


				({ fieldId, fieldOptions }) =>
				() =>
					dispatch(fieldInitOptions(fieldId, fieldOptions)),
		onEditRequest:
			// @ts-expect-error - TS7031 - Binding element 'fieldId' implicitly has an 'any' type. | TS7031 - Binding element 'fieldOptions' implicitly has an 'any' type.


				({ fieldId, fieldOptions }) =>
				// @ts-expect-error - TS7019 - Rest parameter 'args' implicitly has an 'any[]' type.
				(...args) => {
					const analyticsEvent = args.length ? args[args.length - 1] : undefined;
					dispatch(fieldEditBegin(fieldId, fieldOptions, uuid.v4(), analyticsEvent));
				},
		onChange:
			// @ts-expect-error - TS7031 - Binding element 'fieldId' implicitly has an 'any' type. | TS7031 - Binding element 'fieldOptions' implicitly has an 'any' type. | TS7031 - Binding element 'memoizedTransformToStateValue' implicitly has an 'any' type.


				({ fieldId, fieldOptions, memoizedTransformToStateValue }) =>
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				(value: any) =>
					dispatch(fieldEditUpdate(fieldId, memoizedTransformToStateValue(value), fieldOptions)),
		onConfirm:
			// @ts-expect-error - TS7031 - Binding element 'fieldId' implicitly has an 'any' type. | TS7031 - Binding element 'fieldOptions' implicitly has an 'any' type. | TS7031 - Binding element 'analyticsAttributeIdRetriever' implicitly has an 'any' type.


				({ fieldId, fieldOptions, analyticsAttributeIdRetriever }) =>
				// @ts-expect-error - TS7019 - Rest parameter 'args' implicitly has an 'any[]' type.
				(...args) => {
					// withAnalyticsEvents adds the analyticsEvent as the last
					// argument to the handler. The saveFieldData is optional
					// and considered present if onConfirm is invoked with more
					// than one argument.
					const saveFieldData = args.length > 1 ? args[0] : undefined;
					const analyticsEvent = args.length ? args[args.length - 1] : undefined;

					// 'analyticsAttributeIdRetriever' is an optional function which allows each issue field to define what they
					// want to use as 'newValId' and 'oldValId' in 'issueField updated' event attribute. If this function is not
					// provided, 'id' property of the field value will be used, in the case there is no id. Event will be fired without
					// 'newValId' and 'oldValId'
					return dispatch(
						fieldEditConfirm(
							fieldId,
							fieldOptions,
							saveFieldData,
							analyticsEvent,
							analyticsAttributeIdRetriever,
						),
					);
				},
		onBlur:
			// @ts-expect-error - TS7031 - Binding element 'fieldId' implicitly has an 'any' type. | TS7031 - Binding element 'fieldOptions' implicitly has an 'any' type.


				({ fieldId, fieldOptions }) =>
				() =>
					dispatch(fieldEditCancel(fieldId, fieldOptions)),
		onCancel:
			// @ts-expect-error - TS7031 - Binding element 'fieldId' implicitly has an 'any' type. | TS7031 - Binding element 'fieldOptions' implicitly has an 'any' type.


				({ fieldId, fieldOptions }) =>
				() => {
					dispatch(fieldEditCancel(fieldId, fieldOptions));
					dispatch(deleteDraftRequest({ fieldId }));

					// BENTO-12481 new inline-edit version keeps the focus on the field if it was navigated to vis keyboard,
					// as a result - preventing the whole issue view modal from being closed by `esc` keypress.
					// This will make sure that pressing `esc` on the field with remove the focus on it
					// and will allow futher `esc` keypress to close issue view modal

					// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					const activeElement = window.document?.activeElement;
					if (activeElement instanceof HTMLElement) {
						activeElement.blur();
					}
				},
		onPaste:
			// @ts-expect-error - TS7031 - Binding element 'fieldId' implicitly has an 'any' type. | TS7031 - Binding element 'fieldOptions' implicitly has an 'any' type.


				({ fieldId, fieldOptions }) =>
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				(pastedContent: any) =>
					dispatch(fieldPaste(fieldId, fieldOptions, pastedContent)),
		// @ts-expect-error - TS7031 - Binding element 'additionalCallbacks' implicitly has an 'any' type.
		getAdditionalCallbacks: ({ additionalCallbacks }) => additionalCallbacks(dispatch),
	}),
	// @ts-expect-error - TS7006 - Parameter 'stateProps' implicitly has an 'any' type.
	mergeStateAndDispatchProps: (stateProps, dispatchProps) => {
		// Curry the stateProps into the functions returned by mapDispatchToProps
		// to create the wired-up action creators.

		const callbacks = callbacksFactory(dispatchProps, stateProps);

		// Wire up the additional callbacks provided by the consumer.
		const additionalCallbacks = dispatchProps.getAdditionalCallbacks(stateProps);

		// Create the final props that will be passed to the WrappedComponent
		return {
			...stateProps,
			...callbacks,
			...additionalCallbacks,
		};
	},
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getDisplayName = (Component: ComponentType<any>) =>
	Component.displayName || Component.name || 'Component';

export const getDisplayNamePrefix = (FallbackField?: Fallback) => {
	if (FallbackField) {
		return 'WithFallback';
	}
	return '';
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getFallbackComponent = (FallbackField?: Fallback) => (props: any) => {
	if (!FallbackField) {
		return null;
	}
	return (
		<ErrorBoundary prefixOverride="issue" id={`Fallback(${getDisplayName(FallbackField)})`}>
			<FallbackField {...props} />
		</ErrorBoundary>
	);
};

const getComponentWithAnalytics =
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(WrappedComponent: ComponentType<any>, mergedProps: any) => (props: typeof mergedProps) => {
		const { onConfirm, onEditRequest } = props;
		const { createAnalyticsEvent } = useAnalyticsEvents();
		const handleOnConfirm = useCallback(
			// @ts-expect-error - TS7019 - Rest parameter 'args' implicitly has an 'any[]' type.
			(...args) => {
				const analyticsEvent = createAnalyticsEvent({
					action: 'updated',
				});
				args.push(analyticsEvent);
				onConfirm(...args);
			},
			[onConfirm, createAnalyticsEvent],
		);
		const handleOnEditRequest = useCallback(
			// @ts-expect-error - TS7019 - Rest parameter 'args' implicitly has an 'any[]' type.
			(...args) => {
				const analyticsEvent = createAnalyticsEvent({
					action: 'updateStarted',
				});
				args.push(analyticsEvent);
				onEditRequest(...args);
			},
			[createAnalyticsEvent, onEditRequest],
		);

		const overrides = useFieldOverridesStoreWithLabel({
			issueKey: props.issueKey,
			fieldKey: props.fieldId,
		});

		return (
			<WithViewExperienceTrackerWrapper fieldType={props.fieldType}>
				<WrappedComponent
					{...props}
					{...overrides}
					onConfirm={handleOnConfirm}
					onEditRequest={handleOnEditRequest}
				/>
			</WithViewExperienceTrackerWrapper>
		);
	};

const connectField =
	(analyticsSubject: string) =>
	<T,>(
		mapStateToConfig: MapStateToConfig<T>,
		FallbackField?: Fallback,
		connectFieldOptions?: ConnectFieldOptions,
	) =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(WrappedComponent: ComponentType<any>) => {
		const reduxConnectParams = getReduxConnectParams(mapStateToConfig, connectFieldOptions);
		const analyticsData = {
			componentName: 'issueFields',
			actionSubject: analyticsSubject,
		};
		const componentName = getDisplayName(WrappedComponent);

		const ComponentWithAnalytics = getComponentWithAnalytics(
			WrappedComponent,
			reduxConnectParams.mergeStateAndDispatchProps,
		);
		// @ts-expect-error - TS2339 - Property 'displayName' does not exist on type '(props: any) => Element'.
		ComponentWithAnalytics.displayName = `${componentName}WithAnalytics`;

		const ComponentWithStore = connect(
			reduxConnectParams.mapStateToProps,
			reduxConnectParams.mapDispatchToProps,
			reduxConnectParams.mergeStateAndDispatchProps,
		)(ComponentWithAnalytics);

		ComponentWithStore.displayName = `${componentName}WithStore`;
		const FallbackComponent = getFallbackComponent(FallbackField);

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const ConnectedComponent = (props: any) => {
			const intl = useIntl();
			return (
				<ErrorBoundary
					prefixOverride="issue"
					id={`${getDisplayNamePrefix(FallbackField)}(${componentName})`}
					render={() => <FallbackComponent {...props} />}
				>
					{}
					<ContextualAnalyticsData {...analyticsData}>
						<ComponentWithStore {...props} intl={intl} />
					</ContextualAnalyticsData>
				</ErrorBoundary>
			);
		};

		ConnectedComponent.displayName = componentName;

		return ConnectedComponent;
	};

export default connectField('issueFields');
