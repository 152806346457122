import { useMemo } from 'react';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { useIssueFieldConfig } from '../field-config-service/main.tsx';

export const useSearchCustomFieldKeys = (issueKey: IssueKey, customFieldType: string): string[] => {
	const [{ value: issueFieldsConfig }] = useIssueFieldConfig(issueKey);
	const fieldKeys = useMemo(
		() =>
			(issueFieldsConfig &&
				Object.keys(issueFieldsConfig).filter(
					(key) => issueFieldsConfig[key].schema?.custom === customFieldType,
				)) ||
			[],
		[issueFieldsConfig, customFieldType],
	);

	return fieldKeys;
};
