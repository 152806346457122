const COMMAND_PALETTE_ISSUE_ACTIONS_PRIORITY = {
	STATUS: 10,
	SUMMARY: 20,
	PARENT: 30,
	LINK_ISSUE: 40,
	CONTENT_FIRST_TAB: 50,
	PINNED_FIELDS: 60,
	DETAILS: 70,
	CONTENT_OTHER_TABS: 80,
	MORE_FIELDS: 90,
	OLD_ISSUE_FIELDS: 95, // TODO: Remove this when there are no more usages
	ADD_COMMENT: 100,
	GQL_FIELDS: 105,
	SUB_HEADER_ITEMS: 110,
	ISSUE_HEADER_ITEMS: 120,
};

export const getCommandPaletteIssueActionsPriority = (
	key: keyof typeof COMMAND_PALETTE_ISSUE_ACTIONS_PRIORITY,
): number => COMMAND_PALETTE_ISSUE_ACTIONS_PRIORITY[key];
