import React, { type ReactElement } from 'react';
import type { AnalyticsSource } from '@atlassian/jira-common-constants/src/analytics-sources.tsx';
import { ViewExperienceTrackingProvider } from '@atlassian/jira-common-experience-tracking-viewing/src/view/experience-tracking-provider/index.tsx';
import EditExperienceTrackingProvider from '@atlassian/jira-common-experience-tracking/src/editing/view/provider/index.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import getMeta from '@atlassian/jira-get-meta';
import { useProjectContext } from '@atlassian/jira-providers-project-context/src/index.tsx';
import { getApplicationForProject } from '@atlassian/jira-shared-types/src/application.tsx';
import { getEdition } from '@atlassian/jira-shared-types/src/edition.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';

const IssueViewExperienceTrackingProvidersNew = ({
	analyticsSource,
	children,
	isExperienceReady,
	issueKey,
}: {
	analyticsSource: AnalyticsSource;
	children: ReactElement;
	isExperienceReady: boolean;
	issueKey: string;
}) => {
	const { data } = useProjectContext();
	const appEditions = useAppEditions();

	const projectType = data?.projectType;
	const application = projectType ? getApplicationForProject(projectType) : null;
	const edition = application ? getEdition(application, appEditions) : null;
	const additionalContext = {};

	const shard = getMeta('ajs-shard');
	const region = getMeta('ajs-region');
	Object.assign(additionalContext, { shard, region });

	return (
		<ViewExperienceTrackingProvider
			experience="viewIssue"
			experienceId={issueKey}
			application={application}
			edition={edition}
			analyticsSource={analyticsSource}
			isExperienceReady={isExperienceReady}
			additionalAttributes={additionalContext}
		>
			<EditExperienceTrackingProvider
				experience="editIssue"
				application={application}
				edition={edition}
				analyticsSource={analyticsSource}
			>
				{children}
			</EditExperienceTrackingProvider>
		</ViewExperienceTrackingProvider>
	);
};
const IssueViewExperienceTrackingProvidersOld = ({
	analyticsSource,
	children,
	isExperienceReady,
	issueKey,
}: {
	analyticsSource: AnalyticsSource;
	children: ReactElement;
	isExperienceReady: boolean;
	issueKey: string;
}) => {
	const { data } = useProjectContext();
	const appEditions = useAppEditions();

	const projectType = data?.projectType;
	const application = projectType ? getApplicationForProject(projectType) : null;
	const edition = application ? getEdition(application, appEditions) : null;

	return (
		<ViewExperienceTrackingProvider
			experience="viewIssue"
			experienceId={issueKey}
			application={application}
			edition={edition}
			analyticsSource={analyticsSource}
			isExperienceReady={isExperienceReady}
		>
			<EditExperienceTrackingProvider
				experience="editIssue"
				application={application}
				edition={edition}
				analyticsSource={analyticsSource}
			>
				{children}
			</EditExperienceTrackingProvider>
		</ViewExperienceTrackingProvider>
	);
};

export const IssueViewExperienceTrackingProviders = componentWithFG(
	'add-region-to-viewissue-event',
	IssueViewExperienceTrackingProvidersNew,
	IssueViewExperienceTrackingProvidersOld,
);
