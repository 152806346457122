import React from 'react';
import { FormattedMessage } from '@atlassian/jira-intl';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';
import { useIsEmbedMode } from '@atlassian/jira-issue-view-embed-mode/src/index.tsx';
import { StyledCommentShortcutHint } from './styled.tsx';

type Props = {
	messageDescriptor: MessageDescriptor;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	values: any;
};
const CommentShortcutHint = ({ messageDescriptor, values }: Props) => {
	const isEmbedMode = useIsEmbedMode();
	if (isEmbedMode) {
		return null;
	}
	return (
		<StyledCommentShortcutHint>
			<FormattedMessage {...messageDescriptor} values={values} />
		</StyledCommentShortcutHint>
	);
};

export default CommentShortcutHint;
