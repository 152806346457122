// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	label: {
		id: 'issue.title-for-status-field',
		defaultMessage: 'Status',
		description: 'The description for a field called Status',
	},
	flagAdded: {
		id: 'issue.status-for-sr-users-flag-added',
		defaultMessage: 'Flag added',
		description: 'The description for screen reader users when flag is added',
	},
	flagRemoved: {
		id: 'issue.status-for-sr-users-flag-removed',
		defaultMessage: 'Flag removed',
		description: 'The description for screen reader users when flag is removed',
	},
});
