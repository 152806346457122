import type { IssueContext } from '../../../common/types.tsx';

export const SET_CONTEXT = 'SET_CONTEXT';

export const setContext = (context: IssueContext) => ({
	type: SET_CONTEXT,
	payload: context,
});

export type SetContextAction = ReturnType<typeof setContext>;
