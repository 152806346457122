import React from 'react';
import type { CustomGlyphProps } from '@atlaskit/icon';

export const bulletListGlyph = (props: CustomGlyphProps) => (
	<svg
		{...props}
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M12.1667 10.6667H7.16667C6.70643 10.6667 6.33333 11.0398 6.33333 11.5C6.33333 11.9602 6.70643 12.3333 7.16667 12.3333H12.1667C12.6269 12.3333 13 11.9602 13 11.5C13 11.0398 12.6269 10.6667 12.1667 10.6667Z"
			fill="currentColor"
		/>
		<path
			d="M4.66667 11.5C4.66667 11.0398 4.29357 10.6667 3.83333 10.6667C3.3731 10.6667 3 11.0398 3 11.5C3 11.9602 3.3731 12.3333 3.83333 12.3333C4.29357 12.3333 4.66667 11.9602 4.66667 11.5Z"
			fill="currentColor"
		/>
		<path
			d="M12.1667 7.33333H7.16667C6.70643 7.33333 6.33333 7.70643 6.33333 8.16667C6.33333 8.6269 6.70643 9 7.16667 9H12.1667C12.6269 9 13 8.6269 13 8.16667C13 7.70643 12.6269 7.33333 12.1667 7.33333Z"
			fill="currentColor"
		/>
		<path
			d="M4.66667 8.16667C4.66667 7.70643 4.29357 7.33333 3.83333 7.33333C3.3731 7.33333 3 7.70643 3 8.16667C3 8.6269 3.3731 9 3.83333 9C4.29357 9 4.66667 8.6269 4.66667 8.16667Z"
			fill="currentColor"
		/>
		<path
			d="M12.1667 4H7.16667C6.70643 4 6.33333 4.3731 6.33333 4.83333C6.33333 5.29357 6.70643 5.66667 7.16667 5.66667H12.1667C12.6269 5.66667 13 5.29357 13 4.83333C13 4.3731 12.6269 4 12.1667 4Z"
			fill="currentColor"
		/>
		<path
			d="M4.66667 4.83333C4.66667 4.3731 4.29357 4 3.83333 4C3.3731 4 3 4.3731 3 4.83333C3 5.29357 3.3731 5.66667 3.83333 5.66667C4.29357 5.66667 4.66667 5.29357 4.66667 4.83333Z"
			fill="currentColor"
		/>
	</svg>
);
