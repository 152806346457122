import { type MutableRefObject, useRef } from 'react';
import type { PublicPluginAPI } from '@atlaskit/editor-common/types';
import type { LoomPlugin } from '@atlaskit/editor-plugin-loom';
import type { AnalyticsPlugin } from '@atlaskit/editor-plugins/analytics';
import { useLoomRecorderTriggerListener } from '@atlassian/jira-loom-utils/src/controllers/use-loom-recorder-trigger/index.tsx';
import { LoomRecorderTriggerEvent } from '@atlassian/jira-loom-utils/src/controllers/use-loom-recorder-trigger/types.tsx';
import {
	useAnalyticsEvents,
	fireOperationalAnalytics,
	fireTrackAnalytics,
} from '@atlassian/jira-product-analytics-bridge';

export type EditorAPI = PublicPluginAPI<[LoomPlugin, AnalyticsPlugin]>;
export type EditorAPIRef = MutableRefObject<EditorAPI | null>;

export interface UseLoomVideoEditorInserterProps {
	entryPointLocation: string;
}

export const useLoomVideoEditorInserter = ({
	entryPointLocation,
}: UseLoomVideoEditorInserterProps) => {
	const editorApiRef = useRef<EditorAPI | null>(null);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	// when cross flow succeeds, open the recorder
	useLoomRecorderTriggerListener(LoomRecorderTriggerEvent.CROSS_FLOW_SUCCESS_FROM_JSM, {
		entryPointLocation,
		// intentionally not localized because Loom RecordSDK does not support i18n
		insertButtonText: 'Add video',
		onInsert: (sharedUrl: string) => {
			const loomRefMetadata = {
				hasEditorApiRefBeenSet: editorApiRef.current != null,
				loomPluginSuccessfullyAdded: editorApiRef.current?.loom != null,
				loomInsertDefined: editorApiRef.current?.loom?.actions?.insertLoom != null,
			};

			if (editorApiRef.current?.loom?.actions?.insertLoom) {
				const isSuccess = editorApiRef.current?.loom?.actions?.insertLoom(
					{
						sharedUrl,
						title: '',
					},
					'end',
				);

				if (isSuccess) {
					fireTrackAnalytics(createAnalyticsEvent({}), 'loomVideoIntoEditor inserted');
				} else {
					fireOperationalAnalytics(
						createAnalyticsEvent({}),
						'jiraLoomUtils.insertLoomVideoIntoEditor fail',
						{
							...loomRefMetadata,
							loomInsertAttempted: true,
						},
					);
				}
			} else {
				fireOperationalAnalytics(
					createAnalyticsEvent({}),
					'jiraLoomUtils.insertLoomVideoIntoEditor fail',
					{
						...loomRefMetadata,
						loomInsertAttempted: false,
					},
				);
			}
		},
	});

	return { editorApiRef };
};
