// These are utility data-version-details-issue-click-ignore-target attributes to prevent issue side bar to open when actionable items are clicked in an issue card

// Should prevent default to prevent the whole issue card (a tag) not to be clicked
export const ISSUE_CARD_CLICK_IGNORE_TARGET = 'issueClickOpenIssueIgnoreTarget';
export const VERSION_SELECT_MENU_PORTAL_CLASS_PREFIX = 'move-issue-modal-select-dropdown';

// Shouldn't prevent default as we need the target's default behaviour. For example, target itself is 'a' tag.
export const FEATURE_FLAG_ACTION_DROPDOWN_ANCHORS = 'featureFlagAnchorActionDropdown';

export const getIssueCardClickIgnorePreventDefaultTargetSelector = () =>
	`[data-version-details-issue-click-ignore-target="${ISSUE_CARD_CLICK_IGNORE_TARGET}"],
    [data-version-details-issue-click-ignore-target="${FEATURE_FLAG_ACTION_DROPDOWN_ANCHORS}"],
    [data-testid="software-releases-move-issues-between-versions.ui.move-issue-version-select-modal--blanket"],
    [class^='${VERSION_SELECT_MENU_PORTAL_CLASS_PREFIX}'], [class*=' ${VERSION_SELECT_MENU_PORTAL_CLASS_PREFIX}']`;

export const getIssueCardClickIgnoreTargetNoPreventDefaultSelector = () =>
	`[data-version-details-issue-click-ignore-target="${FEATURE_FLAG_ACTION_DROPDOWN_ANCHORS}"]`;
