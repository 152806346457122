export const SHOW_INSERT_LOOM_LINK_FORM = 'SHOW_INSERT_LOOM_LINK_FORM' as const;

export const showInsertLoomLinkForm = () => ({
	type: SHOW_INSERT_LOOM_LINK_FORM,
});

export const HIDE_INSERT_LOOM_LINK_FORM = 'HIDE_INSERT_LOOM_LINK_FORM' as const;

export const hideInsertLoomLinkForm = () => ({
	type: HIDE_INSERT_LOOM_LINK_FORM,
});

export type ShowInsertLoomLinkFormAction = ReturnType<typeof showInsertLoomLinkForm>;
export type HideInsertLoomLinkFormAction = ReturnType<typeof hideInsertLoomLinkForm>;
