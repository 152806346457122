/**
 * @generated SignedSource<<92bb3e37d9b477a05d2f3a76411db509>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutTimeTrackingField_IssueViewTimeTrackingField$data = {
  readonly fieldId: string;
  readonly issue: {
    readonly issueId: string;
    readonly key: string;
  };
  readonly name: string;
  readonly timeTrackingSettings: {
    readonly isJiraConfiguredTimeTrackingEnabled: boolean | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutTimeTrackingUtils_IssueViewTimeTrackingController">;
  readonly " $fragmentType": "ui_issueViewLayoutTimeTrackingField_IssueViewTimeTrackingField";
} | null | undefined;
export type ui_issueViewLayoutTimeTrackingField_IssueViewTimeTrackingField$key = {
  readonly " $data"?: ui_issueViewLayoutTimeTrackingField_IssueViewTimeTrackingField$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutTimeTrackingField_IssueViewTimeTrackingField">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutTimeTrackingField_IssueViewTimeTrackingField",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "ui_issueViewLayoutTimeTrackingUtils_IssueViewTimeTrackingController"
    },
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "RequiredField",
      "field": {
        "concreteType": "JiraIssue",
        "kind": "LinkedField",
        "name": "issue",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "kind": "ScalarField",
              "name": "key"
            },
            "action": "NONE",
            "path": "issue.key"
          },
          {
            "kind": "ScalarField",
            "name": "issueId"
          }
        ]
      },
      "action": "NONE",
      "path": "issue"
    },
    {
      "concreteType": "JiraTimeTrackingSettings",
      "kind": "LinkedField",
      "name": "timeTrackingSettings",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isJiraConfiguredTimeTrackingEnabled"
        }
      ]
    }
  ],
  "type": "JiraTimeTrackingField"
};

(node as any).hash = "15dce68eca47c75fad0476a101a834d0";

export default node;
