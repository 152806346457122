import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	emptyStateTitle: {
		id: 'issue-attachments-table.empty.empty-state-title',
		defaultMessage: 'All your attachments in one place',
		description:
			'The title to use when displaying the attachments dialog and there are no attachments',
	},
	emptyStateDescription: {
		id: 'issue-attachments-table.empty.empty-state-description',
		defaultMessage:
			'Attachments can be added to paragraph fields, comments, and worklog entries. When you have attachments on this issue, you can view and download them here.',
		description:
			'The description to use when displaying the attachments dialog and there are no attachments',
	},
	emptyStateDescriptionIssueTermRefresh: {
		id: 'issue-attachments-table.empty.empty-state-description-issue-term-refresh',
		defaultMessage:
			'Attachments can be added to paragraph fields, comments, and worklog entries. When you have attachments on this issue, you can view and download them here.',
		description:
			'The description to use when displaying the attachments dialog and there are no attachments',
	},
});
