import type { Ari } from '@atlassian/jira-platform-ari/src/index.tsx';

export const MULTISELECT_COUNT_TEMPLATE = 'multi-select-count';

export type MultiselectCountFormula = {
	template: typeof MULTISELECT_COUNT_TEMPLATE;
	parameters: {
		id: Ari;
	};
};
