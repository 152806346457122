import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	loggedWork: {
		id: 'issue-history.history-items.history-item.worklog-history-item.logged-work',
		defaultMessage: 'logged {time}',
		description: `Describes that a user has logged work on an issue.
            Part of the heading in History feed, appears after the display name of the user who made the change.
            {time} is the well-formatted time logged (eg, '1d 6h 30m').`,
	},
});
