import { baseScopesRequiredPerField } from '@atlassian/jira-issue-adjustments/src/common.tsx';
import { isAriResourceIdValid } from '../utils/is-ari-resource-id-valid/index.tsx';
import { isNotEmptyString } from '../utils/is-not-empty-string/index.tsx';

export type PriorityPublicGetValue = {
	id: string;
	name: string;
	iconUrl?: string;
};

export type PriorityPublicSetValue = string;

export const validatePrioritySetValue = (value: unknown): value is PriorityPublicSetValue =>
	isNotEmptyString(value);

export const validatePriorityWithAriSetValue = (value: unknown): value is PriorityPublicSetValue =>
	isNotEmptyString(value) && isAriResourceIdValid(value);

export const priorityScopes = {
	...baseScopesRequiredPerField,
	granular: [...baseScopesRequiredPerField.granular, 'read:priority:jira'],
};
