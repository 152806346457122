import { useContext, useCallback } from 'react';
import { di } from 'react-magnetic-di';
import { SET_ACCESS_STATUS } from '../../common/types/actions.tsx';
import { ContentMenuContext } from '../create-content-menu-context/index.tsx';
import { useDirectJoinConfluence } from '../use-direct-join-confluence/index.tsx';
import { useEmbeddedPageTracking } from '../use-embedded-page-tracking/index.tsx';
import { ACCESS_REQUEST_CAPABILITIES } from '../utils/index.tsx';

interface CreateContentClickBehavior {
	accessStatus: string;
	createContent: () => void;
	tenantHasConfluence: boolean;
}

const BASE_DELAY = 300;
const MAX_DELAY = 10000;

export const useCreateContentClickBehavior = () => {
	di(window);
	const { embeddedConfluenceSource, setContentMenuState, setIsCrossFlowModalOpen } =
		useContext(ContentMenuContext);
	const fireAnalyticsEvent = useEmbeddedPageTracking();
	const { directJoin } = useDirectJoinConfluence({
		embeddedConfluenceSource,
		onSuccess: useCallback(
			() =>
				setContentMenuState({
					type: SET_ACCESS_STATUS,
					payload: ACCESS_REQUEST_CAPABILITIES.ACCESS_EXISTS,
				}),
			[setContentMenuState],
		),
	});

	const requestAccess = useCallback(() => {
		fireAnalyticsEvent({
			action: 'requested',
			actionSubject: 'productAccess',
			eventName: 'productAccess requested',
			embeddedConfluenceSource,
			originProduct: 'jira',
			requestedProduct: 'confluence',
			eventType: 'track',
		});

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const tenantUrl = window.location.origin;

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.open(`${tenantUrl}/wiki`, '_blank');
	}, [embeddedConfluenceSource, fireAnalyticsEvent]);

	const handleDirectJoin = useCallback(
		(retries = 15) => directJoin({ retries, baseDelay: BASE_DELAY, maxDelay: MAX_DELAY }),
		[directJoin],
	);

	const openCrossFlowModal = useCallback(() => {
		setIsCrossFlowModalOpen(true);
	}, [setIsCrossFlowModalOpen]);

	const getCreateContentClickBehavior = useCallback(
		({ accessStatus, createContent, tenantHasConfluence }: CreateContentClickBehavior) => {
			if (!tenantHasConfluence) {
				return openCrossFlowModal;
			}

			switch (accessStatus) {
				case ACCESS_REQUEST_CAPABILITIES.REQUEST_ACCESS:
				case ACCESS_REQUEST_CAPABILITIES.PENDING_REQUEST_EXISTS:
				case ACCESS_REQUEST_CAPABILITIES.DENIED_REQUEST_EXISTS:
				case ACCESS_REQUEST_CAPABILITIES.FORBIDDEN:
					return requestAccess;
				case ACCESS_REQUEST_CAPABILITIES.DIRECT_ACCESS:
					return handleDirectJoin;
				case ACCESS_REQUEST_CAPABILITIES.ACCESS_EXISTS:
					return createContent;
				default:
					return requestAccess;
			}
		},
		[handleDirectJoin, openCrossFlowModal, requestAccess],
	);
	return getCreateContentClickBehavior;
};
