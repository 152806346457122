import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { User } from '../../../common/types.tsx';
import type { UsersResponse } from '../types.tsx';

type SearchResponse = {
	values: UsersResponse[];
};

const getAssigneesUrl = (projectKey: string) =>
	`/rest/api/3/user/search/query?query=is assignee of ${projectKey}&maxResults=50`;

export const getProjectAssignees = async (projectKey: string): Promise<User[]> => {
	const users = await fetchJson<SearchResponse>(getAssigneesUrl(projectKey));
	return users.values
		.filter(({ active }) => active)
		.map(({ accountId, emailAddress, displayName, avatarUrls }) => ({
			id: accountId,
			email: emailAddress,
			name: displayName || '',
			avatar:
				avatarUrls['48x48'] ||
				avatarUrls['32x32'] ||
				avatarUrls['24x24'] ||
				Object.values(avatarUrls).pop() ||
				'',
		}));
};
