import React, { type ReactNode } from 'react';
import SortAscendingIcon from '@atlaskit/icon/core/sort-ascending';
import SortDescendingIcon from '@atlaskit/icon/core/sort-descending';
import {
	NEWEST_FIRST,
	OLDEST_FIRST,
} from '@atlassian/jira-issue-shared-types/src/common/types/activity-sort-order-type.tsx';
import type { ActivitySortOrder } from '@atlassian/jira-issue-view-common-types/src/activity-item-type.tsx';
import type { FormatMessage } from '@atlassian/jira-shared-types/src/general.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { ArrowDownIcon } from './assets/sorting-down-icon.tsx';
import { ArrowUpIcon } from './assets/sorting-up-icon.tsx';
import { messages } from './messages.tsx';

type SortOption = ActivitySortOrder & {
	icon: ReactNode;
};

export const getSortOptions = (formatMessage: FormatMessage): SortOption[] => [
	{
		key: NEWEST_FIRST,
		type: NEWEST_FIRST,
		name: formatMessage(messages.newestFirstLabel),
		icon: isVisualRefreshEnabled() ? (
			<SortDescendingIcon color="currentColor" label={formatMessage(messages.newestFirstLabel)} />
		) : (
			<ArrowDownIcon label="" />
		),
	},
	{
		key: OLDEST_FIRST,
		type: OLDEST_FIRST,
		name: formatMessage(messages.oldestFirstLabel),
		icon: isVisualRefreshEnabled() ? (
			<SortAscendingIcon color="currentColor" label={formatMessage(messages.oldestFirstLabel)} />
		) : (
			<ArrowUpIcon label="" />
		),
	},
];
