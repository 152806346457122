import { freeze } from 'icepick';
import { toAccountId, toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type {
	ReleasesDataFetchAction,
	ReleasesDataFetchResponseAction,
} from '../../releases-data/actions/index.tsx';
import type { Context } from '../../types.tsx';
import { SET_CONTEXT, type SetContextAction } from '../actions/index.tsx';

const initialContext: Context = freeze({
	issueContext: {
		issueId: 0,
		issueKey: toIssueKey(''),
		issueStatusCategory: '',
		projectId: 0,
		projectAri: '',
		projectType: '',
		isSimplifiedProject: false,
	},
	analyticsSource: '',
	summary: '',
	user: toAccountId('abc123'),
	isJiraAdmin: false,
	isSiteAdmin: false,
	cloudAri: '',
});

type ReleaseGlanceActions =
	| ReleasesDataFetchAction
	| ReleasesDataFetchResponseAction
	| SetContextAction;

export const contextReducer = (
	state: Context = initialContext,
	action: ReleaseGlanceActions,
): Context => {
	const { type, payload } = action;
	switch (type) {
		case SET_CONTEXT:
			return freeze(payload);
		default:
			return state;
	}
};
