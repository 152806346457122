import type { FC } from 'react';
import { graphql, useFragment } from 'react-relay';
import type { throwOnQueryError_servicedeskCustomerServiceCommon$key } from '@atlassian/jira-relay/src/__generated__/throwOnQueryError_servicedeskCustomerServiceCommon.graphql';

/**
 * Use me to read QueryError
 * and throw a formatted error when needed
 *
 * @example
 *  query fooQuery {
 *      customerService {
 *          entitlementById {
 *              __typename
 *              ... on CustomerServiceEntitlement {
 *                  ...fooStuff
 *              }
 *              ... on QueryError {
 *                  ...throwOnQueryError_servicedeskCustomerServiceCommon
 *              }
 *          }
 *      }
 *  }
 *
 *  if (entitlementById.__typename !== 'CustomerServiceEntitlement') {
 *      return (
 *          <ThrowOnQueryError
 *              operationName="fooQuery"
 *              errorRef={entitlementById.__typename === 'QueryError' ? entitlementById : undefined}
 *          />
 *      );
 *  }
 */
export const ThrowOnQueryError: FC<{
	operationName: string;
	errorRef?: throwOnQueryError_servicedeskCustomerServiceCommon$key;
}> = ({ operationName, errorRef }) => {
	if (!errorRef) {
		throw new Error(`${operationName}: unexpected typename returned`);
	}

	const { identifier, message, extensions } = useFragment(
		graphql`
			fragment throwOnQueryError_servicedeskCustomerServiceCommon on QueryError {
				identifier
				message
				extensions {
					# eslint-disable-next-line @atlassian/relay/unused-fields
					statusCode
					# eslint-disable-next-line @atlassian/relay/unused-fields
					errorType
				}
			}
		`,
		errorRef,
	);

	throw new Error(
		`${operationName}: QueryError; ${JSON.stringify({
			identifier,
			message,
			extensions,
		})}`,
	);
};
