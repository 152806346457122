import React, { type ComponentType, useState, useMemo, useEffect } from 'react';
import uuid from 'uuid';
import Avatar from '@atlaskit/avatar';
import { xcss, Box, Pressable, Text } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl, FormattedMessage } from '@atlassian/jira-intl';
import type {
	GroupedWorklog,
	Worklog,
} from '@atlassian/jira-issue-gira-transformer-types/src/common/types/worklogs.tsx';
import {
	type GroupedHistoryItem,
	ASSIGNEE_CHANGED,
	STATUS_CHANGED,
	type HistoryItem as HistoryItemType,
} from '@atlassian/jira-issue-history/src/types.tsx';
import HistoryItemComponent from '@atlassian/jira-issue-history/src/ui/history-items/history-item/index.tsx';
import type { IncidentChangeItem } from '@atlassian/jira-issue-opsgenie-activity/src/types.tsx';
import TimestampView from '@atlassian/jira-issue-timestamp/src/main.tsx';
import {
	ActivityTimestampStyles,
	Emphasise,
} from '@atlassian/jira-issue-view-activity-common/src/styles/index.tsx';
import WithProfileCardNext from '@atlassian/jira-issue-view-internal-profilecard-next/src/index.tsx';
import type { WorklogItemViewProps } from '@atlassian/jira-issue-worklog-item/src/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import {
	HISTORY_GROUPED_ITEMS_CATEGORY,
	WORK_LOG_GROUPED_ITEMS_CATEGORY,
} from '../../common/constants.tsx';
import type { IssueAllActivityWithIncidentsAndApprovalsQuery_viewIssue_allActivities_nodes_item_ApprovalItem_approvalItem as ApprovalItem } from '../../services/fetch-all-activity/__generated_apollo__/IssueAllActivityWithIncidentsAndApprovalsQuery';
import type { GroupItem, ActivityItem, CommentItem } from '../../types.tsx';
import messages from './messages.tsx';

// the component is used for rendering entries in All, Worklog and History activity tabs that have different data types, thus different item types
export type Props = {
	item: GroupItem | GroupedWorklog | GroupedHistoryItem;
	getCanModifyWorklog?: (worklog: Worklog) => {
		canEditWorklog: boolean;
		canDeleteWorklog: boolean;
	};
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	ActivityEntryComponent: ComponentType<any>;
	WorklogItemComponent?: ComponentType<WorklogItemViewProps>;
};

export type AllItems = (Worklog | ActivityItem | HistoryItemType)[];

type EntriesProps = {
	entries: (Worklog | ActivityItem | HistoryItemType)[];
	type?: string;
	getCanModifyWorklog?: (worklog: Worklog) => {
		canEditWorklog: boolean;
		canDeleteWorklog: boolean;
	};
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	ActivityEntryComponent: ComponentType<any>;
	WorklogItemComponent?: ComponentType<WorklogItemViewProps>;
};

const Entries = ({
	entries,
	type,
	getCanModifyWorklog,
	ActivityEntryComponent: ActivityEntry,
	WorklogItemComponent: WorklogItem,
}: EntriesProps) =>
	entries?.map((entry) => {
		// entry for a grouped HistoryItem
		if (type === HISTORY_GROUPED_ITEMS_CATEGORY) {
			// @ts-expect-error Type 'ActivityItem | Worklog | HistoryItem' is not assignable to type 'HistoryItem'.
			return <HistoryItemComponent historyItem={entry} isGrouped key={uuid()} />;
		}
		// entry for a grouped Worklog item
		if (WorklogItem !== undefined && 'id' in entry && type === WORK_LOG_GROUPED_ITEMS_CATEGORY) {
			const { canEditWorklog, canDeleteWorklog } = getCanModifyWorklog
				? getCanModifyWorklog(entry)
				: { canEditWorklog: false, canDeleteWorklog: false };

			return (
				<WorklogItem
					worklog={entry}
					worklogId={entry.id}
					isGrouped
					key={entry.id}
					canEditWorklog={canEditWorklog}
					canDeleteWorklog={canDeleteWorklog}
					isHighlighted={false} // the grouped item will not be highlighted
					fullIssueUrl="" // the url is not used in the grouped worklog item
					showPermalink
					showTimestamp={false}
				/>
			);
		}

		if (!type) {
			// @ts-expect-error Property 'category' does not exist on type 'ActivityItem | Worklog | HistoryItem'.
			const key = `${entry.category}_${entry.item.id}`;
			return <ActivityEntry key={key} entry={entry} isGrouped />;
		}
		return null;
	});

const filterItem = (
	groupedItem: ActivityItem | Worklog | HistoryItemType,
	type: string,
): groupedItem is ActivityItem | HistoryItemType => {
	// for Worklog
	if (!('item' in groupedItem) && !('type' in groupedItem)) {
		return false;
	}

	// for GroupedHistoryItem
	if ('type' in groupedItem) {
		return groupedItem.type === type;
	}

	// for HistoryActivityItem, one of the types of the ActivityItem
	if ('item' in groupedItem) {
		const groupedItemItem:
			| GroupItem
			| CommentItem
			| ({
					id: string;
			  } & HistoryItemType)
			| Worklog
			| IncidentChangeItem
			| ApprovalItem = groupedItem.item;
		return 'type' in groupedItemItem ? groupedItemItem.type === type : false;
	}

	return false;
};

export const moveFirstofTypesToTop = (allItems: AllItems, filteredTypes: string[]) => {
	if (allItems?.length) {
		const sortedItems = [...allItems];
		filteredTypes.forEach((type) => {
			const index = sortedItems.findIndex((groupedItem) => filterItem(groupedItem, type));
			if (index !== -1) sortedItems.unshift(sortedItems.splice(index, 1)[0]);
		});
		return sortedItems;
	}
	return allItems;
};

const MAX_TOP_ITEMS = 3;
export const FILTERED_TYPES = [ASSIGNEE_CHANGED, STATUS_CHANGED];

export const GroupedActivityEntry = ({
	item,
	getCanModifyWorklog,
	ActivityEntryComponent,
	WorklogItemComponent,
}: Props) => {
	const [showingAll, setShowingAll] = useState(false);
	const [reorderedItems, setReorderedItems] = useState<AllItems>([]);
	const intl = useIntl();

	const handleOnClick = () => {
		setShowingAll((prevIsShowingAll) => !prevIsShowingAll);
	};

	useEffect(() => {
		setReorderedItems(moveFirstofTypesToTop(item.items, FILTERED_TYPES));
	}, [item.items]);

	const displayItems = useMemo(() => {
		if (showingAll) {
			return reorderedItems;
		}

		return reorderedItems.slice(0, MAX_TOP_ITEMS);
	}, [showingAll, reorderedItems]);

	const showShowAllButton = useMemo(() => {
		if (!item.items?.length) {
			return false;
		}

		return item.items.length > MAX_TOP_ITEMS;
	}, [item.items?.length]);

	// @ts-expect-error Property 'actor' does not exist on type GroupedWorklog
	const actor = item.actor || item.author;
	const actorName = actor?.displayName;

	return (
		<>
			<Box
				xcss={containerStyles}
				testId="issue-all-activity.ui.grouped-activity-entry.history-item-grouped"
			>
				{actor === null ? (
					intl.formatMessage(messages.anonymousUser)
				) : (
					<Box xcss={wrapperStyles}>
						<WithProfileCardNext
							userAccount={actor}
							analyticsData={{ trigger: 'history-item-actor-avatar' }}
							testId="issue-all-activity.ui.grouped-activity-entry.profilecard-trigger"
						>
							<Box xcss={avatarContainerStyles}>
								<Avatar size="small" src={actor ? actor.avatarUrl : undefined} />
							</Box>
						</WithProfileCardNext>
					</Box>
				)}
				<Box xcss={contentStyles}>
					<FormattedMessage
						id="issue-all-activity.grouped-activity-entry.change"
						description="Label for the header of the list of the entries in a group in a history timeline"
						defaultMessage="<wrapper>{actorName}</wrapper> made {numberOfChanges} {numberOfChanges, plural, one {change} other {changes}}"
						values={{
							numberOfChanges: item.items.length,
							actorName,
							wrapper: (chunks) => (
								<Box xcss={wrapperStyles}>
									<WithProfileCardNext
										userAccount={actor}
										analyticsData={{ trigger: 'history-item-actor-name' }}
										testId="issue-all-activity.ui.grouped-activity-entry.profilecard-trigger"
									>
										<Emphasise>{chunks}</Emphasise>
									</WithProfileCardNext>
								</Box>
							),
						}}
					/>
					<Box xcss={timestampContainerStyles}>
						<TimestampView
							value={new Date(item.timestamp)?.getTime()}
							extraStyles={ActivityTimestampStyles}
							tooltipPosition="top"
						/>
					</Box>
					<Entries
						entries={displayItems}
						type={'type' in item ? item.type : undefined}
						getCanModifyWorklog={getCanModifyWorklog}
						ActivityEntryComponent={ActivityEntryComponent}
						WorklogItemComponent={WorklogItemComponent}
					/>
					{showShowAllButton && (
						<Pressable
							xcss={
								isVisualRefreshEnabled() && fg('jira_nav4_beta_drop_1')
									? pressableStylesRevised
									: pressableStyles
							}
							padding={
								isVisualRefreshEnabled() && fg('jira_nav4_beta_drop_1') ? 'space.100' : 'space.0'
							}
							onClick={handleOnClick}
						>
							<Text
								size="small"
								weight={
									isVisualRefreshEnabled() && fg('jira_nav4_beta_drop_1') ? 'regular' : 'semibold'
								}
								color="color.text.subtle"
							>
								<FormattedMessage {...(showingAll ? messages.showLess : messages.showAll)} />
							</Text>
						</Pressable>
					)}
				</Box>
			</Box>
			<Box xcss={horizontalDividerStyles} />
		</>
	);
};

export default GroupedActivityEntry;

const horizontalDividerStyles = xcss({
	height: '1px',
	width: '100%',
	backgroundColor: 'color.background.neutral',
	marginTop: 'space.100',
});

const wrapperStyles = xcss({
	display: 'inline-flex',
});

const containerStyles = xcss({
	marginTop: 'space.100',
	marginRight: '0',
	marginBottom: 'space.200',
	marginLeft: '0',
	display: 'flex',
});

const avatarContainerStyles = xcss({
	height: '28px', // Required because AkAvatar is styled as inline-block
	marginRight: 'space.100',
	// Required because JiraIcon isn't the same width as AkAvatar
	width: '28px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
});

const contentStyles = xcss({
	flexGrow: 1,
	alignSelf: 'center',
	/*
		Might be able to remove when we use Fabric Renderer
		Need to give width to prevent overflow (BENTO-1779)
		95% so it's not too close to edge (esp in single column layout)
		30px arbitrary width to accomodate the arrow
	*/
	width: 'calc(95% - 30px)',
	paddingTop: 'space.050',
});

const timestampContainerStyles = xcss({
	marginLeft: 'space.100',
	display: 'inline-flex',
});

const pressableStyles = xcss({
	marginTop: 'space.050',
	backgroundColor: 'color.background.neutral.subtle',
	':hover': {
		backgroundColor: 'color.background.neutral.subtle.hovered',
	},
});

const pressableStylesRevised = xcss({
	marginTop: 'space.050',
	backgroundColor: 'color.background.neutral.subtle',
	paddingLeft: 'space.0',
	':hover': {
		textDecoration: 'underline',
	},
});
