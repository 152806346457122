import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	showDetails: {
		id: 'servicedesk-request-details-panel.expander.show-details',
		defaultMessage: 'Show details',
		description: 'Button to show details for the expander',
	},
	hideDetails: {
		id: 'servicedesk-request-details-panel.expander.hide-details',
		defaultMessage: 'Hide details',
		description: 'Button to hide details for the expander',
	},
});
