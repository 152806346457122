import { useEffect, useRef } from 'react';
import { usePreviousWithInitial } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { functionWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';

const useIsUpdated = <T,>(value: T) => {
	const newValue = usePreviousWithInitial<T>(value);
	return newValue !== value;
};

export type OnIssueKeyChangeArgument = {
	fromIssueKey: IssueKey;
	toIssueKey: IssueKey;
	meta: {
		location: string;
	};
};

/**
 * Checks for updates to the issuekey that come from the issue's data. Generally this is due to requesting data
 * for an issue that has moved projects and thus now has a new actual issue-key.
 * @param issueKeyFromProps
 * @param issueKeyFromData
 * @param onIssueKeyChange
 */
const useIssueKeyMismatchListenerOld = ({
	issueKeyFromProps,
	issueKeyFromData,
	onIssueKeyChange,
}: {
	issueKeyFromProps: string;
	issueKeyFromData: string | null | undefined;
	onIssueKeyChange: undefined | ((details: OnIssueKeyChangeArgument) => void);
}) => {
	const isIssueKeyFromDataUpdated = useIsUpdated(issueKeyFromData);
	const isFirstMountRef = useRef(true);
	const isBothIssueKeysDefined = issueKeyFromData != null && issueKeyFromProps != null;
	const isIssueKeyFromDataDifferentToProps = issueKeyFromData !== issueKeyFromProps;
	if (
		// Check for when the data updates to a value that is not nullish
		(isIssueKeyFromDataUpdated || isFirstMountRef.current) &&
		isBothIssueKeysDefined &&
		// Then guard against the case where updates to the props are what triggered the update to data
		isIssueKeyFromDataDifferentToProps
	) {
		onIssueKeyChange?.({
			fromIssueKey: issueKeyFromProps,
			toIssueKey: issueKeyFromData,
			meta: {
				location: 'issuekey-mismatch-listener',
			},
		});
	}
	isFirstMountRef.current = false;
};

/**
 * Checks for updates to the issuekey that come from the issue's data. Generally this is due to requesting data
 * for an issue that has moved projects and thus now has a new actual issue-key.
 * @param issueKeyFromProps
 * @param issueKeyFromData
 * @param onIssueKeyChange
 */
const useIssueKeyMismatchListenerNew = ({
	issueKeyFromProps,
	issueKeyFromData,
	onIssueKeyChange,
}: {
	issueKeyFromProps: string;
	issueKeyFromData: string | null | undefined;
	onIssueKeyChange: undefined | ((details: OnIssueKeyChangeArgument) => void);
}) => {
	const isIssueKeyFromDataUpdated = useIsUpdated(issueKeyFromData);
	const isFirstMountRef = useRef(true);
	useEffect(() => {
		const isBothIssueKeysDefined = issueKeyFromData != null && issueKeyFromProps != null;
		const isIssueKeyFromDataDifferentToProps = issueKeyFromData !== issueKeyFromProps;
		if (
			// Check for when the data updates to a value that is not nullish
			(isIssueKeyFromDataUpdated || isFirstMountRef.current) &&
			isBothIssueKeysDefined &&
			// Then guard against the case where updates to the props are what triggered the update to data
			isIssueKeyFromDataDifferentToProps
		) {
			onIssueKeyChange?.({
				fromIssueKey: issueKeyFromProps,
				toIssueKey: issueKeyFromData,
				meta: {
					location: 'issuekey-mismatch-listener',
				},
			});
		}
		isFirstMountRef.current = false;
	}, [isIssueKeyFromDataUpdated, issueKeyFromData, issueKeyFromProps, onIssueKeyChange]);
};

export const useIssueKeyMismatchListener = functionWithCondition(
	() => fg('jira-concurrent-issue-view-issue-key-mismatch'),
	useIssueKeyMismatchListenerNew,
	useIssueKeyMismatchListenerOld,
);
