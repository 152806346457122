/**
 * @generated SignedSource<<4c34f2437bfc2f423bf32ced7881a8e3>>
 * @relayHash b236bb037f21efae8b3db9e134b79fab
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 2178ae09b612a2f0e42deb54ceb81da2ddc4fa6fe165bc1ebe48551e0d4fab69

import type { ConcreteRequest, Query } from 'relay-runtime';
export type JiraConfigStateConfigurationStatus = "CONFIGURED" | "NOT_CONFIGURED" | "NOT_INSTALLED" | "NOT_SET" | "PARTIALLY_CONFIGURED" | "PROVIDER_ACTION_CONFIGURED" | "PROVIDER_ACTION_NOT_CONFIGURED" | "%future added value";
export type servicesGetShowAppConfigPromptQuery$variables = {
  cloudAri: string;
};
export type servicesGetShowAppConfigPromptQuery$data = {
  readonly jira: {
    readonly devOps: {
      readonly configStates: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly config: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly status: JiraConfigStateConfigurationStatus;
                };
              }>;
            };
            readonly id: string;
          };
        }>;
      };
      readonly isInContextConfigPromptDismissed: boolean;
    };
  };
};
export type servicesGetShowAppConfigPromptQuery = {
  response: servicesGetShowAppConfigPromptQuery$data;
  variables: servicesGetShowAppConfigPromptQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudAri"
  }
],
v1 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudAri"
},
v2 = [
  (v1/*: any*/),
  {
    "kind": "Literal",
    "name": "providerTypeFilter",
    "value": [
      "DEPLOYMENTS"
    ]
  }
],
v3 = {
  "kind": "ScalarField",
  "name": "id"
},
v4 = {
  "kind": "ScalarField",
  "name": "status"
},
v5 = {
  "args": [
    (v1/*: any*/),
    {
      "kind": "Literal",
      "name": "location",
      "value": "RELEASES_PANEL"
    }
  ],
  "kind": "ScalarField",
  "name": "isInContextConfigPromptDismissed"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "servicesGetShowAppConfigPromptQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "concreteType": "JiraQuery",
          "kind": "LinkedField",
          "name": "jira",
          "plural": false,
          "selections": [
            {
              "kind": "RequiredField",
              "field": {
                "concreteType": "JiraDevOpsQuery",
                "kind": "LinkedField",
                "name": "devOps",
                "plural": false,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": {
                      "args": (v2/*: any*/),
                      "concreteType": "JiraAppConfigStateConnection",
                      "kind": "LinkedField",
                      "name": "configStates",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "RequiredField",
                          "field": {
                            "concreteType": "JiraAppConfigStateEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "RequiredField",
                                "field": {
                                  "concreteType": "JiraAppConfigState",
                                  "kind": "LinkedField",
                                  "name": "node",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "kind": "RequiredField",
                                      "field": (v3/*: any*/),
                                      "action": "THROW",
                                      "path": "jira.devOps.configStates.edges.node.id"
                                    },
                                    {
                                      "kind": "RequiredField",
                                      "field": {
                                        "concreteType": "JiraConfigStateConnection",
                                        "kind": "LinkedField",
                                        "name": "config",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "kind": "RequiredField",
                                            "field": {
                                              "concreteType": "JiraConfigStateEdge",
                                              "kind": "LinkedField",
                                              "name": "edges",
                                              "plural": true,
                                              "selections": [
                                                {
                                                  "kind": "RequiredField",
                                                  "field": {
                                                    "concreteType": "JiraConfigState",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                      {
                                                        "kind": "RequiredField",
                                                        "field": (v4/*: any*/),
                                                        "action": "THROW",
                                                        "path": "jira.devOps.configStates.edges.node.config.edges.node.status"
                                                      }
                                                    ]
                                                  },
                                                  "action": "THROW",
                                                  "path": "jira.devOps.configStates.edges.node.config.edges.node"
                                                }
                                              ]
                                            },
                                            "action": "THROW",
                                            "path": "jira.devOps.configStates.edges.node.config.edges"
                                          }
                                        ]
                                      },
                                      "action": "THROW",
                                      "path": "jira.devOps.configStates.edges.node.config"
                                    }
                                  ]
                                },
                                "action": "THROW",
                                "path": "jira.devOps.configStates.edges.node"
                              }
                            ]
                          },
                          "action": "THROW",
                          "path": "jira.devOps.configStates.edges"
                        }
                      ]
                    },
                    "action": "THROW",
                    "path": "jira.devOps.configStates"
                  },
                  {
                    "kind": "RequiredField",
                    "field": (v5/*: any*/),
                    "action": "THROW",
                    "path": "jira.devOps.isInContextConfigPromptDismissed"
                  }
                ]
              },
              "action": "THROW",
              "path": "jira.devOps"
            }
          ]
        },
        "action": "THROW",
        "path": "jira"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "servicesGetShowAppConfigPromptQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "concreteType": "JiraDevOpsQuery",
            "kind": "LinkedField",
            "name": "devOps",
            "plural": false,
            "selections": [
              {
                "args": (v2/*: any*/),
                "concreteType": "JiraAppConfigStateConnection",
                "kind": "LinkedField",
                "name": "configStates",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraAppConfigStateEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "JiraAppConfigState",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "concreteType": "JiraConfigStateConnection",
                            "kind": "LinkedField",
                            "name": "config",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraConfigStateEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "concreteType": "JiraConfigState",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v4/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              (v5/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "2178ae09b612a2f0e42deb54ceb81da2ddc4fa6fe165bc1ebe48551e0d4fab69",
    "metadata": {},
    "name": "servicesGetShowAppConfigPromptQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "5a496058391c2f700c2a788b106615f3";

export default node;
