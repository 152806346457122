import React from 'react';
import UploadIcon from '@atlaskit/icon/glyph/upload';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { ISSUE } from '@atlassian/jira-development-board-common/src/common/constants.tsx';
import { DevInfoTypes } from '@atlassian/jira-development-board-common/src/types.tsx';
import { AsyncDevInfoIcon } from '@atlassian/jira-development-board-dev-info-icon/src/async.tsx';
import DevInfoIcon from '@atlassian/jira-development-board-dev-info-icon/src/main.tsx';
import type {
	DeploymentSummary,
	EnvironmentType,
} from '@atlassian/jira-development-common/src/model/releases-data.tsx';
import { AppConfigurationPromptSummary } from '@atlassian/jira-development-summary-app-config-prompt-field/src/ui/index.tsx';
import { SummaryItem } from '@atlassian/jira-development-summary-common/src/ui/summary-item/index.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import {
	fireUIAnalytics,
	useAnalyticsEvents,
	ContextualAnalyticsData,
	DRAWER,
} from '@atlassian/jira-product-analytics-bridge';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import type { IssueContext } from './types.tsx';
import envMessages from './ui/env-type/messages.tsx';
import Status from './ui/status/main.tsx';

const envTypeMessageKeys: Record<EnvironmentType, string> = {
	PRODUCTION: 'production',
	STAGING: 'staging',
	TESTING: 'testing',
	DEVELOPMENT: 'development',
	UNMAPPED: 'unmapped',
};

const envTypeMessage = (key: EnvironmentType): MessageDescriptor =>
	// @ts-expect-error - TS2538 - Type 'undefined' cannot be used as an index type.
	envMessages[envTypeMessageKeys[key] || envTypeMessageKeys.UNMAPPED];

export type Props = {
	issueContext: IssueContext;
	summary?: DeploymentSummary;
	onClick: () => void;
	fieldHasOtherActions?: boolean;
	shouldShowAppConfigContextPrompt?: boolean;
};

const DeploymentsOld = ({
	issueContext,
	summary,
	onClick,
	fieldHasOtherActions = false,
	shouldShowAppConfigContextPrompt = false,
}: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	if (shouldShowAppConfigContextPrompt) {
		return (
			<AppConfigurationPromptSummary
				location="RELEASES_PANEL"
				panelHasOtherPrompts={fieldHasOtherActions}
			/>
		);
	}

	if (!summary || !summary.state || !summary.environmentType) {
		return <></>;
	}

	const { state, environmentType } = summary;

	const { issueStatusCategory, projectType, projectId, issueKey, issueId, isSimplifiedProject } =
		issueContext;

	const analyticsAttributes = {
		environmentType,
		deploymentState: state,
		issueStatusCategory,
		projectType,
		projectId,
		isSimplifiedProject,
	};

	const onSummaryItemClick = () => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'summaryItem',
			}),
			'deploymentSummaryLink',
			analyticsAttributes,
		);
		onClick();
	};

	const summaryItemComponent = (
		<SummaryItem
			title={formatMessage(envTypeMessage(environmentType))}
			issueKey={issueKey}
			icon={<UploadIcon size="medium" label="" />}
			onClick={onSummaryItemClick}
			secondaryData={<Status state={state} environmentType={environmentType} />}
			forceVisibleSecondary
			showIconInPlainLink
			data-testid="development-summary-deployment-releases-field.summary-item"
		/>
	);

	let deploymentsComponent = summaryItemComponent;
	if (ff('devops-popups-on-issue-view_zikxe')) {
		if (fg('convert_devinfoicon_to_be_synchronous')) {
			deploymentsComponent = (
				<DevInfoIcon
					devInfoType={DevInfoTypes.DEPLOYMENT}
					issueId={issueId}
					customTrigger={summaryItemComponent}
					scopeId={`devInfoPanelApp${issueKey}`}
					placement="bottom-end"
					offset={[-8, 8]}
					zIndex={layers.modal}
					location={ISSUE}
				/>
			);
		} else {
			deploymentsComponent = (
				<Placeholder fallback={summaryItemComponent} name="async-deployments-dev-info-icon">
					<AsyncDevInfoIcon
						devInfoType={DevInfoTypes.DEPLOYMENT}
						issueId={issueId}
						customTrigger={summaryItemComponent}
						scopeId={`devInfoPanelApp${issueKey}`}
						placement="bottom-end"
						offset={[-8, 8]}
						zIndex={layers.modal}
						location={ISSUE}
					/>
				</Placeholder>
			);
		}
	}

	return (
		<ContextualAnalyticsData sourceType={DRAWER} sourceName="deploymentSummary">
			{deploymentsComponent}
		</ContextualAnalyticsData>
	);
};

const DeploymentsNew = (props: Props) => (
	<UFOSegment name="development-summary-deployment">
		<DeploymentsOld {...props} />
	</UFOSegment>
);

const Deployments = componentWithFG(
	'jira-add-ufo-instrument-for-asyncdevinfoicon',
	DeploymentsNew,
	DeploymentsOld,
);

export default Deployments;
