import React, { forwardRef } from 'react';
import type { BaseGroupProps } from '../../types.tsx';
import { GroupStyled } from '../styled.tsx';

/**
 * Base Group Component
 */
export const BaseGroup = forwardRef<HTMLDivElement, BaseGroupProps>(
	({ Group = GroupStyled, Header, headerNode, children, isOpen }, ref) => (
		// @ts-expect-error - TS2322: Type 'ForwardedRef<HTMLDivElement>' is not assignable to type '(instance: HTMLDivElement | null) => void)
		<Group ref={ref} open={isOpen}>
			{headerNode !== undefined ? headerNode : Header !== undefined && <Header />}
			{children}
		</Group>
	),
);

export default BaseGroup;
