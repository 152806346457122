import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	menuItemText: {
		id: 'set-recurring-issue-action-menu-item-title',
		defaultMessage: 'Clone when issue is done',
		description: 'Title of the issue view automation popup menu item to set an issue as recurring.',
	},
	newLozenge: {
		id: 'set-recurring-issue-action-menu-item-new-lozenge',
		defaultMessage: 'New',
		description: 'Lozenge indicating that this is a new menu-item.',
	},
	ruleDisabledTooltip: {
		id: 'recurring-issue-action.rule-disabled-tooltip',
		defaultMessage: 'Contact your admin to turn on rule.',
		description: 'Tooltip content to show around toggle when the automation rule is disabled.',
	},
	missingDeletePermissionTooltip: {
		id: 'recurring-issue-action.missing-delete-permission-tooltip',
		defaultMessage: 'Contact your admin to edit this rule.',
		description:
			'Tooltip content to show around toggle when a user is missing permissioni to delete the automation rule .',
	},
	successFlagTitle: {
		id: 'set-recurring-issue-action-menu-item-success-flag-title',
		defaultMessage: '{issueKey} will clone when moved to any done status.',
		description:
			'Title of the success flag shown when a work item is successfully set to be recurring.',
	},
	successFlagContent: {
		id: 'set-recurring-issue-action-menu-item-success-flag-content',
		defaultMessage: 'Attachments, start date and due date will not be included.',
		description:
			'Content of the success flag shown when a work item is successfully set to be recurring.',
	},
	successFlagAction: {
		id: 'set-recurring-issue-action-menu-item-success-flag-action',
		defaultMessage: 'View rule',
		description:
			'Action button label of the success flag shown when a work item is successfully set to be recurring.',
	},
});
