export const joinSuggestionsWithPrioritisedRecentlySelected = <T extends { id: string }>({
	recentlySelected,
	prefetched,
}: {
	recentlySelected: T[];
	prefetched: T[];
}): T[] => {
	if (!recentlySelected.length) {
		return prefetched;
	}

	const updatedRecentlySelected: T[] = recentlySelected.reduce((suggestions, suggestion) => {
		const suggestionInPrefetched = prefetched.find((p) => p.id === suggestion.id);
		if (suggestionInPrefetched) suggestions.push(suggestionInPrefetched);
		return suggestions;
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	}, [] as T[]);

	const recentlySelectedIds = updatedRecentlySelected.map((s) => s.id);
	const filteredPrefetched: T[] = prefetched.filter(
		(prefetchedSuggestion) => !recentlySelectedIds.includes(prefetchedSuggestion.id),
	);
	return [...updatedRecentlySelected, ...filteredPrefetched];
};
