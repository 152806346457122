import type { AIContextServicePanelResourceResponse } from '@atlassian/jira-servicedesk-ai-context-service-panel-resources/src/services/types.tsx';

export const AI_CONFIG = {
	product: 'jira',
	subproduct: 'serviceDesk',
	aiFeatureName: 'aiContextServicePanel',
	proactiveGeneratedAI: 1,
	userGeneratedAI: 0,
} as const;

export const getAiExperienceName = (data: AIContextServicePanelResourceResponse) => {
	return [
		data?.summary ? 'summary' : null,
		data?.nextSteps ? 'nextSteps' : null,
		data?.reporterDetails ? 'reporterDetails' : null,
		data?.suggestedEscalation ? 'suggestedEscalation' : null,
		data?.suggestedActions?.length ? 'suggestedActions' : null,
	]
		.filter(Boolean)
		.join(',');
};
