import React from 'react';
import memoize from 'lodash/memoize';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import UserOptionView from '@atlassian/jira-issue-internal-field-select/src/common/custom-format/user-option/index.tsx';
import type { FormatOptionLabelMeta } from '@atlassian/jira-issue-internal-field-select/src/common/select-inline-edit/select-field/index.tsx';
import MultiSelectInlineEditView from '@atlassian/jira-issue-internal-field-select/src/multi-select-inline-edit/index.tsx';
import { genericMessages } from '@atlassian/jira-issue-view-common-constants/src/context-items-messages.tsx';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button/index.tsx';
import connectField from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-field.tsx';
import { issueKeySelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import messages from './messages.tsx';
import {
	transformFromStateValue,
	transformToStateValue,
	transformSuggestions,
} from './transformer.tsx';
import { filterOption } from './utils.tsx';

export const fetchSuggestionsFactory = memoize((issueKey: string) => async (query: string) => {
	const suggestions = await fetchJson(
		`/rest/api/3/user/recommend?${new URLSearchParams({
			context: 'CustomMultiUserField',
			...(issueKey && { issueKey }),
			query,
		})}`,
	);
	return transformSuggestions(suggestions);
});

type FormatOption = {
	email: string;
	label: string;
	avatarUrl: string;
};

export const formatOptionLabel = (option: FormatOption, meta: FormatOptionLabelMeta) => {
	return (
		<UserOptionView
			avatarUrl={option.avatarUrl}
			email={option.email}
			label={option.label}
			context={meta.context}
		/>
	);
};

export const multiUserSelectFilterOption = filterOption;

export default connectField((stateOnMount, ownPropsOnMount) => ({
	fieldId: ownPropsOnMount.fieldId,
	...{ transformFromStateValue, transformToStateValue },
	additionalProps: (state, intl) => ({
		fetchSuggestions: fetchSuggestionsFactory(issueKeySelector(state)),
		placeholder: intl.formatMessage(messages.placeholder),
		noValueText: intl.formatMessage(genericMessages.noValue),
		tagAppearance: 'rounded',
		showDropdownOnClick: false,
		isUserTag: true,
		formatOptionLabel,
		showPinButton: getShowPinButton(ownPropsOnMount.area),
		filterOption: multiUserSelectFilterOption,
	}),
}))(MultiSelectInlineEditView);
