import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	getDesignUpdatesButton: {
		id: 'issue-design-section.common.get-design-updates-popup.get-design-updates-button',
		defaultMessage: 'Request design status updates',
		description: 'Button to request admin to enable receiving design updates',
	},
	getDesignUpdatesButtonForAdmin: {
		id: 'issue-design-section.common.get-design-updates-popup.get-design-updates-button-for-admin',
		defaultMessage: 'Get design status updates',
		description: 'Button to enable receiving design updates',
	},
});
