import type { PolarisComment } from '@atlassian/jira-polaris-domain-comment/src/index.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { getCommentsMeta } from '@atlassian/jira-polaris-remote-issue/src/controllers/util/index.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { getLocalIssueIdToJiraId } from '../../selectors/issue-ids.tsx';
import { createIsIssueHavingUnseenComments } from '../../selectors/properties/comments/index.tsx';
import {
	getKeyProperties,
	getSelectedIssue,
	getSelectedIssueLocalIssueId,
} from '../../selectors/properties/index.tsx';
import type { State, Props } from '../../types.tsx';

export const setCommentsForSelectedIssue =
	(issueKey: string, comments: PolarisComment[]) =>
	async ({ getState, setState }: StoreActionApi<State>) => {
		const state = getState();
		const selectedIssueLocalIssueId = getSelectedIssueLocalIssueId(state);
		const keyProperties = getKeyProperties(state);
		if (
			selectedIssueLocalIssueId === undefined ||
			keyProperties[selectedIssueLocalIssueId] !== issueKey
		) {
			return;
		}
		setState({
			lastUpdatedIssueIds: [selectedIssueLocalIssueId],
			properties: {
				...state.properties,
				issueMetadata: {
					...state.properties.issueMetadata,
					[selectedIssueLocalIssueId]: {
						...state.properties.issueMetadata[selectedIssueLocalIssueId],
						comments: getCommentsMeta(comments),
					},
				},
			},
		});
	};

export const setTimestampIssueCommentsSeen =
	(localIssueId: LocalIssueId, timestampCurrentUserSeenComments: number, projectId?: string) =>
	async ({ getState, setState }: StoreActionApi<State>, props: Props) => {
		const state = getState();

		const isIssueHavingUnseenComments = createIsIssueHavingUnseenComments(localIssueId)(
			state,
			props,
		);

		if (!isIssueHavingUnseenComments) {
			return;
		}

		const { issuesRemote } = props;

		setState({
			properties: {
				...state.properties,
				commentsMetadata: {
					...state.properties.commentsMetadata,
					[localIssueId]: { timestampCurrentUserSeenComments },
				},
			},
		});
		const jiraIdMap = getLocalIssueIdToJiraId(state, props);
		const issueId = jiraIdMap[localIssueId];
		if (issueId !== undefined) {
			issuesRemote.updateLastCommentsViewedTimestamp({
				issueId,
				timestamp: new Date(timestampCurrentUserSeenComments).toISOString(),
				projectId,
			});
		}
	};

export const setTimestampCommentsSeen =
	(timestampCurrentUserSeenComments: number) =>
	async ({ getState, dispatch }: StoreActionApi<State>, props: Props) => {
		const state = getState();
		const localIssueId = getSelectedIssueLocalIssueId(state);
		if (localIssueId === undefined) {
			return;
		}
		dispatch(
			setTimestampIssueCommentsSeen(
				localIssueId,
				timestampCurrentUserSeenComments,
				props.projectId,
			),
		);
	};

export const signalCommentsUpdates =
	() =>
	async ({ getState }: StoreActionApi<State>, props: Props) => {
		const { onUpdateComments } = props;
		const state = getState();
		const issueKey = getSelectedIssue(state);
		if (issueKey !== undefined) {
			onUpdateComments?.({ issueKey });
		}
	};
