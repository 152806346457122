import React from 'react';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyAfterPaint } from '@atlassian/react-loosely-lazy';
import { useUFOComponentExperience } from '@atlassian/ufo';
import { jsmMajorIncidentExperience } from './common/ui/experience/index.tsx';
import type MajorIncidentField from './ui/index.tsx';
import type { Props } from './ui/index.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyMajorIncidentField = lazyAfterPaint<typeof MajorIncidentField>(
	() =>
		import(
			/* webpackChunkName: "async-servicedesk-incident-management-major-incident-field" */ './ui'
		),
	{ ssr: false },
);

export const AsyncMajorIncidentField = (props: Props) => {
	useUFOComponentExperience(jsmMajorIncidentExperience);
	return (
		<Placeholder name="servicedesk-incident-management-major-incident-field" fallback={null}>
			<LazyMajorIncidentField {...props} />
		</Placeholder>
	);
};
