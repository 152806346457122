import React, { type ComponentType, type ReactElement } from 'react';
import { FieldValueSubscriber as FieldValueSubscriberDI } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';

type Options = {
	fieldKey: string;
	propName: string;
	isEnabled?: (() => boolean) | undefined;
	FieldValueSubscriber?: typeof FieldValueSubscriberDI;
};

type Props = {
	issueKey: IssueKey;
};

/**
 * HOC subscriber to the fieldValue sweet-state store
 * @param fieldKey key for the field to get
 * @param propName wrapped component property to set field value to
 * @param isEnabled if returns true, hoc will add field value to the wrapped component, otherwise it will
 *                  add only passed properties. Useful when FF needs to be checked, as FF check outside of
 *                  render function breaks the SSR
 * @param FieldValueSubscriber field value subscriber DI
 * @returns Wrapped component
 */
const withFieldValue =
	<P extends Props>({
		fieldKey,
		propName,
		isEnabled = () => true,
		FieldValueSubscriber = FieldValueSubscriberDI,
	}: Options): ((WrappedComponent: ComponentType<P>) => (ownProps: P) => ReactElement) =>
	(WrappedComponent: ComponentType<P>): ((ownProps: P) => ReactElement) =>
	(ownProps: P) => {
		if (!isEnabled()) {
			return <WrappedComponent {...ownProps} />;
		}

		const { issueKey } = ownProps;
		return (
			<FieldValueSubscriber issueKey={issueKey} fieldKey={fieldKey}>
				{(fieldValue) => {
					const props = {
						...ownProps,
						[propName]: fieldValue,
					};

					return <WrappedComponent {...props} />;
				}}
			</FieldValueSubscriber>
		);
	};

export default withFieldValue;
