/**
 * @generated SignedSource<<16235fac4dcd142989d99aa9fd5272b4>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutGroupPickerField_IssueViewGroupPickerField$data = {
  readonly __typename: "JiraSingleGroupPickerField";
  readonly description: string | null | undefined;
  readonly fieldConfig: {
    readonly isEditable: boolean | null | undefined;
    readonly isRequired: boolean | null | undefined;
  } | null | undefined;
  readonly fieldId: string;
  readonly id: string;
  readonly issue: {
    readonly key: string;
  } | null | undefined;
  readonly name: string;
  readonly selectedGroup: {
    readonly groupId: string;
    readonly id: string;
    readonly name: string;
  } | null | undefined;
  readonly type: string;
  readonly " $fragmentType": "ui_issueViewLayoutGroupPickerField_IssueViewGroupPickerField";
};
export type ui_issueViewLayoutGroupPickerField_IssueViewGroupPickerField$key = {
  readonly " $data"?: ui_issueViewLayoutGroupPickerField_IssueViewGroupPickerField$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutGroupPickerField_IssueViewGroupPickerField">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "id"
},
v1 = {
  "kind": "ScalarField",
  "name": "name"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutGroupPickerField_IssueViewGroupPickerField",
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    (v1/*: any*/),
    {
      "kind": "ScalarField",
      "name": "description"
    },
    {
      "kind": "ScalarField",
      "name": "__typename"
    },
    {
      "concreteType": "JiraFieldConfig",
      "kind": "LinkedField",
      "name": "fieldConfig",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isEditable"
        },
        {
          "kind": "ScalarField",
          "name": "isRequired"
        }
      ]
    },
    {
      "concreteType": "JiraIssue",
      "kind": "LinkedField",
      "name": "issue",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "key"
        }
      ]
    },
    {
      "concreteType": "JiraGroup",
      "kind": "LinkedField",
      "name": "selectedGroup",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "kind": "ScalarField",
          "name": "groupId"
        }
      ]
    }
  ],
  "type": "JiraSingleGroupPickerField"
};
})();

(node as any).hash = "aaa5b87697a2f0abfed59bb00ef6cb79";

export default node;
