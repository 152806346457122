import { useMemo } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { fireAnalyticsEvent } from '@atlaskit/editor-common/analytics';

export function useFireAnalyticsEvent() {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	return useMemo(() => fireAnalyticsEvent(createAnalyticsEvent), [createAnalyticsEvent]);
}
