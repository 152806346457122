import { useEditField } from '@atlassian/jira-issue-field-base/src/services/edit-field-service/main.tsx';
import { useFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import { getFieldType } from '@atlassian/jira-issue-view-common-utils/src/fields/index.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type {
	CheckboxSelectValue,
	CheckboxSelectIdShape,
	CheckboxSelectIdShapeResponse,
} from './types.tsx';

export type Props = {
	issueKey: IssueKey;
	fieldKey: string;
	initialValue?: CheckboxSelectValue | null;
	onSuccess?: (value: CheckboxSelectValue | null) => void;
	onFailure?: (error: Error) => void;
};

export const useCheckboxSelectField = ({
	issueKey,
	initialValue = null,
	fieldKey,
	onSuccess,
	onFailure,
}: Props) => {
	const [fieldConfig] = useFieldConfig(issueKey, fieldKey);
	const fieldType = getFieldType(fieldConfig.value) || 'array';

	const [{ value, loading, error }, { saveValue, saveById, resetError }] = useEditField<
		CheckboxSelectValue | null,
		null,
		CheckboxSelectIdShapeResponse,
		CheckboxSelectIdShape | null
	>({
		fieldKey,
		issueKey,
		fieldType,
		initialValue,
		onSuccess,
		onFailure,
	});

	return [
		{ value, loading, error, fieldConfig },
		{ saveValue, saveById, resetError },
	] as const;
};
