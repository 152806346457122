import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	gotIt: {
		id: 'issue-attachments-base.services.got-it',
		defaultMessage: 'Got it',
		description: 'The "got it" button shows in flag',
	},
	tryAgain: {
		id: 'issue-attachments-base.services.try-again',
		defaultMessage: 'Try again',
		description: 'The button that will re-download the failed file',
	},
	downloadStartTitle: {
		id: 'issue-attachments-base.services.download-start-title',
		defaultMessage: 'Downloading attachments',
		description: 'The title for flag that says downloading is in-progress',
	},
	downloadStartDescription: {
		id: 'issue-attachments-base.services.download-start-description',
		defaultMessage:
			'This might take a while. When we’re done, your attachments will be in a zip file on your device.',
		description: 'The description for flag that says downloading is in-progress',
	},
	downloadFailedTitle: {
		id: 'issue-attachments-base.services.download-failed-title',
		defaultMessage: 'We couldn’t download your attachments',
		description: 'The title for download all attachment failed',
	},
	downloadFailedDescription: {
		id: 'issue-attachments-base.services.download-failed-description',
		defaultMessage:
			'We didn’t finish downloading your attachments for {issueKey}. Check your connection and then try again.',
		description: 'The description for download all attachment failed',
	},
	downloadFailedExceedFileSizeDescription: {
		id: 'issue-attachments-base.services.download-failed-exceed-file-size-description',
		defaultMessage:
			"The total size of {issueKey}'s attachments is too large to download. Try downloading each attachment individually.",
		description: 'The description for when download all attachment failed due to large file size',
	},
	deleteFailedTitle: {
		id: 'issue-attachments-base.services.delete-failed-title',
		defaultMessage: 'Attachment could not be deleted',
		description: 'The title for deleting an attachment failed',
	},
	deleteFailedDescription: {
		id: 'issue-attachments-base.services.delete-failed-description',
		defaultMessage:
			'There was a connection problem and the attachment was not successfully deleted. Try checking your network connection.',
		description: 'The description for deleting an attachment failed',
	},
});
