import type { ExtensionHandlers, ExtensionManifest } from '@atlaskit/editor-common/extensions';

import { RedactionMarker } from './ui/redaction-marker';

const EXTENSION_TYPE = 'com.atlassian.guard-premium';

export const getRedactionExtensionHandler: () => ExtensionHandlers = () => ({
	[EXTENSION_TYPE]: RedactionMarker,
});

export const getRedactionExtension: () => ExtensionManifest = () => {
	return {
		title: 'Redaction',
		type: EXTENSION_TYPE,
		key: 'redaction',
		description: 'Inserts redacted block.',
		icons: {
			'48': () => import('@atlaskit/icon/glyph/editor/addon'),
		},
		modules: {
			nodes: {
				default: {
					type: 'inlineExtension',
					render: async () => RedactionMarker,
				},
			},
		},
	};
};
