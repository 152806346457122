import get from 'lodash/get';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { ff } from '@atlassian/jira-feature-flagging';
import { useEditField } from '@atlassian/jira-issue-field-base/src/services/edit-field-service/main.tsx';
import { useFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import { SUMMARY_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { StringValue } from './types.tsx';
import { useSaveSummaryField } from './use-save-summary-field/index.tsx';

type Props = {
	issueId: string | undefined;
	issueKey: IssueKey;
	initialValue?: StringValue;
	onSubmit?: (
		oldValue: StringValue,
		newValue: StringValue,
		meta: unknown,
		event: UIAnalyticsEvent,
	) => void;
	onSuccess?: (arg1: StringValue) => void;
	onFailure?: (arg1: Error) => void;
	/**
	 * "onSaveField" is currently only used by Increment planning boards of Advanced Roadmaps
	 * please refer to "issue/fields/summary-components/summary-inline-edit-state/src/controllers/with-sweet-state/index.tsx"
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onSaveField?: (issueKey: IssueKey, fieldId: string, value: StringValue) => Promise<any>;
};

/** @deprecated
 * Please use RELAY to read and modify the value of the summary field.
 * This approach replaces the use of this hook, coupled with the legacy summary field backed by the Sweet State store.
 * The values from the Sweet State store are no longer up-to-date, as the field has been replaced by the RELAY alternative.
 * * */
export const useSummaryField = ({
	issueId,
	issueKey,
	initialValue,
	onSubmit,
	onSuccess,
	onFailure,
	onSaveField,
}: Props) => {
	const [fieldConfig] = useFieldConfig(issueKey, SUMMARY_TYPE);
	const fieldType = get(fieldConfig.value, 'schema.type', 'string');

	const saveField = ff('relay-migration-issue-fields-summary_eog1v')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useSaveSummaryField(issueId)
		: undefined;

	const [{ value, error }, { saveValue, resetError }] = useEditField<StringValue, null>({
		fieldKey: SUMMARY_TYPE,
		issueKey,
		fieldType,
		initialValue: initialValue || null,
		onSubmit,
		onSuccess,
		onFailure,
		...(ff('relay-migration-issue-fields-summary_eog1v') && { saveField }),
		// delete this condition and keep code inside when removing the isIncrementPlanningEnabled FF
		...(onSaveField && { saveField: onSaveField }),
	});

	return [
		{
			value,
			error,
			fieldConfig,
		},
		{ saveValue, resetError },
	] as const;
};
