// This component deals exclusively with the creation of new comments.
// For editing, there is a separate version available from @atlassian/jira-issue-comment-base

// eslint-disable-next-line jira/restricted/react-component-props
import type { ComponentProps } from 'react';
import type { CommentId } from '@atlassian/jira-issue-view-common-types/src/comment-type.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { mentionProviderSelector } from '@atlassian/jira-issue-view-services/src/mentions/mention-provider-selector.tsx';
import {
	editorExpandedFailure,
	editorSaveFailure,
} from '@atlassian/jira-issue-view-store/src/actions/editor-actions.tsx';
import { clearSharedAsCommentValues } from '@atlassian/jira-issue-view-store/src/actions/share-as-comment-actions.tsx';
import { activityProviderSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/activity-provider-selector.tsx';
import { contextIdentifierSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/comments-selector.tsx';
import { baseUrlSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import { mediaContextSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/media-context-selector.tsx';
import {
	getTimestampForSharingAsComment,
	getValueForSharingAsComment,
} from '@atlassian/jira-issue-view-store/src/selectors/share-as-comment-selector.tsx';

export type IssueCommentEditorPropsT<T> = Omit<
	// @ts-expect-error - TS2344 - Type 'LibraryManagedAttributes<T, ComponentProps<T>>' does not satisfy the constraint '{ contextIdentifier?: ContextIdentifier | undefined; mentionProvider?: Record<any, any> | null | undefined; activityProvider?: Record<any, any> | null | undefined; ... 5 more ...; clearSharedValues?: (() => void) | undefined; }'. | TS2344 - Type 'T' does not satisfy the constraint 'keyof IntrinsicElements | JSXElementConstructor<any>'.
	JSX.LibraryManagedAttributes<T, ComponentProps<T>>,
	| 'contextIdentifier'
	| 'mentionProvider'
	| 'activityProvider'
	| 'mediaContext'
	| 'sharedAtTimestamp'
	| 'sharedValue'
	| 'onExpandedFailure'
	| 'onSaveFailure'
	| 'clearSharedValues'
> & {
	commentId?: CommentId;
};

export const enhanceIssueCommentEditor = flowWithSafeComponent(
	connect(
		(state: State, props) => {
			const baseUrl = baseUrlSelector(state);
			return {
				contextIdentifier: contextIdentifierSelector(state, props),
				baseUrl,
				mentionProvider: mentionProviderSelector(state),
				activityProvider: activityProviderSelector(state),
				mediaContext: mediaContextSelector(state),
				sharedAtTimestamp: getTimestampForSharingAsComment(state),
				sharedValue: getValueForSharingAsComment(state),
				useStickyToolbar: true,
			};
		},
		(dispatch) => ({
			onExpandedFailure: () => dispatch(editorExpandedFailure()),
			onSaveFailure: () => dispatch(editorSaveFailure()),
			clearSharedValues: () => {
				dispatch(clearSharedAsCommentValues());
			},
		}),
	),
);
