/**
 * @generated SignedSource<<c4a7ee7fd169fb529965669d6c16c838>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutUrlField_IssueViewUrlField$data = {
  readonly __typename: "JiraUrlField";
  readonly fieldId: string;
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutFieldHeading_IssueViewFieldHeading" | "url_issueFieldUrlInlineEditFull_UrlInlineEditView">;
  readonly " $fragmentType": "ui_issueViewLayoutUrlField_IssueViewUrlField";
};
export type ui_issueViewLayoutUrlField_IssueViewUrlField$key = {
  readonly " $data"?: ui_issueViewLayoutUrlField_IssueViewUrlField$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutUrlField_IssueViewUrlField">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutUrlField_IssueViewUrlField",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "ui_issueViewLayoutFieldHeading_IssueViewFieldHeading"
    },
    {
      "kind": "FragmentSpread",
      "name": "url_issueFieldUrlInlineEditFull_UrlInlineEditView"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "ScalarField",
      "name": "__typename"
    }
  ],
  "type": "JiraUrlField"
};

(node as any).hash = "d9170a9881652481598c0f784869eeac";

export default node;
