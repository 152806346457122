import React, { useCallback } from 'react';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import { useIntl } from '@atlassian/jira-intl';
import { useOnboardingTour } from '@atlassian/jira-issue-onboarding-modal/src/services/onboarding-tour/main.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';

const StartTour = () => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [, { setTourIsActive }] = useOnboardingTour();

	const onClick = useCallback(() => {
		setTourIsActive();
		const analyticsEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'dropdownItem',
		});
		fireUIAnalytics(analyticsEvent, 'startChangeBoardingTour');
	}, [createAnalyticsEvent, setTourIsActive]);

	return (
		<DropdownItem
			onClick={onClick}
			key="start-tour"
			// eslint-disable-next-line jira/integration/test-id-by-folder-structure
			data-testid="issue.views.issue-base.foundation.issue-actions.issue-actions-dropdown-group.start-tour"
		>
			{formatMessage(messages.startTour)}
		</DropdownItem>
	);
};

export default StartTour;
