/**
 * @generated SignedSource<<59684492994e794d66b7f4b41850a88a>>
 * @relayHash b63cfebb7ef053f918bea39979f290fa
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID b63a12e188964014afd42c546882f38c2662478c07b37471a8f48347f37065bb

import type { ConcreteRequest, Query } from 'relay-runtime';
export type JiraAutofixStatus = "COMPLETED" | "FAILED" | "IN_PROGRESS" | "PENDING" | "%future added value";
export type uiGetAutofixJobsQuery$variables = {
  issueAri: string;
};
export type uiGetAutofixJobsQuery$data = {
  readonly jira: {
    readonly devOps: {
      readonly autofixJobs: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly errorMessage: string | null | undefined;
            readonly errorType: string | null | undefined;
            readonly status: JiraAutofixStatus;
          } | null | undefined;
        } | null | undefined> | null | undefined;
      };
    } | null | undefined;
  } | null | undefined;
};
export type uiGetAutofixJobsQuery = {
  response: uiGetAutofixJobsQuery$data;
  variables: uiGetAutofixJobsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "issueAri"
  }
],
v1 = {
  "args": [
    {
      "kind": "Variable",
      "name": "issueAri",
      "variableName": "issueAri"
    }
  ],
  "concreteType": "JiraAutofixJobConnection",
  "kind": "LinkedField",
  "name": "autofixJobs",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraAutofixJobEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraAutofixJob",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "status"
            },
            {
              "kind": "ScalarField",
              "name": "errorMessage"
            },
            {
              "kind": "ScalarField",
              "name": "errorType"
            }
          ]
        }
      ]
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "uiGetAutofixJobsQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "concreteType": "JiraDevOpsQuery",
            "kind": "LinkedField",
            "name": "devOps",
            "plural": false,
            "selections": [
              {
                "kind": "RequiredField",
                "field": (v1/*: any*/),
                "action": "THROW",
                "path": "jira.devOps.autofixJobs"
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "uiGetAutofixJobsQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "concreteType": "JiraDevOpsQuery",
            "kind": "LinkedField",
            "name": "devOps",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "b63a12e188964014afd42c546882f38c2662478c07b37471a8f48347f37065bb",
    "metadata": {},
    "name": "uiGetAutofixJobsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "b1509555c3edaf7ce18a1c913d32b61e";

export default node;
