import type { MediaError } from '@atlaskit/media-picker';
import type { Attachment } from '@atlassian/jira-issue-view-common-types/src/attachment-type.tsx';

// ATTACHMENT_MEDIA_SERVICES_UPLOAD_BEGIN
export const ATTACHMENT_MEDIA_SERVICES_UPLOAD_BEGIN =
	'ATTACHMENT_MEDIA_SERVICES_UPLOAD_BEGIN' as const;

export const attachmentUploadBegin = (attachment: Attachment) => ({
	type: ATTACHMENT_MEDIA_SERVICES_UPLOAD_BEGIN,
	payload: attachment,
});

// ATTACHMENT_MEDIA_SERVICES_UPLOAD_END
export const ATTACHMENT_MEDIA_SERVICES_UPLOAD_END = 'ATTACHMENT_MEDIA_SERVICES_UPLOAD_END' as const;

export const attachmentUploadEnd = (attachment: Attachment) => ({
	type: ATTACHMENT_MEDIA_SERVICES_UPLOAD_END,
	payload: attachment,
});

// ATTACHMENT_MEDIA_SERVICES_UPLOAD_ERROR
export const ATTACHMENT_MEDIA_SERVICES_UPLOAD_ERROR =
	'ATTACHMENT_MEDIA_SERVICES_UPLOAD_ERROR' as const;

export const attachmentUploadError = (error: MediaError) => ({
	type: ATTACHMENT_MEDIA_SERVICES_UPLOAD_ERROR,
	payload: error,
});

export type AttachmentUploadErrorAction = ReturnType<typeof attachmentUploadError>;

// ATTACHMENT_JIRA_CREATE_SUCCESS
export const ATTACHMENT_JIRA_CREATE_SUCCESS = 'ATTACHMENT_JIRA_CREATE_SUCCESS' as const;

export const attachmentJiraCreateSuccess = (attachment: Attachment) => ({
	type: ATTACHMENT_JIRA_CREATE_SUCCESS,
	payload: attachment,
});

// This action gets fired when all pending attachments being uploaded to Jira are finished.
// ATTACHMENT_JIRA_CREATE_END
export const ATTACHMENT_JIRA_CREATE_END = 'ATTACHMENT_JIRA_CREATE_END' as const;

export const attachmentJiraCreateEnd = (
	issueKey: string,
	numCreated: number,
	numFailed: number,
) => ({
	type: ATTACHMENT_JIRA_CREATE_END,
	payload: {
		issueKey,
		numCreated,
		numFailed,
	},
});

export type AttachmentJiraCreateEndAction = ReturnType<typeof attachmentJiraCreateEnd>;

// ATTACHMENT_JIRA_CREATE_FAILURE
export const ATTACHMENT_JIRA_CREATE_FAILURE = 'ATTACHMENT_JIRA_CREATE_FAILURE' as const;

export const attachmentJiraCreateFailure = (
	attachment: Attachment,
	statusCode: number,
	exceedFileSizeLimit?: boolean,
) => ({
	type: ATTACHMENT_JIRA_CREATE_FAILURE,
	payload: {
		attachment,
		statusCode,
		exceedFileSizeLimit,
	},
});

export type AttachmentJiraCreateFailureAction = ReturnType<typeof attachmentJiraCreateFailure>;

// MEDIA_PICKER_ERROR
export const MEDIA_PICKER_ERROR = 'MEDIA_PICKER_ERROR' as const;

export const mediaPickerError = (error: MediaError) => ({
	type: MEDIA_PICKER_ERROR,
	payload: error,
});

export type AttachmentPickerAction =
	| ReturnType<typeof attachmentUploadBegin>
	| ReturnType<typeof attachmentUploadEnd>
	| AttachmentUploadErrorAction
	| ReturnType<typeof attachmentJiraCreateSuccess>
	| AttachmentJiraCreateFailureAction
	| ReturnType<typeof mediaPickerError>;
