import React from 'react';
import BrowserMediaPicker from '../browser/index.tsx';
import type { EventHandlers } from '../common/with-media-picker-props-provider/index.tsx';

type Props = {
	eventHandlers?: EventHandlers;
};

export const PopupAttachmentPicker = (props: Props) => {
	const { eventHandlers } = props;

	return <BrowserMediaPicker eventHandlers={eventHandlers} />;
};

export default PopupAttachmentPicker;
