import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/switchMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import {
	CORE_PROJECT,
	SERVICE_DESK_PROJECT,
	SOFTWARE_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import {
	setCreateLinkedIssuePermission,
	type FetchCreateLinkedIssuePermissionRequest,
	FETCH_CREATE_LINKED_ISSUE_PERMISSION_REQUEST,
} from '@atlassian/jira-issue-view-store/src/actions/issue-links-actions.tsx';
import { projectTypeSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';
import { canAddIssueLinksSelector } from '@atlassian/jira-issue-view-store/src/selectors/issue-links-type-selector.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	action$: ActionsObservable<FetchCreateLinkedIssuePermissionRequest>,
	store: MiddlewareAPI<State>,
) =>
	action$.ofType(FETCH_CREATE_LINKED_ISSUE_PERMISSION_REQUEST).switchMap(() => {
		// Required permissions to see the Create linked issue button:
		//  - Source issue is a CORE or Service Desk issue or Software issue.
		//  - Source issue is not a next-gen issue.
		//  - Issue linking feature is not deactivated.
		//  - User has LINK_ISSUES permission in the current project.

		const state = store.getState();
		const projectType = projectTypeSelector(state);
		const canAddIssueLinks = canAddIssueLinksSelector(state);

		const isProjectPermissionEnabled =
			projectType === CORE_PROJECT ||
			projectType === SERVICE_DESK_PROJECT ||
			projectType === SOFTWARE_PROJECT;

		if (canAddIssueLinks && isProjectPermissionEnabled) {
			return Observable.of(setCreateLinkedIssuePermission(true));
		}

		return Observable.of(setCreateLinkedIssuePermission(false));
	});
