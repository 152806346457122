/**
 * @generated SignedSource<<506f45535620e4112729474229b7d58f>>
 * @relayHash 08c252cc5c3789c2ecbcea82e0174f13
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 0ea3a4f7efc5495f47c1aaa78caf8bccb3cd76572d3141b65719532cb23b2e60

import type { ConcreteRequest, Query } from 'relay-runtime';
export type detailsDeploymentDataDetectedQuery$variables = {
  projectAri: string;
  updatedFrom?: AGG$DateTime | null | undefined;
};
export type detailsDeploymentDataDetectedQuery$data = {
  readonly devOps: {
    readonly ariGraph: {
      readonly deploymentRelationships: {
        readonly nodes: ReadonlyArray<{
          readonly __typename: "AriGraphRelationship";
        } | null | undefined> | null | undefined;
      } | null | undefined;
    } | null | undefined;
  };
};
export type detailsDeploymentDataDetectedQuery = {
  response: detailsDeploymentDataDetectedQuery$data;
  variables: detailsDeploymentDataDetectedQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectAri"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "updatedFrom"
  }
],
v1 = {
  "concreteType": "DevOps",
  "kind": "LinkedField",
  "name": "devOps",
  "plural": false,
  "selections": [
    {
      "concreteType": "AriGraph",
      "kind": "LinkedField",
      "name": "ariGraph",
      "plural": false,
      "selections": [
        {
          "alias": "deploymentRelationships",
          "args": [
            {
              "fields": [
                {
                  "kind": "Variable",
                  "name": "from",
                  "variableName": "projectAri"
                },
                {
                  "kind": "Literal",
                  "name": "type",
                  "value": "project-associated-deployment"
                },
                {
                  "kind": "Variable",
                  "name": "updatedFrom",
                  "variableName": "updatedFrom"
                }
              ],
              "kind": "ObjectValue",
              "name": "filter"
            },
            {
              "kind": "Literal",
              "name": "first",
              "value": 1
            }
          ],
          "concreteType": "AriGraphRelationshipConnection",
          "kind": "LinkedField",
          "name": "relationships",
          "plural": false,
          "selections": [
            {
              "concreteType": "AriGraphRelationship",
              "kind": "LinkedField",
              "name": "nodes",
              "plural": true,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "__typename"
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "detailsDeploymentDataDetectedQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": (v1/*: any*/),
        "action": "THROW",
        "path": "devOps"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "detailsDeploymentDataDetectedQuery",
    "selections": [
      (v1/*: any*/)
    ]
  },
  "params": {
    "id": "0ea3a4f7efc5495f47c1aaa78caf8bccb3cd76572d3141b65719532cb23b2e60",
    "metadata": {},
    "name": "detailsDeploymentDataDetectedQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "21211370ddb0d181284a4265fae29842";

export default node;
