type IdItem = {
	id: string;
};

export const getRecentlySelectedWithoutNewSelected = <T extends IdItem>({
	selections,
	newlySelected,
}: {
	selections: T[];
	newlySelected: T;
}): T[] => selections.filter((selection) => selection.id !== newlySelected.id);
