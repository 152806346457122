/**
 * @generated SignedSource<<d77eb9fc84b20aecb90697ee7911ebab>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutMetaResolvedDateField_IssueViewResolvedDateField$data = {
  readonly dateTime: AGG$DateTime | null | undefined;
  readonly " $fragmentType": "ui_issueViewLayoutMetaResolvedDateField_IssueViewResolvedDateField";
};
export type ui_issueViewLayoutMetaResolvedDateField_IssueViewResolvedDateField$key = {
  readonly " $data"?: ui_issueViewLayoutMetaResolvedDateField_IssueViewResolvedDateField$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutMetaResolvedDateField_IssueViewResolvedDateField">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutMetaResolvedDateField_IssueViewResolvedDateField",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "dateTime"
    }
  ],
  "type": "JiraDateTimePickerField"
};

(node as any).hash = "6a930131aee2a1313fe4a8e084c7a7ab";

export default node;
