import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	descriptionCustomer: {
		id: 'servicedesk-customer-service-issue-view-panel.common.details.empty-state.description-customer',
		defaultMessage:
			'To get context on your customers, make sure you’ve <a>set up detail fields to show on the issue view</a>.',
		description:
			'Empty state description for admins when there are no detail fields on the customer details panel on the issue view.',
	},
	descriptionCustomerIssueTermRefresh: {
		id: 'servicedesk-customer-service-issue-view-panel.common.details.empty-state.description-customer-issue-term-refresh',
		defaultMessage:
			'To get context on your customers, make sure you’ve <a>set up detail fields to show on the issue view</a>.',
		description:
			'Empty state description for admins when there are no detail fields on the customer details panel on the issue view.',
	},
	descriptionOrganization: {
		id: 'servicedesk-customer-service-issue-view-panel.common.details.empty-state.description-organization',
		defaultMessage:
			'To get context on your customers’ organizations, make sure you’ve <a>set up detail fields to show on the issue view</a>.',
		description:
			'Empty state description for admins when there are no detail fields on the oragnization details panel on the issue view.',
	},
	descriptionOrganizationIssueTermRefresh: {
		id: 'servicedesk-customer-service-issue-view-panel.common.details.empty-state.description-organization-issue-term-refresh',
		defaultMessage:
			'To get context on your customers’ organizations, make sure you’ve <a>set up detail fields to show on the issue view</a>.',
		description:
			'Empty state description for admins when there are no detail fields on the oragnization details panel on the issue view.',
	},
	descriptionEntitlement: {
		id: 'servicedesk-customer-service-issue-view-panel.common.details.empty-state.description-entitlement',
		defaultMessage:
			'To get context on your customers’ entitlements, make sure you’ve <a>set up detail fields to show on the issue view</a>.',
		description:
			'Empty state description for admins when there are no detail fields on the entitlement details panel on the issue view.',
	},
	descriptionEntitlementIssueTermRefresh: {
		id: 'servicedesk-customer-service-issue-view-panel.common.details.empty-state.description-entitlement-issue-term-refresh',
		defaultMessage:
			'To get context on your customers’ entitlements, make sure you’ve <a>set up detail fields to show on the issue view</a>.',
		description:
			'Empty state description for admins when there are no detail fields on the entitlement details panel on the issue view.',
	},
	notAdminDescriptionCustomer: {
		id: 'servicedesk-customer-service-issue-view-panel.common.details.empty-state.not-admin-description-customer',
		defaultMessage:
			'To get more context on your customers here, ask an admin to create customer detail fields.',
		description:
			'Empty state description where there are no detail fields and the user does not have permission to add them.',
	},
	notAdminDescriptionOrganization: {
		id: 'servicedesk-customer-service-issue-view-panel.common.details.empty-state.not-admin-description-organization',
		defaultMessage:
			'To get more context on your customers’ organizations here, ask an admin to create organization detail fields.',
		description:
			'Empty state description where there are no detail fields and the user does not have permission to add them.',
	},
	notAdminDescriptionEntitlement: {
		id: 'servicedesk-customer-service-issue-view-panel.common.details.empty-state.not-admin-description-entitlement',
		defaultMessage:
			'To get more context on your customers’ entitlements here, ask an admin to create entitlement detail fields.',
		description:
			'Empty state description where there are no detail fields and the user does not have permission to add them.',
	},
});
