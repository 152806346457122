import React from 'react';
import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import { useStatusField } from '@atlassian/jira-issue-field-status/src/services/use-status-service/index.tsx';
import {
	useProjectKey,
	useProjectType,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import { SummaryKey } from '@atlassian/jira-providers-issue/src/model/issue-system-fields.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { AsyncQuickApprovalsPanel } from '@atlassian/jira-software-quick-approvals-panel/src/async.tsx';
import { useResource } from '@atlassian/react-resource-router';

const QuickApprovalPanelWrapper = () => {
	const { data: projectContextData } = useResource(projectContextResource);
	const issueKey = useIssueKey();
	const [summaryValue] = useFieldValue({
		issueKey,
		fieldKey: SummaryKey,
	});
	const projectKey = useProjectKey(issueKey);
	const projectType = useProjectType(projectKey);
	const [{ value: currentStatus }] = useStatusField({
		issueKey,
	});

	const isIssueDone = currentStatus?.statusCategory?.id === 3 || false;

	return projectType === SOFTWARE_PROJECT &&
		projectContextData &&
		!projectContextData?.isProjectArchived ? (
		<AsyncQuickApprovalsPanel
			projectId={projectContextData.projectId}
			isIssueDone={isIssueDone}
			issueKey={issueKey}
			summary={summaryValue}
		/>
	) : null;
};

export default QuickApprovalPanelWrapper;
