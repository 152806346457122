import React from 'react';
import { graphql, useFragment } from 'react-relay';
import { IssueViewLayoutSafeFieldWrapper } from '@atlassian/jira-issue-view-layout-safe-field-wrapper/src/ui/index.tsx';
import type { ui_issueViewLayoutSecurityLevelField_IssueViewSecurityLevelField$key } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutSecurityLevelField_IssueViewSecurityLevelField.graphql';
import { IssueViewSecurityLevelFieldView } from './field/index.tsx';

export interface IssueViewSecurityLevelFieldProps {
	securityLevelField: ui_issueViewLayoutSecurityLevelField_IssueViewSecurityLevelField$key;
}

export const IssueViewSecurityLevelField = ({
	securityLevelField,
}: IssueViewSecurityLevelFieldProps) => {
	const data = useFragment<ui_issueViewLayoutSecurityLevelField_IssueViewSecurityLevelField$key>(
		graphql`
			fragment ui_issueViewLayoutSecurityLevelField_IssueViewSecurityLevelField on JiraIssue {
				...field_issueViewLayoutSecurityLevelField_IssueViewSecurityLevelFieldView
			}
		`,
		securityLevelField,
	);

	return (
		<IssueViewLayoutSafeFieldWrapper
			packageName="jiraIssueViewLayoutSecurityLevelField"
			fieldType="security"
		>
			<IssueViewSecurityLevelFieldView securityLevelField={data} />
		</IssueViewLayoutSafeFieldWrapper>
	);
};
