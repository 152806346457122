import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	looksGood: {
		id: 'canned-comments.common.looks-good',
		defaultMessage: 'Looks good!',
		description: 'Canned comment for looks good',
	},
	needHelp: {
		id: 'canned-comments.common.need-help',
		defaultMessage: 'Need help?',
		description: 'Canned comment for need help',
	},
	blocker: {
		id: 'canned-comments.common.blocker',
		defaultMessage: 'This is blocked ',
		description: "Canned comment for blocker'",
	},
	clarify: {
		id: 'canned-comments.common.clarify',
		defaultMessage: 'Can you clarify ',
		description: 'Canned comment for clarification',
	},
	onTrack: {
		id: 'canned-comments.common.on-track',
		defaultMessage: 'This is on track',
		description: 'Canned comment for on track',
	},
	takingALook: {
		id: 'canned-comments.common.taking-a-look',
		defaultMessage: 'Taking a look',
		description: 'Canned comment for taking a Look',
	},
	onIt: {
		id: 'canned-comments.common.on-it',
		defaultMessage: "I'm on it",
		description: "Canned comment for I'm on it",
	},
	looksGoodButton: {
		id: 'canned-comments.common.looks-good-button',
		defaultMessage: '🎉 Looks good!',
		description: 'Canned comment for great work',
	},
	needHelpButton: {
		id: 'canned-comments.common.need-help-button',
		defaultMessage: '👋 Need help?',
		description: 'Canned comment for need help',
	},
	blockerButton: {
		id: 'canned-comments.common.blocker-button',
		defaultMessage: '⛔ This is blocked...',
		description: "Canned comment for blocker'",
	},
	clarifyButton: {
		id: 'canned-comments.common.clarify-button',
		defaultMessage: '🔍 Can you clarify...?',
		description: 'Canned comment for clarification',
	},
	onTrackButton: {
		id: 'canned-comments.common.on-track-button',
		defaultMessage: '✅ This is on track',
		description: 'Canned comment for on track',
	},
	takingALookButton: {
		id: 'canned-comments.common.taking-a-look-button',
		defaultMessage: '👋 Taking a look',
		description: 'Canned comment for taking a look',
	},
	onItButton: {
		id: 'canned-comments.common.on-it-button',
		defaultMessage: "✅ I'm on it",
		description: "Canned comment for I'm on it",
	},
	moreOptions: {
		id: 'canned-comments.common.more-options',
		defaultMessage: 'More options',
		description: 'Label for more options button',
	},
	buttonGroupLabel: {
		id: 'canned-comments.common.button-group-label',
		defaultMessage: 'Quick comments',
		description: 'Label for canned comments button group',
	},
});
