import { createSelector } from 'reselect';
import { isEpicHierarchyLevel } from '@atlassian/jira-issue-type-hierarchies/src/index.tsx';
import { EPIC } from '@atlassian/jira-issue-view-configurations/src/index.tsx';
import { fieldPendingValueSelector } from '../../../common/state/selectors/field-selector.tsx';
import { isEpicSelector } from '../../../common/state/selectors/issue-selector.tsx';
import { hierarchyLevelSelector } from './hierarchy-level-selector.tsx';

export const epicSelector = createSelector(fieldPendingValueSelector(EPIC), (epicField) =>
	epicField !== null ? epicField : undefined,
);

export const isClassicOrNextGenEpicSelector = createSelector(
	isEpicSelector,
	hierarchyLevelSelector,
	(isClassicEpic, issueHierarchyLevel) =>
		isClassicEpic || isEpicHierarchyLevel(issueHierarchyLevel),
);
