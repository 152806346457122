import React, { type ReactElement } from 'react';
import { ButtonGroup } from '@atlaskit/button';
import Button from '@atlaskit/button/new';
import { Flex, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import messages from '../../messages.tsx';

export type Props = {
	isInternal: boolean;
	onSave: () => void;
	onCancel: () => void;
	secondaryToolbarComponents?: ReactElement | ReactElement[];
};

export const CustomSecondaryToolbar = ({
	isInternal,
	onSave,
	onCancel,
	secondaryToolbarComponents,
}: Props) => {
	const { formatMessage } = useIntl();

	return (
		<Flex
			xcss={actionsStyles}
			testId="issue-view-servicedesk-editor-container.customer-service.custom-secondary-toolbar.flex"
		>
			<ButtonGroup>
				<Button onClick={onSave} appearance="primary">
					{formatMessage(isInternal ? messages.addNote : messages.replyToCustomer)}
				</Button>
				<Button onClick={onCancel} appearance="subtle">
					{formatMessage(messages.cancel)}
				</Button>
			</ButtonGroup>
			{secondaryToolbarComponents && (
				<Flex gap="space.050">
					{Array.isArray(secondaryToolbarComponents)
						? secondaryToolbarComponents.map((Component) => Component)
						: secondaryToolbarComponents}
				</Flex>
			)}
		</Flex>
	);
};

const actionsStyles = xcss({
	marginTop: 'space.150',
	marginBottom: 'space.150',
	justifyContent: 'space-between',
});
