import { useCallback } from 'react';
import { useCommandPaletteSessionId } from '@atlassian/jira-command-palette/src/controllers/command-palette/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useFlagsService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import type { UserValue } from '@atlassian/jira-issue-field-assignee/src/common/types.tsx';
import useAssigneeField from '@atlassian/jira-issue-field-assignee/src/services/use-assignee-field/index.tsx';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { messages } from './messages.tsx';

export const useSaveAssigneeField = (issueKey: string) => {
	const { formatMessage } = useIntl();

	const [{ commandPaletteSessionId }] = useCommandPaletteSessionId();
	const { showFlag } = useFlagsService();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onSaveSuccessfully = useCallback(
		(user: UserValue) => {
			const action = 'commandPalette';
			const actionSubject = 'assignIssue';
			const eventActionAndSubject = `${actionSubject} ${action}`;

			const event = createAnalyticsEvent({
				action,
				actionSubject,
			});

			fireTrackAnalytics(event, eventActionAndSubject, {
				assigneeId: user?.accountId,
				commandPaletteSessionId,
			});

			showFlag({
				type: 'success',
				title: formatMessage(messages.successFlagMessage),
			});
		},
		[commandPaletteSessionId, createAnalyticsEvent, formatMessage, showFlag],
	);

	const onFailSave = useCallback(
		(err: Error) => {
			fireErrorAnalytics({
				error: err,
				meta: {
					id: 'commandPaletteAssignIssue',

					packageName: 'jiraIssueView',

					teamName: 'deliveroo',
				},
			});
			showFlag({
				type: 'error',
				title: formatMessage(messages.errorFlagTitle),
				description: formatMessage(messages.errorFlagDescription),
			});
		},
		[formatMessage, showFlag],
	);

	const [, { saveValue: saveAssigneeField }] = useAssigneeField({
		issueKey,
		onSuccess: onSaveSuccessfully,
		onFailure: onFailSave,
	});

	return { saveAssigneeField };
};
