import React from 'react';
import Tooltip from '@atlaskit/tooltip';
import {
	type DeploymentState,
	type EnvironmentType,
	deploymentStates as deploymentModelStates,
	deploymentEnvironmentTypes,
} from '@atlassian/jira-development-common/src/model/releases-data.tsx';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import { DeploymentIcons } from '@atlassian/jira-software-deployments-icons-common/src/ui/index.tsx';
import {
	type DeploymentState as DeploymentIconState,
	DeploymentStateOptions,
} from '@atlassian/jira-software-deployments-icons-common/src/ui/types.tsx';
import messages from './messages.tsx';

type DeploymentStates = {
	messageKey: string;
};
const deploymentStates: Record<DeploymentState, DeploymentStates> = {
	PENDING: {
		messageKey: 'pending',
	},
	IN_PROGRESS: {
		messageKey: 'inProgress',
	},
	SUCCESSFUL: {
		messageKey: 'successful',
	},
	FAILED: {
		messageKey: 'failed',
	},
	ROLLED_BACK: {
		messageKey: 'rolledBack',
	},
	CANCELLED: {
		messageKey: 'cancelled',
	},
	UNKNOWN: {
		messageKey: 'unknown',
	},
};

const stateMessage = (key: DeploymentState): MessageDescriptor =>
	// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ unknown: MessageDescriptorV2; cancelled: MessageDescriptorV2; rolledBack: MessageDescriptorV2; failed: MessageDescriptorV2; successful: MessageDescriptorV2; inProgress: MessageDescriptorV2; pending: MessageDescriptorV2; }'. | TS2532 - Object is possibly 'undefined'.
	messages[(deploymentStates[key] || deploymentStates.UNKNOWN).messageKey];

const getDeploymentIconState = (
	state: DeploymentState,
	environmentType: EnvironmentType,
): DeploymentIconState => {
	switch (state) {
		case deploymentModelStates.SUCCESSFUL:
			return environmentType === deploymentEnvironmentTypes.PRODUCTION
				? DeploymentStateOptions.SUCCESSFUL
				: DeploymentStateOptions.SUCCESSFULNONPRODUCTION;
		default:
			return state;
	}
};

const getDeploymentIcon = (state: DeploymentState, environmentType: EnvironmentType) => (
	<DeploymentIcons deploymentState={getDeploymentIconState(state, environmentType)} size="medium" />
);

type Props = {
	state?: DeploymentState;
	environmentType?: EnvironmentType;
};

const Status = ({ state, environmentType }: Props) => {
	const { formatMessage } = useIntl();

	if (!state) {
		return null;
	}

	const StatusIcon = getDeploymentIcon(
		state,
		environmentType ?? deploymentEnvironmentTypes.UNMAPPED,
	);

	return <Tooltip content={formatMessage(stateMessage(state))}>{StatusIcon}</Tooltip>;
};

export default Status;
