import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import {
	BRANCH_PANEL_DATA_TYPE,
	BUILD_PANEL_DATA_TYPE,
	COMMIT_PANEL_DATA_TYPE,
	DEPLOYMENT_PANEL_DATA_TYPE,
	PULLREQUEST_PANEL_DATA_TYPE,
	FEATURE_FLAGS_PANEL_DATA_TYPE,
	REMOTE_LINKS_PANEL_DATA_TYPE,
} from '../../../common/model/constants.tsx';
import {
	setSelectedApplicationType,
	type Action,
	CHANGE_TAB,
} from '../../state/actions/ui/index.tsx';
import type { State } from '../../state/reducers/types.tsx';
import { getApplicationTypes } from '../../state/selectors/summary/index.tsx';
import { getActiveTab } from '../../state/selectors/ui/index.tsx';

export const epic = (action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
	action$.ofType(CHANGE_TAB).mergeMap(() => {
		const state = store.getState();
		const activeTab = getActiveTab(state);
		switch (activeTab) {
			case BRANCH_PANEL_DATA_TYPE:
			case COMMIT_PANEL_DATA_TYPE:
			case PULLREQUEST_PANEL_DATA_TYPE: {
				const applicationTypes = getApplicationTypes(activeTab)(state);
				return Observable.of(setSelectedApplicationType(applicationTypes[0]));
			}
			case REMOTE_LINKS_PANEL_DATA_TYPE:
			case FEATURE_FLAGS_PANEL_DATA_TYPE: {
				return Observable.empty<never>();
			}
			case BUILD_PANEL_DATA_TYPE:
			case DEPLOYMENT_PANEL_DATA_TYPE: {
				/* Why hardcode 'bamboo'? This is set to retrieve the error messages within instance types and only
				 * bamboo can have errors from dev status. This is different from commits/branches/pullrequests tab
				 * because we show each instance type and its error message within its own sub tab, but for builds and
				 * deployments, we need to combine Bamboo and Cloud Data into single view and show errors only from Bamboo. */
				return Observable.of(setSelectedApplicationType('bamboo'));
			}

			default:
				throw new Error(`Unsupported tab type: ${activeTab}`);
		}
	});
