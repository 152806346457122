import type { ProjectType } from '@atlassian/jira-common-constants/src/index.tsx';
import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { toQuotedString, createIssuesUrl } from '@atlassian/jira-common-jql/src/helpers.tsx';
import type { SelectableValueOption } from '@atlassian/jira-issue-selectable-field-edit-view/src/ui/types.tsx';
import type { Version } from '@atlassian/jira-issue-shared-types/src/common/types/issue-type.tsx';
import {
	AFFECTS_VERSIONS,
	FIX_VERSIONS,
} from '@atlassian/jira-issue-view-configurations/src/index.tsx';
import type { ProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';

// JQL search keys don't actually match the field keys
const fieldKeyToJQLMap = {
	[AFFECTS_VERSIONS]: 'affectedVersion',
	[FIX_VERSIONS]: 'fixVersion',
} as const;
// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ readonly versions: "affectedVersion"; readonly fixVersions: "fixVersion"; }'.
const getJqlKey = (fieldKey: string) => fieldKeyToJQLMap[fieldKey] || 'fixVersion';

export const getVersionUrl = ({
	version,
	fieldId,
	projectKey,
	projectType,
}: {
	version: Version;
	fieldId: string;
	projectKey: ProjectKey;
	projectType?: ProjectType;
}) => {
	if (
		// Version details page is only available in Software projects.
		projectType === SOFTWARE_PROJECT &&
		// Version details page only shows issues that are *fixed* in a version, not those that *affect* it.
		// Therefore it only makes sense to link to the version details page from the fix version field.
		fieldId === FIX_VERSIONS
	) {
		return `/projects/${projectKey}/versions/${version.id}/tab/release-report-all-issues`;
	}
	// Use an issue search URL as a fallback for cases where the version details page doesn't make sense.
	const jql = `project = ${toQuotedString(projectKey)} AND ${getJqlKey(fieldId)} = ${toQuotedString(version.name)}`;
	return createIssuesUrl(jql);
};

const sortOrder = ['RECENT', 'UNRELEASED', 'RELEASED'];
export const sortOptions = (options: SelectableValueOption[]) =>
	options.sort(
		(a: SelectableValueOption, b: SelectableValueOption) =>
			sortOrder.indexOf(a?.selectableGroupKey || '') -
			sortOrder.indexOf(b?.selectableGroupKey || ''),
	);

export const MAX_OPTIONS_PER_CATEGORY = 20;
export const limitOptionPerCategory = (
	options: SelectableValueOption[],
): SelectableValueOption[] => {
	const limitedOptions: SelectableValueOption[] = [];
	const optionsCountPerGroup: Record<string, number> = {};

	for (const option of options) {
		const groupKey = option.selectableGroupKey || '';
		if (optionsCountPerGroup[groupKey] === undefined) {
			optionsCountPerGroup[groupKey] = 0;
		}

		if (optionsCountPerGroup[groupKey] < MAX_OPTIONS_PER_CATEGORY) {
			limitedOptions.push(option);
		}

		optionsCountPerGroup[groupKey]++;
	}

	return limitedOptions;
};
