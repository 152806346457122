import broadcastConnectEvent from '@atlassian/jira-connect-utils/src/common/utils/broadcast-connect-event.tsx';
import type { ChangeEvent } from '@atlassian/jira-issue-ecosystem/src/types.tsx';

export const EVENT_NAME = 'ISSUE_CHANGED';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const targetSpecNoDialogs = (registration: any) =>
	registration?.extension?.options?.moduleType !== 'dialogs';

export const emitIssueChangedEvent = (eventDetails: ChangeEvent) => {
	broadcastConnectEvent(EVENT_NAME, targetSpecNoDialogs || {}, eventDetails);
};
