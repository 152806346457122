import { fetchQuery, graphql } from 'react-relay';
import { createOperationDescriptor } from 'relay-runtime';
import type { Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import getRelayEnvironment from '@atlassian/jira-relay-environment/src/index.tsx';
import servicesMainJiraDevSummaryDevInfoSummaryQuery, {
	type servicesMainJiraDevSummaryDevInfoSummaryQuery as servicesMainJiraDevSummaryDevInfoSummaryQueryType,
} from '@atlassian/jira-relay/src/__generated__/servicesMainJiraDevSummaryDevInfoSummaryQuery.graphql';
import { transformSummary, type TransformedSummary } from './utils.tsx';

export const fetchDevSummaryData = (issueAri: Ari): Promise<TransformedSummary> => {
	/* eslint-disable @atlassian/relay/unused-fields */
	const query = graphql`
		query servicesMainJiraDevSummaryDevInfoSummaryQuery($issueId: ID!) {
			jira @required(action: THROW) {
				devOps @required(action: THROW) {
					devOpsIssuePanel(issueId: $issueId) @required(action: THROW) {
						__id
						hasBranchCapabilities
						panelState
						devOpsIssuePanelBanner
						devSummaryResult {
							devSummary @required(action: THROW) {
								branch @required(action: THROW) {
									overall @required(action: THROW) {
										count
										lastUpdated
									}
									summaryByProvider @required(action: THROW) {
										providerId
										name
										count
									}
								}
								commit @required(action: THROW) {
									overall @required(action: THROW) {
										count
										lastUpdated
									}
									summaryByProvider @required(action: THROW) {
										providerId
										name
										count
									}
								}
								pullrequest @required(action: THROW) {
									overall @required(action: THROW) {
										count
										lastUpdated
										state
										stateCount
										open
									}
									summaryByProvider @required(action: THROW) {
										providerId
										name
										count
									}
								}
								build @required(action: THROW) {
									overall @required(action: THROW) {
										count
										lastUpdated
										failedBuildCount
										successfulBuildCount
										unknownBuildCount
									}
									summaryByProvider @required(action: THROW) {
										providerId
										name
										count
									}
								}
								review @required(action: THROW) {
									overall @required(action: THROW) {
										count
										lastUpdated
										state
										stateCount
									}
									summaryByProvider @required(action: THROW) {
										providerId
										name
										count
									}
								}
								deploymentEnvironments @required(action: THROW) {
									overall @required(action: THROW) {
										count
										topEnvironments @required(action: THROW) {
											title
										}
									}
									summaryByProvider @required(action: THROW) {
										providerId
										name
										count
									}
								}
							}
							errors {
								message
								instance {
									name
									type
									baseUrl
								}
							}
							configErrors {
								message
								instance {
									name
									type
									baseUrl
								}
							}
						}
					}
				}
			}
		}
	`;
	/* eslint-enable @atlassian/relay/unused-fields */

	const variables = {
		issueId: issueAri,
	};
	const request = fetchQuery<servicesMainJiraDevSummaryDevInfoSummaryQueryType>(
		getRelayEnvironment(),
		query,
		variables,
		{
			fetchPolicy: 'store-or-network',
		},
	);
	/* fetchQuery will NOT retain the data for the query, meaning that it is not guaranteed that the
     data will remain saved in the Relay store at any point after the request completes. We need to
     explicitly retain the operation to ensure it doesn't get deleted. See https://relay.dev/docs/api-reference/fetch-query/#behavior */
	getRelayEnvironment().retain(
		createOperationDescriptor(servicesMainJiraDevSummaryDevInfoSummaryQuery, variables),
	);
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	return request.toPromise().then((summary) => transformSummary(summary!));
};
