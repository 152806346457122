import React, { type ComponentType, type ReactElement } from 'react';
import EditExperienceTrackerImplementation from '@atlassian/jira-common-experience-tracking/src/editing/view/consumer/index.tsx';
import EditExperienceTrackingProviderImplementation from '@atlassian/jira-common-experience-tracking/src/editing/view/provider/index.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import {
	applicationSelector,
	applicationEditionSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/application-selector.tsx';
import { analyticsSourceSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import { isFieldEditingSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector.tsx';
import { getComponentDisplayName } from '../common/index.tsx';

// Edit experience tracking provider

export const EditExperienceTrackingProvider = connect(
	(state: State) => ({
		experience: 'editIssue',
		application: applicationSelector(state),
		edition: applicationEditionSelector(state),
		analyticsSource: analyticsSourceSelector(state),
	}),
	{},
	// @ts-expect-error - TS2345 - Argument of type 'typeof ExperienceTrackingProvider' is not assignable to parameter of type 'Component<{ experience: string; application: ProjectApplication | null; edition: ApplicationEdition | null; analyticsSource: string | undefined; }>'.
)(EditExperienceTrackingProviderImplementation);

// Edit experience tracker

type EditExperienceTrackerProps = {
	fieldId: string;
};

const EditExperienceTracker = connect(
	(state: State, ownProps: EditExperienceTrackerProps) => ({
		field: ownProps.fieldId,
		isEditing: isFieldEditingSelector(ownProps.fieldId)(state),
	}),
	{},
)(EditExperienceTrackerImplementation);

export const withEditExperienceTracker =
	(fieldId: string) =>
	<T,>(ComponentBeingTracked: ComponentType<T>): ((props: T) => ReactElement) => {
		const WithEditExperienceTracker = (props: T) => (
			<EditExperienceTracker fieldId={fieldId}>
				{/* @ts-expect-error - TS2322 - Type 'T' is not assignable to type 'IntrinsicAttributes & T & { children?: ReactNode; }'. */}
				<ComponentBeingTracked {...props} />
			</EditExperienceTracker>
		);

		WithEditExperienceTracker.displayName = `WithEditExperienceTracker(${getComponentDisplayName(
			ComponentBeingTracked,
		)})`;

		return WithEditExperienceTracker;
	};
