import SingleLineTextInlineEditView from '@atlassian/jira-issue-internal-field-single-line-text/src/single-line-text-inline-edit-view.tsx';
import { genericMessages } from '@atlassian/jira-issue-view-common-constants/src/context-items-messages.tsx';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button/index.tsx';
import connectField from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-field.tsx';

export default connectField((stateOnMount, ownPropsOnMount) => ({
	fieldId: ownPropsOnMount.fieldId,
	additionalProps: (state, intl) => ({
		noValueText: intl.formatMessage(genericMessages.noValue),
		type: 'url',
		showPinButton: getShowPinButton(ownPropsOnMount.area),
		showEditIcon: ownPropsOnMount.showEditIcon,
	}),
}))(SingleLineTextInlineEditView);
