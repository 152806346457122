import React from 'react';
import { graphql, useFragment } from 'react-relay';
import { useIntl } from '@atlassian/jira-intl';
import {
	SingleUserPickerInlineEditView,
	SingleUserPickerInlineEditViewWithIsEditable,
} from '@atlassian/jira-issue-field-single-user-picker-inline-edit-full/src/ui/single-user-picker/index.tsx';
import { REPORTED_ISSUE_ACTION } from '@atlassian/jira-profilecard-next/src/common/constants.tsx';
import type { reporter_issueFieldReporterInlineEditFull_ReporterInlineEditView_fragmentRef$key as ReporterFieldFragment } from '@atlassian/jira-relay/src/__generated__/reporter_issueFieldReporterInlineEditFull_ReporterInlineEditView_fragmentRef.graphql';
import type { reporter_issueFieldReporterInlineEditFull_ReporterInlineEditViewWithIsEditable_fragmentRef$key as ReporterIsEditableFieldFragment } from '@atlassian/jira-relay/src/__generated__/reporter_issueFieldReporterInlineEditFull_ReporterInlineEditViewWithIsEditable_fragmentRef.graphql';
import messages from './messages.tsx';
import type {
	ReporterInlineEditViewWithIsEditableProps,
	ReporterInlineEditViewProps,
} from './types.tsx';

/**
 * Inline edit will handle the switching behaviour between the 'readView' and 'editView' components. This variant allows
 * consumers to define their own value to determine whether the field is editable.
 *
 * In most cases consumers should use `ReporterInlineEditView` which will enforce that the user has permission to
 * edit the field within the issue view. However, this component can be used for experiences that have differing
 * permissions or want finer control for how this data is retrieved, e.g. lazy loading editability.
 *
 * @param props [ReporterInlineEditViewWithIsEditableProps](./types.tsx)
 */
export const ReporterInlineEditViewWithIsEditable = ({
	fragmentRef,
	...props
}: ReporterInlineEditViewWithIsEditableProps) => {
	const { formatMessage } = useIntl();
	const data = useFragment<ReporterIsEditableFieldFragment>(
		graphql`
			fragment reporter_issueFieldReporterInlineEditFull_ReporterInlineEditViewWithIsEditable_fragmentRef on JiraSingleSelectUserPickerField {
				...singleUserPicker_issueFieldSingleUserPickerInlineEditFull_SingleUserPickerInlineEditViewWithIsEditable_fragmentRef
			}
		`,
		fragmentRef,
	);

	return (
		<SingleUserPickerInlineEditViewWithIsEditable
			{...props}
			fragmentRef={data}
			noneOptionLabel={formatMessage(messages.anonymous)}
			profileCardAction={REPORTED_ISSUE_ACTION}
		/>
	);
};

/**
 * Inline edit will handle the switching behaviour between the 'readView' and 'editView' components.
 *
 * @param props [ReporterInlineEditViewProps](./types.tsx)
 */
export const ReporterInlineEditView = ({ fragmentRef, ...props }: ReporterInlineEditViewProps) => {
	const { formatMessage } = useIntl();
	const data = useFragment<ReporterFieldFragment>(
		graphql`
			fragment reporter_issueFieldReporterInlineEditFull_ReporterInlineEditView_fragmentRef on JiraSingleSelectUserPickerField {
				...singleUserPicker_issueFieldSingleUserPickerInlineEditFull_SingleUserPickerInlineEditView_fragmentRef
			}
		`,
		fragmentRef,
	);

	return (
		<SingleUserPickerInlineEditView
			{...props}
			fragmentRef={data}
			noneOptionLabel={formatMessage(messages.anonymous)}
			profileCardAction={REPORTED_ISSUE_ACTION}
		/>
	);
};
