import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	commandPaletteSearchResultListBoxLabel: {
		id: 'command-palette.common.command-palette-search-dropdown.command-palette-search-result-list-wrapper.command-palette-search-result-list-box-label',
		defaultMessage: 'List of commands',
		description:
			'Accessibility attribute "aria-label" for the list of commands in the command palette search dropdown',
	},
});
