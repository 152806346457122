import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	successFlagCreation: {
		id: 'issue-view-base.context.versions-with-relay-edit-view.success-flag-creation',
		defaultMessage: '{name} created.',
		description:
			'The description of the flag that is displayed when creating a version is successful.',
	},
	createNewIcon: {
		id: 'issue-view-base.context.versions-with-relay-edit-view.create-new-icon',
		defaultMessage: 'Create new version icon',
		description: 'Accesibility label for the create version icon',
	},
	createNewItem: {
		id: 'issue-view-base.context.versions-with-relay-edit-view.create-new-item',
		defaultMessage: 'Create new version',
		description: 'Label for the create new version button',
	},
	recentVersions: {
		id: 'issue-view-base.context.versions-with-relay-edit-view.recent-versions',
		defaultMessage: 'Recent versions',
		description: 'Label for the recent versions group',
	},
});
