const messages = {
	emptyStateMessage: {
		id: 'automation-platform.manual-rules-empty-state.empty-state-message-new',
		defaultMessage:
			'Create an automation to perform tasks with the click of a button. Once created, manually triggered automations will appear here.',
		description: 'The message to show when there is no manual rule',
	},
	addManuallyTriggeredAutomation: {
		id: 'automation-platform.manual-rules-empty-state.add-manually-triggered-automation',
		defaultMessage: 'Add manually triggered automation',
		description: 'Link to the page to create automations',
	},
	seeTemplates: {
		id: 'automation-platform.manual-rules-empty-state.see-templates',
		defaultMessage: 'See templates',
		description: 'Link to the page to see the automation templates',
	},
	createAutomationsButtonTitle: {
		id: 'automation-platform.manual-rules-empty-state.create-automations-button-title',
		defaultMessage: 'You must be a project admin to create automations',
		description: 'The title to show for create automations button',
	},
} as const;

export default messages;
