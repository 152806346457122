import { createStore, createHook, type StoreActionApi } from '@atlassian/react-sweet-state';
import type { FieldType } from '../types.tsx';

type State = {
	isModalOpen: boolean;
	fieldType: FieldType | undefined;
};

const actions = {
	openModal:
		() =>
		({ setState }: StoreActionApi<State>) => {
			setState({ isModalOpen: true });
		},
	closeModal:
		() =>
		({ setState }: StoreActionApi<State>) => {
			setState({ isModalOpen: false });
		},
	setFieldType:
		(fieldType: FieldType) =>
		({ setState }: StoreActionApi<State>) => {
			setState({ fieldType });
		},
} as const;

export const Store = createStore<State, typeof actions>({
	initialState: { isModalOpen: false, fieldType: undefined },
	actions,
	name: 'hard-limit-exceed-modal-store',
});

export const useHardLimitsContactAdminModal = createHook(Store);
