import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

export type LabelProps = {
	htmlFor?: string;
	children: React.ReactNode;
};

export const Label = ({ htmlFor, children }: LabelProps) => (
	<Box as="label" htmlFor={htmlFor} xcss={labelStyles}>
		{children}
	</Box>
);

const labelStyles = xcss({
	font: token('font.body.UNSAFE_small'),
	fontWeight: token('font.weight.medium'),
	color: 'color.text.subtle',
	overflowWrap: 'anywhere',
});
