import type { CloneModalState } from '@atlassian/jira-issue-view-common-types/src/clone-modal-type.tsx';
import {
	SHOW_CLONE_MODAL,
	HIDE_CLONE_MODAL,
	type CloneModalAction,
} from '../actions/clone-modal-actions.tsx';

const initialState = { isOpen: false } as const;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: CloneModalState = initialState, action: CloneModalAction) => {
	const { type } = action;
	switch (type) {
		case SHOW_CLONE_MODAL:
			return {
				isOpen: true,
			};
		case HIDE_CLONE_MODAL:
			return {
				isOpen: false,
			};
		default:
			return state;
	}
};
