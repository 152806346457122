import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import type { AssociatedIssuesContextActions } from '@atlassian/jira-associated-issues-context-service/src/actions.tsx';
import type { IssueContextServiceActions } from '@atlassian/jira-issue-context-service/src/types.tsx';
import type { FieldConfigServiceActions } from '@atlassian/jira-issue-field-base/src/services/field-config-service/types.tsx';
import type { FieldValueServiceActions } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import type { IssueViewNonCriticalDataActions } from '@atlassian/jira-issue-non-critical-gira-service/src/controllers/use-issue-view-non-critical-data/types.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { storeFields } from '@atlassian/jira-issue-view-common-utils/src/utils/fields-extraction.tsx';
import { getAssociatedIssuesContext } from '@atlassian/jira-issue-view-common-utils/src/utils/get-associated-issues-context.tsx';
import {
	CHILDREN_ISSUES,
	EPIC_ISSUES,
	PORTFOLIO_CHILD_ISSUES,
	SUBTASKS,
} from '@atlassian/jira-issue-view-configurations/src/index.tsx';
import type { DynamicIssueQueryParams } from '@atlassian/jira-issue-view-services/src/issue/gira/graphql/index.tsx';
import { fetchDynamicAppDataWithRetries } from '@atlassian/jira-issue-view-services/src/issue/issue-fetch-server.tsx';
import { refreshIssueSuccess } from '@atlassian/jira-issue-view-store/src/common/actions/issue-fetch-actions.tsx';
import { issueKeySelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import type { WorkflowTransitionsActions } from '@atlassian/jira-issue-workflow-transitions-services/src/types.tsx';

const getRefreshedAppDataAndStoreFields = (
	state: State,
	dynamicIssueQueryParams: DynamicIssueQueryParams | null | undefined,
	fieldValueActions: FieldValueServiceActions,
	fieldsConfigActions: FieldConfigServiceActions,
	workflowTransitionsActions: WorkflowTransitionsActions,
	associatedIssuesContextActions?: AssociatedIssuesContextActions,
	issueContextActions?: IssueContextServiceActions,
	issueViewNonCriticalDataActions?: IssueViewNonCriticalDataActions,
) => {
	const issueKey = issueKeySelector(state);
	issueViewNonCriticalDataActions?.fetchNonCriticalData(issueKey);

	// @ts-expect-error - TS2345 - Argument of type 'DynamicIssueQueryParams | null | undefined' is not assignable to parameter of type 'DynamicIssueQueryParams | undefined'.
	return fetchDynamicAppDataWithRetries(state, dynamicIssueQueryParams)
		.do((data) => {
			const { childIssuesLimit } = data;
			const { fields } = data.issue;
			storeFields(fields, fieldValueActions, fieldsConfigActions, issueKey, data.project);
			workflowTransitionsActions.setTransitionInProgress(false);

			if (associatedIssuesContextActions) {
				const { childrenIssues, subtasks, issueLinks } = data;
				associatedIssuesContextActions.mergeLocalAssociatedIssuesContext(
					getAssociatedIssuesContext([
						...childrenIssues,
						...subtasks,
						...(issueLinks?.linkedIssueMetas || []),
					]),
				);
			}
			if (issueContextActions) {
				issueContextActions.mergeIssueContext({
					childIssuesLimit,
					childIssuesIssueLimitUrls: {
						[SUBTASKS]: fields[SUBTASKS]?.issueLimitUrl,
						[CHILDREN_ISSUES]: fields[CHILDREN_ISSUES]?.issueLimitUrl,
						[EPIC_ISSUES]: fields[EPIC_ISSUES]?.issueLimitUrl,
						[PORTFOLIO_CHILD_ISSUES]: fields[PORTFOLIO_CHILD_ISSUES]?.issueLimitUrl,
					},
				});
			}

			return data;
		})
		.map(refreshIssueSuccess);
};
export default getRefreshedAppDataAndStoreFields;
