import React from 'react';
import AKTooltip from '@atlaskit/tooltip';
import QuickAddItemView from '../../quick-add-item/view.tsx';
import type { CreateItemViewType, QuickAddItemToRender } from '../../types.tsx';

const getItemListCreator = ({
	isQuickActionsListView,
	sendAnalyticsEvent,
	shouldBeCompact,
	actionRefs,
}: {
	isQuickActionsListView: boolean;
	sendAnalyticsEvent: (id: string, itemIndex: number, handleFunc: () => void) => () => void;
	shouldBeCompact: boolean;
	actionRefs: { actionId: string; ref: React.RefObject<HTMLElement> }[];
}): CreateItemViewType => {
	return (itemToRender: QuickAddItemToRender) => {
		const {
			id,
			tooltip,
			label,
			icon,
			onClick,
			itemIndex,
			testId,
			hasGlobalSpotlightTarget,
			hasSpotlightPulse,
			noWrapper,
		} = itemToRender;

		const actionItemRef = actionRefs.find((actionRef) => actionRef.actionId === id)?.ref;

		const quickAddItemViewComponent = (
			<QuickAddItemView
				key={id}
				id={id}
				icon={icon}
				onClick={sendAnalyticsEvent(id, itemIndex, onClick)}
				label={label}
				compactMode={shouldBeCompact}
				isQuickActionsListView={isQuickActionsListView}
				testId={testId}
				hasGlobalSpotlightTarget={hasGlobalSpotlightTarget}
				hasSpotlightPulse={hasSpotlightPulse}
				noWrapper={noWrapper}
				{...(actionItemRef && { ref: actionItemRef })}
			/>
		);

		if (isQuickActionsListView) {
			return quickAddItemViewComponent;
		}

		return (
			<AKTooltip content={tooltip} position="bottom" key={id}>
				{quickAddItemViewComponent}
			</AKTooltip>
		);
	};
};

export default getItemListCreator;
