import React from 'react';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import type { Area } from '@atlassian/jira-issue-view-common-types/src/connect-field-type.tsx';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { RespondersGlance } from '@atlassian/jira-responders/src/async.tsx';
import type { Props } from './types.tsx';

export const RespondersGlanceContainer = ({ onOpenPanel, fieldId, showPinButton }: Props) => (
	<ErrorBoundary>
		<RespondersGlance onOpenPanel={onOpenPanel} fieldId={fieldId} showPinButton={showPinButton} />
	</ErrorBoundary>
);

export default connect(
	(state, ownPropsOnMount: { area: Area }) => ({
		showPinButton: getShowPinButton(ownPropsOnMount.area),
	}),
	{},
)(RespondersGlanceContainer);
