import React from 'react';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import { ActionButtonOld } from '../action-button/index.tsx';
import { AddWatchersOld } from './add-watchers/index.tsx';
import ChangeWatchState from './change-watch-state/index.tsx';
import { Dropdown, DropdownOld as DropdownOldView } from './view.tsx';
import { WatchersListOld } from './watchers-list/index.tsx';

const DropdownOld = () => (
	<DropdownOldView
		Button={ActionButtonOld}
		ChangeWatchState={ChangeWatchState}
		WatchersList={WatchersListOld}
		AddWatchers={AddWatchersOld}
	/>
);

export default componentWithCondition(
	() => fg('relay-migration-issue-header-and-parent'),
	Dropdown,
	DropdownOld,
);
