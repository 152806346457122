import type { LoomState } from '@atlassian/jira-issue-view-common-types/src/loom-type.tsx';
import {
	HIDE_INSERT_LOOM_LINK_FORM,
	SHOW_INSERT_LOOM_LINK_FORM,
	type HideInsertLoomLinkFormAction,
	type ShowInsertLoomLinkFormAction,
} from '../actions/loom-actions.tsx';

type State = LoomState;

export type Action = ShowInsertLoomLinkFormAction | HideInsertLoomLinkFormAction;

export const initialState: State = {
	insertLoomLinkClickedCount: 0,
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: State = initialState, action: Action): State => {
	switch (action.type) {
		case SHOW_INSERT_LOOM_LINK_FORM: {
			return {
				...state,
				insertLoomLinkClickedCount: state.insertLoomLinkClickedCount + 1,
			};
		}

		case HIDE_INSERT_LOOM_LINK_FORM: {
			return {
				...state,
				insertLoomLinkClickedCount: initialState.insertLoomLinkClickedCount,
			};
		}

		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
