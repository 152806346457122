import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	failingBuildsTitle: {
		id: 'development-summary-build.failingBuildsMessageTitle',
		defaultMessage: '<bold>{count}</bold> {count, plural, one {build} other {builds}} failing',
		description:
			"The number of failed builds associated with a Jira issue. 'Build' is a technical term from CI/CD that is most often not translated. It is acceptable to transliterate this term to another alphabet/script.",
	},
	successfulBuildsTitle: {
		id: 'development-summary-build.successfulBuildsMessageTitle',
		defaultMessage: '<bold>{count}</bold> {count, plural, one {build} other {builds}}',
		description:
			"The number of successful builds associated with a Jira issue. 'Build' is a technical term from CI/CD that is most often not translated. It is acceptable to transliterate this term to another alphabet/script.",
	},
	incompleteBuildsTitle: {
		id: 'development-summary-build.incompleteBuildsMessageTitle',
		defaultMessage: '<bold>{count}</bold> {count, plural, one {build} other {builds}} incomplete',
		description:
			"The number of incomplete builds associated with a Jira issue. 'Build' is a technical term from CI/CD that is most often not translated. It is acceptable to transliterate this term to another alphabet/script.",
	},
	tooltip: {
		id: 'development-summary-build.tooltip',
		defaultMessage: 'Open build',
		description:
			"The tool tip message indicating that clicking the link will lead to external link of a build. 'Build' is a technical term from CI/CD that is most often not translated. It is acceptable to transliterate this term to another alphabet/script.",
	},
});
