import React from 'react';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import useUpdatedDateField from './services/main.tsx';
import { UpdatedDate } from './ui/read/main.tsx';

type Props = {
	issueKey: IssueKey;
};

export const UpdatedDateField = ({ issueKey }: Props) => {
	const [{ value: updatedDate }] = useUpdatedDateField({ issueKey });
	return <UpdatedDate date={updatedDate} />;
};
