import { useCallback, useEffect } from 'react';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useFlagsService } from '@atlassian/jira-flags';
import { getUpdateAnalyticsFlowHelper } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/index.tsx';
import type {
	AttachmentId,
	AttachmentsQueryOptions,
} from '@atlassian/jira-issue-attachments-store/src/common/types.tsx';
import {
	fireOperationalAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import messages from '../messages.tsx';
import {
	useAttachmentStoreIsFetching,
	useAttachmentStoreError,
	useAttachmentStoreOrderDirection,
	useAttachmentStoreOrderField,
	useAttachmentStoreTotalCount,
	useAttachmentStoreDeletableCount,
	useAttachmentStoreStartAt,
	useAttachmentStoreVisibleAttachments,
	useAttachmentStoreActions,
} from './context.tsx';

export const useIssueAttachmentsActions = () => {
	const [, actions] = useAttachmentStoreActions();

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { showFlag } = useFlagsService();

	const {
		setOrderDirection,
		setOrderField,
		setTotalCount,
		setDeletableCount,
		setVisibleAttachments,
		addNewAttachments,
		deleteAttachment: storeDeleteAttachment,
		getAttachments,
		getAttachmentsCount,
		refreshAttachments,
	} = actions;

	const deleteAttachment = useCallback(
		async (
			issueAttachmentKey: IssueKey,
			deletingAttachmentId: AttachmentId,
			fetchOptions?: AttachmentsQueryOptions,
		) => {
			try {
				await storeDeleteAttachment(issueAttachmentKey, deletingAttachmentId, fetchOptions);
				if (fg('one-update-event-analytics-attach-file')) {
					getUpdateAnalyticsFlowHelper().fireAnalyticsEnd('attachment', {
						analytics: createAnalyticsEvent({}),
						attributes: {
							fieldType: 'attachment',
							actionTaken: 'remove',
						},
					});
				}
				fg('issue_view_add_attachment_metrics') &&
					fireOperationalAnalytics(createAnalyticsEvent({}), 'deleteAttachments success', {
						type: 'single',
					});
			} catch (e: unknown) {
				if (fg('issue_view_add_attachment_metrics')) {
					const error = e instanceof Error ? e : new Error('Failed to delete attachment');
					fireErrorAnalytics({
						error,
						meta: {
							id: 'deleteAttachments',

							packageName: 'jiraIssueView',
							teamName: 'bento',
						},
						attributes: {
							type: 'single',
						},
					});
				}
				showFlag({
					type: 'error',
					title: messages.deleteFailedTitle,
					description: messages.deleteFailedDescription,
				});
			}
		},
		[storeDeleteAttachment, showFlag, createAnalyticsEvent],
	);

	return [
		null,
		{
			setOrderDirection,
			setOrderField,
			setTotalCount,
			setDeletableCount,
			setVisibleAttachments,
			addNewAttachments,
			getAttachments,
			getAttachmentsCount,
			deleteAttachment,
			refreshAttachments,
		},
	] as const;
};

export const useIssueAttachments = (issueKey: IssueKey, options?: AttachmentsQueryOptions) => {
	const [, actions] = useIssueAttachmentsActions();
	const [isFetching] = useAttachmentStoreIsFetching(issueKey);
	const [error] = useAttachmentStoreError(issueKey);
	const [orderDirection] = useAttachmentStoreOrderDirection();
	const [orderField] = useAttachmentStoreOrderField();
	const [startAt] = useAttachmentStoreStartAt();
	const [totalCount] = useAttachmentStoreTotalCount(issueKey);
	const [deletableCount] = useAttachmentStoreDeletableCount(issueKey);
	const [visibleAttachments] = useAttachmentStoreVisibleAttachments(issueKey);

	const { getAttachments } = actions;

	useEffect(() => {
		if (totalCount === undefined && !isFetching && !error) {
			getAttachments(issueKey, options);
		}
	}, [error, getAttachments, issueKey, isFetching, options, totalCount]);

	const meta = {
		orderDirection,
		orderField,
		startAt,
	};

	const value = {
		attachments: visibleAttachments,
		totalCount: totalCount || 0,
		deletableCount: deletableCount || 0,
	};

	return [
		{
			error,
			meta,
			value,
			loading: isFetching,
		},
		actions,
	] as const;
};
