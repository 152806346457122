import React, { useState, useRef, useCallback, useEffect } from 'react';
import { styled } from '@compiled/react';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { isFirefox } from '@atlassian/jira-common-util-browser/src/index.tsx';
import { useBranchCommandBuilderStore } from '../../../../../../controllers/branch-command-builder-store/index.tsx';

export type Props = {
	value: string;
	id: string;
};

const TextValue = ({ value, id }: Props) => {
	/**
	 * https://bugzilla.mozilla.org/show_bug.cgi?id=1583209
	 */
	const textNodeRef = useRef<HTMLElement>(null);
	const [observer, setObserver] = useState<MutationObserver | null>(null);
	const [, { restoreFocusAfterDeletingAllText }] = useBranchCommandBuilderStore();
	const observerCallback = useCallback(
		(mutationRecords: MutationRecord[]) => {
			if (
				mutationRecords.length > 0 &&
				mutationRecords[0].target.childNodes.length === 0 &&
				mutationRecords[0].target.textContent === ''
			) {
				/**
				 * https://bugzilla.mozilla.org/show_bug.cgi?id=389321
				 */

				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				textNodeRef.current?.appendChild(document.createElement('br'));
				restoreFocusAfterDeletingAllText();
			} else if (
				textNodeRef.current?.childElementCount &&
				textNodeRef.current?.childElementCount > 1 &&
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				(textNodeRef.current?.childNodes[0] as HTMLElement)?.tagName === 'BR' &&
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				(textNodeRef.current?.childNodes[1] as HTMLElement)?.tagName === 'BR'
			) {
				const brs = textNodeRef.current.getElementsByTagName('br');
				for (let i = 0; i < brs.length; i += 1) {
					textNodeRef.current.removeChild(brs[i]);
				}
			}
		},
		[restoreFocusAfterDeletingAllText],
	);
	useEffect(() => {
		const obs = new MutationObserver(observerCallback);
		setObserver(obs);
	}, [observerCallback, setObserver]);

	useEffect(() => {
		if (!isFirefox()) return undefined;
		if (!observer) return undefined;
		if (!textNodeRef.current) return undefined;
		observer.observe(textNodeRef.current, {
			characterData: true,
			childList: true,
			subtree: true,
		});
		return () => {
			if (observer) {
				observer.disconnect();
			}
		};
	}, [observer, textNodeRef]);

	const textNodeContent = isFirefox() && value === '' ? <br /> : value;

	return (
		<TextValueWrapper
			data-testid={`development-summary-branch.ui.create-branch-dropdown.copy-branch-command.branch-command-builder.template-input.text-value.text-value-wrapper-${id}`}
			ref={textNodeRef}
			id={id}
		>
			{textNodeContent}
		</TextValueWrapper>
	);
};

export default TextValue;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TextValueWrapper = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	minHeight: `${gridSize * 2.5}px`,
	minWidth: '2px',
	display: 'inline-block',
	wordBreak: 'break-all',
});
