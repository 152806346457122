/**
 * @generated SignedSource<<d8273084c6d6ea678089c73d250f3bf3>>
 * @relayHash 35678cbf18b3329bde6c14432a785d3b
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID f2ff5287318ac47f781285f358fb66b14de78d0aa8b6549946800577556c99d9

import type { ConcreteRequest, Query } from 'relay-runtime';
export type fetchDefinitionsPermissionsQuery$variables = {
  cloudId: string;
};
export type fetchDefinitionsPermissionsQuery$data = {
  readonly jira: {
    readonly isJiraDefinitionsPermissionsEnabled: boolean | null | undefined;
  } | null | undefined;
};
export type fetchDefinitionsPermissionsQuery = {
  response: fetchDefinitionsPermissionsQuery$data;
  variables: fetchDefinitionsPermissionsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  }
],
v1 = [
  {
    "concreteType": "JiraQuery",
    "kind": "LinkedField",
    "name": "jira",
    "plural": false,
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "cloudId",
            "variableName": "cloudId"
          }
        ],
        "kind": "ScalarField",
        "name": "isJiraDefinitionsPermissionsEnabled"
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "fetchDefinitionsPermissionsQuery",
    "selections": (v1/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "fetchDefinitionsPermissionsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "f2ff5287318ac47f781285f358fb66b14de78d0aa8b6549946800577556c99d9",
    "metadata": {},
    "name": "fetchDefinitionsPermissionsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "6425cdb8390af644ee7677b809c529c0";

export default node;
