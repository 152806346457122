import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { feedbackDateSelector } from './selector.tsx';
import FeedbackDetails from './view.tsx';

export default flowWithSafeComponent(
	connect(
		(state: State) => ({
			feedbackDate: feedbackDateSelector(state),
		}),
		{},
	),
)(FeedbackDetails);
