/**
 * @generated SignedSource<<d7d9c9b2f7afb2aadfdb8d3e90d98a18>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type dateTime_issueFieldDateTimeReadviewFull_DateTimeReadView$data = {
  readonly dateTime: AGG$DateTime | null | undefined;
  readonly " $fragmentType": "dateTime_issueFieldDateTimeReadviewFull_DateTimeReadView";
};
export type dateTime_issueFieldDateTimeReadviewFull_DateTimeReadView$key = {
  readonly " $data"?: dateTime_issueFieldDateTimeReadviewFull_DateTimeReadView$data;
  readonly " $fragmentSpreads": FragmentRefs<"dateTime_issueFieldDateTimeReadviewFull_DateTimeReadView">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "dateTime_issueFieldDateTimeReadviewFull_DateTimeReadView",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "dateTime"
    }
  ],
  "type": "JiraDateTimePickerField"
};

(node as any).hash = "20472898e50d717a420a278e6770282c";

export default node;
