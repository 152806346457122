import noop from 'lodash/noop';
import TransitionScreenLegacyDialog from '@atlassian/jira-common-components-transition-screen-legacy-dialog/src/index.tsx';
import { timeToRenderTransitionModal } from '@atlassian/jira-common-components-transition-screen-legacy-dialog/src/transition-modal-performance-analytics.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type { Actions } from '../../../common/types.tsx';
import { startIssueTransitionMetrics } from '../../../common/ui/index.tsx';
import { isNewTransitionScreenEnabled } from '../../../common/utils/index.tsx';

const openIssueTransitionModal: Actions['openIssueTransitionModal'] =
	(nextState, preferNewIssueTransitionScreen = true) =>
	({ setState, getState }) => {
		const currentState = getState();

		const {
			payload: { issueKey = '', transitionId = '' },
			onDialogSuccess,
			onDialogCancel,
			onDialogError,
			triggerPointKey,
		} = nextState || {
			payload: {},
		};
		let transitionModalVersion = '';
		const isNewITSEnabled = isNewTransitionScreenEnabled(
			preferNewIssueTransitionScreen,
			triggerPointKey,
		);

		try {
			if (isNewITSEnabled) {
				transitionModalVersion = 'NEW';
				startIssueTransitionMetrics(currentState.isSubsequentLoad);
				setState({
					...currentState,
					isModalOpen: true,
					// making fetchedIssueId as blank to ensure we donot end up using stale issueId
					fetchedIssueId: '',
					...nextState,
				});
			} else {
				transitionModalVersion = 'OLD';
				timeToRenderTransitionModal.start();
				new TransitionScreenLegacyDialog({
					issueKey,
					transitionId,
				})
					.onDialogSuccess(onDialogSuccess || noop)
					.onDialogCancel(onDialogCancel || noop)
					.onDialogError(onDialogError || noop)
					.show();
			}

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			fireErrorAnalytics({
				meta: {
					id: 'transitionIssueTrigger',
					packageName: 'jiraIssueTransitionTrigger',
					teamName: 'gryffindor',
				},
				error,
				attributes: {
					transitionModalVersion,
				},
				sendToPrivacyUnsafeSplunk: true,
			});
			throw error;
		}
	};
export default openIssueTransitionModal;
