import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	addVoteDisabledTooltip: {
		id: 'issue-field-voters.services.disable-resolved-issue-voting-service.add-vote-disabled-tooltip',
		defaultMessage: "You can't vote on resolved issues",
		description:
			'Tooltip to display when the user has not voted and voting is disabled due to the issue being in DONE status',
	},
	removeVoteDisabledTooltip: {
		id: 'issue-field-voters.services.disable-resolved-issue-voting-service.remove-vote-disabled-tooltip',
		defaultMessage: "You can't change your vote on resolved issues",
		description:
			'Tooltip to display when the user has voted and voting is disabled due to the issue being in DONE status',
	},
	addVoteDisabledTooltipIssueTermRefresh: {
		id: 'issue-field-voters.services.disable-resolved-issue-voting-service.add-vote-disabled-tooltip-issue-term-refresh',
		defaultMessage: "You can't vote on resolved issues",
		description:
			'Tooltip to display when the user has not voted and voting is disabled due to the issue being in DONE status',
	},
	removeVoteDisabledTooltipIssueTermRefresh: {
		id: 'issue-field-voters.services.disable-resolved-issue-voting-service.remove-vote-disabled-tooltip-issue-term-refresh',
		defaultMessage: "You can't change your vote on resolved issues",
		description:
			'Tooltip to display when the user has voted and voting is disabled due to the issue being in DONE status',
	},
});
