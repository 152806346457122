import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';
// eslint-disable-next-line jira/import/no-restricted-import
import type { EntityLimitType as CollectionEntityLimitType } from '@atlassian/jira-polaris-domain-collection/src/types.tsx';
// eslint-disable-next-line jira/import/no-restricted-import
import type { EntityLimitType as ProjectEntityLimitType } from '@atlassian/jira-polaris-domain-project/src/types.tsx';
import messages from './messages.tsx';

export const getLimitMessageDescriptor = (
	limitType: ProjectEntityLimitType | CollectionEntityLimitType,
): MessageDescriptor | null => messages[limitType] || null;
