/**
 * @generated SignedSource<<7ed06e7839b00a7c68abc2d769e062d7>>
 * @relayHash df195733ff48b9549a66bccf7f29c881
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID e749ba152221f5828b74b11f741abd8463da006e3d2639e3e11631fce9e8fedb

import type { ConcreteRequest, Query } from 'relay-runtime';
export type signatureCrFetchComponentQuery$variables = {
  projectAri: string;
};
export type signatureCrFetchComponentQuery$data = {
  readonly jiraCannedResponse: {
    readonly searchCannedResponses: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly content: string;
          readonly isSignature: boolean | null | undefined;
        } | null | undefined;
      }> | null | undefined;
      readonly errors: ReadonlyArray<{
        readonly __typename: "QueryError";
        readonly extensions: ReadonlyArray<{
          readonly errorType: string | null | undefined;
          readonly statusCode: number | null | undefined;
        }> | null | undefined;
      }> | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type signatureCrFetchComponentQuery = {
  response: signatureCrFetchComponentQuery$data;
  variables: signatureCrFetchComponentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectAri"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "projectAri",
        "variableName": "projectAri"
      },
      {
        "kind": "Literal",
        "name": "scopes",
        "value": [
          "PERSONAL"
        ]
      },
      {
        "kind": "Literal",
        "name": "signature",
        "value": true
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  },
  {
    "kind": "Literal",
    "name": "sort",
    "value": {
      "name": "lastUpdatedAt",
      "order": "DESC"
    }
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "content"
},
v3 = {
  "kind": "ScalarField",
  "name": "isSignature"
},
v4 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v5 = {
  "kind": "ScalarField",
  "name": "statusCode"
},
v6 = {
  "kind": "ScalarField",
  "name": "errorType"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "signatureCrFetchComponentQuery",
    "selections": [
      {
        "concreteType": "JiraCannedResponseQueryApi",
        "kind": "LinkedField",
        "name": "jiraCannedResponse",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraCannedResponseConnection",
            "kind": "LinkedField",
            "name": "searchCannedResponses",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraCannedResponseEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "JiraCannedResponse",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ]
                  }
                ]
              },
              {
                "concreteType": "QueryError",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": true,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "signatureCrFetchComponentQuery",
    "selections": [
      {
        "concreteType": "JiraCannedResponseQueryApi",
        "kind": "LinkedField",
        "name": "jiraCannedResponse",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraCannedResponseConnection",
            "kind": "LinkedField",
            "name": "searchCannedResponses",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraCannedResponseEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "JiraCannedResponse",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "id"
                      }
                    ]
                  }
                ]
              },
              {
                "concreteType": "QueryError",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  {
                    "kind": "LinkedField",
                    "name": "extensions",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "e749ba152221f5828b74b11f741abd8463da006e3d2639e3e11631fce9e8fedb",
    "metadata": {},
    "name": "signatureCrFetchComponentQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "5a9e6b8f5b19a40a8afe8909b7ad42a2";

export default node;
