import memoize from 'memoize-one';

import { LS_PROVIDER_KEY, PROVIDERS_API } from './constants';
import type { ProviderRegexResponse } from './types';

export type RegexStorage = {
	getItem(key: string): string | null;
	setItem(key: string, value: string): void;
};

/**
 * Check if the url is supported by Linking Platform by
 * testing it against any of the regex patterns
 * @param url string
 * @returns boolean
 */
export const isSupportedByLinkingPlatform = (url: string, regexStorage: RegexStorage): boolean => {
	const cachedProviderRegex = getProviderRegexFromLs(regexStorage);

	if (!cachedProviderRegex.length || !url.length) {
		return false;
	}

	const regexPattern = new RegExp(cachedProviderRegex, 'g');
	return regexPattern.test(url);
};

/**
 * Get the currently stored provider regex patterns from LS
 * @returns Json<ProviderRegexStorage>
 */
export const getProviderRegexFromLs = (regexStorage: RegexStorage): string => {
	if (regexStorage.getItem(LS_PROVIDER_KEY)) {
		return JSON.parse(`${regexStorage.getItem(LS_PROVIDER_KEY)}`);
	}
	return '';
};

/**
 * setProviderRegexLs - set the provider regex patterns in Storage for
 * easy access within the synchronous md tokenizers
 * @param providerRegex string - combined regex patterns from Linking Platform to identify supported urls
 */
export const setProviderRegexLs = (regexStorage: RegexStorage, providerRegex: string): void => {
	regexStorage?.setItem(LS_PROVIDER_KEY, JSON.stringify(providerRegex));
};

/**
 * hydrateLinkingPlatformCache - fetch the provider regex patterns from Linking Platform
 */
export const hydrateLinkingPlatformCache = async (
	regexStorage: RegexStorage,
	baseUrl?: string,
): Promise<void> => {
	const providerRegex = await getProviderRegexFromLinkingPlatform(baseUrl);
	setProviderRegexLs(regexStorage, providerRegex);
};

/**
 * reduceProviderRegex
 * @param data - ProviderRegexResponse
 * @returns string - combined regex patterns from Linking Platform for a single regex test
 * to avoid individual tests on a loop of the providers
 */
export const reduceProviderRegex = (data: ProviderRegexResponse): string => {
	const sources = data.providers.reduce((acc, provider) => {
		const providerSources = provider.patterns.map((pattern) => pattern.source);
		return [...acc, ...providerSources];
	}, [] as string[]);

	return sources.join('|');
};

/**
 * Get a list of regex patterns for all supported providers from Linking Platform
 * This is called to hydrate localStorage with every `ContentRenderer` mount, so we're
 * memoizing the response to avoid unnecessary network requests
 * @returns ProviderRegexStorage
 */
export const getProviderRegexFromLinkingPlatform = memoize(async (baseUrl: string = '') => {
	try {
		const response = await fetch(baseUrl + PROVIDERS_API, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				type: 'resolve',
			}),
		});
		const data: ProviderRegexResponse = await response.json();
		return !data ? '' : reduceProviderRegex(data);
	} catch (error) {
		return '';
	}
});
