import React from 'react';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { mediaContextSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/media-context-selector.tsx';
import { AsyncExportPdfModal } from '@atlassian/jira-servicedesk-post-incident-review/src/ui/export-pdf-modal/async.tsx';
import { useExportPdfDialog } from './controller/index.tsx';
import type { Props } from './types.tsx';

const ExportPdfModal = ({ mediaContext }: Props) => {
	const [{ isOpen }, { closeExportPdfDialog }] = useExportPdfDialog();
	return isOpen ? (
		<AsyncExportPdfModal onClose={closeExportPdfDialog} mediaContext={mediaContext} />
	) : null;
};

export default connect(
	(state) => ({
		mediaContext: mediaContextSelector(state),
	}),
	{},
)(ExportPdfModal);
