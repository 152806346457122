/**
 * @generated SignedSource<<fa2124c9be7cbf7def1b068df4a37df4>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraProjectStyle = "COMPANY_MANAGED_PROJECT" | "TEAM_MANAGED_PROJECT" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type issueParentSwitcher_issueViewFoundation_IssueParentSwitcherWithRelay$data = {
  readonly id: string;
  readonly issueId: string;
  readonly issueTypeField: {
    readonly issueType: {
      readonly avatar: {
        readonly medium: string | null | undefined;
      } | null | undefined;
      readonly hierarchy: {
        readonly level: number | null | undefined;
      } | null | undefined;
      readonly issueTypeId: string | null | undefined;
      readonly name: string;
    } | null | undefined;
  } | null | undefined;
  readonly key: string;
  readonly parentIssueField: {
    readonly fieldId: string;
    readonly id: string;
    readonly name: string;
    readonly parentIssue: {
      readonly id: string;
      readonly issueId: string;
    } | null | undefined;
    readonly type: string;
  } | null | undefined;
  readonly projectField: {
    readonly name: string;
    readonly project: {
      readonly projectId: string | null | undefined;
      readonly projectStyle: JiraProjectStyle | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "issueParentSwitcher_issueViewFoundation_IssueParentSwitcherWithRelay";
};
export type issueParentSwitcher_issueViewFoundation_IssueParentSwitcherWithRelay$key = {
  readonly " $data"?: issueParentSwitcher_issueViewFoundation_IssueParentSwitcherWithRelay$data;
  readonly " $fragmentSpreads": FragmentRefs<"issueParentSwitcher_issueViewFoundation_IssueParentSwitcherWithRelay">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "id"
},
v1 = {
  "kind": "ScalarField",
  "name": "issueId"
},
v2 = {
  "kind": "ScalarField",
  "name": "name"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "issueParentSwitcher_issueViewFoundation_IssueParentSwitcherWithRelay",
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "name": "key"
    },
    (v1/*: any*/),
    {
      "concreteType": "JiraIssueTypeField",
      "kind": "LinkedField",
      "name": "issueTypeField",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraIssueType",
          "kind": "LinkedField",
          "name": "issueType",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "kind": "ScalarField",
              "name": "issueTypeId"
            },
            {
              "concreteType": "JiraAvatar",
              "kind": "LinkedField",
              "name": "avatar",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "medium"
                }
              ]
            },
            {
              "concreteType": "JiraIssueTypeHierarchyLevel",
              "kind": "LinkedField",
              "name": "hierarchy",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "level"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "concreteType": "JiraParentIssueField",
      "kind": "LinkedField",
      "name": "parentIssueField",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "name": "fieldId"
        },
        {
          "kind": "ScalarField",
          "name": "type"
        },
        (v2/*: any*/),
        {
          "concreteType": "JiraIssue",
          "kind": "LinkedField",
          "name": "parentIssue",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/)
          ]
        }
      ]
    },
    {
      "concreteType": "JiraProjectField",
      "kind": "LinkedField",
      "name": "projectField",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "concreteType": "JiraProject",
          "kind": "LinkedField",
          "name": "project",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "projectId"
            },
            {
              "kind": "ScalarField",
              "name": "projectStyle"
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraIssue"
};
})();

(node as any).hash = "9f4c8f4c271ef98b832652bd39dacec6";

export default node;
