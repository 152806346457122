export const PERSONALIZATION_TAP_TRAIT_CONFLUENCE_ACTIVATION_FETCHED =
	'PERSONALIZATION_TAP_TRAIT_CONFLUENCE_ACTIVATION_FETCHED' as const;

export type PersonalizationTapTraitConfluenceActivationFetchAction = {
	type: typeof PERSONALIZATION_TAP_TRAIT_CONFLUENCE_ACTIVATION_FETCHED;
	payload: {
		confluenceActivationDate: string;
	};
};

export const personalizationTapTraitConfluenceActivationFetched = (
	confluenceActivationDate: string,
): PersonalizationTapTraitConfluenceActivationFetchAction => ({
	type: PERSONALIZATION_TAP_TRAIT_CONFLUENCE_ACTIVATION_FETCHED,
	payload: {
		confluenceActivationDate,
	},
});
