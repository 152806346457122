import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge/src/utils/fire-analytics';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import {
	createGetIdSelector,
	createGetIssueAnalyticsAttributes,
} from '../../selectors/properties/index.tsx';
import type { State, Props } from '../../types.tsx';
import { updateFieldValues } from '../update-field-value/index.tsx';

export const archiveIssues =
	(localIssueIds: LocalIssueId[]) =>
	({ dispatch, getState }: StoreActionApi<State>, props: Props) => {
		const { archivedFieldsConfig, currentUser, projectId, insightsRemote } = props;
		if (!archivedFieldsConfig) {
			return;
		}

		const { archivedField, archivedByField, archivedOnField } = archivedFieldsConfig;
		if (!archivedField || !archivedByField || !archivedOnField) {
			return;
		}

		const { archivedOption } = archivedField;
		if (!archivedOption) {
			return;
		}

		dispatch(
			updateFieldValues({
				localIssueIds,
				fields: {
					[archivedField.key]: {
						newValue: {
							id: archivedOption.jiraOptionId,
						},
					},
					[archivedByField.key]: {
						newValue: [
							{
								accountId: currentUser,
							},
						],
					},
					[archivedOnField.key]: {
						newValue: new Date().toISOString(),
					},
				},
			}),
		);

		if (projectId !== undefined) {
			const issueIdsToCleanUp: string[] = localIssueIds
				.map((localIssueId) => createGetIdSelector(localIssueId)(getState()))
				.filter((val) => val !== undefined)
				.map(String);

			insightsRemote
				.archiveInsights?.({ issueIds: issueIdsToCleanUp })
				.catch((err) =>
					log.safeErrorWithoutCustomerData(
						'polaris.archive-issues-error',
						'Failed to archive insights',
						err,
					),
				);
		}

		fireTrackAnalytics(
			props.createAnalyticsEvent({}),
			'issue archived',
			localIssueIds.length === 1
				? {
						...createGetIssueAnalyticsAttributes(localIssueIds[0])(getState()),
					}
				: {
						issueCount: localIssueIds.length,
						issueIds: localIssueIds.map((id) => createGetIdSelector(id)(getState())?.toString()),
						actionType: 'bulk',
					},
		);
	};

export const unarchiveIssues =
	(localIssueIds: LocalIssueId[]) =>
	({ dispatch, getState }: StoreActionApi<State>, props: Props) => {
		const { archivedFieldsConfig, projectId, insightsRemote } = props;
		if (!archivedFieldsConfig) {
			return;
		}

		const { archivedField, archivedByField, archivedOnField } = archivedFieldsConfig;
		if (!archivedField || !archivedByField || !archivedOnField) {
			return;
		}

		dispatch(
			updateFieldValues({
				localIssueIds,
				fields: {
					[archivedField.key]: { newValue: undefined },
					[archivedByField.key]: { newValue: undefined },
					[archivedOnField.key]: { newValue: undefined },
				},
			}),
		);

		if (projectId) {
			insightsRemote
				.unarchiveInsights?.({
					issueIds: localIssueIds.map((localIssueId) =>
						String(createGetIdSelector(localIssueId)(getState())),
					),
				})

				.catch((err) =>
					log.safeErrorWithoutCustomerData(
						'polaris.unarchive-issues-error',
						'Failed to unarchive insights',
						err,
					),
				);
		}

		fireTrackAnalytics(
			props.createAnalyticsEvent({}),
			'issue unarchived',
			localIssueIds.length === 1
				? {
						...createGetIssueAnalyticsAttributes(localIssueIds[0])(getState()),
					}
				: {
						issueCount: localIssueIds.length,
						issueIds: localIssueIds.map((id) => createGetIdSelector(id)(getState())?.toString()),
						actionType: 'bulk',
					},
		);
	};
