/**
 * @generated SignedSource<<933d34e10fabaae074779ec28463432c>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type project_issueFieldProjectInlineEditFull_ProjectInlineEditViewWithIsEditable_fragmentRef$data = {
  readonly id: string;
  readonly name: string;
  readonly project: {
    readonly avatar: {
      readonly large: string | null | undefined;
      readonly small: string | null | undefined;
    } | null | undefined;
    readonly id: string;
    readonly key: string;
    readonly name: string;
    readonly projectId: string | null | undefined;
  } | null | undefined;
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"project_issueFieldProjectReadviewFull_ProjectReadView">;
  readonly " $fragmentType": "project_issueFieldProjectInlineEditFull_ProjectInlineEditViewWithIsEditable_fragmentRef";
};
export type project_issueFieldProjectInlineEditFull_ProjectInlineEditViewWithIsEditable_fragmentRef$key = {
  readonly " $data"?: project_issueFieldProjectInlineEditFull_ProjectInlineEditViewWithIsEditable_fragmentRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"project_issueFieldProjectInlineEditFull_ProjectInlineEditViewWithIsEditable_fragmentRef">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "id"
},
v1 = {
  "kind": "ScalarField",
  "name": "name"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "project_issueFieldProjectInlineEditFull_ProjectInlineEditViewWithIsEditable_fragmentRef",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "FragmentSpread",
      "name": "project_issueFieldProjectReadviewFull_ProjectReadView"
    },
    {
      "concreteType": "JiraProject",
      "kind": "LinkedField",
      "name": "project",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "kind": "ScalarField",
          "name": "projectId"
        },
        {
          "concreteType": "JiraAvatar",
          "kind": "LinkedField",
          "name": "avatar",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "small"
            },
            {
              "kind": "ScalarField",
              "name": "large"
            }
          ]
        },
        {
          "kind": "ScalarField",
          "name": "key"
        }
      ]
    }
  ],
  "type": "JiraProjectField"
};
})();

(node as any).hash = "9d59fec8df70bac564f21ca7f2d71503";

export default node;
