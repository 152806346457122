import type { MessageDescriptor } from '@atlassian/jira-intl';
import nudgeMessages from '../confluence-dummy-link/reminder-xflow-nudge/messages.tsx';
import messages from './messages.tsx';

export const ELIGIBLE_ISSUE_TYPES = ['bug', 'story', 'epic'];

export const ELIGIBLE_ISSUE_TYPES_SET = new Set<string>(ELIGIBLE_ISSUE_TYPES);

export type SupportedIssueTypes = 'bug' | 'story' | 'epic';

export interface ConfluenceTemplate {
	name: MessageDescriptor;
	url: string;
	suggestedIssueType: string;
	blueprintModuleCompleteKey: string;
	reminderNudgeContent: MessageDescriptor;
}

export const USER_PROPERTY_DUMMY_LINK = 'hasDismissedConfluenceDummyLink';

export const USER_PROPERTY_DUMMY_LINK_CO_USE = 'hasDismissedConfluenceDummyLinkCoUse';

export const DATA_PRIVACY_LINK = 'https://www.atlassian.com/legal/privacy-policy';

/**
 * The only issue types we are targeting for the Confluence Placeholder
 * Template experiment are 'story', 'epic', and 'bug'. Selected issue
 * type to template pairings are listed here:
 * https://hello.atlassian.net/wiki/spaces/ECON/pages/2768820121/Issue+type+template+pairing
 */
export const confluencePlaceholderTemplates: {
	[key: string]: ConfluenceTemplate;
} = {
	story: {
		name: messages.productRequirementsTemplateName,
		url: 'https://www.atlassian.com/software/confluence/templates/product-requirements',
		suggestedIssueType: 'story',
		blueprintModuleCompleteKey:
			'com.atlassian.confluence.plugins.confluence-software-blueprints:requirements-blueprint',
		reminderNudgeContent: nudgeMessages.contentPartOneProductRequirementsTemplateReminderNudge,
	},
	epic: {
		name: messages.projectPlanTemplateName,
		url: 'https://www.atlassian.com/software/confluence/templates/project-plan',
		suggestedIssueType: 'epic',
		blueprintModuleCompleteKey:
			'com.atlassian.confluence.plugins.confluence-business-blueprints:projectplan-blueprint',
		reminderNudgeContent: nudgeMessages.contentPartOneProjectPlanTemplateReminderNudge,
	},
	bug: {
		name: messages.knownErrorsTemplateName,
		url: 'https://www.atlassian.com/software/confluence/templates/itsm-known-errors',
		suggestedIssueType: 'bug',
		blueprintModuleCompleteKey:
			'com.atlassian.confluence.plugins.confluence-business-blueprints:itsm-known-errors-blueprint',
		reminderNudgeContent: nudgeMessages.contentPartOneKnownErrorsTemplateReminderNudge,
	},
};
