import React from 'react';
import { useIsIssueOfIncidentsPractice } from '@atlassian/jira-issue-field-servicedesk-practices/src/services/use-practices-field-value/index.tsx';
import { AsyncMajorIncChangeBoardingFlagContainer } from '@atlassian/jira-major-incident/src/ui/major-incidents-changeboarding-flag/async.tsx';
import { AsyncUpdateAffectedServicesFlagContainer } from '@atlassian/jira-servicedesk-update-affected-services-flag/src/async.tsx';
import { AsyncUpdateAssigneeFlagContainer } from '@atlassian/jira-servicedesk-update-assignee-flag/src/async.tsx';
import { AsyncUpdateIncidentModalContainer } from '@atlassian/jira-servicedesk-update-incident-modal/src/async.tsx';

export const IncidentManagementChangeboarding = () => {
	const isJsmIncident = useIsIssueOfIncidentsPractice();
	const renderJsmIncidentChangeboarding = () => (
		<>
			<AsyncUpdateIncidentModalContainer />
			<AsyncUpdateAssigneeFlagContainer />
			<AsyncUpdateAffectedServicesFlagContainer />
		</>
	);

	return isJsmIncident ? (
		<>
			{renderJsmIncidentChangeboarding()}
			<AsyncMajorIncChangeBoardingFlagContainer />
		</>
	) : null;
};
