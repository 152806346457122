import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import { UPDATED } from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';

type Props = {
	issueKey: IssueKey;
};

const useUpdatedDateField = ({ issueKey }: Props) => {
	const [value] = useFieldValue({ issueKey, fieldKey: UPDATED });

	return [{ value }] as const;
};

export default useUpdatedDateField;
