import { createIndexedDBStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/indexed-db/index.tsx';
import type { ViewUUID } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';

const locallySeenStorage = createIndexedDBStorageProvider('jpd-view-visits');

const getKey = (viewUUID: ViewUUID, accountId: AccountId | undefined) =>
	`${viewUUID}-${accountId || 'anonymous'}`;

export const getLocallySeen = async (viewUUID: ViewUUID, accountId: AccountId | undefined) => {
	return locallySeenStorage.get(getKey(viewUUID, accountId));
};

export const setLocallySeen = async (viewUUID: ViewUUID, accountId: AccountId | undefined) => {
	return locallySeenStorage.set(getKey(viewUUID, accountId), true);
};
