import type { Locale } from '@atlassian/jira-common-constants/src/supported-locales.tsx';
import { formatTimezonedWithLocale } from '@atlassian/jira-platform-utils-date-fns/src/main.tsx';
import type { TimeZone } from '@atlassian/jira-shared-types/src/general.tsx';

const DATE_FORMAT = 'yyyy-MM-dd';

const DATE_FIELD_TYPE = 'com.atlassian.jira.plugin.system.customfieldtypes:datepicker';
const DATE_TIME_FIELD_TYPE = 'com.atlassian.jira.plugin.system.customfieldtypes:datetime';

const stringDateToInterval = (date: string) => ({ start: date, end: date });

export const timestampAggDateToInterval = (
	dateRaw:
		| {
				date?: number;
				fieldType?: string;
		  }
		| undefined,
	locale: Locale = 'en_US',
	timezone: TimeZone = 'UTC',
) => {
	if (!dateRaw) {
		return undefined;
	}

	if (!dateRaw.date) {
		return undefined;
	}

	if (dateRaw.fieldType === DATE_FIELD_TYPE) {
		return stringDateToInterval(
			formatTimezonedWithLocale(new Date(dateRaw.date), DATE_FORMAT, locale, 'UTC'),
		);
	}

	if (dateRaw.fieldType === DATE_TIME_FIELD_TYPE) {
		return stringDateToInterval(
			formatTimezonedWithLocale(new Date(dateRaw.date), DATE_FORMAT, locale, timezone),
		);
	}
};
