/**
 * @generated SignedSource<<5f02e78af5b3cdb21cd2bb9ff00db1e4>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ConditionalInlineFieldConfigContent$data = {
  readonly projectField: {
    readonly project: {
      readonly id: string;
      readonly key: string;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "ConditionalInlineFieldConfigContent";
};
export type ConditionalInlineFieldConfigContent$key = {
  readonly " $data"?: ConditionalInlineFieldConfigContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"ConditionalInlineFieldConfigContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ConditionalInlineFieldConfigContent",
  "selections": [
    {
      "concreteType": "JiraProjectField",
      "kind": "LinkedField",
      "name": "projectField",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraProject",
          "kind": "LinkedField",
          "name": "project",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "id"
            },
            {
              "kind": "ScalarField",
              "name": "key"
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraIssue"
};

(node as any).hash = "a069846faadcb3570f4b4e8ecdff5019";

export default node;
