import { useEffect } from 'react';
import { useQueryLoader, type PreloadedQuery } from 'react-relay';
import type { GraphQLTaggedNode, VariablesOf, OperationType } from 'relay-runtime';
import logger from '@atlassian/jira-common-util-logging/src/log.tsx';
import usePageLoadedSubscriber from '@atlassian/jira-spa-state-controller/src/components/page-loaded-state/index.tsx';

// This hook and its usage of usePageLoadedSubscriber to control when the query is fired
// is a workaround for the missing @defer annotation
export const useLazyLoadQueryAfterPageLoad = <TQuery extends OperationType>(
	query: GraphQLTaggedNode,
	queryArguments: VariablesOf<TQuery>,
): PreloadedQuery<OperationType> | null | undefined => {
	const [queryReference, loadQuery] = useQueryLoader(query);
	const [{ hasLoaded }] = usePageLoadedSubscriber();

	useEffect(() => {
		try {
			if (hasLoaded === true && Object.values(queryArguments).every((arg) => arg != null)) {
				loadQuery(queryArguments);
			}
		} catch (error) {
			const errorMessage = error instanceof Error ? error.message : 'Unknown error';

			logger.safeErrorWithoutCustomerData(
				'issue.use-lazy-load-query-after-page-load-hook',
				`Error occurred while loading query: ${errorMessage}`,
			);
		}
		// This eslint disable is here to ensure that loadQuery is only executed exactly when hasLoaded has changed to
		// true and not due to a change in any other variable, guaranteeing it is only executed once
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hasLoaded]);

	return queryReference;
};
