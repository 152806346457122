import React from 'react';
import type { CustomGlyphProps } from '@atlaskit/icon';

export const pollGlyph = (props: CustomGlyphProps) => (
	<svg
		{...props}
		width="14"
		height="14"
		viewBox="0 0 14 14"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect x="0.33728" y="0.349976" width="13.2684" height="13.3" rx="2" fill="currentColor" />
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.32363 5.18502L3.88859 7.64752C3.73328 7.80493 3.64618 8.01738 3.64618 8.23877C3.64618 8.46015 3.73328 8.67261 3.88859 8.83002C3.96486 8.90765 4.05577 8.96929 4.15604 9.01137C4.2563 9.05345 4.36392 9.07512 4.47261 9.07512C4.58131 9.07512 4.68892 9.05345 4.78919 9.01137C4.88945 8.96929 4.98037 8.90765 5.05664 8.83002L6.9671 6.89919L8.88503 8.83835C8.96151 8.91593 9.05258 8.97752 9.15298 9.01956C9.25337 9.0616 9.36109 9.08324 9.46989 9.08324C9.5787 9.08324 9.68642 9.0616 9.78681 9.01956C9.88721 8.97752 9.97828 8.91593 10.0548 8.83835C10.2099 8.68084 10.2968 8.46842 10.2968 8.2471C10.2968 8.02578 10.2099 7.81337 10.0548 7.65585L7.61057 5.18502C7.4387 5.01359 7.20623 4.91715 6.96377 4.91669C6.73182 4.91669 6.50071 5.00585 6.32363 5.18502Z"
			fill="white"
		/>
	</svg>
);
