import React from 'react';
import PerformanceMark from '@atlassian/jira-common-performance/src/set-performance-mark.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyAfterPaint } from '@atlassian/react-loosely-lazy';
import {
	MARK_APPROVAL_PANEL_LOAD_START,
	MARK_APPROVAL_GLANCE_LOAD_START,
} from './common/constants.tsx';
import type {
	// eslint-disable-next-line import/no-named-default
	default as ApprovalGlancePanelType,
	ApprovalGlancePanelProps,
} from './ui/approval-glance-panel/index.tsx';
import type {
	// eslint-disable-next-line import/no-named-default
	default as ApprovalPanelType,
	ApprovalPanelProps,
} from './ui/approval-panel/index.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const Panel = lazyAfterPaint<typeof ApprovalPanelType>(
	() => import(/* webpackChunkName: "async-approval-panel" */ './ui/approval-panel'),
	{ ssr: false },
);

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const GlancePanel = lazyAfterPaint<typeof ApprovalGlancePanelType>(
	() => import(/* webpackChunkName: "async-approval-glance-panel" */ './ui/approval-glance-panel'),
	{ ssr: false },
);

export const AsyncApprovalPanel = (props: ApprovalPanelProps) => (
	<Placeholder
		name="panel"
		fallback={<PerformanceMark metricKey={MARK_APPROVAL_PANEL_LOAD_START} />}
	>
		<Panel {...props} />
	</Placeholder>
);

export const AsyncApprovalGlancePanel = (props: ApprovalGlancePanelProps) => (
	<Placeholder
		name="glance-panel"
		fallback={<PerformanceMark metricKey={MARK_APPROVAL_GLANCE_LOAD_START} />}
	>
		<GlancePanel {...props} />
	</Placeholder>
);
