export const COMMAND_BODY_TEST_ID = 'command-palette.ui.modal.body.command-body-container';
export const COMMAND_CONTAINER_ROW_TEST_ID = 'command-palette.ui.modal.body.command-container-row';
export const GROUP_TEST_ID = 'command-palette.ui.modal.body.group-container';
export const SPINNER_TEST_ID = 'command-palette.ui.modal.body.spinner-container';
export const NO_RESULTS_TEST_ID = 'command-palette.ui.modal.body.no-results-container';

// TODO: go/restrict-enums
// eslint-disable-next-line no-restricted-syntax
export enum SCROLL_DIRECTION {
	START = 'start',
	END = 'end',
}
