import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	noGuidelines: {
		id: 'issue-classification-badge.read-view-popup.no-guidelines',
		defaultMessage: 'No guidelines',
		description:
			'This is the text to be displayed when there is no guideline for classification in read view',
	},
});
