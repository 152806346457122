import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Wrapper = styled.div({
	margin: `${token('space.050', '4px')} ${token('space.150', '12px')} 0 0`,
	display: 'block',
	minWidth: 0,
	maxWidth: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	"[type='button']": {
		padding: 0,
		font: token('font.body.UNSAFE_small'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		span: {
			margin: 0,
			textOverflow: 'unset',
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		"span[class*='Icon__IconWrapper']": {
			width: '16px',
		},
	},
	marginRight: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	a: {
		marginRight: 0,
	},
});

export default Wrapper;
