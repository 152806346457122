/**
 * @generated SignedSource<<dd6bbf3a8992755d51e86f0f07845f58>>
 * @relayHash dbc3d877457157ecbba110aa5393a66e
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID f8abc19bffaab3b98718cbeb3af5857be80375e17284a975e9e43459c6294c53

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type JiraSingleValueFieldOperations = "SET" | "%future added value";
export type JiraServiceManagementUpdateSentimentFieldInput = {
  id: string;
  operation?: JiraServiceManagementUpdateSentimentOperationInput | null | undefined;
};
export type JiraServiceManagementUpdateSentimentOperationInput = {
  operation: JiraSingleValueFieldOperations;
  sentimentId?: string | null | undefined;
};
export type sentiment_useUpdateIssueViewSentimentField_Mutation$variables = {
  input: JiraServiceManagementUpdateSentimentFieldInput;
};
export type sentiment_useUpdateIssueViewSentimentField_Mutation$data = {
  readonly jira: {
    readonly updateSentimentField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly id: string;
        readonly sentiment: {
          readonly name: string | null | undefined;
          readonly sentimentId: string | null | undefined;
        } | null | undefined;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type sentiment_useUpdateIssueViewSentimentField_Mutation = {
  response: sentiment_useUpdateIssueViewSentimentField_Mutation$data;
  variables: sentiment_useUpdateIssueViewSentimentField_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "concreteType": "JiraMutation",
    "kind": "LinkedField",
    "name": "jira",
    "plural": false,
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "JiraServiceManagementSentimentFieldPayload",
        "kind": "LinkedField",
        "name": "updateSentimentField",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "name": "success"
          },
          {
            "concreteType": "MutationError",
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "message"
              }
            ]
          },
          {
            "concreteType": "JiraServiceManagementSentimentField",
            "kind": "LinkedField",
            "name": "field",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "id"
              },
              {
                "concreteType": "JiraServiceManagementSentiment",
                "kind": "LinkedField",
                "name": "sentiment",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "sentimentId"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "name"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "sentiment_useUpdateIssueViewSentimentField_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "sentiment_useUpdateIssueViewSentimentField_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "f8abc19bffaab3b98718cbeb3af5857be80375e17284a975e9e43459c6294c53",
    "metadata": {},
    "name": "sentiment_useUpdateIssueViewSentimentField_Mutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "132d28c12c42992f986938dcb7f8442e";

export default node;
