import React, { useCallback, useMemo } from 'react';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import { AsyncSuggestResourcesQuickAction } from '@atlassian/jira-aiops-suggested-resources/src/ui/quick-action/async.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	usePracticesFieldValue,
	useIsIssueOfIncidentsPractice,
} from '@atlassian/jira-issue-field-servicedesk-practices/src/services/use-practices-field-value/index.tsx';
import { SCHEDULE_CHANGE_ID } from '@atlassian/jira-issue-view-common-constants/src/quick-add-constants.tsx';
import { LOOM_QUICK_ADD_AD_CONTROL_DISMISS_KEY } from '@atlassian/jira-issue-view-common/src/component/loom-videos/constants.tsx';
import { useLoomTouchpointVariantWithAdControls } from '@atlassian/jira-issue-view-common/src/component/loom-videos/use-loom-touchpoint-variant-with-ad-controls/index.tsx';
import {
	LoomTouchpointVariant,
	type LoomTouchpointVariantType,
} from '@atlassian/jira-loom-utils/src/controllers/use-loom-touchpoint-variant/types.tsx';
import { useForms } from '@atlassian/jira-proforma-panel-in-issue-view-services/src/services/forms-status-service/index.tsx';
import { useProjectPermissions } from '@atlassian/jira-project-permissions-service/src/main.tsx';
import { AsyncDeveloperEscalationsButton } from '@atlassian/jira-servicedesk-customer-service-escalations-issue-view-button/src/async.tsx';
import { useIsDeveloperEscalationsEnabled } from '@atlassian/jira-servicedesk-customer-service-escalations-issue-view-button/src/controllers/use-is-developer-escalations-enabled/index.tsx';
import { useIsJourneyViewerEnabled } from '@atlassian/jira-servicedesk-journey-viewer/src/controllers/use-is-journey-viewer-enabled/index.tsx';
import { AsyncViewJourneyButton } from '@atlassian/jira-servicedesk-journey-viewer/src/ui/issue-view-button/async.tsx';
import { AsyncReviewButton } from '@atlassian/jira-servicedesk-post-incident-review/src/ui/review-button/async.tsx';
import { CHANGES } from '@atlassian/jira-servicedesk-work-category/src/common/constants.tsx';
import { toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import type { UIAnalyticsEvent } from '@atlassian/jira-product-analytics-bridge';
import { AddDesignItem } from '../../add-design-item/index.tsx';
import { AddFormButton } from '../../add-form-button/add-form-button-view/index.tsx';
import { CreateConfluenceContentMenu } from '../../create-confluence-content-menu/index.tsx';
import { LoomInsertLinkButton } from '../../loom-insert-link-item-button/view.tsx';
import { LoomQuickAddItemView } from '../../loom-record-item-button/index.tsx';
import { ScheduleChangeButton } from '../../schedule-change-button/async.tsx';
import type { QuickAddItemToRender } from '../../types.tsx';
import {
	useDynamicQuickActionListItems,
	type QuickActionDropdownItemProps,
} from '../use-dynamic-quick-action-list-items/index.tsx';

type ExternalQuickActionsPropTypes = {
	isAnonymousUser: boolean;
	onInsertLoomLinkClick: () => void;
	shouldBeCompact: boolean;
	forceUpdate: () => void;
	projectKey: string;
	issueKey: string;
	isQuickActionsListView: boolean;
	canCreateIssueLink: boolean;
	onAddIssueLinkClick: () => void;
	createItemView: (itemToRender: QuickAddItemToRender) => React.JSX.Element;
	onLinkPage: ((link: string, analyticsEvent: UIAnalyticsEvent) => void) | undefined;
};

type ExternalQuickActionsReturnTypes = {
	components: React.JSX.Element[][];
	actions: {
		setQuickActionListItem: (
			category: string,
			name: string,
			newValues: QuickActionDropdownItemProps | null,
		) => void;
	};
};

const useExternalQuickActionComponents = ({
	isAnonymousUser,
	onInsertLoomLinkClick,
	shouldBeCompact,
	forceUpdate,
	projectKey,
	issueKey,
	isQuickActionsListView,
	canCreateIssueLink,
	onAddIssueLinkClick,
	createItemView,
	onLinkPage,
}: ExternalQuickActionsPropTypes): ExternalQuickActionsReturnTypes => {
	const [{ canEditIssues }] = useProjectPermissions(projectKey);
	const [practices] = usePracticesFieldValue({ issueKey });
	const shouldShowScheduleChangeButton: boolean =
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		canEditIssues && (practices?.some((practice: any) => practice === CHANGES) ?? false);
	const isIssueOfIncidentsPractice = useIsIssueOfIncidentsPractice();
	const isDeveloperEscalationsEnabled = useIsDeveloperEscalationsEnabled();
	const isJsmViewJourneyEnabled = useIsJourneyViewerEnabled();

	const { dynamicQuickActionListItems, setQuickActionListItem } = useDynamicQuickActionListItems(
		fg('quick_actions_menu_ga')
			? [
					{
						category: 'confluence',
						name: 'new-page',
						isDisabled: true,
						itemKey: 'confluence-quick-actions-new-page',
					},
					{
						category: 'confluence',
						name: 'new-whiteboard',
						isDisabled: true,
						itemKey: 'confluence-quick-actions-new-whiteboard',
					},
				]
			: [],
	);

	const getQuickActionListComponentsByCategory = useCallback(
		(category: string) =>
			dynamicQuickActionListItems
				.filter((quickActionListItem) => quickActionListItem.category === category)
				.map((quickActionListItem) => {
					const dropdownItem = (
						<DropdownItem
							key={quickActionListItem.itemKey}
							onClick={quickActionListItem.onClick}
							elemBefore={quickActionListItem.iconComponent}
							elemAfter={quickActionListItem.itemBadgeComponent}
							{...quickActionListItem.additionalDropdownProps}
						>
							{quickActionListItem.labelComponent}
						</DropdownItem>
					);

					return fg('add_error_boundary_for_quick_actions_add_button') ? (
						<UFOSegment key={quickActionListItem.itemKey} name="quick-actions-confluence-buttons">
							<JSErrorBoundary
								id={quickActionListItem.name || 'quick-actions-confluence-buttons'}
								packageName="jiraQuickActions"
								teamName="bento"
								fallback="unmount"
							>
								{dropdownItem}
							</JSErrorBoundary>
						</UFOSegment>
					) : (
						dropdownItem
					);
				}),
		[dynamicQuickActionListItems],
	);

	const [
		{
			value: { hasIssueForms, hasProjectForms },
		},
		{ onClickAddFormButton },
	] = useForms({
		issueKey: toIssueKey(issueKey),
	});

	const suggestResourcesAction = useMemo(
		() =>
			isIssueOfIncidentsPractice && fg('incident_suggested_resources')
				? [
						<AsyncSuggestResourcesQuickAction
							isCompactMode={shouldBeCompact}
							isQuickActionsListView={isQuickActionsListView}
							key="suggest-resources-action"
						/>,
					]
				: [],
		[isIssueOfIncidentsPractice, isQuickActionsListView, shouldBeCompact],
	);

	const reviewButton = useMemo(
		() =>
			isIssueOfIncidentsPractice && canCreateIssueLink
				? [
						<AsyncReviewButton
							onAddIssueLinkClick={onAddIssueLinkClick}
							compactMode={shouldBeCompact}
							isQuickActionsListView={isQuickActionsListView}
							key="async-review-button"
						/>,
					]
				: [],
		[
			canCreateIssueLink,
			isIssueOfIncidentsPractice,
			isQuickActionsListView,
			onAddIssueLinkClick,
			shouldBeCompact,
		],
	);

	const changeManagementActions = useMemo(
		() =>
			[
				shouldShowScheduleChangeButton && (
					<ScheduleChangeButton key={SCHEDULE_CHANGE_ID} createItemView={createItemView} />
				),
			].filter(Boolean),
		[createItemView, shouldShowScheduleChangeButton],
	);

	const developerEscalationsAction = useMemo(
		() =>
			isDeveloperEscalationsEnabled
				? [
						<AsyncDeveloperEscalationsButton
							key="async-developer-escalations-button"
							createItemView={createItemView}
						/>,
					]
				: [],
		[createItemView, isDeveloperEscalationsEnabled],
	);

	const journeyAction = useMemo(
		() =>
			isJsmViewJourneyEnabled
				? [<AsyncViewJourneyButton key="async-jsm-view-journey-button" />]
				: [],
		[isJsmViewJourneyEnabled],
	);

	const formsActions = useMemo(
		() =>
			hasProjectForms
				? [
						<AddFormButton
							key="add-form-button"
							createItemView={createItemView}
							onClick={onClickAddFormButton}
							hasIssueForms={hasIssueForms}
							isQuickActionsListView={isQuickActionsListView}
						/>,
					]
				: [],
		[createItemView, hasIssueForms, hasProjectForms, isQuickActionsListView, onClickAddFormButton],
	);

	const designActions = useMemo(
		() =>
			!isAnonymousUser && canEditIssues
				? [
						<AddDesignItem
							key="add-design-button"
							createItemView={createItemView}
							onMount={forceUpdate}
							isQuickActionsListView={isQuickActionsListView}
						/>,
					]
				: [],
		[canEditIssues, createItemView, forceUpdate, isAnonymousUser, isQuickActionsListView],
	);

	const asyncConfluenceContentMenu = useMemo(
		() => [
			<CreateConfluenceContentMenu
				key="create-confluence-content-menu"
				appearance={shouldBeCompact ? 'compact' : ''}
				isQuickActionsListView={isQuickActionsListView}
				onLinkPage={onLinkPage}
			/>,
		],
		[isQuickActionsListView, shouldBeCompact, onLinkPage],
	);

	let loomTouchpointVariant: LoomTouchpointVariantType = LoomTouchpointVariant.NONE;
	let dismissAd: () => Promise<void> = useCallback(() => Promise.resolve(), []);

	if (fg('loom_crossflow_enablement_in_jira')) {
		/**
		 * Adding this because of removal of file level eslint disable for
		 * rules of hooks (PIR-21595). To be cleaned up when above FG is removed.
		 */
		/* eslint-disable-next-line react-hooks/rules-of-hooks */
		const touchPointVariants = useLoomTouchpointVariantWithAdControls(
			LOOM_QUICK_ADD_AD_CONTROL_DISMISS_KEY,
		);
		loomTouchpointVariant = touchPointVariants.loomTouchpointVariant;
		dismissAd = touchPointVariants.dismissAd;
	}

	const loomVideoButtons: React.JSX.Element[] = useMemo(() => {
		let loomButtons: React.JSX.Element[] = [];
		if (fg('loom_crossflow_enablement_in_jira')) {
			loomButtons = [
				// record Loom button could have crossflow, crossjoin or co-use touchpoints, so the logic is handled in the LoomQuickAddItemView
				<LoomQuickAddItemView
					key="loom-record-video"
					loomTouchpointVariant={loomTouchpointVariant}
					onDismissAd={dismissAd}
				/>,
			];

			if (loomTouchpointVariant === LoomTouchpointVariant.COUSE) {
				// insert existing Loom video button should only render when we are in the co-use scenario
				loomButtons = [
					...loomButtons,
					<LoomInsertLinkButton
						key="loom-insert-existing-video"
						onInsertLoomLinkClick={onInsertLoomLinkClick}
					/>,
				];
			}
		}

		return loomButtons;
	}, [dismissAd, loomTouchpointVariant, onInsertLoomLinkClick]);

	const confluenceButtons: React.JSX.Element[] = useMemo(() => {
		return fg('quick_actions_menu_ga') ? getQuickActionListComponentsByCategory('confluence') : [];
	}, [getQuickActionListComponentsByCategory]);

	return {
		components: [
			designActions,
			formsActions,
			journeyAction,
			developerEscalationsAction,
			changeManagementActions,
			reviewButton,
			suggestResourcesAction,
			asyncConfluenceContentMenu,
			loomVideoButtons,
			confluenceButtons,
		],
		actions: {
			setQuickActionListItem,
		},
	};
};

export default useExternalQuickActionComponents;
