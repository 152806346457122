import { useCallback, useState } from 'react';
import { graphql, useMutation } from 'react-relay';
import type { ForgeUiIssueData } from '@atlassian/jira-forge-ui-types/src/common/types/extension-data.tsx';
import type { Extension } from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import type {
	dynamicPropertiesMutation$data,
	dynamicPropertiesMutation,
} from '@atlassian/jira-relay/src/__generated__/dynamicPropertiesMutation.graphql';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';

export const useDynamicProperties = (
	extension: Extension,
	extensionData: Required<ForgeUiIssueData> | null,
) => {
	const { cloudId, activationId } = useTenantContext();
	const [data, setData] = useState<unknown>(null);
	const [error, setError] = useState<unknown>(null);
	const [invokeExtensionMutation, isLoading] = useMutation<dynamicPropertiesMutation>(graphql`
		mutation dynamicPropertiesMutation($input: InvokeExtensionInput!) {
			invokeExtension(input: $input) {
				success
				response {
					body
				}
				errors {
					message
					extensions {
						statusCode
					}
				}
			}
		}
	`);

	const request = useCallback(
		() =>
			invokeExtensionMutation({
				variables: {
					input: {
						entryPoint: 'dynamicProperties',
						extensionId: extension.id,
						contextIds: [`ari:cloud:jira:${cloudId}:workspace/${activationId}`],
						payload: {
							cloudId,
							extension: {
								type: extension.type,
								...extensionData,
							},
						},
					},
				},
				onCompleted: (result: dynamicPropertiesMutation$data, errors) => {
					setError(errors ? errors[0] : null);
					setData(result?.invokeExtension?.response?.body);
				},
				onError: (err) => {
					setError(err);
				},
			}),
		[invokeExtensionMutation, extension.id, extension.type, cloudId, activationId, extensionData],
	);

	return {
		properties: data,
		error,
		isLoading,
		fetch: request,
	};
};
