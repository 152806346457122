import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { retryOnError } from '@atlassian/jira-fetch/src/utils/retries.tsx';
import {
	fetchIssueGiraNonCriticalData,
	nonCriticalGiraQueryExperienceTracking,
} from '@atlassian/jira-issue-fetch-services/src/services/issue-gira-non-critical-data/index.tsx';
import type { GiraViewIssue } from '@atlassian/jira-issue-fetch-services/src/services/issue-gira-non-critical-data/types.tsx';
import type { ResourceManager } from '@atlassian/jira-issue-view-common-utils/src/utils/prefetched-resources/prefetched-resource-manager/index.tsx';
import { makeErrorObject } from '@atlassian/jira-issue-view-services/src/issue/error-utils.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import {
	transformEcosystemBackgroundScripts,
	transformEcosystemOperations,
} from './utils/ecosystem.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const logError = (msg: string, err: any) =>
	log.safeErrorWithoutCustomerData('issue.fetch.gira-non-critical.partial', msg, err);

const consumePrefetchedData = async (prefetchedResourceManager: ResourceManager) => {
	const prefetchedDataPromise = prefetchedResourceManager.issueGiraNonCriticalData;
	prefetchedResourceManager.clearGiraNonCriticalData();
	const response = await prefetchedDataPromise;
	return response;
};

const fetchData = async (issueKey: IssueKey) => {
	const response = await fetchIssueGiraNonCriticalData(issueKey);
	return response;
};

const transformData = (viewIssue: GiraViewIssue) => {
	const result = {
		operations: transformEcosystemOperations(viewIssue.ecosystem?.operations),
		backgroundScripts: transformEcosystemBackgroundScripts(viewIssue.ecosystem?.backgroundScripts),
		deletableAttachmentsCount: viewIssue.deletableAttachmentsCount || 0,
	};

	return result;
};

// Fetches the gira data (from either the existing prefetched promise or a fresh query) and transforms it.
// In practice this should always come from the prefetchedResourceManager except if invoked outside of
// initial load or transition.
export const fetchNonCriticalGiraDataWithRetries = async (
	issueKey: IssueKey,
	prefetchedResourceManager?: ResourceManager,
) => {
	const hasPrefetchPromise =
		prefetchedResourceManager?.issueKey === issueKey &&
		!!prefetchedResourceManager?.issueGiraNonCriticalData;
	const getData = () =>
		hasPrefetchPromise ? consumePrefetchedData(prefetchedResourceManager) : fetchData(issueKey);
	const doFetch = () => fetchData(issueKey);
	const response = await retryOnError(getData, {
		retryFunc: doFetch,
	});

	const { errors } = response || {};
	if (!response?.data?.viewIssue) {
		// If the response is completely missing the data, throw an error
		const message = errors
			? JSON.stringify(makeErrorObject(errors))
			: 'No data returned for non-critical Gira query';
		throw new Error(message);
	} else if (errors?.length) {
		// Log an error for partial failure and try to continue
		log.safeErrorWithoutCustomerData(
			'issue.fetch.gira-non-critical.partial',
			'Partial failure when fetching non-critical Gira data',
			makeErrorObject(errors),
		);
	}

	try {
		const transformedData = transformData(response.data.viewIssue);

		if (hasPrefetchPromise) {
			nonCriticalGiraQueryExperienceTracking({
				wasExperienceSuccessful: true,
				isEarlyFetch: true,
			});
		}

		return transformedData;
	} catch (error) {
		logError('Failed to transform non-critical Gira data', error);
		throw new Error(`Failed to transform non-critical Gira data: ${error}`);
	}
};
