import React from 'react';
import type { Attributes } from '@atlassian/jira-product-analytics-bridge';
import { TriggerButton } from './trigger-button/index.tsx';

const ITEM_KEY = 'compalDialog';

export const compalDropdownGroup = (
	shouldShow: boolean,
	onClick: (itemKey: string, event?: Event, actionAttributes?: Attributes) => void,
) => ({
	name: '',
	key: 'CompalDropdownGroup',
	items: [
		...(shouldShow ? [<TriggerButton onClick={() => onClick(ITEM_KEY)} key={ITEM_KEY} />] : []),
	],
});
