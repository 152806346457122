import difference from 'lodash/difference';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import {
	performPutRequest,
	performDeleteRequest,
} from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { useFlagsService } from '@atlassian/jira-flags';
import { fireOperationalAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { getEscalatorBaseUrl } from '@atlassian/jira-servicedesk-incident-management-common/src/utils.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import type { Stakeholder } from '../../../common/types.tsx';
import { transformStkeholdersDetailResponseData } from '../../../common/utils.tsx';
import type { State, ContainerProps } from '../../types.tsx';
import messages from './messages.tsx';

export const removeStakeholder =
	(updatedStakeholders: Stakeholder[]): Action<State, ContainerProps> =>
	async ({ setState, getState }, { issueId, cloudId, analyticsEvent }) => {
		const { showFlag } = useFlagsService();
		const { stakeholders, issueVersion } = getState();

		if (!issueId) {
			setState({
				stakeholders: {
					error: 'Missing issueId',
				},
			});
			return;
		}

		const removedStakeholders = difference(stakeholders.data, updatedStakeholders);

		let data;

		if (issueVersion.isNew) {
			data = updatedStakeholders;
		} else {
			data = transformStkeholdersDetailResponseData(updatedStakeholders);
		}

		setState({
			stakeholders: {
				error: undefined,
				data,
			},
		});

		try {
			if (issueVersion.isNew) {
				performDeleteRequest(
					`/gateway/api/jsm/stakeholders/cloudId/${cloudId}/v1/stakeholders/issue/${issueId}`,
					{
						body: JSON.stringify({
							stakeholderIds: removedStakeholders.map((stakeholder) => stakeholder.id),
						}),
					},
				);
			} else {
				await performPutRequest(
					`${getEscalatorBaseUrl(cloudId)}/issue/${issueId}/incident/stakeholders`,
					{
						body: JSON.stringify({
							stakeholderAris: removedStakeholders.map(
								// @ts-expect-error: After old version of stakeholders removed/deprecated, that util will be removed. `isNew` is the condition
								(stakeholder) => stakeholder.ari,
							),
						}),
					},
				);
			}

			// https://data-portal.internal.atlassian.com/analytics/registry/41046
			fireOperationalAnalytics(analyticsEvent, 'removeStakeholder succeeded');
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			// https://data-portal.internal.atlassian.com/analytics/registry/41048
			fireErrorAnalytics({
				event: analyticsEvent,
				meta: {
					id: 'removeStakeholder',
					packageName: 'jiraJsmStakeholderUpdates',
					teamName: 'itsm-agni',
				},
				error: e,
				sendToPrivacyUnsafeSplunk: true,
			});

			setState({
				stakeholders: {
					error: e.message,
				},
			});

			showFlag({
				type: 'error',
				title: messages.failureHeading,
				description: messages.failureDescription,
			});
		}
	};
