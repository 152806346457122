import { metrics } from '@atlassian/browser-metrics';
import { TTIFFs } from './constants.tsx';
import type { StopTTICustomData } from './types.tsx';

const uimInIssueTransitionTTI = metrics.interaction({
	key: 'ui-modifications-in-issue-transition',
	timings: [
		{ key: 'issue-transition-tti-delta', startMark: 'issue-transition-tti' },
		{ key: 'script-execution', startMark: 'iframe-load' },
		{ key: 'loading-indicators-visibility', startMark: 'fields-registered' },
		{ key: 'time-until-oninit-resolved', endMark: 'oninit-resolved' },
	],
	featureFlags: TTIFFs,
});

export const startUimTTI = () => {
	uimInIssueTransitionTTI.start();
};

export const markIssueTransitionLoaded = () => uimInIssueTransitionTTI.mark('issue-transition-tti');

export const stopTTI = (customData: StopTTICustomData) => {
	uimInIssueTransitionTTI.stop({
		customData,
	});
};

export const cancelTTI = () => {
	uimInIssueTransitionTTI.cancel();
};

export const markIframeLoad = () => {
	uimInIssueTransitionTTI.mark('iframe-load');
};

export const markFieldsRegistered = () => {
	uimInIssueTransitionTTI.mark('fields-registered');
};

export const markOnInitResolved = () => {
	uimInIssueTransitionTTI.mark('oninit-resolved');
};
