import omitBy from 'lodash/omitBy';
import { format, parseISO } from 'date-fns';
import { stringify } from 'query-string';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { secondsToWholeMinuteTimeString } from '@atlassian/jira-issue-format-time/src/common/utils/seconds-to-whole-minute-time-string/index.tsx';
import type { FormValues } from '@atlassian/jira-issue-view-common-types/src/log-time-modal-type.tsx';
import type { IssueKey, BaseUrl } from '@atlassian/jira-shared-types/src/general.tsx';
import type { ServerWorklog } from '../model/index.tsx';
import { getWorklogUrl } from './common.tsx';

const isEmpty = (s: string) => !s;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	baseUrl: BaseUrl,
	issueKey: IssueKey,
	values: FormValues,
): Promise<ServerWorklog> => {
	const { timeLoggedSeconds, workDescription, timeRemaining, timeRemainingSeconds, dateStarted } =
		values || {};

	// Convert from seconds to minutes because the
	// backend doesn't support weeks/days/hours with decimal places other than
	// 0.25, 0.5 or 0.75
	// we have to convert the final value to a whole number as passing decimal
	// values in minutes errors.
	const timeSpent = secondsToWholeMinuteTimeString(timeLoggedSeconds || 0);
	const newEstimate = timeRemaining
		? secondsToWholeMinuteTimeString(timeRemainingSeconds || 0)
		: timeRemaining;

	const params = omitBy(
		{
			adjustEstimate: !newEstimate ? 'auto' : 'new',
			newEstimate,
		},
		isEmpty,
	);

	const url = `${getWorklogUrl(baseUrl, issueKey)}?${stringify(params)}`;
	return performPostRequest(url, {
		body: JSON.stringify({
			timeSpent,
			comment: workDescription,
			started: dateStarted
				? format(parseISO(dateStarted), "yyyy-MM-dd'T'HH:mm:ss.SSSxx")
				: undefined,
		}),
	});
};
