import React, { useCallback } from 'react';
import { useFragment, graphql } from 'react-relay';
import { useIssueId } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useFieldsValuesActions } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import { SingleLineTextHeadingInlineEditView } from '@atlassian/jira-issue-field-single-line-text-inline-edit-heading/src/ui/single-line-text-heading/index.tsx';
import { useIssueViewFieldUpdateEvents } from '@atlassian/jira-issue-view-field-update-events/src/services/issue-view-field-update-events/index.tsx';
import { useFieldExperienceTracking } from '@atlassian/jira-issue-view-layout-experience-tracking/src/field-experience-tracking/index.tsx';
import { IssueViewLayoutSafeFieldWrapper } from '@atlassian/jira-issue-view-layout-safe-field-wrapper/src/ui/index.tsx';
import { UiModificationsRelayFieldDecorator } from '@atlassian/jira-issue-view-ui-modifications-relay-field-decorator/src/ui/index.tsx';
import { SUMMARY_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { ui_issueViewLayoutSummaryField_IssueViewSummaryField$key as IssueViewSummaryFieldFragment } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutSummaryField_IssueViewSummaryField.graphql';

export interface IssueViewSummaryFieldProps {
	fragmentKey: IssueViewSummaryFieldFragment | null;
}

export const IssueViewSummaryField = ({ fragmentKey }: IssueViewSummaryFieldProps) => {
	const issueId = useIssueId();
	const [, { fieldChanged, fieldChangeFailed, fieldChangeRequested }] =
		useIssueViewFieldUpdateEvents();

	const fieldData = useFragment<IssueViewSummaryFieldFragment>(
		graphql`
			fragment ui_issueViewLayoutSummaryField_IssueViewSummaryField on JiraIssue {
				key
				summaryField {
					...singleLineTextHeading_issueFieldSingleLineTextInlineEditHeading_SingleLineTextHeadingInlineEditView
					fieldId
					type
					__typename
				}
			}
		`,
		fragmentKey,
	);

	const { key, summaryField: data } = fieldData ?? { key: '' };

	const [, { setFieldValue: setSummaryFieldValue }] = useFieldsValuesActions();

	const {
		sendSubmitSucceededExperienceEvent,
		sendSubmitFailedExperienceEvent,
		sendEditCancelExperienceEvent,
	} = useFieldExperienceTracking({ fieldId: data?.fieldId ?? '' });

	const onSubmit = useCallback(
		(value: string | null) => {
			issueId &&
				fieldChangeRequested(issueId, data?.fieldId ?? '', value, undefined, {
					type: data?.type ?? '',
					__typename: data?.__typename ?? 'JiraSingleLineTextField',
				});
		},
		[data?.__typename, data?.fieldId, data?.type, fieldChangeRequested, issueId],
	);

	const onSubmitSucceeded = useCallback(
		(value?: string | null) => {
			// Update the summary field value in sweet state to expedite the update in the UI across non relay consumers (e.g. inline edit in boards)
			setSummaryFieldValue(key, SUMMARY_TYPE, value);
			issueId &&
				fieldChanged(issueId, data?.fieldId ?? '', value, {
					type: data?.type ?? '',
					__typename: data?.__typename ?? 'JiraSingleLineTextField',
				});
			sendSubmitSucceededExperienceEvent();
		},
		[
			data?.__typename,
			data?.fieldId,
			data?.type,
			fieldChanged,
			issueId,
			key,
			sendSubmitSucceededExperienceEvent,
			setSummaryFieldValue,
		],
	);

	const onSubmitFailed = useCallback(
		(error?: Error) => {
			issueId && fieldChangeFailed(issueId, data?.fieldId ?? '');
			sendSubmitFailedExperienceEvent(error);
		},
		[data?.fieldId, fieldChangeFailed, issueId, sendSubmitFailedExperienceEvent],
	);

	const onCancel = useCallback(() => {
		sendEditCancelExperienceEvent();
	}, [sendEditCancelExperienceEvent]);

	return (
		<IssueViewLayoutSafeFieldWrapper
			packageName="jiraIssueViewLayoutSummaryField"
			fieldType="summary"
		>
			{data && (
				<UiModificationsRelayFieldDecorator fieldId={data.fieldId} issueKey={key}>
					<SingleLineTextHeadingInlineEditView
						fragmentRef={data}
						onCancel={onCancel}
						onSubmit={onSubmit}
						onSubmitSucceeded={onSubmitSucceeded}
						onSubmitFailed={onSubmitFailed}
						invalidMessageDialogPlacement="right"
						inlineEditTestId="issue-field-summary.ui.issue-field-summary-inline-edit"
						readViewHeadingTestId="issue.views.issue-base.foundation.summary.heading"
						editViewInputWrapperTestId="issue.views.issue-base.foundation.summary.heading.writeable"
					/>
				</UiModificationsRelayFieldDecorator>
			)}
		</IssueViewLayoutSafeFieldWrapper>
	);
};
