import type { MiddlewareAPI } from 'redux';
import type { ActionsObservable } from 'redux-observable';
import { filter } from 'rxjs/operators/filter';
import { ignoreElements } from 'rxjs/operators/ignoreElements';
import { map } from 'rxjs/operators/map';
import { tap } from 'rxjs/operators/tap';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fireTrackAnalytics } from '@atlassian/jira-analytics-web-react/src/utils/fire-track-event.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { ofTypeOrBatchType } from '@atlassian/jira-issue-view-common-utils/src/rx/of-type-or-batch-type.tsx';
import actionAnalyticsMap from './analytics-actions-map.tsx';
import triggerAnalytics from './trigger-analytics.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getAnalyticsForAction = (state: State, action: any) =>
	// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type 'ObjMap<ActionsByKey, AnalyticsGetterType>'.
	actionAnalyticsMap[action.type] && {
		analyticsEvent: action.payload && action.payload.analyticsEvent,
		// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type 'ObjMap<ActionsByKey, AnalyticsGetterType>'.
		...actionAnalyticsMap[action.type](state, action),
	};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getFlattenedEventData = (eventData: any) => {
	if (eventData && eventData.attributes) {
		const { attributes, ...rest } = eventData;
		return { ...attributes, ...rest };
	}
	return eventData;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getAmplitudeShapeEventData = (eventData: any, analyticsEvent: any) => {
	const action = (analyticsEvent.payload && analyticsEvent.payload.action) || 'updated';
	const {
		actionSubjectId,
		name,
		tags,
		containerType,
		containerId,
		objectType,
		objectId,
		...attributes
	} = eventData;

	return {
		action,
		actionSubjectId,
		name,
		tags,
		containerType,
		containerId,
		objectType,
		objectId,
		attributes,
	};
};

const triggerAnalyticsForAction = ({
	eventNameSuffix,
	eventData,
	analyticsEvent,
}: {
	eventNameSuffix: string;
	eventData: unknown;
	analyticsEvent: UIAnalyticsEvent;
}) => {
	if (analyticsEvent) {
		try {
			const formattedEventData = getAmplitudeShapeEventData(eventData, analyticsEvent);
			fireTrackAnalytics(analyticsEvent, formattedEventData);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			log.safeErrorWithoutCustomerData(
				'issue.instrumentation.trigger-analytics-action',
				'Failed to send action track event',
				error,
			);
		}
	}

	const flattenedEventData = getFlattenedEventData(eventData);
	triggerAnalytics(eventNameSuffix, flattenedEventData);
};

const expectedActions: string[] = Object.keys(actionAnalyticsMap);

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<any>, store: MiddlewareAPI<State>) =>
	action$.pipe(
		ofTypeOrBatchType(...expectedActions),
		map((action) => getAnalyticsForAction(store.getState(), action)),
		filter((event) => !!event),
		tap(triggerAnalyticsForAction),
		ignoreElements(),
	);
