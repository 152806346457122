import { cleanupViewLastSeenState } from './cleanup/index.tsx';
import { fetchUserData } from './fetch-user-data/index.tsx';
import { loadViewsLastSeen } from './load-last-seen/index.tsx';
import { updateViewLastSeenStateInternal } from './update/index.tsx';

export const actions = {
	loadViewsLastSeen,
	updateViewLastSeenStateInternal,
	fetchUserData,
	cleanupViewLastSeenState,
};
