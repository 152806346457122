export const RELATIVE = 'RELATIVE' as const;
export const ABSOLUTE = 'ABSOLUTE' as const;

type TimestampDisplaySetting = typeof RELATIVE | typeof ABSOLUTE;

const getDaysElapsed = (date: string | number) =>
	(Date.now() - new Date(date).getTime()) / (1000 * 60 * 60 * 24);

// In accordance with the ADG spec for date formats (https://hello.atlassian.net/wiki/spaces/ADG/pages/195123084/Date+formats+product+1.0+spec)
export const getADGTimestampDisplaySetting = (date: string | number): TimestampDisplaySetting =>
	getDaysElapsed(date) < 8 ? RELATIVE : ABSOLUTE;

const MINUTE = 60;
const HOUR = 60 * 60;
const DAY = 60 * 60 * 24;

type RelativeTimeFormatSingularUnit = 'second' | 'minute' | 'hour' | 'day';

// stolen from react-intl
function getDurationInSeconds(unit?: RelativeTimeFormatSingularUnit): number {
	switch (unit) {
		case 'second':
			return 1;
		case 'minute':
			return MINUTE;
		case 'hour':
			return HOUR;
		default:
			return DAY;
	}
}

const selectUnit = (seconds: number): RelativeTimeFormatSingularUnit => {
	const absValue = Math.abs(seconds);

	if (absValue < MINUTE) {
		return 'second';
	}

	if (absValue < HOUR) {
		return 'minute';
	}

	if (absValue < DAY) {
		return 'hour';
	}

	return 'day';
};

export const getRelativeValueAndUnit = (
	date: string | number,
): [number, RelativeTimeFormatSingularUnit] => {
	const seconds = Math.round((new Date(date).getTime() - Date.now()) / 1000);
	const unit = selectUnit(seconds);
	const unitDuration = getDurationInSeconds(unit);
	const value = Math.round(seconds / unitDuration);
	return [value, unit];
};
