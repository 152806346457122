/**
 * @generated SignedSource<<c967ff04f26085a1498f45a89fe05519>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type actionButton_issueViewWatchers_ActionButtonWithRelay$data = {
  readonly fieldId: string;
  readonly id: string;
  readonly type: string;
  readonly watch: {
    readonly $updatableFragmentSpreads: FragmentRefs<"useWatchesMutation_Mutation_Updatable">;
    readonly count: AGG$Long | null | undefined;
    readonly isWatching: boolean | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "actionButton_issueViewWatchers_ActionButtonWithRelay";
};
export type actionButton_issueViewWatchers_ActionButtonWithRelay$key = {
  readonly " $data"?: actionButton_issueViewWatchers_ActionButtonWithRelay$data;
  readonly " $fragmentSpreads": FragmentRefs<"actionButton_issueViewWatchers_ActionButtonWithRelay">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "actionButton_issueViewWatchers_ActionButtonWithRelay",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "concreteType": "JiraWatch",
      "kind": "LinkedField",
      "name": "watch",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isWatching"
        },
        {
          "kind": "ScalarField",
          "name": "count"
        },
        {
          "kind": "FragmentSpread",
          "name": "useWatchesMutation_Mutation_Updatable"
        }
      ]
    }
  ],
  "type": "JiraWatchesField"
};

(node as any).hash = "e03578819b22cf83a21c8f2cf020ed06";

export default node;
