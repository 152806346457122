import React from 'react';
import { graphql, useFragment } from 'react-relay';
import type { Area } from '@atlassian/jira-issue-view-common-types/src/connect-field-type.tsx';
import { IssueViewMultiGroupPickerField } from '@atlassian/jira-issue-view-layout-multi-group-picker-field/src/ui/index.tsx';
import {
	ISSUE_FIELD_GROUPS_CF_TYPE,
	MULTI_GROUP_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { ui_issueViewLayoutMultiGroupPicker_IssueViewMultiGroupPicker$key } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutMultiGroupPicker_IssueViewMultiGroupPicker.graphql';

export interface IssueViewMultiGroupPickerProps {
	area?: Area;
	fragmentKey: ui_issueViewLayoutMultiGroupPicker_IssueViewMultiGroupPicker$key;
}

export const IssueViewMultiGroupPicker = ({
	area,
	fragmentKey,
}: IssueViewMultiGroupPickerProps) => {
	const data = useFragment<ui_issueViewLayoutMultiGroupPicker_IssueViewMultiGroupPicker$key>(
		graphql`
			fragment ui_issueViewLayoutMultiGroupPicker_IssueViewMultiGroupPicker on JiraMultipleGroupPickerField
			@argumentDefinitions(
				issueViewRelayMultiGroupPickerFieldFlag: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/relay-migration-issue-fields-multi-group-picker.relayprovider"
				}
			) {
				type
				...ui_issueViewLayoutMultiGroupPickerField_IssueViewMultiGroupPickerField
					@include(if: $issueViewRelayMultiGroupPickerFieldFlag)
			}
		`,
		fragmentKey,
	);

	switch (data.type) {
		case MULTI_GROUP_CF_TYPE:
			return <IssueViewMultiGroupPickerField area={area} fragmentKey={data} />;
		case ISSUE_FIELD_GROUPS_CF_TYPE:
			return null; // TODO implement
		default:
			return null;
	}
};
