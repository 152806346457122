/* eslint-disable @atlassian/relay/must-use-inline */
import { stringify } from 'query-string';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/reduce';
import 'rxjs/add/operator/map';
import { graphql, fetchQuery, type Environment as RelayEnvironment } from 'react-relay';
import type { MentionContextIdentifier, MentionsResult } from '@atlaskit/mention';
import { IdentityTeamAri } from '@atlassian/ari/identity/team';
import type { ProjectType } from '@atlassian/jira-common-constants/src/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';
import type { TeamMember } from '@atlassian/jira-issue-view-common-types/src/user-type.tsx';
import { UserMentionProvider } from '@atlassian/jira-mention-provider/src/services/user-mention-provider/index.tsx';
import getRelayEnvironment from '@atlassian/jira-relay-environment/src/index.tsx';
import type {
	issueUserTeamMentionProviderQuery$data,
	issueUserTeamMentionProviderQuery,
} from '@atlassian/jira-relay/src/__generated__/issueUserTeamMentionProviderQuery.graphql';
import {
	toAccountId,
	type AccountId,
	type BaseUrl,
	type IssueKey,
} from '@atlassian/jira-shared-types/src/general.tsx';

const ACCESS_LEVEL = 'CONTAINER';
const USER_TYPE = 'TEAM';

type ServerTeamMember = {
	accountId: string;
	name: string;
};

type ServerUserTeam = {
	name: string;
	avatarUrl: string;
	accountId?: string;
	teamId?: string;
	nickname?: string;
	email?: string;
	members?: ServerTeamMember[];
	memberCount?: number;
	includesYou?: boolean;
};

type UserTeamMention = {
	id: string;
	name: string;
	avatarUrl: string;
	mentionName?: string;
	nickname?: string;
	accessLevel?: string;
	userType?: string;
	context?: {
		members: TeamMember[];
		memberCount: number;
		includesYou: boolean;
		teamLink: string;
	};
};

const getTeamUrl = (teamId: undefined | string) => {
	if (teamId == null || teamId === '') {
		return '';
	}

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	return `${window.location.origin}/jira/people/team/${teamId}?ref=jira$&src=issue`;
};

const transformTeamMember = ({ accountId, name }: ServerTeamMember): TeamMember => ({
	id: accountId,
	name,
});

export const transformUserTeamToMention = ({
	accountId,
	teamId,
	name,
	avatarUrl,
	members,
	memberCount,
	includesYou,
}: ServerUserTeam): UserTeamMention =>
	members
		? {
				id: toAccountId(teamId || ''),
				name,
				accessLevel: ACCESS_LEVEL,
				userType: USER_TYPE,
				avatarUrl,
				context: {
					members: members.map<TeamMember>((member) => transformTeamMember(member)),
					memberCount: memberCount || 0,
					includesYou: includesYou || false,
					teamLink: getTeamUrl(teamId),
				},
			}
		: {
				name,
				mentionName: name,
				nickname: name,
				id: toAccountId(accountId || ''),
				avatarUrl,
			};

const transformMentionableResponse = (
	response: issueUserTeamMentionProviderQuery$data,
): UserTeamMention[] => {
	const mentions = response.jira?.searchUserTeamMention?.edges.map((edge) => {
		const { user, team } = edge?.node || {};
		if (team) {
			return {
				id: team.fullTeam?.id || '',
				name: team.fullTeam?.displayName || '',
				accessLevel: ACCESS_LEVEL,
				userType: USER_TYPE,
				avatarUrl: team.fullTeam?.smallAvatarImageUrl || '',
				context: {
					members:
						team.fullTeam?.members?.nodes?.map((node) => ({
							id: node?.member?.accountId || '',
							name: node?.member?.name || '',
						})) || [],
					memberCount: team.jiraMemberCount || 0,
					includesYou: team.jiraIncludesYou || false,
					teamLink: getTeamUrl(IdentityTeamAri.parse(team.fullTeam?.id || '').teamId),
				},
			};
		}
		return {
			name: user?.name || '',
			mentionName: user?.name || '',
			nickname: user?.name || '',
			id: user?.accountId || '',
			avatarUrl: user?.picture || '',
		};
	});

	return mentions || [];
};

const userTeamMentionQuery = graphql`
	query issueUserTeamMentionProviderQuery(
		$cloudId: ID!
		$siteId: String!
		$query: String!
		$issueKey: String!
		$sessionId: String!
		$maxResults: Int!
		$organizationId: String!
	) {
		jira {
			searchUserTeamMention(
				cloudId: $cloudId
				query: $query
				issueKey: $issueKey
				sessionId: $sessionId
				maxResults: $maxResults
				organizationId: $organizationId
			) {
				edges @required(action: THROW) {
					node @required(action: THROW) {
						team {
							fullTeam(siteId: $siteId) {
								id
								displayName
								smallAvatarImageUrl
								members {
									nodes {
										member {
											id
											accountId
											name
											picture
										}
									}
								}
							}
							jiraIncludesYou
							jiraMemberCount
						}
						user {
							accountId
							name
							picture
						}
					}
				}
			}
		}
	}
`;

export default class IssueUserTeamMentionProvider extends UserMentionProvider {
	private environment: RelayEnvironment;

	private cloudId: string;

	constructor(
		baseUrl: BaseUrl,
		issueKey: IssueKey,
		loggedInAccountId: AccountId | null,
		createTeamPath: string,
		projectType?: ProjectType | null,
		orgId?: string | null,
		cloudId?: string,
		environment?: RelayEnvironment | null,
	) {
		super(baseUrl, issueKey, loggedInAccountId, projectType);
		this.createTeamPath = createTeamPath;
		this.orgId = orgId || '';
		this.cloudId = cloudId || '';
		this.environment = environment || getRelayEnvironment();
	}

	// @ts-expect-error - TS2564 - Property 'teamHighlightEnabled' has no initializer and is not definitely assigned in the constructor.
	teamHighlightEnabled: boolean;

	createTeamPath: string;

	orgId: string | null;

	mentionTypeaheadHighlightEnabled = () => true;

	mentionTypeaheadCreateTeamPath = () => this.createTeamPath;

	getQueryString = (query?: string, contextIdentifier?: MentionContextIdentifier) =>
		stringify({
			query: query || '',
			issueKey: this.issueKey,
			organizationId: this.orgId,
			maxResults: 20,
			...contextIdentifier,
		});

	getUrl = (queryString: string) =>
		`${this.baseUrl}/rest/internal/2/userteam/mention/search?${queryString}`;

	async search(query: string, context?: MentionContextIdentifier): Promise<MentionsResult> {
		const queryString = this.getQueryString(query, context);
		const url = this.getUrl(queryString);
		this.activeSearches.add(query || '');

		if (fg('agg-mention-provider')) {
			const variables = {
				query: query || '',
				issueKey: this.issueKey,
				organizationId: this.orgId || '',
				maxResults: 20,
				sessionId: context?.sessionId || '',
				cloudId: this.cloudId,
				siteId: this.cloudId,
			};
			const environment = this.environment;
			const response = await fetchQuery<issueUserTeamMentionProviderQuery>(
				environment,
				userTeamMentionQuery,
				variables,
			)
				.toPromise()
				.catch((error) => {
					fireErrorAnalytics({
						meta: {
							id: 'jiraUserTeamMentionProviderFetchError',
							packageName: 'jiraUserTeamMentionProvider',
							teamName: 'Bento',
						},
						error,
					});
				});
			const transformedMentionableResponse = response ? transformMentionableResponse(response) : [];

			return new Promise((resolve, reject) => {
				try {
					resolve({
						query: query || '',
						mentions: transformedMentionableResponse,
					});
				} catch (error) {
					reject(error);
				}
			});
		}

		return (
			// @ts-expect-error - TS2769 - No overload matches this call.
			fetchJson$(url)
				// @ts-expect-error - TS2571 - Object is of type 'unknown'.
				.mergeMap((users) => users.map(transformUserTeamToMention))
				// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/js/no-reduce-accumulator-spread
				.reduce<Array<any>>((mentions, mention) => [...mentions, mention], [])
				.map((mentions) => ({ query: query || '', mentions }))
				.toPromise()
		);
	}
}
