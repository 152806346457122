import React, { type ReactNode, type ReactElement, useMemo } from 'react';
import type { ActionMeta } from '@atlassian/jira-common-components-picker/src/model.tsx';
import type { FieldOptionsFilter } from '@atlassian/jira-issue-field-injections/src/controllers/types.tsx';
import TagView from '@atlassian/jira-issue-view-internal-tagview/src/tag-view.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import SelectInlineEdit from '../common/select-inline-edit/index.tsx';
import type { Option, SelectValueShape } from '../common/select-inline-edit/select-field/types.tsx';
import type { SelectInlineEditProps } from '../common/select-inline-edit/types.tsx';

export type Props = {
	isUserTag?: boolean;
	issueKey?: IssueKey; // TODO - make issueKey mandatory in BENTO-11149
} & Omit<SelectInlineEditProps, 'onChange'> & {
		customReadView?: (value: Props['value']) => ReactElement;
		customEditView?: (props: Props) => ReactElement;
		renderAppEditView?: () => ReactElement;
		value: SelectValueShape[];
		tagAppearance?: string;
		validate: (arg1: SelectValueShape[]) => ReactNode;
		titleView?: ReactNode;
		fieldContentWrapper?: (arg1: { children: ReactNode; topMargin?: number }) => ReactElement;
		onChange: (arg1: SelectValueShape[], arg2?: ActionMeta<Option> | undefined) => void;
		onDataRequest?: (arg1: { isInitial: boolean }) => void;
		onDataLoaded?: (arg1: { isInitial: boolean }) => void;
		showNewLozenge?: boolean;
		optionsFilter?: FieldOptionsFilter;
	};

export type MultiSelectValueItemShape = {
	content: string;
	value: string;
	key?: string; // react array key, can be mapped to anything that uniquely identifies the item.,
	href?: string;
	avatarUrl?: string;
	elemBefore?: ReactNode;
	tag?: {
		appearance: string;
		elemBefore: ReactNode;
	};
};

const MultiSelectInlineEditView = (props: Props) => {
	const renderReadView = () => {
		const {
			value = [],
			noValueText,
			tagAppearance,
			isMobile,
			customReadView,
			isEditable,
			isUserTag,
		} = props;

		return !customReadView ? (
			<TagView
				value={value?.map(({ value: val, content, color, elemBefore, href }) => ({
					value: val.toString(),
					content,
					color,
					elemBefore,
					href,
				}))}
				noValueText={noValueText}
				tagAppearance={tagAppearance}
				shouldHideLinks={isMobile}
				truncateValues={!isEditable && isUserTag}
				isUserTag={isUserTag}
			/>
		) : (
			customReadView(value)
		);
	};

	const {
		validate,
		invalidMessage,
		value,
		onChange,
		tagAppearance,
		onDataRequest,
		onDataLoaded,
		customReadView,
		customEditView,
		isUserTag,
		showNewLozenge,
		...restProps
	} = props;

	const errorMessage = useMemo(
		() => validate(value) || invalidMessage,
		[value, validate, invalidMessage],
	);

	const renderEditView = customEditView
		? () =>
				customEditView({
					value,
					onChange,
					validate,
					customReadView,
					invalidMessage: errorMessage,
					...restProps,
				})
		: undefined;

	return (
		<SelectInlineEdit
			{...restProps}
			value={value}
			isMulti
			onChangeMulti={onChange}
			invalidMessage={errorMessage}
			renderReadView={renderReadView}
			renderEditView={renderEditView}
			onDataRequest={onDataRequest}
			onDataLoaded={onDataLoaded}
			showNewLozenge={showNewLozenge}
		/>
	);
};

MultiSelectInlineEditView.displayName = 'MultiSelectInlineEditView';

MultiSelectInlineEditView.defaultProps = {
	tagAppearance: 'default',
	validate: () => null,
	hasAutocomplete: true,
	debounceFetchSuggestionsTime: 300,
};

export default MultiSelectInlineEditView;
