/**
 * @generated SignedSource<<53ec575764aff7d345f9576bffe1cb98>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type detailsView_servicedeskCustomerServiceIssueViewPanel$data = {
  readonly __typename: "CustomerServiceCustomDetailValues";
  readonly results: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"readOnlyCustomDetail_servicedeskCustomerServiceCustomDetails">;
  }>;
  readonly " $fragmentType": "detailsView_servicedeskCustomerServiceIssueViewPanel";
} | {
  readonly __typename: "QueryError";
  readonly " $fragmentSpreads": FragmentRefs<"throwOnQueryError_servicedeskCustomerServiceCommon">;
  readonly " $fragmentType": "detailsView_servicedeskCustomerServiceIssueViewPanel";
} | {
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  readonly __typename: "%other";
  readonly " $fragmentType": "detailsView_servicedeskCustomerServiceIssueViewPanel";
};
export type detailsView_servicedeskCustomerServiceIssueViewPanel$key = {
  readonly " $data"?: detailsView_servicedeskCustomerServiceIssueViewPanel$data;
  readonly " $fragmentSpreads": FragmentRefs<"detailsView_servicedeskCustomerServiceIssueViewPanel">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "detailsView_servicedeskCustomerServiceIssueViewPanel",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__typename"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "concreteType": "CustomerServiceCustomDetailValue",
          "kind": "LinkedField",
          "name": "results",
          "plural": true,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "id"
            },
            {
              "kind": "FragmentSpread",
              "name": "readOnlyCustomDetail_servicedeskCustomerServiceCustomDetails"
            }
          ]
        }
      ],
      "type": "CustomerServiceCustomDetailValues"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "throwOnQueryError_servicedeskCustomerServiceCommon"
        }
      ],
      "type": "QueryError"
    }
  ],
  "type": "CustomerServiceCustomDetailValuesQueryResult",
  "abstractKey": "__isCustomerServiceCustomDetailValuesQueryResult"
};

(node as any).hash = "9c3a4b1bb0a13f8da010d416eedf07b7";

export default node;
