import React from 'react';
import { styled } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import { token } from '@atlaskit/tokens';
import Button from '@atlaskit/button';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { SummaryItem } from '@atlassian/jira-development-summary-common/src/ui/summary-item/index.tsx';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import type { main_developmentSummaryVulnerability_Vulnerability$key } from '@atlassian/jira-relay/src/__generated__/main_developmentSummaryVulnerability_Vulnerability.graphql';
import { PriorityStatusLozenge } from '@atlassian/jira-software-security-common/src/ui/status-lozenge/index.tsx';
import messages from './messages.tsx';
import { VulnerabilityDropdown } from './vulnerability-dropdown/index.tsx';

export type Props = {
	vulnerabilityData: main_developmentSummaryVulnerability_Vulnerability$key;
};

const SUMMARY_ITEM_TEST_ID = 'development-summary-vulnerability.ui.summary-item';

export const Vulnerability = ({ vulnerabilityData }: Props) => {
	const data = useFragment(
		graphql`
			fragment main_developmentSummaryVulnerability_Vulnerability on DevOpsSecurityVulnerabilityDetails
			@relay(plural: true) {
				...vulnerabilityDropdown_developmentSummaryVulnerability
				status
				url
			}
		`,
		vulnerabilityData,
	);
	const count = data.length;
	const { formatMessage } = useIntl();
	const title = (
		<FormattedMessage
			{...messages.summaryItemTitle}
			values={{
				count,
				styledCount: <BoldFont>{count}</BoldFont>,
			}}
		/>
	);
	const statusLozenge =
		// @ts-expect-error - Type '(DevOpsSecurityVulnerabilityStatus | null | undefined)[]' is not assignable to type '(DevOpsSecurityVulnerabilityStatus | null)[]'.
		count > 0 ? <PriorityStatusLozenge statuses={data.map((detail) => detail.status)} /> : null;
	const oneClickUrl = count === 1 ? data[0]?.url : null;
	const hasManyVulnerabilities = count > 1;

	return (
		<>
			{hasManyVulnerabilities ? (
				<VulnerabilityDropdown
					vulnerabilityDropdownData={data}
					renderDropdownTrigger={({ onClick, isSelected, ref, ...dropdownTriggerProps }) => (
						<SummaryItem
							title={title}
							itemType="vulnerability"
							itemCount={count}
							oneClickUrl={oneClickUrl}
							oneClickToolTipContent={formatMessage(messages.summaryItemOneClickTooltip)}
							secondaryData={statusLozenge}
							secondaryHover={
								<TriggerButton
									{...dropdownTriggerProps}
									isSelected={isSelected}
									onClick={onClick}
									appearance="subtle"
									iconBefore={
										<ChevronDownIcon
											size="medium"
											label={formatMessage(messages.summaryItemDropdownTriggerIconLabel)}
										/>
									}
								/>
							}
							reference={ref}
							onClick={onClick}
							forceVisibleSecondary={isSelected}
							data-testid={SUMMARY_ITEM_TEST_ID}
						/>
					)}
				/>
			) : (
				<SummaryItem
					title={title}
					itemType="vulnerability"
					itemCount={count}
					oneClickUrl={oneClickUrl}
					oneClickToolTipContent={formatMessage(messages.summaryItemOneClickTooltip)}
					secondaryData={statusLozenge}
					data-testid={SUMMARY_ITEM_TEST_ID}
				/>
			)}
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const TriggerButton = styled(Button)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		width: `${gridSize * 3}px`,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const BoldFont = styled.span({
	fontWeight: token('font.weight.medium'),
});
