import { getCommandPaletteEvent } from '../get-command-palette-event/index.tsx';
import type { CommandPaletteEvent, EventDataTypeBase } from '../types.tsx';

export const commandPaletteDispatchEvent = <EventDataType extends EventDataTypeBase>(
	data: EventDataType,
) => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	document.dispatchEvent(
		new CustomEvent(getCommandPaletteEvent(data.id), {
			detail: data,
		}),
	);
};

export const commandPaletteSubscribeEvent = <EventDataType extends EventDataTypeBase>(
	eventId: string,
	callbackFunction: (data: EventDataType) => void,
) => {
	const eventListener: EventListenerOrEventListenerObject = (event) => {
		// Type casted to CommandPaletteEvent<EventDataType> as addEventListener does not support typed CustomEvents
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const customEvent = event as CommandPaletteEvent<EventDataType>;
		callbackFunction(customEvent.detail);
	};

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	document.addEventListener(getCommandPaletteEvent(eventId), eventListener);

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	return () => document.removeEventListener(getCommandPaletteEvent(eventId), eventListener);
};
