import React from 'react';
import UFOLoadHold from '@atlaskit/react-ufo/load-hold';
import { CloneIssueDialogBoundary } from '@atlassian/jira-clone-issue-dialog/src/ui/async.tsx';
import type CloneIssueDialogType from '@atlassian/jira-clone-issue-dialog/src/ui/index.tsx';
import { useCloneStatus } from '@atlassian/jira-clone-issue/src/services/clone/context.tsx';
import { cloneStatusIsInProgress } from '@atlassian/jira-clone-issue/src/services/constants.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { hideCloneModal } from '@atlassian/jira-issue-view-store/src/actions/clone-modal-actions.tsx';
import {
	canModifyReporterPermissionsSelector,
	canAssignIssuesPermissionsSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/permissions-selector.tsx';
import { hasChildrenIssuesSelector } from '@atlassian/jira-issue-view-store/src/issue-field/state/selectors/children-issue-selector.tsx';
import { isClassicOrNextGenEpicSelector } from '@atlassian/jira-issue-view-store/src/issue-field/state/selectors/epic-selector.tsx';
import { isCloneModalOpen } from '@atlassian/jira-issue-view-store/src/selectors/clone-modal-selector.tsx';
import { linkedConfluencePagesCountSelector } from '@atlassian/jira-issue-view-store/src/selectors/confluence-content-selector.tsx';
import { hasCombinedIssueLinksSelector } from '@atlassian/jira-issue-view-store/src/selectors/issue-links-selector.tsx';
import { hasSubtasksSelector } from '@atlassian/jira-issue-view-store/src/selectors/subtasks-selector.tsx';
import { webLinksSelector } from '@atlassian/jira-issue-view-store/src/selectors/web-links-selector.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type { Props, StateProps } from './types.tsx';

// TODO reevaluate phase - forPaint is simply for initial parity
export const CloneIssueDialog = lazyForPaint<typeof CloneIssueDialogType>(
	() =>
		import(/* webpackChunkName: "async-clone-issue-dialog" */ '@atlassian/jira-clone-issue-dialog'),
);

const CloneIssueModalView = ({
	isOpen,
	isClassicOrNextGenEpic,
	hideModal,
	shouldAskChildren,
	shouldAskSubtasks,
	shouldAskLinks,
	canModifyReporter,
	canAssignIssues,
}: Props) => {
	const [cloneFetchStatus] = useCloneStatus();
	const shouldHold = cloneFetchStatus ? cloneStatusIsInProgress(cloneFetchStatus) : false;

	return (
		<>
			<UFOLoadHold name="issue-clone-hold" hold={shouldHold} />
			{isOpen ? (
				<CloneIssueDialogBoundary packageName="issue">
					<CloneIssueDialog
						isClassicOrNextGenEpic={isClassicOrNextGenEpic}
						shouldAskChildren={shouldAskChildren}
						shouldAskSubtasks={shouldAskSubtasks}
						shouldAskLinks={shouldAskLinks}
						canModifyReporter={canModifyReporter}
						canAssignIssues={canAssignIssues}
						onClose={hideModal}
					/>
				</CloneIssueDialogBoundary>
			) : null}
		</>
	);
};

export const CloneIssueModal = connect(
	(state: State): StateProps => {
		const hasIssueLinksOrExternalLinks = hasCombinedIssueLinksSelector(state);
		const hasWebLinks = webLinksSelector(state).length > 0;

		// We don't look for mentioned pages because the clone will naturally have a new issue key
		const hasConfluencePageLinks = linkedConfluencePagesCountSelector(state) > 0;

		return {
			isOpen: isCloneModalOpen(state),
			isClassicOrNextGenEpic: isClassicOrNextGenEpicSelector(state),
			shouldAskChildren: hasChildrenIssuesSelector(state),
			shouldAskSubtasks: hasSubtasksSelector(state),
			shouldAskLinks: hasIssueLinksOrExternalLinks || hasWebLinks || hasConfluencePageLinks,
			canModifyReporter: canModifyReporterPermissionsSelector(state),
			canAssignIssues: canAssignIssuesPermissionsSelector(state),
		};
	},
	{
		hideModal: hideCloneModal,
	},
)(CloneIssueModalView);

export default CloneIssueModal;
