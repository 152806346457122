import { UFOExperience, ExperienceTypes, ExperiencePerformanceTypes } from '@atlassian/ufo';

export const attachmentsUploadCredentialsExperience = new UFOExperience(
	'jsm-issue-view-attachments-upload-credentials',
	{
		type: ExperienceTypes.Operation,
		performanceType: ExperiencePerformanceTypes.Custom,
	},
);

export const attachmentsReadCredentialsExperience = new UFOExperience(
	'jsm-issue-view-attachments-read-credentials',
	{
		type: ExperienceTypes.Operation,
		performanceType: ExperiencePerformanceTypes.Custom,
	},
);
