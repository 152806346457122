import type { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';
import type { IssueType } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const transformIssueTypesFromProjectResponse = (projectResponse: any): IssueType[] =>
	projectResponse.issueTypes;

export const getProjectIssueTypes = (baseUrl: string, projectKey: string) => {
	const url = `${baseUrl}/rest/api/3/project/${projectKey}`;
	return fetchJson$(url).map(transformIssueTypesFromProjectResponse);
};

const attemptChangeIssueType = (baseUrl: string, issueKey: string, targetIssueType: number) =>
	fetchJson$(`${baseUrl}/rest/internal/2/issue/${issueKey}/changeissuetype`, {
		method: 'post',
		body: JSON.stringify({ targetIssueTypeId: targetIssueType }),
	});

export const attemptSimpleIssueTypechange = (
	baseUrl: string,
	issueKey: string,
	targetTypeId: number,
): Observable<boolean> =>
	attemptChangeIssueType(baseUrl, issueKey, targetTypeId).map(
		// @ts-expect-error - TS2571 - Object is of type 'unknown'.
		(response) => response.changeSuccessful,
	);
