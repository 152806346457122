import React from 'react';
import type { CustomGlyphProps } from '@atlaskit/icon';

export const keyGlyph = (props: CustomGlyphProps) => (
	<svg
		{...props}
		width="16"
		height="16"
		viewBox="4 4 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M5.22889 14.8718V13.0773L5.81078 12.3774L7.51577 14.8718H8.98881L6.69786 11.5717L8.83825 9H7.47101L5.30214 11.6368H5.22889V9H4V14.8718H5.22889Z"
			fill="currentColor"
		/>
		<path
			d="M13.8067 13.8586H11.1455V12.3733H13.6562V11.4293H11.1455V10.0132H13.8067V9H9.91658V14.8718H13.8067V13.8586Z"
			fill="currentColor"
		/>
		<path
			d="M17.9573 14.8718V12.6785L20 9H18.6694L17.3795 11.47H17.3062L16.0122 9H14.6816L16.7284 12.6785V14.8718H17.9573Z"
			fill="currentColor"
		/>
	</svg>
);
