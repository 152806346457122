import React from 'react';
import { styled } from '@compiled/react';
import UnlockIcon from '@atlaskit/icon/glyph/unlock';
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { defineMessages } from '@atlassian/jira-intl';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import { AnalyticsEventToProps } from '@atlassian/jira-product-analytics-bridge';

const messages = defineMessages({
	removeSecurityLevel: {
		id: 'issue-field-security-level.security-level-view.dropdown.remove-item.remove-security-level',
		defaultMessage: 'Remove security level',
		description:
			'Button label - when clicked it will remove the current security level for an issue',
	},
});

type Props = {
	onRemove: () => void;
};

const BaseRemoveItem = (props: Props) => {
	const intl = useIntl();
	return (
		<RemoveSecurityLevelItemContainer
			onClick={props.onRemove}
			data-testid="issue-field.security-level.ui.security-level-view.dropdown.remove-item.remove-security-level-item-container"
			role="button"
		>
			{}
			<Box xcss={styles}>
				<UnlockIcon label="" />
			</Box>
			<div>{intl.formatMessage(messages.removeSecurityLevel)}</div>
		</RemoveSecurityLevelItemContainer>
	);
};

export const RemoveItem = AnalyticsEventToProps('removeItem', {
	onRemove: 'clicked',
})(BaseRemoveItem);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RemoveSecurityLevelItemContainer = styled.div({
	alignItems: 'center',
	display: 'flex',
	fontWeight: 'initial',
	position: 'fixed',
	bottom: 0,
	width: '100%',
	padding: token('space.100', '8px'),
	boxSizing: 'border-box',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderTop: `1px solid ${token('color.border', colors.N40)}`,
	cursor: 'pointer',
	borderBottomLeftRadius: '4px',
	borderBottomRightRadius: '4px',
});
const styles = xcss({ marginRight: 'space.150' });
