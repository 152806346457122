import React from 'react';
import { useFragment, graphql } from 'react-relay';
import { CreatedDate as CreatedDateFieldReadView } from '@atlassian/jira-created-date/src/ui/read/main.tsx';
import type { ui_issueViewLayoutMetaCreatedDateField_IssueViewCreatedDateField$key } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutMetaCreatedDateField_IssueViewCreatedDateField.graphql';

export interface IssueViewCreatedDateFieldProps {
	fragmentKey: ui_issueViewLayoutMetaCreatedDateField_IssueViewCreatedDateField$key;
}

export const IssueViewCreatedDateField = ({ fragmentKey }: IssueViewCreatedDateFieldProps) => {
	const data = useFragment<ui_issueViewLayoutMetaCreatedDateField_IssueViewCreatedDateField$key>(
		graphql`
			fragment ui_issueViewLayoutMetaCreatedDateField_IssueViewCreatedDateField on JiraDateTimePickerField {
				dateTime
			}
		`,
		fragmentKey,
	);

	if (!data.dateTime) {
		return null;
	}

	return <CreatedDateFieldReadView date={data.dateTime} />;
};
