import React from 'react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

const successColor = token('color.icon.success', colors.G300);
const backgroundColor = token('color.icon.inverse', colors.N0);

const SuccessNonProductionIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="100%"
		height="100%"
		fill="none"
		viewBox="0 0 16 16"
	>
		<circle r="7" cx="8" cy="8" fill={backgroundColor} />

		<path
			fill={successColor}
			fillRule="evenodd"
			d="M14 8A6 6 0 112 8a6 6 0 0112 0zm2 0A8 8 0 110 8a8 8 0 0116 0zm-4.293-1.293a1 1 0 00-1.414-1.414L7 8.586 5.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
			clipRule="evenodd"
		/>
	</svg>
);

SuccessNonProductionIcon.displayName = 'SuccessNonProductionIcon';
export default SuccessNonProductionIcon;
