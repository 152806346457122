import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	responders: {
		id: 'issue-history.history-items.history-item.responders-history-item.responders',
		defaultMessage: 'Responders',
		description:
			'subject of a responder updation event that will be bolded. Eg: John added <bold>Responders</bold>',
	},
	respondersLowercase: {
		id: 'issue-history.history-items.history-item.responders-history-item.responders-lowercase',
		defaultMessage: 'responders',
		description:
			'subject of a responder updation event that will be bolded. Eg: John added <bold>responders</bold>',
	},
	added: {
		id: 'issue-history.history-items.history-item.responders-history-item.added',
		defaultMessage: 'added {responders}',
		description:
			'Description when responders are added. {responders} corresponds to the bolded text in the key  issue-history.history-items.history-item.responders-history-item.responders',
	},
	removed: {
		id: 'issue-history.history-items.history-item.responders-history-item.removed',
		defaultMessage: 'removed {responders}',
		description:
			'Description when responders are removed. {responders} corresponds to the bolded text in the key  issue-history.history-items.history-item.responders-history-item.responders',
	},
});
