import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	errorFlagTitle: {
		id: 'invite-and-assign.controllers.assign-invited-user.error-flag-title',
		defaultMessage: 'We couldn’t assign this issue',
		description: 'Flag title: Is shown to users if the assign invited user fails',
	},
	errorFlagDescription: {
		id: 'invite-and-assign.controllers.assign-invited-user.error-flag-description',
		defaultMessage:
			'Try adding the assignee again. If this problem persists, contact your site administrator.',
		description: 'Flag description: Is shown to users if the assign invited user fails',
	},
	errorFlagTitleIssueTermRefresh: {
		id: 'invite-and-assign.controllers.assign-invited-user.error-flag-title-issue-term-refresh',
		defaultMessage: 'We couldn’t assign this work item',
		description: 'Flag title: Is shown to users if the assign invited user fails',
	},
});
