import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/from';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/switchMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import fetchDetailsForIssues from '../../services/fetch-details-for-issues/index.tsx';
import { getBaseUrl } from '../../state/context/selectors.tsx';
import {
	type FetchDetailsForIssuesRequestAction,
	FETCH_DETAILS_FOR_ISSUES_REQUEST,
	fetchDetailsForIssuesSuccess,
} from '../../state/entities/actions.tsx';
import { getAllIssues } from '../../state/entities/selectors.tsx';
import type { State } from '../../state/types.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	action$: ActionsObservable<FetchDetailsForIssuesRequestAction>,
	store: MiddlewareAPI<State>,
) =>
	action$.ofType(FETCH_DETAILS_FOR_ISSUES_REQUEST).switchMap(() => {
		const state = store.getState();
		const baseUrl = getBaseUrl(state);
		const issues = getAllIssues(state);
		const issueKeys = issues
			.map((issue) => issue.issueKey)
			.filter((issueKey) => issueKey !== undefined);

		// @ts-expect-error - TS2345 - Argument of type '(string | undefined)[]' is not assignable to parameter of type 'string[]'.
		return Observable.from(fetchDetailsForIssues(baseUrl, issueKeys))
			.map((detailsForIssues) => fetchDetailsForIssuesSuccess(detailsForIssues))
			.catch((error) => {
				log.safeErrorWithoutCustomerData(
					'issue.views.common.child-issues-panel.fetch-details-for-issues',
					'Failed to fetch details for issues',
					error,
				);
				return Observable.empty<never>();
			});
	});
