/**
 * @generated SignedSource<<16604de935f651cc19d82b7e37df401e>>
 * @relayHash 123792dff837a7336eebac3310ed82ae
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID a99754ddf56960c4d16963c1cacf8aae981e794b261d2f77608d68125dde4ca1

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { view_DesignPanelContentQuery } from './view_DesignPanelContentQuery.graphql';

const node: PreloadableConcreteRequest<view_DesignPanelContentQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "a99754ddf56960c4d16963c1cacf8aae981e794b261d2f77608d68125dde4ca1",
    "metadata": {},
    "name": "view_DesignPanelContentQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
