import { createSelector } from 'reselect';
import type { Context, State } from '../../types.tsx';

export const contextSelector = (state: State): Context => state.releasesContext;

export const issueContextSelector = createSelector(
	contextSelector,
	(context) => context.issueContext,
);

export const userSelector = createSelector(contextSelector, (context) => context.user);

export const isAnonymousSelector = createSelector(userSelector, (user) => user === null);
