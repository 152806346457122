import { useCallback } from 'react';
import { useLocalStorage } from '@atlassian/jira-insights-common/src/services/use-local-storage/main.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';

const LOCAL_STORAGE_KEY = 'jpd-viewedIdeaPanelChangeboarding';

export const useIdeaPanelChangeBoarding = () => {
	const accountId = useAccountId();
	const [viewed, setViewed] = useLocalStorage<number>(LOCAL_STORAGE_KEY, accountId || 'none', {
		defaultValue: 0,
	});

	const handleSetViewed = useCallback(() => {
		setViewed(Date.now());
	}, [setViewed]);

	return [viewed, handleSetViewed] as const;
};
