import React, { useCallback, useMemo } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { useFlagsService } from '@atlassian/jira-flags';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { ResolutionOption } from './common/types.tsx';
import {
	mapResolutionOptionToOption,
	mapOptionToResolutionOption,
	mapAndFilterResolutionOptions,
} from './common/utils.tsx';
import messages from './messages.tsx';
import { useResolutionField } from './services/index.tsx';
import { ResolutionEditView } from './ui/edit/popover/index.tsx';
import { ResolutionReadView } from './ui/read/index.tsx';

type Props = {
	issueKey: IssueKey;
};

export const ResolutionField = ({ issueKey }: Props) => {
	const { showFlag } = useFlagsService();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onFailure = useCallback(() => {
		showFlag({
			type: 'error',
			title: [
				fg('jira-issue-terminology-refresh-m3')
					? messages.editResolutionFailedTitleIssueTermRefresh
					: messages.editResolutionFailedTitle,
				{ issueKey },
			],
			description: messages.editResolutionFailedDescription,
		});
	}, [issueKey, showFlag]);

	const [{ value, fieldConfig }, { saveValue }] = useResolutionField({ issueKey, onFailure });

	const { allowedValues = [], isEditable = false } = fieldConfig || {};

	const selected = useMemo(() => (value ? mapResolutionOptionToOption(value) : null), [value]);
	const options = useMemo(
		() => mapAndFilterResolutionOptions(value, allowedValues),
		[allowedValues, value],
	);

	const isEditViewEnabled = isEditable && options.length >= 1;

	const onChange = useCallback(
		(option: ResolutionOption) => {
			const optionExternal = mapOptionToResolutionOption(option);
			const analyticsEvent = createAnalyticsEvent({
				action: 'changed',
				actionSubject: 'select',
			});
			fireUIAnalytics(analyticsEvent, 'resolutionField');
			saveValue(optionExternal, null, analyticsEvent);
		},
		[createAnalyticsEvent, saveValue],
	);
	if (fieldConfig === null || fieldConfig === undefined) {
		return null;
	}

	return isEditViewEnabled ? (
		<ResolutionEditView onChange={onChange} allowedOptions={options} value={selected} />
	) : (
		<ResolutionReadView value={value} isLabelClickEnabled={false} />
	);
};
