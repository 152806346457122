import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { getUserAuthUrl } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-media-user-auth/index.tsx';
import { IssueViewFetchError } from '@atlassian/jira-issue-view-errors/src/common/utils/issue-view-fetch-error/index.tsx';

export const fetchUserAuth$ = (_baseUrl?: string) =>
	fetchJson$(getUserAuthUrl()).catch((error) => {
		throw new IssueViewFetchError(error, getUserAuthUrl());
	});

export const fetchUserAuth = (_baseUrl?: string) => fetchJson(getUserAuthUrl());
