import React, { type ReactNode } from 'react';
import Heading from '@atlaskit/heading';
import { Box, Stack, xcss } from '@atlaskit/primitives';

export const AIContextItem = ({ title, children }: AIContextItemProps) => {
	return (
		<Stack xcss={aiContextItemCss} role="paragraph">
			{title && (
				<Box xcss={headerCss}>
					{typeof title === 'string' ? <Heading size="xsmall">{title}</Heading> : title}
				</Box>
			)}
			{children}
		</Stack>
	);
};

const aiContextItemCss = xcss({
	marginBottom: 'space.150',
});

const headerCss = xcss({
	marginBottom: 'space.050',
});

export type AIContextItemProps = {
	title?: string | ReactNode;
	children: ReactNode;
};
