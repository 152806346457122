import type { ViewIssueData as IssueServiceViewIssueData } from '@atlassian/jira-issue-fetch-services/src/types.tsx';
import type { RemoteIssueLinkTransformResult } from '@atlassian/jira-issue-gira-transformer-types/src/common/types/remote-issue-links.tsx';
import type { ViewIssueData } from '@atlassian/jira-issue-gira-transformer-types/src/common/types/server-data.tsx';

export const transformData = (
	issue: ViewIssueData | IssueServiceViewIssueData,
): RemoteIssueLinkTransformResult => {
	const { remoteLinks } = issue;
	if (!remoteLinks) {
		// TODO: Proper error handling in NIMBUS-210
		// as "{remoteLinks: null}" indicates there is a error when fetching remoteLinks
		// we'd like to log the error and show user a flag
		return { remoteLinks: { isLinkingEnabled: false, remoteLinkedIssues: [] } };
	}
	return { remoteLinks };
};
