import React from 'react';
import ExportBlockedInfo from './export-blocked-info/index.tsx';

const issueExportBlockedFooterGroup = {
	name: '',
	key: 'IssueExportBlockedFooterGroup',
	items: [<ExportBlockedInfo key="export-blocked-info" />],
};

export default issueExportBlockedFooterGroup;
