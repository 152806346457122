import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	errorFlagTitle: {
		id: 'business-goal-picker.error-flag-title',
		defaultMessage: "Something's gone wrong",
		description: 'Title of the error flag when opening the goal picker fails',
	},
	errorFlagDescription: {
		id: 'business-goal-picker.error-flag-description',
		defaultMessage:
			'Our team has been notified. If the problem persists, please contact Atlassian Support.',
		description: 'Description of the error flag when opening the goal picker fails',
	},
});
