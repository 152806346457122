import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	emptyState: {
		id: 'development-summary-feature-flag.empty.empty-state',
		defaultMessage: 'Feature Flag',
		description:
			"Placeholder for the feature flag row in the releases field. 'Feature Flag' is a technical term that is most often not translated. It is acceptable to transliterate this term to another alphabet/script.",
	},
	addFeatureFlagTitle: {
		id: 'development-summary-feature-flag.empty.add-feature-flag-title',
		defaultMessage: 'Add feature flag',
		description:
			"The tool tip message indicating that clicking the link will result in an action to add a feature flag. 'Feature Flag' is a technical term that is most often not translated. It is acceptable to transliterate this term to another alphabet/script.",
	},

	featureFlagActionDropdownIconLabel: {
		id: 'development-summary-feature-flag.empty.feature-flag-action-dropdown-icon-label',
		defaultMessage: 'Open feature flag action dropdown',
		description:
			"The alt text describing the icon on a drop down. 'Feature Flag' is a technical term that is most often not translated. It is acceptable to transliterate this term to another alphabet/script.",
	},
	tooltip: {
		id: 'development-summary-feature-flag.empty.tooltip',
		defaultMessage: 'Open feature flag',
		description:
			"The tool tip message indicating that clicking the link will lead to external link of a feature flag. 'Feature Flag' is a technical term that is most often not translated. It is acceptable to transliterate this term to another alphabet/script.",
	},
});
