import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const issueSmartRequestSummaryTriggerEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(/* webpackChunkName: "async-issue-smart-request-summary-trigger" */ './src').then(
			(module) => module.SmartRequestSummaryTrigger,
		),
	),
	getPreloadProps: () => ({}),
});
