// ATTACHMENT_PICKER_POPUP_OPEN_SUCCESS
export const ATTACHMENT_PICKER_POPUP_OPEN_SUCCESS = 'ATTACHMENT_PICKER_POPUP_OPEN_SUCCESS' as const;

export const attachmentPickerPopupOpenSuccess = () => ({
	type: ATTACHMENT_PICKER_POPUP_OPEN_SUCCESS,
});

export type AttachmentPickerPopupOpenSuccess = ReturnType<typeof attachmentPickerPopupOpenSuccess>;

// ATTACHMENT_PICKER_POPUP_CLOSE_SUCCESS
export const ATTACHMENT_PICKER_POPUP_CLOSE_SUCCESS =
	'ATTACHMENT_PICKER_POPUP_CLOSE_SUCCESS' as const;

export const attachmentPickerPopupCloseSuccess = () => ({
	type: ATTACHMENT_PICKER_POPUP_CLOSE_SUCCESS,
});

export type AttachmentPickerPopupCloseSuccess = ReturnType<
	typeof attachmentPickerPopupCloseSuccess
>;

export type AttachmentPickerPopupAction =
	| AttachmentPickerPopupOpenSuccess
	| AttachmentPickerPopupCloseSuccess;
