import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { BaseUrl, ProjectId, IssueId } from '@atlassian/jira-shared-types/src/general.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (baseUrl: BaseUrl, projectId: ProjectId, parentId: IssueId, childId: IssueId) =>
	performPostRequest(
		`${baseUrl}/rest/internal/simplified/1.0/projects/${projectId}/issues/${parentId}/children`,
		{
			body: JSON.stringify({
				issueIds: [childId],
			}),
		},
	);
