import { sha256 } from 'js-sha256';
import memoizeOne from 'memoize-one';

import { getTenantContext_DEPRECATED_DO_NOT_USE } from '@atlassian/jira-common-util-get-tenant-context/src/index.tsx';

export const getCloudId = memoizeOne(() => {
	const { cloudId } = getTenantContext_DEPRECATED_DO_NOT_USE();
	return cloudId;
});

/**
 * encrypt String attribute using SHA256 by concating cloud id as prefix, where
 * cloudId call is memoized
 * @returns string of the hash
 */
export const memoizedHashString = (attribute: string): string => {
	const cloudId = getCloudId();
	const hash = sha256(`${cloudId}_${attribute.trim()}`);

	return hash;
};
