// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction
/* eslint-disable jira/i18n/id-named-by-package */
import { defineMessages } from '@atlassian/jira-intl';

// FIXME: JIV-15410 cyclic dependency gremlins - this file might need a better placement

export const genericMessages = defineMessages({
	noValue: {
		id: 'issue.context-items.text-for-edit-view-when-no-value-is-provided',
		defaultMessage: 'None',
		description: '',
	},
	version_no_value_placeholder: {
		id: 'issue.version.text-no-value',
		defaultMessage: 'Select version',
		description: '',
	},
});
