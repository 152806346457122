// THIS FILE IS GENERATED via packages/editor/editor-plugins/scripts/update-editor-plugins.ts. DO NOT MODIFY IT MANUALLY.
// Disable no-re-export rule for entry point files
/* eslint-disable @atlaskit/editor/no-re-export */
export { gridPlugin } from '@atlaskit/editor-plugin-grid';
export type {
	GridPlugin,
	CreateDisplayGrid,
	Highlights,
	GridPluginOptions,
	GridPluginState,
} from '@atlaskit/editor-plugin-grid';
