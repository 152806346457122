import { createSelector } from 'reselect';
import set from 'lodash/set';
import { formatWithLocale } from '@atlassian/jira-platform-utils-date-fns/src/main.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import {
	EMPTY_ROLLUP,
	FILLED_ROLLUP,
} from '@atlassian/jira-polaris-domain-field/src/rollup/constants.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { IssuesRemote } from '@atlassian/jira-polaris-remote-issue/src/controllers/types.tsx';
import { jiraStringMapping } from '../../../../field/mapping/string/index.tsx';
import type { PropertyMaps, State } from '../../../types.tsx';
import { stringComparator } from '../../comparators/index.tsx';
import { removePropertyValue } from '../common/remove-utils.tsx';
import { stringMapping } from '../string/index.tsx';
import type { FieldMapping } from '../types.tsx';

export const dateMapping = (issuesRemote: IssuesRemote, field: Field): FieldMapping<string> => {
	const basicDateMapping = stringMapping(issuesRemote, field);

	const valueAccessorToExport: FieldMapping<string>['valueAccessorToExport'] = (
		state,
		props,
		issueId,
		locale,
	) => {
		const stringDate = basicDateMapping.valueAccessor(state, props, issueId);
		return stringDate
			? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				formatWithLocale(new Date(stringDate), 'MMM dd, yyyy hh:mm a', locale as string)
			: '';
	};

	return {
		...jiraStringMapping(issuesRemote, field),
		setMutable: (maps, issueId, value) => set(maps, ['string', field.key, issueId], value),
		// @ts-expect-error(PARTIAL_RECORD) TS2322 - Type '(maps: PropertyMaps, issueId: string, value: string | undefined) => { string: { [x: string]: Record<string, string> | { [x: string]: string | undefined; }; }; ... 17 more ...; commentsMetadata: CommentsMetadataMap; }' is not assignable to type '(arg1: PropertyMaps, arg2: string, arg3: string | undefined) => PropertyMaps'.
		setImmutable: (maps, issueId, value) => {
			if (maps.string[field.key] && maps.string[field.key][issueId] === value) {
				return maps;
			}
			return {
				...maps,
				string: {
					...maps.string,
					[field.key]: {
						...maps.string[field.key],
						[issueId]: value,
					},
				},
			};
		},
		remove: (maps: PropertyMaps, issueId: LocalIssueId) =>
			removePropertyValue(field.key, maps, issueId, 'string'),
		modifyImmutableIfMultiValueField: (maps: PropertyMaps) => maps,
		comparator: stringComparator,
		valueAccessor: basicDateMapping.valueAccessor,
		valueAccessorToExport,
		getAllValues: (state) =>
			state.properties.string[field.key] ? state.properties.string[field.key] : {},
		getGroupIdentitiesSelector: (fieldKey, issueIdsSelector) =>
			createSelector(
				issueIdsSelector,
				(state: State) => state.properties.string[fieldKey],
				(ids, string) =>
					ids.reduce(
						(result, issueId) =>
							Object.assign(result, {
								[issueId]:
									string !== undefined && string[issueId] !== undefined
										? [
												{
													groupIdentity: string[issueId],
													value: string[issueId],
												},
											]
										: [],
							}),
						{},
					),
			),
		getGroupIdentities: (state, props, issueId) => {
			const stringProperties = state.properties.string;
			const value = stringProperties[field.key] && stringProperties[field.key][issueId];
			return value !== undefined ? [{ groupIdentity: value, value }] : [];
		},
		getRollupOperations: () => [EMPTY_ROLLUP, FILLED_ROLLUP],
		allowEmptyGroup: true,
		getLabel: (groupIdentity, value) => value,
		getFilter: () => undefined,
	};
};
