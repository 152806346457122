/**
 * Searches within a specified container for a ProseMirror editor element and retrieves its `editorId`.
 *
 * @param containerSelector - The CSS selector string for the container element.
 * @returns The `editorId` string if found; otherwise, `undefined`.
 *
 * @example
 * ```typescript
 * const editorId = findEditorId('#editor-container');
 * if (editorId) {
 *   console.log(`Editor ID is: ${editorId}`);
 * } else {
 *   console.log('Editor ID not found.');
 * }
 * ```
 */
export const findEditorId = (containerSelector: string): string | undefined => {
	const editorElement = document.querySelector<HTMLElement>(`${containerSelector} .ProseMirror`);
	return editorElement?.dataset?.editorId;
};
