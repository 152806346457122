/**
 * @generated SignedSource<<261dca4389bfe4ee3ea8e396cf122d92>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutFieldHeading_IssueViewFieldHeading$data = {
  readonly description: string | null | undefined;
  readonly fieldId: string;
  readonly fieldOperations?: {
    readonly canEdit: boolean | null | undefined;
  } | null | undefined;
  readonly issue?: {
    readonly key: string;
  } | null | undefined;
  readonly name: string;
  readonly " $fragmentType": "ui_issueViewLayoutFieldHeading_IssueViewFieldHeading";
};
export type ui_issueViewLayoutFieldHeading_IssueViewFieldHeading$key = {
  readonly " $data"?: ui_issueViewLayoutFieldHeading_IssueViewFieldHeading$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutFieldHeading_IssueViewFieldHeading">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldscustomfieldconfigrelayprovider"
    }
  ],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutFieldHeading_IssueViewFieldHeading",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "description"
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldscustomfieldconfigrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "concreteType": "JiraIssue",
          "kind": "LinkedField",
          "name": "issue",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "key"
            }
          ]
        },
        {
          "concreteType": "JiraFieldOperation",
          "kind": "LinkedField",
          "name": "fieldOperations",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "canEdit"
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraIssueField",
  "abstractKey": "__isJiraIssueField"
};

(node as any).hash = "b35ec08927a9f2bb71c4562e75c12c00";

export default node;
