import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	show: {
		id: 'smart-related-issues-panel.issue-line-card-group.show-more.show',
		defaultMessage: 'Show more',
		description:
			'Label for button where, when clicked, expands the related issues panel to show more related issues',
	},
});
