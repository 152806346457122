import { createStore, createHook, type Action } from '@atlassian/react-sweet-state';
import type { StoreState } from './types.tsx';

const initialState: StoreState = {
	onEditAction: undefined,
	onEditorReady: () => {
		// noop
	},
};

const actions = {
	setOnEditAction:
		(onEditAction: () => void): Action<StoreState> =>
		({ setState }) => {
			setState({ onEditAction });
		},
	setOnEditorReady:
		(onEditorReady: () => void): Action<StoreState> =>
		({ setState }) => {
			setState({ onEditorReady });
		},
	resetOnEditorReady:
		(): Action<StoreState> =>
		({ setState }) => {
			setState({ onEditorReady: initialState.onEditorReady });
		},
};

const ImproveIssueStore = createStore<StoreState, typeof actions>({
	initialState,
	actions,
});

export const useImproveIssueStore = createHook(ImproveIssueStore);
