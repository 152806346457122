import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { getCurrentRouteSelector, getPolarisQuerySelector } from '../../selectors/index.tsx';
import type { State, Props } from '../../types.tsx';
import debouncedRouterActions from '../../utils/debounced-router-actions.tsx';

export const updateDebouncedRouterActions =
	() =>
	(
		{ getState }: StoreActionApi<State>,
		{ getAppUrl, useNativePushMethod, useNativeReplaceMethod }: Props,
	) => {
		debouncedRouterActions.setNativeRouterMethods(useNativePushMethod, useNativeReplaceMethod);
		const currentQuery = getPolarisQuerySelector(getState());
		const currentRoute = getCurrentRouteSelector(getState());
		const path = getAppUrl?.(currentRoute);
		if (getAppUrl !== undefined) {
			debouncedRouterActions.setCurrentState(path, currentQuery);
		}
	};
