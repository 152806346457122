import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';

export const getFileBinary = (
	endpointUrl: string,
	clientId: string,
	token: string,
	attachmentMediaApiId: string,
) =>
	fetch(`${endpointUrl}/file/${attachmentMediaApiId}/binary`, {
		headers: {
			authorization: `Bearer ${token}`,
			'x-client-id': clientId,
		},
	}).then((resposne) => {
		if (resposne.ok) {
			return resposne.blob();
		}
		throw new FetchError(resposne.status, 'Failed to fetch file blob from media');
	});
