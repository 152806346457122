import {
  AndClauseContext,
  ArglistContext,
  ArgumentContext,
  ChangedOperatorContext,
  ClauseContext,
  FieldContext,
  FuncContext,
  FuncNameContext,
  HistoryPredicateContext,
  HistoryPredicateOperatorContext,
  ListContext,
  NotClauseContext,
  NumberStringContext,
  OperandContext,
  OrClauseContext,
  OrderByContext,
  QueryContext,
  SearchSortContext,
  StringContext,
  SubClauseContext,
  TerminalClauseContext,
  TerminalHistoryPredicateContext,
  UnaryOperatorContext,
  WasOperatorContext,
  JQLParserVisitor,
  NonNumberFieldContext,
  NumberFieldContext
} from '@atlassiansox/jql-grammar';
import {
  Query,
  Clause,
  Field,
  COMPOUND_CLAUSE_AND,
  COMPOUND_CLAUSE_NOT,
  COMPOUND_CLAUSE_OR,
  FieldChangedOperator,
  FieldChangedTimePredicate,
  FieldChangedTimePredicateOperator,
  FieldValueOperator,
  FieldWasOperator,
  isFieldChangedOperator,
  isFieldChangedTimePredicateOperatorValue,
  isFieldValueOperator,
  isFieldWasOperator,
  Position,
  FieldOperand,
  FieldValueOperand,
  FunctionArg,
  FunctionOperand,
  FunctionString,
  KEYWORD_OPERAND_EMPTY,
  ListOperand,
  UnitaryOperand,
  ORDER_BY_DIRECTION_ASC,
  ORDER_BY_DIRECTION_DESC,
  OrderBy,
  OrderByField
} from './types';
import { CommonTokenStream, ParserRuleContext, Token } from 'antlr4ts';
import { ErrorNode, ParseTree, RuleNode, TerminalNode } from 'antlr4ts/tree';
import { NodeCreators } from './JastUtils';

const getPositionFromToken = (
  startToken: Token,
  stopToken?: Token
): Position => [startToken.startIndex, (stopToken || startToken).stopIndex + 1];
const getPositionFromContext = (ctx: ParserRuleContext): Position => [
  ctx.start.startIndex,
  ctx.stop ? ctx.stop.stopIndex + 1 : ctx.start.stopIndex
];

const getPositionsFromTerminalNodes = (
  terminalNodes: TerminalNode[]
): Position[] =>
  terminalNodes.map((node: TerminalNode) => getPositionFromToken(node.payload));

/**
 * Type predicate to assert an argument is not undefined.
 */
function notUndefined<T>(x: T | void): x is T {
  return x !== undefined;
}

abstract class JastBuildingVisitor<Result> implements JQLParserVisitor<Result> {
  tokens: CommonTokenStream;

  constructor(tokens: CommonTokenStream) {
    this.tokens = tokens;
  }

  visit(tree: ParseTree): Result {
    throw new Error('Unsupported operation visit(ParseTree)');
  }

  visitChildren(node: RuleNode): Result {
    throw new Error('Unsupported operation visitChildren(RuleNode)');
  }

  visitErrorNode(node: ErrorNode): Result {
    throw new Error('Unsupported operation visitErrorNode(ErrorNode)');
  }

  visitTerminal(node: TerminalNode): Result {
    throw new Error('Unsupported operation visitTerminal(TerminalNode)');
  }
}

export class JastBuildingQueryVisitor extends JastBuildingVisitor<Query> {
  clauseVisitor = new JastBuildingClauseVisitor(this.tokens);
  orderByVisitor = new JastBuildingOrderByVisitor(this.tokens);

  visitQuery = (ctx: QueryContext): Query => {
    const clauseContext = ctx.clause();
    const orderByContext = ctx.orderBy();

    return NodeCreators.Query(
      clauseContext && clauseContext.accept(this.clauseVisitor),
      orderByContext && orderByContext.accept(this.orderByVisitor),
      this.tokens.getText(ctx)
    );
  };
}

class JastBuildingClauseVisitor extends JastBuildingVisitor<Clause | void> {
  terminalClauseVisitor = new JastBuildingTerminalClauseVisitor(this.tokens);

  visitClause = (ctx: ClauseContext): Clause | void => {
    return this.visitOrClause(ctx.orClause());
  };

  visitOrClause = (ctx: OrClauseContext): Clause | void => {
    const clauses = ctx
      .andClause()
      .map(andClauseContext => andClauseContext.accept(this))
      .filter(notUndefined);
    if (clauses.length > 1) {
      const operator = NodeCreators.CompoundOperator(
        COMPOUND_CLAUSE_OR,
        getPositionsFromTerminalNodes(ctx.OR())
      );

      return NodeCreators.CompoundClause(
        operator,
        clauses,
        getPositionFromContext(ctx)
      );
    }

    return clauses.length === 0 ? undefined : clauses[0];
  };

  visitAndClause = (ctx: AndClauseContext): Clause | void => {
    const clauses = ctx
      .notClause()
      .map(notClauseContext => notClauseContext.accept(this))
      .filter(notUndefined);

    if (clauses.length > 1) {
      const operator = NodeCreators.CompoundOperator(
        COMPOUND_CLAUSE_AND,
        getPositionsFromTerminalNodes(ctx.AND())
      );

      return NodeCreators.CompoundClause(
        operator,
        clauses,
        getPositionFromContext(ctx)
      );
    }

    return clauses.length === 0 ? undefined : clauses[0];
  };

  visitNotClause = (ctx: NotClauseContext): Clause | void => {
    const notToken = ctx.NOT();

    const subClauseContext = ctx.subClause();
    const terminalClauseContext = ctx.terminalClause();

    let clause: Clause | void;

    if (subClauseContext !== undefined) {
      clause = subClauseContext.accept(this);
    } else if (terminalClauseContext !== undefined) {
      clause = terminalClauseContext.accept(this.terminalClauseVisitor);
    }

    if (notToken) {
      const operator = NodeCreators.CompoundOperator(
        COMPOUND_CLAUSE_NOT,
        getPositionsFromTerminalNodes([notToken])
      );

      return NodeCreators.CompoundClause(
        operator,
        clause ? [clause] : [],
        getPositionFromContext(ctx)
      );
    }

    return clause;
  };

  visitSubClause = (ctx: SubClauseContext): Clause | void => {
    return this.visitOrClause(ctx.orClause());
  };
}

class JastBuildingTerminalClauseVisitor extends JastBuildingVisitor<Clause> {
  fieldVisitor = new JastBuildingFieldVisitor(this.tokens);
  wasOperatorVisitor = new JastBuildingWasOperatorVisitor(this.tokens);
  operandVisitor = new JastBuildingOperandVisitor(this.tokens);
  historyPredicateVisitor = new JastBuildingHistoryPredicateVisitor(
    this.tokens
  );
  changedOperatorVisitor = new JastBuildingChangedOperatorVisitor(this.tokens);
  unaryOperatorVisitor = new JastBuildingUnaryOperatorVisitor(this.tokens);

  visitTerminalClause = (ctx: TerminalClauseContext): Clause => {
    const field = ctx.field().accept(this.fieldVisitor);
    const wasClauseContext = ctx.wasClause();
    const changedClauseContext = ctx.changedClause();

    if (wasClauseContext) {
      const historyPredicateContext = wasClauseContext.historyPredicate();

      return NodeCreators.FieldWasClause(
        field,
        wasClauseContext.wasOperator().accept(this.wasOperatorVisitor),
        wasClauseContext.operand().accept(this.operandVisitor),
        historyPredicateContext
          ? historyPredicateContext.accept(this.historyPredicateVisitor)
          : [],
        getPositionFromContext(ctx)
      );
    } else if (changedClauseContext) {
      const historyPredicateContext = changedClauseContext.historyPredicate();

      return NodeCreators.FieldChangedClause(
        field,
        changedClauseContext
          .changedOperator()
          .accept(this.changedOperatorVisitor),
        historyPredicateContext
          ? historyPredicateContext.accept(this.historyPredicateVisitor)
          : [],
        getPositionFromContext(ctx)
      );
    } else {
      // We create a field value clause by default even when an operator has not been specified
      const unaryContext = ctx.unaryClause();

      return NodeCreators.FieldValueClause(
        field,
        unaryContext &&
          unaryContext.unaryOperator().accept(this.unaryOperatorVisitor),
        unaryContext && unaryContext.operand().accept(this.operandVisitor),
        getPositionFromContext(ctx)
      );
    }
  };
}

class JastBuildingHistoryPredicateVisitor extends JastBuildingVisitor<
  FieldChangedTimePredicate[]
> {
  terminalHistoryPredicateVisitor = new JastBuildingTerminalHistoryPredicateVisitor(
    this.tokens
  );

  visitHistoryPredicate = (
    ctx: HistoryPredicateContext
  ): FieldChangedTimePredicate[] => {
    return ctx
      .terminalHistoryPredicate()
      .map(predicateCtx =>
        predicateCtx.accept(this.terminalHistoryPredicateVisitor)
      );
  };
}

class JastBuildingTerminalHistoryPredicateVisitor extends JastBuildingVisitor<
  FieldChangedTimePredicate
> {
  historyPredicateOperatorVisitor = new JastBuildingHistoryPredicateOperatorVisitor(
    this.tokens
  );
  operandVisitor = new JastBuildingOperandVisitor(this.tokens);

  visitTerminalHistoryPredicate = (
    ctx: TerminalHistoryPredicateContext
  ): FieldChangedTimePredicate => {
    return NodeCreators.FieldChangedTimePredicate(
      ctx
        .historyPredicateOperator()
        .accept(this.historyPredicateOperatorVisitor),
      ctx.operand().accept(this.operandVisitor),
      getPositionFromContext(ctx)
    );
  };
}

class JastBuildingHistoryPredicateOperatorVisitor extends JastBuildingVisitor<
  FieldChangedTimePredicateOperator
> {
  visitHistoryPredicateOperator = (
    ctx: HistoryPredicateOperatorContext
  ): FieldChangedTimePredicateOperator => {
    const value = this.tokens.getText(ctx).toLowerCase();
    if (!isFieldChangedTimePredicateOperatorValue(value)) {
      // TODO: If we reach here something has gone wrong. We should throw/log some kind of error
      throw new Error(
        `Found a history predicate operator which does not map to a changed time predicate operator in the ast: ${value}`
      );
    }

    return NodeCreators.FieldChangedTimePredicateOperator(
      value,
      getPositionFromContext(ctx)
    );
  };
}

class JastBuildingFieldVisitor extends JastBuildingVisitor<Field> {
  visitField = (ctx: FieldContext): Field => {
    return NodeCreators.Field(
      this.tokens.getText(ctx),
      undefined,
      getPositionFromContext(ctx)
    );
  };
  visitNonNumberField = (ctx: NonNumberFieldContext): Field => {
    return this.visitField(ctx);
  };
  visitNumberField = (ctx: NumberFieldContext): Field => {
    return this.visitField(ctx);
  };
}

class JastBuildingUnaryOperatorVisitor extends JastBuildingVisitor<
  FieldValueOperator
> {
  visitUnaryOperator = (ctx: UnaryOperatorContext): FieldValueOperator => {
    const value = this.tokens.getText(ctx).toLowerCase();
    if (!isFieldValueOperator(value)) {
      // TODO this should never happen
      throw new Error(
        `'${value}' does not match any of the recognised unary operators`
      );
    }

    return NodeCreators.FieldValueOperator(value, getPositionFromContext(ctx));
  };
}

class JastBuildingChangedOperatorVisitor extends JastBuildingVisitor<
  FieldChangedOperator
> {
  visitChangedOperator = (
    ctx: ChangedOperatorContext
  ): FieldChangedOperator => {
    const value = this.tokens.getText(ctx).toLowerCase();
    if (!isFieldChangedOperator(value)) {
      // TODO this should never happen
      throw new Error(
        `'${value}' does not match any of the recognised changed operators`
      );
    }

    return NodeCreators.FieldChangedOperator(
      value,
      getPositionFromContext(ctx)
    );
  };
}

class JastBuildingWasOperatorVisitor extends JastBuildingVisitor<
  FieldWasOperator
> {
  visitWasOperator = (ctx: WasOperatorContext): FieldWasOperator => {
    const value = this.tokens.getText(ctx).toLowerCase();
    if (!isFieldWasOperator(value)) {
      // TODO this should never happen
      throw new Error(
        `'${value}' does not match any of the recognised was operators`
      );
    }

    return NodeCreators.FieldWasOperator(value, getPositionFromContext(ctx));
  };
}

class JastBuildingOperandVisitor extends JastBuildingVisitor<FieldOperand | void> {
  listVisitor = new JastBuildingListVisitor(this.tokens);
  unitaryOperandVisitor = new JastBuildingUnitaryOperandVisitor(this.tokens);

  visitOperand = (ctx: OperandContext): FieldOperand | void => {
    const listContext = ctx.list();
    if (listContext !== undefined) {
      return listContext.accept(this.listVisitor);
    }

    return ctx.accept(this.unitaryOperandVisitor);
  };
}

class JastBuildingUnitaryOperandVisitor extends JastBuildingVisitor<UnitaryOperand | void> {
  stringVisitor = new JastBuildingStringVisitor(this.tokens);
  funcVisitor = new JastBuildingFuncVisitor(this.tokens);

  visitOperand = (ctx: OperandContext): UnitaryOperand | void => {
    const empty = ctx.EMPTY();

    if (empty !== undefined) {
      return NodeCreators.KeywordOperand(
        KEYWORD_OPERAND_EMPTY,
        getPositionFromToken(empty.payload)
      );
    }

    const stringContext = ctx.string();
    if (stringContext !== undefined) {
      return stringContext.accept(this.stringVisitor);
    }

    const numberStringContext = ctx.numberString();
    if (numberStringContext !== undefined) {
      return numberStringContext.accept(this.stringVisitor);
    }

    const funcContext = ctx.func();
    if (funcContext !== undefined) {
      return funcContext.accept(this.funcVisitor);
    }

    // Occurs when the parse tree has optimistically created an empty operand node, e.g. "status changed to"
    return undefined;
  };
}

class JastBuildingStringVisitor extends JastBuildingVisitor<FieldValueOperand> {
  visitString = (ctx: StringContext): FieldValueOperand => {
    return NodeCreators.FieldValueOperand(
      this.tokens.getText(ctx),
      getPositionFromContext(ctx)
    );
  };

  visitNumberString = (ctx: NumberStringContext): FieldValueOperand => {
    return NodeCreators.FieldValueOperand(
      this.tokens.getText(ctx),
      getPositionFromContext(ctx)
    );
  };
}

class JastBuildingFuncVisitor extends JastBuildingVisitor<FunctionOperand> {
  funcNameVisitor = new JastBuildingFuncNameVisitor(this.tokens);
  arglistVisitor = new JastBuildingArglistVisitor(this.tokens);

  visitFunc = (ctx: FuncContext): FunctionOperand => {
    const arglistContext = ctx.arglist();

    return NodeCreators.FunctionOperand(
      ctx.funcName().accept(this.funcNameVisitor),
      arglistContext === undefined
        ? []
        : arglistContext.accept(this.arglistVisitor),
      getPositionFromContext(ctx)
    );
  };
}

class JastBuildingFuncNameVisitor extends JastBuildingVisitor<FunctionString> {
  visitFuncName = (ctx: FuncNameContext): FunctionString => {
    return NodeCreators.FunctionString(
      this.tokens.getText(ctx),
      getPositionFromContext(ctx)
    );
  };
}

class JastBuildingArglistVisitor extends JastBuildingVisitor<FunctionArg[]> {
  argumentVisitor = new JastBuildingArgumentVisitor(this.tokens);

  visitArglist = (ctx: ArglistContext): FunctionArg[] => {
    return ctx
      .argument()
      .map(argumentCtx => argumentCtx.accept(this.argumentVisitor));
  };
}

class JastBuildingArgumentVisitor extends JastBuildingVisitor<FunctionArg> {
  visitArgument = (ctx: ArgumentContext): FunctionArg => {
    return NodeCreators.FunctionArg(
      this.tokens.getText(ctx),
      getPositionFromContext(ctx)
    );
  };
}

class JastBuildingListVisitor extends JastBuildingVisitor<ListOperand> {
  unitaryOperandVisitor = new JastBuildingUnitaryOperandVisitor(this.tokens);

  visitList = (ctx: ListContext): ListOperand => {
    const values = ctx
      .operand()
      .map(operandCtx => operandCtx.accept(this.unitaryOperandVisitor))
      .filter(notUndefined);

    return NodeCreators.ListOperand(values, getPositionFromContext(ctx));
  };
}

class JastBuildingOrderByVisitor extends JastBuildingVisitor<OrderBy> {
  searchSortVisitor = new JastBuildingSearchSortVisitor(this.tokens);

  visitOrderBy = (ctx: OrderByContext): OrderBy | void => {
    // If this rule returned due to an exception then the order by operator is incomplete so we should exit early,
    // e.g. 'order '.
    if (ctx.exception) {
      return undefined;
    }

    const operator = NodeCreators.OrderByOperator(
      getPositionFromToken(ctx.ORDER().payload, ctx.BY().payload)
    );

    const fields = ctx
      .searchSort()
      .map(searchSortCtx => searchSortCtx.accept(this.searchSortVisitor))
      .filter(notUndefined);

    return NodeCreators.OrderBy(operator, fields, getPositionFromContext(ctx));
  };
}

class JastBuildingSearchSortVisitor extends JastBuildingVisitor<OrderByField | void> {
  fieldVisitor = new JastBuildingFieldVisitor(this.tokens);

  visitSearchSort = (ctx: SearchSortContext): OrderByField | void => {
    const fieldCtx = ctx.field();

    // If the field rule is returned due to an exception then we should ignore this search search rule. This happens
    // when there are no fields following and order by clause, e.g. 'order by '
    if (fieldCtx.exception) {
      return undefined;
    }

    let direction = undefined;
    const desc = ctx.DESC();
    const asc = ctx.ASC();
    if (desc !== undefined) {
      direction = NodeCreators.OrderByDirection(
        ORDER_BY_DIRECTION_DESC,
        getPositionFromToken(desc.payload)
      );
    } else if (asc !== undefined) {
      direction = NodeCreators.OrderByDirection(
        ORDER_BY_DIRECTION_ASC,
        getPositionFromToken(asc.payload)
      );
    }

    return NodeCreators.OrderByField(
      ctx.field().accept(this.fieldVisitor),
      direction,
      getPositionFromContext(ctx)
    );
  };
}
