/**
 * @generated SignedSource<<41b9f514412b45a47d932397eb405f99>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutUserField_IssueViewUserField$data = {
  readonly __typename: "JiraSingleSelectUserPickerField";
  readonly fieldId: string;
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"singleUserPicker_issueFieldSingleUserPickerInlineEditFull_SingleUserPickerInlineEditView_fragmentRef" | "ui_issueViewLayoutFieldHeading_IssueViewFieldHeading">;
  readonly " $fragmentType": "ui_issueViewLayoutUserField_IssueViewUserField";
};
export type ui_issueViewLayoutUserField_IssueViewUserField$key = {
  readonly " $data"?: ui_issueViewLayoutUserField_IssueViewUserField$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutUserField_IssueViewUserField">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutUserField_IssueViewUserField",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "ui_issueViewLayoutFieldHeading_IssueViewFieldHeading"
    },
    {
      "kind": "FragmentSpread",
      "name": "singleUserPicker_issueFieldSingleUserPickerInlineEditFull_SingleUserPickerInlineEditView_fragmentRef"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "ScalarField",
      "name": "__typename"
    }
  ],
  "type": "JiraSingleSelectUserPickerField"
};

(node as any).hash = "d708667dea6951614020b77bd07ccdf2";

export default node;
