import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import rankField from '@atlassian/jira-issue-fields-ranker/src/services/index.tsx';
import type { Component } from '@atlassian/jira-issue-shared-types/src/common/types/issue-type.tsx';
import { COMPONENTS_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';

/*
 * Rank list of components by calling Field recommendations service
 */
// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	components: Component[],
	issueId?: string | number,
	projectId?: string | number,
	sessionId?: string,
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
): Promise<Component[]> =>
	rankField(COMPONENTS_TYPE, components, issueId, projectId, sessionId, createAnalyticsEvent);
