import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	fileName: {
		id: 'issue-attachments-table.file-name',
		defaultMessage: 'Name',
		description: 'The heading for the filename column in the attachments table',
	},
	size: {
		id: 'issue-attachments-table.size',
		defaultMessage: 'Size',
		description: 'The heading for the size column in the attachments table',
	},
	date: {
		id: 'issue-attachments-table.date',
		defaultMessage: 'Date added',
		description: 'The heading for the date column in the attachments table',
	},
	delete: {
		id: 'issue-attachments-table.delete',
		defaultMessage: 'Delete or go to',
		description: 'The heading for the delete or go to column in the attachments table',
	},
	download: {
		id: 'issue-attachments-table.download',
		defaultMessage: 'Download',
		description: 'The heading for the download column in the attachments table',
	},
	loadingAriaLabel: {
		id: 'issue-attachments-table.loading-aria-label',
		defaultMessage: 'loading attachments',
		description: 'The aria label when the attachments table is paginating or sorting',
	},
	loadedAriaLabel: {
		id: 'issue-attachments-table.loaded-aria-label',
		defaultMessage: 'Attachments table loaded. Press tab to enter the table',
		description: 'The aria label when the attachments table has been paginated or sorted',
	},
	internalOnlyLabel: {
		id: 'issue-attachments-table.internal-only-label',
		defaultMessage: 'Internal only',
		description: 'The label for attachments which are inside internal comments',
	},
});
