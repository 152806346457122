import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { attachmentPickerPopupCloseSuccess } from '@atlassian/jira-issue-view-store/src/actions/attachment-popup-actions.tsx';
import { isAttachmentPickerPopupOpenSelector } from '@atlassian/jira-issue-view-store/src/selectors/attachment-picker-selector.tsx';
import BrowserMediaPicker, { type BaseProps } from './view.tsx';

type StateProps = {
	isOpen: boolean;
};

const ConnectedBrowserMediaPicker: React.FC<BaseProps> = connect(
	(state: State): StateProps => ({
		isOpen: isAttachmentPickerPopupOpenSelector(state),
	}),
	{
		onClose: attachmentPickerPopupCloseSuccess,
	},
)(BrowserMediaPicker);

export default ConnectedBrowserMediaPicker;
