import React, { useCallback } from 'react';
import { useFlagsService } from '@atlassian/jira-flags';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import type {
	ApproverFieldValue,
	GroupApproverFieldValue,
	GroupApproverPrincipal,
} from '@atlassian/jira-issue-shared-types/src/common/types/approval-type.tsx';
import { getIssueModalAkDropdownPortal } from '@atlassian/jira-issue-view-common-utils/src/get-element/index.tsx';
import { useIssueLayoutActions } from '@atlassian/jira-issue-view-layout/src/services/main.tsx';
import {
	SERVICEDESK_APPROVAL_TYPE,
	APPROVALS_PANEL_TYPE,
	STATUS_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { AsyncApprovalPanel as JsmAsyncApprovalPanel } from '@atlassian/jira-servicedesk-approval-panel/src/async.tsx';
import messages from '../messages.tsx';
import type { JSMPanelProps } from '../types.tsx';
import useApprovalField from '../use-approval-fields.tsx';

/**
 * Convert Group Approver Principals to Multi-Group-Picker values.
 * @param {ApproverFieldValue | ReadOnlyArray<ApproverFieldValue> | ReadOnlyArray<GroupApproverPrincipal>} list A list of approvers.
 * @returns An array of multi-group-picker values.
 */
const convertGroupFieldValues = (
	list:
		| null
		| ApproverFieldValue
		| ReadonlyArray<ApproverFieldValue>
		| ReadonlyArray<GroupApproverPrincipal>,
): ReadonlyArray<GroupApproverFieldValue> => {
	if (list == null) return [];
	const approvers = Array.isArray(list) ? list : [list];
	return approvers
		.filter((approver) => approver.type === 'group' && typeof approver.name === 'string')
		.map((approver) => ({ name: approver.name }));
};

/**
 * Filter out approvers that aren't single user values.
 * @param {ApproverFieldValue | ReadOnlyArray<ApproverFieldValue> | ReadOnlyArray<GroupApproverPrincipal>} list A list of approvers.
 * @returns Only the approvers that are single user picker values.
 */
const onlyUserApprovers = (
	list:
		| null
		| ApproverFieldValue
		| ReadonlyArray<ApproverFieldValue>
		| ReadonlyArray<GroupApproverPrincipal>,
): null | ApproverFieldValue | ReadonlyArray<ApproverFieldValue> => {
	if (list == null) return null;
	// @ts-expect-error - TS2322 - Type 'readonly GroupApproverPrincipal[] | ApproverFieldValue | readonly ApproverFieldValue[]' is not assignable to type 'ApproverFieldValue | readonly ApproverFieldValue[] | null'.
	if (!Array.isArray(list)) return list;
	return list.filter((approver) => approver.type !== 'group');
};

export const JSMApprovalPanel = (props: JSMPanelProps) => {
	const { setRefreshIssue } = props;
	const issueKey = useIssueKey();
	const { showFlag } = useFlagsService();
	const approvalFieldId =
		(props.payload && props.payload.approvalFieldId) || SERVICEDESK_APPROVAL_TYPE;

	const [, { setIssueViewLayoutContextPanel }] = useIssueLayoutActions();

	const onSaveApprovalAnswerSuccess = useCallback(() => {
		setRefreshIssue();
		showFlag({
			type: 'success',
			title: messages.saveSuccess,
		});
	}, [showFlag, setRefreshIssue]);

	const onSaveApprovalAnswerError = useCallback(
		(refreshIssue?: boolean) => {
			if (refreshIssue) {
				setRefreshIssue();
				showFlag({
					type: 'error',
					title: messages.saveConflictError,
				});
			} else {
				showFlag({
					type: 'error',
					title: messages.saveError,
				});
			}
		},
		[setRefreshIssue, showFlag],
	);

	const onOpenPanel = (fieldId: string, title: string) => {
		setIssueViewLayoutContextPanel(issueKey, {
			key: APPROVALS_PANEL_TYPE,
			type: APPROVALS_PANEL_TYPE,
			title,
			payload: { approvalFieldId: fieldId },
		});
	};

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, jira/jira-ssr/no-unchecked-globals-usage
	const portalElement = (getIssueModalAkDropdownPortal() as HTMLElement) || document.body;

	const onHidePanel = useCallback(() => {
		setIssueViewLayoutContextPanel(issueKey, undefined);
	}, [issueKey, setIssueViewLayoutContextPanel]);

	const onAddApproverError = useCallback(() => {
		showFlag({
			type: 'error',
			title: messages.addApproverError,
		});
	}, [showFlag]);

	const onRemoveApproverError = useCallback(() => {
		showFlag({
			type: 'error',
			title: messages.removeApproverError,
		});
	}, [showFlag]);

	const { approval, linkedFieldId, saveLinkedFieldValue, autoCompleteUrl, isEditable } =
		useApprovalField({
			issueKey,
			approvalFieldId,
		});

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [statusFieldValue] = useFieldValue({ issueKey, fieldKey: STATUS_TYPE });

	const onApproverListUpdated = useCallback(
		(
			newApproverList:
				| null
				| ApproverFieldValue
				| ReadonlyArray<ApproverFieldValue>
				| ReadonlyArray<GroupApproverPrincipal>,
		) => {
			const fieldValues =
				approval?.configuration.approvers.type === 'multi_group_picker'
					? convertGroupFieldValues(newApproverList)
					: onlyUserApprovers(newApproverList);
			Promise.all([
				saveLinkedFieldValue(
					fieldValues,
					null,
					createAnalyticsEvent({
						approverSourceFieldType: approval?.configuration.approvers.type,
					}),
				),
				Promise.resolve(
					// TODO - Keep redux + ss in sync. Remove when approvals multi-user-picker is extracted
					props.updateApprovalLinkedUserPickerField(linkedFieldId, newApproverList),
				),
			])
				// Once saveLinkedFieldValue() finishes, we need to trigger a background refresh so that
				// we can work out whether the current user is able to approve the request or not.
				// Ideally, we'd only request data for the servicedesk-approvals calculated field, but
				// at this particular location, we don't have a way to do that.
				.then(props.renderApproverList);
		},
		[
			approval?.configuration.approvers.type,
			createAnalyticsEvent,
			linkedFieldId,
			props,
			saveLinkedFieldValue,
		],
	);

	// No need to render ApprovalPanel if there are no active approvals
	if (approval == null) {
		return null;
	}

	const approvalProps = {
		fieldId: linkedFieldId,
		fieldMetaKey: linkedFieldId,
		approval,
		issueKey,
		fieldAutoCompleteUrl: autoCompleteUrl ?? '',
		hasLinkedFieldEditPermissions: !!isEditable,
		issueStatusId: statusFieldValue.id,
		portalElement,
		onRemoveApproverError,
		onAddApproverError,
		onHidePanel,
		renderApproverList: props.renderApproverList, // TODO - remove when issue refresh hook exists
		onApproverListUpdated,
		canCurrentUserApprove: approval.canAnswerApproval,
		onOpenPanel,
		onSaveApprovalAnswerSuccess,
		onSaveApprovalAnswerError,
		onZeroApprovalRequired: setRefreshIssue,
	};

	return <JsmAsyncApprovalPanel {...approvalProps} />;
};
