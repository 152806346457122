import type { ComponentType } from 'react';
import { compose } from 'redux';
import memoizeOne from 'memoize-one';
import { defineMessages } from '@atlassian/jira-intl';
import CheckboxInlineEditView from '@atlassian/jira-issue-internal-fields/src/checkbox-select/checkbox-inline-edit-view.tsx';
import { genericMessages } from '@atlassian/jira-issue-view-common-constants/src/context-items-messages.tsx';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button/index.tsx';
import connectField from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-field.tsx';
import { withExtraOwnProps } from '@atlassian/jira-issue-view-common-views/src/with-extra-own-props/index.tsx';
import {
	fieldAllowedValuesSelector,
	fieldNameSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector.tsx';
import { filterAllowedValues } from '../../common/utils/filter-select-allowed-values.tsx';
import {
	transformFromStateValue,
	transformToStateValue,
	transformSuggestions,
	type StateValue,
} from './checkbox-select-transformer.tsx';

// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

const messages = defineMessages({
	placeholder: {
		id: 'issue.checkbox-select.text-for-dropdown-when-nothing-selected',
		defaultMessage: 'Select {fieldName}',
		description: '',
	},
});

const memoizedFetchSuggestionsFactoryV2 = memoizeOne((values, allowedValuesOverrides) =>
	transformSuggestions(filterAllowedValues<StateValue>(values, allowedValuesOverrides)),
);

const Checkboxes = compose(
	withExtraOwnProps,
	connectField((stateOnMount, ownPropsOnMount) => ({
		fieldId: ownPropsOnMount.fieldId,
		transformFromStateValue,
		transformToStateValue,
		additionalProps: (state, intl, fieldOptions, extraOwnProps) => ({
			options: memoizedFetchSuggestionsFactoryV2(
				fieldAllowedValuesSelector(ownPropsOnMount.fieldId)(state),
				extraOwnProps?.allowedValuesOverrides,
			),
			placeholder: intl.formatMessage(messages.placeholder, {
				fieldName: fieldNameSelector(ownPropsOnMount.fieldId)(state),
			}),
			noValueText: intl.formatMessage(genericMessages.noValue),
			showPinButton: getShowPinButton(ownPropsOnMount.area),
		}),
	})),
)(CheckboxInlineEditView);

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
export default Checkboxes as ComponentType<any>;
