import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { SITE_SHARING_CONFIG_URL } from './constants.tsx';
import type { SiteSharingConfig, SitePublicSharingConfig } from './types.tsx';

export const fetchSiteSharingConfig = (): Promise<SiteSharingConfig> =>
	fetchJson(SITE_SHARING_CONFIG_URL);

export const enableSharingOnSite = (): Promise<SiteSharingConfig> =>
	performPutRequest(`${SITE_SHARING_CONFIG_URL}/enable`);

export const disableSharingOnSite = (): Promise<SiteSharingConfig> =>
	performPutRequest(`${SITE_SHARING_CONFIG_URL}/disable`);

export const fetchSitePublicSharingConfig = (): Promise<SitePublicSharingConfig> =>
	fetchJson(`${SITE_SHARING_CONFIG_URL}/public`);

export const enablePublicSharingOnSite = (): Promise<SitePublicSharingConfig> =>
	performPutRequest(`${SITE_SHARING_CONFIG_URL}/public/enable`);

export const disablePublicSharingOnSite = (): Promise<SitePublicSharingConfig> =>
	performPutRequest(`${SITE_SHARING_CONFIG_URL}/public/disable`);
