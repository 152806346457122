import type { AssociatedIssue } from '@atlassian/jira-issue-shared-types/src/common/types/associated-issue-type.tsx';
import {
	INWARD_LINK_DIRECTION,
	OUTWARD_LINK_DIRECTION,
	BOTH_LINK_DIRECTION,
	type IssueLinkDirection,
} from '@atlassian/jira-issue-shared-types/src/common/types/linked-issue-type.tsx';
import type { IssueKey, IssueId } from '@atlassian/jira-shared-types/src/general.tsx';

/**
 * BENTO-3476
 * Model for server link types does not exists yet
 * This temporary function should be removed once the ticket is resolved
 */
export const toLinkDirection = (directionStr: string): IssueLinkDirection => {
	const direction = directionStr.toUpperCase();

	switch (direction) {
		case INWARD_LINK_DIRECTION:
			return INWARD_LINK_DIRECTION;
		case OUTWARD_LINK_DIRECTION:
			return OUTWARD_LINK_DIRECTION;
		case BOTH_LINK_DIRECTION:
			return BOTH_LINK_DIRECTION;
		default:
			return BOTH_LINK_DIRECTION;
	}
};

// Details of the created linked issue, returned from the CreateLinkedIssue endpoint
export type ServerCreatedLinkedIssue = {
	id: string; // id of the created linked issue,
	key: string; // key of the created linked issue,
	fields: {
		summary: string;
		issuetype: {
			iconUrl: string;
		};
		// issue links of the newly created linked issue. The current issue maybe one of these.
		issuelinks: {
			id: string; // id of the issue link object,
			type: {
				id: string; // link type id,
				inward: string; // link type's inward description,
				outward: string; // link type's outward description
			};
			outwardIssue?: AssociatedIssue;
			inwardIssue?: AssociatedIssue;
		}[];
	};
};

export type NormalizedCreatedLinkedIssue = {
	id: IssueId;
	key: IssueKey;
	summary: string;
	issueTypeIconUrl: string;
	issueLink:
		| {
				id: string;
				direction: IssueLinkDirection;
				linkType: {
					id: string;
					label: string;
				};
		  }
		| undefined;
};
