import React, { forwardRef, useEffect, type MouseEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import AddIcon from '@atlaskit/icon/glyph/add';
import Tooltip from '@atlaskit/tooltip';
import type { DropdownTriggerParams } from '@atlassian/jira-development-summary-common/src/common/types.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';

export type Props = Partial<
	Omit<DropdownTriggerParams, 'ref'> & {
		href?: string | null;
	}
>;
export const CreatePRIconButton = forwardRef<HTMLElement, Props>(
	({ onClick: onClickProp, href, ...props }, ref) => {
		const { formatMessage } = useIntl();
		const { createAnalyticsEvent } = useAnalyticsEvents();
		useEffect(() => {
			fireUIAnalytics(
				createAnalyticsEvent({ action: 'viewed', actionSubject: 'button' }),
				'createDevAction',
				{
					summaryItemType: 'pullrequest',
					inline: true,
				},
			);
		}, [createAnalyticsEvent]);

		const tooltipContent = formatMessage(messages.tooltipContent);
		const onClick = (e: MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
			href === undefined && onClickProp && onClickProp();
			fireUIAnalytics(analyticsEvent, 'createDevAction', {
				summaryItemType: 'pullrequest',
				inline: true,
			});
		};

		const buttonProps = href !== undefined && href !== null ? { href, target: '_blank' } : {};

		return (
			<Tooltip content={tooltipContent} hideTooltipOnClick>
				<Button
					ref={ref}
					{...props}
					{...buttonProps}
					appearance="subtle"
					iconBefore={<AddIcon size="small" label={tooltipContent} />}
					onClick={onClick}
					testId="development-summary-pull-request.ui.create-pull-request-summary-item.create-pr-icon-button.button"
				/>
			</Tooltip>
		);
	},
);
