import { useCallback } from 'react';
import { useAnalyticsEvents, fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { SOFTWARE } from '@atlassian/jira-shared-types/src/application-key.tsx';
import type { InviteUserResponseSuccess } from '../types.tsx';
import { SUCCESSFUL_INVITE_STATUSES } from './constants.tsx';
import type { HandleInviteUserResultCallbackParams } from './types.tsx';

export const useHandleInviteUserResult = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	return useCallback(
		({
			invitedUser,
			resourceAri,
			integrationSource,
			atlOrigin,
		}: HandleInviteUserResultCallbackParams): InviteUserResponseSuccess => {
			const inviteResult = invitedUser.results[resourceAri];
			fireTrackAnalytics(createAnalyticsEvent({}), 'user invited', invitedUser.id, {
				integration: integrationSource,
				products: [SOFTWARE],
				inviteResult: [
					{
						resourceAri,
						status: inviteResult,
					},
				],
				...atlOrigin.toAnalyticsAttributes({ hasGeneratedId: true }),
			});

			const errorReason = invitedUser.errorReasons[resourceAri];

			if (errorReason && errorReason.message) {
				throw new Error(errorReason.message);
			}
			// Throw error for responses that have no errorReason and are not a status we consider to be successful
			if (SUCCESSFUL_INVITE_STATUSES.includes(inviteResult) === false) {
				throw new Error(`Unsuccessful invite. Response returned result of ${inviteResult}`);
			}

			return {
				userId: invitedUser.id,
				isInviteSuccessful: true,
			};
		},
		[createAnalyticsEvent],
	);
};
