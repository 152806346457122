import type { Event } from '../../types.tsx';

export const getRealtimeEventsQueueLimit = (): number => 100;

export const getRealtimeEventsQueueJitter = (): number => 50;

export const getRealtimeEventsQueueJitterForBatch = (): number => 2000;

export const eventPayloadToCompareDuplicates = (event: Event) => {
	switch (event.type) {
		case 'avi:polaris:created:insight':
		case 'avi:polaris:updated:insight': {
			const { insight, updatedTime, ...other } = event.payload;
			return {
				...event,
				payload: {
					...other,
				},
			};
		}
		case 'avi:polaris:created:view':
		case 'avi:polaris:updated:view': {
			const { view, ...other } = event.payload;
			return {
				...event,
				payload: {
					...other,
				},
			};
		}
		default:
			return event;
	}
};
