import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { ReleasesData } from '@atlassian/jira-development-common/src/model/releases-data.tsx';

export const RELEASES_DATA_FETCH_REQUEST = 'RELEASES_DATA_FETCH_REQUEST' as const;
export const RELEASES_DATA_FETCH_RESPONSE = 'RELEASES_DATA_FETCH_RESPONSE' as const;

export type ReleasesDataFetchAction = ReturnType<typeof releasesDataFetchRequest>;

export const releasesDataFetchRequest = (analyticsEvent: UIAnalyticsEvent) => ({
	type: RELEASES_DATA_FETCH_REQUEST,
	payload: {
		meta: { analyticsEvent },
	},
});

export const releasesDataFetchResponse = (payload: ReleasesData | Error) => ({
	type: RELEASES_DATA_FETCH_RESPONSE,
	payload,
	error: payload instanceof Error,
});

export type ReleasesDataFetchResponseAction = ReturnType<typeof releasesDataFetchResponse>;
