import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { applicationKeySelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import { isSimplifiedProjectSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';
import BannerView, { type TopBannerRendererProps as StateProps } from './view.tsx';

export default connect(
	(state: State): StateProps => ({
		applicationKey: applicationKeySelector(state),
		isProjectSimplified: isSimplifiedProjectSelector(state),
	}),
	{},
)(BannerView);

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { TopBannerRenderer } from './view';
