import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	remoteLinkDeleteLinkTooltip: {
		id: 'issue-line-card.remove-button.remote-link-delete-link-tooltip',
		defaultMessage: 'Unlink remote issue',
		description: 'Tooltip message for button deleting remote issue link',
	},
	issueLinkDeleteLinkTooltip: {
		id: 'issue-line-card.remove-button.issue-link-delete-link-tooltip',
		defaultMessage: 'Unlink issue',
		description: 'Tooltip message for button deleting issue link',
	},
});
