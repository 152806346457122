import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/fromPromise';
import {
	INTERNAL_SERVER_ERROR,
	NOT_FOUND,
} from '@atlassian/jira-common-constants/src/http-status-codes.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { ISSUE_VIEW_INTERACTIVE_QUERY } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-gira-data/index.tsx';
import { getClient } from '@atlassian/jira-issue-fetch-services/src/common/utils/client.tsx';
import fetchIssueGiraData from '@atlassian/jira-issue-fetch-services/src/services/issue-gira-data/index.tsx';
import type { GraphQlData as IssueServiceGraphQlData } from '@atlassian/jira-issue-fetch-services/src/types.tsx';
import type {
	GiraMediaContext,
	MediaContext,
} from '@atlassian/jira-issue-gira-transformer-types/src/common/types/media-context.tsx';
import type { GraphQlData } from '@atlassian/jira-issue-gira-transformer-types/src/common/types/server-data.tsx';
import type { TransformedGiraData } from '@atlassian/jira-issue-gira-transformer-types/src/common/types/transform-result.tsx';
import type {
	JiraSettingsGira,
	JiraSettingsState as JiraSettingsGiraOld,
} from '@atlassian/jira-issue-shared-types/src/common/types/jira-settings-type.tsx';
import type { MyPreferences } from '@atlassian/jira-issue-shared-types/src/common/types/user-preferences-type.tsx';
import type {
	DynamicGraphQlResponse,
	GraphQlResponse,
} from '@atlassian/jira-issue-view-common-types/src/gira.tsx';
import { fallbackOnMissingOrError$ } from '@atlassian/jira-issue-view-common-utils/src/utils/prefetched-resources/utils/fallback-on-error.tsx';
import { IssueViewFetchError } from '@atlassian/jira-issue-view-errors/src/common/utils/issue-view-fetch-error/index.tsx';
import type { BaseUrl, IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { makeErrorObject } from '../error-utils.tsx';
import { transformData as transformTotalAttachmentsCountData } from './attachment-counts/index.tsx';
import { transformData as transformCommentsData } from './comments/index.tsx';
import { transformData as transformEcosystemData } from './ecosystem/index.tsx';
import { buildDynamicIssueQuery, type DynamicIssueQueryParams } from './graphql/index.tsx';
import {
	transformData as transformJiraSettings,
	transformTimeTrackingData as transformJiraSettingsTimeTrackingData,
} from './jira-settings/index.tsx';
import { transformData as transformMediaContext } from './media-context/index.tsx';
import { transformData as transformPermissionData } from './permissions/index.tsx';
import { transformData as transformProjectData } from './project/index.tsx';
import { transformData as tranformRemoteLinksData } from './remote-issue-links/index.tsx';
import { transformData as transformWorklogsData } from './worklogs/index.tsx';

// forcing type to stop exponential inference with 2 unions
const transformMediaTokens = (mediaContext?: GiraMediaContext): MediaContext =>
	// @ts-expect-error - TS2322 - Type 'MediaContext | {}' is not assignable to type 'MediaContext'.
	mediaContext ? transformMediaContext(mediaContext) : {};

export const transformData = (data: GraphQlData | IssueServiceGraphQlData): TransformedGiraData => {
	const { viewIssue, jira, jiraSettings, permissions, mediaContext, project } = data;

	return {
		/*
		 *  Do not add feature-flagged spread at the top
		 *  It would break flow check
		 */
		...transformCommentsData(viewIssue),
		...transformWorklogsData(viewIssue),
		...transformEcosystemData(jira, viewIssue),
		...transformJiraSettings(jiraSettings),
		...transformPermissionData(permissions, viewIssue, jira),
		...transformProjectData(project),
		...transformMediaTokens(mediaContext),
		...tranformRemoteLinksData(viewIssue),
		containersByType: viewIssue.containersByType,
		...transformTotalAttachmentsCountData(viewIssue),
	};
};

const transformDynamicData = (data: GraphQlData) => {
	const { viewIssue, jira, jiraSettings, activitySortOrder } = data;

	return {
		...(viewIssue && viewIssue.comments && transformCommentsData(viewIssue)),
		...(viewIssue && transformEcosystemData(jira, viewIssue)),
		...transformJiraSettingsTimeTrackingData(jiraSettings),
		activitySortOrder,
	};
};

export const fetchAllGiraData = async (
	baseUrl: BaseUrl,
	issueKey: IssueKey,
	prefetchedResource?: Promise<GraphQlResponse> | null,
) => {
	const response = fallbackOnMissingOrError$(prefetchedResource, () =>
		Observable.fromPromise(fetchIssueGiraData(baseUrl, issueKey)),
	).toPromise();

	const { data, errors } = await response;

	if (!data) {
		// No data at all, not good
		const message = errors
			? JSON.stringify(makeErrorObject(errors))
			: 'No data returned from graphql call';
		throw new IssueViewFetchError(new Error(message), ISSUE_VIEW_INTERACTIVE_QUERY);
	}

	if (data && errors) {
		// log errors for partial data
		log.safeErrorWithoutCustomerData(
			'issue.fetch.gira',
			'Failed to fetch some data from gira',
			makeErrorObject(errors),
		);
	}

	if (!data.viewIssue) {
		if (Array.isArray(errors) && errors.length > 0) {
			const errorMessage = JSON.stringify(makeErrorObject(errors));
			// if `viewIssue` is missing and any errors were in the response
			// assume that an error caused the data to be missing.
			throw new IssueViewFetchError(
				new FetchError(
					INTERNAL_SERVER_ERROR,
					`Call to gira returned empty viewIssue with errors: ${errorMessage}`,
				),
				ISSUE_VIEW_INTERACTIVE_QUERY,
			);
		} else {
			// GraphQL doesn't rely on HTTP status codes to classify errors.
			// But we do have code that knows how to handle 404s (display "Issue not found"
			// screen, etc.), so that's why we're forcing a 404 here.
			throw new IssueViewFetchError(
				new FetchError(NOT_FOUND, 'Call to gira returned empty viewIssue'),
				ISSUE_VIEW_INTERACTIVE_QUERY,
			);
		}
	}

	return transformData(data);
};

export const fetchDynamicGiraData = async (
	baseUrl: BaseUrl,
	issueKey: IssueKey,
	dynamicIssueQueryParams?: DynamicIssueQueryParams,
) => {
	const response: DynamicGraphQlResponse = await getClient(baseUrl).query({
		query: buildDynamicIssueQuery(dynamicIssueQueryParams),
		variables: { issueKey },
	});
	const { data, errors } = response;

	if (!data) {
		// No data at all, not good
		const message = errors
			? JSON.stringify(makeErrorObject(errors))
			: 'No data returned from graphql call for dynamic data';
		throw new Error(message);
	}

	if (data && errors) {
		// log errors for partial data
		log.safeErrorWithoutCustomerData(
			'issue.fetch.gira.dynamic',
			'Failed to fetch some data from gira for dynamic data fetch',
			makeErrorObject(errors),
		);
	}

	// @ts-expect-error - TS2345 - Argument of type 'DynamicGraphQlData' is not assignable to parameter of type 'GraphQlData'.
	return transformDynamicData(data);
};

export type TransformDataOld = TransformedGiraData & {
	jiraSettings: JiraSettingsGiraOld;
	myPreferences: MyPreferences | undefined;
};
export type TransformDataNew = TransformedGiraData & {
	jiraSettings: JiraSettingsGira;
};
