import { createSelector } from 'reselect';
import forOwn from 'lodash/forOwn';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { DynamicFieldFormula } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/types.tsx';
import { isInitialized } from '../../../../selectors/meta.tsx';
import { createValueSelectorForFormula } from '../../index.tsx';
import type { DynamicFieldResolver } from '../../types.tsx';
import { normalize } from '../utils/normalize.tsx';

export const FORMULA_TEMPLATE = 'norm';

export const normalizationResolver: DynamicFieldResolver<number> = (
	formula: DynamicFieldFormula,
) => {
	if (formula === undefined || formula.template !== FORMULA_TEMPLATE) {
		return undefined;
	}
	const valuesSelector = createValueSelectorForFormula(formula.parameters.formula);
	if (!valuesSelector) {
		return undefined;
	}
	const nonEmptyValuesArraySelector = createSelector(valuesSelector, (values) => {
		const valuesArray: (number | undefined)[] = Object.values(values);
		if (valuesArray.length === 0) {
			return undefined;
		}
		const nonEmptyValues = new Set<number>();
		forOwn(values, (value) => {
			if (value === Infinity) {
				return;
			}
			if (value === undefined) {
				nonEmptyValues.add(0);
				return;
			}
			nonEmptyValues.add(value);
		});
		if (nonEmptyValues.size === 0) {
			return undefined;
		}
		return Array.from<number>(nonEmptyValues);
	});
	const minValueSelector = createSelector(nonEmptyValuesArraySelector, (nonEmptyValuesArray) =>
		nonEmptyValuesArray ? Math.min(0, ...nonEmptyValuesArray) : undefined,
	);
	const maxValueSelector = createSelector(nonEmptyValuesArraySelector, (nonEmptyValuesArray) =>
		nonEmptyValuesArray ? Math.max(...nonEmptyValuesArray) : undefined,
	);
	return (localIssueId: LocalIssueId) =>
		createSelector(
			isInitialized,
			createSelector(valuesSelector, (values) => values[localIssueId]),
			minValueSelector,
			maxValueSelector,
			(isInit, value, min, max) => {
				if (!isInit) {
					return undefined;
				}

				if (value === undefined || min === undefined || max === undefined) {
					return undefined;
				}

				return normalize(value, min, max);
			},
		);
};
