import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	directJoinSuccessTitle: {
		id: 'issue-create-confluence-content.controllers.use-direct-join-confluence.direct-join-success-title',
		defaultMessage: "We're creating your Confluence account",
		description:
			"The title for the notification shown to the user when they've successfully requested to join Confluence directly",
	},
	directJoinSuccessDescription: {
		id: 'issue-create-confluence-content.controllers.use-direct-join-confluence.direct-join-success-description',
		defaultMessage: 'This should only take a few moments...',
		description:
			"The description for the notification shown to the user when they've successfully requested to join Confluence directly",
	},
	directJoinVerifySuccessTitle: {
		id: 'issue-create-confluence-content.controllers.use-direct-join-confluence.direct-join-verify-success-title',
		defaultMessage: 'All done',
		description:
			'The title for the notification shown to the user once their Confluence access has successfully been verified',
	},
	directJoinVerifySuccessDescription: {
		id: 'issue-create-confluence-content.controllers.use-direct-join-confluence.direct-join-verify-success-description',
		defaultMessage: 'You can now create Confluence Pages from Jira',
		description:
			'The description for the notification shown to the user once their Confluence access has successfully been verified',
	},
	directJoinExceededDescription: {
		id: 'issue-create-confluence-content.controllers.use-direct-join-confluence.direct-join-exceeded-description',
		defaultMessage:
			'Could not join Confluence, available licenses exceeded. Please reach out to your admin for assistance.',
		description:
			"The description for the error notification shown to the user when the user's direct join request fails due to licenses exceeded",
	},
	directJoinFailTitle: {
		id: 'issue-create-confluence-content.controllers.use-direct-join-confluence.direct-join-fail-title',
		defaultMessage: 'Error creating your Confluence account',
		description:
			"The title for the error notification shown to the user when the user's direct join request fails",
	},
	directJoinFailDescription: {
		id: 'issue-create-confluence-content.controllers.use-direct-join-confluence.direct-join-fail-description',
		defaultMessage: 'Failed to join Confluence. Please try again.',
		description:
			"The description for the error notification shown to the user when the user's direct join request fails",
	},
	directJoinDeniedTitle: {
		id: 'issue-create-confluence-content.controllers.use-direct-join-confluence.direct-join-denied-title',
		defaultMessage: 'Access Denied',
		description:
			"The title for the notification shown to the user when the user's direct join request is denied or forbidden",
	},
	directJoinDeniedDescription: {
		id: 'issue-create-confluence-content.controllers.use-direct-join-confluence.direct-join-denied-description',
		defaultMessage:
			'Your request to join Confluence has been denied. Please reach out to your admin for assistance.',
		description:
			"The description for the notification shown to the user when the user's direct join request is denied or forbidden",
	},
});
