import omitBy from 'lodash/omitBy';
import {
	type GlanceUpdate,
	type GlanceWorkMode,
	CLICK_THROUGH,
	OPEN_PANEL,
} from '@atlassian/jira-issue-view-common-types/src/glance-type.tsx';

const validateWorkMode = (value: string): GlanceWorkMode =>
	[CLICK_THROUGH, OPEN_PANEL].find((workMode) => workMode === value) || OPEN_PANEL;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sanitizeGlanceUpdate = (glanceUpdate: any): GlanceUpdate => {
	const withSetValues = omitBy(glanceUpdate, (value) => value === undefined || value === null);
	return withSetValues.workMode === undefined
		? withSetValues
		: { ...withSetValues, workMode: validateWorkMode(withSetValues.workMode) };
};
