import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	triggerLabel: {
		id: 'issue-view-layout-templates-item-list.sortable-item-list.reorder-menu.trigger-label',
		defaultMessage: 'Reorder "{fieldName}" field',
		description: 'Accessible label for the reorder dropdown menu trigger',
	},
	moveUp: {
		id: 'issue-view-layout-templates-item-list.sortable-item-list.reorder-menu.move-up',
		defaultMessage: 'Move up',
		description: 'Dropdown menu item. Move the list item up the list by one',
	},
	moveDown: {
		id: 'issue-view-layout-templates-item-list.sortable-item-list.reorder-menu.move-down',
		defaultMessage: 'Move down',
		description: 'Dropdown menu item. Move the list item down the list by one',
	},
	postMoveAnnouncement: {
		id: 'issue-view-layout-templates-item-list.sortable-item-list.reorder-menu.post-move-announcement',
		defaultMessage:
			'You\'ve moved "{fieldName}" from position {startPosition} to position {endPosition} of {numberOfItems}',
		description: 'Message announced to screen readers after a field is reordered',
	},
});
