// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck react-select unsupported props
import React, { useRef, useCallback, useEffect, useState, useMemo, type FocusEvent } from 'react';
import debounce from 'lodash/debounce';
import Select, { type FormatOptionLabelMeta } from '@atlaskit/select';
import Tooltip from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { Option } from '@atlassian/jira-issue-field-select-base/src/common/types.tsx';
import { filterOptionByLabelAndFilterValues } from '@atlassian/jira-issue-field-select-base/src/ui/filter-options-by-label-and-filter-values/index.tsx';
import { defaultSelectStyles } from '@atlassian/jira-issue-field-select-base/src/ui/react-select-styles/styled.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import type { ParentFieldOption } from '../../common/types.tsx';
import { preventEscKeyPropagationKeyPressHandler } from '../../common/utils.tsx';
import Menu from './menu/index.tsx';
import messages from './messages.tsx';
import CustomOption from './option/index.tsx';
import type { Props } from './types.tsx';
import { fetchParentsForNew, fetchParentsForExisting } from './utils.tsx';

const LOAD_OPTIONS_DEBOUNCE_TIMER = 300;

export const ParentFieldEdit = (props: Props) => {
	const {
		onFocus,
		onBlur,
		value = null,
		onChange,
		selectedProjectId,
		selectedIssueTypeId,
		isInvalid = false,
		autoFocus = false,
		onCloseMenuOnScroll,
		isDropdownMenuFixedAndLayered,
		isDisabled = false,
		isExistingIssue = false,
		defaultMenuIsOpen = false,
		isEditing,
		classNamePrefix,
		isClearable = true,
		passedInIssueKey, // used for experiences that don't have issueKey in context, eg. bulk edit
		fieldId,
		spacing: spacingProps = 'compact',
		'aria-labelledby': ariaLabelledBy,
		onSearchParents,
		showDoneIssuesCheckboxVisible,
	} = props;

	const { formatMessage } = useIntl();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [suggestions, setSuggestions] = useState<ParentFieldOption[] | undefined>();
	const [defaultSuggestions, setDefaultSuggestions] = useState<ParentFieldOption[] | undefined>();
	const [noOptionsMessage, setNoOptionsMessage] = useState<string>('');
	const [hasLastFetchFailed, setHasLastFetchFailed] = useState<boolean>(false);
	const [excludeDone, setExcludeDone] = useState<boolean>(true);

	const isInitialMount = useRef<boolean>(true);
	const lastQuery = useRef<string>('');

	const cloudId = useCloudId();
	const issueKey = useIssueKey();

	const componentProps = useMemo(
		() => ({
			onChange: (event: React.MouseEvent<HTMLDivElement> | React.MouseEvent<HTMLLabelElement>) => {
				setExcludeDone(!event.target.checked);
			},
			showDoneIssuesCheckboxVisible,
		}),
		[showDoneIssuesCheckboxVisible],
	);

	const formatOptionLabel = useCallback(
		(option: ParentFieldOption, { context }: FormatOptionLabelMeta<ParentFieldOption>) => {
			if (context === 'menu') {
				return (
					<Tooltip content={option.label}>
						<CustomOption option={option} />
					</Tooltip>
				);
			}

			return <>{option.label || `${option.key} ${option.fields.summary}`}</>;
		},
		[],
	);

	const searchParents = useCallback(
		async (query = '') => {
			setHasLastFetchFailed(false);
			try {
				lastQuery.current = query;

				if (isExistingIssue) {
					const fetchedOptions = await fetchParentsForExisting({
						searchTerm: query,
						issueKey: passedInIssueKey || issueKey,
						cloudId,
						excludeDone,
					});
					return fetchedOptions;
				}

				if (selectedProjectId && selectedIssueTypeId) {
					const fetchedOptions = await fetchParentsForNew({
						searchTerm: query,
						selectedProjectId,
						selectedIssueTypeId,
						cloudId,
						excludeDone,
					});
					return fetchedOptions;
				}

				setHasLastFetchFailed(true);
				return {
					options: [],
				};
			} catch {
				setHasLastFetchFailed(true);
				return {
					options: [],
				};
			}
		},
		[
			isExistingIssue,
			issueKey,
			cloudId,
			excludeDone,
			selectedProjectId,
			selectedIssueTypeId,
			passedInIssueKey,
		],
	);

	const requestSuggestions = useCallback(
		async (query = ''): Promise<void> => {
			let response;
			if (onSearchParents && fg('issue_view_in_program_board')) {
				response = await onSearchParents(query);
			} else {
				response = await searchParents(query);
			}

			if (lastQuery.current === query) {
				if (response.options !== undefined) {
					setSuggestions(response.options);
					if (response.options.length === 0 && response.message) {
						setNoOptionsMessage(response.message);
					}

					if (query === '') {
						setDefaultSuggestions(response.options);
					}
				} else {
					setSuggestions([]);

					if (query === '') {
						setDefaultSuggestions([]);
					}
				}
				setIsLoading(false);
			}
		},
		[searchParents, onSearchParents],
	);

	// go/jfe-eslint
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debouncedRequestSuggestions = useCallback(
		debounce(requestSuggestions, LOAD_OPTIONS_DEBOUNCE_TIMER),
		[excludeDone],
	);

	const onQueryChange = useCallback(
		(query: string): void => {
			debouncedRequestSuggestions.cancel();
			setHasLastFetchFailed(false);
			if (query) {
				lastQuery.current = query;
				setIsLoading(true);
				debouncedRequestSuggestions(query);
			} else {
				lastQuery.current = '';
				setIsLoading(false);
				setSuggestions(defaultSuggestions);
			}
		},
		[debouncedRequestSuggestions, defaultSuggestions],
	);

	const handleFocus = useCallback(
		(e: FocusEvent<HTMLElement>): void => {
			if (lastQuery.current === '' && !defaultSuggestions) {
				setIsLoading(true);
				requestSuggestions();
			}
			onFocus && onFocus(e);
		},
		[defaultSuggestions, onFocus, requestSuggestions],
	);

	const filterOption = useCallback(
		(option: Option): boolean => {
			if (hasLastFetchFailed) {
				// If the fetch has failed, we will show a disabled option to indicate the error to users
				return true;
			}

			return filterOptionByLabelAndFilterValues(option, lastQuery.current || '');
		},
		[hasLastFetchFailed],
	);

	const getNoOptionsMessage = useCallback(() => {
		const noOptions =
			noOptionsMessage !== '' ? noOptionsMessage : formatMessage(messages.noMatches);
		// we now get the translated message from the backend, but will fall back to the old message if it's not there
		return hasLastFetchFailed ? formatMessage(messages.failedFetch) : noOptions;
	}, [formatMessage, hasLastFetchFailed, noOptionsMessage]);

	const getLoadingMessage = useCallback(
		(): string => formatMessage(messages.loading),
		[formatMessage],
	);

	useEffect(() => {
		if (!isInitialMount.current) {
			setIsLoading(true);
			requestSuggestions(lastQuery.current);
		}
		isInitialMount.current = false;
	}, [excludeDone, requestSuggestions]);

	const spacing = useMemo(() => spacingProps, [spacingProps]);

	return (
		<Select
			// need to stop esc keydown propagation to avoid modal close if select is open
			onKeyDown={preventEscKeyPropagationKeyPressHandler}
			inputId={`${fieldId}-field`}
			options={suggestions}
			value={value}
			formatOptionLabel={formatOptionLabel}
			onBlur={onBlur}
			onFocus={handleFocus}
			onChange={onChange}
			menuPosition={isDropdownMenuFixedAndLayered ? 'fixed' : undefined}
			styles={defaultSelectStyles}
			closeMenuOnScroll={onCloseMenuOnScroll}
			isMulti={false}
			isLoading={isLoading}
			onInputChange={onQueryChange}
			loadingMessage={getLoadingMessage}
			noOptionsMessage={getNoOptionsMessage}
			placeholder={formatMessage(messages.placeholder)}
			components={{ Menu }}
			componentsProps={componentProps}
			autoFocus={autoFocus}
			filterOption={filterOption}
			validationState={isInvalid === true ? 'error' : undefined}
			hideSelectedOptions
			isClearable={isClearable}
			{...(isEditing !== undefined && { menuIsOpen: isEditing })}
			isDisabled={isDisabled}
			defaultMenuIsOpen={defaultMenuIsOpen} // Without this, issue view Parent field requires two clicks to open the menu
			classNamePrefix={classNamePrefix}
			spacing={spacing}
			aria-label={formatMessage(messages.placeholder)}
			aria-labelledby={ariaLabelledBy}
			{...(fg('issue_view_in_program_board') && { menuPlacement: 'auto' })}
		/>
	);
};
