import head from 'lodash/head';
import { JiraProjectAri } from '@atlassian/ari/jira/project';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import type { StandardFieldType as NewStandardFieldType } from '@atlassian/jira-polaris-domain-field/src/field-types/types.tsx';
import type {
	Field,
	FieldConfiguration,
} from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { PolarisPlayKind } from '@atlassian/jira-polaris-domain-field/src/play/types.tsx';
import {
	DuplicateNameError,
	isDuplicateFieldNameError,
} from '@atlassian/jira-polaris-lib-errors/src/common/utils/duplicate-name-error/index.tsx';
import {
	getEntityLimitError,
	EntityLimitError,
} from '@atlassian/jira-polaris-lib-errors/src/common/utils/entity-limit-error/index.tsx';
import { ENTITY_LIMIT_ERROR_TYPE } from '@atlassian/jira-polaris-lib-errors/src/common/utils/entity-limit-error/types.tsx';
import { createErrorAnalytics } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import type { DynamicFieldFormula } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/types.tsx';
import type { FetchResponse } from '@atlassian/jira-polaris-remote-field/src/controllers/crud/types.tsx';
import type { GetState, SetState, StoreActionApi } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types.tsx';

const createProjectAri = (siteId: string, projectId: undefined | string) =>
	JiraProjectAri.create({ projectId: String(projectId), siteId }).toString();

const updateState = (
	newFields: FetchResponse,
	getState: GetState<State>,
	setState: SetState<State>,
): Field => {
	const state = getState();
	const newState = {
		fields: {
			...state.fields,
			...newFields.fields,
		},
		fieldValueDecorations: {
			...state.fieldValueDecorations,
			...newFields.fieldValueDecorations,
		},
	};
	setState(newState);
	return newFields.fields[Object.keys(newFields.fields)[0]];
};

const handleFetchError = async (err: FetchError, callback: (err: Error) => void): Promise<void> => {
	const { statusCode, originalResponse } = err;
	if (statusCode === 409 && originalResponse) {
		const responseJson = await originalResponse.json();
		const errorString = responseJson?.error;
		if (isDuplicateFieldNameError(errorString)) {
			throw new DuplicateNameError(errorString, [{ error: errorString }], statusCode);
		}

		// for now the entity limit error only relies on the statuscode 409, so this is the only else case currently
		const error = new EntityLimitError(
			errorString,
			[{ error: errorString }],
			statusCode,
			ENTITY_LIMIT_ERROR_TYPE.FIELDS_PER_PROJECT,
		);
		callback(error);
		throw error;
	}

	throw err;
};

export const createField =
	(name: string, type: NewStandardFieldType, configuration?: FieldConfiguration) =>
	async (
		{ getState, setState }: StoreActionApi<State>,
		{ projectId, issueTypeIds, fieldRemote, onFieldUpdateFailed }: Props,
	): Promise<Field> => {
		const issueTypeId = head(issueTypeIds);

		if (issueTypeId === undefined) {
			throw new Error('cannot create field for unknown idea type');
		}
		if (projectId === undefined) {
			throw new Error('cannot create field for unknown project');
		}

		try {
			const fieldsData = await fieldRemote.createField({
				jiraIssueTypeId: issueTypeId,
				name: name.trim(),
				type,
				configuration,
			});
			return updateState(fieldsData, getState, setState);
		} catch (err) {
			if (err instanceof FetchError) {
				await handleFetchError(err, onFieldUpdateFailed);
			}
			throw err;
		}
	};

export const createConnectionField =
	(name: string, type: NewStandardFieldType) =>
	async ({ dispatch }: StoreActionApi<State>): Promise<Field> => {
		try {
			return await dispatch(createField(name, type, { issueTypeFilters: [] }));
		} catch (error) {
			if (error instanceof Error) {
				fireErrorAnalytics(
					createErrorAnalytics(
						'polaris.error.controllers.field.actions.create-field.connection',
						error,
					),
				);
			}
			throw error;
		}
	};

export const createCalculatedField =
	(name: string, formula: DynamicFieldFormula) =>
	async (
		{ getState, setState }: StoreActionApi<State>,
		{ cloudId, issueTypeIds, projectId, fieldRemote, onFieldUpdateFailed }: Props,
	): Promise<Field> => {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		const issueTypeId = issueTypeIds![0];

		try {
			const fieldsData = await fieldRemote.createCalculatedField({
				project: createProjectAri(cloudId, projectId),
				label: name.trim(),
				formula,
				issueTypeId,
			});
			return updateState(fieldsData, getState, setState);
		} catch (err) {
			if (err instanceof FetchError) {
				await handleFetchError(err, onFieldUpdateFailed);
			}
			throw err;
		}
	};

export const createPlayField =
	(name: string, maxSpend: number, kind: PolarisPlayKind = 'PolarisBudgetAllocationPlay') =>
	async (
		{ getState, setState }: StoreActionApi<State>,
		{ onActionFailed, onFieldUpdateFailed, cloudId, projectId, fieldRemote, issueTypeIds }: Props,
		// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
	): Promise<Field | void> => {
		try {
			const issueTypeId = head(issueTypeIds);

			if (issueTypeId === undefined) {
				throw new Error('cannot create field for unknown idea type');
			}

			const fieldsData = await fieldRemote.createPlayField({
				kind,
				label: name.trim(),
				parameters: { maxSpend },
				project: createProjectAri(cloudId, projectId),
				issueTypeId,
			});
			return updateState(fieldsData, getState, setState);
		} catch (err) {
			const error = err instanceof Error ? err : new Error('Unknown error');
			const entityLimitError = getEntityLimitError(error);
			if (entityLimitError) {
				onFieldUpdateFailed(entityLimitError);
			} else {
				onActionFailed(error);
			}
			throw error;
		}
	};

export const updatePlayFieldParameters =
	(id: string, parameters: { maxSpend: number }) =>
	({ getState, setState }: StoreActionApi<State>) => {
		const { fields } = getState();
		Object.keys(fields).forEach((key) => {
			const field = fields[key];
			if (field.play?.id === id) {
				fields[key] = {
					...field,
					play: {
						...field.play,
						parameters,
					},
				};
			}
		});
		setState({
			fields: { ...fields },
		});
	};
