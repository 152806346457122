export const PERFORMANCE_KEYS = {
	// Click on status to open dropdown → render transitions as an options in the dropdown
	// With and optional feedback phase is when the opened dropdown has no transitions just yet and renders a spinner
	TIME_TO_RENDER_OPTIONS: 'jira.fe.issue.fields.status.time-to.render-options',

	// Select new status → close dropdown and render updated status
	TIME_TO_APPLY_SELECTED_OPTION: 'jira.fe.issue.fields.status.time-to.reflect-selected',

	// Cancel status change (click outside or press esc) → render current status
	TIME_TO_CANCEL_DROPDOWN: 'jira.fe.issue.fields.status.time-to.cancel-dropdown',

	// Select new status that has a transition screen → open dialog with transition screen form
	TIME_TO_OPEN_TRANSITION_SCREEN: 'jira.fe.issue.fields.status.time-to.open-transition-screen',

	// Submit transition screen without error → submits the transition screen form and closes the dialog
	TIME_TO_CONFIRM_TRANSITION_SCREEN:
		'jira.fe.issue.fields.status.time-to.confirm-transition-screen',

	// Canceling the transition screen and closing the dialog
	TIME_TO_CANCEL_TRANSITION_SCREEN: 'jira.fe.issue.fields.status.time-to.cancel-transition-screen',

	// Submit transition screen with error → re-render dialog again with reported error
	TIME_TO_ERROR_TRANSITION_SCREEN: 'jira.fe.issue.fields.status.time-to.error-transition-screen',
} as const;

export const ISSUE_TRANSITION_STATUS_FIELD = 'issue-transition-status-field';
