import { defineMessages } from '@atlassian/jira-intl';
import { PlaceholderEnum } from './types.tsx';

const issueKey = PlaceholderEnum.ISSUE_KEY;
const issueSummary = PlaceholderEnum.ISSUE_SUMMARY;
const issueSummaryShort = PlaceholderEnum.ISSUE_SUMMARY_SHORT;
const issueType = PlaceholderEnum.ISSUE_TYPE;
const projectKey = PlaceholderEnum.PROJECT_KEY;

export default defineMessages({
	configSectionTitle: {
		id: 'development-summary-branch.common.config-section-title',
		defaultMessage: 'Update branch name format',
		description:
			"Title of the config section. 'Branch' is a technical term for the tool Git, and is most often not translated.",
	},
	configTabsDescription: {
		id: 'development-summary-branch.common.config-tabs-description',
		defaultMessage: 'Select fields to add',
		description: 'A short description for how to use config tabs.',
	},
	[issueKey]: {
		id: 'development-summary-branch.common.issue-key',
		defaultMessage: 'Issue key',
		description: 'Display name of a config tab.',
	},
	[issueSummary]: {
		id: 'development-summary-branch.common.issue-summary',
		defaultMessage: 'Issue summary',
		description: 'Display name of a config tab.',
	},
	[issueSummaryShort]: {
		id: 'development-summary-branch.common.issue-summary-short',
		defaultMessage: 'Issue summary short',
		description: 'Display name of a config tab.',
	},
	[issueType]: {
		id: 'development-summary-branch.common.issue-type',
		defaultMessage: 'Issue type',
		description: 'Display name of a config tab.',
	},
	[projectKey]: {
		id: 'development-summary-branch.common.project-key',
		defaultMessage: 'Project key',
		description: 'Display name of a config tab.',
	},
	previewSectionTitle: {
		id: 'development-summary-branch.common.preview-section-title',
		defaultMessage: 'Preview',
		description: 'Title of the config preview section.',
	},
	resetButtonLabel: {
		id: 'development-summary-branch.common.reset-button-label',
		defaultMessage: 'Reset',
		description: 'Label of the reset button',
	},
	cancelButton: {
		id: 'development-summary-branch.common.cancel-button',
		defaultMessage: 'Cancel',
		description: 'Text of the cancel button',
	},
	saveButton: {
		id: 'development-summary-branch.common.save-button',
		defaultMessage: 'Save',
		description: 'Text of the save button',
	},
	errorMessage: {
		id: 'development-summary-branch.common.error-message',
		defaultMessage: 'You must include the Issue key field',
		description: "Error message for a branch config which doesn't have 'issue key' field",
	},
	saveError: {
		id: 'development-summary-branch.common.save-error',
		defaultMessage: "We couldn't save this branch name format. Try again.",
		description: 'Error message for config branch data save failed',
	},
	copyPageGitCommandErrorMessage: {
		id: 'development-summary-branch.common.copy-page-git-command-error-message',
		defaultMessage:
			'This command exceeds {maxLength} characters. To make it shorter, copy and edit the command when creating your branch.',
		description:
			'Warning message for a git command longer than maxLength characters in copy git command page',
	},
	gitCommandSettingDescription: {
		id: 'development-summary-branch.common.git-command-setting-description',
		defaultMessage: 'Include git command',
		description:
			'A short description next to a checkbox for including the git command in front of the branch name',
	},
	templateLabel: {
		id: 'development-summary-branch.common.template-label',
		defaultMessage: 'Git branch name template',
		description: 'Label for the template builder input',
	},
});
