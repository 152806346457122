import React, { memo, useMemo, type ReactNode } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import Lozenge from '@atlaskit/lozenge';
import { SummaryItem } from '@atlassian/jira-development-summary-common/src/ui/summary-item/index.tsx';
import { FormattedI18nMessage } from '@atlassian/jira-formatted-i18n-message/src/ui/index.tsx';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import messages from './messages.tsx';
import type { Review as ReviewType, ReviewState } from './types.tsx';

const statusCategoryToAppearenceMap: Record<ReviewState, string> = {
	REVIEW: 'inprogress',
	SUMMARIZE: 'inprogress',
	DRAFT: 'new',
	CLOSED: 'success',
	APPROVAL: 'inprogress',
	DEAD: 'removed',
	REJECTED: 'removed',
	UNKNOWN: 'default',
};

type ReviewProps = {
	review: ReviewType | undefined | null;
	onClick?: () => void;
};

export const areEqual = (previousProps: ReviewProps, nextProps: ReviewProps): boolean =>
	nextProps.review?.count === previousProps.review?.count &&
	nextProps.review?.lastUpdated === previousProps.review?.lastUpdated &&
	nextProps.review?.stateCount === previousProps.review?.stateCount &&
	nextProps.review?.state === previousProps.review?.state;

export const Review = memo<ReviewProps>(({ review, onClick }: ReviewProps) => {
	const { formatNumber, formatMessage } = useIntl();
	const { count = 0, stateCount = 0, state } = review ?? {};
	const title = useMemo(
		() => (
			<FormattedI18nMessage
				componentsMapping={{
					bold: BoldFont,
				}}
				message={formatMessage(messages.title, {
					count: formatNumber(stateCount),
					boldStart: '{boldStart}',
					boldEnd: '{boldEnd}',
				})}
			/>
		),
		[formatMessage, formatNumber, stateCount],
	);

	const secondaryData = useMemo(() => {
		const safeState = state || 'UNKNOWN';
		// @ts-expect-error - TS2322 - Type 'string | undefined' is not assignable to type 'ThemeAppearance | undefined'.
		return <Lozenge appearance={statusCategoryToAppearenceMap[safeState]}>{safeState}</Lozenge>;
	}, [state]);

	if (review && count > 0) {
		return (
			<SummaryItem
				title={title}
				href={null}
				onClick={onClick}
				itemType="review"
				secondaryData={secondaryData}
				data-testid="development-summary-review.ui.summary-item"
			/>
		);
	}
	return <></>;
}, areEqual);

export default Review;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BoldFont = styled.span<{ children: ReactNode }>({
	fontWeight: token('font.weight.medium'),
});
