/** @jsx jsx */
import React, { forwardRef, useContext, useCallback, useEffect } from 'react';
import { css, jsx } from '@compiled/react';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import type { CreatePayload } from '@atlaskit/link-create';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useTenantHasConfluence } from '@atlassian/jira-business-confluence-pages/src/utils/use-tenant-has-confluence/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { toFlagId, useFlagService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueAri } from '@atlassian/jira-issue-hooks/src/services/use-issue-ari/index.tsx';
import {
	type ConfluenceWhiteboard,
	CONFLUENCE_WHITEBOARD,
} from '@atlassian/jira-issue-shared-types/src/common/types/confluence-content-type.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import {
	fetchConfluenceWhiteboardSuccess,
	type FetchConfluenceWhiteboardSuccess,
} from '@atlassian/jira-issue-view-store/src/actions/confluence-whiteboards-actions.tsx';
import { SET_IS_CREATE_CONTENT_MODAL_OPEN } from '../../../common/types/actions.tsx';
import { CONTENT_TYPE, type CreateContentModalCoreProps } from '../../../common/types/types.tsx';
import { ContentMenuContext } from '../../../controllers/create-content-menu-context/index.tsx';
import { useCreateContentClickBehavior } from '../../../controllers/use-create-content-click-behavior/index.tsx';
import { useEmbeddedPageTracking } from '../../../controllers/use-embedded-page-tracking/index.tsx';
import { useConfluenceWhiteboard } from '../../../controllers/use-fetch-whiteboard/index.tsx';
import { useWhiteboardIssueLinking } from '../../../controllers/use-whiteboard-issue-linking/index.tsx';
import { ACCESS_REQUEST_CAPABILITIES } from '../../../controllers/utils/index.tsx';
import { Whiteboard16Icon } from './assets/index.tsx';
import messages from './messages.tsx';

interface WhiteboardLinkProps {
	children: React.ReactNode;
}
const WhiteboardLink = forwardRef<HTMLAnchorElement, WhiteboardLinkProps>(
	({ children, ...props }, ref: React.Ref<HTMLAnchorElement>) => (
		<a
			{...props}
			target="_blank"
			ref={ref}
			href="https://www.atlassian.com/software/confluence/whiteboards"
		>
			{children}
		</a>
	),
);

type CreateWhiteboardMenuItemComponentType = Omit<
	CreateContentModalCoreProps,
	'onCreateBehavior'
> & {
	onFetchConfluenceWhiteboardSuccess: (
		whiteboard: ConfluenceWhiteboard,
	) => FetchConfluenceWhiteboardSuccess;
};

export const CreateWhiteboardMenuItemComponent = ({
	onCancel,
	onCloseComplete,
	onCreate,
	onFailure,
	onOpenComplete,
	onFetchConfluenceWhiteboardSuccess,
	isQuickActionsListView,
	setQuickActionListItem,
}: CreateWhiteboardMenuItemComponentType) => {
	const { formatMessage } = useIntl();
	const {
		setCreateContentRuntimeProps,
		embeddedConfluenceSource,
		accessStatus,
		setActiveWhiteboardData,
		setEmbeddedWhiteboardModalOpen,
		setContentMenuState,
	} = useContext(ContentMenuContext);
	const issueAri = useIssueAri();
	const { linkIssueToWhiteboard } = useWhiteboardIssueLinking({ issueAri });
	const { fetchConfluenceWhiteboard } = useConfluenceWhiteboard();
	const fireAnalyticsEvent = useEmbeddedPageTracking();
	const { showFlag } = useFlagService();
	const tenantHasConfluence = useTenantHasConfluence();
	const getCreateContentClickBehavior = useCreateContentClickBehavior();

	const showWhiteboardLink = useCallback(() => {
		if (tenantHasConfluence && accessStatus !== ACCESS_REQUEST_CAPABILITIES.ACCESS_EXISTS) {
			return { component: WhiteboardLink };
		}
		return {};
	}, [accessStatus, tenantHasConfluence]);

	const linkWhiteboard = useCallback(
		async (whiteboardAri: string, confluenceWhiteboardUrl: string) => {
			try {
				await linkIssueToWhiteboard(whiteboardAri);

				showFlag({
					key: toFlagId('createdWhiteboardSucceededOnLink'),
					type: 'success',
					title: formatMessage(messages.createdWhiteboardSucceededOnLinkTitle),
					description: formatMessage(messages.createdWhiteboardSucceededOnLinkDescription),
				});

				fireAnalyticsEvent({
					action: 'success',
					eventName: 'linkIssueToWhiteboard',
					embeddedConfluenceSource,
					eventType: 'operational',
				});
			} catch (error) {
				showFlag({
					key: toFlagId('createdWhiteboardFailedOnLink'),
					type: 'error',
					title: formatMessage(messages.createdWhiteboardFailedOnLinkTitle),
					description: formatMessage(messages.createdWhiteboardFailedOnLinkDescription),
					actions: [
						{
							// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
							onClick: () => window.open(confluenceWhiteboardUrl, '_blank'),
							content: messages.createdWhiteboardFailedOnLinkAction,
						},
					],
				});

				fireErrorAnalytics({
					meta: {
						id: 'linkIssueToWhiteboard',
						teamName: 'confluence-better-together',
						packageName: 'jiraIssueCreateConfluenceContent',
					},
					attributes: { embeddedConfluenceSource },
					error: new Error(
						`Failed to link Confluence whiteboard: ${error instanceof Error ? error.message : JSON.stringify(error)}`,
					),
				});

				throw new Error('linkIssueToWhiteboardFailed');
			}
		},
		[embeddedConfluenceSource, fireAnalyticsEvent, formatMessage, linkIssueToWhiteboard, showFlag],
	);

	const linkAndFetchWhiteboard = useCallback(
		async (whiteboardAri: string, confluenceWhiteboardUrl: string) => {
			try {
				await linkWhiteboard(whiteboardAri, confluenceWhiteboardUrl);
				const confluenceWhiteboard = await fetchConfluenceWhiteboard(whiteboardAri);
				const whiteboard: ConfluenceWhiteboard = {
					whiteboardId: confluenceWhiteboard.whiteboardId,
					href: `${confluenceWhiteboard.links?.base}${confluenceWhiteboard.links?.webUi}`,
					author: confluenceWhiteboard.author,
					title: confluenceWhiteboard.title,
					id: confluenceWhiteboard.id,
					type: CONFLUENCE_WHITEBOARD,
				};

				onFetchConfluenceWhiteboardSuccess(whiteboard);
				fireAnalyticsEvent({
					action: 'success',
					eventName: 'fetchConfluenceWhiteboard',
					embeddedConfluenceSource,
					eventType: 'operational',
				});
			} catch (error) {
				showFlag({
					key: toFlagId('createdWhiteboardFailedOnLinkFetch'),
					type: 'error',
					title: formatMessage(messages.createdWhiteboardFailedOnLinkTitle),
					description: formatMessage(messages.createdWhiteboardFailedOnLinkDescription),
					actions: [
						{
							// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
							onClick: () => window.open(confluenceWhiteboardUrl, '_blank'),
							content: messages.createdWhiteboardFailedOnLinkAction,
						},
					],
				});

				if (error instanceof Error && error?.message === 'linkIssueToWhiteboardFailed') {
					return;
				}

				fireErrorAnalytics({
					meta: {
						id: 'fetchConfluenceWhiteboard',
						teamName: 'confluence-better-together',
						packageName: 'jiraIssueCreateConfluenceContent',
					},
					attributes: { embeddedConfluenceSource },
					error: new Error(
						`Failed to fetch Confluence whiteboard: ${error instanceof Error ? error.message : JSON.stringify(error)}`,
					),
				});
			}
		},
		[
			linkWhiteboard,
			fetchConfluenceWhiteboard,
			onFetchConfluenceWhiteboardSuccess,
			fireAnalyticsEvent,
			embeddedConfluenceSource,
			showFlag,
			formatMessage,
		],
	);

	const createConfluenceWhiteboard = useCallback(() => {
		setContentMenuState({ type: SET_IS_CREATE_CONTENT_MODAL_OPEN, payload: true });
		setCreateContentRuntimeProps({
			isOpen: true,
			isQuickActionsListView,
			contentType: CONTENT_TYPE.WHITEBOARD,
			onCancel,
			onOpenComplete,
			onCloseComplete,
			onCreate: (payload: CreatePayload): void => {
				onCreate && onCreate(payload);
				const { url, ari: whiteboardAri } = payload;
				const standaloneConfluenceWhiteboardUrl = `${url}?openTemplatePicker=true`;
				if (!whiteboardAri || !url) {
					showFlag({
						key: toFlagId('createConfluenceWhiteboardFailed'),
						type: 'error',
						title: formatMessage(messages.createWhiteboardFailedTitle),
						description: formatMessage(messages.createWhiteboardFailedDescription),
					});

					fireErrorAnalytics({
						meta: {
							id: 'createConfluenceWhiteboard',
							teamName: 'confluence-better-together',
							packageName: 'jiraIssueCreateConfluenceContent',
						},
						attributes: { embeddedConfluenceSource },
						error: new Error('Failed to create Confluence whiteboard'),
					});

					return;
				}

				linkAndFetchWhiteboard(whiteboardAri, standaloneConfluenceWhiteboardUrl);

				setActiveWhiteboardData(payload);
				setEmbeddedWhiteboardModalOpen(true);

				fireAnalyticsEvent({
					action: 'success',
					eventName: 'createConfluenceWhiteboard',
					embeddedConfluenceSource,
					eventType: 'operational',
				});
			},
			onFailure: (error: unknown): void => {
				setContentMenuState({ type: SET_IS_CREATE_CONTENT_MODAL_OPEN, payload: false });

				if (error instanceof Error && error?.message === 'No Whiteboard access') {
					showFlag({
						type: 'error',
						title: formatMessage(messages.noWhiteboardBetaAccessToastTitle),
						actions: [
							{
								onClick: () =>
									// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
									window.open(
										'https://www.atlassian.com/software/confluence/whiteboards',
										'_blank',
									),
								content: messages.noWhiteboardBetaAccessToastAction,
							},
						],
					});
					return;
				}

				showFlag({
					key: toFlagId('createConfluenceWhiteboardFailed'),
					type: 'error',
					title: formatMessage(messages.createWhiteboardFailedTitle),
					description: formatMessage(messages.createWhiteboardFailedDescription),
				});

				fireErrorAnalytics({
					meta: {
						id: 'createConfluenceWhiteboard',
						teamName: 'confluence-better-together',
						packageName: 'jiraIssueCreateConfluenceContent',
					},
					attributes: { embeddedConfluenceSource },
					error: new Error(
						`Failed to create Confluence whiteboard from Link Create Component: ${error instanceof Error ? error.message : JSON.stringify(error)}`,
					),
				});
				onFailure && onFailure(error);
			},
		});
	}, [
		embeddedConfluenceSource,
		fireAnalyticsEvent,
		formatMessage,
		linkAndFetchWhiteboard,
		onCancel,
		onCloseComplete,
		onCreate,
		onFailure,
		onOpenComplete,
		setActiveWhiteboardData,
		setCreateContentRuntimeProps,
		setEmbeddedWhiteboardModalOpen,
		setContentMenuState,
		showFlag,
		isQuickActionsListView,
	]);

	const onClick = useCallback(() => {
		fireAnalyticsEvent({
			action: 'clicked',
			eventName: 'createWhiteboardMenuItem',
			embeddedConfluenceSource,
			tenantHasConfluence,
		});

		// When cross join is implemented we may not need the if condition here
		if (!tenantHasConfluence || accessStatus === ACCESS_REQUEST_CAPABILITIES.ACCESS_EXISTS) {
			getCreateContentClickBehavior({
				accessStatus,
				createContent: createConfluenceWhiteboard,
				tenantHasConfluence,
			})();
		}
	}, [
		fireAnalyticsEvent,
		embeddedConfluenceSource,
		tenantHasConfluence,
		accessStatus,
		getCreateContentClickBehavior,
		createConfluenceWhiteboard,
	]);

	const isQuickActionsGA = fg('quick_actions_menu_ga') && isQuickActionsListView;

	useEffect(() => {
		// eslint-disable-next-line jira/ff/no-preconditioning
		fg('quick_actions_menu_ga') &&
			setQuickActionListItem &&
			setQuickActionListItem('confluence', 'new-whiteboard', {
				onClick,
				isDisabled: false,
				testId:
					'issue-create-confluence-content.ui.create-confluence-content-menu.create-whiteboard-menu-item.create-whiteboard-menu-item',
				iconComponent: (
					<Box xcss={whiteBoardLinkStyle}>
						<Whiteboard16Icon />
					</Box>
				),
				labelComponent: (
					<>
						<span css={messageStyles}>
							{fg('quick_actions_menu_ga')
								? formatMessage(messages.newConfluenceWhiteboardQuickActionsList)
								: formatMessage(messages.createConfluencewhiteboardQuickActionsList)}
						</span>
					</>
				),
				additionalDropdownProps: { ...showWhiteboardLink() },
			});
	}, [formatMessage, onClick, showWhiteboardLink, setQuickActionListItem]);

	if (isQuickActionsGA) {
		return null;
	}

	return (
		<DropdownItem
			testId="issue-create-confluence-content.ui.create-confluence-content-menu.create-whiteboard-menu-item.create-whiteboard-menu-item"
			elemBefore={<Whiteboard16Icon />}
			onClick={onClick}
			{...showWhiteboardLink()}
		>
			{formatMessage(messages.createConfluenceWhiteboardItem)}
		</DropdownItem>
	);
};

const mapStateToProps = () => ({});
const mapDispatchToProps = {
	onFetchConfluenceWhiteboardSuccess: fetchConfluenceWhiteboardSuccess,
};
export const CreateWhiteboardMenuItem = connect(
	mapStateToProps,
	mapDispatchToProps,
)(CreateWhiteboardMenuItemComponent);

const whiteBoardLinkStyle = xcss({
	paddingLeft: 'space.050',
});

const messageStyles = css({
	paddingLeft: token('space.050'),
});
