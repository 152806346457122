import React, { type ReactNode, type ReactElement, type FC, type PropsWithChildren } from 'react';
import { AssociatedIssuesContextContainer } from '@atlassian/jira-associated-issues-context-service/src/context.tsx';
import { IssueAttachmentStoreContainer } from '@atlassian/jira-issue-attachments-base/src/services/index.tsx';
import { IssueContextContainer } from '@atlassian/jira-issue-context-service/src/context.tsx';
import { DesignPanelContainer } from '@atlassian/jira-issue-design-section-store/src/index.tsx';
import { IssueRefreshLoadingContainer } from '@atlassian/jira-issue-field-base/src/services/issue-refresh-loading-service/index.tsx';
import { IssueFieldEditingContextProvider } from '@atlassian/jira-issue-field-optional-editing-state-manager/src/index.tsx';
import { IssueRefreshServiceStoreContainer } from '@atlassian/jira-issue-refresh-service/src/services/context.tsx';
import { IssueScrollContainer } from '@atlassian/jira-issue-scroll/src/services/index.tsx';
import { TriggerIssueTransitionModalContainer } from '@atlassian/jira-issue-transition-trigger/src/controllers/trigger-issue-transition-modal/index.tsx';
import { StoreForIssueViewContainer } from '@atlassian/jira-issue-view-common-views/src/store-provider/index.tsx';
import { EcosystemStoreContainer } from '@atlassian/jira-issue-view-ecosystem-service/src/services/context.tsx';
import { IssueViewFieldUpdateEventContainer } from '@atlassian/jira-issue-view-field-update-events/src/services/issue-view-field-update-events/index.tsx';
import { ForceStoreContainer } from '@atlassian/jira-issue-view-forge-service/src/services/context.tsx';
import { FindFieldDialogContainer } from '@atlassian/jira-issue-view-foundation/src/issue-actions/find-field/controller/index.tsx';
import { IssueLayoutContainer } from '@atlassian/jira-issue-view-services/src/issue-layout-service/context.tsx';
import { FormInIssueStatusContainer } from '@atlassian/jira-proforma-panel-in-issue-view-services/src/services/forms-status-in-issue-service/index.tsx';
import { UiModificationsContainer } from '@atlassian/jira-ui-modifications-view-issue-view/src/ui/container/index.tsx';
import { WorklogTimeTrackingContainer } from '@atlassian/jira-worklog-time-tracking/src/services/store/index.tsx';
import type { ContainerComponent } from '@atlassian/react-sweet-state';

const composeContainers = (
	containers: readonly ContainerComponent<unknown>[],
	scope: string | undefined,
	children: ReactNode,
) =>
	containers.reduceRight<ReactElement>(
		(acc, Container) => <Container scope={scope}>{acc}</Container>,
		<>{children}</>,
	);

// issue-view "contains" a set of sweet-state stores inside it preventing global pollution
// information from them will not leak outside issue view
const NON_GLOBAL_ISSUE_STORE_CONTAINERS = [
	ForceStoreContainer,
	StoreForIssueViewContainer,
	DesignPanelContainer,
	EcosystemStoreContainer,
	IssueRefreshServiceStoreContainer,
	FindFieldDialogContainer,
	IssueAttachmentStoreContainer,
	IssueScrollContainer,
	IssueLayoutContainer,
	IssueViewFieldUpdateEventContainer,
	FormInIssueStatusContainer,
	IssueAttachmentStoreContainer,
	// is expected to contain current issue information and should be scoped
	// but due to JIV-14202 we do not actually scope on it
	IssueContextContainer,
	IssueFieldEditingContextProvider,
	AssociatedIssuesContextContainer,
] as const;

// a subset of containers above expected to be hard-scoped to a given issue
// their content should be reset on issue change
// JIV-14202: the following containers are not passed into redux and free from glitching problem
const SCOPED_ISSUE_CONTAINERS = [
	// are expected to be "reset" on issue update and are not used by Redux
	FindFieldDialogContainer,
	FormInIssueStatusContainer,
	WorklogTimeTrackingContainer,
	IssueRefreshLoadingContainer,
	UiModificationsContainer,
	TriggerIssueTransitionModalContainer,
	//
] as const;

// just ensure those sets do not intersect
const unscopedIssueContainers = NON_GLOBAL_ISSUE_STORE_CONTAINERS.filter(
	(container) => !SCOPED_ISSUE_CONTAINERS.includes(container),
);

/**
 * Combines given sweet-state containers into one component
 */
export const CombineStateContainers: FC<
	{ scope: string; containers: readonly ContainerComponent<unknown>[] } & PropsWithChildren
> = ({ scope, containers, children }) => composeContainers(containers, scope, children);

/**
 * A sweet-state boundary container or issue-view domain
 * is expected to act as top-level Container for many issue-view sub-stores
 * @public used outside IssueApp
 */
export const IssueStateContainers: FC<{ scope: string } & PropsWithChildren> = ({
	children,
	scope,
}) =>
	composeContainers(
		unscopedIssueContainers,
		undefined,
		composeContainers(SCOPED_ISSUE_CONTAINERS, scope, children),
	);
