/**
 * @generated SignedSource<<a55cbe9cfff2886669422d4caa93dfd3>>
 * @relayHash 893d0c9fba0e5eafeef61c018c08dc98
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 8dfd0632ae9b193ef2be67d89800d8dba887645b6ada77aea07c76fc664ad5dc

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type labels_issueFieldLabelsEditviewFull_LabelsEditViewQuery$variables = {
  id: string;
};
export type labels_issueFieldLabelsEditviewFull_LabelsEditViewQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"labels_issueFieldLabelsEditviewFull_LabelsEditViewWithSuggestionsFragment_suggestionsFragment">;
};
export type labels_issueFieldLabelsEditviewFull_LabelsEditViewQuery = {
  response: labels_issueFieldLabelsEditviewFull_LabelsEditViewQuery$data;
  variables: labels_issueFieldLabelsEditviewFull_LabelsEditViewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v3 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 50
  },
  {
    "kind": "Literal",
    "name": "searchBy",
    "value": ""
  },
  {
    "kind": "Literal",
    "name": "suggested",
    "value": true
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "labels_issueFieldLabelsEditviewFull_LabelsEditViewQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "labels_issueFieldLabelsEditviewFull_LabelsEditViewWithSuggestionsFragment_suggestionsFragment"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "labels_issueFieldLabelsEditviewFull_LabelsEditViewQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": (v3/*: any*/),
                "concreteType": "JiraLabelConnection",
                "kind": "LinkedField",
                "name": "labels",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraLabelEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "JiraLabel",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "name"
                          },
                          (v2/*: any*/)
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "name": "cursor"
                      }
                    ]
                  },
                  {
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "endCursor"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "hasNextPage"
                      }
                    ]
                  }
                ],
                "storageKey": "labels(first:50,searchBy:\"\",suggested:true)"
              },
              {
                "args": (v3/*: any*/),
                "filters": [
                  "suggested",
                  "searchBy",
                  "currentProjectOnly"
                ],
                "handle": "connection",
                "key": "labels_issueFieldLabelsEditviewFull_LabelsEditViewWithSuggestionsFragmentSearch__labels",
                "kind": "LinkedHandle",
                "name": "labels"
              }
            ],
            "type": "JiraLabelsField"
          },
          {
            "kind": "ScalarField",
            "name": "id"
          }
        ]
      }
    ]
  },
  "params": {
    "id": "8dfd0632ae9b193ef2be67d89800d8dba887645b6ada77aea07c76fc664ad5dc",
    "metadata": {},
    "name": "labels_issueFieldLabelsEditviewFull_LabelsEditViewQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "f7c0efbc6f8dee59af5c37b02898ac3e";

export default node;
