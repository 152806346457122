import { createStateHook } from '@atlassian/react-sweet-state';
import { store, useAdjustmentsEnabled } from '../../index.tsx';

export const useHasFinishedLoading = createStateHook(store, {
	selector: (state) => state.hasFinishedLoading,
});
export const useShouldLoad = createStateHook(store, {
	selector: (state) => state.shouldUiModificationsLoad,
});

type UimEnabledStatus = 'unknown' | 'disabled' | 'enabled';

export const useUiModificationsEnabledStatus = (
	hasForgeFinishedLoading: boolean,
): UimEnabledStatus => {
	const shouldUiModificationsLoad = useShouldLoad();
	const hasFinishedLoading = useHasFinishedLoading();
	const adjustmentsAreEnabled = useAdjustmentsEnabled();

	const uimIsEnabled =
		hasForgeFinishedLoading &&
		shouldUiModificationsLoad === 'yes' &&
		hasFinishedLoading &&
		adjustmentsAreEnabled;

	const uimIsDisabled =
		hasForgeFinishedLoading &&
		(shouldUiModificationsLoad === 'no' || (hasFinishedLoading && !adjustmentsAreEnabled));

	if (uimIsEnabled) {
		return 'enabled';
	}
	if (uimIsDisabled) {
		return 'disabled';
	}
	return 'unknown';
};
