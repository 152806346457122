// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	hideDoneTooltipHiddenIssues: {
		id: 'issue.views.common.child-issues-panel.hide-done-icon.tooltip.hidden-issues-info',
		defaultMessage:
			'{completedChildIssuesCount, plural, one {# Done issue is hidden} other {# Done issues are hidden}}. ',
		description: 'Tooltip for the hide done information icon, displays how many issues are hidden.',
	},
	hideDoneTooltipHowToShow: {
		id: 'issue.views.common.child-issues-panel.hide-done-icon.tooltip.how-to-show-info',
		defaultMessage: 'To show, go to (•••)',
		description: 'Tooltip for the hide done information icon, displays how to show issues.',
	},
	hideDoneIconLabel: {
		id: 'issue.views.common.child-issues-panel.hide-done-icon.label',
		defaultMessage:
			'Hide done information - {completedChildIssuesCount, plural, one {# Done issue is hidden} other {# Done issues are hidden}}. To show, go to (•••)',
		description: 'Hide done information icon label.',
	},
	hideDoneTooltipHiddenIssuesIssueTermRefresh: {
		id: 'issue.views.common.child-issues-panel.hide-done-icon.tooltip.hidden-issues-info-issue-term-refresh',
		defaultMessage:
			'{completedChildIssuesCount, plural, one {# Done issue is hidden} other {# Done issues are hidden}}. ',
		description: 'Tooltip for the hide done information icon, displays how many issues are hidden.',
	},
	hideDoneIconLabelIssueTermRefresh: {
		id: 'issue.views.common.child-issues-panel.hide-done-icon.label-issue-term-refresh',
		defaultMessage:
			'Hide done information - {completedChildIssuesCount, plural, one {# Done issue is hidden} other {# Done issues are hidden}}. To show, go to (•••)',
		description: 'Hide done information icon label.',
	},
});
