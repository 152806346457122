import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	tooltipContent: {
		id: 'development-summary-branch.create-branch-command-button.tooltip-content',
		defaultMessage: 'Create and checkout new branch',
		description:
			"The create branch CLI button tooltip content. 'Branch' is a technical term for the tool Git, and is most often not translated.",
	},
});
