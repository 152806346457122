import {
	ACCESS_ROLE_EDITOR,
	ACCESS_ROLE_EDITOR_ARI,
	ACCESS_ROLE_VIEWER_ARI,
} from '@atlassian/jira-polaris-domain-view/src/view-access/constants.tsx';
import type {
	AccessRoleAri,
	AccessRole,
} from '@atlassian/jira-polaris-domain-view/src/view-access/types.tsx';

export const transformRoleToRoleAri = (role: AccessRole): AccessRoleAri =>
	role === ACCESS_ROLE_EDITOR ? ACCESS_ROLE_EDITOR_ARI : ACCESS_ROLE_VIEWER_ARI;
