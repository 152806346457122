import type { OLD_SLA_FORMAT, NEW_SLA_FORMAT } from './constants.tsx';

export type SLA_DISPLAY_FORMAT = typeof OLD_SLA_FORMAT | typeof NEW_SLA_FORMAT;

export type CompletedCycle = {
	failed: boolean;
	remainingTimeLong: string;
	remainingTimeHumanReadable?: string;
	startTime: string;
	stopTime: string;
	breachTimeInEpochMillis?: number | string;
	goalTimeLong?: string;
	remainingTime: string;
	goalTime?: string;
};

export type GoalView = {
	paused: boolean;
	failed: boolean;
	active: boolean;
	closed: boolean;
	metricName: string;
	remainingTime: string;
	remainingTimeLong: string;
	goalTimeLong: string;
	goalTime: string;
	calendarName: string;
	completeGoals: CompletedCycle[];
	startTime: string;
	stopTime: string;
	breachTime?: string;
	breachTimeInEpochMillis?: number;
	stopTimeInEpochMillis?: number;
	slaDisplayFormat?: SLA_DISPLAY_FORMAT; // Making this optional for backward compatibility
	emergencyLevel?: string;
	currentJqlMatch: string;
	nextConditionMatch: string;
};

export type Sla = {
	hasPreviousCycles?: boolean;
	goalViews: GoalView[];
};

export type TransformedGoalView = {
	isBreached: boolean;
	timeRemainingInMinutes: number;
	status: SlaStatus;
	timeRemainingFriendly: string;
	metricName: string;
	goalTime: string;
	calendarName: string;
	previousCycles: CompletedCycle[];
	stopTime: string;
	hoverContent?: string;
	slaDisplayFormat: SLA_DISPLAY_FORMAT;
};

export type TransformedSla = {
	hasPreviousCycles?: boolean;
	goalViews: TransformedGoalView[];
};

export const ONGOING = 'ONGOING' as const;
export const COMPLETED = 'COMPLETED' as const;
export const PAUSED = 'PAUSED' as const;
export type SlaStatus = typeof ONGOING | typeof COMPLETED | typeof PAUSED;
