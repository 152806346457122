import 'rxjs/add/observable/of';
import 'rxjs/add/observable/from';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import isEqual from 'lodash/isEqual';
import { Observable } from 'rxjs/Observable';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import type { ChildIssue } from '@atlassian/jira-issue-view-common-types/src/children-issues-type.tsx';
import transformServerChild from '../../common/transform-server-child/index.tsx';
import reorderSubtask from '../../rest/reorder-subtask.tsx';
import fetchSubtasks from '../../services/fetch-subtasks/index.tsx';
import { getBaseUrl, getParentId } from '../../state/context/selectors.tsx';
import {
	reorderChildrenSuccess,
	reorderChildrenFailed,
	fetchDetailsForIssuesRequest,
	type ReorderChildrenRequestPayload,
} from '../../state/entities/actions.tsx';
import type { State } from '../../state/types.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: State, reorderActionPayload: ReorderChildrenRequestPayload) => {
	const baseUrl = getBaseUrl(state);
	const parentId = getParentId(state);

	if (!parentId) {
		throw new Error('Attempted to reorder subtasks without parentId');
	}

	const { currentOrder, newOrder: optimisticOrder, currentIndex, newIndex } = reorderActionPayload;

	return Observable.from(reorderSubtask(baseUrl, parentId, currentIndex, newIndex))
		.switchMap(() => fetchSubtasks(baseUrl, parentId))
		.flatMap((serverSubtasks = []) => {
			// @ts-expect-error - TS7031 - Binding element 'id' implicitly has an 'any' type.
			const serverOrderIds = serverSubtasks.map(({ id }) => id);
			const optimisticOrderIds = optimisticOrder.map(({ id }) => id);

			if (isEqual(serverOrderIds, optimisticOrderIds)) {
				// Server order matches what we are showing
				// Return success action (but this will not change state)
				return Observable.of(reorderChildrenSuccess(optimisticOrder));
			}

			if (isEqual(serverOrderIds.slice().sort(), optimisticOrderIds.slice().sort())) {
				// Issues are the same, just different order
				// Update our order to match what is returned by the server
				// @ts-expect-error - TS7006 - Parameter 'id' implicitly has an 'any' type.
				const differentOrder: ChildIssue[] = serverOrderIds.map((id) =>
					optimisticOrder.find((issue) => issue.id === id),
				);
				return Observable.of(reorderChildrenSuccess(differentOrder));
			}

			// Server has a different set of issues, update what we have
			// @ts-expect-error - TS7006 - Parameter 'serverIssue' implicitly has an 'any' type.
			const newOrder = serverSubtasks.map((serverIssue) => {
				const { id } = serverIssue;
				const existingIssue = optimisticOrder.find((issue) => issue.id === id) || {};
				return {
					...existingIssue, // use existing data (can have more than serverIssue)
					...transformServerChild(serverIssue, baseUrl), // add serverIssue incase of new issue
				};
			});
			return Observable.of(
				reorderChildrenSuccess(newOrder), // update UI with new issue order
				// @ts-expect-error - TS2769 - No overload matches this call.
				fetchDetailsForIssuesRequest(), // re-fetch data for issues, new issue might have assignee
			);
		})
		.catch((error) => {
			log.safeErrorWithoutCustomerData(
				'issue.views.common.child-issues-panel.reorder-subtask',
				'Failed to reorder subtasks',
				error,
			);
			// Reordering failed, put order back to current order
			// Will also show error flag
			return Observable.of(
				reorderChildrenFailed(currentOrder, optimisticOrder, currentIndex, newIndex),
			);
		});
};
