import type { ViewIssueData as IssueServiceViewIssueData } from '@atlassian/jira-issue-fetch-services/src/types.tsx';
import type { ViewIssueData } from '@atlassian/jira-issue-gira-transformer-types/src/common/types/server-data.tsx';
import type { WorklogEntitiesState } from '@atlassian/jira-issue-gira-transformer-types/src/common/types/worklogs.tsx';
import { normalizeWorklogs } from '../../worklog-transformer.tsx';
import { getWorklogsQuery } from './graphql.tsx';

const NUM_INITIAL_WORKLOG_TO_LOAD = 5;
const CREATED_ORDER_DESCENDING = '-started';

export const getQuery = () =>
	getWorklogsQuery(NUM_INITIAL_WORKLOG_TO_LOAD, 0, CREATED_ORDER_DESCENDING);

export const transformData = (
	issue: ViewIssueData | IssueServiceViewIssueData,
): WorklogEntitiesState => {
	const { worklogs: rawWorklogs } = issue;

	if (!rawWorklogs) {
		return {
			worklogs: {},
			sortedWorklogIds: [],
			totalWorklogs: 0,
			worklogsStartIndex: 0,
		};
	}

	const { totalCount, startIndex, nodes } = rawWorklogs;
	const { worklogs } = normalizeWorklogs(nodes);
	const sortedWorklogIds = nodes.map((worklog) => worklog.id);

	return {
		worklogs,
		sortedWorklogIds,
		totalWorklogs: totalCount,
		worklogsStartIndex: startIndex,
	};
};
