// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	fetchErrorTitle: {
		id: 'issue.forge.formatter.fetch-error-title',
		defaultMessage: "We couldn't fetch the edited value. Please reload the page.",
		description:
			'Error message shown by default, when an error occurs after saving custom field data and trying to get formatted value of the field.',
	},
});
