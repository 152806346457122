import React, { useState, useCallback, useMemo } from 'react';
import { styled } from '@compiled/react';
import Popup, { type TriggerProps } from '@atlaskit/popup';
import AkSpinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { FIELD_TYPE_MAP } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/constants.tsx';
import { getUpdateAnalyticsFlowHelper } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/index.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { VOTES_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import { FIELD_KEY } from '../../common/types.tsx';
import { getVoteLabelForDropdown } from '../../common/utils.tsx';
import { VOTING_OPTIONS_MENU } from '../../constants.tsx';
import messages from '../../messages.tsx';
import { useVoteField } from '../../services/vote-field-service/index.tsx';
import VoteButton from '../button/index.tsx';
import type VotersDropdownContent from '../dropdown-content/index.tsx';
import type { DropdownContentProps } from '../types.tsx';
import type { Props } from './types.tsx';

export const DropdownContent = lazyForPaint<typeof VotersDropdownContent>(
	() => import(/* webpackChunkName: "async-voters-dropdown" */ '../dropdown-content'),
);

// eslint-disable-next-line jira/styled/styled-component-order, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpinnerContainer = styled.div({
	height: '121px',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
});

const fallback = (
	<SpinnerContainer>
		<AkSpinner size="medium" />
	</SpinnerContainer>
);

const LazyDropdownContent = ({
	setCloseDropdown,
	disableVotingProps,
	onFieldUpdated,
	...rest
}: DropdownContentProps) => (
	<ErrorBoundary id="issue-voters-list" packageName="issue" render={() => fallback}>
		<Placeholder name="dropdown-content" fallback={fallback}>
			<DropdownContent
				setCloseDropdown={setCloseDropdown}
				disableVotingProps={disableVotingProps}
				onFieldUpdated={onFieldUpdated}
				{...(fg('relay-migration-issue-header-and-parent') ? { ...rest } : {})}
			/>
		</Placeholder>
	</ErrorBoundary>
);

export const VoterDropdown = ({
	disableVotingProps,
	onFieldUpdated,
	useVoteField: useVoteFieldFromProps,
	...rest
}: Props) => {
	const issueKey = useIssueKey();
	const { createAnalyticsEvent } = fg('one_event_rules_them_all_fg')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useAnalyticsEvents()
		: { createAnalyticsEvent: undefined };
	const [fieldValue] = fg('relay-migration-issue-header-and-parent')
		? [{}]
		: // eslint-disable-next-line react-hooks/rules-of-hooks
			useFieldValue({
				issueKey,
				fieldKey: FIELD_KEY,
			});
	const [{ value: voteValue }] = fg('relay-migration-issue-header-and-parent')
		? (useVoteFieldFromProps || useVoteField)({})
		: [{ value: { votes: 0, hasVoted: false } }];

	const hasVoted = fg('relay-migration-issue-header-and-parent')
		? voteValue.hasVoted
		: fieldValue.hasVoted;
	const votes = fg('relay-migration-issue-header-and-parent') ? voteValue.votes : fieldValue.votes;

	const { formatMessage } = useIntl();
	const ariaLabel = useMemo(
		() => formatMessage(getVoteLabelForDropdown(hasVoted, votes), { votes }),
		[formatMessage, hasVoted, votes],
	);
	const tooltip = formatMessage(messages.voteOptionsTooltip);

	const [isOpen, setIsOpen] = useState<boolean>(false);

	const toggleDropdown = useCallback(() => {
		// remove check for createAnalyticsEvent when cleaning up one_event_rules_them_all_fg
		if (createAnalyticsEvent && fg('one_event_rules_them_all_fg')) {
			getUpdateAnalyticsFlowHelper().fireAnalyticsStart(VOTES_TYPE, {
				analytics: createAnalyticsEvent({}),
				attributes: {
					fieldType: FIELD_TYPE_MAP[VOTES_TYPE],
				},
			});
		}
		setIsOpen((prevIsOpen) => !prevIsOpen);
	}, [createAnalyticsEvent]);

	const closeDropdown = useCallback(() => {
		setIsOpen(false);
	}, []);

	return (
		<Container>
			<Popup
				boundary="clippingParents"
				isOpen={isOpen}
				placement="auto-end"
				trigger={(triggerProps: TriggerProps) => {
					const { 'aria-expanded': ariaExpanded, ...restProps } = triggerProps;
					return (
						<div {...restProps}>
							<VoteButton
								onClick={toggleDropdown}
								tooltip={tooltip}
								ariaLabel={ariaLabel}
								isSelected={isOpen}
								{...(fg('relay-migration-issue-header-and-parent')
									? { useVoteField: useVoteFieldFromProps, ...rest }
									: {})}
							/>
						</div>
					);
				}}
				onClose={closeDropdown}
				content={() => (
					<ContentWrapper data-testid={VOTING_OPTIONS_MENU}>
						{isOpen && (
							<LazyDropdownContent
								setCloseDropdown={closeDropdown}
								onFieldUpdated={onFieldUpdated}
								disableVotingProps={disableVotingProps}
								{...(fg('relay-migration-issue-header-and-parent')
									? { useVoteField: useVoteFieldFromProps, ...rest }
									: {})}
							/>
						)}
					</ContentWrapper>
				)}
				zIndex={layers.modal}
			/>
		</Container>
	);
};

export default VoterDropdown;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	"[data-role='droplistContent']": {
		overflow: 'visible',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const ContentWrapper = styled.div({
	width: '240px',
	minHeight: '121px',
	padding: `${token('space.050', '4px')} 0 0`,
});
