import React, { useCallback } from 'react';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import type { Props } from './types.tsx';

const CloneIssue = ({ onCloneItemClick, onClick, label, itemKey }: Props) => {
	const handleClick = useCallback(() => {
		onCloneItemClick();
		onClick?.(itemKey);
	}, [onCloneItemClick, onClick, itemKey]);

	return (
		<DropdownItem key="clone" onClick={handleClick}>
			{label}
		</DropdownItem>
	);
};

export default CloneIssue;
