import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	manualRules: {
		id: 'issue-field-status.status-view.actions.actions-menu.automation-submenu.manual-rules',
		defaultMessage: 'From Automation',
		description:
			'Label for a section in a dropdown menu that holds "manual rules" a user can execute based on the issue currently in context',
	},
});
