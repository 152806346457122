import React, { forwardRef, type ReactNode } from 'react';
import { ClassNames } from '@compiled/react';

type Props = {
	width: number;
	height?: number;
	children: ReactNode;
	show: boolean;
};

const ClassyAnimator = forwardRef<HTMLElement, Props>((props, ref) => (
	// `position: absolute` is applied to make inner `fixed` element position be relative to its container, not to the window https://stackoverflow.com/a/11833892/1343917
	<ClassNames>
		{({ css, style }) => (
			<div
				// @ts-expect-error - TS2322: Property 'align' is missing in type 'HTMLElement' but required in type 'HTMLDivElement'.
				ref={ref}
				/* eslint-disable-next-line jira/react/no-style-attribute, @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766 */
				style={style}
				/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */
				className={css`
					position: absolute;
					right: 0;
					top: 0;

					width: ${props.show ? props.width : 0}px;
					height: ${typeof props.height === 'number' ? `${props.height}px` : 'auto'};
					transition: ${props.show
						? 'width 0.5s cubic-bezier(0.15, 1, 0.3, 1)'
						: 'width 0.5s cubic-bezier(0.15, 1, 0.3, 1), opacity 0, 0.5s, visibility 0, 0.5s'};
					overflow: hidden;
					opacity: ${props.show ? 1 : 0};
					visibility: ${props.show ? 'visible' : 'hidden'};
				`}
			>
				{props.children}
			</div>
		)}
	</ClassNames>
));

export default ClassyAnimator;
