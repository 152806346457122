import { useCallback, useEffect } from 'react';
import { useUserPreferencesValue } from '@atlassian/jira-issue-user-preference-services/src/main.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import {
	PINNED_FIELDS_BANNER_DISMISS_ACTION,
	PINNED_FIELDS_BANNER_PREFERENCE_KEY,
	PINNED_FIELDS_BANNER_USER_HIDDEN_ACTION,
	NUMBER_OF_DAYS_TO_SHOW,
} from '../common/constants.tsx';
import { isUnixTimestampOlderThanDays } from './utils.tsx';

export const useIsPinnedFieldsBannerVisible = () => {
	const [bannerLastInteracted, { setValue: setBannerLastInteracted }] = useUserPreferencesValue(
		PINNED_FIELDS_BANNER_PREFERENCE_KEY,
	);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onClose = useCallback((): void => {
		const analyticsEvent = createAnalyticsEvent({
			eventName: 'pinnedFieldsBanner closed',
			action: 'clicked',
			actionSubject: 'button',
			actionSubjectId: 'pinnedFieldsBanner',
		});
		fireUIAnalytics(analyticsEvent, PINNED_FIELDS_BANNER_DISMISS_ACTION);
		setBannerLastInteracted(PINNED_FIELDS_BANNER_USER_HIDDEN_ACTION);
	}, [createAnalyticsEvent, setBannerLastInteracted]);

	const onMount = useCallback((): void => {
		const analyticsEvent = createAnalyticsEvent({
			eventName: 'pinnedFieldsBanner viewed',
			action: 'viewed',
			actionSubject: 'banner',
			actionSubjectId: 'pinnedFieldsBannerViewed',
		});
		fireUIAnalytics(analyticsEvent, PINNED_FIELDS_BANNER_DISMISS_ACTION);
	}, [createAnalyticsEvent]);

	const isVisible =
		!isUnixTimestampOlderThanDays(bannerLastInteracted, NUMBER_OF_DAYS_TO_SHOW) &&
		!(bannerLastInteracted === PINNED_FIELDS_BANNER_USER_HIDDEN_ACTION);

	// Check onMount, if banner has been seen, and how long ago
	useEffect(() => {
		// If initial value is `null`, it's never been seen, we set the last time
		// interacted to current unix timestamp.
		if (bannerLastInteracted === null) {
			setBannerLastInteracted(Date.now().toString());
		}
		// Fire `viewed` Analytics Event
		isVisible && onMount();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return [isVisible, onClose] as const;
};
