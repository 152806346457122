import { useEffect, useState } from 'react';
import { getIssueFields, type TransformedIssueData } from '../../services/search-filter/index.tsx';
import { getProjectKeyFromIssueKey, isProjectAllowed } from './utils.tsx';

type UseIsEligibleProps = {
	issueId: string;
	issueKey: string;
};

type EligibilityFields = {
	isEligible: boolean;
	eligibilityFailureMessages: string[];
	isProjectWhitelisted: boolean;
	isLoading: boolean;
};

const supportedLanguages = ['java', 'kt', 'kts', 'js', 'jsx', 'cjs', 'mjs', 'ts', 'tsx'];

export const REASON_NO_ASSIGNEE =
	'<strong>No assignee.</strong> Without an assignee, the FF Cleanup Agent cannot assign anyone to a pull request.';
export const REASON_NO_CODE_REFERENCES =
	"<strong>Number of code references is 0.</strong> It looks like this flag has already been cleaned up. Please transition this ticket to 'Code references removed' and archive the flag in Launch Darkly if it is safe to do so.";
export const REASON_POSSIBLE_SERVED_VALUES =
	'<strong>Number of possible served values must be 1.</strong> As there are multiple values, the FF Cleanup Agent cannot know which value to use.';
export const getReasonUnsupportedLanguages = (languages: string[]) =>
	`<strong>Unsupported languages ${languages.join(', ')}.</strong> The FF Cleanup Agent currently only supports ${supportedLanguages.join(', ')}.`;

const getEligibilityResults = (data: TransformedIssueData) => {
	const failureMessages = [];
	if (!data.hasAssignee) failureMessages.push(REASON_NO_ASSIGNEE);
	if (data.numberOfCodeReferences === 0) failureMessages.push(REASON_NO_CODE_REFERENCES);
	if (data.numberOfPossibleServedValues !== 1) failureMessages.push(REASON_POSSIBLE_SERVED_VALUES);

	const unsupportedLangs = data.languages.filter((lang) => !supportedLanguages.includes(lang));
	if (unsupportedLangs.length) {
		failureMessages.push(getReasonUnsupportedLanguages(unsupportedLangs));
	}

	return {
		isEligible: failureMessages.length === 0,
		failureMessages,
	};
};

export const useIsEligible = ({ issueId, issueKey }: UseIsEligibleProps) => {
	const [eligibilityStatus, setEligibilityStatus] = useState<EligibilityFields>({
		isLoading: true,
		isEligible: false,
		isProjectWhitelisted: false,
		eligibilityFailureMessages: [],
	});

	const projectKey = getProjectKeyFromIssueKey(issueKey);

	useEffect(() => {
		if (!projectKey || !isProjectAllowed(projectKey)) {
			setEligibilityStatus({
				isEligible: false,
				eligibilityFailureMessages: [],
				isProjectWhitelisted: false,
				isLoading: false,
			});
		} else {
			getIssueFields(issueId)
				.then((res) => {
					const eligibilityResults = getEligibilityResults(res);
					setEligibilityStatus({
						isEligible: eligibilityResults.isEligible,
						eligibilityFailureMessages: eligibilityResults.failureMessages,
						isProjectWhitelisted: true,
						isLoading: false,
					});
				})
				.catch(() => {
					setEligibilityStatus({
						isEligible: false,
						eligibilityFailureMessages: [],
						isProjectWhitelisted: true,
						isLoading: false,
					});
				});
		}
	}, [projectKey, issueId]);

	return eligibilityStatus;
};
