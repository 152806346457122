export const VIEW_ACCESS_LEVEL_OPEN = 'OPEN';
export const VIEW_ACCESS_LEVEL_LIMITED = 'LIMITED';
export const VIEW_ACCESS_LEVEL_RESTRICTED = 'RESTRICTED';

type ViewAccessLevelMap = {
	OPEN: typeof VIEW_ACCESS_LEVEL_OPEN;
	LIMITED: typeof VIEW_ACCESS_LEVEL_LIMITED;
	RESTRICTED: typeof VIEW_ACCESS_LEVEL_RESTRICTED;
};

export const VIEW_ACCESS_LEVELS: ViewAccessLevelMap = {
	OPEN: VIEW_ACCESS_LEVEL_OPEN,
	LIMITED: VIEW_ACCESS_LEVEL_LIMITED,
	RESTRICTED: VIEW_ACCESS_LEVEL_RESTRICTED,
};

export const ACCESS_ROLE_EDITOR = 'EDITOR';
export const ACCESS_ROLE_VIEWER = 'VIEWER';

export const ACCESS_ROLE_EDITOR_ARI = 'ari:cloud:jira-product-discovery::role/view/editor';
export const ACCESS_ROLE_VIEWER_ARI = 'ari:cloud:jira-product-discovery::role/view/viewer';

export const ACCOUNT_USER_TYPE_CREATOR = 'CREATOR';
export const ACCOUNT_USER_TYPE_CONTRIBUTOR = 'CONTRIBUTOR';

export const PRINCIPAL_TYPE_PROFILE = 'user';
export const PRINCIPAL_TYPE_GROUP = 'group';

type PrincipalTypeMap = {
	PROFILE: typeof PRINCIPAL_TYPE_PROFILE;
	GROUP: typeof PRINCIPAL_TYPE_GROUP;
};

export const PRINCIPAL_TYPES: PrincipalTypeMap = {
	PROFILE: PRINCIPAL_TYPE_PROFILE,
	GROUP: PRINCIPAL_TYPE_GROUP,
};

export const ACCESS_ROLE_ERROR_MISSING_MANAGE_DISCOVERY_VIEWS_PERMISSION =
	'MISSING_MANAGE_DISCOVERY_VIEWS_PERMISSION';

export const ACCESS_ROLE_ERROR_MISSING_BROWSE_PROJECTS_PERMISSION =
	'MISSING_BROWSE_PROJECTS_PERMISSION';

type AccessRoleErrorMap = {
	MISSING_MANAGE_DISCOVERY_VIEWS_PERMISSION: typeof ACCESS_ROLE_ERROR_MISSING_MANAGE_DISCOVERY_VIEWS_PERMISSION;

	MISSING_BROWSE_PROJECTS_PERMISSION: typeof ACCESS_ROLE_ERROR_MISSING_BROWSE_PROJECTS_PERMISSION;
};

export const ACCESS_ROLE_ERRORS: AccessRoleErrorMap = {
	MISSING_MANAGE_DISCOVERY_VIEWS_PERMISSION:
		ACCESS_ROLE_ERROR_MISSING_MANAGE_DISCOVERY_VIEWS_PERMISSION,

	MISSING_BROWSE_PROJECTS_PERMISSION: ACCESS_ROLE_ERROR_MISSING_BROWSE_PROJECTS_PERMISSION,
};
