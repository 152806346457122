import {
  JiraIssueSearchViewAriResourceOwner,
  JiraIssueSearchViewAriResourceType
} from "./chunk-4BHYFYKX.mjs";
import {
  RegisteredAri
} from "./chunk-YG2E6ZVD.mjs";
import {
  AriParser
} from "./chunk-Q7FABYST.mjs";

// src/jira/issue-search-view/manifest.ts
var jiraIssueSearchViewAriStaticOpts = {
  qualifier: "ari",
  platformQualifier: "cloud",
  cloudId: new RegExp("^[a-zA-Z0-9_\\-.]{1,255}$"),
  // eslint-disable-line no-useless-escape
  resourceOwner: JiraIssueSearchViewAriResourceOwner,
  resourceType: JiraIssueSearchViewAriResourceType,
  resourceIdSlug: "activation/{activationId}/{namespaceId}/{viewId}",
  resourceIdSegmentFormats: {
    activationId: /[a-zA-Z0-9\-]+/,
    // eslint-disable-line no-useless-escape
    namespaceId: /[!a-zA-Z0-9\-_.~@:{}=]+(\/[!a-zA-Z0-9\-_.~@:{}=]+)*/,
    // eslint-disable-line no-useless-escape
    viewId: /[!a-zA-Z0-9\-_.~@:{}=]+(\/[!a-zA-Z0-9\-_.~@:{}=]+)*/
    // eslint-disable-line no-useless-escape
  }
};

// src/jira/issue-search-view/index.ts
var JiraIssueSearchViewAri = class _JiraIssueSearchViewAri extends RegisteredAri {
  constructor(opts) {
    super(opts);
    this._siteId = opts.cloudId || "", this._activationId = opts.resourceIdSegmentValues.activationId, this._namespaceId = opts.resourceIdSegmentValues.namespaceId, this._viewId = opts.resourceIdSegmentValues.viewId;
  }
  get siteId() {
    return this._siteId;
  }
  get activationId() {
    return this._activationId;
  }
  get namespaceId() {
    return this._namespaceId;
  }
  get viewId() {
    return this._viewId;
  }
  static create(opts) {
    let derivedOpts = {
      qualifier: jiraIssueSearchViewAriStaticOpts.qualifier,
      platformQualifier: jiraIssueSearchViewAriStaticOpts.platformQualifier,
      cloudId: opts.siteId,
      resourceOwner: jiraIssueSearchViewAriStaticOpts.resourceOwner,
      resourceType: jiraIssueSearchViewAriStaticOpts.resourceType,
      resourceId: `activation/${opts.activationId}/${opts.namespaceId}/${opts.viewId}`,
      resourceIdSegmentValues: {
        activationId: opts.activationId,
        namespaceId: opts.namespaceId,
        viewId: opts.viewId
      }
    }, ariOpts = AriParser.fromOpts(derivedOpts, jiraIssueSearchViewAriStaticOpts);
    return new _JiraIssueSearchViewAri(ariOpts);
  }
  static parse(maybeAri) {
    let opts = AriParser.fromString(maybeAri, jiraIssueSearchViewAriStaticOpts);
    return new _JiraIssueSearchViewAri(opts);
  }
  getVariables() {
    return {
      siteId: this.siteId,
      activationId: this.activationId,
      namespaceId: this.namespaceId,
      viewId: this.viewId
    };
  }
};

export {
  JiraIssueSearchViewAri
};
