// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	configureTooltipAdmin: {
		id: 'issue.details.configuration-button.configure-fields.tooltip-admin',
		defaultMessage: 'Configure fields',
		description:
			'Tooltip that appears when you hover over the button as Site Admin or Project Admin',
	},
	configureTooltipEndUser: {
		id: 'issue.details.configuration-button.configure-fields.tooltip-end-user',
		defaultMessage: 'Contact your admin to change the field order & layout',
		description: 'Tooltip that appears when you hover over the button as End User',
	},
	configureTooltipAdminNoRequestType: {
		id: 'issue.details.configuration-button.configure-fields.tooltip-admin-no-request-type',
		defaultMessage:
			'You can’t configure the layout of an issue while it doesn’t have a request type.',
		description:
			'Tooltip that appears when you hover over the button but the issue is in a service project and has no request type (as Site Admin or Project Admin)',
	},
	configureTooltipAdminNoRequestTypeIssueTermRefresh: {
		id: 'issue.details.configuration-button.configure-fields.tooltip-admin-no-request-type-issue-term-refresh',
		defaultMessage:
			'You can’t configure the layout of an issue while it doesn’t have a request type.',
		description:
			'Tooltip that appears when you hover over the button but the issue is in a service project and has no request type (as Site Admin or Project Admin)',
	},
});
