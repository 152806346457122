import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	copyIssueKeyLabel: {
		id: 'development-summary-commit.create-commit-dropdown.copy-issue-key.copy-issue-key-label',
		defaultMessage: 'Copy issue key',
		description:
			'Label for a read only field users can use to copy the current jira issue key to their clipboard.',
	},
	copyIssueKeyLabelIssueTermRefresh: {
		id: 'development-summary-commit.create-commit-dropdown.copy-issue-key.copy-issue-key-label-issue-term-refresh',
		defaultMessage: 'Copy issue key',
		description:
			'Label for a read only field users can use to copy the current jira issue key to their clipboard.',
	},
});
