/* eslint-disable import/dynamic-import-chunkname */

import { useEffect, useState } from 'react';

type MessageFile = {
	default: { [key: string]: string };
};

export function fetchLanguageFile(locale: string): Promise<MessageFile> {
	switch (locale) {
		case 'cs-CZ':
		case 'cs':
			return import(/* webpackChunkName: "async-google-recaptcha-i18n-cs" */ '../../i18n/cs');
		case 'da-DK':
		case 'da':
			return import(/* webpackChunkName: "async-google-recaptcha-i18n-da" */ '../../i18n/da');
		case 'de-DE':
		case 'de':
			return import(/* webpackChunkName: "async-google-recaptcha-i18n-de" */ '../../i18n/de');
		case 'en-GB':
			return import(/* webpackChunkName: "async-invite-people-i18n-en-GB" */ '../../i18n/en_GB');
		case 'en-US':
		case 'en':
			return import(/* webpackChunkName: "async-invite-people-i18n-en" */ '../../i18n/en');
		case 'en-ZZ':
			return import(/* webpackChunkName: "async-invite-people-i18n-en-ZZ" */ '../../i18n/en_ZZ');
		case 'es-ES':
		case 'es':
			return import(/* webpackChunkName: "async-google-recaptcha-i18n-es" */ '../../i18n/es');
		case 'fi-FI':
		case 'fi':
			return import(/* webpackChunkName: "async-google-recaptcha-i18n-fi" */ '../../i18n/fi');
		case 'fr-FR':
		case 'fr':
			return import(/* webpackChunkName: "async-google-recaptcha-i18n-fr" */ '../../i18n/fr');
		case 'hu-HU':
		case 'hu':
			return import(/* webpackChunkName: "async-google-recaptcha-i18n-hu" */ '../../i18n/hu');
		case 'it-IT':
		case 'it':
			return import(/* webpackChunkName: "async-google-recaptcha-i18n-it" */ '../../i18n/it');
		case 'ja-JP':
		case 'ja':
			return import(/* webpackChunkName: "async-google-recaptcha-i18n-ja" */ '../../i18n/ja');
		case 'ko-KR':
		case 'ko':
			return import(/* webpackChunkName: "async-google-recaptcha-i18n-ko" */ '../../i18n/ko');
		case 'nb-NB':
		case 'nb':
			return import(/* webpackChunkName: "async-google-recaptcha-i18n-nb" */ '../../i18n/nb');
		case 'nl-NL':
		case 'nl':
			return import(/* webpackChunkName: "async-google-recaptcha-i18n-nl" */ '../../i18n/nl');
		case 'pl-PL':
		case 'pl':
			return import(/* webpackChunkName: "async-google-recaptcha-i18n-pl" */ '../../i18n/pl');
		case 'pt-BR':
		case 'pt':
			return import(/* webpackChunkName: "async-google-recaptcha-i18n-pt-BR" */ '../../i18n/pt_BR');
		case 'ru-RU':
		case 'ru':
			return import(/* webpackChunkName: "async-google-recaptcha-i18n-ru" */ '../../i18n/ru');
		case 'sv-SE':
		case 'sv':
			return import(/* webpackChunkName: "async-google-recaptcha-i18n-sv" */ '../../i18n/sv');
		case 'tr-TR':
		case 'tr':
			return import(/* webpackChunkName: "async-google-recaptcha-i18n-tr" */ '../../i18n/tr');
		case 'th-TH':
		case 'th':
			return import(/* webpackChunkName: "async-google-recaptcha-i18n-th" */ '../../i18n/th');
		case 'uk-UK':
		case 'uk':
			return import(/* webpackChunkName: "async-google-recaptcha-i18n-uk" */ '../../i18n/uk');
		case 'vi-VI':
		case 'vi':
			return import(/* webpackChunkName: "async-google-recaptcha-i18n-vi" */ '../../i18n/vi');
		case 'zh-TW':
		case 'zh-HK':
			return import(/* webpackChunkName: "async-google-recaptcha-i18n-zh-TW" */ '../../i18n/zh_TW');
		case 'zh-ZH':
		case 'zh-CN':
		case 'zh':
			return import(/* webpackChunkName: "async-google-recaptcha-i18n-zh-ZH" */ '../../i18n/zh');
	}

	return Promise.resolve({ default: {} });
}

export function useFetchMessagesByLocale(locale: string) {
	const [messages, setMessages] = useState({});

	useEffect(() => {
		let isMounted = true;

		async function startToFetchMessagesByLocale() {
			// Intl.Locale doesn't like underscores
			const finalLocale = new Intl.Locale(locale.replace('_', '-')).toString();

			try {
				const module = await fetchLanguageFile(finalLocale);
				const newMessages = module.default || module;
				if (isMounted) {
					setMessages(newMessages);
				}
			} catch (e) {
				// fail to download message and do nothing because UI will use default language (English) to render
			}
		}

		void startToFetchMessagesByLocale();

		return () => {
			isMounted = false;
		};
	}, [locale]);

	return { messages };
}
