import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	changed: {
		id: 'issue-history.history-items.history-item.priority-history-item.changed',
		defaultMessage: 'changed the {priority}',
		description: `Describes that a user has changed the priority of the issue.
            Part of the heading in History feed, appears after the display name of the user who made the change.
            {priority} is the 'Priority' field label.`,
	},
	priority: {
		id: 'issue-history.history-items.history-item.priority-history-item.priority',
		defaultMessage: 'Priority',
		description: "(noun) Value to display as label for the 'Priority' field.",
	},
	priorityLowercase: {
		id: 'issue-history.history-items.history-item.priority-history-item.priority-lowercase',
		defaultMessage: 'priority',
		description: "(noun) Value to display as label for the 'priority' field.",
	},
});
