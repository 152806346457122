/**
 * @generated SignedSource<<15b814227445ee9125cb9e9b1ec9b1fe>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type addWatchers_issueViewWatchers_AddWatchersRelay$data = {
  readonly fieldId: string;
  readonly id: string;
  readonly selectedUsersConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly accountId: string;
        readonly id: string;
        readonly name: string;
        readonly picture: AGG$URL;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly type: string;
  readonly watch: {
    readonly $updatableFragmentSpreads: FragmentRefs<"useWatchesMutation_Mutation_Updatable">;
    readonly count: AGG$Long | null | undefined;
    readonly isWatching: boolean | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "addWatchers_issueViewWatchers_AddWatchersRelay";
};
export type addWatchers_issueViewWatchers_AddWatchersRelay$key = {
  readonly " $data"?: addWatchers_issueViewWatchers_AddWatchersRelay$data;
  readonly " $fragmentSpreads": FragmentRefs<"addWatchers_issueViewWatchers_AddWatchersRelay">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "addWatchers_issueViewWatchers_AddWatchersRelay",
  "selections": [
    (v0/*: any*/),
    {
      "concreteType": "JiraWatch",
      "kind": "LinkedField",
      "name": "watch",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "count"
        },
        {
          "kind": "ScalarField",
          "name": "isWatching"
        },
        {
          "kind": "FragmentSpread",
          "name": "useWatchesMutation_Mutation_Updatable"
        }
      ]
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "concreteType": "JiraUserConnection",
      "kind": "LinkedField",
      "name": "selectedUsersConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraUserEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "name": "accountId"
                },
                {
                  "kind": "ScalarField",
                  "name": "name"
                },
                {
                  "kind": "ScalarField",
                  "name": "picture"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraWatchesField"
};
})();

(node as any).hash = "81f5ee6bf1bd2e4e8f87523a916214bd";

export default node;
