// code-reaper-ignore-file -- Alternative implementation of random numbers for the browser.

const MAX_UINT32 = 0xffffffff + 1;

/**
 * Statistically random value in [0, 1).
 * Not crypto safe, as it falls back to Math.random
 */
export default function random() {
	try {
		const buf = new Uint32Array(1);

		// buf now has a random uint32 value

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.crypto.getRandomValues(buf);

		// Turn it back into a value in [0,1)
		return buf[0] / MAX_UINT32;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		return Math.random();
	}
}
