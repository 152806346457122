import React, { type ReactNode, useMemo, useCallback, useState } from 'react';
import { styled } from '@compiled/react';
import Button, { LoadingButton } from '@atlaskit/button';
import { Box, xcss, Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import {
	dismissFlag as createDismissFlag,
	type FlagComponentProps,
	InfoFlag,
	useFlagService,
} from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { Invitees } from '../../common/types.tsx';
import { FlagCompatibleAvatarGroup } from '../../common/ui/flag-compatible-avatar-group/index.tsx';
import type { Source } from '../../services/feature-flag/index.tsx';
import {
	type Product,
	clampString,
	generateId,
	getProductDisplayName,
} from '../../utils/index.tsx';
import { messages } from './messages.tsx';
import { RecaptchaTerms } from './recaptcha/index.tsx';

export const useInvitePromptFlag = () => {
	const { showFlag, dismissFlag } = useFlagService();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	return useCallback(
		({
			targetPageLabel,
			product,
			source,
			users,
			allowOptOut,
			onSubmit,
			onDismiss,
			onOptOut,
		}: {
			targetPageLabel?: string;
			product: Product;
			source: Source;
			users: Invitees;
			allowOptOut: boolean;
			onSubmit: () => Promise<unknown>;
			onDismiss: () => unknown;
			onOptOut: () => unknown;
		}) => {
			const id = `invite-${generateId()}`;

			const dismissTheFlag = () => dismissFlag(createDismissFlag(id));

			const attributes = {
				invitees: users.length,
				renderedPageTitle: !!targetPageLabel,
				allowOptOut,
				inviteeIds: users.map((user) => user.id),
				source,
				product,
			};

			const submit = async () => {
				fireUIAnalytics(
					createAnalyticsEvent({}),
					'button clicked',
					'addPeopleButtonInviteOnLinkInsert',
					attributes,
				);
				await onSubmit();
				dismissTheFlag();
			};

			const optOut = () => {
				dismissTheFlag();
				onOptOut();
				fireUIAnalytics(
					createAnalyticsEvent({}),
					'button clicked',
					'optOutButtonInviteOnLinkInsert',
					attributes,
				);
			};

			const dismiss = () => {
				dismissTheFlag();
				onDismiss();
				fireUIAnalytics(
					createAnalyticsEvent({}),
					'flag dismissed',
					'inviteOnLinkInsert',
					attributes,
				);
			};

			fireUIAnalytics(createAnalyticsEvent({}), 'flag shown', 'inviteOnLinkInsert', attributes);

			showFlag({
				id,
				isLowPriority: true,
				render: (props) => (
					<InvitePromptFlag
						{...props}
						id={id}
						users={users}
						targetPageLabel={targetPageLabel}
						onDismiss={dismiss}
						onSubmit={submit}
						onOptOut={allowOptOut ? optOut : undefined}
						product={product}
					/>
				),
			});
		},
		[createAnalyticsEvent, dismissFlag, showFlag],
	);
};

export type Props = {
	id: FlagComponentProps['id'];
	onDismiss: FlagComponentProps['onDismissed'];
	onOptOut?: () => unknown;
	users: Invitees;
	onSubmit: () => Promise<unknown>;
	targetPageLabel?: string;
	product: Product;
};

type InfoFlagProps = React.ComponentProps<typeof InfoFlag>;

export const InvitePromptFlag = ({
	users,
	onSubmit,
	onDismiss,
	onOptOut,
	targetPageLabel,
	product,
	...props
}: Props) => {
	const [isLoading, setIsLoading] = useState(false);

	const productDisplayName = getProductDisplayName(product);

	const handleOnSubmit = useCallback(async () => {
		setIsLoading(true);
		await onSubmit();
	}, [onSubmit]);

	const { formatMessage } = useIntl();
	const optOutEnabled = onOptOut !== undefined;
	const customFlagFooterProps = useMemo(
		(): Pick<InfoFlagProps, 'actions' | 'linkComponent'> => ({
			// Workaround to achieve primary button + avatars in the footer.
			//
			// How this works:
			// We pass a dummy `action` that gets displayed in the Flag's footer
			// by the custom `linkComponent`. The component displays our actual footer.
			// This way, we don't occupy the limited space inside `description`.
			//
			// Why not use `actions` normally?
			// Because our Avatar Group is not an action, and even our Submit button
			// should be bold & blue (appearance="primary") which is not supported
			// by the AK Flag.
			//
			// Why not put this in `description`?
			// Because that one has a max height equivalent to 5 lines of text,
			// and our footer is taller than 2, causing an unwanted scrollbar
			// to show.
			actions: [
				{
					content: '',
					// Replace with lodash/noop
					// eslint-disable-next-line @typescript-eslint/no-empty-function
					onClick: () => {},
				},
			],
			linkComponent: () => (
				<>
					<RecaptchaTerms />
					<ButtonsContainer>
						<ButtonItem optOutEnabled={optOutEnabled}>
							<LoadingButton
								appearance="primary"
								onClick={handleOnSubmit}
								spacing="compact"
								isLoading={isLoading}
							>
								{formatMessage(messages.addPeopleButton, {
									inviteeCount: users.length,
								})}
							</LoadingButton>
						</ButtonItem>
						{optOutEnabled && (
							<ButtonItem optOutEnabled={optOutEnabled}>
								<Button
									appearance="subtle-link"
									onClick={onOptOut}
									spacing="compact"
									isDisabled={isLoading}
								>
									{formatMessage(messages.dismiss, {})}
								</Button>
							</ButtonItem>
						)}
					</ButtonsContainer>
				</>
			),
		}),
		[onOptOut, optOutEnabled, handleOnSubmit, users, formatMessage, isLoading],
	);

	const maxLength = 20;
	const clampedTargetPageLabel = clampString(targetPageLabel || '', maxLength);

	return (
		<InfoFlag
			{...props}
			{...customFlagFooterProps}
			onDismissed={onDismiss}
			title={
				<>
					<Box xcss={avatarsContainerStyles}>
						<FlagCompatibleAvatarGroup
							data={users.map((user) => ({
								name: user.name,
								src: user.avatar,
								appearance: 'circle',
								size: 'medium',
							}))}
							boundariesElement="scrollParent"
						/>
					</Box>
					{formatMessage(messages.titleGeneric, {
						inviteeCount: users.length,
						displayName: users[0].name,
						productDisplayName,
					})}
				</>
			}
			description={
				<MainContentContainer>
					{targetPageLabel
						? formatMessage(messages.descriptionWithPageTitleGeneric, {
								strong: (...chunks: ReactNode[]) => <Text as="strong">{chunks.join('')}</Text>,
								inviteeCount: users.length,
								title: clampedTargetPageLabel,
								productDisplayName,
							})
						: formatMessage(messages.descriptionWithoutPageTitleGeneric, {
								inviteeCount: users.length,
								productDisplayName,
							})}
				</MainContentContainer>
			}
			messageType="marketing"
			messageId="invite-prompt-on-link-insert.ui.invite-prompt-flag.info-flag.invite-prompt-flag"
		/>
	);
};

const avatarsContainerStyles = xcss({
	marginLeft: 'space.negative.025', // Aligns avatars with the rest of the text
	marginTop: 'space.negative.025',
	marginBottom: 'space.050',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MainContentContainer = styled.p({
	display: '-webkit-box',
	'-webkit-line-clamp': '3',
	'-webkit-box-orient': 'vertical',
	overflow: 'hidden',
	margin: token('space.0', '0px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonsContainer = styled.div({
	display: 'flex',
	marginRight: token('space.negative.200', '-16px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonItem = styled.div<{ optOutEnabled?: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	maxWidth: `${({ optOutEnabled }: { optOutEnabled?: boolean }) =>
		optOutEnabled ? '160px' : '328px'}`,
});
