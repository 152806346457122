import { baseScopesRequiredPerField } from '@atlassian/jira-issue-adjustments/src/common.tsx';
import type { MultiCheckboxSetPublicValue } from '@atlassian/jira-ui-modifications-common/src/common/utils/common/fields/multi-checkbox.tsx';
import { commonIssueViewGranularScopes } from './fields-scopes.tsx';

export type InternalValue = {
	id: string;
	value: string;
};

export type PublicGetValue = {
	id: string;
	value: string;
};

export type MultiCheckboxPublicGetValue = PublicGetValue[];

export type MultiCheckboxInternalIdShapeValue = { id: string }[];

export type MultiCheckboxInternalFullShapeValue = InternalValue[];

export const multiCheckboxSetValueTransformer = (
	values: MultiCheckboxSetPublicValue,
): MultiCheckboxInternalIdShapeValue => values.map((id) => ({ id }));

export const multiCheckboxGetValueTransformer = (
	internalValue: MultiCheckboxInternalFullShapeValue,
): MultiCheckboxPublicGetValue => {
	if (!internalValue) return [];

	return internalValue.map(({ id, value }) => ({
		id,
		value,
	}));
};

export const multiCheckboxScopes = {
	...baseScopesRequiredPerField,
	granular: [...baseScopesRequiredPerField.granular, ...commonIssueViewGranularScopes],
};
