/**
 * @generated SignedSource<<55fabefafd9ec36c47f3816cda47d540>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type headerBreadcrumbs_issueViewFoundation_HeaderBreadcrumbsView$data = {
  readonly " $fragmentSpreads": FragmentRefs<"breadcrumbs_issueViewFoundation_IssueBreadcrumbsWithHooksNew">;
  readonly " $fragmentType": "headerBreadcrumbs_issueViewFoundation_HeaderBreadcrumbsView";
};
export type headerBreadcrumbs_issueViewFoundation_HeaderBreadcrumbsView$key = {
  readonly " $data"?: headerBreadcrumbs_issueViewFoundation_HeaderBreadcrumbsView$data;
  readonly " $fragmentSpreads": FragmentRefs<"headerBreadcrumbs_issueViewFoundation_HeaderBreadcrumbsView">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsparent"
    }
  ],
  "kind": "Fragment",
  "name": "headerBreadcrumbs_issueViewFoundation_HeaderBreadcrumbsView",
  "selections": [
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsparent",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "breadcrumbs_issueViewFoundation_IssueBreadcrumbsWithHooksNew"
        }
      ]
    }
  ],
  "type": "JiraIssue"
};

(node as any).hash = "165f409269cd2e8591a2a33419d2a703";

export default node;
