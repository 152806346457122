/* eslint-disable @atlassian/relay/must-colocate-fragment-spreads, @atlassian/relay/unused-fields */

import { fetchQuery, graphql } from 'react-relay';
import getRelayEnvironment from '@atlassian/jira-relay-environment/src/index.tsx';
import type { fetchDevOpsSummariesQuery } from '@atlassian/jira-relay/src/__generated__/fetchDevOpsSummariesQuery.graphql';
import type { CloudId, IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { createIssueAri } from '../../common/utils.tsx';

// Max number of items supported by the underlying API
const MAX_ISSUE_IDS = 100;

export const fetchDevOpsSummaries = async (cloudId: CloudId, issueIds: IssueId[]) => {
	const ids = issueIds.slice(0, MAX_ISSUE_IDS).map((issueId) => createIssueAri(cloudId, issueId));
	if (ids.length === 0) {
		// GraphQL will throw if we pass an empty list of issue ids
		return [];
	}

	const result = await fetchQuery<fetchDevOpsSummariesQuery>(
		getRelayEnvironment(),
		graphql`
			query fetchDevOpsSummariesQuery($ids: [ID!]!) {
				devOps {
					summarisedEntities(ids: $ids) {
						summarisedDeployments {
							count
							deploymentEnvironment {
								category
							}
							deploymentState
						}
						summarisedBuilds {
							count
							...buildData_softwareReleasesVersionDetailIssueList_devOpsSummarisedBuilds
						}
						...deploymentData_softwareReleasesVersionDetailIssueList
					}
				}
			}
		`,
		{ ids },
	).toPromise();

	const summarisedEntities = result?.devOps?.summarisedEntities;

	if (summarisedEntities == null) {
		throw new Error('Required data missing from GraphQL response');
	}
	if (summarisedEntities.length !== ids.length) {
		throw new Error('Unexpected number of summary items in GraphQL response');
	}

	return summarisedEntities;
};
