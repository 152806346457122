import React from 'react';
import type { CustomGlyphProps } from '@atlaskit/icon';

export const hiddenGlyph = (props: CustomGlyphProps) => (
	<svg
		{...props}
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect width="24" height="24" fill="white" fill-opacity="0.01" />
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M21.2071 3.61907C21.5976 4.00959 21.5976 4.64275 21.2071 5.03328L4.70711 21.5333C4.31658 21.9238 3.68342 21.9238 3.29289 21.5333C2.90237 21.1428 2.90237 20.5096 3.29289 20.1191L19.7929 3.61907C20.1834 3.22854 20.8166 3.22854 21.2071 3.61907Z"
			fill="currentColor"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16.1619 5.25008C14.8945 4.67646 13.4849 4.32617 12 4.32617H12.001C6.48 4.32617 2 9.16717 2 12.3262C2 13.7123 2.92324 15.4673 4.43188 16.9801L5.85543 15.5565C4.68982 14.3795 4.001 13.1022 4.001 12.3262C4.001 10.3252 7.46 6.32617 12.001 6.32617C12.9154 6.32617 13.7969 6.49966 14.6193 6.79262L16.1619 5.25008ZM16.5131 7.72727L17.9659 6.27452C18.1973 6.43493 18.4211 6.60231 18.6365 6.77548L17.2019 8.21008C16.9822 8.04196 16.7522 7.88033 16.5131 7.72727ZM12.9757 8.43624C12.6564 8.35395 12.3218 8.31017 11.977 8.31017C9.771 8.31017 7.977 10.1042 7.977 12.3102C7.977 12.6549 8.0208 12.9895 8.10313 13.3088L12.9757 8.43624ZM7.4026 16.8378L5.96313 18.2773C6.19088 18.4398 6.42608 18.5957 6.66807 18.7439L8.12972 17.2822C7.87835 17.1436 7.63571 16.9947 7.4026 16.8378ZM9.76817 15.6438C9.53617 15.4896 9.32112 15.3118 9.12645 15.1139L10.5402 13.7002C10.7364 13.903 10.9751 14.0645 11.2417 14.1702L9.76817 15.6438ZM10.1498 18.0906L8.56336 19.677C9.64041 20.0854 10.7994 20.3262 12 20.3262C17.423 20.3262 22 15.4122 22 12.3262C22 11.0666 21.2878 9.53974 20.0815 8.15889L18.6599 9.58049C19.5044 10.5623 19.999 11.5771 19.999 12.3262C19.999 14.0662 16.537 18.3262 12.001 18.3262H12C11.3597 18.3262 10.7407 18.2413 10.1498 18.0906ZM15.9767 12.2637C15.9769 12.2791 15.977 12.2946 15.977 12.3102C15.977 14.5162 14.184 16.3102 11.977 16.3102C11.9615 16.3102 11.946 16.3101 11.9305 16.3099L15.9767 12.2637ZM15.3108 10.1012C15.1566 9.86917 14.9788 9.65411 14.7809 9.45944L13.3672 10.8732C13.57 11.0694 13.7315 11.3081 13.8371 11.5748L15.3108 10.1012Z"
			fill="currentColor"
		/>
	</svg>
);
