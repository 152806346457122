import { createStore, createHook, type Action } from '@atlassian/react-sweet-state';

interface State {
	isBlanketShown: boolean;
	isPolling: boolean;
}

const initialState: State = {
	isBlanketShown: false,
	isPolling: false,
};

const actions = {
	showBlanket:
		(): Action<State> =>
		({ setState }) =>
			setState({ isBlanketShown: true }),
	hideBlanket:
		(): Action<State> =>
		({ setState }) =>
			setState({ isBlanketShown: false, isPolling: false }),
	startPolling:
		(): Action<State> =>
		({ setState }) =>
			setState({ isPolling: true }),
} as const;

type Actions = typeof actions;

const store = createStore<State, Actions>({
	name: 'loom-utils.loom-recorder-loading-blanket',
	initialState,
	actions,
});

export const useLoomRecorderLoadingBlanket = createHook(store);
