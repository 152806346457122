import { useState, useRef } from 'react';

type InputMethods = 'keyboard' | 'mouse';

export const useHoverState = (): [
	boolean,
	{
		onMouseOver: () => void;
		onFocus: () => void;
		onMouseOut: () => void;
		onBlur: () => void;
	},
] => {
	const [isHovered, setIsHovered] = useState(false);
	const inputMethod = useRef<InputMethods>('mouse');

	const setTrueWithInput = (method: InputMethods) => () => {
		if (!isHovered) {
			setIsHovered(true);
			inputMethod.current = method;
		}
	};

	const setFalseWithInput = (method: InputMethods) => () => {
		if (inputMethod.current === method) {
			setIsHovered(false);
		}
	};

	return [
		isHovered,
		{
			onMouseOver: setTrueWithInput('mouse'),
			onMouseOut: setFalseWithInput('mouse'),
			onFocus: setTrueWithInput('keyboard'),
			onBlur: setFalseWithInput('keyboard'),
		},
	];
};
