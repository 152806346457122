import type { DynamicFieldFormula } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/types.tsx';
import { cacheSelectorCreator } from '@atlassian/jira-polaris-lib-selector-creator-cache/src/index.tsx';
import type { DynamicFieldResolver, DynamicFieldSelectorCreator } from '../types.tsx';
import { issuePlayTotalSpentDynamicFieldResolver } from './agg/play-agg.tsx';
import { propertyAggregationSumResolver } from './agg/sum.tsx';
import { compositionResolver } from './composition/index.tsx';
import { constantResolver } from './const/index.tsx';
import {
	dataPointsCountDynamicFieldResolver,
	dataPointsCreatedDynamicFieldResolver,
} from './count/num-data-points.tsx';
import { linkedIssuesCountDynamicFieldResolver } from './count/num-linked-issues.tsx';
import { discreteResolver } from './discrete/index.tsx';
import { exprResolver } from './expr/index.tsx';
import { fieldResolver } from './field/index.tsx';
import { multiselectCountResolver } from './multi-select-count/index.tsx';
import { normalizationResolver } from './norm/index.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { dataPointsCreatedDynamicFieldResolver } from './count/num-data-points';

/**
 * Collect all resolvers in this array. The main field resolution code will attempt to map
 * all dynamic fields to all resolvers, and use the ones that can be applied automatically.
 * Adding new resolvers is thus a declarative operation in this package alone.
 */
const numberResolvers = [
	compositionResolver,
	constantResolver,
	dataPointsCountDynamicFieldResolver,
	discreteResolver,
	exprResolver,
	fieldResolver,
	issuePlayTotalSpentDynamicFieldResolver,
	linkedIssuesCountDynamicFieldResolver,
	multiselectCountResolver,
	normalizationResolver,
	propertyAggregationSumResolver,
];

const datesResolvers = [dataPointsCreatedDynamicFieldResolver];

const getSelectorForFormula = <T,>(
	resolvers: DynamicFieldResolver<T>[],
	formula: DynamicFieldFormula,
	playId?: string,
): DynamicFieldSelectorCreator<T> | undefined => {
	let selectorCreator;
	resolvers.find((resolver) => {
		const creator = resolver(formula, playId);
		if (creator !== undefined) {
			selectorCreator = creator;
			return true;
		}
		return false;
	});
	if (selectorCreator !== undefined) {
		return cacheSelectorCreator(selectorCreator);
	}
	return selectorCreator;
};

export const getNumberSelectorForFormula = cacheSelectorCreator(
	(
		formula: DynamicFieldFormula,
		playId?: string,
	): DynamicFieldSelectorCreator<number> | undefined =>
		getSelectorForFormula(numberResolvers, formula, playId),
	{ useJsonEquality: true },
);

export const getDateSelectorForFormula = cacheSelectorCreator(
	(
		formula: DynamicFieldFormula,
		playId?: string,
	): DynamicFieldSelectorCreator<string[]> | undefined =>
		getSelectorForFormula(datesResolvers, formula, playId),
	{ useJsonEquality: true },
);
