const MINUTES_PER_HOUR = 60;
const SECONDS_PER_MINUTE = 60;

// Supports both '.' and ',' as decimal separators
const UNIT_STRING_PATTERN = /^(\d+(?:[.,]\d+)?)([wdhm])$/;

type UnitQuantity = {
	quantity: number;
	unit: string;
};

const convertToUnitQuantity = (rawString: string): UnitQuantity | null => {
	const matches = UNIT_STRING_PATTERN.exec(rawString);
	if (!matches) {
		return null;
	}

	// Replace comma with a period for evaluating arithmetic decimal
	const normalizedQuantity = matches[1].replaceAll(',', '.');

	return {
		quantity: parseFloat(normalizedQuantity),
		unit: matches[2],
	};
};

const timeStringToSeconds = (daysPerWeek: number, hoursPerDay: number) => {
	const unitDefinitions = [
		{ unit: 'w', seconds: daysPerWeek * hoursPerDay * MINUTES_PER_HOUR * SECONDS_PER_MINUTE },
		{ unit: 'd', seconds: hoursPerDay * MINUTES_PER_HOUR * SECONDS_PER_MINUTE },
		{ unit: 'h', seconds: MINUTES_PER_HOUR * SECONDS_PER_MINUTE },
		{ unit: 'm', seconds: SECONDS_PER_MINUTE },
	];

	return (timeString: string): number | null => {
		// Split up the time string, and parse it into an array of UnitQuantities.
		const unitQuantities = timeString.split(' ').map(convertToUnitQuantity);

		let quantitiesConsumed = 0;
		let seconds = 0;

		for (let i = 0; i < unitDefinitions.length; i += 1) {
			// If we have consumed all the quantity-unit pairs in the string
			// we are done.
			if (quantitiesConsumed === unitQuantities.length) {
				break;
			}

			const unitDefinition = unitDefinitions[i];

			const unitQuantity = unitQuantities[quantitiesConsumed];
			if (!unitQuantity) {
				return null;
			}

			// If the quantity-unit pair matches the unit definition we are up to, consume it.
			if (unitQuantity.unit === unitDefinition.unit) {
				seconds += unitQuantity.quantity * unitDefinition.seconds;
				quantitiesConsumed += 1;
			}
		}

		// We have made it through all the unit definitions, but there is still some left-over input
		if (quantitiesConsumed < unitQuantities.length) {
			return null;
		}

		return Math.floor(seconds);
	};
};

export default timeStringToSeconds;
