import { useRef } from 'react';
import flatten from 'lodash/flatten';
import flow from 'lodash/flow';
import { withAnalyticsEvents } from '@atlaskit/analytics-next';
import {
	type FeatureFlagsPerProvider,
	type Provider,
	type RemoteLinksData,
	fromRemoteLinkProviderId,
	fromRemoteLinkType,
} from '@atlassian/jira-development-common/src/model/releases-data.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import {
	flagsPerProviderSelector,
	providersSelector,
	remoteLinksDataSelector,
} from '../../../controllers/releases-data/selectors/index.tsx';
import type { State } from '../../../controllers/types.tsx';
import OnMountedAnalytics from './main.tsx';

export default flow(
	withAnalyticsEvents(),
	connect(
		(state: State) => ({
			flagsPerProvider: flagsPerProviderSelector(state),
			providers: providersSelector(state),
			remoteLinksData: remoteLinksDataSelector(state),
		}),
		{},
	),
)(OnMountedAnalytics);

interface AnalyticsAttributes {
	[key: string]: number;
}

interface Args {
	actionSubject: string;
	actionSubjectId: string;
	flagsPerProvider: FeatureFlagsPerProvider;
	providers: Provider[] | undefined;
	remoteLinksData: RemoteLinksData | null | undefined;
}

export const calculateFlagsPerProviderStat = (
	flagsPerProvider: FeatureFlagsPerProvider,
): AnalyticsAttributes => {
	const flagsPerProviderCnt: AnalyticsAttributes = {};
	Object.keys(flagsPerProvider).forEach((key) => {
		flagsPerProviderCnt[key] = flagsPerProvider[key].length;
	});

	return flagsPerProviderCnt;
};

export const calculateDeploymentsPerProviderStat = (
	providers: Provider[] | undefined,
): AnalyticsAttributes => {
	const deploymentsPerProviderCnt: AnalyticsAttributes = {};

	(providers ?? []).forEach((provider) => {
		const deployments = flatten(Object.values(provider.environments));
		deploymentsPerProviderCnt[provider.id] = deployments.length;
	});

	return deploymentsPerProviderCnt;
};

export const calculateDeploymentsPerEnvironmentStat = (
	providers: Provider[] | undefined,
): AnalyticsAttributes => {
	const environmentsPerProviderCnt: AnalyticsAttributes = {};
	(providers ?? []).forEach((provider) => {
		const { environments } = provider;

		Object.keys(environments).forEach((environmentType) => {
			// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'Partial<Record<"STAGING" | "PRODUCTION" | "DEVELOPMENT" | "TESTING" | "UNMAPPED", DeploymentData[]>>'.
			const deployments = environments[environmentType];
			environmentsPerProviderCnt[environmentType] =
				(environmentsPerProviderCnt[environmentType] || 0) + deployments.length;
		});
	});

	return environmentsPerProviderCnt;
};

export const calculateRemoteLinksPerProviderStat = (
	remoteLinksData: RemoteLinksData | null | undefined,
): AnalyticsAttributes => {
	const remoteLinksPerProviderCnt: AnalyticsAttributes = {};

	(remoteLinksData?.remoteLinks ?? []).forEach((link) => {
		remoteLinksPerProviderCnt[fromRemoteLinkProviderId(link.providerId)] =
			(remoteLinksPerProviderCnt[fromRemoteLinkProviderId(link.providerId)] || 0) + 1;
	});

	return remoteLinksPerProviderCnt;
};

export const calculateRemoteLinksPerTypeStat = (
	remoteLinksData: RemoteLinksData | null | undefined,
): AnalyticsAttributes => {
	const remoteLinksPerTypeCnt: AnalyticsAttributes = {};

	(remoteLinksData?.remoteLinks ?? []).forEach((link) => {
		remoteLinksPerTypeCnt[fromRemoteLinkType(link.type)] =
			(remoteLinksPerTypeCnt[fromRemoteLinkType(link.type)] || 0) + 1;
	});

	return remoteLinksPerTypeCnt;
};

export const useOnMountedAnalytics = ({
	actionSubject,
	actionSubjectId,
	flagsPerProvider,
	providers,
	remoteLinksData,
}: Args) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const hasFired = useRef(false);

	return () => {
		if (hasFired.current === true) return;

		hasFired.current = true;
		const flagsPerProviderStat = calculateFlagsPerProviderStat(flagsPerProvider);
		const totalNumFlags: number = Object.keys(flagsPerProviderStat).reduce(
			(acc, key) => acc + flagsPerProviderStat[key],
			0,
		);
		const totalNumFeatureFlagProviders: number = Object.keys(flagsPerProviderStat).length;

		const deploymentsPerProviderStat = calculateDeploymentsPerProviderStat(providers);
		const totalNumDeployments: number = Object.keys(deploymentsPerProviderStat).reduce(
			(acc, key) => acc + deploymentsPerProviderStat[key],
			0,
		);

		const deploymentsPerEnvironmentStat = calculateDeploymentsPerEnvironmentStat(providers);

		const totalNumDeploymentProviders: number = Object.keys(deploymentsPerProviderStat).length;

		const totalNumRemoteLinks = remoteLinksData ? remoteLinksData.remoteLinks.length : 0;
		const totalNumRemoteLinkProviders = remoteLinksData ? remoteLinksData.providers.length : 0;
		const remoteLinksPerProviderStat = calculateRemoteLinksPerProviderStat(remoteLinksData);
		const remoteLinksPerTypeStat = calculateRemoteLinksPerTypeStat(remoteLinksData);

		const flagsPerProviderJson = JSON.stringify(flagsPerProviderStat);
		const deploymentsPerProviderJson = JSON.stringify(deploymentsPerProviderStat);
		const environmentsPerProviderJson = JSON.stringify(deploymentsPerEnvironmentStat);
		const remoteLinksPerProviderJson = JSON.stringify(remoteLinksPerProviderStat);
		const remoteLinksPerTypeJson = JSON.stringify(remoteLinksPerTypeStat);
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'rendered',
				actionSubject,
			}),
			actionSubjectId,
			{
				totalNumFlags,
				totalNumFeatureFlagProviders,
				flagsPerProvider: flagsPerProviderJson,
				totalNumDeployments,
				totalNumDeploymentProviders,
				totalNumRemoteLinks,
				totalNumRemoteLinkProviders,
				remoteLinksPerProviderStat: remoteLinksPerProviderJson,
				remoteLinksPerTypeStat: remoteLinksPerTypeJson,
				deploymentsPerProviderStat: deploymentsPerProviderJson,
				environmentsPerProviderStat: environmentsPerProviderJson,
			},
		);
	};
};
