import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	errorMessage: {
		id: 'smart-related-issues-panel.issue-line-card-group.no-issues-feedback.error-message',
		defaultMessage: "We couldn't find any similar requests.",
		description:
			'Error message shown when the related issues panel failed to fetch similar issues from the backend',
	},
	searchLink: {
		id: 'smart-related-issues-panel.issue-line-card-group.no-issues-feedback.search-link',
		defaultMessage: 'Search instead?',
		description: 'Link to attempt to navigate to the global issue navigator',
	},
});
