import React, { forwardRef, useEffect, useState, useRef, useLayoutEffect } from 'react';

import { IconButton } from '@atlaskit/button/new';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import { SpotlightCard } from '@atlaskit/onboarding';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { useIsCompactMode } from '@atlassian/jira-issue-view-compact-mode/src/index.tsx';
import messages from './messages.tsx';

export type SpotlightTargetCoordinates = {
	left: number;
	right: number;
	top: number;
	bottom: number;
};

export type ChangeboardingMessageProps = {
	targetCoordinates: SpotlightTargetCoordinates | null;
	onCloseSpotlight: () => void;
};

type SpotlightStyle = {
	top: string | number;
	left: string | number;
	marginTop?: string | number;
	marginLeft?: string | number;
	visibility?: 'hidden' | 'visible';
};

const ChangeboardingMessage = forwardRef<HTMLElement, ChangeboardingMessageProps>(
	({ targetCoordinates, onCloseSpotlight }, ref) => {
		const { formatMessage } = useIntl();
		const isCompact = useIsCompactMode();
		const [spotlightStyle, setSpotlightStyle] = useState<SpotlightStyle>();
		const closeButtonRef = useRef<HTMLButtonElement | null>(null);
		const [_, setIsCloseButtonFocused] = useState(false);

		useEffect(() => {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			if (ref && (ref as React.RefObject<HTMLElement>).current && targetCoordinates) {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				const element = (ref as React.RefObject<HTMLElement>).current;
				if (element && typeof window !== 'undefined') {
					const computedStyle = window.getComputedStyle(element);
					const elementWidth = element.offsetWidth;
					const marginLeft = parseFloat(computedStyle.marginLeft) || 0;
					const marginRight = parseFloat(computedStyle.marginRight) || 0;
					const totalWidth = elementWidth + marginLeft + marginRight;

					if (isCompact && targetCoordinates.left - totalWidth < 0) {
						setSpotlightStyle({
							top: `${targetCoordinates.bottom}px`,
							left: `${targetCoordinates.left}px`,
							marginTop: token('space.100'),
							marginLeft: 0,
							visibility: 'visible',
						});
					} else {
						setSpotlightStyle({
							top: `${targetCoordinates.top}px`,
							left: isCompact
								? `${targetCoordinates.left - totalWidth}px`
								: `${targetCoordinates.right}px`,
							visibility: 'visible',
						});
					}
				}
			}
		}, [ref, targetCoordinates, isCompact]);

		useLayoutEffect(() => {
			if (spotlightStyle?.visibility === 'visible') {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				(ref as React.RefObject<HTMLElement>)?.current?.focus();
			}
		}, [spotlightStyle?.visibility, ref]);

		const handleKeydown = (event: KeyboardEvent) => {
			if (event.key === 'Tab') {
				setIsCloseButtonFocused((prevIsFocused) => {
					event.preventDefault();
					event.stopImmediatePropagation();
					if (!prevIsFocused) {
						closeButtonRef.current?.focus();
					} else {
						onCloseSpotlight();
					}
					return !prevIsFocused;
				});
			}
			if (event.key === 'Escape') {
				onCloseSpotlight();
			}
		};

		useEffect(() => {
			if (typeof document !== 'undefined') {
				document.addEventListener('keydown', handleKeydown, { capture: true });
				return () => {
					document.removeEventListener('keydown', handleKeydown, { capture: true });
				};
			}
		});

		return (
			<Box
				xcss={spotlightContainerStyles}
				// eslint-disable-next-line jira/react/no-style-attribute,  @atlaskit/ui-styling-standard/enforce-style-prop
				style={spotlightStyle}
				ref={ref}
				tabIndex={0}
				data-testId="issue-view-foundation.quick-add.quick-actions-changeboarding.changeboarding-message"
			>
				<SpotlightCard
					headingAfterElement={
						<Box xcss={buttonWrapperStyles}>
							<IconButton
								icon={(iconProps) => (
									<CrossIcon
										primaryColor={token('color.icon.inverse')}
										{...iconProps}
										size="medium"
									/>
								)}
								label={formatMessage(messages.closeButtonLabel)}
								appearance="subtle"
								onClick={onCloseSpotlight}
								ref={closeButtonRef}
							/>
						</Box>
					}
					heading={formatMessage(messages.spotlightHeading)}
				>
					{formatMessage(messages.spotlightDescription)}
				</SpotlightCard>
			</Box>
		);
	},
);

export default ChangeboardingMessage;

const buttonWrapperStyles = xcss({
	alignSelf: 'flex-start',
});

const spotlightContainerStyles = xcss({
	position: 'fixed',
	zIndex: 'spotlight',
	marginRight: 'space.100',
	marginLeft: 'space.100',
	visibility: 'hidden',
	top: 'space.0',
	left: 'space.0',
});
