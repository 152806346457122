import React from 'react';

import { IntlProvider as IntlNextProvider, useIntl } from 'react-intl-next';

import { useFetchMessagesByLocale } from './useFetchMessagesByLocale';
interface IntlProviderType {
	children: React.ReactElement<any>;
}

const IntlProvider = ({ children }: IntlProviderType) => {
	const { locale } = useIntl();
	const { messages } = useFetchMessagesByLocale(locale);

	return (
		<IntlNextProvider messages={messages} key={locale} locale={locale}>
			{children}
		</IntlNextProvider>
	);
};

export default IntlProvider;
