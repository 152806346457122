// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	print: {
		id: 'issue.print',
		defaultMessage: 'Print',
		description: 'Label for the issue actions item to print the issue',
	},
});
