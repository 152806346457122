import type { AccountId, CloudId } from '@atlassian/jira-shared-types/src/general.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';

export const useCurrentUser = (): AccountId | undefined => {
	const { atlassianAccountId } = useTenantContext();

	return atlassianAccountId || undefined;
};

export const useCloudId = (): CloudId => {
	const { cloudId } = useTenantContext();
	return cloudId;
};
