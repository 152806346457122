/**
 * @generated SignedSource<<f72e9d880229cddaa2fc892abf0dd015>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraConfigStateConfigurationStatus = "CONFIGURED" | "NOT_CONFIGURED" | "NOT_INSTALLED" | "NOT_SET" | "PARTIALLY_CONFIGURED" | "PROVIDER_ACTION_CONFIGURED" | "PROVIDER_ACTION_NOT_CONFIGURED" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type panel_issueDesignSection_DesignPanel$data = {
  readonly devOps: {
    readonly providers: {
      readonly designProviders: ReadonlyArray<{
        readonly configState: {
          readonly config: {
            readonly nodes: ReadonlyArray<{
              readonly status: JiraConfigStateConfigurationStatus | null | undefined;
              readonly workspaceId: string;
            }> | null | undefined;
          } | null | undefined;
        } | null | undefined;
        readonly id: string;
      } | null | undefined> | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"heading_issueDesignSection_DesignsHeading">;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "panel_issueDesignSection_DesignPanel";
};
export type panel_issueDesignSection_DesignPanel$key = {
  readonly " $data"?: panel_issueDesignSection_DesignPanel$data;
  readonly " $fragmentSpreads": FragmentRefs<"panel_issueDesignSection_DesignPanel">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "cloudId"
    },
    {
      "kind": "RootArgument",
      "name": "siteAri"
    }
  ],
  "kind": "Fragment",
  "name": "panel_issueDesignSection_DesignPanel",
  "selections": [
    {
      "concreteType": "DevOps",
      "kind": "LinkedField",
      "name": "devOps",
      "plural": false,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "id",
              "variableName": "siteAri"
            },
            {
              "kind": "Literal",
              "name": "providerTypes",
              "value": [
                "DESIGN"
              ]
            }
          ],
          "concreteType": "DevOpsProviders",
          "kind": "LinkedField",
          "name": "providers",
          "plural": false,
          "selections": [
            {
              "kind": "FragmentSpread",
              "name": "heading_issueDesignSection_DesignsHeading"
            },
            {
              "concreteType": "DevOpsDesignProvider",
              "kind": "LinkedField",
              "name": "designProviders",
              "plural": true,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "id"
                },
                {
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "cloudId",
                      "variableName": "cloudId"
                    }
                  ],
                  "concreteType": "JiraAppConfigState",
                  "kind": "LinkedField",
                  "name": "configState",
                  "plural": false,
                  "selections": [
                    {
                      "concreteType": "JiraConfigStateConnection",
                      "kind": "LinkedField",
                      "name": "config",
                      "plural": false,
                      "selections": [
                        {
                          "concreteType": "JiraConfigState",
                          "kind": "LinkedField",
                          "name": "nodes",
                          "plural": true,
                          "selections": [
                            {
                              "kind": "ScalarField",
                              "name": "status"
                            },
                            {
                              "kind": "ScalarField",
                              "name": "workspaceId"
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "Query"
};

(node as any).hash = "e264870854fdd0653bed96cbe2a4d1f4";

export default node;
