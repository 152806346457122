import type { RecursiveOptionalNullable } from '@atlassian/jira-relay-utils/src/utils/types/types.tsx';
import type { AggJiraVote } from '../../common/types.tsx';

export const calculateVote = (
	didVote: boolean,
	value: RecursiveOptionalNullable<AggJiraVote>,
): AggJiraVote => {
	if (didVote) {
		return {
			...value,
			count: (value?.count || 0) + 1,
			hasVoted: true,
		};
	}
	return {
		...value,
		count: (value?.count || 0) - 1,
		hasVoted: false,
	};
};
