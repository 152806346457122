export type MediaUploadPermissionResponse = {
	clientId: string;
	endpointUrl: string;
	targetCollection: string;
	token: string;
	tokenDurationInMins: number;
};

export const getMediaUploadPermissionUrl = (baseUrl: string, issueKey: string) =>
	`/rest/api/2/attachment/upload/issue/${issueKey}/credentials`;
