import { useState } from 'react';
import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import { HAS_SEEN_RECURRING_WORK_PULSE_KEY } from '../../common/constants.tsx';

const store = createLocalStorageProvider('jira-recurring-issue-action');

export const useHasSeenRecurringWorkPulse = () => {
	const [hasSeenRecurringWorkPulse, setHasSeenRecurringWorkPulse] = useState(
		store.get(HAS_SEEN_RECURRING_WORK_PULSE_KEY) || false,
	);

	const setHasSeenPulse = (value: boolean) => {
		setHasSeenRecurringWorkPulse(value);
		store.set(HAS_SEEN_RECURRING_WORK_PULSE_KEY, value);
	};

	return [hasSeenRecurringWorkPulse, setHasSeenPulse];
};
