import React from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type { Props, DeveloperEscalationsButton } from './ui/index.tsx';

const DeveloperEscalationsButtonAsync = lazyForPaint<typeof DeveloperEscalationsButton>(() =>
	import(/* webpackChunkName: "async-developer-escalations-button" */ './ui').then(
		(exportedModule) => exportedModule.DeveloperEscalationsButton,
	),
);

export const AsyncDeveloperEscalationsButton = (props: Props) => (
	<JSErrorBoundary
		id="DeveloperEscalationsButton"
		packageName="jiraServicedeskCustomerServiceEscalationsIssueViewButton"
		teamName="boysenberry"
		sendToPrivacyUnsafeSplunk
		fallback="unmount"
	>
		<Placeholder name="developer-escalations-button" fallback={null}>
			<DeveloperEscalationsButtonAsync {...props} />
		</Placeholder>
	</JSErrorBoundary>
);
