import type { ChildIssue } from '@atlassian/jira-issue-shared-types/src/common/types/children-issues-type.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import ChildIssuesPanel from '@atlassian/jira-issue-view-common-views/src/child-issues-panel/index.tsx';
import {
	type ChangeEvent,
	ChangeEventTypes,
} from '@atlassian/jira-issue-view-common-views/src/child-issues-panel/model/on-change-callback.tsx';
import { CLASSIC_PORTFOLIO_CHILDREN } from '@atlassian/jira-issue-view-common-views/src/child-issues-panel/model/types.tsx';
import { PORTFOLIO_CHILD_ISSUES } from '@atlassian/jira-issue-view-configurations/src/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { updateAssociatedIssueRequest } from '@atlassian/jira-issue-view-store/src/actions/associated-issue-actions.tsx';
import { childIssueAddSuccess } from '@atlassian/jira-issue-view-store/src/actions/child-panel-actions.tsx';
import { navigateToNewIssue } from '@atlassian/jira-issue-view-store/src/actions/issue-navigation-actions.tsx';
import {
	localeSelector,
	baseUrlSelector,
	issueKeySelector,
	issueIdSelector,
	accountIdloggedInUserSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import {
	childIssuesLimitUrlSelector,
	issueTypeIdSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector.tsx';
import {
	isCompletedLoadingSelector,
	projectIdSelector,
	projectTypeSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';

import { hierarchyLevelSelector } from '@atlassian/jira-issue-view-store/src/issue-field/state/selectors/hierarchy-level-selector.tsx';
import { totalChildIssueCountSelector } from '@atlassian/jira-issue-view-store/src/selectors/child-issues-limit-selector.tsx';
import { persistedChildrenIssuesSelector } from '@atlassian/jira-issue-view-store/src/selectors/children-issues-selector.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import messages from './messages.tsx';

export default flowWithSafeComponent(
	connect(
		(state: State) => ({
			childIssuesPanelType: CLASSIC_PORTFOLIO_CHILDREN,
			baseUrl: baseUrlSelector(state),
			locale: localeSelector(state),
			user: accountIdloggedInUserSelector(state),
			// FIXME: JIV-17455 this field can be null
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			projectId: projectIdSelector(state)!,
			projectType: projectTypeSelector(state),
			parentKey: issueKeySelector(state),
			parentIssueTypeId: issueTypeIdSelector(state),
			// FIXME: JIV-17455 this field can be null
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			parentId: issueIdSelector(state)!,
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			issues: persistedChildrenIssuesSelector(state) as ChildIssue[],
			isIssueViewComplete: isCompletedLoadingSelector(state),
			// FIXME: JIV-17455 provide correct defaults
			issueHierarchyLevel: hierarchyLevelSelector(state) || 0,
			childIssuesLimitUrl: childIssuesLimitUrlSelector(state)(PORTFOLIO_CHILD_ISSUES),
			messages,
			supportsIssueCreation: false,
			hasExceededIssuesLimitAfterLoad: undefined, // TODO: Clean up or change
			totalChildIssueCount: totalChildIssueCountSelector(state),
		}),
		(dispatch) => ({
			// @ts-expect-error - TS7031 - Binding element 'fromIssueKey' implicitly has an 'any' type. | TS7031 - Binding element 'toIssueKey' implicitly has an 'any' type.
			onNavigateToNewIssue: ({ fromIssueKey, toIssueKey }) => {
				if (!__SPA__) {
					dispatch(navigateToNewIssue({ fromIssueKey, toIssueKey }));
				}
			},
			onChange: (event: ChangeEvent) => {
				switch (event.type) {
					case ChangeEventTypes.CHILD_ISSUE_ADDED:
						dispatch(childIssueAddSuccess(event.meta.childIssue));
						break;
					default:
						break;
				}
			},

			onEditAssociatedIssue: (issueKey?: IssueKey) => {
				dispatch(updateAssociatedIssueRequest(issueKey));
			},
		}),
	),
)(ChildIssuesPanel);
