import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/switchMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { trackOrLogClientError } from '@atlassian/jira-issue-view-common-utils/src/errors/index.tsx';
import { APPLINK_AUTHENTICATION_SUCCESS } from '@atlassian/jira-issue-view-store/src/actions/applink-authentication-actions.tsx';
import {
	fetchConfluenceAppLinksFailed,
	fetchConfluenceAppLinksSuccess,
	FETCH_CONFLUENCE_APP_LINKS_REQUEST,
} from '@atlassian/jira-issue-view-store/src/actions/fetch-confluence-app-links-actions.tsx';
import { baseUrlSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import fetchConfluenceAppLinks from './fetch-confluence-app-links-server.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<any>, store: MiddlewareAPI<State>) =>
	action$
		.ofType(FETCH_CONFLUENCE_APP_LINKS_REQUEST, APPLINK_AUTHENTICATION_SUCCESS)
		.switchMap(() => {
			const state = store.getState();
			const baseUrl = baseUrlSelector(state);

			// @ts-expect-error - TS2684 - The 'this' context of type 'Observable<unknown>' is not assignable to method's 'this' of type 'Observable<ConfluenceAppLinksResponse>'.
			return fetchConfluenceAppLinks(baseUrl)
				.map(fetchConfluenceAppLinksSuccess)
				.catch((error) => {
					trackOrLogClientError(
						'issue.fetch.confluence-app-links-epic',
						'FETCH_CONFLUENCE_APP_LINKS_REQUEST: Failed to fetch confluence app links data',
						error,
					);
					return Observable.of(fetchConfluenceAppLinksFailed());
				});
		});
