import head from 'lodash/head';
import type { DisplayMode } from '@atlassian/jira-polaris-domain-field/src/field/people/types.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { createGetFieldByKey } from '../../selectors/field.tsx';
import type { Props, State } from '../../types.tsx';

export const updateUserFieldDisplayMode =
	(fieldKey: FieldKey, displayMode: DisplayMode, localUpdate = false) =>
	async (
		{ getState, setState }: StoreActionApi<State>,
		{ onFieldUpdateFailed, fieldRemote, issueTypeIds, createAnalyticsEvent }: Props,
	): Promise<void> => {
		try {
			const field = createGetFieldByKey(fieldKey)(getState());
			if (!field) {
				return undefined;
			}

			const issueTypeId = head(issueTypeIds);
			if (issueTypeId === undefined) {
				return undefined;
			}

			const configuration = {
				...field.configuration,
				displayMode,
			};

			if (!localUpdate) {
				await fieldRemote.updateFieldConfiguration({
					oldConfiguration: field.configuration ?? {},
					configuration,
					fieldKey: field.key,
					issueTypeId,
					type: 'updateUserFieldDisplayMode',
				});
			}

			const fields = { ...getState().fields };
			fields[fieldKey] = {
				...fields[fieldKey],
				configuration,
			};
			setState({ fields });

			fireTrackAnalytics(createAnalyticsEvent({}), 'issueFields updated', {
				issueFieldKey: fieldKey,
				issueFieldType: field.type,
				updatedItems: [{ name: 'displayMode' }],
			});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (err: any) {
			onFieldUpdateFailed(err);
		}
	};
