import { defineMessages } from '@atlassian/jira-intl';

/**
 * Note: any key you add to this will add a similar requirement to add an attached emoji in `constants.tsx`,
 * as well as provide it as an available value in the 'quickReply' query parameter on any issue view route.
 *
 * This value will populate the comment box on any issue when the query param is
 */
export default defineMessages({
	clarify: {
		id: 'quick-reply.common.clarify',
		description:
			'Prefilled text in the comment box when clicking a link from the assigned issue notification, needs to be the same as `issue.assigned.prompted.response.canyouclarify` in Flight Deck translations for `jira-growth-collaboration-prompted-issue-assigned-notification`',
		defaultMessage: 'Can you clarify',
	},
	onIt: {
		id: 'quick-reply.common.on-it',
		description:
			'Prefilled text in the comment box when clicking a link from the assigned issue notification, needs to be the same as `issue.assigned.prompted.response.onit` in Flight Deck translations for `jira-growth-collaboration-prompted-issue-assigned-notification`',
		defaultMessage: "I'm on it",
	},
	takingALook: {
		id: 'quick-reply.common.taking-a-look',
		description:
			'Prefilled text in the comment box when clicking a link from the assigned issue notification, needs to be the same as `issue.assigned.prompted.response.takingalook` in Flight Deck translations for `jira-growth-collaboration-prompted-issue-assigned-notification`',
		defaultMessage: 'Taking a look',
	},
});
