// eslint-disable-next-line jira/restricted/react-component-props
import React, { type FC, type ComponentProps } from 'react';
import { Boundary } from '@atlassian/jira-issue-view-common-utils/src/boundary/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

export const LazyAssetsGlance = lazyForPaint(
	() => import(/* webpackChunkName: "async-assets-glance" */ './index'),
);

export const AssetsGlance: FC<ComponentProps<typeof LazyAssetsGlance>> = (props) => (
	<Boundary id="assets-glance">
		<LazyAssetsGlance {...props} />
	</Boundary>
);
