import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	buttonCta: {
		id: 'development-summary-development-field.empty-summary.button-cta',
		defaultMessage: 'Integrate source code',
		description: '',
	},
	notIntegrated: {
		id: 'development-summary-development-field.empty-summary.not-integrated',
		defaultMessage: 'Not Integrated',
		description: '',
	},
});
