// ADD_CONTENT_PANEL_REQUEST
export const ADD_CONTENT_PANEL_REQUEST = 'ADD_CONTENT_PANEL_REQUEST' as const;

export const addContentPanelRequest = (addonModuleKey: string) => ({
	type: ADD_CONTENT_PANEL_REQUEST,
	payload: addonModuleKey,
});

export type AddContentPanelRequest = ReturnType<typeof addContentPanelRequest>;

// ADD_CONTENT_PANEL_SUCCESS
export const ADD_CONTENT_PANEL_SUCCESS = 'ADD_CONTENT_PANEL_SUCCESS' as const;

export const addContentPanelSuccess = (addonModuleKey: string) => ({
	type: ADD_CONTENT_PANEL_SUCCESS,
	payload: addonModuleKey,
});

export type AddContentPanelSuccess = ReturnType<typeof addContentPanelSuccess>;

// ADD_CONTENT_PANEL_FAILURE
export const ADD_CONTENT_PANEL_FAILURE = 'ADD_CONTENT_PANEL_FAILURE' as const;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addContentPanelFailure = (message: any) => ({
	type: ADD_CONTENT_PANEL_FAILURE,
	payload: message,
});

export type AddContentPanelFailure = ReturnType<typeof addContentPanelFailure>;

// REMOVE_CONTENT_PANEL_REQUEST
export const REMOVE_CONTENT_PANEL_REQUEST = 'REMOVE_CONTENT_PANEL_REQUEST' as const;

export const removeContentPanelRequest = (addonModuleKey: string) => ({
	type: REMOVE_CONTENT_PANEL_REQUEST,
	payload: addonModuleKey,
});

export type RemoveContentPanelRequest = ReturnType<typeof removeContentPanelRequest>;

// REMOVE_CONTENT_PANEL_SUCCESS
export const REMOVE_CONTENT_PANEL_SUCCESS = 'REMOVE_CONTENT_PANEL_SUCCESS' as const;

export const removeContentPanelSuccess = (addonModuleKey: string) => ({
	type: REMOVE_CONTENT_PANEL_SUCCESS,
	payload: addonModuleKey,
});

export type RemoveContentPanelSuccess = ReturnType<typeof removeContentPanelSuccess>;

// REMOVE_CONTENT_PANEL_FAILURE
export const REMOVE_CONTENT_PANEL_FAILURE = 'REMOVE_CONTENT_PANEL_FAILURE' as const;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const removeContentPanelFailure = (message?: any) => ({
	type: REMOVE_CONTENT_PANEL_FAILURE,
	payload: message,
});

export type RemoveContentPanelFailure = ReturnType<typeof removeContentPanelFailure>;

// CONTENT_PANEL_SELECTED
export const CONTENT_PANEL_SELECTED = 'CONTENT_PANEL_SELECTED' as const;

export const contentPanelSelected = (addonModuleKey: string) => ({
	type: CONTENT_PANEL_SELECTED,
	payload: addonModuleKey,
});

export type ContentPanelSelected = ReturnType<typeof contentPanelSelected>;

// CONTENT_PANEL_UNSELECT
export const CONTENT_PANEL_UNSELECT = 'CONTENT_PANEL_UNSELECT' as const;

export const contentPanelUnselect = (addonModuleKey: string) => ({
	type: CONTENT_PANEL_UNSELECT,
	payload: addonModuleKey,
});

export type ContentPanelUnselect = ReturnType<typeof contentPanelUnselect>;

// CONTENT_PANELS_CUSTOMISED_REQUEST
export const CONTENT_PANELS_CUSTOMISED_REQUEST = 'CONTENT_PANELS_CUSTOMISED_REQUEST' as const;

export const contentPanelsCustomisedRequest = () => ({
	type: CONTENT_PANELS_CUSTOMISED_REQUEST,
});

export type ContentPanelsCustomisedRequest = ReturnType<typeof contentPanelsCustomisedRequest>;

// CONTENT_PANELS_CUSTOMISED_SUCCESS
export const CONTENT_PANELS_CUSTOMISED_SUCCESS = 'CONTENT_PANELS_CUSTOMISED_SUCCESS' as const;

export const contentPanelsCustomisedSuccess = () => ({
	type: CONTENT_PANELS_CUSTOMISED_SUCCESS,
});

export type ContentPanelsCustomisedSuccess = ReturnType<typeof contentPanelsCustomisedSuccess>;

export type EcosystemContentPanelAction =
	| AddContentPanelRequest
	| AddContentPanelSuccess
	| AddContentPanelFailure
	| RemoveContentPanelRequest
	| RemoveContentPanelSuccess
	| RemoveContentPanelFailure
	| ContentPanelSelected
	| ContentPanelUnselect
	| ContentPanelsCustomisedRequest
	| ContentPanelsCustomisedSuccess;
