import { statusCategories } from '@atlassian/jira-common-constants/src/index.tsx';
import { MAP_FROM_SERVER_COLOR } from '@atlassian/jira-issue-epic-color/src/common/constants.tsx';
import type { ServerColor } from '@atlassian/jira-issue-epic-color/src/common/types.tsx';
import type { ParentFieldOption } from '../../common/types.tsx';
import type {
	AggJiraParentConnection,
	AggJiraParentConnectionNode,
} from '../../ui/parent/types.tsx';

export const transformParentNode = (edgeNode: AggJiraParentConnectionNode): ParentFieldOption => {
	const fieldsEdges = edgeNode?.fieldsById?.edges || [];
	const summaryField = fieldsEdges.find((field) => field?.node?.fieldId === 'summary');
	const issueTypeField = fieldsEdges.find((field) => field?.node?.fieldId === 'issuetype');
	const statusField = fieldsEdges.find((field) => field?.node?.fieldId === 'status');
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const colorKey = edgeNode?.issueColorField?.color?.colorKey as ServerColor;

	const checkParentIssueIsDone = (statusCategoryId: string): boolean => {
		const doneStatusCategoryId =
			statusCategories.StatusCategoryIds[statusCategories.StatusCategoryTypes.DONE];
		return doneStatusCategoryId && String(doneStatusCategoryId) === statusCategoryId;
	};

	const isDone = checkParentIssueIsDone(
		statusField?.node?.status?.statusCategory?.statusCategoryId || '',
	);

	return {
		id: edgeNode?.id || '',
		key: edgeNode?.key || '',
		issueId: edgeNode?.issueId || undefined,
		webUrl: edgeNode?.webUrl || undefined,
		fields: {
			issuetype: {
				id: issueTypeField?.node?.issueType?.issueTypeId || '',
				name: issueTypeField?.node?.issueType?.name || '',
				iconUrl: issueTypeField?.node?.issueType?.avatar?.xsmall || '',
			},
			summary: summaryField?.node?.text || '',
		},
		color: colorKey in MAP_FROM_SERVER_COLOR ? MAP_FROM_SERVER_COLOR[colorKey] : undefined,
		value: edgeNode?.id || '',
		label: `${edgeNode?.key || ''} ${summaryField?.node?.text || ''}`,
		isDone,
		aggValue: edgeNode,
	};
};

export const transformToSuggestions = (
	fieldOptionsData: AggJiraParentConnection,
): ParentFieldOption[] =>
	(fieldOptionsData?.edges || [])
		?.filter((edge) => edge?.node)
		.map((edge) => transformParentNode(edge?.node));
