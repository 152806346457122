/**
 * @generated SignedSource<<6a4e36ad39c4bc8b71db6bb517b6f135>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutSingleSelectUserPickerField_IssueViewSingleSelectUserPickerField$data = {
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"issueViewReporterField_servicedeskCustomerServiceCustomFields_JCSReporterField" | "ui_issueViewLayoutAssigneeField_IssueViewAssigneeField" | "ui_issueViewLayoutReporterField_IssueViewReporterField" | "ui_issueViewLayoutUserField_IssueViewUserField">;
  readonly " $fragmentType": "ui_issueViewLayoutSingleSelectUserPickerField_IssueViewSingleSelectUserPickerField";
};
export type ui_issueViewLayoutSingleSelectUserPickerField_IssueViewSingleSelectUserPickerField$key = {
  readonly " $data"?: ui_issueViewLayoutSingleSelectUserPickerField_IssueViewSingleSelectUserPickerField$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutSingleSelectUserPickerField_IssueViewSingleSelectUserPickerField">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsassigneerelayprovider"
    },
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsreporterrelayprovider"
    },
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsuserrelayprovider"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "jcsIssueLayoutEapFlag"
    }
  ],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutSingleSelectUserPickerField_IssueViewSingleSelectUserPickerField",
  "selections": [
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsassigneerelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "ui_issueViewLayoutAssigneeField_IssueViewAssigneeField"
        }
      ]
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsreporterrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "ui_issueViewLayoutReporterField_IssueViewReporterField"
        }
      ]
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsuserrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "ui_issueViewLayoutUserField_IssueViewUserField"
        }
      ]
    },
    {
      "condition": "jcsIssueLayoutEapFlag",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "issueViewReporterField_servicedeskCustomerServiceCustomFields_JCSReporterField"
        }
      ]
    },
    {
      "kind": "ScalarField",
      "name": "type"
    }
  ],
  "type": "JiraSingleSelectUserPickerField"
};

(node as any).hash = "f602acc5f69d1f9fc966e3fbaf3fef83";

export default node;
