import versionsCache from '@atlassian/jira-cache/src/services/versions/index.tsx';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import type { Version } from '@atlassian/jira-issue-shared-types/src/common/types/issue-type.tsx';
import type { ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import messages from './messages.tsx';

type VersionItem = {
	content: string;
	value: string;
};

type StateValue = {
	id: string;
	name: string;
};

type CachedVersion = Version & {
	projectId: ProjectId;
};

type TransformedCachedVersion = Version & {
	projectId: ProjectId;
	fromCache?: boolean;
};

type Item = {
	content: string;
	value: string;
	fromCache?: boolean;
	href?: string;
};

export const transformToStateValue = (item: VersionItem | null): StateValue | null =>
	item && {
		name: item.content,
		id: item.value,
	};

export const transformFromStateValue = (stateValue: StateValue | null): VersionItem | null =>
	stateValue && {
		content: stateValue.name,
		value: stateValue.id,
	};

const toItem = (item: Version | TransformedCachedVersion): Item => ({
	content: item.name,
	value: item.id,
	// @ts-expect-error - TS2339 - Property 'fromCache' does not exist on type 'Version | TransformedCachedVersion'.
	fromCache: item.fromCache || false,
});

const validateCacheItems = (
	rest: Version[],
	cache: CachedVersion[],
	projectId: ProjectId,
): TransformedCachedVersion[] =>
	cache
		.filter((item) => item.projectId === projectId)
		.map((item) => {
			const duplicatedItem = rest.find((restItem) => restItem.id === item.id);
			if (duplicatedItem) {
				const newItem = { ...duplicatedItem, projectId };
				versionsCache.update(`${item.id}`, newItem);
				return { ...newItem, fromCache: true };
			}
			return { ...item, fromCache: true };
		});

const MAX_RESULTS = 5;

const transformCacheEntries = (cache: TransformedCachedVersion[]): TransformedCachedVersion[] =>
	cache.slice(-1 * MAX_RESULTS).reverse();

export const transformSuggestionsFromServerAndCache = (
	suggestionsFromServer: Version[],
	suggestionsFromCache: CachedVersion[],
	intl: IntlShape,
	projectId: ProjectId,
): {
	heading: string;
	items: Item[];
}[] => {
	const activeSuggestions = suggestionsFromServer.filter((suggestion) => !suggestion.archived);

	const unreleasedItems = activeSuggestions
		.filter((suggestion) => !suggestion.released)
		.map(toItem);

	const releasedItems = activeSuggestions.filter((suggestion) => suggestion.released).map(toItem);

	const suggestionsFromServerIds = new Set(suggestionsFromServer.map(({ id }) => id));

	const cacheItems = validateCacheItems(suggestionsFromServer, suggestionsFromCache, projectId)
		.filter((v) => !v.archived)
		// the following filter fixes a bug where archived versions are still showing despite the server response not including them.
		// this is because the the archived version may still exist inside the browser cache with an 'unreleased' status.
		// to fix this, remove any version in the browser cache that isn't part of the server response.
		// https://hello.atlassian.net/wiki/spaces/~494408470/pages/1657964060/Recent+Versions+Bug
		.filter((v) => suggestionsFromServerIds.has(v.id));

	const cacheLists = transformCacheEntries(cacheItems);

	return [
		{
			heading: intl.formatMessage(messages.recent),
			items: cacheLists.map(toItem),
		},
		{
			heading: intl.formatMessage(messages.unreleasedHeading),
			items: unreleasedItems,
		},
		{
			heading: intl.formatMessage(messages.releasedHeading),
			items: releasedItems,
		},
	];
};
