export const INLINE = 'INLINE';
export const HEADER = 'HEADER';

export const INLINE_READ_TESTID =
	'issue-field-summary.ui.multiline-inline-summary.ui.read.link-item';

export const INLINE_EDIT_TESTID =
	'issue-field-summary.ui.multiline-inline-summary.ui.multiline-edit--container';

export const SUMMARY_CONFIRM_EDIT_ARIA_LABEL_NAME = 'Confirm Summary';
export const SUMMARY_CANCEL_EDIT_ARIA_LABEL_NAME = 'Cancel Summary';
export const WARNING_ICON_TESTID =
	'issue-field-summary.ui.multiline-inline-summary.ui.edit.warning-icon';
export const ERROR_ICON_TESTID =
	'issue-field-summary.ui.multiline-inline-summary.ui.edit.error-icon';
export const EDITOR_ICON_TESTID =
	'issue-field-summary.ui.multiline-inline-summary.ui.read.editor-edit-icon';
export const EDITOR_ICON_BUTTON_TESTID =
	'issue-field-summary.ui.multiline-inline-summary.ui.read.edit-button';
export const FIELD_REQUIRED_MESSAGE = 'Summary is required';
export const CHARACTER_LIMIT_REACHED_MESSAGE = '255 character limit';
export const INLINE_EDIT_FIELD_TESTID =
	'issue-field-summary.ui.multiline-inline-summary.ui.multiline-edit';
