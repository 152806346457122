import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	feedBackTitle: {
		id: 'platform-utils-shared-editor-config.feed-back-title',
		defaultMessage: 'Help us make Atlassian Intelligence even better',
		description: 'Title shown in the feedback collector form',
	},
	summaryPlaceholder: {
		id: 'platform-utils-shared-editor-config.summary-placeholder',
		defaultMessage: 'Let us know what you were trying to do and how it could be better',
		description: 'Placeholder message for the summary form section',
	},
	userConsentCheckboxLabel: {
		id: 'platform-utils-shared-editor-config.user-consent-checkbox-label',
		defaultMessage: 'Include my last prompt and response in the feedback.',
		description:
			'Label for checkbox asking for user consent to include their AI prompt and response in feedback',
	},
	canContactText: {
		id: 'platform-utils-shared-editor-config.can-contact-text',
		defaultMessage: 'Atlassian can contact me about this feedback. See our <a>privacy policy</a>.',
		description:
			'Label message for being able to contact user. Includes a link to the Atlassian privacy page.',
	},
	improveAtlassianText: {
		id: 'platform-utils-shared-editor-config.improve-atlassian-text',
		defaultMessage:
			'I’d like to help improve Atlassian products by joining the <a>Atlassian Research Group</a>.',
		description:
			'Label message asking users if they want to join research group. Includes a link about the atlassian research group',
	},
});
