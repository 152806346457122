import type { Action } from '@atlassian/react-sweet-state';
import type { State } from '../../types.tsx';

export const setLoading =
	(loading: boolean): Action<State> =>
	({ getState, setState }) => {
		setState({
			meta: {
				...getState().meta,
				loading,
			},
		});
	};
