import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	betaLozenge: {
		id: 'atlassian-intelligence.common.ai-footer.beta-lozenge',
		defaultMessage: 'Beta',
		description: 'Text in beta lozenge in the footer.',
	},
	disclaimerText: {
		id: 'atlassian-intelligence.common.ai-footer.disclaimer-text',
		defaultMessage: 'Content quality may vary',
		description: 'Info Text in the footer.',
	},
	shortAttributionText: {
		id: 'atlassian-intelligence.common.ai-footer.short-attribution-text',
		defaultMessage: 'Powered by AI',
		description: 'Short attribution info text in the footer.',
	},
	longAttributionText: {
		id: 'atlassian-intelligence.common.ai-footer.long-attribution-text',
		defaultMessage: 'Powered by Atlassian Intelligence',
		description: 'Long attribution info text in the footer.',
	},
});
