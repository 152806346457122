// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	editDescription: {
		id: 'issue.placeholder-for-empty-issue-description-field',
		defaultMessage: 'Edit description',
		description: 'Command that will open the description editor from the command palette',
	},
});
