import curry from 'lodash/curry';
import type { Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import type { DynamicFieldFormula } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/types.tsx';
import { createGetIdeaPlayTotalBudget } from '../../../../selectors/properties/plays/index.tsx';
import type { DynamicFieldResolver } from '../../types.tsx';

const FORMULA_TEMPLATE = 'play_agg';

export const issuePlayTotalSpentDynamicFieldResolver: DynamicFieldResolver<number> = (
	formula: DynamicFieldFormula,
	playId?: Ari,
) => {
	if (formula === undefined || formula.template !== FORMULA_TEMPLATE) {
		return undefined;
	}

	const curriedCreateGetIdeaPlayTotalBudget = curry(createGetIdeaPlayTotalBudget);

	// @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
	return curriedCreateGetIdeaPlayTotalBudget(playId);
};
