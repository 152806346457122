/**
 * @generated SignedSource<<958e637ee10821885bf093340822be19>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutResolutionField_IssueViewResolutionField$data = {
  readonly __typename: "JiraResolutionField";
  readonly fieldConfig: {
    readonly isEditable: boolean | null | undefined;
  } | null | undefined;
  readonly fieldId: string;
  readonly id: string;
  readonly issue: {
    readonly id: string;
    readonly key: string;
  } | null | undefined;
  readonly resolution: {
    readonly id: string;
    readonly name: string;
  } | null | undefined;
  readonly resolutions: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string;
        readonly resolutionId: string;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly type: string;
  readonly " $fragmentType": "ui_issueViewLayoutResolutionField_IssueViewResolutionField";
};
export type ui_issueViewLayoutResolutionField_IssueViewResolutionField$key = {
  readonly " $data"?: ui_issueViewLayoutResolutionField_IssueViewResolutionField$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutResolutionField_IssueViewResolutionField">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "id"
},
v1 = {
  "kind": "ScalarField",
  "name": "name"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutResolutionField_IssueViewResolutionField",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__typename"
    },
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "concreteType": "JiraIssue",
      "kind": "LinkedField",
      "name": "issue",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "name": "key"
        }
      ]
    },
    {
      "concreteType": "JiraFieldConfig",
      "kind": "LinkedField",
      "name": "fieldConfig",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isEditable"
        }
      ]
    },
    {
      "concreteType": "JiraResolution",
      "kind": "LinkedField",
      "name": "resolution",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "RequiredField",
          "field": (v1/*: any*/),
          "action": "NONE",
          "path": "resolution.name"
        }
      ]
    },
    {
      "concreteType": "JiraResolutionConnection",
      "kind": "LinkedField",
      "name": "resolutions",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraResolutionEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraResolution",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "RequiredField",
                  "field": (v1/*: any*/),
                  "action": "NONE",
                  "path": "resolutions.edges.node.name"
                },
                {
                  "kind": "ScalarField",
                  "name": "resolutionId"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraResolutionField"
};
})();

(node as any).hash = "055684f8c8c283e917c3542703c698d8";

export default node;
