import React, { useMemo } from 'react';
import { withMetrics } from '@atlassian/jira-forge-ui-analytics/src/common/ui/index.tsx';
import {
	CONTEXT_TOKEN_TYPE_JIRA,
	ISSUE_GLANCE_MODULE,
} from '@atlassian/jira-forge-ui-constants/src/constants.tsx';
import {
	isCustomUIExtension,
	isNativeUiExtension,
} from '@atlassian/jira-forge-ui-utils-internal/src/utils/extension/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import { LazyIssueViewForgeExtension } from '@atlassian/jira-forge-issue-view-base/src/ui/async/index.tsx';
import { Skeleton } from '../../common/ui/skeleton/main.tsx';
import type { LazyComponentProps } from '../../types.tsx';

const skeletonConfig = [{ width: 100 }, { width: 100 }, { width: 75 }];

export const LazyIssueGlanceRenderer = withMetrics(
	// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
	lazyForPaint(
		() =>
			import(
				/* webpackChunkName: "async-forge-ui-issue-glance" */ '@atlassian/jira-forge-ui/src/ui/components/issue-glance'
			),
		{
			ssr: false,
		},
	),
);

export const LazyIssueGlance = (props: LazyComponentProps<typeof LazyIssueGlanceRenderer>) => {
	const { extension, extensionData } = props;
	const loadingComponent = useMemo(() => <Skeleton skeletonConfig={skeletonConfig} />, []);
	const extensionDataValue = useMemo(
		() => ({
			...extensionData,
			type: ISSUE_GLANCE_MODULE,
		}),
		[extensionData],
	);
	const extraProps = useMemo(
		() => ({
			loadingComponent,
			module: ISSUE_GLANCE_MODULE,
			extensionData: extensionDataValue,
			contextToken: CONTEXT_TOKEN_TYPE_JIRA,
		}),
		[extensionDataValue, loadingComponent],
	);

	if (isNativeUiExtension(extension) || isCustomUIExtension(extension)) {
		return (
			<Placeholder name="issue-glance" fallback={loadingComponent}>
				<LazyIssueViewForgeExtension {...props} {...extraProps} />
			</Placeholder>
		);
	}

	return (
		<Placeholder name="issue-glance" fallback={loadingComponent}>
			<LazyIssueGlanceRenderer {...props} {...extraProps} />
		</Placeholder>
	);
};

export const AsyncIssueGlance = LazyIssueGlance;
