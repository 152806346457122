// Represents the index of the start (inclusive) and end (exclusive) of the current part of the query
import { JastVisitor } from '../JastVisitor';

/**
 * Tuple representing the [start, stop] position of an element.
 */
export type Position = [number, number];

/**
 * Represents a node in the AST.
 */
export interface AstNode {
  /**
   * Delegates to the appropriate visit method of the current node for the provided visitor.
   * @param visitor Visitor with the visit method to invoke.
   */
  accept: <Result>(visitor: JastVisitor<Result>) => Result;
}

export interface BaseError {
  start: number;
  stop: number;
  message: string;
}
