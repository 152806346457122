import { createStore, createActionsHook } from '@atlassian/react-sweet-state';
import { UnarchiveIssueModalActions, type Actions } from './actions.tsx';
import { initialState } from './constants.tsx';
import type { State } from './types.tsx';

export const UnarchiveIssueModalStore = createStore<State, Actions>({
	initialState,
	actions: UnarchiveIssueModalActions,
	name: 'unarchiveIssueModalStore',
});

export const useUnarchiveIssueModalActions = createActionsHook<State, Actions>(
	UnarchiveIssueModalStore,
);
