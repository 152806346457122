import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type { View } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { createErrorAnalytics } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import { ViewNotFoundError } from '@atlassian/jira-polaris-remote-view/src/common/errors/view-not-found-error.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { createGetViewByAri } from '../../../selectors/view/index.tsx';
import type { Props, State } from '../../../types.tsx';
import { updateViewState } from '../../utils/state/index.tsx';

export const loadViewDescription =
	(viewId: string, refresh = false) =>
	async ({ getState, setState }: StoreActionApi<State>, { viewRemote }: Props) => {
		if (!refresh && createGetViewByAri(viewId)(getState())?.descriptionLoadingState?.loaded) {
			// don't load description if we already have them
			return;
		}

		if (createGetViewByAri(viewId)(getState())?.descriptionLoadingState?.isLoading) {
			// don't load description if it is currently already loading
			return;
		}

		const { viewSets: viewSetsAfterUpdate } = updateViewState(
			getState().viewSets,
			(view: View): boolean => view.viewId === viewId,
			(view: View) => ({
				...view,
				descriptionLoadingState: {
					isLoading: true,
					loaded: false,
				},
			}),
		);
		setState({
			viewSets: viewSetsAfterUpdate,
		});

		if (viewRemote.fetchViewDescription !== undefined) {
			try {
				const data = await viewRemote.fetchViewDescription(viewId);
				const { changedView, viewSets: viewSetsAfterCreation } = updateViewState(
					getState().viewSets,
					(view: View): boolean => view.viewId === viewId,
					(view: View) => ({
						...view,
						description: data.description,
						descriptionLoadingState: {
							isLoading: false,
							loaded: true,
						},
					}),
				);
				if (changedView) {
					setState({ viewSets: viewSetsAfterCreation });
				}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				fireErrorAnalytics(
					createErrorAnalytics(
						'polaris.error.controllers.views.actions.description.load-view-description',
						error,
					),
				);

				// remove loading state
				const { viewSets: viewSetsAfterErrorUpdate } = updateViewState(
					getState().viewSets,
					(view: View): boolean => view.viewId === viewId,
					(view: View) => ({
						...view,
						descriptionLoadingState: {
							isLoading: false,
							loaded: false,
						},
					}),
				);
				setState({
					viewSets: viewSetsAfterErrorUpdate,
				});

				if (!(error instanceof ViewNotFoundError)) {
					throw error;
				}
			}
		}
	};
