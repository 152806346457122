/**
 * @generated SignedSource<<fa45e3d640571abcad77c6151fcaa015>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutVotesField_IssueViewVotesField$data = {
  readonly __typename: "JiraVotesField";
  readonly fieldId: string;
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueFieldVoters_IssueVotersRelay">;
  readonly " $fragmentType": "ui_issueViewLayoutVotesField_IssueViewVotesField";
};
export type ui_issueViewLayoutVotesField_IssueViewVotesField$key = {
  readonly " $data"?: ui_issueViewLayoutVotesField_IssueViewVotesField$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutVotesField_IssueViewVotesField">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutVotesField_IssueViewVotesField",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "ScalarField",
      "name": "__typename"
    },
    {
      "kind": "FragmentSpread",
      "name": "main_issueFieldVoters_IssueVotersRelay"
    }
  ],
  "type": "JiraVotesField"
};

(node as any).hash = "bdacb6b126c60eb3c8e0727d36556181";

export default node;
