/* eslint-disable @atlassian/relay/unused-fields */
import React, { useCallback } from 'react';
import { useFragment, graphql, useMutation } from 'react-relay';
import {
	classicToBackendColor,
	classicToFrontendColor,
	getColorFromValue,
	getValueFromColor,
	toBackendColor,
	toFrontendColor,
} from '@atlassian/jira-issue-epic-color/src/common/utils.tsx';
import type { IssueViewRelayFragment } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/main.tsx';
import { getDisplayName } from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-field.tsx';
import {
	useConnectRelayField,
	type PropsCallback,
} from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-relay-field.tsx';
import { ConnectedRelayFieldWrapper } from '@atlassian/jira-issue-view-common-views/src/connect-field/relay-field/field-wrapper.tsx';
import { UnsafeColorPicker } from '@atlassian/jira-issue-view-foundation/src/color/view/view.tsx';
import { EPIC_COLOR_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import type {
	ui_issueViewLayoutColorField_IssueViewColorField$key,
	ui_issueViewLayoutColorField_IssueViewColorField$data,
} from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutColorField_IssueViewColorField.graphql';
import type { ui_issueViewLayoutColorField_IssueViewColorField_Mutation } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutColorField_IssueViewColorField_Mutation.graphql';

type ComponentValueShape = string;
type AggValueShape = {
	colorKey: string;
};

export function transformComponentValueToAggValue(
	colorStr: ComponentValueShape,
	isClassicColor: boolean,
): AggValueShape {
	const color = getColorFromValue(colorStr);
	const value = isClassicColor ? classicToBackendColor(color) : toBackendColor(color);

	return {
		colorKey: value,
	};
}

export function transformAggValueToComponentValue(
	aggValue: AggValueShape | null | undefined,
	isClassicColor: boolean,
): ComponentValueShape {
	const color = isClassicColor
		? classicToFrontendColor(aggValue?.colorKey)
		: toFrontendColor(aggValue?.colorKey);

	return getValueFromColor(color);
}

export type IssueViewColorFieldProps = {
	fragmentKey: ui_issueViewLayoutColorField_IssueViewColorField$key;
};

export function IssueViewColorField(props: IssueViewColorFieldProps) {
	const data = useFragment<ui_issueViewLayoutColorField_IssueViewColorField$key>(
		graphql`
			fragment ui_issueViewLayoutColorField_IssueViewColorField on JiraColorField {
				id
				fieldId
				type
				name
				description
				__typename

				fieldConfig {
					isEditable
				}

				color {
					colorKey @required(action: THROW)
				}
			}
		`,
		props.fragmentKey,
	);

	const [commit] = useMutation<ui_issueViewLayoutColorField_IssueViewColorField_Mutation>(graphql`
		mutation ui_issueViewLayoutColorField_IssueViewColorField_Mutation(
			$input: JiraUpdateColorFieldInput!
		) @raw_response_type {
			jira {
				updateColorField(input: $input) @optIn(to: "JiraIssueFieldMutations") {
					success
					errors {
						message
					}
					field {
						color {
							colorKey
						}
					}
				}
			}
		}
	`);

	const isClassicColor = data.type === EPIC_COLOR_TYPE;

	const getComponentProps = useCallback<
		PropsCallback<
			ui_issueViewLayoutColorField_IssueViewColorField$key,
			ui_issueViewLayoutColorField_IssueViewColorField$data,
			ComponentValueShape,
			AggValueShape
		>
	>(
		({ softRefreshCallbacks }) => {
			return {
				jiraIssueField: data,

				value: transformAggValueToComponentValue(data.color, isClassicColor),

				onValueConfirm(colorStr) {
					const newAggValue = transformComponentValueToAggValue(colorStr, isClassicColor);

					softRefreshCallbacks.onSubmit(newAggValue);

					commit({
						variables: {
							input: {
								id: data.id,
								operation: {
									operation: 'SET',
									color: newAggValue.colorKey,
								},
							},
						},
						onCompleted: (mutationData) => {
							if (mutationData.jira?.updateColorField?.success) {
								softRefreshCallbacks.onSubmitSucceeded(newAggValue);
							} else {
								softRefreshCallbacks.onSubmitFailed();
							}
						},
						onError() {
							softRefreshCallbacks.onSubmitFailed();
						},
						optimisticResponse: {
							jira: {
								updateColorField: {
									success: true,
									errors: [],
									field: {
										id: data.id,
										color: {
											// We need to provide an ID for the optimistic response, but we can't
											// tell what it should be in advance because the API doesn't list available
											// colors. This generated ID actually matched what the API would return,
											// but even if it didn't, the optimistic response gets thrown away when the
											// real response returns so there should be no impact.
											id: btoa(`JiraColor:${newAggValue.colorKey}`),
											colorKey: newAggValue.colorKey,
										},
									},
								},
							},
						},
					});
				},
			};
		},
		[commit, data, isClassicColor],
	);

	const connectField = useConnectRelayField(props, data, getComponentProps);

	// Don't render anything if we don't have a color
	if (!data.color) {
		return null;
	}

	return <UnsafeColorPicker {...connectField.componentProps} />;
}

type IssueViewColorProps = {
	issueViewRelayFragment?: IssueViewRelayFragment | null;
};

/**
 * Small wrapper to grab the correct field from the IssueViewRelayFragment
 */
export function IssueViewColor(props: IssueViewColorProps) {
	const fragment = props.issueViewRelayFragment?.issueColorField;

	if (!fragment) {
		return null;
	}

	const componentName = getDisplayName(UnsafeColorPicker);

	return (
		<ConnectedRelayFieldWrapper componentName={componentName}>
			<IssueViewColorField fragmentKey={fragment} />
		</ConnectedRelayFieldWrapper>
	);
}
