import { useCallback, useMemo } from 'react';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { getUpdateAnalyticsFlowHelper } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/index.tsx';
import { ASSIGNEE_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { useAssignToMeMutation } from './assign-to-me-mutation.tsx';
import { useAssignToMeMutationData } from './assign-to-me-mutation-data.tsx';

export const useAssignToMeOrUnassign = () => {
	const { getData } = useAssignToMeMutationData();
	const { onAssignToMeOrUnassign } = useAssignToMeMutation();

	const handleAssignToMeOrUnassign = useCallback(async () => {
		try {
			const data = await getData();
			if (fg('one_event_rules_them_all_fg')) {
				getUpdateAnalyticsFlowHelper().setActionTakenAttributes(
					ASSIGNEE_TYPE,
					data.field?.accountId,
					data.newUser?.accountId ?? null,
				);
			}
			return onAssignToMeOrUnassign(data);
		} catch (error) {
			fireErrorAnalytics({
				meta: {
					id: 'issue-view-keyboard-shortcuts.services.assign-to-me-or-unassign.assign-to-me-mutation.error',
					packageName: 'jiraIssueViewKeyboardShortcuts',
				},
				// Skipping sentry since there is no action for us to do.
				skipSentry: true,
			});
		}
	}, [getData, onAssignToMeOrUnassign]);

	return useMemo(() => ({ handleAssignToMeOrUnassign }), [handleAssignToMeOrUnassign]);
};
