import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	link: {
		id: 'issue-view-watchers.watchers.dropdown.add-watchers.link',
		defaultMessage: 'Add watchers',
		description:
			'Indicate to users that when the link is clicked, the add watchers user-picker will be displayed.',
	},
});
