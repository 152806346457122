import React, { type ReactNode, type Ref } from 'react';
import Button, { type ButtonProps } from '@atlaskit/button/new';
import {
	type ModalEntryPoint,
	ModalEntryPointPressableTrigger,
	type ModalEntryPointPressableTriggerProps,
} from '@atlassian/jira-modal-entry-point-pressable-trigger/src/ModalEntryPointPressableTrigger.tsx';

type ModalEntryPointButtonTriggerProps<TEntryPoint extends ModalEntryPoint> = Omit<
	ModalEntryPointPressableTriggerProps<TEntryPoint>,
	'children' | 'interactionName' | 'title' | 'width'
> & {
	interactionName: string;
	modalTitle?: ReactNode;
} & Omit<ButtonProps, 'interactionName'>;

export function ModalEntryPointButtonTrigger<TEntryPoint extends ModalEntryPoint>({
	children,
	entryPoint,
	entryPointParams,
	entryPointProps,
	errorAttributes,
	Fallback,
	modalTitle,
	modalProps,
	useInternalModal,
	...buttonProps
}: ModalEntryPointButtonTriggerProps<TEntryPoint>) {
	return (
		<ModalEntryPointPressableTrigger
			entryPoint={entryPoint}
			entryPointParams={entryPointParams}
			entryPointProps={entryPointProps}
			errorAttributes={errorAttributes}
			Fallback={Fallback}
			modalTitle={modalTitle}
			modalProps={modalProps}
			useInternalModal={useInternalModal}
		>
			{({ ref }) => (
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				<Button ref={ref as Ref<HTMLButtonElement>} {...buttonProps}>
					{children}
				</Button>
			)}
		</ModalEntryPointPressableTrigger>
	);
}
