/**
 * @generated SignedSource<<008f7411f1402a66c9bc054fe63d526e>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type DevOpsSecurityVulnerabilityStatus = "CLOSED" | "IGNORED" | "OPEN" | "UNKNOWN" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type vulnerabilityDropdownItem_developmentSummaryVulnerability$data = {
  readonly status: DevOpsSecurityVulnerabilityStatus | null | undefined;
  readonly title: string | null | undefined;
  readonly url: string | null | undefined;
  readonly " $fragmentType": "vulnerabilityDropdownItem_developmentSummaryVulnerability";
};
export type vulnerabilityDropdownItem_developmentSummaryVulnerability$key = {
  readonly " $data"?: vulnerabilityDropdownItem_developmentSummaryVulnerability$data;
  readonly " $fragmentSpreads": FragmentRefs<"vulnerabilityDropdownItem_developmentSummaryVulnerability">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "vulnerabilityDropdownItem_developmentSummaryVulnerability",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "title"
    },
    {
      "kind": "ScalarField",
      "name": "status"
    },
    {
      "kind": "ScalarField",
      "name": "url"
    }
  ],
  "type": "DevOpsSecurityVulnerabilityDetails"
};

(node as any).hash = "853928b60ff05b0150e67a49514ae30c";

export default node;
