/**
 * @generated SignedSource<<0a4a745f2c897a994b392b9051f82b43>>
 * @relayHash 93292c0398c4a3b19df229a123aaec7b
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID c94ead3145135ec9c9c90f91ec726f7254ee4a58a275b5cb0c7b9b96cc2b0654

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type JiraSingleValueFieldOperations = "SET" | "%future added value";
export type JiraUpdateRadioSelectFieldInput = {
  id: string;
  operation: JiraRadioSelectFieldOperationInput;
};
export type JiraRadioSelectFieldOperationInput = {
  id?: string | null | undefined;
  operation: JiraSingleValueFieldOperations;
};
export type ui_issueViewLayoutRadioSelectField_IssueViewRadioSelectField_Mutation$variables = {
  input: JiraUpdateRadioSelectFieldInput;
};
export type ui_issueViewLayoutRadioSelectField_IssueViewRadioSelectField_Mutation$data = {
  readonly jira: {
    readonly updateRadioSelectField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly id: string;
        readonly selectedOption: {
          readonly id: string;
          readonly value: string | null | undefined;
        } | null | undefined;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type ui_issueViewLayoutRadioSelectField_IssueViewRadioSelectField_Mutation$rawResponse = {
  readonly jira: {
    readonly updateRadioSelectField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly id: string;
        readonly selectedOption: {
          readonly id: string;
          readonly value: string | null | undefined;
        } | null | undefined;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type ui_issueViewLayoutRadioSelectField_IssueViewRadioSelectField_Mutation = {
  rawResponse: ui_issueViewLayoutRadioSelectField_IssueViewRadioSelectField_Mutation$rawResponse;
  response: ui_issueViewLayoutRadioSelectField_IssueViewRadioSelectField_Mutation$data;
  variables: ui_issueViewLayoutRadioSelectField_IssueViewRadioSelectField_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "kind": "ScalarField",
  "name": "id"
},
v2 = [
  {
    "concreteType": "JiraMutation",
    "kind": "LinkedField",
    "name": "jira",
    "plural": false,
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "JiraRadioSelectFieldPayload",
        "kind": "LinkedField",
        "name": "updateRadioSelectField",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "name": "success"
          },
          {
            "concreteType": "MutationError",
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "message"
              }
            ]
          },
          {
            "concreteType": "JiraRadioSelectField",
            "kind": "LinkedField",
            "name": "field",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "concreteType": "JiraOption",
                "kind": "LinkedField",
                "name": "selectedOption",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "value"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "ui_issueViewLayoutRadioSelectField_IssueViewRadioSelectField_Mutation",
    "selections": (v2/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ui_issueViewLayoutRadioSelectField_IssueViewRadioSelectField_Mutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": "c94ead3145135ec9c9c90f91ec726f7254ee4a58a275b5cb0c7b9b96cc2b0654",
    "metadata": {},
    "name": "ui_issueViewLayoutRadioSelectField_IssueViewRadioSelectField_Mutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "8ca886f8b4ea009915e15c1072bfb560";

export default node;
