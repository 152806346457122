import {
	createStore,
	createHook,
	createSubscriber,
	createContainer,
} from '@atlassian/react-sweet-state';
import { SHORTCUT_TRIGGER_ID_CHANGE_STATUS } from '../constant.tsx';
import type { ShortcutState, Panel, StatePartial } from '../types.tsx';

const initialState: ShortcutState = {
	shareDropdownTriggerCount: 0,
	issueScrollTriggerCount: 0,
	focusedPanel: 'LEFT',
	[SHORTCUT_TRIGGER_ID_CHANGE_STATUS]: 0,
};

const actions = {
	triggerComponent:
		(property: keyof Omit<ShortcutState, 'focusedPanel'>) =>
		({
			setState,
			getState,
		}: {
			setState: (state: StatePartial) => void;
			getState: () => ShortcutState;
		}) => {
			const current = getState()[property];
			setState({ [property]: current + 1 });
		},
	resetComponent:
		(property: string) =>
		({ setState }: { setState: (state: StatePartial) => void }) => {
			setState({ [property]: 0 });
		},
	triggerFocusedPanel:
		(panel: Panel) =>
		({
			setState,
			getState,
		}: {
			setState: (state: StatePartial) => void;
			getState: () => ShortcutState;
		}) => {
			const current = getState().issueScrollTriggerCount;
			setState({ focusedPanel: panel, issueScrollTriggerCount: current + 1 });
		},
} as const;

type Actions = typeof actions;

export const Store = createStore<ShortcutState, Actions>({
	name: 'keyboard-shortcuts',
	initialState,
	actions,
});

// Subscriber for class components
export const IssueKeyboardShortcutsSubscriber = createSubscriber<ShortcutState, Actions>(Store);

// Container to scope the store
export const IssueKeyboardShortcutsContainer = createContainer<
	ShortcutState,
	Actions,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Record<any, any>
>(Store);

// Selector to only trigger change for the specific key
export const useShareKeyboardShortcutsStore = createHook(Store, {
	selector: (state: ShortcutState): ShortcutState['shareDropdownTriggerCount'] =>
		state.shareDropdownTriggerCount,
});

export const useIssueScrollKeyboardShortcutsStore = createHook(Store, {
	selector: (state: ShortcutState): ShortcutState['issueScrollTriggerCount'] =>
		state.issueScrollTriggerCount,
});

// Selector for when is needed only the actions
export const useIssueKeyboardShortcutsActions = createHook(Store, {
	selector: null,
});

// Selectors to only trigger change for specific keys
export const useStatusKeyboardShortcutsStore = createHook(Store, {
	selector: (state: ShortcutState): ShortcutState['statusDropdownTriggerCount'] =>
		state[SHORTCUT_TRIGGER_ID_CHANGE_STATUS],
});

export const useFocusedPanelKeyboardShortcutsStore = createHook(Store, {
	selector: (state: ShortcutState): ShortcutState['focusedPanel'] => state.focusedPanel,
});

export const useKeyboardShortcutsStore = createHook(Store, {
	selector: (state: ShortcutState) => state,
});
