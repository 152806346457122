import { createSelector } from 'reselect';
import { fg } from '@atlassian/jira-feature-gating';
import {
	accountIdloggedInUserSelector,
	baseUrlSelector,
	cloudIdSelector,
	issueKeySelector,
	orgIdSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import { projectTypeSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';
import IssueUserTeamMentionProviderWithResolver from './issue-user-team-mention-provider-with-resolver.tsx';
import IssueUserTeamMentionProvider from './issue-user-team-mention-provider.tsx';

export const mentionProviderSelector = createSelector(
	baseUrlSelector,
	issueKeySelector,
	accountIdloggedInUserSelector,
	projectTypeSelector,
	orgIdSelector,
	cloudIdSelector,
	(baseUrl, issueKey, loggedInAccountId, projectType, orgId, cloudId) =>
		fg('gravityai-1855-mentions-bug-fix-issue-view')
			? new IssueUserTeamMentionProviderWithResolver(
					baseUrl,
					issueKey,
					loggedInAccountId,
					`${baseUrl}/jira/people/search#createTeam`,
					projectType,
					orgId,
					cloudId,
				)
			: new IssueUserTeamMentionProvider(
					baseUrl,
					issueKey,
					loggedInAccountId,
					`${baseUrl}/jira/people/search#createTeam`,
					projectType,
					orgId,
					cloudId,
				),
);
