import cloneDeep from 'lodash/cloneDeep';
import {
	SUMMARY_ITEM_BRANCH,
	SUMMARY_ITEM_BUILD,
	SUMMARY_ITEM_COMMIT,
	SUMMARY_ITEM_PULLREQUEST,
	OAUTH_ANALYTICS_TYPE,
} from '@atlassian/jira-development-summary-common/src/common/constants.tsx';
import type { CreatePullRequestTarget } from '@atlassian/jira-development-summary-common/src/common/types.tsx';
import type { Build, Summary } from '../../common/types.tsx';
import type { ResponseData } from './types.tsx';

// Do not use this function on entity types that have state/status
const shouldStoreEntityUrl = (
	// @ts-expect-error - TS7006 - Parameter 'summary' implicitly has an 'any' type.
	summary,
	summaryItemType: string | 'branch' | 'commit',
	// @ts-expect-error - TS2304 - Cannot find name 'BranchesType'. | TS2304 - Cannot find name 'CommitsType'.
	entities: Array<BranchesType> | Array<CommitsType>,
) =>
	summary[summaryItemType]?.url === null &&
	summary[summaryItemType]?.count === 1 &&
	entities.length === 1;

const shouldStorePullRequestUrl = (
	// @ts-expect-error - TS7006 - Parameter 'summary' implicitly has an 'any' type.
	summary,
	pullRequest: {
		readonly status: string;
		readonly url: string;
	},
) =>
	summary[SUMMARY_ITEM_PULLREQUEST]?.stateCount === 1 &&
	summary[SUMMARY_ITEM_PULLREQUEST]?.url === null &&
	summary[SUMMARY_ITEM_PULLREQUEST]?.state === pullRequest.status;

// Returns non-empty buildStates list if stateCount is exactly 1. There is a specific priority order in which build states
// are checked. That order from highest priority to lowest is -> failed, success, other
// Note: if any build count priority rules are changed here, make sure to also update
// src/packages/development/summary-legacy/src/dev-panel/summary/build/build-view.js
export const getTargetBuildStatesForOneClickUrl = (summaryBuilds?: Build) => {
	let targetBuildStatesForOneClickUrl: string[];
	if (summaryBuilds?.failedBuildCount === 1) {
		targetBuildStatesForOneClickUrl = ['FAILED'];
	} else if (summaryBuilds?.failedBuildCount === 0 && summaryBuilds?.successfulBuildCount === 1) {
		targetBuildStatesForOneClickUrl = ['SUCCESSFUL'];
	} else if (
		summaryBuilds?.failedBuildCount === 0 &&
		summaryBuilds?.successfulBuildCount === 0 &&
		summaryBuilds?.count === 1
	) {
		targetBuildStatesForOneClickUrl = ['IN_PROGRESS', 'PENDING', 'CANCELLED', 'UNKNOWN'];
	} else {
		// empty means that the build summary item will show a count greater than 1. So don't look for url matching target build state.
		targetBuildStatesForOneClickUrl = [];
	}
	return targetBuildStatesForOneClickUrl;
};

// Note: For pull requests (PRs), transformedSummary contains pull request stateCount/state for states in this priority
// from highest to lowest OPEN -> MERGED -> DECLINED. Find the first pull request matching state returned by summary
export const enrichSummary = (transformedSummary: Summary, swagResponse: ResponseData) => {
	const enrichedSummary = cloneDeep(transformedSummary);
	const targetBuildStatesForOneClickUrl = getTargetBuildStatesForOneClickUrl(
		enrichedSummary[SUMMARY_ITEM_BUILD],
	);
	const createPullRequestTargets: CreatePullRequestTarget[] = [];
	enrichedSummary.detailsHasInstanceErrors = false;
	swagResponse.data.developmentInformation.details.instanceTypes.forEach((instanceType) => {
		if (instanceType.devStatusErrorMessages.length > 0) {
			enrichedSummary.detailsHasInstanceErrors = true;
		}
		// Repository types
		instanceType.repository.forEach(({ name, avatarUrl, commits, branches, pullRequests }) => {
			// Find the first pull request matching state of summary pull request to be shown (see above note about PRs)
			const pullRequestToStore = pullRequests.find((pullRequest) =>
				shouldStorePullRequestUrl(enrichedSummary, pullRequest),
			);
			if (enrichedSummary[SUMMARY_ITEM_PULLREQUEST] && pullRequestToStore !== undefined) {
				enrichedSummary[SUMMARY_ITEM_PULLREQUEST].url = pullRequestToStore.url;
			}

			if (
				enrichedSummary[SUMMARY_ITEM_COMMIT] &&
				shouldStoreEntityUrl(enrichedSummary, SUMMARY_ITEM_COMMIT, commits)
			) {
				enrichedSummary[SUMMARY_ITEM_COMMIT].url = commits[0].url;
			}

			if (
				enrichedSummary[SUMMARY_ITEM_BRANCH] &&
				shouldStoreEntityUrl(enrichedSummary, SUMMARY_ITEM_BRANCH, branches)
			) {
				enrichedSummary[SUMMARY_ITEM_BRANCH].url = branches[0].url;
			}

			if (name != null && branches.length > 0) {
				createPullRequestTargets.push(
					...branches
						.filter((branch) => branch.createPullRequestUrl != null && branch.name != null)
						.map((branch) => ({
							repositoryName: name,
							repositoryAvatarUrl: avatarUrl,
							branchName: branch.name ?? '',
							createPullRequestUrl: branch.createPullRequestUrl ?? '',
							scmProvider:
								instanceType.type !== undefined &&
								instanceType.type.startsWith(OAUTH_ANALYTICS_TYPE)
									? OAUTH_ANALYTICS_TYPE
									: instanceType.type ?? '',
						})),
				);
			}
		});

		// Dangling Pull requests - if PR url hasn't already been set by above repository check
		// Find the first pull request matching state of summary pull request to be shown (see above note about PRs)
		if (
			instanceType.danglingPullRequests !== null &&
			instanceType.danglingPullRequests !== undefined
		) {
			const pullRequestToStore = instanceType.danglingPullRequests.find((pullRequest) =>
				shouldStorePullRequestUrl(enrichedSummary, pullRequest),
			);
			if (enrichedSummary[SUMMARY_ITEM_PULLREQUEST] && pullRequestToStore !== undefined) {
				enrichedSummary[SUMMARY_ITEM_PULLREQUEST].url = pullRequestToStore.url;
			}
		}

		// Builds
		if (targetBuildStatesForOneClickUrl.length !== 0) {
			instanceType.buildProviders.forEach((buildProvider) => {
				const buildToStore = buildProvider.builds.find(
					(build) =>
						enrichedSummary[SUMMARY_ITEM_BUILD]?.url === null &&
						targetBuildStatesForOneClickUrl.includes(build.state),
				);
				if (enrichedSummary[SUMMARY_ITEM_BUILD] && buildToStore !== undefined) {
					enrichedSummary[SUMMARY_ITEM_BUILD].url = buildToStore.url;
				}
			});
		}
	});

	createPullRequestTargets.sort(
		(a, b) =>
			a.repositoryName.localeCompare(b.repositoryName) || a.branchName.localeCompare(b.branchName),
	);

	if (enrichedSummary[SUMMARY_ITEM_PULLREQUEST]) {
		enrichedSummary[SUMMARY_ITEM_PULLREQUEST].createPullRequestTargets = createPullRequestTargets;
	}

	return enrichedSummary;
};
