import React, { type PropsWithChildren, useContext, useMemo, createContext } from 'react';
import { FieldInjectionsContext } from '@atlassian/jira-issue-field-injections/src/controllers/injections-context/index.tsx';
import type { FieldInjectionsContextValue } from '@atlassian/jira-issue-field-injections/src/controllers/injections-context/types.tsx';

export type FieldContainerContextValue<T> = {
	capturing: Pick<
		FieldInjectionsContextValue<T>['capturing'],
		| 'captureFieldId'
		| 'captureFieldType'
		| 'captureIsDisabled'
		| 'captureIsRequired'
		| 'captureLabel'
		| 'captureRenderer'
	>;
	overriding: Pick<
		FieldInjectionsContextValue<T>['overriding'],
		'overrideLabel' | 'overrideIsDisabled' | 'overrideIsRequired' | 'overrideElementAfterLabel'
	>;
};

export type FieldContextValue<T> = {
	capturing: Pick<
		FieldInjectionsContextValue<T>['capturing'],
		'captureAllowedValues' | 'captureDescription'
	>;
	overriding: Pick<
		FieldInjectionsContextValue<T>['overriding'],
		| 'overrideAllowedValues'
		| 'overrideFieldOptionsFilter'
		| 'overrideOnBlur'
		| 'overrideOnChange'
		| 'overrideDescription'
	>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FieldContainerContext = createContext<FieldContainerContextValue<any> | null>(null);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FieldContext = createContext<FieldContextValue<any> | null>(null);

/**
 * This component is used to decompose the value provided by FieldInjectionsContext into
 * separate contexts designed to inject values precisely into FieldContainer of the Field itself.
 * It provides a fine-grained control over field rendering.
 *
 * @param props
 * @returns
 */
export const InjectionsProvider = <T,>({ children }: PropsWithChildren<{}>) => {
	const {
		capturing: {
			captureAllowedValues,
			captureDescription,
			captureFieldId,
			captureFieldType,
			captureIsDisabled,
			captureIsRequired,
			captureLabel,
			captureRenderer,
		},
		overriding: {
			overrideLabel,
			overrideElementAfterLabel,
			overrideIsRequired,
			overrideIsDisabled,
			overrideAllowedValues,
			overrideFieldOptionsFilter,
			overrideOnChange,
			overrideOnBlur,
			overrideDescription,
		},
	} = useContext(FieldInjectionsContext);

	const fieldContainerContextValue: FieldContainerContextValue<T> = useMemo(
		() => ({
			capturing: {
				captureFieldId,
				captureFieldType,
				captureIsDisabled,
				captureIsRequired,
				captureLabel,
				captureRenderer,
			},
			overriding: {
				overrideLabel,
				overrideIsDisabled,
				overrideIsRequired,
				overrideElementAfterLabel,
			},
		}),
		[
			captureFieldId,
			captureFieldType,
			captureIsDisabled,
			captureIsRequired,
			captureLabel,
			overrideElementAfterLabel,
			overrideIsDisabled,
			overrideIsRequired,
			overrideLabel,
			captureRenderer,
		],
	);

	const fieldContextValue: FieldContextValue<T> = useMemo(
		() => ({
			capturing: {
				captureAllowedValues,
				captureDescription,
			},
			overriding: {
				overrideAllowedValues,
				overrideFieldOptionsFilter,
				overrideOnChange,
				overrideOnBlur,
				overrideDescription,
			},
		}),
		[
			captureAllowedValues,
			captureDescription,
			overrideAllowedValues,
			overrideFieldOptionsFilter,
			overrideOnBlur,
			overrideOnChange,
			overrideDescription,
		],
	);

	return (
		<FieldContainerContext.Provider value={fieldContainerContextValue}>
			<FieldContext.Provider value={fieldContextValue}>{children}</FieldContext.Provider>
		</FieldContainerContext.Provider>
	);
};

export const useFieldContainerContext = () => {
	const value = useContext(FieldContainerContext);

	if (value === null) {
		throw new Error('Missing FieldContainerContext.Provider');
	}

	return value;
};

export const useFieldContext = () => {
	const value = useContext(FieldContext);

	if (value === null) {
		throw new Error('Missing FieldContext.Provider');
	}

	return value;
};
