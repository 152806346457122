import { CONTENT_TYPE } from '../types/types.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	DEFAULT_JIRA_ISSUE_EMBEDDED_CONF_SOURCE,
	JIRA_ISSUE_LENGTHY_DESCRIPTION_PROMPT,
	JIRA_ISSUE_QUICK_ACTIONS,
	JIRA_ISSUE_LINKED_MENTIONED_PAGES,
	JIRA_ISSUE_LINK_PAGE,
	JIRA_SOFTWARE_BACKLOG_END_OF_SPRINT_RETRO,
	JIRA_ISSUE_AUTO_LINK_CONTENT_ON_LINK_PASTE,
} from './embedded-confluence-source';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	RETRO_CONFLUENCE_CROSS_FLOW_SOURCE,
	JIRA_ISSUE_CREATE_CONTENT_CONFLUENCE_CROSS_FLOW_SOURCE,
} from './confluence-cross-flow-source';

export const ACTION_SUBJECTS = {
	[CONTENT_TYPE.PAGE]: {
		CREATE_MODAL: 'createPageModal',
		CANCEL_CREATE_MODAL: 'cancelCreatePageModal',
		CREATE: 'createUnpublishedDraft',
	},
	[CONTENT_TYPE.WHITEBOARD]: {
		CREATE_MODAL: 'createWhiteboardModal',
		CANCEL_CREATE_MODAL: 'cancelCreateWhiteboardModal',
		CREATE: 'createWhiteboard',
	},
};

export const ACTIONS = {
	CLICKED: 'clicked',
	SUCCESS: 'success',
	CLOSE_COMPLETE: 'closeComplete',
	OPEN_COMPLETE: 'openComplete',
};
