import { createHook } from '@atlassian/react-sweet-state';
import { type Actions, store } from '../../main.tsx';
import type { IssueAdjustmentsState } from '../../types.tsx';

const selectTriggerFieldInitializationKey = (state: IssueAdjustmentsState) =>
	state.triggerFieldInitializationKey;

export const useTriggerFieldInitialization = createHook<IssueAdjustmentsState, Actions, number>(
	store,
	{
		selector: selectTriggerFieldInitializationKey,
	},
);
