import {
	type PriorityValue,
	PRIORITIES,
} from '@atlassian/jira-servicedesk-incident-management-common/src/constants.tsx';
import { ALERT_STATUS } from '../../common/constants.tsx';
import {
	type AcknowledgedBy,
	type AlertStatus,
	type LinkedAlert,
	toAlertId,
} from '../../common/types.tsx';
import type { EscalatorLinkedAlert, LinkedAlertIds } from '../types.tsx';

// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ readonly P1: "P1"; readonly P2: "P2"; readonly P3: "P3"; readonly P4: "P4"; readonly P5: "P5"; }'.
const toPriority = (value: string): PriorityValue => PRIORITIES[value] || PRIORITIES.P5;

const toAlertStatus = (status: string, acknowledgedBy: AcknowledgedBy | null): AlertStatus => {
	if (status === ALERT_STATUS.OPEN && acknowledgedBy != null) {
		return ALERT_STATUS.ACKED;
	}

	if (status === ALERT_STATUS.CLOSED) {
		return ALERT_STATUS.CLOSED;
	}

	return ALERT_STATUS.OPEN;
};

export const transformEscalatorAlertResponse = (
	alert: EscalatorLinkedAlert,
	linkedAlertIds: LinkedAlertIds = {},
): LinkedAlert => {
	const alertId = toAlertId(alert.id);
	return {
		id: alertId,
		tinyId: alert.tinyId,
		priority: toPriority(alert.priority),
		message: alert.message,
		owner: alert.owner,
		responders: alert.responders,
		acknowledgedBy: alert.acknowledgedBy,
		status: toAlertStatus(alert.status, alert.acknowledgedBy),
		count: alert.count,
		createdAt: alert.createdAt,
		url: alert.url,
		isLinked: !!linkedAlertIds[alertId],
		summary: 'summary', // populating this property so it can be use to render summary field in linked alerts modal
	};
};
