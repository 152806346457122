import { useCallback } from 'react';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import { fg } from '@atlassian/jira-feature-gating';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import {
	setCommentValue,
	saveCommentRequest,
} from '@atlassian/jira-issue-view-store/src/actions/comment-actions.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useDispatch, useStore } from '@atlassian/jira-react-redux/src/index.tsx';
import { useTasksUpdater } from '../use-tasks-updater/index.tsx';

export const useCommentTasksUpdater = (commentId: string) => {
	const store = useStore<State>();
	const dispatch = useDispatch();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const getAdfValue = useCallback(
		() => store.getState().entities.comments[commentId]?.bodyAdf,
		[commentId, store],
	);

	const changeAdfValue = useCallback(
		(updatedAdf: ADF) => {
			dispatch(setCommentValue(commentId, updatedAdf));
		},
		[commentId, dispatch],
	);

	const saveAdfValue = useCallback(() => {
		dispatch(
			saveCommentRequest(
				store.getState().entities.comments[commentId],
				createAnalyticsEvent({}),
				fg('comments_missing_attributes')
					? store.getState().ui.comments.commentSessionId
					: undefined,
			),
		);
	}, [commentId, createAnalyticsEvent, dispatch, store]);

	const toggleTask = useTasksUpdater({
		getAdfValue,
		onChange: changeAdfValue,
		onSave: saveAdfValue,
	});

	return toggleTask;
};
