/**
 * @generated SignedSource<<3f8498acdde998cfc10862c9a95dd314>>
 * @relayHash 1d37c69b7f3610c1320221b182a6da40
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 524011e015e79235b8450b442433c9df1dc212a8bb8c511c7cf1ba20bf5a83a4

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type assignToMeInlineEdit_rootQuery$variables = Record<PropertyKey, never>;
export type assignToMeInlineEdit_rootQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"assignToMeInlineEdit_issueViewLayoutAssigneeField_AssignToMeInlineEditInner_rootFragmentKey">;
};
export type assignToMeInlineEdit_rootQuery = {
  response: assignToMeInlineEdit_rootQuery$data;
  variables: assignToMeInlineEdit_rootQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "name": "assignToMeInlineEdit_rootQuery",
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "assignToMeInlineEdit_issueViewLayoutAssigneeField_AssignToMeInlineEditInner_rootFragmentKey"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "assignToMeInlineEdit_rootQuery",
    "selections": [
      {
        "concreteType": "AuthenticationContext",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "__typename"
              },
              {
                "kind": "ScalarField",
                "name": "id"
              },
              {
                "kind": "ScalarField",
                "name": "accountStatus"
              },
              {
                "kind": "ScalarField",
                "name": "name"
              },
              {
                "kind": "ScalarField",
                "name": "picture"
              },
              {
                "kind": "ScalarField",
                "name": "accountId"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "524011e015e79235b8450b442433c9df1dc212a8bb8c511c7cf1ba20bf5a83a4",
    "metadata": {},
    "name": "assignToMeInlineEdit_rootQuery",
    "operationKind": "query",
    "text": null
  }
};

(node as any).hash = "8100cad491ea8120d94050015ab57083";

export default node;
