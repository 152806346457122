import type { View } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import { areCurrentViewAllSwimlanesCollapsed } from '../../selectors/view/index.tsx';
import type { State, Props } from '../../types.tsx';
import { updateViewState } from '../utils/state/index.tsx';
import { currentViewFilter } from '../utils/views/index.tsx';

export const initSwimlanes =
	(swimlanes: string[]): Action<State, Props> =>
	({ setState, getState }, { currentViewSlug }) => {
		const { changedView, viewSets } = updateViewState(
			getState().viewSets,
			currentViewFilter(currentViewSlug),
			(view: View) => ({
				...view,
				collapsedSwimlanes: Object.fromEntries(Object.values(swimlanes).map((key) => [key, false])),
			}),
		);

		if (changedView) {
			setState({ viewSets });
		}
	};

export const toogleSwimlane =
	(verticalGropIdentity: string, isHiddenByDefault: boolean): Action<State, Props> =>
	({ getState, setState }, { currentViewSlug }) => {
		const { changedView, viewSets } = updateViewState(
			getState().viewSets,
			currentViewFilter(currentViewSlug),
			(view: View) => {
				const collapsedSwimlanes = { ...view.collapsedSwimlanes };
				collapsedSwimlanes[verticalGropIdentity] =
					isHiddenByDefault && collapsedSwimlanes[verticalGropIdentity] === undefined
						? false
						: !collapsedSwimlanes[verticalGropIdentity];
				return { ...view, collapsedSwimlanes };
			},
		);

		if (changedView) {
			setState({ viewSets });
			// collapsed swimlanes are not persisted, this info is only part of the local state
		}
	};

export const toggleAllSwimlanes =
	(): Action<State, Props> =>
	({ getState, setState }, props) => {
		const { currentViewSlug } = props;
		const areAllRowGroupsCollapsed = areCurrentViewAllSwimlanesCollapsed(getState(), props);
		const { changedView, viewSets } = updateViewState(
			getState().viewSets,
			currentViewFilter(currentViewSlug),
			(view: View) => {
				const collapsedSwimlanes = { ...view.collapsedSwimlanes };
				Object.keys(collapsedSwimlanes).forEach((key) => {
					collapsedSwimlanes[key] = !areAllRowGroupsCollapsed;
				});

				return { ...view, collapsedSwimlanes };
			},
		);

		if (changedView) {
			setState({ viewSets });
		}
	};
