import { useMemo } from 'react';
import { useService } from '@atlassian/jira-common-services/src/use-service/index.tsx';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { HipaaComplianceResponse, HipaaComplianceHook } from './types.tsx';

const getHipaaComplianceStatus = async (): Promise<HipaaComplianceResponse | undefined> =>
	performGetRequest('/rest/internal/latest/compliance/status/HIPAA');
export const useIsHipaaComplianceService = (): HipaaComplianceHook => {
	const {
		loading,
		error,
		data,
		fetch: fetchHipaaComplianceStatus,
	} = useService<HipaaComplianceResponse>(getHipaaComplianceStatus);
	const transformedData = useMemo(
		() =>
			data
				? {
						isCompliant: data.isCompliant,
					}
				: undefined,
		[data],
	);

	return [{ loading, error, data: transformedData }, fetchHipaaComplianceStatus];
};
