import type messages from './messages.tsx';

export const emoji = {
	clarify: '🔍',
	onIt: '✅',
	takingALook: '👋',
} as const satisfies Record<keyof typeof messages, string>;

export const QUICK_REPLY_ANALYTICS_SCOPE = 'jiraQuickReply';
export const QUICK_REPLY_ANALYTICS_VALID = 'validQueryParam';
export const QUICK_REPLY_ANALYTICS_INVALID = 'invalidQueryParam';
