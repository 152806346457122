import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	appsHaveBeenBlockedByYourAdmin: {
		id: 'blocked-apps-info-banner.apps-have-been-blocked-by-your-admin',
		defaultMessage:
			"Can't find something? Some apps are blocked by your admin in this project through a data security policy.",
		description:
			'Informs that the the some extensions are no longer visible due to security policy.',
	},
});
