import type { RecursiveFilter } from '../../filter/types.tsx';
import type { DynamicFieldFormula } from '../types.tsx';
import {
	PROPERTY_AGGREGATION_TEMPLATE,
	PROPERTY_SUM,
	type PropertyAggregationFormula,
} from './types.tsx';

export const propertySum = (
	key: string,
	oauthClientId: string,
	filter?: RecursiveFilter,
): PropertyAggregationFormula => ({
	template: PROPERTY_AGGREGATION_TEMPLATE,
	parameters: {
		agg: PROPERTY_SUM,
		key,
		oauthClientId,
		filter,
	},
});

export const propertyAggVisitFields = (formula: DynamicFieldFormula): boolean => {
	if (formula.template !== PROPERTY_AGGREGATION_TEMPLATE) {
		return false;
	}
	// no fields or subformulas, so nothing to do here
	return true;
};
