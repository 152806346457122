import React from 'react';
import { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import Icon from '@atlaskit/icon';
import Spinner from '@atlaskit/spinner';
import Tooltip from '@atlaskit/tooltip';
import type {
	ManualRule,
	ManualRuleExecutor,
} from '@atlassian/jira-automation-platform/src/common/types.tsx';
import {
	ManualTriggerGlyph,
	MenuIcon,
	TriggerIcon,
} from '@atlassian/jira-automation-platform/src/common/ui/icon/index.tsx';
import { ManualRulesEmptyState } from '@atlassian/jira-automation-platform/src/ui/manual-rules-empty-state/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { createAri } from '@atlassian/jira-platform-ari/src/index.tsx';
import { AnalyticsEventToProps } from '@atlassian/jira-product-analytics-bridge';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import type { StatusTransition } from '../../../../../common/types.tsx';
import { Skeleton } from '../../../../../common/ui/skeleton/index.tsx';
import messages from './messages.tsx';

export const DropdownItemWithAnalytics = AnalyticsEventToProps('dropdownItem', {
	onClick: 'clicked',
})(DropdownItem);

type ManualRulesListProps = {
	issueKey: string;
	issueId?: number;
	executingRule: number | null;
	rules: ManualRule[];
	onExecuteRule: ManualRuleExecutor;
};
export const ManualRulesList = ({
	issueId,
	executingRule,
	rules,
	onExecuteRule,
}: ManualRulesListProps) => {
	const cloudId = useCloudId();
	const issueAri = createAri({
		resourceOwner: 'jira',
		cloudId,
		resourceType: 'issue',
		resourceId: issueId ? issueId.toString() : '',
	});

	return (
		<>
			{rules.map((rule) => (
				<Tooltip key={rule.id} content={rule.name}>
					<DropdownItemWithAnalytics
						onClick={() => onExecuteRule(rule.id, [issueAri])}
						elemBefore={
							executingRule === rule.id ? (
								<MenuIcon>
									<Spinner size="small" />
								</MenuIcon>
							) : (
								<TriggerIcon>
									<Icon size="small" glyph={ManualTriggerGlyph} label="" />
								</TriggerIcon>
							)
						}
					>
						{rule.name}
					</DropdownItemWithAnalytics>
				</Tooltip>
			))}
		</>
	);
};

export type Props = {
	initialised: boolean;
	rules: ManualRule[];
	transitions: StatusTransition[];

	executeRule: ManualRuleExecutor;
	issueKey: string;
	issueId?: number;
	executingRule: number | null;

	baseAutomationUrl: string;
	canManageAutomations: boolean;
};

export const AutomationSubMenu = (props: Props) => {
	const {
		initialised,
		rules,
		transitions,
		baseAutomationUrl,
		canManageAutomations,
		executeRule,
		executingRule,
		issueKey,
		issueId,
	} = props;

	const { formatMessage } = useIntl();

	const shouldShowRulesList = initialised && rules.length > 0;
	const shouldShowEmptyState = initialised && rules.length === 0 && transitions.length === 0;

	if (!initialised) {
		return <Skeleton />;
	}

	if (shouldShowRulesList) {
		const automationItemGroupProps = canManageAutomations
			? { title: formatMessage(messages.manualRules) }
			: {};

		return (
			<DropdownItemGroup {...automationItemGroupProps}>
				<ManualRulesList
					rules={rules}
					onExecuteRule={executeRule}
					issueKey={issueKey}
					issueId={issueId}
					executingRule={executingRule}
				/>
			</DropdownItemGroup>
		);
	}

	if (shouldShowEmptyState) {
		return (
			<DropdownItemGroup title={formatMessage(messages.manualRules)}>
				<ManualRulesEmptyState
					baseAutomationUrl={baseAutomationUrl}
					canManageAutomations={canManageAutomations}
					createAutomationComponentFilter="QUICK_ACTIONS"
				/>
			</DropdownItemGroup>
		);
	}

	return null;
};

export default AutomationSubMenu;
