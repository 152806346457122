import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';

/*
    In https://jdog.jira-dev.com/browse/BENTO-809 we needed to prevent newlines
    from being copied when the user selects the issue key. In order to do this,
    we grow the LeftAligned portion and selectively apply padding to the Left
    and RightAligned containers so that the space taken up by the issue key is
    larger, and therefore gives the user a larger area to select the issue key
    in.
    The gutterWidth of 20px is hardcoded here and is tied to the gutter width
    that corresponds to the spacing of the grid that we are using.
*/

export const gutterWidth = gridSize * 2.5;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const RightAligned = styled.div({
	display: 'flex',
	flexFlow: 'row',
	flexWrap: 'nowrap',
	maxHeight: '32px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'[dir="rtl"] &': {
		paddingRight: token('space.200', '16px'),
		paddingLeft: token('space.250', '20px'),
	},
	flex: '1 0 auto',
	justifyContent: 'flex-end',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const HeaderWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	minHeight: `${gridSize * 4}px`,
	marginRight: 0,
	marginLeft: 'auto',
	marginBottom: token('space.025', '2px'),
	height: '100%',
	alignItems: 'center',
	maxWidth: '100%',
	display: 'flex',
	justifyContent: 'flex-end',
});
