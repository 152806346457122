import React from 'react';
import { Box } from '@atlaskit/primitives';
import { defineMessages, useIntl } from '@atlassian/jira-intl';
import type { AuditItemsResult, ProjectAdminDetails } from '../../common/types.tsx';
import RecentRuleRunsSkeleton from './recent-rule-runs-skeleton/index.tsx';
import RuleDisplay from './rule-display/index.tsx';

type RecentRuleRunsProps = { auditItems: AuditItemsResult } & {
	projectAdminDetails: ProjectAdminDetails;
};

const messages = defineMessages({
	noRuleRuns: {
		id: 'automation-issue-audit-log-panel.recent-rule-runs.no-rule-runs-text',
		defaultMessage: 'There are no recent rule runs for this issue.',
		description:
			"Text displayed when there are no recently run Automation rules that affected this issue. This appears after the user has attempted to get rule runs but it's empty.",
	},
	initialState: {
		id: 'automation-issue-audit-log-panel.recent-rule-runs.initial-state-text',
		defaultMessage: 'Refresh to see recent runs.',
		description:
			'Initial state text displayed. There is a refresh button. This prompts the user to hit the button to get recently run Automation rules that affect this issue.',
	},
});

const EmptyState: React.FC<{ text: string }> = ({ text }) => {
	return (
		<Box
			paddingBlockEnd="space.1000" // actually should be 170px
			color="color.text"
			testId="automation-issue-audit-log.ui.recent-rule-runs.empty-state"
		>
			{text}
		</Box>
	);
};

const RecentRuleContent: React.FC<RecentRuleRunsProps> = ({ auditItems, projectAdminDetails }) => {
	const { formatMessage } = useIntl();

	if (auditItems.status === 'loading') {
		return <RecentRuleRunsSkeleton />;
	}

	if (auditItems.status === 'loaded') {
		if (auditItems.value.length === 0) {
			return <EmptyState text={formatMessage(messages.noRuleRuns)} />;
		}
		return <RuleDisplay recentItems={auditItems.value} projectAdminDetails={projectAdminDetails} />;
	}

	return <EmptyState text={formatMessage(messages.initialState)} />;
};

const RecentRuleRuns: React.FC<RecentRuleRunsProps> = ({ auditItems, projectAdminDetails }) => {
	return (
		<Box paddingBlockStart="space.150" testId="automation-issue-audit-log.ui.recent-rule-runs">
			<RecentRuleContent auditItems={auditItems} projectAdminDetails={projectAdminDetails} />
		</Box>
	);
};

export default RecentRuleRuns;
