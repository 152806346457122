import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyAfterPaint } from '@atlassian/react-loosely-lazy';
import { useUFOComponentExperience } from '@atlassian/ufo';
import { TabsSkeleton } from './common/ui/tabs-skeleton/index.tsx';
import { summaryTabLoadExperience } from './experiences.tsx';
import type RiskInsightsPanelType from './ui/index.tsx';
import type { Props } from './ui/types.tsx';

export const AsyncRiskInsightsPanel = lazyAfterPaint<typeof RiskInsightsPanelType>(
	() => import(/* webpackChunkName: "async-risk-insights-panel" */ './ui'),
);

const LazyRiskInsightsPanel = ({ issueKey }: Props) => {
	useUFOComponentExperience(summaryTabLoadExperience);

	return (
		<JSErrorBoundary
			id="riskInsightsPanel"
			packageName="jiraServiceDeskRiskInsightsPanel"
			fallback="unmount"
			teamName="jenga"
			sendToPrivacyUnsafeSplunk
		>
			<Box xcss={panelStyles}>
				<Placeholder name="risk-insights-panel" fallback={<TabsSkeleton />}>
					<AsyncRiskInsightsPanel issueKey={issueKey} />
				</Placeholder>
			</Box>
		</JSErrorBoundary>
	);
};
const panelStyles = xcss({
	marginBottom: 'space.300',
});

export default LazyRiskInsightsPanel;
