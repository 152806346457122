import parameters from '@atlassian/jira-relay/src/__generated__/uiAppConfigurationPromptQuery$parameters';
import type {
	JiraConfigStateProviderType,
	JiraDevOpsInContextConfigPromptLocation,
} from '@atlassian/jira-relay/src/__generated__/uiAppConfigurationPromptQuery.graphql';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';
import type { Location } from './src/common/types.tsx';

type Props = {
	location: Location;
	cloudId: string;
};

const locationToProviderTypes = (location: Location): JiraConfigStateProviderType[] => {
	switch (location) {
		case 'DEVELOPMENT_PANEL':
			return ['DEVELOPMENT_INFO'];
		case 'RELEASES_PANEL':
			return ['DEPLOYMENTS'];
		case 'SECURITY_PANEL':
			return ['SECURITY'];
		default:
			return [];
	}
};

export const appConfigurationPromptEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() =>
			import(/* webpackChunkName: "async-jira-development-app-configuration-prompt" */ './src/ui'),
	),
	getPreloadProps: ({ location, cloudId }: Props) => ({
		queries: {
			queryRef: {
				options: {
					fetchPolicy: 'store-and-network' as const,
				},
				parameters,
				variables: {
					cloudId,
					providerTypes: locationToProviderTypes(location),
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					location: location as JiraDevOpsInContextConfigPromptLocation,
				},
			},
		},
	}),
});
