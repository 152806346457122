import parameters from '@atlassian/jira-relay/src/__generated__/srcAsyncInlineCreateStatusQuery$parameters';
import type { srcAsyncInlineCreateStatusQuery$variables } from '@atlassian/jira-relay/src/__generated__/srcAsyncInlineCreateStatusQuery.graphql';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const asyncInlineCreateStatusModal = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-inline-create-status-modal" */ './src'),
	),
	getPreloadProps: ({ cloudId, projectKey }: srcAsyncInlineCreateStatusQuery$variables) => ({
		queries: {
			inlineCreateStatusModal: {
				options: { fetchPolicy: 'store-or-network' as const },
				parameters,
				variables: {
					cloudId,
					projectKey,
				},
			},
		},
	}),
});
