import { getTime } from 'date-fns';
import type {
	Worklog,
	User,
} from '@atlassian/jira-issue-gira-transformer-types/src/common/types/worklogs.tsx';
import type { Worklogs } from '@atlassian/jira-issue-view-common-types/src/worklog-type.tsx';
import { removeCollectionFromAdf } from '@atlassian/jira-rich-content/src/common/adf-parsing-utils.tsx';
import type { ServerWorklog, ServerUser, ServerResponse } from '../../model/index.tsx';

const transformUser = (user: ServerUser): User => {
	const { accountId, displayName = null, avatarUrls = null } = user;
	return {
		accountId,
		displayName,
		avatarUrl: avatarUrls && avatarUrls['48x48'],
	};
};

export const transformWorklog = (worklog: ServerWorklog): Worklog => {
	const {
		id,
		author = null,
		updateAuthor = null,
		timeSpent,
		started,
		created,
		updated,
		comment = null,
	} = worklog;
	const wasUpdated = updated !== created;
	return {
		id,
		author: author && transformUser(author),
		timeSpent,
		started: getTime(new Date(started)),
		updated: wasUpdated ? getTime(new Date(updated)) : null,
		updateAuthor: wasUpdated && updateAuthor ? transformUser(updateAuthor) : null,
		comment: removeCollectionFromAdf(comment),
	};
};

export const transformWorklogs = (worklogs: ServerWorklog[]): Worklog[] =>
	worklogs.map(transformWorklog);

export const transformResponse = (response: ServerResponse): Worklogs => ({
	totalWorklogs: response.total,
	worklogs: transformWorklogs(response.worklogs),
});
