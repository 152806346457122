/**
 * @generated SignedSource<<26cc895a648b0bab1c864aab5e1d5b77>>
 * @relayHash d901d35fabd9719b1f617f8d9ebdc537
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID a7a0b56fd412e9bdb1efbc889ed8f61988f64f3f19ddfea11a99b1c26f94530c

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type uiUnlinkIdeaMutation$variables = {
  cloudId: string;
  issueLinkId: string;
};
export type uiUnlinkIdeaMutation$data = {
  readonly jira: {
    readonly deleteIssueLink: {
      readonly deletedIds: ReadonlyArray<string | null | undefined> | null | undefined;
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type uiUnlinkIdeaMutation = {
  response: uiUnlinkIdeaMutation$data;
  variables: uiUnlinkIdeaMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "issueLinkId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "kind": "Variable",
    "name": "issueLinkId",
    "variableName": "issueLinkId"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "deletedIds"
},
v3 = {
  "kind": "ScalarField",
  "name": "success"
},
v4 = {
  "concreteType": "MutationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "message"
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "uiUnlinkIdeaMutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraDeleteIssueLinkPayload",
            "kind": "LinkedField",
            "name": "deleteIssueLink",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "uiUnlinkIdeaMutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraDeleteIssueLinkPayload",
            "kind": "LinkedField",
            "name": "deleteIssueLink",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "kind": "ScalarField",
                "name": "id"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "a7a0b56fd412e9bdb1efbc889ed8f61988f64f3f19ddfea11a99b1c26f94530c",
    "metadata": {},
    "name": "uiUnlinkIdeaMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "e77201428b6cdacc072498d9e2de302e";

export default node;
