import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	title: {
		id: 'issue-view-layout-templates-pinned-fields-banner.title',
		defaultMessage: 'Your pinned fields',
		description: 'Title of the section message displayed on the Pinned Fields Click Through Banner',
	},
	groupingTitle: {
		id: 'issue-view-layout-templates-pinned-fields-banner.grouping-title',
		defaultMessage: 'Pinned fields',
		description:
			'Title of the section message displayed on the Pinned Fields Click Through Banner, when grouping is enabled',
	},
	description: {
		id: 'issue-view-layout-templates-pinned-fields-banner.description',
		defaultMessage: 'Click on the {pinnedIcon} next to a field label to start pinning.',
		description: 'Body of the section message displayed on the Pinned Fields Click Through Banner',
	},
	dismissTooltip: {
		id: 'issue-view-layout-templates-pinned-fields-banner.dismiss-tooltip',
		defaultMessage: 'Hide message',
		description: 'Tooltip message of dismiss button on the Pinned Fields Click Through Banner',
	},
});
