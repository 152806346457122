// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	linkButton: {
		id: 'issue.views.web-links.link.link-button',
		defaultMessage: 'Link',
		description: 'Button text to link the web link',
	},
	cancelButton: {
		id: 'issue.views.web-links.link.cancel-button',
		defaultMessage: 'Cancel',
		description: 'Button text to cancel operation and close the field',
	},
});
