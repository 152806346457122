import React, { useMemo } from 'react';
import { styled } from '@compiled/react';
import BitbucketPullRequestsIcon from '@atlaskit/icon/glyph/bitbucket/pullrequests';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import type {
	DropdownTriggerParams,
	CreatePullRequestTarget,
} from '@atlassian/jira-development-summary-common/src/common/types.tsx';
import { SummaryItem } from '@atlassian/jira-development-summary-common/src/ui/summary-item/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import {
	fireUIAnalytics,
	useAnalyticsEvents,
	FireUiAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { CreatePullRequestDropdown } from './create-pull-request-dropdown/index.tsx';
import messages from './messages.tsx';

// eslint-disable-next-line jira/styled/styled-component-order, @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconWrapper = styled.span({
	verticalAlign: 'middle',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const PullRequestIcon = (
	<IconWrapper aria-hidden>
		<BitbucketPullRequestsIcon size="medium" label="" />
	</IconWrapper>
);
type Props = {
	hasErrors: boolean;
	createPullRequestTargets: CreatePullRequestTarget[] | null | undefined;
	branchesCount: number;
	onAuthErrorClick?: () => void;
};

export const CreatePullRequestSummaryItem = ({
	branchesCount,
	createPullRequestTargets,
	hasErrors,
	onAuthErrorClick,
}: Props) => {
	const PULL_REQUEST_SUMMARY_TYPE = 'pullrequest';
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const issueKey = useIssueKey();

	const createPullRequestTitle = formatMessage(messages.createPullRequestTitle);

	const downChevron = useMemo(
		() => (
			<ChevronDownIcon
				size="medium"
				label={formatMessage(messages.createPullRequestDropdownIconLabel)}
			/>
		),
		[formatMessage],
	);

	const commonSummaryItemProps = {
		title: createPullRequestTitle,
		issueKey,
		linkFormatting: true,
		icon: PullRequestIcon,
	};

	const commonAnalyticsAttributes = {
		summaryItemType: PULL_REQUEST_SUMMARY_TYPE,
		inline: false,
	};
	const isLoading = createPullRequestTargets === null || createPullRequestTargets === undefined;
	const hasUrlLoaded =
		createPullRequestTargets !== null &&
		createPullRequestTargets !== undefined &&
		createPullRequestTargets.length === 1;

	// only show link if theres one branch, no errors,
	// and if its either currently loading (show spinner) or theres exactly one link (show external link)
	// otherwise show the dropdown
	if (branchesCount === 1 && !hasErrors && (isLoading || hasUrlLoaded)) {
		return (
			<SummaryItem
				{...commonSummaryItemProps}
				href={hasUrlLoaded ? createPullRequestTargets?.[0].createPullRequestUrl : null}
				onClick={() => {
					fireUIAnalytics(createAnalyticsEvent({}), 'link clicked', 'createPullRequestLink', {
						...commonAnalyticsAttributes,
						linkListLength: 1,
					});
				}}
				// eslint-disable-next-line jira/integration/test-id-by-folder-structure
				data-testid="development-summary-pull-request.ui.create-pull-request-inline-summary-item"
			/>
		);
	}

	return (
		<>
			<FireUiAnalytics
				actionSubject="button"
				action="viewed"
				actionSubjectId="createDevAction"
				attributes={commonAnalyticsAttributes}
			/>
			<CreatePullRequestDropdown
				hasErrors={hasErrors}
				onAuthErrorClick={onAuthErrorClick}
				createPullRequestTargets={createPullRequestTargets}
				renderDropdownTrigger={({ ref, ...props }: DropdownTriggerParams) => (
					<SummaryItem
						secondaryData={downChevron}
						reference={ref}
						{...commonSummaryItemProps}
						{...props}
						onClick={() => {
							props.onClick();
							fireUIAnalytics(
								createAnalyticsEvent({}),
								'button clicked',
								'createDevAction',
								commonAnalyticsAttributes,
							);
						}}
						data-testid="development-summary-pull-request.ui.create-pull-request-summary-item"
					/>
				)}
			/>
		</>
	);
};
