import { createStore, createHook, createActionsHook } from '@atlassian/react-sweet-state';
import { actions, type Actions } from './actions.tsx';
import type { State } from './types.tsx';

const initialState: State = {
	forcePreload: false,
	issues: [],
	flagAction: undefined,
};

const store = createStore<State, Actions>({
	initialState,
	actions,
	name: 'flagIssueModalStore',
});

export const useFlagIssueModal = createHook(store);
export const useFlagIssueModalActions = createActionsHook(store);
