import React from 'react';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type SlaType from './index.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const SlaPanel = lazyForPaint<typeof SlaType>(
	() => import(/* webpackChunkName: "async-sla-panel" */ './index'),
	{ ssr: false },
);

const AsyncSlaPanel = () => (
	<ErrorBoundary id="async.sla.panel" packageName="atlassian/jira-servicedesk-sla-panel">
		<Placeholder name="sla-panel" fallback={null}>
			<SlaPanel />
		</Placeholder>
	</ErrorBoundary>
);

export default AsyncSlaPanel;
