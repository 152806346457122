export const JiraSmartLinkLocations = {
	JIRA_WEB_LINKS: 'jiraWebLinks',
	CONFLUENCE_PAGES: 'jiraLinkedConfluencePages',
	CONFLUENCE_WHITEBOARDS: 'jiraLinkedConfluenceWhiteboards',
} as const;

export type JiraSmartLinkLocations =
	(typeof JiraSmartLinkLocations)[keyof typeof JiraSmartLinkLocations];

export const smartLinksAttributes = {
	display: 'flexible',
};

export const webLinksAnalyticAttributes = {
	...smartLinksAttributes,
	location: JiraSmartLinkLocations.JIRA_WEB_LINKS,
} as const;

export const confluencePagesAnalyticAttributes = {
	...smartLinksAttributes,
	location: JiraSmartLinkLocations.CONFLUENCE_PAGES,
} as const;

export const confluenceWhiteboardsAnalyticAttributes = {
	...smartLinksAttributes,
	location: JiraSmartLinkLocations.CONFLUENCE_WHITEBOARDS,
} as const;
