import React, { type ComponentType, useMemo } from 'react';
import { compose } from 'redux';
import memoizeOne from 'memoize-one';
import { graphql } from 'react-relay';
import { ErrorMessage } from '@atlaskit/form';
import { Stack } from '@atlaskit/primitives';
import type { ActionMeta } from '@atlassian/jira-common-components-picker/src/model.tsx';
import { FRAGMENT_SELECTABLE_FIELD_OPTIONS_FIRST } from '@atlassian/jira-issue-field-constants/src/index.tsx';
import { MultiSelectEditView } from '@atlassian/jira-issue-field-multi-select-editview-full/src/ui/multi-select/index.tsx';
import type { MultiSelectEditViewProps } from '@atlassian/jira-issue-field-multi-select-editview-full/src/ui/multi-select/types.tsx';
import { useQueryLoaderOnIntent } from '@atlassian/jira-issue-hooks/src/services/use-query-loader-on-intent/index.tsx';
import type { Option as SelectOption } from '@atlassian/jira-issue-internal-field-select/src/common/select-inline-edit/select-field/types.tsx';
import MultiSelectInlineEditView, {
	type Props as MultiSelectInlineEditViewProps,
} from '@atlassian/jira-issue-internal-field-select/src/multi-select-inline-edit/index.tsx';
import { genericMessages } from '@atlassian/jira-issue-view-common-constants/src/context-items-messages.tsx';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button/index.tsx';
import connectField from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-field.tsx';
import { withExtraOwnProps } from '@atlassian/jira-issue-view-common-views/src/with-extra-own-props/index.tsx';
import { fieldARISelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector.tsx';
import type { multiSelectWithRelayEditViewFieldOptionsPrefetchIntentQuery } from '@atlassian/jira-relay/src/__generated__/multiSelectWithRelayEditViewFieldOptionsPrefetchIntentQuery.graphql';
import {
	transformFromStateValue,
	transformToStateValue,
	transformRelayToReduxValue,
	transformReduxToRelayValue,
} from './transformer.tsx';

export const getCustomEditView = memoizeOne(
	(ari?: string) =>
		({
			onChange,
			value,
			noOptionsMessage,
			loadingMessage,
			invalidMessage,
			label,
			isInvalid,
			optionsFilter,
		}: MultiSelectInlineEditViewProps) => {
			const handleChange: MultiSelectEditViewProps['onChange'] = (values, actionMeta) => {
				const legacyValues = values?.map(transformRelayToReduxValue) ?? [];
				const legacyActionMeta: ActionMeta<SelectOption> = {
					action: actionMeta.action,
					...(actionMeta.option
						? {
								option: transformRelayToReduxValue(actionMeta.option),
							}
						: null),
					...(actionMeta.removedValue
						? {
								removedValue: transformRelayToReduxValue(actionMeta.removedValue),
							}
						: null),
				};

				onChange?.(legacyValues, legacyActionMeta);
			};

			const selectedOptions = useMemo(() => value.map(transformReduxToRelayValue), [value]);

			return (
				<Stack space="space.075">
					{Boolean(invalidMessage) && <ErrorMessage>{invalidMessage}</ErrorMessage>}
					<MultiSelectEditView
						autoFocus
						filterOptionsById={optionsFilter}
						fieldName={label}
						fieldId={ari || ''}
						value={selectedOptions}
						onChange={handleChange}
						noOptionsMessage={noOptionsMessage}
						loadingMessage={loadingMessage}
						isInvalid={isInvalid}
						spacing="compact"
					/>
				</Stack>
			);
		},
);

export type MultiSelectInlineEditViewWithPrefetchFieldOptionsProps = {
	ari: string;
} & MultiSelectInlineEditViewProps;

export const MultiSelectInlineEditViewWithPrefetchFieldOptions = (
	props: MultiSelectInlineEditViewWithPrefetchFieldOptionsProps,
) => {
	const [, prefetchOptions, abortPrefetchOptions] =
		// eslint-disable-next-line @atlassian/relay/must-use-inline
		useQueryLoaderOnIntent<multiSelectWithRelayEditViewFieldOptionsPrefetchIntentQuery>(graphql`
			query multiSelectWithRelayEditViewFieldOptionsPrefetchIntentQuery($id: ID!, $first: Int) {
				# eslint-disable-next-line @atlassian/relay/must-colocate-fragment-spreads
				...ui_issueSelectableFieldEditView_SelectableFieldEditViewWithFieldOptionsFragment
					@arguments(id: $id, first: $first)
			}
		`);

	return (
		<MultiSelectInlineEditView
			{...props}
			onInlineEditContainerPointerEnter={() =>
				prefetchOptions({
					id: props.ari,
					first: FRAGMENT_SELECTABLE_FIELD_OPTIONS_FIRST,
				})
			}
			onInlineEditContainerPointerLeave={(e) => e.pointerType === 'mouse' && abortPrefetchOptions()}
		/>
	);
};

export const MultiSelectFieldWithRelayEditView = compose<
	ComponentType<{ fieldId: string; area: string }>
>(
	withExtraOwnProps,
	connectField((_, ownPropsOnMount) => {
		const fieldARISelectorGetter = fieldARISelector(ownPropsOnMount.fieldId);

		return {
			fieldId: ownPropsOnMount.fieldId,
			transformFromStateValue,
			transformToStateValue,
			additionalProps: (state, intl) => ({
				noValueText: intl.formatMessage(genericMessages.noValue),
				showPinButton: getShowPinButton(ownPropsOnMount.area),
				customEditView: getCustomEditView(fieldARISelectorGetter(state)),
				ari: fieldARISelectorGetter(state),
			}),
		};
	}),
)(MultiSelectInlineEditViewWithPrefetchFieldOptions);
