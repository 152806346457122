/* eslint-disable @atlassian/relay/unused-fields */
import { useCallback } from 'react';
import { graphql, fetchQuery } from 'react-relay';
import getRelayEnvironment from '@atlassian/jira-relay-environment/src/index.tsx';
import type { useFetchWhiteboardFetchConfluenceWhiteboardQuery } from '@atlassian/jira-relay/src/__generated__/useFetchWhiteboardFetchConfluenceWhiteboardQuery.graphql';

export type FetchConfluenceWhiteboardType = (arg: string) => Promise<{
	whiteboardId: string;
	links?: {
		webUi?: string | null;
		base?: string | null;
	} | null;
	author?: {
		user?: {
			accountId: string;
			accountStatus: string;
			name: string;
			picture: string;
		} | null;
	} | null;
	title?: string | null;
	id: string;
}>;

export const useConfluenceWhiteboard = () => {
	const fetchConfluenceWhiteboard = useCallback(async (whiteboardAri: string) => {
		const data = await fetchQuery<useFetchWhiteboardFetchConfluenceWhiteboardQuery>(
			getRelayEnvironment(),
			graphql`
				query useFetchWhiteboardFetchConfluenceWhiteboardQuery($id: ID!) {
					confluence {
						whiteboard(id: $id) @optIn(to: "ConfluenceWhiteboardsRelease") {
							whiteboardId
							links {
								base
								webUi
							}
							author {
								user {
									accountId
									accountStatus
									name
									picture
								}
							}
							title
							id
						}
					}
				}
			`,
			{
				id: whiteboardAri,
			},
			{
				fetchPolicy: 'store-or-network',
			},
		).toPromise();

		const whiteboard = data?.confluence?.whiteboard;

		if (!whiteboard) {
			throw new Error('Whiteboard not found');
		}

		return whiteboard;
	}, []);

	return {
		fetchConfluenceWhiteboard,
	};
};
