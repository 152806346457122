import { useCallback } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import gqlTagPolaris from 'graphql-tag';
import type { Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import type {
	jira_polaris_GetEarliestOnboardedProjectForCloudId as GetEarliestOnboardedProjectForCloudId,
	jira_polaris_GetEarliestOnboardedProjectForCloudIdVariables as GetEarliestOnboardedProjectForCloudIdVariables,
} from './__generated_apollo__/jira_polaris_GetEarliestOnboardedProjectForCloudId';
import type { EarliestOnboardedProjectTimeStampFetcher } from './types.tsx';

const GET_EARLIEST_ONBOARDED_PROJECT_FOR_CLOUDID_QUERY = gqlTagPolaris`
    query jira_polaris_GetEarliestOnboardedProjectForCloudId($ari: ID!) {
        polarisGetEarliestOnboardedProjectForCloudId(id: $ari) {
            datetime
            template
        }
    }
`;

export const useFetchEarliestOnboardedProject = (): [EarliestOnboardedProjectTimeStampFetcher] => {
	const client = useApolloClient();

	return [
		useCallback(
			async (ari: Ari) => {
				const { data, errors } = await client.query<
					GetEarliestOnboardedProjectForCloudId,
					GetEarliestOnboardedProjectForCloudIdVariables
				>({
					query: GET_EARLIEST_ONBOARDED_PROJECT_FOR_CLOUDID_QUERY,
					variables: {
						ari,
					},
				});

				if (errors !== undefined) {
					throw new Error(
						`polaris.GetEarliestOnboardedProjectForCloudId.error:${errors
							.map((e) => e.message)
							.join(', ')}`,
					);
				}

				return {
					timestamp: data.polarisGetEarliestOnboardedProjectForCloudId?.datetime,
					template: data.polarisGetEarliestOnboardedProjectForCloudId?.template,
				};
			},
			[client],
		),
	];
};
