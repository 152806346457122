import React, { type ReactNode } from 'react';
import { components } from '@atlaskit/select';
import { useIntl } from '@atlassian/jira-intl';
import type { GroupOption } from '../types.tsx';
import messages from './messages.tsx';
import { Container, MenuFooterText } from './styled.tsx';

type Props = {
	options: GroupOption[];
	children: ReactNode;
};

const MenuListComponent = (props: Props) => {
	const { formatMessage } = useIntl();
	const { options } = props;

	const footer =
		options.length > 0 && options[0].moreResultsAvailable ? (
			<MenuFooterText>{formatMessage(messages.menuFooter)}</MenuFooterText>
		) : null;

	return (
		// @ts-expect-error - TS2740 - Type '{ children: (Element | ReactNode)[]; options: GroupOption[]; }' is missing the following properties from type 'CommonProps<OptionTypeBase, boolean, GroupTypeBase<OptionTypeBase>>': clearValue, cx, getStyles, getValue, and 7 more.
		<components.MenuList {...props}>
			<Container>{formatMessage(messages.menuHeader)}</Container>
			{props.children}
			{footer}
		</components.MenuList>
	);
};

export default MenuListComponent;
