import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noMatches: {
		id: 'issue-field-parent.edit.no-matches',
		defaultMessage: 'No matches',
		description: 'Message when no options are found in a select',
	},
	failedFetch: {
		id: 'issue-field-parent.edit.failed-fetch',
		defaultMessage: 'Search failed. Please try again.',
		description: 'Message when fetching parent suggestions fails',
	},
	placeholder: {
		id: 'issue-field-parent.edit.placeholder',
		defaultMessage: 'Select parent',
		description: 'Placeholder text for parent select component',
	},
	loading: {
		id: 'issue-field-parent.edit.loading',
		defaultMessage: 'Searching...',
		description: 'Message showed when parent options are being loaded from the server.',
	},
});
