import React, { type FC } from 'react';
import { styled } from '@compiled/react';
import Link from '@atlaskit/link';
import { token } from '@atlaskit/tokens';
import { FormattedMessage } from '@atlassian/jira-intl';
import messages from '../messages.tsx';

const linkToSupport = 'https://support.atlassian.com/contact/#/';

export const FeedbackTitle: FC = () => (
	<>
		<p>
			<FormattedMessage {...messages.feedbackPreambleMessage} />
		</p>

		<ParagraphWithMargin>
			<FormattedMessage
				{...messages.reportABugMessage}
				values={{
					link: (chunks) => (
						<span>
							<Link href={linkToSupport} target="_blank" rel="noopener noreferrer">
								{chunks}
							</Link>
						</span>
					),
				}}
			/>
		</ParagraphWithMargin>
	</>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ParagraphWithMargin = styled.p({
	marginBottom: token('space.100', '8px'),
});
