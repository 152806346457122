/**
 * @generated SignedSource<<90453d405888c92f35ace7d8150fb67d>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type assignToMeInlineEdit_issueViewLayoutAssigneeField_AssignToMeInlineEditInner_rootFragmentKey$data = {
  readonly " $fragmentSpreads": FragmentRefs<"assignToMe_issueFieldAssigneeAssignToMe_fragmentRefRoot">;
  readonly " $fragmentType": "assignToMeInlineEdit_issueViewLayoutAssigneeField_AssignToMeInlineEditInner_rootFragmentKey";
};
export type assignToMeInlineEdit_issueViewLayoutAssigneeField_AssignToMeInlineEditInner_rootFragmentKey$key = {
  readonly " $data"?: assignToMeInlineEdit_issueViewLayoutAssigneeField_AssignToMeInlineEditInner_rootFragmentKey$data;
  readonly " $fragmentSpreads": FragmentRefs<"assignToMeInlineEdit_issueViewLayoutAssigneeField_AssignToMeInlineEditInner_rootFragmentKey">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "assignToMeInlineEdit_issueViewLayoutAssigneeField_AssignToMeInlineEditInner_rootFragmentKey",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "assignToMe_issueFieldAssigneeAssignToMe_fragmentRefRoot"
    }
  ],
  "type": "Query"
};

(node as any).hash = "28fc53e806452f77e4b27731846f82b7";

export default node;
