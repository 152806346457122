import React, { type ReactNode } from 'react';
import type { ProfilecardTriggerPosition } from '@atlaskit/profilecard';
import {
	mapExternalUserToAccountId,
	type OptionalAccount,
	type ExternalUser,
} from '@atlassian/jira-issue-view-common-utils/src/utils/map-user-account-id.tsx';
import {
	mapExternalUserToFullName,
	type OptionalUsername,
} from '@atlassian/jira-issue-view-common-utils/src/utils/map-user-full-name.tsx';
import { AsyncProfileCardNext } from '@atlassian/jira-profilecard-next/src/async.tsx';
import type { Actions, AnalyticsData } from '@atlassian/jira-profilecard-next/src/types.tsx';

type Props = {
	userAccount: ExternalUser;
	children: ReactNode;
	analyticsData?: AnalyticsData;
	position?: ProfilecardTriggerPosition;
	actions?: Actions[];
	testId?: string;
};

const WithProfileCardNext = (props: Props) => {
	const accountId: string | undefined = mapExternalUserToAccountId(
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		props.userAccount as OptionalAccount,
	);

	const fullName: string | undefined =
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		mapExternalUserToFullName(props.userAccount as OptionalUsername);

	const renderAsyncProfileCardWrapper = (accountIdInArgs: string) => (
		<AsyncProfileCardNext
			accountId={accountIdInArgs}
			analyticsData={props.analyticsData}
			position={props.position}
			actions={props.actions}
			testId={props.testId}
			prepopulatedData={{ fullName }}
		>
			{props.children}
		</AsyncProfileCardNext>
	);

	return accountId ? renderAsyncProfileCardWrapper(accountId) : <>{props.children}</>;
};

export default WithProfileCardNext;
