import type {
	User as Voter,
	ServerRestUser as User,
} from '@atlassian/jira-issue-shared-types/src/common/types/user-type.tsx';
import { toAccountId } from '@atlassian/jira-shared-types/src/general.tsx';

export const addLoggedInUserToVoters = (voters: Voter[], loggedInUser: Voter | null): Voter[] => {
	if (!loggedInUser || voters.findIndex((voter) => voter.id === loggedInUser.id) !== -1) {
		return voters;
	}

	const newVoters = [...voters, loggedInUser];
	return newVoters.sort((v1, v2) => v1.displayName.localeCompare(v2.displayName));
};

export const removeLoggedInUserFromVoters = (
	voters: Voter[],
	loggedInUser: Voter | null,
): Voter[] => {
	if (!loggedInUser) {
		return voters;
	}
	return voters.filter((voter) => voter.id !== loggedInUser.id);
};

export const transformServerUserToVoter = (user: User): Voter => ({
	id: toAccountId(user.accountId),
	displayName: user.displayName,
	avatarUrl: user.avatarUrls && user.avatarUrls['48x48'],
});
