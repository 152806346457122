/** @jsx jsx */
import React, { useCallback } from 'react';
import { css, jsx } from '@compiled/react';
import Button, { IconButton } from '@atlaskit/button/new';
import CheckIcon from '@atlaskit/icon/core/migration/check-mark--check';
import CrossIcon from '@atlaskit/icon/core/migration/close--cross';
import { xcss, Flex, Box } from '@atlaskit/primitives';
import {
	Card,
	TitleBlock,
	SmartLinkSize,
	SmartLinkTheme,
	SmartLinkPosition,
} from '@atlaskit/smart-card';
import { token } from '@atlaskit/tokens';
import {
	FeedbackForm,
	RELATED_ISSUES_AI_EXPERIENCE,
} from '@atlassian/jira-atlassian-intelligence/src/common/ui/feedback-form/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useProjectKey } from '@atlassian/jira-project-context-service/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	useRelatedIssuesActions,
	useRelatedIssuesState,
	type RelatedIssue,
} from '../../controllers/context.tsx';
import { useRelatesToIssueLinkType } from '../../utils/index.tsx';
import type { onIssueLinkSave } from '../types.tsx';
import messages from './messages.tsx';

const FEEDBACK_ENTRYPOINT_ID = 'f91a821a-c7b4-47a6-bc0c-b99c234f3124';
const FEEDBACK_COLLECTOR_ID = '043ab007-eb05-4694-9742-302b0a7f41a6';
const ACTION_BUTTON_COMPONENT_SELECTOR =
	'ai-improve-issue-ai-related-issues-draft-list-row-action-button';

type Props = {
	onIssueLinkSave: onIssueLinkSave;
};

export const RelatedIssuesDraftList = ({ onIssueLinkSave }: Props) => {
	const { formatMessage } = useIntl();
	const { resourceSuggestions } = useRelatedIssuesState();
	const { acceptSuggestion, acceptAllSuggestions, discardSuggestion, discardAllSuggestions } =
		useRelatedIssuesActions();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const relatedToIssueType = useRelatesToIssueLinkType();

	const draftSuggestions = resourceSuggestions?.filter(
		(suggestion) => suggestion.resourceStatus === 'DRAFT',
	);

	const onSingleSuggestionAccept = useCallback(
		(linkSuggestionsRequest: RelatedIssue) => {
			const optimisticId = `OPTIMISTIC_LINKED_ISSUE_${Date.now()}_${linkSuggestionsRequest.id}`;

			onIssueLinkSave(
				optimisticId,
				linkSuggestionsRequest,
				relatedToIssueType,
				createAnalyticsEvent({}),
			);
		},
		[createAnalyticsEvent, onIssueLinkSave, relatedToIssueType],
	);

	const onAcceptSuggestion = useCallback(
		(linkSuggestionsRequest: RelatedIssue) => {
			/**
			 * We have this condition here to handle the case
			 * where there is only one suggestion and the user clicks on the accept button.
			 */
			if (draftSuggestions && draftSuggestions.length === 1) {
				acceptAllSuggestions();
			} else {
				acceptSuggestion(linkSuggestionsRequest);
			}
			onSingleSuggestionAccept(linkSuggestionsRequest);

			if (fg('jira-ai-similar-issues-updating-analytics')) {
				fireTrackAnalytics(
					createAnalyticsEvent({}),
					'aiResult actioned',
					'aiRelatedIssuesSuggestionResultBox',
					{
						aiResultAction: 'inlineIssueAccepted',
					},
				);
			} else {
				fireTrackAnalytics(createAnalyticsEvent({}), {
					actionSubject: 'aiResult',
					action: 'actioned',
					actionSubjectId: 'client',
					attributes: {
						source: 'aiRelatedIssuesSuggestionResultBox',
						aiResultAction: 'inlineIssueAccepted',
					},
				});
			}
		},
		[
			acceptAllSuggestions,
			acceptSuggestion,
			createAnalyticsEvent,
			draftSuggestions,
			onSingleSuggestionAccept,
		],
	);

	const onAcceptAllSuggestions = useCallback(() => {
		if (!draftSuggestions) {
			return;
		}
		for (const suggestion of draftSuggestions) {
			onSingleSuggestionAccept(suggestion.resource);
		}
		acceptAllSuggestions();

		if (fg('jira-ai-similar-issues-updating-analytics')) {
			fireTrackAnalytics(
				createAnalyticsEvent({}),
				'aiResult actioned',
				'aiRelatedIssuesSuggestionResultBox',
				{
					aiResultAction: 'bulkIssuesAccepted',
					aiItemsCount: draftSuggestions.length,
				},
			);
		} else {
			fireTrackAnalytics(createAnalyticsEvent({}), {
				actionSubject: 'aiResult',
				action: 'actioned',
				actionSubjectId: 'client',
				attributes: {
					source: 'aiRelatedIssuesSuggestionResultBox',
					aiResultAction: 'bulkIssuesAccepted',
					aiItemsCount: draftSuggestions.length,
				},
			});
		}
	}, [acceptAllSuggestions, createAnalyticsEvent, draftSuggestions, onSingleSuggestionAccept]);

	const onDiscardSuggestion = useCallback(
		(suggestionToBeDiscarded: RelatedIssue) => {
			/**
			 * We have this condition here to handle the case
			 * where there is only one suggestion and the user clicks on the discard button.
			 */
			if (draftSuggestions && draftSuggestions.length === 1) {
				discardAllSuggestions();
			} else {
				discardSuggestion(suggestionToBeDiscarded);
			}

			if (fg('jira-ai-similar-issues-updating-analytics')) {
				fireTrackAnalytics(
					createAnalyticsEvent({}),
					'aiResult actioned',
					'aiRelatedIssuesSuggestionResultBox',
					{
						aiResultAction: 'inlineIssueDeclined',
					},
				);
			} else {
				fireTrackAnalytics(createAnalyticsEvent({}), {
					actionSubject: 'aiResult',
					action: 'actioned',
					actionSubjectId: 'client',
					attributes: {
						source: 'aiRelatedIssuesSuggestionResultBox',
						aiResultAction: 'inlineIssueDeclined',
					},
				});
			}
		},
		[createAnalyticsEvent, discardAllSuggestions, discardSuggestion, draftSuggestions],
	);

	const onContainerRef = useCallback((element: HTMLDivElement | null) => {
		if (element && typeof element.scrollIntoView === 'function') {
			element.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
			});
		}
	}, []);

	const cards = draftSuggestions?.map(({ resource }) => {
		return (
			<div css={rowStyles} key={resource.id}>
				<Flex alignItems="center">
					<Card
						appearance="block"
						url={resource.issueLink}
						showHoverPreview
						ui={{
							size: SmartLinkSize.Medium,
							hideElevation: true,
							hideBackground: true,
							theme: SmartLinkTheme.Black,
							hidePadding: true,
						}}
					>
						<TitleBlock
							hideTitleTooltip
							position={SmartLinkPosition.Center}
							testId={`ai-related-issues.ui.related-issues-draft-list.title-block-${resource.id}`}
						/>
					</Card>
				</Flex>
				<Flex xcss={actionStyles}>
					<IconButton
						testId={`ai-related-issues.ui.related-issues-draft-list.accept-suggestion-icon-button-${resource.id}`}
						data-component-selector={`${ACTION_BUTTON_COMPONENT_SELECTOR}`}
						icon={CheckIcon}
						label={formatMessage(messages.link)}
						spacing="compact"
						appearance="subtle"
						onClick={() => onAcceptSuggestion(resource)}
						isTooltipDisabled={false}
						tooltip={{
							testId:
								'ai-work-breakdown.ui.issue-breakdown-draft-list.suggestion-list-item.icon-button.tooltip',
							position: 'bottom',
						}}
					/>
					<IconButton
						testId={`ai-related-issues.ui.related-issues-draft-list.discard-suggestion-icon-button-${resource.id}`}
						data-component-selector={`${ACTION_BUTTON_COMPONENT_SELECTOR}`}
						icon={CrossIcon}
						label={formatMessage(messages.remove)}
						spacing="compact"
						appearance="subtle"
						onClick={() => onDiscardSuggestion(resource)}
						isTooltipDisabled={false}
						tooltip={{
							testId:
								'ai-work-breakdown.ui.issue-breakdown-draft-list.suggestion-list-item.icon-button.tooltip',
							position: 'bottom',
						}}
					/>
				</Flex>
			</div>
		);
	});

	const onFeedbackAction = useCallback(
		(typeOfAction: 'submitted' | 'clicked', aiFeedbackResult: 'up' | 'down') => {
			if (fg('jira-ai-similar-issues-updating-analytics')) {
				fireTrackAnalytics(
					createAnalyticsEvent({}),
					`aiFeedback ${typeOfAction}`,
					'aiRelatedIssuesSuggestionResultBox',
					{ aiFeedbackResult },
				);
			} else {
				fireTrackAnalytics(createAnalyticsEvent({}), {
					actionSubject: 'aiFeedback',
					action: typeOfAction,
					actionSubjectId: 'client',
					attributes: {
						source: 'aiRelatedIssuesSuggestionResultBox',
						aiFeedbackResult,
					},
				});
			}
		},
		[createAnalyticsEvent],
	);

	const onFeedbackClicked = useCallback(
		(aiFeedbackResult: 'up' | 'down') => {
			onFeedbackAction('clicked', aiFeedbackResult);
		},
		[onFeedbackAction],
	);

	const onFeedbackSubmitted = useCallback(
		(aiFeedbackResult: 'up' | 'down') => {
			onFeedbackAction('submitted', aiFeedbackResult);
		},
		[onFeedbackAction],
	);

	return (
		<Box ref={onContainerRef}>
			{cards}
			{draftSuggestions && draftSuggestions.length !== 0 && (
				<Flex xcss={footerActionsSectionStyles}>
					<FeedbackForm
						feedbackCollectorId={FEEDBACK_COLLECTOR_ID}
						feedbackFormEntrypointId={FEEDBACK_ENTRYPOINT_ID}
						aiExperience={RELATED_ISSUES_AI_EXPERIENCE}
						onFeedbackClicked={onFeedbackClicked}
						onFeedbackSent={onFeedbackSubmitted}
						projectKey={projectKey}
					/>
					<Flex columnGap="space.150">
						<Button
							type="button"
							testId="ai-related-issues.ui.related-issues-draft-list.accept-all-loading-button"
							isDisabled={false}
							onClick={onAcceptAllSuggestions}
						>
							{formatMessage(messages.acceptAll)}
						</Button>
					</Flex>
				</Flex>
			)}
		</Box>
	);
};

const rowStyles = css({
	display: 'flex',
	justifyContent: 'space-between',
	paddingInline: token('space.200'),
	paddingBlock: token('space.150'),
	borderWidth: token('border.width'),
	borderColor: token('color.border'),
	borderStyle: 'solid',
	backgroundColor: token('elevation.surface'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	"[data-component-selector='ai-improve-issue-ai-related-issues-draft-list-row-action-button']": {
		opacity: '0',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	"[data-component-selector='ai-improve-issue-ai-related-issues-draft-list-row-action-button-is-linking']":
		{ opacity: '1' },

	'&:hover, &:focus-within': {
		backgroundColor: token('color.background.neutral.subtle.hovered'),
		textDecoration: 'none',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		"[data-component-selector='ai-improve-issue-ai-related-issues-draft-list-row-action-button']": {
			opacity: '1',
		},
	},
});

const actionStyles = xcss({
	display: 'flex',
	justifyContent: 'space-between',
	columnGap: 'space.150',
});

const footerActionsSectionStyles = xcss({
	justifyContent: 'space-between',
	padding: 'space.150',
});
