import memoizeOne from 'memoize-one';
import type { Area } from '@atlassian/jira-issue-view-common-types/src/connect-field-type.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button/index.tsx';
import connectField from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-field.tsx';
import { baseUrlSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import { fieldSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector.tsx';
import { toBaseUrl } from '@atlassian/jira-shared-types/src/general.tsx';
import { transformFromStateValue, transformToStateValue } from './people-transformer.tsx';
import { People } from './view.tsx';

const emptyField = {};
const emptyConfiguration = {};
const additionalPropsFactory = memoizeOne(
	(fieldId: string, area: Area | undefined, showEditIcon?: boolean) => (state: State) => {
		const field = fieldSelector(fieldId)(state) || emptyField;

		return {
			baseUrl: toBaseUrl(baseUrlSelector(state)),
			// @ts-expect-error - TS2339 - Property 'configuration' does not exist on type '{}'.
			configuration: field.configuration || emptyConfiguration,
			showPinButton: getShowPinButton(area),
			showEditIcon,
		};
	},
);

export default connectField((stateOnMount, { fieldId, area, showEditIcon }) => ({
	fieldId,
	transformFromStateValue,
	transformToStateValue,
	additionalProps: additionalPropsFactory(fieldId, area, showEditIcon),
}))(People);
