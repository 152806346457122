import type { Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import { setDeliveryProject } from '../../../services/polaris-api/set-delivery-project/index.tsx';
import type { Props, State } from '../types.tsx';

export const setSelectedDeliveryProject =
	(deliveryProjectAri: Ari): Action<State, Props> =>
	async ({ getState, setState }, { apolloClient, onActionFailed }) => {
		const { project } = getState();
		if (project.id) {
			setDeliveryProject(apolloClient, project.id, deliveryProjectAri)
				.then(() => {
					setState({
						project: {
							...project,
							selectedDeliveryProject: deliveryProjectAri,
						},
					});
				})
				.catch((error) => {
					onActionFailed(error);
				});
		}
	};
