import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/switchMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import {
	fetchProjectHasSubtaskValidatorsSuccessAction,
	fetchProjectHasSubtaskValidatorsFailureAction,
	FETCH_PROJECT_HAS_SUBTASK_VALIDATORS,
} from '@atlassian/jira-issue-view-store/src/actions/project-validator-actions.tsx';
import {
	baseUrlSelector,
	projectKeySelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import { fetchProjectHasSubtaskValidators as fetchProjectHasSubtaskValidatorsFromServer } from './project-validator-server.tsx';

type ReduxAction = {
	readonly type: string;
};

const fetchProjectHasSubtaskValidators = (
	action$: ActionsObservable<ReduxAction>,
	store: MiddlewareAPI<State>,
) =>
	action$.ofType(FETCH_PROJECT_HAS_SUBTASK_VALIDATORS).switchMap(() => {
		const state = store.getState();
		const baseUrl = baseUrlSelector(state);
		const projectKey = projectKeySelector(state);
		return (
			fetchProjectHasSubtaskValidatorsFromServer(baseUrl, projectKey)
				// @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'boolean'.
				.map((response) => fetchProjectHasSubtaskValidatorsSuccessAction(response))
				.catch(() => Observable.of(fetchProjectHasSubtaskValidatorsFailureAction()))
		);
	});

export default fetchProjectHasSubtaskValidators;
