import SingleSelectInlineEditView from '@atlassian/jira-issue-internal-field-select/src/single-select-inline-edit/index.tsx';
import { genericMessages } from '@atlassian/jira-issue-view-common-constants/src/context-items-messages.tsx';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button/index.tsx';
import connectField from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-field.tsx';
import { isFieldRequiredSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector.tsx';
import { fetchSuggestionsFactory } from '../rest/fetch.tsx';
import messages from './messages.tsx';
import { transformFromStateValue, transformToStateValue } from './transformer.tsx';

export default connectField((stateOnMount, ownPropsOnMount) => ({
	fieldId: ownPropsOnMount.fieldId,
	transformFromStateValue,
	transformToStateValue,
	additionalProps: (state, intl) => ({
		fetchSuggestions: fetchSuggestionsFactory(),
		placeholder: intl.formatMessage(messages.placeholder),
		noValueText: intl.formatMessage(genericMessages.noValue),
		allowEmptyValue: !isFieldRequiredSelector(ownPropsOnMount.fieldId)(state),
		hasAutocomplete: true,
		showPinButton: getShowPinButton(ownPropsOnMount.area),
	}),
}))(SingleSelectInlineEditView);
