// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'issue.views.common.child-issues-panel.heading.title.title',
		defaultMessage: 'Child issues',
		description: 'Heading for the child issues panel',
	},
	titleIssueTermRefresh: {
		id: 'issue.views.common.child-issues-panel.heading.title.title-issue-term-refresh',
		defaultMessage: 'Child work items',
		description: 'Heading for the child work items panel',
	},
});
