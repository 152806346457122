import React from 'react';
import type { CustomGlyphProps } from '@atlaskit/icon';

export const labelGlyph = (props: CustomGlyphProps) => (
	<svg
		{...props}
		width="16"
		height="16"
		viewBox="4 4 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M10.6445 10.6463C10.4961 10.7972 10.3193 10.9172 10.1243 10.9994C9.92932 11.0816 9.71997 11.1243 9.50835 11.1252C9.29673 11.1261 9.08703 11.085 8.89135 11.0045C8.69568 10.9239 8.51789 10.8053 8.36825 10.6557C8.21861 10.5061 8.10009 10.3283 8.0195 10.1326C7.93892 9.93693 7.89788 9.72723 7.89875 9.51561C7.89963 9.30399 7.94239 9.09464 8.02458 8.89963C8.10677 8.70462 8.22675 8.52782 8.37762 8.37942C8.67836 8.07868 9.08625 7.90972 9.51156 7.90972C9.93687 7.90972 10.3448 8.07868 10.6455 8.37942C10.9462 8.68016 11.1152 9.08805 11.1152 9.51336C11.1152 9.93867 10.9462 10.3466 10.6455 10.6473M11.2671 5.01079L7.21914 5.5881C6.80686 5.64729 6.42491 5.83864 6.13065 6.13342C5.8364 6.42819 5.64572 6.81048 5.58727 7.22287L5.00995 11.2708C4.98853 11.4186 5.00181 11.5694 5.04873 11.7112C5.09566 11.8529 5.17495 11.9819 5.28033 12.0877L11.9146 18.721C12.0039 18.8105 12.11 18.8814 12.2267 18.9298C12.3434 18.9782 12.4685 19.0032 12.5949 19.0032C12.7213 19.0032 12.8464 18.9782 12.9631 18.9298C13.0799 18.8814 13.1859 18.8105 13.2752 18.721L18.7183 13.2779C18.8987 13.0975 19 12.8528 19 12.5977C19 12.3425 18.8987 12.0978 18.7183 11.9174L12.084 5.28213C11.9784 5.17628 11.8496 5.09655 11.7078 5.04928C11.5659 5.002 11.415 4.9885 11.2671 5.00983V5.01079Z"
			stroke="currentColor"
			strokeWidth="1.5"
		/>
	</svg>
);
