import type { TeamFieldType } from '@atlassian/jira-issue-internal-fields/src/team/types.tsx';
import type { TransformerContext } from '@atlassian/jira-issue-view-common-types/src/connect-field-type.tsx';

type StateValueDataType = {
	id: number;
	title: string;
	isShared: boolean;
	avatarUrl: string;
} | null;

/**
 * Team avatarUrl is relative URL
 */
type TeamSuggestion = {
	shareable: boolean;
	id: number;
	title: string;
	avatarUrl?: string;
};
type SuggestionOption = {
	teams: TeamSuggestion[];
	moreResultsAvailable: boolean;
};

const toItem = (
	data: {
		avatarUrl: string;
		id: number;
		isShared: boolean;
		title: string;
	},
	baseUrl: string,
) => ({
	value: data.id,
	label: data.title,
	isShared: data.isShared,
	avatarUrl: data.avatarUrl ? `${baseUrl}${data.avatarUrl}` : '',
});

const fromItemData = (data: {
	avatarUrl: string | undefined;
	isShared: boolean;
	label: string;
	value: number;
}) => ({
	id: data.value,
	title: data.label,
	isShared: data.isShared,
	avatarUrl: data.avatarUrl ? data.avatarUrl : '',
});

export const transformFromStateValue = (
	value: StateValueDataType,
	transformerContext: TransformerContext,
) => (value ? toItem(value, transformerContext.baseUrl) : null);

export const transformToStateValue = (value: TeamFieldType) => (value ? fromItemData(value) : null);

export const transformSuggestions = (suggestions: SuggestionOption, baseUrl: string) => {
	const formattedData: TeamFieldType[] = suggestions.teams.map((team: TeamSuggestion) => ({
		value: team.id,
		label: team.title,
		isShared: team.shareable,
		avatarUrl: team.avatarUrl ? `${baseUrl}${team.avatarUrl}` : '',
	}));
	return [
		{ options: formattedData, moreResultsAvailable: suggestions.moreResultsAvailable },
	] as const;
};
