import React from 'react';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type IncidentSummary from './index.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyIncidentSummary = lazyForPaint<typeof IncidentSummary>(
	() => import(/* webpackChunkName: "async-servicedesk-pir-incident-summary" */ './index'),
	{ ssr: false },
);

export const AsyncIncidentSummary = () => (
	<Placeholder name="servicedesk-pir-incident-summary" fallback={null}>
		<LazyIncidentSummary />
	</Placeholder>
);
