import URL from 'url-parse';
import { type Href, toHref } from '@atlassian/jira-shared-types/src/general.tsx';

const FALLBACK_HOST_NAME = 'null' as const;

/**
 * Get Favicon URL
 */
export const getFavIconUrl = (href: string): Href => {
	const { origin } = new URL(href.trim(), FALLBACK_HOST_NAME);
	const iconUrl = origin === FALLBACK_HOST_NAME ? '' : `${origin}/favicon.ico`;

	return toHref(iconUrl);
};

/**
 * Validate that the url starts with (http|https) protocols only.
 */
export const validateUrl = (url: string) => {
	const sanitisedUrl = url.trim();

	// Obtain the protocol that precedes ://
	const urlMatch = sanitisedUrl.match(/^.+:\/\//);
	const protocol = urlMatch ? urlMatch[0] : urlMatch;

	const minUrlLength = 2;

	// Only allow no-protocol AND http:// AND https:// protocols as valid url links
	if (![null, 'http://', 'https://'].includes(protocol)) {
		return false;
	}

	// Check the URL length (characters after protocol is minUrlLength or greater).
	if (sanitisedUrl.length < minUrlLength + (protocol?.length || 0)) {
		return false;
	}

	return true;
};

export const prependHttp = (url: string) => {
	// Check if the URL already starts with http:// OR https://
	if (/^https?:\/\//.test(url)) {
		return url;
	}

	return `http://${url}`;
};
