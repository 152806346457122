import type { ProjectType } from '@atlassian/jira-common-constants/src/index.tsx';
import {
	CORE_PROJECT,
	SOFTWARE_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import type { State as ReduxState } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { useLoomTouchpointVariant } from '@atlassian/jira-loom-utils/src/controllers/use-loom-touchpoint-variant/index.tsx';
import { LoomTouchpointVariant } from '@atlassian/jira-loom-utils/src/controllers/use-loom-touchpoint-variant/types.tsx';
import { useSelector } from '@atlassian/jira-react-redux/src/index.tsx';

const SUPPORTED_JIRA_PROJECT_TYPES: ProjectType[] = [CORE_PROJECT, SOFTWARE_PROJECT];

export const useLoomTouchpointWithProjectType = () => {
	const { loomTouchpointVariant } = useLoomTouchpointVariant();

	const project = useSelector((state: ReduxState) => state.entities?.project);
	const projectType = project?.projectType;

	const isSupportedProjectType = projectType && SUPPORTED_JIRA_PROJECT_TYPES.includes(projectType);
	return {
		loomTouchpointVariant: isSupportedProjectType
			? loomTouchpointVariant
			: LoomTouchpointVariant.NONE,
	};
};
