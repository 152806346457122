import React from 'react';
import { styled } from '@compiled/react';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import {
	isLoadingSelector,
	errorSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';
import { ShareLoadingPlaceholder } from '@atlassian/jira-share-button/src/index.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import Share from '../../share/index.tsx';

type Props = {
	isLoading: boolean;
	error: string | null;
};

const ShareButtonItem = ({ error = null, isLoading }: Props) => {
	const loggedInAccountId = useAccountId();

	return !error && loggedInAccountId ? (
		<ShareWrapper>
			{isLoading ? <ShareLoadingPlaceholder triggerButtonStyle="icon-only" /> : <Share />}
		</ShareWrapper>
	) : null;
};

export const ShareButton = flowWithSafeComponent(
	connect(
		(state) => ({
			isLoading: isLoadingSelector(state),
			error: errorSelector(state),
		}),
		{},
	),
)(ShareButtonItem);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ShareWrapper = styled.div({
	flexShrink: 0,
});
