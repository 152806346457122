import { JiraProjectAri } from '@atlassian/ari/jira/project';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useProjectId, useProjectKey } from '@atlassian/jira-project-context-service/src/main.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';

export const useProjectAri = () => {
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const projectId = useProjectId(projectKey);
	const siteId = useCloudId();
	const projectAri =
		projectId &&
		JiraProjectAri.create({
			siteId,
			projectId,
		}).toString();
	return projectAri || '';
};
