import React from 'react';
import { Flex } from '@atlaskit/primitives';
import ChangesIcon from '@atlaskit/icon/core/changes';
import { useIssueTypeAvatarId } from '@atlassian/jira-polaris-component-issue-types/src/controllers/index.tsx';
import { IssueTypeAvatar } from '@atlassian/jira-polaris-component-issue-types-manager/src/ui/issue-type-avatar.tsx';
import {
	isIssueTypeIdFilter,
	type ConnectionFieldIssueTypeFilter,
} from '@atlassian/jira-polaris-domain-field/src/field/connection/types.tsx';

type Props = {
	issueTypeFilters?: ConnectionFieldIssueTypeFilter[];
};

export const ConnectionFieldIcon = ({ issueTypeFilters = [] }: Props) => {
	const issueTypeIds = issueTypeFilters.find(isIssueTypeIdFilter);
	const avatarId = useIssueTypeAvatarId({ issueTypeId: issueTypeIds?.ids[0] ?? '' });

	if (!issueTypeIds?.ids.length || !avatarId) {
		// eslint-disable-next-line jira/i18n/text-wrapped-in-format-message
		return <ChangesIcon label="connection" color="var(--ds-icon)" />;
	}

	return (
		<Flex alignItems="center">
			<IssueTypeAvatar avatarId={avatarId} size="xsmall" />
		</Flex>
	);
};
