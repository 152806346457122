/**
 * @generated SignedSource<<f20e9e896aff2f32a67d0b2c33b2ea08>>
 * @relayHash 3e9cd3f4f8a3e2ca4c7e8f868ef8096f
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 5e407496765da7eb004622686d678d6984436e5b576fa7ba9f7dfc75c89fef6f

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraConfigStateConfigurationStatus = "CONFIGURED" | "NOT_CONFIGURED" | "NOT_INSTALLED" | "NOT_SET" | "PARTIALLY_CONFIGURED" | "PROVIDER_ACTION_CONFIGURED" | "PROVIDER_ACTION_NOT_CONFIGURED" | "%future added value";
export type mainDevelopmentSummarySecurityFieldQuery$variables = {
  cloudId: string;
  isNotSiteAdmin: boolean;
  issueAri: string;
  projectAri: string;
};
export type mainDevelopmentSummarySecurityFieldQuery$data = {
  readonly devOps: {
    readonly ariGraph: {
      readonly hasRelationship?: boolean | null | undefined;
      readonly relationships: {
        readonly nodes: ReadonlyArray<{
          readonly from: {
            readonly data: {
              readonly " $fragmentSpreads": FragmentRefs<"main_developmentSummaryVulnerability_Vulnerability">;
            };
          };
        } | null | undefined> | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly jira?: {
    readonly devOps: {
      readonly configStates: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly config: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly status: JiraConfigStateConfigurationStatus | null | undefined;
                } | null | undefined;
              }> | null | undefined;
            } | null | undefined;
            readonly id: string;
          } | null | undefined;
        }> | null | undefined;
      } | null | undefined;
      readonly isInContextConfigPromptDismissed: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type mainDevelopmentSummarySecurityFieldQuery = {
  response: mainDevelopmentSummarySecurityFieldQuery$data;
  variables: mainDevelopmentSummarySecurityFieldQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isNotSiteAdmin"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueAri"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectAri"
},
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  },
  {
    "kind": "Variable",
    "name": "to",
    "variableName": "issueAri"
  },
  {
    "kind": "Literal",
    "name": "type",
    "value": "vulnerability-associated-issue"
  }
],
v5 = {
  "condition": "isNotSiteAdmin",
  "kind": "Condition",
  "passingValue": false,
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "from",
          "variableName": "projectAri"
        },
        {
          "kind": "Literal",
          "name": "type",
          "value": "project-associated-vulnerability"
        }
      ],
      "kind": "ScalarField",
      "name": "hasRelationship"
    }
  ]
},
v6 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v7 = {
  "kind": "ScalarField",
  "name": "id"
},
v8 = {
  "kind": "ScalarField",
  "name": "status"
},
v9 = {
  "args": [
    (v6/*: any*/),
    {
      "kind": "Literal",
      "name": "providerTypeFilter",
      "value": [
        "SECURITY"
      ]
    }
  ],
  "concreteType": "JiraAppConfigStateConnection",
  "kind": "LinkedField",
  "name": "configStates",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraAppConfigStateEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraAppConfigState",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v7/*: any*/),
            {
              "concreteType": "JiraConfigStateConnection",
              "kind": "LinkedField",
              "name": "config",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraConfigStateEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "concreteType": "JiraConfigState",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v8/*: any*/)
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
},
v10 = {
  "args": [
    (v6/*: any*/),
    {
      "kind": "Literal",
      "name": "location",
      "value": "SECURITY_PANEL"
    }
  ],
  "kind": "ScalarField",
  "name": "isInContextConfigPromptDismissed"
},
v11 = [
  (v7/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "name": "mainDevelopmentSummarySecurityFieldQuery",
    "selections": [
      {
        "concreteType": "DevOps",
        "kind": "LinkedField",
        "name": "devOps",
        "plural": false,
        "selections": [
          {
            "concreteType": "AriGraph",
            "kind": "LinkedField",
            "name": "ariGraph",
            "plural": false,
            "selections": [
              {
                "args": (v4/*: any*/),
                "concreteType": "AriGraphRelationshipConnection",
                "kind": "LinkedField",
                "name": "relationships",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "AriGraphRelationship",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "AriGraphRelationshipNode",
                        "kind": "LinkedField",
                        "name": "from",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "RequiredField",
                            "field": {
                              "kind": "LinkedField",
                              "name": "data",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "InlineFragment",
                                  "selections": [
                                    {
                                      "kind": "FragmentSpread",
                                      "name": "main_developmentSummaryVulnerability_Vulnerability"
                                    }
                                  ],
                                  "type": "DevOpsSecurityVulnerabilityDetails"
                                }
                              ]
                            },
                            "action": "THROW",
                            "path": "devOps.ariGraph.relationships.nodes.from.data"
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              (v5/*: any*/)
            ]
          }
        ]
      },
      {
        "condition": "isNotSiteAdmin",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "concreteType": "JiraQuery",
            "kind": "LinkedField",
            "name": "jira",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraDevOpsQuery",
                "kind": "LinkedField",
                "name": "devOps",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  {
                    "kind": "RequiredField",
                    "field": (v10/*: any*/),
                    "action": "THROW",
                    "path": "jira.devOps.isInContextConfigPromptDismissed"
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "mainDevelopmentSummarySecurityFieldQuery",
    "selections": [
      {
        "concreteType": "DevOps",
        "kind": "LinkedField",
        "name": "devOps",
        "plural": false,
        "selections": [
          {
            "concreteType": "AriGraph",
            "kind": "LinkedField",
            "name": "ariGraph",
            "plural": false,
            "selections": [
              {
                "args": (v4/*: any*/),
                "concreteType": "AriGraphRelationshipConnection",
                "kind": "LinkedField",
                "name": "relationships",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "AriGraphRelationship",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "AriGraphRelationshipNode",
                        "kind": "LinkedField",
                        "name": "from",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "name": "data",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "__typename"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v7/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "name": "title"
                                  },
                                  (v8/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "name": "url"
                                  }
                                ],
                                "type": "DevOpsSecurityVulnerabilityDetails"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": (v11/*: any*/),
                                "type": "Node",
                                "abstractKey": "__isNode"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": (v11/*: any*/),
                                "type": "ConfluencePage"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": (v11/*: any*/),
                                "type": "ConfluenceSpace"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": (v11/*: any*/),
                                "type": "DevOpsFeatureFlag"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": (v11/*: any*/),
                                "type": "DevOpsOperationsComponentDetails"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": (v11/*: any*/),
                                "type": "DevOpsOperationsIncidentDetails"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": (v11/*: any*/),
                                "type": "DevOpsOperationsPostIncidentReviewDetails"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": (v11/*: any*/),
                                "type": "DevOpsPullRequestDetails"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": (v11/*: any*/),
                                "type": "DevOpsRepository"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": (v11/*: any*/),
                                "type": "JiraConfluenceRemoteIssueLink"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": (v11/*: any*/),
                                "type": "JiraCustomRemoteIssueLink"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": (v11/*: any*/),
                                "type": "JiraIssueRemoteIssueLink"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": (v11/*: any*/),
                                "type": "JiraWebRemoteIssueLink"
                              }
                            ]
                          },
                          (v7/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              },
              (v5/*: any*/)
            ]
          }
        ]
      },
      {
        "condition": "isNotSiteAdmin",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "concreteType": "JiraQuery",
            "kind": "LinkedField",
            "name": "jira",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraDevOpsQuery",
                "kind": "LinkedField",
                "name": "devOps",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "5e407496765da7eb004622686d678d6984436e5b576fa7ba9f7dfc75c89fef6f",
    "metadata": {},
    "name": "mainDevelopmentSummarySecurityFieldQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "d81da7370785ec9d986e8ac15dcb40e4";

export default node;
