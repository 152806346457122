import { defineMessages } from 'react-intl-next';
export const alignmentMessages = defineMessages({
	alignment: {
		id: 'fabric.editor.alignment',
		defaultMessage: 'Text alignment',
		description: 'Opens drop down menu of options to configure text alignment',
	},
	alignLeft: {
		id: 'fabric.editor.alignLeft',
		defaultMessage: 'Align left',
		description: 'label stating that text is aligned left',
	},
	alignCenter: {
		id: 'fabric.editor.alignCenter',
		defaultMessage: 'Align center',
		description: 'label stating that text is aligned center',
	},
	alignRight: {
		id: 'fabric.editor.alignRight',
		defaultMessage: 'Align right',
		description: 'label stating that text is aligned right',
	},
});
