import type { UserOption } from '@atlassian/jira-issue-user-picker/src/types.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const toItem = (assignee: any): UserOption => ({
	avatarUrl: assignee.avatarUrls && assignee.avatarUrls['48x48'],
	id: assignee.accountId,
	name: assignee.displayName,
	byline: assignee.emailAddress,
	active: assignee.active,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const transformFromStateValue = (value: any) => (value ? toItem(value) : null);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const toState = (value: any) => ({
	avatarUrls: {
		'48x48': value.avatarUrl,
	},
	displayName: value.name,
	accountId: value.id,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const transformToStateValue = (value: any) => (value ? toState(value) : null);
