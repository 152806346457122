import React from 'react';
import { APP_BUNDLE_READY, PREFETCH_API_READY } from './constants.tsx';
import SetCustomMark from './set-custom-mark/index.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { default as ApdexProvider } from './provider';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { default as SubmitApdex } from './submit-apdex-mark';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { default as SetCustomMark } from './set-custom-mark';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { default as ApdexActions } from './apdex-actions';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { afterPaintEmit } from './after-paint-emit';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { Props as SubmitApdexProps } from './submit-apdex-mark';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { BM3Metric, BM3Config } from './submit-apdex-mark/types';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { addBM3SSRDoneAsFMPToInteraction } from './submit-apdex-mark/utils/add-bm3-config-to-interaction';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { addUFOSSRDoneAsFMPToInteraction } from './submit-apdex-mark/utils/add-ufo-config-to-interaction';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { SPA_APP_START } from './constants';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { TTI_RAF_MARK, TTI_RAF_KEY } from './constants';

export const SetBootstrapBundleReadyMark = () => <SetCustomMark name="appBootstrapBundleReady" />;
export const SetBundleReadyMark = () => <SetCustomMark name={APP_BUNDLE_READY} />;
export const SetApiReadyMark = () => <SetCustomMark name={PREFETCH_API_READY} />;
