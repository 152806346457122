import React, { useEffect, useState } from 'react';
import { styled } from '@compiled/react';
import { type PreloadedQuery, usePreloadedQuery, graphql, useQueryLoader } from 'react-relay';
import { metrics } from '@atlassian/browser-metrics';
import coinflip from '@atlassian/jira-coinflip/src/index.tsx';
import { SECURITY_KNOWN_APPS } from '@atlassian/jira-development-app-configuration-prompt/src/common/constants.tsx';
import { AppConfigurationPromptSummary } from '@atlassian/jira-development-summary-app-config-prompt-field/src/ui/index.tsx';
import {
	VIEW_SECURITY_FIELD_IN_THE_ISSUE_VIEW,
	useSecurityFieldInTheIssueViewExperiencesFailed,
	ViewSecurityFieldInTheIssueViewExperienceStart,
	ViewSecurityFieldInTheIssueViewSuccessTracker,
} from '@atlassian/jira-development-summary-vulnerability/src/services/experience-tracker/index.tsx';
import { Vulnerability } from '@atlassian/jira-development-summary-vulnerability/src/ui/main.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	FieldWrapper,
	SideBySideField,
	FieldHeadingTitle,
	FieldHeadingSideBySide,
} from '@atlassian/jira-issue-field-heading/src/styled.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { FireUiAnalytics } from '@atlassian/jira-product-analytics-bridge';
import SecurityFieldQuery, {
	type mainDevelopmentSummarySecurityFieldQuery,
} from '@atlassian/jira-relay/src/__generated__/mainDevelopmentSummarySecurityFieldQuery.graphql';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useIsSiteAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-site-admin/index.tsx';
import { LazySuspense } from '@atlassian/react-loosely-lazy';
import messages from './messages.tsx';
import { useProjectAri } from './utils.tsx';

const getExperienceTrackerSamplingRate = () => 50;

const securityFieldTimeToLoad = metrics.interaction({
	key: 'security-field',
	// @ts-expect-error - TS2741 - Property '[BMInteractionMetrics.response]' is missing in type '{ result: string; }' but required in type 'InteractionHistogramConfig'.
	histogram: {
		result: '300_1000_2500_4000_7000_10000',
	},
});

function SecurityFieldContents({
	queryRef,
}: {
	queryRef: PreloadedQuery<mainDevelopmentSummarySecurityFieldQuery>;
}) {
	useEffect(() => {
		securityFieldTimeToLoad.stop();
	}, []);

	const data = usePreloadedQuery<mainDevelopmentSummarySecurityFieldQuery>(
		graphql`
			query mainDevelopmentSummarySecurityFieldQuery(
				$issueAri: ID!
				$projectAri: ID!
				$cloudId: ID!
				$isNotSiteAdmin: Boolean!
			) {
				devOps {
					ariGraph {
						relationships(type: "vulnerability-associated-issue", to: $issueAri, first: 100) {
							nodes {
								from {
									data @required(action: THROW) {
										... on DevOpsSecurityVulnerabilityDetails {
											...main_developmentSummaryVulnerability_Vulnerability
										}
									}
								}
							}
						}
						hasRelationship(type: "project-associated-vulnerability", from: $projectAri)
							@skip(if: $isNotSiteAdmin)
					}
				}
				jira @skip(if: $isNotSiteAdmin) {
					devOps {
						configStates(cloudId: $cloudId, providerTypeFilter: [SECURITY])
							@optIn(to: "Jira-config-states-by-provider") {
							edges {
								node {
									id
									config {
										edges {
											node {
												status
											}
										}
									}
								}
							}
						}
						isInContextConfigPromptDismissed(cloudId: $cloudId, location: SECURITY_PANEL)
							@required(action: THROW)
					}
				}
			}
		`,
		queryRef,
	);

	const { formatMessage } = useIntl();
	const appEdges = data.jira?.devOps?.configStates?.edges ?? [];
	const isInContextConfigPromptDismissed =
		data.jira?.devOps?.isInContextConfigPromptDismissed || false;

	const unconfiguredDisplayableEdges = isInContextConfigPromptDismissed
		? []
		: appEdges
				// Known apps filter
				.filter((edge) => SECURITY_KNOWN_APPS.includes(edge.node?.id ?? ''))
				// UNCONFIGURED filter
				.filter(
					(edge) =>
						(edge.node?.config?.edges ?? [])
							.map((configEdge) => configEdge.node)
							// finding apps that have only workspaces whose states are either NOT_SET, NOT_CONFIGURED or PROVIDER_ACTION_CONFIGURED
							.find(
								(node) =>
									node?.status === 'CONFIGURED' || node?.status === 'PROVIDER_ACTION_CONFIGURED',
							) === undefined,
				)
				.filter(Boolean);

	const vulnRelationshipList = data.devOps?.ariGraph?.relationships?.nodes ?? [];
	const vulnDataList =
		vulnRelationshipList.flatMap((vuln) => (vuln?.from.data ? [vuln.from.data] : [])) ?? [];
	const shouldDisplayInContextPrompt =
		data.devOps?.ariGraph?.hasRelationship === false && unconfiguredDisplayableEdges.length > 0;

	return (
		<>
			{(vulnDataList.length > 0 || shouldDisplayInContextPrompt) && (
				<>
					<FireUiAnalytics action="viewed" actionSubject="securityFieldInIssueView" />
					<FieldWrapper>
						<SecurityFieldHeading>
							<FieldHeadingTitle>
								{formatMessage(messages.securityFieldHeadingTitle)}
							</FieldHeadingTitle>
						</SecurityFieldHeading>
						<SideBySideField>
							{shouldDisplayInContextPrompt && (
								<AppConfigurationPromptSummary location="SECURITY_PANEL" />
							)}
							{vulnDataList.length > 0 && <Vulnerability vulnerabilityData={vulnDataList} />}
						</SideBySideField>
					</FieldWrapper>
				</>
			)}
			<ViewSecurityFieldInTheIssueViewSuccessTracker
				experienceName={VIEW_SECURITY_FIELD_IN_THE_ISSUE_VIEW}
				hasVulnerabilities={vulnRelationshipList.length !== 0}
			/>
		</>
	);
}

export type Props = {
	readonly issueAri: string;
};

export function SecurityField({ issueAri }: Props) {
	const cloudId = useCloudId();
	const projectAri = useProjectAri();
	const isSiteAdmin = useIsSiteAdmin();
	const [queryRef, loadQuery] =
		useQueryLoader<mainDevelopmentSummarySecurityFieldQuery>(SecurityFieldQuery);
	useEffect(() => {
		securityFieldTimeToLoad.start();
		loadQuery({
			issueAri,
			cloudId,
			projectAri,
			isNotSiteAdmin: !isSiteAdmin,
		});
	}, [issueAri, cloudId, loadQuery, projectAri, isSiteAdmin]);

	// As the security field is rendered on the issue view,
	// we will sample this at a 1 in (ff value) rate to minimise the number of experiences.
	const [isInSample] = useState(coinflip(getExperienceTrackerSamplingRate()));
	const securityFieldInTheIssueViewExperiencesFailed =
		useSecurityFieldInTheIssueViewExperiencesFailed({
			experienceName: VIEW_SECURITY_FIELD_IN_THE_ISSUE_VIEW,
		});

	return (
		<UFOSegment name="development-summary-security-field">
			{isInSample && (
				<ViewSecurityFieldInTheIssueViewExperienceStart
					experienceName={VIEW_SECURITY_FIELD_IN_THE_ISSUE_VIEW}
					experienceId={`${VIEW_SECURITY_FIELD_IN_THE_ISSUE_VIEW}-${Date.now()}`}
				/>
			)}
			<JSErrorBoundary
				teamName="fusion-isotopes"
				packageName="jiraDevelopmentSummaryVulnerability"
				id="jiraDevelopmentSummaryVulnerability"
				sendToPrivacyUnsafeSplunk
				onError={securityFieldInTheIssueViewExperiencesFailed}
				fallback={() => <></>}
			>
				{queryRef && (
					<LazySuspense fallback={<></>}>
						<SecurityFieldContents queryRef={queryRef} />
					</LazySuspense>
				)}
			</JSErrorBoundary>
		</UFOSegment>
	);
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const SecurityFieldHeading = styled(FieldHeadingSideBySide)({
	display: 'flex',
	paddingRight: '0px',
});

export default SecurityField;
