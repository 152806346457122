import flatMap from 'lodash/flatMap';
import { memoizedHashString } from '@atlassian/jira-issue-analytics/src/utils/hash-attribute/index.tsx';
import { isGroup } from '../../../utils.tsx';
import type { Options, Option, Group } from '../types.tsx';

export const flattenOptions = (optionsOrOptionGroups: Options): Option[] =>
	flatMap(
		optionsOrOptionGroups.map((optionOrGroup) =>
			// @ts-expect-error - TS2339 - Property 'options' does not exist on type 'Option | Group'. | TS2339 - Property 'options' does not exist on type 'Option | Group'.
			isGroup(optionOrGroup) && optionOrGroup.options ? optionOrGroup.options : optionOrGroup,
		),
	);

export const filterOptions = (options: Option[], query?: string): Option[] =>
	options.filter(
		(option) =>
			(typeof option.value === 'string' &&
				option.value.toLowerCase().includes((query || '').toLowerCase())) ||
			(typeof option.label === 'string' &&
				option.label.toLowerCase().includes((query || '').toLowerCase())),
	);

export const flattenSelectedOptions = (
	selectedOptions?: ReadonlyArray<Option | Group> | Readonly<Option | Group> | null,
): ReadonlyArray<Readonly<Option>> => {
	if (selectedOptions == null) return [];

	return Array.isArray(selectedOptions) ? selectedOptions : [selectedOptions];
};

export const getSafeValue = (val: string | number, valueSafeForAnalytics = false): string =>
	valueSafeForAnalytics ? val?.toString() : memoizedHashString(val?.toString())?.toString();

export const getSafeOptionsForAnalytics = (
	items: ReadonlyArray<Readonly<Option>>,
	valuesSafeForAnalytics = false,
): string[] =>
	items
		.filter((item) => item.value)
		.map(({ value }) => getSafeValue(value, valuesSafeForAnalytics));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getOptionIdsForAnalytics = (items: ReadonlyArray<Readonly<Option>>): any[] => {
	const optionIds = items.map(({ id }) => id || null);
	return optionIds.every((x) => x === null) ? [] : optionIds;
};

export const getOptionValuesLengths = (items: ReadonlyArray<Readonly<Option>>): number[] =>
	items.filter((item) => item.value).map(({ value }) => (value != null ? `${value}`.length : 0));
