import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	loadMoreOlderComments: {
		id: 'issue-view-activity.load-more-older-comments-non-final',
		defaultMessage: 'Load more older comments',
		description:
			'The text is shown on a button when there are more older comments available to load for an issue.',
	},
	loadMoreNewerComments: {
		id: 'issue-view-activity.load-more-newer-comments-non-final',
		defaultMessage: 'Load more newer comments',
		description:
			'The text is shown on a button when there are more newer comments available to load for an issue.',
	},
});
