import React from 'react';
import type { ReadViewProps } from '../../../types.tsx';

export const SingleSelectFieldReadView = ({
	render,
	value,
	getValueContainer,
	inInlineEdit,
}: ReadViewProps) => {
	const ValueContainer = getValueContainer();
	return <ValueContainer render={render || (() => value)} noBorder={inInlineEdit} />;
};
