/**
 * @generated SignedSource<<9b630b03f283f96ef5de75aaf3c71e54>>
 * @relayHash 971e1ea24c2f6c76d44f31d2976f4b23
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 46f209fdc3e11d536fc9a978464d06968b198103fe86b1475055b549f7a25d0f

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { ui_MoreDesignActionsQuery } from './ui_MoreDesignActionsQuery.graphql';

const node: PreloadableConcreteRequest<ui_MoreDesignActionsQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "46f209fdc3e11d536fc9a978464d06968b198103fe86b1475055b549f7a25d0f",
    "metadata": {},
    "name": "ui_MoreDesignActionsQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
