import React, { type ComponentType } from 'react';
import flow from 'lodash/flow';
import { SmartCardProvider } from '@atlaskit/link-provider';
import withLinkActions from '@atlassian/jira-common-navigation/src/with-link-actions/index.tsx';
import withMediaProvider from '@atlassian/jira-issue-media-provider/src/controllers/with-media-provider/index.tsx';
import {
	useSmartCardProductType,
	useSmartCardIsAIEnabled,
} from '@atlassian/jira-linking-platform-utils/src/index.tsx';
import { useProfileCardProvider } from '@atlassian/jira-profilecard-provider/src/main.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import type { ContainerProps, WrappedProps } from './types.tsx';
import RendererView from './view.tsx';

const WrappedRendererView: ComponentType<WrappedProps> = flow(
	withMediaProvider,
	withLinkActions,
)(RendererView);

const Renderer = (props: ContainerProps) => {
	const loggedInUserAccountId = useAccountId();
	const cloudId = useCloudId();
	const profileCardProvider = useProfileCardProvider();

	const smartCardProviderProduct = useSmartCardProductType();
	const isAIFeatureEnabled = useSmartCardIsAIEnabled();

	return (
		<SmartCardProvider product={smartCardProviderProduct} isAdminHubAIEnabled={isAIFeatureEnabled}>
			<WrappedRendererView
				{...props}
				cloudId={cloudId}
				loggedInUserAccountId={loggedInUserAccountId}
				profileCardProvider={profileCardProvider}
			/>
		</SmartCardProvider>
	);
};

export default Renderer;
