export const ATTACHMENT_NUMBER_APPROACH_LIMIT = 145;
export const ATTACHMENT_NUMBER_EXCEED_LIMIT = 150;
export const ATTACHMENT_NUMBER_EXCEEDED_LIMIT = ATTACHMENT_NUMBER_EXCEED_LIMIT + 1;
export const ATTACHMENTS_LEARN_ABOUT_LINK =
	'https://support.atlassian.com/jira-software-cloud/docs/add-an-attachment-to-an-issue/';

export const isAttachmentNumberApproachLimit = (totalNumber: number) =>
	totalNumber > ATTACHMENT_NUMBER_APPROACH_LIMIT;

export const isAttachmentNumberExceedLimit = (totalNumber: number) =>
	totalNumber > ATTACHMENT_NUMBER_EXCEED_LIMIT;

export const isAttachmentNumberOverLimit = (totalNumber: number) =>
	totalNumber > ATTACHMENT_NUMBER_EXCEEDED_LIMIT;
