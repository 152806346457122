import type { Observable as ObservableType } from 'rxjs/Observable';
import fetch from '../../rest/reorder-children-before.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	baseUrl: string,
	customFieldId: number,
	issueIds: number[],
	rankBeforeId: number,
): ObservableType<string | null> => fetch(baseUrl, customFieldId, issueIds, rankBeforeId);
