import React from 'react';

export const sliderGlyph = () => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6 5C6.55229 5 7 4.55228 7 4C7 3.44772 6.55229 3 6 3C5.44772 3 5 3.44772 5 4C5 4.55228 5.44772 5 6 5ZM6 7C7.30622 7 8.41746 6.16519 8.82929 5H15C15.5523 5 16 4.55228 16 4C16 3.44772 15.5523 3 15 3H8.82929C8.41746 1.83481 7.30622 1 6 1C4.69378 1 3.58254 1.83481 3.17071 3H1C0.447715 3 0 3.44772 0 4C0 4.55228 0.447715 5 1 5H3.17071C3.58254 6.16519 4.69378 7 6 7ZM10 11C9.44771 11 9 11.4477 9 12C9 12.5523 9.44771 13 10 13C10.5523 13 11 12.5523 11 12C11 11.4477 10.5523 11 10 11ZM10 9C8.69378 9 7.58254 9.83481 7.17071 11L1 11C0.447714 11 0 11.4477 0 12C0 12.5523 0.447715 13 1 13L7.17071 13C7.58254 14.1652 8.69378 15 10 15C11.3062 15 12.4175 14.1652 12.8293 13H15C15.5523 13 16 12.5523 16 12C16 11.4477 15.5523 11 15 11H12.8293C12.4175 9.83481 11.3062 9 10 9Z"
			fill="currentColor"
		/>
	</svg>
);
