/**
 * @generated SignedSource<<6e4a16c211f79073c03b8012cdc69510>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type dateTime_issueFieldDateTimeInlineEditFull_DateTimeInlineEditView$data = {
  readonly dateTime: AGG$DateTime | null | undefined;
  readonly fieldConfig: {
    readonly isEditable: boolean | null | undefined;
  } | null | undefined;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"dateTime_issueFieldDateTimeReadviewFull_DateTimeReadView">;
  readonly " $fragmentType": "dateTime_issueFieldDateTimeInlineEditFull_DateTimeInlineEditView";
};
export type dateTime_issueFieldDateTimeInlineEditFull_DateTimeInlineEditView$key = {
  readonly " $data"?: dateTime_issueFieldDateTimeInlineEditFull_DateTimeInlineEditView$data;
  readonly " $fragmentSpreads": FragmentRefs<"dateTime_issueFieldDateTimeInlineEditFull_DateTimeInlineEditView">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "dateTime_issueFieldDateTimeInlineEditFull_DateTimeInlineEditView",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "dateTime_issueFieldDateTimeReadviewFull_DateTimeReadView"
    },
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "kind": "ScalarField",
      "name": "dateTime"
    },
    {
      "concreteType": "JiraFieldConfig",
      "kind": "LinkedField",
      "name": "fieldConfig",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isEditable"
        }
      ]
    }
  ],
  "type": "JiraDateTimePickerField"
};

(node as any).hash = "feef7c51eb31d46e7a6dc71c98b907d9";

export default node;
