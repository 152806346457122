/**
 * @generated SignedSource<<98d0159534a545dc26127c9e3286ef09>>
 * @relayHash 8e1c7f6c9b7e2b02b56b97daa488418c
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 7fa14cdae43c83889bd3d0a2c9c8880d21257bdb818df5f5ca41a104e635ce2f

import type { ConcreteRequest, Query } from 'relay-runtime';
export type fetchUsersForWatchersFieldQuery$variables = {
  cloudId: string;
  issueKey: string;
  query: string;
};
export type fetchUsersForWatchersFieldQuery$data = {
  readonly jira: {
    readonly issueByKey: {
      readonly watchesField: {
        readonly suggestedWatchers?: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly accountId: string;
              readonly id: string;
              readonly name: string;
              readonly picture: AGG$URL;
            } | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type fetchUsersForWatchersFieldQuery = {
  response: fetchUsersForWatchersFieldQuery$data;
  variables: fetchUsersForWatchersFieldQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "issueKey"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "query"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "kind": "Variable",
    "name": "key",
    "variableName": "issueKey"
  }
],
v2 = [
  {
    "kind": "Literal",
    "name": "idOrAlias",
    "value": "watches"
  }
],
v3 = [
  {
    "kind": "Variable",
    "name": "searchBy",
    "variableName": "query"
  }
],
v4 = {
  "kind": "ScalarField",
  "name": "id"
},
v5 = {
  "kind": "ScalarField",
  "name": "accountId"
},
v6 = {
  "kind": "ScalarField",
  "name": "name"
},
v7 = {
  "kind": "ScalarField",
  "name": "picture"
},
v8 = {
  "kind": "ScalarField",
  "name": "__typename"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "fetchUsersForWatchersFieldQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraIssue",
            "kind": "LinkedField",
            "name": "issueByKey",
            "plural": false,
            "selections": [
              {
                "alias": "watchesField",
                "args": (v2/*: any*/),
                "kind": "LinkedField",
                "name": "fieldByIdOrAlias",
                "plural": false,
                "selections": [
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "args": (v3/*: any*/),
                        "concreteType": "JiraUserConnection",
                        "kind": "LinkedField",
                        "name": "suggestedWatchers",
                        "plural": false,
                        "selections": [
                          {
                            "concreteType": "JiraUserEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v4/*: any*/),
                                  (v5/*: any*/),
                                  (v6/*: any*/),
                                  (v7/*: any*/)
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ],
                    "type": "JiraWatchesField"
                  }
                ],
                "storageKey": "fieldByIdOrAlias(idOrAlias:\"watches\")"
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "fetchUsersForWatchersFieldQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraIssue",
            "kind": "LinkedField",
            "name": "issueByKey",
            "plural": false,
            "selections": [
              {
                "alias": "watchesField",
                "args": (v2/*: any*/),
                "kind": "LinkedField",
                "name": "fieldByIdOrAlias",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "args": (v3/*: any*/),
                        "concreteType": "JiraUserConnection",
                        "kind": "LinkedField",
                        "name": "suggestedWatchers",
                        "plural": false,
                        "selections": [
                          {
                            "concreteType": "JiraUserEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v8/*: any*/),
                                  (v4/*: any*/),
                                  (v5/*: any*/),
                                  (v6/*: any*/),
                                  (v7/*: any*/)
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ],
                    "type": "JiraWatchesField"
                  },
                  (v4/*: any*/)
                ],
                "storageKey": "fieldByIdOrAlias(idOrAlias:\"watches\")"
              },
              (v4/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "7fa14cdae43c83889bd3d0a2c9c8880d21257bdb818df5f5ca41a104e635ce2f",
    "metadata": {},
    "name": "fetchUsersForWatchersFieldQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "ae8c726316444040dc54af979a81ba53";

export default node;
