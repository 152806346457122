export const EVENT_DEBOUNCE_WAIT_MS = 2000;

export const INSIGHT_CREATED_EVENT = 'avi:polaris:created:insight' as const;
export const INSIGHT_DELETED_EVENT = 'avi:polaris:deleted:insight' as const;
export const INSIGHT_UPDATED_EVENT = 'avi:polaris:updated:insight' as const;

export const PLAY_CONTRIBUTION_CREATED_EVENT = 'avi:polaris:created:contribution' as const;
export const PLAY_CONTRIBUTION_DELETED_EVENT = 'avi:polaris:deleted:contribution' as const;
export const PLAY_CONTRIBUTION_UPDATED_EVENT = 'avi:polaris:updated:contribution' as const;

export const PLAY_UPDATED_EVENT = 'avi:polaris:updated:play' as const;

export const POLARIS_SUBSCRIPTION_EVENTS = [
	INSIGHT_CREATED_EVENT,
	INSIGHT_DELETED_EVENT,
	INSIGHT_UPDATED_EVENT,
	PLAY_CONTRIBUTION_CREATED_EVENT,
	PLAY_CONTRIBUTION_DELETED_EVENT,
	PLAY_CONTRIBUTION_UPDATED_EVENT,
	PLAY_UPDATED_EVENT,
] as const;
