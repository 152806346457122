/** @jsx jsx */
import React, {
	Component,
	type KeyboardEvent,
	type ChangeEvent,
	type PropsWithChildren,
} from 'react';
import { css, jsx } from '@compiled/react';
// eslint-disable-next-line jira/restricted/styled-components-migration, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';
import noop from 'lodash/noop';
import AutosizeTextArea from 'react-autosize-textarea';
import { fg } from '@atlassian/jira-feature-gating';

// Designed to be used as the edit view for AkFieldBase.
// Can be removed once Atlaskit provides a suitable alternative.
// eslint-disable-next-line @typescript-eslint/no-explicit-any,  @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TextAreaWrapperOld = styled.div<any>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	textarea: {
		display: 'block',
		width: '100%',
		margin: 0,
		padding: 0,
		border: 'none',
		overflow: 'auto',
		outline: 'none',
		boxShadow: 'none',
		background: 'transparent',
		wordWrap: 'break-word',
		resize: 'none',
		color: 'inherit',
		font: 'inherit',
	},
});

const TextAreaWrapper = ({ isDisabled, children }: PropsWithChildren<{ isDisabled: boolean }>) => {
	return fg('jfp-1020-use-compiled') ? (
		<div css={containerStyles}>{children}</div>
	) : (
		<TextAreaWrapperOld isDisabled={isDisabled}>{children}</TextAreaWrapperOld>
	);
};

const containerStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	textarea: {
		display: 'block',
		width: '100%',
		margin: 0,
		padding: 0,
		border: 'none',
		overflow: 'auto',
		outline: 'none',
		boxShadow: 'none',
		background: 'transparent',
		wordWrap: 'break-word',
		resize: 'none',
		color: 'inherit',
		font: 'inherit',
	},
});

export type Props = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	value: string | null | undefined | any | null | undefined;
	onChange: (arg1: string) => void;
	isDisabled: boolean;
	shouldAutoFocus: boolean;
	onKeyDown: (arg1: KeyboardEvent<HTMLElement>) => void;
	isRequired?: boolean;
};

// eslint-disable-next-line jira/react/no-class-components
export default class TextArea extends Component<Props> {
	static defaultProps = {
		isDisabled: false,
		shouldAutoFocus: false,
		onKeyDown: noop,
	};

	onChange = (event: ChangeEvent<HTMLInputElement>) => this.props.onChange(event.target.value);

	render = () => (
		<TextAreaWrapper isDisabled={this.props.isDisabled}>
			<AutosizeTextArea
				{...this.props}
				value={this.props.value}
				// @ts-expect-error - TS2769 - No overload matches this call.
				onChange={this.onChange}
				onKeyDown={this.props.onKeyDown}
				disabled={this.props.isDisabled}
				autoFocus={this.props.shouldAutoFocus}
				{...(this.props.isRequired ? { 'aria-required': 'true' } : {})}
			/>
		</TextAreaWrapper>
	);
}
