import {
	type ActivityClient,
	createActivityClient,
	type ActivityItem as RWActivityItem,
} from '@atlassian/recent-work-client';

import { type ActivityItem, type ActivityProvider, type ActivityResponse } from '../types';

import { ActivityError } from './error';

/**
 * Currently the underlying FE service can only retrieve the first page of results, which is limited at 100 results.
 * See ACT-3418 for upcoming fix.
 */
const DEFAULT_ACTIVITY_COUNT = 100;

/**
 * @deprecated - Please use @atlassian/recent-work-client instead
 */
export default class ActivityResource implements ActivityProvider {
	private readonly cloudId: string;
	private readonly client: ActivityClient;
	private recentPromise?: Promise<ActivityResponse>;

	/**
	 *
	 * @param url
	 * @param cloudId - this will be transformed into an ARI
	 * @param options - @deprecated this is no longer used
	 */
	constructor(url: string, cloudId: string, options: RequestInit = {}) {
		this.cloudId = `ari:cloud:platform::site/${cloudId}`;
		this.client = createActivityClient('v3', 'editor', undefined, url);
	}

	public async getRecentItems(limit: number = DEFAULT_ACTIVITY_COUNT): Promise<ActivityItem[]> {
		if (!this.recentPromise) {
			this.recentPromise = this.client.fetchActivities(
				[50],
				{ rootContainerIds: [this.cloudId], limit },
				['viewed'],
			);
		}

		try {
			const response = await this.recentPromise;
			return response.viewed
				.map<ActivityItem>((node: RWActivityItem) => ({
					objectId: node.object.id,
					name: node.object.name || '',
					container: this.getContainerName(node.containers),
					url: node.object.url,
					iconUrl:
						node.object.icon?.type === 'EMOJI' && node.object.icon.fallback
							? node.object.icon.fallback
							: node.object.icon?.value || '',
					type: node.object.type,
					viewedTimestamp: node.timestamp,
				}))
				.filter((item) => Boolean(item.name));
		} catch (err: any) {
			throw new ActivityError(err.message, err.code);
		}
	}

	public async searchRecent(query: string): Promise<ActivityItem[]> {
		const items = await this.getRecentItems();
		return this.filterItems(items, query);
	}

	/**
	 * It should return the closet container's name
	 */
	private getContainerName(containers: RWActivityItem['containers']): string {
		for (let i = containers.length - 1; i >= 0; --i) {
			const containerName = containers[i].name;
			if (containerName) {
				return containerName;
			}
		}
		return '';
	}

	private filterItems(items: ActivityItem[], searchTerm: string): ActivityItem[] {
		if (!searchTerm) {
			return [];
		}

		return items.filter((item: ActivityItem) => {
			return item.name?.toLowerCase()?.includes(searchTerm.toLowerCase());
		});
	}
}
