/**
 * @generated SignedSource<<f42a8fbcec6fafecc60c3944837dce53>>
 * @relayHash abf08d9536a3e58c304cd22770c7001a
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 5c9654e6d65e340f30c81170173decc752df36b135a196ec3a85b156f3b5d244

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type JiraStatusCategoryColor = "BLUE_GRAY" | "BROWN" | "GREEN" | "MEDIUM_GRAY" | "WARM_RED" | "YELLOW" | "%future added value";
export type JiraUpdateStatusFieldInput = {
  id: string;
  statusTransitionId: number;
};
export type useStatusMutation$variables = {
  input: JiraUpdateStatusFieldInput;
};
export type useStatusMutation$data = {
  readonly jira: {
    readonly updateStatusByQuickTransition: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly id: string;
        readonly status: {
          readonly description: string | null | undefined;
          readonly id: string;
          readonly name: string | null | undefined;
          readonly statusCategory: {
            readonly colorName: JiraStatusCategoryColor | null | undefined;
            readonly id: string;
            readonly key: string | null | undefined;
            readonly name: string | null | undefined;
            readonly statusCategoryId: string;
          } | null | undefined;
          readonly statusId: string;
        };
        readonly transitions: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly hasScreen: boolean | null | undefined;
              readonly isAvailable: boolean | null | undefined;
              readonly isGlobal: boolean | null | undefined;
              readonly isInitial: boolean | null | undefined;
              readonly isLooped: boolean | null | undefined;
              readonly name: string | null | undefined;
              readonly transitionId: number | null | undefined;
            } | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type useStatusMutation$rawResponse = {
  readonly jira: {
    readonly updateStatusByQuickTransition: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly id: string;
        readonly status: {
          readonly description: string | null | undefined;
          readonly id: string;
          readonly name: string | null | undefined;
          readonly statusCategory: {
            readonly colorName: JiraStatusCategoryColor | null | undefined;
            readonly id: string;
            readonly key: string | null | undefined;
            readonly name: string | null | undefined;
            readonly statusCategoryId: string;
          } | null | undefined;
          readonly statusId: string;
        };
        readonly transitions: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly hasScreen: boolean | null | undefined;
              readonly id: string;
              readonly isAvailable: boolean | null | undefined;
              readonly isGlobal: boolean | null | undefined;
              readonly isInitial: boolean | null | undefined;
              readonly isLooped: boolean | null | undefined;
              readonly name: string | null | undefined;
              readonly transitionId: number | null | undefined;
            } | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type useStatusMutation = {
  rawResponse: useStatusMutation$rawResponse;
  response: useStatusMutation$data;
  variables: useStatusMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "success"
},
v3 = {
  "concreteType": "MutationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "message"
    }
  ]
},
v4 = {
  "kind": "ScalarField",
  "name": "id"
},
v5 = {
  "kind": "ScalarField",
  "name": "name"
},
v6 = {
  "concreteType": "JiraStatus",
  "kind": "LinkedField",
  "name": "status",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    {
      "kind": "ScalarField",
      "name": "statusId"
    },
    (v5/*: any*/),
    {
      "kind": "ScalarField",
      "name": "description"
    },
    {
      "concreteType": "JiraStatusCategory",
      "kind": "LinkedField",
      "name": "statusCategory",
      "plural": false,
      "selections": [
        (v4/*: any*/),
        {
          "kind": "ScalarField",
          "name": "colorName"
        },
        {
          "kind": "ScalarField",
          "name": "statusCategoryId"
        },
        {
          "kind": "ScalarField",
          "name": "key"
        },
        (v5/*: any*/)
      ]
    }
  ]
},
v7 = {
  "kind": "ScalarField",
  "name": "transitionId"
},
v8 = {
  "kind": "ScalarField",
  "name": "isAvailable"
},
v9 = {
  "kind": "ScalarField",
  "name": "hasScreen"
},
v10 = {
  "kind": "ScalarField",
  "name": "isGlobal"
},
v11 = {
  "kind": "ScalarField",
  "name": "isLooped"
},
v12 = {
  "kind": "ScalarField",
  "name": "isInitial"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "useStatusMutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraStatusFieldPayload",
            "kind": "LinkedField",
            "name": "updateStatusByQuickTransition",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraStatusField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v6/*: any*/),
                  {
                    "concreteType": "JiraTransitionConnection",
                    "kind": "LinkedField",
                    "name": "transitions",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraTransitionEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "concreteType": "JiraTransition",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v7/*: any*/),
                              (v5/*: any*/),
                              (v8/*: any*/),
                              (v9/*: any*/),
                              (v10/*: any*/),
                              (v11/*: any*/),
                              (v12/*: any*/)
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useStatusMutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraStatusFieldPayload",
            "kind": "LinkedField",
            "name": "updateStatusByQuickTransition",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraStatusField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v6/*: any*/),
                  {
                    "concreteType": "JiraTransitionConnection",
                    "kind": "LinkedField",
                    "name": "transitions",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraTransitionEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "concreteType": "JiraTransition",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v7/*: any*/),
                              (v5/*: any*/),
                              (v8/*: any*/),
                              (v9/*: any*/),
                              (v10/*: any*/),
                              (v11/*: any*/),
                              (v12/*: any*/),
                              (v4/*: any*/)
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "5c9654e6d65e340f30c81170173decc752df36b135a196ec3a85b156f3b5d244",
    "metadata": {},
    "name": "useStatusMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "43ec4cc0ec8b30eb953d649bfd35c8a6";

export default node;
