import React, { useCallback } from 'react';
import { Text, Stack } from '@atlaskit/primitives';
import { useAIEventsInstrumentation } from '@atlassian/ai-analytics';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { ENTRYPOINT_ID_JIRA_PLATFORM } from '@atlassian/jira-feedback-collector/src/constants.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { DwellWrapper } from '@atlassian/jira-servicedesk-ai-context-common/src/ui/dwell-wrapper/index.tsx';
import { useVisibleActions } from '@atlassian/jira-servicedesk-ai-context-common/src/controllers/useVisibleActions.tsx';
import { UpdateAssignee } from '@atlassian/jira-servicedesk-ai-context-common/src/ui/actions/update-assignee/index.tsx';
import { UpdatePriority } from '@atlassian/jira-servicedesk-ai-context-common/src/ui/actions/update-priority/index.tsx';
import {
	Feedback,
	type FeedbackDirection,
} from '@atlassian/jira-servicedesk-ai-context-common/src/ui/ai-context-container/feedback/index.tsx';
import { AIContextContainer } from '@atlassian/jira-servicedesk-ai-context-common/src/ui/ai-context-container/index.tsx';
import { AIContextEscalation } from '@atlassian/jira-servicedesk-ai-context-common/src/ui/ai-context-escalation/index.tsx';
import { ActionItem } from '@atlassian/jira-servicedesk-ai-context-common/src/ui/ai-context-item/action-item/index.tsx';
import { AIContextItem } from '@atlassian/jira-servicedesk-ai-context-common/src/ui/ai-context-item/index.tsx';
import { AIContextRenderer } from '@atlassian/jira-servicedesk-ai-context-common/src/ui/ai-context-renderer/index.tsx';
import type { ActionType } from '@atlassian/jira-servicedesk-ai-context-service-panel-resources/src/services/types.tsx';
import { getAiExperienceName } from '../common/analytics.tsx';
import { FEEDBACK_GROUP_ID, AI_CONTEXT_PANEL_DWELL_DELAY } from '../common/constants.tsx';
import type { AiContextServiceResource } from '../common/types.tsx';
import messages from './messages.tsx';

export type Props = {
	resource: AiContextServiceResource;
};

export const Content = ({ resource }: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { loading, error, data, update } = resource;
	const {
		trackAIResultView,
		trackAIFeedbackSubmit,
		trackAIResultAction,
		trackAIInteractionDismiss,
	} = useAIEventsInstrumentation();
	const actions = useVisibleActions({ actions: data?.suggestedActions });

	const actionSuccessHandler = useCallback(
		(actionType: ActionType) => {
			trackAIResultAction('accept', {
				attributes: { aiExperienceName: actionType, isSuccess: true },
			});

			update((state) => {
				const updatedActions = state.suggestedActions.filter(
					(action) => action.type !== actionType,
				);
				return { ...state, suggestedActions: updatedActions };
			});
		},
		[update, trackAIResultAction],
	);

	const actionFailureHandler = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(actionType: ActionType, e: any) => {
			trackAIResultAction('accept', {
				attributes: { aiExperienceName: actionType, isSuccess: false },
			});

			const failureError = new Error(`${actionType} action failed`);
			fireErrorAnalytics({
				event: createAnalyticsEvent({}),
				error: failureError,
				meta: {
					id: `${actionType}.update`,
					packageName: 'jiraServicedeskAiContextServicePanel',
					teamName: 'JSM Agent AI',
				},
				attributes: {
					statusCode: e.statusCode,
				},
			});
		},
		[createAnalyticsEvent, trackAIResultAction],
	);

	const onPriorityUpdate = useCallback(() => {
		actionSuccessHandler('priority');
	}, [actionSuccessHandler]);

	const onPriorityUpdateError = useCallback(
		(e: unknown) => {
			actionFailureHandler('priority', e);
		},
		[actionFailureHandler],
	);

	const onAssigneeUpdate = useCallback(() => {
		actionSuccessHandler('assignee');
	}, [actionSuccessHandler]);

	const onAssigneeUpdateError = useCallback(
		(e: unknown) => {
			actionFailureHandler('assignee', e);
		},
		[actionFailureHandler],
	);

	const handleFeedbackClicked = useCallback(
		(direction: FeedbackDirection) => {
			trackAIFeedbackSubmit(direction);
		},
		[trackAIFeedbackSubmit],
	);

	const handleFeedbackSent = useCallback(
		(direction: FeedbackDirection) => {
			trackAIFeedbackSubmit(direction, { attributes: { detailedFeedback: true } });
		},
		[trackAIFeedbackSubmit],
	);

	const onPanelToggle = useCallback(
		(isOpen: boolean) => {
			// panel closed while loading
			if (!isOpen && loading) {
				trackAIInteractionDismiss();
			}

			// panel opened and data loaded
			if (isOpen && data) {
				trackAIResultView({
					doesNotMeetMAUCriteria: false,
					attributes: {
						aiExperienceName: getAiExperienceName(data),
					},
				});
			}
		},
		[data, loading, trackAIInteractionDismiss, trackAIResultView],
	);

	const handleDwellEvent = useCallback(() => {
		if (data) {
			trackAIResultView({
				doesNotMeetMAUCriteria: false,
				attributes: {
					aiExperienceName: getAiExperienceName(data),
					dwell: true,
				},
			});
		}
	}, [data, trackAIResultView]);

	return (
		<AIContextContainer
			isLoading={loading}
			error={error}
			onPanelToggle={onPanelToggle}
			attribution={formatMessage(messages.attribution)}
			groupId="ai-context-service"
			title={formatMessage(messages.contextTitle)}
		>
			<DwellWrapper delay={AI_CONTEXT_PANEL_DWELL_DELAY} onDwell={handleDwellEvent}>
				{!!data?.suggestedEscalation && (
					<AIContextEscalation>
						<AIContextRenderer document={data.suggestedEscalation} />
					</AIContextEscalation>
				)}
				{!!data?.summary && (
					<AIContextItem title={formatMessage(messages.summary)}>
						<AIContextRenderer document={data.summary} />
					</AIContextItem>
				)}
				{!!data?.reporterDetails && (
					<AIContextItem title={formatMessage(messages.reporterDetails)}>
						<AIContextRenderer document={data.reporterDetails} />
					</AIContextItem>
				)}
				{!!data?.nextSteps && (
					<AIContextItem title={formatMessage(messages.nextSteps)}>
						<AIContextRenderer document={data.nextSteps} />
					</AIContextItem>
				)}
				<AIContextItem title={formatMessage(messages.suggestedActions)}>
					{actions?.length ? (
						<Stack role="list">
							{actions.map((suggestedAction) => {
								let actionNode;
								if (suggestedAction.type === 'priority') {
									actionNode = (
										<UpdatePriority
											context={suggestedAction.context}
											onSuccess={onPriorityUpdate}
											onFailure={onPriorityUpdateError}
										/>
									);
								} else if (suggestedAction.type === 'assignee') {
									actionNode = (
										<UpdateAssignee
											context={suggestedAction.context}
											onSuccess={onAssigneeUpdate}
											onFailure={onAssigneeUpdateError}
										/>
									);
								}
								return (
									<ActionItem
										key={suggestedAction.type}
										summary={suggestedAction.content.title}
										description={suggestedAction.content.description}
										action={actionNode}
									/>
								);
							})}
						</Stack>
					) : (
						<Text>{formatMessage(messages.noSuggestedActions)}</Text>
					)}
				</AIContextItem>
				<Feedback
					entrypointId={ENTRYPOINT_ID_JIRA_PLATFORM}
					feedbackGroupId={FEEDBACK_GROUP_ID}
					onFeedbackClicked={handleFeedbackClicked}
					onFeedbackSent={handleFeedbackSent}
				/>
			</DwellWrapper>
		</AIContextContainer>
	);
};
