import React from 'react';
import { styled } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { Skeleton } from '@atlassian/jira-common-components-skeleton/src/styled.tsx';

const LoadingSkeleton = () => (
	<Box xcss={loadingWrapperStyles}>
		<LineItemWrapper>
			<Skeleton height="16px" width="6%" />
			<Skeleton height="16px" width="90%" />
		</LineItemWrapper>
		<LineItemWrapper>
			<Skeleton height="16px" width="6%" />
			<Skeleton height="16px" width="90%" />
		</LineItemWrapper>
		<LineItemWrapper>
			<Skeleton height="16px" width="6%" />
			<Skeleton height="16px" width="90%" />
		</LineItemWrapper>
		<LineItemWrapper>
			<Skeleton height="16px" width="6%" />
			<Skeleton height="16px" width="90%" />
		</LineItemWrapper>
		<LineItemWrapper>
			<Skeleton height="16px" width="6%" />
			<Skeleton height="16px" width="90%" />
		</LineItemWrapper>
	</Box>
);

export default LoadingSkeleton;

const loadingWrapperStyles = xcss({
	paddingLeft: 'space.200',
	paddingRight: 'space.200',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LineItemWrapper = styled.div({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
	marginBottom: token('space.100', '8px'),
});
