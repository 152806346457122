import React, { Component, type SyntheticEvent } from 'react';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type {
	BaseUrl,
	IssueKey,
	IssueTypeId,
	ProjectId,
} from '@atlassian/jira-shared-types/src/general.tsx';
import Error from '../../common/ui/view-workflow/error/index.tsx';
import type { ServiceApi } from '../../services/view-workflow-service/index.tsx';
import ViewWorkflowClassic from './classic/index.tsx';
import { ViewWorkflowModalDropDownTrigger } from './read-view-modal/index.tsx';
import ViewWorkflowSimplified from './simplified/index.tsx';

export type Props = {
	issueKey: IssueKey;
	isModalMounted: boolean;
	baseUrl: BaseUrl;
	workflowServiceApi: ServiceApi;
	onOpenModal: (issueTypeId: IssueTypeId, projectId: ProjectId) => void;
	onCloseModal?: () => void;
};

type State = {
	isWorkflowServiceRefetching: boolean;
};

// eslint-disable-next-line jira/react/no-class-components
export default class ViewWorkflow extends Component<Props, State> {
	state: State = {
		isWorkflowServiceRefetching: false,
	};

	onWorkflowServiceRefetch = async (e: SyntheticEvent<HTMLElement>, retry: () => Promise<void>) => {
		e.stopPropagation();
		this.setState({
			isWorkflowServiceRefetching: true,
		});

		await retry();
		this.setState({
			isWorkflowServiceRefetching: false,
		});
	};

	render() {
		const { baseUrl, issueKey, isModalMounted, onOpenModal, workflowServiceApi, onCloseModal } =
			this.props;
		const { isWorkflowServiceRefetching } = this.state;

		const { data, loading, error, fetch } = workflowServiceApi;

		if (error) {
			return (
				<Error
					onRetry={(e) => {
						this.onWorkflowServiceRefetch(e, fetch);
					}}
				/>
			);
		}

		if (!data) {
			return null;
		}

		const { isSimplified, projectId, issueTypeId, hasPermission } = data;

		if (fg('cmp_support_for_readonly_workflow_modal')) {
			if (!projectId) {
				log.safeErrorWithoutCustomerData(
					'issue-field-status.view-workflow.no-project-id',
					'WorkflowServiceApi finished loading but there was no projectId',
				);
				return null;
			}

			return (
				<ViewWorkflowModalDropDownTrigger
					issueKey={issueKey}
					projectId={projectId}
					issueTypeId={issueTypeId}
					isSimplifiedProject={isSimplified}
					hasPermission={hasPermission}
					isServiceLoading={loading}
					isServiceRefetching={isWorkflowServiceRefetching}
					onClose={onCloseModal}
				/>
			);
		}

		if (isSimplified === true) {
			return (
				<ViewWorkflowSimplified
					projectId={projectId}
					issueTypeId={issueTypeId}
					isModalMounted={isModalMounted}
					hasPermission={hasPermission}
					isServiceLoading={loading}
					isServiceRefetching={isWorkflowServiceRefetching}
					onOpenModal={onOpenModal}
				/>
			);
		}

		return (
			<ViewWorkflowClassic
				baseUrl={baseUrl}
				issueKey={issueKey}
				hasPermission={hasPermission}
				isServiceLoading={loading}
				isServiceRefetching={isWorkflowServiceRefetching}
			/>
		);
	}
}
