/**
 * @generated SignedSource<<ece559cc75651d75622d9b47e5f4a4ac>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type singleUserPicker_issueFieldSingleUserPickerInlineEditFull_SingleUserPickerInlineEditView_fragmentRef$data = {
  readonly fieldConfig: {
    readonly isEditable: boolean | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"singleUserPicker_issueFieldSingleUserPickerInlineEditFull_SingleUserPickerInlineEditViewWithIsEditable_fragmentRef">;
  readonly " $fragmentType": "singleUserPicker_issueFieldSingleUserPickerInlineEditFull_SingleUserPickerInlineEditView_fragmentRef";
};
export type singleUserPicker_issueFieldSingleUserPickerInlineEditFull_SingleUserPickerInlineEditView_fragmentRef$key = {
  readonly " $data"?: singleUserPicker_issueFieldSingleUserPickerInlineEditFull_SingleUserPickerInlineEditView_fragmentRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"singleUserPicker_issueFieldSingleUserPickerInlineEditFull_SingleUserPickerInlineEditView_fragmentRef">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "singleUserPicker_issueFieldSingleUserPickerInlineEditFull_SingleUserPickerInlineEditView_fragmentRef",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "singleUserPicker_issueFieldSingleUserPickerInlineEditFull_SingleUserPickerInlineEditViewWithIsEditable_fragmentRef"
    },
    {
      "concreteType": "JiraFieldConfig",
      "kind": "LinkedField",
      "name": "fieldConfig",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isEditable"
        }
      ]
    }
  ],
  "type": "JiraSingleSelectUserPickerField"
};

(node as any).hash = "d6afe351b35e0beb2951194f3010ad16";

export default node;
