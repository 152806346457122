import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

const ProjectEditViewEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-project-edit-view" */ './ui/project'),
	),
	getPreloadProps: () => ({}),
});

export default ProjectEditViewEntryPoint;
