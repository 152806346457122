import { type ParametricSelector, createSelector } from 'reselect';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { DynamicFieldFormula } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/types.tsx';
import type { State, Props } from '../../../../types.tsx';
import type { DynamicFieldResolver } from '../../types.tsx';
import { getNumberSelectorForFormula } from '../index.tsx';

const FORMULA_TEMPLATE = 'composition';

const prodResult = (...values: number[]) => {
	let result = 1;
	let hasUndefined = false;
	let hasZero = false;
	values.every((value) => {
		if (value === undefined) {
			hasUndefined = true;
			return false;
		}
		// fixing NaN when Infinity * 0
		if (value === 0) {
			hasZero = true;
		}

		if (hasZero) {
			return true;
		}

		result *= value;
		return true;
	});
	if (hasUndefined) {
		return 0;
	}
	if (hasZero) {
		return 0;
	}
	if (result !== undefined) {
		return result;
	}
	return 0;
};

const quotResult = (...values: number[]) => {
	let result: number | undefined;
	values.every((value, index) => {
		if (index === 0) {
			result = value;
			return true;
		}
		// if any of the divisors is 0, undefined or the result of a division by 0 (= Infinity)
		// we early return Infinity (= imposible division)
		if (value === 0 || value === undefined || value === Infinity) {
			result = Infinity;
			return false;
		}

		if (result !== undefined) {
			result /= value;
		}
		return true;
	});
	if (result !== undefined) {
		return result;
	}
	return 0;
};

const sumResult = (...values: number[]) => {
	let notUndefined = false;
	let result = 0;
	values.forEach((value) => {
		if (value !== undefined) {
			notUndefined = true;
		}
		result += value !== undefined ? value : 0;
		return true;
	});
	if (notUndefined) {
		return result;
	}
	return 0;
};

const diffResult = (...values: number[]) => {
	let notUndefined = false;
	const result = values.reduce((a, b) => {
		if (a !== undefined || b !== undefined) {
			notUndefined = true;
		}
		return (a === undefined ? 0 : a) - (b === undefined ? 0 : b);
	});
	if (notUndefined) {
		return result;
	}
	return 0;
};

const noopResult = () => undefined;

export const compositionResolver: DynamicFieldResolver<number> = (formula: DynamicFieldFormula) => {
	if (formula === undefined || formula.template !== FORMULA_TEMPLATE) {
		return undefined;
	}
	const termResolvers = formula.parameters.formulas.map((term) =>
		getNumberSelectorForFormula(term),
	);

	return (localIssueId: LocalIssueId) => {
		const issueSelectors: ParametricSelector<State, Props | undefined, number>[] = [];

		termResolvers.forEach((termResolver) => {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			const selector = termResolver?.(localIssueId) as
				| ParametricSelector<State, Props | undefined, number>
				| undefined;
			if (selector !== undefined) {
				issueSelectors.push(selector);
			}
		});

		switch (formula.parameters.agg) {
			case 'prod':
				return createSelector(
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					issueSelectors as [ParametricSelector<State, Props | undefined, number>],
					prodResult,
				);
			case 'quot':
				return createSelector(
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					issueSelectors as [ParametricSelector<State, Props | undefined, number>],
					quotResult,
				);
			case 'sum':
				return createSelector(
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					issueSelectors as [ParametricSelector<State, Props | undefined, number>],
					sumResult,
				);
			case 'diff':
				return createSelector(
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					issueSelectors as [ParametricSelector<State, Props | undefined, number>],
					diffResult,
				);
			default:
				return noopResult;
		}
	};
};
