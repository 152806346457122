import React, { useCallback, useEffect, useMemo } from 'react';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { addDesignButtonEntryPoint } from '@atlassian/jira-issue-add-design-button/entrypoint.tsx';
import { useDesignPanelStore } from '@atlassian/jira-issue-design-section-store/src/index.tsx';
import { createAri } from '@atlassian/jira-platform-ari/src/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import type { AddDesignItemProps } from './types.tsx';

export const AddDesignItem = ({
	createItemView,
	onMount,
	isQuickActionsListView,
}: AddDesignItemProps) => {
	const cloudId = useCloudId();
	const siteAri = createAri({
		resourceOwner: 'jira',
		resourceType: 'site',
		resourceId: cloudId,
	});

	const entryPointParams = useMemo(
		() => ({
			siteAri,
		}),
		[siteAri],
	);

	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		addDesignButtonEntryPoint,
		entryPointParams,
	);
	const [, { setShouldShowAddDesignForm }] = useDesignPanelStore();

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => entryPointActions.load(), [entryPointActions]);

	const onDesignItemClick = useCallback(() => {
		setShouldShowAddDesignForm(true);
	}, [setShouldShowAddDesignForm]);

	const runtimeProps = useMemo(
		() => ({
			onClick: onDesignItemClick,
			onMount,
			createItemView,
			isQuickActionsListView,
		}),
		[onDesignItemClick, onMount, createItemView, isQuickActionsListView],
	);

	return (
		<JiraEntryPointContainer
			entryPointReferenceSubject={entryPointReferenceSubject}
			id="jiraIssueAddDesignItem"
			packageName="jiraIssueViewFoundation"
			errorFallback="unmount"
			teamName="helios-headcoach"
			runtimeProps={runtimeProps}
		/>
	);
};
