import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	title: {
		id: 'invite-prompt-on-link-insert.invite-error-flag.title',
		defaultMessage: 'Something went wrong',
		description: 'Title of error flag',
	},
	description: {
		id: 'invite-prompt-on-link-insert.invite-error-flag.description',
		defaultMessage: 'Try inviting your teammates again. If the problem persists, contact support.',
		description: 'Description informing the user that invites were not successfully sent',
	},
	action: {
		id: 'invite-prompt-on-link-insert.invite-error-flag.action',
		defaultMessage: 'Contact support',
		description: 'Action to contact support',
	},
});
