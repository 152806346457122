import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { User } from '@atlassian/jira-issue-shared-types/src/common/types/user-type.tsx';
import type { IssueKey, BaseUrl } from '@atlassian/jira-shared-types/src/general.tsx';

export type Params = {
	issueKey: IssueKey;
	query: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	signal: any;
};

export const getFetchUsersUrl = (baseUrl: BaseUrl) => `${baseUrl}/rest/api/2/user/viewissue/search`;

/**
 * Fetch a list of users that has "view" permissions for a given issue.
 */
// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({ issueKey, query, signal }: Params): Promise<User[]> =>
	fetchJson(
		`/rest/api/3/user/recommend?${new URLSearchParams({
			context: 'Watchers',
			...(issueKey && { issueKey }),
			query,
		})}`,
		{
			method: 'GET',
			headers: {
				'x-atlassian-force-account-id': 'true',
			},
			signal,
		},
	).then((response) =>
		// @ts-expect-error - TS7006 - Parameter 'user' implicitly has an 'any' type.
		response.map((user) => ({
			id: user.accountId,
			displayName: user.displayName,
			avatarUrl: user.avatarUrls['48x48'],
			timeZone: user.timeZone,
		})),
	);
