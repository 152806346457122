/**
 * @generated SignedSource<<f21edd838d39b5397600b4c949e6e7f6>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type field_issueViewLayoutSecurityLevelField_IssueViewSecurityLevelFieldView$data = {
  readonly fieldByIdOrAlias: {
    readonly __typename: "JiraSecurityLevelField";
    readonly fieldId: string;
    readonly type: string;
    readonly " $fragmentSpreads": FragmentRefs<"inlineEdit_issueViewLayoutSecurityLevelField_SecurityLevelInlineEdit">;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null | undefined;
  readonly " $fragmentType": "field_issueViewLayoutSecurityLevelField_IssueViewSecurityLevelFieldView";
};
export type field_issueViewLayoutSecurityLevelField_IssueViewSecurityLevelFieldView$key = {
  readonly " $data"?: field_issueViewLayoutSecurityLevelField_IssueViewSecurityLevelFieldView$data;
  readonly " $fragmentSpreads": FragmentRefs<"field_issueViewLayoutSecurityLevelField_IssueViewSecurityLevelFieldView">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "field_issueViewLayoutSecurityLevelField_IssueViewSecurityLevelFieldView",
  "selections": [
    {
      "args": [
        {
          "kind": "Literal",
          "name": "idOrAlias",
          "value": "security"
        },
        {
          "kind": "Literal",
          "name": "ignoreMissingField",
          "value": true
        }
      ],
      "kind": "LinkedField",
      "name": "fieldByIdOrAlias",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "kind": "FragmentSpread",
              "name": "inlineEdit_issueViewLayoutSecurityLevelField_SecurityLevelInlineEdit"
            },
            {
              "kind": "ScalarField",
              "name": "fieldId"
            },
            {
              "kind": "ScalarField",
              "name": "type"
            },
            {
              "kind": "ScalarField",
              "name": "__typename"
            }
          ],
          "type": "JiraSecurityLevelField"
        }
      ],
      "storageKey": "fieldByIdOrAlias(idOrAlias:\"security\",ignoreMissingField:true)"
    }
  ],
  "type": "JiraIssue"
};

(node as any).hash = "aa89653bbdd71889ff373c692f8a7ade";

export default node;
