import { useContext, createContext } from 'react';
import { identity } from '../injections-context/index.tsx';
import type { InlineEditFieldInjectionsContextValue } from './types.tsx';

export const initialValue: InlineEditFieldInjectionsContextValue = {
	overriding: {
		overrideLabel: identity,
		overrideDescription: identity,
		overrideIsEditable: identity,
		overrideFieldOptionsFilter: identity,
	},
};

export const InlineEditFieldInjectionsContext =
	createContext<InlineEditFieldInjectionsContextValue>(initialValue);

export const useInlineEditFieldInjections = () => useContext(InlineEditFieldInjectionsContext);
