import type { FocusEvent, MutableRefObject, RefObject } from 'react';
import { FOCUSED_FIELD_STATE } from '../../../constants.tsx';
import type { InlineFieldState } from '../../../types.tsx';

export const submitForgeFormField = (
	formRef?: RefObject<HTMLFormElement> | MutableRefObject<HTMLFormElement | undefined>,
) => {
	formRef?.current?.requestSubmit();
};
export const dispatchFormSubmitAndBlurElements = (formRef?: RefObject<HTMLFormElement>): void => {
	submitForgeFormField(formRef);
	if (formRef?.current?.elements) {
		Array.from(formRef.current.elements).forEach((element) => {
			if ('blur' in element && typeof element.blur === 'function') {
				element?.blur();
			}
		});
	}
};

/**
 * Used in a custom field uikit 1 and 2 wrapper's onBlur function.
 * Checks if the event happened outside the parent component
 * so that the submit event is triggered only in that case.
 * @param fieldState state of a custom field containing its status
 * @param event FocusEvent that triggered the onBlur function
 * @param divRef a reference to a div element (wrapper)
 * @returns a boolean value indicating if the event happened outside the parent component
 */
export const isBlurEventTriggeredOutsideTheField = (
	fieldState: InlineFieldState,
	event: FocusEvent<HTMLElement>,
	divRef: RefObject<HTMLDivElement>,
) => fieldState?.status === FOCUSED_FIELD_STATE && !divRef.current?.contains(event.relatedTarget);
