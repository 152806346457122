import React, {
	type ReactElement,
	useState,
	useEffect,
	useCallback,
	type ChangeEvent,
} from 'react';
import { styled } from '@compiled/react';
import { useFragment, graphql, useMutation } from 'react-relay';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fg } from '@atlassian/jira-feature-gating';
import { ValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { ErrorFlag } from '@atlassian/jira-issue-error-flag/src/index.tsx';
import { useInlineEditFieldInjections } from '@atlassian/jira-issue-field-injections/src/controllers/inline-edit-injections-context/index.tsx';
import {
	ReadViewContainer,
	InlineEditContainer,
} from '@atlassian/jira-issue-field-inline-edit/src/styled.tsx';
import { FieldInlineEditStateLess } from '@atlassian/jira-issue-field-inline-edit/src/ui/index.tsx';
import { NumberEditView } from '@atlassian/jira-issue-field-number-editview-full/src/ui/number/index.tsx';
import { NumberReadView } from '@atlassian/jira-issue-field-number-readview-full/src/ui/number/index.tsx';
import { useOptionallyControlledEditingState } from '@atlassian/jira-issue-field-optional-editing-state-manager/src/index.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { number_issueFieldNumber_NumberField_Mutation as NumberMutation } from '@atlassian/jira-relay/src/__generated__/number_issueFieldNumber_NumberField_Mutation.graphql';
import type { number_issueFieldNumberInlineEditFull_NumberInlineEditView$key as NumberFragment } from '@atlassian/jira-relay/src/__generated__/number_issueFieldNumberInlineEditFull_NumberInlineEditView.graphql';
import messages from './messages.tsx';
import type { Props, NumberValue } from './types.tsx';

export const NumberInlineEditView = ({
	fragmentRef,
	isEditing: isEditingDefault = false,
	onCancel,
	onEdit,
	onEscape,
	onEnter,
	onSubmit,
	onSubmitSucceeded,
	onSubmitFailed,
}: Props) => {
	const data = useFragment<NumberFragment>(
		graphql`
			fragment number_issueFieldNumberInlineEditFull_NumberInlineEditView on JiraNumberField {
				...number_issueFieldNumberReadviewFull_NumberReadView
				id
				name
				number
				fieldConfig {
					isEditable
				}
			}
		`,
		fragmentRef,
	);

	const { id: uniqueFieldId, number: value, name } = data;
	const { overriding } = useInlineEditFieldInjections();

	const [commit] = useMutation<NumberMutation>(graphql`
		mutation number_issueFieldNumber_NumberField_Mutation($input: JiraUpdateNumberFieldInput!)
		@raw_response_type {
			jira {
				updateNumberField(input: $input) {
					success
					errors {
						message
					}
					field {
						id
						number
					}
				}
			}
		}
	`);

	const [isEditing, setIsEditing] = useOptionallyControlledEditingState(
		isEditingDefault,
		uniqueFieldId,
	);
	const [error, setError] = useState<Error | null>(null);
	const [updatedValue, setUpdatedValue] = useState<NumberValue>(value);
	const isFieldEditable = overriding.overrideIsEditable(data.fieldConfig?.isEditable ?? false);

	useEffect(() => {
		if (error) {
			setIsEditing(true);
		}
		if (!isEditing) {
			setUpdatedValue(value);
		}
	}, [error, isEditing, value, setIsEditing]);

	const save = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			if (updatedValue !== value) {
				commit({
					variables: {
						input: {
							id: uniqueFieldId,
							operation: {
								operation: 'SET',
								number: updatedValue,
							},
						},
					},
					onCompleted: (mutationData) => {
						if (mutationData.jira?.updateNumberField) {
							const {
								success,
								errors: responseErrors,
								field,
							} = mutationData.jira?.updateNumberField;
							if (success == null || success === false) {
								if (responseErrors != null && responseErrors.length > 0) {
									if (responseErrors[0].message && fg('relay-migration-issue-fields-number')) {
										setError(
											new ValidationError(
												responseErrors[0].message != null
													? responseErrors[0].message
													: 'Relay error encountered while updating number field',
											),
										);
									} else {
										setError(
											new Error(
												responseErrors[0].message != null
													? responseErrors[0].message
													: 'Relay error encountered while updating number field',
											),
										);
									}
								} else {
									setError(new Error('Relay error encountered while updating number field'));
								}
							} else if (success && field != null) {
								onSubmitSucceeded && onSubmitSucceeded(field.number);
								setIsEditing(false);
							}
						}
					},
					onError: (incomingError) => {
						setError(incomingError);
						onSubmitFailed?.();
					},
					optimisticResponse: {
						jira: {
							updateNumberField: {
								success: true,
								errors: [],
								field: {
									id: uniqueFieldId,
									number: updatedValue != null ? updatedValue : null,
								},
							},
						},
					},
					optimisticUpdater: () => {
						setIsEditing(false);
					},
				});
			} else {
				setIsEditing(false);
			}
			fireUIAnalytics(analyticsEvent);
		},
		[updatedValue, value, commit, uniqueFieldId, onSubmitSucceeded, setIsEditing, onSubmitFailed],
	);

	const cancel = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			setError(null);
			setIsEditing(false);
			setUpdatedValue(value);
			fireUIAnalytics(analyticsEvent);
		},
		[setError, setIsEditing, value],
	);

	const onCancelRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			cancel(analyticsEvent);
			onCancel && onCancel(analyticsEvent);
		},
		[onCancel, cancel],
	);

	const onConfirmRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			save(analyticsEvent);
			onSubmit?.(updatedValue);
		},
		[onSubmit, save, updatedValue],
	);

	const onEditRequested = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			setIsEditing(true);
			fireUIAnalytics(analyticsEvent);
			onEdit && onEdit(analyticsEvent);
		},
		[onEdit, setIsEditing],
	);

	const onEscapeRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			cancel(analyticsEvent);
			onEscape && onEscape(analyticsEvent);
		},
		[onEscape, cancel],
	);

	const onEnterRequest = useCallback(
		(analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent);
			onEnter && onEnter(analyticsEvent);
		},
		[onEnter],
	);

	const onChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			setError(null);
			const typedValue =
				event.target.value === '' ? null : parseFloat(Number(event.target.value).toFixed(3));
			setUpdatedValue(typedValue);
		},
		[setError, setUpdatedValue],
	);

	const renderEditView = (): ReactElement => (
		<NumberEditView
			label={name}
			defaultValue={updatedValue}
			isInvalid={!!error}
			onChange={onChange}
		/>
	);

	const renderReadView = (): ReactElement => (
		<ReadViewContainer>
			<NumberReadView fragmentRef={data} />
		</ReadViewContainer>
	);

	const InlineEditWrapper = isFieldEditable ? InlineEditContainer : NumberInlineEditContainer;
	const { formatMessage } = useIntl();

	return (
		<>
			{error && (
				<ErrorFlag
					description={messages.errorMessage}
					error={error}
					title={formatMessage(messages.errorTitleWithFieldName, { fieldName: data.name })}
				/>
			)}
			<InlineEditWrapper>
				<FieldInlineEditStateLess
					actionSubject="numberInlineEdit"
					defaultValue={updatedValue}
					editView={renderEditView}
					isEditable={isFieldEditable}
					isEditing={isEditing}
					readView={renderReadView}
					testId="issue-field-number-inline-edit-full.ui.number.issue-field-number"
					fieldId={fg('one_event_rules_them_all_fg') ? uniqueFieldId : undefined}
					onCancel={onCancelRequest}
					onConfirm={onConfirmRequest}
					onEdit={onEditRequested}
					onEnter={onEnterRequest}
					onEscape={onEscapeRequest}
				/>
			</InlineEditWrapper>
		</>
	);
};

const readViewContainerSelectorName = 'jira-issue-field-inline-edit-read-view-container';
const READ_VIEW_CONTAINER_COMPONENT_SELECTOR = `[data-component-selector="${readViewContainerSelectorName}"]`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NumberInlineEditContainer = styled(InlineEditContainer)({
	left: 0,
	padding: '6px 0',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > div': {
		margin: '11px 0 3px 2px',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	[READ_VIEW_CONTAINER_COMPONENT_SELECTOR]: {
		marginLeft: 0,
		lineHeight: 1,
	},
});
