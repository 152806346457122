import { defineMessages } from '@atlassian/jira-intl';

export const commonMessages = defineMessages({
	accept: {
		id: 'jira-servicedesk-ai-context-ops-panel.common.dismiss',
		defaultMessage: 'Accept',
		description: 'Action button that accept the suggestions provided to user.',
	},
	error: {
		id: 'jira-servicedesk-ai-context-ops-panel.common.error',
		defaultMessage: 'Something went wrong. Try again or refresh the page.',
		description:
			'Toast message shown to the user when we failed to update the suggested field in issue-view.',
	},
});
