import React, { memo } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { Skeleton } from '@atlassian/jira-common-components-skeleton/src/styled.tsx';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const SkeletonLineWrapper = styled.div({
	width: '100%',
	marginBottom: token('space.100', '8px'),
});
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const SkeletonWrapper = styled.div({
	flex: '1 0 auto',
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default memo<Record<any, any>>(() => (
	<SkeletonWrapper data-testid="risk-insights-panel.common.ui.skeleton.skeleton-wrapper">
		<SkeletonLineWrapper>
			<Skeleton height="20px" width="30%" />
		</SkeletonLineWrapper>
		<SkeletonLineWrapper>
			<Skeleton height="20px" width="100%" />
		</SkeletonLineWrapper>
		<SkeletonLineWrapper>
			<Skeleton height="20px" width="100%" />
		</SkeletonLineWrapper>
	</SkeletonWrapper>
));
