import omit from 'lodash/omit';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { PropertyMaps } from '../../../types.tsx';

export const removePropertyValue = (
	fieldKey: FieldKey,
	maps: PropertyMaps,
	issueId: LocalIssueId,
	mappingKey: keyof PropertyMaps,
) => {
	// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type
	if (maps[mappingKey][fieldKey] && maps[mappingKey][fieldKey][issueId] === undefined) {
		return maps;
	}

	if (mappingKey === 'issueMetadata' || mappingKey === 'lexoRank') {
		const idToValueMap = maps[mappingKey];
		const newIdToValueMap = omit(idToValueMap, issueId);
		return {
			...maps,
			[mappingKey]: newIdToValueMap,
		};
	}

	const idToValueMap = maps[mappingKey][fieldKey];
	const newIdToValueMap = omit(idToValueMap, issueId);
	return {
		...maps,
		[mappingKey]: {
			...maps[mappingKey],
			[fieldKey]: {
				...newIdToValueMap,
			},
		},
	};
};
