/**
 * Field rollup operations.
 */
export const AVG_ROLLUP = 'AVG';
export const COUNT_ROLLUP = 'COUNT';
export const EMPTY_ROLLUP = 'EMPTY';
export const FILLED_ROLLUP = 'FILLED';
export const MAX_ROLLUP = 'MAX';
export const MEDIAN_ROLLUP = 'MEDIAN';
export const MIN_ROLLUP = 'MIN';
export const RANGE_ROLLUP = 'RANGE';
export const SUM_ROLLUP = 'SUM';

export const FieldRollups = {
	AVG_ROLLUP,
	COUNT_ROLLUP,
	EMPTY_ROLLUP,
	FILLED_ROLLUP,
	MAX_ROLLUP,
	MEDIAN_ROLLUP,
	MIN_ROLLUP,
	RANGE_ROLLUP,
	SUM_ROLLUP,
} as const;
