import React, { useCallback, useEffect, type FC } from 'react';
import { IconButton } from '@atlaskit/button/new';
import CrossIcon from '@atlaskit/icon/core/migration/close--cross';
import { SmartCardProvider } from '@atlaskit/link-provider';
import Lozenge from '@atlaskit/lozenge';
import { Box, Inline, Text, xcss } from '@atlaskit/primitives';
import SectionMessage from '@atlaskit/section-message';
import { token } from '@atlaskit/tokens';
import { StreamingStatusBar } from '@atlassian/jira-ai-work-breakdown/src/ui/issue-breakdown-draft-list/streaming-status-bar/index.tsx';
import AIContainer from '@atlassian/jira-atlassian-intelligence/src/common/ui/ai-container/index.tsx';
import { AiFooter } from '@atlassian/jira-atlassian-intelligence/src/common/ui/ai-footer/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { steps, useRelatedIssues, type Step } from '../controllers/context.tsx';
import { useFetchRelatedIssue } from '../services/use-fetch-related-issues/index.tsx';
import messages from './messages.tsx';
import { RelatedIssuesDraftList } from './related-issues-draft-list/index.tsx';
import type { onIssueLinkSave } from './types.tsx';

type SuggestRelatedIssuesModalProps = {
	currentStep: Step;
	existingIssues: string[];
	isLinkedIssuePanelButtonTriggered: boolean;
	onDiscardAllSuggestions: () => void;
	onIssueLinkSave: onIssueLinkSave;
};

const UNCATEGORISED_ERROR = 'UNKNOWN ERROR';

export const SuggestRelatedIssuesModal: FC<SuggestRelatedIssuesModalProps> = ({
	currentStep,
	existingIssues,
	isLinkedIssuePanelButtonTriggered = false,
	onDiscardAllSuggestions,
	onIssueLinkSave,
}) => {
	const { formatMessage } = useIntl();
	const { data, error, loading, fetchSuggestion } = useFetchRelatedIssue(existingIssues);
	const [
		{ resourceSuggestions },
		{ setResourceSuggestion, setErrorMessage, setIsLinkedIssuePanelButtonTriggered },
	] = useRelatedIssues();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		if (currentStep === steps.fetching) {
			fetchSuggestion();
		}
	}, [fetchSuggestion, currentStep]);

	useEffect(() => {
		if (loading) {
			if (fg('jira-ai-similar-issues-updating-analytics')) {
				fireTrackAnalytics(createAnalyticsEvent({}), 'aiInteraction initiated', {
					invokedFrom: isLinkedIssuePanelButtonTriggered
						? 'linkedIssuesPanel'
						: 'improveIssueDropdown',
					existingItemsCount: existingIssues.length,
				});
			} else {
				fireTrackAnalytics(createAnalyticsEvent({}), {
					action: 'initiated',
					actionSubject: 'aiInteraction',
					actionSubjectId: 'client',
					attributes: {
						source: isLinkedIssuePanelButtonTriggered
							? 'linkedIssuesPanel'
							: 'improvedIssueDropdown',
						existingItemsCount: existingIssues.length,
					},
				});
			}
		}
	}, [createAnalyticsEvent, existingIssues.length, isLinkedIssuePanelButtonTriggered, loading]);

	useEffect(() => {
		if (currentStep === steps.display) {
			setIsLinkedIssuePanelButtonTriggered(false);
			if (data) {
				if (fg('jira-ai-similar-issues-updating-analytics')) {
					fireTrackAnalytics(
						createAnalyticsEvent({}),
						'aiResult viewed',
						'aiRelatedIssuesSuggestionResultBox',
						{
							aiSuggestedCount: data.length,
						},
					);
				} else {
					fireTrackAnalytics(createAnalyticsEvent({}), {
						actionSubject: 'aiResult',
						action: 'viewed',
						actionSubjectId: 'client',
						attributes: {
							source: 'aiRelatedIssuesSuggestionResultBox',
							aiSuggestedCount: data.length,
						},
					});
				}
			} else if (error) {
				if (fg('jira-ai-similar-issues-updating-analytics')) {
					fireTrackAnalytics(
						createAnalyticsEvent({}),
						'aiResult error',
						'aiRelatedIssuesSuggestionResultBox',
						{
							aiErrorCode: error instanceof FetchError ? error.statusCode : UNCATEGORISED_ERROR,
						},
					);
				} else {
					fireTrackAnalytics(createAnalyticsEvent({}), {
						actionSubject: 'aiResult',
						action: 'error',
						actionSubjectId: 'client',
						attributes: {
							source: 'aiRelatedIssuesSuggestionResultBox',
							aiErrorCode: error instanceof FetchError ? error.statusCode : UNCATEGORISED_ERROR,
						},
					});
				}
			}
		}
	}, [createAnalyticsEvent, currentStep, data, error, setIsLinkedIssuePanelButtonTriggered]);

	useEffect(() => {
		if (data) {
			setResourceSuggestion(data);
		} else if (error) {
			setErrorMessage(error);
		}
	}, [setResourceSuggestion, setErrorMessage, data, error]);

	const onDismissSuggestions = useCallback(() => {
		onDiscardAllSuggestions();
		if (loading) {
			if (fg('jira-ai-similar-issues-updating-analytics')) {
				fireTrackAnalytics(
					createAnalyticsEvent({}),
					'aiInteraction dismissed',
					'aiRelatedIssuesSuggestionResultBox',
				);
			} else {
				fireTrackAnalytics(createAnalyticsEvent({}), {
					actionSubject: 'aiInteraction',
					action: 'dismissed',
					actionSubjectId: 'client',
					attributes: {
						source: 'aiRelatedIssuesSuggestionResultBox',
					},
				});
			}
		} else if (resourceSuggestions?.length) {
			if (fg('jira-ai-similar-issues-updating-analytics')) {
				fireTrackAnalytics(
					createAnalyticsEvent({}),
					'aiResult actioned',
					'aiRelatedIssuesSuggestionResultBox',
					{
						aiResultAction: 'bulkIssuesDeclined',
						aiItemsCount: resourceSuggestions.length,
					},
				);
			} else {
				fireTrackAnalytics(createAnalyticsEvent({}), {
					actionSubject: 'aiResult',
					action: 'actioned',
					actionSubjectId: 'client',
					attributes: {
						source: 'aiRelatedIssuesSuggestionResultBox',
						aiResultAction: 'bulkIssuesDeclined',
						aiItemsCount: resourceSuggestions.length,
					},
				});
			}
		}
	}, [createAnalyticsEvent, loading, onDiscardAllSuggestions, resourceSuggestions]);

	const shouldDisplayWarning = () => {
		return error !== undefined || data?.length === 0;
	};

	switch (currentStep) {
		case steps.fetching:
		case steps.display:
			return (
				<SmartCardProvider>
					<AIContainer
						hasNewBorderExperience
						shouldFitContainer
						isShadow
						isLoading={loading}
						spacing="none"
						testId="ai-related-issues.ui.ai-container"
					>
						<Box
							testId="ai-related-issues.ui.header"
							xcss={[headerContainerStyles, hoverableHeaderStyles, hoverableStyles]}
						>
							<Inline
								space="space.050"
								testId="ai-related-issues.ui.button"
								xcss={headerButtonStyles}
							>
								<Box as="span" xcss={headerPromptStyles}>
									<Text>
										{formatMessage(
											fg('jira-issue-terminology-refresh-m3')
												? messages.findSimilarIssuesIssueTermRefresh
												: messages.findSimilarIssues,
										)}
									</Text>
								</Box>
								<Lozenge appearance="new">{formatMessage(messages.betaNonFinal)}</Lozenge>
							</Inline>
							<IconButton
								testId="ai-related-issues.ui.cross-button"
								appearance="subtle"
								icon={CrossIcon}
								label={formatMessage(messages.discardSuggestions)}
								onClick={onDismissSuggestions}
								isTooltipDisabled={false}
								tooltip={{ position: 'left' }}
							/>
						</Box>
						{loading && (
							<StreamingStatusBar message={formatMessage(messages.generatingSuggestions)} />
						)}
						{shouldDisplayWarning() && (
							<SectionMessage appearance="warning">
								{formatMessage(
									fg('jira-issue-terminology-refresh-m3')
										? messages.similarIssuesCouldNotBeGeneratedIssueTermRefresh
										: messages.similarIssuesCouldNotBeGenerated,
								)}
							</SectionMessage>
						)}
						<RelatedIssuesDraftList onIssueLinkSave={onIssueLinkSave} />
						<AiFooter isBeta={false} />
					</AIContainer>
				</SmartCardProvider>
			);
		default:
			return null;
	}
};

const headerButtonStyles = xcss({
	background: 'none',
	width: '100%',
	display: 'flex',
	justifyContent: 'baseline',
	alignItems: 'center',
	padding: 'space.200',
});

const headerPromptStyles = xcss({
	borderRadius: token('border.radius.100', '3px'),
	padding: 'space.025',
	paddingTop: '0',
	paddingBottom: '0',
	height: 'space.0',
	width: 'space.100',
	color: 'color.text.subtle',
	background: token('color.background.neutral', '#091E420F'),
	fontWeight: token('font.weight.medium', '400'),
	marginRight: 'space.100',
});

const headerContainerStyles = xcss({
	paddingRight: 'space.150',
	display: 'flex',
	borderBottom: `1px solid ${token('color.background.accent.gray.subtler', '#EBECF0')}`,
	alignItems: 'center',
});

const hoverableStyles = xcss({
	cursor: 'pointer',
});

const hoverableHeaderStyles = xcss({
	':hover': {
		background: token('color.background.neutral.subtle.hovered', '#EBECF0'),
		textDecoration: 'none',
	},
});
