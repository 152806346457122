/*global jest*/
import {ConnectHost} from '../ConnectHost';
import {MockSimpleXdmExtension} from "./MockSimpleXdmExtension";

/**
 * This class provides a mock implementation of ConnectHost.
 */
export class MockConnectHost implements ConnectHost {
  onIframeEstablished = jest.fn();
  offIframeEstablished = jest.fn();
  onIframeUnload = jest.fn();
  offIframeUnload = jest.fn();
  createExtension = jest.fn(params => {
    let val = new MockSimpleXdmExtension;
    val.iframeAttributes.src = params.url;
    return val;
  });
  destroy = jest.fn();
  getContentResolver = jest.fn();
  hasJwt = jest.fn();
  isJwtExpired = jest.fn();
}
