import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/takeUntil';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/switchMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import type { AssociatedIssuesContextActions } from '@atlassian/jira-associated-issues-context-service/src/actions.tsx';
import {
	FORBIDDEN,
	NOT_FOUND,
	UNAUTHORIZED,
} from '@atlassian/jira-common-constants/src/http-status-codes.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import type { AttachmentServiceActions } from '@atlassian/jira-issue-attachments-base/src/services/attachments-service/types.tsx';
import type { IssueContextServiceActions } from '@atlassian/jira-issue-context-service/src/types.tsx';
import type { FieldConfigServiceActions } from '@atlassian/jira-issue-field-base/src/services/field-config-service/types.tsx';
import type { FieldValueServiceActions } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import type { ContainersByType } from '@atlassian/jira-issue-layout-common-constants/src/index.tsx';
import {
	Reason,
	type IssueRefreshServiceActions,
} from '@atlassian/jira-issue-refresh-service/src/types.tsx';
import {
	AUTHENTICATION_ERROR,
	CONNECTIVITY_ERROR,
	NOT_FOUND_OR_NO_PERMISSION_ERROR,
	UNKNOWN_ERROR,
	type IssueError,
} from '@atlassian/jira-issue-shared-types/src/common/types/error-type.tsx';
import type { ProjectContext } from '@atlassian/jira-issue-shared-types/src/common/types/project-type.tsx';
import type { UserPreferenceActions } from '@atlassian/jira-issue-user-preference-services/src/types.tsx';
import { ISSUE_CRITICAL_DATA_REDUX } from '@atlassian/jira-issue-view-common-constants/src/mark-types.tsx';
import { fetchViewContextWithErrorHandling } from '@atlassian/jira-issue-view-common-media/src/view-context/view-context-epic.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { clearFetchIssueNetworkTimes } from '@atlassian/jira-issue-view-common-utils/src/browser-metrics/index.tsx';
import { trackOrLogClientError } from '@atlassian/jira-issue-view-common-utils/src/errors/index.tsx';
import { storeFields } from '@atlassian/jira-issue-view-common-utils/src/utils/fields-extraction.tsx';
import { getAssociatedIssuesContext } from '@atlassian/jira-issue-view-common-utils/src/utils/get-associated-issues-context.tsx';
import type { ResourceManager } from '@atlassian/jira-issue-view-common-utils/src/utils/prefetched-resources/prefetched-resource-manager/index.tsx';
import {
	CHILDREN_ISSUES,
	EPIC_ISSUES,
	PORTFOLIO_CHILD_ISSUES,
	SUBTASKS,
	DATA_CLASSIFICATION_FIELD,
} from '@atlassian/jira-issue-view-configurations/src/index.tsx';
import type {
	EcosystemActions,
	State as EcosystemState,
} from '@atlassian/jira-issue-view-ecosystem-service/src/services/types.tsx';
import { statusCodesNotImpactingReliability } from '@atlassian/jira-issue-view-errors/src/common/utils/index.tsx';
import { IssueViewFetchError } from '@atlassian/jira-issue-view-errors/src/common/utils/issue-view-fetch-error/index.tsx';
import type { IssueViewLayoutActions } from '@atlassian/jira-issue-view-layout/src/services/types.tsx';
import { fetchAllAppDataWithRetries } from '@atlassian/jira-issue-view-services/src/issue/issue-fetch-server.tsx';
import { LOAD_NEW_ISSUE } from '@atlassian/jira-issue-view-store/src/actions/issue-navigation-actions.tsx';
import {
	FETCH_ISSUE_REQUEST,
	fetchIssueFailure,
	type FetchIssueFailureDescription,
	fetchIssueSuccess,
	CANCEL_REFRESH_ISSUE_REQUEST,
} from '@atlassian/jira-issue-view-store/src/common/actions/issue-fetch-actions.tsx';
import {
	fetchUploadContextFailure,
	fetchUploadContextRequest,
	fetchUploadContextSuccess,
	type UploadContextAction,
} from '@atlassian/jira-issue-view-store/src/common/media/upload-context/upload-context-actions.tsx';
import { fetchViewContextSuccess } from '@atlassian/jira-issue-view-store/src/common/media/view-context/view-context-actions.tsx';
import { reportServerTimeViaState } from '@atlassian/jira-issue-view-store/src/common/metrics/analytics-actions.tsx';
import {
	accountIdloggedInUserSelector,
	baseUrlSelector,
	issueKeySelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import { isServiceDeskSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';
import type { ProjectContextServiceActions } from '@atlassian/jira-project-context-service/src/types.tsx';
import type { ProjectPermissionActions } from '@atlassian/jira-project-permissions-service/src/types.tsx';
import type { JiraSettingsActions } from '@atlassian/jira-settings-service/src/types.tsx';
import {
	type AccountId,
	toIssueId,
	toProjectKey,
} from '@atlassian/jira-shared-types/src/general.tsx';
import { blockedOnBackendEntryLog } from '../common/metrics/reporters/backend-blocking-time-reporter.tsx';

// Exported for testing only
export const transformError = (
	rawError: FetchError | IssueViewFetchError | Error,
	accountId: AccountId | null,
) => {
	let error: IssueError = UNKNOWN_ERROR;

	// @ts-expect-error - TS2339 - Property 'statusCode' does not exist on type 'Error | FetchError | IssueViewFetchError'.
	if (rawError.statusCode === UNAUTHORIZED) {
		error = AUTHENTICATION_ERROR;
		// @ts-expect-error - TS2339 - Property 'statusCode' does not exist on type 'Error | FetchError | IssueViewFetchError'.
	} else if (rawError.statusCode === FORBIDDEN) {
		error = NOT_FOUND_OR_NO_PERMISSION_ERROR;
		// @ts-expect-error - TS2339 - Property 'statusCode' does not exist on type 'Error | FetchError | IssueViewFetchError'.
	} else if (rawError.statusCode === NOT_FOUND) {
		error = NOT_FOUND_OR_NO_PERMISSION_ERROR;
	} else if (
		// @ts-expect-error - TS2339 - Property 'statusCode' does not exist on type 'Error | FetchError | IssueViewFetchError'.
		!rawError.statusCode ||
		// @ts-expect-error - TS2339 - Property 'statusCode' does not exist on type 'Error | FetchError | IssueViewFetchError'.
		statusCodesNotImpactingReliability.includes(rawError.statusCode)
	) {
		error = CONNECTIVITY_ERROR;
	} else if (!accountId) {
		error = AUTHENTICATION_ERROR;
	}

	if (rawError instanceof IssueViewFetchError) {
		const rawIssueViewFetchError: IssueViewFetchError = rawError;
		const { endpoint, traceId, statusCode, message } = rawIssueViewFetchError;

		return {
			errorMessage: statusCode
				? `${message}, endpoint: ${endpoint}, status code: ${statusCode}`
				: `${message}, endpoint: ${endpoint}`,
			endpoint,
			traceId,
			statusCode,
			error,
		};
	}

	if (rawError instanceof FetchError) {
		const rawFetchError: FetchError = rawError;
		const { message, statusCode, traceId } = rawFetchError;

		return {
			error,
			statusCode,
			errorMessage: `${message}, status code: ${statusCode}`,
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			...(traceId ? { traceId } : ({} as Partial<FetchIssueFailureDescription>)),
		};
	}

	return {
		error,
		statusCode: undefined,
	};
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({
		fieldValueActions,
		fieldConfigActions,
		issueViewLayoutActions,
		ecosystemActions,
		userPreferenceActions,
		permissionActions,
		attachmentActions,
		projectContextActions,
		prefetchedResourceManager,
		jiraSettingsActions,
		issueContextActions,
		associatedIssuesContextActions,
		issueRefreshServiceActions,
	}: {
		fieldValueActions: FieldValueServiceActions;
		fieldConfigActions: FieldConfigServiceActions;
		issueViewLayoutActions: IssueViewLayoutActions;
		ecosystemActions: EcosystemActions;
		userPreferenceActions: UserPreferenceActions;
		permissionActions: ProjectPermissionActions;
		attachmentActions: AttachmentServiceActions;
		projectContextActions: ProjectContextServiceActions;
		prefetchedResourceManager?: ResourceManager;
		jiraSettingsActions: JiraSettingsActions;
		issueContextActions: IssueContextServiceActions;
		associatedIssuesContextActions?: AssociatedIssuesContextActions;
		issueRefreshServiceActions: IssueRefreshServiceActions;
	}) =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(action$: ActionsObservable<any>, store: MiddlewareAPI<State>) =>
		action$.ofType(FETCH_ISSUE_REQUEST, LOAD_NEW_ISSUE).switchMap(() => {
			clearFetchIssueNetworkTimes();
			const state = store.getState();
			const loggedInAccountId = accountIdloggedInUserSelector(state);
			const baseUrl = baseUrlSelector(state);
			const maxTokenLength = issueContextActions.getMaxTokenLength();
			const isServiceDesk = isServiceDeskSelector(state);

			// Since fetch services used in here depend on the Redux store data, we need to extract issue key from there as well,
			// otherwise we might end up with an out of the sync state where we fetch details for an issue from the Redux store
			// but merge it with totally different issue key from the React sweet state.
			const issueKey = issueKeySelector(state);

			issueContextActions.mergeIssueContext({ isArchived: false, isClassifyEditEnabled: false });

			// if we're about to kick off a request for gira data with redux, we're ready for it to arrive
			blockedOnBackendEntryLog.markStartBackendBlockingTimestamp(ISSUE_CRITICAL_DATA_REDUX);

			const fetchAllAppDataWithRetries$ = fetchAllAppDataWithRetries(
				state,
				prefetchedResourceManager,
			);

			const fetchAllAppDataWithRetriesCancellable$ = fetchAllAppDataWithRetries$.takeUntil(
				action$.ofType(CANCEL_REFRESH_ISSUE_REQUEST),
			);

			return fetchAllAppDataWithRetriesCancellable$
				.filter(() => {
					// This filter step prevents edge case of users navigating between issues during preview mode
					// and seeing the other issues data instead of own issue data.
					const transitionedIssueKey = issueKeySelector(state);
					return transitionedIssueKey === issueKey;
				})
				.do(
					({
						childIssuesLimit,
						isAiEnabledForIssue,
						issue: { fields, id, viewScreenId },
						myPreferences,
						permissions,
						attachments,
						jiraSettings,
						project,
						isArchived,
						containersByType,
						childrenIssues,
						subtasks,
						issueLinks,
					}) => {
						storeFields(fields, fieldValueActions, fieldConfigActions, issueKey, project);

						// At this point React sweet state may contain an info about the previous issue.
						// We are updating issue key as the first thing here and later on merge other issue details.
						issueContextActions.mergeIssueContext({ issueKey });

						issueViewLayoutActions.setIssueViewContainersLayout(
							issueKey,
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							containersByType as ContainersByType,
						);

						userPreferenceActions.setUserPreferences(myPreferences);

						permissions &&
							project &&
							permissionActions.setProjectPermissions(
								// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
								toProjectKey(project.projectKey as string),
								permissions,
							);

						if (attachments !== undefined) {
							attachmentActions.setTotalCount(issueKey, attachments.totalCount);
							typeof attachments.deletableCount === 'number' &&
								attachmentActions.setDeletableCount(issueKey, attachments.deletableCount);
							attachmentActions.setVisibleAttachments(issueKey, attachments.nodes);
						}

						jiraSettings && jiraSettingsActions.setJiraSettings(jiraSettings);

						project &&
							projectContextActions.setProjectContext(
								// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
								toProjectKey(project.projectKey as string),
								// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
								project as ProjectContext,
							);

						id &&
							issueContextActions.mergeIssueContext({
								issueId: toIssueId(String(id)),
							});

						if (associatedIssuesContextActions) {
							associatedIssuesContextActions.mergeLocalAssociatedIssuesContext(
								getAssociatedIssuesContext([
									...childrenIssues,
									...subtasks,
									...(issueLinks?.linkedIssueMetas || []),
								]),
							);
						}

						issueContextActions.mergeIssueContext({ isAiEnabledForIssue });

						issueContextActions.mergeIssueContext({
							childIssuesLimit,
							childIssuesIssueLimitUrls: {
								[SUBTASKS]: fields[SUBTASKS]?.issueLimitUrl,
								[CHILDREN_ISSUES]: fields[CHILDREN_ISSUES]?.issueLimitUrl,
								[EPIC_ISSUES]: fields[EPIC_ISSUES]?.issueLimitUrl,
								[PORTFOLIO_CHILD_ISSUES]: fields[PORTFOLIO_CHILD_ISSUES]?.issueLimitUrl,
							},
							viewScreenId,
						});

						const dataClassifcationFieldValue = fields[DATA_CLASSIFICATION_FIELD]?.value || null;
						const isClassifyEditEnabled = !!dataClassifcationFieldValue?.fieldConfig?.isEditable;

						issueContextActions.mergeIssueContext({ isArchived, isClassifyEditEnabled });

						issueRefreshServiceActions.setEvent({
							refreshed: true,
							reason: Reason.OnDemand,
						});
					},
				)
				.mergeMap((gqlData) => {
					const { viewContext, uploadContext, userAuth, ...rest } = gqlData;
					const view$ = viewContext
						? Observable.of(fetchViewContextSuccess(viewContext))
						: fetchViewContextWithErrorHandling(
								baseUrl,
								issueKey,
								maxTokenLength,
								isServiceDesk,
								reportServerTimeViaState(store),
								prefetchedResourceManager?.issueMediaReadPermission,
							);

					let uploadContextAction: UploadContextAction;
					if (uploadContext) {
						// typescript needs to use in operator for union type check
						if ('error' in uploadContext && uploadContext.error != null) {
							uploadContextAction = fetchUploadContextFailure(new Error(uploadContext.error));
						} else if (
							// typescript needs to use in operator for union type check
							'clientId' in uploadContext &&
							uploadContext.clientId != null &&
							uploadContext.serviceHost != null &&
							uploadContext.token != null &&
							uploadContext.tokenLifespanInMs != null &&
							uploadContext.collection != null &&
							uploadContext.tokenIssueTimestamp != null
						) {
							uploadContextAction = fetchUploadContextSuccess({
								clientId: uploadContext.clientId,
								serviceHost: uploadContext.serviceHost,
								token: uploadContext.token,
								tokenLifespanInMs: uploadContext.tokenLifespanInMs,
								collection: uploadContext.collection,
								tokenIssueTimestamp: uploadContext.tokenIssueTimestamp,
							});
						}
					} else {
						uploadContextAction = fetchUploadContextRequest();
					}

					return view$.map((viewContextAction) => {
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						ecosystemActions.setEcosystem(rest.ecosystem as EcosystemState, issueKey);
						return [viewContextAction, uploadContextAction, fetchIssueSuccess(rest)] as const;
					});
				})
				.flatMap((actions) => {
					blockedOnBackendEntryLog.markEndBackendBlockingTimestamp(ISSUE_CRITICAL_DATA_REDUX);
					if (prefetchedResourceManager) {
						prefetchedResourceManager.clearAll();
					}

					// @ts-expect-error - TS2345 - Argument of type 'readonly [{ type: "FETCH_VIEW_CONTEXT_SUCCESS"; payload: ViewContext; } | { type: "FETCH_VIEW_CONTEXT_FAILURE"; payload: { error: Error | { statusCode: number; }; }; }, any, FetchUserAuthRequestAction | FetchUserAuthSuccessAction, { ...; }]' is not assignable to parameter of type 'Action[]'.
					return Observable.of(...actions);
				})
				.catch((error) => {
					const logMessage = 'FETCH_ISSUE_REQUEST: Failed to fetch issue data';
					const transformedError = transformError(error, loggedInAccountId);

					if (transformedError.error === NOT_FOUND_OR_NO_PERMISSION_ERROR) {
						// TODO send metrics SignalFx -> issue not found (BENTO-5187)
					} else {
						trackOrLogClientError('issue.fetch.issue-fetch-epic', logMessage, error);
					}

					return Observable.of(fetchIssueFailure(transformedError));
				});
		});
