import { useOgTenantInfo } from '@atlassian/jira-eoc-tenant-info/src/controllers/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	useIssueId,
	useIsAiEnabledForIssue,
	useIssueKey,
} from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useIsIssueOfIncidentsPractice } from '@atlassian/jira-issue-field-servicedesk-practices/src/services/use-practices-field-value/index.tsx';
import { PROJECT_ARCHIVED } from '@atlassian/jira-issue-shared-types/src/common/types/project-type.tsx';
import {
	useProjectId,
	useProjectKey,
	useProjectStatus,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import { toProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import type { StoreContainerProps } from '../../types.tsx';

export const useSuggestedResourcesStoreProps = () => {
	const issueKey = useIssueKey();
	const issueId = useIssueId();

	const cloudId = useCloudId();

	const projectKey = useProjectKey(issueKey);
	const projectId = toProjectId(useProjectId(projectKey) || '');
	const projectStatus = useProjectStatus(projectKey);
	const isProjectArchived = projectStatus === PROJECT_ARCHIVED;

	const isIssueOfIncidentPractice = useIsIssueOfIncidentsPractice();

	const isAiOptInEnabled = useIsAiEnabledForIssue();
	const { isConsolidated, isTransitioned, isTransitioning } = useOgTenantInfo();

	if (
		!fg('incident_suggested_resources') ||
		!issueId ||
		!isAiOptInEnabled ||
		!(isConsolidated || isTransitioned || isTransitioning) ||
		!projectId ||
		!isIssueOfIncidentPractice ||
		isProjectArchived
	) {
		return null;
	}

	const containerProps: StoreContainerProps = {
		issueId,
		cloudId,
		projectId,
		scope: 'aiops-suggested-resources-incident-view',
	};

	return containerProps;
};
