import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { createGetIssueAnalyticsAttributes } from '../../selectors/properties/index.tsx';
import type { State } from '../../types.tsx';

export const execWithIssueAnalyticsData =
	(
		localIssueId: LocalIssueId | undefined,
		callback: (issueAnalyticsData: object | undefined) => void,
	) =>
	({ getState }: StoreActionApi<State>) => {
		const analyticsData = localIssueId
			? createGetIssueAnalyticsAttributes(localIssueId)(getState())
			: {};

		callback(analyticsData);
	};
