import { createSelector } from 'reselect';
import isEqual from 'lodash/isEqual';
import set from 'lodash/set';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import {
	COUNT_ROLLUP,
	EMPTY_ROLLUP,
	FILLED_ROLLUP,
} from '@atlassian/jira-polaris-domain-field/src/rollup/constants.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { Filter } from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import type { IssuesRemote } from '@atlassian/jira-polaris-remote-issue/src/controllers/types.tsx';
import { jiraLabelsMapping } from '../../../../field/mapping/labels/index.tsx';
import type { PropertyMaps, State } from '../../../types.tsx';
import { safeStringListComparator } from '../../comparators/index.tsx';
import { CSV_SEPARATOR } from '../common/constanst.tsx';
import { createStringValueIntersectionFilter } from '../common/filter-utils.tsx';
import { removePropertyValue } from '../common/remove-utils.tsx';
import type { FieldMapping } from '../types.tsx';

export const labelsMapping = (issuesRemote: IssuesRemote, field: Field): FieldMapping<string[]> => {
	const valueAccessor: FieldMapping<string[]>['valueAccessor'] = (state, props, issueId) => {
		const labelsMap = state.properties.stringList[field.key];

		// normal and published views use different search endpoints, that return different labels order,
		// to have same order and sorting in both views, we sort labels on the FE
		return labelsMap && [...(labelsMap[issueId] ?? [])].sort();
	};

	const valueAccessorToExport: FieldMapping<string>['valueAccessorToExport'] = (
		state,
		props,
		issueId,
	) => valueAccessor(state, props, issueId)?.join(CSV_SEPARATOR) || '';

	return {
		// @ts-expect-error - TS2783 - 'field' is specified more than once, so this usage will be overwritten.
		field,
		...jiraLabelsMapping(issuesRemote, field),
		setMutable: (maps, issueId, value) => set(maps, ['stringList', field.key, issueId], value),
		// @ts-expect-error(PARTIAL_RECORD) TS2322 - Type '(maps: PropertyMaps, issueId: LocalIssueId, value?: string[]) => { stringList: { [x: string]: Record<string, string[]> | { [x: string]: string[] | undefined; }; }; number: PropertyMap<number>; string: PropertyMap<...>; ... 15 more ...; commentsMetadata: CommentsMetadataMap; }' is not assignable to type '(arg1: PropertyMaps, arg2: string, arg3: string[] | undefined) => PropertyMaps'.
		setImmutable: (maps: PropertyMaps, issueId: LocalIssueId, value?: string[]) => {
			if (maps.stringList[field.key] && isEqual(maps.stringList[field.key][issueId], value)) {
				return maps;
			}
			return {
				...maps,
				stringList: {
					...maps.stringList,
					[field.key]: {
						...maps.stringList[field.key],
						[issueId]: value,
					},
				},
			};
		},
		remove: (maps: PropertyMaps, issueId: LocalIssueId) =>
			removePropertyValue(field.key, maps, issueId, 'stringList'),
		modifyImmutableIfMultiValueField: (
			maps: PropertyMaps,
			issueId: LocalIssueId,
			addValues: string[] | undefined,
			removeValues: string[] = [],
		) => {
			if (!addValues || addValues.length === 0) {
				return maps;
			}
			const oldValues = (maps.stringList[field.key] || {})[issueId] || [];
			const newValues = oldValues
				.filter((oldValue) => !removeValues.includes(oldValue))
				.concat(
					addValues.filter(
						(newValue) => oldValues.findIndex((oldValue) => oldValue === newValue) < 0,
					),
				);
			return {
				...maps,
				stringList: {
					...maps.stringList,
					[field.key]: {
						...maps.stringList[field.key],
						[issueId]: newValues,
					},
				},
			};
		},
		comparator: safeStringListComparator,
		valueAccessor,
		valueAccessorToExport,
		getAllValues: (state) => {
			if (state.properties.stringList[field.key] !== undefined) {
				return state.properties.stringList[field.key];
			}
			return {};
		},
		getGroupIdentitiesSelector: (fieldKey, issueIdsSelector) =>
			createSelector(
				issueIdsSelector,
				(state: State) => state.properties.stringList[fieldKey],
				(ids, stringList) =>
					ids.reduce(
						(result, issueId) =>
							Object.assign(result, {
								[issueId]:
									stringList !== undefined && stringList[issueId] !== undefined
										? stringList[issueId].map((label) => ({
												groupIdentity: label,
												value: [label],
											}))
										: [],
							}),
						{},
					),
			),
		getGroupIdentities: (state, props, issueId) => {
			const value =
				state.properties.stringList[field.key] && state.properties.stringList[field.key][issueId];
			return value !== undefined
				? value.map((label) => ({ groupIdentity: label, value: [label] }))
				: [];
		},
		getRollupOperations: () => [COUNT_ROLLUP, EMPTY_ROLLUP, FILLED_ROLLUP],
		allowEmptyGroup: true,
		getLabel: (groupIdentity) => groupIdentity,
		getFilter: (filter: Filter) => {
			if (filter.type === 'FIELD' && filter.field === field.key) {
				return createStringValueIntersectionFilter(filter);
			}
			return undefined;
		},
	};
};
