/**
 * @generated SignedSource<<3847af97eb61301e67dba3535da0335d>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutMultiGroupPickerField_IssueViewMultiGroupPickerField$data = {
  readonly __typename: "JiraMultipleGroupPickerField";
  readonly description: string | null | undefined;
  readonly fieldConfig: {
    readonly isEditable: boolean | null | undefined;
    readonly isRequired: boolean | null | undefined;
  } | null | undefined;
  readonly fieldId: string;
  readonly id: string;
  readonly issue: {
    readonly key: string;
  } | null | undefined;
  readonly name: string;
  readonly selectedGroupsConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly groupId: string;
        readonly id: string;
        readonly name: string;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly type: string;
  readonly " $fragmentType": "ui_issueViewLayoutMultiGroupPickerField_IssueViewMultiGroupPickerField";
};
export type ui_issueViewLayoutMultiGroupPickerField_IssueViewMultiGroupPickerField$key = {
  readonly " $data"?: ui_issueViewLayoutMultiGroupPickerField_IssueViewMultiGroupPickerField$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutMultiGroupPickerField_IssueViewMultiGroupPickerField">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "id"
},
v1 = {
  "kind": "ScalarField",
  "name": "name"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutMultiGroupPickerField_IssueViewMultiGroupPickerField",
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    (v1/*: any*/),
    {
      "kind": "ScalarField",
      "name": "description"
    },
    {
      "kind": "ScalarField",
      "name": "__typename"
    },
    {
      "concreteType": "JiraFieldConfig",
      "kind": "LinkedField",
      "name": "fieldConfig",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isEditable"
        },
        {
          "kind": "ScalarField",
          "name": "isRequired"
        }
      ]
    },
    {
      "concreteType": "JiraIssue",
      "kind": "LinkedField",
      "name": "issue",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "key"
        }
      ]
    },
    {
      "concreteType": "JiraGroupConnection",
      "kind": "LinkedField",
      "name": "selectedGroupsConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraGroupEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraGroup",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "kind": "ScalarField",
                  "name": "groupId"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraMultipleGroupPickerField"
};
})();

(node as any).hash = "e0d4ecec1c3ebcc0569a49562f8e926d";

export default node;
