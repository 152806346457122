import React from 'react';
import type { collectAll, collectAny } from '@atlassian/experience-tracker';
import { useExperienceFail } from '@atlassian/jira-experience-tracker/src/ui/experience-fail/index.tsx';
import { ExperienceStart } from '@atlassian/jira-experience-tracker/src/ui/experience-start/index.tsx';
import { ExperienceSuccess } from '@atlassian/jira-experience-tracker/src/ui/experience-success/index.tsx';
import { JIRA_SOFTWARE } from '@atlassian/jira-shared-types/src/application.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';

export const VIEW_SECURITY_FIELD_IN_THE_ISSUE_VIEW = 'viewSecurityFieldInTheIssueView' as const;

type SecurityFieldInTheIssueViewExperiencesProps = {
	experienceName: typeof VIEW_SECURITY_FIELD_IN_THE_ISSUE_VIEW;
};
type SecurityFieldInTheIssueViewExperiencesSuccessProps = {
	experienceName: typeof VIEW_SECURITY_FIELD_IN_THE_ISSUE_VIEW;
	hasVulnerabilities: boolean;
};
type Attributes = {
	readonly [key: string]: string | number | boolean | null;
};

type OnExperienceFail = (location: string, error: Error, additionalAttributes?: Attributes) => void;

type SecurityFieldInTheIssueViewExperienceStartProps = {
	experienceId: string | null;
	experienceName?: typeof VIEW_SECURITY_FIELD_IN_THE_ISSUE_VIEW;
	collect?: ReturnType<typeof collectAll> | ReturnType<typeof collectAny>;
};

export const ViewSecurityFieldInTheIssueViewSuccessTracker = ({
	experienceName = VIEW_SECURITY_FIELD_IN_THE_ISSUE_VIEW,
	hasVulnerabilities,
}: SecurityFieldInTheIssueViewExperiencesSuccessProps) => (
	<ExperienceSuccess experience={experienceName} attributes={{ hasVulnerabilities }} />
);

export const useSecurityFieldInTheIssueViewExperiencesFailed = ({
	experienceName = VIEW_SECURITY_FIELD_IN_THE_ISSUE_VIEW,
}: SecurityFieldInTheIssueViewExperiencesProps): OnExperienceFail =>
	useExperienceFail({
		experience: experienceName,
	});

export const ViewSecurityFieldInTheIssueViewExperienceStart = ({
	experienceId = Date.now().toString(),
	experienceName = VIEW_SECURITY_FIELD_IN_THE_ISSUE_VIEW,
	collect,
}: SecurityFieldInTheIssueViewExperienceStartProps) => {
	const appEditions = useAppEditions();
	return (
		<ExperienceStart
			experienceId={experienceId}
			experience={experienceName}
			analyticsSource="security"
			edition={appEditions.software}
			application={JIRA_SOFTWARE}
			collect={collect || undefined}
		/>
	);
};
