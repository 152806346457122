import type { ViewSet } from '@atlassian/jira-polaris-domain-view/src/view-set/types.tsx';
import type { View } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';

export const updateViewState = (
	currentViewSets: ViewSet[],
	predicate: (arg1: View) => boolean,
	mutation: (arg: View) => View,
): {
	changedView?: View;
	currentView?: View;
	viewSets: ViewSet[];
} => {
	let changedView: View | undefined;
	let currentView: View | undefined;

	const mutateView = (view: View): View => {
		if (predicate(view)) {
			currentView = view;
			changedView = mutation(view);

			return changedView;
		}
		return view;
	};

	const newViewSets = currentViewSets.map((element) => {
		const views = element.views.map(mutateView);
		const viewSets = element.viewSets?.map((viewSet: ViewSet) => ({
			...viewSet,
			views: viewSet.views.map(mutateView),
		}));

		return {
			...element,
			views,
			viewSets,
		};
	});

	return {
		changedView,
		currentView,
		viewSets: changedView !== currentView ? newViewSets : currentViewSets,
	};
};
