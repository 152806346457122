import { createJpdContainer } from '@atlassian/jira-polaris-lib-react-sweet-state-utils/src/utils/hooks/index.tsx';
import { createPolarisStore } from '@atlassian/jira-polaris-lib-react-sweet-state-utils/src/utils/store/index.tsx';
import actions from './actions/index.tsx';
import { EMPTY_STATE } from './constants.tsx';
import type { State, Props } from './types.tsx';

export type Actions = typeof actions;

export const ProjectStore = createPolarisStore<State, Actions>({
	initialState: EMPTY_STATE,
	actions,
	name: 'PolarisProjectStore',
});

const { Container, useActions, createHook, createHigherLevelHook } = createJpdContainer<
	Props,
	State,
	Actions
>(ProjectStore, {
	onInit:
		() =>
		({ dispatch }) => {
			dispatch(actions.loadProjectInformation());
			dispatch(actions.loadProject(false, true));
			dispatch(actions.loadInsights({ fireAnalyticsEvent: true }));
			dispatch(actions.loadPlays(true));
		},
	onUpdate:
		() =>
		({ dispatch }) => {
			dispatch(actions.loadProject());
			dispatch(actions.loadInsights());
			dispatch(actions.loadPlays());
		},
	onCleanup:
		() =>
		({ dispatch }) => {
			dispatch(actions.unLoadProject());
		},
});

export const useProjectActions = useActions;
export const ProjectContainer = Container;
export const createProjectHook = createHook;
export const createHigherLevelProjectHook = createHigherLevelHook;
