import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	failedFetch: {
		id: 'issue-field-team-editview-full.team.failed-fetch',
		defaultMessage: 'Search failed.',
		description: 'Message when fetching suggestions fails',
	},
	loading: {
		id: 'issue-field-team-editview-full.team.loading',
		defaultMessage: 'Searching...',
		description: 'Message when options are being loaded from the server.',
	},
	placeholder: {
		id: 'issue-field-team-editview-full.team.placeholder',
		defaultMessage: 'Choose a team',
		description: 'A placeholder shown in the team field while no value is selected',
	},
	defaultNoValueText: {
		id: 'issue-field-team-editview-full.team.default-no-value-text',
		defaultMessage: 'None',
		description: 'The default text displayed on the no team option',
	},
	createTeamButtonText: {
		id: 'issue-field-team-editview-full.team.create-team-button-text',
		defaultMessage: 'Create a team',
		description: 'The label of the inline Create team button in the teams field dropdown',
	},
	emptyStateDescription: {
		id: 'issue-field-team-editview-full.team.empty-state-description',
		defaultMessage: 'Create a team to assign work across Jira and track it on your team’s profile',
		description: 'The description for the empty state of the teams field dropdown',
	},
	noOptionsMessage: {
		id: 'issue-field-team-editview-full.team.no-options-message',
		defaultMessage: 'No options',
		description: 'The title for the list of all teams',
	},
});
