import { useCallback } from 'react';
import 'rxjs/add/observable/timer';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/debounce';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import isEmpty from 'lodash/isEmpty';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { fetchIdeasSuggestions } from '../services/jira/ideas-search/index.tsx';
import { fetchIssueSuggestions } from '../services/jira/issue-suggest-v2/index.tsx';
import type { ProjectType, FetchQueryParams, GroupedOption, IssueOption } from '../types.tsx';

type FetchIssuesProps = {
	projectId: string | undefined;
	search: string;
	excludedProjectTypes: ProjectType[];
	hideArchived: boolean;
	exactIssueKeySearch?: boolean;
	excludedIssueIds: number[];
};

export const useFetchIssues = (
	isIdeasSearch?: boolean,
	issueKey?: IssueKey,
	archivedFieldLabel?: string,
) =>
	useCallback(
		({
			projectId,
			search,
			excludedProjectTypes,
			hideArchived,
			exactIssueKeySearch,
			excludedIssueIds,
		}: FetchIssuesProps): Promise<IssueOption[]> => {
			if (isIdeasSearch) {
				return fetchIdeasSuggestions({
					issueKey: issueKey || '',
					query: search,
					exactIssueKeySearch,
				}).then((issues) =>
					issues.map((issue) => ({
						label: issue.summaryText,
						value: `${issue.key} ${issue.summaryText}`,
						item: issue,
					})),
				);
			}
			return fetchIssueSuggestions({
				issueKey: issueKey || '',
				projectId,
				excludedIssueIds,
				query: search,
				excludedProjectTypes,
				archivedFieldLabel: hideArchived ? archivedFieldLabel : undefined,
				exactIssueKeySearch,
			}).then((issues) =>
				issues.map((issue) => ({
					label: issue.summaryText,
					value: `${issue.key} ${issue.summaryText}`,
					item: issue,
				})),
			);
		},
		[isIdeasSearch, issueKey, archivedFieldLabel],
	);

export const getSearchParams = (inputValue: string) => {
	const issueKeyMatch = inputValue.match(
		/(browse\/|selectedIssue=|\/queues\/issue\/|\/queues\/.+\/.+\/)*(?<issueKey>([A-Z][A-Z0-9]+)-([0-9]+))/,
	);
	let searchString = inputValue;
	let isExactIssueSearchKey = false;

	if (issueKeyMatch != null && issueKeyMatch.groups?.issueKey != null) {
		searchString = issueKeyMatch.groups.issueKey;
		isExactIssueSearchKey = true;
	}

	return {
		searchString,
		isExactIssueSearchKey,
	};
};

export const createFetchQuery = (
	setIsLoading: (value: boolean) => void,
	excludedIssueIds: number[],
	fetchIssues: ({
		projectId,
		search,
		excludedProjectTypes,
		hideArchived,
		exactIssueKeySearch,
		excludedIssueIds,
	}: FetchIssuesProps) => Promise<IssueOption[]>,
	setOptions: (value?: IssueOption[]) => void,
	setInternalDefaultOptions: (value: IssueOption[] | GroupedOption | undefined) => void,
	defaultOptions?: GroupedOption,
) => {
	const query$ = new Subject<FetchQueryParams>();
	const subscription = query$
		.map((x) => {
			setIsLoading(true);
			return x;
		})
		.debounce(({ isImmediate }) =>
			isImmediate ? Observable.empty<never>() : Observable.timer(750),
		)
		.switchMap(
			({
				query,
				currentProjectId,
				isInitialLoading,
				currentExcludedProjectTypes,
				hideArchivedIssues,
				issueKeySearch,
			}) =>
				fetchIssues({
					projectId: currentProjectId,
					search: query,
					excludedProjectTypes: currentExcludedProjectTypes,
					hideArchived: hideArchivedIssues,
					exactIssueKeySearch: issueKeySearch,
					excludedIssueIds,
				}).then((suggestions) => ({ suggestions, isInitialLoading })),
		)
		.subscribe(({ suggestions, isInitialLoading }) => {
			if (defaultOptions !== undefined && isInitialLoading) {
				setInternalDefaultOptions(suggestions);
			}
			if (defaultOptions !== undefined && !isEmpty(suggestions)) {
				const newSuggestions = suggestions.filter(
					(fetchedItem) =>
						!defaultOptions.options.find(
							(defaultItem) => defaultItem.item?.id === fetchedItem.item?.id,
						),
				);
				setOptions(newSuggestions);
			} else {
				setOptions(suggestions);
			}
			setIsLoading(false);
		});

	return {
		query$,
		unsubscribe: () => subscription.unsubscribe(),
	};
};
