import React from 'react';
import { expVal } from '@atlassian/jira-feature-experiments';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import { Emphasise } from '@atlassian/jira-issue-view-activity-common/src/styles/index.tsx';
import GroupedChange from '../../../../common/ui/grouped-change/index.tsx';
import HistoryItem from '../../../../common/ui/history-item/index.tsx';
import type { WorkLoggedHistoryItem } from '../../../../types.tsx';
import messages from './messages.tsx';

type Props = {
	isGrouped?: Boolean;
	historyItem: WorkLoggedHistoryItem;
};

const WorklogHistoryItem = ({ historyItem, isGrouped = false }: Props) => {
	const { actor, timestamp, comment, timeSpent } = historyItem;
	const { formatMessage } = useIntl();

	return (
		<FormattedMessage
			{...messages.loggedWork}
			values={{
				time: <Emphasise key="priority">{timeSpent}</Emphasise>,
			}}
		>
			{(...action) =>
				isGrouped && expVal('issue_view_activity_timeline', 'isActivityTimelineEnabled', false) ? (
					<GroupedChange
						field={formatMessage(messages.loggedWork, {
							time: <Emphasise key="priority">{timeSpent}</Emphasise>,
						})}
					/>
				) : (
					<HistoryItem
						// eslint-disable-next-line jira/integration/test-id-by-folder-structure
						testId="issue-history.ui.history-items.worklog-history-item.history-item"
						actor={actor}
						action={action}
						timestamp={timestamp}
						change={comment}
					/>
				)
			}
		</FormattedMessage>
	);
};

export default WorklogHistoryItem;
