// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	addAttachmentLabel: {
		id: 'issue.label-for-add-attachment-button',
		defaultMessage: 'Add attachment',
		description:
			'The label to be used on the quick add button for adding an attachment to an issue',
	},
});
