import React from 'react';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { fetchSortedCommentsRequest } from '@atlassian/jira-issue-view-store/src/actions/comment-actions.tsx';
import useToggleFlag from './hook.tsx';
import useToggleFlagWithComment from './toggle-flag-with-comment.tsx';
import type { ConnectedComponentProps, Props } from './types.tsx';

export const FlagIssueActionView = ({ onClick, itemKey, label }: Props) => {
	const toggleFlag = useToggleFlag(itemKey, onClick);

	return (
		<DropdownItem
			key="flagIssue"
			/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
			data-testid="issue.views.issue-base.foundation.issue-actions.add-to-issue-dropdown-group.flag.flag-issue-action-view"
			onClick={toggleFlag}
		>
			{label}
		</DropdownItem>
	);
};

export const FlagIssueWithComment = ({
	onClick,
	itemKey,
	label,
	onAddComment,
}: ConnectedComponentProps) => {
	const onAddFlag = () => {
		onClick?.(itemKey);
		onAddComment();
	};

	const toggleFlag = useToggleFlagWithComment(itemKey, onAddFlag);

	return (
		<DropdownItem
			key="flagIssue"
			/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
			data-testid="issue.views.issue-base.foundation.issue-actions.add-to-issue-dropdown-group.flag.flag-issue-action-view"
			onClick={toggleFlag}
		>
			{label}
		</DropdownItem>
	);
};

export const ConnectFlagIssueWithComment = connect(null, (dispatch) => ({
	onAddComment: () => {
		dispatch(fetchSortedCommentsRequest());
	},
}))(FlagIssueWithComment);

const FlagIssue = componentWithFG(
	'task_off_track_experiment_kill_switch',
	ConnectFlagIssueWithComment,
	FlagIssueActionView,
);

export default FlagIssue;
