// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	attachedFormsHeader: {
		id: 'issue-view.forms-panel.attached-forms-header',
		defaultMessage: 'Attached forms',
		description: 'Header for attached forms section in jira issue view',
	},
	heading: {
		id: 'issue-view.forms-panel.heading',
		defaultMessage: 'Forms',
		description: 'Heading for forms panel in jira issue view',
	},
	useFormsErrorTitle: {
		id: 'issue-view.forms-panel.proforma.error',
		defaultMessage: 'Error loading forms',
		description:
			'Title of error flag when there was a problem loading the Proforma forms on the issue view',
	},
	useFormsErrorMessage: {
		id: 'issue-view.forms-panel.proforma.error-message',
		defaultMessage: 'Please try refreshing the page',
		description:
			'Body of error flag when there was a problem loading the Proforma forms on the issue view',
	},
	addFormIconLabel: {
		id: 'issue-view.forms-panel.proforma.add-form-icon-label',
		defaultMessage: 'Add form',
		description: "Label in the Forms panel 'Add form' icon which opens the form selection dropdown",
	},
});
