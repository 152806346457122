import type { Observable } from 'rxjs/Observable';
import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';
import 'rxjs/add/operator/map';
import type { User } from '@atlassian/jira-issue-shared-types/src/common/types/user-type.tsx';
import type { IssueKey, BaseUrl } from '@atlassian/jira-shared-types/src/general.tsx';

type Params = {
	baseUrl: BaseUrl;
	issueKey: IssueKey;
};

type Watcher = {
	accountId: string;
	avatarUrls: Record<string, string>;
	displayName: string;
	timeZone: string;
};

export const fetchWatchers = ({ baseUrl, issueKey }: Params): Observable<User[]> =>
	fetchJson$<{ watchers: Watcher[] }>(`${baseUrl}/rest/api/2/issue/${issueKey}/watchers`, {
		method: 'get',
	}).map((response) =>
		response.watchers.map((watcher) => ({
			id: watcher.accountId,
			name: undefined,
			displayName: watcher.displayName,
			avatarUrl: watcher.avatarUrls['48x48'],
			timeZone: watcher.timeZone,
		})),
	);
