import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	requestRaised: {
		id: 'servicedesk-request-details-panel.reporter-and-channel.request-raised',
		defaultMessage: 'raised this request',
		description:
			'Text to be displayed at the top in description section showing reporter and channel details',
	},
	via: {
		id: 'servicedesk-request-details-panel.reporter-and-channel.via',
		defaultMessage: 'via',
		description:
			'Text to be displayed at the top in description section showing reporter and channel details',
	},
	noReporter: {
		id: 'servicedesk-request-details-panel.reporter-and-channel.no-reporter',
		defaultMessage: 'Anonymous',
		description: 'Text to show when there is no reporter',
	},
});
