import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import AsyncIcon from '@atlassian/jira-common-components-async-icon/src/view.tsx';

type Props = {
	children: ReactNode;
	iconUrl: string | null | undefined;
	iconTitle: string | null | undefined;
};

export default function TextWithIcon(props: Props) {
	const { children, iconTitle, iconUrl } = props;

	return (
		<Wrapper>
			<AsyncIcon url={iconUrl} title={iconTitle} alt={iconTitle} />
			<Text>{children}</Text>
		</Wrapper>
	);
}

TextWithIcon.defaultProps = {
	iconUrl: undefined,
	iconTitle: '',
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Text = styled.span({
	marginLeft: token('space.100', '8px'),
});
