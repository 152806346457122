/**
 * @generated SignedSource<<eacbd8425296081c9170deb3a33013e8>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type AccountStatus = "active" | "closed" | "inactive" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type people_issueFieldPeopleInlineEditFull_PeopleInlineEditViewWithIsEditable_fragmentRef$data = {
  readonly id: string;
  readonly name: string;
  readonly selectedUsersConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly accountId: string;
        readonly accountStatus: AccountStatus;
        readonly email?: string | null | undefined;
        readonly id: string;
        readonly name: string;
        readonly picture: AGG$URL;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"people_issueFieldPeopleReadviewFull_PeopleReadView">;
  readonly " $fragmentType": "people_issueFieldPeopleInlineEditFull_PeopleInlineEditViewWithIsEditable_fragmentRef";
};
export type people_issueFieldPeopleInlineEditFull_PeopleInlineEditViewWithIsEditable_fragmentRef$key = {
  readonly " $data"?: people_issueFieldPeopleInlineEditFull_PeopleInlineEditViewWithIsEditable_fragmentRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"people_issueFieldPeopleInlineEditFull_PeopleInlineEditViewWithIsEditable_fragmentRef">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "id"
},
v1 = {
  "kind": "ScalarField",
  "name": "name"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "people_issueFieldPeopleInlineEditFull_PeopleInlineEditViewWithIsEditable_fragmentRef",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "FragmentSpread",
      "name": "people_issueFieldPeopleReadviewFull_PeopleReadView"
    },
    {
      "concreteType": "JiraUserConnection",
      "kind": "LinkedField",
      "name": "selectedUsersConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraUserEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "accountId"
                },
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "name": "accountStatus"
                },
                (v1/*: any*/),
                {
                  "kind": "ScalarField",
                  "name": "picture"
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "email"
                    }
                  ],
                  "type": "AtlassianAccountUser"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraPeopleField"
};
})();

(node as any).hash = "748ee006bbb63a62e4a494346523bfae";

export default node;
