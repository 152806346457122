import React from 'react';
import { AsyncExportConfluenceModal } from '@atlassian/jira-servicedesk-post-incident-review/src/ui/export-confluence-modal/async.tsx';
import { useExportConfluenceDialog } from './controller/index.tsx';

const ExportConfluenceModal = () => {
	const [{ isOpen }, { closeExportConfluenceDialog }] = useExportConfluenceDialog();
	return isOpen ? <AsyncExportConfluenceModal onClose={closeExportConfluenceDialog} /> : null;
};

export default ExportConfluenceModal;
