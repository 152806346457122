import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	learnMore: {
		id: 'common-components-blocked-apps-info-banner.learn-more',
		defaultMessage: 'View blocked apps',
		description: 'Describes an action to redirect the user to the page with blocked extensions.',
	},
	dontShowAgain: {
		id: 'common-components-blocked-apps-info-banner.dont-show-again',
		defaultMessage: 'Don’t show again',
		description: 'Describes an action to redirect the user to the page with blocked extensions.',
	},
});
