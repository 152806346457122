// GALILEO-404 START
import React, { useCallback } from 'react';
import {
	Behaviours,
	Prompts,
	TargetTabs,
	createProvisioningApiExtensions,
} from '@atlassiansox/cross-flow-extensions';
import { Journeys, Targets, useCrossFlow } from '@atlassiansox/cross-flow-support';
import LightbulbIcon from '@atlaskit/icon/core/lightbulb';
import Lozenge from '@atlaskit/lozenge';
import { Grid, Text, Flex, Anchor, xcss, Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { useIsFullPageIssueView } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { messages } from './messages.tsx';

export const CrossflowIdeaCard = ({
	parentIssue,
	parentName,
}: {
	parentIssue: string;
	parentName: string;
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isFullPageIssueView: boolean = useIsFullPageIssueView();
	const crossFlow = useCrossFlow();
	const { formatMessage } = useIntl();

	const handleOnClick = useCallback(
		async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
			if (crossFlow.isEnabled) {
				// Keep issue view open
				e.preventDefault();

				const extensions = createProvisioningApiExtensions({
					actions: {
						primary: {
							behaviour: Behaviours.LAND,
							prompt: Prompts.RETURN_TO_PRODUCT,
							options: {
								targetTab: TargetTabs.NEW,
								urlParams: { SELECTED_ISSUE_PARAM: parentIssue },
							},
						},
					},
					jobName: 'jira-jpd-issueview-link',
					jobParams: {
						issueKey: parentIssue,
						ideaName: parentName,
					},
				});

				await crossFlow.api.open({
					journey: Journeys.GET_STARTED,
					sourceComponent: 'DiscoverIdeasIssueViewSmartLink',
					sourceContext: 'jira',
					targetProduct: Targets.JIRA_PRODUCT_DISCOVERY,
					extensions,
				});
			}

			fireUIAnalytics(createAnalyticsEvent({}), 'card clicked', 'crossflowIdeaLinkedCard', {
				target: isFullPageIssueView ? 'sidebar' : 'newTab',
				parentIssue,
			});
		},
		[createAnalyticsEvent, isFullPageIssueView, parentIssue, crossFlow, parentName],
	);

	return (
		<Anchor
			href={`/browse/${parentIssue}`}
			onClick={handleOnClick}
			xcss={isVisualRefreshEnabled() ? ideaCardStyles : ideaCardStylesOld}
			testId="issue-view-jpd-ideas.ui.crossflow-idea-card.crossflow-linked-idea-card"
		>
			<Grid templateColumns="20px 1fr" gap="space.100">
				<Tooltip content={formatMessage(messages.crossflowIdeaTooltip)}>
					<Flex>
						<LightbulbIcon
							testId="issue-view-jpd-ideas.ui.crossflow-idea-card.crossflow-linked-idea-card-icon"
							color={token('color.text.accent.orange')}
							label={formatMessage(messages.tryLozenge)}
						/>
					</Flex>
				</Tooltip>
				<Flex>
					<Text maxLines={1}>{formatMessage(messages.crossflowIdeaSummary)}</Text>
					<Box xcss={lozengeStyles}>
						<Lozenge appearance="new">{formatMessage(messages.tryLozenge)}</Lozenge>
					</Box>
				</Flex>
			</Grid>
		</Anchor>
	);
};

const ideaCardStyles = xcss({
	textDecoration: 'none',
	background: 'none',
	cursor: 'pointer',

	border: 'solid',
	borderColor: 'color.border',
	borderWidth: '0px',

	borderBottomWidth: '1px',
	borderLeftWidth: '1px',
	borderRightWidth: '1px',

	paddingLeft: 'space.100',
	paddingRight: 'space.100',

	display: 'flex',
	alignItems: 'center',
	height: '40px',
	justifyContent: 'space-between',

	transition: '0.3s',
	':hover': {
		backgroundColor: 'color.background.neutral.subtle.hovered',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors
	':first-of-type': {
		borderTopWidth: '1px',
		borderTopLeftRadius: 'border.radius',
		borderTopRightRadius: 'border.radius',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':last-of-type': {
		borderBottomWidth: '1px',
		borderBottomLeftRadius: 'border.radius',
		borderBottomRightRadius: 'border.radius',
	},
});

const ideaCardStylesOld = xcss({
	borderBottom: `1px solid ${token('color.border', '#ebecf0')}`,
	display: 'flex',
	paddingLeft: 'space.100',
	paddingRight: 'space.100',
	height: '40px',
	alignItems: 'center',
	transition: '0.3s',
	cursor: 'pointer',
	color: 'color.text',
	textDecoration: 'none',
	justifyContent: 'space-between',
	':hover': {
		backgroundColor: 'color.background.neutral.subtle.hovered',
		color: 'color.text',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors
	':last-child': {
		border: 'none',
	},
});

const lozengeStyles = xcss({ marginLeft: 'space.100' });
// GALILEO-404 END
