import React, { useState } from 'react';
import { styled } from '@compiled/react';
import Popup from '@atlaskit/popup';
import { fontFallback } from '@atlaskit/theme/typography';
import { token } from '@atlaskit/tokens';
import { ClassificationIcon } from './classification-icon.tsx';
import type { ClassifyButtonProps, ColorValues, ContainerProps } from './types.tsx';

export const colorValues: ColorValues = {
	fontColor: '#0C66E4',
	bgColor: '#E9F2FF',
	bgColorHovered: '#99C0FF',
	bgColorPressed: '#66A1FF',
};

export const ClassifyButton = ({ renderPopupComponent, currentName }: ClassifyButtonProps) => {
	const [isPopupOpen, setIsPopupOpen] = useState(true);

	const handleContainerClicked = (): void => {
		setIsPopupOpen(!isPopupOpen);
	};

	const closePopup = () => {
		setIsPopupOpen(false);
	};

	return (
		<Popup
			placement="bottom-start"
			trigger={(triggerProps) => (
				<ContainerButton
					data-testid="issue-classification-badge.ui.classify-button.container-button"
					colorValues={colorValues}
					{...triggerProps}
					onClick={() => {
						handleContainerClicked();
					}}
				>
					<ClassificationIcon width={14} height={14} color={colorValues.fontColor} />

					<Name data-testid="issue-classification-badge.ui.classify-button.name">
						{currentName}
					</Name>
				</ContainerButton>
			)}
			content={() => renderPopupComponent(closePopup)}
			isOpen={isPopupOpen}
			onClose={() => {
				closePopup();
			}}
			zIndex={700}
		/>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
const ContainerButton = styled.button<ContainerProps>((props) => ({
	display: 'inline-flex',
	maxWidth: '100%',
	whiteSpace: 'nowrap',
	width: 'auto',
	alignItems: 'center',
	padding: token('space.025', '2px'),
	paddingRight: token('space.050', '4px'),
	border: 'none',
	borderRadius: token('border.radius', '3px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	color: props.colorValues.fontColor,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	background: props.colorValues.bgColor,
	cursor: 'pointer',
	outline: 0,
	transition: 'background 0.1s ease-out 0s',
	'&:focus-visible': {
		outline: `1px solid ${token('color.border.focused', '#388BFF')}`,
		outlineOffset: token('space.025', '2px'),
	},
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		background: props.colorValues.bgColorHovered,
	},
	'&:active': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		background: props.colorValues.bgColorPressed,
	},
}));

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Name = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	font: token('font.body.small', fontFallback.body.small),
	fontWeight: token('font.weight.medium', '500'),
	marginLeft: token('space.025', '2px'),
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});
