import get from 'lodash/get';
import { useEditField } from '@atlassian/jira-issue-field-base/src/services/edit-field-service/main.tsx';
import { useFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import { ComponentsKey } from '@atlassian/jira-providers-issue/src/model/issue-system-fields.tsx';
import type {
	ComponentsFieldValue,
	ComponentsSaveId,
	ComponentFieldOptions,
	ComponentsResponse,
} from './types.tsx';

export const useComponentsField = ({
	issueKey,
	initialValue,
	onSuccess,
	onFailure,
}: ComponentFieldOptions) => {
	const [{ value: fieldConfig }] = useFieldConfig(issueKey, ComponentsKey);
	const fieldType = get(fieldConfig, 'schema.type', 'array');

	const [{ loading, value, error }, { saveById, saveValue, resetError }] = useEditField<
		ComponentsFieldValue,
		null,
		ComponentsResponse,
		ComponentsSaveId
	>({
		fieldKey: ComponentsKey,
		issueKey,
		fieldType,
		initialValue: initialValue || null,
		onSuccess,
		onFailure,
	});

	return [
		{ loading, value, error, fieldConfig },
		{ saveValue, saveById, resetError },
	] as const;
};
