/**
 * @generated SignedSource<<da0c5ae26f62f08e06440133cad68f82>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutComponentsField_IssueViewComponentsField$data = {
  readonly __typename: "JiraComponentsField";
  readonly description: string | null | undefined;
  readonly fieldConfig: {
    readonly isEditable: boolean | null | undefined;
  } | null | undefined;
  readonly fieldId: string;
  readonly id: string;
  readonly issue: {
    readonly projectField: {
      readonly project: {
        readonly key: string;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly name: string;
  readonly selectedComponentsConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly ari: string | null | undefined;
        readonly componentId: string;
        readonly description: string | null | undefined;
        readonly id: string;
        readonly metadata: AGG$JSON | null | undefined;
        readonly name: string | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly type: string;
  readonly " $fragmentType": "ui_issueViewLayoutComponentsField_IssueViewComponentsField";
};
export type ui_issueViewLayoutComponentsField_IssueViewComponentsField$key = {
  readonly " $data"?: ui_issueViewLayoutComponentsField_IssueViewComponentsField$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutComponentsField_IssueViewComponentsField">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "id"
},
v1 = {
  "kind": "ScalarField",
  "name": "name"
},
v2 = {
  "kind": "ScalarField",
  "name": "description"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutComponentsField_IssueViewComponentsField",
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "kind": "ScalarField",
      "name": "__typename"
    },
    {
      "concreteType": "JiraFieldConfig",
      "kind": "LinkedField",
      "name": "fieldConfig",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isEditable"
        }
      ]
    },
    {
      "concreteType": "JiraIssue",
      "kind": "LinkedField",
      "name": "issue",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraProjectField",
          "kind": "LinkedField",
          "name": "projectField",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraProject",
              "kind": "LinkedField",
              "name": "project",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "key"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "concreteType": "JiraComponentConnection",
      "kind": "LinkedField",
      "name": "selectedComponentsConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraComponentEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraComponent",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "kind": "ScalarField",
                  "name": "ari"
                },
                {
                  "kind": "ScalarField",
                  "name": "metadata"
                },
                {
                  "kind": "ScalarField",
                  "name": "componentId"
                },
                (v2/*: any*/)
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraComponentsField"
};
})();

(node as any).hash = "c110ef1b9c27239ad97290a295017148";

export default node;
