import React, { type FC } from 'react';

import { FormattedMessage } from 'react-intl-next';

import Link from '@atlaskit/link';

import IntlProvider from '../../common/utils/IntlProvider';

import messages from './messages';
import type { RecaptchaTermsAndPolicyProps } from './types';

const RecaptchaTermsAndPolicy: FC<RecaptchaTermsAndPolicyProps> = ({ tagName = 'span' }) => (
	<IntlProvider>
		<FormattedMessage
			{...messages.termsAndPolicy}
			tagName={tagName}
			values={{
				privacyPolicy: (
					<Link
						href="https://policies.google.com/privacy"
						target="_blank"
						rel="noopener noreferrer"
					>
						<FormattedMessage {...messages.privacyPolicy} />
					</Link>
				),
				termsOfService: (
					<Link href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">
						<FormattedMessage {...messages.termsOfService} />
					</Link>
				),
			}}
		/>
	</IntlProvider>
);

export default RecaptchaTermsAndPolicy;
