import { useMemo } from 'react';
import {
	statusCategoryForId,
	DONE,
} from '@atlassian/jira-common-constants/src/status-categories.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import type { DisableVotingProps } from '../../common/types.tsx';
import messages from './messages.tsx';

export const useDisableResolvedIssueVoting = (): DisableVotingProps => {
	const { formatMessage } = useIntl();
	const issueKey = useIssueKey();
	const [statusFieldValue] = useFieldValue({
		issueKey,
		fieldKey: 'status',
	});

	const isDisabled = useMemo(
		() => statusCategoryForId(statusFieldValue?.statusCategory.id) === DONE,
		[statusFieldValue],
	);

	const tooltips = useMemo(
		() =>
			isDisabled
				? {
						addVote: formatMessage(
							fg('jira-issue-terminology-refresh-m3')
								? messages.addVoteDisabledTooltipIssueTermRefresh
								: messages.addVoteDisabledTooltip,
						),
						removeVote: formatMessage(
							fg('jira-issue-terminology-refresh-m3')
								? messages.removeVoteDisabledTooltipIssueTermRefresh
								: messages.removeVoteDisabledTooltip,
						),
					}
				: undefined,
		[formatMessage, isDisabled],
	);

	return { isDisabled, tooltips };
};

export const useDisableResolvedIssueVotingPure = (statusCategoryId: string): DisableVotingProps => {
	const { formatMessage } = useIntl();

	const isDisabled = useMemo(
		() => statusCategoryForId(statusCategoryId) === DONE,
		[statusCategoryId],
	);

	const tooltips = useMemo(
		() =>
			isDisabled
				? {
						addVote: formatMessage(
							fg('jira-issue-terminology-refresh-m3')
								? messages.addVoteDisabledTooltipIssueTermRefresh
								: messages.addVoteDisabledTooltip,
						),
						removeVote: formatMessage(
							fg('jira-issue-terminology-refresh-m3')
								? messages.removeVoteDisabledTooltipIssueTermRefresh
								: messages.removeVoteDisabledTooltip,
						),
					}
				: undefined,
		[formatMessage, isDisabled],
	);

	return { isDisabled, tooltips };
};
