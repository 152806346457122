import React from 'react';
import { Box, Grid, Inline, Stack, xcss } from '@atlaskit/primitives';
import { SkeletonCompiled } from '@atlassian/jira-common-components-skeleton/src/styled.tsx';

const ROWS_COUNT = 8;

export const IdeaViewSkeleton = () => (
	<Box padding="space.200" xcss={containerStyles}>
		<Stack space="space.400">
			<Inline space="space.100" spread="space-between" alignBlock="center">
				<SkeletonCompiled height="16px" width="16px" />
				<SkeletonCompiled height="16px" width="100%" />
			</Inline>
			<Stack space="space.100">
				<SkeletonCompiled height="16px" width="100%" />
				<Inline space="space.100">
					<SkeletonCompiled height="24px" width="24px" />
					<SkeletonCompiled height="24px" width="24px" />
					<SkeletonCompiled height="24px" width="24px" />
					<SkeletonCompiled height="24px" width="24px" />
				</Inline>
			</Stack>
			<Stack space="space.200">
				<SkeletonCompiled height="16px" width="80%" />
				<SkeletonCompiled height="16px" width="100%" />
				<SkeletonCompiled height="16px" width="100%" />
				<SkeletonCompiled height="16px" width="80%" />
			</Stack>
			<Stack space="space.200">
				<SkeletonCompiled height="16px" width="40%" />
				<SkeletonCompiled height="100px" width="100px" />
			</Stack>
			<Stack space="space.200">
				{[...Array(ROWS_COUNT)].map((_, index) => (
					<Grid
						key={index}
						templateColumns="[title] minmax(100px, 0.6fr) [content] 1fr"
						gap="space.100"
						alignItems="center"
					>
						<Box padding="space.100">
							<SkeletonCompiled height="16px" width="48px" />
						</Box>
						<SkeletonCompiled width="100px" height="16px" />
					</Grid>
				))}
			</Stack>
		</Stack>
	</Box>
);

const containerStyles = xcss({
	width: '100%',
	position: 'absolute',
	top: 'space.0',
	bottom: 'space.0',
	paddingTop: 'space.300',
	paddingBottom: 'space.200',
	paddingRight: 'space.300',
	paddingLeft: 'space.300',
});
