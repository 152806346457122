import { SERVICE_DESK_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { projectTypeSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';
import FindOutMore from './main.tsx';
import type { StateProps } from './types.tsx';

export default connect(
	(state): StateProps => ({
		isServiceDeskProject: projectTypeSelector(state) === SERVICE_DESK_PROJECT,
	}),
	{},
)(FindOutMore);
