/**
 * NOTE:
 *
 * This file is automatically generated by i18n-tools.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
// Icelandic
export default {
	'fabric.elements.share.form.title': 'Deila',
	'fabric.elements.share.form.send': 'Senda ',
	'fabric.elements.share.form.retry': 'Reyna aftur',
	'fabric.elements.share.form.comment.placeholder': 'Bæta við skilaboðum',
	'fabric.elements.share.form.user-picker.placeholder.generic':
		'Sláðu inn nafn, teymi eða tölvupóstur',
	'fabric.elements.share.form.user-picker.placeholder.existingUserOnly': 'Sláðu inn nafn eða teymi',
	'fabric.elements.share.form.user-picker.add-more': 'Færa inn fleiri ',
	'fabric.elements.share.form.user-picker.validation.required':
		'Veldu að minnsta kosti einn notanda, teymi eða netfang.',
	'fabric.elements.share.form.user-picker.no-options.domainBasedUserOnly':
		'Þú getur aðeins boðið fólki sem eru með {domains} netföng. Hafðu samband við stjórnanda síðunnar þinnar til að bjóða öðru fólki.',
	'fabric.elements.share.form.user-picker.no-options.existingUserOnly':
		'Ekki fundust neinar niðurstöður fyrir „{inputValue}“. Bjóddu fólki með því að nota netföng.',
	'fabric.elements.share.form.user-picker.no-options.generic':
		'Engar niðurstöður fundust fyrir "{inputValue}". Bjóddu fólki með því að nota tölvupóstfang.',
	'fabric.elements.share.trigger.button.icon.label': 'Deilingartákn',
	'fabric.elements.share.trigger.button.text': 'Deila',
	'fabric.elements.share.trigger.button.tooltip.text': 'Deila',
	'fabric.elements.share.trigger.button.invite.text': 'Boðið',
	'fabric.elements.share.copylink.button.icon.label': '„Afrita tengil“-tákn',
	'fabric.elements.share.copylink.button.text': 'Afrita tengil',
	'fabric.elements.share.copied.to.clipboard.icon.label':
		'Tákn sem sýnir að tengilinn hafi verið afritaður með góðum árangri',
	'fabric.elements.share.copied.to.clipboard.message': 'Tengill afritaður á klippiborð',
	'fabric.elements.share.form.capabilities.info.message':
		'Boðskortið þitt verður sent til stjórnanda til samþykkis',
	'fabric.elements.share.failure.icon.label': 'Villutákn',
	'fabric.elements.share.failure.message': 'Ekki er hægt að deila',
	'fabric.elements.share.success.message':
		'{object, select, blogpost {Blog post shared} tafla {Board shared} dagatal {Calendar shared} uppkast {Draft shared} sía {Filter shared} málefni {Issue shared} miðill {Media shared} síða {Page shared} verkefni {Project shared} togbeiðni {Pull Request shared} spurning {Question shared} skýrsla {Report shared} Gagnageymsla {Repository shared} beiðni {Request shared} vegvísir {Roadmap shared} síða {Site shared} svæði {Space shared} annað {Link Shared}}',
	'fabric.elements.share.admin.notified.message': 'Stjórnanda hefur verið tilkynnt',
};
