import React from 'react';
import { graphql, useFragment } from 'react-relay';
import UFOCustomData from '@atlaskit/react-ufo/custom-data';
import type { ufoDataFragment_issueSelectableFieldEditView_UFOCustomDataFragment$key as BaseIssueFieldFragment } from '@atlassian/jira-relay/src/__generated__/ufoDataFragment_issueSelectableFieldEditView_UFOCustomDataFragment.graphql';

export const UFOCustomDataFragment = ({
	hasSearchByString,
	baseIssueFieldFragmentRef,
}: {
	hasSearchByString: boolean;
	baseIssueFieldFragmentRef: BaseIssueFieldFragment;
}) => {
	const data = useFragment<BaseIssueFieldFragment>(
		graphql`
			fragment ufoDataFragment_issueSelectableFieldEditView_UFOCustomDataFragment on JiraIssueField {
				__typename
				type
			}
		`,
		baseIssueFieldFragmentRef,
	);

	return (
		<UFOCustomData
			data={{
				typeName: data.__typename,
				hasSearchByString,
				/**
				 * Don't report `issueFieldType` if `node` is not a `JiraIssueField`
				 * e.g., `JiraParentOption` implements `JiraHasSelectableValueOptions` but doesn't have the `type` field
				 */
				issueFieldType: data.type ?? undefined,
			}}
		/>
	);
};
