import React from 'react';
import { styled } from '@compiled/react';
import EmptyState from '@atlaskit/empty-state';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import ErrorImage from '@atlassian/jira-servicedesk-common/src/utils/assets/error-white.svg';
import messages from './messages.tsx';

export const DetailsErrorState = ({ description }: { description: string }) => {
	const { formatMessage } = useIntl();

	return (
		<EmptyStateContainer>
			<EmptyState
				header={formatMessage(messages.header)}
				description={description}
				imageUrl={ErrorImage}
				imageWidth={75}
				testId="servicedesk-organizations-contacts-common.common.ui.details-error-state.error-state"
			/>
		</EmptyStateContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmptyStateContainer = styled.div({
	padding: `0 ${token('space.500', '40px')}`,
});
