import React, { memo, useMemo, useState } from 'react';
import EmojiFlagsIcon from '@atlaskit/icon/glyph/emoji/flags';
import type {
	FeatureFlagRolloutSummary,
	FeatureFlagName,
	FeatureFlagDetailsLink,
} from '@atlassian/jira-development-common/src/model/releases-data.tsx';
import { SummaryItem } from '@atlassian/jira-development-summary-common/src/ui/summary-item/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { FeatureFlagActionDropdownSimple } from '../../common/ui/feature-flag-action-dropdown-simple/index.tsx';
import FeatureFlagActionIconButton from './feature-flag-action-icon-button/index.tsx';
import FlagsDescription from './flags-description/index.tsx';
import messages from './messages.tsx';
import Status from './status/index.tsx';

const FEATURE_FLAG_SUMMARY_ITEM_TEST_ID = 'development-summary-feature-flag.ui.summary-item';

type Props = {
	connectFeatureFlagTarget: string | undefined;
	createFeatureFlagTarget: string | undefined;
	detailsLink: FeatureFlagDetailsLink | undefined;
	enabledFeatureFlags: FeatureFlagName[];
	disabledFeatureFlags: FeatureFlagName[];
	enabledFeatureFlagsCnt: number;
	disabledFeatureFlagsCnt: number;
	rolloutSummary: FeatureFlagRolloutSummary | undefined;
	totalFeatureFlags: number;
	onClick?: () => void;
};

export const areEqual = (previousProps: Props, nextProps: Props): boolean =>
	nextProps.enabledFeatureFlagsCnt === previousProps.enabledFeatureFlagsCnt &&
	nextProps.disabledFeatureFlagsCnt === previousProps.disabledFeatureFlagsCnt &&
	nextProps.detailsLink === previousProps.detailsLink;

const FeatureFlagSummaryItem = memo<Props>(
	({
		connectFeatureFlagTarget,
		createFeatureFlagTarget,
		detailsLink,
		disabledFeatureFlags,
		disabledFeatureFlagsCnt,
		enabledFeatureFlags,
		enabledFeatureFlagsCnt,
		onClick,
		rolloutSummary,
		totalFeatureFlags,
	}: Props) => {
		const { formatMessage } = useIntl();
		const [shouldForceVisibleSecondary, setShouldForceVisibleSecondary] = useState(false);

		const title = useMemo(
			() => (
				<FlagsDescription
					enabledFeatureFlags={enabledFeatureFlags}
					disabledFeatureFlags={disabledFeatureFlags}
				/>
			),
			[enabledFeatureFlags, disabledFeatureFlags],
		);

		const secondaryData = useMemo(
			() => (
				<Status
					hasFeatureFlags={disabledFeatureFlagsCnt + enabledFeatureFlagsCnt > 0}
					disabledFeatureFlagsCnt={disabledFeatureFlagsCnt}
					enabledFeatureFlagsCnt={enabledFeatureFlagsCnt}
					rolloutSummary={rolloutSummary}
				/>
			),
			[disabledFeatureFlagsCnt, enabledFeatureFlagsCnt, rolloutSummary],
		);

		return (
			// TODO - Apply the same solution as the one being designed for relative date in commits
			// to prevent the secondaryData overlapping the title onHover.
			<SummaryItem
				title={title}
				secondaryData={secondaryData}
				onClick={onClick}
				icon={<EmojiFlagsIcon label="Flag Icon" size="medium" />}
				showIconInPlainLink
				itemType="featureFlag"
				itemCount={totalFeatureFlags}
				oneClickUrl={detailsLink}
				oneClickToolTipContent={formatMessage(messages.tooltip)}
				secondaryHover={
					<FeatureFlagActionDropdownSimple
						connectFeatureFlagTarget={connectFeatureFlagTarget}
						createFeatureFlagTarget={createFeatureFlagTarget}
						getDropdownTrigger={(props) => <FeatureFlagActionIconButton {...props} />}
						onOpenDropdown={({ isOpen }) => {
							setShouldForceVisibleSecondary(isOpen);
						}}
					/>
				}
				forceVisibleSecondary={shouldForceVisibleSecondary}
				data-testid={FEATURE_FLAG_SUMMARY_ITEM_TEST_ID}
			/>
		);
	},
	areEqual,
);

export default FeatureFlagSummaryItem;
