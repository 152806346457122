import type { ConnectionFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/connection/types.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { JPD_CONNECTION_ISSUE_LINK_TYPE } from '../../../constants.tsx';
import { createGetField } from '../../../selectors/fields.tsx';
import { getLocalIssueIdsByJiraIssueId } from '../../../selectors/issue-ids.tsx';
import { getIssueMetadataProperties } from '../../../selectors/properties/linked-issues/index.tsx';
import type { State, Props } from '../../../types.tsx';
import { isMatchingConnectionFieldFilter } from '../../../utils/connection-field-filters.tsx';
import { createGetIssueType } from '../../../selectors/properties/index.tsx';

type GetConnectionFieldValuesFromLinkedIssues = {
	fieldKey: FieldKey;
	localIssueId: string;
	state: State;
	props: Props;
};

export const getConnectionFieldValuesFromLinkedIssues = ({
	fieldKey,
	localIssueId,
	state,
	props,
}: GetConnectionFieldValuesFromLinkedIssues): ConnectionFieldValue[] => {
	const localIssueIdsByJiraIssueId = getLocalIssueIdsByJiraIssueId(state, props);

	const issueMetadataPropertiesMap = getIssueMetadataProperties(state);
	const issueLinks = issueMetadataPropertiesMap[localIssueId]?.issueLinks.links || [];

	const connectionField = createGetField(fieldKey)(state, props);
	const connectionFieldFilters = connectionField?.configuration?.issueTypeFilters || [];

	return issueLinks.reduce<ConnectionFieldValue[]>(
		(result, { inwardIssue, outwardIssue, typeName }) => {
			if (typeName !== JPD_CONNECTION_ISSUE_LINK_TYPE) {
				return result;
			}

			// issue link used for connection doesn't respect the direction of the link, so we need to check both
			const linkedIssue =
				localIssueId === localIssueIdsByJiraIssueId[inwardIssue.id.toString()]
					? outwardIssue
					: inwardIssue;
			const linkedIssueId = linkedIssue.id.toString();
			const issueTypeId = props.issueTypeIds?.find((id) => id === linkedIssue.issueTypeId);
			const linkedIssueLocalId = localIssueIdsByJiraIssueId[linkedIssueId];

			const matchesFilter = isMatchingConnectionFieldFilter({
				filters: connectionFieldFilters,
				issueType: createGetIssueType(linkedIssueLocalId)(state, props)?.id ?? issueTypeId,
				issueTypeNameFilter: props.getIssueTypeNameFilter,
			});

			// making a list of unique linked issues that match the filter from connection field configuration
			if (matchesFilter && !result.find(({ id }) => id === linkedIssueId)) {
				result.push({
					id: linkedIssueId,
				});
			}

			return result;
		},
		[],
	);
};
