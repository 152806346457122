import React, { useLayoutEffect } from 'react';

type Metric = {
	start: () => void;
	stop: () => void;
	cancel: () => void;
};

export const StopMetric = ({ metric }: { metric: Metric }) => {
	useLayoutEffect(() => {
		metric.stop();
	}, [metric]);
	return <></>;
};

export const StartMetric = ({ metric }: { metric: Metric }) => {
	useLayoutEffect(() => {
		metric.start();
	}, [metric]);
	return <></>;
};

export const CancelMetric = ({ metric }: { metric: Metric }) => {
	useLayoutEffect(() => {
		metric.cancel();
	}, [metric]);
	return <></>;
};
