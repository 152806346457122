import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	workflowTransitions: {
		id: 'issue-field-status.status-view.actions.actions-menu.transitions-submenu.workflow-transitions',
		defaultMessage: 'From Workflow',
		description:
			'Label for a section in a dropdown menu that holds "Jira Workflow Transitions" a user can execute based on the issue currently in context',
	},
});
