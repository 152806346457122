import React from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import { MountEvent, fireOperationalAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { Props } from './types.tsx';
import { CommentVisibilityView } from './view.tsx';

export const CommentVisibilityViewWithAnalytics = (props: Props) => (
	<>
		<ErrorBoundary prefixOverride="issue" id="safe(CommentVisibilityViewWithAnalytics)">
			<MountEvent
				onMount={(analyticsEvent: UIAnalyticsEvent) => {
					fireOperationalAnalytics(analyticsEvent, 'commentVisibilitySelect mounted', {
						isLoading: props.isLoading,
					});
				}}
			/>
			<CommentVisibilityView {...props} />
		</ErrorBoundary>
	</>
);

export default CommentVisibilityViewWithAnalytics;
