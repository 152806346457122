// import type { ServerIssueType } from '../common/service/issue/subtask-types-server';
// JIV-15410: type duplication
type ServerIssueType = {
	self: string;
	id: number;
	description: string;
	iconUrl: string;
	name: string;
	subtask: boolean;
	expand: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	fields: any;
};

// SET_ISSUE_TYPES
export const SET_ISSUE_TYPES = 'SET_ISSUE_TYPES' as const;

export const setIssueTypes = (issueTypes: ServerIssueType[]) => ({
	type: SET_ISSUE_TYPES,
	payload: issueTypes,
});

export type SetIssueTypesAction = ReturnType<typeof setIssueTypes>;

// FETCHING_ISSUE_TYPES
export const FETCHING_ISSUE_TYPES = 'FETCHING_ISSUE_TYPES' as const;

export const fetchingIssueTypes = () => ({
	type: FETCHING_ISSUE_TYPES,
});

export type FetchingIssueTypesAction = ReturnType<typeof fetchingIssueTypes>;

// FETCH_ISSUE_TYPES_FAILED
export const FETCH_ISSUE_TYPES_FAILED = 'FETCH_ISSUE_TYPES_FAILED' as const;

export const fetchIssueTypesFailed = () => ({
	type: FETCH_ISSUE_TYPES_FAILED,
});

export type FetchIssueTypesFailedAction = ReturnType<typeof fetchIssueTypesFailed>;
