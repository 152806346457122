import fetchText$ from '@atlassian/jira-fetch/src/utils/as-text-stream.tsx';
import { useEditField } from '@atlassian/jira-issue-field-base/src/services/edit-field-service/main.tsx';
import { useFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import type { FlaggedValue } from '@atlassian/jira-issue-shared-types/src/common/types/flag-type.tsx';
import { FLAGGED_CF_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { Props } from './types.tsx';
import { transformFromStateValue } from './utils.tsx';

export const flagUrl = (_baseUrl: string) => '/rest/greenhopper/1.0/xboard/issue/flag/flag.json';

export const setFlag = (baseUrl: string, issueKey: string, shouldFlag: boolean) =>
	fetchText$(flagUrl(baseUrl), {
		method: 'POST',
		body: JSON.stringify({
			flag: shouldFlag,
			issueKeys: [issueKey],
			commentVisibility: '',
			comment: '',
		}),
	})
		.toPromise()
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		.then(() => {});

// @ts-expect-error - TS2304 - Cannot find name 'IssueKey'.
const saveField = (issueKey: IssueKey, fieldKey: string, value: FlaggedValue, baseUrl: string) =>
	setFlag(baseUrl, issueKey, transformFromStateValue(value));

export const useFlaggedField = ({ issueKey, fieldKey, onSuccess, onFailure }: Props) => {
	const [fieldConfig] = useFieldConfig(issueKey, fieldKey);

	const [{ value, error }, { saveValue, resetError }] = useEditField<FlaggedValue, null>({
		fieldKey,
		issueKey,
		fieldType: FLAGGED_CF_TYPE,
		initialValue: null,
		// @ts-expect-error - TS2322 - Type '(issueKey: IssueKey, fieldKey: string, value: FlaggedValue, baseUrl: string) => Promise<void>' is not assignable to type 'SaveField<FlaggedValue, undefined>'.
		saveField,
		onSuccess,
		onFailure,
	});

	return [
		{ value, error, fieldConfig },
		{ saveValue, resetError },
	] as const;
};
