import React from 'react';
import { getDisplayName } from '@atlassian/jira-common-util-with-injected-props/src/index.tsx';
import { RouterActions, type RouterActionsType } from '@atlassian/react-resource-router';

type InjectedProps = {
	linkActions: RouterActionsType;
};

// eslint-disable-next-line jira/import/no-anonymous-default-export, @typescript-eslint/no-unnecessary-type-constraint
export default <Config extends unknown>(
	WrappedComponent: Flow.AbstractComponent<Config & InjectedProps>,
): Flow.AbstractComponent<Config> => {
	const WithLinkActions = (props: Config) => (
		<RouterActions>
			{/* @ts-expect-error Spread types may only be created from object types.ts(2698) */}
			{(linkActions) => <WrappedComponent linkActions={linkActions} {...props} />}
		</RouterActions>
	);

	WithLinkActions.displayName = `withLinkActions(${getDisplayName(WrappedComponent)})`;
	// @ts-expect-error - TS2322 - Type '{ (props: Config): JSX.Element; displayName: string; }' is not assignable to type 'AbstractComponent<Config, any>'.
	return WithLinkActions;
};
