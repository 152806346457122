import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	copiedTooltipLabel: {
		id: 'platform-copy-text-field.copied-tooltip-label',
		defaultMessage: 'Copied!',
		description: 'Text displayed in the tooltip when the text in the field is successfully copied.',
	},
	copyFailedTooltipLabel: {
		id: 'platform-copy-text-field.copy-failed-tooltip-label',
		defaultMessage: 'Copy failed!',
		description:
			'Text displayed in the tooltip when the user attempts to copy text but the operation fails.',
	},
	copyIconLabel: {
		id: 'platform-copy-text-field.copy-icon-label',
		defaultMessage: 'Copy icon',
		description: 'Label describing the copy icon.',
	},
});
