import type { FieldsByKey } from '../collections/types.tsx';
import { isDeliveryFieldType } from '../field-types/index.tsx';
import { isIntervalFieldWithDeliveryDateConfig } from '../field/interval/utils.tsx';
import type { Field, FieldKey } from '../field/types.tsx';

const isDependingOnDeliveryData = (field: Field): boolean =>
	isDeliveryFieldType(field.type) || isIntervalFieldWithDeliveryDateConfig(field);

export const hasFieldKeyDependingOnDeliveryData = (
	fieldKeys: FieldKey[],
	fields: FieldsByKey,
): boolean =>
	!!fieldKeys.find((fieldKey) => fields[fieldKey] && isDependingOnDeliveryData(fields[fieldKey]));
