import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	filterButtonLabel: {
		id: 'jsm-issue-activity-filter.activity-feed-filter-button.filter-button-label',
		defaultMessage: 'Filter',
		description:
			'The label for the filter button that shows / hides the timeline filter modal for the all activities feed',
	},
});
