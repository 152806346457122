import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	successfulBuild: {
		id: 'software-releases-version-detail-issue-list.issues.issue-card.delivery.build-data.successful-build',
		defaultMessage: '{buildCount} successful {buildCount, plural, one {build} other {builds}}',
		description: 'Represents number of successful builds',
	},
	failedBuild: {
		id: 'software-releases-version-detail-issue-list.issues.issue-card.delivery.build-data.failed-build',
		defaultMessage: '{buildCount} failed {buildCount, plural, one {build} other {builds}}',
		description: 'Represents number of failed builds',
	},
	unknownBuild: {
		id: 'software-releases-version-detail-issue-list.issues.issue-card.delivery.build-data.unknown-build',
		defaultMessage: '{buildCount} unknown state {buildCount, plural, one {build} other {builds}}',
		description: 'Represents number of builds in an unknown state',
	},
});
