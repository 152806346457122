import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	goToComment: {
		id: 'issue-attachments-base.goto-button.go-to-comment',
		defaultMessage: 'Go to the comment with this attachment',
		description: 'Tooltip message for an attachment that is within a comment',
	},
	goToWorklog: {
		id: 'issue-attachments-base.goto-button.go-to-worklog',
		defaultMessage: 'Go to the work log entry with this attachment',
		description: 'Tooltip message for an attachment that is within a worklog',
	},
	goToField: {
		id: 'issue-attachments-base.goto-button.go-to-field',
		defaultMessage: 'You can find this attachment in the {field} field',
		description:
			'Tooltip message for an attachment that is within a description, environment or customfield',
	},
});
