import { createSelector } from 'reselect';
import differenceBy from 'lodash/differenceBy';
import isEqual from 'lodash/isEqual';
import set from 'lodash/set';
import uniqBy from 'lodash/uniqBy';
import memoizeOne from 'memoize-one';
import type { ConnectionFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/connection/types.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { Filter } from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import type { State } from '../../../types.tsx';
import {
	createCombinedComparator,
	numberComparator,
	safeStringListComparator,
} from '../../comparators/index.tsx';
import { createStringValueIntersectionFilter } from '../common/filter-utils.tsx';
import { defaultMapping } from '../default/index.tsx';
import type { FieldMapping, GroupIdMap } from '../types.tsx';

export const isConnectionFieldValue = (value: unknown): value is ConnectionFieldValue =>
	typeof value === 'object' && value !== null && 'id' in value;

const mapValueToEntityValue = (state: State, id: number): string | undefined =>
	Object.values(state.externalIssueData).find(({ issueId }) => issueId === id)?.summary;

const connectionMappingInternal = (field: Field): FieldMapping<ConnectionFieldValue[]> => {
	const valueAccessor: FieldMapping<ConnectionFieldValue[]>['valueAccessor'] = (
		state,
		props,
		localIssueId,
	) => state.properties.connection[field.key]?.[localIssueId] || [];

	return {
		...defaultMapping,
		field,
		valueAccessor,
		setMutable: (maps, localIssueId, value = []) =>
			set(maps.connection, [field.key, localIssueId], value),
		setImmutable: (maps, localIssueId, value = []) => {
			if (isEqual(maps.connection[field.key]?.[localIssueId], value)) {
				return maps;
			}
			return {
				...maps,
				connection: {
					...maps.connection,
					[field.key]: {
						...maps.connection[field.key],
						[localIssueId]: value,
					},
				},
			};
		},
		modifyImmutableIfMultiValueField: (maps, localIssueId, addValues = [], removeValues = []) => {
			const currentValue = maps.connection[field.key]?.[localIssueId] || [];

			return {
				...maps,
				connection: {
					...maps.connection,
					[field.key]: {
						...maps.connection[field.key],
						[localIssueId]: uniqBy(
							[...differenceBy(currentValue, removeValues, 'id'), ...addValues],
							'id',
						),
					},
				},
			};
		},
		getGroupIdentitiesSelector: (fieldKey, issueIdsSelector) =>
			createSelector(
				issueIdsSelector,
				(state: State) => state.properties.connection[fieldKey],
				(ids, connection) =>
					ids.reduce(
						(result, localIssueId) =>
							Object.assign<GroupIdMap<ConnectionFieldValue[]>, GroupIdMap<ConnectionFieldValue[]>>(
								result,
								{
									[localIssueId]:
										connection?.[localIssueId]?.map((value) => ({
											groupIdentity: value.id,
											value: [value],
										})) || [],
								},
							),
						{},
					),
			),
		allowEmptyGroup: true,
		isMultiValueField: true,
		getFilter: (filter: Filter) => {
			if (filter.type === 'FIELD' && filter.field === field.key) {
				const stringValueIntersectionFilter = createStringValueIntersectionFilter(filter);
				if (stringValueIntersectionFilter === undefined) {
					return undefined;
				}
				return (value, store, props, localIssueId) =>
					stringValueIntersectionFilter(
						value?.map(({ id }) => id),
						store,
						props,
						localIssueId,
					);
			}
			return undefined;
		},
		comparatorWithMapping(state, _, a, b, direction) {
			const mappedA = a
				?.map(({ id }) => mapValueToEntityValue(state, parseInt(id, 10)))
				.filter(Boolean);
			const mappedB = b
				?.map(({ id }) => mapValueToEntityValue(state, parseInt(id, 10)))
				.filter(Boolean);
			return createCombinedComparator<string[]>([
				// first compare number of linked issues
				(a1 = [], b1 = [], dir) => numberComparator(a1.length, b1.length, dir),
				// then compare by summaries of linked issues
				safeStringListComparator,
			])(mappedA, mappedB, direction);
		},
	};
};

export const connectionMapping = memoizeOne(connectionMappingInternal);
