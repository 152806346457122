import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SC1 = styled.div<{
	isModal: boolean;
	fontSize: number;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	intrusion: any;
	lineHeight: number;
}>({
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: (props) => `${props.fontSize}px`,

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	borderRight: (props) =>
		props.isModal
			? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				`${props.intrusion}px solid ${token('elevation.surface.overlay', colors.N0)}`
			: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				`${props.intrusion}px solid ${token('elevation.surface', colors.N0)}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	borderTop: (props) =>
		props.isModal
			? `${(props.lineHeight - props.fontSize) / 2}px solid ${token(
					'elevation.surface.overlay',
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					colors.N0,
				)}`
			: `${(props.lineHeight - props.fontSize) / 2}px solid ${token(
					'elevation.surface',
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					colors.N0,
				)}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	borderBottom: (props) =>
		props.isModal
			? `${(props.lineHeight - props.fontSize) / 2}px solid ${token(
					'elevation.surface.overlay',
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					colors.N0,
				)}`
			: `${(props.lineHeight - props.fontSize) / 2}px solid ${token(
					'elevation.surface',
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					colors.N0,
				)}`,
});
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export default SC1;
