import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	issueIconAltText: {
		id: 'command-palette-common.common.command.issue.issue-left-icon.issue-icon-alt-text',
		defaultMessage: 'Icon for the issue type {issueTypeName}',
		description: 'Alternate text for the icon displayed next to the issue',
	},
	issueIconAltTextIssueTermRefresh: {
		id: 'command-palette-common.common.command.issue.issue-left-icon.issue-icon-alt-text-issue-term-refresh',
		defaultMessage: 'Icon for the issue type {issueTypeName}',
		description: 'Alternate text for the icon displayed next to the issue',
	},
});
