import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	hideEmptyFieldsButtonText: {
		id: 'issue-view-layout-templates-views.context.visible-hidden.hide-when-empty.hide-empty-fields-button-text',
		defaultMessage: 'Hide empty fields',
		description: 'Text on button to hide empty fields in context',
	},
	showEmptyFieldsButtonText: {
		id: 'issue-view-layout-templates-views.context.visible-hidden.hide-when-empty.show-empty-fields-button-text',
		defaultMessage: 'Show {noOfEmptyFields, plural, one {# empty field} other {# empty fields}}',
		description: 'Text on button to show empty fields in context',
	},
	detailsText: {
		id: 'issue-view-layout-templates-views.context.visible-hidden.hide-when-empty.details-text',
		defaultMessage: 'Details',
		description: 'Details Group in Context',
	},
});
