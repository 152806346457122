/**
 * @generated SignedSource<<def8b12f23138eae86e48f0caaf427f1>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type view_issueViewActivityComment_SmartRepliesSettings$data = {
  readonly isIssueViewSmartRepliesAdminEnabled: ReadonlyArray<{
    readonly key: string;
    readonly value: string;
  }> | null | undefined;
  readonly userPreferences: {
    readonly isIssueViewSmartRepliesUserEnabled: boolean | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "view_issueViewActivityComment_SmartRepliesSettings";
};
export type view_issueViewActivityComment_SmartRepliesSettings$key = {
  readonly " $data"?: view_issueViewActivityComment_SmartRepliesSettings$data;
  readonly " $fragmentSpreads": FragmentRefs<"view_issueViewActivityComment_SmartRepliesSettings">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "cloudId"
    }
  ],
  "kind": "Fragment",
  "name": "view_issueViewActivityComment_SmartRepliesSettings",
  "selections": [
    {
      "alias": "isIssueViewSmartRepliesAdminEnabled",
      "args": [
        (v0/*: any*/),
        {
          "kind": "Literal",
          "name": "keys",
          "value": "jira.smart.replies.admin.is-enabled"
        }
      ],
      "concreteType": "JiraApplicationProperty",
      "kind": "LinkedField",
      "name": "applicationPropertiesByKey",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "key"
        },
        {
          "kind": "ScalarField",
          "name": "value"
        }
      ]
    },
    {
      "args": [
        (v0/*: any*/)
      ],
      "concreteType": "JiraUserPreferences",
      "kind": "LinkedField",
      "name": "userPreferences",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isIssueViewSmartRepliesUserEnabled"
        }
      ]
    }
  ],
  "type": "JiraQuery"
};
})();

(node as any).hash = "96fcd798dfb1617a3f782eda391c0af6";

export default node;
