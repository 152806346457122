import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { createResource } from '@atlassian/react-resource-router';
import type {
	AIContextServicePanelApiResponse,
	AIContextServicePanelResourceResponse,
} from './types.tsx';
import { transformData } from './utils.tsx';

export const aiContextServicePanelResource = createResource<AIContextServicePanelResourceResponse>({
	type: 'AI-CONTEXT-SERVICE-PANEL-RESOURCE-TYPE',
	getKey: ({ match }) => {
		const issueId = match.params.issueId;
		return `aiContextServicePanelResource-${issueId}`;
	},
	getData: async ({ match }) => {
		const issueId = match.params.issueId;

		if (!issueId) {
			throw new Error('AI context service panel resource cannot be loaded without an issueId');
		}

		const response = await performGetRequest<AIContextServicePanelApiResponse>(
			`/gateway/api/firebolt/1/context-panel/${issueId}`,
		);

		return transformData(response);
	},
	maxAge: 0,
	isBrowserOnly: true,
});
