export const figmaProviderDetails = {
	id: 'com.figma.jira-add-on',
	priority: 1,
	domain: 'figma.com',
	name: 'Figma',
	optedOutAppIdentifiers: ['com.figma.jira-add-on', 'design'],
};

export const azureDevOpsProviderDetails = {
	id: 'azure-devops-for-jira',
	priority: 2,
	domain: 'dev.azure.com',
	name: 'Azure',
	optedOutAppIdentifiers: ['azure-devops-for-jira'],
};

export const gitLabProviderDetails = {
	id: 'gitlab-jira-connect-gitlab.com',
	priority: 3,
	domain: 'gitlab.com',
	name: 'GitLab',
	optedOutAppIdentifiers: ['gitlab-jira-connect-gitlab.com'],
};

export const snykProviderDetails = {
	id: 'snyk.in.jira',
	priority: 4,
	domain: 'snyk.io',
	name: 'Snyk',
	optedOutAppIdentifiers: ['snyk.in.jira'],
};

export const ALL_DOMAINS = [
	figmaProviderDetails,
	azureDevOpsProviderDetails,
	gitLabProviderDetails,
	snykProviderDetails,
];

export const OPTED_OUT_FROM_ALL_IDENTIFIER = 'all-apps';
