import { compositionVisitFields } from './composition/index.tsx';
import { discretizationVisitFields } from './discretization/index.tsx';
import { expressionVisitFields } from './expression/index.tsx';
import { fieldVisitFields } from './field/index.tsx';
import { multiselectCountVisitFields } from './multi-select-count/index.tsx';
import { normalizationVisitFields } from './normalize/index.tsx';
import { propertyAggVisitFields } from './property-aggregation/index.tsx';
import type { DynamicFieldFormula, FieldVisitor, FormulaVisitor } from './types.tsx';

type VisitationHandler = (
	formula: DynamicFieldFormula,
	visitField: FieldVisitor,
	visitFormula: FormulaVisitor,
) => boolean;

// strictly speaking, we only need to have visitors for formulas that can
// reference other fields
const visitationHandlers: VisitationHandler[] = [
	compositionVisitFields,
	fieldVisitFields,
	propertyAggVisitFields,
	normalizationVisitFields,
	expressionVisitFields,
	discretizationVisitFields,
	multiselectCountVisitFields,
];

export const visitFormulaFields = (formula: DynamicFieldFormula, visitField: FieldVisitor) => {
	const recurse = (next: DynamicFieldFormula) => {
		visitationHandlers.findIndex((f) => f(next, visitField, recurse));
	};

	recurse(formula);
};

// returns the field keys of the fields referenced *directly* by the given formula
export const formulaUsesFields = (formula: DynamicFieldFormula): string[] => {
	const seen: Record<string, boolean> = {};
	const list: string[] = [];

	visitFormulaFields(formula, (fieldKey) => {
		if (!seen[fieldKey]) {
			seen[fieldKey] = true;
			list.push(fieldKey);
		}
	});

	return list;
};
