// jira-frontend/browse/src/packages/platform/observability/product-analytics-bridge/README.md#Tracking outside of UI
import {
	type AnalyticsAttributes,
	fireOperationalAnalytics,
	type AnalyticsEvent,
} from '@atlassian/jira-product-analytics-bridge';
import type { AnalyticsAttributesUntyped } from '@atlassian/jira-product-analytics-bridge/src/types';
import { getAnalyticsWebClient } from '@atlassian/jira-product-analytics-web-client';

/**
 * ## fireOperationalAnalyticsWithoutContext
 *
 * Triggers an operational analytics event without the Jira analytics context
 * Action subjects are prefixed with `jiraUiModifications.`
 */
// Remove on 'analytics-replace-jira-packages' cleanup
export const fireOperationalAnalyticsWithoutContext = (
	actionSubject: string,
	action: string,
	viewType: string,
	attributes: AnalyticsAttributes = {},
) =>
	getAnalyticsWebClient()
		.getInstance()
		.sendOperationalEvent({
			action,
			actionSubject: `jiraUiModifications.${actionSubject}`,
			source: viewType,
			attributes: {
				...attributes,
				viewType,
			},
		});

/**
 * ## fireOperationalAnalyticsWithContext
 *
 * Triggers an operational analytics event with the Jira analytics context
 * Action subjects are prefixed with `jiraUiModifications.`
 */
// Remove on 'analytics-replace-jira-packages' cleanup
export const fireOperationalAnalyticsWithContext = (
	event: AnalyticsEvent,
	id: string,
	viewType: string,
	attributes: AnalyticsAttributes | AnalyticsAttributesUntyped = {},
) => {
	fireOperationalAnalytics(event, `jiraUiModifications.${id}`, { ...attributes, viewType });
};
