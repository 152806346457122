import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { AvatarUrls } from '@atlassian/jira-shared-types/src/rest/jira/user.tsx';

type Assignee = {
	self: string;
	accountId: string;
	emailAddress: string;
	avatarUrls: AvatarUrls;
	displayName: string | null;
	active: boolean;
	timeZone: string;
	accountType: string;
};

type IssueFields = {
	expand: string;
	id: string;
	self: string;
	key: string;
	fields: {
		summary: string | null;
		// customfield_62745: string | null;
		customfield_62399: number | null;
		customfield_62397: number | null;
		assignee: Assignee | null;
		customfield_62481: string | null;
		customfield_61933: string | null;
	};
};

export type TransformedIssueData = {
	hasAssignee: boolean;
	numberOfCodeReferences: number | null;
	linkToPR: string | null;
	numberOfPossibleServedValues: number | null;
	languages: string[];
};

export const getIssueFields = async (issueId: string): Promise<TransformedIssueData> => {
	const jiraFields = [
		'summary',
		'status',
		'assignee',
		'customfield_62399', // Number of code references
		'customfield_61933', // Link to PR
		'customfield_62397', // Number of possible served values
		// 'customfield_62745', // possible values
		'customfield_62481', // File extensions flag found in
	];

	return fetchJson<IssueFields>(`/rest/api/3/issue/${issueId}?fields=${jiraFields.join(',')}`).then(
		(data) => transformData(data),
	);
};

export const transformData = (data: IssueFields) => {
	const numberOfCodeReferences = data.fields.customfield_62399;
	const linkToPR = data.fields.customfield_61933;
	const numberOfPossibleServedValues = data.fields.customfield_62397;
	// const possibleValues = data.fields.customfield_62745;
	const languages = data.fields.customfield_62481 ?? '';

	return {
		hasAssignee: Boolean(data.fields.assignee),
		numberOfCodeReferences,
		linkToPR,
		numberOfPossibleServedValues,
		languages: languages.split(',').map((str) => str.trim()),
	};
};
