import findIndex from 'lodash/findIndex';
import type { OptionFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/option/types.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';

export const getOptionIndex = (
	field: Field,
	optionProperty: OptionFieldValue,
): number | undefined => {
	if (field.options !== undefined && optionProperty !== undefined) {
		const index = findIndex(field.options, (option) => option.jiraOptionId === optionProperty.id);
		if (index !== -1) {
			return index;
		}
	}
	return undefined;
};

export const getOptionWeight = (
	field: Field,
	optionProperty: OptionFieldValue,
): number | undefined => {
	const index = getOptionIndex(field, optionProperty);
	if (index !== undefined && index !== -1) {
		return field.options?.[index].weight;
	}
	return undefined;
};
