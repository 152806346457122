/**
 * @generated SignedSource<<ae999777cbfd22caef956051c2c40e88>>
 * @relayHash 86c70b4eeb101ecb6586554a0835034b
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID c0fec7afcdea33af0b796ec9b4bb29c26bd102c7eafa58714a7c1bdae4678cb8

import type { ConcreteRequest, Query } from 'relay-runtime';
export type applicationPropertiesGetConfluencePropertiesQuery$variables = {
  cloudId: string;
  keys: ReadonlyArray<string>;
};
export type applicationPropertiesGetConfluencePropertiesQuery$data = {
  readonly jira: {
    readonly applicationPropertiesByKey: ReadonlyArray<{
      readonly key: string;
      readonly value: string;
    }> | null | undefined;
  } | null | undefined;
};
export type applicationPropertiesGetConfluencePropertiesQuery = {
  response: applicationPropertiesGetConfluencePropertiesQuery$data;
  variables: applicationPropertiesGetConfluencePropertiesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "keys"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "kind": "Variable",
    "name": "keys",
    "variableName": "keys"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "key"
},
v3 = {
  "kind": "ScalarField",
  "name": "value"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "applicationPropertiesGetConfluencePropertiesQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraApplicationProperty",
            "kind": "LinkedField",
            "name": "applicationPropertiesByKey",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "applicationPropertiesGetConfluencePropertiesQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraApplicationProperty",
            "kind": "LinkedField",
            "name": "applicationPropertiesByKey",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "kind": "ScalarField",
                "name": "id"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "c0fec7afcdea33af0b796ec9b4bb29c26bd102c7eafa58714a7c1bdae4678cb8",
    "metadata": {},
    "name": "applicationPropertiesGetConfluencePropertiesQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "22bd28d5a1b34c4d3bb8292e3ce92fb3";

export default node;
