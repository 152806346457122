import type { AnalyticsEvent } from '@atlassian/jira-common-analytics-v2-wrapped-components/src/types.tsx';
import type { IssueLinkDirection } from '@atlassian/jira-issue-shared-types/src/common/types/linked-issue-type.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import {
	saveLinkedIssueFailure,
	saveLinkedIssueRequestWithoutFetch,
	saveLinkedIssueSuccess,
	deleteLinkedIssueRequestWithoutFetch,
	deleteLinkedIssueSuccess,
	type NewIssueLinks,
	deleteLinkedIssueFailureNoFlags,
} from '@atlassian/jira-issue-view-store/src/actions/issue-links-actions.tsx';
import type { Application } from '@atlassian/jira-shared-types/src/application.tsx';
import type { ApplicationEdition } from '@atlassian/jira-shared-types/src/edition.tsx';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import {
	useLinkUnlinkIssues,
	type UseLinkUnlinkIssuesProps,
} from './use-link-unlink-issues/index.tsx';

export type UnconnectedCommandPaletteSubscriberProps = UseLinkUnlinkIssuesProps;

export const UnconnectedCommandPaletteSubscriber = ({
	linkIssuesRequest,
	linkIssuesSuccess,
	linkIssuesFailure,
	unlinkIssueRequest,
	unlinkIssueSuccess,
	unlinkIssueFailure,
}: UnconnectedCommandPaletteSubscriberProps) => {
	useLinkUnlinkIssues({
		linkIssuesRequest,
		linkIssuesSuccess,
		linkIssuesFailure,
		unlinkIssueRequest,
		unlinkIssueSuccess,
		unlinkIssueFailure,
	});

	return null;
};

export const CommandPaletteSubscriber = connect(undefined, (dispatch) => ({
	linkIssuesRequest: (issueLinks: NewIssueLinks) => {
		dispatch(saveLinkedIssueRequestWithoutFetch(issueLinks));
	},
	linkIssuesSuccess: (
		optimisticId: string,
		analyticsEvent: AnalyticsEvent,
		issueKey?: string,
		postAnalyticsEvent?: AnalyticsEvent,
	) => {
		dispatch(saveLinkedIssueSuccess(optimisticId, analyticsEvent, issueKey, postAnalyticsEvent));
	},
	linkIssuesFailure: (optimisticId: string, error: string, analyticsEvent?: AnalyticsEvent) => {
		dispatch(saveLinkedIssueFailure(optimisticId, error, analyticsEvent));
	},
	unlinkIssueRequest: (
		linkedIssueId: string,
		application: Application | undefined,
		edition: ApplicationEdition | undefined,
	) => {
		dispatch(deleteLinkedIssueRequestWithoutFetch(linkedIssueId, application, edition));
	},
	unlinkIssueSuccess: (
		linkedIssueId: string,
		meta: { issueId: IssueId; direction: IssueLinkDirection },
	) => {
		dispatch(deleteLinkedIssueSuccess(linkedIssueId, meta));
	},
	unlinkIssueFailure: (linkedIssueId: string) => {
		dispatch(deleteLinkedIssueFailureNoFlags(linkedIssueId));
	},
}))(UnconnectedCommandPaletteSubscriber);
