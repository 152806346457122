import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	tooltipContent: {
		id: 'development-summary-feature-flag.summary.feature-flag-action-icon-button.tooltip-content',
		defaultMessage: 'Create/Connect Feature Flag',
		description:
			"The create/connect feature flag button tooltip content. 'Feature Flag' is a technical term, and is most often not translated.",
	},
});
