import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { FormattedMessage } from '@atlassian/jira-intl';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import messages from './messages.tsx';
import type { BaseError, Props } from './types.tsx';

const getInstanceLink = (error: BaseError) => <a href={error.instanceUrl}>{error.instanceName}</a>;

export const ConfigErrorMessage = (props: Props) => {
	const { formatMessage } = useIntl();

	const configErrorInstancesOneMessage = (errors: BaseError[]) => (
		<FormattedMessage
			{...messages.oneConfigError}
			values={{
				instanceOne: getInstanceLink(errors[0]),
			}}
		/>
	);

	const configErrorInstancesTwoMessage = (errors: BaseError[]) => (
		<FormattedMessage
			{...messages.twoConfigError}
			values={{
				instanceOne: getInstanceLink(errors[0]),
				instanceTwo: getInstanceLink(errors[1]),
			}}
		/>
	);

	const configErrorInstancesThreeMessage = (errors: BaseError[]) => (
		<FormattedMessage
			{...messages.threeConfigError}
			values={{
				instanceOne: getInstanceLink(errors[0]),
				instanceTwo: getInstanceLink(errors[1]),
				instanceThree: getInstanceLink(errors[2]),
			}}
		/>
	);

	const configErrorInstancesMoreMessage = (errors: BaseError[]) => (
		<FormattedMessage
			{...messages.moreConfigError}
			values={{
				instanceOne: getInstanceLink(errors[0]),
				instanceTwo: getInstanceLink(errors[1]),
				instanceThree: getInstanceLink(errors[2]),
				count: errors.length - 3,
			}}
		/>
	);

	const configErrorLearnMore = () => (
		<FormattedMessage
			{...(fg('jira-issue-terminology-refresh-m3')
				? messages.configErrorAndLearnMoreIssueTermRefresh
				: messages.configErrorAndLearnMore)}
			values={{
				h: (chunks) => (
					<a href="https://confluence.atlassian.com/display/JIRAKB/JIRA+Application+Development+panel+displays+error+-+Couldn%27t+read+data">
						{chunks}
					</a>
				),
				a: (chunks) => <a href="/plugins/servlet/applinks/listApplicationLinks">{chunks}</a>,
			}}
		/>
	);

	const renderConfigErrorDescription = (configErrors: BaseError[]) => {
		switch (configErrors.length) {
			case 1:
				return configErrorInstancesOneMessage(configErrors);
			case 2:
				return configErrorInstancesTwoMessage(configErrors);
			case 3:
				return configErrorInstancesThreeMessage(configErrors);
			default:
				return configErrorInstancesMoreMessage(configErrors);
		}
	};

	const { configErrors } = props;

	return configErrors && configErrors.length ? (
		<div>
			<Title>{formatMessage(messages.configTitle)}</Title>
			{renderConfigErrorDescription(configErrors)} {configErrorLearnMore()}
		</div>
	) : null;
};

export default ConfigErrorMessage;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Title = styled.div({
	margin: `${token('space.300', '24px')} 0 ${token('space.100', '8px')} 0`,
	fontWeight: token('font.weight.medium'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const visibleForTesting = {
	Title,
};
