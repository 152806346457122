import React, { Component, Fragment, type ReactNode } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { AsyncSuggestedResourcesPanel } from '@atlassian/jira-aiops-suggested-resources/src/ui/suggestion-panel/async.tsx';
import PerformanceMark from '@atlassian/jira-common-performance/src/set-performance-mark.tsx';
import { DevOpsAppRecommendationContextProvider as DevOpsAppRecommendationProvider } from '@atlassian/jira-dev-ops-app-recommendations-context-provider/src/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import { toForgeKeyWithDate } from '@atlassian/jira-forge-ui-utils/src/utils/connect/index.tsx';
import AttachmentsView from '@atlassian/jira-issue-view-base/src/content/attachment/panel/index.tsx';
import AutomationDiscoveryContainerPanel from '@atlassian/jira-issue-view-base/src/content/automation/index.tsx';
import ChildrenIssuesView from '@atlassian/jira-issue-view-base/src/content/children-issues/index.tsx';
import ConfluenceContentView from '@atlassian/jira-issue-view-base/src/content/confluence-content/index.tsx';
import * as PerfMarks from '@atlassian/jira-issue-view-base/src/content/constants.tsx';
import {
	SyncDesignPanel,
	AsyncDesignPanel,
} from '@atlassian/jira-issue-view-base/src/content/designs/src/index.tsx';
import { AsyncDevOpsAppRecommendationPanel } from '@atlassian/jira-issue-view-base/src/content/dev-ops-recommendations/index.tsx';
import EpicIssuesView from '@atlassian/jira-issue-view-base/src/content/epic-issues/index.tsx';
import { IncidentInvestigationPanel } from '@atlassian/jira-issue-view-base/src/content/incident-investigation-panel/index.tsx';
import IssueLinksView from '@atlassian/jira-issue-view-content-issue-links/src/index.tsx';
import LinkedAlertsModals from '@atlassian/jira-issue-view-base/src/content/linked-alerts-modals/index.tsx';
import { LoomPanel } from '@atlassian/jira-issue-view-base/src/content/loom-videos/index.tsx';
import ObjectRelatedRequestsPanel from '@atlassian/jira-issue-view-base/src/content/object-related-requests-panel/index.tsx';
import JiraOpsAgentDiscoverabilityPanel from '@atlassian/jira-issue-view-base/src/content/ops-agent-discoverability-panel/index.tsx';
import PlaybooksPanel from '@atlassian/jira-issue-view-base/src/content/playbooks/index.tsx';
import PortfolioChildIssuesView from '@atlassian/jira-issue-view-base/src/content/portfolio-child-issues/index.tsx';
import WrappedProformaFormsPanelView from '@atlassian/jira-issue-view-base/src/content/proforma-forms/index.tsx';
import SubtasksView from '@atlassian/jira-issue-view-base/src/content/subtasks/index.tsx';
import type { Props } from '@atlassian/jira-issue-view-base/src/content/types.tsx';
import { ConnectedView as WebLinksConnectedView } from '@atlassian/jira-issue-view-base/src/content/web-links/index.tsx';
import { withGICHook } from '@atlassian/jira-issue-view-base/src/content/with-gic-hook/index.tsx';
import { FORGE_ENTITY_TYPE } from '@atlassian/jira-issue-view-common-constants/src/ecosystem-constants.tsx';
import {
	ContentItemsWrapper,
	IssueSectionWrapper,
} from '@atlassian/jira-issue-view-common-styles/src/issue-layout.tsx';
import type { Panel as ForgePanel } from '@atlassian/jira-issue-view-common-types/src/forge-types.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { isDevOpsAppRecommenderEnabled } from '@atlassian/jira-issue-view-common/src/feature-flags.tsx';
import ConnectContentPanel, {
	type ContentPanel,
} from '@atlassian/jira-issue-view-ecosystem/src/connect/content-panel-view.tsx';
import ForgeContentPanel from '@atlassian/jira-issue-view-ecosystem/src/forge/content-panel-view.tsx';
import { JPDIdeasPanel } from '@atlassian/jira-issue-view-jpd-ideas/src/ui/index.tsx';
import { ContentTemplateRenderer } from '@atlassian/jira-issue-view-layout-templates/src/main.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import {
	contextSelector,
	isAnonymousSelector,
	issueKeySelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import {
	isCompletedLoadingSelector,
	isEpicSelector,
	isServiceDeskSelector,
	projectTypeSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';
import { jpdDeliverLinkTypeIdSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/jpd-delivery-link-id-selector.tsx';
import { ecosystemAddedContentPanelsSelector } from '@atlassian/jira-issue-view-store/src/ecosystem/ecosystem-extensions-selector.tsx';
import {
	shouldRenderChildPanelSelector,
	shouldRenderPortfolioChildPanelSelector,
	shouldRenderSubtaskPanelSelector,
} from '@atlassian/jira-issue-view-store/src/selectors/child-panel-ui-selector.tsx';
import { isIssueLinksEnabledSelector } from '@atlassian/jira-issue-view-store/src/selectors/issue-links-selector.tsx';
import { showLinkIdeasInputSelector } from '@atlassian/jira-issue-view-store/src/selectors/link-idea-selector.tsx';
import { additionalWebLinksSectionsSelector } from '@atlassian/jira-issue-view-store/src/selectors/web-links-selector.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { FireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { getApplicationForProject } from '@atlassian/jira-shared-types/src/application.tsx';
import { getEdition } from '@atlassian/jira-shared-types/src/edition.tsx';
import SmartRelatedIssuesPanel from '@atlassian/jira-smart-related-issues-panel/src/ui/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { EcosystemIssueViewErrorBoundary } from './ecosystem-issue-view-error-boundary.tsx';

const DevOpsAppRecommendationContextProvider = componentWithCondition(
	isDevOpsAppRecommenderEnabled,
	DevOpsAppRecommendationProvider,
	({ children }: { children: ReactNode }) => <>{children}</>,
);

/**
 * @deprecated Use functional component `ContentItems` instead. Class components are deprecated.
 */
// TODO Decomp BENTO-12515 - convert to functional component
// eslint-disable-next-line jira/react/no-class-components
export class DeprecatedContentItems extends Component<Props> {
	static displayName = 'ContentItems';

	static defaultProps = {
		isComplete: false,
		isCompactMode: false,
		isAnonymousUser: false,
		isEpic: false,
		hasIssueLinksEntity: false,
		contentPanels: [],
		additionalWebLinksSections: [],
	};

	maybeWrapInSectionWrapper(component: ReactNode) {
		const { isCompactMode } = this.props;

		// This is ineffective as react components that return null are not falsy
		if (!component) {
			return component;
		}

		if (isVisualRefreshEnabled() && fg('visual-refresh_drop_2')) {
			return (
				<Box xcss={isCompactMode ? issueSectionWrapperCompactStyles : issueSectionWrapperStyles}>
					{component}
				</Box>
			);
		}

		return <IssueSectionWrapper isCompactMode={isCompactMode}>{component}</IssueSectionWrapper>;
	}

	renderEcosystemContentPanels() {
		const { contentPanels, isCompactMode, projectType, context } = this.props;
		let application = null;
		let edition = null;

		if (projectType) {
			application = getApplicationForProject(projectType);

			if (application && context && context.appEditions) {
				edition = getEdition(application, context.appEditions);
			}
		}

		const panels = contentPanels.map((panel: ForgePanel | ContentPanel) => {
			if (panel.type === FORGE_ENTITY_TYPE) {
				const { name, appKey, moduleKey, dateAdded, panelInstanceId, extension } =
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
					panel as any;

				const panelKey = toForgeKeyWithDate(appKey, moduleKey, panelInstanceId, dateAdded);
				return (
					<UFOSegment name="issue-content-forge-panel" key={panel.name}>
						<EcosystemIssueViewErrorBoundary
							application={application}
							edition={edition}
							experience="issueViewContentItemForgePanel"
							key={panel.name}
						>
							<ForgeContentPanel
								key={panelKey}
								panelKey={panelKey}
								name={name}
								appKey={appKey}
								moduleKey={moduleKey}
								extension={extension}
								isCompactMode={isCompactMode}
								externalId="issue.ecosystem.content-panel.forge"
							/>
						</EcosystemIssueViewErrorBoundary>
					</UFOSegment>
				);
			}

			if (
				panel.moduleKey.includes('linked-goals-content-panel') &&
				expVal('native_goals_on_issue_view', 'isGoalsFieldEnabled', false)
			) {
				return null;
			}

			return (
				<UFOSegment name="issue-content-connect-panel" key={panel.name}>
					<EcosystemIssueViewErrorBoundary
						application={application}
						edition={edition}
						experience="issueViewContentItemConnectPanel"
						key={panel.name}
					>
						<ConnectContentPanel
							key={panel.name}
							name={panel.name}
							appKey={panel.appKey}
							moduleKey={panel.moduleKey}
						/>
					</EcosystemIssueViewErrorBoundary>
				</UFOSegment>
			);
		});

		return <>{panels}</>;
	}

	renderAdditionalWebLinksSections() {
		const { additionalWebLinksSections } = this.props;

		return (
			<>
				{additionalWebLinksSections.length > 0 && (
					<FireTrackAnalytics
						eventName="additionalWebLinksSections shown"
						componentName="additionalWebLinksSections"
					/>
				)}
				{additionalWebLinksSections.map(({ applicationId, applicationName }) => (
					<Fragment key={applicationId}>
						<UFOSegment name="issue-content-additional-web-links">
							{this.maybeWrapInSectionWrapper(
								<WebLinksConnectedView
									applicationId={applicationId}
									applicationName={applicationName}
								/>,
							)}
						</UFOSegment>
					</Fragment>
				))}
			</>
		);
	}

	render() {
		const {
			isComplete,
			isCompactMode,
			issueKey,
			isEpic,
			isAnonymousUser,
			hasIssueLinksEntity,
			shouldRenderChildrenIssues,
			shouldRenderPortfolioChildrenIssues,
			shouldRenderSubtasks,
			isServiceDesk,
			issueViewRelayFragment,
			jpdDeliveryIssueLinkTypeId,
			rootRelayFragment,
			shouldShowLinkIdeaInput,
		} = this.props;

		const issueLinks = hasIssueLinksEntity ? <IssueLinksView /> : null;

		const subtasks = shouldRenderSubtasks ? <SubtasksView key={issueKey} /> : null;

		const childrenIssues = shouldRenderChildrenIssues ? (
			<ChildrenIssuesView key={issueKey} />
		) : null;

		const jpdPanel = fg('jpd_idea_panel_in_issue_view') ? (
			<JPDIdeasPanel
				jpdDeliveryIssueLinkTypeId={jpdDeliveryIssueLinkTypeId}
				shouldShowAddIssueLinks={shouldShowLinkIdeaInput}
				issueViewRelayFragment={issueViewRelayFragment}
			/>
		) : null;

		const loomPanel = fg('loom_crossflow_enablement_in_jira') ? <LoomPanel /> : null;

		const portfolioChildIssues = shouldRenderPortfolioChildrenIssues ? (
			// @ts-expect-error TS274 missing analyticsSource, issueTypes, fetchIssueTypesStatus, onAddChildClick, and 3 more.
			<PortfolioChildIssuesView key={issueKey} />
		) : null;

		const epicIssues = isEpic ? <EpicIssuesView key={issueKey} /> : null;

		const attachments = isComplete ? <AttachmentsView /> : null;

		const devOpsAppRecommendation = isDevOpsAppRecommenderEnabled() ? (
			<Placeholder name="devOpsAppRecommendation" fallback={null}>
				<AsyncDevOpsAppRecommendationPanel />
			</Placeholder>
		) : null;

		let designPanel = null;
		if (!isAnonymousUser) {
			if (expVal('collapsed_designs_panel', 'isCollapsedDesignsPanelEnabled', false)) {
				designPanel = <SyncDesignPanel rootRelayFragment={rootRelayFragment} />;
			} else {
				designPanel = <AsyncDesignPanel />;
			}
		}

		return (
			<UFOSegment name="issue-content-items">
				<ContentItemsWrapper isCompactMode={isCompactMode}>
					<DevOpsAppRecommendationContextProvider>
						<PerformanceMark metricKey={PerfMarks.DESCRIPTION_BEGIN_MARK_KEY} />
						{/* need to rename this from *-description as it also includes things like tabs and fields */}
						<UFOSegment name="issue-content-description">
							<ContentTemplateRenderer issueViewRelayFragment={issueViewRelayFragment} />
						</UFOSegment>
						<PerformanceMark metricKey={PerfMarks.DESCRIPTION_END_MARK_KEY} />

						<PerformanceMark metricKey={PerfMarks.ATTACHMENTS_BEGIN_MARK_KEY} />
						<UFOSegment name="issue-content-attachments">
							{this.maybeWrapInSectionWrapper(attachments)}
						</UFOSegment>
						<PerformanceMark metricKey={PerfMarks.ATTACHMENTS_END_MARK_KEY} />

						<PerformanceMark metricKey={PerfMarks.DESIGNS_BEGIN_MARK_KEY} />
						<UFOSegment name="issue-content-designs-panel">
							{this.maybeWrapInSectionWrapper(designPanel)}
						</UFOSegment>
						<PerformanceMark metricKey={PerfMarks.DESIGNS_END_MARK_KEY} />
						<PerformanceMark metricKey={PerfMarks.DEV_OPS_APP_RECOMMENDATIONS_BEGIN_MARK_KEY} />
						<UFOSegment name="issue-content-dev-ops-app-recomendations">
							{this.maybeWrapInSectionWrapper(devOpsAppRecommendation)}
						</UFOSegment>
						<PerformanceMark metricKey={PerfMarks.DEV_OPS_APP_RECOMMENDATIONS_END_MARK_KEY} />
						{fg('jpd_idea_panel_in_issue_view') ? (
							<>
								<PerformanceMark metricKey={PerfMarks.JPD_IDEAS_BEGIN_MARK_KEY} />
								<UFOSegment name="issue-content-jpd-ideas">
									{this.maybeWrapInSectionWrapper(jpdPanel)}
								</UFOSegment>
								<PerformanceMark metricKey={PerfMarks.JPD_IDEAS_END_MARK_KEY} />
							</>
						) : null}

						{fg('loom_crossflow_enablement_in_jira') ? (
							<>
								<PerformanceMark metricKey={PerfMarks.LOOM_PANEL_BEGIN_MARK_KEY} />
								<UFOSegment name="issue-content-loom-panel">
									{this.maybeWrapInSectionWrapper(loomPanel)}
								</UFOSegment>
								<PerformanceMark metricKey={PerfMarks.LOOM_PANEL_END_MARK_KEY} />
							</>
						) : null}

						<PerformanceMark metricKey={PerfMarks.EPICS_BEGIN_MARK_KEY} />
						<UFOSegment name="issue-content-epics">
							{this.maybeWrapInSectionWrapper(epicIssues)}
						</UFOSegment>
						<PerformanceMark metricKey={PerfMarks.EPICS_END_MARK_KEY} />

						<PerformanceMark metricKey={PerfMarks.CHILDREN_ISSUES_BEGIN_MARK_KEY} />
						<UFOSegment name="issue-content-children-issues">
							{this.maybeWrapInSectionWrapper(childrenIssues)}
						</UFOSegment>
						<PerformanceMark metricKey={PerfMarks.CHILDREN_ISSUES_END_MARK_KEY} />

						<PerformanceMark metricKey={PerfMarks.PORTFOLIO_CHILD_ISSUES_BEGIN_MARK_KEY} />
						<UFOSegment name="issue-content-portfolio-child-issues">
							{this.maybeWrapInSectionWrapper(portfolioChildIssues)}
						</UFOSegment>
						<PerformanceMark metricKey={PerfMarks.PORTFOLIO_CHILD_ISSUES_END_MARK_KEY} />

						<PerformanceMark metricKey={PerfMarks.SUBTASKS_BEGIN_MARK_KEY} />
						<UFOSegment name="issue-content-subtasks">
							{this.maybeWrapInSectionWrapper(subtasks)}
						</UFOSegment>
						<PerformanceMark metricKey={PerfMarks.SUBTASKS_END_MARK_KEY} />

						<PerformanceMark metricKey={PerfMarks.ISSUE_LINKS_BEGIN_MARK_KEY} />
						{this.maybeWrapInSectionWrapper(issueLinks)}

						<PerformanceMark metricKey={PerfMarks.ISSUE_LINKS_END_MARK_KEY} />

						{isServiceDesk && (
							<>
								<PerformanceMark metricKey={PerfMarks.INCIDENT_INVESTIGATIONS_BEGIN_MARK_KEY} />
								<UFOSegment name="issue-content-incident-investigations">
									{this.maybeWrapInSectionWrapper(<IncidentInvestigationPanel />)}
								</UFOSegment>
								<PerformanceMark metricKey={PerfMarks.INCIDENT_INVESTIGATIONS_END_MARK_KEY} />

								{fg('automation_discoverability_in_jsm') && (
									<>
										<PerformanceMark
											metricKey={PerfMarks.AUTOMATION_DISCOVERY_PANEL_BEGIN_MARK_KEY}
										/>
										<UFOSegment name="issue-content-automation-discovery">
											{this.maybeWrapInSectionWrapper(<AutomationDiscoveryContainerPanel />)}
										</UFOSegment>
										<PerformanceMark
											metricKey={PerfMarks.AUTOMATION_DISCOVERY_PANEL_END_MARK_KEY}
										/>
									</>
								)}

								{fg('playbooks-in-jsm') && (
									// TODO: https://ifountain.atlassian.net/browse/BIF-1447 --> This placement is subject to change. Finalize the placement of Playbooks in Issue View
									<>
										<PerformanceMark metricKey={PerfMarks.PLAYBOOKS_PANEL_BEGIN_MARK_KEY} />
										<UFOSegment name="issue-content-playbooks">
											{this.maybeWrapInSectionWrapper(<PlaybooksPanel />)}
										</UFOSegment>
										<PerformanceMark metricKey={PerfMarks.PLAYBOOKS_PANEL_END_MARK_KEY} />
									</>
								)}
							</>
						)}
						{fg('ops_agent_discoverability_panel_issue_view') && (
							<>
								<PerformanceMark metricKey={PerfMarks.IT_OPS_DISCOVERABILITY_BEGIN_MARK_KEY} />
								<UFOSegment name="incident-itops-discoverability">
									{this.maybeWrapInSectionWrapper(<JiraOpsAgentDiscoverabilityPanel />)}
								</UFOSegment>
								<PerformanceMark metricKey={PerfMarks.IT_OPS_DISCOVERABILITY_END_MARK_KEY} />
							</>
						)}

						<PerformanceMark metricKey={PerfMarks.OBJECT_RELATED_REQUESTS_BEGIN_MARK_KEY} />
						<UFOSegment name="issue-content-object-related-requests">
							{this.maybeWrapInSectionWrapper(<ObjectRelatedRequestsPanel />)}
						</UFOSegment>
						<PerformanceMark metricKey={PerfMarks.OBJECT_RELATED_REQUESTS_END_MARK_KEY} />

						{fg('incident_suggested_resources') ? (
							<>
								<PerformanceMark metricKey={PerfMarks.SUGGESTED_RESOURCES_BEGIN_MARK_KEY} />
								<UFOSegment name="issue-suggested-resources">
									{this.maybeWrapInSectionWrapper(<AsyncSuggestedResourcesPanel />)}
								</UFOSegment>
								<PerformanceMark metricKey={PerfMarks.SUGGESTED_RESOURCES_END_MARK_KEY} />
							</>
						) : null}

						<PerformanceMark metricKey={PerfMarks.SMART_RELATED_ISSUES_BEGIN_MARK_KEY} />
						<UFOSegment name="issue-content-smart-related-issues">
							{this.maybeWrapInSectionWrapper(<SmartRelatedIssuesPanel />)}
						</UFOSegment>
						<PerformanceMark metricKey={PerfMarks.SMART_RELATED_ISSUES_END_MARK_KEY} />

						<PerformanceMark metricKey={PerfMarks.CONFLUENCE_PAGES_BEGIN_MARK_KEY} />
						<UFOSegment name="issue-content-confluence-pages">
							{this.maybeWrapInSectionWrapper(<ConfluenceContentView />)}
						</UFOSegment>
						<PerformanceMark metricKey={PerfMarks.CONFLUENCE_PAGES_END_MARK_KEY} />

						<PerformanceMark metricKey={PerfMarks.WEB_LINKS_BEGIN_MARK_KEY} />
						<UFOSegment name="issue-content-web-links">
							{this.maybeWrapInSectionWrapper(<WebLinksConnectedView />)}
						</UFOSegment>
						<PerformanceMark metricKey={PerfMarks.WEB_LINKS_END_MARK_KEY} />

						<PerformanceMark metricKey={PerfMarks.ADDITIONAL_WEB_LINKS_BEGIN_MARK_KEY} />
						{this.renderAdditionalWebLinksSections()}
						<PerformanceMark metricKey={PerfMarks.ADDITIONAL_WEB_LINKS_END_MARK_KEY} />

						<PerformanceMark metricKey={PerfMarks.ECOSYSTEM_CONTENT_BEGIN_MARK_KEY} />
						{this.maybeWrapInSectionWrapper(this.renderEcosystemContentPanels())}
						<PerformanceMark metricKey={PerfMarks.ECOSYSTEM_CONTENT_END_MARK_KEY} />

						<PerformanceMark metricKey={PerfMarks.LINKED_ALERTS_BEGIN_MARK_KEY} />
						<UFOSegment name="issue-content-linked-alerts">
							{this.maybeWrapInSectionWrapper(<LinkedAlertsModals />)}
						</UFOSegment>
						<PerformanceMark metricKey={PerfMarks.LINKED_ALERTS_END_MARK_KEY} />

						<PerformanceMark metricKey={PerfMarks.PROFORMA_FORMS_BEGIN_MARK_KEY} />
						<UFOSegment name="issue-content-proforma-forms">
							{this.maybeWrapInSectionWrapper(
								<WrappedProformaFormsPanelView issueKey={issueKey} />,
							)}
						</UFOSegment>
						<PerformanceMark metricKey={PerfMarks.PROFORMA_FORMS_END_MARK_KEY} />
					</DevOpsAppRecommendationContextProvider>
				</ContentItemsWrapper>
			</UFOSegment>
		);
	}
}

const DeprecatedReduxConnectedView = connect(
	(state: State) => ({
		issueKey: issueKeySelector(state),
		contentPanels: ecosystemAddedContentPanelsSelector(state),
		isComplete: isCompletedLoadingSelector(state),
		isEpic: isEpicSelector(state),
		isAnonymousUser: isAnonymousSelector(state),
		hasIssueLinksEntity: isIssueLinksEnabledSelector(state),
		jpdDeliveryIssueLinkTypeId: jpdDeliverLinkTypeIdSelector(state),
		shouldShowLinkIdeaInput: showLinkIdeasInputSelector(state),
		shouldRenderChildrenIssues: shouldRenderChildPanelSelector(state),
		shouldRenderSubtasks: shouldRenderSubtaskPanelSelector(state),
		shouldRenderPortfolioChildrenIssues: shouldRenderPortfolioChildPanelSelector(state),
		additionalWebLinksSections: additionalWebLinksSectionsSelector(state),
		isServiceDesk: isServiceDeskSelector(state),
		projectType: projectTypeSelector(state),
		context: contextSelector(state),
	}),
	{},
)(withGICHook(DeprecatedContentItems));

/**
 * @deprecated Use functional component `ContentItems` instead. Class components are deprecated.
 */
export default DeprecatedReduxConnectedView;

const issueSectionWrapperStyles = xcss({
	marginTop: 'space.300',
	':empty': {
		display: 'none',
	},
});

const issueSectionWrapperCompactStyles = xcss({
	marginTop: 'space.250',
	':empty': {
		display: 'none',
	},
});
