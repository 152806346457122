type OriginalKey = string;
type ReplacementKey = string;

/**
 * Converts record to a new one with keys replaced using keyReplacementMap.
 * If a key is not present in the map the related entry won't be copied to
 * the resulting record.
 *
 * @param record
 * @param keyReplacementMap
 * @returns new record
 */
export const replaceOmitRecordKeys = <T,>(
	record: Record<OriginalKey, T>,
	keyReplacementMap: Record<OriginalKey, ReplacementKey>,
) => {
	const result: Record<string, T> = {};

	for (const [key, value] of Object.entries(record)) {
		const localId = keyReplacementMap[key];
		if (localId) {
			result[localId] = value;
		}
	}

	return result;
};
