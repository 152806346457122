/**
 * @generated SignedSource<<e3e7eaf85051bb2d23ffb70d08f4080d>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type cascadingSelect_issueFieldCascadingSelectReadviewFull_CascadingSelectReadView$data = {
  readonly cascadingOption: {
    readonly childOptionValue: {
      readonly value: string | null | undefined;
    } | null | undefined;
    readonly parentOptionValue: {
      readonly value: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "cascadingSelect_issueFieldCascadingSelectReadviewFull_CascadingSelectReadView";
};
export type cascadingSelect_issueFieldCascadingSelectReadviewFull_CascadingSelectReadView$key = {
  readonly " $data"?: cascadingSelect_issueFieldCascadingSelectReadviewFull_CascadingSelectReadView$data;
  readonly " $fragmentSpreads": FragmentRefs<"cascadingSelect_issueFieldCascadingSelectReadviewFull_CascadingSelectReadView">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "ScalarField",
    "name": "value"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "cascadingSelect_issueFieldCascadingSelectReadviewFull_CascadingSelectReadView",
  "selections": [
    {
      "concreteType": "JiraCascadingOption",
      "kind": "LinkedField",
      "name": "cascadingOption",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraOption",
          "kind": "LinkedField",
          "name": "parentOptionValue",
          "plural": false,
          "selections": (v0/*: any*/)
        },
        {
          "concreteType": "JiraOption",
          "kind": "LinkedField",
          "name": "childOptionValue",
          "plural": false,
          "selections": (v0/*: any*/)
        }
      ]
    }
  ],
  "type": "JiraCascadingSelectField"
};
})();

(node as any).hash = "3327b7b284e2098b00737ec46c5db99a";

export default node;
