import { AnyAri } from '@atlassian/ari/any-ari';
import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { ProjectMetadata } from '@atlassian/jira-polaris-domain-project/src/types.tsx';
import { getNextgenSettingsUrl } from '@atlassian/jira-project-settings-apps-common/src/urls.tsx';
import { createHook, createStateHook } from '@atlassian/react-sweet-state';
import { ProjectStore } from '../main.tsx';

export const useIsProjectAvailable = createHook(ProjectStore, {
	selector: (state) =>
		fg('polaris-refactor-skipprojectinfo') ? !!state.project.id : !!state.project.key,
});

/**
 * Returns the Jira project key for this project.
 * @deprecated use useProjectMetadata from `@atlassian/jira-polaris-component-project-metadata` package instead
 */
export const useProjectKey = createHook(ProjectStore, {
	selector: (state) => state.project?.key,
});

/**
 * Returns the Jira project name for this project.
 * @deprecated use useProjectMetadata from `@atlassian/jira-polaris-component-project-metadata` package instead
 */
export const useProjectName = createHook(ProjectStore, {
	selector: (state) => state.project?.name,
});

export const useIsProjectOnboarded = createHook(ProjectStore, {
	selector: (state) => state.project?.onboarded,
});

export const useProjectOnboardedAtTimestamp = createStateHook(ProjectStore, {
	selector: (state) => state.project?.onboardedAtTimestamp,
});

export const useProjectTemplateVersion = createStateHook(ProjectStore, {
	selector: (state) => state.project?.templateVersion,
});

export const useSelectedDeliveryProject = createHook(ProjectStore, {
	selector: (state) => state.project?.selectedDeliveryProject,
});

export const useError = createHook(ProjectStore, {
	selector: (state) => state.meta.project.error,
});

export const useIsProjectLoading = createHook(ProjectStore, {
	selector: (state) => state.meta.project.loading,
});

/**
 * Returns array of IssueTypeId which represent `Ideas` in this Polaris project.
 * @deprecated use @atlassian/jira-polaris-component-issue-types instead (https://hello.atlassian.net/browse/FD-80462 jpd_extracted_issue_type_store)
 */
export const useIdeaIssueTypes = createHook(ProjectStore, {
	selector: (state) => state.project?.ideaIssueTypes,
});

/**
 * Returns fieldKey suitable for making calls to rank issues in this project.
 */
export const useJiraRankFieldKey = createHook(ProjectStore, {
	selector: (state) => state.project?.jiraRankFieldKey,
});

/**
 * Returns the Jira project name for this project.
 * @deprecated use useProjectMetadata from `@atlassian/jira-polaris-component-project-metadata` package instead
 */
export const useProjectAvatarUrls = createHook(ProjectStore, {
	selector: (state) => state.project?.avatarUrls,
});

export const useProjectArjHierarchyConfiguration = createHook(ProjectStore, {
	selector: (state) => state.project?.arjHierarchyConfiguration || [],
});

/**
 * Returns the url for the project settings page.
 * @deprecated use useProjectMetadata from `@atlassian/jira-polaris-component-project-metadata` package instead
 */
export const useProjectSettingsUrl = createHook(ProjectStore, {
	selector: ({ project }) => project.key && getNextgenSettingsUrl(SOFTWARE_PROJECT, project.key),
});

/**
 * @deprecated use useProjectMetadata from `@atlassian/jira-polaris-component-project-metadata` package instead
 */
export const useProjectMetadata = createStateHook(ProjectStore, {
	selector: (state): ProjectMetadata | undefined => {
		if (!state.project || state.project.id === undefined) {
			return undefined;
		}

		const id = AnyAri.parse(state.project.id).resourceId;

		if (
			id === undefined ||
			state.project.key === undefined ||
			state.project.name === undefined ||
			state.project.avatarUrls === undefined
		) {
			return undefined;
		}

		return {
			id,
			key: state.project.key,
			name: state.project.name,
			avatarUrls: state.project.avatarUrls,
		};
	},
});
