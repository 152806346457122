const DIRECT_JOIN_CONFLUENCE_SUCCESS = 'directJoinConfluenceSuccess';
const VERIFY_DIRECT_JOIN_CONFLUENCE_SUCCESS = 'verifyDirectJoinConfluenceSuccess';
const DIRECT_JOIN_DENIED = 'directJoinConfluenceDenied';
const DIRECT_JOIN_FAILED = 'directJoinConfluenceFailed';

export const SUCCESS_FLAGS = {
	directJoinSuccess: DIRECT_JOIN_CONFLUENCE_SUCCESS,
	verifyDirectJoinSuccess: VERIFY_DIRECT_JOIN_CONFLUENCE_SUCCESS,
};

export const FAIL_FLAGS = {
	directJoinFailed: DIRECT_JOIN_FAILED,
	directJoinDenied: DIRECT_JOIN_DENIED,
};
