import { freeze } from 'icepick';
import { defaultLocale } from '@atlassian/jira-common-constants/src/supported-locales.tsx';
import { type SetContextAction, SET_CONTEXT } from '../actions/index.tsx';
import type { ContextState } from '../types.tsx';

export const initialState = freeze({
	issueId: '',
	issueKey: '',
	issueStatusCategory: '',
	projectId: '',
	projectType: '',
	analyticsSource: null,
	locale: defaultLocale,
	isSiteAdmin: false,
	issueAri: '',
	nextGenProject: false,
	projectAri: '',
	isJiraAdmin: false,
});

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: ContextState = initialState, action: SetContextAction): ContextState => {
	const { type, payload } = action;
	switch (type) {
		case SET_CONTEXT:
			return freeze({ ...state, ...payload });
		default:
			return state;
	}
};
