import { Observable } from 'rxjs/Observable';

import 'rxjs/add/observable/fromPromise';

import 'rxjs/add/operator/catch';

export const fallbackOnMissingOrError$ = <T,>(
	primary: Promise<T> | null | undefined,
	fallback: () => Observable<T>,
): Observable<T> => {
	if (primary) {
		return Observable.fromPromise(primary).catch(fallback);
	}
	return fallback();
};
