import { useState } from 'react';
import { useIssueId } from '@atlassian/jira-issue-context-service/src/main.tsx';

export const useStableIssueId = () => {
	const issueIdInIssueContext = useIssueId();
	const [issueId, setIssueId] = useState(issueIdInIssueContext);

	// We need this workaround because the issueId is passed as undefined even though the issue is displayed while switching between issues in sidebar in the "Queues" view.
	// This also causes flickering in other panels such as SLAs and Details.
	// This workaround prevents the panel from unmounting and mounting again when switching between issues
	if (!!issueIdInIssueContext && issueIdInIssueContext !== issueId) {
		setIssueId(issueIdInIssueContext);
	}

	return issueId;
};
