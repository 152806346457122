/**
 * @generated SignedSource<<0d5d7559db30f2f7c54b414410b5100e>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type main_issueFieldVoters_IssueVotersRelay$data = {
  readonly issue: {
    readonly statusField: {
      readonly status: {
        readonly statusCategory: {
          readonly statusCategoryId: string;
        } | null | undefined;
      };
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"voteFieldServiceRelay_issueFieldVoters_UseVoteFieldRelayGenerator">;
  readonly " $fragmentType": "main_issueFieldVoters_IssueVotersRelay";
};
export type main_issueFieldVoters_IssueVotersRelay$key = {
  readonly " $data"?: main_issueFieldVoters_IssueVotersRelay$data;
  readonly " $fragmentSpreads": FragmentRefs<"main_issueFieldVoters_IssueVotersRelay">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "main_issueFieldVoters_IssueVotersRelay",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "voteFieldServiceRelay_issueFieldVoters_UseVoteFieldRelayGenerator"
    },
    {
      "concreteType": "JiraIssue",
      "kind": "LinkedField",
      "name": "issue",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraStatusField",
          "kind": "LinkedField",
          "name": "statusField",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraStatus",
              "kind": "LinkedField",
              "name": "status",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraStatusCategory",
                  "kind": "LinkedField",
                  "name": "statusCategory",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "statusCategoryId"
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraVotesField"
};

(node as any).hash = "bd3b2b8ebcb490d84e45e5efdc104d26";

export default node;
