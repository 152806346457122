import type { Observable as ObservableType } from 'rxjs/Observable';
import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';
import type { BaseUrl, IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';

const deleteOptions = { method: 'DELETE' } as const;
const getDeletionUrl = (baseUrl: BaseUrl, issueKey: IssueKey, globalId: string) =>
	`${baseUrl}/rest/api/3/issue/${issueKey}/remotelink?globalId=${encodeURIComponent(globalId)}`;

export const deleteRemoteIssueLink = (
	baseUrl: BaseUrl,
	issueKey: IssueKey,
	globalId: string,
): ObservableType<unknown> =>
	fetchJson$(getDeletionUrl(baseUrl, issueKey, globalId), deleteOptions);
