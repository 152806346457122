import {
	type Store,
	type StoreActionApi,
	type ActionThunk,
	createContainer,
} from '@atlassian/react-sweet-state';
import { batchStateUpdates } from '../batch-state-updates/index.tsx';
import type { TPropsAwareState } from './types.tsx';

/**
 * helper function to create a sweet state container that exposes it's props through a callback and provides
 * a listener registry to react to props changes
 */
export const createContainerWithContext = <
	TContainerProps,
	TState extends TPropsAwareState<TContainerProps>,
	TActions extends Record<string, ActionThunk<TState, TActions>>,
>(
	store: Store<TState, TActions>,
	options?: {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		onInit?: () => (api: StoreActionApi<TState>, containerProps: TContainerProps) => any;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		onUpdate?: () => (api: StoreActionApi<TState>, containerProps: TContainerProps) => any;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		onCleanup?: () => (api: StoreActionApi<TState>, containerProps: TContainerProps) => any;
		displayName?: string;
	},
) =>
	createContainer<TState, TActions, TContainerProps>(store, {
		...options,
		onInit: () => (api, containerProps) => {
			batchStateUpdates<TContainerProps, TState>(({ dispatch, setState }, props) => {
				// @ts-expect-error - TS2345 - Argument of type '{ containerProps: TContainerProps; }' is not assignable to parameter of type 'Partial<TState>'.
				setState({ containerProps: props });
				options?.onInit && dispatch(options.onInit());
			})(api, containerProps);
		},
		onUpdate: () => (api, containerProps) => {
			batchStateUpdates<TContainerProps, TState>(({ dispatch, setState, getState }, props) => {
				const prevContainerProps = getState().containerProps;
				// @ts-expect-error - TS2345 - Argument of type '{ containerProps: TContainerProps; }' is not assignable to parameter of type 'Partial<TState>'.
				setState({ containerProps: props, prevContainerProps });
				options?.onUpdate && dispatch(options.onUpdate());
			})(api, containerProps);
		},
	});
