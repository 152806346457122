/** @jsx jsx */
import React, { useCallback, useState, type ComponentPropsWithoutRef, type ReactNode } from 'react';
import { css, styled, jsx } from '@compiled/react';
import { useFragment, graphql, useMutation } from 'react-relay';
import { ErrorMessage } from '@atlaskit/form';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { fontSize, gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { FRAGMENT_SELECTABLE_FIELD_OPTIONS_FIRST } from '@atlassian/jira-issue-field-constants/src/index.tsx';
import { useInlineEditFieldInjections } from '@atlassian/jira-issue-field-injections/src/controllers/inline-edit-injections-context/index.tsx';
import { FieldInlineEditStateLess } from '@atlassian/jira-issue-field-inline-edit/src/ui/index.tsx';
import { useOptionallyControlledEditingState } from '@atlassian/jira-issue-field-optional-editing-state-manager/src/index.tsx';
import { SingleSelectEditView } from '@atlassian/jira-issue-field-single-select-editview-full/src/ui/single-select/index.tsx';
import type {
	NullableOption,
	Option,
} from '@atlassian/jira-issue-field-single-select-editview-full/src/ui/single-select/types.tsx';
import { SingleSelectReadView } from '@atlassian/jira-issue-field-single-select-readview-full/src/ui/single-select/index.tsx';
import { useQueryLoaderOnIntent } from '@atlassian/jira-issue-hooks/src/services/use-query-loader-on-intent/index.tsx';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type {
	singleSelect_issueFieldSingleSelect_SingleSelectField_Mutation as SingleSelectInlineEditViewMutation,
	singleSelect_issueFieldSingleSelect_SingleSelectField_Mutation$rawResponse as SingleSelectInlineEditViewMutationResponse,
	singleSelect_issueFieldSingleSelect_SingleSelectField_Mutation$data as SingleSelectInlineEditViewMutationData,
} from '@atlassian/jira-relay/src/__generated__/singleSelect_issueFieldSingleSelect_SingleSelectField_Mutation.graphql';
import type { singleSelect_issueFieldSingleSelect_SingleSelectField_PrefetchQuery as SingleSelectInlineEditViewPrefetchQuery } from '@atlassian/jira-relay/src/__generated__/singleSelect_issueFieldSingleSelect_SingleSelectField_PrefetchQuery.graphql';
import type { singleSelect_issueFieldSingleSelectInlineEditFull_SingleSelectInlineEditView$key as SingleSelectInlineEditViewFragment } from '@atlassian/jira-relay/src/__generated__/singleSelect_issueFieldSingleSelectInlineEditFull_SingleSelectInlineEditView.graphql';
import messages from './messages.tsx';
import type { SingleSelectInlineEditViewProps } from './types.tsx';

type JiraTypeNonNullable = NonNullable<SingleSelectInlineEditViewMutationData['jira']>;
type UpdateSingleSelectFieldTypeNonNullable = NonNullable<
	JiraTypeNonNullable['updateSingleSelectField']
>;
type ErrorsType = UpdateSingleSelectFieldTypeNonNullable['errors'];

/**
 * Inline edit will handle the switching behaviour between the 'readView' and 'editView' components.
 *
 * @param props {@link SingleSelectInlineEditViewProps}
 */
export const SingleSelectInlineEditView = ({
	fragmentRef,
	onSubmit,
	onSubmitSucceeded,
	onSubmitFailed,
	spacing = 'default',
}: SingleSelectInlineEditViewProps) => {
	const data = useFragment<SingleSelectInlineEditViewFragment>(
		graphql`
			fragment singleSelect_issueFieldSingleSelectInlineEditFull_SingleSelectInlineEditView on JiraSingleSelectField {
				id
				name
				fieldId
				type
				...singleSelect_issueFieldSingleSelectReadviewFull_SingleSelectReadView
				fieldOption {
					id
					optionId
					value
				}
				fieldConfig {
					isEditable
				}
			}
		`,
		fragmentRef,
	);

	const [commit] = useMutation<SingleSelectInlineEditViewMutation>(graphql`
		mutation singleSelect_issueFieldSingleSelect_SingleSelectField_Mutation(
			$input: JiraUpdateSingleSelectFieldInput!
		) @raw_response_type {
			jira @optIn(to: ["JiraIssueFieldMutations"]) {
				updateSingleSelectField(input: $input) {
					success
					errors {
						message
					}
					field {
						...singleSelect_issueFieldSingleSelectInlineEditFull_SingleSelectInlineEditView
					}
				}
			}
		}
	`);

	const {
		id: uniqueFieldId,
		name: fieldName,
		fieldOption,
		fieldConfig,
		fieldId,
		type: fieldType,
	} = data;
	const [isEditing, setIsEditing] = useOptionallyControlledEditingState(false, uniqueFieldId);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);

	const { overriding } = useInlineEditFieldInjections();

	const isFieldEditable = overriding.overrideIsEditable(fieldConfig?.isEditable ?? false);
	const filterOptionsById = overriding.overrideFieldOptionsFilter(null);

	const { formatMessage } = useIntl();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onCancelRequest = useCallback(() => {
		setErrorMessage(null);
		setIsEditing(false);
	}, [setIsEditing]);

	const onEditRequest = useCallback(() => {
		setErrorMessage(null);
		setIsEditing(true);
	}, [setIsEditing]);

	const onCommitError = useCallback(
		(responseErrors: ErrorsType) => {
			const responseErrorMessage =
				responseErrors?.[0]?.message || formatMessage(messages.failedSave);

			setErrorMessage(responseErrorMessage);
			setIsEditing(true);
			onSubmitFailed?.();
		},
		[formatMessage, setIsEditing, onSubmitFailed],
	);

	const onCommitCompleted = useCallback(
		(response: SingleSelectInlineEditViewMutationData, newOption: Option | null) => {
			if (!response.jira?.updateSingleSelectField) {
				onCommitError([]);
				return;
			}

			const { success, errors: responseErrors } = response.jira.updateSingleSelectField;

			if (!success) {
				onCommitError(responseErrors);
				return;
			}
			if (fg('thor_track_single_select_field_updated')) {
				fireTrackAnalytics(createAnalyticsEvent({}), 'field updated', {
					fieldKey: fieldId,
					fieldType,
				});
			}
			onSubmitSucceeded?.(newOption);
		},
		[onCommitError, onSubmitSucceeded, createAnalyticsEvent, fieldId, fieldType],
	);

	const handleNewValue = useCallback(
		(newOption: Option | null) => {
			// Clear errors and exit editing mode
			setErrorMessage(null);
			setIsEditing(false);

			// Execute mutation
			commit({
				variables: {
					input: {
						id: uniqueFieldId,
						operation: {
							operation: 'SET',
							id: newOption?.value ?? null,
						},
					},
				},
				onCompleted(response) {
					onCommitCompleted(response, newOption);
				},
				onError(error) {
					onCommitError([{ message: error.message }]);
				},
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				optimisticResponse: {
					jira: {
						updateSingleSelectField: {
							success: true,
							errors: null,
							field: {
								id: uniqueFieldId,
								name: fieldName,
								fieldOption:
									newOption != null
										? {
												id: newOption?.value,
												optionId: newOption?.optionId,
												value: newOption?.label,
											}
										: null,
								fieldConfig: { isEditable: true },
							},
						},
					},
				} as SingleSelectInlineEditViewMutationResponse,
			});
		},
		[commit, fieldName, onCommitCompleted, onCommitError, setIsEditing, uniqueFieldId],
	);

	const onConfirmRequest = useCallback(() => {
		setIsEditing(false);
	}, [setIsEditing]);

	const onChangeRequest = (value: NullableOption) => {
		handleNewValue(value);
		onSubmit?.(value);
	};

	const renderReadView = () => (
		<ReadViewContainer data-testid="issue-field-single-select-inline-edit-full.ui.single-select.read-view">
			<SingleSelectReadView fieldType={fieldType} fragmentRef={data} />
		</ReadViewContainer>
	);

	const renderEditView = () => (
		<>
			{errorMessage !== null && (
				<Box xcss={errorMessageWrapperStyles}>
					<ErrorMessage>{errorMessage}</ErrorMessage>
				</Box>
			)}
			<SingleSelectEditView
				fieldId={uniqueFieldId}
				fieldType={fieldType}
				fieldName={fieldName}
				filterOptionsById={filterOptionsById}
				value={
					fieldOption
						? {
								label: fieldOption.value ?? '',
								value: fieldOption.id ?? '',
								optionId: fieldOption.optionId ?? '',
							}
						: undefined
				}
				onChange={onChangeRequest}
				autoFocus
				spacing={spacing}
				{...(fg('fix_select_field_dropdown_opening_issue') ? { openMenuOnFocus: true } : {})}
			/>
		</>
	);

	const [, prefetchOptions, abortPrefetchOptions] =
		useQueryLoaderOnIntent<SingleSelectInlineEditViewPrefetchQuery>(graphql`
			query singleSelect_issueFieldSingleSelect_SingleSelectField_PrefetchQuery(
				$id: ID!
				$first: Int
				$filterById: JiraFieldOptionIdsFilterInput
			) {
				# eslint-disable-next-line @atlassian/relay/must-colocate-fragment-spreads
				...ui_issueSelectableFieldEditView_SelectableFieldEditViewWithFieldOptionsFragment
					@arguments(id: $id, searchBy: "", first: $first, filterById: $filterById)
			}
		`);

	return (
		<InlineEditContainer
			isEditable={isFieldEditable}
			onPointerEnter={() =>
				prefetchOptions({
					id: uniqueFieldId,
					first: FRAGMENT_SELECTABLE_FIELD_OPTIONS_FIRST,
					filterById: filterOptionsById,
				})
			}
			onPointerLeave={(e) => e.pointerType === 'mouse' && abortPrefetchOptions()}
		>
			<FieldInlineEditStateLess
				testId="issue-field-single-select-inline-edit-full.ui.single-select.field-inline-edit-state-less"
				fieldId={fg('one_event_rules_them_all_fg') ? fieldId : undefined}
				isLabelHidden
				label={fieldName}
				readView={renderReadView}
				editView={renderEditView}
				isEditing={isEditing}
				isEditable={isFieldEditable}
				hideActionButtons
				readViewFitContainerWidth
				onConfirm={onConfirmRequest}
				onCancel={onCancelRequest}
				onEdit={onEditRequest}
				editButtonLabel={formatMessage(messages.editButtonLabel, {
					fieldName,
				})}
				confirmButtonLabel={formatMessage(messages.confirmButtonLabel, {
					fieldName,
				})}
				cancelButtonLabel={formatMessage(messages.cancelButtonLabel, {
					fieldName,
				})}
			/>
		</InlineEditContainer>
	);
};

const ReadViewContainer = (props: ComponentPropsWithoutRef<typeof ReadViewContainerStyles>) => (
	<ReadViewContainerStyles data-component-selector={readViewContainerSelectorName} {...props} />
);

const readViewContainerSelectorName = 'jira-issue-view-select-inline-edit-read-view-container';
const READ_VIEW_CONTAINER_COMPONENT_SELECTOR = `[data-component-selector="${readViewContainerSelectorName}"]`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage
const nonEditableStylesNew = css({
	[READ_VIEW_CONTAINER_COMPONENT_SELECTOR]: {
		/* First tag is always mis-aligned so use the container padding to position the field */
		paddingLeft: token('space.075', '6px'),
		/* Side-by-side style overrides clashing with tags */
		'& > div > div > div': {
			padding: 0,
		},
	},
} as const);

const nonEditableStylesOld = {
	[READ_VIEW_CONTAINER_COMPONENT_SELECTOR]: {
		/* First tag is always mis-aligned so use the container padding to position the field */
		paddingLeft: token('space.075', '6px'),
		/* Side-by-side style overrides clashing with tags */
		'& > div > div > div': {
			padding: 0,
		},
	},
} as const;

const inlineEditContainerStyles = css({
	width: '100%',
	marginLeft: token('space.negative.050', '-4px'),
	marginTop: token('space.negative.100', '-8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& div[data-read-view-fit-container-width]': {
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		minHeight: '32px',
		paddingTop: 0,
		paddingBottom: 0,
		paddingLeft: token('space.075', '6px'),
		paddingRight: 0,
	},
});

const InlineEditContainerNew = ({
	isEditable,
	children,
	...props
}: {
	isEditable: boolean;
	children: ReactNode;
}) => (
	<div css={[inlineEditContainerStyles, !isEditable && nonEditableStylesNew]} {...props}>
		{children}
	</div>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InlineEditContainerOld = styled.div<{ isEditable: boolean }>(
	{
		width: '100%',
		marginLeft: token('space.negative.100', '-8px'),
		marginTop: token('space.negative.100', '-8px'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& div[data-read-view-fit-container-width]': {
			display: 'flex',
			alignItems: 'center',
			width: '100%',
			minHeight: '32px',
			paddingTop: 0,
			paddingBottom: 0,
			paddingLeft: token('space.075', '6px'),
			paddingRight: 0,
		},
	},
	/* apply non-editable styles */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isEditable }) => !isEditable && nonEditableStylesOld,
);

const InlineEditContainer = componentWithFG(
	'issue_view_field_config_edit',
	InlineEditContainerNew,
	InlineEditContainerOld,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReadViewContainerStyles = styled.div({
	display: 'flex',
	flex: '1 1 auto',
	wordBreak: 'break-word',
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	lineHeight: (gridSize * 2.5) / fontSize,
});

const errorMessageWrapperStyles = xcss({
	marginBottom: 'space.075',
});
