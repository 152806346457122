import { toSanitizedUrl } from '@atlassian/jira-development-common/src/model/common-types.tsx';
import type { CreateBranchTarget } from '../../common/types.tsx';
import type { RemoteAppResponse } from './types.tsx';

export const transformRemoteApps = ({ remoteApps = [] }: RemoteAppResponse): CreateBranchTarget[] =>
	remoteApps
		.map((remoteApp) => ({
			...remoteApp,
			url:
				remoteApp.url !== null && remoteApp.url !== undefined
					? toSanitizedUrl(remoteApp.url)
					: undefined,
		}))
		.filter((target) => target.url);
