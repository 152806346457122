import type { MutableRefObject } from 'react';
import { combineEpics } from 'redux-observable';
import type { History } from 'history';
import type { AssociatedIssuesContextActions } from '@atlassian/jira-associated-issues-context-service/src/actions.tsx';
import type { SubProduct } from '@atlassian/jira-common-constants/src/sub-product-types.tsx';
import type { AttachmentServiceActions } from '@atlassian/jira-issue-attachments-base/src/services/attachments-service/types.tsx';
import type { IssueContextServiceActions } from '@atlassian/jira-issue-context-service/src/types.tsx';
import type { FieldConfigServiceActions } from '@atlassian/jira-issue-field-base/src/services/field-config-service/types.tsx';
import type { FieldValueServiceActions } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import type { IssueRefreshLoadingActions } from '@atlassian/jira-issue-field-base/src/services/issue-refresh-loading-service/index.tsx';
import type { MediaContextServiceActions } from '@atlassian/jira-issue-media-context-service/src/types.tsx';
import type { IssueViewNonCriticalDataActions } from '@atlassian/jira-issue-non-critical-gira-service/src/controllers/use-issue-view-non-critical-data/types.tsx';
import type { IssueRefreshServiceActions } from '@atlassian/jira-issue-refresh-service/src/types.tsx';
import type { IssueScrollActions } from '@atlassian/jira-issue-scroll/src/services/types.tsx';
import type { OnErrorCallback } from '@atlassian/jira-issue-shared-types/src/common/types/error-type.tsx';
import type { UserPreferenceActions } from '@atlassian/jira-issue-user-preference-services/src/types.tsx';
import { worklogsEpic } from '@atlassian/jira-issue-view-activity/src/worklog/ops/index.tsx';
import mediaContextEpic from '@atlassian/jira-issue-view-common-media/src/media-context-epic.tsx';
import type { ResourceManager } from '@atlassian/jira-issue-view-common-utils/src/utils/prefetched-resources/prefetched-resource-manager/index.tsx';
import type { EcosystemActions } from '@atlassian/jira-issue-view-ecosystem-service/src/services/types.tsx';
import type { IssueViewFieldUpdateActions } from '@atlassian/jira-issue-view-field-update-events/src/index.tsx';
import type { ForgeServiceActions } from '@atlassian/jira-issue-view-forge-service/src/services/types.tsx';
import type { IssueViewLayoutActions } from '@atlassian/jira-issue-view-layout/src/services/types.tsx';
import type { OnChangeCallback } from '@atlassian/jira-issue-view-model/src/change-type.tsx';
import type { RecentIssuesActions } from '@atlassian/jira-issue-view-services/src/recent-issues-service/types.tsx';
import {
	baseUrlSelector,
	issueKeySelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import deleteDraftsEpic from '@atlassian/jira-issue-view-store/src/drafts/delete-draft-epic.tsx';
import loadDraftsEpic from '@atlassian/jira-issue-view-store/src/drafts/load-draft-epic.tsx';
import saveDraftsEpic from '@atlassian/jira-issue-view-store/src/drafts/save-draft-epic.tsx';
import type { WorkflowTransitionsActions } from '@atlassian/jira-issue-workflow-transitions-services/src/types.tsx';
import type { ProjectContextServiceActions } from '@atlassian/jira-project-context-service/src/types.tsx';
import type { ProjectPermissionActions } from '@atlassian/jira-project-permissions-service/src/types.tsx';
import type { DataProvider } from '@atlassian/jira-providers-issue/src/model/data-provider.tsx';
import type { JiraSettingsActions } from '@atlassian/jira-settings-service/src/types.tsx';
import analyticsEpic from './analytics/analytics-epic.tsx';
import activityFeedEpic from './epics/activity-feed-epic.tsx';
import saveActivitySortEpic from './epics/activity-sort-save-epic.tsx';
import applinkAuthenticationEpic from './epics/applink-authentication-epic.tsx';
import assignIssueParentEpic from './epics/assign-issue-parent-epic.tsx';
import attachmentsEpic from './epics/attachment-save-epic.tsx';
import attachmentSaveMediaTokenEpic from './epics/attachment-save-media-token-epic.tsx';
import cardCoverFetchEpic from './epics/card-cover-fetch-epic.tsx';
import cardCoverUpdateEpic from './epics/card-cover-update-epic.tsx';
import changeIssueTypeEpic from './epics/change-issue-type-epic.tsx';
import commentDeleteDraftEpic from './epics/comment-delete-draft-epic.tsx';
import deleteCommentEpic from './epics/comment-delete-epic.tsx';
import {
	fetchAttachmentCommentRequest,
	fetchCommentsRequest,
	fetchSortedCommentsRequest,
} from './epics/comment-fetch-epic.tsx';
import commentLoadDraftEpic from './epics/comment-load-draft-epic.tsx';
import commentMediaTokenEpic from './epics/comment-media-token-epic.tsx';
import commentSaveDraftEpic from './epics/comment-save-draft-epic.tsx';
import saveCommentEpic from './epics/comment-save-epic.tsx';
import saveCommentWithPastedContentEpic from './epics/comment-save-with-pasted-content-epic.tsx';
import confluenceAppLinksEpic from './epics/confluence-app-links-epic.tsx';
import confluencePagesEpic from './epics/confluence-pages-epic.tsx';
import contextEpic from './epics/context-epic.tsx';
import deleteLinkedIssuesEpic from './epics/delete-linked-issues-epic.tsx';
import { deleteRemoteLinkedIssuesEpic } from './epics/delete-remote-linked-issues-epic.tsx';
import addContentPanelEpic from './epics/ecosystem-content-panel-epic.tsx';
import getErrorNotificationEpic from './epics/error-notification-epic.tsx';
import fieldExperienceEpic from './epics/experience-tracking/field-experience-tracking-epic.tsx';
import fetchAssociatedIssueEpic from './epics/fetch-associated-issue-epic.tsx';
import fetchCreateLinkedIssuePermissionEpic from './epics/fetch-create-linked-issue-permission-epic.tsx';
import fetchLinkedIssues from './epics/fetch-linked-issues-epic.tsx';
import fetchForgeEpic from './epics/forge-fetch-epic.tsx';
import issueDataProviderEpic from './epics/issue-data-provider-epic.tsx';
import issueDeleteEpic from './epics/issue-delete-epic.tsx';
import fetchIssueEpic from './epics/issue-fetch-epic.tsx';
import linkedIssueAnalytics from './epics/issue-link-analytics-epic.tsx';
import saveLinkedIssues from './epics/issue-links-add-epic.tsx';
import {
	issueNavigationEpic,
	projectNavigationEpic,
	sprintNavigationEpic,
} from './epics/issue-navigation-epic.tsx';
import issueRealtimeEpic from './epics/issue-realtime-epic.tsx';
import refreshIssueEpic from './epics/issue-refresh-epic.tsx';
import fetchScreenIdEpic from './epics/issue-screenid-epic.tsx';
import issueTypesEpic from './epics/issue-types-epic.tsx';
import jiraAppLinksEpic from './epics/jira-app-links-epic.tsx';
import loginEpic from './epics/login-epic.tsx';
import multilineFieldEditEpic from './epics/multiline-field-edit-epic.tsx';
import newCommentLoadDraftEpic from './epics/new-comment-load-draft-epic.tsx';
import personalizationFetchTapTraitConfluenceActivationEpic from './epics/personalization-fetch-tap-trait-confluence-activation-epic.tsx';
import personalizationFetchTapTraitEpic from './epics/personalization-fetch-tap-trait-epic.tsx';
import prefetchIssues from './epics/prefetch-issue-epic.tsx';
import fetchProjectIssueTypes from './epics/project-issue-types-epic.tsx';
import projectValidatorEpic from './epics/project-validator-epic.tsx';
import recentlyViewedIssueEpic from './epics/recently-viewed-issue-epic.tsx';
import {
	saveRemoteLinkedIssueRequestEpic,
	saveRemoteLinkedIssueRetryEpic,
} from './epics/remote-issue-links-add-epic.tsx';
import requestPanelMetadataFetchEpic from './epics/request-panel-metadata-fetch-epic.tsx';
import shareAsCommentEpic from './epics/share-as-comment-epic.tsx';
import fetchUserEpic from './epics/user-fetch-epic.tsx';
import { createWebLink, createWebLinkRetry, deleteWebLink } from './epics/web-links-epic.tsx';
import worklogsMediaTokenEpic from './epics/worklog-media-token-epic.tsx';
import getChangeNotificationEpic from './issue-field/ops/change-notification-epic/index.tsx';
import fieldDeleteDraftEpic from './issue-field/ops/field-delete-draft-epic.tsx';
import fieldEditLoadDraftEpic from './issue-field/ops/field-edit-load-draft-epic.tsx';
import fieldExternalRequestEpic from './issue-field/ops/field-external-request-epic.tsx';
import fieldLoadDraftEpic from './issue-field/ops/field-load-draft-epic.tsx';
import fieldMediaTokenEpic from './issue-field/ops/field-media-token-epic.tsx';
import fieldSaveDraftEpic from './issue-field/ops/field-save-draft-epic.tsx';
import saveFieldEpic from './issue-field/ops/field-save-epic.tsx';
import saveFieldWithPastedContentEpic from './issue-field/ops/field-save-with-pasted-content-epic.tsx';

const getDraftEpics = () => [
	deleteDraftsEpic,
	saveDraftsEpic,
	loadDraftsEpic,
	fieldSaveDraftEpic,
	fieldDeleteDraftEpic,
	fieldLoadDraftEpic,
	fieldEditLoadDraftEpic,
	commentDeleteDraftEpic,
	commentLoadDraftEpic,
	commentSaveDraftEpic,
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getEpicsSSR = (): any[] => [contextEpic];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let getEpics: any = getEpicsSSR;

if (!__SERVER__) {
	getEpics = (
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		onChange: any,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		onError: any,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		dataProvider: any,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		history: any,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		subProduct: any,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		issueRefreshServiceActions: any,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		fieldValueActions: any,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		fieldConfigActions: any,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		issueViewLayoutActions: any,
		issueViewFieldUpdateActions: IssueViewFieldUpdateActions,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		forgeActions: any,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		ecosystemActions: any,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		attachmentActions: any,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		recentIssuesActions: any,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		userPreferenceActions: any,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		prefetchedResourceManager: any,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		issueScrollActions: any,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		permissionActions: any,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		jiraSettingsActions: any,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		projectContextActions: any,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		issueContextActions: any,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		workflowTransitionsActions: any,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		mediaContextActions: any,
		issueRefreshLoadingActionsRef: MutableRefObject<IssueRefreshLoadingActions>,
		associatedIssuesContextActions?: AssociatedIssuesContextActions,
		issueViewNonCriticalDataActions?: IssueViewNonCriticalDataActions, // eslint-disable-next-line @typescript-eslint/no-explicit-any
	): any[] => [
		contextEpic,
		analyticsEpic,
		fetchIssueEpic({
			fieldValueActions,
			fieldConfigActions,
			issueViewLayoutActions,
			ecosystemActions,
			userPreferenceActions,
			permissionActions,
			attachmentActions,
			prefetchedResourceManager,
			jiraSettingsActions,
			projectContextActions,
			issueContextActions,
			associatedIssuesContextActions,
			issueRefreshServiceActions,
		}),
		refreshIssueEpic({
			issueRefreshServiceActions,
			fieldValueActions,
			fieldConfigActions,
			issueViewLayoutActions,
			userPreferenceActions,
			permissionActions,
			attachmentActions,
			projectContextActions,
			issueContextActions,
			ecosystemActions,
			associatedIssuesContextActions,
			prefetchedResourceManager,
			issueRefreshLoadingActionsRef,
			issueViewNonCriticalDataActions,
		}),
		fetchForgeEpic(prefetchedResourceManager, forgeActions),
		applinkAuthenticationEpic,
		fetchUserEpic,
		saveFieldEpic(
			fieldValueActions,
			fieldConfigActions,
			workflowTransitionsActions,
			associatedIssuesContextActions,
			issueContextActions,
			issueViewNonCriticalDataActions,
		),
		loginEpic,
		saveCommentEpic,
		deleteCommentEpic(attachmentActions),
		fetchCommentsRequest,
		fetchSortedCommentsRequest,
		activityFeedEpic,
		fetchAttachmentCommentRequest(issueScrollActions),
		shareAsCommentEpic,
		assignIssueParentEpic(fieldValueActions),
		mediaContextEpic(
			baseUrlSelector,
			issueKeySelector,
			subProduct,
			attachmentActions,
			issueContextActions,
			mediaContextActions,
		),
		attachmentSaveMediaTokenEpic,
		commentMediaTokenEpic,
		fieldMediaTokenEpic,
		deleteLinkedIssuesEpic,
		saveLinkedIssues,
		linkedIssueAnalytics,
		fetchCreateLinkedIssuePermissionEpic,
		attachmentsEpic(attachmentActions),
		...getDraftEpics(),
		getChangeNotificationEpic(onChange, issueViewFieldUpdateActions),
		newCommentLoadDraftEpic,
		addContentPanelEpic,
		cardCoverFetchEpic,
		cardCoverUpdateEpic,
		issueNavigationEpic(history, dataProvider, recentIssuesActions, issueContextActions),
		projectNavigationEpic,
		sprintNavigationEpic,
		issueDataProviderEpic(dataProvider, associatedIssuesContextActions),
		issueDeleteEpic,
		fieldExternalRequestEpic,
		getErrorNotificationEpic(onError),
		saveCommentWithPastedContentEpic,
		saveFieldWithPastedContentEpic,
		projectValidatorEpic,
		recentlyViewedIssueEpic,
		fetchProjectIssueTypes(projectContextActions),
		changeIssueTypeEpic,
		requestPanelMetadataFetchEpic,
		jiraAppLinksEpic,
		confluenceAppLinksEpic,
		confluencePagesEpic,
		issueRealtimeEpic(issueRefreshServiceActions),
		worklogsEpic(attachmentActions, issueScrollActions),
		worklogsMediaTokenEpic,
		fieldExperienceEpic,
		multilineFieldEditEpic,
		issueTypesEpic,
		fetchScreenIdEpic,
		deleteWebLink,
		createWebLink,
		createWebLinkRetry,
		prefetchIssues,
		deleteRemoteLinkedIssuesEpic,
		saveRemoteLinkedIssueRequestEpic,
		saveRemoteLinkedIssueRetryEpic,
		saveActivitySortEpic,
		fetchAssociatedIssueEpic(associatedIssuesContextActions),
		fetchLinkedIssues(associatedIssuesContextActions),
		personalizationFetchTapTraitEpic,
		personalizationFetchTapTraitConfluenceActivationEpic,
	];
}

const getEpicsWrapper = (
	onChange: OnChangeCallback,
	onError: OnErrorCallback,
	dataProvider: DataProvider,
	history: History,
	subProduct: null | SubProduct,
	issueRefreshServiceActions: IssueRefreshServiceActions,
	fieldValueActions: FieldValueServiceActions,
	fieldConfigActions: FieldConfigServiceActions,
	issueViewLayoutActions: IssueViewLayoutActions,
	issueViewFieldUpdateActions: IssueViewFieldUpdateActions,
	forgeActions: ForgeServiceActions,
	ecosystemActions: EcosystemActions,
	attachmentActions: AttachmentServiceActions,
	recentIssuesActions: RecentIssuesActions,
	userPreferenceActions: UserPreferenceActions,
	prefetchedResourceManager: undefined | ResourceManager,
	issueScrollActions: IssueScrollActions,
	permissionActions: ProjectPermissionActions,
	jiraSettingsActions: JiraSettingsActions,
	projectContextActions: ProjectContextServiceActions,
	issueContextActions: IssueContextServiceActions,
	workflowTransitionsActions: WorkflowTransitionsActions,
	mediaContextActions: MediaContextServiceActions,
	issueRefreshLoadingActionsRef: MutableRefObject<IssueRefreshLoadingActions>,
	associatedIssuesContextActions?: AssociatedIssuesContextActions,
	issueViewNonCriticalDataActions?: IssueViewNonCriticalDataActions,
) => {
	if (!__SERVER__) {
		return getEpics(
			onChange,
			onError,
			dataProvider,
			history,
			subProduct,
			issueRefreshServiceActions,
			fieldValueActions,
			fieldConfigActions,
			issueViewLayoutActions,
			issueViewFieldUpdateActions,
			forgeActions,
			ecosystemActions,
			attachmentActions,
			recentIssuesActions,
			userPreferenceActions,
			prefetchedResourceManager,
			issueScrollActions,
			permissionActions,
			jiraSettingsActions,
			projectContextActions,
			issueContextActions,
			workflowTransitionsActions,
			mediaContextActions,
			issueRefreshLoadingActionsRef,
			associatedIssuesContextActions,
			issueViewNonCriticalDataActions,
		);
	}

	return getEpicsSSR();
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	onChange: OnChangeCallback,
	onError: OnErrorCallback,
	dataProvider: DataProvider,
	history: History,
	subProduct: SubProduct | null,
	issueRefreshServiceActions: IssueRefreshServiceActions,
	fieldValueActions: FieldValueServiceActions,
	fieldConfigActions: FieldConfigServiceActions,
	issueViewLayoutActions: IssueViewLayoutActions,
	issueViewFieldUpdateActions: IssueViewFieldUpdateActions,
	forgeActions: ForgeServiceActions,
	ecosystemActions: EcosystemActions,
	attachmentActions: AttachmentServiceActions,
	recentIssuesActions: RecentIssuesActions,
	userPreferenceActions: UserPreferenceActions,
	prefetchedResourceManager: ResourceManager | undefined,
	issueScrollActions: IssueScrollActions,
	permissionActions: ProjectPermissionActions,
	jiraSettingsActions: JiraSettingsActions,
	projectContextActions: ProjectContextServiceActions,
	issueContextActions: IssueContextServiceActions,
	workflowTransitionsActions: WorkflowTransitionsActions,
	mediaContextActions: MediaContextServiceActions,
	issueRefreshLoadingActionsRef: MutableRefObject<IssueRefreshLoadingActions>,
	associatedIssuesContextActions?: AssociatedIssuesContextActions,
	issueViewNonCriticalDataActions?: IssueViewNonCriticalDataActions,
) =>
	combineEpics(
		...getEpicsWrapper(
			onChange,
			onError,
			dataProvider,
			history,
			subProduct,
			issueRefreshServiceActions,
			fieldValueActions,
			fieldConfigActions,
			issueViewLayoutActions,
			issueViewFieldUpdateActions,
			forgeActions,
			ecosystemActions,
			attachmentActions,
			recentIssuesActions,
			userPreferenceActions,
			prefetchedResourceManager,
			issueScrollActions,
			permissionActions,
			jiraSettingsActions,
			projectContextActions,
			issueContextActions,
			workflowTransitionsActions,
			mediaContextActions,
			issueRefreshLoadingActionsRef,
			associatedIssuesContextActions,
			issueViewNonCriticalDataActions,
		),
	);
