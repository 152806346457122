import React, { useEffect, useRef } from 'react';
import { styled as styled2 } from '@compiled/react';
import Tag from '@atlaskit/tag';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import messages from '../../../../../../common/messages.tsx';
import type { PlaceholderEnum } from '../../../../../../common/types.tsx';
import { useBranchCommandBuilderStore } from '../../../../../../controllers/branch-command-builder-store/index.tsx';

export type Props = {
	value: PlaceholderEnum;
	index: number;
	focusTagIndex: number;
	id: string;
};

const Placeholder = ({ value, index, focusTagIndex, id }: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [, { removePlaceholder }] = useBranchCommandBuilderStore();
	const placeHolderRef = useRef<HTMLDivElement>(null);
	useEffect(() => {
		const button = placeHolderRef.current?.getElementsByTagName('button')[0];
		if (!button) return;

		// prevent tag 'x' button tab focus
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		(button as HTMLElement).tabIndex = -1;

		if ((index + 1) / 2 === focusTagIndex) button.focus();
	}, [focusTagIndex, index]);
	return (
		// Use 'data' attributes later when constructing
		// a state from the DOM content
		<PlaceholderContainer
			id={id}
			contentEditable={false}
			data-key={value}
			data-index={index}
			ref={placeHolderRef}
			data-testid={`development-summary-branch.ui.create-branch-dropdown.copy-branch-command.branch-command-builder.template-input.placeholder.tag-${value}`}
		>
			<Tag
				testId="development-summary-branch.ui.create-branch-dropdown.copy-branch-command.branch-command-builder.template-input.placeholder.tag"
				text={formatMessage(messages[value])}
				appearance="rounded"
				color="blueLight"
				isRemovable
				removeButtonLabel="dismiss"
				onAfterRemoveAction={() => {
					const analyticsEvent = createAnalyticsEvent({
						action: 'clicked',
						actionSubject: 'button',
					});
					fireUIAnalytics(analyticsEvent, 'updateBranchNameFormatLabelRemoved', {
						label: value,
					});
					removePlaceholder(value, index, false);
				}}
			/>
		</PlaceholderContainer>
	);
};

export default Placeholder;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PlaceholderContainer = styled2.div({
	lineHeight: 1,
	margin: `${token('space.025', '2px')} 0`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	span: {
		margin: 0,
	},
});
