import 'rxjs/add/observable/of';
import 'rxjs/add/observable/from';
import curry from 'lodash/curry';
import { Observable } from 'rxjs/Observable';
import { filter } from 'rxjs/operators/filter';
import { mergeMap as flatMap } from 'rxjs/operators/mergeMap';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';

type BatchedActionMeta = {
	batch: boolean;
};

type AnalyticsActionMeta = {
	analyticsEvent: UIAnalyticsEvent;
};

type ActionOrBatchedAction = {
	type: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	payload?: Record<any, any> | Record<any, any>[];
	meta?: AnalyticsActionMeta | BatchedActionMeta;
};

// @ts-expect-error - TS2741 - Property 'batch' is missing in type '{}' but required in type 'BatchedActionMeta'.
const isBatch = ({ meta: { batch } = {} }: ActionOrBatchedAction) => batch === true;

const includesType = curry((types: string[], { type }: ActionOrBatchedAction) =>
	types.includes(type),
);

export const ofTypeOrBatchType =
	(...types: string[]) =>
	(source: Observable<ActionOrBatchedAction>): Observable<ActionOrBatchedAction> =>
		source.pipe(
			filter((action) =>
				isBatch(action)
					? // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
						action.payload.some(includesType(types))
					: types.includes(action.type),
			),
			flatMap((action) =>
				isBatch(action)
					? // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
						Observable.from(action.payload.filter(includesType(types)))
					: Observable.of(action),
			),
		);
