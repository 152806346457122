import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	visibleToAllUsers: {
		id: 'issue-comment-base.comment.comment-visibility.visible-to-all-users',
		defaultMessage: 'Visible to all users',
		description: 'Comment visibility option. Display name for public option',
	},
	projectRolesLabel: {
		id: 'issue-comment-base.comment.comment-visibility.project-roles-label',
		defaultMessage: 'Project roles',
		description: 'Comment visibility option. Section heading for project roles',
	},
	groupsLabel: {
		id: 'issue-comment-base.comment.comment-visibility.groups-label',
		defaultMessage: 'Groups',
		description: 'Comment visibility option. Section heading for groups',
	},
});
