import React, { memo, useCallback } from 'react';
import { styled } from '@compiled/react';
import { Text } from '@atlaskit/primitives';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { FieldDescription } from '@atlassian/jira-issue-field-description/src/ui/index.tsx';
import { FieldHeading } from '@atlassian/jira-issue-field-heading/src/index.tsx';
import {
	FieldHeadingTitle,
	SideBySideField,
	FieldWrapper,
} from '@atlassian/jira-issue-field-heading/src/styled.tsx';
import { useNumberField } from '@atlassian/jira-issue-field-number/src/services/index.tsx';
import IssueNumberField from '@atlassian/jira-issue-field-number/src/ui/index.tsx';
import { FieldPin } from '@atlassian/jira-issue-field-pin/src/index.tsx';
import { genericMessages } from '@atlassian/jira-issue-view-common-constants/src/context-items-messages.tsx';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button/index.tsx';
import type { Props } from './types.tsx';

const View = ({ fieldId: fieldKey, label, area }: Props) => {
	const { formatMessage, formatNumber } = useIntl();
	const issueKey = useIssueKey();
	const showPinButton = getShowPinButton(area);
	const [{ value, fieldConfig }] = useNumberField({ issueKey, fieldKey });
	const fieldLabel = fieldConfig?.title || label;

	const renderReadView = useCallback(() => {
		// @ts-expect-error - TS2367 - This condition will always return 'false' since the types 'number' and 'string' have no overlap.
		if (value === null || value === '') {
			return <Text color="color.text.subtlest">{formatMessage(genericMessages.noValue)}</Text>;
		}
		return formatNumber(value);
	}, [formatMessage, formatNumber, value]);

	return (
		<NumberFieldWrapper data-testid={`issue.views.issue-base.context.number.${fieldKey}`}>
			<FieldHeading fieldId={fieldKey}>
				<FieldHeadingTitle>{fieldLabel}</FieldHeadingTitle>
				{issueKey !== undefined && fieldKey !== undefined && (
					<FieldDescription issueKey={issueKey} fieldKey={fieldKey} label={fieldLabel} />
				)}
				{showPinButton === true && <FieldPin fieldId={fieldKey} label={fieldLabel} />}
			</FieldHeading>
			<SideBySideField>
				<IssueNumberField
					label={fieldLabel}
					issueKey={issueKey}
					fieldKey={fieldKey}
					noValueText={formatMessage(genericMessages.noValue)}
					// @ts-expect-error - Type 'string | Element' is not assignable to type 'Element | undefined'.
					readView={renderReadView()}
					isFitContainerWidthReadView
				/>
			</SideBySideField>
		</NumberFieldWrapper>
	);
};

export default memo<Props>(View);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NumberFieldWrapper = styled(FieldWrapper)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > div > div > div > div > div > div > div': {
		width: '100%',
	},
});
