import { combineEpics } from 'redux-observable';
import type { AttachmentServiceActions } from '@atlassian/jira-issue-attachments-base/src/services/attachments-service/types.tsx';
import type { IssueScrollActions } from '@atlassian/jira-issue-scroll/src/services/types.tsx';
import { combinedEpics as addWorklogEpic } from './add-worklog/index.tsx';
import deleteWorklogEpic from './delete-worklog/index.tsx';
import editWorklogEpic from './edit-worklog/index.tsx';
import {
	fetchNewerSortedWorklogsEpic,
	fetchOlderSortedWorklogsEpic,
} from './fetch-more-worklogs-sorted/index.tsx';
import {
	fetchSurroundingWorklogsByIdEpic,
	fetchSurroundingWorklogsAttachmentScroll,
} from './fetch-surrounding-worklogs/index.tsx';
import { fetchSortedWorklogsRequest } from './fetch-worklogs-sorted/index.tsx';
import {
	fetchWorklogsRequest as fetchWorklogsEpic,
	fetchWorklogsRequestInitial as fetchWorklogsInitialEpic,
} from './fetch-worklogs/index.tsx';

export const worklogsEpicWithAttachmentScroll = (
	attachmentActions: AttachmentServiceActions,
	issueScrollActions: IssueScrollActions,
) =>
	// @ts-expect-error - TS2769 - No overload matches this call.
	combineEpics(
		addWorklogEpic,
		fetchWorklogsEpic,
		fetchWorklogsInitialEpic,
		fetchSurroundingWorklogsByIdEpic,
		fetchSurroundingWorklogsAttachmentScroll(issueScrollActions),
		editWorklogEpic,
		deleteWorklogEpic(attachmentActions),
		fetchSortedWorklogsRequest,
		fetchNewerSortedWorklogsEpic,
		fetchOlderSortedWorklogsEpic,
	);

export const worklogsEpic = (
	attachmentActions: AttachmentServiceActions,
	issueScrollActions: IssueScrollActions,
) => worklogsEpicWithAttachmentScroll(attachmentActions, issueScrollActions);
