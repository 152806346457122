import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { FieldChangesMapPublic } from '../../../../../common/types/fields/field-changes.tsx';
import type { ScreenTabsDataPublic } from '../../../../../common/types/screen-tabs.tsx';
import type { AppId, ChangeId, IssueAdjustmentsState } from '../../../types.tsx';
import { SCREEN_TABS_HISTORY_TYPE } from '../constants.tsx';
import type { AppliedChangesHistory, HistorySingleAppChange } from '../types.tsx';
import { normalizeFieldsAppliedChangesForHistory } from './utils/normalize-changes/index.tsx';

const prepareAppliedChangesHistoryForSingleChange = ({
	currentAppliedChangesHistory,
	appId,
	changeId,
	newSingleAppChange,
}: {
	currentAppliedChangesHistory: AppliedChangesHistory;
	appId: AppId;
	changeId: ChangeId;
	newSingleAppChange: HistorySingleAppChange;
}) => {
	const appliedChangesHistoryChangeId = currentAppliedChangesHistory[changeId] ?? {};
	const appliedChangesHistoryChangeIdApp = appliedChangesHistoryChangeId.apps?.[appId] ?? {};

	return {
		...currentAppliedChangesHistory,
		[changeId]: {
			apps: {
				...appliedChangesHistoryChangeId.apps,
				[appId]: { ...appliedChangesHistoryChangeIdApp, ...newSingleAppChange },
			},
			createdAt: appliedChangesHistoryChangeId.createdAt ?? Date.now(),
		},
	};
};

type CollectScreenTabsAppliedChangesHistoryPayload = {
	appId: AppId;
	changeId: ChangeId;
	screenTabs: ScreenTabsDataPublic;
};

export const collectScreenTabsAppliedChangesHistory =
	({ appId, changeId, screenTabs }: CollectScreenTabsAppliedChangesHistoryPayload) =>
	({ setState, getState }: StoreActionApi<IssueAdjustmentsState>) => {
		const { appliedChangesHistory } = getState();

		if (!screenTabs.length) {
			return;
		}

		const newAppliedChangesHistory = prepareAppliedChangesHistoryForSingleChange({
			currentAppliedChangesHistory: appliedChangesHistory,
			appId,
			changeId,
			newSingleAppChange: { tabs: { type: SCREEN_TABS_HISTORY_TYPE } },
		});

		setState({ appliedChangesHistory: newAppliedChangesHistory });
	};

type CollectFieldsAppliedChangesHistoryPayload = {
	appId: AppId;
	changeId: ChangeId;
	fieldsChanges: FieldChangesMapPublic;
};

export const collectFieldsAppliedChangesHistory =
	({ appId, changeId, fieldsChanges }: CollectFieldsAppliedChangesHistoryPayload) =>
	({ setState, getState }: StoreActionApi<IssueAdjustmentsState>) => {
		const { appliedChangesHistory, internalFormMetadata } = getState();

		const singleAppChange = normalizeFieldsAppliedChangesForHistory({
			fieldsChanges,
			internalFormMetadata,
		});

		const newAppliedChangesHistory = prepareAppliedChangesHistoryForSingleChange({
			currentAppliedChangesHistory: appliedChangesHistory,
			appId,
			changeId,
			newSingleAppChange: singleAppChange,
		});

		setState({ appliedChangesHistory: newAppliedChangesHistory });
	};
