import React, { useCallback, useMemo } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { getUpdateAnalyticsFlowHelper } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/index.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import useDeletableAttachmentsCount from '@atlassian/jira-issue-non-critical-gira-service/src/controllers/use-deletable-attachments-count/index.tsx';
import { ModalEntryPointDropdownItemTrigger } from '@atlassian/jira-modal-entry-point-dropdown-item-trigger/src/ModalEntryPointDropdownItemTrigger.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { deleteAllDialogEntryPoint } from '../../../delete-all-dialog/entrypoint.tsx';
import messages from './messages.tsx';

const DeleteAllItemEntryPointTrigger = ({ deletableCount }: { deletableCount: number }) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const entryPointProps = useMemo(
		() => ({
			onClose: () => {
				const cancelDeleteAnalyticsEvent = createAnalyticsEvent({
					action: 'closed',
					actionSubject: 'modalDialog',
				});
				fireUIAnalytics(cancelDeleteAnalyticsEvent, 'deleteAllAttachmentsDialog', {
					deletableCount,
				});
			},
			submitDeleteAnalyticEvent: createAnalyticsEvent({
				action: 'submitted',
				actionSubject: 'form',
			}),
			cancelDeleteAnalyticsEvent: createAnalyticsEvent({
				action: 'closed',
				actionSubject: 'modalDialog',
			}),
		}),
		[createAnalyticsEvent, deletableCount],
	);
	const modalProps = useMemo(
		() => ({
			width: 'small',
		}),
		[],
	);

	const onClick = useCallback(() => {
		const analyticsEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'dropdownItem',
		});
		fireUIAnalytics(analyticsEvent, 'deleteAllAttachmentsDialog', {
			deletableCount,
		});
		if (fg('one-update-event-analytics-attach-file')) {
			getUpdateAnalyticsFlowHelper().fireAnalyticsStart('attachment', {
				analytics: createAnalyticsEvent({}),
				attributes: {
					fieldType: 'attachment',
					actionTaken: 'remove',
				},
			});
		}
	}, [createAnalyticsEvent, deletableCount]);

	return (
		<ModalEntryPointDropdownItemTrigger
			entryPoint={deleteAllDialogEntryPoint}
			entryPointProps={entryPointProps}
			interactionName="deleteAllAttachmentsDialog"
			onClick={onClick}
			key="delete-all-attachments"
			/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
			testId="issue.views.issue-base.content.attachment.heading.meatballs.delete-all-item.dropdown-item"
			modalProps={modalProps}
			useInternalModal
		>
			{formatMessage(messages.deleteAll)}
		</ModalEntryPointDropdownItemTrigger>
	);
};

const DeleteAllItem = () => {
	const issueKey = useIssueKey();
	const [{ value: deletableCount }] = useDeletableAttachmentsCount(issueKey);

	// BE can temporarily return -1, so we safeguard it with `<= 0`
	if (typeof deletableCount !== 'number' || deletableCount <= 0) {
		return null;
	}

	return <DeleteAllItemEntryPointTrigger deletableCount={deletableCount} />;
};
export default DeleteAllItem;
