import type { KeyboardEvent } from 'react';
import each from 'lodash/each';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import map from 'lodash/map';
import min from 'lodash/min';
import { isFirefox } from '@atlassian/jira-common-util-browser/src/index.tsx';
import { initialTemplates } from '../../../../../common/constants.tsx';
import {
	type CaretPosition,
	type UserConfigTemplates,
	BranchTemplateType,
} from '../../../../../common/types.tsx';

function isInputNode(node: Node) {
	return (
		node.nodeType === Node.ELEMENT_NODE &&
		node instanceof HTMLDivElement &&
		node.attributes.getNamedItem('role')?.value === 'textbox'
	);
}
function isTagNode(node: HTMLElement | ChildNode) {
	return (
		node.nodeType === Node.ELEMENT_NODE &&
		node instanceof HTMLElement &&
		!isEmpty(node.dataset?.key)
	);
}

function isTextNode(node: HTMLElement | ChildNode) {
	return (
		node.nodeType === Node.ELEMENT_NODE && node instanceof HTMLElement && isEmpty(node.dataset?.key)
	);
}

function minOfLength(textLength: number, characterIndex: number) {
	return min([textLength, characterIndex]) ?? 0;
}

function checkConsecutiveNodes(
	childNodes: NodeListOf<ChildNode>,
	previousCaretPosition: CaretPosition,
	isGivenNodeType: (node: ChildNode | HTMLElement) => boolean,
	templateType: BranchTemplateType,
) {
	let lastNode = null;
	let hasConsecutiveNodes = false;
	let caretPosition = previousCaretPosition;
	let templateIndex = 0;

	if (!isFirefox()) {
		return { hasConsecutiveNodes, caretPosition };
	}

	for (let i = 0; i < childNodes.length; i += 1) {
		const childNode = childNodes[i];

		if (isTagNode(childNode)) {
			templateIndex += 1;
		}

		if (lastNode !== null && isGivenNodeType(childNode) && isGivenNodeType(lastNode)) {
			hasConsecutiveNodes = true;

			const characterIndex =
				templateType === BranchTemplateType.TEXT ? lastNode.textContent?.length ?? 0 : 0;

			caretPosition = {
				templateIndex,
				characterIndex,
			};
			break;
		}

		lastNode = childNode;
	}

	return { hasConsecutiveNodes, caretPosition };
}

function checkConsecutiveTextNodes(
	childNodes: NodeListOf<ChildNode>,
	previousCaretPosition: CaretPosition,
) {
	return checkConsecutiveNodes(
		childNodes,
		previousCaretPosition,
		isTextNode,
		BranchTemplateType.TEXT,
	);
}

function checkConsecutiveTagNodes(
	childNodes: NodeListOf<ChildNode>,
	previousCaretPosition: CaretPosition,
) {
	return checkConsecutiveNodes(
		childNodes,
		previousCaretPosition,
		isTagNode,
		BranchTemplateType.PLACEHOLDER,
	);
}

export function isEmptyInput(inputElement: HTMLElement | null) {
	if (inputElement === null) {
		return false;
	}

	return (
		(inputElement.childNodes.length === 1 &&
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			(inputElement.childNodes[0] as HTMLElement).tagName === 'BR') ||
		inputElement.childNodes.length === 0
	);
}

export function buildTemplateFromEnteredInput(
	event: KeyboardEvent<HTMLElement>,
): UserConfigTemplates {
	let result = map(event.currentTarget.childNodes, (node) => {
		if (isTagNode(node)) {
			return {
				type: BranchTemplateType.PLACEHOLDER,
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				value: (node as HTMLElement).dataset.key as string,
			};
		}
		return { type: BranchTemplateType.TEXT, value: node.textContent ?? '' };
	});
	if (result.length === 0) result = [Array.from(initialTemplates)[0]];
	return result;
}

export function getCaretPosition(
	window: Window,
	event: KeyboardEvent<HTMLElement>,
	previousCaretPosition: CaretPosition,
): CaretPosition {
	const styledInputNode = event.currentTarget;

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const selection = window.getSelection();

	if (selection === null || selection?.rangeCount < 0) {
		// can't find a focus or a selection, return the default position
		return {
			templateIndex: 0,
			characterIndex: 0,
		};
	}

	if (selection?.rangeCount === 0) {
		return previousCaretPosition;
	}

	const range = selection?.getRangeAt(0) || {};
	const { endContainer: selectedTextNode, endOffset } = range;
	let templateIndex = 0;
	let characterIndex = 0;

	const { hasConsecutiveNodes: hasConsecutiveTextNodes, caretPosition: manualPosition } =
		checkConsecutiveTextNodes(styledInputNode.childNodes, previousCaretPosition);

	if (hasConsecutiveTextNodes) {
		return manualPosition;
	}

	const { hasConsecutiveNodes: hasConsecutiveTagNodes, caretPosition: updatedCaretPosition } =
		checkConsecutiveTagNodes(styledInputNode.childNodes, previousCaretPosition);

	if (hasConsecutiveTagNodes) {
		return updatedCaretPosition;
	}

	if (isFirefox() && isInputNode(selectedTextNode)) {
		/**
		 * In Firefox, when you delete a tag with the DELETE button (instead of Backspace),
		 * the focus is set on the input tag itself and the range is no longer on the new text tag.
		 *
		 * So we can use the previous caret position to retain the focus at the correct place.
		 */
		return previousCaretPosition;
	}

	each(styledInputNode.childNodes, (childNode) => {
		if (isTagNode(childNode)) {
			// we have reached a 'Tag' node, so reset character index as it's a new block
			// and increment template index
			templateIndex += 1;
			characterIndex = 0;
		} else if (
			// same text input node but with no text in it
			childNode.childNodes.length === 1 &&
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			(childNode.childNodes[0] as HTMLElement).tagName === 'BR' &&
			childNode === selectedTextNode
		) {
			characterIndex = 0;
			return false;
		} else if (childNode?.childNodes[0] === selectedTextNode || childNode === selectedTextNode) {
			// we have reached the end node, increment the index
			// until the end of the selected range
			characterIndex += endOffset;
			// return false to exit the 'each'
			return false;
		}
		return true;
	});
	return { templateIndex, characterIndex };
}

export const restoreCaretPosition = (
	window: Window,
	document: Document,
	inputElement: HTMLElement | null,
	caretPosition: CaretPosition,
) => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const range = document.createRange();
	if (window === null || window === undefined || inputElement === null) {
		// we can't restore caret position
		return;
	}

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	if (!isFunction(window.getSelection) || window.getSelection() === null) {
		// we can't restore caret position
		return;
	}

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const selection = window.getSelection();
	if (selection === null) {
		return;
	}

	let templatesYetToScan = caretPosition.templateIndex;
	for (let i = 0; i < inputElement.childNodes.length; i += 1) {
		const node = inputElement.childNodes[i];
		if (isTagNode(node)) {
			templatesYetToScan -= 1;
		}
		if (templatesYetToScan === 0) {
			let textNode = isTagNode(node) ? inputElement.childNodes[i + 1] : node;
			if (textNode === undefined) {
				textNode = inputElement.childNodes[i];
			}

			if (textNode.childNodes.length > 0) {
				// node with text
				const characterPosition = minOfLength(
					textNode.childNodes[0].textContent?.length ?? 0,
					caretPosition.characterIndex,
				);
				range.setStart(textNode.childNodes[0], characterPosition);
				range.setEnd(textNode.childNodes[0], characterPosition);
			} else {
				// node without text
				const characterPosition = minOfLength(
					textNode.textContent?.length ?? 0,
					caretPosition.characterIndex,
				);
				range.setStart(textNode, characterPosition);
				range.setEnd(textNode, characterPosition);
			}
			selection.removeAllRanges();
			selection.addRange(range);
			break; // exit 'for' loop as we have found our text node to set the caret position on
		}
	}
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const changeEachLineLastTextElementStyle = (inputRef: any) => {
	const eachLineLastTextNode: string[] = [];
	const nodePosition: { [key: number]: string } = {};
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	(inputRef.current as HTMLElement).childNodes.forEach((textNode) => {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const textElement = textNode as HTMLElement;
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const nodeId = textElement.getAttribute?.('id') as string;

		// We use HTMLElement.offsetTop to differentiate between lines
		const nodeTop = textElement.offsetTop;

		nodePosition[nodeTop] = nodeId;
	});

	Object.values(nodePosition).forEach((elementId: string) => {
		if (elementId?.includes(BranchTemplateType.TEXT)) {
			eachLineLastTextNode.push(elementId);
		}
	});

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	(inputRef.current as HTMLElement).childNodes.forEach((node) => {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const element = node as HTMLElement;
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const id = element.getAttribute?.('id') as string;
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const isLastTextNode = eachLineLastTextNode.includes(id as string);
		if (element?.style) element.style.flex = isLastTextNode ? 'auto' : 'none';
	});
};
