// In IE, selectionRange.toString() always convert all &nbsp; to normal space on other browsers, it does not.
// So we must convert manually to made consistency across all browsers
export const sanitiseSelection = (selectedText: string | undefined) => {
	if (!selectedText) return '';
	return selectedText
		.replace(/\u00a0/g, '\u0020')
		.replace(/\n/, ' ')
		.trim();
};

// This is to make reading aid functions identical to Confluence
// For more details, check next/packages/contextual-reading-aids/src/ReadingAidsPopup.tsx#186
export const TAGS_WITH_CONTEXTS = ['SPAN', 'A', 'STRONG', 'CODE'];

export const getSelectionParentContent = (range: Range) => {
	const container = range.startContainer.parentElement;
	if (!range || !container) {
		return '';
	}
	if (TAGS_WITH_CONTEXTS.includes(container.tagName)) {
		return container.parentElement
			? container.parentElement.textContent || container.innerText
			: '';
	}
	return container.textContent || container.innerText;
};

const SENTENCE_REGEX =
	/(?=[^])(?:\P{Sentence_Terminal}|\p{Sentence_Terminal}(?!['"`\p{Close_Punctuation}\p{Final_Punctuation}\s]))*(?:\p{Sentence_Terminal}+['"`\p{Close_Punctuation}\p{Final_Punctuation}]*|$)/guy;
const MAX_SENTENCE_LENGTH = 200;

export const extractSelectionContext = (content: string, query: string) => {
	const contextSentences = content
		.match(SENTENCE_REGEX)
		?.filter((sentence) => !!sentence.match(query.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&')));
	if (
		contextSentences &&
		contextSentences[0] &&
		contextSentences[0].length <= MAX_SENTENCE_LENGTH
	) {
		return contextSentences[0].trim();
	}
	return '';
};

export const getSelectionText = (trigger?: HTMLElement) => {
	if (trigger) {
		if (!trigger.innerText) {
			return {};
		}
		const selectedContext = trigger.parentElement;
		const additionalContext = selectedContext
			? selectedContext.textContent || selectedContext.innerText
			: '';
		return {
			boundingRect: trigger.getBoundingClientRect(),
			selectedText: {
				value: trigger.innerText,
				context: additionalContext,
			},
		};
	}

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const selection = window.getSelection();
	if (!selection || selection.isCollapsed) {
		return {};
	}

	const range = selection.getRangeAt(0);

	const value = sanitiseSelection(range.toString());

	if (!value) {
		return {};
	}

	const rects = range.getClientRects();
	const boundingRect = Array.from(rects).find((r) => r.width !== 0 && r.height !== 0);

	if (!boundingRect) {
		return {};
	}
	const parentContent = getSelectionParentContent(range);
	const context = extractSelectionContext(parentContent, value);

	return {
		selectedText: {
			value,
			context,
		},
		boundingRect,
	};
};

export const calculateTriggerPosition = (
	container: HTMLDivElement,
	_mouseX: number,
	trigger?: HTMLElement,
) => {
	const { boundingRect, selectedText } = getSelectionText(trigger);
	if (boundingRect && selectedText) {
		const { left, top, width, height } = boundingRect;
		const { left: portalLeft, top: portalTop } = container.getBoundingClientRect();
		const position = {
			top: top - portalTop,
			left: left - portalLeft,
			width,
			height,
		};
		return { position, selectedText };
	}
	return undefined;
};
