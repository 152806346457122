import type { ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import {
	type IssueTypeCreateResponse,
	performCreateIssueType,
} from '../../services/jira/create-issue-type.tsx';
import type { IssueType, IssueTypeModifiableProps, State } from '../types.tsx';

const transformIssueType = (rest: IssueTypeCreateResponse): IssueType => ({
	id: String(rest.id),
	name: rest.name,
	entityId: rest.entityId,
	description: rest.description,
	avatarId: String(rest.avatarId),
	hierarchyLevel: rest.hierarchyLevel,
	operation: {
		deletable: true,
		editable: true,
	},
});

export const createIssueType =
	(projectId: ProjectId, data: IssueTypeModifiableProps) =>
	({ setState, getState }: StoreActionApi<State>) => {
		return performCreateIssueType(projectId, {
			name: data.name,
			description: data.description,
			avatarId: Number(data.avatarId),
		})
			.then(transformIssueType)
			.then((newIssueType) => {
				setState({
					issueTypesById: {
						...getState().issueTypesById,
						[newIssueType.id]: newIssueType,
					},
					issueTypesByProjectId: {
						...getState().issueTypesByProjectId,
						[projectId]: [...(getState().issueTypesByProjectId[projectId] ?? []), newIssueType.id],
					},
				});
			});
	};
