import { useState, useEffect } from 'react';
import { useSetTimeoutAutoClear } from '../../../utils.tsx';
import type { UseIsTruncatedProps } from './types.tsx';

export const useShouldShowTooltip = () => useState<boolean>(false);
export const useIsTruncated = () => useState<boolean>(false);

export const useTruncatedTooltip = ({ containerRef, tooltipDelay }: UseIsTruncatedProps) => {
	const [shouldShowTruncatedTooltip, setShouldShowTruncatedTooltip] = useShouldShowTooltip();
	const [isTruncated, setIsTruncated] = useIsTruncated();

	const { setTimeoutAutoClear } = useSetTimeoutAutoClear();
	const setTimeoutFunc = setTimeoutAutoClear;

	useEffect(() => {
		const container = containerRef.current;
		if (!container) return;

		if (container.offsetWidth < container.scrollWidth) {
			setIsTruncated(true);
		} else {
			setIsTruncated(false);
		}
	}, [setIsTruncated, containerRef]);

	useEffect(() => {
		if (isTruncated && tooltipDelay === 0) {
			setShouldShowTruncatedTooltip(true);
			// Replace with lodash/noop
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			return () => {};
		}

		if (isTruncated && tooltipDelay > 0) {
			setTimeoutFunc(() => setShouldShowTruncatedTooltip(true), tooltipDelay);
			// Replace with lodash/noop
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			return () => {};
		}

		setShouldShowTruncatedTooltip(false);
		// Replace with lodash/noop
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		return () => {};
	}, [isTruncated, setShouldShowTruncatedTooltip, tooltipDelay, setTimeoutFunc]);

	return { shouldShowTruncatedTooltip };
};
