import { createAri } from '@atlassian/jira-platform-ari/src/index.tsx';
import type { CloudId, IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { PACKAGE_NAME, TEAM_NAME } from './constants.tsx';

export const createIssueAri = (cloudId: CloudId, issueId: IssueId) =>
	createAri({
		resourceOwner: 'jira',
		cloudId,
		resourceType: 'issue',
		resourceId: issueId,
	});

export const getAnalyticsMeta = (id: string) => ({
	id: `${PACKAGE_NAME}.${id}`,
	packageName: PACKAGE_NAME,
	teamName: TEAM_NAME,
});
