import React, { useEffect } from 'react';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { QuickApprovalsButtonEntryPoint } from './entrypoint.tsx';

const entryPointParams = {};
const emptyObject = {};

export const AsyncQuickApprovalsButton = () => {
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		QuickApprovalsButtonEntryPoint,
		entryPointParams,
	);

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		entryPointActions.load();
	}, [entryPointActions]);

	return (
		<JiraEntryPointContainer
			id="jswApprovalsPanel"
			packageName="jira-software-quick-approvals-panel-button"
			teamName="a4t-pandora"
			fallback={null}
			entryPointReferenceSubject={entryPointReferenceSubject}
			runtimeProps={emptyObject}
		/>
	);
};
