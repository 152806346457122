import React from 'react';
import { styled } from '@compiled/react';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import SquareAddIconButton from '@atlassian/jira-issue-view-common-views/src/button/square-add-icon-button.tsx';
import { messages } from './messages.tsx';
import type { Props } from './types.tsx';

const AddButton = ({ onClick }: Props) => {
	const intl = useIntl();

	return (
		<RightSideContainer data-testId="issue.views.issue-base.content.web-links.add-button">
			<SquareAddIconButton label={intl.formatMessage(messages.label)} onClick={onClick} />
		</RightSideContainer>
	);
};

export default AddButton;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RightSideContainer = styled.span({
	marginLeft: 'auto',
});
