import React from 'react';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import { useUFOComponentExperience } from '@atlassian/ufo';
import { jsmChatChannelExperience } from './common/ui/experience/index.tsx';
import type { Props } from './ui/chat-channel-field/index.tsx';
import type ChatChannel from './ui/index.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyChatChannel = lazyForPaint<typeof ChatChannel>(
	() => import(/* webpackChunkName: "async-servicedesk-incident-management-chat-channel" */ './ui'),
	{ ssr: false },
);

export const AsyncChatChannelField = (props: Props) => {
	useUFOComponentExperience(jsmChatChannelExperience);
	return (
		<Placeholder name="servicedesk-incident-management-chat-channel" fallback={null}>
			<LazyChatChannel {...props} />
		</Placeholder>
	);
};
