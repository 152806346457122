import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { ViewSectionTypeViews } from '../../../views/types.tsx';
import { getPolarisQuerySelector } from '../../selectors/index.tsx';
import type { State, Props } from '../../types.tsx';
import { createQueryStringPostfix } from '../../utils/query.tsx';
import { getStorageRouteSlugWithLegacySupport } from '../../utils/storage.tsx';

export const init =
	() =>
	(
		{ getState, setState }: StoreActionApi<State>,
		{ getAppUrl, storageKey, section, useNativeReplaceMethod }: Props,
	) => {
		const storage = getStorageRouteSlugWithLegacySupport();

		// use storage route only if it's related to current project
		if (storage && storage.storageKey === storageKey) {
			setState({
				routeFromStorage: {
					section: storage.section,
					resource: storage.resource,
				},
			});
			const currentQuery = getPolarisQuerySelector(getState());
			if (
				storage === undefined ||
				section !== undefined ||
				(storage.section === 'settings' && storage.resource === 'workflow') ||
				(storage.section !== ViewSectionTypeViews && currentQuery.selectedIssue) ||
				(storage.storageKey !== undefined && storage.storageKey !== storageKey) ||
				storageKey === undefined ||
				getAppUrl === undefined
			) {
				return;
			}
			useNativeReplaceMethod(
				`${getAppUrl({
					resource: storage.resource,
					section: storage.section,
				})}${createQueryStringPostfix(currentQuery)}`,
			);
		}
	};
