import { INTERVAL_FIELD_SOURCES } from '@atlassian/jira-polaris-domain-field/src/field/interval/index.tsx';
import type { DateFieldConfiguration } from '@atlassian/jira-polaris-remote-issue/src/services/jira/types.tsx';
import type { Props } from '../../../types.tsx';

export function getFieldConfigurations(fields: Props['fields']): DateFieldConfiguration[] {
	const dateFieldConfigurations: DateFieldConfiguration[] = [];

	fields?.forEach((field) => {
		if (
			field?.configuration?.source === INTERVAL_FIELD_SOURCES.DELIVERY_DATE &&
			field?.configuration?.customfieldKey &&
			field?.configuration?.aggregationType
		) {
			dateFieldConfigurations.push({
				fieldKey: field.configuration.customfieldKey,
				aggregationType: field.configuration.aggregationType,
			});
		}
	});

	return dateFieldConfigurations;
}
