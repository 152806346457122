import type {
	CompositionFormula,
	DynamicFieldFormula,
	FieldVisitor,
	FormulaVisitor,
} from '../types.tsx';
import {
	COMPOSITION_PRODUCT,
	COMPOSITION_SUM,
	COMPOSITION_TEMPLATE,
	COMPOSITION_QUOTIENT,
} from './types.tsx';

export const sum = (formulas: DynamicFieldFormula[]): CompositionFormula => ({
	template: COMPOSITION_TEMPLATE,
	parameters: {
		agg: COMPOSITION_SUM,
		formulas,
	},
});

export const multiply = (formulas: DynamicFieldFormula[]): CompositionFormula => ({
	template: COMPOSITION_TEMPLATE,
	parameters: {
		agg: COMPOSITION_PRODUCT,
		formulas,
	},
});

export const quotient = (formulas: DynamicFieldFormula[]): CompositionFormula => ({
	template: COMPOSITION_TEMPLATE,
	parameters: {
		agg: COMPOSITION_QUOTIENT,
		formulas,
	},
});

export const isSumFormula = (formula: DynamicFieldFormula): formula is CompositionFormula =>
	formula.template === COMPOSITION_TEMPLATE && formula.parameters.agg === COMPOSITION_SUM;

export const compositionVisitFields = (
	formula: DynamicFieldFormula,
	visitField: FieldVisitor,
	visitFormula: FormulaVisitor,
): boolean => {
	if (formula.template !== COMPOSITION_TEMPLATE) {
		return false;
	}
	formula.parameters.formulas.forEach(visitFormula);
	return true;
};
