import React from 'react';
import Heading from '@atlaskit/heading';
import Image from '@atlaskit/image';
import { Box, xcss, Flex, Stack, Text } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import {
	SCREEN,
	FireScreenAnalytics,
	ContextualAnalyticsData,
} from '@atlassian/jira-product-analytics-bridge';
import addCommentDarkIllustration from './assets/add-comment-dark.svg';
import addCommentIllustration from './assets/add-comment.svg';
import messages from './messages.tsx';

export type CommentEmptyStateProps = {
	hasDraft: boolean;
	shortcutKey: JSX.Element;
};

export const CommentEmptyState = ({ hasDraft, shortcutKey }: CommentEmptyStateProps) => {
	const { formatMessage } = useIntl();

	return (
		<ContextualAnalyticsData
			sourceType={SCREEN}
			sourceName="issueCommentEmptyState"
			attributes={{ hasDraft }}
		>
			<FireScreenAnalytics />
			<Flex xcss={placeholderWrapperStyles} columnGap="space.100" justifyContent="start">
				<Box xcss={imageWrapperStyles}>
					<Image
						src={addCommentIllustration}
						srcDark={addCommentDarkIllustration}
						alt={formatMessage(messages.header)}
					/>
				</Box>
				<Stack alignInline="start" space="space.100">
					<Heading size="xsmall">{formatMessage(messages.header)}</Heading>
					<Text size="small" color="color.text.subtle">
						{formatMessage(messages.description)}
					</Text>
					<Box xcss={shortcutHintStyles}>
						<Text size="small" color="color.text.subtle">
							{formatMessage(messages.commentShortcutHintMessage, { shortcutKey })}
						</Text>
					</Box>
				</Stack>
			</Flex>
		</ContextualAnalyticsData>
	);
};

const placeholderWrapperStyles = xcss({
	marginTop: 'space.200',
});

const imageWrapperStyles = xcss({
	width: '88px',
	height: '88px',
	margin: 'space.100',
});

const shortcutHintStyles = xcss({
	marginTop: 'space.050',
});
