import React, { useMemo, type ReactNode } from 'react';
import { JiraSiteAri } from '@atlassian/ari/jira/site';
import { devOpsAppRecommendationPanelEntryPoint } from '@atlassian/jira-dev-ops-app-recommendations/entrypoint.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { createUseEntryPointContext } from '@atlassian/jira-entry-point/src/controllers/utils/create-use-entry-point-context/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';

export const DevOpsAppRecommendationContext =
	createUseEntryPointContext<typeof devOpsAppRecommendationPanelEntryPoint>();

export const DevOpsAppRecommendationContextProvider = ({ children }: { children: ReactNode }) => {
	const cloudId = useCloudId();
	const siteAri = JiraSiteAri.create({ siteId: cloudId }).toString();
	const entryPointParams = useMemo(
		() => ({
			siteAri,
		}),
		[siteAri],
	);
	const entryPointResult = useEntryPoint(devOpsAppRecommendationPanelEntryPoint, entryPointParams);

	return (
		<DevOpsAppRecommendationContext.Provider value={entryPointResult}>
			{children}
		</DevOpsAppRecommendationContext.Provider>
	);
};
