export type SprintFieldValue = {
	id: number;
	state: 'active' | 'closed' | 'future';
	name: string;
	boardId?: number;
	goal?: string;
	startDate?: string;
	endDate?: string;
	completeDate?: string;
	fromCache?: boolean;
	projectKeys?: string[];
};

export const findActiveSprint = (sprints: SprintFieldValue[] | null) => {
	const activeSprint = sprints
		? sprints.find((sprint) => sprint.state && sprint.state.toLowerCase() === 'active')
		: null;
	return activeSprint || null;
};

export const findFutureSprint = (sprints: SprintFieldValue[] | null) => {
	const futureSprint = sprints
		? sprints.find((sprint) => sprint.state && sprint.state.toLowerCase() === 'future')
		: null;
	return futureSprint || null;
};
