import { createSelector } from 'reselect';
import { entitiesSelector } from '../common/state/selectors/issue-selector.tsx';
import { permissionsSelector } from '../common/state/selectors/permissions-selector.tsx';
import { isIssueLinksEnabledSelector } from './issue-links-selector.tsx';

// Deprecated. Use jira-settings-hook instead if possible
export const issueLinkTypesSelector = createSelector(
	entitiesSelector,
	(entities) => entities.jiraSettings.issueLinkTypes,
);

export const canAddIssueLinksSelector = createSelector(
	permissionsSelector,
	isIssueLinksEnabledSelector,
	issueLinkTypesSelector,
	({ canLinkIssues }, issueLinksEnabled, types) =>
		!!(canLinkIssues && issueLinksEnabled && types && types.length),
);
