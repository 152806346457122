/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//German (Germany)
export default {
  'fabric.elements.share.copied.to.clipboard.message': 'Link in Zwischenablage kopiert',
  'fabric.elements.share.copylink.button.text': 'Link kopieren',
  'fabric.elements.share.copypubliclink.button.text': 'Öffentlichen Link kopieren',
  'fabric.elements.share.failure.icon.label': 'Fehler-Symbol',
  'fabric.elements.share.failure.message': 'Teilen nicht möglich',
  'fabric.elements.share.form.comment.label': 'Nachricht (optional)',
  'fabric.elements.share.form.comment.placeholder': 'Irgendetwas Wissenswertes?',
  'fabric.elements.share.form.info.message.no.invite.confluence':
    'Empfänger sehen den Namen der Seite und Ihre Nachricht',
  'fabric.elements.share.form.info.message.no.invite.jira': 'Empfänger sehen den Namen des Vorgangs und Ihre Nachricht',
  'fabric.elements.share.form.no-permissions': 'Sie können keine Elemente teilen.',
  'fabric.elements.share.form.public.send': 'Öffentlichen Link senden',
  'fabric.elements.share.form.public.share': 'Öffentlichen Link teilen',
  'fabric.elements.share.form.required-field.summary': 'Pflichtfelder sind mit einem Sternchen markiert.',
  'fabric.elements.share.form.retry': 'Erneut versuchen',
  'fabric.elements.share.form.send': 'Senden',
  'fabric.elements.share.form.share': 'Teilen',
  'fabric.elements.share.form.title': 'Teilen',
  'fabric.elements.share.form.user-picker.add-more': 'Weitere eingeben',
  'fabric.elements.share.form.user-picker.label.confluence': 'Namen, Teams, Gruppen oder E-Mail-Adressen',
  'fabric.elements.share.form.user-picker.label.email-disabled-confluence': 'Namen, Teams oder Gruppen',
  'fabric.elements.share.form.user-picker.label.email-disabled-jira': 'Namen oder Teams',
  'fabric.elements.share.form.user-picker.label.email-only': 'E-Mail-Adressen',
  'fabric.elements.share.form.user-picker.label.jira': 'Namen, Teams oder E-Mail-Adressen',
  'fabric.elements.share.form.user-picker.no-options.existingUserOnly':
    'Es wurden keine Ergebnisse für „{inputValue}“ gefunden.',
  'fabric.elements.share.form.user-picker.no-options.generic':
    'Es wurden keine Ergebnisse für „{inputValue}“ gefunden. Laden Sie Personen mittels E-Mail-Adresse ein.',
  'fabric.elements.share.form.user-picker.placeholder.confluence': 'z. B. Maria, Team Orange, Gruppe eins',
  'fabric.elements.share.form.user-picker.placeholder.email-disabled-jira': 'z. B. Maria, Team Orange',
  'fabric.elements.share.form.user-picker.placeholder.email-only': 'z. B. maria@company.com',
  'fabric.elements.share.form.user-picker.placeholder.jira': 'z. B. Maria, Team Orange, maria@company.com',
  'fabric.elements.share.form.user-picker.validation.required':
    'Wählen Sie mindestens einen Benutzer, eine Gruppe, ein Team oder eine E-Mail-Adresse aus.',
  'fabric.elements.share.form.user-picker.validation.required-message.confluence':
    'Wählen Sie mindestens eine Person, ein Team, eine Gruppe oder eine E-Mail-Adresse aus.',
  'fabric.elements.share.form.user-picker.validation.required-message.email-disabled-confluence':
    'Wählen Sie mindestens eine Person, ein Team oder eine Gruppe aus.',
  'fabric.elements.share.form.user-picker.validation.required-message.email-disabled-jira':
    'Wählen Sie mindestens eine Person oder ein Team aus.',
  'fabric.elements.share.form.user-picker.validation.required-message.email-only':
    'Wählen Sie mindestens eine E-Mail-Adresse aus.',
  'fabric.elements.share.form.user-picker.validation.required-message.jira':
    'Wählen Sie mindestens eine Person, ein Team oder eine E-Mail-Adresse aus.',
  'fabric.elements.share.in.integration.button': 'In {integrationName} teilen',
  'fabric.elements.share.invite.to.confluence': 'Zu Confluence einladen',
  'fabric.elements.share.invite.to.jira': 'Zu Jira einladen',
  'fabric.elements.share.main.tab.text.confluence': 'Seite teilen',
  'fabric.elements.share.main.tab.text.jira': 'Vorgang teilen',
  'fabric.elements.share.menu.email.label': 'E-Mail',
  'fabric.elements.share.menu.slack.label': 'Slack',
  'fabric.elements.share.popup.label': 'Teilen',
  'fabric.elements.share.success.message':
    '{object, select,blogpost {Blogpost geteilt}board {Board geteilt}calendar {Kalender geteilt}draft {Entwurf geteilt}filter {Filter geteilt}issue {Vorgang geteilt}summary {Zusammenfassung geteilt}list {Liste geteilt}timeline {Zeitleiste geteilt}form {Formular geteilt}media {Medien geteilt}page {Seite geteilt}project {Projekt geteilt}pullrequest {Pull-Anfrage geteilt}question {Frage geteilt}report {Bericht geteilt}repository {Repository geteilt}request {Anfrage geteilt}roadmap {Zeitleiste geteilt}site {Site geteilt}space {Bereich geteilt}other {Link geteilt}}',
  'fabric.elements.share.to.integration.button': 'Mit {integrationName} teilen',
  'fabric.elements.share.trigger.button.icon.label': 'Teilen-Symbol',
  'fabric.elements.share.trigger.button.invite.text': 'Einladen',
  'fabric.elements.share.trigger.button.text': 'Teilen',
  'fabric.elements.share.trigger.button.tooltip.text': 'Teilen',
};
