import React, { useState, useEffect, type PropsWithChildren, type FC } from 'react';
import { styled } from '@compiled/react';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import {
	withConnectHost,
	getConnectHost,
} from '@atlassian/jira-connect-utils/src/common/utils/ecosystem-connect-host.tsx';
import type { ACJSHost } from '@atlassian/jira-connect/src/index.tsx';
import { useIssueKey, useIssueId } from '@atlassian/jira-issue-context-service/src/main.tsx';
import ForgeBackgroundScripts from '@atlassian/jira-issue-ecosystem-forge/src/ui/background-scripts/index.tsx';
import useConnectBackgroundScripts from '@atlassian/jira-issue-non-critical-gira-service/src/controllers/use-connect-background-scripts/index.tsx';
import EcosystemModuleWithRefresh, {
	EcosystemModuleWithoutRefresh,
} from './ecosystem-module-view/index.tsx';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BackgroundScriptsContainer = styled.div({
	display: 'none',
});

export const EcosystemBackgroundScriptsView = () => {
	const issueKey = useIssueKey();
	const [{ value: scripts, isReady }] = useConnectBackgroundScripts(issueKey);

	const [connectHost, setConnectHost] = useState<ACJSHost>(getConnectHost());

	useEffect(() => {
		if (!connectHost) {
			log.safeInfoWithoutCustomerData(
				'issue.ecosystem.background.scripts',
				'Delayed initialization of ecosystem background scripts',
			);
			withConnectHost((host) => setConnectHost(host));
		}
	}, [connectHost]);

	return connectHost && isReady ? (
		<BackgroundScriptsContainer>
			{scripts.map(({ appKey, moduleKey, options, shouldReloadOnRefresh }) => {
				const EcosystemModule = shouldReloadOnRefresh
					? EcosystemModuleWithRefresh
					: EcosystemModuleWithoutRefresh;
				const key = `${appKey}-${moduleKey}`;
				return (
					<EcosystemModule
						key={key}
						appKey={appKey}
						moduleKey={moduleKey}
						options={options}
						appName={key}
						location="issueViewBackgroundScript"
					/>
				);
			})}
		</BackgroundScriptsContainer>
	) : null;
};

const PreventUnstableRender: FC<PropsWithChildren> = ({ children }) => {
	// TODO: reconsider placement of this check. It might be better to move it a level above to IssueView.isComplete
	const issueId = useIssueId(); // note: issueKey is stable during transition, but issueId is not
	// hold rendering until issueKey is set
	return issueId ? <>{children}</> : null;
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => (
	<PreventUnstableRender>
		<ForgeBackgroundScripts />
		<EcosystemBackgroundScriptsView />
	</PreventUnstableRender>
);
