// Bento has a high priority, almost like a modal
export const KEYBOARD_SHORTCUT_PRIORITY = 999;

export const ISSUE_ACTION_SHORTCUT_ASSIGN_ISSUE = 'a' as const;
export const ISSUE_ACTION_SHORTCUT_CHANGE_STATUS = 'd' as const;
export const ISSUE_ACTION_SHORTCUT_ASSIGN_TO_ME = 'i' as const;
export const ISSUE_ACTION_SHORTCUT_EDIT_LABELS = 'l' as const;
export const ISSUE_ACTION_SHORTCUT_ADD_COMMENT = 'm' as const;
export const ISSUE_ACTION_SHORTCUT_ADD_TIME_TRACKING = 'q' as const;
// Registering uppercase "S" with <Shortcuts> which is triggered by shift+s
export const ISSUE_ACTION_SHORTCUT_SHARE = 'S' as const;
export const ISSUE_ACTION_SHORTCUT_LEFT_PANEL = 'arrowleft' as const;
export const ISSUE_ACTION_SHORTCUT_RIGHT_PANEL = 'arrowright' as const;
export const ISSUE_ACTION_SHORTCUT_SCROLL_UP = 'arrowup' as const;
export const ISSUE_ACTION_SHORTCUT_SCROLL_DOWN = 'arrowdown' as const;

export const MENU_SHORTCUT_DIALOG_OPEN = '.' as const;

export const BOARD_SHORTCUT_NEXT_ISSUE = 'j' as const;
export const BOARD_SHORTCUT_PREVIOUS_ISSUE = 'k' as const;
export const BOARD_SHORTCUT_NEXT_ACTIVITY_ISSUE = 'n' as const;
export const BOARD_SHORTCUT_PREVIOUS_ACTIVITY_ISSUE = 'p' as const;

// Component trigger ID's
export const SHORTCUT_TRIGGER_ID_CHANGE_STATUS = 'statusDropdownTriggerCount' as const;

// Actual key combination that triggers the share shortcut key
export const SHORTCUT_TRIGGER_KEYS_SHARE = 'shift+s' as const;
