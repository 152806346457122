import head from 'lodash/head';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { createExperienceWithMetaType } from '@atlassian/jira-polaris-lib-analytics/src/common/utils/experience/main.tsx';
import { fireCompoundAnalyticsEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/analytics/index.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types.tsx';

const fieldOptionUpdateExperience = createExperienceWithMetaType(
	experience.fieldsSidebar.fieldOptionUpdate,
	'renameOption',
	(err) => `FieldOptionUpdate: ${err?.message ?? 'generic error'}`,
);

export const renameOption =
	(fieldKey: FieldKey, jiraOptionId: string, newValue: string) =>
	async (
		{ getState, setState }: StoreActionApi<State>,
		{ onFieldUpdateFailed, projectId, createAnalyticsEvent, fieldRemote, issueTypeIds }: Props,
	) => {
		const state = getState();
		const previousOptions = state.fields[fieldKey].options;

		const newOptions = previousOptions?.map((option) => {
			if (option.jiraOptionId === jiraOptionId) {
				return {
					...option,
					value: newValue,
				};
			}
			return option;
		});

		const fields = {
			...state.fields,
			[fieldKey]: {
				...state.fields[fieldKey],
				options: newOptions,
			},
		};

		setState({ fields });

		const issueTypeId = head(issueTypeIds);

		if (projectId !== undefined && issueTypeId !== undefined) {
			fieldOptionUpdateExperience.start();

			fieldRemote
				.renameFieldOption({
					fieldKey,
					newTitle: newValue,
					optionId: +jiraOptionId,
					issueTypeId,
				})
				.then(() => {
					fieldOptionUpdateExperience.success();

					fireCompoundAnalyticsEvent.FieldConfig.optionLabelChanged(createAnalyticsEvent({}), {
						issueFieldKey: fieldKey,
						issueFieldType: fields[fieldKey].type,
						fieldValueId: jiraOptionId,
					});
				})
				.catch((error: Error) => {
					fieldOptionUpdateExperience.failure(error);

					onFieldUpdateFailed(error);
				});
		}
	};
