import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/catch';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { trackOrLogClientError } from '@atlassian/jira-issue-view-common-utils/src/errors/index.tsx';
import { EDIT_COMMENT_UPDATE } from '@atlassian/jira-issue-view-store/src/actions/comment-actions.tsx';
import { saveDraftRequest } from '@atlassian/jira-issue-view-store/src/drafts/draft-actions.tsx';
import { createCommentDraftKey } from '@atlassian/jira-issue-view-store/src/drafts/draft-utils.tsx';
import { commentBodyHtmlSelector } from '@atlassian/jira-issue-view-store/src/selectors/comment-selector.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const processEditForSaving = (action: any, state: State) => {
	const {
		payload: { id, value },
	} = action;
	const commentContent = commentBodyHtmlSelector(id)(state);
	// @ts-expect-error - TS2322 - Type 'string | null | undefined' is not assignable to type 'string'.
	const commentContentNotNull: string = commentContent;

	return Observable.of(saveDraftRequest(createCommentDraftKey(id), value, commentContentNotNull));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const errorHandler = (error: any) => {
	trackOrLogClientError('issue.comment-save-draft', error.message, error);
	return Observable.empty<never>();
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<any>, store: MiddlewareAPI<State>) =>
	action$
		.ofType(EDIT_COMMENT_UPDATE)
		.switchMap((action) => processEditForSaving(action, store.getState()))
		.catch((error) => errorHandler(error));
