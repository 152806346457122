import React, { useEffect, useContext, useCallback } from 'react';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIssueId, useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useSummaryField } from '@atlassian/jira-issue-field-summary-common/src/services/index.tsx';
import { useIssueAbsoluteUrl } from '@atlassian/jira-issue-hooks/src/services/use-issue-absolute-url/index.tsx';
import { useIssueAri } from '@atlassian/jira-issue-hooks/src/services/use-issue-ari/index.tsx';
import { useSubProductId } from '@atlassian/jira-issue-hooks/src/services/use-subproduct-id/index.tsx';
import { useShareKeyboardShortcutsStore } from '@atlassian/jira-issue-view-keyboard-shortcuts/src/services/store.tsx';
import { HeaderPopupsStateContext } from '@atlassian/jira-issue-view-sticky-header-container/src/popup-context/index.tsx';
import { usePreviousWithInitial } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import { integrationTypes } from '@atlassian/jira-share-button/src/constants.tsx';
import ShareButtonNonLazy from '@atlassian/jira-share-button/src/ui/index.tsx';
import { useShareIntegrations } from '@atlassian/jira-share-integrations/src/index.tsx';

export const ShareView = () => {
	const issueKey = useIssueKey();
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const issueId = ff('relay-migration-issue-fields-summary_eog1v') ? useIssueId() : undefined;
	const [{ value: summary }] = useSummaryField({ issueKey, issueId });
	const issueAri = useIssueAri();
	const { shareIntegrations } = useShareIntegrations();
	const issueUrl = useIssueAbsoluteUrl();
	const subProductId = useSubProductId();
	const { updateIsOpened } = useContext(HeaderPopupsStateContext);

	const [shareDropdownTriggerCount, { resetComponent }] = useShareKeyboardShortcutsStore();

	const prevShareShortcutTriggerCount = usePreviousWithInitial(shareDropdownTriggerCount);

	// clean up - reset trigger when we unmount
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => () => resetComponent('shareDropdownTriggerCount'), []);

	const onDialogClose = useCallback(() => {
		updateIsOpened(false);
	}, [updateIsOpened]);

	const onTriggerButtonClick = useCallback(() => {
		updateIsOpened(true);
	}, [updateIsOpened]);

	return (
		<ErrorBoundary id="share.button" packageName="share-button">
			<ShareButtonNonLazy
				integrationType={integrationTypes.BENTO_ISSUE}
				integrationMode={shareIntegrations && shareIntegrations.length > 0 ? 'tabs' : 'off'}
				objectAri={issueAri}
				productId="jira"
				shareContentType="issue"
				shareIntegrations={shareIntegrations}
				shareLink={issueUrl}
				shareTitle={summary ?? ''}
				shouldAutoDismissFlag={false}
				// @ts-expect-error - TS2322 - Type 'SubProductId' is not assignable to type 'ProductId'.
				subProductId={subProductId}
				triggerButtonStyle="icon-only"
				shareShortcutTriggerCount={shareDropdownTriggerCount}
				prevShareShortcutTriggerCount={prevShareShortcutTriggerCount}
				onDialogClose={onDialogClose}
				onTriggerButtonClick={onTriggerButtonClick}
			/>
		</ErrorBoundary>
	);
};

export default ShareView;
