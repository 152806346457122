import React, { useMemo } from 'react';
import AsyncDevDetailsDialogLoader from '@atlassian/jira-development-details-loader/src/ui/async.tsx';
import DevDetailsDialogLoader from '@atlassian/jira-development-details-loader/src/ui/index.tsx';
import AsyncDevPanelApp from '@atlassian/jira-development-summary-development-field/src/ui/async.tsx';
import DevPanelApp from '@atlassian/jira-development-summary-development-field/src/ui/index.tsx';
import AsyncReleasesPanelApp from '@atlassian/jira-development-summary-releases-field/src/ui/async.tsx';
import ReleasesPanelApp from '@atlassian/jira-development-summary-releases-field/src/ui/index.tsx';
import AsyncSecurityField from '@atlassian/jira-development-summary-security-field/src/ui/async.tsx';
import SecurityField from '@atlassian/jira-development-summary-security-field/src/ui/main.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	useAnalyticsSource,
	useHasSoftwareAccess,
	useIssueKey,
	useProjectKey,
} from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import { useSummaryField } from '@atlassian/jira-issue-field-summary-common/src/services/index.tsx';
import { createAri } from '@atlassian/jira-platform-ari/src/index.tsx';
import { STATUS_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import {
	useIsSimplifiedProject,
	useProjectId,
	useProjectType,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import JiraRelayEnvironmentProvider from '@atlassian/jira-relay-environment-provider/src/index.tsx';
import { isDevopsFeatureDisabledInFedRamp } from '@atlassian/jira-software-devops-fedramp-utils/src/index.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useIsAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-admin/index.tsx';
import { useIsSiteAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-site-admin/index.tsx';
import type { Props } from './types.tsx';

function AllDevInfoPanel({ issueId, issueAri }: Props) {
	const user = useAccountId();
	const issueKey = useIssueKey();
	const analyticsSource = useAnalyticsSource();
	const projectKey = useProjectKey();
	const projectType = useProjectType(projectKey);
	const projectId = useProjectId(projectKey);
	const cloudId = useCloudId();
	const isSimplifiedProject = useIsSimplifiedProject(projectKey);
	const [{ value: summary }] = useSummaryField({ issueKey, issueId });
	const [statusFieldValue] = useFieldValue({
		issueKey,
		fieldKey: STATUS_TYPE,
	});
	const issueStatusCategory = statusFieldValue?.statusCategory.id || null;
	const hasSoftwareAccess = useHasSoftwareAccess();
	const isJiraAdmin = useIsAdmin();
	const isSiteAdmin = useIsSiteAdmin();

	const issueContext = useMemo(
		() => ({
			issueKey,
			issueId: Number(issueId),
			issueStatusCategory,
			projectAri: createAri({
				resourceId: projectId || '',
				resourceOwner: 'jira',
				resourceType: 'project',
				cloudId,
			}),
			projectId: Number(projectId),
			projectType: projectType || '',
			isSimplifiedProject,
		}),
		[issueKey, issueId, issueStatusCategory, projectId, projectType, isSimplifiedProject, cloudId],
	);

	const devPanelAppProps = {
		hasSoftwareAccess,
		issueId: issueId.toString(),
		issueAri,
		issueKey,
		issueStatusCategory: issueStatusCategory || '',
		projectId: projectId || '',
		projectType: projectType || '',
		nextGenProject: isSimplifiedProject,
		analyticsSource: analyticsSource || '',
		projectAri: createAri({
			resourceId: projectId || '',
			resourceOwner: 'jira',
			resourceType: 'project',
			cloudId,
		}),
		isJiraAdmin,
	};

	// ProjectContext is still loading
	if (projectId == null) return null;

	// async import exists in a different file because if it points to the same file path, webpack will optimise it away
	return fg('perf-push-big-components-sync-devpanel-async-sub') ? (
		<ErrorBoundary id="devInfoPanelApp" packageName="jiraDevelopmentDevInfoPanelApp">
			<JiraRelayEnvironmentProvider>
				<AsyncDevPanelApp {...devPanelAppProps} />
				{!isDevopsFeatureDisabledInFedRamp() && (
					<AsyncReleasesPanelApp
						issueContext={issueContext}
						hasSoftwareAccess={hasSoftwareAccess}
						analyticsSource={analyticsSource || ''}
						summary={summary || ''}
						user={user}
						isJiraAdmin={isJiraAdmin}
						isSiteAdmin={isSiteAdmin}
						cloudAri={cloudId}
					/>
				)}
				{!isDevopsFeatureDisabledInFedRamp() && fg('isotopes-security-page-in-jira-enabled-v2') && (
					<AsyncSecurityField issueAri={issueAri} />
				)}
				<AsyncDevDetailsDialogLoader scopeId={`devInfoPanelApp${issueContext.issueKey}`} />
			</JiraRelayEnvironmentProvider>
		</ErrorBoundary>
	) : (
		<ErrorBoundary id="devInfoPanelApp" packageName="jiraDevelopmentDevInfoPanelApp">
			<JiraRelayEnvironmentProvider>
				<DevPanelApp {...devPanelAppProps} />
				{!isDevopsFeatureDisabledInFedRamp() && (
					<ReleasesPanelApp
						issueContext={issueContext}
						hasSoftwareAccess={hasSoftwareAccess}
						analyticsSource={analyticsSource || ''}
						summary={summary || ''}
						user={user}
						isJiraAdmin={isJiraAdmin}
						isSiteAdmin={isSiteAdmin}
						cloudAri={cloudId}
					/>
				)}
				{!isDevopsFeatureDisabledInFedRamp() && fg('isotopes-security-page-in-jira-enabled-v2') && (
					<SecurityField issueAri={issueAri} />
				)}
				<DevDetailsDialogLoader scopeId={`devInfoPanelApp${issueContext.issueKey}`} />
			</JiraRelayEnvironmentProvider>
		</ErrorBoundary>
	);
}

export default AllDevInfoPanel;
