import React from 'react';
import { styled } from '@compiled/react';
import { ModalHeader } from '@atlaskit/modal-dialog';

export function DetailsHeaderPlaceholder() {
	return (
		<ModalHeader>
			<DetailsHeaderPlaceholderContent />
		</ModalHeader>
	);
}

export default DetailsHeaderPlaceholder;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DetailsHeaderPlaceholderContent = styled.div({
	height: '73px',
});
