import find from 'lodash/find';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { RemoteIssue } from '@atlassian/jira-polaris-remote-issue/src/controllers/crud/types.tsx';
import { getMetaFromJiraSearchIssue } from '@atlassian/jira-polaris-remote-issue/src/controllers/util/index.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { getFieldKeys } from '../../selectors/fields.tsx';
import { createGetIdSelector, createGetKeySelector } from '../../selectors/properties/index.tsx';
import type { State, Props } from '../../types.tsx';
import { generateLocalExternalIssueId } from '../../utils/local-id.tsx';

const createStateWithExternLinkedIssue = (
	state: State,
	props: Props,
	localIssueId: LocalIssueId,
	issueResponse: RemoteIssue | null,
	linkedIssueResponse: RemoteIssue,
	isDeliveryIssue?: boolean,
): State => {
	const getIdSelector = createGetIdSelector(localIssueId);
	// @ts-expect-error - TS2554 - Expected 1 arguments, but got 2.
	const baseIssueId = getIdSelector(state, props);
	const externalIssueId = generateLocalExternalIssueId();

	const issueLink = find(
		linkedIssueResponse.fields.issuelinks,
		(link) =>
			link?.outwardIssue?.id === baseIssueId.toString() ||
			link?.inwardIssue?.id === baseIssueId.toString(),
	);
	const issueLinkId = issueLink && issueLink.id;

	const newState: State = {
		...state,
		lastUpdatedIssueIds: [localIssueId],
	};

	if (isDeliveryIssue === true) {
		return {
			...newState,
			externalIssueData: {
				...state.externalIssueData,
				[externalIssueId]: {
					issueId: +linkedIssueResponse.id,
					issueKey: linkedIssueResponse.key,
					projectId: linkedIssueResponse.fields.project.id,
					summary: linkedIssueResponse.fields.summary,
					status: linkedIssueResponse.fields.status,
					issueType: linkedIssueResponse.fields.issuetype,
					priority: linkedIssueResponse.fields.priority,
					issueLinkId,
					childIssues: [],
					isDeliveryIssue,
				},
			},
			properties: {
				...state.properties,
				linkedDeliveryIssues: {
					...state.properties.linkedDeliveryIssues,
					[localIssueId]: [
						...(state.properties.linkedDeliveryIssues[localIssueId] || []),
						externalIssueId,
					],
				},
			},
		};
	}

	if (issueResponse) {
		return {
			...newState,
			properties: {
				...state.properties,
				issueMetadata: {
					...state.properties.issueMetadata,
					[localIssueId]: getMetaFromJiraSearchIssue(
						issueResponse,
						props.polarisIssueLinkType,
						props.hiddenIssueLinkTypes,
					),
				},
			},
		};
	}

	return state;
};

export const linkIssue =
	(issueId: LocalIssueId, jiraIssueId: string) =>
	({ getState, setState }: StoreActionApi<State>, props: Props) =>
		Promise.all([
			props.issuesRemote.fetchIssue({
				issueIdOrKey: createGetKeySelector(issueId)(getState()),
				fields: getFieldKeys(getState(), props),
			}),
			props.issuesRemote.fetchIssue({
				issueIdOrKey: jiraIssueId,
				fields: getFieldKeys(getState(), props),
			}),
		]).then(([issueResponse, linkedIssueResponse]) => {
			setState(
				createStateWithExternLinkedIssue(
					getState(),
					props,
					issueId,
					issueResponse,
					linkedIssueResponse,
				),
			);
		});

export const linkDeliveryIssue =
	(issueId: LocalIssueId, jiraIssueId: string) =>
	({ getState, setState }: StoreActionApi<State>, props: Props) =>
		props.issuesRemote
			.fetchIssue({
				issueIdOrKey: jiraIssueId,
				fields: getFieldKeys(getState(), props),
			})
			.then((linkedIssueResponse) => {
				setState(
					createStateWithExternLinkedIssue(
						getState(),
						props,
						issueId,
						null,
						linkedIssueResponse,
						true,
					),
				);
			});
