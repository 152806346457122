export const PREFETCH_ISSUES = 'PREFETCH_ISSUES' as const;

export type PrefetchIssuesAction = {
	type: typeof PREFETCH_ISSUES;
	payload: {
		issueKeys: string[];
		issueIds: string[];
	};
};

export const prefetchIssues = ({
	issueKeys,
	issueIds,
}: {
	issueKeys?: string[];
	issueIds?: string[];
}): PrefetchIssuesAction => ({
	type: PREFETCH_ISSUES,
	payload: {
		issueKeys: issueKeys || [],
		issueIds: issueIds || [],
	},
});
