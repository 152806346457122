import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	generating: {
		id: 'canned-comments.ui.ai-comments.ai-generated-comment.generating',
		defaultMessage: 'Generating',
		description: 'Status message displayed while Atlassian Intelligence is generating content',
	},
	insert: {
		id: 'canned-comments.ui.ai-comments.ai-generated-comment.insert',
		defaultMessage: 'Insert',
		description:
			'Text for button the user can click to insert the AI-generated comment into the comment box on the issue view.',
	},
	discard: {
		id: 'canned-comments.ui.ai-comments.ai-generated-comment.discard',
		defaultMessage: 'Discard',
		description:
			'Text for button the user can click to discard the AI-generated comment on the issue view.',
	},
	cancel: {
		id: 'canned-comments.ui.ai-comments.ai-generated-comment.cancel',
		defaultMessage: 'Cancel',
		description:
			'Text for button the user can click to cancel while Atlassian Intelligence is generating a comment on the issue view.',
	},
});
