import React from 'react';
import { graphql, useFragment } from 'react-relay';
import type { Area } from '@atlassian/jira-issue-view-common-types/src/connect-field-type.tsx';
import { IssueViewApproversField } from '@atlassian/jira-issue-view-layout-approvers-field/src/ui/index.tsx';
import { IssueViewPeopleField } from '@atlassian/jira-issue-view-layout-people-field/src/ui/index.tsx';
import {
	PEOPLE_CF_TYPE,
	APPROVERS_LIST_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { ui_issueViewLayoutPeople_IssueViewPeople$key } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutPeople_IssueViewPeople.graphql';

export interface IssueViewPeopleProps {
	area?: Area;
	fragmentKey: ui_issueViewLayoutPeople_IssueViewPeople$key;
}

export const IssueViewPeople = ({ area, fragmentKey }: IssueViewPeopleProps) => {
	const data = useFragment<ui_issueViewLayoutPeople_IssueViewPeople$key>(
		graphql`
			fragment ui_issueViewLayoutPeople_IssueViewPeople on JiraPeopleField
			@argumentDefinitions(
				issueViewRelayPeopleFieldFlag: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/relay-migration-issue-fields-people.relayprovider"
				}
				issueViewRelayApproversFieldFlag: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/relay-migration-issue-fields-approvers.relayprovider"
				}
			) {
				...ui_issueViewLayoutPeopleField_IssueViewPeopleField
					@include(if: $issueViewRelayPeopleFieldFlag)
				...ui_issueViewLayoutApproversField_IssueViewApproversField
					@include(if: $issueViewRelayApproversFieldFlag)
				type
			}
		`,
		fragmentKey,
	);

	switch (data.type) {
		case PEOPLE_CF_TYPE:
			return <IssueViewPeopleField area={area} fragmentKey={data} />;
		case APPROVERS_LIST_CF_TYPE:
			return <IssueViewApproversField area={area} fragmentKey={data} />;
		default:
			return null;
	}
};
