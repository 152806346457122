import React, { useLayoutEffect, useMemo, useRef } from 'react';
import { type Direction, ExitingPersistence, FadeIn } from '@atlaskit/motion';
import { Popper, type Placement } from '@atlaskit/popper'; // ignore-for-ENGHEALTH-17759
import { layers } from '@atlaskit/theme/constants';
import { visuallyHiddenStyles } from '@atlassian/jira-accessibility/src/common/ui/screenreader-text/index.tsx';
import PopupContent from './popup-content/index.tsx';
import type { Props } from './types.tsx';

/**
 * Converts a Popper placement to it's general direction.
 *
 * @param position - Popper Placement value, e.g. 'top-start'
 * @returns Popper Direction, e.g. 'top'
 */
const getDirectionFromPlacement = (placement: Placement): Direction =>
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	placement.split('-')[0] as Direction;

// Inverts motion direction
const invertedDirection = {
	top: 'bottom',
	bottom: 'top',
	left: 'right',
	right: 'left',
} as const;

const ValidationPopper = ({
	id,
	isVisuallyHidden,
	message,
	onFocus,
	onBlur,
	placement,
	fallbackPlacements,
	referenceElement,
}: Props) => {
	const updateRef = useRef<Function>();

	const modifiers = useMemo(
		() => [
			{
				name: 'flip',
				options: {
					fallbackPlacements,
				},
			},
		],
		[fallbackPlacements],
	);

	useLayoutEffect(() => {
		// Since we are applying visually hidden styles instead of unmounting the popup for accessibility reasons,
		// there will be cases where the popup placement is calculated while it's visually hidden (e.g. when a
		// validation occurs on blur). While visually hidden, popup size is 1px x 1px, and this can make Popper
		// think that the popup fits in a placement where it doesn't actually fit, and end up overflowing the
		// viewport. To prevent this from happening, we ask Popper to recompute popup position when it becomes visible.
		!isVisuallyHidden && updateRef.current?.();
	}, [isVisuallyHidden]);

	return (
		<ExitingPersistence appear>
			{message && (
				<Popper
					placement={placement}
					referenceElement={referenceElement}
					strategy="absolute"
					modifiers={modifiers}
				>
					{({ ref, style, placement: finalPlacement, update }) => {
						updateRef.current = update;

						return (
							<FadeIn
								entranceDirection={invertedDirection[getDirectionFromPlacement(finalPlacement)]}
							>
								{({ className }) => (
									<div
										ref={ref}
										// eslint-disable-next-line jira/react/no-style-attribute
										style={{
											// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
											...style,
											// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
											...(isVisuallyHidden && visuallyHiddenStyles),
											zIndex: layers.layer(),
										}}
									>
										<PopupContent
											{...message}
											// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
											className={className}
											id={id}
											onFocus={onFocus}
											onBlur={onBlur}
											data-testid="issue-field-validation-popup.ui.validation-popper.popup-content"
											testId={`issue-field-validation-popup.ui.validation-popper.popup-content${
												isVisuallyHidden ? '-hidden' : ''
											}`}
										/>
									</div>
								)}
							</FadeIn>
						);
					}}
				</Popper>
			)}
		</ExitingPersistence>
	);
};

export default ValidationPopper;
