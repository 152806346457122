// eslint-disable-next-line jira/restricted/react-component-props
import React, { Component, type ElementRef, type ReactElement, type ComponentProps } from 'react';
import { CreatableSelect } from '@atlaskit/select';
import Error from '@atlassian/jira-common-components-error-state/src/index.tsx';
import { defaultSelectStyles } from '@atlassian/jira-issue-internal-field-select/src/common/select-inline-edit/select-field/styled.tsx';
import { TenantContextSubscriber } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import IssueOptionService from '../services/options/main.tsx';
import type {
	IssueOptionType,
	LoadOptions,
	State as ServiceState,
} from '../services/options/types.tsx';
import { IssueOption, SingleValue, CreateIssueOption } from './issue-cards/index.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

// eslint-disable-next-line jira/react/no-class-components
export class IssuePicker extends Component<Props> {
	static defaultProps = {
		initialValue: '',
	};

	onBlur = (loadOptions: LoadOptions) => {
		loadOptions('');
	};

	onChange = (
		issueOption: IssueOptionType,
		{
			action,
		}: {
			action: string;
		},
		loadOptions: LoadOptions,
	) => {
		switch (action) {
			case 'select-option': {
				const { issueId, issueKey, summary, imageUrl } = issueOption;
				this.props.onChange &&
					this.props.onChange({
						issueId,
						issueKey,
						issueSummary: summary,
						issueTypeIconUrl: imageUrl,
					});
				loadOptions('');
				break;
			}
			case 'clear': {
				this.props.onClear && this.props.onClear();
				break;
			}
			default:
				break;
		}
	};

	onCreate = (inputValue: string) => {
		this.props.onCreate && this.props.onCreate(inputValue);
	};

	onInputChange =
		(loadOptions: LoadOptions) =>
		(
			input: string,
			{
				action,
			}: {
				action: string;
			},
		) => {
			if (action === 'input-change') {
				loadOptions(input);
			}
		};

	onSetSelectRef = (ref: ElementRef<typeof CreatableSelect>) => {
		this.akSelect = ref;
	};

	formatOptionLabel = (
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		option: any,
		{
			context,
		}: {
			context: string;
		},
	) => {
		const isNewOption = option.__isNew__;
		if (isNewOption) {
			const label = this.props.intl.formatMessage(messages.createIssue, {
				value: option.value,
			});
			return <CreateIssueOption label={label} />;
		}

		if (context === 'menu') {
			return (
				<IssueOption
					imageUrl={option.imageUrl}
					issueKey={option.issueKey}
					summary={option.summary}
				/>
			);
		}
		return option.label;
	};

	akSelect: ElementRef<typeof CreatableSelect> | undefined;

	focus() {
		this.akSelect?.focus();
	}

	reset() {
		// Unfortunately, this is what we found in order to clear the select value.
		this.akSelect?.select?.clearValue();
		// The reason for doing a blur then focus is because AK button doesn't get the focus on clicking.
		this.akSelect?.blur();
		this.akSelect?.focus();
	}

	filterOption = () => true;

	renderNoOptionsMessage = (
		error: ServiceState['error'],
	): ReactElement<ComponentProps<typeof Error>> | undefined => {
		if (!error) {
			return undefined;
		}

		const { intl } = this.props;

		return (
			<Error
				description={intl.formatMessage(messages.defaultFailedLoadingIssues)}
				imageWidth={56}
				isSubtle
			/>
		);
	};

	render() {
		const { initialValue, issueKey, projectId, jql, intl, label } = this.props;
		return (
			<TenantContextSubscriber>
				{({ tenantContext: { baseUrl } }) => (
					<IssueOptionService
						labels={{
							recentIssues: intl.formatMessage(messages.defaultRecentIssues),
							matchingIssues: intl.formatMessage(messages.defaultMatchingIssues),
						}}
						baseUrl={baseUrl}
						issueKey={issueKey}
						projectId={projectId}
						jql={jql}
						initialValue={initialValue}
					>
						{({ loadOptions, options, isLoading, error }) => (
							<CreatableSelect
								ref={this.onSetSelectRef}
								styles={defaultSelectStyles}
								isLoading={isLoading}
								formatOptionLabel={this.formatOptionLabel}
								options={options}
								defaultInputValue={initialValue}
								autoFocus
								allowCreateWhileLoading
								defaultOptions
								onCreateOption={this.onCreate}
								placeholder={intl.formatMessage(messages.defaultPlaceholder)}
								components={{ SingleValue }}
								// @ts-expect-error - TS7006 - Parameter 'issueOption' implicitly has an 'any' type. | TS7006 - Parameter 'actionObject' implicitly has an 'any' type.
								onChange={(issueOption: IssueOptionType, actionObject) =>
									this.onChange(issueOption, actionObject, loadOptions)
								}
								onInputChange={this.onInputChange(loadOptions)}
								onBlur={() => this.onBlur(loadOptions)}
								isClearable
								filterOption={this.filterOption}
								noOptionsMessage={() => this.renderNoOptionsMessage(error)}
								openMenuOnFocus
								aria-label={label}
							/>
						)}
					</IssueOptionService>
				)}
			</TenantContextSubscriber>
		);
	}
}

export default IssuePicker;
