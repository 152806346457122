import type { Worklogs } from '@atlassian/jira-issue-view-common-types/src/worklog-type.tsx';
import type { IssueKey, BaseUrl } from '@atlassian/jira-shared-types/src/general.tsx';
import { transformResponse } from '../../common/transform-worklogs/index.tsx';
import fetchWorklogs from '../../rest/fetch-worklogs.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	baseUrl: BaseUrl,
	issueKey: IssueKey,
	startAt: number,
	maxResults: number,
	reverseSort: boolean,
): Promise<Worklogs> =>
	fetchWorklogs(baseUrl, issueKey, startAt, maxResults, reverseSort).then((result) =>
		transformResponse(result),
	);
