import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import omit from 'lodash/omit';
import reduce from 'lodash/reduce';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { SelectableId, View } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import type { Action, StoreActionApi } from '@atlassian/react-sweet-state';
import type { State, Props } from '../../types.tsx';
import { updateViewState } from '../utils/state/index.tsx';
import { currentViewFilter } from '../utils/views/index.tsx';

const toggle = ({ selectedIssues }: View, id: LocalIssueId) => {
	if (selectedIssues[id]) {
		return omit(selectedIssues, [id]);
	}
	return { ...selectedIssues, [id]: true };
};

export const setSelection =
	(ids: LocalIssueId[]): Action<State, Props> =>
	({ getState, setState }: StoreActionApi<State>, { currentViewSlug }) => {
		const { changedView, viewSets } = updateViewState(
			getState().viewSets,
			currentViewFilter(currentViewSlug),
			(view: View) => ({
				...view,
				selectedIssues: mapValues(
					keyBy(ids, (id) => id),
					() => true,
				),
			}),
		);
		if (changedView) {
			setState({ viewSets });
		}
	};

export const toggleSelection =
	(id: LocalIssueId): Action<State, Props> =>
	({ getState, setState }: StoreActionApi<State>, { currentViewSlug }) => {
		const { changedView, viewSets } = updateViewState(
			getState().viewSets,
			currentViewFilter(currentViewSlug),
			(view: View) => ({
				...view,
				selectedIssues: toggle(view, id),
			}),
		);
		if (changedView) {
			setState({ viewSets });
		}
	};

export const clearSelection =
	(clearIds: SelectableId[]): Action<State, Props> =>
	({ getState, setState }: StoreActionApi<State>, { currentViewSlug }) => {
		const { changedView, viewSets } = updateViewState(
			getState().viewSets,
			currentViewFilter(currentViewSlug),
			(view: View) => ({
				...view,
				selectedIssues: reduce(
					Object.keys(view.selectedIssues),
					(result, id) => (clearIds.includes(id) ? result : { ...result, [id]: true }),
					{},
				),
			}),
		);
		if (changedView) {
			setState({ viewSets });
		}
	};
