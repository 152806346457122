import { fetchQuery, graphql } from 'react-relay';
import type { IEnvironment } from 'relay-runtime';
import type {
	JiraProjectType,
	aiOptinDataQuery as aiOptInDataQueryType,
} from '@atlassian/jira-relay/src/__generated__/aiOptinDataQuery.graphql';
import type { CloudId } from '@atlassian/jira-shared-types/src/general.tsx';

export const JiraProjectTypeEnum = {
	SOFTWARE: 'SOFTWARE',
	SERVICE_DESK: 'SERVICE_DESK',
	BUSINESS: 'BUSINESS',
	PRODUCT_DISCOVERY: 'PRODUCT_DISCOVERY',
} as const;

export type JiraProjectTypeEnum = (typeof JiraProjectTypeEnum)[keyof typeof JiraProjectTypeEnum];

export const fetchAiOptInData = async (
	projectType: JiraProjectType | null,
	cloudId: CloudId,
	environment: IEnvironment,
): Promise<boolean> => {
	if (!projectType) {
		return false;
	}

	const data = await fetchQuery<aiOptInDataQueryType>(
		environment,
		graphql`
			query aiOptinDataQuery($projectType: JiraProjectType!, $cloudId: ID!) {
				jira @optIn(to: "JiraIsIssueViewEditorAiEnabled") {
					isIssueViewEditorAiEnabled(jiraProjectType: $projectType, cloudId: $cloudId)
				}
			}
		`,
		{
			projectType,
			cloudId,
		},
	)
		.toPromise()
		.catch((err: unknown) => {
			throw err;
		});

	return data?.jira?.isIssueViewEditorAiEnabled || false;
};
