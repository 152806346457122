import HttpError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import getXsrfToken from '@atlassian/jira-platform-xsrf-token/src/index.tsx';
import type { BaseUrl, IssueId } from '@atlassian/jira-shared-types/src/general.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (baseUrl: BaseUrl, parentId: IssueId, currentIndex: number, newIndex: number) => {
	const url = `${baseUrl}/secure/MoveSubtask.jspa?id=${parentId}&currentSubTaskSequence=${currentIndex}&subTaskSequence=${newIndex}&atl_token=${
		getXsrfToken() || ''
	}`;
	return fetch(url).then((response) => {
		if (!response.ok) {
			return response.text().then((str) => {
				throw new HttpError(response.status, str);
			});
		}
		// MoveIssueLink.jspa will return 200 and redirect to /browse/<issue-key> which will return raw HTML
		// So return 'null' instead as we don't care about the response
		return null;
	});
};
