import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	nextIssue: {
		id: 'issue.shortcuts.navigatingIssues.nextIssue',
		defaultMessage: 'Next issue',
		description: 'Navigate to the next issue via keyboard shortcut',
	},
	previousIssue: {
		id: 'issue.shortcuts.navigatingIssues.previousIssue',
		defaultMessage: 'Previous issue',
		description: 'Navigate to the previous issue via keyboard shortcut',
	},
	nextActivity: {
		id: 'issue.shortcuts.navigatingIssues.nextActivity',
		defaultMessage: 'Next activity',
		description: 'Navigate to the next activity via keyboard shortcut',
	},
	previousActivity: {
		id: 'issue.shortcuts.navigatingIssues.previousActivity',
		defaultMessage: 'Previous activity',
		description: 'Navigate to the previous activity via keyboard shortcut',
	},
	editAssignee: {
		id: 'issue.shortcuts.editAssignee',
		defaultMessage: 'Edit assignee',
		description: 'Edit the assignee via keyboard shortcut',
	},
	commentOnIssue: {
		id: 'issue.shortcuts.commentOnIssue',
		defaultMessage: 'Comment on issue',
		description: 'Add comment keyboard shortcut',
	},
	editLabels: {
		id: 'issue.shortcuts.editLabels',
		defaultMessage: 'Edit labels',
		description: 'Edit the labals via keyboard shortcut',
	},
	assignToMe: {
		id: 'issue.shortcuts.assignToMe',
		defaultMessage: 'Assign to me',
		description: 'Assign to me via keyboard shortcut',
	},
	unassignFromMe: {
		id: 'issue.shortcuts.unassign',
		defaultMessage: 'Unassign',
		description: 'Unassign from me via command palette',
	},
	openCompalDialog: {
		id: 'issue.shortcuts.openCompalDialog',
		defaultMessage: 'Open command palette',
		description: 'Opens the command palette via keyboard shortcut',
	},
	openWorklogModal: {
		id: 'issue.shortcuts.openWorklogModal',
		defaultMessage: 'Open work log modal',
		description: 'Open time tracking modal via keyboard shortcut',
	},
	openShareDialog: {
		id: 'issue.shortcuts.openShareDialog',
		defaultMessage: 'Open share dialog',
		description: 'Open share dialog via keyboard shortcut',
	},
	scrollIssueUp: {
		id: 'issue.shortcuts.scrollIssueUp',
		defaultMessage: 'Scrolls up open issue',
		description: 'Scroll focused area up',
	},
	scrollIssueDown: {
		id: 'issue.shortcuts.scrollIssueDown',
		defaultMessage: 'Scrolls down open issue',
		description: 'Scroll focused area down',
	},
	switchSelectedAreaLeft: {
		id: 'issue.shortcuts.switchSelectedAreaLeft',
		defaultMessage: 'Swap to left side of open issue',
		description: 'Select the left side of the view',
	},
	switchSelectedAreaRight: {
		id: 'issue.shortcuts.switchSelectedAreaRight',
		defaultMessage: 'Swap to right side of open issue',
		description: 'Select the left side of the view',
	},
	changeStatus: {
		id: 'issue.shortcuts.change-status',
		defaultMessage: 'Change status',
		description: 'Open change status dropdown via keyboard shortcut',
	},
	addComment: {
		id: 'issue.shortcuts.add-comment',
		defaultMessage: 'Add comment',
		description: 'Add comment command to the command palette',
	},
	nextIssueIssueTermRefresh: {
		id: 'issue.shortcuts.navigatingIssues.nextIssue-issue-term-refresh',
		defaultMessage: 'Next work item',
		description: 'Navigate to the next issue via keyboard shortcut',
	},
	previousIssueIssueTermRefresh: {
		id: 'issue.shortcuts.navigatingIssues.previousIssue-issue-term-refresh',
		defaultMessage: 'Previous work item',
		description: 'Navigate to the previous issue via keyboard shortcut',
	},
	commentOnIssueIssueTermRefresh: {
		id: 'issue.shortcuts.commentOnIssue-issue-term-refresh',
		defaultMessage: 'Comment on work item',
		description: 'Add comment keyboard shortcut',
	},
	scrollIssueUpIssueTermRefresh: {
		id: 'issue.shortcuts.scrollIssueUp-issue-term-refresh',
		defaultMessage: 'Scrolls up to open work item',
		description: 'Scroll focused area up',
	},
	scrollIssueDownIssueTermRefresh: {
		id: 'issue.shortcuts.scrollIssueDown-issue-term-refresh',
		defaultMessage: 'Scrolls  down to open work item',
		description: 'Scroll focused area down',
	},
	switchSelectedAreaLeftIssueTermRefresh: {
		id: 'issue.shortcuts.switchSelectedAreaLeft-issue-term-refresh',
		defaultMessage: 'Swap to left side of open work item',
		description: 'Select the left side of the view',
	},
	switchSelectedAreaRightIssueTermRefresh: {
		id: 'issue.shortcuts.switchSelectedAreaRight-issue-term-refresh',
		defaultMessage: 'Swap to right side of open work item',
		description: 'Select the left side of the view',
	},
});
