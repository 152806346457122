export const ITEMS_PER_PAGE = 6;
export const TABLE_ROW_CLASSNAME = 'jira-issue-view-attachments-table';

export const ROW_HIGHLIGHT_CLASSNAME = 'media-table-row-highlighted';

export const LIST_VIEW_COLUMN_KEYS = {
	DATE: 'date',
	DELETE_OR_GOTO: 'deleteOrGoto',
	DOWNLOAD: 'download',
	CUSTOM_DOWNLOAD: 'customDownload',
	FILE: 'file',
	SIZE: 'size',
} as const;

// Based on the Figma doco for the attachments view
// See: https://jdog.jira-dev.com/browse/BENTO-7112
export const WIDTH_TO_HIDE_SIZE_COLUMN = 430;
export const SIZE_COLUMN_WIDTH = 66;
export const DATE_COLUMN_WIDTH = 188;
export const DOWNLOAD_COLUMN_WIDTH = 48;
export const DELETE_OR_GOTO_COLUMN_WIDTH = 36;
