export type IssueViewData = {
	requestStatus?: string;
	issueResolution?: string;
	issueKey: string;
	issueReporter?: string;
	issueSummary?: string;
	issueAssignee?: string;
	currentUser?: string;
	portalUrl?: string;
	requestUrl?: string;
};
export type IssueViewDataKeys = keyof IssueViewData;

export type ErrorMapping = {
	[key: string]: Error;
};

export const ADFNode = {
	TEXT: 'text',
	INLINE_CARD: 'inlineCard',
} as const;
export type ADFNode = (typeof ADFNode)[keyof typeof ADFNode];
