import React, { type ReactNode } from 'react';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { Loading as IssueSecurityLevelFieldReadonly } from './loading/index.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { IssueSecurityLevelFieldReadonly };

export type IssueSecurityLevelFieldBoundaryProps = {
	children: ReactNode;
	fallback: ReactNode;
	packageName: string;
};

export const IssueSecurityLevelFieldBoundary = ({
	children,
	fallback,
	packageName,
}: IssueSecurityLevelFieldBoundaryProps) => (
	<ErrorBoundary id="issue-security-level-field" packageName={packageName} render={() => fallback}>
		<Placeholder name="issue-security-level-field-boundary" fallback={fallback}>
			{children}
		</Placeholder>
	</ErrorBoundary>
);
