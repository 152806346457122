import parameters from '@atlassian/jira-relay/src/__generated__/indexOld_DesignPanelQuery$parameters';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const designPanelEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-design-panel" */ './src'),
	),
	getPreloadProps: ({
		siteAri,
		issueAri,
		cloudId,
	}: {
		siteAri: string;
		issueAri: string;
		cloudId: string;
	}) => ({
		queries: {
			designPanel: {
				options: {
					fetchPolicy: 'store-and-network' as const,
				},
				parameters,
				variables: {
					siteAri,
					issueAri,
					cloudId,
				},
			},
		},
		extraProps: {
			issueAri,
		},
	}),
});
