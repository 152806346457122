import React, { createContext, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import type { CreatePayload } from '@atlaskit/link-create';
import type { UIAnalyticsEvent } from '@atlassian/jira-product-analytics-bridge';
import {
	CONFLUENCE_ACCESS_REQUEST_KEY,
	CONFLUENCE_ACCESS_REQUEST_TTL,
} from '../../common/constants/cache-keys.tsx';
import {
	type CreateContentModalRuntimeProps,
	type CreateMenuContextType,
	LINK_CREATE_DRAFT_STATUS,
	CONTENT_TYPE,
	type CreateMenuActions,
} from '../../common/types/types.tsx';
import { isStorageKeyExpired, setSessionStorageData } from '../utils/get-cached-data.tsx';
import { ACCESS_REQUEST_CAPABILITIES } from '../utils/index.tsx';
import { contentMenuReducer, getInitialContentMenuState } from './reducer.tsx';

const defaultWhiteboardData: CreatePayload = {
	url: '',
	objectId: '',
	objectType: '',
};

const defaultPageData: CreatePayload = {
	url: '',
	objectId: '',
	objectType: '',
};

export const ContentMenuContext = createContext<CreateMenuContextType>({
	userHasConfluenceAccess: false,
	isCreateContentModalOpen: false,
	embeddedConfluenceSource: '',
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	setContentMenuState: (_value: CreateMenuActions) => {},

	accessStatus: ACCESS_REQUEST_CAPABILITIES.ACCESS_EXISTS,

	isEpModalOpen: false,
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	setIsEpModalOpen: (_value: boolean) => {},

	activePageData: defaultPageData,

	createContentRuntimeProps: {
		isOpen: true,
		contentType: CONTENT_TYPE.PAGE,
	},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	setCreateContentRuntimeProps: (_value: CreateContentModalRuntimeProps) => {},

	isEpModalLoading: false,
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	setIsEpModalLoading: (_value: boolean) => {},

	activeWhiteboardData: defaultWhiteboardData,
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	setActiveWhiteboardData: (_value: CreatePayload) => {},

	isEmbeddedWhiteboardModalOpen: false,
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	setEmbeddedWhiteboardModalOpen: (_value: boolean) => {},

	isCrossFlowModalOpen: false,
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	setIsCrossFlowModalOpen: (_value: boolean) => {},

	isDeferSpaceSelection: false,
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	setIsDeferSpaceSelection: (_value: boolean) => {},

	pageSpaceName: '',
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	setPageSpaceName: (_value?: string) => {},
});

export interface CreateConfluenceContentMenuProviderProps {
	children?: React.ReactNode;
	embeddedConfluenceSource?: string;
	hasConfluenceAccess: boolean;
	onLinkPage?: (url: string, analyticsEvent: UIAnalyticsEvent) => void;
}

export const CreateConfluenceContentMenuProvider = ({
	children,
	embeddedConfluenceSource = 'jiraIssueView',
	hasConfluenceAccess,
	onLinkPage,
}: CreateConfluenceContentMenuProviderProps) => {
	/*
	PLEASE READ BEFORE CONTRIBUTING:
	If you need to add additional state to this provider, please
	do not use a useState() variable. Please manage the state
	via the `contentMenuReducer`

	*/
	const [isEpModalOpen, setIsEpModalOpen] = useState(false);
	const [isEmbeddedWhiteboardModalOpen, setEmbeddedWhiteboardModalOpen] = useState(false);
	const [isCrossFlowModalOpen, setIsCrossFlowModalOpen] = useState(false);
	const [pageSpaceName, setPageSpaceName] = useState<string | undefined>('');
	const [activeWhiteboardData, setActiveWhiteboardData] = useState(defaultWhiteboardData);
	const [createContentRuntimeProps, setCreateContentRuntimeProps] =
		useState<CreateContentModalRuntimeProps>({
			onCreateBehavior: LINK_CREATE_DRAFT_STATUS, // Only used for Page
			isOpen: true,
			contentType: CONTENT_TYPE.PAGE,
		});
	const [isDeferSpaceSelection, setIsDeferSpaceSelection] = useState(false);
	const [isEpModalLoading, setIsEpModalLoading] = useState(false);
	const isAccessCacheExpired = useMemo(
		() => isStorageKeyExpired(CONFLUENCE_ACCESS_REQUEST_KEY),
		[],
	);
	const [state, dispatch] = useReducer(
		contentMenuReducer,
		getInitialContentMenuState(hasConfluenceAccess),
	);
	const setContentMenuState = useCallback((action: CreateMenuActions) => dispatch(action), []);

	useEffect(() => {
		if (isAccessCacheExpired && hasConfluenceAccess) {
			/*
				if the key is expired or undefined (hasn't been set yet) and the user has confluence access
				re-set the cache. If the user doesn't have access we don't set the cache here because
				the user's access status must be fetched first, which happens in <ContentMenu> which is a child
				of this provider.
			*/
			setSessionStorageData({
				key: CONFLUENCE_ACCESS_REQUEST_KEY,
				value: { accessStatus: ACCESS_REQUEST_CAPABILITIES.ACCESS_EXISTS },
				ttl: CONFLUENCE_ACCESS_REQUEST_TTL,
			});
		}
	}, [isAccessCacheExpired, hasConfluenceAccess]);

	return (
		<ContentMenuContext.Provider
			value={{
				accessStatus: state.accessStatus,
				isCreateContentModalOpen: state.isCreateContentModalOpen,
				activePageData: state.activePageData,
				createContentRuntimeProps,
				setCreateContentRuntimeProps,
				isEpModalOpen,
				setIsEpModalOpen,
				isEpModalLoading,
				setIsEpModalLoading,
				embeddedConfluenceSource,
				userHasConfluenceAccess: hasConfluenceAccess,
				activeWhiteboardData,
				setActiveWhiteboardData,
				isEmbeddedWhiteboardModalOpen,
				setEmbeddedWhiteboardModalOpen,
				isCrossFlowModalOpen,
				setIsCrossFlowModalOpen,
				isDeferSpaceSelection,
				setIsDeferSpaceSelection,
				pageSpaceName,
				setPageSpaceName,
				setContentMenuState,
				onLinkPage,
			}}
		>
			{children}
		</ContentMenuContext.Provider>
	);
};
