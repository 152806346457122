import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	slaText: {
		id: 'issue-view-layout-templates-servicedesk.context.sla-text',
		defaultMessage: 'SLAs',
		description: 'Heading for service level agreement panel',
	},
	customerContextText: {
		id: 'issue-view-layout-templates-servicedesk.context.customer-context-text',
		defaultMessage: 'Customer',
		description:
			'Heading for the customer panel which gives details about customers and organizations.',
	},
	incidentSummaryText: {
		id: 'issue-view-layout-templates-servicedesk.context.incident-summary-text',
		defaultMessage: 'Incident Summary',
		description: 'Heading for incident summary panel',
	},
});
