import {
	SERVICE_DESK_PROJECT,
	SOFTWARE_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	useIssueKey,
	useProjectKey,
	useViewScreenId,
} from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useIssueFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import {
	ISSUE_TYPE,
	itemDocumentationUrls,
} from '@atlassian/jira-issue-view-configurations/src/index.tsx';
import { REQUEST_TYPE_CF_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import {
	useIsSimplifiedProject,
	useProjectType,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import { useCanConfigureIssue } from '@atlassian/jira-project-permissions-service/src/main.tsx';
import {
	getClassicIssueLayoutSettingsUrl,
	getClassicRequestTypeLayoutSettingsUrl,
	getNextgenSettingsIssueTypesUrl,
	getJcsRequestTypeLayoutSettingsUrl,
} from '@atlassian/jira-project-settings-apps-common/src/urls.tsx';
import { useIsJCS } from '@atlassian/jira-servicedesk-customer-service-common/src/utils/use-is-jcs/index.tsx';
import type { FormatMessage } from '@atlassian/jira-shared-types/src/general.tsx';
import messages from '../issue-actions/issue-configuration-dropdown-group/configure-issue/messages.tsx';
import { getFieldKeyByCustomType } from './get-field-key-by-custom-type.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (formatMessage: FormatMessage) => {
	const projectKey = useProjectKey();
	const projectType = useProjectType(projectKey);
	const issueKey = useIssueKey();
	const viewScreenId = useViewScreenId();
	const isJCS = useIsJCS();

	const isSimplifiedProject = useIsSimplifiedProject(projectKey);
	const canConfigureIssue = useCanConfigureIssue(projectKey);
	const shouldShowSwiftLink = isSimplifiedProject && canConfigureIssue;

	const issueTypeIdFieldKey = ISSUE_TYPE;
	const [issueTypeId] = useFieldValue({ issueKey, fieldKey: issueTypeIdFieldKey });

	const [{ value: issueFieldsConfig }] = useIssueFieldConfig(issueKey);

	let requestKeyCustomFieldKey = ISSUE_TYPE;

	// Classic service desk is the only project to have a request type
	if (!isSimplifiedProject && projectType === SERVICE_DESK_PROJECT && issueFieldsConfig) {
		// @ts-expect-error - TS2322 - Type 'string | undefined' is not assignable to type 'string'.
		requestKeyCustomFieldKey = getFieldKeyByCustomType(REQUEST_TYPE_CF_TYPE, issueFieldsConfig);
	}

	const key = requestKeyCustomFieldKey !== undefined ? requestKeyCustomFieldKey : ISSUE_TYPE;

	// Classic project request type id is custom, next gen is the issue type
	const [requestType] = useFieldValue({
		issueKey,
		fieldKey: key,
	});

	// Next gen project
	if (isSimplifiedProject && shouldShowSwiftLink && projectType) {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const { pathname, search } = window.location;

		return getNextgenSettingsIssueTypesUrl(projectType, projectKey, issueTypeId?.id, {
			// url and title are not using encodeURIComponent here because it will be serialized later.
			url: `${pathname}${search}`,
			title: formatMessage(messages.swiftBacklink, { issueKey }),
		});
	}

	// Classic & Service Desk
	if (!projectKey || viewScreenId == null || viewScreenId === 0 || isSimplifiedProject) {
		// Next gen failed or classic project with missing information
		return projectType
			? itemDocumentationUrls[projectType]
			: itemDocumentationUrls[SOFTWARE_PROJECT];
	}

	if (projectType === SERVICE_DESK_PROJECT) {
		if (
			requestType == null ||
			requestType.requestType === undefined ||
			requestType.requestType.id === undefined
		) {
			return itemDocumentationUrls[SERVICE_DESK_PROJECT];
		}

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const { pathname, search } = window.location;

		// JCS project
		if (isJCS) {
			return fg('jcs_issue_layout_eap')
				? getClassicIssueLayoutSettingsUrl({
						projectKey,
						viewScreenId,
						backLink: {
							// url and title are not using encodeURIComponent here because it will be serialized later.
							url: `${pathname}${search}`,
							title: formatMessage(messages.swiftBacklink, { issueKey }),
						},
					})
				: getJcsRequestTypeLayoutSettingsUrl({
						projectKey,
						requestTypeId: requestType.requestType.id,
						backLink: {
							// url and title are not using encodeURIComponent here because it will be serialized later.
							url: `${pathname}${search}`,
							title: formatMessage(messages.swiftBacklink, { issueKey }),
						},
					});
		}

		return getClassicRequestTypeLayoutSettingsUrl({
			projectKey,
			requestTypeId: requestType.requestType.id,
			backLink: {
				// url and title are not using encodeURIComponent here because it will be serialized later.
				url: `${pathname}${search}`,
				title: formatMessage(messages.swiftBacklink, { issueKey }),
			},
		});
	}

	if (!viewScreenId) {
		// invalid classic project
		return projectType
			? itemDocumentationUrls[projectType]
			: itemDocumentationUrls[SOFTWARE_PROJECT];
	}

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const { pathname, search } = window.location;
	return getClassicIssueLayoutSettingsUrl({
		projectKey,
		viewScreenId,
		backLink: {
			// url and title are not using encodeURIComponent here because it will be serialized later.
			url: `${pathname}${search}`,
			title: formatMessage(messages.swiftBacklink, { issueKey }),
		},
	});
};
