import { useState, useRef, useCallback, useEffect } from 'react';
import rafSchedule from 'raf-schd';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';

export type UseScrollCarousel = {
	setCanScrollDebounced: () => void;
	shouldHideScrollLeftButton: boolean;
	shouldHideScrollRightButton: boolean;
	scrollToNext: () => void;
	scrollToPrevious: () => void;
	setRef: (instance: HTMLDivElement) => void;
	onFocusCannedComment: (event: React.FocusEvent<HTMLButtonElement>) => void;
};

const isScrollAtLeft = (elem: HTMLDivElement | null) => elem?.scrollLeft === 0;
const isScrollAtRight = (elem: HTMLDivElement | null) =>
	elem
		? // https://stackoverflow.com/questions/3898130/check-if-a-user-has-scrolled-to-the-bottom-not-just-the-window-but-any-element
			Math.abs(elem?.scrollWidth - elem?.scrollLeft - elem?.clientWidth) <= 1
		: false;

const getScrollSize = (visibleItems: IntersectionObserverEntry[]) =>
	visibleItems.reduce((sum, entry) => sum + entry.boundingClientRect.width, 0);

export const useScrollCarousel = (): UseScrollCarousel => {
	const scrollableContainerRef = useRef<HTMLDivElement | null>(null);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const resizeObserver = useRef<ResizeObserver | null>(null);
	const intersectionObserver = useRef<IntersectionObserver | null>(null);
	const visibleItemsRef = useRef<IntersectionObserverEntry[]>([]);

	const [shouldHideScrollLeftButton, setShouldHideScrollLeftButton] = useState<boolean>(false);
	const [shouldHideScrollRightButton, setShouldHideScrollRightButton] = useState<boolean>(false);

	const setCanScrollDebounced = rafSchedule(() => {
		// Refs are null before mounting and after unmount
		if (!scrollableContainerRef.current) {
			return;
		}

		setShouldHideScrollLeftButton(isScrollAtLeft(scrollableContainerRef.current));
		setShouldHideScrollRightButton(isScrollAtRight(scrollableContainerRef.current));
	});

	const updateVisibleItem = rafSchedule((entries: IntersectionObserverEntry[]) => {
		entries.forEach((entry) => {
			if (!entry.isIntersecting) {
				visibleItemsRef.current = visibleItemsRef.current.filter(
					(visible) => !visible.target.isEqualNode(entry.target),
				);
			}

			if (entry.isIntersecting) {
				visibleItemsRef.current = [...visibleItemsRef.current, entry];
			}
		});
	});

	const setRef = useCallback(
		(node: HTMLDivElement) => {
			scrollableContainerRef.current = node;

			if (scrollableContainerRef.current) {
				// create resize-observer if we don't have it yet
				if (!resizeObserver.current) {
					resizeObserver.current = new ResizeObserver(() => {
						setCanScrollDebounced();
					});
				}

				// create intersection-observer if we don't have it yet
				if (!intersectionObserver.current && IntersectionObserver) {
					intersectionObserver.current = new IntersectionObserver(updateVisibleItem, {
						threshold: 1,
						root: scrollableContainerRef.current,
					});
				}

				// observe nodes and its children
				resizeObserver.current.observe(scrollableContainerRef.current);
				Array.from(scrollableContainerRef.current.children).forEach((child) => {
					intersectionObserver.current?.observe(child);
				});

				setCanScrollDebounced();
			}
		},
		[setCanScrollDebounced, updateVisibleItem],
	);

	useEffect(
		() => () => {
			if (resizeObserver.current) {
				resizeObserver.current.disconnect();
			}
			if (intersectionObserver.current) {
				intersectionObserver.current.disconnect();
			}
		},
		[],
	);

	useEffect(
		() => () => {
			setCanScrollDebounced.cancel();
		},
		[setCanScrollDebounced],
	);

	useEffect(
		() => () => {
			updateVisibleItem.cancel();
		},
		[updateVisibleItem],
	);

	// Function to scroll to the next item
	const scrollToNext = () => {
		const container = scrollableContainerRef.current;
		if (container) {
			if (visibleItemsRef.current.length > 0) {
				container.scrollBy({
					top: 0,
					/**
					 * scroll size will be the same amount of the number of visible items
					 * i.e.: we want to scroll to the next section and don't show the items that are visible already
					 */
					left: getScrollSize(visibleItemsRef.current),
					behavior: 'smooth',
				});
			} else {
				/**
				 * fallback for 2 scenarios:
				 * 1. IntersectionObserver is not available
				 * 2. User resize the observer root, which makes the visibleItemsRef array empty.
				 *    e.g: if the root container (comment box) gets resized to the point
				 *    where there are no visible canned comments, in this case visibleItemsRef is empty
				 *    and the logic on the other 'if' condition won't work and fallback is needed
				 */
				container.scrollBy({
					top: 0,
					left: container.clientWidth / 2,
					behavior: 'smooth',
				});
			}

			fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'scrollRight');
		}
	};

	// Function to scroll to the previous item
	const scrollToPrevious = () => {
		const container = scrollableContainerRef.current;

		if (container) {
			if (visibleItemsRef.current.length > 0) {
				container.scrollBy({
					top: 0,
					/**
					 * scroll size will be the same amount of the number of visible items
					 * i.e.: we want to scroll to the next section and don't show the items that are visible already
					 */
					left: -getScrollSize(visibleItemsRef.current),
					behavior: 'smooth',
				});
			} else {
				/**
				 * fallback for 2 scenarios:
				 * 1. IntersectionObserver is not available
				 * 2. User resize the observer root, which makes the visibleItemsRef array empty.
				 *    e.g: if the root container (comment box) gets resized to the point
				 *    where there are no visible canned comments, in this case visibleItemsRef is empty
				 *    and the logic on the other 'if' condition won't work and fallback is needed
				 */
				container.scrollBy({
					top: 0,
					left: -container.clientWidth / 2,
					behavior: 'smooth',
				});
			}

			fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', 'scrollLeft');
		}
	};

	const onFocusCannedComment = (event: React.FocusEvent<HTMLButtonElement>) => {
		const container = scrollableContainerRef.current;
		const cannedCommentElement = event.currentTarget;

		if (container) {
			// Calculate if the element is fully visible
			const containerRect = container.getBoundingClientRect();
			const cannedCommentElementRect = cannedCommentElement.getBoundingClientRect();

			// Element is partially off-screen to the left or right respectively
			if (
				cannedCommentElementRect.left < containerRect.left ||
				cannedCommentElementRect.right > containerRect.right
			) {
				cannedCommentElement.scrollIntoView({
					behavior: 'smooth',
					inline: 'center',
				});
			}
		}
	};

	return {
		setCanScrollDebounced,
		shouldHideScrollLeftButton,
		shouldHideScrollRightButton,
		scrollToNext,
		scrollToPrevious,
		setRef,
		onFocusCannedComment,
	};
};
