import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/operator/withLatestFrom';
import 'rxjs/add/operator/filter';
import type { ActionsObservable } from 'redux-observable';
import type { User } from '@atlassian/jira-issue-shared-types/src/common/types/user-type.tsx';
import {
	ASSIGN_TO_ME_SUCCESS,
	REMOVE_FROM_ME_SUCCESS,
} from '@atlassian/jira-issue-view-common-constants/src/flags.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { ASSIGN_TO_ME_EXTERNAL } from '@atlassian/jira-issue-view-store/src/actions/external-actions.tsx';
import { fieldSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector.tsx';
import { loggedInUserDetailsSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';
import { canBeAssignedToIssuesPermissionsSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/permissions-selector.tsx';
import { FIELD_INIT_OPTIONS } from '@atlassian/jira-issue-view-store/src/issue-field/state/actions/field-actions.tsx';
import { fieldSaveRequest } from '@atlassian/jira-issue-view-store/src/issue-field/state/actions/field-save-actions.tsx';
import { ASSIGNEE_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';

const transform = ({ avatarUrl, displayName, id }: User) => ({
	avatarUrls: {
		'48x48': avatarUrl,
	},
	displayName,
	accountId: id,
});

const assignToMeFromExternalSource = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	action$: ActionsObservable<any>,
	store: MiddlewareAPI<State>,
) => {
	const assignToMe$ = action$.ofType(ASSIGN_TO_ME_EXTERNAL);
	const fieldOptions$ = action$
		.ofType(FIELD_INIT_OPTIONS)
		.filter((action) => action.payload.fieldId === ASSIGNEE_TYPE);

	return (
		assignToMe$
			// @ts-expect-error - TS2769 - No overload matches this call.
			.filter(() => {
				const state = store.getState();
				return (
					loggedInUserDetailsSelector(state) && canBeAssignedToIssuesPermissionsSelector(state)
				);
			})
			.withLatestFrom(fieldOptions$, (_, { payload: { fieldId, fieldOptions } }) => {
				const state = store.getState();
				const loggedInUser = loggedInUserDetailsSelector(state);
				if (loggedInUser) {
					const assignee = fieldSelector(fieldId)(state);
					const value =
						assignee?.value?.accountId === loggedInUser.id ? null : transform(loggedInUser);
					const message = !value ? REMOVE_FROM_ME_SUCCESS : ASSIGN_TO_ME_SUCCESS;
					// @ts-expect-error - TS2554 - Expected 6-7 arguments, but got 3.
					return fieldSaveRequest(ASSIGNEE_TYPE, value, {
						...fieldOptions,
						onSaveSuccessFlagType: message,
					});
				}
				return null;
			})
	);
};

export default assignToMeFromExternalSource;
