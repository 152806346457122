// TODO: this file is temporary and will be removed as part of BENTO-8784

import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import type { MediaType } from '@atlaskit/media-client';
import { MediaTypeIcon } from '@atlaskit/media-ui/media-type-icon';
import { Truncate } from '@atlaskit/media-ui/truncateText';
import Tooltip from '@atlaskit/tooltip';
// TODO: this file is temporary and will be removed as part of BENTO-8784

type Props = {
	mediaType: MediaType;
	icon?: ReactNode;
	text: string;
	startFixedChars: number;
	endFixedChars: number;
};

export const NameCell = ({ mediaType, icon, text, startFixedChars, endFixedChars }: Props) => (
	<NameCellWrapper>
		{mediaType && <MediaTypeIcon type={mediaType} />}
		{icon}
		<TruncateWrapper>
			<Tooltip content={text} testId="issue-attachments-table.ui.name-cell-temporary.tooltip">
				<Truncate text={text} startFixedChars={startFixedChars} endFixedChars={endFixedChars} />
			</Tooltip>
		</TruncateWrapper>
	</NameCellWrapper>
);

export default NameCell;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NameCellWrapper = styled.div({
	display: 'flex',
	alignContent: 'center',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const TruncateWrapper = styled.div({
	minWidth: 0,
	width: '100%',
});
