import React from 'react';
import { styled } from '@compiled/react';
import { Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import AsyncIcon from '@atlassian/jira-common-components-async-icon/src/view.tsx';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { FormattedMessage } from '@atlassian/jira-intl';
import type { ContextPanel } from '@atlassian/jira-issue-shared-types/src/common/types/context-panel-type.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';

export const Content = ({
	label,
	name,
}: {
	label: ContextPanel['label'];
	name: ContextPanel['name'];
}) =>
	label === name ? (
		<StyledContent>
			<FormattedMessage
				id="issue.ecosystem.context.rich-context-label"
				defaultMessage="Open {label}"
				values={{
					label: isVisualRefreshEnabled() ? (
						<Text weight="semibold">{label}</Text>
					) : (
						<Bold>{label}</Bold>
					),
				}}
			/>
		</StyledContent>
	) : (
		<StyledContent>{label}</StyledContent>
	);

export const Icon = ({ icon }: { icon: ContextPanel['icon'] }) => {
	if (!icon) {
		return null;
	}

	return (
		<StyledIcon>
			<AsyncIcon url={icon.url} alt={icon.alt} width={24} height={24} />
		</StyledIcon>
	);
};

export const Subtitle = ({
	icon,
	label,
	name,
}: {
	icon: ContextPanel['icon'];
	label: ContextPanel['label'];
	name: ContextPanel['name'];
}) => (
	<SubtitleWrapper>
		<Icon icon={icon} />
		<Content label={label} name={name} />
	</SubtitleWrapper>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledIcon = styled.div({
	overflow: 'hidden',
	marginTop: token('space.050', '4px'),
	marginBottom: token('space.050', '4px'),
	marginLeft: token('space.100', '8px'),
	height: '24px',
	width: '24px',
	alignItems: 'center',
	paddingLeft: token('space.025', '2px'),
	paddingRight: token('space.025', '2px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledContent = styled.div({
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	flex: 1,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	lineHeight: `${gridSize * 4}px`,
	paddingLeft: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Bold = styled.span({
	fontWeight: token('font.weight.semibold'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SubtitleWrapper = styled.div({
	display: 'flex',
});
