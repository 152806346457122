// FETCH_PROJECT_HAS_SUBTASK_VALIDATORS
export const FETCH_PROJECT_HAS_SUBTASK_VALIDATORS = 'FETCH_PROJECT_HAS_SUBTASK_VALIDATORS' as const;

export type FetchProjectHasSubtaskValidatorsAction = {
	type: typeof FETCH_PROJECT_HAS_SUBTASK_VALIDATORS;
};

export const fetchProjectHasSubtaskValidatorsAction =
	(): FetchProjectHasSubtaskValidatorsAction => ({
		type: FETCH_PROJECT_HAS_SUBTASK_VALIDATORS,
	});

// FETCH_PROJECT_HAS_SUBTASK_VALIDATORS_SUCCESS
export const FETCH_PROJECT_HAS_SUBTASK_VALIDATORS_SUCCESS =
	'FETCH_PROJECT_HAS_SUBTASK_VALIDATORS_SUCCESS' as const;

export type FetchProjectHasSubtaskValidatorsSuccessAction = {
	type: typeof FETCH_PROJECT_HAS_SUBTASK_VALIDATORS_SUCCESS;
	payload: boolean;
};

export const fetchProjectHasSubtaskValidatorsSuccessAction = (
	projectHasSubtaskValidators: boolean,
): FetchProjectHasSubtaskValidatorsSuccessAction => ({
	type: FETCH_PROJECT_HAS_SUBTASK_VALIDATORS_SUCCESS,
	payload: projectHasSubtaskValidators,
});

// FETCH_PROJECT_HAS_SUBTASK_VALIDATORS_FAILURE
export const FETCH_PROJECT_HAS_SUBTASK_VALIDATORS_FAILURE =
	'FETCH_PROJECT_HAS_SUBTASK_VALIDATORS_FAILURE' as const;

export type FetchProjectHasSubtaskValidatorsFailureAction = {
	type: typeof FETCH_PROJECT_HAS_SUBTASK_VALIDATORS_FAILURE;
};

export const fetchProjectHasSubtaskValidatorsFailureAction =
	(): FetchProjectHasSubtaskValidatorsFailureAction => ({
		type: FETCH_PROJECT_HAS_SUBTASK_VALIDATORS_FAILURE,
	});

export type ProjectValidatorAction =
	| FetchProjectHasSubtaskValidatorsAction
	| FetchProjectHasSubtaskValidatorsSuccessAction
	| FetchProjectHasSubtaskValidatorsFailureAction;
