import React, { memo } from 'react';
import { useRovoEntitlement } from '@atlassian/conversation-assistant-entitlement';
import { Skeleton } from '@atlassian/jira-common-components-skeleton/src/styled.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { useIsIssueOfIncidentsPractice } from '@atlassian/jira-issue-field-servicedesk-practices/src/services/use-practices-field-value/index.tsx';
import AsyncOpsAgentDiscoverability from '@atlassian/jira-ops-agent-discoverability/src/async.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const JiraOpsAgentDiscoverabilityPanel = memo<Record<any, any>>(() => {
	const isIssueOfIncidentPractice = useIsIssueOfIncidentsPractice();
	const { isRovoEnabled } = useRovoEntitlement();

	if (!isIssueOfIncidentPractice || !isRovoEnabled) {
		return null;
	}

	return (
		<UFOSegment name="issue-incident-itops-discoverability">
			<JSErrorBoundary
				id="AsyncOpsAgentDiscoverabilityPanel"
				packageName="jiraIssueViewBase"
				fallback="unmount"
				sendToPrivacyUnsafeSplunk
			>
				<Placeholder
					name="ops-agent-discoverability-panel"
					fallback={<Skeleton width="100%" height="60px" />}
				>
					<AsyncOpsAgentDiscoverability />
				</Placeholder>
			</JSErrorBoundary>
		</UFOSegment>
	);
});

export default JiraOpsAgentDiscoverabilityPanel;
