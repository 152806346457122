import type { CardCover } from '@atlassian/jira-issue-view-common-types/src/card-cover-type.tsx';
import {
	type CardCoverActions,
	CARD_COVER_FETCH_SUCCESS,
	CARD_COVER_UPDATE_REQUEST,
	CARD_COVER_UPDATE_FAILURE,
} from '../../actions/card-cover-actions.tsx';

export const initialState: CardCover = {
	isEnabled: false,
	attachmentId: null,
	availableAttachmentIds: [],
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: CardCover = initialState, action: CardCoverActions) => {
	switch (action.type) {
		case CARD_COVER_FETCH_SUCCESS:
			return action.payload.cardCover;

		case CARD_COVER_UPDATE_REQUEST:
		case CARD_COVER_UPDATE_FAILURE:
			return {
				...state,
				attachmentId: action.payload.attachmentId,
			};

		default:
			return state;
	}
};
