import type { Action } from '@atlassian/react-sweet-state';
import type { OpenArgs, State } from './types.tsx';

const open =
	({ issues, flagAction, callback, source, analyticsArgs }: OpenArgs): Action<State> =>
	({ setState }) => {
		const newState: Partial<State> = {
			issues,
			flagAction,
			callback,
			analyticsArgs: {
				source,
				...analyticsArgs,
			},
		};

		setState(newState);
	};

const close =
	(): Action<State> =>
	({ setState }) => {
		setState({
			issues: [],
			flagAction: undefined,
			callback: undefined,
			analyticsArgs: undefined,
		});
	};

const preload =
	(): Action<State> =>
	({ setState, getState }) => {
		const { forcePreload } = getState();
		if (!forcePreload) {
			setState({ forcePreload: true });
		}
	};

export const actions = {
	open,
	close,
	preload,
} as const;

export type Actions = typeof actions;
