import React from 'react';

import { fg } from '@atlaskit/platform-feature-flags';

import { CONFIG_ITEM_KEYS } from '../../../config-items/config-item-keys';
import { createEditorPluginAIConfigItemMarkdown } from '../../../config-items/config-items';
import { Icon } from '../../assets/icons/brainstorm';
import { createInsertAtCurrentPosition } from '../../config-item-actions/markdown';

import { messages } from './messages';

const SELECTION_TYPE = 'empty';

export const suggestResponseConfigItem = (issueId?: string) =>
	createEditorPluginAIConfigItemMarkdown({
		key: CONFIG_ITEM_KEYS.SUGGEST_REPONSE,
		title: messages.title,
		description: messages.description,
		selectionType: SELECTION_TYPE,
		endpoint: `/gateway/api/firebolt/1/agent-response/issues/${issueId}/stream`,
		intentSchemaId: 'brainstorm_intent_schema.json',
		icon: ({ shownAt }) => <Icon shownAt={shownAt} />,
		isVisible: () => true,
		actions: [createInsertAtCurrentPosition({ appearance: 'primary' })],
		getBackendModel: () => {
			return 'assistance-service';
		},

		statusLozengeType: fg('platform_remove_jsm_beta_tags') ? undefined : 'beta',
	});
