/**
 * @generated SignedSource<<ac88ab127ac9ae040b14fea73c66a12b>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraSprintState = "ACTIVE" | "CLOSED" | "FUTURE" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type sprint_issueFieldSprintInlineEditFull_SprintInlineEditViewIsEditable_fragmentRef$data = {
  readonly fieldId: string;
  readonly id: string;
  readonly name: string;
  readonly selectedSprintsConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly endDate: AGG$DateTime | null | undefined;
        readonly id: string;
        readonly name: string | null | undefined;
        readonly sprintId: string;
        readonly state: JiraSprintState | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"sprint_issueFieldSprintReadviewFull_SprintReadView">;
  readonly " $fragmentType": "sprint_issueFieldSprintInlineEditFull_SprintInlineEditViewIsEditable_fragmentRef";
};
export type sprint_issueFieldSprintInlineEditFull_SprintInlineEditViewIsEditable_fragmentRef$key = {
  readonly " $data"?: sprint_issueFieldSprintInlineEditFull_SprintInlineEditViewIsEditable_fragmentRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"sprint_issueFieldSprintInlineEditFull_SprintInlineEditViewIsEditable_fragmentRef">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "id"
},
v1 = {
  "kind": "ScalarField",
  "name": "name"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "sprint_issueFieldSprintInlineEditFull_SprintInlineEditViewIsEditable_fragmentRef",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "sprint_issueFieldSprintReadviewFull_SprintReadView"
    },
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    (v1/*: any*/),
    {
      "concreteType": "JiraSprintConnection",
      "kind": "LinkedField",
      "name": "selectedSprintsConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraSprintEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraSprint",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "name": "sprintId"
                },
                (v1/*: any*/),
                {
                  "kind": "ScalarField",
                  "name": "state"
                },
                {
                  "kind": "ScalarField",
                  "name": "endDate"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraSprintField"
};
})();

(node as any).hash = "52958eeea1ec618ec650c65098329a2d";

export default node;
