import { createSelector } from 'reselect';
import { issueIdSelector } from '../common/state/selectors/context-selector.tsx';
import { projectIdSelector } from '../common/state/selectors/issue-selector.tsx';

export const richTextContextIdentifierSelector = createSelector(
	issueIdSelector,
	projectIdSelector,
	(issueId, projectId) => ({
		objectId: issueId,
		containerId: projectId,
	}),
);
