/** @jsx jsx */
import React, { forwardRef, useMemo } from 'react';
import { styled, css, jsx } from '@compiled/react';
import { useFragment, graphql } from 'react-relay';
import Avatar from '@atlaskit/avatar';
import { Text as TextPrimitive } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { AsyncProfileCardNext } from '@atlassian/jira-profilecard-next/src/async.tsx';
import { VIEW_PROFILE_ACTION } from '@atlassian/jira-profilecard-next/src/common/constants.tsx';
import type { Actions } from '@atlassian/jira-profilecard-next/src/types.tsx';
import type { singleUserPicker_issueFieldSingleUserPickerReadviewFull_SingleUserPickerReadView_fragmentRef$key as SingleUserPickerFragment } from '@atlassian/jira-relay/src/__generated__/singleUserPicker_issueFieldSingleUserPickerReadviewFull_SingleUserPickerReadView_fragmentRef.graphql';
import type { singleUserPicker_issueFieldSingleUserPickerReadviewFull_SingleUserPickerWithProfileCardReadView_fragmentRef$key as SingleUserPickerWithProfileCardFragment } from '@atlassian/jira-relay/src/__generated__/singleUserPicker_issueFieldSingleUserPickerReadviewFull_SingleUserPickerWithProfileCardReadView_fragmentRef.graphql';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import messages from './messages.tsx';
import type {
	SingleUserPickerReadViewProps,
	SingleUserPickerWithProfileCardReadViewProps,
} from './types.tsx';

/**
 * The SingleUserPickerReadView will show a read only view of SingleUserPicker field.
 * @param props [SingleUserPickerReadViewProps](./types.tsx)
 */
export const SingleUserPickerReadView = ({
	fragmentRef,
	emptyUserLabel = null,
	isTruncated,
}: SingleUserPickerReadViewProps) => {
	const data = useFragment<SingleUserPickerFragment>(
		graphql`
			fragment singleUserPicker_issueFieldSingleUserPickerReadviewFull_SingleUserPickerReadView_fragmentRef on JiraSingleSelectUserPickerField {
				user {
					name
					picture
					accountStatus
				}
			}
		`,
		fragmentRef,
	);
	const intl = useIntl();
	const { formatMessage } = intl;

	const {
		name = emptyUserLabel ?? formatMessage(messages.anonymousOption),
		picture,
		accountStatus,
	} = data?.user || {};

	return (
		// We use our own container element rather than an AvatarItem as we've observed hydration bugs with SSR+Emotion
		// when this component is wrapped in an async profile card, and this approach is the path of least friction.
		// Further context here https://atlassian.slack.com/archives/C060GTVV988/p1712624943348969.
		<Container>
			<Avatar
				as={AvatarAlignmentFix}
				src={picture}
				name={name}
				size="small"
				borderColor="transparent"
			/>
			{fg('endeavour_add_density_full_to_issue_fields') ? (
				<TextPrimitive maxLines={isTruncated ? 1 : undefined}>
					<span>{name}</span>
					{data?.user && accountStatus !== 'active' && (
						<UserStatus>{`(${formatMessage(messages.deactivated)})`}</UserStatus>
					)}
				</TextPrimitive>
			) : (
				<Text>
					<span>{name}</span>
					{data?.user && accountStatus !== 'active' && (
						<UserStatus>{`(${formatMessage(messages.deactivated)})`}</UserStatus>
					)}
				</Text>
			)}
		</Container>
	);
};

export const SingleUserPickerWithProfileCardReadView = ({
	fragmentRef,
	emptyUserLabel,
	profileCardAction,
	isTruncated,
}: SingleUserPickerWithProfileCardReadViewProps) => {
	const data = useFragment<SingleUserPickerWithProfileCardFragment>(
		graphql`
			fragment singleUserPicker_issueFieldSingleUserPickerReadviewFull_SingleUserPickerWithProfileCardReadView_fragmentRef on JiraSingleSelectUserPickerField {
				...singleUserPicker_issueFieldSingleUserPickerReadviewFull_SingleUserPickerReadView_fragmentRef
				user {
					accountId
				}
			}
		`,
		fragmentRef,
	);

	const accountId: string | null = data?.user?.accountId || null;

	const actions = useMemo(
		(): Actions[] =>
			profileCardAction ? [VIEW_PROFILE_ACTION, profileCardAction] : [VIEW_PROFILE_ACTION],
		[profileCardAction],
	);

	if (accountId) {
		return (
			<UFOSegment name="issue-field-single-user-picker-read-view">
				<AsyncProfileCardNext accountId={accountId} actions={actions}>
					<SingleUserPickerReadView
						fragmentRef={data}
						emptyUserLabel={emptyUserLabel}
						isTruncated={isTruncated}
					/>
				</AsyncProfileCardNext>
			</UFOSegment>
		);
	}

	return (
		<UFOSegment name="issue-field-single-user-picker-read-view">
			<SingleUserPickerReadView
				fragmentRef={data}
				emptyUserLabel={emptyUserLabel}
				isTruncated={isTruncated}
			/>
		</UFOSegment>
	);
};

/**
 * Component to pass for "as" prop of Avatar. It reverses the 2px margin added in the Avatar
 * component when a transparent border is used.
 *
 * Done using div + css because styled components are not compatible with Avatar's types.
 */
const AvatarAlignmentFix = forwardRef<HTMLDivElement, React.AllHTMLAttributes<HTMLElement>>(
	(props, ref) => <div {...props} ref={ref} css={avatarAlignmentStyles} />,
);

const avatarAlignmentStyles = css({
	margin: token('space.negative.025', '-2px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	alignItems: 'center',
	gap: token('space.100', '8px'),
	maxWidth: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Text = styled.span({
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const UserStatus = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N200),
	marginLeft: token('space.025', '2px'),
});
