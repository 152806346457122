// Analog of jquery .scrollParent(). Taken from https://stackoverflow.com/a/42543908/1343917
export const getScrollParent = (
	element?: HTMLElement | null,
	shouldExcludeStaticParent = true,
): Element | null => {
	if (!element || !element.parentElement) {
		return null;
	}

	let style = getComputedStyle(element);
	const excludeStaticParent = shouldExcludeStaticParent && style.position === 'absolute';
	const overflowRegex = /(auto|scroll)/; // overflow-x: hidden will make overflowY to be 'auto' automatically (by W3C spec)

	if (style.position === 'fixed') {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		return document.body;
	}

	// @ts-expect-error - TS2322 - Type 'HTMLElement | null' is not assignable to type 'HTMLElement'.
	for (let parent = element.parentElement; parent; parent = parent && parent.parentElement) {
		style = getComputedStyle(parent);

		const shouldIgnoreElement = excludeStaticParent && style.position === 'static';
		if (
			!shouldIgnoreElement &&
			overflowRegex.test(style.overflow + style.overflowY + style.overflowX)
		) {
			return parent;
		}
	}

	return null;
};
