import type { AcronymType } from '../../services/index.tsx';
import { ALLOWED_ELEMENTS, DEFAULT_ACRONYM_HIGHLIGHT_REGEX } from './constants.tsx';

const TIME_OUT = 300;

type DataNode = {
	version?: number;
	type: string;
	text?: string;
	attrs?: unknown;
	marks?: DataNode[];
	content?: DataNode[];
};

type AcronymNode = {
	node: Node;
	matchIndex: number | undefined;
	matchLength: number;
};

const validMarks = (data: DataNode) => {
	const invalidMarks = ['annotation', 'link'];
	return !data.marks || !data.marks.some((mark) => invalidMarks.includes(mark.type));
};

const validType = (data: DataNode) => {
	const invalidTypes = ['codeBlock'];
	return !invalidTypes.includes(data.type);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const extractContent = (data: any, flatMap: DataNode[]) => {
	if (validType(data)) {
		if (data.content && data.content.length > 0) {
			for (const node of data.content) {
				extractContent(node, flatMap);
			}
		}
	}

	// leaf node
	if (data.type === 'text' && validMarks(data)) {
		flatMap.push(data);
	}
};

/**
 *
 * @param adfValue
 * @returns value of texts
 * Using the ADF value to find all the texts from considered valid types
 *
 */

export const parseContent = (adfValue: unknown): string => {
	const flatMap: DataNode[] = [];
	extractContent(adfValue, flatMap);
	return flatMap.map((node) => node.text).join(',');
};

/**
 *
 * @param walker
 * @param acronyms
 * @returns AcronymNode[]
 *
 * Optimistically go through the DOM node and find all the occurences
 */

const findAllOccurencesOptimisticallly = (
	walker: TreeWalker,
	acronyms: readonly string[],
): AcronymNode[] => {
	const nodesToReplace = [];

	// keep track of parent node and whether it's been skipped already
	let lastParentNode: ParentNode | null = null;
	let skip = false;
	const existingMatches = new Set<string>();
	// Prefill existing data

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const highlightedAcronyms = document.querySelectorAll('.acronym-highlight');
	highlightedAcronyms.forEach((item) => item.textContent && existingMatches.add(item.textContent));

	while (walker.nextNode()) {
		const node = walker.currentNode;
		// skip text nodes that have parents we don't want, such as span or a elements
		// only checks again if we encounter a new parent node
		if (node.parentNode !== lastParentNode) {
			lastParentNode = node.parentNode;
			skip = !ALLOWED_ELEMENTS.includes(node.parentNode?.nodeName.toLowerCase() || '');
		}
		// eslint-disable-next-line no-continue
		if (skip) continue;

		const nodeValue = node.nodeValue || '';
		// find all valid acronym matches in the text node
		for (const acronymMatch of nodeValue.matchAll(DEFAULT_ACRONYM_HIGHLIGHT_REGEX)) {
			if (!existingMatches.has(acronymMatch[0]) && acronyms.includes(acronymMatch[0])) {
				nodesToReplace.push({
					node,
					matchIndex: acronymMatch.index,
					matchLength: acronymMatch[0].length,
				});
				existingMatches.add(acronymMatch[0]);
			}
		}
	}

	return nodesToReplace;
};

const markUpNodes = (nodesToReplace: AcronymNode[], onClick: (e: MouseEvent) => void) => {
	while (nodesToReplace.length > 0) {
		const item = nodesToReplace.pop();
		const { node, matchIndex, matchLength } = item || {};
		if (!node || matchIndex === undefined || !matchLength || !node?.nodeValue || !node.parentNode)
			// eslint-disable-next-line no-continue
			continue;

		// split up the text node content into part before match, match, and after match
		const before = node.nodeValue.substr(0, matchIndex);
		const middle = node.nodeValue.substr(matchIndex, matchLength);
		const after = node.nodeValue.substr(matchIndex + matchLength);

		// replace text node with part before match
		node.nodeValue = before;

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const fragment = document.createDocumentFragment();
		const replacementSpan = createReplacementNode(middle, onClick);
		fragment.appendChild(replacementSpan);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const afterNode = document.createTextNode(after);
		fragment.appendChild(afterNode);

		// insert new text node with span and part after match
		node.parentNode.insertBefore(fragment, node.nextSibling);
	}
};
/* eslint-disable no-param-reassign */
const applyDefaultStyle = (htmlElement: HTMLElement) => {
	htmlElement.style.background = 'none';
	htmlElement.style.borderImage = 'linear-gradient(to right, #0065FF, #BF63F3, #F5E6A8) 0 0 100% 0';
	htmlElement.style.borderWidth = '2px';
	htmlElement.style.borderBottomStyle = 'solid';
};
/* eslint-disable no-param-reassign */
const applyHoverStyle = (htmlElement: HTMLElement) => {
	htmlElement.style.background =
		'linear-gradient(to right, rgba(0, 101, 255, 0.1), rgba(191, 99, 243, 0.1))';
	htmlElement.style.cursor = 'pointer';
};

const createReplacementNode = (middle: string, onClick: (e: MouseEvent) => void) => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const replacementSpan = document.createElement('span');
	replacementSpan.classList.add('acronym-highlight');
	replacementSpan.textContent = middle;
	applyDefaultStyle(replacementSpan);
	const mouseOverHandler = () => {
		applyHoverStyle(replacementSpan);
	};

	const mouseOutHandler = () => {
		applyDefaultStyle(replacementSpan);
	};

	replacementSpan.addEventListener('mouseover', mouseOverHandler);
	replacementSpan.addEventListener('mouseout', mouseOutHandler);
	replacementSpan.addEventListener('click', onClick);
	// prevent the trigger of undesired effect onpointerup event
	// in /src/ui/utils.tsx
	replacementSpan.addEventListener('pointerup', (e) => e.stopPropagation());

	return replacementSpan;
};

const markUpContent = (
	element: HTMLElement,
	acronyms: AcronymType,
	onClick: (e: MouseEvent) => void,
) => {
	if (!acronyms) {
		return;
	}

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const walker = document.createTreeWalker(element, NodeFilter.SHOW_TEXT);
	const nodesToReplace = findAllOccurencesOptimisticallly(walker, acronyms);
	markUpNodes(nodesToReplace, onClick);
};

const resetMarkUpContent = () => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const highlightedAcronyms = document.querySelectorAll('.acronym-highlight');
	highlightedAcronyms.forEach((item) => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const textNode = document.createTextNode(item.textContent ?? '');

		item.replaceWith(textNode);
	});
};

/**
 *
 * @param element
 * @param acronyms
 * @param onClick
 *
 * Gracefully mark up the content to avoid racing with renderer
 */

export const markUpContentGracefully = (
	element: HTMLElement,
	acronyms: AcronymType,
	onClick: (e: MouseEvent) => void,
) => {
	setTimeout(() => markUpContent(element, acronyms, onClick), TIME_OUT);
};

export const resetMarkedUpContentGracefully = () => {
	setTimeout(() => resetMarkUpContent(), TIME_OUT);
};
