import { PlaceholderEnum, BranchTemplateType } from './types.tsx';

export const UPDATE_BRANCH_NAME_FORMAT_TITLE_TEST_ID =
	'development-summary-branch.ui.create-branch-dropdown.copy-branch-command.branch-command-builder.section-title-update-branch-name-format';

export const PREVIEW_TITLE_TEST_ID =
	'development-summary-branch.ui.create-branch-dropdown.copy-branch-command.branch-command-builder.section-title-preivew';

export const RESET_BUTTON_TEST_ID =
	'development-summary-branch.ui.create-branch-dropdown.copy-branch-command.branch-command-builder.reset-button';

export const CANCEL_BUTTON_TEST_ID =
	'development-summary-branch.ui.create-branch-dropdown.copy-branch-command.branch-command-builder.button-cancel';

export const SAVE_BUTTON_TEST_ID =
	'development-summary-branch.ui.create-branch-dropdown.copy-branch-command.branch-command-builder.button-save';

export const COPY_PAGE_GIT_COMMAND_LENGTH_ERROR_MESSAGE_TEST_ID =
	'development-summary-branch.ui.create-branch-dropdown.copy-branch-command.git-command-error-message-copy-page';

export const SAVE_ERROR_MESSAGE_TEST_ID =
	'development-summary-branch.ui.create-branch-dropdown.copy-branch-command.branch-command-builder.save-error-message';

export const PROPERTY_KEY = 'create-branch-config';

export const GIT_COMMAND_SECTION_TEST_ID =
	'development-summary-branch.ui.create-branch-dropdown.git-command-section';

export const tags: PlaceholderEnum[] = [
	PlaceholderEnum.ISSUE_KEY,
	PlaceholderEnum.ISSUE_SUMMARY,
	PlaceholderEnum.ISSUE_SUMMARY_SHORT,
	PlaceholderEnum.ISSUE_TYPE,
	PlaceholderEnum.PROJECT_KEY,
];

export const initialTemplates = [
	{ type: BranchTemplateType.TEXT, value: '' },
	{ type: BranchTemplateType.PLACEHOLDER, value: PlaceholderEnum.ISSUE_KEY },
	{ type: BranchTemplateType.TEXT, value: '-' },
	{ type: BranchTemplateType.PLACEHOLDER, value: PlaceholderEnum.ISSUE_SUMMARY },
	{ type: BranchTemplateType.TEXT, value: '' },
];

export const TAB = 'Tab';
export const ARROW_DOWN = 'ArrowDown';
export const ARROW_UP = 'ArrowUp';
export const ARROW_LEFT = 'ArrowLeft';
export const ARROW_RIGHT = 'ArrowRight';
export const ENTER = 'Enter';
export const BACKSPACE = 'Backspace';
export const DELETE = 'Delete';

export const TAB_KEY_CODE = 9;
export const ARROW_DOWN_KEY_CODE = 40;
export const ARROW_UP_KEY_CODE = 38;
export const ARROW_LEFT_KEY_CODE = 37;
export const ARROW_RIGHT_KEY_CODE = 39;
export const ENTER_KEY_CODE = 13;
export const BACKSPACE_KEY_CODE = 8;
export const DELETE_KEY_CODE = 46;

export const GIT_COMMAND_MAX_LENGTH = 250;
export const GIT_CHECKOUT_NEW_BRANCH = 'git checkout -b';

export const ERROR = 'error';
export const WARNING = 'warning';

export const FORBIDDEN_GIT_BRANCH_EXPRESSIONS =
	// We need control character pattern matching to comply with Git's ref format
	// eslint-disable-next-line no-control-regex
	/(^|[/.])([/.]|$)|^@$|@{|[\x00-\x20\x7f~^:?*;"'`&|$()!\][\\]|\.lock(\/|$)|\s/g;
