// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	underOriginalEstimate: {
		id: 'issue.log-time.under-original-estimated-time-tooltip',
		defaultMessage: '{time} under original estimate',
		description: '',
	},
	overOriginalEstimate: {
		id: 'issue.log-time.over-original-estimated-time-tooltip',
		defaultMessage: '{time} over original estimate',
		description: '',
	},
});
