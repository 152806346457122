import sortBy from 'lodash/sortBy';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { RemoteIssue } from '@atlassian/jira-polaris-remote-issue/src/controllers/crud/types.tsx';

type SortIssuesByRankProps = {
	issues: RemoteIssue[];
	rankField: string | undefined;
};

export const sortIssuesByRank = ({ issues, rankField }: SortIssuesByRankProps): RemoteIssue[] =>
	rankField !== undefined
		? sortBy(issues, [(issue: RemoteIssue) => issue.fields[rankField]])
		: issues;

type SortIdsByRankProps = {
	ids: LocalIssueId[];
	lexoRankProperties: Record<string, string> | undefined;
};

export const sortIdsByRank = ({ ids, lexoRankProperties }: SortIdsByRankProps): LocalIssueId[] =>
	lexoRankProperties !== undefined
		? sortBy(ids, [(id: LocalIssueId) => lexoRankProperties[id]])
		: ids;
