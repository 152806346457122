import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';

import { token } from '@atlaskit/tokens';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Icon = styled.img({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * 2}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 2}px`,
	marginRight: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const OptionWrapper = styled.div({
	display: 'flex',
	flexDirection: 'row',
	overflow: 'hidden',
	alignItems: 'center',
	justifyContent: 'start',
	padding: `0 ${token('space.050', '4px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const OptionText = styled.div({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Container = styled.div({
	padding: `${token('space.100', '8px')} ${token('space.200', '16px')} ${token(
		'space.200',
		'16px',
	)}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const MenuFooterText = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N90),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: '12px',
	padding: `${token('space.100', '8px')} ${token('space.200', '16px')}`,
});
