import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import { xcss, Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	FieldWrapper,
	SideBySideField,
	FieldHeadingTitle,
	FieldHeadingSideBySide,
} from '@atlassian/jira-issue-field-heading/src/styled.tsx';
import { FieldPin } from '@atlassian/jira-issue-field-pin/src/index.tsx';
import { DEV_SUMMARY_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

export const DevelopmentFieldWrapper = ({
	children,
	afterHeadingNode,
	pinnable = false,
}: Props) => {
	const { formatMessage } = useIntl();
	const titleLabel = formatMessage(messages.label);
	return (
		<FieldWrapper>
			<DevPanelFieldHeading>
				<FieldHeadingTitle>{titleLabel}</FieldHeadingTitle>
				{afterHeadingNode}
				{pinnable && <FieldPin fieldId={DEV_SUMMARY_TYPE} label={titleLabel} />}
			</DevPanelFieldHeading>
			<SideBySideField>{children}</SideBySideField>
		</FieldWrapper>
	);
};

export default DevelopmentFieldWrapper;

const DevPanelFieldHeadingNew = ({ children }: { children: ReactNode }) => (
	<DevPanelFieldHeadingOuter>
		<Box xcss={devPanelFieldHeadingOuterStyles}>{children}</Box>
	</DevPanelFieldHeadingOuter>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DevPanelFieldHeadingOld = styled(FieldHeadingSideBySide)({
	display: 'flex',
	paddingRight: '0px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DevPanelFieldHeadingOuter = styled(FieldHeadingSideBySide)({
	width: '40%',
	minWidth: '120px',
	flexGrow: 1,
	boxSizing: 'border-box',
	paddingRight: token('space.250', '20px'),
});

const devPanelFieldHeadingOuterStyles = xcss({
	display: 'flex',
	justifyContent: 'space-between',
	height: 'fit-content',
	paddingTop: 'space.100',
	paddingBottom: 'space.100',
	paddingLeft: 'space.050',
	paddingRight: 'space.050',
	borderRadius: '4px',
	width: '100%',
	':hover': {
		backgroundColor: 'color.background.neutral.hovered',
	},
});

const DevPanelFieldHeading = componentWithFG(
	'issue_view_field_config_edit',
	DevPanelFieldHeadingNew,
	DevPanelFieldHeadingOld,
);
