import { fg } from '@atlassian/jira-feature-gating';
import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';
import { defaultOptions as deprecatedDefaultOptions } from '@atlassian/jira-fetch/src/utils/fetch-default-options.tsx';
import {
	type SaveRemoteIssueLinkPayload,
	REMOTE_ISSUE_LINK_PAYLOAD_WITH_ISSUE_ID,
} from '@atlassian/jira-issue-shared-types/src/common/types/remote-issue-link-add-type.tsx';
import type { BaseUrl, IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { getSaveRemoteIssueLinksUrl } from './issue-urls.tsx';

export const getPostBody = (remoteLink: SaveRemoteIssueLinkPayload) => {
	const {
		applicationType,
		applicationName,
		issueLinkUrl,
		relationship,
		summary,
		iconUrl,
		issueKey,
		createReciprocalLink,
	} = remoteLink;

	const extraField =
		remoteLink.payloadType === REMOTE_ISSUE_LINK_PAYLOAD_WITH_ISSUE_ID
			? { globalId: remoteLink.globalId }
			: {
					// @ts-expect-error - TS2339 - Property 'appLinkId' does not exist on type 'RemoteIssueLinkPayloadWithIssueId'.
					appLinkId: remoteLink.appLinkId,
				};

	return {
		application: {
			name: applicationName,
			type: applicationType,
		},
		relationship,
		object: {
			summary,
			icon: {
				url16x16: iconUrl,
			},
			title: issueKey,
			url: issueLinkUrl,
		},
		createReciprocalLink,
		...extraField,
	};
};

const postOptions = {
	...deprecatedDefaultOptions,
	method: 'POST',
} as const;

export const saveRemoteIssueLink = (
	baseUrl: BaseUrl,
	thisIssueKey: IssueKey,
	remoteLink: SaveRemoteIssueLinkPayload,
) => {
	if (fg('add_observability_headers_to_fetch_default_options')) {
		return fetchJson$(getSaveRemoteIssueLinksUrl(baseUrl, thisIssueKey), {
			method: 'POST',
			body: JSON.stringify(getPostBody(remoteLink)),
		});
	}

	return fetchJson$(getSaveRemoteIssueLinksUrl(baseUrl, thisIssueKey), {
		...postOptions,
		body: JSON.stringify(getPostBody(remoteLink)),
	});
};
