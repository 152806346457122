import { useState } from 'react';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import type {
	BaseUrl,
	ProjectKey,
	IssueKey,
	CloudId,
} from '@atlassian/jira-shared-types/src/general.tsx';
import {
	STATUS_FIELD_FETCH_VIEW_WORKFLOW,
	PERMISSION_VIEW_READONLY_WORKFLOW,
	PERMISSION_ADMINISTER,
} from '../../../common/constants.tsx';
import { useFetchWorkflowServiceData } from '../use-workflow-relay-query/index.tsx';
import type { ServiceApi, ViewWorkflowData, ViewWorkflowServiceGraphQL } from './types.tsx';
import { transform } from './utils.tsx';

export type Props = {
	issueKey: IssueKey;
	// remove props below when ff relay-migration-issue-fields-status clean up
	isSimplified?: boolean;
	baseUrl: BaseUrl;
	projectKey?: ProjectKey;
	cloudId: CloudId;
};

export const graphQlUrl = (): string => '/rest/gira/latest/?operation=nextGenViewWorkflowQuery';

const optionsGraphQlQuery = (issueKey: IssueKey, cloudId: CloudId, projectKey?: ProjectKey) => `
    query nextGenViewWorkflowQuery {
        permissions(issueKey:"${issueKey}", keys:["${PERMISSION_VIEW_READONLY_WORKFLOW}", "${PERMISSION_ADMINISTER}"]) {
            key
            havePermission
        }
        ${
					projectKey
						? `project(projectKey: "${projectKey}") {
            projectId
            simplified
            type
        }`
						: ''
				}
        jira {
            issueByKey(key: "${issueKey}", cloudId: "${cloudId}") {
                fieldsById(ids: ["issuetype"]) {
                    edges {
                        node {
                            ... on JiraIssueTypeField {
                                fieldId
                                issueType {
                                issueTypeId
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const getViewWorkflowService: ViewWorkflowServiceGraphQL = (
	baseUrl,
	issueKey,
	cloudId,
	projectKey,
	isSimplified,
) =>
	fetchJson(graphQlUrl(), {
		method: 'POST',
		body: JSON.stringify({ query: optionsGraphQlQuery(issueKey, cloudId, projectKey) }),
	}).then((data) => transform(data, isSimplified));

export const useViewWorkflowData = ({
	baseUrl,
	projectKey,
	issueKey,
	isSimplified,
	cloudId,
}: Props): ServiceApi => {
	const [dataState, setDataState] = useState<ViewWorkflowData | undefined>(undefined);
	const [errorState, setErrorState] = useState<FetchError | undefined>(undefined);
	const [loadingState, setLoadingState] = useState<boolean>(false);

	const { fetchWorkflowServiceData } = fg('relay-migration-issue-fields-status')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useFetchWorkflowServiceData({ issueKey })
		: {
				fetchWorkflowServiceData: () => undefined,
			};

	const fetch = async () => {
		request();
		try {
			let dataRes: ViewWorkflowData | undefined;
			if (fg('relay-migration-issue-fields-status')) {
				dataRes = await fetchWorkflowServiceData();
			} else {
				dataRes = await getViewWorkflowService(
					baseUrl,
					issueKey,
					cloudId,
					projectKey,
					isSimplified,
				);
			}
			success(dataRes);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (ex: any) {
			log.safeErrorWithoutCustomerData(STATUS_FIELD_FETCH_VIEW_WORKFLOW, ex);
			failure(ex);
		}
	};

	const request = () => {
		setDataState(undefined);
		setErrorState(undefined);
		setLoadingState(true);
	};

	const success = (data?: ViewWorkflowData) => {
		setDataState(data);
		setErrorState(undefined);
		setLoadingState(false);
	};

	const failure = (error: FetchError) => {
		setDataState(undefined);
		setErrorState(error);
		setLoadingState(false);
	};

	return { data: dataState, error: errorState, loading: loadingState, fetch };
};
