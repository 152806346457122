import React from 'react';

export const checkboxGlyph = () => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3 2H13C13.5523 2 14 2.44772 14 3V13C14 13.5523 13.5523 14 13 14H3C2.44772 14 2 13.5523 2 13V3C2 2.44772 2.44772 2 3 2ZM0 3C0 1.34315 1.34315 0 3 0H13C14.6569 0 16 1.34315 16 3V13C16 14.6569 14.6569 16 13 16H3C1.34315 16 0 14.6569 0 13V3ZM5.71929 7.30529C5.62704 7.20978 5.5167 7.1336 5.39469 7.08119C5.27269 7.02878 5.14147 7.00119 5.00869 7.00004C4.87591 6.99888 4.74423 7.02419 4.62133 7.07447C4.49844 7.12475 4.38679 7.199 4.29289 7.29289C4.199 7.38679 4.12475 7.49844 4.07447 7.62133C4.02419 7.74423 3.99888 7.87591 4.00004 8.00869C4.00119 8.14147 4.02878 8.27269 4.08119 8.39469C4.1336 8.5167 4.20978 8.62704 4.30529 8.71929L6.30529 10.7193C6.49282 10.9068 6.74712 11.0121 7.01229 11.0121C7.27745 11.0121 7.53176 10.9068 7.71929 10.7193L11.7193 6.71929C11.8148 6.62704 11.891 6.5167 11.9434 6.39469C11.9958 6.27269 12.0234 6.14147 12.0245 6.00869C12.0257 5.87591 12.0004 5.74423 11.9501 5.62133C11.8998 5.49844 11.8256 5.38679 11.7317 5.29289C11.6378 5.199 11.5261 5.12475 11.4032 5.07447C11.2803 5.02419 11.1487 4.99888 11.0159 5.00004C10.8831 5.00119 10.7519 5.02878 10.6299 5.08119C10.5079 5.1336 10.3975 5.20978 10.3053 5.30529L7.01229 8.59829L5.71929 7.30529Z"
			fill="currentColor"
		/>
	</svg>
);
