import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	many: {
		id: 'development-summary-feature-flag.summary.flags-description.many',
		defaultMessage: '{flagsCnt} feature flags',
		description:
			"The message indicating the number of feature flags associated with this issue. 'Feature Flag' is a technical term that is most often not translated. It is acceptable to transliterate this term to another alphabet/script.",
	},
});
