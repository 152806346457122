import React from 'react';
import isValidEmailAddress from '@atlassian/jira-common-is-valid-email-address/src/index.tsx';
import prefixUrl from '@atlassian/jira-common-util-prefix-url/src/index.tsx';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { LazySingleLineSmartLink } from '@atlassian/jira-issue-field-smart-link/src/ui/single-line-smart-link/async.tsx';

// eslint-disable-next-line no-script-url
const forbiddenScheme = 'javascript:';

export const formatReadValue = (value: string) => {
	if (value.toString().startsWith(forbiddenScheme)) {
		return value;
	}
	if (isValidEmailAddress(value)) {
		if (expValEquals('jsw_perf_format-links-in-custom-fields', 'issue-view', true)) {
			return (
				<LazySingleLineSmartLink
					anchorTarget="_blank"
					url={`mailto:${value}`}
					text={value}
					fieldType="url"
				/>
			);
		}

		return <a href={`mailto:${value}`}>{value}</a>;
	}

	if (expValEquals('jsw_perf_format-links-in-custom-fields', 'issue-view', true)) {
		return (
			<LazySingleLineSmartLink anchorTarget="_blank" url={prefixUrl(value, '//')} fieldType="url" />
		);
	}

	return (
		<a href={prefixUrl(value, '//')} target="_blank">
			{value}
		</a>
	);
};
