import { useEffect } from 'react';
import type { Store } from 'redux';
import { useIssueId, useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { State as IssueState } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { getFieldType } from '@atlassian/jira-issue-view-common-utils/src/fields/index.tsx';
import { ChangeEventTypes } from '@atlassian/jira-issue-view-field-update-events/src/services/issue-view-field-update-events/constants.tsx';
import { useIssueViewFieldUpdateEvents } from '@atlassian/jira-issue-view-field-update-events/src/services/issue-view-field-update-events/index.tsx';
import { useSoftRefresh } from './use-soft-refresh/index.tsx';
import { getSoftRefreshSupportedFields } from './utils.tsx';

export const useFieldChangeSoftRefreshSync = (store: Store<IssueState>) => {
	const [, { registerEventHandler }] = useIssueViewFieldUpdateEvents();
	const issueId = useIssueId();
	const issueKey = useIssueKey();

	const { onSoftRefresh } = useSoftRefresh();

	useEffect(() => {
		const unregister = registerEventHandler('onChange', (event) => {
			if (event.type !== ChangeEventTypes.FIELD_CHANGED || event.issueId !== issueId) {
				return;
			}
			const field = store.getState()?.fields[event.fieldId];
			const fieldType = getFieldType(field);
			if (fieldType && getSoftRefreshSupportedFields().includes(fieldType)) {
				onSoftRefresh(issueKey, fieldType);
			}
		});

		return unregister;
	}, [registerEventHandler, store, issueId, issueKey, onSoftRefresh]);

	return null;
};
