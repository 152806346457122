import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	rolloutText: {
		id: 'development-summary-feature-flag.summary.status.tooltip.rollout-text',
		defaultMessage: '{text}',
		description:
			'The tool tip message is a user provided value and having this redundant message avoids flow issues with null or undefined from the rollout.text object',
	},
	rolloutPercentage: {
		id: 'development-summary-feature-flag.summary.status.tooltip.rollout-percentage',
		defaultMessage: '{percentage}%',
		description:
			'The tool tip message indicating the % of audience receiving the on value from the feature flag',
	},
	rolloutSingleRule: {
		id: 'development-summary-feature-flag.summary.status.tooltip.rollout-single-rule',
		defaultMessage: '1 Rule',
		description: 'The tool tip message indicating 1 rule has been enabled for the flag.',
	},
	rolloutMultipleRules: {
		id: 'development-summary-feature-flag.summary.status.tooltip.rollout-multiple-rules',
		defaultMessage: '{rulesCnt} Rules',
		description: 'The tool tip message indicating x number of rules have been enabled for the flag',
	},
	singleFeatureFlagOff: {
		id: 'development-summary-feature-flag.summary.status.tooltip.single-feature-flag-off',
		defaultMessage: 'Feature flag off',
		description:
			"The tool tip message indicating that the feature flag is currently turned off. 'Feature Flag' is a technical term that is most often not translated. It is acceptable to transliterate this term to another alphabet/script.",
	},
	multipleFeatureFlagsAllOn: {
		id: 'development-summary-feature-flag.summary.status.tooltip.multiple-feature-flags-all-on',
		defaultMessage: 'All feature flags on',
		description:
			"The tool tip message indicating that all feature flags associated with the issue are currently turned on. 'Feature Flag' is a technical term that is most often not translated. It is acceptable to transliterate this term to another alphabet/script.",
	},
	multipleFeatureFlagsSomeOn: {
		id: 'development-summary-feature-flag.summary.status.tooltip.multiple-feature-flags-some-on',
		defaultMessage: '{onCnt} of {offCnt} feature flags on',
		description:
			"The tool tip message indicating that a variable number of feature flags associated with the issue are currently turned on. 'Feature Flag' is a technical term that is most often not translated. It is acceptable to transliterate this term to another alphabet/script.",
	},
	multipleFeatureFlagsAllOff: {
		id: 'development-summary-feature-flag.summary.status.tooltip.multiple-feature-flags-all-off',
		defaultMessage: 'All feature flags off',
		description:
			"The tool tip message indicating that all feature flags associated with the issue are currently turned off. 'Feature Flag' is a technical term that is most often not translated. It is acceptable to transliterate this term to another alphabet/script.",
	},
});
