import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	placeholder: {
		id: 'issue-field-project.placeholder',
		defaultMessage: 'Select project',
		description: 'Placeholder for project edit view',
	},
	errorTitle: {
		id: 'issue-field-project.error-title',
		defaultMessage: "We couldn't set the project value",
		description: 'Error message informing the user that updating the field failed',
	},
	errorMessage: {
		id: 'issue-field-project.error-message',
		defaultMessage: 'Check your connection, then give it another try.',
		description: 'Error message when save fails',
	},
});
