import React, { useMemo, type FC } from 'react';
import { graphql, useFragment } from 'react-relay';
import { fg } from '@atlassian/jira-feature-gating';
import type { readOnlyCustomDetail_servicedeskCustomerServiceCustomDetails$key } from '@atlassian/jira-relay/src/__generated__/readOnlyCustomDetail_servicedeskCustomerServiceCustomDetails.graphql';
import { BooleanFieldReadView } from '../../common/ui/field/boolean-field/read-view/index.tsx';
import { DateFieldReadView } from '../../common/ui/field/date-field/read-view/index.tsx';
import { EmailFieldReadView } from '../../common/ui/field/email-field/read-view/index.tsx';
import { NumberFieldReadView } from '../../common/ui/field/number-field/read-view/index.tsx';
import { PhoneNumberFieldReadView } from '../../common/ui/field/phone-number-field/read-view/index.tsx';
import { MultiSelectFieldReadView } from '../../common/ui/field/select-field/multi/read-view/index.tsx';
import { SingleSelectFieldReadView } from '../../common/ui/field/select-field/single/read-view/index.tsx';
import { TextFieldReadView } from '../../common/ui/field/text-field/read-view/index.tsx';
import { UrlFieldReadView } from '../../common/ui/field/url-field/read-view/index.tsx';
import { DefaultLayout } from '../../common/ui/layout/default/index.tsx';

export const ReadOnlyCustomDetail: FC<{
	customDetailRef: readOnlyCustomDetail_servicedeskCustomerServiceCustomDetails$key;
	isInline?: boolean;
	popup?: {
		renderContent: () => React.ReactNode;
	};
	Layout?: typeof DefaultLayout;
	testId?: string;
}> = ({ customDetailRef, isInline, popup, Layout = DefaultLayout, testId }) => {
	const data = useFragment(
		graphql`
			fragment readOnlyCustomDetail_servicedeskCustomerServiceCustomDetails on CustomerServiceCustomDetailValue {
				id
				name @required(action: THROW)
				value
				values
				type {
					name @required(action: THROW)
					options
				}
				config {
					styleConfiguration {
						options {
							optionValue
							style {
								backgroundColour
							}
						}
					}
				}
			}
		`,
		customDetailRef,
	);
	const { id, name, value, values, type, config } = data;

	const CustomDetailField = useMemo(() => {
		switch (type.name) {
			case 'TEXT':
				return TextFieldReadView;
			case 'NUMBER':
				return NumberFieldReadView;
			case 'EMAIL':
				return EmailFieldReadView;
			case 'URL':
				return UrlFieldReadView;
			case 'DATE':
				return DateFieldReadView;
			case 'PHONE':
				return PhoneNumberFieldReadView;
			case 'BOOLEAN':
				return BooleanFieldReadView;
			case 'SELECT':
				return SingleSelectFieldReadView;
			case 'MULTISELECT':
				return MultiSelectFieldReadView;
			default:
				return null;
		}
	}, [type.name]);

	if (!CustomDetailField) {
		return <></>;
	}

	const fieldId = `custom-detail-${id}`;

	const formattedOptions = type.options?.map((option) => {
		const color = config?.styleConfiguration?.options?.find(
			({ optionValue }) => optionValue === option,
		)?.style?.backgroundColour;
		return {
			label: option,
			value: option,
			color,
		};
	});

	return (
		<Layout
			isInline={isInline}
			id={type.name === 'BOOLEAN' && fg('jsm-cs-ally-fixes') ? fieldId : undefined}
			popup={popup}
			label={name}
			testId={testId}
		>
			{({ getValueContainer }) => (
				<CustomDetailField
					id={fieldId}
					value={value}
					values={values}
					formattedOptions={formattedOptions}
					getValueContainer={getValueContainer}
				/>
			)}
		</Layout>
	);
};
