import React from 'react';
import type { CustomGlyphProps } from '@atlaskit/icon';

export const scalesGlyph = (props: CustomGlyphProps) => (
	<svg
		{...props}
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M2.6154 15.282C2.47407 15.6226 2.0834 15.7826 1.7434 15.6413C1.4034 15.4993 1.24273 15.1093 1.38407 14.7693L4.46207 6.41064C4.6034 6.07064 4.99407 5.90997 5.3334 6.0513C5.6734 6.1933 5.83407 6.5833 5.69273 6.9233L2.6154 15.282Z"
			fill="currentColor"
		/>
		<path
			d="M9.28191 14.7693C9.42324 15.1093 9.26258 15.4993 8.92258 15.6413C8.58258 15.7826 8.19258 15.6226 8.05058 15.282L4.97391 6.92331C4.83258 6.58331 4.99324 6.19331 5.33324 6.05131C5.67324 5.90998 6.06324 6.07065 6.20524 6.41065L9.28191 14.7693ZM15.9492 15.282C15.8072 15.6226 15.4166 15.7826 15.0766 15.6413C14.7366 15.4993 14.5766 15.1093 14.7179 14.7693L17.7952 6.40998C17.9366 6.07065 18.3266 5.90998 18.6666 6.05131C19.0066 6.19331 19.1679 6.58331 19.0259 6.92331L15.9492 15.282V15.282Z"
			fill="currentColor"
		/>
		<path
			d="M22.6154 14.7693C22.7568 15.1093 22.5968 15.4993 22.2568 15.6413C21.9168 15.7826 21.5261 15.6226 21.3841 15.282L18.3074 6.9233C18.1654 6.5833 18.3268 6.1933 18.6668 6.0513C19.0068 5.90997 19.3968 6.07064 19.5381 6.41064L22.6154 14.7693V14.7693Z"
			fill="currentColor"
		/>
		<path
			d="M9.90467 14.6667H0.762C0.341333 14.6667 0 15.0074 0 15.4287C0 17.1114 2.388 18.4761 5.33333 18.4761C8.27867 18.4761 10.6667 17.1114 10.6667 15.4287C10.6667 15.0074 10.3253 14.6667 9.90467 14.6667ZM16 22.6667H8C7.632 22.6667 7.33333 22.3687 7.33333 22.0001C7.33333 20.5274 9.42267 19.3334 12 19.3334C14.5773 19.3334 16.6667 20.5274 16.6667 22.0001C16.6667 22.3687 16.3687 22.6667 16 22.6667ZM23.238 14.6667H14.0953C13.674 14.6667 13.3333 15.0074 13.3333 15.4287C13.3333 17.1114 15.7207 18.4761 18.6667 18.4761C21.6127 18.4761 24 17.1114 24 15.4287C24 15.0074 23.6593 14.6667 23.238 14.6667Z"
			fill="currentColor"
		/>
		<path
			d="M12.6666 1.99992C12.6666 1.63192 12.3686 1.33325 11.9999 1.33325C11.6319 1.33325 11.3333 1.63192 11.3333 1.99992V19.9999C11.3333 20.3686 11.6319 20.6666 11.9999 20.6666C12.3686 20.6666 12.6666 20.3686 12.6666 19.9999V1.99992Z"
			fill="currentColor"
		/>
		<path
			d="M12.0001 3.99992C12.7365 3.99992 13.3334 3.40296 13.3334 2.66659C13.3334 1.93021 12.7365 1.33325 12.0001 1.33325C11.2637 1.33325 10.6667 1.93021 10.6667 2.66659C10.6667 3.40296 11.2637 3.99992 12.0001 3.99992Z"
			fill="currentColor"
		/>
		<path
			d="M5.33333 7.99992C6.06971 7.99992 6.66667 7.40296 6.66667 6.66659C6.66667 5.93021 6.06971 5.33325 5.33333 5.33325C4.59695 5.33325 4 5.93021 4 6.66659C4 7.40296 4.59695 7.99992 5.33333 7.99992Z"
			fill="currentColor"
		/>
		<path
			d="M18.6666 7.99992C19.403 7.99992 19.9999 7.40296 19.9999 6.66659C19.9999 5.93021 19.403 5.33325 18.6666 5.33325C17.9302 5.33325 17.3333 5.93021 17.3333 6.66659C17.3333 7.40296 17.9302 7.99992 18.6666 7.99992Z"
			fill="currentColor"
		/>
		<path
			d="M18.6666 6.66667C18.6666 7.40267 18.6666 6.66667 11.9999 6.66667C5.33325 6.66667 5.33325 7.40267 5.33325 6.66667C5.33325 5.93067 7.33325 4 11.9999 4C16.6666 4 18.6666 5.93067 18.6666 6.66667Z"
			fill="currentColor"
		/>
	</svg>
);
