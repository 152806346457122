import { Slice } from '@atlaskit/editor-prosemirror/model';
import { ReplaceStep, Step, StepMap, StepResult } from '@atlaskit/editor-prosemirror/transform';
export const stepType = 'editor-linking-meta';
export const invertStepType = 'editor-linking-meta-invert';
/**
 * Custom Prosemirror Step to attach metadata about user interactions with links
 * Using a Step means that it will work with prosemirror-history and we get utilise when
 * firing events on history change
 */
export class LinkMetaStep extends Step {
  constructor(pos, metadata, isInverted = false) {
    super();
    this.pos = pos;
    this.metadata = metadata;
    this.isInverted = isInverted;
  }
  getMetadata() {
    return this.metadata;
  }

  /**
   * Generate new undo/redo analytics event when step is inverted
   */
  invert() {
    /**
     * Omit sourceEvent in history
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {
      sourceEvent,
      ...metadata
    } = this.metadata;
    return new LinkMetaStep(this.pos, metadata, true);
  }

  // Should make no modifications to the doc
  apply(doc) {
    return StepResult.ok(doc);
  }
  map(mapping) {
    let newPos = this.pos;
    if (typeof newPos === 'number') {
      newPos = mapping.map(newPos);
    }
    // Return the same events, this step will never be removed
    return new LinkMetaStep(newPos, this.metadata, this.isInverted);
  }
  getMap() {
    return new StepMap([this.pos || 0, 0, 0]);
  }

  // Return null to avoid merging events
  merge() {
    return null;
  }
  toJSON() {
    // When serialized we should create a noop Replace step
    return {
      stepType: 'replace',
      from: 0,
      to: 0
    };
  }
  static fromJSON() {
    // This is a "local custom step" once serialized
    // we need to transform it in a no-operation action
    return new ReplaceStep(0, 0, Slice.empty);
  }
}

/** Register this step with Prosemirror */
Step.jsonID(stepType, LinkMetaStep);