// THIS FILE IS GENERATED via packages/editor/editor-plugins/scripts/update-editor-plugins.ts. DO NOT MODIFY IT MANUALLY.
// Disable no-re-export rule for entry point files
/* eslint-disable @atlaskit/editor/no-re-export */
export { floatingToolbarPlugin } from '@atlaskit/editor-plugin-floating-toolbar';
export type {
	FloatingToolbarPlugin,
	ForceFocusSelector,
	ConfigWithNodeInfo,
	FloatingToolbarPluginData,
	FloatingToolbarPluginState,
} from '@atlaskit/editor-plugin-floating-toolbar';
