import React from 'react';
import { useResourceWithCustomRouterContext } from '@atlassian/jira-resource-with-custom-router-context/src/controllers/use-resource-with-custom-router-context/index.tsx';
import { aiContextOpsPanelResource } from '@atlassian/jira-servicedesk-ai-context-ops-panel-resources/src/services/index.tsx';
import { AIContextOpsPanel } from '@atlassian/jira-servicedesk-ai-context-ops-panel/src/ui/index.tsx';

import { useRefreshOnIssueIdChange } from '../../../controllers/use-refresh-on-issue-id-change/index.tsx';
import type { DummyRoute, DummyRouteMatchParams } from './types.tsx';

type Props = {
	issueId: string;
	projectId: string;
};

// Check the comments in the implementation of useResourceWithCustomRouterContext hook for more detail about this dummy route
const DUMMY_ROUTE_WITH_ISSUE_ID: DummyRoute = {
	name: 'SERVICEDESK_AI_CONTEXT_OPS_PANEL_DUMMY_ROUTE_NAME',
	path: '/servicedesk-ai-context-panel-dummy-route/:projectId/:issueId',
};

export const AIContextOpsPanelWithPrefetchedResource = ({ issueId, projectId }: Props) => {
	const resource = useResourceWithCustomRouterContext(
		aiContextOpsPanelResource,
		{
			route: DUMMY_ROUTE_WITH_ISSUE_ID,
			matchParams: { projectId, issueId } satisfies DummyRouteMatchParams,
		},
		{
			shouldPrefetch: true,
		},
	);

	useRefreshOnIssueIdChange({ issueId, refreshResource: resource.refresh });

	return <AIContextOpsPanel resource={resource} />;
};
