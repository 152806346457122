import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	ok: {
		id: 'issue-view-foundation.quick-add.add-form-button.add-form-spotlight.ok',
		defaultMessage: 'OK',
		description: 'Proforma issue view add form changeboarding spotlight button text.',
	},
	message: {
		id: 'issue-view-foundation.quick-add.add-form-button.add-form-spotlight.message',
		defaultMessage:
			'Attach forms to an issue so you can collect more information from customers, or complete internal team processes.',
		description: 'Proforma issue view add form changeboarding spotlight text',
	},
	messageIssueTermRefresh: {
		id: 'issue-view-foundation.quick-add.add-form-button.add-form-spotlight.message-issue-term-refresh',
		defaultMessage:
			'Attach forms to an issue so you can collect more information from customers, or complete internal team processes.',
		description: 'Proforma issue view add form changeboarding spotlight text',
	},
});

export default messages;
