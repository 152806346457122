import type { Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import type { DynamicFieldFormula, FieldVisitor } from '../types.tsx';
import { FIELD_TEMPLATE, type FieldFormula } from './types.tsx';

export const field = (id: Ari, normalize?: boolean): FieldFormula => ({
	template: FIELD_TEMPLATE,
	parameters: { id, normalize },
});

export const isField = (formula: DynamicFieldFormula): boolean =>
	formula.template === FIELD_TEMPLATE;

export const fieldVisitFields = (
	formula: DynamicFieldFormula,
	visitField: FieldVisitor,
): boolean => {
	if (formula.template !== FIELD_TEMPLATE) {
		return false;
	}
	visitField(formula.parameters.id);
	return true;
};
