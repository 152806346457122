import { JiraIssueFieldOptionAri } from '@atlassian/ari/jira/issue-field-option';
import type {
	AllowedValuesOverrides,
	FieldOptionsFilter,
	OptionsVisibility,
	PublicToInternalPropertyTransformerContext,
} from '@atlassian/jira-issue-adjustments/src/common.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { createAri } from '@atlassian/jira-platform-ari/src/index.tsx';
import { isAriResourceIdValid } from '../is-ari-resource-id-valid/index.tsx';
import { isNotEmptyString } from '../is-not-empty-string/index.tsx';
import { isObject } from '../is-object/index.tsx';

export const transformSelectSetOptionsVisibility = ({
	isVisible,
	options,
}: OptionsVisibility): AllowedValuesOverrides => ({
	operation: isVisible ? 'ALLOW' : 'EXCLUDE',
	ids: options,
});

export const transformSetOptionsVisibilityForFrontendFilteringWithARIs = (
	{ isVisible, options }: OptionsVisibility,
	_lookupValues: undefined,
	context: PublicToInternalPropertyTransformerContext,
): AllowedValuesOverrides => ({
	operation: isVisible ? 'ALLOW' : 'EXCLUDE',
	ids: transformOptionIdsToARI(options, context),
});

/**
 * @deprecated
 * This function is deprecated and will be removed in the future.
 * It is only used in issue view. However, we should not use it for any new fields
 * remove this function with the clean-up of `uim-use-new-options-visibility-transformers` FG
 */
const transformOptionIdsToAriIssueView = (
	optionIds: string[],
	{ tenantContext, fieldContext }: PublicToInternalPropertyTransformerContext,
) =>
	optionIds.map((optionId) => {
		const resourceId = `${fieldContext.fieldId}/${optionId}`;
		return createAri({
			resourceOwner: 'jira',
			cloudId: tenantContext.cloudId,
			resourceType: 'issue-field-option',
			activationId: tenantContext.activationId,
			resourceId,
		});
	});

/**
 * @deprecated
 * This function is deprecated and will be removed in the future.
 * It is only used in issue view. However, we should not use it for any new fields
 * remove this function with the clean-up of `uim-use-new-options-visibility-transformers` FG
 */
export const transformSetOptionsVisibilityAriIssueView = (
	{ isVisible, options }: OptionsVisibility,
	_lookupValues: undefined,
	context: PublicToInternalPropertyTransformerContext,
): FieldOptionsFilter => ({
	operation: isVisible ? 'ALLOW' : 'EXCLUDE',
	optionIds: transformOptionIdsToAriIssueView(options, context),
});

export const transformOptionIdsToARI = (
	optionIds: string[],
	{ tenantContext, fieldContext }: PublicToInternalPropertyTransformerContext,
) =>
	fg('uim-use-new-options-visibility-transformers')
		? optionIds.reduce<string[]>((acc, optionId) => {
				try {
					const optionAri = JiraIssueFieldOptionAri.create({
						siteId: tenantContext.cloudId,
						activationId: tenantContext.activationId,
						fieldId: fieldContext.fieldId,
						optionId,
					}).toString();

					acc.push(optionAri);
				} catch (e) {
					// do nothing
				}

				return acc;
			}, [])
		: optionIds.map((optionId) =>
				JiraIssueFieldOptionAri.create({
					siteId: tenantContext.cloudId,
					activationId: tenantContext.activationId,
					fieldId: fieldContext.fieldId,
					optionId,
				}).toString(),
			);

export const transformSetOptionsVisibilityAri = (
	{ isVisible, options }: OptionsVisibility,
	_lookupValues: undefined,
	context: PublicToInternalPropertyTransformerContext,
): FieldOptionsFilter => ({
	operation: isVisible ? 'ALLOW' : 'EXCLUDE',
	optionIds: transformOptionIdsToARI(options, context),
});

// transformer is called on FieldPublicShape type
export const transformSelectGetOptionsVisibility = ({
	isVisible,
	options,
}: OptionsVisibility): OptionsVisibility => ({
	isVisible,
	options,
});

export const validateSetOptionsVisibilityRule = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	optionsVisibility: any,
): optionsVisibility is OptionsVisibility =>
	isObject(optionsVisibility) &&
	typeof optionsVisibility.isVisible === 'boolean' &&
	Array.isArray(optionsVisibility.options) &&
	optionsVisibility.options.every(isNotEmptyString);

export const optionsVisibilityContainNoMoreThan100Items = (optionsVisibility: OptionsVisibility) =>
	optionsVisibility.options.length <= 100;

export const validateSetOptionsVisibility = (optionsVisibility: OptionsVisibility): boolean =>
	validateSetOptionsVisibilityRule(optionsVisibility) &&
	optionsVisibilityContainNoMoreThan100Items(optionsVisibility);

export const validateSetOptionsVisibilityAri = (optionsVisibility: OptionsVisibility): boolean =>
	validateSetOptionsVisibility(optionsVisibility) &&
	optionsVisibility.options.every((optionId) => isAriResourceIdValid(optionId));
