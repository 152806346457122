/**
 * @generated SignedSource<<214891217be8bc36e19651e3c9c2c329>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type src_issueViewWatchers_WatchersAppWithRelay$data = {
  readonly watch: {
    readonly count: AGG$Long | null | undefined;
    readonly isWatching: boolean | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"keyboardShortcuts_issueViewWatchers_KeyboardShortcutsRelay" | "view_issueViewWatchers_ActionIcon">;
  readonly " $fragmentType": "src_issueViewWatchers_WatchersAppWithRelay";
};
export type src_issueViewWatchers_WatchersAppWithRelay$key = {
  readonly " $data"?: src_issueViewWatchers_WatchersAppWithRelay$data;
  readonly " $fragmentSpreads": FragmentRefs<"src_issueViewWatchers_WatchersAppWithRelay">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "src_issueViewWatchers_WatchersAppWithRelay",
  "selections": [
    {
      "concreteType": "JiraWatch",
      "kind": "LinkedField",
      "name": "watch",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isWatching"
        },
        {
          "kind": "ScalarField",
          "name": "count"
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "view_issueViewWatchers_ActionIcon"
    },
    {
      "kind": "FragmentSpread",
      "name": "keyboardShortcuts_issueViewWatchers_KeyboardShortcutsRelay"
    }
  ],
  "type": "JiraWatchesField"
};

(node as any).hash = "be0824a6a37bdf0ef31cf12d408773c0";

export default node;
