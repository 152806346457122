import React from 'react';
import type { CustomGlyphProps } from '@atlaskit/icon';

export const commentGlyph = (props: CustomGlyphProps) => (
	<svg {...props} width="16" height="16" viewBox="4 4 16 16" fill="none" focusable={false}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M5.776 11.6782C5.776 8.97778 8.568 6.78044 12 6.78044C15.432 6.78044 18.224 8.97778 18.224 11.6782C18.224 14.3796 15.432 16.576 12 16.576C8.568 16.576 5.776 14.3796 5.776 11.6782ZM18.9671 18.5858V18.584C18.9671 18.584 17.5769 16.5751 18.2853 15.8142L18.2524 15.832C19.3431 14.6907 20 13.248 20 11.6782C20 7.99644 16.4116 5 12 5C7.58844 5 4 7.99556 4 11.6782C4 15.3609 7.58844 18.3573 12 18.3573C13.2622 18.3573 14.4533 18.104 15.5173 17.6676C16.4089 18.576 17.5502 19.0649 18.3973 19.1956L18.3991 19.1929C18.4458 19.2104 18.495 19.2203 18.5449 19.2222C18.6205 19.2222 18.6949 19.2035 18.7615 19.1677C18.8281 19.1319 18.8847 19.0802 18.9265 19.0171C18.9682 18.9541 18.9937 18.8817 19.0006 18.8065C19.0075 18.7312 18.9966 18.6554 18.9671 18.5858Z"
			fill="currentColor"
		/>
		<path
			d="M15.5556 9.44444H8.44444C7.95352 9.44444 7.55556 9.84241 7.55556 10.3333C7.55556 10.8243 7.95352 11.2222 8.44444 11.2222H15.5556C16.0465 11.2222 16.4444 10.8243 16.4444 10.3333C16.4444 9.84241 16.0465 9.44444 15.5556 9.44444Z"
			fill="currentColor"
		/>
		<path
			d="M11.1111 12.1111H8.44444C7.95352 12.1111 7.55556 12.5091 7.55556 13C7.55556 13.4909 7.95352 13.8889 8.44444 13.8889H11.1111C11.602 13.8889 12 13.4909 12 13C12 12.5091 11.602 12.1111 11.1111 12.1111Z"
			fill="currentColor"
		/>
	</svg>
);
