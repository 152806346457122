import { createSelector } from 'reselect';
import {
	type AnalyticsSource,
	MOBILE_ISSUE,
} from '@atlassian/jira-common-constants/src/analytics-sources.tsx';
import type { ContextState } from '@atlassian/jira-issue-view-common-types/src/context-type.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';

export const contextSelector = (state: State): ContextState => state.context;

/**
 *  @deprecated - use useAnalyticsSource instead.
 */
export const analyticsSourceSelector = createSelector(contextSelector, (context: ContextState) =>
	context.analyticsSource !== undefined ? context.analyticsSource : undefined,
);

export const isMobileSelector = createSelector(
	// @ts-expect-error - TS2769 - No overload matches this call.
	analyticsSourceSelector,
	(analyticsSource?: AnalyticsSource): boolean => analyticsSource === MOBILE_ISSUE,
);

export const issueActionsSelector = createSelector(
	contextSelector,
	(context: ContextState): string[] => context.issueActions || [],
);
