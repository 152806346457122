import type {
	EcosystemContentPanel,
	GlanceStatus,
} from '@atlassian/jira-issue-gira-transformer-types/src/common/types/ecosystem.tsx';

export const glanceAnalyticsData = (
	appKey: string,
	moduleKey: string,
	status: GlanceStatus | null,
) => {
	const statusType = (status && status.type) ?? '';

	return {
		appKey,
		moduleKey,
		statusType,
	};
};

export const contentPanelAnalyticsOnLoad = (
	contentPanels: EcosystemContentPanel[],
	panelsOnPage: string[],
) => {
	let totalOnPage = 0;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const data = contentPanels.reduce<Record<string, any>>((acc, contentPanel) => {
		if (!acc[contentPanel.appKey]) {
			acc[contentPanel.appKey] = {
				total: 0,
				totalOnPage: 0,
			};
		}

		const current = acc[contentPanel.appKey];
		current.total += 1;

		const extId = `${contentPanel.appKey}_${contentPanel.moduleKey}`;
		if (panelsOnPage.includes(extId)) {
			current.totalOnPage += 1;
			totalOnPage += 1;
		}

		return acc;
	}, {});

	const safeData = JSON.stringify(data);

	return {
		totalOnPage,
		total: contentPanels.length,
		data: safeData,
	};
};

export const contentPanelAnalyticsData = (appKey: string, moduleKey: string) => ({
	appKey,
	moduleKey,
});

export const toggleCollapsedStateData = (appKey: string, moduleKey: string, action: string) => ({
	appKey,
	moduleKey,
	event: action,
});

export const activityPanelAnalyticsData = (appKey: string, moduleKey: string) => ({
	appKey,
	moduleKey,
});
