import React, { useEffect } from 'react';
import type { UFOExperience } from '@atlassian/ufo';

export const ExperienceFailure = ({ experience }: { experience: UFOExperience }) => {
	useEffect(() => {
		experience.failure();
	}, [experience]);

	return <></>;
};
