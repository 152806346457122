import { styled } from '@compiled/react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SC1 = styled.div({
	width: '100%',
	display: 'flex',
	position: 'relative',
});
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export default SC1;
