import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	descriptionCustomer: {
		id: 'servicedesk-customer-service-issue-view-panel.common.details.error-state.description-customer',
		defaultMessage: 'We were unable to load customer details. Refresh the page to try again.',
		description:
			'Description text for the details panel dropdown error state. This error state is shown when un unexpected error occurs when loading the detail fields on the issue view.',
	},
	descriptionOrganization: {
		id: 'servicedesk-customer-service-issue-view-panel.common.details.error-state.description-organization',
		defaultMessage: 'We were unable to load organization details. Refresh the page to try again.',
		description:
			'Description text for the details panel dropdown error state. This error state is shown when un unexpected error occurs when loading the detail fields on the issue view.',
	},
	descriptionEntitlement: {
		id: 'servicedesk-customer-service-issue-view-panel.common.details.error-state.description-entitlement',
		defaultMessage: 'We were unable to load entitlement details. Refresh the page to try again.',
		description:
			'Description text for the details panel dropdown error state. This error state is shown when un unexpected error occurs when loading the detail fields on the issue view.',
	},
});
