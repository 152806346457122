import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	addAffectedServicesSuccess: {
		id: 'jira-servicedesk-ai-context-ops-panel.add-responders.success',
		defaultMessage:
			'Added {affectedServicesCount, plural, =0 {} one {# affected service} other {# affected services}} to the incident',
		description: 'Success toast message when user added Services to an issue.',
	},
});
