import parameters from '@atlassian/jira-relay/src/__generated__/view_DesignPanelContentQuery$parameters';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const designPanelContentEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-design-panel-content" */ './view/index.tsx'),
	),
	getPreloadProps: ({ siteAri, issueAri }: { siteAri: string; issueAri: string }) => ({
		queries: {
			designPanelContent: {
				options: {
					fetchPolicy: 'store-or-network' as const,
				},
				parameters,
				variables: {
					siteAri,
					issueAri,
				},
			},
		},
		extraProps: {
			issueAri,
		},
	}),
});
