// Generated from JQLLexer.g4 by ANTLR 4.7.3-SNAPSHOT

import { ATN } from 'antlr4ts/atn/ATN';
import { ATNDeserializer } from 'antlr4ts/atn/ATNDeserializer';
import { CharStream } from 'antlr4ts/CharStream';
import { Lexer } from 'antlr4ts/Lexer';
import { LexerATNSimulator } from 'antlr4ts/atn/LexerATNSimulator';
import { NotNull } from 'antlr4ts/Decorators';
import { Override } from 'antlr4ts/Decorators';
import { RuleContext } from 'antlr4ts/RuleContext';
import { Vocabulary } from 'antlr4ts/Vocabulary';
import { VocabularyImpl } from 'antlr4ts/VocabularyImpl';

import * as Utils from 'antlr4ts/misc/Utils';

export class JQLLexer extends Lexer {
  public static readonly LPAREN = 1;
  public static readonly RPAREN = 2;
  public static readonly COMMA = 3;
  public static readonly LBRACKET = 4;
  public static readonly RBRACKET = 5;
  public static readonly BANG = 6;
  public static readonly LT = 7;
  public static readonly GT = 8;
  public static readonly GTEQ = 9;
  public static readonly LTEQ = 10;
  public static readonly EQUALS = 11;
  public static readonly NOT_EQUALS = 12;
  public static readonly LIKE = 13;
  public static readonly NOT_LIKE = 14;
  public static readonly IN = 15;
  public static readonly IS = 16;
  public static readonly AND = 17;
  public static readonly OR = 18;
  public static readonly NOT = 19;
  public static readonly EMPTY = 20;
  public static readonly WAS = 21;
  public static readonly CHANGED = 22;
  public static readonly BEFORE = 23;
  public static readonly AFTER = 24;
  public static readonly FROM = 25;
  public static readonly TO = 26;
  public static readonly ON = 27;
  public static readonly DURING = 28;
  public static readonly ORDER = 29;
  public static readonly BY = 30;
  public static readonly ASC = 31;
  public static readonly DESC = 32;
  public static readonly POSNUMBER = 33;
  public static readonly NEGNUMBER = 34;
  public static readonly CUSTOMFIELD = 35;
  public static readonly STRING = 36;
  public static readonly QUOTE_STRING = 37;
  public static readonly SQUOTE_STRING = 38;
  public static readonly MATCHWS = 39;
  public static readonly ERROR_RESERVED = 40;
  public static readonly ERRORCHAR = 41;

  // tslint:disable:no-trailing-whitespace
  public static readonly channelNames: string[] = ['DEFAULT_TOKEN_CHANNEL', 'HIDDEN'];

  // tslint:disable:no-trailing-whitespace
  public static readonly modeNames: string[] = ['DEFAULT_MODE'];

  public static readonly ruleNames: string[] = [
    'LPAREN',
    'RPAREN',
    'COMMA',
    'LBRACKET',
    'RBRACKET',
    'MINUS',
    'BANG',
    'LT',
    'GT',
    'GTEQ',
    'LTEQ',
    'EQUALS',
    'NOT_EQUALS',
    'LIKE',
    'NOT_LIKE',
    'IN',
    'IS',
    'AND',
    'OR',
    'NOT',
    'EMPTY',
    'WAS',
    'CHANGED',
    'BEFORE',
    'AFTER',
    'FROM',
    'TO',
    'ON',
    'DURING',
    'ORDER',
    'BY',
    'ASC',
    'DESC',
    'POSNUMBER',
    'NEGNUMBER',
    'CUSTOMFIELD',
    'STRING',
    'QUOTE_STRING',
    'SQUOTE_STRING',
    'MATCHWS',
    'RESERVED_CHARS',
    'ERROR_RESERVED',
    'ERRORCHAR',
    'QUOTE',
    'SQUOTE',
    'BSLASH',
    'NL',
    'CR',
    'SPACE',
    'AMPER',
    'AMPER_AMPER',
    'PIPE',
    'PIPE_PIPE',
    'ESCAPE',
    'STRINGSTOP',
    'CONTROLCHARS',
    'NEWLINE',
    'HEXDIGIT',
    'WS',
    'DIGIT',
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z'
  ];

  private static readonly _LITERAL_NAMES: Array<string | undefined> = [
    undefined,
    "'('",
    "')'",
    "','",
    "'['",
    "']'",
    "'!'",
    "'<'",
    "'>'",
    "'>='",
    "'<='",
    "'='",
    "'!='",
    "'~'",
    "'!~'"
  ];
  private static readonly _SYMBOLIC_NAMES: Array<string | undefined> = [
    undefined,
    'LPAREN',
    'RPAREN',
    'COMMA',
    'LBRACKET',
    'RBRACKET',
    'BANG',
    'LT',
    'GT',
    'GTEQ',
    'LTEQ',
    'EQUALS',
    'NOT_EQUALS',
    'LIKE',
    'NOT_LIKE',
    'IN',
    'IS',
    'AND',
    'OR',
    'NOT',
    'EMPTY',
    'WAS',
    'CHANGED',
    'BEFORE',
    'AFTER',
    'FROM',
    'TO',
    'ON',
    'DURING',
    'ORDER',
    'BY',
    'ASC',
    'DESC',
    'POSNUMBER',
    'NEGNUMBER',
    'CUSTOMFIELD',
    'STRING',
    'QUOTE_STRING',
    'SQUOTE_STRING',
    'MATCHWS',
    'ERROR_RESERVED',
    'ERRORCHAR'
  ];
  public static readonly VOCABULARY: Vocabulary = new VocabularyImpl(
    JQLLexer._LITERAL_NAMES,
    JQLLexer._SYMBOLIC_NAMES,
    []
  );

  // @Override
  // @NotNull
  public get vocabulary(): Vocabulary {
    return JQLLexer.VOCABULARY;
  }
  // tslint:enable:no-trailing-whitespace

  constructor(input: CharStream) {
    super(input);
    this._interp = new LexerATNSimulator(JQLLexer._ATN, this);
  }

  // @Override
  public get grammarFileName(): string {
    return 'JQLLexer.g4';
  }

  // @Override
  public get ruleNames(): string[] {
    return JQLLexer.ruleNames;
  }

  // @Override
  public get serializedATN(): string {
    return JQLLexer._serializedATN;
  }

  // @Override
  public get channelNames(): string[] {
    return JQLLexer.channelNames;
  }

  // @Override
  public get modeNames(): string[] {
    return JQLLexer.modeNames;
  }

  public static readonly _serializedATN: string =
    '\x03\uC91D\uCABA\u058D\uAFBA\u4F53\u0607\uEA8B\uC241\x02+\u01EA\b\x01' +
    '\x04\x02\t\x02\x04\x03\t\x03\x04\x04\t\x04\x04\x05\t\x05\x04\x06\t\x06' +
    '\x04\x07\t\x07\x04\b\t\b\x04\t\t\t\x04\n\t\n\x04\v\t\v\x04\f\t\f\x04\r' +
    '\t\r\x04\x0E\t\x0E\x04\x0F\t\x0F\x04\x10\t\x10\x04\x11\t\x11\x04\x12\t' +
    '\x12\x04\x13\t\x13\x04\x14\t\x14\x04\x15\t\x15\x04\x16\t\x16\x04\x17\t' +
    '\x17\x04\x18\t\x18\x04\x19\t\x19\x04\x1A\t\x1A\x04\x1B\t\x1B\x04\x1C\t' +
    '\x1C\x04\x1D\t\x1D\x04\x1E\t\x1E\x04\x1F\t\x1F\x04 \t \x04!\t!\x04"\t' +
    "\"\x04#\t#\x04$\t$\x04%\t%\x04&\t&\x04'\t'\x04(\t(\x04)\t)\x04*\t*\x04" +
    '+\t+\x04,\t,\x04-\t-\x04.\t.\x04/\t/\x040\t0\x041\t1\x042\t2\x043\t3\x04' +
    '4\t4\x045\t5\x046\t6\x047\t7\x048\t8\x049\t9\x04:\t:\x04;\t;\x04<\t<\x04' +
    '=\t=\x04>\t>\x04?\t?\x04@\t@\x04A\tA\x04B\tB\x04C\tC\x04D\tD\x04E\tE\x04' +
    'F\tF\x04G\tG\x04H\tH\x04I\tI\x04J\tJ\x04K\tK\x04L\tL\x04M\tM\x04N\tN\x04' +
    'O\tO\x04P\tP\x04Q\tQ\x04R\tR\x04S\tS\x04T\tT\x04U\tU\x04V\tV\x04W\tW\x03' +
    '\x02\x03\x02\x03\x03\x03\x03\x03\x04\x03\x04\x03\x05\x03\x05\x03\x06\x03' +
    '\x06\x03\x07\x03\x07\x03\b\x03\b\x03\t\x03\t\x03\n\x03\n\x03\v\x03\v\x03' +
    '\v\x03\f\x03\f\x03\f\x03\r\x03\r\x03\x0E\x03\x0E\x03\x0E\x03\x0F\x03\x0F' +
    '\x03\x10\x03\x10\x03\x10\x03\x11\x03\x11\x03\x11\x03\x12\x03\x12\x03\x12' +
    '\x03\x13\x03\x13\x03\x13\x03\x13\x03\x13\x03\x13\x05\x13\xDE\n\x13\x03' +
    '\x14\x03\x14\x03\x14\x03\x14\x03\x14\x05\x14\xE5\n\x14\x03\x15\x03\x15' +
    '\x03\x15\x03\x15\x03\x16\x03\x16\x03\x16\x03\x16\x03\x16\x03\x16\x03\x16' +
    '\x03\x16\x03\x16\x03\x16\x03\x16\x05\x16\xF6\n\x16\x03\x17\x03\x17\x03' +
    '\x17\x03\x17\x03\x18\x03\x18\x03\x18\x03\x18\x03\x18\x03\x18\x03\x18\x03' +
    '\x18\x03\x19\x03\x19\x03\x19\x03\x19\x03\x19\x03\x19\x03\x19\x03\x1A\x03' +
    '\x1A\x03\x1A\x03\x1A\x03\x1A\x03\x1A\x03\x1B\x03\x1B\x03\x1B\x03\x1B\x03' +
    '\x1B\x03\x1C\x03\x1C\x03\x1C\x03\x1D\x03\x1D\x03\x1D\x03\x1E\x03\x1E\x03' +
    '\x1E\x03\x1E\x03\x1E\x03\x1E\x03\x1E\x03\x1F\x03\x1F\x03\x1F\x03\x1F\x03' +
    '\x1F\x03\x1F\x03 \x03 \x03 \x03!\x03!\x03!\x03!\x03"\x03"\x03"\x03' +
    '"\x03"\x03#\x06#\u0136\n#\r#\x0E#\u0137\x03$\x03$\x06$\u013C\n$\r$\x0E' +
    "$\u013D\x03%\x03%\x03%\x03&\x03&\x06&\u0145\n&\r&\x0E&\u0146\x03'\x03" +
    "'\x03'\x07'\u014C\n'\f'\x0E'\u014F\v'\x03'\x03'\x03(\x03(\x03" +
    '(\x07(\u0156\n(\f(\x0E(\u0159\v(\x03(\x03(\x03)\x06)\u015E\n)\r)\x0E)' +
    '\u015F\x03)\x03)\x03*\x03*\x03+\x03+\x03,\x03,\x03-\x03-\x03.\x03.\x03' +
    '/\x03/\x030\x030\x031\x031\x032\x032\x033\x033\x034\x034\x034\x035\x03' +
    '5\x036\x036\x036\x037\x037\x037\x037\x037\x037\x037\x037\x037\x037\x03' +
    '7\x037\x057\u018C\n7\x038\x038\x038\x038\x038\x038\x038\x038\x038\x03' +
    '8\x038\x038\x038\x038\x038\x038\x038\x058\u019F\n8\x039\x039\x03:\x03' +
    ':\x05:\u01A5\n:\x03;\x03;\x03;\x03;\x03;\x03;\x03;\x05;\u01AE\n;\x03<' +
    '\x03<\x03<\x05<\u01B3\n<\x03=\x03=\x03>\x03>\x03?\x03?\x03@\x03@\x03A' +
    '\x03A\x03B\x03B\x03C\x03C\x03D\x03D\x03E\x03E\x03F\x03F\x03G\x03G\x03' +
    'H\x03H\x03I\x03I\x03J\x03J\x03K\x03K\x03L\x03L\x03M\x03M\x03N\x03N\x03' +
    'O\x03O\x03P\x03P\x03Q\x03Q\x03R\x03R\x03S\x03S\x03T\x03T\x03U\x03U\x03' +
    'V\x03V\x03W\x03W\x02\x02\x02X\x03\x02\x03\x05\x02\x04\x07\x02\x05\t\x02' +
    '\x06\v\x02\x07\r\x02\x02\x0F\x02\b\x11\x02\t\x13\x02\n\x15\x02\v\x17\x02' +
    '\f\x19\x02\r\x1B\x02\x0E\x1D\x02\x0F\x1F\x02\x10!\x02\x11#\x02\x12%\x02' +
    "\x13'\x02\x14)\x02\x15+\x02\x16-\x02\x17/\x02\x181\x02\x193\x02\x1A5" +
    '\x02\x1B7\x02\x1C9\x02\x1D;\x02\x1E=\x02\x1F?\x02 A\x02!C\x02"E\x02#' +
    "G\x02$I\x02%K\x02&M\x02'O\x02(Q\x02)S\x02\x02U\x02*W\x02+Y\x02\x02[\x02" +
    '\x02]\x02\x02_\x02\x02a\x02\x02c\x02\x02e\x02\x02g\x02\x02i\x02\x02k\x02' +
    '\x02m\x02\x02o\x02\x02q\x02\x02s\x02\x02u\x02\x02w\x02\x02y\x02\x02{\x02' +
    '\x02}\x02\x02\x7F\x02\x02\x81\x02\x02\x83\x02\x02\x85\x02\x02\x87\x02' +
    '\x02\x89\x02\x02\x8B\x02\x02\x8D\x02\x02\x8F\x02\x02\x91\x02\x02\x93\x02' +
    '\x02\x95\x02\x02\x97\x02\x02\x99\x02\x02\x9B\x02\x02\x9D\x02\x02\x9F\x02' +
    '\x02\xA1\x02\x02\xA3\x02\x02\xA5\x02\x02\xA7\x02\x02\xA9\x02\x02\xAB\x02' +
    '\x02\xAD\x02\x02\x03\x02#\t\x02\x02.11=B]`}\xA1\uFDD2\uFDF1\x00\x01\n' +
    '\x02\x02\v\r\x0E\x10!$$^^\x81\xA1\uFDD2\uFDF1\x00\x01\n\x02\x02\v\r\x0E' +
    "\x10!))^^\x81\xA1\uFDD2\uFDF1\x00\x01\n\x02%',-11==AB``}}\x7F\x7F\x05" +
    '\x02ppttvv\b\x02\x02\v\r\x0E\x10!\x81\xA1\uFDD2\uFDF1\x00\x01\x03\x02' +
    '2;\x04\x02CCcc\x04\x02DDdd\x04\x02EEee\x04\x02FFff\x04\x02GGgg\x04\x02' +
    'HHhh\x04\x02IIii\x04\x02JJjj\x04\x02KKkk\x04\x02LLll\x04\x02MMmm\x04\x02' +
    'NNnn\x04\x02OOoo\x04\x02PPpp\x04\x02QQqq\x04\x02RRrr\x04\x02SSss\x04\x02' +
    'TTtt\x04\x02UUuu\x04\x02VVvv\x04\x02WWww\x04\x02XXxx\x04\x02YYyy\x04\x02' +
    'ZZzz\x04\x02[[{{\x04\x02\\\\||\x02\u01E8\x02\x03\x03\x02\x02\x02\x02\x05' +
    '\x03\x02\x02\x02\x02\x07\x03\x02\x02\x02\x02\t\x03\x02\x02\x02\x02\v\x03' +
    '\x02\x02\x02\x02\x0F\x03\x02\x02\x02\x02\x11\x03\x02\x02\x02\x02\x13\x03' +
    '\x02\x02\x02\x02\x15\x03\x02\x02\x02\x02\x17\x03\x02\x02\x02\x02\x19\x03' +
    '\x02\x02\x02\x02\x1B\x03\x02\x02\x02\x02\x1D\x03\x02\x02\x02\x02\x1F\x03' +
    '\x02\x02\x02\x02!\x03\x02\x02\x02\x02#\x03\x02\x02\x02\x02%\x03\x02\x02' +
    "\x02\x02'\x03\x02\x02\x02\x02)\x03\x02\x02\x02\x02+\x03\x02\x02\x02\x02" +
    '-\x03\x02\x02\x02\x02/\x03\x02\x02\x02\x021\x03\x02\x02\x02\x023\x03\x02' +
    '\x02\x02\x025\x03\x02\x02\x02\x027\x03\x02\x02\x02\x029\x03\x02\x02\x02' +
    '\x02;\x03\x02\x02\x02\x02=\x03\x02\x02\x02\x02?\x03\x02\x02\x02\x02A\x03' +
    '\x02\x02\x02\x02C\x03\x02\x02\x02\x02E\x03\x02\x02\x02\x02G\x03\x02\x02' +
    '\x02\x02I\x03\x02\x02\x02\x02K\x03\x02\x02\x02\x02M\x03\x02\x02\x02\x02' +
    'O\x03\x02\x02\x02\x02Q\x03\x02\x02\x02\x02U\x03\x02\x02\x02\x02W\x03\x02' +
    '\x02\x02\x03\xAF\x03\x02\x02\x02\x05\xB1\x03\x02\x02\x02\x07\xB3\x03\x02' +
    '\x02\x02\t\xB5\x03\x02\x02\x02\v\xB7\x03\x02\x02\x02\r\xB9\x03\x02\x02' +
    '\x02\x0F\xBB\x03\x02\x02\x02\x11\xBD\x03\x02\x02\x02\x13\xBF\x03\x02\x02' +
    '\x02\x15\xC1\x03\x02\x02\x02\x17\xC4\x03\x02\x02\x02\x19\xC7\x03\x02\x02' +
    '\x02\x1B\xC9\x03\x02\x02\x02\x1D\xCC\x03\x02\x02\x02\x1F\xCE\x03\x02\x02' +
    "\x02!\xD1\x03\x02\x02\x02#\xD4\x03\x02\x02\x02%\xDD\x03\x02\x02\x02'" +
    '\xE4\x03\x02\x02\x02)\xE6\x03\x02\x02\x02+\xF5\x03\x02\x02\x02-\xF7\x03' +
    '\x02\x02\x02/\xFB\x03\x02\x02\x021\u0103\x03\x02\x02\x023\u010A\x03\x02' +
    '\x02\x025\u0110\x03\x02\x02\x027\u0115\x03\x02\x02\x029\u0118\x03\x02' +
    '\x02\x02;\u011B\x03\x02\x02\x02=\u0122\x03\x02\x02\x02?\u0128\x03\x02' +
    '\x02\x02A\u012B\x03\x02\x02\x02C\u012F\x03\x02\x02\x02E\u0135\x03\x02' +
    '\x02\x02G\u0139\x03\x02\x02\x02I\u013F\x03\x02\x02\x02K\u0144\x03\x02' +
    '\x02\x02M\u0148\x03\x02\x02\x02O\u0152\x03\x02\x02\x02Q\u015D\x03\x02' +
    '\x02\x02S\u0163\x03\x02\x02\x02U\u0165\x03\x02\x02\x02W\u0167\x03\x02' +
    '\x02\x02Y\u0169\x03\x02\x02\x02[\u016B\x03\x02\x02\x02]\u016D\x03\x02' +
    '\x02\x02_\u016F\x03\x02\x02\x02a\u0171\x03\x02\x02\x02c\u0173\x03\x02' +
    '\x02\x02e\u0175\x03\x02\x02\x02g\u0177\x03\x02\x02\x02i\u017A\x03\x02' +
    '\x02\x02k\u017C\x03\x02\x02\x02m\u017F\x03\x02\x02\x02o\u019E\x03\x02' +
    '\x02\x02q\u01A0\x03\x02\x02\x02s\u01A4\x03\x02\x02\x02u\u01AD\x03\x02' +
    '\x02\x02w\u01B2\x03\x02\x02\x02y\u01B4\x03\x02\x02\x02{\u01B6\x03\x02' +
    '\x02\x02}\u01B8\x03\x02\x02\x02\x7F\u01BA\x03\x02\x02\x02\x81\u01BC\x03' +
    '\x02\x02\x02\x83\u01BE\x03\x02\x02\x02\x85\u01C0\x03\x02\x02\x02\x87\u01C2' +
    '\x03\x02\x02\x02\x89\u01C4\x03\x02\x02\x02\x8B\u01C6\x03\x02\x02\x02\x8D' +
    '\u01C8\x03\x02\x02\x02\x8F\u01CA\x03\x02\x02\x02\x91\u01CC\x03\x02\x02' +
    '\x02\x93\u01CE\x03\x02\x02\x02\x95\u01D0\x03\x02\x02\x02\x97\u01D2\x03' +
    '\x02\x02\x02\x99\u01D4\x03\x02\x02\x02\x9B\u01D6\x03\x02\x02\x02\x9D\u01D8' +
    '\x03\x02\x02\x02\x9F\u01DA\x03\x02\x02\x02\xA1\u01DC\x03\x02\x02\x02\xA3' +
    '\u01DE\x03\x02\x02\x02\xA5\u01E0\x03\x02\x02\x02\xA7\u01E2\x03\x02\x02' +
    '\x02\xA9\u01E4\x03\x02\x02\x02\xAB\u01E6\x03\x02\x02\x02\xAD\u01E8\x03' +
    '\x02\x02\x02\xAF\xB0\x07*\x02\x02\xB0\x04\x03\x02\x02\x02\xB1\xB2\x07' +
    '+\x02\x02\xB2\x06\x03\x02\x02\x02\xB3\xB4\x07.\x02\x02\xB4\b\x03\x02\x02' +
    '\x02\xB5\xB6\x07]\x02\x02\xB6\n\x03\x02\x02\x02\xB7\xB8\x07_\x02\x02\xB8' +
    '\f\x03\x02\x02\x02\xB9\xBA\x07/\x02\x02\xBA\x0E\x03\x02\x02\x02\xBB\xBC' +
    '\x07#\x02\x02\xBC\x10\x03\x02\x02\x02\xBD\xBE\x07>\x02\x02\xBE\x12\x03' +
    '\x02\x02\x02\xBF\xC0\x07@\x02\x02\xC0\x14\x03\x02\x02\x02\xC1\xC2\x07' +
    '@\x02\x02\xC2\xC3\x07?\x02\x02\xC3\x16\x03\x02\x02\x02\xC4\xC5\x07>\x02' +
    '\x02\xC5\xC6\x07?\x02\x02\xC6\x18\x03\x02\x02\x02\xC7\xC8\x07?\x02\x02' +
    '\xC8\x1A\x03\x02\x02\x02\xC9\xCA\x07#\x02\x02\xCA\xCB\x07?\x02\x02\xCB' +
    '\x1C\x03\x02\x02\x02\xCC\xCD\x07\x80\x02\x02\xCD\x1E\x03\x02\x02\x02\xCE' +
    '\xCF\x07#\x02\x02\xCF\xD0\x07\x80\x02\x02\xD0 \x03\x02\x02\x02\xD1\xD2' +
    '\x05\x8BF\x02\xD2\xD3\x05\x95K\x02\xD3"\x03\x02\x02\x02\xD4\xD5\x05\x8B' +
    'F\x02\xD5\xD6\x05\x9FP\x02\xD6$\x03\x02\x02\x02\xD7\xD8\x05{>\x02\xD8' +
    '\xD9\x05\x95K\x02\xD9\xDA\x05\x81A\x02\xDA\xDE\x03\x02\x02\x02\xDB\xDE' +
    '\x05e3\x02\xDC\xDE\x05g4\x02\xDD\xD7\x03\x02\x02\x02\xDD\xDB\x03\x02\x02' +
    '\x02\xDD\xDC\x03\x02\x02\x02\xDE&\x03\x02\x02\x02\xDF\xE0\x05\x97L\x02' +
    '\xE0\xE1\x05\x9DO\x02\xE1\xE5\x03\x02\x02\x02\xE2\xE5\x05i5\x02\xE3\xE5' +
    '\x05k6\x02\xE4\xDF\x03\x02\x02\x02\xE4\xE2\x03\x02\x02\x02\xE4\xE3\x03' +
    '\x02\x02\x02\xE5(\x03\x02\x02\x02\xE6\xE7\x05\x95K\x02\xE7\xE8\x05\x97' +
    'L\x02\xE8\xE9\x05\xA1Q\x02\xE9*\x03\x02\x02\x02\xEA\xEB\x05\x83B\x02\xEB' +
    '\xEC\x05\x93J\x02\xEC\xED\x05\x99M\x02\xED\xEE\x05\xA1Q\x02\xEE\xEF\x05' +
    '\xABV\x02\xEF\xF6\x03\x02\x02\x02\xF0\xF1\x05\x95K\x02\xF1\xF2\x05\xA3' +
    'R\x02\xF2\xF3\x05\x91I\x02\xF3\xF4\x05\x91I\x02\xF4\xF6\x03\x02\x02\x02' +
    '\xF5\xEA\x03\x02\x02\x02\xF5\xF0\x03\x02\x02\x02\xF6,\x03\x02\x02\x02' +
    '\xF7\xF8\x05\xA7T\x02\xF8\xF9\x05{>\x02\xF9\xFA\x05\x9FP\x02\xFA.\x03' +
    '\x02\x02\x02\xFB\xFC\x05\x7F@\x02\xFC\xFD\x05\x89E\x02\xFD\xFE\x05{>\x02' +
    '\xFE\xFF\x05\x95K\x02\xFF\u0100\x05\x87D\x02\u0100\u0101\x05\x83B\x02' +
    '\u0101\u0102\x05\x81A\x02\u01020\x03\x02\x02\x02\u0103\u0104\x05}?\x02' +
    '\u0104\u0105\x05\x83B\x02\u0105\u0106\x05\x85C\x02\u0106\u0107\x05\x97' +
    'L\x02\u0107\u0108\x05\x9DO\x02\u0108\u0109\x05\x83B\x02\u01092\x03\x02' +
    '\x02\x02\u010A\u010B\x05{>\x02\u010B\u010C\x05\x85C\x02\u010C\u010D\x05' +
    '\xA1Q\x02\u010D\u010E\x05\x83B\x02\u010E\u010F\x05\x9DO\x02\u010F4\x03' +
    '\x02\x02\x02\u0110\u0111\x05\x85C\x02\u0111\u0112\x05\x9DO\x02\u0112\u0113' +
    '\x05\x97L\x02\u0113\u0114\x05\x93J\x02\u01146\x03\x02\x02\x02\u0115\u0116' +
    '\x05\xA1Q\x02\u0116\u0117\x05\x97L\x02\u01178\x03\x02\x02\x02\u0118\u0119' +
    '\x05\x97L\x02\u0119\u011A\x05\x95K\x02\u011A:\x03\x02\x02\x02\u011B\u011C' +
    '\x05\x81A\x02\u011C\u011D\x05\xA3R\x02\u011D\u011E\x05\x9DO\x02\u011E' +
    '\u011F\x05\x8BF\x02\u011F\u0120\x05\x95K\x02\u0120\u0121\x05\x87D\x02' +
    '\u0121<\x03\x02\x02\x02\u0122\u0123\x05\x97L\x02\u0123\u0124\x05\x9DO' +
    '\x02\u0124\u0125\x05\x81A\x02\u0125\u0126\x05\x83B\x02\u0126\u0127\x05' +
    '\x9DO\x02\u0127>\x03\x02\x02\x02\u0128\u0129\x05}?\x02\u0129\u012A\x05' +
    '\xABV\x02\u012A@\x03\x02\x02\x02\u012B\u012C\x05{>\x02\u012C\u012D\x05' +
    '\x9FP\x02\u012D\u012E\x05\x7F@\x02\u012EB\x03\x02\x02\x02\u012F\u0130' +
    '\x05\x81A\x02\u0130\u0131\x05\x83B\x02\u0131\u0132\x05\x9FP\x02\u0132' +
    '\u0133\x05\x7F@\x02\u0133D\x03\x02\x02\x02\u0134\u0136\x05y=\x02\u0135' +
    '\u0134\x03\x02\x02\x02\u0136\u0137\x03\x02\x02\x02\u0137\u0135\x03\x02' +
    '\x02\x02\u0137\u0138\x03\x02\x02\x02\u0138F\x03\x02\x02\x02\u0139\u013B' +
    '\x05\r\x07\x02\u013A\u013C\x05y=\x02\u013B\u013A\x03\x02\x02\x02\u013C' +
    '\u013D\x03\x02\x02\x02\u013D\u013B\x03\x02\x02\x02\u013D\u013E\x03\x02' +
    '\x02\x02\u013EH\x03\x02\x02\x02\u013F\u0140\x05\x7F@\x02\u0140\u0141\x05' +
    '\x85C\x02\u0141J\x03\x02\x02\x02\u0142\u0145\x05m7\x02\u0143\u0145\n\x02' +
    '\x02\x02\u0144\u0142\x03\x02\x02\x02\u0144\u0143\x03\x02\x02\x02\u0145' +
    '\u0146\x03\x02\x02\x02\u0146\u0144\x03\x02\x02\x02\u0146\u0147\x03\x02' +
    '\x02\x02\u0147L\x03\x02\x02\x02\u0148\u014D\x05Y-\x02\u0149\u014C\x05' +
    'm7\x02\u014A\u014C\n\x03\x02\x02\u014B\u0149\x03\x02\x02\x02\u014B\u014A' +
    '\x03\x02\x02\x02\u014C\u014F\x03\x02\x02\x02\u014D\u014B\x03\x02\x02\x02' +
    '\u014D\u014E\x03\x02\x02\x02\u014E\u0150\x03\x02\x02\x02\u014F\u014D\x03' +
    '\x02\x02\x02\u0150\u0151\x05Y-\x02\u0151N\x03\x02\x02\x02\u0152\u0157' +
    '\x05[.\x02\u0153\u0156\x05m7\x02\u0154\u0156\n\x04\x02\x02\u0155\u0153' +
    '\x03\x02\x02\x02\u0155\u0154\x03\x02\x02\x02\u0156\u0159\x03\x02\x02\x02' +
    '\u0157\u0155\x03\x02\x02\x02\u0157\u0158\x03\x02\x02\x02\u0158\u015A\x03' +
    '\x02\x02\x02\u0159\u0157\x03\x02\x02\x02\u015A\u015B\x05[.\x02\u015BP' +
    '\x03\x02\x02\x02\u015C\u015E\x05w<\x02\u015D\u015C\x03\x02\x02\x02\u015E' +
    '\u015F\x03\x02\x02\x02\u015F\u015D\x03\x02\x02\x02\u015F\u0160\x03\x02' +
    '\x02\x02\u0160\u0161\x03\x02\x02\x02\u0161\u0162\b)\x02\x02\u0162R\x03' +
    '\x02\x02\x02\u0163\u0164\t\x05\x02\x02\u0164T\x03\x02\x02\x02\u0165\u0166' +
    '\x05S*\x02\u0166V\x03\x02\x02\x02\u0167\u0168\v\x02\x02\x02\u0168X\x03' +
    '\x02\x02\x02\u0169\u016A\x07$\x02\x02\u016AZ\x03\x02\x02\x02\u016B\u016C' +
    '\x07)\x02\x02\u016C\\\x03\x02\x02\x02\u016D\u016E\x07^\x02\x02\u016E^' +
    '\x03\x02\x02\x02\u016F\u0170\x07\x0F\x02\x02\u0170`\x03\x02\x02\x02\u0171' +
    '\u0172\x07\f\x02\x02\u0172b\x03\x02\x02\x02\u0173\u0174\x07"\x02\x02' +
    '\u0174d\x03\x02\x02\x02\u0175\u0176\x07(\x02\x02\u0176f\x03\x02\x02\x02' +
    '\u0177\u0178\x07(\x02\x02\u0178\u0179\x07(\x02\x02\u0179h\x03\x02\x02' +
    '\x02\u017A\u017B\x07~\x02\x02\u017Bj\x03\x02\x02\x02\u017C\u017D\x07~' +
    '\x02\x02\u017D\u017E\x07~\x02\x02\u017El\x03\x02\x02\x02\u017F\u018B\x05' +
    ']/\x02\u0180\u018C\t\x06\x02\x02\u0181\u018C\x05Y-\x02\u0182\u018C\x05' +
    '[.\x02\u0183\u018C\x05]/\x02\u0184\u018C\x05c2\x02\u0185\u0186\x07w\x02' +
    '\x02\u0186\u0187\x05u;\x02\u0187\u0188\x05u;\x02\u0188\u0189\x05u;\x02' +
    '\u0189\u018A\x05u;\x02\u018A\u018C\x03\x02\x02\x02\u018B\u0180\x03\x02' +
    '\x02\x02\u018B\u0181\x03\x02\x02\x02\u018B\u0182\x03\x02\x02\x02\u018B' +
    '\u0183\x03\x02\x02\x02\u018B\u0184\x03\x02\x02\x02\u018B\u0185\x03\x02' +
    '\x02\x02\u018Cn\x03\x02\x02\x02\u018D\u019F\x05q9\x02\u018E\u019F\x05' +
    'Y-\x02\u018F\u019F\x05[.\x02\u0190\u019F\x05\x19\r\x02\u0191\u019F\x05' +
    '\x0F\b\x02\u0192\u019F\x05\x11\t\x02\u0193\u019F\x05\x13\n\x02\u0194\u019F' +
    '\x05\x03\x02\x02\u0195\u019F\x05\x05\x03\x02\u0196\u019F\x05\x1D\x0F\x02' +
    '\u0197\u019F\x05\x07\x04\x02\u0198\u019F\x05\t\x05\x02\u0199\u019F\x05' +
    '\v\x06\x02\u019A\u019F\x05i5\x02\u019B\u019F\x05e3\x02\u019C\u019F\x05' +
    'S*\x02\u019D\u019F\x05s:\x02\u019E\u018D\x03\x02\x02\x02\u019E\u018E\x03' +
    '\x02\x02\x02\u019E\u018F\x03\x02\x02\x02\u019E\u0190\x03\x02\x02\x02\u019E' +
    '\u0191\x03\x02\x02\x02\u019E\u0192\x03\x02\x02\x02\u019E\u0193\x03\x02' +
    '\x02\x02\u019E\u0194\x03\x02\x02\x02\u019E\u0195\x03\x02\x02\x02\u019E' +
    '\u0196\x03\x02\x02\x02\u019E\u0197\x03\x02\x02\x02\u019E\u0198\x03\x02' +
    '\x02\x02\u019E\u0199\x03\x02\x02\x02\u019E\u019A\x03\x02\x02\x02\u019E' +
    '\u019B\x03\x02\x02\x02\u019E\u019C\x03\x02\x02\x02\u019E\u019D\x03\x02' +
    '\x02\x02\u019Fp\x03\x02\x02\x02\u01A0\u01A1\t\x07\x02\x02\u01A1r\x03\x02' +
    '\x02\x02\u01A2\u01A5\x05_0\x02\u01A3\u01A5\x05a1\x02\u01A4\u01A2\x03\x02' +
    '\x02\x02\u01A4\u01A3\x03\x02\x02\x02\u01A5t\x03\x02\x02\x02\u01A6\u01AE' +
    '\x05y=\x02\u01A7\u01AE\x05{>\x02\u01A8\u01AE\x05}?\x02\u01A9\u01AE\x05' +
    '\x7F@\x02\u01AA\u01AE\x05\x81A\x02\u01AB\u01AE\x05\x83B\x02\u01AC\u01AE' +
    '\x05\x85C\x02\u01AD\u01A6\x03\x02\x02\x02\u01AD\u01A7\x03\x02\x02\x02' +
    '\u01AD\u01A8\x03\x02\x02\x02\u01AD\u01A9\x03\x02\x02\x02\u01AD\u01AA\x03' +
    '\x02\x02\x02\u01AD\u01AB\x03\x02\x02\x02\u01AD\u01AC\x03\x02\x02\x02\u01AE' +
    'v\x03\x02\x02\x02\u01AF\u01B3\x05c2\x02\u01B0\u01B3\x07\v\x02\x02\u01B1' +
    '\u01B3\x05s:\x02\u01B2\u01AF\x03\x02\x02\x02\u01B2\u01B0\x03\x02\x02\x02' +
    '\u01B2\u01B1\x03\x02\x02\x02\u01B3x\x03\x02\x02\x02\u01B4\u01B5\t\b\x02' +
    '\x02\u01B5z\x03\x02\x02\x02\u01B6\u01B7\t\t\x02\x02\u01B7|\x03\x02\x02' +
    '\x02\u01B8\u01B9\t\n\x02\x02\u01B9~\x03\x02\x02\x02\u01BA\u01BB\t\v\x02' +
    '\x02\u01BB\x80\x03\x02\x02\x02\u01BC\u01BD\t\f\x02\x02\u01BD\x82\x03\x02' +
    '\x02\x02\u01BE\u01BF\t\r\x02\x02\u01BF\x84\x03\x02\x02\x02\u01C0\u01C1' +
    '\t\x0E\x02\x02\u01C1\x86\x03\x02\x02\x02\u01C2\u01C3\t\x0F\x02\x02\u01C3' +
    '\x88\x03\x02\x02\x02\u01C4\u01C5\t\x10\x02\x02\u01C5\x8A\x03\x02\x02\x02' +
    '\u01C6\u01C7\t\x11\x02\x02\u01C7\x8C\x03\x02\x02\x02\u01C8\u01C9\t\x12' +
    '\x02\x02\u01C9\x8E\x03\x02\x02\x02\u01CA\u01CB\t\x13\x02\x02\u01CB\x90' +
    '\x03\x02\x02\x02\u01CC\u01CD\t\x14\x02\x02\u01CD\x92\x03\x02\x02\x02\u01CE' +
    '\u01CF\t\x15\x02\x02\u01CF\x94\x03\x02\x02\x02\u01D0\u01D1\t\x16\x02\x02' +
    '\u01D1\x96\x03\x02\x02\x02\u01D2\u01D3\t\x17\x02\x02\u01D3\x98\x03\x02' +
    '\x02\x02\u01D4\u01D5\t\x18\x02\x02\u01D5\x9A\x03\x02\x02\x02\u01D6\u01D7' +
    '\t\x19\x02\x02\u01D7\x9C\x03\x02\x02\x02\u01D8\u01D9\t\x1A\x02\x02\u01D9' +
    '\x9E\x03\x02\x02\x02\u01DA\u01DB\t\x1B\x02\x02\u01DB\xA0\x03\x02\x02\x02' +
    '\u01DC\u01DD\t\x1C\x02\x02\u01DD\xA2\x03\x02\x02\x02\u01DE\u01DF\t\x1D' +
    '\x02\x02\u01DF\xA4\x03\x02\x02\x02\u01E0\u01E1\t\x1E\x02\x02\u01E1\xA6' +
    '\x03\x02\x02\x02\u01E2\u01E3\t\x1F\x02\x02\u01E3\xA8\x03\x02\x02\x02\u01E4' +
    '\u01E5\t \x02\x02\u01E5\xAA\x03\x02\x02\x02\u01E6\u01E7\t!\x02\x02\u01E7' +
    '\xAC\x03\x02\x02\x02\u01E8\u01E9\t"\x02\x02\u01E9\xAE\x03\x02\x02\x02' +
    '\x14\x02\xDD\xE4\xF5\u0137\u013D\u0144\u0146\u014B\u014D\u0155\u0157\u015F' +
    '\u018B\u019E\u01A4\u01AD\u01B2\x03\x02\x03\x02';
  public static __ATN: ATN;
  public static get _ATN(): ATN {
    if (!JQLLexer.__ATN) {
      JQLLexer.__ATN = new ATNDeserializer().deserialize(
        Utils.toCharArray(JQLLexer._serializedATN)
      );
    }

    return JQLLexer.__ATN;
  }
}
