import React from 'react';

import { Box, xcss } from '@atlaskit/primitives';

const redactionBarStyles = xcss({
	display: 'inline-flex',
	aspectRatio: '2.8/1',
	height: '1lh',
	'@supports not (height: 1lh)': {
		height: '1.5em',
	},
	backgroundColor: 'color.background.neutral.bold.pressed',
	verticalAlign: 'middle',
	position: 'relative',
	':hover': {
		backgroundColor: 'color.background.neutral.bold',
		borderRadius: '3px',
	},
	':focus-visible': {
		backgroundColor: 'color.background.neutral.bold',
		borderRadius: '3px',
	},
});

export function RedactionMarker() {
	return <Box as="span" xcss={redactionBarStyles} />;
}
