import React, { type PropsWithChildren } from 'react';

import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import {
	createConfluencePageLinkSuccess,
	linkConfluencePageAISuggestionsClosed,
} from '@atlassian/jira-issue-view-store/src/actions/confluence-pages-actions.tsx';
import {
	issueIdSelector,
	issueKeySelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import {
	linkedConfluencePagesPagesSelector,
	mentionedConfluencePagesPagesSelector,
} from '@atlassian/jira-issue-view-store/src/selectors/confluence-content-selector.tsx';

import {
	JiraIssueRelatedResourcesContainer,
	type ContainerProps,
} from './related-resources-context.tsx';

const RelatedResourcesInternalContainer = ({
	children,
	...containerProps
}: PropsWithChildren<ContainerProps>) => {
	return (
		<JiraIssueRelatedResourcesContainer
			scope={`related-resources-${containerProps.issueKey}`}
			{...containerProps}
		>
			{children}
		</JiraIssueRelatedResourcesContainer>
	);
};

export const RelatedResourcesContainer = connect(
	(state) => ({
		issueId: issueIdSelector(state),
		issueKey: issueKeySelector(state),
		linkedConfluencePages: linkedConfluencePagesPagesSelector(state),
		mentionedConfluencePagesPages: mentionedConfluencePagesPagesSelector(state),
	}),
	{
		onCloseAISuggestions: linkConfluencePageAISuggestionsClosed,
		onCreateConfluencePageLinkSuccess: createConfluencePageLinkSuccess,
	},
)(RelatedResourcesInternalContainer);
