/** @jsx jsx */
import React, {
	useMemo,
	useCallback,
	useEffect,
	useRef,
	type ComponentType,
	forwardRef,
} from 'react';
import { styled, css, jsx } from '@compiled/react';
import isEqual from 'lodash/isEqual';
import isUndefined from 'lodash/isUndefined';
import Badge from '@atlaskit/badge';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import ChevronUpIcon from '@atlaskit/icon/glyph/chevron-up';
import { Box, Flex, xcss, Inline } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';

import { FormattedMessage } from '@atlassian/jira-intl';
import {
	FIELD_HEADING_TITLE_COMPONENT_SELECTOR,
	FIELD_WRAPPER_COMPONENT_SELECTOR,
	MULTILINE_FIELD_HEADING_TITLE_COMPONENT_SELECTOR,
} from '@atlassian/jira-issue-field-heading/src/styled.tsx';
import {
	useStickyHeaderRegistration,
	StickyHeaderTrackerContainer,
	useStickyHeader,
} from '@atlassian/jira-issue-header-tracker-service/src/services/sticky-header/index.tsx';
import { smoothScrollIntoViewIfNeeded } from '@atlassian/jira-issue-view-common-utils/src/scroll/index.tsx';
import { usePrevious } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import { useProjectKeyUnsafe } from '@atlassian/jira-polaris-component-environment-container/src/index.tsx';
import {
	fireUIAnalytics,
	useAnalyticsEvents,
	type Attributes,
} from '@atlassian/jira-product-analytics-bridge';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { ChildIssuesLimitFlag } from '../child-issues-limit-flag/index.tsx';
import { ChildIssuesLimitPanel } from '../child-issues-limit-panel/index.tsx';
import { AsyncConfigurableChildIssuesPanel } from '../configurable-child-issues-panel/src/ui/async.tsx';

import { ExpandableHeader } from './expandable-header/index.tsx';
import messages from './messages.tsx';
import { useGroupLocalStorage } from './services/local-storage/index.tsx';
import type {
	CollapsibleChildIssuesProps,
	GeneralProps,
	GroupWrapperProps,
	BaseGroupProps,
} from './types.tsx';

import {
	COLLAPSIBLE_CHILD_ISSUES_UI_EVENT,
	COLLAPSIBLE_CHILD_ISSUES_ID,
	groupBorderRadius,
} from './constants.tsx';

export const CollapsibleChildIssues = ({
	Title,
	totalIssueCount,
	projectId,
	parentIssueTypeId,
	filterSubtasks,
	completedIssueCount,
	onAddChildClick,
	childIssuesLimitStatus,
	isApproachingLimit,
	issueHierarchyLevel,
	allIssues,
	incompleteIssues,
	hasExceededChildIssuesLimitOnLoadOrAfter,
}: CollapsibleChildIssuesProps) => {
	const projectKey = useProjectKeyUnsafe();
	const collapsibleGroupRef = useRef<HTMLDivElement>(null);
	const [isOpen, setIsOpen] = useGroupLocalStorage(projectKey, COLLAPSIBLE_CHILD_ISSUES_ID, false);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [stickyHeaderPosition] = useStickyHeader();
	const didOpenAtLeastOnceRef = useRef(false);
	const headerRef = useRef(null);
	const { registerSticky, deregisterSticky } = useStickyHeaderRegistration();

	const completionPercentage = useMemo(
		() => Math.floor((completedIssueCount / totalIssueCount) * 100),
		[completedIssueCount, totalIssueCount],
	);
	const progressPercentageContainer = useMemo(
		() => (
			<Flex gap="space.050" alignItems="center" xcss={completionPercentageContainer}>
				<span css={completionCirclestyles} />
				<FormattedMessage
					{...messages.completionPercentageText}
					values={{
						completionPercentage: (
							<Inline as="span" xcss={percentageValueContainer}>
								{completionPercentage}
							</Inline>
						),
					}}
				/>
			</Flex>
		),
		[completionPercentage],
	);

	const previousValue = usePrevious(isOpen);
	const hasClosed = useMemo(
		() => !isUndefined(previousValue) && !isOpen && !isEqual(isOpen, previousValue),
		[isOpen, previousValue],
	);

	useEffect(() => {
		if (hasClosed) {
			// Wait until group has collapsed
			setTimeout(() => {
				smoothScrollIntoViewIfNeeded(collapsibleGroupRef.current, {
					scrollMode: 'if-needed',
					behavior: (actions) =>
						actions.forEach(({ el, top }) => {
							// eslint-disable-next-line no-param-reassign
							el.scrollTop = top - (stickyHeaderPosition || 0);
						}),
					block: 'start',
				});
			});
		}
	}, [hasClosed, stickyHeaderPosition]);

	useEffect(() => {
		const STICKY_NAME = Symbol(`group-${COLLAPSIBLE_CHILD_ISSUES_ID}`);
		registerSticky(STICKY_NAME, headerRef);
		return () => deregisterSticky(STICKY_NAME);
	}, [registerSticky, deregisterSticky, stickyHeaderPosition]);

	const onHeaderClick = useCallback(() => {
		setIsOpen(!isOpen);

		const openedOrClosed = isOpen ? 'closed' : 'opened';
		const analyticsEvent = createAnalyticsEvent({
			eventName: `${COLLAPSIBLE_CHILD_ISSUES_ID} ${openedOrClosed}`,
			action: 'clicked',
			actionSubject: 'button',
			actionSubjectId: { COLLAPSIBLE_CHILD_ISSUES_ID },
		});
		const uiAnalytics: Attributes = {
			groupTitle: <Title />,
			groupState: openedOrClosed,
		};
		fireUIAnalytics(analyticsEvent, COLLAPSIBLE_CHILD_ISSUES_UI_EVENT, uiAnalytics);
	}, [Title, createAnalyticsEvent, isOpen, setIsOpen]);

	const onToggle = useCallback(
		(event: Event) => {
			didOpenAtLeastOnceRef.current = true;
			event.preventDefault();
			onHeaderClick?.();
		},
		[onHeaderClick],
	);

	const renderHeaderActions = useCallback(
		() => (
			<Box xcss={iconWrapper}>
				{isOpen ? (
					<ChevronUpIcon label="" primaryColor={token('color.icon.subtle', colors.N500)} />
				) : (
					<ChevronDownIcon label="" primaryColor={token('color.icon.subtle', colors.N500)} />
				)}
			</Box>
		),
		[isOpen],
	);

	const renderIssues = useMemo(() => {
		return allIssues.length > 0 ? (
			<UFOSegment name="issue-child-issues-table">
				<AsyncConfigurableChildIssuesPanel
					parentIssueTypeId={parentIssueTypeId}
					projectId={projectId}
					filterSubtasks={filterSubtasks}
					expandedHeaderComponents={{
						onAddChildClick,
						childIssuesLimitStatus,
						issueHierarchyLevel,
						allIssues,
						incompleteIssues,
					}}
				/>
			</UFOSegment>
		) : null;
	}, [
		allIssues,
		childIssuesLimitStatus,
		filterSubtasks,
		incompleteIssues,
		issueHierarchyLevel,
		onAddChildClick,
		parentIssueTypeId,
		projectId,
	]);

	return (
		<StickyHeaderTrackerContainer scope={`group-${COLLAPSIBLE_CHILD_ISSUES_ID}`}>
			<BaseGroup
				headerNode={
					<ExpandableHeader
						isOpen={isOpen}
						id={COLLAPSIBLE_CHILD_ISSUES_ID}
						onToggle={onToggle}
						renderHeaderActions={renderHeaderActions}
						stickyHeaderPosition={stickyHeaderPosition}
						subTitle={!hasExceededChildIssuesLimitOnLoadOrAfter && progressPercentageContainer}
						filterSubtasks={filterSubtasks}
						projectId={projectId}
						parentIssueTypeId={parentIssueTypeId}
						title={
							<Flex gap="space.100" alignItems="center">
								<Title />
								{!hasExceededChildIssuesLimitOnLoadOrAfter && <Badge>{totalIssueCount}</Badge>}
							</Flex>
						}
					/>
				}
				Group={GroupStyled}
				ref={collapsibleGroupRef}
				isOpen={isOpen}
			>
				{!didOpenAtLeastOnceRef.current && !isOpen ? null : (
					<Body isOpen={isOpen}>
						{hasExceededChildIssuesLimitOnLoadOrAfter && (
							<ChildIssuesLimitPanel
								hasExceededIssuesLimitInitialLoad={hasExceededChildIssuesLimitOnLoadOrAfter}
							/>
						)}
						<ChildIssuesLimitFlag
							isApproachingLimit={isApproachingLimit}
							childIssuesLimitStatus={childIssuesLimitStatus}
						/>
						{renderIssues}
					</Body>
				)}
			</BaseGroup>
		</StickyHeaderTrackerContainer>
	);
};

const innerBodyPaddingStyle = {
	boxSizing: 'border-box',
	[`${FIELD_HEADING_TITLE_COMPONENT_SELECTOR}, ${MULTILINE_FIELD_HEADING_TITLE_COMPONENT_SELECTOR}`]:
		{
			color: token('color.text.subtle', colors.N500),
		},
	[FIELD_WRAPPER_COMPONENT_SELECTOR]: {
		marginBottom: token('space.150', '12px'),
	},
	[`> div > ${FIELD_WRAPPER_COMPONENT_SELECTOR}:last-child`]: {
		marginBottom: 0,
	},
};

const borderStyle = `1px solid ${token('color.border', colors.N40)}`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @typescript-eslint/consistent-type-assertions -- Ignored via go/DSP-18766
const GroupStyled = styled.details({
	boxSizing: 'border-box',
	marginBottom: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&[open] > div': {
		borderTop: 0,
		borderRight: borderStyle,
		borderBottom: borderStyle,
		borderLeft: borderStyle,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		borderBottomRightRadius: `${groupBorderRadius}px`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		borderBottomLeftRadius: `${groupBorderRadius}px`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:not([open]) > div': {
		display: 'none', // required for cypress tests compatibility
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&[open] > summary': {
		borderBottomRightRadius: 0,
		borderBottomLeftRadius: 0,
	},
}) as ComponentType<GroupWrapperProps>;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-unsafe-values, @typescript-eslint/consistent-type-assertions -- Ignored via go/DSP-18766
const Body = styled.div(innerBodyPaddingStyle) as ComponentType<GeneralProps>;

/**
 * Base Group Component
 */
const BaseGroup = forwardRef<HTMLDivElement, BaseGroupProps>(
	({ Group = GroupStyled, Header, headerNode, children, isOpen }, ref) => (
		<Group ref={ref} open={isOpen}>
			{headerNode !== undefined ? headerNode : Header !== undefined && <Header />}
			{children}
		</Group>
	),
);

const completionCirclestyles = css({
	display: 'inline-block',
	height: token('space.200'),
	width: token('space.200'),
	backgroundColor: token('color.icon.accent.lime'),
	borderRadius: token('border.radius.circle'),
	textAlign: 'center',
});

const completionPercentageContainer = xcss({
	flexShrink: 0,
	color: 'color.text.subtlest',
	fontSize: 'font.body',
	marginLeft: 'space.100',
});

const percentageValueContainer = xcss({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	width: `${gridSize}px`,
	textAlign: 'right',
});

const iconWrapper = xcss({
	minWidth: '20px',
	marginBottom: 'space.negative.050',
});
