import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/merge';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { trackOrLogClientError } from '@atlassian/jira-issue-view-common-utils/src/errors/index.tsx';
import {
	issueKeySelector,
	accountIdloggedInUserSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import {
	fieldPersistedValueSelector,
	richTextFieldIdsSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector.tsx';
import { loadDraftRequest } from '@atlassian/jira-issue-view-store/src/drafts/draft-actions.tsx';
import { getDraftKey } from '@atlassian/jira-issue-view-store/src/drafts/draft-utils.tsx';
import {
	fieldEditUpdate,
	type FieldEditBeginAction,
	FIELD_EDIT_BEGIN,
} from '@atlassian/jira-issue-view-store/src/issue-field/state/actions/field-actions.tsx';
import { fieldLoadDraftError } from '@atlassian/jira-issue-view-store/src/issue-field/state/actions/field-draft-actions.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const loadFieldDraftRequests = (action: any, state: State) => {
	const richTextFieldIds = richTextFieldIdsSelector(state);

	if (richTextFieldIds.indexOf(action.payload.fieldId) !== -1) {
		const loggedInAccountId = accountIdloggedInUserSelector(state);
		const issueKey = issueKeySelector(state);

		return richTextFieldIds.map((fieldId) => {
			const currentFieldValue = fieldPersistedValueSelector(fieldId)(state);
			const draftKey = getDraftKey(loggedInAccountId, issueKey, fieldId);
			return Observable.of(
				loadDraftRequest(
					fieldId,
					draftKey,
					fieldId !== action.payload.fieldId ? null : fieldEditUpdate,
					currentFieldValue,
				),
			);
		});
	}
	return [];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const errorHandler = (error: any) => {
	trackOrLogClientError(
		'issue.load-field-draft',
		'Failed to send request to load field draft from local storage ',
		error,
	);
	return Observable.of(fieldLoadDraftError(error));
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<FieldEditBeginAction>, store: MiddlewareAPI<State>) =>
	action$
		.ofType(FIELD_EDIT_BEGIN)
		.mergeMap((action) => Observable.merge(...loadFieldDraftRequests(action, store.getState())))
		.catch((error) => errorHandler(error));
