import ExtensibleError from '@atlassian/jira-extensible-error/src/index.tsx';

const DUPLICATE_FIELD_NAME_ERROR = 'Duplicate field name';

export class DuplicateNameError extends ExtensibleError {
	name = 'DuplicateNameError';

	errors: { error: string }[];

	statusCode: number | null;

	constructor(message: string, errors: { error: string }[] = [], statusCode: number | null = null) {
		super(message, { cause: errors[0] });
		this.errors = errors;
		this.statusCode = statusCode;
	}
}

export const isDuplicateFieldNameError = (errorMessage: string) =>
	errorMessage === DUPLICATE_FIELD_NAME_ERROR;
