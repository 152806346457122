import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	areTheseRelevant: {
		id: 'smart-related-issues-panel.feedback-sentiment.are-these-relevant',
		defaultMessage: 'Are these results relevant?',
		description:
			'Text which sits next to Yes/No buttons which the user can click to indicate relevance',
	},
	yes: {
		id: 'smart-related-issues-panel.feedback-sentiment.yes',
		defaultMessage: 'Yes',
		description: 'Label of button for giving feedback where the results are relevant',
	},
	no: {
		id: 'smart-related-issues-panel.feedback-sentiment.no',
		defaultMessage: 'No',
		description: 'Label of button for giving feedback where the results are not relevant',
	},
	thanksForYourFeedback: {
		id: 'smart-related-issues-panel.feedback-sentiment.thanks-for-your-feedback',
		defaultMessage: 'Thanks for your feedback!',
		description: 'Message show when user has provided feedback',
	},
	tellUsMore: {
		id: 'smart-related-issues-panel.feedback-sentiment.tell-us-more',
		defaultMessage: 'Tell us more',
		description: 'Label of button which can be clicked to open feedback collector',
	},
	toImproveFutureResultsWithPlaceholder: {
		id: 'smart-related-issues-panel.feedback-sentiment.to-improve-future-results-with-placeholder',
		defaultMessage: '{tellUsMore} to improve future results.',
		description: 'Sentence with a placeholder for a button labeled "Tell us more".',
	},
	yesAriaLabel: {
		id: 'smart-related-issues-panel.feedback-sentiment.yes-aria-label',
		defaultMessage: 'Yes',
		description: 'The aria label of yes button',
	},
	noAriaLabel: {
		id: 'smart-related-issues-panel.feedback-sentiment.no-aria-label',
		defaultMessage: 'No',
		description: 'The aria label of no button',
	},
});
