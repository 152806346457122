import React from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import { graphql, useFragment } from 'react-relay';
import { GridColumn } from '@atlaskit/page';
import { token } from '@atlaskit/tokens';
import PerformanceMark from '@atlassian/jira-common-performance/src/set-performance-mark.tsx';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type {
	IssueViewRelayFragment,
	MainIssueAggQueryRelayFragment,
} from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/main.tsx';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import IssuePageGrid, {
	issuePageGridColumns,
} from '@atlassian/jira-issue-view-common-views/src/issue-page-grid/index.tsx';
import withCompactMode from '@atlassian/jira-issue-view-compact-mode/src/index.tsx';
import type { FeedbackRenderer } from '@atlassian/jira-issue-view-model/src/feedback-type.tsx';
import type { ViewModeOptions } from '@atlassian/jira-issue-view-model/src/view-mode-options.tsx';
import type { header_issueViewFoundation_HeaderItemWithoutCompactMode$key } from '@atlassian/jira-relay/src/__generated__/header_issueViewFoundation_HeaderItemWithoutCompactMode.graphql';
import type { IssueDeleteCallbacks } from '../issue-actions/delete-issue/types.tsx';
import {
	HEADER_BREADCRUMBS_COMPACT_BEGIN_MARK_KEY,
	HEADER_BREADCRUMBS_COMPACT_END_MARK_KEY,
	HEADER_ACTIONS_COMPACT_BEGIN_MARK_KEY,
	HEADER_ACTIONS_COMPACT_END_MARK_KEY,
} from './constants.tsx';
import { HeaderActions } from './header-actions/index.tsx';
import { HeaderBreadcrumbs } from './header-breadcrumbs/index.tsx';

export type HeaderProps = {
	issueDeleteCallbacks?: IssueDeleteCallbacks;
	shouldShowCloseButton: boolean;
	shouldShowProjectLevelBreadcrumb?: boolean;
	shouldShowRootProjectsBreadcrumb?: boolean;
	renderFeedback?: FeedbackRenderer;
	viewModeOptions?: ViewModeOptions;
	onClose?: (event?: React.MouseEvent | React.KeyboardEvent) => void;
	issueViewRelayFragment: IssueViewRelayFragment | null | undefined;
	rootRelayFragment: MainIssueAggQueryRelayFragment | null;
};

export type Props = {
	isCompact?: boolean;
} & HeaderProps;

export const HeaderItemWithoutCompactMode = (props: Props) => {
	const {
		shouldShowCloseButton,
		onClose,
		renderFeedback,
		isCompact,
		viewModeOptions,
		issueDeleteCallbacks = {},
		shouldShowProjectLevelBreadcrumb,
		shouldShowRootProjectsBreadcrumb,
		issueViewRelayFragment,
	} = props;

	const data =
		issueViewRelayFragment && fg('relay-migration-issue-header-and-parent')
			? // eslint-disable-next-line @atlassian/relay/query-restriction, react-hooks/rules-of-hooks
				useFragment<header_issueViewFoundation_HeaderItemWithoutCompactMode$key>(
					graphql`
						fragment header_issueViewFoundation_HeaderItemWithoutCompactMode on JiraIssue
						@argumentDefinitions(
							issueViewRelayParentFieldFlag: {
								type: "Boolean!"
								provider: "@atlassian/jira-relay-provider/src/relay-migration-issue-fields-parent"
							}
							issueViewRelayHeaderActionsFlag: {
								type: "Boolean!"
								provider: "@atlassian/jira-relay-provider/src/relay-migration-issue-fields-header-actions.relayprovider"
							}
						) {
							...headerActions_issueViewFoundation_HeaderActionsView
								@include(if: $issueViewRelayHeaderActionsFlag)
							...headerBreadcrumbs_issueViewFoundation_HeaderBreadcrumbsView
								@include(if: $issueViewRelayParentFieldFlag)
						}
					`,
					issueViewRelayFragment,
				)
			: undefined;

	return (
		<IssuePageGrid isCompact={isCompact}>
			<GridColumn medium={issuePageGridColumns}>
				<HeaderWrapper>
					<PerformanceMark metricKey={HEADER_BREADCRUMBS_COMPACT_BEGIN_MARK_KEY} />
					<HeaderBreadcrumbs
						shouldShowProjectLevelBreadcrumb={shouldShowProjectLevelBreadcrumb}
						shouldShowRootProjectsBreadcrumb={shouldShowRootProjectsBreadcrumb}
						isCompact={isCompact}
						issue={data}
					/>
					<PerformanceMark metricKey={HEADER_BREADCRUMBS_COMPACT_END_MARK_KEY} />

					<PerformanceMark metricKey={HEADER_ACTIONS_COMPACT_BEGIN_MARK_KEY} />
					<HeaderActions
						headerActions={data}
						renderFeedback={renderFeedback}
						issueDeleteCallbacks={issueDeleteCallbacks}
						viewModeOptions={viewModeOptions}
						onClose={onClose}
						shouldShowCloseButton={shouldShowCloseButton}
						// @ts-expect-error TS2322 - Property isCompact does not exist on typ
						isCompact={isCompact}
					/>
					<PerformanceMark metricKey={HEADER_ACTIONS_COMPACT_END_MARK_KEY} />
				</HeaderWrapper>
			</GridColumn>
		</IssuePageGrid>
	);
};

// FIXME: remove default props
HeaderItemWithoutCompactMode.defaultProps = {
	shouldShowCloseButton: false,
	shouldShowProjectLevelBreadcrumb: false,
	shouldShowRootProjectsBreadcrumb: false,
	renderFeedback: () => null,
	viewModeOptions: {
		viewModeSwitchEnabled: false,
	},
	onClose: noop,
};

HeaderItemWithoutCompactMode.displayName = 'Header';

const Header: (props: Props) => React.ReactNode = flowWithSafeComponent(withCompactMode)(
	HeaderItemWithoutCompactMode,
);
export default Header;

/*
    In https://jdog.jira-dev.com/browse/BENTO-809 we needed to prevent newlines
    from being copied when the user selects the issue key. In order to do this,
    we grow the LeftAligned portion and selectively apply padding to the Left
    and RightAligned containers so that the space taken up by the issue key is
    larger, and therefore gives the user a larger area to select the issue key
    in.
*/

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderWrapper = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	minHeight: `${gridSize * 4}px`,
	marginBottom: token('space.025', '2px'),
	display: 'flex',
	justifyContent: 'space-between',
	height: '100%',
	alignItems: 'flex-start',
});
