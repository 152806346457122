import React, { useCallback, useState } from 'react';
import { getATLContextUrl } from '@atlassian/atl-context';
import { BaseBlockedAppsInfoBanner } from '@atlassian/jira-common-components-blocked-apps-info-banner/src/main.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useHasBlockedExtensions } from '@atlassian/jira-issue-view-forge-service/src/services/main.tsx';
import { useProjectKey } from '@atlassian/jira-project-context-service/src/main.tsx';
import messages from './messages.tsx';
import { useHasBlockedConnectApps } from './services/connect-addon/index.tsx';

const useHasBlockedExtensionsWithDefaultToFalse = () => {
	const forge = useHasBlockedExtensions() || false;
	const connect = useHasBlockedConnectApps() || false;
	return forge || connect;
};

const useViewBlockedAppsLink = () => `${getATLContextUrl('id')}/manage-profile/apps`;

const useLocalStorage = () => {
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);

	const [notShowAgain, setNotShowAgain] = useState(() =>
		Boolean(localStorage.getItem(`showBlockedAppsBanner-${projectKey}`)),
	);

	const handleDoNotShowAgain = useCallback(() => {
		localStorage.setItem(`showBlockedAppsBanner-${projectKey}`, 'true');
		setNotShowAgain(true);
	}, [projectKey]);

	return [notShowAgain, handleDoNotShowAgain] as const;
};

export const BlockedAppsIssueInfoBanner = () => (
	<BaseBlockedAppsInfoBanner
		getHasBlockedExtensions={useHasBlockedExtensionsWithDefaultToFalse}
		getViewBlockedAppsLink={useViewBlockedAppsLink}
		getMainMessage={() => messages.appsHaveBeenBlockedByYourAdmin}
		dismissalHandler={useLocalStorage}
	/>
);
