import React, { useEffect, useCallback, useRef } from 'react';
import flowRight from 'lodash/flowRight';
import isNil from 'lodash/isNil';
import memoizeOne from 'memoize-one';
import { defineMessages } from '@atlassian/jira-intl';
import { usePriorityField } from '@atlassian/jira-issue-field-priority-chip/src/services/index.tsx';
import type { SelectValueShape } from '@atlassian/jira-issue-internal-field-select/src/common/select-inline-edit/select-field/types.tsx';
import SingleSelectInlineEditView from '@atlassian/jira-issue-internal-field-select/src/single-select-inline-edit/index.tsx';
import type { ServerRestPriority as StateValue } from '@atlassian/jira-issue-shared-types/src/common/types/priority-type.tsx';
import { genericMessages } from '@atlassian/jira-issue-view-common-constants/src/context-items-messages.tsx';
import type {
	AllowedValuesOverrides,
	FieldOptions,
} from '@atlassian/jira-issue-view-common-types/src/connect-field-type.tsx';
import { getIssueModalAkDropdownPortal } from '@atlassian/jira-issue-view-common-utils/src/get-element/index.tsx';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button/index.tsx';
import connectField from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-field.tsx';
import { withExtraOwnProps } from '@atlassian/jira-issue-view-common-views/src/with-extra-own-props/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { fieldAllowedValuesSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector.tsx';
import { fieldUpdate } from '@atlassian/jira-issue-view-store/src/issue-field/state/actions/field-save-actions.tsx';
import { isFieldInTabSelector } from '@atlassian/jira-issue-view-store/src/selectors/tab-selector.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { singleSelectFieldRenderReadView } from '../../common/single-select-field-read-view/main.tsx';
import { filterAllowedValues } from '../../common/utils/filter-select-allowed-values.tsx';
import {
	transformFromStateValue,
	transformToStateValue,
	transformSuggestions,
} from './transformer.tsx';
import type { Props } from './types.tsx';

// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

const messages = defineMessages({
	placeholder: {
		id: 'issue.priority.text-for-edit-view-when-no-value-is-provided',
		defaultMessage: 'Select priority',
		description: '',
	},
});

const fetchSuggestionsFactory = memoizeOne(
	(suggestions) => () => Promise.resolve(suggestions).then(transformSuggestions),
);

// eslint-disable-next-line @typescript-eslint/no-empty-function
const onDataRequest = (): void => {};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const onDataLoaded = (): void => {};

export const PriorityView = (props: Props) => {
	const {
		isEditing,
		value,
		onChange,
		onEditRequest,
		onConfirm,
		onCancel,
		issueKey,
		onSave,
		...rest
	} = props;

	const selectProps = { ...rest, isEditing, value };

	const wasOnChangeCalled = useRef(false);
	const [{ value: valueInSweetState }] = usePriorityField({ issueKey });

	useEffect(() => {
		// Check for values to be present, and check user has not changed the value from the issue view before allowing the state to sync
		const valueUpdatedInSweetState =
			!isNil(value) &&
			!isNil(valueInSweetState) &&
			value.content !== valueInSweetState.name &&
			!wasOnChangeCalled.current;

		if (valueUpdatedInSweetState) {
			onSave && onSave(valueInSweetState);
		}
	}, [isEditing, onSave, value, valueInSweetState]);

	const onChangeHandler = useCallback(
		(selectedValue?: SelectValueShape | null): void => {
			wasOnChangeCalled.current = true;
			onChange(selectedValue);
		},
		[onChange],
	);

	const onEditRequestHandler = useCallback((): void => {
		onEditRequest();
	}, [onEditRequest]);

	const onConfirmHandler = useCallback((): void => {
		wasOnChangeCalled.current = false;
		onConfirm();
	}, [onConfirm]);

	const onCancelHandler = useCallback((): void => {
		onCancel();
	}, [onCancel]);

	return (
		<UFOSegment name="issue-field-priority-redux">
			<SingleSelectInlineEditView
				{...selectProps}
				onEditRequest={onEditRequestHandler}
				onConfirm={onConfirmHandler}
				onCancel={onCancelHandler}
				onChange={onChangeHandler}
				onDataRequest={onDataRequest}
				onDataLoaded={onDataLoaded}
				renderReadView={singleSelectFieldRenderReadView(value)}
			/>
		</UFOSegment>
	);
};

// FIXME: JIV-17455 use function composition
const ConnectedField = connect(
	undefined,
	(
		dispatch,
		ownProps: {
			fieldId: string;
			fieldOptions: FieldOptions<unknown>;
		},
	) => ({
		onSave: (value: unknown) =>
			dispatch(fieldUpdate(ownProps.fieldId, value, ownProps.fieldOptions)),
	}),
)(PriorityView);

export const filterValues = memoizeOne(
	(suggestions: StateValue[], allowedValuesOverrides?: AllowedValuesOverrides) =>
		filterAllowedValues(suggestions, allowedValuesOverrides),
);

export default flowRight(
	withExtraOwnProps,
	connectField((stateOnMount, ownPropsOnMount) => ({
		fieldId: ownPropsOnMount.fieldId,
		transformFromStateValue,
		transformToStateValue,
		additionalProps: (state, intl, fieldOptions, extraOwnProps) => ({
			fetchSuggestions: fetchSuggestionsFactory(
				filterValues(
					fieldAllowedValuesSelector(ownPropsOnMount.fieldId)(state),
					extraOwnProps?.allowedValuesOverrides,
				),
			),
			placeholder: intl.formatMessage(messages.placeholder),
			noValueText: intl.formatMessage(genericMessages.noValue),
			portalElement: isFieldInTabSelector(ownPropsOnMount.fieldId)(state)
				? // eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					getIssueModalAkDropdownPortal() || document.body
				: undefined,
			showPinButton: getShowPinButton(ownPropsOnMount.area),
		}),
	})),
)(ConnectedField);
