import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	applyingUimErrorFlagTitle: {
		id: 'ui-modifications-view-issue-view.entry-point.error-flags.hooks.use-error-flag-config.applying-uim-error-flag-title',
		defaultMessage: 'There was an error with applying UI modifications.',
		description: 'Title displayed on the flag when applying UI modifications failed',
	},
	applyingUimErrorFlagDescription: {
		id: 'ui-modifications-view-issue-view.entry-point.error-flags.hooks.use-error-flag-config.applying-uim-error-flag-description',
		defaultMessage: 'We encountered an error while applying changes made by UI modifications apps.',
		description: 'Description displayed on the flag when applying UI modifications failed',
	},
	genericUimErrorFlagDescription: {
		id: 'ui-modifications-view-issue-view.entry-point.error-flags.hooks.use-error-flag-config.generic-uim-error-flag-description',
		defaultMessage: 'We encountered an error while applying changes made by UI modifications apps.',
		description: 'Description displayed on the flag while applying UI modifications failed',
	},
	loadingUimErrorFlagTitle: {
		id: 'ui-modifications-view-issue-view.entry-point.error-flags.hooks.use-error-flag-config.loading-uim-error-flag-title',
		defaultMessage: 'We couldn’t load UI modifications.',
		description: 'Title displayed on the flag while loading UI modifications failed',
	},
	missingScopesUimErrorFlagTitle: {
		id: 'ui-modifications-view-issue-view.entry-point.error-flags.hooks.use-error-flag-config.missing-scopes-uim-error-flag-title',
		defaultMessage: 'Missing scopes for UI modifications apps',
		description:
			'Title displayed on the flag while applying UI modifications failed due to an missing scopes error',
	},
	missingScopesUimErrorFlagDescription: {
		id: 'ui-modifications-view-issue-view.entry-point.error-flags.hooks.use-error-flag-config.missing-scopes-uim-error-flag-description',
		defaultMessage:
			'We couldn’t load some of the UI modifications apps for this page, because they don’t have required scopes.',
		description:
			'Description displayed on the flag while applying UI modifications failed due to an missing scopes error',
	},
	perFieldHookErrorFlagTitle: {
		id: 'ui-modifications-view-issue-view.entry-point.error-flags.hooks.use-error-flag-config.per-field-hook-error-flag-title',
		defaultMessage: 'We had some issues with applying UI modifications.',
		description:
			'Title displayed on the flag while saving UI modifications value to a field failed',
	},
	perFieldHookErrorFlagDescription: {
		id: 'ui-modifications-view-issue-view.entry-point.error-flags.hooks.use-error-flag-config.per-field-hook-error-flag-description',
		defaultMessage: 'We encountered an error while applying UI modifications to “{fieldName}”.',
		description:
			'Description displayed on the flag while saving UI modifications value to a field failed',
	},
});
