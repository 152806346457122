// Generated from JQLParser.g4 by ANTLR 4.7.3-SNAPSHOT

import { ATN } from 'antlr4ts/atn/ATN';
import { ATNDeserializer } from 'antlr4ts/atn/ATNDeserializer';
import { FailedPredicateException } from 'antlr4ts/FailedPredicateException';
import { NotNull } from 'antlr4ts/Decorators';
import { NoViableAltException } from 'antlr4ts/NoViableAltException';
import { Override } from 'antlr4ts/Decorators';
import { Parser } from 'antlr4ts/Parser';
import { ParserRuleContext } from 'antlr4ts/ParserRuleContext';
import { ParserATNSimulator } from 'antlr4ts/atn/ParserATNSimulator';
import { ParseTreeListener } from 'antlr4ts/tree/ParseTreeListener';
import { ParseTreeVisitor } from 'antlr4ts/tree/ParseTreeVisitor';
import { RecognitionException } from 'antlr4ts/RecognitionException';
import { RuleContext } from 'antlr4ts/RuleContext';
//import { RuleVersion } from "antlr4ts/RuleVersion";
import { TerminalNode } from 'antlr4ts/tree/TerminalNode';
import { Token } from 'antlr4ts/Token';
import { TokenStream } from 'antlr4ts/TokenStream';
import { Vocabulary } from 'antlr4ts/Vocabulary';
import { VocabularyImpl } from 'antlr4ts/VocabularyImpl';

import * as Utils from 'antlr4ts/misc/Utils';

import { JQLParserListener } from './JQLParserListener';
import { JQLParserVisitor } from './JQLParserVisitor';

export class JQLParser extends Parser {
  public static readonly LPAREN = 1;
  public static readonly RPAREN = 2;
  public static readonly COMMA = 3;
  public static readonly LBRACKET = 4;
  public static readonly RBRACKET = 5;
  public static readonly BANG = 6;
  public static readonly LT = 7;
  public static readonly GT = 8;
  public static readonly GTEQ = 9;
  public static readonly LTEQ = 10;
  public static readonly EQUALS = 11;
  public static readonly NOT_EQUALS = 12;
  public static readonly LIKE = 13;
  public static readonly NOT_LIKE = 14;
  public static readonly IN = 15;
  public static readonly IS = 16;
  public static readonly AND = 17;
  public static readonly OR = 18;
  public static readonly NOT = 19;
  public static readonly EMPTY = 20;
  public static readonly WAS = 21;
  public static readonly CHANGED = 22;
  public static readonly BEFORE = 23;
  public static readonly AFTER = 24;
  public static readonly FROM = 25;
  public static readonly TO = 26;
  public static readonly ON = 27;
  public static readonly DURING = 28;
  public static readonly ORDER = 29;
  public static readonly BY = 30;
  public static readonly ASC = 31;
  public static readonly DESC = 32;
  public static readonly POSNUMBER = 33;
  public static readonly NEGNUMBER = 34;
  public static readonly CUSTOMFIELD = 35;
  public static readonly STRING = 36;
  public static readonly QUOTE_STRING = 37;
  public static readonly SQUOTE_STRING = 38;
  public static readonly MATCHWS = 39;
  public static readonly ERROR_RESERVED = 40;
  public static readonly ERRORCHAR = 41;
  public static readonly RULE_query = 0;
  public static readonly RULE_clause = 1;
  public static readonly RULE_orClause = 2;
  public static readonly RULE_andClause = 3;
  public static readonly RULE_notClause = 4;
  public static readonly RULE_subClause = 5;
  public static readonly RULE_terminalClause = 6;
  public static readonly RULE_unaryClause = 7;
  public static readonly RULE_wasClause = 8;
  public static readonly RULE_changedClause = 9;
  public static readonly RULE_historyPredicate = 10;
  public static readonly RULE_terminalHistoryPredicate = 11;
  public static readonly RULE_historyPredicateOperator = 12;
  public static readonly RULE_unaryOperator = 13;
  public static readonly RULE_wasOperator = 14;
  public static readonly RULE_changedOperator = 15;
  public static readonly RULE_field = 16;
  public static readonly RULE_customField = 17;
  public static readonly RULE_operand = 18;
  public static readonly RULE_string = 19;
  public static readonly RULE_numberString = 20;
  public static readonly RULE_list = 21;
  public static readonly RULE_func = 22;
  public static readonly RULE_funcName = 23;
  public static readonly RULE_arglist = 24;
  public static readonly RULE_propertyArgument = 25;
  public static readonly RULE_argument = 26;
  public static readonly RULE_orderBy = 27;
  public static readonly RULE_searchSort = 28;
  // tslint:disable:no-trailing-whitespace
  public static readonly ruleNames: string[] = [
    'query',
    'clause',
    'orClause',
    'andClause',
    'notClause',
    'subClause',
    'terminalClause',
    'unaryClause',
    'wasClause',
    'changedClause',
    'historyPredicate',
    'terminalHistoryPredicate',
    'historyPredicateOperator',
    'unaryOperator',
    'wasOperator',
    'changedOperator',
    'field',
    'customField',
    'operand',
    'string',
    'numberString',
    'list',
    'func',
    'funcName',
    'arglist',
    'propertyArgument',
    'argument',
    'orderBy',
    'searchSort'
  ];

  private static readonly _LITERAL_NAMES: Array<string | undefined> = [
    undefined,
    "'('",
    "')'",
    "','",
    "'['",
    "']'",
    "'!'",
    "'<'",
    "'>'",
    "'>='",
    "'<='",
    "'='",
    "'!='",
    "'~'",
    "'!~'"
  ];
  private static readonly _SYMBOLIC_NAMES: Array<string | undefined> = [
    undefined,
    'LPAREN',
    'RPAREN',
    'COMMA',
    'LBRACKET',
    'RBRACKET',
    'BANG',
    'LT',
    'GT',
    'GTEQ',
    'LTEQ',
    'EQUALS',
    'NOT_EQUALS',
    'LIKE',
    'NOT_LIKE',
    'IN',
    'IS',
    'AND',
    'OR',
    'NOT',
    'EMPTY',
    'WAS',
    'CHANGED',
    'BEFORE',
    'AFTER',
    'FROM',
    'TO',
    'ON',
    'DURING',
    'ORDER',
    'BY',
    'ASC',
    'DESC',
    'POSNUMBER',
    'NEGNUMBER',
    'CUSTOMFIELD',
    'STRING',
    'QUOTE_STRING',
    'SQUOTE_STRING',
    'MATCHWS',
    'ERROR_RESERVED',
    'ERRORCHAR'
  ];
  public static readonly VOCABULARY: Vocabulary = new VocabularyImpl(
    JQLParser._LITERAL_NAMES,
    JQLParser._SYMBOLIC_NAMES,
    []
  );

  // @Override
  // @NotNull
  public get vocabulary(): Vocabulary {
    return JQLParser.VOCABULARY;
  }
  // tslint:enable:no-trailing-whitespace

  // @Override
  public get grammarFileName(): string {
    return 'JQLParser.g4';
  }

  // @Override
  public get ruleNames(): string[] {
    return JQLParser.ruleNames;
  }

  // @Override
  public get serializedATN(): string {
    return JQLParser._serializedATN;
  }

  constructor(input: TokenStream) {
    super(input);
    this._interp = new ParserATNSimulator(JQLParser._ATN, this);
  }
  // @RuleVersion(0)
  public query(): QueryContext {
    let _localctx: QueryContext = new QueryContext(this._ctx, this.state);
    this.enterRule(_localctx, 0, JQLParser.RULE_query);
    let _la: number;
    try {
      this.enterOuterAlt(_localctx, 1);
      {
        this.state = 59;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if (
          ((_la & ~0x1f) === 0 &&
            ((1 << _la) &
              ((1 << JQLParser.LPAREN) | (1 << JQLParser.BANG) | (1 << JQLParser.NOT))) !==
              0) ||
          (((_la - 33) & ~0x1f) === 0 &&
            ((1 << (_la - 33)) &
              ((1 << (JQLParser.POSNUMBER - 33)) |
                (1 << (JQLParser.NEGNUMBER - 33)) |
                (1 << (JQLParser.CUSTOMFIELD - 33)) |
                (1 << (JQLParser.STRING - 33)) |
                (1 << (JQLParser.QUOTE_STRING - 33)) |
                (1 << (JQLParser.SQUOTE_STRING - 33)))) !==
              0)
        ) {
          {
            this.state = 58;
            this.clause();
          }
        }

        this.state = 62;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if (_la === JQLParser.ORDER) {
          {
            this.state = 61;
            this.orderBy();
          }
        }

        this.state = 64;
        this.match(JQLParser.EOF);
      }
    } catch (re) {
      if (re instanceof RecognitionException) {
        _localctx.exception = re;
        this._errHandler.reportError(this, re);
        this._errHandler.recover(this, re);
      } else {
        throw re;
      }
    } finally {
      this.exitRule();
    }
    return _localctx;
  }
  // @RuleVersion(0)
  public clause(): ClauseContext {
    let _localctx: ClauseContext = new ClauseContext(this._ctx, this.state);
    this.enterRule(_localctx, 2, JQLParser.RULE_clause);
    try {
      this.enterOuterAlt(_localctx, 1);
      {
        this.state = 66;
        this.orClause();
      }
    } catch (re) {
      if (re instanceof RecognitionException) {
        _localctx.exception = re;
        this._errHandler.reportError(this, re);
        this._errHandler.recover(this, re);
      } else {
        throw re;
      }
    } finally {
      this.exitRule();
    }
    return _localctx;
  }
  // @RuleVersion(0)
  public orClause(): OrClauseContext {
    let _localctx: OrClauseContext = new OrClauseContext(this._ctx, this.state);
    this.enterRule(_localctx, 4, JQLParser.RULE_orClause);
    let _la: number;
    try {
      this.enterOuterAlt(_localctx, 1);
      {
        this.state = 68;
        this.andClause();
        this.state = 73;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        while (_la === JQLParser.OR) {
          {
            {
              this.state = 69;
              this.match(JQLParser.OR);
              this.state = 70;
              this.andClause();
            }
          }
          this.state = 75;
          this._errHandler.sync(this);
          _la = this._input.LA(1);
        }
      }
    } catch (re) {
      if (re instanceof RecognitionException) {
        _localctx.exception = re;
        this._errHandler.reportError(this, re);
        this._errHandler.recover(this, re);
      } else {
        throw re;
      }
    } finally {
      this.exitRule();
    }
    return _localctx;
  }
  // @RuleVersion(0)
  public andClause(): AndClauseContext {
    let _localctx: AndClauseContext = new AndClauseContext(this._ctx, this.state);
    this.enterRule(_localctx, 6, JQLParser.RULE_andClause);
    let _la: number;
    try {
      this.enterOuterAlt(_localctx, 1);
      {
        this.state = 76;
        this.notClause();
        this.state = 81;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        while (_la === JQLParser.AND) {
          {
            {
              this.state = 77;
              this.match(JQLParser.AND);
              this.state = 78;
              this.notClause();
            }
          }
          this.state = 83;
          this._errHandler.sync(this);
          _la = this._input.LA(1);
        }
      }
    } catch (re) {
      if (re instanceof RecognitionException) {
        _localctx.exception = re;
        this._errHandler.reportError(this, re);
        this._errHandler.recover(this, re);
      } else {
        throw re;
      }
    } finally {
      this.exitRule();
    }
    return _localctx;
  }
  // @RuleVersion(0)
  public notClause(): NotClauseContext {
    let _localctx: NotClauseContext = new NotClauseContext(this._ctx, this.state);
    this.enterRule(_localctx, 8, JQLParser.RULE_notClause);
    let _la: number;
    try {
      this.state = 88;
      this._errHandler.sync(this);
      switch (this._input.LA(1)) {
        case JQLParser.BANG:
        case JQLParser.NOT:
          this.enterOuterAlt(_localctx, 1);
          {
            this.state = 84;
            _la = this._input.LA(1);
            if (!(_la === JQLParser.BANG || _la === JQLParser.NOT)) {
              this._errHandler.recoverInline(this);
            } else {
              if (this._input.LA(1) === Token.EOF) {
                this.matchedEOF = true;
              }

              this._errHandler.reportMatch(this);
              this.consume();
            }
            this.state = 85;
            this.notClause();
          }
          break;
        case JQLParser.LPAREN:
          this.enterOuterAlt(_localctx, 2);
          {
            this.state = 86;
            this.subClause();
          }
          break;
        case JQLParser.POSNUMBER:
        case JQLParser.NEGNUMBER:
        case JQLParser.CUSTOMFIELD:
        case JQLParser.STRING:
        case JQLParser.QUOTE_STRING:
        case JQLParser.SQUOTE_STRING:
          this.enterOuterAlt(_localctx, 3);
          {
            this.state = 87;
            this.terminalClause();
          }
          break;
        default:
          throw new NoViableAltException(this);
      }
    } catch (re) {
      if (re instanceof RecognitionException) {
        _localctx.exception = re;
        this._errHandler.reportError(this, re);
        this._errHandler.recover(this, re);
      } else {
        throw re;
      }
    } finally {
      this.exitRule();
    }
    return _localctx;
  }
  // @RuleVersion(0)
  public subClause(): SubClauseContext {
    let _localctx: SubClauseContext = new SubClauseContext(this._ctx, this.state);
    this.enterRule(_localctx, 10, JQLParser.RULE_subClause);
    try {
      this.enterOuterAlt(_localctx, 1);
      {
        this.state = 90;
        this.match(JQLParser.LPAREN);
        this.state = 91;
        this.orClause();
        this.state = 92;
        this.match(JQLParser.RPAREN);
      }
    } catch (re) {
      if (re instanceof RecognitionException) {
        _localctx.exception = re;
        this._errHandler.reportError(this, re);
        this._errHandler.recover(this, re);
      } else {
        throw re;
      }
    } finally {
      this.exitRule();
    }
    return _localctx;
  }
  // @RuleVersion(0)
  public terminalClause(): TerminalClauseContext {
    let _localctx: TerminalClauseContext = new TerminalClauseContext(this._ctx, this.state);
    this.enterRule(_localctx, 12, JQLParser.RULE_terminalClause);
    try {
      this.enterOuterAlt(_localctx, 1);
      {
        this.state = 94;
        this.field();
        this.state = 98;
        this._errHandler.sync(this);
        switch (this._input.LA(1)) {
          case JQLParser.LT:
          case JQLParser.GT:
          case JQLParser.GTEQ:
          case JQLParser.LTEQ:
          case JQLParser.EQUALS:
          case JQLParser.NOT_EQUALS:
          case JQLParser.LIKE:
          case JQLParser.NOT_LIKE:
          case JQLParser.IN:
          case JQLParser.IS:
          case JQLParser.NOT:
            {
              this.state = 95;
              this.unaryClause();
            }
            break;
          case JQLParser.WAS:
            {
              this.state = 96;
              this.wasClause();
            }
            break;
          case JQLParser.CHANGED:
            {
              this.state = 97;
              this.changedClause();
            }
            break;
          default:
            throw new NoViableAltException(this);
        }
      }
    } catch (re) {
      if (re instanceof RecognitionException) {
        _localctx.exception = re;
        this._errHandler.reportError(this, re);
        this._errHandler.recover(this, re);
      } else {
        throw re;
      }
    } finally {
      this.exitRule();
    }
    return _localctx;
  }
  // @RuleVersion(0)
  public unaryClause(): UnaryClauseContext {
    let _localctx: UnaryClauseContext = new UnaryClauseContext(this._ctx, this.state);
    this.enterRule(_localctx, 14, JQLParser.RULE_unaryClause);
    try {
      this.enterOuterAlt(_localctx, 1);
      {
        this.state = 100;
        this.unaryOperator();
        this.state = 101;
        this.operand();
      }
    } catch (re) {
      if (re instanceof RecognitionException) {
        _localctx.exception = re;
        this._errHandler.reportError(this, re);
        this._errHandler.recover(this, re);
      } else {
        throw re;
      }
    } finally {
      this.exitRule();
    }
    return _localctx;
  }
  // @RuleVersion(0)
  public wasClause(): WasClauseContext {
    let _localctx: WasClauseContext = new WasClauseContext(this._ctx, this.state);
    this.enterRule(_localctx, 16, JQLParser.RULE_wasClause);
    let _la: number;
    try {
      this.enterOuterAlt(_localctx, 1);
      {
        this.state = 103;
        this.wasOperator();
        this.state = 104;
        this.operand();
        this.state = 106;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if (
          (_la & ~0x1f) === 0 &&
          ((1 << _la) &
            ((1 << JQLParser.BEFORE) |
              (1 << JQLParser.AFTER) |
              (1 << JQLParser.FROM) |
              (1 << JQLParser.TO) |
              (1 << JQLParser.ON) |
              (1 << JQLParser.DURING) |
              (1 << JQLParser.BY))) !==
            0
        ) {
          {
            this.state = 105;
            this.historyPredicate();
          }
        }
      }
    } catch (re) {
      if (re instanceof RecognitionException) {
        _localctx.exception = re;
        this._errHandler.reportError(this, re);
        this._errHandler.recover(this, re);
      } else {
        throw re;
      }
    } finally {
      this.exitRule();
    }
    return _localctx;
  }
  // @RuleVersion(0)
  public changedClause(): ChangedClauseContext {
    let _localctx: ChangedClauseContext = new ChangedClauseContext(this._ctx, this.state);
    this.enterRule(_localctx, 18, JQLParser.RULE_changedClause);
    let _la: number;
    try {
      this.enterOuterAlt(_localctx, 1);
      {
        this.state = 108;
        this.changedOperator();
        this.state = 110;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if (
          (_la & ~0x1f) === 0 &&
          ((1 << _la) &
            ((1 << JQLParser.BEFORE) |
              (1 << JQLParser.AFTER) |
              (1 << JQLParser.FROM) |
              (1 << JQLParser.TO) |
              (1 << JQLParser.ON) |
              (1 << JQLParser.DURING) |
              (1 << JQLParser.BY))) !==
            0
        ) {
          {
            this.state = 109;
            this.historyPredicate();
          }
        }
      }
    } catch (re) {
      if (re instanceof RecognitionException) {
        _localctx.exception = re;
        this._errHandler.reportError(this, re);
        this._errHandler.recover(this, re);
      } else {
        throw re;
      }
    } finally {
      this.exitRule();
    }
    return _localctx;
  }
  // @RuleVersion(0)
  public historyPredicate(): HistoryPredicateContext {
    let _localctx: HistoryPredicateContext = new HistoryPredicateContext(this._ctx, this.state);
    this.enterRule(_localctx, 20, JQLParser.RULE_historyPredicate);
    let _la: number;
    try {
      this.enterOuterAlt(_localctx, 1);
      {
        this.state = 113;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        do {
          {
            {
              this.state = 112;
              this.terminalHistoryPredicate();
            }
          }
          this.state = 115;
          this._errHandler.sync(this);
          _la = this._input.LA(1);
        } while (
          (_la & ~0x1f) === 0 &&
          ((1 << _la) &
            ((1 << JQLParser.BEFORE) |
              (1 << JQLParser.AFTER) |
              (1 << JQLParser.FROM) |
              (1 << JQLParser.TO) |
              (1 << JQLParser.ON) |
              (1 << JQLParser.DURING) |
              (1 << JQLParser.BY))) !==
            0
        );
      }
    } catch (re) {
      if (re instanceof RecognitionException) {
        _localctx.exception = re;
        this._errHandler.reportError(this, re);
        this._errHandler.recover(this, re);
      } else {
        throw re;
      }
    } finally {
      this.exitRule();
    }
    return _localctx;
  }
  // @RuleVersion(0)
  public terminalHistoryPredicate(): TerminalHistoryPredicateContext {
    let _localctx: TerminalHistoryPredicateContext = new TerminalHistoryPredicateContext(
      this._ctx,
      this.state
    );
    this.enterRule(_localctx, 22, JQLParser.RULE_terminalHistoryPredicate);
    try {
      this.enterOuterAlt(_localctx, 1);
      {
        this.state = 117;
        this.historyPredicateOperator();
        this.state = 118;
        this.operand();
      }
    } catch (re) {
      if (re instanceof RecognitionException) {
        _localctx.exception = re;
        this._errHandler.reportError(this, re);
        this._errHandler.recover(this, re);
      } else {
        throw re;
      }
    } finally {
      this.exitRule();
    }
    return _localctx;
  }
  // @RuleVersion(0)
  public historyPredicateOperator(): HistoryPredicateOperatorContext {
    let _localctx: HistoryPredicateOperatorContext = new HistoryPredicateOperatorContext(
      this._ctx,
      this.state
    );
    this.enterRule(_localctx, 24, JQLParser.RULE_historyPredicateOperator);
    let _la: number;
    try {
      this.enterOuterAlt(_localctx, 1);
      {
        this.state = 120;
        _la = this._input.LA(1);
        if (
          !(
            (_la & ~0x1f) === 0 &&
            ((1 << _la) &
              ((1 << JQLParser.BEFORE) |
                (1 << JQLParser.AFTER) |
                (1 << JQLParser.FROM) |
                (1 << JQLParser.TO) |
                (1 << JQLParser.ON) |
                (1 << JQLParser.DURING) |
                (1 << JQLParser.BY))) !==
              0
          )
        ) {
          this._errHandler.recoverInline(this);
        } else {
          if (this._input.LA(1) === Token.EOF) {
            this.matchedEOF = true;
          }

          this._errHandler.reportMatch(this);
          this.consume();
        }
      }
    } catch (re) {
      if (re instanceof RecognitionException) {
        _localctx.exception = re;
        this._errHandler.reportError(this, re);
        this._errHandler.recover(this, re);
      } else {
        throw re;
      }
    } finally {
      this.exitRule();
    }
    return _localctx;
  }
  // @RuleVersion(0)
  public unaryOperator(): UnaryOperatorContext {
    let _localctx: UnaryOperatorContext = new UnaryOperatorContext(this._ctx, this.state);
    this.enterRule(_localctx, 26, JQLParser.RULE_unaryOperator);
    try {
      this.state = 136;
      this._errHandler.sync(this);
      switch (this.interpreter.adaptivePredict(this._input, 9, this._ctx)) {
        case 1:
          this.enterOuterAlt(_localctx, 1);
          {
            this.state = 122;
            this.match(JQLParser.EQUALS);
          }
          break;

        case 2:
          this.enterOuterAlt(_localctx, 2);
          {
            this.state = 123;
            this.match(JQLParser.NOT_EQUALS);
          }
          break;

        case 3:
          this.enterOuterAlt(_localctx, 3);
          {
            this.state = 124;
            this.match(JQLParser.LIKE);
          }
          break;

        case 4:
          this.enterOuterAlt(_localctx, 4);
          {
            this.state = 125;
            this.match(JQLParser.NOT_LIKE);
          }
          break;

        case 5:
          this.enterOuterAlt(_localctx, 5);
          {
            this.state = 126;
            this.match(JQLParser.LT);
          }
          break;

        case 6:
          this.enterOuterAlt(_localctx, 6);
          {
            this.state = 127;
            this.match(JQLParser.GT);
          }
          break;

        case 7:
          this.enterOuterAlt(_localctx, 7);
          {
            this.state = 128;
            this.match(JQLParser.LTEQ);
          }
          break;

        case 8:
          this.enterOuterAlt(_localctx, 8);
          {
            this.state = 129;
            this.match(JQLParser.GTEQ);
          }
          break;

        case 9:
          this.enterOuterAlt(_localctx, 9);
          {
            this.state = 130;
            this.match(JQLParser.IN);
          }
          break;

        case 10:
          this.enterOuterAlt(_localctx, 10);
          {
            this.state = 131;
            this.match(JQLParser.IS);
            this.state = 132;
            this.match(JQLParser.NOT);
          }
          break;

        case 11:
          this.enterOuterAlt(_localctx, 11);
          {
            this.state = 133;
            this.match(JQLParser.IS);
          }
          break;

        case 12:
          this.enterOuterAlt(_localctx, 12);
          {
            this.state = 134;
            this.match(JQLParser.NOT);
            this.state = 135;
            this.match(JQLParser.IN);
          }
          break;
      }
    } catch (re) {
      if (re instanceof RecognitionException) {
        _localctx.exception = re;
        this._errHandler.reportError(this, re);
        this._errHandler.recover(this, re);
      } else {
        throw re;
      }
    } finally {
      this.exitRule();
    }
    return _localctx;
  }
  // @RuleVersion(0)
  public wasOperator(): WasOperatorContext {
    let _localctx: WasOperatorContext = new WasOperatorContext(this._ctx, this.state);
    this.enterRule(_localctx, 28, JQLParser.RULE_wasOperator);
    try {
      this.state = 146;
      this._errHandler.sync(this);
      switch (this.interpreter.adaptivePredict(this._input, 10, this._ctx)) {
        case 1:
          this.enterOuterAlt(_localctx, 1);
          {
            this.state = 138;
            this.match(JQLParser.WAS);
          }
          break;

        case 2:
          this.enterOuterAlt(_localctx, 2);
          {
            this.state = 139;
            this.match(JQLParser.WAS);
            this.state = 140;
            this.match(JQLParser.NOT);
          }
          break;

        case 3:
          this.enterOuterAlt(_localctx, 3);
          {
            this.state = 141;
            this.match(JQLParser.WAS);
            this.state = 142;
            this.match(JQLParser.IN);
          }
          break;

        case 4:
          this.enterOuterAlt(_localctx, 4);
          {
            this.state = 143;
            this.match(JQLParser.WAS);
            this.state = 144;
            this.match(JQLParser.NOT);
            this.state = 145;
            this.match(JQLParser.IN);
          }
          break;
      }
    } catch (re) {
      if (re instanceof RecognitionException) {
        _localctx.exception = re;
        this._errHandler.reportError(this, re);
        this._errHandler.recover(this, re);
      } else {
        throw re;
      }
    } finally {
      this.exitRule();
    }
    return _localctx;
  }
  // @RuleVersion(0)
  public changedOperator(): ChangedOperatorContext {
    let _localctx: ChangedOperatorContext = new ChangedOperatorContext(this._ctx, this.state);
    this.enterRule(_localctx, 30, JQLParser.RULE_changedOperator);
    try {
      this.enterOuterAlt(_localctx, 1);
      {
        this.state = 148;
        this.match(JQLParser.CHANGED);
      }
    } catch (re) {
      if (re instanceof RecognitionException) {
        _localctx.exception = re;
        this._errHandler.reportError(this, re);
        this._errHandler.recover(this, re);
      } else {
        throw re;
      }
    } finally {
      this.exitRule();
    }
    return _localctx;
  }
  // @RuleVersion(0)
  public field(): FieldContext {
    let _localctx: FieldContext = new FieldContext(this._ctx, this.state);
    this.enterRule(_localctx, 32, JQLParser.RULE_field);
    let _la: number;
    try {
      this.state = 170;
      this._errHandler.sync(this);
      switch (this._input.LA(1)) {
        case JQLParser.POSNUMBER:
        case JQLParser.NEGNUMBER:
          _localctx = new NumberFieldContext(_localctx);
          this.enterOuterAlt(_localctx, 1);
          {
            this.state = 150;
            this.numberString();
          }
          break;
        case JQLParser.CUSTOMFIELD:
        case JQLParser.STRING:
        case JQLParser.QUOTE_STRING:
        case JQLParser.SQUOTE_STRING:
          _localctx = new NonNumberFieldContext(_localctx);
          this.enterOuterAlt(_localctx, 2);
          {
            {
              this.state = 153;
              this._errHandler.sync(this);
              switch (this._input.LA(1)) {
                case JQLParser.STRING:
                case JQLParser.QUOTE_STRING:
                case JQLParser.SQUOTE_STRING:
                  {
                    this.state = 151;
                    this.string();
                  }
                  break;
                case JQLParser.CUSTOMFIELD:
                  {
                    this.state = 152;
                    this.customField();
                  }
                  break;
                default:
                  throw new NoViableAltException(this);
              }
              this.state = 167;
              this._errHandler.sync(this);
              _la = this._input.LA(1);
              while (_la === JQLParser.LBRACKET) {
                {
                  {
                    {
                      this.state = 155;
                      this.match(JQLParser.LBRACKET);
                      {
                        this.state = 156;
                        this.argument();
                      }
                      this.state = 157;
                      this.match(JQLParser.RBRACKET);
                    }
                    this.state = 162;
                    this._errHandler.sync(this);
                    _la = this._input.LA(1);
                    while (
                      ((_la - 33) & ~0x1f) === 0 &&
                      ((1 << (_la - 33)) &
                        ((1 << (JQLParser.POSNUMBER - 33)) |
                          (1 << (JQLParser.NEGNUMBER - 33)) |
                          (1 << (JQLParser.STRING - 33)) |
                          (1 << (JQLParser.QUOTE_STRING - 33)) |
                          (1 << (JQLParser.SQUOTE_STRING - 33)))) !==
                        0
                    ) {
                      {
                        {
                          this.state = 159;
                          this.propertyArgument();
                        }
                      }
                      this.state = 164;
                      this._errHandler.sync(this);
                      _la = this._input.LA(1);
                    }
                  }
                }
                this.state = 169;
                this._errHandler.sync(this);
                _la = this._input.LA(1);
              }
            }
          }
          break;
        default:
          throw new NoViableAltException(this);
      }
    } catch (re) {
      if (re instanceof RecognitionException) {
        _localctx.exception = re;
        this._errHandler.reportError(this, re);
        this._errHandler.recover(this, re);
      } else {
        throw re;
      }
    } finally {
      this.exitRule();
    }
    return _localctx;
  }
  // @RuleVersion(0)
  public customField(): CustomFieldContext {
    let _localctx: CustomFieldContext = new CustomFieldContext(this._ctx, this.state);
    this.enterRule(_localctx, 34, JQLParser.RULE_customField);
    try {
      this.enterOuterAlt(_localctx, 1);
      {
        this.state = 172;
        this.match(JQLParser.CUSTOMFIELD);
        this.state = 173;
        this.match(JQLParser.LBRACKET);
        this.state = 174;
        this.match(JQLParser.POSNUMBER);
        this.state = 175;
        this.match(JQLParser.RBRACKET);
      }
    } catch (re) {
      if (re instanceof RecognitionException) {
        _localctx.exception = re;
        this._errHandler.reportError(this, re);
        this._errHandler.recover(this, re);
      } else {
        throw re;
      }
    } finally {
      this.exitRule();
    }
    return _localctx;
  }
  // @RuleVersion(0)
  public operand(): OperandContext {
    let _localctx: OperandContext = new OperandContext(this._ctx, this.state);
    this.enterRule(_localctx, 36, JQLParser.RULE_operand);
    try {
      this.state = 182;
      this._errHandler.sync(this);
      switch (this.interpreter.adaptivePredict(this._input, 15, this._ctx)) {
        case 1:
          this.enterOuterAlt(_localctx, 1);
          {
            this.state = 177;
            this.match(JQLParser.EMPTY);
          }
          break;

        case 2:
          this.enterOuterAlt(_localctx, 2);
          {
            this.state = 178;
            this.string();
          }
          break;

        case 3:
          this.enterOuterAlt(_localctx, 3);
          {
            this.state = 179;
            this.numberString();
          }
          break;

        case 4:
          this.enterOuterAlt(_localctx, 4);
          {
            this.state = 180;
            this.func();
          }
          break;

        case 5:
          this.enterOuterAlt(_localctx, 5);
          {
            this.state = 181;
            this.list();
          }
          break;
      }
    } catch (re) {
      if (re instanceof RecognitionException) {
        _localctx.exception = re;
        this._errHandler.reportError(this, re);
        this._errHandler.recover(this, re);
      } else {
        throw re;
      }
    } finally {
      this.exitRule();
    }
    return _localctx;
  }
  // @RuleVersion(0)
  public string(): StringContext {
    let _localctx: StringContext = new StringContext(this._ctx, this.state);
    this.enterRule(_localctx, 38, JQLParser.RULE_string);
    let _la: number;
    try {
      this.enterOuterAlt(_localctx, 1);
      {
        this.state = 184;
        _la = this._input.LA(1);
        if (
          !(
            ((_la - 36) & ~0x1f) === 0 &&
            ((1 << (_la - 36)) &
              ((1 << (JQLParser.STRING - 36)) |
                (1 << (JQLParser.QUOTE_STRING - 36)) |
                (1 << (JQLParser.SQUOTE_STRING - 36)))) !==
              0
          )
        ) {
          this._errHandler.recoverInline(this);
        } else {
          if (this._input.LA(1) === Token.EOF) {
            this.matchedEOF = true;
          }

          this._errHandler.reportMatch(this);
          this.consume();
        }
      }
    } catch (re) {
      if (re instanceof RecognitionException) {
        _localctx.exception = re;
        this._errHandler.reportError(this, re);
        this._errHandler.recover(this, re);
      } else {
        throw re;
      }
    } finally {
      this.exitRule();
    }
    return _localctx;
  }
  // @RuleVersion(0)
  public numberString(): NumberStringContext {
    let _localctx: NumberStringContext = new NumberStringContext(this._ctx, this.state);
    this.enterRule(_localctx, 40, JQLParser.RULE_numberString);
    let _la: number;
    try {
      this.enterOuterAlt(_localctx, 1);
      {
        this.state = 186;
        _localctx._num = this._input.LT(1);
        _la = this._input.LA(1);
        if (!(_la === JQLParser.POSNUMBER || _la === JQLParser.NEGNUMBER)) {
          _localctx._num = this._errHandler.recoverInline(this);
        } else {
          if (this._input.LA(1) === Token.EOF) {
            this.matchedEOF = true;
          }

          this._errHandler.reportMatch(this);
          this.consume();
        }
      }
    } catch (re) {
      if (re instanceof RecognitionException) {
        _localctx.exception = re;
        this._errHandler.reportError(this, re);
        this._errHandler.recover(this, re);
      } else {
        throw re;
      }
    } finally {
      this.exitRule();
    }
    return _localctx;
  }
  // @RuleVersion(0)
  public list(): ListContext {
    let _localctx: ListContext = new ListContext(this._ctx, this.state);
    this.enterRule(_localctx, 42, JQLParser.RULE_list);
    let _la: number;
    try {
      this.enterOuterAlt(_localctx, 1);
      {
        this.state = 188;
        this.match(JQLParser.LPAREN);
        this.state = 189;
        this.operand();
        this.state = 194;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        while (_la === JQLParser.COMMA) {
          {
            {
              this.state = 190;
              this.match(JQLParser.COMMA);
              this.state = 191;
              this.operand();
            }
          }
          this.state = 196;
          this._errHandler.sync(this);
          _la = this._input.LA(1);
        }
        this.state = 197;
        this.match(JQLParser.RPAREN);
      }
    } catch (re) {
      if (re instanceof RecognitionException) {
        _localctx.exception = re;
        this._errHandler.reportError(this, re);
        this._errHandler.recover(this, re);
      } else {
        throw re;
      }
    } finally {
      this.exitRule();
    }
    return _localctx;
  }
  // @RuleVersion(0)
  public func(): FuncContext {
    let _localctx: FuncContext = new FuncContext(this._ctx, this.state);
    this.enterRule(_localctx, 44, JQLParser.RULE_func);
    let _la: number;
    try {
      this.enterOuterAlt(_localctx, 1);
      {
        this.state = 199;
        this.funcName();
        this.state = 200;
        this.match(JQLParser.LPAREN);
        this.state = 202;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if (
          ((_la - 33) & ~0x1f) === 0 &&
          ((1 << (_la - 33)) &
            ((1 << (JQLParser.POSNUMBER - 33)) |
              (1 << (JQLParser.NEGNUMBER - 33)) |
              (1 << (JQLParser.STRING - 33)) |
              (1 << (JQLParser.QUOTE_STRING - 33)) |
              (1 << (JQLParser.SQUOTE_STRING - 33)))) !==
            0
        ) {
          {
            this.state = 201;
            this.arglist();
          }
        }

        this.state = 204;
        this.match(JQLParser.RPAREN);
      }
    } catch (re) {
      if (re instanceof RecognitionException) {
        _localctx.exception = re;
        this._errHandler.reportError(this, re);
        this._errHandler.recover(this, re);
      } else {
        throw re;
      }
    } finally {
      this.exitRule();
    }
    return _localctx;
  }
  // @RuleVersion(0)
  public funcName(): FuncNameContext {
    let _localctx: FuncNameContext = new FuncNameContext(this._ctx, this.state);
    this.enterRule(_localctx, 46, JQLParser.RULE_funcName);
    try {
      this.state = 208;
      this._errHandler.sync(this);
      switch (this._input.LA(1)) {
        case JQLParser.STRING:
        case JQLParser.QUOTE_STRING:
        case JQLParser.SQUOTE_STRING:
          this.enterOuterAlt(_localctx, 1);
          {
            this.state = 206;
            this.string();
          }
          break;
        case JQLParser.POSNUMBER:
        case JQLParser.NEGNUMBER:
          this.enterOuterAlt(_localctx, 2);
          {
            this.state = 207;
            this.numberString();
          }
          break;
        default:
          throw new NoViableAltException(this);
      }
    } catch (re) {
      if (re instanceof RecognitionException) {
        _localctx.exception = re;
        this._errHandler.reportError(this, re);
        this._errHandler.recover(this, re);
      } else {
        throw re;
      }
    } finally {
      this.exitRule();
    }
    return _localctx;
  }
  // @RuleVersion(0)
  public arglist(): ArglistContext {
    let _localctx: ArglistContext = new ArglistContext(this._ctx, this.state);
    this.enterRule(_localctx, 48, JQLParser.RULE_arglist);
    let _la: number;
    try {
      this.enterOuterAlt(_localctx, 1);
      {
        this.state = 210;
        this.argument();
        this.state = 215;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        while (_la === JQLParser.COMMA) {
          {
            {
              this.state = 211;
              this.match(JQLParser.COMMA);
              this.state = 212;
              this.argument();
            }
          }
          this.state = 217;
          this._errHandler.sync(this);
          _la = this._input.LA(1);
        }
      }
    } catch (re) {
      if (re instanceof RecognitionException) {
        _localctx.exception = re;
        this._errHandler.reportError(this, re);
        this._errHandler.recover(this, re);
      } else {
        throw re;
      }
    } finally {
      this.exitRule();
    }
    return _localctx;
  }
  // @RuleVersion(0)
  public propertyArgument(): PropertyArgumentContext {
    let _localctx: PropertyArgumentContext = new PropertyArgumentContext(this._ctx, this.state);
    this.enterRule(_localctx, 50, JQLParser.RULE_propertyArgument);
    try {
      this.enterOuterAlt(_localctx, 1);
      {
        this.state = 218;
        this.argument();
      }
    } catch (re) {
      if (re instanceof RecognitionException) {
        _localctx.exception = re;
        this._errHandler.reportError(this, re);
        this._errHandler.recover(this, re);
      } else {
        throw re;
      }
    } finally {
      this.exitRule();
    }
    return _localctx;
  }
  // @RuleVersion(0)
  public argument(): ArgumentContext {
    let _localctx: ArgumentContext = new ArgumentContext(this._ctx, this.state);
    this.enterRule(_localctx, 52, JQLParser.RULE_argument);
    try {
      this.state = 222;
      this._errHandler.sync(this);
      switch (this._input.LA(1)) {
        case JQLParser.STRING:
        case JQLParser.QUOTE_STRING:
        case JQLParser.SQUOTE_STRING:
          this.enterOuterAlt(_localctx, 1);
          {
            this.state = 220;
            this.string();
          }
          break;
        case JQLParser.POSNUMBER:
        case JQLParser.NEGNUMBER:
          this.enterOuterAlt(_localctx, 2);
          {
            this.state = 221;
            this.numberString();
          }
          break;
        default:
          throw new NoViableAltException(this);
      }
    } catch (re) {
      if (re instanceof RecognitionException) {
        _localctx.exception = re;
        this._errHandler.reportError(this, re);
        this._errHandler.recover(this, re);
      } else {
        throw re;
      }
    } finally {
      this.exitRule();
    }
    return _localctx;
  }
  // @RuleVersion(0)
  public orderBy(): OrderByContext {
    let _localctx: OrderByContext = new OrderByContext(this._ctx, this.state);
    this.enterRule(_localctx, 54, JQLParser.RULE_orderBy);
    let _la: number;
    try {
      this.enterOuterAlt(_localctx, 1);
      {
        this.state = 224;
        this.match(JQLParser.ORDER);
        this.state = 225;
        this.match(JQLParser.BY);
        this.state = 226;
        this.searchSort();
        this.state = 231;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        while (_la === JQLParser.COMMA) {
          {
            {
              this.state = 227;
              this.match(JQLParser.COMMA);
              this.state = 228;
              this.searchSort();
            }
          }
          this.state = 233;
          this._errHandler.sync(this);
          _la = this._input.LA(1);
        }
      }
    } catch (re) {
      if (re instanceof RecognitionException) {
        _localctx.exception = re;
        this._errHandler.reportError(this, re);
        this._errHandler.recover(this, re);
      } else {
        throw re;
      }
    } finally {
      this.exitRule();
    }
    return _localctx;
  }
  // @RuleVersion(0)
  public searchSort(): SearchSortContext {
    let _localctx: SearchSortContext = new SearchSortContext(this._ctx, this.state);
    this.enterRule(_localctx, 56, JQLParser.RULE_searchSort);
    let _la: number;
    try {
      this.enterOuterAlt(_localctx, 1);
      {
        this.state = 234;
        this.field();
        this.state = 236;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        if (_la === JQLParser.ASC || _la === JQLParser.DESC) {
          {
            this.state = 235;
            _la = this._input.LA(1);
            if (!(_la === JQLParser.ASC || _la === JQLParser.DESC)) {
              this._errHandler.recoverInline(this);
            } else {
              if (this._input.LA(1) === Token.EOF) {
                this.matchedEOF = true;
              }

              this._errHandler.reportMatch(this);
              this.consume();
            }
          }
        }
      }
    } catch (re) {
      if (re instanceof RecognitionException) {
        _localctx.exception = re;
        this._errHandler.reportError(this, re);
        this._errHandler.recover(this, re);
      } else {
        throw re;
      }
    } finally {
      this.exitRule();
    }
    return _localctx;
  }

  public static readonly _serializedATN: string =
    '\x03\uC91D\uCABA\u058D\uAFBA\u4F53\u0607\uEA8B\uC241\x03+\xF1\x04\x02' +
    '\t\x02\x04\x03\t\x03\x04\x04\t\x04\x04\x05\t\x05\x04\x06\t\x06\x04\x07' +
    '\t\x07\x04\b\t\b\x04\t\t\t\x04\n\t\n\x04\v\t\v\x04\f\t\f\x04\r\t\r\x04' +
    '\x0E\t\x0E\x04\x0F\t\x0F\x04\x10\t\x10\x04\x11\t\x11\x04\x12\t\x12\x04' +
    '\x13\t\x13\x04\x14\t\x14\x04\x15\t\x15\x04\x16\t\x16\x04\x17\t\x17\x04' +
    '\x18\t\x18\x04\x19\t\x19\x04\x1A\t\x1A\x04\x1B\t\x1B\x04\x1C\t\x1C\x04' +
    '\x1D\t\x1D\x04\x1E\t\x1E\x03\x02\x05\x02>\n\x02\x03\x02\x05\x02A\n\x02' +
    '\x03\x02\x03\x02\x03\x03\x03\x03\x03\x04\x03\x04\x03\x04\x07\x04J\n\x04' +
    '\f\x04\x0E\x04M\v\x04\x03\x05\x03\x05\x03\x05\x07\x05R\n\x05\f\x05\x0E' +
    '\x05U\v\x05\x03\x06\x03\x06\x03\x06\x03\x06\x05\x06[\n\x06\x03\x07\x03' +
    '\x07\x03\x07\x03\x07\x03\b\x03\b\x03\b\x03\b\x05\be\n\b\x03\t\x03\t\x03' +
    '\t\x03\n\x03\n\x03\n\x05\nm\n\n\x03\v\x03\v\x05\vq\n\v\x03\f\x06\ft\n' +
    '\f\r\f\x0E\fu\x03\r\x03\r\x03\r\x03\x0E\x03\x0E\x03\x0F\x03\x0F\x03\x0F' +
    '\x03\x0F\x03\x0F\x03\x0F\x03\x0F\x03\x0F\x03\x0F\x03\x0F\x03\x0F\x03\x0F' +
    '\x03\x0F\x03\x0F\x05\x0F\x8B\n\x0F\x03\x10\x03\x10\x03\x10\x03\x10\x03' +
    '\x10\x03\x10\x03\x10\x03\x10\x05\x10\x95\n\x10\x03\x11\x03\x11\x03\x12' +
    '\x03\x12\x03\x12\x05\x12\x9C\n\x12\x03\x12\x03\x12\x03\x12\x03\x12\x03' +
    '\x12\x07\x12\xA3\n\x12\f\x12\x0E\x12\xA6\v\x12\x07\x12\xA8\n\x12\f\x12' +
    '\x0E\x12\xAB\v\x12\x05\x12\xAD\n\x12\x03\x13\x03\x13\x03\x13\x03\x13\x03' +
    '\x13\x03\x14\x03\x14\x03\x14\x03\x14\x03\x14\x05\x14\xB9\n\x14\x03\x15' +
    '\x03\x15\x03\x16\x03\x16\x03\x17\x03\x17\x03\x17\x03\x17\x07\x17\xC3\n' +
    '\x17\f\x17\x0E\x17\xC6\v\x17\x03\x17\x03\x17\x03\x18\x03\x18\x03\x18\x05' +
    '\x18\xCD\n\x18\x03\x18\x03\x18\x03\x19\x03\x19\x05\x19\xD3\n\x19\x03\x1A' +
    '\x03\x1A\x03\x1A\x07\x1A\xD8\n\x1A\f\x1A\x0E\x1A\xDB\v\x1A\x03\x1B\x03' +
    '\x1B\x03\x1C\x03\x1C\x05\x1C\xE1\n\x1C\x03\x1D\x03\x1D\x03\x1D\x03\x1D' +
    '\x03\x1D\x07\x1D\xE8\n\x1D\f\x1D\x0E\x1D\xEB\v\x1D\x03\x1E\x03\x1E\x05' +
    '\x1E\xEF\n\x1E\x03\x1E\x02\x02\x02\x1F\x02\x02\x04\x02\x06\x02\b\x02\n' +
    '\x02\f\x02\x0E\x02\x10\x02\x12\x02\x14\x02\x16\x02\x18\x02\x1A\x02\x1C' +
    '\x02\x1E\x02 \x02"\x02$\x02&\x02(\x02*\x02,\x02.\x020\x022\x024\x026' +
    '\x028\x02:\x02\x02\x07\x04\x02\b\b\x15\x15\x04\x02\x19\x1E  \x03\x02&' +
    '(\x03\x02#$\x03\x02!"\x02\xFB\x02=\x03\x02\x02\x02\x04D\x03\x02\x02\x02' +
    '\x06F\x03\x02\x02\x02\bN\x03\x02\x02\x02\nZ\x03\x02\x02\x02\f\\\x03\x02' +
    '\x02\x02\x0E`\x03\x02\x02\x02\x10f\x03\x02\x02\x02\x12i\x03\x02\x02\x02' +
    '\x14n\x03\x02\x02\x02\x16s\x03\x02\x02\x02\x18w\x03\x02\x02\x02\x1Az\x03' +
    '\x02\x02\x02\x1C\x8A\x03\x02\x02\x02\x1E\x94\x03\x02\x02\x02 \x96\x03' +
    '\x02\x02\x02"\xAC\x03\x02\x02\x02$\xAE\x03\x02\x02\x02&\xB8\x03\x02\x02' +
    '\x02(\xBA\x03\x02\x02\x02*\xBC\x03\x02\x02\x02,\xBE\x03\x02\x02\x02.\xC9' +
    '\x03\x02\x02\x020\xD2\x03\x02\x02\x022\xD4\x03\x02\x02\x024\xDC\x03\x02' +
    '\x02\x026\xE0\x03\x02\x02\x028\xE2\x03\x02\x02\x02:\xEC\x03\x02\x02\x02' +
    '<>\x05\x04\x03\x02=<\x03\x02\x02\x02=>\x03\x02\x02\x02>@\x03\x02\x02\x02' +
    '?A\x058\x1D\x02@?\x03\x02\x02\x02@A\x03\x02\x02\x02AB\x03\x02\x02\x02' +
    'BC\x07\x02\x02\x03C\x03\x03\x02\x02\x02DE\x05\x06\x04\x02E\x05\x03\x02' +
    '\x02\x02FK\x05\b\x05\x02GH\x07\x14\x02\x02HJ\x05\b\x05\x02IG\x03\x02\x02' +
    '\x02JM\x03\x02\x02\x02KI\x03\x02\x02\x02KL\x03\x02\x02\x02L\x07\x03\x02' +
    '\x02\x02MK\x03\x02\x02\x02NS\x05\n\x06\x02OP\x07\x13\x02\x02PR\x05\n\x06' +
    '\x02QO\x03\x02\x02\x02RU\x03\x02\x02\x02SQ\x03\x02\x02\x02ST\x03\x02\x02' +
    '\x02T\t\x03\x02\x02\x02US\x03\x02\x02\x02VW\t\x02\x02\x02W[\x05\n\x06' +
    '\x02X[\x05\f\x07\x02Y[\x05\x0E\b\x02ZV\x03\x02\x02\x02ZX\x03\x02\x02\x02' +
    'ZY\x03\x02\x02\x02[\v\x03\x02\x02\x02\\]\x07\x03\x02\x02]^\x05\x06\x04' +
    '\x02^_\x07\x04\x02\x02_\r\x03\x02\x02\x02`d\x05"\x12\x02ae\x05\x10\t' +
    '\x02be\x05\x12\n\x02ce\x05\x14\v\x02da\x03\x02\x02\x02db\x03\x02\x02\x02' +
    'dc\x03\x02\x02\x02e\x0F\x03\x02\x02\x02fg\x05\x1C\x0F\x02gh\x05&\x14\x02' +
    'h\x11\x03\x02\x02\x02ij\x05\x1E\x10\x02jl\x05&\x14\x02km\x05\x16\f\x02' +
    'lk\x03\x02\x02\x02lm\x03\x02\x02\x02m\x13\x03\x02\x02\x02np\x05 \x11\x02' +
    'oq\x05\x16\f\x02po\x03\x02\x02\x02pq\x03\x02\x02\x02q\x15\x03\x02\x02' +
    '\x02rt\x05\x18\r\x02sr\x03\x02\x02\x02tu\x03\x02\x02\x02us\x03\x02\x02' +
    '\x02uv\x03\x02\x02\x02v\x17\x03\x02\x02\x02wx\x05\x1A\x0E\x02xy\x05&\x14' +
    '\x02y\x19\x03\x02\x02\x02z{\t\x03\x02\x02{\x1B\x03\x02\x02\x02|\x8B\x07' +
    '\r\x02\x02}\x8B\x07\x0E\x02\x02~\x8B\x07\x0F\x02\x02\x7F\x8B\x07\x10\x02' +
    '\x02\x80\x8B\x07\t\x02\x02\x81\x8B\x07\n\x02\x02\x82\x8B\x07\f\x02\x02' +
    '\x83\x8B\x07\v\x02\x02\x84\x8B\x07\x11\x02\x02\x85\x86\x07\x12\x02\x02' +
    '\x86\x8B\x07\x15\x02\x02\x87\x8B\x07\x12\x02\x02\x88\x89\x07\x15\x02\x02' +
    '\x89\x8B\x07\x11\x02\x02\x8A|\x03\x02\x02\x02\x8A}\x03\x02\x02\x02\x8A' +
    '~\x03\x02\x02\x02\x8A\x7F\x03\x02\x02\x02\x8A\x80\x03\x02\x02\x02\x8A' +
    '\x81\x03\x02\x02\x02\x8A\x82\x03\x02\x02\x02\x8A\x83\x03\x02\x02\x02\x8A' +
    '\x84\x03\x02\x02\x02\x8A\x85\x03\x02\x02\x02\x8A\x87\x03\x02\x02\x02\x8A' +
    '\x88\x03\x02\x02\x02\x8B\x1D\x03\x02\x02\x02\x8C\x95\x07\x17\x02\x02\x8D' +
    '\x8E\x07\x17\x02\x02\x8E\x95\x07\x15\x02\x02\x8F\x90\x07\x17\x02\x02\x90' +
    '\x95\x07\x11\x02\x02\x91\x92\x07\x17\x02\x02\x92\x93\x07\x15\x02\x02\x93' +
    '\x95\x07\x11\x02\x02\x94\x8C\x03\x02\x02\x02\x94\x8D\x03\x02\x02\x02\x94' +
    '\x8F\x03\x02\x02\x02\x94\x91\x03\x02\x02\x02\x95\x1F\x03\x02\x02\x02\x96' +
    '\x97\x07\x18\x02\x02\x97!\x03\x02\x02\x02\x98\xAD\x05*\x16\x02\x99\x9C' +
    '\x05(\x15\x02\x9A\x9C\x05$\x13\x02\x9B\x99\x03\x02\x02\x02\x9B\x9A\x03' +
    '\x02\x02\x02\x9C\xA9\x03\x02\x02\x02\x9D\x9E\x07\x06\x02\x02\x9E\x9F\x05' +
    '6\x1C\x02\x9F\xA0\x07\x07\x02\x02\xA0\xA4\x03\x02\x02\x02\xA1\xA3\x05' +
    '4\x1B\x02\xA2\xA1\x03\x02\x02\x02\xA3\xA6\x03\x02\x02\x02\xA4\xA2\x03' +
    '\x02\x02\x02\xA4\xA5\x03\x02\x02\x02\xA5\xA8\x03\x02\x02\x02\xA6\xA4\x03' +
    '\x02\x02\x02\xA7\x9D\x03\x02\x02\x02\xA8\xAB\x03\x02\x02\x02\xA9\xA7\x03' +
    '\x02\x02\x02\xA9\xAA\x03\x02\x02\x02\xAA\xAD\x03\x02\x02\x02\xAB\xA9\x03' +
    '\x02\x02\x02\xAC\x98\x03\x02\x02\x02\xAC\x9B\x03\x02\x02\x02\xAD#\x03' +
    '\x02\x02\x02\xAE\xAF\x07%\x02\x02\xAF\xB0\x07\x06\x02\x02\xB0\xB1\x07' +
    '#\x02\x02\xB1\xB2\x07\x07\x02\x02\xB2%\x03\x02\x02\x02\xB3\xB9\x07\x16' +
    '\x02\x02\xB4\xB9\x05(\x15\x02\xB5\xB9\x05*\x16\x02\xB6\xB9\x05.\x18\x02' +
    '\xB7\xB9\x05,\x17\x02\xB8\xB3\x03\x02\x02\x02\xB8\xB4\x03\x02\x02\x02' +
    '\xB8\xB5\x03\x02\x02\x02\xB8\xB6\x03\x02\x02\x02\xB8\xB7\x03\x02\x02\x02' +
    "\xB9'\x03\x02\x02\x02\xBA\xBB\t\x04\x02\x02\xBB)\x03\x02\x02\x02\xBC" +
    '\xBD\t\x05\x02\x02\xBD+\x03\x02\x02\x02\xBE\xBF\x07\x03\x02\x02\xBF\xC4' +
    '\x05&\x14\x02\xC0\xC1\x07\x05\x02\x02\xC1\xC3\x05&\x14\x02\xC2\xC0\x03' +
    '\x02\x02\x02\xC3\xC6\x03\x02\x02\x02\xC4\xC2\x03\x02\x02\x02\xC4\xC5\x03' +
    '\x02\x02\x02\xC5\xC7\x03\x02\x02\x02\xC6\xC4\x03\x02\x02\x02\xC7\xC8\x07' +
    '\x04\x02\x02\xC8-\x03\x02\x02\x02\xC9\xCA\x050\x19\x02\xCA\xCC\x07\x03' +
    '\x02\x02\xCB\xCD\x052\x1A\x02\xCC\xCB\x03\x02\x02\x02\xCC\xCD\x03\x02' +
    '\x02\x02\xCD\xCE\x03\x02\x02\x02\xCE\xCF\x07\x04\x02\x02\xCF/\x03\x02' +
    '\x02\x02\xD0\xD3\x05(\x15\x02\xD1\xD3\x05*\x16\x02\xD2\xD0\x03\x02\x02' +
    '\x02\xD2\xD1\x03\x02\x02\x02\xD31\x03\x02\x02\x02\xD4\xD9\x056\x1C\x02' +
    '\xD5\xD6\x07\x05\x02\x02\xD6\xD8\x056\x1C\x02\xD7\xD5\x03\x02\x02\x02' +
    '\xD8\xDB\x03\x02\x02\x02\xD9\xD7\x03\x02\x02\x02\xD9\xDA\x03\x02\x02\x02' +
    '\xDA3\x03\x02\x02\x02\xDB\xD9\x03\x02\x02\x02\xDC\xDD\x056\x1C\x02\xDD' +
    '5\x03\x02\x02\x02\xDE\xE1\x05(\x15\x02\xDF\xE1\x05*\x16\x02\xE0\xDE\x03' +
    '\x02\x02\x02\xE0\xDF\x03\x02\x02\x02\xE17\x03\x02\x02\x02\xE2\xE3\x07' +
    '\x1F\x02\x02\xE3\xE4\x07 \x02\x02\xE4\xE9\x05:\x1E\x02\xE5\xE6\x07\x05' +
    '\x02\x02\xE6\xE8\x05:\x1E\x02\xE7\xE5\x03\x02\x02\x02\xE8\xEB\x03\x02' +
    '\x02\x02\xE9\xE7\x03\x02\x02\x02\xE9\xEA\x03\x02\x02\x02\xEA9\x03\x02' +
    '\x02\x02\xEB\xE9\x03\x02\x02\x02\xEC\xEE\x05"\x12\x02\xED\xEF\t\x06\x02' +
    '\x02\xEE\xED\x03\x02\x02\x02\xEE\xEF\x03\x02\x02\x02\xEF;\x03\x02\x02' +
    '\x02\x19=@KSZdlpu\x8A\x94\x9B\xA4\xA9\xAC\xB8\xC4\xCC\xD2\xD9\xE0\xE9' +
    '\xEE';
  public static __ATN: ATN;
  public static get _ATN(): ATN {
    if (!JQLParser.__ATN) {
      JQLParser.__ATN = new ATNDeserializer().deserialize(
        Utils.toCharArray(JQLParser._serializedATN)
      );
    }

    return JQLParser.__ATN;
  }
}

export class QueryContext extends ParserRuleContext {
  public EOF(): TerminalNode {
    return this.getToken(JQLParser.EOF, 0);
  }
  public clause(): ClauseContext | undefined {
    return this.tryGetRuleContext(0, ClauseContext);
  }
  public orderBy(): OrderByContext | undefined {
    return this.tryGetRuleContext(0, OrderByContext);
  }
  constructor(parent: ParserRuleContext | undefined, invokingState: number) {
    super(parent, invokingState);
  }
  // @Override
  public get ruleIndex(): number {
    return JQLParser.RULE_query;
  }
  // @Override
  public enterRule(listener: JQLParserListener): void {
    if (listener.enterQuery) {
      listener.enterQuery(this);
    }
  }
  // @Override
  public exitRule(listener: JQLParserListener): void {
    if (listener.exitQuery) {
      listener.exitQuery(this);
    }
  }
  // @Override
  public accept<Result>(visitor: JQLParserVisitor<Result>): Result {
    if (visitor.visitQuery) {
      return visitor.visitQuery(this);
    } else {
      return visitor.visitChildren(this);
    }
  }
}

export class ClauseContext extends ParserRuleContext {
  public orClause(): OrClauseContext {
    return this.getRuleContext(0, OrClauseContext);
  }
  constructor(parent: ParserRuleContext | undefined, invokingState: number) {
    super(parent, invokingState);
  }
  // @Override
  public get ruleIndex(): number {
    return JQLParser.RULE_clause;
  }
  // @Override
  public enterRule(listener: JQLParserListener): void {
    if (listener.enterClause) {
      listener.enterClause(this);
    }
  }
  // @Override
  public exitRule(listener: JQLParserListener): void {
    if (listener.exitClause) {
      listener.exitClause(this);
    }
  }
  // @Override
  public accept<Result>(visitor: JQLParserVisitor<Result>): Result {
    if (visitor.visitClause) {
      return visitor.visitClause(this);
    } else {
      return visitor.visitChildren(this);
    }
  }
}

export class OrClauseContext extends ParserRuleContext {
  public andClause(): AndClauseContext[];
  public andClause(i: number): AndClauseContext;
  public andClause(i?: number): AndClauseContext | AndClauseContext[] {
    if (i === undefined) {
      return this.getRuleContexts(AndClauseContext);
    } else {
      return this.getRuleContext(i, AndClauseContext);
    }
  }
  public OR(): TerminalNode[];
  public OR(i: number): TerminalNode;
  public OR(i?: number): TerminalNode | TerminalNode[] {
    if (i === undefined) {
      return this.getTokens(JQLParser.OR);
    } else {
      return this.getToken(JQLParser.OR, i);
    }
  }
  constructor(parent: ParserRuleContext | undefined, invokingState: number) {
    super(parent, invokingState);
  }
  // @Override
  public get ruleIndex(): number {
    return JQLParser.RULE_orClause;
  }
  // @Override
  public enterRule(listener: JQLParserListener): void {
    if (listener.enterOrClause) {
      listener.enterOrClause(this);
    }
  }
  // @Override
  public exitRule(listener: JQLParserListener): void {
    if (listener.exitOrClause) {
      listener.exitOrClause(this);
    }
  }
  // @Override
  public accept<Result>(visitor: JQLParserVisitor<Result>): Result {
    if (visitor.visitOrClause) {
      return visitor.visitOrClause(this);
    } else {
      return visitor.visitChildren(this);
    }
  }
}

export class AndClauseContext extends ParserRuleContext {
  public notClause(): NotClauseContext[];
  public notClause(i: number): NotClauseContext;
  public notClause(i?: number): NotClauseContext | NotClauseContext[] {
    if (i === undefined) {
      return this.getRuleContexts(NotClauseContext);
    } else {
      return this.getRuleContext(i, NotClauseContext);
    }
  }
  public AND(): TerminalNode[];
  public AND(i: number): TerminalNode;
  public AND(i?: number): TerminalNode | TerminalNode[] {
    if (i === undefined) {
      return this.getTokens(JQLParser.AND);
    } else {
      return this.getToken(JQLParser.AND, i);
    }
  }
  constructor(parent: ParserRuleContext | undefined, invokingState: number) {
    super(parent, invokingState);
  }
  // @Override
  public get ruleIndex(): number {
    return JQLParser.RULE_andClause;
  }
  // @Override
  public enterRule(listener: JQLParserListener): void {
    if (listener.enterAndClause) {
      listener.enterAndClause(this);
    }
  }
  // @Override
  public exitRule(listener: JQLParserListener): void {
    if (listener.exitAndClause) {
      listener.exitAndClause(this);
    }
  }
  // @Override
  public accept<Result>(visitor: JQLParserVisitor<Result>): Result {
    if (visitor.visitAndClause) {
      return visitor.visitAndClause(this);
    } else {
      return visitor.visitChildren(this);
    }
  }
}

export class NotClauseContext extends ParserRuleContext {
  public notClause(): NotClauseContext | undefined {
    return this.tryGetRuleContext(0, NotClauseContext);
  }
  public NOT(): TerminalNode | undefined {
    return this.tryGetToken(JQLParser.NOT, 0);
  }
  public BANG(): TerminalNode | undefined {
    return this.tryGetToken(JQLParser.BANG, 0);
  }
  public subClause(): SubClauseContext | undefined {
    return this.tryGetRuleContext(0, SubClauseContext);
  }
  public terminalClause(): TerminalClauseContext | undefined {
    return this.tryGetRuleContext(0, TerminalClauseContext);
  }
  constructor(parent: ParserRuleContext | undefined, invokingState: number) {
    super(parent, invokingState);
  }
  // @Override
  public get ruleIndex(): number {
    return JQLParser.RULE_notClause;
  }
  // @Override
  public enterRule(listener: JQLParserListener): void {
    if (listener.enterNotClause) {
      listener.enterNotClause(this);
    }
  }
  // @Override
  public exitRule(listener: JQLParserListener): void {
    if (listener.exitNotClause) {
      listener.exitNotClause(this);
    }
  }
  // @Override
  public accept<Result>(visitor: JQLParserVisitor<Result>): Result {
    if (visitor.visitNotClause) {
      return visitor.visitNotClause(this);
    } else {
      return visitor.visitChildren(this);
    }
  }
}

export class SubClauseContext extends ParserRuleContext {
  public LPAREN(): TerminalNode {
    return this.getToken(JQLParser.LPAREN, 0);
  }
  public orClause(): OrClauseContext {
    return this.getRuleContext(0, OrClauseContext);
  }
  public RPAREN(): TerminalNode {
    return this.getToken(JQLParser.RPAREN, 0);
  }
  constructor(parent: ParserRuleContext | undefined, invokingState: number) {
    super(parent, invokingState);
  }
  // @Override
  public get ruleIndex(): number {
    return JQLParser.RULE_subClause;
  }
  // @Override
  public enterRule(listener: JQLParserListener): void {
    if (listener.enterSubClause) {
      listener.enterSubClause(this);
    }
  }
  // @Override
  public exitRule(listener: JQLParserListener): void {
    if (listener.exitSubClause) {
      listener.exitSubClause(this);
    }
  }
  // @Override
  public accept<Result>(visitor: JQLParserVisitor<Result>): Result {
    if (visitor.visitSubClause) {
      return visitor.visitSubClause(this);
    } else {
      return visitor.visitChildren(this);
    }
  }
}

export class TerminalClauseContext extends ParserRuleContext {
  public field(): FieldContext {
    return this.getRuleContext(0, FieldContext);
  }
  public unaryClause(): UnaryClauseContext | undefined {
    return this.tryGetRuleContext(0, UnaryClauseContext);
  }
  public wasClause(): WasClauseContext | undefined {
    return this.tryGetRuleContext(0, WasClauseContext);
  }
  public changedClause(): ChangedClauseContext | undefined {
    return this.tryGetRuleContext(0, ChangedClauseContext);
  }
  constructor(parent: ParserRuleContext | undefined, invokingState: number) {
    super(parent, invokingState);
  }
  // @Override
  public get ruleIndex(): number {
    return JQLParser.RULE_terminalClause;
  }
  // @Override
  public enterRule(listener: JQLParserListener): void {
    if (listener.enterTerminalClause) {
      listener.enterTerminalClause(this);
    }
  }
  // @Override
  public exitRule(listener: JQLParserListener): void {
    if (listener.exitTerminalClause) {
      listener.exitTerminalClause(this);
    }
  }
  // @Override
  public accept<Result>(visitor: JQLParserVisitor<Result>): Result {
    if (visitor.visitTerminalClause) {
      return visitor.visitTerminalClause(this);
    } else {
      return visitor.visitChildren(this);
    }
  }
}

export class UnaryClauseContext extends ParserRuleContext {
  public unaryOperator(): UnaryOperatorContext {
    return this.getRuleContext(0, UnaryOperatorContext);
  }
  public operand(): OperandContext {
    return this.getRuleContext(0, OperandContext);
  }
  constructor(parent: ParserRuleContext | undefined, invokingState: number) {
    super(parent, invokingState);
  }
  // @Override
  public get ruleIndex(): number {
    return JQLParser.RULE_unaryClause;
  }
  // @Override
  public enterRule(listener: JQLParserListener): void {
    if (listener.enterUnaryClause) {
      listener.enterUnaryClause(this);
    }
  }
  // @Override
  public exitRule(listener: JQLParserListener): void {
    if (listener.exitUnaryClause) {
      listener.exitUnaryClause(this);
    }
  }
  // @Override
  public accept<Result>(visitor: JQLParserVisitor<Result>): Result {
    if (visitor.visitUnaryClause) {
      return visitor.visitUnaryClause(this);
    } else {
      return visitor.visitChildren(this);
    }
  }
}

export class WasClauseContext extends ParserRuleContext {
  public wasOperator(): WasOperatorContext {
    return this.getRuleContext(0, WasOperatorContext);
  }
  public operand(): OperandContext {
    return this.getRuleContext(0, OperandContext);
  }
  public historyPredicate(): HistoryPredicateContext | undefined {
    return this.tryGetRuleContext(0, HistoryPredicateContext);
  }
  constructor(parent: ParserRuleContext | undefined, invokingState: number) {
    super(parent, invokingState);
  }
  // @Override
  public get ruleIndex(): number {
    return JQLParser.RULE_wasClause;
  }
  // @Override
  public enterRule(listener: JQLParserListener): void {
    if (listener.enterWasClause) {
      listener.enterWasClause(this);
    }
  }
  // @Override
  public exitRule(listener: JQLParserListener): void {
    if (listener.exitWasClause) {
      listener.exitWasClause(this);
    }
  }
  // @Override
  public accept<Result>(visitor: JQLParserVisitor<Result>): Result {
    if (visitor.visitWasClause) {
      return visitor.visitWasClause(this);
    } else {
      return visitor.visitChildren(this);
    }
  }
}

export class ChangedClauseContext extends ParserRuleContext {
  public changedOperator(): ChangedOperatorContext {
    return this.getRuleContext(0, ChangedOperatorContext);
  }
  public historyPredicate(): HistoryPredicateContext | undefined {
    return this.tryGetRuleContext(0, HistoryPredicateContext);
  }
  constructor(parent: ParserRuleContext | undefined, invokingState: number) {
    super(parent, invokingState);
  }
  // @Override
  public get ruleIndex(): number {
    return JQLParser.RULE_changedClause;
  }
  // @Override
  public enterRule(listener: JQLParserListener): void {
    if (listener.enterChangedClause) {
      listener.enterChangedClause(this);
    }
  }
  // @Override
  public exitRule(listener: JQLParserListener): void {
    if (listener.exitChangedClause) {
      listener.exitChangedClause(this);
    }
  }
  // @Override
  public accept<Result>(visitor: JQLParserVisitor<Result>): Result {
    if (visitor.visitChangedClause) {
      return visitor.visitChangedClause(this);
    } else {
      return visitor.visitChildren(this);
    }
  }
}

export class HistoryPredicateContext extends ParserRuleContext {
  public terminalHistoryPredicate(): TerminalHistoryPredicateContext[];
  public terminalHistoryPredicate(i: number): TerminalHistoryPredicateContext;
  public terminalHistoryPredicate(
    i?: number
  ): TerminalHistoryPredicateContext | TerminalHistoryPredicateContext[] {
    if (i === undefined) {
      return this.getRuleContexts(TerminalHistoryPredicateContext);
    } else {
      return this.getRuleContext(i, TerminalHistoryPredicateContext);
    }
  }
  constructor(parent: ParserRuleContext | undefined, invokingState: number) {
    super(parent, invokingState);
  }
  // @Override
  public get ruleIndex(): number {
    return JQLParser.RULE_historyPredicate;
  }
  // @Override
  public enterRule(listener: JQLParserListener): void {
    if (listener.enterHistoryPredicate) {
      listener.enterHistoryPredicate(this);
    }
  }
  // @Override
  public exitRule(listener: JQLParserListener): void {
    if (listener.exitHistoryPredicate) {
      listener.exitHistoryPredicate(this);
    }
  }
  // @Override
  public accept<Result>(visitor: JQLParserVisitor<Result>): Result {
    if (visitor.visitHistoryPredicate) {
      return visitor.visitHistoryPredicate(this);
    } else {
      return visitor.visitChildren(this);
    }
  }
}

export class TerminalHistoryPredicateContext extends ParserRuleContext {
  public historyPredicateOperator(): HistoryPredicateOperatorContext {
    return this.getRuleContext(0, HistoryPredicateOperatorContext);
  }
  public operand(): OperandContext {
    return this.getRuleContext(0, OperandContext);
  }
  constructor(parent: ParserRuleContext | undefined, invokingState: number) {
    super(parent, invokingState);
  }
  // @Override
  public get ruleIndex(): number {
    return JQLParser.RULE_terminalHistoryPredicate;
  }
  // @Override
  public enterRule(listener: JQLParserListener): void {
    if (listener.enterTerminalHistoryPredicate) {
      listener.enterTerminalHistoryPredicate(this);
    }
  }
  // @Override
  public exitRule(listener: JQLParserListener): void {
    if (listener.exitTerminalHistoryPredicate) {
      listener.exitTerminalHistoryPredicate(this);
    }
  }
  // @Override
  public accept<Result>(visitor: JQLParserVisitor<Result>): Result {
    if (visitor.visitTerminalHistoryPredicate) {
      return visitor.visitTerminalHistoryPredicate(this);
    } else {
      return visitor.visitChildren(this);
    }
  }
}

export class HistoryPredicateOperatorContext extends ParserRuleContext {
  public FROM(): TerminalNode | undefined {
    return this.tryGetToken(JQLParser.FROM, 0);
  }
  public TO(): TerminalNode | undefined {
    return this.tryGetToken(JQLParser.TO, 0);
  }
  public BY(): TerminalNode | undefined {
    return this.tryGetToken(JQLParser.BY, 0);
  }
  public BEFORE(): TerminalNode | undefined {
    return this.tryGetToken(JQLParser.BEFORE, 0);
  }
  public AFTER(): TerminalNode | undefined {
    return this.tryGetToken(JQLParser.AFTER, 0);
  }
  public ON(): TerminalNode | undefined {
    return this.tryGetToken(JQLParser.ON, 0);
  }
  public DURING(): TerminalNode | undefined {
    return this.tryGetToken(JQLParser.DURING, 0);
  }
  constructor(parent: ParserRuleContext | undefined, invokingState: number) {
    super(parent, invokingState);
  }
  // @Override
  public get ruleIndex(): number {
    return JQLParser.RULE_historyPredicateOperator;
  }
  // @Override
  public enterRule(listener: JQLParserListener): void {
    if (listener.enterHistoryPredicateOperator) {
      listener.enterHistoryPredicateOperator(this);
    }
  }
  // @Override
  public exitRule(listener: JQLParserListener): void {
    if (listener.exitHistoryPredicateOperator) {
      listener.exitHistoryPredicateOperator(this);
    }
  }
  // @Override
  public accept<Result>(visitor: JQLParserVisitor<Result>): Result {
    if (visitor.visitHistoryPredicateOperator) {
      return visitor.visitHistoryPredicateOperator(this);
    } else {
      return visitor.visitChildren(this);
    }
  }
}

export class UnaryOperatorContext extends ParserRuleContext {
  public EQUALS(): TerminalNode | undefined {
    return this.tryGetToken(JQLParser.EQUALS, 0);
  }
  public NOT_EQUALS(): TerminalNode | undefined {
    return this.tryGetToken(JQLParser.NOT_EQUALS, 0);
  }
  public LIKE(): TerminalNode | undefined {
    return this.tryGetToken(JQLParser.LIKE, 0);
  }
  public NOT_LIKE(): TerminalNode | undefined {
    return this.tryGetToken(JQLParser.NOT_LIKE, 0);
  }
  public LT(): TerminalNode | undefined {
    return this.tryGetToken(JQLParser.LT, 0);
  }
  public GT(): TerminalNode | undefined {
    return this.tryGetToken(JQLParser.GT, 0);
  }
  public LTEQ(): TerminalNode | undefined {
    return this.tryGetToken(JQLParser.LTEQ, 0);
  }
  public GTEQ(): TerminalNode | undefined {
    return this.tryGetToken(JQLParser.GTEQ, 0);
  }
  public IN(): TerminalNode | undefined {
    return this.tryGetToken(JQLParser.IN, 0);
  }
  public IS(): TerminalNode | undefined {
    return this.tryGetToken(JQLParser.IS, 0);
  }
  public NOT(): TerminalNode | undefined {
    return this.tryGetToken(JQLParser.NOT, 0);
  }
  constructor(parent: ParserRuleContext | undefined, invokingState: number) {
    super(parent, invokingState);
  }
  // @Override
  public get ruleIndex(): number {
    return JQLParser.RULE_unaryOperator;
  }
  // @Override
  public enterRule(listener: JQLParserListener): void {
    if (listener.enterUnaryOperator) {
      listener.enterUnaryOperator(this);
    }
  }
  // @Override
  public exitRule(listener: JQLParserListener): void {
    if (listener.exitUnaryOperator) {
      listener.exitUnaryOperator(this);
    }
  }
  // @Override
  public accept<Result>(visitor: JQLParserVisitor<Result>): Result {
    if (visitor.visitUnaryOperator) {
      return visitor.visitUnaryOperator(this);
    } else {
      return visitor.visitChildren(this);
    }
  }
}

export class WasOperatorContext extends ParserRuleContext {
  public WAS(): TerminalNode {
    return this.getToken(JQLParser.WAS, 0);
  }
  public NOT(): TerminalNode | undefined {
    return this.tryGetToken(JQLParser.NOT, 0);
  }
  public IN(): TerminalNode | undefined {
    return this.tryGetToken(JQLParser.IN, 0);
  }
  constructor(parent: ParserRuleContext | undefined, invokingState: number) {
    super(parent, invokingState);
  }
  // @Override
  public get ruleIndex(): number {
    return JQLParser.RULE_wasOperator;
  }
  // @Override
  public enterRule(listener: JQLParserListener): void {
    if (listener.enterWasOperator) {
      listener.enterWasOperator(this);
    }
  }
  // @Override
  public exitRule(listener: JQLParserListener): void {
    if (listener.exitWasOperator) {
      listener.exitWasOperator(this);
    }
  }
  // @Override
  public accept<Result>(visitor: JQLParserVisitor<Result>): Result {
    if (visitor.visitWasOperator) {
      return visitor.visitWasOperator(this);
    } else {
      return visitor.visitChildren(this);
    }
  }
}

export class ChangedOperatorContext extends ParserRuleContext {
  public CHANGED(): TerminalNode {
    return this.getToken(JQLParser.CHANGED, 0);
  }
  constructor(parent: ParserRuleContext | undefined, invokingState: number) {
    super(parent, invokingState);
  }
  // @Override
  public get ruleIndex(): number {
    return JQLParser.RULE_changedOperator;
  }
  // @Override
  public enterRule(listener: JQLParserListener): void {
    if (listener.enterChangedOperator) {
      listener.enterChangedOperator(this);
    }
  }
  // @Override
  public exitRule(listener: JQLParserListener): void {
    if (listener.exitChangedOperator) {
      listener.exitChangedOperator(this);
    }
  }
  // @Override
  public accept<Result>(visitor: JQLParserVisitor<Result>): Result {
    if (visitor.visitChangedOperator) {
      return visitor.visitChangedOperator(this);
    } else {
      return visitor.visitChildren(this);
    }
  }
}

export class FieldContext extends ParserRuleContext {
  constructor(parent: ParserRuleContext | undefined, invokingState: number) {
    super(parent, invokingState);
  }
  // @Override
  public get ruleIndex(): number {
    return JQLParser.RULE_field;
  }
  public copyFrom(ctx: FieldContext): void {
    super.copyFrom(ctx);
  }
}
export class NumberFieldContext extends FieldContext {
  public numberString(): NumberStringContext {
    return this.getRuleContext(0, NumberStringContext);
  }
  constructor(ctx: FieldContext) {
    super(ctx.parent, ctx.invokingState);
    this.copyFrom(ctx);
  }
  // @Override
  public enterRule(listener: JQLParserListener): void {
    if (listener.enterNumberField) {
      listener.enterNumberField(this);
    }
  }
  // @Override
  public exitRule(listener: JQLParserListener): void {
    if (listener.exitNumberField) {
      listener.exitNumberField(this);
    }
  }
  // @Override
  public accept<Result>(visitor: JQLParserVisitor<Result>): Result {
    if (visitor.visitNumberField) {
      return visitor.visitNumberField(this);
    } else {
      return visitor.visitChildren(this);
    }
  }
}
export class NonNumberFieldContext extends FieldContext {
  public string(): StringContext | undefined {
    return this.tryGetRuleContext(0, StringContext);
  }
  public customField(): CustomFieldContext | undefined {
    return this.tryGetRuleContext(0, CustomFieldContext);
  }
  public LBRACKET(): TerminalNode[];
  public LBRACKET(i: number): TerminalNode;
  public LBRACKET(i?: number): TerminalNode | TerminalNode[] {
    if (i === undefined) {
      return this.getTokens(JQLParser.LBRACKET);
    } else {
      return this.getToken(JQLParser.LBRACKET, i);
    }
  }
  public RBRACKET(): TerminalNode[];
  public RBRACKET(i: number): TerminalNode;
  public RBRACKET(i?: number): TerminalNode | TerminalNode[] {
    if (i === undefined) {
      return this.getTokens(JQLParser.RBRACKET);
    } else {
      return this.getToken(JQLParser.RBRACKET, i);
    }
  }
  public propertyArgument(): PropertyArgumentContext[];
  public propertyArgument(i: number): PropertyArgumentContext;
  public propertyArgument(i?: number): PropertyArgumentContext | PropertyArgumentContext[] {
    if (i === undefined) {
      return this.getRuleContexts(PropertyArgumentContext);
    } else {
      return this.getRuleContext(i, PropertyArgumentContext);
    }
  }
  public argument(): ArgumentContext[];
  public argument(i: number): ArgumentContext;
  public argument(i?: number): ArgumentContext | ArgumentContext[] {
    if (i === undefined) {
      return this.getRuleContexts(ArgumentContext);
    } else {
      return this.getRuleContext(i, ArgumentContext);
    }
  }
  constructor(ctx: FieldContext) {
    super(ctx.parent, ctx.invokingState);
    this.copyFrom(ctx);
  }
  // @Override
  public enterRule(listener: JQLParserListener): void {
    if (listener.enterNonNumberField) {
      listener.enterNonNumberField(this);
    }
  }
  // @Override
  public exitRule(listener: JQLParserListener): void {
    if (listener.exitNonNumberField) {
      listener.exitNonNumberField(this);
    }
  }
  // @Override
  public accept<Result>(visitor: JQLParserVisitor<Result>): Result {
    if (visitor.visitNonNumberField) {
      return visitor.visitNonNumberField(this);
    } else {
      return visitor.visitChildren(this);
    }
  }
}

export class CustomFieldContext extends ParserRuleContext {
  public CUSTOMFIELD(): TerminalNode {
    return this.getToken(JQLParser.CUSTOMFIELD, 0);
  }
  public LBRACKET(): TerminalNode {
    return this.getToken(JQLParser.LBRACKET, 0);
  }
  public POSNUMBER(): TerminalNode {
    return this.getToken(JQLParser.POSNUMBER, 0);
  }
  public RBRACKET(): TerminalNode {
    return this.getToken(JQLParser.RBRACKET, 0);
  }
  constructor(parent: ParserRuleContext | undefined, invokingState: number) {
    super(parent, invokingState);
  }
  // @Override
  public get ruleIndex(): number {
    return JQLParser.RULE_customField;
  }
  // @Override
  public enterRule(listener: JQLParserListener): void {
    if (listener.enterCustomField) {
      listener.enterCustomField(this);
    }
  }
  // @Override
  public exitRule(listener: JQLParserListener): void {
    if (listener.exitCustomField) {
      listener.exitCustomField(this);
    }
  }
  // @Override
  public accept<Result>(visitor: JQLParserVisitor<Result>): Result {
    if (visitor.visitCustomField) {
      return visitor.visitCustomField(this);
    } else {
      return visitor.visitChildren(this);
    }
  }
}

export class OperandContext extends ParserRuleContext {
  public EMPTY(): TerminalNode | undefined {
    return this.tryGetToken(JQLParser.EMPTY, 0);
  }
  public string(): StringContext | undefined {
    return this.tryGetRuleContext(0, StringContext);
  }
  public numberString(): NumberStringContext | undefined {
    return this.tryGetRuleContext(0, NumberStringContext);
  }
  public func(): FuncContext | undefined {
    return this.tryGetRuleContext(0, FuncContext);
  }
  public list(): ListContext | undefined {
    return this.tryGetRuleContext(0, ListContext);
  }
  constructor(parent: ParserRuleContext | undefined, invokingState: number) {
    super(parent, invokingState);
  }
  // @Override
  public get ruleIndex(): number {
    return JQLParser.RULE_operand;
  }
  // @Override
  public enterRule(listener: JQLParserListener): void {
    if (listener.enterOperand) {
      listener.enterOperand(this);
    }
  }
  // @Override
  public exitRule(listener: JQLParserListener): void {
    if (listener.exitOperand) {
      listener.exitOperand(this);
    }
  }
  // @Override
  public accept<Result>(visitor: JQLParserVisitor<Result>): Result {
    if (visitor.visitOperand) {
      return visitor.visitOperand(this);
    } else {
      return visitor.visitChildren(this);
    }
  }
}

export class StringContext extends ParserRuleContext {
  public STRING(): TerminalNode | undefined {
    return this.tryGetToken(JQLParser.STRING, 0);
  }
  public QUOTE_STRING(): TerminalNode | undefined {
    return this.tryGetToken(JQLParser.QUOTE_STRING, 0);
  }
  public SQUOTE_STRING(): TerminalNode | undefined {
    return this.tryGetToken(JQLParser.SQUOTE_STRING, 0);
  }
  constructor(parent: ParserRuleContext | undefined, invokingState: number) {
    super(parent, invokingState);
  }
  // @Override
  public get ruleIndex(): number {
    return JQLParser.RULE_string;
  }
  // @Override
  public enterRule(listener: JQLParserListener): void {
    if (listener.enterString) {
      listener.enterString(this);
    }
  }
  // @Override
  public exitRule(listener: JQLParserListener): void {
    if (listener.exitString) {
      listener.exitString(this);
    }
  }
  // @Override
  public accept<Result>(visitor: JQLParserVisitor<Result>): Result {
    if (visitor.visitString) {
      return visitor.visitString(this);
    } else {
      return visitor.visitChildren(this);
    }
  }
}

export class NumberStringContext extends ParserRuleContext {
  // @ts-ignore
  public _num: Token;
  public POSNUMBER(): TerminalNode | undefined {
    return this.tryGetToken(JQLParser.POSNUMBER, 0);
  }
  public NEGNUMBER(): TerminalNode | undefined {
    return this.tryGetToken(JQLParser.NEGNUMBER, 0);
  }
  constructor(parent: ParserRuleContext | undefined, invokingState: number) {
    super(parent, invokingState);
  }
  // @Override
  public get ruleIndex(): number {
    return JQLParser.RULE_numberString;
  }
  // @Override
  public enterRule(listener: JQLParserListener): void {
    if (listener.enterNumberString) {
      listener.enterNumberString(this);
    }
  }
  // @Override
  public exitRule(listener: JQLParserListener): void {
    if (listener.exitNumberString) {
      listener.exitNumberString(this);
    }
  }
  // @Override
  public accept<Result>(visitor: JQLParserVisitor<Result>): Result {
    if (visitor.visitNumberString) {
      return visitor.visitNumberString(this);
    } else {
      return visitor.visitChildren(this);
    }
  }
}

export class ListContext extends ParserRuleContext {
  public LPAREN(): TerminalNode {
    return this.getToken(JQLParser.LPAREN, 0);
  }
  public operand(): OperandContext[];
  public operand(i: number): OperandContext;
  public operand(i?: number): OperandContext | OperandContext[] {
    if (i === undefined) {
      return this.getRuleContexts(OperandContext);
    } else {
      return this.getRuleContext(i, OperandContext);
    }
  }
  public RPAREN(): TerminalNode {
    return this.getToken(JQLParser.RPAREN, 0);
  }
  public COMMA(): TerminalNode[];
  public COMMA(i: number): TerminalNode;
  public COMMA(i?: number): TerminalNode | TerminalNode[] {
    if (i === undefined) {
      return this.getTokens(JQLParser.COMMA);
    } else {
      return this.getToken(JQLParser.COMMA, i);
    }
  }
  constructor(parent: ParserRuleContext | undefined, invokingState: number) {
    super(parent, invokingState);
  }
  // @Override
  public get ruleIndex(): number {
    return JQLParser.RULE_list;
  }
  // @Override
  public enterRule(listener: JQLParserListener): void {
    if (listener.enterList) {
      listener.enterList(this);
    }
  }
  // @Override
  public exitRule(listener: JQLParserListener): void {
    if (listener.exitList) {
      listener.exitList(this);
    }
  }
  // @Override
  public accept<Result>(visitor: JQLParserVisitor<Result>): Result {
    if (visitor.visitList) {
      return visitor.visitList(this);
    } else {
      return visitor.visitChildren(this);
    }
  }
}

export class FuncContext extends ParserRuleContext {
  public funcName(): FuncNameContext {
    return this.getRuleContext(0, FuncNameContext);
  }
  public LPAREN(): TerminalNode {
    return this.getToken(JQLParser.LPAREN, 0);
  }
  public RPAREN(): TerminalNode {
    return this.getToken(JQLParser.RPAREN, 0);
  }
  public arglist(): ArglistContext | undefined {
    return this.tryGetRuleContext(0, ArglistContext);
  }
  constructor(parent: ParserRuleContext | undefined, invokingState: number) {
    super(parent, invokingState);
  }
  // @Override
  public get ruleIndex(): number {
    return JQLParser.RULE_func;
  }
  // @Override
  public enterRule(listener: JQLParserListener): void {
    if (listener.enterFunc) {
      listener.enterFunc(this);
    }
  }
  // @Override
  public exitRule(listener: JQLParserListener): void {
    if (listener.exitFunc) {
      listener.exitFunc(this);
    }
  }
  // @Override
  public accept<Result>(visitor: JQLParserVisitor<Result>): Result {
    if (visitor.visitFunc) {
      return visitor.visitFunc(this);
    } else {
      return visitor.visitChildren(this);
    }
  }
}

export class FuncNameContext extends ParserRuleContext {
  public string(): StringContext | undefined {
    return this.tryGetRuleContext(0, StringContext);
  }
  public numberString(): NumberStringContext | undefined {
    return this.tryGetRuleContext(0, NumberStringContext);
  }
  constructor(parent: ParserRuleContext | undefined, invokingState: number) {
    super(parent, invokingState);
  }
  // @Override
  public get ruleIndex(): number {
    return JQLParser.RULE_funcName;
  }
  // @Override
  public enterRule(listener: JQLParserListener): void {
    if (listener.enterFuncName) {
      listener.enterFuncName(this);
    }
  }
  // @Override
  public exitRule(listener: JQLParserListener): void {
    if (listener.exitFuncName) {
      listener.exitFuncName(this);
    }
  }
  // @Override
  public accept<Result>(visitor: JQLParserVisitor<Result>): Result {
    if (visitor.visitFuncName) {
      return visitor.visitFuncName(this);
    } else {
      return visitor.visitChildren(this);
    }
  }
}

export class ArglistContext extends ParserRuleContext {
  public argument(): ArgumentContext[];
  public argument(i: number): ArgumentContext;
  public argument(i?: number): ArgumentContext | ArgumentContext[] {
    if (i === undefined) {
      return this.getRuleContexts(ArgumentContext);
    } else {
      return this.getRuleContext(i, ArgumentContext);
    }
  }
  public COMMA(): TerminalNode[];
  public COMMA(i: number): TerminalNode;
  public COMMA(i?: number): TerminalNode | TerminalNode[] {
    if (i === undefined) {
      return this.getTokens(JQLParser.COMMA);
    } else {
      return this.getToken(JQLParser.COMMA, i);
    }
  }
  constructor(parent: ParserRuleContext | undefined, invokingState: number) {
    super(parent, invokingState);
  }
  // @Override
  public get ruleIndex(): number {
    return JQLParser.RULE_arglist;
  }
  // @Override
  public enterRule(listener: JQLParserListener): void {
    if (listener.enterArglist) {
      listener.enterArglist(this);
    }
  }
  // @Override
  public exitRule(listener: JQLParserListener): void {
    if (listener.exitArglist) {
      listener.exitArglist(this);
    }
  }
  // @Override
  public accept<Result>(visitor: JQLParserVisitor<Result>): Result {
    if (visitor.visitArglist) {
      return visitor.visitArglist(this);
    } else {
      return visitor.visitChildren(this);
    }
  }
}

export class PropertyArgumentContext extends ParserRuleContext {
  public argument(): ArgumentContext {
    return this.getRuleContext(0, ArgumentContext);
  }
  constructor(parent: ParserRuleContext | undefined, invokingState: number) {
    super(parent, invokingState);
  }
  // @Override
  public get ruleIndex(): number {
    return JQLParser.RULE_propertyArgument;
  }
  // @Override
  public enterRule(listener: JQLParserListener): void {
    if (listener.enterPropertyArgument) {
      listener.enterPropertyArgument(this);
    }
  }
  // @Override
  public exitRule(listener: JQLParserListener): void {
    if (listener.exitPropertyArgument) {
      listener.exitPropertyArgument(this);
    }
  }
  // @Override
  public accept<Result>(visitor: JQLParserVisitor<Result>): Result {
    if (visitor.visitPropertyArgument) {
      return visitor.visitPropertyArgument(this);
    } else {
      return visitor.visitChildren(this);
    }
  }
}

export class ArgumentContext extends ParserRuleContext {
  public string(): StringContext | undefined {
    return this.tryGetRuleContext(0, StringContext);
  }
  public numberString(): NumberStringContext | undefined {
    return this.tryGetRuleContext(0, NumberStringContext);
  }
  constructor(parent: ParserRuleContext | undefined, invokingState: number) {
    super(parent, invokingState);
  }
  // @Override
  public get ruleIndex(): number {
    return JQLParser.RULE_argument;
  }
  // @Override
  public enterRule(listener: JQLParserListener): void {
    if (listener.enterArgument) {
      listener.enterArgument(this);
    }
  }
  // @Override
  public exitRule(listener: JQLParserListener): void {
    if (listener.exitArgument) {
      listener.exitArgument(this);
    }
  }
  // @Override
  public accept<Result>(visitor: JQLParserVisitor<Result>): Result {
    if (visitor.visitArgument) {
      return visitor.visitArgument(this);
    } else {
      return visitor.visitChildren(this);
    }
  }
}

export class OrderByContext extends ParserRuleContext {
  public ORDER(): TerminalNode {
    return this.getToken(JQLParser.ORDER, 0);
  }
  public BY(): TerminalNode {
    return this.getToken(JQLParser.BY, 0);
  }
  public searchSort(): SearchSortContext[];
  public searchSort(i: number): SearchSortContext;
  public searchSort(i?: number): SearchSortContext | SearchSortContext[] {
    if (i === undefined) {
      return this.getRuleContexts(SearchSortContext);
    } else {
      return this.getRuleContext(i, SearchSortContext);
    }
  }
  public COMMA(): TerminalNode[];
  public COMMA(i: number): TerminalNode;
  public COMMA(i?: number): TerminalNode | TerminalNode[] {
    if (i === undefined) {
      return this.getTokens(JQLParser.COMMA);
    } else {
      return this.getToken(JQLParser.COMMA, i);
    }
  }
  constructor(parent: ParserRuleContext | undefined, invokingState: number) {
    super(parent, invokingState);
  }
  // @Override
  public get ruleIndex(): number {
    return JQLParser.RULE_orderBy;
  }
  // @Override
  public enterRule(listener: JQLParserListener): void {
    if (listener.enterOrderBy) {
      listener.enterOrderBy(this);
    }
  }
  // @Override
  public exitRule(listener: JQLParserListener): void {
    if (listener.exitOrderBy) {
      listener.exitOrderBy(this);
    }
  }
  // @Override
  public accept<Result>(visitor: JQLParserVisitor<Result>): Result {
    if (visitor.visitOrderBy) {
      return visitor.visitOrderBy(this);
    } else {
      return visitor.visitChildren(this);
    }
  }
}

export class SearchSortContext extends ParserRuleContext {
  public field(): FieldContext {
    return this.getRuleContext(0, FieldContext);
  }
  public DESC(): TerminalNode | undefined {
    return this.tryGetToken(JQLParser.DESC, 0);
  }
  public ASC(): TerminalNode | undefined {
    return this.tryGetToken(JQLParser.ASC, 0);
  }
  constructor(parent: ParserRuleContext | undefined, invokingState: number) {
    super(parent, invokingState);
  }
  // @Override
  public get ruleIndex(): number {
    return JQLParser.RULE_searchSort;
  }
  // @Override
  public enterRule(listener: JQLParserListener): void {
    if (listener.enterSearchSort) {
      listener.enterSearchSort(this);
    }
  }
  // @Override
  public exitRule(listener: JQLParserListener): void {
    if (listener.exitSearchSort) {
      listener.exitSearchSort(this);
    }
  }
  // @Override
  public accept<Result>(visitor: JQLParserVisitor<Result>): Result {
    if (visitor.visitSearchSort) {
      return visitor.visitSearchSort(this);
    } else {
      return visitor.visitChildren(this);
    }
  }
}
