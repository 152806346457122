import React from 'react';
import type { CustomGlyphProps } from '@atlaskit/icon';

export const sortAscMatrixGlyph = (props: CustomGlyphProps) => (
	<svg
		{...props}
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M6 4C5.7 4 5.4 4.1 5.2 4.3L2.3 7.2C1.9 7.6 1.9 8.3 2.3 8.7C2.7 9.1 3.3 9.1 3.7 8.7L5 7.4L5 19.1C5 19.6 5.4 20 6 20C6.6 20 7 19.6 7 19.1L7 7.4L8.3 8.7C8.7 9.1 9.3 9.1 9.7 8.7C10.1 8.3 10.1 7.6 9.7 7.2L6.8 4.3C6.6 4.1 6.3 4 6 4Z"
			fill="currentColor"
		/>
		<path
			d="M13 19L21 19C21.6 19 22 18.6 22 18C22 17.4 21.6 17 21 17L13 17C12.4 17 12 17.4 12 18C12 18.6 12.4 19 13 19Z"
			fill="currentColor"
		/>
		<path
			d="M13 11L18 11C18.6 11 19 11.4 19 12C19 12.6 18.6 13 18 13L13 13C12.4 13 12 12.6 12 12C12 11.4 12.4 11 13 11Z"
			fill="currentColor"
		/>
		<path
			d="M16 5L13 5C12.4 5 12 5.4 12 6C12 6.6 12.4 7 13 7L16 7C16.6 7 17 6.6 17 6C17 5.4 16.6 5 16 5Z"
			fill="currentColor"
		/>
	</svg>
);

export const sortDescMatrixGlyph = (props: CustomGlyphProps) => (
	<svg
		{...props}
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M6 20C5.7 20 5.4 19.9 5.2 19.7L2.3 16.8C1.9 16.4 1.9 15.7 2.3 15.3C2.7 14.9 3.3 14.9 3.7 15.3L5 16.6V4.9C5 4.4 5.4 4 6 4C6.6 4 7 4.4 7 4.9V16.6L8.3 15.3C8.7 14.9 9.3 14.9 9.7 15.3C10.1 15.7 10.1 16.4 9.7 16.8L6.8 19.7C6.6 19.9 6.3 20 6 20Z"
			fill="currentColor"
		/>
		<path
			d="M13 5H21C21.6 5 22 5.4 22 6C22 6.6 21.6 7 21 7H13C12.4 7 12 6.6 12 6C12 5.4 12.4 5 13 5Z"
			fill="currentColor"
		/>
		<path
			d="M13 13H18C18.6 13 19 12.6 19 12C19 11.4 18.6 11 18 11H13C12.4 11 12 11.4 12 12C12 12.6 12.4 13 13 13Z"
			fill="currentColor"
		/>
		<path
			d="M16 19H13C12.4 19 12 18.6 12 18C12 17.4 12.4 17 13 17H16C16.6 17 17 17.4 17 18C17 18.6 16.6 19 16 19Z"
			fill="currentColor"
		/>
	</svg>
);
