import React, { type ReactNode } from 'react';
import { graphql, useFragment } from 'react-relay';
import type { AllowedValues } from '@atlassian/jira-issue-field-injections/src/controllers/injections-context/types.tsx';
import type { FieldOptionsFilter } from '@atlassian/jira-issue-field-injections/src/controllers/types.tsx';
import type {
	PLAIN_TEXT,
	RICH_CONTENT,
} from '@atlassian/jira-issue-shared-types/src/common/types/field-schema-type.tsx';
import type { ui_issueFormFieldBase_JiraIssueFieldFormComponent$key as FormFieldBaseFragment } from '@atlassian/jira-relay/src/__generated__/ui_issueFormFieldBase_JiraIssueFieldFormComponent.graphql';
import { InjectionsProvider } from '../controllers/injections-provider/index.tsx';
import { FieldContainer } from './field-container/index.tsx';
import { Field } from './field/index.tsx';

type Renderer = typeof PLAIN_TEXT | typeof RICH_CONTENT;

export type Props<T> = {
	children: (args: {
		value: T;
		onChange: (...args: unknown[]) => void;
		onBlur: (...args: unknown[]) => void;
		isDisabled: boolean;
		isRequired: boolean;
		label: string;
		error?: string;
		isInvalid: boolean;
		allowedValues?: AllowedValues<T>;
		timeZone?: string | null;
		id: string;
		fieldOptionsFilter?: FieldOptionsFilter;
		fetchSuggestionsOnMount?: boolean;
		ariaLabelledBy?: string;
	}) => ReactNode;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	issueFieldFragment: any;
	defaultValue?: T;
	allowedValues?: AllowedValues<T>;
	renderer?: Renderer;
	onValidate?: (
		value: T,
		isRequired: boolean,
		type: string | undefined,
	) => string | undefined | Promise<string | undefined>;
	shouldHideLabel?: boolean;
	shouldHideFieldMessage?: boolean;
	fieldHelpTextUrl?: string;
};

/**
 * An AkField component which attaches itself to the JiraIssueField interface in AGG
 * to provide default behaviour for all JiraIssueFields in forms.
 *
 * It provides extensibility capabilities consumed by UI modifications.
 */
export const JiraIssueFieldFormComponent = <T,>({
	issueFieldFragment,
	children,
	defaultValue,
	allowedValues,
	onValidate,
	renderer,
	shouldHideLabel = false,
	shouldHideFieldMessage = false,
	fieldHelpTextUrl,
}: Props<T>) => {
	const data = useFragment<FormFieldBaseFragment>(
		graphql`
			fragment ui_issueFormFieldBase_JiraIssueFieldFormComponent on JiraIssueField {
				name
				fieldId
				type
				description
				... on JiraIssueFieldConfiguration {
					fieldConfig {
						isRequired
						isEditable
					}
				}
			}
		`,
		issueFieldFragment,
	);

	const isEditable =
		typeof data?.fieldConfig?.isEditable === 'boolean' ? data?.fieldConfig?.isEditable : true;

	if (!data?.fieldConfig) return null;

	const { fieldId, type: fieldType, name: label, fieldConfig, description } = data;

	const isRequired = typeof fieldConfig.isRequired === 'boolean' ? fieldConfig.isRequired : false;

	const isDisabled = !isEditable;
	const id = `${fieldId}-field`;

	return (
		<InjectionsProvider>
			<FieldContainer
				defaultValue={defaultValue}
				fieldName={fieldId}
				label={label}
				fieldType={fieldType}
				isRequired={isRequired}
				isDisabled={isDisabled}
				onValidate={onValidate}
				id={id}
				renderer={renderer}
				shouldHideLabel={shouldHideLabel}
			>
				{({ fieldProps, error }) => (
					<Field<T>
						label={label}
						fieldProps={fieldProps}
						error={error}
						description={description}
						allowedValues={allowedValues}
						fieldHelpTextUrl={fieldHelpTextUrl}
						shouldShowFieldMessage={!shouldHideFieldMessage}
					>
						{children}
					</Field>
				)}
			</FieldContainer>
		</InjectionsProvider>
	);
};
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { NonEditableReasonTooltipWrapper } from './non-editable-reason-tooltip-wrapper';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { Field } from './field';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { FieldContainer } from './field-container';
