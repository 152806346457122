// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	warningLabel: {
		id: 'attachments.heading.title.warning',
		defaultMessage:
			'This issue may stop displaying previews and thumbnails if more attachments are added.',
		description: 'The warning label when user is approaching the limit',
	},
	warningLinkLabel: {
		id: 'attachments.heading.title.warning.link',
		defaultMessage: 'Learn about attachments',
		description: 'The label for the attachments meatball menu',
	},
	warningLabelIssueTermRefresh: {
		id: 'attachments.heading.title.warning-issue-term-refresh',
		defaultMessage:
			'This issue may stop displaying previews and thumbnails if more attachments are added.',
		description: 'The warning label when user is approaching the limit',
	},
});
