// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction
/* eslint-disable jira/i18n/id-named-by-package */

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	move: {
		id: 'issue.move',
		defaultMessage: 'Move',
		description: 'Label for the issue actions item to move the issue',
	},
	clone: {
		id: 'issue.clone',
		defaultMessage: 'Clone',
		description: 'Label for the issue actions item to clone the issue',
	},
	delete: {
		id: 'issue.delete',
		defaultMessage: 'Delete',
		description: 'Label for the issue actions item to delete the issue',
	},
	print: {
		id: 'issue.print',
		defaultMessage: 'Print',
		description: 'Label for the issue actions item to print the issue',
	},
	exportXML: {
		id: 'issue.export-xml',
		defaultMessage: 'Export XML',
		description: 'Label for the issue actions item to export the issue to XML',
	},
	exportDOC: {
		id: 'issue.export-doc',
		defaultMessage: 'Export Word',
		description: 'Label for the issue actions item to export the issue to Microsoft Word',
	},
	switchToModal: {
		id: 'issue.switch-to-modal',
		defaultMessage: 'Open issues in dialog',
		description: 'Label for the issue actions item to open issues in a dialog',
	},
	switchToSidebar: {
		id: 'issue.switch-to-sidebar',
		defaultMessage: 'Open issues in sidebar',
		description: 'Label for the issue actions item the open issues in the sidebar',
	},
	newIssueView: {
		id: 'issue.title-for-new-issue-actions',
		defaultMessage: 'New Jira issue view',
		description: 'Label for the section heading for the "New Jira issue view" issue actions',
	},
	swiftLink: {
		id: 'issue.swift-link',
		defaultMessage: 'Configure',
		description:
			'Label for a the issue actions item which takes the user to the SWIFT Issue Type config page',
	},
	swiftBacklink: {
		id: 'issue.swift-backlink-title',
		defaultMessage: 'Back to {issueKey}',
		description:
			'Text for a link which will return the user to this page, from the Issue Type config page',
	},
	startTour: {
		id: 'issue.start-guided-tour',
		defaultMessage: 'Show me the highlights',
		description: 'Label for the issue actions item which starts the guided tour',
	},
	findOutMore: {
		id: 'issue.find-out-more',
		defaultMessage: 'Find out more',
		description:
			'Label for the issue actions item which shows the user more information about the new issue view',
	},
	seeOldView: {
		id: 'issue.see-old-view',
		defaultMessage: 'See the old view',
		description: 'Label for the issue actions item which redirects to the old issue view',
	},
	turnOffNewIssueView: {
		id: 'issue.turn-off-new-issue-view',
		defaultMessage: 'Turn off new issue view',
		description: 'Label for the issue actions item which turns off the new issue view',
	},
	turnOffForNow: {
		id: 'issue.turn-off-issue-view-for-now',
		defaultMessage: 'Turn off for now',
		description: 'Label for the issue actions item which turns off the new issue view',
	},
	triggerButtonLabel: {
		id: 'issue.label-for-issue-actions-button',
		defaultMessage: 'Actions',
		description: 'Label for the button that opens the issue actions dropdown menu',
	},
	configure: {
		id: 'issue.configure',
		defaultMessage: 'Configure',
		description: 'Label for the issue action item that links to the classic screen config page',
	},
	addToParentLevel: {
		id: 'issue.add-to-parent-level',
		defaultMessage: 'Add parent',
		description: 'Meatball option to add a child to a parent level',
	},
	changeParentLevel: {
		id: 'issue.change-parent-level',
		defaultMessage: 'Change parent',
		description: 'Meatball option to change a child parent level',
	},
	convertToSubtask: {
		id: 'issue.convert-to-subtask',
		defaultMessage: 'Convert to Subtask',
		description: 'Meatball option to change an issue to a subtask',
	},
	convertToIssue: {
		id: 'issue.convert-to-issue',
		defaultMessage: 'Convert to Issue',
		description: 'Meatball option to change a subtask to a parent level issue',
	},
	switchToModalIssueTermRefresh: {
		id: 'issue.switch-to-modal-issue-term-refresh',
		defaultMessage: 'Open issues in dialog',
		description: 'Label for the issue actions item to open issues in a dialog',
	},
	switchToSidebarIssueTermRefresh: {
		id: 'issue.switch-to-sidebar-issue-term-refresh',
		defaultMessage: 'Open issues in sidebar',
		description: 'Label for the issue actions item the open issues in the sidebar',
	},
	newIssueViewIssueTermRefresh: {
		id: 'issue.title-for-new-issue-actions-issue-term-refresh',
		defaultMessage: 'New Jira issue view',
		description: 'Label for the section heading for the "New Jira issue view" issue actions',
	},
	turnOffNewIssueViewIssueTermRefresh: {
		id: 'issue.turn-off-new-issue-view-issue-term-refresh',
		defaultMessage: 'Turn off new issue view',
		description: 'Label for the issue actions item which turns off the new issue view',
	},
	convertToIssueIssueTermRefresh: {
		id: 'issue.convert-to-issue-issue-term-refresh',
		defaultMessage: 'Convert to Issue',
		description: 'Meatball option to change a subtask to a parent level issue',
	},
});
