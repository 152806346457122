import isNil from 'lodash/isNil';
import reduce from 'lodash/reduce';
import { getAriConfig, type Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import type { GetPolarisProjectQuery, ViewsetsTypeGQL } from './get/types.tsx';

const getViewSetAndViewsCount = (
	viewsets: ReadonlyArray<ViewsetsTypeGQL | ViewsetsTypeGQL['viewsets']> | null,
): { viewsCount: number; viewsetsCount: number } => {
	if (isNil(viewsets)) {
		return { viewsCount: 0, viewsetsCount: 0 };
	}
	return reduce(
		viewsets,
		(acc, viewset) => {
			// @ts-expect-error - Property 'viewsets' does not exist on type 'readonly jira_polaris_ProjectQuery_reduced_polarisProject_viewsets_viewsets[]
			const childrenCounts = getViewSetAndViewsCount(viewset.viewsets);
			return {
				viewsCount:
					acc.viewsCount +
					// @ts-expect-error - Property 'views' does not exist on type 'readonly jira_polaris_ProjectQuery_reduced_polarisProject_viewsets_viewsets[]
					(viewset.views?.length || 0) +
					childrenCounts.viewsCount,
				viewsetsCount: acc.viewsetsCount + 1 + childrenCounts.viewsetsCount,
			};
		},
		{ viewsCount: 0, viewsetsCount: 0 },
	);
};

type Props<T> = {
	projectAri: Ari;
	durationMs: number;
	data: T;
};

export const createAnalyticsEventPayload = (
	props: Props<GetPolarisProjectQuery>,
): {
	[key: string]: string | number | boolean | undefined;
} => {
	const { projectAri, durationMs, data } = props;

	try {
		if (!isNil(data) && !isNil(data.polarisProject)) {
			const { onboarded, viewsets } = data.polarisProject;

			const { cloudId, resourceId } = getAriConfig(projectAri);

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			const { hostname } = window.location;
			const viewSetsAndViewsCount = getViewSetAndViewsCount(viewsets);

			return {
				durationMs,
				onboarded,
				cloudId,
				hostname,
				projectId: resourceId,
				viewsets: viewSetsAndViewsCount.viewsetsCount,
				views: viewSetsAndViewsCount.viewsCount,
			};
		}

		return {
			durationMs,
		};

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		return {
			durationMs,
			analyticsDataError: e,
		};
	}
};
