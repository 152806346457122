/**
 * @generated SignedSource<<5f87bbae0df7928fcb860f8d696c8c51>>
 * @relayHash 029277561796d9f13acaef1dbb085ee8
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID a6baebfa2f37e676d26595f29fc232725ad41353c9fcd04168be34a6b616f152

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type JiraSingleValueFieldOperations = "SET" | "%future added value";
export type JiraUpdateSingleLineTextFieldInput = {
  id: string;
  operation: JiraSingleLineTextFieldOperationInput;
};
export type JiraSingleLineTextFieldOperationInput = {
  operation: JiraSingleValueFieldOperations;
  text?: string | null | undefined;
};
export type singleLineText_issueFieldSingleLineText_SingleLineTextField_Mutation$variables = {
  input: JiraUpdateSingleLineTextFieldInput;
};
export type singleLineText_issueFieldSingleLineText_SingleLineTextField_Mutation$data = {
  readonly jira: {
    readonly updateSingleLineTextField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly id: string;
        readonly text: string | null | undefined;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type singleLineText_issueFieldSingleLineText_SingleLineTextField_Mutation$rawResponse = {
  readonly jira: {
    readonly updateSingleLineTextField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly id: string;
        readonly text: string | null | undefined;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type singleLineText_issueFieldSingleLineText_SingleLineTextField_Mutation = {
  rawResponse: singleLineText_issueFieldSingleLineText_SingleLineTextField_Mutation$rawResponse;
  response: singleLineText_issueFieldSingleLineText_SingleLineTextField_Mutation$data;
  variables: singleLineText_issueFieldSingleLineText_SingleLineTextField_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "concreteType": "JiraMutation",
    "kind": "LinkedField",
    "name": "jira",
    "plural": false,
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "JiraSingleLineTextFieldPayload",
        "kind": "LinkedField",
        "name": "updateSingleLineTextField",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "name": "success"
          },
          {
            "concreteType": "MutationError",
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "message"
              }
            ]
          },
          {
            "concreteType": "JiraSingleLineTextField",
            "kind": "LinkedField",
            "name": "field",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "text"
              },
              {
                "kind": "ScalarField",
                "name": "id"
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "singleLineText_issueFieldSingleLineText_SingleLineTextField_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "singleLineText_issueFieldSingleLineText_SingleLineTextField_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "a6baebfa2f37e676d26595f29fc232725ad41353c9fcd04168be34a6b616f152",
    "metadata": {},
    "name": "singleLineText_issueFieldSingleLineText_SingleLineTextField_Mutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "7910ed923271af0f6fe2fd325e2358de";

export default node;
