import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { RESULTS } from '../constants.tsx';

export const FieldConfig = {
	labelChangedSuccess: (event: UIAnalyticsEvent, attributes: object) => {
		fireTrackAnalytics(event, 'issueFields updated', {
			updatedItems: [{ name: 'label' }],
			...attributes,
		});
	},
	labelChangedFailure: (event: UIAnalyticsEvent) => {
		// https://data-portal.internal.atlassian.com/analytics/registry/47519
		fireTrackAnalytics(event, 'fieldConfig updated', 'labelChanged', {
			result: RESULTS.FAILURE,
		});
	},
	emojiChangedSuccess: (event: UIAnalyticsEvent, attributes: object) => {
		fireTrackAnalytics(event, 'issueFields updated', {
			updatedItems: [{ name: 'emoji' }],
			...attributes,
		});
	},
	emojiChangedFailure: (event: UIAnalyticsEvent) => {
		// https://data-portal.internal.atlassian.com/analytics/registry/47519
		fireTrackAnalytics(event, 'fieldConfig updated', 'emojiChanged', {
			result: RESULTS.FAILURE,
		});
	},
	descriptionChangedSuccess: (event: UIAnalyticsEvent, attributes: object) => {
		fireTrackAnalytics(event, 'issueFields updated', {
			updatedItems: [{ name: 'description' }],
			...attributes,
		});
	},
	descriptionChangedFailure: (event: UIAnalyticsEvent) => {
		// https://data-portal.internal.atlassian.com/analytics/registry/47519
		fireTrackAnalytics(event, 'fieldConfig updated', 'descriptionChanged', {
			result: RESULTS.FAILURE,
		});
	},
	fieldDeletedSuccess: (event: UIAnalyticsEvent, attributes: object) => {
		// https://data-portal.internal.atlassian.com/analytics/registry/51170
		fireTrackAnalytics(event, 'issueField deleted', attributes);
	},
	fieldDeletedFailure: (event: UIAnalyticsEvent) => {
		// https://data-portal.internal.atlassian.com/analytics/registry/47519
		fireTrackAnalytics(event, 'fieldConfig updated', 'fieldDeleted', {
			result: RESULTS.FAILURE,
		});
	},
	optionLabelChanged: (event: UIAnalyticsEvent, attributes: object) => {
		fireTrackAnalytics(event, 'issueFields updated', {
			updatedItems: [{ name: 'optionLabel' }],
			...attributes,
		});
	},
	optionCreatedSuccess: (event: UIAnalyticsEvent, attributes: object) => {
		fireTrackAnalytics(event, 'issueFields updated', {
			updatedItems: [{ name: 'optionLabel', updateType: 'created' }],
			...attributes,
		});
	},
	optionCreatedFailure: (event: UIAnalyticsEvent) => {
		// https://data-portal.internal.atlassian.com/analytics/registry/47519
		fireTrackAnalytics(event, 'fieldConfig updated', 'optionCreated', {
			result: RESULTS.FAILURE,
		});
	},
	optionDeletedSuccess: (event: UIAnalyticsEvent, attributes: object) => {
		fireTrackAnalytics(event, 'issueFields updated', {
			updatedItems: [{ name: 'optionLabel', updateType: 'deleted' }],
			...attributes,
		});
	},
	optionDeletedFailure: (event: UIAnalyticsEvent) => {
		// https://data-portal.internal.atlassian.com/analytics/registry/47519
		fireTrackAnalytics(event, 'fieldConfig updated', 'optionDeleted', {
			result: RESULTS.FAILURE,
		});
	},
	optionWeightChanged: (event: UIAnalyticsEvent, attributes: object) => {
		fireTrackAnalytics(event, 'issueFields updated', {
			updatedItems: [{ name: 'optionWeight' }],
			...attributes,
		});
	},
	optionRankChanged: (event: UIAnalyticsEvent, attributes: object) => {
		fireTrackAnalytics(event, 'issueFields updated', {
			updatedItems: [{ name: 'optionRank' }],
			...attributes,
		});
	},
	optionWeightTypeChanged: (event: UIAnalyticsEvent, attributes: object) => {
		fireTrackAnalytics(event, 'issueFields updated', {
			updatedItems: [{ name: 'optionWeightType' }],
			...attributes,
		});
	},
	valueDecorationChanged: (event: UIAnalyticsEvent, attributes: object) => {
		fireTrackAnalytics(event, 'issueFields updated', {
			updatedItems: [{ name: 'fieldValueDecorations' }],
			...attributes,
		});
	},
};
