import traceUFOPress from '@atlaskit/react-ufo/trace-press';
import { fg } from '@atlassian/jira-feature-gating';
import {
	IssueTransitionLoadUFOExperience,
	IssueTransitionUFOExperience,
} from '@atlassian/jira-issue-transition-analytics/src/services/experiences/index.tsx';
import {
	issueTransittionModalLoadSubsequent,
	issueTransitionModalLoad,
} from '@atlassian/jira-issue-transition-analytics/src/services/performance-analytics/index.tsx';
import { startUimTTI } from '@atlassian/jira-ui-modifications-analytics/src/common/utils/performance-analytics/issue-transition.tsx';

export const startIssueTransitionMetrics = (isSubsequentLoad: boolean) => {
	IssueTransitionLoadUFOExperience.start();
	IssueTransitionUFOExperience.start();
	const issueTransitionModalLoadMetric = isSubsequentLoad
		? issueTransittionModalLoadSubsequent
		: issueTransitionModalLoad;
	if (isSubsequentLoad) {
		traceUFOPress('issue-transition.transition-modal-subsequent');
	} else {
		traceUFOPress('issue-transition.transition-modal');
	}

	issueTransitionModalLoadMetric.start();

	if (fg('ui-modifications-on-issue-transition-view_7z1g0')) {
		startUimTTI();
	}
};
