import React from 'react';
import type { DataProvider } from '../../model/data-provider.tsx';
import { DataProviderContext } from './context.tsx';

type OwnProps = {
	readonly dataProvider: DataProvider;
};

export const WithDataProvider =
	<
		TProps extends {
			readonly dataProvider?: DataProvider;
		},
	>(
		WrappedComponent: Flow.AbstractComponent<TProps & OwnProps>,
	): Flow.AbstractComponent<TProps> =>
	// @ts-expect-error - TS2322 - Type '(props: TProps) => JSX.Element' is not assignable to type 'AbstractComponent<TProps, any>'.
	(props: TProps) => {
		const { dataProvider, ...otherProps } = props;

		return (
			<DataProviderContext.Consumer>
				{(contextDataProvider: () => DataProvider) => (
					// @ts-expect-error - TS2322 - Type 'Omit<TProps, "dataProvider"> & { dataProvider: DataProvider; }' is not assignable to type 'IntrinsicAttributes & PropsWithoutRef<TProps & OwnProps> & RefAttributes<any> & { children?: ReactNode; }'.
					<WrappedComponent {...otherProps} dataProvider={dataProvider || contextDataProvider()} />
				)}
			</DataProviderContext.Consumer>
		);
	};
