import React from 'react';
import { styled } from '@compiled/react';
import { useUID } from 'react-uid';
import { Label } from '@atlaskit/form';
import useUFOTypingPerformanceTracing from '@atlaskit/react-ufo/typing-performance-tracing';
import Textfield from '@atlaskit/textfield';
import type { Props } from './types.tsx';

export const InlineField = ({ headingText, ...TextfieldProps }: Props) => {
	const typeInstrumentationRef = useUFOTypingPerformanceTracing(
		'create-web-link-inline-field-type-tracing',
	);

	const uid = useUID();

	return (
		<TextFieldWrapper>
			<Label htmlFor={uid}>{headingText}</Label>
			<Textfield id={uid} ref={typeInstrumentationRef} {...TextfieldProps} />
		</TextFieldWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TextFieldWrapper = styled.div({
	flex: 'auto',
});
