import type { DynamicFieldFormula, FieldVisitor, FormulaVisitor } from '../types.tsx';
import { DISCRETIZATION_TEMPLATE } from './types.tsx';

export const discretizationVisitFields = (
	formula: DynamicFieldFormula,
	visitField: FieldVisitor,
	visitFormula: FormulaVisitor,
): boolean => {
	if (formula.template !== DISCRETIZATION_TEMPLATE) {
		return false;
	}
	visitFormula(formula.parameters.formula);
	return true;
};
