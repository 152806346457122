import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	showAll: {
		id: 'issue-all-activity.grouped-activity-entry.show-all',
		defaultMessage: 'Show all changes',
		description:
			'Label for the button that opens the whole list of the entries in a group in a history timeline',
	},
	showLess: {
		id: 'issue-all-activity.grouped-activity-entry.show-less',
		defaultMessage: 'Show less changes',
		description:
			'Label for the button that hides the whole list of the entries in a group in a history timeline',
	},
	change: {
		id: 'issue-all-activity.grouped-activity-entry.change',
		defaultMessage:
			'<wrapper>{actorName}</wrapper> made {numberOfChanges} {numberOfChanges, plural, one {change} other {changes}}',
		description: 'Label for the header of the list of the entries in a group in a history timeline',
	},
	anonymousUser: {
		id: 'issue-all-activity.grouped-activity-entry.anonymous-user',
		defaultMessage: 'Anonymous user',
		description:
			'Text to indicate that an action was performed by an Anonymous User. Displayed before the action performed e.g. "Anonymous User {didSomething}".',
	},
});
