import type { LabelSuggestion } from '@atlassian/jira-issue-shared-types/src/common/types/labels-type.tsx';
import type { Registry } from '../../common/types.tsx';
import createEntry from '../../common/utils/api/index.tsx';
import { props } from './constants.tsx';

const labelsCaches: { [labelsFieldId: string]: Registry<LabelSuggestion> } = {};

export const getLabelsCache = (labelsFieldId?: string) => {
	const cacheKey = labelsFieldId || props.cacheKey;
	if (!labelsCaches[cacheKey]) {
		labelsCaches[cacheKey] = createEntry({
			...props,
			cacheKey,
		});
	}
	return labelsCaches[cacheKey];
};
