import React, { useCallback, type ComponentType } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { Placement } from '@atlaskit/popper';
import type { ActionTypes, User as UserOption } from '@atlaskit/user-picker';
import { fg } from '@atlassian/jira-feature-gating';
import { getUpdateAnalyticsFlowHelper } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/index.tsx';
import { ErrorFlag } from '@atlassian/jira-issue-error-flag/src/index.tsx';
import { useFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import {
	AnalyticsEventToProps,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { AssigneeKey } from '@atlassian/jira-providers-issue/src/model/issue-system-fields.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { UserValue } from '../../common/types.tsx';
import { AssigneePickerView } from '../../common/ui/read-view/popover/index.tsx';
import useAssigneeField from '../../services/use-assignee-field/index.tsx';
import AssigneePickerEdit from './edit-view/main.tsx';
import type { AssigneePickerEditProps } from './edit-view/types.tsx';
import messages from './messages.tsx';

const AssigneePickerEditWithAnalytics: ComponentType<
	AssigneePickerEditProps & {
		readonly onChange?: (
			updatedValue: UserValue,
			action: ActionTypes,
			analyticsEvent: UIAnalyticsEvent,
		) => void;
	}
> = AnalyticsEventToProps('assigneePickerEdit', {
	onChange: 'changed',
})(AssigneePickerEdit);

export type AssigneeFieldProps = {
	readonly actionSubject?: string;
	readonly issueKey: IssueKey;
	readonly shouldPreloadAssignToMe?: boolean;
	readonly enablePeopleInvite?: boolean;
	readonly popupPlacement?: Placement;
	readonly onFocus?: (sessionId?: string) => void;
	readonly onChange?: (updatedValue: UserValue, action: ActionTypes) => void;
	readonly onUpdate?: (newValue: UserValue) => void;
	readonly onFailure?: (error: Error) => void;
	readonly onSubmit?: (
		oldValue: UserValue,
		newValue: UserValue,
		meta: unknown,
		event: UIAnalyticsEvent,
	) => void;
	readonly isEligibleForInviteAndAssign?: boolean;
	readonly suggestedEmailDomain?: string;
	readonly onInviteAndAssignOption?: (value: UserOption, action: ActionTypes) => void;
	readonly onOpen?: () => void;
	readonly onClose?: () => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	readonly onSaveField?: (issueKey: IssueKey, fieldId: string, value: UserValue) => Promise<any>;
	readonly enableAutomaticOption?: boolean;
};

const AssigneeField = ({
	issueKey,
	onChange,
	onUpdate,
	onFailure,
	onSubmit,
	onSaveField,
	onOpen,
	...rest
}: AssigneeFieldProps) => {
	const [{ value: fieldConfig }] = useFieldConfig(issueKey, AssigneeKey);
	const [{ value, error }, { saveValue }] = useAssigneeField({
		issueKey,
		onSuccess: onUpdate,
		onFailure,
		onSubmit,
		onSaveField,
	});
	const { autoCompleteUrl, isEditable = false } = fieldConfig || {};
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleOnChange = useCallback(
		(updatedValue: UserValue, action: ActionTypes, analyticsEvent: UIAnalyticsEvent) => {
			onChange?.(updatedValue, action);
			saveValue(updatedValue, null, analyticsEvent);
		},
		[onChange, saveValue],
	);

	const handleOnOpen = fg('one_event_rules_them_all_fg')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useCallback(() => {
				onOpen?.();
				getUpdateAnalyticsFlowHelper().fireAnalyticsStart(AssigneeKey, {
					analytics: createAnalyticsEvent({}),
					attributes: {
						fieldType: AssigneeKey,
						isInlineEditing: true,
					},
				});
			}, [createAnalyticsEvent, onOpen])
		: undefined;

	return (
		<>
			{error && (
				<ErrorFlag error={error} title={messages.errorTitle} description={messages.errorMessage} />
			)}

			{isEditable ? (
				<AssigneePickerEditWithAnalytics
					autoCompleteUrl={autoCompleteUrl}
					value={value}
					// @ts-expect-error - TS2322 - Type '(updatedValue: UserValue, action: ActionTypes, analyticsEvent: UIAnalyticsEventInterface) => void' is not assignable to type '((updatedValue: UserValue, action?: ActionTypes | undefined) => void) & ((updatedValue: UserValue, action: ActionTypes, analyticsEvent: UIAnalyticsEventInterface) => void)'.
					onChange={handleOnChange}
					onOpen={fg('one_event_rules_them_all_fg') ? handleOnOpen : onOpen}
					{...rest}
				/>
			) : (
				<AssigneePickerView value={value} />
			)}
		</>
	);
};

export default AssigneeField;
