import React, { useCallback, memo, useState, useRef, type ReactNode } from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';

import { statusCategories } from '@atlassian/jira-common-constants/src/index.tsx';

import { layers, gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { DeliverySummary } from '@atlassian/jira-issue-dev-ops-summary/src/ui/delivery-summary/index.tsx';
import type { UserValue } from '@atlassian/jira-issue-field-assignee/src/common/types.tsx';
import { useFieldConfigStore } from '@atlassian/jira-issue-field-base/src/services/field-config-service/context.tsx';
import type { StatusValue } from '@atlassian/jira-issue-field-status/src/common/types.tsx';
import { IssueType } from '@atlassian/jira-issue-line-card-issue-type/src/index.tsx';
import { ASSIGNEE_TYPE, SUMMARY_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { isDevopsFeatureDisabledInFedRamp } from '@atlassian/jira-software-devops-fedramp-utils/src/index.tsx';
import { FlexContainer } from '../common/ui/styled.tsx';
import { InteractiveAssignee } from './assignee/interactive-assignee/index.tsx';
import { ReadOnlyAssignee } from './assignee/read-only-assignee/index.tsx';
import { Error } from './error/index.tsx';
import { Estimate } from './estimate/index.tsx';
import { IssueKey } from './issue-key/index.tsx';
import { LoadingSpinner } from './loading-spinner/index.tsx';
import { Priority } from './priority/index.tsx';
import { ReadOnlyEstimate } from './read-only-estimate/index.tsx';
import { SummaryReadOnly } from './read-only-summary/index.tsx';
import { RemoveButton } from './remove-button/index.tsx';
import { Status } from './status/index.tsx';
import { Summary } from './summary/index.tsx';
import type { IssueLineCardProps } from './types.tsx';

const { statusCategoryForId } = statusCategories;

const testIdSelectors = {
	card: 'issue-line-card.card-container',
} as const;

const IssueLineCard = memo<IssueLineCardProps>(
	({
		additionalItems,
		assigneeDisplayName,
		assigneeUrl,
		canRemove = true,
		errorInlineMessage,
		estimateFieldId,
		globalId,
		hasError = false,
		id,
		isActive = false,
		isDraggable = false,
		isFocused = false,
		isHovering = false,
		isInlineEditEnabled = false,
		isInteractive = true,
		isLoading = false,
		isRemote = false,
		isResolved,
		issueKey,
		issueLink,
		issuePriorityName,
		issuePriorityUrl,
		issueSummary,
		issueTypeIconUrl,
		issueTypeIcon,
		issueTypeName,
		linkId,
		onDelete = noop,
		onEdit = noop,
		onClick = noop,
		onHandleUserClickAnalytics,
		projectType,
		remoteServerHostname,
		reorderMenu,
		restrictedReorderingMessage = '',
		shouldDisplayDevOpsData = false,
		showHoverPreviewEnabled = false,
		statusCategory,
		statusCategoryId,
		statusId,
		statusName,
		subtitle,
	}: IssueLineCardProps) => {
		const [hasAssigneeFieldConfig] = useFieldConfigStore({
			issueKey,
			fieldKey: ASSIGNEE_TYPE,
		});

		const [hasSummaryFieldConfig] = useFieldConfigStore({
			issueKey,
			fieldKey: SUMMARY_TYPE,
		});

		const [hasStoryPointFieldConfig] = useFieldConfigStore({
			issueKey,
			fieldKey: estimateFieldId || '',
		});

		const hoverTimeoutId = useRef<unknown>(null);
		const [hasHovered, setHasHovered] = useState(false);

		const isActionVisible = !isActive && (isHovering || isFocused);
		const shouldShowRemoveAction = isActive || isHovering || isFocused;

		const handleEditSummary = useCallback(
			(summary: string | null) => {
				onEdit({ issueSummary: summary || undefined });
			},
			[onEdit],
		);

		const handleEditAssignee = useCallback(
			(assignee: UserValue) => {
				onEdit({
					assigneeDisplayName: assignee?.displayName,
					assigneeUrl: assignee?.avatarUrls?.['16x16'],
				});
			},
			[onEdit],
		);

		const handleEditStatus = useCallback(
			(status: StatusValue) =>
				onEdit({
					statusId: status.id,
					statusName: status.name,
					statusCategory: statusCategoryForId(status.statusCategory.id),
					statusCategoryId: status.statusCategory.id,
				}),
			[onEdit],
		);

		const handleOnMouseEnter = useCallback(() => {
			if (hoverTimeoutId.current) {
				// @ts-expect-error - TS2769 - No overload matches this call.
				clearTimeout(hoverTimeoutId.current);
			}

			// delay rendering interactive elements to avoid unnecessary render when scrolling
			hoverTimeoutId.current = setTimeout(() => {
				setHasHovered(true);
			}, 200);
		}, [setHasHovered]);

		const handleOnMouseLeave = useCallback(() => {
			if (hoverTimeoutId.current) {
				// @ts-expect-error - TS2769 - No overload matches this call.
				clearTimeout(hoverTimeoutId.current);
				hoverTimeoutId.current = null;
				setHasHovered(false);
			}
		}, [setHasHovered]);

		const handleOnFocus = useCallback(() => {
			setHasHovered(true);
		}, [setHasHovered]);

		const canEditStoryPoint = isInlineEditEnabled && (hasStoryPointFieldConfig || hasHovered);

		const estimateComponent =
			estimateFieldId !== undefined && canEditStoryPoint ? (
				<Estimate issueKey={issueKey} estimateFieldId={estimateFieldId} />
			) : (
				estimateFieldId !== undefined && (
					<ReadOnlyEstimate issueKey={issueKey} estimateFieldId={estimateFieldId} />
				)
			);

		const card = (
			<Card
				isFocused={isFocused}
				isHovering={isHovering}
				onMouseEnter={handleOnMouseEnter}
				onMouseLeave={handleOnMouseLeave}
				onFocus={handleOnFocus}
				data-testid={testIdSelectors.card}
			>
				<IssueType
					issueTypeName={issueTypeName}
					issueTypeIconUrl={issueTypeIconUrl}
					issueTypeIcon={issueTypeIcon}
					isDraggable={isDraggable}
					isActionVisible={isActionVisible}
					restrictedReorderingMessage={restrictedReorderingMessage}
					onHandleUserClickAnalytics={onHandleUserClickAnalytics}
					reorderMenu={reorderMenu}
				/>
				<IssueKey
					issueKey={issueKey}
					issueLink={issueLink}
					hideTooltipOnMouseDown={isDraggable}
					remoteServerHostname={remoteServerHostname}
					statusCategory={statusCategory}
					showHoverPreviewEnabled={showHoverPreviewEnabled}
					isResolved={isResolved}
				/>
				{isInlineEditEnabled && (hasSummaryFieldConfig || hasHovered) ? (
					<Summary
						issueId={id}
						issueKey={issueKey}
						onClick={onClick}
						issueLink={issueLink}
						onUpdate={handleEditSummary}
						showHoverPreviewEnabled={showHoverPreviewEnabled}
					/>
				) : (
					<SummaryReadOnly
						onClick={onClick}
						value={issueSummary}
						isInlineEditEnabled={isInlineEditEnabled}
						issueLink={issueLink}
						showHoverPreviewEnabled={showHoverPreviewEnabled}
					/>
				)}
				{additionalItems?.map((item: ReactNode, index: number) => (
					<AdditionalItemWrapper
						data-testid="issue-line-card.ui.additional-item-wrapper"
						key={index}
					>
						{item}
					</AdditionalItemWrapper>
				))}
				{shouldDisplayDevOpsData && !isDevopsFeatureDisabledInFedRamp() && (
					<DeliverySummary issueId={id} zIndexForPopup={layers.modal} />
				)}
				{issuePriorityUrl != null && (
					<Priority
						issuePriorityUrl={issuePriorityUrl}
						issuePriorityName={issuePriorityName}
						hideTooltipOnMouseDown={isDraggable}
					/>
				)}
				{estimateComponent}
				{isInlineEditEnabled && (hasAssigneeFieldConfig || hasHovered) ? (
					<InteractiveAssignee issueKey={issueKey} onUpdate={handleEditAssignee} />
				) : (
					<ReadOnlyAssignee
						assigneeDisplayName={assigneeDisplayName}
						assigneeUrl={assigneeUrl}
						hideTooltipOnMouseDown={isDraggable}
					/>
				)}
				{statusCategory !== undefined &&
					statusName !== undefined &&
					statusId !== undefined &&
					statusCategoryId !== undefined && (
						<Status
							hideTooltipOnMouseDown={isDraggable}
							isInteractive={isInteractive}
							isRemote={isRemote}
							issueKey={issueKey}
							projectType={projectType}
							statusCategoryId={statusCategoryId}
							statusId={statusId}
							statusName={statusName}
							onEditStatus={handleEditStatus}
						/>
					)}
				{isLoading && <LoadingSpinner />}
				{(hasError || errorInlineMessage) && <Error errorInlineMessage={errorInlineMessage} />}
				{onDelete !== noop && !isLoading && !hasError && canRemove && (
					<RemoveButton
						linkId={linkId}
						issueKey={issueKey}
						isRemote={isRemote}
						shouldShowRemoveAction={shouldShowRemoveAction}
						globalId={globalId}
					/>
				)}
			</Card>
		);

		return subtitle != null ? (
			<MultiLineCardContainer data-testid="issue-line-card.ui.multi-line-card-container">
				{card}
				{subtitle}
			</MultiLineCardContainer>
		) : (
			card
		);
	},
);

export default IssueLineCard;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Card = styled(FlexContainer)<{
	isFocused?: boolean;
	isHovering?: boolean;
	onMouseEnter?: () => void;
	onMouseLeave?: () => void;
	onFocus?: () => void;
}>({
	width: '100%',
	height: '100%',
	position: 'relative',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AdditionalItemWrapper = styled(FlexContainer)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	paddingRight: `${gridSize / 2}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MultiLineCardContainer = styled(Card)({
	flexDirection: 'column',
	alignItems: 'flex-start',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const visibleForTesting = {
	Card,
	AdditionalItemWrapper,
	MultiLineCardContainer,
};
