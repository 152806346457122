// EDITOR_CHANGE_FAILURE
export const EDITOR_CHANGE_FAILURE = 'EDITOR_CHANGE_FAILURE' as const;

export const editorChangeFailure = () => ({
	type: EDITOR_CHANGE_FAILURE,
});

// EDITOR_EXPANDED_FAILURE
export const EDITOR_EXPANDED_FAILURE = 'EDITOR_EXPANDED_FAILURE' as const;

export type EditorChangeFailure = ReturnType<typeof editorChangeFailure>;
export const editorExpandedFailure = () => ({
	type: EDITOR_EXPANDED_FAILURE,
});

// EDITOR_SAVE_FAILURE
export const EDITOR_SAVE_FAILURE = 'EDITOR_SAVE_FAILURE' as const;

export type EditorExpandedFailure = ReturnType<typeof editorExpandedFailure>;
export const editorSaveFailure = () => ({
	type: EDITOR_SAVE_FAILURE,
});

export type EditorSaveFailure = ReturnType<typeof editorSaveFailure>;

export type EditorAction = EditorChangeFailure | EditorExpandedFailure | EditorSaveFailure;
