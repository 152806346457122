// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	linkParentErrorTitle: {
		id: 'issue.views.common.child-issues-panel.flags.link-parent-error-title',
		defaultMessage: "We couldn't link the child issue",
		description:
			'Heading text for error flag that appears when linking child issue to parent fails',
	},
	linkParentErrorDescription: {
		id: 'issue.views.common.child-issues-panel.flags.link-parent-error-description',
		defaultMessage:
			'The issue was created but the linking failed. Head over to your board and try again.',
		description: 'Body text for error flag that appears when linking child issue to parent fails',
	},
	reorderErrorTitle: {
		id: 'issue.views.common.child-issues-panel.flags.reorder-error-title',
		defaultMessage: "We couldn't reorder those issues",
		description: 'Heading text for error flag that appears when reordering fails',
	},
	reorderErrorDescription: {
		id: 'issue.views.common.child-issues-panel.flags.reorder-error-description',
		defaultMessage: 'Wait a few moments, then give it another try.',
		description: 'Body text for error flag that appears when reordering fails',
	},
	linkParentErrorTitleIssueTermRefresh: {
		id: 'issue.views.common.child-issues-panel.flags.link-parent-error-title-issue-term-refresh',
		defaultMessage: "We couldn't link the child issue",
		description:
			'Heading text for error flag that appears when linking child issue to parent fails',
	},
	linkParentErrorDescriptionIssueTermRefresh: {
		id: 'issue.views.common.child-issues-panel.flags.link-parent-error-description-issue-term-refresh',
		defaultMessage:
			'The issue was created but the linking failed. Head over to your board and try again.',
		description: 'Body text for error flag that appears when linking child issue to parent fails',
	},
	reorderErrorTitleIssueTermRefresh: {
		id: 'issue.views.common.child-issues-panel.flags.reorder-error-title-issue-term-refresh',
		defaultMessage: "We couldn't reorder those issues",
		description: 'Heading text for error flag that appears when reordering fails',
	},
});
