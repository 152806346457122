import React from 'react';

import SectionMessage from '@atlaskit/section-message';

import { defineMessages, useIntl } from '@atlassian/jira-intl';

const messages = defineMessages({
	errorTitle: {
		id: 'automation-issue-audit-log-panel.automation-issue-audit-log-body.generic-error.error-title',
		defaultMessage: 'We encountered an unexpected issue',
		description:
			'Error title that shows when something goes wrong in the automation issue audit log panel experience.',
	},
	errorDescription: {
		id: 'automation-issue-audit-log-panel.automation-issue-audit-log-body.generic-error.error-description',
		defaultMessage: 'An unknown error occurred. Please reload and try again.',
		description:
			'Error description that shows when something goes wrong in the automation issue audit log panel experience.',
	},
});

const GenericAutomationIssueAuditLogError: React.FC = () => {
	const { formatMessage } = useIntl();

	return (
		<SectionMessage
			appearance="warning"
			title={formatMessage(messages.errorTitle)}
			testId="automation-issue-audit-log.ui.generic-automation-issue-audit-log-error.generic-error"
		>
			{formatMessage(messages.errorDescription)}
		</SectionMessage>
	);
};

export default GenericAutomationIssueAuditLogError;
