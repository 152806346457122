import get from 'lodash/get';
import { useEditField } from '@atlassian/jira-issue-field-base/src/services/edit-field-service/main.tsx';
import { useFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import type { StringValue, UrlProps } from './types.tsx';

export const useUrlCustomField = ({
	issueKey,
	fieldKey,
	onSuccess,
	onFailure,
	initialValue = null,
}: UrlProps) => {
	const [fieldConfig] = useFieldConfig(issueKey, fieldKey);
	const fieldType = get(fieldConfig.value, 'schema.type', 'string');

	const [{ value, error }, { saveValue, resetError }] = useEditField<StringValue, null>({
		fieldKey,
		issueKey,
		fieldType,
		initialValue,
		onSuccess,
		onFailure,
	});

	return [
		{
			value,
			error,
			fieldConfig,
		},
		{ saveValue, resetError },
	] as const;
};
