/**
 * @generated SignedSource<<5c11e7199f2efae6c37489d9383daccc>>
 * @relayHash 5072d4110dd043586c01b6b73a1b3b46
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID c10f090c2ab68abab7f63e3f786595507d3bc9c6e6f6d794e69d25eacb4ad0b7

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type JiraUpdateTimeTrackingFieldInput = {
  id: string;
  originalEstimate?: JiraEstimateInput | null | undefined;
  remainingEstimate?: JiraEstimateInput | null | undefined;
};
export type JiraEstimateInput = {
  timeInSeconds: AGG$Long;
};
export type ui_issueViewLayoutTimeTrackingAddModal_IssueViewTimeTrackingAddModalMutation$variables = {
  input: JiraUpdateTimeTrackingFieldInput;
};
export type ui_issueViewLayoutTimeTrackingAddModal_IssueViewTimeTrackingAddModalMutation$data = {
  readonly jira: {
    readonly updateTimeTrackingField: {
      readonly field: {
        readonly id: string;
        readonly remainingEstimate: {
          readonly timeInSeconds: AGG$Long | null | undefined;
        } | null | undefined;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type ui_issueViewLayoutTimeTrackingAddModal_IssueViewTimeTrackingAddModalMutation$rawResponse = {
  readonly jira: {
    readonly updateTimeTrackingField: {
      readonly field: {
        readonly id: string;
        readonly remainingEstimate: {
          readonly timeInSeconds: AGG$Long | null | undefined;
        } | null | undefined;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type ui_issueViewLayoutTimeTrackingAddModal_IssueViewTimeTrackingAddModalMutation = {
  rawResponse: ui_issueViewLayoutTimeTrackingAddModal_IssueViewTimeTrackingAddModalMutation$rawResponse;
  response: ui_issueViewLayoutTimeTrackingAddModal_IssueViewTimeTrackingAddModalMutation$data;
  variables: ui_issueViewLayoutTimeTrackingAddModal_IssueViewTimeTrackingAddModalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "concreteType": "JiraMutation",
    "kind": "LinkedField",
    "name": "jira",
    "plural": false,
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "JiraTimeTrackingFieldPayload",
        "kind": "LinkedField",
        "name": "updateTimeTrackingField",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "name": "success"
          },
          {
            "concreteType": "JiraTimeTrackingField",
            "kind": "LinkedField",
            "name": "field",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "id"
              },
              {
                "concreteType": "JiraEstimate",
                "kind": "LinkedField",
                "name": "remainingEstimate",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "timeInSeconds"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "ui_issueViewLayoutTimeTrackingAddModal_IssueViewTimeTrackingAddModalMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ui_issueViewLayoutTimeTrackingAddModal_IssueViewTimeTrackingAddModalMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "c10f090c2ab68abab7f63e3f786595507d3bc9c6e6f6d794e69d25eacb4ad0b7",
    "metadata": {},
    "name": "ui_issueViewLayoutTimeTrackingAddModal_IssueViewTimeTrackingAddModalMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "d5025a1b2d290de9831ef65efcd4567b";

export default node;
