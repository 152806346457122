import differenceInDays from 'date-fns/differenceInDays';

export const isValidTimestamp = (maybeTimestamp: string | null) =>
	maybeTimestamp !== undefined && maybeTimestamp !== null && !Number.isNaN(Number(maybeTimestamp));

export const isUnixTimestampOlderThanDays = (
	possibleTimeStamp: string | null,
	numberOfDays: number,
) => {
	const isTimeStamp = isValidTimestamp(possibleTimeStamp);
	if (!isTimeStamp) return false;
	// @ts-expect-error - TS2345 - Argument of type 'string | false' is not assignable to parameter of type 'string'.
	const timestamp = parseInt(possibleTimeStamp !== null && possibleTimeStamp.toString(), 10);
	if (differenceInDays(new Date(), timestamp) > numberOfDays) {
		return true;
	}
	return false;
};
