/**
 * @generated SignedSource<<169007f7f0e0808b187c74850e4e1732>>
 * @relayHash c107bdc62965b31df5c7d6d353741a4f
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID dae8102c203cb926d69e76db3b036e014467e7cf0acc5e2789f3cabb781da68e

import type { ConcreteRequest, Query } from 'relay-runtime';
export type associatedIssueDataQuery$variables = {
  cloudId: string;
  issueKey: string;
};
export type associatedIssueDataQuery$data = {
  readonly jira: {
    readonly issueByKey: {
      readonly isResolved: boolean | null | undefined;
      readonly issueId: string;
      readonly key: string;
    } | null | undefined;
  };
};
export type associatedIssueDataQuery = {
  response: associatedIssueDataQuery$data;
  variables: associatedIssueDataQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueKey"
},
v2 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "kind": "Variable",
    "name": "key",
    "variableName": "issueKey"
  }
],
v3 = {
  "kind": "ScalarField",
  "name": "issueId"
},
v4 = {
  "kind": "ScalarField",
  "name": "key"
},
v5 = {
  "kind": "ScalarField",
  "name": "isResolved"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "name": "associatedIssueDataQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "concreteType": "JiraQuery",
          "kind": "LinkedField",
          "name": "jira",
          "plural": false,
          "selections": [
            {
              "args": (v2/*: any*/),
              "concreteType": "JiraIssue",
              "kind": "LinkedField",
              "name": "issueByKey",
              "plural": false,
              "selections": [
                (v3/*: any*/),
                (v4/*: any*/),
                (v5/*: any*/)
              ]
            }
          ]
        },
        "action": "THROW",
        "path": "jira"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "associatedIssueDataQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v2/*: any*/),
            "concreteType": "JiraIssue",
            "kind": "LinkedField",
            "name": "issueByKey",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "kind": "ScalarField",
                "name": "id"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "dae8102c203cb926d69e76db3b036e014467e7cf0acc5e2789f3cabb781da68e",
    "metadata": {},
    "name": "associatedIssueDataQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "7c72a81538e7ab5c77fc802667b9df45";

export default node;
