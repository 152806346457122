import { type Ari, createAri, toAri } from '@atlassian/jira-platform-ari/src/index.tsx';
import type { ViewSet } from '@atlassian/jira-polaris-domain-view/src/view-set/types.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { Action, StoreActionApi } from '@atlassian/react-sweet-state';
import { createViewSetAri } from '../../../../common/utils/ari/index.tsx';
import type { State, Props } from '../../types.tsx';
import { logViewError } from '../utils/errors.tsx';
import { updateViewSetState } from '../utils/sections/index.tsx';
import { findViewSet } from '../utils/views/index.tsx';
import { upsertViewSet } from './utils.tsx';

type SaveSectionProps = {
	isReadOnly?: boolean;
	id: string;
	name?: string;
	collapsed?: boolean;
};

type UpdateStateProps = {
	id: string;
	name?: string;
	newId?: Ari | null;
	collapsed?: boolean;
};

export const updateState =
	({ id, newId = null, name, collapsed }: UpdateStateProps) =>
	async ({ getState, setState }: StoreActionApi<State>) => {
		const { viewSets } = updateViewSetState(
			getState().viewSets,
			(viewSet) => viewSet.id === id,
			(viewSet: ViewSet) => {
				const newViewSet = { ...viewSet };
				newId && Object.assign(newViewSet, { id: newId });
				name !== undefined && Object.assign(newViewSet, { name });
				collapsed !== undefined && Object.assign(newViewSet, { collapsed });
				return newViewSet;
			},
		);
		setState({ viewSets });
	};

const viewsetRankList = (viewSet: ViewSet) => {
	const viewRanks = viewSet.views.map((view) => view.rank);
	if (viewSet.viewSets === undefined) {
		return viewRanks;
	}

	return [...viewRanks, ...viewSet.viewSets.map((viewset) => viewset.rank)] as const;
};

const nextRankInViewset = (viewSet: ViewSet) => {
	const rankList = viewsetRankList(viewSet);
	return 1 + rankList.reduce((a, b) => Math.max(a, b), rankList.length);
};

export const createSection =
	() =>
	async ({ getState, setState }: StoreActionApi<State>) => {
		const { viewSets } = getState();
		const prioritizeViewSet = findViewSet(viewSets, (v) => v.type === 'PRIORITIZE');

		if (prioritizeViewSet) {
			const viewSet: ViewSet = {
				name: '',
				id: createAri({
					resourceOwner: 'polaris',
					cloudId: 'tmp',
					resourceType: 'polaris-viewset-tmp',
					resourceId: `${prioritizeViewSet.id + Math.random() * 1000}`,
				}),
				type: 'SECTION',
				collapsed: false,
				rank: nextRankInViewset(prioritizeViewSet),
				views: [],
			};

			const updatedViewSet = viewSets.map((v) => {
				if (v.id === prioritizeViewSet.id) {
					return {
						...v,
						viewSets: [...(prioritizeViewSet.viewSets || []), viewSet],
					};
				}
				return v;
			});

			setState({ viewSets: updatedViewSet });
		}
	};

export const saveSection =
	({ id, name, collapsed, isReadOnly = false }: SaveSectionProps) =>
	async (
		{ getState, dispatch }: StoreActionApi<State>,
		{ viewRemote, onViewUpdateFailed, createAnalyticsEvent }: Props,
	) => {
		const { viewSets } = getState();
		const prioritizeViewSet = findViewSet(viewSets, (v) => v.type === 'PRIORITIZE');

		if (prioritizeViewSet) {
			try {
				if (id.includes('polaris-viewset-tmp') && name !== undefined) {
					dispatch(updateState({ id, name, collapsed }));
					if (!isReadOnly) {
						const { viewSetId } = await viewRemote.createViewSet({
							projectAri: prioritizeViewSet.id,
							name,
						});
						dispatch(updateState({ id, name, collapsed, newId: viewSetId }));

						fireTrackAnalytics(createAnalyticsEvent({}), 'viewSection created', {
							viewSectionId: viewSetId,
						});
					}
				} else {
					dispatch(updateState({ id, name, collapsed }));
					const ari = toAri(id);
					if (ari && !isReadOnly) {
						await viewRemote.updateViewSet({
							name,
							viewSetId: ari,
						});

						fireTrackAnalytics(createAnalyticsEvent({}), 'viewSection updated', {
							updatedItems: [{ name: 'name' }],
							viewSectionId: id,
						});
					}
				}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				logViewError('actions.sections.save', Object.assign(error));
				onViewUpdateFailed(error);
			}
		}
	};

export const deleteSection =
	(id: Ari, updateStateOnly = false) =>
	async (
		{ getState, setState }: StoreActionApi<State>,
		{ viewRemote, onViewUpdateFailed, createAnalyticsEvent }: Props,
	) => {
		const { viewSets } = getState();
		const prioritizeViewSet = findViewSet(viewSets, (v) => v.type === 'PRIORITIZE');

		if (prioritizeViewSet) {
			try {
				const updatedViewSet = viewSets.map((v) => {
					if (v.id === prioritizeViewSet.id) {
						return {
							...v,
							viewSets: (prioritizeViewSet.viewSets || []).filter((vs) => vs.id !== id),
						};
					}
					return v;
				});

				setState({
					viewSets: updatedViewSet,
				});

				if (!id.includes('polaris-viewset-tmp') && !updateStateOnly) {
					await viewRemote.deleteViewSet(id);

					fireTrackAnalytics(createAnalyticsEvent({}), 'viewSection deleted', {
						viewSectionId: id,
					});
				}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				logViewError('actions.sections.save', Object.assign(error));
				onViewUpdateFailed(error);
			}
		}
	};

export const deleteSectionFromState =
	(id: number): Action<State, Props> =>
	({ dispatch }, props) => {
		const viewSetAri = createViewSetAri(props.cloudId, id.toString());

		dispatch(deleteSection(viewSetAri, true));
	};

export const loadSection =
	(viewSetId: number, shouldUpdate = false): Action<State, Props> =>
	async ({ getState, setState }, props) => {
		const state = getState();
		const { viewRemote, onViewUpdateFailed } = props;
		const { viewSets } = state;
		const viewSetAri = createViewSetAri(props.cloudId, viewSetId.toString());

		const existingViewSet = findViewSet(viewSets, ({ id }) => id === viewSetAri);
		if (existingViewSet !== undefined && !shouldUpdate) {
			return;
		}

		try {
			const result = await viewRemote.fetchViewSets();
			const prioritizeViewSet = result.viewsets.find(({ type }) => type === 'PRIORITIZE');
			const newViewSet = prioritizeViewSet?.viewsets.find(({ id }) => viewSetAri === id);
			if (!newViewSet) {
				return;
			}

			const updatedViewSet: ViewSet[] = getState().viewSets.map((rootViewSet) => {
				if (rootViewSet.type !== 'PRIORITIZE') {
					return rootViewSet;
				}

				return {
					...rootViewSet,
					viewSets: upsertViewSet(rootViewSet.viewSets || [], {
						...newViewSet,
						type: 'SECTION',
						collapsed: false,
						views: [],
					}),
				};
			});

			setState({
				viewSets: updatedViewSet,
			});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			onViewUpdateFailed(error);
		}
	};
