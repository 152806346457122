import React from 'react';
import type { Dispatch } from 'redux';
import memoizeOne from 'memoize-one';
import { defineMessages, FormattedMessage } from '@atlassian/jira-intl';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button/index.tsx';
import connectField from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-field.tsx';
import {
	issueKeySelector,
	issueIdSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import {
	fieldEditingValueSelector,
	isFieldEditableSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector.tsx';
import { timeTrackingConfigurationSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/time-tracking-selector.tsx';
import { sweetStateFieldUpdated } from '@atlassian/jira-issue-view-store/src/issue-field/state/actions/field-save-actions.tsx';
import { TIME_TRACKING_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import getXsrfToken from '@atlassian/jira-platform-xsrf-token/src/index.tsx';
import type { Props } from './types.tsx';
import OriginalEstimate from './view.tsx';
// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

const messages = defineMessages({
	placeholder: {
		id: 'issue.time-estimate.placeholder-for-the-field',
		defaultMessage: '2w 4d 6h 45m',
		description: '',
	},
});

const invalidTimeFormatMessage = (
	<FormattedMessage
		id="issue.time-estimate.input-time-string-is-invalid"
		defaultMessage="Your estimate must be in the format {format}."
		values={{
			format: <b>2w 4d 6h 45m</b>,
		}}
	/>
);

const onUpdateFactory = memoizeOne(
	// @ts-expect-error - TS7006 - Parameter 'value' implicitly has an 'any' type.
	(dispatch: Dispatch, fieldId: Props['fieldId']) => (value) =>
		dispatch(sweetStateFieldUpdated(fieldId, value)),
);
export default connectField((stateOnMount, ownPropsOnMount) => ({
	fieldId: ownPropsOnMount.fieldId,
	// This field is not optimistic because updating this field can trigger an update to the
	// Time tracking field (when Time tracking has no remaining time). Using a non-optimistic
	// field forces the app data to refresh so we can fetch the updated Time tracking state.
	isOptimistic: false,
	additionalProps: (state, intl) => ({
		config: timeTrackingConfigurationSelector(state),
		placeholder: intl.formatMessage(messages.placeholder),
		issueKey: issueKeySelector(state),
		issueId: issueIdSelector(state),
		timeTrackingValue: fieldEditingValueSelector(TIME_TRACKING_TYPE)(state),
		isEditable: isFieldEditableSelector(ownPropsOnMount.fieldId)(state) && !!getXsrfToken(),
		showPinButton: getShowPinButton(ownPropsOnMount.area),
		invalidTimeFormatMessage,
	}),
	additionalCallbacks: (dispatch: Dispatch) => ({
		onUpdate: onUpdateFactory(dispatch, ownPropsOnMount.fieldId),
	}),
}))(OriginalEstimate);
