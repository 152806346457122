/**
 * @generated SignedSource<<0bea7bdb1ab04608e0adae775a48a489>>
 * @relayHash 1c8f8121d741811d7b2661309c01658c
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID fbadbe11df646d1843e2f5e6b0c539949776927315464f12d13394d8659babf4

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type people_peopleSearchRefetchQuery$variables = {
  after?: string | null | undefined;
  first?: number | null | undefined;
  id: string;
  searchBy?: string | null | undefined;
};
export type people_peopleSearchRefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"people_issueFieldPeopleEditviewFull_PeopleEditViewWithFieldOptionsFragmentOld_suggestionsFragmentRef">;
};
export type people_peopleSearchRefetchQuery = {
  response: people_peopleSearchRefetchQuery$data;
  variables: people_peopleSearchRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": 50,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": "",
    "kind": "LocalArgument",
    "name": "searchBy"
  }
],
v1 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v2 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v3 = {
  "kind": "Variable",
  "name": "id",
  "variableName": "id"
},
v4 = {
  "kind": "Variable",
  "name": "searchBy",
  "variableName": "searchBy"
},
v5 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v6 = [
  (v1/*: any*/),
  (v2/*: any*/),
  (v4/*: any*/)
],
v7 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "people_peopleSearchRefetchQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "people_issueFieldPeopleEditviewFull_PeopleEditViewWithFieldOptionsFragmentOld_suggestionsFragmentRef"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "people_peopleSearchRefetchQuery",
    "selections": [
      {
        "args": [
          (v3/*: any*/)
        ],
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": (v6/*: any*/),
                "concreteType": "JiraUserConnection",
                "kind": "LinkedField",
                "name": "users",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraUserEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v7/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "accountId"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "name"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "picture"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "accountStatus"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "email"
                              }
                            ],
                            "type": "AtlassianAccountUser"
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "name": "cursor"
                      }
                    ]
                  },
                  {
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "endCursor"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "hasNextPage"
                      }
                    ]
                  }
                ]
              },
              {
                "args": (v6/*: any*/),
                "filters": [
                  "searchBy"
                ],
                "handle": "connection",
                "key": "people_issueFieldPeopleEditviewFull_users",
                "kind": "LinkedHandle",
                "name": "users"
              }
            ],
            "type": "JiraPeopleField"
          },
          (v7/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "id": "fbadbe11df646d1843e2f5e6b0c539949776927315464f12d13394d8659babf4",
    "metadata": {},
    "name": "people_peopleSearchRefetchQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "d213768811d2d06473ca393994f12052";

export default node;
