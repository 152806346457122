import React from 'react';
import { Dropzone, type DropzoneConfig } from '@atlaskit/media-picker';
import withMediaPickerPropsProvider, {
	type EventHandlers,
} from '../with-media-picker-props-provider/index.tsx';

type Props = {
	isEnabled: boolean;
	eventHandlers?: EventHandlers;
	config: Partial<DropzoneConfig>;
	onDragEnter: () => void;
	onDragLeave: () => void;
	onDrop: () => void;
};

const WithMediaPickerDropzone = withMediaPickerPropsProvider(Dropzone);

export const CommonDropzoneMediaPicker = (props: Props) => {
	const { isEnabled, ...otherProps } = props;
	return isEnabled ? <WithMediaPickerDropzone {...otherProps} /> : null;
};
