import React from 'react';
import { ButtonItem } from '@atlaskit/menu';
import useMoveIssue from './hook.tsx';
import type { Props } from './types.tsx';

const MoveIssueButtonItem = ({
	onClick,
	label,
	setDropdownItemRef,
	itemKey,
	isSelected = false,
}: Props) => {
	const handleClick = useMoveIssue(itemKey, onClick);

	return (
		<ButtonItem
			key="move"
			isSelected={isSelected}
			ref={setDropdownItemRef}
			role="menuitem"
			onClick={handleClick}
		>
			{label}
		</ButtonItem>
	);
};

export default MoveIssueButtonItem;
