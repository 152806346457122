import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import {
	ACCESS_ROLE_ERRORS,
	PRINCIPAL_TYPES,
} from '@atlassian/jira-polaris-domain-view/src/view-access/constants.tsx';
import type {
	AccessRole,
	AccessRoleAri,
	PrincipalType,
} from '@atlassian/jira-polaris-domain-view/src/view-access/types.tsx';
import { experience } from '@atlassian/jira-polaris-lib-analytics/src/common/constants/experience/index.tsx';
import { sendPendoTrackEvent } from '@atlassian/jira-polaris-lib-analytics/src/services/pendo/index.tsx';
import { createErrorAnalytics } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import {
	isAccessForbiddenError,
	isNotFoundError,
} from '@atlassian/jira-polaris-lib-errors/src/controllers/utils.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { Action } from '@atlassian/react-sweet-state';
import { applyAddViewAccessPrincipals } from '../../../services/jpd-views-service/index.tsx';
import { isSetViewAccessPrincipalRolesErrorResponse } from '../../../services/jpd-views-service/utils.tsx';
import type { State } from '../../types.tsx';
import { transformRoleToRoleAri } from '../../utils.tsx';
import { loadViewPermissionsConfig } from '../load-view-permissions-config/index.tsx';

const ufoExperienceType = 'addViewAccessPrincipals';

export const addViewAccessPrincipals =
	({
		viewUUID,
		principals,
		role,
		viewId,
		createAnalyticsEvent,
		onActionFailed,
	}: {
		viewUUID: string;
		principals: {
			id: string;
			type: PrincipalType;
			name: string;
			avatarUrl?: string;
		}[];
		role: AccessRole;
		viewId?: string;
		createAnalyticsEvent: CreateUIAnalyticsEvent;
		onActionFailed?: (err: Error) => void;
	}): Action<State> =>
	async ({ setState, getState, dispatch }) => {
		const accessLevel = getState().accessLevel;

		setState({
			config: {
				...getState().config,
				isAddingPrincipals: true,
				failures: undefined,
			},
		});

		const roleAri: AccessRoleAri = transformRoleToRoleAri(role);

		const accounts = principals
			.filter((e) => e.type === PRINCIPAL_TYPES.PROFILE)
			.map((item) => ({
				id: item.id,
				role: roleAri,
			}));

		const groups = principals
			.filter((e) => e.type === PRINCIPAL_TYPES.GROUP)
			.map((item) => ({
				id: item.id,
				role: roleAri,
			}));

		try {
			experience.viewPermissions.updatePrincipals.start();

			await applyAddViewAccessPrincipals(viewUUID, accounts, groups);
			await dispatch(loadViewPermissionsConfig({ viewUUID, viewId, createAnalyticsEvent }));

			const currentState = getState();
			setState({
				config: {
					...currentState.config,
					isAddingPrincipals: false,
					skippedAccountFailures: currentState.config.skippedAccountFailures.filter(
						(skippedAccount) =>
							!principals.some((principal) => principal.id === skippedAccount.accountId),
					),
				},
			});

			// https://data-portal.internal.atlassian.com/analytics/registry/69639
			fireTrackAnalytics(createAnalyticsEvent({}), 'viewAccessData principalAdded', {
				accessLevel,
				viewUuid: viewUUID,
				viewId,
				role,
				newUserCount: accounts.length,
				newGroupCount: groups.length,
			});

			sendPendoTrackEvent({
				actionSubjectAndAction: 'viewAccessData principalAdded',
				actionSubjectId: 'viewAccessData',
				attributes: {
					accessLevel,
					viewUuid: viewUUID,
					viewId: viewId || '',
					role,
					newUserCount: accounts.length,
					newGroupCount: groups.length,
				},
			});

			experience.viewPermissions.updatePrincipals.success({
				metadata: {
					type: ufoExperienceType,
				},
			});

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			await dispatch(loadViewPermissionsConfig({ viewUUID, viewId, createAnalyticsEvent }));

			let errorResponse: unknown = error.originalResponse;

			try {
				if (typeof error.originalResponse?.json === 'function') {
					errorResponse = await error.originalResponse.json();
				}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (subError: any) {
				fireErrorAnalytics(
					createErrorAnalytics(
						'polaris.view-access.add-view-access-principals.parse-error',
						subError,
					),
				);
			}

			if (isSetViewAccessPrincipalRolesErrorResponse(errorResponse)) {
				let hasSkippedAccounts = false;
				let hasFailures = false;

				if (
					errorResponse.details.skippedAccounts &&
					errorResponse.details.skippedAccounts.length > 0
				) {
					hasSkippedAccounts = true;

					// https://data-portal.internal.atlassian.com/analytics/registry/69691
					fireTrackAnalytics(
						createAnalyticsEvent({}),
						'viewAccessData setRoleFailureSkippedAccounts',
						{
							viewUuid: viewUUID,
							viewId,
							missingManageViewsPermissionCount: errorResponse.details.skippedAccounts.filter(
								(item) =>
									item.errorCode === ACCESS_ROLE_ERRORS.MISSING_MANAGE_DISCOVERY_VIEWS_PERMISSION,
							).length,
							missingProjectAccessCount: errorResponse.details.skippedAccounts.filter(
								(item) => item.errorCode === ACCESS_ROLE_ERRORS.MISSING_BROWSE_PROJECTS_PERMISSION,
							).length,
						},
					);

					sendPendoTrackEvent({
						actionSubjectAndAction: 'viewAccessData setRoleFailureSkippedAccounts',
						actionSubjectId: 'viewAccessData',
						attributes: {
							viewUuid: viewUUID,
							viewId: viewId || '',
							missingManageViewsPermissionCount: errorResponse.details.skippedAccounts.filter(
								(item) =>
									item.errorCode === ACCESS_ROLE_ERRORS.MISSING_MANAGE_DISCOVERY_VIEWS_PERMISSION,
							).length,
							missingProjectAccessCount: errorResponse.details.skippedAccounts.filter(
								(item) => item.errorCode === ACCESS_ROLE_ERRORS.MISSING_BROWSE_PROJECTS_PERMISSION,
							).length,
						},
					});

					const skippedAccounts = errorResponse.details.skippedAccounts.map((item) => {
						const foundPrincipal = principals.find((principal) => principal.id === item.accountId);

						return {
							...item,
							requestedRole: role,
							name: foundPrincipal?.name ?? '',
							avatarUrl: foundPrincipal?.avatarUrl,
						};
					});

					const currentState = getState();

					setState({
						config: {
							...currentState.config,
							skippedAccountFailures: [
								...currentState.config.skippedAccountFailures.filter(
									(item) =>
										!skippedAccounts.some(
											(skippedAccount) => skippedAccount.accountId === item.accountId,
										),
								),
								...skippedAccounts,
							],
						},
					});
				}

				if (errorResponse.details.failures) {
					hasFailures = true;

					// https://data-portal.internal.atlassian.com/analytics/registry/69692
					fireTrackAnalytics(createAnalyticsEvent({}), 'viewAccessData setRoleFailure', {
						viewUuid: viewUUID,
						viewId,
						failuresCount:
							errorResponse.details.failures.accountIds.length +
							errorResponse.details.failures.groupIds.length,
					});

					sendPendoTrackEvent({
						actionSubjectAndAction: 'viewAccessData setRoleFailure',
						actionSubjectId: 'viewAccessData',
						attributes: {
							viewUuid: viewUUID,
							viewId: viewId || '',
							failuresCount:
								errorResponse.details.failures.accountIds.length +
								errorResponse.details.failures.groupIds.length,
						},
					});

					setState({
						config: {
							...getState().config,
							failures: {
								role,
								principals: [
									...errorResponse.details.failures.accountIds.map((id) => ({
										id,
										name: principals.find((principal) => principal.id === id)?.name ?? '',
										type: PRINCIPAL_TYPES.PROFILE,
									})),
									...errorResponse.details.failures.groupIds.map((id) => ({
										id,
										name: principals.find((principal) => principal.id === id)?.name ?? '',
										type: PRINCIPAL_TYPES.GROUP,
									})),
								],
							},
						},
					});
				}

				if (hasSkippedAccounts || hasFailures) {
					experience.viewPermissions.updatePrincipals.successWithReason({
						metadata: {
							error: `UpdatePrincipals: ${error.message}`,
							type: ufoExperienceType,
						},
					});
				} else {
					experience.viewPermissions.updatePrincipals.failure({
						metadata: {
							error: `UpdatePrincipals: ${error.message}`,
							type: ufoExperienceType,
						},
					});
				}

				setState({
					config: {
						...getState().config,
						isAddingPrincipals: false,
					},
				});
			} else {
				if (isClientFetchError(error) || isAccessForbiddenError(error) || isNotFoundError(error)) {
					experience.viewPermissions.updatePrincipals.abort({
						metadata: {
							error: `UpdatePrincipals: ${error.message}`,
							type: ufoExperienceType,
						},
					});
				} else {
					experience.viewPermissions.updatePrincipals.failure({
						metadata: {
							error: `UpdatePrincipals: ${error.message}`,
							type: ufoExperienceType,
						},
					});
				}

				fireErrorAnalytics(
					createErrorAnalytics('polaris.view-access.add-view-access-principals', error),
				);

				setState({
					config: {
						...getState().config,
						isAddingPrincipals: false,
					},
				});

				onActionFailed?.(error);
			}
		}
	};
