import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import type { ActivitySortOrderType as LegacyGiraActivitySortOrder } from '@atlassian/jira-issue-shared-types/src/common/types/activity-sort-order-type.tsx';
import type { AggResponseData } from '../types/issue-type.tsx';

/**
 * Logic is based on:
 * https://stash.atlassian.com/projects/jiracloud/repos/jira/browse/jira-components/jira-plugins/jira-graphql/issue-view-data-fetchers/core/src/main/java/com/atlassian/jira/graphql/gira/userpreferences/JiraIssueViewActivityFeedSortOrderDataFetcher.java
 */
export const transformAggUserPreferencesToLegacyGiraActivityFeedSortOrder = (
	aggResponse: AggResponseData,
): LegacyGiraActivitySortOrder => {
	const aggValue = aggResponse.jira.userPreferences?.issueViewActivityFeedSortOrder;

	switch (aggValue) {
		case 'NEWEST_FIRST':
		case 'OLDEST_FIRST':
			return aggValue;
		default:
			log.safeErrorWithoutCustomerData(
				'issue.agg-field-transformers.activity-sort-order',
				`Unsupported activity sort order: ${aggValue}`,
			);
			return 'NEWEST_FIRST';
	}
};
