/**
 * @generated SignedSource<<9a3b3aa2f382336a7b82c73dfa4c8486>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type assignToMeInlineEdit_issueViewLayoutAssigneeField_AssignToMeInlineEditInner_fragmentKey$data = {
  readonly fieldId: string;
  readonly id: string;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"assignToMe_issueFieldAssigneeAssignToMe_fragmentRef">;
  readonly " $fragmentType": "assignToMeInlineEdit_issueViewLayoutAssigneeField_AssignToMeInlineEditInner_fragmentKey";
};
export type assignToMeInlineEdit_issueViewLayoutAssigneeField_AssignToMeInlineEditInner_fragmentKey$key = {
  readonly " $data"?: assignToMeInlineEdit_issueViewLayoutAssigneeField_AssignToMeInlineEditInner_fragmentKey$data;
  readonly " $fragmentSpreads": FragmentRefs<"assignToMeInlineEdit_issueViewLayoutAssigneeField_AssignToMeInlineEditInner_fragmentKey">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "assignToMeInlineEdit_issueViewLayoutAssigneeField_AssignToMeInlineEditInner_fragmentKey",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "FragmentSpread",
      "name": "assignToMe_issueFieldAssigneeAssignToMe_fragmentRef"
    }
  ],
  "type": "JiraSingleSelectUserPickerField"
};

(node as any).hash = "e24bace2befcf62d1f03a65bd4286110";

export default node;
