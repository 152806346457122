import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	oneConfigError: {
		id: 'development-summary-development-field.summary.error-tag.config-errors.one-config-error',
		defaultMessage: "Couldn't read data from {instanceOne}.",
		description:
			'Message displayed to the user when a single error occurred when reading data from their service.',
	},
	twoConfigError: {
		id: 'development-summary-development-field.summary.error-tag.config-errors.two-config-error',
		defaultMessage: "Couldn't read data from {instanceOne} and {instanceTwo}.",
		description:
			'Message displayed to the user when three errors occur when reading data from their service.',
	},
	threeConfigError: {
		id: 'development-summary-development-field.summary.error-tag.config-errors.three-config-error',
		defaultMessage: "Couldn't read data from {instanceOne}, {instanceTwo} and {instanceThree}.",
		description:
			'Message displayed to the user when two errors occur when reading data from their service.',
	},
	moreConfigError: {
		id: 'development-summary-development-field.summary.error-tag.config-errors.more-config-error',
		defaultMessage:
			"Couldn't read data from {instanceOne}, {instanceTwo}, {instanceThree} and {count} other {count, plural, one {application} other {applications}}.",
		description:
			'Message displayed to the user when more than three errors occurred when reading data from their service.',
	},
	configErrorAndLearnMore: {
		id: 'development-summary-development-field.summary.error-tag.config-errors.config-error-and-learn-more',
		defaultMessage: 'Learn <h>about this issue</h> or go to <a>application links</a> to fix it.',
		description:
			'Help message shown when a users sees error messages. Links out to external pages. ',
	},
	configTitle: {
		id: 'development-summary-development-field.summary.error-tag.config-errors.config-title',
		defaultMessage: 'Configuration problem',
		description:
			'Header of a dialog that lets the user know a configuration problem has occurred with their service',
	},
	configErrorAndLearnMoreIssueTermRefresh: {
		id: 'development-summary-development-field.summary.error-tag.config-errors.config-error-and-learn-more-issue-term-refresh',
		defaultMessage: 'Learn <h>about this issue</h> or go to <a>application links</a> to fix it.',
		description:
			'Help message shown when a users sees error messages. Links out to external pages. ',
	},
});
