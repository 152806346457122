import every from 'lodash/every';
import get from 'lodash/fp/get';
import unionBy from 'lodash/unionBy';
import teamAvatar from '@atlassian/jira-illustrations/src/ui/adg4/jira/spots/other/assets/team.svg';
import {
	type Approval,
	type ApproverDTO,
	type ApproverFieldValue,
	type ApproverPrincipals,
	type GroupApproverFieldValue,
	type GroupApproverPrincipal,
	type ServerApproval,
	type ServerApprover,
	type ServerApproverDTO,
	type UserApproverPrincipal,
	APPROVER_PENDING as PENDING,
} from '@atlassian/jira-issue-shared-types/src/common/types/approval-type.tsx';
import { USER_CF_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { toArray } from '@atlassian/jira-servicedesk-approval-panel/src/common/utils.tsx';

const GROUP_FIELD_TYPE = 'multi_group_picker';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isVoid = (x: any): boolean => x === undefined;
const getOr = (
	dflt: string,
	path: string,
	obj:
		| undefined
		| {
				['16x16']?: string;
				['24x24']: string;
				['32x32']?: string;
				['48x48']?: string;
		  },
) => (isVoid(get(path, obj)) ? dflt : get(path, obj));

// intersectionBy() and differenceBy() are provided by lodash, but lodash loses the types. These
// versions preserve the type of the first array provided.
const intersectionBy = <A, B, C>(
	f: (arg1: A | B) => C,
	as: ReadonlyArray<A>,
	bs: ReadonlyArray<B>,
): ReadonlyArray<A> => as.filter((a) => bs.some((b) => f(a) === f(b)));
const differenceBy = <A, B, C>(
	f: (arg1: A | B) => C,
	xs: ReadonlyArray<A>,
	ys: ReadonlyArray<B>,
): ReadonlyArray<A> => xs.filter((x) => !ys.some((y) => f(x) === f(y)));

export const asGroupApproverFieldValues = (
	approvers?:
		| null
		| ApproverFieldValue
		| ReadonlyArray<ApproverFieldValue>
		| ReadonlyArray<GroupApproverFieldValue>,
): GroupApproverFieldValue[] => {
	if (!Array.isArray(approvers)) return [];
	return approvers.flatMap((approver) =>
		typeof approver.name === 'string' && typeof approver.accountId === 'undefined'
			? [{ name: approver.name }]
			: [],
	);
};

const asApproverFieldValue = (
	approver: ApproverFieldValue | GroupApproverFieldValue,
): ApproverFieldValue | null => {
	// @ts-expect-error - TS2339 - Property 'accountId' does not exist on type 'ApproverFieldValue | GroupApproverFieldValue'. | TS2339 - Property 'displayName' does not exist on type 'ApproverFieldValue | GroupApproverFieldValue'.
	if (typeof approver.accountId === 'undefined' || typeof approver.displayName === 'undefined') {
		return null;
	}
	// @ts-expect-error - TS2339 - Property 'accountId' does not exist on type 'ApproverFieldValue | GroupApproverFieldValue'. | TS2339 - Property 'displayName' does not exist on type 'ApproverFieldValue | GroupApproverFieldValue'.
	const { accountId, displayName } = approver;
	return { ...approver, accountId, displayName };
};

export const asApproverFieldValues = (
	approvers?:
		| null
		| ApproverFieldValue
		| ReadonlyArray<ApproverFieldValue>
		| ReadonlyArray<GroupApproverFieldValue>,
): ApproverFieldValue[] => {
	if (approvers == null) return [];
	// @ts-expect-error - TS2322 - Type 'ApproverFieldValue | readonly ApproverFieldValue[] | readonly GroupApproverFieldValue[]' is not assignable to type 'ApproverFieldValue'.
	if (!Array.isArray(approvers)) return [approvers];
	return approvers.map(asApproverFieldValue).filter(Boolean);
};

const asGroupApprovers = (
	approvers?: ApproverPrincipals[] | ReadonlyArray<GroupApproverPrincipal>,
): ReadonlyArray<GroupApproverPrincipal> =>
	toArray<UserApproverPrincipal | GroupApproverPrincipal>(approvers).flatMap((x) =>
		x.type === 'group' ? [x] : [],
	);

const transformApproverFromUserPickerValue: (arg1: ApproverFieldValue) => ApproverDTO = ({
	key,
	accountId,
	displayName,
	avatarUrls,
}) => ({
	approver: {
		accountId,
		key,
		displayName,
		isFromLinkedField: true,
		avatarUrl: getOr(teamAvatar, '24x24', avatarUrls),
	},
});

const transformApproverFromApproval: (arg1: ServerApproverDTO) => ApproverDTO = ({
	approver: { key, accountId, displayName, avatarUrl },
	approverDecision,
}) => ({
	approver: {
		accountId,
		key,
		displayName,
		avatarUrl,
	},
	approverDecision,
});

const markLinkedFieldApprovers = (
	principalsFromLinkedField: ReadonlyArray<ApproverDTO>,
	mergedApprovers: ReadonlyArray<ApproverDTO>,
): ReadonlyArray<ApproverDTO> => {
	const linkedFieldApproverIds = principalsFromLinkedField.map(
		(approver) => approver.approver.accountId,
	);
	return mergedApprovers.map((approver) => {
		if (linkedFieldApproverIds.includes(approver.approver.accountId)) {
			return {
				approverDecision: approver.approverDecision,
				approver: { ...approver.approver, isFromLinkedField: true },
			};
		}
		return approver;
	});
};

const mergeApprovers = (
	approversFromPendingApproval: ReadonlyArray<ApproverDTO>,
	principalsFromLinkedField: ReadonlyArray<ApproverDTO>,
	excludedApprovers: ReadonlyArray<ServerApprover> = [],
) => {
	const hasAccountId = every(principalsFromLinkedField, (a) => a.approver.accountId);
	const mergedApprovers = unionBy(
		approversFromPendingApproval,
		principalsFromLinkedField,
		// When update value of a user picker field, Bento only put username into the state, so if accountId is not present,
		// we use username to identify an user when merge
		(approverWithDecision) => (hasAccountId ? approverWithDecision.approver.accountId : null),
	);

	const mergedApproversSansExclusions = mergedApprovers.filter(
		(a) => !excludedApprovers.some((e) => e.accountId === a.approver.accountId),
	);

	// preserving the isLinkedField if present in the linked Field
	return markLinkedFieldApprovers(principalsFromLinkedField, mergedApproversSansExclusions);
};

const getApprovalWithMergedApproversFromUserSelector = (
	approval: ServerApproval,
	principalsFromLinkedField: ApproverFieldValue | ApproverFieldValue[] | null,
	linkedFieldName: string,
	linkedFieldType: string,
): Approval => {
	// Filter out the pending approvers from approval because they will be in linked user picker field if agents want
	// to keep them, otherwise that means they are removed. We keep the approvers returned from graphql when
	// approverDecision is approved/declined because when an approver has already approved/declined, remove him/her from
	// the linked user picker field won't remove the approval decision.
	const approversFromApproval = principalsFromLinkedField
		? approval.approvers.filter((a) => a.approverDecision !== PENDING)
		: approval.approvers;
	const transformedApproversFromApproval = approversFromApproval.map(transformApproverFromApproval);
	const principals =
		principalsFromLinkedField && !Array.isArray(principalsFromLinkedField)
			? [principalsFromLinkedField]
			: principalsFromLinkedField;
	const transformedApproversFromLinkedUserPickerFieldValue = (principals ?? []).map(
		transformApproverFromUserPickerValue,
	);
	const mergedApprovers = mergeApprovers(
		transformedApproversFromApproval,
		transformedApproversFromLinkedUserPickerFieldValue,
		approval.excludedApprovers,
	);

	const isSingleUserPicker = linkedFieldType === USER_CF_TYPE;
	const {
		id,
		name,
		finalDecision,
		canAnswerApproval,
		decisions,
		approverPrincipals,
		excludedApprovers,
		approvalState,
		approvedStatus,
	} = approval;
	return {
		id,
		name,
		finalDecision,
		approvers: mergedApprovers,
		canAnswerApproval,
		linkedFieldName,
		isSingleUserPicker,
		decisions: (decisions || []).map(transformApproverFromApproval),
		approverPrincipals,
		configuration: approval.configuration,
		pendingApprovalCount: approval.pendingApprovalCount,
		excludedApprovers,
		approvalState,
		approvedStatus,
	};
};

const fieldValToGroupApprover: (arg1: GroupApproverFieldValue) => GroupApproverPrincipal = ({
	name,
}) => ({
	name,
	type: 'group',
	meta: { memberCount: NaN, approvedCount: NaN },
	id: undefined,
});

// Merge Group Approvers.
//
// We need this function because of the way updates to the global state work. When we update
// approvers using the approval-glance-panel, only the linked field is updated in state. The
// approverGroups field is left untouched with stale data. To get additional information about
// number of members, etc. we have to send a refresh request (this happens elsewhere). In the
// meantime we don't want to leave users hanging, so we make do as best we can with the values from
// the linked field.
const mergeGroupApprovers = (
	approverPrincipals: ReadonlyArray<GroupApproverPrincipal>,
	groupsFromLinkedField: ReadonlyArray<GroupApproverFieldValue>,
): ReadonlyArray<GroupApproverPrincipal> => {
	const approverGroups = approverPrincipals || [];
	// First we want to exclude any approver principals that ARE NOT in the linked field. This is
	// because our user may have deleted approver groups.
	// Next we want to add in any principals from the linked field that ARE NOT already in
	// the approval principals array. This is because our user may have added new approver groups.
	return [
		...intersectionBy(get('name'), approverGroups, groupsFromLinkedField),
		...differenceBy(get('name'), groupsFromLinkedField, approverGroups).map(
			fieldValToGroupApprover,
		),
	];
};

const getApprovalWithMergedApproversFromGroupSelector = (
	{
		id,
		name,
		finalDecision,
		approvers,
		configuration,
		canAnswerApproval,
		decisions,
		approverPrincipals,
		pendingApprovalCount,
		excludedApprovers,
		approvalState,
		approvedStatus,
	}: ServerApproval,
	principalsFromLinkedField: ReadonlyArray<GroupApproverFieldValue>,
	linkedFieldName: string,
): Approval => ({
	id,
	name,
	finalDecision,
	approvers: approvers.map(transformApproverFromApproval),
	canAnswerApproval,
	linkedFieldName,
	isSingleUserPicker: false,
	decisions: (decisions || []).map(transformApproverFromApproval),
	approverPrincipals: mergeGroupApprovers(
		asGroupApprovers(approverPrincipals),
		principalsFromLinkedField,
	),
	pendingApprovalCount,
	configuration,
	excludedApprovers,
	approvalState,
	approvedStatus,
});

export const getApprovalWithMergeApprovers = (
	approval: ServerApproval,
	principalsFromLinkedField:
		| null
		| ApproverFieldValue
		| ReadonlyArray<ApproverFieldValue>
		| ReadonlyArray<GroupApproverFieldValue>,
	linkedFieldName: string,
	linkedFieldType: string,
): Approval =>
	approval.configuration.approvers.type !== GROUP_FIELD_TYPE
		? getApprovalWithMergedApproversFromUserSelector(
				approval,
				asApproverFieldValues(principalsFromLinkedField),
				linkedFieldName,
				linkedFieldType,
			)
		: getApprovalWithMergedApproversFromGroupSelector(
				approval,
				asGroupApproverFieldValues(principalsFromLinkedField),
				linkedFieldName,
			);
