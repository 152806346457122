import { useCallback } from 'react';
import { graphql, useMutation } from 'react-relay';
import { ValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import type { useSaveAffectedServicesField_AffectedServicesMutation as AffectedServicesMutation } from '@atlassian/jira-relay/src/__generated__/useSaveAffectedServicesField_AffectedServicesMutation.graphql';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { JiraIssuefieldvalueAri } from '@atlassian/ari/jira/issuefieldvalue';
import type { ServiceEntity } from '../../../common/types.tsx';
import type { SaveField } from './types.tsx';

export const useSaveAffectedServicesField = (issueId: string): SaveField => {
	const cloudId = useCloudId();

	const [commit] = useMutation<AffectedServicesMutation>(graphql`
		mutation useSaveAffectedServicesField_AffectedServicesMutation(
			$input: JiraUpdateAffectedServicesFieldInput!
		) @raw_response_type {
			jira @optIn(to: ["JiraIssueFieldMutations"]) {
				updateAffectedServicesField(input: $input) {
					success
					errors {
						message
					}
					field {
						id
					}
				}
			}
		}
	`);

	return useCallback(
		async (_issueKey: IssueKey, fieldKey: string, value: ServiceEntity[]) =>
			new Promise((resolve, reject) => {
				commit({
					variables: {
						input: {
							id: JiraIssuefieldvalueAri.create({
								siteId: cloudId,
								issueId,
								fieldId: fieldKey,
							}).toString(),
							operation: {
								operation: 'SET',
								ids: value.map((service) => service.id) || [],
							},
						},
					},
					onCompleted(mutationData) {
						if (mutationData.jira?.updateAffectedServicesField) {
							const { success, errors: responseErrors } =
								mutationData.jira?.updateAffectedServicesField;
							if (success) {
								resolve(null);
								return;
							}
							if (responseErrors?.length) {
								reject(
									responseErrors[0].message
										? new ValidationError(responseErrors[0].message)
										: new Error('Unknown error when mutating affected services'),
								);
							}
						} else {
							reject(new Error('Unknown error when mutating affected services'));
						}
					},
					onError(error: Error) {
						reject(error);
					},
				});
			}),
		[cloudId, commit, issueId],
	);
};
