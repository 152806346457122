import {
	PolarisEnvironmentContainerTypes,
	type PolarisEnvironmentContainer,
} from '@atlassian/jira-polaris-component-environment-container/src/controllers/types.tsx';
import type { DateFieldConfiguration } from '@atlassian/jira-polaris-remote-issue/src/services/jira/types.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge/src/utils/fire-analytics';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import {
	getDeliveryCalculationMode,
	getDeliveryCalculationStrategy,
} from '../../selectors/fields.tsx';
import { getLocalIssueIdsByJiraIssueId } from '../../selectors/issue-ids.tsx';
import {
	getSelectedIssueLocalIssueId,
	getSelectedIssueJiraId,
} from '../../selectors/properties/index.tsx';
import { createGetProject } from '../../selectors/properties/project/index.tsx';
import type { Props, State } from '../../types.tsx';
import { fireErrorAnalytics } from './fire-error-analytics/index.tsx';
import { getFieldConfigurations } from './get-field-configurations/index.tsx';
import { loadAggregatedDeliveryProgress } from './load-aggregated-delivery-progress/index.tsx';
import type { DeliveryAnalyticsAttributes } from './types.tsx';

export const loadDeliveryProgress =
	(
		providedDeliveryStrategy?: 'default' | 'simple',
		calculationMode?: 'issue_count' | 'story_points',
		issueIds?: IssueId[],
		archivedOnly?: boolean,
	) =>
	({ dispatch }: StoreActionApi<State>) => {
		dispatch(
			loadAggregatedDeliveryProgress(
				true,
				providedDeliveryStrategy,
				calculationMode,
				issueIds,
				archivedOnly,
			),
		);
	};

export const refreshDeliveryProgressForSelectedIssue =
	() =>
	({ dispatch, getState }: StoreActionApi<State>, props: Props) => {
		const { projectId, isSharedView } = props;

		if (isSharedView) {
			return;
		}

		const selectedIssueLocalIssueId = getSelectedIssueLocalIssueId(getState());

		if (!selectedIssueLocalIssueId) {
			return;
		}

		const jiraId = getSelectedIssueJiraId(getState());
		if (jiraId === undefined) {
			return;
		}

		if (projectId === undefined) {
			return;
		}

		dispatch(refreshDeliveryProgressForSpecificIssue(String(jiraId)));
	};

export const refreshDeliveryProgressForSpecificIssue =
	(issueId: IssueId, container?: PolarisEnvironmentContainer) =>
	({ getState, setState }: StoreActionApi<State>, props: Props) => {
		const { fields, projectId, isSharedView } = props;

		if (isSharedView) {
			return;
		}

		if (issueId === undefined) {
			return;
		}

		const localIssueId = getLocalIssueIdsByJiraIssueId(getState(), props)[issueId];
		if (!localIssueId) {
			return;
		}

		// if container is a collection, we have the project to issue id mapping in state and have to
		// find the correct project id from there for the issue in question; note: we should make the
		// project id mapping available in all container cases (where it is known), so we can simplify
		// actions like this one and avoid container type code branches
		const safeProjectId =
			container?.type === PolarisEnvironmentContainerTypes.COLLECTION
				? createGetProject(localIssueId)(getState())?.id
				: projectId;

		if (safeProjectId === undefined) {
			return;
		}

		const calculationMode = getDeliveryCalculationMode(getState(), props);
		const calculationStrategy = getDeliveryCalculationStrategy(getState(), props);
		const analyticsAttributes: DeliveryAnalyticsAttributes = {
			deliveryDatesCount: 0,
			deliveryDatesPresent: false,
			calculationMode,
		};

		const dateFieldConfigurations: DateFieldConfiguration[] = getFieldConfigurations(fields);
		if (dateFieldConfigurations.length > 0) {
			analyticsAttributes.deliveryDatesCount = dateFieldConfigurations.length;
			analyticsAttributes.deliveryDatesPresent = true;
		}

		const fetchAggregatedStartTimestamp = Date.now();
		props.issuesRemote
			.fetchAggregatedDeliveryProgress({
				projectId: safeProjectId,
				issueIds: [issueId],
				calculationStrategy,
				calculationMode,
				dateFieldConfigurations,
			})
			.then(({ ideaToProgressAggregation, ideaDateAggregation }) => {
				const aggregatedDeliveryDates = {
					...getState().properties.aggregatedDeliveryDates,
				};

				if (ideaDateAggregation && ideaDateAggregation[issueId]) {
					aggregatedDeliveryDates[localIssueId] = ideaDateAggregation[issueId];
				}

				setState({
					properties: {
						...getState().properties,
						aggregatedDeliveryProgress: {
							...getState().properties.aggregatedDeliveryProgress,
							[localIssueId]: ideaToProgressAggregation[issueId],
						},
						aggregatedDeliveryDates,
					},
				});
			})
			.catch((error) => {
				fireErrorAnalytics(props.createAnalyticsEvent, fetchAggregatedStartTimestamp);
				props.onDeliveryDataFetchFailed(error);
			});

		fireTrackAnalytics(props.createAnalyticsEvent({}), 'deliveryData loaded', analyticsAttributes);
	};

export const initDeliveryProgress =
	() =>
	({ dispatch }: StoreActionApi<State>, props: Props) => {
		if (props.singleIdeaVisible) {
			dispatch(refreshDeliveryProgressForSelectedIssue());
		} else {
			dispatch(loadAggregatedDeliveryProgress());
		}
	};
