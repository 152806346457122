import React, { forwardRef, type MouseEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import Icon from '@atlaskit/icon/base';
import type { CustomGlyphProps, GlyphProps } from '@atlaskit/icon/types';
import Tooltip from '@atlaskit/tooltip';
import type { DropdownTriggerParams } from '@atlassian/jira-development-summary-common/src/common/types.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';

const TerminalGlyph = (props: CustomGlyphProps) => (
	<svg width="24" height="24" viewBox="0 0 24 24" {...props}>
		<path
			fill="currentColor"
			d="M11.236 12C11.236 11.6675 11.104 11.3487 10.8689 11.1135L5.1101 5.35474C4.87652 5.12584 4.56205 4.99836 4.23501 5.00002C3.90798 5.00167 3.59481 5.13231 3.36356 5.36356C3.13231 5.59481 3.00167 5.90798 3.00002 6.23501C2.99836 6.56205 3.12584 6.87652 3.35474 7.11009L8.24465 12L3.35474 16.8899C3.12584 17.1234 2.99836 17.438 3.00002 17.765C3.00167 18.0921 3.13231 18.4052 3.36356 18.6365C3.59481 18.8677 3.90798 18.9983 4.23501 19C4.56205 19.0017 4.87652 18.8742 5.1101 18.6452L10.8689 12.8865C11.104 12.6513 11.236 12.3325 11.236 12ZM13.0305 16.2844C12.3381 16.2844 11.7768 16.8458 11.7768 17.5382C11.7768 18.2307 12.3381 18.7921 13.0305 18.7921H20.5535C21.246 18.7921 21.8073 18.2307 21.8073 17.5382C21.8073 16.8458 21.246 16.2844 20.5535 16.2844H13.0305Z"
		/>
	</svg>
);

const TerminalIcon = (props: GlyphProps) => <Icon glyph={TerminalGlyph} {...props} />;

export type Props = Partial<Omit<DropdownTriggerParams, 'ref'>>;

export const CreateBranchCommandButton = forwardRef<HTMLElement, Props>(
	({ onClick: onClickProp, ...props }, ref) => {
		const { formatMessage } = useIntl();

		const tooltipContent = formatMessage(messages.tooltipContent);
		const onClick = (e: MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
			onClickProp && onClickProp();
			fireUIAnalytics(analyticsEvent, 'createBranchCommandButton');
		};

		return (
			<Tooltip content={tooltipContent} hideTooltipOnClick>
				<Button
					ref={ref}
					{...props}
					iconBefore={<TerminalIcon label={tooltipContent} size="small" />}
					onClick={onClick}
					testId="development-summary-branch.ui.create-branch-command-button.create-branch-command-button"
				/>
			</Tooltip>
		);
	},
);
