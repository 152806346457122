import type { DraftsUiState } from '@atlassian/jira-issue-view-common-types/src/drafts-type.tsx';
import { isOnlyWhitespaceAdf } from '@atlassian/jira-rich-content/src/common/adf-parsing-utils.tsx';
import {
	type SaveDraftRequest,
	type LoadDraftSuccess,
	type DeleteDraftSuccess,
	DELETE_DRAFT_SUCCESS,
	LOAD_DRAFT_SUCCESS,
	SAVE_DRAFT_REQUEST,
} from '../../drafts/draft-actions.tsx';

const initialState: DraftsUiState = {};

type Action = SaveDraftRequest | LoadDraftSuccess | DeleteDraftSuccess;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: DraftsUiState = initialState, action: Action) => {
	switch (action.type) {
		case LOAD_DRAFT_SUCCESS:
			return {
				...state,
				[action.payload.fieldId]: true,
			};
		case SAVE_DRAFT_REQUEST:
			return {
				...state,
				[action.payload.fieldId]: !isOnlyWhitespaceAdf(action.payload.value),
			};
		case DELETE_DRAFT_SUCCESS:
			return {
				...state,
				[action.payload.fieldId]: false,
			};
		default:
			return state;
	}
};
