/**
 * @generated SignedSource<<978622ce221fde7d0ebc62e1557c9c0d>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type number_issueFieldNumberInlineEditFull_NumberInlineEditView$data = {
  readonly fieldConfig: {
    readonly isEditable: boolean | null | undefined;
  } | null | undefined;
  readonly id: string;
  readonly name: string;
  readonly number: number | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"number_issueFieldNumberReadviewFull_NumberReadView">;
  readonly " $fragmentType": "number_issueFieldNumberInlineEditFull_NumberInlineEditView";
};
export type number_issueFieldNumberInlineEditFull_NumberInlineEditView$key = {
  readonly " $data"?: number_issueFieldNumberInlineEditFull_NumberInlineEditView$data;
  readonly " $fragmentSpreads": FragmentRefs<"number_issueFieldNumberInlineEditFull_NumberInlineEditView">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "number_issueFieldNumberInlineEditFull_NumberInlineEditView",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "number_issueFieldNumberReadviewFull_NumberReadView"
    },
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "kind": "ScalarField",
      "name": "number"
    },
    {
      "concreteType": "JiraFieldConfig",
      "kind": "LinkedField",
      "name": "fieldConfig",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isEditable"
        }
      ]
    }
  ],
  "type": "JiraNumberField"
};

(node as any).hash = "c9ae573efc6753c8602beb34d739f609";

export default node;
