import { useState } from 'react';
import partition from 'lodash/partition';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useFieldsValues } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import type { LayoutContainerTemplateItem } from '@atlassian/jira-issue-layout-common-constants/src/index.tsx';
import { isEmpty } from '@atlassian/jira-issue-view-layout-templates-utils/src/index.tsx';
import { getLayoutItemId } from '@atlassian/jira-issue-view-layout/src/services/utils.tsx';
import { usePrimarySecondaryItems, type Layout } from '../primary-secondary-items/index.tsx';

const EMPTY_ARRAY: Array<never> = [];

export type LayoutItems = {
	pinnedFields: Layout['pinnedFields'];
	visibleItems: LayoutContainerTemplateItem[];
	hiddenItems: LayoutContainerTemplateItem[];
	apps: Layout['apps'];
	hasSecondaryItems?: boolean;
};

export const useVisibleHiddenItems = (
	alwaysPrimaryFieldIds: string[] = [],
): [LayoutItems, () => void] => {
	const items = usePrimarySecondaryItems(alwaysPrimaryFieldIds);

	const { pinnedFields, primaryItems, secondaryItems, forgeItems, ecosystemItems, apps } = items;

	const issueKey = useIssueKey();
	const totalItems = [...pinnedFields, ...primaryItems, ...secondaryItems];
	const [issueFieldsValues] = useFieldsValues(issueKey);

	const [
		initiallyHiddenSecondaryFields = EMPTY_ARRAY,
		initiallyVisibleSecondaryFields = EMPTY_ARRAY,
	] = partition(secondaryItems, (item) => isEmpty(issueFieldsValues?.[getLayoutItemId(item)]));

	const [hiddenSecondaryFields, setHiddenSecondaryFields] = useState(
		initiallyHiddenSecondaryFields,
	);
	const [visibleSecondaryFields, setVisibleSecondaryFields] = useState(
		initiallyVisibleSecondaryFields,
	);
	const [pinnedFieldsLength, setPinnedFieldsLength] = useState(pinnedFields.length);
	const [totalItemsLength, setTotalItemsLength] = useState(totalItems.length);

	const updateHiddenSecondaryFields = () => {
		const hiddenItemsArray = secondaryItems.filter((item) =>
			isEmpty(issueFieldsValues?.[getLayoutItemId(item)]),
		);

		const visibleSecondaryItems = secondaryItems.filter(
			(item) => !isEmpty(issueFieldsValues?.[getLayoutItemId(item)]),
		);

		setHiddenSecondaryFields(hiddenItemsArray);
		setVisibleSecondaryFields(visibleSecondaryItems);
	};

	const hasPinnedFieldsChanged = pinnedFieldsLength !== pinnedFields.length;
	if (hasPinnedFieldsChanged) {
		setPinnedFieldsLength(pinnedFields.length);
		updateHiddenSecondaryFields();
	}

	const hasItemsLengthChanged = totalItemsLength !== totalItems.length;
	if (hasItemsLengthChanged) {
		setTotalItemsLength(totalItems.length);
		updateHiddenSecondaryFields();
	}

	const layout = {
		// Pinned fields at the top
		pinnedFields,
		// Details
		visibleItems: [...primaryItems, ...visibleSecondaryFields, ...forgeItems, ...ecosystemItems],
		// Fields with no values set get hidden in a separate panel at the bottom
		hiddenItems: hiddenSecondaryFields,
		apps,
		hasSecondaryItems: secondaryItems?.length > 0,
	};

	return [layout, updateHiddenSecondaryFields];
};
