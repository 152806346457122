import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fireOperationalAnalyticsDeferred } from '@atlassian/jira-product-analytics-bridge';
import type { Action } from '@atlassian/react-sweet-state';
import type { State } from '../../types.tsx';
import { loadTrackingMetadata } from '../load-tracking-metadata/index.tsx';

export const setIsSingleIssueLoaded =
	(createAnalyticsEvent: CreateUIAnalyticsEvent): Action<State> =>
	({ getState, setState, dispatch }) => {
		if (getState().meta.isSingleIssueLoaded || getState().meta.initialized) {
			return;
		}

		fireOperationalAnalyticsDeferred(createAnalyticsEvent({}), 'singleIssueLoaded success');

		setState({
			meta: {
				...getState().meta,
				isSingleIssueLoaded: true,
				singleIssueLoadedTimestamp: new Date().getTime(),
			},
		});

		dispatch(loadTrackingMetadata());
	};
