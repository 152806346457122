import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	addVote: {
		id: 'issue-view-foundation.issue-actions.vote-issue.add-vote',
		defaultMessage: 'Add vote',
		description: 'Label for the issue actions item to add vote issue',
	},
	removeVote: {
		id: 'issue-view-foundation.issue-actions.vote-issue.remove-vote',
		defaultMessage: 'Remove vote',
		description: 'Label for the issue actions item to remove vote',
	},
});
