// TODO: go/restrict-enums

import type { FieldKey, Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';

// TODO: go/restrict-enums
// eslint-disable-next-line no-restricted-syntax
export enum PolarisTimelineMode {
	MONTHS = 'MONTHS',
	QUARTERS = 'QUARTERS',
}
/**
 * This is used as the key in the arrangement information if the view is not grouped.
 * NOTE: this is used to store the data on the backend - changes need data migration!
 * */
export const NO_GROUPING_GROUP_ID = 'NO_GROUPING';

/** An identifier for a field value that can be grouped by (e.g. optionId of a select option). */
type GroupValueId = string;

/** Mapping of issue IDs to row number in a timeline view. */
type IssueRows = Record<IssueId, number>;

/**
 * Arrangement information used in timeline views to plot ideas onto given rows. This is scoped
 * by field value as we retain the arrangement while switching groupBy fields.
 */
export type GroupValueIdToIdArrangement = Record<GroupValueId, IssueRows>;

export type ArrangementInformation = Record<
	FieldKey | typeof NO_GROUPING_GROUP_ID,
	GroupValueIdToIdArrangement
>;

/** Configuration of a timeline view */
export type PolarisTimelineConfig = {
	startDateField: Field | undefined;
	dueDateField: Field | undefined;
	startTimestamp: string | undefined;
	endTimestamp: string | undefined;
	mode: PolarisTimelineMode;
	arrangementInformation?: ArrangementInformation | undefined;
	summaryCardField?: Field | undefined;
};
