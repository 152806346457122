import React, { useCallback, useMemo, useEffect } from 'react';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import Button from '@atlaskit/button/new';
import { Box, Inline, Stack, Text } from '@atlaskit/primitives';
import { ReactRenderer } from '@atlaskit/renderer';
import { useAIEventsInstrumentation } from '@atlassian/ai-analytics';
import { AITelepointerWrapper } from '@atlassian/ai-components';
import { useAIMockStreaming } from '@atlassian/ai-mock-streaming';
import { encodeAdf } from '@atlassian/conversation-assistant-content-renderer';
import { useIntl } from '@atlassian/jira-intl';
import { useCurrentComment } from '../../../controllers/use-ai-comments-context/index.tsx';
import { GeneratingAnimation } from './generating-animation.tsx';
import messages from './messages.tsx';

type Props = {
	isLoading: boolean;
	onDismiss: (isDiscard: boolean) => void;
	onInsertComment: (comment: ADF) => void;
};

const AIGeneratedComment = ({ isLoading, onDismiss, onInsertComment }: Props) => {
	const { formatMessage } = useIntl();
	const [{ currentComment, currentCommentType }] = useCurrentComment();

	const { delayedContent, isComplete } = useAIMockStreaming(currentComment ?? undefined);

	const adfContent = useMemo(() => {
		if (!delayedContent) {
			return null;
		}
		return encodeAdf(delayedContent, true);
	}, [delayedContent]);

	const { trackAIResultView } = useAIEventsInstrumentation();

	useEffect(() => {
		if (!isLoading && currentComment) {
			trackAIResultView({
				attributes: {
					aiExperienceName: currentCommentType ?? undefined,
				},
			});
		}
	}, [currentComment, currentCommentType, isLoading, trackAIResultView]);

	const onInsertClick = useCallback(() => {
		adfContent && onInsertComment(adfContent);
	}, [adfContent, onInsertComment]);

	return (
		<Box padding="space.200">
			{isLoading && (
				<Stack space="space.100">
					<Inline space="space.050">
						<GeneratingAnimation />
						<Box padding="space.025">
							<Text color="color.text.subtlest">{formatMessage(messages.generating)}</Text>
						</Box>
					</Inline>
					<Inline alignInline="end">
						<Button appearance="subtle" onClick={() => onDismiss(false)}>
							{formatMessage(messages.cancel)}
						</Button>
					</Inline>
				</Stack>
			)}
			{!isLoading && adfContent && (
				<Stack space="space.100">
					<AITelepointerWrapper hideTelepointer={isComplete}>
						<ReactRenderer document={adfContent} />
					</AITelepointerWrapper>
					<Inline space="space.100" alignInline="end">
						<Button appearance="subtle" onClick={() => onDismiss(true)} isDisabled={!isComplete}>
							{formatMessage(messages.discard)}
						</Button>
						<Button appearance="primary" onClick={onInsertClick} isDisabled={!isComplete}>
							{formatMessage(messages.insert)}
						</Button>
					</Inline>
				</Stack>
			)}
		</Box>
	);
};

export default AIGeneratedComment;
