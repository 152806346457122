import type { LinkedIssuesProgress } from '@atlassian/jira-polaris-domain-field/src/field-types/progress/types.tsx';
import {
	type StatusCategoryKey,
	DONE,
	IN_PROGRESS,
	TODO,
} from '@atlassian/jira-polaris-domain-field/src/field-types/status/types.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { DELIVERY_CALCULATION_MODE } from '@atlassian/jira-polaris-domain-field/src/presentation/constants.tsx';
import { NUMERIC_FIELD_FILTER } from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import type { LinkedIssuesFormula } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/linked-issues/types.tsx';
import {
	createGetLinkedDeliveryIssuesExist,
	createGetLinkedProgress,
} from '../../../selectors/properties/linked-issues/index.tsx';
import { factor, nullSafeComparator } from '../../comparators/index.tsx';
import { CSV_SEPARATOR_DELIVERY } from '../common/constanst.tsx';
import { defaultMapping } from '../default/index.tsx';
import type { MappingFilterFunction, FieldMapping, DeliveryValuesExport } from '../types.tsx';
import { createFilterFunction } from './filter/index.tsx';

export const linkedIssuesProgressComparator = nullSafeComparator<LinkedIssuesProgress>(
	(progress1, progress2, direction) => {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const categoryKeys = [DONE, IN_PROGRESS, TODO] as Array<
			keyof LinkedIssuesProgress['distribution']
		>;
		for (const key of categoryKeys) {
			const percentage1 =
				progress1.total > 0 ? (progress1.distribution[key] || 0) / progress1.total : 0;
			const percentage2 =
				progress2.total > 0 ? (progress2.distribution[key] || 0) / progress2.total : 0;

			if (percentage1 - percentage2 !== 0) {
				return (percentage1 - percentage2) * factor(direction);
			}
		}
		return 0;
	},
);

export const linkedIssuesProgressMapping = (field: Field): FieldMapping<LinkedIssuesProgress> => {
	const valueAccessor: FieldMapping<LinkedIssuesProgress>['valueAccessor'] = (
		state,
		_,
		issueId,
	) => {
		const linkedDeliveryIssuesExist = createGetLinkedDeliveryIssuesExist(issueId)(state);
		if (!linkedDeliveryIssuesExist) {
			return undefined;
		}
		const linkedProgress = createGetLinkedProgress(issueId)(state);
		return linkedProgress;
	};

	const valueAccessorToExport: FieldMapping<string>['valueAccessorToExport'] = (
		state,
		props,
		issueId,
		deliveryValuesExport,
	) => {
		const { statusCategories, formatMessage, messages } =
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			deliveryValuesExport as DeliveryValuesExport;
		const progress = valueAccessor(state, props, issueId);
		const linkedDeliveryIssuesExist = createGetLinkedDeliveryIssuesExist(issueId)(state);
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const calculationMode = (field?.formula as LinkedIssuesFormula)?.parameters?.calculationMode;

		if (linkedDeliveryIssuesExist && progress?.total === 0) {
			return formatMessage(messages.unestimated);
		}

		if (!progress || !statusCategories || progress.total === 0) {
			return '';
		}

		const tooltipMessage =
			calculationMode === DELIVERY_CALCULATION_MODE.STORY_POINTS
				? messages.deliveryTooltipStoryPoints
				: messages.deliveryProgressTooltip;

		const deliveryProgressList: Array<{ key: StatusCategoryKey; name: string }> =
			progress.singleStatus && progress.total > 0
				? [statusCategories[progress.singleStatus.category]]
				: [statusCategories[DONE], statusCategories[IN_PROGRESS], statusCategories[TODO]];

		return deliveryProgressList
			.map(
				({ key, name }) =>
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					formatMessage(tooltipMessage, {
						count: progress.distribution[key] || 0,
						category: name,
						total: progress.total,
					}) as string,
			)
			.join(CSV_SEPARATOR_DELIVERY);
	};

	return {
		...defaultMapping,
		field,
		comparator: linkedIssuesProgressComparator,
		valueAccessor,
		valueAccessorToExport,
		getFilter: (filter): MappingFilterFunction<LinkedIssuesProgress> | undefined =>
			filter.type === NUMERIC_FIELD_FILTER && filter.field === field.key
				? createFilterFunction(filter.values)
				: undefined,
	};
};
