import React from 'react';
import type { CustomGlyphProps } from '@atlaskit/icon';

export const autosaveOffGlyph = (props: CustomGlyphProps) => (
	<svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="24" fill="none">
		<path
			fill="currentColor"
			d="m6.973 5.221.747-.747a5.214 5.214 0 0 0-3.863 1.543C2.443 7.43 1.96 9.48 2.569 11.282a.87.87 0 0 1-.53 1.08.806.806 0 0 1-1.059-.486C.17 9.475.812 6.74 2.697 4.856c1.451-1.45 3.41-2.17 5.34-2.046L6.972 1.743A.822.822 0 0 1 8.133.581l2.262 2.263a.904.904 0 0 1-.001 1.277l-2.26 2.26a.82.82 0 0 1-1.16-1.16ZM9.606 19.34l2.26-2.26a.82.82 0 0 1 1.16 1.16l-.745.746c1.4.05 2.809-.49 3.861-1.542 1.414-1.414 1.896-3.463 1.288-5.265a.87.87 0 0 1 .991-1.118.786.786 0 0 1 .598.525c.81 2.4.168 5.134-1.716 7.018-1.452 1.452-3.411 2.171-5.341 2.047l1.067 1.067a.822.822 0 0 1-1.162 1.162l-2.262-2.262a.904.904 0 0 1 0-1.278Z"
		/>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M2.708 19.442a.997.997 0 0 1-1.631-.326.996.996 0 0 1 .216-1.09l5.893-5.892L8.64 7.73h2.722l.057.172 4.88-4.88a.996.996 0 0 1 1.412.003.996.996 0 0 1 .002 1.412L2.708 19.442Zm7.088-9.918.328-.328-.118-.43-.21.758Z"
			clipRule="evenodd"
		/>
		<path
			fill="currentColor"
			d="m9.97 14.294-.113-.001 2.759-2.76L14 15.73h-2.075l-.432-1.582a8.36 8.36 0 0 1-1.523.146Z"
		/>
	</svg>
);
export const autosaveOnGlyph = (props: CustomGlyphProps) => (
	<svg {...props} xmlns="http://www.w3.org/2000/svg" width="20" height="24" fill="none">
		<path
			fill="currentColor"
			d="m7.72 4.744-.747.747a.82.82 0 0 0 1.16 1.16l2.261-2.26a.904.904 0 0 0 .001-1.278L8.133.851A.822.822 0 1 0 6.97 2.013L8.038 3.08c-1.93-.125-3.89.595-5.341 2.046C.812 7.011.169 9.744.98 12.146c.146.432.62.65 1.059.486a.87.87 0 0 0 .53-1.08C1.961 9.75 2.443 7.7 3.857 6.286A5.214 5.214 0 0 1 7.72 4.744ZM11.866 17.35l-2.26 2.26a.904.904 0 0 0-.001 1.277l2.262 2.263a.821.821 0 1 0 1.162-1.162l-1.067-1.068c1.93.125 3.89-.595 5.34-2.046 1.885-1.885 2.528-4.618 1.717-7.019a.786.786 0 0 0-.598-.525.82.82 0 0 0-.462.039.87.87 0 0 0-.53 1.08c.609 1.802.127 3.85-1.287 5.264a5.213 5.213 0 0 1-3.861 1.543l.745-.746a.82.82 0 0 0-1.16-1.16Z"
		/>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M11.493 14.417a8.36 8.36 0 0 1-1.523.146c-.48 0-.947-.049-1.463-.134L8.075 16H6l2.639-8h2.722L14 16h-2.075l-.432-1.583Zm-2.59-1.388a8.42 8.42 0 0 0 1.067.073c.36 0 .732-.024 1.127-.085l-1.091-3.982-1.103 3.994Z"
			clipRule="evenodd"
		/>
	</svg>
);
