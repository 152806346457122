import React, { Component } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import AppBase from '@atlassian/jira-app-base/src/index.tsx';
import ReportErrors from '@atlassian/jira-errors-handling/src/utils/reporting-error-boundary.tsx';
import {
	ContextualAnalyticsData,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import type { IssueContext } from '../common/types.tsx';
import { setContext } from '../controllers/context/actions/index.tsx';
import {
	devPanelWillUnmount,
	fetchDevPanelRequest,
} from '../controllers/dev-summary/actions/index.tsx';
import { panelStateSelector } from '../controllers/dev-summary/selectors/index.tsx';
import EmptySummary from './empty-summary/index.tsx';
import Summary from './summary/index.tsx';
import type { Props, StateProps, DispatchProps } from './types.tsx';

// eslint-disable-next-line jira/react/no-class-components
export class DevPanelApp extends Component<Props> {
	static defaultProps: Props = {
		issueAri: '',
		issueId: '',
		issueKey: toIssueKey(''),
		issueStatusCategory: '',
		isSiteAdmin: false,
		hasSoftwareAccess: false,
		projectId: '',
		projectType: '',
		nextGenProject: false,
		analyticsSource: '',
		panelState: 'HIDDEN',
		// Replace with lodash/noop
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		onFetchDevPanel: () => {},
		// Replace with lodash/noop
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		onWillUnMount: () => {},
		// Replace with lodash/noop
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		onSetContext: () => {},
		projectAri: '',
		isJiraAdmin: false,
	};

	constructor(props: Props) {
		super(props);
		this.dispatchSetContext();
	}

	UNSAFE_componentWillMount() {
		this.props.onFetchDevPanel();
	}

	componentDidUpdate(prevProps: Props) {
		if (this.props.panelState !== prevProps.panelState) {
			return;
		}
		this.dispatchSetContext();
		this.props.onFetchDevPanel();
	}

	componentWillUnmount() {
		this.props.onWillUnMount();
	}

	dispatchSetContext() {
		const {
			issueId,
			issueAri,
			issueKey,
			issueStatusCategory,
			projectId,
			projectType,
			nextGenProject,
			analyticsSource,
			isSiteAdmin,
			onSetContext,
			projectAri,
			isJiraAdmin,
		} = this.props;

		onSetContext({
			issueId,
			issueAri,
			issueKey,
			issueStatusCategory,
			projectId,
			projectType,
			nextGenProject,
			analyticsSource,
			isSiteAdmin: isSiteAdmin || false,
			projectAri,
			isJiraAdmin: isJiraAdmin || false,
		});
	}

	render() {
		return this.props.hasSoftwareAccess ? (
			<AppBase>
				<ContextualAnalyticsData
					attributes={{
						analyticsSource: this.props.analyticsSource,
						issueId: this.props.issueId,
						isEmptyStateEnabled: true,
						isSiteAdmin: this.props.isSiteAdmin,
					}}
				>
					<ReportErrors id="devPanelApp" packageName="jiraDevelopmentSummaryDevelopmentField">
						{this.props.panelState === 'DEV_SUMMARY' && <Summary />}
						{this.props.panelState === 'NOT_CONNECTED' && <EmptySummary />}
					</ReportErrors>
				</ContextualAnalyticsData>
			</AppBase>
		) : (
			<></>
		);
	}
}

const DevPanelWithTenantContext = ({ onFetchDevPanel, ...props }: Props) => {
	const { isSiteAdmin } = useTenantContext();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const wrappedOnFetchDevPanel = () =>
		onFetchDevPanel(
			createAnalyticsEvent({
				actionSubject: 'panel',
			}),
		);

	return (
		<DevPanelApp {...props} onFetchDevPanel={wrappedOnFetchDevPanel} isSiteAdmin={isSiteAdmin} />
	);
};

// This connect uses the store prop thats passed though not the provider that its underneath.

export default connect(
	(state): StateProps => ({
		// @ts-expect-error - TS2345 - Argument of type '{}' is not assignable to parameter of type 'State'.
		panelState: panelStateSelector(state),
	}),
	(dispatch): DispatchProps => ({
		onFetchDevPanel: (analyticsEvent?: UIAnalyticsEvent) => {
			dispatch(fetchDevPanelRequest(analyticsEvent));
		},
		onWillUnMount: () => dispatch(devPanelWillUnmount()),
		onSetContext: (issueContext: IssueContext) => dispatch(setContext(issueContext)),
	}),
)(DevPanelWithTenantContext);
