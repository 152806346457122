/**
 * @generated SignedSource<<7a95bd8bf0015c6cdc0642e8f3e2bd7f>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type inlineEdit_issueViewLayoutSecurityLevelField_SecurityLevelInlineEdit$data = {
  readonly id: string;
  readonly isEditableInIssueView: boolean | null | undefined;
  readonly name: string;
  readonly securityLevel: {
    readonly description: string | null | undefined;
    readonly id: string;
    readonly name: string | null | undefined;
    readonly securityId: string;
  } | null | undefined;
  readonly securityLevels: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly description: string | null | undefined;
        readonly id: string;
        readonly name: string | null | undefined;
        readonly securityId: string;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "inlineEdit_issueViewLayoutSecurityLevelField_SecurityLevelInlineEdit";
};
export type inlineEdit_issueViewLayoutSecurityLevelField_SecurityLevelInlineEdit$key = {
  readonly " $data"?: inlineEdit_issueViewLayoutSecurityLevelField_SecurityLevelInlineEdit$data;
  readonly " $fragmentSpreads": FragmentRefs<"inlineEdit_issueViewLayoutSecurityLevelField_SecurityLevelInlineEdit">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "id"
},
v1 = {
  "kind": "ScalarField",
  "name": "name"
},
v2 = [
  (v0/*: any*/),
  {
    "kind": "ScalarField",
    "name": "securityId"
  },
  (v1/*: any*/),
  {
    "kind": "ScalarField",
    "name": "description"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "inlineEdit_issueViewLayoutSecurityLevelField_SecurityLevelInlineEdit",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "concreteType": "JiraSecurityLevel",
      "kind": "LinkedField",
      "name": "securityLevel",
      "plural": false,
      "selections": (v2/*: any*/)
    },
    {
      "concreteType": "JiraSecurityLevelConnection",
      "kind": "LinkedField",
      "name": "securityLevels",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraSecurityLevelEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraSecurityLevel",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v2/*: any*/)
            }
          ]
        }
      ]
    },
    {
      "kind": "ScalarField",
      "name": "isEditableInIssueView"
    }
  ],
  "type": "JiraSecurityLevelField"
};
})();

(node as any).hash = "a1623133dc704372a31fb833dc5df6a1";

export default node;
