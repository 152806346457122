import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	globalTransition: {
		id: 'issue-field-status.status-view.transition.with-label.global-transition',
		defaultMessage: 'Transition to',
		description:
			'Global transition button label. If button is clicked - status will change to the selected one',
	},
});
