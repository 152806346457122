import { useState, useEffect, useCallback } from 'react';
import type { ViewUUID } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { useLastSeenRecord } from './last-seen/index.tsx';
import { getLocallySeen, setLocallySeen } from './locally-seen/index.tsx';

export const useHasSeenView = (
	viewUUID: ViewUUID | undefined,
	accountId: AccountId | undefined,
	waitForBackendData = true,
) => {
	const lastSeen = useLastSeenRecord(viewUUID);
	const [locallySeenView, setlocallySeenView] = useState<boolean | undefined>(undefined);
	const [loadedLocallySeen, setLoadedLocallySeen] = useState<boolean>(false);

	useEffect(() => {
		if (viewUUID === undefined) {
			return;
		}
		getLocallySeen(viewUUID, accountId).then((seen: boolean) => {
			setlocallySeenView(seen);
			setLoadedLocallySeen(true);
		});
	}, [accountId, viewUUID]);

	if (viewUUID === undefined || !loadedLocallySeen) {
		return undefined;
	}

	if (locallySeenView === true) {
		return true;
	}

	if (!waitForBackendData) {
		return false;
	}

	if (lastSeen === undefined || lastSeen.loading || lastSeen.error) {
		return undefined;
	}

	if (accountId === undefined) {
		return undefined;
	}

	return lastSeen.data[accountId] !== undefined;
};

export const useMarkViewAsSeenLocally = () => {
	const accountId = useAccountId();

	return useCallback(
		(viewUUID: ViewUUID) => {
			setLocallySeen(viewUUID, accountId !== null ? accountId : undefined);
		},
		[accountId],
	);
};
