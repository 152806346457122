import isObject from 'lodash/isObject';
import type { IssueTypeFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/issue-type/types.tsx';
import {
	isIssueTypeIdFilter,
	isIssueTypeNameFilter,
	type ConnectionFieldIssueTypeFilter,
} from '@atlassian/jira-polaris-domain-field/src/field/connection/types.tsx';
import type { IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';

type IsMatchingConnectionFieldFilter = {
	filters: ConnectionFieldIssueTypeFilter[];
	// TODO: accept IssueTypeId only once "jpd_extracted_issue_type_store" FG is cleaned up
	issueType: IssueTypeId | IssueTypeFieldValue | undefined;
	issueTypeNameFilter?: (names: string[]) => IssueTypeId[];
};

export const isMatchingConnectionFieldFilter = ({
	filters,
	issueType,
	issueTypeNameFilter,
}: IsMatchingConnectionFieldFilter) => {
	if (!issueType) {
		return false;
	}

	return filters.some((filter) => {
		if (isIssueTypeNameFilter(filter)) {
			const validIds = issueTypeNameFilter?.(filter.names) || [];
			return isObject(issueType) ? validIds.includes(issueType.id) : validIds.includes(issueType);
		}

		if (isIssueTypeIdFilter(filter)) {
			return isObject(issueType)
				? filter.ids.includes(issueType.id)
				: filter.ids.includes(issueType);
		}

		// In case of unsupported filter type
		return false;
	});
};
