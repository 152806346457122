import React from 'react';
import Lozenge from '@atlaskit/lozenge';
import type { FeatureFlagRolloutSummary } from '@atlassian/jira-development-common/src/model/releases-data.tsx';
import StatusTooltip from './tooltip/index.tsx';

const lozengeAppearance = (isOn: boolean) => (isOn ? 'success' : 'default');

const lozengeValue = (isOn: boolean) => (isOn ? 'on' : 'off');

export type Props = {
	hasFeatureFlags: boolean;
	disabledFeatureFlagsCnt: number;
	enabledFeatureFlagsCnt: number;
	rolloutSummary: FeatureFlagRolloutSummary | undefined;
};

export default function Status(props: Props) {
	const { disabledFeatureFlagsCnt, enabledFeatureFlagsCnt, hasFeatureFlags, rolloutSummary } =
		props;
	const isOn = enabledFeatureFlagsCnt > 0;

	return hasFeatureFlags ? (
		<StatusTooltip
			disabledFeatureFlagsCnt={disabledFeatureFlagsCnt}
			enabledFeatureFlagsCnt={enabledFeatureFlagsCnt}
			rolloutSummary={rolloutSummary}
		>
			<Lozenge appearance={lozengeAppearance(isOn)}>{lozengeValue(isOn)}</Lozenge>
		</StatusTooltip>
	) : null;
}
