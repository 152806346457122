import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/concat';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import type { ActionsObservable } from 'redux-observable';
import size from 'lodash/size';
import { Observable } from 'rxjs/Observable';
import { functionWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import type { Action } from '@atlassian/jira-issue-view-actions/src/index.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import {
	fetchSubtaskTypes,
	type ServerIssueType,
} from '@atlassian/jira-issue-view-services/src/issue/subtask-types-server.tsx';
import {
	CHILD_ISSUE_PANEL_ADD_CLICKED,
	SUBTASK_QUICK_ADD_CLICKED,
	ISSUE_IN_EPIC_QUICK_ADD_CLICKED,
	CHILD_ISSUE_QUICK_ADD_CLICKED,
	AIWB_SUGGEST_CHILD_ISSUE,
} from '@atlassian/jira-issue-view-store/src/actions/child-panel-actions.tsx';
import {
	fetchingIssueTypes,
	fetchIssueTypesFailed,
	setIssueTypes,
} from '@atlassian/jira-issue-view-store/src/actions/issue-types-actions.tsx';
import {
	FETCH_ISSUE_REQUEST,
	FETCH_ISSUE_SUCCESS,
} from '@atlassian/jira-issue-view-store/src/common/actions/issue-fetch-actions.tsx';
import { reportServerTimeViaState } from '@atlassian/jira-issue-view-store/src/common/metrics/analytics-actions.tsx';
import { projectKeySelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import type { ProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';

type CacheItem<T> = {
	time: Date;
	data?: T;
};

const cacheTTL = 1000 * 60 * 30; // 30min
const requestIssueTypesCache = new Map<ProjectKey, CacheItem<ServerIssueType[]>>();

export const clearRequestCache = () => requestIssueTypesCache.clear();

export const issueTypesEpicNew = (
	action$: ActionsObservable<Action>,
	store: MiddlewareAPI<State>,
) =>
	action$
		.ofType(
			SUBTASK_QUICK_ADD_CLICKED,
			ISSUE_IN_EPIC_QUICK_ADD_CLICKED,
			CHILD_ISSUE_QUICK_ADD_CLICKED,
			CHILD_ISSUE_PANEL_ADD_CLICKED,
			AIWB_SUGGEST_CHILD_ISSUE,
		)
		.mergeMap(() => {
			const state = store.getState();
			const projectKey = projectKeySelector(state);

			// we have/already requested
			let cacheItem = requestIssueTypesCache.get(projectKey);

			if (cacheItem) {
				// @ts-expect-error - TS2362 - The left-hand side of an arithmetic operation must be of type 'any', 'number', 'bigint' or an enum type. | TS2363 - The right-hand side of an arithmetic operation must be of type 'any', 'number', 'bigint' or an enum type.
				if (new Date() - cacheItem.time < cacheTTL) {
					if (cacheItem.data) {
						return Observable.of(setIssueTypes(cacheItem.data));
					}
					// assume we are currently fetching
					return Observable.empty();
				}
			}

			cacheItem = {
				time: new Date(),
			};

			// add or touch cache
			requestIssueTypesCache.set(projectKey, cacheItem);

			const headerProcessors = reportServerTimeViaState(store);

			return Observable.concat(
				Observable.of(fetchingIssueTypes()),
				// @ts-expect-error - TS2345 - Argument of type '(result: IssueAndSubtaskType) => Observable<{ type: "SET_ISSUE_TYPES"; payload: ServerIssueType[]; }> | Observable<{ type: "FETCH_ISSUE_TYPES_FAILED"; }>' is not assignable to parameter of type '(value: IssueAndSubtaskType, index: number) => ObservableInput<{ type: "SET_ISSUE_TYPES"; payload: ServerIssueType[]; }>'.
				fetchSubtaskTypes('', projectKey, headerProcessors).mergeMap((result) => {
					const issueTypes = result && result.issueTypes;

					// if the fetch call fails we receive empty arrays so let's clear the cache
					if (!size(issueTypes)) {
						requestIssueTypesCache.delete(projectKey);
						return Observable.of(fetchIssueTypesFailed());
					}

					// set the cache data
					requestIssueTypesCache.set(projectKey, { ...cacheItem, data: issueTypes });

					return Observable.of(setIssueTypes(issueTypes));
				}),
			);
		});

export const issueTypesEpicOld = (
	action$: ActionsObservable<Action>,
	store: MiddlewareAPI<State>,
) =>
	action$.ofType(FETCH_ISSUE_REQUEST, FETCH_ISSUE_SUCCESS).mergeMap(() => {
		const state = store.getState();
		const projectKey = projectKeySelector(state);

		// we have/already requested
		let cacheItem = requestIssueTypesCache.get(projectKey);

		if (cacheItem) {
			// @ts-expect-error - TS2362 - The left-hand side of an arithmetic operation must be of type 'any', 'number', 'bigint' or an enum type. | TS2363 - The right-hand side of an arithmetic operation must be of type 'any', 'number', 'bigint' or an enum type.
			if (new Date() - cacheItem.time < cacheTTL) {
				if (cacheItem.data) {
					return Observable.of(setIssueTypes(cacheItem.data));
				}
				// assume we are currently fetching
				return Observable.empty();
			}
		}

		cacheItem = {
			time: new Date(),
		};

		// add or touch cache
		requestIssueTypesCache.set(projectKey, cacheItem);

		const headerProcessors = reportServerTimeViaState(store);

		return Observable.concat(
			Observable.of(fetchingIssueTypes()),
			// @ts-expect-error - TS2345 - Argument of type '(result: IssueAndSubtaskType) => Observable<{ type: "SET_ISSUE_TYPES"; payload: ServerIssueType[]; }> | Observable<{ type: "FETCH_ISSUE_TYPES_FAILED"; }>' is not assignable to parameter of type '(value: IssueAndSubtaskType, index: number) => ObservableInput<{ type: "SET_ISSUE_TYPES"; payload: ServerIssueType[]; }>'.
			fetchSubtaskTypes('', projectKey, headerProcessors).mergeMap((result) => {
				const issueTypes = result && result.issueTypes;

				// if the fetch call fails we receive empty arrays so let's clear the cache
				if (!size(issueTypes)) {
					requestIssueTypesCache.delete(projectKey);
					return Observable.of(fetchIssueTypesFailed());
				}

				// set the cache data
				requestIssueTypesCache.set(projectKey, { ...cacheItem, data: issueTypes });

				return Observable.of(setIssueTypes(issueTypes));
			}),
		);
	});

const issueTypesEpic = functionWithCondition(
	() => fg('optimise_fetch_createmeta_when_click_suggest_child'),
	issueTypesEpicNew,
	issueTypesEpicOld,
);

export default issueTypesEpic;
