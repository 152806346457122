import React, { useContext, useMemo } from 'react';
import { AiIssueBreakdownEntryPointContext } from '@atlassian/jira-ai-work-breakdown/src/controllers/context-provider.tsx';
import type { AiIssueBreakdownProps } from '@atlassian/jira-ai-work-breakdown/src/types.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';

export const AiIssueBreakdownEntryPointContainer = ({
	onInit,
	onCreate,
	childIssueTypes,
	isSubTaskCreationOpen,
	issueHierarchyLevel,
	onDiscard,
	issueId,
	projectId,
	issueKey,
	projectKey,
}: AiIssueBreakdownProps) => {
	const { entryPointReferenceSubject } = useContext(AiIssueBreakdownEntryPointContext);
	const runtimeProps = useMemo(
		() => ({
			onInit,
			onCreate,
			childIssueTypes,
			isSubTaskCreationOpen,
			issueHierarchyLevel,
			onDiscard,
			issueId,
			issueKey,
			projectId,
			projectKey,
		}),
		[
			onInit,
			onCreate,
			childIssueTypes,
			isSubTaskCreationOpen,
			issueHierarchyLevel,
			onDiscard,
			issueId,
			issueKey,
			projectId,
			projectKey,
		],
	);

	return (
		<JiraEntryPointContainer
			entryPointReferenceSubject={entryPointReferenceSubject}
			id="jiraAIWorkBreakdownEntryPointContainer"
			packageName="jiraAIWorkBreakdown"
			teamName="crocs"
			fallback={null}
			runtimeProps={runtimeProps}
		/>
	);
};
