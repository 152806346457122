import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';

export const RELATIVE = 'RELATIVE' as const;
export const ABSOLUTE = 'ABSOLUTE' as const;

export type TimestampDisplaySetting = typeof RELATIVE | typeof ABSOLUTE;

export type Timestamp = string | number;

type PositionType = 'bottom' | 'left' | 'right' | 'top' | 'mouse';

export type TimestampStyles = {
	always?: string;
	whenDisplaySettingSwitchable?: string;
};

export type Props = {
	value: Timestamp;
	extraStyles?: TimestampStyles;
	tooltipPosition?: PositionType;
	intl?: IntlShape;
};
