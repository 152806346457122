import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	summaryItemLabel: {
		id: 'development-summary-remote-links.summary-item-label',
		defaultMessage: '{remoteLinksCount} other {remoteLinksCount, plural, one {link} other {links}}',
		description:
			'Text displayed on the Remote Links summary item indicating the number of other links associated to an issue.',
	},
	linkIconLabel: {
		id: 'development-summary-remote-links.link-icon-label',
		defaultMessage: 'Link icon',
		description: 'Accessible alt text for a "link" icon displayed on the Remote Links summary item',
	},
});
