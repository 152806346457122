import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	header: {
		id: 'servicedesk-organizations-contacts-common.common.attributes.error-state.header',
		defaultMessage: 'Something went wrong',
		description:
			'Heading text for the details sidebar error state. This error state is shown when un unexpected error occurs when loading the detail fields.',
	},
});
