import type { Store } from 'redux';
import type { Epic } from 'redux-observable';
import initiateStore from '@atlassian/jira-common-tangerine/src/state/initiate.tsx';
import errorHandlerReduxMiddleware from '@atlassian/jira-errors-handling/src/utils/redux-middleware.tsx';
import { APP_ID } from '../model/constants.tsx';
import type { Action } from './actions/index.tsx';
import rootReducer from './reducers/index.tsx';
import type { State } from './reducers/types.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { State };

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (rootEpic?: Epic<Action, State>): Store<State> =>
	initiateStore<State, Action, undefined>({
		appName: 'development/details',
		rootReducer,
		rootEpic,
		middlewares: [errorHandlerReduxMiddleware(APP_ID)],
	});
