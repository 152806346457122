import { useEffect } from 'react';
import { useFlagService } from '@atlassian/jira-flags/src'; // ignore-for-ENGHEALTH-17759
import {
	useError,
	useIssueAdjustmentsActions,
} from '@atlassian/jira-issue-adjustments/src/controllers.tsx';
import { useErrorFlagConfig } from './hooks/use-error-flag-config/index.tsx';

export const ErrorFlags = () => {
	const errorProperties = useError();
	const { showFlag } = useFlagService();
	const errorFlag = useErrorFlagConfig(errorProperties);
	const { resetError } = useIssueAdjustmentsActions();

	useEffect(() => {
		if (errorFlag) {
			showFlag(errorFlag);
			resetError();
		}
	}, [errorFlag, resetError, showFlag]);
	return null;
};
