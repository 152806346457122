import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	entitlement: {
		id: 'servicedesk-customer-service-issue-view-panel.entitlement.entitlement',
		defaultMessage: 'Entitlement details',
		description:
			'Section header in the issue view panel which shows details from the entitlement custom field',
	},
});
