import memoizeOne from 'memoize-one';
import {
	StatusCategoryTypes,
	StatusCategoryIds,
	type StatusCategoryKeys,
} from '@atlassian/jira-common-constants/src/status-categories.tsx';
import type { StatusTransition, StatusTransitionOption, StatusValue } from './types.tsx';

const isGlobalSelfTransition = (transition: StatusTransition, currentStatusId: string): boolean =>
	transition.isGlobal && transition.to.id === currentStatusId;

const isInitialSelfTransition = (transition: StatusTransition, currentStatusId: string): boolean =>
	transition.isInitial && transition.to.id === currentStatusId;

const isLoopedTransitionToSelf = (transition: StatusTransition, currentStatusId: string): boolean =>
	transition.to.id === currentStatusId && transition.isLooped === true;

const filterLoopedTransitionsToSelf = memoizeOne(
	(transitions: StatusTransition[], currentStatusId: string): StatusTransition[] =>
		transitions.filter(
			(transition) =>
				!isLoopedTransitionToSelf(transition, currentStatusId) &&
				!isGlobalSelfTransition(transition, currentStatusId) &&
				!isInitialSelfTransition(transition, currentStatusId),
		),
);

export const isOnlyGlobalTransitions = (transitions: StatusTransition[]): boolean =>
	transitions.every((t) => t.isGlobal);

// transitions are already ordered by the back-end, so we just need to apply filters preserving the order
export const getFilteredOrderedTransitions = (
	transitions: StatusTransition[] | null,
	currentStatusId: string,
): StatusTransition[] => filterLoopedTransitionsToSelf(transitions || [], currentStatusId);

export const getLoopedTransitionsForStatus = memoizeOne(
	(transitions: StatusTransition[] | null, currentStatusId: string): StatusTransition[] =>
		(transitions || []).filter((transition) =>
			isLoopedTransitionToSelf(transition, currentStatusId),
		),
);

const formatOptions = (options: StatusTransition[]): StatusTransitionOption[] =>
	options.map((t) => ({
		label: t.to.name,
		value: t.id,
		transition: t,
	}));

const memoizedTranformOptions = memoizeOne(formatOptions);

export const transformOptions = (options: StatusTransition[]): StatusTransitionOption[] =>
	memoizedTranformOptions(options);

export const getStatusShape = (
	id: string,
	name: string,
	category: StatusCategoryKeys,
): StatusValue => {
	const statusCategoryId: number =
		StatusCategoryIds[category] || StatusCategoryIds[StatusCategoryTypes.UNDEFINED];

	return {
		id,
		name,
		statusCategory: { id: statusCategoryId, name },
	};
};
