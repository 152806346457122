import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	searchButtonPlaceholder: {
		id: 'development-summary-pull-request.create-pull-request-summary-item.create-pull-request-dropdown.search-button-placeholder',
		defaultMessage: 'Select branch to create pull request',
		description:
			"The placeholder for the search button in the 'Create pull request' dropdown. 'Branch' and 'Pull request' are technical terms for the tool Git, and are most often not translated.",
	},
});
