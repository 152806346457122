import { createSelector } from 'reselect';
import { entitiesSelector } from '../common/state/selectors/issue-selector.tsx';
import { isConfluencePageLinksEnabledSelector } from './confluence-content-selector.tsx';
import { canLinkIssuesSelector } from './issue-actions-permissions-selector.tsx';

export const confluenceAppLinksSelector = createSelector(
	entitiesSelector,
	(entities) => entities.confluenceAppLinks || [],
);

export const systemConfluenceAppLinkSelector = createSelector(entitiesSelector, (entities) =>
	entities?.confluenceAppLinks.find((link) => link.name === 'System Confluence'),
);

export const hasConfluenceAppLinks = createSelector(
	confluenceAppLinksSelector,
	(links) => links && links.length > 0,
);

export const canLinkConfluencePageSelector = createSelector(
	hasConfluenceAppLinks,
	isConfluencePageLinksEnabledSelector,
	canLinkIssuesSelector,
	(hasConfluenceAppLinksInTenant, isConfluenceLinksEnabled, canLinkIssues) =>
		hasConfluenceAppLinksInTenant && isConfluenceLinksEnabled && canLinkIssues,
);
