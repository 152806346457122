/**
 * @generated SignedSource<<ecd2c49f0b8f0da823401aaada13e1d3>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type watchersList_issueViewWatchers_WatchersListRelay$data = {
  readonly fieldId: string;
  readonly id: string;
  readonly selectedUsersConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly accountId: string;
        readonly id: string;
        readonly name: string;
        readonly picture: AGG$URL;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly type: string;
  readonly watch: {
    readonly $updatableFragmentSpreads: FragmentRefs<"useWatchesMutation_Mutation_Updatable">;
    readonly count: AGG$Long | null | undefined;
    readonly isWatching: boolean | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "watchersList_issueViewWatchers_WatchersListRelay";
};
export type watchersList_issueViewWatchers_WatchersListRelay$key = {
  readonly " $data"?: watchersList_issueViewWatchers_WatchersListRelay$data;
  readonly " $fragmentSpreads": FragmentRefs<"watchersList_issueViewWatchers_WatchersListRelay">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "watchersList_issueViewWatchers_WatchersListRelay",
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "concreteType": "JiraWatch",
      "kind": "LinkedField",
      "name": "watch",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "count"
        },
        {
          "kind": "ScalarField",
          "name": "isWatching"
        },
        {
          "kind": "FragmentSpread",
          "name": "useWatchesMutation_Mutation_Updatable"
        }
      ]
    },
    {
      "concreteType": "JiraUserConnection",
      "kind": "LinkedField",
      "name": "selectedUsersConnection",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraUserEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "ScalarField",
                  "name": "accountId"
                },
                {
                  "kind": "ScalarField",
                  "name": "name"
                },
                {
                  "kind": "ScalarField",
                  "name": "picture"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraWatchesField"
};
})();

(node as any).hash = "f08761f560371c0798a01df8a7770a96";

export default node;
