import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { createErrorAnalytics } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types.tsx';

export const deletePublishedView =
	(viewUUID: string | undefined): Action<State, Props> =>
	async (_, { viewRemote }) => {
		try {
			viewUUID && (await viewRemote.deleteView(viewUUID));
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			fireErrorAnalytics(
				createErrorAnalytics('polaris.view-sharing.delete-published-view-action', error),
			);
		}
	};
