import uuid from 'uuid';
import type { Action } from '@atlassian/react-sweet-state';
import { PROPERTY_KEY, initialTemplates } from '../../../common/constants.tsx';
import {
	type UserBranchSettings,
	type CaretPosition,
	type PlaceholderEnum,
	type UserConfigTemplates,
	BranchTemplateType,
} from '../../../common/types.tsx';
import {
	getUserBranchConfigTemplates,
	setUserBranchConfigTemplates,
} from '../../../services/branch-command-builder/index.tsx';
import type { State, BranchData } from '../types.tsx';
import {
	insertPlaceholderIntoState,
	mergeConsecutiveTextTemplates,
	removePlaceholderFromState,
	getUpdatedCaretPosition,
	handleConsecutiveTagTemplates,
	handleEndTagTemplate,
} from './utils.tsx';

const getPlaceHoldersLength = (template: UserConfigTemplates) =>
	template.filter((item) => item.type === BranchTemplateType.PLACEHOLDER).length;

export const getBranchTemplate = (branchSettings: UserBranchSettings) =>
	Array.isArray(branchSettings) ? branchSettings : branchSettings.branchNameTemplate;

export const getBranchSettings = (
	branchSettings: UserBranchSettings,
	isGitCommandIncluded = true,
) =>
	Array.isArray(branchSettings)
		? {
				branchNameTemplate: branchSettings,
				version: 'v2',
				isGitCommandIncluded,
			}
		: branchSettings;

const getInitialState = (templates: UserBranchSettings) => {
	const userBranchSettings = getBranchSettings(templates);
	const { branchNameTemplate } = userBranchSettings;
	const newBranchNameSettings = {};
	const defaultTemplates = Array.from(branchNameTemplate);

	return {
		uniqueKey: uuid.v4(),
		writeOnlyTemplates: defaultTemplates,
		readAndWriteTemplates: defaultTemplates,
		originalTemplates: defaultTemplates,
		caretPosition: {
			templateIndex: getPlaceHoldersLength(defaultTemplates),
			characterIndex: 0,
		},
		isLoading: false,
		isSaving: false,
		saveError: null,
		focusTagIndex: 0,
		...newBranchNameSettings,
	};
};

export const setInitialState =
	(templates: UserBranchSettings): Action<State> =>
	({ setState }) => {
		const initialState = getInitialState(templates);
		setState({
			...initialState,
		});
	};

/**
 * This custom restore is specific to Firefox as it moves the
 * caret position if the text tag becomes empty
 */
export const restoreFocusAfterDeletingAllText =
	(): Action<State> =>
	({ setState, getState }) => {
		const { caretPosition, writeOnlyTemplates, readAndWriteTemplates } = getState();

		const lastTemplate = readAndWriteTemplates[readAndWriteTemplates.length - 1];
		const isLastTemplateATag = lastTemplate.type === BranchTemplateType.PLACEHOLDER;

		if (writeOnlyTemplates.length === readAndWriteTemplates.length + 2) {
			// a tag along with a text has been deleted with BACKSPACE (not DELETE)
			if (caretPosition.templateIndex > 0) {
				setState({
					uniqueKey: uuid.v4(),
					writeOnlyTemplates: readAndWriteTemplates,
					caretPosition: {
						templateIndex: caretPosition.templateIndex - 1,
						characterIndex: 0,
					},
					restoreFocus: true,
				});
			}
		} else if (readAndWriteTemplates.length % 2 === 0 && isLastTemplateATag) {
			// tag template at the end
			const updatedTemplates: UserConfigTemplates = handleEndTagTemplate(readAndWriteTemplates);
			setState({
				uniqueKey: uuid.v4(),
				readAndWriteTemplates: updatedTemplates,
				writeOnlyTemplates: updatedTemplates,
				caretPosition: {
					templateIndex: caretPosition.templateIndex,
					characterIndex: 0,
				},
				restoreFocus: true,
			});
		} else if (readAndWriteTemplates.length % 2 === 0) {
			// even number of templates, insert text between tags
			const updatedTemplates = handleConsecutiveTagTemplates(readAndWriteTemplates);
			setState({
				uniqueKey: uuid.v4(),
				readAndWriteTemplates: updatedTemplates,
				writeOnlyTemplates: updatedTemplates,
				caretPosition: {
					templateIndex: caretPosition.templateIndex - 1,
					characterIndex: 0,
				},
				restoreFocus: true,
			});
		} else {
			// only text is deleted with DELETE or BACKSPACE
			setState({
				uniqueKey: uuid.v4(),
				writeOnlyTemplates: readAndWriteTemplates,
				restoreFocus: true,
			});
		}
	};

export const setEmptyTextStarterTemplates =
	(): Action<State> =>
	({ setState }) => {
		const emptyTextWrapper = [initialTemplates[0]];
		setState({
			uniqueKey: uuid.v4(),
			writeOnlyTemplates: emptyTextWrapper,
			readAndWriteTemplates: emptyTextWrapper,
			caretPosition: {
				templateIndex: 0,
				characterIndex: 0,
			},
			restoreFocus: true,
		});
	};

export const setDefaultTemplates =
	(): Action<State> =>
	({ setState }) => {
		const defaultTemplates = Array.from(initialTemplates);
		setState({
			uniqueKey: uuid.v4(),
			writeOnlyTemplates: defaultTemplates, // use contentEditable method, if not add new tags in the templates, the structure will not change,
			// need hard refresh the state make it re-render
			readAndWriteTemplates: defaultTemplates,
			focusTagIndex: 0,
			// after reset, set the focus at the end of the input
			caretPosition: {
				templateIndex: getPlaceHoldersLength(defaultTemplates),
				characterIndex: 0,
			},
			restoreFocus: true,
			saveError: null,
		});
	};

export const setUserEnteredTemplate =
	(templates: UserConfigTemplates): Action<State> =>
	({ setState }) => {
		const mergedTemplates = mergeConsecutiveTextTemplates([...templates], true);
		const merged = mergedTemplates.length !== templates.length;

		if (merged) {
			setState({
				uniqueKey: uuid.v4(),
				readAndWriteTemplates: mergedTemplates,
				writeOnlyTemplates: mergedTemplates,
				focusTagIndex: 0,
				restoreFocus: true,
			});
		} else {
			setState({
				readAndWriteTemplates: templates,
			});
		}
	};

export const setCaretPosition =
	(caretPosition: CaretPosition): Action<State> =>
	({ setState }) => {
		setState({ caretPosition });
	};

export const insertPlaceholder =
	(placeholder: PlaceholderEnum): Action<State> =>
	({ getState, setState }) => {
		const { readAndWriteTemplates, caretPosition } = getState();
		// clone array as we use in-place modification of array
		// so sweet state can detect the change
		const modifiedTemplates = Array.from(
			insertPlaceholderIntoState(caretPosition, readAndWriteTemplates, placeholder),
		);
		const updatedCaretPosition = {
			templateIndex: caretPosition.templateIndex + 1,
			characterIndex: 0,
		};
		setState({
			uniqueKey: uuid.v4(),
			readAndWriteTemplates: modifiedTemplates,
			writeOnlyTemplates: modifiedTemplates,
			focusTagIndex: 0,
			caretPosition: updatedCaretPosition,
			restoreFocus: true,
		});
	};

export const removePlaceholder =
	(
		placeholder: PlaceholderEnum,
		placeholderIndex: number,
		removedWithKeyboard: boolean,
	): Action<State> =>
	({ getState, setState }) => {
		const { readAndWriteTemplates } = getState();
		// clone array as we use in-place modification of array
		// so sweet state can detect the change
		const modifiedTemplates = Array.from(
			removePlaceholderFromState(
				readAndWriteTemplates,
				placeholder,
				placeholderIndex,
				removedWithKeyboard,
			),
		);

		const updatedCaretPosition = getUpdatedCaretPosition(
			placeholderIndex,
			readAndWriteTemplates,
			removedWithKeyboard,
		);

		setState({
			uniqueKey: uuid.v4(),
			readAndWriteTemplates: modifiedTemplates,
			writeOnlyTemplates: modifiedTemplates,
			focusTagIndex: 0,
			...(!removedWithKeyboard && {
				caretPosition: updatedCaretPosition,
				restoreFocus: true,
			}),
		});
	};

// export for test
export const saveToUserPropertiesAPI = (
	accountId: string | null,
	userInput: UserBranchSettings,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	setState: any,
) => {
	const userBranchSettings = getBranchTemplate(userInput);

	return setUserBranchConfigTemplates(accountId, PROPERTY_KEY, userBranchSettings)
		.then(() => {
			setState({ isSaving: false, originalTemplates: userBranchSettings });
			return true;
		})
		.catch((err) => {
			setState({ isSaving: false, saveError: err.message });
			return false;
		});
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getFromUserPropertiesAPI = (accountId: string | null, setState: any) =>
	getUserBranchConfigTemplates(accountId, PROPERTY_KEY)
		.then((res) => {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			const initialState = getInitialState(res as UserBranchSettings);
			setState({
				...initialState,
			});
		})
		.catch(() => {
			const initialState = getInitialState(initialTemplates);
			setState({
				...initialState,
			});
		});

export const saveUserConfigBranchData =
	(
		accountId: string | null,
		readAndWriteTemplates: UserBranchSettings, // eslint-disable-next-line @typescript-eslint/no-explicit-any
	): (({ setState }: { setState: any }) => Promise<any>) =>
	({ setState }) => {
		const { branchNameTemplate, isGitCommandIncluded } = getBranchSettings(readAndWriteTemplates);

		setState({
			uniqueKey: uuid.v4(),
			writeOnlyTemplates: branchNameTemplate,
			readAndWriteTemplates: branchNameTemplate,
			caretPosition: {
				templateIndex: getPlaceHoldersLength(branchNameTemplate),
				characterIndex: 0,
			},
			isSaving: true,
			saveError: null,
			focusTagIndex: 0,
			isGitCommandIncluded,
		});
		return saveToUserPropertiesAPI(accountId, readAndWriteTemplates, setState);
	};

export const getUserConfigBranchData =
	(
		accountId: string | null,
	): (({ setState }: { setState: any }) => Promise<any>) => // eslint-disable-line @typescript-eslint/no-explicit-any
	({ setState }) => {
		setState({
			isLoading: true,
		});
		return getFromUserPropertiesAPI(accountId, setState);
	};

export const setBranchData =
	(branchData: BranchData): Action<State> =>
	({ setState }) => {
		setState({
			branchData,
		});
	};

export const setFocusTagIndex =
	(focusTagIndex: number): Action<State> =>
	({ setState }) => {
		setState({
			focusTagIndex,
		});
	};

export const setRestoreFocus =
	(restoreFocus: boolean): Action<State> =>
	({ setState }) => {
		setState({
			restoreFocus,
		});
	};

export const setIsGitCommandIncluded =
	(isGitCommandIncluded: boolean): Action<State> =>
	({ setState }) => {
		setState({
			isGitCommandIncluded,
		});
	};
