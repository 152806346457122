import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';

export const GoalName = ({ name }: { name: string }) =>
	name && (
		<Box xcss={goalTextStyles} as="span">
			{name}
		</Box>
	);

const goalTextStyles = xcss({
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});
