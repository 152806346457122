import React from 'react';
import { styled } from '@compiled/react';
import LockIconFilled from '@atlaskit/icon/glyph/lock-filled';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';

import { useIntl } from '@atlassian/jira-intl';

import messages from '../messages.tsx';

export const InternalAttachmentIcon = () => {
	const { formatMessage } = useIntl();
	const internalOnlyLabel = formatMessage(messages.internalOnlyLabel);

	return (
		<Tooltip content={internalOnlyLabel}>
			<IconWrapper>
				<LockIconFilled
					testId="issue-attachments-table.ui.lock-icon.lock-icon"
					primaryColor={token('color.icon', colors.DN80A)}
					label={internalOnlyLabel}
				/>
			</IconWrapper>
		</Tooltip>
	);
};

export default InternalAttachmentIcon;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconWrapper = styled.div({
	display: 'flex',
	alignContent: 'center',
	alignItems: 'center',
	paddingRight: token('space.050', '4px'),
});
