import { fg } from '@atlassian/jira-feature-gating';
import parameters from '@atlassian/jira-relay/src/__generated__/labels_issueFieldLabelsEditviewFull_LabelsEditViewQuery.graphql';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

type EntryPointParams = { id?: string };

const LabelsEditViewEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-labels-edit-view" */ './ui/labels'),
	),
	getPreloadProps: ({ id }: EntryPointParams) =>
		id && fg('issue_view_prefetch_relay_label_fields')
			? {
					queries: {
						labelsSuggestionsData: {
							parameters,
							options: {
								fetchPolicy: 'store-or-network' as const,
							},
							variables: {
								id,
							},
						},
					},
				}
			: {},
});

export default LabelsEditViewEntryPoint;
