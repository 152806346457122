import {
	PARENT_CANDIDATES_FOR_NEW_ISSUES_QUERY_WITH_INFO,
	buildParentCandidatesForNewIssueQuery,
} from '@atlassian/jira-issue-fetch-services-common/src/services/issue-parent-candidates-for-new-issue/index.tsx';
import type { CloudId, ProjectId, IssueTypeId } from '@atlassian/jira-shared-types/src/general.tsx';
import { getClient } from '../../../common/utils/client.tsx';
import type { ParentCandidatesResponseForNew } from '../types.tsx';

export const DEFAULT_NUMBER_OF_PARENT_CANDIDATES_FOR_NEW_ISSUE_RETRIEVAL_LIMIT = 20;

/**
 * Returns parent candidates for a new issue (e.g one that is about to be created)
 *
 * @param cloudId - The cloud ID of the instance
 * @param projectId - The ID of the project in which the issue will be created
 * @param issueTypeId - The issue type of the project to be created
 * @param searchTerm - A search string for the parent, on either full key match or partial summary match
 * @param excludeDone - Whether to exclude done issues from the list of parent candidate results
 * @returns A list of possible parent issues for the specified project and issue type
 */
// eslint-disable-next-line jira/import/no-anonymous-default-export
export default async (
	cloudId: CloudId,
	projectId: ProjectId,
	issueTypeId: IssueTypeId,
	searchTerm: string,
	excludeDone: boolean,
): Promise<ParentCandidatesResponseForNew> => {
	const variables = {
		cloudId,
		projectId,
		issueTypeId,
		searchTerm,
		excludeDone,
		first: DEFAULT_NUMBER_OF_PARENT_CANDIDATES_FOR_NEW_ISSUE_RETRIEVAL_LIMIT,
	};

	const response: ParentCandidatesResponseForNew = await getClient('').query({
		query: buildParentCandidatesForNewIssueQuery(),
		variables,
		operationName: `jira_parenting_${PARENT_CANDIDATES_FOR_NEW_ISSUES_QUERY_WITH_INFO}`,
	});

	return response;
};
