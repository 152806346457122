import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';
import type { UserInputModalProps } from './types.tsx';

export const automationManualTriggerEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-component-automation-manual-trigger" */ './index.tsx'),
	),
	getPreloadProps: (props: UserInputModalProps) => ({
		extraProps: props,
	}),
});
