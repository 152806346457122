import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';

export const copyTextFieldWrapperStyles = `
    display: flex;
`;

export const fieldWrapperStyles = `
    flex-grow: 1;
    margin-right: ${gridSize}px;
    // Override blue focus ring and white background colour on focus
    [data-ds--text-field--container] {
        &:focus-within {
            border-color: ${token('color.border', colors.N40)};
        }
    }
    [data-ds--text-field--input] {
        &:focus {
            background: ${token('color.border', colors.N30)};
        }
    }
`;
