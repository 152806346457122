import React from 'react';
import DataClassificationLevelBadge from '@atlassian/data-classification-level';
import { expVal } from '@atlassian/jira-feature-experiments';
import { ff } from '@atlassian/jira-feature-flagging';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import { Emphasise } from '@atlassian/jira-issue-view-activity-common/src/styles/index.tsx';
import Change from '../../../../common/ui/change/index.tsx';
import GroupedChange from '../../../../common/ui/grouped-change/index.tsx';
import HistoryItem from '../../../../common/ui/history-item/index.tsx';
import type {
	DataClassification,
	DataClassificationChangedHistoryItem,
} from '../../../../types.tsx';
import messages from './messages.tsx';

type DataClassificationHistoryItemProps = {
	isGrouped?: Boolean;
	historyItem: DataClassificationChangedHistoryItem;
};

type DataClassificationBadgeProps = {
	classification: DataClassification;
};

const DataClassificationBadge = ({ classification }: DataClassificationBadgeProps) => (
	<DataClassificationLevelBadge
		testId="issue-history.ui.history-items.history-item.data-classification-history-item.data-classification-badge"
		name={classification.name}
		color={classification.color?.colorKey}
		guideline={classification.guidelines}
	/>
);

export const DataClassificationHistoryItem = ({
	historyItem,
	isGrouped = false,
}: DataClassificationHistoryItemProps) => {
	const { actor, timestamp, fromClassification, toClassification } = historyItem;

	const { formatMessage } = useIntl();

	if (!ff('jira-issue-history-data-classification_mhyjw')) {
		return null;
	}

	return (
		<FormattedMessage
			{...messages.changed}
			values={{
				status: (
					<Emphasise key="dataClassificationLevel">
						{formatMessage(
							expVal('issue_view_activity_timeline', 'isActivityTimelineEnabled', false)
								? messages.dataClassificationLevelLowercase
								: messages.dataClassificationLevel,
						)}
					</Emphasise>
				),
			}}
		>
			{(...action) =>
				isGrouped && expVal('issue_view_activity_timeline', 'isActivityTimelineEnabled', false) ? (
					<GroupedChange
						field={formatMessage(messages.dataClassificationLevel)}
						from={
							fromClassification ? (
								<DataClassificationBadge classification={fromClassification} />
							) : (
								formatMessage(
									expVal('issue_view_activity_timeline', 'isActivityTimelineEnabled', false)
										? messages.noClassificationLowercase
										: messages.noClassification,
								)
							)
						}
						to={
							toClassification ? (
								<DataClassificationBadge classification={toClassification} />
							) : (
								formatMessage(
									expVal('issue_view_activity_timeline', 'isActivityTimelineEnabled', false)
										? messages.noClassificationLowercase
										: messages.noClassification,
								)
							)
						}
					/>
				) : (
					<HistoryItem
						testId="issue-history.ui.history-items.history-item.data-classification-history-item.history-item"
						actor={actor}
						action={action}
						timestamp={timestamp}
						change={
							<Change
								from={
									fromClassification ? (
										<DataClassificationBadge classification={fromClassification} />
									) : (
										formatMessage(
											expVal('issue_view_activity_timeline', 'isActivityTimelineEnabled', false)
												? messages.noClassificationLowercase
												: messages.noClassification,
										)
									)
								}
								to={
									toClassification ? (
										<DataClassificationBadge classification={toClassification} />
									) : (
										formatMessage(
											expVal('issue_view_activity_timeline', 'isActivityTimelineEnabled', false)
												? messages.noClassificationLowercase
												: messages.noClassification,
										)
									)
								}
							/>
						}
					/>
				)
			}
		</FormattedMessage>
	);
};
