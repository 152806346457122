import React, { type PropsWithChildren } from 'react';

import {
	type Action,
	type ActionThunk,
	createContainer as createOriginalContainer,
	type Store,
} from 'react-sweet-state';

import { type DefaultEocWrapperType, type EocStoreOptionsType } from './types';

/**
 * This component runs the useContainerHook with props that are sent from useSyncContainerProps.
 * We can use all features of react hooks inside here. Also, we created this component outside of
 * the createDefaultEocWrapper due to if we use this inside of the createDefaultEocWrapper,
 * it'll re-render on every child have changed.
 */
function InnerElement<C extends {}>({
	children,
	useContainerHook,
	containerProps,
}: {
	children: React.ReactElement;
	containerProps: C;
	useContainerHook: (props: C) => void;
}) {
	useContainerHook?.(containerProps);

	return children;
}

/**
 * This function sends the state and actions that returns from useSyncContainerProps function, into InnerComponent as
 * a props. Thus, we can use this props inside the hook that we have run inside the InnerComponent. Also, since props
 * have passed as props into the store container, we can use props as params inside the actions.
 * We are overriding the props that sent where the container used, with the props that returned
 * from useSyncContainerProps.
 * @template P It is original container's props.
 * @template I prop types that passed into useContainerHook
 */
export function createDefaultEocWrapper<P extends {}, I extends {}>({
	useSyncContainerProps,
	useContainerHook,
}: DefaultEocWrapperType<P, I>): React.FC<PropsWithChildren<P>> {
	return ({ children }) => {
		if (React.isValidElement(children)) {
			const containerProps = useSyncContainerProps
				? useSyncContainerProps(children.props)
				: ({} as I);

			return React.cloneElement(
				children,
				{ ...children.props, ...containerProps },
				useContainerHook ? (
					<InnerElement useContainerHook={useContainerHook} containerProps={containerProps}>
						{children.props.children}
					</InnerElement>
				) : (
					children.props.children
				),
			);
		}

		return null;
	};
}

/**
 * This function creates a HOC to inject the props that we sent. This function control the prop of Wrapper,
 * if it is exists, function wrap the child with Wrapper component.
 * Also, you can create a Wrapper with createDefaultEocWrapper function.
 * @template S store type
 * @template A action types of store
 * @template P props type of container
 */
export const createContainer = <
	S,
	A extends Record<string, ActionThunk<S, A>>,
	P = { scope?: string; isGlobal?: boolean },
>(
	store: Store<S, A>,
	options?: {
		onInit?: () => Action<S, P>;
		onUpdate?: () => Action<S, P>;
		onCleanup?: () => Action<S, P>;
		displayName?: string;
	} & EocStoreOptionsType<P>,
) => {
	const OriginalContainer = createOriginalContainer<S, A, P>(store, {
		...options,
	});

	const HOC: React.FC<PropsWithChildren<{} | P>> = ({ ...props }) => {
		return options?.Wrapper ? (
			<options.Wrapper {...(props as P)}>
				<OriginalContainer {...(props as P)}>{props.children}</OriginalContainer>
			</options.Wrapper>
		) : (
			<OriginalContainer {...(props as P)}>{props.children}</OriginalContainer>
		);
	};

	HOC.displayName = OriginalContainer.displayName + '-HOC'; // To track if error occure because of HOC

	return HOC;
};
