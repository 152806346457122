import { borderRadius } from '@atlassian/jira-common-styles/src/main.tsx';

export const COLLAPSIBLE_CHILD_ISSUES_UI_EVENT = 'jivCollapsibleChildIssues' as const;
export const COLLAPSIBLE_CHILD_ISSUES_ID = 'COLLAPSIBLE_CHILD_ISSUES' as const;

export const groupBorderRadius = borderRadius + 1;

export const subTitleStyledSelectorName =
	'issue-view-common-views.child-issues-panel.collapsible-child-issues.sub-title-styled';
export const SUB_TITLE_STYLED_COMPONENT_SELECTOR = `[data-component-selector="${subTitleStyledSelectorName}"]`;

export const titleStyledSelectorName =
	'iissue-view-common-views.child-issues-panel.collapsible-child-issues.title-styled';
export const TITLE_STYLED_COMPONENT_SELECTOR = `[data-component-selector="${titleStyledSelectorName}"]`;

export const HEADER_TITLE_DATA_TEST_ID =
	'issue-view-common-views.child-issues-panel.collapsible-child-issues.title';
