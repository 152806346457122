import type { State as IssueState } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { RouterActionsType } from '@atlassian/react-resource-router';

// NAVIGATE_TO_NEW_ISSUE
export const NAVIGATE_TO_NEW_ISSUE = 'NAVIGATE_TO_NEW_ISSUE';

export const navigateToNewIssue = ({
	fromIssueKey,
	toIssueKey,
}: {
	fromIssueKey: IssueKey;
	toIssueKey: IssueKey;
}) => ({
	type: NAVIGATE_TO_NEW_ISSUE,
	payload: { fromIssueKey, toIssueKey },
});

export type NavigateToNewIssueAction = ReturnType<typeof navigateToNewIssue>;

// NAVIGATE_TO_PROJECT
export const NAVIGATE_TO_PROJECT = 'NAVIGATE_TO_PROJECT';

export const navigateToProject = (projectUrl: string, pushAction: RouterActionsType['push']) => ({
	type: NAVIGATE_TO_PROJECT,
	payload: { projectUrl, pushAction },
});

export type NavigateToProjectAction = ReturnType<typeof navigateToProject>;

// NAVIGATE_TO_SPRINT
export const NAVIGATE_TO_SPRINT = 'NAVIGATE_TO_SPRINT';

export const navigateToSprint = (sprintId: number) => ({
	type: NAVIGATE_TO_SPRINT,
	payload: { sprintId },
});

export type NavigateToSprintAction = ReturnType<typeof navigateToSprint>;

// LOAD_NEW_ISSUE
export const LOAD_NEW_ISSUE = 'LOAD_NEW_ISSUE';

export const loadNewIssue = (issueKey: IssueKey) => ({
	type: LOAD_NEW_ISSUE,
	payload: { issueKey },
});

export type LoadNewIssueAction = ReturnType<typeof loadNewIssue>;

// LOAD_RECENT_ISSUE
export const LOAD_RECENT_ISSUE = 'LOAD_RECENT_ISSUE';

export const loadRecentIssue = (issueKey: IssueKey, issueState: IssueState) => ({
	type: LOAD_RECENT_ISSUE,
	payload: { issueKey, issueState },
});

export type LoadRecentIssueAction = ReturnType<typeof loadRecentIssue>;

export type IssueNavigationAction =
	| LoadNewIssueAction
	| LoadRecentIssueAction
	| NavigateToNewIssueAction
	| NavigateToProjectAction
	| NavigateToSprintAction;
