import React, { useMemo, useState, useEffect, useRef, type ReactElement } from 'react';
import { styled } from '@compiled/react';
import { MenuGroup, LinkItem } from '@atlaskit/menu';
import Popup from '@atlaskit/popup';
import { layers, gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import type { DropdownTriggerParams } from '@atlassian/jira-development-summary-common/src/common/types.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	fireUIAnalytics,
	useAnalyticsEvents,
	ContextualAnalyticsData,
	FireUiAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';

export type Props = {
	connectFeatureFlagTarget?: string;
	createFeatureFlagTarget?: string;
	getDropdownTrigger: (props: DropdownTriggerParams) => ReactElement;
	hideConnectFeatureFlagLinks?: boolean;
	hideCreateFeatureFlagLinks?: boolean;
	onOpenDropdown?: (arg1: { isOpen: boolean }) => void;
};

const FeatureFlagActionDropdownInner = ({
	connectFeatureFlagTarget,
	createFeatureFlagTarget,
	onOpenDropdown,
	getDropdownTrigger,
	hideConnectFeatureFlagLinks = false,
	hideCreateFeatureFlagLinks = false,
}: Props) => {
	const { formatMessage } = useIntl();

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [isOpen, setIsOpen] = useState(false);
	const previousOpen = useRef(false);

	useEffect(() => {
		if (previousOpen.current !== isOpen) {
			previousOpen.current = isOpen;
			isOpen &&
				fireUIAnalytics(createAnalyticsEvent({}), 'dropdown opened', 'featureFlagActionDropdown');
			!isOpen &&
				fireUIAnalytics(createAnalyticsEvent({}), 'dropdown closed', 'featureFlagActionDropdown');
			onOpenDropdown && onOpenDropdown({ isOpen });
		}
	}, [createAnalyticsEvent, isOpen, onOpenDropdown]);

	const createFeatureFlagDropdownItem = useMemo(
		() => (
			<>
				<FeatureFlagDropdownScrollContainer>
					<LinkItem
						// eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
						cssFn={() => ({
							minHeight: 'initial',
						})}
						key={createFeatureFlagTarget}
						href={createFeatureFlagTarget}
						onClick={() => {
							setIsOpen(false);
							fireUIAnalytics(createAnalyticsEvent({}), 'link clicked', 'CreateFeatureFlagLink', {
								redirectToFFProviderPage: true,
							});
						}}
						target="_blank"
						rel="noreferrer noopener"
						testId="development-summary-feature-flag.common.ui.feature-flag-action-dropdown-simple.link-item"
					>
						{formatMessage(messages.createFeatureFlagMessage)}
					</LinkItem>
				</FeatureFlagDropdownScrollContainer>
				<FireUiAnalytics action="shown" actionSubject="createFeatureFlagLinkList" />
			</>
		),
		[createFeatureFlagTarget, formatMessage, createAnalyticsEvent],
	);

	const showCreateFeatureFlagLinkSection =
		!hideCreateFeatureFlagLinks &&
		createFeatureFlagTarget !== null &&
		createFeatureFlagTarget !== undefined;

	const connectFeatureFlagDropdownItem = useMemo(
		() => (
			<>
				<FeatureFlagDropdownScrollContainer>
					<LinkItem
						// eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
						cssFn={() => ({
							minHeight: 'initial',
						})}
						key={connectFeatureFlagTarget}
						href={connectFeatureFlagTarget}
						onClick={() => {
							setIsOpen(false);
							fireUIAnalytics(createAnalyticsEvent({}), 'link clicked', 'ConnectFeatureFlagLink', {
								redirectToFFProviderPage: true,
							});
						}}
						target="_blank"
						rel="noreferrer noopener"
						testId="development-summary-feature-flag.common.ui.feature-flag-action-dropdown-simple.link-item"
					>
						{formatMessage(messages.connectFeatureFlagMessage)}
					</LinkItem>
				</FeatureFlagDropdownScrollContainer>
				<FireUiAnalytics action="shown" actionSubject="connectFeatureFlagLinkList" />
			</>
		),
		[connectFeatureFlagTarget, formatMessage, createAnalyticsEvent],
	);

	const showConnectFeatureFlagLinkSection =
		!hideConnectFeatureFlagLinks &&
		connectFeatureFlagTarget !== null &&
		connectFeatureFlagTarget !== undefined;

	return (
		<Popup
			id="feature-flag-action-dropdown"
			testId="development-summary-feature-flag.common.ui.feature-flag-action-dropdown-simple.popup"
			zIndex={layers.modal + 1}
			placement="bottom-end"
			shouldFlip
			trigger={(triggerProps) =>
				getDropdownTrigger({
					...triggerProps,
					isSelected: isOpen,
					onClick: () => setIsOpen(!isOpen),
				})
			}
			isOpen={isOpen}
			onClose={() => setIsOpen(false)}
			content={() => (
				<FeatureFlagDropdownWidthContainer>
					<MenuGroup>
						{showCreateFeatureFlagLinkSection && createFeatureFlagDropdownItem}
						{showConnectFeatureFlagLinkSection && connectFeatureFlagDropdownItem}
					</MenuGroup>
				</FeatureFlagDropdownWidthContainer>
			)}
		/>
	);
};

export const FeatureFlagActionDropdownSimple = ({
	connectFeatureFlagTarget,
	createFeatureFlagTarget,
	onOpenDropdown,
	getDropdownTrigger,
	hideConnectFeatureFlagLinks = false,
	hideCreateFeatureFlagLinks = false,
}: Props) => (
	<ContextualAnalyticsData
		attributes={{
			singleFFProvider:
				(connectFeatureFlagTarget != null && connectFeatureFlagTarget !== undefined) ||
				(createFeatureFlagTarget != null && createFeatureFlagTarget !== undefined),
		}}
	>
		<FeatureFlagActionDropdownInner
			connectFeatureFlagTarget={connectFeatureFlagTarget}
			createFeatureFlagTarget={createFeatureFlagTarget}
			onOpenDropdown={onOpenDropdown}
			getDropdownTrigger={getDropdownTrigger}
			hideConnectFeatureFlagLinks={hideConnectFeatureFlagLinks}
			hideCreateFeatureFlagLinks={hideCreateFeatureFlagLinks}
		/>
	</ContextualAnalyticsData>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FeatureFlagDropdownScrollContainer = styled.div({
	maxHeight: '145px',
	overflow: 'auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FeatureFlagDropdownWidthContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `${gridSize * 46}px`,
});
