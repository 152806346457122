import scrollIntoViewIfNeeded, { type Options } from 'scroll-into-view-if-needed';

const behavior = 'smooth';

export const smoothScrollIntoViewIfNeeded = (
	element?: HTMLElement | null,
	options: Options = Object.freeze({}),
) => {
	if (element) {
		scrollIntoViewIfNeeded(element, { behavior, ...options });
	}
};

export const smoothScrollIntoCenterIfNeeded = (element: HTMLElement | null) => {
	if (element) {
		smoothScrollIntoViewIfNeeded(element, { block: 'center' });
	}
};
