import type { ViewUUID } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { State } from '../types.tsx';

export const getSortedLastVisitors = (state: State, viewUUID: ViewUUID): AccountId[] => {
	const lastSeenRecord = state.lastSeen[viewUUID];

	if (lastSeenRecord === undefined) {
		return [];
	}

	const { data } = lastSeenRecord;
	return Object.keys(data).sort((a, b) => data[b].getTime() - data[a].getTime());
};
