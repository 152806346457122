import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	fetchErrorFlagTitle: {
		id: 'project-create-importer.upload-flow.fetch-error-flag-title',
		defaultMessage: 'AI generation error',
		description: 'Flag title for fetch AI generation error',
	},
	fetchErrorFlagDescription: {
		id: 'project-create-importer.upload-flow.fetch-error-flag-description',
		defaultMessage: 'There was an error generating a response. Please wait a while and try again.',
		description: 'Flag description for fetch AI generation error',
	},
});
