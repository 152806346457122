import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	dismissState: {
		id: 'development-summary-development-field.empty-summary.absolute-close-button.dismiss-state',
		defaultMessage: 'Hide empty development information',
		description:
			'Accessible label associated to a close button to dismiss the empty dev info state.',
	},
});
