/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//Chinese (Traditional)
export default {
  'fabric.elements.share.copied.to.clipboard.message': '已將連結複製到剪貼簿',
  'fabric.elements.share.copylink.button.text': '複製連結',
  'fabric.elements.share.copypubliclink.button.text': '複製公開連結',
  'fabric.elements.share.failure.icon.label': '錯誤圖示',
  'fabric.elements.share.failure.message': '無法分享',
  'fabric.elements.share.form.comment.label': '訊息 (選填)',
  'fabric.elements.share.form.comment.placeholder': '他們應該知道哪些事情？',
  'fabric.elements.share.form.info.message.no.invite.confluence': '收件者將看到頁面的名稱和您的訊息',
  'fabric.elements.share.form.info.message.no.invite.jira': '收件者將看到議題的名稱和您的訊息',
  'fabric.elements.share.form.no-permissions': '您無法分享。',
  'fabric.elements.share.form.public.send': '傳送公開連結',
  'fabric.elements.share.form.public.share': '共用公開連結',
  'fabric.elements.share.form.required-field.summary': '必填欄位標有星號',
  'fabric.elements.share.form.retry': '重試',
  'fabric.elements.share.form.send': '傳送',
  'fabric.elements.share.form.share': '分享',
  'fabric.elements.share.form.title': '分享',
  'fabric.elements.share.form.user-picker.add-more': '輸入更多項目',
  'fabric.elements.share.form.user-picker.label.confluence': '名稱、團隊、群組或電子郵件',
  'fabric.elements.share.form.user-picker.label.email-disabled-confluence': '名稱、團隊或群組',
  'fabric.elements.share.form.user-picker.label.email-disabled-jira': '名稱或團隊',
  'fabric.elements.share.form.user-picker.label.email-only': '電子郵件',
  'fabric.elements.share.form.user-picker.label.jira': '名稱、團隊或電子郵件',
  'fabric.elements.share.form.user-picker.no-options.existingUserOnly': '我們找不到「{inputValue}」的任何結果。',
  'fabric.elements.share.form.user-picker.no-options.generic':
    '我們找不到「{inputValue}」的任何結果。請透過電子郵件地址邀請使用者。',
  'fabric.elements.share.form.user-picker.placeholder.confluence': '例如：瑪麗亞, 橘隊, 第一組',
  'fabric.elements.share.form.user-picker.placeholder.email-disabled-jira': '例如：瑪麗亞, 橘隊',
  'fabric.elements.share.form.user-picker.placeholder.email-only': '例如：maria@company.com',
  'fabric.elements.share.form.user-picker.placeholder.jira': '例如：瑪麗亞, 橘隊, maria@company.com',
  'fabric.elements.share.form.user-picker.validation.required': '請至少選取一個使用者、群組、團隊或電子郵件。',
  'fabric.elements.share.form.user-picker.validation.required-message.confluence':
    '至少選取一個人員、團隊、群組或電子郵件',
  'fabric.elements.share.form.user-picker.validation.required-message.email-disabled-confluence':
    '至少選取一個人員、團隊或群組',
  'fabric.elements.share.form.user-picker.validation.required-message.email-disabled-jira': '至少選取一個人員或團隊',
  'fabric.elements.share.form.user-picker.validation.required-message.email-only': '至少選取一個電子郵件',
  'fabric.elements.share.form.user-picker.validation.required-message.jira': '至少選取一個人員、團隊或電子郵件',
  'fabric.elements.share.in.integration.button': '在 {integrationName} 中分享',
  'fabric.elements.share.invite.to.confluence': '邀請加入 Confluence',
  'fabric.elements.share.invite.to.jira': '邀請加入 Jira',
  'fabric.elements.share.main.tab.text.confluence': '分享頁面',
  'fabric.elements.share.main.tab.text.jira': '共用議題',
  'fabric.elements.share.menu.email.label': '電子郵件',
  'fabric.elements.share.menu.slack.label': 'Slack',
  'fabric.elements.share.popup.label': '分享',
  'fabric.elements.share.success.message':
    '{object, select,blogpost {已分享部落格貼文}board {已分享看板}calendar {已分享行事曆}draft {已分享草稿}filter {已分享篩選條件}issue {已分享議題}summary {已分享摘要}list {已分享列表}timeline {已分享時間表}form {已分享表單}media {已分享媒體}page {已分享頁面}project {已分享專案}pullrequest {已分享提取要求}question {已分享問題}report {已分享報告}repository {已分享存放庫}request {已分享要求}roadmap {已分享時間軸}site {已分享網站}space {已分享空間}other {已分享連結}}',
  'fabric.elements.share.to.integration.button': '與 {integrationName} 分享',
  'fabric.elements.share.trigger.button.icon.label': '分享圖示',
  'fabric.elements.share.trigger.button.invite.text': '邀請',
  'fabric.elements.share.trigger.button.text': '分享',
  'fabric.elements.share.trigger.button.tooltip.text': '分享',
};
