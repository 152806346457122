// api contract: https://hello.atlassian.net/wiki/spaces/AM3/pages/4383854942/Suggested+Comment+Reply+Agent+API+Design
export const PRODUCT_TYPES = {
	JWM: 'JWM',
	JSW: 'JSW',
} as const;

export type SuggestedCommentReplyAgentProduct = (typeof PRODUCT_TYPES)[keyof typeof PRODUCT_TYPES];

export const SuggestedCommentReplyAgentCommentType = {
	QUICK_REPLY: 'QUICK_REPLY',
	QUESTION: 'QUESTION',
	SUGGESTION: 'SUGGESTION',
	JIRA_STATUS_UPDATE: 'JIRA_STATUS_UPDATE',
	JIRA_COMMENT_REPLY: 'JIRA_COMMENT_REPLY',
	JIRA_ASSIGNEE_MISSING: 'JIRA_ASSIGNEE_MISSING',
	JIRA_DESCRIPTION_MISSING: 'JIRA_DESCRIPTION_MISSING',
	JIRA_MOTIVATION: 'JIRA_MOTIVATION',
} as const;

export type SuggestedCommentReplyAgentCommentType =
	(typeof SuggestedCommentReplyAgentCommentType)[keyof typeof SuggestedCommentReplyAgentCommentType];

export const SUGGESTED_COMMENT_AGENT_NAME_ID = 'suggested_comment_reply_agent';
export const SUGGESTED_COMMENT_AGENT_EXPERIENCE_ID = 'suggested-comment-reply';

export type SuggestedCommentReplyCommentTypes = SuggestedCommentReplyAgentCommentType[];

export type SuggestedCommentReplyAgentRequest = {
	object_id: string; // issue-key
	locale: string;
	product: SuggestedCommentReplyAgentProduct;
	comment_types?: SuggestedCommentReplyCommentTypes;
};

export type SuggestedCommentReplyAgentResponse = {
	commentSuggestions?: { commentReplyType: string; text: string }[];
};
