import React, { useState } from 'react';
import { Provider } from '@atlassian/jira-react-redux/src/index.tsx';
import createStore from '../controllers/index.tsx';
import DevPanel from './main.tsx';
import type { OwnProps } from './types.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({ issueKey, ...props }: OwnProps) => {
	const [store] = useState(createStore({ issueKey }));
	return (
		<Provider store={store}>
			<DevPanel {...props} issueKey={issueKey} />
		</Provider>
	);
};
