import React, { memo, useCallback } from 'react';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { QuickAddItemToRender } from '../../types.tsx';

export const dropDownListButtonSubject = 'linkDropdownListButton';
export const dropDownListButtonSubjectId = 'moreLinksDropdownToggled';

export const dropDownListItemSubject = 'linkDropdownListItem';
export const dropDownListItemSubjectId = 'createLink';

export type Props = {
	item: QuickAddItemToRender;
};

const DropdownListItem = memo<Props>((props: Props) => {
	const { id, icon, label, onClick, testId } = props.item;
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onItemClick = useCallback(() => {
		const analyticsEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'dropdownItem',
		});
		fireUIAnalytics(analyticsEvent, dropDownListItemSubjectId, {
			actionItemId: id,
			name: dropDownListItemSubject,
		});
		onClick && onClick();
	}, [id, onClick, createAnalyticsEvent]);

	return (
		<DropdownItem elemBefore={icon} onClick={onItemClick} data-testid={testId}>
			{label}
		</DropdownItem>
	);
});

export default DropdownListItem;
