import { createSelector } from 'reselect';
import { WATCHES } from '@atlassian/jira-issue-view-configurations/src/index.tsx';
import { fieldSelector } from '../../../common/state/selectors/field-selector.tsx';

const watchesSelector = fieldSelector(WATCHES);

const watchesValueSelector = createSelector(watchesSelector, (watches) => watches?.value);

export const isUserWatchingSelector = createSelector(
	watchesValueSelector,
	(watchesValue) => watchesValue?.isWatching,
);

export const watchersCountSelector = createSelector(
	watchesValueSelector,
	(watchesValue) => watchesValue?.watchCount,
);
