/**
 * @generated SignedSource<<9eac824a5d526dbdcbe1b4f030e7c4b8>>
 * @relayHash bac56b5a15fe2bdd371f393834598764
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 5b9b5e7ad81080c3ef0caaf8a1945246c94639ab4dd9c2bfabbb0adde00cb670

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type JiraSingleValueFieldOperations = "SET" | "%future added value";
export type JiraUpdateNumberFieldInput = {
  id: string;
  operation: JiraNumberFieldOperationInput;
};
export type JiraNumberFieldOperationInput = {
  number?: number | null | undefined;
  operation: JiraSingleValueFieldOperations;
};
export type number_issueFieldNumber_NumberField_Mutation$variables = {
  input: JiraUpdateNumberFieldInput;
};
export type number_issueFieldNumber_NumberField_Mutation$data = {
  readonly jira: {
    readonly updateNumberField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly id: string;
        readonly number: number | null | undefined;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type number_issueFieldNumber_NumberField_Mutation$rawResponse = {
  readonly jira: {
    readonly updateNumberField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly id: string;
        readonly number: number | null | undefined;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type number_issueFieldNumber_NumberField_Mutation = {
  rawResponse: number_issueFieldNumber_NumberField_Mutation$rawResponse;
  response: number_issueFieldNumber_NumberField_Mutation$data;
  variables: number_issueFieldNumber_NumberField_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "concreteType": "JiraMutation",
    "kind": "LinkedField",
    "name": "jira",
    "plural": false,
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "JiraNumberFieldPayload",
        "kind": "LinkedField",
        "name": "updateNumberField",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "name": "success"
          },
          {
            "concreteType": "MutationError",
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "message"
              }
            ]
          },
          {
            "concreteType": "JiraNumberField",
            "kind": "LinkedField",
            "name": "field",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "id"
              },
              {
                "kind": "ScalarField",
                "name": "number"
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "number_issueFieldNumber_NumberField_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "number_issueFieldNumber_NumberField_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "5b9b5e7ad81080c3ef0caaf8a1945246c94639ab4dd9c2bfabbb0adde00cb670",
    "metadata": {},
    "name": "number_issueFieldNumber_NumberField_Mutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "229bc4c46fc83a0e7aaf848fb3a4b543";

export default node;
