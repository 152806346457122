import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import { SpotlightTarget } from '@atlaskit/onboarding';
import { token } from '@atlaskit/tokens';
import PerformanceMark from '@atlassian/jira-common-performance/src/set-performance-mark.tsx';
import { GIVE_FEEDBACK } from '@atlassian/jira-issue-view-common-constants/src/view-constants.tsx';
import ModalActionsCloseButton from '../../modal-close-button.tsx';
import {
	HEADER_ACTIONS_COMPACT_BEGIN_MARK_KEY,
	HEADER_ACTIONS_COMPACT_END_MARK_KEY,
} from '../constants.tsx';

/**
 * This is the modal header that shows for an issue view modal when there is an error
 */
export const ErrorHeader = ({
	onClose,
	renderFeedback,
}: {
	onClose: () => void;
	renderFeedback?: () => ReactNode;
}) => {
	let renderFeedbackWithWrapper;
	if (renderFeedback) {
		const feedback = renderFeedback();
		renderFeedbackWithWrapper = <SpotlightTarget name={GIVE_FEEDBACK}>{feedback}</SpotlightTarget>;
	}
	return (
		<>
			<PerformanceMark metricKey={HEADER_ACTIONS_COMPACT_BEGIN_MARK_KEY} />
			<HeaderButtonsContainer>
				{renderFeedbackWithWrapper}
				<ModalActionsCloseButton onClose={onClose} />
			</HeaderButtonsContainer>
			<PerformanceMark metricKey={HEADER_ACTIONS_COMPACT_END_MARK_KEY} />
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderButtonsContainer = styled.div({
	display: 'flex',
	justifyContent: 'flex-end',
	width: '100%',
	gap: token('space.100', '8px'),
});
