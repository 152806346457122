import React from 'react';

import type { IntlShape, MessageDescriptor } from 'react-intl-next';

import { fg } from '@atlaskit/platform-feature-flags';

import { CONFIG_ITEM_KEYS } from '../../../config-items/config-item-keys';
import { createEditorPluginAIConfigItemMarkdown } from '../../../config-items/config-items';
import { Icon as PageIcon } from '../../assets/icons/issueReformatter';
import type { IconShownAt } from '../../assets/icons/types';
import { createInsertBelow, createReplace } from '../../config-item-actions/markdown';
import { isMinLength } from '../../config-item-visible/visibility-utils';

import { messages } from './messages';

const SELECTION_TYPE = 'range';

export type GetAdditionalProps = () => Partial<{
	issue_summary?: string;
	issue_type_label?: string;
}>;

function createOption({
	key,
	title,
	description,
	icon,
	getAdditionalProps,
}: {
	key: CONFIG_ITEM_KEYS;
	title: MessageDescriptor;
	description: MessageDescriptor;
	icon?: ({
		formatMessage,
		shownAt,
	}: {
		formatMessage: IntlShape['formatMessage'];
		shownAt: IconShownAt;
	}) => JSX.Element;
	getAdditionalProps: GetAdditionalProps;
}) {
	return createEditorPluginAIConfigItemMarkdown({
		key,
		title,
		description,
		selectionType: SELECTION_TYPE,
		intentSchemaId: 'issue_reformatter_intent_schema.json',
		getAdditionalContext: () => {
			return getAdditionalProps?.() || {};
		},
		isVisible: ({ editorView, positions }) => isMinLength({ editorView, minLength: 1, positions }),
		icon,
		actions: [
			createInsertBelow({ appearance: 'secondary' }),
			createReplace({ appearance: 'primary' }),
		],
		getBackendModel: () => 'assistance-service',
		...(fg('platform_gravityai-1853_issue_reformatter_ga') ? {} : { statusLozengeType: 'beta' }),
	});
}

type Options = {
	getAdditionalProps: GetAdditionalProps;
};

export const reformatWithTemplateConfigItem = (options: Options) =>
	createOption({
		key: CONFIG_ITEM_KEYS.ADD_STRUCTURE,
		title: messages.improveDescription,
		description: messages.reformatWithTemplateDescription,
		icon: ({ shownAt }) => <PageIcon shownAt={shownAt} />,
		getAdditionalProps: options.getAdditionalProps,
	});
