import memoizeOne from 'memoize-one';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import type { SaveFieldArguments } from '@atlassian/jira-issue-view-common-types/src/connect-field-type.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button/index.tsx';
import connectField from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-field.tsx';
import { updateRequestLanguage } from '@atlassian/jira-issue-view-services/src/issue/request-language-server.tsx';
import { issueIdSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import { fieldAllowedValuesSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector.tsx';
import { isRequestLanguageVisibleSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-visibility-selector.tsx';
import messages from './messages.tsx';
import {
	type StateValue,
	transformFromStateValue,
	transformToStateValue,
	transformSuggestions,
} from './transformer.tsx';
import WrappedRequestLanguageField from './view.tsx';

const fetchSuggestionsFactory = memoizeOne(
	(suggestions: StateValue[], intl: IntlShape) => () =>
		Promise.resolve(transformSuggestions(suggestions, intl)),
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const saveField = ({ baseUrl, value }: SaveFieldArguments<any>, state: State) => {
	const { languageCode } = value;
	const issueId = issueIdSelector(state);
	return updateRequestLanguage(baseUrl, issueId, languageCode);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const analyticsAttributeIdRetriever = (value: any): string => value && value.languageCode;

export default connectField((stateOnMount, ownPropsOnMount) => ({
	fieldId: ownPropsOnMount.fieldId,
	transformFromStateValue,
	transformToStateValue,
	saveField,
	additionalProps: (state, intl) => ({
		fetchSuggestions: fetchSuggestionsFactory(
			fieldAllowedValuesSelector(ownPropsOnMount.fieldId)(state),
			intl,
		),
		shouldHide: !isRequestLanguageVisibleSelector(ownPropsOnMount.fieldId)(state),
		hasAutocomplete: true,
		placeholder: intl.formatMessage(messages.placeholder),
		analyticsAttributeIdRetriever,
		showPinButton: getShowPinButton(ownPropsOnMount.area),
	}),
}))(WrappedRequestLanguageField);
