import React, { type ReactElement, useCallback, useMemo } from 'react';
import isNil from 'lodash/isNil';
import Avatar from '@atlaskit/avatar';
import { ForgeScreenEvent } from '@atlassian/jira-forge-ui-analytics/src/common/ui/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type {
	FetchSuggestions,
	SelectValueShape,
} from '@atlassian/jira-issue-internal-field-select/src/common/select-inline-edit/select-field/types.tsx';
import {
	OptionContainer,
	OptionLabel,
} from '@atlassian/jira-issue-internal-field-select/src/common/styled.tsx';
import MultiSelectInlineEditView from '@atlassian/jira-issue-internal-field-select/src/multi-select-inline-edit/index.tsx';
import { genericMessages } from '@atlassian/jira-issue-view-common-constants/src/context-items-messages.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { fetchSuggestionsFactory } from '../../../../../../multi-user/index.tsx';
import messages from '../../../../../../multi-user/messages.tsx';
import {
	transformFromStateValue,
	transformToStateValue,
	type StateUser,
} from '../../../../../../multi-user/transformer.tsx';
import { useAnalyticsAttributesContext } from '../../../analytics/atrributes-context/index.tsx';
import type { CustomFieldProps } from '../../../types.tsx';

export type Props = CustomFieldProps<StateUser[]> & {
	fetchSuggestions: FetchSuggestions;
};

// @ts-expect-error - TS2749 - 'Option' refers to a value, but is being used as a type here. Did you mean 'typeof Option'?
const formatOptionLabel = (option: Option) => (
	<OptionContainer>
		{!isNil(option.avatarUrl) ? (
			<Avatar
				size="small"
				{...(isVisualRefreshEnabled() ? { borderColor: 'transparent' } : {})}
				src={option.avatarUrl}
			/>
		) : null}
		<OptionLabel>{option.label}</OptionLabel>
	</OptionContainer>
);

export const View = ({
	shouldFireScreenEvent,
	extension,
	type,
	value,
	onChange,
	customReadView,
	...editProps
}: CustomFieldProps<StateUser[]>) => {
	const { formatMessage } = useIntl();

	const onChangeWithTransformation: (arg1: SelectValueShape[]) => void = useCallback(
		(newValue) => onChange(transformToStateValue(newValue)),
		[onChange],
	);

	const customReadViewWithTransformation = useCallback<
		(val: undefined | null | SelectValueShape[] | SelectValueShape) => ReactElement
	>(
		(val) => {
			if (!isNil(customReadView)) {
				if (val && 'length' in val) {
					return customReadView(transformToStateValue(val));
				}
			}
			return <></>;
		},

		[customReadView],
	);

	const optionalCustomReadView = !isNil(customReadView)
		? customReadViewWithTransformation
		: undefined;

	const analyticsAttributes = useAnalyticsAttributesContext();
	const attributes = useMemo(
		() => ({ ...analyticsAttributes, renderMode: 'default' }),
		[analyticsAttributes],
	);

	const issueKey = useIssueKey();

	return (
		<>
			{shouldFireScreenEvent && <ForgeScreenEvent attributes={attributes} />}
			<MultiSelectInlineEditView
				{...editProps}
				value={transformFromStateValue(value)}
				customReadView={optionalCustomReadView}
				placeholder={formatMessage(messages.placeholder)}
				noValueText={formatMessage(genericMessages.noValue)}
				onChange={onChangeWithTransformation}
				tagAppearance="rounded"
				formatOptionLabel={formatOptionLabel}
				fetchSuggestions={fetchSuggestionsFactory(issueKey)}
			/>
		</>
	);
};

export default View;
