import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { useEditField } from '@atlassian/jira-issue-field-base/src/services/edit-field-service/main.tsx';
import { useFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import type { FieldConfiguration } from '@atlassian/jira-issue-field-base/src/services/field-config-service/types.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { JiraDatePickerValue } from '../../common/types.tsx';

export type UseDateFieldOptions = {
	issueKey: IssueKey;
	fieldKey: string;
	initialValue?: JiraDatePickerValue;
	onSuccess?: (newValue: JiraDatePickerValue) => void;
};

export const useDateField = ({
	issueKey,
	fieldKey,
	initialValue,
	onSuccess,
}: UseDateFieldOptions): [
	{
		loading: boolean;
		value: JiraDatePickerValue;
		error: Error | null;
		fieldConfig: FieldConfiguration<JiraDatePickerValue> | null;
	},
	{
		saveValue: (
			value: JiraDatePickerValue,
			meta: null,
			analyticsEvent: UIAnalyticsEvent,
		) => Promise<void>;
		resetError: () => void;
	},
] => {
	// useFieldConfig now returns an object { value, loading } instead of just the fieldConfig value
	// if possible when touching this, try and refactor to pass the new object instead of just the value
	const [{ value: fieldConfig }] = useFieldConfig(issueKey, fieldKey);

	const fieldType = fieldConfig?.schema.type ?? 'date';

	const [{ loading, value, error }, { saveValue, resetError }] = useEditField<
		JiraDatePickerValue,
		null
	>({
		fieldKey,
		issueKey,
		fieldType,
		initialValue: initialValue || null,
		onSuccess,
	});

	return [
		{ loading, value, error, fieldConfig },
		{ saveValue, resetError },
	];
};
