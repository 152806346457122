import type { ComponentType } from 'react';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { flowWithSafeComponent } from '@atlassian/jira-issue-view-common-utils/src/flow-with-safe-component/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { isServiceDeskSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import { FallbackAddCommentEditor, type OwnProps, type StateProps } from './view.tsx';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default flowWithSafeComponent(
	connect(
		(state: State): StateProps => ({
			isServiceDesk: isServiceDeskSelector(state),
		}),
		{},
	),
)(FallbackAddCommentEditor) as ComponentType<OwnProps>;
