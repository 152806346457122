import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/switchMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import {
	UNAUTHORIZED,
	FORBIDDEN,
	NOT_FOUND,
} from '@atlassian/jira-common-constants/src/http-status-codes.tsx';
import { fetchIssueViewScreenId } from '@atlassian/jira-issue-fetch-services/src/services/issue-view-screenid/index.tsx';
import {
	AUTHENTICATION_ERROR,
	CONNECTIVITY_ERROR,
	NOT_FOUND_OR_NO_PERMISSION_ERROR,
	UNKNOWN_ERROR,
} from '@atlassian/jira-issue-shared-types/src/common/types/error-type.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { trackOrLogClientError } from '@atlassian/jira-issue-view-common-utils/src/errors/index.tsx';
import {
	FETCH_SCREENID_REQUEST,
	fetchScreenIdSuccess,
	fetchScreenIdFailure,
	type FetchScreenIdRequestAction,
} from '@atlassian/jira-issue-view-store/src/common/actions/issue-screenid-actions.tsx';
import {
	accountIdloggedInUserSelector,
	baseUrlSelector,
	issueKeySelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';

// @ts-expect-error - TS2304 - Cannot find name 'AccountId'.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const transformError = (statusCode: any, accountId: null | AccountId) => {
	let error = UNKNOWN_ERROR;

	if (statusCode === UNAUTHORIZED) {
		// @ts-expect-error - TS2322 - Type '"AUTHENTICATION_ERROR"' is not assignable to type '"UNKNOWN_ERROR"'.
		error = AUTHENTICATION_ERROR;
	} else if (statusCode === FORBIDDEN) {
		// @ts-expect-error - TS2322 - Type '"NOT_FOUND_OR_NO_PERMISSION_ERROR"' is not assignable to type '"UNKNOWN_ERROR"'.
		error = NOT_FOUND_OR_NO_PERMISSION_ERROR;
	} else if (statusCode === NOT_FOUND) {
		// @ts-expect-error - TS2322 - Type '"NOT_FOUND_OR_NO_PERMISSION_ERROR"' is not assignable to type '"UNKNOWN_ERROR"'.
		error = NOT_FOUND_OR_NO_PERMISSION_ERROR;
	} else if (!statusCode) {
		// @ts-expect-error - TS2322 - Type '"CONNECTIVITY_ERROR"' is not assignable to type '"UNKNOWN_ERROR"'.
		error = CONNECTIVITY_ERROR;
	} else if (!accountId) {
		// @ts-expect-error - TS2322 - Type '"AUTHENTICATION_ERROR"' is not assignable to type '"UNKNOWN_ERROR"'.
		error = AUTHENTICATION_ERROR;
	}

	return {
		error,
		statusCode,
	};
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	action$: ActionsObservable<FetchScreenIdRequestAction>,
	store: MiddlewareAPI<State>,
) =>
	action$.ofType(FETCH_SCREENID_REQUEST).switchMap(() => {
		const state = store.getState();

		const loggedInAccountId = accountIdloggedInUserSelector(state);
		const baseUrl = baseUrlSelector(state);
		const issueKey = issueKeySelector(state);
		return (
			// @ts-expect-error - TS2684 - The 'this' context of type 'Observable<unknown>' is not assignable to method's 'this' of type 'Observable<ScreenIdData>'.
			fetchIssueViewScreenId(baseUrl, issueKey)
				/*
            Cannot call `fetchIssueViewScreenId(...).map` with `fetchScreenIdSuccess` bound to `f` because inexact  mixed [1] is incompatible with exact  `ScreenIdData` [2] in the first argument.Flow(InferError)
            as-json-stream.js(31, 78): [1] mixed
            issue-screenid-actions.js(25, 52): [2] `ScreenIdData`
        */
				.map(fetchScreenIdSuccess)
				.catch((error) => {
					const logMessage = 'FETCH_ISSUE_REQUEST: Failed to fetch issue data';
					const transformedError = transformError(error.statusCode, loggedInAccountId);

					// @ts-expect-error - TS2367 - This condition will always return 'false' since the types '"UNKNOWN_ERROR"' and '"NOT_FOUND_OR_NO_PERMISSION_ERROR"' have no overlap.
					if (transformedError.error === NOT_FOUND_OR_NO_PERMISSION_ERROR) {
						// TODO send metrics SignalFx -> issue not found (BENTO-5187)
					} else {
						trackOrLogClientError('issue.fetch.issue-screenid-epic', logMessage, error);
					}

					return Observable.of(fetchScreenIdFailure(transformedError));
				})
		);
	});
