import React from 'react';
import HtmlView from '@atlassian/jira-common-components-html-view/src/html-view.tsx';
import { EditorContainer } from '../view.tsx';

export type Props = {
	bodyHtml?: string;
};

/**
 * This shows the Preview mode for comments.
 * All other usages of the Edtior and Adf content (e.g. description, rich text custom fields) don't need to use <HtmlView> for fallback because they are have SSR support.
 * Comments have SSR support as well now, but "common.error-boundary.issue.issue-comment-editor" errors still happen quite often, so we still need this component as a fallback in ErrorBoundary
 */
export const PreviewCommentContent = ({ bodyHtml }: Props) => (
	<EditorContainer>
		<HtmlView html={bodyHtml} />
	</EditorContainer>
);
