/**
 * @generated SignedSource<<7c9f5a09cecf989dc25b908e83cf3893>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type parent_issueFieldParentInlineEditFull_ParentInlineEditViewIsEditable_fragmentRef$data = {
  readonly fieldId: string;
  readonly id: string;
  readonly name: string;
  readonly parentIssue: {
    readonly fieldsById: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly fieldId?: string;
          readonly issueType?: {
            readonly avatar: {
              readonly xsmall: string | null | undefined;
            } | null | undefined;
            readonly hierarchy: {
              readonly level: number | null | undefined;
            } | null | undefined;
            readonly issueTypeId: string | null | undefined;
            readonly name: string;
          } | null | undefined;
          readonly status?: {
            readonly statusCategory: {
              readonly statusCategoryId: string;
            } | null | undefined;
          };
          readonly text?: string | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
    readonly id: string;
    readonly issueColorField: {
      readonly color: {
        readonly colorKey: string | null | undefined;
      } | null | undefined;
    } | null | undefined;
    readonly issueId: string;
    readonly key: string;
    readonly webUrl: AGG$URL | null | undefined;
  } | null | undefined;
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"parent_issueFieldParentReadviewFull_ParentReadView">;
  readonly " $fragmentType": "parent_issueFieldParentInlineEditFull_ParentInlineEditViewIsEditable_fragmentRef";
};
export type parent_issueFieldParentInlineEditFull_ParentInlineEditViewIsEditable_fragmentRef$key = {
  readonly " $data"?: parent_issueFieldParentInlineEditFull_ParentInlineEditViewIsEditable_fragmentRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"parent_issueFieldParentInlineEditFull_ParentInlineEditViewIsEditable_fragmentRef">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "id"
},
v1 = {
  "kind": "ScalarField",
  "name": "fieldId"
},
v2 = {
  "kind": "ScalarField",
  "name": "name"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "parent_issueFieldParentInlineEditFull_ParentInlineEditViewIsEditable_fragmentRef",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "parent_issueFieldParentReadviewFull_ParentReadView"
    },
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "kind": "ScalarField",
      "name": "type"
    },
    (v2/*: any*/),
    {
      "concreteType": "JiraIssue",
      "kind": "LinkedField",
      "name": "parentIssue",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "name": "issueId"
        },
        {
          "kind": "ScalarField",
          "name": "webUrl"
        },
        {
          "kind": "ScalarField",
          "name": "key"
        },
        {
          "concreteType": "JiraColorField",
          "kind": "LinkedField",
          "name": "issueColorField",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraColor",
              "kind": "LinkedField",
              "name": "color",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "colorKey"
                }
              ]
            }
          ]
        },
        {
          "args": [
            {
              "kind": "Literal",
              "name": "ids",
              "value": [
                "summary",
                "issuetype",
                "status"
              ]
            }
          ],
          "concreteType": "JiraIssueFieldConnection",
          "kind": "LinkedField",
          "name": "fieldsById",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraIssueFieldEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v1/*: any*/),
                        {
                          "kind": "ScalarField",
                          "name": "text"
                        }
                      ],
                      "type": "JiraSingleLineTextField"
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v1/*: any*/),
                        {
                          "concreteType": "JiraIssueType",
                          "kind": "LinkedField",
                          "name": "issueType",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "ScalarField",
                              "name": "issueTypeId"
                            },
                            (v2/*: any*/),
                            {
                              "concreteType": "JiraAvatar",
                              "kind": "LinkedField",
                              "name": "avatar",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "ScalarField",
                                  "name": "xsmall"
                                }
                              ]
                            },
                            {
                              "concreteType": "JiraIssueTypeHierarchyLevel",
                              "kind": "LinkedField",
                              "name": "hierarchy",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "ScalarField",
                                  "name": "level"
                                }
                              ]
                            }
                          ]
                        }
                      ],
                      "type": "JiraIssueTypeField"
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v1/*: any*/),
                        {
                          "concreteType": "JiraStatus",
                          "kind": "LinkedField",
                          "name": "status",
                          "plural": false,
                          "selections": [
                            {
                              "concreteType": "JiraStatusCategory",
                              "kind": "LinkedField",
                              "name": "statusCategory",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "ScalarField",
                                  "name": "statusCategoryId"
                                }
                              ]
                            }
                          ]
                        }
                      ],
                      "type": "JiraStatusField"
                    }
                  ]
                }
              ]
            }
          ],
          "storageKey": "fieldsById(ids:[\"summary\",\"issuetype\",\"status\"])"
        }
      ]
    }
  ],
  "type": "JiraParentIssueField"
};
})();

(node as any).hash = "ec789f875759fa2d778c7073865af90a";

export default node;
