/* eslint-disable @atlassian/relay/graphql-naming */
import { useCallback, useMemo } from 'react';
import { graphql, useMutation } from 'react-relay';
import { useIssueId } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useIssueViewFieldUpdateEvents } from '@atlassian/jira-issue-view-field-update-events/src/services/issue-view-field-update-events/index.tsx';
import type { useWatchesMutation_Mutation } from '@atlassian/jira-relay/src/__generated__/useWatchesMutation_Mutation.graphql';
import type { useWatchesMutation_Mutation_Updatable$key } from '@atlassian/jira-relay/src/__generated__/useWatchesMutation_Mutation_Updatable.graphql';

type AggJiraWatches = {
	readonly count: number | null | undefined;
	readonly isWatching: boolean | null | undefined;
};
const __typename = 'JiraWatchesField';

type WatchesField = {
	type: string;
	fieldId: string;
	watch: AggJiraWatches;
};

export const useWatchesMutation = () => {
	const issueId = useIssueId();

	const [, { fieldChanged, fieldChangeFailed, fieldChangeRequested }] =
		useIssueViewFieldUpdateEvents();

	const onSubmit = useCallback(
		(type: string, fieldId: string, value: AggJiraWatches) => {
			issueId &&
				fieldChangeRequested(issueId, fieldId, value, undefined, {
					type,
					__typename,
				});
		},
		[fieldChangeRequested, issueId],
	);

	const onSubmitSucceeded = useCallback(
		(type: string, fieldId: string, value: AggJiraWatches) => {
			issueId &&
				fieldChanged(issueId, fieldId, value, {
					type,
					__typename,
				});
		},
		[fieldChanged, issueId],
	);

	const onSubmitFailed = useCallback(
		(type: string, fieldId: string) => issueId && fieldChangeFailed(issueId, fieldId),
		[fieldChangeFailed, issueId],
	);

	const [commit] = useMutation<useWatchesMutation_Mutation>(graphql`
		mutation useWatchesMutation_Mutation($input: JiraUpdateWatchesFieldInput!) @raw_response_type {
			jira @optIn(to: "JiraIssueFieldMutations") {
				updateWatchesField(input: $input) {
					success
					errors {
						message
					}
					field {
						...addWatchers_issueViewWatchers_AddWatchersRelay
						...changeWatchState_issueViewWatchers_ChangeWatchWithRelay
						...watchersList_issueViewWatchers_WatchersListRelay
						...actionButton_issueViewWatchers_ActionButtonWithRelay
					}
				}
			}
		}
	`);

	const onUpdate = useCallback(
		({
			id,
			userAri,
			isWatching,
			onSuccess,
			onError,
			field,
			watch,
		}: {
			id: string; // Field ari
			userAri?: string;
			isWatching: boolean;
			onSuccess?: () => void;
			onError?: (error: Error) => void;
			field: WatchesField;
			watch: useWatchesMutation_Mutation_Updatable$key | undefined | null;
		}) => {
			const newWatch: AggJiraWatches = {
				...field.watch,
				isWatching: !userAri ? isWatching : field.watch.isWatching,
				count: isWatching ? (field?.watch?.count ?? 0) + 1 : (field?.watch?.count ?? 0) - 1,
			};

			const handleError = (error: Error) => {
				onSubmitFailed(field.type, field.fieldId);
				onError?.(error);
			};

			onSubmit(field.type, field.fieldId, newWatch);
			commit({
				variables: {
					input: {
						id,
						operation: {
							operation: isWatching ? 'ADD' : 'REMOVE',
							id: userAri || undefined,
						},
					},
				},
				onCompleted: (response) => {
					if (response.jira?.updateWatchesField?.success) {
						onSubmitSucceeded(field.type, field.fieldId, newWatch);
						onSuccess?.();
					} else {
						handleError?.(
							new Error(
								response.jira?.updateWatchesField?.errors?.[0]?.message ||
									'Could not update watchers',
							),
						);
					}
				},
				onError: (error) => {
					handleError?.(error);
				},
				optimisticUpdater: (store) => {
					if (!watch) {
						return null;
					}
					const { updatableData } =
						store.readUpdatableFragment<useWatchesMutation_Mutation_Updatable$key>(
							// eslint-disable-next-line @atlassian/relay/must-use-inline
							graphql`
								fragment useWatchesMutation_Mutation_Updatable on JiraWatch @updatable {
									isWatching
									count
								}
							`,
							watch,
						);
					if (!userAri) {
						updatableData.isWatching = isWatching;
					}
					const previousCount = updatableData.count || 0;
					updatableData.count = isWatching ? previousCount + 1 : previousCount - 1;
				},
			});
		},
		[commit, onSubmit, onSubmitFailed, onSubmitSucceeded],
	);

	return useMemo(() => ({ onUpdate }), [onUpdate]);
};
