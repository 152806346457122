import React, { useCallback } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import EditIcon from '@atlaskit/icon/core/migration/edit';
import { Pressable, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import type { StatusCategory } from '@atlassian/jira-common-constants/src/status-categories.tsx';
import { asyncInlineEditStatusModal } from '@atlassian/jira-inline-edit-status-modal/entrypoint.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { defaultModalProps } from '@atlassian/jira-issue-status-and-types-common/src/index.tsx';
import { ISSUE_VIEW_SOURCE } from '@atlassian/jira-issue-status-and-types-common/src/types.tsx';
import { ModalEntryPointPressableTrigger } from '@atlassian/jira-modal-entry-point-pressable-trigger/src/ModalEntryPointPressableTrigger.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { fg } from '@atlassian/jira-feature-gating';
import { editStatusExperience } from '../../../experiences.tsx';
import { messages } from './messages.tsx';

type Props = {
	status: {
		id: string;
		statusName: string;
		statusCategory: StatusCategory;
	};
	projectId: string;
	projectKey: string;
	issueTypeId: string;
	statusNames?: string[];
	onClick: () => void;
	onClose: () => void;
	onStatusUpdated?: () => Promise<void>;
};

export const EditStatusButton = ({
	status,
	projectId,
	projectKey,
	issueTypeId,
	statusNames = [],
	onClick,
	onClose,
	onStatusUpdated,
}: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();

	const onButtonClick = (
		event: React.MouseEvent<HTMLElement, MouseEvent>,
		analyticsEvent: UIAnalyticsEvent,
	) => {
		onClick();
		event.stopPropagation(); // prevents transition from being selected
		fireUIAnalytics(analyticsEvent, 'editStatusButton');
	};

	const onCloseModal = useCallback(() => {
		onClose();
		fireUIAnalytics(
			createAnalyticsEvent({
				actionSubject: 'inlineEditStatusModal',
				action: 'closed',
			}),
		);
	}, [createAnalyticsEvent, onClose]);

	const entryPointProps = {
		onClose: onCloseModal,
		status,
		issueTypeId,
		projectId,
		projectKey,
		editStatusExperience,
		statusNames,
		source: ISSUE_VIEW_SOURCE,
		onStatusUpdated,
	};

	return (
		<ModalEntryPointPressableTrigger
			entryPoint={asyncInlineEditStatusModal}
			entryPointProps={entryPointProps}
			interactionName="edit-status-modal"
			modalProps={defaultModalProps}
			useInternalModal
		>
			{({ ref }) => (
				<Pressable
					tabIndex={0}
					xcss={iconButtonStyles}
					onClick={onButtonClick}
					aria-label={formatMessage(messages.statusNameButtonLabel, {
						statusName: status.statusName,
					})}
					data-show-on-hover
					// ref returned from entrypoint is of Type 'RefObject<HTMLElement>' is not assignable to type 'Ref<HTMLButtonElement>
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					ref={ref as React.Ref<HTMLButtonElement>}
				>
					<Tooltip
						content={formatMessage(
							fg('update_edit_button_tooltip_text')
								? messages.editStatusTooltip
								: messages.statusEditNameTooltip,
						)}
						position="bottom"
					>
						<EditIcon label="" LEGACY_size="small" color={token('color.icon')} />
					</Tooltip>
				</Pressable>
			)}
		</ModalEntryPointPressableTrigger>
	);
};

const iconButtonStyles = xcss({
	borderRadius: 'border.radius',
	outlineOffset: 'space.0',
	padding: 'space.025',
	marginInline: 'space.100',
	opacity: 0,
	transition: 'opacity 0.3s ease',
	':focus': {
		boxShadow: 'elevation.shadow.overflow',
		opacity: 1,
	},
	':hover': {
		backgroundColor: 'color.background.neutral.subtle.hovered',
		opacity: 1,
	},
});
