import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/from';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/switchMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { fireTrackAnalytics } from '@atlassian/jira-analytics-web-react/src/utils/fire-track-event.tsx';
import type { AssociatedIssuesContextActions } from '@atlassian/jira-associated-issues-context-service/src/actions.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { extractProjectKey } from '@atlassian/jira-issue-fetch-services-common/src/common/utils/extract-project-key.tsx';
import { fetchIssueGraphQlData } from '@atlassian/jira-issue-fetch-services/src/services/issue-graphql-data/index.tsx';
import type { FieldConfigServiceActions } from '@atlassian/jira-issue-field-base/src/services/field-config-service/types.tsx';
import type { FieldValueServiceActions } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import type { FieldsState } from '@atlassian/jira-issue-shared-types/src/common/types/field-type.tsx';
import type { NextGenChildIssue } from '@atlassian/jira-issue-view-common-types/src/issue-server-type.tsx';
import { extractFromObject } from '@atlassian/jira-issue-view-common-utils/src/utils/utils.tsx';
import { transformChild } from '@atlassian/jira-issue-view-services/src/issue/child-issue-transformer-new.tsx';
import { getGraphQlFields } from '@atlassian/jira-issue-view-services/src/issue/issue-transformer.tsx';
import { toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import {
	getBaseUrl,
	getChildIssuesPanelType,
	getIsSimplifiedProject,
} from '../../state/context/selectors.tsx';
import {
	LINK_CHILD_SUCCESS,
	type LinkChildSuccessAction,
	fetchSingleChildSuccess,
} from '../../state/entities/actions.tsx';
import type { State } from '../../state/types.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
		associatedIssuesContextActions?: AssociatedIssuesContextActions,
		fieldsValuesActions?: FieldValueServiceActions,
		fieldConfigActions?: FieldConfigServiceActions,
	) =>
	(action$: ActionsObservable<LinkChildSuccessAction>, store: MiddlewareAPI<State>) =>
		action$
			.ofType(LINK_CHILD_SUCCESS)
			.do(({ payload: { linkedChild }, meta }) => {
				const state = store.getState();
				const panelType = getChildIssuesPanelType(state);
				const attributes = {
					action: 'linked',
					actionSubjectId: linkedChild.id,
					attributes: {
						isInlineChildAdded: true,
						panelType,
					},
				};
				fireTrackAnalytics(meta.analyticsEvent, attributes);
			})
			.switchMap(({ payload: { linkedChild } }) => {
				const state = store.getState();
				const baseUrl = getBaseUrl(state);

				const issueKey =
					linkedChild.issueKey !== undefined && linkedChild.issueKey !== null
						? toIssueKey(linkedChild.issueKey)
						: undefined;
				const projectKey = issueKey !== undefined ? extractProjectKey(issueKey) : undefined;

				if (!issueKey || !projectKey) {
					const errorMessage = 'Issue or project key missing when handling link child success';
					log.safeErrorWithoutCustomerData(
						'issue.views.common.child-issues-panel.link-child-success.missing-key',
						errorMessage,
						new Error(errorMessage),
					);
					return Observable.empty<never>();
				}

				return Observable.from(fetchIssueGraphQlData(baseUrl, issueKey, projectKey))
					.map(({ data: graphqlData }) => {
						const fieldsData: FieldsState = getGraphQlFields(graphqlData);
						const issue: NextGenChildIssue = {
							id: graphqlData.issue.id,
							key: issueKey,
							self: '',
							fields: Object.values(fieldsData),

							// Added to satisfy Flow; `transformChild` will not use these fields
							agile: {},
							customFields: {
								textareaAdf: [],
							},
							systemFields: {},
						};
						const transformedData = transformChild(issue, getIsSimplifiedProject(state));

						associatedIssuesContextActions &&
							associatedIssuesContextActions.mergeLocalAssociatedIssuesContext({
								[issueKey]: {
									estimateFieldId: transformedData.estimateFieldId,
									isResolved: transformedData.isResolved ?? false,
								},
							});

						if (fieldsData && fieldsValuesActions && fieldConfigActions) {
							const { fieldValues, fieldConfigs } = extractFromObject(fieldsData);
							fieldsValuesActions.setIssue(issueKey, fieldValues);
							fieldConfigActions.setIssueConfig(issueKey, fieldConfigs);
						}

						return fetchSingleChildSuccess(issue);
					})
					.catch((error) => {
						log.safeErrorWithoutCustomerData(
							'issue.views.common.child-issues-panel.link-child-success.fetch-single-issue',
							'Failed to fetch single issue after a successful child issue linking',
							error,
						);
						return Observable.empty<never>();
					});
			});
