import type {
	GiraEcosystemOperation,
	GiraEcosystemBackgroundScript,
} from '@atlassian/jira-issue-fetch-services/src/services/issue-gira-non-critical-data/types.tsx';
import type { EcosystemBackgroundScript } from '@atlassian/jira-issue-gira-transformer-types/src/common/types/ecosystem.tsx';
import type { EcosystemOperation } from '@atlassian/jira-issue-view-ecosystem-service/src/services/types.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const transformIFrameOptions = (serverOptions: string | any) =>
	typeof serverOptions === 'string' ? JSON.parse(serverOptions) : serverOptions;

export const transformEcosystemOperations = (operations: GiraEcosystemOperation[] | undefined) => {
	if (!operations) {
		return [];
	}

	return operations.map(
		({ name, url, tooltip, styleClass }: GiraEcosystemOperation): EcosystemOperation => ({
			name: name ?? '',
			url: url ?? '',
			tooltip: tooltip ?? undefined,
			styleClass: styleClass ?? undefined,
		}),
	);
};

export const transformEcosystemBackgroundScripts = (
	backgroundScripts: GiraEcosystemBackgroundScript[] | undefined,
) => {
	if (!backgroundScripts) {
		return [];
	}

	return backgroundScripts.map(
		({
			iframe,
			shouldReloadOnRefresh,
		}: GiraEcosystemBackgroundScript): EcosystemBackgroundScript => ({
			appKey: iframe?.addonKey ?? '',
			moduleKey: iframe?.moduleKey ?? '',
			options: transformIFrameOptions(iframe?.options),
			shouldReloadOnRefresh,
		}),
	);
};
