import React, { type ReactNode, useState, useEffect, useRef, type ReactElement } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import { MenuGroup, Section } from '@atlaskit/menu';
import Popup from '@atlaskit/popup';
import { token } from '@atlaskit/tokens';
import { layers, gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import type { DropdownTriggerParams } from '@atlassian/jira-development-summary-common/src/common/types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { FormattedI18nMessage } from '@atlassian/jira-formatted-i18n-message/src/ui/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { CopyCommitCommand } from './copy-commit-command/index.tsx';
import { CopyIssueKey } from './copy-issue-key/index.tsx';
import messages from './messages.tsx';

export type Props = {
	getDropdownTrigger: (props: DropdownTriggerParams) => ReactElement;
	onOpenDropdown?: (arg1: { isOpen: boolean }) => void;
};
const ONBOARDING_LINK =
	'https://support.atlassian.com/jira-software-cloud/docs/reference-issues-in-your-development-work/';
const LearnMoreLink = ({ children }: { children: ReactNode }) => (
	<Button
		appearance="link"
		target="_blank"
		spacing="none"
		testId="development-summary-commit.ui.create-commit-dropdown.button-learn-more"
		href={ONBOARDING_LINK}
		onClick={(_, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'createCommitLearnMore');
		}}
	>
		{children}
	</Button>
);
export const CreateCommitDropdown = ({ onOpenDropdown, getDropdownTrigger }: Props) => {
	const { formatMessage } = useIntl();

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [isOpen, setIsOpen] = useState(false);
	const previousOpen = useRef(false);

	useEffect(() => {
		if (previousOpen.current !== isOpen) {
			previousOpen.current = isOpen;
			isOpen &&
				fireUIAnalytics(createAnalyticsEvent({}), 'dropdown opened', 'commitCreateDropdown');
			!isOpen &&
				fireUIAnalytics(createAnalyticsEvent({}), 'dropdown closed', 'commitCreateDropdown');
			onOpenDropdown && onOpenDropdown({ isOpen });
		}
	}, [createAnalyticsEvent, isOpen, onOpenDropdown]);

	return (
		<Popup
			id="create-commit-dropdown"
			testId="development-summary-commit.ui.create-commit-dropdown.popup"
			zIndex={layers.modal + 1}
			placement="bottom-end"
			shouldFlip
			trigger={(triggerProps) =>
				getDropdownTrigger({
					...triggerProps,
					isSelected: isOpen,
					onClick: () => setIsOpen(!isOpen),
				})
			}
			isOpen={isOpen}
			onClose={() => setIsOpen(false)}
			content={() => (
				<CommitDropdownWidthContainer>
					<MenuGroup>
						<Section
							title={formatMessage(
								fg('jira-issue-terminology-refresh-m3')
									? messages.commitOnboardingGroupTitleIssueTermRefresh
									: messages.commitOnboardingGroupTitle,
							)}
						>
							<CustomDropdownItem>
								<FormattedI18nMessage
									componentsMapping={{
										link: LearnMoreLink,
									}}
									message={formatMessage(
										fg('jira-issue-terminology-refresh-m3')
											? messages.commitOnboardingGroupMessageIssueTermRefresh
											: messages.commitOnboardingGroupMessage,
										{
											linkStart: '{linkStart}',
											linkEnd: '{linkEnd}',
										},
									)}
								/>

								<CopyIssueKey />
								<CopyCommitCommand />
							</CustomDropdownItem>
						</Section>
					</MenuGroup>
				</CommitDropdownWidthContainer>
			)}
		/>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CustomDropdownItem = styled.div({
	padding: `${token('space.050', '4px')} ${token('space.250', '20px')} ${token(
		'space.100',
		'8px',
	)} ${token('space.250', '20px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CommitDropdownWidthContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `${gridSize * 48}px`,
});
