import React, { type SyntheticEvent, useCallback } from 'react';
import Button from '@atlaskit/button/new';
import { defineMessages, useIntl } from '@atlassian/jira-intl';
import type { CreatedIssueWithIssueData } from '@atlassian/jira-issue-create-common-types/src/common/types/index.tsx';
import { JSM_CREATE_PROBLEM_FROM_INCIDENT_AI } from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/constants.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { ProblemTicketSuggestedAction } from '@atlassian/jira-servicedesk-ai-context-ops-panel-resources/src/common/types.tsx';
import { commonMessages } from '../../messages.tsx';
import type { ActionButtonProps } from '../../types.tsx';
import { useCreateProblemFromIncident } from './utils.tsx';

export const messages = defineMessages({
	title: {
		id: 'jira-servicedesk-ai-context-ops-panel..create-problem.title',
		defaultMessage: 'Create Issue',
		description: 'Create Issue Title',
	},
});

type Props = ActionButtonProps<ProblemTicketSuggestedAction['context']>;

export const CreateProblemButton = ({ context, onSuccess, onClick: onClickProp }: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onIssueCreate = useCallback(
		(data: CreatedIssueWithIssueData): void => {
			onSuccess?.();
			fireUIAnalytics(createAnalyticsEvent({}), 'issueCreatedAIPanel clicked', {
				type: 'problem_ticket',
				issueId: data.issueId,
			});
		},
		[createAnalyticsEvent, onSuccess],
	);

	const { openProblemCreateModal, isDisabled } = useCreateProblemFromIncident({ onIssueCreate });

	const { formatMessage } = useIntl();

	const onClick = (_: SyntheticEvent<HTMLElement>) => {
		if (!isDisabled) {
			openProblemCreateModal(JSM_CREATE_PROBLEM_FROM_INCIDENT_AI, messages.title, context);
		}
		onClickProp?.();
	};
	return (
		<Button onClick={onClick} isDisabled={isDisabled}>
			{formatMessage(commonMessages.accept)}
		</Button>
	);
};
