import React, { useMemo } from 'react';
import {
	IssueAdjustmentsAsync,
	type IssueAdjustmentsAsyncProps,
} from '@atlassian/jira-issue-adjustments/src/ui.tsx';
import { useActivationId } from '@atlassian/jira-tenant-context-controller/src/components/activation-id/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { ForgeAppRenderer } from './forge-app-renderer/index.tsx';

export type UiModificationsJiraCoreProps = Omit<
	IssueAdjustmentsAsyncProps,
	'cloudId' | 'activationId' | 'runtimeProps'
> & { runtimeProps: Omit<IssueAdjustmentsAsyncProps['runtimeProps'], 'ForgeAppRenderer'> };

export const UiModificationsJiraCoreOld = (props: UiModificationsJiraCoreProps) => {
	const cloudId = useCloudId();
	const activationId = useActivationId();

	return <IssueAdjustmentsAsync {...props} cloudId={cloudId} activationId={activationId} />;
};

export const UiModificationsJiraCoreNew = (props: UiModificationsJiraCoreProps) => {
	const cloudId = useCloudId();
	const activationId = useActivationId();
	const { issueAdjustmentsContext, fieldsCount, viewType, resetFormFieldsCounter, modules } =
		props.runtimeProps;

	const runtimeProps = useMemo(() => {
		return {
			issueAdjustmentsContext,
			fieldsCount,
			viewType,
			resetFormFieldsCounter,
			modules,
			ForgeAppRenderer,
		};
	}, [issueAdjustmentsContext, fieldsCount, viewType, resetFormFieldsCounter, modules]);

	return (
		<IssueAdjustmentsAsync
			{...props}
			runtimeProps={runtimeProps}
			cloudId={cloudId}
			activationId={activationId}
		/>
	);
};

export const UiModificationsJiraCore = componentWithCondition(
	() => fg('uim_iframe_renderer_passed_by_props'),
	UiModificationsJiraCoreNew,
	UiModificationsJiraCoreOld,
);
