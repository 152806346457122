import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	branchTabLabel: {
		id: 'development.details.main.branch-tab-label',
		defaultMessage: 'Branches',
		description:
			'Label for a tab in the development dialog showing branches associated with a jira issue. \n' +
			'"Branch" is a technical term from the git tool and is most often not translated, ' +
			'otherwise it could sound like "ramification" or "tree branch". \n' +
			'It is acceptable to transliterate this term to another alphabet/script. \n' +
			'In languages without plurals (eg Japanese), ' +
			'it is fine to keep this term without a quantifying adjective for brevity, in this context.',
	},
	pullRequestsTabLabel: {
		id: 'development.details.main.pull-requests-tab-label',
		defaultMessage: 'Pull requests',
		description:
			'Label for a tab in the development dialog showing pull requests associated with a jira issue. \n' +
			'"Pull request" is a technical term from the git tool and is most often not translated. \n' +
			'It is acceptable to transliterate this term to another alphabet/script. \n' +
			'In languages without plurals (eg Japanese), ' +
			'it is fine to keep this term without a quantifying adjective for brevity, in this context.',
	},
	buildTabLabel: {
		id: 'development.details.main.build-tab-label',
		defaultMessage: 'Builds',
		description:
			'Label for a tab in the development dialog showing builds associated with a jira issue. \n' +
			'"Build" is a technical term from CI/CD and is most often not translated, \n' +
			'It is acceptable to transliterate this term to another alphabet/script. \n' +
			'In languages without plurals (eg Japanese), ' +
			'it is fine to keep this term without a quantifying adjective for brevity, in this context.',
	},

	commitTabLabel: {
		id: 'development.details.main.commit-tab-label',
		defaultMessage: 'Commits',
		description:
			'Label for a tab in the development dialog showing commits associated with a jira issue. \n' +
			'"Commit" is a technical term from the git tool tool and is most often not translated. \n' +
			'It is acceptable to transliterate this term to another alphabet/script. \n' +
			'In languages without plurals (eg Japanese), ' +
			'it is fine to keep this term without a quantifying adjective for brevity, in this context.',
	},

	deploymentsTabLabel: {
		id: 'development.details.main.deployments-tab-label',
		defaultMessage: 'Deployments',
		description:
			'Label for a tab in the development dialog showing deployments associated with a jira issue. \n' +
			'"Deployment" is a technical term from CI/CD and is sometimes translated, ' +
			'some languages might choose to only transliterate this term. \n' +
			'In languages without plurals (eg Japanese), ' +
			'it is fine to keep this term without a quantifying adjective for brevity, in this context.',
	},
	featureFlagsLabel: {
		id: 'development.details.main.feature-flags-label',
		defaultMessage: 'Feature flags',
		description:
			'Label for a tab in the development dialog showing feature flags associated with a jira issue. \n' +
			'"Feature flag" is a technical term from CI/CD and is most often not translated. \n' +
			'In languages without plurals (eg Japanese), ' +
			'it is fine to keep this term without a quantifying adjective for brevity, in this context.',
	},
	remoteLinksLabel: {
		id: 'development.details.main.remote-links-label',
		defaultMessage: 'Other links',
		description:
			'Label for a tab in the development dialog showing other links associated with a jira issue. \n' +
			'"Link" here refers to a "Browser Link" https://en.wikipedia.org/wiki/Links_(web_browser).',
	},
});
