import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types.tsx';

export const setForceReloadViewOnNextUpdate =
	(value: boolean): Action<State, Props> =>
	({ setState }) => {
		setState({
			forceReloadOnNextUpdate: value,
		});
	};
