/**
 * Finds the difference between provided ids and the list of available entities, by returning a list of
 * ids that weren't found in that entities list
 *
 * @param value - the list of values search being made by
 * @param lookupValues - list of entities with full information about all valid ids
 * @param lookupKey - property name of the entity from the lookupValues list with which value will be compared
 */

export const getValueLookupValuesDiff = (
	value: string[],
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	lookupValues: any[] = [],
	lookupKey: string,
): string[] => value.filter((id) => !lookupValues.some((option) => option[lookupKey] === id));
