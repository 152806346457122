import get from 'lodash/get';
import { useEditField } from '@atlassian/jira-issue-field-base/src/services/edit-field-service/main.tsx';
import { useFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import type { CascadingSelectResponse, CascadingSelectValue, Props } from './types.tsx';
import { useSaveCascadeSelectField } from './use-save-cascade-select-field/index.tsx';

export const useCascadeSelectField = ({
	fieldKey,
	issueId,
	issueKey,
	onSuccess,
	onFailure,
	initialValue,
}: Props) => {
	const saveField = useSaveCascadeSelectField(issueId);
	const [{ value: fieldConfig }] = useFieldConfig(issueKey, fieldKey);
	const fieldType = get(fieldConfig, 'schema.type', 'option-with-child');
	const [{ value, loading, error }, { saveValue, resetError }] = useEditField<
		CascadingSelectValue,
		null,
		CascadingSelectResponse
	>({
		fieldType,
		issueId,
		issueKey,
		onSuccess,
		onFailure,
		initialValue: initialValue || null,
		fieldKey,
		saveField,
	});

	return [
		{ value, loading, error, fieldConfig },
		{ saveValue, resetError },
	] as const;
};
