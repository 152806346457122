export const REQUEST_CHANNEL_PORTAL = 'portal' as const;
export const REQUEST_CHANNEL_EMAIL = 'email' as const;
export const REQUEST_CHANNEL_API = 'api' as const;
export const REQUEST_CHANNEL_JIRA = 'jira' as const;
export const REQUEST_CHANNEL_DEPLOYMENT = 'deployment' as const;
export const REQUEST_CHANNEL_ANONYMOUS_PORTAL = 'anonymousportal' as const;
export const REQUEST_CHANNEL_UNKNOWN = 'unknown' as const;

export type RequestChannel =
	| typeof REQUEST_CHANNEL_PORTAL
	| typeof REQUEST_CHANNEL_EMAIL
	| typeof REQUEST_CHANNEL_API
	| typeof REQUEST_CHANNEL_JIRA
	| typeof REQUEST_CHANNEL_DEPLOYMENT
	| typeof REQUEST_CHANNEL_ANONYMOUS_PORTAL
	| typeof REQUEST_CHANNEL_UNKNOWN
	| undefined;
