import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	tooltipContent: {
		id: 'development-summary-commit.create-commit-icon-button.tooltip-content',
		defaultMessage: 'Create new commit',
		description:
			"The create new commit button tooltip content. 'Commit' is a technical term for the tool Git, and is most often not translated.",
	},
});
