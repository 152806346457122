import React, { useRef, type ReactNode } from 'react';
import type { UIEventPayload } from '@atlassiansox/analytics-web-client';
import {
	AnalyticsListener,
	type CreateUIAnalyticsEvent,
	type UIAnalyticsEvent,
} from '@atlaskit/analytics-next';
import { getEvent, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { getAnalyticsWebClientPromise } from '@atlassian/jira-product-analytics-web-client-async';
import { getSourceByInviteFlow } from '../../common/utils.tsx';
import type { InviteFlow } from '../types.tsx';

type Props = {
	children: ReactNode;
};

const useAnalyticsClient = (): {
	sendEvent: (arg1: UIAnalyticsEvent) => void;
} => {
	const analyticsWebClientRef = useRef(getAnalyticsWebClientPromise());
	return {
		sendEvent: (analyticsEvent: UIAnalyticsEvent) => {
			analyticsWebClientRef.current.then((webClient) =>
				webClient.sendEvent(getEvent(analyticsEvent)),
			);
		},
	};
};

export const AnalyticsProvider = ({ children }: Props) => {
	const { sendEvent } = useAnalyticsClient();
	return (
		<AnalyticsListener channel="peopleAndTeams" onEvent={sendEvent}>
			{children}
		</AnalyticsListener>
	);
};

const sendAnalyticsClientUIEvent = async (
	createAnalyticsEvent: CreateUIAnalyticsEvent | null,
	analyticsPayload: UIEventPayload,
) => {
	if (createAnalyticsEvent) {
		fireUIAnalytics(
			createAnalyticsEvent({}),
			// @ts-expect-error Argument of type '[string, string, Attributes | undefined]' is not assignable to parameter of type 'RestArguments'.
			`${analyticsPayload.actionSubject} ${analyticsPayload.action}`,
			analyticsPayload?.actionSubjectId || '',
			analyticsPayload.attributes,
		);
	} else {
		const webAnalyticsClient = await getAnalyticsWebClientPromise();
		const analyticsClient = webAnalyticsClient.getInstance();

		// SSR doesn't have an analytics client
		if (analyticsClient) {
			analyticsClient.sendUIEvent(analyticsPayload);
		}
	}
};

export const fireModalTriggeredAnalytics = async (
	createAnalyticsEvent: CreateUIAnalyticsEvent | null,
	inviteFlow: InviteFlow,
	source?: string | null,
) => {
	const analyticsPayload = {
		action: 'triggered',
		actionSubject: 'modal',
		actionSubjectId: 'invitePeopleModal',
		attributes: {
			source: getSourceByInviteFlow(inviteFlow),
		},
		source: source != null && source !== '' ? source : 'unknown',
	};
	sendAnalyticsClientUIEvent(createAnalyticsEvent, analyticsPayload);
};

export const fireAddTeammatetoJiraAnalytics = async (
	createAnalyticsEvent: CreateUIAnalyticsEvent | null,
	source?: string | null,
) => {
	const analyticsPayload = {
		action: 'clicked',
		actionSubject: 'addTeammatetoJira',
		product: 'jira',
		source: source != null && source !== '' ? source : 'unknown',
	};
	sendAnalyticsClientUIEvent(createAnalyticsEvent, analyticsPayload);
};
