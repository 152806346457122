// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	label: {
		id: 'issue.sprint.label',
		defaultMessage: 'Sprint',
		description: 'Field sprint. Represents sprint attached to the issue.',
	},
	placeholder: {
		id: 'issue.sprint.text-for-edit-view-when-no-value-is-provided',
		defaultMessage: 'Select Sprint',
		description: '',
	},
	activeSprints: {
		id: 'issue.sprint.active-heading',
		defaultMessage: 'Active',
		description: '',
	},
	recentSprints: {
		id: 'issue.sprint.recent-heading',
		defaultMessage: 'Recent sprints',
		description: 'Header in the sprints list for cache entries',
	},
	futureSprints: {
		id: 'issue.sprint.future-heading',
		defaultMessage: 'Future',
		description: '',
	},
	closedSprintsLabel: {
		id: 'issue.sprint.text-for-closed-sprints-inline-dialog',
		defaultMessage: '{count} completed {count, plural, one {sprint} other {sprints}}',
		description: '',
	},
});
