import { styled } from '@compiled/react';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FormattedValueContainer = styled.div({
	display: 'flex',
	background: 'transparent',
	flexDirection: 'column',
	padding: 0,
	position: 'relative',
	overflow: 'hidden',
	alignItems: 'flex-start',
	width: '100%',
});
