import {
  Clause,
  CLAUSE_TYPE_COMPOUND,
  CLAUSE_TYPE_FIELD_CHANGED,
  CLAUSE_TYPE_FIELD_VALUE,
  CLAUSE_TYPE_FIELD_WAS,
  CompoundClause,
  CompoundOperator,
  CompoundOperatorValue,
  Field,
  FieldChangedClause,
  FieldChangedOperator,
  FieldChangedOperatorValue,
  FieldChangedTimePredicate,
  FieldChangedTimePredicateOperator,
  FieldChangedTimePredicateOperatorValue,
  FieldOperand,
  FieldValueClause,
  FieldValueOperand,
  FieldValueOperator,
  FieldValueOperatorValue,
  FieldWasClause,
  FieldWasOperator,
  FieldWasOperatorValue,
  FunctionArg,
  FunctionOperand,
  FunctionString,
  KeywordOperand,
  KeywordOperandValue,
  ListOperand,
  NODE_TYPE_CLAUSE,
  NODE_TYPE_FIELD_OPERAND,
  NODE_TYPE_ORDER_BY,
  NODE_TYPE_QUERY,
  OPERAND_TYPE_FIELD_VALUE,
  OPERAND_TYPE_FUNCTION,
  OPERAND_TYPE_KEYWORD,
  OPERAND_TYPE_LIST,
  ORDER_BY_OPERATOR_ORDER_BY,
  OrderBy,
  OrderByDirection,
  OrderByDirectionValue,
  OrderByField,
  OrderByOperator,
  Position,
  Property,
  Query,
  UnitaryOperand
} from './types';
import { JastVisitor } from './JastVisitor';

/**
 * AstNode accept functions. We define them outside of the NodeCreators so the same function reference is returned when
 * a creator is invoked multiple times (which is helpful when testing for equality of AST objects).
 */
function acceptQuery<Result>(this: Query, visitor: JastVisitor<Result>) {
  return visitor.visitQuery
    ? visitor.visitQuery(this)
    : visitor.visitNode(this);
}

function acceptCompoundClause<Result>(
  this: CompoundClause,
  visitor: JastVisitor<Result>
) {
  return visitor.visitCompoundClause
    ? visitor.visitCompoundClause(this)
    : visitor.visitNode(this);
}

function acceptCompoundOperator<Result>(
  this: CompoundOperator,
  visitor: JastVisitor<Result>
) {
  return visitor.visitCompoundOperator
    ? visitor.visitCompoundOperator(this)
    : visitor.visitNode(this);
}

function acceptFieldWasClause<Result>(
  this: FieldWasClause,
  visitor: JastVisitor<Result>
) {
  return visitor.visitFieldWasClause
    ? visitor.visitFieldWasClause(this)
    : visitor.visitNode(this);
}

function acceptFieldChangedClause<Result>(
  this: FieldChangedClause,
  visitor: JastVisitor<Result>
) {
  return visitor.visitFieldChangedClause
    ? visitor.visitFieldChangedClause(this)
    : visitor.visitNode(this);
}

function acceptFieldValueClause<Result>(
  this: FieldValueClause,
  visitor: JastVisitor<Result>
) {
  return visitor.visitFieldValueClause
    ? visitor.visitFieldValueClause(this)
    : visitor.visitNode(this);
}

function acceptFieldChangedTimePredicate<Result>(
  this: FieldChangedTimePredicate,
  visitor: JastVisitor<Result>
) {
  return visitor.visitFieldChangedTimePredicate
    ? visitor.visitFieldChangedTimePredicate(this)
    : visitor.visitNode(this);
}

function acceptFieldChangedTimePredicateOperator<Result>(
  this: FieldChangedTimePredicateOperator,
  visitor: JastVisitor<Result>
) {
  return visitor.visitFieldChangedTimePredicateOperator
    ? visitor.visitFieldChangedTimePredicateOperator(this)
    : visitor.visitNode(this);
}

function acceptField<Result>(this: Field, visitor: JastVisitor<Result>) {
  return visitor.visitField
    ? visitor.visitField(this)
    : visitor.visitNode(this);
}

function acceptFieldValueOperator<Result>(
  this: FieldValueOperator,
  visitor: JastVisitor<Result>
) {
  return visitor.visitFieldValueOperator
    ? visitor.visitFieldValueOperator(this)
    : visitor.visitNode(this);
}

function acceptFieldChangedOperator<Result>(
  this: FieldChangedOperator,
  visitor: JastVisitor<Result>
) {
  return visitor.visitFieldChangedOperator
    ? visitor.visitFieldChangedOperator(this)
    : visitor.visitNode(this);
}

function acceptFieldWasOperator<Result>(
  this: FieldWasOperator,
  visitor: JastVisitor<Result>
) {
  return visitor.visitFieldWasOperator
    ? visitor.visitFieldWasOperator(this)
    : visitor.visitNode(this);
}

function acceptFieldValueOperand<Result>(
  this: FieldValueOperand,
  visitor: JastVisitor<Result>
) {
  return visitor.visitFieldValueOperand
    ? visitor.visitFieldValueOperand(this)
    : visitor.visitNode(this);
}

function acceptKeywordOperand<Result>(
  this: KeywordOperand,
  visitor: JastVisitor<Result>
) {
  return visitor.visitKeywordOperand
    ? visitor.visitKeywordOperand(this)
    : visitor.visitNode(this);
}

function acceptFunctionOperand<Result>(
  this: FunctionOperand,
  visitor: JastVisitor<Result>
) {
  return visitor.visitFunctionOperand
    ? visitor.visitFunctionOperand(this)
    : visitor.visitNode(this);
}

function acceptFunction<Result>(
  this: FunctionString,
  visitor: JastVisitor<Result>
) {
  return visitor.visitFunction
    ? visitor.visitFunction(this)
    : visitor.visitNode(this);
}

function acceptArgument<Result>(
  this: FunctionArg,
  visitor: JastVisitor<Result>
) {
  return visitor.visitArgument
    ? visitor.visitArgument(this)
    : visitor.visitNode(this);
}

function acceptListOperand<Result>(
  this: ListOperand,
  visitor: JastVisitor<Result>
) {
  return visitor.visitListOperand
    ? visitor.visitListOperand(this)
    : visitor.visitNode(this);
}

function acceptOrderBy<Result>(this: OrderBy, visitor: JastVisitor<Result>) {
  return visitor.visitOrderBy
    ? visitor.visitOrderBy(this)
    : visitor.visitNode(this);
}

function acceptOrderByOperator<Result>(
  this: OrderByOperator,
  visitor: JastVisitor<Result>
) {
  return visitor.visitOrderByOperator
    ? visitor.visitOrderByOperator(this)
    : visitor.visitNode(this);
}

function acceptOrderByField<Result>(
  this: OrderByField,
  visitor: JastVisitor<Result>
) {
  return visitor.visitOrderByField
    ? visitor.visitOrderByField(this)
    : visitor.visitNode(this);
}

function acceptOrderByDirection<Result>(
  this: OrderByDirection,
  visitor: JastVisitor<Result>
) {
  return visitor.visitOrderByDirection
    ? visitor.visitOrderByDirection(this)
    : visitor.visitNode(this);
}

/**
 * Methods used to create an AST node of a given type.
 */
export const NodeCreators = {
  Query: (
    where: Clause | void,
    orderBy: OrderBy | void,
    represents: string
  ): Query => ({
    type: NODE_TYPE_QUERY,
    where,
    orderBy,
    represents,
    accept: acceptQuery
  }),

  CompoundClause: (
    operator: CompoundOperator,
    clauses: Clause[],
    position: Position
  ): CompoundClause => ({
    type: NODE_TYPE_CLAUSE,
    clauseType: CLAUSE_TYPE_COMPOUND,
    operator,
    clauses,
    position,
    accept: acceptCompoundClause
  }),

  CompoundOperator: (
    value: CompoundOperatorValue,
    positions: Position[]
  ): CompoundOperator => ({
    value,
    positions,
    accept: acceptCompoundOperator
  }),

  FieldWasClause: (
    field: Field,
    operator: FieldWasOperator | void,
    operand: FieldOperand | void,
    predicates: FieldChangedTimePredicate[],
    position: Position
  ): FieldWasClause => ({
    type: NODE_TYPE_CLAUSE,
    clauseType: CLAUSE_TYPE_FIELD_WAS,
    field,
    operator,
    operand,
    predicates,
    position,
    accept: acceptFieldWasClause
  }),

  FieldChangedClause: (
    field: Field,
    operator: FieldChangedOperator | void,
    predicates: FieldChangedTimePredicate[],
    position: Position
  ): FieldChangedClause => ({
    type: NODE_TYPE_CLAUSE,
    clauseType: CLAUSE_TYPE_FIELD_CHANGED,
    field,
    operator,
    predicates,
    position,
    accept: acceptFieldChangedClause
  }),

  FieldValueClause: (
    field: Field,
    operator: FieldValueOperator | void,
    operand: FieldOperand | void,
    position: Position
  ): FieldValueClause => ({
    type: NODE_TYPE_CLAUSE,
    clauseType: CLAUSE_TYPE_FIELD_VALUE,
    field,
    operator,
    operand,
    position,
    accept: acceptFieldValueClause
  }),

  FieldChangedTimePredicate: (
    operator: FieldChangedTimePredicateOperator,
    operand: FieldOperand | void,
    position: Position
  ): FieldChangedTimePredicate => ({
    operator,
    operand,
    position,
    accept: acceptFieldChangedTimePredicate
  }),

  FieldChangedTimePredicateOperator: (
    value: FieldChangedTimePredicateOperatorValue,
    position: Position
  ): FieldChangedTimePredicateOperator => ({
    value,
    position,
    accept: acceptFieldChangedTimePredicateOperator
  }),

  Field: (
    name: string,
    property: Property[] | void,
    position: Position
  ): Field => ({
    name,
    property,
    position,
    accept: acceptField
  }),

  FieldValueOperator: (
    value: FieldValueOperatorValue,
    position: Position
  ): FieldValueOperator => ({
    value,
    position,
    accept: acceptFieldValueOperator
  }),

  FieldChangedOperator: (
    value: FieldChangedOperatorValue,
    position: Position
  ): FieldChangedOperator => ({
    value,
    position,
    accept: acceptFieldChangedOperator
  }),

  FieldWasOperator: (
    value: FieldWasOperatorValue,
    position: Position
  ): FieldWasOperator => ({
    value,
    position,
    accept: acceptFieldWasOperator
  }),

  FieldValueOperand: (
    value: string,
    position: Position
  ): FieldValueOperand => ({
    type: NODE_TYPE_FIELD_OPERAND,
    operandType: OPERAND_TYPE_FIELD_VALUE,
    value,
    position,
    accept: acceptFieldValueOperand
  }),

  KeywordOperand: (
    value: KeywordOperandValue,
    position: Position
  ): KeywordOperand => ({
    type: NODE_TYPE_FIELD_OPERAND,
    operandType: OPERAND_TYPE_KEYWORD,
    value,
    position,
    accept: acceptKeywordOperand
  }),

  FunctionOperand: (
    functionString: FunctionString,
    args: FunctionArg[],
    position: Position
  ): FunctionOperand => ({
    type: NODE_TYPE_FIELD_OPERAND,
    operandType: OPERAND_TYPE_FUNCTION,
    function: functionString,
    arguments: args,
    position,
    accept: acceptFunctionOperand
  }),

  FunctionString: (value: string, position: Position): FunctionString => ({
    value,
    position,
    accept: acceptFunction
  }),

  FunctionArg: (value: string, position: Position): FunctionArg => ({
    value,
    position,
    accept: acceptArgument
  }),

  ListOperand: (values: UnitaryOperand[], position: Position): ListOperand => ({
    type: NODE_TYPE_FIELD_OPERAND,
    operandType: OPERAND_TYPE_LIST,
    values,
    position,
    accept: acceptListOperand
  }),

  OrderBy: (
    operator: OrderByOperator,
    fields: OrderByField[],
    position: Position
  ): OrderBy => ({
    type: NODE_TYPE_ORDER_BY,
    operator,
    fields,
    position,
    accept: acceptOrderBy
  }),

  OrderByOperator: (position: Position): OrderByOperator => ({
    value: ORDER_BY_OPERATOR_ORDER_BY,
    position,
    accept: acceptOrderByOperator
  }),

  OrderByField: (
    field: Field,
    direction: OrderByDirection | void,
    position: Position
  ): OrderByField => ({
    field,
    direction,
    position,
    accept: acceptOrderByField
  }),

  OrderByDirection: (
    value: OrderByDirectionValue,
    position: Position
  ): OrderByDirection => ({
    value,
    position,
    accept: acceptOrderByDirection
  })
};
