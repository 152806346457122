import React from 'react';
import { ButtonItem } from '@atlaskit/menu';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { fetchSortedCommentsRequest } from '@atlassian/jira-issue-view-store/src/actions/comment-actions.tsx';
import useToggleFlag from './hook.tsx';
import useToggleFlagWithComment from './toggle-flag-with-comment.tsx';
import type { ConnectedComponentProps, Props } from './types.tsx';

export const FlagIssueActionView = ({
	onClick,
	itemKey,
	label,
	setDropdownItemRef,
	isSelected = false,
}: Props) => {
	const toggleFlag = useToggleFlag(itemKey, onClick);

	return (
		<ButtonItem
			key="flagIssue"
			/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
			data-testid="issue.views.issue-base.foundation.issue-actions.add-to-issue-dropdown-group.flag.button-item"
			onClick={toggleFlag}
			isSelected={isSelected}
			ref={setDropdownItemRef}
			role="menuitem"
		>
			{label}
		</ButtonItem>
	);
};

export const FlagIssueWithComment = ({
	onClick,
	itemKey,
	label,
	setDropdownItemRef,
	isSelected = false,
	onAddComment,
}: ConnectedComponentProps) => {
	const onAddFlag = () => {
		onClick?.(itemKey);
		onAddComment();
	};
	const toggleFlag = useToggleFlagWithComment(itemKey, onAddFlag);

	return (
		<ButtonItem
			key="flagIssue"
			/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
			data-testid="issue.views.issue-base.foundation.issue-actions.add-to-issue-dropdown-group.flag.button-item"
			onClick={toggleFlag}
			isSelected={isSelected}
			ref={setDropdownItemRef}
			role="menuitem"
		>
			{label}
		</ButtonItem>
	);
};

export const ConnectFlagIssueWithComment = connect(null, (dispatch) => ({
	onAddComment: () => {
		dispatch(fetchSortedCommentsRequest());
	},
}))(FlagIssueWithComment);

const FlagIssue = componentWithFG(
	'task_off_track_experiment_kill_switch',
	FlagIssueWithComment,
	FlagIssueActionView,
);

export default FlagIssue;
