export const FETCH_INCIDENT_CAPABILITIES_PROVISION_STATE_ERROR =
	'FETCH_INCIDENT_CAPABILITIES_PROVISION_STATE_ERROR' as const;
export const UPDATE_SYSTEM_INCIDENT_ERROR = 'UPDATE_SYSTEM_INCIDENT_ERROR' as const;

export const UNSUPPRESS_ALERT_CREATION = 'unsuppress_alert_creation' as const;
export const SUPPRESS_ALERT_CREATION = 'suppress_alert_creation' as const;

export const NOT_PROVISIONED = 'not_provisioned';
export const IN_PROGRESS = 'in_progress';
export const DONE = 'done';
export const ERROR = 'error';
