/**
 * @generated SignedSource<<e52f8fb883c1947c9b1ca0fa8580660d>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutSprintField_IssueViewSprintField$data = {
  readonly __typename: "JiraSprintField";
  readonly fieldId: string;
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"sprint_issueFieldSprintInlineEditFull_SprintInlineEditView_fragmentRef" | "ui_issueViewLayoutFieldHeading_IssueViewFieldHeading">;
  readonly " $fragmentType": "ui_issueViewLayoutSprintField_IssueViewSprintField";
};
export type ui_issueViewLayoutSprintField_IssueViewSprintField$key = {
  readonly " $data"?: ui_issueViewLayoutSprintField_IssueViewSprintField$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutSprintField_IssueViewSprintField">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutSprintField_IssueViewSprintField",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "ui_issueViewLayoutFieldHeading_IssueViewFieldHeading"
    },
    {
      "kind": "FragmentSpread",
      "name": "sprint_issueFieldSprintInlineEditFull_SprintInlineEditView_fragmentRef"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "ScalarField",
      "name": "__typename"
    }
  ],
  "type": "JiraSprintField"
};

(node as any).hash = "841f19c650c60b7cfae72487623eee8b";

export default node;
