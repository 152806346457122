import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import { type ActionsObservable, combineEpics } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import {
	editCommentUpdate,
	ADD_COMMENT_FORM_EXPAND,
} from '@atlassian/jira-issue-view-store/src/actions/comment-actions.tsx';
import { SET_CONTEXT } from '@atlassian/jira-issue-view-store/src/actions/context-actions.tsx';
import {
	issueKeySelector,
	accountIdloggedInUserSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import { loadDraftRequest } from '@atlassian/jira-issue-view-store/src/drafts/draft-actions.tsx';
import {
	getDraftKey,
	createCommentDraftKey,
} from '@atlassian/jira-issue-view-store/src/drafts/draft-utils.tsx';
import { NEW_COMMENT_ID } from '@atlassian/jira-issue-view-store/src/selectors/comment-constants.tsx';

const loadNewCommentDraft = (state: State, useDraftHandler: boolean) => {
	const commentId = NEW_COMMENT_ID;
	const loggedInAccountId = accountIdloggedInUserSelector(state);
	const issueKey = issueKeySelector(state);
	const id = createCommentDraftKey(commentId);
	const draftKey = getDraftKey(loggedInAccountId, issueKey, id);

	return Observable.of(
		loadDraftRequest(commentId, draftKey, !useDraftHandler ? null : editCommentUpdate),
	);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const errorHandler = (error: any) => {
	log.safeErrorWithoutCustomerData('issue.new-comment-load-draft', error.message, error);
	return Observable.empty<never>();
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const setContextEpic = (action$: ActionsObservable<any>, store: MiddlewareAPI<State>) =>
	action$
		.ofType(SET_CONTEXT)
		.mergeMap(() => loadNewCommentDraft(store.getState(), false))
		.catch((error) => errorHandler(error));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const addCommentFormExpandEpic = (action$: ActionsObservable<any>, store: MiddlewareAPI<State>) =>
	action$
		.ofType(ADD_COMMENT_FORM_EXPAND)
		.mergeMap(() => loadNewCommentDraft(store.getState(), true))
		.catch((error) => errorHandler(error));

export default combineEpics(setContextEpic, addCommentFormExpandEpic);
