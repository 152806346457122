import React from 'react';
import type { CustomGlyphProps } from '@atlaskit/icon';

export const issueTypeGlyph = (props: CustomGlyphProps) => (
	<svg
		{...props}
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M6.5 2.25C4.42893 2.25 2.75 3.92893 2.75 6C2.75 7.81422 4.03832 9.32753 5.75 9.67499V7.25C5.75 6.14543 6.64543 5.25 7.75 5.25H10.175C9.82753 3.53832 8.31422 2.25 6.5 2.25ZM11.6968 5.25C11.3329 2.70578 9.14485 0.75 6.5 0.75C3.6005 0.75 1.25 3.1005 1.25 6C1.25 8.64485 3.20578 10.8329 5.75 11.1968V13C5.75 14.1046 6.64543 15 7.75 15H13.5C14.6046 15 15.5 14.1046 15.5 13V7.25C15.5 6.14543 14.6046 5.25 13.5 5.25H11.6968ZM7.75 6.75C7.47386 6.75 7.25 6.97386 7.25 7.25V13C7.25 13.2761 7.47386 13.5 7.75 13.5H13.5C13.7761 13.5 14 13.2761 14 13V7.25C14 6.97386 13.7761 6.75 13.5 6.75H7.75Z"
			fill="currentColor"
		/>
	</svg>
);
