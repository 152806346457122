import {
  CompoundOperator,
  FieldChangedOperator,
  FieldChangedTimePredicate,
  FieldValueOperator,
  FieldWasOperator
} from './operator';
import { FieldOperand } from './operand';
import { AstNode, Position } from './common';

export const NODE_TYPE_CLAUSE: 'clause' = 'clause';

export const CLAUSE_TYPE_COMPOUND: 'compound' = 'compound';

/**
 * A JQL query clause that consists of nested clauses.
 * For example, <code>(labels in (urgent, blocker) OR lastCommentedBy = currentUser())</code>.
 * Note that, where nesting is not defined, the parser nests JQL clauses based on the operator precedence. For example,
 * "A OR B AND C" is parsed as "(A OR B) AND C".
 * See <a href="https://confluence.atlassian.com/x/awiiLQ#Advancedsearching-parenthesesSettingtheprecedenceofoperators">Setting the precedence of operators</a>
 * for more information about precedence in JQL queries.
 */

export interface CompoundClause extends AstNode {
  type: typeof NODE_TYPE_CLAUSE;
  clauseType: typeof CLAUSE_TYPE_COMPOUND;
  /**
   * The operator between the clauses.
   */
  operator: CompoundOperator;
  /**
   * List of nested clauses.
   */
  clauses: Clause[];
  /**
   * Tuple representing the character position of the compound clause.
   */
  position: Position;
}

export const CLAUSE_TYPE_FIELD_VALUE: 'field_value' = 'field_value';

/**
 * A clause that asserts the current value of a field. For example, <code>summary ~ test</code>.
 */
export interface FieldValueClause extends AstNode {
  type: typeof NODE_TYPE_CLAUSE;
  clauseType: typeof CLAUSE_TYPE_FIELD_VALUE;
  /**
   * The field in the clause.
   */
  field: Field;
  /**
   * The operator between the field and operand.
   */
  operator: FieldValueOperator | void;
  /**
   * The operand to which the operator is applied.
   */
  operand: FieldOperand | void;
  /**
   * Tuple representing the character position of the field value clause.
   */
  position: Position;
}

export const CLAUSE_TYPE_FIELD_CHANGED: 'field_changed' = 'field_changed';

/**
 * A clause that asserts whether a field was changed. For example, <code>status CHANGED AFTER startOfMonth(-1M)</code>.
 * See <a href="https://confluence.atlassian.com/x/dgiiLQ#Advancedsearching-operatorsreference-CHANGEDCHANGED">CHANGED</a>
 * for more information about the CHANGED operator.
 */
export interface FieldChangedClause extends AstNode {
  type: typeof NODE_TYPE_CLAUSE;
  clauseType: typeof CLAUSE_TYPE_FIELD_CHANGED;
  /**
   * The field in the clause.
   */
  field: Field;
  /**
   * The operator applied to the field.
   */
  operator: FieldChangedOperator | void;
  /**
   * The list of time predicates.
   */
  predicates: FieldChangedTimePredicate[];
  /**
   * Tuple representing the character position of the field changed clause.
   */
  position: Position;
}

export const CLAUSE_TYPE_FIELD_WAS: 'field_was' = 'field_was';

/**
 * A clause that asserts a previous value of a field. For example, <code>status WAS "Resolved" BY currentUser() BEFORE "2019/02/02"</code>.
 * See <a href="https://confluence.atlassian.com/x/dgiiLQ#Advancedsearching-operatorsreference-WASWAS">WAS</a> for
 * more information about the WAS operator.
 */
export interface FieldWasClause extends AstNode {
  type: typeof NODE_TYPE_CLAUSE;
  clauseType: typeof CLAUSE_TYPE_FIELD_WAS;
  /**
   * The field in the clause.
   */
  field: Field;
  /**
   * The operator between the field and operand.
   */
  operator: FieldWasOperator | void;
  /**
   * The operand to which the operator is applied.
   */
  operand: FieldOperand | void;
  /**
   * The list of time predicates.
   */
  predicates: FieldChangedTimePredicate[];
  /**
   * Tuple representing the character position of the field was clause.
   */
  position: Position;
}

/**
 * An individual JQL clause that can be composed together to form a query.
 */
export type Clause =
  | CompoundClause
  | FieldValueClause
  | FieldChangedClause
  | FieldWasClause;

export const PROPERTY_DATA_TYPE_NUMBER: 'number' = 'number';
export const PROPERTY_DATA_TYPE_STRING: 'string' = 'string';
export const PROPERTY_DATA_TYPE_TEXT: 'text' = 'text';
export const PROPERTY_DATA_TYPE_DATE: 'date' = 'date';
export const PROPERTY_DATA_TYPE_USER: 'user' = 'user';

export type PropertyDataType =
  | typeof PROPERTY_DATA_TYPE_NUMBER
  | typeof PROPERTY_DATA_TYPE_STRING
  | typeof PROPERTY_DATA_TYPE_TEXT
  | typeof PROPERTY_DATA_TYPE_DATE
  | typeof PROPERTY_DATA_TYPE_USER;

/**
 * Details of an entity property.
 */
export interface Property {
  /**
   * The object on which the property is set.
   */
  entity: string;
  /**
   * The key of the property.
   */
  key: string;
  /**
   * The path in the property value to query.
   */
  path: string;
  /**
   * The type of the property value extraction. Not available if the extraction for the property is not registered on
   * the instance with the <a href="https://developer.atlassian.com/cloud/jira/platform/modules/entity-property/">Entity property</a>
   * module.
   */
  type: PropertyDataType;
  /**
   * Tuple representing the character position of the property.
   */
  position: Position;
}

/**
 * A field used in a JQL query.
 */
export interface Field extends AstNode {
  /**
   * The name of the field.
   */
  name: string;
  /**
   * When the field refers to a value in an entity property, details of the entity property value.
   */
  property: Property[] | void;
  /**
   * Tuple representing the character position of the field.
   */
  position: Position;
}
