import React, { useCallback, useEffect, useState, type KeyboardEvent } from 'react';
import { ErrorMessage } from '@atlaskit/form';
import { isFirefox } from '@atlassian/jira-common-util-browser/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import {
	TAB,
	ENTER,
	ARROW_LEFT,
	ARROW_RIGHT,
	ARROW_UP,
	ARROW_DOWN,
	DELETE,
	BACKSPACE,
	TAB_KEY_CODE,
	ENTER_KEY_CODE,
	BACKSPACE_KEY_CODE,
	DELETE_KEY_CODE,
	ARROW_LEFT_KEY_CODE,
	ARROW_RIGHT_KEY_CODE,
	ARROW_UP_KEY_CODE,
	ARROW_DOWN_KEY_CODE,
} from '../../../../../common/constants.tsx';
import messages from '../../../../../common/messages.tsx';
import {
	type UserConfigTemplates,
	BranchTemplateType,
	PlaceholderEnum,
} from '../../../../../common/types.tsx';
import { useBranchCommandBuilderStore } from '../../../../../controllers/branch-command-builder-store/index.tsx';
import { enumFromStringValue } from './common/utils.tsx';
import Placeholder from './placeholder/index.tsx';
import { StyledInput } from './styled.tsx';
import TextValue from './text-value/index.tsx';
import {
	isEmptyInput,
	getCaretPosition,
	restoreCaretPosition,
	buildTemplateFromEnteredInput,
	changeEachLineLastTextElementStyle,
} from './utils.tsx';

export type Props = {
	templates: UserConfigTemplates;
	getValidate: () => { isValid: boolean; errorMessage: string };
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	inputRef: any;
};

const TemplateInput = ({ templates, getValidate, inputRef }: Props) => {
	const [
		{ readAndWriteTemplates, uniqueKey, focusTagIndex, restoreFocus, caretPosition },
		{
			setEmptyTextStarterTemplates,
			setCaretPosition,
			setUserEnteredTemplate,
			setFocusTagIndex,
			setRestoreFocus,
			removePlaceholder,
		},
	] = useBranchCommandBuilderStore();
	const [isValid, setIsValid] = useState(true);
	const [errorMessage, setErrorMessage] = useState('');
	const { formatMessage } = useIntl();

	useEffect(() => {
		const { errorMessage: error, isValid: result } = getValidate();
		setErrorMessage(error);
		setIsValid(result);

		changeEachLineLastTextElementStyle(inputRef);
	}, [inputRef, getValidate]);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onChange = useCallback(
		(event: KeyboardEvent<HTMLElement>) => {
			// this should support ctrl + z to revert
			setCaretPosition(getCaretPosition(window, event, caretPosition));
			const userConfig = buildTemplateFromEnteredInput(event);
			setUserEnteredTemplate(userConfig);
		},
		[setCaretPosition, setUserEnteredTemplate, caretPosition],
	);
	const updateCaretPosition = useCallback(
		(event: KeyboardEvent<HTMLElement>) => {
			setCaretPosition(getCaretPosition(window, event, caretPosition));
		},
		[setCaretPosition, caretPosition],
	);

	const handleKeyUp = useCallback(
		(event: KeyboardEvent<HTMLElement>) => {
			setCaretPosition(getCaretPosition(window, event, caretPosition));
			// Click Tab key can automatically focus on the very beginning
			if (event.keyCode === TAB_KEY_CODE || event.key === TAB) {
				focusTagIndex !== 0 && setFocusTagIndex(0);
			}

			if (
				isFirefox() &&
				(event.keyCode === BACKSPACE_KEY_CODE ||
					event.key === BACKSPACE ||
					event.keyCode === DELETE_KEY_CODE ||
					event.key === DELETE)
			) {
				if (isEmptyInput(inputRef.current)) {
					setEmptyTextStarterTemplates();
				}
			}
		},
		[
			setCaretPosition,
			inputRef,
			caretPosition,
			setEmptyTextStarterTemplates,
			focusTagIndex,
			setFocusTagIndex,
		],
	);

	useEffect(() => {
		if (restoreFocus) {
			restoreCaretPosition(window, document, inputRef.current, caretPosition);
			setRestoreFocus(false);
		}
	}, [restoreFocus, caretPosition, setRestoreFocus, inputRef]);

	const handleOnFocus = (event: KeyboardEvent<HTMLElement>) => {
		const currentCaretPosition = getCaretPosition(window, event, caretPosition);

		if (currentCaretPosition === undefined) {
			return;
		}
		if (restoreFocus === false && focusTagIndex === 0 && event.target === inputRef.current) {
			// when add or remove tag, or click reset button should not fire the event
			const analyticsEvent = createAnalyticsEvent({
				action: 'focused',
				actionSubject: 'updateBranchNameFormatInput',
			});
			fireUIAnalytics(analyticsEvent);
		}
	};

	const handleOnMouseDown = () => {
		focusTagIndex !== 0 && setFocusTagIndex(0);
	};

	const handleKeyDown = (event: KeyboardEvent<HTMLElement>) => {
		if (event.keyCode === TAB_KEY_CODE || event.key === TAB) {
			focusTagIndex !== 0 && setFocusTagIndex(0);
		}
		if (event.keyCode === ARROW_LEFT_KEY_CODE || event.key === ARROW_LEFT) {
			// Press left key to focus on a tag cross button
			if (caretPosition.characterIndex === 0 && focusTagIndex === 0) {
				event.preventDefault();
				setFocusTagIndex(caretPosition.templateIndex);
			} else if (focusTagIndex !== 0) {
				// Leave the tag cross button
				if (
					// Press right key to focus a tag cross button then press left key to leave
					readAndWriteTemplates[focusTagIndex * 2 - 2]?.value?.length ===
					caretPosition.characterIndex
				) {
					event.preventDefault();
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					restoreCaretPosition(window, document, inputRef.current as HTMLElement, {
						...caretPosition,
						templateIndex: focusTagIndex - 1 > 0 ? focusTagIndex - 1 : 0,
					});
				} else {
					// Press left key to focus a tag cross button then leave normally
					restoreCaretPosition(
						window,
						document,
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						inputRef.current as HTMLElement,
						caretPosition,
					);
				}
				setCaretPosition({
					templateIndex: focusTagIndex - 1,
					characterIndex: readAndWriteTemplates[focusTagIndex * 2 - 2]?.value?.length || 0,
				});
				setFocusTagIndex(0);
			} else {
				setCaretPosition({
					...caretPosition,
					characterIndex: caretPosition.characterIndex - 1,
				});
			}
		}

		if (event.keyCode === ARROW_RIGHT_KEY_CODE || event.key === ARROW_RIGHT) {
			if (
				readAndWriteTemplates[caretPosition.templateIndex * 2]?.value?.length ===
					caretPosition.characterIndex &&
				focusTagIndex === 0 &&
				caretPosition.templateIndex < (readAndWriteTemplates.length - 1) / 2
			) {
				// Press right key to focus on a tag cross button
				event.preventDefault();
				setFocusTagIndex(caretPosition.templateIndex + 1);
			} else if (focusTagIndex !== 0) {
				// Leave the tag cross button
				if (caretPosition.characterIndex === 0) {
					// Press left key to focus on a tag cross button then press right key to leave
					event.preventDefault();
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					restoreCaretPosition(window, document, inputRef.current as HTMLElement, {
						...caretPosition,
						templateIndex: focusTagIndex,
					});
				} else {
					// Press right key to focus a tag cross button then leave normally
					restoreCaretPosition(
						window,
						document,
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						inputRef.current as HTMLElement,
						caretPosition,
					);
				}
				setCaretPosition({ templateIndex: focusTagIndex, characterIndex: 0 });
				setFocusTagIndex(0);
			} else if (
				caretPosition.templateIndex < (readAndWriteTemplates.length - 1) / 2 ||
				(caretPosition.templateIndex === (readAndWriteTemplates.length - 1) / 2 &&
					caretPosition.characterIndex <
						readAndWriteTemplates[readAndWriteTemplates.length - 1]?.value?.length)
			) {
				setCaretPosition({
					...caretPosition,
					characterIndex: caretPosition.characterIndex + 1,
				});
			}
		}
		if (event.keyCode === ARROW_UP_KEY_CODE || event.key === ARROW_UP) {
			event.preventDefault();
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			restoreCaretPosition(window, document, inputRef.current as HTMLElement, {
				templateIndex: 0,
				characterIndex: 0,
			});
			focusTagIndex !== 0 && setFocusTagIndex(0);
		}
		if (event.keyCode === ARROW_DOWN_KEY_CODE || event.key === ARROW_DOWN) {
			event.preventDefault();
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			restoreCaretPosition(window, document, inputRef.current as HTMLElement, {
				templateIndex: (readAndWriteTemplates.length - 1) / 2,
				characterIndex: readAndWriteTemplates[readAndWriteTemplates.length - 1].value.length,
			});
			focusTagIndex !== 0 && setFocusTagIndex(0);
		}
		if (event.keyCode === ENTER_KEY_CODE || event.key === ENTER) {
			if (focusTagIndex !== 0)
				removePlaceholder(
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					(event.target as Element)?.parentElement?.parentElement?.dataset?.key as PlaceholderEnum,
					focusTagIndex * 2 - 1,
					false,
				);
			// avoid useless space when click enter
			event.preventDefault();
		}
	};

	const label = fg('jira-a11y-team_development_branch_template_label')
		? formatMessage(messages.configSectionTitle)
		: formatMessage(messages.templateLabel);

	return (
		<>
			<StyledInput
				contentEditable
				suppressContentEditableWarning
				role="textbox"
				tabIndex="0"
				onInput={onChange}
				onMouseUp={updateCaretPosition}
				onMouseDown={handleOnMouseDown}
				onKeyUp={handleKeyUp}
				onKeyDown={handleKeyDown}
				isValid={isValid}
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				key={uniqueKey as string}
				innerRef={inputRef}
				onFocus={handleOnFocus}
				data-testid="development-summary-branch.ui.create-branch-dropdown.copy-branch-command.branch-command-builder.template-input.styled-input"
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				onCopy={(event: any) => event.preventDefault()}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				onPaste={(event: any) => event.preventDefault()}
				aria-label={label}
			>
				{templates.map((template, index) => {
					if (template.type === BranchTemplateType.TEXT) {
						const id = `index-${index}-${BranchTemplateType.TEXT}`;
						return <TextValue key={id} value={template.value} id={id} />;
					}
					if (template.type === BranchTemplateType.PLACEHOLDER) {
						const enumValue = enumFromStringValue(PlaceholderEnum, template.value);

						if (enumValue !== undefined) {
							const id = `index-${index}-${BranchTemplateType.PLACEHOLDER}`;
							return (
								<Placeholder
									id={id}
									key={`index-${index}-${enumValue}`}
									index={index}
									value={enumValue}
									focusTagIndex={focusTagIndex}
								/>
							);
						}
					}

					return null;
				})}
			</StyledInput>
			{!isValid && (
				<ErrorMessage testId="development-summary-branch.ui.create-branch-dropdown.copy-branch-command.branch-command-builder.template-input.error-message">
					{errorMessage}
				</ErrorMessage>
			)}
		</>
	);
};

export default TemplateInput;
