/**
 * @generated SignedSource<<84e3f24e5b158506253748cb82c9036b>>
 * @relayHash 7881910e51a829d77ee52c81fed56f55
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 32369970bb9fcc9b3b84ccefcf25e7559cb4fc2c03b05a059baa4b375dbc8738

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraFieldOptionIdsFilterOperation = "ALLOW" | "EXCLUDE" | "%future added value";
export type JiraFieldOptionIdsFilterInput = {
  operation: JiraFieldOptionIdsFilterOperation;
  optionIds: ReadonlyArray<string>;
};
export type cascadingSelect_issueFieldCascadingSelect_PrefetchQuery$variables = {
  childOptionsFilterById?: JiraFieldOptionIdsFilterInput | null | undefined;
  filterById?: JiraFieldOptionIdsFilterInput | null | undefined;
  first?: number | null | undefined;
  id: string;
  isSelectedParentIdSet: boolean;
  searchBy?: string | null | undefined;
  selectedParentIdFilter: JiraFieldOptionIdsFilterInput;
};
export type cascadingSelect_issueFieldCascadingSelect_PrefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"cascadingSelect_issueFieldCascadingSelectEditviewFull_CascadingSelectEditViewWithFieldOptionsFragment">;
};
export type cascadingSelect_issueFieldCascadingSelect_PrefetchQuery = {
  response: cascadingSelect_issueFieldCascadingSelect_PrefetchQuery$data;
  variables: cascadingSelect_issueFieldCascadingSelect_PrefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "childOptionsFilterById"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filterById"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isSelectedParentIdSet"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchBy"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "selectedParentIdFilter"
},
v7 = {
  "kind": "Variable",
  "name": "filterById",
  "variableName": "filterById"
},
v8 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v9 = {
  "kind": "Variable",
  "name": "id",
  "variableName": "id"
},
v10 = {
  "kind": "Variable",
  "name": "searchBy",
  "variableName": "searchBy"
},
v11 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v12 = [
  (v7/*: any*/),
  (v8/*: any*/),
  (v10/*: any*/)
],
v13 = {
  "kind": "ScalarField",
  "name": "id"
},
v14 = {
  "kind": "ScalarField",
  "name": "value"
},
v15 = {
  "kind": "ScalarField",
  "name": "isDisabled"
},
v16 = {
  "args": [
    {
      "kind": "Variable",
      "name": "filterById",
      "variableName": "childOptionsFilterById"
    }
  ],
  "concreteType": "JiraOptionConnection",
  "kind": "LinkedField",
  "name": "childOptions",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraOptionEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraOption",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v13/*: any*/),
            (v14/*: any*/),
            (v15/*: any*/)
          ]
        }
      ]
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "name": "cascadingSelect_issueFieldCascadingSelect_PrefetchQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "childOptionsFilterById",
            "variableName": "childOptionsFilterById"
          },
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "kind": "Variable",
            "name": "isSelectedParentIdSet",
            "variableName": "isSelectedParentIdSet"
          },
          (v10/*: any*/),
          {
            "kind": "Variable",
            "name": "selectedParentIdFilter",
            "variableName": "selectedParentIdFilter"
          }
        ],
        "kind": "FragmentSpread",
        "name": "cascadingSelect_issueFieldCascadingSelectEditviewFull_CascadingSelectEditViewWithFieldOptionsFragment"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v6/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/),
      (v5/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "cascadingSelect_issueFieldCascadingSelect_PrefetchQuery",
    "selections": [
      {
        "args": [
          (v9/*: any*/)
        ],
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v11/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": (v12/*: any*/),
                "concreteType": "JiraParentOptionConnection",
                "kind": "LinkedField",
                "name": "parentOptions",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraParentOptionEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "JiraParentOption",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v13/*: any*/),
                          (v14/*: any*/),
                          (v15/*: any*/),
                          (v16/*: any*/),
                          (v11/*: any*/)
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "name": "cursor"
                      }
                    ]
                  },
                  {
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "endCursor"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "hasNextPage"
                      }
                    ]
                  }
                ]
              },
              {
                "args": (v12/*: any*/),
                "filters": [
                  "searchBy",
                  "filterById"
                ],
                "handle": "connection",
                "key": "cascadingSelect_issueFieldCascadingSelectEditviewFull_parentOptions",
                "kind": "LinkedHandle",
                "name": "parentOptions"
              },
              {
                "condition": "isSelectedParentIdSet",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": "selectedParentOptions",
                    "args": [
                      {
                        "kind": "Variable",
                        "name": "filterById",
                        "variableName": "selectedParentIdFilter"
                      }
                    ],
                    "concreteType": "JiraParentOptionConnection",
                    "kind": "LinkedField",
                    "name": "parentOptions",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraParentOptionEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "concreteType": "JiraParentOption",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v13/*: any*/),
                              (v14/*: any*/),
                              (v15/*: any*/),
                              (v16/*: any*/)
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ],
            "type": "JiraCascadingSelectField"
          },
          (v13/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "id": "32369970bb9fcc9b3b84ccefcf25e7559cb4fc2c03b05a059baa4b375dbc8738",
    "metadata": {},
    "name": "cascadingSelect_issueFieldCascadingSelect_PrefetchQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "d6b5aeb02fb02f4bd87412a0a561fb87";

export default node;
