import { createContext, useContext } from 'react';
import type { ForgeUiAnalyticsAttributes } from '@atlassian/jira-forge-ui-types/src/common/types/analytics.tsx';

const AnalyticsAttributesContext = createContext<ForgeUiAnalyticsAttributes>({
	appId: 'appId',
	envId: 'envId',
	source: 'unknownSource',
});

export const useAnalyticsAttributesContext = () => useContext(AnalyticsAttributesContext);

export const AnalyticsAttributesContextProvider = AnalyticsAttributesContext.Provider;
