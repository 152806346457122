import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';

const findIssueFromActivity = (activities: IssueKey[][], index: number) => {
	const foundActivity = activities.find((activity) => activity.length);
	return foundActivity && (foundActivity[index] || foundActivity[foundActivity.length - 1]);
};

const findCurrentIssueWithActivity = (boardMap: IssueKey[][], currentIssueKey: IssueKey) => {
	const currentActivity = boardMap.find((activity) => activity.includes(currentIssueKey)) || [];
	const issueIndex = currentActivity.indexOf(currentIssueKey);
	return [currentActivity, issueIndex] as const;
};

const findCurrentIssueWithActivityIndex = (boardMap: IssueKey[][], currentIssueKey: IssueKey) => {
	const currentActivityIndex = boardMap.findIndex((activity) => activity.includes(currentIssueKey));
	const issueIndex = boardMap[currentActivityIndex].indexOf(currentIssueKey);
	return [currentActivityIndex, issueIndex] as const;
};

export const findNextIssue = (boardMap: IssueKey[][] | null, currentIssueKey: IssueKey) => {
	if (!boardMap) return currentIssueKey;
	const [currentActivity, issueIndex] = findCurrentIssueWithActivity(boardMap, currentIssueKey);
	return currentActivity[issueIndex + 1] || currentIssueKey;
};

export const findPreviousIssue = (boardMap: IssueKey[][] | null, currentIssueKey: IssueKey) => {
	if (!boardMap) return currentIssueKey;
	const [currentActivity, issueIndex] = findCurrentIssueWithActivity(boardMap, currentIssueKey);
	return currentActivity[issueIndex - 1] || currentIssueKey;
};

export const findIssueOfNextActivity = (
	boardMap: IssueKey[][] | null,
	currentIssueKey: IssueKey,
) => {
	if (!boardMap) return currentIssueKey;
	const [currentActivityIndex, issueIndex] = findCurrentIssueWithActivityIndex(
		boardMap,
		currentIssueKey,
	);
	const nextActivities = boardMap.slice(currentActivityIndex + 1);
	return findIssueFromActivity(nextActivities, issueIndex) || currentIssueKey;
};

export const findIssueOfPreviousActivity = (
	boardMap: IssueKey[][] | null,
	currentIssueKey: IssueKey,
) => {
	if (!boardMap) return currentIssueKey;
	const [currentActivityIndex, issueIndex] = findCurrentIssueWithActivityIndex(
		boardMap,
		currentIssueKey,
	);
	const previousActivities = boardMap.slice(0, currentActivityIndex).reverse();
	return findIssueFromActivity(previousActivities, issueIndex) || currentIssueKey;
};
