import 'rxjs/add/observable/fromPromise';
import { Observable } from 'rxjs/Observable';
import { fetchDevSummaryData as fetchDevSummaryDataCommon } from '@atlassian/jira-development-summary-common/src/services/index.tsx';
import type { TransformedSummary } from '@atlassian/jira-development-summary-common/src/services/utils.tsx';
import type { Gh4JConfigResponse } from './types.tsx';

export const fetchDevSummaryData = (issueAri: string): Observable<TransformedSummary> =>
	Observable.fromPromise(fetchDevSummaryDataCommon(issueAri));

/**
 *
 * TEMP method to fetch the config state of GitHub For Jira
 * Cleaning up tracked in ticket: https://softwareteams.atlassian.net/browse/ARC-1838
 * If the app is installed, then returns the app property
 * Otherwise, if the app is not installed or has been removed, returns null
 *
 */
export const fetchGh4JConfigState = (baseUrl: string): Promise<Gh4JConfigResponse> =>
	fetch(`${baseUrl}/rest/atlassian-connect/latest/addons-gh4j/properties/is-configured`).then(
		(response) => (response.status === 200 ? response.json() : Promise.resolve(null)),
	);
