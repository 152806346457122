import { setIn } from 'icepick';
import { fg } from '@atlassian/jira-feature-gating';
import type { PersistedCommentsById } from '@atlassian/jira-issue-view-common-types/src/comment-type.tsx';
import {
	type SetCommentValueAction,
	type saveCommentSuccess,
	type saveCommentCancel,
	type deleteCommentSuccess,
	type deleteCommentRequest,
	type deleteCommentFailure,
	type SaveCommentRequestAction,
	SET_COMMENT_VALUE,
	SAVE_COMMENT_SUCCESS,
	DELETE_COMMENT_SUCCESS,
	DELETE_COMMENT_REQUEST,
	DELETE_COMMENT_FAILURE,
	SAVE_COMMENT_REQUEST,
	SAVE_COMMENT_CANCEL,
} from '../../actions/comment-actions.tsx';

type State = PersistedCommentsById;

type Action =
	| SetCommentValueAction
	| ReturnType<typeof saveCommentCancel>
	| ReturnType<typeof saveCommentSuccess>
	| ReturnType<typeof deleteCommentSuccess>
	| ReturnType<typeof deleteCommentRequest>
	| ReturnType<typeof deleteCommentFailure>
	| SaveCommentRequestAction;

export const initialState: State = {};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: State = initialState, action: Action): State => {
	switch (action.type) {
		case SET_COMMENT_VALUE:
			return setIn(state, [action.payload.id, 'bodyAdf'], action.payload.value);

		case SAVE_COMMENT_CANCEL: {
			if (!fg('jira_threaded_comment_fg')) {
				return state;
			}

			const { optimisticId, isNewComment, parentId } = action.payload;
			if (isNewComment && parentId && state[parentId]) {
				const parentComment = state[parentId];
				if (parentComment) {
					const updatedReplies = parentComment.replies ? [...parentComment.replies] : [];
					const indexOfOptimisticComment = updatedReplies.indexOf(optimisticId);
					if (indexOfOptimisticComment >= 0) {
						updatedReplies.splice(indexOfOptimisticComment, 1);

						const updatedParentComment = {
							...parentComment,
							replies: updatedReplies,
						};
						return {
							...state,
							[parentId]: updatedParentComment,
						};
					}
				}
			}
			return state;
		}

		case SAVE_COMMENT_REQUEST: {
			if (!fg('jira_threaded_comment_fg')) {
				return state;
			}
			const { id, isNewComment, parentId } = action.payload.comment;
			if (isNewComment && parentId) {
				const parentComment = state[parentId];
				if (parentComment) {
					const updatedReplies = parentComment.replies ? [...parentComment.replies] : [];
					if (updatedReplies.indexOf(id) === -1) {
						updatedReplies.push(id);
					}
					const updatedParentComment = {
						...parentComment,
						replies: updatedReplies,
					};
					return {
						...state,
						[parentId]: updatedParentComment,
					};
				}
			}
			return state;
		}

		case SAVE_COMMENT_SUCCESS: {
			const { comment } = action.payload;

			if (fg('jira_threaded_comment_fg')) {
				const { optimisticId, isNewComment, parentId } = action.payload;
				if (isNewComment && parentId) {
					const parentComment = state[parentId];
					if (parentComment) {
						const updatedReplies = parentComment.replies ? [...parentComment.replies] : [];
						const optimisticIndex = updatedReplies.indexOf(optimisticId);
						if (optimisticIndex !== -1) {
							updatedReplies[optimisticIndex] = comment.id;
						} else {
							updatedReplies.push(comment.id);
						}

						const updatedParentComment = {
							...parentComment,
							replies: updatedReplies,
						};
						// @ts-expect-error - TS2322 - Type '{ [x: string]: Comment | undefined; }' is not assignable to type 'Partial<Record<string, PersistedComment>>'.
						return {
							...state,
							[comment.id]: {
								...comment,
								parentId,
							},
							[parentId]: updatedParentComment,
						};
					}
				}

				const existingComment = state[comment.id];
				if (existingComment && (existingComment.parentId || existingComment.replies)) {
					// @ts-expect-error - TS2322 - Type '{ [x: string]: Comment | undefined; }' is not assignable to type 'Partial<Record<string, PersistedComment>>'.
					return {
						...state,
						[comment.id]: {
							...comment,
							parentId: existingComment.parentId,
							replies: existingComment.replies,
						},
					};
				}

				// @ts-expect-error - TS2322 - Type '{ [x: string]: Comment | undefined; }' is not assignable to type 'Partial<Record<string, PersistedComment>>'.
				return {
					...state,
					[comment.id]: comment,
				};
			}
			// @ts-expect-error - TS2322 - Type '{ [x: string]: Comment | undefined; }' is not assignable to type 'Partial<Record<string, PersistedComment>>'.
			return {
				...state,
				[comment.id]: comment,
			};
		}

		case DELETE_COMMENT_REQUEST: {
			const { hasReplies, id } = action.payload;

			if (hasReplies && fg('jira_threaded_comment_fg')) {
				const nextState = { ...state };

				const comment = { ...state[id], isDeleted: true };
				nextState[id] = comment;
				return nextState;
			}

			return state;
		}

		case DELETE_COMMENT_FAILURE: {
			const { payload: id } = action;

			if (state[id]?.isDeleted && fg('jira_threaded_comment_fg')) {
				const nextState = { ...state };

				const comment = { ...state[id], isDeleted: false };
				nextState[id] = comment;
				return nextState;
			}

			return state;
		}

		case DELETE_COMMENT_SUCCESS: {
			const { id, parentId } = action.payload;
			const nextState = { ...state };

			if (fg('jira_threaded_comment_fg')) {
				// handle child comment
				// find child comment reference in parent's "replies" and remove the entry from there
				// the child comment itself will also be removed at the end using delete nextState[id]
				if (parentId && state[parentId] && state[parentId].replies) {
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					const parent = { ...state[parentId], replies: [...state[parentId].replies!] };
					if (parent) {
						const index = parent.replies.indexOf(id);
						if (index >= 0) {
							parent.replies.splice(index, 1);
							nextState[parentId] = parent;

							// when a root comment was deleted which had replies, it was left in the state with isDeleted marked as true
							// now, if the last child of this root comment is also being deleted, we should remove the entire tree from state
							if (parent.replies.length === 0 && parent.isDeleted) {
								delete nextState[parentId];
							}
						}
					}
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				} else if (state[id].replies && state[id].replies!.length > 0) {
					// handle root comment with replies
					// when there are replies present, the root comment should not be deleted from state but rather its isDeleted property should be marked as true
					const comment = { ...state[id], isDeleted: true };
					nextState[id] = comment;
					// if there are replies present and root comment is deleted, preserve it
					return nextState;
				}
			}

			delete nextState[id];
			return nextState;
		}
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
