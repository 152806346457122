import React, {
	// eslint-disable-next-line jira/restricted/react
	PureComponent,
	Fragment,
	// eslint-disable-next-line jira/restricted/react-component-props
	type ComponentProps,
	type ReactElement,
	useMemo,
} from 'react';
import noop from 'lodash/noop';
import type { PreloadedQuery } from 'react-relay';
import { token } from '@atlaskit/tokens';
import { AnalyticsSubject } from '@atlassian/jira-analytics-web-react/src/components/decorators.tsx';
import {
	AGILITY_BACKLOG,
	CORE_ISSUE,
	EMBED_ISSUE,
	FULL_ISSUE,
	MOBILE_ISSUE,
	SERVICEDESK_QUEUES_ISSUE,
	SERVICEDESK_REPORTS_ISSUE,
	SOFTWARE_ISSUE,
	type AnalyticsSource,
} from '@atlassian/jira-common-constants/src/analytics-sources.tsx';
import type {
	IssueViewRelayFragment,
	MainIssueAggQueryRelayFragment,
} from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/main.tsx';
import { StickyHeaderTrackerContainer } from '@atlassian/jira-issue-header-tracker-service/src/services/sticky-header/index.tsx';
import { ELID_ISSUE_HEADER } from '@atlassian/jira-issue-view-common-constants/src/index.tsx';
import { isKeyEscapeEvent } from '@atlassian/jira-issue-view-common-utils/src/events/index.tsx';
import { markEvalEnd } from '@atlassian/jira-issue-view-common-utils/src/utils/mark-issue-app-eval.tsx';
import type PrefetchedResourceManager from '@atlassian/jira-issue-view-common-utils/src/utils/prefetched-resources/prefetched-resource-manager/index.tsx';
import { PrefetchedResourceStoreContainer } from '@atlassian/jira-issue-view-common-utils/src/utils/prefetched-resources/prefetched-resource-manager/index.tsx';
import { CompactModeProvider } from '@atlassian/jira-issue-view-compact-mode/src/services/provider/index.tsx';
import { IssueViewErrorBoundary } from '@atlassian/jira-issue-view-error-boundary/src/ui/issue-view-error-boundary/index.tsx';
import { IssueViewExperienceTrackingProviders } from '@atlassian/jira-issue-view-experience-tracking-providers/src/index.tsx';
import DocumentTitle from '@atlassian/jira-issue-view-foundation/src/document-title/index.tsx';
import { ErrorPage } from '@atlassian/jira-issue-view-foundation/src/error-page/index.tsx';
import Header from '@atlassian/jira-issue-view-foundation/src/header/index.tsx';
import {
	clickthroughAllowedSources,
	type FeedbackRenderer,
} from '@atlassian/jira-issue-view-model/src/feedback-type.tsx';
import type { ViewModeOptions } from '@atlassian/jira-issue-view-model/src/view-mode-options.tsx';
import StickyHeaderContainer from '@atlassian/jira-issue-view-sticky-header-container/src/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { usePrevious } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import type {
	mainIssueAggQuery as mainIssueAggQueryType,
	mainIssueAggQuery$data,
} from '@atlassian/jira-relay/src/__generated__/mainIssueAggQuery.graphql';
import { IssueViewSkeleton } from '@atlassian/jira-skeletons/src/ui/issue/IssueViewSkeleton.tsx';
import { MarkProductStart } from '@atlassian/jira-spa-performance-breakdown/src/utils/mark-product-start/index.tsx';
import { StorageLimitsBanner } from '@atlassian/jira-storage-limits-banner/src/ui/async.tsx';
import UFOLabel from '@atlassian/jira-ufo-label/src/index.tsx';
import { UpFlowPersistentUpgradeBanner } from '@atlassian/jira-up-flow-persistent-upgrade-banner/src/async.tsx';
import { markStartIssue } from '../../common/metrics/index.tsx';
import AssignIssueParentModal from '../assign-issue-parent-modal/index.tsx';
import Banner from '../banner/index.tsx';
import type { TopBannerRenderer } from '../banner/view.tsx';
import { ChangeBoardingTour } from '../changeboarding/ui/index.tsx';
import { renderFeedback as fullPageRenderFeedback } from '../feedback/index.tsx';
import AppProvider from './app-provider/index.tsx';
import {
	IssueViewCriticalQueryPreloader,
	IssueViewRouteResourceConsumer,
} from './app-provider/resource-manager/index.tsx';
import type { AppProviderWithConfigProps } from './app-provider/types.tsx';
import ChangeManagementTour from './change-management-tour.tsx';
import IncidentManagementTour from './incident-management-tour.tsx';
import {
	SingleColumnSectionWrapper,
	SingleColumnSectionWrapperInner,
} from './issue-app.styled.tsx';
import IssueView from './issue-view.tsx';
import type { IssueViewRenderProps } from './issue-view.types.tsx';

/**
 * Locations that allow to close on Escape being pressed
 */
const closeOnEscapeLocations = new Set([SOFTWARE_ISSUE, AGILITY_BACKLOG]);

type HeaderConfig = JSX.LibraryManagedAttributes<typeof Header, ComponentProps<typeof Header>>;

type IssueAppPropsInternal = {
	isSpaEnabled: boolean;
	issueMaxWidth?: number;
	shouldSetInitialFocus: boolean;
	shouldShowCloseButton?: boolean;
	shouldShowProjectLevelBreadcrumb?: boolean;
	shouldShowRootProjectsBreadcrumb: boolean;
	/** Used to avoid the scrolling hotkeys in the IssueView from working. This props is `false` by default */
	isScrollingHotkeysDisabled?: boolean;
	returnFocusRef?: React.RefObject<HTMLElement>;
} & AppProviderWithConfigProps & {
		viewModeOptions: ViewModeOptions;
		renderFeedback?: FeedbackRenderer;
		children?: (renderProps: IssueViewRenderProps) => ReactElement;
		renderTopBanner?: TopBannerRenderer;
	} & NonNullable<HeaderConfig['issueDeleteCallbacks']> & {
		onClose: (event?: React.KeyboardEvent | KeyboardEvent | React.MouseEvent) => void;
		prefetchedResourceManager: PrefetchedResourceManager | null;
		issueAggQueryData: mainIssueAggQuery$data | null;
	};

// "embed-issue" analyticSource is set from "browse/{issueKey}/embed" route
// and we consider this as a full page despite the use of word embed in it's name.
const isFullPage = (analyticsSource: AnalyticsSource) =>
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	([FULL_ISSUE, EMBED_ISSUE] as AnalyticsSource[]).includes(analyticsSource);

const getRenderFeedbackForErrorBoundary = (analyticsSource: AnalyticsSource) => {
	// this should ideally be using isClickthroughAllowedSelector within the feedback component
	if (clickthroughAllowedSources.includes(analyticsSource)) {
		return () => fullPageRenderFeedback('');
	}
	return undefined;
};

// TODO Decomp BENTO-12515 - convert to functional component
// eslint-disable-next-line jira/react/no-class-components
class IssueAppInner extends PureComponent<IssueAppPropsInternal> {
	static defaultProps = {
		isLoadedWithPage: false,
		isSpaEnabled: false,
		shouldShowRootProjectsBreadcrumb: false,
		viewModeOptions: { viewModeSwitchEnabled: false },
		onClose: noop,
		onIssueDeleteSuccess: noop,
		onIssueDeleteFailure: noop,
	};

	componentDidMount() {
		if (this.canCloseOnEsc()) {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.addEventListener('keydown', this.onEscape);
		}
	}

	componentWillUnmount() {
		if (this.canCloseOnEsc()) {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.removeEventListener('keydown', this.onEscape);
		}
	}

	onEscape = (event: KeyboardEvent) => {
		if (isKeyEscapeEvent(event)) {
			this.props.onClose(event);
			event.preventDefault();
		}
	};

	canCloseOnEsc = (): boolean => closeOnEscapeLocations.has(this.props.analyticsSource);

	getRenderFeedback = () => {
		const { analyticsSource } = this.props;
		// this should ideally be using isClickthroughAllowedSelector within the feedback component
		if (clickthroughAllowedSources.includes(analyticsSource)) {
			return fullPageRenderFeedback;
		}
		return undefined;
	};

	renderStorageLimitsBanner = () => {
		const { analyticsSource } = this.props;
		if (clickthroughAllowedSources.includes(analyticsSource)) {
			return <StorageLimitsBanner isFullWidth />;
		}
		return null;
	};

	renderTopBanner = () => {
		const { analyticsSource } = this.props;

		if (clickthroughAllowedSources.includes(analyticsSource)) {
			return <UpFlowPersistentUpgradeBanner isBannerEnabled isFullWidth />;
		}
		return null;
	};

	renderIssueView = (
		issueViewRelayFragment: IssueViewRelayFragment | null | undefined,
		rootRelayFragment: MainIssueAggQueryRelayFragment | null,
	) => {
		const issueViewProps = this.getIssueViewProps(issueViewRelayFragment, rootRelayFragment);
		return <IssueView {...issueViewProps} />;
	};

	renderChangeboardingTour = () => {
		/** sources that show "take a tour" in the dropdown  */
		const analyticsSourceTourWhitelist = [
			FULL_ISSUE,
			SERVICEDESK_QUEUES_ISSUE,
			SERVICEDESK_REPORTS_ISSUE,
			CORE_ISSUE,
		];

		const shouldRenderChangeboarding = analyticsSourceTourWhitelist.includes(
			this.props.analyticsSource,
		);

		return shouldRenderChangeboarding ? <ChangeBoardingTour /> : null;
	};

	isMobile = () => this.props.analyticsSource === MOBILE_ISSUE;

	isFullPage = (analyticsSource: AnalyticsSource) => isFullPage(analyticsSource);

	renderHeader(
		issueViewRelayFragment: IssueViewRelayFragment | null | undefined,
		rootRelayFragment: MainIssueAggQueryRelayFragment | null,
	) {
		const {
			onClose,
			shouldShowCloseButton,
			shouldShowProjectLevelBreadcrumb,
			shouldShowRootProjectsBreadcrumb,
			onIssueDeleteSuccess,
			onIssueDeleteFailure,
			viewModeOptions,
		} = this.props;

		if (!this.isMobile()) {
			return null;
		}

		return (
			<StickyHeaderContainer
				shouldUseDeprecatedScrollParent
				elementId={ELID_ISSUE_HEADER}
				style={{
					// TODO Delete this comment after verifying space token -> previous value ``${gridSize * 3}px``
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					paddingLeft: token('space.300', '24px'),
					// TODO Delete this comment after verifying space token -> previous value ``${gridSize * 3}px``
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					paddingRight: token('space.300', '24px'),
				}}
			>
				<Header
					shouldShowCloseButton={!!shouldShowCloseButton}
					shouldShowProjectLevelBreadcrumb={!!shouldShowProjectLevelBreadcrumb}
					shouldShowRootProjectsBreadcrumb={!!shouldShowRootProjectsBreadcrumb}
					issueDeleteCallbacks={{ onIssueDeleteSuccess, onIssueDeleteFailure }}
					onClose={onClose}
					renderFeedback={this.getRenderFeedback()}
					viewModeOptions={viewModeOptions}
					issueViewRelayFragment={issueViewRelayFragment}
					rootRelayFragment={rootRelayFragment}
				/>
			</StickyHeaderContainer>
		);
	}

	getIssueViewProps = (
		issueViewRelayFragment: IssueViewRelayFragment | null,
		rootRelayFragment: MainIssueAggQueryRelayFragment | null,
	): IssueViewRenderProps => ({
		renderFeedback: this.getRenderFeedback(),
		issueDeleteCallbacks: {
			onIssueDeleteSuccess: this.props.onIssueDeleteSuccess,
			onIssueDeleteFailure: this.props.onIssueDeleteFailure,
		},
		viewModeOptions: this.props.viewModeOptions,
		onClose: this.props.onClose,
		shouldSetInitialFocus: this.props.shouldSetInitialFocus,
		shouldShowProjectLevelBreadcrumb: this.props.shouldShowProjectLevelBreadcrumb,
		shouldShowRootProjectsBreadcrumb: this.props.shouldShowProjectLevelBreadcrumb,
		shouldShowCloseButton: this.props.shouldShowCloseButton,
		issueMaxWidth: this.props.issueMaxWidth,
		isScrollingHotkeysDisabled: this.props.isScrollingHotkeysDisabled,
		issueViewRelayFragment,
		rootRelayFragment,
	});

	render() {
		const {
			analyticsSource,
			backButtonUrl,
			dispatchExternalActionRef,
			ecosystemEnabled,
			estimationStatistic,
			history,
			isLoadedWithPage,
			isSpaEnabled,
			issueActions,
			issueKey,
			loginRedirectUrl,
			metrics,
			onChange,
			onError,
			onIssueKeyChange,
			rapidViewId,
			rapidViewMap,
			viewModeOptions,
			issueMaxWidth,
			issueNavigatorData,
			prefetchedResourceManager,
			issueAggQueryData,
		} = this.props;

		const appProviderProps: AppProviderWithConfigProps = {
			analyticsSource,
			backButtonUrl,
			dispatchExternalActionRef,
			ecosystemEnabled,
			estimationStatistic,
			history,
			isLoadedWithPage,
			isSpaEnabled,
			issueActions,
			issueKey,
			issueNavigatorData,
			loginRedirectUrl,
			metrics,
			onChange,
			onError,
			onIssueKeyChange,
			rapidViewId,
			rapidViewMap,
			viewMode: viewModeOptions && viewModeOptions.viewMode,
		};

		const SingleColumnSectionWrapperFF = !this.isMobile() ? SingleColumnSectionWrapper : Fragment;

		const SingleColumnSectionWrapperInnerFF = !this.isMobile()
			? SingleColumnSectionWrapperInner
			: Fragment;

		const compactModeChildren = (
			issueViewRelayFragment: IssueViewRelayFragment | null | undefined,
			rootRelayFragment: MainIssueAggQueryRelayFragment | null,
		) => (
			<StickyHeaderTrackerContainer scope="issue-app">
				{/* @ts-expect-error - TS2322 - Type 'number | undefined' is not assignable to type 'string | number'. */}
				<SingleColumnSectionWrapperFF issueMaxWidth={issueMaxWidth}>
					<SingleColumnSectionWrapperInnerFF>
						{this.renderChangeboardingTour()}
						{/* The storage limits banner is only rendered inside JSW projects. The top banner (i.e. UpFlowPersistentUpgradeBanner)
                            is fully replaced by Edition Awareness on JSW, so we won't get a case where two banners appear in the issue app. */}
						<Banner renderTopBanner={this.renderStorageLimitsBanner} />
						<Banner renderTopBanner={this.renderTopBanner} />
						{this.renderHeader(issueViewRelayFragment, rootRelayFragment)}
					</SingleColumnSectionWrapperInnerFF>
				</SingleColumnSectionWrapperFF>
				<AssignIssueParentModal />
				{this.renderIssueView(issueViewRelayFragment, rootRelayFragment)}
				<ChangeManagementTour currentIssueKey={issueKey} />
				<IncidentManagementTour currentIssueKey={issueKey} />
			</StickyHeaderTrackerContainer>
		);

		const renderAppProviderChildren = (
			issueViewRelayFragment: IssueViewRelayFragment | null | undefined,
			rootRelayFragment: MainIssueAggQueryRelayFragment | null,
		) => (
			<>
				<DocumentTitle location={analyticsSource} />
				<CompactModeProvider>
					{compactModeChildren(issueViewRelayFragment, rootRelayFragment)}
				</CompactModeProvider>
			</>
		);

		// the appProvider no longer throws errors on AGG failures, the error boundary needs to be pulled above the IssueViewCriticalQueryPreloader
		return (
			<UFOLabel name="issue-app">
				{this.isFullPage(analyticsSource) && <MarkProductStart />}
				<AppProvider
					prefetchedResourceManager={prefetchedResourceManager}
					issueAggQueryData={issueAggQueryData}
					preloadedQuery={undefined}
					giraResponsePromise={undefined}
					{...appProviderProps}
				>
					{renderAppProviderChildren}
				</AppProvider>
			</UFOLabel>
		);
	}
}

type IssueAppInnerProps = JSX.LibraryManagedAttributes<
	typeof IssueAppInner,
	ComponentProps<typeof IssueAppInner>
>;
export type IssueAppProps = Omit<
	IssueAppInnerProps,
	'prefetchedResourceManager' | 'issueAggQueryData'
>;
type IssueAppPropsTakingPreloadedQuery = IssueAppInnerProps & {
	preloadedQuery: PreloadedQuery<mainIssueAggQueryType> | null;
};

// this is the first thing to be called in the component
export const useMarkStartIssue = (props: IssueAppProps) => {
	const { analyticsSource, issueKey } = props;
	const previousCurrentIssueKey = usePrevious(issueKey);
	useMemo(() => {
		if (issueKey !== previousCurrentIssueKey) {
			markStartIssue(isFullPage(analyticsSource));
		}
	}, [analyticsSource, issueKey, previousCurrentIssueKey]);
};

function renderWithErrorBoundary(props: IssueAppProps, node: JSX.Element) {
	return (
		<IssueViewExperienceTrackingProviders
			issueKey={props.issueKey}
			analyticsSource={props.analyticsSource}
			isExperienceReady
		>
			<IssueViewErrorBoundary
				errorBoundaryId="issue-view-app-provider"
				renderFeedback={getRenderFeedbackForErrorBoundary(props.analyticsSource)}
				loginRedirectUrl={props.loginRedirectUrl}
				issueKey={props.issueKey}
				fallback={(errorComponent) =>
					props.viewModeOptions?.viewMode === 'SIDEBAR' ? (
						<ErrorPage
							// eslint-disable-next-line @typescript-eslint/no-empty-function
							onClose={props.onClose ?? (() => {})}
							renderFeedback={getRenderFeedbackForErrorBoundary(props.analyticsSource)}
						>
							{errorComponent}
						</ErrorPage>
					) : (
						errorComponent
					)
				}
			>
				{node}
			</IssueViewErrorBoundary>
		</IssueViewExperienceTrackingProviders>
	);
}

const IssueAppInnerWithAnalyticsSubject = AnalyticsSubject('issue')(IssueAppInner);

export const IssueAppWithRouteResources = ({
	preloadedQuery,
	...props
}: IssueAppPropsTakingPreloadedQuery) => {
	useMarkStartIssue(props);
	return preloadedQuery ? (
		<PrefetchedResourceStoreContainer>
			{renderWithErrorBoundary(
				props,
				<IssueViewRouteResourceConsumer issueKey={props.issueKey} preloadedQuery={preloadedQuery}>
					{({ prefetchedResourceManager, issueAggQueryData }) => (
						<IssueAppInnerWithAnalyticsSubject
							{...props}
							prefetchedResourceManager={prefetchedResourceManager}
							issueAggQueryData={issueAggQueryData}
						/>
					)}
				</IssueViewRouteResourceConsumer>,
			)}
		</PrefetchedResourceStoreContainer>
	) : (
		<IssueViewSkeleton />
	);
};

const IssueAppWithCriticalQueryPreloader = (props: IssueAppProps) => {
	useMarkStartIssue(props);
	return (
		<PrefetchedResourceStoreContainer>
			<Placeholder name="issue-view-skeleton" fallback={<IssueViewSkeleton />}>
				{renderWithErrorBoundary(
					props,
					<IssueViewCriticalQueryPreloader issueKey={props.issueKey}>
						{({ prefetchedResourceManager, issueAggQueryData }) => (
							<IssueAppInnerWithAnalyticsSubject
								{...props}
								prefetchedResourceManager={prefetchedResourceManager}
								issueAggQueryData={issueAggQueryData}
							/>
						)}
					</IssueViewCriticalQueryPreloader>,
				)}
			</Placeholder>
		</PrefetchedResourceStoreContainer>
	);
};

export default IssueAppWithCriticalQueryPreloader;

markEvalEnd();
