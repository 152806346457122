/**
 * @generated SignedSource<<bf459c05be47aae43add07479c450184>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutAssigneeField_IssueViewAssigneeField$data = {
  readonly __typename: "JiraSingleSelectUserPickerField";
  readonly fieldId: string;
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"assignToMeInlineEdit_issueViewLayoutAssigneeField_fragmentKey" | "assignee_issueFieldAssigneeInlineEditFull_AssigneeInlineEditView_fragmentRef" | "ui_issueViewLayoutFieldHeading_IssueViewFieldHeading">;
  readonly " $fragmentType": "ui_issueViewLayoutAssigneeField_IssueViewAssigneeField";
};
export type ui_issueViewLayoutAssigneeField_IssueViewAssigneeField$key = {
  readonly " $data"?: ui_issueViewLayoutAssigneeField_IssueViewAssigneeField$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutAssigneeField_IssueViewAssigneeField">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutAssigneeField_IssueViewAssigneeField",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "ui_issueViewLayoutFieldHeading_IssueViewFieldHeading"
    },
    {
      "kind": "FragmentSpread",
      "name": "assignee_issueFieldAssigneeInlineEditFull_AssigneeInlineEditView_fragmentRef"
    },
    {
      "kind": "FragmentSpread",
      "name": "assignToMeInlineEdit_issueViewLayoutAssigneeField_fragmentKey"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "ScalarField",
      "name": "__typename"
    }
  ],
  "type": "JiraSingleSelectUserPickerField"
};

(node as any).hash = "64eb22070529d1638511032e08070b8a";

export default node;
