import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { FireUiAnalytics } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';

export const NoResultsFound = () => {
	const { formatMessage } = useIntl();
	return (
		<MessageWrapper>
			<FireUiAnalytics
				actionSubject="message"
				action="viewed"
				actionSubjectId="createPullRequestNoSearchResultsFoundMessage"
			/>
			{formatMessage(messages.noResultsFound)}
		</MessageWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MessageWrapper = styled.div({
	textAlign: 'center',
	margin: token('space.100', '8px'),
});
