import React, { useMemo } from 'react';
import { usePracticesFieldValue } from '@atlassian/jira-issue-field-servicedesk-practices/src/services/use-practices-field-value/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { INCIDENTS } from '@atlassian/jira-servicedesk-work-category/src/common/constants.tsx';
import { lazyAfterPaint } from '@atlassian/react-loosely-lazy';
import type IncidentInvestigationPanelType from './main.tsx';
import type { IncidentInvestigationPanelProps } from './types.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const IncidentInvestigationPanel = lazyAfterPaint<typeof IncidentInvestigationPanelType>(
	() =>
		import(
			/* webpackChunkName: "async-servicedesk-incident-management-issue-view-incident-investigation" */ './main'
		),
	{ ssr: false },
);

const AsyncIncidentInvestigationPanel = (props: IncidentInvestigationPanelProps) => {
	const [practices] = usePracticesFieldValue({ issueKey: props.issueKey });
	const isIncident = useMemo(
		() => Array.isArray(practices) && practices.includes(INCIDENTS),
		[practices],
	);

	if (isIncident) {
		return (
			<Placeholder
				name="servicedesk-incident-management-issue-view-incident-investigation"
				fallback={null}
			>
				<IncidentInvestigationPanel {...props} />
			</Placeholder>
		);
	}

	return null;
};

export default AsyncIncidentInvestigationPanel;
