import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	aup: {
		id: 'servicedesk-ai-context-common.aup',
		defaultMessage: 'Atlassian’s Acceptable Use Policy',
		description: "Full text for Atlassian's AUP",
	},
	aupError: {
		id: 'servicedesk-ai-context-common.aup-error',
		defaultMessage:
			'Atlassian Intelligence can’t provide a response as it may not comply with {policyLink}.',
		description:
			'Error message that is displayed when there is an Acceptable Use Policy error from the API',
	},
	error: {
		id: 'servicedesk-ai-context-common.error',
		defaultMessage: "Atlassian Intelligence isn't responding at the moment.",
		description: 'Error message that is displayed when there is an error from the API',
	},
	errorWithRefresh: {
		id: 'servicedesk-ai-context-common.error-refresh',
		defaultMessage:
			"Atlassian Intelligence isn't responding. Wait a moment, then refresh the page.",
		description:
			'Error message that is displayed when there is an error from the API and there is a possibility of a refresh solving it',
	},
});
