import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { CreatedDateField as CreatedDateView } from '@atlassian/jira-created-date/src/main.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { ResolvedDateField as ResolvedDateView } from '@atlassian/jira-resolved-date/src/main.tsx';
import { UpdatedDateField as UpdatedDateView } from '@atlassian/jira-updated-date/src/main.tsx';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	marginTop: token('space.050', '4px'),
	marginRight: token('space.200', '16px'),
	flex: 1,
	paddingLeft: token('space.150', '12px'),
});

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => {
	const issueKey = useIssueKey();
	return (
		<Container>
			<CreatedDateView issueKey={issueKey} />
			<UpdatedDateView issueKey={issueKey} />
			<ResolvedDateView issueKey={issueKey} />
		</Container>
	);
};
