import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	requiredErrorMessage: {
		id: 'issue-field-date-inline-edit-full.date.required-error-message',
		defaultMessage: '{fieldName} is required',
		description:
			'Validation message shown to the user when they attempt to clear a required field.',
	},
});
