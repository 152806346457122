import { createViewAccessStateHook } from '../main.tsx';
import type { State } from '../types.tsx';

const getViewAccessMap = (state: State) => state.viewAccessMap;

const getHasManageViewsPermission = (state: State) => state.hasManageViewsPermission;

const getCurrentViewAccessConfig = (state: State) => state.config;

const getCurrentViewAccess = (state: State) => ({
	accessLevel: state.accessLevel,
	additionalAccess: state.additionalAccess,
});

export const useViewAccessMap = createViewAccessStateHook(getViewAccessMap);

export const useHasManageViewsPermission = createViewAccessStateHook(getHasManageViewsPermission);

export const useCurrentViewAccessConfig = createViewAccessStateHook(getCurrentViewAccessConfig);

export const useCurrentViewAccess = createViewAccessStateHook(getCurrentViewAccess);
