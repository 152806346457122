import mapValues from 'lodash/mapValues';
import omit from 'lodash/omit';
import without from 'lodash/without';
import type { IssueTypeId, ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { performDeleteIssueType } from '../../services/jira/delete-issue-type.tsx';
import type { State } from '../types.tsx';

export const deleteIssueType =
	(issueTypeId: IssueTypeId, projectId: ProjectId) =>
	({ setState, getState }: StoreActionApi<State>) => {
		return performDeleteIssueType(issueTypeId, projectId).then(() => {
			setState({
				issueTypesById: omit(getState().issueTypesById, issueTypeId),
				issueTypesByProjectId: mapValues(getState().issueTypesByProjectId, (issueTypeIds) =>
					without(issueTypeIds, issueTypeId),
				),
			});
		});
	};
