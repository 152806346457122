import React, { Component, type SyntheticEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { injectIntlV2 as injectIntl } from '@atlassian/jira-intl/src/v2/inject.tsx';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import {
	fireOperationalAnalytics,
	fireUIAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import {
	type IssueTypeId,
	type ProjectId,
	toIssueTypeId,
	toProjectId,
} from '@atlassian/jira-shared-types/src/general.tsx';
import ViewWorkflowButton from '../../../common/ui/view-workflow/button/index.tsx';
import messages from '../../../common/ui/view-workflow/messages.tsx';

export type Props = {
	isModalMounted: boolean;
	isServiceLoading: boolean;
	isServiceRefetching: boolean;
	issueTypeId?: IssueTypeId;
	hasPermission?: boolean;
	projectId?: ProjectId;
	onOpenModal: (issueTypeId: IssueTypeId, projectId: ProjectId) => void;
};

type ViewProps = Props & {
	intl: IntlShape;
};

type State = {
	isLoadingModal: boolean;
};

// eslint-disable-next-line jira/react/no-class-components
class ViewWorkflowSimplified extends Component<ViewProps, State> {
	static defaultProps: Partial<ViewProps> = {
		hasPermission: false,
	};

	state: State = {
		isLoadingModal: false,
	};

	onMount = (analyticsEvent: UIAnalyticsEvent) => {
		const { hasPermission } = this.props;

		fireOperationalAnalytics(analyticsEvent, 'viewWorkflowTrigger mounted', {
			hasViewWorkflowPermission: hasPermission,
			isSimplifiedProject: true,
		});
	};

	onClick = (event: SyntheticEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
		fireUIAnalytics(analyticsEvent, 'viewWorkflowTrigger', {
			isSimplifiedProject: true,
		});

		this.setState({
			// modal code is being loaded only once, therefore there is no need to reset this to false
			isLoadingModal: true,
		});

		const {
			issueTypeId = toIssueTypeId(''),
			projectId = toProjectId(''),
			onOpenModal,
		} = this.props;

		onOpenModal(issueTypeId, projectId);
	};

	getTooltipMessage() {
		const { isServiceLoading, isServiceRefetching, hasPermission, intl } = this.props;

		if (isServiceLoading || isServiceRefetching) {
			return intl.formatMessage(messages.loading);
		}

		if (hasPermission !== true) {
			return intl.formatMessage(messages.noPermission);
		}

		return '';
	}

	render() {
		const { isServiceLoading, isServiceRefetching, hasPermission, isModalMounted } = this.props;
		const { isLoadingModal } = this.state;

		const isModalMounting = isLoadingModal && !isModalMounted;
		const isDisabled = hasPermission !== true || isModalMounting;
		const isLoading = isServiceRefetching || isModalMounting;
		const tooltipContent = this.getTooltipMessage();

		return (
			<ViewWorkflowButton
				onMount={this.onMount}
				tooltipContent={tooltipContent}
				isDisabled={isDisabled}
				isLoading={isLoading}
				isInitialLoading={isServiceLoading}
				onClick={this.onClick}
			/>
		);
	}
}

export default injectIntl(ViewWorkflowSimplified);
