import { useScrollStoreScrollTarget, useScrollStoreScrollTargetActions } from './context.tsx';

export const useIssueScroll = () => {
	const [scrollTarget, { setScrollTarget, getScrollTargetId }] = useScrollStoreScrollTarget();

	return [{ scrollTarget }, { setScrollTarget, getScrollTargetId }] as const;
};

export const useIssueScrollActions = () => {
	const [, { setScrollTarget, getScrollTargetId }] = useScrollStoreScrollTargetActions();
	return [undefined, { setScrollTarget, getScrollTargetId }] as const;
};
