import { createIndexedDBStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/indexed-db/index.tsx';
import type { AsyncStorage } from '@atlassian/jira-browser-storage-providers/src/types.tsx';
import { setMark } from '@atlassian/jira-common-performance/src/marks.tsx';
import { expVal, expValEquals } from '@atlassian/jira-feature-experiments';
import { getAnalyticsWebClientPromise } from '@atlassian/jira-product-analytics-web-client-async';
import { createCacheKey } from '../create-cache-key/index.tsx';

const jiraCache: AsyncStorage = createIndexedDBStorageProvider('jira-cache', {
	shouldAutoReconnect: true,
});

const SEVEN_DAYS_IN_MILLIS = 7 * 24 * 60 * 60 * 1000;

const getEndpoint = (url: string): string => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const urlObj = new URL(url, window.location.origin);
	const pathname = urlObj.pathname;
	const segments = pathname.split('/');
	return segments[segments.length - 1];
};

export const withCache =
	<T,>(fn: (url: string, options: RequestInit) => Promise<T>) =>
	async <E,>(
		url: string,
		onFnErrorCacheHandler?: (error: E) => T,
		maxAge: number = SEVEN_DAYS_IN_MILLIS,
		options: RequestInit = {},
	): Promise<T> => {
		if (!expValEquals('jira_caching_layer_milestone_1_exp', 'cohort', 'variation')) {
			return fn(url, options);
		}
		const cachedKey = createCacheKey(url);
		const cacheReadStartTime = performance.now();
		setMark('indexed-db-cache-read:start');
		const cachedData = await jiraCache.get(cachedKey);
		setMark('indexed-db-cache-read:end');
		const cacheReadStopTime = performance.now();
		getAnalyticsWebClientPromise().then((client) => {
			const payload = {
				action: 'read',
				actionSubject: 'jiraCacheLayerCacheOperation',
				source: 'jiraCacheLayer',
				attributes: {
					duration: (cacheReadStopTime - cacheReadStartTime).toFixed(2),
				},
			};
			client.getInstance().sendTrackEvent(payload);
		});
		if (cachedData !== undefined && Date.now() < cachedData.expiresAt) {
			return cachedData.data;
		}
		let data: T;
		try {
			const fetchStartTime = performance.now();
			data = await fn(url, options);
			const fetchStopTime = performance.now();
			getAnalyticsWebClientPromise().then((client) => {
				const payload = {
					action: 'succeeded',
					actionSubject: 'jiraCacheExperimentFetch',
					source: 'jiraCacheLayer',
					attributes: {
						cohort: expVal('jira_caching_layer_milestone_1_exp', 'cohort', 'not-enrolled'),
						duration: (fetchStopTime - fetchStartTime).toFixed(2),
						endpoint: getEndpoint(url),
					},
				};
				client.getInstance().sendTrackEvent(payload);
			});
		} catch (error: unknown) {
			if (onFnErrorCacheHandler) {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				data = onFnErrorCacheHandler(error as E);
			} else {
				throw error;
			}
		}
		const cacheWriteStartTime = performance.now();
		setMark('indexed-db-cache-write:start');
		jiraCache.set(cachedKey, { data, expiresAt: Date.now() + maxAge }).then(() => {
			setMark('indexed-db-cache-write:end');
			const cacheWriteStopTime = performance.now();
			getAnalyticsWebClientPromise().then((client) => {
				const payload = {
					action: 'write',
					actionSubject: 'jiraCacheLayerCacheOperation',
					source: 'jiraCacheLayer',
					attributes: {
						duration: (cacheWriteStopTime - cacheWriteStartTime).toFixed(2),
					},
				};
				client.getInstance().sendTrackEvent(payload);
			});
		});
		return data;
	};
