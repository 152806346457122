import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	added: {
		id: 'issue-history.history-items.history-item.flag-history-item.added',
		defaultMessage: 'added a {flag}',
		description: `Describes that a user has added a flag to the issue.
            Part of the heading in History feed, appears after the display name of the user who made the change.
            {flag} is the 'Flag' field label.`,
	},
	removed: {
		id: 'issue-history.history-items.history-item.flag-history-item.removed',
		defaultMessage: 'removed a {flag}',
		description: `Describes that a user has removed a flag from the issue.
            Part of the heading in History feed, appears after the display name of the user who made the change.
            {flag} is the 'Flag' field label.`,
	},
	flag: {
		id: 'issue-history.history-items.history-item.flag-history-item.flag',
		defaultMessage: 'Flag',
		description: "(noun) Value to display as label for the 'Flag' field.",
	},
	flagLowercase: {
		id: 'issue-history.history-items.history-item.flag-history-item.flag-lowercase',
		defaultMessage: 'flag',
		description: "(noun) Value to display as label for the 'flag' field.",
	},
});
