/**
 * @generated SignedSource<<1feefca43a5d55f7379f8711ea87e3fd>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type singleSelect_issueFieldSingleSelectReadviewFull_SingleSelectReadView$data = {
  readonly fieldOption: {
    readonly color?: {
      readonly colorKey: string | null | undefined;
    } | null | undefined;
    readonly value: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "singleSelect_issueFieldSingleSelectReadviewFull_SingleSelectReadView";
};
export type singleSelect_issueFieldSingleSelectReadviewFull_SingleSelectReadView$key = {
  readonly " $data"?: singleSelect_issueFieldSingleSelectReadviewFull_SingleSelectReadView$data;
  readonly " $fragmentSpreads": FragmentRefs<"singleSelect_issueFieldSingleSelectReadviewFull_SingleSelectReadView">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone1experimentrelayprovider"
    }
  ],
  "kind": "Fragment",
  "name": "singleSelect_issueFieldSingleSelectReadviewFull_SingleSelectReadView",
  "selections": [
    {
      "concreteType": "JiraOption",
      "kind": "LinkedField",
      "name": "fieldOption",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "value"
        },
        {
          "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcthorcolorfulsingleselectmilestone1experimentrelayprovider",
          "kind": "Condition",
          "passingValue": true,
          "selections": [
            {
              "concreteType": "JiraColor",
              "kind": "LinkedField",
              "name": "color",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "name": "colorKey"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraSingleSelectField"
};

(node as any).hash = "84a4de02de9677e683b23d07051f7494";

export default node;
