import parameters from '@atlassian/jira-relay/src/__generated__/ui_JPDIdeaViewBentoSidebarQuery.graphql';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

type EntryPointParams = { issueKey: string; cloudId: string };

export const IdeaViewBentoSidebarEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() =>
			import(/* webpackChunkName: "async-polaris-component-idea-view-bento-sidebar" */ './src/ui'),
	),
	getPreloadProps: ({ issueKey, cloudId }: EntryPointParams) => ({
		queries: {
			issueData: {
				options: {
					fetchPolicy: 'network-only' as const,
				},
				parameters,
				variables: {
					issueKey,
					cloudId,
				},
			},
		},
	}),
});
