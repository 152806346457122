import type { ProjectType } from '@atlassian/jira-common-constants/src/project-types.tsx';

import { getTenantContext_DEPRECATED_DO_NOT_USE } from '@atlassian/jira-common-util-get-tenant-context/src/index.tsx';
import { getApplicationForProject } from '@atlassian/jira-shared-types/src/application.tsx';
import { getEdition } from '@atlassian/jira-shared-types/src/edition.tsx';

export type WorklogUpdateAction = 'ADD' | 'DELETE' | 'EDIT';
export type WorklogFetchAction = 'FETCH' | 'FETCH_MORE' | 'FETCH_SORTED' | 'FETCH_SURROUNDING';
export type WorklogAction = WorklogUpdateAction | WorklogFetchAction;

const worklogFetchActionSet = new Set<WorklogAction>([
	'FETCH',
	'FETCH_MORE',
	'FETCH_SORTED',
	'FETCH_SURROUNDING',
]);

type ExperienceDescriptionInput = {
	wasSuccessful: boolean;
	action: WorklogAction;
	analyticsSource: string;
	projectType: ProjectType | null;
	errorMessage?: string;
	statusCode?: number;
};

export const worklogExperienceDescription = ({
	wasSuccessful,
	action,
	analyticsSource,
	projectType,
	errorMessage,
	statusCode,
}: ExperienceDescriptionInput) => {
	let application = null;
	let edition = null;

	const statusCodeProp = typeof statusCode !== 'undefined' ? { statusCode } : { statusCode: 0 };

	if (projectType !== null) {
		try {
			const { appEditions } = getTenantContext_DEPRECATED_DO_NOT_USE();
			application = getApplicationForProject(projectType);
			edition = getEdition(application, appEditions);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			// application and edition already default to null.
		}
	}

	const experience = worklogFetchActionSet.has(action) ? 'worklogFetch' : 'worklogUpdate';

	return {
		experience,
		wasExperienceSuccesful: wasSuccessful,
		analyticsSource,
		application,
		edition,
		additionalAttributes: {
			action,
			...statusCodeProp,
			...(errorMessage !== undefined && errorMessage !== null ? { errorMessage } : {}),
		},
	};
};
