import { createSelector } from 'reselect';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import {
	fieldPendingValueSelector,
	fieldPersistedValueSelector,
	fieldAllowedValuesSelector,
} from './field-selector.tsx';
import { canUseServiceDeskAgentFeaturesSelector } from './permissions-selector.tsx';

export const isRequestLanguageVisibleSelector: (fieldId: string) => (arg1: State) => boolean = (
	fieldId: string,
) =>
	createSelector(
		fieldAllowedValuesSelector(fieldId),
		(requestLanguageDataAllowedValues) =>
			!requestLanguageDataAllowedValues || requestLanguageDataAllowedValues.length > 1,
	);

export const isRequestFeedbackVisibleSelector: (fieldId: string) => (arg1: State) => boolean = (
	fieldId: string,
) =>
	createSelector(
		canUseServiceDeskAgentFeaturesSelector,
		fieldPersistedValueSelector(fieldId),
		(canUseServiceDeskAgentFeatures, fieldPersistedValue) =>
			Boolean(canUseServiceDeskAgentFeatures && fieldPersistedValue && fieldPersistedValue.rating),
	);

export const isParentTypeVisibleSelector: (fieldId: string) => (arg1: State) => boolean = (
	fieldId: string,
) =>
	createSelector(fieldPendingValueSelector(fieldId), (fieldPendingValue) =>
		Boolean(
			fieldPendingValue &&
				(fieldPendingValue.showField || fieldPendingValue.hasEpicLinkFieldDependency),
		),
	);
