import { useMemo } from 'react';
import {
	useWorkflowTransitionsStoreValue,
	useWorkflowTransitionsStoreActions,
	useWorkflowTransitionsStoreTransitionInProgress,
} from './context.tsx';
import type { WorkflowTransitionsActions } from './types.tsx';

export const useWorkflowTransitionsValue = () => {
	const [value, { setState }] = useWorkflowTransitionsStoreValue();
	return [value, setState] as const;
};

export const useWorkflowTransitionsTransitionInProgress = () => {
	const [value] = useWorkflowTransitionsStoreTransitionInProgress();
	return value;
};

export const useWorkflowTransitionsActions = () => {
	const [, actions] = useWorkflowTransitionsStoreActions();

	const workflowActions: WorkflowTransitionsActions = useMemo(() => {
		// @ts-expect-error - TS7006 - Parameter 'partialState' implicitly has an 'any' type.
		const setState = (partialState) => {
			actions.setState(partialState);
		};

		const setTransitionInProgress = (isLoading: boolean) => {
			actions.setTransitionInProgress(isLoading);
		};

		return {
			setState,
			setTransitionInProgress,
		};
	}, [actions]);

	return [null, workflowActions] as const;
};
