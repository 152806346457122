// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	titleOld: {
		id: 'issue.views.common.child-issues-panel.child-issues-limit-flag.title',
		defaultMessage: 'Best practice: Limit child issues to 100',
		description: 'Title of the child issues limit flag',
	},
	withoutTimetrackingDescriptionOld: {
		id: 'issue.views.common.child-issues-panel.child-issues-limit-flag.without-timetracking-description',
		defaultMessage:
			"The issue view can only display up to 100 child issues. If you add more, you'll have to view them in search.",
		description:
			'Description of the child issues limit flag when the issue does not include the timetracking field',
	},
	withTimetrackingDescriptionOld: {
		id: 'issue.views.common.child-issues-panel.child-issues-limit-flag.with-timetracking-description',
		defaultMessage:
			"The issue view can only display up to 100 child issues. If you add more, you'll have to view them in search and won't be able to include them in time tracking.",
		description:
			'Description of the child issues limit flag when the issue includes the timetracking field',
	},
	title: {
		id: 'issue.views.common.child-issues-panel.child-issues-limit-flag.title-with-limit',
		defaultMessage: 'Best practice: Limit child issues to {limit}',
		description: 'Title of the child issues limit flag',
	},
	withoutTimetrackingDescription: {
		id: 'issue.views.common.child-issues-panel.child-issues-limit-flag.without-timetracking-description-with-limit',
		defaultMessage:
			"The issue view can only display up to {limit} child issues. If you add more, you'll have to view them in search.",
		description:
			'Description of the child issues limit flag when the issue does not include the timetracking field',
	},
	withTimetrackingDescription: {
		id: 'issue.views.common.child-issues-panel.child-issues-limit-flag.with-timetracking-description-with-limit',
		defaultMessage:
			"The issue view can only display up to {limit} child issues. If you add more, you'll have to view them in search and won't be able to include them in time tracking.",
		description:
			'Description of the child issues limit flag when the issue includes the timetracking field',
	},
	learnMoreLink: {
		id: 'issue.views.common.child-issues-panel.child-issues-limit-flag.learn-more-link',
		defaultMessage: 'Learn more',
		description: 'Text for the link to the learn more in-product child issues help',
	},
	titleOldIssueTermRefresh: {
		id: 'issue.views.common.child-issues-panel.child-issues-limit-flag.title-issue-term-refresh',
		defaultMessage: 'Best practice: Limit child issues to 100',
		description: 'Title of the child issues limit flag',
	},
	withoutTimetrackingDescriptionOldIssueTermRefresh: {
		id: 'issue.views.common.child-issues-panel.child-issues-limit-flag.without-timetracking-description-issue-term-refresh',
		defaultMessage:
			"The issue view can only display up to 100 child issues. If you add more, you'll have to view them in search.",
		description:
			'Description of the child issues limit flag when the issue does not include the timetracking field',
	},
	withTimetrackingDescriptionOldIssueTermRefresh: {
		id: 'issue.views.common.child-issues-panel.child-issues-limit-flag.with-timetracking-description-issue-term-refresh',
		defaultMessage:
			"The issue view can only display up to 100 child issues. If you add more, you'll have to view them in search and won't be able to include them in time tracking.",
		description:
			'Description of the child issues limit flag when the issue includes the timetracking field',
	},
	titleIssueTermRefresh: {
		id: 'issue.views.common.child-issues-panel.child-issues-limit-flag.title-with-limit-issue-term-refresh',
		defaultMessage: 'Best practice: Limit child issues to {limit}',
		description: 'Title of the child issues limit flag',
	},
	withoutTimetrackingDescriptionIssueTermRefresh: {
		id: 'issue.views.common.child-issues-panel.child-issues-limit-flag.without-timetracking-description-with-limit-issue-term-refresh',
		defaultMessage:
			"The issue view can only display up to {limit} child issues. If you add more, you'll have to view them in search.",
		description:
			'Description of the child issues limit flag when the issue does not include the timetracking field',
	},
	withTimetrackingDescriptionIssueTermRefresh: {
		id: 'issue.views.common.child-issues-panel.child-issues-limit-flag.with-timetracking-description-with-limit-issue-term-refresh',
		defaultMessage:
			"The issue view can only display up to {limit} child issues. If you add more, you'll have to view them in search and won't be able to include them in time tracking.",
		description:
			'Description of the child issues limit flag when the issue includes the timetracking field',
	},
});
