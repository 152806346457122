import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

const NumberEditViewEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-number-edit-view" */ './ui/number'),
	),
	getPreloadProps: () => ({}),
});

export default NumberEditViewEntryPoint;
