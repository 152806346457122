import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

const PriorityEditViewEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-priority-edit-view" */ './ui/priority'),
	),
	getPreloadProps: () => ({}),
});

export default PriorityEditViewEntryPoint;
