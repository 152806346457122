import type { Locale } from '@atlassian/jira-common-constants/src/supported-locales.tsx';
import type { User } from '@atlassian/jira-shared-types/src/rest/jira/user.tsx';
import type { SiteAdminStatus } from '@atlassian/jira-shared-types/src/tenant-context.tsx';

export type DataBasic = {
	accountId: string | null;
	isAdmin: boolean;
	isAnonymous: boolean;
	isSiteAdmin: boolean;
	locale: Locale;
	siteAdminStatus: SiteAdminStatus;
	userFullname: string;
};

export type DataExtra = User | null;

export type Data = DataBasic & DataExtra;

export function isDataExtra(data: DataBasic | DataExtra): data is DataExtra {
	return data ? 'avatarUrls' in data : false;
}

export type ControllerBasic = {
	data: {
		user: DataBasic;
	};
	error: null;
	loading: false;
};

export type ControllerFull = {
	data: {
		user: Data;
	};
	error: Error | null;
	loading: boolean;
};

export type Controller = ControllerBasic | ControllerFull;
