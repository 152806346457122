import { createSelector } from 'reselect';
import isNumber from 'lodash/isNumber';
import type { TimeTrackingState } from '@atlassian/jira-issue-shared-types/src/common/types/time-tracking-type.tsx';
import {
	type HierarchyLevel,
	isBaseHierarchyLevel,
} from '@atlassian/jira-issue-type-hierarchies/src/index.tsx';
import type {
	NextGenChildIssue,
	Subtask,
} from '@atlassian/jira-issue-view-common-types/src/issue-server-type.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import {
	CHILDREN_ISSUES,
	EPIC_ISSUES,
	SUBTASKS,
	TIME_TRACKING,
} from '@atlassian/jira-issue-view-configurations/src/index.tsx';
import { hierarchyLevelSelector } from '../../../issue-field/state/selectors/hierarchy-level-selector.tsx';
import { persistedChildrenIssuesSelector } from '../../../selectors/children-issues-selector.tsx';
import { hasSubtasksSelector } from '../../../selectors/subtasks-selector.tsx';
import { isMobileSelector } from './context-selector.tsx';
import {
	childIssuesLimitUrlSelector,
	availableFieldIdsSelector,
	fieldPersistedValueSelector,
} from './field-selector.tsx';
import { entitiesSelector, isClassicProjectSelector, uiSelector } from './issue-selector.tsx';
import { permissionsSelector } from './permissions-selector.tsx';

// Deprecated. Use jira-settings-hook instead if possible
export const timeTrackingConfigurationSelector = createSelector(
	entitiesSelector,
	(entities) => entities.jiraSettings.timeTrackingConfiguration,
);

export const timeTrackingSelector: (arg1: State) => TimeTrackingState =
	fieldPersistedValueSelector(TIME_TRACKING);

export const isTimeTrackingEnabledSelector = createSelector(
	timeTrackingConfigurationSelector,
	(timeTrackingConfiguration) => timeTrackingConfiguration.isTimeTrackingEnabled,
);

/**
 * Checks if the time tracking field is an available field and isTimeTrackingEnabled
 * value in the time tracking config to determine if the native Jira time tracking
 * solution is enabled.
 */
export const isNativeJiraTimeTrackingEnabledSelector = createSelector(
	isTimeTrackingEnabledSelector,
	availableFieldIdsSelector,
	(isTimeTrackingEnabled, availableFields) =>
		isTimeTrackingEnabled && availableFields.includes(TIME_TRACKING),
);

export const canLogTimeSelector = createSelector(
	isNativeJiraTimeTrackingEnabledSelector,
	permissionsSelector,
	isMobileSelector,
	(isNativeJiraTimeTrackingEnabled, permissions, isMobile) =>
		isNativeJiraTimeTrackingEnabled && permissions.canLogWork && !isMobile,
);

const worklogUiSelector = createSelector(uiSelector, (ui) => ui.worklog);

const modalSelector = createSelector(worklogUiSelector, (uiState) => uiState.modal);

export const worklogLoadingStageSelector = createSelector(
	worklogUiSelector,
	(uiState) => uiState.loadingStage,
);

export const getSubtasksOrChildrenBelowBaseLevelIssuesSelector = createSelector(
	fieldPersistedValueSelector(SUBTASKS),
	fieldPersistedValueSelector(CHILDREN_ISSUES),
	hierarchyLevelSelector,
	(
		subtasks: Subtask[] | undefined,
		childIssues: NextGenChildIssue[] | undefined,
		newIssueHierarchyLevel: HierarchyLevel | null,
	) =>
		childIssues?.length && isBaseHierarchyLevel(newIssueHierarchyLevel) ? childIssues : subtasks,
);

// Only exported for testing purposes
export const hasSubtasksOrBaseLevelChildrenSelector = createSelector(
	persistedChildrenIssuesSelector,
	hasSubtasksSelector,
	hierarchyLevelSelector,
	childIssuesLimitUrlSelector,
	(
		childIssues,
		hasSubtasks,
		newIssueHierarchyLevel: HierarchyLevel | null,
		getChildIssuesLimitUrl: (arg1: string) => string,
	) =>
		hasSubtasks ||
		((Boolean(childIssues?.length) ||
			Boolean(getChildIssuesLimitUrl(EPIC_ISSUES) || getChildIssuesLimitUrl(CHILDREN_ISSUES))) &&
			isBaseHierarchyLevel(newIssueHierarchyLevel)),
);

export const addTimeTrackingValues = (
	originalValue: undefined | number,
	newValue: undefined | number,
) =>
	isNumber(originalValue) || isNumber(newValue)
		? (originalValue || 0) + (newValue || 0)
		: originalValue;

// @ts-expect-error - TS7006 - Parameter 'fields' implicitly has an 'any' type.
const getSubFieldByName = (fields, fieldName: string) =>
	Array.isArray(fields)
		? fields.find((field) => field.key === fieldName)?.value
		: fields && fields[fieldName];

// Only exported for testing purposes
export const doSubtasksOrBaseLevelChildrenHaveTimeTrackingConfiguredSelector = createSelector(
	getSubtasksOrChildrenBelowBaseLevelIssuesSelector,
	isClassicProjectSelector,
	childIssuesLimitUrlSelector,
	(childIssues, isClassicProject, getChildIssuesLimitUrl) => {
		// Time tracking data is always available on classic subtasks even if the Time tracking field isn't configured
		if (isClassicProject) {
			return true;
		}

		if (getChildIssuesLimitUrl(CHILDREN_ISSUES) || getChildIssuesLimitUrl(EPIC_ISSUES)) {
			return true;
		}

		if (!childIssues || childIssues.length === 0) {
			return false;
		}
		const childIssuesTimeTrackingValues = childIssues
			.map((subIssue) =>
				// if Time tracking fields aren't configured, undefined is returned
				getSubFieldByName(subIssue.fields, TIME_TRACKING),
			)
			.filter(Boolean);
		return childIssuesTimeTrackingValues.length > 0;
	},
);

export const shouldDisplayRollUpDataControlSelector = createSelector(
	hasSubtasksOrBaseLevelChildrenSelector,
	doSubtasksOrBaseLevelChildrenHaveTimeTrackingConfiguredSelector,
	(hasSubtasksOrBaseLevelChildren, doSubtasksOrBaseLevelChildrenHaveTimeTrackingConfigured) =>
		hasSubtasksOrBaseLevelChildren && doSubtasksOrBaseLevelChildrenHaveTimeTrackingConfigured,
);

export const timeTrackingWithSubtasksOrChildrenRollupSelector = createSelector(
	timeTrackingSelector,
	getSubtasksOrChildrenBelowBaseLevelIssuesSelector,
	(currentIssueTimeTrackingData, subtasksOrChildIssues) => (isRollingUpData: boolean) => {
		// base case: we return just the current issue's time tracking data whenever the issue
		// has no subtasks or the respective UI option is unchecked
		if (!subtasksOrChildIssues || subtasksOrChildIssues.length === 0 || !isRollingUpData) {
			return currentIssueTimeTrackingData || {};
		}
		return subtasksOrChildIssues
			.map((subIssue) => getSubFieldByName(subIssue.fields, TIME_TRACKING))
			.filter(Boolean)
			.reduce(
				(accumulator, current) => ({
					originalEstimateSeconds: addTimeTrackingValues(
						accumulator.originalEstimateSeconds,
						current.originalEstimateSeconds,
					),
					timeSpentSeconds: addTimeTrackingValues(
						accumulator.timeSpentSeconds,
						current.timeSpentSeconds,
					),
					remainingEstimateSeconds: addTimeTrackingValues(
						accumulator.remainingEstimateSeconds,
						current.remainingEstimateSeconds,
					),
				}),
				currentIssueTimeTrackingData || {},
			);
	},
);

export const openModalSelector = createSelector(
	modalSelector,
	(modalState) => modalState.openModal,
);

export const modalIsSavingSelector = createSelector(
	modalSelector,
	(modalState) => modalState.isSaving,
);

const activeWorklogIdSelector = createSelector(
	modalSelector,
	(modalState) => modalState.activeWorklogId,
);

const worklogEntitiesSelector = createSelector(entitiesSelector, (entities) => entities.worklog);

export const worklogsSelector = createSelector(
	worklogEntitiesSelector,
	(entitiesState) => entitiesState.worklogs,
);

export const activeWorklogSelector = createSelector(
	activeWorklogIdSelector,
	worklogsSelector,
	(activeWorklogId, worklogs) => (activeWorklogId && worklogs[activeWorklogId]) || null,
);

const sortedWorklogIdsSelector = createSelector(
	worklogEntitiesSelector,
	(entitiesState) => entitiesState.sortedWorklogIds,
);

export const sortedWorklogsSelector = createSelector(
	worklogsSelector,
	sortedWorklogIdsSelector,
	(worklogs, sortedWorklogIds) => {
		const worklogIds = Array.isArray(sortedWorklogIds) ? sortedWorklogIds : Object.keys(worklogs); // Since there's no sort, just return the worklogs

		return worklogIds.map((worklogId) => worklogs[worklogId]);
	},
);

export const totalWorklogsSelector = createSelector(
	worklogEntitiesSelector,
	(entities) => entities.totalWorklogs,
);

export const worklogsStartIndexSelector = createSelector(
	worklogEntitiesSelector,
	(entities) => entities.worklogsStartIndex || 0,
);
