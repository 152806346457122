import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { IssueAdjustmentsState } from '../../types.tsx';
import type { ErrorState } from './types.tsx';

export const setError =
	(errorProperties: ErrorState) =>
	({ setState }: StoreActionApi<IssueAdjustmentsState>) => {
		setState({
			error: {
				errorCode: errorProperties.errorCode,
				fieldName: errorProperties.fieldName,
			},
		});
	};

export const resetError =
	() =>
	({ setState }: StoreActionApi<IssueAdjustmentsState>) => {
		setState({
			error: null,
		});
	};
