import React, { memo, type MouseEvent } from 'react';
import { PaddedFlexContainer } from '../../common/ui/styled.tsx';
import { preventDefault, stopPropagation } from '../../common/utils.tsx';
import type { Props } from './types.tsx';

const onErrorDialogMouseDown = (e: MouseEvent<HTMLElement>) => {
	preventDefault(e);
	stopPropagation(e);
};

export const Error = memo<Props>(({ errorInlineMessage }: Props) => (
	<PaddedFlexContainer onClick={stopPropagation} onMouseDown={onErrorDialogMouseDown}>
		{errorInlineMessage}
	</PaddedFlexContainer>
));
