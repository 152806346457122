// TODO: go/restrict-enums
// eslint-disable-next-line no-restricted-syntax
export enum TRANSITION_STEP {
	INITIAL = 'initial',
	DELAYING = 'delaying',
	TRANSITIONING = 'transitioning',
	FINISHED = 'finished',
	DELAYING_FADE_OUT = 'delaying-fade-out',
	TRANSITIONING_FADE_OUT = 'transitioning-fade-out',
}

export const TRANSITION_INITIAL_DELAY_TIME_MS = 600;
export const TRANSITION_HOVER_DELAY_TIME_MS = 300;
export const TRANSITION_DURATION_MS = 350;
