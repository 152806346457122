/**
 * @generated SignedSource<<5b05ec12c5358ea0d1ece67484de8a92>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutPeople_IssueViewPeople$data = {
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutApproversField_IssueViewApproversField" | "ui_issueViewLayoutPeopleField_IssueViewPeopleField">;
  readonly " $fragmentType": "ui_issueViewLayoutPeople_IssueViewPeople";
};
export type ui_issueViewLayoutPeople_IssueViewPeople$key = {
  readonly " $data"?: ui_issueViewLayoutPeople_IssueViewPeople$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutPeople_IssueViewPeople">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsapproversrelayprovider"
    },
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldspeoplerelayprovider"
    }
  ],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutPeople_IssueViewPeople",
  "selections": [
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldspeoplerelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "ui_issueViewLayoutPeopleField_IssueViewPeopleField"
        }
      ]
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsapproversrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "ui_issueViewLayoutApproversField_IssueViewApproversField"
        }
      ]
    },
    {
      "kind": "ScalarField",
      "name": "type"
    }
  ],
  "type": "JiraPeopleField"
};

(node as any).hash = "a74ef12d7ed0df17386f999ece585488";

export default node;
