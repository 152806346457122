import type { ValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import type { FlagMessage } from '@atlassian/jira-flags';
import { SERVER_VALIDATION_ERROR_MESSAGE_ID } from './constants.tsx';

export const getErrorMessage = (
	error: ValidationError,
	description?: FlagMessage,
): FlagMessage | undefined => {
	const serverError = error.message || error.errors[0]?.error;
	if (serverError) {
		return {
			id: SERVER_VALIDATION_ERROR_MESSAGE_ID,
			defaultMessage: serverError,
		};
	}
	return description;
};
