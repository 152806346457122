import { useCallback, useMemo } from 'react';
import { fetchQuery, graphql } from 'relay-runtime';
import type { Transition } from '@atlassian/jira-issue-fetch-services/src/types.tsx';
import getRelayEnvironment from '@atlassian/jira-relay-environment/src/index.tsx';
import type { transitionsRelayQueryForStatusFieldQuery } from '@atlassian/jira-relay/src/__generated__/transitionsRelayQueryForStatusFieldQuery.graphql';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';

type Props = {
	issueKey: IssueKey;
};

export const useFetchTransitionsForStatusField = ({ issueKey }: Props) => {
	const cloudId = useCloudId();

	const fetchTransitions = useCallback(async (): Promise<Transition[]> => {
		const data = await fetchQuery<transitionsRelayQueryForStatusFieldQuery>(
			getRelayEnvironment(),
			graphql`
				query transitionsRelayQueryForStatusFieldQuery($cloudId: ID!, $issueKey: String!) {
					jira {
						issueByKey(key: $issueKey, cloudId: $cloudId) {
							statusField {
								transitions @optIn(to: "JiraStatusFieldOptions") {
									edges {
										node {
											transitionId
											name
											hasScreen
											isGlobal
											isInitial
											isLooped
											hasPreConditions
											to {
												statusId
												name
												statusCategory {
													statusCategoryId
												}
											}
										}
									}
								}
							}
						}
					}
				}
			`,
			{
				issueKey,
				cloudId,
			},
		).toPromise();
		return (
			data?.jira?.issueByKey?.statusField?.transitions?.edges?.map((edge) => ({
				id: edge?.node?.transitionId ?? 0,
				name: edge?.node?.name ?? '',
				hasScreen: edge?.node?.hasScreen ?? false,
				isGlobal: edge?.node?.isGlobal ?? false,
				isInitial: edge?.node?.isInitial ?? false,
				isConditional: edge?.node?.hasPreConditions ?? false,
				isLooped: edge?.node?.isLooped ?? false,
				to: {
					id: parseInt(edge?.node?.to?.statusId ?? '', 10),
					name: edge?.node?.to?.name ?? '',
					description: '',
					statusCategory: {
						id: parseInt(edge?.node?.to?.statusCategory?.statusCategoryId ?? '', 10),
					},
				},
				from: null,
			})) ?? []
		);
	}, [cloudId, issueKey]);
	return useMemo(() => ({ fetchTransitions }), [fetchTransitions]);
};
