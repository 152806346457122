import type { User } from '@atlassian/jira-project-settings-apps-people-field/src/index.tsx';
import { type AccountId, toAccountId } from '@atlassian/jira-shared-types/src/general.tsx';

type State = {
	name?: string;
	id: AccountId;
	accountId: AccountId;
	displayName: string;
	avatarUrls: {
		[key: string]: string | undefined;
	};
};

const avatarSize = '24x24';

// this function will be memoized by 'connectField'
export const transformToStateValue = (value: User[]): State[] =>
	value.map((item) => ({
		id: toAccountId(item.id),
		accountId: toAccountId(item.id),
		displayName: item.name,
		avatarUrls: { [avatarSize]: item.avatarUrl },
	}));

const toUser = (data: State): User | null => {
	if (!data || typeof data !== 'object' || !data.accountId || !data.displayName) {
		return null;
	}

	let avatarUrl = '';
	if (data.avatarUrls && typeof data.avatarUrls === 'object') {
		avatarUrl = String(data.avatarUrls[avatarSize]);
	}

	return {
		id: String(data.accountId),
		name: String(data.displayName),
		type: 'user',
		fixed: false,
		avatarUrl,
	};
};

// this function will be memoized by 'connectField'
export const transformFromStateValue = (value: State[]): User[] => {
	// Custom fields can have a null value
	if (!Array.isArray(value)) {
		return [];
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return value.reduce<Array<any>>((users: User[], item: State) => {
		const user = toUser(item);
		// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
		return user ? [...users, user] : users;
	}, []);
};
