import React from 'react';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type { Props, ViewJourneyButton } from './ViewJourneyButton.tsx';

const ViewJourneyButtonAsyncInner = lazyForPaint<typeof ViewJourneyButton>(() =>
	import(/* webpackChunkName: "async-view-journey-button" */ './ViewJourneyButton').then(
		(exportedModule) => exportedModule.ViewJourneyButton,
	),
);

export const AsyncViewJourneyButton = (props: Props) => (
	<Placeholder name="developer-escalations-button" fallback={null}>
		<ViewJourneyButtonAsyncInner {...props} />
	</Placeholder>
);
