import {
	OPTION_COLOR_THEMES,
	GREY_META,
	OPTION_COLOR_THEMES_MAP,
	OPTION_COLOR_THEMES_COLORFUL_SINGLE_SELECT,
} from '../constants.tsx';

export const getRandomColor = () => {
	const randomIndex = Math.floor(Math.random() * OPTION_COLOR_THEMES_COLORFUL_SINGLE_SELECT.length);
	return OPTION_COLOR_THEMES_COLORFUL_SINGLE_SELECT[randomIndex].name;
};

export const getColorNameFromValue = (colorValue: string) =>
	OPTION_COLOR_THEMES.find((item) => item.backgroundColor === colorValue)?.name ?? '';

export const getValueFromColorName = (colorName: string) =>
	OPTION_COLOR_THEMES_MAP[colorName]?.backgroundColor ?? '';

export const getColorMetadata = (colorName: string | null | undefined) => {
	if (!colorName) {
		return GREY_META;
	}
	return OPTION_COLOR_THEMES_MAP[colorName] ?? GREY_META;
};

export const getAllColorNames = () => OPTION_COLOR_THEMES.map((item) => item.name);
