export const getSaveAdfCommentUrl = ({
	baseUrl,
	issueKey,
	id = '',
	isNewComment = false,
}: {
	baseUrl: string;
	issueKey: string;
	id?: string;
	isNewComment?: boolean;
}) => {
	const commentIdPlaceholder = isNewComment ? '' : `/${id}`;
	return `${baseUrl}/rest/api/3/issue/${issueKey}/comment${commentIdPlaceholder}`;
};
