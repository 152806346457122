/** @jsx jsx */
import React, { type ReactNode, forwardRef } from 'react';
import ReactDOM from 'react-dom';
import { css, jsx } from '@compiled/react';
import type { Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/types';
import { DropIndicator } from '@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/box';
import { token } from '@atlaskit/tokens';
import { FIELD_WRAPPER_COMPONENT_SELECTOR } from '@atlassian/jira-issue-field-heading/src/styled.tsx';
import { SortableItemDragPreview } from './drag-preview/index.tsx';

const containerStyles = css({
	/**
	 * Position relative is required on the container
	 * because the drop indicator uses absolute positioning.
	 */
	position: 'relative',
	gap: token('space.025', '2px'),
	display: 'flex',
	alignItems: 'baseline',
	cursor: 'grab',
	paddingLeft: token('space.025', '2px'),
	paddingRight: token('space.150', '12px'),
	/**
	 * The content passed in through `children` has a margin we don't want.
	 *
	 * Ideally we could remove this nested selector,
	 * but keeping this existing behavior for now.
	 */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	[`&& ${FIELD_WRAPPER_COMPONENT_SELECTOR}`]: {
		marginBottom: 0,
	},
	/**
	 * Allowing the cursor from the input to flow through, otherwise it will
	 * have a grab cursor.
	 *
	 * Ideally we could remove this nested selector,
	 * but keeping this existing behavior for now.
	 */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	[`${FIELD_WRAPPER_COMPONENT_SELECTOR} > :last-child`]: {
		cursor: 'auto',
	},

	/**
	 * Ensures that the reorder menu is shown when hovering this item.
	 */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':hover': {
		'--reorder-menu-opacity': 1,
	},
});

const draggingStyles = css({ opacity: 0.4 });

export type SortableItemState =
	| { type: 'idle' }
	| { type: 'preview'; container: HTMLElement }
	| { type: 'dragging' }
	| { type: 'dragging-over'; edge: Edge; shouldRenderDropIndicator: boolean };

export type SortableItemPositionVariant = 'standard' | 'first-item' | 'last-item';

type SortableItemContainerProps = {
	children: ReactNode;
	dragState: SortableItemState;
	fieldName: string;
	positionVariant?: SortableItemPositionVariant;
	testId?: string;
};

export const SortableItemContainer = forwardRef<HTMLDivElement, SortableItemContainerProps>(
	({ children, dragState, fieldName, positionVariant = 'standard', testId }, ref) => (
		<div
			ref={ref}
			data-testid={testId}
			css={[containerStyles, dragState.type === 'dragging' && draggingStyles]}
		>
			{children}
			{dragState.type === 'dragging-over' && dragState.shouldRenderDropIndicator && (
				<div
					css={[
						dropIndicatorContainerStyles,
						positionVariant === 'first-item' && firstItemStyles,
						positionVariant === 'last-item' && lastItemStyles,
					]}
				>
					<DropIndicator edge={dragState.edge} gap={token('space.100', '8px')} />
				</div>
			)}
			{dragState.type === 'preview' &&
				ReactDOM.createPortal(
					<SortableItemDragPreview fieldName={fieldName} />,
					dragState.container,
				)}
		</div>
	),
);

const dropIndicatorContainerStyles = css({
	position: 'absolute',
	top: 0,
	bottom: 0,
	/**
	 * Insetting the terminal because otherwise it gets clipped
	 * by the scroll container on the left side panel.
	 */
	left: token('space.050', '8px'),
	right: 0,
});

const firstItemStyles = css({
	/**
	 * The first item's drop indicator needs to be a bit higher
	 * so that it touches the top of the container.
	 */
	top: token('space.negative.050', '-4px'),
	/**
	 * The pinned fields title container has a zIndex applied that
	 * we need to be higher than.
	 */
	zIndex: 99,
});

const lastItemStyles = css({
	/**
	 * The last item's drop indicator needs to be a bit lower
	 * so that it touches the bottom of the container.
	 */
	bottom: token('space.negative.050', '-4px'),
});
