import type { Environment as RelayEnvironment } from 'react-relay';
import {
	DefaultMentionNameResolver,
	type MentionNameDetails,
	type MentionNameResolver,
	MentionNameStatus,
	type ResolvingMentionProvider,
} from '@atlaskit/mention';
import type { ProjectType } from '@atlassian/jira-common-constants/src/index.tsx';
import MentionNameClient from '@atlassian/jira-mention-provider/src/common/utils.tsx';
import type { AccountId, BaseUrl, IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import IssueUserTeamMentionProvider from './issue-user-team-mention-provider.tsx';

interface MentionConfig {
	mentionNameResolver: MentionNameResolver;
}

export default class IssueUserTeamMentionProviderWithNameResolver
	extends IssueUserTeamMentionProvider
	implements ResolvingMentionProvider
{
	config: MentionConfig;

	constructor(
		baseUrl: BaseUrl,
		issueKey: IssueKey,
		loggedInAccountId: AccountId | null,
		createTeamPath: string,
		projectType?: ProjectType | null,
		orgId?: string | null,
		cloudId?: string,
		environment?: RelayEnvironment | null,
	) {
		super(
			baseUrl,
			issueKey,
			loggedInAccountId,
			createTeamPath,
			projectType,
			orgId,
			cloudId,
			environment,
		);
		this.config = {
			mentionNameResolver: new DefaultMentionNameResolver(new MentionNameClient()),
		};
	}

	resolveMentionName(id: string): Promise<MentionNameDetails> | MentionNameDetails {
		if (!this.config.mentionNameResolver) {
			return {
				id,
				name: '',
				status: MentionNameStatus.UNKNOWN,
			};
		}
		return this.config.mentionNameResolver.lookupName(id);
	}

	supportsMentionNameResolving(): boolean {
		return !!this.config.mentionNameResolver;
	}

	cacheMentionName(id: string, mentionName: string): void {
		if (!this.config.mentionNameResolver) {
			return;
		}
		this.config.mentionNameResolver.cacheName(id, mentionName);
	}
}
