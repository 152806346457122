import React, { Component, type MouseEvent, type ReactNode, type SyntheticEvent } from 'react';
import { styled } from '@compiled/react';
import uuid from 'uuid';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Avatar from '@atlaskit/avatar';
import AkComment, { CommentAction, CommentAuthor, CommentEdited } from '@atlaskit/comment';
import Lozenge from '@atlaskit/lozenge';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import AkToolTip from '@atlaskit/tooltip';
import type { ProjectType } from '@atlassian/jira-common-constants/src/index.tsx';
import {
	CORE_PROJECT,
	SOFTWARE_PROJECT,
	SERVICE_DESK_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import transformToISOString from '@atlassian/jira-common-date/src/utils/transform-to-iso-string/index.tsx';
import type { CreateAnalyticsEventType } from '@atlassian/jira-common-navigation/src/keyboard-shortcuts/analytics.tsx';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { type IntlShape, FormattedMessage } from '@atlassian/jira-intl';
import { useIsIssueOfIncidentsPractice } from '@atlassian/jira-issue-field-servicedesk-practices/src/services/use-practices-field-value/index.tsx';
import {
	COMMENT_VISIBILITY_TYPE_PUBLIC,
	type CommentVisibility,
} from '@atlassian/jira-issue-gira-transformer-types/src/common/types/comments.tsx';
import type { User } from '@atlassian/jira-issue-shared-types/src/common/types/user-type.tsx';
import Timestamp from '@atlassian/jira-issue-timestamp/src/main.tsx';
import { COMMENT_VISIBILITY_PUBLIC } from '@atlassian/jira-issue-view-common-types/src/comment-type.tsx';
import { smoothScrollIntoViewIfNeeded } from '@atlassian/jira-issue-view-common-utils/src/scroll/index.tsx';
import { PermalinkButton } from '@atlassian/jira-issue-view-common/src/component/permalink-button/button/async.tsx';
import PermalinkProvider, {
	type Permalink,
} from '@atlassian/jira-issue-view-common/src/component/permalink-button/permalink-provider/index.tsx';
import draftMessages from '@atlassian/jira-issue-view-common/src/messages/drafts.tsx';
import WithProfileCard from '@atlassian/jira-issue-view-internal-profilecard-next/src/index.tsx';
import { NEW_COMMENT_ID } from '@atlassian/jira-issue-view-store/src/selectors/comment-constants.tsx';
import { isJsmLoomExperimentEnabled } from '@atlassian/jira-loom-jsm-agent-response/src/common/utils.tsx';
import { ModalContextProvider } from '@atlassian/jira-modal-context-provider/src/ModalContextProvider.tsx';
import { ModalEntryPointPressableTrigger } from '@atlassian/jira-modal-entry-point-pressable-trigger/src/ModalEntryPointPressableTrigger.tsx';
import { isShallowEqual } from '@atlassian/jira-platform-shallow-equal/src/index.tsx';
import {
	fireOperationalAnalytics,
	fireUIAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { isOnlyWhitespaceAdf } from '@atlassian/jira-rich-content/src/common/adf-parsing-utils.tsx';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import { TenantContextSubscriber } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import CommentAfterContent from './akcomment-resources/comment-after-content.tsx';
import CommentDeleted from './akcomment-resources/comment-deleted.tsx';
import { IssueCommentReactionsBoundary } from './comment-reactions/async.tsx';
import type IssueCommentReactionsType from './comment-reactions/index.tsx';
import IssueCommentReactionsSync from './comment-reactions/index.tsx';
import CommentVisibilitySelector from './comment-visibility/index.tsx';
import { areCommentVisibilitiesEqual } from './comment-visibility/view/utils.tsx';
import { deleteIssueLinkConfirmationEntryPoint } from './confirmation-modal/entrypoint.tsx';
import { IssueCommentEditorReadonly } from './content/async.tsx';
import CommentContent from './content/index.tsx';
import { EventOccurredAtSelector } from './event-occurred-at-selector/index.tsx';
import { isJsmTimelineEnabled } from './feature-flags.tsx';
import messages from './messages.tsx';
import { ActivityTimestampStylesControl, InternalCommentContainer } from './styled.tsx';

type CommentId = string;
type EditSaveData = {
	authorId: AccountId | null;
	createdDate: string;
	bodyAdf: ADF;
	bodyHtml: string;
	isNewComment: boolean;
	visibility: CommentVisibility;
	eventOccurredAt?: number | null;
	jsdIncidentActivityViewHidden?: boolean | null;
	fullIssueUrl?: string;
	triggerIncidentSaveCommentFlag?: boolean;
	parentId?: CommentId;
};

export type Props = {
	isOptimistic: boolean;
	isEditing: boolean;
	isInternal: boolean;
	isIssueOfIncidentsPractice: boolean;
	isHighlighted: boolean;
	hasDraft: boolean;
	hasSaveFailed: boolean;
	hasScrolledPermalink: boolean;
	shouldScrollAfterAsyncComponentsLoaded: boolean;
	shouldShowCommentVisibility: boolean;
	canEdit: boolean;
	canDelete: boolean;
	canAdd?: boolean;
	bodyAdf: ADF;
	/*
	 *  TODO : https://hello.atlassian.net/browse/GCS-1009 Make jsdAuthorCanSeeRequest mandatory when FF is cleaned
	 */
	jsdAuthorCanSeeRequest?: boolean; // JSM specific field for identifying whether user is request participant or not,
	bodyHtml: string;
	author?: User;
	updateAuthor?: User;
	createdDate: string;
	createAnalyticsEvent: CreateAnalyticsEventType;
	commentId: string;
	updatedDate?: string | null;
	edited: boolean;
	showReactions: boolean;
	visibility: CommentVisibility;
	visibilityEdited: CommentVisibility;
	fullIssueUrl: string;
	eventOccurredAt?: number | null;
	jsdIncidentActivityViewHidden?: boolean | null;
	projectType?: ProjectType | null;
	isEditingInternal: boolean;
	setScrollStatus: (status: boolean) => void;
	getOnEditSave: (
		arg1: EditSaveData,
		arg2: UIAnalyticsEvent,
		commentSessionId?: string | null,
	) => void;

	getOnEditClick: (arg1: boolean, commentSessionId?: string | null) => void;
	showAddedCommentDescription?: boolean;
	showLozenge?: boolean;
	showSmallAvatar?: boolean;
	onSaveRetry: () => void;
	onSaveEdit: () => void;
	onSaveCancel: (parentId?: CommentId) => void;
	onEditCancel: () => void;
	onEditUpdate: (arg1: ADF) => void;
	onEditPaste: (arg1: string) => void;
	onBlur: () => void;
	onCopyClick: (e: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => void;
	onCommentVisibilityChange: (arg1: CommentVisibility) => void;
	intl: IntlShape;
	children?: ReactNode;
	isReplyingToComment?: boolean;
	onReplyClick?: (
		replyingToCommentId: string,
		authorId: string | null,
		authorDisplayName: string | null,
		visibility?: CommentVisibility,
		commentSessionId?: string | null,
	) => void;
	isDeleted?: boolean;
	hasReplies?: boolean;
	parentId?: CommentId;
	commentSessionId?: string | null;
};

// TODO reevaluate phase - forPaint is simply for initial parity
export const IssueCommentReactionsAsync = lazyForPaint<typeof IssueCommentReactionsType>(
	() => import(/* webpackChunkName: "async-comment-reactions" */ './comment-reactions'),
);

const IssueCommentReactions = componentWithFG(
	'convert_issuecommentreactions_to_be_synchronous',
	IssueCommentReactionsSync,
	IssueCommentReactionsAsync,
);

// TODO reevaluate phase - forPaint is simply for initial parity
export const IssueCommentEditor = lazyForPaint<typeof CommentContent>(
	() => import(/* webpackChunkName: "async-editor-view" */ './content'),
);

// eslint-disable-next-line jira/react/no-class-components
export class CommentBase extends Component<
	Props,
	{
		selectedEventOccurredAt: number | null | undefined;
	}
> {
	static displayName = 'Comment';

	static defaultProps = {
		isEditing: false,
		isIssueOfIncidentsPractice: false,
		visibility: COMMENT_VISIBILITY_PUBLIC,
		updatedDate: null,
		edited: false,
		eventOccurredAt: null,
		jsdIncidentActivityViewHidden: null,
	};

	constructor(props: Props) {
		super(props);
		this.state = {
			selectedEventOccurredAt: props.eventOccurredAt,
		};
	}

	componentDidMount() {
		const {
			props: { isOptimistic, commentId },
			optimisticCommentContainerRef,
		} = this;
		if (isOptimistic && commentId.includes(NEW_COMMENT_ID) && optimisticCommentContainerRef) {
			smoothScrollIntoViewIfNeeded(optimisticCommentContainerRef, {
				scrollMode: 'always',
				behavior: 'smooth',
			});
		}

		this.scrollCommentIntoView();
	}

	shouldComponentUpdate(nextProps: Props) {
		if (
			(!this.props.shouldScrollAfterAsyncComponentsLoaded &&
				nextProps.shouldScrollAfterAsyncComponentsLoaded) ||
			(!this.props.hasScrolledPermalink && nextProps.hasScrolledPermalink)
		) {
			const propsWithPermalinkUpdates = {
				...this.props,
				// make the props equal to preform shallow equal, a different
				// approach to using omit
				shouldScrollAfterAsyncComponentsLoaded: nextProps.shouldScrollAfterAsyncComponentsLoaded,
				hasScrolledPermalink: nextProps.hasScrolledPermalink,
			};

			// check to see if any other props has changed
			const propsShallowEqual = isShallowEqual(propsWithPermalinkUpdates, nextProps);
			if (propsShallowEqual) {
				// We only need to need to scroll to comments that are highlighted
				// and have not already scrolled. Which is true when the user
				// views the issue from a permalink. Most cases it will be false
				// so lets stop the re-rendering of comments that aren't highlighted.
				return nextProps.isHighlighted && !nextProps.hasScrolledPermalink;
			}
		}

		return true;
	}

	componentDidUpdate() {
		this.scrollCommentIntoView();
	}

	changeEventOccurredAt = (val: number | null) => {
		this.setState({
			selectedEventOccurredAt: val,
		});
	};

	onEditClick = (
		_: MouseEvent<HTMLElement>,
		analyticsEvent?: UIAnalyticsEvent,
		isSiteAdmin?: boolean,
	) => {
		const { getOnEditClick, isInternal, hasDraft, createAnalyticsEvent } = this.props;

		const commentSessionId = fg('comments_missing_attributes') ? uuid.v4() : undefined;
		getOnEditClick(isInternal, commentSessionId);

		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		fireOperationalAnalytics(analyticsEvent as UIAnalyticsEvent, 'editCommentEditor expanded', {
			hasDraft,
		});
		fireUIAnalytics(createAnalyticsEvent({ actionSubject: 'commentEdit', action: 'started' }), {
			hasDraft,
			commentSessionId,
			...(this.jsmLoomExperiment?.enabled && {
				isSiteAdmin,
				loom_jsm_agent_response_touchpoint: this.jsmLoomExperiment?.cohort,
			}),
		});
	};

	onEditSave = (value: ADF): void => {
		const isValueEmpty = isOnlyWhitespaceAdf(value);
		if (!isValueEmpty) {
			const {
				author,
				commentId,
				createdDate,
				bodyHtml,
				shouldShowCommentVisibility,
				visibility,
				visibilityEdited,
				getOnEditSave,
				createAnalyticsEvent,
				jsdIncidentActivityViewHidden,
				fullIssueUrl,
				isIssueOfIncidentsPractice,
				isEditingInternal,
				projectType,
				parentId,
				commentSessionId,
			} = this.props;
			const authorId = author ? author.id : null;
			const analyticsEvent = createAnalyticsEvent({ action: 'updated' });

			this.state.selectedEventOccurredAt
				? analyticsEvent.update({ commentEditWithEventOccurredAt: true })
				: analyticsEvent.update({ commentEditWithEventOccurredAt: false });

			if (shouldShowCommentVisibility) {
				analyticsEvent.update({
					isVisibilityUpdated: !areCommentVisibilitiesEqual(visibility, visibilityEdited),
				});
			}

			getOnEditSave(
				{
					authorId,
					createdDate,
					bodyAdf: value,
					bodyHtml,
					isNewComment: commentId.includes(NEW_COMMENT_ID),
					visibility:
						projectType === SERVICE_DESK_PROJECT && !isEditingInternal
							? COMMENT_VISIBILITY_PUBLIC
							: visibilityEdited,

					...(isJsmTimelineEnabled() && isIssueOfIncidentsPractice && isEditingInternal
						? {
								eventOccurredAt: this.state.selectedEventOccurredAt,
								jsdIncidentActivityViewHidden,
								fullIssueUrl,
								triggerIncidentSaveCommentFlag: isIssueOfIncidentsPractice,
							}
						: {}),
					...(this.isThreadedCommentsEnabled() ? { parentId } : {}),
				},
				analyticsEvent,
				commentSessionId,
			);
		}
	};

	onHideSave = (): void => {
		const {
			author,
			commentId,
			createdDate,
			bodyHtml,
			bodyAdf,
			jsdIncidentActivityViewHidden,
			visibilityEdited,
			projectType,
			isEditingInternal,
			getOnEditSave,
			createAnalyticsEvent,
			eventOccurredAt,
			commentSessionId,
		} = this.props;

		const authorId = author ? author.id : null;
		const analyticsEvent = createAnalyticsEvent({ action: 'updated' });

		fireUIAnalytics(
			createAnalyticsEvent({}),
			jsdIncidentActivityViewHidden ? 'unhide clicked' : 'hide clicked',
		);

		getOnEditSave(
			{
				authorId,
				createdDate,
				bodyAdf,
				bodyHtml,
				isNewComment: commentId.includes(NEW_COMMENT_ID),
				visibility:
					projectType === SERVICE_DESK_PROJECT && !isEditingInternal
						? COMMENT_VISIBILITY_PUBLIC
						: visibilityEdited,
				eventOccurredAt,
				jsdIncidentActivityViewHidden: !jsdIncidentActivityViewHidden,
			},
			analyticsEvent,
			commentSessionId,
		);
	};

	getAuthorDisplayName = (author?: User) =>
		author ? author.displayName : this.props.intl.formatMessage(messages.anonymous);

	onReplyClick = () => {
		const {
			onReplyClick,
			parentId,
			commentId,
			author,
			shouldShowCommentVisibility,
			visibility,
			createAnalyticsEvent,
		} = this.props;
		if (!onReplyClick) {
			return;
		}
		const replyingToCommentId = parentId || commentId;
		const authorId = author ? author.id : null;
		const authorDisplayName = this.getAuthorDisplayName(author);
		const replyVisibility = shouldShowCommentVisibility ? visibility : undefined;
		const commentSessionId = fg('comments_missing_attributes') ? uuid.v4() : undefined;

		onReplyClick(
			replyingToCommentId,
			authorId,
			authorDisplayName,
			replyVisibility,
			commentSessionId,
		);
		fireUIAnalytics(
			createAnalyticsEvent({ action: 'clicked', actionSubject: 'button' }),
			'replyButton',
			{ isReplyToChildComment: !!parentId, commentSessionId },
		);
	};

	getCommentReactions = () => (
		<ReactionsContainer data-testid="issue-comment-base.ui.comment.reactions-container">
			<IssueCommentReactionsBoundary packageName="issue" fallback={<></>}>
				<UFOSegment name="IssueCommentReactions">
					<IssueCommentReactions />
				</UFOSegment>
			</IssueCommentReactionsBoundary>
		</ReactionsContainer>
	);

	isThreadedCommentsEnabled = (): boolean =>
		// eslint-disable-next-line jira/ff/no-preconditioning
		fg('jira_threaded_comment_fg') &&
		(this.props.projectType === SOFTWARE_PROJECT || this.props.projectType === CORE_PROJECT) &&
		expVal('jira_threaded_comment_experiment', 'is_enabled', false);

	deleteConfirmModalProps = {
		width: 'small',
		testId: 'issue-comment-base.ui.comment.confirmation-modal.modal-dialog',
	};

	jsmLoomExperiment = { ...isJsmLoomExperimentEnabled() };

	getCommentActions: () => ReactNode[] = () =>
		[
			this.props.canAdd && this.isThreadedCommentsEnabled() && (
				<CommentAction key="reply" onClick={this.onReplyClick}>
					{this.props.intl.formatMessage(messages.reply)}
				</CommentAction>
			),
			this.props.canEdit &&
				(this.jsmLoomExperiment.enabled ? (
					<TenantContextSubscriber>
						{({ tenantContext: { isSiteAdmin } }) => {
							return (
								<CommentAction
									key="edit"
									onClick={(...args) => this.onEditClick(...args, isSiteAdmin)}
								>
									{this.props.intl.formatMessage(messages.edit)}
									{this.props.hasDraft
										? ` (${this.props.intl.formatMessage(draftMessages.draftIndicator)})`
										: ''}
								</CommentAction>
							);
						}}
					</TenantContextSubscriber>
				) : (
					<CommentAction key="edit" onClick={this.onEditClick}>
						{this.props.intl.formatMessage(messages.edit)}
						{this.props.hasDraft
							? ` (${this.props.intl.formatMessage(draftMessages.draftIndicator)})`
							: ''}
					</CommentAction>
				)),
			this.props.canDelete && (
				<ModalEntryPointPressableTrigger
					entryPoint={deleteIssueLinkConfirmationEntryPoint}
					entryPointProps={{
						commentId: this.props.commentId,
						eventOccurredAt: this.props.eventOccurredAt,
						jsdIncidentActivityViewHidden: this.props.jsdIncidentActivityViewHidden,
						hasReplies: this.props.hasReplies,
						parentId: this.props.parentId,
					}}
					modalProps={this.deleteConfirmModalProps}
					useInternalModal
				>
					{({ ref }) => (
						<CommentAction key="delete" ref={ref}>
							{this.props.intl.formatMessage(messages.delete)}
						</CommentAction>
					)}
				</ModalEntryPointPressableTrigger>
			),
			isJsmTimelineEnabled() &&
				this.props.canEdit &&
				this.props.isInternal &&
				this.props.isIssueOfIncidentsPractice && (
					<CommentAction key="hide" onClick={this.onHideSave}>
						{this.props.intl.formatMessage(
							this.props.jsdIncidentActivityViewHidden ? messages.unhide : messages.hide,
						)}
					</CommentAction>
				),
			this.props.showReactions && this.getCommentReactions(),
		].filter((action) => !!action);

	onSaveCancelClick = () => {
		const { onSaveCancel, parentId } = this.props;
		onSaveCancel(parentId);
	};

	getCommentSaveFailedActions: () => ReactNode[] = () =>
		[
			<CommentAction key="retry" onClick={this.props.onSaveRetry}>
				{this.props.intl.formatMessage(messages.retrySave)}
			</CommentAction>,
			<CommentAction key="edit" onClick={this.props.onSaveEdit}>
				{this.props.intl.formatMessage(messages.edit)}
			</CommentAction>,
			<CommentAction key="cancel" onClick={this.onSaveCancelClick}>
				{this.props.intl.formatMessage(messages.cancelSave)}
			</CommentAction>,
		].filter((action) => !!action);

	renderAdfRendererFallback = () => <IssueCommentEditorReadonly bodyHtml={this.props.bodyHtml} />;

	getCommentContent = () => {
		const {
			bodyAdf,
			commentId,
			isEditing,
			onEditCancel,
			onEditUpdate,
			onEditPaste,
			onBlur,
			visibilityEdited,
			onCommentVisibilityChange,
			shouldShowCommentVisibility,
			eventOccurredAt,
			isEditingInternal,
			projectType,
			parentId,
		} = this.props;

		const shouldShowCommentVisibilitySelector = this.isThreadedCommentsEnabled()
			? shouldShowCommentVisibility && !parentId // should not show for replies
			: shouldShowCommentVisibility;

		const jsmTimelineComponents =
			isJsmTimelineEnabled() && isEditingInternal
				? [
						<EventOccurredAtSelector
							key="eventOccurredAtSelector"
							initialValue={eventOccurredAt}
							onChange={(newEventOccurredAt: number | null) => {
								this.changeEventOccurredAt(newEventOccurredAt);
							}}
						/>,
					]
				: [];

		let secondaryToolbarComponents = shouldShowCommentVisibilitySelector
			? [
					...jsmTimelineComponents,
					<CommentVisibilitySelector
						key="commentVisibilitySelector"
						initialValue={visibilityEdited}
						onChange={onCommentVisibilityChange}
					/>,
				]
			: jsmTimelineComponents;

		secondaryToolbarComponents =
			shouldShowCommentVisibilitySelector &&
			(projectType === SERVICE_DESK_PROJECT ? this.props.isEditingInternal : true)
				? [
						...jsmTimelineComponents,
						<CommentVisibilitySelector
							key="commentVisibilitySelector"
							initialValue={visibilityEdited}
							onChange={onCommentVisibilityChange}
						/>,
					]
				: jsmTimelineComponents;

		const commentContentProps = {
			commentId,
			isEditing,
			secondaryToolbarComponents,
			canEdit: this.props.canEdit,
			onCancel: onEditCancel,
			onChange: onEditUpdate,
			onSave: this.onEditSave,
			onPaste: onEditPaste,
			onBlur,
			onHideSave: this.onHideSave,
		};

		if (__SERVER__) {
			return <CommentContent {...commentContentProps} bodyAdf={bodyAdf} />;
		}

		return (
			<ErrorBoundary
				id="issue-comment-editor"
				packageName="issue"
				render={this.renderAdfRendererFallback}
			>
				<CommentContent {...commentContentProps} bodyAdf={bodyAdf} />
			</ErrorBoundary>
		);
	};

	getRestrictedTo = (restrictedTo: JSX.Element, restrictedToText: string) => {
		const { isOptimistic, isDeleted } = this.props;

		if (isDeleted && this.isThreadedCommentsEnabled()) {
			return undefined;
		}

		return !isOptimistic ? restrictedTo : restrictedToText;
	};

	getCommentTime = () => {
		const {
			intl: { formatMessage },
			createdDate,
			showAddedCommentDescription,
			showLozenge,
			eventOccurredAt,
			isDeleted,
		} = this.props;

		if (isDeleted && this.isThreadedCommentsEnabled()) {
			return undefined;
		}

		const value =
			isJsmTimelineEnabled() && eventOccurredAt
				? transformToISOString(eventOccurredAt)
				: createdDate;

		return (
			<>
				{showAddedCommentDescription !== undefined && showAddedCommentDescription && (
					<DescriptionAction
						data-testid="issue-comment-base.ui.comment.action"
						dangerouslySetInnerHTML={{
							__html: formatMessage(
								expVal('issue_view_activity_timeline', 'isActivityTimelineEnabled', false)
									? messages.addedCommentDescriptionLowercase
									: messages.addedCommentDescription,
								undefined,
								{
									ignoreTag: true,
								},
							),
						}}
					/>
				)}
				<Timestamp
					value={value}
					tooltipPosition="top"
					extraStyles={ActivityTimestampStylesControl}
				/>
				{showLozenge !== undefined && showLozenge && (
					<LozengeWrapper>
						<Lozenge>{formatMessage(messages.lozenge)}</Lozenge>
					</LozengeWrapper>
				)}
			</>
		);
	};

	getCommentEditedToolTip = () => {
		const { intl } = this.props;

		const editTooltip = (
			<FormattedMessage
				{...messages.editedBy}
				values={{
					author: this.getAuthorDisplayName(this.props.updateAuthor),
					timestamp: <Timestamp value={this.props.updatedDate || ''} />,
				}}
			/>
		);

		return (
			<AkToolTip
				content={editTooltip}
				position="top"
				testId="issue-comment-base.ui.comment.ak-tool-tip"
			>
				<CommentEdited>{intl.formatMessage(messages.edited)}</CommentEdited>
			</AkToolTip>
		);
	};

	getFormattedGridContainer = (itemOne: ReactNode, itemTwo: ReactNode) =>
		isVisualRefreshEnabled() && fg('jira_nav4_eap_drop_2') ? (
			<GridContentContainerVisualRefresh data-testid="issue-comment-base.ui.comment.grid-content-container">
				<GridContainer>{itemOne}</GridContainer>
				{itemTwo != null ? <GridContainer>{itemTwo}</GridContainer> : null}
			</GridContentContainerVisualRefresh>
		) : (
			<GridContentContainer data-testid="issue-comment-base.ui.comment.grid-content-container">
				<GridContainer>{itemOne}</GridContainer>
				{itemTwo != null ? <GridContainer>{itemTwo}</GridContainer> : null}
			</GridContentContainer>
		);

	getPermalinkIcon = (permalink: Permalink) => {
		const {
			commentId,
			fullIssueUrl,
			onCopyClick,
			intl: { formatMessage },
			parentId,
		} = this.props;

		// no copy link for child comments
		if (parentId && this.isThreadedCommentsEnabled()) {
			return null;
		}

		return (
			<PermalinkButton
				id={commentId}
				fullIssueUrl={`${fullIssueUrl}?focusedCommentId=${commentId}`}
				label={formatMessage(messages.copy)}
				onCopyClick={onCopyClick}
				isVisible={permalink.isVisible}
			/>
		);
	};

	renderEditedOld = () => (this.props.edited ? this.getCommentEditedToolTip() : null);

	getEditingAndRestrictedTo = (restrictedToText: string, permalink: Permalink) => {
		const { isEditing, hasSaveFailed, isOptimistic, isDeleted, intl } = this.props;
		const isDeletedComment = isDeleted && this.isThreadedCommentsEnabled();

		// if comment is deleted, show only deleted component in edited field
		if (isDeletedComment) {
			return {
				restrictedTo: null,
				edited: <CommentDeleted intl={intl} />,
			};
		}

		const copyIcon = this.getPermalinkIcon(permalink);
		const hasRestrictedToText = restrictedToText.length > 0;

		// comment being saved and showing save label text
		// return null as otherwise would render the copy icon
		if (isOptimistic && !hasSaveFailed) return { restrictedTo: null, edited: null };

		const restrictedTo = hasRestrictedToText
			? this.getFormattedGridContainer(restrictedToText, copyIcon)
			: null;

		const editedCopyIcon = hasRestrictedToText ? null : copyIcon;

		const editedComment = this.getCommentEditedToolTip();

		// don't show copy icon while editing
		if (isEditing) {
			return {
				restrictedTo: null,
				edited: this.props.edited ? editedComment : null,
			};
		}
		// if there are 3 things to show (edited, restrictedTo, and copy icon) then the icon will need to be shown in the restrictedTo prop
		if (this.props.edited) {
			return {
				restrictedTo,
				edited: this.getFormattedGridContainer(editedComment, editedCopyIcon),
			};
		}
		// show copy icon if comment is not an internal comment.
		return {
			restrictedTo,
			edited: editedCopyIcon,
		};
	};

	optimisticCommentContainerRef: HTMLElement | null | undefined;

	isRequestParticipant = () => this.props.jsdAuthorCanSeeRequest === false;

	getAuthorAdditionalInfo = () => {
		if (expVal('issue_view_activity_timeline', 'isActivityTimelineEnabled', false)) {
			return this.isRequestParticipant()
				? ` ${this.props.intl.formatMessage(messages.notRequestParticipant)}`
				: '';
		}

		return this.isRequestParticipant()
			? this.props.intl.formatMessage(messages.notRequestParticipant)
			: '';
	};

	renderComment = (restrictedToText: string) => {
		const {
			author,
			isOptimistic,
			hasSaveFailed,
			isEditing,
			isHighlighted,
			isInternal,
			commentId,
			showSmallAvatar,
			children,
			isReplyingToComment,
			hasReplies,
			isDeleted,
		} = this.props;

		const isDeletedComment = isDeleted && this.isThreadedCommentsEnabled();

		return (
			<PermalinkProvider>
				{(permalink) => {
					// @ts-expect-error - TS2525 - Initializer provides no value for this binding element and the binding element has no default value. | TS7031 - Binding element 'restrictedTo' implicitly has an 'any' type. | TS2525 - Initializer provides no value for this binding element and the binding element has no default value. | TS7031 - Binding element 'edited' implicitly has an 'any' type.
					const { restrictedTo, edited } = !isOptimistic
						? this.getEditingAndRestrictedTo(restrictedToText, permalink)
						: {};

					const comment = (
						<AkComment
							avatar={
								<WithProfileCard
									userAccount={isDeletedComment ? undefined : author}
									analyticsData={{ trigger: 'comment-avatar' }}
									testId={`issue-comment-base.ui.comment.${commentId}.avatar`}
								>
									<Avatar
										borderColor="transparent"
										src={(!isDeletedComment && author && author.avatarUrl) || undefined}
										size={showSmallAvatar ? 'small' : 'medium'}
									/>
								</WithProfileCard>
							}
							author={
								!isDeletedComment && (
									<WithProfileCard
										userAccount={author}
										analyticsData={{ trigger: 'comment-author' }}
									>
										<CommentAuthor>
											{this.getAuthorDisplayName(author)}
											{!expVal(
												'issue_view_activity_timeline',
												'isActivityTimelineEnabled',
												false,
											) && <>&nbsp;</>}
											{this.getAuthorAdditionalInfo()}
										</CommentAuthor>
									</WithProfileCard>
								)
							}
							time={this.getCommentTime()}
							edited={!isOptimistic ? edited : this.renderEditedOld()}
							content={!isDeletedComment && <div>{this.getCommentContent()}</div>}
							afterContent={
								// this is a workaround for an issue in @atlaskit/comment where it adds an extra padding even if "children" return null
								this.isThreadedCommentsEnabled() &&
								!hasReplies &&
								!isReplyingToComment &&
								children && (
									<CommentAfterContent isHighlighted={!isInternal && !isEditing && isHighlighted} />
								)
							}
							restrictedTo={this.getRestrictedTo(restrictedTo, restrictedToText)}
							actions={
								!isDeletedComment && !isOptimistic && !isEditing ? this.getCommentActions() : []
							}
							isError={hasSaveFailed && !isEditing}
							errorActions={this.getCommentSaveFailedActions()}
							isSaving={isOptimistic && !hasSaveFailed}
							savingText={this.props.intl.formatMessage(messages.saving)}
							highlighted={!isInternal && !isEditing && isHighlighted}
							testId={`issue-comment-base.ui.comment.ak-comment.${commentId}`}
						>
							{this.isThreadedCommentsEnabled() && children}
						</AkComment>
					);

					return !isOptimistic ? (
						<IssueCommentWrapper
							onMouseEnter={() => (this.isThreadedCommentsEnabled() ? undefined : permalink.show())}
							onMouseLeave={() => (this.isThreadedCommentsEnabled() ? undefined : permalink.hide())}
							onMouseOver={(e) =>
								this.isThreadedCommentsEnabled() ? showPermalink(e, permalink) : undefined
							}
							onMouseOut={(e) =>
								this.isThreadedCommentsEnabled() ? hidePermalink(e, permalink) : undefined
							}
							onFocus={(e) =>
								this.isThreadedCommentsEnabled() ? showPermalink(e, permalink) : permalink.show()
							}
							onBlur={(e) =>
								this.isThreadedCommentsEnabled() ? hidePermalink(e, permalink) : permalink.hide()
							}
						>
							<span
								data-testid={`comment-base-item-${commentId}`}
								ref={this.setCommentPermalinkContainer}
							>
								<ModalContextProvider>{comment}</ModalContextProvider>
							</span>
						</IssueCommentWrapper>
					) : (
						<div ref={this.setOptimisticCommentPermalinkContainer}>{comment}</div>
					);
				}}
			</PermalinkProvider>
		);
	};

	renderInternalComment = () => {
		const { visibility, intl, isEditing, isHighlighted } = this.props;
		const highlightColor = isHighlighted
			? token('color.background.accent.yellow.subtler', colors.Y75)
			: token('color.background.accent.yellow.subtlest', colors.Y50);
		const restrictedToText =
			visibility.type === COMMENT_VISIBILITY_TYPE_PUBLIC
				? `${intl.formatMessage(messages.internalNote)}`
				: `${visibility.value} • ${intl.formatMessage(messages.internalNote)}`;

		return (
			<InternalCommentContainer isEditing={isEditing} highlightColor={highlightColor}>
				{this.renderComment(restrictedToText)}
			</InternalCommentContainer>
		);
	};

	setCommentPermalinkContainer = (container: HTMLElement | null) => {
		this.commentPermalinkContainer = container;
	};

	setOptimisticCommentPermalinkContainer = (container: HTMLElement | null) => {
		this.optimisticCommentContainerRef = container;
	};

	commentPermalinkContainer: HTMLElement | null | undefined;

	scrollCommentIntoView = () => {
		const {
			hasScrolledPermalink,
			shouldScrollAfterAsyncComponentsLoaded,
			setScrollStatus,
			createAnalyticsEvent,
		} = this.props;

		// hasScrolledPermalink ensures we only scroll once on initial page load.
		// ensure we don't scroll if we resize after initial load.
		// shouldScrollAfterAsyncComponentsLoaded used to ensure we only scroll when all async data is finished fetching.
		if (!hasScrolledPermalink && shouldScrollAfterAsyncComponentsLoaded) {
			smoothScrollIntoViewIfNeeded(this.commentPermalinkContainer, {
				scrollMode: 'always',
				behavior: 'smooth',
			});
			setScrollStatus(true);

			const analyticsEvent = createAnalyticsEvent({
				action: 'scrolled',
			});
			fireUIAnalytics(analyticsEvent, 'permalink comment', { name: 'comment' });
		}
	};

	render() {
		const { visibility, isInternal } = this.props;

		if (isInternal) {
			return this.renderInternalComment();
		}
		return this.renderComment(visibility.value);
	}
}

const showPermalink = (e: SyntheticEvent, permalink: Permalink) => {
	e.stopPropagation();
	permalink.show();
};

const hidePermalink = (e: SyntheticEvent, permalink: Permalink) => {
	e.stopPropagation();
	permalink.hide();
};

const CommentWithIssueOfIncidentsPractice = (props: Props) => {
	const isIssueOfIncidentsPractice = useIsIssueOfIncidentsPractice();

	return <CommentBase {...props} isIssueOfIncidentsPractice={isIssueOfIncidentsPractice} />;
};

export const Comment = componentWithCondition(
	isJsmTimelineEnabled,
	CommentWithIssueOfIncidentsPractice,
	CommentBase,
);
export default Comment;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const GridContainer = styled.span({
	gridRow: 1,
	marginLeft: token('space.050', '4px'),
	whiteSpace: 'nowrap',
});

GridContainer.displayName = 'GridContainer';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const GridContentContainer = styled.span({
	display: 'grid',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const GridContentContainerVisualRefresh = styled.span({
	display: 'grid',
	alignItems: 'center',
});

GridContentContainer.displayName = 'GridContentContainer';
GridContentContainerVisualRefresh.displayName = 'GridContentContainer';

// TODO https://product-fabric.atlassian.net/browse/FS-4202
// Ideally we would style the Reactions component itself, but currently
// AK doesn't allow us to do it. So we're wrapping the component on this div
// and pulling it to the left as much as we can without covering the item separator (".")
// we're also giving it a specific height so the item separator will be properly aligned.

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReactionsContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 2.5}px`,
	marginBottom: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CommentWrapper = styled.div({
	marginTop: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:first-child, &:first-of-type': {
		padding: 0,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CommentWrapperVisualRefresh = styled.div({
	margin: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:first-child, &:first-of-type': {
		padding: 0,
	},
});

const IssueCommentWrapper = componentWithCondition(
	() => isVisualRefreshEnabled() && fg('jira_nav4_eap_drop_2'),
	CommentWrapperVisualRefresh,
	CommentWrapper,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LozengeWrapper = styled.div({
	display: 'inline-flex',
	marginLeft: token('space.150', '12px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DescriptionAction = styled.div({
	display: 'inline-block',
	/* so styling lines up with other feeds */
	marginLeft: token('space.negative.050', '-4px'),
	marginRight: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> span': {
		fontWeight: token('font.weight.medium'),
	},
});
