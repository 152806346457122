import type { ViewSet } from '@atlassian/jira-polaris-domain-view/src/view-set/types.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import { type State, type Props, ViewSectionTypeViews } from '../../types.tsx';
import { loadViewComments } from '../comments/load-view-comments/index.tsx';
import { loadViewDescription } from '../description/load-view-description/index.tsx';
import { updateState as updateSectionState } from '../sections/index.tsx';
import {
	internRemoteView,
	doesViewMatchSlug,
	getFirstAvailableViewIdInViewSet,
} from '../utils/views/index.tsx';

export const setViewSets =
	(viewSets: ViewSet[]): Action<State, Props> =>
	(
		{ setState, dispatch },
		{ fields, router, currentViewSlug, currentViewSection, isSharedView, isCollectionView },
	) => {
		let hasCurrentView = false;

		const updated = viewSets.map((viewSet) => {
			const views = viewSet.views.map((remoteView) => {
				const modRemoteView = internRemoteView(fields, remoteView, isSharedView, isCollectionView);
				if (
					currentViewSlug !== undefined &&
					doesViewMatchSlug(modRemoteView, currentViewSlug) &&
					isSharedView !== true
				) {
					hasCurrentView = true;
					router.routeTo(
						{
							section: ViewSectionTypeViews,
							resource: modRemoteView.slug,
						},
						{
							clearParameters: false,
							replaceInHistory: true,
						},
					);
				}
				return modRemoteView;
			});
			const newViewSets = viewSet.viewSets?.map((vSet: ViewSet) => ({
				...vSet,
				views: vSet.views.map((remoteView) => {
					const modRemoteView = internRemoteView(
						fields,
						remoteView,
						isSharedView,
						isCollectionView,
					);
					if (
						currentViewSlug !== undefined &&
						doesViewMatchSlug(modRemoteView, currentViewSlug) &&
						isSharedView !== true
					) {
						hasCurrentView = true;
						router.routeTo(
							{
								section: ViewSectionTypeViews,
								resource: modRemoteView.slug,
							},
							{
								clearParameters: false,
								replaceInHistory: true,
							},
						);
					}
					return modRemoteView;
				}),
			}));
			return {
				...viewSet,
				views,
				viewSets: newViewSets,
			};
		});

		// if there isn't a current view yet, we take the first view of the capture viewset (if there is one) including nested sections,
		// then of the prioritize viewset, otherwise the first view of a viewset that has some views
		// NOTE: in that case we do not clear the URL parameters when rerouting to the default view. This makes
		// sure we redirect to the selected issue correctly.
		let newCurrentView;
		let sectionId;
		if (currentViewSection === ViewSectionTypeViews && !hasCurrentView) {
			const getFirstAvailableViewIdInViewSetWithType = getFirstAvailableViewIdInViewSet(updated);
			const viewData =
				getFirstAvailableViewIdInViewSetWithType('CAPTURE') ||
				getFirstAvailableViewIdInViewSetWithType('PRIORITIZE');

			// `setViewSets(...)` is called for `loadView` and `loadViews` actions
			// and in case when user picks a project, first the redirect chain look like
			// 1. /browse/:projectKey
			// 2. /jira/polaris/projects/:projectKey/ideas
			// 3. /jira/polaris/projects/DSA/ideas/view/1052268
			//
			// We want to replace `2.` with `3.` item in the history stack
			// as going back to `2.` would cause an infinite spinner
			const replaceInHistory = currentViewSlug === undefined;

			if (viewData?.view !== undefined && isSharedView !== true) {
				router.routeTo(
					{
						section: ViewSectionTypeViews,
						resource: viewData.view.slug,
					},
					{
						clearParameters: false,
						replaceInHistory,
					},
				);
				newCurrentView = viewData.view;
				sectionId = viewData.sectionId;
			} else {
				const firstViewSetWithViews = updated.find(
					(viewSet) => viewSet.views !== undefined && viewSet.views.length > 0,
				);
				if (firstViewSetWithViews !== undefined && isSharedView !== true) {
					router.routeTo(
						{
							section: ViewSectionTypeViews,
							resource: firstViewSetWithViews.views[0].slug,
						},
						{
							clearParameters: false,
							replaceInHistory,
						},
					);
					[newCurrentView] = firstViewSetWithViews.views;
				}
			}
		}

		setState({
			viewSets: updated,
		});

		if (sectionId !== undefined) {
			dispatch(
				updateSectionState({
					id: sectionId,
					collapsed: true, // due to the logical bug we need to set `collapsed: true` to expand the section
				}),
			);
		}

		if (newCurrentView?.viewId !== undefined) {
			dispatch(loadViewComments(newCurrentView.viewId));
			dispatch(loadViewDescription(newCurrentView.viewId));
		}
	};
