import { query$ } from '@atlassian/jira-software-swag/src/services/query/index.tsx';
import type { ResponseData } from './types.tsx';

const OPERATION_NAME = 'DevDetailsDialog';

const branchesQuery = `branches {
        name
        url
        createReviewUrl
        createPullRequestUrl
        lastCommit {
            url
            displayId
            timestamp
        }
        pullRequests {
            name
            url
            status
            lastUpdate
        }
        reviews {
            state
            url
            id
        }
    }`;

const commitsQuery = `commits{
        id
        displayId
        url
        createReviewUrl
        timestamp
        isMerge
        message
        author {
          name
          avatarUrl
        }
        files{
          linesAdded
          linesRemoved
          changeType
          url
          path
        }
        reviews{
          id
          url
          state
        }
    }`;

const getPullRequestQuery = (prName: string) =>
	`${prName} {
        id
        url
        name
        branchName
        branchUrl
        destinationBranchName
        destinationBranchUrl
        lastUpdate
        status
        author {
          name
          avatarUrl
        }
        reviewers{
          name
          avatarUrl
          isApproved
        }
        repositoryId
        repositoryName
        repositoryUrl
        repositoryAvatarUrl
    }`;
const buildProvidersQuery = `buildProviders {
          id
          name
          url
          description
          avatarUrl
          builds {
            id
            buildNumber
            name
            description
            url
            state
            testSummary {
              totalNumber
              numberPassed
              numberFailed
              numberSkipped
            }
            lastUpdated
            references {
              name
              uri
            }
          }
        }`;

const deploymentProvidersQuery = `deploymentProviders {
          id
          name
          homeUrl
          logoUrl
          deployments {
            displayName
            url
            state
            lastUpdated
            pipelineId
            pipelineDisplayName
            pipelineUrl
            environment {
                id
                type
                displayName
            }
          }
        }`;

const featureFlagsQuery = `featureFlagProviders {
        id
        createFlagTemplateUrl
        linkFlagTemplateUrl
        featureFlags {
            id
            key
            displayName
            providerId
            details{
                url
                lastUpdated
                environment{
                    name
                    type
                }
                status{
                enabled
                defaultValue
                rollout{
                    percentage
                    text
                    rules
                }
            }
        }
    }
}`;

const remoteLinksQuery = `remoteLinksByType {
        providers {
            id
            name
            homeUrl
            logoUrl
            documentationUrl
            actions {
                id
                label {
                    value
                }
                templateUrl
            }
        }
        types {
            type
            remoteLinks {
                id
                providerId
                displayName
                url
                type
                description
                status {
                    appearance
                    label
                }
                actionIds
                attributeMap {
                    key
                    value
                }
            }
        }
    }`;

const embeddedMarketplaceQuery = `
    embeddedMarketplace {
        shouldDisplayForBuilds,
        shouldDisplayForDeployments,
        shouldDisplayForFeatureFlags
      }
`;

// TYPO FIX: FUSE-8114 repository needs to be repositories
// TODO: FUSE-8114 buildProviders will be moved out of instanceTypes and placed under details
const innerQuery = () => `
    details {
        instanceTypes {
            id
            name
            type
            typeName
            isSingleInstance
            baseUrl
            devStatusErrorMessages
            repository {
                id
                name
                avatarUrl
                description
                url
                parent {
                    name
                    url
                }
                ${branchesQuery}
                ${commitsQuery}
                ${getPullRequestQuery('pullRequests')}
            }
            ${getPullRequestQuery('danglingPullRequests')}
            ${buildProvidersQuery}
         }
         ${deploymentProvidersQuery}
         ${featureFlagsQuery}
         ${remoteLinksQuery}
         ${embeddedMarketplaceQuery}
    }
`;

const swagGraphQLQuery = () => `
    query ${OPERATION_NAME} ($issueId: ID!) {
        developmentInformation(issueId: $issueId){
            ${innerQuery()}
        }
    }`;

const query = (baseUrl: string, issueId: string): Promise<ResponseData> =>
	// @ts-expect-error - TS2322 - Type 'Promise<ResponseData | { data: unknown; }>' is not assignable to type 'Promise<ResponseData>'.
	query$(baseUrl, OPERATION_NAME, swagGraphQLQuery(), { issueId })
		.toPromise()
		.then((result) => ({ data: result }));

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (baseUrl: string, issueId: string): Promise<ResponseData> => query(baseUrl, issueId);
