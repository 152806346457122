import { useMemo } from 'react';
import { useJiraSoftwareProjectNavigation } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/common/project-sidebar-navigation-resource/index.tsx';
import type { UseFeaturesReturn } from './types.tsx';
import { extractData } from './utils/extract-data.tsx';

/* As part of breaking cycles between @atlassian/jira-development-details -> @atlassian/jira-navigation-apps-sidebar-software-classic we copied useFeatures utils to /packages/development/details/src/main/view/deployments-tab/environment-types/utils/index.tsx and its references(types & utils). Kindly update the if there is changes in below useFeatures change so that both utils will be always in-sync */
export const useFeatures = (): UseFeaturesReturn => {
	const { data, loading, error } = useJiraSoftwareProjectNavigation();

	const extractedData = useMemo(() => data && extractData(data), [data]);
	return { data: extractedData, loading, error };
};
