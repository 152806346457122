import React from 'react';
import { styled } from '@compiled/react';
import { useFragment, graphql } from 'react-relay';
import Badge from '@atlaskit/badge';
import { useIntl } from '@atlassian/jira-intl';
import { EmptyFieldText } from '@atlassian/jira-issue-empty-field-text/src/ui/index.tsx';
import type { storyPointEstimate_issueFieldStoryPointEstimateReadviewFull_StoryPointEstimateReadView$key as StoryPointEstimateFragment } from '@atlassian/jira-relay/src/__generated__/storyPointEstimate_issueFieldStoryPointEstimateReadviewFull_StoryPointEstimateReadView.graphql';
import {
	STORY_POINT_ESTIMATE_BADGE_TEST_ID,
	STORY_POINT_ESTIMATE_TEST_ID,
} from '../../common/constants.tsx';
import type { StoryPointEstimateReadViewProps } from './types.tsx';

export const StoryPointEstimateReadView = ({ fragmentRef }: StoryPointEstimateReadViewProps) => {
	const { formatNumber } = useIntl();

	const data = useFragment<StoryPointEstimateFragment>(
		graphql`
			fragment storyPointEstimate_issueFieldStoryPointEstimateReadviewFull_StoryPointEstimateReadView on JiraNumberField {
				number
			}
		`,
		fragmentRef,
	);

	const { number } = data;

	return (
		<span data-testid={STORY_POINT_ESTIMATE_TEST_ID}>
			{number == null ? (
				<EmptyFieldText />
			) : (
				<BadgeWrapper>
					<Badge testId={STORY_POINT_ESTIMATE_BADGE_TEST_ID}>{formatNumber(number)}</Badge>
				</BadgeWrapper>
			)}
		</span>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const BadgeWrapper = styled.div({
	borderWidth: '2px',
	borderStyle: 'solid',
	borderColor: 'transparent',
});
