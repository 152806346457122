import React from 'react';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import type { Area } from '@atlassian/jira-issue-view-common-types/src/connect-field-type.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import type { issueViewEntitlementField_servicedeskCustomerServiceEntitlementsField_EntitlementsField$key } from '@atlassian/jira-relay/src/__generated__/issueViewEntitlementField_servicedeskCustomerServiceEntitlementsField_EntitlementsField.graphql';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import { useUFOComponentExperience } from '@atlassian/ufo';
import { entitlementFieldIssueViewMountExperience } from './experiences.tsx';
import type EntitlementsField from './ui/issue-view-entitlement-field/index.tsx';

const LazyEntitlementsField = lazyForPaint<typeof EntitlementsField>(
	() =>
		import(
			/* webpackChunkName: "async-customer-service-entitlements-field" */ './ui/issue-view-entitlement-field'
		),
);

export const AsyncEntitlementsField = (props: {
	area?: Area;
	fragmentKey: issueViewEntitlementField_servicedeskCustomerServiceEntitlementsField_EntitlementsField$key;
}) => {
	useUFOComponentExperience(entitlementFieldIssueViewMountExperience);

	return (
		<ErrorBoundary
			id="jsmCustomerServiceEntitlementsField"
			packageName="atlassian/jira-servicedesk-customer-service-entitlements-field"
			onError={() => entitlementFieldIssueViewMountExperience.failure()}
		>
			<Placeholder name="async-customer-service-entitlements-field" fallback={null}>
				<LazyEntitlementsField {...props} />
			</Placeholder>
		</ErrorBoundary>
	);
};
