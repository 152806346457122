import { createSelector } from 'reselect';
import isEqual from 'lodash/isEqual';
import keyBy from 'lodash/keyBy';
import set from 'lodash/set';
import memoizeOne from 'memoize-one';
import type { FieldOption } from '@atlassian/jira-polaris-domain-field/src/field-option/types.tsx';
import type { OptionFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/option/types.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import {
	COUNT_ROLLUP,
	EMPTY_ROLLUP,
	FILLED_ROLLUP,
	AVG_ROLLUP,
	MEDIAN_ROLLUP,
	SUM_ROLLUP,
} from '@atlassian/jira-polaris-domain-field/src/rollup/constants.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { Filter } from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import type { IssuesRemote } from '@atlassian/jira-polaris-remote-issue/src/controllers/types.tsx';
import { jiraOptionMapping } from '../../../../field/mapping/option/index.tsx';
import type { PropertyMaps, State } from '../../../types.tsx';
import {
	createCombinedComparator,
	nullSafeComparator,
	numberComparator,
} from '../../comparators/index.tsx';
import { createStringValueContainsFilter } from '../common/filter-utils.tsx';
import { getOptionIndex, getOptionWeight } from '../common/option-utils.tsx';
import { removePropertyValue } from '../common/remove-utils.tsx';
import type { FieldMapping } from '../types.tsx';

const optionMappingInternal = (
	issuesRemote: IssuesRemote,
	field: Field,
): FieldMapping<OptionFieldValue> => {
	const optionsById = keyBy(field.options, ({ jiraOptionId }) => jiraOptionId);
	const valueAccessor: FieldMapping<OptionFieldValue>['valueAccessor'] = (state, props, issueId) =>
		state.properties.singleSelect[field.key]?.[issueId];

	const valueAccessorToExport: FieldMapping<string>['valueAccessorToExport'] = (
		state,
		props,
		issueId,
		options,
	) => {
		const selected = valueAccessor(state, props, issueId);
		return selected
			? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				(options as FieldOption[]).find((opt: FieldOption) => opt.jiraOptionId === selected.id)
					?.value
			: '';
	};

	return {
		...jiraOptionMapping(issuesRemote, field),
		setMutable: (maps: PropertyMaps, issueId: LocalIssueId, value?: OptionFieldValue) =>
			set(maps.singleSelect, [field.key, issueId], value),
		// @ts-expect-error(PARTIAL_RECORD) TS2322 - Type '(maps: PropertyMaps, issueId: LocalIssueId, value?: IssueOptionProperty) => { singleSelect: { [x: string]: Record<string, IssueOptionProperty> | { [x: string]: IssueOptionProperty | undefined; }; }; ... 17 more ...; commentsMetadata: CommentsMetadataMap; }' is not assignable to type '(arg1: PropertyMaps, arg2: string, arg3: IssueOptionProperty | undefined) => PropertyMaps'.
		setImmutable: (maps: PropertyMaps, issueId: LocalIssueId, value?: OptionFieldValue) => {
			if (maps.singleSelect[field.key] && isEqual(maps.singleSelect[field.key][issueId], value)) {
				return maps;
			}
			return {
				...maps,
				singleSelect: {
					...maps.singleSelect,
					[field.key]: {
						...maps.singleSelect[field.key],
						[issueId]: value,
					},
				},
			};
		},
		remove: (maps: PropertyMaps, issueId: LocalIssueId) =>
			removePropertyValue(field.key, maps, issueId, 'singleSelect'),
		modifyImmutableIfMultiValueField: (maps: PropertyMaps) => maps,
		comparator: createCombinedComparator([
			nullSafeComparator<OptionFieldValue>((a, b, direction) =>
				numberComparator(getOptionWeight(field, a), getOptionWeight(field, b), direction),
			),
			nullSafeComparator<OptionFieldValue>((a, b, direction) =>
				numberComparator(getOptionIndex(field, a), getOptionIndex(field, b), direction),
			),
		]),
		valueAccessor,
		valueAccessorToExport,
		getAllValues: (state) => state.properties.singleSelect[field.key] ?? {},
		getGroupIdentitiesSelector: (fieldKey, issueIdsSelector) =>
			createSelector(
				issueIdsSelector,
				(state: State) => state.properties.singleSelect[fieldKey],
				(ids, singleSelect) =>
					ids.reduce(
						(result, issueId) =>
							Object.assign(result, {
								[issueId]:
									singleSelect !== undefined && singleSelect[issueId] !== undefined
										? [
												{
													groupIdentity: singleSelect[issueId].id,
													value: singleSelect[issueId],
												},
											]
										: [],
							}),
						{},
					),
			),
		getGroupIdentities: (state, _, issueId) =>
			state.properties.singleSelect[field.key] !== undefined &&
			state.properties.singleSelect[field.key][issueId] !== undefined
				? [
						{
							groupIdentity: state.properties.singleSelect[field.key][issueId].id,
							value: state.properties.singleSelect[field.key][issueId],
						},
					]
				: [],
		getRollupOperations: () => [
			COUNT_ROLLUP,
			EMPTY_ROLLUP,
			FILLED_ROLLUP,
			AVG_ROLLUP,
			MEDIAN_ROLLUP,
			SUM_ROLLUP,
		],
		allowEmptyGroup: true,
		getLabel: (groupIdentity) => optionsById[groupIdentity]?.value,
		getFilter: (filter: Filter) => {
			if (filter.type === 'FIELD' && filter.field === field.key) {
				const stringValueContainsFilter = createStringValueContainsFilter(filter);
				if (stringValueContainsFilter === undefined) {
					return undefined;
				}
				return (value: undefined | OptionFieldValue, state, props, localIssueId) =>
					stringValueContainsFilter(value?.id, state, props, localIssueId);
			}
			return undefined;
		},
		getWeight: (groupIdentity) => optionsById[groupIdentity]?.weight,
	};
};

export const optionMapping = memoizeOne(optionMappingInternal);
