import { gqlTagRest } from '@atlassian/jira-common-graphql-tags/src/index.tsx';
import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';
import { getGraphQlUrl } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-graphql-data/index.tsx';
import { IssueViewFetchOperations } from '@atlassian/jira-issue-fetch-services-common/src/types.tsx';
import type { IssueKey, BaseUrl } from '@atlassian/jira-shared-types/src/general.tsx';

export const getViewScreenGraphQlQuery = (issueKey: IssueKey) =>
	gqlTagRest`query viewScreenIdQuery {
            issue(issueIdOrKey: "${issueKey}", latestVersion: true, screen: "view") {
                id
                viewScreenId
            }
        }`;

const getFieldDataOptions = (issueKey: IssueKey) => ({
	method: 'POST',
	body: JSON.stringify({
		query: getViewScreenGraphQlQuery(issueKey),
	}),
});

export const fetchIssueViewScreenId = (baseUrl: BaseUrl, issueKey: IssueKey) =>
	fetchJson$(
		getGraphQlUrl(baseUrl, IssueViewFetchOperations.VIEW_SCREEN_ID),
		getFieldDataOptions(issueKey),
	);
