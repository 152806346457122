import React, {
	useCallback,
	type FC,
	type PropsWithChildren,
	type KeyboardEvent as ReactKeyboardEvent,
} from 'react';
/*
 * This component provides an easy way to prevent the keydown events from bubbling past this component at a DOM level.
 *
 * This should only be used when needing to block events keyboard events that aren't making use of the existing Shortcuts component.
 *
 * This is only necessary for preventing issue arrow navigation in GIN. This can be removed once GIN has been completely replaced by NIN.
 */

/**
 * blocks all keyboard events from going above boundary.
 * Consider {@link ShortcutScope} to control reactions to hotkeys.
 * Can be replaced by it once GIN has been completely replaced by NIN.
 * @param children
 * @constructor
 */
export const SuppressKeyboardEvents: FC<PropsWithChildren> = ({ children }) => {
	const suppressEvent = useCallback((e: ReactKeyboardEvent) => {
		// just suppress event from going above
		e.stopPropagation();
	}, []);

	return (
		// eslint-disable-next-line jsx-a11y/no-static-element-interactions
		<div data-testid="common-components-arrow-keys-handler.div" onKeyDown={suppressEvent}>
			{children}
		</div>
	);
};
