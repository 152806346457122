import {
	DONE,
	IN_PROGRESS,
	TODO,
} from '@atlassian/jira-polaris-domain-field/src/field-types/status/types.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { DELIVERY_CALCULATION_MODE } from '@atlassian/jira-polaris-domain-field/src/presentation/constants.tsx';
import { NUMERIC_FIELD_FILTER } from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import type { LinkedIssuesFormula } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/linked-issues/types.tsx';
import { createGetLinkedDeliveryIssuesExist } from '../../../selectors/properties/linked-issues/index.tsx';
import { statusCategoryAggregateComparator } from '../../comparators/index.tsx';
import { CSV_SEPARATOR_DELIVERY } from '../common/constanst.tsx';
import { defaultMapping } from '../default/index.tsx';
import type { MappingFilterFunction, FieldMapping, DeliveryValuesExport } from '../types.tsx';
import { createFilterFunction } from './filter/index.tsx';

export const linkedDeliveryIssuesStatusMapping = (field: Field): FieldMapping<number[]> => {
	const valueAccessor: FieldMapping<number[]>['valueAccessor'] = (state, _, issueId) =>
		state.properties.aggregatedDeliveryProgress[issueId];

	const valueAccessorToExport: FieldMapping<string>['valueAccessorToExport'] = (
		state,
		props,
		issueId,
		deliveryValuesExport,
	) => {
		const { statusCategories, formatMessage, messages } =
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			deliveryValuesExport as DeliveryValuesExport;

		const progress = valueAccessor(state, props, issueId);
		const linkedDeliveryIssuesExist = createGetLinkedDeliveryIssuesExist(issueId)(state);
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const calculationMode = (field?.formula as LinkedIssuesFormula)?.parameters?.calculationMode;

		if (!progress || progress.length === 0 || !statusCategories) {
			return '';
		}

		const tooltipMessage =
			calculationMode === DELIVERY_CALCULATION_MODE.STORY_POINTS
				? messages.deliveryTooltipStoryPoints
				: messages.deliveryStatusTooltip;

		const categoriesNames = [
			statusCategories[TODO].name,
			statusCategories[IN_PROGRESS].name,
			statusCategories[DONE].name,
		];

		let totalIssues = 0;
		const multipleStatus: { name: string; count: number }[] = progress.map(
			(count: number, index: number) => {
				totalIssues += count;
				return {
					name: categoriesNames[index],
					count,
				};
			},
		);

		if (linkedDeliveryIssuesExist && totalIssues === 0) {
			return formatMessage(messages.unestimated);
		}

		if (totalIssues === 0) {
			return '';
		}

		const singleStatus = multipleStatus.find(({ count }) => count === 1);
		if (singleStatus && totalIssues === 1) {
			// This will create a formatted messages for single tickets
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			return formatMessage(tooltipMessage, {
				count: singleStatus.count,
				category: singleStatus.name,
				total: 1,
			}) as string;
		}

		return multipleStatus
			.map(
				({ name, count }) =>
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					formatMessage(tooltipMessage, {
						count,
						category: name,
						total: totalIssues,
					}) as string,
			)
			.join(CSV_SEPARATOR_DELIVERY);
	};

	return {
		...defaultMapping,
		field,
		comparator: statusCategoryAggregateComparator,
		valueAccessor,
		valueAccessorToExport,
		getFilter: (filter): MappingFilterFunction<number[]> | undefined =>
			filter.type === NUMERIC_FIELD_FILTER && filter.field === field.key
				? createFilterFunction(filter.values)
				: undefined,
	};
};
