// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	noValueText: {
		id: 'issue.placeholder-for-empty-issue-description-field',
		defaultMessage: 'Add a description...',
		description: "A placeholder when there's no description",
	},
});
