import React from 'react';
import {
	issueTypeAvatarSizesMap,
	makeAvatarUrlFromId,
	type IssueTypeAvatarSizes,
} from '@atlassian/jira-polaris-component-issue-types/src/ui/utils/make-avatar-url-from-id.tsx';

type Props = {
	avatarId: string;
	size: IssueTypeAvatarSizes;
};

export const IssueTypeAvatar = ({ avatarId, size }: Props) => {
	const avatarUrl = makeAvatarUrlFromId(avatarId, size);
	return <img src={avatarUrl} alt="" width={issueTypeAvatarSizesMap[size]} />;
};
