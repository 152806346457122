/**
 *  Analog of $(element).innerHeight()
 *  This function will still works if [adding is set in % in css, because getComputedStyle() always returns value in pixels
 */
export const getInnerHeight = (element: HTMLElement | null): number | undefined => {
	if (!element) {
		return undefined;
	}

	const elementStyle = getComputedStyle(element);
	const elementPadding =
		(parseFloat(elementStyle.paddingTop) || 0) + (parseFloat(elementStyle.paddingBottom) || 0);
	return element.clientHeight - elementPadding; // Doing this instead of .getComputedStyle().height; to make it work for both 'box-sizing: content-box' and 'border-box'. .clientHeight excludes  horizontal scrollbars height which is good
};
