import React from 'react';
import { styled } from '@compiled/react';
import { ButtonGroup } from '@atlaskit/button';
import Lozenge from '@atlaskit/lozenge';
import { colors } from '@atlaskit/theme';

import { token } from '@atlaskit/tokens';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { SectionHeading } from '@atlassian/jira-issue-view-common/src/component/section-heading/section-heading-view.tsx';
import type { TPanelHeaderProps } from '../../../common/types.tsx';
import Expander from './expander/index.tsx';
import messages from './messages.tsx';
import SparklesIcon from './sparkles-icon/index.tsx';

const SimilarIncidentsPanelHeader = ({ onExpand, onCollapse, isExpanded }: TPanelHeaderProps) => {
	const { formatMessage } = useIntl();

	const handleHeaderClick = () => (isExpanded ? onCollapse() : onExpand());

	return (
		<HeaderWrapper onClick={handleHeaderClick} isExpanded={isExpanded}>
			<HeaderTextContainer>
				<SparklesIcon />
				<HeaderTitle>&nbsp;&nbsp;{formatMessage(messages.title)}&nbsp;&nbsp;&nbsp;</HeaderTitle>
				<Lozenge appearance="new">{formatMessage(messages.new)}</Lozenge>
				{isExpanded && <HeaderDescription>{formatMessage(messages.description)}</HeaderDescription>}
			</HeaderTextContainer>
			<ButtonGroup>
				<Expander isContentExpanded={isExpanded} onExpand={onExpand} onCollapse={onCollapse} />
			</ButtonGroup>
		</HeaderWrapper>
	);
};

export default SimilarIncidentsPanelHeader;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderWrapper = styled.div<{
	isExpanded: boolean;
}>(
	{
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginTop: token('space.100', '-18px'),
		marginBottom: token('space.300', '-24px'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		borderRadius: `${gridSize / 2}px`,
		borderWidth: '1px',
		borderStyle: 'solid',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		borderColor: `${token('color.border', colors.N40)}`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('elevation.surface', colors.N0),
		padding: token('space.200', '16px'),
		'&:hover, &:focus, &:focus-within': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			backgroundColor: token('elevation.surface.overlay.hovered', colors.N20),
			cursor: 'pointer',
		},
		'&:focus': {
			'outline-color': token('color.border.focused', '#2684FF'),
		},
		'&:active': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			backgroundColor: token('elevation.surface.overlay.pressed', colors.B50),
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			borderColor: token('color.border', colors.B300),
		},

		'&::-webkit-details-marker': {
			display: 'none',
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isExpanded }) =>
		isExpanded && 'border-bottom-left-radius: 0;  border-bottom-right-radius: 0; margin-bottom: 0;',
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderTextContainer = styled(SectionHeading)({
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	minWidth: '0',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderTitle = styled.span({
	margin: '0',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderDescription = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: `${token('color.text.subtlest', colors.N200)}`,
	margin: `0 auto 0 ${token('space.100', '8px')}`,
	textOverflow: 'ellipsis',
	overflow: 'hidden',
});
