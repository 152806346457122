import type { Action } from '@atlassian/react-sweet-state';
import type { State } from '../types.tsx';

export const initialized =
	(): Action<State> =>
	({ getState, setState }) => {
		const wasInitilized = getState().initialized;
		if (!wasInitilized) {
			setState({
				initialized: true,
			});
		}
		return wasInitilized === true ? Promise.resolve() : undefined;
	};
