import React, {
	Component,
	type ComponentType,
	type PropsWithChildren,
	type ReactNode,
} from 'react';
import isShallowEqual from '@atlassian/jira-common-util-is-shallow-equal/src/index.tsx';

export type AnimationProps = {
	onAnimationEnd: () => void;
};

type Props<UpdateProps> = {
	animationIn: ComponentType<PropsWithChildren<AnimationProps>>;
	animationOut: ComponentType<PropsWithChildren<AnimationProps>>;
	updateProps: UpdateProps;
	children: (updateProps: UpdateProps) => ReactNode;
};

type State<UpdateProps> = {
	prevUpdateProps: UpdateProps | null;
	shouldAnimateIn: boolean;
	shouldAnimateOut: boolean;
};

// eslint-disable-next-line jira/react/no-class-components
export class UpdateAnimator<UpdateProps extends Record<string, unknown>> extends Component<
	Props<UpdateProps>,
	State<UpdateProps>
> {
	state: State<UpdateProps> = {
		prevUpdateProps: null,
		shouldAnimateIn: false,
		shouldAnimateOut: false,
	};

	UNSAFE_componentWillReceiveProps = (nextProps: Props<UpdateProps>) => {
		if (!isShallowEqual(nextProps.updateProps, this.props.updateProps)) {
			this.setState({ prevUpdateProps: this.props.updateProps, shouldAnimateOut: true });
		}
	};

	onAnimationOutEnd = () => {
		this.setState({ prevUpdateProps: null, shouldAnimateOut: false, shouldAnimateIn: true });
	};

	onAnimationInEnd = () => {
		this.setState({ shouldAnimateIn: false });
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	animationWrapper = (element: any) => {
		const { animationOut: AnimationOut, animationIn: AnimationIn } = this.props;

		if (this.state.shouldAnimateOut) {
			return <AnimationOut onAnimationEnd={this.onAnimationOutEnd}>{element}</AnimationOut>;
		}

		if (this.state.shouldAnimateIn) {
			return <AnimationIn onAnimationEnd={this.onAnimationInEnd}>{element}</AnimationIn>;
		}
		return element;
	};

	render() {
		const animatedProps = this.state.prevUpdateProps || this.props.updateProps;

		return this.animationWrapper(this.props.children(animatedProps));
	}
}
