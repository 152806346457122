import React from 'react';
import { CommentEdited } from '@atlaskit/comment';
import { Box, xcss } from '@atlaskit/primitives';
import type { IntlShape } from '@atlassian/jira-intl';
import messages from '../messages.tsx';

type CommentDeletedProps = {
	intl: IntlShape;
};

const CommentDeleted = ({ intl }: CommentDeletedProps) => {
	return (
		<Box xcss={commentDeletedStyles}>
			<CommentEdited>{intl.formatMessage(messages.commentDeleted)}</CommentEdited>
		</Box>
	);
};

const commentDeletedStyles = xcss({
	marginTop: 'space.075',
});

export default CommentDeleted;
