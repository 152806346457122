import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	arrowRight: {
		id: 'issue-view-layout-group.arrow-right',
		defaultMessage: 'Collapsed',
		description: 'Arrow pointing that block is collapsed (accessibility label)',
	},
	arrowDown: {
		id: 'issue-view-layout-group.arrow-down',
		defaultMessage: 'Expanded',
		description: 'Arrow pointing that block is expanded (accessibility label)',
	},
	configure: {
		id: 'issue-view-layout-group.configure',
		defaultMessage: 'Configure',
		description: 'Tooltip over the configure button.',
	},
});
