export const extractErrorCollectionMessages = (message: string) => {
	try {
		const errorCollection = JSON.parse(message);
		const fieldErrors = Object.values(errorCollection.errors || {});
		const errorMessages = errorCollection.errorMessages || [];
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return [...errorMessages, ...fieldErrors].join(' ') as string;
	} catch {
		return message;
	}
};
