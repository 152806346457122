import React from 'react';
import { Text } from '@atlaskit/primitives';
import { components } from '@atlaskit/select';
import { fg } from '@atlassian/jira-feature-gating';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import type { TeamFieldType } from '../types.tsx';
import messages from './messages.tsx';

type Props = {
	data: TeamFieldType;
	intl: IntlShape;
};

const SingleValueComponent = (props: Props) => {
	const { formatMessage } = useIntl();
	const { data } = props;
	const label = data ? (
		data.label
	) : (
		<Text color="color.text.subtlest">
			{formatMessage(
				fg('jira-issue-terminology-refresh-m3')
					? messages.placeholderIssueTermRefresh
					: messages.placeholder,
			)}
		</Text>
	);

	// @ts-expect-error - TS2740 - Type '{ children: string | Element; data: TeamFieldType; intl: IntlShapeV2; }' is missing the following properties from type 'CommonProps<OptionTypeBase, false, GroupTypeBase<OptionTypeBase>>': clearValue, cx, getStyles, getValue, and 8 more.
	return <components.SingleValue {...props}>{label}</components.SingleValue>;
};

export default SingleValueComponent;
