import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	errorFlagTitle: {
		id: 'invite-and-assign.controllers.invite-user.error-flag-title',
		defaultMessage: 'We couldn’t send your invite',
		description: 'Title for the error flag if an invite is unsuccessful.',
	},
	errorFlagDescription: {
		id: 'invite-and-assign.controllers.invite-user.error-flag-description',
		defaultMessage:
			'Try sending the invite again. If this problem persists, contact your site administrator.',
		description: 'Description for the error flag if an invite is unsuccessful.',
	},
});
