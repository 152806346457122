import {
	createStore,
	createHook,
	createSubscriber,
	createContainer,
	type Action,
	type SubscriberComponent,
} from '@atlassian/react-sweet-state';

export const MIN_EDITOR_SIDEBAR_CONTAINER_WIDTH = 360;
export const SIDEBAR_MIN_WIDTH_DEFAULT = 255;

type State = {
	sidebarMinWidth: number;
};

const initialState: State = {
	sidebarMinWidth: SIDEBAR_MIN_WIDTH_DEFAULT,
};

const actions = {
	updateSidebarMinWidth:
		(sidebarMinWidth: number): Action<State> =>
		({ setState, getState }) => {
			setState({
				...getState(),
				sidebarMinWidth,
			});
		},
	resetSidebarMinWidth:
		(): Action<State> =>
		({ setState, getState }) => {
			setState({
				...getState(),
				sidebarMinWidth: SIDEBAR_MIN_WIDTH_DEFAULT,
			});
		},
} as const;

type ActionType = typeof actions;

const Store = createStore<State, ActionType>({
	initialState,
	actions,
	name: 'issue-layout',
});

export const IssueLayoutSubscriber: SubscriberComponent<State, ActionType> =
	createSubscriber(Store);

export const useIssueLayout = createHook(Store);

export const IssueLayoutContainer = createContainer(Store);
