import type { Action } from '@atlassian/react-sweet-state';
import { EMPTY_PROJECT_STATE } from '../constants.tsx';
import type { State, Props } from '../types.tsx';

export const unLoadProject =
	(): Action<State, Props> =>
	async ({ getState, setState }) => {
		setState({
			meta: {
				...getState().meta,
				loadingProps: undefined,
				project: {
					loading: false,
					error: undefined,
				},
			},
			project: EMPTY_PROJECT_STATE,
		});
	};
