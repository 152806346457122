import React from 'react';
import Button from '@atlaskit/button/new';
import { useFlagsService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';

import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useMajorIncidentField } from '@atlassian/jira-major-incident/src/services/use-major-incident/index.tsx';
import type { UIAnalyticsEvent } from '@atlassian/jira-product-analytics-bridge';
import type { MajorIncidentSuggestedAction } from '@atlassian/jira-servicedesk-ai-context-ops-panel-resources/src/common/types.tsx';
import { commonMessages } from '../../messages.tsx';
import type { ActionButtonProps } from '../../types.tsx';
import { messages } from './messages.tsx';

type Props = ActionButtonProps<MajorIncidentSuggestedAction['context']>;

export const MajorIncidentButton = ({
	context,
	onSuccess: onSuccessProp,
	onFailure: onFailureProp,
	onClick: onClickProp,
}: Props) => {
	const { formatMessage } = useIntl();
	const issueKey = useIssueKey();
	const { showFlag } = useFlagsService();
	const { suggestion } = context;

	const onSuccess = () => {
		showFlag({
			type: 'success',
			title: suggestion.id
				? formatMessage(messages.majorIncidentOnSuccess)
				: formatMessage(messages.majorIncidentOffSuccess),
		});
		onSuccessProp?.();
	};

	const onFailure = (error: Error) => {
		showFlag({
			type: 'error',
			title: formatMessage(commonMessages.error),
		});
		onFailureProp?.(error);
	};

	const [{ loading }, { saveValue }] = useMajorIncidentField({
		issueKey,
		onSuccess,
		onFailure,
	});

	const onClickHandler = (_: unknown, event: UIAnalyticsEvent) => {
		saveValue(suggestion.id, null, event);
		onClickProp?.();
	};

	return (
		<Button isLoading={loading} onClick={onClickHandler}>
			{formatMessage(commonMessages.accept)}
		</Button>
	);
};
