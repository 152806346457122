import React, { memo } from 'react';
import Tooltip from '@atlaskit/tooltip';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import IssueStatusField from '@atlassian/jira-issue-field-status/src/ui/main.tsx';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import { PaddedFlexContainer } from '../../common/ui/styled.tsx';
import { preventDefault } from '../../common/utils.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

export const Status = memo<Props>(
	({
		issueKey,
		statusName,
		statusId,
		statusCategoryId,
		isRemote,
		isInteractive,
		hideTooltipOnMouseDown,
		projectType,
		onEditStatus,
	}: Props) => {
		const intl = useIntl();
		const tooltipContent =
			isRemote === true && isInteractive !== true
				? intl.formatMessage(messages.remoteIssueUneditableStatusTooltip)
				: null;

		return (
			<PaddedFlexContainer
				onClick={preventDefault}
				data-testid="issue-line-card.ui.status.status-field-container"
			>
				<Tooltip
					content={tooltipContent}
					hideTooltipOnMouseDown={hideTooltipOnMouseDown}
					position="bottom"
				>
					<ContextualAnalyticsData sourceName="childIssuesPanel">
						<IssueStatusField
							isEditable={isInteractive}
							appearance="lozenge"
							issueKey={issueKey}
							projectType={projectType}
							initialValue={{
								id: statusId,
								name: statusName,
								statusCategory: { id: statusCategoryId },
							}}
							onSuccess={onEditStatus}
						/>
					</ContextualAnalyticsData>
				</Tooltip>
			</PaddedFlexContainer>
		);
	},
);
