import React, { useMemo, useState, useCallback } from 'react';
import { ConnectionHandler, useFragment, graphql, useMutation } from 'react-relay';
import type { RecordSourceSelectorProxy } from 'relay-runtime';
import Button from '@atlaskit/button/new';
import { ButtonGroup } from '@atlaskit/button';
import { ExitingPersistence } from '@atlaskit/motion';
import { Stack, xcss, Box, Inline } from '@atlaskit/primitives';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useFlagService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueId } from '@atlassian/jira-issue-context-service/src/index.tsx';
import type { IssueViewRelayFragment } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/main.tsx';
import { OUTWARD_LINK_DIRECTION } from '@atlassian/jira-issue-shared-types/src/common/types/linked-issue-type.tsx';
import SquareAddIconButton from '@atlassian/jira-issue-view-common-views/src/button/square-add-icon-button.tsx';
import {
	SectionHeading,
	SectionHeadingTitle,
} from '@atlassian/jira-issue-view-common/src/component/section-heading/section-heading-view.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import {
	hideLinkIdeaInput,
	showLinkIdeaInput,
} from '@atlassian/jira-issue-view-store/src/actions/link-idea-actions.tsx';
import { IssueSelect } from '@atlassian/jira-polaris-common/src/ui/common/issue-select/main.tsx';
import type { IssueOption } from '@atlassian/jira-polaris-common/src/ui/common/issue-select/types.tsx';
import { IdeaViewSkeleton } from '@atlassian/jira-polaris-component-idea-view-bento-sidebar/src/ui/idea-view/skeleton/index.tsx';
import { RightSidebar } from '@atlassian/jira-polaris-component-idea-view-bento-sidebar/src/ui/right-sidebar/index.tsx';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	useAnalyticsEvents,
	fireTrackAnalytics,
	SCREEN,
} from '@atlassian/jira-product-analytics-bridge';
import type {
	ui_issueViewJpdIdeas_JPDIdeasPanel$key,
	ui_issueViewJpdIdeas_JPDIdeasPanel$data,
} from '@atlassian/jira-relay/src/__generated__/ui_issueViewJpdIdeas_JPDIdeasPanel.graphql';
import type {
	uiLinkIdeaMutation,
	uiLinkIdeaMutation$data,
} from '@atlassian/jira-relay/src/__generated__/uiLinkIdeaMutation.graphql';
import type { uiUnlinkIdeaMutation } from '@atlassian/jira-relay/src/__generated__/uiUnlinkIdeaMutation.graphql';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import RelayDataID from '@atlassian/relay-data-id';
import {
	useActiveEntryPointSubject,
	useActiveEntryPointSubjectActions,
} from '../controllers/entrypoint-reference-store.tsx';
import { useIdeaPanelChangeBoarding } from '../controllers/idea-panel-change-boarding.tsx';
import { useSelectedIdeaKey } from '../controllers/url-selected-idea.tsx';

import { useCrossflowIdeaCardEligible } from '../controllers/use-crossflow-idea-card-eligible.tsx';
import { ChangeBordingLozenge } from './change-bording-lozenge/index.tsx';
import { CrossflowIdeaCard } from './crossflow-idea-card/index.tsx';
import { IdeaCard } from './idea-card/index.tsx';
import { messages } from './messages.tsx';

const PRODUCT_DISCOVERY = 'PRODUCT_DISCOVERY';

type Props = {
	issueViewRelayFragment: IssueViewRelayFragment;
	jpdDeliveryIssueLinkTypeId?: string | null;
	shouldShowAddIssueLinks?: boolean;
	onHideLinkIdeaInput: () => void;
	onShowLinkIdeaInput: () => void;
};

export const JPDIdeasPanelComponent = ({
	issueViewRelayFragment,
	jpdDeliveryIssueLinkTypeId,
	shouldShowAddIssueLinks = false,
	onHideLinkIdeaInput,
	onShowLinkIdeaInput,
}: Props) => {
	const [viewedChangeBoarding] = useIdeaPanelChangeBoarding();
	const urlSelectedIdeaKey = useSelectedIdeaKey();
	const { formatMessage } = useIntl();
	const cloudId = useCloudId();
	const issueId = useIssueId();
	const { showFlag } = useFlagService();
	const [selectedIssueLinks, setSelectedIssueLinks] = useState<IssueOption[]>([]);
	const [linkingInProgress, setLinkingInProgress] = useState(false);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const data: ui_issueViewJpdIdeas_JPDIdeasPanel$data =
		useFragment<ui_issueViewJpdIdeas_JPDIdeasPanel$key>(
			graphql`
				fragment ui_issueViewJpdIdeas_JPDIdeasPanel on JiraIssueLinkConnection {
					__id
					edges {
						node {
							... on JiraIssueLink {
								id
								issueLinkId
								direction
								type {
									linkTypeId
								}
								issue {
									id
									issueId
									key
									fieldsById(
										ids: ["assignee", "issuetype", "priority", "status", "summary", "project"]
									) {
										edges {
											node {
												__typename
												... on JiraSingleLineTextField {
													fieldId
													name
													text
												}
												... on JiraProjectField {
													fieldId
													project {
														projectType
													}
												}
												... on JiraIssueTypeField {
													fieldId
													name
													issueType {
														name
														avatar {
															medium
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			`,
			// @ts-expect-error - No overload matches this call.
			issueViewRelayFragment?.issueLinks,
		);

	const [unlinkIdeaMutationCommit] = useMutation<uiUnlinkIdeaMutation>(graphql`
		mutation uiUnlinkIdeaMutation($cloudId: ID!, $issueLinkId: ID!) {
			jira {
				deleteIssueLink(cloudId: $cloudId, issueLinkId: $issueLinkId) {
					deletedIds
					success
					errors {
						message
					}
				}
			}
		}
	`);

	const connectionId = data?.__id;

	const [createIssueLinks] = useMutation<uiLinkIdeaMutation>(graphql`
		mutation uiLinkIdeaMutation($cloudId: ID!, $input: JiraBulkCreateIssueLinksInput!) {
			jira {
				createIssueLinks(cloudId: $cloudId, input: $input) @optIn(to: "JiraCreateIssueLinks") {
					success
					issueLinkEdges {
						node {
							id
							issueLinkId
							direction
							type {
								linkTypeId
							}
							issue {
								id
								key
								fieldsById(
									ids: ["assignee", "issuetype", "priority", "status", "summary", "project"]
								) {
									edges {
										node {
											__typename
											... on JiraSingleLineTextField {
												fieldId
												name
												text
											}
											... on JiraProjectField {
												fieldId
												project {
													projectType
												}
											}
											... on JiraIssueTypeField {
												fieldId
												name
												issueType {
													name
													avatar {
														medium
													}
												}
											}
										}
									}
								}
							}
						}
					}
					errors {
						message
					}
				}
			}
		}
	`);

	const onUnlinkModalConfirmed = useCallback(
		(issueLinkId: string) => {
			unlinkIdeaMutationCommit({
				variables: {
					cloudId,
					issueLinkId,
				},
				updater: (store, payload) => {
					const { success, deletedIds } = payload?.jira?.deleteIssueLink ?? {};
					if (success) {
						if (connectionId) {
							const connection = store.get(connectionId);
							if (connection) {
								const nodeIds = deletedIds?.map((deletedId) =>
									RelayDataID({ id: deletedId }, 'JiraIssueLink'),
								);

								nodeIds?.forEach((nodeId) => {
									if (nodeId) {
										ConnectionHandler.deleteNode(connection, nodeId);
									}
								});

								fireTrackAnalytics(createAnalyticsEvent({}), 'idea unlinked');
							}
						}
					}
				},
				onError: () => {
					showFlag({
						type: 'error',
						title: formatMessage(messages.unlinkIdeaErrorFlagTitle),
						description: formatMessage(messages.unlinkIdeaErrorFlagDescription),
						isAutoDismiss: true,
					});
				},
			});
		},
		[
			cloudId,
			connectionId,
			createAnalyticsEvent,
			unlinkIdeaMutationCommit,
			formatMessage,
			showFlag,
		],
	);

	const ideaLinks = useMemo(
		() =>
			(data?.edges || []).filter((link) =>
				link?.node?.issue?.fieldsById?.edges?.some(
					(edge) =>
						edge?.node?.__typename === 'JiraProjectField' &&
						edge?.node?.project?.projectType === PRODUCT_DISCOVERY,
				),
			),
		[data?.edges],
	);

	const ideaLinksProps = useMemo(
		() =>
			ideaLinks
				.map((link) => {
					const issue = link?.node?.issue;
					const issueLinkId = link?.node?.issueLinkId;
					const isDeliveryIssueLinkType =
						jpdDeliveryIssueLinkTypeId &&
						link?.node?.type?.linkTypeId === jpdDeliveryIssueLinkTypeId &&
						link?.node?.direction === OUTWARD_LINK_DIRECTION;

					if (!issue?.key || issueLinkId === null) {
						return null;
					}

					if (!isDeliveryIssueLinkType && fg('jpd_idea_panel_in_issue_view')) {
						return null;
					}

					const summaryEdge = issue?.fieldsById?.edges?.find(
						(edge) =>
							edge?.node?.__typename === 'JiraSingleLineTextField' &&
							edge?.node?.fieldId === 'summary',
					);

					const issueTypeEdge = issue?.fieldsById?.edges?.find(
						(edge) => edge?.node?.__typename === 'JiraIssueTypeField',
					);

					const summary =
						(summaryEdge?.node && 'text' in summaryEdge.node && summaryEdge.node.text) || '';
					const issueType =
						issueTypeEdge?.node && 'issueType' in issueTypeEdge.node
							? issueTypeEdge.node.issueType
							: undefined;

					return {
						issueId: issue.issueId,
						issueKey: issue.key,
						summary,
						issueTypeIconUrl: issueType?.avatar?.medium ?? undefined,
						issueTypeName: issueType?.name,
						issueLinkId,
					};
				})
				.filter(Boolean),
		[ideaLinks, jpdDeliveryIssueLinkTypeId],
	);

	const onCreateIssueLinksUpdater = useCallback(
		(store: RecordSourceSelectorProxy, payload: uiLinkIdeaMutation$data | undefined | null) => {
			const { success, issueLinkEdges } = payload?.jira?.createIssueLinks ?? {};
			if (!success || !issueLinkEdges) {
				return;
			}

			// Iterate over each new issue link edge
			issueLinkEdges.forEach((edge) => {
				if (!edge || !edge.node) {
					return;
				}

				const { node } = edge;
				const { id, direction, issue, issueLinkId, type } = node;

				if (!issueLinkId) {
					return;
				}
				const existingEdge = ideaLinksProps.find((idea) => idea.issueLinkId === issueLinkId);

				if (existingEdge) {
					// Edge already exists, no need to add it again
					return;
				}
				// Create or get the JiraIssueLink node
				const issueLinkNodeId = RelayDataID({ id }, 'JiraIssueLink');
				if (!issueLinkNodeId) return;
				let issueLinkNode = store.get(issueLinkNodeId);
				if (!issueLinkNode) {
					issueLinkNode = store.create(issueLinkNodeId, 'JiraIssueLink');
				}

				// Set scalar fields
				issueLinkNode.setValue(direction, 'direction');
				issueLinkNode.setValue(issueLinkId, 'issueLinkId');
				issueLinkNode.setValue(id, 'id');

				// Handle 'type' linked record
				if (type) {
					const typeId = `client:type:${type.linkTypeId}`;
					let typeRecord = store.get(typeId);
					if (!typeRecord) {
						typeRecord = store.create(typeId, 'JiraIssueLinkType');
					}
					typeRecord.setValue(type.linkTypeId, 'linkTypeId');
					issueLinkNode.setLinkedRecord(typeRecord, 'type');
				}

				// Handle 'issue' linked record
				if (issue) {
					const issueRecordId = RelayDataID({ id: issue.id }, 'JiraIssue');
					if (!issueRecordId) return;
					let issueRecord = store.get(issueRecordId);
					if (!issueRecord) {
						issueRecord = store.create(issueRecordId, 'JiraIssue');
					}
					issueRecord.setValue(issue.key, 'key');
					issueRecord.setValue(issue.id, 'id');

					issueLinkNode.setLinkedRecord(issueRecord, 'issue');
				}

				// Retrieve the connection using the connectionId
				const connection = store.get(connectionId);
				if (!connection) {
					return;
				}

				// Create a new JiraIssueLinkEdge for the main issueLinks connection
				const edgeId = `client:newEdge:${issueLinkId}`;
				let newEdge = store.get(edgeId);
				if (!newEdge) {
					newEdge = store.create(edgeId, 'JiraIssueLinkEdge');
				}
				newEdge.setLinkedRecord(issueLinkNode, 'node');

				// Insert the new edge into the main issueLinks connection
				ConnectionHandler.insertEdgeAfter(connection, newEdge);
			});

			fireTrackAnalytics(createAnalyticsEvent({}), 'idea linked');
		},
		[connectionId, ideaLinksProps, createAnalyticsEvent],
	);

	const onCreateIssueLinks = useCallback(() => {
		if (!issueId || !jpdDeliveryIssueLinkTypeId) {
			return;
		}
		const targetIssueIds = selectedIssueLinks.map((issueLink) => String(issueLink.item?.id));
		setLinkingInProgress(true);

		createIssueLinks({
			variables: {
				input: {
					sourceIssueId: issueId,
					issueLinkTypeId: jpdDeliveryIssueLinkTypeId,
					targetIssueIds,
				},
				cloudId,
			},
			onCompleted: (payload) => {
				if (payload?.jira?.createIssueLinks?.success) {
					onHideLinkIdeaInput();
					setSelectedIssueLinks([]);
				}
				setLinkingInProgress(false);
			},
			updater: onCreateIssueLinksUpdater,
			onError: () => {
				setLinkingInProgress(false);
				showFlag({
					type: 'error',
					title: formatMessage(messages.linkIdeaErrorFlagTitle),
					description: formatMessage(messages.linkIdeaErrorFlagDescription),
					isAutoDismiss: true,
				});
			},
		});
	}, [
		issueId,
		cloudId,
		selectedIssueLinks,
		jpdDeliveryIssueLinkTypeId,
		createIssueLinks,
		onCreateIssueLinksUpdater,
		setSelectedIssueLinks,
		onHideLinkIdeaInput,
		formatMessage,
		showFlag,
	]);

	const renderAddIssueLink = () => {
		if (shouldShowAddIssueLinks) {
			return (
				<UFOSegment name="issue-add-jpd-ideas">
					<Box xcss={addContainerStyles}>
						<IssueSelect
							isDisabled={linkingInProgress}
							autoFocus
							isIdeasSelect
							isAttachedToBody
							hideArchived
							projectId={undefined}
							placeholder={formatMessage(messages.ideasPickerPlaceholder)}
							// @ts-expect-error - TS2345: Argument of type IssueOption | undefined is not assignable to parameter of type SetStateAction<IssueOption[]
							onIssueSelected={(option) => setSelectedIssueLinks(option)}
							excludedProjectTypes={[]}
						/>
						<Box xcss={buttonsContainerStyles}>
							<ButtonGroup>
								<Button
									appearance="primary"
									onClick={onCreateIssueLinks}
									isDisabled={!selectedIssueLinks.length}
									isLoading={linkingInProgress}
								>
									{formatMessage(messages.linkButton)}
								</Button>
								<Button
									appearance="subtle"
									onClick={onHideLinkIdeaInput}
									isDisabled={linkingInProgress}
								>
									{formatMessage(messages.cancelButton)}
								</Button>
							</ButtonGroup>
						</Box>
					</Box>
				</UFOSegment>
			);
		}
	};

	const { setEntryPointReferenceSubject } = useActiveEntryPointSubjectActions();
	const [entryPointReferenceSubject] = useActiveEntryPointSubject();

	const handleOnSidebarClose = useCallback(() => {
		setEntryPointReferenceSubject(null);
	}, [setEntryPointReferenceSubject]);

	// GALILEO-404 START
	const [shouldShowIdeaDiscoveryCard, parentIssue, parentName] = useCrossflowIdeaCardEligible();

	const renderIdeaDiscoveryCard = () => {
		if (shouldShowIdeaDiscoveryCard) {
			return (
				parentIssue && (
					<UFOSegment name="issue-jpd-idea-discovery-card">
						<JSErrorBoundary
							id="CrossflowIdeaCard"
							packageName="issue-view-ideas-panel"
							teamName="galileo"
							fallback="unmount"
						>
							<ContextualAnalyticsData
								sourceName="crossflowIdeaCard"
								sourceType={SCREEN}
								attributes={{
									issueKey: parentIssue,
								}}
							>
								<Stack space="space.100">
									<SectionHeading>
										<Inline space="space.100" alignBlock="center">
											<SectionHeadingTitle>
												{formatMessage(messages.ideasTitle)}
											</SectionHeadingTitle>
										</Inline>
									</SectionHeading>
									<Stack xcss={isVisualRefreshEnabled() ? containerStyles : containerStylesOld}>
										<CrossflowIdeaCard parentIssue={parentIssue} parentName={parentName} />
									</Stack>
									<FireScreenAnalytics />
								</Stack>
							</ContextualAnalyticsData>
						</JSErrorBoundary>
					</UFOSegment>
				)
			);
		}
	};
	// GALILEO-404 END

	const renderIdeaCards = () => {
		if (ideaLinksProps.length) {
			return (
				<UFOSegment name="issue-jpd-ideas">
					<Stack xcss={isVisualRefreshEnabled() ? containerStyles : containerStylesOld}>
						{ideaLinksProps.map((linkProps) => (
							<IdeaCard
								key={linkProps.issueKey}
								{...linkProps}
								onUnlinkModalConfirmed={onUnlinkModalConfirmed}
								isUrlSelected={linkProps.issueKey === urlSelectedIdeaKey}
							/>
						))}
					</Stack>
					<ExitingPersistence appear>
						{entryPointReferenceSubject && (
							<RightSidebar onClose={handleOnSidebarClose}>
								<JiraEntryPointContainer
									id="async-jpd-idea-sidebar-view"
									packageName="jira-polaris-component-idea-view-bento-sidebar"
									entryPointReferenceSubject={entryPointReferenceSubject}
									runtimeProps={{
										onClose: handleOnSidebarClose,
									}}
									fallback={<IdeaViewSkeleton />}
									errorFallback="unmount"
								/>
							</RightSidebar>
						)}
					</ExitingPersistence>
				</UFOSegment>
			);
		}
	};

	if (ideaLinksProps.length || shouldShowAddIssueLinks) {
		return (
			<ContextualAnalyticsData
				sourceName="linkedIdeasPanel"
				sourceType={SCREEN}
				attributes={{
					linkedIdeasCount: ideaLinks.length,
				}}
			>
				<Stack space="space.100">
					<SectionHeading>
						{!viewedChangeBoarding && fg('polaris_-_jpd_in_jsw_change_boarding_killswitch') ? (
							<Inline space="space.100" alignBlock="center">
								<SectionHeadingTitle>{formatMessage(messages.ideasTitle)}</SectionHeadingTitle>
								<ChangeBordingLozenge />
							</Inline>
						) : (
							<SectionHeadingTitle>{formatMessage(messages.ideasTitle)}</SectionHeadingTitle>
						)}

						<SquareAddIconButton
							label={formatMessage(messages.plusButtonLabel)}
							onClick={onShowLinkIdeaInput}
						/>
					</SectionHeading>
					{renderIdeaCards()}
					{renderAddIssueLink()}
					<FireScreenAnalytics />
				</Stack>
			</ContextualAnalyticsData>
		);
	}

	return renderIdeaDiscoveryCard();
};

const JPDIdeaPanelWrapped = (props: Props) => {
	return (
		<JSErrorBoundary
			id="issueViewJpdIdeas"
			fallback="unmount"
			teamName="JPD - Juno"
			packageName="@atlassian/jira-issue-view-jpd-ideas"
		>
			<JPDIdeasPanelComponent {...props} />
		</JSErrorBoundary>
	);
};

export const JPDIdeasPanel = connect(
	() => ({}),
	(dispatch) => ({
		onHideLinkIdeaInput: () => {
			dispatch(hideLinkIdeaInput());
		},
		onShowLinkIdeaInput: () => {
			dispatch(showLinkIdeaInput());
		},
	}),
)(JPDIdeaPanelWrapped);

const containerStyles = xcss({
	paddingRight: 'space.025',
});

const containerStylesOld = xcss({
	boxShadow: 'elevation.shadow.raised',
	borderRadius: '3px',
});

const addContainerStyles = xcss({
	display: 'flex',
	flexDirection: 'column',
	paddingRight: 'space.025',
	marginTop: 'space.100',
	marginBottom: 'space.100',
});

const buttonsContainerStyles = xcss({
	alignSelf: 'flex-end',
	marginTop: 'space.100',
});
