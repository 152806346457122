/**
 * The URL standard regex for spec.: RFC 3986
 * https://tools.ietf.org/html/rfc3986#page-51
 */
const URLStandardRegex = new RegExp('^(([^:/?#]+):)?(//([^/?#]*))?([^?#]*)(\\?([^#]*))?(#(.*))?');
/**
 * Full Domain name limits as per RFC is 256 characters
 * TLD limit as per RFC 1035 is 63 characters. Although, TLDs can contain numbers, since none of the existing popular TLDs are numbers, we are not allowing numbers in TLD.
 * Any characters allowed in protocol and query string. Domain name needs to be alphanumeric with hyphens allowed.
 */
const stricterURLRegex = new RegExp(
	'^(([^:/?#]+):)?(//([^/?#]*))?[-a-zA-Z0-9@:.]{2,256}.[a-z]{2,63}\\b([-a-zA-Z0-9@:%_+.~#?&//=]*)',
);
const protocolRegex = new RegExp('^(http|https|ftp)://.*|^//[^/]*?');
/**
 * Validate if given string / any is url
 * strict param will check if it is a valid url as well as URI.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isValidUrl(string?: any, strict: Boolean = false): boolean {
	// Have to be string
	if (!string || typeof string !== 'string') {
		return false;
	}
	// Exit early if there is a space, no need to regex that
	if (string.indexOf(' ') >= 0 || string.length === 0) {
		return false;
	}
	// Localhost is bit different, doesn't need a dot but others likely should
	if (string.indexOf('localhost') === -1 && string.indexOf('.') === -1) {
		return false;
	}

	if (strict === true) return stricterURLRegex.test(string) || protocolRegex.test(string);

	return URLStandardRegex.test(string);
}

export default isValidUrl;
