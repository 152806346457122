import { Component, type ReactNode } from 'react';
import type { ProjectType } from '@atlassian/jira-common-constants/src/project-types.tsx';

import { getTenantContext_DEPRECATED_DO_NOT_USE } from '@atlassian/jira-common-util-get-tenant-context/src/index.tsx';
import { sendExperienceAnalytics } from '@atlassian/jira-issue-view-analytics/src/controllers/send-experience-analytics/index.tsx';
import { getApplicationForProject } from '@atlassian/jira-shared-types/src/application.tsx';
import { getEdition } from '@atlassian/jira-shared-types/src/edition.tsx';

type Props = {
	isReady: boolean;
	experience: string;
	analyticsSource: string;
	projectType: ProjectType | null;
	children: ReactNode;
};

type State = {
	noErrorsAfterTimeout: boolean;
	caughtError: boolean;
};

export default class AddAttachmentFrontendSlaTracker extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			noErrorsAfterTimeout: false,
			caughtError: false,
		};
	}

	componentDidMount() {
		// This trigger is specifically needed for the case where isReady is true on first mount.
		// Otherwise, all SLA event logic is handled by shouldComponentUpdate.
		if (this.props.isReady) {
			setTimeout(() => {
				this.setState({
					noErrorsAfterTimeout: true,
				});
			}, 1000);
		}

		// This will trigger on every remount to ensure that this comoponent properly rerenders once
		this.didMount = true;
	}

	/*
        This lifecycle function controls the sending of Add Attachment Frontend SLA events based
        on whether its children (some of which are media deps) render successfully. The complexity
        here is because:
        - Errors are caught asynchronously
        - We only want to send a single SLA event per "conceptual" render
        The only time we want to re-render the component is if we do catch an error, in which case
        we swap out all the children for a null so as not to break the rest of the Issue View.
     */
	shouldComponentUpdate(nextProps: Props, nextState: State) {
		if (nextState.caughtError) {
			return true;
		}

		const justMadeTimeoutWithNoErrors =
			!this.state.noErrorsAfterTimeout && nextState.noErrorsAfterTimeout;

		if (justMadeTimeoutWithNoErrors) {
			const { experience, analyticsSource } = this.props;
			const { application, edition } = this.getApplicationAndEdition();

			sendExperienceAnalytics({
				experience,
				wasExperienceSuccesful: true,
				analyticsSource,
				application,
				edition,
			});

			return false;
		}

		const hasJustBecomeReady = (!this.props.isReady && nextProps.isReady) || this.didMount;

		if (hasJustBecomeReady) {
			setTimeout(() => {
				this.setState({
					noErrorsAfterTimeout: true,
				});
			}, 1000);

			this.didMount = false;

			return true;
		}

		return false;
	}

	componentDidCatch(error: Error) {
		const { experience, analyticsSource } = this.props;
		const { application, edition } = this.getApplicationAndEdition();

		sendExperienceAnalytics({
			experience,
			wasExperienceSuccesful: false,
			analyticsSource,
			application,
			edition,
			additionalAttributes: {
				errorMessage: error.message || 'unknown',
			},
		});

		this.setState({ caughtError: true });
	}

	didMount = false;

	getApplicationAndEdition() {
		const { projectType } = this.props;

		if (projectType == null) {
			return {
				application: null,
				edition: null,
			};
		}

		const application = getApplicationForProject(projectType);

		const tenantContext =
			getTenantContext_DEPRECATED_DO_NOT_USE && getTenantContext_DEPRECATED_DO_NOT_USE();
		const appEditions = tenantContext && tenantContext.appEditions;

		const edition = appEditions ? getEdition(application, appEditions) : null;

		return {
			application,
			edition,
		};
	}

	render() {
		const { caughtError } = this.state;

		if (caughtError) {
			return null;
		}

		return this.props.children;
	}
}
