import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	successFlagMessage: {
		id: 'issue-field-status.status-view.command-palette.use-save-status-field.success-flag-message',
		defaultMessage: 'Status updated',
		description: 'Toast flag that will be displayed when user changes the status of the issue',
	},
	errorFlagTitle: {
		id: 'issue-field-status.status-view.command-palette.use-save-status-field.error-flag-title',
		defaultMessage: "We're unable to edit status",
		description: 'Title of the toast flag if something goes wrong tying to save the new status',
	},
	errorFlagDescription: {
		id: 'issue-field-status.status-view.command-palette.use-save-status-field.error-flag-description',
		defaultMessage: 'Wait a few moments, then try again.',
		description:
			'Description of the toast flag if something goes wrong tying to save the new status',
	},
});
