import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	editedBy: {
		id: 'issue-comment-base.comment.edited-by',
		defaultMessage: '{timestamp} by {author}',
		description: 'Text for showing when an activity item was edited by a user',
	},
	anonymous: {
		id: 'issue-comment-base.comment.anonymous',
		defaultMessage: 'Anonymous',
		description: 'Text to show for an anonymous user',
	},
	edit: {
		id: 'issue-comment-base.comment.edit',
		defaultMessage: 'Edit',
		description: '',
	},
	hide: {
		id: 'issue-comment-base.comment.hide',
		defaultMessage: 'Hide',
		description: 'Text to show hide comment',
	},
	unhide: {
		id: 'issue-comment-base.comment.unhide',
		// eslint-disable-next-line @atlassian/tangerine/i18n/messages-are-spelled-correctly
		defaultMessage: 'Unhide',
		description: 'Text to show unhide comment',
	},
	delete: {
		id: 'issue-comment-base.comment.delete',
		defaultMessage: 'Delete',
		description: '',
	},
	commentDeleted: {
		id: 'issue-comment-base.comment.comment-deleted',
		defaultMessage: 'Comment deleted',
		description:
			'This text is shown in place of an actual comment when that comment has been deleted',
	},
	edited: {
		id: 'issue-comment-base.comment.edited',
		defaultMessage: 'Edited',
		description: '',
	},
	retrySave: {
		id: 'issue-comment-base.comment.retry-save',
		defaultMessage: 'Retry',
		description: '',
	},
	cancelSave: {
		id: 'issue-comment-base.comment.cancel-save',
		defaultMessage: 'Cancel',
		description: '',
	},
	saving: {
		id: 'issue-comment-base.comment.saving',
		defaultMessage: 'Saving...',
		description: '',
	},
	internalNote: {
		id: 'issue-comment-base.comment.internal-note',
		defaultMessage: 'Internal note',
		description: 'This text will be displayed next to lock icon if that comment is internal',
	},
	copy: {
		id: 'issue-comment-base.comment.copy',
		defaultMessage: 'Copy link to comment',
		description: 'Tooltip shown on hover',
	},
	lozenge: {
		id: 'issue-comment-base.comment.lozenge',
		defaultMessage: 'Comments',
		description: 'This text will be displayed inside the comment lozenge',
	},
	addedCommentDescription: {
		id: 'issue-comment-base.comment.added-comment-description',
		defaultMessage: 'added a <span>Comment</span>',
		description: 'Should read as part of a sentence: {author name} added a Comment {date and time}',
	},
	addedCommentDescriptionLowercase: {
		id: 'issue-comment-base.comment.added-comment-description-lowercase',
		defaultMessage: 'added a <span>comment</span>',
		description: 'Should read as part of a sentence: {author name} added a comment {date and time}',
	},
	notRequestParticipant: {
		id: 'issue-comment-base.comment.not-request-participant',
		defaultMessage: '(not a request participant)',
		description:
			'This text will get concatenated with author name if user is not a request participant',
	},
	comment: {
		id: 'issue-comment-base.comment.comment',
		defaultMessage: 'Comment',
		description: 'Editor assisitive label for comment section',
	},
	reply: {
		id: 'issue-comment-base.comment.reply',
		defaultMessage: 'Reply',
		description: 'The text for CTA on a comment to reply, on the Jira issue view',
	},
});
