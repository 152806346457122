import React from 'react';
import { graphql, useFragment } from 'react-relay';
import type { main_issueFieldVoters_IssueVotersRelay$key } from '@atlassian/jira-relay/src/__generated__/main_issueFieldVoters_IssueVotersRelay.graphql';
import type { SubmitCallbacks, VoteValue } from './common/types.tsx';
import {
	useDisableResolvedIssueVoting,
	useDisableResolvedIssueVotingPure,
} from './services/disable-resolved-issue-voting-service/index.tsx';
import { UseVoteFieldRelayGenerator } from './services/vote-field-service-relay/index.tsx';
import IssueVotersBase from './ui/main.tsx';
import VoteToggleBase from './ui/vote-toggle/index.tsx';

type Props = {
	onFieldUpdated?: (value: VoteValue) => void;
};
export const VoteToggle = ({ onFieldUpdated }: Props) => {
	const disableVotingProps = useDisableResolvedIssueVoting();
	return <VoteToggleBase disableVotingProps={disableVotingProps} onFieldUpdated={onFieldUpdated} />;
};

/**
 * @deprecated use IssueVotersRelay instead
 */
export const IssueVoters = ({ onFieldUpdated }: Props) => {
	const disableVotingProps = useDisableResolvedIssueVoting();
	return (
		<IssueVotersBase disableVotingProps={disableVotingProps} onFieldUpdated={onFieldUpdated} />
	);
};

export const IssueVotersRelay = ({
	onFieldUpdated,
	onSubmit,
	onSubmitFailed,
	onSubmitSucceeded,
	votesField,
}: Props &
	SubmitCallbacks & {
		votesField: main_issueFieldVoters_IssueVotersRelay$key;
	}) => {
	const data = useFragment(
		graphql`
			fragment main_issueFieldVoters_IssueVotersRelay on JiraVotesField {
				...voteFieldServiceRelay_issueFieldVoters_UseVoteFieldRelayGenerator
				issue {
					statusField {
						status {
							statusCategory {
								statusCategoryId
							}
						}
					}
				}
			}
		`,
		votesField,
	);
	const disableVotingProps = useDisableResolvedIssueVotingPure(
		data?.issue?.statusField?.status?.statusCategory?.statusCategoryId || '',
	);

	const useVoteField = UseVoteFieldRelayGenerator({
		votesField: data,
		onSubmit,
		onSubmitFailed,
		onSubmitSucceeded,
	});
	return (
		<IssueVotersBase
			disableVotingProps={disableVotingProps}
			onFieldUpdated={onFieldUpdated}
			useVoteField={useVoteField}
		/>
	);
};
