import React, { type ReactNode, useCallback } from 'react';
import noop from 'lodash/noop';
import { Box, xcss } from '@atlaskit/primitives';
import Tabs, { Tab, TabList, TabPanel } from '@atlaskit/tabs';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import type { RenderCustomSecondaryToolbar } from '@atlassian/jira-issue-view-common/src/component/editor/index.tsx';
import { useLoomJSMAgentBanner } from '@atlassian/jira-loom-jsm-agent-response/src/controllers/use-loom-jsm-agent-banner/index.tsx';
import { useIsJCS } from '@atlassian/jira-servicedesk-customer-service-common/src/utils/use-is-jcs/index.tsx';
import CollapsedEditorView from './collapsed-editor-view/index.tsx';
import { CustomBanner } from './customer-service/custom-banner/index.tsx';
import { CustomSecondaryToolbar } from './customer-service/custom-secondary-toolbar/index.tsx';
import messages from './messages.tsx';
import type { CommentButtonActions } from './types.tsx';

export const INTERNAL_COMMENT_ID = 'internal' as const;
export const PUBLIC_COMMENT_ID = 'public' as const;

const TABS = {
	public: {
		id: PUBLIC_COMMENT_ID,
		label: <FormattedMessage {...messages.replyToCustomer} />,
		content: <div />,
	},
	internal: {
		id: INTERNAL_COMMENT_ID,
		label: <FormattedMessage {...messages.addInternalNote} />,
		content: <div />,
	},
} as const;

const JCS_TABS = {
	public: {
		id: PUBLIC_COMMENT_ID,
		label: <FormattedMessage {...messages.replyToCustomer} />,
		content: <div />,
	},
	internal: {
		id: INTERNAL_COMMENT_ID,
		label: <FormattedMessage {...messages.addNote} />,
		content: <div />,
	},
} as const;

export type OwnProps = {
	isExpanded: boolean;
	children: ({
		assistiveLabel,
		renderCustomSecondaryToolbar,
		isLoomJSMBannerEnabled,
	}: {
		assistiveLabel: string;
		renderCustomSecondaryToolbar?: RenderCustomSecondaryToolbar;
		isLoomJSMBannerEnabled?: boolean;
	}) => ReactNode;
	onExpanded?: () => void;
	commentButtonActions?: CommentButtonActions;
};

export type StateProps = {
	isInternal: boolean;
	isPublicCommentAllowed: boolean;
	hasPublicComments: boolean;
};

export type DispatchProps = {
	onCommentVisibilityChange: (isInternal: boolean) => void;
};

export type Props = OwnProps & StateProps & DispatchProps;

type ExpandViewProps = {
	isInternal: boolean;
	isPublicCommentAllowed: boolean;
	hasPublicComments: boolean;
	children: ({
		assistiveLabel,
		renderCustomSecondaryToolbar,
		isLoomJSMBannerEnabled,
	}: {
		assistiveLabel: string;
		renderCustomSecondaryToolbar?: RenderCustomSecondaryToolbar;
		isLoomJSMBannerEnabled?: boolean;
	}) => ReactNode;
	onTabSelect: (selectedIndex: { id: string }) => void;
};

const ExpandedView = ({
	isInternal,
	isPublicCommentAllowed,
	hasPublicComments,
	children,
	onTabSelect,
}: ExpandViewProps) => {
	const { formatMessage } = useIntl();
	const isJCS = useIsJCS();
	const currentTabs = isJCS && fg('jcs_issue_layout_eap') ? JCS_TABS : TABS;
	const selectedTabIndex =
		isPublicCommentAllowed && !isInternal ? currentTabs.public : currentTabs.internal;
	const tabs = isPublicCommentAllowed
		? [currentTabs.internal, currentTabs.public]
		: [currentTabs.internal];

	// Loom banner in JSM Agent response Experiment
	// eslint-disable-next-line jira/ff/unsafe-no-exposure
	const [configLoomJSM] = UNSAFE_noExposureExp('loom_jsm_agent_response_touchpoint');
	const loomJSMCohort = configLoomJSM.get('cohort', 'not-enrolled');
	const isLoomJSMBannerEnabled =
		loomJSMCohort === 'variation' || loomJSMCohort === 'control'
			? // eslint-disable-next-line react-hooks/rules-of-hooks
				useLoomJSMAgentBanner({
					hasPublicComments,
					isEditingInternal: isInternal,
					isServiceDesk: true,
				})
			: undefined;

	const handleChange = useCallback(
		(index: number) => {
			onTabSelect({
				id: index === 0 ? INTERNAL_COMMENT_ID : PUBLIC_COMMENT_ID,
			});
		},
		[onTabSelect],
	);

	const getAssistiveLabel = () => {
		const internalMessage = isJCS ? messages.addNote : messages.addInternalNote;
		return formatMessage(isInternal ? internalMessage : messages.replyToCustomer);
	};

	const renderCustomSecondaryToolbar: RenderCustomSecondaryToolbar = (
		onSave,
		onCancel,
		secondaryToolbarComponents,
	) => (
		<CustomSecondaryToolbar
			isInternal={isInternal}
			onSave={onSave}
			onCancel={onCancel}
			secondaryToolbarComponents={secondaryToolbarComponents}
		/>
	);

	return (
		<>
			<Box xcss={tabsContainerStyles}>
				<Tabs
					selected={selectedTabIndex.id === INTERNAL_COMMENT_ID ? 0 : 1}
					onChange={handleChange}
					id="comment-editor-container-tabs"
					shouldUnmountTabPanelOnChange
				>
					<TabList>
						{tabs.map((tab, index) => (
							<Tab key={index}>{tab.label}</Tab>
						))}
					</TabList>
					{tabs.map((tab, index) => (
						<TabPanel key={index}>{tab.content}</TabPanel>
					))}
				</Tabs>
			</Box>
			{isJCS && fg('jcs_issue_layout_eap') && <CustomBanner isInternal={isInternal} />}
			{children({
				assistiveLabel: fg('jcs_issue_layout_eap')
					? getAssistiveLabel()
					: formatMessage(isInternal ? messages.addInternalNote : messages.replyToCustomer),
				renderCustomSecondaryToolbar:
					isJCS && fg('jcs_issue_layout_eap') ? renderCustomSecondaryToolbar : undefined,
				isLoomJSMBannerEnabled,
			})}
		</>
	);
};

const CollapsedView = ({
	onReplyToCustomer,
	onAddInternalNote,
	isPublicCommentAllowed,
	replyToCustomerButtonActions,
	addInternalNoteButtonActions,
}: {
	onReplyToCustomer: () => void;
	onAddInternalNote: () => void;
	isPublicCommentAllowed: boolean;
	replyToCustomerButtonActions?: CommentButtonActions;
	addInternalNoteButtonActions?: CommentButtonActions;
}) => (
	<CollapsedEditorView
		onReplyToCustomer={onReplyToCustomer}
		onAddInternalNote={onAddInternalNote}
		isPublicCommentAllowed={isPublicCommentAllowed}
		replyToCustomerButtonActions={replyToCustomerButtonActions}
		addInternalNoteButtonActions={addInternalNoteButtonActions}
	/>
);

export const ServiceDeskCommentEditorContainer = ({
	isExpanded = false,
	isInternal = false,
	isPublicCommentAllowed = false,
	hasPublicComments,
	onExpanded = noop,
	onCommentVisibilityChange = noop,
	children,
	commentButtonActions,
}: Props) => {
	const expandEditor = useCallback(() => {
		if (!isExpanded) {
			onExpanded && onExpanded();
		}
	}, [isExpanded, onExpanded]);

	const onReplyToCustomer = useCallback(() => {
		onCommentVisibilityChange(false);
		expandEditor();
	}, [expandEditor, onCommentVisibilityChange]);

	const onAddInternalNote = useCallback(() => {
		onCommentVisibilityChange(true);
		expandEditor();
	}, [expandEditor, onCommentVisibilityChange]);

	const onTabSelect = useCallback(
		(selectedTab: { id: string }) => {
			onCommentVisibilityChange(selectedTab.id === INTERNAL_COMMENT_ID);
		},
		[onCommentVisibilityChange],
	);

	return isExpanded ? (
		<ExpandedView
			isInternal={isInternal}
			isPublicCommentAllowed={isPublicCommentAllowed}
			hasPublicComments={hasPublicComments}
			onTabSelect={onTabSelect}
		>
			{children}
		</ExpandedView>
	) : (
		<CollapsedView
			onReplyToCustomer={onReplyToCustomer}
			onAddInternalNote={onAddInternalNote}
			isPublicCommentAllowed={isPublicCommentAllowed}
			replyToCustomerButtonActions={commentButtonActions}
			addInternalNoteButtonActions={commentButtonActions}
		/>
	);
};

export default ServiceDeskCommentEditorContainer;

const tabsContainerStyles = xcss({
	marginBottom: 'space.200',
});
