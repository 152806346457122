import type { IssueLinkType } from '@atlassian/jira-issue-shared-types/src/common/types/linked-issue-type.tsx';
import { useIssueLinkTypes } from '@atlassian/jira-settings-service/src/main.tsx';

export const useHasRelatesToIssueLinkType = () => {
	const issueLinkTypes = useIssueLinkTypes()[0];
	return issueLinkTypes.some((linkType) => linkType.label === 'relates to');
};

export const useRelatesToIssueLinkType = (): IssueLinkType => {
	const issueLinkTypes = useIssueLinkTypes()[0];
	const newRelatesToIssueLinkType = issueLinkTypes.filter(
		(linkType) => linkType.label === 'relates to',
	);
	return newRelatesToIssueLinkType?.[0];
};
