import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	addPeopleButton: {
		id: 'invite-prompt-on-link-insert.invite-prompt-flag.add-people-button',
		defaultMessage: '{inviteeCount, plural, one {Add # person} other {Add # people}}',
		description: 'CTA on a primary button to invite a group of people',
	},
	dismiss: {
		id: 'invite-prompt-on-link-insert.invite-prompt-flag.dismiss',
		defaultMessage: "Don't show again",
		description: 'Secondary button to dismiss the flag permanently',
	},
	titleGeneric: {
		id: 'invite-prompt-on-link-insert.invite-prompt-flag.title-generic',
		defaultMessage:
			'{inviteeCount, plural, one {{displayName} needs access to {productDisplayName}} other {People need access to {productDisplayName}}}',
		description: 'Title of a flag pop up where the user can invite one or more users.',
	},
	descriptionWithoutPageTitleGeneric: {
		id: 'invite-prompt-on-link-insert.invite-prompt-flag.description-without-page-title-generic',
		defaultMessage:
			'{inviteeCount, plural, one {Invite 1 person who needs access to collaborate with you.} other {Invite people who need access to collaborate with you.}}',
		description: 'Description of a flag pop up where the user can invite one or more users.',
	},
	descriptionWithPageTitleGeneric: {
		id: 'invite-prompt-on-link-insert.invite-prompt-flag.description-with-page-title-generic',
		defaultMessage:
			'{inviteeCount, plural, one {Invite 1 person who needs access to collaborate with you on <strong>{title}</strong>.} other {Invite people who need access to collaborate with you on <strong>{title}</strong>.}}',
		description: 'Description of a flag pop up where the user can invite one or more users.',
	},
	selectPeopleButton: {
		id: 'invite-prompt-on-link-insert.invite-prompt-flag.select-people-button',
		defaultMessage: 'Select people',
		description: 'CTA on a button to invite a group of people via core invites',
	},
});
