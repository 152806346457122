import type { FlagConfiguration } from '@atlassian/jira-flags';
import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	title: {
		id: 'issue-field-status.services.flags.validation-error.main.title',
		defaultMessage: 'Transition failed',
		description:
			"Occurs when a user tries to update an issue's status but a validation error occurs",
	},
});

const statusTransitionValidationErrorFlag = (description: string): FlagConfiguration => ({
	type: 'warning',
	title: messages.title,
	description: {
		id: 'issue-field.status.services.flags.validation-error.main.description',
		defaultMessage: description,
	},
	isAutoDismiss: true,
});

export default statusTransitionValidationErrorFlag;
