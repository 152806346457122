import memoizeOne from 'memoize-one';
import { formatTimeString } from '@atlassian/jira-issue-format-time/src/index.tsx';
import type { TimeTrackingConfig } from '@atlassian/jira-issue-shared-types/src/common/types/jira-settings-type.tsx';
import type { TimeTrackingState } from '@atlassian/jira-issue-shared-types/src/common/types/time-tracking-type.tsx';
import type { FormatMessage } from '@atlassian/jira-shared-types/src/general.tsx';
import messages from '../messages.tsx';

export const getEditButtonLabel = memoizeOne(
	(
		formatMessage: FormatMessage,
		label: string,
		config: TimeTrackingConfig,
		value: TimeTrackingState,
	) => {
		const { timeSpentSeconds, remainingEstimateSeconds } = value;

		const timeSpent = formatTimeString(config)(timeSpentSeconds || 0);
		const timeRemaining = formatTimeString(config)(remainingEstimateSeconds || 0);

		const isTimeSpentSeconds = !!timeSpentSeconds;
		const isRemainingEstimateSeconds = !!remainingEstimateSeconds;

		const TIME_LOGGED_AND_TIME_REMAINING = isTimeSpentSeconds && isRemainingEstimateSeconds;
		const TIME_REMAINING_AND_NO_TIME_LOGGED = isRemainingEstimateSeconds && !isTimeSpentSeconds;
		const TIME_LOGGED_AND_NO_TIME_REMAINING = isTimeSpentSeconds && !isRemainingEstimateSeconds;

		switch (true) {
			case TIME_LOGGED_AND_TIME_REMAINING:
				return formatMessage(messages.timeSpentRemainingLabel, {
					timeSpent,
					timeRemaining,
					fieldName: label,
				});
			case TIME_REMAINING_AND_NO_TIME_LOGGED:
				return formatMessage(messages.extendedTimeRemainingLabel, {
					timeRemaining,
					fieldName: label,
				});
			case TIME_LOGGED_AND_NO_TIME_REMAINING:
				return formatMessage(messages.extendedTimeSpentLabel, {
					timeSpent,
					fieldName: label,
				});
			default:
				return formatMessage(messages.noTimeLoggedLabel, {
					fieldName: label,
				});
		}
	},
);
