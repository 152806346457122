import type { FlagConfiguration } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	title: {
		id: 'forge-ui.common.flags.issue-panel.operation-action.generic-error.title',
		defaultMessage: 'We couldn’t complete the action',
		description: 'Flag title. Flag is shown when generic error occurred in issue panel.',
	},
	descriptionWithPanelName: {
		id: 'forge-ui.common.flags.issue-panel.operation-action.generic-error.description-with-panel-name',
		defaultMessage: 'We couldn’t perform the action on {issuePanelName}. Try again later',
		description:
			'Flag description. Flag is shown when generic error occurred in issue panel and we know panel name.',
	},
	genericDescription: {
		id: 'forge-ui.common.flags.issue-panel.operation-action.generic-error.generic-description',
		defaultMessage: 'We couldn’t perform the action on issue panel. Try again later.',
		description:
			"Flag description. Flag is shown when generic error occurred and we don't know panel name",
	},
});

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (issuePanelName?: string): FlagConfiguration => ({
	type: 'error',
	title: messages.title,
	description:
		issuePanelName === undefined
			? messages.genericDescription
			: [messages.descriptionWithPanelName, { issuePanelName }],
	isAutoDismiss: false,
});
