/**
 * @generated SignedSource<<03e7291518456d4895303607753b8334>>
 * @relayHash e0f1f2a58d6cdd8d7028571b28e491fa
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 6b26f86bac3fdf64d54f307c65f97fec71607084cfed9ade7abcc3ccf4c0c13b

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { uiAppConfigurationPromptQuery } from './uiAppConfigurationPromptQuery.graphql';

const node: PreloadableConcreteRequest<uiAppConfigurationPromptQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "6b26f86bac3fdf64d54f307c65f97fec71607084cfed9ade7abcc3ccf4c0c13b",
    "metadata": {},
    "name": "uiAppConfigurationPromptQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
