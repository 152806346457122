import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	addToPast: {
		id: 'issue-comment-base.comment.event-occurred-at-selector.add-to-past',
		defaultMessage: 'Add to past',
		description: 'Text for the button to send to the past',
	},
	selectDateAndTimeText: {
		id: 'issue-comment-base.comment.event-occurred-at-selector.select-date-and-time-text',
		defaultMessage: 'Select date & time',
		description: 'Text for the button saying save date and time',
	},
	spotlightHeading: {
		id: 'issue-comment-base.comment.event-occurred-at-selector.spotlight-heading',
		defaultMessage: 'Add internal notes to the past',
		description: 'Heading for the spotlight',
	},
	spotlightDescription: {
		id: 'issue-comment-base.comment.event-occurred-at-selector.spotlight-description',
		defaultMessage:
			'Assign any incident or post-incident review related activity to a specific date and time in the past using the internal notes feature. Click Add internal note > Select your desired date and time in the past > Save.',
		description: 'Description for the spotlight',
	},
});
