/**
 * @generated SignedSource<<9e6ca36f56b7b86c3b45133010b85c28>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type vulnerabilityDropdown_developmentSummaryVulnerability$data = ReadonlyArray<{
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"vulnerabilityDropdownItem_developmentSummaryVulnerability">;
  readonly " $fragmentType": "vulnerabilityDropdown_developmentSummaryVulnerability";
}>;
export type vulnerabilityDropdown_developmentSummaryVulnerability$key = ReadonlyArray<{
  readonly " $data"?: vulnerabilityDropdown_developmentSummaryVulnerability$data;
  readonly " $fragmentSpreads": FragmentRefs<"vulnerabilityDropdown_developmentSummaryVulnerability">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "vulnerabilityDropdown_developmentSummaryVulnerability",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "kind": "FragmentSpread",
      "name": "vulnerabilityDropdownItem_developmentSummaryVulnerability"
    }
  ],
  "type": "DevOpsSecurityVulnerabilityDetails"
};

(node as any).hash = "58ae81eb8d6169bd5303e4621deff12b";

export default node;
