import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	whatIsThis: {
		id: 'smart-related-issues-panel.similar-incidents.header.what-is-this',
		defaultMessage: 'What is this?',
		description: 'The dropdown item text for what similar incidents info',
	},
	feedback: {
		id: 'smart-related-issues-panel.similar-incidents.header.feedback',
		defaultMessage: 'Give feedback',
		description: 'The dropdown item text for giving feedback for panel',
	},
	new: {
		id: 'smart-related-issues-panel.similar-incidents.header.new',
		defaultMessage: 'New',
		description: 'Lozenge message',
	},
	title: {
		id: 'smart-related-issues-panel.similar-incidents.header.title',
		defaultMessage: 'Similar incidents',
		description: 'Title message for similar incidents panel',
	},
	description: {
		id: 'smart-related-issues-panel.similar-incidents.header.description',
		defaultMessage:
			'Incidents from the past that may help in investigating and resolving this incident',
		description: 'Some context message for similar incidents panel',
	},
	modalTitle: {
		id: 'smart-related-issues-panel.similar-incidents.header.modal-title',
		defaultMessage: 'What are similar incidents?',
		description: 'Modal text for similar incidents whats this modal',
	},
	modalClose: {
		id: 'smart-related-issues-panel.similar-incidents.header.modal-close',
		defaultMessage: 'Close',
		description: 'Close label for modal close button',
	},
	modalDescriptionFirst: {
		id: 'smart-related-issues-panel.similar-incidents.header.modal-description-first',
		defaultMessage:
			'We’ll show you incidents from the past that are similar to the one you’re viewing, so you can use information from them to investigate and resolve the incident faster.',
		description: 'First paragraph text',
	},
	mmodalDescriptionSecond: {
		id: 'smart-related-issues-panel.similar-incidents.header.mmodal-description-second',
		defaultMessage:
			'We use algorithms that look for underlying categories and semantic similarities in the summary and description fields of past incidents from the project, and finally show you the top 5 results.',
		description: 'Second paragraph text',
	},
});
