import type { Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import type { State } from '../types.tsx';

export const markDynamicFieldAsInitialized =
	(fieldId: Ari): Action<State> =>
	({ getState, setState }) => {
		setState({
			dynamicPropertiesInitialized: {
				...getState().dynamicPropertiesInitialized,
				[fieldId]: true,
			},
		});
	};
