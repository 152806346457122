import { useCallback } from 'react';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { getMobileSectionMsgCache, setMobileSectionMsgCache } from './utils.tsx';

export const useMobileSectionMessage = () => {
	const cloudId = useCloudId();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const checkAndDisplayMessage = useCallback(() => {
		if (cloudId) {
			const getMobileMessageKeyFromStorage = getMobileSectionMsgCache(cloudId);
			if (getMobileMessageKeyFromStorage === null && new Date().getHours() >= 16) {
				setMobileSectionMsgCache(cloudId, JSON.stringify(false));
				fireUIAnalytics(createAnalyticsEvent({}), 'mobileSectionMessage displayed');
			}
		}
	}, [cloudId, createAnalyticsEvent]);

	return { checkAndDisplayMessage };
};
