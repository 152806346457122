import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	failureHeading: {
		id: 'jsm-stakeholder-updates.services.actions.fetch-stakeholders.failure-heading',
		defaultMessage: 'Stakeholders were not populated',
		description: 'Heading of error flag shown after fetch stakeholder call failed.',
	},
	failureDescription: {
		id: 'jsm-stakeholder-updates.services.actions.fetch-stakeholders.failure-description',
		defaultMessage: 'Something went wrong. Try refreshing the page.',
		description: 'Description of error flag shown after fetch stakeholder call failed.',
	},
});
