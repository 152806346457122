import type { DynamicFieldFormula } from '@atlassian/jira-polaris-lib-formula/src/utils/formula/types.tsx';
import type { DynamicFieldResolver } from '../../types.tsx';
import { getNumberSelectorForFormula } from '../index.tsx';

const FORMULA_TEMPLATE = 'expr';

export const exprResolver: DynamicFieldResolver<number> = (formula: DynamicFieldFormula) => {
	if (formula === undefined || formula.template !== FORMULA_TEMPLATE) {
		return undefined;
	}
	const sub = formula.parameters.formula;

	// we just delegate directly to the subformula; no extra layer of indirection at "runtime"
	// (aka calculation time)
	return getNumberSelectorForFormula(sub);
};
