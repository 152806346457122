/**
 * @generated SignedSource<<8cba9120d9304692f56ff263b50af5a4>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type storyPointEstimate_issueFieldStoryPointEstimateReadviewFull_StoryPointEstimateReadView$data = {
  readonly number: number | null | undefined;
  readonly " $fragmentType": "storyPointEstimate_issueFieldStoryPointEstimateReadviewFull_StoryPointEstimateReadView";
};
export type storyPointEstimate_issueFieldStoryPointEstimateReadviewFull_StoryPointEstimateReadView$key = {
  readonly " $data"?: storyPointEstimate_issueFieldStoryPointEstimateReadviewFull_StoryPointEstimateReadView$data;
  readonly " $fragmentSpreads": FragmentRefs<"storyPointEstimate_issueFieldStoryPointEstimateReadviewFull_StoryPointEstimateReadView">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "storyPointEstimate_issueFieldStoryPointEstimateReadviewFull_StoryPointEstimateReadView",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "number"
    }
  ],
  "type": "JiraNumberField"
};

(node as any).hash = "213e14801681adab521b885bb8cf8c60";

export default node;
