import React from 'react';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';
import type { DevInfoIconProps } from './main.tsx';
import type DevInfoIcon from './main.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const AsyncDevInfoIconOld = lazy<typeof DevInfoIcon>(
	() => import(/* webpackChunkName: "async-jira-development-board-dev-info-icon" */ './main.tsx'),
);

export const AsyncDevInfoIconNew = (props: DevInfoIconProps) => (
	<UFOSegment name="asyncDevInfoIcon">
		<AsyncDevInfoIconOld {...props} />
	</UFOSegment>
);

export const AsyncDevInfoIcon = componentWithFG(
	'jira-add-ufo-instrument-for-asyncdevinfoicon',
	AsyncDevInfoIconNew,
	AsyncDevInfoIconOld,
);
