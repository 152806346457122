import React, { useCallback } from 'react';
import { Text } from '@atlaskit/primitives';
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';

export const JIRA_APPLE_APP_STORE_URL =
	'https://apps.apple.com/us/app/jira-cloud-by-atlassian/id1006972087';

export const JIRA_GOOGLE_PLAY_URL =
	'https://play.google.com/store/apps/details?id=com.atlassian.android.jira.core';

const SectionMessageComponent = () => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onClick = useCallback(
		(url: string, text: string) => {
			fireUIAnalytics(createAnalyticsEvent({}), `${text} clicked`);

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.open(url, '_blank');
		},
		[createAnalyticsEvent],
	);

	return (
		<SectionMessage
			testId="issue-view-activity.section-message"
			title={formatMessage(messages.title)}
			actions={[
				<SectionMessageAction
					key="jira-ios-download-cta"
					testId="issue-view-activity.section-message.jira-ios-download-cta"
					onClick={() => onClick(JIRA_APPLE_APP_STORE_URL, 'jiraIosDownloadCTA')}
				>
					{formatMessage(messages.jiraIosDownloadCta)}
				</SectionMessageAction>,
				<SectionMessageAction
					key="jira-andriod-download-cta"
					testId="issue-view-activity.section-message.jira-andriod-download-cta"
					onClick={() => onClick(JIRA_GOOGLE_PLAY_URL, 'jiraAndroidDownloadCTA')}
				>
					{formatMessage(messages.jiraAndroidDownloadCta)}
				</SectionMessageAction>,
			]}
		>
			<Text>
				{formatMessage(
					fg('jira-issue-terminology-refresh-m3') ? messages.bodyIssueTermRefresh : messages.body,
				)}
			</Text>
		</SectionMessage>
	);
};

export default SectionMessageComponent;
