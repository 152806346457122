import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/operator/map';
import type { ActionsObservable } from 'redux-observable';
import {
	type CreateChildRetryAction,
	CREATE_CHILD_RETRY,
	createChildRequest,
} from '../../state/entities/actions.tsx';
import { getIssue } from '../../state/entities/selectors.tsx';
import type { State } from '../../state/types.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<CreateChildRetryAction>, store: MiddlewareAPI<State>) =>
	action$.ofType(CREATE_CHILD_RETRY).map(({ payload: { optimisticId }, meta }) => {
		const state = store.getState();
		const issue = getIssue(optimisticId)(state);
		// @ts-expect-error - TS2339 - Property 'isLoading' does not exist on type 'ChildIssue | undefined'. | TS2339 - Property 'hasError' does not exist on type 'ChildIssue | undefined'.
		const { isLoading, hasError, ...issueDetails } = issue;
		// @ts-expect-error - TS2345 - Argument of type '{ id: string; issueId?: string | undefined; issueKey?: string | undefined; issueLink?: string | undefined; assigneeUrl?: string | null | undefined; assigneeDisplayName?: string | null | undefined; ... 17 more ...; updated?: Date | undefined; }' is not assignable to parameter of type 'OptimisticUiChildIssue'.
		return createChildRequest(issueDetails, meta.analyticsEvent);
	});
