import React from 'react';
import WatchIcon from '@atlaskit/icon/core/migration/eye-open--watch';
import { Flex, Text, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

type Props = {
	isInternal: boolean;
};

export const CustomBanner = ({ isInternal }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<Flex xcss={[bannerStyles, isInternal && internalBannerStyles]} role="alert">
			<WatchIcon
				color={isInternal ? token('color.icon.accent.yellow') : undefined}
				label=""
				spacing="none"
				LEGACY_size="small"
			/>
			<Text>
				{formatMessage(isInternal ? messages.internalNoteBanner : messages.replyToCustomerBanner)}
			</Text>
		</Flex>
	);
};

const bannerStyles = xcss({
	paddingInline: 'space.100',
	paddingBlock: 'space.150',
	alignItems: 'center',
	gap: 'space.100',
});

const internalBannerStyles = xcss({
	backgroundColor: 'color.background.accent.yellow.subtlest',
});
