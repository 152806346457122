export function getChangedProps<T>(current?: T, prev?: T): (keyof T)[] {
	if (
		current === undefined ||
		current === null ||
		typeof current !== 'object' ||
		typeof prev !== 'object'
	) {
		return [];
	}
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return (Object.keys(current) as Array<keyof T>).filter(
		(key) => current?.[key] !== undefined && current?.[key] !== prev?.[key],
	);
}
