import React from 'react';
import { useFragment, graphql } from 'react-relay';
import { Box, xcss } from '@atlaskit/primitives';
import { IssueViewCreatedDateField } from '@atlassian/jira-issue-view-layout-meta-created-date-field/src/ui/index.tsx';
import { IssueViewResolvedDateField } from '@atlassian/jira-issue-view-layout-meta-resolved-date-field/src/ui/index.tsx';
import { IssueViewUpdatedDateField } from '@atlassian/jira-issue-view-layout-meta-updated-date-field/src/ui/index.tsx';
import type { ui_issueViewLayoutMetaContainer_IssueViewMetaContainer$key } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutMetaContainer_IssueViewMetaContainer.graphql';

export interface IssueViewMetaContainerProps {
	fragmentKey: ui_issueViewLayoutMetaContainer_IssueViewMetaContainer$key | null;
}

export const IssueViewMetaContainer = ({ fragmentKey }: IssueViewMetaContainerProps) => {
	const data = useFragment<ui_issueViewLayoutMetaContainer_IssueViewMetaContainer$key>(
		graphql`
			fragment ui_issueViewLayoutMetaContainer_IssueViewMetaContainer on JiraIssue {
				createdField {
					...ui_issueViewLayoutMetaCreatedDateField_IssueViewCreatedDateField
				}
				updatedField {
					...ui_issueViewLayoutMetaUpdatedDateField_IssueViewUpdatedDateField
				}
				resolutionDateField {
					...ui_issueViewLayoutMetaResolvedDateField_IssueViewResolvedDateField
				}
			}
		`,
		fragmentKey,
	);

	return (
		<Box xcss={containerStyles}>
			{data?.createdField && <IssueViewCreatedDateField fragmentKey={data.createdField} />}
			{data?.updatedField && <IssueViewUpdatedDateField fragmentKey={data.updatedField} />}
			{data?.resolutionDateField && (
				<IssueViewResolvedDateField fragmentKey={data.resolutionDateField} />
			)}
		</Box>
	);
};

const containerStyles = xcss({
	marginTop: 'space.050',
	marginRight: 'space.200',
	flex: 1,
	paddingLeft: 'space.150',
});
