import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	errorMessage: {
		id: 'issue-field-status.common.view-workflow.error.error-message',
		defaultMessage: 'Couldn’t find the workflow.',
		description:
			'Error message that indicates that the view workflow permission could not be loaded',
	},
	tryAgain: {
		id: 'issue-field-status.common.view-workflow.error.try-again',
		defaultMessage: 'Try again',
		description: 'Subtle link to reload the View Workflow permission',
	},
});
