import { defaultSchema } from '@atlaskit/adf-schema/schema-default';
import { JSONTransformer, type JSONDocNode } from '@atlaskit/editor-json-transformer';
import { WikiMarkupTransformer } from '@atlaskit/editor-wikimarkup-transformer';

const wikiTransformer = new WikiMarkupTransformer(defaultSchema);
const adfTransformer = new JSONTransformer();

export const convertWikiToAdf = (wiki?: string | null): null | JSONDocNode => {
	if (wiki === null || wiki === undefined || wiki === '') {
		return null;
	}
	const pmNode = wikiTransformer.parse(wiki);
	return adfTransformer.encode(pmNode);
};
