import { loadSitePublicSharingConfig } from './private/load-site-public-sharing-config/index.tsx';
import { loadProjectAccessLevel, setProjectAccessLevel } from './project-access/index.tsx';
import { loadSharingSettings, updateSharingSettings } from './settings/index.tsx';
import { loadSharingSiteConfig } from './site-config/index.tsx';
import {
	addSharingStakeholders,
	loadSharingStakeholders,
	removeSharingStakeholders,
} from './stakeholders/index.tsx';
import { deletePublishedView } from './views/index.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { loadSharingSettings, updateSharingSettings } from './settings';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	addSharingStakeholders,
	loadSharingStakeholders,
	removeSharingStakeholders,
} from './stakeholders';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { loadSharingSiteConfig } from './site-config';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { setProjectAccessLevel, loadProjectAccessLevel } from './project-access';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { deletePublishedView } from './views';

export const privateActions = {
	loadSitePublicSharingConfig,
} as const;

export const publicActions = {
	loadSharingSettings,
	updateSharingSettings,
	addSharingStakeholders,
	loadSharingStakeholders,
	removeSharingStakeholders,
	loadSharingSiteConfig,
	setProjectAccessLevel,
	loadProjectAccessLevel,
	deletePublishedView,
} as const;

export type Actions = typeof publicActions;
