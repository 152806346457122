/**
 * @generated SignedSource<<499c8c7c45601b171574f0cb9e6e50a4>>
 * @relayHash 23c8b594575cffbe45743034757d1521
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 3fa2005ce3a97406372c152039c0bd96cc5523c7977a18d95fd4209005d4ed47

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type JiraSingleValueFieldOperations = "SET" | "%future added value";
export type JiraUpdateIssueTypeFieldInput = {
  id: string;
  operation: JiraIssueTypeFieldOperationInput;
};
export type JiraIssueTypeFieldOperationInput = {
  id: string;
  operation: JiraSingleValueFieldOperations;
};
export type ui_issueTypeSwitcher_Mutation$variables = {
  input: JiraUpdateIssueTypeFieldInput;
};
export type ui_issueTypeSwitcher_Mutation$data = {
  readonly jira: {
    readonly updateIssueTypeField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly issueType: {
          readonly avatar: {
            readonly xsmall: string | null | undefined;
          } | null | undefined;
          readonly hierarchy: {
            readonly level: number | null | undefined;
          } | null | undefined;
          readonly id: string;
          readonly issueTypeId: string | null | undefined;
          readonly name: string;
        } | null | undefined;
        readonly issueTypes: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly avatar: {
                readonly xsmall: string | null | undefined;
              } | null | undefined;
              readonly hierarchy: {
                readonly level: number | null | undefined;
              } | null | undefined;
              readonly id: string;
              readonly issueTypeId: string | null | undefined;
              readonly name: string;
            } | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type ui_issueTypeSwitcher_Mutation$rawResponse = {
  readonly jira: {
    readonly updateIssueTypeField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly id: string;
        readonly issueType: {
          readonly avatar: {
            readonly xsmall: string | null | undefined;
          } | null | undefined;
          readonly hierarchy: {
            readonly level: number | null | undefined;
          } | null | undefined;
          readonly id: string;
          readonly issueTypeId: string | null | undefined;
          readonly name: string;
        } | null | undefined;
        readonly issueTypes: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly avatar: {
                readonly xsmall: string | null | undefined;
              } | null | undefined;
              readonly hierarchy: {
                readonly level: number | null | undefined;
              } | null | undefined;
              readonly id: string;
              readonly issueTypeId: string | null | undefined;
              readonly name: string;
            } | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type ui_issueTypeSwitcher_Mutation = {
  rawResponse: ui_issueTypeSwitcher_Mutation$rawResponse;
  response: ui_issueTypeSwitcher_Mutation$data;
  variables: ui_issueTypeSwitcher_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "success"
},
v3 = {
  "concreteType": "MutationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "message"
    }
  ]
},
v4 = {
  "kind": "ScalarField",
  "name": "id"
},
v5 = {
  "kind": "ScalarField",
  "name": "name"
},
v6 = {
  "kind": "ScalarField",
  "name": "issueTypeId"
},
v7 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "xsmall"
    }
  ]
},
v8 = {
  "concreteType": "JiraIssueTypeHierarchyLevel",
  "kind": "LinkedField",
  "name": "hierarchy",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "level"
    }
  ]
},
v9 = {
  "concreteType": "JiraIssueType",
  "kind": "LinkedField",
  "name": "issueType",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    (v5/*: any*/),
    (v6/*: any*/),
    (v7/*: any*/),
    (v8/*: any*/)
  ]
},
v10 = {
  "concreteType": "JiraIssueTypeConnection",
  "kind": "LinkedField",
  "name": "issueTypes",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraIssueTypeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraIssueType",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v4/*: any*/),
            (v6/*: any*/),
            (v5/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/)
          ]
        }
      ]
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "ui_issueTypeSwitcher_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraIssueTypeFieldPayload",
            "kind": "LinkedField",
            "name": "updateIssueTypeField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraIssueTypeField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ui_issueTypeSwitcher_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraIssueTypeFieldPayload",
            "kind": "LinkedField",
            "name": "updateIssueTypeField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraIssueTypeField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v4/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "3fa2005ce3a97406372c152039c0bd96cc5523c7977a18d95fd4209005d4ed47",
    "metadata": {},
    "name": "ui_issueTypeSwitcher_Mutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "9c9119130da6f640697b4d928df071b6";

export default node;
