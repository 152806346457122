import React from 'react';
import FindFieldModal from '@atlassian/jira-issue-config-find-field/src/async.tsx';
import { useIssueKey, useProjectKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import {
	useProjectId,
	useIsSimplifiedProject,
	useProjectType,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import { useFindFieldDialog } from './controller/index.tsx';

const FindField = () => {
	const [{ isOpen }, { closeFindFieldDialog }] = useFindFieldDialog();
	const issueKey = useIssueKey();
	const projectKey = useProjectKey();
	const projectId = useProjectId(projectKey);
	const nextGenProject = useIsSimplifiedProject(projectKey);
	const projectType = useProjectType(projectKey);

	return isOpen ? (
		<FindFieldModal
			project={{ projectId, nextGenProject, projectType }}
			issueKey={issueKey}
			operation="VIEW"
			onCancel={closeFindFieldDialog}
		/>
	) : null;
};

export default FindField;
