import type { Transition } from '@atlassian/jira-issue-fetch-services/src/types.tsx';
import type { StatusTransition } from '../../common/types.tsx';

export const filterTransitionsWithDestinationAndCategory = (
	transitions: StatusTransition[] = [],
): StatusTransition[] =>
	transitions.filter((transition) => !!transition.to && !!transition.to.statusCategory);

export const transformTransitionToStore = (transition: StatusTransition): Transition => ({
	id: parseInt(transition.id, 10),
	name: transition.name,
	hasScreen: transition.hasScreen,
	isGlobal: transition.isGlobal,
	isInitial: transition.isInitial,
	isConditional: transition.isConditional,
	...(typeof transition.isLooped === 'boolean' && { isLooped: transition.isLooped }),
	to: {
		id: parseInt(transition.to.id, 10),
		name: transition.to.name,
		description: '',
		statusCategory: {
			id: transition.to.statusCategory.id,
		},
	},
	from: null,
});

export const transformTransitionFromStore = (transition: Transition): StatusTransition => ({
	id: String(transition.id),
	name: transition.name,
	hasScreen: transition.hasScreen,
	isGlobal: transition.isGlobal,
	isInitial: transition.isInitial,
	isConditional: transition.isConditional,
	...(typeof transition.isLooped === 'boolean' && { isLooped: transition.isLooped }),
	to: {
		id: String(transition.to.id),
		name: transition.to.name,
		statusCategory: {
			id: transition.to.statusCategory.id,
		},
	},
});
