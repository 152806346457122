import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'development-summary-branch.title',
		defaultMessage: '{styledCount} {count, plural, one {branch} other {branches}}',
		description:
			"The number of branches associated with a Jira Issue. 'Branch' is a technical term for the tool Git, and is most often not translated. It is acceptable to transliterate this term to another alphabet/script.",
	},
	emptyState: {
		id: 'development-summary-branch.empty-state',
		defaultMessage: '{Icon} Branch',
		description:
			"Placeholder for the number of branches associated with a Jira Issue. 'Branch' is a technical term for the tool Git, and is most often not translated. It is acceptable to transliterate this term to another alphabet/script.",
	},
	emptyStateUpdate: {
		id: 'development-summary-branch.empty-state-update',
		defaultMessage: 'Branch',
		description:
			"Placeholder for the number of branches associated with a Jira Issue. 'Branch' is a technical term for the tool Git, and is most often not translated. It is acceptable to transliterate this term to another alphabet/script.",
	},
	createBranchTitle: {
		id: 'development-summary-branch.create-branch-title',
		defaultMessage: '{Icon} Create Branch',
		description:
			"The tool tip message indicating that clicking the link will result in an action to create a branch. 'Branch' is a technical term from the tool Git that is most often not translated. It is acceptable to transliterate this term to another alphabet/script.",
	},
	createBranchTitleLowercase: {
		id: 'development-summary-branch.create-branch-title-lowercase',
		defaultMessage: 'Create branch',
		description:
			"The tool tip message indicating that clicking the link will result in an action to create a branch. 'Branch' is a technical term from the tool Git that is most often not translated. It is acceptable to transliterate this term to another alphabet/script.",
	},
	createBranchDropdownIconLabelLowercase: {
		id: 'development-summary-branch.create-branch-dropdown-icon-label-lowercase',
		defaultMessage: 'Create branch',
		description:
			"The alt text describing the icon on a drop down. 'Branch' is a technical term from the tool Git that is most often not translated. It is acceptable to transliterate this term to another alphabet/script.",
	},
	tooltip: {
		id: 'development-summary-branch.tooltip',
		defaultMessage: 'Open branch',
		description:
			"The tool tip message indicating that clicking the link will lead to external link of a branch. 'Branch' is a technical term from the tool Git that is most often not translated. It is acceptable to transliterate this term to another alphabet/script.",
	},
});
