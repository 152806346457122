export const timeTracking = () => `
      timeTracking {
        isTimeTrackingEnabled
        hoursPerDay
        daysPerWeek
        defaultUnit
      }
`;

export const getTimeTrackingQuery = () => `
    jiraSettings {
      ${timeTracking()}
    }
`;

export const getQuery = () => `
    jiraSettings {
      ${timeTracking()}
      issueLinkTypes {
        id
        name
        inward
        outward
      }
    }
`;
