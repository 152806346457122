import React from 'react';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import {
	projectIssueTypeRequest,
	showIssueTypeList,
	simpleChangeIssueTypeRequest,
} from '@atlassian/jira-issue-view-store/src/actions/change-issue-type-actions.tsx';
import { idSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';
import {
	availableProjectIssueTypesSelector,
	shouldShowIssueTypeList,
} from '@atlassian/jira-issue-view-store/src/issue-field/state/selectors/change-issue-type-selector.tsx';
import ChangeIssueType, { type Props } from './main.tsx';

export default connect(
	(state) => ({
		issueTypes: availableProjectIssueTypesSelector(state),
		issueId: idSelector(state),
		isOpen: shouldShowIssueTypeList(state),
	}),
	{
		showIssueTypeList,
		loadIssueTypes: projectIssueTypeRequest,
		changeIssueType: simpleChangeIssueTypeRequest,
	},
)((props: Props) => (
	<ErrorBoundary id="change-issue-type" packageName="issue-view">
		<ChangeIssueType {...props} />
	</ErrorBoundary>
));
