import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { styled } from '@compiled/react';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';
import Button from '@atlaskit/button';
import { useSmartLinkLifecycleAnalytics } from '@atlaskit/link-analytics';
import { SmartCardProvider } from '@atlaskit/link-provider';
import { token } from '@atlaskit/tokens';
import { JiraIssueAri } from '@atlassian/ari/jira';
import EnterEscapeHandler from '@atlassian/jira-common-components-enter-escape-handler/src/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { toFlagId, useFlagService } from '@atlassian/jira-flags';
import { FormattedI18nMessage } from '@atlassian/jira-formatted-i18n-message/src/ui/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useInviteOnCreateLink } from '@atlassian/jira-invite-prompt-on-link-insert/src/ui/index.tsx';
import { useIssueId } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useEmbeddedPageTracking } from '@atlassian/jira-issue-create-confluence-content/src/controllers/use-embedded-page-tracking/index.tsx';
import { useConfluenceWhiteboard } from '@atlassian/jira-issue-create-confluence-content/src/controllers/use-fetch-whiteboard/index.tsx';
import { useWhiteboardIssueLinking } from '@atlassian/jira-issue-create-confluence-content/src/controllers/use-whiteboard-issue-linking/index.tsx';
import type {
	ConfluencePage,
	ConfluenceWhiteboard,
} from '@atlassian/jira-issue-shared-types/src/common/types/confluence-content-type.tsx';
import type { FailedRemoteLink } from '@atlassian/jira-issue-shared-types/src/common/types/remote-link-error-type.tsx';
import type { ConfluenceAppLink } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { smoothScrollIntoCenterIfNeeded } from '@atlassian/jira-issue-view-common-utils/src/scroll/index.tsx';
import { confluencePagesAnalyticAttributes } from '@atlassian/jira-issue-view-common-views/src/smart-link-content/smart-link-analytics.tsx';
import { usePrevious } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import type { UIAnalyticsEvent } from '@atlassian/jira-product-analytics-bridge';
import type { Href } from '@atlassian/jira-shared-types/src/general.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import ConfluenceSearchView from './confluence-search-view/index.tsx';
import { CreateLinkError } from './constants.tsx';
import { CreateConfluenceContentMenu } from './create-confluence-content-menu/index.tsx';
import mapErrorMessage from './error-mapping.tsx';
import messages from './messages.tsx';
import {
	createConfluenceWhiteboardAri,
	getWhiteboard,
	getWhiteboardIdFromUrl,
	isWhiteboardContentTypeFromUrl,
} from './utils/utils.tsx';

type Props = {
	isInvalid: boolean;
	isLoading: boolean;
	linkedPagesCount: number;
	linkedWhiteboardsCount: number;
	errorMessage: string;
	createLinkClickedCount: number;
	recentlyViewedPages: ConfluencePage[];
	// eslint-disable-next-line jira/react/handler-naming
	requestRecentlyViewedPages: () => void;
	confluenceAppLinks?: ConfluenceAppLink[];
	linkedPages?: (ConfluencePage | FailedRemoteLink)[];
	mentionedPages?: (ConfluencePage | FailedRemoteLink)[];
	repairLink?: Href;
	onSubmit: (arg1: string, triggerInviteExperiment: () => void) => void;
	onCancel: () => void;
	onInvalidLinkModified: () => void;
	onSubmitClickAnalytics: () => void;
	onCancelClickAnalytics: () => void;
	onEnterKeyPressedAnalytics: () => void;
	onEscapeKeyPressedAnalytics: () => void;
	onSelectAnalytics: () => void;
	onAuthenticateApplink: (arg1: string) => void;
	onCreateWhiteboardLinkRequest: () => void;
	onCreateWhiteboardLinkRequestFailed: () => void;
	onFetchConfluenceWhiteboardSuccess: (arg1: ConfluenceWhiteboard) => void;
	onLinkPage?: (link: string, analyticsEvent: UIAnalyticsEvent) => void;
};

// Exposed to testing
export const useIsDisabled = () => useState(() => true);

export const ConfluencePageCreateLinkView = ({
	isInvalid,
	isLoading,
	linkedPagesCount,
	linkedWhiteboardsCount,
	errorMessage,
	createLinkClickedCount,
	recentlyViewedPages,
	requestRecentlyViewedPages,
	confluenceAppLinks,
	linkedPages,
	mentionedPages,
	repairLink,
	onSubmit,
	onCancel,
	onInvalidLinkModified,
	onSubmitClickAnalytics,
	onCancelClickAnalytics,
	onEnterKeyPressedAnalytics,
	onEscapeKeyPressedAnalytics,
	onSelectAnalytics,
	onAuthenticateApplink,
	onCreateWhiteboardLinkRequest,
	onCreateWhiteboardLinkRequestFailed,
	onFetchConfluenceWhiteboardSuccess,
	onLinkPage,
}: Props) => {
	const [requestRecentlyViewedState] = useState(requestRecentlyViewedPages);
	const [isDisabled, setIsDisabled] = useIsDisabled();
	const [isAutoFocusable, setIsAutoFocusable] = useState(true);
	const [link, setLink] = useState(() => '');
	const [, setShowList] = useState(() => false);

	// using previous values for properties passed down into functions which
	// were using prevProps
	const prevCreateLinkClickedCount = usePrevious<number>(createLinkClickedCount);
	const prevIsLoading = usePrevious<boolean>(isLoading);
	const prevLinkedPagesCount = usePrevious<number>(linkedPagesCount);
	const prevLinkedWhiteboardsCount = usePrevious<number>(linkedWhiteboardsCount);

	const childContainer = useRef<unknown>(null);

	const { formatMessage } = useIntl();

	const { showFlag } = useFlagService();

	const cloudId = useTenantContext().cloudId;
	const issueId = useIssueId();
	const issueAri = useMemo(
		() =>
			JiraIssueAri.create({
				siteId: cloudId,
				issueId: issueId || '',
			}).toString(),
		[cloudId, issueId],
	);
	const linkIssueToWhiteboard = useWhiteboardIssueLinking({ issueAri }).linkIssueToWhiteboard;
	const fetchConfluenceWhiteboard = useConfluenceWhiteboard().fetchConfluenceWhiteboard;
	const fireAnalyticsEvent = useEmbeddedPageTracking();

	const linkAnalytics = useSmartLinkLifecycleAnalytics();

	const triggerInviteExperiment = useInviteOnCreateLink(link);

	const sendSmartLinkCreatedEvent = useCallback(() => {
		linkAnalytics?.linkCreated(
			{
				url: link,
			},
			null,
			confluencePagesAnalyticAttributes,
		);
	}, [link, linkAnalytics]);

	const handleSubmit = useCallback(async () => {
		const shouldSubmit = !isDisabled && !isLoading;
		if (!shouldSubmit) {
			return;
		}
		if (isWhiteboardContentTypeFromUrl(link)) {
			onCreateWhiteboardLinkRequest();
			const embeddedConfluenceSource = 'issueViewCreateLink';
			try {
				const whiteboardAri = createConfluenceWhiteboardAri({
					siteId: cloudId,
					whiteboardId: getWhiteboardIdFromUrl(link) ?? '',
				});
				await linkIssueToWhiteboard(whiteboardAri);
				fireAnalyticsEvent({
					action: 'success',
					eventName: 'linkIssueToWhiteboard',
					embeddedConfluenceSource,
					eventType: 'operational',
				});
			} catch (error) {
				fireErrorAnalytics({
					meta: {
						id: 'linkIssueToWhiteboard',
						teamName: 'bento',
						packageName: 'jiraIssueViewBase',
					},
					attributes: { embeddedConfluenceSource },
					error: new Error(`Failed to link Confluence whiteboard to Jira issue: ${error}`),
				});
				if (
					error instanceof Error &&
					error?.message === 'You do not have permission to ingest this relationship'
				) {
					showFlag({
						key: toFlagId('linkWhiteboardFailed'),
						type: 'error',
						title: formatMessage(messages.linkCrossOriginWhiteboardFailedTitle),
						description: formatMessage(messages.linkCrossOriginWhiteboardFailedDescription),
					});
				}
				onCreateWhiteboardLinkRequestFailed();
			}
			const whiteboard = await getWhiteboard({
				fetchConfluenceWhiteboard,
				cloudId,
				link,
				embeddedConfluenceSource,
			});
			fireAnalyticsEvent({
				action: 'success',
				eventName: 'fetchConfluenceWhiteboard',
				embeddedConfluenceSource,
				eventType: 'operational',
			});
			sendSmartLinkCreatedEvent();
			if (whiteboard) {
				onFetchConfluenceWhiteboardSuccess(whiteboard);
			}
		} else {
			onSubmit(link, triggerInviteExperiment);
			sendSmartLinkCreatedEvent();
		}
	}, [
		isDisabled,
		isLoading,
		link,
		onSubmit,
		sendSmartLinkCreatedEvent,
		triggerInviteExperiment,
		cloudId,
		linkIssueToWhiteboard,
		fetchConfluenceWhiteboard,
		onFetchConfluenceWhiteboardSuccess,
		onCreateWhiteboardLinkRequest,
		onCreateWhiteboardLinkRequestFailed,
		fireAnalyticsEvent,
		formatMessage,
		showFlag,
	]);

	const onChange = useCallback(
		(linkOnChange: string | null) => {
			// @ts-expect-error - TS2345 - Argument of type 'string | null' is not assignable to parameter of type 'string | undefined'.
			const trimmedLink = trim(linkOnChange);

			setIsDisabled(isEmpty(trimmedLink));
			setLink(trimmedLink);

			if (isInvalid) {
				onInvalidLinkModified();
			}
		},
		[isInvalid, onInvalidLinkModified, setIsDisabled],
	);

	const resetAndFocus = useCallback(() => {
		setIsDisabled(true);
		setLink('');
		setShowList(false);
	}, [setIsDisabled]);

	useEffect(() => {
		if (childContainer.current !== undefined) {
			// @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'HTMLElement | null'.
			smoothScrollIntoCenterIfNeeded(childContainer.current);
			// fetch recently viewed confluence pages
		}
		requestRecentlyViewedState;
	}, [requestRecentlyViewedState]);

	useEffect(() => {
		if (
			prevCreateLinkClickedCount !== undefined &&
			createLinkClickedCount !== prevCreateLinkClickedCount &&
			childContainer.current !== undefined
		) {
			// @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'HTMLElement | null'.
			smoothScrollIntoCenterIfNeeded(childContainer.current);
			setIsAutoFocusable(true);
		} else {
			setIsAutoFocusable(false);
		}

		const shouldResetAndFocus =
			prevLinkedPagesCount !== undefined &&
			prevLinkedWhiteboardsCount !== undefined &&
			prevIsLoading !== undefined &&
			(linkedWhiteboardsCount > prevLinkedWhiteboardsCount ||
				linkedPagesCount > prevLinkedPagesCount) &&
			!isLoading &&
			prevIsLoading === true;

		if (shouldResetAndFocus) {
			resetAndFocus();
		}
	}, [
		createLinkClickedCount,
		isLoading,
		linkedPagesCount,
		prevCreateLinkClickedCount,
		prevIsLoading,
		prevLinkedPagesCount,
		resetAndFocus,
		linkedWhiteboardsCount,
		prevLinkedWhiteboardsCount,
	]);

	const onSubmitClicked = useCallback(() => {
		handleSubmit();
		onSubmitClickAnalytics();
	}, [onSubmitClickAnalytics, handleSubmit]);

	const onCancelClicked = useCallback(() => {
		if (!isLoading) {
			setIsDisabled(true);
			setLink('');
			setShowList(false);
			onCancel();
		}
		onCancelClickAnalytics();
	}, [isLoading, onCancel, onCancelClickAnalytics, setIsDisabled]);

	const onEnterPressed = useCallback(() => {
		handleSubmit();
		onEnterKeyPressedAnalytics();
	}, [onEnterKeyPressedAnalytics, handleSubmit]);

	const onEscPressed = useCallback(() => {
		if (!isLoading) {
			setIsDisabled(true);
			setLink('');
			setShowList(false);
			onCancel();
		}
		onEscapeKeyPressedAnalytics();
	}, [isLoading, onCancel, onEscapeKeyPressedAnalytics, setIsDisabled]);

	const options = recentlyViewedPages.map((page) => ({
		label: page.title,
		value: page.href,
		data: page,
		page,
	}));

	let validationState = isInvalid ? 'error' : 'default';
	let validationMessage = isInvalid ? formatMessage(mapErrorMessage(errorMessage)) : undefined;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onClickAuth = (e: any) => {
		e.preventDefault();
		if (repairLink) {
			onAuthenticateApplink(repairLink);
		}
	};

	if (isInvalid && repairLink) {
		validationState = 'warning';
		// @ts-expect-error - TS2322 - Type 'Element' is not assignable to type 'string'.
		validationMessage = (
			<FormattedI18nMessage
				componentsMapping={{
					link: ({ children }) => (
						<a
							href={repairLink}
							onClick={onClickAuth}
							target="_blank"
							/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
							data-testid="issue.views.issue-base.content.confluence-pages.create-link.auth"
						>
							{children}
						</a>
					),
				}}
				message={mapErrorMessage(CreateLinkError.UNAUTHORIZED_PROMPT).defaultMessage}
			/>
		);
	}

	return (
		// @ts-expect-error - TS2322 - Type 'MutableRefObject<unknown>' is not assignable to type 'LegacyRef<HTMLDivElement> | undefined'.
		<div ref={childContainer}>
			<EnterEscapeHandler onEscape={onEscPressed}>
				<ConfluenceSearchView
					recentOptions={options}
					onChange={onChange}
					isAutoFocusable={isAutoFocusable}
					validationMessage={validationMessage}
					// @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'ValidationState | undefined'.
					validationState={validationState}
					onSelect={onSelectAnalytics}
					onEnter={onEnterPressed}
					link={link}
					isLoading={isLoading}
					confluenceAppLinks={confluenceAppLinks || []}
					linkedPages={linkedPages || []}
					mentionedPages={mentionedPages || []}
				/>
			</EnterEscapeHandler>
			<ButtonsContainer>
				<MenuWrapper>
					<CreateConfluenceContentMenu onLinkPage={onLinkPage} />
				</MenuWrapper>
				<ButtonGroupWrapper>
					<StyledButton
						appearance="primary"
						onClick={onSubmitClicked}
						isDisabled={isDisabled}
						// @ts-expect-error - TS2322 - Type '{ children: string; appearance: "primary"; onClick: () => void; isDisabled: boolean; isLoading: boolean; testId: string; }' is not assignable to type 'IntrinsicAttributes & ButtonProps & RefAttributes<HTMLElement> & { theme?: Theme | undefined; } & { ...; }'.
						isLoading={isLoading}
						/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
						testId="issue.views.issue-base.content.confluence-pages.create-link.link"
					>
						{formatMessage(messages.linkButton)}
					</StyledButton>
					<StyledButton
						appearance="subtle"
						onClick={onCancelClicked}
						/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
						testId="issue.views.issue-base.content.confluence-pages.create-link.cancel"
					>
						{formatMessage(messages.cancelButton)}
					</StyledButton>
				</ButtonGroupWrapper>
			</ButtonsContainer>
		</div>
	);
};

const ConfluencePageCreateLinkViewWithSmartCardProvider = (props: Props) => (
	<SmartCardProvider>
		<ConfluencePageCreateLinkView {...props} />
	</SmartCardProvider>
);

export default ConfluencePageCreateLinkViewWithSmartCardProvider;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledButton = styled(Button)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	marginLeft: `${token('space.100', '8px')} !important`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonsContainer = styled.div({
	marginTop: token('space.100', '8px'),
	display: 'flex',
	justifyContent: 'space-between',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MenuWrapper = styled.div({
	display: 'flex',
	justifyContent: 'flex-start',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonGroupWrapper = styled.div({
	display: 'flex',
	justifyContent: 'flex-end',
});
