import type { IssueTypeFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/issue-type/types.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { IssuesRemote } from '@atlassian/jira-polaris-remote-issue/src/controllers/types.tsx';
import { defaultJiraUpdateService } from '../common/index.tsx';
import type { JiraFieldMapping } from '../types.tsx';

export const jiraIssueTypeMapping = (
	issuesRemote: IssuesRemote,
	field: Field,
): JiraFieldMapping<IssueTypeFieldValue> => ({
	field,
	getValueFromJiraIssue: (issue) => issue.fields[field.key],
	getFieldValueForJiraUpdate: (value) => (value !== undefined ? value : null),
	getFieldValueForJiraBulkUpdate: ({ id }) => ({
		issueType: {
			id: field.key,
			issueTypeId: id,
		},
	}),
	isMultiValueField: false,
	isSupportedByIssueUpdateApi: false,
	...defaultJiraUpdateService(issuesRemote, field.key),
});
