import type { OptionalNullable } from '../types/types.tsx';

export type Connection<TNode> = {
	readonly edges?: OptionalNullable<
		ReadonlyArray<
			OptionalNullable<{
				readonly node?: OptionalNullable<TNode>;
			}>
		>
	>;
} | null;

export function mapNodes<TNode>(conn: OptionalNullable<Connection<TNode>>): TNode[] {
	const nodes: TNode[] = [];

	return (
		conn?.edges?.reduce((acc, edge) => {
			if (edge?.node) {
				acc.push(edge.node);
			}

			return acc;
		}, nodes) ?? []
	);
}
