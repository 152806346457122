// If the full URL supplied matches the Jira view issue pattern for the specified site then strip the URL
// to just the issue key. If the URL does not match the pattern then return it unchanged.
import { type BaseUrl, toBaseUrl } from '@atlassian/jira-shared-types/src/general.tsx';

const ISSUE_BROWSE_PATH = 'browse/';
export const ISSUE_KEY_REGEXP = /^(([A-Z][A-Z0-9]+)-([0-9]+))($|\?.*)/;

export const getCurrentHostPattern = (): BaseUrl => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	if (window.location.port !== '') {
		return toBaseUrl(
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			`${window.location.protocol}//${window.location.hostname}:${window.location.port}`,
		);
	}

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	return toBaseUrl(`${window.location.protocol}//${window.location.hostname}`);
};

export const stripUrlToIssueKey = (baseUrl?: string, candidateUrl?: string): string => {
	let baseUrlPath = baseUrl;
	if (baseUrlPath === undefined || candidateUrl === undefined) {
		return candidateUrl || '';
	}

	if (!baseUrlPath.endsWith('/')) {
		baseUrlPath += '/';
	}

	baseUrlPath += ISSUE_BROWSE_PATH;

	if (candidateUrl.indexOf(baseUrlPath) !== 0) {
		return candidateUrl;
	}

	const candidatePath = candidateUrl.slice(baseUrlPath.length);
	const match = candidatePath.match(ISSUE_KEY_REGEXP);

	if (match === null) {
		return candidateUrl;
	}

	return match[1] || ''; // issue key is at index 1 (first capture group)
};
