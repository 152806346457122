import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type {
	PolarisPlay,
	PolarisPlayContribution,
} from '@atlassian/jira-polaris-domain-field/src/play/types.tsx';
import { createErrorAnalytics } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import { fetchPlays } from '@atlassian/jira-polaris-remote-legacy-project/src/controllers/fetch-plays/index.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import { createIssueAri } from '../../../../common/utils/ari/index.tsx';
import type { Props, State } from '../../types.tsx';

const withItemReplacedAtIndex = <T,>(items: T[], index: number, replace: (item: T) => T): T[] => {
	if (index < 0 || index > items.length - 1) {
		throw new Error('Attempt to replace an element at index out of bounds');
	}
	const replacement = replace(items[index]);

	if (index === 0) {
		return [replacement, ...items.slice(1)];
	}
	if (index === items.length - 1) {
		return [...items.slice(0, -1), replacement];
	}
	return [...items.slice(0, index), replacement, ...items.slice(index + 1)];
};

const withItemRemovedAtIndex = <T,>(items: T[], index: number): T[] => {
	if (index < 0 || index > items.length - 1) {
		throw new Error('Attempt to remove an element at index out of bounds');
	}
	if (index === 0) {
		return items.slice(1);
	}
	if (index === items.length - 1) {
		return items.slice(0, -1);
	}
	return [...items.slice(0, index), ...items.slice(index + 1)];
};

const normalizeError = (error: unknown) => {
	if (error instanceof Error) {
		return error;
	}
	return new Error('Unexpected error - catched value is not an instance of an Error class');
};

const createUpdatedContributions = (
	existingContributions: PolarisPlayContribution[] | undefined,
	contribution: PolarisPlayContribution,
) => {
	if (existingContributions === undefined) {
		return [contribution];
	}

	const existingContributionIndex = existingContributions.findIndex(
		(item) => item.id === contribution.id,
	);

	if (existingContributionIndex === -1) {
		return [...existingContributions, contribution];
	}

	return withItemReplacedAtIndex(
		existingContributions,
		existingContributionIndex,
		() => contribution,
	);
};

export const createPlay =
	(play: PolarisPlay): Action<State, Props> =>
	async ({ getState, setState }) => {
		const state = getState();

		setState({
			...state,
			project: {
				...state.project,
				plays: [...(state.project.plays ?? []), play],
			},
		});
	};

export const updatePlayParams =
	(playAri: string, parameters: { maxSpend: number }): Action<State, Props> =>
	async ({ getState, setState }) => {
		const state = getState();

		try {
			if (state.project.plays === null) {
				throw new Error('Attempted params update of play in the nullified plays collection');
			}

			const playIndex = state.project.plays.findIndex(({ id }) => id === playAri);
			if (playIndex === -1) {
				throw new Error('Attempted params update of a non-existing play');
			}

			setState({
				...state,
				project: {
					...state.project,
					plays: withItemReplacedAtIndex(state.project.plays, playIndex, (play) => ({
						...play,
						parameters,
					})),
				},
			});
		} catch (error) {
			fireErrorAnalytics(
				createErrorAnalytics(
					'polaris.error.controllers.project.plays.update-play-params',
					normalizeError(error),
				),
			);
		}
	};

export const deletePlay =
	(play: PolarisPlay): Action<State, Props> =>
	async ({ getState, setState }) => {
		const state = getState();

		try {
			if (state.project.plays === null) {
				throw new Error('Attempted deletion of a play from the nullified plays collection');
			}

			const playIndex = state.project.plays.findIndex(({ id }) => id === play.id);
			if (playIndex === -1) {
				throw new Error('Attempted deletion of a non-existing play');
			}

			setState({
				...state,
				project: {
					...state.project,
					plays: withItemRemovedAtIndex(state.project.plays, playIndex),
				},
			});
		} catch (error) {
			fireErrorAnalytics(
				createErrorAnalytics(
					'polaris.error.controllers.project.plays.delete-play',
					normalizeError(error),
				),
			);
		}
	};

export const loadPlays =
	(fireAnalyticsEvent = false): Action<State, Props> =>
	async (
		{ getState, setState },
		{ apolloClient, containerAri, createAnalyticsEvent, onProjectLoadingError },
	) => {
		try {
			const plays = await fetchPlays(
				apolloClient,
				containerAri,
				createAnalyticsEvent,
				fireAnalyticsEvent,
			)();

			const state = getState();
			setState({
				...state,
				meta: {
					...state.meta,
					plays: { loading: true, error: undefined },
				},
				project: {
					...state.project,
					plays,
				},
			});
		} catch (error) {
			const state = getState();
			const normalizedError = normalizeError(error);

			setState({
				...state,
				meta: {
					...state.meta,
					plays: { loading: false, error: normalizedError },
				},
				project: {
					...state.project,
					plays: null,
				},
			});

			onProjectLoadingError(normalizedError);
		}
	};

export const upsertPlayContribution =
	(playId: string, contribution: PolarisPlayContribution): Action<State, Props> =>
	async ({ getState, setState }) => {
		const state = getState();

		try {
			if (state.project.plays === null) {
				throw new Error('Attempted upsert of contribution on a nullified plays collection');
			}

			const playIndex = state.project.plays.findIndex(({ id }) => id === playId);
			if (playIndex === -1) {
				throw new Error('Attempted upsert of contribution on a non-existing play');
			}

			setState({
				...state,
				project: {
					...state.project,
					plays: withItemReplacedAtIndex(state.project.plays, playIndex, (play) => ({
						...play,
						contributions: createUpdatedContributions(play.contributions, contribution),
					})),
				},
			});
		} catch (error) {
			fireErrorAnalytics(
				createErrorAnalytics(
					'polaris.error.controllers.project.plays.upsert-play-contribution',
					normalizeError(error),
				),
			);
		}
	};

export const loadPlayContributions =
	(subjectId: string, playId: string): Action<State, Props> =>
	async ({ getState, setState }, { cloudId, playContributionRemote }) => {
		const state = getState();

		try {
			if (state.project.plays === null) {
				throw new Error(
					'Attempted load of play contributions while plays is a nullified collection',
				);
			}

			const currentPlayIndex = state.project.plays.findIndex((item) => item.id === playId);
			if (currentPlayIndex === -1) {
				throw new Error('Attempted load of play contributions for an non-existing play');
			}

			const subjectAri = createIssueAri(cloudId, subjectId);
			const existingContribution = state.project.plays[currentPlayIndex]?.contributions?.find(
				(item) => item.subject === subjectAri,
			);

			if (existingContribution === undefined) {
				throw new Error(
					'Attempted load of play contributions while there is no given user contribution locally',
				);
			}

			if (
				// I don't understand why are we checking the existence of a comment here but it
				// was there before I started refactoring. No tests. No comments. Nothing meaningful
				// in the commit messages history. Worth revising. I'm too scared to remove it 😅 ~ bcytrowski
				existingContribution.comment !== undefined
			) {
				throw new Error(
					'Attempted load of play contributions while there is an existing user contribution with a comment',
				);
			}

			setState({
				meta: {
					...state.meta,
					playComments: {
						error: undefined,
						loading: true,
					},
				},
			});

			const contributions = await playContributionRemote.getIssueContributions(playId, subjectAri);

			const newPlayContributions = state.project.plays[currentPlayIndex].contributions?.map(
				(contribution) => {
					const updatedContribution = contributions?.find((item) => item.id === contribution.id);
					if (updatedContribution) {
						return {
							...updatedContribution,
							author: contribution.author,
						};
					}
					return contribution;
				},
			);

			const newState = {
				...state,
				project: {
					...state.project,
					plays: withItemReplacedAtIndex(state.project.plays, currentPlayIndex, (play) => ({
						...play,
						contributions: newPlayContributions,
					})),
				},
			};
			setState(newState);
		} catch (error) {
			fireErrorAnalytics(
				createErrorAnalytics(
					'polaris.error.controllers.project.plays.load-play-contributions',
					normalizeError(error),
				),
			);
		} finally {
			setState({
				meta: {
					...state.meta,
					playComments: {
						error: undefined,
						loading: false,
					},
				},
			});
		}
	};

export const deletePlayContribution =
	(playId: string, contributionId: string): Action<State, Props> =>
	async ({ getState, setState }) => {
		const state = getState();

		try {
			if (state.project.plays === null) {
				throw new Error('Attempted deletion of play contribution from nullified plays collection');
			}

			const currentPlayIndex = state.project.plays.findIndex((item) => item.id === playId);
			if (currentPlayIndex === -1) {
				throw new Error('Attempted deletion of play contribution from a non-existing play');
			}

			const playContributions = state.project.plays[currentPlayIndex].contributions;

			if (playContributions === undefined) {
				throw new Error(
					'Attempted deletion of play contribution from undefined play.contributions collection',
				);
			}

			const contributionIndex = playContributions.findIndex(
				(contribution) => contribution.id === contributionId,
			);

			if (contributionIndex === -1) {
				throw new Error('Attempted deletion of non-existing play contribution');
			}

			setState({
				...state,
				project: {
					...state.project,
					plays: withItemReplacedAtIndex(state.project.plays, currentPlayIndex, (play) => ({
						...play,
						contributions: withItemRemovedAtIndex(playContributions, contributionIndex),
					})),
				},
			});
		} catch (error) {
			fireErrorAnalytics(
				createErrorAnalytics(
					'polaris.error.controllers.project.plays.delete-play-contribution',
					normalizeError(error),
				),
			);
		}
	};
