import type { ViewSet } from '@atlassian/jira-polaris-domain-view/src/view-set/types.tsx';

export const upsertViewSet = (viewSets: ViewSet[], newViewSet: ViewSet): ViewSet[] => {
	let isExisting = false;
	const updatedViewSets = viewSets.map((viewSet) => {
		if (viewSet.id === newViewSet.id) {
			isExisting = true;
			return {
				...newViewSet,
				views: viewSet.views,
				collapsed: viewSet.collapsed,
			};
		}
		return viewSet;
	});

	return isExisting ? updatedViewSets : [...updatedViewSets, newViewSet];
};
