export const glancesQuery = `
        glancesField {
          fieldKey
          value {
            name
            status
            iframe {
              addonKey
              moduleKey
              options
            }
            icon {
              url
            }
            content
            type
          }
        }
      `;

export const legacyRightWebPanelQuery = `
        legacyRightWebPanels {
          edges {
            node {
              name
              status
              addonKey
              moduleKey
              options
              content
            }
          }
        }
      `;
