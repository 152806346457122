import omit from 'lodash/omit';
import type { WorklogEntitiesState } from '@atlassian/jira-issue-gira-transformer-types/src/common/types/worklogs.tsx';
import { NEWEST_FIRST } from '@atlassian/jira-issue-shared-types/src/common/types/activity-sort-order-type.tsx';
import {
	FETCH_ISSUE_SUCCESS,
	type FetchIssueSuccessAction,
} from '../../common/actions/issue-fetch-actions.tsx';
import {
	FETCH_WORKLOGS_SUCCESS,
	EDIT_WORKLOG_SUCCESS,
	DELETE_WORKLOG_SUCCESS,
	FETCH_WORKLOGS_BY_ID_SUCCESS,
	FETCH_WORKLOGS_BY_ID_FAILURE,
	FETCH_SORTED_WORKLOGS_SUCCESS,
	FETCH_NEWER_SORTED_WORKLOGS_SUCCESS,
	FETCH_OLDER_SORTED_WORKLOGS_SUCCESS,
	type FetchWorklogsByIdSuccessAction,
	type FetchWorklogsByIdFailureAction,
	type FetchWorklogsSuccessAction,
	type EditWorklogSuccessAction,
	type DeleteWorklogSuccessAction,
	type FetchSortedWorklogsSuccessAction,
	type FetchNewerSortedWorklogsSuccessAction,
	type FetchOlderSortedWorklogsSuccessAction,
} from '../../common/actions/worklog-actions.tsx';

type State = WorklogEntitiesState;

export const initialState: State = {
	sortedWorklogIds: [],
	worklogs: {},
	totalWorklogs: 0,
	worklogsStartIndex: 0,
};

type Action =
	| FetchWorklogsSuccessAction
	| FetchWorklogsByIdSuccessAction
	| FetchWorklogsByIdFailureAction
	| EditWorklogSuccessAction
	| DeleteWorklogSuccessAction
	| FetchIssueSuccessAction
	| FetchSortedWorklogsSuccessAction
	| FetchNewerSortedWorklogsSuccessAction
	| FetchOlderSortedWorklogsSuccessAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: State = initialState, action: Action): State => {
	switch (action.type) {
		case FETCH_WORKLOGS_BY_ID_SUCCESS: {
			const {
				sortedWorklogIds,
				worklogs,
				startIndex: worklogsStartIndex,
				totalWorklogs,
			} = action.payload;
			return { ...state, sortedWorklogIds, worklogs, worklogsStartIndex, totalWorklogs };
		}

		case FETCH_WORKLOGS_BY_ID_FAILURE: {
			return { ...initialState };
		}

		case EDIT_WORKLOG_SUCCESS: {
			const { worklogs } = action.payload;
			return { ...state, worklogs };
		}

		// This action contains the original logic to fetch data
		// And not be used now since we get data from gira and use FETCH_INITIAL_WORKLOGS_SUCCESS
		case FETCH_WORKLOGS_SUCCESS: {
			const { sortedWorklogIds, worklogs, totalWorklogs } = action.payload;
			const { worklogsStartIndex } = state;
			// add fetched data to existing data
			const newSortedWorklogIds = sortedWorklogIds.slice().reverse().concat(state.sortedWorklogIds);
			const newWorklogs = { ...state.worklogs, ...worklogs };
			return {
				sortedWorklogIds: newSortedWorklogIds,
				worklogs: newWorklogs,
				totalWorklogs,
				worklogsStartIndex,
			};
		}

		case FETCH_ISSUE_SUCCESS: {
			const { payload } = action;
			const { sortedWorklogIds, worklogs, totalWorklogs, worklogsStartIndex } = payload;
			return { sortedWorklogIds, worklogs, totalWorklogs, worklogsStartIndex };
		}

		case DELETE_WORKLOG_SUCCESS: {
			const { worklogId } = action.payload;
			const { totalWorklogs, worklogsStartIndex } = state;
			const sortedWorklogIds = state.sortedWorklogIds.filter((id) => id !== worklogId);
			const worklogs = omit(state.worklogs, worklogId);
			return {
				sortedWorklogIds,
				worklogs,
				totalWorklogs: totalWorklogs - 1,
				worklogsStartIndex,
			};
		}

		case FETCH_SORTED_WORKLOGS_SUCCESS: {
			const { worklogs, totalWorklogs, startIndex, sortedWorklogIds } = action.payload;
			return {
				sortedWorklogIds,
				worklogs,
				totalWorklogs,
				worklogsStartIndex: startIndex,
			};
		}

		case FETCH_NEWER_SORTED_WORKLOGS_SUCCESS: {
			const { worklogs, totalWorklogs, startIndex, sortedWorklogIds, selectedSortOrder } =
				action.payload;

			const newSortedWorklogIds =
				selectedSortOrder === NEWEST_FIRST
					? [...sortedWorklogIds, ...state.sortedWorklogIds]
					: [...state.sortedWorklogIds, ...sortedWorklogIds];

			return {
				...state,
				worklogs: { ...state.worklogs, ...worklogs },
				sortedWorklogIds: newSortedWorklogIds,
				totalWorklogs,
				worklogsStartIndex: startIndex,
			};
		}

		case FETCH_OLDER_SORTED_WORKLOGS_SUCCESS: {
			const { worklogs, totalWorklogs, startIndex, sortedWorklogIds, selectedSortOrder } =
				action.payload;

			const newSortedWorklogIds =
				selectedSortOrder === NEWEST_FIRST
					? [...state.sortedWorklogIds, ...sortedWorklogIds]
					: [...sortedWorklogIds, ...state.sortedWorklogIds];

			return {
				...state,
				worklogs: { ...state.worklogs, ...worklogs },
				sortedWorklogIds: newSortedWorklogIds,
				totalWorklogs,
				worklogsStartIndex: startIndex,
			};
		}
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
