import { createHook, createStore, createContainer } from '@atlassian/react-sweet-state';
import * as actions from './actions.tsx';
import type { State } from './types.tsx';

export type Actions = typeof actions;

export const initialState = {
	isTourActive: false,
} as const;

export const store = createStore<State, Actions>({
	name: 'new-issue-view-onboarding',
	initialState,
	actions,
});

const getNewIssueViewOnboarding = (state: State) => state;

export const useOnboardingTourStore = createHook(store, {
	selector: getNewIssueViewOnboarding,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const OnboardingTourContainer = createContainer<State, Actions, Record<any, any>>(store);
