import type { AuthProvider } from '@atlaskit/media-core';
import type { TokenProvider } from '../token-provider/index.tsx';

export const createAuthProvider =
	(tokenProvider: TokenProvider, clientId: string, baseUrl: string): AuthProvider =>
	async (context) => ({
		clientId,
		token: await tokenProvider(context ? context.collectionName : undefined),
		baseUrl,
	});
