import React, { Component, createRef } from 'react';
import { styled } from '@compiled/react';
import memoizeOne from 'memoize-one';

import { token } from '@atlaskit/tokens';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { useIsModal } from '@atlassian/jira-issue-context-service/src/main.tsx';
import {
	ELID_ISSUE_HEADER,
	ELID_ISSUE_HEADER_ACTIONS,
} from '@atlassian/jira-issue-view-common-constants/src/index.tsx';
import { getInnerHeight } from '@atlassian/jira-issue-view-common-utils/src/dom-util/get-inner-height/index.tsx';
import { getScrollParent } from '@atlassian/jira-issue-view-common-utils/src/dom-util/get-scroll-parent/index.tsx';
import type { RefObject } from '@atlassian/jira-shared-types/src/general.tsx';
import { useSpaStatePageReady } from '@atlassian/jira-spa-state-controller/src/components/page-ready-state/index.tsx';
import ClassyAnimator from '../../common/animations/classy-animator.tsx';
import { LOG_LOCATION, EXTRA_GLANCE_PANEL_OFFSET } from './constants.tsx';
import { Container } from './styled.tsx';
import type { RenderStyles, Props, State, Position } from './types.tsx';
import { getPanelWidth, getContentWidth, getHeaderWidth, getContextWidth } from './utils.tsx';

// eslint-disable-next-line jira/react/no-class-components
export class SidePanel extends Component<Props, State> {
	static displayName = 'SidePanel';

	constructor(props: Props) {
		super(props);
		this.refPanelPositioner = createRef();
		this.refFixedPanel = createRef<HTMLDivElement>();
	}

	state: State = {
		styles: {
			panelWidth: 0,
			headerWidth: 0,
			contentWidth: 0,
			contextWidth: 0,
			panelTop: 0,
			panelHeight: 0,
		},
	};

	componentDidMount(): void {
		// Update side Panel Styles also calls setSidePanelPosition
		this.updateSidePanelStyles();
		this.attachSidePanelListeners();
		// ResizeObserver
		if (this.props && this.props.refs && this.scrollContainerResizeObserver) {
			if (this.props.refs.refLayoutContainer) {
				this.scrollContainerResizeObserver.observe(this.props.refs.refLayoutContainer);
			}
			if (this.props.refs.refPanelColumnElement) {
				this.scrollContainerResizeObserver.observe(this.props.refs.refPanelColumnElement);
			}
		}
	}

	componentDidUpdate(prevProps: Props): void {
		// On panel open
		if (this.props.isOpen && this.props.isOpen !== prevProps.isOpen) {
			this.updateSidePanelStyles();
		}
		// Update ResizeObserver
		if (this.scrollContainerResizeObserver) {
			const {
				refs: {
					refPanelColumnElement: newRefPanelColumnElement,
					refLayoutContainer: newRefLayoutContainer,
				},
			} = this.props;
			const {
				refs: {
					refPanelColumnElement: oldRefPanelColumnElement,
					refLayoutContainer: oldRefLayoutContainer,
				},
			} = prevProps;
			// Make sure column and layout resizes are observed, in case the component
			// is mounted in a compact mode and one or the other is not passed in.
			if (newRefPanelColumnElement) {
				this.scrollContainerResizeObserver.observe(newRefPanelColumnElement);
			}
			if (newRefLayoutContainer) {
				this.scrollContainerResizeObserver.observe(newRefLayoutContainer);
			}
			if (
				newRefPanelColumnElement !== oldRefPanelColumnElement ||
				newRefLayoutContainer !== oldRefLayoutContainer
			) {
				if (oldRefPanelColumnElement && this.scrollContainerResizeObserver) {
					this.scrollContainerResizeObserver.unobserve(oldRefPanelColumnElement);
				}
				if (oldRefLayoutContainer && this.scrollContainerResizeObserver) {
					this.scrollContainerResizeObserver.unobserve(oldRefLayoutContainer);
				}
				// Update styles if Scroll Container has changed
				this.detachSidePanelListeners();
				this.updateSidePanelPosition();
				this.attachSidePanelListeners();
			}
		}
	}

	componentWillUnmount(): void {
		this.detachSidePanelListeners();
		if (this.scrollContainerResizeObserver) {
			this.scrollContainerResizeObserver.disconnect();
		}
	}

	refFixedPanel: RefObject<HTMLDivElement>;

	// `position: absolute` element which helps position `fixed` element in the issue (e.g. its `left` being always correct without recalculations in js)
	refPanelPositioner: RefObject;

	// panel's scroll parent. Can be page, modal etc.
	scrollContainerCached: HTMLElement | null = null;

	scrollContainerResizeObserver =
		global && global.ResizeObserver
			? // eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				new window.ResizeObserver(() => {
					requestAnimationFrame(() => {
						this.updateSidePanelStyles();
					});
				})
			: null;

	getMemoizedStyles = memoizeOne(
		(
			panelWidth?: number,
			panelTop?: number,
			panelHeight?: number,
			headerWidth?: number,
			contentWidth?: number,
			contextWidth?: number,
		): RenderStyles => ({
			panelWidth,
			panelTop,
			panelHeight,
			headerWidth,
			contentWidth,
			contextWidth,
		}),
	);

	currentPosition: RenderStyles = {
		panelWidth: undefined,
		headerWidth: undefined,
		contentWidth: undefined,
		contextWidth: undefined,
		panelTop: undefined,
		panelHeight: undefined,
	};

	updateSidePanelStyles = (): void => {
		if (!this.props || !this.props.isOpen) {
			return;
		}

		const { refs } = this.props;

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const panelColumnHeader = document.getElementById(ELID_ISSUE_HEADER_ACTIONS);

		// Content
		const panelColumn = refs.refPanelColumnElement;
		const panelColumnStyle = panelColumn && getComputedStyle(panelColumn);
		const contextWidth = getContextWidth(refs);
		const contentWidth = getContentWidth(refs, panelColumnStyle);
		const headerWidth = getHeaderWidth(panelColumnHeader, contentWidth);

		// Panel
		const panelPos = this.calculateSidePanelPosition();
		const panelWidth = getPanelWidth(refs, panelColumnStyle);
		const panelTop = panelPos && panelPos.panelTop ? panelPos.panelTop : undefined;
		const panelHeight = panelPos && panelPos.panelHeight ? panelPos.panelHeight : undefined;

		const styles = this.getMemoizedStyles(
			panelWidth,
			panelTop,
			panelHeight,
			headerWidth,
			contentWidth,
			contextWidth,
		);

		if (styles !== this.state.styles) {
			this.setState({
				styles,
			});
			this.setSidePanelPosition(panelPos);
		}
	};

	getScrollContainer(): HTMLElement | null {
		if (this.props.refs.refSidePanelContainerElement) {
			return this.props.refs.refSidePanelContainerElement;
		}
		if (!this.scrollContainerCached) {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			this.scrollContainerCached = getScrollParent(
				this.refPanelPositioner.current,
				false,
			) as HTMLElement;
		}
		return this.scrollContainerCached;
	}

	getGlanceOffsetTop = (scrollContainer: HTMLElement | null): number | undefined => {
		if (!scrollContainer) return undefined;

		const scrollContainerTop = scrollContainer.getBoundingClientRect().top;
		let issueHeaderBottom = EXTRA_GLANCE_PANEL_OFFSET; // bottom relative to scrollContainer
		const issueHeaderElement =
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.getElementById(ELID_ISSUE_HEADER_ACTIONS) ||
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.getElementById(ELID_ISSUE_HEADER);

		/* On scrolling, header will stay in 'sticky' position, so we need to show panel below the header.
           In modal, scrollContainer is actually just an issue body for modal, so header is outside of scrollContainer there */
		if (issueHeaderElement && scrollContainer.contains(issueHeaderElement)) {
			/**
			 * In case getBoundingClientRect returns incorrect value, during resize,
			 * with changeboarding panel at the top, we can use container top value
			 * as a fallback.
			 */
			const layoutContainer = this.props.refs.refLayoutContainer;
			const layoutContainerTop = layoutContainer ? layoutContainer.getBoundingClientRect().top : 0;

			const headerElementRect = issueHeaderElement.getBoundingClientRect();
			const bottomPosition =
				headerElementRect && headerElementRect.bottom < 0
					? layoutContainerTop + headerElementRect.height
					: headerElementRect.bottom;

			issueHeaderBottom = bottomPosition + EXTRA_GLANCE_PANEL_OFFSET - scrollContainerTop;
		}

		// In IE11 position:sticky of the header becomes position: relative, therefore header can be scrolled to be "above" the viewport
		issueHeaderBottom = Math.max(issueHeaderBottom, EXTRA_GLANCE_PANEL_OFFSET);

		return issueHeaderBottom;
	};

	setSidePanelPosition = (position?: Position): void => {
		if (!position) return;

		const { panelTop, panelHeight } = position;

		if (this.currentPosition.panelTop !== panelTop && this.refFixedPanel.current) {
			this.currentPosition.panelTop = panelTop;
			this.refFixedPanel.current.style.top = panelTop != null ? `${panelTop}px` : ''; // this executes in 0.1-0.16ms instead of 14-17ms for .setState() (which will become bigger the more fields we have in context tab, because it causes extra call of all React lifecycle methods). Faster time is very important for changes on scroll - to achieve smooth scrolling.
		}
		if (this.currentPosition.panelHeight !== panelHeight && this.refPanelPositioner.current) {
			this.currentPosition.panelHeight = panelHeight;
			this.refPanelPositioner.current.style.height = panelHeight != null ? `${panelHeight}px` : '';
		}
	};

	calculateSidePanelPosition = (): Position | undefined => {
		const scrollContainer = this.getScrollContainer();
		// scrollContainerHeight must not include scrollbar height - so that panel is not shown on top of the scrollbar
		const scrollContainerInnerHeight = getInnerHeight(scrollContainer);
		if (scrollContainer === null || scrollContainerInnerHeight === null) {
			return undefined;
		}

		// min(window.innerHeight, ...) is needed e.g. for mobile issue view

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const scrollContainerHeight = Math.min(window.innerHeight, scrollContainerInnerHeight || 0);
		const panelOffsetTop = this.getGlanceOffsetTop(scrollContainer) || 0;

		/*
          Note: because mobile has a page wrapper (id="inner-container") with `transfrom:` applied - ANY child `position: fixed` element (including glance panel) behaves like `position: absolute`.
          https://stackoverflow.com/questions/36855473/position-fixed-not-working-is-working-like-absolute/36857029
        */
		const panelTop = panelOffsetTop + scrollContainer.getBoundingClientRect().top;

		const panelHeight = Math.max(
			scrollContainerHeight - panelTop,
			scrollContainerHeight - panelOffsetTop,
		);

		if (panelHeight <= 0) {
			log.safeErrorWithoutCustomerData(
				LOG_LOCATION,
				`panel height is <= 0: panelHeight='${panelHeight}`,
				new Error('panel height is <= 0'),
			);
		}

		return {
			panelTop,
			panelHeight: panelHeight < 0 ? undefined : panelHeight,
		};
	};

	updateSidePanelPosition = (): number | undefined => {
		// not to calculate on scroll when glance panel is closed for example - not to slow down page scroll, even if it is only for 1-4ms
		if (!this.props.isOpen) return;
		requestAnimationFrame(() => {
			const newPosition = this.calculateSidePanelPosition();
			this.setSidePanelPosition(newPosition);
		});
	};

	getSidePanelListenerNode() {
		const scrollContainer = this.getScrollContainer();
		if (!scrollContainer) {
			return;
		}

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		return scrollContainer === document.body ? document : scrollContainer;
	}

	attachSidePanelListeners(): void {
		this.getSidePanelListenerNode()?.addEventListener('scroll', this.updateSidePanelPosition);
	}

	detachSidePanelListeners(): void {
		this.getSidePanelListenerNode()?.removeEventListener('scroll', this.updateSidePanelPosition);
	}

	render() {
		const { isOpen, isModal, children } = this.props;
		const { styles } = this.state;
		const classyAnimatorWidth = styles.panelWidth
			? styles.panelWidth
			: (styles.contextWidth || 0) + gridSize;
		return (
			<ClassyAnimator ref={this.refPanelPositioner} width={classyAnimatorWidth} show={isOpen}>
				<Container
					data-testid="issue-view-base.context.side-panel.container"
					isModal={isModal}
					ref={this.refFixedPanel}
				>
					<ContainerInner>{children(styles)}</ContainerInner>
				</Container>
			</ClassyAnimator>
		);
	}
}

export const SidePanelRenderOnOpen = ({ isOpen, ...rest }: Props) => {
	const [{ isReady }] = useSpaStatePageReady();
	const isModal = useIsModal();
	// We perform the expensive animation calculations before TTI only on user interaction
	if (!isOpen && !isReady) {
		return null;
	}

	return <SidePanel isOpen={isOpen} isModal={isModal} {...rest} />;
};

export default SidePanelRenderOnOpen;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContainerInner = styled.div({
	display: 'flex',
	flexDirection: 'column',
	height: '100%',
	paddingLeft: token(
		'space.100',
		'8px',
	) /* applying this to Container will cause panel be outside of Modal on initial animation */,
});
