import React, {
	type KeyboardEvent,
	type SyntheticEvent,
	useCallback,
	useRef,
	useState,
} from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { LoadingButton } from '@atlaskit/button';
import ButtonGroup from '@atlaskit/button/button-group';
import Button from '@atlaskit/button/standard-button';
import RetryIcon from '@atlaskit/icon/core/retry';
import { SimpleTag } from '@atlaskit/tag';
import TagGroup from '@atlaskit/tag-group';
import { colors, fontSizeSmall } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import Shortcuts from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcuts/index.tsx';
import { gridSize, fontFamily } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import {
	UPDATE_BRANCH_NAME_FORMAT_TITLE_TEST_ID,
	PREVIEW_TITLE_TEST_ID,
	RESET_BUTTON_TEST_ID,
	CANCEL_BUTTON_TEST_ID,
	SAVE_BUTTON_TEST_ID,
	tags,
	ENTER,
	ENTER_KEY_CODE,
	SAVE_ERROR_MESSAGE_TEST_ID,
	ERROR,
} from '../../../../common/constants.tsx';
import messages from '../../../../common/messages.tsx';
import {
	type UserConfigTemplates,
	PlaceholderEnum,
	BranchTemplateType,
} from '../../../../common/types.tsx';
import { getBranchTemplate } from '../../../../controllers/branch-command-builder-store/actions/index.tsx';
import { useBranchCommandBuilderStore } from '../../../../controllers/branch-command-builder-store/index.tsx';
import ErrorAlert from './error-alert/index.tsx';
import GitCommandPreview from './git-command-preview/index.tsx';
import TemplateInput from './template-input/main.tsx';
import { getGitCommand } from './utils.tsx';

const TagComponent = ({
	text,
	onClick,
	onKeyDown,
	tagId,
}: {
	text: string;
	onClick: () => void;
	onKeyDown: (syntheticEvent: SyntheticEvent) => void;
	tagId: string;
}) => (
	<SimpleTagWrapper
		onClick={onClick}
		tabIndex={0}
		onKeyDown={onKeyDown}
		data-testid={`development-summary-branch.ui.create-branch-dropdown.copy-branch-command.branch-command-builder.tag-component-${tagId}`}
	>
		<SimpleTag
			testId="development-summary-branch.ui.create-branch-dropdown.copy-branch-command.branch-command-builder.simple-tag"
			text={text}
			appearance="rounded"
			color="blueLight"
		/>
	</SimpleTagWrapper>
);

type BranchCommandBuilderProps = {
	setIsBranchConfigOpen: (isBranchConfigOpen: boolean) => void;
};

const BranchCommandBuilder = ({ setIsBranchConfigOpen }: BranchCommandBuilderProps) => {
	const { formatMessage } = useIntl();
	const [isTemplateValid, setIsTemplateValid] = useState(true);
	const [
		{
			readAndWriteTemplates,
			writeOnlyTemplates,
			isSaving,
			saveError,
			originalTemplates,
			branchData,
		},
		{ setDefaultTemplates, insertPlaceholder, saveUserConfigBranchData, setInitialState },
	] = useBranchCommandBuilderStore();
	const { projectKey, issueKey, issueSummary, issueType } = branchData;
	const branchSettings = getBranchTemplate(readAndWriteTemplates);

	const { gitCommand } = getGitCommand(
		branchSettings,
		projectKey,
		issueKey,
		issueSummary,
		issueType,
	);
	const inputRef = useRef<HTMLElement>(null);

	const accountId = useAccountId();

	const getAnalyticsAttributes = (templates: UserConfigTemplates) => {
		const isIssueTitleUsed = templates.some(
			(template) => template.value === PlaceholderEnum.ISSUE_SUMMARY,
		);
		const isIssueTypeUsed = templates.some(
			(template) => template.value === PlaceholderEnum.ISSUE_TYPE,
		);
		const isProjectKeyUsed = templates.some(
			(template) => template.value === PlaceholderEnum.PROJECT_KEY,
		);

		return {
			isIssueTitleUsed,
			isIssueTypeUsed,
			isProjectKeyUsed,
		};
	};

	const handleSave = (syntheticEvent: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
		fireUIAnalytics(
			analyticsEvent,
			'updateBranchNameFormatSaved',
			getAnalyticsAttributes(writeOnlyTemplates),
		);

		saveUserConfigBranchData(accountId, branchSettings).then((res) => {
			res && setIsBranchConfigOpen(false);
		});
	};

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleTagOnInsert = (tagName: PlaceholderEnum, viaKeyboard = false) => {
		const analyticsEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'updateBranchNameFormatLabel',
		});

		fireUIAnalytics(analyticsEvent, { labelName: tagName, viaKeyboard });

		insertPlaceholder(tagName);
	};

	const handleTagOnKeydown = (event: SyntheticEvent, tagName: PlaceholderEnum) => {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const keyboardEvent = event as KeyboardEvent;

		if (keyboardEvent.key === ENTER || keyboardEvent.keyCode === ENTER_KEY_CODE) {
			handleTagOnInsert(tagName, true);
		}
	};

	const handleReset = () => {
		const analyticsEvent = createAnalyticsEvent({
			action: 'reset',
			actionSubject: 'updateBranchNameFormat',
		});

		fireUIAnalytics(analyticsEvent);

		return setDefaultTemplates();
	};

	const handleCancel = (syntheticEvent: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
		setInitialState(originalTemplates);
		fireUIAnalytics(analyticsEvent, 'updateBranchNameFormatCancelled');
		setIsBranchConfigOpen(false);
	};

	const getValidate = useCallback(
		(
			templates: UserConfigTemplates,
			errorMessage: string,
		): { isValid: boolean; errorMessage: string } => {
			const isValid = templates.some(
				(template) =>
					template.value === PlaceholderEnum.ISSUE_KEY &&
					template.type === BranchTemplateType.PLACEHOLDER,
			);

			if (!isValid && isTemplateValid) {
				// Just the first time not valid we fire the event
				const analyticsEvent = createAnalyticsEvent({
					action: 'blocked',
					actionSubject: 'updateBranchNameFormatSaving',
				});

				fireUIAnalytics(analyticsEvent);
			}
			isTemplateValid !== isValid && setIsTemplateValid(isValid);

			return {
				isValid,
				errorMessage,
			};
		},
		[createAnalyticsEvent, isTemplateValid],
	);

	const getValidateCallback = useCallback(
		() => getValidate(readAndWriteTemplates, formatMessage(messages.errorMessage)),
		[getValidate, readAndWriteTemplates, formatMessage],
	);
	return (
		<ShortcutScope>
			<BranchCommandBuilderWrapper data-testid="development-summary-branch.ui.create-branch-dropdown.copy-branch-command.branch-command-builder.title-component">
				<SectionWrapper>
					<SectionHeader>
						<SectionTitle data-testid={UPDATE_BRANCH_NAME_FORMAT_TITLE_TEST_ID}>
							{formatMessage(messages.configSectionTitle)}
						</SectionTitle>
						<ResetButton data-testid={RESET_BUTTON_TEST_ID} onClick={handleReset}>
							<RetryIcon
								label={formatMessage(messages.resetButtonLabel)}
								color={token('color.icon')}
							/>
						</ResetButton>
					</SectionHeader>

					<Shortcuts
						keyMap={
							/* stop event propagation */ {
								// eslint-disable-next-line @typescript-eslint/no-empty-function
								arrowleft: { callback: () => {} },
								// eslint-disable-next-line @typescript-eslint/no-empty-function
								arrowright: { callback: () => {} },
							}
						}
					/>

					<TemplateInput
						templates={writeOnlyTemplates}
						getValidate={getValidateCallback}
						inputRef={inputRef}
					/>

					<ConfigTabsDescription>
						{formatMessage(messages.configTabsDescription)}
					</ConfigTabsDescription>
					<ConfigTabsWrapper data-testid="development-summary-branch.ui.create-branch-dropdown.copy-branch-command.branch-command-builder.config-tabs-wrapper">
						<TagGroup>
							{tags.map((tag) => {
								const message = messages[tag];
								return (
									<TagComponent
										key={tag}
										text={formatMessage(message)}
										onClick={() => handleTagOnInsert(tag)}
										onKeyDown={(e: SyntheticEvent) => handleTagOnKeydown(e, tag)}
										tagId={tag}
									/>
								);
							})}
						</TagGroup>
					</ConfigTabsWrapper>
				</SectionWrapper>

				<Divider />

				<SectionWrapper>
					<SectionTitle data-testid={PREVIEW_TITLE_TEST_ID}>
						{formatMessage(messages.previewSectionTitle)}
					</SectionTitle>
					<GitCommandPreview gitCommand={gitCommand} />
					<ButtonGroupWrapper>
						{saveError && (
							<ErrorAlert
								testId={SAVE_ERROR_MESSAGE_TEST_ID}
								type={ERROR}
								alertMessage={formatMessage(messages.saveError)}
							/>
						)}
						<ButtonGroup>
							<Button appearance="subtle" testId={CANCEL_BUTTON_TEST_ID} onClick={handleCancel}>
								{formatMessage(messages.cancelButton)}
							</Button>

							{isSaving ? (
								<LoadingButton appearance="primary" isLoading>
									{formatMessage(messages.saveButton)}
								</LoadingButton>
							) : (
								<Button
									appearance="primary"
									testId={SAVE_BUTTON_TEST_ID}
									onClick={handleSave}
									isDisabled={!isTemplateValid}
								>
									{formatMessage(messages.saveButton)}
								</Button>
							)}
						</ButtonGroup>
					</ButtonGroupWrapper>
				</SectionWrapper>
			</BranchCommandBuilderWrapper>
		</ShortcutScope>
	);
};

export default BranchCommandBuilder;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BranchCommandBuilderWrapper = styled.div({
	padding: `${token('space.050', '4px')} ${token('space.250', '20px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SectionWrapper = styled.div({
	margin: `${token('space.100', '8px')} 0`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SectionHeader = styled.div({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SectionTitle = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	fontFamily,
	textTransform: 'uppercase',
	fontWeight: token('font.weight.bold'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	fontSize: `${fontSizeSmall}px`,
	margin: `${token('space.100', '8px')} 0`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle', colors.N300),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ResetButton = styled.button({
	border: 'none',
	outline: 'none',
	cursor: 'pointer',
	background: 'transparent',
	padding: 0,
	'&:focus': {
		outline: 'none',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		boxShadow: `0 0 0 2px ${token('color.border.focused', colors.B200)}`,
		borderRadius: '2px',
		border: 0,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ConfigTabsWrapper = styled.div({
	margin: `${token('space.050', '4px')} 0`,
	display: 'flex',
	flexWrap: 'wrap',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& span': {
		cursor: 'pointer',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Divider = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderBottom: `2px solid ${token('color.border', colors.N30)}`,
	margin: `${token('space.200', '16px')} 0`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonGroupWrapper = styled.div({
	display: 'flex',
	justifyContent: 'end',
	margin: `${token('space.100', '8px')} 0`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SimpleTagWrapper = styled.span({
	margin: token('space.050', '4px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > span': {
		margin: 0,
		padding: `0 ${token('space.025', '2px')}`,
	},
	'&:focus': {
		outline: 'none',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		boxShadow: `0 0 0 2px ${token('color.border.focused', colors.B200)}`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		borderRadius: `${gridSize * 2}px`,
		border: 0,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ConfigTabsDescription = styled.p({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	fontSize: `${fontSizeSmall}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle', colors.N300),
});
