const cutoffDateMs = 1535727600000; // 2018-09-01T01:00:00 UTC

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (firstActivationDateMs: number | null): boolean => {
	if (firstActivationDateMs == null) {
		return false;
	}

	return firstActivationDateMs < cutoffDateMs;
};
