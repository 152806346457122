/**
 * @generated SignedSource<<c6ed6f2e80602085b4937748ddaf953d>>
 * @relayHash 524d85ce5dded2d9716dceedb13042f0
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID b143065b2371a01a056e06ca6a0f574cd106056b83483283319b3b7d3d180d58

import type { ConcreteRequest, Query } from 'relay-runtime';
export type utilsUseSingleSelectMutationMissingIssueAndFieldOptionDataQuery$variables = {
  cloudId: string;
  fieldId: string;
  issueKey: string;
  searchBy?: string | null | undefined;
  skipFieldOptionAri: boolean;
};
export type utilsUseSingleSelectMutationMissingIssueAndFieldOptionDataQuery$data = {
  readonly jira: {
    readonly issueByKey: {
      readonly fieldsById?: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly fieldOptions?: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly id: string;
                  readonly optionId: string;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined;
          } | null | undefined;
        } | null | undefined> | null | undefined;
      } | null | undefined;
      readonly issueId: string;
    } | null | undefined;
  } | null | undefined;
};
export type utilsUseSingleSelectMutationMissingIssueAndFieldOptionDataQuery = {
  response: utilsUseSingleSelectMutationMissingIssueAndFieldOptionDataQuery$data;
  variables: utilsUseSingleSelectMutationMissingIssueAndFieldOptionDataQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fieldId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueKey"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchBy"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skipFieldOptionAri"
},
v5 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "kind": "Variable",
    "name": "key",
    "variableName": "issueKey"
  }
],
v6 = {
  "kind": "ScalarField",
  "name": "issueId"
},
v7 = [
  {
    "items": [
      {
        "kind": "Variable",
        "name": "ids.0",
        "variableName": "fieldId"
      }
    ],
    "kind": "ListValue",
    "name": "ids"
  }
],
v8 = {
  "kind": "ScalarField",
  "name": "id"
},
v9 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "searchBy",
          "variableName": "searchBy"
        }
      ],
      "concreteType": "JiraOptionConnection",
      "kind": "LinkedField",
      "name": "fieldOptions",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraOptionEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraOption",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v8/*: any*/),
                {
                  "kind": "ScalarField",
                  "name": "optionId"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraSingleSelectField"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "name": "utilsUseSingleSelectMutationMissingIssueAndFieldOptionDataQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v5/*: any*/),
            "concreteType": "JiraIssue",
            "kind": "LinkedField",
            "name": "issueByKey",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "condition": "skipFieldOptionAri",
                "kind": "Condition",
                "passingValue": false,
                "selections": [
                  {
                    "args": (v7/*: any*/),
                    "concreteType": "JiraIssueFieldConnection",
                    "kind": "LinkedField",
                    "name": "fieldsById",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraIssueFieldEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v9/*: any*/)
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "utilsUseSingleSelectMutationMissingIssueAndFieldOptionDataQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v5/*: any*/),
            "concreteType": "JiraIssue",
            "kind": "LinkedField",
            "name": "issueByKey",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "condition": "skipFieldOptionAri",
                "kind": "Condition",
                "passingValue": false,
                "selections": [
                  {
                    "args": (v7/*: any*/),
                    "concreteType": "JiraIssueFieldConnection",
                    "kind": "LinkedField",
                    "name": "fieldsById",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraIssueFieldEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "__typename"
                              },
                              (v9/*: any*/),
                              (v8/*: any*/)
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              (v8/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "b143065b2371a01a056e06ca6a0f574cd106056b83483283319b3b7d3d180d58",
    "metadata": {},
    "name": "utilsUseSingleSelectMutationMissingIssueAndFieldOptionDataQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "9fecb581bfb367efbee5768f0f78cc7a";

export default node;
