import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noFocusedCommentFoundTitle: {
		id: 'remove-permalink-query.no-focused-comment-found-title',
		defaultMessage: 'The comment you are trying to view does not exist',
		description: 'Title for success message when custom field context is deleted',
	},
	noFocusedCommentFoundDescription: {
		id: 'remove-permalink-query.no-focused-comment-found-description',
		defaultMessage: 'Please refresh the page to see other comments',
		description:
			'Description of the flag shown when the request failed while restoring custom field.',
	},
});
