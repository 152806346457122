import React from 'react';
import { styled } from '@compiled/react';
import { ButtonItem } from '@atlaskit/menu';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	FireUiAnalytics,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';

export type Props = {
	onClick?: () => void;
};

export const CreatePullRequestAuthenticationRow = ({ onClick }: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { formatMessage } = useIntl();
	return (
		<TargetRow>
			<FireUiAnalytics
				actionSubject="link"
				action="viewed"
				actionSubjectId="createPullRequestAuthenticationMessage"
			/>
			<ButtonItem
				onClick={() => {
					fireUIAnalytics(
						createAnalyticsEvent({}),
						'link clicked',
						'createPullRequestAuthenticationMessage',
					);
					onClick && onClick();
				}}
				shouldTitleWrap
				testId="development-summary-pull-request.ui.create-pull-request-summary-item.create-pull-request-dropdown.create-pull-request-authentication-row.button-item"
			>
				{formatMessage(messages.authenticationMessage)}{' '}
				<LinkFormatted>{formatMessage(messages.openDialogPrompt)}</LinkFormatted>
			</ButtonItem>
		</TargetRow>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TargetRow = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `${gridSize * 50}px`,
	marginBottom: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> button': {
		padding: `${token('space.100', '8px')} ${token('space.200', '16px')}`,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LinkFormatted = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.link', colors.B400),
});
