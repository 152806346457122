import type { IssueType } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { type SetIssueTypesAction, SET_ISSUE_TYPES } from '../../actions/issue-types-actions.tsx';
import {
	type FetchIssueSuccessAction,
	FETCH_ISSUE_SUCCESS,
} from '../../common/actions/issue-fetch-actions.tsx';

export type State = IssueType[];
export const initialState: State = [];

type Action = SetIssueTypesAction | FetchIssueSuccessAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: State = initialState, action: Action) => {
	switch (action.type) {
		case FETCH_ISSUE_SUCCESS:
			return action.payload.issueTypes || state || initialState; // || in case of nulls

		case SET_ISSUE_TYPES: {
			return action.payload.map<IssueType>((issueType) => {
				const { id, name, subtask, iconUrl, fields } = issueType;
				return {
					id,
					name,
					subtask,
					iconUrl,
					fields,
				};
			});
		}

		default:
			// prettier-ignore
			// @ts-expect-error - TS2339 - Property 'type' does not exist on type 'never'.
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			(action.type as never);
			return state;
	}
};
