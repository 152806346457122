import {
	FETCH_ISSUE_FAILURE,
	type FetchIssueFailureAction,
} from '../common/actions/issue-fetch-actions.tsx';

type ErrorMessageObject = {
	traceId?: string;
	errorMessage?: string;
	endpoint?: string;
	statusCode?: number;
};

const initialState = null;

// @ts-expect-error - TS2304 - Cannot find name 'FetchIssueFailureDescription'.
const fetchIssueFailerHelper = (payload: FetchIssueFailureDescription): ErrorMessageObject => {
	const { traceId, errorMessage, endpoint, statusCode } = payload;

	const returnObj: Partial<ErrorMessageObject> = {};

	if (traceId !== null) {
		returnObj.traceId = traceId;
	}

	if (errorMessage !== null) {
		returnObj.errorMessage = errorMessage;
	}

	if (endpoint !== null) {
		returnObj.endpoint = endpoint;
	}

	if (statusCode !== null) {
		returnObj.statusCode = statusCode;
	}

	return returnObj;
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	state: ErrorMessageObject | null = initialState,
	action: FetchIssueFailureAction,
): ErrorMessageObject | null => {
	switch (action.type) {
		case FETCH_ISSUE_FAILURE:
			return fetchIssueFailerHelper(action.payload);
		default:
			return state;
	}
};
