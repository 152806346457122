import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	changed: {
		id: 'issue-history.history-items.history-item.assignee-history-item.changed',
		defaultMessage: 'changed the {assignee}',
		description: `Describes that a user has updated the assignee of the issue.
            Part of the heading in History feed, appears after the display name of the user who made the change.
            {assignee} is the 'Assignee' field label.`,
	},
	assignee: {
		id: 'issue-history.history-items.history-item.assignee-history-item.assignee',
		defaultMessage: 'Assignee',
		description: "(noun) Value to display as label for the 'Assignee' field",
	},
	assigneeLowercase: {
		id: 'issue-history.history-items.history-item.assignee-history-item.assignee-lowercase',
		defaultMessage: 'assignee',
		description: "(noun) Value to display as label for the 'assignee' field",
	},
});
