// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noRecentOptionsMessage: {
		id: 'issue.views.confluence-search-view.noOptionsMessage',
		defaultMessage: 'No recently visited pages',
		description: 'Message for no recent options in drop down',
	},
	noResultOptionsMessage: {
		id: 'issue.views.confluence-search-view.noResultOptionsMessage',
		defaultMessage: "We can't find any pages matching your search",
		description: 'Message when search doesnt return any results',
	},
});
