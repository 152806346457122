import { Component } from 'react';
import flatten from 'lodash/flatten';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import {
	type FeatureFlagsPerProvider,
	type Provider,
	type RemoteLinksData,
	fromRemoteLinkProviderId,
	fromRemoteLinkType,
} from '@atlassian/jira-development-common/src/model/releases-data.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';

type Props = {
	actionSubject: string;
	actionSubjectId: string;
	createAnalyticsEvent: (arg1: { action: string; actionSubject: string }) => UIAnalyticsEvent;
	flagsPerProvider: FeatureFlagsPerProvider;
	providers: Provider[];
	remoteLinksData: RemoteLinksData;
};

// eslint-disable-next-line jira/react/no-class-components
export default class AnalyticsView extends Component<Props> {
	componentDidMount() {
		const flagsPerProviderStat = this.calculateFlagsPerProviderStat();
		const totalNumFlags: number = Object.keys(flagsPerProviderStat).reduce(
			(acc, key) => acc + flagsPerProviderStat[key],
			0,
		);
		const totalNumFeatureFlagProviders: number = Object.keys(flagsPerProviderStat).length;

		const deploymentsPerProviderStat = this.calculateDeploymentsPerProviderStat();
		const totalNumDeployments: number = Object.keys(deploymentsPerProviderStat).reduce(
			(acc, key) => acc + deploymentsPerProviderStat[key],
			0,
		);

		const deploymentsPerEnvironmentStat = this.calculateDeploymentsPerEnvironmentStat();

		const totalNumDeploymentProviders: number = Object.keys(deploymentsPerProviderStat).length;

		const { remoteLinksData } = this.props;
		const totalNumRemoteLinks = remoteLinksData.remoteLinks.length;
		const totalNumRemoteLinkProviders = remoteLinksData.providers.length;
		const remoteLinksPerProviderStat = this.calculateRemoteLinksPerProviderStat();
		const remoteLinksPerTypeStat = this.calculateRemoteLinksPerTypeStat();

		if (this.props.createAnalyticsEvent) {
			const flagsPerProviderJson = JSON.stringify(flagsPerProviderStat);
			const deploymentsPerProviderJson = JSON.stringify(deploymentsPerProviderStat);
			const environmentsPerProviderJson = JSON.stringify(deploymentsPerEnvironmentStat);
			const remoteLinksPerProviderJson = JSON.stringify(remoteLinksPerProviderStat);
			const remoteLinksPerTypeJson = JSON.stringify(remoteLinksPerTypeStat);
			fireUIAnalytics(
				this.props.createAnalyticsEvent({
					action: 'rendered',
					actionSubject: this.props.actionSubject,
				}),
				this.props.actionSubjectId,
				{
					totalNumFlags,
					totalNumFeatureFlagProviders,
					flagsPerProvider: flagsPerProviderJson,
					totalNumDeployments,
					totalNumDeploymentProviders,
					totalNumRemoteLinks,
					totalNumRemoteLinkProviders,
					remoteLinksPerProviderStat: remoteLinksPerProviderJson,
					remoteLinksPerTypeStat: remoteLinksPerTypeJson,
					deploymentsPerProviderStat: deploymentsPerProviderJson,
					environmentsPerProviderStat: environmentsPerProviderJson,
				},
			);
		}
	}

	calculateFlagsPerProviderStat(): {
		[key: string]: number;
	} {
		const { flagsPerProvider } = this.props;

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const flagsPerProviderCnt: Record<string, any> = {};
		Object.keys(flagsPerProvider).forEach((key) => {
			flagsPerProviderCnt[key] = flagsPerProvider[key].length;
		});

		return flagsPerProviderCnt;
	}

	calculateDeploymentsPerProviderStat(): {
		[key: string]: number;
	} {
		const { providers } = this.props;

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const deploymentsPerProviderCnt: Record<string, any> = {};

		providers.forEach((provider) => {
			const deployments = flatten(Object.values(provider.environments));
			deploymentsPerProviderCnt[provider.id] = deployments.length;
		});

		return deploymentsPerProviderCnt;
	}

	calculateDeploymentsPerEnvironmentStat(): {
		[key: string]: number;
	} {
		const { providers } = this.props;

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const environmentsPerProviderCnt: Record<string, any> = {};
		providers.forEach((provider) => {
			const { environments } = provider;

			Object.keys(environments).forEach((environmentType) => {
				// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'Partial<Record<"STAGING" | "PRODUCTION" | "DEVELOPMENT" | "TESTING" | "UNMAPPED", DeploymentData[]>>'.
				const deployments = environments[environmentType];
				environmentsPerProviderCnt[environmentType] =
					(environmentsPerProviderCnt[environmentType] || 0) + deployments.length;
			});
		});

		return environmentsPerProviderCnt;
	}

	calculateRemoteLinksPerProviderStat(): {
		[key: string]: number;
	} {
		const { remoteLinksData } = this.props;

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const remoteLinksPerProviderCnt: Record<string, any> = {};

		remoteLinksData.remoteLinks.forEach((link) => {
			remoteLinksPerProviderCnt[fromRemoteLinkProviderId(link.providerId)] =
				(remoteLinksPerProviderCnt[fromRemoteLinkProviderId(link.providerId)] || 0) + 1;
		});

		return remoteLinksPerProviderCnt;
	}

	calculateRemoteLinksPerTypeStat(): {
		[key: string]: number;
	} {
		const { remoteLinksData } = this.props;

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const remoteLinksPerTypeCnt: Record<string, any> = {};

		remoteLinksData.remoteLinks.forEach((link) => {
			remoteLinksPerTypeCnt[fromRemoteLinkType(link.type)] =
				(remoteLinksPerTypeCnt[fromRemoteLinkType(link.type)] || 0) + 1;
		});

		return remoteLinksPerTypeCnt;
	}

	render() {
		return null;
	}
}
