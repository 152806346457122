// This file is temporary and eventually this icon will be in atlaskit

import React from 'react';
import Icon from '@atlaskit/icon';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

const likeIconFilled = (props: { [key: string]: string }) => (
	<svg
		{...props}
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		width="24"
		height="24"
		viewBox="0 0 24 24"
	>
		<defs>
			<path
				id="like-filled-a"
				d="M4.00029867,12.0000031 L4.00029867,19.0000031 C3.99379083,19.2619469 4.0938648,19.5154845 4.27800285,19.7035645 C4.46214091,19.8916445 4.71487577,19.9984685 4.97933941,20.0000031 L5.99875297,20.0000031 L5.99875297,11.0000031 L4.97933941,11.0000031 C4.71487577,11.0015345 4.46214091,11.1083586 4.27800285,11.2964386 C4.0938648,11.4845186 3.99379083,11.7380562 4.00029867,12.0000031 Z M19.2814088,11.0400031 C18.7031035,10.3648275 17.8537454,9.97663317 16.959972,9.97900342 L14.3256459,9.97900342 C14.3660187,9.79800348 14.4063915,9.61900354 14.4366711,9.4470036 C14.9514245,6.5130046 14.4366711,5.44700497 13.9320109,4.85300517 C13.4705845,4.31076256 12.7907146,3.99849428 12.0748615,4 C10.5305357,4.05274499 9.2630356,5.22781051 9.10745966,6.75100452 C8.71382472,8.59000389 8.6532655,8.75100384 8.13851212,9.47600359 L7.37142865,10.5650032 C7.13702441,10.900368 7.01037109,11.2980817 7.00807332,11.7060028 L7.00807332,17.9790007 C7.0094101,18.5107601 7.22390249,19.0202137 7.60436426,19.3952867 C7.98482603,19.7703597 8.50009148,19.9803277 9.03680723,19.9790426 L16.2837274,19.9790426 C17.7690249,19.9867984 19.0398298,18.9240081 19.2814088,17.4720009 L19.9576535,13.4720022 C20.1064636,12.6021375 19.8588502,11.7116379 19.2814088,11.0400031 Z"
			/>
		</defs>
		<g fillRule="evenodd">
			<use fill="currentColor" xlinkHref="#like-filled-a" />
			<path
				fill="inherit"
				d="M17.2829545,17.140001 C17.2013522,17.6232979 16.7783039,17.9768163 16.2837274,17.9750077 L9.03680723,17.9750077 L9.03680723,11.7060028 L9.8038907,10.6170032 C10.5252697,9.572607 10.9727451,8.36664508 11.105914,7.1080044 C11.1422788,6.56547046 11.3606605,5.89943888 12.0748615,6.00000478 C12.7890625,6.10057068 12.6501741,7.91600412 12.4382168,9.10000372 C12.2228565,10.0810361 11.9326853,11.0444672 11.5702013,11.9820027 L16.959972,11.9740027 C17.2574249,11.9729101 17.5402185,12.1018688 17.7328364,12.3264423 C17.9254544,12.5510157 18.0082813,12.848334 17.9591992,13.1390023 L17.2829545,17.140001 Z"
			/>
		</g>
	</svg>
);

type Props = {
	size: 'small' | 'medium' | 'large' | 'xlarge';
	label: string;
	secondaryColor?: string;
};

const LikeIconFilled = ({
	size,
	label,
	secondaryColor = token('color.icon.subtle', colors.N700),
}: Props) => (
	// @ts-expect-error - TS2322 - Type '(props: { [key: string]: string; }) => JSX.Element' is not assignable to type 'ComponentType<CustomGlyphProps> | undefined'.
	<Icon glyph={likeIconFilled} size={size} label={label} secondaryColor={secondaryColor} />
);

export default LikeIconFilled;
