import { useFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';

type Options = {
	issueKey: IssueKey;
	fieldKey: string;
};

export const useReadOnlyField = ({ issueKey, fieldKey }: Options) => {
	// useFieldConfig now returns an object { value, loading } instead of just the fieldConfig value
	// if possible when touching this, try and refactor to pass the new object instead of just the value
	const [{ value: fieldConfig }] = useFieldConfig(issueKey, fieldKey);

	const [value] = useFieldValue({
		fieldKey,
		issueKey,
	});

	return [{ value, fieldConfig }, {}] as const;
};
