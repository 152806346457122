import type { SyntheticEvent, MouseEvent } from 'react';
import { statusCategories } from '@atlassian/jira-common-constants/src/index.tsx';

export const preventDefault = (e: SyntheticEvent<HTMLElement>) => e.preventDefault();
export const stopPropagation = (e: SyntheticEvent<HTMLElement>) => e.stopPropagation();
const isLeftClick = (event: MouseEvent): boolean => event.button === 0;
export const isLeftClickOnly = (event: MouseEvent): boolean =>
	isLeftClick(event) && !(event.metaKey || event.ctrlKey);

const { DONE } = statusCategories;
export const shouldDisplayStrikethrough = (
	isResolved: boolean | undefined,
	statusCategory: string | undefined,
) => {
	if (isResolved !== undefined) {
		return isResolved;
	}

	// In JSW, the strikethrough must now only depend on resolution. However we have to keep
	// this logic because there is a consumer of issue-line-card in JSM that displays
	// the strikethrough based on status src/packages/servicedesk/post-incident-review/src/ui/primary-incident/index.tsx
	return statusCategory === DONE;
};
