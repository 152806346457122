import type { State as IssueState } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { createStore, createHook } from '@atlassian/react-sweet-state';
import type { State, StoreApi } from './types.tsx';
import { isValidIssueData } from './utils.tsx';

const deleteStaleIssues =
	() =>
	({ setState, getState }: StoreApi) => {
		const state = getState();
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		Object.entries(state).forEach(([issueKey, issueData]: [any, any]) => {
			if (!isValidIssueData(issueData)) {
				setState({
					[issueKey]: undefined,
				});
			}
		});
	};

export const setRecentIssue =
	(issueKey: IssueKey, issueState: IssueState) =>
	({ setState, dispatch }: StoreApi) => {
		dispatch(deleteStaleIssues());
		setState({
			[String(issueKey)]: {
				issueState,
				savedAt: Date.now(),
			},
		});
	};

export const getRecentIssue =
	(issueKey: IssueKey) =>
	({ getState, dispatch }: StoreApi) => {
		dispatch(deleteStaleIssues());
		const state = getState();
		const issueData = state[issueKey];
		return isValidIssueData(issueData) ? issueData?.issueState : undefined;
	};

export const getRecentIssuesCount =
	() =>
	({ getState }: StoreApi) => {
		const state = getState();
		const recentIssuesCount = Object.values(state).filter(Boolean).length;
		return recentIssuesCount;
	};

const actions = { setRecentIssue, getRecentIssue, getRecentIssuesCount } as const;

export type Actions = typeof actions;

// this is a global state
export const store = createStore<State, Actions>({
	name: 'recent-issues',
	initialState: {},
	actions,
});

export const useRecentIssuesStore = createHook(store, {
	selector: null,
});
