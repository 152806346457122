import React from 'react';
import { ManualRulesEmptyState as ManualRulesEmptyStateAfe } from '@atlassian/automation-manual-triggers';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';

interface ManualRulesEmptyStateProps {
	baseAutomationUrl: string;
	createAutomationComponentFilter?: string;
	canManageAutomations: boolean;
}

export const ManualRulesEmptyState = (props: ManualRulesEmptyStateProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { baseAutomationUrl, createAutomationComponentFilter, canManageAutomations } = props;

	return (
		<ManualRulesEmptyStateAfe
			canManageAutomations={canManageAutomations}
			baseAutomationUrl={baseAutomationUrl}
			createAutomationComponentFilter={createAutomationComponentFilter}
			onEmptyStateCreateAutomationButtonClicked={() => {
				const analyticsEvent = createAnalyticsEvent({
					actionSubject: 'button',
					action: 'clicked',
				});
				fireUIAnalytics(
					analyticsEvent,
					'manualRulesActionsMenu.emptyState.createAutomationButton',
					{ userType: 'admin' },
				);
			}}
			onEmptyStateSeeTemplatesLinkClicked={() => {
				const analyticsEvent = createAnalyticsEvent({
					actionSubject: 'button',
					action: 'clicked',
				});
				fireUIAnalytics(analyticsEvent, 'manualRulesActionsMenu.emptyState.seeTemplatesLink', {
					userType: 'admin',
				});
			}}
		/>
	);
};
