import React from 'react';
import { Stack } from '@atlaskit/primitives';
import type { HeaderProps } from '@atlassian/jira-command-palette/src/common/types/commands/index.tsx';
import { components } from '@atlassian/jira-command-palette/src/common/ui/components/index.tsx';
import { IssueTag, type IssueTagProps } from '../issue-tag/index.tsx';

export const CommandFieldHeader = ({ children, issueKey }: HeaderProps & IssueTagProps) => (
	<components.Header>
		<Stack space="space.200">
			<IssueTag issueKey={issueKey} />
			{children}
		</Stack>
	</components.Header>
);
