import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { IssuesRemote } from '@atlassian/jira-polaris-remote-issue/src/controllers/types.tsx';
import { defaultJiraUpdateService } from '../common/index.tsx';
import { NUMBER } from '../constants.tsx';
import type { JiraFieldMapping } from '../types.tsx';

export const jiraNumberMapping = (
	issuesRemote: IssuesRemote,
	field: Field,
): JiraFieldMapping<number | undefined> => ({
	field,
	getValueFromJiraIssue: (issue) => {
		if (issue.fields[field.key] !== null && issue.fields[field.key] !== undefined) {
			return issue.fields[field.key];
		}
		if (field.type === FIELD_TYPES.RATING || field.type === FIELD_TYPES.CHECKBOX) {
			return 0;
		}
		return undefined;
	},
	getFieldValueForJiraUpdate: (value) => (value !== undefined ? value : null),
	getFieldValueForJiraBulkUpdate: (value) => ({
		[NUMBER]: {
			fieldId: field.key,
			value: value !== undefined ? value : null,
		},
	}),
	isMultiValueField: false,
	isSupportedByIssueUpdateApi: true,
	...defaultJiraUpdateService(issuesRemote, field.key),
});
