import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import {
	ClassificationLozenge,
	type ClassificationOption,
	type Color,
} from '@atlassian/data-classification-level';
import type { editViewPopup_issueClassificationBadge_ClassificationEditViewPopup$data as ClassificationEditViewPopupData } from '@atlassian/jira-relay/src/__generated__/editViewPopup_issueClassificationBadge_ClassificationEditViewPopup.graphql';
import type { ClassificationSelectOption } from './types.tsx';

export const transformClassificationsToAggClassificationSuggestions = (
	classifications: ClassificationSelectOption[] | undefined | null,
): ClassificationEditViewPopupData['classificationLevels'] =>
	classifications
		? {
				edges: classifications.map((classification: ClassificationSelectOption) => ({
					node: {
						id: classification.id,
						name: classification.name,
						definition: classification.definition,
						color: { colorKey: classification.color },
					},
				})),
			}
		: classifications;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OverflowWrapper = styled.span({
	overflow: 'hidden',
});
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ProjectDefaultTextWrapper = styled.span({
	position: 'relative',
	top: token('space.negative.025', '-2px'),
});

export const getProjectDefaultClassificationOption = (
	message: string,
	defaultClassificationLevel:
		| ClassificationOption
		| ClassificationEditViewPopupData['defaultClassificationLevel'],
): ClassificationOption => {
	const color =
		typeof defaultClassificationLevel?.color === 'string'
			? defaultClassificationLevel?.color
			: defaultClassificationLevel?.color?.colorKey;
	return {
		id: undefined,
		nameFormatter: (classification: ClassificationOption) => (
			<OverflowWrapper>
				<ProjectDefaultTextWrapper>{message} - </ProjectDefaultTextWrapper>
				<ClassificationLozenge {...classification} name={defaultClassificationLevel?.name || ''} />
			</OverflowWrapper>
		),
		// The below lint error is because Color type expects string, whereas the graphQL type expects { colorKey: string }. This has been handled in variable definition for color.
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		color: color as Color,
		definition: '',
	};
};
