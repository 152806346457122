// DEPRECATED - use jira-issue-parent-services package
import { Observable, type Observable as ObservableType } from 'rxjs/Observable';
import 'rxjs/add/observable/throw';
import type { Issue } from '@atlassian/jira-assign-issue-parent-modal/src/model/types.tsx';
import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';
import type { BaseUrl, IssueId } from '@atlassian/jira-shared-types/src/general.tsx';

export const mapParentIdByIssueId = (issues: Issue[]) =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	issues.reduce<Record<string, any>>(
		(
			mapping: {
				[key: string]: number;
			},
			issue: Issue,
		) => ({
			// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
			...mapping,
			// @ts-expect-error - TS2464 - A computed property name must be of type 'string', 'number', 'symbol', or 'any'.
			[issue.id]: issue.parentId,
		}),
		{},
	);

const getIssueSetParentUrl = (baseUrl: BaseUrl, projectId: number, parentId: IssueId) =>
	`${baseUrl}/rest/internal/simplified/1.0/projects/${projectId}/issues/${parentId}/children`;

const getIssueDeleteParentUrl = (baseUrl: BaseUrl, projectId: number) =>
	`${baseUrl}/rest/internal/simplified/1.0/projects/${projectId}/issues/delete-parents`;

type Payload = {
	issueIds: IssueId[];
};

export const setIssueParent = (
	baseUrl: BaseUrl,
	projectId: number,
	parentId: IssueId,
	payload: Payload,
): ObservableType<void> =>
	projectId
		? fetchJson$(getIssueSetParentUrl(baseUrl, projectId, parentId), {
				method: 'POST',
				body: JSON.stringify(payload),
			})
		: Observable.throw('Missing Project Id');

export const deleteIssueParent = (
	baseUrl: BaseUrl,
	projectId: number,
	payload: Payload,
): ObservableType<void> =>
	projectId
		? fetchJson$(getIssueDeleteParentUrl(baseUrl, projectId), {
				method: 'POST',
				body: JSON.stringify(payload),
			})
		: Observable.throw('Missing Project Id');
