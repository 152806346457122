import 'rxjs/add/observable/of';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import isEqual from 'lodash/isEqual';
import { Observable } from 'rxjs/Observable';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import type { ChildIssue } from '@atlassian/jira-issue-view-common-types/src/children-issues-type.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import transformServerChild from '../../common/transform-server-child/index.tsx';
import fetchChildren from '../../services/fetch-children/index.tsx';
import reorderChildrenAfter from '../../services/reorder-children-after/index.tsx';
import reorderChildrenBefore from '../../services/reorder-children-before/index.tsx';
import { getBaseUrl, getParentId, getCustomFieldIdRank } from '../../state/context/selectors.tsx';
import {
	reorderChildrenSuccess,
	reorderChildrenFailed,
	fetchDetailsForIssuesRequest,
	type ReorderChildrenRequestPayload,
} from '../../state/entities/actions.tsx';
import type { State } from '../../state/types.tsx';

type OnFailureProps = {
	error: Error;
	currentOrder: ChildIssue[];
	optimisticOrder: ChildIssue[];
	currentIndex: number;
	newIndex: number;
};

const onFailure = ({
	error,
	currentOrder,
	optimisticOrder,
	currentIndex,
	newIndex,
}: OnFailureProps) => {
	log.safeErrorWithoutCustomerData(
		'issue.views.common.child-issues-panel.reorder-children',
		'Failed to reorder children',
		error,
	);

	return Observable.of(
		reorderChildrenFailed(currentOrder, optimisticOrder, currentIndex, newIndex),
	);
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	state: State,
	reorderActionPayload: ReorderChildrenRequestPayload,
	reorderActionMeta: {
		analyticsEvent: UIAnalyticsEvent;
	},
) => {
	const baseUrl = getBaseUrl(state);
	const parentId = getParentId(state);
	const customFieldId = getCustomFieldIdRank(state);

	const { currentOrder, newOrder: optimisticOrder, currentIndex, newIndex } = reorderActionPayload;

	if (!parentId) {
		return onFailure({
			error: new Error('Attempted to reorder children without parentId'),
			currentOrder,
			optimisticOrder,
			currentIndex,
			newIndex,
		});
	}

	if (!customFieldId) {
		return onFailure({
			error: new Error('Attempted to reorder children without customFieldId'),
			currentOrder,
			optimisticOrder,
			currentIndex,
			newIndex,
		});
	}

	const { analyticsEvent } = reorderActionMeta;

	const { id: issueId } = currentOrder[currentIndex];
	const issueIds = issueId ? [+issueId] : [];
	const isRankAfter = newIndex > currentIndex;
	const rankId = Number((optimisticOrder[isRankAfter ? newIndex - 1 : newIndex + 1] || {}).id);

	if (!rankId) {
		return onFailure({
			error: new Error('Attempted to reorder children without rankId'),
			currentOrder,
			optimisticOrder,
			currentIndex,
			newIndex,
		});
	}

	const reorderChildren$ = isRankAfter
		? reorderChildrenAfter(baseUrl, customFieldId, issueIds, rankId)
		: reorderChildrenBefore(baseUrl, customFieldId, issueIds, rankId);

	return reorderChildren$
		.switchMap(() => fetchChildren(baseUrl, parentId))
		.flatMap((serverSubtasks = []) => {
			const serverOrderIds = serverSubtasks.map(({ id }) => id);
			const optimisticOrderIds = optimisticOrder.map(({ id }) => id);

			fireTrackAnalytics(analyticsEvent, 'issueRank updated', {
				numIssues: 1,
				previousIssueIndex: currentIndex,
				currentIssueIndex: newIndex,
				isRankAfter,
			});

			// Server order matches what we are showing, return success action (but this will not change state)
			if (isEqual(serverOrderIds, optimisticOrderIds)) {
				return Observable.of(reorderChildrenSuccess(optimisticOrder));
			}

			// Issues are the same, just different order, update our order to match what is returned by the server
			if (isEqual([...serverOrderIds].sort(), [...optimisticOrderIds].sort())) {
				// @ts-expect-error - TS2322 - Type '(ChildIssue | undefined)[]' is not assignable to type 'ChildIssue[]'.
				const differentOrder: ChildIssue[] = serverOrderIds.map((id) =>
					optimisticOrder.find((issue) => issue.id === id),
				);

				return Observable.of(reorderChildrenSuccess(differentOrder));
			}

			// Server has a different set of issues, update what we have
			const newOrder = serverSubtasks.map((serverIssue) => {
				const { id } = serverIssue;
				const existingIssue = optimisticOrder.find((issue) => issue.id === id) || {};

				return {
					...existingIssue, // use existing data (can have more than serverIssue)
					...transformServerChild(serverIssue, baseUrl), // add serverIssue in case of new issue
				};
			});

			return Observable.of(
				reorderChildrenSuccess(newOrder), // update UI with new issue order
				// @ts-expect-error - TS2769 - No overload matches this call.
				fetchDetailsForIssuesRequest(), // re-fetch data for issues, new issue might have assignee
			);
		})
		.catch((error) =>
			onFailure({
				error,
				currentOrder,
				optimisticOrder,
				currentIndex,
				newIndex,
			}),
		);
};
