import { useMemo } from 'react';
import type { IssueViewStaticContext } from '@atlassian/jira-forge-ui-types/src/common/types/conditions/issue-view.tsx';
import { useIssueId, useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import { ISSUE_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import {
	useProjectId,
	useProjectKey,
	useProjectType,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import { useProjectPermissions } from '@atlassian/jira-project-permissions-service/src/main.tsx';

export const useIssueViewStaticContext = (): IssueViewStaticContext => {
	const issueKey = useIssueKey();
	const issueId = useIssueId();
	const projectKey = useProjectKey(issueKey);
	const projectId = useProjectId(projectKey);
	const projectType = useProjectType(projectKey);
	const [issueType] = useFieldValue({
		issueKey,
		fieldKey: ISSUE_TYPE,
	});
	const [permissions] = useProjectPermissions(projectKey);

	return useMemo(
		() => ({
			issueKey,
			issueId: issueId || null,
			issueType: issueType?.name || null,
			projectId: projectId || null,
			projectKey,
			projectType: projectType || null,
			canAddComments: permissions.canAddComments,
			canAdministerJira: permissions.canAdministerJira,
			canAdministerProjects: permissions.canAdministerProject,
			canAssignIssues: permissions.canAssignIssues,
			canBeAssignedToIssues: permissions.canBeAssignedToIssues,
			canCloneIssues: permissions.canCloneIssue,
			canCreateAttachments: permissions.canCreateAttachments,
			canCreateChildren: permissions.canCreateChildren,
			canCreateSubtasks: permissions.canCreateSubtask,
			canDeleteAllAttachments: permissions.canDeleteAllAttachments,
			canDeleteAllComments: permissions.canDeleteAllComments,
			canDeleteAllWorklogs: permissions.canDeleteAllWorklogs,
			canDeleteIssues: permissions.canDeleteIssue,
			canDeleteOwnAttachments: permissions.canDeleteOwnAttachments,
			canDeleteOwnComments: permissions.canDeleteOwnComments,
			canDeleteOwnWorklogs: permissions.canDeleteOwnWorklogs,
			canEditAllComments: permissions.canEditAllComments,
			canEditAllWorklogs: permissions.canEditAllWorklogs,
			canEditIssues: permissions.canEditIssues,
			canEditOwnComments: permissions.canEditOwnComments,
			canEditOwnWorklogs: permissions.canEditOwnWorklogs,
			canLinkIssues: permissions.canLinkIssues,
			canLogWork: permissions.canLogWork,
			canManageWatchers: permissions.canManageWatchers,
			canModifyReporters: permissions.canModifyReporter,
			canMoveIssues: permissions.canMoveIssue,
			canScheduleIssues: permissions.canScheduleIssues,
			canUseServiceManagementAgentFeatures: permissions.canUseServiceDeskAgentFeatures,
			canBrowseUsers: permissions.canBrowseUsers,
			canViewDevTools: permissions.canViewDevTools,
			canViewWatchers: permissions.canViewWatchers,
		}),
		[
			issueId,
			issueKey,
			issueType,
			projectId,
			projectKey,
			projectType,
			permissions.canAddComments,
			permissions.canAdministerJira,
			permissions.canAdministerProject,
			permissions.canAssignIssues,
			permissions.canBeAssignedToIssues,
			permissions.canCloneIssue,
			permissions.canCreateAttachments,
			permissions.canCreateChildren,
			permissions.canCreateSubtask,
			permissions.canDeleteAllAttachments,
			permissions.canDeleteAllComments,
			permissions.canDeleteAllWorklogs,
			permissions.canDeleteIssue,
			permissions.canDeleteOwnAttachments,
			permissions.canDeleteOwnComments,
			permissions.canDeleteOwnWorklogs,
			permissions.canEditAllComments,
			permissions.canEditAllWorklogs,
			permissions.canEditIssues,
			permissions.canEditOwnComments,
			permissions.canEditOwnWorklogs,
			permissions.canLinkIssues,
			permissions.canLogWork,
			permissions.canManageWatchers,
			permissions.canModifyReporter,
			permissions.canMoveIssue,
			permissions.canScheduleIssues,
			permissions.canUseServiceDeskAgentFeatures,
			permissions.canBrowseUsers,
			permissions.canViewDevTools,
			permissions.canViewWatchers,
		],
	);
};
