export const LOAD_START_MARK_KEY = 'ISSUE_RESTRICTIONS_LOAD_START_MARK_KEY';
// FETCH_ERROR and UPDATE_ERROR to show different error message for fetch and update API
export const FETCH_ERROR = 'FETCH_ERROR' as const;
export const UPDATE_ERROR = 'UPDATE_ERROR' as const;

// Analytics

// Screen Analytics
export const RESTRICT_ISSUE_LOCK_BTN = 'restrictIssueLockButton';
export const RESTRICT_ISSUE_TYPE_LOCK_BTN = 'restrictIssueTypeLockButton';
export const FETCH_RESTRICTIONS_ERROR_ISSUE_VIEW = 'fetchRestrictionsErrorIssueView';
export const FETCH_RESTRICTIONS_ERROR_ISSUE_TYPE = 'fetchRestrictionsErrorIssueType';
export const UPDATE_RESTRICTIONS_ERROR_ISSUE_VIEW = 'updateRestrictionsErrorIssueView';
export const UPDATE_RESTRICTIONS_ERROR_ISSUE_TYPE = 'updateRestrictionsErrorIssueType';
export const RESTRICT_ISSUE = 'restrictIssue';
export const RESTRICT_ISSUE_TYPE = 'restrictIssueType';
export const ISSUE_RESTRICTIONS_UPFLOW = 'issueRestrictionsUpflow';

// UI Analytics
export const RESTRICT_ISSUE_LOCK_BTN_UI = 'restrictIssueLockButton';
export const RESTRICT_ISSUE_TYPE_LOCK_BTN_UI = 'restrictIssueTypeLockButton';
export const APPLY_ISSUE_RESTRICTION_BUTTON_UI = 'applyIssueRestrictionButton';
export const APPLY_ISSUE_TYPE_RESTRICTION_BUTTON_UI = 'applyIssueTypeRestrictionButton';
export const APPLY_ISSUE_RESTRICTION_CANCEL_BUTTON_UI = 'applyIssueRestrictionCancelButton';
export const APPLY_ISSUE_TYPE_RESTRICTION_CANCEL_BUTTON_UI =
	'applyIssueTypeRestrictionCancelButton';
export const FREE_ISSUE_TOUCHPOINT_ID = 'projectSettingsIssueRestrictions';
export const FREE_ISSUE_TYPE_TOUCHPOINT_ID = 'projectSettingsIssueTypeRestrictions';

// error Analytics

export const UPDATE_RESTRICTION_ISSUE_TYPE_ERROR = 'updateRestrictionsIssueTypeService';
export const UPDATE_RESTRICTION_ISSUE_ERROR = 'updateRestrictionsIssueService';

export const FETCH_RESTRICTION_ISSUE_TYPE_ERROR = 'fetchRestrictionsIssueTypeService';
export const FETCH_RESTRICTION_ISSUE_ERROR = 'fetchRestrictionsIssueService';

export const ISSUE_TYPE_ISSUE_RESTRICTIONS_ARTICLE_ID = '4bxuXus1tIdOVkhCHELmbn';
export const LEARN_MORE_LINK =
	'https://support.atlassian.com/jira-software-cloud/docs/next-gen-permissions';

// Package
export const PACKAGE_NAME = 'projectSettingsIssuePermissions';
