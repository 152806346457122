// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	placeholder: {
		id: 'issue.single-version-picker.text-for-dropdown-when-nothing-selected',
		defaultMessage: 'Select version',
		description: '',
	},
	recent: {
		id: 'issue.fix-versions.create-new-component-message.recent',
		defaultMessage: 'Recent versions',
		description: 'Header displayed in versions list used to mark recently selected items',
	},
	releasedHeading: {
		id: 'issue.fix-versions.released-message',
		defaultMessage: 'Released',
		description: 'Header displayed in versions list used to mark released items',
	},
	unreleasedHeading: {
		id: 'issue.fix-versions.unreleased-message',
		defaultMessage: 'Unreleased',
		description: 'Header displayed in versions list used to mark unreleased items',
	},
});
