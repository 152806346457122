import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	summaryItemTitle: {
		id: 'development-summary-vulnerability.summary-item-title',
		defaultMessage: `{count, plural,
                    =0 {no vulnerabilities}
                    one {{styledCount} vulnerability}
                    other {{styledCount} vulnerabilities}
                }`,
		description:
			'The summary item title indicating the number of vulnerabilities associated with a given issue.',
	},
	summaryItemOneClickTooltip: {
		id: 'development-summary-vulnerability.summary-item-one-click-tooltip',
		defaultMessage: 'Open vulnerability',
		description:
			'The tooltip describing the click action for the summary item when one vulnerability is associated with a given issue.',
	},
	summaryItemDropdownTriggerIconLabel: {
		id: 'development-summary-vulnerability.summary-item-dropdown-trigger-icon-label',
		defaultMessage: 'Show more',
		description:
			'The icon label describing the action for the summary item dropdown button when multiple vulnerabilities are associated with a given issue.',
	},
});
