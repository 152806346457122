import React from 'react';
import type { CustomGlyphProps } from '@atlaskit/icon';

export const upvoteGlyph = (props: CustomGlyphProps) => (
	<svg
		{...props}
		width="16"
		height="10"
		viewBox="0 0 16 10"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M15.5749 7.42198L9.00169 0.444771H9.00026C8.73188 0.159985 8.36793 0 7.98845 0C7.60896 0 7.24502 0.159985 6.97664 0.444771L0.404905 7.42198C0.143634 7.70498 -0.00186729 8.08598 1.81198e-05 8.48221C0.00190353 8.87843 0.151024 9.25786 0.414975 9.53803C0.678926 9.81821 1.03638 9.9765 1.40966 9.9785L7.98845 9.99998H14.576C14.7633 10.001 14.9489 9.96258 15.1221 9.88697C15.2953 9.81137 15.4526 9.70007 15.585 9.55952C15.7174 9.41896 15.8223 9.25194 15.8935 9.0681C15.9647 8.88427 16.0009 8.68727 16 8.4885C15.999 8.28973 15.961 8.09313 15.888 7.91006C15.815 7.727 15.7086 7.56111 15.5749 7.42198Z"
			fill="currentColor"
		/>
	</svg>
);
