/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { type KeyboardEventHandler, useCallback } from 'react';

import { jsx } from '@emotion/react'; // eslint-disable-line @atlaskit/ui-styling-standard/use-compiled
import { useIntl } from 'react-intl-next';

import { IconButton } from '@atlaskit/button/new';
import { INPUT_METHOD } from '@atlaskit/editor-common/analytics';
import { useSharedPluginState } from '@atlaskit/editor-common/hooks';
import type { PublicPluginAPI } from '@atlaskit/editor-common/types';
import type { MentionsPlugin } from '@atlaskit/editor-plugins/mentions';
import MentionIcon from '@atlaskit/icon/core/mention';

import messages from './messages';

type Props = {
	editorApi?: PublicPluginAPI<[MentionsPlugin]>;
};

export const MentionButton = ({ editorApi }: Props) => {
	const { formatMessage } = useIntl();

	const { typeAheadState, mentionState } = useSharedPluginState(editorApi, [
		'mention',
		'typeAhead',
	]);

	const handleClick = useCallback(() => {
		if (editorApi) {
			editorApi.mention?.actions.openTypeAhead(INPUT_METHOD.QUICK_INSERT);
		}
	}, [editorApi]);

	// The editor can be wrapped in a component that process keyboard events,
	// but we don't need to propagate `Enter`, `Space`, and `Tab` key event to the editor.
	const stopPropagationForActionKeys: KeyboardEventHandler = useCallback((event) => {
		if (['Enter', ' ', 'Tab'].includes(event.key)) {
			event.stopPropagation();
		}
	}, []);

	return (
		<IconButton
			isTooltipDisabled={false}
			label={formatMessage(messages.label)}
			testId="mention-button"
			icon={MentionIcon}
			isSelected={
				typeAheadState?.currentHandler?.id &&
				mentionState?.typeAheadHandler.id &&
				typeAheadState.currentHandler.id === mentionState.typeAheadHandler.id
			}
			spacing="compact"
			appearance="subtle"
			onClick={handleClick}
			onKeyDown={stopPropagationForActionKeys}
			onKeyUp={stopPropagationForActionKeys}
		/>
	);
};
