import type { ProjectType } from '@atlassian/jira-common-constants/src/index.tsx';
import type {
	SelectValueShape,
	Option as SelectOption,
} from '@atlassian/jira-issue-internal-field-select/src/common/select-inline-edit/select-field/types.tsx';
import type { SelectableValueOption } from '@atlassian/jira-issue-selectable-field-edit-view/src/ui/types.tsx';
import type { Version } from '@atlassian/jira-issue-shared-types/src/common/types/issue-type.tsx';
import type { ProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { getVersionUrl } from './utils.tsx';

type ReleaseVersion = { ari: string } & Version;

export const transformFromStateValue = (
	value: ReleaseVersion[],
	{
		projectKey,
		projectType,
		fieldId,
	}: {
		projectKey: ProjectKey;
		projectType?: ProjectType;
		fieldId: string;
	},
): SelectValueShape[] =>
	value
		? value.map((version) => ({
				value: version.id,
				content: version.name || '',
				href: getVersionUrl({ version, fieldId, projectKey, projectType }),
				ari: version.ari,
			}))
		: [];

export const transformFromStateValueSingleSelect = (
	value: ReleaseVersion,
	{
		projectKey,
		projectType,
		fieldId,
	}: {
		projectKey: ProjectKey;
		projectType?: ProjectType;
		fieldId: string;
	},
): SelectValueShape[] =>
	value
		? [
				{
					value: value.id,
					content: value.name || '',
					href: getVersionUrl({ version: value, fieldId, projectKey, projectType }),
					ari: value.ari,
				},
			]
		: [];

export const transformToStateValue = (value: SelectValueShape[] | null): Version[] | null =>
	value &&
	value.map((item) => ({
		name: item.content,
		id: String(item.value),
		ari: item.ari,
	}));

export const transformToStateValueSingleSelect = (
	value: SelectValueShape[] | null,
): Version | null =>
	value && value.length === 1
		? { name: value[0].content, id: String(value[0].value), ari: value[0].ari }
		: null;

export const transformRelayToReduxValue = (option: SelectableValueOption): SelectOption => {
	return {
		value: option.versionId ?? '',
		label: option.selectableLabel ?? '',
		content: option.selectableLabel ?? '',
		ari: option.id,
	};
};

/**
 * Transforms a redux value to a relay selectable value.
 * @param option
 * @returns a SelectableValueOption type.
 */
export const transformReduxToRelayValue = (option: SelectValueShape): SelectableValueOption => ({
	versionId: String(option.value),
	selectableLabel: option.content || '',
	selectableGroupKey: null,
	selectableIconUrl: null,
	id: option.ari || '',
});
