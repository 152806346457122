// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	contentPartOneProductRequirementsTemplateReminderNudge: {
		id: 'confluence-placeholder-template-product-requirements.modal-content-part-one',
		defaultMessage: 'Create and attach requirements to your issue, without leaving Jira. ',
		description:
			'Part one of the Content text for Product Requirements template of the issue view placeholder reminder xflow nudge experiment for confluence',
	},
	contentPartOneKnownErrorsTemplateReminderNudge: {
		id: 'confluence-placeholder-template-known-errors.modal-content-part-one',
		defaultMessage: 'Create and attach known errors to your issue, without leaving Jira. ',
		description:
			'Part one of the Content text for for Known Errors template of the issue view placeholder reminder xflow nudge experiment for confluence',
	},
	contentPartOneProjectPlanTemplateReminderNudge: {
		id: 'confluence-placeholder-template-project-plan.modal-content-part-one',
		defaultMessage: 'Create and attach a plan to your issue, without leaving Jira. ',
		description:
			'Part one of the Content text for Project Plan template of the issue view placeholder reminder xflow nudge experiment for confluence',
	},
	contentPartTwoReminderNudge: {
		id: 'confluence-placeholder-template-known-errors.modal-content-part-two',
		defaultMessage: 'Finish signing up for your free trial of Confluence.',
		description:
			'Part two of the Content text for the issue view placeholder reminder xflow nudge experiment for confluence',
	},
	tryNowButtonReminderNudge: {
		id: 'issue-view.placeholder-reminder-xflow-nudge-experiment.try-now',
		defaultMessage: 'Try now',
		description:
			'Try now button text for the issue view placeholder reminder xflow nudge experiment for confluence',
	},
	dismissButtonReminderNudge: {
		id: 'issue-view.placeholder-reminder-xflow-nudge-experiment.dismiss',
		defaultMessage: 'Dismiss',
		description:
			'Dismiss button text for the issue view placeholder reminder xflow nudge experiment for confluence',
	},
	contentPartOneProductRequirementsTemplateReminderNudgeIssueTermRefresh: {
		id: 'confluence-placeholder-template-product-requirements.modal-content-part-one-issue-term-refresh',
		defaultMessage: 'Create and attach requirements to your issue, without leaving Jira. ',
		description:
			'Part one of the Content text for Product Requirements template of the issue view placeholder reminder xflow nudge experiment for confluence',
	},
	contentPartOneKnownErrorsTemplateReminderNudgeIssueTermRefresh: {
		id: 'confluence-placeholder-template-known-errors.modal-content-part-one-issue-term-refresh',
		defaultMessage: 'Create and attach known errors to your issue, without leaving Jira. ',
		description:
			'Part one of the Content text for for Known Errors template of the issue view placeholder reminder xflow nudge experiment for confluence',
	},
	contentPartOneProjectPlanTemplateReminderNudgeIssueTermRefresh: {
		id: 'confluence-placeholder-template-project-plan.modal-content-part-one-issue-term-refresh',
		defaultMessage: 'Create and attach a plan to your issue, without leaving Jira. ',
		description:
			'Part one of the Content text for Project Plan template of the issue view placeholder reminder xflow nudge experiment for confluence',
	},
});
