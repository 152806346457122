import React, { useEffect, useMemo } from 'react';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { useLoomVideoEditorInserter } from '@atlassian/jira-loom-utils/src/controllers/use-loom-video-editor-inserter/index.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { issueEditorEntrypoint } from './entrypoint.tsx';
import type { OwnProps } from './index.tsx';

const entryPointParams = {};

const IssueEditor = (props: OwnProps) => {
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		issueEditorEntrypoint,
		entryPointParams,
	);

	const runtimeProps = useMemo(() => props, [props]);

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		entryPointActions.load();
	}, [entryPointActions]);

	return (
		<JiraEntryPointContainer
			entryPointReferenceSubject={entryPointReferenceSubject}
			id="lazyIssueEditor"
			packageName="issueViewCommon"
			errorFallback="unmount"
			teamName="team-bento"
			runtimeProps={runtimeProps}
		/>
	);
};

const IssueEditorWithLoomEmbedder = (props: OwnProps) => {
	const { editorApiRef } = useLoomVideoEditorInserter({
		entryPointLocation: 'jsmIssueView_customerReply_crossflow',
	});

	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		issueEditorEntrypoint,
		entryPointParams,
	);

	const runtimeProps = useMemo(() => {
		return {
			...props,
			editorApiRef,
		};
	}, [editorApiRef, props]);

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		entryPointActions.load();
	}, [entryPointActions]);

	return (
		<JiraEntryPointContainer
			entryPointReferenceSubject={entryPointReferenceSubject}
			id="lazyIssueEditor"
			packageName="issueViewCommon"
			errorFallback="unmount"
			teamName="team-bento"
			runtimeProps={runtimeProps}
		/>
	);
};

export const LazyIssueEditor = componentWithCondition(
	() => {
		const [experimentConfigJSM] = UNSAFE_noExposureExp('loom_jsm_agent_response_touchpoint');
		return experimentConfigJSM.get('cohort', 'not-enrolled') === 'variation';
	},
	IssueEditorWithLoomEmbedder,
	IssueEditor,
);
