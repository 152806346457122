import type { Epic } from 'redux-observable';
import initiateStore from '@atlassian/jira-common-tangerine/src/state/initiate.tsx';
import rootReducer from './reducer.tsx';
import type { Action, State } from './types.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (rootEpic: Epic<Action, State>, parentKey: string, panelType: string) =>
	initiateStore<State, Action, undefined>({
		appName: `${parentKey}-child-issues-panel-${panelType}`,
		rootReducer,
		rootEpic,
	});
