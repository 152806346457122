// eslint-disable-next-line jira/restricted/react-component-props
import React, { type ComponentType, type ComponentProps } from 'react';
import CoordinationClient from '@atlassian/jira-engagement/src/ui/coordination-client/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type { FlagContainerMajorIncident } from './new-flag/index.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const NewMajorIncChangeBoardingFlagContainer = lazyForPaint<
	ComponentType<
		JSX.LibraryManagedAttributes<
			typeof FlagContainerMajorIncident,
			ComponentProps<typeof FlagContainerMajorIncident>
		>
	>
>(
	() =>
		import(
			/* webpackChunkName: "async-major-inc-new-changeboarding-flag" */ './new-flag/index'
		).then((module) => module.FlagContainerMajorIncident),
	{ ssr: false },
);

export const AsyncMajorIncChangeBoardingFlagContainer = () => (
	<Placeholder name="major-inc-new-changeboarding-flag" fallback={null}>
		<CoordinationClient messageId="jsm-major-incident-changeboarding-flag">
			<NewMajorIncChangeBoardingFlagContainer />
		</CoordinationClient>
	</Placeholder>
);
