// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

// FIXME: JIV-15410 cyclic dependency gremlins. This file is a duplicate of `src/packages/issue/issue-actions/activity-feed/src/messages.tsx`

export const messages = defineMessages({
	allActivity: {
		id: 'issue-activity-feed.all-activity',
		defaultMessage: 'All',
		description: 'Display the all activity feed tab for the activity feed',
	},
	comments: {
		id: 'issue-activity-feed.comments',
		defaultMessage: 'Comments',
		description: 'Display the comments for the activity feed',
	},
	history: {
		id: 'issue-activity-feed.history',
		defaultMessage: 'History',
		description: 'Display the history for the activity feed',
	},
	worklog: {
		id: 'issue-activity-feed.worklog',
		defaultMessage: 'Work log',
		description: 'Display the work log for the activity feed',
	},
	activity: {
		id: 'issue-activity-feed.activity',
		defaultMessage: 'Activity',
		description: 'A general name for the activity feed (used when no other options are available)',
	},
	approvals: {
		id: 'issue-activity-feed.approvals',
		defaultMessage: 'Approvals',
		description: 'Display the JSM Approvals for the activity feed',
	},
	filterBy: {
		id: 'issue-activity-feed.filter-by',
		defaultMessage: 'Show:',
		description: 'Prompt the user to show the selected activity feed results',
	},
});
