import type { Flag } from '@atlassian/jira-flags';
import messages from './messages.tsx';

export const ERROR_FLAG_ID = 'OPEN_GOAL_PICKER_ERROR_FLAG';
export const POPUP_ENTRYPOINT_ERROR_FLAG: Flag = {
	messageType: 'transactional',
	id: ERROR_FLAG_ID,
	type: 'error',
	title: messages.errorFlagTitle,
	description: messages.errorFlagDescription,
	isAutoDismiss: true,
};
