// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	changeType: {
		id: 'issue.details.change-issue-type',
		defaultMessage: '{issueType} - Change issue type',
		description: '',
	},
	changeTypeGroupName: {
		id: 'issue.details.change-issue-type-groupname',
		defaultMessage: 'Change Issue Type',
		description: '',
	},
	changeTypeIssueTermRefresh: {
		id: 'issue.details.change-issue-type-issue-term-refresh',
		defaultMessage: '{issueType} - Change work type',
		description: '',
	},
	changeTypeGroupNameIssueTermRefresh: {
		id: 'issue.details.change-issue-type-groupname-issue-term-refresh',
		defaultMessage: 'Change Work Type',
		description: '',
	},
});
