import { createSelector } from 'reselect';
import {
	type AnalyticsSource,
	CORE_ISSUE,
	FULL_ISSUE,
	SERVICEDESK_QUEUES_ISSUE,
	SERVICEDESK_REPORTS_ISSUE,
	SOFTWARE_BACKLOG_ISSUE,
	SOFTWARE_ISSUE,
} from '@atlassian/jira-common-constants/src/analytics-sources.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import isFirstActivationDateBeforeCutoff from '@atlassian/jira-issue-view-common-utils/src/utils/is-first-activation-date-before-cutoff.tsx';
import { analyticsSourceSelector } from '../common/state/selectors/context-selector.tsx';
import {
	isSimplifiedProjectSelector,
	uiSelector,
} from '../common/state/selectors/issue-selector.tsx';
import { getChangeboardingBannerSeenTimes } from './changeboarding-local-storage.tsx';
import { openedGlanceSelector } from './ecosystem-glance-ui-selector.tsx';
import { isNewIssueViewLockedInSelector } from './ui-config-selector.tsx';

const changeboardingUiSelector = createSelector(uiSelector, (ui) => ui.changeboarding);

/** sources that show the welcome modal */
const analyticsSourceChangeboardingWhitelist: AnalyticsSource[] = [
	CORE_ISSUE,
	FULL_ISSUE,
	SOFTWARE_ISSUE,
	SOFTWARE_BACKLOG_ISSUE,
];

/** sources that show "take a tour" in the dropdown  */
export const analyticsSourceTakeTourEnabledList: AnalyticsSource[] = [
	FULL_ISSUE,
	SERVICEDESK_QUEUES_ISSUE,
	SERVICEDESK_REPORTS_ISSUE,
];

export const shouldShowChangeboardingSelector = createSelector(
	analyticsSourceSelector,
	isNewIssueViewLockedInSelector,
	isSimplifiedProjectSelector,
	(analyticsSource, isNewIssueViewLockedIn, isSimplified) =>
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		analyticsSourceChangeboardingWhitelist.includes(analyticsSource as AnalyticsSource) &&
		!isNewIssueViewLockedIn &&
		!isSimplified,
);

export const shouldShowTourSelector = createSelector(
	analyticsSourceSelector,
	isNewIssueViewLockedInSelector,
	isSimplifiedProjectSelector,
	(analyticsSource) =>
		Boolean(
			analyticsSource &&
				[
					...analyticsSourceTakeTourEnabledList,
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					...[CORE_ISSUE as AnalyticsSource],
				] // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					.includes(analyticsSource as AnalyticsSource),
		),
);

const hasSeenBannerLessTimesThanThresholdSelector = () => getChangeboardingBannerSeenTimes() < 7;
const isBannerEnabledForInstanceSelector = (state: State) => {
	const { firstActivationDateMs } = state.context;
	return isFirstActivationDateBeforeCutoff(firstActivationDateMs);
};

export const shouldShowChangeboardingBannerSelector = createSelector(
	shouldShowChangeboardingSelector,
	changeboardingUiSelector,
	isBannerEnabledForInstanceSelector,
	hasSeenBannerLessTimesThanThresholdSelector,
	openedGlanceSelector,
	isNewIssueViewLockedInSelector,
	(
		shouldShowChangeboarding,
		changeboardingUi,
		isBannerEnabledForInstance,
		hasSeenBannerLessTimesThanThreshold,
		openedGlance,
		isNewIssueViewLockedIn,
	) => {
		const shouldShowChangeBoardingBanner =
			shouldShowChangeboarding &&
			changeboardingUi.isBannerVisible &&
			isBannerEnabledForInstance &&
			hasSeenBannerLessTimesThanThreshold &&
			!openedGlance;

		return shouldShowChangeBoardingBanner && !isNewIssueViewLockedIn;
	},
);

export const isTourActive = createSelector(
	changeboardingUiSelector,
	(changeboardingUi) => changeboardingUi.isTourActive,
);

export const isBannerDismissed = createSelector(
	changeboardingUiSelector,
	(changeboardingUi) => changeboardingUi.isBannerDismissed,
);
