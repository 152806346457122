import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	bambooDeploymentIcon: {
		id: 'software-releases-version-detail-issue-list.issues.issue-card.delivery.bamboo-deployment-data.bamboo-deployment-icon',
		defaultMessage: 'Bamboo deployment icon',
		description: 'Icon label for Bamboo deployments',
	},
	deployedEnvironments: {
		id: 'software-releases-version-detail-issue-list.issues.issue-card.delivery.bamboo-deployment-data.deployed-environments',
		defaultMessage:
			'{hasHiddenEnvironments, select, false {Deployed to {displayedEnvironments}} other {Deployed to {displayedEnvironments} and {othersCount, number} {othersCount, plural, one {other} other {others}}}}',
		description: 'Message to display where there are Bamboo deployments',
	},
});
