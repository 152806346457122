import React from 'react';
import { styled } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import ShortcutIcon from '@atlaskit/icon/glyph/shortcut';
import { LinkItem } from '@atlaskit/menu';
import { token } from '@atlaskit/tokens';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import type { CreatePullRequestTarget } from '@atlassian/jira-development-summary-common/src/common/types.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	fireUIAnalytics,
	useAnalyticsEvents,
	ContextualAnalyticsData,
} from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';

export const getTargetDisplayName = (target: CreatePullRequestTarget) =>
	`${target.repositoryName}/${target.branchName}`;

export type Props = {
	createPullRequestTarget: CreatePullRequestTarget;
};

export const CreatePullRequestTargetRow = ({ createPullRequestTarget }: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	return (
		<ContextualAnalyticsData>
			<TargetRow
				key={createPullRequestTarget.createPullRequestUrl}
				data-testid="development-summary-pull-request.ui.create-pull-request-summary-item.create-pull-request-dropdown.create-pull-request-target-row.target-row"
			>
				<LinkItem
					iconBefore={
						<AvatarWrapper>
							<Avatar
								// @ts-expect-error - TS2322 - Type 'string | null | undefined' is not assignable to type 'string | undefined'.
								src={createPullRequestTarget.repositoryAvatarUrl}
								appearance="square"
								size="xsmall"
							/>
						</AvatarWrapper>
					}
					iconAfter={
						<IconContainer data-component-selector="pull-request-icon-after">
							<ShortcutIcon label={formatMessage(messages.shortcutIconLabel)} size="small" />
						</IconContainer>
					}
					href={createPullRequestTarget.createPullRequestUrl}
					target="_blank"
					onClick={() => {
						fireUIAnalytics(createAnalyticsEvent({}), 'link clicked', 'createPullRequestLink', {
							scmProvider: createPullRequestTarget.scmProvider,
						});
					}}
					testId="development-summary-pull-request.ui.create-pull-request-summary-item.create-pull-request-dropdown.create-pull-request-target-row.link-item"
				>
					{getTargetDisplayName(createPullRequestTarget)}
				</LinkItem>
			</TargetRow>
		</ContextualAnalyticsData>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconContainer = styled.span({
	opacity: 0,
	transition: 'opacity 0.3s ease-out',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TargetRow = styled.div({
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `${gridSize * 50}px`,

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	a: {
		padding: `0px ${token('space.200', '16px')}`,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&:hover [data-component-selector="pull-request-icon-after"]': {
		opacity: 1,
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:not(:last-child)': {
		marginBottom: token('space.100', '8px'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AvatarWrapper = styled.span({
	display: 'flex',
});
