import {
  JiraIssuefieldvalueAriResourceOwner,
  JiraIssuefieldvalueAriResourceType
} from "./chunk-ZNA5VEMQ.mjs";
import {
  RegisteredAri
} from "./chunk-YG2E6ZVD.mjs";
import {
  AriParser
} from "./chunk-Q7FABYST.mjs";

// src/jira/issuefieldvalue/manifest.ts
var jiraIssuefieldvalueAriStaticOpts = {
  qualifier: "ari",
  platformQualifier: "cloud",
  cloudId: new RegExp("^[a-zA-Z0-9_\\-.]{1,255}$"),
  // eslint-disable-line no-useless-escape
  resourceOwner: JiraIssuefieldvalueAriResourceOwner,
  resourceType: JiraIssuefieldvalueAriResourceType,
  resourceIdSlug: "{issueId}/{fieldId}",
  resourceIdSegmentFormats: {
    issueId: /[0-9]+/,
    // eslint-disable-line no-useless-escape
    fieldId: /[a-zA-Z0-9_\-]+/
    // eslint-disable-line no-useless-escape
  }
};

// src/jira/issuefieldvalue/index.ts
var JiraIssuefieldvalueAri = class _JiraIssuefieldvalueAri extends RegisteredAri {
  constructor(opts) {
    super(opts);
    this._siteId = opts.cloudId || "", this._issueId = opts.resourceIdSegmentValues.issueId, this._fieldId = opts.resourceIdSegmentValues.fieldId;
  }
  get siteId() {
    return this._siteId;
  }
  get issueId() {
    return this._issueId;
  }
  get fieldId() {
    return this._fieldId;
  }
  static create(opts) {
    let derivedOpts = {
      qualifier: jiraIssuefieldvalueAriStaticOpts.qualifier,
      platformQualifier: jiraIssuefieldvalueAriStaticOpts.platformQualifier,
      cloudId: opts.siteId,
      resourceOwner: jiraIssuefieldvalueAriStaticOpts.resourceOwner,
      resourceType: jiraIssuefieldvalueAriStaticOpts.resourceType,
      resourceId: `${opts.issueId}/${opts.fieldId}`,
      resourceIdSegmentValues: {
        issueId: opts.issueId,
        fieldId: opts.fieldId
      }
    }, ariOpts = AriParser.fromOpts(derivedOpts, jiraIssuefieldvalueAriStaticOpts);
    return new _JiraIssuefieldvalueAri(ariOpts);
  }
  static parse(maybeAri) {
    let opts = AriParser.fromString(maybeAri, jiraIssuefieldvalueAriStaticOpts);
    return new _JiraIssuefieldvalueAri(opts);
  }
  getVariables() {
    return {
      siteId: this.siteId,
      issueId: this.issueId,
      fieldId: this.fieldId
    };
  }
};

export {
  JiraIssuefieldvalueAri
};
