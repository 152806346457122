export type FieldScopes = {
	granular: string[];
	coarse: string[];
};

export const baseScopesRequiredPerField: FieldScopes = {
	granular: [
		'read:field:jira',
		'write:field:jira',
		'read:field-configuration:jira',
		'write:field-configuration:jira',
	],
	coarse: ['read:jira-work', 'write:jira-work', 'manage:jira-configuration'],
};
