/**
 * @generated SignedSource<<7287ed73acf98d4887f9b4a9f2b15d3f>>
 * @relayHash d4d017a9817eae9b09cdc9b98c60cda1
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 9f00656421a631887a9d3a4e023e44dcb718bd03d3b8f87fd8311d90738c0686

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type useWhiteboardIssueLinkingIssueToWhiteboardCreateMutation$variables = {
  issueAri: string;
  whiteboardAri: string;
};
export type useWhiteboardIssueLinkingIssueToWhiteboardCreateMutation$data = {
  readonly graphStore: {
    readonly createIssueToWhiteboard: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type useWhiteboardIssueLinkingIssueToWhiteboardCreateMutation = {
  response: useWhiteboardIssueLinkingIssueToWhiteboardCreateMutation$data;
  variables: useWhiteboardIssueLinkingIssueToWhiteboardCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "issueAri"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "whiteboardAri"
  }
],
v1 = [
  {
    "concreteType": "GraphStoreMutation",
    "kind": "LinkedField",
    "name": "graphStore",
    "plural": false,
    "selections": [
      {
        "args": [
          {
            "fields": [
              {
                "items": [
                  {
                    "fields": [
                      {
                        "kind": "Variable",
                        "name": "from",
                        "variableName": "issueAri"
                      },
                      {
                        "kind": "Variable",
                        "name": "to",
                        "variableName": "whiteboardAri"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "relationships.0"
                  }
                ],
                "kind": "ListValue",
                "name": "relationships"
              }
            ],
            "kind": "ObjectValue",
            "name": "input"
          }
        ],
        "concreteType": "GraphStoreCreateIssueToWhiteboardPayload",
        "kind": "LinkedField",
        "name": "createIssueToWhiteboard",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "name": "success"
          },
          {
            "concreteType": "MutationError",
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "message"
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "useWhiteboardIssueLinkingIssueToWhiteboardCreateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useWhiteboardIssueLinkingIssueToWhiteboardCreateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "9f00656421a631887a9d3a4e023e44dcb718bd03d3b8f87fd8311d90738c0686",
    "metadata": {},
    "name": "useWhiteboardIssueLinkingIssueToWhiteboardCreateMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "8aa5adc11d7ea6f5f25f31cea1c6a9ca";

export default node;
