import React from 'react';
import { styled } from '@compiled/react';
import { defineMessages } from '@atlassian/jira-intl';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import SquareAddIconButton from '@atlassian/jira-issue-view-common-views/src/button/square-add-icon-button.tsx';

// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

const messages = defineMessages({
	label: {
		id: 'issue.confluence-links-add-label',
		defaultMessage: 'Link a Confluence Page',
		description: 'Link a Confluence Page',
	},
});

type Props = {
	onClick: () => void;
};

export const AddButton = (props: Props) => {
	const { formatMessage } = useIntl();
	return (
		<RightSideContainer>
			<SquareAddIconButton label={formatMessage(messages.label)} onClick={props.onClick} />
		</RightSideContainer>
	);
};

export default AddButton;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RightSideContainer = styled.span({
	marginLeft: 'auto',
});
