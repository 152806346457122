import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	copy: {
		id: 'issue-get-design-updates-content.header.copy',
		defaultMessage: 'Highlight when designs have changed since you last saw them',
		description: 'Heading for the popup to ask admin for getting design updates',
	},
	copyForAdmin: {
		id: 'issue-get-design-updates-content.header.copy-for-admin',
		defaultMessage: 'Configure your Figma teams to get design status updates',
		description: 'Heading for the popup to get design updates',
	},
	closeIcon: {
		id: 'issue-get-design-updates-content.header.close-icon',
		defaultMessage: 'Close icon',
		description: 'Icon to close popup',
	},
});
