/**
 * @generated SignedSource<<5b669f790319d988e8ec7ac77249ad62>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraTimeFormat = "DAYS" | "HOURS" | "PRETTY" | "%future added value";
export type JiraTimeUnit = "DAY" | "HOUR" | "MINUTE" | "WEEK" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type originalEstimate_issueFieldOriginalEstimateEditviewFull_OriginalEstimateEditView$data = {
  readonly defaultFormat: JiraTimeFormat | null | undefined;
  readonly defaultUnit: JiraTimeUnit | null | undefined;
  readonly workingDaysPerWeek: number | null | undefined;
  readonly workingHoursPerDay: number | null | undefined;
  readonly " $fragmentType": "originalEstimate_issueFieldOriginalEstimateEditviewFull_OriginalEstimateEditView";
};
export type originalEstimate_issueFieldOriginalEstimateEditviewFull_OriginalEstimateEditView$key = {
  readonly " $data"?: originalEstimate_issueFieldOriginalEstimateEditviewFull_OriginalEstimateEditView$data;
  readonly " $fragmentSpreads": FragmentRefs<"originalEstimate_issueFieldOriginalEstimateEditviewFull_OriginalEstimateEditView">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "originalEstimate_issueFieldOriginalEstimateEditviewFull_OriginalEstimateEditView",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "workingHoursPerDay"
    },
    {
      "kind": "ScalarField",
      "name": "workingDaysPerWeek"
    },
    {
      "kind": "ScalarField",
      "name": "defaultFormat"
    },
    {
      "kind": "ScalarField",
      "name": "defaultUnit"
    }
  ],
  "type": "JiraTimeTrackingSettings"
};

(node as any).hash = "6e0b8f7b6db8fad6cdba55c3be7b02d1";

export default node;
