import { createSelector } from 'reselect';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { getCurrentView } from '../current/index.tsx';

export const getCurrentViewTableColumnSizes = createSelector(
	getCurrentView,
	(view) => view?.tableColumnSizes ?? [],
);

export const getCurrentViewTableColumnSizesByFieldKey = createSelector(
	getCurrentViewTableColumnSizes,
	(sizes) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const map: Record<string, any> = {};

		sizes.forEach(({ fieldKey, size }) => {
			map[fieldKey] = size;
		});

		return map;
	},
);

export const getCurrentViewTableColumnSize = (fieldKey?: FieldKey) =>
	createSelector(
		getCurrentViewTableColumnSizes,
		(sizes) => sizes.find((col) => col.fieldKey === fieldKey)?.size,
	);
