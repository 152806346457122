import React from 'react';
import memoizeOne from 'memoize-one';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import UserOptionView from '@atlassian/jira-issue-internal-field-select/src/common/custom-format/user-option/index.tsx';
import type { FormatOptionLabelMeta } from '@atlassian/jira-issue-internal-field-select/src/common/select-inline-edit/select-field/index.tsx';
import MultiSelectInlineEditView from '@atlassian/jira-issue-internal-field-select/src/multi-select-inline-edit/index.tsx';
import { genericMessages } from '@atlassian/jira-issue-view-common-constants/src/context-items-messages.tsx';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button/index.tsx';
import connectField from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-field.tsx';
import { fieldAutoCompleteUrlSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector.tsx';
import messages from './messages.tsx';
import {
	transformFromStateValue,
	transformToStateValue,
	transformSuggestions,
} from './transformer.tsx';

export const fetchSuggestionsFactory = memoizeOne((autoCompleteUrl) => (query: string) => {
	if (!autoCompleteUrl) {
		return Promise.resolve([]);
	}
	return fetchJson(`${autoCompleteUrl}${query}`).then(transformSuggestions);
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const analyticsAttributeIdRetriever = (value: any): string =>
	value && Array.isArray(value)
		? value.map((participant) => participant.accountId).join(',')
		: value;

type FormatOption = {
	email: string;
	label: string;
	avatarUrl: string;
};
export const formatOptionLabel = (option: FormatOption, meta: FormatOptionLabelMeta) => (
	<UserOptionView
		avatarUrl={option.avatarUrl}
		email={option.email}
		label={option.label}
		context={meta.context}
	/>
);

const filterOption = () => true;

const multiUserSelectFilterOption = filterOption;
export default connectField((stateOnMount, ownPropsOnMount) => {
	const fieldAutoCompleteUrlSelectorGetter = fieldAutoCompleteUrlSelector(ownPropsOnMount.fieldId);
	return {
		fieldId: ownPropsOnMount.fieldId,
		transformFromStateValue,
		transformToStateValue,
		additionalProps: (state, intl) => ({
			fetchSuggestions: fetchSuggestionsFactory(fieldAutoCompleteUrlSelectorGetter(state)),
			placeholder: intl.formatMessage(messages.placeholder),
			noValueText: intl.formatMessage(genericMessages.noValue),
			tagAppearance: 'rounded',
			analyticsAttributeIdRetriever,
			showPinButton: getShowPinButton(ownPropsOnMount.area),
			formatOptionLabel,
			filterOption: multiUserSelectFilterOption,
		}),
	};
})(MultiSelectInlineEditView);
