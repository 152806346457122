import React from 'react';

import { token } from '@atlaskit/tokens';

import { RainbowBorder } from './RainbowBorder';
import type { IconProps } from './types';

export const Icon = (props: IconProps) => {
	const { shownAt } = props;

	return (
		<RainbowBorder shownAt={shownAt}>
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill={token('color.icon', '#44546F')}
				xmlns="http://www.w3.org/2000/svg"
			>
				<path d="M15 6H9C8.44772 6 8 6.44772 8 7C8 7.55228 8.44772 8 9 8H15C15.5523 8 16 7.55228 16 7C16 6.44772 15.5523 6 15 6Z" />
				<path d="M15 9H9C8.44772 9 8 9.44772 8 10C8 10.5523 8.44772 11 9 11H15C15.5523 11 16 10.5523 16 10C16 9.44772 15.5523 9 15 9Z" />
				<path d="M11 12H9C8.44772 12 8 12.4477 8 13C8 13.5523 8.44772 14 9 14H11C11.5523 14 12 13.5523 12 13C12 12.4477 11.5523 12 11 12Z" />
				<path d="M7 4V20H17V4H7ZM5 3.99C5 2.892 5.897 2 7.006 2H16.994C18.102 2 19 2.898 19 3.99V20.01C19 21.109 18.103 22 16.994 22H7.006C6.4765 22.0008 5.96822 21.7919 5.59231 21.419C5.21639 21.0461 5.00344 20.5395 5 20.01V3.99Z" />
			</svg>
		</RainbowBorder>
	);
};
