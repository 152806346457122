/**
 * @generated SignedSource<<58fbf642f3a1353fbc3ff32ef5c4f6be>>
 * @relayHash c1ad0b37dbc9b3978488001c34902c42
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID b2ae82fd1a8471f7e1f28351bfb63e37614ed98239861e1b2d82ea4b5680f7a4

import type { ConcreteRequest, Mutation } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraVotesOperations = "ADD" | "REMOVE" | "%future added value";
export type JiraUpdateVotesFieldInput = {
  id: string;
  operation: JiraVotesFieldOperationInput;
};
export type JiraVotesFieldOperationInput = {
  operation: JiraVotesOperations;
};
export type voteFieldServiceRelay_issue_field_Mutation$variables = {
  input: JiraUpdateVotesFieldInput;
};
export type voteFieldServiceRelay_issue_field_Mutation$data = {
  readonly jira: {
    readonly updateVotesField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly " $fragmentSpreads": FragmentRefs<"voteFieldServiceRelay_issueFieldVoters_UseVoteFieldRelayGenerator">;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type voteFieldServiceRelay_issue_field_Mutation$rawResponse = {
  readonly jira: {
    readonly updateVotesField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly fieldId: string;
        readonly id: string;
        readonly type: string;
        readonly vote: {
          readonly count: AGG$Long | null | undefined;
          readonly hasVoted: boolean | null | undefined;
        } | null | undefined;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type voteFieldServiceRelay_issue_field_Mutation = {
  rawResponse: voteFieldServiceRelay_issue_field_Mutation$rawResponse;
  response: voteFieldServiceRelay_issue_field_Mutation$data;
  variables: voteFieldServiceRelay_issue_field_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "success"
},
v3 = {
  "concreteType": "MutationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "message"
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "voteFieldServiceRelay_issue_field_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraVotesFieldPayload",
            "kind": "LinkedField",
            "name": "updateVotesField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraVotesField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  {
                    "kind": "FragmentSpread",
                    "name": "voteFieldServiceRelay_issueFieldVoters_UseVoteFieldRelayGenerator"
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "voteFieldServiceRelay_issue_field_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraVotesFieldPayload",
            "kind": "LinkedField",
            "name": "updateVotesField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraVotesField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "fieldId"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "type"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "id"
                  },
                  {
                    "concreteType": "JiraVote",
                    "kind": "LinkedField",
                    "name": "vote",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "count"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "hasVoted"
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "b2ae82fd1a8471f7e1f28351bfb63e37614ed98239861e1b2d82ea4b5680f7a4",
    "metadata": {},
    "name": "voteFieldServiceRelay_issue_field_Mutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "1364dd0c3233a52225dbce87bfff88c1";

export default node;
