import type { JiraAutofixStatus } from '@atlassian/jira-relay/src/__generated__/uiGetAutofixJobsQuery.graphql';

export type JobType = 'INTERNAL_REMOVE_FEATURE_FLAG';

export type JobStatus = JiraAutofixStatus;

type FFCleanupBaseError = {
	errorType?: string | null;
	errorMessage?: string | null;
};

type BitbucketPermError = FFCleanupBaseError & {
	repoName: string;
	repoUrl: string;
};

export type FFCleanupError = BitbucketPermError | FFCleanupBaseError;

export const isBitbucketPermError = (error: FFCleanupError): error is BitbucketPermError =>
	'repoName' in error && 'repoUrl' in error;
