/**
 * Endpoint used to request field recommendations from the Collaboration Graph API
 *
 * See documentation for more details: {@link https://developer.atlassian.com/platform/collaboration-graph/rest/api-group-collaboration-graph-recommendations-api/#api-v2-recommend-fields-post}
 */
export const RECOMMENDATIONS_URL = '/gateway/api/collaboration/v2/recommend/fields';

/**
 * Logging location used for recommendations-related errors/warnings
 */
export const RECOMMENDATIONS_LOCATION = 'smarts.fields.ranking';

/**
 * Duration allowed for the recommendations request to run before timing out
 */
export const RECOMMENDATIONS_TIMEOUT = 5000;
