import React from 'react';
import { AIOpsSendUnifiedAIEvent, fireAIOpsEvent } from '@atlassian/eoc-aiops-instrumentation';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { MountEvent } from '@atlassian/jira-product-analytics-bridge';

export const SendUnifiedEvents = ({ error }: { error: unknown }) => {
	return (
		<>
			<AIOpsSendUnifiedAIEvent
				onMount={(event, createAIPayload) => {
					fireAIOpsEvent(
						event,
						createAIPayload({
							type: 'initiated',
							isProactive: true,
						}),
					);
				}}
			/>
			{error ? (
				<>
					<AIOpsSendUnifiedAIEvent
						onMount={(event, createAIPayload) => {
							fireAIOpsEvent(
								event,
								createAIPayload({
									type: 'error',
									isProactive: true,
									status: error instanceof FetchError ? error.statusCode : undefined,
								}),
							);
						}}
					/>
					<MountEvent
						onMount={() => {
							fireErrorAnalytics({
								meta: {
									id: 'AIOpsContextPanel',
									packageName: 'jiraServicedeskAIContextOpsPanel',
									teamName: 'itsm-aiops-grogu',
								},
								attributes: {
									status: error instanceof FetchError ? error.statusCode : undefined,
								},
								error: error instanceof Error ? error : new Error(String(error)),
							});
						}}
					/>
				</>
			) : null}
		</>
	);
};
