import React, { useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { FormattedMessage } from '@atlassian/jira-intl';
import { useDateTimeFormatter } from '@atlassian/jira-issue-format-date/src/main.tsx';
import { Emphasise } from '@atlassian/jira-issue-view-activity-common/src/styles/index.tsx';
import messages from '../../../../common/messages.tsx';
import Change from '../../../../common/ui/change/index.tsx';
import GroupedChange from '../../../../common/ui/grouped-change/index.tsx';
import HistoryItem from '../../../../common/ui/history-item/index.tsx';
import type { DateChangedHistoryItem } from '../../../../types.tsx';

type Props = {
	isGrouped?: Boolean;
	historyItem: DateChangedHistoryItem;
};

// so logger can be di'ed
export const getLogger = () => log;

export const DateChangedHistoryItemView = ({ historyItem, isGrouped = false }: Props) => {
	const { actor, field, fieldType, timestamp, fromDate, toDate, fieldSchema } = historyItem;

	const { formatDateTime, formatDateOnly } = useDateTimeFormatter();

	const formatDateOrDateTime = useCallback(
		(value: string | null) => {
			if (isEmpty(value)) {
				return null;
			}

			if (fieldSchema === 'datetime') {
				return formatDateTime(value);
			}

			if (fieldSchema === 'date') {
				return formatDateOnly(value);
			}

			getLogger().safeErrorWithoutCustomerData(
				'issue.activity.history.datechangedhistoryitemview.formatdateordatetime',
				'Unexpected fieldSchema when trying to format date time',
				{
					expected: "'date' or 'datetime'",
					recieved: fieldSchema,
				},
			);
			return value;
		},
		[fieldSchema, formatDateOnly, formatDateTime],
	);

	const normalizedField = fieldType === 'jira' ? field?.toLocaleLowerCase() : field;

	return (
		<FormattedMessage
			{...messages.updatedField}
			values={{
				field: <Emphasise key="field">{normalizedField}</Emphasise>,
			}}
		>
			{(...action) =>
				isGrouped && expVal('issue_view_activity_timeline', 'isActivityTimelineEnabled', false) ? (
					<GroupedChange
						field={normalizedField}
						from={formatDateOrDateTime(fromDate)}
						to={formatDateOrDateTime(toDate)}
					/>
				) : (
					<HistoryItem // eslint-disable-next-line jira/integration/test-id-by-folder-structure
						testId="issue-history.ui.history-items.date-history-item.history-item"
						actor={actor}
						action={action}
						timestamp={timestamp}
						change={
							<Change from={formatDateOrDateTime(fromDate)} to={formatDateOrDateTime(toDate)} />
						}
					/>
				)
			}
		</FormattedMessage>
	);
};

export default DateChangedHistoryItemView;
