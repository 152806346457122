import memoize from 'lodash/memoize';
import type {
	CascadingSelectItem,
	CascadingSelectParent,
	CascadingSelectAllowedValues,
	SelectedOption,
	Option,
	ParentChildOptionMap,
} from './types.tsx';

export const transformToOptionValue = (
	serverValue: CascadingSelectParent | null,
): SelectedOption | null => {
	if (!serverValue) {
		return null;
	}
	const { id, value, child } = serverValue;
	return {
		value: id,
		label: value,
		child: child && {
			value: child.id,
			label: child.value,
		},
	};
};

export const transformToOptions: (
	arg1: CascadingSelectAllowedValues | CascadingSelectItem[],
) => Option[] = memoize((values: CascadingSelectAllowedValues | CascadingSelectItem[]): Option[] =>
	values.map(({ id, value }) => ({ value: id, label: value })),
);

export const transformToParentChildOptionMap = memoize(
	(values: CascadingSelectAllowedValues): ParentChildOptionMap =>
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		values.reduce<Record<string, any>>((acc, { id, children }) => {
			acc[id] = children ? transformToOptions(children) : [];
			return acc;
		}, {}),
);

export const transformToServerValue = (option: SelectedOption): CascadingSelectParent | null => {
	if (!option) {
		return null;
	}
	const { label, value } = option;
	// @ts-expect-error - TS2339 - Property 'child' does not exist on type 'Option | ParentChildOption'.
	const child = option.child || undefined;
	return {
		id: value,
		value: label,
		child: child && {
			id: child.value,
			value: child.label,
		},
	};
};
