import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	deactivated: {
		id: 'issue-field-single-user-picker-readview-full.single-user-picker.deactivated',
		defaultMessage: 'Deactivated',
		description:
			'Text displayed to the right of the user name of a deactivated user in user picker field',
	},
	anonymousOption: {
		id: 'issue-field-single-user-picker-readview-full.single-user-picker.anonymous-option',
		defaultMessage: 'Anonymous',
		description: 'Represents an anonymous user on this Jira instance',
	},
});
