import React, { type FC, type ComponentPropsWithoutRef } from 'react';
import { Clipboard } from '@atlassian/jira-clipboard/src/ui/index.tsx';
import AuiModalStackIndex from '@atlassian/jira-issue-view-common-utils/src/aui-modal-stack-index/index.tsx';
import withMediaPickerPropsProvider, {
	type WiredProps,
	type WithMediaConfigProps,
} from '../with-media-picker-props-provider/index.tsx';

export type ClipboardProps = ComponentPropsWithoutRef<typeof Clipboard>;

const WrappedClipboard: FC<
	Omit<ClipboardProps, keyof WithMediaConfigProps> &
		WiredProps & { config?: Partial<ClipboardProps['config']> }
> = withMediaPickerPropsProvider(Clipboard);

export type Props = {
	isEnabled: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	eventHandlers?: any;
} & ComponentPropsWithoutRef<typeof WrappedClipboard>;

export const CommonClipboardMediaPicker = ({ isEnabled, ...otherProps }: Props) => (
	<AuiModalStackIndex>
		{(stackIndex) => {
			const shouldRender = isEnabled && stackIndex === 0;
			if (!shouldRender) {
				return null;
			}
			return <WrappedClipboard {...otherProps} isolated={false} />;
		}}
	</AuiModalStackIndex>
);
