import React from 'react';
import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { RecaptchaTermsAndPolicy } from '@atlassian/google-recaptcha';

export const RecaptchaTerms = () => (
	<RecaptchaContainer>
		<RecaptchaTermsAndPolicy />
	</RecaptchaContainer>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RecaptchaContainer = styled.div({
	font: token('font.body.UNSAFE_small'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N300),
	marginTop: token('space.075', '6px'),
	marginBottom: token('space.050', '4px'),
});
