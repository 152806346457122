import React from 'react';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type DevPanelEmptyStateModalType from './main.tsx';
import type { Props } from './ui/types.tsx';

const LazyDevPanelEmptyStateModal = lazyForPaint<typeof DevPanelEmptyStateModalType>(
	() => import(/* webpackChunkName: "async-dev-panel-empty-state-modal" */ './main'),
);

export const AsyncDevPanelEmptyStateModal = (props: Props) => (
	<Placeholder name="dev-panel-empty-state-modal" fallback={<></>}>
		<LazyDevPanelEmptyStateModal {...props} />
	</Placeholder>
);
