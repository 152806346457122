import React, { useMemo, type ComponentPropsWithoutRef, type ReactNode } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import Pressable from '@atlaskit/primitives/pressable';
import Tooltip from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { useFlagsService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import PinFilled from './assets/pin-filled.tsx';
import PinUnfilled from './assets/pin-unfilled.tsx';
import messages from './messages.tsx';
import type { AnalyticsProps } from './types.tsx';
import {
	isFieldPinned,
	usePinnedFields,
	encodePinnedFields,
	fieldPinButtonSelectorName,
} from './utils.tsx';

export type IssueViewPinFieldIconProps = {
	fieldId: string;
	label?: string;
};

export const IssueViewPinFieldIcon = ({ fieldId, label = '' }: IssueViewPinFieldIconProps) => {
	const issueKey = useIssueKey();
	const [pinnedFields, setPinnedFields] = usePinnedFields(issueKey);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { showFlag } = useFlagsService();
	const { formatMessage } = useIntl();

	const dispatchPinnedFieldAnalytics = ({
		isPinned,
		newPinnedFieldLength,
		pinnedFieldPosition,
		setPinSuccess,
		savedFieldIdLength,
		fieldName,
	}: AnalyticsProps) => {
		const payload = {
			isPinned,
			newPinnedFieldCount: newPinnedFieldLength,
			pinnedFieldPosition,
			setPinSuccess,
			savedFieldIdLength,
			fieldName,
		};

		const event = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'button',
			actionSubjectId: 'pinnedFieldButton',
		});
		fireUIAnalytics(event, 'pinnedFields', payload);
	};

	// Without the issueKey, there is not point pinning the field, as we won't know the project key either.
	// The code block has to be after the hook ^
	if (!issueKey) {
		return null;
	}

	const isPinned = isFieldPinned(pinnedFields, fieldId);
	const userHasPinnedFields = pinnedFields.length > 0;

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const tooltipMessage = useMemo((): string => {
		if (isPinned) {
			return formatMessage(messages.unpinField);
		}

		if (userHasPinnedFields === undefined || userHasPinnedFields) {
			// This renders typical (messages.pinField) 'Pin to top' message
			return formatMessage(messages.pinField);
		}

		return formatMessage(messages.firstPinnedField);
	}, [formatMessage, userHasPinnedFields, isPinned]);

	const onClick = (): void => {
		const clonedPinnedFields = [...pinnedFields];

		let pinnedFieldIndex = -1;
		if (isPinned) {
			// Unpin the field
			pinnedFieldIndex = clonedPinnedFields.indexOf(fieldId);
			clonedPinnedFields.splice(pinnedFieldIndex, 1);
		} else {
			// Pin the field
			clonedPinnedFields.push(fieldId);
		}

		const setPinSuccess = setPinnedFields(clonedPinnedFields);

		dispatchPinnedFieldAnalytics({
			isPinned: !isPinned,
			newPinnedFieldLength: clonedPinnedFields.length,
			pinnedFieldPosition: isPinned ? pinnedFieldIndex : clonedPinnedFields.indexOf(fieldId),
			setPinSuccess,
			savedFieldIdLength: encodePinnedFields(clonedPinnedFields).length,
			fieldName: label,
		});

		if (!isPinned) {
			showFlag(
				setPinSuccess
					? {
							type: 'success',
							title: [messages.fieldPinned, { fieldLabel: label }],
						}
					: {
							title: messages.errorTooManyPinnedTitle,
							description: messages.errorTooManyPinnedDescription,
							type: 'error',
						},
			);
		}
	};

	const ariaLabel = label ? `${label} ${tooltipMessage}` : tooltipMessage;

	return (
		<FieldPinButton>
			<Tooltip content={tooltipMessage}>
				<Pressable
					onClick={onClick}
					xcss={[fg('issue_view_field_config_edit') ? wrapperStylesNew : wrapperStylesOld]}
					aria-label={ariaLabel}
					aria-pressed={isPinned}
					testId="issue-field-pin.button"
				>
					{isPinned ? (
						<PinFilled data-testid="issue-field-pin.pin-filled-icon" />
					) : (
						<PinUnfilled data-testid="issue-field-pin.pin-icon" />
					)}
				</Pressable>
			</Tooltip>
		</FieldPinButton>
	);
};

const FieldPinButton = (props: ComponentPropsWithoutRef<typeof FieldPinButtonComponent>) => (
	<FieldPinButtonComponent data-component-selector={fieldPinButtonSelectorName} {...props} />
);

const FieldPinButtonComponent = ({ children, ...props }: { children: ReactNode }) => (
	// eslint-disable-next-line react/jsx-props-no-spreading
	<Box xcss={fieldPinButtonComponentStyles} {...props}>
		{children}
	</Box>
);

const wrapperStylesOld = xcss({
	display: 'inline-flex',
	alignItems: 'center',
	justifyContent: 'center',
	backgroundColor: 'color.background.neutral.subtle',
	padding: '0',
});

const wrapperStylesNew = xcss({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	backgroundColor: 'color.background.neutral.subtle',
	padding: '0',
	borderRadius: '4px',
	color: 'color.text.subtle',
});

const fieldPinButtonComponentStyles = xcss({
	width: '16px',
	height: '16px',
});
