import { combineReducers, createStore, applyMiddleware, type Store } from 'redux';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { freeze } from 'icepick';
import composeWithDevtools from '@atlassian/jira-common-util-compose-with-devtools/src/index.tsx';
import analyticsEpic from './analytics/epic/index.tsx';
import contextReducer, { initialState as contextInitialState } from './context/reducer/index.tsx';
import { enrichDevPanelWithOneClickLinks, fetchDevPanel } from './dev-summary/epic/index.tsx';
import entitiesReducer, {
	initialState as devSummaryInitialState,
} from './dev-summary/reducer/index.tsx';
import type { State, Action } from './types.tsx';

const defaultInitialState = freeze({
	context: contextInitialState,
	entities: devSummaryInitialState,
});

export const rootReducer = (state: State = defaultInitialState, action: Action) =>
	freeze(
		combineReducers({
			context: contextReducer,
			entities: entitiesReducer,
		})(state, action),
	);

export const rootEpic = () =>
	combineEpics<Action, State>(
		fetchDevPanel,
		enrichDevPanelWithOneClickLinks,
		// @ts-expect-error - TS2345 - Argument of type '(action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) => Observable<{ eventData: any; analyticsEvent: any; action: any; actionSubjectId: any; }>' is not assignable to parameter of type 'Epic<State, DevPanelActions>'.
		analyticsEpic,
	);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({
	initialState = defaultInitialState,
	issueKey = '',
}: {
	initialState?: State;
	issueKey?: string;
}): Store<State> =>
	createStore(
		rootReducer,
		initialState,
		composeWithDevtools({ name: `DevPanel-${issueKey}` })(
			applyMiddleware(createEpicMiddleware(rootEpic())),
		),
	);
