import { FieldOperand } from './operand';
import { AstNode, Position } from './common';

export const COMPOUND_CLAUSE_AND: 'and' = 'and';
export const COMPOUND_CLAUSE_OR: 'or' = 'or';
export const COMPOUND_CLAUSE_NOT: 'not' = 'not';

export type CompoundOperatorValue =
  | typeof COMPOUND_CLAUSE_AND
  | typeof COMPOUND_CLAUSE_OR
  | typeof COMPOUND_CLAUSE_NOT;

/**
 * The operator between clauses in a compound clause.
 */
export interface CompoundOperator extends AstNode {
  /**
   * Literal value for an operator
   */
  value: CompoundOperatorValue;
  /**
   * Array of position tuples where each item in the array represents an occurrence of the operator value.
   */
  positions: Position[];
}

export const FIELD_CHANGED_OPERATOR_CHANGED: 'changed' = 'changed';

export type FieldChangedOperatorValue = typeof FIELD_CHANGED_OPERATOR_CHANGED;

/**
 * A time predicate for a temporal JQL clause.
 */
export interface FieldChangedTimePredicate extends AstNode {
  /**
   * The operator between the field and the operand.
   */
  operator: FieldChangedTimePredicateOperator;
  /**
   * The operand applied to the operator.
   */
  operand: FieldOperand | void;
  /**
   * Tuple representing the character position of an operator.
   */
  position: Position;
}

export const FIELD_WAS_OPERATOR_WAS: 'was' = 'was';
export const FIELD_WAS_OPERATOR_WAS_IN: 'was in' = 'was in';
export const FIELD_WAS_OPERATOR_WAS_NOT_IN: 'was not in' = 'was not in';
export const FIELD_WAS_OPERATOR_WAS_NOT: 'was not' = 'was not';

export type FieldWasOperatorValue =
  | typeof FIELD_WAS_OPERATOR_WAS
  | typeof FIELD_WAS_OPERATOR_WAS_IN
  | typeof FIELD_WAS_OPERATOR_WAS_NOT_IN
  | typeof FIELD_WAS_OPERATOR_WAS_NOT;

const FIELD_WAS_OPERATORS: FieldWasOperatorValue[] = [
  FIELD_WAS_OPERATOR_WAS,
  FIELD_WAS_OPERATOR_WAS_IN,
  FIELD_WAS_OPERATOR_WAS_NOT_IN,
  FIELD_WAS_OPERATOR_WAS_NOT
];

export const FIELD_CHANGED_TIME_PREDICATE_OPERATOR_BEFORE: 'before' = 'before';
export const FIELD_CHANGED_TIME_PREDICATE_OPERATOR_AFTER: 'after' = 'after';
export const FIELD_CHANGED_TIME_PREDICATE_OPERATOR_FROM: 'from' = 'from';
export const FIELD_CHANGED_TIME_PREDICATE_OPERATOR_TO: 'to' = 'to';
export const FIELD_CHANGED_TIME_PREDICATE_OPERATOR_ON: 'on' = 'on';
export const FIELD_CHANGED_TIME_PREDICATE_OPERATOR_DURING: 'during' = 'during';
export const FIELD_CHANGED_TIME_PREDICATE_OPERATOR_BY: 'by' = 'by';

export type FieldChangedTimePredicateOperatorValue =
  | typeof FIELD_CHANGED_TIME_PREDICATE_OPERATOR_BEFORE
  | typeof FIELD_CHANGED_TIME_PREDICATE_OPERATOR_AFTER
  | typeof FIELD_CHANGED_TIME_PREDICATE_OPERATOR_FROM
  | typeof FIELD_CHANGED_TIME_PREDICATE_OPERATOR_TO
  | typeof FIELD_CHANGED_TIME_PREDICATE_OPERATOR_ON
  | typeof FIELD_CHANGED_TIME_PREDICATE_OPERATOR_DURING
  | typeof FIELD_CHANGED_TIME_PREDICATE_OPERATOR_BY;

/**
 * The operator used in a time predicate.
 */
export interface FieldChangedTimePredicateOperator extends AstNode {
  /**
   * Literal value for an operator
   */
  value: FieldChangedTimePredicateOperatorValue;
  /**
   * Tuple representing the character position of an operator.
   */
  position: Position;
}

const FIELD_CHANGED_TIME_PREDICATE_OPERATORS: FieldChangedTimePredicateOperatorValue[] = [
  FIELD_CHANGED_TIME_PREDICATE_OPERATOR_BEFORE,
  FIELD_CHANGED_TIME_PREDICATE_OPERATOR_AFTER,
  FIELD_CHANGED_TIME_PREDICATE_OPERATOR_FROM,
  FIELD_CHANGED_TIME_PREDICATE_OPERATOR_TO,
  FIELD_CHANGED_TIME_PREDICATE_OPERATOR_ON,
  FIELD_CHANGED_TIME_PREDICATE_OPERATOR_DURING,
  FIELD_CHANGED_TIME_PREDICATE_OPERATOR_BY
];

export const FIELD_OPERATOR_EQUALS: '=' = '=';
export const FIELD_OPERATOR_NOT_EQUALS: '!=' = '!=';
export const FIELD_OPERATOR_GT: '>' = '>';
export const FIELD_OPERATOR_LT: '<' = '<';
export const FIELD_OPERATOR_GT_EQUALS: '>=' = '>=';
export const FIELD_OPERATOR_LT_EQUALS: '<=' = '<=';
export const FIELD_OPERATOR_IN: 'in' = 'in';
export const FIELD_OPERATOR_NOT_IN: 'not in' = 'not in';
export const FIELD_OPERATOR_LIKE: '~' = '~';
export const FIELD_OPERATOR_LIKE_EUALS: '~=' = '~=';
export const FIELD_OPERATOR_IS: 'is' = 'is';
export const FIELD_OPERATOR_IS_NOT: 'is not' = 'is not';

export type FieldValueOperatorValue =
  | typeof FIELD_OPERATOR_EQUALS
  | typeof FIELD_OPERATOR_NOT_EQUALS
  | typeof FIELD_OPERATOR_GT
  | typeof FIELD_OPERATOR_LT
  | typeof FIELD_OPERATOR_GT_EQUALS
  | typeof FIELD_OPERATOR_LT_EQUALS
  | typeof FIELD_OPERATOR_IN
  | typeof FIELD_OPERATOR_NOT_IN
  | typeof FIELD_OPERATOR_LIKE
  | typeof FIELD_OPERATOR_LIKE_EUALS
  | typeof FIELD_OPERATOR_IS
  | typeof FIELD_OPERATOR_IS_NOT;

const FIELD_VALUE_OPERATORS: FieldValueOperatorValue[] = [
  FIELD_OPERATOR_EQUALS,
  FIELD_OPERATOR_NOT_EQUALS,
  FIELD_OPERATOR_GT,
  FIELD_OPERATOR_LT,
  FIELD_OPERATOR_GT_EQUALS,
  FIELD_OPERATOR_LT_EQUALS,
  FIELD_OPERATOR_IN,
  FIELD_OPERATOR_NOT_IN,
  FIELD_OPERATOR_LIKE,
  FIELD_OPERATOR_LIKE_EUALS,
  FIELD_OPERATOR_IS,
  FIELD_OPERATOR_IS_NOT
];

export type OperatorValue =
  | FieldValueOperatorValue
  | FieldChangedOperatorValue
  | FieldWasOperatorValue;

export interface Operator<T extends OperatorValue> extends AstNode {
  /**
   * Literal value for an operator.
   */
  value: T;
  /**
   * Tuple representing the character position of an operator.
   */
  position: Position;
}

/**
 * Represents a query operator for the {@link FieldValueClause}.
 */
export interface FieldValueOperator extends Operator<FieldValueOperatorValue> {}

/**
 * Represents a query operator for the {@link FieldWasClause}.
 */
export interface FieldWasOperator extends Operator<FieldWasOperatorValue> {}

/**
 * Represents a query operator for the {@link FieldChangedClause}.
 */
export interface FieldChangedOperator
  extends Operator<FieldChangedOperatorValue> {}

// Guards

export const isFieldChangedTimePredicateOperatorValue = (
  operator: string
): operator is FieldChangedTimePredicateOperatorValue => {
  return FIELD_CHANGED_TIME_PREDICATE_OPERATORS.some(
    valueOperator => valueOperator === operator
  );
};

export const isFieldChangedOperator = (
  operator: string
): operator is FieldChangedOperatorValue => {
  return operator === FIELD_CHANGED_OPERATOR_CHANGED;
};

export const isFieldValueOperator = (
  operator: string
): operator is FieldValueOperatorValue => {
  return FIELD_VALUE_OPERATORS.some(
    valueOperator => valueOperator === operator
  );
};

export const isFieldWasOperator = (
  operator: string
): operator is FieldWasOperatorValue => {
  return FIELD_WAS_OPERATORS.some(wasOperator => wasOperator === operator);
};
