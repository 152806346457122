import React, { useEffect } from 'react';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import type { ThirdPartyIssueWatchNudgeTriggerProps } from '../../types.tsx';
import { thirdPartyIssueWatchNudgeEntryPoint } from './entrypoint.tsx';

const entryPointParams = {};

const AsyncThirdPartyIssueWatchNudge = (props: ThirdPartyIssueWatchNudgeTriggerProps) => {
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		thirdPartyIssueWatchNudgeEntryPoint,
		entryPointParams,
	);

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		entryPointActions.load();
	}, [entryPointActions]);

	return (
		<JiraEntryPointContainer
			id="third-party-issue-watch-nudge"
			packageName="third-party-issue-watch-nudge"
			entryPointReferenceSubject={entryPointReferenceSubject}
			runtimeProps={props}
			errorFallback="unmount"
		/>
	);
};

export default AsyncThirdPartyIssueWatchNudge;
