import React from 'react';
import AppStyle from '@atlassian/jira-common-components-app-style/src/index.tsx';
import AppBase from '@atlassian/jira-issue-view-app-base/src/index.tsx';
import FeedbackComponent from './view/feedback.tsx';
import type { Props } from './view/types.tsx';

const FeedbackButton = (props: Props) => (
	<AppBase>
		<AppStyle>
			<FeedbackComponent {...props} />
		</AppStyle>
	</AppBase>
);

export default FeedbackButton;
