import React from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { ELID_ISSUE_HEADER } from '@atlassian/jira-issue-view-common-constants/src/index.tsx';
import Header, {
	type HeaderProps,
} from '@atlassian/jira-issue-view-foundation/src/header/index.tsx';
import StickyHeaderContainer from '@atlassian/jira-issue-view-sticky-header-container/src/index.tsx';
import { HeaderPopupsStateProvider } from '@atlassian/jira-issue-view-sticky-header-container/src/popup-context/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';

type Props = HeaderProps & {
	scrollContainer?: HTMLElement;
	shouldRenderHeader: boolean;
};
// These keys are needed for React's reconciliation algorithm
// to know the components are the same when changing layout.
// This stops component level state being reset on layout change.
const SPLIT_HEADER = 'SPLIT_HEADER' as const;

export const RenderCompactHeader = ({
	issueViewRelayFragment,
	scrollContainer,
	shouldRenderHeader,
	shouldShowCloseButton,
	shouldShowProjectLevelBreadcrumb,
	shouldShowRootProjectsBreadcrumb,
	issueDeleteCallbacks,
	renderFeedback,
	viewModeOptions,
	onClose,
	rootRelayFragment,
}: Props) => {
	if (!shouldRenderHeader) {
		return null;
	}
	return (
		<HeaderPopupsStateProvider>
			<StickyHeaderContainer
				key={SPLIT_HEADER}
				elementId={ELID_ISSUE_HEADER}
				scrollContainer={scrollContainer}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
				style={
					isVisualRefreshEnabled() && fg('jira_nav4_eap_drop_2')
						? {
								top: 0,
								/* padding 0 so that not to cut out button border etc. because of 0 extraTopOffset */
								paddingTop: 0,
							}
						: undefined
				}
			>
				<Header
					issueViewRelayFragment={issueViewRelayFragment}
					rootRelayFragment={rootRelayFragment}
					shouldShowCloseButton={shouldShowCloseButton}
					shouldShowProjectLevelBreadcrumb={shouldShowProjectLevelBreadcrumb}
					shouldShowRootProjectsBreadcrumb={shouldShowRootProjectsBreadcrumb}
					issueDeleteCallbacks={issueDeleteCallbacks}
					renderFeedback={renderFeedback}
					viewModeOptions={viewModeOptions}
					onClose={onClose}
				/>
			</StickyHeaderContainer>
		</HeaderPopupsStateProvider>
	);
};
