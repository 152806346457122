import { useCallback, useEffect, useMemo, useRef } from 'react';
import type { TabsProps } from '@atlaskit/tabs';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { useAdjustmentsEnabled } from '../adjustments-context/index.tsx';
import { useTriggerFieldInitialization } from '../issue-adjustments/hooks/use-trigger-field-initialization/index.tsx';
import { useViewType } from '../issue-adjustments/hooks/use-view-type/index.tsx';
import { useAdjustedScreenTabs } from '../issue-adjustments/main.tsx';
import type { HookResult, Props, ScreenTabCommonProps } from './types.tsx';
import { useMemoizedTabs } from './utils.tsx';

export const useScreenTabs = <ScreenTabProps extends ScreenTabCommonProps>({
	tabs,
	viewProps,
	selected,
	onChange,
}: Props<ScreenTabProps>): HookResult<ScreenTabProps> => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const adjustmentsAreEnabled = useAdjustmentsEnabled();
	const viewType = useViewType();
	const [
		adjustedScreenTabs,
		{ initializeNoScreenTab, initializeScreenTabs, updateActiveScreenTab },
	] = useAdjustedScreenTabs();
	const memoizedTabs = useMemoizedTabs(tabs);
	const [triggerFieldInitializationKey] = useTriggerFieldInitialization();
	const selectedRef = useRef(selected);

	// Always refreshes `selectedRef` value using their props value
	selectedRef.current = selected;

	// Initialize screen tabs once in UIM store
	useEffect(() => {
		if (!adjustmentsAreEnabled) {
			return;
		}

		// receiving an empty tab array and no selected need to be initialized in store
		if (!memoizedTabs.length && !selectedRef.current) {
			initializeNoScreenTab();
			return;
		}

		// receiving single tab means there's no screen tab visible on view
		if (memoizedTabs.length === 1) {
			initializeScreenTabs({ tabs: [], selected: 0 });
			return;
		}

		// receiving multiple tabs
		initializeScreenTabs({ tabs: memoizedTabs, selected: selectedRef.current });

		/**
		 * `triggerFieldInitializationKey` changes upon subsequent view re-renderings.
		 * Typical usage is inside GIC having "Create new Issue" checkbox ticked.
		 * When this happens, we want to re-run initializeScreenTabs.
		 */
	}, [
		adjustmentsAreEnabled,
		viewType,
		memoizedTabs,
		initializeNoScreenTab,
		initializeScreenTabs,
		createAnalyticsEvent,
		triggerFieldInitializationKey,
	]);

	// Controlled tabs are synced with UIM store
	const controlledTabs = useMemo(() => {
		if (adjustmentsAreEnabled && Array.isArray(adjustedScreenTabs)) {
			const adjustedVisibilityMap: Record<string, boolean> = {};
			for (const adjustedScreenTab of adjustedScreenTabs) {
				adjustedVisibilityMap[adjustedScreenTab.id] = adjustedScreenTab.isVisible;
			}

			return tabs.map((inputTab) => ({
				...inputTab,
				// isVisible is determined by the UIM store, but if not, default to true
				isVisible: adjustedVisibilityMap[inputTab.id] ?? true,
			}));
		}

		// default all tabs to be visible
		return tabs.map((inputTab) => ({
			...inputTab,
			isVisible: true,
		}));
	}, [adjustmentsAreEnabled, tabs, adjustedScreenTabs]);

	// Controlled selected is synced with UIM store unless initialization hasn't happened or activeAdjustedTab is empty
	const controlledSelected = useMemo(() => {
		if (
			!adjustmentsAreEnabled ||
			!Array.isArray(adjustedScreenTabs) ||
			!adjustedScreenTabs.length ||
			viewProps?.shouldDisableScreenTabsControl
		) {
			return selected;
		}

		const activeAdjustedTab = adjustedScreenTabs.find((tab) => tab.isActive);
		if (activeAdjustedTab) {
			return memoizedTabs.findIndex((tab) => tab.id === activeAdjustedTab.id);
		}

		return selected;
	}, [
		adjustmentsAreEnabled,
		adjustedScreenTabs,
		viewProps?.shouldDisableScreenTabsControl,
		memoizedTabs,
		selected,
	]);

	// Controlled onChange is propagating user changing tabs to the UIM store
	const controlledOnChange = useCallback(
		(...args: Parameters<NonNullable<TabsProps['onChange']>>) => {
			const [userNewSelected] = args;

			if (adjustmentsAreEnabled) {
				updateActiveScreenTab({ selected: userNewSelected });
			}

			onChange?.(...args);
		},
		[adjustmentsAreEnabled, onChange, updateActiveScreenTab],
	);

	// Call onChange() once when controlledSelected focuses a different tab than the initially selected
	useEffect(() => {
		if (
			memoizedTabs.length > 0 &&
			typeof controlledSelected === 'number' &&
			controlledSelected !== selected
		) {
			onChange?.(controlledSelected, createAnalyticsEvent({}));
		}
	}, [controlledSelected, memoizedTabs, selected, onChange, createAnalyticsEvent]);

	return {
		controlledTabs,
		controlledSelected,
		controlledOnChange,
	};
};
