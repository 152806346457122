import React from 'react';

export const AlertsGlyph = (props: { [key: string]: string }) => (
	<svg
		{...props}
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12 22C13.104 22 14 21.104 14 20H10C10 21.104 10.896 22 12 22Z"
			fill="currentColor"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M19 16.9295C18.962 16.9585 18.879 16.9995 18.75 16.9995H5.25C5.121 16.9995 5.038 16.9585 5 16.9295V16.3025C6.347 15.8965 7.341 14.7765 7.517 13.4265L7.521 13.3975L7.523 13.3675L7.964 9.00151C8.203 7.28851 9.933 5.99951 12 5.99951C14.067 5.99951 15.797 7.28851 16.036 9.00151L16.477 13.3675L16.479 13.3975L16.483 13.4265C16.659 14.7765 17.654 15.8965 19 16.3025V16.9295ZM19.728 14.4265C19.051 14.2845 18.547 13.7815 18.467 13.1675L18.022 8.76751C17.707 6.35151 15.622 4.47151 13 4.08251V2.99951C13 2.44751 12.553 1.99951 12 1.99951C11.447 1.99951 11 2.44751 11 2.99951V4.08251C8.378 4.47151 6.293 6.35151 5.978 8.76751L5.533 13.1675C5.453 13.7815 4.949 14.2845 4.272 14.4265C3.527 14.5815 3 15.1735 3 15.8535V17.0005C3 18.1045 4.007 18.9995 5.25 18.9995H18.75C19.993 18.9995 21 18.1045 21 17.0005V15.8535C21 15.1735 20.473 14.5815 19.728 14.4265Z"
			fill="currentColor"
		/>
	</svg>
);
