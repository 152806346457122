/**
 * @generated SignedSource<<8fe21d7999b231958bc229c6abd7995e>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type header_issueViewWatchers_WatchersItem$data = {
  readonly " $fragmentSpreads": FragmentRefs<"src_issueViewWatchers_WatchersAppWithRelay">;
  readonly " $fragmentType": "header_issueViewWatchers_WatchersItem";
};
export type header_issueViewWatchers_WatchersItem$key = {
  readonly " $data"?: header_issueViewWatchers_WatchersItem$data;
  readonly " $fragmentSpreads": FragmentRefs<"header_issueViewWatchers_WatchersItem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "header_issueViewWatchers_WatchersItem",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "src_issueViewWatchers_WatchersAppWithRelay"
    }
  ],
  "type": "JiraWatchesField"
};

(node as any).hash = "df76776db51c72868fae843756e4cdaa";

export default node;
