import React, { createContext, useContext, useState, type ReactNode } from 'react';

interface ConfluenceDummyLink {
	isEpModalOpen: boolean; // used to control the DraftEPModal
	setIsEpModalOpen: (isOpen: boolean) => void;
	activePageUrl: string; // used by the DraftEpModal to load the appropriate modal.
	setActivePageUrl: (activePageUrl: string) => void;
	embeddedConfluenceSource: string;
	isLoading: boolean;
	setIsLoading: (isLoading: boolean) => void;
}

const ConfluenceDummyLinkContext = createContext<ConfluenceDummyLink>({
	isEpModalOpen: false,
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	setIsEpModalOpen: () => {},
	activePageUrl: '',
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	setActivePageUrl: () => {},
	embeddedConfluenceSource: '',
	isLoading: false,
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	setIsLoading: () => {},
});

export const useConfluenceDummyLinkContext = () => useContext(ConfluenceDummyLinkContext);

interface ConfluenceDummyLinkProviderProps {
	children: ReactNode;
	embeddedConfluenceSource?: string;
}

export const ConfluenceDummyLinkProvider = ({
	children,
	embeddedConfluenceSource = 'jiraIssueView',
}: ConfluenceDummyLinkProviderProps) => {
	const [isEpModalOpen, setIsEpModalOpen] = useState(false);
	const [activePageUrl, setActivePageUrl] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	return (
		<ConfluenceDummyLinkContext.Provider
			value={{
				isEpModalOpen,
				setIsEpModalOpen,
				activePageUrl,
				setActivePageUrl,
				embeddedConfluenceSource,
				isLoading,
				setIsLoading,
			}}
		>
			{children}
		</ConfluenceDummyLinkContext.Provider>
	);
};
