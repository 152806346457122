import isNil from 'lodash/isNil';
import { createHook, createSelector } from '@atlassian/react-sweet-state';
import type { FieldId } from '../../../../common/types/field.tsx';
import { store, type Actions } from '../../main.tsx';
import type { IssueAdjustmentsState } from '../../types.tsx';

export type FieldIsProcessing = {
	isBusy: boolean;
	appsIds: string[];
};

/*
 * When there is an error in any part of UI modifications
 * we need to ensure that fields from the Jira view are not blocked by reacting on an error here.
 * e.g. GIC fields will not be blocked anymore when error occurs in one of the decorators.
 */
export const selectFieldIsProcessing = createSelector(
	(state: IssueAdjustmentsState) => state.registeredFields,
	(state: IssueAdjustmentsState) => state.error,
	(_, fieldId: FieldId) => fieldId,
	(registeredChangesMap, error, fieldId) => {
		if (error) {
			return {
				isBusy: false,
				appsIds: [],
			};
		}

		const appsIds = Object.entries(registeredChangesMap)
			.filter(
				([_, registeredFields]) =>
					!isNil(registeredFields) && Object.values(registeredFields).flat().includes(fieldId),
			)
			.map(([appId]) => appId);

		return {
			isBusy: appsIds.length > 0,
			appsIds,
		};
	},
);

export const useFieldIsProcessing = createHook<
	IssueAdjustmentsState,
	Actions,
	FieldIsProcessing,
	FieldId
>(store, {
	selector: selectFieldIsProcessing,
});
