import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	reformatWithTemplateDescription: {
		id: 'fabric.editor.ai.config.item.range.reformatWithTemplate.default.description',
		defaultMessage: 'Restructure the issue description based on the issue type',
		description: 'Description for the Atlassian Intelligence "Add structure" feature.',
	},
	improveDescription: {
		id: 'fabric.editor.ai.config.item.range.reformatWithTemplate.default.title.experiment.one',
		defaultMessage: 'Improve description',
		description:
			'Describes an option to use Atlassian Intelligence to add structure to the issue description currently selected by the user.',
	},
});
