import type { Action } from '@atlassian/react-sweet-state';
import type { State, Props } from '../../types.tsx';

export const setUnsavedChanges =
	(unsavedChanges: boolean): Action<State, Props> =>
	({ setState }) => {
		setState({
			hasUnsavedChanges: unsavedChanges,
		});
	};
