import type { JobStatus, JobType } from './types.tsx';

export const INTERNAL_REMOVE_FEATURE_FLAG: JobType = 'INTERNAL_REMOVE_FEATURE_FLAG';

export const STATUS: Record<string, JobStatus> = {
	PENDING: 'PENDING',
	IN_PROGRESS: 'IN_PROGRESS',
	COMPLETED: 'COMPLETED',
	FAILED: 'FAILED',
};

export const statusOrder = {
	'': 0,
	[STATUS.FAILED]: 1,
	[STATUS.PENDING]: 2,
	[STATUS.IN_PROGRESS]: 3,
	[STATUS.COMPLETED]: 4,
};
