import type { ReactNode } from 'react';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { ReactionClient } from '@atlaskit/reactions';
import type { Locale } from '@atlassian/jira-common-constants/src/supported-locales.tsx';
import type { Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import type { ArjConfiguration } from '@atlassian/jira-polaris-component-environment-tenant/src/controllers/arj/types.tsx';
import type {
	FieldValueDecorations,
	ValueDecoration,
} from '@atlassian/jira-polaris-domain-field/src/decoration/types.tsx';
import type { ConnectionFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/connection/types.tsx';
import type { DocumentFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/document/types.tsx';
import type { IssueTypeFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/issue-type/types.tsx';
import type { OptionFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/option/types.tsx';
import type { ReactionsFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/reactions/types.tsx';
import type {
	StatusFieldValue,
	StatusCategoryKey,
} from '@atlassian/jira-polaris-domain-field/src/field-types/status/types.tsx';
import type { UserFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/user/types.tsx';
import type { ProjectFieldValue } from '@atlassian/jira-polaris-domain-field/src/field/project/types.tsx';
import type { FieldKey, Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { PolarisPlayContribution } from '@atlassian/jira-polaris-domain-field/src/play/types.tsx';
import type { SortField } from '@atlassian/jira-polaris-domain-field/src/sort/types.tsx';
import type {
	LocalIssueId,
	ExternalIssueId,
} from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { Insight } from '@atlassian/jira-polaris-domain-insight/src/insight/types.tsx';
import type { Filter } from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import type { SortMode } from '@atlassian/jira-polaris-domain-view/src/sort/types.tsx';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type { InsightsRemote } from '@atlassian/jira-polaris-remote-insight/src/types.tsx';
import type {
	RemoteIssue,
	RemoteIssueMeta,
} from '@atlassian/jira-polaris-remote-issue/src/controllers/crud/types.tsx';
import type {
	IssuesRemote,
	SharingIssuesRemote,
} from '@atlassian/jira-polaris-remote-issue/src/controllers/types.tsx';
import type { IssueLinkType } from '@atlassian/jira-polaris-remote-issue/src/services/jira/get-issue/types.tsx';
import type { PolarisArjHierarchyConfiguration } from '@atlassian/jira-polaris-remote-legacy-project/src/services/project-config/types.tsx';
import type { PlayContributionRemote } from '@atlassian/jira-polaris-remote-play-contribution/src/controllers/play-contribution/types.tsx';
import type {
	IssueTypeId,
	TimeZone,
	IssueKey,
	AccountId,
	IssueId,
} from '@atlassian/jira-shared-types/src/general.tsx';

import type { ExternalReferenceEntitiesMap } from '../../services/atlas/types.tsx';
import type { UpdateCommentsHandler } from '../context/types.tsx';
import type { ArchivedFieldsConfig } from '../field/types.tsx';
import type {
	PolarisInsightsByIssueId,
	PolarisPlayContributionsByIssueId,
} from '../project/types.tsx';
import type { IssueTypeToTransitionsMap } from '../workflow/types.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PartialRecord<K extends keyof any, T> = Partial<Record<K, T>>;

export type PropertyMap<TPropertyValue> = Record<FieldKey, Record<LocalIssueId, TPropertyValue>>;

export const IssueCreateStatusInCreation = 'IN_CREATION' as const;
export const IssueCreateStatusInTransition = 'IN_TRANSITION' as const;
export const IssueCreateStatusCreated = 'CREATED' as const;

type IssueCreateStatusInCreationType = typeof IssueCreateStatusInCreation;
type IssueCreateStatusInTransitionType = typeof IssueCreateStatusInTransition;
type IssueCreateStatusCreatedType = typeof IssueCreateStatusCreated;

export type IssueCreateStatusType =
	| IssueCreateStatusInCreationType
	| IssueCreateStatusInTransitionType
	| IssueCreateStatusCreatedType;

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { IssueViewSection } from '../../common/types/issue';

export const IssueCreateGroupTypeUnknown = 'unknown';
export const IssueCreateGroupTypeNoGroup = 'noGroup';
export const IssueCreateGroupTypeEmpty = 'empty';
export const IssueCreateGroupTypeSpecified = 'group';

type IssueCreateGroupTypeUnknownType = typeof IssueCreateGroupTypeUnknown;
type IssueCreateGroupTypeNoGroupType = typeof IssueCreateGroupTypeNoGroup;
type IssueCreateGroupTypeEmptyType = typeof IssueCreateGroupTypeEmpty;
type IssueCreateGroupTypeSpecifiedType = typeof IssueCreateGroupTypeSpecified;

export const IssueCreateGroupFromLocationTop = 'top';
export const IssueCreateGroupFromLocationBottom = 'bottom';

type IssueCreateGroupFromLocationTopType = typeof IssueCreateGroupFromLocationTop;
type IssueCreateGroupFromLocationBottomType = typeof IssueCreateGroupFromLocationBottom;

type IssueCreateGroupFromLocation =
	| IssueCreateGroupFromLocationTopType
	| IssueCreateGroupFromLocationBottomType;

export type IssueCreatedPropertyItemGroupType =
	| { groupType: IssueCreateGroupTypeUnknownType }
	| {
			groupType: IssueCreateGroupTypeNoGroupType;
			fromLocation?: IssueCreateGroupFromLocation;
			rankingAllowed?: boolean;
	  }
	| {
			groupType: IssueCreateGroupTypeEmptyType;
			fromLocation?: IssueCreateGroupFromLocation;
			rankingAllowed?: boolean;
	  }
	| {
			groupType: IssueCreateGroupTypeSpecifiedType;
			groupIdentity: string;
			fieldKey: FieldKey;
			fieldValue: unknown;
			fromLocation?: IssueCreateGroupFromLocation;
			rankingAllowed?: boolean;
	  };

/**
 * Created issue can be in different states:
 *  * in creation,
 *  * in transition (saving)
 *  * created (saved)
 *
 *  and might normally has at least one anchor, unless there are no
 *  issues in the table before issue creation
 */
export type IssueCreatedPropertyItem = {
	status: IssueCreateStatusType;
	anchorBefore?: LocalIssueId;
	anchorAfter?: LocalIssueId;
} & IssueCreatedPropertyItemGroupType;

export type IssueCreatedProperty = {
	[key: LocalIssueId]: IssueCreatedPropertyItem;
};

/**
 * Representation for data points for ideas in the property map
 */
export type InsightsProperty = Record<LocalIssueId, Insight[]>;

/**
 *  Play contributions representation
 */
type PlayContributionsProperty = Record<LocalIssueId, PolarisPlayContribution[]>;

export type ExternalReferenceIdProperty = Partial<Record<LocalIssueId, string | string[]>>;

/**
 * Plays representation for ideas
 */
export type PlaysProperty = {
	[key: string]: PlayContributionsProperty;
};

type ExternalReferenceProperty = {
	[key: string]: ExternalReferenceIdProperty;
};

type CommentsMetadata = {
	timestampCurrentUserSeenComments: number;
};

export type CommentsMetadataMap = Record<LocalIssueId, CommentsMetadata | undefined>;

type InsightsMetadata = {
	timestampCurrentUserSeenInsights: number;
};

export type InsightsMetadataMap = Record<LocalIssueId, InsightsMetadata | undefined>;

export type IssueMetadata = RemoteIssueMeta;

export type IssueFieldDate = {
	sourceIssueId?: number;
	date?: number;
	fieldKey: string;
	fieldType?: string;
	aggregationType: string;
};

/**
 * property maps grouped by type
 */
export type PropertyMaps = {
	/**
	 * single value numeric properties
	 */
	number: PropertyMap<number>;
	/**
	 * single value string properties
	 */
	string: PropertyMap<string>;
	/**
	 * multi-value string properties
	 */
	stringList: PropertyMap<string[]>;
	/**
	 * status type fields
	 */
	status: PropertyMap<StatusFieldValue>;
	/**
	 * issue type fields
	 */
	issueType: PropertyMap<IssueTypeFieldValue>;
	/**
	 * single user type fields
	 */
	user: PropertyMap<UserFieldValue>;
	/**
	 * people type fields
	 */
	people: PropertyMap<UserFieldValue[]>;
	/**
	 * single select type fields
	 */
	singleSelect: PropertyMap<OptionFieldValue>;
	/**
	 * multi select type fields
	 */
	multiSelect: PropertyMap<OptionFieldValue[]>;
	/**
	 * reactions type fields
	 */
	reactions: PropertyMap<ReactionsFieldValue[]>;
	/**
	 * marker for newly created issues - in creation, in transition or created
	 */
	created: IssueCreatedProperty;
	/**
	 * insights for ideas represented as a field
	 */
	insights: InsightsProperty;
	insightsMetadata: InsightsMetadataMap;
	/**
	 * insights for ideas represented as a field
	 */
	plays: PlaysProperty;
	/**
	 * insights for ideas represented as a field
	 */
	externalReference: ExternalReferenceProperty;
	/**
	 * lexorank as returned by the Jira backend
	 */
	lexoRank: Record<LocalIssueId, string>;
	/**
	 * document field - used in single issue view for e.g. description
	 */
	document: PropertyMap<DocumentFieldValue>;
	/**
	 * map to all linked delivery issues
	 */
	linkedDeliveryIssues: Record<LocalIssueId, ExternalIssueId[]>;
	aggregatedDeliveryProgress: Record<LocalIssueId, number[]>;
	aggregatedDeliveryDates: Record<LocalIssueId, IssueFieldDate[]>;
	commentsMetadata: CommentsMetadataMap;
	externalReferenceEntities: ExternalReferenceEntitiesMap;

	/** contains issues metadata about issue links, comments, etc */
	issueMetadata: Record<LocalIssueId, IssueMetadata>;

	/** contains the project ids an idea belongs to */
	projects: PropertyMap<ProjectFieldValue>;

	/** contains map of linked issues filtered by connection field configuration */
	connection: PropertyMap<ConnectionFieldValue[]>;

	// TODO: POL-12642 - store id and name
	team: PropertyMap<string>;
};

/**
 * Container props that when changed should cause a reload of issue data
 */
type LoadingProps = {
	rankField: string | undefined;
	projectId: string | undefined;
	issueTypeIds: IssueTypeId[] | undefined;
	fieldKeys: FieldKey[] | undefined;
	polarisIssueLinkType: string | undefined;
};

/**
 * Props for the issue bulk update action
 */
export type IssueBulkUpdateProps = {
	taskId: string;
	getUpdateIssueFieldsBulkProgress: IssuesRemote['getUpdateIssueFieldsBulkProgress'];
};

/**
 * Container props for the issue data container
 */
type SharedProps = {
	insightsRemote: InsightsRemote;
	issuesRemote: IssuesRemote | SharingIssuesRemote;
	playContributionRemote: PlayContributionRemote;
	// This property is used to allow the issue store to be used for a selected issue as in a single idea view.
	// When this is set special hooks and actions can be used that do not depend on any issue IDs.
	selectedIssue: IssueKey | undefined;
	locale: Locale;
	timezone: TimeZone;
	currentUser: AccountId | undefined;
	rankField: string | undefined;
	archivedFieldsConfig: ArchivedFieldsConfig | undefined;
	cloudId: string;
	projectId: string | undefined;
	selectedViewId?: string;
	issueTypeIds: IssueTypeId[] | undefined;
	getIssueTypeNameFilter: (names: string[]) => IssueTypeId[];
	issueLinkTypes?: IssueLinkType[];
	fields: Field[] | undefined;
	insights: PolarisInsightsByIssueId | undefined;
	plays: PolarisPlayContributionsByIssueId | undefined;
	sortMode: SortMode | undefined;
	externalIssueRanking: IssueId[] | undefined;
	sortBy: SortField[] | undefined;
	filter: Filter[] | undefined;
	containsArchived: boolean;
	decorations: FieldValueDecorations;
	polarisIssueLinkType: string | undefined;
	hiddenIssueLinkTypes: string[];
	children: ReactNode;
	createAnalyticsEvent: CreateUIAnalyticsEvent;
	apolloClient: PolarisApolloClient;
	reactionsClient: ReactionClient;
	workflowTransitions: IssueTypeToTransitionsMap;
	arjLicensed: boolean;
	arjConfiguration: ArjConfiguration | undefined;
	arjHierarchyConfiguration: PolarisArjHierarchyConfiguration | undefined;
	embedded: boolean;
	isRankingEnabled: boolean;
	/**
	 * contains information about the current view that is needed
	 * to perform issue state actions
	 */
	currentViewSelectedIssueId: LocalIssueId[];
	onActionFailed: (arg1: Error) => void;
	onContainerReady: () => void;
	onLoadArchivedIssues: () => void;
	projectOnboardedAt: string | undefined;
	projectTemplateVersion: string | undefined;
	hasBulkChangePermissions: boolean;
	hasNoProjectPermissions: boolean;
	permissionsLoaded: boolean;
	isSharedView?: boolean;
	isCollectionView?: boolean;
	initialFilter?: Filter[];
	/**
	 * Indicates we are in a single idea view.
	 */
	singleIdeaVisible: boolean;
	/**
	 * callback to use when the delivery data fetching failed
	 * @param error
	 */
	onDeliveryDataFetchFailed: (error: Error) => void;
};

export type Props = SharedProps & {
	onIssueBulkUpdate: (props: IssueBulkUpdateProps) => void;
	onIssueCreationFailed: (arg1: Error) => void;
	onIssueLoadingFailed: (arg1: Error) => void;
	onIssueUpdateFailed: (arg1: Error) => void;
	onDynamicFieldInitialized: (fieldKey: FieldKey) => void;
	onDynamicFieldStoreInitialized: () => Promise<void> | void;
	onUpdateComments?: UpdateCommentsHandler;
};

export type ExternalProps = SharedProps & {
	children: ReactNode;
};

export type PropertyValues<TPropertyValue> = Record<LocalIssueId, TPropertyValue | undefined>;

/**
 * Selector function for all the values of a dynamic field.
 */
export type DynamicFieldValuesSelector<TState, TPropertyValue> = (
	arg1: TState,
	arg2: Props | undefined,
) => PropertyValues<TPropertyValue>;

/**
 * Mapping of field Ari to the selector yielding the full issue ID to value map.
 */
type DynamicFieldsValuesSelectorMap<TState, TPropertyValue> = Record<
	Ari,
	DynamicFieldValuesSelector<TState, TPropertyValue>
>;

/**
 * Mapping of field Ari to the selector yielding the single issue ID to value map.
 */
type DynamicFieldsValueSelectorMap<TState, TPropertyValue> = Record<
	Ari,
	(issueId: LocalIssueId) => (arg1: TState, arg2: Props | undefined) => TPropertyValue | undefined
>;

/**
 * All dynamic field selectors. State type needs to be passed in as generic
 * otherwise flow won't accept the back reference
 */
export type DynamicPropertyMaps<TState> = {
	numberValue: DynamicFieldsValueSelectorMap<TState, number>;
	dateValue: DynamicFieldsValueSelectorMap<TState, string[]>;
	numberValues: DynamicFieldsValuesSelectorMap<TState, number>;
	dateValues: DynamicFieldsValuesSelectorMap<TState, string[]>;
};

type LinkedIssueChildIssue = {
	issueId: number;
	issueKey: string;
	summary: string;
	status: {
		categoryKey: StatusCategoryKey;
		categoryId: number;
		categoryName: string;
		categoryColorName: string;
		statusId: string;
		statusName: string;
	};
	issueType: {
		id: string;
		name: string;
		iconUrl: string;
		subtask: boolean;
	};
	// issue may have a parent key if it was resolved though the ARJ path
	parentKey?: string;
	children: LinkedIssueChildIssue[];
};

export type ExternalIssueData = {
	issueId: number;
	issueKey: string;
	summary: string;
	status: {
		self: string;
		description: string;
		iconUrl: string;
		name: string;
		id: string;
		statusCategory: {
			id: number;
			key: StatusCategoryKey;
			self: string;
			colorName: string;
			name: string;
		};
	};
	issueType: {
		id: string;
		name: string;
		description: string;
		iconUrl: string;
		entityId: string;
		avatarId: number;
		hierarchyLevel: number;
		self: string;
		subtask: boolean;
	};
	priority: {
		id: string;
		name: string;
		iconUrl: string;
		self: string;
	};
	projectId?: string;
	issueLinkId: string;
	isDeliveryIssue: boolean;
	childIssues: LinkedIssueChildIssue[];
};

export type ExternalIssueDataMap = Record<ExternalIssueId, ExternalIssueData>;

export type State = {
	/**
	 * meta information on status of the issue state. does not contain any issue
	 * data; contains additional indicators on to the overall state of the state
	 */
	meta: {
		/**
		 * Primary initialisation state. Is initially false and will be set to true only once
		 * during the lifecycle of the application - when allthethings have been loaded for the
		 * first time. Must never be reverted to false afterwards.
		 * Used to render dependent components (e.g. Realtime) that require the available data to not
		 * modify quickly during initial render (e.g. set of linked projects, which will change from [] to
		 * a populated set during lazy issue data loading)
		 */
		initialized: boolean;
		initializedArchived: boolean;
		/**
		 * This will be set if we need to restrict the number of issues we load for delivery data based
		 * on child issues. The value shows the number of issues we loaded successfully. If undefined no
		 * limit got hit.
		 */
		restrictedDeliveryIssuesCount?: number | undefined;
		loading: boolean;
		loadingArchivedIssues: boolean;
		loadingLinkedIssues: boolean;
		lastRefreshedLinkedIssues?: number;
		error: Error | undefined;
		archivedError: Error | undefined;
		loadingProps: LoadingProps | undefined;
		// indicates if a single issue has been loaded (at least once)
		// needed when loading all issues to preserve the single loaded issue previous local id
		isSingleIssueLoaded: boolean;
		// the timestamp when a single issue was loaded
		singleIssueLoadedTimestamp: number | undefined;
		/**
		 * Indicates if the delivery data for all ideas in the project has been fetched.
		 * This is used to detect if data has to be fetched when coming from a single idea page to a view.
		 */
		fullDeliveryDataInitialized: boolean;
	};
	/**
	 * This attribute is used to allow the issue store to be used for a selected issue as in a single idea view.
	 * When this is set special hooks and actions can be used that do not depend on any issue IDs.
	 */
	selectedIssue: IssueKey | undefined;
	lastUpdatedIssueIds: LocalIssueId[];
	/**
	 * source of truth for all existing issues. if an id is not in this list, it
	 * does not exist in the current context. while property maps might be empty
	 * or missing for certain local issue ids, this is guaranteed to contain all
	 * currently known entities.
	 */
	ids: LocalIssueId[];
	/**
	 * This is the external issue ranking stored in the state. Note that we also
	 * usually use a prop value to inject external ranking information, but in some
	 * cases we have to adapt the state directly (specifically this needed to be
	 * introduced to avoid flickering on DnD operations in lists and boards).
	 */
	externalIssueRanking: IssueId[] | undefined;
	/**
	 * contains a set of all value sets for issue ids, grouped by type
	 */
	properties: PropertyMaps;
	/**
	 * contains all dynamic field selectors
	 */
	dynamicProperties: DynamicPropertyMaps<State>;
	/**
	 * contains the data we collected for external issues. This currently means delivery tickets.
	 */
	externalIssueData: ExternalIssueDataMap;
	/**
	 * props mirror for correct selector and hook handling
	 */
	containerProps: Props | undefined;
	/**
	 * props mirror for correct selector and hook handling
	 */
	prevContainerProps?: Props;
	/**
	 * This holds counters for open real-time updates. We use it for ignoring real-time updates after certain
	 * changes to prevent certain race conditions.
	 */
	openUpdateCounter: Record<LocalIssueId, number>;
	/**
	 * containes the raw data fetched from the Backend
	 */
	prefechedIssues: RemoteIssue[] | undefined;
	archivedIssues: RemoteIssue[] | undefined;
};

export type BasicIssue = {
	localIssueId: LocalIssueId;
	id: number;
	key: string;
	summary: string;
	isArchived: boolean;
};

export type IssueForMerge = {
	localIssueId: LocalIssueId;
	id: number;
	key: string;
	summary: string;
	description: DocumentFieldValue;
	issuetype: IssueTypeFieldValue;
	externalIssueData: ExternalIssueData[];
	fieldsForUpdate: Record<FieldKey, unknown>;
};

export type PlayStats = {
	voters: number;
	votes: number;
	comments: number;
};

export type HighlightedField = {
	appliedDecoration: ValueDecoration;
	fieldKey: FieldKey;
	label: string;
};
