import React, { useCallback, useMemo, type ReactNode } from 'react';
import { Text } from '@atlaskit/primitives';
import {
	dismissFlag as createDismissFlag,
	type FlagComponentProps,
	AkFlag,
	useFlagService,
} from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { Invitees } from '../../common/types.tsx';
import { FlagCompatibleAvatarGroup } from '../../common/ui/flag-compatible-avatar-group/index.tsx';
import type { Source } from '../../services/feature-flag/index.tsx';
import {
	generateId,
	type Product,
	clampString,
	getProductDisplayName,
} from '../../utils/index.tsx';
import { messages } from './messages.tsx';

export const useInvitePromptFlagV5 = () => {
	const { showFlag, dismissFlag } = useFlagService();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	return useCallback(
		({
			targetPageLabel,
			product,
			source,
			users,
			allowOptOut,
			onSubmit,
			onDismiss,
			onOptOut,
		}: {
			targetPageLabel?: string;
			product: Product;
			source: Source;
			users: Invitees;
			allowOptOut: boolean;
			onSubmit: () => Promise<unknown>;
			onDismiss: () => unknown;
			onOptOut: () => unknown;
		}) => {
			const id = `invite-v5-${generateId()}`;
			const dismissTheFlag = () => dismissFlag(createDismissFlag(id));

			const attributes = {
				invitees: users.length,
				renderedPageTitle: !!targetPageLabel,
				allowOptOut,
				inviteeIds: users.map((user) => user.id),
				source,
				product,
			};

			const submit = async () => {
				fireUIAnalytics(
					createAnalyticsEvent({}),
					'button clicked',
					'addPeopleButtonInviteOnLinkInsertV5',
					attributes,
				);
				await onSubmit();
				dismissTheFlag();
			};

			const optOut = () => {
				dismissTheFlag();
				onOptOut();
				fireUIAnalytics(
					createAnalyticsEvent({}),
					'button clicked',
					'optOutButtonInviteOnLinkInsertV5',
					attributes,
				);
			};

			const dismiss = () => {
				dismissTheFlag();
				onDismiss();
				fireUIAnalytics(
					createAnalyticsEvent({}),
					'flag dismissed',
					'inviteOnLinkInsertV5',
					attributes,
				);
			};

			fireUIAnalytics(createAnalyticsEvent({}), 'flag shown', 'inviteOnLinkInsertV5', attributes);

			showFlag({
				id,
				isLowPriority: true,
				render: () => (
					<InviteFlagV5
						id={id}
						users={users}
						onDismiss={dismiss}
						onSubmit={submit}
						onOptOut={allowOptOut ? optOut : undefined}
						product={product}
						targetPageLabel={targetPageLabel}
					/>
				),
			});
		},
		[createAnalyticsEvent, dismissFlag, showFlag],
	);
};

export type Props = {
	id: FlagComponentProps['id'];
	users: Invitees;
	onDismiss: FlagComponentProps['onDismissed'];
	onSubmit: () => Promise<unknown>;
	onOptOut?: () => unknown;
	targetPageLabel?: string;
	product: Product;
};

export const InviteFlagV5 = ({
	id,
	onDismiss,
	onSubmit,
	onOptOut,
	users,
	product,
	targetPageLabel,
}: Props) => {
	const { formatMessage } = useIntl();
	const productDisplayName = getProductDisplayName(product);
	const maxLength = 40;
	const clampedTargetPageLabel = clampString(targetPageLabel || '', maxLength);

	const actions = useMemo(() => {
		const allActions = [
			{
				content: formatMessage(messages.addPeopleButton, {
					inviteeCount: users.length,
					displayName: users[0].name.split(' ')[0],
				}),
				onClick: onSubmit,
			},
			{
				content: formatMessage(messages.dismiss, {}),
				onClick: onOptOut,
			},
		];
		return onOptOut ? allActions : allActions.slice(0, 1);
	}, [users, onSubmit, onOptOut, formatMessage]);

	const description = targetPageLabel
		? formatMessage(messages.descriptionWithPageTitleGeneric, {
				strong: (...chunks: ReactNode[]) => <Text as="strong">{chunks.join('')}</Text>,
				inviteeCount: users.length,
				title: clampedTargetPageLabel,
				productDisplayName,
				displayName: users[0].name.split(' ')[0],
			})
		: formatMessage(messages.descriptionWithoutPageTitleGeneric, {
				strong: (...chunks: ReactNode[]) => <Text as="strong">{chunks.join('')}</Text>,
				inviteeCount: users.length,
				productDisplayName,
				displayName: users[0].name.split(' ')[0],
			});
	return (
		<AkFlag
			id={id}
			onDismissed={onDismiss}
			title={formatMessage(messages.titleGeneric, {
				inviteeCount: users.length,
				displayName: users[0].name,
			})}
			description={description}
			icon={
				<FlagCompatibleAvatarGroup
					data={users.map((user) => ({
						name: user.name,
						src: user.avatar,
					}))}
					boundariesElement="scrollParent"
					useSmallAvatars
				/>
			}
			actions={actions}
			messageType="marketing"
			messageId="invite-prompt-on-link-insert.ui.invite-prompt-flag.info-flag.invite-prompt-flag-v5"
		/>
	);
};
