import React, { useCallback, memo, useMemo } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { isAttachmentNumberExceedLimit } from '@atlassian/jira-issue-attachments-limit/src/common/utils.tsx';
import AttachmentsError from '@atlassian/jira-issue-attachments-table/src/ui/error/index.tsx';
import AttachmentsTableSkeleton from '@atlassian/jira-issue-attachments-table/src/ui/skeleton/index.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { ATTACHMENTS_VIEW } from '@atlassian/jira-issue-shared-types/src/common/types/user-preferences-type.tsx';
import { useUserPreferencesValue } from '@atlassian/jira-issue-user-preference-services/src/main.tsx';
import { LIST_VIEW, STRIP_VIEW } from '../constants.tsx';
import { AttachmentFilmstripRelay } from '../filmstrip-view-relay/ui/index.tsx';
import FilmStrip from '../filmstrip-view/index.tsx';
import ListView from '../list-view/index.tsx';
import type { Props } from './types.tsx';

const MemoContent = memo(
	({ attachmentError, refreshAttachments, isPreview, totalCount }: Props) => {
		const [value] = useUserPreferencesValue(ATTACHMENTS_VIEW);
		const issueKey = useIssueKey();

		const onErrorClick = useCallback(() => {
			refreshAttachments(issueKey);
		}, [issueKey, refreshAttachments]);

		/**
		 * Film Strip View is from Media component. Media api and Strip View will be broken when JWT token is too long.
		 * Enforce render list view when original Strip View is broken.
		 */
		const renderMode = useMemo(() => {
			return isAttachmentNumberExceedLimit(totalCount) ? LIST_VIEW : value;
		}, [totalCount, value]);

		if (attachmentError) {
			return <AttachmentsError shouldHideImage onClick={onErrorClick} />;
		}

		switch (renderMode) {
			case LIST_VIEW: {
				if (isPreview) {
					return <AttachmentsTableSkeleton totalCount={totalCount} />;
				}
				return <ListView />;
			}
			case STRIP_VIEW:
			default:
				// In FF cleanup rename AttachmentFilmstripRelay to original FilmStrip as well as removal of /film-strip folder
				return fg('relay-migration-issue-view-attachments-') ? (
					<AttachmentFilmstripRelay />
				) : (
					<FilmStrip />
				);
		}
	},
);

export default MemoContent;
