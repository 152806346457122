/**
 * @generated SignedSource<<d525e2a78ca1167dabae1c40ebe8385c>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutSingleLineText_IssueViewSingleLineText$data = {
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutSingleLineTextField_IssueViewSingleLineTextField" | "ui_issueViewLayoutSingleLineTextMessageField_IssueViewSingleLineTextMessageField">;
  readonly " $fragmentType": "ui_issueViewLayoutSingleLineText_IssueViewSingleLineText";
};
export type ui_issueViewLayoutSingleLineText_IssueViewSingleLineText$key = {
  readonly " $data"?: ui_issueViewLayoutSingleLineText_IssueViewSingleLineText$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutSingleLineText_IssueViewSingleLineText">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldssinglelinetextmessagerelayprovider"
    }
  ],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutSingleLineText_IssueViewSingleLineText",
  "selections": [
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldssinglelinetextmessagerelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "ui_issueViewLayoutSingleLineTextMessageField_IssueViewSingleLineTextMessageField"
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "ui_issueViewLayoutSingleLineTextField_IssueViewSingleLineTextField"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    }
  ],
  "type": "JiraSingleLineTextField"
};

(node as any).hash = "9720ffb0773c2efd70fff1a5559a789d";

export default node;
