// eslint-disable-next-line jira/restricted/react
import React, { PureComponent, type MouseEvent } from 'react';
import checkForHref from './check-for-href.tsx';
import HtmlStyleWrapper from './html-style-wrapper.tsx';

type Props = {
	html: string;
};

// eslint-disable-next-line jira/react/no-class-components
export default class HtmlView extends PureComponent<Props> {
	static defaultProps = {
		html: '',
	};

	onContentClick = (e: MouseEvent<HTMLElement>) => {
		// @ts-expect-error - TS2559 - Type 'EventTarget' has no properties in common with type 'ElementToCheck'.
		if (checkForHref(e.target, e.currentTarget)) {
			e.stopPropagation();
		}
	};

	render() {
		return (
			<HtmlStyleWrapper>
				<div // eslint-disable-next-line react/no-danger
					dangerouslySetInnerHTML={{ __html: this.props.html }}
					onClick={this.onContentClick}
					role="presentation"
				/>
			</HtmlStyleWrapper>
		);
	}
}
