import { fetchJson } from '@atlassian/jira-fetch/src/index.tsx';
import type { ProjectId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { RestIssueType } from './types.tsx';

type IssueTypesResponse = {
	issueTypeCollection: {
		limitPerProject: number;
		data: RestIssueType[];
	};
};

export const performGetIssueTypesForProject = (projectId: ProjectId): Promise<RestIssueType[]> => {
	return fetchJson(`/rest/polaris/v2/project/${projectId}/settings/issuetype`).then(
		(response: IssueTypesResponse) => {
			return response.issueTypeCollection.data;
		},
	);
};
