import type { ForgeCustomFieldValue } from '@atlassian/jira-forge-ui-types/src/common/types/contexts/custom-field.tsx';

// FORGE_CUSTOM_FIELD_EDIT_INITIALIZATION
export const FORGE_CUSTOM_FIELD_EDIT_INITIALIZATION =
	'FORGE_CUSTOM_FIELD_EDIT_INITIALIZATION' as const;
export const forgeCustomFieldEditInitialization = () => ({
	type: FORGE_CUSTOM_FIELD_EDIT_INITIALIZATION,
});

// FORGE_CUSTOM_FIELD_EDIT_RENDERED
export const FORGE_CUSTOM_FIELD_EDIT_RENDERED = 'FORGE_CUSTOM_FIELD_EDIT_RENDERED' as const;
export const forgeCustomFieldEditRendered = () => ({
	type: FORGE_CUSTOM_FIELD_EDIT_RENDERED,
});

// FORGE_CUSTOM_FIELD_EDIT_CANCEL
export const FORGE_CUSTOM_FIELD_EDIT_CANCEL = 'FORGE_CUSTOM_FIELD_EDIT_CANCEL' as const;
export const forgeCustomFieldEditCancel = () => ({
	type: FORGE_CUSTOM_FIELD_EDIT_CANCEL,
});

// FORGE_CUSTOM_FIELD_EDIT_SET_VALUE
export const FORGE_CUSTOM_FIELD_EDIT_SET_VALUE = 'FORGE_CUSTOM_FIELD_EDIT_SET_VALUE' as const;
export const forgeCustomFieldEditSetValue = (action: ForgeCustomFieldValue) => ({
	type: FORGE_CUSTOM_FIELD_EDIT_SET_VALUE,
	payload: action,
});

// FORGE_CUSTOM_FIELD_EDIT_FAILED
export const FORGE_CUSTOM_FIELD_EDIT_FAILED = 'FORGE_CUSTOM_FIELD_EDIT_FAILED' as const;
export const forgeCustomFieldEditFailed = () => ({
	type: FORGE_CUSTOM_FIELD_EDIT_FAILED,
});

export type Action =
	| ReturnType<typeof forgeCustomFieldEditInitialization>
	| ReturnType<typeof forgeCustomFieldEditRendered>
	| ReturnType<typeof forgeCustomFieldEditCancel>
	| ReturnType<typeof forgeCustomFieldEditSetValue>
	| ReturnType<typeof forgeCustomFieldEditFailed>;
