// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	switchToListView: {
		id: 'attachments.heading.switch-to-listView',
		defaultMessage: 'Switch to list view',
		description: 'click on this item to switch to list view mode',
	},
	switchToStripView: {
		id: 'attachments.heading.switch-to-strip-view',
		defaultMessage: 'Switch to strip view',
		description: 'click on this item to switch to filmstrip view mode',
	},
});
