/**
 * @generated SignedSource<<7add49e97e7fb5f68600775ce29b5099>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type addParent_issueViewFoundation_BreadcrumbAddParent$data = {
  readonly " $fragmentSpreads": FragmentRefs<"issueParentSwitcher_issueViewFoundation_IssueParentSwitcherWithRelay">;
  readonly " $fragmentType": "addParent_issueViewFoundation_BreadcrumbAddParent";
};
export type addParent_issueViewFoundation_BreadcrumbAddParent$key = {
  readonly " $data"?: addParent_issueViewFoundation_BreadcrumbAddParent$data;
  readonly " $fragmentSpreads": FragmentRefs<"addParent_issueViewFoundation_BreadcrumbAddParent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "addParent_issueViewFoundation_BreadcrumbAddParent",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "issueParentSwitcher_issueViewFoundation_IssueParentSwitcherWithRelay"
    }
  ],
  "type": "JiraIssue"
};

(node as any).hash = "8100ceb952f5f708bdf87284ea075360";

export default node;
