import { ISSUE_ACTIVITY_MODULE } from '@atlassian/jira-forge-ui-constants/src/constants.tsx';
import type { Source } from '@atlassian/jira-forge-ui-types/src/common/types/analytics.tsx';
import type { Attributes } from '@atlassian/jira-product-analytics-bridge';
import { fireUiEvent } from '../../common/utils/consumers/index.tsx';
import { getEventId } from '../../common/utils/index.tsx';
import { forgeModulesConcurrentLoadTime } from '../../common/utils/performance-analytics/main.tsx';
import { MODULE_TYPE_TRIGGER, UI_ACTIONS } from '../../constants.tsx';

const triggerEventId = getEventId({
	module: ISSUE_ACTIVITY_MODULE,
	moduleType: MODULE_TYPE_TRIGGER,
});

export const fireUiTriggerClickedEvent = (
	extensionId: string,
	source?: Source,
	attributes?: Attributes,
) => {
	forgeModulesConcurrentLoadTime(extensionId).start({ startTime: performance.now() });

	return fireUiEvent(UI_ACTIONS.CLICKED, {
		id: triggerEventId,
		source,
		attributes,
	});
};
