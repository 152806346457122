import React from 'react';
import Reactions from '@atlassian/jira-issue-view-common/src/component/reactions/reactions-view.tsx';

export type StateProps = {
	issueAri: string | null;
	visible: boolean;
	commentAri: string | null;
};

export type Props = StateProps;

export function CommentReactions(props: Props) {
	const { issueAri, commentAri, visible } = props;
	const containerAri = issueAri === null ? undefined : issueAri;
	const objectAri = commentAri === null ? undefined : commentAri;

	return visible ? <Reactions containerAri={containerAri} objectAri={objectAri} /> : null;
}

CommentReactions.defaultProps = {
	issueAri: null,
	visible: false,
	commentAri: null,
};
