import { useEffect } from 'react';
import { useFlagService } from '@atlassian/jira-flags/src'; // ignore-for-ENGHEALTH-17759
import {
	useIssueAdjustmentsActions,
	useNotification,
} from '@atlassian/jira-issue-adjustments/src/controllers.tsx';
import { useNotificationFlagConfig } from './hooks/use-notification-flag-config/index.tsx';

export const NotificationFlags = () => {
	const notificationProperties = useNotification();
	const { showFlag } = useFlagService();
	const notificationFlag = useNotificationFlagConfig(notificationProperties);
	const { resetNotification } = useIssueAdjustmentsActions();

	useEffect(() => {
		if (notificationFlag) {
			showFlag(notificationFlag);
			resetNotification();
		}
	}, [notificationFlag, resetNotification, showFlag]);
	return null;
};
