import { expValEquals } from '@atlassian/jira-feature-experiments';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import {
	useIsSimplifiedProject,
	useProjectKey,
} from '@atlassian/jira-project-context-service/src/main.tsx';

export const useCustomFieldsConfigurationExperiment = () => {
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const isSimplifiedProject = useIsSimplifiedProject(projectKey);
	return (
		isSimplifiedProject &&
		expValEquals<'not-enrolled' | 'control' | 'experiment'>(
			'custom_field_configuration_in_issue_view',
			'cohort',
			'experiment',
		)
	);
};
