import React, { createContext, useState, type ReactNode } from 'react';
import noop from 'lodash/noop';

export const HeaderPopupsStateContext = createContext<{
	isOpened: boolean;
	updateIsOpened: (state: boolean) => void;
}>({
	isOpened: false,
	updateIsOpened: noop,
});

export const HeaderPopupsStateProvider = ({ children }: { children: ReactNode }) => {
	const [isOpened, setIsOpened] = useState<boolean>(false);
	const updateIsOpened = (state: boolean) => {
		state ? requestAnimationFrame(() => setIsOpened(state)) : setIsOpened(state);
	};
	return (
		<HeaderPopupsStateContext.Provider value={{ isOpened, updateIsOpened }}>
			{children}
		</HeaderPopupsStateContext.Provider>
	);
};
