import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	findSimilarIssues: {
		id: 'ai-related-issues.find-similar-issues',
		defaultMessage: 'Find similar issues',
		description: 'Prompt indicating that the AI is finding similar issues',
	},
	discardSuggestions: {
		id: 'ai-related-issues.discard-suggestions',
		defaultMessage: 'Discard and close',
		description: 'Message to indicate that the button can initiate an action to descard suggestion',
	},
	generatingSuggestions: {
		id: 'ai-related-issues.generating-suggestions',
		defaultMessage: 'Generating suggestions',
		description:
			'Text shown when AI is generating suggestions for issues related to the jira issue',
	},
	similarIssuesCouldNotBeGenerated: {
		id: 'ai-related-issues.page-suggestions-could-not-be-generated',
		defaultMessage: "Atlassian Intelligence couldn't find issues similar to this one",
		description: 'Message shown when AI cannot suggest similar issues to the issue',
	},
	betaNonFinal: {
		id: 'ai-related-issues.beta-lozenge',
		defaultMessage: 'Beta',
		description: 'Beta lozenge',
	},
	findSimilarIssuesIssueTermRefresh: {
		id: 'ai-related-issues.find-similar-issues-issue-term-refresh',
		defaultMessage: 'Find similar issues',
		description: 'Prompt indicating that the AI is finding similar issues',
	},
	similarIssuesCouldNotBeGeneratedIssueTermRefresh: {
		id: 'ai-related-issues.page-suggestions-could-not-be-generated-issue-term-refresh',
		defaultMessage: "Atlassian Intelligence couldn't find work items similar to this one",
		description: 'Message shown when AI cannot suggest similar work items to the work item',
	},
});
