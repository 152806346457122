import { useCallback, useEffect, useRef } from 'react';
import type { ToggleTaskCallback } from '../../common/types.tsx';
import { updateAdfTaskItems } from '../../common/utils.tsx';
import type { Props } from './types.tsx';

export const DEFAULT_SAVE_TIMEOUT_MS = 2000;

export const useTasksUpdater = (props: Props) => {
	const { getAdfValue, onChange, onSave, saveTimeoutMs = DEFAULT_SAVE_TIMEOUT_MS } = props;

	const saveTimeout = useRef<NodeJS.Timeout | null>(null);

	const saveValue = useCallback(() => {
		if (saveTimeout.current) {
			clearTimeout(saveTimeout.current);
			saveTimeout.current = null;
		}

		onSave();
	}, [onSave]);

	const scheduleSave = useCallback(() => {
		if (saveTimeout.current == null) {
			saveTimeout.current = setTimeout(saveValue, saveTimeoutMs);
		}
	}, [saveTimeoutMs, saveValue]);

	const toggleTask = useCallback<ToggleTaskCallback>(
		(objectKey, state) => {
			const updatedAdf = updateAdfTaskItems({ objectKey, state }, getAdfValue());

			if (updatedAdf) {
				onChange(updatedAdf);
				scheduleSave();
			}
		},
		[getAdfValue, onChange, scheduleSave],
	);

	useEffect(() => {
		return () => {
			// Always clear timeout on unmount
			saveTimeout.current && clearTimeout(saveTimeout.current);
		};
	}, []);

	return toggleTask;
};
