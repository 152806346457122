import { useCallback } from 'react';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import useAssigneeField from '@atlassian/jira-issue-field-assignee/src/services/use-assignee-field/index.tsx';
import { useFieldFlagsOld } from '@atlassian/jira-issue-field-base/src/services/field-flags-service/index.tsx';
import type {
	StatusValue,
	StatusTransition,
} from '@atlassian/jira-issue-field-status/src/common/types.tsx';
import { useIssueAri } from '@atlassian/jira-issue-hooks/src/services/use-issue-ari/index.tsx';
import { useTriggerIssueTransitionModal } from '@atlassian/jira-issue-transition-trigger/src/utils/use-trigger-issue-transition-modal/index.tsx';
import { useShowFlag } from '@atlassian/jira-issue-transition-use-show-flag/src/ui/use-show-flag/index.tsx';
import {
	ASSIGN_TO_ME_ID,
	ADD_COMMENT_ID,
	ISSUE_VIEW_TRANSITION_SHORTCUT,
} from '@atlassian/jira-issue-view-common-constants/src/command-palette.tsx';
import { ADD_FORM_ID } from '@atlassian/jira-issue-view-common-constants/src/quick-add-constants.tsx';
import type { ItemListGroups } from '@atlassian/jira-issue-view-foundation/src/quick-add/quick-add-items/item-list/index.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useProjectKey } from '@atlassian/jira-project-context-service/src/main.tsx';
import { useProjectPermissions } from '@atlassian/jira-project-permissions-service/src/main.tsx';
import type { ShortcutOption } from '@atlassian/jira-shortcuts-dialog/src/common/types.tsx';
import { useUserSubscriber } from '@atlassian/jira-user-services/src/main.tsx';
import messages from './messages.tsx';
import type { GetExtraActionsProps, UseClickActionProps } from './types.tsx';

export const getExtraActions = ({
	onAddCommentLinkClick,
	onAssignToMeLinkClick,
	onClickAddFormButton,
	shouldShowProformaForms,
	formatMessage,
	canEdit,
}: GetExtraActionsProps) => [
	...(onAssignToMeLinkClick
		? [
				{
					key: ASSIGN_TO_ME_ID,
					label: formatMessage(messages.issueActionAssignToMe),
					onClick: onAssignToMeLinkClick,
				},
			]
		: []),
	...(onAddCommentLinkClick
		? [
				{
					key: ADD_COMMENT_ID,
					label: formatMessage(messages.issueActionComment),
					onClick: onAddCommentLinkClick,
				},
			]
		: []),
	...(shouldShowProformaForms && canEdit
		? [
				{
					key: ADD_FORM_ID,
					label: 'Add form',
					onClick: onClickAddFormButton,
				},
			]
		: []),
];

export const quickAddItemsToShortcutOptions = (quickAddItems: ItemListGroups): ShortcutOption[] =>
	Object.keys(quickAddItems)
		// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'ItemList'.
		.map((key) => quickAddItems[key])
		.flat()
		.map(({ id, label, onClick }) => ({
			label,
			onClick,
			key: id,
		}));

export const transitionItemsToShortcutOptions = (
	transitionItems: StatusTransition[],
	currentStatus: StatusValue | null,
	onClick: (arg1: StatusTransition) => Promise<void>,
): ShortcutOption[] =>
	// @ts-expect-error - TS2322 - Type '{ onClick: () => Promise<void>; label: string; key: string; }[]' is not assignable to type 'ShortcutOption[]'.
	transitionItems
		.filter((transition) => transition.to && transition?.to?.id !== currentStatus?.id)
		.map((transition) => ({
			onClick: () => onClick(transition),
			label: transition.name,
			key: String(transition.id),
		}));

export const useClickActions = ({ onChangeWorkflow, onAddComment }: UseClickActionProps) => {
	const issueKey = useIssueKey();
	const issueId = useIssueAri();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const projectKey = useProjectKey(issueKey);
	const [permissions] = useProjectPermissions(projectKey);
	const [{ data: loggedInUser }] = useUserSubscriber();
	const [, { openIssueTransitionModal }] = useTriggerIssueTransitionModal();
	const { showIssueTransitionSuccessFlag } = useShowFlag();

	const { onSuccess, onFailure } = useFieldFlagsOld();

	const onAssignToMeSuccess = useCallback(() => {
		onSuccess(messages.assignToMeSuccessTitle, [
			expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
				? messages.assignToMeSuccessDescriptionIssueTermRefresh
				: messages.assignToMeSuccessDescription,
			{ issueKey },
		]);
	}, [onSuccess, issueKey]);
	const onAssignToMeFailure = useCallback(() => {
		onFailure(messages.assignToMeFailureTitle, messages.assignToMeFailureDescription);
	}, [onFailure]);
	const [
		{ value: assigneeFieldValue, fieldConfig: assigneeFieldConfig },
		{ saveValue: updateAssignee },
	] = useAssigneeField({
		issueKey,
		onSuccess: onAssignToMeSuccess,
		onFailure: onAssignToMeFailure,
	});

	const onAssignToMeLinkClick = useCallback(() => {
		const analyticsEvent = createAnalyticsEvent({});
		updateAssignee(loggedInUser, null, analyticsEvent);
	}, [loggedInUser, updateAssignee, createAnalyticsEvent]);

	const onWorkflowSelected = useCallback(
		async (transition: StatusTransition) => {
			if (transition.hasScreen) {
				const event = createAnalyticsEvent({});

				const handleDialogSuccess = () => {
					fireTrackAnalytics(event, 'issueTransition success', {
						triggerPointKey: ISSUE_VIEW_TRANSITION_SHORTCUT,
						isModalOpen: true,
					});
					fg('show-modernised-issue-transition-success-flag') &&
						showIssueTransitionSuccessFlag(issueKey, transition.to.name || '');
					onChangeWorkflow(transition, true);
				};

				const handleDialogCancel = () => {
					fireTrackAnalytics(event, 'issueTransition cancel', {
						triggerPointKey: ISSUE_VIEW_TRANSITION_SHORTCUT,
						isModalOpen: true,
					});
				};

				const handleDialogError = () => {
					fireTrackAnalytics(event, 'issueTransition failed', {
						triggerPointKey: ISSUE_VIEW_TRANSITION_SHORTCUT,
						isModalOpen: true,
					});
				};

				openIssueTransitionModal?.({
					payload: {
						issueId,
						issueKey,
						transitionId: transition.id,
					},
					triggerPointKey: 'issue-transition-issue-view-shortcut-dialog',
					onDialogSuccess: handleDialogSuccess,
					onDialogCancel: handleDialogCancel,
					onDialogError: handleDialogError,
				});
			} else {
				await performPostRequest(`/rest/api/2/issue/${issueKey}/transitions`, {
					body: JSON.stringify({ transition }),
				});

				onChangeWorkflow(transition, false);

				fireTrackAnalytics(createAnalyticsEvent({}), 'issueTransition success', {
					triggerPointKey: ISSUE_VIEW_TRANSITION_SHORTCUT,
					isModalOpen: false,
				});

				fg('show-modernised-issue-transition-success-flag') &&
					showIssueTransitionSuccessFlag(issueKey, transition.to.name || '');

				fireTrackAnalytics(createAnalyticsEvent({}), 'issueStatus updated', {
					newValId: transition.to.id,
					newStatusCategoryId: transition.to.statusCategory.id,
					newStatusCategoryName: transition.to.statusCategory.name,
					hasScreen: transition.hasScreen,
					isConditional: transition.isConditional,
				});
			}
		},
		[
			createAnalyticsEvent,
			issueId,
			issueKey,
			onChangeWorkflow,
			openIssueTransitionModal,
			showIssueTransitionSuccessFlag,
		],
	);

	const shouldShowAssignToMe = !!(
		assigneeFieldConfig?.isEditable &&
		permissions.canBeAssignedToIssues &&
		assigneeFieldValue?.accountId !== loggedInUser?.accountId
	);

	return {
		onAssignToMeLinkClick: shouldShowAssignToMe ? onAssignToMeLinkClick : undefined,
		onAddCommentLinkClick: permissions.canAddComments ? onAddComment : undefined,
		onWorkflowSelected,
	};
};
