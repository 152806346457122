import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { Filter } from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import type { IssuesRemote } from '@atlassian/jira-polaris-remote-issue/src/controllers/types.tsx';
import { jiraIssueIdMapping } from '../../../../field/mapping/issue-id/index.tsx';
import { numberComparator } from '../../comparators/index.tsx';
import { numberMapping } from '../number/index.tsx';
import type { FieldMapping } from '../types.tsx';

export const issueIdMapping = (issuesRemote: IssuesRemote, field: Field): FieldMapping<number> => {
	const basicNumberMapping = numberMapping(issuesRemote, field);
	return {
		...jiraIssueIdMapping(issuesRemote, field),
		setMutable: basicNumberMapping.setMutable,
		setImmutable: basicNumberMapping.setImmutable,
		remove: basicNumberMapping.remove,
		modifyImmutableIfMultiValueField: basicNumberMapping.modifyImmutableIfMultiValueField,
		comparator: numberComparator,
		valueAccessor: basicNumberMapping.valueAccessor,
		valueAccessorToExport: basicNumberMapping.valueAccessorToExport,
		getAllValues: basicNumberMapping.getAllValues,
		getGroupIdentities: basicNumberMapping.getGroupIdentities,
		getGroupIdentitiesSelector: basicNumberMapping.getGroupIdentitiesSelector,
		allowEmptyGroup: false,
		getLabel: (groupIdentity, value) => String(value),
		getFilter: (filter: Filter) => {
			if (filter.type === 'FIELD' && filter.field === field.key) {
				// @ts-expect-error - TS2532 - Object is possibly 'undefined'.
				const excludedIds = filter.values.map(({ stringValue }) => +stringValue);
				return (issueId?: number): boolean =>
					issueId === undefined || !excludedIds.includes(issueId);
			}
			return undefined;
		},
	};
};
