import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/switchMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import stripQueryParams from '@atlassian/jira-common-strip-query-params/src/index.tsx';
import type { Action } from '@atlassian/jira-issue-view-actions/src/index.tsx';
import {
	COMMENTS,
	WORKLOG,
} from '@atlassian/jira-issue-view-common-constants/src/activity-items.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import {
	SELECT_ACTIVITY_SORT_ORDER,
	SELECT_ACTIVITY_ITEM,
} from '@atlassian/jira-issue-view-store/src/actions/activity-feed-actions.tsx';
import { fetchSortedCommentsRequest as fetchSortedCommentsRequestAction } from '@atlassian/jira-issue-view-store/src/actions/comment-actions.tsx';
import { fetchSortedWorklogsRequest } from '@atlassian/jira-issue-view-store/src/common/actions/worklog-actions.tsx';
import { getSelectedActivityItem } from '@atlassian/jira-issue-view-store/src/selectors/activity-feed-selector.tsx';

const activityFeedHandler = (action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
	// @ts-expect-error - TS2345: Property 'payload' is missing in type '{ type: "state.FETCH_SORTED_WORKLOGS_REQUEST"; }' but required in type 'FetchSortedCommentsRequestAction'.
	action$.ofType(SELECT_ACTIVITY_SORT_ORDER, SELECT_ACTIVITY_ITEM).switchMap(({ type }) => {
		const state = store.getState();
		const selectedActivityItem = getSelectedActivityItem(state);

		const queryParamsToStrip =
			type === SELECT_ACTIVITY_SORT_ORDER
				? ['focusedWorklogId']
				: ['focusedCommentId', 'focusedWorklogId'];
		// @ts-expect-error - TS2345 - Argument of type 'null' is not assignable to parameter of type 'string'.

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.history.replaceState({}, null, stripQueryParams(queryParamsToStrip));

		if (selectedActivityItem === null) {
			return Observable.empty<never>();
		}

		if (type === SELECT_ACTIVITY_ITEM && selectedActivityItem.type === COMMENTS) {
			return Observable.of(fetchSortedCommentsRequestAction());
		}

		if (selectedActivityItem.type === WORKLOG) {
			return Observable.of(fetchSortedWorklogsRequest());
		}

		return Observable.empty<never>();
	});

export default activityFeedHandler;
