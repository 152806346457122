import type { ProductId } from '@atlaskit/share';

const getAriResourceOwner = (subProductId: ProductId) => {
	switch (subProductId) {
		// these options aren't currently valid resource owners
		case 'jira-addon':
		case 'jira-platform':
		case 'jira-polaris':
		case 'jira-portfolio':
		case 'jira-unknown':
			return 'jira';
		// being explicit that these 3 are valid and were not forgotten
		case 'jira-core':
		case 'jira-servicedesk':
		case 'jira-software':
		default:
			return subProductId;
	}
};

export const createAri = (subProductId: ProductId, cloudId: string) =>
	`ari:cloud:${getAriResourceOwner(subProductId)}::site/${cloudId}`;
