import type { DocNode as ADF } from '@atlaskit/adf-schema';
import { doc, p } from '@atlaskit/adf-utils/builders';
import { getEmptyADF } from '@atlaskit/adf-utils/empty-adf';

export const convertTextToAdf = (text: string): ADF => {
	if (text === '') {
		return getEmptyADF();
	}

	return doc(p(text));
};
