export type FeatureFlagActionTarget = {
	readonly name: string;
	readonly url: string | null | undefined;
	readonly baseUrl: string | null;
	readonly iconUrl: string | null;
	readonly iconClass: string | null;
	readonly applicationType: string | null | undefined;
	readonly applicationLinkId: string | null | undefined;
};

export const FEATURE_FLAG_SUMMARY_ITEM_TEST_ID = 'development-summary-feature-flag.ui.summary-item';
