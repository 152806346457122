import { getAutomationStack } from '@atlassian/jira-automation-platform/src/common/utils.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { Environment } from '@atlassian/jira-shared-types/src/tenant-context.tsx';

export type FetchRuleConfigResult = {
	key?: string;
	value: {
		automationRuleId: number | string | null;
		token: string | null;
	};
};

export const fetchRuleConfig = async (issueKey: string): Promise<FetchRuleConfigResult> => {
	const ruleConfigEndpoint = `/rest/api/2/issue/${issueKey}/properties/recurrence-config`;
	try {
		return await performGetRequest(ruleConfigEndpoint);
	} catch (error) {
		if (error instanceof FetchError && error.statusCode === 404) {
			return Promise.resolve({ value: { automationRuleId: null, token: null } });
		}
		throw error;
	}
};

export const fetchRuleData = async (
	environment: Environment | null,
	cloudId: string,
	projectId: string,
	ruleId: number | string,
) => {
	const automationStack = await getAutomationStack(environment, cloudId);
	const ruleDataEndpoint = `/gateway/api/automation/internal-api/jira/${cloudId}/${automationStack}/rest/${projectId}/rule/${ruleId}`;
	return performGetRequest(ruleDataEndpoint);
};
