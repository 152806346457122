import type { ResolutionOption, ResolutionOptionExternal } from './types.tsx';

export const mapOptionToResolutionOption = (
	option: ResolutionOption,
): ResolutionOptionExternal => ({
	id: option.value,
	name: option.label,
});

export const mapResolutionOptionToOption = (
	option: ResolutionOptionExternal,
): ResolutionOption => ({
	value: option.id,
	label: option.name,
});

export const mapResolutionOptionsToOptions = (
	options: ResolutionOptionExternal[],
): ResolutionOption[] => options.map((option) => mapResolutionOptionToOption(option));

export const mapAndFilterResolutionOptions = (
	currentOption: ResolutionOptionExternal | null,
	options: ResolutionOptionExternal[],
): ResolutionOption[] => {
	if (!currentOption) {
		return mapResolutionOptionsToOptions(options);
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return options.reduce<Array<any>>((resolutionOptions, option) => {
		if (option.id !== currentOption.id) {
			resolutionOptions.push(mapResolutionOptionToOption(option));
		}
		return resolutionOptions;
	}, []);
};
