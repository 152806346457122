import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { BoundActions } from '@atlassian/react-sweet-state';
import type {
	State,
	LocalAssociatedIssueContextType,
	RemoteAssociatedIssueContextType,
	PartialAssociatedIssueContext,
	StoreApi,
} from './types.tsx';

export const mergeLocalAssociatedIssuesContext =
	(associatedIssuesContext: LocalAssociatedIssueContextType) =>
	({ setState, getState }: StoreApi) => {
		const currentState = getState();
		setState({
			localIssues: {
				...currentState.localIssues,
				...associatedIssuesContext,
			},
		});
	};

export const mergeRemoteAssociatedIssuesContext =
	(associatedIssuesContext: RemoteAssociatedIssueContextType) =>
	({ setState, getState }: StoreApi) => {
		const currentState = getState();
		setState({
			remoteIssues: {
				...currentState.remoteIssues,
				...associatedIssuesContext,
			},
		});
	};

export const mergeSingleLocalAssociatedIssueContext =
	(issueKey: IssueKey, associatedIssueContext: PartialAssociatedIssueContext) =>
	({ setState, getState }: StoreApi) => {
		const currentState = getState();
		setState({
			localIssues: {
				...currentState.localIssues,
				[issueKey]: { ...currentState?.localIssues?.[issueKey], ...associatedIssueContext },
			},
		});
	};

export const mergeSingleRemoteAssociatedIssueContext =
	(issueKey: IssueKey, associatedIssueContext: PartialAssociatedIssueContext) =>
	({ setState, getState }: StoreApi) => {
		const currentState = getState();
		setState({
			remoteIssues: {
				...currentState.remoteIssues,
				[issueKey]: {
					...currentState?.remoteIssues?.[issueKey],
					...associatedIssueContext,
				},
			},
		});
	};

export const actions = {
	mergeLocalAssociatedIssuesContext,
	mergeRemoteAssociatedIssuesContext,
	mergeSingleLocalAssociatedIssueContext,
	mergeSingleRemoteAssociatedIssueContext,
} as const;

export type Actions = typeof actions;

export type AssociatedIssuesContextActions = BoundActions<State, Actions>;
