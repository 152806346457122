import { PubSubHub } from './pubsub/index.tsx';
import type { PubSub } from './pubsub/types.tsx';
import type { ITSPubSubEvents, ITSPubSubPayloads } from './types.tsx';

export const ITSPubSub: PubSub<ITSPubSubEvents, ITSPubSubPayloads> = new PubSubHub<
	ITSPubSubEvents,
	ITSPubSubPayloads
>();

export const useIssueTransitionScreenPubSub = () => ({
	...ITSPubSub,
});
