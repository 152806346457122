import messages from './messages.tsx';

export const PREDEFINED_FILTER_ALL = 'preDefined--ALL';
export const PREDEFINED_FILTER_OPEN = 'preDefined--Open';
export const PREDEFINED_FILTER_CLOSED = 'preDefined--Closed';
export const PREDEFINED_FILTER_UNACKED = "preDefined--Un'Acked";
export const PREDEFINED_FILTER_NOT_SEEN = 'preDefined--Not Seen';
export const PREDEFINED_FILTER_ASSIGNED = 'preDefined--Assigned to me';

/**
 * We need to i18n _Predefined_ (defined automatically by the system) saved searches, as Opsgenie response does not i18n them.
 * Predefined searches and their names cannot be modified by the user.
 */
export const predefinedIdToNameMap = {
	[PREDEFINED_FILTER_ALL]: messages.all,
	[PREDEFINED_FILTER_OPEN]: messages.open,
	[PREDEFINED_FILTER_CLOSED]: messages.closed,
	[PREDEFINED_FILTER_UNACKED]: messages.unAcked,
	[PREDEFINED_FILTER_NOT_SEEN]: messages.notSeen,
	[PREDEFINED_FILTER_ASSIGNED]: messages.assigned,
} as const;
