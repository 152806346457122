import React from 'react';
import SortAscendingIcon from '@atlaskit/icon/core/sort-ascending';
import ArrowUpLegacy from '@atlassian/jira-icons/src/ui/sorting-up-icon/index.tsx';

export const ArrowUpIcon = ({ label }: { label: string }) => (
	<SortAscendingIcon
		spacing="none"
		color="currentColor"
		label={label}
		LEGACY_size="small"
		LEGACY_fallbackIcon={ArrowUpLegacy}
	/>
);
