/**
 * @generated SignedSource<<abdd55b9daddf3da40ec56f061f59338>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type reporterField_servicedeskCustomerServiceCustomFields$data = {
  readonly __typename: "JiraSingleSelectUserPickerField";
  readonly fieldId: string;
  readonly name: string;
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"reporter_issueFieldReporterInlineEditFull_ReporterInlineEditView_fragmentRef">;
  readonly " $fragmentType": "reporterField_servicedeskCustomerServiceCustomFields";
};
export type reporterField_servicedeskCustomerServiceCustomFields$key = {
  readonly " $data"?: reporterField_servicedeskCustomerServiceCustomFields$data;
  readonly " $fragmentSpreads": FragmentRefs<"reporterField_servicedeskCustomerServiceCustomFields">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "reporterField_servicedeskCustomerServiceCustomFields",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "reporter_issueFieldReporterInlineEditFull_ReporterInlineEditView_fragmentRef"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "kind": "ScalarField",
      "name": "__typename"
    }
  ],
  "type": "JiraSingleSelectUserPickerField"
};

(node as any).hash = "dc187bccd5cdc6ad57471cc4470090d3";

export default node;
