import React from 'react';
import {
	type JSErrorBoundaryProps,
	JSErrorBoundary,
} from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';

type Props = Omit<JSErrorBoundaryProps, 'packageName' | 'teamName'>;

export const ErrorBoundary = (props: Props) => (
	<JSErrorBoundary
		fallback="flag"
		{...props}
		packageName="jiraSoftwareReleasesVersionDetailIssueList"
		teamName="fusion-solaris"
	/>
);
