import React from 'react';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { IssueViewRelayFragment } from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/main.tsx';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import { useIsIssueOfPostIncidentReviewPractice } from '@atlassian/jira-issue-field-servicedesk-practices/src/services/use-practices-field-value/index.tsx';
import {
	type CustomItemType,
	type LayoutContainerTemplateItem,
	CUSTOM_ITEM_TYPE,
} from '@atlassian/jira-issue-layout-common-constants/src/index.tsx';
import { ContentSectionView } from '@atlassian/jira-issue-view-layout-templates-views/src/ui/content/index.tsx';
import { useLayoutContainerByType } from '@atlassian/jira-issue-view-layout/src/services/main.tsx';
import { SERVICEDESK_PRACTICES_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import RiskInsightsPanel from '@atlassian/jira-risk-insights-panel/src/async.tsx';
import { JsdGettingStartedPanelShouldRender } from '@atlassian/jira-servicedesk-getting-started-panel/src/async.tsx';
import { AsyncPrimaryIncident } from '@atlassian/jira-servicedesk-post-incident-review/src/ui/primary-incident/async.tsx';
import { CHANGES } from '@atlassian/jira-servicedesk-work-category/src/common/constants.tsx';
import { getContentItemsWithLooseItemsInFirstTab } from './utils.tsx';

export const JSM_RISK_IMPROVEMENT_ITEM_ID = 'JSM_RISK_IMPROVEMENT_ITEM_ID';
export const JSM_PRIMARY_INCIDENT_ITEM_ID = 'JSM_PRIMARY_INCIDENT_ITEM_ID' as const;

export const shouldRenderRiskInsightPanel = (itsmPractices?: unknown[]): boolean =>
	itsmPractices?.[0] === CHANGES;

const ContentTemplate = ({
	issueViewRelayFragment,
}: {
	// TODO Decomp BENTO-12514 - add useFragment to this component and replace this prop with more specific fragment key
	issueViewRelayFragment?: IssueViewRelayFragment | null;
}) => {
	const issueKey = useIssueKey();
	const [contentItems] = useLayoutContainerByType(issueKey, 'CONTENT');

	const [itsmPractices] = useFieldValue({ issueKey, fieldKey: SERVICEDESK_PRACTICES_TYPE });

	const riskImprovementPanel: CustomItemType[] = shouldRenderRiskInsightPanel(itsmPractices)
		? [
				{
					id: JSM_RISK_IMPROVEMENT_ITEM_ID,
					type: CUSTOM_ITEM_TYPE,
					item: <RiskInsightsPanel issueKey={issueKey} />,
				},
			]
		: [];

	const primaryIncidentPanel: CustomItemType[] = useIsIssueOfPostIncidentReviewPractice()
		? [
				{
					id: JSM_PRIMARY_INCIDENT_ITEM_ID,
					type: CUSTOM_ITEM_TYPE,
					item: <AsyncPrimaryIncident />,
				},
			]
		: [];

	/**
	 * For custom field types we wish to inject them into the first tab so that they are rendered correctly
	 * given that the tab view expects only tabs to be appearing in the content section.
	 */
	const items: LayoutContainerTemplateItem[] = [
		...riskImprovementPanel,
		...[
			...getContentItemsWithLooseItemsInFirstTab(
				contentItems,
				riskImprovementPanel,
				primaryIncidentPanel,
			),
		],
		...primaryIncidentPanel,
	];

	return (
		<>
			<ContentSectionView items={items} issueViewRelayFragment={issueViewRelayFragment} />
			<JsdGettingStartedPanelShouldRender />
		</>
	);
};

export default ContentTemplate;
