import React from 'react';
import { AsyncChatAppCommunicationTool } from '@atlassian/jira-chatops-tool-app/src/async.tsx';
import type { Area } from '@atlassian/jira-issue-view-common-types/src/connect-field-type.tsx';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button/index.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({ area, fieldId }: { area: Area; fieldId: string }) => (
	<AsyncChatAppCommunicationTool
		showPinButton={getShowPinButton(area)}
		fieldId={fieldId}
		type="zoom"
	/>
);
