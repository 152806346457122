import { useEffect } from 'react';
import { type PreloadedQuery, graphql, usePreloadedQuery } from 'react-relay/hooks';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { signatureCrFetchComponentQuery } from '@atlassian/jira-relay/src/__generated__/signatureCrFetchComponentQuery.graphql';

export interface SignatureCrFetchComponentProps {
	setSignatureCR: (signature: string | null) => void;
	queryReference: PreloadedQuery<signatureCrFetchComponentQuery>;
}

export const SignatureCrFetchComponentQuery = graphql`
	query signatureCrFetchComponentQuery($projectAri: ID!) {
		jiraCannedResponse {
			searchCannedResponses(
				sort: { name: "lastUpdatedAt", order: DESC }
				filter: { projectAri: $projectAri, scopes: [PERSONAL], signature: true }
			) {
				edges {
					node {
						content
						isSignature
					}
				}
				errors {
					__typename
					extensions {
						statusCode
						errorType
					}
				}
			}
		}
	}
`;

export const SignatureCrFetchComponent = ({
	queryReference,
	setSignatureCR,
}: SignatureCrFetchComponentProps) => {
	const response = usePreloadedQuery<signatureCrFetchComponentQuery>(
		SignatureCrFetchComponentQuery,
		queryReference,
	);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const searchResponse = response?.jiraCannedResponse?.searchCannedResponses;
	const signatureCR =
		searchResponse?.edges?.map((edge) => edge.node).filter((node) => node?.isSignature)[0] || null;
	const content = signatureCR?.content || null;

	useEffect(() => {
		setSignatureCR(content);
		const hasError = searchResponse?.errors?.find((err) => err.__typename);
		if (hasError) {
			const extensions = searchResponse?.errors?.[0]?.extensions?.[0];
			const errorType = extensions?.errorType || 'FETCH_FAILED';
			const statusCode = extensions?.statusCode || 500;
			fireErrorAnalytics({
				meta: {
					id: 'fetchSignatureCannedResponse',
				},
				error: new FetchError(statusCode, errorType),
			});
		} else {
			fireTrackAnalytics(createAnalyticsEvent({}), 'fetchSignatureCannedResponse succeeded');
		}
	}, [setSignatureCR, content, createAnalyticsEvent, searchResponse, searchResponse?.errors]);

	return null;
};
