/**
 * Since we want to capture the `enter` key press, and confirm the user
 * edit, same way as previous Summary did it, we also need to fire the same
 * event that would have been propagated from InlineEdit.
 *
 * @atlaskit/inline-edit--current/dist/cjs/InlineEditStateless.js.flow:270
 */
export const confirmedPayload = {
	action: 'confirmed',
	actionSubject: 'inlineEdit',
	attributes: {
		componentName: 'inlineEdit',
	},
} as const;
export const cancelledPayload = {
	...confirmedPayload,
	action: 'cancelled',
} as const;
