import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { CopyTextFieldWithLabel } from '@atlassian/jira-platform-copy-text-field/src/index.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';

export const useSuggestedGitCommand = (): string => {
	const issueKey = useIssueKey();

	return `git commit -m "${issueKey} <message>"`;
};

export const CopyCommitCommand = () => {
	const { formatMessage } = useIntl();

	const suggestedCommitMessage = useSuggestedGitCommand();

	return (
		<>
			<CopyTextFieldWithLabel
				label={formatMessage(messages.copyGitCommitLabel)}
				name="copy-git-commit"
				id="copy-git-commit"
				value={suggestedCommitMessage}
				onCopyClick={(_, analyticsEvent) => {
					fireUIAnalytics(analyticsEvent, 'createCommitCommandCopyButton');
				}}
			/>
		</>
	);
};

export default CopyCommitCommand;
