import React, { type SyntheticEvent } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl/src/index.tsx';
import { AnalyticsEventToProps, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';

type Props = {
	link: string;
};

export const HelpCenter = ({ link }: Props) => {
	const onClick = (event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) =>
		fireUIAnalytics(analyticsEvent, 'viewRequestInHelpCenter');

	const intl = useIntl();

	return (
		<Wrapper>
			<LinkWithAnalytics href={link} target="_blank" onClick={onClick}>
				{intl.formatMessage(messages.viewRequest)}
			</LinkWithAnalytics>
		</Wrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Link = styled.a({
	font: token('font.body.UNSAFE_small'),
});

const LinkWithAnalytics = AnalyticsEventToProps('link', {
	onClick: 'clicked',
})(Link);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	// align start of link with the start of the text above
	paddingLeft: token('space.250', '20px'),
	marginLeft: token('space.200', '16px'),
});
