import { UFOExperience, ExperienceTypes, ExperiencePerformanceTypes } from '@atlassian/ufo';

/**
 * Measures comment creation in the issue view that is done in the same comment session
 * as the user adding an attachment through a mechanism outside the comment editor
 * e.g. drag-and-drop, paste, or 'paperclip' picker on the issue
 *
 * We want to measure this separately to regular comment creation because these methods involve
 * manual ADF editing by the issue view, which has caused incidents in the past e.g. HOT-112623.
 */
export const createCommentWithAttachmentsExposure = new UFOExperience(
	'issue-view-create-comment-with-attachments-exposure',
	{
		type: ExperienceTypes.Experience,
		performanceType: ExperiencePerformanceTypes.InlineResult,
	},
);
