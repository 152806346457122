import { useCallback } from 'react';
import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { ISSUE_ARCHIVE_API } from '../constants.tsx';

type onError = () => void;
type onSuccess = () => void;

export const useArchiveIssue = () => {
	const archiveIssue = useCallback(
		async (issueKey: string | null, onSuccess: onSuccess, onError: onError) => {
			try {
				await performPutRequest(ISSUE_ARCHIVE_API, {
					body: JSON.stringify({
						issueIdsOrKeys: [issueKey],
					}),
				});
				onSuccess();
			} catch (error) {
				onError();
			}
		},
		[],
	);

	return archiveIssue;
};
