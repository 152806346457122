/**
 * @generated SignedSource<<fb86f10febd7873bf5fcf89f85958c48>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type reporter_issueFieldReporterInlineEditFull_ReporterInlineEditViewWithIsEditable_fragmentRef$data = {
  readonly " $fragmentSpreads": FragmentRefs<"singleUserPicker_issueFieldSingleUserPickerInlineEditFull_SingleUserPickerInlineEditViewWithIsEditable_fragmentRef">;
  readonly " $fragmentType": "reporter_issueFieldReporterInlineEditFull_ReporterInlineEditViewWithIsEditable_fragmentRef";
};
export type reporter_issueFieldReporterInlineEditFull_ReporterInlineEditViewWithIsEditable_fragmentRef$key = {
  readonly " $data"?: reporter_issueFieldReporterInlineEditFull_ReporterInlineEditViewWithIsEditable_fragmentRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"reporter_issueFieldReporterInlineEditFull_ReporterInlineEditViewWithIsEditable_fragmentRef">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "reporter_issueFieldReporterInlineEditFull_ReporterInlineEditViewWithIsEditable_fragmentRef",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "singleUserPicker_issueFieldSingleUserPickerInlineEditFull_SingleUserPickerInlineEditViewWithIsEditable_fragmentRef"
    }
  ],
  "type": "JiraSingleSelectUserPickerField"
};

(node as any).hash = "75550b2f1743d8a0b6176f6ded3b5c91";

export default node;
