import { useCallback, useEffect, useRef, useState } from 'react';

type UseShouldBeCompact = {
	/**
	 * Whether a component should prefer using a compact mode because it wouldn't
	 * fit in its container when expanded.
	 */
	shouldBeCompact: boolean;
	/**
	 * Set the reference to the DOM node to be measured. It must be assigned
	 * to the expanded version of the component. It can be assigned to multiple
	 * DOM nodes as long as different keys are provided.
	 * @param key a unique key to identify the node.
	 * @returns a function to be passed as an `innerRef` or `ref`.
	 */
	setMeasuredRef: (key: string) => (arg1: HTMLElement | null) => void;
	/**
	 * Force recomputation of whether it should be compact.
	 */
	forceUpdate: () => void;
};

/**
 * Controls whether a component should use compact mode based on the size its expanded
 * version can fit.
 *
 * @param containerWidth the width of the container to use as baseline. Generally the
 * width of the parent of the component.
 * @param offset the offset to be added to the sum of the measured elements.
 * @returns whether it should be in compact mode and a function to assign references to
 * child components to be measured.
 */
export const useShouldBeCompact = (
	containerWidth: number | null | undefined,
	offset = 0,
): UseShouldBeCompact => {
	const [shouldBeCompact, setShouldBeCompact] = useState(false);
	const expandedWidthsByElement = useRef<{ [key: string]: number }>({});
	const elementsToMeasureRef = useRef<{
		[key: string]: WeakRef<HTMLElement> | null;
	}>({});

	const updateAndGetTotalWidthNew = useCallback((): number => {
		const widthsByElement = Object.entries(elementsToMeasureRef.current).reduce(
			(acc: Record<string, number>, [key, element]) => {
				if (element !== null) {
					if (element?.deref() != null) {
						acc[key] = element?.deref()?.clientWidth ?? 0;
					}
				}
				return acc;
			},
			{},
		);

		let maxExpandedWidthOfCurrentElements = 0;
		for (const [key, measuredWidth] of Object.entries(widthsByElement)) {
			if (
				typeof expandedWidthsByElement.current[key] !== 'number' ||
				measuredWidth > expandedWidthsByElement.current[key]
			) {
				expandedWidthsByElement.current[key] = measuredWidth;
			}
			maxExpandedWidthOfCurrentElements += expandedWidthsByElement.current[key];
		}

		return maxExpandedWidthOfCurrentElements;
	}, []);

	const computeShouldBeCompact = useCallback(() => {
		const totalMeasuredWidth = updateAndGetTotalWidthNew();

		if (typeof containerWidth === 'number' && totalMeasuredWidth !== 0) {
			setShouldBeCompact(containerWidth <= totalMeasuredWidth + offset);
		}
	}, [containerWidth, offset, updateAndGetTotalWidthNew]);

	useEffect(() => {
		if (containerWidth !== undefined) {
			computeShouldBeCompact();
		}
	}, [containerWidth, computeShouldBeCompact]);

	const setMeasuredRef = useCallback(
		(key: string) => (element: HTMLElement | null) => {
			elementsToMeasureRef.current[key] = element != null ? new WeakRef(element) : null;
		},
		[],
	);

	return { shouldBeCompact, setMeasuredRef, forceUpdate: computeShouldBeCompact };
};
