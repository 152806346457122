import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	expandAriaLabel: {
		id: 'servicedesk-customer-service-custom-fields.ui.issue-view-reporter-field.expand-aria-label-non-final',
		defaultMessage: "Expand {reporterName}s' details",
		description:
			'Aria label text for a chevron button that when clicked opens a details panel for a reporter.',
	},
	collapseAriaLabel: {
		id: 'servicedesk-customer-service-custom-fields.ui.issue-view-reporter-field.collapse-aria-label-non-final',
		defaultMessage: "Collapse {reporterName}s' details",
		description:
			'Aria label text for a chevron button that when clicked closes a details panel for a reporter.',
	},
});
