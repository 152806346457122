import React, { type SyntheticEvent } from 'react';
import { css, styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { useDevOpsDetailsDialogLoaderActions } from '@atlassian/jira-development-details-loader/src/controllers/main.tsx';
import {
	SUMMARY_ITEM_DEPLOYMENT,
	SUMMARY_ITEM_FEATURE_FLAGS,
	SUMMARY_ITEM_REMOTE_LINKS,
} from '@atlassian/jira-development-summary-common/src/common/constants.tsx';
import { toDetailDialogDataType } from '@atlassian/jira-development-summary-common/src/common/utils.tsx';
import Deployments from '@atlassian/jira-development-summary-deployment-releases-field/src/main.tsx';
import { DeploymentsViewLink } from '@atlassian/jira-development-summary-deployments-view-link/src/index.tsx';
import FeatureFlagSummary from '@atlassian/jira-development-summary-feature-flag/src/ui/index.tsx';
import RemoteLinkSummary from '@atlassian/jira-development-summary-remote-links/src/index.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { useIntl } from '@atlassian/jira-intl';
import {
	FieldHeadingTitle,
	FieldWrapper,
	SideBySideField,
} from '@atlassian/jira-issue-field-heading/src/styled.tsx';
import {
	useAnalyticsEvents,
	type Attributes,
	fireUIAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';
import type { ReleasesFieldProps } from './types.tsx';

export const ReleasesFieldSummary = (props: ReleasesFieldProps) => {
	const {
		hasDeployments,
		hasFeatureFlagsProviders,
		createFlagLink,
		connectFlagLink,
		hasFeatureFlags,
		issueContext,
		deploymentSummary,
		remoteLinksCount,
		disabledFeatureFlags,
		disabledFeatureFlagsCnt,
		enabledFeatureFlags,
		enabledFeatureFlagsCnt,
		detailsLink,
		rolloutSummary,
		totalFeatureFlags,
		shouldShowAppConfigContextPrompt,
	} = props;
	const { formatMessage } = useIntl();

	const { showDevDetailDialog } = useDevOpsDetailsDialogLoaderActions(
		`devInfoPanelApp${issueContext.issueKey}`,
	);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const analyticsAttributes = {
		issueStatusCategory: issueContext.issueStatusCategory,
		projectType: issueContext.projectType,
		projectId: issueContext.projectId,
		isSimplifiedProject: issueContext.isSimplifiedProject,
	};

	const onShowDevDetailsDialog = (
		clickEvent?: KeyboardEvent | SyntheticEvent | SyntheticEvent<EventTarget, Event>,
		// @ts-expect-error - TS1016 - A required parameter cannot follow an optional parameter.
		devInfoType: string,
	): void => {
		clickEvent && clickEvent.preventDefault();

		const detailDialogAnalyticsAttributes: Attributes = {
			...analyticsAttributes,
			summaryType: devInfoType,
		};

		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'summaryItem',
			}),
			'devpanelsummary',
			detailDialogAnalyticsAttributes,
		);
		const panelItemType = toDetailDialogDataType(devInfoType);
		showDevDetailDialog(
			// @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'PanelDataTypes'.
			panelItemType,
			undefined,
			issueContext.issueKey,
			String(issueContext.issueId),
		);
	};

	if (
		!deploymentSummary &&
		!hasFeatureFlags &&
		connectFlagLink == null &&
		createFlagLink == null &&
		remoteLinksCount === 0 &&
		!shouldShowAppConfigContextPrompt
	) {
		return null;
	}

	return (
		<FieldWrapper data-testid="development-summary-releases-field.ui.summary.actions">
			<ReleasesGlanceFieldHeading>
				<FieldHeadingTitle>{formatMessage(messages.label)}</FieldHeadingTitle>
			</ReleasesGlanceFieldHeading>
			<SideBySideField>
				{(deploymentSummary || shouldShowAppConfigContextPrompt) && (
					<Deployments
						issueContext={issueContext}
						summary={deploymentSummary}
						// @ts-expect-error - TS2322 - Type '(e: any) => void' is not assignable to type '() => void'. | TS7006 - Parameter 'e' implicitly has an 'any' type.
						onClick={(e) => onShowDevDetailsDialog(e, SUMMARY_ITEM_DEPLOYMENT)}
						shouldShowAppConfigContextPrompt={shouldShowAppConfigContextPrompt}
						fieldHasOtherActions={
							createFlagLink != null || connectFlagLink != null || remoteLinksCount > 0
						}
					/>
				)}
				{hasFeatureFlags && (
					<FeatureFlagSummary
						issueContext={issueContext}
						hasFeatureFlagsProviders={hasFeatureFlagsProviders}
						connectFeatureFlagTarget={connectFlagLink}
						createFeatureFlagTarget={createFlagLink}
						hasFeatureFlags={hasFeatureFlags}
						enabledFeatureFlags={enabledFeatureFlags}
						enabledFeatureFlagsCnt={enabledFeatureFlagsCnt}
						disabledFeatureFlags={disabledFeatureFlags}
						disabledFeatureFlagsCnt={disabledFeatureFlagsCnt}
						detailsLink={detailsLink}
						rolloutSummary={rolloutSummary}
						totalFeatureFlags={totalFeatureFlags}
						// @ts-expect-error - TS2322 - Type '(e: any) => void' is not assignable to type '() => void'. | TS7006 - Parameter 'e' implicitly has an 'any' type.
						onClick={(e) => onShowDevDetailsDialog(e, SUMMARY_ITEM_FEATURE_FLAGS)}
					/>
				)}
				{remoteLinksCount !== 0 && (
					<RemoteLinkSummary
						issueContext={issueContext}
						remoteLinksCount={remoteLinksCount}
						// @ts-expect-error - TS2322 - Type '(e: any) => void' is not assignable to type '() => void'. | TS7006 - Parameter 'e' implicitly has an 'any' type.
						onClick={(e) => onShowDevDetailsDialog(e, SUMMARY_ITEM_REMOTE_LINKS)}
					/>
				)}
				{!hasFeatureFlags && (
					<FeatureFlagSummary
						issueContext={issueContext}
						hasFeatureFlagsProviders={hasFeatureFlagsProviders}
						connectFeatureFlagTarget={connectFlagLink}
						createFeatureFlagTarget={createFlagLink}
						hasFeatureFlags={hasFeatureFlags}
						enabledFeatureFlags={enabledFeatureFlags}
						enabledFeatureFlagsCnt={enabledFeatureFlagsCnt}
						disabledFeatureFlags={disabledFeatureFlags}
						disabledFeatureFlagsCnt={disabledFeatureFlagsCnt}
						detailsLink={detailsLink}
						rolloutSummary={rolloutSummary}
						totalFeatureFlags={totalFeatureFlags}
						// Replace with lodash/noop
						// eslint-disable-next-line @typescript-eslint/no-empty-function
						onClick={() => {}}
					/>
				)}
				{hasDeployments && (
					<JSErrorBoundary
						id="releasesGlanceDeploymentsLink"
						packageName="jiraDevelopmentReleasesGlance"
						teamName="fusion-solaris"
					>
						<DeploymentsViewLink />
					</JSErrorBoundary>
				)}
			</SideBySideField>
		</FieldWrapper>
	);
};

const labelColumnWidthForGrouping = 40.0;

const fieldHeadingSideBySideCssStyles = css({
	position: 'relative',
	flexGrow: 1,
	boxSizing: 'border-box',
	paddingRight: token('space.300', '24px'),
	minWidth: '120px',
	width: `${labelColumnWidthForGrouping}%`,
	paddingTop: token('space.100', '8px'),
	lineHeight: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ReleasesGlanceFieldHeading = styled.div<{ isCompact?: boolean }>(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	fieldHeadingSideBySideCssStyles,
	{
		display: 'flex',
		paddingRight: token('space.200', '16px'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		maxWidth: (props) => (props.isCompact ? undefined : '170px'),
	},
);
