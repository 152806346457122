import React, { type PropsWithChildren } from 'react';
import { graphql, useFragment } from 'react-relay';
import { useIntl } from '@atlassian/jira-intl';
import { FieldDescriptionIcon } from '@atlassian/jira-issue-field-description-icon/src/ui/index.tsx';
import { FieldHeading } from '@atlassian/jira-issue-field-heading/src/index.tsx';
import {
	FieldHeadingTitle,
	FieldWrapper,
	SideBySideField,
} from '@atlassian/jira-issue-field-heading/src/styled.tsx';
import { useInlineEditFieldInjections } from '@atlassian/jira-issue-field-injections/src/controllers/inline-edit-injections-context/index.tsx';
import { FieldPin } from '@atlassian/jira-issue-field-pin/src/index.tsx';
import type { Area } from '@atlassian/jira-issue-view-common-types/src/connect-field-type.tsx';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button/index.tsx';
import type { heading_issueViewLayoutParentField_ParentFieldHeading$key as IssueViewFieldHeadingFragment } from '@atlassian/jira-relay/src/__generated__/heading_issueViewLayoutParentField_ParentFieldHeading.graphql';
import messages from './messages.tsx';

export type ParentFieldHeadingProps = PropsWithChildren<{
	testId?: string;
	area?: string;
	field: IssueViewFieldHeadingFragment;
}>;

export const ParentFieldHeading = ({ children, area, field, testId }: ParentFieldHeadingProps) => {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const showPinButton = getShowPinButton(area as Area);
	const { overriding } = useInlineEditFieldInjections();
	const { formatMessage } = useIntl();

	const data = useFragment<IssueViewFieldHeadingFragment>(
		graphql`
			fragment heading_issueViewLayoutParentField_ParentFieldHeading on JiraIssueField {
				name
				fieldId
			}
		`,
		field,
	);

	const label = overriding.overrideLabel(data.name);

	// note: this is a temporary solution until the description is provided by the backend
	// by then, we will get the description from the graphql query above
	const description = overriding.overrideDescription(formatMessage(messages.description));

	return (
		<FieldWrapper data-testid={testId}>
			<FieldHeading fieldId={data.fieldId}>
				<FieldHeadingTitle>{label}</FieldHeadingTitle>
				{description && <FieldDescriptionIcon description={description} />}
				{showPinButton && <FieldPin fieldId={data.fieldId} label={label} />}
			</FieldHeading>
			<SideBySideField>{children}</SideBySideField>
		</FieldWrapper>
	);
};
