/* eslint-disable @atlassian/relay/graphql-naming */

import { useState } from 'react';
import get from 'lodash/get';
import { graphql, useFragment, useMutation } from 'react-relay';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fg } from '@atlassian/jira-feature-gating';
import { ValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { useEditField } from '@atlassian/jira-issue-field-base/src/services/edit-field-service/main.tsx';
import { defaultSaveField } from '@atlassian/jira-issue-field-base/src/services/edit-field-service/utils.tsx';
import { useFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import type { FieldConfiguration } from '@atlassian/jira-issue-field-base/src/services/field-config-service/types.tsx';
import type { TeamValue } from '@atlassian/jira-issue-field-team/src/common/types.tsx';
import { useIssueViewFieldUpdateEvents } from '@atlassian/jira-issue-view-field-update-events/src/services/issue-view-field-update-events/index.tsx';
import {
	fireTrackAnalytics,
	fireOperationalAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import type { services_issueViewLayoutTeamField_IssueViewTeamField$key as TeamFieldFragment } from '@atlassian/jira-relay/src/__generated__/services_issueViewLayoutTeamField_IssueViewTeamField.graphql';
import type { services_issueViewLayoutTeamField_IssueViewTeamField_Mutation } from '@atlassian/jira-relay/src/__generated__/services_issueViewLayoutTeamField_IssueViewTeamField_Mutation.graphql';
import type { ui_issueViewLayoutTeamField_IssueViewTeamField$data as ComponentsInlineEditFragmentData } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutTeamField_IssueViewTeamField.graphql';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';

type ComponentValueShape = TeamValue;
type AggValueShape = Pick<ComponentsInlineEditFragmentData, 'selectedTeam'>['selectedTeam'];

export type UseTeamField = (arg1: {
	issueKey: IssueKey;
	fieldKey: string;
	initialValue?: TeamValue | null;
	onSuccess?: (newValue: TeamValue) => void;
	onFailure?: (error: Error, newValue: TeamValue) => void;
}) => [
	{
		value: TeamValue;
		error: Error | null;
		fieldConfig: FieldConfiguration<TeamValue> | null;
	},
	{
		saveValue: (value: TeamValue, meta: null, analyticsEvent: UIAnalyticsEvent) => Promise<void>;
		resetError: () => void;
	},
];

const saveField = async (
	issueKey: IssueKey,
	fieldKey: string,
	newValue: TeamValue,
	baseUrl: string,
) => {
	const newId = newValue?.id ?? null;

	return defaultSaveField(issueKey, fieldKey, newId, baseUrl);
};

export const useTeamField: UseTeamField = ({
	issueKey,
	fieldKey,
	onSuccess,
	onFailure,
	initialValue,
}) => {
	// useFieldConfig now returns an object { value, loading } instead of just the fieldConfig value
	// if possible when touching this, try and refactor to pass the new object instead of just the value
	const [{ value: fieldConfig }] = useFieldConfig(issueKey, fieldKey);
	const fieldType = get(fieldConfig, 'schema.type', 'array');

	const [{ value, error }, { saveValue, resetError }] = useEditField<TeamValue, null>({
		fieldKey,
		issueKey,
		fieldType,
		initialValue: fg('jwm_list_new_copy_paste_behaviour') ? initialValue || null : null,
		onSuccess,
		saveField,
		onFailure,
	});

	return [
		{ value, error, fieldConfig },
		{ saveValue, resetError },
	];
};

export type UseTeamFieldRelayProps = {
	fragmentKey: TeamFieldFragment;
	onSuccess?: (newValue: TeamValue) => void;
	onFailure?: (error: Error, newValue: TeamValue) => void;
};
export type UseTeamFieldRelay = (arg1: UseTeamFieldRelayProps) => [
	{
		value: TeamValue;
		error: Error | null;
		fieldConfig: FieldConfiguration<TeamValue> | null;
	},
	{
		saveValue: (value: TeamValue, meta: null, analyticsEvent: UIAnalyticsEvent) => Promise<void>;
		resetError: () => void;
	},
];

const transformComponentValueToAggValue = (value: ComponentValueShape): AggValueShape => {
	if (!value) {
		return null;
	}

	return {
		jiraSuppliedId: value?.teamId ?? '',
		jiraSuppliedName: value?.name ?? '',
		jiraSuppliedVisibility: true,
		jiraSuppliedTeamId: value?.id ?? '',
		jiraIncludesYou: !!value?.includesYou,
		jiraMemberCount: value?.memberCount ?? 0,
	};
};

export const toComponentValueShape = (fieldData?: AggValueShape | null): ComponentValueShape => {
	if (!fieldData) {
		return null;
	}

	return {
		id: fieldData.jiraSuppliedTeamId,
		name: fieldData.jiraSuppliedName ?? '',
		isVisible: !!fieldData.jiraSuppliedVisibility,
		teamId: fieldData.jiraSuppliedId,
		memberCount: fieldData.jiraMemberCount ?? 0,
		includesYou: !!fieldData?.jiraIncludesYou,
	};
};

export const useTeamFieldRelay: UseTeamFieldRelay = ({ fragmentKey, onSuccess, onFailure }) => {
	const [, { fieldChanged, fieldChangeFailed, fieldChangeRequested }] =
		useIssueViewFieldUpdateEvents();
	const [error, setError] = useState<Error | null>(null);

	// eslint-disable-next-line @atlassian/relay/no-wrapping-hooks, @atlassian/relay/query-restriction
	const data = useFragment<TeamFieldFragment>(
		graphql`
			fragment services_issueViewLayoutTeamField_IssueViewTeamField on JiraTeamViewField {
				id
				fieldId
				type
				name
				__typename

				fieldConfig {
					isEditable
					isRequired
				}

				issue {
					id
					key
				}
				selectedTeam {
					jiraSuppliedId
					jiraSuppliedName
					jiraSuppliedVisibility
					jiraSuppliedTeamId
					jiraIncludesYou
					jiraMemberCount
				}
			}
		`,
		fragmentKey,
	);

	const issueId = data?.issue?.id;

	const [commit] = useMutation<services_issueViewLayoutTeamField_IssueViewTeamField_Mutation>(
		graphql`
			mutation services_issueViewLayoutTeamField_IssueViewTeamField_Mutation(
				$input: JiraUpdateTeamFieldInput!
			) @raw_response_type {
				jira @optIn(to: ["JiraIssueFieldMutations"]) {
					updateTeamField(input: $input) {
						success
						errors {
							message
						}
						field {
							id
							issue {
								key
							}
							selectedTeam {
								jiraSuppliedId
								jiraSuppliedName
								jiraSuppliedVisibility
								jiraSuppliedTeamId
							}
						}
					}
				}
			}
		`,
	);

	async function saveValue(
		value: ComponentValueShape,
		meta: null,
		analyticsEvent: NonNullable<UIAnalyticsEvent>,
	) {
		const newAggValue = transformComponentValueToAggValue(value);

		const analyticsData = {
			editOperationType: 'set',
			issueId,
			fieldKey: data.fieldId,
			fieldType: data.type,
		};

		fireTrackAnalytics(analyticsEvent, 'field updated', analyticsData);
		fireOperationalAnalytics(analyticsEvent, 'field updateSucceeded', analyticsData);

		issueId &&
			fieldChangeRequested(issueId, data.fieldId, newAggValue, undefined, {
				type: data.type,
				__typename: data.__typename,
			});

		commit({
			variables: {
				input: {
					id: data.id,
					operation: {
						operation: 'SET',
						id: newAggValue?.jiraSuppliedId ?? null,
					},
				},
			},
			onCompleted: (mutationData) => {
				if (mutationData.jira?.updateTeamField?.success) {
					issueId &&
						fieldChanged(issueId, data.fieldId, newAggValue, {
							type: data.type,
							__typename: data.__typename,
						});
					onSuccess && onSuccess(value);
				} else {
					issueId && fieldChangeFailed(issueId, data.fieldId);
				}
			},
			onError(ex) {
				issueId && fieldChangeFailed(issueId, data.fieldId);
				onFailure && onFailure(ex, value);

				const isValidationError = ex instanceof ValidationError;
				fireOperationalAnalytics(analyticsEvent, 'field updateFailed', {
					fieldKey: data.fieldId,
					fieldType: data.type,
					isValidationError,
					isClientFetchError: isClientFetchError(ex),
				});
			},
			optimisticResponse: {
				jira: {
					updateTeamField: {
						success: true,
						errors: [],
						field: {
							id: data.id,
							issue: {
								id: issueId ?? '',
								key: data.issue?.key ?? '',
							},
							selectedTeam: {
								jiraSuppliedId: newAggValue?.jiraSuppliedId ?? '',
								jiraSuppliedName: newAggValue?.jiraSuppliedName,
								jiraSuppliedVisibility: newAggValue?.jiraSuppliedVisibility,
								jiraSuppliedTeamId: newAggValue?.jiraSuppliedTeamId ?? '',
							},
						},
					},
				},
			},
		});
	}

	function resetError() {
		setError(null);
	}

	return [
		{
			value: toComponentValueShape(data.selectedTeam),
			error,
			fieldConfig: {
				isEditable: !!data.fieldConfig?.isEditable,
				allowedValues: [],
				isRequired: !!data.fieldConfig?.isRequired,
				schema: { custom: null, system: null, type: '' },
				title: '',
			},
		},
		{ saveValue, resetError },
	];
};
