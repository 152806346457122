import indexOf from 'lodash/indexOf';
import type { FieldsByKey } from '@atlassian/jira-polaris-domain-field/src/collections/types.tsx';
import type { SortField } from '@atlassian/jira-polaris-domain-field/src/sort/types.tsx';
import {
	FIELD_SORT,
	PROJECT_RANK,
	VIEW_RANK,
} from '@atlassian/jira-polaris-domain-view/src/sort/constants.tsx';
import type {
	SortMode,
	ViewSortConfiguration,
} from '@atlassian/jira-polaris-domain-view/src/sort/types.tsx';
import type { View } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';

export const getSortMode = (
	config: ViewSortConfiguration | View,
	fields: FieldsByKey,
): SortMode => {
	// a sortBy field can be unavailable in the current view
	// e.g. a global field that has been first picked as the sortBy field and then unassociated
	const availableSortByFields = config.sortBy?.filter((field) => fields[field.fieldKey]);
	if (config.sortMode === FIELD_SORT && availableSortByFields?.length === 0) {
		return PROJECT_RANK;
	}
	if (config.sortMode !== undefined) {
		return config.sortMode;
	}
	return config.sortBy ? FIELD_SORT : PROJECT_RANK;
};

export const withSortFieldsSet = (
	config: ViewSortConfiguration | View,
	fields?: SortField[],
): ViewSortConfiguration => {
	if (fields === undefined) {
		return {
			sortMode:
				config.sortMode === FIELD_SORT || config.sortMode === undefined
					? PROJECT_RANK
					: config.sortMode,
			sortBy: undefined,
			issueRanking: config.issueRanking,
		};
	}
	return {
		sortMode: FIELD_SORT,
		sortBy: fields,
		issueRanking: config.issueRanking,
	};
};

export const withProjectRankMode = (
	config: ViewSortConfiguration | View,
	clearIssueRanking = false,
): ViewSortConfiguration => ({
	sortMode: PROJECT_RANK,
	sortBy: undefined,
	issueRanking: clearIssueRanking ? undefined : config.issueRanking,
});

export const withViewRankMode = (
	config: ViewSortConfiguration | View,
	issueRanking: IssueId[],
	overrideCurrentIssueRanking = false,
): ViewSortConfiguration => ({
	sortMode: VIEW_RANK,
	sortBy: undefined,
	issueRanking: overrideCurrentIssueRanking ? issueRanking : config.issueRanking || issueRanking,
});

export const withMovedIssue = (
	config: ViewSortConfiguration | View,
	id: IssueId,
	destinationBeforeId: IssueId | undefined,
	destinationAfterId: IssueId | undefined,
	sortedIds: IssueId[],
): ViewSortConfiguration => {
	const ids = [...sortedIds];

	const itemIndex = indexOf(ids, id);
	ids.splice(itemIndex, 1);
	const toIndex =
		destinationBeforeId !== undefined
			? indexOf(ids, destinationBeforeId)
			: indexOf(ids, destinationAfterId) + 1;

	ids.splice(toIndex, 0, id);
	return {
		sortMode: config.sortMode,
		sortBy: config.sortBy,
		issueRanking: ids,
	};
};

const getBaseSorting = (viewIssueRanking: IssueId[], sortedIssueIdsOnCreation: IssueId[]) =>
	sortedIssueIdsOnCreation.length > viewIssueRanking.length
		? sortedIssueIdsOnCreation
		: viewIssueRanking;

export const withInsertedIssue = (
	config: ViewSortConfiguration | View,
	id: IssueId,
	index: number,
	sortedIssueIdsOnCreation: IssueId[],
): ViewSortConfiguration => {
	const ids = [...getBaseSorting(config.issueRanking || [], sortedIssueIdsOnCreation)];
	ids.splice(index, 0, id);
	return {
		sortMode: config.sortMode,
		sortBy: config.sortBy,
		issueRanking: ids,
	};
};

export const withMovedIssuesToTop = (
	config: ViewSortConfiguration | View,
	movedIds: IssueId[],
	sortedIds: IssueId[],
): ViewSortConfiguration => ({
	sortMode: config.sortMode,
	sortBy: config.sortBy,
	issueRanking: [...movedIds, ...sortedIds.filter((id) => !movedIds.includes(id))],
});
