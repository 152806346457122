/**
 * @generated SignedSource<<9dd6faa6158cb034965c171b13e088f5>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type sentiment_issueFieldSentimentInlineEditFull_SentimentInlineEditView$data = {
  readonly fieldId: string;
  readonly id: string;
  readonly name: string;
  readonly sentiment: {
    readonly name: string | null | undefined;
    readonly sentimentId: string | null | undefined;
  } | null | undefined;
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"sentiment_issueFieldSentimentReadviewFull_SentimentReadView">;
  readonly " $fragmentType": "sentiment_issueFieldSentimentInlineEditFull_SentimentInlineEditView";
};
export type sentiment_issueFieldSentimentInlineEditFull_SentimentInlineEditView$key = {
  readonly " $data"?: sentiment_issueFieldSentimentInlineEditFull_SentimentInlineEditView$data;
  readonly " $fragmentSpreads": FragmentRefs<"sentiment_issueFieldSentimentInlineEditFull_SentimentInlineEditView">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "name"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "sentiment_issueFieldSentimentInlineEditFull_SentimentInlineEditView",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "sentiment_issueFieldSentimentReadviewFull_SentimentReadView"
    },
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    (v0/*: any*/),
    {
      "concreteType": "JiraServiceManagementSentiment",
      "kind": "LinkedField",
      "name": "sentiment",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "sentimentId"
        },
        (v0/*: any*/)
      ]
    }
  ],
  "type": "JiraServiceManagementSentimentField"
};
})();

(node as any).hash = "cf695e465961ce052b923eeefffd392a";

export default node;
