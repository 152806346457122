import type MarkdownIt from 'markdown-it';

import type { Schema } from '@atlaskit/editor-prosemirror/model';

import { type Marks, type Nodes, pmToMd, type SchemaMapping } from './mappings';

export const filterSchemaMapping = (schema: Schema, map: any) =>
	Object.keys(map).reduce((newMap: any, key: string) => {
		const value = map[key];
		const block = value.block || value.node;
		const mark = value.mark;
		if ((block && schema.nodes[block]) || (mark && schema.marks[mark])) {
			newMap[key] = value;
		}
		return newMap;
	}, {});

export const enableSchemaPlugins = (md: MarkdownIt, schema: Schema): void => {
	for (const k in pmToMd) {
		const key = k as keyof SchemaMapping;
		for (const i in pmToMd[key]) {
			const idx = i as keyof (Nodes | Marks);
			if (schema[key][idx]) {
				md.enable(pmToMd[key][idx]);
			}
		}
	}
};
