import type { Dispatch } from 'redux';
import memoizeOne from 'memoize-one';
import { genericMessages } from '@atlassian/jira-issue-view-common-constants/src/context-items-messages.tsx';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button/index.tsx';
import connectField from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-field.tsx';
import { issueKeySelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import { sweetStateFieldUpdated } from '@atlassian/jira-issue-view-store/src/issue-field/state/actions/field-save-actions.tsx';
import type { Props } from './types.tsx';
import NumberView from './view.tsx';

const onUpdateFactory = memoizeOne(
	// @ts-expect-error - TS7006 - Parameter 'value' implicitly has an 'any' type.
	(dispatch: Dispatch, fieldId: Props['fieldId']) => (value) =>
		dispatch(sweetStateFieldUpdated(fieldId, value)),
);

export default connectField((stateOnMount, ownPropsOnMount) => ({
	fieldId: ownPropsOnMount.fieldId,
	additionalProps: (state, intl) => ({
		issueKey: issueKeySelector(state),
		noValueText: intl.formatMessage(genericMessages.noValue),
		type: 'number',
		showPinButton: getShowPinButton(ownPropsOnMount.area),
	}),
	additionalCallbacks: (dispatch: Dispatch) => ({
		onUpdate: onUpdateFactory(dispatch, ownPropsOnMount.fieldId),
	}),
}))(NumberView);
