import React from 'react';
import { styled } from '@compiled/react';
import { B400 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

export function DragPreview({
	iconUrl,
	issueKey,
}: {
	/**
	 * This image should be inline or preloaded, otherwise it might not be
	 * loaded in time for the `onGenerateDragPreview` snapshot.
	 */
	iconUrl?: string | null;
	issueKey?: string;
}) {
	return (
		<Container>
			{iconUrl && (
				<img
					src={iconUrl}
					/**
					 * This preview won't be read by assistive technology,
					 * so can be treated as purely decorative.
					 */
					alt=""
				/>
			)}
			<Key>{issueKey}</Key>
		</Container>
	);
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	backgroundColor: token('elevation.surface.overlay', '#FFF'),
	borderRadius: token('border.radius', '4px'),
	display: 'flex',
	alignItems: 'center',
	padding: token('space.100', '8px'),
	gap: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Key = styled.span({
	color: token('color.link', B400),
	font: token('font.heading.xxsmall'),
	fontWeight: token('font.weight.medium'),
	whiteSpace: 'nowrap',
});
