export const callWhenIdle = (callback: () => void, timeout = 200) => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	if (typeof window.requestIdleCallback === 'function') {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const callbackId = window.requestIdleCallback(callback);
		return () => cancelIdleCallback(callbackId);
	}
	const timer = setTimeout(callback, timeout);
	return () => {
		clearTimeout(timer);
	};
};
