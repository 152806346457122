import React, { Component } from 'react';
import type { Locale } from '@atlassian/jira-common-constants/src/supported-locales.tsx';
import type { WorkflowScope } from '@atlassian/jira-project-configuration-commons/src/model/types.tsx';
import type {
	IssueKey,
	BaseUrl,
	CloudId,
	ProjectId,
	IssueTypeId,
} from '@atlassian/jira-shared-types/src/general.tsx';
import UFOInteractionIgnore from '@atlassian/jira-ufo-interaction-ignore/src/index.tsx';
import { WorkflowReadonlyModalBoundary } from '@atlassian/jira-workflow-readonly-modal/src/async.tsx';
import type WorkflowReadonlyModalType from '@atlassian/jira-workflow-readonly-modal/src/index.tsx';
import { lazy, LazyWait } from '@atlassian/react-loosely-lazy';

export type Props = {
	isOpen: boolean;
	issueKey: IssueKey;
	issueTypeId: IssueTypeId;
	projectId: ProjectId;
	baseUrl: BaseUrl;
	locale: Locale;
	cloudId: CloudId;
	scope: WorkflowScope;
	onModalMounted: () => void;
	onClose: () => void;
};

type State = {
	shouldMountWorkflow: boolean;
};

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const WorkflowReadonlyModal = lazy<typeof WorkflowReadonlyModalType>(
	() =>
		import(
			/* webpackChunkName: "async-issue-workflow-readonly-modal" */ '@atlassian/jira-workflow-readonly-modal'
		),
);

// eslint-disable-next-line jira/react/no-class-components
export class WorkflowModal extends Component<Props, State> {
	static getDerivedStateFromProps(props: Props, state: State) {
		return {
			shouldMountWorkflow: state.shouldMountWorkflow || props.isOpen,
		};
	}

	state: State = {
		shouldMountWorkflow: false,
	};

	render() {
		const {
			issueKey,
			issueTypeId,
			baseUrl,
			locale,
			cloudId,
			projectId,
			isOpen,
			onModalMounted,
			onClose,
			scope,
		} = this.props;
		const { shouldMountWorkflow } = this.state;

		return (
			shouldMountWorkflow && (
				<WorkflowReadonlyModalBoundary packageName="issue">
					<UFOInteractionIgnore>
						<LazyWait until={isOpen}>
							<WorkflowReadonlyModal
								onMounted={onModalMounted}
								issueKey={issueKey}
								issueTypeId={issueTypeId}
								baseUrl={baseUrl}
								locale={locale}
								isOpen={isOpen}
								cloudId={cloudId}
								projectId={projectId}
								onClose={onClose}
								scope={scope}
							/>
						</LazyWait>
					</UFOInteractionIgnore>
				</WorkflowReadonlyModalBoundary>
			)
		);
	}
}

export default WorkflowModal;
