/**
 * @generated SignedSource<<927b42dfe240f291536880f95c76ee45>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type singleLineTextHeading_issueFieldSingleLineTextInlineEditHeading_SingleLineTextHeadingInlineEditView$data = {
  readonly fieldConfig: {
    readonly isEditable: boolean | null | undefined;
    readonly isRequired: boolean | null | undefined;
  } | null | undefined;
  readonly fieldId: string;
  readonly id: string;
  readonly name: string;
  readonly text: string | null | undefined;
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"singleLineTextHeading_issueFieldSingleLineTextReadviewHeading_SingleLineTextHeadingReadView">;
  readonly " $fragmentType": "singleLineTextHeading_issueFieldSingleLineTextInlineEditHeading_SingleLineTextHeadingInlineEditView";
};
export type singleLineTextHeading_issueFieldSingleLineTextInlineEditHeading_SingleLineTextHeadingInlineEditView$key = {
  readonly " $data"?: singleLineTextHeading_issueFieldSingleLineTextInlineEditHeading_SingleLineTextHeadingInlineEditView$data;
  readonly " $fragmentSpreads": FragmentRefs<"singleLineTextHeading_issueFieldSingleLineTextInlineEditHeading_SingleLineTextHeadingInlineEditView">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "singleLineTextHeading_issueFieldSingleLineTextInlineEditHeading_SingleLineTextHeadingInlineEditView",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "ScalarField",
      "name": "text"
    },
    {
      "kind": "FragmentSpread",
      "name": "singleLineTextHeading_issueFieldSingleLineTextReadviewHeading_SingleLineTextHeadingReadView"
    },
    {
      "concreteType": "JiraFieldConfig",
      "kind": "LinkedField",
      "name": "fieldConfig",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isEditable"
        },
        {
          "kind": "ScalarField",
          "name": "isRequired"
        }
      ]
    }
  ],
  "type": "JiraSingleLineTextField"
};

(node as any).hash = "4f37d539ff5672e0b2100173d3bcabf8";

export default node;
