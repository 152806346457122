import { functionWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import { CreateLinkError } from './constants.tsx';
import messages from './messages.tsx';

const duplicateErrorTextMessage = functionWithCondition(
	() => fg('jira-issue-terminology-refresh-m3'),
	() => messages.duplicateErrorTextIssueTermRefresh,
	() => messages.duplicateErrorText,
);

const errorMap = {
	[CreateLinkError.DUPLICATE]: duplicateErrorTextMessage(),
	[CreateLinkError.FORBIDDEN]: messages.forbiddenErrorText,
	[CreateLinkError.ISSUE_ID_INVALID]: messages.issueIdErrorText,
	[CreateLinkError.NO_MATCHING_APP_LINK]: messages.noMatchingApplinkErrorText,
	[CreateLinkError.PAGE_ID_NOT_FOUND]: messages.pageIdNotFoundErrorText,
	[CreateLinkError.UNAUTHORIZED]: messages.unauthorizedErrorText,
	[CreateLinkError.UNAUTHORIZED_PROMPT]: messages.unauthorizedErrorPrompt,
	[CreateLinkError.URL_INVALID]: messages.urlInvalidErrorText,
	[CreateLinkError.URL_REQUIRED]: messages.urlRequiredErrorText,
} as const;

// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ readonly "addconfluencelink.error.duplicate": MessageDescriptorV2; readonly "addconfluencelink.error.page.forbidden": MessageDescriptorV2; ... 6 more ...; readonly "addconfluencelink.error.url.required": MessageDescriptorV2; }'.
// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (errorMessage: string) => errorMap[errorMessage] || messages.genericErrorText;
