// eslint-disable-next-line jira/restricted/styled-components-migration, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled, { css } from 'styled-components';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any,   @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledInput = styled.div<any>(
	{
		padding: `${token('space.050', '4px')} ${token('space.025', '2px')}`,
		borderRadius: '1px',
		overflow: 'auto',
		transition: 'outline-color 0.3s, outline-color 0.3s ease-out',
		display: 'flex',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		minHeight: `${gridSize * 4}px`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		maxHeight: `${gridSize * 7}px`,
		boxSizing: 'border-box',
		alignItems: 'center',
		flexWrap: 'wrap',
		position: 'relative',
		'&:focus': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			outline: `2px solid ${token('color.border.focused', colors.B200)}`,
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isValid }: { isValid: boolean }) =>
		isValid
			? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				css({
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					outline: `1px solid ${token('color.border.input', colors.N300A)}`,
					'&:focus': {
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
						outline: `2px solid ${token('color.border.focused', colors.B200)}`,
					},
				})
			: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				css({
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					outline: `1px solid ${token('color.border.danger', colors.R500)}`,
					'&:focus': {
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
						outline: `2px solid ${token('color.border.danger', colors.R500)}`,
					},
				}),
);
