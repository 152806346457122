import { createSelector } from 'reselect';
import { statusCategoryForId } from '@atlassian/jira-common-constants/src/status-categories.tsx';
import type { ServerRestStatus } from '@atlassian/jira-issue-shared-types/src/common/types/status-type.tsx';
import { STATUS } from '@atlassian/jira-issue-view-configurations/src/index.tsx';
import { fieldPersistedValueSelector } from '../common/state/selectors/field-selector.tsx';

export const statusCategorySelector = createSelector(
	fieldPersistedValueSelector(STATUS),
	(status: ServerRestStatus) => {
		if (!status) {
			return null;
		}
		return statusCategoryForId(status.statusCategory.id);
	},
);
