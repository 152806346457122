import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	setSecurityLevel: {
		id: 'issue-field-security-level.security-level-view.menu-item.set-security-level',
		defaultMessage: 'Set security level',
		description:
			'A call to action to users indicating that they can set the security level of the current issue by clicking over this element',
	},
});
