import { createSelector } from 'reselect';
import {
	toFrontendColor,
	toBackendColor,
	getColorFromValue,
	classicToFrontendColor,
	classicToBackendColor,
	getValueFromColor,
} from '@atlassian/jira-issue-epic-color/src/common/utils.tsx';
import {
	fieldSelector,
	fieldByCustomTypeSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector.tsx';
import { issueTypesSelector } from '@atlassian/jira-issue-view-store/src/selectors/issue-types-selector.tsx';
import {
	ISSUE_TYPE,
	COLOR_CF_TYPE,
	EPIC_COLOR_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';

const currentIssueTypeIdSelector = createSelector(
	fieldSelector(ISSUE_TYPE),
	(issueTypeField) => issueTypeField && issueTypeField.value.id,
);

export const colorFieldIdSelector = createSelector(
	fieldByCustomTypeSelector(COLOR_CF_TYPE),
	fieldByCustomTypeSelector(EPIC_COLOR_TYPE),
	(colorField, classicColorField) =>
		(colorField && colorField.key) || (classicColorField && classicColorField.key),
);

export const colorFieldSelector = createSelector(
	fieldByCustomTypeSelector(COLOR_CF_TYPE),
	(issueColorField) => issueColorField,
);

export const issueColorFieldSelector = createSelector(
	fieldByCustomTypeSelector(COLOR_CF_TYPE),
	(issueColorField) => issueColorField,
);

// @deprecated - use "issueColorFieldSelector" instead
// (still used for logging purposes in jira/src/packages/issue/issue-view-foundation/src/color/index.tsx)
export const epicColorFieldSelector = createSelector(
	fieldByCustomTypeSelector(EPIC_COLOR_TYPE),
	(epicColorField) => epicColorField,
);

export const isColorFieldConfiguredForIssueType = createSelector(
	currentIssueTypeIdSelector,
	colorFieldIdSelector,
	issueTypesSelector,
	(currentIssueTypeId, colorFieldId, allIssueTypes) => {
		if (!allIssueTypes) {
			return false;
		}

		const currentIssueType = allIssueTypes.find((issueType) => issueType.id === currentIssueTypeId);

		if (!currentIssueType || !currentIssueType.fields) {
			return false;
		}

		// @ts-expect-error - TS2532 - Object is possibly 'undefined'.
		return colorFieldId in currentIssueType.fields;
	},
);

export const transformFromStateValueClassicSupport = (
	colorStr?: string | null,
	isClassicColor = false,
): string =>
	isClassicColor
		? getValueFromColor(classicToFrontendColor(colorStr))
		: getValueFromColor(toFrontendColor(colorStr));

export const transformToStateValueClassicSupport = (
	value: string,
	isClassicColor = false,
): string =>
	isClassicColor
		? classicToBackendColor(getColorFromValue(value))
		: toBackendColor(getColorFromValue(value));

export const transformFromStateValue = (colorStr?: string | null): string =>
	getValueFromColor(toFrontendColor(colorStr));

export const transformToStateValue = (value: string): string =>
	toBackendColor(getColorFromValue(value));
