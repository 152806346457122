// used to verify mock is originalEstimate
export const originalEstimateBodyParams = {
	fieldsToForcePresent: 'timetracking',
} as const;

export const originalEstimateEditTestId =
	'issue-field-original-estimate-editview-full.ui.original-estimate.edit';
export const originalEstimateViewTestId = 'issue-field-original-estimate.ui.view';

export const READ_VIEW_CONTAINER_SELECTOR_NAME = 'jira-issue-field-inline-edit-read-view-container';
export const READ_VIEW_CONTAINER_SELECTOR = `[data-component-selector="${READ_VIEW_CONTAINER_SELECTOR_NAME}"]`;

export const HOVER_CONTAINER_SELECTOR_NAME = 'jira-issue-field-original-estimate-hover-container';
export const HOVER_CONTAINER_SELECTOR = `[data-component-selector="${HOVER_CONTAINER_SELECTOR_NAME}"]`;
