import type { ComponentType } from 'react';
import type { ComponentIssueAction } from '@atlassian/jira-issue-view-model/src/issue-actions-type.tsx';
import { getEcosystemAction } from '../../ecosystem-action/index.tsx';
import type { GetEcosystemItemsProps } from './types.tsx';

export const getEcosystemItems = ({ ecosystemActions }: GetEcosystemItemsProps) =>
	ecosystemActions
		.map(getEcosystemAction)
		.filter((ecosystemOperation) => ecosystemOperation.isAllowed) // eslint-disable-next-line @typescript-eslint/no-explicit-any
		.reduce<Array<any>>((dropdownItemList, item) => {
			// @ts-expect-error - TS2774 - This condition will always return true since this function is always defined. Did you mean to call it instead?
			if (item.component) {
				const componentIssueAction: ComponentIssueAction = item;
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const ActionItem: ComponentType<Record<any, any>> = componentIssueAction.component;
				const { key, label } = componentIssueAction;
				dropdownItemList.push({
					Component: ActionItem,
					DialogComponent: componentIssueAction.dialogComponent,
					label,
					key,
				});
			}
			return dropdownItemList;
		}, []);
