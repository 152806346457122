import React from 'react';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import useCreatedDateField from './services/main.tsx';
import { CreatedDate } from './ui/read/main.tsx';

type Props = {
	issueKey: IssueKey;
};

export const CreatedDateField = ({ issueKey }: Props) => {
	const [{ value: createdDate }] = useCreatedDateField({ issueKey });
	return <CreatedDate date={createdDate} />;
};
