import {
	ALL_ACTIVITY,
	APPROVALS,
	COMMENTS,
	CONNECT,
	FORGE,
	HISTORY,
	WORKLOG,
} from '@atlassian/jira-issue-view-common-constants/src/activity-items.tsx';
import type { ActivityItem } from '@atlassian/jira-issue-view-common-types/src/activity-item-type.tsx';

export const ufoNameSuffixForSwitchingToActivityItem = (item: ActivityItem): string => {
	// Intentionally avoiding default case to get exhaustiveness checking. Each of the entries here has a hand-made
	// experience created for it in the performance portal. Therefore, you may want to modify the performance portal
	// if you modify this function.
	//
	// eslint-disable-next-line default-case
	switch (item.type) {
		case ALL_ACTIVITY:
			return 'all';
		case APPROVALS:
			return 'approvals';
		case COMMENTS:
			return 'comments';
		case HISTORY:
			return 'history';
		case WORKLOG:
			return 'worklog';
		case CONNECT:
			return 'connect-app';
		case FORGE:
			return 'forge-app';
	}
};

export const ufoNameForSwitchingToActivityItem = (item: ActivityItem): string =>
	`issue-view-activity-button-${ufoNameSuffixForSwitchingToActivityItem(item)}`;
