import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noScorecards: {
		id: 'compass-common.common.no-scorecards',
		defaultMessage: 'No scorecards',
		description: 'Shown when there are no scorecards applied to a component.',
	},
	summaryTextForFailing: {
		id: 'compass-common.common.summary-text-for-failing',
		defaultMessage: '{numerator} {numerator, plural, one {failing} other {failing}}',
		description: 'Summary text for failing scorecards',
	},
	summaryTextForHealthy: {
		id: 'compass-common.common.summary-text-for-healthy',
		defaultMessage: 'All passing',
		description: 'Summary text for healthy scorecards',
	},
	summaryTextForNeedsAttention: {
		id: 'compass-common.common.summary-text-for-needs-attention',
		defaultMessage: '{numerator} {numerator, plural, one {needs} other {need}} attention',
		description: 'Summary text for needs attention scorecards',
	},
});
