import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	dueToday: {
		id: 'date-with-urgency-indicator.due-today',
		defaultMessage: 'Due today',
		description: 'Tooltip text shown when a date is due today',
	},
	dueIn: {
		id: 'date-with-urgency-indicator.due-in',
		defaultMessage: '{daysBetween} {daysBetween, plural, one {day} other {days}} overdue',
		description: 'Tooltip shown when a date is past X days. Eg: Due in 2 days',
	},
});
