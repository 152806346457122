import React, { Component } from 'react';
import { getMetricsContext, type Metrics } from './index.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default () => (WrappedComponent: any) =>
	// eslint-disable-next-line jira/react/no-class-components, @typescript-eslint/no-explicit-any
	class WithMetricsConsumer extends Component<any> {
		static displayName = `WithMetricsConsumer(${
			WrappedComponent.displayName || WrappedComponent.name
		})`;

		MetricsContext = getMetricsContext();

		render() {
			const { MetricsContext } = this;
			return (
				<MetricsContext.Consumer>
					{(metrics: Metrics) => <WrappedComponent {...this.props} metrics={metrics} />}
				</MetricsContext.Consumer>
			);
		}
	};
