import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	pending: {
		id: 'software-releases-version-detail-issue-list.issues.issue-card.delivery.deployment-data.status-icon.pending',
		defaultMessage: 'Pending',
		description: 'Icon shown when a deployment status is pending',
	},
	inProgress: {
		id: 'software-releases-version-detail-issue-list.issues.issue-card.delivery.deployment-data.status-icon.in-progress',
		defaultMessage: 'In-progress',
		description: 'Icon shown when a deployment status is in-progress',
	},
	successful: {
		id: 'software-releases-version-detail-issue-list.issues.issue-card.delivery.deployment-data.status-icon.successful',
		defaultMessage: 'Successful',
		description: 'Icon shown when a deployment status is successful',
	},
	failed: {
		id: 'software-releases-version-detail-issue-list.issues.issue-card.delivery.deployment-data.status-icon.failed',
		defaultMessage: 'Failed',
		description: 'Icon shown when a deployment status is failed',
	},
	rolledBack: {
		id: 'software-releases-version-detail-issue-list.issues.issue-card.delivery.deployment-data.status-icon.rolled-back',
		defaultMessage: 'Rolled back',
		description: 'Icon shown when a deployment status is rolled back',
	},
	cancelled: {
		id: 'software-releases-version-detail-issue-list.issues.issue-card.delivery.deployment-data.status-icon.cancelled',
		defaultMessage: 'Canceled',
		description: 'Icon shown when a deployment status is cancelled',
	},
	unknown: {
		id: 'software-releases-version-detail-issue-list.issues.issue-card.delivery.deployment-data.status-icon.unknown',
		defaultMessage: 'Unknown',
		description: 'Icon shown when a deployment status is unknown',
	},
});
