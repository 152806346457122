import { Component } from 'react';
import matchJiraUrl from '@atlassian/jira-common-match-jira-url/src/index.tsx';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import debouncePromise from '@atlassian/jira-platform-debounce-promise/src/ui/debounced-promise/index.tsx';
import type { Props, State } from './types.tsx';
import { transformOptions } from './utils.tsx';

// eslint-disable-next-line jira/react/no-class-components
export default class IssueOptionService extends Component<Props, State> {
	static defaultProps = {
		initialValue: '',
	};

	state = {
		options: [],
		isLoading: false,
		error: null,
	};

	componentDidMount() {
		this.fetchData(this.props.initialValue);
	}

	fetchData = (inputValue: string) => {
		const { issueKey, projectId, jql, labels } = this.props;

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const matchingIssue = matchJiraUrl(inputValue, window.location.host);

		const query = matchingIssue || inputValue;
		this.setState({ isLoading: true, error: null });
		return fetchJson(
			`/rest/api/3/issue/picker?currentIssueKey=${issueKey}&currentProjectId=${projectId}&showSubTasks=true&showSubTaskParent=true&currentJQL=${jql}&query=${query}`,
		)
			.then((results) => {
				this.setState({ options: transformOptions(results, labels), isLoading: false });
				return results;
			})
			.catch((error) => {
				this.setState({
					isLoading: false,
					options: [],
					error,
				});
				return [];
			});
	};

	debouncedFetchData = debouncePromise(this.fetchData, 300);

	render() {
		const { children } = this.props;
		const { options, isLoading, error } = this.state;
		return children({
			options,
			isLoading,
			error,
			loadOptions: this.debouncedFetchData,
		});
	}
}
