import type { CardCover } from '@atlassian/jira-issue-view-common-types/src/card-cover-type.tsx';

// CARD_COVER_FETCH_SUCCESS
export const CARD_COVER_FETCH_SUCCESS = 'CARD_COVER_FETCH_SUCCESS' as const;

export const cardCoverFetchSuccess = (cardCover: CardCover) => ({
	type: CARD_COVER_FETCH_SUCCESS,
	payload: {
		cardCover,
	},
});

export type CardCoverFetchSuccess = ReturnType<typeof cardCoverFetchSuccess>;

// CARD_COVER_FETCH_FAILURE
export const CARD_COVER_FETCH_FAILURE = 'CARD_COVER_FETCH_FAILURE' as const;

export const cardCoverFetchFailure = () => ({
	type: CARD_COVER_FETCH_FAILURE,
});

export type CardCoverFetchFailure = ReturnType<typeof cardCoverFetchFailure>;

// CARD_COVER_UPDATE_REQUEST
export const CARD_COVER_UPDATE_REQUEST = 'CARD_COVER_UPDATE_REQUEST' as const;

export type CardCoverUpdateRequestAction = {
	type: typeof CARD_COVER_UPDATE_REQUEST;
	payload: {
		currentAttachmentId: number | null;
		attachmentId?: number | null;
	};
};

export const cardCoverUpdateRequest = (
	currentAttachmentId: number | null,
	attachmentId?: number | null,
): CardCoverUpdateRequestAction => ({
	type: CARD_COVER_UPDATE_REQUEST,
	payload: {
		currentAttachmentId,
		attachmentId,
	},
});

export type CardCoverUpdateRequest = ReturnType<typeof cardCoverUpdateRequest>;

// CARD_COVER_UPDATE_SUCCESS
export const CARD_COVER_UPDATE_SUCCESS = 'CARD_COVER_UPDATE_SUCCESS' as const;

export const cardCoverUpdateSuccess = () => ({
	type: CARD_COVER_UPDATE_SUCCESS,
});

export type CardCoverUpdateSuccess = ReturnType<typeof cardCoverUpdateSuccess>;

// CARD_COVER_UPDATE_FAILURE
export const CARD_COVER_UPDATE_FAILURE = 'CARD_COVER_UPDATE_FAILURE' as const;

export const cardCoverUpdateFailure = (attachmentId: number | null) => ({
	type: CARD_COVER_UPDATE_FAILURE,
	payload: {
		attachmentId,
	},
});

export type CardCoverUpdateFailure = ReturnType<typeof cardCoverUpdateFailure>;

export type CardCoverActions =
	| CardCoverFetchSuccess
	| CardCoverFetchFailure
	| CardCoverUpdateRequest
	| CardCoverUpdateSuccess
	| CardCoverUpdateFailure;
