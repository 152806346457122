import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	startWatching: {
		id: 'issue-view-watchers.watchers.dropdown.change-watch-state.start-watching',
		defaultMessage: 'Start watching',
		description: 'Clicking in this element will make the user start watching the issue',
	},
	stopWatching: {
		id: 'issue-view-watchers.watchers.dropdown.change-watch-state.stop-watching',
		defaultMessage: 'Stop watching',
		description: 'Clicking in this element will make the user stop watching the issue',
	},
});
