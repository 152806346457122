import React, { useCallback } from 'react';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import { useOptionallyControlledEditingState } from '@atlassian/jira-issue-field-optional-editing-state-manager/src/index.tsx';
import { ISSUE_RESTRICTION_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { Props } from './types.tsx';

export const RestrictIssue = ({ onClick, label, itemKey }: Props) => {
	const [, setIsIssueRestrictOpen] = useOptionallyControlledEditingState(
		false,
		ISSUE_RESTRICTION_TYPE,
	);

	const handleClick = useCallback(() => {
		setIsIssueRestrictOpen(true);
		onClick?.(itemKey);
	}, [setIsIssueRestrictOpen, onClick, itemKey]);

	return (
		<DropdownItem key="restrictIssue" onClick={handleClick}>
			{label}
		</DropdownItem>
	);
};
