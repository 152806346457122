import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { IssueAdjustmentsState } from '../../types.tsx';

/**
 * Initialize screen tabs store data with an empty array
 */
export const initializeNoScreenTab =
	() =>
	({ setState }: StoreActionApi<IssueAdjustmentsState>) => {
		setState({
			screenTabs: [],
		});
	};
