/**
 * @generated SignedSource<<1d73dd2d1b4a1b89105d8c4e42c0868b>>
 * @relayHash 8c0492e1284c1d4744543fd8b08b4584
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 6b19bb940c54cd38ef844328ec6f0991bd7283e7d8f2ef601cf38853656aea4b

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type JiraUpdateStatusFieldInput = {
  id: string;
  statusTransitionId: number;
};
export type ui_issueViewLayoutStatusField_IssueViewStatusField_Mutation$variables = {
  input: JiraUpdateStatusFieldInput;
};
export type ui_issueViewLayoutStatusField_IssueViewStatusField_Mutation$data = {
  readonly jira: {
    readonly updateStatusByQuickTransition: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type ui_issueViewLayoutStatusField_IssueViewStatusField_Mutation$rawResponse = {
  readonly jira: {
    readonly updateStatusByQuickTransition: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type ui_issueViewLayoutStatusField_IssueViewStatusField_Mutation = {
  rawResponse: ui_issueViewLayoutStatusField_IssueViewStatusField_Mutation$rawResponse;
  response: ui_issueViewLayoutStatusField_IssueViewStatusField_Mutation$data;
  variables: ui_issueViewLayoutStatusField_IssueViewStatusField_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "concreteType": "JiraMutation",
    "kind": "LinkedField",
    "name": "jira",
    "plural": false,
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "JiraStatusFieldPayload",
        "kind": "LinkedField",
        "name": "updateStatusByQuickTransition",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "name": "success"
          },
          {
            "concreteType": "MutationError",
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "message"
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "ui_issueViewLayoutStatusField_IssueViewStatusField_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ui_issueViewLayoutStatusField_IssueViewStatusField_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "6b19bb940c54cd38ef844328ec6f0991bd7283e7d8f2ef601cf38853656aea4b",
    "metadata": {},
    "name": "ui_issueViewLayoutStatusField_IssueViewStatusField_Mutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "1bc17054d631435b7dfbd1ebf7b30316";

export default node;
