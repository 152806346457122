import type { Observable } from 'rxjs/Observable';

import 'rxjs/add/operator/retry';

/*
 * Warning - pushing this to 3 seems to fail the bitbucket tests
 * in the 'when the user does not have a valid Jira session' block -
 * it causes the issue to be rendered and the expected behaviour of
 * showing 'Log In' not to happen
 */
const DEFAULT_RETRY_ATTEMPTS = 2;

/*
 *  Multiple type safe non-variadic functions were created as
 *  there were difficulties getting a variadic function that
 *  could handle an arbitrary number of observables working with
 *  flow - the definitions in src/common/flowtype/libdefs/
 *  are set up to exclusively handle third party libraries, not
 *  in-repo first party code.
 */
export const withRetries2 = <T0, T1>(
	o0: Observable<T0>,
	o1: Observable<T1>,
): [Observable<T0>, Observable<T1>] => [
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	o0.retry(DEFAULT_RETRY_ATTEMPTS) as Observable<T0>,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	o1.retry(DEFAULT_RETRY_ATTEMPTS) as Observable<T1>,
];

export const withRetries3 = <T0, T1, T2>(
	o0: Observable<T0>,
	o1: Observable<T1>,
	o2: Observable<T2>,
): [Observable<T0>, Observable<T1>, Observable<T2>] => [
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	o0.retry(DEFAULT_RETRY_ATTEMPTS) as Observable<T0>,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	o1.retry(DEFAULT_RETRY_ATTEMPTS) as Observable<T1>,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	o2.retry(DEFAULT_RETRY_ATTEMPTS) as Observable<T2>,
];

export const withRetries4 = <T0, T1, T2, T3>(
	o0: Observable<T0>,
	o1: Observable<T1>,
	o2: Observable<T2>,
	o3: Observable<T3>,
): [Observable<T0>, Observable<T1>, Observable<T2>, Observable<T3>] => [
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	o0.retry(DEFAULT_RETRY_ATTEMPTS) as Observable<T0>,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	o1.retry(DEFAULT_RETRY_ATTEMPTS) as Observable<T1>,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	o2.retry(DEFAULT_RETRY_ATTEMPTS) as Observable<T2>,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	o3.retry(DEFAULT_RETRY_ATTEMPTS) as Observable<T3>,
];

export const withRetries6 = <T0, T1, T2, T3, T4, T5>(
	o0: Observable<T0>,
	o1: Observable<T1>,
	o2: Observable<T2>,
	o3: Observable<T3>,
	o4: Observable<T4>,
	o5: Observable<T5>,
): [
	Observable<T0>,
	Observable<T1>,
	Observable<T2>,
	Observable<T3>,
	Observable<T4>,
	Observable<T5>,
] => [
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	o0.retry(DEFAULT_RETRY_ATTEMPTS) as Observable<T0>,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	o1.retry(DEFAULT_RETRY_ATTEMPTS) as Observable<T1>,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	o2.retry(DEFAULT_RETRY_ATTEMPTS) as Observable<T2>,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	o3.retry(DEFAULT_RETRY_ATTEMPTS) as Observable<T3>,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	o4.retry(DEFAULT_RETRY_ATTEMPTS) as Observable<T4>,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	o5.retry(DEFAULT_RETRY_ATTEMPTS) as Observable<T5>,
];

export const withRetries9 = <T0, T1, T2, T3, T4, T5, T6, T7, T8>(
	o0: Observable<T0>,
	o1: Observable<T1>,
	o2: Observable<T2>,
	o3: Observable<T3>,
	o4: Observable<T4>,
	o5: Observable<T5>,
	o6: Observable<T6>,
	o7: Observable<T7>,
	o8: Observable<T8>,
): [
	Observable<T0>,
	Observable<T1>,
	Observable<T2>,
	Observable<T3>,
	Observable<T4>,
	Observable<T5>,
	Observable<T6>,
	Observable<T7>,
	Observable<T8>,
] => [
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	o0.retry(DEFAULT_RETRY_ATTEMPTS) as Observable<T0>,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	o1.retry(DEFAULT_RETRY_ATTEMPTS) as Observable<T1>,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	o2.retry(DEFAULT_RETRY_ATTEMPTS) as Observable<T2>,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	o3.retry(DEFAULT_RETRY_ATTEMPTS) as Observable<T3>,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	o4.retry(DEFAULT_RETRY_ATTEMPTS) as Observable<T4>,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	o5.retry(DEFAULT_RETRY_ATTEMPTS) as Observable<T5>,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	o6.retry(DEFAULT_RETRY_ATTEMPTS) as Observable<T6>,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	o7.retry(DEFAULT_RETRY_ATTEMPTS) as Observable<T7>,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	o8.retry(DEFAULT_RETRY_ATTEMPTS) as Observable<T8>,
];

export const withRetries11 = <T0, T1, T2, T3, T4, T5, T6, T7, T8, T9, T10>(
	o0: Observable<T0>,
	o1: Observable<T1>,
	o2: Observable<T2>,
	o3: Observable<T3>,
	o4: Observable<T4>,
	o5: Observable<T5>,
	o6: Observable<T6>,
	o7: Observable<T7>,
	o8: Observable<T8>,
	o9: Observable<T9>,
	o10: Observable<T10>,
): [
	Observable<T0>,
	Observable<T1>,
	Observable<T2>,
	Observable<T3>,
	Observable<T4>,
	Observable<T5>,
	Observable<T6>,
	Observable<T7>,
	Observable<T8>,
	Observable<T9>,
	Observable<T10>,
] => [
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	o0.retry(DEFAULT_RETRY_ATTEMPTS) as Observable<T0>,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	o1.retry(DEFAULT_RETRY_ATTEMPTS) as Observable<T1>,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	o2.retry(DEFAULT_RETRY_ATTEMPTS) as Observable<T2>,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	o3.retry(DEFAULT_RETRY_ATTEMPTS) as Observable<T3>,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	o4.retry(DEFAULT_RETRY_ATTEMPTS) as Observable<T4>,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	o5.retry(DEFAULT_RETRY_ATTEMPTS) as Observable<T5>,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	o6.retry(DEFAULT_RETRY_ATTEMPTS) as Observable<T6>,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	o7.retry(DEFAULT_RETRY_ATTEMPTS) as Observable<T7>,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	o8.retry(DEFAULT_RETRY_ATTEMPTS) as Observable<T8>,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	o9.retry(DEFAULT_RETRY_ATTEMPTS) as Observable<T9>,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	o10.retry(DEFAULT_RETRY_ATTEMPTS) as Observable<T10>,
];

export const withRetries14 = <T0, T1, T2, T3, T4, T5, T6, T7, T8, T9, T10, T11, T12, T13>(
	o0: Observable<T0>,
	o1: Observable<T1>,
	o2: Observable<T2>,
	o3: Observable<T3>,
	o4: Observable<T4>,
	o5: Observable<T5>,
	o6: Observable<T6>,
	o7: Observable<T7>,
	o8: Observable<T8>,
	o9: Observable<T9>,
	o10: Observable<T10>,
	o11: Observable<T11>,
	o12: Observable<T12>,
	o13: Observable<T13>,
): [
	Observable<T0>,
	Observable<T1>,
	Observable<T2>,
	Observable<T3>,
	Observable<T4>,
	Observable<T5>,
	Observable<T6>,
	Observable<T7>,
	Observable<T8>,
	Observable<T9>,
	Observable<T10>,
	Observable<T11>,
	Observable<T12>,
	Observable<T13>,
] => [
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	o0.retry(DEFAULT_RETRY_ATTEMPTS) as Observable<T0>,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	o1.retry(DEFAULT_RETRY_ATTEMPTS) as Observable<T1>,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	o2.retry(DEFAULT_RETRY_ATTEMPTS) as Observable<T2>,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	o3.retry(DEFAULT_RETRY_ATTEMPTS) as Observable<T3>,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	o4.retry(DEFAULT_RETRY_ATTEMPTS) as Observable<T4>,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	o5.retry(DEFAULT_RETRY_ATTEMPTS) as Observable<T5>,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	o6.retry(DEFAULT_RETRY_ATTEMPTS) as Observable<T6>,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	o7.retry(DEFAULT_RETRY_ATTEMPTS) as Observable<T7>,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	o8.retry(DEFAULT_RETRY_ATTEMPTS) as Observable<T8>,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	o9.retry(DEFAULT_RETRY_ATTEMPTS) as Observable<T9>,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	o10.retry(DEFAULT_RETRY_ATTEMPTS) as Observable<T10>,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	o11.retry(DEFAULT_RETRY_ATTEMPTS) as Observable<T11>,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	o12.retry(DEFAULT_RETRY_ATTEMPTS) as Observable<T12>,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	o13.retry(DEFAULT_RETRY_ATTEMPTS) as Observable<T13>,
];
