import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';

// Helper function to see if experiment is running, and if so, which cohort the tenant is in
export const isJsmLoomExperimentEnabled = () => {
	// eslint-disable-next-line jira/ff/unsafe-no-exposure
	const [configLoomJSM] = UNSAFE_noExposureExp('loom_jsm_agent_response_touchpoint');
	const cohort = configLoomJSM.get('cohort', 'not-enrolled');
	// Adding feature gate check as loom_jsm_agent_response_touchpoint experiment is running in production.
	const enabled = (cohort === 'control' || cohort === 'variation') && fg('loom_jsm_agent_ui');
	return { enabled, cohort };
};
