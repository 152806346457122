import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { emptyIssueData } from '../../common/constants.tsx';
import useIssueViewNonCriticalData from '../use-issue-view-non-critical-data/index.tsx';

export const useConnectBackgroundScripts = (issueKey: IssueKey) => {
	const [issueState] = useIssueViewNonCriticalData(issueKey);
	const { value: issueData = emptyIssueData, isReady = false } = issueState ?? {};
	const { backgroundScripts: value = [] } = issueData;

	return [{ value, isReady }] as const;
};

export default useConnectBackgroundScripts;
