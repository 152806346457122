import React from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import {
	AnalyticsEventToProps,
	FireOperationalAnalytics,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import type { StatusTransition } from '../../../../../common/types.tsx';
import messages from './messages.tsx';

export const DropdownItemWithAnalytics = AnalyticsEventToProps('dropdownItem', {
	onClick: 'clicked',
})(DropdownItem);

export type Props = {
	initialised: boolean;
	onSelect: (selection: StatusTransition, event: UIAnalyticsEvent) => void | Promise<void>;
	transitions: StatusTransition[];
	canManageAutomations: boolean;
};

export const TransitionsSubMenu = ({
	initialised,
	transitions,
	canManageAutomations,
	onSelect,
}: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	if (!initialised || transitions.length === 0) {
		return null;
	}

	const onClick = (
		transition: StatusTransition,
		_: React.SyntheticEvent,
		analyticsEvent: UIAnalyticsEvent,
	) => {
		const localEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'dropdown',
		});
		fireUIAnalytics(localEvent, 'loopActionsMenu');

		onSelect(transition, analyticsEvent);
	};

	const transitionItemGroupProps = canManageAutomations
		? { title: formatMessage(messages.workflowTransitions) }
		: {};

	return (
		<DropdownItemGroup {...transitionItemGroupProps}>
			{transitions.map((transition) => (
				<Tooltip key={transition.id} content={transition.name}>
					<DropdownItemWithAnalytics
						onClick={(event: React.SyntheticEvent, analyticsEvent: UIAnalyticsEvent) =>
							onClick(transition, event, analyticsEvent)
						}
					>
						{transition.name}
					</DropdownItemWithAnalytics>
				</Tooltip>
			))}
			<FireOperationalAnalytics
				action="exposed"
				actionSubject="feature"
				actionSubjectId="loopActions"
				attributes={{
					controlType: 'menu',
				}}
			/>
		</DropdownItemGroup>
	);
};

export default TransitionsSubMenu;
