import type { LinkedIssuesProgress, SingleStatus } from './types.tsx';

export const EMPTY_LINKED_ISSUES_PROGRESS: LinkedIssuesProgress = Object.freeze({
	total: 0,
	distribution: {},
});

const getSingleStatusFromAggregated = (aggregated: number[]): SingleStatus | undefined => {
	if (aggregated[0] + aggregated[1] + aggregated[2] !== 1) {
		return undefined;
	}
	switch (1) {
		case aggregated[0]:
			return {
				category: 'new',
			};
		case aggregated[1]:
			return {
				category: 'indeterminate',
			};
		case aggregated[2]:
			return {
				category: 'done',
			};
		default:
			return undefined;
	}
};

export const issuesProgressFromAggregate = (aggregated: number[]) => ({
	total: aggregated.reduce((a, b) => a + b, 0),
	distribution: {
		new: aggregated[0],
		indeterminate: aggregated[1],
		done: aggregated[2],
	},
	singleStatus: getSingleStatusFromAggregated(aggregated),
});
