import React, { type PropsWithChildren } from 'react';
import { styled } from '@compiled/react';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import { useIsFieldBusy } from '@atlassian/jira-issue-field-base/src/services/field-config-service/context.tsx';

type Props = {
	isBusy: boolean;
};

type PropsItemBusyWrapperByIssueKey = {
	issueKey: string;
	fieldId: string;
};

export const ItemBusyWrapper = ({ children, isBusy }: PropsWithChildren<Props>): JSX.Element => (
	<Container>
		<ContentsContainer isBusy={isBusy}>{children}</ContentsContainer>
		{isBusy && (
			<SpinnerContainer>
				<Spinner size="medium" />
			</SpinnerContainer>
		)}
	</Container>
);

export const ItemBusyWrapperByIssueKey = ({
	children,
	issueKey,
	fieldId,
}: PropsWithChildren<PropsItemBusyWrapperByIssueKey>) => {
	const isBusy = useIsFieldBusy({ issueKey, fieldId });

	return <ItemBusyWrapper isBusy={isBusy}>{children}</ItemBusyWrapper>;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Container = styled.div({
	position: 'relative',
	flex: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ContentsContainer = styled.div<{ isBusy: boolean }>(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) =>
		props.isBusy && {
			opacity: token('opacity.loading', '0.22'),
			pointerEvents: 'none',
		},
	{ width: '100%' },
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SpinnerContainer = styled.div({
	display: 'flex',
	position: 'absolute',
	top: 0,
	right: 0,
	bottom: 0,
	left: 0,
	alignItems: 'center',
	justifyContent: 'center',
});
