import { Browser } from '@atlaskit/media-picker';
import type { AttachmentPickerPopupCloseSuccess } from '@atlassian/jira-issue-view-store/src/actions/attachment-popup-actions.tsx';
import withMediaPickerPropsProvider, {
	type EventHandlers,
} from '../common/with-media-picker-props-provider/index.tsx';

export type BaseProps = {
	eventHandlers?: EventHandlers;
};

export type Props = BaseProps & {
	isOpen: boolean;
	onClose: () => AttachmentPickerPopupCloseSuccess;
};

export const BrowserMediaPicker: React.FC<BaseProps> = withMediaPickerPropsProvider(Browser);

export default BrowserMediaPicker;
