import type { ValueDecoration } from '@atlassian/jira-polaris-domain-field/src/decoration/types.tsx';
import {
	convertRulesToLogic,
	isDecorationWithLogic,
} from '@atlassian/jira-polaris-domain-field/src/decoration/utils.tsx';
import type { FieldType } from '@atlassian/jira-polaris-domain-field/src/field-types/types.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { FieldRemote } from '@atlassian/jira-polaris-remote-field/src/types.tsx';

type UpdateParams = {
	fieldKey: FieldKey;
	fieldType?: FieldType;
	issueTypeId: string | undefined;
	fieldRemote: FieldRemote;
	existingDecorations: ValueDecoration[];
	newDecorations: ValueDecoration[];
};

export const remapValueDecorations = (decorations: ValueDecoration[], fieldType?: FieldType) =>
	decorations.length > 0
		? {
				valueDecorations: decorations.map((decoration) => ({
					...(decoration.backgroundColor ? { backgroundColor: decoration.backgroundColor } : {}),
					...(decoration.emoji ? { emoji: decoration.emoji } : {}),
					highlightContainer: decoration.highlightContainer,
					logic: isDecorationWithLogic(decoration)
						? decoration.logic
						: convertRulesToLogic(decoration.rules, fieldType),
				})),
			}
		: {};

export const performJiraConfigurationUpdate = ({
	fieldKey,
	fieldType,
	issueTypeId,
	fieldRemote,
	existingDecorations,
	newDecorations,
}: UpdateParams) => {
	const oldConfiguration = remapValueDecorations(existingDecorations, fieldType);
	const configuration = remapValueDecorations(newDecorations, fieldType);
	return fieldRemote.updateFieldConfiguration({
		fieldKey,
		issueTypeId: issueTypeId ?? '',
		oldConfiguration,
		configuration,
		type: 'updateFieldDecorations',
	});
};
