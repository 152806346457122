import { useCallback, useEffect } from 'react';
import type { DocNode } from '@atlaskit/adf-schema';
import { doc, p } from '@atlaskit/adf-utils/builders';
import { useIntl } from '@atlassian/jira-intl';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useQueryParams } from '@atlassian/jira-software-router-utils/src/services/query-params/index.tsx';
import {
	QUICK_REPLY_ANALYTICS_SCOPE,
	QUICK_REPLY_ANALYTICS_INVALID,
	QUICK_REPLY_ANALYTICS_VALID,
	emoji,
} from '../../common/constants.tsx';
import messages from '../../common/messages.tsx';
import { isQuickReply } from '../../common/utils.tsx';

export const useHandleQuickReply = (
	onChange?: (arg1: DocNode) => void,
): { hasQuickReply: boolean } => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage, locale } = useIntl();
	const [{ quickReply }, setQueryParams] = useQueryParams('quickReply');
	const hasInvalidQuickReply = !quickReply || !onChange || !isQuickReply(quickReply);

	const fireAnalytics = useCallback(
		(valid: boolean) => {
			fireTrackAnalytics(
				createAnalyticsEvent({}),
				`${QUICK_REPLY_ANALYTICS_SCOPE} ${valid ? QUICK_REPLY_ANALYTICS_VALID : QUICK_REPLY_ANALYTICS_INVALID}`,
				{ quickReply, locale },
			);
		},
		[createAnalyticsEvent, locale, quickReply],
	);

	useEffect(() => {
		if (hasInvalidQuickReply) {
			/**
			 * To ensure nothing is going wrong with localisation etc, we want to fire an event if we
			 * *have* a query parameter value that is not in the current list, but we don't want analytics
			 * noise from when the query parameter is empty etc
			 */
			if (quickReply) {
				fireAnalytics(false);
			}
			return;
		}

		fireAnalytics(true);
		setQueryParams({ quickReply: undefined }, { updateType: 'replace' });
		onChange(doc(p(`${emoji[quickReply]} ${formatMessage(messages[quickReply])}`)));
	}, [quickReply, onChange, setQueryParams, hasInvalidQuickReply, formatMessage, fireAnalytics]);

	return { hasQuickReply: !hasInvalidQuickReply };
};
