import React from 'react';
import { styled } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { type IntlShape, useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type {
	DevOpsEnvironmentCategory,
	deploymentData_softwareReleasesVersionDetailIssueList$key,
} from '@atlassian/jira-relay/src/__generated__/deploymentData_softwareReleasesVersionDetailIssueList.graphql';
import { VIEWPORT_WIDTH_LARGE } from '../../../../../common/constants.tsx';
import messages from './messages.tsx';
import { StatusIcon } from './status-icon/index.tsx';

type FormatMessage = IntlShape['formatMessage'];
type Props = {
	onDeploymentClicked: () => void;
	jiraIssueDevOpsSummarisedEntities: deploymentData_softwareReleasesVersionDetailIssueList$key;
};

const mapEnvironmentCategory = (
	category: DevOpsEnvironmentCategory,
	formatMessage: FormatMessage,
): string => {
	switch (category) {
		case 'PRODUCTION':
			return formatMessage(messages.production);
		case 'STAGING':
			return formatMessage(messages.staging);
		case 'TESTING':
			return formatMessage(messages.testing);
		case 'DEVELOPMENT':
			return formatMessage(messages.development);
		case 'UNMAPPED':
			return formatMessage(messages.unmapped);
		default:
			return formatMessage(messages.unmapped);
	}
};

export const DeploymentData = ({
	onDeploymentClicked,
	jiraIssueDevOpsSummarisedEntities: jiraIssueSummarisedEntities,
}: Props) => {
	const { formatMessage } = useIntl();
	const { summarisedDeployments } = useFragment(
		graphql`
			fragment deploymentData_softwareReleasesVersionDetailIssueList on DevOpsSummarisedEntities {
				summarisedDeployments {
					deploymentEnvironment {
						category
					}
					...statusIcon_softwareReleasesVersionDetailIssueList
				}
			}
		`,
		jiraIssueSummarisedEntities,
	);

	// If null, there is nothing to display
	if (summarisedDeployments == null) return null;
	// Same logic as <StatusIcon/> component that falls back to Unknown if null
	const category = summarisedDeployments.deploymentEnvironment?.category
		? summarisedDeployments.deploymentEnvironment.category
		: 'UNMAPPED';

	return (
		<BaseContainer>
			<Button
				spacing="none"
				appearance="subtle-link"
				onClick={(_, analyticsEvent: UIAnalyticsEvent) => {
					onDeploymentClicked();
					fireUIAnalytics(analyticsEvent, 'deploymentSummaryCell');
				}}
			>
				<ContentsContainer>
					<StatusContainer>
						<StatusIcon jiraSummarisedDeployments={summarisedDeployments} />
					</StatusContainer>
					<DescriptionInternalContainer>
						{mapEnvironmentCategory(category, formatMessage)}
					</DescriptionInternalContainer>
				</ContentsContainer>
			</Button>
		</BaseContainer>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BaseContainer = styled.div({
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentsContainer = styled.div({
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DescriptionInternalContainer = styled.div({
	marginLeft: token('space.100', '8px'),
	textOverflow: 'ellipsis',
	overflow: 'hidden',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	[`@media screen and (min-width: ${VIEWPORT_WIDTH_LARGE}px)`]: {
		display: 'block',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	[`@media screen and (max-width: ${VIEWPORT_WIDTH_LARGE}px)`]: {
		display: 'none',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StatusContainer = styled.div({
	height: '24px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N70),
});
