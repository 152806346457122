/**
 * @generated SignedSource<<104e7a7c30e42c208fb29f3aec68910d>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type storyPointEstimate_issueFieldStoryPointEstimateInlineEditFull_StoryPointEstimateInlineEditViewIsEditable_fragmentRef$data = {
  readonly id: string;
  readonly name: string;
  readonly number: number | null | undefined;
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"storyPointEstimate_issueFieldStoryPointEstimateReadviewFull_StoryPointEstimateReadView">;
  readonly " $fragmentType": "storyPointEstimate_issueFieldStoryPointEstimateInlineEditFull_StoryPointEstimateInlineEditViewIsEditable_fragmentRef";
};
export type storyPointEstimate_issueFieldStoryPointEstimateInlineEditFull_StoryPointEstimateInlineEditViewIsEditable_fragmentRef$key = {
  readonly " $data"?: storyPointEstimate_issueFieldStoryPointEstimateInlineEditFull_StoryPointEstimateInlineEditViewIsEditable_fragmentRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"storyPointEstimate_issueFieldStoryPointEstimateInlineEditFull_StoryPointEstimateInlineEditViewIsEditable_fragmentRef">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "storyPointEstimate_issueFieldStoryPointEstimateInlineEditFull_StoryPointEstimateInlineEditViewIsEditable_fragmentRef",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "storyPointEstimate_issueFieldStoryPointEstimateReadviewFull_StoryPointEstimateReadView"
    },
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "kind": "ScalarField",
      "name": "number"
    }
  ],
  "type": "JiraNumberField"
};

(node as any).hash = "c258cd7d5b4392c1e7419c5721e2a6cd";

export default node;
