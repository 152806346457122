const FIELD_ID_ARI_REGEX = /ari:cloud:jira:.*?:issuefieldvalue\/.*?\/(.*)/;
const PRIORITY_ID_ARI_REGEX = /ari:cloud:jira:.*?:priority\/activation\/.*?\/(.*)/;
const USER_ID_ARI_REGEX = /ari:cloud:identity::user\/(.*)/;

export const getFieldIdFromAri = (fieldIdOrAri?: string): string | undefined => {
	const match = fieldIdOrAri?.match(FIELD_ID_ARI_REGEX);

	return match ? match[1] : fieldIdOrAri;
};

export const getPriorityIdFromAri = (priorityIdOrAri?: string): string | undefined => {
	const match = priorityIdOrAri?.match(PRIORITY_ID_ARI_REGEX);

	return match ? match[1] : priorityIdOrAri;
};

export const getUserIdFromAri = (userIdOrAri?: string): string | undefined => {
	const match = userIdOrAri?.match(USER_ID_ARI_REGEX);

	return match ? match[1] : userIdOrAri;
};
