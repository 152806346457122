import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	tooltip: {
		id: 'issue-activity-feed.activity-sorting-toggle.tooltip',
		defaultMessage: 'Reverse sort direction',
		description: 'Tooltip message for the activity feed toggle sort button',
	},
	newestFirstLabel: {
		id: 'issue-activity-feed.activity-sorting-toggle.newest-first-label',
		defaultMessage: 'Newest first',
		description: 'Label for the newest first sort button option',
	},
	oldestFirstLabel: {
		id: 'issue-activity-feed.activity-sorting-toggle.oldest-first-label',
		defaultMessage: 'Oldest first',
		description: 'Label for the oldest first sort button option',
	},
});
