import {
	getUserProperty,
	setUserProperties,
} from '@atlassian/jira-common-rest/src/api/latest/user-properties/index.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { HAS_SEEN_QUICK_ACTIONS_CHANGEBOARDING_MESSAGE_USER_PROPERTIES_KEY } from '@atlassian/jira-issue-view-common-constants/src/onboarding-constants.tsx';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';

export const COMPONENT_ID = 'quickActionsChangeboarding';
export const PACKAGE_NAME = 'issue.issue-view-foundation.quick-add';
export const TEAM_NAME = 'issue.issue-view-foundation.quick-add';
export const CODE_LOCATION = 'getHasSeenQAChangeboarding';

export const getHasSeenQAChangeboardingFromUserPreferences = (
	accountId: AccountId | null,
): Promise<boolean> =>
	accountId
		? getUserProperty<boolean>(
				accountId,
				HAS_SEEN_QUICK_ACTIONS_CHANGEBOARDING_MESSAGE_USER_PROPERTIES_KEY,
			).catch((error) => {
				if (error.statusCode === 404) {
					return false;
				}

				fireErrorAnalytics({
					error,
					meta: {
						id: COMPONENT_ID,
						packageName: PACKAGE_NAME,
						teamName: TEAM_NAME,
					},
				});

				log.safeErrorWithoutCustomerData(
					CODE_LOCATION,
					'Error reading data from getHasSeenQuickActionsChangeboarding - UserProperty',
					error,
				);
				return true;
			})
		: new Promise((resolve: (result: Promise<boolean> | boolean) => void) => {
				const errorMessage = 'Failed to fetch getHasSeenQuickActionsChangeboarding - no accountId';
				fireErrorAnalytics({
					error: new Error(errorMessage),
					meta: {
						id: COMPONENT_ID,
						packageName: PACKAGE_NAME,
						teamName: TEAM_NAME,
					},
				});

				log.safeErrorWithoutCustomerData(CODE_LOCATION, errorMessage);
				resolve(true);
			});

export const setHasSeenQAChangeboardingInUserPreferences = (
	shouldShowWelcomeMessage: boolean,
	accountId: AccountId | null,
): Promise<boolean> | null =>
	accountId
		? setUserProperties(
				accountId,
				HAS_SEEN_QUICK_ACTIONS_CHANGEBOARDING_MESSAGE_USER_PROPERTIES_KEY,
				String(shouldShowWelcomeMessage),
			)
		: null;
