// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	connectToConfluenceToAuthenticate: {
		id: 'issue.issue-view.confluence-pages.connect-to-app-link',
		defaultMessage: 'Authenticate with Confluence to see this page',
		description:
			'Message that indicates that a user is not connected to a Confluence instance that was linked through a tunnel',
	},
});
