import React, { memo, useCallback } from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useDateTimeField } from '@atlassian/jira-issue-field-date-time/src/services/use-date-time-field/index.tsx';
import { DateTimeField } from '@atlassian/jira-issue-field-date-time/src/ui/main.tsx';
import { FieldDescription } from '@atlassian/jira-issue-field-description/src/ui/index.tsx';
import { FieldHeading } from '@atlassian/jira-issue-field-heading/src/index.tsx';
import {
	FieldHeadingTitle,
	SideBySideField,
	FieldWrapper,
	FieldHeadingIconsContainer,
} from '@atlassian/jira-issue-field-heading/src/styled.tsx';
import { FieldPin } from '@atlassian/jira-issue-field-pin/src/index.tsx';
import { genericMessages } from '@atlassian/jira-issue-view-common-constants/src/context-items-messages.tsx';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button/index.tsx';
import { useProjectKey } from '@atlassian/jira-project-context-service/src/main.tsx';
import {
	useCanAdministerJira,
	useCanAdministerProject,
} from '@atlassian/jira-project-permissions-service/src/main.tsx';
import { FieldEditIcon } from '@atlassian/jira-issue-field-edit-icon/src/FieldEditIcon.tsx';
import { useEditFieldConfigContext } from '@atlassian/jira-issue-view-services/src/edit-field-config-context/context.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { Props } from './types.tsx';

const DateTime = ({ fieldId: fieldKey, label, area, onUpdate, showEditIcon }: Props) => {
	const { formatMessage } = useIntl();
	const issueKey = useIssueKey();
	const showPinButton = getShowPinButton(area);
	const [{ fieldConfig }] = useDateTimeField({ issueKey, fieldKey });
	const fieldLabel = fieldConfig?.title || label;
	const { onEditFieldConfigClick } = useEditFieldConfigContext();

	let isJiraAdmin = false;
	let isProjectAdmin = false;

	let handleClickFieldEdit = useCallback(noop, []);

	if (fg('issue_view_field_config_edit')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		handleClickFieldEdit = useCallback(
			() => onEditFieldConfigClick(fieldKey),
			[fieldKey, onEditFieldConfigClick],
		);
		if (issueKey) {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			const projectKey = useProjectKey(issueKey);
			// eslint-disable-next-line react-hooks/rules-of-hooks
			isJiraAdmin = useCanAdministerJira(projectKey);
			// eslint-disable-next-line react-hooks/rules-of-hooks
			isProjectAdmin = useCanAdministerProject(projectKey);
		}
	}

	return (
		<DateTimeFieldWrapper data-testid={`issue.views.field.date-inline-edit.${fieldKey}`}>
			<FieldHeading fieldId={fieldKey}>
				<FieldHeadingTitle>{fieldLabel}</FieldHeadingTitle>
				<FieldHeadingIconsContainer
					isCustomFieldConfigurationEnabled={fg('issue_view_field_config_edit')}
				>
					{issueKey !== undefined && fieldKey !== undefined && (
						<FieldDescription issueKey={issueKey} fieldKey={fieldKey} label={fieldLabel} />
					)}
					{showPinButton === true && <FieldPin fieldId={fieldKey} label={fieldLabel} />}
					{showEditIcon && fg('issue_view_field_config_edit') && (
						<FieldEditIcon
							onClick={handleClickFieldEdit}
							hasEditPermission={isJiraAdmin || isProjectAdmin}
						/>
					)}
				</FieldHeadingIconsContainer>
			</FieldHeading>
			<SideBySideField>
				<DateTimeField
					isFitContainerWidthReadView
					issueKey={issueKey}
					fieldKey={fieldKey}
					noValueText={formatMessage(genericMessages.noValue)}
					onUpdate={onUpdate}
				/>
			</SideBySideField>
		</DateTimeFieldWrapper>
	);
};

export default memo<Props>(DateTime);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const DateTimeFieldWrapper = styled(FieldWrapper)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > div > div > div > div > div > div > div': {
		width: '100%',
	},
});
