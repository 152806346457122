export const CLICKTHROUGH_BANNER = 'jira.user.issue.clickthrough-banner.lastinteracted' as const;
export const CHANGEBOARDING = 'jira.user.issue.changeboarding.lastinteracted' as const;
export const ATTACHMENTS_VIEW = 'jira.user.issue.attachment.view' as const;
export const SIDEBAR_RESIZE_RATIO = 'jira.user.issue.sidebar.resize-ratio' as const;
export const HIDE_DONE_SUBTASKS_FILTER = 'jira.user.issue.filter.hide-done-subtasks' as const;

// Allow dynamic preference keys, for pinned fields "issue.pinned.PROJECT_KEY"
export type UserPreferences = string;

// Legacy Gira myPreferences
export type MyPreferences = Record<UserPreferences, string | null>;
