import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { ViewAccessLevel } from '@atlassian/jira-polaris-domain-view/src/view-access/types.tsx';
import { getJpdViewsServicePathPrefix } from '@atlassian/jira-polaris-lib-jpd-views-service/src/index.tsx';
import type { FetchCurrentUserPermissionsResponse, FetchViewAccessResponse } from './types.tsx';

const getFetchCurrentUserPermissionsUrl = (viewUUID: string) =>
	`${getJpdViewsServicePathPrefix()}/v1/views/${viewUUID}/access/my-access`;

const getFetchViewPermissionsConfigUrl = (viewUUID: string) =>
	`${getJpdViewsServicePathPrefix()}/v1/views/${viewUUID}/access`;

const getUpdateViewAccessLevelUrl = (viewUUID: string) =>
	`${getJpdViewsServicePathPrefix()}/v1/views/${viewUUID}/access/level`;

const getAddViewAccessPrincipalsUrl = (viewUUID: string) =>
	`${getJpdViewsServicePathPrefix()}/v1/views/${viewUUID}/access/set`;

const getRemoveViewAccessPrincipalsUrl = (viewUUID: string) =>
	`${getJpdViewsServicePathPrefix()}/v1/views/${viewUUID}/access/remove`;

export const fetchCurrentUserPermissions = (
	viewUUID: string,
): Promise<FetchCurrentUserPermissionsResponse> =>
	fetchJson(getFetchCurrentUserPermissionsUrl(viewUUID));

export const fetchViewPermissionsConfig = (
	viewUUID: string,
	abortSignal?: AbortSignal,
): Promise<FetchViewAccessResponse> =>
	fetchJson(getFetchViewPermissionsConfigUrl(viewUUID), {
		signal: abortSignal,
	});

export const applyUpdateViewAccessLevel = ({
	viewUUID,
	accessLevel,
}: {
	viewUUID: string;
	accessLevel: ViewAccessLevel;
}): Promise<void> =>
	performPostRequest(getUpdateViewAccessLevelUrl(viewUUID), {
		body: JSON.stringify({
			accessLevel,
		}),
	});

export const applyAddViewAccessPrincipals = (
	viewUUID: string,
	accounts: { id: string; role: string }[],
	groups: { id: string; role: string }[],
): Promise<void> =>
	performPostRequest(getAddViewAccessPrincipalsUrl(viewUUID), {
		body: JSON.stringify({
			accounts,
			groups,
		}),
	});

export const applyRemoveViewAccessPrincipals = (
	viewUUID: string,
	accounts: string[],
	groups: string[],
): Promise<void> =>
	performPostRequest(getRemoveViewAccessPrincipalsUrl(viewUUID), {
		body: JSON.stringify({
			accounts,
			groups,
		}),
	});
