// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	timeRemainingFieldLabel: {
		id: 'issue.context.time-remaining-field.label',
		defaultMessage: 'Time remaining',
		description: 'Issue label message for the time remaining field',
	},
	includeSubtasksLabel: {
		id: 'issue.time-tracking.include-subtasks',
		defaultMessage: 'Include subtasks',
		description:
			'Label for control where the user toggles whether they want time tracking data to include information from subtasks or not.',
	},
	includeChildrenLabel: {
		id: 'issue.time-tracking.include-children',
		defaultMessage: 'Include child issues',
		description:
			'Label for control where the user toggles whether they want time tracking data to include information from children or not.',
	},
	timeSpentRemainingLabel: {
		id: 'issue.context.time-remaining-spent.label',
		defaultMessage: '{timeSpent} logged, {timeRemaining} remaining- edit {fieldName}',
		description:
			'An accessibility label for an edit button in an input field. Pressing the button allows the user to edit the contents of the input field.',
	},
	extendedTimeRemainingLabel: {
		id: 'issue.context.extended-time-remaining-field.label',
		defaultMessage: 'No time logged, {timeRemaining} remaining- edit {fieldName}',
		description:
			'An accessibility label for an edit button in an input field. Pressing the button allows the user to edit the contents of the input field.',
	},
	extendedTimeSpentLabel: {
		id: 'issue.context.extended-time-spent-field.label',
		defaultMessage: '{timeSpent} logged- edit {fieldName}',
		description:
			'An accessibility label for an edit button in an input field. Pressing the button allows the user to edit the contents of the input field.',
	},
	noTimeLoggedLabel: {
		id: 'issue.context.no-time-logged-field.label',
		defaultMessage: 'No time logged- edit {fieldName}',
		description:
			'An accessibility label for an edit button in an input field. Pressing the button allows the user to edit the contents of the input field.',
	},
	includeChildrenLabelIssueTermRefresh: {
		id: 'issue.time-tracking.include-children-issue-term-refresh',
		defaultMessage: 'Include child issues',
		description:
			'Label for control where the user toggles whether they want time tracking data to include information from children or not.',
	},
});
