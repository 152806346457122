// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'issue.epic-issues-title',
		defaultMessage: 'Issues in this epic',
		description: '',
	},
	titleChildIssues: {
		id: 'issue.epic-issues-title-child-issues',
		defaultMessage: 'Child issues',
		description: 'Label for a panel that shows child issues',
	},
	titleIssueTermRefresh: {
		id: 'issue.epic-issues-title-issue-term-refresh',
		defaultMessage: 'Issues in this epic',
		description: '',
	},
	titleChildIssuesIssueTermRefresh: {
		id: 'issue.epic-issues-title-child-issues-issue-term-refresh',
		defaultMessage: 'Child issues',
		description: 'Label for a panel that shows child issues',
	},
});
