import React, { useEffect } from 'react';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { SmartLinkEntryPoint } from './entrypoint.tsx';
import type { SmartLinkProps } from './index.tsx';

const entryPointParams = {};

export const LazySmartLink = (props: SmartLinkProps) => {
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		SmartLinkEntryPoint,
		entryPointParams,
	);

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		entryPointActions.load();
	}, [entryPointActions]);

	return (
		<JiraEntryPointContainer
			id="jira.issue-field.smart-link"
			packageName="issue-field-smart-link"
			entryPointReferenceSubject={entryPointReferenceSubject}
			runtimeProps={props}
		/>
	);
};
