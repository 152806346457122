import type { Action } from '@atlassian/react-sweet-state';
import type { State, Props } from '../../types.tsx';
import { getRefreshedConnectionProperties } from './utils.tsx';

export const refreshConnectionFieldValues =
	(fieldKey: string): Action<State, Props> =>
	({ getState, setState }, props) => {
		const state = getState();

		const properties = getRefreshedConnectionProperties(fieldKey, state, props);

		setState({ properties });
	};
