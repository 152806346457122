/* eslint-disable @atlassian/relay/unused-fields */
import React, { useCallback, type ComponentPropsWithoutRef } from 'react';
import { useFragment, graphql, useMutation } from 'react-relay';
import { useFieldOptionsOverrides } from '@atlassian/jira-issue-field-base/src/services/field-config-service/context.tsx';
import type { RadioValueItemShape } from '@atlassian/jira-issue-internal-fields/src/radio-select/radio-field-view.tsx';
import RadioInlineEditView from '@atlassian/jira-issue-internal-fields/src/radio-select/radio-inline-edit-view.tsx';
import {
	transformFromStateValue,
	transformToStateValue,
	type RadioOption,
} from '@atlassian/jira-issue-view-base/src/context/radio-select/radio-select-transformer.tsx';
import {
	filterValues,
	messages,
} from '@atlassian/jira-issue-view-base/src/context/radio-select/radio-select-view.tsx';
import { genericMessages } from '@atlassian/jira-issue-view-common-constants/src/context-items-messages.tsx';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button/index.tsx';
import { getDisplayName } from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-field.tsx';
import {
	useConnectRelayField,
	type ConnectedLayoutProps,
	type PropsCallback,
} from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-relay-field.tsx';
import { ConnectedRelayFieldWrapper } from '@atlassian/jira-issue-view-common-views/src/connect-field/relay-field/field-wrapper.tsx';
import { mapNodes } from '@atlassian/jira-relay-utils/src/utils/map-nodes/index.tsx';
import type {
	ui_issueViewLayoutRadioSelectField_IssueViewRadioSelectField$key as ComponentsInlineEditFragment,
	ui_issueViewLayoutRadioSelectField_IssueViewRadioSelectField$data as ComponentsInlineEditFragmentData,
} from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutRadioSelectField_IssueViewRadioSelectField.graphql';
import type { ui_issueViewLayoutRadioSelectField_IssueViewRadioSelectField_Mutation } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutRadioSelectField_IssueViewRadioSelectField_Mutation.graphql';

type ComponentValueShape = RadioValueItemShape;
type AggValueShape = Pick<ComponentsInlineEditFragmentData, 'selectedOption'>['selectedOption'];

type AdditionalProps = Pick<
	ComponentPropsWithoutRef<typeof RadioInlineEditView>,
	'options' | 'placeholder' | 'noValueText' | 'showPinButton' | 'isRequired'
>;

export type IssueViewRadioSelectFieldProps = ConnectedLayoutProps<ComponentsInlineEditFragment>;

export const toComponentValueShape = (fieldData?: AggValueShape): RadioOption | null => {
	if (!fieldData) {
		return null;
	}
	return {
		value: fieldData?.value ?? '',
		id: fieldData?.id ?? '',
		optionId: fieldData?.optionId,
	};
};

const transformComponentValueToAggShape = (componentValue?: ComponentValueShape): AggValueShape => {
	if (!componentValue || !componentValue.value) {
		return null;
	}

	return {
		id: componentValue?.value,
		value: componentValue?.label,
		optionId: componentValue?.optionId ?? '',
	};
};

export function IssueViewRadioSelectField(props: IssueViewRadioSelectFieldProps) {
	const data = useFragment<ComponentsInlineEditFragment>(
		graphql`
			fragment ui_issueViewLayoutRadioSelectField_IssueViewRadioSelectField on JiraRadioSelectField {
				id
				fieldId
				type
				name
				description
				__typename

				fieldConfig {
					isEditable
					isRequired
				}
				issue {
					key
				}
				selectedOption {
					id
					value
					optionId
				}
				fieldOptions {
					edges {
						node {
							# id and value are required by the component
							id @required(action: NONE)
							value @required(action: NONE)
							isDisabled
							optionId
						}
					}
				}
			}
		`,
		props.fragmentKey,
	);

	const issueKey = data?.issue?.key ?? '';
	const [allowedValuesOverrides] = useFieldOptionsOverrides({
		issueKey,
		fieldKey: data.fieldId,
	});

	const [commit] =
		useMutation<ui_issueViewLayoutRadioSelectField_IssueViewRadioSelectField_Mutation>(graphql`
			mutation ui_issueViewLayoutRadioSelectField_IssueViewRadioSelectField_Mutation(
				$input: JiraUpdateRadioSelectFieldInput!
			) @raw_response_type {
				jira @optIn(to: ["JiraIssueFieldMutations"]) {
					updateRadioSelectField(input: $input) {
						success
						errors {
							message
						}
						field {
							id
							selectedOption {
								id
								value
							}
						}
					}
				}
			}
		`);

	const getComponentProps = useCallback<
		PropsCallback<
			ComponentsInlineEditFragment,
			ComponentsInlineEditFragmentData,
			ComponentValueShape | undefined,
			AggValueShape,
			AdditionalProps
		>
	>(
		({ intl, softRefreshCallbacks }) => {
			const options = filterValues(
				mapNodes(data.fieldOptions),
				allowedValuesOverrides ?? undefined,
			);
			return {
				jiraIssueField: data,

				value: transformFromStateValue(toComponentValueShape(data?.selectedOption)) ?? undefined,
				onValueConfirm(componentValue) {
					const newAggValue = transformComponentValueToAggShape(componentValue);
					softRefreshCallbacks.onSubmit(newAggValue);
					commit({
						variables: {
							input: {
								id: data.id,
								operation: {
									id: newAggValue?.id ?? null,
									operation: 'SET',
								},
							},
						},
						onCompleted: (mutationData) => {
							if (mutationData.jira?.updateRadioSelectField?.success) {
								softRefreshCallbacks.onSubmitSucceeded(newAggValue);
							} else {
								softRefreshCallbacks.onSubmitFailed();
							}
						},
						onError() {
							softRefreshCallbacks.onSubmitFailed();
						},
						optimisticResponse: {
							jira: {
								updateRadioSelectField: {
									success: true,
									errors: [],
									field: {
										id: data.id,
										selectedOption: newAggValue,
									},
								},
							},
						},
					});
				},

				additionalProps: {
					options,
					placeholder: intl.formatMessage(messages.placeholder, {
						fieldName: data?.name,
					}),
					noValueText: intl.formatMessage(genericMessages.noValue),
					showPinButton: getShowPinButton(props.area),
					isEditable: !!data?.fieldConfig?.isEditable,
					isRequired: !!data?.fieldConfig?.isRequired,
					fieldId: data.fieldId,
					transformToStateValue,
				},
			};
		},
		[allowedValuesOverrides, commit, data, props.area],
	);

	const connectField = useConnectRelayField(props, data, getComponentProps);
	const componentName = getDisplayName(RadioInlineEditView);

	return (
		<ConnectedRelayFieldWrapper componentName={componentName}>
			<RadioInlineEditView {...connectField.componentProps} />
		</ConnectedRelayFieldWrapper>
	);
}
