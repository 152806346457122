/** @jsx jsx */
import React, { useEffect } from 'react';
import { css, jsx } from '@compiled/react';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import SettingsIcon from '@atlaskit/icon/glyph/settings';
import Lozenge from '@atlaskit/lozenge';
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { appConfigurationPromptEntryPoint } from '@atlassian/jira-development-app-configuration-prompt/entrypoint.tsx';
import type { Location } from '@atlassian/jira-development-app-configuration-prompt/src/common/types.tsx';
import { SummaryItem } from '@atlassian/jira-development-summary-common/src/ui/summary-item/index.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useIsAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-admin/index.tsx';
import { useIsSiteAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-site-admin/index.tsx';
import messages from './messages.tsx';

type Props = {
	location: Location;
	panelHasOtherPrompts?: boolean;
};

export const AppConfigurationPromptSummary = ({
	location,
	panelHasOtherPrompts = false,
}: Props) => {
	const cloudId = useCloudId();
	const { formatMessage } = useIntl();
	const isSiteAdmin = useIsSiteAdmin();
	const isJiraAdmin = useIsAdmin();
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		appConfigurationPromptEntryPoint,
		{
			location,
			cloudId,
		},
	);

	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		if (isSiteAdmin || isJiraAdmin) {
			entryPointActions.load();
		}
	}, [entryPointActions, isSiteAdmin, isJiraAdmin]);

	if (!isSiteAdmin && !isJiraAdmin) {
		return null;
	}

	return (
		<JiraEntryPointContainer
			id="jswDevelopmentSummaryAppConfigPromptEntryPoint"
			packageName="development-field"
			entryPointReferenceSubject={entryPointReferenceSubject}
			errorFallback="flag"
			runtimeProps={{
				location,
				cloudId,
				getDropdownTrigger: ({ ref, onClick, appIcon, ...props }) => (
					<div css={wrapperStyles}>
						<SummaryItem
							title={
								<>
									{location === 'DEVELOPMENT_PANEL' && formatMessage(messages.codeToolsPromptLabel)}
									{location === 'RELEASES_PANEL' &&
										formatMessage(messages.deploymentToolsPromptLabel)}
									{location === 'SECURITY_PANEL' &&
										formatMessage(messages.securityToolsPromptLabel)}
								</>
							}
							icon={appIcon || <SettingsIcon label="finish-tool-setup-icon" />}
							secondaryData={<ChevronDownIcon label="providers-dropdown" />}
							reference={ref}
							{...props}
							onClick={onClick}
							linkFormatting
							data-testid="development-summary-app-config-prompt-field.ui.summary-item"
							disableAnimationOnUpdates
						/>
						<Box xcss={lozengeWrapperStyles}>
							<Lozenge appearance="moved">{formatMessage(messages.pendingLozenge)}</Lozenge>
						</Box>
						{panelHasOtherPrompts && <div css={separatorStyles} />}
					</div>
				),
			}}
		/>
	);
};

const wrapperStyles = css({
	display: 'flex',
	flexDirection: 'column',
	width: '100%',
	marginLeft: '0',
});

const lozengeWrapperStyles = xcss({
	marginTop: 'space.100',
});

const separatorStyles = css({
	borderRadius: '3px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderBottom: `2px solid ${token('color.border', colors.N40A)}`,
	marginTop: token('space.150', '12px'),
	marginBottom: token('space.150', '12px'),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginLeft: '-5px',
	transform: 'translateX(-5px)',
});
