import { FIELD_TYPES } from '@atlassian/jira-polaris-domain-field/src/field-types/index.tsx';
import { createGetFieldMapping } from '../../selectors/fields.tsx';
import type { State, Props, PropertyMaps } from '../../types.tsx';
import { getConnectionFieldValuesFromLinkedIssues } from '../common/connection/index.tsx';

export const getRefreshedConnectionProperties = (
	fieldKey: string,
	state: State,
	props: Props,
): PropertyMaps => {
	let newProperties = { ...state.properties };

	const fieldMapping = createGetFieldMapping(fieldKey)(state, props);

	if (fieldMapping?.field?.type !== FIELD_TYPES.CONNECTION) {
		return state.properties;
	}

	state.ids.forEach((localIssueId) => {
		const fieldValue = getConnectionFieldValuesFromLinkedIssues({
			localIssueId,
			fieldKey,
			state,
			props,
		});

		newProperties = fieldMapping.setImmutable(newProperties, localIssueId, fieldValue);
	});

	return newProperties;
};
