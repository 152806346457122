import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import { fireOperationalAnalytics } from '@atlassian/jira-product-analytics-bridge';

export const LOCAL_STORAGE_ID = 'jsm-linked-alerts';
export const TEXT_SEARCH_KEY = 'text-search';
export const SAVED_SEARCH_ID_KEY = 'saved-search-id';

export const localStorageProvider = createLocalStorageProvider(LOCAL_STORAGE_ID);

export const getFilterFromLocalStorage = (
	key: string,
	analyticsEvent: UIAnalyticsEvent,
): string | null => {
	try {
		return localStorageProvider.get(key);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		fireOperationalAnalytics(analyticsEvent, 'getFilterFromLocalStorage failed');
		return null;
	}
};

export const setFilterInLocalStorage = (
	key: string,
	value: string,
	analyticsEvent: UIAnalyticsEvent,
) => {
	try {
		localStorageProvider.set(key, value);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (_: any) {
		fireOperationalAnalytics(analyticsEvent, 'setFilterInLocalStorage failed');
	}
};
