import React, { Component, type MouseEvent } from 'react';
import type { CreateUIAnalyticsEvent, UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import EditorAddIcon from '@atlaskit/icon/core/migration/add';
import { fireUiAnalytics } from '@atlassian/jira-analytics-web-react/src/utils/fire-ui-event.tsx';
// eslint-disable-next-line jira/wrm/no-load-bridge
import { loadBridge } from '@atlassian/jira-common-bridge/src';
import {
	type ProjectType,
	SOFTWARE_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import logger from '@atlassian/jira-common-util-logging/src/log.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import type { CallbackPayload } from '@atlassian/jira-issue-create-extensibility/src/common/utils/lifecycle-events/types.tsx';
import type { Payload as GICPayload } from '@atlassian/jira-issue-create/src/common/types/index.tsx';
import type { IssueLinks } from '@atlassian/jira-issue-shared-types/src/common/types/linked-issue-type.tsx';
import type { ServerCreatedLinkedIssue } from '@atlassian/jira-issue-view-common-types/src/linked-issue-type.tsx';
import { ISSUE_VIEW_CREATE_LINKED_ISSUE } from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/constants.tsx';
import { withNewGICProvider } from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/main.tsx';
import type { TriggerPointKeyType } from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/types.tsx';
import type { BaseUrl, IssueId, IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import messages from './messages.tsx';
import transformIssueLink from './transformer.tsx';

type Props = {
	baseUrl: BaseUrl;
	sourceIssueId: IssueId;
	sourceIssueKey: IssueKey;
	projectType?: ProjectType;
	// eslint-disable-next-line jira/react/handler-naming
	openIssueCreateModal?: (arg1: {
		onIssueCreate?: (data: { createdIssueDetails: ServerCreatedLinkedIssue }) => void;
		payload: GICPayload;
		triggerPointKey: TriggerPointKeyType;
		isCreateLinkedIssue?: boolean;
		callbackPayload?: CallbackPayload;
	}) => void;
	currentIssueCreateVisibility?: boolean;
	onLinkedIssueCreated: (
		newIssueLinks: IssueLinks,
		analyticsOrMaybePostAnalytics: UIAnalyticsEvent,
		analyticsEvent?: UIAnalyticsEvent,
	) => void;
	intl: IntlShape;
	createAnalyticsEvent: CreateUIAnalyticsEvent;
};

export const LOGGER_LOCATION =
	'issue.views.issue-base-content.issue-links.add.create-linked-issue-button.view';
// eslint-disable-next-line jira/react/no-class-components
class CreateLinkedIssueButton extends Component<Props> {
	showCreateLinkedIssueDialog = async () => {
		const { baseUrl, sourceIssueId, sourceIssueKey, onLinkedIssueCreated, createAnalyticsEvent } =
			this.props;
		this.showLoadingIndicator();
		try {
			// eslint-disable-next-line jira/wrm/no-load-bridge
			const wrmRequire = await loadBridge({ name: 'wrm/require' });
			await new Promise((resolve: (result: Promise<never>) => void) => {
				// @ts-expect-error - TS2571 - Object is of type 'unknown'.
				wrmRequire(
					// eslint-disable-next-line jira/wrm/no-wr-prefix
					'wr!com.atlassian.jira.jira-quick-edit-plugin:create-linked-issue',
					resolve,
				);
			});
			// eslint-disable-next-line jira/wrm/no-load-bridge
			const createLinkedIssueDialog = await loadBridge({
				name: 'quick-edit/create-linked-issue/create-linked-issue-dialog',
			});
			// @ts-expect-error - TS2571 - Object is of type 'unknown'.
			createLinkedIssueDialog
				.show({
					sourceIssueId,
					sourceIssueKey,
				})
				.getQuickForm()
				// @ts-expect-error - TS7006 - Parameter 'event' implicitly has an 'any' type. | TS7031 - Binding element 'createdIssueDetails' implicitly has an 'any' type.
				.bind('issueCreated', (event, { createdIssueDetails }) => {
					const handleLinkedIssueCreated = () => {
						if (
							ff('corex-operandi-issue-view-additional-logging_l6hj8') ||
							fg('operandi_issue_view_additional_logging')
						) {
							onLinkedIssueCreated(
								transformIssueLink(createdIssueDetails, sourceIssueId, baseUrl),
								createAnalyticsEvent({
									action: 'linkedToProject',
								}),
							);
						} else {
							// @ts-expect-error - TS2554 - Expected 2 arguments, but got 1.
							onLinkedIssueCreated(transformIssueLink(createdIssueDetails, sourceIssueId, baseUrl));
						}
					};

					/**
					 * Any error thrown in this callback will block the create linked issue dialog from closing.
					 * We need to catch the error and log it to avoid blocking the dialog from closing.
					 */
					try {
						handleLinkedIssueCreated();
					} catch (error) {
						fireErrorAnalytics({
							meta: {
								id: 'create-linked-issue-button-view',
								packageName: 'jira-issue-view-base',
								teamName: 'gryffindor',
							},
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							error: error as Error,
							sendToPrivacyUnsafeSplunk: true,
						});
					}
				});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			this.hideLoadingIndicator();
			logger.safeErrorWithoutCustomerData(LOGGER_LOCATION, error);
		}
	};

	onCreateLinkedIssueClick = (event: MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
		if (this.props.currentIssueCreateVisibility === true) {
			this.props.openIssueCreateModal?.({
				payload: {
					customFieldsToPreserveOnFormRecreation: ['issuelinks'],
					defaultValues: {
						issueLinks: {
							linkedIssueKeys: [this.props.sourceIssueKey],
						},
					},
					...(fg('create_linked_issues_trigger_point_issue_view') && {
						additionalContext: {
							linkedIssue: {
								sourceIssueId: this.props.sourceIssueId,
							},
						},
					}),
				},
				triggerPointKey: ISSUE_VIEW_CREATE_LINKED_ISSUE,
				...(fg('create_linked_issues_trigger_point_issue_view') && {
					isCreateLinkedIssue: true,
					isModalLoadInContext: true,
				}),
				callbackPayload: {
					id: ISSUE_VIEW_CREATE_LINKED_ISSUE,
					data: {
						sourceIssueId: this.props.sourceIssueId,
						baseUrl: this.props.baseUrl,
					},
				},
			});
		} else {
			this.showCreateLinkedIssueDialog();
		}

		fireUiAnalytics(analyticsEvent, {
			name: 'createLinkedIssue',
		});
	};

	showLoadingIndicator = async () => {
		try {
			// eslint-disable-next-line jira/wrm/no-load-bridge
			const loadingIndicator = await loadBridge({
				name: 'quick-edit/util/loading-indicator',
				wrmKeys: 'wrc!com.atlassian.jira.jira-quick-edit-plugin:loading-indicator',
			});
			// @ts-expect-error - TS2571 - Object is of type 'unknown'.
			loadingIndicator.showLoadingIndicator();
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			logger.safeErrorWithoutCustomerData(LOGGER_LOCATION, error);
		}
	};

	hideLoadingIndicator = async () => {
		try {
			// eslint-disable-next-line jira/wrm/no-load-bridge
			const loadingIndicator = await loadBridge({
				name: 'quick-edit/util/loading-indicator',
				wrmKeys: 'wrc!com.atlassian.jira.jira-quick-edit-plugin:loading-indicator',
			});
			// @ts-expect-error - TS2571 - Object is of type 'unknown'.
			loadingIndicator.hideLoadingIndicator();
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			logger.safeErrorWithoutCustomerData(LOGGER_LOCATION, error);
		}
	};

	render() {
		const {
			intl: { formatMessage },
		} = this.props;

		return (
			<Button
				interactionName="create-linked-issue-button-click"
				spacing="compact"
				onClick={this.onCreateLinkedIssueClick}
				appearance={
					isVisualRefreshEnabled() && fg('jira_nav4_beta_drop_1') ? 'subtle-link' : 'link'
				}
				iconBefore={
					<EditorAddIcon color="currentColor" label="" spacing="none" LEGACY_size="small" />
				}
				/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
				testId="issue.views.issue-base.content.issue-links.add.create-linked-issue-button.create-linked-issue-button"
			>
				{formatMessage(
					expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
						? messages.createLinkedIssueButtonLabelIssueTermRefresh
						: messages.createLinkedIssueButtonLabel,
				)}
			</Button>
		);
	}
}

const CreateLinkedIssueWithNewGIC = withNewGICProvider<Props>(CreateLinkedIssueButton);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (props: Props) => {
	if (
		// eslint-disable-next-line jira/ff/no-preconditioning
		(fg('render_modern_global_issue_create_modal_experience') &&
			fg('create_linked_issues_trigger_point_issue_view')) ||
		props.projectType === SOFTWARE_PROJECT
	) {
		return <CreateLinkedIssueWithNewGIC {...props} />;
	}
	return <CreateLinkedIssueButton {...props} />;
};
