import React from 'react';
import { useFragment, graphql } from 'react-relay';
import { Box, xcss } from '@atlaskit/primitives';
import { FieldDescriptionIcon } from '@atlassian/jira-issue-field-description-icon/src/ui/index.tsx';
import {
	MultilineFieldHeading,
	MultilineFieldHeadingTitle,
} from '@atlassian/jira-issue-field-heading/src/styled.tsx';
import { useInlineEditFieldInjections } from '@atlassian/jira-issue-field-injections/src/controllers/inline-edit-injections-context/index.tsx';
import MessageFieldView from '@atlassian/jira-issue-field-message/src/ui/view/index.tsx';
import { FieldPin } from '@atlassian/jira-issue-field-pin/src/index.tsx';
import type { Area } from '@atlassian/jira-issue-view-common-types/src/connect-field-type.tsx';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button/index.tsx';
import type { ui_issueViewLayoutSingleLineTextMessageField_IssueViewSingleLineTextMessageField$key } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutSingleLineTextMessageField_IssueViewSingleLineTextMessageField.graphql';

export interface IssueViewSingleLineTextMessageFieldProps {
	area?: Area;
	fragmentKey: ui_issueViewLayoutSingleLineTextMessageField_IssueViewSingleLineTextMessageField$key;
}

// Duplicates code from src/packages/issue/issue-view-base/src/context/message-cf/index.tsx
// Duplication was chosen as composition of components makes it easier to understand.
// Relay is used instead of sweet-state.
export const IssueViewSingleLineTextMessageField = ({
	area,
	fragmentKey,
}: IssueViewSingleLineTextMessageFieldProps) => {
	const { overriding } = useInlineEditFieldInjections();
	const showPinButton = getShowPinButton(area);
	const data =
		useFragment<ui_issueViewLayoutSingleLineTextMessageField_IssueViewSingleLineTextMessageField$key>(
			graphql`
				fragment ui_issueViewLayoutSingleLineTextMessageField_IssueViewSingleLineTextMessageField on JiraSingleLineTextField {
					__typename
					name
					fieldId
					description
					text
				}
			`,
			fragmentKey,
		);

	const label = overriding.overrideLabel(data.name);
	const description = overriding.overrideDescription(data.description);

	return (
		<Box xcss={fieldContainerStyles}>
			<MultilineFieldHeading
				data-testid={`issue-field-heading-styled-field-heading.${data.fieldId}`}
			>
				<MultilineFieldHeadingTitle>{label}</MultilineFieldHeadingTitle>
				{description && <FieldDescriptionIcon description={description} />}
				{showPinButton && <FieldPin fieldId={data.fieldId} label={label} />}
			</MultilineFieldHeading>
			<Box
				xcss={messageFieldWrapperStyles}
				testId={`issue.views.field.single-line-text.read-view.${data.fieldId}`}
			>
				<MessageFieldView value={data.text ?? null} />
			</Box>
		</Box>
	);
};

const messageFieldWrapperStyles = xcss({
	paddingTop: 'space.050',
});

const fieldContainerStyles = xcss({
	marginBottom: 'space.200',
});
