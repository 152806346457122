import {
	getUserProperty,
	setUserProperties,
} from '@atlassian/jira-common-rest/src/api/latest/user-properties/index.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { UserBranchSettings } from '../../common/types.tsx';

export const getUserBranchConfigTemplates = (
	accountId: AccountId | null,
	propertyKey: string,
): Promise<UserBranchSettings | null | unknown> =>
	accountId
		? getUserProperty(accountId, propertyKey).catch((error) => {
				// If we can't find key, we know user never save his branch config template
				if (error.statusCode === 404) {
					return null;
				}

				log.safeErrorWithoutCustomerData(
					'getUserBranchConfigTemplates',
					`Error reading data from getUserBranchConfigTemplates with property key ${propertyKey}`,
					error,
				);
				return null;
			})
		: new Promise((resolve: (result: null) => void) => {
				log.safeErrorWithoutCustomerData(
					'getUserBranchConfigTemplates',
					`Failed to get getUserBranchConfigTemplates with property key ${propertyKey} - no accountId`,
				);
				return resolve(null);
			});

export const setUserBranchConfigTemplates = (
	accountId: AccountId | null,
	propertyKey: string,
	userBranchSettings: UserBranchSettings,
): Promise<UserBranchSettings | undefined> =>
	accountId
		? setUserProperties(accountId, propertyKey, JSON.stringify(userBranchSettings))
				.then((res) => {
					if (res.ok) {
						return res;
					}

					return res.text().then((errResp: string) => {
						throw new FetchError(res.status, errResp);
					});
				})
				.catch((error) => {
					log.safeErrorWithoutCustomerData(
						'setUserBranchConfigTemplates',
						`Failed to update template value in setUserBranchConfigTemplates with property key ${propertyKey}`,
						error,
					);
					throw new Error('Failed to save');
				})
		: new Promise(() => {
				log.safeErrorWithoutCustomerData(
					'setUserBranchConfigTemplates',
					`Failed to update template value in setUserBranchConfigTemplates with property key ${propertyKey} - no accountId`,
				);
				throw new Error('No AccountId');
			});
