import { CONTENT_TOO_LARGE } from '@atlassian/jira-common-constants/src/http-status-codes.tsx';
import { ATTACHMENTS_PER_ISSUE_LIMIT_EXCEEDED_ERROR_MESSAGE } from '@atlassian/jira-issue-view-common-constants/src/errors.tsx';
import {
	ADD_WATCHER_ERROR,
	FETCH_WATCHERS_ERROR,
	REMOVE_WATCHER_ERROR,
	START_WATCHING_SUCCESS,
	STOP_WATCHING_SUCCESS,
	TOGGLE_WATCHING_ERROR,
	ADD_WATCHER_ERROR as ADD_WATCHER_ERROR_TYPE,
	ADD_WORKLOG_FAILURE as ADD_WORKLOG_FAILURE_TYPE,
	ADD_WORKLOG_SUCCESS as ADD_WORKLOG_SUCCESS_TYPE,
	ATTACHMENT_ADD_ERROR,
	CHANGE_ISSUE_TYPE_ERROR,
	CHANGE_ISSUE_TYPE_SUCCESS,
	COMMENT_ADD_ERROR_GENERIC,
	COMMENT_ADD_ERROR_INVALID,
	COMMENT_CONTENT_EXCEEDED_ERROR,
	COMMENT_FUTURE_TIME_ERROR,
	SAVE_COMMENT_SUCCESS_FLAG_MESSAGE,
	COMMENT_DELETE_ERROR,
	DELETE_CONFLUENCE_PAGE_LINK_FAILED as DELETE_CONFLUENCE_PAGE_LINK_FAILED_TYPE,
	DELETE_ISSUE_ERROR,
	DELETE_LINKED_ISSUE_ERROR,
	DELETE_WORKLOG_FAILURE as DELETE_WORKLOG_FAILURE_TYPE,
	DELETE_WORKLOG_SUCCESS as DELETE_WORKLOG_SUCCESS_TYPE,
	EDIT_WORKLOG_FAILURE as EDIT_WORKLOG_FAILURE_TYPE,
	EDIT_WORKLOG_SUCCESS as EDIT_WORKLOG_SUCCESS_TYPE,
	EDITOR_ERROR,
	FETCH_JIRA_APP_LINKS_ERROR,
	FETCH_WATCHERS_ERROR as FETCH_WATCHERS_ERROR_TYPE,
	REMOVE_WATCHER_ERROR as REMOVE_WATCHER_ERROR_TYPE,
	RICH_CONTENT_FIELD_CONFIRM_ERROR,
	RICH_CONTENT_FIELD_MAXIMUM_LENGTH_EXCEEDED_ERROR,
	RICH_CONTENT_FIELD_REQUIRED_ERROR,
	SAVE_APPROVAL_ANSWER_ERROR,
	SAVE_APPROVAL_ANSWER_SUCCESS,
	START_WATCHING_SUCCESS as START_WATCHING_SUCCESS_TYPE,
	STOP_WATCHING_SUCCESS as STOP_WATCHING_SUCCESS_TYPE,
	UPDATE_TIME_REMAINING_FAILURE as UPDATE_TIME_REMAINING_FAILURE_TYPE,
	WATCH_TOGGLE_UPDATE_ERROR,
	REMOTE_LINK_VALIDATION_ERROR,
	ATTACHMENT_ADD_SUCCESS,
	ATTACHMENT_ADD_EXCEED_SIZE_LIMIT_ERROR,
	CONFLUENCE_LINKS_PER_ISSUE_LIMIT_EXCEEDED,
	ISSUE_LINKS_PER_ISSUE_LIMIT_EXCEEDED,
	ISSUE_REMOTE_LINKS_PER_ISSUE_LIMIT_EXCEEDED,
	ISSUE_ATTACHMENTS_PER_ISSUE_LIMIT_EXCEEDED,
	COMMENTS_LIMIT_EXCEEDED_FIELDS_LIST,
} from '@atlassian/jira-issue-view-common-constants/src/flags.tsx';
import type { FlagDetails } from '@atlassian/jira-issue-view-common-types/src/flag-type.tsx';
import { extractErrorCollectionMessages } from '@atlassian/jira-issue-view-common-utils/src/utils/fetch-error-extraction.tsx';
import type {
	SaveApproverAnswerErrorAction,
	SaveApproverAnswerSuccessAction,
} from '../actions/approval-action/index.tsx';
import {
	ATTACHMENT_JIRA_CREATE_FAILURE,
	ATTACHMENT_MEDIA_SERVICES_UPLOAD_ERROR,
	ATTACHMENT_JIRA_CREATE_END,
	type AttachmentJiraCreateFailureAction,
	type AttachmentUploadErrorAction,
	type AttachmentJiraCreateEndAction,
} from '../actions/attachment-picker-actions.tsx';
import {
	SIMPLE_CHANGE_ISSUE_TYPE_REQUEST_FAILURE,
	SIMPLE_CHANGE_ISSUE_TYPE_REQUEST_SUCCESS,
	type SimpleChangeIssueTypeFailure,
	type SimpleChangeIssueTypeSuccess,
} from '../actions/change-issue-type-actions.tsx';
import {
	DELETE_COMMENT_FAILURE,
	type DeleteCommentFailureAction,
	SAVE_COMMENT_FAILURE,
	type SaveCommentFailureAction,
	SAVE_COMMENT_SUCCESS,
	type SaveCommentSuccessAction,
} from '../actions/comment-actions.tsx';
import {
	DELETE_CONFLUENCE_PAGE_LINK_FAILED,
	type DeleteConfluencePageLinkFailed,
	CREATE_CONFLUENCE_PAGE_LINK_FAILED,
	type CreateConfluencePageLinkFailed,
} from '../actions/confluence-pages-actions.tsx';
import {
	EDITOR_CHANGE_FAILURE,
	EDITOR_EXPANDED_FAILURE,
	EDITOR_SAVE_FAILURE,
	type EditorAction,
} from '../actions/editor-actions.tsx';
import {
	type FetchJiraAppLinksFailed,
	FETCH_JIRA_APP_LINKS_FAILED,
} from '../actions/fetch-jira-app-links-actions.tsx';
import { DISMISS_FLAG, type FlagAction } from '../actions/flag-actions.tsx';
import { DELETE_ISSUE_FAILURE, type DeleteIssueFailure } from '../actions/issue-delete-actions.tsx';
import {
	type SaveLinkedIssueFailure,
	DELETE_LINKED_ISSUE_FAILURE,
	SAVE_LINKED_ISSUE_FAILURE,
	type DeleteLinkedIssueFailure,
} from '../actions/issue-links-actions.tsx';
import {
	DELETE_REMOTE_LINKED_ISSUE_FAILURE,
	SAVE_REMOTE_LINKED_ISSUE_FAILURE,
	type DeleteRemoteLinkedIssueFailure,
	type SaveRemoteLinkedIssueFailure,
} from '../actions/remote-issue-links-actions.tsx';
import type { ShowWatchersFlag } from '../actions/watchers-actions.tsx';
import { DELETE_WEB_LINK_FAILED, type DeleteWebLinkFailed } from '../actions/web-links-actions.tsx';
import {
	ADD_WORKLOG_FAILURE,
	ADD_WORKLOG_SUCCESS,
	type AddWorklogFailureAction,
	type AddWorklogSuccessAction,
	DELETE_WORKLOG_FAILURE,
	DELETE_WORKLOG_SUCCESS,
	type DeleteWorklogFailureAction,
	type DeleteWorklogSuccessAction,
	EDIT_WORKLOG_FAILURE,
	EDIT_WORKLOG_SUCCESS,
	type EditWorklogFailureAction,
	type EditWorklogSuccessAction,
	UPDATE_TIME_REMAINING_FAILURE,
	type UpdateTimeRemainingFailureAction,
} from '../common/actions/worklog-actions.tsx';
import {
	FIELD_SAVE_FAILURE,
	FIELD_SAVE_SUCCESS,
	SWEET_STATE_FIELD_SAVE_FAILURE,
	type FieldSaveFailureAction,
	type FieldSaveSuccessAction,
	type SweetStateFieldSaveFailureAction,
} from '../issue-field/state/actions/field-save-actions.tsx';

const getNextFlagId = (flags: State) => (flags.length > 0 ? flags[0].id + 1 : 1);

const CONTENT_LIMIT_EXCEEDED = 'CONTENT_LIMIT_EXCEEDED';

export type State = FlagDetails[];

export const initialState: State = [];

type FlagReducerAction =
	| SaveCommentFailureAction
	| SaveCommentSuccessAction
	| DeleteCommentFailureAction
	| AttachmentUploadErrorAction
	| AttachmentJiraCreateFailureAction
	| EditorAction
	| DeleteLinkedIssueFailure
	| FieldSaveFailureAction
	| SweetStateFieldSaveFailureAction
	| FieldSaveSuccessAction
	| FlagAction
	| DeleteIssueFailure
	| SimpleChangeIssueTypeSuccess
	| SimpleChangeIssueTypeFailure
	| ShowWatchersFlag
	| AddWorklogFailureAction
	| AddWorklogSuccessAction
	| UpdateTimeRemainingFailureAction
	| EditWorklogSuccessAction
	| EditWorklogFailureAction
	| DeleteWorklogSuccessAction
	| DeleteWorklogFailureAction
	| DeleteConfluencePageLinkFailed
	| CreateConfluencePageLinkFailed
	| DeleteWebLinkFailed
	| SaveApproverAnswerSuccessAction
	| SaveApproverAnswerErrorAction
	| FetchJiraAppLinksFailed
	| SaveRemoteLinkedIssueFailure
	| DeleteRemoteLinkedIssueFailure
	| AttachmentJiraCreateEndAction
	| SaveLinkedIssueFailure;

/**
 * NOTE: Prefer to use <FlagServiceSubscriber> for new code
 */
const flagsReducer = (state: State = initialState, action: FlagReducerAction) => {
	// When there are multiple flags in the state, the first item
	// in the array will be the most recent flag, so as we get more
	// flags appearing, we prepend them to the state array.
	switch (action.type) {
		case SAVE_COMMENT_FAILURE: {
			if (
				action.payload.invalidMessage &&
				action.payload.invalidMessage.includes(
					"It looks like you've selected a future time for this activity. Send this activity to the current time or a past time instead.",
				)
			) {
				return [
					{
						type: COMMENT_FUTURE_TIME_ERROR,
						id: getNextFlagId(state),
					},
				];
			}
			if (
				action.payload.invalidMessage &&
				action.payload.invalidMessage.includes(CONTENT_LIMIT_EXCEEDED)
			) {
				return [
					{
						type: COMMENT_CONTENT_EXCEEDED_ERROR,
						id: getNextFlagId(state),
					},
					...state,
				];
			}
			if (
				action.payload.invalidMessage &&
				COMMENTS_LIMIT_EXCEEDED_FIELDS_LIST.some(
					(limitExceededMessage) => limitExceededMessage === action.payload.invalidMessage,
				)
			) {
				const limitFlagType = COMMENTS_LIMIT_EXCEEDED_FIELDS_LIST.find(
					(limitExceededMesssage) => limitExceededMesssage === action.payload.invalidMessage,
				);
				return [
					{
						type: limitFlagType,
						id: getNextFlagId(state),
					},
					...state,
				];
			}
			return [
				{
					type: action.payload.isServerValidationError
						? COMMENT_ADD_ERROR_INVALID
						: COMMENT_ADD_ERROR_GENERIC,
					id: getNextFlagId(state),
				},
				...state,
			];
		}

		case SAVE_COMMENT_SUCCESS: {
			return action.payload && action.payload.triggerIncidentSaveCommentFlag
				? [
						{
							type: SAVE_COMMENT_SUCCESS_FLAG_MESSAGE,
							id: getNextFlagId(state),
							fullIssueUrl: action.payload.fullIssueUrl,
							commentId: action.payload.comment.id,
						},
					]
				: state;
		}

		case DELETE_COMMENT_FAILURE:
			return [{ type: COMMENT_DELETE_ERROR, id: getNextFlagId(state) }, ...state];

		case ATTACHMENT_MEDIA_SERVICES_UPLOAD_ERROR:
			return [{ type: ATTACHMENT_ADD_ERROR, id: getNextFlagId(state) }, ...state];

		case ATTACHMENT_JIRA_CREATE_FAILURE:
			if (action.payload.exceedFileSizeLimit) {
				return [
					{ type: ATTACHMENT_ADD_EXCEED_SIZE_LIMIT_ERROR, id: getNextFlagId(state) },
					...state,
				];
			}

			if (action.payload.statusCode === CONTENT_TOO_LARGE) {
				return [
					{ type: ISSUE_ATTACHMENTS_PER_ISSUE_LIMIT_EXCEEDED, id: getNextFlagId(state) },
					...state,
				];
			}
			return [{ type: ATTACHMENT_ADD_ERROR, id: getNextFlagId(state) }, ...state];

		case ATTACHMENT_JIRA_CREATE_END: {
			const { issueKey, numFailed, numCreated } = action.payload;

			if (numFailed > 0 || numCreated <= 0) {
				return state;
			}

			return [
				{
					type: ATTACHMENT_ADD_SUCCESS,
					id: getNextFlagId(state),
					descriptionI18nValues: {
						issueKey,
						numCreated,
					},
				},
				...state,
			];
		}

		case EDITOR_CHANGE_FAILURE:
		case EDITOR_EXPANDED_FAILURE:
		case EDITOR_SAVE_FAILURE:
			return [{ type: EDITOR_ERROR, id: getNextFlagId(state) }, ...state];

		// @ts-expect-error - TS2678 - Type '"RICH_CONTENT_FIELD_CONFIRM_ERROR"' is not comparable to type '"ATTACHMENT_MEDIA_SERVICES_UPLOAD_ERROR" | "ATTACHMENT_JIRA_CREATE_FAILURE" | "EDITOR_CHANGE_FAILURE" | "EDITOR_EXPANDED_FAILURE" | "EDITOR_SAVE_FAILURE" | "DELETE_LINKED_ISSUE_FAILURE" | ... 21 more ... | "DELETE_COMMENT_FAILURE"'.
		case RICH_CONTENT_FIELD_CONFIRM_ERROR:
			return [
				{
					type: RICH_CONTENT_FIELD_CONFIRM_ERROR,
					id: getNextFlagId(state),
				},
				...state,
			];

		case DELETE_LINKED_ISSUE_FAILURE: {
			return [
				{
					type: DELETE_LINKED_ISSUE_ERROR,
					id: getNextFlagId(state),
				},
				...state,
			];
		}
		case DELETE_REMOTE_LINKED_ISSUE_FAILURE: {
			return [
				{
					type: DELETE_LINKED_ISSUE_ERROR,
					id: getNextFlagId(state),
				},
				...state,
			];
		}
		case SAVE_REMOTE_LINKED_ISSUE_FAILURE: {
			const { error, remoteIssueKey } = action.payload;

			if (error.statusCode === CONTENT_TOO_LARGE) {
				return [
					{
						type: ISSUE_REMOTE_LINKS_PER_ISSUE_LIMIT_EXCEEDED,
						id: getNextFlagId(state),
					},
					...state,
				];
			}

			if (error.statusCode === 400) {
				const i18nServerErrorMessage = extractErrorCollectionMessages(error.message);
				return [
					{
						type: REMOTE_LINK_VALIDATION_ERROR,
						id: getNextFlagId(state),
						titleI18nValues: {
							remoteIssueKey,
						},
						descriptionI18nValues: {
							i18nServerErrorMessage,
						},
					},
					...state,
				];
			}
			return state;
		}
		case SWEET_STATE_FIELD_SAVE_FAILURE:
		case FIELD_SAVE_FAILURE: {
			const { fieldOptions } = action.payload;
			const { onSaveFailureFlagType } = fieldOptions;
			if (fieldOptions.isRichTextField && action.payload.invalidMessage) {
				if (action.payload.invalidMessage.includes(CONTENT_LIMIT_EXCEEDED)) {
					return [
						{
							type: RICH_CONTENT_FIELD_MAXIMUM_LENGTH_EXCEEDED_ERROR,
							id: getNextFlagId(state),
						},
						...state,
					];
				}
				if (
					action.payload.invalidMessage &&
					action.payload.invalidMessage.includes(ATTACHMENTS_PER_ISSUE_LIMIT_EXCEEDED_ERROR_MESSAGE)
				) {
					return [
						{
							type: ISSUE_ATTACHMENTS_PER_ISSUE_LIMIT_EXCEEDED,
							id: getNextFlagId(state),
						},
						...state,
					];
				}
				if (
					action.payload.invalidMessage &&
					action.payload.invalidMessage.includes('is required')
				) {
					return [
						{
							type: RICH_CONTENT_FIELD_REQUIRED_ERROR,
							id: getNextFlagId(state),
						},
						...state,
					];
				}
			}
			if (onSaveFailureFlagType) {
				return [
					{
						type: onSaveFailureFlagType,
						id: getNextFlagId(state),
					},
					...state,
				];
			}
			return state;
		}

		case FIELD_SAVE_SUCCESS: {
			const { onSaveSuccessFlagType } = action.payload.fieldOptions;
			if (onSaveSuccessFlagType) {
				return [
					{
						type: onSaveSuccessFlagType,
						id: getNextFlagId(state),
						descriptionI18nValues: {
							issueKey: action.meta && action.meta.issueKey,
						},
					},
					...state,
				];
			}
			return state;
		}

		case DISMISS_FLAG:
			return state.filter((flag) => flag.id !== action.payload);

		case DELETE_ISSUE_FAILURE:
			return [
				{
					type: DELETE_ISSUE_ERROR,
					id: getNextFlagId(state),
					titleI18nValues: {
						issueKey: action.payload.issueKey,
					},
				},
				...state,
			];

		case SIMPLE_CHANGE_ISSUE_TYPE_REQUEST_SUCCESS:
			return [
				{
					type: CHANGE_ISSUE_TYPE_SUCCESS,
					id: getNextFlagId(state),
					titleI18nValues: {
						destType: action.payload.targetTypeName,
					},
				},
				...state,
			];
		case SIMPLE_CHANGE_ISSUE_TYPE_REQUEST_FAILURE:
			return [
				{
					type: CHANGE_ISSUE_TYPE_ERROR,
					id: getNextFlagId(state),
				},
				...state,
			];

		case TOGGLE_WATCHING_ERROR: {
			return [
				{
					type: WATCH_TOGGLE_UPDATE_ERROR,
					id: getNextFlagId(state),
				},
				...state,
			];
		}

		case FETCH_WATCHERS_ERROR: {
			return [
				{
					type: FETCH_WATCHERS_ERROR_TYPE,
					id: getNextFlagId(state),
				},
				...state,
			];
		}

		case START_WATCHING_SUCCESS: {
			return [
				{
					type: START_WATCHING_SUCCESS_TYPE,
					id: getNextFlagId(state),
				},
				...state,
			];
		}

		case STOP_WATCHING_SUCCESS: {
			return [
				{
					type: STOP_WATCHING_SUCCESS_TYPE,
					id: getNextFlagId(state),
				},
				...state,
			];
		}

		case REMOVE_WATCHER_ERROR: {
			return [
				{
					type: REMOVE_WATCHER_ERROR_TYPE,
					id: getNextFlagId(state),
				},
				...state,
			];
		}

		case ADD_WATCHER_ERROR: {
			return [
				{
					type: ADD_WATCHER_ERROR_TYPE,
					id: getNextFlagId(state),
				},
				...state,
			];
		}

		case ADD_WORKLOG_FAILURE: {
			if (action.payload && action.payload.invalidMessage) {
				return [
					{
						type: action.payload.invalidMessage,
						id: getNextFlagId(state),
					},
					...state,
				];
			}
			return [
				{
					type: ADD_WORKLOG_FAILURE_TYPE,
					id: getNextFlagId(state),
				},
				...state,
			];
		}

		case ADD_WORKLOG_SUCCESS: {
			return [
				{
					type: ADD_WORKLOG_SUCCESS_TYPE,
					id: getNextFlagId(state),
					descriptionI18nValues: {
						issueKey: action.payload.issueKey,
					},
				},
				...state,
			];
		}

		case UPDATE_TIME_REMAINING_FAILURE: {
			return [
				{
					type: UPDATE_TIME_REMAINING_FAILURE_TYPE,
					id: getNextFlagId(state),
				},
				...state,
			];
		}

		case EDIT_WORKLOG_SUCCESS: {
			return [
				{
					type: EDIT_WORKLOG_SUCCESS_TYPE,
					id: getNextFlagId(state),
				},
				...state,
			];
		}

		case EDIT_WORKLOG_FAILURE: {
			return [
				{
					type: EDIT_WORKLOG_FAILURE_TYPE,
					id: getNextFlagId(state),
				},
				...state,
			];
		}

		case DELETE_WORKLOG_SUCCESS: {
			return [
				{
					type: DELETE_WORKLOG_SUCCESS_TYPE,
					id: getNextFlagId(state),
				},
				...state,
			];
		}

		case DELETE_WORKLOG_FAILURE: {
			return [
				{
					type: DELETE_WORKLOG_FAILURE_TYPE,
					id: getNextFlagId(state),
				},
				...state,
			];
		}

		case DELETE_CONFLUENCE_PAGE_LINK_FAILED: {
			return [
				{
					type: DELETE_CONFLUENCE_PAGE_LINK_FAILED_TYPE,
					id: getNextFlagId(state),
				},
				...state,
			];
		}

		case CREATE_CONFLUENCE_PAGE_LINK_FAILED: {
			if (action?.payload?.errorMessage === ISSUE_REMOTE_LINKS_PER_ISSUE_LIMIT_EXCEEDED) {
				return [
					{
						type: CONFLUENCE_LINKS_PER_ISSUE_LIMIT_EXCEEDED,
						id: getNextFlagId(state),
					},
					...state,
				];
			}
			return state;
		}

		case DELETE_WEB_LINK_FAILED: {
			return state;
		}

		case SAVE_APPROVAL_ANSWER_ERROR: {
			return [
				{
					type: SAVE_APPROVAL_ANSWER_ERROR,
					id: getNextFlagId(state),
				},
				...state,
			];
		}

		case SAVE_APPROVAL_ANSWER_SUCCESS: {
			return [
				{
					type: SAVE_APPROVAL_ANSWER_SUCCESS,
					id: getNextFlagId(state),
				},
				...state,
			];
		}
		case FETCH_JIRA_APP_LINKS_FAILED: {
			return [
				{
					type: FETCH_JIRA_APP_LINKS_ERROR,
					id: getNextFlagId(state),
				},
				...state,
			];
		}
		case SAVE_LINKED_ISSUE_FAILURE: {
			if (
				action.payload.error &&
				action.payload.error.includes(ISSUE_LINKS_PER_ISSUE_LIMIT_EXCEEDED)
			) {
				return [
					{
						type: ISSUE_LINKS_PER_ISSUE_LIMIT_EXCEEDED,
						id: getNextFlagId(state),
					},
					...state,
				];
			}
			return state;
		}
		default:
			return state;
	}
};

export default flagsReducer;
