import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';

type ResolveResponse = {
	data?: {
		name?: string;
	};
};

const resolverUrl = '/gateway/api/object-resolver/resolve';

export const getPageTitle = async (resourceUrl: string): Promise<string | undefined> => {
	try {
		const response = await fetchJson<ResolveResponse>(resolverUrl, {
			method: 'POST',
			body: JSON.stringify({ resourceUrl }),
		});

		return response.data?.name;
	} catch {
		return undefined;
	}
};
