import { useMemo } from 'react';
import { isFedRamp } from '@atlaskit/atlassian-context';
import { LICENSED_PRODUCTS } from '@atlassian/jira-common-util-get-tenant-context/src/index.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useDateTimeField } from '@atlassian/jira-issue-field-date-time/src/services/use-date-time-field/index.tsx';
import { useResolutionField } from '@atlassian/jira-issue-field-resolution/src/services/index.tsx';
import { CREATED } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { useLicensedProducts } from '@atlassian/jira-tenant-context-controller/src/components/licensed-products/index.tsx';
import type { UseLoomJSMAgentBannerProps } from './types.tsx';

/**
 * Eligibility criteria for showing the Loom JSM Agent Banner:
 * - Tenant is not fedramp
 * - Tenant does not have loom
 * - Issue is a JSM issue
 * - Issue does not have resolution (e.g. Done, Won't Do)
 * - Issue has at least one public comment or was created more than 24 hours ago
 * - Issue is customer facing (isEditingInternal is false)
 */
export const useLoomJSMAgentBanner = ({
	hasPublicComments,
	isEditingInternal,
	isServiceDesk,
}: UseLoomJSMAgentBannerProps): boolean => {
	const isFedRampInstance = isFedRamp();
	const licensedProducts = useLicensedProducts();
	const tenantHasLoomTCS = Boolean(licensedProducts[LICENSED_PRODUCTS.LOOM]);
	const issueKey = useIssueKey();
	const [{ value: createdDate }] = useDateTimeField({ issueKey, fieldKey: CREATED });
	const [{ value: resolution }] = useResolutionField({ issueKey });
	const isSupportedJSMIssue = useMemo(() => {
		return (
			isServiceDesk &&
			!resolution &&
			!!createdDate &&
			(hasPublicComments ||
				new Date().getTime() - new Date(createdDate).getTime() >= 1000 * 60 * 60 * 24)
		);
	}, [createdDate, isServiceDesk, resolution, hasPublicComments]);

	return !isEditingInternal && !tenantHasLoomTCS && !isFedRampInstance && isSupportedJSMIssue;
};
