import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';

const localStorage = createLocalStorageProvider('issue-changeboarding');

const BANNER_SEEN_TIMES_KEY = 'banner-seen-times' as const;
const BANNER_FIRST_SEEN_TIMESTAMP_KEY = 'banner-first-seen-timestamp' as const;

// JIV-15410 this doesn't really belong here but has no other home. move it somewhere it makes more sense
export const getChangeboardingBannerSeenTimes = () => localStorage.get(BANNER_SEEN_TIMES_KEY) || 0;

export const setChangeboardingBannerSeenTimes = (seenTimes: number) =>
	localStorage.set(BANNER_SEEN_TIMES_KEY, seenTimes);

export const getChangeboardingBannerFirstSeenTimestamp = () =>
	localStorage.get(BANNER_FIRST_SEEN_TIMESTAMP_KEY);

export const setChangeboardingBannerFirstSeenTimestamp = (firstSeenTimestamp: number) =>
	localStorage.set(BANNER_FIRST_SEEN_TIMESTAMP_KEY, firstSeenTimestamp);
