/**
 * @generated SignedSource<<5603fda12586c734accd519f63ebd761>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type singleLineText_issueFieldSingleLineTextInlineEditFull_SingleLineTextInlineEditView_fragmentRef$data = {
  readonly fieldConfig: {
    readonly isEditable: boolean | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"singleLineText_issueFieldSingleLineTextInlineEditFull_SingleLineTextInlineEditViewWithIsEditable_fragmentRef">;
  readonly " $fragmentType": "singleLineText_issueFieldSingleLineTextInlineEditFull_SingleLineTextInlineEditView_fragmentRef";
};
export type singleLineText_issueFieldSingleLineTextInlineEditFull_SingleLineTextInlineEditView_fragmentRef$key = {
  readonly " $data"?: singleLineText_issueFieldSingleLineTextInlineEditFull_SingleLineTextInlineEditView_fragmentRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"singleLineText_issueFieldSingleLineTextInlineEditFull_SingleLineTextInlineEditView_fragmentRef">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "singleLineText_issueFieldSingleLineTextInlineEditFull_SingleLineTextInlineEditView_fragmentRef",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "singleLineText_issueFieldSingleLineTextInlineEditFull_SingleLineTextInlineEditViewWithIsEditable_fragmentRef"
    },
    {
      "concreteType": "JiraFieldConfig",
      "kind": "LinkedField",
      "name": "fieldConfig",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isEditable"
        }
      ]
    }
  ],
  "type": "JiraSingleLineTextField"
};

(node as any).hash = "834bf98c01966c210bc0a4f6e9f0f4a7";

export default node;
