import { useCallback, useState } from 'react';
import { graphql, fetchQuery } from 'react-relay';
import { gqlTagRest } from '@atlassian/jira-common-graphql-tags/src/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import getRelayEnvironment from '@atlassian/jira-relay-environment/src/index.tsx';
import type { servicesReadingAids_acronymsQuery } from '@atlassian/jira-relay/src/__generated__/servicesReadingAids_acronymsQuery.graphql';

export type AcronymType = readonly string[] | null;
export type AcronymsQueryProps = { acronyms: AcronymType };
type AcronymsState = {
	data: AcronymsQueryProps | undefined;
	loading: Boolean;
	error: Error | undefined;
};

export const getNlpQuery = (
	query: string,
	locale: string,
	cloudIdARI: string,
	additionalContext?: string,
) => gqlTagRest`query NlpSearchQuery {
    nlpSearch(
      query: "${query}"
      locale:  "${locale}"
      followups_enabled: false
      locations: ["${cloudIdARI}"]
      additionalContext: "${additionalContext || ''}",
      experience: "readingAids"
    ) @optIn(to: "Nlp Search") {
      nlpResults {
        nlpResult
        sources {
          id
          title
          url
          iconUrl
          type
          lastModified
          spaceName
          spaceUrl
        }
      }
      format
      nlpFollowUpResults {
        followUps
      }
      uniqueSources {
        id
        title
        url
        iconUrl
        type
        lastModified
        spaceName
        spaceUrl
      }
      errorState
      disclaimer
    }
  }`;

export const fetchAcronymsData = async (content: string): Promise<AcronymsQueryProps> => {
	try {
		const data = await fetchQuery<servicesReadingAids_acronymsQuery>(
			getRelayEnvironment(),
			graphql`
				query servicesReadingAids_acronymsQuery($content: String!) {
					getKeywords(textInput: { format: PLAIN_TEXT, text: $content })
				}
			`,
			{
				content,
			},
		).toPromise();

		const acronyms = data?.getKeywords ?? null;

		return {
			acronyms,
		};
	} catch (error) {
		fireErrorAnalytics({
			meta: {
				id: 'fetchAcronymsDataService',
				packageName: 'jiraHighlightActions',
				teamName: 'crocs',
			},
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			error: error as Error,
		});
		throw error;
	}
};

const initialState = {
	data: undefined,
	loading: false,
	error: undefined,
};

export const useAcronymsQuery = (): AcronymsState & {
	loadAcronyms: (content: string) => Promise<AcronymsQueryProps | undefined>;
	resetData: () => void;
} => {
	const [state, setState] = useState<AcronymsState>(initialState);

	const loadAcronyms = useCallback(async (contentData: string) => {
		try {
			setState({
				...initialState,
				loading: true,
			});
			const data = await fetchAcronymsData(contentData);
			setState({
				...initialState,
				data,
			});
			return data;
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			setState({
				...initialState,
				error,
			});
			return undefined;
		}
	}, []);

	const resetData = useCallback(() => {
		setState(initialState);
	}, []);

	return { ...state, loadAcronyms, resetData };
};
