/**
 * @generated SignedSource<<9e8462ff62978ed4316847e9c28e5565>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutMetaCreatedDateField_IssueViewCreatedDateField$data = {
  readonly dateTime: AGG$DateTime | null | undefined;
  readonly " $fragmentType": "ui_issueViewLayoutMetaCreatedDateField_IssueViewCreatedDateField";
};
export type ui_issueViewLayoutMetaCreatedDateField_IssueViewCreatedDateField$key = {
  readonly " $data"?: ui_issueViewLayoutMetaCreatedDateField_IssueViewCreatedDateField$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutMetaCreatedDateField_IssueViewCreatedDateField">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutMetaCreatedDateField_IssueViewCreatedDateField",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "dateTime"
    }
  ],
  "type": "JiraDateTimePickerField"
};

(node as any).hash = "7a873820e7f681cece55adec6c8641e3";

export default node;
