import type { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import {
	getMediaReadPermissionUrl,
	type MediaReadPermissionResponse,
} from '@atlassian/jira-issue-fetch-services-common/src/services/issue-media-read-permissions/index.tsx';
import { fetchViewContextPerf } from '@atlassian/jira-issue-view-common-constants/src/browser-metrics-constants.tsx';
import { IssueViewFetchError } from '@atlassian/jira-issue-view-errors/src/common/utils/issue-view-fetch-error/index.tsx';

type HeadersProcessor = (key: string) => (headers: Headers) => void;

export const fetchIssueMediaReadPermission$ = (
	baseUrl: string,
	issueKey: string,
	maxTokenLength: number,
	headersProcessor?: HeadersProcessor,
): Observable<MediaReadPermissionResponse> =>
	fetchJson$<MediaReadPermissionResponse>(getMediaReadPermissionUrl(issueKey, maxTokenLength), {
		perf: fetchViewContextPerf,
		headersProcessor: headersProcessor && headersProcessor(fetchViewContextPerf.key),
	}).catch((error) => {
		throw new IssueViewFetchError(
			error,
			getMediaReadPermissionUrl('{issueKey}', '{maxTokenLength}'),
		);
	});

export const fetchIssueMediaReadPermission = (
	baseUrl: string,
	issueKey: string,
	maxTokenLength: number,
	headersProcessor?: HeadersProcessor,
): Promise<MediaReadPermissionResponse> =>
	fetchJson(getMediaReadPermissionUrl(issueKey, maxTokenLength), {
		// @ts-expect-error - TS2345 - Argument of type '{ perf: { readonly key: "FETCH_VIEW_CONTEXT"; readonly prefix: "ISSUE_DETAILS"; }; headersProcessor: ((headers: Headers) => void) | undefined; }' is not assignable to parameter of type 'RequestInit'.
		perf: fetchViewContextPerf,
		headersProcessor: headersProcessor && headersProcessor(fetchViewContextPerf.key),
	});
