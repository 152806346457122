import React from 'react';
import { Box, Inline, xcss } from '@atlaskit/primitives';
import {
	COMMAND_PALETTE_SECTIONS_PRIORITY,
	COMMAND_PALETTE_ISSUE_ACTIONS,
} from '@atlassian/jira-command-palette-common/src/common/constants.tsx';
import { getCommandPaletteIssueActionsHeader } from '@atlassian/jira-command-palette-common/src/common/utils/index.tsx';
import { IssueTag } from '@atlassian/jira-command-palette-issue-actions-common/src/common/ui/issue-tag/index.tsx';
import { useRegisterCommandSections } from '@atlassian/jira-command-palette/src/controllers/use-register-sections/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueId } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useSummaryField } from '@atlassian/jira-issue-field-summary-common/src/services/index.tsx';
import { messages } from './messages.tsx';
import type { RenderCommandPaletteHeaderProps } from './types.tsx';

export const RenderCommandPaletteHeader = ({ issueKey }: RenderCommandPaletteHeaderProps) => {
	const { formatMessage } = useIntl();
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const issueId = ff('relay-migration-issue-fields-summary_eog1v') ? useIssueId() : undefined;

	const [{ value: summary }] = useSummaryField({ issueKey, issueId });

	// Since IssueTag is another component and not part of renderName, it cannot be traversed by command palette
	// to detect the header text and make it searchable. We duplicate the issue key and summary and visually hide it
	const renderName = () => (
		<Inline alignBlock="center" space="space.050">
			<Box xcss={issueActionsLabelStyles}>
				{formatMessage(
					expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
						? messages.issueActionsIssueTermRefresh
						: messages.issueActions,
				)}
			</Box>
			<div
				hidden
				data-testid="issue.views.issue-details.register-command-palette-header.visually-hidden"
			>
				{issueKey} {summary}
			</div>
			<IssueTag issueKey={issueKey} />
		</Inline>
	);

	const sectionId = getCommandPaletteIssueActionsHeader(issueKey);
	useRegisterCommandSections(
		{
			[sectionId]: {
				renderName,
				priority: COMMAND_PALETTE_SECTIONS_PRIORITY.issueActions,
				actionCategory: COMMAND_PALETTE_ISSUE_ACTIONS,
			},
		},
		[issueKey],
	);

	return null;
};

const issueActionsLabelStyles = xcss({
	fontSize: 'font.body',
	color: 'color.text.subtlest',
	// @ts-expect-error - TS2353 - Object literal may only specify known properties, and 'textWrap' does not exist in type 'SafeCSSObject'.
	textWrap: 'nowrap',
});
