import React, { useCallback } from 'react';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import { useIntl } from '@atlassian/jira-intl';
import {
	useIssueKey,
	useProjectKey,
	useViewScreenId,
} from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import { REQUEST_TYPE_CF_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import {
	useIsSimplifiedProject,
	useProjectType,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import { useCanConfigureIssue } from '@atlassian/jira-project-permissions-service/src/main.tsx';
import { useRouterActions } from '@atlassian/react-resource-router';
import getConfigurationHref from '../../../utils/get-configuration-href.tsx';
import messages from './messages.tsx';

const ConfigureIssueFF = () => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { push } = useRouterActions();
	const href = getConfigurationHref(formatMessage);

	const onClick = useCallback(() => {
		const analyticsEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'dropdownItem',
		});
		fireUIAnalytics(analyticsEvent, 'configureIssue');
		push(href);
	}, [createAnalyticsEvent, push, href]);

	const issueKey = useIssueKey();
	const requestTypeIdFieldKey = REQUEST_TYPE_CF_TYPE;
	const [requestTypeId] = useFieldValue({ issueKey, fieldKey: requestTypeIdFieldKey });
	const viewScreenId = useViewScreenId();

	const isDisabled = (requestTypeId != null || requestTypeId === '') && viewScreenId === 0;

	const projectKey = useProjectKey();
	const projectType = useProjectType(projectKey);

	const isSimplifiedProject = useIsSimplifiedProject(projectKey);
	const canConfigureIssue = useCanConfigureIssue(projectKey);
	const shouldShowSwiftLink = isSimplifiedProject && canConfigureIssue;

	if (shouldShowSwiftLink && projectType) {
		return (
			<DropdownItem href={href} key="configure" onClick={onClick} UNSAFE_shouldDisableRouterLink>
				{formatMessage(messages.configure)}
			</DropdownItem>
		);
	}

	return (
		<DropdownItem
			href={href}
			isDisabled={isDisabled}
			key="configure"
			onClick={onClick}
			UNSAFE_shouldDisableRouterLink
		>
			{formatMessage(messages.configure)}
		</DropdownItem>
	);
};

export default ConfigureIssueFF;
