import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/ignoreElements';
import 'rxjs/add/operator/scan';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/delay';
import 'rxjs/add/operator/switchMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { trackOrLogClientError } from '@atlassian/jira-issue-view-common-utils/src/errors/index.tsx';
import {
	type SetContext,
	SET_CONTEXT,
} from '@atlassian/jira-issue-view-store/src/actions/context-actions.tsx';
import { baseUrlSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';

type Action = SetContext;

const LOG_LOCATION = 'issue.recently-viewed-issue-epic';

/*
 * Checks for actual error message in response as well to guard against potential
 * (if unlikely) change in API that results in a different 404 with a different root cause
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isInaccessibleIssue = (error: any) => error.statusCode === 404;

// Whenever the issue key is changed (including when the issue app is initialized)
// then we add the issue to the user's history
// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	actions$: ActionsObservable<Action>,
	store: MiddlewareAPI<State>,
): Observable<Action> =>
	// @ts-expect-error - TS2322 - Type 'Observable<unknown>' is not assignable to type 'Observable<{ type: "SET_CONTEXT"; payload: Partial<ContextState>; }>'.
	actions$
		.ofType(SET_CONTEXT)
		.scan(
			// @ts-expect-error - TS2769 - No overload matches this call.
			({ issueKey }, action) => ({
				issueKey: action.payload.issueKey,
				loggedInAccountId: action.payload.atlassianAccountId,
				previousIssueKey: issueKey,
			}),
			{ issueKey: null, previousIssueKey: null, loggedInAccountId: null },
		)
		// @ts-expect-error - TS2339 - Property 'loggedInAccountId' does not exist on type '{ type: "SET_CONTEXT"; payload: Partial<ContextState>; }'.
		.filter(({ loggedInAccountId }) => loggedInAccountId !== null)
		// @ts-expect-error - TS2339 - Property 'issueKey' does not exist on type '{ type: "SET_CONTEXT"; payload: Partial<ContextState>; }'. | TS2339 - Property 'previousIssueKey' does not exist on type '{ type: "SET_CONTEXT"; payload: Partial<ContextState>; }'.
		.filter(({ issueKey, previousIssueKey }) => issueKey !== previousIssueKey)
		// @ts-expect-error - TS2339 - Property 'issueKey' does not exist on type '{ type: "SET_CONTEXT"; payload: Partial<ContextState>; }'.
		.map(({ issueKey }) => issueKey)
		.delay(3000)
		.switchMap((issueKey) => {
			const state = store.getState();
			const baseUrl = baseUrlSelector(state);
			const url = `${baseUrl}/rest/internal/2/issue/${issueKey}/history`;
			const options = { method: 'POST' };
			return fetchJson$(url, options)
				.catch((error) => {
					if (!isInaccessibleIssue(error)) {
						trackOrLogClientError(
							LOG_LOCATION,
							// TODO: "Failed to update user's issue history" for non-fetch
							'Client network error occurred when fetching user issue history',
							error,
						);
					}
					return Observable.empty<never>();
				})
				.ignoreElements();
		});
