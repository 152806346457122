import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	createConfluencePageItem: {
		defaultMessage: 'Page',
		id: 'issue-create-confluence-content.create-confluence-content-menu.create-page-menu-item.create-confluence-page-item',
		description: "The 'Page' option in the create confluence content menu",
	},
	createConfluencepageQuickActionsList: {
		defaultMessage: 'Create Confluence page',
		id: 'issue-create-confluence-content.create-confluence-content-menu.create-page-menu-item.create-confluencepage-quick-actions-list',
		description:
			"The 'Page' option in the create confluence content menu with quick action list view enabled",
	},
	newConfluencePageQuickActionsList: {
		defaultMessage: 'New Confluence page',
		id: 'issue-create-confluence-content.create-confluence-content-menu.create-page-menu-item.new-confluence-page-quick-actions-list',
		description:
			'Dropdown item to create a new confluence page that appears in the quick actions dropdown of issue view',
	},
});
