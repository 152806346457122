import React from 'react';
import {
	type Area,
	CONTEXT,
} from '@atlassian/jira-issue-view-common-types/src/connect-field-type.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { isRichTextFieldSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector.tsx';
import MultilineTextView from '../multiline-plaintext/view.tsx';
import MultilineField from './view.tsx';

const SwitchingMultilineField = (props: {
	fieldId: string;
	isRichTextField: boolean;
	area: Area;
}) => {
	const { isRichTextField, fieldId, area = CONTEXT } = props;

	return isRichTextField ? (
		<MultilineField fieldId={fieldId} area={area} />
	) : (
		<MultilineTextView fieldId={fieldId} area={area} />
	);
};

export default connect(
	(state, ownProps) => ({
		// @ts-expect-error - TS2339 - Property 'fieldId' does not exist on type '{}'. | TS2345 - Argument of type '{}' is not assignable to parameter of type 'Readonly<{ agile: Agile; context: ContextState; entities: Readonly<{ applicationRoles?: ApplicationRole[] | undefined; cardCover: CardCover; childrenIssues: ChildrenIssuesState; ... 29 more ...; myPreferences?: Partial<...> | undefined; }>; ... 5 more ...; validators: Validators; }>'.
		isRichTextField: isRichTextFieldSelector(ownProps.fieldId)(state),
	}),
	{},
)(SwitchingMultilineField);

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { TextareaWrapperWithoutMargin } from './view';
