import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	deploymentsTooltip: {
		id: 'software-releases-version-detail-issue-list.issues.issue-card.delivery.deployment-data.deployments-tooltip',
		defaultMessage:
			'{deploymentCount} {deploymentCount, plural, one {deployment} other {deployments}}',
		description:
			'A tooltip which displays the total number of deployments associated to the issue.',
	},
	production: {
		id: 'software-releases-version-detail-issue-list.issues.issue-card.delivery.deployment-data.production',
		defaultMessage: 'Production',
		description: 'The name of the deployment environment type',
	},
	staging: {
		id: 'software-releases-version-detail-issue-list.issues.issue-card.delivery.deployment-data.staging',
		defaultMessage: 'Staging',
		description: 'The name of the deployment environment type',
	},
	testing: {
		id: 'software-releases-version-detail-issue-list.issues.issue-card.delivery.deployment-data.testing',
		defaultMessage: 'Testing',
		description: 'The name of the deployment environment type',
	},
	development: {
		id: 'software-releases-version-detail-issue-list.issues.issue-card.delivery.deployment-data.development',
		defaultMessage: 'Development',
		description: 'The name of the deployment environment type',
	},
	unmapped: {
		id: 'software-releases-version-detail-issue-list.issues.issue-card.delivery.deployment-data.unmapped',
		defaultMessage: 'Undefined environment',
		description:
			'The name of the deployment environment type used when the environment is not production, staging, development or testing.',
	},
});
