import { useMemo } from 'react';
import { ProviderFactory } from '@atlaskit/editor-common/provider-factory';
import { getEmojiProviderWithCustomEmoji } from '@atlassian/jira-common-atlaskit-services/src/emoji.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';

export const useEmojiProvider = () => {
	const cloudId = useCloudId();
	const atlassianAccountId = useAccountId();

	const emojiProvider = useMemo(() => {
		const dataProviders = new ProviderFactory();
		dataProviders.setProvider(
			'emojiProvider',
			getEmojiProviderWithCustomEmoji(cloudId, atlassianAccountId) || undefined,
		);
		return dataProviders;
	}, [cloudId, atlassianAccountId]);

	return emojiProvider;
};
