import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/ignoreElements';
import 'rxjs/add/operator/retry';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/distinct';
import { type ActionsObservable, combineEpics } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useFlagsService } from '@atlassian/jira-flags';
import type { AttachmentServiceActions } from '@atlassian/jira-issue-attachments-base/src/services/attachments-service/types.tsx';
import {
	isAttachmentNumberApproachLimit,
	isAttachmentNumberOverLimit,
	ATTACHMENTS_LEARN_ABOUT_LINK,
	ATTACHMENT_NUMBER_EXCEEDED_LIMIT,
} from '@atlassian/jira-issue-attachments-limit/src/common/utils.tsx';
import attachmentLimitMessages from '@atlassian/jira-issue-attachments-limit/src/messages.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { trackOrLogClientError } from '@atlassian/jira-issue-view-common-utils/src/errors/index.tsx';
import {
	attachmentJiraCreateSuccess,
	attachmentJiraCreateFailure,
	ATTACHMENT_MEDIA_SERVICES_UPLOAD_END,
	ATTACHMENT_JIRA_CREATE_FAILURE,
	ATTACHMENT_MEDIA_SERVICES_UPLOAD_ERROR,
	ATTACHMENT_JIRA_CREATE_SUCCESS,
	MEDIA_PICKER_ERROR,
	attachmentJiraCreateEnd,
} from '@atlassian/jira-issue-view-store/src/actions/attachment-picker-actions.tsx';
import {
	baseUrlSelector,
	issueKeySelector,
	accountIdloggedInUserSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import {
	uploadedAttachmentSelector,
	uploadedAttachmentsByIdSelector,
} from '@atlassian/jira-issue-view-store/src/selectors/attachment-picker-selector.tsx';
import { createAttachmentInJira } from './attachment-save-server.tsx';

const RETRY_ATTEMPTS = 3;
const EXCEED_SIZE_LIMIT_MESSAGE = 'exceedes max allowed size';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const saveAttachmentEpic = (action$: ActionsObservable<any>, store: MiddlewareAPI<State>) =>
	action$.ofType(ATTACHMENT_MEDIA_SERVICES_UPLOAD_END).mergeMap((action) => {
		const state = store.getState();
		const baseUrl = baseUrlSelector(state);
		const issueKey = issueKeySelector(state);
		const loggedInAccountId = accountIdloggedInUserSelector(state);
		const attachment = uploadedAttachmentSelector(action.payload.id)(state);

		if (attachment === undefined) {
			// https://jdog.jira-dev.com/browse/BENTO-10220
			// Try to understand why attachment is undefined, It could be the action.payload is undefined
			trackOrLogClientError(
				'issue.attachment.jira-create-error',
				'Failed to upload attachment',
				action.payload?.id,
			);

			return Observable.of(attachmentJiraCreateFailure(attachment, 200));
		}

		return createAttachmentInJira(attachment, baseUrl, issueKey, loggedInAccountId)
			.retry(RETRY_ATTEMPTS)
			.map(attachmentJiraCreateSuccess)
			.catch((error) => {
				trackOrLogClientError(
					'issue.attachment.jira-create-error',
					'Could not create attachment in Jira',
					error,
				);
				const exceedFileSizeLimit = error.message?.includes(EXCEED_SIZE_LIMIT_MESSAGE);
				return Observable.of(
					attachmentJiraCreateFailure(attachment, error.statusCode, exceedFileSizeLimit),
				);
			});
	});

const attachmentJiraCreationEndEpicFn = () => {
	let numCreated = 0;
	let numFailed = 0;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return (action$: ActionsObservable<any>, store: MiddlewareAPI<State>) =>
		action$
			.ofType(ATTACHMENT_JIRA_CREATE_FAILURE, ATTACHMENT_JIRA_CREATE_SUCCESS)
			.do(({ type }) => {
				if (type === ATTACHMENT_JIRA_CREATE_SUCCESS) {
					numCreated += 1;
				} else if (type === ATTACHMENT_JIRA_CREATE_FAILURE) {
					numFailed += 1;
				}
			})
			.filter(() => {
				const attachments = uploadedAttachmentsByIdSelector(store.getState());
				const numAttachmentsInProgress = Object.keys(attachments).length;
				return numAttachmentsInProgress === 0;
			})
			.map(() => {
				const issueKey = issueKeySelector(store.getState());
				const action = attachmentJiraCreateEnd(issueKey, numCreated, numFailed);
				numCreated = 0;
				numFailed = 0;
				return action;
			});
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const logJiraAttachmentCreationFailureEpic = (action$: ActionsObservable<any>) =>
	action$
		.ofType(ATTACHMENT_JIRA_CREATE_FAILURE)
		.do((action) => {
			log.safeErrorWithoutCustomerData(
				'issue.attachment.jira-create-error',
				'Could not create attachment in Jira',
				{ statusCode: action.payload.statusCode },
			);
		})
		.ignoreElements();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const logMediaServicesUploadErrorEpic = (action$: ActionsObservable<any>) =>
	action$
		.ofType(MEDIA_PICKER_ERROR, ATTACHMENT_MEDIA_SERVICES_UPLOAD_ERROR)
		.do((action) => {
			const { description, name, fileId } = action.payload;
			log.safeErrorWithoutCustomerData(
				'issue.attachment.media-services-upload-error',
				'Could not upload attachment to Media Services',
				{ description, name, fileId },
			);
		})
		.ignoreElements();

// We would like to inform the user if they reach their attachment limit
// for displaying thumbnails.
export const alertUserAboutAttachmentLimitEpic =
	(attachmentActions: AttachmentServiceActions) =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(action$: ActionsObservable<any>, store: MiddlewareAPI<State>) =>
		action$
			.ofType(ATTACHMENT_JIRA_CREATE_SUCCESS)
			.map(() => {
				const state = store.getState();
				const issueKey = issueKeySelector(state);
				return attachmentActions.getAttachmentsCount(issueKey);
			})
			.distinct()
			.do((totalCount: number) => {
				const { showFlag } = useFlagsService();
				const flagOptions = {
					isAutoDismiss: true,
					actions: [
						{
							content: attachmentLimitMessages.issueAttachmentLimitLink,
							href: ATTACHMENTS_LEARN_ABOUT_LINK,
							target: '_blank',
						},
					],
				};
				if (totalCount === ATTACHMENT_NUMBER_EXCEEDED_LIMIT) {
					showFlag({
						type: 'warning',
						title: expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
							? attachmentLimitMessages.issueAttachmentOverLimitFlagHeadingIssueTermRefresh
							: attachmentLimitMessages.issueAttachmentOverLimitFlagHeading,
						description: attachmentLimitMessages.issueAttachmentOverLimitFlagBody,
						...flagOptions,
					});
					return;
				}
				if (
					isAttachmentNumberApproachLimit(totalCount) &&
					!isAttachmentNumberOverLimit(totalCount)
				) {
					showFlag({
						type: 'warning',
						title: expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
							? attachmentLimitMessages.issueAttachmentReachingLimitFlagHeadingIssueTermRefresh
							: attachmentLimitMessages.issueAttachmentReachingLimitFlagHeading,
						description: expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
							? attachmentLimitMessages.issueAttachmentReachingLimitFlagBodyIssueTermRefresh
							: attachmentLimitMessages.issueAttachmentReachingLimitFlagBody,
						...flagOptions,
					});
				}
			})
			.ignoreElements();

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (attachmentActions: AttachmentServiceActions) =>
	combineEpics(
		saveAttachmentEpic,
		attachmentJiraCreationEndEpicFn(),
		logJiraAttachmentCreationFailureEpic,
		logMediaServicesUploadErrorEpic,
		alertUserAboutAttachmentLimitEpic(attachmentActions),
	);
