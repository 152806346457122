import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	errorTitleWithFieldName: {
		id: 'issue-field-number-inline-edit-full.number.error-title-with-field-name',
		defaultMessage: "We couldn't set the {fieldName} value",
		description: 'Error message informing the user that updating the field failed',
	},
	errorMessage: {
		id: 'issue-field-number-inline-edit-full.number.error-message',
		defaultMessage: 'Check your connection, then give it another try.',
		description: 'Error message when save fails',
	},
});
