import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import {
	baseUrlSelector,
	issueKeySelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import ExportIssueDoc from './main.tsx';
import type { StateProps } from './types.tsx';

export default connect(
	(state): StateProps => ({
		baseUrl: baseUrlSelector(state),
		issueKey: issueKeySelector(state),
	}),
	{},
)(ExportIssueDoc);
