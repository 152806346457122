import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/mergeMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { CLASSIC_SUBTASKS, CHILDREN_ISSUES_PANEL } from '../../model/types.tsx';
import { getChildIssuesPanelType } from '../../state/context/selectors.tsx';
import {
	type ReorderChildrenRequestAction,
	REORDER_CHILDREN_REQUEST,
} from '../../state/entities/actions.tsx';
import type { State } from '../../state/types.tsx';
import reorderChildren from './reorder-children.tsx';
import reorderClassicSubtasks from './reorder-subtasks.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	action$: ActionsObservable<ReorderChildrenRequestAction>,
	store: MiddlewareAPI<State>,
) =>
	action$.ofType(REORDER_CHILDREN_REQUEST).mergeMap((action) => {
		const state = store.getState();
		const panelType = getChildIssuesPanelType(state);

		switch (panelType) {
			case CLASSIC_SUBTASKS:
				return reorderClassicSubtasks(state, action.payload);

			case CHILDREN_ISSUES_PANEL:
				return reorderChildren(state, action.payload, action.meta);

			default:
				return Observable.empty<never>();
		}
	});
