import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	acceptAssignee: {
		id: 'servicedesk-ai-context-common.actions.update-assignee.action',
		defaultMessage: 'Accept',
		description: 'CTA text for the update assignee button',
	},
	successTitle: {
		id: 'servicedesk-ai-context-common.actions.update-assignee.success-title',
		defaultMessage: 'Assignee updated',
		description: 'Success title telling the user that the assignee has been updated',
	},
	successDescription: {
		id: 'servicedesk-ai-context-common.actions.update-assignee.success-description',
		defaultMessage: "We've assigned the issue to {assignee}.",
		description: 'Success description telling the user that the assignee has been set',
	},
	errorTitle: {
		id: 'servicedesk-ai-context-common.actions.update-assignee.error-title',
		defaultMessage: "We couldn't update assignee",
		description: 'Error message informing the user that updating the assignee field failed',
	},
	errorDescription: {
		id: 'servicedesk-ai-context-common.actions.update-assignee.error-message',
		defaultMessage: 'Something went wrong. Try again or refresh the page.',
		description: 'Error message when save fails',
	},
});
