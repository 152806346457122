import pipe from 'lodash/fp/pipe';
import isBoolean from 'lodash/isBoolean';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import { fromPromise } from 'rxjs/observable/fromPromise';
import {
	performDeleteRequest,
	performGetRequest,
	performPutRequest,
} from '@atlassian/jira-fetch/src/utils/requests.tsx';
import {
	ISSUE_PANEL_MULTIPLE_LIMIT,
	NONE_PANEL_INSTANCE_ID,
} from '@atlassian/jira-forge-ui-constants/src/constants.tsx';
import { LimitError } from '@atlassian/jira-forge-ui-utils/src/utils/errors/index.tsx';
import type { PanelInstance } from '@atlassian/jira-issue-view-common-types/src/forge-types.tsx';
import type { BaseUrl, IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';

const createUrl = (baseUrl: BaseUrl, issueKey: IssueKey, extensionId: string): string =>
	`${baseUrl}/rest/api/2/issue/${issueKey}/properties/${extensionId}`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const createOnError = (okFn: () => Array<PanelInstance>) => (e: any) => {
	// If the entity prop doesn't exist we expect 404
	if (e.statusCode === 404) {
		return okFn();
	}
	throw e;
};

export const doesPanelMatchIdAndDate = (panel: PanelInstance, added: number, id = 'none') =>
	panel.added === added && (id !== NONE_PANEL_INSTANCE_ID ? panel.id === id : true);

const deletePanels = (endpoint: string) =>
	performDeleteRequest(endpoint)
		.then(() => [])
		.catch(createOnError(() => []));

const updatePanels = (endpoint: string, panels: PanelInstance[]) =>
	performPutRequest(endpoint, {
		body: JSON.stringify(panels),
	}).then(() => panels);

const getPanels = (endpoint: string): Promise<PanelInstance[]> =>
	performGetRequest(endpoint)
		.then(({ value }) =>
			(Array.isArray(value) ? value : [])
				.map(({ added, id, collapsed }) => ({
					added,
					id,
					collapsed,
				}))
				.filter(
					({ added, id, collapsed }) =>
						isNumber(added) &&
						(id != null ? isString(id) : true) &&
						(collapsed != null ? isBoolean(collapsed) : true),
				),
		)
		.catch(createOnError(() => []));

const createPanelsService = (baseUrl: BaseUrl, issueKey: IssueKey, extensionId: string) => {
	const endpoint = createUrl(baseUrl, issueKey, extensionId);
	return {
		get: () => getPanels(endpoint),
		update: (panels: PanelInstance[]) => updatePanels(endpoint, panels),
		deleteEntry: () => deletePanels(endpoint),
	};
};

export type IssueParams = {
	issueKey: IssueKey;
	extensionId: string;
	id: string;
	added: number;
};

export const updateExtensionInIssue = pipe([
	async (
		baseUrl: BaseUrl,
		{ issueKey, extensionId, added, id }: IssueParams,
		collapsed: boolean,
	) => {
		const { get, update } = createPanelsService(baseUrl, issueKey, extensionId);
		const panels = await get();

		if (panels.length === 0) {
			return [];
		}

		const updatedPanels: PanelInstance[] = panels.map((panel) => ({
			...panel,
			collapsed: doesPanelMatchIdAndDate(panel, added, id) ? collapsed : panel.collapsed,
		}));

		return update(updatedPanels).then(() => updatedPanels);
	},
	fromPromise,
]);

export const saveExtensionToIssue = pipe([
	async (
		baseUrl: BaseUrl,
		issueKey: IssueKey,
		extensionId: string,
		date: number,
		allowMultiple: boolean,
		panelInstanceId: string,
	) => {
		const { get, update } = createPanelsService(baseUrl, issueKey, extensionId);
		const panels = await get();

		const newPanel = {
			added: date,
			id: panelInstanceId,
			collapsed: false,
		};

		if (panels.length === 0) {
			return update([newPanel]).then(() => [newPanel]);
		}

		if (allowMultiple) {
			if (panels.length >= ISSUE_PANEL_MULTIPLE_LIMIT) {
				throw new LimitError('Multiple issue panel installation limit reached.');
			}

			return update([...panels, newPanel]).then(() => [...panels, newPanel]);
		}

		return [panels[0]] as const;
	},
	fromPromise,
]);

export const removeExtensionFromIssue = pipe([
	async (baseUrl: BaseUrl, issueKey: IssueKey, extensionId: string, added: number, id?: string) => {
		const { get, update, deleteEntry } = createPanelsService(baseUrl, issueKey, extensionId);

		const panels = await get();

		if (panels.length === 0) {
			return [];
		}

		const filteredPanels = panels.filter((panel) => !doesPanelMatchIdAndDate(panel, added, id));

		return filteredPanels.length === 0 ? deleteEntry() : update(filteredPanels);
	},
	fromPromise,
]);
