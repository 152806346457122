import { useCallback } from 'react';
import { useGICPositioningEnabledController } from './gic-positioning-enabled-controller/index.tsx';

export const useIsGICPositioningEnabled = () => {
	const [{ disableGICPositioning }] = useGICPositioningEnabledController();
	const isGICPositioningEnabled = useCallback(
		() => !disableGICPositioning,
		[disableGICPositioning],
	);
	return isGICPositioningEnabled;
};
