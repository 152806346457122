import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	organizations: {
		id: 'servicedesk-customer-service-issue-view-panel.organizations.organizations',
		defaultMessage: 'Organization details',
		description:
			'Section header in the issue view panel for which organizations the customer is a part of',
	},
});
