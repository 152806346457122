import React from 'react';
import Lozenge from '@atlaskit/lozenge';
import { PRETTY } from '@atlassian/jira-common-constants/src/jira-settings.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { FormattedMessage } from '@atlassian/jira-intl';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import { Emphasise } from '@atlassian/jira-issue-view-activity-common/src/styles/index.tsx';
import { useTimeTrackingConfiguration } from '@atlassian/jira-settings-service/src/main.tsx';
import { timeTrackingFormatter } from '@atlassian/jira-time-tracking-formatter/src/main.tsx';
import messages from '../../../../common/messages.tsx';
import Change from '../../../../common/ui/change/index.tsx';
import GroupedChange from '../../../../common/ui/grouped-change/index.tsx';
import HistoryItem from '../../../../common/ui/history-item/index.tsx';
import type { TimeChangedHistoryItem } from '../../../../types.tsx';

type Props = {
	isGrouped?: Boolean;
	historyItem: TimeChangedHistoryItem;
};

const TimeChangedHistoryItemView = ({ historyItem, isGrouped = false }: Props) => {
	const { actor, timestamp, fromTime, toTime, field, fieldType } = historyItem;
	const [config] = useTimeTrackingConfiguration();
	const intl = useIntl();

	let from = fromTime;
	let to = toTime;

	if (config) {
		const formatterConfig = {
			workingHoursPerDay: config.hoursPerDay,
			workingDaysPerWeek: config.daysPerWeek,
			timeFormat: config.format || PRETTY,
			defaultUnit: config.defaultUnit,
		};
		if (typeof fromTime === 'number') {
			// @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'number | null'.
			from = timeTrackingFormatter(fromTime, formatterConfig, intl);
		}
		if (typeof toTime === 'number') {
			// @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'number | null'.
			to = timeTrackingFormatter(toTime, formatterConfig, intl);
		}
	}

	const normalizedField = fieldType === 'jira' ? field?.toLocaleLowerCase() : field;

	return (
		<FormattedMessage
			{...messages.updatedField}
			values={{
				field: <Emphasise key="field">{normalizedField}</Emphasise>,
			}}
		>
			{(...action) =>
				isGrouped && expVal('issue_view_activity_timeline', 'isActivityTimelineEnabled', false) ? (
					<GroupedChange
						field={field}
						from={from !== null && <Lozenge>{from}</Lozenge>}
						to={to !== null && <Lozenge>{to}</Lozenge>}
					/>
				) : (
					<HistoryItem
						// eslint-disable-next-line jira/integration/test-id-by-folder-structure
						testId="issue-history.ui.history-items.time-changed-history-item.history-item"
						actor={actor}
						action={action}
						timestamp={timestamp}
						change={
							<Change
								from={from !== null && <Lozenge>{from}</Lozenge>}
								to={to !== null && <Lozenge>{to}</Lozenge>}
							/>
						}
					/>
				)
			}
		</FormattedMessage>
	);
};

export default TimeChangedHistoryItemView;
