import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const asyncIssueActionsEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		async () =>
			(await import(/* webpackChunkName: "async-command-palette-issue-actions-core" */ './src/ui'))
				.CommandPaletteIssueActionsEntryPoint,
	),
	getPreloadProps: () => ({}),
});
