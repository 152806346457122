import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { FieldInlineEditStateLess } from '@atlassian/jira-issue-field-inline-edit/src/ui/index.tsx';

const compactWrapperControlSelectorName =
	'jira.issue-view.common.inline-edit.compact-wrapper-control';
export const COMPACT_WRAPPER_COMPONENT_SELECTOR = `[data-component-selector="${compactWrapperControlSelectorName}"]`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CompactInlineEditStateless = (props: any) => (
	<CompactWrapper
		isEditing={props.isEditing}
		isGrouped={props.isGrouped}
		data-testid={props['data-testid']}
		data-component-selector={compactWrapperControlSelectorName}
	>
		<FieldInlineEditStateLess {...props} />
	</CompactWrapper>
);

export default CompactInlineEditStateless;

// Current AK Inline Edit doesn't have a compact mode.
// We want to apply custom styling for now and move to
// a new component that supports this in future.
// See: https://jdog.jira-dev.com/browse/BENTO-2979
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const CompactWrapper = styled.div<{ isEditing?: boolean; isGrouped: boolean }>(
	{
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		marginBottom: (props) => (props.isGrouped !== true ? token('space.300', '24px') : 0),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isEditing }) =>
		isEditing
			? {}
			: {
					'& form > div > div > div': {
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
						minHeight: '32px !important',
					},
				},
);
