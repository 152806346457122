import React from 'react';

import { useIntl } from 'react-intl-next';

import Button from '@atlaskit/button/new';
import { Box, Flex, xcss } from '@atlaskit/primitives';

import { ClassificationView } from '../../common/ui/classification-view';
import { messages } from '../../messages';

import { type ReadPopupProps } from './types';

const containerStyles = xcss({
	width: '330px',
});

export const ReadPopup = ({
	name,
	guideline,
	guidelineRepresentation = 'PLAIN',
	onChangeLevelClick,
}: ReadPopupProps) => {
	const intl = useIntl();

	return (
		<Box
			padding="space.250"
			xcss={containerStyles}
			role="dialog"
			aria-labelledby="data-classification-read-content-name"
			aria-describedby="data-classification-read-content-guideline"
			aria-modal="true"
		>
			<ClassificationView
				name={name}
				guideline={guideline}
				guidelineRepresentation={guidelineRepresentation}
			/>
			{Boolean(onChangeLevelClick) && (
				<Box paddingBlockStart="space.200">
					<Flex justifyContent="end" gap="space.100">
						<Button onClick={onChangeLevelClick}>{intl.formatMessage(messages.changeLevel)}</Button>
					</Flex>
				</Box>
			)}
		</Box>
	);
};
