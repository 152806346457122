import React from 'react';
import { styled, css } from '@compiled/react';
import { Flex, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import type { CommandRowProps } from '../../../types/commands/index.tsx';
import { OutlinedLozenge } from '../../custom-lozenge/index.tsx';
import {
	COMMAND_CONTAINER_TEST_ID,
	LEFT_ICON_TEST_ID,
	MODE_TEST_ID,
	COMMAND_ICON_CONTAINER_TEST_ID,
	COMMAND_INNER_CONTAINER_TEST_ID,
} from './constants.tsx';
import { RenderCommandName } from './render-command-name/index.tsx';
import { RightContent } from './right-content/index.tsx';

export const CommandRow = ({
	index,
	command,
	isActive,
	isFirstCommand,
	analyticsAttributes,
	...rest
}: CommandRowProps) => {
	const InnerContainer = CommandContainerInner;

	return (
		<CommandContainerWrapper data-testid={command.id}>
			<CommandContainer
				index={index}
				active={isActive}
				data-active={isActive}
				data-testid={COMMAND_CONTAINER_TEST_ID}
			>
				<InnerContainer data-testid={COMMAND_INNER_CONTAINER_TEST_ID} {...rest}>
					<CommandLeftIconAndNameContainer>
						<CommandIconsContainer data-testid={COMMAND_ICON_CONTAINER_TEST_ID}>
							{command.components?.LeftIcon &&
								(isVisualRefreshEnabled() ? (
									<Flex
										testId={LEFT_ICON_TEST_ID}
										alignItems="center"
										justifyContent="center"
										xcss={[iconStyles, isActive && iconActiveStyles]}
									>
										<command.components.LeftIcon />
									</Flex>
								) : (
									<CommandLeftIconContainer data-testid={LEFT_ICON_TEST_ID} active={isActive}>
										<command.components.LeftIcon />
									</CommandLeftIconContainer>
								))}
							{command.mode && (
								<OutlinedLozenge data-testid={MODE_TEST_ID}>{command.mode}</OutlinedLozenge>
							)}
						</CommandIconsContainer>
						<RenderCommandName command={command} isActive={isActive} />
					</CommandLeftIconAndNameContainer>
					<RightContent command={command} isFirstCommand={isFirstCommand} isActive={isActive} />
				</InnerContainer>
			</CommandContainer>
		</CommandContainerWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CommandContainerWrapper = styled.div({
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CommandContainer = styled.div<{ active?: boolean; index?: number }>(
	{
		font: token('font.body'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		color: token('color.text.subtle', colors.N500),
		padding: `${token('space.050', '4px')} 0`,
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ active }) =>
		!!active &&
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		css({
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			color: token('color.text', colors.N700),
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			backgroundColor: token('color.background.selected', colors.B50),
			position: 'relative',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
			'&:before': {
				content: '""',
				position: 'absolute',
				top: 0,
				left: 0,
				bottom: 0,
				width: token('space.025', '2px'),
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				backgroundColor: token('color.border.focused', colors.B200),
			},
		}),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CommandContainerInner = styled.div({
	padding: `${token('space.050', '4px')} ${token('space.250', '20px')}`,
	display: 'flex',
	alignItems: 'center',
	cursor: 'pointer',
	pointerEvents: 'auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CommandIconsContainer = styled.div({
	marginRight: token('space.050', '4px'),
	gap: token('space.050', '4px'),
	display: 'flex',
	alignItems: 'center',
});

const iconStyles = xcss({
	width: '24px',
	height: '24px',
	// @ts-expect-error ts(2322) - this color should be set directly within icon after migration, but requires bigger change in types.
	color: 'color.icon.subtle',
});

const iconActiveStyles = xcss({
	// @ts-expect-error ts(2322) - this color should be set directly within icon after migration.
	color: 'color.icon',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CommandLeftIconContainer = styled.div<{ active?: boolean }>({
	display: 'flex',
	alignItems: 'center',
	width: '24px',
	height: '24px',
	lineHeight: '24px',
	justifyContent: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	color: ({ active }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		active ? token('color.icon', colors.N400) : token('color.icon.subtle', colors.N700),
	alignSelf: 'flex-start',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > span': {
		height: '16px',
		width: '16px',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CommandLeftIconAndNameContainer = styled.div({
	display: 'flex',
	flexDirection: 'row',
	flex: 1,
	lineHeight: '24px',
	overflow: 'hidden',
});
