/**
 * @generated SignedSource<<3182d85922663e3aa83b74ec024c86cb>>
 * @relayHash 91be5c344ee0d815b9488120a6e3d2ac
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID fef917a924aab25e1dca4f0b0d78551cf23b3fe8146d59589fa4d1d10810b787

import type { ConcreteRequest, Mutation } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraMultiValueFieldOperations = "ADD" | "REMOVE" | "SET" | "%future added value";
export type JiraUpdateLabelsFieldInput = {
  id: string;
  operations: ReadonlyArray<JiraLabelsFieldOperationInput>;
};
export type JiraLabelsFieldOperationInput = {
  labels: ReadonlyArray<string>;
  operation: JiraMultiValueFieldOperations;
};
export type labels_issueFieldLabelsInlineEditViewFull_LabelsInlineEditViewMutation$variables = {
  input: JiraUpdateLabelsFieldInput;
};
export type labels_issueFieldLabelsInlineEditViewFull_LabelsInlineEditViewMutation$data = {
  readonly jira: {
    readonly updateLabelsField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly " $fragmentSpreads": FragmentRefs<"labels_issueFieldLabelsInlineEditViewFull_LabelsInlineEditViewWithIsEditable_fragmentRef">;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type labels_issueFieldLabelsInlineEditViewFull_LabelsInlineEditViewMutation$rawResponse = {
  readonly jira: {
    readonly updateLabelsField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly fieldId: string;
        readonly id: string;
        readonly name: string;
        readonly selectedLabelsConnection: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly name: string | null | undefined;
            } | null | undefined;
          } | null | undefined> | null | undefined;
          readonly totalCount: number | null | undefined;
        } | null | undefined;
        readonly type: string;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type labels_issueFieldLabelsInlineEditViewFull_LabelsInlineEditViewMutation = {
  rawResponse: labels_issueFieldLabelsInlineEditViewFull_LabelsInlineEditViewMutation$rawResponse;
  response: labels_issueFieldLabelsInlineEditViewFull_LabelsInlineEditViewMutation$data;
  variables: labels_issueFieldLabelsInlineEditViewFull_LabelsInlineEditViewMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "success"
},
v3 = {
  "concreteType": "MutationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "message"
    }
  ]
},
v4 = {
  "kind": "ScalarField",
  "name": "name"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "labels_issueFieldLabelsInlineEditViewFull_LabelsInlineEditViewMutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraLabelsFieldPayload",
            "kind": "LinkedField",
            "name": "updateLabelsField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraLabelsField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  {
                    "kind": "FragmentSpread",
                    "name": "labels_issueFieldLabelsInlineEditViewFull_LabelsInlineEditViewWithIsEditable_fragmentRef"
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "labels_issueFieldLabelsInlineEditViewFull_LabelsInlineEditViewMutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraLabelsFieldPayload",
            "kind": "LinkedField",
            "name": "updateLabelsField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraLabelsField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "fieldId"
                  },
                  {
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "first",
                        "value": 1000
                      }
                    ],
                    "concreteType": "JiraLabelConnection",
                    "kind": "LinkedField",
                    "name": "selectedLabelsConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "totalCount"
                      },
                      {
                        "concreteType": "JiraLabelEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "concreteType": "JiraLabel",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/)
                            ]
                          }
                        ]
                      }
                    ],
                    "storageKey": "selectedLabelsConnection(first:1000)"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "id"
                  },
                  (v4/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "type"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "fef917a924aab25e1dca4f0b0d78551cf23b3fe8146d59589fa4d1d10810b787",
    "metadata": {},
    "name": "labels_issueFieldLabelsInlineEditViewFull_LabelsInlineEditViewMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "b7c0af684826341f4d5d5b319e095b51";

export default node;
