import { Reason, type StoreApi, type SetEventPayload } from '../../types.tsx';

export const setEvent =
	(payload: SetEventPayload) =>
	({ setState, getState }: StoreApi) => {
		const timestamp = Date.now();
		const { reason, refreshed } = payload;
		const reported = false;

		let event;
		switch (reason) {
			case Reason.Realtime: {
				const { details } = payload;
				event = { [reason]: { timestamp, details, refreshed, reported } };
				break;
			}
			case Reason.SoftRefresh: {
				event = { [reason]: { timestamp, refreshed, reported } };
				break;
			}
			case Reason.OnDemand:
			default:
				event = { [Reason.OnDemand]: { timestamp, refreshed, reported } };
				break;
		}

		setState({
			events: {
				...getState().events,
				...event,
			},
		});
	};

export const setEventAsReported =
	(eventReason: Reason) =>
	({ setState, getState }: StoreApi) => {
		const state = getState();
		const event = state.events[eventReason];
		if (!event) return;
		setState({
			events: {
				...state.events,
				[eventReason]: {
					...event,
					reported: true,
				},
			},
		});
	};
