/* eslint-disable @atlassian/relay/unused-fields */
import React, { useCallback, type ComponentPropsWithoutRef } from 'react';
import memoizeOne from 'memoize-one';
import { useFragment, graphql, useMutation, fetchQuery, useRelayEnvironment } from 'react-relay';
import type {
	SelectValueShape,
	ServerSuggestions,
} from '@atlassian/jira-issue-internal-field-select/src/common/select-inline-edit/select-field/types.tsx';
import MultiSelectInlineEditView from '@atlassian/jira-issue-internal-field-select/src/multi-select-inline-edit/index.tsx';
import messages from '@atlassian/jira-issue-view-base/src/context/multi-user/messages.tsx';
import {
	type StateUser,
	transformToStateValue,
	transformFromStateValue,
	avatarSize,
} from '@atlassian/jira-issue-view-base/src/context/multi-user/transformer.tsx';
import { formatOptionLabel } from '@atlassian/jira-issue-view-base/src/context/request-participants/index.tsx';
import { genericMessages } from '@atlassian/jira-issue-view-common-constants/src/context-items-messages.tsx';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button/index.tsx';
import { getDisplayName } from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-field.tsx';
import {
	useConnectRelayField,
	type ConnectedLayoutProps,
	type PropsCallback,
} from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-relay-field.tsx';
import { ConnectedRelayFieldWrapper } from '@atlassian/jira-issue-view-common-views/src/connect-field/relay-field/field-wrapper.tsx';
import type { AggUser } from '@atlassian/jira-issue-view-layout-multi-user-field/src/common/types.tsx';
import { mapNodes } from '@atlassian/jira-relay-utils/src/utils/map-nodes/index.tsx';
import type {
	ui_issueViewLayoutRequestParticipantsField_IssueViewRequestParticipantsField$key as ComponentsInlineEditFragment,
	ui_issueViewLayoutRequestParticipantsField_IssueViewRequestParticipantsField$data as ComponentsInlineEditFragmentData,
} from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutRequestParticipantsField_IssueViewRequestParticipantsField.graphql';
import type { ui_issueViewLayoutRequestParticipantsField_IssueViewRequestParticipantsField_Mutation } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutRequestParticipantsField_IssueViewRequestParticipantsField_Mutation.graphql';
import type { uiRequestParticipantsFieldSearchRefetchQuery as uiRequestParticipantsFieldSearchRefetchQueryType } from '@atlassian/jira-relay/src/__generated__/uiRequestParticipantsFieldSearchRefetchQuery.graphql';

type ComponentValueShape = SelectValueShape[];
type AggValueShape = Pick<
	ComponentsInlineEditFragmentData,
	'selectedUsersConnection'
>['selectedUsersConnection'];

export type IssueViewRequestParticipantsFieldProps =
	ConnectedLayoutProps<ComponentsInlineEditFragment>;

export const toComponentValueShape = (fieldData: AggUser[]): StateUser[] =>
	fieldData.map((user) => ({
		displayName: user.name,
		avatarUrls: {
			[avatarSize]: user.picture,
		},
		id: user.id,
		accountId: user.accountId,
		email: user.email ?? undefined,
	}));

const transformComponentValueToAggShape = (componentValue: ComponentValueShape) => {
	return {
		selectedUsersConnection: {
			edges:
				componentValue?.map((option) => ({
					node: {
						__typename: 'AtlassianAccountUser',
						accountId: option.accountId ?? '',
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						id: option.id as string,
						name: option.content,
						picture: option.avatarUrl ?? '',
						email: option.email,
					},
				})) ?? [],
		},
	};
};
const transformSuggestions = memoizeOne((response: AggUser[] | null): ServerSuggestions => {
	const items: AggUser[] = response ?? [];

	return [
		{
			items: items.map((item) => ({
				content: item.name,
				value: String(item.accountId),
				accountId: item.accountId,
				id: item.id,
				avatarUrl: item.picture,
				email: item.email ?? undefined,
			})),
		},
	];
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const analyticsAttributeIdRetriever = (value: any): string =>
	value && Array.isArray(value)
		? value.map((participant) => participant.accountId).join(',')
		: value;

const filterOption = () => true;

type AdditionalProps = Pick<
	ComponentPropsWithoutRef<typeof MultiSelectInlineEditView>,
	'fetchSuggestions' | 'placeholder' | 'noValueText' | 'tagAppearance' | 'showPinButton'
> & {
	analyticsAttributeIdRetriever: typeof analyticsAttributeIdRetriever;
	formatOptionLabel: typeof formatOptionLabel;
	transformToStateValue: typeof transformToStateValue;
	filterOption: () => boolean;
	showDropdownOnClick: boolean;
};

export function IssueViewRequestParticipantsField(props: IssueViewRequestParticipantsFieldProps) {
	const environment = useRelayEnvironment();
	const data = useFragment<ComponentsInlineEditFragment>(
		graphql`
			fragment ui_issueViewLayoutRequestParticipantsField_IssueViewRequestParticipantsField on JiraMultipleSelectUserPickerField {
				id
				fieldId
				type
				name
				description
				__typename

				fieldConfig {
					isEditable
				}
				issue {
					key
				}
				selectedUsersConnection {
					edges {
						node {
							accountId
							id
							name
							picture
							... on AtlassianAccountUser {
								email
							}
						}
					}
				}
			}
		`,
		props.fragmentKey,
	);

	const [commit] =
		useMutation<ui_issueViewLayoutRequestParticipantsField_IssueViewRequestParticipantsField_Mutation>(
			graphql`
				mutation ui_issueViewLayoutRequestParticipantsField_IssueViewRequestParticipantsField_Mutation(
					$input: JiraUpdateMultipleSelectUserPickerFieldInput!
				) @raw_response_type {
					jira @optIn(to: "JiraIssueFieldMutations") {
						updateMultipleSelectUserPickerField(input: $input) {
							success
							errors {
								message
							}
							field {
								id
								selectedUsersConnection {
									edges {
										node {
											accountId
											id
											name
											picture
											... on AtlassianAccountUser {
												email
											}
										}
									}
								}
							}
						}
					}
				}
			`,
		);

	const getComponentProps = useCallback<
		PropsCallback<
			ComponentsInlineEditFragment,
			ComponentsInlineEditFragmentData,
			ComponentValueShape,
			AggValueShape,
			AdditionalProps
		>
	>(
		({ intl, softRefreshCallbacks }) => {
			const fetchSuggestions = async (query: string): Promise<ServerSuggestions> => {
				const queryData = await fetchQuery<uiRequestParticipantsFieldSearchRefetchQueryType>(
					environment,
					graphql`
						query uiRequestParticipantsFieldSearchRefetchQuery(
							$id: ID!
							$searchBy: String!
							$first: Int!
							$after: String
						) {
							node(id: $id) {
								... on JiraMultipleSelectUserPickerField {
									users(searchBy: $searchBy, first: $first, after: $after)
										@connection(key: "RequestParticipants_issueFieldRequestParticipants_users") {
										edges {
											node {
												id
												accountId
												name
												picture
												... on AtlassianAccountUser {
													email
												}
											}
										}
									}
								}
							}
						}
					`,
					{
						id: data.id,
						searchBy: query,
						first: 50,
						after: null,
					},
				).toPromise();

				return transformSuggestions(mapNodes<AggUser>(queryData?.node?.users));
			};
			return {
				jiraIssueField: data,

				value: transformFromStateValue(
					toComponentValueShape(mapNodes<AggUser>(data.selectedUsersConnection)),
				),

				onValueConfirm(componentValue) {
					const newAggValue = transformComponentValueToAggShape(componentValue);

					softRefreshCallbacks.onSubmit(newAggValue.selectedUsersConnection);

					const newIds = mapNodes<AggUser>(newAggValue.selectedUsersConnection).map(
						(value) => value.id,
					);

					commit({
						variables: {
							input: {
								id: data.id,
								operations: [
									{
										ids: newIds,
										operation: 'SET',
									},
								],
							},
						},
						onCompleted: (mutationData) => {
							if (mutationData.jira?.updateMultipleSelectUserPickerField?.success) {
								softRefreshCallbacks.onSubmitSucceeded(newAggValue.selectedUsersConnection);
							} else {
								softRefreshCallbacks.onSubmitFailed();
							}
						},
						onError() {
							softRefreshCallbacks.onSubmitFailed();
						},
						optimisticResponse: {
							jira: {
								updateMultipleSelectUserPickerField: {
									success: true,
									errors: [],
									field: {
										id: data.id,
										selectedUsersConnection: {
											...newAggValue.selectedUsersConnection,
										},
									},
								},
							},
						},
					});
				},

				additionalProps: {
					fetchSuggestions,
					placeholder: intl.formatMessage(messages.placeholder),
					noValueText: intl.formatMessage(genericMessages.noValue),
					tagAppearance: 'rounded',
					analyticsAttributeIdRetriever,
					formatOptionLabel,
					showPinButton: getShowPinButton(props.area),
					filterOption,
					transformToStateValue,
					showDropdownOnClick: false,
				},
			};
		},
		[commit, data, props.area, environment],
	);

	const connectField = useConnectRelayField(props, data, getComponentProps);
	const componentName = getDisplayName(MultiSelectInlineEditView);

	return (
		<ConnectedRelayFieldWrapper componentName={componentName}>
			{/* @ts-expect-error - TS2322 - ReturnType of useConnectRelayField do match View props interface as additionalProps are being spread */}
			<MultiSelectInlineEditView {...connectField.componentProps} />
		</ConnectedRelayFieldWrapper>
	);
}
