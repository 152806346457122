/**
 * @generated SignedSource<<dd609576641dc2c5fc660f8071188fa0>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraIssueDeploymentEnvironmentState = "DEPLOYED" | "NOT_DEPLOYED" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type delivery_softwareReleasesVersionDetailIssueList$data = {
  readonly devOpsSummarisedEntities: {
    readonly summarisedBuilds?: {
      readonly count: number | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"buildData_softwareReleasesVersionDetailIssueList_devOpsSummarisedBuilds">;
    } | null | undefined;
    readonly summarisedDeployments?: {
      readonly count: number | null | undefined;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"deploymentData_softwareReleasesVersionDetailIssueList">;
  } | null | undefined;
  readonly devSummaryCache: {
    readonly devSummary: {
      readonly build: {
        readonly overall: {
          readonly count: number | null | undefined;
        } | null | undefined;
      } | null | undefined;
      readonly deploymentEnvironments: {
        readonly overall: {
          readonly topEnvironments: ReadonlyArray<{
            readonly status: JiraIssueDeploymentEnvironmentState | null | undefined;
          }> | null | undefined;
        } | null | undefined;
      } | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"bambooDeploymentData_softwareReleasesVersionDetailIssueList" | "buildData_softwareReleasesVersionDetailIssueList_jiraIssueDevSummary">;
    } | null | undefined;
  } | null | undefined;
  readonly issueId: string;
  readonly summarisedBuilds?: {
    readonly count: number | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"buildData_softwareReleasesVersionDetailIssueList_devOpsSummarisedBuilds">;
  } | null | undefined;
  readonly summarisedDeployments?: {
    readonly count: number | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "delivery_softwareReleasesVersionDetailIssueList";
};
export type delivery_softwareReleasesVersionDetailIssueList$key = {
  readonly " $data"?: delivery_softwareReleasesVersionDetailIssueList$data;
  readonly " $fragmentSpreads": FragmentRefs<"delivery_softwareReleasesVersionDetailIssueList">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "count"
},
v1 = [
  (v0/*: any*/)
],
v2 = {
  "concreteType": "DevOpsSummarisedDeployments",
  "kind": "LinkedField",
  "name": "summarisedDeployments",
  "plural": false,
  "selections": (v1/*: any*/)
},
v3 = {
  "kind": "FragmentSpread",
  "name": "buildData_softwareReleasesVersionDetailIssueList_devOpsSummarisedBuilds"
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "isIssueSummarisedDeploymentsMigratedToAgs"
    },
    {
      "kind": "RootArgument",
      "name": "shouldIncludeDevOpsFieldsViaAgs"
    }
  ],
  "kind": "Fragment",
  "name": "delivery_softwareReleasesVersionDetailIssueList",
  "selections": [
    {
      "concreteType": "DevOpsSummarisedEntities",
      "kind": "LinkedField",
      "name": "devOpsSummarisedEntities",
      "plural": false,
      "selections": [
        {
          "condition": "isIssueSummarisedDeploymentsMigratedToAgs",
          "kind": "Condition",
          "passingValue": false,
          "selections": [
            (v2/*: any*/),
            {
              "concreteType": "DevOpsSummarisedBuilds",
              "kind": "LinkedField",
              "name": "summarisedBuilds",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v3/*: any*/)
              ]
            }
          ]
        },
        {
          "kind": "FragmentSpread",
          "name": "deploymentData_softwareReleasesVersionDetailIssueList"
        }
      ]
    },
    {
      "condition": "shouldIncludeDevOpsFieldsViaAgs",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        (v2/*: any*/),
        {
          "concreteType": "DevOpsSummarisedBuilds",
          "kind": "LinkedField",
          "name": "summarisedBuilds",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            (v0/*: any*/)
          ]
        }
      ]
    },
    {
      "concreteType": "JiraIssueDevSummaryResult",
      "kind": "LinkedField",
      "name": "devSummaryCache",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraIssueDevSummary",
          "kind": "LinkedField",
          "name": "devSummary",
          "plural": false,
          "selections": [
            {
              "concreteType": "JiraIssueBuildDevSummaryContainer",
              "kind": "LinkedField",
              "name": "build",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraIssueBuildDevSummary",
                  "kind": "LinkedField",
                  "name": "overall",
                  "plural": false,
                  "selections": (v1/*: any*/)
                }
              ]
            },
            {
              "concreteType": "JiraIssueDeploymentEnvironmentDevSummaryContainer",
              "kind": "LinkedField",
              "name": "deploymentEnvironments",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraIssueDeploymentEnvironmentDevSummary",
                  "kind": "LinkedField",
                  "name": "overall",
                  "plural": false,
                  "selections": [
                    {
                      "concreteType": "JiraIssueDeploymentEnvironment",
                      "kind": "LinkedField",
                      "name": "topEnvironments",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "ScalarField",
                          "name": "status"
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              "kind": "FragmentSpread",
              "name": "bambooDeploymentData_softwareReleasesVersionDetailIssueList"
            },
            {
              "kind": "FragmentSpread",
              "name": "buildData_softwareReleasesVersionDetailIssueList_jiraIssueDevSummary"
            }
          ]
        }
      ]
    },
    {
      "kind": "ScalarField",
      "name": "issueId"
    }
  ],
  "type": "JiraIssue"
};
})();

(node as any).hash = "4ee91cee5bc4c8f8709d2bffdcc6225b";

export default node;
