import type { LocalAssociatedIssueContextType } from '@atlassian/jira-associated-issues-context-service/src/types.tsx';
import type { ChildIssue } from '@atlassian/jira-issue-shared-types/src/common/types/children-issues-type.tsx';
import type { IssueLinksMeta } from '@atlassian/jira-issue-shared-types/src/common/types/linked-issue-type.tsx';
import type { Subtask } from '@atlassian/jira-issue-shared-types/src/common/types/subtask-type.tsx';

export const getAssociatedIssuesContext = (
	associatedIssues: Array<Subtask | ChildIssue | IssueLinksMeta>,
) =>
	associatedIssues.reduce(
		(
			accumulator: LocalAssociatedIssueContextType,
			associatedIssue: ChildIssue | Subtask | IssueLinksMeta,
		) => {
			if (!associatedIssue.issueKey) {
				return accumulator;
			}

			accumulator[associatedIssue.issueKey] = {
				isResolved: associatedIssue.isResolved ?? false,
			};

			if ('estimateFieldId' in associatedIssue) {
				accumulator[associatedIssue.issueKey].estimateFieldId = associatedIssue.estimateFieldId;
			}

			return accumulator;
		},
		{},
	);
