export const shouldPrefetchLabelsSuggestionsUtil = (
	labelsFieldIsNotEmpty: Boolean,
	statusCategoryId: string | null | undefined,
	reporterAccountId: string | undefined,
	assigneeAccountId: string | undefined,
	currentUserAccountId: string | null | undefined,
): Boolean => {
	const hasToDoOrInProgressStatus =
		statusCategoryId?.toString() === '4' || statusCategoryId?.toString() === '2';

	if (currentUserAccountId) {
		return (
			!labelsFieldIsNotEmpty &&
			hasToDoOrInProgressStatus &&
			(reporterAccountId === currentUserAccountId || assigneeAccountId === currentUserAccountId)
		);
	}
	return false;
};
