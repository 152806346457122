import React, { useMemo, type ComponentType } from 'react';
import { EngagementSpotlight } from '@atlassiansox/engagekit-ts';
import { SpotlightManager, SpotlightTarget } from '@atlaskit/onboarding';
import { Box, xcss } from '@atlaskit/primitives';
import { IssueBreakdownButton } from '@atlassian/jira-ai-work-breakdown/src/ui/issue-breakdown-button/index.tsx';
import { useIsAiEnabledForIssue } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { ChildIssue } from '@atlassian/jira-issue-shared-types/src/common/types/children-issues-type.tsx';
import type { ChildIssuesLimitStatus } from '@atlassian/jira-issue-view-common-constants/src/child-issues.tsx';
import { SectionHeadingIcons } from '@atlassian/jira-issue-view-common/src/component/section-heading/section-heading-view.tsx';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller/src/components/is-anonymous/index.tsx';
import { hasExceededIssuesLimitInitialLoad } from '../../../common/util.tsx';
import { useIsAiEnabled } from '../../utils.tsx';
import type { AddChildEventHandler } from '../add-child/types.tsx';

type ConnectProps = {
	Meatballs: ComponentType<{
		childIssuesLimitStatus: ChildIssuesLimitStatus;
		isSortingAvailable?: boolean;
	}>;
	AddChild: ComponentType<{
		onClick?: AddChildEventHandler;
	}>;

	allIssues: ChildIssue[];
	supportsIssueCreation: boolean | undefined;
};

type ParentProps = {
	childIssuesLimitStatus: ChildIssuesLimitStatus;
	issueHierarchyLevel?: number | undefined;
	onAddChildClick: AddChildEventHandler | undefined;
	ColumnPicker?: React.JSX.Element;
};

type Props = ConnectProps & ParentProps;

function HeaderSectionHeadingIcons({
	Meatballs,
	AddChild,
	supportsIssueCreation,
	allIssues,
	onAddChildClick,
	childIssuesLimitStatus,
	issueHierarchyLevel,
	ColumnPicker,
}: Props) {
	const isMeatballMenuEnabled =
		hasExceededIssuesLimitInitialLoad(childIssuesLimitStatus) || allIssues.length > 0;

	const isAiEnabled = useIsAiEnabled();

	const isAiOptInEnabled = useIsAiEnabledForIssue();

	const isAnonymousUser = useIsAnonymous();

	const renderIssueBreakdownButton = useMemo(() => {
		return (
			<SpotlightManager>
				<SpotlightTarget name="suggestIssuesSpotlight">
					<IssueBreakdownButton issueHierarchyLevel={issueHierarchyLevel} isCompact />
				</SpotlightTarget>

				<EngagementSpotlight engagementId="suggestIssuesSpotlight" />
			</SpotlightManager>
		);
	}, [issueHierarchyLevel]);

	return (
		<SectionHeadingIcons>
			{!isAnonymousUser && supportsIssueCreation && isAiEnabled && isAiOptInEnabled ? (
				<Box xcss={issueBreakdownButtonStyles}>{renderIssueBreakdownButton}</Box>
			) : null}
			{isMeatballMenuEnabled ? (
				<Meatballs childIssuesLimitStatus={childIssuesLimitStatus} isSortingAvailable />
			) : null}
			{ColumnPicker}
			{supportsIssueCreation ? <AddChild onClick={onAddChildClick} /> : null}
		</SectionHeadingIcons>
	);
}

export default HeaderSectionHeadingIcons;

const issueBreakdownButtonStyles = xcss({
	marginRight: 'space.075',
});
