import { fetchJson } from '@atlassian/jira-fetch/src/index.tsx';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { User } from '@atlassian/jira-shared-types/src/rest/jira/user.tsx';

export const getUsersInBulk = (accountIds: AccountId[]): Promise<User[]> => {
	if (!accountIds.length) {
		return Promise.resolve([]);
	}

	// https://developer.atlassian.com/cloud/jira/platform/rest/v3/api-group-users/#api-rest-api-3-user-bulk-get
	return fetchJson(
		`/rest/api/3/user/bulk?maxResults=200&accountId=${accountIds.join('&accountId=')}`,
	).then(({ values }) => values);
};
