import type { State } from '../types.tsx';

export const isInitialized = (state: State): boolean => state.meta.initialized;
export const isDeliveryIssueCountRestricted = (state: State): boolean =>
	state.meta.restrictedDeliveryIssuesCount !== undefined;
export const isLoadingLinkedIssues = (state: State): boolean => state.meta.loadingLinkedIssues;
export const isIssuesLoading = (state: State): boolean => state.meta.loading;
export const isArchivedIssuesLoading = (state: State): boolean => state.meta.loadingArchivedIssues;
export const getError = (state: State): Error | undefined => state.meta.error;
export const getArchivedError = (state: State): Error | undefined => state.meta.archivedError;
