import type { OptionFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/option/types.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { IssuesRemote } from '@atlassian/jira-polaris-remote-issue/src/controllers/types.tsx';
import { jiraArchivedMapping } from '../../../../field/mapping/archived/index.tsx';
import { optionMapping } from '../option/index.tsx';
import type { FieldMapping } from '../types.tsx';

export const archivedMapping = (
	issuesRemote: IssuesRemote,
	field: Field,
): FieldMapping<OptionFieldValue> => {
	const optionsById = {
		[field.archivedOption?.jiraOptionId || '']: field.archivedOption,
	};

	return {
		...optionMapping(issuesRemote, field),
		...jiraArchivedMapping(issuesRemote, field),
		getLabel: (groupIdentity) => optionsById[groupIdentity]?.value,
	};
};
