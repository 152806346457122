import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	acceptPriority: {
		id: 'servicedesk-ai-context-common.actions.update-priority.action',
		defaultMessage: 'Accept',
		description: 'CTA text for the update priority button',
	},
	successTitle: {
		id: 'servicedesk-ai-context-common.actions.update-priority.success-title',
		defaultMessage: 'Priority updated',
		description: 'Success title telling the user that the priority has been updated',
	},
	successDescription: {
		id: 'servicedesk-ai-context-common.actions.update-priority.success-description',
		defaultMessage: "We've set the priority to {priority}.",
		description: 'Success description telling the user that the priority has been set',
	},
	errorTitle: {
		id: 'servicedesk-ai-context-common.actions.update-priority.error-title',
		defaultMessage: "We couldn't update priority",
		description: 'Error message informing the user that updating the priority field failed',
	},
	errorDescription: {
		id: 'servicedesk-ai-context-common.actions.update-priority.error-message',
		defaultMessage: 'Something went wrong. Try again or refresh the page.',
		description: 'Error message when save fails',
	},
});
