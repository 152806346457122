import gqlTagPolaris from 'graphql-tag';
import type {
	jira_polaris_GetProjectOnboardedQuery as GetProjectOnboardedQuery,
	jira_polaris_GetProjectOnboardedQueryVariables as GetProjectOnboardedQueryVariables,
} from './__generated_apollo__/jira_polaris_GetProjectOnboardedQuery';
import type { CheckProjectOnboarded } from './types.tsx';

const GET_PROJECT_ONBOARDED_QUERY = gqlTagPolaris`
query jira_polaris_GetProjectOnboardedQuery($ari: ID!) {
    polarisProject(id: $ari, skipRefresh: true) {
        id
        onboarded
        ideaTypes {
            id
        }
    }
}`;

export const checkProjectOnboarded: CheckProjectOnboarded = async (client, ari) => {
	const { data, errors } = await client.query<
		GetProjectOnboardedQuery,
		GetProjectOnboardedQueryVariables
	>({
		query: GET_PROJECT_ONBOARDED_QUERY,
		variables: {
			ari,
		},
		fetchPolicy: 'no-cache',
	});

	if (errors !== undefined) {
		throw new Error(
			`polaris-ideas.check-project-onboarding-error:${errors.map((e) => e.message).join(', ')}`,
		);
	}

	return {
		isOnboarded: !!data.polarisProject?.onboarded,
		ideaTypeAri: data.polarisProject?.ideaTypes?.[0].id || null,
	};
};
