import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	feedbackSubmittedFlagTitle: {
		id: 'atlassian-intelligence.services.feedback.feedback-submitted-flag-title',
		defaultMessage: 'Thank you for sharing your query',
		description:
			'Title for a success flag displayed to the user when their feedback has been submitted',
	},
	feedbackSubmittedFlagDescription: {
		id: 'atlassian-intelligence.services.feedback.feedback-submitted-flag-description',
		defaultMessage: 'Your valuable feedback helps us continually improve our products.',
		description:
			'Description for a success flag displayed to the user when their feedback has been submitted',
	},
});
