import React from 'react';
import type { CustomGlyphProps } from '@atlaskit/icon';

export const switchGlyph = (props: CustomGlyphProps) => (
	<svg
		{...props}
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M11.2927 0.293135C11.683 -0.097523 12.3162 -0.097739 12.7069 0.292652L15.7069 3.29061L15.7901 3.38478C15.8065 3.40593 15.822 3.42759 15.8365 3.44969C15.8396 3.45646 15.8435 3.46257 15.8473 3.46871C15.8623 3.49043 15.8755 3.51343 15.8876 3.5368C15.8922 3.54775 15.8978 3.55883 15.9031 3.57001C15.9148 3.59257 15.9245 3.61528 15.9333 3.63825C15.9374 3.65076 15.9421 3.66354 15.9465 3.67645C15.9549 3.69947 15.9619 3.72278 15.968 3.74626C15.9713 3.76084 15.9748 3.77552 15.978 3.79033C15.9828 3.81167 15.9867 3.83337 15.9898 3.85515C15.9964 3.90238 16 3.95078 16 4L15.9961 3.90937C15.9978 3.92874 15.999 3.94816 15.9995 3.96759L16 4C16 4.01122 15.9998 4.0224 15.9994 4.03354C15.9988 4.04932 15.9979 4.06498 15.9966 4.08062C15.9948 4.10513 15.992 4.12919 15.9884 4.15298C15.986 4.16598 15.9835 4.17966 15.9808 4.19329C15.9758 4.2213 15.9693 4.24832 15.9617 4.27489C15.9587 4.2833 15.956 4.29199 15.9533 4.30065C15.945 4.3292 15.9349 4.35701 15.9235 4.38421C15.9189 4.39319 15.9149 4.4025 15.9107 4.41175C15.8563 4.5338 15.7776 4.64241 15.6811 4.73218L12.7073 7.70687L12.6132 7.79009C12.221 8.09525 11.6537 8.06771 11.2931 7.70735L11.2099 7.61317C10.9048 7.22098 10.9323 6.65374 11.2927 6.29313L12.584 5H1L0.883379 4.99327C0.38604 4.93551 0 4.51284 0 4C0 3.44772 0.447715 3 1 3H12.586L11.2931 1.70735L11.2099 1.61317C10.9048 1.22098 10.9323 0.653741 11.2927 0.293135Z"
			fill="currentColor"
		/>
		<path
			d="M4.70735 8.29313C4.31696 7.90248 3.68379 7.90226 3.29313 8.29265L0.293135 11.2906L0.209914 11.3848C0.19346 11.4059 0.177972 11.4276 0.163452 11.4497C0.160442 11.4565 0.156515 11.4626 0.152653 11.4687C0.137665 11.4904 0.124527 11.5134 0.112375 11.5368C0.107781 11.5478 0.102243 11.5588 0.0969067 11.57C0.0852222 11.5926 0.0755053 11.6153 0.0666666 11.6382C0.0625782 11.6508 0.0579128 11.6635 0.053503 11.6764C0.0450611 11.6995 0.0381117 11.7228 0.0320282 11.7463C0.0286627 11.7608 0.0251751 11.7755 0.0220165 11.7903C0.0171585 11.8117 0.0133133 11.8334 0.0101881 11.8552C0.00355625 11.9024 0 11.9508 0 12L0.0039072 11.9094C0.0021944 11.9287 0.00104523 11.9482 0.000458717 11.9676L0 12C0 12.0112 0.000185013 12.0224 0.000552177 12.0335C0.00119019 12.0493 0.00211239 12.065 0.00340176 12.0806C0.00516224 12.1051 0.00797653 12.1292 0.0116282 12.153C0.014039 12.166 0.0164547 12.1797 0.0191565 12.1933C0.0241671 12.2213 0.0306768 12.2483 0.0382576 12.2749C0.0413313 12.2833 0.0439529 12.292 0.0466957 12.3007C0.0549803 12.3292 0.0651426 12.357 0.0764723 12.3842C0.0810766 12.3932 0.0851307 12.4025 0.0893335 12.4118C0.143727 12.5338 0.222425 12.6424 0.318884 12.7322L3.29265 15.7069L3.38683 15.7901C3.77902 16.0952 4.34626 16.0677 4.70687 15.7073L4.79009 15.6132C5.09525 15.221 5.06771 14.6537 4.70735 14.2931L3.416 13H15L15.1166 12.9933C15.614 12.9355 16 12.5128 16 12C16 11.4477 15.5523 11 15 11H3.414L4.70687 9.70735L4.79009 9.61317C5.09525 9.22098 5.06771 8.65374 4.70735 8.29313Z"
			fill="currentColor"
		/>
	</svg>
);
