import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';

export type User = {
	userId: string;
	name: string;
	avatar: string;
	email: string;
};

const getUserFullEndpoint = (accountId = ''): string =>
	`/rest/api/3/user?accountId=${encodeURIComponent(accountId)}`;

export const getUser = (accountId = ''): Promise<User> => {
	if (!accountId) {
		return Promise.resolve({
			userId: '',
			name: '',
			avatar: '',
			email: '',
		});
	}

	return fetchJson(getUserFullEndpoint(accountId)).then((user) => ({
		email: user.emailAddress,
		name: user.displayName,
		userId: user.accountId,
		avatar: user.avatarUrls['48x48'],
	}));
};

export const getUserName = async (userId: string) => {
	let userName;
	try {
		const user = await getUser(userId);
		userName = user.name || undefined;
	} catch (error) {
		userName = undefined;
	}

	return userName;
};
