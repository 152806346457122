import React, { useCallback, useState } from 'react';
import { IconButton } from '@atlaskit/button/new';
import ThumbsDownIcon from '@atlaskit/icon/core/thumbs-down';
import ThumbsUpIcon from '@atlaskit/icon/core/thumbs-up';
import Lozenge from '@atlaskit/lozenge';
import { Inline, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useFeedbackCollectorActions } from '@atlassian/jira-feedback-collector/src/controllers/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import {
	useProjectKey,
	useProjectType,
} from '@atlassian/jira-project-context-service/src/main.tsx';
import messages from './messages.tsx';

type FeedbackContextEntry = {
	id: string;
	value: string | number | boolean;
};

export type FeedbackDirection = 'up' | 'down';

type Props = {
	entrypointId: string;
	feedbackGroupId: string;
	feedbackContext?: FeedbackContextEntry[];
	onFeedbackClicked?: (aiFeedbackResult: FeedbackDirection) => void;
	onFeedbackSent?: (aiFeedbackResult: FeedbackDirection) => void;
};

export const Feedback = ({
	entrypointId,
	feedbackGroupId,
	feedbackContext,
	onFeedbackClicked,
	onFeedbackSent,
}: Props) => {
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const projectType = useProjectType(projectKey);
	const [feedbackClicked, setFeedbackClicked] = useState<FeedbackDirection | null>(null);
	const [wasFeedbackSent, setWasFeedbackSent] = useState(false);
	const { openFeedbackCollector } = useFeedbackCollectorActions();
	const { formatMessage } = useIntl();

	const handleFeedbackSent = useCallback((wasSent: boolean) => {
		setWasFeedbackSent(wasSent);
	}, []);

	const getFeedbackContext = useCallback(
		(direction: FeedbackDirection) => ({
			entrypointId,
			feedbackGroupId,
			feedbackContext: [
				{ id: 'sentiment', value: direction === 'up' ? 'good' : 'bad' },
				{ id: 'product', value: projectType ?? '' },
				...(feedbackContext ?? []),
			],
			onSubmit() {
				handleFeedbackSent(true);
				onFeedbackSent?.(direction);
			},
			onClose() {
				handleFeedbackSent(false);
			},
		}),
		[
			entrypointId,
			feedbackGroupId,
			projectType,
			feedbackContext,
			handleFeedbackSent,
			onFeedbackSent,
		],
	);

	const handleOnClick = useCallback(
		(direction: FeedbackDirection) => {
			setFeedbackClicked(direction);
			openFeedbackCollector(getFeedbackContext(direction));
			onFeedbackClicked?.(direction);
		},
		[getFeedbackContext, onFeedbackClicked, openFeedbackCollector],
	);

	const handleThumbsUp = useCallback(() => {
		handleOnClick('up');
	}, [handleOnClick]);

	const handleThumbsDown = useCallback(() => {
		handleOnClick('down');
	}, [handleOnClick]);

	return (
		<Inline alignBlock="center" space="space.050" xcss={feedbackCss}>
			<Inline space="space.050">
				<IconButton
					spacing="compact"
					appearance="subtle"
					label={formatMessage(messages.likeButtonLabel)}
					onClick={handleThumbsUp}
					icon={(iconProps) => (
						<ThumbsUpIcon
							{...iconProps}
							color={feedbackClicked === 'up' ? token('color.link.pressed') : 'currentColor'}
						/>
					)}
					isDisabled={!!feedbackClicked}
				/>
				<IconButton
					appearance="subtle"
					spacing="compact"
					label={formatMessage(messages.dislikeButtonLabel)}
					onClick={handleThumbsDown}
					icon={(iconProps) => (
						<ThumbsDownIcon
							{...iconProps}
							color={feedbackClicked === 'down' ? token('color.link.pressed') : 'currentColor'}
						/>
					)}
					isDisabled={!!feedbackClicked}
				/>
			</Inline>
			{wasFeedbackSent && (
				<Lozenge
					appearance="success"
					testId="servicedesk-ai-context-common.ui.ai-context-container.feedback.lozenge"
				>
					{formatMessage(messages.feedbackSent)}
				</Lozenge>
			)}
		</Inline>
	);
};

const feedbackCss = xcss({
	marginBottom: 'space.100',
});
