import React, { memo } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { ff } from '@atlassian/jira-feature-flagging';
import { INLINE } from '@atlassian/jira-issue-field-summary/src/common/constants.tsx';
import SummaryField from '@atlassian/jira-issue-field-summary/src/ui/main.tsx';
import type { Props } from './types.tsx';

export const Summary = memo<Props>(
	({ issueId, issueKey, onClick, issueLink, onUpdate, showHoverPreviewEnabled }: Props) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const handleClick = (e: any) => {
			// We're trusting the summary component to handle all of its own click events.
			// So preventing the line card's navigation-on-click behaviour from mucking around with the inline edit behaviour.
			e.stopPropagation();
		};

		return (
			<PaddedSummaryFlexContainer
				onClick={handleClick}
				// eslint-disable-next-line jira/integration/test-id-by-folder-structure
				data-testid="issue.issue-view.views.common.issue-line-card.issue-line-card-view.summary"
			>
				<SummaryField
					issueId={
						ff('relay-migration-issue-fields-summary_eog1v') ? issueId.toString() : undefined
					}
					issueKey={issueKey}
					onClick={onClick}
					appearance={INLINE}
					url={issueLink}
					onUpdate={onUpdate}
					showHoverPreviewEnabled={showHoverPreviewEnabled}
				/>
			</PaddedSummaryFlexContainer>
		);
	},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const PaddedSummaryFlexContainer = styled.div({
	flex: 1,
	marginLeft: token('space.100', '8px'),
	minWidth: 0,
	marginRight: token('space.200', '16px'),
});
