import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	shortcutThen: {
		id: 'command-palette.common.components.command-row.right-content.shortcuts.shortcut-then',
		defaultMessage: '{shortcutA} <divisor>then</divisor> {shortcutB}',
		description: 'Shortcut label for when two shortcuts are pressed in sequence (e.g G then I)',
	},
	shortcutOr: {
		id: 'command-palette.common.components.command-row.right-content.shortcuts.shortcut-or',
		defaultMessage: '{shortcutA} <divisor>or</divisor> {shortcutB}',
		description: 'Shortcut label when two shortcuts are pressed in parallel (e.g G or I)',
	},
	shortcutPlus: {
		id: 'command-palette.common.components.command-row.right-content.shortcuts.shortcut-plus',
		defaultMessage: '{shortcutA} <divisor>+</divisor> {shortcutB}',
		description: 'Shortcut label when two shortcuts are pressed together (e.g G + I)',
	},
	divisorThen: {
		id: 'command-palette.common.components.command-row.right-content.shortcuts.divisor-then',
		defaultMessage: 'then',
		description:
			'When a shortcut sequence is custom and has a "then" divisor (e.g G then I then F, (G+I) then F etc)',
	},
	divisorOr: {
		id: 'command-palette.common.components.command-row.right-content.shortcuts.divisor-or',
		defaultMessage: 'or',
		description:
			'When a shortcut sequence is custom and has a "or" divisor (e.g G or I or F, (G+I) or F etc)',
	},
	divisorPlus: {
		id: 'command-palette.common.components.command-row.right-content.shortcuts.divisor-plus',
		defaultMessage: '+',
		description:
			'When a shortcut sequence is custom and has a "+" divisor (e.g G + I + F, (G+I) or F etc)',
	},
});
