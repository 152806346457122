import type { State as ReduxState } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import {
	type Connect,
	connect as connectWrapper,
	Provider8Wrapped,
} from '@atlassian/jira-react-redux/src/index.tsx';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const connect: Connect<ReduxState> = ((
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		mapStateToProps?: any,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		mapDispatchToProps?: any,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		mergeProps?: any,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		options: any = Object.freeze({}),
	) =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(component: any) =>
		connectWrapper(mapStateToProps, mapDispatchToProps, mergeProps, {
			...options,
			forceV8: true, // issue-view is already on react-redux v8
		})(component)) as any; // eslint-disable-line @typescript-eslint/no-explicit-any

export const Provider = Provider8Wrapped;
