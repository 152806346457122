import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	issueActions: {
		id: 'issue.issue-view.issue-details.register-command-palette-header.issue-actions',
		defaultMessage: 'Issue actions',
		description: 'Section name in the command palette to group all issue actions',
	},
	issueActionsIssueTermRefresh: {
		id: 'issue.issue-view.issue-details.register-command-palette-header.issue-actions-issue-term-refresh',
		defaultMessage: 'Work item actions',
		description: 'Section name in the command palette to group all issue actions',
	},
});
