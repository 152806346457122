import type { JiraAppLinksState } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { toBaseUrl, toHref } from '@atlassian/jira-shared-types/src/general.tsx';
import {
	APPLINK_AUTHENTICATION_SUCCESS,
	type ApplinkAuthenticationSuccess,
} from '../../actions/applink-authentication-actions.tsx';
import {
	FETCH_JIRA_APP_LINKS_FAILED,
	FETCH_JIRA_APP_LINKS_REQUEST,
	FETCH_JIRA_APP_LINKS_SUCCESS,
	type FetchJiraAppLinksFailed,
	type FetchJiraAppLinksRequest,
	type FetchJiraAppLinksSuccess,
} from '../../actions/fetch-jira-app-links-actions.tsx';

type State = JiraAppLinksState;

export const initialState: State = {
	status: 'NOT_LOADED',
	remoteLinks: [],
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	state: State = initialState,
	action:
		| FetchJiraAppLinksSuccess
		| FetchJiraAppLinksFailed
		| FetchJiraAppLinksRequest
		| ApplinkAuthenticationSuccess,
): JiraAppLinksState => {
	switch (action.type) {
		case FETCH_JIRA_APP_LINKS_SUCCESS: {
			// @ts-expect-error - TS2339 - Property 'payload' does not exist on type '{ type: string; } | { type: string; payload: JiraAppLinksResponse; } | { type: string; }'. | TS7006 - Parameter 'appLink' implicitly has an 'any' type.
			const appLinks = action.payload.map((appLink) => ({
				id: appLink.id,
				name: appLink.name,
				baseUrl: toBaseUrl(appLink.url),
				authUrl: toHref(appLink.authUrl),
				requireCredentials: appLink.requireCredentials,
			}));
			return {
				status: 'LOADED',
				remoteLinks: appLinks,
			};
		}
		case FETCH_JIRA_APP_LINKS_FAILED: {
			return {
				status: 'ERROR',
				remoteLinks: state.remoteLinks,
			};
		}
		case FETCH_JIRA_APP_LINKS_REQUEST: {
			return {
				status: 'LOADING',
				remoteLinks: state.remoteLinks,
			};
		}
		case APPLINK_AUTHENTICATION_SUCCESS: {
			return {
				status: 'NOT_LOADED',
				remoteLinks: state.remoteLinks,
			};
		}
		default:
			return state;
	}
};
