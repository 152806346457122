/**
 * @generated SignedSource<<31dfb116d0d7e78dfb4daf3dbf0a89ef>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutStoryPointEstimateField_IssueViewStoryPointEstimateField$data = {
  readonly __typename: "JiraNumberField";
  readonly fieldId: string;
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"storyPointEstimate_issueFieldStoryPointEstimateInlineEditFull_StoryPointEstimateInlineEditView_fragmentRef" | "ui_issueViewLayoutFieldHeading_IssueViewFieldHeading">;
  readonly " $fragmentType": "ui_issueViewLayoutStoryPointEstimateField_IssueViewStoryPointEstimateField";
};
export type ui_issueViewLayoutStoryPointEstimateField_IssueViewStoryPointEstimateField$key = {
  readonly " $data"?: ui_issueViewLayoutStoryPointEstimateField_IssueViewStoryPointEstimateField$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutStoryPointEstimateField_IssueViewStoryPointEstimateField">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutStoryPointEstimateField_IssueViewStoryPointEstimateField",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "ui_issueViewLayoutFieldHeading_IssueViewFieldHeading"
    },
    {
      "kind": "FragmentSpread",
      "name": "storyPointEstimate_issueFieldStoryPointEstimateInlineEditFull_StoryPointEstimateInlineEditView_fragmentRef"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "ScalarField",
      "name": "__typename"
    }
  ],
  "type": "JiraNumberField"
};

(node as any).hash = "1d6036ac3d2e55ffd65353d7c2357b0d";

export default node;
