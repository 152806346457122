import React from 'react';
import Icon from '@atlaskit/icon';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

const CustomGlyph = () => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_486_1511)">
			<path
				d="M15.2654 7.508L11.3102 6.044L9.83335 1.20356C9.77646 1.01689 9.60358 0.888889 9.40846 0.888889C9.21335 0.888889 9.04046 1.01689 8.98357 1.20356L7.50713 6.044L3.55157 7.508C3.37691 7.57289 3.26135 7.73867 3.26135 7.92489C3.26135 8.11067 3.37691 8.27733 3.55157 8.34178L7.50491 9.80533L8.98224 14.7933C9.03824 14.9818 9.21158 15.1111 9.40846 15.1111C9.60491 15.1111 9.77869 14.9818 9.83469 14.7929L11.312 9.80489L15.2658 8.34133C15.4396 8.27733 15.5556 8.11111 15.5556 7.92489C15.5556 7.73911 15.4396 7.57289 15.2654 7.508Z"
				fill={token('color.icon.accent.purple', colors.P400)}
			/>
			<path
				d="M6.37646 12.3973L5.34801 12.0169L4.94801 10.5502C4.89557 10.3564 4.72001 10.2222 4.51957 10.2222C4.31912 10.2222 4.14357 10.3564 4.09068 10.5498L3.69068 12.0164L2.66268 12.3969C2.48801 12.4613 2.37246 12.628 2.37246 12.8138C2.37246 12.9996 2.48801 13.1662 2.66268 13.2307L3.68535 13.6098L4.08846 15.2196C4.13824 15.4169 4.31557 15.5556 4.51957 15.5556C4.72357 15.5556 4.9009 15.4169 4.95068 15.2191L5.35379 13.6093L6.37646 13.2302C6.55112 13.1662 6.66668 12.9996 6.66668 12.8138C6.66668 12.628 6.55112 12.4613 6.37646 12.3973ZM4.44846 2.76933L3.39779 2.38044L3.00846 1.32933C2.94401 1.15466 2.77779 1.03911 2.59157 1.03911C2.40579 1.03911 2.23957 1.15466 2.17468 1.32933L1.78579 2.38044L0.73468 2.76933C0.560014 2.83422 0.444458 3.00044 0.444458 3.18622C0.444458 3.372 0.560014 3.53866 0.73468 3.60311L1.78579 3.992L2.17468 5.04311C2.23957 5.21777 2.40579 5.33333 2.59157 5.33333C2.77735 5.33333 2.94357 5.21777 3.00846 5.04311L3.39735 3.992L4.44846 3.60311C4.62312 3.53822 4.73868 3.372 4.73868 3.18622C4.73868 3.00044 4.62312 2.83422 4.44846 2.76933Z"
				fill={token('color.icon.accent.purple', colors.P400)}
			/>
		</g>
		<defs>
			<clipPath id="clip0_486_1511">
				<rect width="16" height="16" fill={token('color.icon', colors.N100)} />
			</clipPath>
		</defs>
	</svg>
);

const SparklesIcon = () => <Icon glyph={CustomGlyph} label="" />;

export default SparklesIcon;
