import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	changed: {
		id: 'issue-history.history-items.history-item.transition-history-item.changed',
		defaultMessage: 'changed the {status}',
		description: `Describes that a user has changed the status of the issue.
            Part of the heading in History feed, appears after the display name of the user who made the change.
            {status} is the 'Status' field label.`,
	},
	status: {
		id: 'issue-history.history-items.history-item.transition-history-item.status',
		defaultMessage: 'Status',
		description: "(noun) Value to display as label for the 'Status' field.",
	},
	statusLowercase: {
		id: 'issue-history.history-items.history-item.transition-history-item.status-lowercase',
		defaultMessage: 'status',
		description: "(noun) Value to display as label for the 'status' field.",
	},
});
