import type { AnalyticsEvent } from '@atlassian/jira-common-analytics-v2-wrapped-components/src/types.tsx';

export const LINKED_ISSUE_PROJECT_ANALYTICS_SUCCESS =
	'LINKED_ISSUE_PROJECT_ANALYTICS_SUCCESS' as const;

export const linkedIssueProjectAnalyticsSuccess = (analyticsEvent: AnalyticsEvent) => ({
	type: LINKED_ISSUE_PROJECT_ANALYTICS_SUCCESS,
	payload: { analyticsEvent },
});

export type LinkedIssueProjectAnalyticsSuccess = ReturnType<
	typeof linkedIssueProjectAnalyticsSuccess
>;

export const LINKED_ISSUE_PROJECT_ANALYTICS_FAILURE = 'LINKED_ISSUE_FAILURE' as const;

export const linkedIssueProjectAnalyticsFailure = (error: string) => ({
	type: LINKED_ISSUE_PROJECT_ANALYTICS_FAILURE,
	payload: { error },
});

export type LinkedIssueProjectAnalyticsFailure = ReturnType<
	typeof linkedIssueProjectAnalyticsFailure
>;

export type IssueLinksAction =
	| LinkedIssueProjectAnalyticsSuccess
	| LinkedIssueProjectAnalyticsFailure;
