// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	draftIndicator: {
		id: 'issue.draft-indicator-for-multiline-field',
		defaultMessage: 'Unsaved changes',
		description: "Label that indicates there's a draft for the field",
	},
});
