import type { SelectValueShape } from '@atlassian/jira-issue-internal-field-select/src/common/select-inline-edit/select-field/types.tsx';

export type StateValue = {
	id: string | number;
	value: string;
	iconUrl?: string | null;
	self?: string;
};

type SingleSelectSuggestions = {
	items: SelectValueShape[];
};

type SingleSelectSuggestionsArray = SingleSelectSuggestions[];

const toItem = (fieldData: StateValue): SelectValueShape => ({
	value: fieldData.id,
	content: fieldData.value,
	iconUrl: fieldData.iconUrl,
});

export const transformFromStateValue = (stateValue: StateValue | null): SelectValueShape | null =>
	stateValue && toItem(stateValue);

export const transformToStateValue = (fieldValue: SelectValueShape | null): StateValue | null =>
	fieldValue && {
		id: fieldValue.value,
		value: fieldValue.content,
		iconUrl: fieldValue.iconUrl,
	};

export const transformSuggestions = (
	suggestions: StateValue[] | null,
): SingleSelectSuggestionsArray => (suggestions && [{ items: suggestions.map(toItem) }]) || [];
