/** @jsx jsx */
import React from 'react';
import { styled, jsx, css } from '@compiled/react';
import EditorPanelIcon from '@atlaskit/icon/glyph/editor/panel';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import type { TransitionProps } from './types.tsx';
import { TransitionWithLabel } from './with-label/index.tsx';
import { TransitionWithoutLabel } from './without-label/index.tsx';

export const Transition = ({
	hasLabel,
	transition,
	addTransitionArrowSpacing,
	contentAfter,
	testId,
}: TransitionProps) => {
	if (transition.isDisabled === true) {
		return (
			<DisabledTransition data-testid={testId}>
				<TransitionWithoutLabel transition={transition} isDisabled />
				{transition.unavailableReasonText != null && transition.unavailableReasonText !== '' && (
					<Tooltip content={transition.unavailableReasonText}>
						<IconWrapper>
							<EditorPanelIcon label="" primaryColor={token('color.text.disabled', colors.N200)} />
						</IconWrapper>
					</Tooltip>
				)}
			</DisabledTransition>
		);
	}

	const transitionComponent = hasLabel ? (
		<TransitionWithLabel
			transition={transition}
			addTransitionArrowSpacing={addTransitionArrowSpacing}
		/>
	) : (
		<TransitionWithoutLabel transition={transition} />
	);

	return contentAfter ? (
		<div css={transitionWrapperStyles} data-testid={testId}>
			{transitionComponent}
			{contentAfter}
		</div>
	) : (
		<div data-testid={testId}>{transitionComponent}</div>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DisabledTransition = styled.div({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconWrapper = styled.div({
	height: '15px',
	overflow: 'visible',
	marginTop: token('space.negative.050', '-4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const visibleForTesting = {
	IconWrapper,
};

const transitionWrapperStyles = css({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	// workaround to show contentAfter that only appears when the transition option is hovered
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	'&:hover [data-show-on-hover="true"]': {
		opacity: 1,
	},
});
