import type { PreloadFetchPolicy } from 'react-relay';
import mainGoalsPopupContentQueryParameters from '@atlassian/jira-relay/src/__generated__/mainGoalsPopupContentQuery.graphql';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const goalPickerPopoverEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-jwm-goals-picker" */ './index'),
	),
	getPreloadProps: ({
		cloudId,
		issueAri,
		jiraGoalsFieldUnshardedWorkspaceSummariesEnabled,
	}: {
		cloudId: string;
		issueAri: string;
		jiraGoalsFieldUnshardedWorkspaceSummariesEnabled: boolean;
	}) => ({
		queries: {
			mainGoalsPopupContentQuery: {
				parameters: mainGoalsPopupContentQueryParameters,
				variables: {
					cloudId,
					issueAri,
					jiraGoalsFieldUnshardedWorkspaceSummariesEnabled,
				},
				options: {
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					fetchPolicy: 'store-or-network' as PreloadFetchPolicy,
				},
			},
		},
	}),
});
