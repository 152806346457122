import React, { useCallback } from 'react';
import intersection from 'lodash/intersection';
import { useAnalyticsEvents, type UIAnalyticsEvent } from '@atlaskit/analytics-next';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import useAssigneeField from '@atlassian/jira-issue-field-assignee/src/services/use-assignee-field/index.tsx';
import { useIssueLayoutContextPanel } from '@atlassian/jira-issue-view-layout/src/services/main.tsx';
import {
	fireOperationalAnalytics,
	fireTrackAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { RespondersGlancePanel } from '@atlassian/jira-responders/src/async.tsx';
import type { Responder } from '@atlassian/jira-responders/src/common/types/index.tsx';
import { useRespondersField } from '@atlassian/jira-responders/src/services/use-responders/index.tsx';
import { IncidentConfigurationProvider } from '@atlassian/jira-servicedesk-incident-management-services/src/services/configuration/main.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';

type Props = {
	contentWidth: number;
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (props: Props) => {
	const cloudId = useCloudId();

	const issueKey = useIssueKey();
	const [contextPanel] = useIssueLayoutContextPanel(issueKey);
	const [{ value: currentAssignee }] = useAssigneeField({ issueKey });

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onSuccess = useCallback(() => {
		fireOperationalAnalytics(createAnalyticsEvent({}), 'respondersField updateSucceeded', {});
	}, [createAnalyticsEvent]);

	const onFailure = useCallback(
		// @ts-expect-error - TS7006 - Parameter 'error' implicitly has an 'any' type.
		(error) => {
			fireErrorAnalytics({
				event: createAnalyticsEvent({}),
				meta: {
					id: 'respondersFieldUpdate',

					packageName: 'jiraIssueView',
				},
				error,
				sendToPrivacyUnsafeSplunk: true,
			});
		},
		[createAnalyticsEvent],
	);

	const onSubmit = useCallback(
		(
			oldValue: Responder[] | null,
			newValue: Responder[] | null,
			meta: null,
			event: UIAnalyticsEvent,
		) => {
			const sanitisedOldValue = oldValue || [];
			const sanitisedNewValue = newValue || [];

			const numberOfOriginalTeamResponder = sanitisedOldValue.filter(
				(it) => it.type === 'team',
			).length;
			const numberOfNewValueTeamResponder = sanitisedNewValue.filter(
				(it) => it.type === 'team',
			).length;
			const numberOfOriginalUserResponder = sanitisedOldValue.filter(
				(it) => it.type === 'user',
			).length;
			const numberOfNewValueUserResponder = sanitisedNewValue.filter(
				(it) => it.type === 'user',
			).length;
			const numberOfTotalOriginalResponders = sanitisedOldValue.length;

			const isRemoveOperation = intersection(sanitisedOldValue, sanitisedNewValue).length > 0;

			if (isRemoveOperation) {
				fireTrackAnalytics(event, 'respondersField updateStarted', {
					operationType: 'remove',
					numberOfTotalOriginalResponders,
					numberOfOriginalTeamResponder,
					numberOfOriginalUserResponder,
					numberOfTotalRemovedResponders: sanitisedNewValue.length,
					numberOfRemovedTeamResponder: numberOfNewValueTeamResponder,
					numberOfRemovedUserResponder: numberOfNewValueUserResponder,
				});
			} else {
				fireTrackAnalytics(event, 'respondersField updateStarted', {
					operationType: 'add',
					numberOfTotalOriginalResponders,
					numberOfOriginalTeamResponder,
					numberOfOriginalUserResponder,
					numberOfTotalAddedResponders: sanitisedNewValue.length,
					numberOfAddedTeamResponder: numberOfNewValueTeamResponder,
					numberOfAddeddUserResponder: numberOfNewValueUserResponder,
				});
			}
		},
		[],
	);

	const [{ value: responders, error }, { addResponders, removeResponders, resetError }] =
		useRespondersField({
			issueKey,
			onSuccess,
			onFailure,
			onSubmit,
		});

	return (
		<ErrorBoundary>
			<IncidentConfigurationProvider cloudId={cloudId} isGlobal>
				<RespondersGlancePanel
					{...props}
					responders={responders}
					isPanelOpen={contextPanel !== undefined}
					currentAssignee={currentAssignee}
					onAddResponders={addResponders}
					onRemoveResponders={removeResponders}
					respondersFieldError={{ error, resetError }}
				/>
			</IncidentConfigurationProvider>
		</ErrorBoundary>
	);
};
