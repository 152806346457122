import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noMatches: {
		id: 'issue-field-project.edit.async-project-cf.no-matches',
		defaultMessage: 'No matches',
		description: 'Message when no options are found in a select',
	},
	failedFetch: {
		id: 'issue-field-project.edit.async-project-cf.failed-fetch',
		defaultMessage: 'Search failed. Please try again.',
		description: 'Message when fetching project suggestions fails',
	},
	placeholder: {
		id: 'issue-field-project.edit.async-project-cf.placeholder',
		defaultMessage: 'Select project',
		description: 'Placeholder text for Project Custom select component',
	},
	loading: {
		id: 'issue-field-project.edit.async-project-cf.loading',
		defaultMessage: 'Searching...',
		description: 'Message showed when Projects options are being loaded from the server.',
	},
});
