import { FIELD_TEMPLATE, type FieldFormula } from '../field/types.tsx';
import type {
	DynamicFieldFormula,
	FieldVisitor,
	FormulaVisitor,
	NormalizationFormula,
} from '../types.tsx';
import { NORMALIZATION_TEMPLATE } from './types.tsx';

export const normalize = (formula: DynamicFieldFormula): FieldFormula | NormalizationFormula =>
	formula.template === FIELD_TEMPLATE
		? {
				template: FIELD_TEMPLATE,
				parameters: { id: formula.parameters.id, normalize: true },
			}
		: {
				template: NORMALIZATION_TEMPLATE,
				parameters: {
					formula,
				},
			};

export const isNormalization = (formula: DynamicFieldFormula): boolean =>
	formula.template === NORMALIZATION_TEMPLATE;

export const normalizationVisitFields = (
	formula: DynamicFieldFormula,
	visitField: FieldVisitor,
	visitFormula: FormulaVisitor,
): boolean => {
	if (formula.template !== NORMALIZATION_TEMPLATE) {
		return false;
	}
	visitFormula(formula.parameters.formula);
	return true;
};
