//
// ---- Field Value Operand
//

import { AstNode, Position } from './common';

export const NODE_TYPE_FIELD_OPERAND: 'field_operand' = 'field_operand';

export const OPERAND_TYPE_FIELD_VALUE = 'field_value';

/**
 * An operand that is a user-provided value.
 */
export interface FieldValueOperand extends AstNode {
  type: typeof NODE_TYPE_FIELD_OPERAND;
  operandType: typeof OPERAND_TYPE_FIELD_VALUE;
  /**
   * The operand value.
   */
  value: string;
  /**
   * Tuple representing the character position of the operand.
   */
  position: Position;
}

//
// ---- Keyword Operand
//
export const KEYWORD_OPERAND_EMPTY = 'empty';
export type KeywordOperandValue = typeof KEYWORD_OPERAND_EMPTY;

export const OPERAND_TYPE_KEYWORD = 'keyword';

/**
 * An operand that is a JQL keyword.
 * See <a href="https://confluence.atlassian.com/jiracorecloud/advanced-searching-keywords-reference-765593717.html#Advancedsearching-keywordsreference-EMPTYEMPTY">Advanced searching - keywords reference</a>
 * for more information about operand keywords.
 */
export interface KeywordOperand extends AstNode {
  type: typeof NODE_TYPE_FIELD_OPERAND;
  operandType: typeof OPERAND_TYPE_KEYWORD;
  /**
   * The keyword that is the operand value.
   */
  value: KeywordOperandValue;
  /**
   * Tuple representing the character position of the operand.
   */
  position: Position;
}

//
// ---- Function Operand
//
/**
 * A function name used in an operand.
 */
export interface FunctionString extends AstNode {
  /**
   * The name of the function
   */
  value: string;
  /**
   * Tuple representing the character position of the function.
   */
  position: Position;
}

/**
 * An argument value used in a function,
 */
export interface FunctionArg extends AstNode {
  /**
   * The value of the argument.
   */
  value: string;
  /**
   * Tuple representing the character position of the argument.
   */
  position: Position;
}

export const OPERAND_TYPE_FUNCTION = 'function';

/**
 * An operand that is a function.
 * See <a href="https://confluence.atlassian.com/x/dwiiLQ">Advanced searching - functions reference</a> for more
 * information about JQL functions.
 */
export interface FunctionOperand extends AstNode {
  type: typeof NODE_TYPE_FIELD_OPERAND;
  operandType: typeof OPERAND_TYPE_FUNCTION;
  /**
   * The name of the function.
   */
  function: FunctionString;
  /**
   * The list of function arguments.
   */
  arguments: FunctionArg[];
  /**
   * Tuple representing the character position of the operand.
   */
  position: Position;
}

/**
 * An operand that can be part of a list operand.
 */
export type UnitaryOperand =
  | FieldValueOperand
  | KeywordOperand
  | FunctionOperand;

export const OPERAND_TYPE_LIST = 'list';

/**
 * An operand that is a list of values.
 */
export interface ListOperand extends AstNode {
  type: typeof NODE_TYPE_FIELD_OPERAND;
  operandType: typeof OPERAND_TYPE_LIST;
  /**
   * The list of operand values.
   */
  values: UnitaryOperand[];
  /**
   * Tuple representing the character position of the operand.
   */
  position: Position;
}

//
// ---- Operand Union
//
/**
 * Represents the right hand side value of a clause.
 */
export type FieldOperand =
  | ListOperand
  | FieldValueOperand
  | KeywordOperand
  | FunctionOperand;
