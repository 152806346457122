import parameters from '@atlassian/jira-relay/src/__generated__/assigneeEditView_rootQuery$parameters';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

const AssigneeEditViewEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-assignee-edit-view" */ './ui/assignee/index'),
	),
	getPreloadProps: () =>
		({
			queries: {
				rootData: {
					options: {
						fetchPolicy: 'store-or-network',
					},
					parameters,
					variables: {},
				},
			},
		}) as const,
});

export default AssigneeEditViewEntryPoint;
