import { expValEquals } from '@atlassian/jira-feature-experiments';
import { ff } from '@atlassian/jira-feature-flagging';
import type { JiraSoftwareNavData } from '@atlassian/jira-navigation-apps-resources/src/services/sidebar/project-sidebar-navigation/types.tsx';
import type { Features } from './types.tsx';

export const extractData = (data: JiraSoftwareNavData): Features => {
	const {
		aiInfo,
		hasBacklog,
		hasBoard,
		hasCalendar,
		hasCode,
		hasComponents,
		hasDeployments,
		hasForms,
		hasGoals,
		hasIncidents,
		hasIssues,
		hasList,
		hasOpsgenieSchedule,
		hasPages,
		hasReleases,
		hasReports,
		hasRequests,
		hasRoadmap,
		hasSecurity,
		hasSummaryPage,
		permissionInfo,
	} = data;
	const isProjectOrSiteAdmin =
		permissionInfo.isAdmin || permissionInfo.projectPermissionsQueried.ADMINISTER_PROJECTS === true;
	const hasEditWorkflowPermissions =
		permissionInfo.projectPermissionsQueried.EDIT_WORKFLOW === true;
	const hasEditIssueLayoutPermissions =
		permissionInfo.projectPermissionsQueried.EDIT_ISSUE_LAYOUT === true;

	return {
		hasAIInNIN: hasIssues && aiInfo?.isEnabledInIssueNavigator === true,
		hasBacklog,
		hasBoard,
		hasCalendar,
		hasCode,
		hasComponents,
		hasDeployments,
		hasForms: expValEquals('forms_for_jsw_experiment', 'isEnabled', true) && hasForms,
		hasGoals,
		hasIncidents,
		hasIssues,
		hasList: expValEquals('jsw_list_view', 'cohort', 'variation') ? hasList : false,
		hasOpsgenieSchedule,
		hasPages,
		hasReleases,
		hasReports,
		hasRequests,
		hasRoadmap,
		hasSecurity,
		hasSettings: ff('olympus-da-157-enable-project-settings-navigation_zxaq5')
			? isProjectOrSiteAdmin || hasEditWorkflowPermissions || hasEditIssueLayoutPermissions
			: isProjectOrSiteAdmin,
		hasShortcutsAdd: isProjectOrSiteAdmin,
		hasSummaryPage,
	};
};
