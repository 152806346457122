/**
 * @generated SignedSource<<d9a4f40b8cf9ddde23c9f4b9cef23159>>
 * @relayHash 0f3fdcb25dffe19635701945cd438883
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID b4ff6a9e3f370e691d8f2d6798098bfa3f2cd9a93e2bceff5e7c72b0bf0f91f3

import type { ConcreteRequest, Query } from 'relay-runtime';
export type ui_JPDIdeaViewBentoSidebarQuery$variables = {
  cloudId: string;
  issueKey: string;
};
export type ui_JPDIdeaViewBentoSidebarQuery$data = {
  readonly jira: {
    readonly issueByKey: {
      readonly __typename: "JiraIssue";
      readonly descriptionField: {
        readonly richText: {
          readonly adfValue: {
            readonly json: AGG$JSON | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | null | undefined;
      readonly fieldsById: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly __typename: "JiraProjectField";
            readonly fieldId: string;
            readonly project?: {
              readonly avatar: {
                readonly xsmall: string | null | undefined;
              } | null | undefined;
              readonly key: string;
              readonly name: string;
              readonly projectId: string | null | undefined;
              readonly webUrl: AGG$URL | null | undefined;
            } | null | undefined;
          } | null | undefined;
        } | null | undefined> | null | undefined;
      } | null | undefined;
      readonly issueId: string;
      readonly issueTypeField: {
        readonly issueType: {
          readonly avatar: {
            readonly xsmall: string | null | undefined;
          } | null | undefined;
          readonly name: string;
        } | null | undefined;
      } | null | undefined;
      readonly key: string;
      readonly summaryField: {
        readonly text: string | null | undefined;
      } | null | undefined;
      readonly webUrl: AGG$URL | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type ui_JPDIdeaViewBentoSidebarQuery = {
  response: ui_JPDIdeaViewBentoSidebarQuery$data;
  variables: ui_JPDIdeaViewBentoSidebarQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "issueKey"
},
v2 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "kind": "Variable",
    "name": "key",
    "variableName": "issueKey"
  }
],
v3 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v4 = {
  "kind": "ScalarField",
  "name": "key"
},
v5 = {
  "kind": "ScalarField",
  "name": "issueId"
},
v6 = {
  "kind": "ScalarField",
  "name": "webUrl"
},
v7 = {
  "kind": "ScalarField",
  "name": "text"
},
v8 = {
  "concreteType": "JiraRichText",
  "kind": "LinkedField",
  "name": "richText",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraADF",
      "kind": "LinkedField",
      "name": "adfValue",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "json"
        }
      ]
    }
  ]
},
v9 = {
  "kind": "ScalarField",
  "name": "name"
},
v10 = {
  "concreteType": "JiraAvatar",
  "kind": "LinkedField",
  "name": "avatar",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "xsmall"
    }
  ]
},
v11 = [
  {
    "kind": "Literal",
    "name": "ids",
    "value": [
      "project"
    ]
  }
],
v12 = {
  "kind": "ScalarField",
  "name": "fieldId"
},
v13 = {
  "kind": "ScalarField",
  "name": "projectId"
},
v14 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "name": "ui_JPDIdeaViewBentoSidebarQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v2/*: any*/),
            "concreteType": "JiraIssue",
            "kind": "LinkedField",
            "name": "issueByKey",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "concreteType": "JiraSingleLineTextField",
                "kind": "LinkedField",
                "name": "summaryField",
                "plural": false,
                "selections": [
                  (v7/*: any*/)
                ]
              },
              {
                "concreteType": "JiraRichTextField",
                "kind": "LinkedField",
                "name": "descriptionField",
                "plural": false,
                "selections": [
                  (v8/*: any*/)
                ]
              },
              {
                "concreteType": "JiraIssueTypeField",
                "kind": "LinkedField",
                "name": "issueTypeField",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraIssueType",
                    "kind": "LinkedField",
                    "name": "issueType",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      (v10/*: any*/)
                    ]
                  }
                ]
              },
              {
                "args": (v11/*: any*/),
                "concreteType": "JiraIssueFieldConnection",
                "kind": "LinkedField",
                "name": "fieldsById",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraIssueFieldEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v12/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v3/*: any*/),
                              {
                                "concreteType": "JiraProject",
                                "kind": "LinkedField",
                                "name": "project",
                                "plural": false,
                                "selections": [
                                  (v13/*: any*/),
                                  (v9/*: any*/),
                                  (v4/*: any*/),
                                  (v6/*: any*/),
                                  (v10/*: any*/)
                                ]
                              }
                            ],
                            "type": "JiraProjectField"
                          }
                        ]
                      }
                    ]
                  }
                ],
                "storageKey": "fieldsById(ids:[\"project\"])"
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ui_JPDIdeaViewBentoSidebarQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v2/*: any*/),
            "concreteType": "JiraIssue",
            "kind": "LinkedField",
            "name": "issueByKey",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "concreteType": "JiraSingleLineTextField",
                "kind": "LinkedField",
                "name": "summaryField",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v14/*: any*/)
                ]
              },
              {
                "concreteType": "JiraRichTextField",
                "kind": "LinkedField",
                "name": "descriptionField",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v14/*: any*/)
                ]
              },
              {
                "concreteType": "JiraIssueTypeField",
                "kind": "LinkedField",
                "name": "issueTypeField",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraIssueType",
                    "kind": "LinkedField",
                    "name": "issueType",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v14/*: any*/)
                    ]
                  },
                  (v14/*: any*/)
                ]
              },
              {
                "args": (v11/*: any*/),
                "concreteType": "JiraIssueFieldConnection",
                "kind": "LinkedField",
                "name": "fieldsById",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraIssueFieldEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v12/*: any*/),
                          (v14/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "concreteType": "JiraProject",
                                "kind": "LinkedField",
                                "name": "project",
                                "plural": false,
                                "selections": [
                                  (v13/*: any*/),
                                  (v9/*: any*/),
                                  (v4/*: any*/),
                                  (v6/*: any*/),
                                  (v10/*: any*/),
                                  (v14/*: any*/)
                                ]
                              }
                            ],
                            "type": "JiraProjectField"
                          }
                        ]
                      }
                    ]
                  }
                ],
                "storageKey": "fieldsById(ids:[\"project\"])"
              },
              (v14/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "b4ff6a9e3f370e691d8f2d6798098bfa3f2cd9a93e2bceff5e7c72b0bf0f91f3",
    "metadata": {},
    "name": "ui_JPDIdeaViewBentoSidebarQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "356c28d00e08cb22177ffdeb850e6f52";

export default node;
