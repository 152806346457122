import type { Area } from '@atlassian/jira-issue-view-common-types/src/connect-field-type.tsx';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button/index.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { canUseServiceDeskAgentFeaturesSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/permissions-selector.tsx';
import { StakeholdersGlance } from '@atlassian/jira-jsm-stakeholder-updates/src/ui/glance/async.tsx';

export const StakeholdersGlanceComponent = connect(
	(state, ownPropsOnMount: { area: Area; itemId: string }) => ({
		itemId: ownPropsOnMount.itemId,
		isVisible: canUseServiceDeskAgentFeaturesSelector(state),
		showPinButton: getShowPinButton(ownPropsOnMount.area),
	}),
	{},
)(StakeholdersGlance);
