import { NOT_FOUND } from '@atlassian/jira-common-constants/src/http-status-codes.tsx';
import { statusCategories } from '@atlassian/jira-common-constants/src/index.tsx';
import { ValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { MAP_FROM_SERVER_COLOR } from '@atlassian/jira-issue-epic-color/src/common/constants.tsx';
import type {
	IssueTypeFieldGiraData,
	SummaryFieldGiraData,
	ParentCandidatesDataEdge,
	StatusFieldGiraData,
} from '@atlassian/jira-issue-fetch-services/src/services/issue-parent-candidates/types.tsx';
import type { IssueParent } from '../types.tsx';

type IssueTypeFieldEdge = {
	node: IssueTypeFieldGiraData;
};

type SummaryFieldEdge = {
	node: SummaryFieldGiraData;
};

type StatusFieldEdge = {
	node: StatusFieldGiraData;
};

export const transformData = (edges: ParentCandidatesDataEdge[]): IssueParent[] =>
	edges.map((edge) => {
		const edgeNode = edge.node;
		const fieldsEdges = edgeNode.fieldsById.edges;
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const summaryField = fieldsEdges.find(
			(field) => field.node.fieldId === 'summary',
		) as SummaryFieldEdge;

		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const issueTypeField = fieldsEdges.find(
			(field) => field.node.fieldId === 'issuetype',
		) as IssueTypeFieldEdge;

		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const statusField = fieldsEdges.find(
			(field) => field.node.fieldId === 'status',
		) as StatusFieldEdge;

		const colorField = edgeNode.issueColorField;

		if (summaryField === undefined) {
			throw new ValidationError(
				'Validation Error',
				[
					{
						field: 'summary',
						error: 'Call to gira for parent candidates returned without summary data',
					},
				],
				NOT_FOUND,
			);
		}
		if (issueTypeField === undefined) {
			throw new ValidationError(
				'Validation Error',
				[
					{
						field: 'issueType',
						error: 'Call to gira for parent candidates returned without issue type data',
					},
				],
				NOT_FOUND,
			);
		}
		if (statusField === undefined) {
			throw new ValidationError(
				'Validation Error',
				[
					{
						field: 'status',
						error: 'Call to gira for parent candidates returned without status data',
					},
				],
				NOT_FOUND,
			);
		}

		if (
			// only issues at level 1 are expected to have a hierarchy level (i.e Epics)
			issueTypeField.node.issueType?.hierarchy?.level === 1 &&
			colorField === undefined
		) {
			throw new ValidationError(
				'Validation Error',
				[
					{
						field: 'Issue Color',
						error: 'Call to gira for parent candidates returned without color data',
					},
				],
				NOT_FOUND,
			);
		}

		const summaryFieldNode = summaryField.node;
		const issueTypeFieldNode = issueTypeField.node;

		const checkParentIssueIsDone = (statusCategoryId: string): boolean => {
			const doneStatusCategoryId =
				statusCategories.StatusCategoryIds[statusCategories.StatusCategoryTypes.DONE];
			return doneStatusCategoryId && String(doneStatusCategoryId) === statusCategoryId;
		};

		const isDone = checkParentIssueIsDone(statusField.node.status.statusCategory.statusCategoryId);

		const statusCategoryId = statusField?.node.status.statusCategory.statusCategoryId;

		const parent = {
			id: edgeNode.issueId,
			key: edgeNode.key,
			summary: summaryFieldNode.text,
			issueType: {
				id: String(issueTypeFieldNode.issueType.issueTypeId),
				name: issueTypeFieldNode.issueType.name,
				iconUrl: issueTypeFieldNode.issueType.avatar.xsmall,
			},
			isDone,
			statusCategoryId,
		};

		// todo: write a test for issueColor undefined
		return {
			...parent,
			color: colorField?.color?.colorKey
				? MAP_FROM_SERVER_COLOR[colorField.color.colorKey]
				: undefined,
		};
	});
