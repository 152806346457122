import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	createNewIcon: {
		id: 'issue-field-release-versions-editview-full.release-versions.create-new-icon',
		defaultMessage: 'Create new version icon',
		description: 'Accesibility label for the create version icon',
	},
	createNewItem: {
		id: 'issue-field-release-versions-editview-full.release-versions.create-new-item',
		defaultMessage: 'Create new version',
		description: 'Label for the create new version button',
	},
	releasedHeading: {
		id: 'issue-field-release-versions-editview-full.release-versions.released-heading',
		defaultMessage: 'Released',
		description: 'Header displayed in versions list used to mark released items',
	},
	unreleasedHeading: {
		id: 'issue-field-release-versions-editview-full.release-versions.unreleased-heading',
		defaultMessage: 'Unreleased',
		description: 'Header displayed in versions list used to mark unreleased items',
	},
	placeholder: {
		id: 'issue-field-release-versions-editview-full.release-versions.placeholder',
		defaultMessage: 'Select version',
		description: 'Placeholder message when no version is selected',
	},
});
