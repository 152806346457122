import noop from 'lodash/noop';
import type { State } from './types.tsx';

export const initialState: State = {
	isUnarchiveIssueModalOpen: false,
	issueKey: null,
	selectedIssuesDetails: [],
	onSuccessCallback: noop,
	onErrorCallback: noop,
	showViewIssueActionInSuccessFlag: false,
};
