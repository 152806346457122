import type { Action } from '@atlassian/react-sweet-state';
import type { State, Props } from '../../types.tsx';

export const setIsExporting =
	(isExporting: boolean): Action<State, Props> =>
	({ setState }) => {
		setState({
			isExporting,
		});
	};

export const setIsReadyToExport =
	(isReadyToExport: boolean): Action<State, Props> =>
	({ setState }) => {
		setState({
			isReadyToExport,
		});
	};
