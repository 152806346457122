import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const thirdPartyIssueWatchNudgeEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(/* webpackChunkName: "async-third-party-issue-watch-nudge" */ './main').then(
			({ ThirdPartyIssueWatchNudge }) => ThirdPartyIssueWatchNudge,
		),
	),
	getPreloadProps: () => ({}),
});
