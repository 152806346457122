/**
 * @generated SignedSource<<08007f396db418a9d13cbce15739952a>>
 * @relayHash b249572b29f7cf45d6d7890c57510aed
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 8768a3b7a61167cb3f57c1ea183973ef7e13af793cc24d86f169fda9c41a20dd

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type AccountStatus = "active" | "closed" | "inactive" | "%future added value";
export type JiraSingleValueFieldOperations = "SET" | "%future added value";
export type JiraUpdateSingleSelectUserPickerFieldInput = {
  id: string;
  operation: JiraSingleSelectUserPickerFieldOperationInput;
};
export type JiraSingleSelectUserPickerFieldOperationInput = {
  accountId?: string | null | undefined;
  id?: string | null | undefined;
  operation: JiraSingleValueFieldOperations;
};
export type singleUserPicker_issueFieldSingleUser_SingleUserPickerField_Mutation$variables = {
  input: JiraUpdateSingleSelectUserPickerFieldInput;
};
export type singleUserPicker_issueFieldSingleUser_SingleUserPickerField_Mutation$data = {
  readonly jira: {
    readonly updateSingleSelectUserPickerField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly user: {
          readonly accountId: string;
          readonly accountStatus: AccountStatus;
          readonly id: string;
          readonly name: string;
          readonly picture: AGG$URL;
        } | null | undefined;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type singleUserPicker_issueFieldSingleUser_SingleUserPickerField_Mutation$rawResponse = {
  readonly jira: {
    readonly updateSingleSelectUserPickerField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly id: string;
        readonly user: {
          readonly __typename: string;
          readonly accountId: string;
          readonly accountStatus: AccountStatus;
          readonly id: string;
          readonly name: string;
          readonly picture: AGG$URL;
        } | null | undefined;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type singleUserPicker_issueFieldSingleUser_SingleUserPickerField_Mutation = {
  rawResponse: singleUserPicker_issueFieldSingleUser_SingleUserPickerField_Mutation$rawResponse;
  response: singleUserPicker_issueFieldSingleUser_SingleUserPickerField_Mutation$data;
  variables: singleUserPicker_issueFieldSingleUser_SingleUserPickerField_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "success"
},
v3 = {
  "concreteType": "MutationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "message"
    }
  ]
},
v4 = {
  "kind": "ScalarField",
  "name": "accountId"
},
v5 = {
  "kind": "ScalarField",
  "name": "name"
},
v6 = {
  "kind": "ScalarField",
  "name": "picture"
},
v7 = {
  "kind": "ScalarField",
  "name": "accountStatus"
},
v8 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "singleUserPicker_issueFieldSingleUser_SingleUserPickerField_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraSingleSelectUserPickerFieldPayload",
            "kind": "LinkedField",
            "name": "updateSingleSelectUserPickerField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraSingleSelectUserPickerField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "singleUserPicker_issueFieldSingleUser_SingleUserPickerField_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraSingleSelectUserPickerFieldPayload",
            "kind": "LinkedField",
            "name": "updateSingleSelectUserPickerField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraSingleSelectUserPickerField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "__typename"
                      },
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/)
                    ]
                  },
                  (v8/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "8768a3b7a61167cb3f57c1ea183973ef7e13af793cc24d86f169fda9c41a20dd",
    "metadata": {},
    "name": "singleUserPicker_issueFieldSingleUser_SingleUserPickerField_Mutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "bd06a0600bf6d9530e408c77f4b2739a";

export default node;
