import React from 'react';
import Button from '@atlaskit/button/new';
import { useFlagsService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';

import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useEditAffectedServicesField } from '@atlassian/jira-issue-field-affected-services/src/services/use-edit-affected-services-field/index.tsx';
import type { UIAnalyticsEvent } from '@atlassian/jira-product-analytics-bridge';
import type { AffectedServicesSuggestedAction } from '@atlassian/jira-servicedesk-ai-context-ops-panel-resources/src/common/types.tsx';
import { commonMessages } from '../../messages.tsx';
import type { ActionButtonProps } from '../../types.tsx';

import { messages } from './messages.tsx';

type Props = ActionButtonProps<AffectedServicesSuggestedAction['context']>;

export const AddAffectedServicesButton = ({
	context,
	onSuccess: onSuccessProp,
	onFailure: onFailureProp,
	onClick: onClickProp,
}: Props) => {
	const { formatMessage } = useIntl();
	const { showFlag } = useFlagsService();
	const issueKey = useIssueKey();
	const { suggestion } = context;

	const onSuccess = () => {
		showFlag({
			type: 'success',
			title: formatMessage(messages.addAffectedServicesSuccess, {
				affectedServicesCount: suggestion.length,
			}),
		});
		onSuccessProp?.();
	};

	const onFailure = (error: Error) => {
		showFlag({
			type: 'error',
			title: formatMessage(commonMessages.error),
		});
		onFailureProp?.(error);
	};

	const [{ loading, value: currentServices }, { saveValue }] = useEditAffectedServicesField({
		issueKey,
		onSuccess,
		onFailure,
	});

	const onClickHandler = (_: unknown, event: UIAnalyticsEvent) => {
		const newServices = [...(currentServices ?? []), ...suggestion].map((service) => ({
			id: service.id,
		}));

		saveValue?.(newServices, null, event);
		onClickProp?.();
	};

	return (
		<Button isLoading={loading} onClick={onClickHandler}>
			{formatMessage(commonMessages.accept)}
		</Button>
	);
};
