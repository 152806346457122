import React, { useCallback, useMemo, memo } from 'react';
import { CommandShortcuts } from '@atlassian/jira-command-palette-common/src/ui/command-shortcuts/index.tsx';
import { useActionsCommandPalette } from '@atlassian/jira-command-palette/src/controllers/command-palette/index.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useIsEmbedMode } from '@atlassian/jira-issue-view-embed-mode/src/index.tsx';
import { navigateToNewIssue } from '@atlassian/jira-issue-view-store/src/actions/issue-navigation-actions.tsx';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { ISSUE_ACTION_SHORTCUT_RIGHT_PANEL } from './constant.tsx';
import useBoardNavigationShortcuts from './services/board-navigation-shortcuts.tsx';
import useIssueActionsShortcuts from './services/issue-actions-shortcuts.tsx';
import useMenuShortcuts from './services/menu-shortcuts.tsx';
import { useIssueKeyboardShortcutsActions } from './services/store.tsx';
import type { Action, Props, PanelShortcut } from './types.tsx';

// Tests for binding shortcut keys to actions are handled in the `@atlassian/jira-common-components-keyboard-shortcuts/src/shortcuts` package.
const KeyboardShortcutsView = (props: Props) => {
	const { dispatch } = props;
	const issueKey = useIssueKey();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const [, { triggerComponent, triggerFocusedPanel }] = useIssueKeyboardShortcutsActions();

	const { open } = useActionsCommandPalette();

	const triggerShortcutAction = useCallback(
		(action: Action, name: string, key: string): void => {
			setTimeout(() => dispatch(action), 0);

			fireUIAnalytics(
				createAnalyticsEvent({}),
				'keyboardShortcut pressed',
				`${name}KeyboardShortcut`,
				{ key },
			);
		},
		[createAnalyticsEvent, dispatch],
	);

	const triggerDotShortcutAction = useCallback(
		(name: string, key: string): void => {
			open();

			fireUIAnalytics(
				createAnalyticsEvent({}),
				'keyboardShortcut pressed',
				`${name}KeyboardShortcut`,
				{ key },
			);

			// open dot dialog triggers an extra event
			fireUIAnalytics(
				createAnalyticsEvent({}),
				'keyboardShortcut pressed',
				'dotDialogKeyboardShortcut',
				{ key, view: 'NEW_ISSUE_VIEW' },
			);
		},
		[createAnalyticsEvent, open],
	);

	const navigateToIssue = useCallback(
		(toIssueKey: IssueKey) => {
			if (toIssueKey !== issueKey) {
				dispatch(
					navigateToNewIssue({
						fromIssueKey: issueKey,
						toIssueKey,
					}),
				);
			}
		},
		[dispatch, issueKey],
	);

	const triggerShortcutComponent = useCallback(
		(
			component:
				| 'issueScrollTriggerCount'
				| 'shareDropdownTriggerCount'
				| 'statusDropdownTriggerCount',
			name: string,
			key: string,
		): void => {
			triggerComponent(component);

			fireUIAnalytics(
				createAnalyticsEvent({}),
				'keyboardShortcut pressed',
				`${name}KeyboardShortcut`,
				{ key },
			);
		},
		[triggerComponent, createAnalyticsEvent],
	);

	const triggerShortcutPanelFocus = useCallback(
		(panel: PanelShortcut, name: string) => {
			if (panel === ISSUE_ACTION_SHORTCUT_RIGHT_PANEL) {
				triggerFocusedPanel('RIGHT');
			} else {
				triggerFocusedPanel('LEFT');
			}

			fireUIAnalytics(
				createAnalyticsEvent({}),
				'keyboardShortcut pressed',
				`${name}KeyboardShortcut`,
			);
		},
		[triggerFocusedPanel, createAnalyticsEvent],
	);

	const issueActionsShortcutsKeyMap = useIssueActionsShortcuts(
		triggerShortcutAction,
		triggerShortcutComponent,
		triggerShortcutPanelFocus,
	);

	const menuShortcutsKeyMap = useMenuShortcuts(triggerDotShortcutAction);
	const boardNavigationShortcutsKeyMap = useBoardNavigationShortcuts(navigateToIssue);

	const shortcutsKeyMap = useMemo(
		() => ({
			...issueActionsShortcutsKeyMap, // Not FF guarding this as existing shortcuts need to enabled.
			...boardNavigationShortcutsKeyMap,
			...menuShortcutsKeyMap,
		}),
		[boardNavigationShortcutsKeyMap, issueActionsShortcutsKeyMap, menuShortcutsKeyMap],
	);
	if (useIsEmbedMode()) {
		return null;
	}

	return <CommandShortcuts keyMap={shortcutsKeyMap} />;
};

export default memo<Props>(KeyboardShortcutsView);
