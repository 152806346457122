import React, { Component } from 'react';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@atlaskit/modal-dialog';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import type {
	TimeTracking,
	TimeTrackingConfig,
	OptionalTime,
} from '@atlassian/jira-issue-shared-types/src/common/types/jira-settings-type.tsx';
import type { FormValues } from '@atlassian/jira-issue-view-common-types/src/log-time-modal-type.tsx';
import type { MediaContext } from '@atlassian/jira-issue-view-common-types/src/media-context-type.tsx';
import secondsToTimeString from '@atlassian/jira-issue-view-common-utils/src/time-string/seconds-to-time-string/index.tsx';
import timeStringToSeconds from '@atlassian/jira-issue-view-common-utils/src/time-string/time-string-to-seconds/index.tsx';
import { ModalDialog } from '@atlassian/jira-software-modal-dialog/src/ui/modal-dialog/index.tsx';
import type { FormState } from './footer/types.tsx';
import { LogTimeModalFooter as Footer } from './footer/view.tsx';
import { LogTimeModalForm as Form } from './form/view.tsx';
import ProgressTracker from './progress-tracker/main.tsx';

export type Props = {
	isCollapsed: boolean;
	isDone: boolean;
	isTimeLoggedValid: boolean;
	isTimeRemainingValid: boolean;
	isRollingUpData: boolean;
	isClassicProject: boolean;
	shouldDisplayRollUpDataControl: boolean;
	value: FormValues;
	progressTrackerValue: TimeTracking;
	estimatedTime: OptionalTime;
	rolledUpEstimatedTime: OptionalTime;
	config: TimeTrackingConfig;
	header: string;
	formState: FormState;
	timeZone: string | undefined;
	mediaContext: MediaContext;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mentionProvider: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mentionEncoder: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	activityProvider: any;
	externalId: string;
	onSubmit: (arg1: unknown, arg2: UIAnalyticsEvent) => void;
	onCancel: () => void;
	onTimeLoggedChange: (arg1: string) => void;
	onTimeLoggedBlur: () => void;
	onTimeRemainingChange: (arg1: string) => void;
	onTimeRemainingBlur: () => void;
	onDateStartedChange: (arg1: string | null) => void;
	onWorkDescriptionChange: (arg1: ADF) => void;
	onWorkDescriptionChangeFailure: () => void;
	onToggleRollingUpData: (arg1: boolean, arg2: UIAnalyticsEvent) => void;
};

type State = {
	containerRef: HTMLDivElement | null | undefined;
};

// eslint-disable-next-line jira/react/no-class-components
export default class LogTimeModal extends Component<Props, State> {
	static defaultProps = {
		isDone: false,
		progressTrackerValue: {},
		estimatedTime: undefined,
	};

	state = {
		containerRef: undefined,
	};

	setContainerRef = (ref: HTMLDivElement | null) => {
		if (!this.state.containerRef) {
			this.setState({ containerRef: ref });
		}
	};

	secondsToTimeString(seconds: number): string {
		const { daysPerWeek, hoursPerDay } = this.props.config;
		return secondsToTimeString(daysPerWeek, hoursPerDay)(seconds);
	}

	timeStringToSeconds(timeString: string) {
		const { daysPerWeek, hoursPerDay } = this.props.config;
		return timeStringToSeconds(daysPerWeek, hoursPerDay)(timeString);
	}

	renderHeader = () => <h3>{this.props.header}</h3>;

	renderProgressTracker() {
		const {
			progressTrackerValue,
			progressTrackerValue: { loggedTime, remainingTime },
			estimatedTime,
			rolledUpEstimatedTime,
			config,
			isDone,
			shouldDisplayRollUpDataControl,
			isRollingUpData,
			onToggleRollingUpData,
			isClassicProject,
		} = this.props;

		if (
			loggedTime === undefined &&
			remainingTime === undefined &&
			estimatedTime === undefined &&
			shouldDisplayRollUpDataControl === false
		) {
			// Do not render the progress tracker at all if all values are empty.
			return null;
		}

		return (
			<ProgressTracker
				config={config}
				value={progressTrackerValue}
				estimatedTime={isRollingUpData ? rolledUpEstimatedTime : estimatedTime}
				isDone={isDone}
				shouldDisplayRollUpDataControl={shouldDisplayRollUpDataControl}
				isRollingUpData={isRollingUpData}
				onToggleRollingUpData={onToggleRollingUpData}
				isClassicProject={isClassicProject}
			/>
		);
	}

	renderForm() {
		return (
			<Form
				value={this.props.value}
				isCollapsed={this.props.isCollapsed}
				isDisabled={this.props.formState === 'submitting'}
				isTimeLoggedValid={this.props.isTimeLoggedValid}
				onTimeLoggedBlur={this.props.onTimeLoggedBlur}
				isTimeRemainingValid={this.props.isTimeRemainingValid}
				onTimeRemainingBlur={this.props.onTimeRemainingBlur}
				timeZone={this.props.timeZone}
				portalElement={this.state.containerRef}
				onTimeLoggedChange={this.props.onTimeLoggedChange}
				onTimeRemainingChange={this.props.onTimeRemainingChange}
				onDateStartedChange={this.props.onDateStartedChange}
				onWorkDescriptionChange={this.props.onWorkDescriptionChange}
				onWorkDescriptionChangeFailure={this.props.onWorkDescriptionChangeFailure}
				mediaContext={this.props.mediaContext}
				mentionEncoder={this.props.mentionEncoder}
				mentionProvider={this.props.mentionProvider}
				activityProvider={this.props.activityProvider}
				externalId={this.props.externalId}
			/>
		);
	}

	renderFooter = () => (
		<Footer
			formState={this.props.formState}
			onConfirm={this.props.onSubmit}
			onCancel={this.props.onCancel}
		/>
	);

	renderChildren() {
		/* we need to use the EnterEscapeHandler instead of just onDialogDismissed on the modal
        because we only want to close the top-level modal if there are multiple */
		return (
			<div
				ref={this.setContainerRef}
				// eslint-disable-next-line jira/integration/test-id-by-folder-structure
				data-testid="issue.issue-view.common.log-time-modal-dialog.contents"
			>
				{this.renderProgressTracker()}
				{this.renderForm()}
			</div>
		);
	}

	render() {
		return (
			<ShortcutScope>
				<ModalDialog
					width="small"
					onClose={this.props.onCancel /* so that it closes on overlay click */}
					shouldCloseOnEscapePress
				>
					<ModalHeader>
						<ModalTitle>{this.props.header}</ModalTitle>
					</ModalHeader>
					<ModalBody>{this.renderChildren()}</ModalBody>
					<ModalFooter>
						<Footer
							formState={this.props.formState}
							onConfirm={this.props.onSubmit}
							onCancel={this.props.onCancel}
						/>
					</ModalFooter>
				</ModalDialog>
			</ShortcutScope>
		);
	}
}
