import React from 'react';
import noop from 'lodash/noop';
import memoizeOne from 'memoize-one';
import type { IssueViewRealtimeEvent } from '@atlassian/jira-issue-shared-types/src/common/types/realtime-support.tsx';
import { EVENTS_TO_LISTEN } from '@atlassian/jira-issue-view-common-constants/src/realtime-support.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { realtimeUpdateCurrentIssue } from '@atlassian/jira-issue-view-store/src/actions/issue-realtime-actions.tsx';
import {
	cloudIdSelector,
	issueIdSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import { projectIdSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';
import { realtimeChannelsSelector } from '@atlassian/jira-issue-view-store/src/selectors/realtime-selectors.tsx';
import type { RealtimeEvent } from '@atlassian/jira-realtime/src/common/types/events.tsx';
import Realtime from '@atlassian/jira-realtime/src/main.tsx';
import {
	toProjectId,
	toIssueId,
	toAccountId,
	type CloudId,
} from '@atlassian/jira-shared-types/src/general.tsx';

type Props = {
	channels: string[];
	events: string[];
	cloudId: CloudId;
	projectId: number | null;
	onJoin: (channels: string[]) => void;
	onReceive: (arg1: RealtimeEvent) => void;
	onLeave: (channels: string[]) => void;
};

const IssueRealtimeSupport = ({ cloudId, projectId, ...otherProps }: Props) =>
	cloudId && projectId ? <Realtime appId="issue-view" {...otherProps} /> : null;

const mapStateToProps = (state: State) => ({
	channels: realtimeChannelsSelector(state),
	cloudId: cloudIdSelector(state),
	projectId: projectIdSelector(state),
	issueId: issueIdSelector(state),
});

const mapDispatchToProps = {
	realtimeUpdateCurrentIssue,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

const onReceiveFactory = memoizeOne(
	(
		issueId: StateProps['issueId'],
		dispatchPropsRealtimeUpdateCurrentIssue: DispatchProps['realtimeUpdateCurrentIssue'],
	) =>
		(event: RealtimeEvent) => {
			if (
				event &&
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
				EVENTS_TO_LISTEN.includes(event.type as any) &&
				'issueId' in event.payload &&
				event.payload.issueId === Number(issueId)
			) {
				// TypeScript still does not understand that the `event`
				// is of the IssueViewRealtimeEvent type at this point so it is force casted
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				const issueViewEvent = event as IssueViewRealtimeEvent;

				dispatchPropsRealtimeUpdateCurrentIssue({
					atlassianId: toAccountId(issueViewEvent.payload.atlassianId),
					projectId: toProjectId(String(issueViewEvent.payload.projectId)),
					issueId: toIssueId(String(issueViewEvent.payload.issueId)),
					updateType: issueViewEvent.type,
				});
			}
		},
);

const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps) => ({
	channels: stateProps.channels,
	events: EVENTS_TO_LISTEN,
	cloudId: stateProps.cloudId,
	projectId: stateProps.projectId,
	onJoin: noop,
	onReceive: onReceiveFactory(stateProps.issueId, dispatchProps.realtimeUpdateCurrentIssue),
	onLeave: noop,
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(IssueRealtimeSupport);
