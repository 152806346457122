/**
 * @generated SignedSource<<f363e4edea709dd2b415656b6c68b2b7>>
 * @relayHash d18f9f3978b3600b46084d44d46c9aba
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 67e935705eee63c3a933e86d7e13e3e9087e01504aee222834e09b93f7f54580

import type { ConcreteRequest, Query } from 'relay-runtime';
export type servicesReadingAids_acronymsQuery$variables = {
  content: string;
};
export type servicesReadingAids_acronymsQuery$data = {
  readonly getKeywords: ReadonlyArray<string> | null | undefined;
};
export type servicesReadingAids_acronymsQuery = {
  response: servicesReadingAids_acronymsQuery$data;
  variables: servicesReadingAids_acronymsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "content"
  }
],
v1 = [
  {
    "args": [
      {
        "fields": [
          {
            "kind": "Literal",
            "name": "format",
            "value": "PLAIN_TEXT"
          },
          {
            "kind": "Variable",
            "name": "text",
            "variableName": "content"
          }
        ],
        "kind": "ObjectValue",
        "name": "textInput"
      }
    ],
    "kind": "ScalarField",
    "name": "getKeywords"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "servicesReadingAids_acronymsQuery",
    "selections": (v1/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "servicesReadingAids_acronymsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "67e935705eee63c3a933e86d7e13e3e9087e01504aee222834e09b93f7f54580",
    "metadata": {},
    "name": "servicesReadingAids_acronymsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "add25bd4b5083989935d4c67fbb740e6";

export default node;
