import cloneDeep from 'lodash/cloneDeep';
import {
	type CaretPosition,
	type PlaceholderEnum,
	type UserConfigTemplates,
	BranchTemplateType,
} from '../../../common/types.tsx';

const separatorTemplate = {
	type: BranchTemplateType.TEXT,
	value: '-',
};

const textTemplate = (value: string) => ({
	type: BranchTemplateType.TEXT,
	value,
});

const tagTemplate = (placeholder: PlaceholderEnum) => ({
	type: BranchTemplateType.PLACEHOLDER,
	value: placeholder,
});

const insertAtFirstCharacterOfInput = (
	readAndWriteTemplates: UserConfigTemplates,
	placeholder: PlaceholderEnum,
) => {
	// insert a separator after the tag
	const newUserConfigTemplates = cloneDeep(readAndWriteTemplates);
	if (newUserConfigTemplates.length > 1)
		newUserConfigTemplates[0].value = `${separatorTemplate.value}${newUserConfigTemplates[0].value}`;
	newUserConfigTemplates.unshift(textTemplate(''), tagTemplate(placeholder));

	return newUserConfigTemplates;
};

export const getUpdatedCaretPosition = (
	placeholderIndex: number,
	previousTemplates: UserConfigTemplates,
	removedWithKeyboard: boolean,
) => {
	const templateIndex = Math.ceil((placeholderIndex + 1) / 2) - 1;
	const previousTextTemplate = previousTemplates[placeholderIndex - 1];
	const currentTextTemplate = previousTemplates[placeholderIndex + 1];
	const characterIndexForRemovedViaCrossButton =
		previousTextTemplate.value.endsWith('-') && currentTextTemplate.value.length === 0
			? previousTextTemplate.value.length - 1
			: previousTextTemplate.value.length;
	const characterIndex = removedWithKeyboard
		? previousTextTemplate.value.length
		: characterIndexForRemovedViaCrossButton;
	return {
		templateIndex,
		characterIndex,
	};
};

const insertAtFirstCharacterAfterTag = (
	positionIndex: number,
	readAndWriteTemplates: UserConfigTemplates,
	placeholder: PlaceholderEnum,
) => {
	// insert a separator before and after the tag
	const newUserConfigTemplates = cloneDeep(readAndWriteTemplates);
	const endText = readAndWriteTemplates[positionIndex].value;
	const endTextWithPrefix = endText.startsWith('-') || !endText ? endText : `-${endText}`;
	newUserConfigTemplates.splice(
		positionIndex,
		1,
		separatorTemplate,
		tagTemplate(placeholder),
		textTemplate(endTextWithPrefix),
	);

	return newUserConfigTemplates;
};

/**
 * When we delete a text tag entirely, there is a chance in some browsers, that
 * we end up with two tags next to each other without a text template inbetween.
 * This means we can't place a caret between them and can't enter something new there.
 *
 * So we'll need to insert an empty text template in such scenarios.
 */
export const handleConsecutiveTagTemplates = (readAndWriteTemplates: UserConfigTemplates) => {
	for (let i = 0; i < readAndWriteTemplates.length - 1; i += 1) {
		if (
			readAndWriteTemplates[i].type === BranchTemplateType.PLACEHOLDER &&
			readAndWriteTemplates[i + 1].type === BranchTemplateType.PLACEHOLDER
		) {
			readAndWriteTemplates.splice(i + 1, 0, textTemplate(''));
			return readAndWriteTemplates;
		}
	}

	return readAndWriteTemplates;
};

/**
 * When we delete a text tag entirely, there is a chance in some browsers, that
 * we end up with a tag node at the end without a text template after it.
 * This means we can't place the caret at the end.
 *
 * So we'll need to insert an empty text template in such scenarios.
 */
export const handleEndTagTemplate = (readAndWriteTemplates: UserConfigTemplates) => {
	const lastTemplate = readAndWriteTemplates[readAndWriteTemplates.length - 1];
	if (lastTemplate.type === BranchTemplateType.PLACEHOLDER) {
		readAndWriteTemplates.splice(readAndWriteTemplates.length, 0, textTemplate(''));
		return readAndWriteTemplates;
	}
	return readAndWriteTemplates;
};

/**
 * When we remove a tag, we need to merge the adjacent text templates
 * into a single template. This is required for the caret position logic
 * to work correctly.
 *
 * Caret position works based on the assumption that there are no two text
 * templates next to each other.
 */
export const mergeConsecutiveTextTemplates = (
	readAndWriteTemplates: UserConfigTemplates,
	removedWithKeyboard: boolean,
) => {
	for (let i = 0; i < readAndWriteTemplates.length - 1; i += 1) {
		if (
			readAndWriteTemplates[i].type === BranchTemplateType.TEXT &&
			readAndWriteTemplates[i + 1].type === BranchTemplateType.TEXT
		) {
			// merge the text of 2nd text node with the 1st node and
			// remove the 2nd text tag
			const beginText = readAndWriteTemplates[i].value;
			const endText = readAndWriteTemplates[i + 1].value;
			const beginTextEndWithSuffix = beginText.endsWith('-');
			const endTextStartWithPrefix = endText.startsWith('-');
			let finalSuffixedText;
			if (!beginText && endText && endTextStartWithPrefix) {
				finalSuffixedText = endText.substring(1);
			} else if (!endText && beginText && beginTextEndWithSuffix) {
				finalSuffixedText = beginText.slice(0, -1);
			} else if (beginTextEndWithSuffix && endTextStartWithPrefix) {
				finalSuffixedText = beginText.slice(0, -1) + endText;
			} else {
				finalSuffixedText = beginText + endText;
			}

			finalSuffixedText = finalSuffixedText === '--' ? '-' : finalSuffixedText;

			/**
			 * Keep hyphens when the tag was removed with keyboard to avoid jank
			 */
			const finalText = removedWithKeyboard
				? textTemplate(`${beginText}${endText}`)
				: textTemplate(finalSuffixedText);

			readAndWriteTemplates.splice(i, 2, finalText);
			return readAndWriteTemplates;
		}
	}

	return readAndWriteTemplates;
};

const insertInTheMiddle = (
	positionIndex: number,
	characterIndex: number,
	currentNodeText: string,
	readAndWriteTemplates: UserConfigTemplates,
	placeholder: PlaceholderEnum,
) => {
	const beginText = currentNodeText.substring(0, characterIndex);
	const endText = currentNodeText.substring(characterIndex);
	const noPrefixRequired =
		endText.startsWith('-') ||
		(endText === '' && positionIndex === readAndWriteTemplates.length - 1);
	const beginTextWithSuffix = beginText.endsWith('-') ? beginText : `${beginText}-`;
	const endTextWithPrefix = noPrefixRequired ? endText : `-${endText}`;

	const newUserConfigTemplates = cloneDeep(readAndWriteTemplates);
	newUserConfigTemplates.splice(
		positionIndex,
		1,
		textTemplate(beginTextWithSuffix),
		tagTemplate(placeholder),
		textTemplate(endTextWithPrefix),
	);
	return newUserConfigTemplates;
};

export const removePlaceholderFromState = (
	originalTemplates: UserConfigTemplates,
	placeholderToRemove: PlaceholderEnum,
	placeholderIndex: number,
	removedWithKeyboard: boolean,
) => {
	const readAndWriteTemplates = [...originalTemplates];
	for (let i = 0; i < readAndWriteTemplates.length; i += 1) {
		if (
			readAndWriteTemplates[i].type === BranchTemplateType.PLACEHOLDER &&
			readAndWriteTemplates[i].value === placeholderToRemove &&
			i === placeholderIndex
		) {
			// we have reached the tag we want to remove
			readAndWriteTemplates.splice(i, 1);
			return mergeConsecutiveTextTemplates(readAndWriteTemplates, removedWithKeyboard);
		}
	}

	return readAndWriteTemplates;
};

export const insertPlaceholderIntoState = (
	caretPosition: CaretPosition,
	readAndWriteTemplates: UserConfigTemplates,
	placeholder: PlaceholderEnum,
) => {
	let templatesYetToScan = caretPosition.templateIndex;
	const newUserConfigTemplates = cloneDeep(readAndWriteTemplates);
	for (let i = 0; i < newUserConfigTemplates.length; i += 1) {
		if (templatesYetToScan === 0 && newUserConfigTemplates[i].type === BranchTemplateType.TEXT) {
			// we have reached the template we want to insert the new placeholder into

			if (caretPosition.templateIndex === 0 && caretPosition.characterIndex === 0) {
				return insertAtFirstCharacterOfInput(newUserConfigTemplates, placeholder);
			}

			if (caretPosition.characterIndex === 0) {
				return insertAtFirstCharacterAfterTag(i, newUserConfigTemplates, placeholder);
			}

			return insertInTheMiddle(
				i,
				caretPosition.characterIndex,
				newUserConfigTemplates[i].value,
				newUserConfigTemplates,
				placeholder,
			);
		}
		if (newUserConfigTemplates[i].type === BranchTemplateType.PLACEHOLDER) {
			templatesYetToScan -= 1;
		}
	}

	return newUserConfigTemplates;
};
