import React, { useCallback, useMemo } from 'react';
import { useFragment, graphql, useMutation } from 'react-relay';
import type {
	SecurityLevel,
	SecurityLevelItem,
} from '@atlassian/jira-issue-field-security-level/src/common/types.tsx';
import { SecurityLevelView } from '@atlassian/jira-issue-field-security-level/src/ui/security-level-view/index.tsx';
import { useServerValidationHandler } from '@atlassian/jira-issue-field-validation-handler/src/controllers/server-validation-handler/index.tsx';
import type { inlineEdit_issueViewLayoutSecurityLevelField_SecurityLevelInlineEdit$key } from '@atlassian/jira-relay/src/__generated__/inlineEdit_issueViewLayoutSecurityLevelField_SecurityLevelInlineEdit.graphql';
import type { inlineEdit_securityLevelField_Mutation as SecurityLevelMutation } from '@atlassian/jira-relay/src/__generated__/inlineEdit_securityLevelField_Mutation.graphql';
import type { AggJiraSecurityLevel } from '../../../common/types.tsx';

export type SecurityLevelInlineEditProps = {
	securityLevelField: inlineEdit_issueViewLayoutSecurityLevelField_SecurityLevelInlineEdit$key;
	onSubmit?: (value: AggJiraSecurityLevel) => void;
	onSubmitSucceeded?: (value: AggJiraSecurityLevel) => void;
	onSubmitFailed?: (error?: Error) => void;
};

const transformAggSecurityLevelToLegacy = (
	aggSecurityLevel: AggJiraSecurityLevel | undefined | null,
): SecurityLevel => {
	if (!aggSecurityLevel) {
		return null;
	}
	return {
		id: Number(aggSecurityLevel.securityId),
		name: aggSecurityLevel.name || '',
		description: aggSecurityLevel.description || '',
	};
};

export const SecurityLevelInlineEdit = ({
	securityLevelField,
	onSubmit,
	onSubmitFailed,
	onSubmitSucceeded,
}: SecurityLevelInlineEditProps) => {
	const field =
		useFragment<inlineEdit_issueViewLayoutSecurityLevelField_SecurityLevelInlineEdit$key>(
			graphql`
				fragment inlineEdit_issueViewLayoutSecurityLevelField_SecurityLevelInlineEdit on JiraSecurityLevelField {
					id
					name
					securityLevel {
						id
						securityId
						name
						description
					}
					securityLevels {
						edges {
							node {
								id
								securityId
								name
								description
							}
						}
					}
					isEditableInIssueView
				}
			`,
			securityLevelField,
		);

	const [, { handleSubmitFailed, clearServerValidation }] = useServerValidationHandler(field.name);

	const [commit] = useMutation<SecurityLevelMutation>(graphql`
		mutation inlineEdit_securityLevelField_Mutation($input: JiraUpdateSecurityLevelFieldInput!)
		@raw_response_type {
			jira {
				updateSecurityLevelField(input: $input) @optIn(to: "JiraIssueFieldMutations") {
					success
					errors {
						message
					}
					field {
						securityLevel {
							id
							securityId
							name
							description
						}
					}
				}
			}
		}
	`);

	const handleSubmit = useCallback(
		async (newValue: SecurityLevel) => {
			const newAggValue = newValue
				? field.securityLevels?.edges?.find((edge) =>
						edge?.node?.securityId === undefined
							? false
							: Number(edge.node.securityId) === newValue?.id,
					)?.node
				: null;
			clearServerValidation();
			onSubmit?.(newAggValue);
			commit({
				variables: {
					input: {
						id: field.id,
						operation: {
							operation: 'SET',
							id: newAggValue?.id ?? null,
						},
					},
				},
				onCompleted: (mutationData, errors) => {
					if (mutationData.jira?.updateSecurityLevelField?.success) {
						onSubmitSucceeded?.(newAggValue);
					} else {
						const err = errors?.length ? new Error(errors[0].message) : undefined;
						handleSubmitFailed(err);
						onSubmitFailed?.(err);
					}
				},
				onError(err) {
					handleSubmitFailed(err);
					onSubmitFailed?.(err);
				},
				optimisticResponse: {
					jira: {
						updateSecurityLevelField: {
							success: true,
							errors: null,
							field: {
								id: field.id,
								securityLevel: newAggValue,
							},
						},
					},
				},
			});
		},
		[
			clearServerValidation,
			commit,
			field.id,
			field.securityLevels?.edges,
			handleSubmitFailed,
			onSubmit,
			onSubmitFailed,
			onSubmitSucceeded,
		],
	);

	const allowedValues = useMemo(() => {
		const value: SecurityLevelItem[] = (
			field.securityLevels?.edges?.map((edge) => transformAggSecurityLevelToLegacy(edge?.node)) ||
			[]
		).filter((item: SecurityLevelItem | null): item is Exclude<typeof item, null> => item !== null);
		return value;
	}, [field?.securityLevels?.edges]);

	const value = useMemo(
		() => transformAggSecurityLevelToLegacy(field.securityLevel),
		[field.securityLevel],
	);
	return (
		<SecurityLevelView
			value={value}
			allowedValues={allowedValues}
			isEditable={field.isEditableInIssueView || false}
			onChange={handleSubmit}
		/>
	);
};
