import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { FireUiAnalytics } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';

export const NoEligibleBranches = () => {
	const { formatMessage } = useIntl();
	return (
		<MessageWrapper>
			<FireUiAnalytics
				actionSubject="message"
				action="viewed"
				actionSubjectId="createPullRequestNoEligibleBranchesFoundMessage"
			/>
			{formatMessage(messages.noEligibleBranchesFound)}
		</MessageWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MessageWrapper = styled.div({
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `${gridSize * 40}px`,
	margin: token('space.200', '16px'),
});
