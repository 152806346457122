import React, {
	type ReactNode,
	useMemo,
	useRef,
	useState,
	useCallback,
	useEffect,
	type ReactElement,
	type FocusEvent,
} from 'react';
import noop from 'lodash/noop';
import { di } from 'react-magnetic-di';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/observable/timer';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/debounce';
import 'rxjs/add/operator/switchMap';
import { ErrorMessage } from '@atlaskit/form';
import pickerMessages from '@atlassian/jira-common-components-picker/src/base/messages.tsx';
import type {
	ActionMeta,
	OptionToFilter,
	Props as AkSelectProps,
} from '@atlassian/jira-common-components-picker/src/model.tsx';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { usePrevious } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import { filterOptionByLabelAndFilterValues } from '../../custom-format/filter-option/index.tsx';
import messages from './messages.tsx';
import SelectWithAnalytics from './select-with-analytics/index.tsx';
import type { SelectWithFooterProps } from './select-with-footer/index.tsx';
import {
	OptionWrapper,
	FailedFetchOptionWrapper,
	FlexSpan,
	ImgSpan,
	ErrorMessageWrapper,
	defaultSelectStyles,
	issueFieldSelectStyles,
} from './styled.tsx';
import type {
	SelectValueShape,
	ServerSuggestions,
	Option,
	Options,
	FetchSuggestions,
	OnChangeMulti,
	Group,
} from './types.tsx';
import { mergeReactSelectStyles } from './utils.tsx';

type ErrorMessagePosition = 'top' | 'bottom';

// type is not provided by react-select types atm
export type FormatOptionLabelMeta = {
	context: 'menu' | 'value';
	inputValue: string;
	selectValue: SelectValueShape | SelectValueShape[] | null | undefined;
};

export type FormatOptionLabel = (arg1: Option, arg2: FormatOptionLabelMeta) => ReactElement;

type Value = SelectValueShape | SelectValueShape[] | null | undefined;

export type Props = {
	isOpen?: boolean;
	isMulti?: boolean;
	isInvalid?: boolean;
	isClearable?: AkSelectProps<Option>['isClearable'];
	hasAutocomplete?: boolean;
	canCreateNewItem?: boolean;
} & SelectWithFooterProps & {
		fieldId: string;
		value?: Value;
		fetchSuggestions: FetchSuggestions;
		additionalAnalyticsProps?: {
			isEpicLink?: boolean;
			source?: string;
			isRemote?: boolean;
		};
		ariaLabel?: string;
		debounceFetchSuggestionsTime?: number;
		placeholder?: AkSelectProps<Option>['placeholder'];
		noOptionsMessage?: AkSelectProps<Option>['noOptionsMessage'];
		loadingMessage?: AkSelectProps<Option>['loadingMessage'];
		styles?: AkSelectProps<Option>['styles'];
		formatCreateLabel?: AkSelectProps<Option>['formatCreateLabel'];
		filterOption?: AkSelectProps<Option>['filterOption'];
		autoFocus?: AkSelectProps<Option>['autoFocus'];
		classNamePrefix?: AkSelectProps<Option>['classNamePrefix'];
		invalidMessage?: ReactNode;
		portalElement?: HTMLElement | null | undefined;
		changeQueryValue?: (arg1: string) => string;
		formatOptionLabel?: FormatOptionLabel;
		errorMessagePosition?: ErrorMessagePosition;
		initialData?: {
			data: ServerSuggestions;
			error: boolean;
			loading: boolean;
		};
		components?:
			| undefined
			| {
					Menu: ReactNode;
			  };
		componentsProps?:
			| undefined
			| {
					isChecked: boolean;
					onChange: () => undefined | Promise<undefined>;
			  };
		inputId?: string;
		optionValuesSafeForAnalytics?: boolean;
		onInputChange?: AkSelectProps<Option>['onInputChange'];
		onMenuClose?: AkSelectProps<Option>['onMenuClose'];
		onMenuOpen?: AkSelectProps<Option>['onMenuOpen'];
		onChange?: (arg1: SelectValueShape | null, arg2: ActionMeta<Option>) => void;
		onChangeMulti?: OnChangeMulti;
		onDataRequest?: (arg1: { isInitial: boolean }) => void;
		onDataLoaded?: (arg1: { isInitial: boolean }) => void;
	};

export const defaultSelectProps = {
	hideSelectedOptions: true,
	allowCreateWhileLoading: true,
	filterOption: filterOptionByLabelAndFilterValues,
} as const;

const transformToOption = (item: SelectValueShape): Option => ({ label: item.content, ...item });

const transformFromOption = (option: Option, actionMeta: ActionMeta<Option>): SelectValueShape => {
	const { label, filterValues, ...optionValueProperties } = option;

	const result = {
		...optionValueProperties,
	};

	if (actionMeta.action === 'create-option' && result.content === undefined) {
		result.content = option.label;
	}

	return result;
};

export const SelectFieldView = (props: Props) => {
	di(window);
	const { formatMessage } = useIntl();

	const {
		isMulti = false,
		value = null,
		debounceFetchSuggestionsTime = 0,
		filterOption = defaultSelectProps.filterOption,
		errorMessagePosition = 'top',
		onChangeMulti = noop,
		onChange = noop,
		onInputChange = noop,
		onDataRequest,
		onDataLoaded,
		fetchSuggestions,
		additionalAnalyticsProps,
		initialData,
		changeQueryValue,
		formatOptionLabel,
		invalidMessage,
		fieldId,
	} = props;

	const subscription = useRef<unknown>(null);
	const query$ = useRef<unknown>(null);
	const query = useRef<unknown>(null);
	const additionalAnalyticsPropsInRef = useRef(additionalAnalyticsProps);
	const fetchSuggestionsInRef = useRef(fetchSuggestions);
	const debounceFetchSuggestionsTimeInRef = useRef(debounceFetchSuggestionsTime);
	const onDataLoadedInRef = useRef(onDataLoaded);
	const onDataRequestInRef = useRef(onDataRequest);

	onDataRequestInRef.current = onDataRequest;
	onDataLoadedInRef.current = onDataLoaded;
	debounceFetchSuggestionsTimeInRef.current = debounceFetchSuggestionsTime;
	fetchSuggestionsInRef.current = fetchSuggestions;
	additionalAnalyticsPropsInRef.current = additionalAnalyticsProps;

	const previousInitialData = usePrevious(initialData);

	const transformItems = useCallback((items: SelectValueShape[]): Option[] => {
		if (items && items.length > 0) {
			return items.map(transformToOption);
		}

		return [];
	}, []);

	const transformToOptions = useCallback(
		(suggestions: ServerSuggestions): Options => {
			// A single list of Options, no groups
			if (suggestions.length === 1 && !suggestions[0].heading) {
				return transformItems(suggestions[0].items);
			}

			// Handle Option groups case
			return suggestions.map((suggestion) => {
				const options = transformItems(suggestion.items);
				return suggestion.heading !== undefined
					? {
							label: suggestion.heading,
							options,
						}
					: { options };
			});
		},
		[transformItems],
	);

	const [isLoading, setIsLoading] = useState(() => {
		if (initialData?.data) {
			return Boolean(initialData.loading);
		}
		return true;
	});
	const [hasLastFetchFailed, setHasLastFetchFailed] = useState(() => {
		if (initialData?.data) {
			return Boolean(initialData.error);
		}

		return false;
	});
	const [options, setOptions] = useState(() => {
		if (initialData?.data) {
			return transformToOptions(initialData.data);
		}

		return [];
	});

	const handleOnFocus = useCallback((focusEvent: FocusEvent<HTMLElement>, sessionId?: string) => {
		// @ts-expect-error - TS2571 - Object is of type 'unknown'.
		query$.current?.next({ query: '', sessionId, isImmediate: true });
	}, []);

	const handleOnChange = useCallback(
		(
			selectedOption: Option | Option[] | null,
			actionMeta: ActionMeta<Option>,
			sessionId?: string,
		) => {
			if (query$.current && isMulti) {
				if (!onChangeMulti) {
					return;
				}

				// @ts-expect-error - TS2571 - Object is of type 'unknown'.
				query$.current.next({ query: query.current || '', sessionId, isImmediate: true });

				const selectedOptions = Array.isArray(selectedOption)
					? selectedOption.map((item) => transformFromOption(item, actionMeta))
					: []; // `[]` is sent from Ak when user clicks Clear button, `null` is sent when they delete items one by one. Previous behavior was to return empty array in both cases. This is simpler to handle for the consumers as well.

				onChangeMulti(selectedOptions, actionMeta);
			} else {
				if (!onChange || Array.isArray(selectedOption)) {
					return;
				}

				onChange(selectedOption && transformFromOption(selectedOption, actionMeta), actionMeta);
			}
		},
		[isMulti, onChange, onChangeMulti],
	);

	const handleOnInputChange = useCallback(
		(inputValue: string, actionMeta: ActionMeta<Option | Group>, sessionId?: string) => {
			// This prevents us setting the selected value to nothing when the select loses focus. Previous implementation (via onFilterChange) did not call props.onFilterChange() in other cases
			if (actionMeta.action !== 'input-change' && actionMeta.action !== 'set-value') {
				return;
			}

			let lQuery = inputValue;
			if (changeQueryValue) {
				lQuery = changeQueryValue(inputValue);
			}

			query.current = lQuery;

			if (actionMeta.action === 'input-change') {
				// do not fetch when user selected a value
				setIsLoading(true);
				// need to do this here, otherwise loading state will be applied only after a debounce timeout, causing jank
				// @ts-expect-error - TS2571 - Object is of type 'unknown'.
				query$.current?.next({ query: lQuery, sessionId });
			}

			onInputChange?.(lQuery, actionMeta);
		},
		[changeQueryValue, onInputChange],
	);

	const getSelectedValue = useCallback((): Option | Option[] | null => {
		if (!value) return null;

		if (Array.isArray(value)) {
			return value.map(transformToOption);
		}
		return transformToOption(value);
	}, [value]);

	const doFormatOptionLabel = useCallback(
		(option: Option, meta: FormatOptionLabelMeta) => {
			if (formatOptionLabel !== undefined) {
				return formatOptionLabel(option, meta);
			}

			if (!option.iconUrl) {
				return option.label;
			}

			return (
				<OptionWrapper>
					<ImgSpan>
						<img
							src={option.iconUrl}
							alt=""
							width={2 * gridSize}
							height={2 * gridSize}
							role="none"
						/>
					</ImgSpan>
					<FlexSpan>{option.label}</FlexSpan>
				</OptionWrapper>
			);
		},
		[formatOptionLabel],
	);

	// To run only once during init,
	const failedFetchMessage = useMemo(
		() => (
			<FailedFetchOptionWrapper>
				<span>
					{formatMessage(messages.failedFetch)} {/* aks my rules */}
					{/* eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage */}
					<a role="button" href={window.location.href} onClick={() => window.location.reload()}>
						{formatMessage(
							fg('jira-issue-terminology-refresh-m3')
								? messages.reloadIssueIssueTermRefresh
								: messages.reloadIssue,
						)}
					</a>
				</span>
			</FailedFetchOptionWrapper>
		),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	// Translation for the constructor code.
	// Will only run once at the time on intialisation
	// `constructor memo translation`
	useMemo(() => {
		query$.current = new Subject();
		// @ts-expect-error - TS2571 - Object is of type 'unknown'.
		subscription.current = query$.current
			// @ts-expect-error - TS7031 - Binding element 'isImmediate' implicitly has an 'any' type.
			.debounce(({ isImmediate }) =>
				isImmediate
					? Observable.empty<never>()
					: Observable.timer(debounceFetchSuggestionsTimeInRef.current || 0),
			)
			// @ts-expect-error - TS7031 - Binding element 'lQuery' implicitly has an 'any' type. | TS7031 - Binding element 'sessionId' implicitly has an 'any' type.
			.switchMap(({ query: lQuery, sessionId }) => {
				onDataRequestInRef.current?.({ isInitial: query.current === null });

				setIsLoading(true);
				const fetchPromise = fetchSuggestionsInRef.current(lQuery, sessionId || undefined);

				return fetchPromise
					.then((suggestions: ServerSuggestions) => {
						setHasLastFetchFailed(false);

						return suggestions;
					})
					.catch((error) => {
						log.safeErrorWithoutCustomerData(
							'issue.select.transform-suggestions',
							'Could not fetch or transform suggestions to display in select',
							error,
						);

						additionalAnalyticsPropsInRef.current &&
							log.safeErrorWithoutCustomerData(
								'issue.select.fetch-transform-suggestions.details',
								'Could not fetch or transform retrieved data',
								{
									...additionalAnalyticsPropsInRef.current,
									message: error.message,
								},
							);

						fireErrorAnalytics({
							meta: {
								id: 'issueSelectFetchFailure',
								packageName: 'jiraIssueInternalFieldSelect',
							},
							error,
							attributes: {
								...(additionalAnalyticsPropsInRef.current ?? {}),
							},
						});

						setHasLastFetchFailed(true);
						// Generate an option containing the error message if the suggestions fetch fails
						return [
							{
								items: [
									{
										content: failedFetchMessage,
										value: '',
										isDisabled: true,
									},
								],
							},
						] as const;
					});
			})
			.subscribe((suggestions: ServerSuggestions) => {
				setOptions(transformToOptions(suggestions));
				setIsLoading(false);
				onDataLoadedInRef.current?.({ isInitial: query.current === null });
			});
	}, [failedFetchMessage, transformToOptions]);

	const doFilterOption = useCallback(
		(option: OptionToFilter) => {
			if (hasLastFetchFailed) {
				// If the fetch has failed, we will show a disabled option to indicate the error to users
				return true;
			}
			// at least need to ignore inputValue/query change to "" on "input-blur" - not to reset list to all items before closing the menu (causes flickr)
			// @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'string'.
			return filterOption?.(option, query.current || '');
		},
		[filterOption, hasLastFetchFailed],
	);

	// component did update translation
	useEffect(() => {
		if (initialData?.data && previousInitialData?.data) {
			const { loading, error, data } = initialData;

			if (loading !== previousInitialData.loading || error !== previousInitialData.error) {
				setIsLoading(loading);
				setHasLastFetchFailed(error);
				setOptions(transformToOptions(data));
			}
		}
	}, [
		initialData,
		previousInitialData?.data,
		previousInitialData?.error,
		previousInitialData?.loading,
		transformToOptions,
	]);

	// Component did unmount translation
	useEffect(
		() => () => {
			// @ts-expect-error - TS2571 - Object is of type 'unknown'.
			subscription.current?.unsubscribe();
		},
		[],
	);

	const selectField = useMemo(() => {
		const {
			placeholder: lPlaceholder = '', // undefined will show non-i18n "Select..." text
			noOptionsMessage = () => formatMessage(messages.empty),
			loadingMessage = () => formatMessage(pickerMessages.loading),
			fieldId: lFieldId,
			onChangeMulti: lOnChangeMulti,
			invalidMessage: lInvalidMessage,
			isOpen,
			isInvalid,
			hasAutocomplete,
			portalElement,
			fetchSuggestions: lFetchSuggestions,
			debounceFetchSuggestionsTime: lDebounceFetchSuggestionsTime,
			changeQueryValue: lChangeQueryValue,
			errorMessagePosition: lErrorMessagePosition,
			ariaLabel: lAriaLabel = '',
			spacing = 'compact',
			...selectProps
		} = props;

		const validationState = invalidMessage ? 'error' : undefined;

		return (
			<SelectWithAnalytics
				{...defaultSelectProps}
				{...selectProps}
				filterOption={doFilterOption}
				key={fieldId}
				fieldId={fieldId}
				options={options}
				value={getSelectedValue()}
				styles={mergeReactSelectStyles(defaultSelectStyles, issueFieldSelectStyles, props.styles)}
				isLoading={isLoading}
				isSearchable={hasAutocomplete}
				isInvalid={isInvalid}
				menuIsOpen={isOpen}
				menuPortalTarget={portalElement}
				menuPlacement="auto"
				placeholder={lPlaceholder}
				noOptionsMessage={noOptionsMessage}
				loadingMessage={loadingMessage}
				validationState={validationState}
				formatOptionLabel={doFormatOptionLabel}
				// @ts-expect-error - TS2322 - Type '(selectedOption: Option | Option[] | null, actionMeta: ActionMeta<Option>, sessionId?: string | undefined) => void' is not assignable to type '((selectedOption: Option | readonly Option[] | null, arg2: ActionMeta<Option>) => void) & ((selectedOption: Option | Option[] | null, actionMeta: ActionMeta<...>, sessionId?: string | undefined) => void)'.
				onChange={handleOnChange}
				onFocus={handleOnFocus}
				onInputChange={handleOnInputChange}
				aria-label={lAriaLabel}
				spacing={spacing}
			/>
		);
	}, [
		doFilterOption,
		doFormatOptionLabel,
		fieldId,
		formatMessage,
		getSelectedValue,
		handleOnChange,
		handleOnFocus,
		handleOnInputChange,
		invalidMessage,
		isLoading,
		options,
		props,
	]);

	const errorMessage = useMemo(
		() =>
			invalidMessage ? (
				<ErrorMessageWrapper
					key={`${fieldId}-error-message`}
					errorMessagePosition={errorMessagePosition}
				>
					<ErrorMessage>{invalidMessage}</ErrorMessage>
				</ErrorMessageWrapper>
			) : null,
		[errorMessagePosition, fieldId, invalidMessage],
	);

	const components = [selectField];
	errorMessagePosition === 'top'
		? // @ts-expect-error - TS2345 - Argument of type 'Element | null' is not assignable to parameter of type 'Element'.
			components.unshift(errorMessage)
		: // @ts-expect-error - TS2345 - Argument of type 'Element | null' is not assignable to parameter of type 'Element'.
			components.push(errorMessage);

	return <>{components}</>;
};

export default SelectFieldView;
