import React, { type FC, memo, useMemo } from 'react';
import { Box, xcss, Text } from '@atlaskit/primitives';
import SectionMessage, { SectionMessageAction } from '@atlaskit/section-message';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import {
	isAttachmentNumberExceedLimit,
	ATTACHMENTS_LEARN_ABOUT_LINK,
} from '@atlassian/jira-issue-attachments-limit/src/common/utils.tsx';
import messages from './messages.tsx';

type Props = {
	totalCount: number;
};

export const MemoExceedWarning: FC<Props> = memo(({ totalCount }) => {
	const { formatMessage } = useIntl();
	const isExceedLimit = useMemo(() => {
		return isAttachmentNumberExceedLimit(totalCount);
	}, [totalCount]);
	if (isExceedLimit) {
		return (
			<Box xcss={wrapperStyles}>
				<SectionMessage
					appearance="warning"
					actions={
						<SectionMessageAction href={ATTACHMENTS_LEARN_ABOUT_LINK}>
							{formatMessage(messages.exceedWarningLinkLabel)}
						</SectionMessageAction>
					}
				>
					<Text as="p">
						{formatMessage(
							fg('jira-issue-terminology-refresh-m3')
								? messages.exceedWarningLabelIssueTermRefresh
								: messages.exceedWarningLabel,
						)}
					</Text>
				</SectionMessage>
			</Box>
		);
	}
	return null;
});

export default MemoExceedWarning;

const wrapperStyles = xcss({
	marginTop: 'space.150',
	marginBottom: 'space.100',
});
