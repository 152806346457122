/* eslint-disable @atlassian/relay/unused-fields */
import React, { useCallback, type ComponentPropsWithoutRef } from 'react';
import { useFragment, graphql, useMutation } from 'react-relay';
import { defineMessages } from '@atlassian/jira-intl';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useFieldOptionsOverrides } from '@atlassian/jira-issue-field-base/src/services/field-config-service/context.tsx';
import type { CheckboxValueItemShape } from '@atlassian/jira-issue-internal-fields/src/checkbox-select/checkbox-field-view.tsx';
import { CheckboxInlineEditView } from '@atlassian/jira-issue-internal-fields/src/checkbox-select/checkbox-inline-edit-view.tsx';
import { filterAllowedValues } from '@atlassian/jira-issue-view-base/src/common/utils/filter-select-allowed-values.tsx';
import { genericMessages } from '@atlassian/jira-issue-view-common-constants/src/context-items-messages.tsx';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button/index.tsx';
import { getDisplayName } from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-field.tsx';
import {
	useConnectRelayField,
	type ConnectedLayoutProps,
	type PropsCallback,
} from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-relay-field.tsx';
import { ConnectedRelayFieldWrapper } from '@atlassian/jira-issue-view-common-views/src/connect-field/relay-field/field-wrapper.tsx';
import type {
	ui_issueViewLayoutCheckboxSelectField_IssueViewCheckboxSelectField$key,
	ui_issueViewLayoutCheckboxSelectField_IssueViewCheckboxSelectField$data,
} from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutCheckboxSelectField_IssueViewCheckboxSelectField.graphql';
import type { ui_issueViewLayoutCheckboxSelectField_IssueViewCheckboxSelectField_Mutation } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutCheckboxSelectField_IssueViewCheckboxSelectField_Mutation.graphql';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';

// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction
const messages = defineMessages({
	placeholder: {
		id: 'issue.checkbox-select.text-for-dropdown-when-nothing-selected',
		defaultMessage: 'Select {fieldName}',
		description: '',
	},
});

export type AggItemValue = {
	id: string;
	value: string;
};

const toComponentValueShape = (fieldData: AggItemValue): CheckboxValueItemShape => ({
	label: fieldData.value,
	value: fieldData.id,
});

type ComponentValueShape = CheckboxValueItemShape[] | undefined;
type AggValueShape = Pick<
	ui_issueViewLayoutCheckboxSelectField_IssueViewCheckboxSelectField$data,
	'selectedOptions' | 'fieldOptions'
>;

function transformComponentValueToAggShape(
	componentValue: ComponentValueShape,
	fieldOptions: ui_issueViewLayoutCheckboxSelectField_IssueViewCheckboxSelectField$data['fieldOptions'],
): AggValueShape {
	return {
		selectedOptions: {
			edges:
				componentValue?.map((option) => ({
					// Find the field option with the same id as the selected value
					//
					// This is the only way to reliably retrieve the optionId, which is required by
					// transformAggCheckboxToLegacyField and isn't available in the component value
					node: fieldOptions?.edges?.find((fieldOption) => fieldOption?.node?.id === option.value)
						?.node,
				})) ?? [],
		},
		fieldOptions,
	};
}

type AdditionalProps = Pick<
	ComponentPropsWithoutRef<typeof CheckboxInlineEditView>,
	'options' | 'placeholder' | 'noValueText' | 'showPinButton'
>;

export type IssueViewCheckboxSelectFieldProps =
	ConnectedLayoutProps<ui_issueViewLayoutCheckboxSelectField_IssueViewCheckboxSelectField$key>;

export function IssueViewCheckboxSelectField(props: IssueViewCheckboxSelectFieldProps) {
	const data = useFragment<ui_issueViewLayoutCheckboxSelectField_IssueViewCheckboxSelectField$key>(
		graphql`
			fragment ui_issueViewLayoutCheckboxSelectField_IssueViewCheckboxSelectField on JiraCheckboxesField
			@argumentDefinitions(
				isIssueViewFieldConfigEditEnabled: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/relay-migration-issue-fields-custom-field-config.relayprovider"
				}
			) {
				id
				fieldId
				type
				name
				description
				__typename

				fieldConfig {
					isEditable
				}

				fieldOperations @include(if: $isIssueViewFieldConfigEditEnabled) {
					canEdit
				}

				selectedOptions {
					edges {
						node {
							# id and value are required by the component, optionId is required by transformAggCheckboxToLegacyField
							id @required(action: NONE)
							optionId @required(action: NONE)
							value @required(action: NONE)
						}
					}
				}

				fieldOptions {
					edges {
						node {
							# id and value are required by the component, optionId is required by transformAggCheckboxToLegacyField
							id @required(action: NONE)
							optionId @required(action: NONE)
							value @required(action: NONE)
							isDisabled
						}
					}
				}
			}
		`,
		props.fragmentKey,
	);

	const [commit] =
		useMutation<ui_issueViewLayoutCheckboxSelectField_IssueViewCheckboxSelectField_Mutation>(
			graphql`
				mutation ui_issueViewLayoutCheckboxSelectField_IssueViewCheckboxSelectField_Mutation(
					$input: JiraUpdateCheckboxesFieldInput!
				) @raw_response_type {
					jira {
						updateCheckboxesField(input: $input) @optIn(to: "JiraIssueFieldMutations") {
							success
							errors {
								message
							}
							field {
								selectedOptions {
									edges {
										node {
											id @required(action: NONE)
											optionId @required(action: NONE)
											value @required(action: NONE)
										}
									}
								}
								fieldOptions {
									edges {
										node {
											id @required(action: NONE)
											optionId @required(action: NONE)
											value @required(action: NONE)
											isDisabled
										}
									}
								}
							}
						}
					}
				}
			`,
		);

	const issueKey = useIssueKey();
	const [allowedValuesOverrides] = useFieldOptionsOverrides({
		issueKey,
		fieldKey: data.fieldId,
	});

	const getComponentProps = useCallback<
		PropsCallback<
			ui_issueViewLayoutCheckboxSelectField_IssueViewCheckboxSelectField$key,
			ui_issueViewLayoutCheckboxSelectField_IssueViewCheckboxSelectField$data,
			ComponentValueShape,
			AggValueShape,
			AdditionalProps
		>
	>(
		({ intl, softRefreshCallbacks }) => {
			// Manually filter out options in the FE based on the allowedValuesOverrides.
			//
			// Ideally, this would be done by passing the filter to the fieldOptions query,
			// but there's no guarantee that allowedValuesOverrides will be loaded when this
			// component mounts, and the existing CheckboxInlineEditView relies on FE filtering
			// in Redux anyway, so we can just filter it here in the same way.
			const options = filterAllowedValues(
				mapNodes(data.fieldOptions).filter((option) => option.isDisabled !== true),
				allowedValuesOverrides ?? undefined,
			).map((option) => toComponentValueShape(option));

			return {
				jiraIssueField: data,

				value: mapNodes(data.selectedOptions).map((option) => toComponentValueShape(option)),

				onValueConfirm(componentValue) {
					const newAggValue = transformComponentValueToAggShape(componentValue, data.fieldOptions);
					softRefreshCallbacks.onSubmit(newAggValue);

					commit({
						variables: {
							input: {
								id: data.id,
								operations: [
									{
										operation: 'SET',
										ids: componentValue?.map(({ value }) => value) ?? [],
									},
								],
							},
						},
						onCompleted: (mutationData) => {
							if (mutationData.jira?.updateCheckboxesField?.success) {
								softRefreshCallbacks.onSubmitSucceeded(newAggValue);
							} else {
								softRefreshCallbacks.onSubmitFailed();
							}
						},
						onError() {
							softRefreshCallbacks.onSubmitFailed();
						},
						optimisticResponse: {
							jira: {
								updateCheckboxesField: {
									success: true,
									errors: [],
									field: {
										id: data.id,
										...newAggValue,
									},
								},
							},
						},
					});
				},

				additionalProps: {
					options,
					placeholder: intl.formatMessage(messages.placeholder, {
						fieldName: data.name,
					}),
					noValueText: intl.formatMessage(genericMessages.noValue),
					showPinButton: getShowPinButton(props.area),
					showEditIcon: data.fieldOperations?.canEdit,
				},
			};
		},
		[allowedValuesOverrides, commit, data, props.area],
	);

	const connectField = useConnectRelayField(props, data, getComponentProps);
	const componentName = getDisplayName(CheckboxInlineEditView);

	return (
		<UFOSegment name="issue-field-checkbox">
			<ConnectedRelayFieldWrapper componentName={componentName}>
				<CheckboxInlineEditView {...connectField.componentProps} />
			</ConnectedRelayFieldWrapper>
		</UFOSegment>
	);
}

type Nullable<T> = T | null | undefined;

type Connection<TNode> = {
	readonly edges?: Nullable<
		ReadonlyArray<
			Nullable<{
				readonly node?: Nullable<TNode>;
			}>
		>
	>;
} | null;

function mapNodes<TNode>(conn: Nullable<Connection<TNode>>): TNode[] {
	const nodes: TNode[] = [];

	return (
		conn?.edges?.reduce((acc, edge) => {
			if (edge?.node) {
				acc.push(edge.node);
			}

			return acc;
		}, nodes) ?? []
	);
}
