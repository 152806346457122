import React, { useMemo } from 'react';
import OpenIcon from '@atlaskit/icon/glyph/open';
import {
	ROUTE_NAMES_SOFTWARE_DEPLOYMENTS_CLASSIC,
	ROUTE_NAMES_SOFTWARE_DEPLOYMENTS,
} from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { SummaryItem } from '@atlassian/jira-development-summary-common/src/ui/summary-item/index.tsx';
import type { RouterLinkConfig } from '@atlassian/jira-development-summary-common/src/ui/summary-item/types.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useFeatures as useNextGenFeatures } from '@atlassian/jira-navigation-apps-sidebar-next-gen-use-features/src/controllers/features/index.tsx';
import { useFeatures as useClassicFeatures } from '@atlassian/jira-navigation-apps-sidebar-software-classic/src/controllers/features/index.tsx';
import { useRouteByName } from '@atlassian/jira-platform-router-utils/src/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useProjectContext } from '@atlassian/jira-providers-project-context/src/index.tsx';
import type { ProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { useRouter } from '@atlassian/react-resource-router';
import messages from './messages.tsx';

const DeploymentsViewLinkSummaryItem = ({
	href,
	routerLinkConfig,
}: {
	href?: string;
	routerLinkConfig?: RouterLinkConfig;
}) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	// Memoizing to prevent SummaryItem animations firing on hover caused by react-resource-router prefetch triggering a re-render
	const secondaryData = useMemo(() => <></>, []);

	return (
		<SummaryItem
			title={formatMessage(messages.deploymentsLink)}
			linkFormatting
			icon={<OpenIcon label="" />}
			href={href}
			routerLinkConfig={routerLinkConfig}
			secondaryData={secondaryData}
			onClick={() => {
				fireUIAnalytics(
					createAnalyticsEvent({}),
					'link clicked',
					'releasesGlanceDeploymentsViewLink',
				);
			}}
			data-testid="development-summary-deployments-view-link.summary-item"
		/>
	);
};

const ClassicDeploymentsViewLink = ({ projectKey }: { projectKey: ProjectKey }) => {
	const route = useRouteByName(ROUTE_NAMES_SOFTWARE_DEPLOYMENTS_CLASSIC);

	// check if deployments jira feature is enabled in CMP
	const { data, loading } = useClassicFeatures();
	if (loading || !data?.hasDeployments) return null;

	return route !== null ? (
		<DeploymentsViewLinkSummaryItem
			routerLinkConfig={{ route, prefetch: 'hover', params: { projectKey } }}
		/>
	) : (
		<DeploymentsViewLinkSummaryItem href={`/jira/software/c/projects/${projectKey}/deployments`} />
	);
};

const NextGenDeploymentsViewLink = ({ projectKey }: { projectKey: ProjectKey }) => {
	const route = useRouteByName(ROUTE_NAMES_SOFTWARE_DEPLOYMENTS);

	// check if deployments jira feature is enabled in TMP
	const { data, loading } = useNextGenFeatures();
	if (loading || !data?.hasDeployments) return null;

	return route !== null ? (
		<DeploymentsViewLinkSummaryItem
			routerLinkConfig={{ route, prefetch: 'hover', params: { projectKey } }}
		/>
	) : (
		<DeploymentsViewLinkSummaryItem href={`/jira/software/projects/${projectKey}/deployments`} />
	);
};

export const DeploymentsViewLink = () => {
	const { data: project } = useProjectContext();

	// Check if user is currently already on the Deployment page, hence button display not required
	const [{ route }] = useRouter();

	const routeName = route.name;
	const isOnDeploymentsPage =
		routeName === ROUTE_NAMES_SOFTWARE_DEPLOYMENTS ||
		routeName === ROUTE_NAMES_SOFTWARE_DEPLOYMENTS_CLASSIC;

	if (isOnDeploymentsPage || project == null) return null;

	return project.simplified ? (
		<NextGenDeploymentsViewLink projectKey={project.projectKey} />
	) : (
		<ClassicDeploymentsViewLink projectKey={project.projectKey} />
	);
};
