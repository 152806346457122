import type { CustomerServiceCustomDetailsEntityType } from '@atlassian/jira-relay/src/__generated__/tableCustomDetailsQuery.graphql';
import { EDIT_DETAILS_HASH } from '@atlassian/jira-servicedesk-organizations-contacts-common/src/common/constants.tsx';

export const getProfileUrl = (
	projectKey: string,
	context: CustomerServiceCustomDetailsEntityType,
	id: string,
) =>
	`/jira/servicedesk/projects/${projectKey}/${
		context === 'CUSTOMER' ? 'customers' : 'organizations'
	}/${id}`;

export const getEditDetailsUrl = (
	projectKey: string,
	context: CustomerServiceCustomDetailsEntityType,
	id: string,
) => `${getProfileUrl(projectKey, context, id)}${EDIT_DETAILS_HASH}`;

export const getEditDetailsUrlByEntityType = (
	projectKey: string,
	entityType: CustomerServiceCustomDetailsEntityType,
	id?: string,
) => {
	switch (entityType) {
		case 'CUSTOMER':
			return `/jira/servicedesk/projects/${projectKey}/customers/${id}${EDIT_DETAILS_HASH}`;
		case 'ORGANIZATION':
			return `/jira/servicedesk/projects/${projectKey}/organizations/${id}${EDIT_DETAILS_HASH}`;
		case 'ENTITLEMENT':
			return `/jira/servicedesk/projects/${projectKey}/products${EDIT_DETAILS_HASH}`;
		default:
			return '';
	}
};
