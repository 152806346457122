import {
	SET_VALUE_ARRAY_SIZE_LIMIT,
	baseScopesRequiredPerField,
} from '@atlassian/jira-issue-adjustments/src/common.tsx';
import { isNotEmptyString } from '../utils/is-not-empty-string/index.tsx';

export type ComponentsPublicSetValue = string[];

export type ComponentsPublicGetValues = {
	id: string;
	name: string;
}[];

export const validateComponentsSetValue = (value: unknown): value is ComponentsPublicSetValue =>
	Array.isArray(value) &&
	value.length <= SET_VALUE_ARRAY_SIZE_LIMIT &&
	value.every(isNotEmptyString);

export const componentsRequiredScopes = {
	...baseScopesRequiredPerField,
	granular: [
		'read:project.component:jira',
		...baseScopesRequiredPerField.granular,
		'write:field.default-value:jira',
	],
};
