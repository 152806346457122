/**
 * @generated SignedSource<<cda66031fd3f02e5d8d03c07e0828210>>
 * @relayHash 024826e606590cbf3572a039284a0811
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID e111fbbd52dcd7b6d9a340d42a40235ffa8b6fe65b4b7c5e719d8fd1286421c2

import type { ConcreteRequest, Query } from 'relay-runtime';
export type uiGroupPickerFieldSearchRefetchQuery$variables = {
  after?: string | null | undefined;
  first: number;
  id: string;
  searchBy: string;
};
export type uiGroupPickerFieldSearchRefetchQuery$data = {
  readonly node: {
    readonly groups?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly groupId: string;
          readonly id: string;
          readonly name: string;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type uiGroupPickerFieldSearchRefetchQuery = {
  response: uiGroupPickerFieldSearchRefetchQuery$data;
  variables: uiGroupPickerFieldSearchRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchBy"
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v5 = {
  "kind": "Variable",
  "name": "searchBy",
  "variableName": "searchBy"
},
v6 = {
  "kind": "ScalarField",
  "name": "id"
},
v7 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v8 = [
  {
    "concreteType": "JiraGroupEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "concreteType": "JiraGroup",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          {
            "kind": "ScalarField",
            "name": "name"
          },
          {
            "kind": "ScalarField",
            "name": "groupId"
          },
          (v7/*: any*/)
        ]
      },
      {
        "kind": "ScalarField",
        "name": "cursor"
      }
    ]
  },
  {
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "name": "endCursor"
      },
      {
        "kind": "ScalarField",
        "name": "hasNextPage"
      }
    ]
  }
],
v9 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  (v5/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "name": "uiGroupPickerFieldSearchRefetchQuery",
    "selections": [
      {
        "args": (v4/*: any*/),
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": "groups",
                "args": [
                  (v5/*: any*/)
                ],
                "concreteType": "JiraGroupConnection",
                "kind": "LinkedField",
                "name": "__groupPicker_issueFieldGroupPicker_groups_connection",
                "plural": false,
                "selections": (v8/*: any*/)
              }
            ],
            "type": "JiraSingleGroupPickerField"
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "uiGroupPickerFieldSearchRefetchQuery",
    "selections": [
      {
        "args": (v4/*: any*/),
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": (v9/*: any*/),
                "concreteType": "JiraGroupConnection",
                "kind": "LinkedField",
                "name": "groups",
                "plural": false,
                "selections": (v8/*: any*/)
              },
              {
                "args": (v9/*: any*/),
                "filters": [
                  "searchBy"
                ],
                "handle": "connection",
                "key": "groupPicker_issueFieldGroupPicker_groups",
                "kind": "LinkedHandle",
                "name": "groups"
              }
            ],
            "type": "JiraSingleGroupPickerField"
          },
          (v6/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "id": "e111fbbd52dcd7b6d9a340d42a40235ffa8b6fe65b4b7c5e719d8fd1286421c2",
    "metadata": {
      "connection": [
        {
          "count": "first",
          "cursor": "after",
          "direction": "forward",
          "path": [
            "node",
            "groups"
          ]
        }
      ]
    },
    "name": "uiGroupPickerFieldSearchRefetchQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "259e786475e7e77fd8cd534942991ac7";

export default node;
