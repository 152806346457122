/**
 * @generated SignedSource<<53b8a068a359283acd10e01a066694e0>>
 * @relayHash 1fa04742645fc628264ccada8ffe90a3
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID f4b267c1054c9c8b62435530871c2db879a6fd9d0a1debb12c0b384f64029369

import type { ConcreteRequest, Query } from 'relay-runtime';
export type JiraDevOpsIssuePanelBannerType = "ISSUE_KEY_ONBOARDING" | "%future added value";
export type JiraDevOpsIssuePanelState = "DEV_SUMMARY" | "HIDDEN" | "NOT_CONNECTED" | "%future added value";
export type JiraPullRequestState = "DECLINED" | "DRAFT" | "MERGED" | "OPEN" | "%future added value";
export type JiraReviewState = "APPROVAL" | "CLOSED" | "DEAD" | "DRAFT" | "REJECTED" | "REVIEW" | "SUMMARIZE" | "UNKNOWN" | "%future added value";
export type servicesMainJiraDevSummaryDevInfoSummaryQuery$variables = {
  issueId: string;
};
export type servicesMainJiraDevSummaryDevInfoSummaryQuery$data = {
  readonly jira: {
    readonly devOps: {
      readonly devOpsIssuePanel: {
        readonly __id: string;
        readonly devOpsIssuePanelBanner: JiraDevOpsIssuePanelBannerType | null | undefined;
        readonly devSummaryResult: {
          readonly configErrors: ReadonlyArray<{
            readonly instance: {
              readonly baseUrl: string | null | undefined;
              readonly name: string | null | undefined;
              readonly type: string | null | undefined;
            } | null | undefined;
            readonly message: string | null | undefined;
          }> | null | undefined;
          readonly devSummary: {
            readonly branch: {
              readonly overall: {
                readonly count: number | null | undefined;
                readonly lastUpdated: AGG$DateTime | null | undefined;
              };
              readonly summaryByProvider: ReadonlyArray<{
                readonly count: number | null | undefined;
                readonly name: string | null | undefined;
                readonly providerId: string | null | undefined;
              }>;
            };
            readonly build: {
              readonly overall: {
                readonly count: number | null | undefined;
                readonly failedBuildCount: number | null | undefined;
                readonly lastUpdated: AGG$DateTime | null | undefined;
                readonly successfulBuildCount: number | null | undefined;
                readonly unknownBuildCount: number | null | undefined;
              };
              readonly summaryByProvider: ReadonlyArray<{
                readonly count: number | null | undefined;
                readonly name: string | null | undefined;
                readonly providerId: string | null | undefined;
              }>;
            };
            readonly commit: {
              readonly overall: {
                readonly count: number | null | undefined;
                readonly lastUpdated: AGG$DateTime | null | undefined;
              };
              readonly summaryByProvider: ReadonlyArray<{
                readonly count: number | null | undefined;
                readonly name: string | null | undefined;
                readonly providerId: string | null | undefined;
              }>;
            };
            readonly deploymentEnvironments: {
              readonly overall: {
                readonly count: number | null | undefined;
                readonly topEnvironments: ReadonlyArray<{
                  readonly title: string | null | undefined;
                }>;
              };
              readonly summaryByProvider: ReadonlyArray<{
                readonly count: number | null | undefined;
                readonly name: string | null | undefined;
                readonly providerId: string | null | undefined;
              }>;
            };
            readonly pullrequest: {
              readonly overall: {
                readonly count: number | null | undefined;
                readonly lastUpdated: AGG$DateTime | null | undefined;
                readonly open: boolean | null | undefined;
                readonly state: JiraPullRequestState | null | undefined;
                readonly stateCount: number | null | undefined;
              };
              readonly summaryByProvider: ReadonlyArray<{
                readonly count: number | null | undefined;
                readonly name: string | null | undefined;
                readonly providerId: string | null | undefined;
              }>;
            };
            readonly review: {
              readonly overall: {
                readonly count: number | null | undefined;
                readonly lastUpdated: AGG$DateTime | null | undefined;
                readonly state: JiraReviewState | null | undefined;
                readonly stateCount: number | null | undefined;
              };
              readonly summaryByProvider: ReadonlyArray<{
                readonly count: number | null | undefined;
                readonly name: string | null | undefined;
                readonly providerId: string | null | undefined;
              }>;
            };
          };
          readonly errors: ReadonlyArray<{
            readonly instance: {
              readonly baseUrl: string | null | undefined;
              readonly name: string | null | undefined;
              readonly type: string | null | undefined;
            } | null | undefined;
            readonly message: string | null | undefined;
          }> | null | undefined;
        } | null | undefined;
        readonly hasBranchCapabilities: boolean | null | undefined;
        readonly panelState: JiraDevOpsIssuePanelState | null | undefined;
      };
    };
  };
};
export type servicesMainJiraDevSummaryDevInfoSummaryQuery = {
  response: servicesMainJiraDevSummaryDevInfoSummaryQuery$data;
  variables: servicesMainJiraDevSummaryDevInfoSummaryQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "issueId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "issueId",
    "variableName": "issueId"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "hasBranchCapabilities"
},
v3 = {
  "kind": "ScalarField",
  "name": "panelState"
},
v4 = {
  "kind": "ScalarField",
  "name": "devOpsIssuePanelBanner"
},
v5 = {
  "kind": "ScalarField",
  "name": "count"
},
v6 = {
  "kind": "ScalarField",
  "name": "lastUpdated"
},
v7 = [
  (v5/*: any*/),
  (v6/*: any*/)
],
v8 = {
  "concreteType": "JiraIssueBranchDevSummary",
  "kind": "LinkedField",
  "name": "overall",
  "plural": false,
  "selections": (v7/*: any*/)
},
v9 = {
  "kind": "ScalarField",
  "name": "name"
},
v10 = {
  "concreteType": "JiraIssueDevSummaryByProvider",
  "kind": "LinkedField",
  "name": "summaryByProvider",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "providerId"
    },
    (v9/*: any*/),
    (v5/*: any*/)
  ]
},
v11 = {
  "concreteType": "JiraIssueCommitDevSummary",
  "kind": "LinkedField",
  "name": "overall",
  "plural": false,
  "selections": (v7/*: any*/)
},
v12 = {
  "kind": "ScalarField",
  "name": "state"
},
v13 = {
  "kind": "ScalarField",
  "name": "stateCount"
},
v14 = {
  "concreteType": "JiraIssuePullRequestDevSummary",
  "kind": "LinkedField",
  "name": "overall",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    (v6/*: any*/),
    (v12/*: any*/),
    (v13/*: any*/),
    {
      "kind": "ScalarField",
      "name": "open"
    }
  ]
},
v15 = {
  "concreteType": "JiraIssueBuildDevSummary",
  "kind": "LinkedField",
  "name": "overall",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    (v6/*: any*/),
    {
      "kind": "ScalarField",
      "name": "failedBuildCount"
    },
    {
      "kind": "ScalarField",
      "name": "successfulBuildCount"
    },
    {
      "kind": "ScalarField",
      "name": "unknownBuildCount"
    }
  ]
},
v16 = {
  "concreteType": "JiraIssueReviewDevSummary",
  "kind": "LinkedField",
  "name": "overall",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    (v6/*: any*/),
    (v12/*: any*/),
    (v13/*: any*/)
  ]
},
v17 = {
  "concreteType": "JiraIssueDeploymentEnvironment",
  "kind": "LinkedField",
  "name": "topEnvironments",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "title"
    }
  ]
},
v18 = [
  {
    "kind": "ScalarField",
    "name": "message"
  },
  {
    "concreteType": "JiraIssueDevSummaryErrorProviderInstance",
    "kind": "LinkedField",
    "name": "instance",
    "plural": false,
    "selections": [
      (v9/*: any*/),
      {
        "kind": "ScalarField",
        "name": "type"
      },
      {
        "kind": "ScalarField",
        "name": "baseUrl"
      }
    ]
  }
],
v19 = {
  "concreteType": "JiraIssueDevSummaryError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": (v18/*: any*/)
},
v20 = {
  "concreteType": "JiraIssueDevSummaryError",
  "kind": "LinkedField",
  "name": "configErrors",
  "plural": true,
  "selections": (v18/*: any*/)
},
v21 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "servicesMainJiraDevSummaryDevInfoSummaryQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "concreteType": "JiraQuery",
          "kind": "LinkedField",
          "name": "jira",
          "plural": false,
          "selections": [
            {
              "kind": "RequiredField",
              "field": {
                "concreteType": "JiraDevOpsQuery",
                "kind": "LinkedField",
                "name": "devOps",
                "plural": false,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": {
                      "args": (v1/*: any*/),
                      "concreteType": "JiraDevOpsIssuePanel",
                      "kind": "LinkedField",
                      "name": "devOpsIssuePanel",
                      "plural": false,
                      "selections": [
                        (v2/*: any*/),
                        (v3/*: any*/),
                        (v4/*: any*/),
                        {
                          "concreteType": "JiraIssueDevSummaryResult",
                          "kind": "LinkedField",
                          "name": "devSummaryResult",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "RequiredField",
                              "field": {
                                "concreteType": "JiraIssueDevSummary",
                                "kind": "LinkedField",
                                "name": "devSummary",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "RequiredField",
                                    "field": {
                                      "concreteType": "JiraIssueBranchDevSummaryContainer",
                                      "kind": "LinkedField",
                                      "name": "branch",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "kind": "RequiredField",
                                          "field": (v8/*: any*/),
                                          "action": "THROW",
                                          "path": "jira.devOps.devOpsIssuePanel.devSummaryResult.devSummary.branch.overall"
                                        },
                                        {
                                          "kind": "RequiredField",
                                          "field": (v10/*: any*/),
                                          "action": "THROW",
                                          "path": "jira.devOps.devOpsIssuePanel.devSummaryResult.devSummary.branch.summaryByProvider"
                                        }
                                      ]
                                    },
                                    "action": "THROW",
                                    "path": "jira.devOps.devOpsIssuePanel.devSummaryResult.devSummary.branch"
                                  },
                                  {
                                    "kind": "RequiredField",
                                    "field": {
                                      "concreteType": "JiraIssueCommitDevSummaryContainer",
                                      "kind": "LinkedField",
                                      "name": "commit",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "kind": "RequiredField",
                                          "field": (v11/*: any*/),
                                          "action": "THROW",
                                          "path": "jira.devOps.devOpsIssuePanel.devSummaryResult.devSummary.commit.overall"
                                        },
                                        {
                                          "kind": "RequiredField",
                                          "field": (v10/*: any*/),
                                          "action": "THROW",
                                          "path": "jira.devOps.devOpsIssuePanel.devSummaryResult.devSummary.commit.summaryByProvider"
                                        }
                                      ]
                                    },
                                    "action": "THROW",
                                    "path": "jira.devOps.devOpsIssuePanel.devSummaryResult.devSummary.commit"
                                  },
                                  {
                                    "kind": "RequiredField",
                                    "field": {
                                      "concreteType": "JiraIssuePullRequestDevSummaryContainer",
                                      "kind": "LinkedField",
                                      "name": "pullrequest",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "kind": "RequiredField",
                                          "field": (v14/*: any*/),
                                          "action": "THROW",
                                          "path": "jira.devOps.devOpsIssuePanel.devSummaryResult.devSummary.pullrequest.overall"
                                        },
                                        {
                                          "kind": "RequiredField",
                                          "field": (v10/*: any*/),
                                          "action": "THROW",
                                          "path": "jira.devOps.devOpsIssuePanel.devSummaryResult.devSummary.pullrequest.summaryByProvider"
                                        }
                                      ]
                                    },
                                    "action": "THROW",
                                    "path": "jira.devOps.devOpsIssuePanel.devSummaryResult.devSummary.pullrequest"
                                  },
                                  {
                                    "kind": "RequiredField",
                                    "field": {
                                      "concreteType": "JiraIssueBuildDevSummaryContainer",
                                      "kind": "LinkedField",
                                      "name": "build",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "kind": "RequiredField",
                                          "field": (v15/*: any*/),
                                          "action": "THROW",
                                          "path": "jira.devOps.devOpsIssuePanel.devSummaryResult.devSummary.build.overall"
                                        },
                                        {
                                          "kind": "RequiredField",
                                          "field": (v10/*: any*/),
                                          "action": "THROW",
                                          "path": "jira.devOps.devOpsIssuePanel.devSummaryResult.devSummary.build.summaryByProvider"
                                        }
                                      ]
                                    },
                                    "action": "THROW",
                                    "path": "jira.devOps.devOpsIssuePanel.devSummaryResult.devSummary.build"
                                  },
                                  {
                                    "kind": "RequiredField",
                                    "field": {
                                      "concreteType": "JiraIssueReviewDevSummaryContainer",
                                      "kind": "LinkedField",
                                      "name": "review",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "kind": "RequiredField",
                                          "field": (v16/*: any*/),
                                          "action": "THROW",
                                          "path": "jira.devOps.devOpsIssuePanel.devSummaryResult.devSummary.review.overall"
                                        },
                                        {
                                          "kind": "RequiredField",
                                          "field": (v10/*: any*/),
                                          "action": "THROW",
                                          "path": "jira.devOps.devOpsIssuePanel.devSummaryResult.devSummary.review.summaryByProvider"
                                        }
                                      ]
                                    },
                                    "action": "THROW",
                                    "path": "jira.devOps.devOpsIssuePanel.devSummaryResult.devSummary.review"
                                  },
                                  {
                                    "kind": "RequiredField",
                                    "field": {
                                      "concreteType": "JiraIssueDeploymentEnvironmentDevSummaryContainer",
                                      "kind": "LinkedField",
                                      "name": "deploymentEnvironments",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "kind": "RequiredField",
                                          "field": {
                                            "concreteType": "JiraIssueDeploymentEnvironmentDevSummary",
                                            "kind": "LinkedField",
                                            "name": "overall",
                                            "plural": false,
                                            "selections": [
                                              (v5/*: any*/),
                                              {
                                                "kind": "RequiredField",
                                                "field": (v17/*: any*/),
                                                "action": "THROW",
                                                "path": "jira.devOps.devOpsIssuePanel.devSummaryResult.devSummary.deploymentEnvironments.overall.topEnvironments"
                                              }
                                            ]
                                          },
                                          "action": "THROW",
                                          "path": "jira.devOps.devOpsIssuePanel.devSummaryResult.devSummary.deploymentEnvironments.overall"
                                        },
                                        {
                                          "kind": "RequiredField",
                                          "field": (v10/*: any*/),
                                          "action": "THROW",
                                          "path": "jira.devOps.devOpsIssuePanel.devSummaryResult.devSummary.deploymentEnvironments.summaryByProvider"
                                        }
                                      ]
                                    },
                                    "action": "THROW",
                                    "path": "jira.devOps.devOpsIssuePanel.devSummaryResult.devSummary.deploymentEnvironments"
                                  }
                                ]
                              },
                              "action": "THROW",
                              "path": "jira.devOps.devOpsIssuePanel.devSummaryResult.devSummary"
                            },
                            (v19/*: any*/),
                            (v20/*: any*/)
                          ]
                        },
                        (v21/*: any*/)
                      ]
                    },
                    "action": "THROW",
                    "path": "jira.devOps.devOpsIssuePanel"
                  }
                ]
              },
              "action": "THROW",
              "path": "jira.devOps"
            }
          ]
        },
        "action": "THROW",
        "path": "jira"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "servicesMainJiraDevSummaryDevInfoSummaryQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "concreteType": "JiraDevOpsQuery",
            "kind": "LinkedField",
            "name": "devOps",
            "plural": false,
            "selections": [
              {
                "args": (v1/*: any*/),
                "concreteType": "JiraDevOpsIssuePanel",
                "kind": "LinkedField",
                "name": "devOpsIssuePanel",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "concreteType": "JiraIssueDevSummaryResult",
                    "kind": "LinkedField",
                    "name": "devSummaryResult",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraIssueDevSummary",
                        "kind": "LinkedField",
                        "name": "devSummary",
                        "plural": false,
                        "selections": [
                          {
                            "concreteType": "JiraIssueBranchDevSummaryContainer",
                            "kind": "LinkedField",
                            "name": "branch",
                            "plural": false,
                            "selections": [
                              (v8/*: any*/),
                              (v10/*: any*/)
                            ]
                          },
                          {
                            "concreteType": "JiraIssueCommitDevSummaryContainer",
                            "kind": "LinkedField",
                            "name": "commit",
                            "plural": false,
                            "selections": [
                              (v11/*: any*/),
                              (v10/*: any*/)
                            ]
                          },
                          {
                            "concreteType": "JiraIssuePullRequestDevSummaryContainer",
                            "kind": "LinkedField",
                            "name": "pullrequest",
                            "plural": false,
                            "selections": [
                              (v14/*: any*/),
                              (v10/*: any*/)
                            ]
                          },
                          {
                            "concreteType": "JiraIssueBuildDevSummaryContainer",
                            "kind": "LinkedField",
                            "name": "build",
                            "plural": false,
                            "selections": [
                              (v15/*: any*/),
                              (v10/*: any*/)
                            ]
                          },
                          {
                            "concreteType": "JiraIssueReviewDevSummaryContainer",
                            "kind": "LinkedField",
                            "name": "review",
                            "plural": false,
                            "selections": [
                              (v16/*: any*/),
                              (v10/*: any*/)
                            ]
                          },
                          {
                            "concreteType": "JiraIssueDeploymentEnvironmentDevSummaryContainer",
                            "kind": "LinkedField",
                            "name": "deploymentEnvironments",
                            "plural": false,
                            "selections": [
                              {
                                "concreteType": "JiraIssueDeploymentEnvironmentDevSummary",
                                "kind": "LinkedField",
                                "name": "overall",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/),
                                  (v17/*: any*/)
                                ]
                              },
                              (v10/*: any*/)
                            ]
                          }
                        ]
                      },
                      (v19/*: any*/),
                      (v20/*: any*/)
                    ]
                  },
                  (v21/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "f4b267c1054c9c8b62435530871c2db879a6fd9d0a1debb12c0b384f64029369",
    "metadata": {},
    "name": "servicesMainJiraDevSummaryDevInfoSummaryQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "9ab7eb465e7cc94e7d42163b251e7887";

export default node;
