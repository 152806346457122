import React, { type ReactNode, useCallback, useMemo } from 'react';
import { styled } from '@compiled/react';
import { Text } from '@atlaskit/primitives';
import { N500 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import {
	dismissFlag as createDismissFlag,
	type FlagComponentProps,
	SuccessFlag,
	useFlagService,
} from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import type { Invitees } from '../../common/types.tsx';
import {
	type Product,
	clampString,
	generateId,
	getProductDisplayName,
} from '../../utils/index.tsx';
import { messages } from './messages.tsx';

export const useInviteSuccessFlag = () => {
	const { showFlag, dismissFlag } = useFlagService();
	return useCallback(
		({
			users,
			product,
			requireAdminApproval,
			isAIOLIV5,
		}: {
			users: Invitees;
			product: Product;
			requireAdminApproval: boolean;
			isAIOLIV5: boolean;
		}) => {
			const id = `success-${generateId()}`;
			const dismiss = () => dismissFlag(createDismissFlag(id));

			showFlag({
				id,
				render: (props) => (
					<InviteSuccessFlag
						{...props}
						id={id}
						users={users}
						onDismiss={dismiss}
						product={product}
						requireAdminApproval={requireAdminApproval}
						isAIOLIV5={isAIOLIV5}
					/>
				),
			});
		},
		[dismissFlag, showFlag],
	);
};

export type Props = {
	id: FlagComponentProps['id'];
	onDismiss: FlagComponentProps['onDismissed'];
	users: Invitees;
	product: Product;
	requireAdminApproval?: boolean;
	isAIOLIV5?: boolean;
};

export const InviteSuccessFlag = ({
	users,
	onDismiss,
	product,
	requireAdminApproval,
	isAIOLIV5,
	...props
}: Props) => {
	const { formatMessage } = useIntl();

	const maxLength = 25;
	const invitedEmail = clampString(users[0]?.email || users[0].name, maxLength);

	const productDisplayName = getProductDisplayName(product);

	type SuccessFlagProps = React.ComponentProps<typeof SuccessFlag>;

	const customFlagFooterProps = useMemo(
		(): Pick<SuccessFlagProps, 'actions' | 'linkComponent'> => ({
			actions: [
				{
					content: '',
					// Replace with lodash/noop
					// eslint-disable-next-line @typescript-eslint/no-empty-function
					onClick: () => {},
				},
			],
			linkComponent: () =>
				requireAdminApproval && (
					<FooterParagraph>
						{formatMessage(
							isAIOLIV5 ? messages.additionalInfoGenericV5 : messages.additionalInfoGeneric,
							{
								strong: (...chunks: ReactNode[]) => <Text as="strong">{chunks.join('')}</Text>,
								productDisplayName,
							},
						)}
					</FooterParagraph>
				),
		}),
		[formatMessage, isAIOLIV5, requireAdminApproval, productDisplayName],
	);

	return (
		<SuccessFlag
			{...props}
			{...customFlagFooterProps}
			onDismissed={onDismiss}
			title={formatMessage(messages.title, {
				inviteeCount: users.length,
			})}
			description={
				<p>
					{formatMessage(isAIOLIV5 ? messages.descriptionGenericV5 : messages.descriptionGeneric, {
						strong: (...chunks: ReactNode[]) => <Text as="strong">{chunks.join('')}</Text>,
						inviteeCount: users.length,
						email: invitedEmail,
						productDisplayName,
					})}
				</p>
			}
		/>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FooterParagraph = styled.p({
	color: token('color.text.subtle', N500),
	transform: 'translateX(2px)',
});
