import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import type { FieldConfigServiceActions } from '@atlassian/jira-issue-field-base/src/services/field-config-service/types.tsx';
import type { FieldValueServiceActions } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import type { FieldsState } from '@atlassian/jira-issue-shared-types/src/common/types/field-type.tsx';
import type { ProjectContext } from '@atlassian/jira-issue-shared-types/src/common/types/project-type.tsx';
import {
	EPIC_ISSUES,
	SUBTASKS,
	CHILDREN_ISSUES,
	PORTFOLIO_CHILD_ISSUES,
} from '@atlassian/jira-issue-view-configurations/src/index.tsx';
import { HIERARCHY_LEVEL_ABOVE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import {
	extractSubtasksOrPortfolioChildrenIssues,
	extractChildrenIssues,
	extractFromObject,
} from './utils.tsx';

export const storeFields = (
	fields: FieldsState,
	fieldValueActions: FieldValueServiceActions,
	fieldConfigActions: FieldConfigServiceActions,
	issueKey: IssueKey,
	project: ProjectContext | null | undefined = null,
	allowMergeFieldConfig = false,
) => {
	const { setIssue, mergeIssues, mergeIssue, getFieldValue } = fieldValueActions;
	const { setIssueConfig, mergeIssueConfig } = fieldConfigActions;
	const { fieldValues, fieldConfigs } = extractFromObject(fields);

	const childrenIssues = fieldValues[CHILDREN_ISSUES] || [];
	const epicIssues = fieldValues[EPIC_ISSUES] || [];
	const subtaskIssues = fieldValues[SUBTASKS] || [];
	const portfolioChildIssues = fieldValues[PORTFOLIO_CHILD_ISSUES] || [];
	const hierarchyLevelAboveFieldInState = getFieldValue(issueKey, HIERARCHY_LEVEL_ABOVE);

	const hasChildrenIssuesField = fieldValues[CHILDREN_ISSUES];
	const hasEpicIssuesField = fieldValues[EPIC_ISSUES];
	const hasSubtasksIssuesField = fieldValues[SUBTASKS];
	const hasPortfolioIssuesField = fieldValues[PORTFOLIO_CHILD_ISSUES];

	const { issuesFieldsValues: childrenIssueFieldsValues, childrenKeyList: modifiedChildrenList } =
		extractChildrenIssues(childrenIssues);
	const { issuesFieldsValues: epicIssueFieldValues, childrenKeyList: modifiedEpicChildrenList } =
		extractChildrenIssues(epicIssues);
	const { issuesFieldsValues: subtaskFieldValues, childrenKeyList: modifiedSubtaskChildrenList } =
		extractSubtasksOrPortfolioChildrenIssues(subtaskIssues);
	const {
		issuesFieldsValues: portfolioFieldValues,
		childrenKeyList: modifiedPortfolioChildrenList,
	} = extractSubtasksOrPortfolioChildrenIssues(portfolioChildIssues);

	// Modify the field values of (children-issue, epic-children) to use list with issue key to prevent
	// consumers from using children field values from parent key
	const modifiedFieldValues = {
		...fieldValues,
		...(hasChildrenIssuesField && {
			[CHILDREN_ISSUES]: modifiedChildrenList,
		}),
		...(hasEpicIssuesField && {
			[EPIC_ISSUES]: modifiedEpicChildrenList,
		}),
		...(hasSubtasksIssuesField && {
			[SUBTASKS]: modifiedSubtaskChildrenList,
		}),
		...(hasPortfolioIssuesField && {
			[PORTFOLIO_CHILD_ISSUES]: modifiedPortfolioChildrenList,
		}),
	};

	// This check is necessary for UIF CMP project to work correctly with cards displaying epic lozenges.
	// It's a temporary solution and it's aiming to unblock UIF rollout.
	// We should eventually clean this up in favour of finding a proper fix to an existing problem explained in here:
	// https://hello.atlassian.net/browse/FEBA-471
	if (
		!project?.isSimplified &&
		project?.projectType === SOFTWARE_PROJECT &&
		hierarchyLevelAboveFieldInState
	) {
		mergeIssue(issueKey, modifiedFieldValues);
	} else {
		setIssue(issueKey, modifiedFieldValues);
	}

	if (allowMergeFieldConfig) {
		mergeIssueConfig(issueKey, fieldConfigs);
	} else {
		setIssueConfig(issueKey, fieldConfigs);
	}

	// As epic children issues, subtasks, portfolio children currently do not provide us all fields,
	// unlike children-issues, we do mergeIssues
	// This is to solve the edge cases where if we first land on an epic children issue which contains all field values
	// and then navigate to its parent. This will still allow us to keep all the field values for the child issue instead
	// of overwriting it with the uncompleted fields.
	mergeIssues(epicIssueFieldValues);
	mergeIssues(subtaskFieldValues);
	mergeIssues(portfolioFieldValues);
	mergeIssues(childrenIssueFieldsValues);
};
