import get from 'lodash/get';
import { useEditField } from '@atlassian/jira-issue-field-base/src/services/edit-field-service/main.tsx';
import { useFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import type {
	Props,
	RadioOption,
	RadioSelectIdShape,
	RadioSelectIdShapeResponse,
} from './types.tsx';

export const useRadioSelectCustomField = ({
	issueKey,
	fieldKey,
	initialValue = null,
	onFailure,
	onSuccess,
}: Props) => {
	const [fieldConfig] = useFieldConfig(issueKey, fieldKey);
	const fieldType = get(fieldConfig.value, 'schema.type', 'option');
	const options = fieldConfig.value?.allowedValues ?? [];

	const [{ value, error }, { saveValue, resetError, saveById }] = useEditField<
		RadioOption | null,
		null,
		RadioSelectIdShapeResponse,
		RadioSelectIdShape
	>({
		fieldKey,
		issueKey,
		fieldType,
		initialValue,
		onSuccess,
		onFailure,
	});

	return [
		{
			value,
			error,
			fieldConfig,
			options,
		},
		{ saveValue, resetError, saveById },
	] as const;
};
