import { Observable } from 'rxjs/Observable';

import 'rxjs/add/observable/throw';

import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/delay';
import 'rxjs/add/operator/take';
import 'rxjs/add/operator/concat';

const DEFAULT_RETRY_DELAY = 5000;
const DEFAULT_RETRY_ATTEMPTS = 3;

type RetryOptions = {
	delay: number; // A delay between retries in ms,
	take: number; // The number of retries to take
};

/**
 * Customizable retry strategy.
 *
 * Performs a number retries with a delay. Returns last error if all retries fail.
 * May take an optional scheduler, otherwise the default scheduler is used
 */
export const retryStrategy = <T,>(
	observable: Observable<T>,
	options: RetryOptions,
	// @ts-expect-error - TS2304 - Cannot find name 'rxjs$SchedulerClass'.
	scheduler?: rxjs$SchedulerClass,
): Observable<T> =>
	observable
		.delay(options.delay, scheduler || undefined)
		.take(options.take)
		.concat(observable.flatMap((error) => Observable.throw(error)));

/**
 * A generic retry strategy.
 *
 * Perform 3 retries, with a delay of 5 seconds after each failed attempt.
 * If all 3 retries fail, return an observable with the last error.
 */
export const genericRetryStrategy = <T,>(observable: Observable<T>): Observable<T> =>
	retryStrategy(observable, { delay: DEFAULT_RETRY_DELAY, take: DEFAULT_RETRY_ATTEMPTS });
