import { useCallback } from 'react';
import {
	type UIAnalyticsEvent,
	fireUIAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import type { Attributes } from './types.tsx';

export const useIssueTransitionUIAnalyticsEvent = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const fireUIEvent = useCallback(
		({
			analyticsEvent,
			actionSubjectId,
			action = 'clicked',
			actionSubject,
			attributes = {},
		}: {
			analyticsEvent?: UIAnalyticsEvent;
			actionSubjectId: string;
			action?: string;
			actionSubject?: string;
			attributes?: Attributes;
		}) => {
			if (analyticsEvent !== undefined)
				fireUIAnalytics(analyticsEvent, actionSubjectId, attributes);
			else {
				const createdAnalyticsEvent = createAnalyticsEvent({ action, actionSubject });
				fireUIAnalytics(createdAnalyticsEvent, actionSubjectId, attributes);
			}
		},
		[createAnalyticsEvent],
	);

	return { fireUIEvent };
};
