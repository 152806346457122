import type { IssueConfiguration } from '@atlassian/jira-issue-field-base/src/services/field-config-service/types.tsx';
import type {
	IssueFields,
	IssueWithFields,
} from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import { RICH_CONTENT } from '@atlassian/jira-issue-shared-types/src/common/types/field-schema-type.tsx';
import type {
	Field,
	FieldsState,
} from '@atlassian/jira-issue-shared-types/src/common/types/field-type.tsx';
import {
	EPIC,
	PARENT,
	specialFields,
} from '@atlassian/jira-issue-view-configurations/src/index.tsx';
import {
	ISSUE_FIELD_STRING_CF_TYPE,
	DESCRIPTION_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { getFieldType } from '../fields/index.tsx';
import type {
	ExtractedChildrenIssuesData,
	ExtractedFieldData,
	ExtractedSubtasksOrPortfolioData,
} from './types.tsx';

type ChildIssue = {
	key: string;
	id: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	fields: Record<any, any>[];
	[key: string]: unknown;
};

export const extractFromObject = (fields: FieldsState): ExtractedFieldData => {
	const extractedFieldsData: ExtractedFieldData = {
		fieldValues: {},
		fieldConfigs: {},
	};

	Object.entries(fields).forEach(([key, field]) => {
		if (
			field?.schema?.custom === specialFields()[EPIC] ||
			field?.schema?.custom === specialFields()[PARENT]
		) {
			return;
		}

		const isRichTextField =
			(field.schema.type === ISSUE_FIELD_STRING_CF_TYPE &&
				field.schema.renderer === RICH_CONTENT) ||
			getFieldType(field) === DESCRIPTION_TYPE;

		extractedFieldsData.fieldValues[key] = isRichTextField
			? field.adfValue || field.value
			: field.value;

		extractedFieldsData.fieldConfigs[key] = {
			autoCompleteUrl: field.autoCompleteUrl,
			allowedValues: field.allowedValues,
			isEditable: field.editable,
			isRequired: field.required,
			schema: field.schema,
			title: field.title,
			configuration: field.configuration,
			description: field.description ?? undefined,
		};
	});

	return extractedFieldsData;
};

export const extractFromArray = (fields: Field[]): ExtractedFieldData => {
	const extractedFieldsData: ExtractedFieldData = {
		fieldValues: {},
		fieldConfigs: {},
	};

	fields.forEach((field) => {
		extractedFieldsData.fieldValues[field.key] = field.value !== undefined ? field.value : null;
		extractedFieldsData.fieldConfigs[field.key] = {
			autoCompleteUrl: field.autoCompleteUrl,
			allowedValues: field.allowedValues,
			isEditable: field.editable,
			isRequired: field.required,
			schema: field.schema,
			title: field.title,
		};
	});

	return extractedFieldsData;
};

// Backend payload for subtasks and portfolio children is different to
// epic children issues/next-gen issues, thus we have a different extractor
export const extractSubtasksOrPortfolioChildrenIssues = (
	childIssues: IssueFields[],
): ExtractedSubtasksOrPortfolioData => {
	const issuesFieldsValues: Array<IssueWithFields> = [];

	// @ts-expect-error - TS2345 - Argument of type '(childIssue: ChildIssue) => { id: string; key: string; }' is not assignable to parameter of type '(value: IssueFields, index: number, array: IssueFields[]) => { id: string; key: string; }'.
	const childrenKeyList = childIssues.map((childIssue: ChildIssue) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const fieldValue: Record<string, any> = {};
		const { fields = [], key: childIssueKey, id: childId } = childIssue;

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		Object.entries(fields).forEach(([key, value]: [any, any]) => {
			fieldValue[key] = value;
		});

		issuesFieldsValues.push({ [childIssueKey]: fieldValue });
		return {
			id: childId,
			key: childIssueKey,
		};
	});

	return { issuesFieldsValues, childrenKeyList };
};

export const extractChildrenIssues = (
	childrenIssues: IssueFields[],
): ExtractedChildrenIssuesData => {
	const issuesFieldsValues: Array<IssueWithFields> = [];
	const issueFieldsConfigurations: Array<Record<IssueKey, IssueConfiguration>> = [];
	const childrenKeyList = childrenIssues.map((child) => {
		const { fieldValues: values, fieldConfigs: configs } = extractFromArray(child.fields);

		issuesFieldsValues.push({ [child.key]: values });

		issueFieldsConfigurations.push({ [child.key]: configs });
		return {
			id: child.id,
			key: child.key,
		};
	});

	return { issuesFieldsValues, issueFieldsConfigurations, childrenKeyList };
};
