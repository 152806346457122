import type { ActionsObservable } from 'redux-observable';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/bufferTime';
import uniq from 'lodash/uniq';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import {
	PREFETCH_ISSUES,
	type PrefetchIssuesAction,
} from '@atlassian/jira-issue-view-store/src/actions/prefetch-issues-actions.tsx';
import { getDataProvider } from '@atlassian/jira-providers-issue/src/service/index.tsx';

type ReduxAction = {
	readonly type: string;
};

const bufferTimeMs = 1000;
const bufferMaxSize = 50;

const key$ = new Subject<string>();
key$.bufferTime(bufferTimeMs, bufferTimeMs, bufferMaxSize).subscribe((keysArr) => {
	getDataProvider().loadIssues(uniq(keysArr));
});

const id$ = new Subject<string>();
id$
	.bufferTime(bufferTimeMs, bufferTimeMs, bufferMaxSize)
	.subscribe((idArr) => getDataProvider().loadIssuesById(uniq(idArr)));

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<ReduxAction>) =>
	action$
		.ofType(PREFETCH_ISSUES)
		.do((action: PrefetchIssuesAction) => {
			const { issueKeys, issueIds } = action.payload;
			issueKeys.forEach((key) => key$.next(key));
			issueIds.forEach((id) => id$.next(id));
		})
		.switchMap(() => Observable.empty<never>());
