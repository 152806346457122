import supportedLocales, {
	type Locale,
} from '@atlassian/jira-common-constants/src/supported-locales.tsx';

import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import type { TimeZone } from '@atlassian/jira-shared-types/src/general.tsx';

export const FALLBACK_LOCALE = 'en-US';

/**
 * Converts JIRA style locale into standard [BCP47 format](https://tools.ietf.org/html/bcp47).
 * Non-standard but often used locales are also converted over to the correct standard one.
 *
 * For example `en` -> `en-US`, and `en-UK` -> `en-GB`
 */
export const standardizeLocale = (jiraLocale: Locale): string => {
	if (typeof jiraLocale !== 'string') {
		log.safeErrorWithoutCustomerData(
			'jira-issue-format-date.standardize-locale',
			`Invalid input type for locale was passed in: ${jiraLocale}. Falling back to ${FALLBACK_LOCALE}.`,
		);
		return FALLBACK_LOCALE;
	}

	const standardizedLocale = jiraLocale.replace('_', '-');

	switch (standardizedLocale) {
		case 'en':
			return 'en-US';
		case 'en-UK':
			return 'en-GB';
		default: {
			if (!supportedLocales.includes(jiraLocale)) {
				log.safeWarnWithoutCustomerData(
					'jira-issue-format-date.standardize-locale',
					`Discovered unsupported locale: ${jiraLocale}. This value was not modified, but its use may lead to unpredictable behaviour for formatting.`,
				);
			}

			return standardizedLocale;
		}
	}
};

/**
 * Checks if the time-zone can be used for date time formatting.
 * If it cannot be used, an error is logged and fallback value of `undefined` is used.
 *
 * For example
 *  1. `"Australia/Sydney"` -> `"Australia/Sydney"` (unchanged)
 *  2. `"IlusIV/FirstSettlement"` -> `undefined` and an error is logged
 */
export const verifyTimeZone = (timeZone?: TimeZone): TimeZone | undefined => {
	try {
		Intl.DateTimeFormat(FALLBACK_LOCALE, { timeZone });
		return timeZone;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		log.safeErrorWithoutCustomerData(
			'jira-issue-format-date.verify-time-zone',
			`Invalid timeZone: ${String(
				timeZone,
			)}. Date time formatting cannot be performed with this value. Falling back to undefined`,
			error,
		);

		return undefined;
	}
};
