/**
 * @generated SignedSource<<f97f6a831dcd66a387730fd8f4c3b499>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type header_issueViewFoundation_HeaderItemWithoutCompactMode$data = {
  readonly " $fragmentSpreads": FragmentRefs<"headerActions_issueViewFoundation_HeaderActionsView" | "headerBreadcrumbs_issueViewFoundation_HeaderBreadcrumbsView">;
  readonly " $fragmentType": "header_issueViewFoundation_HeaderItemWithoutCompactMode";
};
export type header_issueViewFoundation_HeaderItemWithoutCompactMode$key = {
  readonly " $data"?: header_issueViewFoundation_HeaderItemWithoutCompactMode$data;
  readonly " $fragmentSpreads": FragmentRefs<"header_issueViewFoundation_HeaderItemWithoutCompactMode">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsheaderactionsrelayprovider"
    },
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsparent"
    }
  ],
  "kind": "Fragment",
  "name": "header_issueViewFoundation_HeaderItemWithoutCompactMode",
  "selections": [
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsheaderactionsrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "headerActions_issueViewFoundation_HeaderActionsView"
        }
      ]
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcrelaymigrationissuefieldsparent",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "headerBreadcrumbs_issueViewFoundation_HeaderBreadcrumbsView"
        }
      ]
    }
  ],
  "type": "JiraIssue"
};

(node as any).hash = "dcd6848d4fb59cb81104368878d3bf3a";

export default node;
