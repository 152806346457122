import type { ConfluenceWhiteboard } from '@atlassian/jira-issue-shared-types/src/common/types/confluence-content-type.tsx';
import type { ConfluenceWhiteboardId } from '@atlassian/jira-shared-types/src/general.tsx';

export const DELETE_CONFLUENCE_WHITEBOARD_LINK_SUCCESS =
	'DELETE_CONFLUENCE_WHITEBOARD_LINK_SUCCESS' as const;

export const deleteConfluenceWhiteboardLinkSuccess = (id: ConfluenceWhiteboardId) => ({
	type: DELETE_CONFLUENCE_WHITEBOARD_LINK_SUCCESS,
	payload: {
		id,
	},
});

export type DeleteConfluenceWhiteboardLinkSuccess = ReturnType<
	typeof deleteConfluenceWhiteboardLinkSuccess
>;

// FETCH_CONFLUENCE_WHITEBOARD_SUCCESS
export const FETCH_CONFLUENCE_WHITEBOARD_SUCCESS = 'FETCH_CONFLUENCE_WHITEBOARD_SUCCESS' as const;

export const fetchConfluenceWhiteboardSuccess = (whiteboard: ConfluenceWhiteboard) => ({
	type: FETCH_CONFLUENCE_WHITEBOARD_SUCCESS,
	payload: { whiteboard },
});

export type FetchConfluenceWhiteboardSuccess = ReturnType<typeof fetchConfluenceWhiteboardSuccess>;

export const CONFLUENCE_WHITEBOARD_LINK_REQUEST = 'CONFLUENCE_WHITEBOARD_LINK_REQUEST' as const;

export const confluenceWhiteboardLinkRequest = () => ({
	type: CONFLUENCE_WHITEBOARD_LINK_REQUEST,
});

export type ConfluenceWhiteboardLinkRequest = ReturnType<typeof confluenceWhiteboardLinkRequest>;

export const CONFLUENCE_WHITEBOARD_LINK_REQUEST_FAILED =
	'CONFLUENCE_WHITEBOARD_LINK_REQUEST_FAILED' as const;

export const confluenceWhiteboardLinkRequestFailed = () => ({
	type: CONFLUENCE_WHITEBOARD_LINK_REQUEST_FAILED,
});

export type ConfluenceWhiteboardLinkRequestFailed = ReturnType<
	typeof confluenceWhiteboardLinkRequestFailed
>;

export type ConfluenceWhiteboardsAction =
	| DeleteConfluenceWhiteboardLinkSuccess
	| FetchConfluenceWhiteboardSuccess
	| ConfluenceWhiteboardLinkRequest
	| ConfluenceWhiteboardLinkRequestFailed;
