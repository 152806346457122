import type { InlineFieldsActions } from '../types.tsx';
import { dispatchRetryFunction, dispatchFormSubmits, resetState, setFieldState } from './main.tsx';

const actions: InlineFieldsActions = {
	setFieldState,
	resetState,
	dispatchRetryFunction,
	dispatchFormSubmits,
};

export default actions;
