export const PINNED_FIELDS_BEGIN_MARK_KEY = 'PINNED_FIELDS_BEGIN_MARK';
export const PINNED_FIELDS_END_MARK_KEY = 'PINNED_FIELDS_END_MARK';

export const DETAILS_FIELDS_BEGIN_MARK_KEY = 'DETAILS_FIELDS_BEGIN_MARK';
export const DETAILS_FIELDS_END_MARK_KEY = 'DETAILS_FIELDS_END_MARK';

export const MORE_FIELDS_BEGIN_MARK_KEY = 'MORE_FIELDS_BEGIN_MARK';
export const MORE_FIELDS_END_MARK_KEY = 'MORE_FIELDS_END_MARK';

export const ECOSYSTEM_CONTEXT_BEGIN_MARK_KEY = 'ECOSYSTEM_CONTEXT_BEGIN_MARK';
export const ECOSYSTEM_CONTEXT_END_MARK_KEY = 'ECOSYSTEM_CONTEXT_END_MARK';
