import type { ServerRestStatus } from '@atlassian/jira-issue-shared-types/src/common/types/status-type.tsx';

export const getStatusInitialValue = (fieldPersistedValue?: ServerRestStatus): ServerRestStatus => {
	if (fieldPersistedValue == null) {
		return {
			name: '',
			id: '',
			statusCategory: {
				id: 0,
				name: '',
			},
		};
	}

	return {
		name: fieldPersistedValue.name,
		id: fieldPersistedValue.id,
		statusCategory: {
			id: fieldPersistedValue.statusCategory.id,
			name: fieldPersistedValue.statusCategory.name,
		},
	};
};
