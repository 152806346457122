import parameters from '@atlassian/jira-relay/src/__generated__/ui_AddDesignButtonQuery$parameters';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

type EntryPointParams = { siteAri: string };

export const addDesignButtonEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-add-design-button" */ './src'),
	),
	getPreloadProps: ({ siteAri }: EntryPointParams) => ({
		queries: {
			designButton: {
				options: {
					fetchPolicy: 'store-and-network' as const,
				},
				parameters,
				variables: {
					siteAri,
				},
			},
		},
	}),
});
