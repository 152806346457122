import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	past: {
		id: 'sla-panel.past',
		defaultMessage: 'past',
		description: 'Time past after sla completion',
	},
	left: {
		id: 'sla-panel.left',
		defaultMessage: 'left',
		description: 'Time left for sla breach',
	},
	met: {
		id: 'sla-panel.met',
		defaultMessage: 'met',
		description: 'SLA is completed',
	},
	breached: {
		id: 'sla-panel.breached',
		defaultMessage: 'breached',
		description: 'SLA is breached',
	},
	due: {
		id: 'sla-panel.due',
		defaultMessage: 'Due',
		description: 'Due time',
	},
	today: {
		id: 'sla-panel.today',
		defaultMessage: 'Today',
		description: 'Today is breach date',
	},
	tomorrow: {
		id: 'sla-panel.tomorrow',
		defaultMessage: 'Tomorrow',
		description: 'Tomorrow is breach date',
	},
	yesterday: {
		id: 'sla-panel.yesterday',
		defaultMessage: 'Yesterday',
		description: 'Yesterday was breach date',
	},
	notApplicable: {
		id: 'sla-panel.not-applicable',
		defaultMessage: 'Breach time not found',
		description: 'This field is not found',
	},
});
