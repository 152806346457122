import React from 'react';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import useResolvedDateField from './services/main.tsx';
import { ResolvedDate } from './ui/read/main.tsx';

type Props = {
	issueKey: IssueKey;
};

export const ResolvedDateField = ({ issueKey }: Props) => {
	const [{ value: resolvedDate }] = useResolvedDateField({ issueKey });
	return <ResolvedDate date={resolvedDate} />;
};
