type Deferred = {
	resolve?: (v: unknown) => void;
	reject?: () => void;
	promise?: Promise<unknown>;
};

const defer = () => {
	const deferred: Deferred = {};
	deferred.promise = new Promise((resolve, reject) => {
		deferred.resolve = resolve;
		deferred.reject = reject;
	});
	return deferred;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function debounce<F extends (...rest: ReadonlyArray<any>) => any>(fn: F, wait = 0): F {
	let deferred: Deferred | null;
	let timer: ReturnType<typeof setTimeout>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	let lastArgs: any;
	function flush() {
		const thisDeferred = deferred;
		clearTimeout(timer);
		if (thisDeferred) {
			// @ts-expect-error - TS2683 - 'this' implicitly has type 'any' because it does not have a type annotation. | TS7005 - Variable 'lastArgs' implicitly has an 'any' type.
			// eslint-disable-next-line @typescript-eslint/no-invalid-this
			Promise.resolve(fn.apply(this, lastArgs)).then(thisDeferred.resolve, thisDeferred.reject);
		}

		lastArgs = [];
		deferred = null;
	}
	// @ts-expect-error - TS2322 - Type '(...args: readonly any[]) => any' is not assignable to type 'F'.
	return function debounced(...args) {
		if (deferred) {
			clearTimeout(timer);
		} else {
			deferred = defer();
		}

		lastArgs = args;
		// @ts-expect-error - TS2683 - 'this' implicitly has type 'any' because it does not have a type annotation.
		// eslint-disable-next-line @typescript-eslint/no-invalid-this
		timer = setTimeout(flush.bind(this), wait);

		return deferred.promise;
	};
}

export default debounce;
