import { extractProjectKey } from '@atlassian/jira-issue-fetch-services-common/src/common/utils/extract-project-key.tsx';
import { AsyncIssueCmdbObjectFieldWithBoundary } from '@atlassian/jira-issue-field-cmdb-object/src/ui/async.tsx';
import connectField from '@atlassian/jira-issue-view-common-views/src/connect-field/connect-field.tsx';
import {
	issueKeySelector,
	issueIdSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import { fieldSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector.tsx';

export default connectField((stateOnMount, { fieldId, area }) => ({
	fieldId,
	// @ts-expect-error - TS2322 - Type '(state: Readonly<{ agile: Agile; context: ContextState; entities: Readonly<{ applicationRoles?: ApplicationRole[] | undefined; cardCover: CardCover; childrenIssues: ChildrenIssuesState; ... 29 more ...; myPreferences?: Partial<...> | undefined; }>; ... 5 more ...; validators: Validators; }>) => { ...; }' is not assignable to type 'AdditionalProps<unknown>'.
	additionalProps: (state) => {
		const field = fieldSelector(fieldId)(state) || {};
		const issueKey = issueKeySelector(state);
		const issueId = issueIdSelector(state);
		return {
			area,
			issueKey,
			issueId,
			projectKey: extractProjectKey(issueKey),
			// @ts-expect-error - TS2339 - Property 'configuration' does not exist on type '{}'.
			cmdbFieldConfig: field.configuration?.cmdbFieldConfig,
			// @ts-expect-error - TS2339 - Property 'configuration' does not exist on type '{}'.
			wasInsightRequestSuccessful: field.configuration?.wasInsightRequestSuccessful,
			source: 'insight',
		};
	},
}))(AsyncIssueCmdbObjectFieldWithBoundary);
