import type { ConfluenceV1ErrorResponse, ConfluenceV2ErrorResponse } from './types.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getConfluenceApiErrorMessage = (error: any): string => {
	if (isConfluenceV1Error(error)) {
		return `${error.statusCode}: ${error.message}`;
	}

	if (isConfluenceV2Error(error)) {
		return error.errors.map((e) => `${e.status}: ${e.title}`).join('\n');
	}
	return `unknown confluence error: ${error}`;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isConfluenceV1Error = (error: any): error is ConfluenceV1ErrorResponse =>
	!!error && typeof error === 'object' && 'statusCode' in error && 'message' in error;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isConfluenceV2Error = (error: any): error is ConfluenceV2ErrorResponse =>
	!!error &&
	typeof error === 'object' &&
	Array.isArray(error.errors) &&
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	error.errors.every((e: any) => 'status' in e && 'title' in e);
