import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled,  @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const BreadCrumbsButton = styled(Button)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&&': {
		fontWeight: token('font.weight.regular'),
		// we need to add important for colour as Atlaskit recommends their default styling,
		// and they will deprecate the CustomTheme component soon + it is not very performant.
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		color: `${token('color.text.subtle', colors.N200)} !important`,
		height: '24px',
		alignItems: 'center',
	},
});
