import React from 'react';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyAfterPaint } from '@atlassian/react-loosely-lazy';
import type {
	ScheduleChangeButton as ScheduleChangeButtonType,
	ScheduleChangeButtonProps,
} from './main.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const AsyncScheduleChangeButton = lazyAfterPaint<typeof ScheduleChangeButtonType>(
	() =>
		import(/* webpackChunkName: "async-schedule-change-button" */ './main').then(
			(exportedModule) => exportedModule.ScheduleChangeButton,
		),
	{ ssr: false },
);

export const ScheduleChangeButton = (props: ScheduleChangeButtonProps) => (
	<Placeholder name="async-schedule-change-button" fallback={null}>
		<AsyncScheduleChangeButton {...props} />
	</Placeholder>
);
