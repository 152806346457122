import { FORGE_ENTITY_TYPE } from '@atlassian/jira-issue-view-common-constants/src/ecosystem-constants.tsx';
import type { Action as ForgeAction } from '@atlassian/jira-issue-view-common-types/src/forge-types.tsx';
import type { EcosystemOperation } from '@atlassian/jira-issue-view-ecosystem-service/src/services/types.tsx';
import { getConnectAction } from './connect/connect-action-view.tsx';
import { getForgeAction } from './forge/forge-action-view.tsx';

export const getEcosystemAction = (action: EcosystemOperation | ForgeAction) => {
	// @ts-expect-error - TS2339 - Property 'type' does not exist on type 'Action | EcosystemOperation'.
	if (action.type === FORGE_ENTITY_TYPE) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
		const forgeAction: ForgeAction = action as any;
		return getForgeAction(forgeAction);
	}
	// @ts-expect-error - TS2345 - Argument of type 'Action | EcosystemOperation' is not assignable to parameter of type 'EcosystemOperation'.
	return getConnectAction(action);
};
