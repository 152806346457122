import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';

export const SITE_ACCESS_LEVEL = 'SITE' as const;
export const PROJECT_ACCESS_LEVEL = 'PROJECT' as const;

export type GeneralAccessLevel = typeof SITE_ACCESS_LEVEL | typeof PROJECT_ACCESS_LEVEL;

export type SharingSettings = {
	publicSharingEnabled: boolean;
	allowIdeaSubmission: boolean;
	sharingLinkEnabled: boolean;
	showIdeaComments: boolean;
	showIdeaInsights: boolean;
	showIdeaPinnedFields: boolean;
	showIdeaAttachments: boolean;
	showViewComments: boolean;
	generalAccessLevel?: GeneralAccessLevel;
	ideaDescription: {
		fieldKey: FieldKey;
	};
};
