// Performance mark keys for approval panel.
export const MARK_APPROVAL_PANEL_LOAD_PREFIX = 'servicedesk.approval-panel-load:';
export const MARK_APPROVAL_PANEL_LOAD_START = `${MARK_APPROVAL_PANEL_LOAD_PREFIX}start`;
export const MARK_APPROVAL_PANEL_LOAD_END = `${MARK_APPROVAL_PANEL_LOAD_PREFIX}end`;

// Performance mark keys for approval glance panel.
export const MARK_APPROVAL_GLANCE_LOAD_PREFIX = 'servicedesk.approval-glance-panel-load:';
export const MARK_APPROVAL_GLANCE_LOAD_START = `${MARK_APPROVAL_GLANCE_LOAD_PREFIX}start`;
export const MARK_APPROVAL_GLANCE_LOAD_END = `${MARK_APPROVAL_GLANCE_LOAD_PREFIX}end`;

export const USER_FIELD_TYPE = 'custom_field' as const;
export const GROUP_FIELD_TYPE = 'multi_group_picker' as const;

export const ALL_CONDITION_TYPE = 'percent' as const;
export const N_PER_BAG_CONDITION_TYPE = 'numberPerPrincipal' as const;
export const NUMBER_CONDITION_TYPE = 'number' as const;
