import React, { useCallback } from 'react';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import { useIntl } from '@atlassian/jira-intl';
import { useIsIssueOfPostIncidentReviewPractice } from '@atlassian/jira-issue-field-servicedesk-practices/src/services/use-practices-field-value/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useExportPdfDialog } from '../../export-pdf-modal/controller/index.tsx';
import messages from './messages.tsx';

export const ExportIssuePDF = () => {
	const { formatMessage } = useIntl();
	const isIssueOfPIRPractice = useIsIssueOfPostIncidentReviewPractice();
	const [, { openExportPdfDialog }] = useExportPdfDialog();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const onClick = useCallback(() => {
		openExportPdfDialog();
		const analyticsEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'dropdownItem',
		});
		fireUIAnalytics(analyticsEvent, 'exportPDF');
	}, [createAnalyticsEvent, openExportPdfDialog]);

	if (!isIssueOfPIRPractice) {
		return null;
	}

	return (
		<DropdownItem key="ExportPDFIssueActionView" onClick={onClick}>
			{formatMessage(messages.exportPDF)}
		</DropdownItem>
	);
};
export default ExportIssuePDF;
