import type { TriggerPointKeyType } from '../types/index.tsx';

export const ISSUE_VIEW: TriggerPointKeyType = 'issue-transition-issue-view';
export const BOARD_CARD_TRANSITION: TriggerPointKeyType = 'issue-transition-board-card-transition';
export const ADVANCED_ROADMAPS_JIRA_TRANSITION: TriggerPointKeyType =
	'issue-transition-advanced-roadmaps-jira-transition';
export const BACKLOG_INLINE_TRANSITION: TriggerPointKeyType =
	'issue-transition-backlog-inline-transition';
export const ISSUE_VIEW_SHORTCUT_DIALOG_TRANSITION: TriggerPointKeyType =
	'issue-transition-issue-view-shortcut-dialog';
export const COMMAND_PALETTE_STATUS_FIELD_TRANSITION: TriggerPointKeyType =
	'issue-transition-command-palette-status-field-transition';
export const ISSUE_RESULTS_SHORTCUT_TRANSITION: TriggerPointKeyType =
	'issue-transition-issue-results-shortcut-transition';
export const ISSUE_TRANSITION_STATUS_FIELD_TRIGGER: TriggerPointKeyType =
	'issue-transition-status-field-trigger';
export const TRIGGER_POINT_KEY_NOT_DEFINED: TriggerPointKeyType = 'trigger-point-key-not-defined';

export const COMMENT_FIELD_ID = 'comment';
export const WORKLOG_FIELD_ID = 'worklog';
export const ISSUE_LINKS_FIELD_ID = 'issuelinks';
