import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/zip';
import 'rxjs/add/operator/takeUntil';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/switchMap';
import type { ActionsObservable, Epic } from 'redux-observable';
import startOfDay from 'date-fns/startOfDay';
import sub from 'date-fns/sub';
import { Observable } from 'rxjs/Observable';
import getVisibility$ from '@atlassian/jira-common-page-visiblity-stream/src/page-visibility-stream.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import {
	SUMMARY_ITEM_BRANCH,
	SUMMARY_ITEM_COMMIT,
	SUMMARY_ITEM_PULLREQUEST,
	SUMMARY_ITEM_BUILD,
} from '@atlassian/jira-development-summary-common/src/common/constants.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fireOperationalAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { Summary, Build } from '../../../common/types.tsx';
import { devPanelTimeToLoad } from '../../../common/utils.tsx';
import { fetchCreateBranchTargets } from '../../../services/branch-targets/index.tsx';
import {
	query as fetchDetails,
	getHasDevelopmentDataInProject,
} from '../../../services/details/index.tsx';
import { fetchDevSummaryData } from '../../../services/summary/index.tsx';
import {
	isJiraAdminSelector,
	isSiteAdminSelector,
	issueAriSelector,
	issueIdSelector,
	projectAriSelector,
} from '../../context/selectors/index.tsx';
import type { State } from '../../types.tsx';
import {
	fetchDevPanelRequest,
	fetchDevPanelSuccess,
	fetchDevPanelFailure,
	fetchDevPanelUrlDetailsSuccess,
	FETCH_DEV_PANEL_REQUEST,
	FETCH_DEV_PANEL_SUCCESS,
	DEV_PANEL_UNMOUNTED,
} from '../actions/index.tsx';
import type { Action } from '../types.tsx';

export const getUpdatedFromDate = () =>
	startOfDay(
		sub(new Date(), {
			weeks: 6,
		}),
	).toISOString();

export const fetchDevPanel: Epic<Action, State> = (
	action$: ActionsObservable<Action>,
	store: MiddlewareAPI<State>,
) =>
	action$.ofType(FETCH_DEV_PANEL_REQUEST).switchMap(({ analyticsEvent }) => {
		devPanelTimeToLoad.start();

		const state = store.getState();
		const issueAri = issueAriSelector(state);
		const issueId = issueIdSelector(state);
		const projectAri = projectAriSelector(state);
		const updatedFrom = getUpdatedFromDate();
		const isJiraAdmin = isJiraAdminSelector(state);
		const isSiteAdmin = isSiteAdminSelector(state);

		return Observable.zip(
			fetchCreateBranchTargets(issueId),
			fetchDevSummaryData(issueAri),
			getHasDevelopmentDataInProject(projectAri, updatedFrom, isJiraAdmin, isSiteAdmin),
			(
				createBranchTargets,
				{ summary, bannerType, hasBranchCapabilities, panelState },
				hasDevelopmentDataInProject,
			) =>
				fetchDevPanelSuccess(
					{
						summary: summary || undefined,
						panelState: panelState || undefined,
						bannerType: bannerType || undefined,
						hasBranchCapabilities: !!hasBranchCapabilities,
						createBranchTargets,
						hasDevelopmentDataInProject,
					},
					analyticsEvent,
				),
		).catch(() => {
			log.safeWarnWithoutCustomerData('issue.dev-panel.fetch.epic', 'Failed to fetch the devPanel');
			return Observable.of(fetchDevPanelFailure(analyticsEvent));
		});
	});

// Returns non-empty buildStates list if stateCount is exactly 1. There is a specific priority order in which build states
// are checked. That order from highest priority to lowest is -> failed, success, other
// Note: if any build count priority rules are changed here, make sure to also update
// src/packages/development/summary-legacy/src/dev-panel/summary/build/build-view.js
export const getTargetBuildStatesForOneClickUrl = (summaryBuilds?: Build) => {
	let targetBuildStatesForOneClickUrl: string[];
	if (summaryBuilds?.failedBuildCount === 1) {
		targetBuildStatesForOneClickUrl = ['FAILED'];
	} else if (summaryBuilds?.failedBuildCount === 0 && summaryBuilds?.successfulBuildCount === 1) {
		targetBuildStatesForOneClickUrl = ['SUCCESSFUL'];
	} else if (
		summaryBuilds?.failedBuildCount === 0 &&
		summaryBuilds?.successfulBuildCount === 0 &&
		summaryBuilds?.count === 1
	) {
		targetBuildStatesForOneClickUrl = ['IN_PROGRESS', 'PENDING', 'CANCELLED', 'UNKNOWN'];
	} else {
		// empty means that the build summary item will show a count greater than 1. So don't look for url matching target build state.
		targetBuildStatesForOneClickUrl = [];
	}
	return targetBuildStatesForOneClickUrl;
};
export const shouldFetchDevDetails = (summary: Summary) => {
	const branchesCount = summary[SUMMARY_ITEM_BRANCH]?.count ?? 0;
	const branchesStatisfied = branchesCount >= 1;

	return (
		branchesStatisfied ||
		summary[SUMMARY_ITEM_COMMIT]?.count === 1 ||
		summary[SUMMARY_ITEM_PULLREQUEST]?.stateCount === 1 ||
		getTargetBuildStatesForOneClickUrl(summary[SUMMARY_ITEM_BUILD]).length !== 0
	);
};

export const enrichDevPanelWithOneClickLinks: Epic<Action, State> = (
	action$: ActionsObservable<Action>,
	store: MiddlewareAPI<State>,
) =>
	action$.ofType(FETCH_DEV_PANEL_SUCCESS).switchMap(({ payload: { summary }, analyticsEvent }) => {
		const state = store.getState();
		const issueId = issueIdSelector(state);
		if (!summary || !shouldFetchDevDetails(summary)) {
			return Observable.empty<never>();
		}
		return fetchDetails(issueId)
			.map((details) => {
				fireOperationalAnalytics(
					// @ts-expect-error - Argument of type 'UIAnalyticsEvent | undefined' is not assignable to parameter of type 'UIAnalyticsEvent'.
					analyticsEvent,
					'developmentDetailsSwagQuery.jiraDevelopmentSummaryDevelopmentField success',
				);
				return fetchDevPanelUrlDetailsSuccess(details);
			})
			.catch((e) => {
				fireErrorAnalytics({
					error: e,
					event: analyticsEvent,
					meta: {
						id: 'developmentDetailsSwagQuery',
						packageName: 'jiraDevelopmentSummaryDevelopmentField',
						teamName: 'fusion-isotopes',
					},
					sendToPrivacyUnsafeSplunk: true,
				});

				return Observable.empty<never>();
			});
	});

export const refetchDevPanelOnPageFocus = (action$: ActionsObservable<Action>) =>
	getVisibility$()
		.takeUntil(action$.ofType(DEV_PANEL_UNMOUNTED))
		.switchMap((isVisible) => {
			if (isVisible !== true) {
				return Observable.empty<never>();
			}
			return Observable.of(fetchDevPanelRequest());
		});
