import { fg } from '@atlassian/jira-feature-gating';
import type { NullableOption } from '@atlassian/jira-issue-field-priority-editview-full/src/ui/priority/types.tsx';
import { getPriorityIdFromAri } from '@atlassian/jira-issue-view-common-utils/src/id-from-ari/index.tsx';
import type { AggJiraPriority } from './types.tsx';

export const transformOptionToAgg = (option: NullableOption): AggJiraPriority =>
	option
		? {
				id: option.value,
				iconUrl: option.iconUrl,
				name: option.label,
				priorityId: fg('one_event_rules_them_all_fg')
					? getPriorityIdFromAri(option.value)
					: option.value?.split('/')?.pop() || option.value,
			}
		: option;
