import noop from 'lodash/noop';
import { createStore, createHook, createContainer } from '@atlassian/react-sweet-state';
import { actions, type Actions } from './actions.tsx';
import type { State } from './types.tsx';

const emptyMentionEncoder = () => '';

export const initialState: State = {
	context: {
		issueAri: null,
		commentAri: null,
		projectType: null,
	},
	editor: {
		contextIdentifier: null,
		mentionProvider: null,
		getEncodedMention: emptyMentionEncoder,
		activityProvider: null,
		mediaContext: null,
		sharedAtTimestamp: null,
		sharedValue: undefined,
		onEditorExpandedFailure: noop,
		onEditorSaveFailure: noop,
		onEditorClearSharedValues: noop,
	},
	reactions: {
		isActive: false,
	},
};

export const store = createStore<State, Actions>({
	name: 'issue-comment',
	initialState,
	actions,
});

export const useContextState = createHook(store, {
	selector: (state) => state.context,
});

export const useContextStateIssueAri = createHook(store, {
	selector: (state) => ({
		issueAri: state.context.issueAri,
		commentAri: state.context.commentAri,
	}),
});

export const useEditorState = createHook(store, {
	selector: (state) => state.editor,
});

export const useReactionsState = createHook(store, {
	selector: (state) => state.reactions,
});

export const useReactionsStateIsActive = createHook(store, {
	selector: (state) => ({
		isActive: state.reactions.isActive,
	}),
});

export const useCommentActivityStoreActions = createHook(store, {
	selector: null,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CommentActivityStoreContainer = createContainer<State, Actions, Record<any, any>>(
	store,
);
