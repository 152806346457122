import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	showMore: {
		id: 'issue-history.history-items.history-item.rich-text-history-item.show-more',
		defaultMessage: 'Show more',
		description: 'Label for the button that opens the full text of an item in a history timeline',
	},
	showLess: {
		id: 'issue-history.history-items.history-item.rich-text-history-item.show-less',
		defaultMessage: 'Show less',
		description: 'Label for the button that hides the full text of an item in a history timeline',
	},
});
