import React from 'react';
import { styled } from '@compiled/react';

export type Props = {
	value: string | undefined;
};

export const ReadOnlyFieldView = ({ value }: Props) => (
	// preserving layout with non breaking space
	<Ellipsis>{value || '\u00A0'}</Ellipsis>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Ellipsis = styled.div({
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});
