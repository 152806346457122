import React from 'react';
import Tooltip from '@atlaskit/tooltip';
import type { FeatureFlagRolloutSummary } from '@atlassian/jira-development-common/src/model/releases-data.tsx';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

/**
 * @returns a tootlip message for indicating the current state of a single enabled flag
 */
const SingleFlagIsOnTooltip = (rolloutSummary?: FeatureFlagRolloutSummary) => {
	const { formatMessage } = useIntl();

	if (rolloutSummary) {
		if (rolloutSummary.text !== null || rolloutSummary.text !== undefined) {
			return formatMessage(messages.rolloutText, { text: rolloutSummary.text });
		}
		if (rolloutSummary.percentage != null && rolloutSummary.percentage > 0) {
			return formatMessage(messages.rolloutPercentage, {
				percentage: rolloutSummary.percentage,
			});
		}
		if (rolloutSummary.rules != null && rolloutSummary.rules > 1) {
			return formatMessage(messages.rolloutMultipleRules, {
				rulesCnt: rolloutSummary.rules,
			});
		}
		return formatMessage(messages.rolloutSingleRule);
	}
	return '';
};

/**
 * @returns a tooltip message when only a single flag is associated
 */
const SingleFlagTooptip = (
	enabledFeatureFlagsCnt: number,
	rolloutSummary?: FeatureFlagRolloutSummary,
) => {
	const { formatMessage } = useIntl();

	if (enabledFeatureFlagsCnt > 0) {
		return SingleFlagIsOnTooltip(rolloutSummary);
	}
	/**
	 * @returns a tooltip message indicating the flag is off
	 */
	return formatMessage(messages.singleFeatureFlagOff);
};

/**
 * @returns a tooltip message for multiple flags
 */
const MultipleFlagsTooltip = (enabledFeatureFlagsCnt: number, disabledFeatureFlagsCnt: number) => {
	const { formatMessage } = useIntl();
	if (enabledFeatureFlagsCnt === 0) {
		return formatMessage(messages.multipleFeatureFlagsAllOff);
	}
	if (disabledFeatureFlagsCnt === 0) {
		return formatMessage(messages.multipleFeatureFlagsAllOn);
	}
	return formatMessage(messages.multipleFeatureFlagsSomeOn, {
		onCnt: enabledFeatureFlagsCnt,
		offCnt: enabledFeatureFlagsCnt + disabledFeatureFlagsCnt,
	});
};

/**
 * @returns a tootlip message for indicating the current state based on the rollout summary
 */
const RolloutSummaryTooltip = (rolloutSummary: FeatureFlagRolloutSummary) => {
	const { formatMessage } = useIntl();
	if (rolloutSummary.text !== null && rolloutSummary.text !== undefined) {
		return formatMessage(messages.rolloutText, { text: rolloutSummary.text });
	}
	if (rolloutSummary.percentage != null && rolloutSummary.percentage > 0) {
		return formatMessage(messages.rolloutPercentage, {
			percentage: rolloutSummary.percentage,
		});
	}
	if (rolloutSummary.rules != null && rolloutSummary.rules > 1) {
		return formatMessage(messages.rolloutMultipleRules, {
			rulesCnt: rolloutSummary.rules,
		});
	}
	return formatMessage(messages.rolloutSingleRule);
};

const StatusTooltip = (props: Props) => {
	const { enabledFeatureFlagsCnt, disabledFeatureFlagsCnt, rolloutSummary } = props;
	let tooltipContent;
	if (rolloutSummary) {
		tooltipContent = RolloutSummaryTooltip(rolloutSummary);
	} else if (enabledFeatureFlagsCnt + disabledFeatureFlagsCnt === 1) {
		tooltipContent = SingleFlagTooptip(enabledFeatureFlagsCnt, rolloutSummary);
	} else {
		tooltipContent = MultipleFlagsTooltip(enabledFeatureFlagsCnt, disabledFeatureFlagsCnt);
	}

	return <Tooltip content={tooltipContent}>{props.children}</Tooltip>;
};

export default StatusTooltip;
