/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//Ukrainian (Ukraine)
export default {
  'fabric.elements.share.copied.to.clipboard.message': 'Посилання скопійовано до буфера обміну',
  'fabric.elements.share.copylink.button.text': 'Копіювати посилання',
  'fabric.elements.share.copypubliclink.button.text': 'Копіювати загальнодоступне посилання',
  'fabric.elements.share.failure.icon.label': 'Піктограма помилки',
  'fabric.elements.share.failure.message': 'Помилка надсилання',
  'fabric.elements.share.form.comment.label': 'Повідомлення (необов’язково)',
  'fabric.elements.share.form.comment.placeholder': 'Потрібно додати повідомлення?',
  'fabric.elements.share.form.info.message.no.invite.confluence':
    'Отримувачі побачать назву сторінки та ваше повідомлення',
  'fabric.elements.share.form.info.message.no.invite.jira': 'Отримувачі побачать назву проблеми та ваше повідомлення',
  'fabric.elements.share.form.no-permissions': 'У вас немає можливості поділитися.',
  'fabric.elements.share.form.public.send': 'Надіслати загальнодоступне посилання',
  'fabric.elements.share.form.public.share': 'Поділитися загальнодоступним посиланням',
  'fabric.elements.share.form.required-field.summary': 'Обов’язкові поля позначені зірочкою',
  'fabric.elements.share.form.retry': 'Повторити спробу',
  'fabric.elements.share.form.send': 'Надіслати',
  'fabric.elements.share.form.share': 'Поділитися',
  'fabric.elements.share.form.title': 'Поділитися',
  'fabric.elements.share.form.user-picker.add-more': 'Додайте більше відомостей',
  'fabric.elements.share.form.user-picker.label.confluence': 'Імена, команди, групи або адреси електронної пошти',
  'fabric.elements.share.form.user-picker.label.email-disabled-confluence': 'Імена, команди або групи',
  'fabric.elements.share.form.user-picker.label.email-disabled-jira': 'Імена або команди',
  'fabric.elements.share.form.user-picker.label.email-only': 'Адреси електронної пошти',
  'fabric.elements.share.form.user-picker.label.jira': 'Імена, команди або адреси електронної пошти',
  'fabric.elements.share.form.user-picker.no-options.existingUserOnly':
    'Не знайдено жодних результатів за запитом «{inputValue}».',
  'fabric.elements.share.form.user-picker.no-options.generic':
    'Не знайдено жодних результатів за запитом «{inputValue}». Запросіть людей за допомогою електронної адреси.',
  'fabric.elements.share.form.user-picker.placeholder.confluence': 'Наприклад: Марія, Team Orange, група 1',
  'fabric.elements.share.form.user-picker.placeholder.email-disabled-jira': 'Наприклад: Марія, Team Orange',
  'fabric.elements.share.form.user-picker.placeholder.email-only': 'Наприклад: maria@company.com',
  'fabric.elements.share.form.user-picker.placeholder.jira': 'Наприклад: Марія, Team Orange, maria@company.com',
  'fabric.elements.share.form.user-picker.validation.required':
    'Виберіть принаймні одного користувача, групу або електронну пошту.',
  'fabric.elements.share.form.user-picker.validation.required-message.confluence':
    'Виберіть принаймні одного користувача, команду, групу або адресу електронної пошти',
  'fabric.elements.share.form.user-picker.validation.required-message.email-disabled-confluence':
    'Виберіть принаймні одного користувача, команду або групу',
  'fabric.elements.share.form.user-picker.validation.required-message.email-disabled-jira':
    'Виберіть принаймні одного користувача або команду',
  'fabric.elements.share.form.user-picker.validation.required-message.email-only':
    'Виберіть принаймні одну адресу електронної пошти',
  'fabric.elements.share.form.user-picker.validation.required-message.jira':
    'Виберіть принаймні одного користувача, команду або адресу електронної пошти',
  'fabric.elements.share.in.integration.button': 'Поділитися в {integrationName}',
  'fabric.elements.share.invite.to.confluence': 'Запросити до Confluence',
  'fabric.elements.share.invite.to.jira': 'Запросити до Jira',
  'fabric.elements.share.main.tab.text.confluence': 'Поділитися сторінкою',
  'fabric.elements.share.main.tab.text.jira': 'Поділитися завданням',
  'fabric.elements.share.menu.email.label': 'Електронна пошта',
  'fabric.elements.share.menu.slack.label': 'Slack',
  'fabric.elements.share.popup.label': 'Поділитися',
  'fabric.elements.share.success.message':
    '{object, select,blogpost {Надано доступ до допису в блозі}board {Надано доступ до дошки}calendar {Надано доступ до календаря}draft {Надано доступ до чернетки}filter {Надано доступ до фільтра}issue {Надано доступ до завдання}summary {Надано доступ до підсумку}list {Надано доступ до списку}timeline {Надано доступ до шкали}form {Надано доступ до форми}media {Надано доступ до медіафайлу}page {Надано доступ до сторінки}project {Надано доступ до проєкту}pullrequest {Надано доступ до запиту на внесення змін}question {Надано доступ до запитання}report {Надано доступ до звіту}repository {Надано доступ до репозиторію}request {Надано доступ до запиту}roadmap {Надано доступ до часової шкали}site {Надано доступ до сайту}space {Надано доступ до розділу}other {Надано доступ до посилання}}',
  'fabric.elements.share.to.integration.button': 'Надіслати в {integrationName}',
  'fabric.elements.share.trigger.button.icon.label': 'Надіслати піктограму',
  'fabric.elements.share.trigger.button.invite.text': 'Запросити',
  'fabric.elements.share.trigger.button.text': 'Поділитися',
  'fabric.elements.share.trigger.button.tooltip.text': 'Поділитися',
};
