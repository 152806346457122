import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/concat';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/zip';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/mergeMap';
import type { ActionsObservable } from 'redux-observable';
import startOfDay from 'date-fns/startOfDay';
import sub from 'date-fns/sub';
import { Observable } from 'rxjs/Observable';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import getDetails, { getDeploymentDataDetected } from '../../services/details/index.tsx';
import {
	getOptOutDeploymentsDiscovery,
	setOptOutDeploymentsDiscovery,
} from '../../services/opt-out-deployments-discovery/index.tsx';
import {
	type Action,
	FETCH_DETAIL,
	setDetailData,
	fetchingFailed,
	OPT_OUT_DEPLOYMENTS_DISCOVERY,
} from '../../state/actions/details/index.tsx';
import { changeTab } from '../../state/actions/ui/index.tsx';
import type { State } from '../../state/reducers/types.tsx';
import {
	getBaseUrl,
	getIssueId,
	getSelectedTab,
	getProjectAri,
	getAccountId,
} from '../../state/selectors/app/index.tsx';

export const getUpdatedFromDate = () =>
	startOfDay(
		sub(new Date(), {
			weeks: 6,
		}),
	).toISOString();

export const epic = (action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
	action$.ofType(FETCH_DETAIL).mergeMap(() => {
		const state = store.getState();
		const issueId = getIssueId(state);
		const baseUrl = getBaseUrl(state);
		const projectAri = getProjectAri(state) || '';
		const accountId = getAccountId(state) || '';
		const updatedFrom = getUpdatedFromDate();
		const detailsObservable = Observable.zip(
			getDetails(baseUrl, issueId),
			getDeploymentDataDetected(projectAri, updatedFrom),
			getOptOutDeploymentsDiscovery(accountId),
			(details, deploymentDataDetected, isOptOutOfDeploymentDiscovery) =>
				setDetailData({
					details: {
						...details,
						embeddedMarketplaceDetails: {
							...(details.embeddedMarketplaceDetails || {}),
							shouldDisplayForBuilds:
								details?.embeddedMarketplaceDetails?.shouldDisplayForBuilds || false,
							shouldDisplayForDeployments:
								!deploymentDataDetected ||
								details?.embeddedMarketplaceDetails?.shouldDisplayForDeployments ||
								false,
							shouldDisplayForFeatureFlags:
								details?.embeddedMarketplaceDetails?.shouldDisplayForFeatureFlags || false,
						},
					},
					isOptOutOfDeploymentDiscovery,
				}),
		).catch((error) => {
			log.safeErrorWithoutCustomerData(
				'packages.development.details.src.main.fetch-details',
				error,
			);
			return Observable.of(fetchingFailed());
		});
		return Observable.concat(
			detailsObservable,
			Observable.of(getSelectedTab(state)).map((selectedTab) => changeTab(selectedTab)),
		);
	});
export const optOutDeploymentDetailDiscovery = (
	action$: ActionsObservable<Action>,
	store: MiddlewareAPI<State>,
) =>
	action$.ofType(OPT_OUT_DEPLOYMENTS_DISCOVERY).mergeMap(() => {
		const state = store.getState();
		const accountId = getAccountId(state) || '';
		return Observable.fromPromise(setOptOutDeploymentsDiscovery(accountId)).catch((error) => {
			log.safeErrorWithoutCustomerData(
				'packages.development.details.src.main.opt-project-out-deployments-ddd-discovery',
				error,
			);
			return Observable.empty();
		});
	});
