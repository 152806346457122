/**
 * If this file gets changed, please update platform/packages/proforma/proforma-common-core/src/form-system/components/questions/common/defaultDateTimeFormats.tsx or let the owners know via #jsm-request-intake.
 *
 * Proforma wants to stay consistent with Jira date and time format options.
 *
 * Later on Proforma will introduce React Context to pass config props, and this will be removed.
 */

/**
 * Long format of a date time, eg "Jun 16, 1971, 2:34 AM" with 12h/24h formatting resolved from locale
 */
export const longDateTimeFormat: Intl.DateTimeFormatOptions = {
	day: '2-digit',
	month: 'short',
	year: 'numeric',
	hour: 'numeric',
	minute: 'numeric',
};

/**
 * Long format of a date without time, eg "June 16, 1971"
 */
export const longDateOnlyFormat: Intl.DateTimeFormatOptions = {
	day: '2-digit',
	month: 'short',
	year: 'numeric',
};
