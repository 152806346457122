import { useService } from '@atlassian/jira-common-services/src/use-service/index.tsx';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { UserPermissionHook, UserPermissionResponse } from './types.tsx';

const getUserPermission = async (): Promise<UserPermissionResponse | undefined> =>
	performGetRequest('/rest/api/3/mypermissions?permissions=USER_PICKER');

export const useUserPickerPermission = (): UserPermissionHook => {
	const {
		loading,
		error,
		data,
		fetch: fetchUserPermission,
	} = useService<UserPermissionResponse>(getUserPermission);

	return [
		{
			loading,
			error,
			data: data?.permissions?.USER_PICKER?.havePermission,
		},
		fetchUserPermission,
	];
};

export default useUserPickerPermission;
