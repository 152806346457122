import { createSelectorCreator, defaultMemoize } from 'reselect';
import isEqual from 'lodash/isEqual';

export const createDeepEqualitySelector = createSelectorCreator(defaultMemoize, isEqual);

/**
 * Create a deep equal check wrapper. Will only return a new ref if the underlying selector
 * creates a non-deep-equal new value
 */
export const wrapWithDeepEqualityCheck = <TState, TProps, TRetVal>(
	selector: (arg1: TState, arg2: TProps) => TRetVal,
): ((arg1: TState, arg2: TProps) => TRetVal) =>
	createDeepEqualitySelector<TState, TProps, TRetVal, TRetVal>(selector, (result) => result);
