import React from 'react';
import { useFragment, graphql } from 'react-relay';
import { Box, xcss, Text } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import type { sentiment_issueFieldSentimentReadviewFull_SentimentReadView$key } from '@atlassian/jira-relay/src/__generated__/sentiment_issueFieldSentimentReadviewFull_SentimentReadView.graphql';
import messages from './messages.tsx';
import { RenderEmojiIconForSentimentCustomField } from './utils.tsx';

type Props = {
	fragmentKey: sentiment_issueFieldSentimentReadviewFull_SentimentReadView$key;
};

export const SentimentReadView = ({ fragmentKey }: Props) => {
	const { formatMessage } = useIntl();

	const data = useFragment<sentiment_issueFieldSentimentReadviewFull_SentimentReadView$key>(
		graphql`
			fragment sentiment_issueFieldSentimentReadviewFull_SentimentReadView on JiraServiceManagementSentimentField {
				sentiment {
					sentimentId
					name
				}
			}
		`,
		fragmentKey,
	);

	return data.sentiment && data.sentiment.sentimentId && data.sentiment.name ? (
		<Box xcss={fieldValueStyles} testId="issue-field-sentiment-readview-full.ui.sentiment.box">
			{RenderEmojiIconForSentimentCustomField(data.sentiment.sentimentId)}
			<Box xcss={contentStyles}>
				<Text as="p">{data.sentiment.name}</Text>
			</Box>
		</Box>
	) : (
		<Box xcss={NoneContentStyles}>{formatMessage(messages.none)}</Box>
	);
};

const contentStyles = xcss({
	display: 'flex',
	paddingTop: 'space.025',
	marginLeft: 'space.050',
});

const fieldValueStyles = xcss({
	display: 'flex',
	marginTop: 'space.050',
	marginLeft: 'space.negative.050',
	paddingBottom: 'space.050',
});

const NoneContentStyles = xcss({
	display: 'flex',
	paddingTop: 'space.075',
	color: 'color.text.subtlest',
	paddingBottom: 'space.050',
});
