import get from 'lodash/get';
import { useEditField } from '@atlassian/jira-issue-field-base/src/services/edit-field-service/main.tsx';
import { useFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import { RESOLUTION_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { ResolutionValue } from '../common/types.tsx';
import type { Props, UseResolutionField } from './types.tsx';

export const useResolutionField: UseResolutionField = ({ issueKey, onFailure }: Props) => {
	// useFieldConfig now returns an object { value, loading } instead of just the fieldConfig value
	// if possible when touching this, try and refactor to pass the new object instead of just the value
	const [{ value: fieldConfig }] = useFieldConfig(issueKey, RESOLUTION_TYPE);

	const fieldType = get(fieldConfig, 'schema.type', RESOLUTION_TYPE);

	const [{ error, value, loading }, { resetError, saveValue }] = useEditField<
		ResolutionValue,
		null
	>({
		fieldType,
		issueKey,
		initialValue: null,
		fieldKey: RESOLUTION_TYPE,
		onFailure,
	});

	return [
		{ error, value, loading, fieldConfig },
		{ resetError, saveValue },
	];
};
