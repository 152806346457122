import type { Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import type { View } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import { isShallowEqual } from '@atlassian/jira-polaris-lib-equals/src/index.tsx';
import { toIssueIdArray } from '@atlassian/jira-shared-types/src/general.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import { isViewInViewRankSortMode } from '../../selectors/view/view-rank/index.tsx';
import type { Props, State } from '../../types.tsx';
import { logViewError } from '../utils/errors.tsx';
import { updateViewState } from '../utils/state/index.tsx';

export const refreshIssueRanking =
	(viewId: Ari): Action<State, Props> =>
	async ({ getState, setState }, { onViewLoadingFailed, rankingRemote }) => {
		if (!isViewInViewRankSortMode(getState(), viewId)) {
			return;
		}

		rankingRemote
			.get({ listId: viewId })
			.then((remoteRanking) => {
				let modified = false;
				const viewMutation = (view: View): View => {
					const newIssueRanking = remoteRanking ? toIssueIdArray(remoteRanking) : undefined;
					if (isShallowEqual(newIssueRanking, view.issueRanking)) {
						return view;
					}
					modified = true;
					return {
						...view,
						issueRanking: newIssueRanking,
					};
				};
				const { viewSets } = updateViewState(
					getState().viewSets,
					(view) => view.viewId === viewId,
					viewMutation,
				);
				if (modified) {
					setState({ viewSets });
				}
			})
			.catch((error) => {
				logViewError('refresh-ranking', error);
				onViewLoadingFailed(error);
			});
	};
