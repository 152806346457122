export const QUEUE_HANDLER_LIMIT = 1000;
export const QUEUE_INTERVAL = 100;
export const VOLUME_STATISTIC_INTERVAL = 1000 * 60 * 2;
export const STATISTIC_INTERVAL = 1000 * 60 * 10;
// 1 minute
export const TIME_WINDOW_1M = 1000 * 60;
export const TIME_WINDOW_5M = 1000 * 60 * 5;
// 2 minutes
export const INACTIVE_TAB_INTERVAL = 2 * 60 * 1000;
// 5 minutes
export const MIN_QUOTA_CHECK_INACTIVE_TIME = 5 * 60 * 1000;
export const REALTIME_EVENTS_QUEUE_INACTIVE_QUOTA = 100;
