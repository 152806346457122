/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//Turkish (Turkey)
export default {
  'fabric.elements.share.copied.to.clipboard.message': 'Bağlantı panoya kopyalandı',
  'fabric.elements.share.copylink.button.text': 'Bağlantıyı kopyala',
  'fabric.elements.share.copypubliclink.button.text': 'Herkese açık bağlantıyı kopyalayın',
  'fabric.elements.share.failure.icon.label': 'Hata simgesi',
  'fabric.elements.share.failure.message': 'Paylaşılamadı',
  'fabric.elements.share.form.comment.label': 'Mesaj (isteğe bağlı)',
  'fabric.elements.share.form.comment.placeholder': 'Bilmeleri gereken bir şey var mı?',
  'fabric.elements.share.form.info.message.no.invite.confluence': 'Alıcılar sayfanın adını ve mesajınızı görür',
  'fabric.elements.share.form.info.message.no.invite.jira': 'Alıcılar konunun adını ve mesajınızı görür',
  'fabric.elements.share.form.no-permissions': 'Paylaşamıyorsunuz.',
  'fabric.elements.share.form.public.send': 'Herkese açık bağlantıyı gönderin',
  'fabric.elements.share.form.public.share': 'Herkese açık bağlantıyı paylaş',
  'fabric.elements.share.form.required-field.summary': 'Gerekli alanlar yıldız işaretiyle belirtilmiştir',
  'fabric.elements.share.form.retry': 'Yeniden Dene',
  'fabric.elements.share.form.send': 'Gönder',
  'fabric.elements.share.form.share': 'Paylaş',
  'fabric.elements.share.form.title': 'Paylaş',
  'fabric.elements.share.form.user-picker.add-more': 'Daha fazlasını girin',
  'fabric.elements.share.form.user-picker.label.confluence': 'Adlar, takımlar, gruplar veya e-postalar',
  'fabric.elements.share.form.user-picker.label.email-disabled-confluence': 'Adlar, takımlar veya gruplar',
  'fabric.elements.share.form.user-picker.label.email-disabled-jira': 'Adlar veya takımlar',
  'fabric.elements.share.form.user-picker.label.email-only': 'E-postalar',
  'fabric.elements.share.form.user-picker.label.jira': 'Adlar, takımlar veya e-postalar',
  'fabric.elements.share.form.user-picker.no-options.existingUserOnly':
    '"{inputValue}" için herhangi bir sonuç bulunamadı.',
  'fabric.elements.share.form.user-picker.no-options.generic':
    '"{inputValue}" için herhangi bir sonuç bulunamadı. Kişileri e-posta adresi kullanarak davet edin.',
  'fabric.elements.share.form.user-picker.placeholder.confluence': 'ör. Meryem, Turuncu Takım, Birinci Grup',
  'fabric.elements.share.form.user-picker.placeholder.email-disabled-jira': 'ör. Meryem, Turuncu Takım',
  'fabric.elements.share.form.user-picker.placeholder.email-only': 'ör. meryem@sirket.com',
  'fabric.elements.share.form.user-picker.placeholder.jira': 'ör. Meryem, Turuncu Takım, meryem@sirket.com',
  'fabric.elements.share.form.user-picker.validation.required':
    'En az bir kullanıcı, grup, takım veya e-posta adresi seçin.',
  'fabric.elements.share.form.user-picker.validation.required-message.confluence':
    'En az bir kişi, takım, grup veya e-posta seçin',
  'fabric.elements.share.form.user-picker.validation.required-message.email-disabled-confluence':
    'En az bir kişi, takım veya grup seçin',
  'fabric.elements.share.form.user-picker.validation.required-message.email-disabled-jira':
    'En az bir kişi veya takım seçin',
  'fabric.elements.share.form.user-picker.validation.required-message.email-only': 'En az bir e-posta seçin',
  'fabric.elements.share.form.user-picker.validation.required-message.jira': 'En az bir kişi, takım veya e-posta seçin',
  'fabric.elements.share.in.integration.button': '{integrationName} içinde paylaş',
  'fabric.elements.share.invite.to.confluence': "Confluence'a davet et",
  'fabric.elements.share.invite.to.jira': "Jira'ya davet et",
  'fabric.elements.share.main.tab.text.confluence': 'Sayfayı paylaşın',
  'fabric.elements.share.main.tab.text.jira': 'Konuyu paylaşın',
  'fabric.elements.share.menu.email.label': 'E-posta',
  'fabric.elements.share.menu.slack.label': 'Slack',
  'fabric.elements.share.popup.label': 'Paylaş',
  'fabric.elements.share.success.message':
    '{object, select,blogpost {Blog gönderisi paylaşıldı}board {Pano paylaşıldı}calendar {Takvim paylaşıldı}draft {Taslak paylaşıldı}filter {Filtre paylaşıldı}issue {Konu paylaşıldı}summary {Özet paylaşıldı}list {Liste paylaşıldı}timeline {Zaman Çizelgesi paylaşıldı}form {Form paylaşıldı}media {Medya paylaşıldı}page {Sayfa paylaşıldı}project {Proje paylaşıldı}pullrequest {Çekme İsteği paylaşıldı}question {Soru paylaşıldı}report {Rapor paylaşıldı}repository {Depo paylaşıldı}request {Talep paylaşıldı}roadmap {Zaman Çizelgesi paylaşıldı}site {Site paylaşıldı}space {Alan paylaşıldı}other {Bağlantı paylaşıldı}}',
  'fabric.elements.share.to.integration.button': '{integrationName} üzerinden paylaş',
  'fabric.elements.share.trigger.button.icon.label': 'Paylaşma simgesi',
  'fabric.elements.share.trigger.button.invite.text': 'Davet Et',
  'fabric.elements.share.trigger.button.text': 'Paylaş',
  'fabric.elements.share.trigger.button.tooltip.text': 'Paylaş',
};
