/**
 * @generated SignedSource<<d698883de3c28ee2d0fd655b48eeea5a>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type parent_issueFieldParentInlineEditFull_ParentInlineEditView_fragmentRef$data = {
  readonly fieldConfig: {
    readonly isEditable: boolean | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"parent_issueFieldParentInlineEditFull_ParentInlineEditViewIsEditable_fragmentRef">;
  readonly " $fragmentType": "parent_issueFieldParentInlineEditFull_ParentInlineEditView_fragmentRef";
};
export type parent_issueFieldParentInlineEditFull_ParentInlineEditView_fragmentRef$key = {
  readonly " $data"?: parent_issueFieldParentInlineEditFull_ParentInlineEditView_fragmentRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"parent_issueFieldParentInlineEditFull_ParentInlineEditView_fragmentRef">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "parent_issueFieldParentInlineEditFull_ParentInlineEditView_fragmentRef",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "parent_issueFieldParentInlineEditFull_ParentInlineEditViewIsEditable_fragmentRef"
    },
    {
      "concreteType": "JiraFieldConfig",
      "kind": "LinkedField",
      "name": "fieldConfig",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isEditable"
        }
      ]
    }
  ],
  "type": "JiraParentIssueField"
};

(node as any).hash = "935d17179ef6e93f2b74d57828fd1128";

export default node;
