import { traverse } from '@atlaskit/adf-utils/traverse';
import {
	getCommonFieldValidators as getCommonFieldValidatorsCore,
	type CommonFieldPropertyValidators,
} from '@atlassian/jira-issue-adjustments/src/common.tsx';
import { convertWikiToAdfAsync } from '@atlassian/jira-platform-convert-wiki-to-adf/src/async.tsx';
import { fireIAErrorAnalytics } from '@atlassian/jira-ui-modifications-analytics/src/common/utils/error-analytics/index.tsx';
import { ExternalImageValidationError } from '../../errors/invalid-external-image-validation-error.tsx';

const validateDescription = async (value: unknown) => {
	if (typeof value !== 'string') {
		return false;
	}

	if (value === '') {
		return true;
	}
	// turn into ADF
	try {
		const adfValue = await convertWikiToAdfAsync(value);
		if (!adfValue) {
			return false;
		}
		// find image nodes that contain external URLs.
		traverse(adfValue, {
			media: (node) => {
				if (node.attrs?.type === 'external' && node.attrs.url !== undefined) {
					// ADF cannot contain external urls due to the possibility of data egress.
					throw new ExternalImageValidationError('Cannot contain external images');
				}
			},
		});
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (err: any) {
		// ExternalImageValidationError will be thrown if there is an external image
		if (!(err instanceof ExternalImageValidationError)) {
			// TODO: use valid value of issueType instead of undefined in fireIAErrorAnalytics call. There is a task for it - https://ecosystem-platform.atlassian.net/browse/UIM-1651;
			fireIAErrorAnalytics({
				error: err,
				id: 'descriptionWikiValidationException',
				// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/consistent-type-assertions
				viewType: undefined as any,
				attributes: {
					errorCause: err?.cause,
					message: err.message,
				},
			});
		}
		return false;
	}
	return true;
};

export const getCommonFieldValidators = (): CommonFieldPropertyValidators => ({
	...getCommonFieldValidatorsCore(),
	description: validateDescription,
});
