import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { State } from '../../types.tsx';

export const clearSkippedAccountFailures =
	() =>
	async ({ getState, setState }: StoreActionApi<State>) => {
		setState({
			config: {
				...getState().config,
				skippedAccountFailures: [],
			},
		});
	};
