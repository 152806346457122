import type { MiddlewareAPI } from 'redux';
import type { BatchAction } from 'redux-batched-actions';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/startWith';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/delay';
import 'rxjs/add/operator/ignoreElements';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/retryWhen';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { type Epic, type ActionsObservable, combineEpics } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import { switchMap } from 'rxjs/operators/switchMap';
import { FORBIDDEN } from '@atlassian/jira-common-constants/src/http-status-codes.tsx';
import type { SubProduct } from '@atlassian/jira-common-constants/src/sub-product-types.tsx';
import { isPageVisible } from '@atlassian/jira-common-page-visibility/src/index.tsx';
import type { AttachmentServiceActions } from '@atlassian/jira-issue-attachments-base/src/services/attachments-service/types.tsx';
import type { IssueContextServiceActions } from '@atlassian/jira-issue-context-service/src/types.tsx';
import { fetchIssueMediaReadPermission$ } from '@atlassian/jira-issue-fetch-services/src/services/issue-media-read-permissions/index.tsx';
import type { MediaContextServiceActions } from '@atlassian/jira-issue-media-context-service/src/types.tsx';
import { viewContextTransformer } from '@atlassian/jira-issue-media-provider/src/controllers/create-media-provider/utils.tsx';
import type { HeadersProcessor } from '@atlassian/jira-issue-view-common-types/src/analytics-types.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import type { ViewResponse } from '@atlassian/jira-issue-view-common-types/src/view-context-type.tsx';
import { trackOrLogClientError } from '@atlassian/jira-issue-view-common-utils/src/errors/index.tsx';
import { ofTypeOrBatchType } from '@atlassian/jira-issue-view-common-utils/src/rx/of-type-or-batch-type.tsx';
import { genericRetryStrategy } from '@atlassian/jira-issue-view-common-utils/src/rx/retry.tsx';
import { fallbackOnMissingOrError$ } from '@atlassian/jira-issue-view-common-utils/src/utils/prefetched-resources/utils/fallback-on-error.tsx';
import {
	fetchViewContextRequest,
	fetchViewContextSuccess,
	fetchViewContextFailure,
	FETCH_VIEW_CONTEXT_REQUEST,
	FETCH_VIEW_CONTEXT_SUCCESS,
	FETCH_VIEW_CONTEXT_FAILURE,
	type ViewContextAction,
	checkViewContext,
	CHECK_VIEW_CONTEXT,
} from '@atlassian/jira-issue-view-store/src/common/media/view-context/view-context-actions.tsx';
import { viewContextSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/media-context-selector.tsx';
import { toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { attachmentsReadCredentialsExperience } from '../experiences.tsx';
import { isServiceDesk as isServiceDeskDI } from '../utils.tsx';

const MINIMUM_FOCUS_TIME_MS = 1000;

const createFailureStream = (error: Error & { statusCode: number }) =>
	Observable.of(fetchViewContextFailure(error));

const fetchViewContextDeprecated = (
	baseUrl: string,
	issueKey: string,
	maxTokenLength: number,
	headersProcessor?: HeadersProcessor,
	prefetchedMediaReadPermissions?: Promise<ViewResponse> | null,
) =>
	fallbackOnMissingOrError$(prefetchedMediaReadPermissions, () =>
		fetchIssueMediaReadPermission$(baseUrl, issueKey, maxTokenLength, headersProcessor),
	)
		.map(viewContextTransformer)
		.map(fetchViewContextSuccess);

const fetchViewContext = (
	baseUrl: string,
	issueKey: string,
	maxTokenLength: number,
	isServiceDesk: boolean,
	headersProcessor?: HeadersProcessor,
	prefetchedMediaReadPermissions?: Promise<ViewResponse> | null,
) =>
	fallbackOnMissingOrError$(prefetchedMediaReadPermissions, () => {
		if (isServiceDesk) {
			attachmentsReadCredentialsExperience.start();
		}
		return fetchIssueMediaReadPermission$(baseUrl, issueKey, maxTokenLength, headersProcessor);
	})
		.map(viewContextTransformer)
		.map(fetchViewContextSuccess);

export const fetchViewContextWithErrorHandlingDeprecated = (
	baseUrl: string,
	issueKey: string,
	maxTokenLength: number,
	headersProcessor?: HeadersProcessor,
	prefetchedMediaReadPermissions?: Promise<ViewResponse> | null,
) =>
	fetchViewContextDeprecated(
		baseUrl,
		issueKey,
		maxTokenLength,
		headersProcessor,
		prefetchedMediaReadPermissions,
	)
		.catch((error: Error & { statusCode: number }) => {
			if (error.statusCode === FORBIDDEN) {
				return createFailureStream(error);
			}
			throw error;
		})
		.retryWhen(genericRetryStrategy)
		.catch(createFailureStream);

export const fetchViewContextWithErrorHandlingNew = (
	baseUrl: string,
	issueKey: string,
	maxTokenLength: number,
	isServiceDesk: boolean,
	headersProcessor?: HeadersProcessor,
	prefetchedMediaReadPermissions?: Promise<ViewResponse> | null,
) =>
	fetchViewContext(
		baseUrl,
		issueKey,
		maxTokenLength,
		isServiceDesk,
		headersProcessor,
		prefetchedMediaReadPermissions,
	)
		.catch((error) => {
			if (error.statusCode === FORBIDDEN) {
				return createFailureStream(error);
			}
			throw error;
		})
		.retryWhen(genericRetryStrategy)
		.do(() => {
			if (isServiceDesk) {
				attachmentsReadCredentialsExperience.success();
			}
		})
		.catch((error) => {
			if (isServiceDesk) {
				attachmentsReadCredentialsExperience.failure();
			}
			return createFailureStream(error);
		});

export const fetchViewContextWithErrorHandling = (
	baseUrl: string,
	issueKey: string,
	maxTokenLength: number,
	isServiceDesk: boolean,
	headersProcessor?: HeadersProcessor,
	prefetchedMediaReadPermissions?: Promise<ViewResponse> | null,
) =>
	fetchViewContextWithErrorHandlingNew(
		baseUrl,
		issueKey,
		maxTokenLength,
		isServiceDesk,
		headersProcessor,
		prefetchedMediaReadPermissions,
	);

export const viewContextEpicDeprecated = (
	getBaseUrl: (state: State) => string,
	getIssueKey: (state: State) => string,
	attachmentActions: AttachmentServiceActions,
	issueContextActions: IssueContextServiceActions,
	mediaContextActions: MediaContextServiceActions,
): Epic<ViewContextAction | BatchAction, State> => {
	const fetchViewContextEpic = (
		action$: ActionsObservable<ViewContextAction | BatchAction>,
		store: MiddlewareAPI<State>,
	) =>
		action$.ofType(FETCH_VIEW_CONTEXT_REQUEST).switchMap(() => {
			const state = store.getState();
			const baseUrl = getBaseUrl(state);
			const issueKey = getIssueKey(state);
			const maxTokenLength = issueContextActions.getMaxTokenLength();
			attachmentActions.refreshAttachments(toIssueKey(issueKey));
			return fetchViewContextWithErrorHandlingDeprecated(baseUrl, issueKey, maxTokenLength);
		});

	const failureLoggingEpic = (action$: ActionsObservable<ViewContextAction | BatchAction>) =>
		action$
			.ofType(FETCH_VIEW_CONTEXT_FAILURE)
			.do(({ payload }) => {
				const { error } = payload;
				trackOrLogClientError(
					'issue.media.fetch-view-context-error',
					'Error when fetching media view context',
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					error as Error,
				);
			})
			.ignoreElements();

	// Whenever the view context is fetched, we need to schedule a subsequent fetch
	// to occur just before the current token is due to expire. This is implemented as
	// an infinite loop - "fetch success/failure" actions result in a new (delayed)
	// "fetch request" being scheduled.
	const refreshBeforeTokenExpiryEpic = (
		action$: ActionsObservable<ViewContextAction | BatchAction>,
		store: MiddlewareAPI<State>,
	) =>
		action$.pipe(
			ofTypeOrBatchType(FETCH_VIEW_CONTEXT_SUCCESS, CHECK_VIEW_CONTEXT),
			switchMap(() => {
				const state = store.getState();
				const issueKey = getIssueKey(state);
				const viewContext = viewContextSelector(state);

				if (viewContext == null || !isPageVisible()) {
					return Observable.empty<never>();
				}
				mediaContextActions.setViewContext(toIssueKey(issueKey), viewContext);
				const { tokenIssueTimestamp, tokenLifespanInMs } = viewContext;
				const wantsRefreshAt = tokenIssueTimestamp + tokenLifespanInMs;
				const timeUntilRefresh = Math.max(1, wantsRefreshAt - Date.now());

				return Observable.of(fetchViewContextRequest()).delay(timeUntilRefresh);
			}),
		);

	// When a user returns to a tab (either from moving around in their browser, or a wake from
	// device sleep) we want to check their media view token and refresh it if necessary. If a
	// refresh is needed, the switchMap in refreshBeforeTokenExpiryEpic will ensure we don't end
	// up with multiple timed refreshes, only the latest one.
	//
	// The combination of debounce and isPageVisible check means we only fire the event if the user
	// has stayed on the page for at least the debounce time, so as not to spam events.
	const focusChangeEpic = () =>
		Observable.fromEvent(window, 'focus')
			.startWith(null)
			.debounceTime(MINIMUM_FOCUS_TIME_MS)
			.switchMap(() =>
				isPageVisible() ? Observable.of(checkViewContext()) : Observable.empty<never>(),
			);

	return combineEpics<ViewContextAction | BatchAction, State>(
		fetchViewContextEpic,
		refreshBeforeTokenExpiryEpic,
		failureLoggingEpic,
		focusChangeEpic,
	);
};

export const viewContextEpic = (
	getBaseUrl: (state: State) => string,
	getIssueKey: (state: State) => string,
	subProduct: SubProduct | null,
	attachmentActions: AttachmentServiceActions,
	issueContextActions: IssueContextServiceActions,
	mediaContextActions: MediaContextServiceActions,
): Epic<ViewContextAction | BatchAction, State> => {
	const fetchViewContextEpic = (
		action$: ActionsObservable<ViewContextAction | BatchAction>,
		store: MiddlewareAPI<State>,
	) =>
		action$.ofType(FETCH_VIEW_CONTEXT_REQUEST).switchMap(() => {
			const state = store.getState();
			const baseUrl = getBaseUrl(state);
			const issueKey = getIssueKey(state);
			const isServiceDesk = isServiceDeskDI(subProduct);
			const maxTokenLength = issueContextActions.getMaxTokenLength();
			attachmentActions.refreshAttachments(toIssueKey(issueKey));
			return fetchViewContextWithErrorHandlingNew(baseUrl, issueKey, maxTokenLength, isServiceDesk);
		});

	const failureLoggingEpic = (action$: ActionsObservable<ViewContextAction | BatchAction>) =>
		action$
			.ofType(FETCH_VIEW_CONTEXT_FAILURE)
			.do(({ payload }) => {
				const { error } = payload;
				trackOrLogClientError(
					'issue.media.fetch-view-context-error',
					'Error when fetching media view context',
					error,
				);
			})
			.ignoreElements();

	// Whenever the view context is fetched, we need to schedule a subsequent fetch
	// to occur just before the current token is due to expire. This is implemented as
	// an infinite loop - "fetch success/failure" actions result in a new (delayed)
	// "fetch request" being scheduled.
	const refreshBeforeTokenExpiryEpic = (
		action$: ActionsObservable<ViewContextAction | BatchAction>,
		store: MiddlewareAPI<State>,
	) =>
		action$.pipe(
			ofTypeOrBatchType(FETCH_VIEW_CONTEXT_SUCCESS, CHECK_VIEW_CONTEXT),
			switchMap(() => {
				const state = store.getState();
				const issueKey = getIssueKey(state);
				const viewContext = viewContextSelector(state);
				mediaContextActions.setViewContext(toIssueKey(issueKey), viewContext);
				if (viewContext == null || !isPageVisible()) {
					return Observable.empty<never>();
				}

				const { tokenIssueTimestamp, tokenLifespanInMs } = viewContext;
				const wantsRefreshAt = tokenIssueTimestamp + tokenLifespanInMs;
				const timeUntilRefresh = Math.max(1, wantsRefreshAt - Date.now());

				return Observable.of(fetchViewContextRequest()).delay(timeUntilRefresh);
			}),
		);

	// When a user returns to a tab (either from moving around in their browser, or a wake from
	// device sleep) we want to check their media view token and refresh it if necessary. If a
	// refresh is needed, the switchMap in refreshBeforeTokenExpiryEpic will ensure we don't end
	// up with multiple timed refreshes, only the latest one.
	//
	// The combination of debounce and isPageVisible check means we only fire the event if the user
	// has stayed on the page for at least the debounce time, so as not to spam events.
	const focusChangeEpic = () =>
		Observable.fromEvent(window, 'focus')
			.startWith(null)
			.debounceTime(MINIMUM_FOCUS_TIME_MS)
			.switchMap(() =>
				isPageVisible() ? Observable.of(checkViewContext()) : Observable.empty<never>(),
			);

	return combineEpics<ViewContextAction | BatchAction, State>(
		fetchViewContextEpic,
		refreshBeforeTokenExpiryEpic,
		failureLoggingEpic,
		focusChangeEpic,
	);
};
