/**
 * @generated SignedSource<<8e625c5128a0cb10db0afae0fb53b1aa>>
 * @relayHash 1526a117032a7bf495ae001b3b64995c
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID d152d0f15dbcfed04a3b44292aafbdceb94b4b57af84942e306015d320a45c49

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type JiraMultiValueFieldOperations = "ADD" | "REMOVE" | "SET" | "%future added value";
export type JiraUpdateMultipleGroupPickerFieldInput = {
  id: string;
  operations: ReadonlyArray<JiraMultipleGroupPickerFieldOperationInput>;
};
export type JiraMultipleGroupPickerFieldOperationInput = {
  groupIds?: ReadonlyArray<string> | null | undefined;
  ids?: ReadonlyArray<string> | null | undefined;
  operation: JiraMultiValueFieldOperations;
};
export type ui_issueViewLayoutMultiGroupPickerField_IssueViewMultiGroupPickerField_Mutation$variables = {
  input: JiraUpdateMultipleGroupPickerFieldInput;
};
export type ui_issueViewLayoutMultiGroupPickerField_IssueViewMultiGroupPickerField_Mutation$data = {
  readonly jira: {
    readonly updateMultipleGroupPickerField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly id: string;
        readonly selectedGroupsConnection: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly groupId: string;
              readonly id: string;
              readonly name: string;
            } | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type ui_issueViewLayoutMultiGroupPickerField_IssueViewMultiGroupPickerField_Mutation$rawResponse = {
  readonly jira: {
    readonly updateMultipleGroupPickerField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly id: string;
        readonly selectedGroupsConnection: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly groupId: string;
              readonly id: string;
              readonly name: string;
            } | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type ui_issueViewLayoutMultiGroupPickerField_IssueViewMultiGroupPickerField_Mutation = {
  rawResponse: ui_issueViewLayoutMultiGroupPickerField_IssueViewMultiGroupPickerField_Mutation$rawResponse;
  response: ui_issueViewLayoutMultiGroupPickerField_IssueViewMultiGroupPickerField_Mutation$data;
  variables: ui_issueViewLayoutMultiGroupPickerField_IssueViewMultiGroupPickerField_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "kind": "ScalarField",
  "name": "id"
},
v2 = [
  {
    "concreteType": "JiraMutation",
    "kind": "LinkedField",
    "name": "jira",
    "plural": false,
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "JiraMultipleGroupPickerFieldPayload",
        "kind": "LinkedField",
        "name": "updateMultipleGroupPickerField",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "name": "success"
          },
          {
            "concreteType": "MutationError",
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "message"
              }
            ]
          },
          {
            "concreteType": "JiraMultipleGroupPickerField",
            "kind": "LinkedField",
            "name": "field",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "concreteType": "JiraGroupConnection",
                "kind": "LinkedField",
                "name": "selectedGroupsConnection",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraGroupEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "JiraGroup",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "name"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "groupId"
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "ui_issueViewLayoutMultiGroupPickerField_IssueViewMultiGroupPickerField_Mutation",
    "selections": (v2/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ui_issueViewLayoutMultiGroupPickerField_IssueViewMultiGroupPickerField_Mutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": "d152d0f15dbcfed04a3b44292aafbdceb94b4b57af84942e306015d320a45c49",
    "metadata": {},
    "name": "ui_issueViewLayoutMultiGroupPickerField_IssueViewMultiGroupPickerField_Mutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "a5292b5a267320d94ba8056cecf44b0b";

export default node;
