import { useCallback, useMemo } from 'react';
// eslint-disable-next-line jira/wrm/no-load-bridge
import { jiraBridge } from '@atlassian/jira-common-bridge';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { Reason } from '@atlassian/jira-issue-refresh-service/src/types.tsx';

export const useSoftRefresh = () => {
	const onSoftRefresh = useCallback(async (issueKey: string, fieldType: string) => {
		try {
			await jiraBridge.refreshIssuePageThrowable(issueKey, Reason.SoftRefresh);
			log.safeInfoWithoutCustomerData(
				'issue.details.use-soft-refresh',
				'issueViewSoftRefresh requested',
				{
					fieldType,
				},
			);
		} catch (error) {
			const errorMessage =
				error instanceof Error ? error.message : 'issueViewSoftRefresh issue refresh failed';
			log.safeErrorWithoutCustomerData(
				'issue.details.use-soft-refresh',
				`${fieldType} - ${errorMessage}`,
			);
		}
	}, []);
	return useMemo(
		() => ({
			onSoftRefresh,
		}),
		[onSoftRefresh],
	);
};
