import { useCallback } from 'react';
import { useIntl } from '@atlassian/jira-intl';
// eslint-disable-next-line jira/import/no-restricted-import
import type { EntityLimitType as CollectionEntityLimitType } from '@atlassian/jira-polaris-domain-collection/src/types.tsx';
// eslint-disable-next-line jira/import/no-restricted-import
import type { EntityLimitType as ProjectEntityLimitType } from '@atlassian/jira-polaris-domain-project/src/types.tsx';
import { getLimitMessageDescriptor } from '../common/index.tsx';

export const useEntityLimitMessage = () => {
	const { formatMessage } = useIntl();

	return useCallback(
		(limitType: ProjectEntityLimitType | CollectionEntityLimitType, limitValue: number) => {
			const message = getLimitMessageDescriptor(limitType);
			return message ? formatMessage(message, { limit: limitValue }) : null;
		},
		[formatMessage],
	);
};
