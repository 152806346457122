const VIEW_ISSUE_ALIAS = 'bentoViewIssue';

const getUserQueryFragment = () => `
    user {
        key
        accountId
        emailAddress
        avatarUrl
        displayName
        active
    }
`;

const getConfluencePagesQueryFragment = () => `
    pages {
        ... on ConfluencePage {
            id
            globalId
            href
            title
            type
            lastUpdatedContext {
                ${getUserQueryFragment()}
            }
        }
        ... on FailedRemoteLink {
            id
            error
            link {
                id
                globalId
                href
            }
            repairLink { href }
        }
    }
`;

const getConfluencePageGroupQueryFragment = () => `
    pageCount
    ${getConfluencePagesQueryFragment()}
`;

const getRemoteLinkedIssuesPreviewFragment = () => `
    id
    issueId
    globalId
    href
    applicationType
    applicationName
    relationship
    title
`;

const getAssociatedIssueFragment = () => `
    issueId
    issueKey {
        stringValue
    }
    summary {
        textValue
    }
    status {
        statusCategoryId
        name
        statusId
    }
    priority {
        iconUrl
        name
    }
    issueType {
        iconUrl
        name
    }
    assignee {
        userValue
        {
            displayName
            avatarUrl
        }
    }
   isResolved
`;

const getRemoteLinkedIssuesDetailsFragment = () => `
    ... on AssociatedIssueWrapper {
        associatedIssue {
            ${getAssociatedIssueFragment()}
        }
    }
    ... on FailedRemoteIssue {
        error
        repairLink { href }
    }
`;

const getRemoteLinkedIssuesFragment = () => `
    remoteLinkedIssues {
        preview {
            ${getRemoteLinkedIssuesPreviewFragment()}
        }
        details {
            ${getRemoteLinkedIssuesDetailsFragment()}
        }
    }
`;

export const getWebLinksFragment = () => `
    webLinks(allowThirdParties: true, orderBy: "-id") {
        linkCount
        links {
            id
            href
            linkText
            iconUrl
            summary
            resolved
            relationship
            applicationName
            applicationType
        }
    }
`;

const getRemoteDataQueryFragment = () => `
    issueId
    remoteLinks {
       isLinkingEnabled
       confluencePages {
            linkedPages {
                ${getConfluencePageGroupQueryFragment()}
            }
            mentionedPages {
                ${getConfluencePageGroupQueryFragment()}
            }
        }
        ${getWebLinksFragment()}
        ${getRemoteLinkedIssuesFragment()}
    }
`;

export const getRemoteDataQueryPrefix = () => '_remote_data';

export const getIssueRemoteDataQuery = () => `
    query issueViewRemoteDataQuery($issueKey: String!) {
        ${VIEW_ISSUE_ALIAS}${getRemoteDataQueryPrefix()}: viewIssue(issueKey: $issueKey) {
            ${getRemoteDataQueryFragment()}
        }
    }
`;

export const getIssueRemoteConfluenceWhiteboardQuery = () => `
    query issueViewRemoteConfluenceWhiteboardQuery($id: ID!) {
        graphStore @optIn(to: "GraphStore") {
            issueToWhiteboard (id: $id first: 900) @optIn(to: "GraphStoreIssueToWhiteboard") {
                edges {
                    node {
                        ... on ConfluenceWhiteboard {
                            id
                            links {
                                base
                                webUi
                            }
                            author {
                                user {
                                accountId
                                accountStatus
                                name
                                picture
                                }
                            }
                            title
                            whiteboardId
                        }
                    }
                }
                totalCount
            }
        }
    }
`;
