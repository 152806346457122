// Typescript doesn't have reverse mapping from string value to enum, so we'll need
// to convert manually.

export function enumFromStringValue<T>(
	enumType: { [s: string]: T },
	enumValue: string,
): T | undefined {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return (Object.values(enumType) as unknown as string[]).includes(enumValue)
		? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			(enumValue as unknown as T)
		: undefined;
}
