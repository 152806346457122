import React, { memo, useCallback } from 'react';
import Badge from '@atlaskit/badge';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueAttachments } from '@atlassian/jira-issue-attachments-base/src/services/attachments-service/main.tsx';
import { useDownloadAllService } from '@atlassian/jira-issue-attachments-base/src/services/download/index.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import {
	issueIdSelector,
	issueKeySelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { IssueId, IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import messages from './messages.tsx';

type Props = {
	issueId: IssueId | undefined;
	issueKey: IssueKey;
};

export const DownloadAllItem = memo<Props>((props: Props) => {
	const { issueId, issueKey } = props;
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	if (!issueId) {
		return null;
	}

	const [
		{
			value: { totalCount },
		},
	] = // go/jfe-eslint
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useIssueAttachments(issueKey);

	// go/jfe-eslint
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const [isDownloading, makeDownloadRequest] = useDownloadAllService(issueId, issueKey);

	// go/jfe-eslint
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const onClick = useCallback(() => {
		makeDownloadRequest();
		const analyticsEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'dropdownItem',
		});
		fireUIAnalytics(analyticsEvent, 'downloadAllAttachments', { totalMedia: totalCount });
	}, [createAnalyticsEvent, makeDownloadRequest, totalCount]);

	return (
		<DropdownItem
			key="download-all-attachments"
			onClick={onClick}
			elemAfter={<Badge>{totalCount}</Badge>}
			isDisabled={isDownloading}
			/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
			testId="issue.views.issue-base.content.attachment.heading.meatballs.download-all-item.dropdown-item"
		>
			{isDownloading ? formatMessage(messages.downloading) : formatMessage(messages.downloadAll)}
		</DropdownItem>
	);
});

export default connect(
	(state: State): Props => ({
		issueId: issueIdSelector(state),
		issueKey: issueKeySelector(state),
	}),
	{},
)(DownloadAllItem);
