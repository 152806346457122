import type {
	CommentUser,
	PolarisComment,
} from '@atlassian/jira-polaris-domain-comment/src/index.tsx';
import type {
	ViewComment,
	ViewCommentAccount,
} from '@atlassian/jira-polaris-domain-view/src/comment/types.tsx';

// note that we are turning this not into our own kind of User, but rather into a CommentUser
// for compatibility with idea/issue comments which come from Jira and not from AGG
const internUser = (user: ViewCommentAccount | null): CommentUser => {
	if (!user) {
		return {
			accountId: 'unknown',
			avatarUrls: {
				'16x16': '#',
				'24x24': '#',
				'32x32': '#',
				'48x48': '#',
			},
			displayName: 'Unknown user',
			active: false,
		};
	}
	return {
		accountId: user.accountId,
		avatarUrls: {
			'16x16': user.picture,
			'24x24': user.picture,
			'32x32': user.picture, // this is the one used by the comment stream
			'48x48': user.picture,
		},
		displayName: user.name,
		active: true, // TODO do we know this??
	};
};

// convert from service representation to internal representation
export const internViewComment = (comment: ViewComment): PolarisComment => {
	const author = internUser(comment.account);

	return {
		id: comment.id,
		author,
		body: comment.content,
		created: comment.created,
		updated: comment.created === comment.updated ? undefined : comment.updated,
		updateAuthor: comment.created === comment.updated ? undefined : author,
	};
};
