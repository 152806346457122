import { defineMessages } from '@atlassian/jira-intl';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default defineMessages({
	errorTitle: {
		id: 'issue-field-assignee.popover.error-title',
		defaultMessage: "We couldn't update the assignee",
		description: 'Error message informing the user that updating the field failed',
	},
	errorMessage: {
		id: 'issue-field-assignee.popover.error-message',
		defaultMessage: 'You can try again in a few minutes, or refresh the page.',
		description: 'Error message when save fails',
	},
}) as {
	errorMessage: MessageDescriptor;
	errorTitle: MessageDescriptor;
};
