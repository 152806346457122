import React from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import type { AnalyticsAttributes } from '../../../../types/analytics/index.tsx';

export const CommandRowErrorBoundary = ({
	children,
	teamName,
	onError,
	attributes,
}: {
	children: React.ReactNode | React.ReactNode[];
	teamName: string;
	onError: () => void;
	attributes?: AnalyticsAttributes;
}) => (
	<JSErrorBoundary
		id="command-palette-row"
		packageName="jiraCommandPalette"
		fallback="unmount"
		teamName={teamName}
		onError={onError}
		attributes={attributes}
	>
		{children}
	</JSErrorBoundary>
);
