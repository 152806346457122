import React from 'react';
import Icon from '@atlaskit/icon';

const ArrowDownGlyph = (props: { [key: string]: string }) => (
	<svg
		{...props}
		width="24px"
		height="24px"
		viewBox="0 0 24 24"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<path
				d="M6.8,4.3 C6.6,4.1 6.3,4 6,4 C5.7,4 5.4,4.1 5.2,4.3 L2.3,7.2 C1.9,7.6 1.9,8.3 2.3,8.7 C2.7,9.1 3.3,9.1 3.7,8.7 L5,7.4 L5,19.1 C5,19.6 5.4,20 6,20 C6.6,20 7,19.6 7,19.1 L7,7.4 L8.3,8.7 C8.7,9.1 9.3,9.1 9.7,8.7 C10.1,8.3 10.1,7.6 9.7,7.2 C9.04754611,6.54754611 8.5582057,6.0582057 8.23197876,5.73197876 C7.91376126,5.41376126 7.436435,4.936435 6.8,4.3 Z"
				id="Path"
				fill="currentColor"
				fillRule="nonzero"
				transform="translate(6.000000, 12.000000) rotate(180.000000) translate(-6.000000, -12.000000) "
			/>
			<path
				d="M21,5 L13,5 C12.4,5 12,5.4 12,6 C12,6.6 12.4,7 13,7 L21,7 C21.6,7 22,6.6 22,6 C22,5.4 21.6,5 21,5 Z"
				id="Path"
				fill="currentColor"
				fillRule="nonzero"
			/>
			<path
				d="M13,13 L18,13 C18.6,13 19,12.6 19,12 C19,11.4 18.6,11 18,11 L13,11 C12.4,11 12,11.4 12,12 C12,12.6 12.4,13 13,13 Z"
				id="Path"
				fill="currentColor"
				fillRule="nonzero"
			/>
			<path
				d="M13,19 L16,19 C16.6,19 17,18.6 17,18 C17,17.4 16.6,17 16,17 L13,17 C12.4,17 12,17.4 12,18 C12,18.6 12.4,19 13,19 Z"
				id="Path"
				fill="currentColor"
				fillRule="nonzero"
			/>
		</g>
	</svg>
);

const ArrowDownIcon = ({ label }: { label: string }) => (
	// @ts-expect-error - TS2322 - Type '(props: { [key: string]: string; }) => JSX.Element' is not assignable to type 'ComponentType<CustomGlyphProps> | undefined'.
	<Icon size="small" label={label} glyph={ArrowDownGlyph} />
);
export default ArrowDownIcon;
