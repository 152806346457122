import {
	performPostRequest,
	performDeleteRequest,
} from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { DEFAULT_ORDER_DIRECTION, DEFAULT_ORDER_FIELD, DEFAULT_PAGE_SIZE } from './constants.tsx';
import type {
	AttachmentId,
	AttachmentsQueryOptions,
	AttachmentsResponse,
	IssueAttachment,
} from './types.tsx';

export const initialAttachmentState: IssueAttachment = {
	isDownloading: false,
	isFetching: false,
	error: null,
	totalCount: undefined,
	deletableCount: undefined,
	visibleAttachments: [],
};

export const attachmentsQuery = () => `
  query attachmentsQuery($issueKey: String, $startAt: Long, $maxResults: Long, $orderField: AttachmentsOrderField, $orderDirection: OrderDirection) {
    viewIssue(issueKey: $issueKey) {
        attachments(startAt: $startAt, maxResults: $maxResults, orderField: $orderField, orderDirection: $orderDirection) {
            nodes {
                id
                filename
                mimetype
                mediaApiFileId
                createdDate
                filesize
                parentName
                parentId
                author {
                    accountId
                }
                hasRestrictedParent
            }
            totalCount
            deletableCount
        }
    }
  }`;
// copied over to remove cycle from '@atlassian/jira-issue-fetch-services-common/src/services/issue-gira-data';
export const IssueViewFetchOperations = {
	EARLY_FETCH_GRAPHQL: 'earlyFetchIssueDataQuery',
	INITIAL_GRAPHQL: 'issueDataQuery',
	EARLY_FETCH_GIRA: 'earlyFetchIssueViewInteractiveQuery',
	INITIAL_GIRA: 'issueViewInteractiveQuery',
	EARLY_FETCH_AGG: 'earlyFetchMainIssueAggQuery',
	INITIAL_AGG: 'mainIssueAggQuery',
	EARLY_FETCH_NON_CRITICAL_GIRA: 'earlyFetchIssueViewNonCriticalGiraQuery',
	NON_CRITICAL_GIRA: 'issueViewNonCriticalGiraQuery',
	VIEW_SCREEN_ID: 'viewScreenIdQuery',
	FIELD_CONFIGURATION: 'fieldConfigurationQuery',
	FIELD_DESCRIPTION: 'fieldDescriptionQuery',
	ATTACHMENT: 'attachmentsQuery',
} as const;

export type IssueViewFetchOperations =
	(typeof IssueViewFetchOperations)[keyof typeof IssueViewFetchOperations];

export const appendOperationQuery = (operation?: IssueViewFetchOperations | string) =>
	operation ? `?operation=${operation}` : '';

export const getGiraUrl = (operation?: IssueViewFetchOperations | string): string =>
	`/rest/gira/1/${appendOperationQuery(operation)}`;

///---

const defaultOptions = {
	startAt: 0,
	maxResults: DEFAULT_PAGE_SIZE,
	orderField: DEFAULT_ORDER_FIELD,
	orderDirection: DEFAULT_ORDER_DIRECTION,
} as const;

export const fetchAttachmentsRequest = async (
	issueKey: IssueKey,
	options?: AttachmentsQueryOptions,
): Promise<AttachmentsResponse | null> => {
	const mergedOptions = {
		...defaultOptions,
		...options,
	};

	const response = await performPostRequest(getGiraUrl('attachmentsQuery'), {
		body: JSON.stringify({
			query: attachmentsQuery(),
			variables: { issueKey, ...mergedOptions },
		}),
	});

	const attachmentsResponse = response?.data?.viewIssue?.attachments;
	if (
		attachmentsResponse &&
		attachmentsResponse.nodes &&
		attachmentsResponse.totalCount !== undefined
	) {
		return response.data.viewIssue.attachments;
	}
	return null;
};

export const deleteAttachmentRequest = async (attachmentId: AttachmentId) =>
	performDeleteRequest(`/rest/api/2/attachment/${attachmentId}`);
