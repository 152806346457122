import type { StoreApi } from './types.tsx';

export const onClickAddFormButton =
	() =>
	({ setState, getState }: StoreApi) => {
		const currentCount = getState().issueViewAddFormButtonClickedCount;
		setState({
			issueViewAddFormButtonClickedCount: currentCount + 1,
		});
	};

export const resetAddFormButtonCount =
	() =>
	({ setState }: StoreApi) => {
		setState({
			issueViewAddFormButtonClickedCount: 0,
		});
	};
