import head from 'lodash/head';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types.tsx';

export const updatePlayField =
	(fieldKey: string, maxSpend: number) =>
	async (
		{ getState, setState }: StoreActionApi<State>,
		{ issueTypeIds, onFieldUpdateFailed, fieldRemote, createAnalyticsEvent }: Props,
	) => {
		const state = getState();
		const field = state.fields[fieldKey];

		try {
			if (field?.play?.id === undefined) {
				onFieldUpdateFailed(new Error('Updated failed: field without id cannot be updated'));
				return;
			}

			await fieldRemote.updatePlayField({
				id: field.play.id,
				parameters: { maxSpend },
			});

			const issueTypeId = head(issueTypeIds);

			if (issueTypeId === undefined) {
				throw new Error('cannot update play field for unknown issue type');
			}

			const oldMaxSpend = field.play?.parameters?.maxSpend;
			await fieldRemote.updateFieldConfiguration({
				fieldKey,
				issueTypeId,
				configuration: {
					maxSpend,
				},
				oldConfiguration: oldMaxSpend ? { maxSpend: oldMaxSpend } : {},
				type: 'updatePlayField',
			});

			setState({
				fields: {
					...state.fields,
					[fieldKey]: {
						...field,
						play: {
							// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							...field.play!,
							parameters: {
								...field.play?.parameters,
								maxSpend,
							},
						},
					},
				},
			});

			fireTrackAnalytics(createAnalyticsEvent({}), 'issueFields updated', {
				updatedItems: [{ name: 'play.maxSpend' }],
				issueFieldKey: fieldKey,
				issueFieldType: field?.type,
			});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			onFieldUpdateFailed(error);
		}
	};
