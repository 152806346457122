/**
 * @generated SignedSource<<67e9e0598d624bf3dda2b88464272620>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutSecurityLevelField_IssueViewSecurityLevelField$data = {
  readonly " $fragmentSpreads": FragmentRefs<"field_issueViewLayoutSecurityLevelField_IssueViewSecurityLevelFieldView">;
  readonly " $fragmentType": "ui_issueViewLayoutSecurityLevelField_IssueViewSecurityLevelField";
};
export type ui_issueViewLayoutSecurityLevelField_IssueViewSecurityLevelField$key = {
  readonly " $data"?: ui_issueViewLayoutSecurityLevelField_IssueViewSecurityLevelField$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutSecurityLevelField_IssueViewSecurityLevelField">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutSecurityLevelField_IssueViewSecurityLevelField",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "field_issueViewLayoutSecurityLevelField_IssueViewSecurityLevelFieldView"
    }
  ],
  "type": "JiraIssue"
};

(node as any).hash = "6ea522fee390d752c15ce6a108e1ed5a";

export default node;
