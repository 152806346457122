import { ATTACHMENT_PARENT_ISSUE } from '@atlassian/jira-issue-attachments-store/src/common/constants.tsx';
import type {
	AttachmentId,
	Attachment,
} from '@atlassian/jira-issue-attachments-store/src/common/types.tsx';
import { useIssueKey, useProjectKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useProjectPermissions } from '@atlassian/jira-project-permissions-service/src/main.tsx';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { useIssueAttachments } from '../services/attachments-service/main.tsx';

export type CanDeleteAttachmentMap = Record<AttachmentId, boolean>;

export type InjectedProps = {
	canDeleteAttachmentMap: CanDeleteAttachmentMap;
	onDeleteAttachment: (arg1: AttachmentId) => void;
};

export type GetCanDeleteAttachmentMapArg = {
	attachments: Attachment[];
	canDeleteAllAttachments: boolean;
	canDeleteOwnAttachments: boolean;
	accountIdLoggedInUser: AccountId | null;
};

const getCanDeleteAttachmentMap = ({
	attachments,
	canDeleteAllAttachments,
	canDeleteOwnAttachments,
	accountIdLoggedInUser,
}: GetCanDeleteAttachmentMapArg): CanDeleteAttachmentMap =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	attachments.reduce<Record<string, any>>(
		(acc, attachment) => ({
			// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
			...acc,
			[attachment.id]:
				attachment.parentName === ATTACHMENT_PARENT_ISSUE &&
				(canDeleteAllAttachments ||
					(accountIdLoggedInUser !== null &&
						attachment.author !== null &&
						canDeleteOwnAttachments &&
						attachment.author.accountId === accountIdLoggedInUser)),
		}),
		{},
	);

export const useCanDeleteAttachment = () => {
	const issueKey = useIssueKey();
	const [
		{
			value: { attachments },
		},
	] = useIssueAttachments(issueKey);

	const projectKey = useProjectKey();
	const [permissions] = useProjectPermissions(projectKey);

	const accountIdLoggedInUser = useAccountId();
	const canDeleteAttachmentMap = getCanDeleteAttachmentMap({
		attachments,
		canDeleteAllAttachments: permissions.canDeleteAllAttachments,
		canDeleteOwnAttachments: permissions.canDeleteOwnAttachments,
		accountIdLoggedInUser,
	});
	return canDeleteAttachmentMap;
};
