/**
 * @generated SignedSource<<1fc75daa9ce2f0888b1c9907fb559918>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type prefetchFieldSuggestions_issueView_MaybePrefetchFieldSuggestions$data = {
  readonly assignee: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly user?: {
          readonly accountId: string;
        } | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly reporter: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly user?: {
          readonly accountId: string;
        } | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly status: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly status?: {
          readonly statusCategory: {
            readonly statusCategoryId: string;
          } | null | undefined;
        };
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "prefetchFieldSuggestions_issueView_MaybePrefetchFieldSuggestions";
};
export type prefetchFieldSuggestions_issueView_MaybePrefetchFieldSuggestions$key = {
  readonly " $data"?: prefetchFieldSuggestions_issueView_MaybePrefetchFieldSuggestions$data;
  readonly " $fragmentSpreads": FragmentRefs<"prefetchFieldSuggestions_issueView_MaybePrefetchFieldSuggestions">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "concreteType": "JiraIssueFieldEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "accountId"
                  }
                ]
              }
            ],
            "type": "JiraSingleSelectUserPickerField"
          }
        ]
      }
    ]
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "prefetchFieldSuggestions_issueView_MaybePrefetchFieldSuggestions",
  "selections": [
    {
      "alias": "status",
      "args": [
        {
          "kind": "Literal",
          "name": "ids",
          "value": [
            "status"
          ]
        }
      ],
      "concreteType": "JiraIssueFieldConnection",
      "kind": "LinkedField",
      "name": "fieldsById",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraIssueFieldEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "concreteType": "JiraStatus",
                      "kind": "LinkedField",
                      "name": "status",
                      "plural": false,
                      "selections": [
                        {
                          "concreteType": "JiraStatusCategory",
                          "kind": "LinkedField",
                          "name": "statusCategory",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "ScalarField",
                              "name": "statusCategoryId"
                            }
                          ]
                        }
                      ]
                    }
                  ],
                  "type": "JiraStatusField"
                }
              ]
            }
          ]
        }
      ],
      "storageKey": "fieldsById(ids:[\"status\"])"
    },
    {
      "alias": "reporter",
      "args": [
        {
          "kind": "Literal",
          "name": "ids",
          "value": [
            "reporter"
          ]
        }
      ],
      "concreteType": "JiraIssueFieldConnection",
      "kind": "LinkedField",
      "name": "fieldsById",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": "fieldsById(ids:[\"reporter\"])"
    },
    {
      "alias": "assignee",
      "args": [
        {
          "kind": "Literal",
          "name": "ids",
          "value": [
            "assignee"
          ]
        }
      ],
      "concreteType": "JiraIssueFieldConnection",
      "kind": "LinkedField",
      "name": "fieldsById",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": "fieldsById(ids:[\"assignee\"])"
    }
  ],
  "type": "JiraIssue"
};
})();

(node as any).hash = "e39bf73746b3b6b0cdd0481b31104c8f";

export default node;
