import React, { type ComponentPropsWithRef, useEffect } from 'react';
import { Field } from '@atlaskit/form';
import { useFieldContainerContext } from '../../controllers/injections-provider/index.tsx';
import { generateKeyForField } from '../../utils/generate-key-for-field/index.tsx';

type Props<T> = {
	defaultValue?: T;
	label: string;
	fieldName: string;
	fieldType: string;
	isRequired: boolean;
	isDisabled: boolean;
	onValidate?: (
		value: T,
		isRequired: boolean,
		type: string | undefined,
	) => string | undefined | Promise<string | undefined>;
	children: ComponentPropsWithRef<typeof Field<T>>['children'];
	id: string;
	renderer?: string;
	shouldHideLabel?: boolean;
};

export const FieldContainer = <T,>({
	defaultValue,
	label,
	// TODO: rename `fieldName` to `fieldId`, thats what is being passed from `JiraIssueFieldFormComponent`
	fieldName,
	fieldType,
	isRequired,
	isDisabled,
	onValidate,
	children,
	id,
	renderer,
	shouldHideLabel = false,
}: Props<T>) => {
	const { capturing, overriding } = useFieldContainerContext();

	// This effect is an extensibility public API of this FieldContainer component.
	// It notifies asynchronously some component upstream about the most recent values
	// of its properties.
	// If injections provider is not present they fall back to noop functions.
	// Designed initially to support UI modifications.

	useEffect(() => {
		capturing.captureFieldId(fieldName);
		capturing.captureFieldType(fieldType);
		capturing.captureLabel(label);
		capturing.captureIsDisabled(isDisabled);
		capturing.captureIsRequired(isRequired);
		capturing.captureRenderer(renderer);
	}, [fieldName, fieldType, defaultValue, isDisabled, isRequired, label, capturing, renderer]);

	const isRequiredOverride = overriding.overrideIsRequired(isRequired);

	return (
		<Field
			testId={`issue-transition.ui.modal.field-renderer.field.${fieldName}`}
			defaultValue={defaultValue}
			name={fieldName}
			key={generateKeyForField({ fieldId: fieldName, isRequiredOverride })}
			validate={(value) =>
				typeof onValidate === 'function'
					? onValidate(
							// TODO Make sure this type assertion is correct
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							value as T,
							isRequiredOverride,
							fieldType,
						)
					: undefined
			}
			// Those properties use overrides provided by injections context.
			// If injections provider is not present they fall back to identity functions.
			label={shouldHideLabel ? '' : overriding.overrideLabel(label)}
			elementAfterLabel={overriding.overrideElementAfterLabel(null)}
			isDisabled={overriding.overrideIsDisabled(isDisabled)}
			isRequired={isRequiredOverride}
			id={id}
		>
			{children}
		</Field>
	);
};
