import React, { useCallback } from 'react';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const FindOutMore = ({ isServiceDeskProject }: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const onClick = useCallback(() => {
		const analyticsEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'dropdownItem',
		});
		fireUIAnalytics(analyticsEvent, 'findOutMore');
	}, [createAnalyticsEvent]);
	const href = isServiceDeskProject
		? '//support.atlassian.com/jira-service-desk-cloud/docs/changes-to-requests-in-the-new-issue-view/'
		: '//confluence.atlassian.com/display/JIRACORECLOUD/The+new+Jira+issue+view';

	return (
		<DropdownItem onClick={onClick} href={href} target="_blank" key="find-out-more">
			{formatMessage(messages.findOutMore)}
		</DropdownItem>
	);
};
export default FindOutMore;
