import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

// TODO: migrate to object syntax. Autofix is available for many cases. Remove the eslint-disable for @atlaskit/design-system/no-styled-tagged-template-expression to check.
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled
export const ButtonContent = styled.div<{
	isActive: boolean;
	hasSecurityLevel: boolean;
}>`
	display: flex;
	align-items: center;
	color: ${
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(props: any) => {
			if (props.isActive) {
				return token('color.icon.brand', colors.N0);
			}
			if (props.hasSecurityLevel) {
				return token('color.icon.danger', colors.R400);
			}

			return null;
		}
	};
`;
