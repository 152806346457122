import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	lightRed: {
		id: 'servicedesk-customer-service-common.color-picker-palette.light-red',
		defaultMessage: 'Light red',
		description: 'One of the colors shown on the color picker palette for choosing a tag color.',
	},
	lightOrange: {
		id: 'servicedesk-customer-service-common.color-picker-palette.light-orange',
		defaultMessage: 'Light orange',
		description: 'One of the colors shown on the color picker palette for choosing a tag color.',
	},
	lightYellow: {
		id: 'servicedesk-customer-service-common.color-picker-palette.light-yellow',
		defaultMessage: 'Light yellow',
		description: 'One of the colors shown on the color picker palette for choosing a tag color.',
	},
	lightGreen: {
		id: 'servicedesk-customer-service-common.color-picker-palette.light-green',
		defaultMessage: 'Light green',
		description: 'One of the colors shown on the color picker palette for choosing a tag color.',
	},
	lightBlue: {
		id: 'servicedesk-customer-service-common.color-picker-palette.light-blue',
		defaultMessage: 'Light blue',
		description: 'One of the colors shown on the color picker palette for choosing a tag color.',
	},
	lightPurple: {
		id: 'servicedesk-customer-service-common.color-picker-palette.light-purple',
		defaultMessage: 'Light purple',
		description: 'One of the colors shown on the color picker palette for choosing a tag color.',
	},
	lightGrey: {
		id: 'servicedesk-customer-service-common.color-picker-palette.light-grey',
		defaultMessage: 'Light grey',
		description: 'One of the colors shown on the color picker palette for choosing a tag color.',
	},
	red: {
		id: 'servicedesk-customer-service-common.color-picker-palette.red',
		defaultMessage: 'Red',
		description: 'One of the colors shown on the color picker palette for choosing a tag color.',
	},
	orange: {
		id: 'servicedesk-customer-service-common.color-picker-palette.orange',
		defaultMessage: 'Orange',
		description: 'One of the colors shown on the color picker palette for choosing a tag color.',
	},
	yellow: {
		id: 'servicedesk-customer-service-common.color-picker-palette.yellow',
		defaultMessage: 'Yellow',
		description: 'One of the colors shown on the color picker palette for choosing a tag color.',
	},
	green: {
		id: 'servicedesk-customer-service-common.color-picker-palette.green',
		defaultMessage: 'Green',
		description: 'One of the colors shown on the color picker palette for choosing a tag color.',
	},
	blue: {
		id: 'servicedesk-customer-service-common.color-picker-palette.blue',
		defaultMessage: 'Blue',
		description: 'One of the colors shown on the color picker palette for choosing a tag color.',
	},
	purple: {
		id: 'servicedesk-customer-service-common.color-picker-palette.purple',
		defaultMessage: 'Purple',
		description: 'One of the colors shown on the color picker palette for choosing a tag color.',
	},
	grey: {
		id: 'servicedesk-customer-service-common.color-picker-palette.grey',
		defaultMessage: 'Grey',
		description: 'One of the colors shown on the color picker palette for choosing a tag color.',
	},
});
