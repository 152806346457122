/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//Hungarian
export default {
  'fabric.elements.share.copied.to.clipboard.message': 'Hivatkozás átmásolva a vágólapra',
  'fabric.elements.share.copylink.button.text': 'Hivatkozás másolása',
  'fabric.elements.share.copypubliclink.button.text': 'Publikus link másolása',
  'fabric.elements.share.failure.icon.label': 'Hiba ikon',
  'fabric.elements.share.failure.message': 'Nem lehet megosztani',
  'fabric.elements.share.form.comment.label': 'Üzenet (nem kötelező)',
  'fabric.elements.share.form.comment.placeholder': 'Van bármi, amit érdemes tudni?',
  'fabric.elements.share.form.info.message.no.invite.confluence': 'A címzettek látják az oldal nevét és az üzenetedet',
  'fabric.elements.share.form.info.message.no.invite.jira': 'A címzettek látják az ügy nevét és az üzenetedet',
  'fabric.elements.share.form.no-permissions': 'Nincs lehetőséged a megosztásra.',
  'fabric.elements.share.form.public.send': 'Publikus link küldése',
  'fabric.elements.share.form.public.share': 'Nyilvános link megosztása',
  'fabric.elements.share.form.required-field.summary': 'A kötelező mezők csillaggal vannak megjelölve',
  'fabric.elements.share.form.retry': 'Újra',
  'fabric.elements.share.form.send': 'Küldés',
  'fabric.elements.share.form.share': 'Megosztás',
  'fabric.elements.share.form.title': 'Megosztás',
  'fabric.elements.share.form.user-picker.add-more': 'Továbbiak megadása',
  'fabric.elements.share.form.user-picker.label.confluence': 'Nevek, csapatok, csoportok vagy e-mail-címek',
  'fabric.elements.share.form.user-picker.label.email-disabled-confluence': 'Nevek, csapatok vagy csoportok',
  'fabric.elements.share.form.user-picker.label.email-disabled-jira': 'Nevek vagy csapatok',
  'fabric.elements.share.form.user-picker.label.email-only': 'E-mail-címek',
  'fabric.elements.share.form.user-picker.label.jira': 'Nevek, csapatok vagy e-mail-címek',
  'fabric.elements.share.form.user-picker.no-options.existingUserOnly':
    'Nincs találat a következő keresésre: „{inputValue}”.',
  'fabric.elements.share.form.user-picker.no-options.generic':
    'Nincs találat a következő keresésre: „{inputValue}”. Hívj meg embereket az e-mail-címük alapján.',
  'fabric.elements.share.form.user-picker.placeholder.confluence': 'pl. Mária, Narancs csapat, egyes-csoport',
  'fabric.elements.share.form.user-picker.placeholder.email-disabled-jira': 'pl. Mária, Narancs csapat',
  'fabric.elements.share.form.user-picker.placeholder.email-only': 'pl. maria@vallalat.hu',
  'fabric.elements.share.form.user-picker.placeholder.jira': 'pl. Mária, Narancs csapat, maria@vallalat.hu',
  'fabric.elements.share.form.user-picker.validation.required':
    'Válassz ki legalább egy felhasználót, csoportot, csapatot vagy e-mail-címet.',
  'fabric.elements.share.form.user-picker.validation.required-message.confluence':
    'Válassz ki legalább egy személyt, csapatot, csoportot vagy e-mail-címet',
  'fabric.elements.share.form.user-picker.validation.required-message.email-disabled-confluence':
    'Válassz ki legalább egy személyt, csapatot vagy csoportot',
  'fabric.elements.share.form.user-picker.validation.required-message.email-disabled-jira':
    'Válassz ki legalább egy személyt vagy csapatot',
  'fabric.elements.share.form.user-picker.validation.required-message.email-only':
    'Válassz ki legalább egy e-mail-címet',
  'fabric.elements.share.form.user-picker.validation.required-message.jira':
    'Válassz ki legalább egy személyt, csapatot vagy e-mail-címet',
  'fabric.elements.share.in.integration.button': 'Megosztás itt: {integrationName}',
  'fabric.elements.share.invite.to.confluence': 'Meghívás a Confluence-be',
  'fabric.elements.share.invite.to.jira': 'Meghívás a Jirába',
  'fabric.elements.share.main.tab.text.confluence': 'Oldal megosztása',
  'fabric.elements.share.main.tab.text.jira': 'Ügy megosztása',
  'fabric.elements.share.menu.email.label': 'E-mail',
  'fabric.elements.share.menu.slack.label': 'Slack',
  'fabric.elements.share.popup.label': 'Megosztás',
  'fabric.elements.share.success.message':
    '{object, select,blogpost {Blogposzt megosztva}board {Tábla megosztva}calendar {Naptár megosztva}draft {Piszkozat megosztva}filter {Szűrő megosztva}issue {Ügy megosztva}summary {Összefoglaló megosztva}list {Lista megosztva}timeline {Idővonal megosztva}form {Űrlap megosztva}media {Média megosztva}page {Oldal megosztva}project {Projekt megosztva}pullrequest {Pull-kérelem megosztva}question {Kérdés megosztva}report {Jelentés megosztva}repository {Adattár megosztva}request {Kérelem megosztva}roadmap {Idővonal megosztva}site {Webhely megosztva}space {Munkatér megosztva}other {Link megosztva}}',
  'fabric.elements.share.to.integration.button': 'Megosztás itt: {integrationName}',
  'fabric.elements.share.trigger.button.icon.label': 'Ikon megosztása',
  'fabric.elements.share.trigger.button.invite.text': 'Meghívás',
  'fabric.elements.share.trigger.button.text': 'Megosztás',
  'fabric.elements.share.trigger.button.tooltip.text': 'Megosztás',
};
