export const BUILD = 'BUILD' as const;
export const DEPLOYMENT = 'DEPLOYMENT' as const;
export const SECURITY = 'SECURITY' as const;

export const getFallbackConfigurationUrl = (providerId: string) =>
	`/plugins/servlet/upm#manage/${providerId}`;

export const BITBUCKET_APP_ID = 'bitbucket';
export const AZURE_DEVOPS_FOR_JIRA_APP_ID = 'azure-devops-for-jira';
export const GITHUB_DEV_APP_ID = 'com.github.integration.development';
export const GITHUB_APP_ID = 'com.github.integration.production';
export const GITHUB_STG_APP_ID = 'com.github.integration.staging';
export const GITLAB_APP_ID = 'gitlab-jira-connect-gitlab.com';

export const CIRCLE_CI_APP_ID = 'circleci.jira';
export const GIT_INTEGRATION_APP_ID = 'com.xiplink.jira.git.jira_git_plugin';
export const HARNESS_CI_APP_ID = 'com.harness.ci.plugin.jira';
export const JENKINS_APP_ID = 'com.atlassian.jenkins-for-jira';
export const JFROG_APP_ID = 'jfrog-artifactory-saas-integration';
export const OCTOPUS_DEPLOY_APP_ID = 'com.octopus.jiraconnectapp.production';
export const STACK_HAWK_APP_ID = 'stackhawk-jira-connect-prod';
export const JSM_APP_ID = 'com.plugins4people.jsd.jsd-config-importer';

export const SNYK_APP_ID = 'snyk.in.jira';
export const SNYK_STG_APP_ID = 'jira-devops-security-app-stg';
export const STACKHAWK_APP_ID = 'stackhawk-jira-connect-prod';
export const MEND_APP_ID = 'WhiteSource-Jira-Cloud';
export const LACEWORK_APP_ID = 'lacework-vuln-jira-connect';
export const JFROG_SECURITY_APP_ID = 'jfrog.security.jiraSecurityInfoProvider';
export const SYSDIG_APP_ID = 'sysdigAppKey';

// Design
export const FIGMA_APP_ID = 'com.figma.jira-add-on-dev';

export const FULLY_SUPPORTED_APPS = [
	BITBUCKET_APP_ID,
	GITHUB_APP_ID,
	GITHUB_STG_APP_ID,
	SNYK_APP_ID,
	SNYK_STG_APP_ID,
];

export const SECURITY_KNOWN_APPS = [
	JFROG_APP_ID,
	STACK_HAWK_APP_ID,
	SNYK_APP_ID,
	SNYK_STG_APP_ID,
	STACKHAWK_APP_ID,
	MEND_APP_ID,
	LACEWORK_APP_ID,
	JFROG_SECURITY_APP_ID,
	SYSDIG_APP_ID,
];

export const KNOWN_APPS = [
	BITBUCKET_APP_ID,
	AZURE_DEVOPS_FOR_JIRA_APP_ID,
	GITHUB_DEV_APP_ID,
	GITHUB_APP_ID,
	GITHUB_STG_APP_ID,
	GITLAB_APP_ID,
	CIRCLE_CI_APP_ID,
	GIT_INTEGRATION_APP_ID,
	HARNESS_CI_APP_ID,
	JENKINS_APP_ID,
	OCTOPUS_DEPLOY_APP_ID,
	JSM_APP_ID,
	FIGMA_APP_ID,
	...SECURITY_KNOWN_APPS,
];

export const isGitHubApp = (appId: string): boolean =>
	[GITHUB_DEV_APP_ID, GITHUB_APP_ID, GITHUB_STG_APP_ID].includes(appId);

export const isBitbucketApp = (appId: string): boolean => appId === BITBUCKET_APP_ID;
