import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/switchMap';
import type { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import {
	type RequiresFullCreateCheckRequestAction,
	REQUIRES_FULL_CREATE_CHECK,
	requiresFullCreateCheckFailed,
} from '../../state/context/actions.tsx';
import { getProjectId } from '../../state/context/selectors.tsx';
import type { State } from '../../state/types.tsx';
import hasValidatorsByIssueType from './has-validators-by-issue-type.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	action$: ActionsObservable<RequiresFullCreateCheckRequestAction>,
	store: MiddlewareAPI<State>,
) =>
	action$.ofType(REQUIRES_FULL_CREATE_CHECK).switchMap(() => {
		const state = store.getState();

		const projectId = getProjectId(state);

		if (!projectId) {
			return Observable.of(requiresFullCreateCheckFailed());
		}

		return hasValidatorsByIssueType(projectId);
	});
