import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fireScreenAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { sendPendoTrackEvent } from '../../pendo/index.tsx';

export const MatrixView = {
	opened: (event: UIAnalyticsEvent, accessLevel?: string) => {
		// https://app.pendo.io/s/4758009750487040/trackevents/5PATMUbxnbpQjTatZdHcElQVLqw
		sendPendoTrackEvent({
			actionSubjectAndAction: 'matrixView opened',
			attributes: { accessLevel: accessLevel || '' },
		});

		// https://data-portal.internal.atlassian.com/analytics/registry/51088
		fireScreenAnalytics(event, { accessLevel });
	},
	fieldUpdatedByDragWithinXAxis: (fieldType: string | undefined) => {
		sendPendoTrackEvent(`fieldValue updated ${fieldType} matrixDragXAxis`);
	},
	fieldUpdatedByDragWithinYAxis: (fieldType: string | undefined) => {
		sendPendoTrackEvent(`fieldValue updated ${fieldType} matrixDragYAxis`);
	},
};
