import { useCallback, useState } from 'react';

export type Operation<TValue> = { operation: 'ADD' | 'REMOVE' | 'SET'; value: TValue[] };

/**
 * Creates a hook for managing the dirty state and mutation operations state of a multi-select Jira-field.
 *
 * Multi select fields in Jira are expected to perform mutations by submitting an array of "Operations" (ADD, REMOVE and SET)
 * The naiive solution is to simply send a SET operation with the new value.
 * But this causes problems if the multi-value field is properly paginated. In that case, you can't use SET because you can't assume you know the full set of current values (otherwise you will chop off values you hadn't loaded yet when you set the new value).
 * You need to use ADD and REMOVE to report the users interactions while they edited the field.
 * <p/> Managing the ADD and REMOVE operations is somewhat complex and needs to be done in parallel with the dirty-state, so it's been extracted to a generic utility here for all multi-select field components to use.
 */
export const useMultiSelectFieldDirtyValueOperations = <TValue,>(): [
	{
		/**
		 * Component state reflecting the current value of the multi-select component. Will update to reflect whatever is passed in though `onChange`
		 */
		dirtyValue: TValue[];
		/**
		 * Component state reflecting the "operations" which should be used to update the field on the server to reflect the actions that the user has performed with `onChange` since `initializeDirtyValue` was called
		 */
		operations: Operation<TValue>[];
	},
	{
		/**
		 * Callback to be fired whenever the user modifies the value of the multi-select field
		 * @param newValue Takes the new value of the field after the user modified it
		 */
		onChange: (newValue: TValue[]) => void;
		/**
		 * Callback to initialise the dirty value to the current value supplied, and clear the operations state.
		 * Should generally be called as the user starts an inline-editing experience
		 */
		initializeDirtyValue: (currentValue: TValue[]) => void;
		// todo JIV-13917 Either 1. add a new callback for updating the dirty value after loading more pages of data for the current value or 2. add the current value as a arg to the hook and have a useEffect which listens to it to update the operations appropriately
		// This should update the operations in a special way:
		//  - do not add anything to the operations
		//  - if anything, remove any already-present values from the current 'ADD' operation if there are any matches
	},
] => {
	const [dirtyValue, setDirtyValue] = useState<TValue[]>([]);
	const [operations, setOperations] = useState<Operation<TValue>[]>([]);

	const initializeDirtyValue = useCallback((currentValue: TValue[]) => {
		setDirtyValue(currentValue);
		setOperations([]);
	}, []);

	const onChange = useCallback((newValue: TValue[]) => {
		// todo JIV-13917: compare the new values to previous state. convert in to a discrete combination of add, remove, and set operations.
		// Eg: User adds item A and removes item B -> results in -> [{operation: 'REMOVE', value: ['B']}, {operation: 'ADD', value: ['A']}]
		// Eg: User swaps the order of items A and B to [B,A] -> results in -> [{operation: 'REMOVE', value: ['A', 'B']}, {operation: 'ADD', value: ['B', 'A']}]
		// Eg: User clears the value of the field (using x button) then adds item A -> results in -> [{operation: 'SET', value: []}, {operation: 'ADD', value: ['A']}]
		// Rules:
		// If the user clears the field value, first operation must be SET to []
		// Any permutation of any number of single-value-add events, single-value-remove events or re-ordering events can be simplified down to a single `ADD` and `REMOVE` operation
		//
		// This will end up a fair bit more complex than it is now, but it will allow us to avoid bugs where we accidentally chop off values past the initial page
		setOperations([{ operation: 'SET', value: newValue }]);
		setDirtyValue(newValue);
	}, []);

	return [
		{ dirtyValue, operations },
		{ onChange, initializeDirtyValue },
	];
};
