import { useCallback, useMemo, useState } from 'react';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useFlagsService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { useIntl } from '@atlassian/jira-intl';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';

/**
 * Boolean flag that will be `true` to signify that a server validation error is present, or `false` if there are no
 * validation errors.
 */
type State = boolean;

type Actions = {
	/**
	 * Callback function that will set server validation error state to `true` and display an error flag with the
	 * provided `message`, or a generic flag description if `message` is undefined.
	 */
	handleSubmitFailed: (error?: Error, message?: string) => void;
	/**
	 * Set server validation error state to `false` to signify there are no validation errors.
	 */
	clearServerValidation: () => void;
};

/**
 * Returns a `[State, Actions]` tuple to handle network or GraphQL error encountered when trying to update an inline
 * edit field.
 *
 * @param fieldName Name of the issue field that failed to update.
 * @param setIsEditing Function to toggle editing state of the inline edit component.
 * @param onSubmitFailed Optional callback to notify consumers of an error.
 */
export const useServerValidationHandler = (
	fieldName: string,
	setIsEditing?: (isEditing: boolean) => void,
	onSubmitFailed?: (error?: Error) => void,
): [State, Actions] => {
	const { formatMessage } = useIntl();
	const { dismissFlag, showFlag } = useFlagsService();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [hasServerValidationError, setHasServerValidationError] = useState(false);

	const handleSubmitFailed = useCallback(
		(error?: Error, message?: string) => {
			setHasServerValidationError(true);
			const flagId = showFlag({
				type: 'error',
				title: formatMessage(
					fg('relay-migration-issue-fields-number')
						? messages.submitFailedTitle
						: messages.submitFailedTitleOld,
					{ fieldName },
				),
				description: message ?? formatMessage(messages.submitFailedDescription),
				testId: fg('relay-migration-issue-fields-assignee-fg')
					? 'issue-field-validation-handler.controllers.server-validation-handler.submit-failed-flag'
					: undefined,
				// eslint-disable-next-line no-nested-ternary
				actions: fg('relay-migration-issue-header-and-parent')
					? setIsEditing
						? [
								{
									content: messages.submitFailedTryAgain,
									onClick: () => {
										setIsEditing(true);
										flagId && dismissFlag(flagId);
									},
								},
							]
						: undefined
					: [
							{
								content: messages.submitFailedTryAgain,
								onClick: () => {
									setIsEditing?.(true);
									flagId && dismissFlag(flagId);
								},
							},
						],
			});
			fireErrorAnalytics({
				meta: {
					id: 'serverValidationHandler',
					packageName: 'jiraIssueFieldValidationHandler',
					teamName: 'empanada',
				},
				event: createAnalyticsEvent({}),
				error,
				attributes: {
					fieldName,
				},
				sendToPrivacyUnsafeSplunk: true,
			});
			if (fg('relay-migration-issue-fields-assignee-fg')) {
				onSubmitFailed?.(error);
			} else {
				onSubmitFailed?.();
			}
		},
		[
			dismissFlag,
			fieldName,
			formatMessage,
			onSubmitFailed,
			setIsEditing,
			showFlag,
			createAnalyticsEvent,
		],
	);

	const clearServerValidation = useCallback(() => setHasServerValidationError(false), []);

	return useMemo(
		() => [hasServerValidationError, { handleSubmitFailed, clearServerValidation }],
		[hasServerValidationError, handleSubmitFailed, clearServerValidation],
	);
};
