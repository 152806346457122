import React from 'react';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import { commentActivityItem } from '@atlassian/jira-issue-activity-feed/src/constants.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { STATUS } from '@atlassian/jira-issue-view-configurations/src/index.tsx';
import { actionDispatchToProps as quickAddItemDispatchToProps } from '@atlassian/jira-issue-view-foundation/src/quick-add/quick-add-items/index.tsx';
import { fieldOptions as statusFieldOptions } from '@atlassian/jira-issue-view-foundation/src/status/view.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { setSelectedActivityItem } from '@atlassian/jira-issue-view-store/src/actions/activity-feed-actions.tsx';
import { addCommentFormExpand } from '@atlassian/jira-issue-view-store/src/actions/comment-actions.tsx';
import { forgeIssueActionsSelector } from '@atlassian/jira-issue-view-store/src/ecosystem/forge/forge-extensions-selector.tsx';
import {
	fieldSaveSuccess,
	fieldUpdated,
} from '@atlassian/jira-issue-view-store/src/issue-field/state/actions/field-save-actions.tsx';
import { hasParentIssueSelector } from '@atlassian/jira-issue-view-store/src/issue-field/state/selectors/parent-issue-selector.tsx';
import { quickAddItemsPermissionsSelector } from '@atlassian/jira-issue-view-store/src/selectors/issue-actions-group-permissions-selector.tsx';
import { ContextualAnalyticsData, MODAL } from '@atlassian/jira-product-analytics-bridge';
import type { DialogStateProps, DialogDispatchProps, Props } from './types.tsx';
import RegisterShortcutsDialogHelper from './view.tsx';

const RegisterShortcutsDialog = (props: Props) => (
	<ErrorBoundary id="issue-register-shortcuts-dialog" packageName="issue">
		<ContextualAnalyticsData sourceName="dotDialog" sourceType={MODAL}>
			<RegisterShortcutsDialogHelper {...props} />
		</ContextualAnalyticsData>
	</ErrorBoundary>
);

export default connect(
	(state: State): DialogStateProps => ({
		...quickAddItemsPermissionsSelector(state),
		forgeIssueActions: forgeIssueActionsSelector(state),
		hasParent: hasParentIssueSelector(state),
	}),
	(dispatch): DialogDispatchProps => ({
		...quickAddItemDispatchToProps(dispatch),
		onChangeWorkflow: (transition, viaDialog) => {
			// @ts-expect-error - TS2554 - Expected 4-5 arguments, but got 3.
			dispatch(fieldSaveSuccess(STATUS, statusFieldOptions, transition.to));
			dispatch(fieldUpdated(STATUS, transition.to, { viaDialog }));
		},
		onAddComment: () => {
			dispatch(setSelectedActivityItem(commentActivityItem));
			dispatch(addCommentFormExpand());
		},
	}),
)(RegisterShortcutsDialog);
