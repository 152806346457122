import React from 'react';
import type { CustomGlyphProps } from '@atlaskit/icon';

export const boardGlyph = (props: CustomGlyphProps) => (
	<svg
		{...props}
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g fill="currentColor">
			<path d="M4 11H12.004C12 11 12 5 12 5H3.996C4 5 4 11 4 11ZM3 4.997C3 4.4465 3.449 4 3.995 4H12.005C12.554 4 13 4.4475 13 4.997V11.003C12.9996 11.267 12.8947 11.52 12.7083 11.7068C12.5218 11.8937 12.269 11.9991 12.005 12H3.995C3.73093 11.9995 3.47785 11.8942 3.29131 11.7073C3.10477 11.5204 3 11.2671 3 11.003V4.997Z" />
			<path d="M6 5V11H7V5H6ZM9 5V11H10V5H9Z" />
		</g>
	</svg>
);
