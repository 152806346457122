import noop from 'lodash/noop';
import { noopJiraUpdateService } from '../../../../field/mapping/common/index.tsx';
import type { FieldMapping } from '../types.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const defaultMapping: FieldMapping<any> = {
	field: undefined,
	getValueFromJiraIssue: () => undefined,
	getFieldValueForJiraUpdate: (value) => (value !== undefined ? value : null),
	setMutable: noop,
	setImmutable: (propertyMaps) => propertyMaps,
	remove: (propertyMaps) => propertyMaps,
	modifyImmutableIfMultiValueField: (propertyMaps) => propertyMaps,
	comparator: () => 0,
	valueAccessor: () => undefined,
	valueAccessorToExport: () => '',
	getAllValues: () => ({}),
	getGroupIdentitiesSelector: (_fieldKey, _issueIdsSelector) => () => ({}),
	getGroupIdentities: () => [],
	allowEmptyGroup: false,
	isMultiValueField: false,
	isSupportedByIssueUpdateApi: false,
	getLabel: () => undefined,
	getFilter: () => undefined,
	...noopJiraUpdateService,
};
