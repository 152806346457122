import { createSelector } from 'reselect';
import isEqual from 'lodash/isEqual';
import keyBy from 'lodash/keyBy';
import set from 'lodash/set';
import type { FieldOption } from '@atlassian/jira-polaris-domain-field/src/field-option/types.tsx';
import type { OptionFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/option/types.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import {
	COUNT_ROLLUP,
	EMPTY_ROLLUP,
	FILLED_ROLLUP,
	AVG_ROLLUP,
	MEDIAN_ROLLUP,
	SUM_ROLLUP,
} from '@atlassian/jira-polaris-domain-field/src/rollup/constants.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { Filter } from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import { isShallowEqual } from '@atlassian/jira-polaris-lib-equals/src/index.tsx';
import type { IssuesRemote } from '@atlassian/jira-polaris-remote-issue/src/controllers/types.tsx';
import { jiraOptionsMapping } from '../../../../field/mapping/options/index.tsx';
import type { State, PropertyMaps } from '../../../types.tsx';
import {
	createCombinedComparator,
	nullSafeComparator,
	numberListComparator,
	weightedListComparator,
} from '../../comparators/index.tsx';
import { CSV_SEPARATOR } from '../common/constanst.tsx';
import { createStringValueIntersectionFilter } from '../common/filter-utils.tsx';
import { getOptionIndex, getOptionWeight } from '../common/option-utils.tsx';
import { removePropertyValue } from '../common/remove-utils.tsx';
import type { FieldMapping } from '../types.tsx';

export const optionsMapping = (
	issuesRemote: IssuesRemote,
	field: Field,
): FieldMapping<OptionFieldValue[]> => {
	const optionsById = keyBy(field.options, ({ jiraOptionId }) => jiraOptionId);
	const valueAccessor: FieldMapping<OptionFieldValue[]>['valueAccessor'] = (
		state,
		props,
		issueId,
	) => {
		return state.properties.multiSelect[field.key]?.[issueId] || [];
	};
	const valueAccessorToExport: FieldMapping<string>['valueAccessorToExport'] = (
		state,
		props,
		issueId,
		options,
	) => {
		const value = valueAccessor(state, props, issueId);
		const selectedList = value ? value.map((selection) => selection.id) : undefined;
		return selectedList
			? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				(options as FieldOption[])
					.filter((opt) => selectedList.includes(opt.jiraOptionId))
					.map((opt) => opt.value)
					.join(CSV_SEPARATOR)
			: '';
	};

	return {
		...jiraOptionsMapping(issuesRemote, field),
		setMutable: (maps: PropertyMaps, issueId: LocalIssueId, value?: OptionFieldValue[]) =>
			set(maps.multiSelect, [field.key, issueId], value),
		// @ts-expect-error(PARTIAL_RECORD) TS2322 - Type '(maps: PropertyMaps, issueId: LocalIssueId, value?: IssueOptionProperty[]) => { multiSelect: { [x: string]: Record<string, IssueOptionProperty[]> | { [x: string]: IssueOptionProperty[] | undefined; }; }; ... 17 more ...; commentsMetadata: CommentsMetadataMap; }' is not assignable to type '(arg1: PropertyMaps, arg2: string, arg3: IssueOptionProperty[] | undefined) => PropertyMaps'.
		setImmutable: (maps: PropertyMaps, issueId: LocalIssueId, value?: OptionFieldValue[]) => {
			if (maps.multiSelect[field.key] && isEqual(maps.multiSelect[field.key][issueId], value)) {
				return maps;
			}
			return {
				...maps,
				multiSelect: {
					...maps.multiSelect,
					[field.key]: {
						...maps.multiSelect[field.key],
						[issueId]: value,
					},
				},
			};
		},
		remove: (maps: PropertyMaps, issueId: LocalIssueId) =>
			removePropertyValue(field.key, maps, issueId, 'multiSelect'),
		modifyImmutableIfMultiValueField: (
			maps: PropertyMaps,
			issueId: LocalIssueId,
			addValues?: OptionFieldValue[],
			removeValues: OptionFieldValue[] = [],
		) => {
			const oldValues = (maps.multiSelect[field.key] || {})[issueId] || [];
			const newValues = oldValues
				.filter(
					(oldValue) => removeValues.findIndex((removeValue) => oldValue.id === removeValue.id) < 0,
				)
				.concat(
					(addValues || []).filter(
						(newValue) => oldValues.findIndex((oldValue) => oldValue.id === newValue.id) < 0,
					),
				);
			if (isShallowEqual(newValues, oldValues)) {
				return maps;
			}
			return {
				...maps,
				multiSelect: {
					...maps.multiSelect,
					[field.key]: {
						...maps.multiSelect[field.key],
						[issueId]: newValues,
					},
				},
			};
		},
		comparator: createCombinedComparator([
			nullSafeComparator<OptionFieldValue[]>((a, b, direction) =>
				weightedListComparator(
					a.map((optionProperty) => getOptionWeight(field, optionProperty)),
					b.map((optionProperty) => getOptionWeight(field, optionProperty)),
					direction,
				),
			),
			nullSafeComparator<OptionFieldValue[]>((a, b, direction) =>
				numberListComparator(
					a.map((optionProperty) => getOptionIndex(field, optionProperty)),
					b.map((optionProperty) => getOptionIndex(field, optionProperty)),
					direction,
				),
			),
		]),
		valueAccessor,
		valueAccessorToExport,
		getAllValues: (state) => state.properties.multiSelect[field.key] ?? {},
		getGroupIdentitiesSelector: (fieldKey, issueIdsSelector) =>
			createSelector(
				issueIdsSelector,
				(state: State) => state.properties.multiSelect[fieldKey],
				(ids, multiSelect) =>
					ids.reduce(
						(result, issueId) =>
							Object.assign(result, {
								[issueId]:
									multiSelect?.[issueId]?.map((value) => ({
										groupIdentity: value.id,
										value: [value],
									})) ?? [],
							}),
						{},
					),
			),
		getGroupIdentities: (state, _, issueId) =>
			state.properties.multiSelect[field.key]?.[issueId]?.map((value) => ({
				groupIdentity: value.id,
				value: [value],
			})) ?? [],
		getRollupOperations: () => {
			const isWeightedField = field.options?.some(({ weight }) => weight !== 1);

			return isWeightedField
				? [AVG_ROLLUP, MEDIAN_ROLLUP, SUM_ROLLUP]
				: [COUNT_ROLLUP, EMPTY_ROLLUP, FILLED_ROLLUP];
		},
		allowEmptyGroup: true,
		getLabel: (groupIdentity) => optionsById[groupIdentity]?.value,
		getFilter: (filter: Filter) => {
			if (filter.type === 'FIELD' && filter.field === field.key) {
				const stringValueIntersectionFilter = createStringValueIntersectionFilter(filter);
				if (stringValueIntersectionFilter === undefined) {
					return undefined;
				}
				return (value: undefined | Array<OptionFieldValue>, store, props, localIssueId) =>
					stringValueIntersectionFilter(
						value?.map(({ id }) => id),
						store,
						props,
						localIssueId,
					);
			}
			return undefined;
		},
		getWeight: (groupIdentity) => optionsById[groupIdentity]?.weight,
	};
};
