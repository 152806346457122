/**
 * @generated SignedSource<<dd52d707c7a058e99e9b81066163db90>>
 * @relayHash 092e46cc9e0a7d1a875c961e7d462b6c
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 8d0846545f9cb15ee6c45cd16505577afdb90c5d88f6951b7cddb91088e57500

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type JiraRemainingTimeEstimateFieldInput = {
  id: string;
  remainingEstimate: JiraEstimateInput;
};
export type JiraEstimateInput = {
  timeInSeconds: AGG$Long;
};
export type ui_issueFieldRemainingEstimate_RemainingEstimateField_Mutation$variables = {
  input: JiraRemainingTimeEstimateFieldInput;
};
export type ui_issueFieldRemainingEstimate_RemainingEstimateField_Mutation$data = {
  readonly jira: {
    readonly updateRemainingTimeEstimateField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly id: string;
        readonly remainingEstimate: {
          readonly timeInSeconds: AGG$Long | null | undefined;
        } | null | undefined;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type ui_issueFieldRemainingEstimate_RemainingEstimateField_Mutation$rawResponse = {
  readonly jira: {
    readonly updateRemainingTimeEstimateField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly id: string;
        readonly remainingEstimate: {
          readonly timeInSeconds: AGG$Long | null | undefined;
        } | null | undefined;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type ui_issueFieldRemainingEstimate_RemainingEstimateField_Mutation = {
  rawResponse: ui_issueFieldRemainingEstimate_RemainingEstimateField_Mutation$rawResponse;
  response: ui_issueFieldRemainingEstimate_RemainingEstimateField_Mutation$data;
  variables: ui_issueFieldRemainingEstimate_RemainingEstimateField_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "concreteType": "JiraMutation",
    "kind": "LinkedField",
    "name": "jira",
    "plural": false,
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "JiraRemainingTimeEstimateFieldPayload",
        "kind": "LinkedField",
        "name": "updateRemainingTimeEstimateField",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "name": "success"
          },
          {
            "concreteType": "MutationError",
            "kind": "LinkedField",
            "name": "errors",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "message"
              }
            ]
          },
          {
            "concreteType": "JiraRemainingTimeEstimateField",
            "kind": "LinkedField",
            "name": "field",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "id"
              },
              {
                "concreteType": "JiraEstimate",
                "kind": "LinkedField",
                "name": "remainingEstimate",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "timeInSeconds"
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "ui_issueFieldRemainingEstimate_RemainingEstimateField_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ui_issueFieldRemainingEstimate_RemainingEstimateField_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "8d0846545f9cb15ee6c45cd16505577afdb90c5d88f6951b7cddb91088e57500",
    "metadata": {},
    "name": "ui_issueFieldRemainingEstimate_RemainingEstimateField_Mutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "33f245e9370b8f69ea8452e9c6d49a82";

export default node;
