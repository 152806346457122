/** @jsx jsx */
import React, { useEffect } from 'react';
import { styled, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { useTenantHasConfluence } from '@atlassian/jira-business-confluence-pages/src/utils/use-tenant-has-confluence/index.tsx';
import { ConfluenceFeatureKeys } from '@atlassian/jira-confluence-integration-controls/src/constants.tsx';
import { EmbeddedConfluenceSidepanelContainer } from '@atlassian/jira-confluence-integration-controls/src/controllers/use-embedded-confluence-side-panel/index.tsx';
import { useIsCrossSellEnabled } from '@atlassian/jira-confluence-integration-controls/src/controllers/use-is-cross-sell-enabled/index.tsx';
import { useUserHasConfluenceAccess } from '@atlassian/jira-confluence-integration-controls/src/controllers/use-user-has-confluence-access/index.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { JIRA_ISSUE_QUICK_ACTIONS } from '@atlassian/jira-issue-create-confluence-content/src/common/constants/embedded-confluence-source.tsx';
import { CreatePageMenuItem } from '@atlassian/jira-issue-create-confluence-content/src/ui/create-confluence-content-menu/create-page-menu-item/index.tsx';
import { CreateWhiteboardMenuItem } from '@atlassian/jira-issue-create-confluence-content/src/ui/create-confluence-content-menu/create-whiteboard-menu-item/index.tsx';
import { createConfluenceContentMenuEntrypoint } from '@atlassian/jira-issue-create-confluence-content/src/ui/create-confluence-content-menu/entrypoint.tsx';
import type { UIAnalyticsEvent } from '@atlassian/jira-product-analytics-bridge';

type CreateConfluenceContentMenuProps = {
	appearance?: string;
	isQuickActionsListView?: boolean;
	setQuickActionListItem?: (category: string, name: string, newValues: object) => void;
	onLinkPage?: (link: string, analyticsEvent: UIAnalyticsEvent) => void;
	openMenu?: string | null;
};

const ContentMenu = ({
	appearance,
	isQuickActionsListView,
	setQuickActionListItem,
	onLinkPage,
	openMenu,
}: CreateConfluenceContentMenuProps) => {
	const {
		entryPointActions: confluenceContentMenuActions,
		entryPointReferenceSubject: confluenceContentMenuSubject,
	} = useEntryPoint(createConfluenceContentMenuEntrypoint, {});

	const confluenceAccess = useUserHasConfluenceAccess(JIRA_ISSUE_QUICK_ACTIONS).hasAccess;
	const tenantHasConfluence = useTenantHasConfluence();

	const isCrossSellEnabled = useIsCrossSellEnabled(ConfluenceFeatureKeys.CREATE_MENU_TOP_ACTIONS);

	useEffect(() => {
		const shouldLoadMenu =
			!isQuickActionsListView || (isQuickActionsListView && openMenu === 'addButton');

		if (fg('remove_unnecessary_load_confluence_menu')) {
			if (confluenceAccess && shouldLoadMenu) {
				confluenceContentMenuActions.load();
			}
		} else if (confluenceAccess) {
			confluenceContentMenuActions.load();
		}
	}, [
		confluenceAccess,
		confluenceContentMenuActions,
		isCrossSellEnabled,
		isQuickActionsListView,
		openMenu,
		tenantHasConfluence,
	]);

	const componentInternals = (
		<JiraEntryPointContainer
			entryPointReferenceSubject={confluenceContentMenuSubject}
			id="confluenceContentMenuEntryPointContainer"
			packageName="@atlassian/jira-issue-create-confluence-content"
			teamName="confluence-better-together"
			runtimeProps={{
				children: (
					<>
						<CreatePageMenuItem
							isQuickActionsListView={isQuickActionsListView && fg('quick_actions_menu_ga')}
							{...(fg('quick_actions_menu_ga') ? { setQuickActionListItem } : {})}
						/>
						<CreateWhiteboardMenuItem
							isQuickActionsListView={isQuickActionsListView && fg('quick_actions_menu_ga')}
							{...(fg('quick_actions_menu_ga') ? { setQuickActionListItem } : {})}
						/>
					</>
				),

				isOpenByDefault: fg('quick_actions_menu_ga') && isQuickActionsListView,
				isQuickActionsListView,
				hasConfluenceAccess: confluenceAccess,
				appearance,
				embeddedConfluenceSource: JIRA_ISSUE_QUICK_ACTIONS,
				onLinkPage,
			}}
		/>
	);

	if (isQuickActionsListView && fg('quick_actions_menu_ga')) {
		return componentInternals;
	}

	return <ButtonWrapper>{componentInternals}</ButtonWrapper>;
};

export const CreateConfluenceContentMenu = (props: CreateConfluenceContentMenuProps) => {
	const [sidePanelEmbeddedConfluenceConfig] = UNSAFE_noExposureExp(
		'jira_issue_view_side_by_side_modeless_ep_exp',
	);
	const isSidePanelFFEnabled =
		sidePanelEmbeddedConfluenceConfig.get('cohort', 'not-enrolled') === 'experiment';

	return isSidePanelFFEnabled ? (
		<EmbeddedConfluenceSidepanelContainer>
			<ContentMenu {...props} />
		</EmbeddedConfluenceSidepanelContainer>
	) : (
		<ContentMenu {...props} />
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonWrapper = styled.span({
	marginRight: token('space.100', '8px'),
});
