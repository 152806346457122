import { createResource } from '@atlassian/react-resource-router';
import { getIncidentSuggestionEndpoint } from './endpoints/index.tsx';

export const aiContextOpsPanelResource = createResource({
	type: 'AI-CONTEXT-OPS-PANEL-RESOURCE-TYPE',
	getKey: ({ match }) => {
		const issueId = match.params.issueId;
		return `aiContextOpsPanelResource-${issueId}`;
	},
	getData: ({ match }, { tenantContext: { cloudId } }) => {
		const issueId = match.params.issueId;
		const projectId = match.params.projectId;

		if (!issueId) {
			throw new Error('AI context ops panel resource cannot be loaded without an issueId');
		}

		if (!projectId) {
			throw new Error('AI context ops panel resource cannot be loaded without a projectId');
		}

		return getIncidentSuggestionEndpoint({
			cloudId,
			issueId,
			projectId,
		});
	},
	maxAge: 0,
	isBrowserOnly: true,
});
