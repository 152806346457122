import memoizeOne from 'memoize-one';
import sprintsCache from '@atlassian/jira-cache/src/services/sprints/index.tsx';
import type { IntlShapeV2 as IntlShape } from '@atlassian/jira-intl/src/v2/types.tsx';
import { getRapidViewUrl } from '@atlassian/jira-issue-view-common/src/legacy/urls.tsx';
import type { ClosedSprint as TransformedClosedSprint } from '@atlassian/jira-shared-types/src/rest/jira/closed-sprint.tsx';
import messages from './messages.tsx';
import { type SprintFieldValue, findActiveSprint, findFutureSprint } from './utils.tsx';

type Item = {
	content: string;
	value: number;
	description?: string;
	fromCache?: boolean;
};

type ServerItem = {
	name: string;
	id: number;
	stateKey: 'ACTIVE' | 'FUTURE' | 'CLOSED';
	date: string;
	boardName?: string;
	fromCache?: boolean;
};
type TransformedCacheItem = {
	name: string;
	id: number;
	stateKey: 'ACTIVE' | 'FUTURE' | 'CLOSED';
	boardName?: string;
	date: string;
	fromCache?: boolean;
};

type ServerSuggestions = {
	suggestions?: ServerItem[];
	allMatches?: ServerItem[];
};

type SuggestionsItem = {
	content: string;
	value: number;
	id: number;
	description?: string;
	fromCache?: boolean;
};

// @ts-expect-error - TS7006 - Parameter 'sprint' implicitly has an 'any' type.
const toItem = (sprint) => ({
	content: sprint.name,
	value: sprint.id,
	fromCache: sprint.fromCache || false,
});

export const transformFromStateValue = (stateValue: SprintFieldValue[] | null) => {
	const activeSprint = findActiveSprint(stateValue);
	const futureSprint = findFutureSprint(stateValue);
	return (activeSprint && toItem(activeSprint)) || (futureSprint && toItem(futureSprint));
};

export const transformToStateValue =
	(closedSprints: SprintFieldValue[]) =>
	(item: Item | null): SprintFieldValue[] => {
		if (!item) {
			return closedSprints;
		}
		const activeSprint = {
			name: item.content,
			id: item.value,
			state: 'active',
			fromCache: item.fromCache || false,
		};
		// @ts-expect-error - TS2769 - No overload matches this call.
		return closedSprints.concat(activeSprint);
	};

const toSuggestionsItem = (suggestion: ServerItem | TransformedCacheItem): SuggestionsItem => ({
	content: suggestion.name,
	value: suggestion.id,
	description: suggestion.boardName,
	id: suggestion.id,
	fromCache: suggestion.fromCache || false,
});

export const validateCacheItems = (
	rest: ServerItem[],
	cache: ServerItem[],
): TransformedCacheItem[] =>
	cache.map((item) => {
		const duplicatedItem = rest.find((restItem) => restItem.id === item.id);
		if (duplicatedItem) {
			sprintsCache.update(`${item.id}`, duplicatedItem);
			return { ...duplicatedItem, fromCache: true };
		}
		return { ...item, fromCache: true };
	});

const MAX_RESULTS = 5;

export const transformCacheEntries = (cache: TransformedCacheItem[]): TransformedCacheItem[] =>
	cache.slice(-1 * MAX_RESULTS).reverse();

export const transformSuggestionsFromServerAndCache = (
	suggestionsFromServer: ServerSuggestions,
	cache: ServerItem[] = [],
	intl: IntlShape,
) => {
	const suggestions = suggestionsFromServer.suggestions || [];
	const allMatches = suggestionsFromServer.allMatches || [];
	const allItems = suggestions.concat(allMatches);

	const activeItems: SuggestionsItem[] = allItems
		.filter((sprint) => sprint.stateKey === 'ACTIVE')
		.map(toSuggestionsItem);

	const futureItems: SuggestionsItem[] = allItems
		.filter((sprint) => sprint.stateKey === 'FUTURE')
		.map(toSuggestionsItem);

	const cacheLists = transformCacheEntries(validateCacheItems(allItems, cache));

	const cacheItems: SuggestionsItem[] = cacheLists.map(toSuggestionsItem);
	return [
		{
			heading: intl.formatMessage(messages.recentSprints),
			items: cacheItems,
		},
		{
			heading: intl.formatMessage(messages.activeSprints),
			items: activeItems,
		},
		{
			heading: intl.formatMessage(messages.futureSprints),
			items: futureItems,
		},
	] as const;
};

const transformClosedSprint = memoizeOne((intl, baseUrl, sprint) => ({
	id: sprint.id,
	name: sprint.name,
	href: getRapidViewUrl(baseUrl, sprint.id),
	endDate: intl.formatDate(sprint.endDate, {
		day: '2-digit',
		month: 'short',
		year: 'numeric',
	}),
}));

export const transformedClosedSprints = (
	closedSprints: SprintFieldValue[],
	baseUrl: string,
	intl: IntlShape,
): TransformedClosedSprint[] =>
	closedSprints.map((sprint) => transformClosedSprint(intl, baseUrl, sprint));

export const getClosedSprintsLabel = (closedSprints: SprintFieldValue[], intl: IntlShape) => {
	const numberOfClosedSprints = closedSprints.length;
	return numberOfClosedSprints > 0
		? intl.formatMessage(messages.closedSprintsLabel, {
				count: numberOfClosedSprints,
			})
		: null;
};
