import React, { useCallback, useMemo, useState } from 'react';
import { usePaginationFragment, graphql, useLazyLoadQuery } from 'react-relay';
import { Card, CardLoading, DateOverrideContext } from '@atlaskit/media-card';
import type { FileIdentifier } from '@atlaskit/media-client';
import { type SizeEvent, FilmstripView, type ScrollEvent } from '@atlaskit/media-filmstrip';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import {
	DEFAULT_ORDER_FIELD,
	DEFAULT_ORDER_DIRECTION,
	DEFAULT_PAGE_SIZE,
	ATTACHMENT_PARENT_ISSUE,
} from '@atlassian/jira-issue-attachments-store/src/common/constants.tsx';
import AttachmentsError from '@atlassian/jira-issue-attachments-table/src/ui/error/index.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import {
	type AttachmentParent,
	ATTACHMENT_PARENT,
} from '@atlassian/jira-issue-shared-types/src/common/types/attachments.tsx';
import { cardCoverUpdateRequest } from '@atlassian/jira-issue-view-store/src/actions/card-cover-actions.tsx';
import {
	scrollToAttachmentComment,
	scrollToAttachmentWorklog,
} from '@atlassian/jira-issue-view-store/src/actions/issue-scroll-actions.tsx';
import useDebouncedCallback from '@atlassian/jira-platform-use-debounce/src/utils/use-debounce-callback/index.tsx';
import { useDispatch } from '@atlassian/jira-react-redux/src/index.tsx';
import type { ui_issueViewBase_AttachmentFilmstripRelayFragment$key } from '@atlassian/jira-relay/src/__generated__/ui_issueViewBase_AttachmentFilmstripRelayFragment.graphql';
import type { ui_issueViewBase_AttachmentFilmstripRelayFragmentRefetchQuery } from '@atlassian/jira-relay/src/__generated__/ui_issueViewBase_AttachmentFilmstripRelayFragmentRefetchQuery.graphql';
import type { ui_issueViewBase_AttachmentFilmstripRelayQuery } from '@atlassian/jira-relay/src/__generated__/ui_issueViewBase_AttachmentFilmstripRelayQuery.graphql';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { mediaFeatureFlags } from '../../../../media-feature-flags.tsx';
import { useOnDeleteAttachment } from '../services/delete-attachments/index.tsx';
import { useMediaClientConfig } from '../services/media-client-config/index.tsx';
import { getActionsForAttachment } from './utils.tsx';

// defaultImageCardDimensions are copied from @atlaskit/media-card as flow doesn't recognise the export
const defaultImageCardDimensions = {
	width: 156,
	height: 125,
} as const;
const DEBOUNCE_DURATION = 300;

const fakeCard = (key: string) => (
	<CardLoading
		key={key}
		dimensions={defaultImageCardDimensions}
		interactionName="jira-filmstrip-view-relay-card-loading"
	/>
);

type AttachmentFilmstripRelayProps = {
	attachmentsFragmentRef: ui_issueViewBase_AttachmentFilmstripRelayFragment$key;
};

// To overcome type check for GQL attachment parent name
function isValidAttachementParent(value: string | null | undefined): value is AttachmentParent {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return Object.values(ATTACHMENT_PARENT).includes(value as AttachmentParent);
}

interface AttachmentActionItem {
	identifier: FileIdentifier & { attachmentId: string };
	selectable: boolean;
	actions: ReturnType<typeof getActionsForAttachment>;
	isLazy: boolean;
	hasRestrictedParent: boolean;
}

export const AttachmentFilmstripRelayFragment = (props: AttachmentFilmstripRelayProps) => {
	const intl = useIntl();
	const [filmstripOffset, setFilmstripOffset] = useState<number>(0);
	const [filmstripOffsetMax, setFilmstripOffsetMax] = useState<number>(0);
	const [animate, setAnimate] = useState<boolean>(false);
	const [error, setError] = useState<boolean>(false);
	const accountIdLoggedInUser = useAccountId();

	// TODO: once attechments implement node contract AttachmentFilmstripRelay would be removed and ui_issueViewBase_AttachmentFilmstripRelayFragment added to mainIssueAggQuery
	const { data, isLoadingNext, hasNext, loadNext } = usePaginationFragment<
		ui_issueViewBase_AttachmentFilmstripRelayFragmentRefetchQuery,
		ui_issueViewBase_AttachmentFilmstripRelayFragment$key
	>(
		graphql`
			fragment ui_issueViewBase_AttachmentFilmstripRelayFragment on Query
			@refetchable(queryName: "ui_issueViewBase_AttachmentFilmstripRelayFragmentRefetchQuery")
			@argumentDefinitions(
				cloudId: { type: "ID!" }
				issueKey: { type: "String!" }
				first: { type: "Int" }
				after: { type: "String", defaultValue: null }
				sortBy: { type: "JiraAttachmentSortInput" }
				maxTokenLength: { type: "Int", defaultValue: 65536 }
			) {
				jira @required(action: THROW) {
					issueByKey(cloudId: $cloudId, key: $issueKey) @required(action: THROW) {
						mediaReadToken(maxTokenLength: $maxTokenLength)
							@optIn(to: "JiraMediaReadTokenInIssue") {
							clientId
							endpointUrl
							tokenLifespanInSeconds
							tokensWithFiles {
								edges {
									node {
										token
									}
								}
							}
						}
						mediaUploadToken @optIn(to: "JiraMediaUploadTokenInIssue") {
							... on JiraMediaUploadToken {
								endpointUrl
								clientId
								targetCollection
								token
								tokenDurationInMin
							}
							... on QueryError {
								errorId: identifier
								errorMessage: message
							}
						}
						key
						cardCoverResolver
						isMobileResolver
						isPreviewResolver
						canDeleteAllAttachments: hasProjectPermission(permission: DELETE_ALL_ATTACHMENTS)
						canDeleteOwnAttachments: hasProjectPermission(permission: DELETE_OWN_ATTACHMENTS)
						attachments(first: $first, after: $after, sortBy: $sortBy)
							@connection(key: "issue_attachments")
							@required(action: THROW) {
							edges @required(action: THROW) {
								node {
									__typename
									... on JiraAttachment {
										attachmentId
										mediaApiFileId
										created
										parentId
										parentName
										hasRestrictedParent
										parentId
										author {
											accountId
										}
									}
								}
							}
							totalCountResolver(issueKey: $issueKey) @required(action: THROW)
						}
					}
				}
			}
		`,
		props.attachmentsFragmentRef,
	);

	// #region issue view redux actions
	const dispatch = useDispatch();

	const onCardCoverShow = useCallback(
		(currentAttachmentId: number | null, newAttachmentId: number) => {
			dispatch(cardCoverUpdateRequest(currentAttachmentId, newAttachmentId));
		},
		[dispatch],
	);
	const onCardCoverHide = useCallback(
		(currentAttachmentId: number | null) => {
			dispatch(cardCoverUpdateRequest(currentAttachmentId));
		},
		[dispatch],
	);
	const onScrollToComments = useCallback(() => {
		dispatch(scrollToAttachmentComment());
	}, [dispatch]);
	const onScrollToWorklogs = useCallback(() => {
		dispatch(scrollToAttachmentWorklog());
	}, [dispatch]);

	// #endregion

	const {
		key: issueKey,
		cardCoverResolver: cardCover,
		isMobileResolver: isMobile,
		isPreviewResolver: isPreview,
		attachments,
		canDeleteAllAttachments,
		canDeleteOwnAttachments,
		mediaReadToken,
		mediaUploadToken,
	} = data?.jira?.issueByKey;

	const { onDeleteAttachment, ModelTransition } = useOnDeleteAttachment({
		issueKey,
		attachmentEdges: attachments.edges,
	});

	const readTokenTransformed = useMemo(() => {
		const { clientId, endpointUrl, tokenLifespanInSeconds = -1 } = mediaReadToken || {};
		const readToken = mediaReadToken?.tokensWithFiles?.edges?.[0]?.node?.token || '';

		return {
			clientId: clientId || '',
			endpointUrl: endpointUrl || '',
			tokenLifespanInSeconds: tokenLifespanInSeconds ?? -1,
			token: readToken,
		};
	}, [mediaReadToken]);

	const uploadTokenTransformed = useMemo(() => {
		const {
			clientId,
			targetCollection,
			endpointUrl,
			token: uploadToken,
			tokenDurationInMin = -1,
			errorMessage,
			errorId,
		} = mediaUploadToken || {};

		return {
			clientId: clientId || '',
			targetCollection: targetCollection || '',
			endpointUrl: endpointUrl || '',
			tokenDurationInMin: tokenDurationInMin ?? -1,
			token: uploadToken || '',
			errorMessage,
			errorId,
		};
	}, [mediaUploadToken]);

	const mediaClientConfig = useMediaClientConfig({
		readToken: readTokenTransformed,
		uploadToken: uploadTokenTransformed,
	});

	const handleSizeChange = useCallback(({ offset: newOffset }: SizeEvent) => {
		setFilmstripOffset(newOffset);
	}, []);

	const refreshAttachments = useCallback(() => {
		if (hasNext && !isLoadingNext) {
			loadNext(DEFAULT_PAGE_SIZE, {
				onComplete: (errorArg) => {
					setError(!!errorArg);
				},
			});
		}
	}, [hasNext, isLoadingNext, loadNext]);

	const [debouncedRefreshAttachments] = useDebouncedCallback(refreshAttachments, DEBOUNCE_DURATION);

	const handleScrollChange = useCallback(
		({ animate: newAnimate, offset: newOffset }: ScrollEvent) => {
			setAnimate(newAnimate);
			setFilmstripOffset(newOffset);

			// loading next page only if we reached the border of the previous one
			if (newOffset > filmstripOffsetMax) {
				debouncedRefreshAttachments();
				setFilmstripOffsetMax(newOffset);
			}
		},
		[debouncedRefreshAttachments, filmstripOffsetMax],
	);

	const items: AttachmentActionItem[] = useMemo(
		() =>
			attachments?.edges
				?.filter((edge) => edge?.node)
				.map((attachment) => {
					if (
						!attachment ||
						!attachment.node ||
						!attachment.node.mediaApiFileId ||
						!attachment.node.attachmentId ||
						!isValidAttachementParent(attachment.node.parentName)
					)
						throw new Error('Invalid attachment entity');

					const identifier: FileIdentifier & { attachmentId: string } = {
						mediaItemType: 'file',
						id: attachment.node.mediaApiFileId,
						attachmentId: attachment.node.attachmentId,
					};

					const canDeleteAttachment =
						attachment.node.parentName === ATTACHMENT_PARENT_ISSUE &&
						(canDeleteAllAttachments ||
							(accountIdLoggedInUser !== null &&
								attachment.node.author !== null &&
								canDeleteOwnAttachments &&
								attachment.node.author?.accountId === accountIdLoggedInUser));

					const actions = getActionsForAttachment(
						attachment.node.attachmentId,
						attachment.node.parentName,
						attachment.node.parentId ?? '',
						cardCover ?? null,
						Boolean(canDeleteAttachment),
						intl,
						issueKey,
						onDeleteAttachment,
						onCardCoverShow,
						onCardCoverHide,
						onScrollToComments,
						onScrollToWorklogs,
					);

					return {
						identifier,
						selectable: false,
						actions,
						isLazy: !isMobile,
						hasRestrictedParent: attachment.node.hasRestrictedParent ?? false,
					};
				}),
		[
			accountIdLoggedInUser,
			attachments?.edges,
			canDeleteAllAttachments,
			canDeleteOwnAttachments,
			cardCover,
			intl,
			isMobile,
			issueKey,
			onCardCoverHide,
			onCardCoverShow,
			onDeleteAttachment,
			onScrollToComments,
			onScrollToWorklogs,
		],
	);

	const getCards = useCallback(
		(visibleItems: AttachmentActionItem[]) => {
			const attachmentIdentifiers: FileIdentifier[] = visibleItems.map((item) => item.identifier);

			const visibleCards = visibleItems.map((item, index) => {
				if (isPreview || error || mediaClientConfig === null) {
					return fakeCard(`card-${index}`);
				}

				const isInternalAttachment = item.hasRestrictedParent;
				return (
					<Card
						key={item.identifier.id}
						mediaClientConfig={mediaClientConfig}
						mediaViewerItems={attachmentIdentifiers}
						useInlinePlayer={false}
						dimensions={defaultImageCardDimensions}
						shouldOpenMediaViewer
						featureFlags={mediaFeatureFlags()}
						titleBoxBgColor={
							isInternalAttachment
								? // TODO: https://product-fabric.atlassian.net/browse/DSP-8582
									token('color.background.accent.purple.subtle', colors.Y75)
								: undefined
						}
						titleBoxIcon={isInternalAttachment ? 'LockFilledIcon' : undefined}
						shouldEnableDownloadButton
						{...item}
						testId={`issue.views.issue-base.content.attachment.filmstrip-view.attachment-id.${item.identifier.attachmentId}`}
					/>
				);
			});
			const endFakeCardsCount = attachments.totalCountResolver - visibleCards.length;

			const endFakeCards = Array.from({ length: endFakeCardsCount }).map((_, index) =>
				fakeCard(`card-${attachments.totalCountResolver - endFakeCardsCount + index}`),
			);
			return [...visibleCards, ...endFakeCards] as const;
		},
		[error, isPreview, attachments.totalCountResolver, mediaClientConfig],
	);

	const overrideCreationDate = useMemo(() => {
		const overrides: Record<string, number> = {};

		attachments?.edges?.forEach((attachment) => {
			if (
				!attachment ||
				!attachment.node ||
				attachment.node.__typename !== 'JiraAttachment' ||
				!attachment.node.mediaApiFileId
			)
				return;
			overrides[attachment.node.mediaApiFileId] =
				Math.floor(new Date(attachment.node.created).getTime() / 1000) * 1000;
		});

		return overrides;
	}, [attachments?.edges]);

	if (error) {
		return <AttachmentsError shouldHideImage onClick={refreshAttachments} />;
	}

	return (
		<>
			<div data-testid="issue-view-base.content.attachment.filmstrip-view-relay.ui.filmstrip-panel">
				<DateOverrideContext.Provider value={overrideCreationDate}>
					<FilmstripView
						offset={filmstripOffset}
						animate={animate}
						onSize={handleSizeChange}
						onScroll={handleScrollChange}
						// @ts-expect-error - TS2322 - Type '{ children: readonly any[]; offset: number; animate: boolean; onSize: ({ offset: newOffset, offsets }: any) => void; onScroll: ({ animate: newAnimate, offset: newOffset }: any) => void; mediaClientConfig: MediaClientConfig; featureFlags: { newCardExperience: boolean; mediaInline: boolean; med...' is not assignable to type 'IntrinsicAttributes & IntrinsicClassAttributes<FilmstripView> & Pick<Readonly<FilmstripViewProps> & Readonly<...>, never> & Partial<...> & Partial<...>'.
						mediaClientConfig={mediaClientConfig}
						featureFlags={mediaFeatureFlags()}
						testId="issue-view-base.content.attachment.filmstrip-view-relay.ui.filmstrip-view"
					>
						{getCards(items)}
					</FilmstripView>
				</DateOverrideContext.Provider>
			</div>
			{ModelTransition}
		</>
	);
};

export const AttachmentFilmstripRelay = () => {
	const { cloudId } = useTenantContext();
	const issueKey = useIssueKey();

	// eslint-disable-next-line @atlassian/relay/query-restriction
	const attachmentsData = useLazyLoadQuery<ui_issueViewBase_AttachmentFilmstripRelayQuery>(
		graphql`
			query ui_issueViewBase_AttachmentFilmstripRelayQuery(
				$cloudId: ID!
				$issueKey: String!
				$first: Int
				$sortBy: JiraAttachmentSortInput
			) {
				...ui_issueViewBase_AttachmentFilmstripRelayFragment
					@arguments(cloudId: $cloudId, issueKey: $issueKey, first: $first, sortBy: $sortBy)
			}
		`,
		{
			cloudId,
			issueKey,
			first: DEFAULT_PAGE_SIZE,
			sortBy: { field: DEFAULT_ORDER_FIELD, order: DEFAULT_ORDER_DIRECTION },
		},
		{ fetchPolicy: 'store-only' },
	);

	return <AttachmentFilmstripRelayFragment attachmentsFragmentRef={attachmentsData} />;
};
