import React from 'react';
import { useDateTimeFormatter } from '@atlassian/jira-issue-format-date/src/main.tsx';
import { useCsatComment } from '@atlassian/jira-servicedesk-request-details-panel-services/src/services/use-csat-comment/index.tsx';
import { FeedbackDate, FeedbackComment } from './styled.tsx';

type Props = {
	feedbackDate?: string;
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default ({ feedbackDate }: Props) => {
	const [comment] = useCsatComment();

	let displayDate: string | null;

	const { formatDateTime } = useDateTimeFormatter();

	try {
		displayDate = feedbackDate ? formatDateTime(feedbackDate) : null;
	} catch {
		displayDate = null;
	}

	return (
		<>
			{comment && <FeedbackComment>{comment}</FeedbackComment>}
			{displayDate && <FeedbackDate hasComment={!!comment}>{displayDate}</FeedbackDate>}
		</>
	);
};
