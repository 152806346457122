import get from 'lodash/get';
import { useEditField } from '@atlassian/jira-issue-field-base/src/services/edit-field-service/main.tsx';
import { useFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import { ISSUE_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { ServiceProps, IssueType } from '../common/types.tsx';

export const useIssueTypeField = ({ issueKey, onSuccess, onFailure }: ServiceProps) => {
	const [{ value: fieldConfig }] = useFieldConfig(issueKey, ISSUE_TYPE);
	const fieldType = get(fieldConfig, 'schema.type', ISSUE_TYPE);

	const [{ value, error, loading }, { saveValue, resetError }] = useEditField<
		IssueType | null,
		null
	>({
		fieldKey: ISSUE_TYPE,
		issueKey,
		fieldType,
		initialValue: null,
		onSuccess,
		onFailure,
	});

	return [
		{ value, error, fieldConfig, loading },
		{ saveValue, resetError },
	] as const;
};
