/**
 * @generated SignedSource<<19615e034c98cb16d7694b9dc40f2b66>>
 * @relayHash e5e7607893c3fc0259d185853207f0d1
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 0c73a998a03796a63fd9a927394bab762b7cebf1a3c719065b99782543a7b051

import type { ConcreteRequest, Mutation } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraSingleValueFieldOperations = "SET" | "%future added value";
export type JiraUpdateRichTextFieldInput = {
  id: string;
  operation: JiraRichTextFieldOperationInput;
};
export type JiraRichTextFieldOperationInput = {
  document: JiraADFInput;
  operation: JiraSingleValueFieldOperations;
};
export type JiraADFInput = {
  jsonValue?: AGG$JSON | null | undefined;
  version?: number | null | undefined;
};
export type ui_issueViewLayoutRichTextField_IssueViewRichTextField_Mutation$variables = {
  input: JiraUpdateRichTextFieldInput;
};
export type ui_issueViewLayoutRichTextField_IssueViewRichTextField_Mutation$data = {
  readonly jira: {
    readonly updateRichTextField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly id: string;
        readonly richText: {
          readonly $updatableFragmentSpreads: FragmentRefs<"ui_issueViewLayoutRichTextField_IssueViewRichTextField_richText">;
          readonly adfValue: {
            readonly convertedPlainText: {
              readonly plainText: string | null | undefined;
            } | null | undefined;
            readonly json: AGG$JSON | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type ui_issueViewLayoutRichTextField_IssueViewRichTextField_Mutation$rawResponse = {
  readonly jira: {
    readonly updateRichTextField: {
      readonly errors: ReadonlyArray<{
        readonly message: string | null | undefined;
      }> | null | undefined;
      readonly field: {
        readonly id: string;
        readonly richText: {
          readonly __typename: "JiraRichText";
          readonly adfValue: {
            readonly convertedPlainText: {
              readonly plainText: string | null | undefined;
            } | null | undefined;
            readonly json: AGG$JSON | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type ui_issueViewLayoutRichTextField_IssueViewRichTextField_Mutation = {
  rawResponse: ui_issueViewLayoutRichTextField_IssueViewRichTextField_Mutation$rawResponse;
  response: ui_issueViewLayoutRichTextField_IssueViewRichTextField_Mutation$data;
  variables: ui_issueViewLayoutRichTextField_IssueViewRichTextField_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "success"
},
v3 = {
  "concreteType": "MutationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "message"
    }
  ]
},
v4 = {
  "kind": "ScalarField",
  "name": "id"
},
v5 = {
  "concreteType": "JiraADF",
  "kind": "LinkedField",
  "name": "adfValue",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "json"
    },
    {
      "concreteType": "JiraAdfToConvertedPlainText",
      "kind": "LinkedField",
      "name": "convertedPlainText",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "plainText"
        }
      ]
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "ui_issueViewLayoutRichTextField_IssueViewRichTextField_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraRichTextFieldPayload",
            "kind": "LinkedField",
            "name": "updateRichTextField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraRichTextField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "concreteType": "JiraRichText",
                    "kind": "LinkedField",
                    "name": "richText",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "FragmentSpread",
                        "name": "ui_issueViewLayoutRichTextField_IssueViewRichTextField_richText"
                      },
                      (v5/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ui_issueViewLayoutRichTextField_IssueViewRichTextField_Mutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraRichTextFieldPayload",
            "kind": "LinkedField",
            "name": "updateRichTextField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "concreteType": "JiraRichTextField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "concreteType": "JiraRichText",
                    "kind": "LinkedField",
                    "name": "richText",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "__typename"
                      },
                      (v5/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "0c73a998a03796a63fd9a927394bab762b7cebf1a3c719065b99782543a7b051",
    "metadata": {},
    "name": "ui_issueViewLayoutRichTextField_IssueViewRichTextField_Mutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "43306885e31f18a5642833d9f1a97999";

export default node;
