/**
 * @generated SignedSource<<e444162d4db31fe2d8cf9ce3d49c500a>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type reporter_issueFieldReporterInlineEditFull_ReporterInlineEditView_fragmentRef$data = {
  readonly " $fragmentSpreads": FragmentRefs<"singleUserPicker_issueFieldSingleUserPickerInlineEditFull_SingleUserPickerInlineEditView_fragmentRef">;
  readonly " $fragmentType": "reporter_issueFieldReporterInlineEditFull_ReporterInlineEditView_fragmentRef";
};
export type reporter_issueFieldReporterInlineEditFull_ReporterInlineEditView_fragmentRef$key = {
  readonly " $data"?: reporter_issueFieldReporterInlineEditFull_ReporterInlineEditView_fragmentRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"reporter_issueFieldReporterInlineEditFull_ReporterInlineEditView_fragmentRef">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "reporter_issueFieldReporterInlineEditFull_ReporterInlineEditView_fragmentRef",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "singleUserPicker_issueFieldSingleUserPickerInlineEditFull_SingleUserPickerInlineEditView_fragmentRef"
    }
  ],
  "type": "JiraSingleSelectUserPickerField"
};

(node as any).hash = "bd02fa384b206ea57830ba99674f24b9";

export default node;
