import React from 'react';
import type {
	EcosystemModuleProps,
	EcosystemTimeoutViewProps,
} from '@atlassian/jira-connect-utils/src/common/types.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint, lazyAfterPaint } from '@atlassian/react-loosely-lazy';

const EcosystemModule = lazyForPaint(
	() => import(/* webpackChunkName: "async-connect-utils-ecosystem-module" */ './index'),
	{ ssr: true },
);

const EcosystemTimeoutView = lazyAfterPaint(
	() =>
		import(
			/* webpackChunkName: "async-connect-utils-ecosystem-timeout-view" */ './common/ui/ecosystem-timeout-view'
		),
	{ ssr: true },
);

export const EcosystemModuleAsync = (props: EcosystemModuleProps) => (
	<Placeholder name="ecosystem-module-async" fallback={<></>}>
		<EcosystemModule {...props} />
	</Placeholder>
);

export const EcosystemTimeoutViewAsync = (props: EcosystemTimeoutViewProps) => (
	<Placeholder name="ecosystem-timeout-view-async" fallback={<></>}>
		<EcosystemTimeoutView {...props} />
	</Placeholder>
);
