import React from 'react';

export const PinUnfilled = (props: { [key: string]: string }) => (
	<svg
		{...props}
		// eslint-disable-next-line jira/integration/test-id-by-folder-structure
		data-testid="issue-field-pin-assets.pin-unfilled"
		xmlns="http://www.w3.org/2000/svg"
		width="16"
		height="16"
		viewBox="0 0 16 16"
	>
		<path
			fill="currentColor"
			d="M3.58176938,7.87928392 L8.11122851,12.4014141 L8.25734637,12.2555315 L8.18428744,12.1825901 C7.49835823,11.4978426 7.49835823,10.387565 8.18428744,9.70273982 L10.299184,7.5911928 C10.8579797,7.03331193 11.6995968,6.9299201 12.363196,7.28100178 L12.563933,7.08062671 L8.9111421,3.43373045 L8.71048291,3.63410553 C9.06208387,4.29665994 8.95852537,5.13692246 8.39972959,5.6948111 L6.28483307,7.80634258 C5.59890386,8.4911678 4.48684425,8.4911678 3.80093838,7.80634258 L3.72787945,7.73340123 L3.58176938,7.87928392 Z M5.48118484,7.00402665 L7.59615917,4.8924874 C7.83821064,4.65079444 7.83821064,4.25893086 7.59615917,4.01723789 L7.45004131,3.87135521 C7.28867366,3.71023174 7.28867366,3.44898677 7.45004131,3.28785554 L8.61890638,2.12084842 C8.78035183,1.95971719 9.04201017,1.95971719 9.20337782,2.12084842 L13.8789159,6.78887687 C14.0403614,6.9500081 14.0403614,7.21125308 13.8789159,7.37238431 L12.7100508,8.53940696 C12.5486832,8.70051489 12.2870249,8.70051489 12.1255794,8.53940696 L11.9794615,8.39352427 C11.7374101,8.1517847 11.3448837,8.1517847 11.1028322,8.39352427 L8.98785786,10.5050169 C8.74580639,10.7467565 8.74580639,11.1385735 8.98785786,11.380313 L9.5723293,11.9638438 C9.73369695,12.1249517 9.73369695,12.3861889 9.5723293,12.5472968 L8.40346423,13.7143583 C8.24201878,13.8754662 7.98036044,13.8754662 7.81899279,13.7143583 L5.48655339,11.3856729 L2.99050556,13.8776413 C2.91355638,13.9544666 2.80936765,13.9978896 2.70054953,13.9985111 L2.4264735,14 C2.20319639,14.0012299 2.01931974,13.8252074 2.01121246,13.6024217 L2.00027307,13.3019562 C1.99610272,13.1873785 2.03985249,13.0762963 2.12104205,12.9951986 L4.60988514,10.5103768 L2.26677088,8.17104929 C2.10537989,8.00994136 2.10537989,7.74862648 2.26677088,7.58752632 L3.4356593,6.42051921 C3.59705028,6.25938797 3.85870862,6.25938797 4.02009961,6.42051921 L4.60454771,7.00402665 C4.8466303,7.24571961 5.23912559,7.24571961 5.48118484,7.00402665 Z"
		/>
	</svg>
);

export default PinUnfilled;
