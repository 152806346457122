import { editorExperiment } from '@atlaskit/tmp-editor-statsig/experiments';

import { type CreatePromptEditorOptions } from '../editors/prompt';

export const is1pSmartLinkUnfurlInPromptEditorEnabled = (
	options: CreatePromptEditorOptions,
): boolean => {
	return Boolean(
		options.autoformattingProvider &&
			options.enableLinks &&
			editorExperiment('platform_editor_ai_1p_smart_link_unfurl_in_prompt', true),
	);
};

export const maybeBuild1pAutoFormattingProvider = (options: CreatePromptEditorOptions) => {
	if (is1pSmartLinkUnfurlInPromptEditorEnabled(options)) {
		return options.autoformattingProvider;
	}
	return undefined;
};
