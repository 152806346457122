/**
 * Removes obsolete and duplicated option weights originating from select and multiselect fields.
 * https://pi-dev-sandbox.atlassian.net/browse/POL-7929
 */
export const sanitizeOptionWeights = (
	fieldOptions: { optionId: number; weight: number }[],
	optionIds: string[],
) => {
	const existingOptionIds: Record<string, boolean> = {};

	return fieldOptions.filter(({ optionId }) => {
		if (!optionIds.includes(optionId.toString())) {
			return false;
		}

		if (existingOptionIds[optionId.toString()]) {
			return false;
		}

		existingOptionIds[optionId.toString()] = true;

		return true;
	});
};
