import type { JiraProjectAri } from '@atlassian/ari/jira/project';
import type { CollectionUUID } from '@atlassian/jira-polaris-domain-collection/src/index.tsx';
import type { ProjectId, ProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';

export const PolarisEnvironmentContainerTypes = {
	PROJECT: 'PROJECT',
	VIEW: 'VIEW',
	COLLECTION: 'COLLECTION',
} as const;

type BasePolarisEnvironmentContainer = {
	id: string;
};

type ViewId = string;

type PolarisProjectContainer = BasePolarisEnvironmentContainer & {
	type: typeof PolarisEnvironmentContainerTypes.PROJECT;
	projectId: ProjectId;
	projectKey: ProjectKey;
	projectAri: JiraProjectAri;
};

type PolarisCollectionContainer = BasePolarisEnvironmentContainer & {
	type: typeof PolarisEnvironmentContainerTypes.COLLECTION;
	collectionId: CollectionUUID;
};

type PolarisViewContainer = BasePolarisEnvironmentContainer & {
	type: typeof PolarisEnvironmentContainerTypes.VIEW;
	viewId: ViewId;
};

export type PolarisEnvironmentContainer =
	| PolarisProjectContainer
	| PolarisCollectionContainer
	| PolarisViewContainer;

export type ContainerId = ProjectId | CollectionUUID | ViewId;
