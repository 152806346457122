/**
 * @generated SignedSource<<8ae4f6c0c9a7542c951bfd1f7d466b2f>>
 * @relayHash 64a0da979ca9d4b0e5ab13e86873d795
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID d5ab8ab0cb2a3348671bca405ebb783b823ddff4d42344b232a4fbc8bd5b7818

import type { ConcreteRequest, Query } from 'relay-runtime';
export type ui_issueViewComponentsField_Query$variables = {
  first?: number | null | undefined;
  ids: ReadonlyArray<string>;
  searchBy: string;
};
export type ui_issueViewComponentsField_Query$data = {
  readonly jira: {
    readonly issueFieldsByIds: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly components?: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly ari: string | null | undefined;
                readonly componentId: string;
                readonly description: string | null | undefined;
                readonly id: string;
                readonly metadata: AGG$JSON | null | undefined;
                readonly name: string | null | undefined;
              } | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type ui_issueViewComponentsField_Query = {
  response: ui_issueViewComponentsField_Query$data;
  variables: ui_issueViewComponentsField_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ids"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchBy"
},
v3 = [
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "ids"
  }
],
v4 = {
  "kind": "ScalarField",
  "name": "id"
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        },
        {
          "kind": "Variable",
          "name": "searchBy",
          "variableName": "searchBy"
        }
      ],
      "concreteType": "JiraComponentConnection",
      "kind": "LinkedField",
      "name": "components",
      "plural": false,
      "selections": [
        {
          "concreteType": "JiraComponentEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "concreteType": "JiraComponent",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v4/*: any*/),
                {
                  "kind": "ScalarField",
                  "name": "name"
                },
                {
                  "kind": "ScalarField",
                  "name": "ari"
                },
                {
                  "kind": "ScalarField",
                  "name": "metadata"
                },
                {
                  "kind": "ScalarField",
                  "name": "componentId"
                },
                {
                  "kind": "ScalarField",
                  "name": "description"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "JiraComponentsField"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "name": "ui_issueViewComponentsField_Query",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v3/*: any*/),
            "concreteType": "JiraIssueFieldConnection",
            "kind": "LinkedField",
            "name": "issueFieldsByIds",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraIssueFieldEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "ui_issueViewComponentsField_Query",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v3/*: any*/),
            "concreteType": "JiraIssueFieldConnection",
            "kind": "LinkedField",
            "name": "issueFieldsByIds",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraIssueFieldEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "name": "__typename"
                      },
                      (v5/*: any*/),
                      (v4/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "d5ab8ab0cb2a3348671bca405ebb783b823ddff4d42344b232a4fbc8bd5b7818",
    "metadata": {},
    "name": "ui_issueViewComponentsField_Query",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "0ebe2d6c032ba1d515a772f3efbdf593";

export default node;
