import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import performance from '@atlassian/jira-common-performance/src/performance.tsx';
import {
	fireOperationalAnalytics,
	type Attributes,
} from '@atlassian/jira-product-analytics-bridge';

const fireRequestEvent = (
	action: string,
	actionSubject: string,
	startTime: number,
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
	additionalAttributes?: Attributes,
): void => {
	const attributes: Attributes = {
		...additionalAttributes,
		elapsedTimeMs: performance.now() - startTime,
	};
	createAnalyticsEvent &&
		fireOperationalAnalytics(
			createAnalyticsEvent({ action, actionSubject }),
			`${actionSubject} ${action}`,
			attributes,
		);
};

export const fireSuggestionsRequestSuccessEvent = (
	fieldId: string,
	startTime: number,
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
	additionalAttributes?: Attributes,
): void => {
	fireRequestEvent(
		'succeeded',
		`${fieldId}Suggestions`,
		startTime,
		createAnalyticsEvent,
		additionalAttributes,
	);
};

export const fireSuggestionsRequestFailedEvent = (
	fieldId: string,
	startTime: number,
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
	additionalAttributes?: Attributes,
): void => {
	fireRequestEvent(
		'failed',
		`${fieldId}Suggestions`,
		startTime,
		createAnalyticsEvent,
		additionalAttributes,
	);
};
