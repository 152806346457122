import { useMemo } from 'react';
import { useServiceDeskContext } from '@atlassian/jira-servicedesk-spa-commons/src/common/utils/servicedesk-context/index.tsx';

interface HasId<T> {
	id: T;
}

export const intersect = <T,>(as: readonly T[], bs: readonly T[]): readonly T[] => {
	const setB = new Set(bs);
	return [...new Set(as)].filter((x) => setB.has(x));
};

export const valueIds = <T, O extends Record<string, HasId<T>>>(obj: O): readonly T[] =>
	Object.values(obj).map((item) => item.id);

export const useIsJiraServiceManagementRequest = () => {
	const [{ data: serviceDeskContext }] = useServiceDeskContext();
	return useMemo(() => !!serviceDeskContext, [serviceDeskContext]);
};
