import type { ReactElement } from 'react';
import usePermalink from '../use-permalink/index.tsx';
import type { Permalink } from './types.tsx';

type Props = {
	defaultIsVisible: boolean;
	children: (arg1: Permalink) => ReactElement;
};

// Expose the useVisibility hook's functionality in the form of a provider for class components
const Provider = (props: Props) => {
	const [isVisible, show, hide] = usePermalink(props.defaultIsVisible);
	return props.children({ isVisible, show, hide });
};
Provider.defaultProps = {
	defaultIsVisible: false,
};

export default Provider;
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { Permalink };
