import type { Vote as VoteValue } from '@atlassian/jira-issue-shared-types/src/common/types/issue-type.tsx';

export const calculateVote = (didVote: boolean, value: VoteValue): VoteValue => {
	if (didVote) {
		return {
			...value,
			votes: value.votes + 1,
			hasVoted: true,
		};
	}
	return {
		...value,
		votes: value.votes - 1,
		hasVoted: false,
	};
};
