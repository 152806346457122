export const ALLOWED_ELEMENTS = [
	'p',
	'h1',
	'h2',
	'h3',
	'h4',
	'h5',
	'h6',
	'strong',
	'em',
	'u',
	'sub',
	'sup',
];

export const DEFAULT_ACRONYM_HIGHLIGHT_REGEX = /(\b)[A-Z][A-Z0-9&]{1,}(\b)/g;
