import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	pinField: {
		id: 'issue-field-pin.pin-field',
		defaultMessage: 'Pin to top',
		description: 'Tooltip, when hover over unpinned field.',
	},
	firstPinnedField: {
		id: 'issue-field-pin.first-pinned-field',
		defaultMessage: 'Pin to top. Only you can see pinned fields.',
		description: 'Tooltip, when hover over unpinned field when the user has no pinned fields.',
	},
	unpinField: {
		id: 'issue-field-pin.unpin-field',
		defaultMessage: 'Unpin',
		description: 'Tooltip, when hovering over pinned field.',
	},
	fieldPinned: {
		id: 'issue-field-pin.field-pinned',
		defaultMessage: '{fieldLabel} pinned to top.',
		description: 'Success message after pinning a field.',
	},
	errorTooManyPinnedTitle: {
		id: 'issue-field-pin.error-too-many-pinned-title',
		defaultMessage: 'Too many pinned fields',
		description: 'Error message title, when too many fields are pinned.',
	},
	errorTooManyPinnedDescription: {
		id: 'issue-field-pin.error-too-many-pinned-description',
		defaultMessage:
			"You've reached the maximum number of fields for this project. If you'd like to pin a new field, you'll need to unpin another.",
		description: 'Error message description, when too many fields are pinned.',
	},
});
