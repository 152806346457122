import cloneDeep from 'lodash/cloneDeep';
import uuid from 'uuid';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fireOperationalAnalyticsDeferred } from '@atlassian/jira-product-analytics-bridge';
import {
	STATISTIC_INTERVAL,
	TIME_WINDOW_1M,
	TIME_WINDOW_5M,
	VOLUME_STATISTIC_INTERVAL,
} from '../../constants.tsx';
import type { Statistic, Event } from '../../types.tsx';

const initialTime = Date.now();
const tabId = uuid();

const getBucket = (measurement: number) => {
	switch (true) {
		case measurement <= 10:
			return '0-10';
		case measurement <= 25:
			return '10-25';
		case measurement <= 50:
			return '25-50';
		case measurement <= 100:
			return '50-100';
		case measurement <= 250:
			return '100-250';
		case measurement <= 500:
			return '250-500';
		default:
			return '500+';
	}
};

const getEventsCountByType = (events: string[]) =>
	events.reduce(
		(result, type) =>
			Object.assign(result, {
				[type]: result[type] ? result[type] + 1 : 1,
			}),
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		{} as Record<string, number>,
	);

const getTimeAfterLoadInSeconds = () => (Date.now() - initialTime) / 1000;

export const sendAnalytics = (
	statistic: Statistic,
	isWindowFocused: boolean,
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
) => {
	if (!createAnalyticsEvent) {
		return;
	}

	if (statistic.maxCalls1m.length) {
		const aggregatedEvents = getEventsCountByType(statistic.maxCalls1m);
		fireOperationalAnalyticsDeferred(
			createAnalyticsEvent({}),
			'realtimeQueueIntervalVolume tracked',
			{
				bucket: getBucket(statistic.maxCalls1m.length),
				interval: '1m',
				volume: statistic.maxCalls1m.length,
				tab: isWindowFocused ? 'active' : 'inactive',
				tabId,
				timeAfterLoadInSeconds: getTimeAfterLoadInSeconds(),
				events: aggregatedEvents,
			},
		);
	}

	if (statistic.maxCalls5m.length) {
		const aggregatedEvents = getEventsCountByType(statistic.maxCalls5m);
		fireOperationalAnalyticsDeferred(
			createAnalyticsEvent({}),
			'realtimeQueueIntervalVolume tracked',
			{
				bucket: getBucket(statistic.maxCalls5m.length),
				interval: '5m',
				volume: statistic.maxCalls5m.length,
				tab: isWindowFocused ? 'active' : 'inactive',
				tabId,
				timeAfterLoadInSeconds: getTimeAfterLoadInSeconds(),
				events: aggregatedEvents,
			},
		);
	}

	if (statistic.maxQueueSize) {
		const aggregatedEvents = getEventsCountByType(
			statistic.currentQueue.map((event) => event.type),
		);
		fireOperationalAnalyticsDeferred(createAnalyticsEvent({}), 'realtimeQueueSize tracked', {
			bucket: getBucket(statistic.currentQueue.length),
			maxBucket: getBucket(statistic.maxQueueSize),
			queueSize: statistic.currentQueue.length,
			maxQueueSize: statistic.maxQueueSize,
			tab: isWindowFocused ? 'active' : 'inactive',
			tabId,
			timeAfterLoadInSeconds: getTimeAfterLoadInSeconds(),
			events: aggregatedEvents,
		});
	}
};

export const sendVolumeAnalytics = (
	events: string[],
	isWindowFocused: boolean,
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
) => {
	if (!createAnalyticsEvent) {
		return;
	}

	if (!events.length) {
		return;
	}

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const searchParams = new URLSearchParams(window.location.search);

	const aggregatedEvents = getEventsCountByType(events);
	fireOperationalAnalyticsDeferred(createAnalyticsEvent({}), 'realtimeEventVolume tracked', {
		bucket: getBucket(events.length),
		volume: events.length,
		tab: isWindowFocused ? 'active' : 'inactive',
		tabId,
		queryParams: [...searchParams.keys()].reduce(
			(acc, key) =>
				Object.assign(acc, {
					[key]:
						// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
						searchParams.get(key) && /\d+/.test(searchParams.get(key)!)
							? '<param>'
							: searchParams.get(key),
				}),
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			{} as Record<string, string>,
		),
		timeAfterLoadInSeconds: getTimeAfterLoadInSeconds(),
		events: aggregatedEvents,
	});
};

export const createQueueAnalytics = (createAnalyticsEvent?: CreateUIAnalyticsEvent) => {
	const volume = {
		headEventTime5m: 0,
		headEventTime1m: 0,
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		calls5m: [] as string[],
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		calls1m: [] as string[],
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		calls: [] as string[],
	};
	const statistic: Statistic = {
		currentQueue: [],
		maxCalls1m: [],
		maxCalls5m: [],
		maxQueueSize: 0,
	};

	return {
		addEvent: (event: Event, eventsInQueue: Event[]) => {
			if (volume.calls1m.length === 0) {
				volume.headEventTime1m = Date.now();
			}
			if (volume.calls5m.length === 0) {
				volume.headEventTime5m = Date.now();
				volume.headEventTime1m = Date.now();
			}
			volume.calls1m.push(event.type);
			volume.calls5m.push(event.type);
			volume.calls.push(event.type);

			statistic.currentQueue = eventsInQueue;
			if (eventsInQueue.length > statistic.maxQueueSize) {
				statistic.maxQueueSize = eventsInQueue.length;
			}
		},
		setup: (isWindowFocused: boolean) => {
			const staticticsInterval = setInterval(() => {
				sendAnalytics(cloneDeep(statistic), isWindowFocused, createAnalyticsEvent);
				statistic.currentQueue = [];
				statistic.maxCalls1m = [];
				statistic.maxCalls5m = [];
				statistic.maxQueueSize = 0;
			}, STATISTIC_INTERVAL);

			const volumeStaticticsInterval = setInterval(() => {
				sendVolumeAnalytics([...volume.calls], isWindowFocused, createAnalyticsEvent);
				volume.calls = [];
			}, VOLUME_STATISTIC_INTERVAL);

			// Cleanup intervals and listeners
			return () => {
				clearInterval(staticticsInterval);
				clearInterval(volumeStaticticsInterval);
			};
		},
		update: (eventsInQueue: Event[]) => {
			statistic.currentQueue = eventsInQueue;
			if (eventsInQueue.length > statistic.maxQueueSize) {
				statistic.maxQueueSize = eventsInQueue.length;
			}

			if (volume.calls1m.length > 0 && volume.headEventTime1m + TIME_WINDOW_1M < Date.now()) {
				if (volume.calls1m.length > statistic.maxCalls1m.length) {
					statistic.maxCalls1m = volume.calls1m;
				}
				volume.calls1m = [];
			}

			if (volume.calls5m.length > 0 && volume.headEventTime5m + TIME_WINDOW_5M < Date.now()) {
				if (volume.calls5m.length > statistic.maxCalls5m.length) {
					statistic.maxCalls5m = volume.calls5m;
				}
				volume.calls5m = [];
				volume.calls1m = [];
			}
		},
	};
};
