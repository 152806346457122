import React from 'react';
import type { ExperienceAttributes } from '@atlassian/jira-experience-tracker/src/common/types.tsx';
import { useExperienceFail } from '@atlassian/jira-experience-tracker/src/ui/experience-fail/index.tsx';
import {
	ExperienceStart,
	useExperienceStart,
} from '@atlassian/jira-experience-tracker/src/ui/experience-start/index.tsx';
import {
	useExperienceSuccess,
	ExperienceSuccess,
} from '@atlassian/jira-experience-tracker/src/ui/experience-success/index.tsx';
import { type IssueDesignsExperiences, ISSUE_DESIGNS_ANALYTICS_SOURCE } from './constants.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	ISSUE_DESIGNS_ANALYTICS_SOURCE,
	VIEW_DESIGN_SECTION_EXPERIENCE,
	LINK_DESIGN_EXPERIENCE,
	UNLINK_DESIGN_EXPERIENCE,
	VIEW_EXPANDED_DESIGN_EXPERIENCE,
	VIEW_ADD_DESIGN_BUTTON_EXPERIENCE,
	LOAD_DESIGN_PANEL_QUERY_EXPERIENCE,
} from './constants';

type IssueDesignsExperienceStartProps = {
	experience: IssueDesignsExperiences;
	experienceId?: string;
};

type UseIssueDesignsExperienceEndProps = {
	experience: IssueDesignsExperiences;
	attributes?: ExperienceAttributes;
};

export const IssueDesignsExperienceStart = ({
	experienceId,
	experience,
}: IssueDesignsExperienceStartProps) => (
	<ExperienceStart
		experienceId={experienceId}
		experience={experience}
		analyticsSource={ISSUE_DESIGNS_ANALYTICS_SOURCE}
	/>
);

export const IssueDesignsExperienceSuccess = ({
	experience,
	attributes,
}: UseIssueDesignsExperienceEndProps) => (
	<ExperienceSuccess experience={experience} attributes={attributes} />
);

export const useIssueDesignsExperienceStart = ({
	experienceId,
	experience,
}: IssueDesignsExperienceStartProps) =>
	useExperienceStart({
		experienceId,
		experience,
		analyticsSource: ISSUE_DESIGNS_ANALYTICS_SOURCE,
	});

export const useIssueDesignsExperienceSuccess = ({
	experience,
	attributes,
}: UseIssueDesignsExperienceEndProps) => useExperienceSuccess({ experience, attributes });

export const useIssueDesignsExperienceFail = ({
	experience,
	attributes,
}: UseIssueDesignsExperienceEndProps) =>
	useExperienceFail({
		experience,
		attributes,
	});
