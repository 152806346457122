// FETCH_JIRA_APP_LINKS_REQUEST
export const FETCH_JIRA_APP_LINKS_REQUEST = 'FETCH_JIRA_APP_LINKS_REQUEST';

export const fetchJiraAppLinks = () => ({
	type: FETCH_JIRA_APP_LINKS_REQUEST,
});

export type FetchJiraAppLinksRequest = ReturnType<typeof fetchJiraAppLinks>;

// FETCH_JIRA_APP_LINKS_SUCCESS
export const FETCH_JIRA_APP_LINKS_SUCCESS = 'FETCH_JIRA_APP_LINKS_SUCCESS';

export type JiraAppLink = {
	id: string;
	name: string;
	authUrl: string;
	url: string;
	primary: boolean;
	requireCredentials: boolean;
};

export type JiraAppLinksResponse = JiraAppLink[];

export const fetchJiraAppLinksSuccess = (response: JiraAppLinksResponse) => ({
	type: FETCH_JIRA_APP_LINKS_SUCCESS,
	payload: response,
});

// FETCH_JIRA_APP_LINKS_FAILED
export const FETCH_JIRA_APP_LINKS_FAILED = 'FETCH_JIRA_APP_LINKS_FAILED' as const;

export const fetchJiraAppLinksFailed = () => ({
	type: FETCH_JIRA_APP_LINKS_FAILED,
});

export type FetchJiraAppLinksSuccess = ReturnType<typeof fetchJiraAppLinksSuccess>;
export type FetchJiraAppLinksFailed = ReturnType<typeof fetchJiraAppLinksFailed>;
