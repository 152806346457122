import React from 'react';
import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import IssueMeatballMenu from '@atlassian/jira-issue-meatball-menu/src/ui/index.tsx';
import type { State } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import {
	analyticsSourceSelector,
	applicationKeySelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import {
	isCompletedLoadingSelector,
	projectTypeSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';
import { canExportIssueSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/permissions-selector.tsx';
import {
	forgeActiveIssueActionSelector,
	forgeIssueActionsSelector,
} from '@atlassian/jira-issue-view-store/src/ecosystem/forge/forge-extensions-selector.tsx';
import { hasParentIssueSelector } from '@atlassian/jira-issue-view-store/src/issue-field/state/selectors/parent-issue-selector.tsx';
import {
	isBannerDismissed,
	shouldShowTourSelector,
} from '@atlassian/jira-issue-view-store/src/selectors/changeboarding-selector.tsx';
import {
	shouldShowActionsSelector,
	shouldShowSwiftLinkSelector,
	shouldShowFindOutMoreSelector,
} from '@atlassian/jira-issue-view-store/src/selectors/issue-actions-selector.tsx';
import IssueActionsView from './issue-actions-view.tsx';
import type { OwnProps, StateProps } from './types.tsx';

const IssueActions = connect(
	(state: State): StateProps => ({
		forgeIssueActions: forgeIssueActionsSelector(state),
		isLoading: !isCompletedLoadingSelector(state),
		isForgeLoading: !!forgeActiveIssueActionSelector(state)?.isLoading,
		shouldShowPulse: isBannerDismissed(state),
		hasParent: hasParentIssueSelector(state),
		shouldShowActions: shouldShowActionsSelector(state),
		shouldShowStartTour: shouldShowTourSelector(state),
		shouldShowFindOutMore: shouldShowFindOutMoreSelector(state),
		shouldShowConfiguration: !!shouldShowSwiftLinkSelector(state) && !!projectTypeSelector(state),
		shouldShowExportActions: canExportIssueSelector(state),
		isSoftwareProjectType: projectTypeSelector(state) === SOFTWARE_PROJECT,
		analyticsSource: analyticsSourceSelector(state),
		applicationKey: applicationKeySelector(state) || '',
	}),
	{},
)(IssueActionsView);

const IssueActionsMenu = (props: OwnProps) => {
	if (__SERVER__) {
		return <IssueMeatballMenu isDisabled getMeatballMenuData={() => []} />;
	}

	return (
		<IssueActions
			viewModeOptions={props.viewModeOptions}
			issueDeleteCallbacks={props.issueDeleteCallbacks}
			onClose={props.onClose}
		/>
	);
};

export default IssueActionsMenu;
