/**
 * @generated SignedSource<<3cc1201a387652eff2dc9e87ab42e67a>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type issueViewOrganizationField_servicedeskCustomerServiceCustomFields_OrganizationField$data = {
  readonly fieldId: string;
  readonly id: string;
  readonly name: string;
  readonly selectedOrganization: {
    readonly id: string;
    readonly organizationName: string | null | undefined;
  } | null | undefined;
  readonly type: string;
  readonly " $fragmentType": "issueViewOrganizationField_servicedeskCustomerServiceCustomFields_OrganizationField";
};
export type issueViewOrganizationField_servicedeskCustomerServiceCustomFields_OrganizationField$key = {
  readonly " $data"?: issueViewOrganizationField_servicedeskCustomerServiceCustomFields_OrganizationField$data;
  readonly " $fragmentSpreads": FragmentRefs<"issueViewOrganizationField_servicedeskCustomerServiceCustomFields_OrganizationField">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "issueViewOrganizationField_servicedeskCustomerServiceCustomFields_OrganizationField",
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "concreteType": "JiraServiceManagementOrganization",
      "kind": "LinkedField",
      "name": "selectedOrganization",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "name": "organizationName"
        }
      ]
    }
  ],
  "type": "JiraCustomerServiceOrganizationField"
};
})();

(node as any).hash = "be2efab2c0d48059a45e6196adc1ef2a";

export default node;
