import type { ChildIssueType } from '@atlassian/jira-issue-view-common-types/src/child-issue-type.tsx';
import type { FetchIssueTypesStatus } from '../../model/types.tsx';

// SHOW INLINE CREATE
export const SHOW_INLINE_CREATE = 'state.ui.SHOW_INLINE_CREATE' as const;
export const showInlineCreate = () => ({
	type: SHOW_INLINE_CREATE,
});

export type ShowInlineCreateAction = ReturnType<typeof showInlineCreate>;

// HIDE INLINE CREATE
export const HIDE_ADD_CHILD_ISSUE = 'state.ui.HIDE_ADD_CHILD_ISSUE' as const;

export const hideAddChildIssue = () => ({
	type: HIDE_ADD_CHILD_ISSUE,
});

type HideAddChildIssueAction = ReturnType<typeof hideAddChildIssue>;

// SET CHILD PANEL VIEW MODE
export const SET_CHILD_PANEL_VIEW = 'state.ui.SET_CHILD_PANEL_VIEW' as const;
export const CHILD_PANEL_VIEWS = Object.freeze({
	InlineCreate: 'InlineCreate',
	AddExistingIssue: 'AddExistingIssue',
});

export type ChildPanelView = (typeof CHILD_PANEL_VIEWS)[keyof typeof CHILD_PANEL_VIEWS];

export const setChildPanelView = (view: ChildPanelView) => ({
	type: SET_CHILD_PANEL_VIEW,
	view,
});

type SetChildPanelView = ReturnType<typeof setChildPanelView>;

// SET CHILD CREATE SUMMARY
export const SET_CHILD_CREATE_SUMMARY = 'state.ui.SET_CHILD_CREATE_SUMMARY' as const;

export const setChildCreateSummary = (summary: string) => ({
	type: SET_CHILD_CREATE_SUMMARY,
	summary,
});

type SetChildCreateSummary = ReturnType<typeof setChildCreateSummary>;

// TOGGLE HIDE DONE
export const TOGGLE_HIDE_DONE = 'state.ui.TOGGLE_HIDE_DONE' as const;

export const toggleHideDone = () => ({
	type: TOGGLE_HIDE_DONE,
});

type ToggleHideDoneAction = ReturnType<typeof toggleHideDone>;

// SELECTED ISSUE TYPE CHANGED
export const SELECTED_ISSUE_TYPE_CHANGED = 'state.ui.SELECTED_ISSUE_TYPE_CHANGED' as const;

export type SelectedIssueTypeChangedPayload = ChildIssueType;

export const selectedIssueTypeChanged = (payload: SelectedIssueTypeChangedPayload) => ({
	type: SELECTED_ISSUE_TYPE_CHANGED,
	payload,
});

export type SelectedIssueTypeChangedAction = ReturnType<typeof selectedIssueTypeChanged>;

export const SET_FETCH_ISSUE_TYPES_STATUS = 'state.ui.SET_FETCH_ISSUE_TYPES_STATUS';

export const setFetchIssueTypesStatus = (payload: FetchIssueTypesStatus) => ({
	type: SET_FETCH_ISSUE_TYPES_STATUS,
	payload,
});

type SetFetchIssueTypesStatus = ReturnType<typeof setFetchIssueTypesStatus>;

export type Action =
	| SetChildPanelView
	| SetChildCreateSummary
	| ShowInlineCreateAction
	| HideAddChildIssueAction
	| ToggleHideDoneAction
	| SelectedIssueTypeChangedAction
	| SetFetchIssueTypesStatus;
