import { PROJECT_KEY_TO_FILTER_ID_MAP } from './constants.tsx';
import type { AllowedProjectKey } from './types.tsx';

export const isProjectAllowed = (projectKey: string): projectKey is AllowedProjectKey =>
	Object.prototype.hasOwnProperty.call(PROJECT_KEY_TO_FILTER_ID_MAP, projectKey);

export const getProjectKeyFromIssueKey = (issueKey: string): string | undefined => {
	const projectKeyRegex = /(^[A-Z]+)-/;
	const projectMatch = issueKey.match(projectKeyRegex);
	if (projectMatch && projectMatch.length > 1) {
		return projectMatch[1];
	}
	return undefined;
};
