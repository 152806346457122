import React from 'react';
import { Stack, Flex, xcss } from '@atlaskit/primitives';
import { Skeleton } from '@atlassian/jira-common-components-skeleton/src/styled.tsx';
import TabsPanelSkeleton from '../skeleton/index.tsx';

export const TabsSkeleton = () => (
	<Stack space="space.100">
		<Flex gap="space.200" xcss={tabHeaderStyles}>
			<Skeleton height="20px" width="96px" />
			<Skeleton height="20px" width="72px" />
			<Skeleton height="20px" width="84px" />
			<Skeleton height="20px" width="72px" />
		</Flex>
		<Flex>
			<Skeleton height="2px" width="100%" />
		</Flex>
		<Flex xcss={tabBodyStyles}>
			<TabsPanelSkeleton />
		</Flex>
	</Stack>
);

const tabHeaderStyles = xcss({
	marginTop: 'space.075',
});

const tabBodyStyles = xcss({
	marginTop: 'space.200',
});
