import type { ProductType as LinkingProductType } from '@atlaskit/linking-common';
import { metrics } from '@atlassian/browser-metrics';

export const OPEN_LOOM_RECORDER_SDK_INTERACTION_METRIC = metrics.interaction({
	key: 'open-loom-recorder-sdk',
});

export const INITIALIZE_LOOM_RECORDER_SDK_INTERACTION_METRIC = metrics.interaction({
	key: 'initialize-loom-recorder-sdk',
});

export const RECORD_LOOM_ENTRY_POINT = 'record-loom-entry-point';
export const INITIALIZE_LOOM_ENTRY_POINT = 'initialize-loom-entry-point';

export type ProductType = LinkingProductType | 'UNKNOWN';
