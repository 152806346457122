/**
 * @generated SignedSource<<dc1eaa1e037d6c8295b63d546c473a1f>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutColorField_IssueViewColorField$data = {
  readonly __typename: "JiraColorField";
  readonly color: {
    readonly colorKey: string;
  } | null | undefined;
  readonly description: string | null | undefined;
  readonly fieldConfig: {
    readonly isEditable: boolean | null | undefined;
  } | null | undefined;
  readonly fieldId: string;
  readonly id: string;
  readonly name: string;
  readonly type: string;
  readonly " $fragmentType": "ui_issueViewLayoutColorField_IssueViewColorField";
};
export type ui_issueViewLayoutColorField_IssueViewColorField$key = {
  readonly " $data"?: ui_issueViewLayoutColorField_IssueViewColorField$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutColorField_IssueViewColorField">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutColorField_IssueViewColorField",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "ScalarField",
      "name": "name"
    },
    {
      "kind": "ScalarField",
      "name": "description"
    },
    {
      "kind": "ScalarField",
      "name": "__typename"
    },
    {
      "concreteType": "JiraFieldConfig",
      "kind": "LinkedField",
      "name": "fieldConfig",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "name": "isEditable"
        }
      ]
    },
    {
      "concreteType": "JiraColor",
      "kind": "LinkedField",
      "name": "color",
      "plural": false,
      "selections": [
        {
          "kind": "RequiredField",
          "field": {
            "kind": "ScalarField",
            "name": "colorKey"
          },
          "action": "THROW",
          "path": "color.colorKey"
        }
      ]
    }
  ],
  "type": "JiraColorField"
};

(node as any).hash = "6f241281255bdfde844af1f89a15ba35";

export default node;
