// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	convertToIssue: {
		id: 'issue.convert-to-issue',
		defaultMessage: 'Convert to Issue',
		description: 'Meatball option to change a subtask to a parent level issue',
	},
	convertToIssueIssueTermRefresh: {
		id: 'issue.convert-to-issue-issue-term-refresh',
		defaultMessage: 'Convert to Issue',
		description: 'Meatball option to change a subtask to a parent level issue',
	},
});
