import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	errorTitle: {
		id: 'issue-field-labels-inline-edit-view-full.labels.error-title',
		defaultMessage: "We couldn't set the labels value",
		description: 'Error message informing the user that updating the labels field failed',
	},
	errorMessage: {
		id: 'issue-field-labels-inline-edit-view-full.labels.error-message',
		defaultMessage: 'Check your connection, then give it another try.',
		description: 'Error message when save fails',
	},
	noValueText: {
		id: 'issue-field-labels-inline-edit-view-full.labels.no-value-text',
		defaultMessage: 'None',
		description: 'Message when a field has no value',
	},
	newLabelTooLongError: {
		id: 'issue-field-labels-inline-edit-view-full.labels.new-label-too-long-error',
		defaultMessage: "Labels can't be more than {maxLength} characters.",
		description: 'Error message to display when a new label is longer than 255 characters.',
	},
	newLabelContainsSpacesError: {
		id: 'issue-field-labels-inline-edit-view-full.labels.new-label-contains-spaces-error',
		defaultMessage: "Labels can't have spaces.",
		description: 'Error message to display when a new label contains a space.',
	},
	maximumNumberOfLabelsExceededError: {
		id: 'issue-field-labels-inline-edit-view-full.labels.maximum-number-of-labels-exceeded-error',
		defaultMessage: "Can't have more than {maxNumberOfLabels} labels.",
		description: 'Error message to display when the maximum number of labels has been exceeded.',
	},
});
