import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const devOpsAppRecommendationPanelEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() =>
			import(
				/* webpackChunkName: "async-dev-ops-app-recommendation-panel" */ './src/ui/dev-ops-app-recommendations-panel'
			),
	),
	getPreloadProps: () => ({
		queries: {},
	}),
});
