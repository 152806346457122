import { stringify } from 'query-string';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { useEditField } from '@atlassian/jira-issue-field-base/src/services/edit-field-service/main.tsx';
import { useFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import { secondsToWholeMinuteTimeString } from '@atlassian/jira-issue-format-time/src/common/utils/seconds-to-whole-minute-time-string/index.tsx';
import type { TimeTrackingState } from '@atlassian/jira-issue-shared-types/src/common/types/time-tracking-type.tsx';
import { useTimeTrackingField } from '@atlassian/jira-issue-time-estimate/src/services/index.tsx';
import { TIME_TRACKING_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import getXsrfToken from '@atlassian/jira-platform-xsrf-token/src/index.tsx';
import type { IssueKey, IssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { originalEstimateBodyParams } from '../common/constants.tsx';
import type { OriginalEstimateValue } from '../common/types.tsx';

type Props = {
	issueKey: IssueKey;
	issueId: IssueId;
	fieldId: string;
	timeTrackingValue: TimeTrackingState;
	onSubmit?: (
		oldValue: OriginalEstimateValue,
		newValue: OriginalEstimateValue,
		meta: unknown,
		event: UIAnalyticsEvent,
	) => void;
	onSuccess?: (arg1: OriginalEstimateValue) => void;
	onFailure?: (arg1: Error) => void;
};

export type SaveFieldArgs = {
	issueId: IssueId;
	newValue: OriginalEstimateValue;
	timeTrackingValue: TimeTrackingState;
};

// Time tracking is updated if user has logged spent time or manually changed the remaining time (remaining is different to original)
export const hasTimeTrackingUpdated = (timeTrackingValue?: TimeTrackingState) => {
	const hasTimeLogged = timeTrackingValue?.timeSpentSeconds != null;
	const hasTimeRemainUpdated =
		timeTrackingValue?.remainingEstimateSeconds != null &&
		timeTrackingValue?.remainingEstimateSeconds !== timeTrackingValue?.originalEstimateSeconds;
	return hasTimeLogged || hasTimeRemainUpdated;
};

export const saveField = async ({ issueId, newValue, timeTrackingValue }: SaveFieldArgs) => {
	const hasTimeRemainingEstimate = hasTimeTrackingUpdated(timeTrackingValue);

	// Convert the value in seconds to a time string to send to Jira.
	// 1m is always 60s, so we can do this safely.
	// we have to convert the final value to a whole number as passing decimal
	// values in minutes errors.
	const value = newValue !== null ? secondsToWholeMinuteTimeString(newValue) : null;

	// https://jdog.jira-dev.com/browse/BENTO-792 - The Time Estimate field is always shown,
	// because we always the return the timeoriginalestimate field from the backend. In order to
	// prevent the field from being visible but not editable when Time Tracking is missing from the
	// screen config, we use this legacy endpoint to save the field.
	return performPostRequest('/secure/AjaxIssueAction.jspa?decorator=none', {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		},
		body: stringify({
			issueId,
			timetracking_originalestimate: value,
			timetracking_targetsubfield: hasTimeRemainingEstimate
				? 'timetracking_originalestimate'
				: undefined,
			...originalEstimateBodyParams,
			singleFieldEdit: true,
			skipScreenCheck: true,
			atl_token: getXsrfToken(),
		}),
	});
};

export const useOriginalEstimateField = ({
	issueKey,
	issueId,
	fieldId,
	timeTrackingValue,
	onSubmit,
	onSuccess,
	onFailure,
}: Props) => {
	// useFieldConfig now returns an object { value, loading } instead of just the fieldConfig value
	// if possible when touching this, try and refactor to pass the new object instead of just the value
	const [{ value: fieldConfig }] = useFieldConfig(issueKey, fieldId);
	const fieldType = fieldConfig?.schema.system || '';

	const saveClassicField = (_: IssueKey, __: string, newValue: OriginalEstimateValue) =>
		saveField({ issueId, newValue, timeTrackingValue });

	const [{ value, error }, { saveValue, resetError }] = useEditField<OriginalEstimateValue, null>({
		fieldKey: fieldId,
		issueKey,
		fieldType,
		initialValue: null,
		saveField: saveClassicField,
		onSuccess,
		onFailure,
		onSubmit,
	});

	return [
		{ value, error, fieldConfig },
		{ saveValue, resetError },
	] as const;
};

type PropsWithTimeTracking = {
	issueKey: IssueKey;
	issueId: IssueId;
	fieldKey: string;
	onSubmit?: (
		oldValue: OriginalEstimateValue,
		newValue: OriginalEstimateValue,
		meta: unknown,
		event: UIAnalyticsEvent,
	) => void;
	onSuccess?: (arg1: OriginalEstimateValue) => void;
	onFailure?: (arg1: Error) => void;
};

/**
 * Hook for a special case for UIM which does not have value for time tracking
 *  so the hook below will get the field values for time tracking field and pass it to the original estimation field hook
 */
export const useOriginalEstimateFieldWithTimeTracking = ({
	issueKey,
	issueId,
	fieldKey,
	onSubmit,
	onSuccess,
	onFailure,
}: PropsWithTimeTracking) => {
	const timeTrack = useTimeTrackingField({
		fieldKey: TIME_TRACKING_TYPE,
		issueId,
		issueKey,
	});

	let timeTrackingValue = {};
	if (timeTrack) {
		const trackData: TimeTrackingState = timeTrack[0].value;
		timeTrackingValue = {
			originalEstimateSeconds: trackData.originalEstimateSeconds,
			timeSpentSeconds: trackData.timeSpentSeconds,
			remainingEstimateSeconds: trackData.remainingEstimateSeconds,
		};
	}

	const [{ value, error, fieldConfig }, { saveValue, resetError }] = useOriginalEstimateField({
		fieldId: fieldKey,
		issueKey,
		issueId,
		timeTrackingValue,
		onSuccess,
		onFailure,
		onSubmit,
	});

	return [
		{ value, error, fieldConfig },
		{ saveValue, resetError },
	] as const;
};
