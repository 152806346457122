import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import {
	createStore,
	createHook,
	createContainer,
	createSubscriber,
	defaultRegistry,
} from '@atlassian/react-sweet-state';
import { fireModalTriggeredAnalytics, fireAddTeammatetoJiraAnalytics } from './analytics/index.tsx';
import type { State, Actions, ContainerProps, ContextConfig } from './types.tsx';

const CONTROLLER_NAME = 'invite-people-drawer';

const initialState: State = {
	isOpen: false,
	inviteFlow: 'unknown',
	continueUrl: undefined,
};

const Store = createStore<State, Actions>({
	name: CONTROLLER_NAME,
	initialState,
	actions: {
		openDrawer:
			(
				createAnalyticsEvent: CreateUIAnalyticsEvent | null,
				{ inviteFlow, analyticsSource, continueUrl }: ContextConfig,
			) =>
			({ setState }) => {
				fireAddTeammatetoJiraAnalytics(createAnalyticsEvent, analyticsSource);

				fireModalTriggeredAnalytics(createAnalyticsEvent, inviteFlow, analyticsSource);

				setState({
					isOpen: true,
					inviteFlow,
					continueUrl,
				});
			},
		closeDrawer:
			() =>
			({ setState }) =>
				setState(initialState),
	},
});

export const useInvitePeopleDrawer = createHook(Store);
export const InvitePeopleDrawerProvider = createContainer<State, Actions, ContainerProps>(Store, {
	onInit:
		() =>
		({ setState }, { isOpen }) => {
			setState({ isOpen });
		},
});

export const InvitePeopleDrawerSubscriber = createSubscriber<
	State,
	Actions,
	boolean,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Record<any, any>
>(Store, {
	displayName: 'InvitePeopleDrawerSubscriber',
	selector: (state: State) => state.isOpen,
});

export const triggerOpenDrawer = (
	createAnalyticsEvent: CreateUIAnalyticsEvent | null,
	contextConfig: ContextConfig,
) => {
	defaultRegistry.getStore(Store)?.actions.openDrawer(createAnalyticsEvent, contextConfig);
};
