/**
 * @generated SignedSource<<549b031c5bb49f31701052b0caeb2143>>
 * @relayHash 056deccd0efa6915b352bf79bff7be6f
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 28659a6750bb8a6cc2a4f5ae9f3557603b7b1ef5dd0d1fb5065f9082560406d7

import type { ConcreteRequest, Query } from 'relay-runtime';
export type transitionsRelayQueryForStatusFieldQuery$variables = {
  cloudId: string;
  issueKey: string;
};
export type transitionsRelayQueryForStatusFieldQuery$data = {
  readonly jira: {
    readonly issueByKey: {
      readonly statusField: {
        readonly transitions: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly hasPreConditions: boolean | null | undefined;
              readonly hasScreen: boolean | null | undefined;
              readonly isGlobal: boolean | null | undefined;
              readonly isInitial: boolean | null | undefined;
              readonly isLooped: boolean | null | undefined;
              readonly name: string | null | undefined;
              readonly to: {
                readonly name: string | null | undefined;
                readonly statusCategory: {
                  readonly statusCategoryId: string;
                } | null | undefined;
                readonly statusId: string;
              } | null | undefined;
              readonly transitionId: number | null | undefined;
            } | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type transitionsRelayQueryForStatusFieldQuery = {
  response: transitionsRelayQueryForStatusFieldQuery$data;
  variables: transitionsRelayQueryForStatusFieldQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "issueKey"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "kind": "Variable",
    "name": "key",
    "variableName": "issueKey"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "transitionId"
},
v3 = {
  "kind": "ScalarField",
  "name": "name"
},
v4 = {
  "kind": "ScalarField",
  "name": "hasScreen"
},
v5 = {
  "kind": "ScalarField",
  "name": "isGlobal"
},
v6 = {
  "kind": "ScalarField",
  "name": "isInitial"
},
v7 = {
  "kind": "ScalarField",
  "name": "isLooped"
},
v8 = {
  "kind": "ScalarField",
  "name": "hasPreConditions"
},
v9 = {
  "kind": "ScalarField",
  "name": "statusId"
},
v10 = {
  "kind": "ScalarField",
  "name": "statusCategoryId"
},
v11 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "transitionsRelayQueryForStatusFieldQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraIssue",
            "kind": "LinkedField",
            "name": "issueByKey",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraStatusField",
                "kind": "LinkedField",
                "name": "statusField",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraTransitionConnection",
                    "kind": "LinkedField",
                    "name": "transitions",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraTransitionEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "concreteType": "JiraTransition",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v3/*: any*/),
                              (v4/*: any*/),
                              (v5/*: any*/),
                              (v6/*: any*/),
                              (v7/*: any*/),
                              (v8/*: any*/),
                              {
                                "concreteType": "JiraStatus",
                                "kind": "LinkedField",
                                "name": "to",
                                "plural": false,
                                "selections": [
                                  (v9/*: any*/),
                                  (v3/*: any*/),
                                  {
                                    "concreteType": "JiraStatusCategory",
                                    "kind": "LinkedField",
                                    "name": "statusCategory",
                                    "plural": false,
                                    "selections": [
                                      (v10/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "transitionsRelayQueryForStatusFieldQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraIssue",
            "kind": "LinkedField",
            "name": "issueByKey",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraStatusField",
                "kind": "LinkedField",
                "name": "statusField",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraTransitionConnection",
                    "kind": "LinkedField",
                    "name": "transitions",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraTransitionEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "concreteType": "JiraTransition",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v3/*: any*/),
                              (v4/*: any*/),
                              (v5/*: any*/),
                              (v6/*: any*/),
                              (v7/*: any*/),
                              (v8/*: any*/),
                              {
                                "concreteType": "JiraStatus",
                                "kind": "LinkedField",
                                "name": "to",
                                "plural": false,
                                "selections": [
                                  (v9/*: any*/),
                                  (v3/*: any*/),
                                  {
                                    "concreteType": "JiraStatusCategory",
                                    "kind": "LinkedField",
                                    "name": "statusCategory",
                                    "plural": false,
                                    "selections": [
                                      (v10/*: any*/),
                                      (v11/*: any*/)
                                    ]
                                  },
                                  (v11/*: any*/)
                                ]
                              },
                              (v11/*: any*/)
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  (v11/*: any*/)
                ]
              },
              (v11/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "28659a6750bb8a6cc2a4f5ae9f3557603b7b1ef5dd0d1fb5065f9082560406d7",
    "metadata": {},
    "name": "transitionsRelayQueryForStatusFieldQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "149d6549724f4aab7d08100ce8f1bebb";

export default node;
