import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import {
	OLDEST_FIRST,
	NEWEST_FIRST,
} from '@atlassian/jira-issue-shared-types/src/common/types/activity-sort-order-type.tsx';
import { issueViewLayers as layers } from '@atlassian/jira-issue-view-layers/src/index.tsx';

const CONTAINER_HEIGHT = 100;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const StyledCommentShortcutHint = styled.small({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N200),
	display: 'block',
	marginTop: token('space.100', '8px'),
	whiteSpace: 'nowrap',
	font: token('font.body.UNSAFE_small'),
});
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766, PLEASE FIX - ENABLING FLAT LINT CONFIG
export const StickyWrapper = styled.div<{
	isHidden: boolean;
	isKeylineVisible: boolean;
	shouldApplySticky: boolean;
	shouldApplyTopSticky: boolean;
	sortOrder: string;
	isModal: boolean;
	isCompact: boolean;
}>(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) =>
		props.shouldApplySticky && {
			position: props.isCompact ? 'static' : 'sticky',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			zIndex: props.isKeylineVisible ? layers.baseLayer : 0,
			transition: 'transform 0.3s cubic-bezier(0.86, 0, 0.07, 1) 0.1s',
			transform: props.isHidden
				? `translate(0, ${
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
						props.sortOrder === OLDEST_FIRST ? CONTAINER_HEIGHT : CONTAINER_HEIGHT * -1
					}px)`
				: 'translate(0, 0)',

			bottom: token('space.negative.025', '-2px'),

			top: props.shouldApplyTopSticky ? 0 : undefined,
			background: props.isModal
				? token('elevation.surface.overlay', 'white')
				: token('elevation.surface', 'white'),
			paddingLeft: token('space.100', '8px'),
			marginLeft: token('space.negative.100', '-8px'),
		},
);

// keyline is displayed above the form when it is in a sticky position to the bottom
// keyline is displayed below the form when it is in a sticky position to the top
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Wrapper = styled.div<{
	isKeylineVisible: boolean;
	isModal: boolean;
	shouldApplySticky: boolean;
	shouldApplyTopSticky: boolean;
	sortOrder: string;
}>(
	{
		display: 'flex',
		paddingTop: token('space.200', '16px'),
		paddingBottom: token('space.200', '16px'),
		/* Needed to adjust the amount of space above and below the comment add box when it is at the top of the feed */
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		marginBottom: (props) =>
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			props.sortOrder === NEWEST_FIRST ? token('space.negative.200', '-16px') : 0,
		marginTop: token('space.100', '8px'),

		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		background: (props) =>
			props.isModal
				? token('elevation.surface.overlay', 'white')
				: token('elevation.surface', 'white'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) =>
		props.isKeylineVisible && props.shouldApplySticky
			? {
					boxShadow: props.shouldApplyTopSticky
						? // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
							`0 2px 0 0 ${token('color.border', colors.N30)}, 0 -2px 0 0 ${token(
								'color.border',
								// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
								colors.N30,
							)}`
						: // eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
							`0 -2px 0 0 ${token('color.border', colors.N30)}`,
					marginTop: token('space.100', '8px'),
				}
			: undefined,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const AvatarContainer = styled.div({
	marginRight: token('space.150', '12px'),
	marginTop: token('space.025', '2px'),
});
