import { useState, useCallback } from 'react';
import type { Environment } from '@atlassian/jira-shared-types/src/tenant-context.tsx';
import type { AuditItemsResult, AuditlogSummary } from '../../common/types.tsx';

const getApiPath = (env: Environment): string => {
	if (env === 'staging') {
		return 'staging';
	}

	return 'pro';
};

const getAuditItemsUrl = (env: Environment, cloudId: string, projectId: string, issueId: string) =>
	`/gateway/api/automation/internal-api/jira/${cloudId}/${getApiPath(env)}/rest/${projectId}/macros/${issueId}/audit-items`;

const isObjectAuditlogSummary = (value: unknown): value is AuditlogSummary => {
	if (typeof value !== 'object' || value === null) {
		return false;
	}
	// ignore @typescript-eslint/consistent-type-assertions because we are checking the type of the object
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const potentialAuditlogSummary = value as AuditlogSummary;
	return (
		typeof potentialAuditlogSummary.id === 'number' &&
		typeof potentialAuditlogSummary.category === 'string' &&
		typeof potentialAuditlogSummary.ruleId === 'number' &&
		(typeof potentialAuditlogSummary.ruleIdUuid === 'undefined' ||
			typeof potentialAuditlogSummary.ruleIdUuid === 'string') &&
		typeof potentialAuditlogSummary.ruleName === 'string' &&
		typeof potentialAuditlogSummary.startTime === 'number' &&
		(typeof potentialAuditlogSummary.endTime === 'undefined' ||
			typeof potentialAuditlogSummary.endTime === 'number')
	);
};

const isObjectArrayOfAuditlogSummary = (value: unknown): value is AuditlogSummary[] => {
	if (!Array.isArray(value)) {
		return false;
	}
	return value.every(isObjectAuditlogSummary);
};

const useAuditItems = (
	env: Environment,
	cloudId: string,
	projectId: string | undefined,
	issueId: string | undefined,
): [AuditItemsResult, () => Promise<void>] => {
	const [auditItemsResults, setAuditItemsResults] = useState<AuditItemsResult>({
		status: 'initial',
	});

	const fetchAuditItems = useCallback(async () => {
		if (projectId === undefined || issueId === undefined) {
			setAuditItemsResults({
				status: 'error',
			});
			return;
		}

		try {
			setAuditItemsResults({
				status: 'loading',
			});

			const response = await fetch(getAuditItemsUrl(env, cloudId, projectId, issueId), {
				method: 'GET',
			});
			const data: unknown = await response.json();

			if (!isObjectArrayOfAuditlogSummary(data)) {
				throw new Error('Data shape is not expected');
			}

			setAuditItemsResults({
				status: 'loaded',
				value: data,
			});
		} catch (e: unknown) {
			setAuditItemsResults({
				status: 'error',
			});
		}
	}, [env, cloudId, projectId, issueId]);

	return [auditItemsResults, fetchAuditItems];
};

export default useAuditItems;
