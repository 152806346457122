/**
 * @generated SignedSource<<c4257dc7989a8dace4c270f9ec8afd9a>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type multiSelect_issueFieldMultiSelectEditviewFull_MultiSelectEditViewWithFieldOptionsFragment$data = {
  readonly node: {
    readonly fieldOptions?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly isDisabled: boolean | null | undefined;
          readonly label: string | null | undefined;
          readonly optionId: string;
          readonly value: string;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "multiSelect_issueFieldMultiSelectEditviewFull_MultiSelectEditViewWithFieldOptionsFragment";
};
export type multiSelect_issueFieldMultiSelectEditviewFull_MultiSelectEditViewWithFieldOptionsFragment$key = {
  readonly " $data"?: multiSelect_issueFieldMultiSelectEditviewFull_MultiSelectEditViewWithFieldOptionsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"multiSelect_issueFieldMultiSelectEditviewFull_MultiSelectEditViewWithFieldOptionsFragment">;
};

import multiSelect_multiSelectSearchRefetchQuery_graphql from './multiSelect_multiSelectSearchRefetchQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = [
  "node",
  "fieldOptions"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterById"
    },
    {
      "defaultValue": 50,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "id"
    },
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "searchBy"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": multiSelect_multiSelectSearchRefetchQuery_graphql
    }
  },
  "name": "multiSelect_issueFieldMultiSelectEditviewFull_MultiSelectEditViewWithFieldOptionsFragment",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "id"
        }
      ],
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": "fieldOptions",
              "args": [
                {
                  "kind": "Variable",
                  "name": "filterById",
                  "variableName": "filterById"
                },
                {
                  "kind": "Variable",
                  "name": "searchBy",
                  "variableName": "searchBy"
                }
              ],
              "concreteType": "JiraOptionConnection",
              "kind": "LinkedField",
              "name": "__multiSelect_issueFieldMultiSelectEditviewFull_fieldOptions_connection",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraOptionEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "concreteType": "JiraOption",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "alias": "value",
                          "kind": "ScalarField",
                          "name": "id"
                        },
                        {
                          "alias": "label",
                          "kind": "ScalarField",
                          "name": "value"
                        },
                        {
                          "kind": "ScalarField",
                          "name": "optionId"
                        },
                        {
                          "kind": "ScalarField",
                          "name": "isDisabled"
                        },
                        {
                          "kind": "ScalarField",
                          "name": "__typename"
                        }
                      ]
                    },
                    {
                      "kind": "ScalarField",
                      "name": "cursor"
                    }
                  ]
                },
                {
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "endCursor"
                    },
                    {
                      "kind": "ScalarField",
                      "name": "hasNextPage"
                    }
                  ]
                }
              ]
            }
          ],
          "type": "JiraMultipleSelectField"
        }
      ]
    }
  ],
  "type": "Query"
};
})();

(node as any).hash = "a3ae907dbf5796c4114f4ff7702c4077";

export default node;
