// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	delete: {
		id: 'issue.delete',
		defaultMessage: 'Delete',
		description: 'Label for the issue actions item to delete the issue',
	},
});
