import { defineMessages } from '@atlassian/jira-intl';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';

type MessagesKeys = 'view';

export const messages: Record<MessagesKeys, MessageDescriptor> = defineMessages({
	view: {
		id: 'profilecard-next',
		defaultMessage: 'View profile',
		description: '',
	},
});

type ProfileCardMessageKeys = 'reportedIssues' | 'viewProfile' | 'assignedIssues' | 'viewFilters';

export const profileCardMessages: Record<ProfileCardMessageKeys, MessageDescriptor> =
	defineMessages({
		assignedIssues: {
			id: 'profilecard-next.assigned-issues',
			defaultMessage: 'Assigned issues',
			description:
				'Button on a user profile card that takes you to the list of assigned issues for that user.',
		},
		viewProfile: {
			id: 'profilecard-next.view-profile',
			defaultMessage: 'View profile',
			description: 'Button on a user profile card that takes you to that users profile',
		},
		reportedIssues: {
			id: 'profilecard-next.reported-issues',
			defaultMessage: 'Reported issues',
			description:
				'Button on a user profile card that takes you to the list of reported issues for that user.',
		},
		viewFilters: {
			id: 'profilecard-next.view-filters',
			defaultMessage: 'View filters',
			description:
				'Button on a user profile card that takes you to the list of filters owned by that user.',
		},
	});
