import React, { useCallback } from 'react';
import { ButtonItem } from '@atlaskit/menu';
import type { Props } from './types.tsx';

const LinkToParentButtonItem = ({
	onLinkToParentClick,
	onClick,
	label,
	setDropdownItemRef,
	itemKey,
	isSelected = false,
}: Props) => {
	const handleClick = useCallback(() => {
		onLinkToParentClick();
		onClick?.(itemKey);
	}, [onLinkToParentClick, onClick, itemKey]);

	return (
		<ButtonItem
			key="link-to-parent"
			isSelected={isSelected}
			ref={setDropdownItemRef}
			role="menuitem"
			onClick={handleClick}
		>
			{label}
		</ButtonItem>
	);
};

export default LinkToParentButtonItem;
