export const AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR' as const;
export const CONNECTIVITY_ERROR = 'CONNECTIVITY_ERROR' as const;
export const NOT_FOUND_OR_NO_PERMISSION_ERROR = 'NOT_FOUND_OR_NO_PERMISSION_ERROR' as const;
export const UNKNOWN_ERROR = 'UNKNOWN_ERROR' as const;

/**
 * Error types reported in the Issue View.
 */
export type IssueError =
	| typeof AUTHENTICATION_ERROR
	| typeof CONNECTIVITY_ERROR
	| typeof NOT_FOUND_OR_NO_PERMISSION_ERROR
	| typeof UNKNOWN_ERROR;

/**
 * Error attributes reported on SLO failure events
 */
export type IssueErrorAttributes = {
	traceId?: string;
	errorMessage?: string;
	endpoint?: string;
	statusCode?: number;
};

/**
 * Callback type for handling Issue View errors
 */
export type OnErrorCallback = (arg1: IssueError) => void;
