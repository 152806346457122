/**
 * @generated SignedSource<<50aa70a6efbfeaaecd5b7acc02d927c1>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type labels_issueFieldLabelsInlineEditViewFull_LabelsInlineEditViewWithIsEditable_fragmentRef$data = {
  readonly fieldId: string;
  readonly id: string;
  readonly name: string;
  readonly selectedLabelsConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly name: string | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
    readonly totalCount: number | null | undefined;
  };
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"labels_issueFieldLabelsEditviewFull_LabelsEditView_nodeIdFragment" | "labels_issueFieldLabelsReadviewFull_LabelsReadView">;
  readonly " $fragmentType": "labels_issueFieldLabelsInlineEditViewFull_LabelsInlineEditViewWithIsEditable_fragmentRef";
};
export type labels_issueFieldLabelsInlineEditViewFull_LabelsInlineEditViewWithIsEditable_fragmentRef$key = {
  readonly " $data"?: labels_issueFieldLabelsInlineEditViewFull_LabelsInlineEditViewWithIsEditable_fragmentRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"labels_issueFieldLabelsInlineEditViewFull_LabelsInlineEditViewWithIsEditable_fragmentRef">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "ScalarField",
  "name": "name"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "labels_issueFieldLabelsInlineEditViewFull_LabelsInlineEditViewWithIsEditable_fragmentRef",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "labels_issueFieldLabelsReadviewFull_LabelsReadView"
    },
    {
      "kind": "FragmentSpread",
      "name": "labels_issueFieldLabelsEditviewFull_LabelsEditView_nodeIdFragment"
    },
    {
      "kind": "ScalarField",
      "name": "id"
    },
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "RequiredField",
      "field": {
        "args": [
          {
            "kind": "Literal",
            "name": "first",
            "value": 1000
          }
        ],
        "concreteType": "JiraLabelConnection",
        "kind": "LinkedField",
        "name": "selectedLabelsConnection",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "name": "totalCount"
          },
          {
            "concreteType": "JiraLabelEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "concreteType": "JiraLabel",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/)
                ]
              }
            ]
          }
        ],
        "storageKey": "selectedLabelsConnection(first:1000)"
      },
      "action": "THROW",
      "path": "selectedLabelsConnection"
    }
  ],
  "type": "JiraLabelsField"
};
})();

(node as any).hash = "eb1d78891ab7e5335abb92a0453c8f02";

export default node;
