import React, { type FC, useState } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import WarningIcon from '@atlaskit/icon/core/migration/warning';
import Popup from '@atlaskit/popup';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

import Link from '@atlassian/jira-common-analytics-v2-wrapped-components/src/link.tsx';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import {
	isAttachmentNumberApproachLimit,
	isAttachmentNumberExceedLimit,
	ATTACHMENTS_LEARN_ABOUT_LINK,
} from '@atlassian/jira-issue-attachments-limit/src/common/utils.tsx';
import messages from './messages.tsx';

type Props = {
	totalCount: number;
	defaultIsOpen?: boolean;
};

const WarningLabel = () => {
	const { formatMessage } = useIntl();
	return (
		<PopupContent>
			{formatMessage(
				fg('jira-issue-terminology-refresh-m3')
					? messages.warningLabelIssueTermRefresh
					: messages.warningLabel,
			)}
			<Link href={ATTACHMENTS_LEARN_ABOUT_LINK}>{formatMessage(messages.warningLinkLabel)}</Link>
		</PopupContent>
	);
};

export const LimitWarning: FC<Props> = ({ totalCount, defaultIsOpen = false }) => {
	const [isOpen, setIsOpen] = useState(defaultIsOpen);

	if (isAttachmentNumberApproachLimit(totalCount) && !isAttachmentNumberExceedLimit(totalCount)) {
		return (
			<Popup
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
				placement="bottom-start"
				content={WarningLabel}
				trigger={(triggerProps) => (
					<ButtonWithMargin
						{...triggerProps}
						/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
						testId="issue.views.issue-base.content.attachment.heading.limit-warning"
						spacing="none"
						appearance="subtle-link"
						onClick={() => setIsOpen(true)}
						iconBefore={
							<WarningIcon label="warning" color={token('color.icon.warning', colors.Y300)} />
						}
					/>
				)}
			/>
		);
	}
	return null;
};

export default LimitWarning;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PopupContent = styled.div({
	display: 'flex',
	flexDirection: 'column',
	maxWidth: 330,
	padding: token('space.200', '16px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonWithMargin = styled(Button)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	marginLeft: `${0.5 * gridSize}px`,
});
