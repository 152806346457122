import React from 'react';
import { ISSUE_VIEW_BACKGROUND_SCRIPT_MODULE } from '@atlassian/jira-forge-ui-constants/src/constants.tsx';
import { ForgeAnalyticsWrapper } from '../../common/ui/index.tsx';
import type { AnalyticsWrapperPropsView } from '../../common/ui/types.tsx';

export const ViewAnalyticsWrapper = (props: AnalyticsWrapperPropsView) => (
	<ForgeAnalyticsWrapper
		module={ISSUE_VIEW_BACKGROUND_SCRIPT_MODULE}
		shouldFireInitializedEvent
		{...props}
	/>
);
