import React from 'react';
import { xcss, Inline, Text } from '@atlaskit/primitives';
import { AiIcon } from '@atlassian/jira-atlassian-intelligence/src/common/ui/ai-icon/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { GenerationAnimation } from './generation-animation.tsx';
import messages from './messages.tsx';

export const GenerationSkeleton = () => {
	const { formatMessage } = useIntl();

	return (
		<Inline alignBlock="center" xcss={containerStyle}>
			<AiIcon label="" size="small" />
			<Text color="color.text.subtlest">{formatMessage(messages.generating)}</Text>
			<GenerationAnimation />
		</Inline>
	);
};

const containerStyle = xcss({
	paddingBlock: 'space.100',
	paddingInline: 'space.100',
	gap: 'space.100',
});
