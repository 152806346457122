import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';

export const getRequestTypesUrl = (baseUrl: string, issueId: IssueId): string =>
	`${baseUrl}/rest/servicedesk/1/servicedesk/request/${issueId}/request-types/`;

export const updateRequestType = async (
	baseUrl: string,
	issueId: IssueId | null | undefined,
	requestTypeId: number | null,
) => {
	if (!issueId) {
		throw new Error('Issue ID not available');
	}
	return performPostRequest(getRequestTypesUrl(baseUrl, issueId), {
		body: JSON.stringify({
			rtId: requestTypeId,
		}),
	});
};
