import type { FlagConfiguration } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	title: {
		id: 'forge-ui.common.flags.custom-field.edit-failed.open-model-error.title',
		defaultMessage: 'We were unable to load the form to edit this field. Please try again.',
		description:
			'Error message displayed when an error occurs trying to load a form that allows the user to edit their custom field.',
	},
});

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (): FlagConfiguration => ({
	type: 'error',
	title: messages.title,
	isAutoDismiss: false,
});
