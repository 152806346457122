import React, { useEffect, useRef, useState } from 'react';
import AppBase from '@atlassian/jira-app-base/src/index.tsx';
import { Provider } from '@atlassian/jira-react-redux/src/index.tsx';
import { setContext } from '../controllers/context/actions/index.tsx';
import createStore from '../controllers/index.tsx';
import ReleasesField from './summary/index.tsx';
import type { ReleasesFieldAppProps } from './types.tsx';

const ReleasesFieldApp = (props: ReleasesFieldAppProps) => {
	const isInitialMount = useRef(true);

	const { hasSoftwareAccess, ...stateProps } = props;

	const [store] = useState(() => {
		const s = createStore();
		s.dispatch(setContext(stateProps));
		return s;
	});

	useEffect(() => {
		if (isInitialMount.current) {
			isInitialMount.current = false;
		} else {
			store.dispatch(setContext(stateProps));
		}
	}, [store, stateProps]);

	return (
		<Provider store={store}>
			<AppBase>
				<ReleasesField hasSoftwareAccess={hasSoftwareAccess} />
			</AppBase>
		</Provider>
	);
};

export default ReleasesFieldApp;
