import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	changed: {
		id: 'issue-history.history-items.history-item.parent-issue-history-item.changed',
		defaultMessage: 'changed the {parent}',
		description: `Describes that a user has updated the parent of the issue.
            Part of the heading in History feed, appears after the display name of the user who made the change.`,
	},
	parent: {
		id: 'issue-history.history-items.history-item.parent-issue-history-item.parent',
		defaultMessage: 'Parent',
		description: "(noun) Value to display as label for the 'Parent' association",
	},
	parentLowercase: {
		id: 'issue-history.history-items.history-item.parent-issue-history-item.parent-lowercase',
		defaultMessage: 'parent',
		description: "(noun) Value to display as label for the 'parent' association",
	},
});
