import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/index.tsx';
import {
	tenantHasActiveProductDiscoveryLicenseSelector,
	hasActivatedConfluenceWithinThirtyDaysSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import {
	getIssueStatusCategoryIdSelector,
	getIssueSummary,
	isEpicIssueTypeSelector,
} from '@atlassian/jira-issue-view-store/src/common/state/selectors/issue-selector.tsx';
import { canLinkIssuesSelector } from '@atlassian/jira-issue-view-store/src/selectors/issue-actions-permissions-selector.tsx';
import { useSelector } from '@atlassian/jira-react-redux/src/index.tsx';
import { useIsAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-admin/index.tsx';
import { messages } from '../ui/crossflow-idea-card/messages.tsx';

const UNSTARTED_STATUS_CATEGORY_ID = 2;
export const useCrossflowIdeaCardEligible = (): [boolean, string, string] => {
	const { formatMessage } = useIntl();
	const parentIssue = useIssueKey();
	const parentName = useSelector(getIssueSummary) ?? formatMessage(messages.fallbackIdeaName);
	const isEpic = useSelector(isEpicIssueTypeSelector);
	const issueStatusCategoryId = useSelector(getIssueStatusCategoryIdSelector);
	const isAdmin = useIsAdmin();
	const tenantHasActiveJpdLicense = useSelector(tenantHasActiveProductDiscoveryLicenseSelector);
	const tenantHasActivatedConfluenceWithin30Days = useSelector(
		hasActivatedConfluenceWithinThirtyDaysSelector,
	);
	const canLinkIssues = useSelector(canLinkIssuesSelector);

	// Killswitch cannot conditionally call hooks, so no choice but to
	// let hooks be called first. They are all in-memory read operations
	if (!fg('discover_ideas_in_issue_view_killswitch')) {
		return [false, parentIssue, parentName];
	}

	const shouldShow =
		isEpic &&
		issueStatusCategoryId === UNSTARTED_STATUS_CATEGORY_ID &&
		canLinkIssues &&
		isAdmin &&
		!tenantHasActiveJpdLicense &&
		!tenantHasActivatedConfluenceWithin30Days;

	if (shouldShow) {
		expVal('discover_ideas_in_issue_view_aa_test', 'AA', false);
		return [
			expVal('discover_ideas_in_issue_view', 'isIdeaDiscoveryEnabled', false),
			parentIssue,
			parentName,
		];
	}

	return [false, parentIssue, parentName];
};
