import { useMemo } from 'react';
import get from 'lodash/get';
import { useEditField } from '@atlassian/jira-issue-field-base/src/services/edit-field-service/main.tsx';
import { useIssueFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import { SERVICE_ENTITY_CF_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { useIssueId } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { ServiceEntity } from '../../common/types.tsx';
import { useSaveAffectedServicesField } from './use-save-affected-services-field/index.tsx';

type Props = {
	issueKey: IssueKey;
	onSuccess?: (arg1: ServiceEntity[]) => void;
	onFailure?: (arg1: Error, arg2: ServiceEntity[]) => void;
};

export const useEditAffectedServicesField = ({ issueKey, onSuccess, onFailure }: Props) => {
	const [{ value: fieldConfig }] = useIssueFieldConfig(issueKey);
	const issueId = useIssueId();
	const saveField = useSaveAffectedServicesField(issueId ?? '');
	const fieldKey = useMemo(() => {
		if (fieldConfig != null) {
			const fieldId = Object.keys(fieldConfig).find(
				(fieldKeyInConfig) =>
					fieldConfig[fieldKeyInConfig].schema.custom === SERVICE_ENTITY_CF_TYPE,
			);
			return fieldId;
		}
	}, [fieldConfig]);
	const fieldType = get(fieldConfig, 'schema.type', 'array');

	const [{ value, error, loading }, { saveValue, resetError }] = useEditField<
		ServiceEntity[],
		null,
		null
	>({
		fieldKey: fieldKey ?? '',
		issueKey,
		fieldType: typeof fieldType === 'string' ? fieldType : 'array',
		initialValue: undefined,
		onSuccess,
		onFailure,
		saveField,
	});

	return [
		{ value, error, loading },
		{ saveValue, resetError },
	];
};
