import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { useEditField } from '@atlassian/jira-issue-field-base/src/services/edit-field-service/main.tsx';
import { useFieldConfig } from '@atlassian/jira-issue-field-base/src/services/field-config-service/main.tsx';
import type { TimeTrackingState } from '@atlassian/jira-issue-shared-types/src/common/types/time-tracking-type.tsx';
import type { IssueKey, IssueId } from '@atlassian/jira-shared-types/src/general.tsx';

type Props = {
	issueKey: IssueKey;
	issueId: IssueId;
	fieldKey: string;
	onSubmit?: (
		oldValue: TimeTrackingState,
		newValue: TimeTrackingState,
		meta: unknown,
		event: UIAnalyticsEvent,
	) => void;
	onSuccess?: (arg1: TimeTrackingState) => void;
	onFailure?: (arg1: Error) => void;
};

export const useTimeTrackingField = ({
	issueKey,
	fieldKey,
	onSubmit,
	onSuccess,
	onFailure,
}: Props) => {
	const [{ value: fieldConfig }] = useFieldConfig(issueKey, fieldKey);
	const fieldType = fieldConfig?.schema.system || '';

	const [{ value, error }, { saveValue, resetError }] = useEditField<TimeTrackingState, null>({
		fieldKey,
		issueKey,
		fieldType,
		initialValue: {
			originalEstimateSeconds: 0,
			timeSpentSeconds: 0,
			remainingEstimateSeconds: 0,
		},
		onSuccess,
		onFailure,
		onSubmit,
	});

	return [
		{ value, error, fieldConfig },
		{ saveValue, resetError },
	] as const;
};
