import { useRelayEnvironment } from 'react-relay';
import { JiraSiteAri } from '@atlassian/ari/jira/site';
import fetchIssueAggData from '@atlassian/jira-issue-fetch-services/src/services/issue-agg-data/index.tsx';
import fetchIssueGiraData from '@atlassian/jira-issue-fetch-services/src/services/issue-gira-data/index.tsx';
import { fetchIssueGiraNonCriticalData } from '@atlassian/jira-issue-fetch-services/src/services/issue-gira-non-critical-data/index.tsx';
import type { GraphQlResponse as GiraResponse } from '@atlassian/jira-issue-view-common-types/src/gira.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type PrefetchedResourceManager from '../prefetched-resource-manager/index.tsx';
import { useSelfClearingPrefetchedResourceManager } from '../prefetched-resource-manager/index.tsx';
import { getCacheEntry } from './utils.tsx';

/**
 * Wrapper for useSelfClearingPrefetchedResourceManager which will perform network fetches
 * initially and when the issue-key changes (in the render phase).
 *
 * Only ONE self-clearing PrefetchedResourceManager hooks should be present per-issue-view app
 * @param issueKey when this updates
 */
export const useLazyLoadingResourceManager = (issueKey: string) => {
	const cloudId = useCloudId();
	const siteAri = JiraSiteAri.create({ siteId: cloudId }).toString();
	const environment = useRelayEnvironment();

	const onIssueKeyChange = ({
		prefetchedResourceManager,
	}: {
		prefetchedResourceManager: PrefetchedResourceManager;
	}) => {
		const getCachedGiraDataPromise = () => {
			const giraPromise = getCacheEntry(issueKey);
			if (giraPromise) {
				return giraPromise;
			}

			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			return fetchIssueGiraData('', issueKey) as Promise<GiraResponse>;
		};

		const giraPromise = getCachedGiraDataPromise();

		if (fg('issue_view_critical_query_preloader_with_rerender')) {
			const giraPromiseWithDataSetter = giraPromise.then((giraData) => {
				prefetchedResourceManager.setGiraData(giraData);
				return giraData;
			});
			prefetchedResourceManager.setGiraPromise(giraPromiseWithDataSetter);
		} else {
			prefetchedResourceManager.setGiraPromise(giraPromise);
		}

		const aggPromise = fetchIssueAggData(issueKey, cloudId, siteAri, environment);
		if (fg('issue_view_critical_query_preloader_with_rerender')) {
			const aggPromiseWithDataSetter = aggPromise.then((aggData) => {
				prefetchedResourceManager.setAggData(aggData);
				return aggData;
			});
			prefetchedResourceManager.setAggPromise(aggPromiseWithDataSetter);
		} else {
			prefetchedResourceManager.setAggPromise(aggPromise);
		}

		const giraNonCriticalPromise = fetchIssueGiraNonCriticalData(issueKey);
		prefetchedResourceManager.setGiraNonCriticalPromise(giraNonCriticalPromise);
	};

	return useSelfClearingPrefetchedResourceManager(issueKey, onIssueKeyChange);
};
