import React from 'react';
import { AnalyticsSubject } from '@atlassian/jira-analytics-web-react/src/components/decorators.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import ActionIcon from './action-icon/index.tsx';
import Watchers from './view.tsx';

const WatchesViewOld = () => <Watchers ActionIcon={ActionIcon} />;

export default componentWithCondition(
	() => fg('relay-migration-issue-header-and-parent'),
	AnalyticsSubject('watchers')(ActionIcon),
	WatchesViewOld,
);
