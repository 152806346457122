import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { IssueType } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { ISSUE_TYPE } from '@atlassian/jira-issue-view-configurations/src/index.tsx';
import type { IssueId, IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';

// PROJECT_ISSUE_TYPES_REQUEST
export const PROJECT_ISSUE_TYPES_REQUEST = 'PROJECT_ISSUE_TYPES_REQUEST' as const;

export const projectIssueTypeRequest = (projectKey: string) => ({
	type: PROJECT_ISSUE_TYPES_REQUEST,
	payload: projectKey,
});

export type ProjectIssueTypeRequest = ReturnType<typeof projectIssueTypeRequest>;

// PROJECT_ISSUE_TYPES_REQUEST_SUCCESS
export const PROJECT_ISSUE_TYPES_REQUEST_SUCCESS = 'PROJECT_ISSUE_TYPES_REQUEST_SUCCESS' as const;

export const projectIssueTypeSuccess = (projectTypes: IssueType[]) => ({
	type: PROJECT_ISSUE_TYPES_REQUEST_SUCCESS,
	payload: projectTypes,
});

export type ProjectIssueTypeSuccess = ReturnType<typeof projectIssueTypeSuccess>;

// PROJECT_ISSUE_TYPES_REQUEST_FAILURE
export const PROJECT_ISSUE_TYPES_REQUEST_FAILURE = 'PROJECT_ISSUE_TYPES_REQUEST_FAILURE' as const;

export const projectIssueTypeFailure = (errorMsg: string) => ({
	type: PROJECT_ISSUE_TYPES_REQUEST_FAILURE,
	payload: errorMsg,
});

export type ProjectIssueTypeFailure = ReturnType<typeof projectIssueTypeFailure>;

// SIMPLE_CHANGE_ISSUE_TYPE_REQUEST
export const SIMPLE_CHANGE_ISSUE_TYPE_REQUEST = 'SIMPLE_CHANGE_ISSUE_TYPE_REQUEST' as const;

export const simpleChangeIssueTypeRequest = (
	issueKey: IssueKey,
	issueId: IssueId,
	currentType: IssueType,
	targetType: IssueType,
	analyticsEvent: UIAnalyticsEvent,
) => ({
	type: SIMPLE_CHANGE_ISSUE_TYPE_REQUEST,
	payload: {
		issueKey,
		issueId,
		currentType,
		targetType,
		analyticsEvent,
	},
});

export type SimpleChangeIssueTypeRequest = ReturnType<typeof simpleChangeIssueTypeRequest>;

// SIMPLE_CHANGE_ISSUE_TYPE_REQUEST_SUCCESS
export const SIMPLE_CHANGE_ISSUE_TYPE_REQUEST_SUCCESS =
	'SIMPLE_CHANGE_ISSUE_TYPE_REQUEST_SUCCESS' as const;

export const simpleChangeIssueTypeSuccess = (issueKey: string, targetTypeName: string) => ({
	type: SIMPLE_CHANGE_ISSUE_TYPE_REQUEST_SUCCESS,
	payload: {
		issueKey,
		targetTypeName,
		fieldId: ISSUE_TYPE, // passed to the Bento onChange callback
	},
});

export type SimpleChangeIssueTypeSuccess = ReturnType<typeof simpleChangeIssueTypeSuccess>;

// SIMPLE_CHANGE_ISSUE_TYPE_REQUEST_FAILURE
export const SIMPLE_CHANGE_ISSUE_TYPE_REQUEST_FAILURE =
	'SIMPLE_CHANGE_ISSUE_TYPE_REQUEST_FAILURE' as const;

export const simpleChangeIssueTypeFailure = (error: string) => ({
	type: SIMPLE_CHANGE_ISSUE_TYPE_REQUEST_FAILURE,
	payload: error,
});

export type SimpleChangeIssueTypeFailure = ReturnType<typeof simpleChangeIssueTypeFailure>;

// SIMPLE_CHANGE_ISSUE_TYPE_NOT_ALLOWED
export const SIMPLE_CHANGE_ISSUE_TYPE_NOT_ALLOWED = 'SIMPLE_CHANGE_ISSUE_TYPE_NOT_ALLOWED' as const;

export const simpleChangeIssueTypeNotAllowed = (
	issueId: IssueId,
	currentType: IssueType,
	targetType: IssueType,
) => ({
	type: SIMPLE_CHANGE_ISSUE_TYPE_NOT_ALLOWED,
	payload: {
		issueId,
		currentType,
		targetType,
	},
});

export const SHOW_ISSUE_TYPE_LIST = 'SHOW_ISSUE_TYPE_LIST' as const;

export const showIssueTypeList = (isOpen: boolean) => ({
	type: SHOW_ISSUE_TYPE_LIST,
	payload: isOpen,
});

export type ShowIssueTypeList = ReturnType<typeof showIssueTypeList>;

export type SimpleChangeIssueTypeNotAllowed = ReturnType<typeof simpleChangeIssueTypeNotAllowed>;

export type ChangeIssueTypeActions =
	| ProjectIssueTypeRequest
	| ProjectIssueTypeSuccess
	| ProjectIssueTypeFailure
	| SimpleChangeIssueTypeRequest
	| SimpleChangeIssueTypeSuccess
	| SimpleChangeIssueTypeFailure
	| SimpleChangeIssueTypeNotAllowed
	| ShowIssueTypeList;
