export const noWorklogPermissions = {
	canDeleteAllWorklogs: false,
	canDeleteOwnWorklogs: false,
	canEditAllWorklogs: false,
	canEditOwnWorklogs: false,
	canLogWork: false,
} as const;

export type Permissions = {
	canAddComments: boolean;
	canAdministerJira: boolean;
	canAdministerProject: boolean;
	canArchiveIssue: boolean;
	canAssignIssues: boolean;
	canBeAssignedToIssues: boolean;
	canCloneIssue: boolean;
	canCreateAttachments: boolean;
	canCreateChildren: boolean;
	canCreateSubtask: boolean;
	canDeleteAllAttachments: boolean;
	canDeleteAllComments: boolean;
	canDeleteAllWorklogs: boolean;
	canDeleteIssue: boolean;
	canDeleteOwnAttachments: boolean;
	canDeleteOwnComments: boolean;
	canDeleteOwnWorklogs: boolean;
	canEditAllComments: boolean;
	canEditAllWorklogs: boolean;
	canEditIssues: boolean;
	canEditOwnComments: boolean;
	canEditOwnWorklogs: boolean;
	canLinkIssues: boolean;
	canLogWork: boolean;
	canManageWatchers: boolean;
	canModifyReporter: boolean;
	canMoveIssue: boolean;
	canScheduleIssues: boolean;
	canUnarchiveIssue: boolean;
	canUseServiceDeskAgentFeatures: boolean;
	canBrowseUsers: boolean;
	canViewDevTools: boolean;
	canViewWatchers: boolean;
	canExportIssue: boolean;
};

export const noPermissions: Permissions = {
	canAddComments: false,
	canAdministerJira: false,
	canAdministerProject: false,
	canArchiveIssue: false,
	canAssignIssues: false,
	canBeAssignedToIssues: false,
	canCloneIssue: false,
	canCreateAttachments: false,
	canCreateChildren: false,
	canCreateSubtask: false,
	canDeleteAllAttachments: false,
	canDeleteAllComments: false,
	canDeleteIssue: false,
	canDeleteOwnAttachments: false,
	canDeleteOwnComments: false,
	canEditAllComments: false,
	canEditIssues: false,
	canEditOwnComments: false,
	canLinkIssues: false,
	canManageWatchers: false,
	canMoveIssue: false,
	canModifyReporter: false,
	canScheduleIssues: false,
	canUnarchiveIssue: false,
	canViewWatchers: false,
	canBrowseUsers: false,
	...noWorklogPermissions,
	canUseServiceDeskAgentFeatures: false,
	canViewDevTools: false,
	canExportIssue: false,
};
