import React, { useCallback, useMemo, type RefObject } from 'react';
import { useFragment, graphql, useMutation } from 'react-relay';
import {
	convertToProjectType,
	type JiraProjectType,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { ValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import type { StatusValue } from '@atlassian/jira-issue-field-status/src/common/types.tsx';
import type { onUpdateStatusMutationProps } from '@atlassian/jira-issue-field-status/src/services/status-service/types.tsx';
import { StatusField } from '@atlassian/jira-issue-field-status/src/ui/main.tsx';
import type { Props as StatusFieldProps } from '@atlassian/jira-issue-field-status/src/ui/types.tsx';
import type { StatusDetails } from '@atlassian/jira-issue-shared-types/src/common/types/status-type.tsx';
import { useIssueViewFieldUpdateEvents } from '@atlassian/jira-issue-view-field-update-events/src/index.tsx';
import type {
	ui_issueViewLayoutStatusField_IssueViewStatusField$key,
	JiraStatusCategoryColor,
} from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutStatusField_IssueViewStatusField.graphql';
import type { ui_issueViewLayoutStatusField_IssueViewStatusField_Mutation } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutStatusField_IssueViewStatusField_Mutation.graphql';

export type AggItemValue = {
	readonly description: string | null | undefined;
	readonly id: string;
	readonly statusId: string;
	readonly name: string | null | undefined;
	readonly statusCategory:
		| {
				readonly colorName: JiraStatusCategoryColor | null | undefined;
				readonly id: string;
				readonly key: string | null | undefined;
				readonly name: string | null | undefined;
				readonly statusCategoryId: string;
		  }
		| null
		| undefined;
};

export const toComponentValueShape = (
	fieldData: AggItemValue | undefined | null,
): StatusDetails | undefined =>
	fieldData
		? {
				id: fieldData.statusId,
				name: fieldData.name ?? '',
				description: fieldData.description ?? '',
				statusCategory: {
					id: Number(fieldData.statusCategory?.statusCategoryId),
					colorName: fieldData.statusCategory?.colorName ?? '',
					key: fieldData.statusCategory?.key ?? '',
					name: fieldData.statusCategory?.name ?? '',
				},
				statusId: fieldData.statusId ?? '',
			}
		: undefined;

function transformComponentValueToAggShape(valueLegacy: StatusValue): AggItemValue {
	return {
		id: valueLegacy.id,
		statusId: valueLegacy.id,
		description: valueLegacy.description,
		name: valueLegacy.name,
		statusCategory: {
			id: valueLegacy.statusCategory.id.toString(),
			statusCategoryId: valueLegacy.statusCategory.id.toString(),
			name: valueLegacy.statusCategory.name,
			key: valueLegacy.statusCategory.key,
			colorName: undefined, // legacy field does not have color, it should be valueLegacy.statusCategory.colorName as JiraStatusCategoryColor,
		},
	};
}

export type IssueViewStatusFieldProps = {
	fragmentKey: ui_issueViewLayoutStatusField_IssueViewStatusField$key;
	statusButtonRef?: RefObject<HTMLElement>;

	// still in redux. Indicates if the issue view is in a loading state or not
	isCompletedLoading?: boolean;
	// triggers on cancel edit redux action required for shortcut to work
	onEditCancel?: () => void;
	onEditStart?: () => void;
};

export function IssueViewStatusField(props: IssueViewStatusFieldProps) {
	const [, { fieldChanged, fieldChangeFailed, fieldChangeRequested }] =
		useIssueViewFieldUpdateEvents();

	const data = useFragment<ui_issueViewLayoutStatusField_IssueViewStatusField$key>(
		graphql`
			fragment ui_issueViewLayoutStatusField_IssueViewStatusField on JiraIssue {
				projectField {
					project {
						projectType
					}
				}
				statusField {
					id
					fieldId
					type
					name
					description
					__typename

					issue {
						issueId
						key
					}

					status {
						id
						statusId
						name
						description
						statusCategory {
							id
							statusCategoryId
							key
							name
							colorName
						}
					}
				}
			}
		`,
		props.fragmentKey,
	);
	const { projectType } = data?.projectField?.project ?? {};
	const {
		issue,
		status,
		fieldId,
		type,
		__typename,
		id: uniqueFieldId,
	} = data?.statusField ?? {
		id: '',
		fieldId: '',
		type: '',
		__typename: 'JiraStatusField',
	};

	const onSubmit = useCallback(
		(value: AggItemValue | null | undefined) => {
			issue?.issueId &&
				fieldChangeRequested(issue?.issueId, fieldId, value, undefined, {
					type,
					__typename,
				});
		},
		[__typename, fieldId, type, fieldChangeRequested, issue?.issueId],
	);

	const onSubmitSucceeded = useCallback(
		(value: AggItemValue | null | undefined) => {
			issue?.issueId &&
				fieldChanged(issue?.issueId, fieldId, value, {
					type,
					__typename,
				});
		},
		[__typename, fieldId, type, fieldChanged, issue?.issueId],
	);

	const onSubmitFailed = useCallback(
		() => issue?.issueId && fieldChangeFailed(issue?.issueId, fieldId),
		[fieldId, fieldChangeFailed, issue?.issueId],
	);

	// no optimistic update for status field as legacy component handles it internally
	const [commit] = useMutation<ui_issueViewLayoutStatusField_IssueViewStatusField_Mutation>(graphql`
		mutation ui_issueViewLayoutStatusField_IssueViewStatusField_Mutation(
			$input: JiraUpdateStatusFieldInput!
		) @raw_response_type {
			jira @optIn(to: ["JiraIssueFieldMutations"]) {
				updateStatusByQuickTransition(input: $input) {
					success
					errors {
						message
					}
				}
			}
		}
	`);

	const onUpdateStatusMutation = useCallback(
		({ transitionId, newStatus }: onUpdateStatusMutationProps) => {
			return new Promise<void>((resolve, reject) => {
				const newAggValue = transformComponentValueToAggShape(newStatus);
				onSubmit(newAggValue);

				const handleError = (error: Error) => {
					onSubmitFailed();
					reject(error);
				};

				commit({
					variables: {
						input: {
							id: uniqueFieldId,
							statusTransitionId: parseInt(transitionId, 10),
						},
					},
					onCompleted(mutationData) {
						if (mutationData.jira?.updateStatusByQuickTransition?.success) {
							onSubmitSucceeded(newAggValue);
							resolve();
						} else if (mutationData.jira?.updateStatusByQuickTransition?.errors?.[0]?.message) {
							handleError(
								new ValidationError(
									mutationData.jira.updateStatusByQuickTransition.errors[0].message,
								),
							);
						} else {
							handleError(new Error('Could not update status'));
						}
					},
					onError: (error) => {
						handleError?.(error);
					},
					optimisticResponse: {
						jira: {
							updateStatusByQuickTransition: {
								success: true,
								errors: null,
							},
						},
					},
				});
			});
		},
		[commit, onSubmit, onSubmitFailed, onSubmitSucceeded, uniqueFieldId],
	);

	const value = useMemo(() => toComponentValueShape(status), [status]);

	// eslint-disable-next-line jira/ff/inline-usage
	const statusFieldProps: StatusFieldProps = {
		issueKey: issue?.key ?? '',
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		projectType: projectType ? convertToProjectType(projectType as JiraProjectType) : null,
		initialValue: value,
		isEditable: props.isCompletedLoading,
		isOptimistic: !fg('issue-view-status-transition-optimistic-update'),
		appearance: 'button',
		preFetchTransitions: true,
		registerInCommandPalette: true,
		statusButtonRef: props.statusButtonRef,
		onEditCancel: props.onEditCancel,
		onUpdateStatusMutation,
		...(fg('one_event_rules_them_all_fg') ? { onEditStart: props.onEditStart } : {}),
	};

	return <StatusField {...statusFieldProps} />;
}
