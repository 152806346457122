import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	placeholder: {
		id: 'issue-view-watchers.watchers.dropdown.add-watchers.user-picker.placeholder',
		defaultMessage: 'Add watchers',
		description:
			'Placeholder text displayed in the user picker field used to select users to be added to the watcher list.',
	},
	noUsersFound: {
		id: 'issue-view-watchers.watchers.dropdown.add-watchers.user-picker.no-users-found',
		defaultMessage: 'No users match your search',
		description: 'Text displayed in the user-picker when the user search returns empty.',
	},
});
