import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	commentReply: {
		id: 'canned-comments.ui.ai-comments.comment-reply',
		defaultMessage: 'Suggest a reply...',
		description: 'Status message displayed for a comment reply comment type',
	},
	assigneeMissing: {
		id: 'canned-comments.ui.ai-comments.assignee-missing',
		defaultMessage: 'Who is working on this...?',
		description: 'Status message displayed for an assignee missing comment type',
	},
	descriptionMissing: {
		id: 'canned-comments.ui.ai-comments.description-missing',
		defaultMessage: 'Can I get more info...?',
		description: 'Status message displayed for a description missing comment type',
	},
	statusUpdate: {
		id: 'canned-comments.ui.ai-comments.status-update',
		defaultMessage: 'Status update...',
		description: 'Status message displayed for a status update comment type',
	},
	motivation: {
		id: 'canned-comments.ui.ai-comments.motivation',
		defaultMessage: 'Thanks...',
		description: 'Status message displayed for a motivation comment type',
	},
	quickReply: {
		id: 'canned-comments.ui.ai-comments.quick-reply',
		defaultMessage: 'Agree...',
		description: 'Status message displayed for a quick reply comment type',
	},
	question: {
		id: 'canned-comments.ui.ai-comments.question',
		defaultMessage: 'Ask a question...',
		description: 'Status message displayed for a question comment type',
	},
	suggestion: {
		id: 'canned-comments.ui.ai-comments.suggestion',
		defaultMessage: 'Add a suggestion...',
		description: 'Status message displayed for a suggestion comment type',
	},
});
