import React, { useContext, type ComponentType } from 'react';
import ViewExperienceContext from '@atlassian/jira-common-experience-tracking-viewing/src/view/common/context/index.tsx';
import TrackerBase from '@atlassian/jira-common-experience-tracking-viewing/src/view/experience-tracker-consumer/tracker-base/view.tsx';
import EditExperienceContext from '@atlassian/jira-common-experience-tracking/src/editing/view/common/context/index.tsx';
import type { AdditionalEventAttributes } from '@atlassian/jira-issue-view-analytics/src/controllers/send-experience-analytics/index.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';

/**
 * TODO BENTO-8607: Add New API to extracted fields, and connect to Issue View experience reporting.
 * New API experience tracking.
 */

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { FieldExperience } from './ui';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	useEditExperienceStart,
	useEditExperienceSuccess,
	useEditExperienceFail,
	useEditExperienceImmediateSuccess,
	useEditExperienceImmediateFailure,
} from './services/edit/index.tsx';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	useTransitionExperienceStart,
	useTransitionExperienceSuccess,
	useTransitionExperienceFail,
} from './services/transition/index.tsx';

type Props = {
	issueKey: IssueKey;
	forField: string;
	failureEventAttributes?: AdditionalEventAttributes | null;
	allowList?: string[] | null;
};

type PassedDownProps = {
	issueKey: IssueKey;
	onEditFailure?: (location: string) => void;
	onRenderSuccess?: () => void;
};

type InitialProps = Props & PassedDownProps;

/**
 * @deprecated Old API Experience Tracking.
 * Will be superseded by experience tracking with @atlassian/jira-experience-tracker (BENTO-8607).
 */
export const withExperienceTracking =
	<T,>(
		WrappedComponent: Flow.AbstractComponent<PassedDownProps & T>,
	): ComponentType<InitialProps & T> =>
	(props: InitialProps) => {
		const { forField, issueKey, failureEventAttributes, allowList, ...rest } = props;
		const viewExperienceContext = useContext(ViewExperienceContext);
		const editExperienceContext = useContext(EditExperienceContext);
		const onEditExperienceFailure = editExperienceContext
			? editExperienceContext.onFailure
			: undefined;
		// If context is missing for some reason, still try to render
		if (viewExperienceContext == null) {
			return (
				// @ts-expect-error - TS2322 - Type '{ onEditFailure: ((field: string) => void) | ((location: string) => void) | undefined; onRenderSuccess?: (() => void) | undefined; issueKey: string; }' is not assignable to type 'PropsWithoutRef<PassedDownProps & T>'.
				<WrappedComponent issueKey={issueKey} onEditFailure={onEditExperienceFailure} {...rest} />
			);
		}
		return (
			<TrackerBase
				location={forField}
				experienceContext={viewExperienceContext}
				failureEventAttributes={failureEventAttributes ?? null}
				parentProvidersWhitelist={allowList ?? null}
			>
				{({ onExperienceSuccess }) => (
					// @ts-expect-error - TS2322 - Type '{ onEditFailure: ((field: string) => void) | ((location: string) => void) | undefined; onRenderSuccess: (() => void) | (() => void); issueKey: string; }' is not assignable to type 'PropsWithoutRef<PassedDownProps & T>'.
					<WrappedComponent
						onRenderSuccess={onExperienceSuccess}
						issueKey={issueKey}
						onEditFailure={onEditExperienceFailure}
						{...rest}
					/>
				)}
			</TrackerBase>
		);
	};

export default withExperienceTracking;
