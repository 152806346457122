import React, { memo } from 'react';
import { styled } from '@compiled/react';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { FieldDescription } from '@atlassian/jira-issue-field-description/src/ui/index.tsx';
import { FieldHeading } from '@atlassian/jira-issue-field-heading/src/index.tsx';
import {
	FieldHeadingTitle,
	SideBySideField,
	FieldWrapper,
} from '@atlassian/jira-issue-field-heading/src/styled.tsx';
import { FieldPin } from '@atlassian/jira-issue-field-pin/src/index.tsx';
import { useProjectField } from '@atlassian/jira-issue-field-project/src/services/index.tsx';
import IssueProjectField from '@atlassian/jira-issue-field-project/src/ui/main.tsx';
import { genericMessages } from '@atlassian/jira-issue-view-common-constants/src/context-items-messages.tsx';
import getShowPinButton from '@atlassian/jira-issue-view-common-utils/src/get-show-pin-button/index.tsx';
import type { Props } from './types.tsx';

const View = ({ fieldId: fieldKey, area, label }: Props) => {
	const { formatMessage } = useIntl();
	const issueKey = useIssueKey();
	const showPinButton = getShowPinButton(area);
	const [{ fieldConfig }] = useProjectField({ issueKey, fieldKey });
	const fieldLabel = fieldConfig?.title || label;

	return (
		<ProjectFieldWrapper>
			<FieldHeading>
				<FieldHeadingTitle>{fieldLabel}</FieldHeadingTitle>
				{issueKey !== undefined && fieldKey !== undefined && (
					<FieldDescription issueKey={issueKey} fieldKey={fieldKey} label={fieldLabel} />
				)}
				{showPinButton === true && <FieldPin fieldId={fieldKey} label={fieldLabel} />}
			</FieldHeading>
			<SideBySideField>
				<IssueProjectField
					issueKey={issueKey}
					fieldKey={fieldKey}
					noValueText={formatMessage(genericMessages.noValue)}
				/>
			</SideBySideField>
		</ProjectFieldWrapper>
	);
};

export default memo<Props>(View);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ProjectFieldWrapper = styled(FieldWrapper)({
	/* Need the bottom so the whole field is same length as others */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > div > div > div > div > div > div > div > div': {
		padding: 0,
	},
});
