import { format, formatDistanceStrict, formatISO, isValid, parseISO } from 'date-fns';
import type {
	SnippetPropertiesObject,
	SnippetPropertyValueType,
	SnippetAction,
} from '@atlassian/jira-polaris-domain-insight/src/snippet/types.tsx';

export const isConfigurePropertiesAction = (action: SnippetAction) =>
	action.type === 'ConfigurePropertiesAction';

export const validSnippetActions = (actions?: SnippetAction[]): SnippetAction[] =>
	actions?.filter(isConfigurePropertiesAction) || [];

export const getSnippetPropertyValue = (
	properties: SnippetPropertiesObject | null,
	key: string,
): SnippetPropertyValueType => {
	if (!properties) {
		return null;
	}
	const value = properties[key];
	if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
		return value.value;
	}
	return value;
};

export const getSnippetPropertyName = (
	properties: SnippetPropertiesObject,
	key: string,
): string => {
	const value = properties[key];
	if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
		return value.name;
	}
	return key;
};

export const getSnippetPropertyType = (properties: SnippetPropertiesObject, key: string) => {
	const value = properties[key];
	if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
		return value.type;
	}
	return undefined;
};

const camelcase = (value: string) => value.charAt(0).toUpperCase() + value.slice(1);

export const getSnippetPropertyNameDecoration = (name: string) => camelcase(name);

export const getSnippetPropertyValueDecoration = (
	value: SnippetPropertyValueType,
	type?: string,
) => {
	switch (type) {
		case 'timestamp': {
			if (typeof value !== 'number') {
				return value;
			}

			const t = parseISO(formatISO(value));
			if (isValid(t)) {
				return format(new Date(t), 'EEEE, MMMM d, yyyy hh:mm a');
			}
			return value;
		}
		case 'duration': {
			if (typeof value !== 'number') {
				return value;
			}
			if (isValid(value)) {
				return formatDistanceStrict(0, value);
			}

			return value;
		}
		default:
			if (typeof value === 'string') {
				return camelcase(value);
			}
			if (Array.isArray(value)) {
				return value.join(', ');
			}
			return value;
	}
};
