import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

const SentimentEditViewEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-Sentiment-edit-view" */ './ui/sentiment'),
	),
	getPreloadProps: () => ({}),
});

export default SentimentEditViewEntryPoint;
