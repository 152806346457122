import type { LiveState } from 'relay-runtime';
import { getTotalCount } from '@atlassian/jira-issue-attachments-selectors/src/index.tsx';
import type { State as AttachmentsState } from '@atlassian/jira-issue-attachments-store/src/types.tsx';
import type { CardCover } from '@atlassian/jira-issue-view-common-types/src/card-cover-type.tsx';
import type { State as IssueState } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { isMobileSelector } from '@atlassian/jira-issue-view-store-selectors/src/common/context-selectors/index.tsx';
import { isPreviewSelector } from '@atlassian/jira-issue-view-store-selectors/src/common/issue-selectors/index.tsx';
import {
	cardCoverSelector,
	isCardCoverEnabledSelector,
} from '@atlassian/jira-issue-view-store-selectors/src/selectors/index.tsx';
import { selectAttachmentsSweetState, selectIssueViewState } from './utils.tsx';

/**
 * @RelayResolver JiraIssue.cardCoverResolver : RelayResolverValue
 * @live
 *
 * Attachments tooltips
 */
export function cardCoverResolver(): LiveState<CardCover | null> {
	return selectIssueViewState((state: IssueState) =>
		isCardCoverEnabledSelector(state) ? cardCoverSelector(state) : null,
	);
}

/**
 * @RelayResolver JiraIssue.isMobileResolver : Boolean
 * @live
 *
 * If analyticsSource of the issue is mobile
 */
export function isMobileResolver(): LiveState<boolean> {
	return selectIssueViewState((state: IssueState) => isMobileSelector(state));
}

/**
 * @RelayResolver JiraIssue.isPreviewResolver : Boolean
 * @live
 *
 * If issue view is not in loading state
 */
export function isPreviewResolver(): LiveState<boolean> {
	return selectIssueViewState((state: IssueState) => isPreviewSelector(state));
}

/**
 * @RelayResolver JiraAttachmentConnection.totalCountResolver(issueKey: String!) : Int
 * @live
 * Total count of attachments for the issue
 */
export function totalCountResolver(args: { issueKey: string }): LiveState<number | undefined> {
	// TODO: You are bound to read only withing Resolver entity type scope
	// so it's easier to pass arg in to avoid complex retrival of issueKey via composition of other resolver
	//
	// const { parentId } = readFragment(
	// 	graphql`
	// 		fragment JiraIssueAttachementsTotalCountFragment on JiraAttachmentConnection {
	// 			edges {
	// 				node {
	// 					parentId
	// 				}
	// 			}
	// 		}
	// 	`,
	// 	ref,
	// );

	return selectAttachmentsSweetState((state: AttachmentsState) =>
		getTotalCount(state, args.issueKey),
	);
}
