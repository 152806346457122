import React, { useCallback } from 'react';
import Tabs, { Tab, TabList, TabPanel, type TabsProps } from '@atlaskit/tabs';
import Tooltip from '@atlaskit/tooltip';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import { ConditionallyVisibleDiv } from '@atlassian/jira-issue-adjustments/src/ui.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useProjectKey } from '@atlassian/jira-project-context-service/src/main.tsx';
import { useTabsLocalStorage } from '../../services/local-storage/index.tsx';

type TabData = {
	id: string;
	label: string;
	content: JSX.Element;
	// make non-optional when cleaning FF 'orge-ui-modifications-screentabs_qoa33'
	isVisible?: boolean;
};

// remove type when cleaning FF 'orge-ui-modifications-screentabs_qoa33'
type TabArray = TabData | null;

export const TAB_TEST_ID = 'issue-view-layout-templates-tab';
const logInfo = (msg: string) =>
	log.safeInfoWithoutCustomerData(
		'issue.issue-view-layout-templates.components.tab-view.static-tabs',
		msg,
	);

type StaticPropOld = {
	tabsContent: TabArray[];
};

/**
 * Tab layout items come from /gira, but hydrated Tab data comes from /graphql which is not available to SSR.
 * If we don't have the /graphql data, we short-circuit the rendering of the tabs in order to provide our
 * own custom skeleton components. Note this means we also can't do any smarts around empty tabs, so
 * we have to assume that all tabs are non-empty, but this will only create jank in very small edge cases.
 */

export const StaticTabsOld = ({ tabsContent }: StaticPropOld) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);
	const [selectedTabId, setSelectedTabId] = useTabsLocalStorage(projectKey);

	const onSelect = useCallback(
		(selected: TabData, selectedIndex: number) => {
			const payload = {
				totalTabCount: tabsContent.length,
				selectedTabPosition: selectedIndex + 1,
			};
			const event = createAnalyticsEvent({
				action: 'selected',
				actionSubject: 'tab',
				actionSubjectId: 'issueFieldTabs',
			});
			logInfo('StaticTabsOld - Selected Issue Field Tabs');
			fireUIAnalytics(event, 'issueFieldTabs', payload);
			setSelectedTabId(selected.id);
		},
		[createAnalyticsEvent, setSelectedTabId, tabsContent.length],
	);

	const handleChange = useCallback(
		(index: number) => {
			logInfo('StaticTabsOld - Selected Issue Field Tabs handleChange Event');
			const selectedTab = tabsContent[index];
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			onSelect(selectedTab as TabData, index);
		},
		[onSelect, tabsContent],
	);
	const selectedTabIndex = tabsContent.findIndex((tab) => tab && tab.id === selectedTabId);

	return (
		<Tabs
			selected={selectedTabIndex === -1 ? 0 : selectedTabIndex}
			onChange={handleChange}
			id="issue-view-layout-templates-tabs"
			shouldUnmountTabPanelOnChange
			testId="issue-view-layout-templates-tab-view.ui.static-tabs.tabs"
		>
			<TabList>
				{tabsContent.map(
					(tab) =>
						tab &&
						(tabsContent.length > 2 ? (
							<Tooltip key={tab.id} content={tab.label} position="bottom">
								<Tab testId={`${TAB_TEST_ID}-${tab.id}`}>{tab.label}</Tab>
							</Tooltip>
						) : (
							<Tab testId={`${TAB_TEST_ID}-${tab.id}`} key={tab.id}>
								{tab.label}
							</Tab>
						)),
				)}
			</TabList>
			{tabsContent.map((tab, index) => (
				<TabPanel key={index}>{tab?.content}</TabPanel>
			))}
		</Tabs>
	);
};

type StaticPropsNew = {
	tabs: TabData[];
	selected?: number;
	onChange?: TabsProps['onChange'];
};

export const StaticTabsNew = ({ tabs, selected, onChange }: StaticPropsNew) => (
	<Tabs
		testId="issue-view-layout-templates-tab-view.ui.static-tabs.tabs"
		selected={selected}
		onChange={onChange}
		id="issue-view-layout-templates-tabs"
		shouldUnmountTabPanelOnChange
	>
		<TabList>
			{tabs.map((tab) => (
				<ConditionallyVisibleDiv key={tab.id} isVisible={tab.isVisible}>
					{tabs.length > 2 ? (
						<Tooltip key={tab.id} content={tab.label} position="bottom">
							<Tab testId={`${TAB_TEST_ID}-${tab.id}`}>{tab.label}</Tab>
						</Tooltip>
					) : (
						<Tab testId={`${TAB_TEST_ID}-${tab.id}`} key={tab.id}>
							{tab.label}
						</Tab>
					)}
				</ConditionallyVisibleDiv>
			))}
		</TabList>
		{tabs.map((tab) => (
			<TabPanel key={tab.id}>{tab?.content}</TabPanel>
		))}
	</Tabs>
);

export const StaticTabs = componentWithCondition(
	() => fg('forge_ui_modifications_for_screen_tabs'),
	StaticTabsNew,
	StaticTabsOld,
);
