export const ATTACHMENTS_PER_ISSUE_LIMIT_EXCEEDED_ERROR_MESSAGE =
	'ATTACHMENTS_PER_ISSUE_LIMIT_EXCEEDED: 2000';
export const COMMENTS_PER_ISSUE_LIMIT_EXCEEDED_ERROR_MESSAGE =
	'COMMENTS_PER_ISSUE_LIMIT_EXCEEDED: 5000';

export const WORKLOGS_PER_ISSUE_LIMIT_EXCEEDED_ERROR_MESSAGE =
	'WORKLOGS_PER_ISSUE_LIMIT_EXCEEDED: 10000';

export const REMOTE_ISSUE_LINKS_PER_ISSUE_LIMIT_EXCEEDED_ERROR_MESSAGE =
	'REMOTE_ISSUE_LINKS_PER_ISSUE_LIMIT_EXCEEDED: 2000';

export const ISSUE_LIMIT_FIELDS_ERROR_MESSAGE_LIST = [
	ATTACHMENTS_PER_ISSUE_LIMIT_EXCEEDED_ERROR_MESSAGE,
	COMMENTS_PER_ISSUE_LIMIT_EXCEEDED_ERROR_MESSAGE,
	WORKLOGS_PER_ISSUE_LIMIT_EXCEEDED_ERROR_MESSAGE,
	REMOTE_ISSUE_LINKS_PER_ISSUE_LIMIT_EXCEEDED_ERROR_MESSAGE,
] as const;
