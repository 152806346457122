import React from 'react';
import type { CustomGlyphProps } from '@atlaskit/icon';

export const shortTextGlyph = (props: CustomGlyphProps) => (
	<svg
		{...props}
		width="16"
		height="16"
		viewBox="4 4 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.90017 6.94518L4.02337 15.4183C4.0008 15.4851 3.99446 15.5563 4.00486 15.626C4.01526 15.6958 4.04211 15.762 4.08318 15.8193C4.12425 15.8766 4.17837 15.9234 4.24106 15.9556C4.30376 15.9879 4.37323 16.0048 4.44373 16.0049H5.3609C5.50353 16.0049 5.6424 15.9591 5.7571 15.8743C5.8718 15.7895 5.95628 15.6702 5.99812 15.5338L6.57401 13.6613H9.89518L10.4702 15.5338C10.512 15.6702 10.5965 15.7895 10.7112 15.8743C10.8259 15.9591 10.9648 16.0049 11.1074 16.0049H12.0966C12.1671 16.0048 12.2365 15.9879 12.2992 15.9556C12.3619 15.9234 12.416 15.8766 12.4571 15.8193C12.4982 15.762 12.525 15.6958 12.5354 15.626C12.5458 15.5563 12.5395 15.4851 12.5169 15.4183L9.63478 6.94429C9.57486 6.7687 9.46148 6.61627 9.31055 6.50837C9.15961 6.40047 8.9787 6.34251 8.79316 6.34262H7.7418C7.55615 6.34257 7.37516 6.40066 7.22421 6.50873C7.07326 6.6168 6.95995 6.76942 6.90017 6.94518ZM8.21193 8.19117H8.25726L9.46948 12.1487H6.99971L8.21193 8.19117ZM18.0883 10.0068V9.71623C18.0883 9.48052 18.182 9.25447 18.3487 9.0878C18.5153 8.92113 18.7414 8.8275 18.9771 8.8275H19.1113C19.347 8.8275 19.573 8.92113 19.7397 9.0878C19.9064 9.25447 20 9.48052 20 9.71623V15.1179C20 15.3536 19.9064 15.5797 19.7397 15.7463C19.573 15.913 19.347 16.0066 19.1113 16.0066H18.9771C18.7414 16.0066 18.5153 15.913 18.3487 15.7463C18.182 15.5797 18.0883 15.3536 18.0883 15.1179V14.8406H18.0101C17.6618 15.6174 16.9152 16.1195 15.8612 16.1195C14.106 16.1195 12.9986 14.686 12.9986 12.4028C12.9986 10.1401 14.1131 8.71463 15.8612 8.71463C16.9028 8.71463 17.6618 9.24342 18.0101 10.0068H18.0883V10.0068ZM18.0484 12.4171C18.0484 11.1106 17.4396 10.2806 16.4895 10.2806C15.5404 10.2806 14.9565 11.0982 14.9565 12.4171C14.9565 13.7422 15.5404 14.5456 16.4895 14.5456C17.4458 14.5456 18.0484 13.7359 18.0484 12.4171Z"
			fill="currentColor"
		/>
	</svg>
);
