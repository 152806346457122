import without from 'lodash/without';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import type { ViewIdentifiers } from '@atlassian/jira-polaris-component-view-id-mapping/src/types.tsx';
import type { ViewUUID } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { getViewLastSeen } from '../../../../services/jpd-views-service/fetch-view-last-seen/index.tsx';
import { fetchViewLastViewed } from '../../../../services/polaris-api/fetch-view-last-viewed/index.tsx';
import type { State } from '../../types.tsx';

type ViewLastSeenConfig = {
	apolloClient: PolarisApolloClient;
	currentUser: AccountId | undefined | null;
};

const handleErrorState =
	(viewUUID: ViewUUID, { getState, setState }: StoreActionApi<State>) =>
	(error: Error) => {
		setState({
			lastSeen: {
				...getState().lastSeen,
				[viewUUID]: {
					loading: false,
					data: {},
					error,
				},
			},
		});
	};

const setViewsLastSeenState =
	(viewUUID: ViewUUID, { getState, setState }: StoreActionApi<State>) =>
	(data: Record<AccountId, Date> | Error) => {
		if (data instanceof Error) {
			throw data;
		}
		setState({
			lastSeen: {
				...getState().lastSeen,
				[viewUUID]: {
					loading: false,
					data,
				},
			},
		});
	};

const loadFromPolarisApi = (viewIds: ViewIdentifiers, { apolloClient }: ViewLastSeenConfig) =>
	fetchViewLastViewed(apolloClient, viewIds.viewAri).then((raw) => {
		const data: Record<AccountId, Date> = {};
		raw.lastViewed.forEach((lv) => {
			data[lv.aaid] = new Date(lv.timestamp);
		});
		return data;
	});

const loadFromViewsService = (viewIds: ViewIdentifiers) => {
	const loadAllPages = (nextPageKey?: string): Promise<Record<AccountId, Date>> =>
		getViewLastSeen(viewIds.viewUUID, 100, nextPageKey).then(async (raw) => {
			const data: Record<AccountId, Date> = {};
			raw.data.forEach((lv) => {
				data[lv.aaid] = new Date(lv.timestamp);
			});

			const additionalPages = raw.pagination?.nextPageKey
				? await loadAllPages(raw.pagination.nextPageKey)
				: {};

			return {
				...data,
				...additionalPages,
			};
		});

	return loadAllPages();
};

const consistencyCheck = async (
	viewUUID: ViewUUID,
	currentUser: AccountId | undefined | null,
	aPromise: Promise<Record<AccountId, Date>>,
	bPromise: Promise<Record<AccountId, Date>>,
) => {
	const a = await aPromise.catch((error: Error) => error);
	const b = await bPromise.catch((error: Error) => error);

	const filterErrorAndCurrentUser = (data: Record<AccountId, Date> | Error) => {
		if (a instanceof Error) {
			return [];
		}
		if (currentUser === null || currentUser === undefined) {
			return Object.keys(data);
		}
		return without(Object.keys(data), currentUser);
	};

	// get list of keys that are not present in both collections
	const aKeys = filterErrorAndCurrentUser(a);
	const bKeys = filterErrorAndCurrentUser(b);

	const missingKeysA = aKeys.filter((key) => !bKeys.includes(key));
	const missingKeysB = bKeys.filter((key) => !aKeys.includes(key));

	const timeWithoutMsPrecision = (date: Date | undefined) => {
		if (date === undefined) {
			return undefined;
		}
		return date.getTime() - (date.getTime() % 1000);
	};

	const mismatchedTimestamps = aKeys.filter((key) => {
		return (
			(a instanceof Error ? undefined : timeWithoutMsPrecision(a[key])) !==
			(b instanceof Error ? undefined : timeWithoutMsPrecision(b[key]))
		);
	});

	log.safeInfoWithoutCustomerData(
		'polaris.components.view-visitors.last-seen.actions.load-last-seen',
		'consistencyCheck',
		{
			passed:
				!(a instanceof Error) &&
				!(b instanceof Error) &&
				missingKeysA.length === 0 &&
				missingKeysB.length === 0 &&
				mismatchedTimestamps.length === 0,
			countA: aKeys.length,
			countB: bKeys.length,
			missingKeysA: missingKeysA.length,
			missingKeysB: missingKeysB.length,
			mismatchedTimestamps: mismatchedTimestamps.length,
			errorA: a instanceof Error ? a.message : undefined,
			errorB: b instanceof Error ? b.message : undefined,
			viewUUID,
		},
	);

	return {
		a,
		b,
	};
};

export const loadViewsLastSeen =
	(viewIds: ViewIdentifiers | undefined, config: ViewLastSeenConfig) =>
	async (api: StoreActionApi<State>) => {
		const { setState, getState } = api;

		if (!viewIds) {
			return;
		}

		if (
			getState().lastSeen[viewIds.viewUUID]?.loading ||
			getState().lastSeen[viewIds.viewUUID]?.data
		) {
			return;
		}

		setState({
			lastSeen: {
				...getState().lastSeen,
				[viewIds.viewUUID]: { loading: true, data: {} },
			},
		});

		switch (expVal('jpd_visitor_handling_architecture_north_star', 'read', 'OLD')) {
			case 'NEW':
				loadFromViewsService(viewIds)
					.then(setViewsLastSeenState(viewIds.viewUUID, api))
					.catch(handleErrorState(viewIds.viewUUID, api));
				break;
			case 'CHECK_USE_NEW':
				consistencyCheck(
					viewIds.viewUUID,
					config.currentUser,
					loadFromPolarisApi(viewIds, config),
					loadFromViewsService(viewIds),
				)
					.then(({ b }) => b)
					.then(setViewsLastSeenState(viewIds.viewUUID, api))
					.catch(handleErrorState(viewIds.viewUUID, api));
				break;
			case 'CHECK_USE_OLD':
				consistencyCheck(
					viewIds.viewUUID,
					config.currentUser,
					loadFromPolarisApi(viewIds, config),
					loadFromViewsService(viewIds),
				)
					.then(({ a }) => a)
					.then(setViewsLastSeenState(viewIds.viewUUID, api))
					.catch(handleErrorState(viewIds.viewUUID, api));
				break;
			case 'OLD':
			default:
				loadFromPolarisApi(viewIds, config)
					.then(setViewsLastSeenState(viewIds.viewUUID, api))
					.catch(handleErrorState(viewIds.viewUUID, api));
		}
	};
