import type { MiddlewareAPI } from 'redux';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/ignoreElements';
import type { ActionsObservable } from 'redux-observable';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { State, Action } from '../../types.tsx';
import actionAnalyticsMap from './utils.tsx';

// @ts-expect-error - TS7031 - Binding element 'type' implicitly has an 'any' type. | TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{ FETCH_DEV_PANEL_SUCCESS: (state: State, action: { analyticsEvent: UIAnalyticsEventInterface; }) => { eventNameSuffix: string; eventData: { issueId: string; issueStatusCategory: string; projectId: string; ... 4 more ...; isEmptyStateEnabled: boolean; }; analyticsEvent: UIAnalyticsEventInterface; action: string; act...'.
const shouldActionTriggerAnalytics = ({ type }) => actionAnalyticsMap[type] !== undefined;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getAnalyticsForAction = (state: State, action: any) =>
	// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{ FETCH_DEV_PANEL_SUCCESS: (state: State, action: { analyticsEvent: UIAnalyticsEventInterface; }) => { eventNameSuffix: string; eventData: { issueId: string; issueStatusCategory: string; projectId: string; ... 4 more ...; isEmptyStateEnabled: boolean; }; analyticsEvent: UIAnalyticsEventInterface; action: string; act...'.
	actionAnalyticsMap[action.type](state, action);

// @ts-expect-error - TS7031 - Binding element 'eventData' implicitly has an 'any' type. | TS7031 - Binding element 'analyticsEvent' implicitly has an 'any' type. | TS7031 - Binding element 'action' implicitly has an 'any' type. | TS7031 - Binding element 'actionSubjectId' implicitly has an 'any' type.
const triggerAnalyticsForAction = ({ eventData, analyticsEvent, action, actionSubjectId }) => {
	if (analyticsEvent) {
		fireUIAnalytics(analyticsEvent, action, actionSubjectId, {
			...eventData,
		});
	}
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<Action>, store: MiddlewareAPI<State>) =>
	action$
		.filter(shouldActionTriggerAnalytics)
		.map((action) => getAnalyticsForAction(store.getState(), action))
		.do(triggerAnalyticsForAction)
		.ignoreElements();
