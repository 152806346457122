import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'development-summary-commit.title',
		defaultMessage: '{boldStart}{count}{boldEnd} {count, plural, one {commit} other {commits}}',
		description:
			"The number of commits associated with a Jira issue. 'Commit' is a technical term from the tool Git that is most often not translated. It is acceptable to transliterate this term to another alphabet/script.",
	},
	emptyState: {
		id: 'development-summary-commit.empty-state',
		defaultMessage: '{Icon} Commit',
		description:
			"Placeholder for the number of commits associated with a Jira Issue. 'Commit' is a technical term from the tool Git that is most often not translated. It is acceptable to transliterate this term to another alphabet/script.",
	},
	emptyStateUpdate: {
		id: 'development-summary-commit.empty-state-update',
		defaultMessage: 'Commit',
		description:
			"Placeholder for the number of commits associated with a Jira Issue. 'Commit' is a technical term from the tool Git that is most often not translated. It is acceptable to transliterate this term to another alphabet/script.",
	},
	createCommitTitleLowercase: {
		id: 'development-summary-commit.create-commit-title-lowercase',
		defaultMessage: 'Create commit',
		description:
			"The tool tip message indicating that clicking the link will result in an action to create a commit. 'Commit' is a technical term from the tool Git that is most often not translated. It is acceptable to transliterate this term to another alphabet/script.",
	},

	createCommitDropdownIconLabel: {
		id: 'development-summary-commit.create-commit-dropdown-icon-label',
		defaultMessage: 'Open create commit dropdown',
		description:
			"The alt text describing the icon on a drop down. 'Commit' is a technical term from the tool Git that is most often not translated. It is acceptable to transliterate this term to another alphabet/script.",
	},
	tooltip: {
		id: 'development-summary-commit.tooltip',
		defaultMessage: 'Open commit',
		description:
			"The tool tip message indicating that clicking the link will lead to external link of a commit. 'Commit' is a technical term from the tool Git that is most often not translated. It is acceptable to transliterate this term to another alphabet/script.",
	},
});
