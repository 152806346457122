import React from 'react';
import { styled } from '@compiled/react';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';

export const LoadingSpinner = () => (
	<Wrapper>
		<Spinner interactionName="issue.fields.status.options.loading" />
	</Wrapper>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	padding: token('space.100', '8px'),
	textAlign: 'center',
});
