import React, { useCallback } from 'react';
import { useFragment, graphql } from 'react-relay';
import { Box, xcss } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { useIssueId } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { StoryPointEstimateInlineEditView } from '@atlassian/jira-issue-field-story-point-estimate-inline-edit-full/src/ui/story-point-estimate/index.tsx';
import type { NumberValue } from '@atlassian/jira-issue-field-story-point-estimate-inline-edit-full/src/ui/story-point-estimate/types.tsx';
import type { Area } from '@atlassian/jira-issue-view-common-types/src/connect-field-type.tsx';
import { useIssueViewFieldUpdateEvents } from '@atlassian/jira-issue-view-field-update-events/src/services/issue-view-field-update-events/index.tsx';
import { IssueViewFieldHeading } from '@atlassian/jira-issue-view-layout-field-heading/src/ui/index.tsx';
import type { ui_issueViewLayoutStoryPointEstimateField_IssueViewStoryPointEstimateField$key } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutStoryPointEstimateField_IssueViewStoryPointEstimateField.graphql';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';

export interface IssueViewStoryPointEstimateFieldProps {
	area?: Area;
	fragmentKey: ui_issueViewLayoutStoryPointEstimateField_IssueViewStoryPointEstimateField$key;
}

export const IssueViewStoryPointEstimateField = ({
	area,
	fragmentKey,
}: IssueViewStoryPointEstimateFieldProps) => {
	const issueId = useIssueId();
	const [, { fieldChanged, fieldChangeFailed, fieldChangeRequested }] =
		useIssueViewFieldUpdateEvents();

	const data =
		useFragment<ui_issueViewLayoutStoryPointEstimateField_IssueViewStoryPointEstimateField$key>(
			graphql`
				fragment ui_issueViewLayoutStoryPointEstimateField_IssueViewStoryPointEstimateField on JiraNumberField {
					...ui_issueViewLayoutFieldHeading_IssueViewFieldHeading
					...storyPointEstimate_issueFieldStoryPointEstimateInlineEditFull_StoryPointEstimateInlineEditView_fragmentRef
					fieldId
					type
					__typename
				}
			`,
			fragmentKey,
		);

	const onSubmit = useCallback(
		(value: NumberValue) => {
			issueId &&
				fieldChangeRequested(issueId, data.fieldId, value, undefined, {
					type: data.type,
					__typename: data.__typename,
				});
		},
		[data.__typename, data.fieldId, data.type, fieldChangeRequested, issueId],
	);

	const onSubmitSucceeded = useCallback(
		(value: NumberValue) => {
			issueId &&
				fieldChanged(issueId, data.fieldId, value, {
					type: data.type,
					__typename: data.__typename,
				});
		},
		[data.__typename, data.fieldId, data.type, fieldChanged, issueId],
	);

	const onSubmitFailed = useCallback(
		() => issueId && fieldChangeFailed(issueId, data.fieldId),
		[data.fieldId, fieldChangeFailed, issueId],
	);
	return (
		<UFOSegment name="issue-field-story-point-estimate">
			<Box testId={`issue.views.issue-base.context.story-point-estimate.${data.fieldId}`}>
				<IssueViewFieldHeading
					area={area}
					fragmentKey={data}
					testId={`issue.issue-view-layout.story-point-estimate-field.${data.fieldId}`}
				>
					<Box
						xcss={
							fg('issue_view_field_config_edit') ? fieldWrapperStylesNew : fieldWrapperStylesOld
						} // eslint-disable-next-line jira/integration/test-id-by-folder-structure
						testId="issue-field-number.ui.issue-field-story-point-estimate--container"
					>
						<StoryPointEstimateInlineEditView
							fragmentRef={data}
							onSubmit={onSubmit}
							onSubmitSucceeded={onSubmitSucceeded}
							onSubmitFailed={onSubmitFailed}
						/>
					</Box>
				</IssueViewFieldHeading>
			</Box>
		</UFOSegment>
	);
};

const fieldWrapperStylesOld = xcss({
	marginLeft: 'space.negative.100',
	marginRight: 'space.100',
});

const fieldWrapperStylesNew = xcss({
	marginLeft: 'space.negative.050',
	marginRight: 'space.100',
});
