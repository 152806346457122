import type { Actions } from '../../../common/types.tsx';
/**
 *
 * @deprecated since we should not be using this in the log run
 * we should not call this method any where and should clean this up as soon as GIC-JIS migration is done
 * @param issueId
 * @returns
 */
const setFetchedIssueId: Actions['setFetchedIssueId'] =
	(issueId: string) =>
	({ setState, getState }) => {
		const currentState = getState();
		const { fetchedIssueId } = currentState;
		if (issueId !== fetchedIssueId) {
			setState({ ...currentState, fetchedIssueId: issueId });
		}
	};

export default setFetchedIssueId;
