import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { BaseUrl, IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { GraphqlResponse, ViewWorkflowData } from './types.tsx';

const query = `
query classicViewWorkflowQuery($issueKey: String!) {
    viewIssue(issueKey: $issueKey) {
        workflow {
            name
            step {
                id
            }
        }
    }
}
`;

export const transformResponse = (response: GraphqlResponse): ViewWorkflowData => {
	if (response.data && response.data.viewIssue && response.data.viewIssue.workflow) {
		return {
			workflowName: response.data.viewIssue.workflow.name,
			currentStepId: response.data.viewIssue.workflow.step.id,
		};
	}

	throw new Error('TransformError');
};

export const getViewWorkflowData = (_baseUrl: BaseUrl, issueKey: IssueKey) =>
	performPostRequest('/rest/gira/1/', {
		body: JSON.stringify({
			query,
			variables: { issueKey },
		}),
	}).then(transformResponse);
