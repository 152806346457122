import type { ReactElement } from 'react';
import { ADD_MODAL } from '@atlassian/jira-issue-view-common-types/src/worklog-type.tsx';
// eslint-disable-next-line camelcase
import { withStoreForIssueViewShortcutActions_DO_NOT_USE } from '@atlassian/jira-issue-view-common-views/src/store-provider/index.tsx';
import timeMessages from '@atlassian/jira-issue-view-common/src/messages/time.tsx';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import { openModal } from '@atlassian/jira-issue-view-store/src/common/actions/worklog-actions.tsx';
import LogWorkButtonItemView from './button-item.tsx';
import { LogWorkView } from './main.tsx';
import type { OwnProps, Props, DispatchProps } from './types.tsx';

const connectToProps = (Component: (arg1: Props) => ReactElement) =>
	connect(
		null,
		(dispatch): DispatchProps => ({
			onLogTimeClick: () => {
				dispatch(openModal(ADD_MODAL, null));
			},
		}),
	)(Component);

export const LogWork = withStoreForIssueViewShortcutActions_DO_NOT_USE<OwnProps>(
	// @ts-expect-error - TS2345 - Argument of type 'ComponentClass<Omit<ShortcutComponentProps & DispatchProps, "onLogTimeClick">> & { ...; }' is not assignable to parameter of type 'ComponentType<ShortcutComponentProps>'.
	connectToProps(LogWorkView),
);

export const label = timeMessages.logWork;

export const LogWorkButtonItem = withStoreForIssueViewShortcutActions_DO_NOT_USE<OwnProps>(
	// @ts-expect-error - TS2345 - Argument of type 'ComponentClass<Omit<ShortcutComponentProps & DispatchProps, "onLogTimeClick">> & { ...; }' is not assignable to parameter of type 'ComponentType<ShortcutComponentProps>'.
	connectToProps(LogWorkButtonItemView),
);
