/**
 * @generated SignedSource<<33badac67969900ef759035782f74b4b>>
 * @relayHash c974cc209303bcbe1bf0362b48c0a5cb
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 086e44049f5cee9f7214b67b2b875b49e7797f9a46426a3ce38d374105b2e280

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { ui_AddDesignButtonQuery } from './ui_AddDesignButtonQuery.graphql';

const node: PreloadableConcreteRequest<ui_AddDesignButtonQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "086e44049f5cee9f7214b67b2b875b49e7797f9a46426a3ce38d374105b2e280",
    "metadata": {},
    "name": "ui_AddDesignButtonQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
