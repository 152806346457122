import React, { useRef, useState, type Ref } from 'react';
import noop from 'lodash/noop';
import { graphql, useFragment } from 'react-relay';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { DEFAULT_PARENT_ISSUE_TYPE_NAME } from '@atlassian/jira-issue-view-common-constants/src/issue-types.tsx';
import type { addParent_issueViewFoundation_BreadcrumbAddParent$key } from '@atlassian/jira-relay/src/__generated__/addParent_issueViewFoundation_BreadcrumbAddParent.graphql';
import IssueParentSwitcher from '../../issue-parent-switcher/index.tsx';
import { truncateBreadcrumbs } from '../common/utils.tsx';
import messages from '../messages.tsx';
import { IssueContainer, SmallBreadcrumbsItem } from '../styled.tsx';

type ExternalProps = {
	isSimplifiedProject?: boolean;
	issueTypeName?: string;
	issueHierarchyLevel?: number | null;
	triggerRef?: Ref<HTMLElement>;
	onAddOrRemoveParent?: () => void;
	issue?: addParent_issueViewFoundation_BreadcrumbAddParent$key; // TODO remove optional on relay-migration-issue-header-and-parent cleanup
};

export const BreadcrumbAddParent = ({
	isSimplifiedProject = false,
	issueTypeName = DEFAULT_PARENT_ISSUE_TYPE_NAME,
	issueHierarchyLevel,
	triggerRef = { current: null },
	onAddOrRemoveParent = noop,
	issue,
}: ExternalProps) => {
	const data =
		issue && fg('relay-migration-issue-header-and-parent')
			? // eslint-disable-next-line @atlassian/relay/query-restriction, react-hooks/rules-of-hooks
				useFragment<addParent_issueViewFoundation_BreadcrumbAddParent$key>(
					graphql`
						fragment addParent_issueViewFoundation_BreadcrumbAddParent on JiraIssue {
							...issueParentSwitcher_issueViewFoundation_IssueParentSwitcherWithRelay
						}
					`,
					issue,
				)
			: undefined;
	const [isOpen, setIsOpen] = useState(false);
	const { formatMessage } = useIntl();
	const breadcrumbsItemRef = useRef<HTMLElement>();

	const target =
		issueTypeName && issueTypeName !== DEFAULT_PARENT_ISSUE_TYPE_NAME
			? issueTypeName
			: DEFAULT_PARENT_ISSUE_TYPE_NAME.toLowerCase();

	/**
	 * - For orphan issues in TMP projects, we display "Add <issueTypeName>"
	 * - For orphan issues in CMP projects, we display "Add parent"
	 *
	 * See https://hello.jira.atlassian.cloud/browse/JPO-25790 for future reference
	 *
	 * @returns MessageDescriptor
	 */
	const getIssueParentSwitcherLabel = () => {
		if (isSimplifiedProject) {
			return formatMessage(messages.addParent, { target });
		}
		return formatMessage(messages.addParentLabel);
	};

	return (
		<IssueContainer
			// eslint-disable-next-line jira/integration/test-id-by-folder-structure
			data-testid="issue.views.issue-base.foundation.breadcrumbs.add-parent.breadcrumb-add-parent"
			role="listitem"
		>
			<IssueParentSwitcher
				clickRef={breadcrumbsItemRef}
				isOpen={isOpen}
				isOrphan
				issueTypeName={issueTypeName}
				onOpenChange={(newIsOpenState: boolean) => setIsOpen(newIsOpenState)}
				text={truncateBreadcrumbs(getIssueParentSwitcherLabel())}
				issueHierarchyLevel={issueHierarchyLevel}
				onAddOrRemoveParent={onAddOrRemoveParent}
				triggerRef={triggerRef}
				issue={data}
				component={fg('relay-migration-issue-header-and-parent') ? undefined : SmallBreadcrumbsItem}
			/>
		</IssueContainer>
	);
};

export default BreadcrumbAddParent;
