import { useCallback } from 'react';
import { NULL_SPACE_OBJECT, type SpaceInfo } from '../types.tsx';
import { useProjectSpaceInfo } from '../use-project-space-info/index.tsx';
import { useUsersPersonalSpaceInfo } from '../use-users-personal-space-info/index.tsx';

export const useEmbeddedConfluenceDefaultSpace = () => {
	const { getProjectSpaceInfo } = useProjectSpaceInfo();
	const { getUsersPersonalSpaceInfo } = useUsersPersonalSpaceInfo();

	const getEmbeddedConfluenceDefaultSpace = useCallback(async () => {
		const isFulfilled = (
			input: PromiseSettledResult<SpaceInfo>,
		): input is PromiseFulfilledResult<SpaceInfo> => input.status === 'fulfilled';

		const promises = await Promise.allSettled<SpaceInfo>([
			getProjectSpaceInfo(),
			getUsersPersonalSpaceInfo(),
		]);

		const defaultSpace = promises
			.filter(isFulfilled)
			.map((promiseResult) => promiseResult.value)
			.find((space) => !!space.spaceId && !!space.spaceKey);

		return defaultSpace ?? NULL_SPACE_OBJECT;
	}, [getProjectSpaceInfo, getUsersPersonalSpaceInfo]);

	return { getEmbeddedConfluenceDefaultSpace };
};
