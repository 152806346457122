import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import {
	type ChildIssuesPanelType,
	type SortingOption,
	SORT_OPTION_RANKING,
	SORT_OPTION_CREATED,
	SORT_OPTION_KEY,
	SORT_OPTION_PRIORITY,
	SORT_OPTION_STATUS,
	SORT_OPTION_ASSIGNEE,
	CLASSIC_PORTFOLIO_CHILDREN,
	CLASSIC_PROJECT_EPIC_CHILDREN,
	CLASSIC_SUBTASKS,
	CHILDREN_ISSUES_PANEL,
} from '../../model/types.tsx';

export const generateSortBy = (sortByValue?: SortingOption) => {
	if (!sortByValue) {
		return encodeURIComponent(' ORDER BY rank');
	}
	switch (sortByValue) {
		case SORT_OPTION_STATUS:
			return encodeURIComponent(' ORDER BY statusCategory, status asc, rank');
		case SORT_OPTION_PRIORITY:
		case SORT_OPTION_KEY:
		case SORT_OPTION_ASSIGNEE:
		case SORT_OPTION_CREATED:
			return encodeURIComponent(` ORDER BY ${sortByValue}, rank`);
		case SORT_OPTION_RANKING:
		default:
			return encodeURIComponent(' ORDER BY rank');
	}
};

// different between parent and parent link jql is that parent refers to subtasks/next gen child issues
// parent link refers to portfolio children issues
const generateParentJQL = (parentKey: IssueKey) => `${encodeURIComponent(`parent=${parentKey}`)}`;
const generateParentLinkJQL = (parentKey: IssueKey) =>
	`${encodeURIComponent(`"Parent Link"=${parentKey}`)}`;
const generateEpicLinkJQL = (parentKey: IssueKey) =>
	`${encodeURIComponent(`"Epic Link"=${parentKey}`)}`;

const generateOverLimitJQL = (parentKey: IssueKey, sortByValue?: SortingOption) =>
	`jql=${generateParentJQL(parentKey)}${generateSortBy(sortByValue)}`;

// Non-system epics do not support Epic Link: https://hello.atlassian.net/wiki/spaces/~888416081/pages/2366083841/System+and+Non-system+epic+issue+types
// To search for their children, we have to additionally use Parent Link
// Once https://team.atlassian.com/project/ATLAS-15331 is complete, both should be replaced with the Parent JQL
// The work to replace it is tracked at https://hello.atlassian.net/browse/JPO-23454
const generateJQLForCMPParentAtHierarchyLevelOne = (
	parentKey: IssueKey,
	sortByValue?: SortingOption,
) =>
	`jql=${generateEpicLinkJQL(parentKey)} OR ${generateParentLinkJQL(parentKey)}${generateSortBy(
		sortByValue,
	)}`;

export const generateChildIssuesJQL = (
	parentKey: IssueKey,
	childIssuesPanelType: ChildIssuesPanelType,
	hasExceededIssuesLimitInitialLoad: boolean,
	sortByValue?: SortingOption,
) => {
	if (hasExceededIssuesLimitInitialLoad && childIssuesPanelType !== CHILDREN_ISSUES_PANEL) {
		return generateOverLimitJQL(parentKey, sortByValue);
	}

	switch (childIssuesPanelType) {
		case CLASSIC_PORTFOLIO_CHILDREN:
			return `jql=${generateParentLinkJQL(parentKey)}${generateSortBy(sortByValue)}`;
		case CLASSIC_PROJECT_EPIC_CHILDREN:
			return generateJQLForCMPParentAtHierarchyLevelOne(parentKey, sortByValue);
		case CLASSIC_SUBTASKS:
		case CHILDREN_ISSUES_PANEL:
		default:
			return `jql=${generateParentJQL(parentKey)}${generateSortBy(sortByValue)}`;
	}
};
