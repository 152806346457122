import {
	SUMMARY_ITEM_BRANCH,
	SUMMARY_ITEM_BUILD,
	SUMMARY_ITEM_PULLREQUEST,
	SUMMARY_ITEM_REVIEW,
	SUMMARY_ITEM_FEATURE_FLAGS,
	SUMMARY_ITEM_REMOTE_LINKS,
	SUMMARY_ITEM_DEPLOYMENT,
	SUMMARY_ITEM_COMMIT,
} from './constants.tsx';

export const toDetailDialogDataType = (itemType: string): string => {
	const needsTransformation = ![
		SUMMARY_ITEM_BRANCH,
		SUMMARY_ITEM_BUILD,
		SUMMARY_ITEM_PULLREQUEST,
		SUMMARY_ITEM_REVIEW,
		SUMMARY_ITEM_FEATURE_FLAGS,
		SUMMARY_ITEM_REMOTE_LINKS,
		// @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type '"branch" | "pullrequest" | "build" | "remote-links" | "review" | "feature-flags"'.
	].includes(itemType);

	if (!needsTransformation) {
		return itemType;
	}
	if (itemType === SUMMARY_ITEM_DEPLOYMENT || itemType === 'deploymentEnvironments') {
		return 'deployment-environment';
	}
	if (itemType === SUMMARY_ITEM_COMMIT) {
		return 'repository';
	}
	throw new Error(`Unknown item type ${itemType}`);
};
