/**
 * @generated SignedSource<<95b885d98116415e04b7330ebc0d66c7>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type date_issueFieldDateReadviewFull_DateReadView$data = {
  readonly date: AGG$Date | null | undefined;
  readonly " $fragmentType": "date_issueFieldDateReadviewFull_DateReadView";
};
export type date_issueFieldDateReadviewFull_DateReadView$key = {
  readonly " $data"?: date_issueFieldDateReadviewFull_DateReadView$data;
  readonly " $fragmentSpreads": FragmentRefs<"date_issueFieldDateReadviewFull_DateReadView">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "date_issueFieldDateReadviewFull_DateReadView",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "date"
    }
  ],
  "type": "JiraDatePickerField"
};

(node as any).hash = "d7db56777519dc6f4756e8e006032b0d";

export default node;
