import has from 'lodash/has';
import gqlTagPolaris from 'graphql-tag';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { Ari } from '@atlassian/jira-platform-ari/src/index.tsx';
import { PLAY_FRAGMENT } from '@atlassian/jira-polaris-lib-polaris-api-graphql/src/services/polaris-api/play/index.tsx';
import type { PolarisApolloClient } from '@atlassian/jira-polaris-lib-remote-context/src/controllers/providers/types.tsx';
import { fireOperationalAnalyticsDeferred } from '@atlassian/jira-product-analytics-bridge';
import type { PlaysDataQuery, PolarisPlays } from './types.tsx';

const PROJECT_CONFIG_PLAYS_QUERY = gqlTagPolaris`
  query jira_polaris_GetPlaysDataQuery($project: ID!, $skipComment: Boolean!) {
    polarisProject(id: $project, skipRefresh: true) {
      id
      plays {
        ...Play
      }
    }
  }
  ${PLAY_FRAGMENT}
`;

export const getPlaysData = async (
	client: PolarisApolloClient,
	projectAri: Ari,
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	fireAnalyticsEvent?: boolean,
): Promise<PolarisPlays> => {
	const fetchStart = new Date();

	const result = await client.query<PlaysDataQuery>({
		query: PROJECT_CONFIG_PLAYS_QUERY,
		variables: {
			project: projectAri.toString(),
			skipComment: true,
		},
		fetchPolicy: 'network-only',
		errorPolicy: 'all',
	});

	if (!has(result, 'data.polarisProject.plays')) {
		throw new Error('project-config.fetch-error: no plays data or no data node');
	}

	const plays = result.data?.polarisProject?.plays ?? [];

	if (fireAnalyticsEvent === true) {
		fireOperationalAnalyticsDeferred(createAnalyticsEvent({}), 'JpdFetchPlays succeeded', {
			projectAri,
			durationMs: new Date().getTime() - fetchStart.getTime(),
			plays: plays.length,
			plays_contributions: plays.reduce((acc, play) => acc + (play.contributions?.length || 0), 0),
		});
	}

	return plays;
};
