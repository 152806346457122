export const LINKED_ISSUES_TEMPLATE = 'linked_issues';
const LINKED_ISSUES_PROGRESS = 'progress';
const LINKED_ISSUES_STATUS = 'status';
export const STORY_POINTS = 'story_points';
export const ISSUE_COUNT = 'issue_count';

export type LinkedIssuesFormula = {
	template: typeof LINKED_ISSUES_TEMPLATE;
	parameters: {
		rollup: typeof LINKED_ISSUES_PROGRESS | typeof LINKED_ISSUES_STATUS;
		calculationMode: typeof STORY_POINTS | typeof ISSUE_COUNT;
	};
};
