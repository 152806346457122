import type { IssueError } from '@atlassian/jira-issue-shared-types/src/common/types/error-type.tsx';

// FETCH_SCREENID_REQUEST
export const FETCH_SCREENID_REQUEST = 'FETCH_SCREENID_REQUEST' as const;

export type FetchScreenIdFailureDescription = {
	error: IssueError;
	statusCode: number | null | undefined;
};

export const fetchScreenIdRequest = () => ({
	type: FETCH_SCREENID_REQUEST,
});

// FETCH_SCREENID_SUCCESS
export const FETCH_SCREENID_SUCCESS = 'FETCH_SCREENID_SUCCESS' as const;

export type ScreenIdData = {
	data: {
		issue: {
			id: string;
			viewScreenId: number;
		};
	};
};

export const fetchScreenIdSuccess = (screenIdData: ScreenIdData) => ({
	type: FETCH_SCREENID_SUCCESS,
	payload: screenIdData && screenIdData.data,
});

// FETCH_SCREENID_FAILURE
export const FETCH_SCREENID_FAILURE = 'FETCH_SCREENID_FAILURE' as const;

export const fetchScreenIdFailure = (failureDescription: FetchScreenIdFailureDescription) => ({
	type: FETCH_SCREENID_FAILURE,
	payload: failureDescription,
});

export type FetchScreenIdRequestAction = ReturnType<typeof fetchScreenIdRequest>;
export type FetchScreenIdSuccessAction = ReturnType<typeof fetchScreenIdSuccess>;
export type FetchScreenIdFailureAction = ReturnType<typeof fetchScreenIdFailure>;

export type IssueFetchAction =
	| FetchScreenIdRequestAction
	| FetchScreenIdSuccessAction
	| FetchScreenIdFailureAction;
