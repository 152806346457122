import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { DEFAULT_ORDER_DIRECTION, DEFAULT_ORDER_FIELD } from '../../common/constants.tsx';
import type {
	Attachment,
	AttachmentId,
	AttachmentsQueryOptions,
	OrderDirection,
	OrderField,
} from '../../common/types.tsx';
import {
	deleteAttachmentRequest,
	fetchAttachmentsRequest,
	initialAttachmentState,
} from '../../common/utils.tsx';
import type { StoreApi } from '../../types.tsx';

export const setIsFetching =
	(issueKey: IssueKey, isFetching: boolean) =>
	({ setState, getState }: StoreApi) => {
		const oldState = getState();
		setState({
			issueAttachments: {
				...oldState.issueAttachments,
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				[issueKey as string]: {
					...(oldState.issueAttachments[issueKey] || initialAttachmentState),
					isFetching,
				},
			},
		});
	};

export const setError =
	(issueKey: IssueKey, error: Error | null) =>
	({ setState, getState }: StoreApi) => {
		const oldState = getState();
		setState({
			issueAttachments: {
				...oldState.issueAttachments,
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				[issueKey as string]: {
					...(oldState.issueAttachments[issueKey] || initialAttachmentState),
					error,
				},
			},
		});
	};

export const setOrderDirection =
	(orderDirection: OrderDirection) =>
	({ getState, setState }: StoreApi) => {
		const { meta: currentMeta } = getState();

		setState({
			meta: {
				...currentMeta,
				orderDirection,
			},
		});
	};

export const setOrderField =
	(orderField: OrderField) =>
	({ getState, setState }: StoreApi) => {
		const { meta: currentMeta } = getState();

		setState({
			meta: {
				...currentMeta,
				orderField,
			},
		});
	};

export const setTotalCount =
	(issueKey: IssueKey, totalCount: number) =>
	({ setState, getState }: StoreApi) => {
		const oldState = getState();
		setState({
			issueAttachments: {
				...oldState.issueAttachments,
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				[issueKey as string]: {
					...(oldState.issueAttachments[issueKey] || initialAttachmentState),
					totalCount,
				},
			},
		});
	};

export const setDeletableCount =
	(issueKey: IssueKey, deletableCount: number) =>
	({ setState, getState }: StoreApi) => {
		const oldState = getState();
		setState({
			issueAttachments: {
				...oldState.issueAttachments,
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				[issueKey as string]: {
					...(oldState.issueAttachments[issueKey] || initialAttachmentState),
					deletableCount,
				},
			},
		});
	};

export const setStartAt =
	(startAt: number) =>
	({ getState, setState }: StoreApi) => {
		const { meta: currentMeta } = getState();
		setState({
			meta: {
				...currentMeta,
				startAt,
			},
		});
	};

export const setVisibleAttachments =
	(issueKey: IssueKey, visibleAttachments: Attachment[]) =>
	({ setState, getState }: StoreApi) => {
		const oldState = getState();
		setState({
			issueAttachments: {
				...oldState.issueAttachments,
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				[issueKey as string]: {
					...(oldState.issueAttachments[issueKey] || initialAttachmentState),
					visibleAttachments,
				},
			},
		});
	};

export const addNewAttachments =
	(issueKey: IssueKey, newAttachments: Attachment[]) =>
	({ setState, getState }: StoreApi) => {
		const oldState = getState();
		const oldAttachments = oldState.issueAttachments[issueKey]
			? // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
				oldState.issueAttachments[issueKey].visibleAttachments
			: [];
		const oldTotalCount = oldState.issueAttachments[issueKey]
			? // @ts-expect-error - TS2532 - Object is possibly 'undefined'.
				oldState.issueAttachments[issueKey].totalCount || 0
			: 0;
		const allAttachments = newAttachments.concat(oldAttachments);
		const totalCount = oldTotalCount + newAttachments.length;
		setState({
			issueAttachments: {
				...oldState.issueAttachments,
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				[issueKey as string]: {
					...(oldState.issueAttachments[issueKey] || initialAttachmentState),
					visibleAttachments: allAttachments,
					totalCount,
				},
			},
		});
	};

export const getAttachmentsCount =
	(issueKey: IssueKey) =>
	({ getState }: StoreApi) => {
		const currentState = getState();
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return currentState.issueAttachments[issueKey as string]?.totalCount ?? 0;
	};

export const getAttachments =
	(issueKey: IssueKey, fetchOptions?: AttachmentsQueryOptions) =>
	async ({ dispatch }: StoreApi) => {
		dispatch(setIsFetching(issueKey, true));
		dispatch(setError(issueKey, null));

		try {
			const response = await fetchAttachmentsRequest(issueKey, fetchOptions);

			if (response) {
				dispatch(setTotalCount(issueKey, response.totalCount));
				typeof response.deletableCount === 'number' &&
					dispatch(setDeletableCount(issueKey, response.deletableCount));
				dispatch(setVisibleAttachments(issueKey, response.nodes));

				// Set or reset pagination/sorting
				dispatch(setOrderDirection(fetchOptions?.orderDirection || DEFAULT_ORDER_DIRECTION));
				dispatch(setOrderField(fetchOptions?.orderField || DEFAULT_ORDER_FIELD));
				dispatch(setStartAt(fetchOptions?.startAt || 0));
			} else {
				const errorString = 'Failed to fetch issue attachments';
				dispatch(setError(issueKey, new Error(errorString)));
				log.safeErrorWithoutCustomerData('issue.attachments.fetch-attachments', errorString);
			}
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			dispatch(setError(issueKey, e));
			log.safeErrorWithoutCustomerData('issue.attachments.fetch-attachments', e.toString());
		}

		dispatch(setIsFetching(issueKey, false));
	};

export const deleteAttachment =
	(
		issueKey: IssueKey,
		deletingAttachmentId: AttachmentId,
		fetchOptions?: AttachmentsQueryOptions,
	) =>
	async ({ dispatch, getState }: StoreApi) => {
		const state = getState();
		const issueAttachments = state.issueAttachments[issueKey];

		if (!issueAttachments) {
			return;
		}

		const { totalCount, visibleAttachments } = issueAttachments;

		if (totalCount !== undefined) {
			dispatch(
				setVisibleAttachments(
					issueKey,
					visibleAttachments.filter((attachment) => attachment.id !== deletingAttachmentId),
				),
			);

			dispatch(setTotalCount(issueKey, totalCount - 1));

			try {
				await deleteAttachmentRequest(deletingAttachmentId);
				dispatch(getAttachments(issueKey, fetchOptions));
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				dispatch(getAttachments(issueKey, fetchOptions));
				throw error;
			}
		}
	};

export const refreshAttachments =
	(issueKey: IssueKey, fetchOptions?: AttachmentsQueryOptions) =>
	({ dispatch, getState }: StoreApi) => {
		const state = getState();
		const issueAttachments = state.issueAttachments[issueKey];

		if (!issueAttachments) {
			return;
		}

		const { startAt } = state.meta;
		const { isFetching } = issueAttachments;

		if (!isFetching || (fetchOptions && fetchOptions.startAt !== startAt)) {
			dispatch(getAttachments(issueKey, fetchOptions));
		}
	};
