import React from 'react';
import type { CustomGlyphProps } from '@atlaskit/icon';

export const numberGlyph = (props: CustomGlyphProps) => (
	<svg
		{...props}
		width="16"
		height="16"
		viewBox="4 4 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M17.4413 8C16.3689 8 15.5302 8.54147 15.1904 9.29529L16.3477 9.91108C16.5601 9.53948 16.8786 9.30591 17.4094 9.30591C17.9509 9.30591 18.3756 9.65627 18.3756 10.1871C18.3756 10.6649 18.0783 11.0471 17.4307 11.0471H16.783V12.2256H17.3139C18.1102 12.2256 18.5136 12.6715 18.5136 13.1918C18.5136 13.8076 18.0146 14.1685 17.4307 14.1685C16.7724 14.1685 16.3689 13.8607 16.1778 13.3404L14.925 13.8607C15.2754 14.7843 16.2415 15.4638 17.5368 15.4638C18.9489 15.4638 20 14.6569 20 13.3935C20 12.5441 19.5435 11.939 18.8427 11.6098V11.5886C19.4267 11.2488 19.7877 10.6543 19.7877 9.95355C19.7877 8.70073 18.7366 8 17.4413 8ZM7.36563 8.08494H6.20836L4 9.66689L4.69011 10.7392L5.91108 9.82614V15.3789H7.36563V8.08494ZM11.4214 8C10.2004 8 9.42535 8.60518 9.00066 9.38023L10.0411 10.1234C10.3597 9.59257 10.795 9.33776 11.3046 9.33776C11.8779 9.33776 12.2814 9.76244 12.2814 10.3251C12.2814 10.9091 11.8885 11.4081 11.3046 12.0345L9.03251 14.434L9.05375 15.3789H13.8102V14.0624H11.1453V14.0411L12.5255 12.6184C13.4705 11.6417 13.7359 10.994 13.7359 10.1659C13.7359 8.8069 12.6317 8 11.4214 8Z"
			fill="currentColor"
		/>
	</svg>
);
