import React, { useMemo } from 'react';
import { styled, css, keyframes } from '@compiled/react';
import ChevronRightLargeIcon from '@atlaskit/icon/glyph/chevron-right-large';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type { CommandInternal } from '../../../../types/commands/index.tsx';
import { hasChildren } from '../../../../utils.tsx';
import { CHEVRON_CONTAINER_TEST_ID, SHORTCUT_CONTAINER_TEST_ID } from './constants.tsx';
import { messages } from './messages.tsx';
import { Shortcuts } from './shortcuts/index.tsx';
import { TRANSITION_DURATION_MS, TRANSITION_STEP } from './use-hover-transition-step/constants.tsx';
import { useHoverTransitionStep } from './use-hover-transition-step/index.tsx';

export type RightContentProps = {
	command: CommandInternal;
	isActive: boolean;
	isFirstCommand?: boolean;
};

export const RightContent = ({ command, isActive, isFirstCommand = false }: RightContentProps) => {
	const { formatMessage } = useIntl();
	const transitionStep = useHoverTransitionStep(command, isActive, isFirstCommand);

	const commandHasChildren = useMemo(() => hasChildren(command), [command]);

	const shouldRenderRightContent = commandHasChildren || !!command.shortcut;

	if (!shouldRenderRightContent) return null;

	return (
		<RightContentWrapper>
			{commandHasChildren && (
				<CommandRightChevronContainer
					data-testid={CHEVRON_CONTAINER_TEST_ID}
					transitionStep={transitionStep}
				>
					<ChevronRightLargeIcon label={formatMessage(messages.rightChevronLabel)} />
				</CommandRightChevronContainer>
			)}
			{!!command.shortcut && (
				<ShortcutContainer
					data-testid={SHORTCUT_CONTAINER_TEST_ID}
					shouldHaveTransition={commandHasChildren}
					transitionStep={transitionStep}
				>
					<Shortcuts shortcut={command.shortcut} />
				</ShortcutContainer>
			)}
		</RightContentWrapper>
	);
};

const animationFadeIn = keyframes({
	from: {
		opacity: 0,
	},
	to: {
		opacity: 1,
	},
});

const animationFadeOut = keyframes({
	from: {
		opacity: 1,
	},
	to: {
		opacity: 0,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RightContentWrapper = styled.div({
	position: 'relative',
	display: 'grid',
	marginLeft: token('space.050', '4px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle', colors.N400),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CommandRightChevronContainer = styled.div<{
	transitionStep: TRANSITION_STEP;
}>(
	{
		gridRow: 1,
		gridColumn: 1,
		alignItems: 'center',
		display: 'flex',
		width: '24px',
		height: '24px',
		lineHeight: '24px',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		animationDuration: `${TRANSITION_DURATION_MS}ms`,
		animationTimingFunction: 'ease-in-out',
		animationFillMode: 'forwards',
		opacity: 0,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		color: token('color.icon.subtle', colors.N700),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ transitionStep }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		transitionStep === TRANSITION_STEP.TRANSITIONING && css({ animationName: animationFadeIn }),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ transitionStep }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		transitionStep === TRANSITION_STEP.TRANSITIONING_FADE_OUT &&
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		css({ animationName: animationFadeOut }),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ transitionStep }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		(transitionStep === TRANSITION_STEP.FINISHED ||
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			transitionStep === TRANSITION_STEP.DELAYING_FADE_OUT) &&
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		css({ opacity: 1 }),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ShortcutContainer = styled.div<{
	shouldHaveTransition: boolean;
	transitionStep: TRANSITION_STEP;
}>(
	{
		gridRow: 1,
		gridColumn: 1,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		animationDuration: `${TRANSITION_DURATION_MS}ms`,
		animationTimingFunction: 'ease-in-out',
		animationFillMode: 'forwards',
		display: 'flex',
		justifyContent: 'flex-end',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ shouldHaveTransition, transitionStep }) =>
		shouldHaveTransition &&
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		transitionStep === TRANSITION_STEP.TRANSITIONING_FADE_OUT &&
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		css({ animationName: animationFadeIn }),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ shouldHaveTransition, transitionStep }) =>
		shouldHaveTransition &&
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		transitionStep === TRANSITION_STEP.TRANSITIONING &&
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		css({ animationName: animationFadeOut }),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ transitionStep }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		(transitionStep === TRANSITION_STEP.FINISHED ||
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			transitionStep === TRANSITION_STEP.DELAYING_FADE_OUT) &&
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		css({ opacity: 0 }),
);
