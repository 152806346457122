/**
 * @generated SignedSource<<10f1f933d154d2f8e851e3325d629d45>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type assignToMeInlineEdit_issueViewLayoutAssigneeField_fragmentKey$data = {
  readonly " $fragmentSpreads": FragmentRefs<"assignToMeInlineEdit_issueViewLayoutAssigneeField_AssignToMeInlineEditInner_fragmentKey">;
  readonly " $fragmentType": "assignToMeInlineEdit_issueViewLayoutAssigneeField_fragmentKey";
};
export type assignToMeInlineEdit_issueViewLayoutAssigneeField_fragmentKey$key = {
  readonly " $data"?: assignToMeInlineEdit_issueViewLayoutAssigneeField_fragmentKey$data;
  readonly " $fragmentSpreads": FragmentRefs<"assignToMeInlineEdit_issueViewLayoutAssigneeField_fragmentKey">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "assignToMeInlineEdit_issueViewLayoutAssigneeField_fragmentKey",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "assignToMeInlineEdit_issueViewLayoutAssigneeField_AssignToMeInlineEditInner_fragmentKey"
    }
  ],
  "type": "JiraSingleSelectUserPickerField"
};

(node as any).hash = "6dc8361b42b462ff5696d239b7e582ef";

export default node;
