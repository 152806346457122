import React from 'react';
import { Inline, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { IssueLeftIcon as IssueCommandLeftIcon } from '@atlassian/jira-command-palette-common/src/common/ui/command/issue/issue-left-icon/index.tsx';
import { TruncateWithTooltipOnHover } from '@atlassian/jira-command-palette/src/common/ui/truncate-with-tooltip/truncate-with-tooltip-on-hover/index.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIssueId } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useIssueTypeField } from '@atlassian/jira-issue-field-issue-type/src/services/index.tsx';
import { useSummaryField } from '@atlassian/jira-issue-field-summary-common/src/services/index.tsx';

export type IssueTagProps = {
	issueKey: string;
};

export const IssueTag = ({ issueKey }: IssueTagProps) => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const issueId = ff('relay-migration-issue-fields-summary_eog1v') ? useIssueId() : undefined;

	const [{ value: summary }] = useSummaryField({ issueKey, issueId });
	const [{ value: issueType }] = useIssueTypeField({ issueKey });

	return (
		<Inline xcss={issueTagContainerStyles}>
			<Inline xcss={issueTagStyles} space="space.050" alignBlock="center">
				<IssueCommandLeftIcon issueTypeName={issueType?.name} iconUrl={issueType?.iconUrl} />
				<TruncateWithTooltipOnHover>{`${issueKey} ${summary}`}</TruncateWithTooltipOnHover>
			</Inline>
		</Inline>
	);
};

const issueTagContainerStyles = xcss({
	width: '100%',
	minWidth: '0',
});

const issueTagStyles = xcss({
	maxWidth: '100%',
	padding: 'space.050',
	backgroundColor: 'color.background.neutral',
	borderRadius: 'border.radius.100',
	font: token('font.body'),
	color: 'color.text.subtle',
	lineHeight: 1,
});
