export const FOCUSED_FIELD_STATE = 'FOCUSED';
export const PENDING_FIELD_STATE = 'PENDING';
export const RESOLVED_FIELD_STATE = 'RESOLVED';
export const STORED_FIELD_STATE = 'STORED';
export const FAILED_FIELD_STATE = 'FAILED';
export const IDLE_FIELD_STATE = 'IDLE';

export const FORGE_CF_TIMEOUT = 10000;

export const FORGE_STATUS_WAIT = 'WAIT';
export const FORGE_STATUS_PROCEED = 'PROCEED';
export const FORGE_STATUS_TIMEOUT = 'TIMEOUT';
