import React from 'react';
import type { CustomGlyphProps } from '@atlaskit/icon';

export const statusGlyph = (props: CustomGlyphProps) => (
	<svg
		{...props}
		width="16"
		height="16"
		viewBox="4 4 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16.5255 16.5255C15.3253 17.7257 13.6974 18.4 12 18.4C10.3026 18.4 8.67475 17.7257 7.47452 16.5255C6.27428 15.3253 5.6 13.6974 5.6 12C5.6 10.3026 6.27428 8.67475 7.47452 7.47452C8.67475 6.27428 10.3026 5.6 12 5.6C13.6974 5.6 15.3253 6.27428 16.5255 7.47452C17.7257 8.67475 18.4 10.3026 18.4 12C18.4 13.6974 17.7257 15.3253 16.5255 16.5255ZM4 12C4 16.4184 7.5816 20 12 20C16.4184 20 20 16.4184 20 12C20 7.5816 16.4184 4 12 4C7.5816 4 4 7.5816 4 12ZM12.2517 9.23528C12.1017 9.38659 12.0176 9.591 12.0176 9.80404C12.0176 10.0171 12.1017 10.2215 12.2517 10.3728L13.2685 11.3959H8.80075C8.35838 11.3959 8 11.7559 8 12.1999C8 12.6439 8.35838 13.0038 8.80075 13.0038H13.2685L12.2517 14.027C12.1017 14.1783 12.0176 14.3827 12.0176 14.5957C12.0176 14.8088 12.1017 15.0132 12.2517 15.1645C12.4019 15.3145 12.6054 15.3988 12.8177 15.3988C13.0299 15.3988 13.2335 15.3145 13.3836 15.1645L15.7659 12.7686C15.9159 12.6173 16 12.4129 16 12.1999C16 11.9868 15.9159 11.7824 15.7659 11.6311L13.3836 9.23528C13.3095 9.16071 13.2213 9.10154 13.1241 9.06116C13.027 9.02079 12.9229 9 12.8177 9C12.7125 9 12.6083 9.02079 12.5112 9.06116C12.4141 9.10154 12.3259 9.16071 12.2517 9.23528Z"
			fill="currentColor"
		/>
	</svg>
);
