import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	addRespondersSuccess: {
		id: 'jira-servicedesk-ai-context-ops-panel.add-responders.success',
		defaultMessage:
			'Added {suggestedResponderCount, plural, =0 {} one {# responder} other {# responders}}',
		description: 'Success toast message when user added responders to an issue.',
	},
});
