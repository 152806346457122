import React, { type ReactNode, useCallback } from 'react';
import { graphql, useFragment } from 'react-relay';
import { Box, xcss } from '@atlaskit/primitives';
import { useIssueId } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { FieldHeading } from '@atlassian/jira-issue-field-heading/src/index.tsx';
import {
	FieldWrapper,
	FieldHeadingTitle,
	SideBySideField,
} from '@atlassian/jira-issue-field-heading/src/styled.tsx';
import { ReporterInlineEditView } from '@atlassian/jira-issue-field-reporter-inline-edit-full/src/ui/reporter/index.tsx';
import type { AggUser } from '@atlassian/jira-issue-user-picker-edit-view/src/common/types.tsx';
import { useIssueViewFieldUpdateEvents } from '@atlassian/jira-issue-view-field-update-events/src/services/issue-view-field-update-events/index.tsx';
import type { reporterField_servicedeskCustomerServiceCustomFields$key } from '@atlassian/jira-relay/src/__generated__/reporterField_servicedeskCustomerServiceCustomFields.graphql';

export interface ReporterFieldProps {
	fragmentKey: reporterField_servicedeskCustomerServiceCustomFields$key;
	expandButton?: ReactNode;
}

// Based on original reporter field -  src/packages/issue/fields/issue-view-layout/single-select-user-picker/reporter-field/src/ui/index.tsx
export const ReporterField = ({ fragmentKey, expandButton }: ReporterFieldProps) => {
	const issueId = useIssueId();
	const [, { fieldChanged, fieldChangeFailed, fieldChangeRequested }] =
		useIssueViewFieldUpdateEvents();

	const data = useFragment<reporterField_servicedeskCustomerServiceCustomFields$key>(
		graphql`
			fragment reporterField_servicedeskCustomerServiceCustomFields on JiraSingleSelectUserPickerField {
				...reporter_issueFieldReporterInlineEditFull_ReporterInlineEditView_fragmentRef
				fieldId
				type
				name
				__typename
			}
		`,
		fragmentKey,
	);

	const onSubmit = useCallback(
		(value: AggUser | null) => {
			issueId &&
				fieldChangeRequested(issueId, data.fieldId, value, undefined, {
					type: data.type,
					__typename: data.__typename,
				});
		},
		[data.__typename, data.fieldId, data.type, fieldChangeRequested, issueId],
	);

	const onSubmitSucceeded = useCallback(
		(value: AggUser | null) => {
			issueId &&
				fieldChanged(issueId, data.fieldId, value, {
					type: data.type,
					__typename: data.__typename,
				});
		},
		[data.__typename, data.fieldId, data.type, fieldChanged, issueId],
	);

	const onSubmitFailed = useCallback(() => {
		issueId && fieldChangeFailed(issueId, data.fieldId);
	}, [data.fieldId, fieldChangeFailed, issueId]);

	return (
		<FieldWrapper>
			<FieldHeading>
				<Box xcss={titleStyles}>
					<FieldHeadingTitle>{data.name}</FieldHeadingTitle>
				</Box>
			</FieldHeading>
			<SideBySideField>
				<Box xcss={fieldWrapperStyles}>
					<Box xcss={inlineEditStyles}>
						<ReporterInlineEditView
							fragmentRef={data}
							onSubmit={onSubmit}
							onSubmitSucceeded={onSubmitSucceeded}
							onSubmitFailed={onSubmitFailed}
						/>
					</Box>
					{expandButton}
				</Box>
			</SideBySideField>
		</FieldWrapper>
	);
};

const fieldWrapperStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	gap: 'space.100',
});

const inlineEditStyles = xcss({
	flexGrow: 1,
});

const titleStyles = xcss({
	display: 'flex',
});
