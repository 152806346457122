/**
 * @generated SignedSource<<6c3533b181da4fe930f227b215516895>>
 * @relayHash c0a99e60ca7db3e867b3bb2039798d28
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 06cc27c43b93217b220d59a67c5b60e54cc82db33ba6e684ea3f67b1f62fb4a0

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { assigneeEditView_rootQuery } from './assigneeEditView_rootQuery.graphql';

const node: PreloadableConcreteRequest<assigneeEditView_rootQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "06cc27c43b93217b220d59a67c5b60e54cc82db33ba6e684ea3f67b1f62fb4a0",
    "metadata": {},
    "name": "assigneeEditView_rootQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
