import { useEffect } from 'react';
import type { Store } from 'redux';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { getUpdateAnalyticsFlowHelper } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/index.tsx';
import { useIssueId } from '@atlassian/jira-issue-context-service/src/main.tsx';
import type { State as IssueState } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import { getFieldType } from '@atlassian/jira-issue-view-common-utils/src/fields/index.tsx';
import type { FieldChangedEvent } from '@atlassian/jira-issue-view-field-update-events/src/index.tsx';
import { ChangeEventTypes } from '@atlassian/jira-issue-view-field-update-events/src/services/issue-view-field-update-events/constants.tsx';
import { useIssueViewFieldUpdateEvents } from '@atlassian/jira-issue-view-field-update-events/src/services/issue-view-field-update-events/index.tsx';
import {
	FLAGGED_CF_TYPE,
	STATUS_TYPE,
	TEAMS_PLATFORM_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge/src';

// avoid firing the analytics twice for these field types as they have been handled in useEditField
export const excludeFields: string[] = [FLAGGED_CF_TYPE, STATUS_TYPE, TEAMS_PLATFORM_CF_TYPE];

export const shouldFireAnalytics = (
	additionalProperties: FieldChangedEvent['meta']['additionalProperties'],
) => {
	if (!additionalProperties) {
		return true;
	}
	if (typeof additionalProperties === 'object' && 'isEditFromIssueView' in additionalProperties) {
		return additionalProperties.isEditFromIssueView;
	}
	return true;
};

export const useAnalyticsService = (store: Store<IssueState>) => {
	const [, { registerEventHandler }] = useIssueViewFieldUpdateEvents();
	const issueId = useIssueId();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		const unregister = registerEventHandler('onChange', (event) => {
			try {
				if (
					event.type !== ChangeEventTypes.FIELD_CHANGED ||
					event.issueId !== issueId ||
					!shouldFireAnalytics(event.meta.additionalProperties)
				) {
					return;
				}
				const field = store.getState()?.fields[event.fieldId];
				const fieldType = getFieldType(field) ?? '';

				if (excludeFields.includes(fieldType)) return;

				getUpdateAnalyticsFlowHelper().fireAnalyticsEnd(event.fieldId, {
					analytics: createAnalyticsEvent({}),
					attributes: {
						fieldType,
					},
				});
			} catch (e: unknown) {
				log.safeErrorWithoutCustomerData(
					'issue-details.app-provider.use-analytics-service.event-listener',
					e instanceof Error ? e.toString() : 'unknown error',
				);
			}
		});

		return unregister;
	}, [createAnalyticsEvent, issueId, registerEventHandler, store]);
};
