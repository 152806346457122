import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	readViewMessage: {
		id: 'issue-field-resolution.read.read-view-message',
		defaultMessage: 'You cannot edit the resolution field',
		description: 'Tooltip message for the read view',
	},
	unresolvedResolution: {
		id: 'issue-field-resolution.read.unresolved-resolution',
		defaultMessage: 'Unresolved',
		description: 'Default message for unresolved resolution',
	},
});
