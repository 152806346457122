import flow from 'lodash/flow';
import { connect } from '@atlassian/jira-issue-view-react-redux/src/index.tsx';
import type { State } from '../../model/types.tsx';
import { getCanManageWatchers, getCanViewWatchers } from '../../state/selectors.tsx';
import ActionIcon from './view.tsx';

export default flow(
	connect(
		(state: State) => ({
			canViewDropdown: getCanViewWatchers(state) || getCanManageWatchers(state),
		}),
		{},
	),
)(ActionIcon);
