import React, { useEffect } from 'react';
import Badge from '@atlaskit/badge';
import ErrorIcon from '@atlaskit/icon/core/migration/error';
import Lozenge from '@atlaskit/lozenge';
import Spinner from '@atlaskit/spinner';
import { R300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import AsyncIcon from '@atlassian/jira-common-components-async-icon/src/view.tsx';
import type { ForgeUiIssueData } from '@atlassian/jira-forge-ui-types/src/common/types/extension-data.tsx';
import type { IssueGlance } from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import { useDynamicProperties } from '@atlassian/jira-forge-ui/src/services/dynamic-properties/index.tsx';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useIssueLayoutGlance } from '@atlassian/jira-issue-view-layout/src/services/main.tsx';
import { StatusContainer } from '../../common/styled-glance-view.tsx';

type Props = {
	extension: IssueGlance;
	extensionData: ForgeUiIssueData;
	moduleKey: string;
	appKey: string;
};

export const Status = ({ extension, extensionData, appKey, moduleKey }: Props) => {
	const { properties, error, isLoading, fetch } = useDynamicProperties(extension, extensionData);
	const issueKey = useIssueKey();
	const [openedGlance] = useIssueLayoutGlance(issueKey);
	const hasDynamicProperties = !!extension.properties.dynamicProperties;
	const isOpen =
		!!openedGlance && openedGlance.appKey === appKey && openedGlance.moduleKey === moduleKey;

	useEffect(() => {
		if (hasDynamicProperties && !isOpen) {
			fetch();
		}
	}, [hasDynamicProperties, isOpen, fetch]);

	const render = () => {
		if (hasDynamicProperties) {
			if (isLoading) {
				return <Spinner size="small" />;
			}

			if (error) {
				return <ErrorIcon label="Error" color={token('color.icon.danger', R300)} />;
			}
		}

		const { status: extensionStatus = {} } = extension.properties;
		const status =
			// @ts-expect-error - TS2571 - Object is of type 'unknown'.
			hasDynamicProperties && properties && properties.status
				? // @ts-expect-error - TS2571 - Object is of type 'unknown'.
					properties.status
				: extensionStatus;

		switch (status.type) {
			case 'lozenge':
				return <Lozenge appearance={status.value.type}>{status.value.label}</Lozenge>;
			case 'badge': {
				const num = parseInt(status.value.label, 10);
				if (num >= 0) {
					return <Badge appearance="default">{num}</Badge>;
				}
				return null;
			}
			case 'icon': {
				return <AsyncIcon url={status.value.url} width={24} height={24} />;
			}
			default:
				return null;
		}
	};

	return <StatusContainer>{render()}</StatusContainer>;
};
