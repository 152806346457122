import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'development-summary-deployment-development-field.title',
		defaultMessage: 'Deployed to',
		description:
			'Prefix of a built sentence indicating what environments a jira issue has been deployed to.',
	},
});
