/**
 * @generated SignedSource<<a5859f2e8aff0abc870aef405a820df7>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type cascadingSelect_issueFieldCascadingSelectEditviewFull_CascadingSelectEditViewWithFieldOptionsFragment$data = {
  readonly node: {
    readonly parentOptions?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly childOptions: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly id: string;
                readonly isDisabled: boolean | null | undefined;
                readonly value: string | null | undefined;
              } | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined;
          readonly id: string;
          readonly isDisabled: boolean | null | undefined;
          readonly value: string | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
    readonly selectedParentOptions?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly childOptions: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly id: string;
                readonly isDisabled: boolean | null | undefined;
                readonly value: string | null | undefined;
              } | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined;
          readonly id: string;
          readonly isDisabled: boolean | null | undefined;
          readonly value: string | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "cascadingSelect_issueFieldCascadingSelectEditviewFull_CascadingSelectEditViewWithFieldOptionsFragment";
};
export type cascadingSelect_issueFieldCascadingSelectEditviewFull_CascadingSelectEditViewWithFieldOptionsFragment$key = {
  readonly " $data"?: cascadingSelect_issueFieldCascadingSelectEditviewFull_CascadingSelectEditViewWithFieldOptionsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"cascadingSelect_issueFieldCascadingSelectEditviewFull_CascadingSelectEditViewWithFieldOptionsFragment">;
};

import cascadingSelect_cascadingSelectSearchRefetchQuery_graphql from './cascadingSelect_cascadingSelectSearchRefetchQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = [
  "node",
  "parentOptions"
],
v1 = {
  "kind": "ScalarField",
  "name": "id"
},
v2 = {
  "kind": "ScalarField",
  "name": "value"
},
v3 = {
  "kind": "ScalarField",
  "name": "isDisabled"
},
v4 = {
  "args": [
    {
      "kind": "Variable",
      "name": "filterById",
      "variableName": "childOptionsFilterById"
    }
  ],
  "concreteType": "JiraOptionConnection",
  "kind": "LinkedField",
  "name": "childOptions",
  "plural": false,
  "selections": [
    {
      "concreteType": "JiraOptionEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "concreteType": "JiraOption",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/)
          ]
        }
      ]
    }
  ]
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "childOptionsFilterById"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterById"
    },
    {
      "defaultValue": 50,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "id"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isSelectedParentIdSet"
    },
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "searchBy"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "selectedParentIdFilter"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": cascadingSelect_cascadingSelectSearchRefetchQuery_graphql
    }
  },
  "name": "cascadingSelect_issueFieldCascadingSelectEditviewFull_CascadingSelectEditViewWithFieldOptionsFragment",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "id"
        }
      ],
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": "parentOptions",
              "args": [
                {
                  "kind": "Variable",
                  "name": "filterById",
                  "variableName": "filterById"
                },
                {
                  "kind": "Variable",
                  "name": "searchBy",
                  "variableName": "searchBy"
                }
              ],
              "concreteType": "JiraParentOptionConnection",
              "kind": "LinkedField",
              "name": "__cascadingSelect_issueFieldCascadingSelectEditviewFull_parentOptions_connection",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraParentOptionEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "concreteType": "JiraParentOption",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        (v2/*: any*/),
                        (v3/*: any*/),
                        (v4/*: any*/),
                        {
                          "kind": "ScalarField",
                          "name": "__typename"
                        }
                      ]
                    },
                    {
                      "kind": "ScalarField",
                      "name": "cursor"
                    }
                  ]
                },
                {
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "endCursor"
                    },
                    {
                      "kind": "ScalarField",
                      "name": "hasNextPage"
                    }
                  ]
                }
              ]
            },
            {
              "condition": "isSelectedParentIdSet",
              "kind": "Condition",
              "passingValue": true,
              "selections": [
                {
                  "alias": "selectedParentOptions",
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "filterById",
                      "variableName": "selectedParentIdFilter"
                    }
                  ],
                  "concreteType": "JiraParentOptionConnection",
                  "kind": "LinkedField",
                  "name": "parentOptions",
                  "plural": false,
                  "selections": [
                    {
                      "concreteType": "JiraParentOptionEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "concreteType": "JiraParentOption",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/),
                            (v2/*: any*/),
                            (v3/*: any*/),
                            (v4/*: any*/)
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ],
          "type": "JiraCascadingSelectField"
        }
      ]
    }
  ],
  "type": "Query"
};
})();

(node as any).hash = "ebe1d9e6c6846d6dbd53863d27c091d5";

export default node;
