import type { ThemeAppearance } from '@atlaskit/lozenge';

export const REMOTE_LINK_TYPES = [
	'document',
	'alert',
	'test',
	'security',
	'logFile',
	'prototype',
	'coverage',
	'bugReport',
	'releaseNotes',
	'other',
] as const;

export type RemoteLinkStatus = {
	readonly label: string;
	readonly appearance: ThemeAppearance;
};

export type RemoteLinkAction = {
	readonly id: string;
	readonly label: string;
	readonly url: string;
};

export type RemoteLinkProvider = {
	readonly id: string;
	readonly name: string;
	readonly homeUrl: string;
	readonly logoUrl: string | null;
	readonly documentationUrl: string | null;
};

export type RemoteLink = {
	readonly id: string;
	readonly provider: RemoteLinkProvider;
	readonly displayName: string;
	readonly url: string;
	readonly type: string;
	readonly description: string | null;
	readonly status: RemoteLinkStatus | null;
	readonly actions: RemoteLinkAction[];
};
