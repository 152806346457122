import omit from 'lodash/omit';
import uniq from 'lodash/uniq';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type {
	AppId,
	AppRegisteredFields,
	ChangeId,
	IssueAdjustmentsState,
	RegisteredFields,
} from '../../types.tsx';

export const registerFields =
	({
		changeId,
		fieldsToBeRegistered,
		appId,
	}: {
		changeId: ChangeId;
		fieldsToBeRegistered: RegisteredFields;
		appId: AppId;
	}) =>
	({ setState, getState }: StoreActionApi<IssueAdjustmentsState>) => {
		if (!Array.isArray(fieldsToBeRegistered)) {
			return;
		}

		const { registeredFields } = getState();
		const appFields = registeredFields[appId] ?? {};
		const appChangeIdFields = appFields[changeId] ?? [];

		const nextRegisteredFields = {
			...registeredFields,
			[appId]: {
				...appFields,
				[changeId]: uniq([...appChangeIdFields, ...fieldsToBeRegistered]),
			},
		};

		setState({ registeredFields: nextRegisteredFields });
	};

export const unregisterFields =
	({ changeId, appId }: { changeId: ChangeId; appId: AppId }) =>
	({ setState, getState }: StoreActionApi<IssueAdjustmentsState>) => {
		const { registeredFields } = getState();
		const registeredAppFields: AppRegisteredFields = registeredFields[appId] ?? {};
		const nextRegisteredAppFields = omit(registeredAppFields, changeId);

		setState({
			registeredFields: {
				...registeredFields,
				[appId]: nextRegisteredAppFields,
			},
		});
	};
