import type { SelectValueShape } from '@atlassian/jira-issue-internal-field-select/src/common/select-inline-edit/select-field/types.tsx';
import type {
	RequestTypeFromServer,
	RequestTypePayload,
	StateValue,
} from '@atlassian/jira-shared-types/src/rest/jira/customer-request-type.tsx';

const toItem = (stateValue: StateValue) => {
	/* When the current user cannot see the request type, an error message will be returned. In this case, we want to
    hide the Request type field. */
	if (stateValue.errorMessage) {
		return {
			hasError: true,
		};
	}

	if (!stateValue.requestType) {
		return null;
	}

	const { icon, issueTypeId, id, name } = stateValue.requestType;
	const iconUrl = icon._links.iconUrls['48x48'];
	return {
		issueTypeId,
		content: name,
		value: id,
		iconUrl,
	};
};

const toSuggestion = (requestType: RequestTypeFromServer) => ({
	issueTypeId: requestType.issueType.toString(),
	content: requestType.name,
	value: requestType.id,
	iconUrl: requestType.iconUrl,
});

/**
 * Different cases of Request type state value:
 * - Issue has a valid request type:
 * {
 *     content: {
 *         requestType: { ... }
 *     }
 * }
 *
 * - Issue does not have a request type:
 * {
 *     content: null
 * }
 *
 * - Issue has a deleted request type:
 * {
 *     content: { }
 * }
 *
 * - User cannot see the request type:
 * {
 *     content: {
 *         errorMessage: "..."
 *     }
 * }
 *
 */
export const transformFromStateValue = (stateValue: StateValue | null) =>
	stateValue && toItem(stateValue);

export const transformToStateValue = (value: SelectValueShape) => ({
	requestType: {
		icon: {
			_links: {
				iconUrls: {
					'48x48': value.iconUrl || '',
				},
			},
		},
		id: value.value,
		name: value.content,
		issueTypeId: value.issueTypeId,
	},
});

export const transformSuggestions = (suggestionsFromServer: RequestTypePayload) => {
	const validRequestTypeItems = suggestionsFromServer.validRequestTypes.map(toSuggestion);

	return [{ items: validRequestTypeItems }] as const;
};
