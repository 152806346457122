import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import { Text } from '@atlaskit/primitives';
import { components } from '@atlaskit/select';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import messages from './messages.tsx';

export type LoadingMenuProps = {
	isLoading: boolean;
	isIdeasSelect?: boolean;
	children: ReactNode;
	testId?: string;
};

// exported for storybook only
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ComponentsMenu = (props: any) => {
	const { children, ...rest } = props;

	return <components.Menu {...rest}>{children}</components.Menu>;
};

export const LoadingMenu = (props: LoadingMenuProps) => {
	const { isLoading, isIdeasSelect, children, testId, ...rest } = props;
	const { formatMessage } = useIntl();
	const { cloudName } = useTenantContext();
	return (
		<Wrapper isLoading={isLoading}>
			{isLoading && (
				<Placeholder>
					<Caption>
						<Text size="small" weight="medium">
							{isIdeasSelect
								? formatMessage(messages.loadingIdeasSuggestions, { cloudName })
								: formatMessage(
										fg('polaris-issue-terminology-refresh')
											? messages.loadingSuggestionsIssueTermRefresh
											: messages.loadingSuggestions,
										{ cloudName },
									)}
						</Text>
					</Caption>
					<Spinner size="small" testId={testId} />
				</Placeholder>
			)}
			<ComponentsMenu {...rest}>{children}</ComponentsMenu>
		</Wrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div<{ isLoading: boolean }>({
	position: 'absolute',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	top: ({ isLoading }) => (isLoading ? '-3px' : 0),
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Placeholder = styled.div({
	display: 'flex',
	marginTop: token('space.100', '8px'),
	marginRight: token('space.150', '12px'),
	paddingLeft: token('space.150', '12px'),
	backgroundColor: token('elevation.surface', '#fff'),
	height: 16,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Caption = styled.div({
	color: '#999',
	cursor: 'default',
	textTransform: 'uppercase',
	marginRight: token('space.150', '12px'),
	boxSizing: 'border-box',
});
