// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	findOutMore: {
		id: 'issue.find-out-more',
		defaultMessage: 'Find out more',
		description:
			'Label for the issue actions item which shows the user more information about the new issue view',
	},
});
