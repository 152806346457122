import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	showAll: {
		id: 'issue.tag-view.show-all',
		defaultMessage:
			'Show all {peopleCount, plural, few {# people} many {# people} other {# people}}',
		description:
			'Text shown where a large number of users needs to be displayed for a field but we only show the first 8 users and this button shows the rest.',
	},
	showLess: {
		id: 'issue.tag-view.show-less',
		defaultMessage: 'Show less',
		description:
			'Text when a large number of users shown for a field has been displayed this button goes back to showing fewer users',
	},
});
