import { type Action, createStore, createHook } from '@atlassian/react-sweet-state';
import type {
	CLASSIC_SUBTASKS,
	CHILDREN_ISSUES_PANEL,
	CLASSIC_PROJECT_EPIC_CHILDREN,
} from '../../model/types.tsx';

type State = {
	whichChildPanelRenderTriggered:
		| typeof CHILDREN_ISSUES_PANEL
		| typeof CLASSIC_SUBTASKS
		| typeof CLASSIC_PROJECT_EPIC_CHILDREN
		| null;
};

const initialState: State = {
	// This state is used to determine the source from which the child panel was opened from
	// Specifically, this is used by the Improve Issue Dropdown
	whichChildPanelRenderTriggered: null,
};

export const setWhichChildPanelRenderTriggered =
	(
		whichChildPanelRenderTriggered:
			| typeof CHILDREN_ISSUES_PANEL
			| typeof CLASSIC_SUBTASKS
			| typeof CLASSIC_PROJECT_EPIC_CHILDREN,
	): Action<State> =>
	({ setState }) => {
		setState({ whichChildPanelRenderTriggered });
	};

export const resetWhichChildPanelRenderTriggered =
	(): Action<State> =>
	({ setState }) => {
		setState({ whichChildPanelRenderTriggered: null });
	};

export const actions = {
	setWhichChildPanelRenderTriggered,
	resetWhichChildPanelRenderTriggered,
};

type ActionType = typeof actions;

const Store = createStore<State, ActionType>({
	initialState,
	actions,
	name: 'child-panel-render-trigger',
});

export const useChildPanelRenderTrigger = createHook(Store);
