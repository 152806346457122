import { useCallback, useEffect, useRef } from 'react';
import { useService } from '@atlassian/jira-common-services/src/use-service/index.tsx';
import FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useEnvironment } from '@atlassian/jira-tenant-context-controller/src/components/environment/index.tsx';
import { MISSING_PERMS, type DISABLED, type ENABLED } from '../../common/constants.tsx';
import { fetchRuleConfig, fetchRuleData } from '../../services/fetch-rule.tsx';
import { useWithAnalytics } from './use-with-analytics.tsx';

export type RuleState = typeof ENABLED | typeof DISABLED | typeof MISSING_PERMS;

export type RuleConfigResult = {
	ruleId: number | string | null;
	token: string | null;
	ruleState: RuleState | null;
};

export const useFetchRule = (issueKey: string, projectId: string) => {
	const cloudId = useCloudId();
	const environment = useEnvironment();
	const withAnalytics = useWithAnalytics();
	const hasFetched = useRef(false);

	const fetchRule = useCallback(async (): Promise<RuleConfigResult> => {
		// Fetch whether a rule is configured for this issue
		const ruleConfig = await withAnalytics(fetchRuleConfig(issueKey), 'fetchRuleConfig');
		const ruleId = ruleConfig.value?.automationRuleId;
		const token = ruleConfig?.value && ruleConfig?.value.token;

		if (!ruleId) {
			return Promise.resolve({
				ruleId,
				token,
				ruleState: null,
			});
		}

		// If there's a rule configured for this issue, fetch the rule data
		try {
			const ruleData = await withAnalytics(
				fetchRuleData(environment, cloudId, projectId, ruleId),
				'fetchRuleData',
			);
			return {
				ruleId,
				token,
				ruleState: ruleData?.state || null,
			};
		} catch (error) {
			if (error instanceof FetchError) {
				// Rule exists but user doesn't have permission to update rule
				if (error.statusCode === 403) {
					return Promise.resolve({
						ruleId,
						token,
						ruleState: MISSING_PERMS,
					});
				}
				// Edge case where rule was deleted just before we fetch rule data
				if (error.statusCode === 404) {
					return Promise.resolve({
						ruleId: null,
						token: null,
						ruleState: null,
					});
				}
			}
			throw error;
		}
	}, [cloudId, environment, issueKey, projectId, withAnalytics]);

	const { data, error, loading, fetch } = useService<RuleConfigResult>(fetchRule);

	useEffect(() => {
		if (!hasFetched.current) {
			fetch();
			hasFetched.current = true;
		}
	}, [fetch]);

	return { data, error, loading };
};
