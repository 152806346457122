import React from 'react';
import { styled } from '@compiled/react';
import type { DocNode as ADF } from '@atlaskit/adf-schema';
import { Field } from '@atlaskit/form';
import { Box, xcss } from '@atlaskit/primitives';
import mapLocaleToPrsLocale from '@atlaskit/prs-locale-mapper';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import type { FormValues } from '@atlassian/jira-issue-view-common-types/src/log-time-modal-type.tsx';
import type { MediaContext } from '@atlassian/jira-issue-view-common-types/src/media-context-type.tsx';
import { parseDateTimeValue } from '@atlassian/jira-issue-view-common-utils/src/date-time-formatter/index.tsx';
import { getLocale } from '@atlassian/jira-platform-utils-date-fns/src/main.tsx';
import { useLocale } from '@atlassian/jira-tenant-context-controller/src/components/locale/index.tsx';
import DateTimeField from '../../../legacy-date-time/view.tsx';
import { WorkDescription as WorkDescriptionEditor } from '../work-description/view.tsx';
import messages from './messages.tsx';
import ValidatedTimeLoggedTextField from './time-logged/view.tsx';
import ValidatedTimeRemainingTextField from './time-remaining/view.tsx';
import TimeTrackingHelperText from './time-tracking-helper-text/view.tsx';

export type Props = {
	isCollapsed: boolean;
	isDisabled: boolean;
	isTimeLoggedValid: boolean;
	isTimeRemainingValid: boolean;
	value: FormValues;
	timeZone: string | undefined;
	portalElement?: HTMLElement | null | undefined;
	mediaContext: MediaContext;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mentionEncoder: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mentionProvider: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	activityProvider: any;
	externalId: string;
	onTimeLoggedChange: (arg1: string) => void;
	onTimeLoggedBlur: () => void;
	onTimeRemainingChange: (arg1: string) => void;
	onTimeRemainingBlur: () => void;
	onDateStartedChange: (arg1: string | null) => void;
	onWorkDescriptionChange: (arg1: ADF) => void;
	onWorkDescriptionChangeFailure: () => void;
};

export const LogTimeModalForm = ({
	portalElement = undefined,
	isCollapsed,
	isDisabled,
	isTimeLoggedValid,
	isTimeRemainingValid,
	value: { timeLogged, timeRemaining, dateStarted, workDescription },
	timeZone,
	mediaContext,
	mentionEncoder,
	mentionProvider,
	activityProvider,
	externalId,
	onTimeLoggedChange,
	onTimeLoggedBlur,
	onTimeRemainingChange,
	onTimeRemainingBlur,
	onDateStartedChange,
	onWorkDescriptionChange,
	onWorkDescriptionChangeFailure,
}: Props) => {
	const intl = useIntl();
	const locale = useLocale();
	const weekStartDay = getLocale(locale)?.options?.weekStartsOn;

	return (
		<>
			<TimeFieldsContainer>
				<TimeFieldContainer
					// eslint-disable-next-line jira/integration/test-id-by-folder-structure
					data-testid="issue.common.component.log-time-modal.modal.form.time-spent"
				>
					<ValidatedTimeLoggedTextField
						value={timeLogged}
						isDisabled={isDisabled}
						isValid={isTimeLoggedValid}
						onChange={onTimeLoggedChange}
						onBlur={onTimeLoggedBlur}
					/>{' '}
				</TimeFieldContainer>
				<TimeFieldContainer
					// eslint-disable-next-line jira/integration/test-id-by-folder-structure
					data-testid="issue.common.component.log-time-modal.modal.form.time-remaining"
				>
					<ValidatedTimeRemainingTextField
						value={timeRemaining}
						isDisabled={isDisabled}
						isValid={isTimeRemainingValid}
						onChange={onTimeRemainingChange}
						onBlur={onTimeRemainingBlur}
					/>
				</TimeFieldContainer>
			</TimeFieldsContainer>
			<TimeTrackingHelperText />
			<WorklogDetailsContainer
				/* eslint-disable-next-line jira/integration/test-id-by-folder-structure */
				data-testid="issue.common.component.log-time-modal.modal.form.worklog-details-container"
				isCollapsed={isCollapsed}
			>
				<Box xcss={dateStartedContainerStyles}>
					<Field name="date-started" label={intl.formatMessage(messages.dateStarted)} isRequired>
						{() => (
							<DateTimeField
								value={dateStarted || null}
								timeZone={timeZone}
								parseValue={parseDateTimeValue}
								onChange={onDateStartedChange}
								isDisabled={isDisabled}
								weekStartDay={weekStartDay}
								locale={fg('ken-1553-fix-a11y-violation') ? mapLocaleToPrsLocale(locale) : locale}
							/>
						)}
					</Field>
				</Box>
				<WorkDescriptionEditor
					defaultValue={workDescription}
					isDisabled={isDisabled}
					portalElement={portalElement}
					onChange={onWorkDescriptionChange}
					onChangeFailure={onWorkDescriptionChangeFailure}
					externalId={externalId}
					mediaContext={mediaContext}
					mentionProvider={mentionProvider}
					mentionEncoder={mentionEncoder}
					activityProvider={activityProvider}
				/>
			</WorklogDetailsContainer>
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TimeFieldsContainer = styled.div({
	alignItems: 'flex-start',
	display: 'flex',
	flexDirection: 'row',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'> :not(:first-child)': {
		marginLeft: token('space.100', '8px'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TimeFieldContainer = styled.div({
	width: '50%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const WorklogDetailsContainer = styled.div<{ isCollapsed?: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	display: (props) => (props.isCollapsed ? 'none' : 'block'),
});

const dateStartedContainerStyles = xcss({
	width: '100%',
});
