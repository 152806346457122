export const LOOKUP_SOURCE_ALLOWED_VALUES_PROPERTY = 'LOOKUP_SOURCE_ALLOWED_VALUES_PROPERTY';
export const LOOKUP_SOURCE_USERS_ASYNC_DATA = 'LOOKUP_SOURCE_USERS_ASYNC_DATA';

/**
 * `AUTOMATIC_ID` is a duplicate const of `AUTOMATIC_ID` from `@atlassian/jira-issue-field-assignee`
 * located in src/packages/issue/fields/assignee/src/common/constants.tsx
 * This was done to prevent cyclic dependencies between packages
 */
export const AUTOMATIC_ID = '-1';

/**
 * The limit of array in setValue for multi fields
 */
export const SET_VALUE_ARRAY_SIZE_LIMIT = 100;
