import React, { useMemo, useCallback } from 'react';
import { styled } from '@compiled/react';
import { Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { PRETTY } from '@atlassian/jira-common-constants/src/jira-settings.tsx';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import type {
	TimeTracking,
	TimeTrackingConfig,
	OptionalTime,
} from '@atlassian/jira-issue-shared-types/src/common/types/jira-settings-type.tsx';
import { timeTrackingFormatter } from '@atlassian/jira-time-tracking-formatter/src/main.tsx';
import { ProgressTracker } from '../progress-tracker/view.tsx';

type Props = {
	isCompact?: boolean;
	isDone?: boolean;
	isEditable: boolean;
	value: TimeTracking;
	config: TimeTrackingConfig;
	estimatedTime: OptionalTime;
};

const LoggedTime = ({
	isCompact = true,
	isDone = false,
	isEditable,
	value,
	config,
	estimatedTime,
}: Props) => {
	const intl = useIntl();

	const secondsToTimeString = useCallback(
		(seconds: number) =>
			timeTrackingFormatter(
				seconds,
				{
					workingHoursPerDay: config.hoursPerDay,
					workingDaysPerWeek: config.daysPerWeek,
					timeFormat: config.format || PRETTY,
					defaultUnit: config.defaultUnit,
				},
				intl,
			),
		[config.daysPerWeek, config.defaultUnit, config.format, config.hoursPerDay, intl],
	);

	const timeLoggedText = useMemo(() => {
		if (value && value.loggedTime) {
			const loggedTimeText = secondsToTimeString(value.loggedTime);
			return (
				<FormattedMessage
					id="issue.log-time.time-logged"
					defaultMessage="{time} logged"
					values={{
						time: (
							// eslint-disable-next-line jira/integration/test-id-by-folder-structure
							<Text color="inherit" testId="issue.issue-view.common.logged-time.value">
								{loggedTimeText}
							</Text>
						),
					}}
				/>
			);
		}

		return <FormattedMessage id="issue.log-time.no-time-logged" defaultMessage="No time logged" />;
	}, [secondsToTimeString, value]);

	const timeRemainingText = useMemo(() => {
		if (!value.remainingTime) {
			return null;
		}

		const timeRemaining = secondsToTimeString(value.remainingTime);
		return (
			<RemainingText data-testid="issue.component.logged-time.remaining-time">
				<FormattedMessage
					id="issue.log-time.remaining-time"
					defaultMessage="{time} remaining"
					values={{
						time: <Text color="inherit">{timeRemaining}</Text>,
					}}
				/>
			</RemainingText>
		);
	}, [secondsToTimeString, value.remainingTime]);

	return (
		<LoggedTimeContainer isEditable={isEditable}>
			<Main>
				<ProgressTracker
					value={value}
					estimatedTime={estimatedTime}
					config={config}
					isDone={isDone}
					isCompact={isCompact}
				/>
				<TextContainer>
					<TimeLoggedTextContainer>{timeLoggedText}</TimeLoggedTextContainer>
					{timeRemainingText}
				</TextContainer>
			</Main>
		</LoggedTimeContainer>
	);
};

export default LoggedTime;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
const LoggedTimeContainer = styled.div<{ isEditable: boolean }>(({ isEditable }) =>
	isEditable
		? {
				paddingTop: 0,
				paddingBottom: 0,
				paddingLeft: token('space.075', '6px'),
				paddingRight: 0,
				marginRight: token('space.negative.025', '-2px'),

				minHeight: token('space.300', '24px'),
			}
		: {
				marginTop: token('space.negative.050', '-4px'),
				marginBottom: token('space.negative.050', '-4px'),
				marginLeft: token('space.negative.025', '-2px'),
				marginRight: token('space.100', '8px'),
				'& > div:first-child > div:first-child > div': {
					padding: 0,
				},
			},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Main = styled.div({
	flexGrow: 1,
	marginTop: token('space.050', '4px'),
	marginRight: token('space.025', '2px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TextContainer = styled.small({
	color: token('color.text.subtlest', '#626F86'),
	display: 'flex',
	justifyContent: 'space-between',
	marginTop: 0,
	font: token('font.body'),
	flexWrap: 'wrap',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TimeLoggedTextContainer = styled.span({
	marginRight: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RemainingText = styled.span({
	cursor: 'pointer',
});
