import type { ProjectOption, Option, Options } from './types.tsx';

export const mapDataToOption = (data: ProjectOption): Option => ({
	label: data.name,
	value: data.key,
	iconUrl: data.avatarUrls['48x48'],
	projectId: data.id,
});

export const mapDataToOptions = (data: ProjectOption[]): Options => data.map(mapDataToOption);

export const mapOptionToData = ({ label, value, iconUrl }: Option): ProjectOption => ({
	key: value,
	name: label,
	avatarUrls: {
		'48x48': iconUrl,
	},
});
