import { createSelector } from 'reselect';
import isEqual from 'lodash/isEqual';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import set from 'lodash/set';
import memoizeOne from 'memoize-one';
import type { StatusFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/status/types.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { Filter } from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import type { IssuesRemote } from '@atlassian/jira-polaris-remote-issue/src/controllers/types.tsx';
import { jiraStatusMapping } from '../../../../field/mapping/status/index.tsx';
import type { PropertyMaps, State } from '../../../types.tsx';
import {
	nullSafeComparator,
	stringComparator,
	type SafeComparator,
} from '../../comparators/index.tsx';
import {
	createStringValueContainsFilter,
	createStringValuesContainsFilter,
} from '../common/filter-utils.tsx';
import { removePropertyValue } from '../common/remove-utils.tsx';
import type { FieldMapping } from '../types.tsx';

const getStatusList = (valuesObject: Record<string, StatusFieldValue>): StatusFieldValue[] =>
	Object.values(valuesObject);
const getStatusListMemoized = memoizeOne(getStatusList);

const getSimilarStatusIdsByStatusId = (
	values: StatusFieldValue[],
	comparator: SafeComparator<StatusFieldValue>,
): Record<string, string[]> => {
	const statusesById = keyBy(values, 'id');
	return mapValues(statusesById, (statusA) =>
		values.filter((statusB) => comparator(statusA, statusB, 'ASC') === 0).map(({ id }) => id),
	);
};
const getSimilarStatusIdsByStatusIdMemoized = memoizeOne(getSimilarStatusIdsByStatusId);

export const statusMapping = (
	issuesRemote: IssuesRemote,
	field: Field,
): FieldMapping<StatusFieldValue> => {
	const valueAccessor: FieldMapping<StatusFieldValue>['valueAccessor'] = (state, props, issueId) =>
		state.properties.status[field.key] !== undefined
			? state.properties.status[field.key][issueId]
			: undefined;

	const valueAccessorToExport: FieldMapping<string>['valueAccessorToExport'] = (
		state,
		props,
		issueId,
	) => valueAccessor(state, props, issueId)?.name || '';

	const getAllValues: FieldMapping<StatusFieldValue>['getAllValues'] = (state: State) =>
		state.properties.status[field.key] ? state.properties.status[field.key] : {};

	const comparator = nullSafeComparator<StatusFieldValue>((a, b, direction) => {
		// inverse order on key results in correct category order
		const category = stringComparator(b.statusCategory.key, a.statusCategory.key, direction);
		if (category !== 0) {
			return category;
		}
		return stringComparator(a.name, b.name, direction);
	});

	return {
		...jiraStatusMapping(issuesRemote, field),
		setMutable: (maps: PropertyMaps, issueId: LocalIssueId, value?: StatusFieldValue) =>
			set(maps.status, [field.key, issueId], value),
		// @ts-expect-error(PARTIAL_RECORD) TS2322 - Type '(maps: PropertyMaps, issueId: LocalIssueId, value?: IssueStatusProperty) => { status: { [x: string]: Record<string, IssueStatusProperty> | { [x: string]: IssueStatusProperty | undefined; }; }; ... 17 more ...; commentsMetadata: CommentsMetadataMap; }' is not assignable to type '(arg1: PropertyMaps, arg2: string, arg3: IssueStatusProperty | undefined) => PropertyMaps'.
		setImmutable: (maps: PropertyMaps, issueId: LocalIssueId, value?: StatusFieldValue) => {
			if (maps.status[field.key] && isEqual(maps.status[field.key][issueId], value)) {
				return maps;
			}
			return {
				...maps,
				status: {
					...maps.status,
					[field.key]: {
						...maps.status[field.key],
						[issueId]: value,
					},
				},
			};
		},
		remove: (maps: PropertyMaps, issueId: LocalIssueId) =>
			removePropertyValue(field.key, maps, issueId, 'status'),
		modifyImmutableIfMultiValueField: (maps: PropertyMaps) => maps,
		comparator,
		valueAccessor,
		valueAccessorToExport,
		getAllValues,
		getGroupIdentitiesSelector: (fieldKey, issueIdsSelector) =>
			createSelector(
				issueIdsSelector,
				(state: State) => state.properties.status[fieldKey],
				(ids, status) =>
					ids.reduce(
						(result, issueId) =>
							Object.assign(result, {
								[issueId]:
									status !== undefined && status[issueId] !== undefined
										? [
												{
													groupIdentity: status[issueId].id,
													value: status[issueId],
												},
											]
										: [],
							}),
						{},
					),
			),
		getGroupIdentities: (state, props, issueId) =>
			state.properties.status[field.key] !== undefined &&
			state.properties.status[field.key][issueId] !== undefined
				? [
						{
							groupIdentity: state.properties.status[field.key][issueId].id,
							value: state.properties.status[field.key][issueId],
						},
					]
				: [],
		allowEmptyGroup: false,
		getLabel: (groupIdentity, value) => value?.name,
		getFilter: (filter: Filter) => {
			if (filter.type === 'FIELD' && filter.field === field.key) {
				const stringValueContainsFilter = createStringValueContainsFilter(filter);
				const stringValuesContainsFilter = createStringValuesContainsFilter(filter);
				if (stringValueContainsFilter === undefined || stringValuesContainsFilter === undefined) {
					return undefined;
				}
				return (value: undefined | StatusFieldValue, state, props, localIssueId) => {
					if (props?.isCollectionView) {
						const allStatuses = getStatusListMemoized(getAllValues(state, props));
						const similarStatusIdsByStatusId = getSimilarStatusIdsByStatusIdMemoized(
							allStatuses,
							comparator,
						);
						const similarIds = value?.id ? similarStatusIdsByStatusId[value.id] : undefined;
						return stringValuesContainsFilter(similarIds, state, props, localIssueId);
					}
					return stringValueContainsFilter(value?.id, state, props, localIssueId);
				};
			}
			return undefined;
		},
	};
};
