const INTERVAL_FIELD_SOURCE_MANUAL = 'MANUAL';
const INTERVAL_FIELD_SOURCE_ATLAS_PROJECT_START_DATE = 'ATLAS_PROJECT_START_DATE';
const INTERVAL_FIELD_SOURCE_ATLAS_PROJECT_TARGET_DATE = 'ATLAS_PROJECT_TARGET_DATE';
const INTERVAL_FIELD_SOURCE_DELIVERY_DATE = 'DELIVERY_DATE';

export type IntervalFieldSourcesMap = {
	MANUAL: typeof INTERVAL_FIELD_SOURCE_MANUAL;
	ATLAS_PROJECT_START_DATE: typeof INTERVAL_FIELD_SOURCE_ATLAS_PROJECT_START_DATE;
	ATLAS_PROJECT_TARGET_DATE: typeof INTERVAL_FIELD_SOURCE_ATLAS_PROJECT_TARGET_DATE;
	DELIVERY_DATE: typeof INTERVAL_FIELD_SOURCE_DELIVERY_DATE;
};

export const INTERVAL_FIELD_SOURCES: IntervalFieldSourcesMap = {
	MANUAL: INTERVAL_FIELD_SOURCE_MANUAL,
	ATLAS_PROJECT_START_DATE: INTERVAL_FIELD_SOURCE_ATLAS_PROJECT_START_DATE,
	ATLAS_PROJECT_TARGET_DATE: INTERVAL_FIELD_SOURCE_ATLAS_PROJECT_TARGET_DATE,
	DELIVERY_DATE: INTERVAL_FIELD_SOURCE_DELIVERY_DATE,
};

export type IntervalFieldSource = IntervalFieldSourcesMap[keyof IntervalFieldSourcesMap];
