import React, { type ReactNode } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import SectionMessage from '@atlaskit/section-message';

export const aiContextEscalationTestId = 'servicedesk-ai-context-panel.ui.ai-escalation';

export const AIContextEscalation = ({ children }: AIContextEscalationProps) => {
	return (
		<Box xcss={aiContextEscalationCss}>
			<SectionMessage appearance="warning" testId={aiContextEscalationTestId}>
				{children}
			</SectionMessage>
		</Box>
	);
};

const aiContextEscalationCss = xcss({
	marginBottom: 'space.150',
});

export type AIContextEscalationProps = {
	children: ReactNode;
};
