import { useMemo } from 'react';
import type { FlagConfiguration } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { useIntl } from '@atlassian/jira-intl';
import type { NotificationType } from '@atlassian/jira-issue-adjustments/src/controllers.tsx';
import { getNotificationFlagProperties } from './utils.tsx';

type Props = {
	type: NotificationType;
} | null;

export const useNotificationFlagConfig = (
	notificationProperties: Props,
): FlagConfiguration | null => {
	const { formatMessage } = useIntl();
	const notificationFlagConfig = useMemo(() => {
		if (notificationProperties === null) {
			return null;
		}

		const notificationFlagProperties = getNotificationFlagProperties(notificationProperties.type);
		return notificationFlagProperties
			? ({
					type: notificationFlagProperties.type,
					title: formatMessage(notificationFlagProperties.title),
					description: formatMessage(notificationFlagProperties.message),
					isAutoDismiss: true,
				} as const)
			: null;
	}, [notificationProperties, formatMessage]);
	return notificationFlagConfig;
};
