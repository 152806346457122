import React, { useCallback, useEffect, useState, useMemo } from 'react';
import compact from 'lodash/compact';
import some from 'lodash/some';
import type { Subject } from 'rxjs/Subject';
import Select from '@atlaskit/select';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import type {
	GroupedOption,
	IssueOption,
	IssueSelectProps,
	FilterOption,
	FetchQueryParams,
} from '../types.tsx';
import { LoadingMenu, type LoadingMenuProps } from './menu/index.tsx';
import messages from './messages.tsx';
import {
	Option,
	SelectedOption,
	MultiSelectedOption,
	type IssueOptionPropsNext,
} from './option/index.tsx';
import { createFetchQuery, getSearchParams, useFetchIssues } from './utils.tsx';

const EXCLUDED_ISSUE_IDS: IssueSelectProps['excludedIssueIds'] = [];

export const IssueSelect = ({
	archivedFieldLabel,
	selectedIssueKey,
	isDisabled,
	autoFocus,
	projectId,
	excludedProjectTypes,
	excludedIssueIds = EXCLUDED_ISSUE_IDS,
	hideArchived,
	placeholder,
	defaultOptions,
	onIssueSelected,
	createOption,
	isAttachedToBody = false,
	renderOptionIconAfter,
	onProjectChanged,
	onCreateIssue,
	selectedIssue,
	setSelectedIssue,
	isIdeasSelect = false,
}: IssueSelectProps) => {
	const [inputString, setInputString] = useState('');
	const [internalDefaultOptions, setInternalDefaultOptions] = useState<
		GroupedOption | IssueOption[] | undefined
	>(defaultOptions);
	const [options, setOptions] = useState<IssueOption[]>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
	const [issueExactKey, setIssueExactKey] = useState<string | undefined>(undefined);
	const [fetchQuery$, setFetchQuery$] = useState<Subject<FetchQueryParams> | undefined>(undefined);

	const fetchIssues = useFetchIssues(isIdeasSelect, selectedIssueKey, archivedFieldLabel);
	const { cloudName } = useTenantContext();
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		const { query$, unsubscribe } = createFetchQuery(
			setIsLoading,
			excludedIssueIds,
			fetchIssues,
			setOptions,
			setInternalDefaultOptions,
			defaultOptions,
		);
		setFetchQuery$(query$);

		return () => unsubscribe();
	}, [defaultOptions, excludedIssueIds, fetchIssues]);

	// trigger search on initial render (and whenever external props change)
	useEffect(() => {
		// to be improved with POL-10020
		// Don't call once again, when projectId is undefined and it's still loading - to handle copy & paste link case
		if (isLoading && projectId === undefined) return;

		fetchQuery$?.next({
			query: '%',
			currentProjectId: projectId,
			currentExcludedProjectTypes: excludedProjectTypes,
			hideArchivedIssues: hideArchived,
			isImmediate: true,
			isInitialLoading: true,
		});
		// Dont't fire it when isLoading is changed
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [excludedProjectTypes, fetchQuery$, hideArchived, projectId]);

	const noOptionsMessage = () => {
		if (isLoading) {
			return formatMessage(messages.isLoading);
		}
		if (inputString !== undefined) {
			return isIdeasSelect
				? formatMessage(messages.noIdeasFound)
				: formatMessage(
						fg('polaris-issue-terminology-refresh')
							? messages.noIssuesFoundIssueTermRefresh
							: messages.noIssuesFound,
					);
		}
		return formatMessage(
			fg('polaris-issue-terminology-refresh')
				? messages.typeToSearchIssueTermRefresh
				: messages.typeToSearch,
		);
	};

	const handleChange = useCallback(
		(selection: IssueOption | null) => {
			if (selection === null) {
				setSelectedIssue && setSelectedIssue(undefined);
				onIssueSelected(undefined);
			} else {
				setSelectedIssue && setSelectedIssue(selection);
				onIssueSelected(selection);
				if (selection.isNew === true && createOption !== undefined && onCreateIssue) {
					onCreateIssue(selection.label.trim());
				}
			}
			fireUIAnalytics(
				createAnalyticsEvent({ action: 'clicked', actionSubject: 'dropdownItem' }),
				'issue',
				{
					selectedIssueId: selection?.item?.id.toString(),
				},
			);
		},
		[createOption, onCreateIssue, onIssueSelected, createAnalyticsEvent, setSelectedIssue],
	);

	const handleInputChange = useCallback(
		(inputValue: string) => {
			if (inputValue !== inputString) {
				if (inputValue !== undefined && inputValue.trim().length > 0) {
					setOptions([]);
					const { searchString, isExactIssueSearchKey } = getSearchParams(inputValue);
					isExactIssueSearchKey ? setIssueExactKey(searchString) : setIssueExactKey(undefined);

					// to handle copy & paste use case, only when there is an exact issue key
					if (isExactIssueSearchKey) {
						setInputString(inputValue.trim());
						onProjectChanged?.(undefined); // to be improved with POL-10020
						fetchQuery$?.next({
							query: searchString,
							currentExcludedProjectTypes: excludedProjectTypes,
							hideArchivedIssues: hideArchived,
							issueKeySearch: true,
							currentProjectId: undefined,
						});
					} else {
						setInputString(searchString);
						fetchQuery$?.next({
							query: searchString,
							currentExcludedProjectTypes: excludedProjectTypes,
							hideArchivedIssues: hideArchived,
							issueKeySearch: isExactIssueSearchKey,
							currentProjectId: projectId,
						});
					}
				} else {
					// wildcard search when input is undefined or empty
					setIssueExactKey(undefined);
					setInputString(inputValue);
					fetchQuery$?.next({
						query: '%',
						currentProjectId: projectId,
						currentExcludedProjectTypes: excludedProjectTypes,
						hideArchivedIssues: hideArchived,
					});
				}
			}
		},
		[excludedProjectTypes, fetchQuery$, hideArchived, inputString, projectId, onProjectChanged],
	);

	const onMenuOpen = useCallback(() => {
		setMenuIsOpen(true);
		fireUIAnalytics(
			createAnalyticsEvent({ action: 'toggled', actionSubject: 'dropdown' }),
			'issuesList',
		);
	}, [createAnalyticsEvent]);

	const onMenuClose = useCallback(() => {
		setMenuIsOpen(false);
	}, []);

	const groupedOptions = useMemo(() => {
		const defaultOptionSummaryMatchExactly =
			defaultOptions !== undefined &&
			some(defaultOptions.options, (option) => option.item?.summaryText === inputString?.trim());
		return compact([
			createOption !== undefined &&
				inputString !== undefined &&
				inputString.trim().length > 0 &&
				defaultOptionSummaryMatchExactly === false && {
					options: [{ isNew: true, label: inputString.trim() }],
				},
			defaultOptions !== undefined && {
				label: defaultOptions.label,
				options: defaultOptions.options.filter(
					(option) => !option.item?.id || !excludedIssueIds.includes(option.item?.id),
				),
			},
			{
				label: isIdeasSelect
					? formatMessage(messages.optionIdeasGroupSuggestions, { cloudName })
					: formatMessage(
							fg('polaris-issue-terminology-refresh')
								? messages.optionGroupSuggestionsIssueTermRefresh
								: messages.optionGroupSuggestions,
							{ cloudName },
						),
				options: options || [],
			},
		]);
	}, [
		isIdeasSelect,
		cloudName,
		createOption,
		excludedIssueIds,
		defaultOptions,
		formatMessage,
		inputString,
		options,
	]);

	const filterOptionOnlyWhenIssueExactKey = useCallback(
		(option: FilterOption) => option.data?.item?.key === issueExactKey,
		[issueExactKey],
	);

	if (isIdeasSelect) {
		return (
			<Select<IssueOption>
				// @ts-expect-error - TS2322 - Type 'true' is not assignable to type 'false'.
				isMulti
				testId="polaris-lib-issue-select.ui.select"
				isDisabled={isDisabled}
				autoFocus={autoFocus}
				spacing="compact"
				isSearchable
				isLoading={isLoading}
				isClearable
				noOptionsMessage={noOptionsMessage}
				placeholder={
					placeholder !== undefined && placeholder !== null
						? placeholder
						: formatMessage(
								fg('polaris-issue-terminology-refresh')
									? messages.issuePickerPlaceholderIssueTermRefresh
									: messages.issuePickerPlaceholder,
							)
				}
				filterOption={issueExactKey ? filterOptionOnlyWhenIssueExactKey : undefined}
				defaultOptions={internalDefaultOptions}
				onChange={handleChange}
				value={selectedIssue}
				onInputChange={handleInputChange}
				inputValue={inputString}
				components={{
					Option: (props: IssueOptionPropsNext) => (
						<Option {...props} renderIconAfter={renderOptionIconAfter} />
					),
					// Typecheck with project references identifies this as error but existing typecheck is not so doesnt allow ts-expect-error, so used ts-ignore
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore - error TS2322: Type '(props: MultiValueProps<IssueOption>) => React.JSX.Element' is not assignable to type 'ComponentType<MultiValueProps<IssueOption, false, GroupBase<IssueOption>>> | undefined'.
					MultiValue: MultiSelectedOption,
					DropdownIndicator: () => <></>,
					Menu: (props: LoadingMenuProps) => (
						<LoadingMenu {...props} isLoading={isLoading} isIdeasSelect />
					),
				}}
				// @ts-expect-error - TS2322 - Type '({ options: { isNew: boolean; label: any; }[]; label?: undefined; } | { label: string; options: unknown; })[]' is not assignable to type 'readonly ({ item?: { id: number; key: string; img: string; summaryText: string; } | undefined; label: string; value: string; isNew?: boolean | undefined; } | GroupTypeBase<{ item?: { id: number; key: string; img: string; summaryText: string; } | undefined; label: string; value: string; isNew?: boolean | undefined; }...'.
				options={groupedOptions}
				menuIsOpen={menuIsOpen}
				onMenuOpen={onMenuOpen}
				onMenuClose={onMenuClose}
				menuShouldScrollIntoView={false}
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				menuPortalTarget={isAttachedToBody ? document.body : undefined}
				styles={{
					// without properly set zIndex value dropdown with options is cut inside modal dialogs
					// eslint-disable-next-line @typescript-eslint/no-explicit-any -- Ignored via go/DSP-18766
					menuPortal: (base: any) => ({ ...base, zIndex: layers.modal }),
					valueContainer: (base) => ({ ...base, display: 'flex' }),
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					menu: (base: any) => ({ ...base, zIndex: 99 }),
					input: (base) => ({ ...base, flex: 0, height: '36px' }),
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					placeholder: (base: any) => ({
						...base,
						textOverflow: 'ellipsis',
						overflow: 'hidden',
						whiteSpace: 'nowrap',
						maxWidth: '100%',
						position: 'absolute',
					}),
				}}
			/>
		);
	}

	return (
		<Select<IssueOption>
			testId="polaris-lib-issue-select.ui.select"
			isDisabled={isDisabled}
			autoFocus={autoFocus}
			spacing="compact"
			isSearchable
			isLoading={isLoading}
			isClearable
			noOptionsMessage={noOptionsMessage}
			placeholder={
				placeholder !== undefined && placeholder !== null
					? placeholder
					: formatMessage(
							fg('polaris-issue-terminology-refresh')
								? messages.issuePickerPlaceholderIssueTermRefresh
								: messages.issuePickerPlaceholder,
						)
			}
			filterOption={issueExactKey ? filterOptionOnlyWhenIssueExactKey : undefined}
			defaultOptions={internalDefaultOptions}
			onChange={handleChange}
			value={selectedIssue}
			onInputChange={handleInputChange}
			inputValue={inputString}
			components={{
				Option: (props: IssueOptionPropsNext) => (
					<Option {...props} renderIconAfter={renderOptionIconAfter} />
				),
				SingleValue: SelectedOption,
				Menu: (props: LoadingMenuProps) => <LoadingMenu {...props} isLoading={isLoading} />,
			}}
			// @ts-expect-error - TS2322 - Type '({ options: { isNew: boolean; label: any; }[]; label?: undefined; } | { label: string; options: unknown; })[]' is not assignable to type 'readonly ({ item?: { id: number; key: string; img: string; summaryText: string; } | undefined; label: string; value: string; isNew?: boolean | undefined; } | GroupTypeBase<{ item?: { id: number; key: string; img: string; summaryText: string; } | undefined; label: string; value: string; isNew?: boolean | undefined; }...'.
			options={groupedOptions}
			menuIsOpen={menuIsOpen}
			onMenuOpen={onMenuOpen}
			onMenuClose={onMenuClose}
			menuShouldScrollIntoView={false}
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			menuPortalTarget={isAttachedToBody ? document.body : undefined}
			styles={{
				// without properly set zIndex value dropdown with options is cut inside modal dialogs
				// eslint-disable-next-line @typescript-eslint/no-explicit-any -- Ignored via go/DSP-18766
				menuPortal: (base: any) => ({ ...base, zIndex: layers.modal }),
				valueContainer: (base) => ({ ...base, display: 'flex' }),
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				menu: (base: any) => ({ ...base, zIndex: 99 }),
				input: (base) => ({ ...base, flex: 0 }),
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				placeholder: (base: any) => ({
					...base,
					textOverflow: 'ellipsis',
					overflow: 'hidden',
					whiteSpace: 'nowrap',
					maxWidth: '100%',
					position: 'absolute',
				}),
			}}
		/>
	);
};

IssueSelect.defaultProps = {
	isAttachedToBody: false,
};
