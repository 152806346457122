import type { ConfluencePage } from '@atlassian/jira-issue-shared-types/src/common/types/confluence-content-type.tsx';

export type RecentlyVisitedConfluencePageFromServer = {
	id: number;
	lastSeen: number;
	title: string;
	space: string;
	spaceKey: string;
	type: string;
	url: string;
	contentType: string;
	available: boolean;
};

// FETCH_RECENTLY_VISITED_CONFLUENCE_PAGES_REQUEST
export const FETCH_RECENTLY_VISITED_CONFLUENCE_PAGES_REQUEST =
	'FETCH_RECENTLY_VISITED_CONFLUENCE_PAGES_REQUEST';

export const fetchRecentlyVisitedConfluencePagesRequest = () => ({
	type: FETCH_RECENTLY_VISITED_CONFLUENCE_PAGES_REQUEST,
});

// FETCH_RECENTLY_VISITED_CONFLUENCE_PAGES_SUCCESS
export const FETCH_RECENTLY_VISITED_CONFLUENCE_PAGES_SUCCESS =
	'FETCH_RECENTLY_VISITED_CONFLUENCE_PAGES_SUCCESS';

export const fetchRecentlyVisitedConfluencePagesSuccess = (response: ConfluencePage[]) => ({
	type: FETCH_RECENTLY_VISITED_CONFLUENCE_PAGES_SUCCESS,
	payload: response,
});

// FETCH_RECENTLY_VISITED_CONFLUENCE_PAGES_FAILED
export const FETCH_RECENTLY_VISITED_CONFLUENCE_PAGES_FAILED =
	'FETCH_RECENTLY_VISITED_CONFLUENCE_PAGES_FAILED';

export const fetchRecentlyVisitedConfluencePagesFailed = () => ({
	type: FETCH_RECENTLY_VISITED_CONFLUENCE_PAGES_FAILED,
});
