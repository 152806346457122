import type {
	GlanceStatus,
	GlanceIcon,
} from '@atlassian/jira-issue-gira-transformer-types/src/common/types/ecosystem.tsx';

export const CLICK_THROUGH = 'click_through' as const;
export const OPEN_PANEL = 'open_panel' as const;

export type GlanceContent = string;
export type GlanceWorkMode = typeof CLICK_THROUGH | typeof OPEN_PANEL;

export type GlanceUpdate = {
	icon?: GlanceIcon;
	content?: GlanceContent;
	status?: GlanceStatus;
	workMode?: GlanceWorkMode;
};

export type GlanceUiState = {
	appKey: string;
	moduleKey: string;
};

export type GlanceReference = {
	appKey: string;
	moduleKey: string;
};

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type {
	Glance,
	GlanceStatus,
	GlanceIcon,
	ConnectGlanceType,
} from '@atlassian/jira-issue-gira-transformer-types/src/common/types/ecosystem.tsx';
