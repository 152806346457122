import React from 'react';
import type { DocNode } from '@atlaskit/adf-schema';
import { ProviderFactory } from '@atlaskit/editor-common/provider-factory';
import { ReactRenderer } from '@atlaskit/renderer';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import {
	smartLinksDefault,
	onLinkClick,
} from '@atlassian/jira-linking-platform-utils/src/index.tsx';
import { useProfileCardProvider } from '@atlassian/jira-profilecard-provider/src/main.tsx';

export const AIContextRenderer = ({ document }: AIContextRendererProps) => {
	const dataProviders = new ProviderFactory();
	const profileCardProvider = useProfileCardProvider();
	dataProviders.setProvider('profilecardProvider', profileCardProvider);

	return (
		<JSErrorBoundary id="ai-context-renderer" fallback="unmount" teamName="JSM Agent AI">
			<ReactRenderer
				dataProviders={dataProviders}
				smartLinks={smartLinksDefault}
				document={document}
				eventHandlers={{
					link: { onClick: onLinkClick },
				}}
			/>
		</JSErrorBoundary>
	);
};

export type AIContextRendererProps = {
	document: DocNode;
};
