/**
 * @generated SignedSource<<b4e2dea446549fc98b1a39a5b74adb0e>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type voters_issueViewFoundation_VotersItemNew$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutVotesField_IssueViewVotesField">;
  readonly " $fragmentType": "voters_issueViewFoundation_VotersItemNew";
};
export type voters_issueViewFoundation_VotersItemNew$key = {
  readonly " $data"?: voters_issueViewFoundation_VotersItemNew$data;
  readonly " $fragmentSpreads": FragmentRefs<"voters_issueViewFoundation_VotersItemNew">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "voters_issueViewFoundation_VotersItemNew",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "id"
    },
    {
      "kind": "FragmentSpread",
      "name": "ui_issueViewLayoutVotesField_IssueViewVotesField"
    }
  ],
  "type": "JiraVotesField"
};

(node as any).hash = "019fea7d90694c4b125da990ecc8da1b";

export default node;
