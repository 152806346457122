import type { RestIssueType } from '../../services/jira/types.tsx';
import type { IssueType } from '../types.tsx';

export const transformIssueTypeResponse = (rest: RestIssueType): IssueType => ({
	id: String(rest.id),
	name: rest.name,
	entityId: rest.entityId,
	description: rest.description,
	avatarId: String(rest.avatarId),
	hierarchyLevel: rest.hierarchyLevel,
	operation: {
		deletable: rest.operation.deletable,
		editable: rest.operation.editable,
	},
});
