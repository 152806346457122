import type {
	ViewContext,
	UploadContextUnion,
	UserAuth,
	MediaContext,
	GiraMediaContext,
	ReadAttachmentsToken,
	UploadTokenUnion,
	UserToken,
} from '@atlassian/jira-issue-gira-transformer-types/src/common/types/media-context.tsx';
import { getTokenDeltaProps } from '@atlassian/jira-issue-media-provider/src/controllers/create-media-provider/utils.tsx';

const getFirstTokenIfAvailable = (tokensWithFiles: { token: string }[]) =>
	tokensWithFiles.length > 0 ? tokensWithFiles[0].token : null;

export const transformReadAttachmentsToken = (
	readAttachmentsToken: ReadAttachmentsToken,
): ViewContext => {
	const token = getFirstTokenIfAvailable(readAttachmentsToken.tokensWithFiles);
	const tokenProps = typeof token === 'string' ? getTokenDeltaProps(token) : {};

	return {
		clientId: readAttachmentsToken.clientId,
		serviceHost: readAttachmentsToken.endpointUrl,
		token,
		areTokenPermissionsUpToDate: true,
		tokenLifespanInMs: readAttachmentsToken.tokenLifespanInSeconds * 1000,
		tokenIssueTimestamp: Date.now() - 10000, // added 10s buffer to cover network calls
		collection: null,
		tokenDecodeError: tokenProps.tokenDecodeError,
		localTimeDelta: tokenProps.localTimeDelta,
		tokenExpiryTime: tokenProps.tokenExpiryTime,
	};
};

export const transformUploadContext = (
	uploadToken: UploadTokenUnion,
): UploadContextUnion | null => {
	if (
		// @ts-expect-error - TS2339 - Property 'clientId' does not exist on type 'UploadTokenUnion'.
		uploadToken.clientId != null &&
		// @ts-expect-error - TS2339 - Property 'endpointUrl' does not exist on type 'UploadTokenUnion'.
		uploadToken.endpointUrl != null &&
		// @ts-expect-error - TS2339 - Property 'token' does not exist on type 'UploadTokenUnion'.
		uploadToken.token != null &&
		// @ts-expect-error - TS2339 - Property 'tokenDurationInMins' does not exist on type 'UploadTokenUnion'.
		uploadToken.tokenDurationInMins != null &&
		// @ts-expect-error - TS2339 - Property 'targetCollection' does not exist on type 'UploadTokenUnion'.
		uploadToken.targetCollection != null
	) {
		return {
			// @ts-expect-error - TS2339 - Property 'clientId' does not exist on type 'UploadTokenUnion'.
			clientId: uploadToken.clientId,
			// @ts-expect-error - TS2339 - Property 'endpointUrl' does not exist on type 'UploadTokenUnion'.
			serviceHost: uploadToken.endpointUrl,
			// @ts-expect-error - TS2339 - Property 'token' does not exist on type 'UploadTokenUnion'.
			token: uploadToken.token,
			// @ts-expect-error - TS2339 - Property 'tokenDurationInMins' does not exist on type 'UploadTokenUnion'.
			tokenLifespanInMs: uploadToken.tokenDurationInMins * 60 * 1000,
			// @ts-expect-error - TS2339 - Property 'targetCollection' does not exist on type 'UploadTokenUnion'.
			collection: uploadToken.targetCollection,
			tokenIssueTimestamp: Date.now() - 10000, // added 10s buffer to cover network calls
		};
	}
	// @ts-expect-error - TS2339 - Property 'error' does not exist on type 'UploadTokenUnion'.
	if (uploadToken.error != null) {
		// @ts-expect-error - TS2339 - Property 'error' does not exist on type 'UploadTokenUnion'.
		return { error: uploadToken.error };
	}
	return null;
};

export const transformUserContext = (userToken: UserToken): UserAuth => ({
	clientId: userToken.id,
	token: userToken.token,
	tokenLifespanInMs: Number(userToken.tokenDurationInMins) * 60 * 1000,
	tokenIssueTimestamp: Date.now() - 10000, // added 10s buffer to cover network calls
});

export const transformData = ({
	readToken,
	uploadToken,
	userToken,
}: GiraMediaContext): MediaContext => ({
	viewContext: readToken ? transformReadAttachmentsToken(readToken) : null,
	uploadContext: uploadToken ? transformUploadContext(uploadToken) : null,
	userAuth: userToken ? transformUserContext(userToken) : null,
});
