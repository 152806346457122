/**
 * @generated SignedSource<<29239e2b6c5e784f8aa5c64e85783d42>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_issueViewLayoutSingleLineTextField_IssueViewSingleLineTextField$data = {
  readonly __typename: "JiraSingleLineTextField";
  readonly fieldId: string;
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"singleLineText_issueFieldSingleLineTextInlineEditFull_SingleLineTextInlineEditView_fragmentRef" | "ui_issueViewLayoutFieldHeading_IssueViewFieldHeading">;
  readonly " $fragmentType": "ui_issueViewLayoutSingleLineTextField_IssueViewSingleLineTextField";
};
export type ui_issueViewLayoutSingleLineTextField_IssueViewSingleLineTextField$key = {
  readonly " $data"?: ui_issueViewLayoutSingleLineTextField_IssueViewSingleLineTextField$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewLayoutSingleLineTextField_IssueViewSingleLineTextField">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ui_issueViewLayoutSingleLineTextField_IssueViewSingleLineTextField",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "ui_issueViewLayoutFieldHeading_IssueViewFieldHeading"
    },
    {
      "kind": "FragmentSpread",
      "name": "singleLineText_issueFieldSingleLineTextInlineEditFull_SingleLineTextInlineEditView_fragmentRef"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    },
    {
      "kind": "ScalarField",
      "name": "type"
    },
    {
      "kind": "ScalarField",
      "name": "__typename"
    }
  ],
  "type": "JiraSingleLineTextField"
};

(node as any).hash = "6480fbd7d29c2dace96425018a8bac47";

export default node;
