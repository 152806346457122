/**
 * @generated SignedSource<<02f164da7183161ef2a4218fc267af7d>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type buildData_softwareReleasesVersionDetailIssueList_jiraIssueDevSummary$data = {
  readonly build: {
    readonly overall: {
      readonly failedBuildCount: number;
      readonly successfulBuildCount: number;
      readonly unknownBuildCount: number;
    };
  };
  readonly " $fragmentType": "buildData_softwareReleasesVersionDetailIssueList_jiraIssueDevSummary";
};
export type buildData_softwareReleasesVersionDetailIssueList_jiraIssueDevSummary$key = {
  readonly " $data"?: buildData_softwareReleasesVersionDetailIssueList_jiraIssueDevSummary$data;
  readonly " $fragmentSpreads": FragmentRefs<"buildData_softwareReleasesVersionDetailIssueList_jiraIssueDevSummary">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "buildData_softwareReleasesVersionDetailIssueList_jiraIssueDevSummary",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "concreteType": "JiraIssueBuildDevSummaryContainer",
        "kind": "LinkedField",
        "name": "build",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "concreteType": "JiraIssueBuildDevSummary",
              "kind": "LinkedField",
              "name": "overall",
              "plural": false,
              "selections": [
                {
                  "kind": "RequiredField",
                  "field": {
                    "kind": "ScalarField",
                    "name": "failedBuildCount"
                  },
                  "action": "THROW",
                  "path": "build.overall.failedBuildCount"
                },
                {
                  "kind": "RequiredField",
                  "field": {
                    "kind": "ScalarField",
                    "name": "successfulBuildCount"
                  },
                  "action": "THROW",
                  "path": "build.overall.successfulBuildCount"
                },
                {
                  "kind": "RequiredField",
                  "field": {
                    "kind": "ScalarField",
                    "name": "unknownBuildCount"
                  },
                  "action": "THROW",
                  "path": "build.overall.unknownBuildCount"
                }
              ]
            },
            "action": "THROW",
            "path": "build.overall"
          }
        ]
      },
      "action": "THROW",
      "path": "build"
    }
  ],
  "type": "JiraIssueDevSummary"
};

(node as any).hash = "51c2585ef90bb27a6d71bf4d7670a32b";

export default node;
