/**
 * @generated SignedSource<<7aadd33622a8a3fcc1a4c1d7b52e529f>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type AccountStatus = "active" | "closed" | "inactive" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type people_issueFieldPeopleEditviewFull_PeopleEditViewWithFieldOptionsFragmentOld_suggestionsFragmentRef$data = {
  readonly node: {
    readonly users?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly accountId: string;
          readonly accountStatus: AccountStatus;
          readonly email?: string | null | undefined;
          readonly id: string;
          readonly name: string;
          readonly picture: AGG$URL;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "people_issueFieldPeopleEditviewFull_PeopleEditViewWithFieldOptionsFragmentOld_suggestionsFragmentRef";
};
export type people_issueFieldPeopleEditviewFull_PeopleEditViewWithFieldOptionsFragmentOld_suggestionsFragmentRef$key = {
  readonly " $data"?: people_issueFieldPeopleEditviewFull_PeopleEditViewWithFieldOptionsFragmentOld_suggestionsFragmentRef$data;
  readonly " $fragmentSpreads": FragmentRefs<"people_issueFieldPeopleEditviewFull_PeopleEditViewWithFieldOptionsFragmentOld_suggestionsFragmentRef">;
};

import people_peopleSearchRefetchQuery_graphql from './people_peopleSearchRefetchQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = [
  "node",
  "users"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": 50,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "id"
    },
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "searchBy"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": people_peopleSearchRefetchQuery_graphql
    }
  },
  "name": "people_issueFieldPeopleEditviewFull_PeopleEditViewWithFieldOptionsFragmentOld_suggestionsFragmentRef",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "id"
        }
      ],
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": "users",
              "args": [
                {
                  "kind": "Variable",
                  "name": "searchBy",
                  "variableName": "searchBy"
                }
              ],
              "concreteType": "JiraUserConnection",
              "kind": "LinkedField",
              "name": "__people_issueFieldPeopleEditviewFull_users_connection",
              "plural": false,
              "selections": [
                {
                  "concreteType": "JiraUserEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "ScalarField",
                          "name": "id"
                        },
                        {
                          "kind": "ScalarField",
                          "name": "accountId"
                        },
                        {
                          "kind": "ScalarField",
                          "name": "name"
                        },
                        {
                          "kind": "ScalarField",
                          "name": "picture"
                        },
                        {
                          "kind": "ScalarField",
                          "name": "accountStatus"
                        },
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "kind": "ScalarField",
                              "name": "email"
                            }
                          ],
                          "type": "AtlassianAccountUser"
                        },
                        {
                          "kind": "ScalarField",
                          "name": "__typename"
                        }
                      ]
                    },
                    {
                      "kind": "ScalarField",
                      "name": "cursor"
                    }
                  ]
                },
                {
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "name": "endCursor"
                    },
                    {
                      "kind": "ScalarField",
                      "name": "hasNextPage"
                    }
                  ]
                }
              ]
            }
          ],
          "type": "JiraPeopleField"
        }
      ]
    }
  ],
  "type": "Query"
};
})();

(node as any).hash = "d213768811d2d06473ca393994f12052";

export default node;
