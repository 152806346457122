import React, { useRef, useEffect, memo } from 'react';
import { useAIEventsInstrumentation, type AIEventErrorAttributes } from '@atlassian/ai-analytics';
import { getAiExperienceName } from '../../common/analytics.tsx';
import type { AiContextServiceResource } from '../../common/types.tsx';

type Props = {
	children: React.ReactNode;
	resource: AiContextServiceResource;
};

const ServiceAIAnalyticsWrapper = ({ children, resource }: Props) => {
	const { trackAIInteractionInit, trackAIResultView, trackAIResultError } =
		useAIEventsInstrumentation();
	const aiInitEvent = useRef(false);
	const aiResultViewEvent = useRef(false);
	const aiResultErrorEvent = useRef(false);
	const { error, data } = resource;

	useEffect(() => {
		if (!aiInitEvent.current) {
			trackAIInteractionInit();
			aiInitEvent.current = true;
		}

		if (data && !aiResultViewEvent.current) {
			trackAIResultView({
				doesNotMeetMAUCriteria: true,
				attributes: {
					aiExperienceName: getAiExperienceName(data),
				},
			});
			aiResultViewEvent.current = true;
		}
	}, [data, trackAIInteractionInit, trackAIResultView]);

	useEffect(() => {
		if (error && !aiResultErrorEvent.current) {
			const payload: AIEventErrorAttributes = {
				aiErrorMessage: error.message || 'Error loading AI context',
				aiErrorCode: 'statusCode' in error ? error.statusCode : 500,
			};
			trackAIResultError(payload);
			aiResultErrorEvent.current = true;
		}
	}, [error, trackAIResultError]);

	return <>{children}</>;
};

export default memo(ServiceAIAnalyticsWrapper);
