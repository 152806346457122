import React, { memo, useCallback, useEffect } from 'react';
import { useWrappedRealtimeHandlerWithQueue } from '@atlassian/jira-polaris-lib-realtime-queue/src/index.tsx';
import { useRealtimeProjectChannel } from '@atlassian/jira-polaris-lib-realtime/src/common/utils.tsx';
import type { GetViewResponse } from '@atlassian/jira-polaris-remote-view/src/services/polaris-api/fetch-view/index.tsx';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { RealtimeEvent } from '@atlassian/jira-realtime/src/common/types/events.tsx';
import Realtime from '@atlassian/jira-realtime/src/main.tsx';
import {
	PREFIXED_APP_ID,
	VIEW_CREATED_EVENT,
	VIEW_DELETED_EVENT,
	VIEW_EVENTS,
	VIEW_UPDATED_EVENT,
	VIEWSET_CREATED_EVENT,
	VIEWSET_DELETED_EVENT,
	VIEWSET_UPDATED_EVENT,
} from './constants.tsx';
import type { ViewRealtimeEvent, PolarisViewEventPayload } from './types.tsx';
import { isViewCommentEvent, isViewEvent, isViewSetEvent } from './utils.tsx';
import { validateViewPayload, enrichViewPayload, onValidationError } from './validate/index.tsx';

type Props = {
	projectId: string;
	onRefreshViewComments: (viewId: string) => void;
	onDeleteView: (id: number, uuid: string, userId: string) => void;
	onCreateView: (id: number, uuid: string, view?: GetViewResponse | null) => void;
	onUpdateView: (arg: PolarisViewEventPayload) => void;
	onUpdateViewArrangementInfo: (id: number, uuid: string) => void;
	onUpdateViewsRanks: () => void;
	onUpdateViewSetsRanks: () => void;
	onCreateViewSet: (id: number, uuid: string) => void;
	onDeleteViewSet: (id: number, uuid: string) => void;
	onUpdateViewSet: (id: number, uuid: string) => void;
};

export const ViewRealtimeHandler = memo(
	({
		projectId,
		onRefreshViewComments,
		onDeleteView,
		onCreateView,
		onUpdateView,
		onUpdateViewArrangementInfo,
		onUpdateViewsRanks,
		onCreateViewSet,
		onDeleteViewSet,
		onUpdateViewSet,
		onUpdateViewSetsRanks,
	}: Props) => {
		const projectChannels = useRealtimeProjectChannel(projectId);
		const { createAnalyticsEvent } = useAnalyticsEvents();

		const onReceive = useCallback(
			(event: ViewRealtimeEvent | RealtimeEvent) => {
				if (isViewCommentEvent(event)) {
					const viewId = event.payload.viewAri || event.payload.viewId;
					onRefreshViewComments(viewId);
				}

				if (isViewEvent(event)) {
					const { viewId, viewUUID, changes, userId, view } = event.payload;
					switch (event.type) {
						case VIEW_DELETED_EVENT:
							onDeleteView(viewId, viewUUID, userId);
							break;
						case VIEW_CREATED_EVENT: {
							const viewFromPayload =
								view &&
								validateViewPayload(
									enrichViewPayload(view),
									createAnalyticsEvent,
									onValidationError,
								)
									? enrichViewPayload(view)
									: null;
							onCreateView(viewId, viewUUID, viewFromPayload);
							break;
						}
						case VIEW_UPDATED_EVENT:
							if (changes?.includes('*')) {
								const viewFromPayload =
									view &&
									validateViewPayload(
										enrichViewPayload(view),
										createAnalyticsEvent,
										onValidationError,
									)
										? enrichViewPayload(view)
										: null;
								onUpdateView({ ...event.payload, view: viewFromPayload });
								return;
							}

							if (changes?.includes('arrangement-info')) {
								onUpdateViewArrangementInfo(viewId, viewUUID);
							}

							if (changes?.includes('rank')) {
								onUpdateViewsRanks();
							}

							break;
						default:
							break;
					}
					return;
				}

				if (isViewSetEvent(event)) {
					const { viewSetId, viewSetUUID, changes } = event.payload;
					switch (event.type) {
						case VIEWSET_DELETED_EVENT:
							onDeleteViewSet(viewSetId, viewSetUUID);
							break;
						case VIEWSET_CREATED_EVENT:
							onCreateViewSet(viewSetId, viewSetUUID);
							break;
						case VIEWSET_UPDATED_EVENT:
							if (changes?.includes('*')) {
								onUpdateViewSet(viewSetId, viewSetUUID);
								return;
							}

							if (changes?.includes('rank')) {
								onUpdateViewSetsRanks();
							}
							break;
						default:
							break;
					}
				}
			},
			[
				onRefreshViewComments,
				onDeleteView,
				createAnalyticsEvent,
				onCreateView,
				onUpdateView,
				onUpdateViewArrangementInfo,
				onUpdateViewsRanks,
				onDeleteViewSet,
				onCreateViewSet,
				onUpdateViewSet,
				onUpdateViewSetsRanks,
			],
		);

		const [onReceiveWithQueue, clearEvents] = useWrappedRealtimeHandlerWithQueue(onReceive);

		useEffect(() => () => clearEvents(), [clearEvents]);

		return (
			<Realtime
				appId={PREFIXED_APP_ID}
				channels={projectChannels}
				events={VIEW_EVENTS}
				onReceive={onReceiveWithQueue}
			/>
		);
	},
);
