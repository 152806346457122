import {
	type IssueLink,
	type IssueLinks,
	type IssueLinkDirection,
	type LinkedIssue,
	type NormalizedIssueLinks,
	type NormalizedLinkedIssues,
	INWARD_LINK_DIRECTION,
	OUTWARD_LINK_DIRECTION,
} from '@atlassian/jira-issue-shared-types/src/common/types/linked-issue-type.tsx';
import type { JiraIssueLinkDirection } from '@atlassian/jira-relay/src/__generated__/mainIssueAggQuery.graphql';

import { type IssueKey, toIssueId, toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { AggIssueLink, AggLinkedIssue, AggResponseData } from '../types/issue-type.tsx';

import { transformAggAssociatedIssueToAssociatedIssue } from './associated-issue-transformer.tsx';

type IssueLinksMeta = {
	issueKey: string;
	isResolved: boolean;
};

// For type safety, since `LinkedIssue` requires `issueKey`
const transformAggAssociatedIssueToLinkedIssue = (issue: AggLinkedIssue): LinkedIssue => ({
	...transformAggAssociatedIssueToAssociatedIssue(issue),
	issueKey: issue.key,
});

// Throw an error if someone adds a new direction on the server and we receive it
const transformAggIssueLinkDirectionToIssueLinkDirection = (
	direction: JiraIssueLinkDirection,
): IssueLinkDirection => {
	switch (direction) {
		case INWARD_LINK_DIRECTION:
			return INWARD_LINK_DIRECTION;
		case OUTWARD_LINK_DIRECTION:
			return OUTWARD_LINK_DIRECTION;
		default:
			throw new Error('Invalid issue link direction.');
	}
};

const transformAggIssueLinkToIssueLink = (issueLink: AggIssueLink): IssueLink => ({
	id: toIssueId(issueLink.issueLinkId),
	direction: transformAggIssueLinkDirectionToIssueLinkDirection(issueLink.direction),
	linkedIssueKey: issueLink.issue?.key || '',
	typeId: issueLink.type.linkTypeId,
	typeName: issueLink.relationName,
});

export const transformAggResponseToIssueLinks = (data: AggResponseData): IssueLinks | undefined => {
	/**
	 * If the issue links are disabled, the `issueLinks` returned by AGG will be null. This happens in the following cases:
	 * - Issue links are deactivated globally
	 * - Issue link field is hidden in the global field configuration AND project type is CMP
	 */
	const serverIssueLinksRoot = data.jira.issueByKey?.issueLinks;
	if (serverIssueLinksRoot == null) {
		return undefined;
	}

	/**
	 * Map of links containing the current issue's relation to the linked issue.
	 * Keyed with the issue link ID instead of the linked issue's ID since
	 * an individual issue can be linked multiple times. See `linkedIssueKeys`.
	 */
	const issueLinks: NormalizedIssueLinks = {};
	const linkedIssueMetas: IssueLinksMeta[] = [];

	/**
	 * Map of the issues linked to the current issue; keyed with the linked issueKey.
	 * The linked issue data is transformed in a way that's usable for Bento.
	 * See `transformAggAssociatedIssueToLinkedIssue()`.
	 */
	const linkedIssues: NormalizedLinkedIssues = {};

	// Make sure we get unique keys only
	// `issueLinks` can have duplicate issues from different `relationName`s
	const linkedIssueKeys: Set<IssueKey> = new Set();

	const serverIssueLinks = serverIssueLinksRoot?.edges;

	if (serverIssueLinks != null) {
		serverIssueLinks.forEach((edge) => {
			if (edge?.node == null) return;

			const { node } = edge;
			const { issue, issueLinkId, relationName } = node;

			if (relationName && issue != null) {
				issueLinks[issueLinkId] = transformAggIssueLinkToIssueLink(node);
				linkedIssues[issue.key] = transformAggAssociatedIssueToLinkedIssue(issue);
				linkedIssueKeys.add(toIssueKey(issue.key));

				linkedIssueMetas.push({
					isResolved: issue.isResolved ?? false,
					issueKey: issue.key,
				});
			}
		});
	}

	return {
		issueLinks,
		linkedIssues,
		linkedIssueKeys: [...linkedIssueKeys], // Convert back to `IssueKey[]`
		linkedIssueMetas,
	};
};
