import React from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { CopyTextFieldWithLabel } from '@atlassian/jira-platform-copy-text-field/src/index.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';

export const CopyIssueKey = () => {
	const issueKey = useIssueKey();
	const { formatMessage } = useIntl();
	return (
		<>
			<CopyTextFieldWithLabel
				label={formatMessage(
					fg('jira-issue-terminology-refresh-m3')
						? messages.copyIssueKeyLabelIssueTermRefresh
						: messages.copyIssueKeyLabel,
				)}
				name="copy-issue-key"
				id="copy-issue-key"
				value={issueKey}
				onCopyClick={(_, analyticsEvent) => {
					fireUIAnalytics(analyticsEvent, 'createCommitIssueKeyCopyButton');
				}}
			/>
		</>
	);
};

export default CopyIssueKey;
