import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';

export const issueViewSidePadding = 4 * gridSize;

// DACI explaining thinking behind these calculations: https://hello.atlassian.net/wiki/spaces/JIE/pages/654624200/DACI+Have+all+issue+page+area+scrollable+while+maintaining+horizontal+centering+of+page+content
const getScrollContainerPaddingSide = (issueMaxWidth: number) =>
	`calc((100% - ${issueMaxWidth}px) / 2)`;

export const getIssueContainerPaddingSide = (propName: string, issueMaxWidth: number) => `
        ${propName}: ${getScrollContainerPaddingSide(
					issueMaxWidth,
				)}; /* for browsers which do not support max(); negative padding is ignored by browsers */
        ${propName}: max(${getScrollContainerPaddingSide(
					issueMaxWidth,
				)}, 0px); /* for browsers which support max() */

        .msie-11 & { /* IE11 is the only browser which does not ignore negative padding */
            ${propName}: 0;
        }
    `;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SingleColumnSectionWrapper = styled.div<{ issueMaxWidth: number }>({
	flexShrink: 0,

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingLeft: (props) =>
		props.issueMaxWidth !== undefined ? `calc((100% - ${props.issueMaxWidth}px) / 2)` : undefined,

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	paddingRight: (props) =>
		props.issueMaxWidth !== undefined ? `calc((100% - ${props.issueMaxWidth}px) / 2)` : undefined,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SingleColumnSectionWrapperInner = styled.div({
	padding: `0 ${token('space.400', '32px')}`,
});
