import { Component, type ReactNode } from 'react';

type Props = {
	children: (stackIndex: number) => ReactNode;
};

type State = {
	stackIndex: number;
};

const isAuiDialogEvent = (ev: unknown) => {
	if (!(ev instanceof CustomEvent)) return false;

	const hasAuiDialogElement = ev.detail?.layerElement?.classList?.contains('aui-dialog2');

	return hasAuiDialogElement;
};

// eslint-disable-next-line jira/react/no-class-components
export default class AuiModalStackIndex extends Component<Props, State> {
	state = {
		stackIndex: 0,
	};

	componentDidMount() {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.addEventListener('legacyJiraDialogBeforeShow', this.incrementStackIndex);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.addEventListener('legacyJiraDialogBeforeHide', this.decrementStackIndex);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.addEventListener('on-before-aui-layer-show', this.incrementStackIndexWhenAuiDialog);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.addEventListener('on-before-aui-layer-hide', this.decrementStackIndexWhenAuiDialog);
	}

	componentWillUnmount() {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.removeEventListener('legacyJiraDialogBeforeShow', this.incrementStackIndex);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.removeEventListener('legacyJiraDialogBeforeHide', this.decrementStackIndex);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.removeEventListener('on-before-aui-layer-show', this.incrementStackIndexWhenAuiDialog);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.removeEventListener('on-before-aui-layer-hide', this.decrementStackIndexWhenAuiDialog);
	}

	incrementStackIndex = () => {
		this.setState((oldState) => ({ stackIndex: oldState.stackIndex + 1 }));
	};

	decrementStackIndex = () => {
		this.setState((oldState) => ({ stackIndex: oldState.stackIndex - 1 }));
	};

	incrementStackIndexWhenAuiDialog = (ev: unknown) => {
		if (!isAuiDialogEvent(ev)) return;
		this.incrementStackIndex();
	};

	decrementStackIndexWhenAuiDialog = (ev: unknown) => {
		if (!isAuiDialogEvent(ev)) return;
		this.decrementStackIndex();
	};

	render() {
		/**
		 * Currently not all event listeners are being triggered, so the stackIndex
		 * smaller then 1 is being blocked.
		 * https://hello.atlassian.net/wiki/spaces/JSDee/pages/1781258277/Bento+Modal+View+-+FocusLock+Problem
		 */
		return this.props.children(this.state.stackIndex);
	}
}
