import React, { useCallback, useMemo, useState } from 'react';
import { graphql, useFragment, useMutation } from 'react-relay';
import editMessages from '@atlassian/jira-common-components-inline-edit/src/messages.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useInlineEditFieldInjections } from '@atlassian/jira-issue-field-injections/src/controllers/inline-edit-injections-context/index.tsx';
import { useFieldInlineEditActions } from '@atlassian/jira-issue-field-inline-edit-actions/src/controllers/index.tsx';
import type { OnSubmitCallbacks } from '@atlassian/jira-issue-field-inline-edit-actions/src/controllers/types.tsx';
import type { ValidationFieldProps } from '@atlassian/jira-issue-field-inline-edit-lite/src/ui/field-inline-edit-lite/types.tsx';
import { FieldInlineEditLiteWithEntryPoint } from '@atlassian/jira-issue-field-inline-edit-lite/src/ui/index.tsx';
import { SingleUserPickerWithProfileCardReadView } from '@atlassian/jira-issue-field-single-user-picker-readview-full/src/ui/single-user-picker/index.tsx';
import type { AggUser } from '@atlassian/jira-issue-user-picker-edit-view/src/common/types.tsx';
import SingleUserPickerEditViewEntryPoint from '@atlassian/jira-issue-user-picker-edit-view/src/entrypoint.tsx';
import type { SingleUserPickerEditViewProps } from '@atlassian/jira-issue-user-picker-edit-view/src/ui/single-user-picker/types.tsx';
import { transformAggToUserOption } from '@atlassian/jira-issue-user-picker-edit-view/src/utils/transform-agg-to-user-option/index.tsx';
import type { singleUserPicker_issueFieldSingleUser_SingleUserPickerField_Mutation as UserPickerMutation } from '@atlassian/jira-relay/src/__generated__/singleUserPicker_issueFieldSingleUser_SingleUserPickerField_Mutation.graphql';
import type { singleUserPicker_issueFieldSingleUserPickerInlineEditFull_SingleUserPickerInlineEditView_fragmentRef$key as UserPickerFieldFragment } from '@atlassian/jira-relay/src/__generated__/singleUserPicker_issueFieldSingleUserPickerInlineEditFull_SingleUserPickerInlineEditView_fragmentRef.graphql';
import type { singleUserPicker_issueFieldSingleUserPickerInlineEditFull_SingleUserPickerInlineEditViewWithIsEditable_fragmentRef$key as UserPickerIsEditableFragment } from '@atlassian/jira-relay/src/__generated__/singleUserPicker_issueFieldSingleUserPickerInlineEditFull_SingleUserPickerInlineEditViewWithIsEditable_fragmentRef.graphql';
import messages from './messages.tsx';
import type {
	SingleUserPickerInlineEditViewWithIsEditableProps,
	SingleUserPickerInlineEditViewProps,
} from './types.tsx';

const isEqualUser = (a: AggUser | null, b: AggUser | null) => a?.id === b?.id;

/**
 * Inline edit will handle the switching behaviour between the 'readView' and 'editView' components. This variant allows
 * consumers to define their own value to determine whether the field is editable.
 *
 * In most cases consumers should use `SingleUserPickerInlineEditView` which will enforce that the user has permission to
 * edit the field within the issue view. However, this component can be used for experiences that have differing
 * permissions or want finer control for how this data is retrieved, e.g. lazy loading editability.
 *
 * @param props [SingleUserPickerInlineEditViewWithIsEditableProps](./types.tsx)
 */
export const SingleUserPickerInlineEditViewWithIsEditable = ({
	attributes,
	spacing = 'compact',
	editViewPopup,
	editViewPopupAlignBlock,
	fragmentRef,
	isEditing: startWithEditViewOpen = false,
	menuPosition,
	onSubmit,
	onSubmitFailed,
	onSubmitSucceeded,
	onCancel,
	onEdit,
	isEditable,
	readViewFitContainerHeight,
	noneOptionLabel,
	profileCardAction,
	isTruncated = true,
	portalElement,
}: SingleUserPickerInlineEditViewWithIsEditableProps) => {
	const { formatMessage } = useIntl();

	const data = useFragment<UserPickerIsEditableFragment>(
		graphql`
			fragment singleUserPicker_issueFieldSingleUserPickerInlineEditFull_SingleUserPickerInlineEditViewWithIsEditable_fragmentRef on JiraSingleSelectUserPickerField {
				id
				fieldId
				type
				name
				...singleUserPicker_issueFieldSingleUserPickerReadviewFull_SingleUserPickerWithProfileCardReadView_fragmentRef
				user {
					accountId
					name
					picture
					accountStatus
					id
				}
				fieldConfig {
					isRequired
				}
			}
		`,
		fragmentRef,
	);

	const { overriding } = useInlineEditFieldInjections();

	const { id: uniqueFieldId, fieldId, type, name, user, fieldConfig } = data;
	const isFieldRequired = useMemo(
		() => fieldConfig?.isRequired ?? false,
		[fieldConfig?.isRequired],
	);

	const fieldName = useMemo(
		() =>
			fg('relay-migration-issue-fields-reporter') || fg('relay-migration-issue-fields-user-fg')
				? overriding.overrideLabel(name)
				: name,
		[name, overriding],
	);

	const isFieldEditable = useMemo(
		() =>
			fg('relay-migration-issue-fields-reporter') || fg('relay-migration-issue-fields-user-fg')
				? overriding.overrideIsEditable(isEditable)
				: isEditable,
		[isEditable, overriding],
	);

	const [commit] = useMutation<UserPickerMutation>(graphql`
		mutation singleUserPicker_issueFieldSingleUser_SingleUserPickerField_Mutation(
			$input: JiraUpdateSingleSelectUserPickerFieldInput!
		) @raw_response_type {
			jira @optIn(to: ["JiraIssueFieldMutations"]) {
				updateSingleSelectUserPickerField(input: $input) {
					success
					errors {
						message
					}
					field {
						user {
							accountId
							name
							picture
							accountStatus
							id
						}
					}
				}
			}
		}
	`);

	const initialValue = user ?? null;
	const [updatedValue, setUpdatedValue] = useState<AggUser | null>(initialValue);

	const handleSubmit = useCallback(
		(newUser: AggUser | null, { onSuccess, onFail }: OnSubmitCallbacks) => {
			onSubmit?.(newUser);
			commit({
				variables: {
					input: {
						id: uniqueFieldId,
						operation: {
							operation: 'SET',
							id: newUser?.id ?? null,
						},
					},
				},
				onCompleted: (mutationData) => {
					if (mutationData.jira?.updateSingleSelectUserPickerField?.success) {
						onSuccess();
					} else {
						onFail();
					}
				},
				onError(error) {
					onFail(error);
				},
				optimisticResponse: {
					jira: {
						updateSingleSelectUserPickerField: {
							success: true,
							errors: null,
							field: {
								id: uniqueFieldId,
								user: newUser
									? {
											id: newUser.id,
											accountId: newUser.accountId,
											accountStatus: newUser.accountStatus,
											name: newUser.name,
											picture: newUser.picture,
											__typename: 'AtlassianAccountUser',
										}
									: null,
							},
						},
					},
				},
			});
		},
		[commit, uniqueFieldId, onSubmit],
	);

	const {
		handleCancel,
		handleEdit,
		handleConfirm,
		handleChangeAndConfirm,
		hasServerValidationError,
		invalidMessage,
		isEditing,
	} = useFieldInlineEditActions({
		attributes,
		fieldId,
		fieldName,
		fieldType: type,
		initialValue,
		isValueEqual: isEqualUser,
		onCancel,
		onEdit,
		onSubmit: handleSubmit,
		onSubmitSucceeded,
		onSubmitFailed,
		onUpdateValue: setUpdatedValue,
		startWithEditViewOpen,
		updatedValue,
	});

	// #region Read view
	const finalNoneOptionLabel = noneOptionLabel ?? formatMessage(messages.noneOption);
	const renderReadView = useCallback(
		() => (
			<SingleUserPickerWithProfileCardReadView
				fragmentRef={data}
				emptyUserLabel={finalNoneOptionLabel}
				profileCardAction={profileCardAction}
				isTruncated={isTruncated}
			/>
		),
		[data, profileCardAction, finalNoneOptionLabel, isTruncated],
	);
	// #endregion

	const getEditViewProps = (fieldProps: ValidationFieldProps): SingleUserPickerEditViewProps => ({
		...fieldProps,
		fieldId: uniqueFieldId,
		autoFocus: true,
		value: updatedValue ? transformAggToUserOption(updatedValue) : null,
		onChange: handleChangeAndConfirm,
		onClear: () => handleChangeAndConfirm(null),
		spacing,
		noneOptionLabel: finalNoneOptionLabel,
		includeNoneOption: !isFieldRequired,
		isClearable: !isFieldRequired,
		menuPosition,
		portalElement,
		ariaLabel: fieldName,
	});

	const editButtonLabel = formatMessage(editMessages.extendedEditButtonLabel, {
		inputFieldValue: initialValue?.name ?? finalNoneOptionLabel,
		fieldName,
	});

	return (
		<FieldInlineEditLiteWithEntryPoint
			editViewPopup={editViewPopup}
			editViewPopupAlignBlock={editViewPopupAlignBlock}
			editViewEntryPoint={SingleUserPickerEditViewEntryPoint}
			editViewEntryPointParams={{}}
			getEditViewProps={getEditViewProps}
			fieldName={fieldName}
			hasUnsubmittedChanges={hasServerValidationError}
			invalidMessage={invalidMessage}
			isEditing={isEditing}
			isEditable={isFieldEditable}
			onCancel={handleCancel}
			onConfirm={handleConfirm}
			onEdit={handleEdit}
			readView={renderReadView}
			hideActionButtons
			editButtonLabel={editButtonLabel}
			readViewFitContainerHeight={readViewFitContainerHeight}
		/>
	);
};

/**
 * Inline edit will handle the switching behaviour between the 'readView' and 'editView' components.
 *
 * @param props [SingleUserPickerInlineEditViewProps](./types.tsx)
 */
export const SingleUserPickerInlineEditView = ({
	fragmentRef,
	...props
}: SingleUserPickerInlineEditViewProps) => {
	const data = useFragment<UserPickerFieldFragment>(
		graphql`
			fragment singleUserPicker_issueFieldSingleUserPickerInlineEditFull_SingleUserPickerInlineEditView_fragmentRef on JiraSingleSelectUserPickerField {
				...singleUserPicker_issueFieldSingleUserPickerInlineEditFull_SingleUserPickerInlineEditViewWithIsEditable_fragmentRef
				fieldConfig {
					isEditable
				}
			}
		`,
		fragmentRef,
	);

	return (
		<SingleUserPickerInlineEditViewWithIsEditable
			{...props}
			fragmentRef={data}
			isEditable={data.fieldConfig?.isEditable ?? false}
		/>
	);
};
