import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	affectedServices: {
		id: 'issue-history.history-items.history-item.affected-services-history-item.affected-services',
		defaultMessage: 'Affected services',
		description:
			'subject of an affected service update event that will be bolded. Eg: John added <bold>Affected services</bold>',
	},
	added: {
		id: 'issue-history.history-items.history-item.affected-services-history-item.added',
		defaultMessage: 'added {services}',
		description:
			'Description when services are added. {services} corresponds to the bolded text in the key issue-history.history-items.history-item.services-history-item.affected-services',
	},
	removed: {
		id: 'issue-history.history-items.history-item.affected-services-history-item.removed',
		defaultMessage: 'removed {services}',
		description:
			'Description when affected services are removed. {services} corresponds to the bolded text in the key issue-history.history-items.history-item.services-history-item.affected-services',
	},
});
