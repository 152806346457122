import { combineEpics, type Epic } from 'redux-observable';
import errorHandlerEpicMiddleware from '@atlassian/jira-errors-handling/src/utils/epic-wrapper.tsx';
import { APP_ID } from '../model/constants.tsx';
import type { Action } from '../state/actions/index.tsx';
import type { State } from '../state/reducers/types.tsx';
import { epic as analytics } from './analytics/analytics-epic.tsx';
import { epic as analyticsScheduler } from './analytics/analytics-schedule-epic.tsx';
import { epic as changeTab } from './change-tab/index.tsx';
import { epic as fetchDetails, optOutDeploymentDetailDiscovery } from './fetch-details/index.tsx';
import { epic as initApp } from './init/index.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type StateEpic = Epic<any, State>;

const epic: Epic<Action, State> = combineEpics(
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	initApp as StateEpic,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	changeTab as StateEpic,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	fetchDetails as StateEpic,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	optOutDeploymentDetailDiscovery as StateEpic,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	analytics as StateEpic,
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	analyticsScheduler as StateEpic,
);

export default errorHandlerEpicMiddleware(APP_ID, 'root', epic);
