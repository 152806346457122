// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	assignIssue: {
		id: 'issue.assignee.command-palette.assign-issue',
		defaultMessage: 'Assign issue',
		description:
			'Command palette action which will open list of assignees to be assign to the issue',
	},
	searchPeople: {
		id: 'issue.assignee.command-palette.search-people',
		defaultMessage: 'Search people',
		description: 'Input placeholder that is shown before user types to find a user in the list',
	},
	current: {
		id: 'issue.assignee.command-palette.current',
		defaultMessage: 'Current',
		description: 'Tag that is displayed next to the current user assigned to the issue',
	},
	fallbackNoResults: {
		id: 'issue.assignee.command-palette.fallback-no-results',
		defaultMessage: 'No results match your search.',
		description: 'Message displayed in the command palette when the search returns no results',
	},
	fallbackOnError: {
		id: 'issue.assignee.command-palette.fallback-on-error',
		defaultMessage: "We're having trouble loading this data. Wait a few moments, then try again.",
		description: 'Message displayed in the command palette when the search returns api failure',
	},
	assignIssueIssueTermRefresh: {
		id: 'issue.assignee.command-palette.assign-issue-issue-term-refresh',
		defaultMessage: 'Assign issue',
		description:
			'Command palette action which will open list of assignees to be assign to the issue',
	},
});
