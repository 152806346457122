import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	deleteConfluenceWhiteboardLinkFailedTitle: {
		defaultMessage: "We couldn't remove the Confluence whiteboard link",
		id: 'issue-view-common-views.confluence-content-line-card.confluence-whiteboard-line-card-group-view.delete-confluence-whiteboard-link-failed-title',
		description:
			'This error message title will be shown when the user fails to unlink Confluence Whiteboard to the issue.',
	},
	deleteConfluenceWhiteboardLinkFailedDescription: {
		defaultMessage: 'Check your connection, then give it another try.',
		id: 'issue-view-common-views.confluence-content-line-card.confluence-whiteboard-line-card-group-view.delete-confluence-whiteboard-link-failed-description',
		description:
			'This error message description will be shown when the user fails to unlink Confluence Whiteboard to the issue.',
	},
	deleteConfluenceWhiteboardLinkPermissionFailedDescription: {
		defaultMessage: "You don't have the permissions to perform this action",
		id: 'issue-view-common-views.confluence-content-line-card.confluence-whiteboard-line-card-group-view.delete-confluence-whiteboard-link-permission-failed-description',
		description:
			'This error message description will be shown when the user does not have permission to unlink Confluence Whiteboard to the issue.',
	},
	deleteConfirmationModalTitle: {
		defaultMessage: 'Delete the Confluence whiteboard link?',
		id: 'issue-view-common-views.confluence-content-line-card.confluence-whiteboard-line-card-group-view.delete-confirmation-modal-title',
		description: 'The title of the delete whiteboard link confirmation modal.',
	},
	deleteConfirmationModalDescription: {
		defaultMessage: 'You can add the whiteboard later again if you need to.',
		id: 'issue-view-common-views.confluence-content-line-card.confluence-whiteboard-line-card-group-view.delete-confirmation-modal-description',
		description: 'The description of the delete whiteboard link confirmation modal.',
	},
});
