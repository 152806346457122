import { useCallback, useMemo } from 'react';
import type { IntlShapeV2 } from '@atlassian/jira-intl/src/v2/types.tsx';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import type { State as ReduxState } from '@atlassian/jira-issue-view-common-types/src/issue-type.tsx';
import type { ContextlessTypename } from '@atlassian/jira-issue-view-field-update-events/src/common/types.tsx';
import { useIssueViewFieldUpdateEvents } from '@atlassian/jira-issue-view-field-update-events/src/services/issue-view-field-update-events/index.tsx';
import { isMobileSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/context-selector.tsx';
import { fieldInvalidMessageSelector } from '@atlassian/jira-issue-view-store/src/common/state/selectors/field-selector.tsx';
import { useStore } from '@atlassian/jira-react-redux/src/index.tsx';
import { messages } from '../connect-field.tsx';
import { useEditStates } from './edit-states.tsx';

type Nullable<T> = T | null | undefined;

/**
 * Data the consumer must query for on the JiraIssueField.
 */
export type RequiredFieldData = {
	__typename: ContextlessTypename;

	fieldId: string;
	type: string;
	name: string;
	description?: Nullable<string>; // TODO: allow this to be passed in to the wrapped components (currently derived from state in the component)

	fieldConfig: Nullable<{
		isEditable: Nullable<boolean>;
	}>;
};

/**
 * Props we require from the consumer of the HOC.
 */
export type AdditionalProps = Record<string, unknown> | undefined;

export type ProvidedProps<TComponentValue, TAdditionalProps extends AdditionalProps = undefined> = {
	jiraIssueField: RequiredFieldData;

	value: TComponentValue;
	onValueConfirm: (value: TComponentValue) => void;

	onEditChange?: (isEditing: boolean) => void;

	// Additional props are passed through to the wrapped component
	additionalProps?: TAdditionalProps;
};

/**
 * Props required for the wrapped component.
 */
export type WrappedComponentProps<
	TComponentValue,
	TAdditionalProps extends AdditionalProps = undefined,
> = undefined extends TAdditionalProps
	? BaseProps<TComponentValue>
	: BaseProps<TComponentValue> & TAdditionalProps;

type BaseProps<TComponentValue> = {
	intl: IntlShapeV2;

	// Common values
	issueKey: string;
	fieldId: string;
	fieldType: string;
	label: string;
	value: TComponentValue;
	isEditable: boolean;
	isEditing: boolean;
	fieldEditSessionId: string;
	isMobile: boolean;
	invalidMessage: string | undefined;

	// Common actions
	onEditRequest: () => void;
	onChange: (value: TComponentValue) => void;
	onConfirm: () => void;
	onCancel: () => void;
	onBlur: () => void;

	// Components don't currently expect these to be passed in, but they should be going forward
	description?: Nullable<string>;
};

/**
 * Converts the props provided by our relay-compatible code into the props required by the wrapped field.
 */
export function useComponentProps<TComponentValue, TAdditionalProps extends AdditionalProps>(args: {
	issueKey: string;
	providedProps: ProvidedProps<TComponentValue, TAdditionalProps>;
}): WrappedComponentProps<TComponentValue, TAdditionalProps> {
	const { issueKey, providedProps } = args;
	const {
		jiraIssueField,
		value: componentValue,
		additionalProps = {},
		onEditChange,
		onValueConfirm,
	} = providedProps;

	const intl = useIntl();
	const store = useStore<ReduxState>(); // Use the store down so that we can fill gaps missing in Relay

	const {
		isEditing,
		editingSessionId,
		startEditing,
		setEditingValue,
		cancelEditing,
		submitEditing,
		value: displayedValue,
	} = useEditStates(jiraIssueField.fieldId, componentValue, onEditChange);

	// region: Callbacks
	const onConfirm = useCallback(() => {
		submitEditing((editingValue) => {
			onValueConfirm(editingValue);
		});
		// TODO: Analytics
	}, [onValueConfirm, submitEditing]);

	const onCancel = useCallback(() => {
		cancelEditing({ clearDraft: true });

		// BENTO-12481 new inline-edit version keeps the focus on the field if it was navigated to vis keyboard,
		// as a result - preventing the whole issue view modal from being closed by `esc` keypress.
		// This will make sure that pressing `esc` on the field with remove the focus on it
		// and will allow futher `esc` keypress to close issue view modal

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const activeElement = window.document?.activeElement;
		if (activeElement instanceof HTMLElement) {
			activeElement.blur();
		}
	}, [cancelEditing]);
	// end region: Callbacks

	const state = store.getState();

	return useMemo(
		() => ({
			intl,

			// Common props
			// These props are provided by default in the existing connect-field HOC
			issueKey,
			fieldId: jiraIssueField.fieldId,
			fieldType: jiraIssueField.type,
			label: jiraIssueField.name,
			value: displayedValue,
			description: jiraIssueField.description,
			isEditable: jiraIssueField.fieldConfig?.isEditable ?? false,
			isEditing,
			fieldEditSessionId: editingSessionId ?? '',

			onEditRequest: startEditing,
			onChange: setEditingValue,
			onBlur: cancelEditing,
			onConfirm,
			onCancel,

			// Redux props
			// These props are also provided by default, but we don't yet have a way of deriving them in the Relay approach.
			// Here, we use the state if it's available so that you can test components without using issue fetch mocks.
			isMobile: state ? isMobileSelector(state) : false,
			invalidMessage: state
				? fieldInvalidMessageSelector(
						jiraIssueField.fieldId,
						intl.formatMessage(messages.defaultFallbackInvalidMessage),
					)(state) ?? undefined
				: intl.formatMessage(messages.defaultFallbackInvalidMessage),

			// The fields below are provided by the existing connectField, but are not implemented in this Relay connector
			// We may need to introduce functionality case-by-case as we convert fields to Relay

			// fieldOptions
			// memoizedTransformToStateValue
			// additionalCallbacks
			// initFieldOptions
			// onPaste
			// customFieldConfig
			// isWaiting

			// Passed through.
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			...(additionalProps as TAdditionalProps),
		}),
		[
			additionalProps,
			cancelEditing,
			displayedValue,
			editingSessionId,
			intl,
			isEditing,
			issueKey,
			jiraIssueField.description,
			jiraIssueField.fieldConfig?.isEditable,
			jiraIssueField.fieldId,
			jiraIssueField.name,
			jiraIssueField.type,
			onCancel,
			onConfirm,
			setEditingValue,
			startEditing,
			state,
		],
	);
}

export type SoftRefreshCallbacks<TAggValue> = {
	onSubmit: (value: TAggValue) => void;
	onSubmitSucceeded: (value: TAggValue) => void;
	onSubmitFailed: () => void;
};

/**
 * Provides soft refresh callbacks with the required data embedded.
 */
export function useSoftRefreshCallbacks<TAggValue>(args: {
	issueId: Nullable<string>;
	fieldData: Pick<RequiredFieldData, 'fieldId' | 'type' | '__typename'>;
}) {
	const { issueId, fieldData } = args;

	const [, { fieldChangeRequested, fieldChanged, fieldChangeFailed }] =
		useIssueViewFieldUpdateEvents();

	const onSubmit = useCallback(
		(value: TAggValue) => {
			issueId &&
				fieldChangeRequested(issueId, fieldData.fieldId, value, undefined, {
					type: fieldData.type,
					__typename: fieldData.__typename,
				});
		},
		[fieldData.__typename, fieldData.fieldId, fieldData.type, fieldChangeRequested, issueId],
	);

	const onSubmitSucceeded = useCallback(
		(value: TAggValue) => {
			issueId &&
				fieldChanged(issueId, fieldData.fieldId, value, {
					type: fieldData.type,
					__typename: fieldData.__typename,
				});
		},
		[fieldData.__typename, fieldData.fieldId, fieldData.type, fieldChanged, issueId],
	);

	const onSubmitFailed = useCallback(
		() => issueId && fieldChangeFailed(issueId, fieldData.fieldId),
		[fieldData.fieldId, fieldChangeFailed, issueId],
	);

	return { onSubmit, onSubmitSucceeded, onSubmitFailed };
}
