import gqlTagPolaris from 'graphql-tag';

// TODO clean up with FD-75592 - jpd_visitor_handling_architecture_north_star
// this is still used for the old last viewed query path in the experiment
export const LAST_VIEWED_FRAGMENT = gqlTagPolaris`
  fragment PolarisViewLastViewed on PolarisViewLastViewed {
    aaid
    timestamp
    account {
        __typename
        accountId
        name
        picture
    }
  }
`;
