import React from 'react';
import PeopleGroupIcon from '@atlaskit/icon/core/migration/people-group';
import type { MultiSelectValueItemShape } from '@atlassian/jira-issue-internal-field-select/src/multi-select-inline-edit/index.tsx';

type Organization = {
	id: string;
	name: string;
};

type TransformedSuggestions = {
	items: MultiSelectValueItemShape[];
};

const toItem = (organization: Organization): MultiSelectValueItemShape => ({
	value: organization.id,
	content: organization.name,
	elemBefore: <PeopleGroupIcon color="currentColor" label="" />,
});

export const transformFromStateValue = (
	stateValues: Organization[] | null,
): MultiSelectValueItemShape[] => {
	if (!stateValues) {
		return [];
	}
	return stateValues.map(toItem);
};

export const transformToStateValue = (value: MultiSelectValueItemShape[]) =>
	value &&
	value.map<Organization>((item) => ({
		id: item.value,
		name: item.content,
	}));

export const transformSuggestions = (
	suggestionsFromServer: Organization[],
): TransformedSuggestions[] => [
	{
		items: suggestionsFromServer.map(toItem),
	},
];
