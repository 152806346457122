import React from 'react';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import DraftPR from './ui/index.tsx';

const ffCleanupEnabledCloudIds = [
	// hello
	'a436116f-02ce-4520-8fbb-7301462a1674',
	// staging
	'bc466197-6775-4fe1-a605-604a1a34a9d2',
];

type FFCleanupComponentProps = {
	issueId: string;
	issueKey: string;
};

const FFCleanupComponent = ({ issueId, issueKey }: FFCleanupComponentProps) => {
	const cloudId = useCloudId();

	return ffCleanupEnabledCloudIds.includes(cloudId) ? (
		<DraftPR issueId={issueId} issueKey={issueKey} />
	) : null;
};

export default FFCleanupComponent;
