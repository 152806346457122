import {
	type ChildIssuesLimitStatus,
	EXCEEDED_LIMIT_AFTER_LOAD,
	EXCEEDED_LIMIT_INITIAL_LOAD,
} from '@atlassian/jira-issue-view-common-constants/src/child-issues.tsx';

export const hasExceededIssuesLimitAfterLoad = (childIssueLimitStatus: ChildIssuesLimitStatus) =>
	childIssueLimitStatus === EXCEEDED_LIMIT_AFTER_LOAD;

export const hasExceededIssuesLimitInitialLoad = (childIssueLimitStatus: ChildIssuesLimitStatus) =>
	childIssueLimitStatus === EXCEEDED_LIMIT_INITIAL_LOAD;
