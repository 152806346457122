import type { NotificationType } from '@atlassian/jira-issue-adjustments/src/controllers.tsx';
import { conflictUimNotificationFlagProperties } from './constants.tsx';
import type { NotificationFlagProperties } from './types.tsx';

export const getNotificationFlagProperties = (
	notificationType: NotificationType,
): NotificationFlagProperties | null => {
	const notificationFlagPropertiesMap: Record<NotificationType, NotificationFlagProperties> = {
		MULTIPLE_APPS_CONFLICTS: conflictUimNotificationFlagProperties,
	};

	const notificationFlagProperties = notificationFlagPropertiesMap[notificationType];

	if (notificationFlagProperties === undefined) {
		return null;
	}

	return notificationFlagProperties;
};
