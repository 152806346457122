// FIXME: JIV-15565 this rule has been disabled in order to preserve Ids during extraction

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'issue.activity.worklog.add-modal.title',
		defaultMessage: 'Time tracking',
		description: 'Title for the Add work log modal',
	},
});
