import omit from 'lodash/omit';
import type { ViewUUID } from '@atlassian/jira-polaris-domain-view/src/view/types.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import type { State } from '../../types.tsx';

export const cleanupViewLastSeenState =
	(viewUUID: ViewUUID) =>
	async ({ setState, getState }: StoreActionApi<State>) => {
		setState({
			lastSeen: {
				...omit(getState().lastSeen, viewUUID),
			},
		});
	};
