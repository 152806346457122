import head from 'lodash/head';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import {
	isIssueTypeIdFilter,
	isIssueTypeNameFilter,
	type ConnectionFieldIssueTypeFilter,
	type ConnectionFieldIssueViewLayoutSort,
} from '@atlassian/jira-polaris-domain-field/src/field/connection/types.tsx';
import type {
	ConnectionFieldConfiguration,
	FieldKey,
} from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import { createErrorAnalytics } from '@atlassian/jira-polaris-lib-errors/src/controllers/index.tsx';
import type { StoreActionApi } from '@atlassian/react-sweet-state';
import { createGetFieldByKey } from '../../selectors/field.tsx';
import type { Props, State } from '../../types.tsx';

export const updateConnectionFieldFilter =
	(fieldKey: FieldKey, filter: ConnectionFieldIssueTypeFilter | null) =>
	async (
		{ getState, setState }: StoreActionApi<State>,
		{ onFieldUpdateFailed, fieldRemote, issueTypeIds }: Props,
	): Promise<void> => {
		const state = getState();
		const currentField = createGetFieldByKey(fieldKey)(state);
		const issueTypeId = head(issueTypeIds);

		if (!issueTypeId || !currentField) {
			return;
		}

		const newConfiguration: ConnectionFieldConfiguration = {
			...currentField.configuration,
			issueTypeFilters:
				filter &&
				((isIssueTypeNameFilter(filter) && filter.names.length > 0) ||
					(isIssueTypeIdFilter(filter) && filter.ids.length > 0))
					? [filter]
					: [],
		};

		try {
			setState({
				fields: {
					...state.fields,
					[fieldKey]: {
						...currentField,
						configuration: newConfiguration,
					},
				},
			});

			await fieldRemote.updateFieldConfiguration({
				fieldKey,
				issueTypeId,
				oldConfiguration: currentField.configuration || {},
				configuration: newConfiguration,
				type: 'updateConnectionField',
			});
		} catch (error) {
			setState({
				fields: {
					...state.fields,
					[fieldKey]: currentField,
				},
			});

			if (error instanceof Error) {
				fireErrorAnalytics(
					createErrorAnalytics(
						'polaris.error.controllers.field.actions.update-field-configuration.connection.issue-filters',
						error,
					),
				);

				onFieldUpdateFailed(error);
			}
		}
	};

export type ConnectionFieldIssueViewLayout = {
	selectedFieldKey?: FieldKey | null;
	sort?: ConnectionFieldIssueViewLayoutSort | null;
};

export const updateConnectionFieldIssueViewLayout =
	(fieldKey: FieldKey, config: ConnectionFieldIssueViewLayout) =>
	async (
		{ getState, setState }: StoreActionApi<State>,
		{ onFieldUpdateFailed, fieldRemote, issueTypeIds }: Props,
	): Promise<void> => {
		const state = getState();
		const currentField = createGetFieldByKey(fieldKey)(state);
		const issueTypeId = head(issueTypeIds);

		if (!issueTypeId || !currentField) {
			return;
		}

		let issueViewLayoutFields: FieldKey[];
		if (config.selectedFieldKey === undefined) {
			issueViewLayoutFields = currentField?.configuration?.issueViewLayout?.fields || [];
		} else if (config.selectedFieldKey === null) {
			issueViewLayoutFields = [];
		} else {
			issueViewLayoutFields = [config.selectedFieldKey];
		}

		let issueViewLayoutSort: ConnectionFieldIssueViewLayoutSort[];
		if (config.sort === undefined) {
			issueViewLayoutSort = currentField?.configuration?.issueViewLayout?.sort || [];
		} else if (config.sort === null) {
			issueViewLayoutSort = [];
		} else {
			issueViewLayoutSort = [config.sort];
		}

		const newConfiguration: ConnectionFieldConfiguration = {
			...currentField.configuration,
			issueViewLayout: {
				fields: issueViewLayoutFields,
				sort: issueViewLayoutSort,
			},
		};

		try {
			setState({
				fields: {
					...state.fields,
					[fieldKey]: {
						...currentField,
						configuration: newConfiguration,
					},
				},
			});

			await fieldRemote.updateFieldConfiguration({
				fieldKey,
				issueTypeId,
				oldConfiguration: currentField.configuration || {},
				configuration: newConfiguration,
				type: 'updateConnectionField',
			});
		} catch (error) {
			setState({
				fields: {
					...state.fields,
					[fieldKey]: currentField,
				},
			});

			if (error instanceof Error) {
				fireErrorAnalytics(
					createErrorAnalytics(
						'polaris.error.controllers.field.actions.update-field-configuration.connection.issue-view-layout',
						error,
					),
				);

				onFieldUpdateFailed(error);
			}
		}
	};
