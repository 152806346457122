/**
 * @generated SignedSource<<2e7afbec48289247dd3fc8eb0b272854>>
 * @relayHash dae76b3225132fa62bdeb2015fb8559b
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ab319552c0a1ae0dcfefebd47f99ffd84ca1f285af7069c6072ffa3f330253fb

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { indexOld_DesignPanelQuery } from './indexOld_DesignPanelQuery.graphql';

const node: PreloadableConcreteRequest<indexOld_DesignPanelQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "ab319552c0a1ae0dcfefebd47f99ffd84ca1f285af7069c6072ffa3f330253fb",
    "metadata": {},
    "name": "indexOld_DesignPanelQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
