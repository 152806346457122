// FIELD_DELETE_DRAFT_FAILURE
export const FIELD_DELETE_DRAFT_FAILURE = 'FIELD_DELETE_DRAFT_FAILURE' as const;

export const fieldDeleteDraftError = (error: string) => ({
	type: FIELD_DELETE_DRAFT_FAILURE,
	payload: error,
});

export type FieldDeleteDraftError = ReturnType<typeof fieldDeleteDraftError>;

// FIELD_LOAD_DRAFT_FAILURE
export const FIELD_LOAD_DRAFT_FAILURE = 'FIELD_LOAD_DRAFT_FAILURE' as const;

export const fieldLoadDraftError = (error: string) => ({
	type: FIELD_LOAD_DRAFT_FAILURE,
	payload: error,
});

export type FieldLoadDraftError = ReturnType<typeof fieldLoadDraftError>;

// FIELD_SAVE_DRAFT_FAILURE
export const FIELD_SAVE_DRAFT_FAILURE = 'FIELD_SAVE_DRAFT_FAILURE' as const;

export const fieldSaveDraftError = (error: string) => ({
	type: FIELD_SAVE_DRAFT_FAILURE,
	payload: error,
});

export type FieldSaveDraftError = ReturnType<typeof fieldSaveDraftError>;

export type FieldDraftAction = FieldDeleteDraftError | FieldLoadDraftError | FieldSaveDraftError;
