import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	classificationLevel: {
		id: 'issue-classification-badge.classification-level',
		defaultMessage: 'This issue is classified as {name}',
		description: 'Tooltip, when hover over data classification field.',
	},

	sucessFlagTitle: {
		id: 'issue-classification-badge.sucess-flag-title',
		defaultMessage: 'Classification level updated',
		description:
			'This is the title of a success popup after a user succcessfully updates the data classification field in issue view',
	},

	successFlagDescription: {
		id: 'issue-classification-badge.success-flag-description',
		defaultMessage: 'The issue {issueKey} is classified as "{name}"',
		description:
			'This is the description of a success popup after a user succcessfully updates the data classification field in issue view',
	},

	errorFlagTitle: {
		id: 'issue-classification-badge.error-flag-title',
		defaultMessage: "We couldn't update the classification level",
		description:
			'This is the title of an error popup after a user fails to update the data classification field in issue view',
	},

	errorFlagDescription: {
		id: 'issue-classification-badge.error-flag-description',
		defaultMessage: 'Check your connection and try again.',
		description:
			'This is the description of an error popup after a user fails to update the data classification field in issue view',
	},

	noClassficationName: {
		id: 'issue-classification-badge.no-classfication-name',
		defaultMessage: 'Classification level removed',
		description:
			'This is the title of success popup when no classification is selected for data classification field in issue view',
	},

	noClassficationDescription: {
		id: 'issue-classification-badge.no-classfication-description',
		defaultMessage: "The issue {issueKey} doesn't have a classification level anymore.",
		description:
			'This is the description of success popup when no classification is selected for data classification field in issue view',
	},

	classifyIssue: {
		id: 'issue-classification-badge.classify-issue',
		defaultMessage: 'Classify issue',
		description:
			'This is the text of the issue classification badge to add classification in issue view',
	},
	classifyRequest: {
		id: 'issue-classification-badge.classify-request',
		defaultMessage: 'Classify request',
		description:
			'This is the text of the request classification badge to add classification in issue view of service projects',
	},
	couldNotLoadClassificationLevel: {
		id: 'issue-classification-badge.could-not-load-classification-level',
		defaultMessage: "We couldn't load the classification level.",
		description: 'This message is displayed there is an error fetching the classfication',
	},
	cannotClassifyIssueTitle: {
		id: 'issue-classification-badge.cannot-classify-issue-title',
		defaultMessage: "We can't classify the issue right now",
		description:
			'This is the title of popup when there is an error in loading classification and user clicks on Classify issue button',
	},

	cannotClassifyIssueDescription: {
		id: 'issue-classification-badge.cannot-classify-issue-description',
		defaultMessage: 'Check your connection and try again.',
		description:
			'This is the description of popup when there is an error in loading classification and user clicks on Classify issue button',
	},
	refresh: {
		id: 'issue-classification-badge.refresh',
		defaultMessage: 'Refresh',
		description: 'Call to action to refresh the page',
	},
});
