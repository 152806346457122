import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { fireOperationalAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { Action } from '@atlassian/react-sweet-state';
import type { State, ContainerProps } from '../../types.tsx';

export const getLatestUpdate =
	(): Action<State, ContainerProps> =>
	async ({ getState, setState }, { issueId, cloudId, analyticsEvent }) => {
		const {
			informModal: {
				latestUpdate: { isLoading },
				...rest
			},
			...state
		} = getState();

		if (!issueId) {
			setState({
				stakeholders: {
					error: 'Missing issueId',
				},
			});
			return;
		}

		if (isLoading) {
			return;
		}

		setState({
			...state,
			informModal: {
				...rest,
				latestUpdate: {
					isLoading: true,
					data: undefined,
				},
			},
		});

		try {
			const data = await performGetRequest(
				`/gateway/api/jsm/stakeholders/cloudId/${cloudId}/v1/stakeholders/issue/${issueId}/status-update/latest`,
			);
			setState({
				...state,
				informModal: { ...rest, latestUpdate: { isLoading: true, data } },
			});
			// https://data-portal.internal.atlassian.com/analytics/registry/59451
			fireOperationalAnalytics(analyticsEvent, 'getLatestUpdate succeeded');
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			// https://data-portal.internal.atlassian.com/analytics/registry/59455
			fireErrorAnalytics({
				event: analyticsEvent,
				meta: {
					id: 'getLatestUpdate',
					packageName: 'jiraJsmStakeholderUpdates',
					teamName: 'itsm-agni',
				},
				error,
				sendToPrivacyUnsafeSplunk: true,
			});
			setState({
				...state,
				informModal: {
					...rest,
					latestUpdate: {
						isLoading: false,
						data: undefined,
					},
				},
			});
		}
	};
