import { useState } from 'react';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (defaultIsVisible = false) => {
	const [isVisible, setIsVisible] = useState(defaultIsVisible);

	const show = () => {
		setIsVisible(true);
	};
	const hide = () => {
		setIsVisible(false);
	};

	return [isVisible, show, hide] as const;
};
