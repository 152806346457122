import { fg } from '@atlassian/jira-feature-gating';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import {
	END_AFTER_NOW,
	END_BEFORE_NOW,
	INTERVAL_FIELD_FILTER,
	START_AFTER_NOW,
	START_BEFORE_NOW,
	type IntervalFieldFilter,
	type IntervalFieldFilterEmptyValue,
	type IntervalFieldFilterLegacyValue,
	type IntervalFieldFilterNotEmptyValue,
	type IntervalFieldFilterValue,
} from '@atlassian/jira-polaris-domain-view/src/filter/types.tsx';
import {
	isIntervalFieldFilterAbsoluteDatesValue,
	isIntervalFieldFilterEmptyValue,
	isIntervalFieldFilterLegacyValue,
	isIntervalFieldFilterNotEmptyValue,
	isIntervalFieldFilterRollingDatesCurrentValue,
	isIntervalFieldFilterRollingDatesPastNextValue,
} from '@atlassian/jira-polaris-domain-view/src/filter/utils.tsx';

type FilterValue = {
	operator: string | null;
	stringValue: string | null;
	numericValue: number | null;
};

const transformIntervalFilterOperatorsLegacy = <T extends FilterValue>(
	inputValues: ReadonlyArray<T>,
): IntervalFieldFilterLegacyValue[] => {
	const outputValues: IntervalFieldFilterLegacyValue[] = [];

	inputValues.forEach((value) => {
		if (
			value?.operator === END_AFTER_NOW ||
			value?.operator === END_BEFORE_NOW ||
			value?.operator === START_AFTER_NOW ||
			value?.operator === START_BEFORE_NOW
		) {
			outputValues.push({
				numericValue: value.numericValue !== null ? value.numericValue : 0,
				operator: value.operator,
			});
		}
	});

	return outputValues;
};

const transformIntervalFilterOperatorsNext = <T extends FilterValue>(
	inputValues: ReadonlyArray<T>,
): IntervalFieldFilterValue[] => {
	const outputValues: IntervalFieldFilterValue[] = [];

	inputValues.forEach((gqlValue) => {
		if (
			gqlValue.operator === END_AFTER_NOW ||
			gqlValue.operator === END_BEFORE_NOW ||
			gqlValue.operator === START_AFTER_NOW ||
			gqlValue.operator === START_BEFORE_NOW
		) {
			const filterValue: IntervalFieldFilterLegacyValue = {
				numericValue: gqlValue.numericValue !== null ? gqlValue.numericValue : 0,
				operator: gqlValue.operator,
			};
			outputValues.push(filterValue);
			return;
		}

		if (
			(gqlValue.operator === 'EQ' || gqlValue.operator === 'NEQ') &&
			gqlValue.numericValue === null
		) {
			const filterValue: IntervalFieldFilterEmptyValue | IntervalFieldFilterNotEmptyValue = {
				operator: gqlValue.operator,
				value: null,
				text: null,
			};

			outputValues.push(filterValue);
			return;
		}

		if (gqlValue.operator === null && gqlValue.stringValue) {
			let filterValue: unknown | undefined;
			try {
				filterValue = JSON.parse(gqlValue.stringValue);
			} catch {
				// do nothing
			}

			if (
				filterValue &&
				(isIntervalFieldFilterAbsoluteDatesValue(filterValue) ||
					isIntervalFieldFilterRollingDatesCurrentValue(filterValue) ||
					isIntervalFieldFilterRollingDatesPastNextValue(filterValue))
			) {
				outputValues.push(filterValue);
			}
		}
	});

	return outputValues;
};

export const transformIntervalFilter = <T extends FilterValue>(
	gqlValues: ReadonlyArray<T>,
	fieldKey: FieldKey,
): IntervalFieldFilter => {
	const values = fg('polaris_better_date_filters')
		? transformIntervalFilterOperatorsNext(gqlValues)
		: transformIntervalFilterOperatorsLegacy(gqlValues);

	const base: Omit<IntervalFieldFilter, 'values'> = {
		type: INTERVAL_FIELD_FILTER,
		field: fieldKey,
	};

	// Unfortunately, the manual control flow analysis here is required
	// because otherwise TypeScript compiler is unable to correctly
	// determine the typing of the union types
	// @see https://github.com/microsoft/TypeScript/issues/30581
	// @see https://github.com/microsoft/TypeScript/issues/25051
	//
	// The other way around to "reduce" the code size and avoid
	// multiple type guards is to use type assertion
	// @see https://stackoverflow.com/a/64467314
	for (const value of values) {
		if (isIntervalFieldFilterLegacyValue(value)) {
			return {
				...base,
				values: [value],
			};
		}

		if (isIntervalFieldFilterAbsoluteDatesValue(value)) {
			return {
				...base,
				values: [value],
			};
		}

		if (isIntervalFieldFilterRollingDatesCurrentValue(value)) {
			return {
				...base,
				values: [value],
			};
		}

		if (isIntervalFieldFilterRollingDatesPastNextValue(value)) {
			return {
				...base,
				values: [value],
			};
		}

		if (isIntervalFieldFilterEmptyValue(value)) {
			return {
				...base,
				values: [value],
			};
		}

		if (isIntervalFieldFilterNotEmptyValue(value)) {
			return {
				...base,
				values: [value],
			};
		}
	}

	return { ...base, values: [] };
};
