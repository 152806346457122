import { useCallback } from 'react';
import {
	fireOperationalAnalyticsDeferred,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';

export const useIssueTransitionsOperationalAnalyticsEvent = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const fireOperationalEventIssueTransitionFieldsCount = useCallback(
		({ actionSubject, fieldCount }: { actionSubject: string; fieldCount: number }) => {
			const analyticsEvent = createAnalyticsEvent({});
			fireOperationalAnalyticsDeferred(analyticsEvent, actionSubject, { fieldCount });
		},
		[createAnalyticsEvent],
	);

	const fireOperationalAnalyticsPermissionsUnAvailable = useCallback(
		({ actionSubject }: { actionSubject: string }) => {
			const analyticsEvent = createAnalyticsEvent({});
			fireOperationalAnalyticsDeferred(analyticsEvent, actionSubject);
		},
		[createAnalyticsEvent],
	);

	const fireOperationalAnalyticsTransitionScreenOpened = useCallback(
		({
			actionSubject,
			isBenefitsModalFGEneabled,
			preferNewIssueTransitionScreen,
			issueViewAnalyticsSource,
			isBenefitsModalDisabled,
			dateToShowBenefitsModal,
		}: {
			actionSubject: string;
			isBenefitsModalFGEneabled?: boolean;
			preferNewIssueTransitionScreen?: boolean;
			issueViewAnalyticsSource?: string;
			isBenefitsModalDisabled?: boolean;
			dateToShowBenefitsModal?: string | undefined;
		}) => {
			const analyticsEvent = createAnalyticsEvent({});
			fireOperationalAnalyticsDeferred(analyticsEvent, actionSubject, {
				isBenefitsModalFGEneabled,
				preferNewIssueTransitionScreen,
				issueViewAnalyticsSource,
				isBenefitsModalDisabled,
				dateToShowBenefitsModal,
			});
		},
		[createAnalyticsEvent],
	);

	return {
		fireOperationalEventIssueTransitionFieldsCount,
		fireOperationalAnalyticsPermissionsUnAvailable,
		fireOperationalAnalyticsTransitionScreenOpened,
	};
};
