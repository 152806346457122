import { withAnalyticsEvents } from '@atlaskit/analytics-next';
import Select from '@atlaskit/select';

export const SelectWithAnalytics = withAnalyticsEvents({
	onMenuOpen: {
		action: 'opened',
		component: 'select',
	},
	onMenuClose: {
		action: 'closed',
		component: 'select',
	},
})(Select);
