import React from 'react';
import { useResourceWithCustomRouterContext } from '@atlassian/jira-resource-with-custom-router-context/src/controllers/use-resource-with-custom-router-context/index.tsx';
import { aiContextServicePanelResource } from '@atlassian/jira-servicedesk-ai-context-service-panel-resources/src/services/index.tsx';
import { AIContextServicePanel } from '@atlassian/jira-servicedesk-ai-context-service-panel/src/ui/index.tsx';
import { useRefreshOnIssueIdChange } from '../../../controllers/use-refresh-on-issue-id-change/index.tsx';
import type { DummyRoute, DummyRouteMatchParams } from './types.tsx';

// Check the comments in the implementation of useResourceWithCustomRouterContext hook for more detail about this dummy route
const DUMMY_ROUTE_WITH_ISSUE_ID: DummyRoute = {
	name: 'SERVICEDESK_AI_CONTEXT_SERVICE_PANEL_DUMMY_ROUTE_NAME',
	path: '/servicedesk-ai-context-service-panel-dummy-route/:issueId',
};

export const AIContextServicePanelWithPrefetchedResource = ({ issueId }: Props) => {
	const resource = useResourceWithCustomRouterContext(
		aiContextServicePanelResource,
		{
			route: DUMMY_ROUTE_WITH_ISSUE_ID,
			matchParams: { issueId } satisfies DummyRouteMatchParams,
		},
		{
			shouldPrefetch: true,
		},
	);

	useRefreshOnIssueIdChange({ issueId, refreshResource: resource.refresh });

	return <AIContextServicePanel resource={resource} />;
};

type Props = {
	issueId: string;
};
