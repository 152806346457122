/**
 * @generated SignedSource<<e35b61e300ce256cc72c4fcad7e82b74>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
import type { totalCountResolver as jiraAttachmentConnectionTotalCountResolverResolverType } from "../../../relay-resolvers/src/attachments/index.tsx";
import type { cardCoverResolver as jiraIssueCardCoverResolverResolverType } from "../../../relay-resolvers/src/attachments/index.tsx";
import type { isMobileResolver as jiraIssueIsMobileResolverResolverType } from "../../../relay-resolvers/src/attachments/index.tsx";
import type { isPreviewResolver as jiraIssueIsPreviewResolverResolverType } from "../../../relay-resolvers/src/attachments/index.tsx";
export type ui_issueViewBase_AttachmentFilmstripRelayFragment$data = {
  readonly jira: {
    readonly issueByKey: {
      readonly attachments: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly __typename: string;
            readonly attachmentId: string;
            readonly author: {
              readonly accountId: string;
            } | null | undefined;
            readonly created: AGG$DateTime;
            readonly hasRestrictedParent: boolean | null | undefined;
            readonly mediaApiFileId: string | null | undefined;
            readonly parentId: string | null | undefined;
            readonly parentName: string | null | undefined;
          } | null | undefined;
        } | null | undefined>;
        readonly totalCountResolver: NonNullable<number | null | undefined>;
      };
      readonly canDeleteAllAttachments: boolean | null | undefined;
      readonly canDeleteOwnAttachments: boolean | null | undefined;
      readonly cardCoverResolver: ReturnType<ReturnType<typeof jiraIssueCardCoverResolverResolverType>["read"]> | null | undefined;
      readonly isMobileResolver: boolean | null | undefined;
      readonly isPreviewResolver: boolean | null | undefined;
      readonly key: string;
      readonly mediaReadToken: {
        readonly clientId: string | null | undefined;
        readonly endpointUrl: string | null | undefined;
        readonly tokenLifespanInSeconds: AGG$Long | null | undefined;
        readonly tokensWithFiles: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly token: string | null | undefined;
            } | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined;
      } | null | undefined;
      readonly mediaUploadToken: {
        readonly clientId?: string | null | undefined;
        readonly endpointUrl?: AGG$URL | null | undefined;
        readonly errorId?: string | null | undefined;
        readonly errorMessage?: string | null | undefined;
        readonly targetCollection?: string | null | undefined;
        readonly token?: string | null | undefined;
        readonly tokenDurationInMin?: number | null | undefined;
      } | null | undefined;
    };
  };
  readonly " $fragmentType": "ui_issueViewBase_AttachmentFilmstripRelayFragment";
};
export type ui_issueViewBase_AttachmentFilmstripRelayFragment$key = {
  readonly " $data"?: ui_issueViewBase_AttachmentFilmstripRelayFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_issueViewBase_AttachmentFilmstripRelayFragment">;
};

import {cardCoverResolver as jiraIssueCardCoverResolverResolver} from './../../../relay-resolvers/src/attachments/index';
import {isMobileResolver as jiraIssueIsMobileResolverResolver} from './../../../relay-resolvers/src/attachments/index';
import {isPreviewResolver as jiraIssueIsPreviewResolverResolver} from './../../../relay-resolvers/src/attachments/index';
import {totalCountResolver as jiraAttachmentConnectionTotalCountResolverResolver} from './../../../relay-resolvers/src/attachments/index';
import ui_issueViewBase_AttachmentFilmstripRelayFragmentRefetchQuery_graphql from './ui_issueViewBase_AttachmentFilmstripRelayFragmentRefetchQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = [
  "jira",
  "issueByKey",
  "attachments"
],
v1 = {
  "kind": "ScalarField",
  "name": "clientId"
},
v2 = {
  "kind": "ScalarField",
  "name": "endpointUrl"
},
v3 = {
  "kind": "ScalarField",
  "name": "token"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cloudId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "issueKey"
    },
    {
      "defaultValue": 65536,
      "kind": "LocalArgument",
      "name": "maxTokenLength"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sortBy"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": ui_issueViewBase_AttachmentFilmstripRelayFragmentRefetchQuery_graphql
    }
  },
  "name": "ui_issueViewBase_AttachmentFilmstripRelayFragment",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "args": [
                {
                  "kind": "Variable",
                  "name": "cloudId",
                  "variableName": "cloudId"
                },
                {
                  "kind": "Variable",
                  "name": "key",
                  "variableName": "issueKey"
                }
              ],
              "concreteType": "JiraIssue",
              "kind": "LinkedField",
              "name": "issueByKey",
              "plural": false,
              "selections": [
                {
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "maxTokenLength",
                      "variableName": "maxTokenLength"
                    }
                  ],
                  "concreteType": "JiraMediaReadToken",
                  "kind": "LinkedField",
                  "name": "mediaReadToken",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/),
                    {
                      "kind": "ScalarField",
                      "name": "tokenLifespanInSeconds"
                    },
                    {
                      "concreteType": "JiraMediaTokenWithFilesConnection",
                      "kind": "LinkedField",
                      "name": "tokensWithFiles",
                      "plural": false,
                      "selections": [
                        {
                          "concreteType": "JiraMediaTokenWithFilesEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "concreteType": "JiraMediaTokenWithFiles",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v3/*: any*/)
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "name": "mediaUploadToken",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v2/*: any*/),
                        (v1/*: any*/),
                        {
                          "kind": "ScalarField",
                          "name": "targetCollection"
                        },
                        (v3/*: any*/),
                        {
                          "kind": "ScalarField",
                          "name": "tokenDurationInMin"
                        }
                      ],
                      "type": "JiraMediaUploadToken"
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": "errorId",
                          "kind": "ScalarField",
                          "name": "identifier"
                        },
                        {
                          "alias": "errorMessage",
                          "kind": "ScalarField",
                          "name": "message"
                        }
                      ],
                      "type": "QueryError"
                    }
                  ]
                },
                {
                  "kind": "ScalarField",
                  "name": "key"
                },
                {
                  "alias": "canDeleteAllAttachments",
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "permission",
                      "value": "DELETE_ALL_ATTACHMENTS"
                    }
                  ],
                  "kind": "ScalarField",
                  "name": "hasProjectPermission",
                  "storageKey": "hasProjectPermission(permission:\"DELETE_ALL_ATTACHMENTS\")"
                },
                {
                  "alias": "canDeleteOwnAttachments",
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "permission",
                      "value": "DELETE_OWN_ATTACHMENTS"
                    }
                  ],
                  "kind": "ScalarField",
                  "name": "hasProjectPermission",
                  "storageKey": "hasProjectPermission(permission:\"DELETE_OWN_ATTACHMENTS\")"
                },
                {
                  "kind": "RequiredField",
                  "field": {
                    "alias": "attachments",
                    "args": [
                      {
                        "kind": "Variable",
                        "name": "sortBy",
                        "variableName": "sortBy"
                      }
                    ],
                    "concreteType": "JiraAttachmentConnection",
                    "kind": "LinkedField",
                    "name": "__issue_attachments_connection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "RequiredField",
                        "field": {
                          "concreteType": "JiraAttachmentEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "ScalarField",
                                  "name": "__typename"
                                },
                                {
                                  "kind": "ScalarField",
                                  "name": "attachmentId"
                                },
                                {
                                  "kind": "ScalarField",
                                  "name": "mediaApiFileId"
                                },
                                {
                                  "kind": "ScalarField",
                                  "name": "created"
                                },
                                {
                                  "kind": "ScalarField",
                                  "name": "parentId"
                                },
                                {
                                  "kind": "ScalarField",
                                  "name": "parentName"
                                },
                                {
                                  "kind": "ScalarField",
                                  "name": "hasRestrictedParent"
                                },
                                {
                                  "kind": "LinkedField",
                                  "name": "author",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "kind": "ScalarField",
                                      "name": "accountId"
                                    }
                                  ]
                                }
                              ]
                            },
                            {
                              "kind": "ScalarField",
                              "name": "cursor"
                            }
                          ]
                        },
                        "action": "THROW",
                        "path": "jira.issueByKey.attachments.edges"
                      },
                      {
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "name": "endCursor"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "hasNextPage"
                          }
                        ]
                      },
                      {
                        "kind": "ClientExtension",
                        "selections": [
                          {
                            "kind": "RequiredField",
                            "field": {
                              "args": [
                                {
                                  "kind": "Variable",
                                  "name": "issueKey",
                                  "variableName": "issueKey"
                                }
                              ],
                              "kind": "RelayLiveResolver",
                              "name": "totalCountResolver",
                              "resolverModule": jiraAttachmentConnectionTotalCountResolverResolver,
                              "path": "jira.issueByKey.attachments.totalCountResolver"
                            },
                            "action": "THROW",
                            "path": "jira.issueByKey.attachments.totalCountResolver"
                          }
                        ]
                      }
                    ]
                  },
                  "action": "THROW",
                  "path": "jira.issueByKey.attachments"
                },
                {
                  "kind": "ClientExtension",
                  "selections": [
                    {
                      "kind": "RelayLiveResolver",
                      "name": "cardCoverResolver",
                      "resolverModule": jiraIssueCardCoverResolverResolver,
                      "path": "jira.issueByKey.cardCoverResolver"
                    },
                    {
                      "kind": "RelayLiveResolver",
                      "name": "isMobileResolver",
                      "resolverModule": jiraIssueIsMobileResolverResolver,
                      "path": "jira.issueByKey.isMobileResolver"
                    },
                    {
                      "kind": "RelayLiveResolver",
                      "name": "isPreviewResolver",
                      "resolverModule": jiraIssueIsPreviewResolverResolver,
                      "path": "jira.issueByKey.isPreviewResolver"
                    }
                  ]
                }
              ]
            },
            "action": "THROW",
            "path": "jira.issueByKey"
          }
        ]
      },
      "action": "THROW",
      "path": "jira"
    }
  ],
  "type": "Query"
};
})();

(node as any).hash = "70268ebefd07f4dccf36a158b30f1df6";

export default node;
