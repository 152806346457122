import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';

export const InvisibleDiv = ({ children }: { children: ReactNode }) => (
	<StyledInvisibleDiv>{children}</StyledInvisibleDiv>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledInvisibleDiv = styled.div({
	display: 'none',
});
