import React from 'react';
import { useStableIssueId } from '../../controllers/use-stable-issue-id/index.tsx';
import { AIContextServicePanelWithPrefetchedResource } from './ai-context-service-panel-with-prefetched-resource/index.tsx';

export const AIContextServicePanelLoader = () => {
	const issueId = useStableIssueId();

	if (!issueId) {
		return null;
	}

	return <AIContextServicePanelWithPrefetchedResource issueId={issueId} />;
};
