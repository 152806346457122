import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';

const url = '/gateway/api/compass/v1/entitlements';

export const getCompassPermissions = async (): Promise<boolean> => {
	try {
		return await performPostRequest(url, {
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				entitlements: [],
			}),
		});
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		if (error.statusCode === 403) {
			return error;
		}
		throw error;
	}
};
