import React from 'react';
import { useFragment, graphql } from 'react-relay';
import type { ui_issueViewLayoutMetaResolvedDateField_IssueViewResolvedDateField$key } from '@atlassian/jira-relay/src/__generated__/ui_issueViewLayoutMetaResolvedDateField_IssueViewResolvedDateField.graphql';
import { ResolvedDate as ResolvedDateFieldReadView } from '@atlassian/jira-resolved-date/src/ui/read/main.tsx';

export interface IssueViewResolvedDateFieldProps {
	fragmentKey: ui_issueViewLayoutMetaResolvedDateField_IssueViewResolvedDateField$key;
}

export const IssueViewResolvedDateField = ({ fragmentKey }: IssueViewResolvedDateFieldProps) => {
	const data = useFragment<ui_issueViewLayoutMetaResolvedDateField_IssueViewResolvedDateField$key>(
		graphql`
			fragment ui_issueViewLayoutMetaResolvedDateField_IssueViewResolvedDateField on JiraDateTimePickerField {
				dateTime
			}
		`,
		fragmentKey,
	);

	if (!data.dateTime) {
		return null;
	}

	return <ResolvedDateFieldReadView date={data.dateTime} />;
};
