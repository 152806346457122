export const ISSUE_CARD_DATA_COMPONENT_SELECTOR = 'VersionDetailIssueListIssueCardContainer';
export const DEV_DETAILS_DIALOG_SCOPE_ID = 'VersionDetailsIssueList';

// Viewport widths for issue list media queries
// 1000px issue card width = 1740px page viewport width
// 640px issue card width = 1380px page viewport width
//
// See https://softwareteams.atlassian.net/browse/RLSS-4029 for details
export const VIEWPORT_WIDTH_LARGE = 1740;
export const VIEWPORT_WIDTH_MEDIUM = 1380;
