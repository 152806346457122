import type { LocalIssueId } from '@atlassian/jira-polaris-domain-idea/src/idea/types.tsx';
import type { RemoteIssue } from '@atlassian/jira-polaris-remote-issue/src/controllers/crud/types.tsx';
import type { ExternalIssueDataMap } from '../../../types.tsx';
import { generateLocalExternalIssueId } from '../../../utils/local-id.tsx';

export type IssueLinkData = {
	deliveryIssueIds: LocalIssueId[];
	jiraLinkIssueIds: LocalIssueId[];
	externalIssueData: ExternalIssueDataMap;
};

const EMPTY = {
	deliveryIssueIds: [],
	jiraLinkIssueIds: [],
	externalIssueData: {},
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const hasDeliveryIssueLink = (linkedIssue: any, deliveryLinkTypeId: string, issueLinks: any) =>
	issueLinks.find(
		// @ts-expect-error - TS7006 - Parameter 'link' implicitly has an 'any' type.
		(link) =>
			link &&
			link.inwardIssue &&
			link.inwardIssue === linkedIssue &&
			link.type &&
			link.type.id === deliveryLinkTypeId,
	) !== undefined;

export const transformIssueLinkData = (
	polarisIssueLinkType: string | undefined,
	hiddenIssueLinkTypes: string[],
	issue: RemoteIssue,
): IssueLinkData => {
	if (!issue.fields.issuelinks) {
		return EMPTY;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const selfToExternalIssueId: Record<string, any> = {};
	const deliveryIssueIds: Array<LocalIssueId> = [];
	const jiraLinkIssueIds: Array<LocalIssueId> = [];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const externalIssueData: Record<string, any> = {};
	issue.fields.issuelinks.forEach((issueLink) => {
		const linkedIssueKey = issueLink?.inwardIssue?.key || issueLink?.outwardIssue?.key;
		if (!linkedIssueKey) {
			return;
		}

		const linkedExternalIssueId =
			selfToExternalIssueId[linkedIssueKey] || generateLocalExternalIssueId();
		const linkedIssue = issueLink.inwardIssue || issueLink.outwardIssue;

		if (!externalIssueData[linkedExternalIssueId]) {
			externalIssueData[linkedExternalIssueId] = {
				issueId: parseInt(linkedIssue.id, 10),
				issueKey: linkedIssue.key,
				summary: linkedIssue.fields.summary,
				status: linkedIssue.fields.status,
				issueType: linkedIssue.fields.issuetype,
				priority: linkedIssue.fields.priority,
				issueLinkId: issueLink.id,
				isDeliveryIssue:
					polarisIssueLinkType !== undefined &&
					hasDeliveryIssueLink(linkedIssue, polarisIssueLinkType, issue.fields.issuelinks),
				childIssues: [],
			};
		}
		if (
			polarisIssueLinkType !== undefined &&
			issueLink.type.id === polarisIssueLinkType &&
			hasDeliveryIssueLink(linkedIssue, polarisIssueLinkType, issue.fields.issuelinks)
		) {
			deliveryIssueIds.push(linkedExternalIssueId);
		} else if (!hiddenIssueLinkTypes.includes(issueLink.type.id)) {
			jiraLinkIssueIds.push(linkedExternalIssueId);
		}
	});
	return {
		deliveryIssueIds,
		jiraLinkIssueIds,
		externalIssueData,
	};
};
