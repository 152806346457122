import React, { type ComponentType } from 'react';
import { useIsEmbedMode } from '@atlassian/jira-issue-view-embed-mode/src/index.tsx';
import { SetBootstrapBundleReadyMark } from '@atlassian/jira-providers-spa-apdex-analytics/src/index.tsx';
import { toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { ProjectSubProductUpdater } from '@atlassian/jira-spa-apps-common/src/analytics-sub-product/project-sub-product-updater/index.tsx';
import { type RouteContext, withRouter } from '@atlassian/react-resource-router';
import SanitiseUrl from './common/sanitise-url/index.tsx';
import { StartIssueMetric } from './start-issue-metric.tsx';
import App from './view/index.tsx';

type Props = RouteContext;

const IssueWithoutRouterFunctional = (props: Props) => {
	const issueKey = toIssueKey(props.match.params.issueKey || '');
	const isEmbedMode = useIsEmbedMode();

	return (
		<>
			<StartIssueMetric issueKey={issueKey} isEmbedMode={isEmbedMode} />
			<SetBootstrapBundleReadyMark />
			<SanitiseUrl issueKey={issueKey} location={props.location}>
				<ProjectSubProductUpdater allowMonolithDeferral />
				<App />
			</SanitiseUrl>
		</>
	);
};

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
export default withRouter(IssueWithoutRouterFunctional) as ComponentType<Record<any, any>>;
