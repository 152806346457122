import { addStakeholder } from './add-stakeholder/index.tsx';
import { getStakeholders } from './fetch-stakeholders/index.tsx';
import { getIssueVersion } from './get-issue-version/index.tsx';
import { getLatestUpdate } from './get-latest-update/index.tsx';
import { getLimits } from './get-limits/index.tsx';
import { removeStakeholder } from './remove-stakeholder/index.tsx';
import { updateStakeholder } from './update-stakeholder/index.tsx';

export const actions = {
	getStakeholders,
	addStakeholder,
	removeStakeholder,
	updateStakeholder,
	getLatestUpdate,
	getLimits,
	getIssueVersion,
} as const;

export type Actions = typeof actions;
