import {
	performPostRequest,
	performDeleteRequest,
} from '@atlassian/jira-fetch/src/utils/requests.tsx';
import {
	toConfluencePageType,
	type ConfluencePage,
} from '@atlassian/jira-issue-shared-types/src/common/types/confluence-content-type.tsx';
import type { FailedRemoteLink } from '@atlassian/jira-issue-shared-types/src/common/types/remote-link-error-type.tsx';
import {
	APPLINK_REQ_AUTH,
	REMOTE_SERVER_ERROR,
} from '@atlassian/jira-issue-view-common-constants/src/remote-link-error-type.tsx';
import {
	type BaseUrl,
	type Href,
	type IssueId,
	toConfluencePageId,
	toDisplayName,
	toFailedRemoteLinkId,
	toHref,
	toRemoteLinkGlobalId,
} from '@atlassian/jira-shared-types/src/general.tsx';

type ServerResponse = {
	id: string;
	globalId: string;
	object: {
		title: string; // Page title,
		url: string;
		icon: {
			title: string; // Page or blogpost
		};
		status: {
			resolved: boolean; // If all data was fetched,
			icon: {
				title: string; // Display name,
				url16x16: string; // Display avatar or repair link if unresolved
			};
		};
	};
};

const transformConfluencePage = (response: ServerResponse): ConfluencePage | FailedRemoteLink => {
	if (response.object.status.resolved) {
		const confluencePage: ConfluencePage = {
			id: toConfluencePageId(response.id),
			globalId: toRemoteLinkGlobalId(response.globalId),
			href: toHref(response.object.url),
			title: response.object.title,
			type: toConfluencePageType(response.object.icon.title),
			lastUpdatedContext: {
				user: {
					displayName: toDisplayName(response.object.status.icon.title),
					avatarUrl: toHref(response.object.status.icon.url16x16),
				},
			},
		};
		return confluencePage;
	}
	const failedRemoteLink: FailedRemoteLink = {
		id: toFailedRemoteLinkId(response.id),
		error: response.object.status.icon.url16x16 ? APPLINK_REQ_AUTH : REMOTE_SERVER_ERROR,
		link: {
			id: toFailedRemoteLinkId(response.id),
			globalId: toRemoteLinkGlobalId(response.globalId),
			href: toHref(response.object.url),
		},
		repairLink: {
			href: toHref(response.object.status.icon.url16x16),
		},
	};
	return failedRemoteLink;
};

const createConfluencePageLinkUrl = (baseUrl: BaseUrl) =>
	`${baseUrl}/rest/confluenceIssueLink/1/confluence`;

const createConfluencePageLinkQueryParams = (issueId: IssueId, pageHref: Href) =>
	`?issueId=${issueId}&pageUrl=${pageHref}`;

export const createConfluencePageLinkRequest = ({
	baseUrl,
	issueId,
	pageHref,
}: {
	baseUrl: BaseUrl;
	issueId: IssueId;
	pageHref: Href;
}) =>
	performPostRequest(
		`${createConfluencePageLinkUrl(baseUrl)}${createConfluencePageLinkQueryParams(
			issueId,
			pageHref,
		)}`,
		{
			body: JSON.stringify({}),
		},
	).then(transformConfluencePage);

export const getDeleteRequest = (baseUrl: string, issueKey: string, globalId: string) =>
	performDeleteRequest(
		`${baseUrl}/rest/api/2/issue/${issueKey}/remotelink?globalId=${encodeURIComponent(globalId)}`,
	);
