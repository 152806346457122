/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { AppSkeletonImage } from '../../common/ui/AppSkeletonImage.tsx';
import devSummaryCreateBranchPopup from './assets/dev-summary-create-branch-popup.tpl.svg';

const containerStyles = css({
	padding: `${token('space.0', '0')} ${token('space.250', '20px')}`,
	height: '6.25em',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	object: {
		borderLeftWidth: 'unset',
		borderRightWidth: 'unset',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	img: {
		borderLeftWidth: 'unset',
		borderRightWidth: 'unset',
	},
});

export const DevSummaryCreateBranchPopupSkeleton = () => (
	<div data-testid="skeletons.ui.dev-summary-create-branch-popup.div" css={containerStyles}>
		<AppSkeletonImage src={devSummaryCreateBranchPopup} />
	</div>
);
