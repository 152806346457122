/**
    This function is in order to encapsulate the behavior of the AppLinksUtils functionality

    AppLinksUtils is an Atlassian Plugin.

    AppLinks repository: https://bitbucket.org/atlassian/application-links
 */

/**
    The following function implements the same interface as the [OAuthCallback object](https://bitbucket.org/atlassian/application-links/src/02de9b212e57ff72a3a44744ed6cabc5ff359874/applinks-plugin/src/main/resources/applinks/internal/feature/oauth/oauth-callback.js?at=master)

    It creates the object, assigns the object to a global variable in window and calls the plugin in a new window

    Once the Authentication dance is perform, the opened window will call success or failure callbacks from the window.opener.oauthCallback (https://bitbucket.org/atlassian/application-links/src/02de9b212e57ff72a3a44744ed6cabc5ff359874/applinks-oauth-plugin/src/main/resources/com/atlassian/applinks/oauth/auth/oauth-dance.js?at=master)
 */
const authenticateUsingAppLinks = (url: string): Promise<undefined> | null => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	if (window.oauthCallback) {
		// preventing opening more than one authentication at the time.
		return null;
	}

	const result = new Promise(
		(
			resolve: (result: Promise<undefined> | undefined) => void,
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			reject: (error?: any) => void,
		) => {
			let oauthWindow: Window | null;
			let timer: ReturnType<typeof setTimeout>;
			let isPending = true;

			const oauthCallback = {
				success: () => {
					clearInterval(timer);
					// @ts-expect-error - TS2794 - Expected 1 arguments, but got 0. Did you forget to include 'void' in your type argument to 'Promise'?
					resolve();
					isPending = false;

					// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					delete window.oauthCallback;
					oauthWindow && oauthWindow.close();
				},
				failure: () => {
					clearInterval(timer);
					reject(Error('Failed to authenticate with applinks'));
					isPending = false;

					// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					delete window.oauthCallback;
					oauthWindow && oauthWindow.close();
				},
			};

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.oauthCallback = oauthCallback;

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			oauthWindow = window.open(url, 'com_atlassian_applinks_authentication');

			timer = setInterval(() => {
				if (oauthWindow && oauthWindow.closed && isPending) {
					// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					delete window.oauthCallback;
					reject(Error('Failed to authenticate with applinks'));
					clearInterval(timer);
				}
			}, 500);
		},
	);

	return result;
};

export default authenticateUsingAppLinks;
