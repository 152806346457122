import React, { type SyntheticEvent } from 'react';
import { createSelector } from 'reselect';
import DraftPR from '@atlassian/jira-autodev-draft-pr/src/index.tsx';
import AsyncAutodev from '@atlassian/jira-autodev/src/async.tsx';
import { AutodevErrorBoundary } from '@atlassian/jira-autodev/src/common/ui/error-boundary/index.tsx';
import { AutodevIssueContextStoreContainer } from '@atlassian/jira-autodev/src/controllers/issue-context/index.tsx';
import { useDevOpsDetailsDialogLoaderActions } from '@atlassian/jira-development-details-loader/src/controllers/main.tsx';
import { AppConfigurationPromptSummary } from '@atlassian/jira-development-summary-app-config-prompt-field/src/ui/index.tsx';
import Branch from '@atlassian/jira-development-summary-branch/src/ui/index.tsx';
import Build from '@atlassian/jira-development-summary-build/src/ui/index.tsx';
import Commit from '@atlassian/jira-development-summary-commit/src/ui/index.tsx';
import {
	SUMMARY_ITEM_BRANCH,
	SUMMARY_ITEM_BUILD,
	SUMMARY_ITEM_COMMIT,
	SUMMARY_ITEM_DEPLOYMENT,
	SUMMARY_ITEM_PULLREQUEST,
	SUMMARY_ITEM_REVIEW,
} from '@atlassian/jira-development-summary-common/src/common/constants.tsx';
import { toDetailDialogDataType } from '@atlassian/jira-development-summary-common/src/common/utils.tsx';
import Deployment from '@atlassian/jira-development-summary-deployment-development-field/src/ui/index.tsx';
import PullRequest from '@atlassian/jira-development-summary-pull-request/src/ui/index.tsx';
import Review from '@atlassian/jira-development-summary-review/src/ui/index.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import {
	useAnalyticsEvents,
	type Attributes,
	fireUIAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { useProjectId, useProjectKey } from '@atlassian/jira-project-context-service/src/main.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import DevelopmentFieldWrapper from '../../common/ui/development-field-wrapper/index.tsx';
import SlideDownOnMount from '../../common/ui/slide-down-on-mount/index.tsx';
import { getAnalyticsIssueData } from '../../controllers/analytics/selectors/index.tsx';
import { issueKeySelector, issueIdSelector } from '../../controllers/context/selectors/index.tsx';
import {
	hasBranchCapabilitiesSelector,
	hasSummaryInformationSelector,
	branchSelector,
	createBranchTargetsSelector,
	summarySectionMessageSelector,
	reviewSummaryItemSelector,
	buildSummaryItemSelector,
	deploymentSummaryItemSelector,
	pullRequestSummaryItemSelector,
	commitSummaryItemSelector,
	legacyDataInformationSelector,
	detailsHasInstanceErrorsSelector,
	hasDevelopmentDataInProjectSelector,
	hasSummaryItemsSelector,
} from '../../controllers/dev-summary/selectors/index.tsx';
import ErrorTag from './error-tag/index.tsx';
import OnboardingMessage from './onboarding-message/index.tsx';
import type { StateProps, Props } from './types.tsx';

export const Summary = ({
	hasSummaryData,
	showSummarySectionMessage,
	review,
	build,
	deployment,
	pullRequest,
	issueKey,
	issueId,
	hasBranchCapabilities,
	analyticsAttributes,
	branch,
	branchTargets,
	legacyDataSummary,
	commit,
	detailsHasInstanceErrors,
	hasDevelopmentDataInProject,
}: Props) => {
	const { showDevDetailDialog } = useDevOpsDetailsDialogLoaderActions(`devInfoPanelApp${issueKey}`);

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const projectKey = useProjectKey(issueKey);
	const projectId = useProjectId(projectKey) ?? '';

	const onShowDevDetailsDialog = (
		clickEvent?: KeyboardEvent | SyntheticEvent | SyntheticEvent<EventTarget, Event>,
		// @ts-expect-error - TS1016 - A required parameter cannot follow an optional parameter.
		devInfoType: string,
	): void => {
		clickEvent && clickEvent.preventDefault();

		const detailDialogAnalyticsAttributes: Attributes = {
			...analyticsAttributes,
			summaryType: devInfoType,
		};

		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'summaryItem',
			}),
			'devpanelsummary',
			detailDialogAnalyticsAttributes,
		);
		const panelItemType = toDetailDialogDataType(devInfoType);
		if (devInfoType === SUMMARY_ITEM_REVIEW) {
			const summaryItem =
				// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'LegacyDevSummary'.
				legacyDataSummary && legacyDataSummary[panelItemType]
					? // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'LegacyDevSummary'.
						legacyDataSummary[panelItemType]
					: undefined;
			showDevDetailDialog(
				// @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'PanelDataTypes'.
				panelItemType,
				summaryItem,
				issueKey,
				issueId,
			);
		} else {
			showDevDetailDialog(
				// @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'PanelDataTypes'.
				panelItemType,
				undefined,
				issueKey,
				issueId,
			);
		}
	};

	const { count: branchesCount = 0 } = branch ?? {};

	return (
		<>
			{!hasSummaryData && showSummarySectionMessage && <OnboardingMessage />}

			<DevelopmentFieldWrapper afterHeadingNode={<ErrorTag />} pinnable>
				<SlideDownOnMount>
					{ff('autodev-on-jira-issue') && (
						<UFOSegment name="view-autodev-issue-section">
							<AutodevErrorBoundary id="JiraAutodevDevSummaryField" fallback="flag">
								<AutodevIssueContextStoreContainer
									issueId={issueId}
									issueKey={issueKey}
									projectId={projectId}
									projectKey={projectKey}
								>
									<AsyncAutodev issueId={issueId} />
								</AutodevIssueContextStoreContainer>
							</AutodevErrorBoundary>
						</UFOSegment>
					)}
					{!hasSummaryData && !hasDevelopmentDataInProject && (
						<AppConfigurationPromptSummary
							location="DEVELOPMENT_PANEL"
							panelHasOtherPrompts={hasBranchCapabilities}
						/>
					)}
					<Branch
						branch={branch}
						branchTargets={branchTargets}
						hasBranchCapabilities={hasBranchCapabilities}
						// @ts-expect-error - TS2322 - Type '(e: any) => void' is not assignable to type '() => void'. | TS7006 - Parameter 'e' implicitly has an 'any' type.
						onClick={(e) => onShowDevDetailsDialog(e, SUMMARY_ITEM_BRANCH)}
						issueId={issueId}
						issueKey={issueKey}
					/>
					<Commit
						commit={commit}
						hasCommitCapabilities={hasBranchCapabilities} // If you have branch capabilities you will also have commit capabilities
						// @ts-expect-error - TS2322 - Type '(e: any) => void' is not assignable to type '() => void'. | TS7006 - Parameter 'e' implicitly has an 'any' type.
						onClick={(e) => onShowDevDetailsDialog(e, SUMMARY_ITEM_COMMIT)}
						issueId={issueId}
						issueKey={issueKey}
					/>
					{hasSummaryData && (
						<PullRequest
							branchesCount={branchesCount}
							hasErrors={detailsHasInstanceErrors}
							pullRequest={pullRequest}
							// @ts-expect-error - TS2322 - Type '(e: any) => void' is not assignable to type '() => void'. | TS7006 - Parameter 'e' implicitly has an 'any' type.
							onClick={(e) => onShowDevDetailsDialog(e, SUMMARY_ITEM_PULLREQUEST)}
							issueId={issueId}
							issueKey={issueKey}
						/>
					)}
					<DraftPR issueId={issueId} issueKey={issueKey} />
					{hasSummaryData && (
						<Review
							review={review}
							// @ts-expect-error - TS2322 - Type '(e: any) => void' is not assignable to type '() => void'. | TS7006 - Parameter 'e' implicitly has an 'any' type.
							onClick={(e) => onShowDevDetailsDialog(e, SUMMARY_ITEM_REVIEW)}
						/>
					)}
					{hasSummaryData && (
						<Build
							build={build}
							// @ts-expect-error - TS2322 - Type '(e: any) => void' is not assignable to type '() => void'. | TS7006 - Parameter 'e' implicitly has an 'any' type.
							onClick={(e) => onShowDevDetailsDialog(e, SUMMARY_ITEM_BUILD)}
						/>
					)}
					{hasSummaryData && (
						<Deployment
							deployment={deployment}
							issueKey={issueKey}
							// @ts-expect-error - TS2322 - Type '(e: any) => void' is not assignable to type '() => void'. | TS7006 - Parameter 'e' implicitly has an 'any' type.
							onClick={(e) => onShowDevDetailsDialog(e, SUMMARY_ITEM_DEPLOYMENT)}
						/>
					)}
				</SlideDownOnMount>
			</DevelopmentFieldWrapper>
		</>
	);
};

const getAnalyticsIssueDataMemoized = () =>
	createSelector(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(state: any) => getAnalyticsIssueData(state),
		(res) => res,
	);

const mapStateToPropsFactory = () => {
	const getAnalyticsIssueDataInstance = getAnalyticsIssueDataMemoized();
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return (state: any): StateProps => ({
		showSummarySectionMessage: summarySectionMessageSelector(state),
		// hasSummaryData includes errors
		hasSummaryData: hasSummaryInformationSelector(state),
		// hasSummaryItems does not include errors
		hasSummaryItems: hasSummaryItemsSelector(state),
		legacyDataSummary: legacyDataInformationSelector(state),
		analyticsAttributes: getAnalyticsIssueDataInstance(state),
		review: reviewSummaryItemSelector(state),
		build: buildSummaryItemSelector(state),
		commit: commitSummaryItemSelector(state),
		deployment: deploymentSummaryItemSelector(state),
		pullRequest: pullRequestSummaryItemSelector(state),
		issueKey: issueKeySelector(state),
		issueId: issueIdSelector(state),
		hasBranchCapabilities: hasBranchCapabilitiesSelector(state),
		branch: branchSelector(state),
		branchTargets: createBranchTargetsSelector(state) ?? [],
		detailsHasInstanceErrors: detailsHasInstanceErrorsSelector(state),
		hasDevelopmentDataInProject: hasDevelopmentDataInProjectSelector(state),
	});
};

export default connect(mapStateToPropsFactory, {})(Summary);
