/**
 * @generated SignedSource<<13a6da17ba8fb3ac372e8c90d067d9b8>>
 * @relayHash 80cbeeb6d277f8a7d95b81231a6bbe51
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 46fdc455d21bd35f6e26bb68772b9a0b49b8ccfa054909ffff9e598beab0f022

import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type JiraSingleValueFieldOperations = "SET" | "%future added value";
export type JiraUpdateResolutionFieldInput = {
  id: string;
  operation: JiraResolutionFieldOperationInput;
};
export type JiraResolutionFieldOperationInput = {
  id?: string | null | undefined;
  operation: JiraSingleValueFieldOperations;
};
export type ui_issueFieldResolution_IssueViewResolutionFieldMutation$variables = {
  input: JiraUpdateResolutionFieldInput;
};
export type ui_issueFieldResolution_IssueViewResolutionFieldMutation$data = {
  readonly jira: {
    readonly updateResolutionField: {
      readonly field: {
        readonly resolution: {
          readonly id: string;
          readonly name: string;
        } | null | undefined;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type ui_issueFieldResolution_IssueViewResolutionFieldMutation$rawResponse = {
  readonly jira: {
    readonly updateResolutionField: {
      readonly field: {
        readonly id: string;
        readonly resolution: {
          readonly id: string;
          readonly name: string | null | undefined;
        } | null | undefined;
      } | null | undefined;
      readonly success: boolean;
    } | null | undefined;
  } | null | undefined;
};
export type ui_issueFieldResolution_IssueViewResolutionFieldMutation = {
  rawResponse: ui_issueFieldResolution_IssueViewResolutionFieldMutation$rawResponse;
  response: ui_issueFieldResolution_IssueViewResolutionFieldMutation$data;
  variables: ui_issueFieldResolution_IssueViewResolutionFieldMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "success"
},
v3 = {
  "kind": "ScalarField",
  "name": "id"
},
v4 = {
  "kind": "ScalarField",
  "name": "name"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "ui_issueFieldResolution_IssueViewResolutionFieldMutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraResolutionFieldPayload",
            "kind": "LinkedField",
            "name": "updateResolutionField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "concreteType": "JiraResolutionField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraResolution",
                    "kind": "LinkedField",
                    "name": "resolution",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "kind": "RequiredField",
                        "field": (v4/*: any*/),
                        "action": "NONE",
                        "path": "jira.updateResolutionField.field.resolution.name"
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Mutation"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ui_issueFieldResolution_IssueViewResolutionFieldMutation",
    "selections": [
      {
        "concreteType": "JiraMutation",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "JiraResolutionFieldPayload",
            "kind": "LinkedField",
            "name": "updateResolutionField",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "concreteType": "JiraResolutionField",
                "kind": "LinkedField",
                "name": "field",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraResolution",
                    "kind": "LinkedField",
                    "name": "resolution",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ]
                  },
                  (v3/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "46fdc455d21bd35f6e26bb68772b9a0b49b8ccfa054909ffff9e598beab0f022",
    "metadata": {},
    "name": "ui_issueFieldResolution_IssueViewResolutionFieldMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "b318a3b190eca3a7be126ec47b68ca9e";

export default node;
