import { useRef } from 'react';

type Props = {
	hoverDuration?: number;
	onHide: () => void;
	onShow: () => void;
};

type UseCard = {
	setCardHoverStatus: (arg1: boolean) => void;
	setTriggerHoverStatus: (arg1: boolean) => void;
};

export const useCard = ({ onHide, onShow, hoverDuration = 800 }: Props): UseCard => {
	const hideTimeoutId = useRef<unknown>(null);
	const showTimeoutId = useRef<unknown>(null);

	const triggerHoverStatus = useRef<boolean>(false);
	const cardHoverStatus = useRef<boolean>(false);

	const executeOnHide = () => {
		if (hideTimeoutId.current) {
			// @ts-expect-error - TS2769 - No overload matches this call.
			clearTimeout(hideTimeoutId.current);
		}

		hideTimeoutId.current = setTimeout(() => {
			if (!triggerHoverStatus.current && !cardHoverStatus.current) {
				onHide();
			}
		}, 400);
	};

	const executeOnShow = () => {
		if (showTimeoutId.current) {
			// @ts-expect-error - TS2769 - No overload matches this call.
			clearTimeout(showTimeoutId.current);
		}

		showTimeoutId.current = setTimeout(() => {
			// only able to show card when trigger is hovering after 800 milliseconds
			if (triggerHoverStatus.current) {
				onShow();
			}
		}, hoverDuration);
	};

	const setTriggerHoverStatus = (status: boolean) => {
		triggerHoverStatus.current = status;
		executeOnHide();
		executeOnShow();
	};

	const setCardHoverStatus = (status: boolean) => {
		cardHoverStatus.current = status;
		executeOnHide();
		executeOnShow();
	};

	return {
		setCardHoverStatus,
		setTriggerHoverStatus,
	};
};
