import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	addPeopleButton: {
		id: 'invite-prompt-on-link-insert.invite-prompt-flag-v5.add-people-button',
		defaultMessage: '{inviteeCount, plural, one {Add {displayName}} other {Add # people}}',
		description: 'CTA on a primary button to invite a group of people',
	},
	dismiss: {
		id: 'invite-prompt-on-link-insert.invite-prompt-flag-v5.dismiss',
		defaultMessage: "Don't show again",
		description: 'Secondary button to dismiss the flag permanently',
	},
	titleGeneric: {
		id: 'invite-prompt-on-link-insert.invite-prompt-flag-v5.title-generic',
		defaultMessage:
			'{inviteeCount, plural, one {{displayName} may need access} other {Your team may need access}}',
		description: 'Title of a flag pop up where the user can invite one or more users.',
	},
	descriptionWithoutPageTitleGeneric: {
		id: 'invite-prompt-on-link-insert.invite-prompt-flag-v5.description-without-page-title-generic',
		defaultMessage:
			'{inviteeCount, plural, one {Your project assignee <strong>{displayName}</strong> needs access to <strong>{productDisplayName}</strong>.} other {# project assignees need access to <strong>{productDisplayName}</strong>.}}',
		description: 'Description of a flag pop up where the user can invite one or more users.',
	},
	descriptionWithPageTitleGeneric: {
		id: 'invite-prompt-on-link-insert.invite-prompt-flag-v5.description-with-page-title-generic',
		defaultMessage:
			'{inviteeCount, plural, one {Your project assignee <strong>{displayName}</strong> needs access to {productDisplayName} to view <strong>{title}</strong>.} other {# project assignees need access to {productDisplayName} to view <strong>{title}</strong>.}}',
		description: 'Description of a flag pop up where the user can invite one or more users.',
	},
});
