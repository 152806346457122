import { Component, type ReactNode } from 'react';
import { isClientFetchError } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import type { Attributes } from '@atlassian/jira-product-analytics-bridge';

type Props = {
	isEditing: boolean;
	field: string;
	children: ReactNode;
	onFailure: (field: string, errorMessage: string, additionalAttributes?: Attributes) => void;
};
/**
 * Manages error handling for network-related issues within a component hierarchy. Upon encountering errors, it differentiates between network fetch issues and others. For non-network errors during an edit state, it triggers a custom error handling method without sending failure events for network-related errors. It ensures the component tree's integrity by rethrowing exceptions and maintains normal rendering behavior for child components.
 */
export default class Consumer extends Component<Props> {
	componentDidCatch(error: Error) {
		// This filters for any errors that are related to network connectivity
		// We don't care to send any failure event should this happen.
		if (this.props.isEditing && !isClientFetchError(error)) {
			// @ts-expect-error - TS2339 - Property 'traceId' does not exist on type 'Error'.
			const { message, traceId } = error;
			this.props.onFailure(this.props.field, message, {
				...(traceId !== undefined && traceId !== null
					? {
							traceId,
						}
					: {}),
			});
		}
		throw error;
	}

	render() {
		return this.props.children;
	}
}
