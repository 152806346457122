import React from 'react';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type ReviewButton from './index.tsx';
import type { ReviewButtonProps } from './types.tsx';

const ReviewButtonAsync = lazyForPaint<typeof ReviewButton>(
	() => import(/* webpackChunkName: "async-pir-review-button" */ './index'),
);

export const AsyncReviewButton = (props: ReviewButtonProps) => (
	<Placeholder name="pir-review-button" fallback={null}>
		<ReviewButtonAsync {...props} />
	</Placeholder>
);
