import React from 'react';

import { token } from '@atlaskit/tokens';

export const emptyStateIcon = (
	<svg
		width="313"
		height="215"
		viewBox="0 0 313 215"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M146.296 0.793168C183.809 4.00611 210.17 26.1894 237.711 45.7988C266.71 66.447 301.378 85.3411 307.01 115.226C313.293 148.564 305.832 189.973 268.25 207.848C231.892 225.141 189.127 193.51 146.296 191.668C108.52 190.042 68.5787 214.216 37.4175 197.917C4.57549 180.738 1.18294 145.502 0.153 115.226C-0.911134 83.9451 2.95664 50.6107 31.6423 28.1492C60.6366 5.44611 104.914 -2.75118 146.296 0.793168Z"
			fill={token('color.background.accent.blue.subtlest', '#E9F2FF')}
		/>
		<g filter="url(#filter0_dd_1968_47879)">
			<rect
				x="21"
				y="15"
				width="280"
				height="180"
				rx="8"
				fill={token('elevation.surface', 'white')}
			/>
			<rect
				x="207"
				y="46.5"
				width="77"
				height="14"
				rx="2"
				fill={token('color.background.accent.blue.subtlest', '#E9F2FF')}
			/>
			<rect
				x="208"
				y="47.5"
				width="12"
				height="12"
				rx="4"
				fill={token('color.background.accent.blue.subtlest', '#E9F2FF')}
			/>
			<path
				d="M215 50L213 57"
				stroke={token('color.icon.accent.blue', '#1D7AFC')}
				strokeLinecap="round"
			/>
			<path
				d="M215 50L211.5 53.5H216.5L213 57"
				stroke={token('color.icon.accent.blue', '#1D7AFC')}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<rect
				x="207"
				y="46.5"
				width="77"
				height="14"
				rx="2"
				stroke={token('color.border.focused', '#388BFF')}
				strokeWidth="2"
			/>
			<rect
				x="37"
				y="45.5"
				width="155"
				height="16"
				rx="3"
				fill={token('color.background.accent.gray.subtlest', '#F1F2F4')}
			/>
			<rect
				x="37"
				y="71.5"
				width="155"
				height="28"
				rx="3"
				fill={token('color.background.accent.gray.subtlest', '#F1F2F4')}
			/>
			<rect
				x="37"
				y="109.5"
				width="17"
				height="17"
				rx="8.5"
				fill={token('color.background.accent.gray.subtlest', '#F1F2F4')}
			/>
			<rect
				x="58"
				y="113.5"
				width="27"
				height="9"
				rx="3"
				fill={token('color.background.accent.gray.subtlest', '#F1F2F4')}
			/>
			<rect
				x="89"
				y="113.5"
				width="27"
				height="9"
				rx="3"
				fill={token('color.background.accent.gray.subtlest', '#F1F2F4')}
			/>
			<rect
				x="120"
				y="113.5"
				width="27"
				height="9"
				rx="3"
				fill={token('color.background.accent.gray.subtlest', '#F1F2F4')}
			/>
			<rect
				x="37"
				y="136.5"
				width="133"
				height="9"
				rx="3"
				fill={token('color.background.accent.gray.subtlest', '#F1F2F4')}
			/>
			<rect
				x="37"
				y="155.5"
				width="89"
				height="9"
				rx="3"
				fill={token('color.background.accent.gray.subtlest', '#F1F2F4')}
			/>
		</g>
		<defs>
			<filter
				id="filter0_dd_1968_47879"
				x="9"
				y="11"
				width="304"
				height="204"
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset />
				<feGaussianBlur stdDeviation="0.5" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0.0352941 0 0 0 0 0.117647 0 0 0 0 0.258824 0 0 0 0.31 0"
				/>
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1968_47879" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy="8" />
				<feGaussianBlur stdDeviation="6" />
				<feColorMatrix
					type="matrix"
					values="0 0 0 0 0.0352941 0 0 0 0 0.117647 0 0 0 0 0.258824 0 0 0 0.15 0"
				/>
				<feBlend
					mode="normal"
					in2="effect1_dropShadow_1968_47879"
					result="effect2_dropShadow_1968_47879"
				/>
				<feBlend
					mode="normal"
					in="SourceGraphic"
					in2="effect2_dropShadow_1968_47879"
					result="shape"
				/>
			</filter>
		</defs>
	</svg>
);
