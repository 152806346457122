import { parentCandidatesForNewIssueQuery } from './fragments/index.tsx';

export const PARENT_CANDIDATES_FOR_NEW_ISSUES_QUERY_WITH_INFO =
	'issueParentCandidatesForNewIssuesQueryWithInfo';

export const buildParentCandidatesForNewIssueQuery = () => `
    query jira_parenting_${PARENT_CANDIDATES_FOR_NEW_ISSUES_QUERY_WITH_INFO}($projectId: ID!, $issueTypeId: ID!, $cloudId: ID!, $searchTerm: String, $excludeDone: Boolean, $first: Int)  {
        ${parentCandidatesForNewIssueQuery()}
    }
`;
