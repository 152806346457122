import type { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';
import type { CreateBranchTarget } from '../../common/types.tsx';
import { transformRemoteApps } from './utils.tsx';

export const fetchCreateBranchTargets = (issueId: string): Observable<CreateBranchTarget[]> =>
	fetchJson$(`/rest/dev-status/1.0/issue/create-branch-targets?issueId=${issueId}`).map(
		// @ts-expect-error - TS2304 - Cannot find name 'RemoteAppResponse'.
		(response: RemoteAppResponse) => transformRemoteApps(response),
	);
