import type { Observable as ObservableType } from 'rxjs/Observable';
import fetchJson$ from '@atlassian/jira-fetch/src/utils/as-json-stream.tsx';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';

type Payload = {
	issueIds: IssueId[];
};

const getIssueSetParentUrlTMP = (projectId: number, parentId: IssueId): string =>
	`/rest/internal/simplified/1.0/projects/${projectId}/issues/${parentId}/children`;

export const setIssueParentsTMP = (
	projectId: number,
	parentId: IssueId,
	payload: Payload,
): ObservableType<unknown> =>
	fetchJson$(getIssueSetParentUrlTMP(projectId, parentId), {
		method: 'POST',
		body: JSON.stringify(payload),
	});
