import React, { type FC, type ReactNode } from 'react';
import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';

type Props = {
	isCompact?: boolean;
	shouldHighlightOnHover?: boolean;
	children?: ReactNode | undefined;
	tooltip?: string | null;
};

const ProgressBarInner: FC<Props> = ({
	shouldHighlightOnHover,
	tooltip,
	isCompact = true,
	children,
}) =>
	shouldHighlightOnHover ? (
		<ProgressBarContainerHover
			data-testid="issue.component.progress-tracker.progress-bar.progress-bar-container-hover"
			hasPointerCursor={!!tooltip}
		>
			<ProgressBarContainer isCompact={isCompact} hasPointerCursor={!!tooltip}>
				{children}
			</ProgressBarContainer>
		</ProgressBarContainerHover>
	) : (
		<ProgressBarContainer
			data-testid="issue.component.progress-tracker.progress-bar.progress-bar-container"
			isCompact={isCompact}
			hasPointerCursor={!!tooltip}
		>
			{children}
		</ProgressBarContainer>
	);

const ProgressBar: FC<Props> = (props) =>
	!props.tooltip ? (
		<ProgressBarInner {...props} />
	) : (
		<Tooltip testId="issue.component.progress-tracker.progress-bar.tooltip" content={props.tooltip}>
			<ProgressBarInner {...props} />
		</Tooltip>
	);

export default ProgressBar;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ProgressBarContainerHover = styled.div<{ hasPointerCursor?: boolean }>({
	padding: token('space.050', '4px'),
	backgroundColor: 'transparent',
	borderRadius: '3px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	cursor: (props) => (props.hasPointerCursor ? 'pointer;' : undefined),
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('color.background.neutral.hovered', colors.N60),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ProgressBarContainer = styled.div<{
	isCompact?: boolean;
	hasPointerCursor?: boolean;
}>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${gridSize / 2}px`,
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	height: (props) => (props.isCompact ? `${gridSize / 2}px` : `${gridSize}px`),
	overflow: 'hidden',
	width: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	minHeight: `${gridSize / 2}px !important;`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	cursor: (props) => (props.hasPointerCursor ? 'pointer' : ''),
});
