import type { UserFieldValue } from '@atlassian/jira-polaris-domain-field/src/field-types/user/types.tsx';
import type { Field } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { IssuesRemote } from '@atlassian/jira-polaris-remote-issue/src/controllers/types.tsx';
import { defaultJiraUpdateService } from '../common/index.tsx';
import { USER_MULTI_SELECT } from '../constants.tsx';
import type { JiraFieldMapping } from '../types.tsx';

export const jiraUsersMapping = (
	issuesRemote: IssuesRemote,
	field: Field,
): JiraFieldMapping<UserFieldValue[]> => ({
	field,
	getValueFromJiraIssue: (issue) => issue.fields[field.key] ?? undefined,
	getFieldValueForJiraUpdate: (value) => (value !== undefined ? value : null),
	getFieldValueForJiraBulkUpdate: (value) => {
		const users =
			value?.map(({ accountId }) => ({
				accountId,
			})) ?? [];
		return {
			[USER_MULTI_SELECT]: {
				fieldId: field.key,
				users: users.length > 0 ? users : null,
			},
		};
	},
	isMultiValueField: true,
	isSupportedByIssueUpdateApi: true,
	...defaultJiraUpdateService(issuesRemote, field.key),
});
