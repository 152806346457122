import { createContainer } from '@atlassian/react-sweet-state';
import { store, type Actions } from '../main.tsx';
import type { IssueAdjustmentsState, StoreContainerProps } from '../types.tsx';

export const LoadedUiModificationsContainer = createContainer<
	IssueAdjustmentsState,
	Actions,
	StoreContainerProps
>(store, {
	displayName: 'LoadedUiModificationsContainer',
	onInit:
		() =>
		({ setState }, { viewType }) => {
			setState({ viewType });
		},
});
