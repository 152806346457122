/**
 * @generated SignedSource<<28cc3f1e512619502f58cc6e1fc159db>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type singleLineText_issueFieldSingleLineTextReadviewFull_SingleLineTextReadView$data = {
  readonly fieldId: string;
  readonly text: string | null | undefined;
  readonly " $fragmentType": "singleLineText_issueFieldSingleLineTextReadviewFull_SingleLineTextReadView";
};
export type singleLineText_issueFieldSingleLineTextReadviewFull_SingleLineTextReadView$key = {
  readonly " $data"?: singleLineText_issueFieldSingleLineTextReadviewFull_SingleLineTextReadView$data;
  readonly " $fragmentSpreads": FragmentRefs<"singleLineText_issueFieldSingleLineTextReadviewFull_SingleLineTextReadView">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "singleLineText_issueFieldSingleLineTextReadviewFull_SingleLineTextReadView",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "text"
    },
    {
      "kind": "ScalarField",
      "name": "fieldId"
    }
  ],
  "type": "JiraSingleLineTextField"
};

(node as any).hash = "5f51bd1526b02ac7cd91c57eec827a2f";

export default node;
