import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	changeTypeSuccessTitle: {
		id: 'issue-view-layout-issue-type-switcher.changeTypeSuccessTitle',
		defaultMessage: 'Issue type changed to {destType}',
		description: 'Title of the success flag when issue type is changed in issue view',
	},
	changeTypeSuccessDescription: {
		id: 'issue-view-layout-issue-type-switcher.changeTypeSuccessDescription',
		defaultMessage: 'Issue type changed',
		description: 'Content of the success flag when issue type is changed in issue view',
	},
	changeTypeFailureTitle: {
		id: 'issue-view-layout-issue-type-switcher.changeTypeFailureTitle',
		defaultMessage: 'Error changing issue type',
		description: 'Title of the error flag when issue type is failed to change in issue view',
	},
	changeTypeFailureDescription: {
		id: 'issue-view-layout-issue-type-switcher.changeTypeFailureDescription',
		defaultMessage: 'There was an error changing the issue type. Please try again.',
		description: 'Content of of the error flag when issue type is failed to change in issue view',
	},
});
