import type { UserOption, AggUser } from '../../common/types.tsx';
import { getAccountIdFromAri } from '../get-account-id-from-ari/index.tsx';

export const transformUserOptionToAgg = (option: UserOption): AggUser => ({
	picture: option?.avatarUrl || '',
	accountId: option.accountId ?? getAccountIdFromAri(option.id),
	name: option.name,
	accountStatus: option.active ? 'active' : 'inactive',
	id: option.id,
	email: option.byline ?? null,
});
