import React, { useMemo } from 'react';
import type { ActiveKeyMap } from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-registry.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { FormattedMessage } from '@atlassian/jira-intl';
import { useIssueKey, useRapidViewMap } from '@atlassian/jira-issue-context-service/src/main.tsx';
import {
	KEYBOARD_SHORTCUT_PRIORITY,
	BOARD_SHORTCUT_NEXT_ACTIVITY_ISSUE,
	BOARD_SHORTCUT_NEXT_ISSUE,
	BOARD_SHORTCUT_PREVIOUS_ACTIVITY_ISSUE,
	BOARD_SHORTCUT_PREVIOUS_ISSUE,
} from '../constant.tsx';
import { messages } from '../messages.tsx';
import type { NavigateToIssue } from '../types.tsx';
import {
	findIssueOfNextActivity,
	findIssueOfPreviousActivity,
	findNextIssue,
	findPreviousIssue,
} from './issue-board-navigator.tsx';

const useBoardNavigationShortcuts = (navigateToIssue: NavigateToIssue): ActiveKeyMap => {
	const issueKey = useIssueKey();
	const rapidViewMap = useRapidViewMap();

	// @ts-expect-error - TS2322 - Type '{ j?: undefined; k?: undefined; n?: undefined; p?: undefined; } | { j: { callback: () => void; label: Element; priority: number; }; k: { callback: () => void; label: Element; priority: number; }; n: { ...; }; p: { ...; }; }' is not assignable to type 'ActiveKeyMap'.
	return useMemo(() => {
		if (!rapidViewMap) {
			return {};
		}

		return {
			[BOARD_SHORTCUT_NEXT_ISSUE]: {
				callback: () => {
					navigateToIssue(findNextIssue(rapidViewMap, issueKey));
				},
				label: (
					<FormattedMessage
						{...(expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
							? messages.nextIssueIssueTermRefresh
							: messages.nextIssue)}
					/>
				),
				priority: KEYBOARD_SHORTCUT_PRIORITY,
			},
			[BOARD_SHORTCUT_PREVIOUS_ISSUE]: {
				callback: () => {
					navigateToIssue(findPreviousIssue(rapidViewMap, issueKey));
				},
				label: (
					<FormattedMessage
						{...(expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
							? messages.previousIssueIssueTermRefresh
							: messages.previousIssue)}
					/>
				),
				priority: KEYBOARD_SHORTCUT_PRIORITY,
			},
			[BOARD_SHORTCUT_NEXT_ACTIVITY_ISSUE]: {
				callback: () => {
					navigateToIssue(findIssueOfNextActivity(rapidViewMap, issueKey));
				},
				label: <FormattedMessage {...messages.nextActivity} />,
				priority: KEYBOARD_SHORTCUT_PRIORITY,
			},
			[BOARD_SHORTCUT_PREVIOUS_ACTIVITY_ISSUE]: {
				callback: () => {
					navigateToIssue(findIssueOfPreviousActivity(rapidViewMap, issueKey));
				},
				label: <FormattedMessage {...messages.previousActivity} />,
				priority: KEYBOARD_SHORTCUT_PRIORITY,
			},
		};
	}, [issueKey, navigateToIssue, rapidViewMap]);
};

export default useBoardNavigationShortcuts;
