import React, { type ReactNode } from 'react';
import IssuePageGrid from '@atlassian/jira-issue-view-common-views/src/issue-page-grid/index.tsx';
import { ErrorHeader } from '../header/error-header/index.tsx';

export const ErrorPage = ({
	onClose,
	renderFeedback,
	children,
}: {
	onClose: () => void;
	renderFeedback?: () => ReactNode;
	children?: ReactNode;
}) => (
	<IssuePageGrid>
		<ErrorHeader onClose={onClose} renderFeedback={renderFeedback} />
		{children}
	</IssuePageGrid>
);
