import React, { memo } from 'react';
import { Skeleton } from '@atlassian/jira-common-components-skeleton/src/styled.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { useSearchCustomFieldKeys } from '@atlassian/jira-issue-field-base/src/services/custom-field-key-service/index.tsx';
import { useFieldValue } from '@atlassian/jira-issue-field-base/src/services/field-value-service/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import {
	SERVICE_ENTITY_CF_TYPE,
	SERVICEDESK_PRACTICES_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import AsyncObjectRelatedRequestsPanel from '@atlassian/jira-servicedesk-object-related-requests-panel/src/async.tsx';
import type { ChangeOrIncident } from '@atlassian/jira-servicedesk-object-related-requests-panel/src/common/types.tsx';
import { INCIDENTS } from '@atlassian/jira-servicedesk-work-category/src/common/constants.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ObjectRelatedRequestsPanel = memo<Record<any, any>>(() => {
	const issueKey = useIssueKey();

	const [affectedServicesFieldId] = useSearchCustomFieldKeys(issueKey, SERVICE_ENTITY_CF_TYPE);
	const [affectedServices] = useFieldValue({ issueKey, fieldKey: affectedServicesFieldId });
	// @ts-expect-error - TS7031 - Binding element 'id' implicitly has an 'any' type.
	const serviceIds: string[] = affectedServices?.map(({ id }) => id) ?? [];

	const [itsmPractices] = useFieldValue({ issueKey, fieldKey: SERVICEDESK_PRACTICES_TYPE });

	const changeOrIncident: ChangeOrIncident | null =
		itsmPractices?.[0] === INCIDENTS ? INCIDENTS : null;

	if (fg('enable-assets-in-change-management')) {
		if (changeOrIncident == null) {
			return null;
		}
	} else {
		// eslint-disable-next-line no-lonely-if
		if (!affectedServicesFieldId || serviceIds.length === 0 || changeOrIncident == null) {
			return null;
		}
	}

	return (
		<UFOSegment name="issue-object-related-requests-panel">
			<JSErrorBoundary
				id="AsyncObjectRelatedRequestsPanel"
				packageName="jiraIssueViewBase"
				fallback="unmount"
				sendToPrivacyUnsafeSplunk
			>
				<Placeholder
					name="object-related-requests-panel"
					fallback={<Skeleton width="100%" height="60px" />}
				>
					<AsyncObjectRelatedRequestsPanel
						// TODO remove service related props after cleanup as they are not used
						affectedServicesFieldId={affectedServicesFieldId}
						itsmPractice={changeOrIncident}
						serviceIds={serviceIds}
					/>
				</Placeholder>
			</JSErrorBoundary>
		</UFOSegment>
	);
});

export default ObjectRelatedRequestsPanel;
