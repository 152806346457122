import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';

type AccountIds = string[];

const getInviteesUrl = '/gateway/api/growth/invite-on-link-insert/get-invitees';

export const getUsersWithoutProductAccess = (
	cloudId: string,
	product: string,
	accountIds: AccountIds,
): Promise<AccountIds> =>
	fetchJson<AccountIds>(getInviteesUrl, {
		method: 'POST',
		body: JSON.stringify({ cloudId, product, accountIds }),
	});
