import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	unlinkButtonTooltip: {
		id: 'issue-view-jpd-ideas.idea-card.idea-unlink-cross-button.unlink-button-tooltip',
		defaultMessage: 'Unlink idea',
		description: 'Tooltip to show on hover of unlink idea cross icon button',
	},
	unlinkModalTitle: {
		id: 'issue-view-jpd-ideas.idea-card.idea-unlink-cross-button.unlink-modal-title',
		defaultMessage: 'Remove the link to {issueKey}',
		description: 'Modal title for unlinking idea from issue confirmation dialog',
	},
	unlinkModalBody: {
		id: 'issue-view-jpd-ideas.idea-card.idea-unlink-cross-button.unlink-modal-body',
		defaultMessage: 'You can add it again later if you need to.',
		description: 'Modal body for unlinking idea from issue confirmation dialog',
	},
	unlinkModalConfirmButton: {
		id: 'issue-view-jpd-ideas.idea-card.idea-unlink-cross-button.unlink-modal-confirm-button',
		defaultMessage: 'Remove',
		description: 'Confirm button for unlinking idea from issue confirmation dialog',
	},
	unlinkModalCancelButton: {
		id: 'issue-view-jpd-ideas.idea-card.idea-unlink-cross-button.unlink-modal-cancel-button',
		defaultMessage: 'Cancel',
		description: 'Cancel button for unlinking idea from issue confirmation dialog',
	},
});
