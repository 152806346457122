/** @jsx jsx */
import React, { useCallback, type FC } from 'react';
import { css, jsx } from '@compiled/react';
import Button, { IconButton } from '@atlaskit/button/new';
import CheckIcon from '@atlaskit/icon/core/migration/check-mark--check';
import CrossIcon from '@atlaskit/icon/core/migration/close--cross';
import { xcss, Flex, Box } from '@atlaskit/primitives';
import {
	Card,
	TitleBlock,
	SmartLinkSize,
	SmartLinkTheme,
	SmartLinkPosition,
} from '@atlaskit/smart-card';
import { token } from '@atlaskit/tokens';
import {
	FeedbackForm,
	RELATED_RESOURCES_AI_EXPERIENCE,
} from '@atlassian/jira-atlassian-intelligence/src/common/ui/feedback-form/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useIssueKey } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useProjectKey } from '@atlassian/jira-project-context-service/src/main.tsx';
import {
	useRelatedResources,
	type RelatedResource,
} from '../../controllers/related-resources-context.tsx';
import messages from './messages.tsx';

const FEEDBACK_ENTRYPOINT_ID = '0b84d62e-7d07-41a3-9368-5cf2c1dd3343';
const FEEDBACK_COLLECTOR_ID = 'b085d646-059d-48d8-941d-c5f04447ef0e';
const ACTION_BUTTON_COMPONENT_SELECTOR =
	'ai-improve-issue-ai-related-resources-draft-list-row-action-button';

export const RelatedResourcesDraftList: FC = () => {
	const { formatMessage } = useIntl();
	const [{ resourceSuggestions, isLoadingSuggestions }, { acceptSuggestions, discardSuggestion }] =
		useRelatedResources();

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const issueKey = useIssueKey();
	const projectKey = useProjectKey(issueKey);

	const draftSuggestions = resourceSuggestions?.filter(
		(suggestion) => suggestion.resourceStatus === 'DRAFT',
	);

	const onAcceptSuggestions = useCallback(
		(linkSuggestionsRequest: RelatedResource[], isAcceptingAllResources: boolean) => {
			acceptSuggestions(linkSuggestionsRequest);

			const actionSubjectId = isAcceptingAllResources
				? 'suggestedResourceAllAccepted'
				: 'suggestedResourceAccepted';
			fireTrackAnalytics(createAnalyticsEvent({}), 'aiResult actioned', actionSubjectId, {
				aiResultAction: actionSubjectId,
				resourceIds: linkSuggestionsRequest.map(({ id }) => id),
			});
		},
		[acceptSuggestions, createAnalyticsEvent],
	);
	const onDiscardSuggestion = useCallback(
		(suggestionToBeDiscarded: RelatedResource) => {
			discardSuggestion(suggestionToBeDiscarded);
			fireTrackAnalytics(
				createAnalyticsEvent({}),
				'aiResult actioned',
				'suggestedResourceRemoved',
				{
					aiResultAction: 'suggestedResourceRemoved',
					resourceIds: [suggestionToBeDiscarded.id],
				},
			);
		},
		[createAnalyticsEvent, discardSuggestion],
	);

	const cards = draftSuggestions?.map(({ resource, isLinking }) => {
		return (
			<div css={rowStyles} key={resource.id}>
				<Flex>
					<Card
						appearance="block"
						url={resource.url}
						showHoverPreview
						ui={{
							size: SmartLinkSize.Medium,
							hideElevation: true,
							hideBackground: true,
							theme: SmartLinkTheme.Black,
							hidePadding: true,
						}}
					>
						<TitleBlock hideTitleTooltip position={SmartLinkPosition.Center} />
					</Card>
				</Flex>
				<Flex xcss={actionStyles}>
					<IconButton
						testId={`ai-related-resources.ui.related-resources-draft-list.accept-suggestion-icon-button-${resource.id}`}
						data-component-selector={`${ACTION_BUTTON_COMPONENT_SELECTOR}${isLinking ? 'is-linking' : ''}`}
						icon={CheckIcon}
						label={formatMessage(messages.accept)}
						spacing="compact"
						appearance="subtle"
						isLoading={isLinking}
						onClick={() => onAcceptSuggestions([resource], false)}
					/>
					<IconButton
						testId={`ai-related-resources.ui.related-resources-draft-list.discard-suggestion-icon-button-${resource.id}`}
						data-component-selector={`${ACTION_BUTTON_COMPONENT_SELECTOR}${isLinking ? 'is-linking' : ''}`}
						icon={CrossIcon}
						label={formatMessage(messages.remove)}
						spacing="compact"
						appearance="subtle"
						onClick={() => onDiscardSuggestion(resource)}
						isDisabled={isLinking}
					/>
				</Flex>
			</div>
		);
	});

	const onFeedbackAction = useCallback(
		(typeOfAction: 'submitted' | 'clicked', aiFeedbackResult: 'up' | 'down') => {
			fireTrackAnalytics(
				createAnalyticsEvent({}),
				typeOfAction === 'submitted' ? 'aiFeedback submitted' : 'aiFeedback clicked',
				typeOfAction === 'submitted' ? 'feedbackSubmitted' : 'feedbackClicked',
				{ aiFeedbackResult },
			);
		},
		[createAnalyticsEvent],
	);

	const onFeedbackClicked = useCallback(
		(aiFeedbackResult: 'up' | 'down') => {
			onFeedbackAction('clicked', aiFeedbackResult);
		},
		[onFeedbackAction],
	);

	const onFeedbackSubmitted = useCallback(
		(aiFeedbackResult: 'up' | 'down') => {
			onFeedbackAction('submitted', aiFeedbackResult);
		},
		[onFeedbackAction],
	);

	const onContainerRef = useCallback((element: HTMLDivElement | null) => {
		if (element && typeof element.scrollIntoView === 'function') {
			element.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
			});
		}
	}, []);

	const areAllDraftSuggestionsLinking = draftSuggestions?.every((res) => res.isLinking === true);

	return (
		<Box ref={onContainerRef}>
			{cards}
			{draftSuggestions && draftSuggestions.length !== 0 && (
				<Flex xcss={footerActionsSectionStyles}>
					<FeedbackForm
						feedbackCollectorId={FEEDBACK_COLLECTOR_ID}
						feedbackFormEntrypointId={FEEDBACK_ENTRYPOINT_ID}
						aiExperience={RELATED_RESOURCES_AI_EXPERIENCE}
						onFeedbackClicked={onFeedbackClicked}
						onFeedbackSent={onFeedbackSubmitted}
						projectKey={projectKey}
					/>
					<Flex columnGap="space.150">
						<Button
							type="button"
							testId="ai-related-resources.ui.related-resources-draft-list.accept-all-loading-button"
							onClick={() =>
								draftSuggestions &&
								onAcceptSuggestions(
									draftSuggestions.map((suggestion) => suggestion.resource),
									true,
								)
							}
							isLoading={areAllDraftSuggestionsLinking}
							isDisabled={isLoadingSuggestions}
						>
							{formatMessage(messages.acceptAll)}
						</Button>
					</Flex>
				</Flex>
			)}
		</Box>
	);
};

const rowStyles = css({
	display: 'flex',
	justifyContent: 'space-between',
	paddingInline: token('space.200'),
	paddingBlock: token('space.150'),
	borderWidth: token('border.width'),
	borderColor: token('color.border'),
	borderStyle: 'solid',
	backgroundColor: token('elevation.surface'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	"[data-component-selector='ai-improve-issue-ai-related-resources-draft-list-row-action-button']":
		{ opacity: '0' },
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	"[data-component-selector='ai-improve-issue-ai-related-resources-draft-list-row-action-button-is-linking']":
		{ opacity: '1' },

	'&:hover, &:focus-within': {
		backgroundColor: token('color.background.neutral.subtle.hovered'),
		textDecoration: 'none',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		"[data-component-selector='ai-improve-issue-ai-related-resources-draft-list-row-action-button']":
			{ opacity: '1' },
	},
});

const actionStyles = xcss({
	display: 'flex',
	justifyContent: 'space-between',
	columnGap: 'space.150',
});

const footerActionsSectionStyles = xcss({
	justifyContent: 'space-between',
	padding: 'space.150',
});
