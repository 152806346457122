import React from 'react';
import Icon from '@atlaskit/icon';

const ArrowUpGlyph = (props: { [key: string]: string }) => (
	<svg
		{...props}
		width="24px"
		height="24px"
		viewBox="0 0 24 24"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<path
				d="M7.8,4.3 C7.6,4.1 7.3,4 7,4 C6.7,4 6.4,4.1 6.2,4.3 L3.3,7.2 C2.9,7.6 2.9,8.3 3.3,8.7 C3.7,9.1 4.3,9.1 4.7,8.7 L6,7.4 L6,19.1 C6,19.6 6.4,20 7,20 C7.6,20 8,19.6 8,19.1 L8,7.4 L9.3,8.7 C9.7,9.1 10.3,9.1 10.7,8.7 C11.1,8.3 11.1,7.6 10.7,7.2 C10.0475461,6.54754611 9.5582057,6.0582057 9.23197876,5.73197876 C8.91376126,5.41376126 8.436435,4.936435 7.8,4.3 Z"
				id="Path"
				fill="currentColor"
				fillRule="nonzero"
			/>
			<path
				d="M21,17 L13,17 C12.4,17 12,17.4 12,18 C12,18.6 12.4,19 13,19 L21,19 C21.6,19 22,18.6 22,18 C22,17.4 21.6,17 21,17 Z"
				id="Path"
				fill="currentColor"
				fillRule="nonzero"
			/>
			<path
				d="M13,13 L18,13 C18.6,13 19,12.6 19,12 C19,11.4 18.6,11 18,11 L13,11 C12.4,11 12,11.4 12,12 C12,12.6 12.4,13 13,13 Z"
				id="Path"
				fill="currentColor"
				fillRule="nonzero"
			/>
			<path
				d="M13,7 L16,7 C16.6,7 17,6.6 17,6 C17,5.4 16.6,5 16,5 L13,5 C12.4,5 12,5.4 12,6 C12,6.6 12.4,7 13,7 Z"
				id="Path"
				fill="currentColor"
				fillRule="nonzero"
			/>
		</g>
	</svg>
);

const ArrowUpIcon = ({ label }: { label: string }) => (
	// @ts-expect-error - TS2322 - Type '(props: { [key: string]: string; }) => JSX.Element' is not assignable to type 'ComponentType<CustomGlyphProps> | undefined'.
	<Icon size="small" label={label} glyph={ArrowUpGlyph} />
);

export default ArrowUpIcon;
